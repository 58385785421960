import type Immutable from 'immutable';
import React from 'react';

import SegmentLabelThumbList from '~/components/logic/segments/SegmentLabelThumbList';
import SegmentsListsDiff from '~/components/logic/segments/SegmentsListsDiff';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';



type Props = {
	value: Immutable.Map<string, any>,
	width: number,
};

const SegmentsDiffFormatter: React.FC<Props> = (props) => {
	const {
		value,
		width,
	} = props;

	const websiteId = useWebsiteId();

	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	if (
		value.get('left').size > 0
		|| value.get('entered').size > 0
	) {
		return (
			<SegmentsListsDiff
				segmentDefinitions={segmentDefinitions.listAll()}
				segmentNamesEntered={value.get('entered').toArray()}
				segmentNamesLeft={value.get('left').toArray()}
				segmentNamesSame={value.get('same').toArray()}
				width={width}
			/>
		);
	}

	return (
		<SegmentLabelThumbList
			segmentDefinitions={segmentDefinitions.listAll()}
			segmentNames={value.get('same')}
			width={width}
		/>
	);
};



export default React.memo(SegmentsDiffFormatter);
