import React from 'react';

import type CK from '~/types/contentking';

import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';
import useAccountFixedBillingEntity from '~/hooks/useAccountFixedBillingEntity';
import useAccountSignup from '~/hooks/useAccountSignup';
import useBillingEntities from '~/hooks/useBillingEntities';



function useSignupBillingEntity(accountId: CK.AccountId | null): string | null {
	const accountBillingEntity = useAccountBillingEntity(accountId);
	const accountFixedBillingEntity = useAccountFixedBillingEntity(accountId);
	const accountSignup = useAccountSignup(accountId);
	const billingEntities = useBillingEntities();

	const country = accountSignup.customerDetails.country;

	return React.useMemo(
		() => {
			if (accountFixedBillingEntity !== null) {
				return accountFixedBillingEntity;
			}

			if (billingEntities !== null && country !== null) {
				return billingEntities.getByCountry(country);
			}

			return accountBillingEntity;
		},
		[
			accountBillingEntity,
			accountFixedBillingEntity,
			billingEntities,
			country,
		],
	);
}



export default useSignupBillingEntity;
