import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import CustomElementFormatter from '~/components/logic/formatters/CustomElementFormatter';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import DefinitionTermValueSkeleton from '~/components/logic/pageDetail/components/DefinitionTermValueSkeleton';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LinkTarget from '~/components/logic/LinkTarget';
import MissingValue from '~/components/app/MissingValue';
import RealtimeValueUpdateHighlight from '~/components/patterns/values/RealtimeValueUpdateHighlight';
import RichText from '~/components/patterns/typography/RichText';

import {
	useCustomElementsBlockQuery,
} from './CustomElementsBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	noDefinitionsLink: {
		id: 'ui.pageDetail.customElements.noDefinitions.link',
	},
	noDefinitionsText: {
		id: 'ui.pageDetail.customElements.noDefinitions.text',
	},
	title: {
		id: 'ui.pageDetail.customElements.title',
	},
});



const CustomElementsBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);

	const { data } = usePageDetailPropertiesQuery(
		useCustomElementsBlockQuery,
		legacyUrlId,
		websiteId,
	);

	const customElementsData = data?.lookupPageByLegacyId?.pageTypeData?.customElements ?? null;

	return (
		<BorderedBox
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.CustomElements}
				/>
			)}
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			paddingSize={3}
		>
			{customElementDefinitions.count > 0 && (
				<DefinitionTermsList>
					{customElementDefinitions.listAll().map((customElement) => {
						const customElementName = customElement.name;
						const customElementData = customElementsData?.find((customElementData) => customElementData.type.name === customElementName) ?? null;

						const customElementIsExtracted = customElementData?.isExtracted === true;
						const customElementValue = customElementData?.value ?? null;

						return (
							<DefinitionTerm
								description={customElementsData !== null ? (
									<RealtimeValueUpdateHighlight
										value={customElementValue}
									>
										<CustomElementFormatter
											column={'custom_' + customElementName}
											customElements={customElementIsExtracted}
											useTextInspector={true}
											value={customElementValue}
										/>
									</RealtimeValueUpdateHighlight>
								) : (
									<DefinitionTermValueSkeleton />
								)}
								key={customElementName}
								term={customElement.label}
							/>
						);
					})}
				</DefinitionTermsList>
			)}

			{customElementDefinitions.count === 0 && (
				<RichText>
					<MissingValue>
						<FormattedMessage
							{...messages.noDefinitionsText}
							values={{
								text__link: (
									<LinkTarget
										routeName="website.detail.customElements"
										routeParams={{
											action: 'create_custom_element',
											websiteId,
										}}
									>
										{({ routeName, routeParams }) => (
											<InternalLink
												routeName={routeName}
												routeParams={routeParams}
												style={InternalLinkStyle.Decent}
											>
												<FormattedMessage {...messages.noDefinitionsLink} />
											</InternalLink>
										)}
									</LinkTarget>
								),
							}}
						/>
					</MissingValue>
				</RichText>
			)}
		</BorderedBox>
	);
};



export default CustomElementsBlock;
