import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import HighlightedDate from '~/components/patterns/time/HighlightedDate';
import PureRadioFields, {
	SIZE_SMALL as RADIO_SIZE_SMALL,
} from '~/components/atoms/forms/components/builders/PureRadioFields';
import RobotsTxtStatusMessage from '~/components/app/RobotsTxtStatusMessage';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import Version, {
	VersionHighlightStyle,
} from '~/components/patterns/time/versionsOverview/Version';
import VersionsComparisonButton from '~/components/patterns/time/versionsOverview/VersionsComparisonButton';
import VersionsOverview from '~/components/patterns/time/versionsOverview/VersionsOverview';

import {
	useRobotsTxtComparisonSidebarQuery,
} from '~/components/app/RobotsTxtComparisonSidebar.gql';

import useBatchContextRobotsTxt from '~/hooks/useBatchContextRobotsTxt';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const DEFAULT_REVISIONS_LIST = [];

const messages = defineMessages({
	liveVersion: {
		id: 'ui.robotsTxtComparisonSidebar.liveVersion',
	},
});



type Props = {
	nextRevisionId: CK.ID | null,
	onComparisonSelect: (options: {
		nextRevisionId: CK.ID,
		previousRevisionId: CK.ID,
	}) => void,
	previousRevisionId: CK.ID | null,
};

const RobotsTxtComparisonSidebar: React.FC<Props> = (props) => {
	const {
		nextRevisionId,
		onComparisonSelect,
		previousRevisionId,
	} = props;

	const websiteId = useWebsiteId();
	const isCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId);
	const viewportType = useViewportType();

	const [hoveredRevisions, setHoveredRevisions] = React.useState<Array<CK.ID>>([]);

	const { data, loading } = useRobotsTxtComparisonSidebarQuery({
		context: useBatchContextRobotsTxt(websiteId),
		variables: {
			websiteId,
		},
	});

	const revisionsList = data?.list ?? DEFAULT_REVISIONS_LIST;
	const latestRevisionId = data?.latest?.id ?? null;

	const nextList = React.useMemo(
		() => {
			return revisionsList.map((revision) => ({
				checked: revision.id === nextRevisionId,
				disabled: revision.id === previousRevisionId,
				value: revision.id,
			}));
		},
		[
			nextRevisionId,
			previousRevisionId,
			revisionsList,
		],
	);

	const previousList = React.useMemo(
		() => {
			return revisionsList.map((revision) => ({
				checked: revision.id === previousRevisionId,
				disabled: revision.id === nextRevisionId,
				value: revision.id,
			}));
		},
		[
			nextRevisionId,
			previousRevisionId,
			revisionsList,
		],
	);

	return (
		<VersionsOverview>
			{loading && revisionsList.length === 0 && (
				<>
					<Version
						date={(
							<SquareSkeleton
								height={16}
								width={135}
							/>
						)}
						isHighlighted={true}
					/>
					<Version
						date={(
							<SquareSkeleton
								height={16}
								width={135}
							/>
						)}
					/>
				</>
			)}

			<PureRadioFields
				isControlled={true}
				items={nextList}
				name="nextList"
				onChangeCallback={(revisionId) => {
					if (previousRevisionId === null) {
						return;
					}

					onComparisonSelect({
						nextRevisionId: revisionId,
						previousRevisionId,
					});
				}}
				size={RADIO_SIZE_SMALL}
			>
				{({ fields: nextFields }) => (
					<PureRadioFields
						isControlled={true}
						items={previousList}
						name="previousList"
						onChangeCallback={(revisionId) => {
							if (nextRevisionId === null) {
								return;
							}

							onComparisonSelect({
								nextRevisionId,
								previousRevisionId: revisionId,
							});
						}}
						size={RADIO_SIZE_SMALL}
					>
						{({ fields: prevFields }) => (
							revisionsList.map((revision, index) => {
								const showStatusMessage = (
									revision.responseStatusCode === null
									|| revision.responseStatusCode >= 300
									|| revision.responseFailureReason !== null
									|| revision.isContentEmpty
								);

								const showLiveVersionFlag = (
									revision.id === latestRevisionId
									&& !isCrawlingPaused
								) && !showStatusMessage;

								const isHovered = hoveredRevisions.includes(revision.id);
								const isSelected = revision.id === nextRevisionId || revision.id === previousRevisionId;

								return (
									<>
										{index > 0 && !viewportType.isSmall && (
											<VersionsComparisonButton
												key={`comparison-button-${index}`}
												label="Compare these versions"
												onButtonClick={() => {
													onComparisonSelect({
														nextRevisionId: getArrayItemAtSafeIndex(revisionsList, index - 1).id,
														previousRevisionId: getArrayItemAtSafeIndex(revisionsList, index).id,
													});
												}}
												onMouseEnter={() => {
													setHoveredRevisions([
														getArrayItemAtSafeIndex(revisionsList, index - 1).id,
														getArrayItemAtSafeIndex(revisionsList, index).id,
													]);
												}}
												onMouseLeave={() => {
													setHoveredRevisions([]);
												}}
											/>
										)}
										<Version
											alert={showStatusMessage && (
												<RobotsTxtStatusMessage
													failureReason={revision.responseFailureReason}
													isContentEmpty={revision.isContentEmpty}
													isLatestRevision={revision.id === latestRevisionId}
													shortMessage={true}
													statusCode={revision.responseStatusCode}
												/>
											)}
											date={(
												<HighlightedDate timestamp={revision.createdAt} />
											)}
											flag={showLiveVersionFlag && (
												<FormattedMessage {...messages.liveVersion} />
											)}
											highlightStyle={(
												isHovered ? (
													VersionHighlightStyle.Hover
												) : isSelected ? (
													VersionHighlightStyle.Comparison
												) : (
													VersionHighlightStyle.Decent
												)
											)}
											isHighlighted={isSelected || isHovered}
											key={revision.id}
											radioElements={[
												nextFields[index].field,
												prevFields[index].field,
											]}
										/>
									</>
								);
							})
						)}
					</PureRadioFields>
				)}
			</PureRadioFields>
		</VersionsOverview>
	);
};



export default RobotsTxtComparisonSidebar;
