import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	IsConductorClient,
} from '~/config';

import {
	validateField,
} from '~/components/app/validations';

import {
	useUpdateUserProfileMutation,
} from './PersonalSettingsForm.gql';

import useIsAllowedWithUser from '~/hooks/useIsAllowedWithUser';
import useUserEmail from '~/hooks/useUserEmail';
import useUserFirstName from '~/hooks/useUserFirstName';
import useUserJobTitle from '~/hooks/useUserJobTitle';
import useUserLastName from '~/hooks/useUserLastName';



const messages = defineMessages({
	emailLabel: {
		id: 'ui.settings.form.emailLabel',
	},
	firstNameLabel: {
		id: 'ui.settings.form.firstNameLabel',
	},
	jobLabel: {
		id: 'ui.settings.form.jobLabel',
	},
	lastNameLabel: {
		id: 'ui.settings.form.lastNameLabel',
	},
	personalSettingsTitle: {
		id: 'ui.settings.personalSettings.title',
	},
});

const validations = {
	validateFirstName: validateField(
		'firstName',
		(f) => [
			f.validateNonEmpty(),
			f.validateMaximumLength(100),
		],
	),
	validateJobTitle: validateField(
		'jobTitle',
		(f) => [
			f.validateNonEmpty(),
			f.validateMaximumLength(50),
		],
	),
	validateLastName: validateField(
		'lastName',
		(f) => [
			f.validateNonEmpty(),
			f.validateMaximumLength(100),
		],
	),
};



type Props = {
	userId: string | null,
};

const PersonalSettingsForm: React.FC<Props> = (props) => {
	const {
		userId,
	} = props;

	const userEmail = useUserEmail(userId);
	const userFirstName = useUserFirstName(userId);
	const userJobTitle = useUserJobTitle(userId);
	const userLastName = useUserLastName(userId);

	const isAllowedToManageUser = useIsAllowedWithUser(userId, GraphQL.ActionWithUser.ManagePersonalDetails);

	const [updateUserProfile] = useUpdateUserProfileMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (userId === null) {
				return;
			}

			await updateUserProfile({
				variables: {
					firstName: values.firstName,
					jobTitle: values.jobTitle,
					lastName: values.lastName,
					legacyUserId: userId,
				},
			});
		},
		[
			updateUserProfile,
			userId,
		],
	);

	function renderOverview() {
		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.firstNameLabel} />
					)}
				>
					<StaticText focusTarget="firstName">
						{userFirstName ?? ''}
					</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.lastNameLabel} />
					)}
				>
					<StaticText focusTarget="lastName">
						{userLastName ?? ''}
					</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.emailLabel} />
					)}
				>
					<StaticText width="auto">
						{userEmail ?? ''}
					</StaticText>
				</FormRow>

				{IsConductorClient === false && (
					<FormRow
						label={(
							<FormattedMessage {...messages.jobLabel} />
						)}
					>
						<StaticText focusTarget="jobTitle">
							{userJobTitle ?? ''}
						</StaticText>
					</FormRow>
				)}
			</FormRows>
		);
	}

	function renderForm() {
		return (
			<Form
				defaultFocus="firstName"
				defaultValues={{
					firstName: userFirstName ?? '',
					jobTitle: userJobTitle,
					lastName: userLastName ?? '',
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRows>
					<FormRow
						htmlFor="firstName"
						label={(
							<FormattedMessage {...messages.firstNameLabel} />
						)}
					>
						<FieldStatus name="validateFirstName">
							<TextField name="firstName" />
						</FieldStatus>
					</FormRow>

					<FormRow
						htmlFor="lastName"
						label={(
							<FormattedMessage {...messages.lastNameLabel} />
						)}
					>
						<FieldStatus name="validateLastName">
							<TextField name="lastName" />
						</FieldStatus>
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.emailLabel} />
						)}
					>
						<StaticText width="auto">
							{userEmail ?? ''}
						</StaticText>
					</FormRow>

					{IsConductorClient === false && (
						<FormRow
							htmlFor="jobTitle"
							label={(
								<FormattedMessage {...messages.jobLabel} />
							)}
						>
							<FieldStatus name="validateJobTitle">
								<TextField name="jobTitle" />
							</FieldStatus>
						</FormRow>
					)}
				</FormRows>

				<FormErrorMessages />

				<ButtonsLayout>
					<CancelButton />
					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		);
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageUser}
			title={(
				<FormattedMessage {...messages.personalSettingsTitle} />
			)}
		>
			<DisplayPart>
				{renderOverview()}
			</DisplayPart>

			<EditablePart>
				{renderForm()}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default PersonalSettingsForm;
