import React from 'react';

import FormFieldsContext from '~/components/atoms/forms/basis/FormFieldsContext';

import {
	validateField,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';
import useFormFieldsContext from '~/hooks/useFormFieldsContext';
import useFormValidations from '~/hooks/useFormValidations';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import {
	type Rule,
	type Values,
} from '~/utilities/validations';



export type CompositeFieldValidationInput = {
	f: Parameters<Parameters<typeof validateField>[1]>[0],
	getValue: (values: Values, fieldName: string) => any,
};

type Field = {
	defaultValue?: any,
	name: string,
	validation?: (input: CompositeFieldValidationInput) => ReadonlyArray<Rule>,
};

function formatFieldName(
	name: string,
	field: Field,
) {
	return name + '/' + field.name;
}

const fallbackDefaultValue = {};
const fallbackValidation = () => [];



type Props = {
	children: RenderProp<{
		getFieldName: (field: string) => string,
	}>,
	fields: ReadonlyArray<Field>,
	name: string,
};

const CompositeField: React.FC<Props> = (props) => {
	const {
		children,
		fields,
		name,
	} = props;

	const formContext = useFormContext();

	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;
	const formContextValuesSelector = formContext.valuesSelector;

	const formDefaultValue = formContext.defaultValues[name] ?? fallbackDefaultValue;

	const internalFormFields = React.useMemo(
		() => {
			return fields.map(
				(field) => ({
					defaultValue: formDefaultValue[field.name] ?? field.defaultValue,
					name: formatFieldName(name, field),
				}),
			);
		},
		[
			fields,
			formDefaultValue,
			name,
		],
	);

	const formFieldsContext = useFormFieldsContext(internalFormFields);

	const formFieldsContextValues = formFieldsContext.state.values;

	useFormValidations(
		name,
		React.useCallback(
			() => Object.fromEntries(
				fields.map(
					(field) => {
						const fieldName = formatFieldName(name, field);

						const getValue = (values: Values, fieldName: string) => {
							return formContextValuesSelector(values)[name]?.[fieldName];
						};

						const fieldValidation = field.validation ?? fallbackValidation;

						return [
							fieldName,
							validateField(fieldName, (f) => {
								f.setValueSelector(
									(values) => getValue(values, field.name),
								);

								return fieldValidation({ f, getValue });
							}),
						];
					},
				),
			),
			[
				fields,
				formContextValuesSelector,
				name,
			],
		),
	);

	React.useEffect(
		() => {
			formContextOnChangeHandler(
				name,
				Object.fromEntries(
					fields.map(
						(field) => [
							field.name,
							formFieldsContextValues[formatFieldName(name, field)],
						],
					),
				),
				{
					interacted: false,
				},
			);
		},
		[
			fields,
			formContextOnChangeHandler,
			formFieldsContextValues,
			name,
		],
	);

	React.useEffect(
		() => {
			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnUnmountHandler,
			name,
		],
	);

	const childrenInput = React.useMemo(
		() => {
			const fieldNames = Object.fromEntries(
				fields.map(
					(field) => ([
						field.name,
						formatFieldName(name, field),
					]),
				),
			);

			return {
				getFieldName: (field: string) => {
					const fieldName = fieldNames[field];

					if (fieldName === undefined) {
						throw new Error(`Unknown field '${field}'`);
					}

					return fieldName;
				},
			};
		},
		[
			fields,
			name,
		],
	);

	const valuesSelector = React.useCallback(
		(values: Values) => {
			values = formContextValuesSelector(values);

			return Object.fromEntries(
				fields.map((field) => {
					return [
						formatFieldName(name, field),
						values[name]?.[field.name],
					];
				}),
			);
		},
		[
			fields,
			formContextValuesSelector,
			name,
		],
	);

	const updatedFormContext = React.useMemo(
		() => {
			return {
				...formFieldsContext.contextForProvider,
				valuesSelector,
			};
		},
		[
			formFieldsContext.contextForProvider,
			valuesSelector,
		],
	);

	return (
		<FormFieldsContext context={updatedFormContext}>
			{renderProp(children, childrenInput)}
		</FormFieldsContext>
	);
};



export default CompositeField;
