import classNames from 'classnames';
import React from 'react';

import styles from './RealtimeValueUpdateHighlight.module.scss';



const HIGHLIGHT_TIMEOUT = 5000;

enum RealtimeValueUpdateHighlightState {
	Added = 'added',
	Changed = 'changed',
	NoHighlight = 'no-highlight',
	Removed = 'removed',
}

type Props = {
	children?: React.ReactNode,
	/** Disable value update highlight - for example when data are loading */
	disabled?: boolean,
	/** Value reflecting data which can be changed */
	value?: React.ReactNode,
};



const RealtimeValueUpdateHighlight: React.FC<Props> = (props) => {
	const {
		children,
		disabled = false,
		value,
	} = props;

	const previousDisabled = React.useRef<boolean>();
	const previousValue = React.useRef<React.ReactNode>();

	const [currentHighlight, setCurrentHighlight] = React.useState(RealtimeValueUpdateHighlightState.NoHighlight);

	const highlightTimeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

	React.useEffect(
		() => () => {
			if (highlightTimeoutRef.current !== null) {
				clearTimeout(highlightTimeoutRef.current);
			}
		},
		[],
	);

	React.useEffect(
		() => {
			if (
				disabled
				|| previousDisabled.current
				|| previousDisabled.current === undefined
			) {
				previousDisabled.current = disabled;
				previousValue.current = value;

				return;
			}

			if (highlightTimeoutRef.current !== null) {
				clearTimeout(highlightTimeoutRef.current);
			}

			if (value === null || value === false || value === undefined) {
				setCurrentHighlight(RealtimeValueUpdateHighlightState.Removed);
			} else if (previousValue.current === null || previousValue.current === false || previousValue.current === undefined) {
				setCurrentHighlight(RealtimeValueUpdateHighlightState.Added);
			} else {
				setCurrentHighlight(RealtimeValueUpdateHighlightState.Changed);
			}

			highlightTimeoutRef.current = setTimeout(
				() => setCurrentHighlight(RealtimeValueUpdateHighlightState.NoHighlight),
				HIGHLIGHT_TIMEOUT,
			);

			previousDisabled.current = disabled;
			previousValue.current = value;
		},
		[
			disabled,
			value,
		],
	);

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.addedHighlight]: currentHighlight === RealtimeValueUpdateHighlightState.Added,
		[styles.changedHighlight]: currentHighlight === RealtimeValueUpdateHighlightState.Changed,
		[styles.removedHighlight]: currentHighlight === RealtimeValueUpdateHighlightState.Removed,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default RealtimeValueUpdateHighlight;
