import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CustomMonitoringLocationIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M27.3723 18.3675C27.0526 20.936 25.9693 23.3495 24.2625 25.2954C22.5557 27.2413 20.3041 28.6301 17.7992 29.2819C15.2942 29.9337 12.6513 29.8186 10.2125 28.9514C7.77372 28.0842 5.65142 26.5048 4.12036 24.4178C2.5893 22.3309 1.71997 19.8323 1.62493 17.2457C1.52989 14.6591 2.21351 12.1035 3.58729 9.90974C4.96107 7.71603 6.96174 5.98521 9.3303 4.94137C11.6989 3.89753 14.3262 3.58873 16.8723 4.05496"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M15.577 7.67752C15.2245 7.70502 14.872 7.71752 14.5195 7.71752C12.0672 7.73043 9.65417 7.10217 7.51953 5.89502"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M7.54492 27.7C9.68482 26.5061 12.0945 25.8794 14.5449 25.8794C16.9953 25.8794 19.405 26.5061 21.5449 27.7"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M1.5752 16.79H18.6927"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M15.915 12.2875H15.25C10.0725 12.2875 5.54246 11.665 3.08496 10.73"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M26.1652 22.3824C23.5727 21.6674 19.6452 21.2124 15.2627 21.2124C10.0827 21.2124 5.55516 21.8374 3.09766 22.7699"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.5225 4.03003V29.75"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M21.0675 19.9001C20.3375 25.5326 17.6825 29.7101 14.5225 29.7101C10.7725 29.7101 7.77246 23.9151 7.77246 16.7626C7.77246 10.0876 10.4025 4.59514 13.7725 3.89014"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M23.915 4.8726C23.0531 4.8726 22.2264 5.21501 21.6169 5.8245C21.0074 6.43399 20.665 7.26064 20.665 8.1226V8.1226C20.665 8.76423 20.855 9.3915 21.2109 9.92537C21.5668 10.4592 22.0728 10.8758 22.665 11.1226C23.0608 11.2888 23.4858 11.3738 23.915 11.3726V11.3726C24.3443 11.3739 24.7694 11.2889 25.165 11.1226C25.5605 10.9595 25.9199 10.7199 26.2225 10.4176V10.4176C26.8334 9.80491 27.1767 8.97526 27.1775 8.1101V8.1101C27.1755 7.24591 26.8323 6.41748 26.2225 5.8051V5.8051C25.6106 5.19464 24.7819 4.85128 23.9175 4.8501L23.915 4.8726Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M23.9128 1.60986C22.183 1.61048 20.5241 2.2974 19.3003 3.51986V3.51986C18.0753 4.74263 17.3857 6.40155 17.3828 8.13236V8.72486C17.3863 10.0989 17.8238 11.4367 18.6328 12.5474L23.9053 19.8549L29.1878 12.5474C29.9969 11.4367 30.4343 10.0989 30.4378 8.72486V8.13236C30.437 6.40309 29.7512 4.74457 28.5303 3.51986V3.51986C27.3051 2.29641 25.6443 1.60943 23.9128 1.60986V1.60986Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default CustomMonitoringLocationIconBuilder;

