import Immutable, {
	List,
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
} from '~/actions';

import {
	selectedWebsiteIdSelector,
} from './ui/selectors';

import {
	coverSpan,
	createCoverage,
	getPatch,
} from '~/utilities/coverage';



function createDefaultState() {
	return Map({
		data: Map(),
		websiteId: null,
	});
}



function createDefaultWebsite(websiteId) {
	return Map({
		coverage: createCoverage(),
		loaded: false,
		range: List(),
		records: Map(),
		total: null,
		websiteId,
	});
}



export function createState(name, loadFunction, ttl = null) {
	const STORE_ACTION = name + '/store';



	const selector = (state) => state.get(name);

	const dataSelector = (state) => selector(state).get('data');

	const currentDataSelector = (state) => dataSelector(state).get(selectedWebsiteIdSelector(state));



	const loadData = () => {
		return (dispatch, getState) => {
			const websiteId = selectedWebsiteIdSelector(getState());

			if (!websiteId) {
				return Promise.resolve();
			}

			const currentData = currentDataSelector(getState());

			const patch = getPatch(
				currentData ? currentData.get('coverage') : createCoverage(),
				0,
				1,
			);

			if (patch.coverSpan === 0) {
				return Promise.resolve();
			}

			return loadFunction(websiteId)
				.then(
					(data) => {
						dispatch({
							data,
							type: STORE_ACTION,
							websiteId,
						});
					},
				);
		};
	};



	const reducer = (state, action) => {
		if (state === undefined) {
			state = createDefaultState();
		}

		switch (action.type) {

			case CHANGE_URL_STATE: {
				const {
					urlState,
				} = action;

				if (
					urlState.params.websiteId
					&& !state.get('data').has(urlState.params.websiteId)
				) {
					state = state.setIn(
						['data', urlState.params.websiteId],
						createDefaultWebsite(urlState.params.websiteId),
					);
				}

				break;
			}

			case STORE_ACTION: {
				const {
					websiteId,
					data,
				} = action;

				const offset = 0;

				if (!state.get('data').has(websiteId)) {
					state = state.setIn(
						['data', websiteId],
						createDefaultWebsite(websiteId),
					);
				}

				state = state.setIn(
					['data', websiteId, 'total'],
					data.total,
				);
				state = state.setIn(
					['data', websiteId, 'loaded'],
					true,
				);
				state = state.setIn(
					['data', websiteId, 'range'],
					state.get('data').get(websiteId).get('range').withMutations((mutableRange) => {
						for (const index in data.url_ids) {
							mutableRange.set(offset + parseInt(index, 10), data.url_ids[index]);
						}
					}),
				);
				state = state.setIn(
					['data', websiteId, 'coverage'],
					coverSpan(
						state.getIn(['data', websiteId, 'coverage']),
						0,
						1,
						ttl ? (Date.now() / 1000 + ttl) : Infinity,
					),
				);
				state = state.setIn(
					['data', websiteId, 'records'],
					state.get('data').get(websiteId).get('records').withMutations((mutableRecords) => {
						for (const index in data.urls) {
							const recordId = data.urls[index].id.toString();

							if (mutableRecords.has(recordId)) {
								mutableRecords.set(
									recordId,
									mutableRecords.get(recordId).merge(data.urls[index]),
								);
							} else {
								mutableRecords.set(
									recordId,
									Immutable.fromJS(data.urls[index]),
								);
							}
						}
					}),
				);

				break;
			}

		}

		return state;
	};



	return {
		loadData,
		reducer,
		selector: currentDataSelector,
	};
}



export function getRecord(currentData, rowIndex) {
	return currentData.getIn([
		'records',
		currentData.getIn(['range', rowIndex]).toString(),
	]);
}
