import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy from '~/components/logic/Copy';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const messages = defineMessages({
	description: {
		id: 'ui.members.new.success.description',
	},
	finish: {
		id: 'ui.members.new.success.finish',
	},
	heading: {
		id: 'ui.members.new.success.heading',
	},
	title: {
		id: 'ui.members.new.success.title',
	},
});



type Props = {
	emails: Array<string>,
};

const AddMemberModalSuccessStep: React.FC<Props> = (props) => {
	const {
		emails,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();

	return (
		<MultiStepModalStep
			name="success"
			title={(
				<FormattedMessage
					{...messages.title}
					values={{ emails: emails.length }}
				/>
			)}
		>
			{({ isActive }) => (isActive ? (
				<ConfirmationMessage
					animate={true}
					context={ConfirmationMessageContext.EmailSent}
					ctaButtons={(
						<Button
							onClick={classicFormBehavior.finish}
							style={ButtonStyle.Action}
							uppercase={false}
							width={ButtonWidth.SubmitButton}
						>
							<FormattedMessage {...messages.finish} />
						</Button>
					)}
					message={(
						<FormattedMessage
							{...messages.heading}
							values={{ emails: emails.length }}
						/>
					)}
				>
					<Copy
						{...messages.description}
						values={{
							email: emails[0],
							emails: emails.length,
						}}
					/>
				</ConfirmationMessage>
			) : null)}
		</MultiStepModalStep>
	);
};



export default AddMemberModalSuccessStep;
