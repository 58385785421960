import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import MultiselectTableField from '../../../logic/formFields/MultiselectTableField';
import Ellipsis from '~/components/patterns/values/Ellipsis';



class CheckListTable extends Component {

	constructor(props, context) {
		super(props, context);

		this._getValueFromItem = this._getValueFromItem.bind(this);
		this._renderCell = this._renderCell.bind(this);
		this._renderHeader = this._renderHeader.bind(this);
	}



	_getValueFromItem(item) {
		return item.value;
	}



	_renderCell({ columnIndex, rowIndex }) {
		const {
			items,
		} = this.props;

		if (columnIndex > 0) {
			return false;
		}

		return (
			<Ellipsis>
				{items[rowIndex].label}
			</Ellipsis>
		);
	}



	_renderHeader() {
		const {
			label,
		} = this.props;

		return label;
	}



	render() {
		const {
			items,
			name,
			tableHeight,
		} = this.props;

		return (
			<MultiselectTableField
				cellRenderer={this._renderCell}
				columnCount={1}
				columnWidth={400}
				headerRenderer={this._renderHeader}
				items={items}
				name={name}
				ref={(ref) => this.tableRef = ref}
				tableHeight={tableHeight}
				valueGetter={this._getValueFromItem}
			/>
		);
	}

}

CheckListTable.propTypes = {
	items: PropTypes.array.isRequired,
	label: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired,
	/** Possibility to change default table height */
	tableHeight: PropTypes.number,
};



export default CheckListTable;
