import Immutable from 'immutable';



const NAME = 'paymentAuthorization';

const STATE_AUTHORIZING = 'authorizing';
const STATE_FAILED = 'failed';
const STATE_INACTIVE = 'inactive';



function createDefaultState() {
	return Immutable.Map({
		actionToken: null,
		resultToken: null,
		state: STATE_INACTIVE,
		useModal: null,
	});
}



function createState() {
	const selector = (state) => state.get(NAME);

	const ACTION_CANCEL = NAME + '/' + 'cancel';
	const ACTION_FAIL = NAME + '/' + 'fail';
	const ACTION_FINISH = NAME + '/' + 'finish';
	const ACTION_INITIATE = NAME + '/' + 'initiate';

	const reducer = (state, action) => {
		if (state === undefined) {
			state = createDefaultState();
		}

		switch (action.type) {

			case ACTION_CANCEL: {
				state = createDefaultState();

				break;
			}

			case ACTION_FAIL: {
				state = state.set('resultToken', null);
				state = state.set('state', STATE_FAILED);

				break;
			}

			case ACTION_FINISH: {
				const {
					token,
				} = action;

				state = state.set('actionToken', null);
				state = state.set('resultToken', token);

				break;
			}

			case ACTION_INITIATE: {
				const {
					token,
					useModal,
				} = action;

				state = state.set('actionToken', token);
				state = state.set('state', STATE_AUTHORIZING);
				state = state.set('useModal', useModal);

				break;
			}

		}

		return state;
	};

	return {
		actionTokenSelector: (state) => selector(state).get('actionToken'),
		cancelAuthorization: () => {
			return {
				type: ACTION_CANCEL,
			};
		},
		failAuthorization: () => {
			return {
				type: ACTION_FAIL,
			};
		},
		finishAuthorization: (token) => {
			return {
				type: ACTION_FINISH,
				token,
			};
		},
		initiateAuthorization: (token, useModal) => {
			return {
				type: ACTION_INITIATE,
				token,
				useModal,
			};
		},
		isActiveSelector: (state) => selector(state).get('state') === STATE_AUTHORIZING,
		isAuthorizedSelector: (state) => !!selector(state).get('resultToken'),
		reducer,
		resultTokenSelector: (state) => selector(state).get('resultToken'),
		useModalSelector: (state) => selector(state).get('useModal'),
	};
}



export const {
	actionTokenSelector,
	cancelAuthorization,
	failAuthorization,
	finishAuthorization,
	initiateAuthorization,
	isActiveSelector,
	isAuthorizedSelector,
	reducer,
	resultTokenSelector,
	useModalSelector,
} = createState();
