import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import AttachedHeading from '../../../patterns/structuredValues/AttachedHeading';
import CrossfadeAnimation from '~/components/patterns/animations/CrossfadeAnimation';
import CustomerLogo from '~/components/patterns/images/customerLogos/CustomerLogo';
import CustomerLogosList from '~/components/patterns/images/customerLogos/CustomerLogosList';
import DecentText, {
	DecentTextAlignment,
} from '~/components/patterns/typography/DecentText';
import WidthLimiter from '~/components/patterns/utils/WidthLimiter';

import {
	type Screen,
	listSatisfiedCustomerLogos,
} from '~/model/satisfiedCustomers';



const messages = defineMessages({
	title: {
		id: 'ui.signup.satisfiedCustomers.title',
	},
});



type Props = {
	customerType?: GraphQL.CustomerType | null,
	grayscale?: boolean,
	screen?: Screen | null,
};

const SatisfiedCustomers: React.FC<Props> = (props) => {
	const {
		customerType,
		grayscale = false,
		screen,
	} = props;

	const logoGroupings = listSatisfiedCustomerLogos(customerType, screen);

	return (
		<AttachedHeading
			heading={(
				<DecentText
					alignment={DecentTextAlignment.Center}
					strong={true}
				>
					<FormattedMessage {...messages.title} />
				</DecentText>
			)}
		>
			<WidthLimiter width={230}>
				<CrossfadeAnimation>
					{logoGroupings.map((grouping, i) => (
						<CustomerLogosList key={i}>
							{grouping.map((customer, j) => (
								<CustomerLogo
									grayscale={grayscale}
									key={j}
									name={customer.name}
									src={customer.logo}
								/>
							))}
						</CustomerLogosList>
					))}
				</CrossfadeAnimation>
			</WidthLimiter>
		</AttachedHeading>
	);
};



export default SatisfiedCustomers;
