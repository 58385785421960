import classNames from 'classnames';
import React from 'react';



export enum StatusLabelType {
	Alert = 'alert',
	Disabled = 'disabled',
	Success = 'success',
	Warning = 'warning',
}

type Props = {
	children?: React.ReactNode,
	type: StatusLabelType,
};


const StatusLabel: React.FC<Props> = (props) => {
	const {
		children,
		type,
	} = props;

	return (
		<div
			className={classNames({
				'status-label': true,
				['status-label--' + type]: true,
			})}
		>
			{children}
		</div>
	);
};



export default StatusLabel;
