import React from 'react';

import {
	type FormOnChangeHandlerOptions,
} from '~/components/atoms/forms/basis/Form';

import useFormContext from '~/hooks/useFormContext';

import {
	type Values,
} from '~/utilities/validations';



type Field = {
	defaultValue: any,
	name: string,
};

export type FormFieldsContext = {
	focused: string | null,
	interacted: Record<string, true>,
	values: Values,
};



function useFormFieldsContext(fields: ReadonlyArray<Field>) {
	const formContext = useFormContext();

	const formContextOnBlurHandler = formContext.onBlurHandler;
	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnFocusHandler = formContext.onFocusHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const defaultValues = React.useMemo(
		() => {
			return Object.fromEntries(
				fields.map((field) => ([
					field.name,
					field.defaultValue,
				])),
			);
		},
		[
			fields,
		],
	);

	const fieldNamesRef = React.useRef<Array<string>>([]);
	fieldNamesRef.current = fields.map(
		(field) => field.name,
	);

	const [state, setState] = React.useState<FormFieldsContext>(
		() => {
			return {
				focused: null,
				interacted: {},
				values: { ...defaultValues },
			};
		},
	);

	React.useEffect(
		() => {
			const newValues = { ...state.values };

			let modify = false;

			for (const field of fields) {
				if (newValues.hasOwnProperty(field.name) === false) {
					newValues[field.name] = field.defaultValue;
					modify = true;
				}
			}

			if (modify) {
				setState(
					(state) => ({
						...state,
						values: newValues,
					}),
				);
			}
		},
		[
			fields,
			state,
		],
	);

	const updatedOnBlurHandler = React.useCallback(
		(fieldName: string) => {
			if (fieldNamesRef.current.includes(fieldName) === false) {
				return formContextOnBlurHandler(fieldName);
			}

			setState(
				(state) => ({
					...state,
					focused: null,
					interacted: {
						...state.interacted,
						[fieldName]: true,
					},
				}),
			);
		},
		[
			formContextOnBlurHandler,
		],
	);

	const updatedOnChangeHandler = React.useCallback(
		(fieldName: string, value: any, options: FormOnChangeHandlerOptions = {}) => {
			if (fieldNamesRef.current.includes(fieldName) === false) {
				return formContextOnChangeHandler(fieldName, value, options);
			}

			setState(
				(state) => ({
					...state,
					interacted: {
						...state.interacted,
						[fieldName]: true,
					},
					values: {
						...state.values,
						[fieldName]: value,
					},
				}),
			);

			return Promise.resolve();
		},
		[
			formContextOnChangeHandler,
		],
	);

	const updatedOnFocusHandler = React.useCallback(
		(fieldName: string) => {
			if (fieldNamesRef.current.includes(fieldName) === false) {
				return formContextOnFocusHandler(fieldName);
			}

			setState(
				(state) => ({
					...state,
					focused: fieldName,
				}),
			);
		},
		[
			formContextOnFocusHandler,
		],
	);

	const updatedOnMountHandler = React.useCallback(
		() => {},
		[],
	);

	const updatedOnUnmountHandler = React.useCallback(
		(fieldName: string) => {
			if (fieldNamesRef.current.includes(fieldName) === false) {
				return formContextOnUnmountHandler(fieldName);
			}

			setState(
				(state) => {
					const updatedInteracted = { ...state.interacted };
					delete updatedInteracted[fieldName];

					const updatedValues = { ...state.values };
					delete updatedValues[fieldName];

					return {
						...state,
						interacted: updatedInteracted,
						values: updatedValues,
					};
				},
			);
		},
		[
			formContextOnUnmountHandler,
		],
	);

	const contextForProvider = React.useMemo(
		() => {
			return {
				...formContext,
				defaultValues: {
					...formContext.defaultValues,
					...defaultValues,
				},
				focused: state.focused ?? formContext.focused,
				interacted: {
					...formContext.interacted,
					...state.interacted,
				},
				onBlurHandler: updatedOnBlurHandler,
				onChangeHandler: updatedOnChangeHandler,
				onFocusHandler: updatedOnFocusHandler,
				onMountHandler: updatedOnMountHandler,
				onUnmountHandler: updatedOnUnmountHandler,
				values: {
					...formContext.values,
					...state.values,
				},
			};
		},
		[
			defaultValues,
			formContext,
			state,
			updatedOnBlurHandler,
			updatedOnChangeHandler,
			updatedOnFocusHandler,
			updatedOnMountHandler,
			updatedOnUnmountHandler,
		],
	);

	const publishedState = React.useMemo(
		() => {
			return {
				...state,
				values: Object.fromEntries(
					fields.map(
						(field) => [
							field.name,
							state.values[field.name] ?? field.defaultValue,
						],
					),
				),
			};
		},
		[
			fields,
			state,
		],
	);

	return React.useMemo(
		() => {
			return {
				contextForProvider,
				state: publishedState,
			};
		},
		[
			contextForProvider,
			publishedState,
		],
	);
}



export default useFormFieldsContext;
