import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttachedEnterpriseRibbon from '~/components/patterns/pricingPlans/AttachedEnterpriseRibbon';
import BenefitNameWithIcon from './BenefitNameWithIcon';
import BenefitSectionName from './BenefitSectionName';
import BenefitsComparison from './BenefitsComparison';
import Caption from '~/components/patterns/headings/Caption';
import PlansComparisonTable, {
	PlansComparisonTableScope,
} from '~/components/patterns/pricingPlans/plansComparisonTable/PlansComparisonTable';
import PlansComparisonTableCells, {
	PlansComparisonTableCellsStyle,
} from '~/components/patterns/pricingPlans/plansComparisonTable/PlansComparisonTableCells';
import PlansComparisonTableHeadlineColumn, {
	PlansComparisonTableHeadlineColumnCustomerType,
} from '~/components/patterns/pricingPlans/plansComparisonTable/PlansComparisonTableHeadlineColumn';
import PlansComparisonTableLabels from '~/components/patterns/pricingPlans/plansComparisonTable/PlansComparisonTableLabels';
import PlanDetails from '~/components/patterns/pricingPlans/PlanDetails';
import PlanName from '../../../names/PlanName';
import PlanPriceSummary from '../components/PlanPriceSummary';
import SignupPlanComparisonButton from '~/components/app/SignupPlanComparisonButton';

import useAccountCustomerType from '~/hooks/useAccountCustomerType';
import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountId from '~/hooks/useAccountId';
import useViewportType from '~/hooks/useViewportType';

import {
	type BenefitSection,
} from '~/model/benefits';

import {
	isEnterprisePlan,
} from '~/model/plans';

import {
	type Tariff,
} from '~/model/pricing/tariffs';

import {
	getNewPlanBenefits,
} from '~/model/universal';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import {
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	mostPopular: {
		id: 'ui.planBenefits.flags.mostPopular',
	},
});



const headlineCustomerType = {
	[GraphQL.CustomerType.Agency]: PlansComparisonTableHeadlineColumnCustomerType.Agency,
	[GraphQL.CustomerType.Ecommerce]: PlansComparisonTableHeadlineColumnCustomerType.Ecommerce,
	[GraphQL.CustomerType.Marketplace]: PlansComparisonTableHeadlineColumnCustomerType.Marketplace,
	[GraphQL.CustomerType.Other]: null,
	[GraphQL.CustomerType.Publisher]: PlansComparisonTableHeadlineColumnCustomerType.Publisher,
	[GraphQL.CustomerType.Travel]: PlansComparisonTableHeadlineColumnCustomerType.TravelWebsite,
};

type Props = {
	bestChoicePlan?: GraphQL.AccountPlan,
	disabledPlans: {
		[K in GraphQL.AccountPlan]?: 'accountTooLarge' | 'disallowed' | undefined
	},
	mostPopularPlan?: GraphQL.AccountPlan,
	plans: Array<GraphQL.AccountPlan>,
	sections: Array<BenefitSection>,
	tariff: Tariff,
};

const DesktopPlansComparison = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		bestChoicePlan,
		disabledPlans,
		mostPopularPlan,
		plans,
		sections,
		tariff,
	} = props;

	const accountId = useAccountId();

	const accountCustomerType = useAccountCustomerType(accountId);
	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const viewportType = useViewportType();

	if (
		accountFeaturesAvailability === null
	) {
		return null;
	}

	const bestForCustomerType = accountCustomerType !== null
		? headlineCustomerType[accountCustomerType]
		: null;

	const firstSection = getArrayItemAtSafeIndex(sections, 0);
	const restSections = sections.slice(1);

	return (
		<>
			<PlansComparisonTable
				labels={(
					<PlansComparisonTableLabels
						labels={
							getNewPlanBenefits(tariff, accountFeaturesAvailability, firstSection)
								.map((item) => (
									<BenefitNameWithIcon
										benefit={item.name}
										hint={item.hint}
										iconType={item.iconType}
										key={item.name}
									/>
								))
						}
					/>
				)}
				ref={ref}
				scope={PlansComparisonTableScope.MainFeaturesComparison}
				title={(
					<Caption>
						<BenefitSectionName section={firstSection} />
					</Caption>
				)}
			>
				{plans.map((plan, i) => {
					const isDisabled = isString(disabledPlans[plan]);
					const isEnterprise = isEnterprisePlan(plan);

					const cells = getNewPlanBenefits(tariff, accountFeaturesAvailability, firstSection)
						.map((item) => item.values[plan]);

					return (
						<PlansComparisonTableHeadlineColumn
							benefits={(
								<PlansComparisonTableCells
									cells={cells}
									style={(
										isEnterprise
											? PlansComparisonTableCellsStyle.Light
											: PlansComparisonTableCellsStyle.Default
									)}
								/>
							)}
							customerType={bestForCustomerType}
							details={(
								<PlanDetails
									ctaButton={(
										<SignupPlanComparisonButton plan={plan} />
									)}
									isDisabled={isDisabled}
									name={isEnterprise && !viewportType.isMedium ? (
										<AttachedEnterpriseRibbon>
											<PlanName plan={plan} />
										</AttachedEnterpriseRibbon>
									) : (
										<PlanName plan={plan} />
									)}
									priceOverview={accountId !== null && (
										<PlanPriceSummary
											accountId={accountId}
											disabledReason={disabledPlans[plan]}
											plan={plan}
										/>
									)}
								/>
							)}
							flag={plan === mostPopularPlan && (
								<FormattedMessage {...messages.mostPopular} />
							)}
							isDisabled={isDisabled}
							isEnterprise={isEnterprise}
							isFirst={i === 0}
							isLast={i === plans.length - 1}
							key={plan}
							visibleProTip={plan === bestChoicePlan && bestForCustomerType !== null}
						/>
					);
				})}
			</PlansComparisonTable>

			{restSections.map((section) => (
				<BenefitsComparison
					key={section}
					plans={plans}
					section={section}
					tariff={tariff}
				/>
			))}
		</>
	);
});



export default DesktopPlansComparison;
