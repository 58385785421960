import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountDefaultLighthouseThresholdsQuery,
} from './useAccountDefaultLighthouseThresholds.gql';



function useAccountDefaultLighthouseThresholds(accountId: CK.AccountId | null): GraphQL.WebsiteLighthouseThresholds | null {
	const { data } = useAccountDefaultLighthouseThresholdsQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? -1,
		},
	});

	return data?.account?.defaultLighthouseThresholds ?? null;
}



export default useAccountDefaultLighthouseThresholds;
