/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMessagingAppChannelsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  messagingAppChannelIds: ReadonlyArray<GraphQL.Scalars['ID']['input']> | GraphQL.Scalars['ID']['input'];
}>;


export type DeleteMessagingAppChannelsMutation = { readonly __typename?: 'Mutation', readonly DeleteMessagingAppChannels: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType }> } | null } } };


export const DeleteMessagingAppChannelsDocument = gql`
    mutation DeleteMessagingAppChannels($accountId: AccountId!, $messagingAppChannelIds: [ID!]!) {
  DeleteMessagingAppChannels(
    accountId: $accountId
    messagingAppChannelIds: $messagingAppChannelIds
  ) {
    query {
      account(id: $accountId) {
        id
        messagingAppChannels {
          id
          label
          messagingAppType
        }
      }
    }
  }
}
    `;
export type DeleteMessagingAppChannelsMutationFn = Apollo.MutationFunction<DeleteMessagingAppChannelsMutation, DeleteMessagingAppChannelsMutationVariables>;

/**
 * __useDeleteMessagingAppChannelsMutation__
 *
 * To run a mutation, you first call `useDeleteMessagingAppChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessagingAppChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessagingAppChannelsMutation, { data, loading, error }] = useDeleteMessagingAppChannelsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      messagingAppChannelIds: // value for 'messagingAppChannelIds'
 *   },
 * });
 */
export function useDeleteMessagingAppChannelsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessagingAppChannelsMutation, DeleteMessagingAppChannelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessagingAppChannelsMutation, DeleteMessagingAppChannelsMutationVariables>(DeleteMessagingAppChannelsDocument, options);
      }
export type DeleteMessagingAppChannelsMutationHookResult = ReturnType<typeof useDeleteMessagingAppChannelsMutation>;
export type DeleteMessagingAppChannelsMutationResult = Apollo.MutationResult<DeleteMessagingAppChannelsMutation>;
export type DeleteMessagingAppChannelsMutationOptions = Apollo.BaseMutationOptions<DeleteMessagingAppChannelsMutation, DeleteMessagingAppChannelsMutationVariables>;