import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	ISSUE_CHANGES_GROUP_IGNORING,
	ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE,
	ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES,
	ISSUE_CHANGES_GROUP_OPENED,
	ISSUE_CHANGES_GROUP_RESOLVED,
	ISSUE_CHANGES_GROUP_SEGMENT_CHANGED,
	ISSUE_CHANGES_GROUP_UNAFFECTED,
} from '~/model/affectedPagesComparison';



const messages = defineMessages({
	[ISSUE_CHANGES_GROUP_IGNORING]: {
		id: 'ui.affectedPagesComparison.value.issueChangesGroup.ignoring',
	},
	[ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE]: {
		id: 'ui.affectedPagesComparison.value.issueChangesGroup.noLongerApplicable',
	},
	[ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES]: {
		id: 'ui.affectedPagesComparison.value.issueChangesGroup.onlyImportanceChanges',
	},
	[ISSUE_CHANGES_GROUP_OPENED]: {
		id: 'ui.affectedPagesComparison.value.issueChangesGroup.opened',
	},
	[ISSUE_CHANGES_GROUP_RESOLVED]: {
		id: 'ui.affectedPagesComparison.value.issueChangesGroup.resolved',
	},
	[ISSUE_CHANGES_GROUP_SEGMENT_CHANGED]: {
		id: 'ui.affectedPagesComparison.value.issueChangesGroup.segmentChanged',
	},
	[ISSUE_CHANGES_GROUP_UNAFFECTED]: {
		id: 'ui.affectedPagesComparison.value.issueChangesGroup.unaffected',
	},
});



type Props = {
	issueChangesGroup: (
		| typeof ISSUE_CHANGES_GROUP_IGNORING
		| typeof ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE
		| typeof ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES
		| typeof ISSUE_CHANGES_GROUP_OPENED
		| typeof ISSUE_CHANGES_GROUP_RESOLVED
		| typeof ISSUE_CHANGES_GROUP_SEGMENT_CHANGED
		| typeof ISSUE_CHANGES_GROUP_UNAFFECTED
	),
};

const IssueChangesGroupName: React.FC<Props> = (props) => {
	const {
		issueChangesGroup,
	} = props;

	const message = messages[issueChangesGroup];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default IssueChangesGroupName;
