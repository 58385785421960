import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import {
	setSelectedDefinitions,
} from '~/actions/alertsConfiguration';



function useClearAlertDefinitionsBulkSelection() {
	const dispatch = useDispatch();

	return React.useCallback(
		() => {
			dispatch(
				setSelectedDefinitions({
					selectedDefinitions: [],
				}),
			);
		},
		[
			dispatch,
		],
	);
}



export default useClearAlertDefinitionsBulkSelection;
