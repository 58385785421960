import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import Separator from '~/components/patterns/values/Separator';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextTarget from '~/components/atoms/forms/components/TextTarget';

import {
	assertDictionaryId,
} from '~/localization/dictionaries';



const messages = defineMessages({
	address: {
		id: 'ui.newTeam.form.address',
	},
	company: {
		id: 'ui.newTeam.form.company',
	},
	country: {
		id: 'ui.newTeam.form.country',
	},
	province: {
		id: 'ui.newTeam.form.province',
	},
	state: {
		id: 'ui.newTeam.form.state',
	},
	zipCodeAndCity: {
		id: 'ui.newTeam.form.zipCodeAndCity',
	},
});



type Props = {
	address: GraphQL.BillingDetailsAddress | null,
};

const AddressOverview: React.FC<Props> = (props) => {
	const {
		address,
	} = props;

	function renderCountry() {
		let countryName: React.ReactNode | string = '-';
		const countryCode = address?.country ?? null;

		if (countryCode !== null) {
			const messageId = `countries.${countryCode}`;

			assertDictionaryId(messageId);

			countryName = (
				<FormattedMessage id={messageId} />
			);
		}

		return (
			<FormRow
				label={(
					<FormattedMessage {...messages.country} />
				)}
			>
				<StaticText focusTarget="country">
					{countryName}
				</StaticText>
			</FormRow>
		);
	}

	function renderState() {
		const countryCode = address?.country ?? null;
		const stateCode = address?.state ?? null;

		if (countryCode !== null && stateCode !== null) {
			const messageId = `states.${countryCode}.${stateCode}`;

			assertDictionaryId(messageId);

			return (
				<FormRow
					label={(
						<FormattedMessage {...countryCode === 'US' ? messages.state : messages.province} />
					)}
				>
					<StaticText focusTarget="state">
						<FormattedMessage id={messageId} />
					</StaticText>
				</FormRow>
			);
		}

		return null;
	}

	return (
		<FormRows>
			<FormRow
				label={(
					<FormattedMessage {...messages.company} />
				)}
			>
				<StaticText focusTarget="name">
					{address?.name ?? '-'}
				</StaticText>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.address} />
				)}
			>
				<StaticText focusTarget="address">
					{address?.address ?? '-'}
				</StaticText>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.zipCodeAndCity} />
				)}
			>
				<StaticText>
					<TextTarget focusTarget="zip_code">
						{address?.postalCode ?? '-'}
					</TextTarget>

					{address !== null && (
						<Separator>, </Separator>
					)}

					<TextTarget focusTarget="city">
						{address?.city ?? '-'}
					</TextTarget>
				</StaticText>
			</FormRow>

			{renderCountry()}

			{renderState()}
		</FormRows>
	);
};



export default AddressOverview;
