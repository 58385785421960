import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import Copy from '~/components/logic/Copy';
import MarginsList from '~/components/atoms/lists/MarginsList';
import Measurer from '~/utilities/Measurer';
import RichText from '~/components/patterns/typography/RichText';
import SidebarFreeTextInput from './SidebarFreeTextInput';
import SidebarSuggestedDomains from './SidebarSuggestedDomains';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';

import {
	useSuggestedDomainsQuery,
} from './AddMultipleWebsitesStep.gql';



const messages = defineMessages({
	description: {
		id: 'ui.websites.new.steps.multiple.sidebar.description',
	},
	tabFreeTextInput: {
		id: 'ui.websites.new.steps.multiple.sidebar.tab.freeTextInput',
	},
	tabSuggestedWebsites: {
		id: 'ui.websites.new.steps.multiple.sidebar.tab.suggestedWebsites',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	addDomainsToStagingArea: (domains: Array<string>) => void,
	isMaxWebsitesLimitReached: boolean,
	maxUnverifiedWebsites: number | null,
	maxWebsites?: number,
	sessionId?: string,
	stagedDomains: Array<string>,
};

enum Tab {
	SuggestedWebsites,
	FreeTextInput,
}



const Sidebar: React.FC<Props> = (props) => {
	const {
		accountId,
		addDomainsToStagingArea,
		isMaxWebsitesLimitReached,
		maxUnverifiedWebsites,
		maxWebsites,
		sessionId,
		stagedDomains,
	} = props;

	const [activeTab, setActiveTab] = React.useState<Tab | null>(Tab.SuggestedWebsites);
	const { data, loading: isLoadingSuggestedDomains } = useSuggestedDomainsQuery({
		variables: {
			accountId: accountId ?? 0,
		},
		skip: accountId === null,
		onCompleted: (data) => {
			if (data.account?.suggestedDomains.length === 0) {
				setActiveTab(Tab.FreeTextInput);
			}
		},
	});

	const suggestedDomains = data?.account?.suggestedDomains || [];

	return (
		<MarginsList>
			<RichText>
				<Copy {...messages.description} />
			</RichText>

			<BorderedBox
				header={(
					<TabNavigation stretchItems={false}>
						<TabNavigationItem
							isActive={activeTab === Tab.SuggestedWebsites}
							onClickCallback={() => setActiveTab(Tab.SuggestedWebsites)}
						>
							<FormattedMessage {...messages.tabSuggestedWebsites} />
						</TabNavigationItem>
						<TabNavigationItem
							isActive={activeTab === Tab.FreeTextInput}
							onClickCallback={() => setActiveTab(Tab.FreeTextInput)}
						>
							<FormattedMessage {...messages.tabFreeTextInput} />
						</TabNavigationItem>
					</TabNavigation>
				)}
				headerPaddingSize={0}
				paddingSize={activeTab === Tab.FreeTextInput ? 1 : 0}
				reversedColors={true}
			>
				<Measurer>
					{({ containerWidth }) => (
						<>
							<div style={{ display: activeTab === Tab.SuggestedWebsites ? 'block' : 'none' }}>
								<SidebarSuggestedDomains
									accountId={accountId}
									addDomainsToStagingArea={addDomainsToStagingArea}
									isLoading={accountId === null || isLoadingSuggestedDomains}
									isMaxWebsitesLimitReached={isMaxWebsitesLimitReached}
									maxWebsites={maxWebsites}
									onFreeTextInputClick={() => setActiveTab(Tab.FreeTextInput)}
									sessionId={sessionId}
									stagedDomains={stagedDomains}
									suggestedDomains={suggestedDomains}
									width={containerWidth}
								/>
							</div>

							<div style={{ display: activeTab === Tab.FreeTextInput ? 'block' : 'none' }}>
								<SidebarFreeTextInput
									accountId={accountId}
									addDomainsToStagingArea={addDomainsToStagingArea}
									isMaxWebsitesLimitReached={isMaxWebsitesLimitReached}
									maxUnverifiedWebsites={maxUnverifiedWebsites}
									maxWebsites={maxWebsites}
									sessionId={sessionId}
								/>
							</div>
						</>
					)}
				</Measurer>
			</BorderedBox>
		</MarginsList>
	);
};



export default Sidebar;
