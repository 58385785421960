import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PagesFixedIconBuilder: IconBuilder = {
	icon: () => {
		return (
			<g>
				<defs>
					<path
						d="M0 0h24v28H0V0zm16 28a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
						id="a"
					/>
				</defs>
				<g
					fill="none"
					fillRule="evenodd"
					transform="translate(3)"
				>
					<path d="M20 24L0 24 0 0 20 0z" />
					<mask
						fill="#fff"
						id="b"
					>
						<use xlinkHref="#a" />
					</mask>
					<g
						mask="url(#b)"
						stroke="#8595A6"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<g transform="translate(1 1)">
							<path d="M0.333333333 21.7942918L0.333333333 3 15.3704584 3 15.3704584 21.7942918z" />
							<path d="M3 8.333h9.333H3zm0 4h9.333H3zm0 4h9.333H3z" />
							<path
								d="M3 2.79951793L3 0.333333333 18.3946464 0.333333333 18.3946464 19.5744765 15.5747595 19.5744765"
								strokeOpacity=".703"
							/>
						</g>
					</g>
					<g transform="translate(10 14)">
						<circle
							cx="6"
							cy="6"
							fill="#42CC67"
							r="6"
						/>
						<g
							fill="#FAFAFA"
							transform="translate(3 3.917)"
						>
							<path d="M2.957 3.855l-.422.45a.618.618 0 0 1-.942-.037L.353 2.745a.515.515 0 0 1 .023-.669.441.441 0 0 1 .628-.024l1.953 1.803" />
							<path d="M1.287 3.693l3.561-3.5a.524.524 0 0 1 .759.016.61.61 0 0 1 0 .824l-3.023 3.22a.62.62 0 0 1-.98-.087l-.317-.473z" />
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 28,
	viewBoxWidth: 28,
};



export default PagesFixedIconBuilder;
