import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CrawlingIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-crawling">
					<g id="crawling">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<path
							d="M18.7864411,14.6174327 C17.8415976,17.2479896 15.2172927,19.1408027 12.1279905,19.1408027 C8.24311272,19.1408027 5.0944158,16.1499704 5.0944158,12.4604698"
							id="Stroke-155"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M5.46891458,9.59639819 C6.41375811,6.96584127 9.03806296,5.07302818 12.1273652,5.07302818 C16.012243,5.07302818 19.1609399,8.06386042 19.1609399,11.7533611"
							id="Stroke-156"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<polyline
							id="Stroke-157"
							points="21.524221 8.70758881 19.1593769 11.8187733 16.0489739 9.45392923"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</polyline>
						<polyline
							id="Stroke-158"
							points="2.5547483 15.395346 5.06964199 12.4045138 8.06047423 14.9194075"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</polyline>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CrawlingIconBuilder;

