import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import ColumnName from '~/components/names/ColumnName';
import ColumnValueFormatter from '~/components/logic/formatters/ColumnValueFormatter';
import {
	linkInternal,
} from '~/components/logic/Copy';
import DatePeriodLabel from '~/components/app/DatePeriodLabel';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermValueSkeleton from './DefinitionTermValueSkeleton';
import Emphasis from '~/components/patterns/typography/Emphasis';
import {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LabeledValue, {
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import RichText from '~/components/patterns/typography/RichText';

import {
	useGoogleAnalyticsBlockQuery,
} from './GoogleAnalyticsBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import useWebsiteGoogleAnalyticsVisibleColumns from '~/hooks/useWebsiteGoogleAnalyticsVisibleColumns';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



const messages = defineMessages({
	notEnabled: {
		id: 'ui.pageDetail.googleAnalytics.notEnabled',
	},
	title: {
		id: 'ui.pageDetail.googleAnalytics.title',
	},
});



const GoogleAnalyticsColumnMapping = [
	[CK.PagesCommonColumn.GaPageViews, 'pageViews'],
	[CK.PagesCommonColumn.GaUniquePageViews, 'uniquePageViews'],
	[CK.PagesCommonColumn.GaAverageTime, 'averageTime'],
	[CK.PagesCommonColumn.GaBounceRate, 'bounceRate'],
	[CK.PagesCommonColumn.GaPageValue, 'pageValue'],
] as const;

const GoogleAnalyticsV4ColumnMapping = [
	[CK.PagesCommonColumn.GaScreenPageViews, 'screenPageViews'],
	[CK.PagesCommonColumn.GaActiveUsers, 'activeUsers'],
	[CK.PagesCommonColumn.GaAverageEngagementTime, 'averageEngagementTime'],
	[CK.PagesCommonColumn.GaEngagementRate, 'engagementRate'],
] as const;



const GoogleAnalyticsBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const websiteIntegrations = useWebsiteIntegrations(websiteId);
	const googleAnalyticsVisibleColumns = useWebsiteGoogleAnalyticsVisibleColumns(websiteId);
	const isGoogleAnalyticsEnabled = websiteIntegrations.isEnabled(GraphQL.WebsiteIntegrationType.GoogleAnalytics);

	const { data } = usePageDetailPropertiesQuery(
		useGoogleAnalyticsBlockQuery,
		legacyUrlId,
		websiteId,
		{
			skip: !isGoogleAnalyticsEnabled,
		},
	);

	const showGoogleAnalyticsV4Data = googleAnalyticsVisibleColumns.includes(GraphQL.GoogleAnalyticsVersion.V4);

	const googleAnalyticsData = (
		showGoogleAnalyticsV4Data
			? data?.lookupPageByLegacyId?.googleAnalyticsV4Data
			: data?.lookupPageByLegacyId?.googleAnalyticsData
	) ?? null;

	const googleAnalyticsMissingDataReason = (
		showGoogleAnalyticsV4Data
			? data?.lookupPageByLegacyId?.googleAnalyticsV4MissingDataReason
			: data?.lookupPageByLegacyId?.googleAnalyticsMissingDataReason
	) ?? null;

	const columnMapping = (
		showGoogleAnalyticsV4Data
			? GoogleAnalyticsV4ColumnMapping
			: GoogleAnalyticsColumnMapping
	);

	return (
		<BorderedBox
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.GoogleAnalytics}
				/>
			)}
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			headerSublabel={googleAnalyticsData !== null && (
				<DatePeriodLabel
					since={new Date(googleAnalyticsData.dateRange.since)}
					until={new Date(googleAnalyticsData.dateRange.until)}
				/>
			)}
			paddingSize={3}
		>
			{!isGoogleAnalyticsEnabled ? (
				<RichText>
					<Emphasis>
						<FormattedMessage
							{...messages.notEnabled}
							values={{
								linkIntegrations: linkInternal(
									'website.detail.integrations',
									{ websiteId },
									{ style: InternalLinkStyle.Decent },
								),
							}}
						/>
					</Emphasis>
				</RichText>
			) : (
				<LabeledValuesList>
					{columnMapping.map(([column, field]) => {
						const label = (
							<ColumnName column={column} />
						);

						let showAsDefinitionTerm = true;
						let value;

						if (googleAnalyticsData !== null) {
							showAsDefinitionTerm = false;
							value = googleAnalyticsData[field];
						} else if (googleAnalyticsMissingDataReason !== null) {
							value = googleAnalyticsMissingDataReason;
						}

						if (value !== undefined) {
							value = (
								<ColumnValueFormatter
									column={column}
									value={value}
								/>
							);
						} else {
							value = (
								<DefinitionTermValueSkeleton />
							);
						}

						if (showAsDefinitionTerm) {
							return (
								<DefinitionTerm
									description={value}
									key={column}
									term={label}
								/>
							);
						}

						return (
							<LabeledValue
								flow={LabeledValueFlow.Row}
								key={column}
								label={label}
								maxWidth={350}
							>
								{value}
							</LabeledValue>
						);
					})}
				</LabeledValuesList>
			)}
		</BorderedBox>
	);
};



export default GoogleAnalyticsBlock;
