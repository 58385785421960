import Immutable from 'immutable';
import memoizee from 'memoizee';

import {
	get,
} from './api/API';

import {
	encodeFilterParameter,
} from './filterParameter';

import {
	IssueCategoryName,
	type IssueName,
	PageIssue,
} from './issuesNew';

import removeDefaultValues from '~/utilities/removeDefaultValues';



const SHARED_DEFAULT_FILTER: Immutable.Map<string, any> = Immutable.Map({
	segment: 'all',
});

const CATEGORY_DEFAULT_FILTERS = Immutable.fromJS({
	[IssueCategoryName.Analytics]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.CanonicalLink]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.H1]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.Hreflang]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.Images]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.Lighthouse]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.Links]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.MetaDescription]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.OpenGraph]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.RobotDirectives]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.SchemaOrg]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.Title]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.TwitterCards]: SHARED_DEFAULT_FILTER.merge({}),
	[IssueCategoryName.XmlSitemap]: SHARED_DEFAULT_FILTER.merge({
		xml_sitemap_status: [
			'incorrectly_missing',
			'incorrectly_present',
		],
	}),
});

const ISSUE_DEFAULT_FILTERS = Immutable.fromJS({
	[PageIssue.AnalyticsAnalyticsMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.AnalyticsVisualAnalyticsMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.CanonicalLinkIncorrectlyCanonicalized]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.CanonicalLinkMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.CanonicalLinkPointsToUnindexable]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.CanonicalLinkTooMany]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1Duplicate]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1IncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1LevelsSkipped]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1Missing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1TooMany]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangConflictingTargets]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangInvalidTarget]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangInvalidValue]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangMissingSelfReference]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangMissingSpecificAudience]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangMissingXDefault]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.ImagesAltAttribute]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.ImagesMixedTransport]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseCls]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseFcp]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseLcp]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthousePerformance]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseSi]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseTbt]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseTti]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LinksBroken]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LinksRedirected]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LinksToCanonicalized]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.MetaDescriptionDuplicate]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.MetaDescriptionIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.MetaDescriptionMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.MetaDescriptionTooMany]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphDescriptionIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphDescriptionMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphImageMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphTitleIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphTitleMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphUrlMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.RobotDirectivesConflicting]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.RobotDirectivesInvalid]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.RobotDirectivesUnsupported]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.SchemaOrgErrors]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.SchemaOrgInvalidJson]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TitleDuplicate]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TitleIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TitleMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TitleTooMany]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsDescriptionIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsDescriptionMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsImageMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsSiteMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsTitleIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsTitleMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsTypeInvalid]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsTypeMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.XmlSitemapIncorrectlyMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.XmlSitemapIncorrectlyPresent]: SHARED_DEFAULT_FILTER.merge({}),
});

export const DEFAULT_SORT_BY = Immutable.Map({
	key: 'relevance',
	direction: false,
});



export function createAffectedPagesFilterParameter(filter, sortBy, issueCategory, issueType: IssueName | null = null) {
	if (issueType === PageIssue.XmlSitemapIncorrectlyMissing || issueType === PageIssue.XmlSitemapIncorrectlyPresent) {
		filter = filter.remove('xml_sitemap_status');
	}

	return encodeFilterParameter({
		defaultFilter: getDefaultFilter(issueCategory, issueType),
		defaultSortBy: DEFAULT_SORT_BY,
		filter,
		sortBy,
	});
}



export const createFilter = memoizee((filter, issueCategory, issueType: IssueName | null = null) => {
	return getDefaultFilter(issueCategory, issueType).merge(filter);
});



export function getDefaultFilter(issueCategory, issueType: IssueName | null = null) {
	if (issueType) {
		return ISSUE_DEFAULT_FILTERS.get(issueType, Immutable.Map());
	}

	return CATEGORY_DEFAULT_FILTERS.get(issueCategory, Immutable.Map());
}



export function loadAffectedPagesViaApi(websiteId, parameters) {
	return get('/websites/' + websiteId + '/issues/affected_pages', parameters, {
		timeout: 30000,
	});
}



export function removeDefaultFilterValues(activeFilters, issueCategory, issueType) {
	return removeDefaultValues(
		activeFilters,
		getDefaultFilter(issueCategory, issueType),
	);
}
