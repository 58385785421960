import React from 'react';

import ContextMenu from './atoms/navigations/contextMenu/ContextMenu';
import ContextMenuOverlay from './atoms/navigations/contextMenu/ContextMenuOverlay';
import ContextMenuItem from './atoms/navigations/contextMenu/ContextMenuItem';



type Position = {
	bottom?: number,
	left?: number,
	right?: number,
	top?: number,
};

type OpenContextMenuOptions = {
	onClose?: () => void,
	onOpen?: () => void,
};

type ContextMenuContextValue = {
	open: boolean,
	openContextMenu: (
		event: MouseEvent,
		entries: Array<ContextMenuEntry>,
		options?: OpenContextMenuOptions
	) => void,
	position: Position,
};

export type ContextMenuEntry = {
	icon?: React.ReactNode,
	label: React.ReactNode,
	onClick: () => void,
};



const CONTEXT_MENU_WIDTH = 240;
const CONTEXT_MENU_ITEM_MIN_HEIGHT = 35;



const ContextMenuContext = React.createContext<ContextMenuContextValue | null>(null);



type Props = {
	children?: React.ReactNode,
};

const ContextMenuProvider: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const [open, setOpen] = React.useState(false);
	const [position, setPosition] = React.useState<Position>({});
	const [entries, setEntries] = React.useState<Array<ContextMenuEntry>>([]);

	const onCloseCallback = React.useRef<(() => void) | null>(null);

	function openContextMenu(
		event: MouseEvent,
		entries: Array<ContextMenuEntry>,
		options?: OpenContextMenuOptions,
	) {
		event.preventDefault();
		event.stopPropagation();

		setEntries(entries);
		setOpen(true);

		const width = CONTEXT_MENU_WIDTH;
		const approximateHeight = entries.length * CONTEXT_MENU_ITEM_MIN_HEIGHT * 1.2;

		const stickToBottom = event.pageY + approximateHeight > window.innerHeight;
		const stickToRight = event.pageX + width > window.innerWidth;

		const bottom = stickToBottom ? 0 : undefined;
		const left = stickToRight ? undefined : event.pageX;
		const right = stickToRight ? 0 : undefined;
		const top = stickToBottom ? undefined : event.pageY;

		setPosition({
			bottom,
			right,
			left,
			top,
		});

		if (options && options.onOpen) {
			options.onOpen();
		}

		if (options && options.onClose) {
			onCloseCallback.current = options.onClose;
		}
	}

	function closeContextMenu() {
		setOpen(false);

		if (onCloseCallback.current) {
			onCloseCallback.current();
			onCloseCallback.current = null;
		}
	}

	React.useEffect(() => {
		if (!open) {
			return;
		}

		function keyListener(e: KeyboardEvent) {
			if (e.key === 'Escape') {
				closeContextMenu();
			}
		}

		function visibilityListener() {
			if (document.hidden) {
				closeContextMenu();
			}
		}

		document.addEventListener('keydown', keyListener);
		document.addEventListener('visibilitychange', visibilityListener);

		return () => {
			document.removeEventListener('keydown', keyListener);
			document.removeEventListener('visibilitychange', visibilityListener);
		};
	}, [open]);

	const value = React.useMemo<ContextMenuContextValue>(
		() => ({
			open,
			openContextMenu,
			position,
		}),
		[
			open,
			position,
		],
	);

	return (
		<ContextMenuContext.Provider value={value}>
			{children}

			{open && (
				<ContextMenuOverlay requestCloseOverlay={closeContextMenu}>
					<ContextMenu position={position}>
						{entries.map((item, i) => (
							<ContextMenuItem
								icon={item.icon}
								key={i}
								onClickCallback={() => {
									item.onClick();
									closeContextMenu();
								}}
							>
								{item.label}
							</ContextMenuItem>
						))}
					</ContextMenu>
				</ContextMenuOverlay>
			)}
		</ContextMenuContext.Provider>
	);
};



export default ContextMenuProvider;

export {
	ContextMenuContext,
};
