import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import AnchorsNavigation from '../../atoms/navigations/AnchorsNavigation';
import SidebarNavigation from '../../atoms/navigations/SidebarNavigation';

import {
	verticalScrollTo,
} from '~/utilities/scrollTo';



class PanelNavigation extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleAnchorItemClick = this._handleAnchorItemClick.bind(this);
	}



	_handleAnchorItemClick(section, e) {
		if (e) {
			e.preventDefault();
		}

		const {
			sectionRefs,
		} = this.props;

		let container = document.querySelector('.js-scrollable-panel');

		if (!container || !container.classList.contains('panel')) {
			container = document.querySelector('.js-screen-scrollable-content');
		}

		if (!container) {
			container = document.querySelector('#js-page-scrollable-area');
		}

		if (container) {
			verticalScrollTo(
				container,
				sectionRefs[section].current.offsetTop,
			);
		}
	}



	render() {
		const {
			compactLayout,
			items,
			onNavigationItemClick,
		} = this.props;

		if (compactLayout) {
			return (
				<AnchorsNavigation
					onClickCallback={this._handleAnchorItemClick}
					options={items.map(({ label, name }) => {
						return {
							label,
							value: name,
						};
					})}
				/>
			);
		}

		return (
			<SidebarNavigation
				onClickCallback={onNavigationItemClick}
				options={items.map(({ isActive, label, name, routeName, routeParams }) => {
					const params = {
						label,
						highlighted: isActive,
						value: name,
					};

					if (routeName) {
						params.routeName = routeName;
						params.routeParams = routeParams;
					}

					return params;
				})}
			/>
		);
	}

}

PanelNavigation.defaultProps = {
	compactLayout: false,
};

PanelNavigation.propTypes = {
	compactLayout: PropTypes.bool,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			isActive: PropTypes.bool.isRequired,
			label: PropTypes.node.isRequired,
			name: PropTypes.string.isRequired,
			routeName: PropTypes.string.isRequired,
			routeParams: PropTypes.object.isRequired,
		}),
	).isRequired,
	onNavigationItemClick: PropTypes.func,
	sectionRefs: PropTypes.object.isRequired,
};



export default PanelNavigation;
