import type Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import LabeledValue, {
	LabeledValueContentAlignment,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import RichText, {
	RichTextBottomMarginSize,
} from '~/components/patterns/typography/RichText';
import UnreliableResponseName from '~/components/names/UnreliableResponseName';



const messages = defineMessages({
	currentStatus: {
		id: 'ui.alerts.overview.incident.details.websiteHasTroubleResponding.currentStatus',
	},
	failureReasonPercentage: {
		id: 'ui.alerts.overview.incident.details.websiteHasTroubleResponding.failureReasonPercentage',
	},
	failureReasonTotal: {
		id: 'ui.alerts.overview.incident.details.websiteHasTroubleResponding.failureReasonTotal',
	},
	overallFailedRequests: {
		id: 'ui.alerts.overview.incident.details.websiteHasTroubleResponding.overallFailedRequests',
	},
	overallImpact: {
		id: 'ui.alerts.overview.incident.details.websiteHasTroubleResponding.overallImpact',
	},
	recentFailedRequests: {
		id: 'ui.alerts.overview.incident.details.websiteHasTroubleResponding.recentFailedRequests',
	},
});



type Props = {
	incident: Immutable.Map<any, any>,
	websiteId: CK.WebsiteId,
};

const WebsiteHasTroubleResponding: React.FC<Props> = (props) => {
	const {
		incident,
	} = props;

	const isIncidentActive = incident.get('phase') === 'active';

	const currentStatus = incident.getIn(['state', 'currentStatus'], null).toJS();
	const overallImpact = incident.getIn(['state', 'overallImpact'], null).toJS();

	const failedRequestsPercentage = (
		Object.entries(currentStatus.failedRequestsPerTypePercentage ?? {})
			.map((entry) => ({
				reason: (entry[0] as string),
				percentage: (entry[1] as number),
			}))
			.sort((a, b) => b.percentage - a.percentage)
	);

	const failedRequestsTotal = (
		Object.entries(overallImpact.failedRequestsPerTypeTotal ?? {})
			.map((entry) => ({
				reason: (entry[0] as string),
				count: (entry[1] as number),
			}))
			.sort((a, b) => b.count - a.count)
	);

	const countFailedRequests = failedRequestsTotal
		.reduce((count, failedRequest) => count += failedRequest.count, 0);

	return (
		<IncidentBoxContent>
			{isIncidentActive === true && (
				<LabeledValue
					contentAlignment={LabeledValueContentAlignment.Left}
					label={(
						<FormattedMessage {...messages.currentStatus} />
					)}
				>
					<RichText bottomMarginSize={RichTextBottomMarginSize.Smaller}>
						<Copy
							{...messages.recentFailedRequests}
							values={{
								percentage: currentStatus.percentageOfFailedRequests,
								window: currentStatus.windowSizeInSeconds * 1000,
							}}
						/>

						{currentStatus.percentageOfFailedRequests > 0 && (
							<ul>
								{failedRequestsPercentage.map((failedRequest) => (
									<li key={failedRequest.reason}>
										<FormattedMessage
											{...messages.failureReasonPercentage}
											values={{
												reason: <UnreliableResponseName reason={failedRequest.reason} />,
												percentage: failedRequest.percentage,
											}}
										/>
									</li>
								))}
							</ul>
						)}
					</RichText>
				</LabeledValue>
			)}

			<LabeledValue
				contentAlignment={LabeledValueContentAlignment.Left}
				label={(
					<FormattedMessage {...messages.overallImpact} />
				)}
			>
				<RichText bottomMarginSize={RichTextBottomMarginSize.Smaller}>
					<Copy
						{...messages.overallFailedRequests}
						values={{
							count: countFailedRequests,
							isIncidentActive: isIncidentActive ? 'yes' : 'no',
						}}
					/>
					<ul>
						{failedRequestsTotal.map((failedRequest) => (
							<li key={failedRequest.reason}>
								<FormattedMessage
									{...messages.failureReasonTotal}
									values={{
										reason: <UnreliableResponseName reason={failedRequest.reason} />,
										count: failedRequest.count,
									}}
								/>
							</li>
						))}
					</ul>
				</RichText>
			</LabeledValue>
		</IncidentBoxContent>
	);
};



export default WebsiteHasTroubleResponding;
