import React from 'react';

import LevelIndicator from '~/components/patterns/values/LevelIndicator';

import {
	SENSITIVITY_LEVELS,
	type SENSITIVITY_LEVEL_ALWAYS,
	type SENSITIVITY_LEVEL_HIGH,
	type SENSITIVITY_LEVEL_LOW,
	type SENSITIVITY_LEVEL_MEDIUM,
	type SENSITIVITY_LEVEL_NEVER,
	getNumberOfSensitivityLevel,
} from '~/model/alerts';



type Props = {
	sensitivity:
		| typeof SENSITIVITY_LEVEL_ALWAYS
		| typeof SENSITIVITY_LEVEL_HIGH
		| typeof SENSITIVITY_LEVEL_LOW
		| typeof SENSITIVITY_LEVEL_MEDIUM
		| typeof SENSITIVITY_LEVEL_NEVER,
};

const SensitivityIndicator: React.FC<Props> = (props) => {
	const {
		sensitivity,
	} = props;

	return (
		<LevelIndicator
			level={getNumberOfSensitivityLevel(sensitivity)}
			max={SENSITIVITY_LEVELS.size - 1}
		/>
	);
};



export default SensitivityIndicator;
