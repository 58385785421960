import Immutable from 'immutable';

import decodeFromBase64 from '~/utilities/decodeFromBase64';
import encodeInBase64 from '~/utilities/encodeInBase64';



export function decodeFilterParameter(filterParameter) {
	const result = {
		filter: null,
		sortBy: null,
	};

	if (!filterParameter) {
		return result;
	}

	let decodedFilterParameter;

	try {
		decodedFilterParameter = JSON.parse(
			decodeFromBase64(filterParameter),
		);
	} catch (e) {
		decodedFilterParameter = {
			filter: {},
			sortBy: {},
		};
	}

	if (decodedFilterParameter.filter) {
		result.filter = Immutable.fromJS(decodedFilterParameter.filter);
	}

	if (decodedFilterParameter.sortBy) {
		result.sortBy = Immutable.fromJS(decodedFilterParameter.sortBy);
	}

	return result;
}



export function encodeFilterParameter({ defaultFilter, defaultSortBy, filter, sortBy }) {
	filter = filter ? Immutable.fromJS(filter).filter((value) => value !== '') : defaultFilter;
	sortBy = sortBy || defaultSortBy;

	const filterIsSame = JSON.stringify(filter.toJS()) === JSON.stringify(defaultFilter.toJS());
	const sortingIsSame = (!sortBy && !defaultSortBy) || (sortBy.get('key') === defaultSortBy.get('key') && sortBy.get('direction') === defaultSortBy.get('direction'));

	if (filterIsSame && sortingIsSame) {
		return false;
	}

	if (filter || sortBy) {
		return encodeInBase64(
			JSON.stringify({
				filter: filter ? filter.toJS() : null,
				sortBy: sortBy ? sortBy.toJS() : null,
			}),
		);
	}

	return false;
}
