/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpenGraphBlockPartPrimaryQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type OpenGraphBlockPartPrimaryQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageType: GraphQL.PageType, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly primaryProperties: { readonly __typename?: 'OnPageProperties', readonly openGraphDescription: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphImage: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphTitle: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphType: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphUrl: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> } } } | null } | null };

export type OpenGraphBlockPartSrcQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type OpenGraphBlockPartSrcQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageType: GraphQL.PageType, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly srcProperties: { readonly __typename?: 'OnPageProperties', readonly openGraphDescription: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphImage: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphTitle: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphType: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphUrl: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> } } } | null } | null };

export type OpenGraphqBlockPrimaryPropertiesFragmentFragment = { readonly __typename?: 'OnPageProperties', readonly openGraphDescription: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphImage: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphTitle: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphType: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly openGraphUrl: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> } };

export type ContentItemsCollectionFragmentFragment = { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> };

export const ContentItemsCollectionFragmentFragmentDoc = gql`
    fragment ContentItemsCollectionFragment on ContentItemsCollection {
  contentItems {
    content
    position
  }
  isExpectedToBeNonEmpty
  type
}
    `;
export const OpenGraphqBlockPrimaryPropertiesFragmentFragmentDoc = gql`
    fragment OpenGraphqBlockPrimaryPropertiesFragment on OnPageProperties {
  openGraphDescription {
    ...ContentItemsCollectionFragment
  }
  openGraphImage {
    ...ContentItemsCollectionFragment
  }
  openGraphTitle {
    ...ContentItemsCollectionFragment
  }
  openGraphType {
    ...ContentItemsCollectionFragment
  }
  openGraphUrl {
    ...ContentItemsCollectionFragment
  }
}
    ${ContentItemsCollectionFragmentFragmentDoc}`;
export const OpenGraphBlockPartPrimaryDocument = gql`
    query OpenGraphBlockPartPrimary($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageType
    pageTypeData {
      primaryProperties {
        ...OpenGraphqBlockPrimaryPropertiesFragment
      }
    }
  }
}
    ${OpenGraphqBlockPrimaryPropertiesFragmentFragmentDoc}`;

/**
 * __useOpenGraphBlockPartPrimaryQuery__
 *
 * To run a query within a React component, call `useOpenGraphBlockPartPrimaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenGraphBlockPartPrimaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenGraphBlockPartPrimaryQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useOpenGraphBlockPartPrimaryQuery(baseOptions: Apollo.QueryHookOptions<OpenGraphBlockPartPrimaryQuery, OpenGraphBlockPartPrimaryQueryVariables> & ({ variables: OpenGraphBlockPartPrimaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenGraphBlockPartPrimaryQuery, OpenGraphBlockPartPrimaryQueryVariables>(OpenGraphBlockPartPrimaryDocument, options);
      }
export function useOpenGraphBlockPartPrimaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenGraphBlockPartPrimaryQuery, OpenGraphBlockPartPrimaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenGraphBlockPartPrimaryQuery, OpenGraphBlockPartPrimaryQueryVariables>(OpenGraphBlockPartPrimaryDocument, options);
        }
export function useOpenGraphBlockPartPrimarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OpenGraphBlockPartPrimaryQuery, OpenGraphBlockPartPrimaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OpenGraphBlockPartPrimaryQuery, OpenGraphBlockPartPrimaryQueryVariables>(OpenGraphBlockPartPrimaryDocument, options);
        }
export type OpenGraphBlockPartPrimaryQueryHookResult = ReturnType<typeof useOpenGraphBlockPartPrimaryQuery>;
export type OpenGraphBlockPartPrimaryLazyQueryHookResult = ReturnType<typeof useOpenGraphBlockPartPrimaryLazyQuery>;
export type OpenGraphBlockPartPrimarySuspenseQueryHookResult = ReturnType<typeof useOpenGraphBlockPartPrimarySuspenseQuery>;
export type OpenGraphBlockPartPrimaryQueryResult = Apollo.QueryResult<OpenGraphBlockPartPrimaryQuery, OpenGraphBlockPartPrimaryQueryVariables>;
export const OpenGraphBlockPartSrcDocument = gql`
    query OpenGraphBlockPartSrc($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageType
    pageTypeData {
      srcProperties {
        ...OpenGraphqBlockPrimaryPropertiesFragment
      }
    }
  }
}
    ${OpenGraphqBlockPrimaryPropertiesFragmentFragmentDoc}`;

/**
 * __useOpenGraphBlockPartSrcQuery__
 *
 * To run a query within a React component, call `useOpenGraphBlockPartSrcQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenGraphBlockPartSrcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenGraphBlockPartSrcQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useOpenGraphBlockPartSrcQuery(baseOptions: Apollo.QueryHookOptions<OpenGraphBlockPartSrcQuery, OpenGraphBlockPartSrcQueryVariables> & ({ variables: OpenGraphBlockPartSrcQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenGraphBlockPartSrcQuery, OpenGraphBlockPartSrcQueryVariables>(OpenGraphBlockPartSrcDocument, options);
      }
export function useOpenGraphBlockPartSrcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenGraphBlockPartSrcQuery, OpenGraphBlockPartSrcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenGraphBlockPartSrcQuery, OpenGraphBlockPartSrcQueryVariables>(OpenGraphBlockPartSrcDocument, options);
        }
export function useOpenGraphBlockPartSrcSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OpenGraphBlockPartSrcQuery, OpenGraphBlockPartSrcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OpenGraphBlockPartSrcQuery, OpenGraphBlockPartSrcQueryVariables>(OpenGraphBlockPartSrcDocument, options);
        }
export type OpenGraphBlockPartSrcQueryHookResult = ReturnType<typeof useOpenGraphBlockPartSrcQuery>;
export type OpenGraphBlockPartSrcLazyQueryHookResult = ReturnType<typeof useOpenGraphBlockPartSrcLazyQuery>;
export type OpenGraphBlockPartSrcSuspenseQueryHookResult = ReturnType<typeof useOpenGraphBlockPartSrcSuspenseQuery>;
export type OpenGraphBlockPartSrcQueryResult = Apollo.QueryResult<OpenGraphBlockPartSrcQuery, OpenGraphBlockPartSrcQueryVariables>;