import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import ChangeSummary, {
	PAGES_DIFF_BUNDLE,
} from '../pricing/ChangeSummary';
import Copy from '../Copy';
import MoneyStuff from '~/components/app/MoneyStuff';
import SubscriptionChangeSummary from '~/components/app/SubscriptionChangeSummary';
import WithBillingDetails from '../WithBillingDetails';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import usePageBundle from '~/hooks/usePageBundle';

import {
	createPurchase,
} from '~/model/pricing/costs';

import {
	DETAIL_PLAN,
} from '~/model/pricing/universal';



const messages = defineMessages({
	overMaximumMessage: {
		id: 'ui.websites.new.changeSummary.overMaximumPages.message',
	},
	overMaximumTitle: {
		id: 'ui.general.caution',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	pageCapacity: number,
	websites?: number,
};

const PageBundleChangeSummary: React.FC<Props> = (props) => {
	const {
		accountId,
		pageCapacity,
		websites = 0,
	} = props;

	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountBillingEntity = useAccountBillingEntity(accountId);
	const accountCurrency = useAccountCurrency(accountId);
	const accountPageBundle = usePageBundle(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);

	if (
		accountBillingCycle === null
		|| accountBillingEntity === null
		|| accountCurrency === null
		|| accountId === null
		|| accountPageBundle === null
		|| accountPhase === null
		|| accountPlan === null
		|| accountWebsiteSizes === null
		|| allowedBillingCycles === null
		|| pageCapacity === 0
	) {
		return null;
	}

	if (accountPhase === GraphQL.AccountPhase.Trial) {
		return null;
	}

	return (
		<MoneyStuff>
			{({ tariff }) => {
				const normalizedPageCapacity = tariff.normalizePageCapacity({
					pageCapacity,
				});

				const pageBundleUsage = accountWebsiteSizes.totalPageCapacity;

				const numberOfPages = pageBundleUsage + normalizedPageCapacity;

				if (numberOfPages > tariff.maximumPagesAmount && websites > 0) {
					return (
						<CalloutMessage
							borders={true}
							highlightedBorders={true}
							message={<FormattedMessage {...messages.overMaximumTitle} />}
							status={CalloutMessageStatus.Critical}
						>
							<Copy
								{...messages.overMaximumMessage}
								values={{
									countWebsites: websites,
									maximumNumberOfPages: tariff.maximumPagesAmount,
									numberOfPages,
								}}
							/>
						</CalloutMessage>
					);
				}

				const websitesOld = accountWebsiteSizes.websites.length;
				const websitesNew = websitesOld + websites;

				const pagesOld = accountPageBundle;

				const pagesNew = Math.max(pagesOld, tariff.normalizePagesAmount({
					numberOfPages: pageBundleUsage + normalizedPageCapacity,
				}));

				if (accountBillingEntity !== 'old') {
					return (
						<SubscriptionChangeSummary
							accountId={accountId}
							additionalWebsitesPageCapacity={pageCapacity}
							extraWebsites={websites}
							hidePrice={false}
							pageBundle={pagesNew}
						/>
					);
				}

				if (websites === 0 && pagesOld >= pagesNew) {
					return null;
				}

				return (
					<WithBillingDetails useChildrenAsPlaceholder={false}>
						{({ billingDetails }) => {
							const discounts = billingDetails.get('discounts')
								?.filter((discount) => discount.get('type') !== 'dollars') ?? [];
							let taxRate = billingDetails.get('tax');

							if (typeof taxRate === 'object') {
								taxRate = taxRate.get('rate');
							}

							const priceOld = tariff.calculatePurchaseCostDetails({
								billingCycle: accountBillingCycle,
								discounts,
								purchases: [
									createPurchase({
										details: {
											[DETAIL_PLAN]: accountPlan,
										},
										numberOfPages: pagesOld,
									}),
								],
								taxRate,
							});

							const priceNew = tariff.calculatePurchaseCostDetails({
								billingCycle: accountBillingCycle,
								discounts,
								purchases: [
									createPurchase({
										details: {
											[DETAIL_PLAN]: accountPlan,
										},
										numberOfPages: pagesNew,
									}),
								],
								taxRate,
							});

							return (
								<ChangeSummary
									accountId={accountId}
									additionalWebsitesPageCapacity={pageCapacity}
									allowedBillingCycles={allowedBillingCycles}
									billingCycleNew={accountBillingCycle}
									billingCycleOld={accountBillingCycle}
									currency={accountCurrency}
									hidePrice={false}
									pagesDiff={PAGES_DIFF_BUNDLE}
									pagesNew={pagesNew}
									pagesOld={pagesOld}
									planNew={accountPlan}
									planOld={accountPlan}
									priceNew={priceNew.subtotal}
									priceOld={priceOld.subtotal}
									taxType={priceNew.taxType}
									websitesNew={websitesNew}
									websitesOld={websitesOld}
								/>
							);
						}}
					</WithBillingDetails>
				);
			}}
		</MoneyStuff>
	);
};



export default PageBundleChangeSummary;
