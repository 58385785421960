import {
	differenceInDays,
	endOfYear,
	isAfter,
	isBefore,
	isFuture,
	isMonday,
	isSameDay,
	isToday,
	startOfYear,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DateRangeCalendarPicker, {
	type Props as DateRangeCalendarPickerProps,
	DateRangeCalenderPickerControl,
} from '~/components/patterns/forms/fieldParts/DateRangeCalendarPicker';

import usePredefinedDateRanges from '~/hooks/usePredefinedDateRanges';

import {
	DateRangePreset,
	findPredefinedRangeForDates,
	getDateRangeDefinition,
} from '~/model/dateRangePresets';



const messages = defineMessages({
	disabledExplanation: {
		id: 'ui.dateRange.unavailable',
	},
});



function areDatesCompatible(startDate: Date, endDate: Date): boolean {
	if (isToday(endDate)) {
		return true;
	}

	if (isSameDay(startDate, endDate)) {
		return true;
	}

	if (isAfter(startDate, endDate)) {
		return false;
	}

	const now = new Date();
	if (differenceInDays(now, startDate) < 15) {
		return true;
	}

	if (isMonday(startDate) && isMonday(endDate)) {
		return true;
	}

	return false;
}



type Props = {
	endDate: Date | null,
	minDate: Date,
	onRangeSelect: (range: {
		startDate: Date | null,
		endDate: Date | null,
	}) => void,
	startDate: Date | null,
};

const ChangeTrackingCalendar: React.FC<Props> = (props) => {
	const {
		endDate,
		minDate,
		onRangeSelect,
		startDate,
	} = props;

	const predefinedDateRanges = usePredefinedDateRanges(
		minDate,
		[DateRangePreset.Everything],
	);

	const presetsOptions = React.useMemo(
		() => {
			const selectedDateRangeName = findPredefinedRangeForDates(
				startDate,
				endDate,
				minDate,
				[DateRangePreset.Everything],
			);

			return predefinedDateRanges.map((dateRange) => ({
				disabled: dateRange.disabled,
				disabledExplanation: dateRange.disabled ? (
					<FormattedMessage {...messages.disabledExplanation} />
				) : null,
				highlighted: dateRange.name === selectedDateRangeName,
				label: dateRange.label,
				value: dateRange.name,
			}));
		},
		[
			endDate,
			minDate,
			predefinedDateRanges,
			startDate,
		],
	);

	const handlePresetSelect = React.useCallback(
		(preset: DateRangePreset) => {
			if (preset !== DateRangePreset.Custom) {
				const dateRangeDefinition = getDateRangeDefinition(preset);

				const startDate = dateRangeDefinition.startDate(minDate);
				const endDate = dateRangeDefinition.endDate();

				onRangeSelect({
					startDate,
					endDate,
				});
			}
		},
		[
			minDate,
			onRangeSelect,
		],
	);

	const handleRangeSelect = React.useCallback<DateRangeCalendarPickerProps['onRangeSelect']>(
		({ startDate, endDate }) => {
			if (startDate !== null && endDate !== null) {
				const compatibleDates = areDatesCompatible(startDate, endDate);

				if (!compatibleDates) {
					onRangeSelect({
						startDate,
						endDate: startDate,
					});

					return;
				}
			}

			onRangeSelect({
				startDate,
				endDate,
			});
		},
		[
			onRangeSelect,
		],
	);

	const disabledDate = React.useCallback<
		Exclude<DateRangeCalendarPickerProps['disabledDate'], undefined>
	>(
		(date, { startDate, activeControl }) => {
			const now = new Date();

			// enable today
			if (isToday(date)) {
				return false;
			}

			// disable dates in the future
			if (isFuture(date)) {
				return true;
			}

			// disable dates before the creation of the website
			if (isBefore(date, minDate)) {
				return true;
			}

			if (activeControl === DateRangeCalenderPickerControl.Start) {
				// enable previous 15 days
				if (differenceInDays(now, date) < 15) {
					return false;
				}

				// enable every monday
				if (isMonday(date)) {
					return false;
				}

				return true;
			}

			if (startDate === null) {
				// enable previous 15 days
				if (differenceInDays(now, date) <= 15) {
					return false;
				}

				// enable every monday
				if (isMonday(date)) {
					return false;
				}
			} else {
				return !areDatesCompatible(startDate, date);
			}

			return false;
		},
		[
			minDate,
		],
	);

	return (
		<DateRangeCalendarPicker
			disabledDate={disabledDate}
			endDate={endDate}
			lowerBound={startOfYear(minDate)}
			onPresetSelect={handlePresetSelect}
			onRangeSelect={handleRangeSelect}
			presetsOptions={presetsOptions}
			startDate={startDate}
			upperBound={endOfYear(new Date())}
		/>
	);
};



export default ChangeTrackingCalendar;
