import React from 'react';



type Props = {
	cancelButton: React.ReactNode,
	label: React.ReactNode,
	range: React.ReactNode,
	toggleButton: React.ReactNode,
};

const DateRangeFilterLayout: React.FC<Props> = (props) => {
	const {
		cancelButton,
		label,
		range,
		toggleButton,
	} = props;

	return (
		<div className="date-range-filter">
			<div className="date-range-filter__date">
				<div className="date-range-filter__label">
					{label}
				</div>
				<div className="date-range-filter__range">
					{range}
				</div>
			</div>
			<div className="date-range-filter__buttons">
				{toggleButton}
				{cancelButton}
			</div>
		</div>
	);
};



export default DateRangeFilterLayout;
