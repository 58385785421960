import React from 'react';

import CodeSnippets from '~/components/patterns/structuredValues/CodeSnippets';
import Value from './Value';



type Props = {
	elementLabel: React.ReactNode,
	elements: ReadonlyArray<React.ReactNode>,
};

const TooManyCase: React.FC<Props> = (props) => {
	const {
		elementLabel,
		elements,
	} = props;

	return (
		<CodeSnippets
			snippets={elements.map((element, i) => {
				return {
					label: (
						<span>
							{elementLabel}
							<span>&nbsp;#{i + 1}</span>
						</span>
					),
					content: (
						<Value>
							{element}
						</Value>
					),
				};
			})}
		/>
	);
};



export default TooManyCase;
