import React from 'react';

import {
	type Context,
	ManuallyTrackedLoadingContext,
} from '~/components/app/ManuallyTrackedLoading';



function useManuallyTrackedLoading(): Context | null {
	return React.useContext(ManuallyTrackedLoadingContext);
}



export default useManuallyTrackedLoading;
