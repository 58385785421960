import React from 'react';

import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';



type Props = {
	explanation: React.ReactNode,
	children: React.ReactNode,
};



const ExplainedValue: React.FC<Props> = (props) => {
	const {
		explanation,
		children,
	} = props;

	return (
		<Hint
			attachment={HintAttachment.Left}
			popup={explanation}
		>
			<span className="explained-value">
				{children}
			</span>
		</Hint>
	);
};



export default ExplainedValue;
