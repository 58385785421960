import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import Backlink from '~/components/logic/links/Backlink';
import EditInWordpressButton from '~/components/app/PageDetail/EditInWordpressButton';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderScoreInfo from '~/components/atoms/screenLayouts/components/header/HeaderScoreInfo';
import OpenPageButton from '~/components/app/PageDetail/OpenPageButton';
import PageHeaderTitleRoyalEnhancement from '~/components/app/PageDetail/PageHeaderTitleRoyalEnhancement';
import PageTitle from './PageTitle';
import PageDetailHeader from '~/components/patterns/screens/pageDetail/header/PageDetailHeader';

import useGetIssueCategory from '~/hooks/useGetIssueCategory';
import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageHealth from '~/hooks/usePageHealth';
import usePageIssueCategories from '~/hooks/usePageIssueCategories';
import usePageType from '~/hooks/usePageType';
import usePageUrl from '~/hooks/usePageUrl';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';

import {
	animateHeartbeatSelector,
	hoveredCategorySelector,
} from '~/state/ui/selectors';



const openedPageIssuesCategorySelector = (state) => {
	return state.get('ui')
		.get('content')
		.get('pageDetailIssuesCategoryType');
};



const messages = defineMessages({
	score: {
		id: 'ui.pageDetail.score',
	},
});



type Props = {
	backlink?: string,
	closeCallback: () => void,
	footer?: React.ReactNode,
};

const PageHeader: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
		footer,
	} = props;

	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const pageHealth = usePageHealth(legacyUrlId, websiteId);
	const pageIssueCategories = usePageIssueCategories(legacyUrlId, websiteId);
	const pageUrl = usePageUrl(legacyUrlId, websiteId);
	const pageType = usePageType(legacyUrlId, websiteId);

	const animateHeartbeat = useSelector(animateHeartbeatSelector);
	const hoveredIssueCategoryName = useSelector(hoveredCategorySelector);
	const openedIssueCategoryName = useSelector(openedPageIssuesCategorySelector);
	const websiteIsPaused = useWebsiteIsCrawlingPaused(websiteId) ?? false;
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const hoveredIssueCategory = useGetIssueCategory(
		pageIssueCategories,
		hoveredIssueCategoryName ?? null,
	);

	const openedIssueCategory = useGetIssueCategory(
		pageIssueCategories,
		openedIssueCategoryName ?? null,
	);

	let scoreImpact = 0;

	if (pageHealth !== null) {
		if (hoveredIssueCategory !== null) {
			scoreImpact = hoveredIssueCategory.pointsToGain + pageHealth;
		} else if (openedIssueCategory !== null) {
			scoreImpact = openedIssueCategory.pointsToGain + pageHealth;
		}
	}

	let closeLink;

	if (backlink) {
		closeLink = (
			<Backlink
				callback={closeCallback}
				label={backlink}
			/>
		);
	}

	let score: React.ReactNode = null;

	if (pageType === GraphQL.PageType.Page) {
		score = (
			<HeaderScoreInfo
				animateHeartbeat={animateHeartbeat}
				impact={scoreImpact}
				key={websiteId + '-' + legacyUrlId}
				label={(
					<FormattedMessage {...messages.score} />
				)}
				maximum={1000}
				paused={websiteIsPaused}
				value={pageHealth}
			/>
		);
	} else if (pageType === GraphQL.PageType.Unknown) {
		score = (
			<HeaderScoreInfo
				key={websiteId + '-' + legacyUrlId}
				label={(
					<FormattedMessage {...messages.score} />
				)}
			/>
		);
	}

	const actionButtons: Array<React.ReactNode> = [];

	actionButtons.push(
		<OpenPageButton
			key="open-page-button"
			url={pageUrl}
		/>,
	);

	const wordpressStatus = websiteIntegrations.getStatus(GraphQL.WebsiteIntegrationType.Wordpress);

	if (
		wordpressStatus === GraphQL.WebsiteIntegrationStatus.Available
		|| wordpressStatus === GraphQL.WebsiteIntegrationStatus.Enabled
	) {
		actionButtons.push(
			<EditInWordpressButton
				key="wordpressButton"
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>,
		);
	}

	return (
		<PageDetailHeader
			buttons={actionButtons.length > 0 && (
				<HeaderButtonsLayout>
					{actionButtons}
				</HeaderButtonsLayout>
			)}
			footer={footer}
			score={score}
			title={(
				<HeaderTitleLayout>
					{closeLink}

					<PageHeaderTitleRoyalEnhancement
						legacyUrlId={legacyUrlId}
						websiteId={websiteId}
					>
						<PageTitle
							legacyUrlId={legacyUrlId}
							websiteId={websiteId}
						/>
					</PageHeaderTitleRoyalEnhancement>
				</HeaderTitleLayout>
			)}
		/>
	);
};



export default PageHeader;
