import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import tinycolor from 'tinycolor2';



class SegmentFilterSegment extends Component {

	_isColorTooLight(colorString) {
		const color = tinycolor(colorString);
		return color.getBrightness() > 180;
	}



	_renderSymbol() {
		const {
			symbol,
		} = this.props;

		if (!symbol) {
			return false;
		}

		return (
			<span className="segment-filter-segment__symbol">
				<span className="segment-filter-segment__symbol-text">
					{symbol}
				</span>
			</span>
		);
	}



	_renderLabel(label) {
		return (
			<span className="segment-filter-segment__label">
				<span>{label}</span>
			</span>
		);
	}



	render() {
		const {
			children,
			color,
			inversedColors,
			label,
		} = this.props;

		const style = {};

		if (color) {
			style.backgroundColor = color;
		}

		const componentClasses = classNames({
			'segment-filter-segment': true,
			'segment-filter-segment--has-label': label,
			'segment-filter-segment--use-dark-font': this._isColorTooLight(color),
			'segment-filter-segment--inversed-style': inversedColors,
		});

		return (
			<div
				className={componentClasses}
				style={style}
			>
				{this._renderSymbol()}
				{label && this._renderLabel(label)}
				{children && (
					<span className="segment-filter-segment__content">
						{children}
					</span>
				)}
			</div>
		);
	}

}

SegmentFilterSegment.propTypes = {
	color: PropTypes.string,
	inversedColors: PropTypes.bool,
	label: PropTypes.node,
	/** Additional symbol for better differentiation of displaying segment */
	symbol: PropTypes.node,
};



export default SegmentFilterSegment;
