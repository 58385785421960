import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	type ValidationBuilder,
} from '~/components/app/validations';



const messages = defineMessages({
	passwordMustContainDigit: {
		id: 'ui.formErrors.passwordMustContainDigit',
	},
	passwordMustContainLowerCaseLetter: {
		id: 'ui.formErrors.passwordMustContainLowerCaseLetter',
	},
	passwordMustContainSpecialCharacter: {
		id: 'ui.formErrors.passwordMustContainSpecialCharacter',
	},
	passwordMustContainUpperCaseLetter: {
		id: 'ui.formErrors.passwordMustContainUpperCaseLetter',
	},
});



export function validatePassword(f: ValidationBuilder) {
	return [
		f.validateNonEmpty(),
		f.validateMinimumLength(8),
		f.validateMaximumLength(64),
		f.custom({
			message: (
				<FormattedMessage {...messages.passwordMustContainDigit} />
			),
			rule: ({ value }) => /.*\d{1}.*/.test(value),
		}),
		f.custom({
			message: (
				<FormattedMessage {...messages.passwordMustContainLowerCaseLetter} />
			),
			rule: ({ value }) => value.split('').some((letter) => letter !== letter.toUpperCase()),
		}),
		f.custom({
			message: (
				<FormattedMessage {...messages.passwordMustContainUpperCaseLetter} />
			),
			rule: ({ value }) => value.split('').some((letter) => letter !== letter.toLowerCase()),
		}),
		f.custom({
			message: (
				<FormattedMessage {...messages.passwordMustContainSpecialCharacter} />
			),
			rule: ({ value }) => value.split('').some(
				(letter) => (
					/\d/.test(letter) === false
					&& /\s/.test(letter) === false
					&& letter === letter.toLowerCase()
					&& letter === letter.toUpperCase()
				),
			),
		}),
	];
}
