import {
	runAction,
} from '~/model/actions';

import {
	tryLoadUserData,
} from '~/model/authentication';

import {
	shouldHintNotBeDisplayedAnymore,
} from '~/model/interfaceHints';

import {
	interfaceHintsSelector,
} from '~/state/ui/interfaceHints/selectors';

import {
	userInfoSelector,
} from '~/state/authentication/selectors';



export const MARK_INTERFACE_HINT_AS_DISPLAYED = 'MARK_INTERFACE_HINT_AS_DISPLAYED';
export const SET_INTERFACE_HINT_AS_CLOSED = 'SET_INTERFACE_HINT_AS_CLOSED';
export const SET_INTERFACE_HINT_AS_OPENED = 'SET_INTERFACE_HINT_AS_OPENED';



export function tryHidingInterfaceHint(hint, conditions, context) {
	return (dispatch, getState) => {
		if (shouldHintNotBeDisplayedAnymore(
			hint,
			conditions,
			context,
			interfaceHintsSelector(getState()).get('hints'),
		)) {
			dispatch(
				markInterfaceHintAsDisplayed(hint),
			);
		}
	};
}



export function markInterfaceHintAsDisplayed(hintName) {
	return (dispatch, getState) => {
		dispatch({
			type: MARK_INTERFACE_HINT_AS_DISPLAYED,
			name: hintName,
		});

		const isImpersonated = userInfoSelector(getState()).get('is_impersonated') === true;
		if (isImpersonated) {
			return false;
		}

		const email = userInfoSelector(getState()).get('email');

		return runAction({
			action: 'RememberDisplayedInterfaceHints',
			input: {
				email,
				interfaceHints: [hintName],
			},
		}).then(() => {
			return dispatch(
				tryLoadUserData(),
			);
		});
	};
}



export function setInterfaceHintAsClosed() {
	return {
		type: SET_INTERFACE_HINT_AS_CLOSED,
	};
}



export function setInterfaceHintAsOpened(hintName) {
	return {
		type: SET_INTERFACE_HINT_AS_OPENED,
		name: hintName,
	};
}
