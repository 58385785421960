/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateConnectedClientMutationVariables = GraphQL.Exact<{
  acceptedLegalDocuments: ReadonlyArray<GraphQL.LegalDocumentType> | GraphQL.LegalDocumentType;
  agencyId: GraphQL.Scalars['AccountId']['input'];
  clientContactEmail: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  clientAccountName: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  domain: GraphQL.Scalars['String']['input'];
}>;


export type CreateConnectedClientMutation = { readonly __typename?: 'Mutation', readonly CreateConnectedClient: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly clientConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessClientConnection', readonly clientAccountId: CK.AccountId, readonly clientAccountName: string, readonly id: any, readonly inviterEmail: string | null, readonly isInitiatedByAgency: boolean, readonly isPending: boolean, readonly level: GraphQL.AccountAccessConnectionLevel }>, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null, readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId }> } | null } } };


export const CreateConnectedClientDocument = gql`
    mutation CreateConnectedClient($acceptedLegalDocuments: [LegalDocumentType!]!, $agencyId: AccountId!, $clientContactEmail: String, $clientAccountName: String, $domain: String!) {
  CreateConnectedClient(
    acceptedLegalDocuments: $acceptedLegalDocuments
    agencyId: $agencyId
    clientContactEmail: $clientContactEmail
    clientAccountName: $clientAccountName
    domain: $domain
  ) {
    query {
      account(id: $agencyId) {
        clientConnections {
          clientAccountId
          clientAccountName
          id
          inviterEmail
          isInitiatedByAgency
          isPending
          level
        }
        features {
          feature
          id
          inUse
        }
        id
      }
      authenticatedSession {
        accounts {
          id
        }
      }
    }
  }
}
    `;
export type CreateConnectedClientMutationFn = Apollo.MutationFunction<CreateConnectedClientMutation, CreateConnectedClientMutationVariables>;

/**
 * __useCreateConnectedClientMutation__
 *
 * To run a mutation, you first call `useCreateConnectedClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectedClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectedClientMutation, { data, loading, error }] = useCreateConnectedClientMutation({
 *   variables: {
 *      acceptedLegalDocuments: // value for 'acceptedLegalDocuments'
 *      agencyId: // value for 'agencyId'
 *      clientContactEmail: // value for 'clientContactEmail'
 *      clientAccountName: // value for 'clientAccountName'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useCreateConnectedClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateConnectedClientMutation, CreateConnectedClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConnectedClientMutation, CreateConnectedClientMutationVariables>(CreateConnectedClientDocument, options);
      }
export type CreateConnectedClientMutationHookResult = ReturnType<typeof useCreateConnectedClientMutation>;
export type CreateConnectedClientMutationResult = Apollo.MutationResult<CreateConnectedClientMutation>;
export type CreateConnectedClientMutationOptions = Apollo.BaseMutationOptions<CreateConnectedClientMutation, CreateConnectedClientMutationVariables>;