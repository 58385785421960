import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import TextField, {
	type TextFieldRef,
	TextFieldSize,
} from '~/components/atoms/forms/components/TextField';



const messages = defineMessages({
	placeholder: {
		id: 'ui.contentOverview.tableFilter.placeholder',
	},
});



type Props = {
	name: string,
	placeholder?: string,
	width: number,
};

const PlainTextFieldFilter = React.forwardRef<TextFieldRef, Props>((props, ref) => {
	const intl = useIntl();

	const {
		name,
		placeholder,
		width,
	} = props;

	return (
		<TextField
			attributes={{
				maxLength: 1000,
			}}
			name={name}
			placeholder={placeholder || intl.formatMessage(messages.placeholder)}
			ref={ref}
			size={TextFieldSize.Small}
			width={width}
		/>
	);
});



export default PlainTextFieldFilter;
