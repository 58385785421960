import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CurrencySelectForm from '~/components/atoms/forms/forms/CurrencySelectForm';
import HiddenContent from '~/components/patterns/content/HiddenContent';

import useAccountSignup from '~/hooks/useAccountSignup';
import useAllowedCurrencies from '~/hooks/useAllowedCurrencies';
import useSignupCurrency from '~/hooks/useSignupCurrency';



const messages = defineMessages({
	switchCurrency: {
		id: 'ui.billing.switchCurrencyLink',
	},
});



type Props = {
	accountId: CK.AccountId,
};

const CurrencyConfigurator: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountSignup = useAccountSignup(accountId);
	const allowedCurrencies = useAllowedCurrencies(accountId);
	const signupCurrency = useSignupCurrency(accountId);

	const accountSignupSetProperty = accountSignup.setProperty;

	const handleChangeCurrency = React.useCallback(
		(currency) => {
			accountSignupSetProperty('currency', currency);
		},
		[
			accountSignupSetProperty,
		],
	);

	if (
		allowedCurrencies === null
		|| allowedCurrencies.length === 1
		|| signupCurrency === null
	) {
		return null;
	}

	return (
		<HiddenContent
			hiddenContent={true}
			toggleLinkLabel={(
				<FormattedMessage {...messages.switchCurrency} />
			)}
		>
			<CurrencySelectForm
				allowedCurrencies={allowedCurrencies}
				defaultCurrency={signupCurrency}
				onChangeCallback={handleChangeCurrency}
			/>
		</HiddenContent>
	);
};



export default CurrencyConfigurator;
