import classNames from 'classnames';
import React from 'react';



export enum FormWrapperContainerSize {
	Form = 'form',
	Large = 'large',
	XLarge = 'xlarge',
}

type Props = React.PropsWithChildren<{
	/** Edit mode highlight */
	editMode?: boolean,
	/** Optional onClick callback triggered when clicking on box */
	onClickCallback?: (e: MouseEvent) => void,
	/** Optional onKeyUp callback triggered when revealed key on focused box */
	onKeyUpCallback?: (e: MouseEvent) => void,
	size?: FormWrapperContainerSize,
}>;



const FormWrapperContainer = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		editMode,
		children,
		onClickCallback,
		onKeyUpCallback,
		size,
	} = props;

	const clickHandler = (e) => {
		if (onClickCallback) {
			onClickCallback(e);
		}
	};

	const keyUpHandler = (e) => {
		e.preventDefault();

		if (onKeyUpCallback) {
			onKeyUpCallback(e);
		}
	};

	const componentClasses = classNames({
		'form-wrapper-container': true,
		'form-wrapper-container--in-edit-mode': editMode, // this class is used on ESC key callback
		'form-wrapper-container--is-clickable': onClickCallback,
		['form-wrapper-container--' + size + '-size']: size,
	});

	return (
		<div
			className={componentClasses}
			onClick={clickHandler}
			onKeyUp={keyUpHandler}
			ref={ref}
			tabIndex={-1}
		>
			{children}
		</div>
	);
});



export default FormWrapperContainer;
