import classNames from 'classnames';
import React, {
	PureComponent,
} from 'react';

import {
	getValueByScoreCondition,
} from '~/utilities/scores';



export enum ScoreBarSize {
	Default = 'default',
	Large = 'large',
	Small = 'small',
}

type Props = {
	greyscale?: boolean,
	impact?: number,
	impactAnimation?: boolean,
	maximum?: number,
	size?: ScoreBarSize,
	value?: number,
};

type State = {
	animated: boolean,
};



class ScoreBar extends PureComponent<Props, State> {

	_isMounted: boolean;

	static defaultProps = {
		size: ScoreBarSize.Default,
	};



	constructor(props, context) {
		super(props, context);

		this._isMounted = false;

		this.state = {
			animated: false,
		};
	}



	componentDidMount() {
		const {
			value,
		} = this.props;

		this._isMounted = true;

		if (value !== undefined) {
			this.setState({
				animated: true,
			});
		}
	}



	componentDidUpdate(prevProps) {
		const {
			value: prevValue,
		} = prevProps;

		const {
			value,
		} = this.props;

		if (prevValue === undefined && value !== undefined) {
			// we have to set
			setTimeout(() => {
				if (!this._isMounted) {
					return;
				}

				this.setState({
					animated: true,
				});
			}, 200);
		}
	}



	componentWillUnmount() {
		this._isMounted = false;
	}



	_renderImpactBar(value, maximum) {
		const {
			impactAnimation,
		} = this.props;

		const impact = this.props.impact === undefined ? 0 : this.props.impact;

		const impactPercentage = parseInt(
			(impact / maximum * 100).toString(),
			10,
		);

		const impactClasses = classNames({
			'score-bar__impact': true,
			'score-bar__impact--animated': impactAnimation,
			'score-bar__impact--hidden': value >= impact,
		});

		return (
			<span
				className={impactClasses}
				style={{ width: impactPercentage + '%' }}
			>
			</span>
		);
	}



	render() {
		const {
			greyscale,
			impact,
			size,
		} = this.props;

		const {
			animated,
		} = this.state;

		const value = this.props.value === undefined ? 0 : this.props.value;
		const maximum = this.props.maximum === undefined ? 1 : this.props.maximum;

		const scorePercentage = parseInt(
			(value / maximum * 100).toString(),
			10,
		);

		const barClasses = classNames({
			'score-bar': true,
			'score-bar--animated': animated,
			'score-bar--large': size === ScoreBarSize.Large,
			'score-bar--small': size === ScoreBarSize.Small,
			'score-bar--greyscale': greyscale,
		});

		let scoreStateClass = {};

		if (!greyscale) {
			scoreStateClass = getValueByScoreCondition(
				value,
				maximum,
				{
					good: 'score-bar__value--state-good',
					fine: 'score-bar__value--state-fine',
					normal: 'score-bar__value--state-normal',
					worse: 'score-bar__value--state-worse',
					bad: 'score-bar__value--state-bad',
				},
			);
		}

		const spanClasses = classNames({
			'score-bar__value': true,
		}, scoreStateClass);

		return (
			<div className={barClasses}>
				<span
					className={spanClasses}
					style={{ width: scorePercentage + '%' }}
				>
				</span>
				{impact ? this._renderImpactBar(value, maximum) : false}
			</div>
		);
	}

}



export default ScoreBar;
