import classNames from 'classnames';
import React from 'react';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Colorizer, {
	ColorizerStyle,
} from '~/components/patterns/utils/Colorizer';
import HelpHint from '~/components/patterns/hints/HelpHint';



export enum AvailabilityLabelSize {
	Default = 'default',
	Small = 'small',
	XSmall = 'xsmall',
}

export enum AvailabilityLabelStatus {
	Available = 'available',
	Unavailable = 'unavailable',
	Warning = 'warning',
}

type Props = {
	children?: React.ReactNode,
	/** Message for optional hint attached to label */
	hint?: React.ReactNode,
	/** Disabled state of availability */
	isDisabled?: boolean,
	/** Availability status */
	status: AvailabilityLabelStatus,
	/** Label size */
	size?: AvailabilityLabelSize,
};



const iconMapping = {
	[AvailabilityLabelStatus.Available]: (
		<BasicIcon type={BasicIconType.Available} />
	),
	[AvailabilityLabelStatus.Unavailable]: (
		<BasicIcon type={BasicIconType.Unavailable} />
	),
	[AvailabilityLabelStatus.Warning]: (
		<BasicIcon
			color="#ff743b"
			type={BasicIconType.Exclamation}
		/>
	),
};



const AvailabilityLabel: React.FC<Props> = (props) => {
	const {
		children,
		hint,
		isDisabled,
		size = AvailabilityLabelSize.Default,
		status,
	} = props;

	let iconSize = 21;

	if (size === AvailabilityLabelSize.Small) {
		iconSize = 18;
	}

	if (size === AvailabilityLabelSize.XSmall) {
		iconSize = 15;
	}

	const icon = React.cloneElement(iconMapping[status], {
		grayscale: isDisabled,
		size: iconSize,
	});

	const content = (
		<Colorizer style={status === AvailabilityLabelStatus.Warning && !isDisabled ? ColorizerStyle.Warning : ColorizerStyle.Inherited}>
			{children}
		</Colorizer>
	);

	const componentClasses = classNames({
		'availability-label': true,
		['availability-label--' + size + '-size']: true,
	});

	return (
		<span className={componentClasses}>
			<span className="availability-label__icon">
				{icon}
			</span>
			<span className="availability-label__label">
				{hint ? (
					<AttachedElement
						element={(
							<HelpHint message={hint} />
						)}
					>
						{content}
					</AttachedElement>
				) : content}
			</span>
		</span>
	);
};



export default AvailabilityLabel;
