import React from 'react';

import EmptyValue from '~/components/app/EmptyValue';
import MissingValue from '~/components/app/MissingValue';
import TextInspector from '~/components/patterns/typography/TextInspector';



type Props = {
	children: React.ReactNode,
};

const Value: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	let output = children;

	if (output === null || output === undefined) {
		output = (
			<MissingValue />
		);
	}

	if (output === '') {
		output = (
			<EmptyValue />
		);
	}

	if (typeof output === 'string') {
		output = (
			<TextInspector text={output} />
		);
	}

	return (
		<span>
			{output}
		</span>
	);
};



export default Value;
