import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import MainNavigationLayout from '~/components/patterns/navigations/MainNavigationLayout';
import MenuItemLayout from '../builders/MenuItemLayout';
import MultiWebsiteDashboardLink from '~/components/app/MultiWebsiteDashboardLink';
import NavigationIcon, {
	NavigationIconType,
} from '~/components/patterns/icons/NavigationIcon';

import useUrlState from '~/hooks/useUrlState';



const messages = defineMessages({
	label: {
		id: 'ui.menu.multidashboard',
	},
});



const MultiwebsitesNavigation: React.FC = () => {
	const urlState = useUrlState();

	const isActive = urlState.name === 'multiDashboard';

	return (
		<MainNavigationLayout
			activeItemIndex={isActive ? 0 : undefined}
			primaryItems={[
				<MultiWebsiteDashboardLink key="multiwebsite-dashboard-link">
					{({ isActive, isDimmed }) => {
						return (
							<MenuItemLayout
								icon={(
									<NavigationIcon type={NavigationIconType.Websites} />
								)}
								isActive={isActive}
								isDimmed={isDimmed}
								label={(
									<FormattedMessage {...messages.label} />
								)}
							/>
						);
					}}
				</MultiWebsiteDashboardLink>,
			]}
		/>
	);
};



export default MultiwebsitesNavigation;
