import classNames from 'classnames';
import React, {
	PureComponent,
} from 'react';

import styles from './DatatableContainer.module.scss';



type Props = {
	children?: React.ReactNode,
	/** Make content disabled */
	disabled?: boolean,
	/** Defined height of container (it's taken from parent height by default) */
	height?: number,
	/** We can attach onClick callback to whole container */
	onClickCallback?: (e: Event) => void,
	/** Optional overlay (eg. for loaders) */
	overlay?: React.ReactNode,
	/** Defined width of container (it's taken from parent width by default) */
	width?: number,
};



class DatatableContainer extends PureComponent<Props> {

	constructor(props, context) {
		super(props, context);

		this._handleClick = this._handleClick.bind(this);
	}



	_handleClick(e) {
		const {
			disabled,
			onClickCallback,
		} = this.props;

		if (!onClickCallback || disabled) {
			return false;
		}

		onClickCallback(e);
	}



	render() {
		const {
			disabled,
			children,
			height,
			overlay,
			width,
		} = this.props;

		const componentClasses = classNames({
			[styles.component]: true,
			[styles.isDisabled]: disabled,
		});

		return (
			<div
				className={componentClasses}
				onClick={this._handleClick}
				style={{
					height,
					width,
				}}
			>
				<div
					className={styles.content}
					style={{
						height,
						width,
					}}
				>
					{children}
				</div>
				{overlay}
			</div>
		);
	}

}



export default DatatableContainer;
