import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import reactStringReplace from 'react-string-replace';

import NonPrintableSymbol, {
	NonPrintableSymbolType,
} from '~/components/patterns/typography/NonPrintableSymbol';



const messages = defineMessages({
	byteOrderMark: {
		id: 'ui.textInspector.bomCharacter',
		defaultMessage: 'This is a byte order mark (BOM) character.',
	},
	newLine: {
		id: 'ui.textInspector.newlineCharacter',
		defaultMessage: 'This is a newline character.',
	},
	tab: {
		id: 'ui.textInspector.tabCharacter',
		defaultMessage: 'This is a tab character.',
	},
	unknownCharacter: {
		id: 'ui.textInspector.unknownCharacter',
		defaultMessage: 'This is an unknown non-printable character.',
	},
});



const BOM_REGEXP_RULE = '\u00EF\u00BB\u00BF';
const NEWLINE_REGEXP_RULE = '\n';
const NON_PRINTABLE_REGEXP_RULE = '[\x00\x08\x0B\x0C\x0E-\x1F]|\r|\f|\v|\x07|\u2063';
const TAB_REGEXP_RULE = '\t';



type Props = {
	text: React.ReactNode,
};



const TextInspector: React.FC<Props> = (props) => {
	const {
		text,
	} = props;

	// fallback when text value isn't raw string
	if (typeof text !== 'string') {
		return (
			<>{text || null}</>
		);
	}

	let output: React.ReactNodeArray | string = text;

	// byte order mark (BOM)
	// https://en.wikipedia.org/wiki/Byte_order_mark
	output = reactStringReplace(output, new RegExp('(' + BOM_REGEXP_RULE + ')', 'g'), (match, i) => (
		<NonPrintableSymbol
			key={'unknown-char-' + i}
			message={(
				<FormattedMessage {...messages.byteOrderMark} />
			)}
			type={NonPrintableSymbolType.Unknown}
		/>
	));

	// new line symbol
	output = reactStringReplace([...output], new RegExp('(' + NEWLINE_REGEXP_RULE + ')', 'g'), (match, i) => (
		<NonPrintableSymbol
			key={'newline-symbol-' + i}
			message={(
				<FormattedMessage {...messages.newLine} />
			)}
			type={NonPrintableSymbolType.Newline}
		/>
	));

	// tab symbol
	output = reactStringReplace([...output], new RegExp('(' + TAB_REGEXP_RULE + ')', 'g'), (match, i) => (
		<NonPrintableSymbol
			key={'tab-symbol-' + i}
			message={(
				<FormattedMessage {...messages.tab} />
			)}
			type={NonPrintableSymbolType.Tab}
		/>
	));

	// match general unknown non-printable character
	output = reactStringReplace([...output], new RegExp('(' + NON_PRINTABLE_REGEXP_RULE + ')', 'g'), (match, i) => (
		<NonPrintableSymbol
			key={'unknown-char-' + i}
			message={(
				<FormattedMessage {...messages.unknownCharacter} />
			)}
			type={NonPrintableSymbolType.Unknown}
		/>
	));

	return (
		<>{output}</>
	);
};



export default React.memo(TextInspector);
