import React from 'react';
import {
	useSelector,
} from 'react-redux';

import useColumnSets from '~/hooks/useColumnSets';
import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';
import useCurrentColumnSetId from '~/hooks/useCurrentColumnSetId';
import usePersistableCurrentColumns from '~/hooks/usePersistableCurrentColumns';

import {
	inSegmentManagementSelector,
} from '~/state/pagesColumns/selectors';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



function useIsColumnSetModified(): boolean | undefined {
	const columnSets = useColumnSets();
	const currentColumnSet = useCurrentColumnSet();
	const currentColumnSetId = useCurrentColumnSetId();
	const inSegmentManagement = useSelector(inSegmentManagementSelector);
	const persistableCurrentColumns = usePersistableCurrentColumns();

	return React.useMemo(
		() => {
			if (columnSets.isLoaded === false) {
				return;
			}

			if (inSegmentManagement) {
				return false;
			}

			if (currentColumnSet !== null) {
				const currentColumnSetColumns = currentColumnSet.columns;

				const previouslyPresentPersistableColumns = persistableCurrentColumns.filter(
					(column) => currentColumnSetColumns.some(
						(currentColumnSetColumn) => currentColumnSetColumn.name === column.name,
					),
				);

				const isAnyColumnAdded = persistableCurrentColumns.find(
					(column) => (
						column.enabled
						&& previouslyPresentPersistableColumns.some(
							(currentColumnSetColumn) => currentColumnSetColumn.name === column.name,
						) === false
					),
				);

				if (isAnyColumnAdded) {
					return true;
				}

				return currentColumnSetColumns.some(
					(columnSetColumn, index) => (
						columnSetColumn.name !== getArrayItemAtSafeIndex(previouslyPresentPersistableColumns, index).name
						|| columnSetColumn.enabled !== getArrayItemAtSafeIndex(previouslyPresentPersistableColumns, index).enabled
					),
				);
			}

			return currentColumnSetId !== null;
		},
		[
			columnSets,
			currentColumnSet,
			currentColumnSetId,
			inSegmentManagement,
			persistableCurrentColumns,
		],
	);
}



export default useIsColumnSetModified;
