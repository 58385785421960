import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import StatusMessage from '~/components/patterns/messages/embedded/StatusMessage';



const messages = defineMessages({
	description: {
		id: 'ui.pageDetail.statusMessage.overquota.description',
	},
	descriptionTitle: {
		id: 'ui.pageDetail.statusMessage.overquota.descriptionTitle',
	},
	title: {
		id: 'ui.pageDetail.statusMessage.overquota.title',
	},
});



const Overquota: React.FC = () => {
	return (
		<StatusMessage
			message={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DefinitionTermsList>
				<DefinitionTerm
					description={<FormattedMessage {...messages.description} />}
					term={<FormattedMessage {...messages.descriptionTitle} />}
				/>
			</DefinitionTermsList>
		</StatusMessage>
	);
};



export default Overquota;
