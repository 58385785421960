import classNames from 'classnames';
import React from 'react';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



export type ScrollWheelPickerOption<Value> = {
	/** Textual label of option */
	label: React.ReactNode,
	/** Name sent as parameter when clicking on some option */
	value: Value,
};

type Props<Value> = {
	/** Callback triggered when clicking on specific option */
	onClickCallback: (value: Value) => void,
	/** List of options */
	options: Array<ScrollWheelPickerOption<Value>>,
	value: Value | null,
};



const ScrollWheelPicker = <Value extends unknown>(props: Props<Value>) => {
	const {
		onClickCallback,
		options,
		value,
	} = props;

	const optionsRef = React.useRef<HTMLUListElement | null>(null);
	const initialScroll = React.useRef(true);

	const indexOfSelectedOption = options.findIndex((option) => {
		return option.value === value;
	});

	React.useLayoutEffect(
		() => {
			const element = optionsRef.current?.querySelector(`
				[data-value="${value}"]
			`);

			element?.scrollIntoView({
				behavior: 'auto',
				block: initialScroll.current ? 'center' : 'nearest',
			});

			initialScroll.current = false;
		},
		[
			value,
		],
	);

	const handlePreviousOptionSelection = React.useCallback(
		(e) => {
			e.preventDefault();

			if (indexOfSelectedOption > 0) {
				onClickCallback(
					getArrayItemAtSafeIndex(options, indexOfSelectedOption - 1).value,
				);
			}
		},
		[
			indexOfSelectedOption,
			onClickCallback,
			options,
		],
	);

	const handleNextOptionSelection = React.useCallback(
		(e) => {
			e.preventDefault();

			if (indexOfSelectedOption < options.length - 1) {
				onClickCallback(
					getArrayItemAtSafeIndex(options, indexOfSelectedOption + 1).value,
				);
			}
		},
		[
			indexOfSelectedOption,
			onClickCallback,
			options,
		],
	);

	return (
		<div className="scroll-wheel-picker">
			<div className="scroll-wheel-picker__control-element">
				<button
					className="scroll-wheel-picker__arrow scroll-wheel-picker__arrow--prev"
					disabled={indexOfSelectedOption === 0}
					onClick={handlePreviousOptionSelection}
					type="button"
				>
					▲
				</button>
			</div>
			<div className="scroll-wheel-picker__scroll-container">
				<ul
					className="scroll-wheel-picker__options"
					ref={optionsRef}
				>
					{options.map((option) => (
						<li
							className={classNames({
								'scroll-wheel-picker__option': true,
								'scroll-wheel-picker__option--is-selected': value === option.value,
							})}
							data-value={option.value}
							key={`option-${option.value}`}
							onClick={() => onClickCallback(option.value)}
						>
							{option.label}
						</li>
					))}
				</ul>
			</div>
			<div className="scroll-wheel-picker__control-element">
				<button
					className="scroll-wheel-picker__arrow scroll-wheel-picker__arrow--next"
					disabled={indexOfSelectedOption === options.length - 1}
					onClick={handleNextOptionSelection}
					type="button"
				>
					▼
				</button>
			</div>
		</div>
	);
};



export default ScrollWheelPicker;
