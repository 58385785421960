/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectGoogleAnalyticsAccountToWebsiteMutationVariables = GraphQL.Exact<{
  viewId: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ConnectGoogleAnalyticsAccountToWebsiteMutation = { readonly __typename?: 'Mutation', readonly ConnectGoogleAnalyticsAccountToWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly googleAnalyticsIntegration: { readonly __typename?: 'WebsiteGoogleAnalyticsIntegration', readonly propertyId: string, readonly version: GraphQL.GoogleAnalyticsVersion, readonly viewId: string | null, readonly googleAnalyticsAccount: { readonly __typename?: 'GoogleAnalyticsAccount', readonly id: CK.ID, readonly name: string } } | null, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null } } };

export type ConnectGoogleAnalyticsV4AccountToWebsiteMutationVariables = GraphQL.Exact<{
  propertyId: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ConnectGoogleAnalyticsV4AccountToWebsiteMutation = { readonly __typename?: 'Mutation', readonly ConnectGoogleAnalyticsV4AccountToWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly sharedColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly personalColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> } | null, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly googleAnalyticsIntegration: { readonly __typename?: 'WebsiteGoogleAnalyticsIntegration', readonly propertyId: string, readonly version: GraphQL.GoogleAnalyticsVersion, readonly viewId: string | null, readonly googleAnalyticsAccount: { readonly __typename?: 'GoogleAnalyticsAccount', readonly id: CK.ID, readonly name: string } } | null, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null } } };


export const ConnectGoogleAnalyticsAccountToWebsiteDocument = gql`
    mutation ConnectGoogleAnalyticsAccountToWebsite($viewId: String!, $websiteId: WebsiteId!) {
  ConnectGoogleAnalyticsAccountToWebsite(viewId: $viewId, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        id
        googleAnalyticsIntegration {
          googleAnalyticsAccount {
            id
            name
          }
          propertyId
          version
          viewId
        }
        integrations {
          isNotOwned
          status
          type
        }
      }
    }
  }
}
    `;
export type ConnectGoogleAnalyticsAccountToWebsiteMutationFn = Apollo.MutationFunction<ConnectGoogleAnalyticsAccountToWebsiteMutation, ConnectGoogleAnalyticsAccountToWebsiteMutationVariables>;

/**
 * __useConnectGoogleAnalyticsAccountToWebsiteMutation__
 *
 * To run a mutation, you first call `useConnectGoogleAnalyticsAccountToWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectGoogleAnalyticsAccountToWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectGoogleAnalyticsAccountToWebsiteMutation, { data, loading, error }] = useConnectGoogleAnalyticsAccountToWebsiteMutation({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useConnectGoogleAnalyticsAccountToWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<ConnectGoogleAnalyticsAccountToWebsiteMutation, ConnectGoogleAnalyticsAccountToWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectGoogleAnalyticsAccountToWebsiteMutation, ConnectGoogleAnalyticsAccountToWebsiteMutationVariables>(ConnectGoogleAnalyticsAccountToWebsiteDocument, options);
      }
export type ConnectGoogleAnalyticsAccountToWebsiteMutationHookResult = ReturnType<typeof useConnectGoogleAnalyticsAccountToWebsiteMutation>;
export type ConnectGoogleAnalyticsAccountToWebsiteMutationResult = Apollo.MutationResult<ConnectGoogleAnalyticsAccountToWebsiteMutation>;
export type ConnectGoogleAnalyticsAccountToWebsiteMutationOptions = Apollo.BaseMutationOptions<ConnectGoogleAnalyticsAccountToWebsiteMutation, ConnectGoogleAnalyticsAccountToWebsiteMutationVariables>;
export const ConnectGoogleAnalyticsV4AccountToWebsiteDocument = gql`
    mutation ConnectGoogleAnalyticsV4AccountToWebsite($propertyId: String!, $websiteId: WebsiteId!) {
  ConnectGoogleAnalyticsV4AccountToWebsite(
    propertyId: $propertyId
    websiteId: $websiteId
  ) {
    query {
      authenticatedSession {
        memberships {
          account {
            id
            sharedColumnSets {
              columns {
                enabled
                name
              }
              id
              isDefault
              name
            }
          }
          user {
            id
            legacyId
          }
        }
        personalColumnSets {
          columns {
            enabled
            name
          }
          id
          isDefault
          name
        }
      }
      website(id: $websiteId) {
        id
        googleAnalyticsIntegration {
          googleAnalyticsAccount {
            id
            name
          }
          propertyId
          version
          viewId
        }
        integrations {
          isNotOwned
          status
          type
        }
      }
    }
  }
}
    `;
export type ConnectGoogleAnalyticsV4AccountToWebsiteMutationFn = Apollo.MutationFunction<ConnectGoogleAnalyticsV4AccountToWebsiteMutation, ConnectGoogleAnalyticsV4AccountToWebsiteMutationVariables>;

/**
 * __useConnectGoogleAnalyticsV4AccountToWebsiteMutation__
 *
 * To run a mutation, you first call `useConnectGoogleAnalyticsV4AccountToWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectGoogleAnalyticsV4AccountToWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectGoogleAnalyticsV4AccountToWebsiteMutation, { data, loading, error }] = useConnectGoogleAnalyticsV4AccountToWebsiteMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useConnectGoogleAnalyticsV4AccountToWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<ConnectGoogleAnalyticsV4AccountToWebsiteMutation, ConnectGoogleAnalyticsV4AccountToWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectGoogleAnalyticsV4AccountToWebsiteMutation, ConnectGoogleAnalyticsV4AccountToWebsiteMutationVariables>(ConnectGoogleAnalyticsV4AccountToWebsiteDocument, options);
      }
export type ConnectGoogleAnalyticsV4AccountToWebsiteMutationHookResult = ReturnType<typeof useConnectGoogleAnalyticsV4AccountToWebsiteMutation>;
export type ConnectGoogleAnalyticsV4AccountToWebsiteMutationResult = Apollo.MutationResult<ConnectGoogleAnalyticsV4AccountToWebsiteMutation>;
export type ConnectGoogleAnalyticsV4AccountToWebsiteMutationOptions = Apollo.BaseMutationOptions<ConnectGoogleAnalyticsV4AccountToWebsiteMutation, ConnectGoogleAnalyticsV4AccountToWebsiteMutationVariables>;