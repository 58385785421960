import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';

import ChangesChart from './ChangesChart';
import NoDataMessage from './NoDataMessage';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';

import {
	usePageChangesWidgetQuery,
} from './PageChangesWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useNavigateToTrackedChanges from '~/hooks/useNavigateToTrackedChanges';
import useNavigation from '~/hooks/useNavigation';
import usePageChangeTypeTitles from '~/hooks/usePageChangeTypeTitles';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateRangeForQuery,
} from '~/model/dashboard';

import {
	CHANGES_IN_ANY_COLUMN,
	CHANGE_TYPE_ADDED,
	CHANGE_TYPE_CHANGED,
	CHANGE_TYPE_OTHER,
	CHANGE_TYPE_REDIRECTED,
	CHANGE_TYPE_REMOVED,
} from '~/model/historicalChanges';



const messages = defineMessages({
	primaryActionLink: {
		id: 'ui.dashboardPageChangesWidget.primaryActionLink',
	},
	title: {
		id: 'ui.dashboardPageChangesWidget.title',
	},
});



const CATEGORIES = [
	{
		name: CHANGE_TYPE_CHANGED,
		color: '#FFB131',
	},
	{
		name: CHANGE_TYPE_ADDED,
		color: '#42CC67',
	},
	{
		name: CHANGE_TYPE_REMOVED,
		color: '#FF5959',
	},
	{
		name: CHANGE_TYPE_REDIRECTED,
		color: '#367be2',
	},
	{
		name: CHANGE_TYPE_OTHER,
		color: '#8595A6',
	},
];



const PageChangesWidget: React.FC = () => {
	const navigateToTrackedChanges = useNavigateToTrackedChanges();
	const navigation = useNavigation();
	const pageChangeTypeTitles = usePageChangeTypeTitles();

	const dateRange = useDashboardDateRange();
	const scope = useDashboardScopeSection();
	const websiteId = useWebsiteId();

	const {
		data,
		loading,
		reasonForNoData,
	} = useDashboardQuery('pageChanges', usePageChangesWidgetQuery, {
		variables: {
			scope,
			websiteId,
			...formatDateRangeForQuery(dateRange),
		},
	});

	const categories = React.useMemo(
		() => {
			return CATEGORIES.map((category) => ({
				color: category.color,
				label: pageChangeTypeTitles[category.name],
				name: category.name,
			}));
		},
		[
			pageChangeTypeTitles,
		],
	);

	const chartData = React.useMemo(
		() => {
			if (data === null) {
				return null;
			}

			return data.map((item) => {
				const changes = {};

				CATEGORIES.forEach((category) => {
					const change = item.changes.find((change) => change.changeType === category.name);

					if (change !== undefined) {
						changes[category.name] = change.numberOfPages;
					}
				});

				return {
					changes,
					endDate: item.endDate,
					startDate: item.startDate,
				};
			});
		},
		[
			data,
		],
	);

	const handleChartClick = React.useCallback(
		({ changeType, endDate, startDate }) => {
			navigation.navigate(
				navigateToTrackedChanges({
					endDate,
					filter: {
						[CK.PagesCommonColumn.ChangeType]: [changeType],
						changes_in: changeType === CHANGE_TYPE_CHANGED ? CHANGES_IN_ANY_COLUMN : null,
						changes_in_columns: null,
					},
					scope,
					startDate,
				}),
			);
		},
		[
			navigateToTrackedChanges,
			navigation,
			scope,
		],
	);

	return (
		<WidgetCell type={WidgetType.PageChanges}>
			<Widget
				label={(
					<FormattedMessage {...messages.title} />
				)}
				loading={loading}
				overlay={chartData === null && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages.primaryActionLink} />
					),
					...navigateToTrackedChanges({
						endDate: dateRange.end,
						filter: {
							[CK.PagesCommonColumn.ChangeType]: [
								CHANGE_TYPE_ADDED,
								CHANGE_TYPE_CHANGED,
								CHANGE_TYPE_OTHER,
								CHANGE_TYPE_REDIRECTED,
								CHANGE_TYPE_REMOVED,
							],
							changes_in: CHANGES_IN_ANY_COLUMN,
							changes_in_columns: null,
						},
						scope,
						startDate: dateRange.start,
					}),
				}}
			>
				{chartData !== null ? (
					<ChangesChart
						categories={categories}
						data={chartData}
						onBarClickCallback={handleChartClick}
					/>
				) : null}
			</Widget>
		</WidgetCell>
	);
};


export default PageChangesWidget;
