import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import {
	RESET_HISTORICAL_CHANGES_INTERVAL,
} from '~/actions/historicalChanges';

import {
	filterSelector,
	sortBySelector,
} from '~/state/historicalChanges/selectors';



function useResetTrackedChangesDateInterval() {
	const dispatch = useDispatch();
	const filter = useSelector(filterSelector);
	const sortBy = useSelector(sortBySelector);

	return React.useCallback(
		() => {
			dispatch({
				type: RESET_HISTORICAL_CHANGES_INTERVAL,
				historicalChangesFilter: filter,
				historicalChangesSortBy: sortBy,
			});
		},
		[
			dispatch,
			filter,
			sortBy,
		],
	);
}



export default useResetTrackedChangesDateInterval;
