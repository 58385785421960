import classNames from 'classnames';
import React from 'react';

import StatusFlag, {
	StatusFlagSize,
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';



export enum BlankSlateStatus {
	Negative = 'negative',
	Neutral = 'neutral',
	Positive = 'positive',
}

type Props = {
	children: React.ReactNode,
	/** Status expression of blank slate */
	status?: BlankSlateStatus,
};



const BlankSlate: React.FC<Props> = (props) => {
	const {
		children,
		status = BlankSlateStatus.Neutral,
	} = props;

	let icon: React.ReactNode = null;

	if (status === BlankSlateStatus.Positive) {
		icon = (
			<div className="blank-slate__icon">
				<StatusFlag
					size={StatusFlagSize.Medium}
					status={StatusFlagStatus.Success}
				/>
			</div>
		);
	} else if (status === BlankSlateStatus.Negative) {
		icon = (
			<div className="blank-slate__icon">
				<StatusFlag
					size={StatusFlagSize.Medium}
					status={StatusFlagStatus.Critical}
				/>
			</div>
		);
	}

	const componentClasses = classNames({
		'blank-slate': true,
		[ 'blank-slate--status-' + status ]: true,
	});

	return (
		<div className={componentClasses}>
			<div className="blank-slate__container">
				{icon}

				<div className="blank-slate__message">
					{children}
				</div>
			</div>
		</div>
	);
};



export default BlankSlate;
