import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import ContextNavigationButton, {
	ContextNavigationButtonIconType,
	ContextNavigationButtonStyle,
} from '~/components/patterns/navigations/ContextNavigationButton';
import DeleteIncorrectLinksModal from '~/components/app/DeleteIncorrectLinksModal';
import DeleteUrlModal from '~/components/app/DeleteUrlModal';
import ImportUrlModal from '~/components/app/ImportUrlModal';
import List from '~/components/patterns/lists/List';
import OnlyForAdmin from '~/components/app/OnlyForAdmin';
import PrioritizeExternalUrlsModal from '~/components/app/PrioritizeExternalUrlsModal';
import RemoveUnclickablePagesModal from '~/components/app/RemoveUnclickablePagesModal';
import RemoveWebsiteConfirmationModal from '~/components/app/RemoveWebsiteConfirmationModal';
import WebsiteAdminPrioritizeUrlsByFilterModal from '~/components/app/WebsiteAdminPrioritizeUrlsByFilterModal';
import WebsiteAdminPruneHistoricalPageDataModal from '~/components/app/WebsiteAdminPruneHistoricalPageDataModal';
import WebsiteAdminTestFetchViaRenderingModal from '~/components/app/WebsiteAdminTestFetchViaRenderingModal';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	useWebsiteDetailContextActionsQuery,
} from './WebsiteDetailContextActions.gql';

import useOpenModal from '~/hooks/useOpenModal';
import useNavigation from '~/hooks/useNavigation';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	CONTENTKING_ENVIRONMENT,
} from '~/config';

import {
	ObjectType,
} from '~/model/permissions';

import {
	selectedWebsiteSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	alertSettings: {
		id: 'ui.websites.detail.alertSettings',
	},
	deleteWebsiteLink: {
		id: 'ui.websites.detail.deleteWebsite',
	},
	exclusionsLink: {
		id: 'ui.websites.form.exclusions.link',
	},
	pruneOrphansLink: {
		id: 'ui.websiteDetail.pruneOrphansLink',
	},
});



const WebsiteDetailContextActions: React.FC = () => {
	const websiteId = useWebsiteId();
	const navigation = useNavigation();
	const websiteDetail = useSelector(selectedWebsiteSelector);

	const { data } = useWebsiteDetailContextActionsQuery({
		variables: {
			websiteId,
		},
	});

	const grafanaLink = data?.website?.grafanaLink ?? null;
	const scrapbookLink = data?.website?.scrapbookLink ?? null;

	const handleOpenDeleteWebsiteModal = useOpenModal(
		() => (
			<RemoveWebsiteConfirmationModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const handleOpenDeleteUrlModal = useOpenModal(
		() => (
			<DeleteUrlModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const handleOpenImportUrlModal = useOpenModal(
		() => (
			<ImportUrlModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const handleOpenPrioritizeExternalUrlsModal = useOpenModal(
		() => (
			<PrioritizeExternalUrlsModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const handleOpenDeleteIncorrectLinksModal = useOpenModal(
		() => (
			<DeleteIncorrectLinksModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const handleOpenPrioritizeUrlsByFilterModal = useOpenModal(
		() => (
			<WebsiteAdminPrioritizeUrlsByFilterModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const handleOpenPruneHistoricalPageDataModal = useOpenModal(
		() => (
			<WebsiteAdminPruneHistoricalPageDataModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const handleOpenTestFetchViaRenderingModal = useOpenModal(
		() => (
			<WebsiteAdminTestFetchViaRenderingModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const handleRemoveUnclickablePagesModal = useOpenModal(
		() => (
			<RemoveUnclickablePagesModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const handleOpenPruneOrphansModal = React.useCallback(
		() => {
			navigation.navigate({
				keepParameters: true,
				routeParams: {
					action: 'purge_orphan_pages',
				},
			});
		},
		[
			navigation,
		],
	);

	const handleOpenUrlExclusionSetup = React.useCallback(
		() => {
			navigation.navigate({
				keepParameters: true,
				routeParams: {
					action: 'exclusion_list',
				},
			});
		},
		[
			navigation,
		],
	);

	const inactiveLinkHandler = React.useCallback(
		() => false,
		[],
	);

	return (
		<List>
			<OnlyForAdmin canBeHidden={true}>
				<ContextNavigationButton
					href={grafanaLink ?? '#'}
					iconType={ContextNavigationButtonIconType.Graphs}
					onClickCallback={scrapbookLink === null ? inactiveLinkHandler : undefined}
				>
					Diagnose
				</ContextNavigationButton>
			</OnlyForAdmin>

			{CONTENTKING_ENVIRONMENT === 'production' && (
				<OnlyForAdmin canBeHidden={true}>
					<ContextNavigationButton
						href={scrapbookLink ?? '#'}
						iconType={ContextNavigationButtonIconType.Graphs}
						onClickCallback={scrapbookLink === null ? inactiveLinkHandler : undefined}
					>
						Show logs
					</ContextNavigationButton>
				</OnlyForAdmin>
			)}

			<OnlyForAdmin canBeHidden={true}>
				<ContextNavigationButton
					iconType={ContextNavigationButtonIconType.Import}
					onClickCallback={handleOpenImportUrlModal}
				>
					Import URL
				</ContextNavigationButton>
			</OnlyForAdmin>

			<OnlyForAdmin canBeHidden={true}>
				<ContextNavigationButton
					iconType={ContextNavigationButtonIconType.Delete}
					onClickCallback={handleOpenDeleteUrlModal}
				>
					Delete URL
				</ContextNavigationButton>
			</OnlyForAdmin>

			<OnlyForAdmin canBeHidden={true}>
				<ContextNavigationButton
					iconType={ContextNavigationButtonIconType.Crawling}
					onClickCallback={handleOpenPrioritizeUrlsByFilterModal}
				>
					Prioritize urls by filter
				</ContextNavigationButton>
			</OnlyForAdmin>

			<OnlyForAdmin canBeHidden={true}>
				<ContextNavigationButton
					iconType={ContextNavigationButtonIconType.Crawling}
					onClickCallback={handleOpenPrioritizeExternalUrlsModal}
				>
					Prioritize external URLs
				</ContextNavigationButton>
			</OnlyForAdmin>

			<OnlyForAdmin canBeHidden={true}>
				<WithPermission
					action={GraphQL.ActionWithWebsite.ManageUrlExclusionList}
					objectId={websiteId}
					objectType={ObjectType.Website}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false}
							iconType={ContextNavigationButtonIconType.Reset}
							onClickCallback={handleOpenDeleteIncorrectLinksModal}
							tooltip={message}
						>
							Delete incorrect links
						</ContextNavigationButton>
					)}
				</WithPermission>
			</OnlyForAdmin>

			<OnlyForAdmin canBeHidden={true}>
				<WithPermission
					action={GraphQL.ActionWithWebsite.ManageAdminDangerousEscapeHatches}
					objectId={websiteId}
					objectType={ObjectType.Website}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false}
							iconType={ContextNavigationButtonIconType.Reset}
							onClickCallback={handleOpenPruneHistoricalPageDataModal}
							tooltip={message}
						>
							Prune historical page data
						</ContextNavigationButton>
					)}
				</WithPermission>
			</OnlyForAdmin>

			<OnlyForAdmin canBeHidden={true}>
				<WithPermission
					action={GraphQL.ActionWithWebsite.ManageInternals}
					objectId={websiteId}
					objectType={ObjectType.Website}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false}
							iconType={ContextNavigationButtonIconType.Reset}
							onClickCallback={handleOpenTestFetchViaRenderingModal}
							tooltip={message}
						>
							Debug rendering
						</ContextNavigationButton>
					)}
				</WithPermission>
			</OnlyForAdmin>

			<OnlyForAdmin canBeHidden={true}>
				<WithPermission
					action={GraphQL.ActionWithWebsite.ManageInternals}
					objectId={websiteId}
					objectType={ObjectType.Website}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false}
							iconType={ContextNavigationButtonIconType.Reset}
							onClickCallback={handleRemoveUnclickablePagesModal}
							tooltip={message}
						>
							Remove unclickable pages
						</ContextNavigationButton>
					)}
				</WithPermission>
			</OnlyForAdmin>

			<ContextNavigationButton
				iconType={ContextNavigationButtonIconType.Alerts}
				routeName="website.alerts.configuration"
				routeParams={{
					websiteId,
				}}
			>
				<FormattedMessage {...messages.alertSettings} />
			</ContextNavigationButton>

			<WithPermission
				action={GraphQL.ActionWithWebsite.ManageUrlExclusionList}
				objectId={websiteId}
				objectType={ObjectType.Website}
				showMessage={false}
			>
				{({ isAllowed, message }) => (
					<ContextNavigationButton
						disabled={isAllowed.yes === false}
						iconType={ContextNavigationButtonIconType.ExclusionList}
						onClickCallback={handleOpenUrlExclusionSetup}
						tooltip={message}
					>
						<FormattedMessage
							{...messages.exclusionsLink}
							values={{
								rulesCount: websiteDetail?.get('virtualRobotsRulesCount'),
							}}
						/>
					</ContextNavigationButton>
				)}
			</WithPermission>

			<WithPermission
				action={GraphQL.ActionWithWebsite.PruneOrphanPages}
				objectId={websiteId}
				objectType={ObjectType.Website}
				showMessage={false}
			>
				{({ isAllowed, message }) => (
					<ContextNavigationButton
						disabled={isAllowed.yes === false}
						iconType={ContextNavigationButtonIconType.MagicWand}
						onClickCallback={handleOpenPruneOrphansModal}
						tooltip={message}
					>
						<FormattedMessage {...messages.pruneOrphansLink} />
					</ContextNavigationButton>
				)}
			</WithPermission>

			<WithPermission
				action={GraphQL.ActionWithWebsite.RemoveWebsite}
				objectId={websiteId}
				objectType={ObjectType.Website}
				showMessage={false}
			>
				{({ isAllowed, message }) => (
					<ContextNavigationButton
						disabled={isAllowed.yes === false}
						iconType={ContextNavigationButtonIconType.Delete}
						onClickCallback={handleOpenDeleteWebsiteModal}
						style={ContextNavigationButtonStyle.Link}
						tooltip={message}
					>
						<FormattedMessage {...messages.deleteWebsiteLink} />
					</ContextNavigationButton>
				)}
			</WithPermission>
		</List>
	);
};



export default WebsiteDetailContextActions;
