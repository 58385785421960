import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ExclusionListIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="exclusion-list"
					transform="translate(5.000000, 5.000000)"
				>
					<polygon
						fill="#FFFFFF"
						fillOpacity="0"
						fillRule="nonzero"
						id="Fill-1"
						points="17 17 0 17 0 0 17 0"
					>
					</polygon>
					<path
						d="M7,2 L16,2"
						id="Stroke-6"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3"
					>
					</path>
					<path
						d="M7,8 L16,8"
						id="Stroke-6-Copy"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3"
					>
					</path>
					<path
						d="M7,14 L16,14"
						id="Stroke-6-Copy-2"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3"
					>
					</path>
					<g
						id="Group-5"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						transform="translate(1.000000, 0.500000)"
					>
						<path
							d="M0,0 L3,2.99656896"
							id="Stroke-6-Copy-6"
						>
						</path>
						<path
							d="M3,0 L0,2.99656896"
							id="Stroke-6-Copy-7"
						>
						</path>
					</g>
					<g
						id="Group-5-Copy"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						transform="translate(1.000000, 6.500000)"
					>
						<path
							d="M0,0 L3,2.99656896"
							id="Stroke-6-Copy-6"
						>
						</path>
						<path
							d="M3,0 L0,2.99656896"
							id="Stroke-6-Copy-7"
						>
						</path>
					</g>
					<g
						id="Group-5-Copy-2"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						transform="translate(1.000000, 12.500000)"
					>
						<path
							d="M0,0 L3,2.99656896"
							id="Stroke-6-Copy-6"
						>
						</path>
						<path
							d="M3,0 L0,2.99656896"
							id="Stroke-6-Copy-7"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default ExclusionListIconBuilder;

