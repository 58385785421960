import memoize from 'memoizee';
import {
	useSelector,
} from 'react-redux';

import {
	FeatureFlag,
	isEnabled,
} from '~/featureFlags';

import {
	selector as featureFlagsSelector,
} from '~/state/featureFlags/selectors';



const format = memoize(
	(featureFlags) => ({
		isEnabled: (flag: FeatureFlag) => isEnabled(featureFlags, flag),
		listAllEnabled: () => Object.values(FeatureFlag).filter(
			(flag) => isEnabled(featureFlags, flag),
		),
	}),
);



function useFeatureFlags() {
	const featureFlags = useSelector(featureFlagsSelector);

	return format(featureFlags);
}



export default useFeatureFlags;
