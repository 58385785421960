import React from 'react';

import {
	DynamicModalContext,
} from '~/components/logic/DynamicModal';



function useModals() {
	const modal = React.useContext(DynamicModalContext);

	// Prevent unnecessary rerendering by only exposing stable fields.
	// To access other fields (e.g. `modal`) use `React.useContext(DynamicModalContext)` directly.
	return React.useMemo(
		() => ({
			closeCallback: modal.closeCallback,
			closeModal: modal.closeModal,
			openModal: modal.openModal,
		}),
		[
			modal.closeCallback,
			modal.closeModal,
			modal.openModal,
		],
	);
}



export default useModals;
