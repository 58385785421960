import React from 'react';

import IssueCategoryCardLayout from './builders/IssueCategoryCardLayout';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';
import WebsiteIssueCategoryCardContent from './builders/WebsiteIssueCategoryCardContent';



const IssueCategoryCardSkeleton: React.FC = () => {
	return (
		<IssueCategoryCardLayout isLoading={true}>
			<WebsiteIssueCategoryCardContent
				isLoading={true}
				title={(
					<SquareSkeleton
						height={18}
						maxWidth={100}
						style={SquareSkeletonStyle.Light}
					/>
				)}
				values={[
					<SquareSkeleton
						height={32}
						key="skeleton"
						maxWidth={56}
					/>,
				]}
			/>
		</IssueCategoryCardLayout>
	);
};



export default IssueCategoryCardSkeleton;
