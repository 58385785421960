import classNames from 'classnames';
import gravatar from 'gravatar-api';
import React from 'react';

import LazyImage from '~/components/patterns/images/LazyImage';



export enum GravatarStyle {
	Default = 'default',
	Member = 'member',
}



type Props = {
	email: string | null,
	placeholder?: React.ReactNode,
	style?: GravatarStyle,
};

const Gravatar: React.FC<Props> = (props) => {
	const {
		email,
		placeholder,
		style = GravatarStyle.Default,
	} = props;

	const size = style === GravatarStyle.Member ? 80 : 28;

	const src = React.useMemo(
		() => {
			if (email === null) {
				return null;
			}

			return gravatar.imageUrl({
				email,
				parameters: {
					d: placeholder ? 'blank' : 'mp',
					size: size * 2,
				},
				secure: true,
			});
		},
		[
			email,
			placeholder,
			size,
		],
	);

	const componentClasses = classNames({
		'gravatar': true,
		'gravatar--member': style === GravatarStyle.Member,
	});

	return (
		<div className={componentClasses}>
			<LazyImage
				animated={false}
				height="100%"
				placeholder={placeholder ? (
					<div className="gravatar__placeholder">
						{placeholder}
					</div>
				) : null}
				src={src}
				width="100%"
			/>
		</div>
	);
};



export default Gravatar;
