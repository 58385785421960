import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useAdministerAccountInvoiceSettingsMutation,
} from './InvoicesSettingsForm.gql';

import useAccountIsDeliveryAddressEnabled from '~/hooks/useAccountIsDeliveryAddressEnabled';
import useAccountIsExtraInvoiceDetailsEnabled from '~/hooks/useAccountIsExtraInvoiceDetailsEnabled';

import FormError from '~/utilities/FormError';



type Props = {
	accountId: CK.AccountId,
};

const InvoicesSettingsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountIsDeliveryAddressEnabled = useAccountIsDeliveryAddressEnabled(accountId);
	const accountIsExtraInvoiceDetailsEnabled = useAccountIsExtraInvoiceDetailsEnabled(accountId);

	const [administerAccountInvoiceSettings] = useAdministerAccountInvoiceSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				await administerAccountInvoiceSettings({
					variables: {
						accountId,
						isDeliveryAddressEnabled: values.isDeliveryAddressEnabled,
						isExtraInvoiceDetailsEnabled: values.isExtraInvoiceDetailsEnabled,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}
		},
		[
			accountId,
			administerAccountInvoiceSettings,
		],
	);

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageInternals}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					isForAdmins={true}
					title="Invoicing"
				>
					<DisplayPart>
						<FormRows>
							<FormRow label="Invoice details">
								<StaticText focusTarget="isExtraInvoiceDetailsEnabled">
									{accountIsExtraInvoiceDetailsEnabled ? 'enabled' : 'disabled'}
								</StaticText>
							</FormRow>

							<FormRow label="Delivery address">
								<StaticText focusTarget="isDeliveryAddressEnabled">
									{accountIsDeliveryAddressEnabled ? 'enabled' : 'disabled'}
								</StaticText>
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form
							defaultValues={{
								isDeliveryAddressEnabled: accountIsDeliveryAddressEnabled,
								isExtraInvoiceDetailsEnabled: accountIsExtraInvoiceDetailsEnabled,
							}}
							onSuccess={handleSubmit}
						>
							<FormRows>
								<FormRow
									htmlFor="isExtraInvoiceDetailsEnabled"
									label="Invoice details"
								>
									<CheckboxField
										label="enabled"
										name="isExtraInvoiceDetailsEnabled"
										width={false}
									/>
								</FormRow>

								<FormRow
									htmlFor="isDeliveryAddressEnabled"
									label="Delivery address"
								>
									<CheckboxField
										label="enabled"
										name="isDeliveryAddressEnabled"
										width={false}
									/>
								</FormRow>
							</FormRows>

							<ButtonsLayout>
								<CancelButton />
								<SaveSubmitButton />
							</ButtonsLayout>
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WhenAccountActionAllowed>
	);
};



export default InvoicesSettingsForm;
