import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SearchEnginesIconBuilder: IconBuilder = {
	icon: ({ color = '#30C1B9' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeLinecap="round"
				strokeWidth="1"
			>
				<g
					id="search-engines"
					stroke={color}
					strokeWidth="2"
					transform="translate(3.000000, 4.000000)"
				>
					<path
						d="M15.7971,20.9794 C19.6999,20.9794 22.8638,17.8155 22.8638,13.9127 C22.8638,10.0098 19.6999,6.8459 15.7971,6.8459 C11.8942,6.8459 8.7303,10.0098 8.7303,13.9127 C8.7303,17.8155 11.8942,20.9794 15.7971,20.9794 Z"
						id="Path"
					>
					</path>
					<path
						d="M21.9216,17.572 L26.1204,21.7706 C26.2828,21.9318 26.4117,22.1235 26.4997,22.3349 C26.5877,22.5462 26.633,22.773 26.633,23.0019 C26.633,23.2309 26.5877,23.4577 26.4997,23.669 C26.4117,23.8803 26.2828,24.0721 26.1204,24.2333 C25.9582,24.3964 25.7655,24.5259 25.5533,24.6142 C25.3411,24.7026 25.1136,24.7481 24.8837,24.7481 C24.6539,24.7481 24.4264,24.7026 24.2142,24.6142 C24.002,24.5259 23.8093,24.3964 23.6471,24.2333 L19.4506,20.0369"
						id="Path"
					>
					</path>
					<path
						d="M6.8459,19.3785 L1.38418,19.3785 C1.01708,19.3785 0.66501,19.2327 0.40543,18.9731 C0.14584,18.7135 0,18.3614 0,17.9943 L0,1.38418 C0,1.01707 0.14584,0.665 0.40543,0.40542 C0.66501,0.14583 1.01708,1.77635684e-15 1.38418,1.77635684e-15 L22.3268,1.77635684e-15 C22.6939,1.77635684e-15 23.046,0.14583 23.3056,0.40542 C23.5652,0.665 23.711,1.01707 23.711,1.38418 L23.711,7.788"
						id="Path"
					>
					</path>
					<line
						id="Path"
						x1="0"
						x2="23.711"
						y1="3.92493"
						y2="3.92493"
					>
					</line>
					<polyline
						id="Path"
						points="3.5481 8.0835 5.48351 8.0835 7.4189 8.0835"
					>
					</polyline>
					<line
						id="Path"
						x1="3.5481"
						x2="5.53446"
						y1="11.2534"
						y2="11.2534"
					>
					</line>
					<line
						id="Path"
						x1="3.5481"
						x2="5.53446"
						y1="14.4231"
						y2="14.4231"
					>
					</line>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default SearchEnginesIconBuilder;
