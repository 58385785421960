import React from 'react';

import CodeValue from '~/components/patterns/values/CodeValue';



type Props = {
	snippets: Array<{
		/** Code snippet */
		content: React.ReactNode,
		/** Label of snippet */
		label: React.ReactNode,
	}>,
};



const CodeSnippets: React.FC<Props> = (props) => {
	const {
		snippets,
	} = props;

	return (
		<div className="code-snippets">
			{snippets.map((item, index) => (
				<div
					className="code-snippets__snippet"
					key={'code-snippet-' + index}
				>
					<div className="code-snippets__snippet-label">
						{item.label}
					</div>
					<div className="code-snippets__snippet-content">
						<CodeValue>
							{item.content}
						</CodeValue>
					</div>
				</div>

			))}
		</div>
	);
};



export default CodeSnippets;
