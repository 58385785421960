import classNames from 'classnames';
import React from 'react';

import styles from './Small.module.scss';



type Props = {
	/** Smaller text */
	children: React.ReactNode,
	/** Show small text in muted style. This is suitable for comments or notes */
	muted?: boolean,
};



const Small: React.FC<Props> = (props) => {
	const {
		children,
		muted,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isMuted]: muted,
	});

	return (
		<small className={componentClasses}>
			{children}
		</small>
	);
};



export default Small;
