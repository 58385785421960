import {
	Map,
} from 'immutable';

import {
	getAddonPrice,
	getPlanPrice,
} from './prices';

import {
	choosePlan,
	convertDetailsToAddons,
	convertPagesToAddons,
} from './tariffs';

import roundWithPrecision from '~/utilities/roundWithPrecision';



function normalizeDiscounts(discounts, currency) {
	if (discounts.toJS !== undefined) {
		discounts = discounts.toJS();
	} else {
		discounts = discounts.slice(0);
	}

	discounts.sort((discountA, discountB) => {
		if (discountA.type === 'percent' && discountB.type === 'percent') {
			if (discountA.rate === discountB.rate) {
				return 0;
			}

			return discountA.rate > discountB.rate ? -1 : 1;
		}

		if (discountA.type === 'dollars' && discountB.type === 'dollars') {
			if (discountA.amount[currency] === discountB.amount[currency]) {
				return 0;
			}

			return discountA.amount[currency] > discountB.amount[currency] ? -1 : 1;
		}

		if (discountA.type === 'percent' && discountB.type === 'dollars') {
			return -1;
		}

		if (discountB.type === 'percent' && discountA.type === 'dollars') {
			return 1;
		}
	});

	return discounts;
}



export function createPurchase({ details = {}, numberOfPages }) {
	return {
		details: Map(details),
		numberOfPages,
	};
}



export function calculatePurchaseCost({ accountType, billingCycle, currency, discounts = [], purchase, tariff, tariffs } = {}) {
	const {
		details,
		numberOfPages,
	} = purchase;

	const addons = Object.assign(
		convertPagesToAddons({
			accountType,
			numberOfPages,
			tariff,
		}),
		convertDetailsToAddons({
			accountType,
			details,
			tariff,
		}),
	);

	const plan = choosePlan({
		accountType,
		billingCycle,
		details,
		tariff,
	});

	if (plan === null) {
		return null;
	}

	return calculateCost({
		addons,
		currency,
		discounts,
		plan,
		tariff,
		tariffs,
	});
}



function calculateCost({ addons, currency, discounts = [], plan, tariff, tariffs } = {}) {
	let pagesCost = 0;

	for (const addon in addons) {
		if (addons.hasOwnProperty(addon)) {
			pagesCost += getAddonPrice({
				addon,
				currency,
				plan,
				tariff,
				tariffs,
			}) * addons[addon];
		}
	}

	let totalCost = getPlanPrice({
		currency,
		plan,
		tariff,
		tariffs,
	}) + pagesCost;

	discounts
		.filter(({ type }) => type === 'dollars')
		.forEach(({ amount }) => {
			totalCost -= amount[currency];
		});

	totalCost = Math.max(0, totalCost);

	discounts
		.filter(({ type }) => type === 'percent')
		.forEach(({ rate }) => {
			totalCost -= roundWithPrecision(totalCost * rate, 2);
		});

	return totalCost;
}



export function calculatePurchaseCostDetails({
	accountType,
	billingCycle,
	currency,
	discounts = [],
	purchases,
	tariff,
	tariffs,
	taxRate = 0.0,
	taxType,
}) {
	discounts = normalizeDiscounts(discounts, currency);

	const costWithCommitmentDiscount = purchases.map((purchase) => calculatePurchaseCost({
		accountType,
		billingCycle,
		currency,
		purchase,
		tariff,
		tariffs,
	})).reduce((total, cost) => total + cost, 0);

	let baseForDiscountValues = costWithCommitmentDiscount;

	const resultDiscounts = discounts.map((discount) => {
		let discountValue = 0;

		if (discount.type === 'percent') {
			discountValue = roundWithPrecision(baseForDiscountValues * discount.rate, 2);
		} else if (discount.type === 'dollars') {
			discountValue = discount.amount[currency];
		}

		baseForDiscountValues -= discountValue;

		return {
			amount: discount.type === 'dollars'
				? discount.amount[currency]
				: null,
			code: discount.code,
			rate: discount.type === 'percent'
				? discount.rate
				: null,
			reason: 'custom',
			type: discount.type,
			value: discountValue,
		};
	});

	const subtotal = baseForDiscountValues;
	const tax = roundWithPrecision(taxRate * subtotal, 2);
	const resultTaxType = tax > 0 ? (taxType ?? null) : null;
	const total = subtotal + tax;

	return {
		billingCycle,
		currency,
		cost: costWithCommitmentDiscount,
		discounts: resultDiscounts,
		subtotal,
		tax,
		taxRate,
		taxType: resultTaxType,
		total,
	};
}
