import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import HeaderTitleWithValue, {
	SIZE_MODAL as TITLE_SIZE_MODAL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import ChangeRoleForm from '~/components/logic/changeRoleModal/ChangeRoleForm';
import ChangeUserRoleAssignWebsitesForm from '~/components/app/ChangeUserRoleAssignWebsitesForm';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import MultiStepModal from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';

import {
	useChangeUserRoleModalQuery,
	useChangeUserRoleMutation,
} from './ChangeUserRoleModal.gql';

import useAccountId from '~/hooks/useAccountId';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';

import {
	WebsiteAccess,
} from '~/model/users';

import {
	selectedMemberDetailIdSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	title: {
		id: 'ui.changeRoleModal.title',
	},
});



const ChangeUserRoleModal: React.FC = () => {
	const accountId = useAccountId();
	const classicFormBehavior = useClassicFormBehavior();
	const userId = btoa(useSelector(selectedMemberDetailIdSelector));

	const { data } = useChangeUserRoleModalQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
			legacyUserId: userId,
		},
	});

	const userAccountMembership = data?.userAccountMembership ?? null;

	const [changeUserRole] = useChangeUserRoleMutation();

	const multiStepModalRef = React.useRef<any>();

	const [role, setRole] = React.useState<GraphQL.UserRole | null>(null);

	const handleSubmitLimitedWebsitesAccess = React.useCallback(
		async ({ assignedWebsites, scope }) => {
			if (accountId === null || role === null) {
				return;
			}

			await changeUserRole({
				variables: {
					accountId,
					assignedWebsites: scope === WebsiteAccess.Selected ? assignedWebsites : null,
					limitedWebsitesAccess: scope === WebsiteAccess.Selected,
					legacyId: userId,
					legacyUserId: userId,
					role,
				},
			});

			classicFormBehavior.finish();
		},
		[
			accountId,
			changeUserRole,
			classicFormBehavior,
			role,
			userId,
		],
	);

	const handleSubmitRoleStep = React.useCallback(
		async (role: GraphQL.UserRole) => {
			if (accountId === null) {
				return;
			}

			if (
				role === GraphQL.UserRole.Editor
				|| role === GraphQL.UserRole.Viewer
			) {
				setRole(role);

				multiStepModalRef.current?.goToNextStep();

				return;
			}

			await changeUserRole({
				variables: {
					accountId,
					assignedWebsites: null,
					limitedWebsitesAccess: null,
					legacyId: userId,
					legacyUserId: userId,
					role,
				},
			});

			classicFormBehavior.finish();
		},
		[
			accountId,
			changeUserRole,
			classicFormBehavior,
			setRole,
			userId,
		],
	);

	function renderHeader() {
		return (
			<HeaderTitleWithValue
				size={TITLE_SIZE_MODAL}
				title={(
					<FormattedMessage {...messages.title} />
				)}
				value={userAccountMembership !== null ? userAccountMembership.user.email : ''}
			/>
		);
	}

	const showLimitedWebsitesAccessStep = userAccountMembership !== null && [
		GraphQL.UserRole.Editor,
		GraphQL.UserRole.Viewer,
	].includes(role || userAccountMembership.role);

	return (
		<MultiStepModal
			minHeight={420}
			preloader={userAccountMembership === null && (
				<LoadingDots isStretched={true} />
			)}
			ref={multiStepModalRef}
		>
			<MultiStepModalStep
				name="change-role-step"
				title={renderHeader()}
			>
				{userAccountMembership !== null && (
					<ChangeRoleForm
						onRoleChange={setRole}
						onSubmitCallback={handleSubmitRoleStep}
						role={userAccountMembership.role}
					/>
				)}
			</MultiStepModalStep>

			{showLimitedWebsitesAccessStep && (
				<MultiStepModalStep
					name="limited-websites-access-step"
					title={renderHeader()}
				>
					<ChangeUserRoleAssignWebsitesForm
						hasLimitedWebsitesAccess={userAccountMembership.hasLimitedWebsitesAccess}
						onSubmitCallback={handleSubmitLimitedWebsitesAccess}
						websites={userAccountMembership.websites}
					/>
				</MultiStepModalStep>
			)}
		</MultiStepModal>
	);
};



export default ChangeUserRoleModal;
