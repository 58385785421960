/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChooseCustomerTypeMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  customerType: GraphQL.CustomerType;
}>;


export type ChooseCustomerTypeMutation = { readonly __typename?: 'Mutation', readonly ChooseCustomerType: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly customerType: GraphQL.CustomerType | null, readonly id: CK.AccountId, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }> } | null } } };


export const ChooseCustomerTypeDocument = gql`
    mutation ChooseCustomerType($accountId: AccountId!, $customerType: CustomerType!) {
  ChooseCustomerType(accountId: $accountId, customerType: $customerType) {
    query {
      account(id: $accountId) {
        customerType
        features {
          feature
          id
        }
        id
      }
    }
  }
}
    `;
export type ChooseCustomerTypeMutationFn = Apollo.MutationFunction<ChooseCustomerTypeMutation, ChooseCustomerTypeMutationVariables>;

/**
 * __useChooseCustomerTypeMutation__
 *
 * To run a mutation, you first call `useChooseCustomerTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseCustomerTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseCustomerTypeMutation, { data, loading, error }] = useChooseCustomerTypeMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      customerType: // value for 'customerType'
 *   },
 * });
 */
export function useChooseCustomerTypeMutation(baseOptions?: Apollo.MutationHookOptions<ChooseCustomerTypeMutation, ChooseCustomerTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChooseCustomerTypeMutation, ChooseCustomerTypeMutationVariables>(ChooseCustomerTypeDocument, options);
      }
export type ChooseCustomerTypeMutationHookResult = ReturnType<typeof useChooseCustomerTypeMutation>;
export type ChooseCustomerTypeMutationResult = Apollo.MutationResult<ChooseCustomerTypeMutation>;
export type ChooseCustomerTypeMutationOptions = Apollo.BaseMutationOptions<ChooseCustomerTypeMutation, ChooseCustomerTypeMutationVariables>;