import debounce from 'lodash/debounce';
import React from 'react';

import AbstractTextField, {
	AbstractTextFieldType,
} from '~/components/patterns/forms/fields/AbstractTextField';



type Props = {
	value?: string | null,
	onChange: (value: string) => void,
};

const SearchFilter: React.FC<Props> = (props) => {
	const {
		value,
		onChange,
	} = props;

	const handleChange = React.useMemo(
		() => {
			return debounce((value) => onChange(value), 300);
		},
		[
			onChange,
		],
	);

	return (
		<AbstractTextField
			attributes={{
				placeholder: 'Enter search term',
			}}
			key="pageProperties"
			name="pageProperties"
			onChangeCallback={handleChange}
			trimValue={true}
			type={AbstractTextFieldType.Search}
			value={value ?? ''}
			width={false}
		/>
	);
};

export default SearchFilter;
