import max from 'date-fns/max';
import subDays from 'date-fns/subDays';
import Immutable from 'immutable';
import React from 'react';
import {
	useIntl,
} from 'react-intl';

import CK from '~/types/contentking';

import AttachedChartOverlay from '~/components/patterns/charts/structures/AttachedChartOverlay';
import ChartContainer, {
	CHART_HEIGHT,
} from '~/components/atoms/charts/components/ChartContainer';
import ColumnChart from '~/components/atoms/charts/charts/ColumnChart';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';

import {
	useWebsiteStatisticsPageChangesChartQuery,
} from './WebsiteStatisticsPageChangesChart.gql';

import useBatchContextMultiDashboardAccountSection from '~/hooks/useBatchContextMultiDashboardAccountSection';
import useNavigateToTrackedChanges from '~/hooks/useNavigateToTrackedChanges';
import useNavigation from '~/hooks/useNavigation';
import usePageChangeTypeTitles from '~/hooks/usePageChangeTypeTitles';
import usePollInterval from '~/hooks/usePollInterval';
import useViewportType from '~/hooks/useViewportType';

import {
	CHANGES_IN_ANY_COLUMN,
	CHANGE_TYPE_ADDED,
	CHANGE_TYPE_CHANGED,
	CHANGE_TYPE_OTHER,
	CHANGE_TYPE_REDIRECTED,
	CHANGE_TYPE_REMOVED,
	formatDateForBackend,
	formatEndDateForBackend,
} from '~/model/historicalChanges';

import {
	calculateTickIntervalNew,
} from '~/model/websiteStatistics/charts/utilities';



const categories = [
	{
		name: CHANGE_TYPE_CHANGED,
		color: '#FFB131',
	},
	{
		name: CHANGE_TYPE_ADDED,
		color: '#42CC67',
	},
	{
		name: CHANGE_TYPE_REMOVED,
		color: '#FF5959',
	},
	{
		name: CHANGE_TYPE_REDIRECTED,
		color: '#367be2',
	},
	{
		name: CHANGE_TYPE_OTHER,
		color: '#8595A6',
	},
];



const WebsiteStatisticsPageChangesChart = (props) => {
	const {
		accountId,
		hasBeenVisible,
		isVisible,
		websiteCreatedAt,
		websiteId,
	} = props;

	const intl = useIntl();
	const navigateToTrackedChanges = useNavigateToTrackedChanges();
	const navigation = useNavigation();
	const pageChangeTypeTitles = usePageChangeTypeTitles();
	const viewportType = useViewportType();

	const endDate = new Date();
	const startDate = max([
		subDays(endDate, 6),
		new Date(websiteCreatedAt),
	]);

	const {
		data,
	} = useWebsiteStatisticsPageChangesChartQuery({
		context: useBatchContextMultiDashboardAccountSection(accountId),
		pollInterval: usePollInterval(isVisible ? 30000 : 0),
		skip: !hasBeenVisible,
		variables: {
			endDate: formatEndDateForBackend(startDate, endDate),
			scope: 'website',
			startDate: formatDateForBackend(startDate),
			websiteId,
		},
	});

	const chartData = React.useMemo(
		() => {
			const items = data?.dashboardData.pageChanges?.data ?? null;

			if (items === null) {
				return null;
			}

			return {
				data: categories.map((category) => ({
					name: category.name,
					data: items.map((item) => {
						const change = item.changes.find((change) => change.changeType === category.name);

						if (change !== undefined) {
							return change.numberOfPages;
						}

						return 0;
					}),
				})),
				legendItems: categories.map((category) => ({
					color: category.color,
					label: pageChangeTypeTitles[category.name],
				})),
				seriesColors: categories.map((category) => category.color),
				xAxisCaptions: Immutable.List(
					items.map((item) => {
						if (item.startDate === item.endDate) {
							return intl.formatDate(item.endDate, {
								day: 'numeric',
								month: 'short',
								timeZone: 'Europe/Amsterdam',
							});
						}

						return intl.formatDate(item.startDate, {
							day: 'numeric',
							month: 'short',
							timeZone: 'Europe/Amsterdam',
						}) + ' -<br/>' + intl.formatDate(item.endDate, {
							day: 'numeric',
							month: 'short',
							timeZone: 'Europe/Amsterdam',
						});
					}),
				),
			};
		},
		[
			data,
			intl,
			pageChangeTypeTitles,
		],
	);

	const renderTooltip = React.useCallback(
		({ count, seriesName }) => {
			return pageChangeTypeTitles[seriesName] + ': ' + intl.formatNumber(count);
		},
		[
			intl,
			pageChangeTypeTitles,
		],
	);

	const handleChartClick = React.useCallback(
		(pointIndex, changeType) => {
			const {
				endDate,
				startDate,
			} = data.dashboardData.pageChanges.data[pointIndex];

			navigation.navigate(
				navigateToTrackedChanges({
					endDate,
					filter: {
						[CK.PagesCommonColumn.ChangeType]: [changeType],
						changes_in: changeType === CHANGE_TYPE_CHANGED ? CHANGES_IN_ANY_COLUMN : null,
						changes_in_columns: null,
					},
					scope: 'website',
					startDate,
					websiteId,
				}),
			);
		},
		[
			data,
			navigateToTrackedChanges,
			navigation,
			websiteId,
		],
	);

	if (chartData === null) {
		return (
			<AttachedChartOverlay>
				<SquareSkeleton
					height={CHART_HEIGHT}
					style={SquareSkeletonStyle.Transparent}
				/>
			</AttachedChartOverlay>
		);
	}

	const numberOfYAxisPoints = 3;

	return (
		<AttachedChartOverlay>
			<ChartContainer
				animate={true}
				chart={(
					<ColumnChart
						barWidth={10}
						data={chartData.data}
						key="chart-page-changes"
						maximum={({ maxValue }) => calculateTickIntervalNew(maxValue * 1.1, numberOfYAxisPoints)}
						minBarLength={3}
						minimum={0}
						onClickCallback={handleChartClick}
						renderTooltipCallback={renderTooltip}
						seriesColors={chartData.seriesColors}
						viewportType={viewportType}
						xAxisCaptions={chartData.xAxisCaptions}
						yAxisPoints={numberOfYAxisPoints}
					/>
				)}
				disabled={false}
				name="page-changes"
				padded={true}
				type="column-chart"
			/>
		</AttachedChartOverlay>
	);
};



export default React.memo(WebsiteStatisticsPageChangesChart);
