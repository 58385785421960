import classNames from 'classnames';
import React, {
	Component,
} from 'react';

import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';



type Props = {
	/** Default content visibility state */
	hiddenContent: boolean,
	children: React.ReactNode,
	/** Link label to reveal hidden content */
	toggleLinkLabel?: React.ReactNode,
};

type State = {
	hiddenContent: boolean,
};



class HiddenContent extends Component<Props, State> {

	static defaultProps = {
		hiddenContent: true,
	};



	constructor(props, context) {
		super(props, context);

		this._handleRevealContent = this._handleRevealContent.bind(this);

		this.state = {
			hiddenContent: props.hiddenContent,
		};
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			hiddenContent,
		} = this.props;

		const {
			hiddenContent: nextHiddenContent,
		} = nextProps;

		if (hiddenContent !== nextHiddenContent) {
			this.setState({
				hiddenContent: nextHiddenContent,
			});
		}
	}



	_handleRevealContent(e) {
		e.preventDefault();

		this.setState({
			hiddenContent: false,
		});
	}



	_renderRevealLink() {
		const {
			toggleLinkLabel,
		} = this.props;

		const {
			hiddenContent,
		} = this.state;

		if (!toggleLinkLabel || !hiddenContent) {
			return false;
		}

		return (
			<div className="hidden-content__reveal-link">
				<InternalLink
					onClickCallback={this._handleRevealContent}
					style={InternalLinkStyle.Decent}
				>
					{toggleLinkLabel}
				</InternalLink>
			</div>
		);
	}



	render() {
		const {
			children,
		} = this.props;

		const {
			hiddenContent,
		} = this.state;

		const componentClasses = classNames({
			'hidden-content': true,
			'hidden-content--is-hidden': hiddenContent,
		});

		return (
			<div className={componentClasses}>
				{this._renderRevealLink()}
				<div className="hidden-content__body">
					{children}
				</div>
			</div>
		);
	}

}



export default HiddenContent;
