import {
	differenceInMilliseconds,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AutoprioritizationHint from '~/components/logic/interfaceHints/AutoprioritizationHint';
import LastCheckTimestamp, {
	LastCheckTimestampCrawlingType,
	LastCheckTimestampSize,
} from '~/components/patterns/time/LastCheckTimestamp';
import PageLastCheckedMessage, {
	PageLastCheckedMessageType,
} from '~/components/app/PageLastCheckedMessage';

import usePageIsPrioritized from '~/hooks/usePageIsPrioritized';
import usePageLastCheckedAt from '~/hooks/usePageLastCheckedAt';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';



export { PageLastCheckedMessageType };



const messages = defineMessages({
	hint: {
		id: 'ui.pageLastCheckedInfo.hint',
	},
	label: {
		id: 'ui.pageLastCheckedInfo.label',
	},
	paused: {
		id: 'ui.pageLastCheckedInfo.paused',
	},
});



type Props = {
	legacyUrlId: number,
	messageType?: PageLastCheckedMessageType,
	websiteId: CK.WebsiteId,
};

const PageLastCheckedInfo: React.FC<Props> = (props) => {
	const {
		legacyUrlId,
		messageType = PageLastCheckedMessageType.Generic,
		websiteId,
	} = props;

	const isWebsitePaused = useWebsiteIsCrawlingPaused(websiteId);
	const pageIsPrioritized = usePageIsPrioritized(legacyUrlId, websiteId);
	const pageLastCheckedAt = usePageLastCheckedAt(legacyUrlId, websiteId);

	const [lastCheckedAt, setLastCheckedAt] = React.useState(pageLastCheckedAt);

	const isSpinning = (
		pageIsPrioritized === true
		&& isWebsitePaused === false
	);

	React.useEffect(
		() => {
			if (pageLastCheckedAt === lastCheckedAt) {
				return;
			}

			if (pageLastCheckedAt === null) {
				return;
			}

			if (lastCheckedAt === null) {
				setLastCheckedAt(pageLastCheckedAt);

				return;
			}

			const diff = differenceInMilliseconds(new Date(), pageLastCheckedAt);

			if (diff < 60000) {
				if (differenceInMilliseconds(new Date(), lastCheckedAt) < 60000) {
					return;
				}

				setLastCheckedAt(new Date());

				const timeout = setTimeout(
					() => {
						setLastCheckedAt(pageLastCheckedAt);
					},
					diff,
				);

				return () => {
					clearTimeout(timeout);
				};
			}

			setLastCheckedAt(pageLastCheckedAt);
		},
		[
			lastCheckedAt,
			pageLastCheckedAt,
		],
	);

	let message: React.ReactNode;
	let tooltip: React.ReactNode;

	if (isSpinning && lastCheckedAt === null) {
		message = (
			<FormattedMessage {...messages.label} />
		);

		tooltip = (
			<FormattedMessage {...messages.hint} />
		);
	} else if (lastCheckedAt !== null) {
		message = (
			<PageLastCheckedMessage
				date={lastCheckedAt}
			/>
		);

		tooltip = (
			<PageLastCheckedMessage
				date={lastCheckedAt}
				messageType={messageType}
			/>
		);

		if (isWebsitePaused) {
			tooltip = (
				<FormattedMessage
					{...messages.paused}
					values={{
						pastRelativeText: tooltip,
					}}
				/>
			);
		} else if (isSpinning) {
			tooltip = (
				<FormattedMessage {...messages.hint} />
			);
		}
	}

	return (
		<AutoprioritizationHint>
			<LastCheckTimestamp
				checkedAtLabel={message}
				crawlingType={(
					isSpinning
						? LastCheckTimestampCrawlingType.InProgress
						: LastCheckTimestampCrawlingType.Ready
				)}
				explanationMessage={tooltip}
				isCrawlingPaused={isWebsitePaused ?? undefined}
				size={LastCheckTimestampSize.Small}
			/>
		</AutoprioritizationHint>
	);
};



export default PageLastCheckedInfo;
