import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AdminUserRoyalAccessForm from '~/components/app/AdminUserRoyalAccessForm';
import AdminUserSettingsForm from '~/components/app/AdminUserSettingsForm';
import CurrentUserMembershipsOverview from '~/components/app/CurrentUserMembershipsOverview';
import FormsList from '~/components/atoms/lists/FormsList';
import InstallBrowserExtensionForm from '~/components/app/InstallBrowserExtensionForm';
import PersonalSettingsForm from '~/components/app/PersonalSettingsForm';
import TwoFactorAuthenticationForm from '~/components/app/TwoFactorAuthenticationForm';
import UserCustomerTeamContextDetailsOverview from '~/components/app/UserCustomerTeamContextDetailsOverview';

import useDisabledMemberships from '~/hooks/useDisabledMemberships';
import useIsAllowedWithUser from '~/hooks/useIsAllowedWithUser';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useUserIsEligibleForRoyalAccess from '~/hooks/useUserIsEligibleForRoyalAccess';

import {
	IsConductorClient,
} from '~/config';



type Props = {
	isForCurrentUser: boolean,
	userId: string | null,
} & ({
	isForCurrentUser: false,
	memberships?: never,
} | {
	isForCurrentUser: true,
	memberships: ReadonlyArray<{
		account: {
			id: CK.AccountId,
		},
		hasLimitedWebsitesAccess: boolean,
		role: GraphQL.UserRole,
		websites: ReadonlyArray<{
			id: CK.WebsiteId,
		}>,
	}>,
});

const PersonalSettingsGroup: React.FC<Props> = (props) => {
	const {
		isForCurrentUser,
		memberships,
		userId,
	} = props;

	const disabledMemberships = useDisabledMemberships();
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const userIsEligibleForRoyalAccess = useUserIsEligibleForRoyalAccess(userId);
	const isCurrentUserAllowedToManageRoyalAccess = useIsAllowedWithUser(
		userId,
		GraphQL.ActionWithUser.ManageRoyalAccess,
	);

	const forms: Array<React.ReactNode> = [];

	if (isForCurrentUser) {
		if ((memberships.length + (disabledMemberships?.length ?? 0)) > 1) {
			forms.push((
				<CurrentUserMembershipsOverview
					key="current-user-memberships-overview"
					memberships={memberships}
				/>
			));
		}

		forms.push((
			<PersonalSettingsForm
				key="personal-settings-form"
				userId={userId}
			/>
		));

		forms.push((
			<InstallBrowserExtensionForm
				key="install-browser-extension"
			/>
		));

		if (IsConductorClient === false) {
			forms.push((
				<TwoFactorAuthenticationForm
					key="two-factor-authentication"
				/>
			));
		}
	} else {
		forms.push((
			<PersonalSettingsForm
				key="member-personal-settings-form"
				userId={userId}
			/>
		));

		forms.push((
			<InstallBrowserExtensionForm
				key="install-browser-extension"
			/>
		));
	}

	if (kingdomAdminFeatures.areVisible) {
		forms.push((
			<AdminUserSettingsForm
				isForCurrentUser={isForCurrentUser}
				key="admin-user-settings-form"
				legacyUserId={userId}
			/>
		));

		if (userIsEligibleForRoyalAccess && isCurrentUserAllowedToManageRoyalAccess.yes) {
			forms.push((
				<AdminUserRoyalAccessForm
					key="admin-user-royal-access-form"
					userId={userId}
				/>
			));
		}

		forms.push((
			<UserCustomerTeamContextDetailsOverview
				key="admin-customer-team-context"
				userId={userId}
			/>
		));
	}

	return (
		<FormsList>
			{forms}
		</FormsList>
	);
};



export default PersonalSettingsGroup;
