/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEnrichmentFieldMutationVariables = GraphQL.Exact<{
  dataType: GraphQL.EnrichmentFieldDataType;
  label: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CreateEnrichmentFieldMutation = { readonly __typename?: 'Mutation', readonly CreateEnrichmentField: { readonly __typename?: 'CreateEnrichmentFieldResult', readonly enrichmentField: { readonly __typename?: 'EnrichmentField', readonly id: CK.ID } | null, readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly enrichmentFields: ReadonlyArray<{ readonly __typename?: 'EnrichmentField', readonly column: `ef_${string}`, readonly dataType: GraphQL.EnrichmentFieldDataType, readonly id: CK.ID, readonly label: string }> } | null } } };


export const CreateEnrichmentFieldDocument = gql`
    mutation CreateEnrichmentField($dataType: EnrichmentFieldDataType!, $label: String!, $websiteId: WebsiteId!) {
  CreateEnrichmentField(dataType: $dataType, label: $label, websiteId: $websiteId) {
    enrichmentField {
      id
    }
    query {
      website(id: $websiteId) {
        id
        enrichmentFields {
          column
          dataType
          id
          label
        }
      }
    }
  }
}
    `;
export type CreateEnrichmentFieldMutationFn = Apollo.MutationFunction<CreateEnrichmentFieldMutation, CreateEnrichmentFieldMutationVariables>;

/**
 * __useCreateEnrichmentFieldMutation__
 *
 * To run a mutation, you first call `useCreateEnrichmentFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnrichmentFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnrichmentFieldMutation, { data, loading, error }] = useCreateEnrichmentFieldMutation({
 *   variables: {
 *      dataType: // value for 'dataType'
 *      label: // value for 'label'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCreateEnrichmentFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnrichmentFieldMutation, CreateEnrichmentFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEnrichmentFieldMutation, CreateEnrichmentFieldMutationVariables>(CreateEnrichmentFieldDocument, options);
      }
export type CreateEnrichmentFieldMutationHookResult = ReturnType<typeof useCreateEnrichmentFieldMutation>;
export type CreateEnrichmentFieldMutationResult = Apollo.MutationResult<CreateEnrichmentFieldMutation>;
export type CreateEnrichmentFieldMutationOptions = Apollo.BaseMutationOptions<CreateEnrichmentFieldMutation, CreateEnrichmentFieldMutationVariables>;