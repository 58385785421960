import React from 'react';



type Props = {
	/** Slider component */
	slider: React.ReactNode,
	/** Title */
	title?: React.ReactNode,
};



const CountSliderHeadlineLayout: React.FC<Props> = (props) => {
	const {
		slider,
		title,
	} = props;

	return (
		<div className="count-slider-headline">
			<div className="count-slider-headline__title">
				{title}
			</div>
			<div className="count-slider-headline__slider">
				{slider}
			</div>
		</div>
	);
};



export default CountSliderHeadlineLayout;
