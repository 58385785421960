import React from 'react';

import type CK from '~/types/contentking';

import {
	useWebsiteScopeIssuesLazyQuery,
} from './useWebsiteScopeIssues.gql';



function useReloadWebsiteScopeIssues(websiteId: CK.WebsiteId | null) {
	const [reloadWebsiteScopeIssues] = useWebsiteScopeIssuesLazyQuery({
		fetchPolicy: 'cache-and-network',
	});

	return React.useCallback(
		async () => {
			if (websiteId === null) {
				return;
			}

			await reloadWebsiteScopeIssues({
				variables: {
					websiteId,
				},
			});
		},
		[
			reloadWebsiteScopeIssues,
			websiteId,
		],
	);
}



export default useReloadWebsiteScopeIssues;
