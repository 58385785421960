import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import ModalPanel from '~/components/atoms/panels/ModalPanel';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';

import useModals from '~/hooks/useModals';

import {
	getRouter,
} from '~/routing/router';



const messages = defineMessages({
	title: {
		id: 'ui.protectUnsavedChanges.title',
	},
	description: {
		id: 'ui.protectUnsavedChanges.description',
	},
	stayOnPage: {
		id: 'ui.protectUnsavedChanges.stayOnPage',
	},
	discardAndExit: {
		id: 'ui.protectUnsavedChanges.discardAndExit',
	},
	saveAndExit: {
		id: 'ui.protectUnsavedChanges.saveAndExit',
	},
});



type Options = {
	description?: React.ReactNode,
	title?: React.ReactNode,
};



/**
 * Protect user from losing unsaved changes when leaving a screen. The user is
 * presented with either an in-app modal or a native browser dialog.
 *
 * The in-app modal is shown when a user tries to navigate to another screen
 * within our app. The native browser dialog is shown when refreshing or closing
 * the current tab or when navigating away from our app.
 *
 * CAUTION: Due to browser and router limitations we cannot protect user when
 * using the browsers _back button_.
 **/
function useProtectUnsavedChanges(options: Options = {}) {
	const {
		description,
		title,
	} = options;

	const modals = useModals();

	const unloadListener = React.useCallback(
		(event: BeforeUnloadEvent) => {
			event.preventDefault();
			event.returnValue = '';
		},
		[],
	);

	const protectedRouteName = React.useRef<string | null>(null);

	const disableProtection = React.useCallback(
		() => {
			if (protectedRouteName.current === null) {
				return;
			}

			window.removeEventListener('beforeunload', unloadListener);

			const router = getRouter();
			router.clearCanDeactivate(protectedRouteName.current);

			protectedRouteName.current = null;
		},
		[
			unloadListener,
		],
	);

	const protectUnsavedChanges = React.useCallback(
		() => {
			if (protectedRouteName.current !== null) {
				disableProtection();
			}

			window.addEventListener('beforeunload', unloadListener);

			const router = getRouter();
			const routeName = router.getState().name;

			protectedRouteName.current = routeName;

			router.canDeactivate(routeName, () => (toState) => {
				function discardAndExit() {
					disableProtection();

					modals.closeModal();
					router.navigate(toState.name, toState.params, { force: true });
				}

				function stayOnPage() {
					modals.closeModal();
				}

				modals.openModal(() => (
					<ModalPanel
						canBeClosed={true}
						canBeClosedViaOverlay={true}
						onCloseCallback={modals.closeModal}
					>
						<ModalContainer
							gapsSize={2}
							header={(
								<ModalHeader
									title={title ?? <FormattedMessage {...messages.title} />}
								/>
							)}
							headerGapsSize={1}
						>
							<ModalTextSection>
								<RichText>
									<p>
										{description ?? <FormattedMessage {...messages.description} />}
									</p>
								</RichText>
							</ModalTextSection>

							<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
								<Button
									onClick={stayOnPage}
									style={ButtonStyle.Link}
								>
									<FormattedMessage {...messages.stayOnPage} />
								</Button>

								<Button
									onClick={discardAndExit}
									style={ButtonStyle.Hollow}
								>
									<FormattedMessage {...messages.discardAndExit} />
								</Button>
							</ModalButtonsLayout>
						</ModalContainer>
					</ModalPanel>
				));
			});
		},
		[
			description,
			disableProtection,
			modals,
			title,
			unloadListener,
		],
	);

	return React.useMemo(
		() => ({
			protectUnsavedChanges,
			disableProtection,
		}),
		[
			protectUnsavedChanges,
			disableProtection,
		],
	);
}



export default useProtectUnsavedChanges;
