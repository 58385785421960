import React from 'react';

import CK from '~/types/contentking';

import Ellipsis from '~/components/patterns/values/Ellipsis';
import OnlySpecificPageTypeFormatter from './OnlySpecificPageTypeFormatter';

import {
	TYPE_MISSING,
	type TYPE_NOT_CRAWLED_YET,
	type TYPE_NO_DATA,
	type TYPE_OVERQUOTA,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	type TYPE_UNREACHABLE,
} from '~/model/pages';



const ColumnsWithFixedWidth: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.Health,
];

const PageOnlyColumns: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.AnalyticsServices,
	CK.PagesCommonColumn.CanonicalUrl,
	CK.PagesCommonColumn.H1,
	CK.PagesCommonColumn.HreflangLanguage,
	CK.PagesCommonColumn.LighthouseCumulativeLayoutShift,
	CK.PagesCommonColumn.LighthouseFirstContentfulPaint,
	CK.PagesCommonColumn.LighthouseLargestContentfulPaint,
	CK.PagesCommonColumn.LighthousePerformance,
	CK.PagesCommonColumn.LighthouseSpeedIndex,
	CK.PagesCommonColumn.LighthouseTimeToInteractive,
	CK.PagesCommonColumn.LighthouseTotalBlockingTime,
	CK.PagesCommonColumn.LinkAmp,
	CK.PagesCommonColumn.LinkNext,
	CK.PagesCommonColumn.LinkPrev,
	CK.PagesCommonColumn.MetaDescription,
	CK.PagesCommonColumn.MobileVariant,
	CK.PagesCommonColumn.NumberOfHreflangs,
	CK.PagesCommonColumn.OpenGraphDescription,
	CK.PagesCommonColumn.OpenGraphImage,
	CK.PagesCommonColumn.OpenGraphTitle,
	CK.PagesCommonColumn.OpenGraphType,
	CK.PagesCommonColumn.OpenGraphUrl,
	CK.PagesCommonColumn.Relevance,
	CK.PagesCommonColumn.SchemaOrgNumberOfTypes,
	CK.PagesCommonColumn.SchemaOrgTypes,
	CK.PagesCommonColumn.TagManagers,
	CK.PagesCommonColumn.Title,
	CK.PagesCommonColumn.TwitterCard,
	CK.PagesCommonColumn.TwitterDescription,
	CK.PagesCommonColumn.TwitterImage,
	CK.PagesCommonColumn.TwitterSite,
	CK.PagesCommonColumn.TwitterTitle,
	CK.PagesCommonColumn.VisualAnalyticsServices,
];

const RedirectOnlyColumns: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.Redirect,
];

const UnreachableOnlyColumns: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.TimeServerResponse,
];



const ALLOWED_PAGE_TYPES_PAGE = [
	TYPE_PAGE,
] as const;

const ALLOWED_PAGE_TYPES_REDIRECT = [
	TYPE_REDIRECT,
] as const;

const ALLOWED_PAGE_TYPES_NOT_UNREACHABLE = [
	TYPE_MISSING,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
] as const;



type Props = {
	column: CK.PagesColumn,
	pageType: (
		| typeof TYPE_MISSING
		| typeof TYPE_NO_DATA
		| typeof TYPE_NOT_CRAWLED_YET
		| typeof TYPE_OVERQUOTA
		| typeof TYPE_PAGE
		| typeof TYPE_REDIRECT
		| typeof TYPE_SERVER_ERROR
		| typeof TYPE_UNREACHABLE
	),
	value: any,
	zIndex?: number,
};

const ColumnFormatter: React.FC<Props> = (props) => {
	const {
		column,
		pageType,
		value,
		zIndex,
	} = props;

	let formattedValue = value;

	if (PageOnlyColumns.includes(column)) {
		formattedValue = (
			<OnlySpecificPageTypeFormatter
				allowedPageTypes={ALLOWED_PAGE_TYPES_PAGE}
				pageType={pageType}
				value={formattedValue}
			/>
		);
	} else if (RedirectOnlyColumns.includes(column)) {
		formattedValue = (
			<OnlySpecificPageTypeFormatter
				allowedPageTypes={ALLOWED_PAGE_TYPES_REDIRECT}
				pageType={pageType}
				value={formattedValue}
			/>
		);
	} else if (UnreachableOnlyColumns.includes(column)) {
		formattedValue = (
			<OnlySpecificPageTypeFormatter
				allowedPageTypes={ALLOWED_PAGE_TYPES_NOT_UNREACHABLE}
				pageType={pageType}
				value={formattedValue}
			/>
		);
	}

	if (!ColumnsWithFixedWidth.includes(column)) {
		formattedValue = (
			<Ellipsis popupZIndex={zIndex}>
				{formattedValue}
			</Ellipsis>
		);
	}

	return formattedValue;
};



export default ColumnFormatter;
