import classNames from 'classnames';
import React from 'react';

import styles from './HighlightedBox.module.scss';



type Props = {
	children: React.ReactNode,
	/** Section designed for attached call-to-action elements */
	ctaElements?: React.ReactNode,
	/** We have to describe what kind of content is highlighted using title */
	title: React.ReactNode,
};

const HighlightedBox: React.FC<Props> = (props) => {
	const {
		ctaElements,
		children,
		title,
	} = props;

	const elements = React.Children.toArray(children).filter((child) => !!child);

	return (
		<div className={styles.component}>
			{title && (
				<div className={styles.heading}>
					<div className={styles.title}>
						{title}
					</div>
				</div>
			)}
			<div className={styles.content}>
				{elements.map((child, index) => {
					const itemClasses = classNames({
						[styles.contentItem]: true,
						[styles.contentItemHasDivider]: elements.length > 1,
						[styles.contentItemIsStandalone]: elements.length === 1,
					});

					return (
						<div
							className={itemClasses}
							key={'highlighted-box-' + index}
						>
							{child}
						</div>
					);
				})}
				{ctaElements && (
					<div className={styles.ctaElements}>
						{ctaElements}
					</div>
				)}
			</div>
		</div>
	);
};



export default HighlightedBox;
