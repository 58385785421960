import Immutable from 'immutable';
import chunk from 'lodash/chunk';

import {
	UPDATE_PAGES_FILTER,
	UPDATE_PAGES_SORTING,
	startLoadingPagesByIds,
	storeLoadedPages,
} from '~/actions';

import {
	DEFAULT_FILTER,
	DEFAULT_SORT_BY,
	GRAPHABLE_FIELDS,
	STATE_NOT_LOADED,
	createFullId,
	getFilter,
	loadPagesByIdsViaApi,
	loadPagesChartsViaApi,
	normalizeFilter,
} from '~/model/pages';

import {
	getSegmentDefinitionByName,
} from '~/model/segments';

import {
	filterSelector,
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';

import encodeInBase64 from '~/utilities/encodeInBase64';



export const CLEAR_PAGES_CHARTS = 'CLEAR_PAGES_CHARTS';
export const START_LOADING_PAGES_CHARTS = 'START_LOADING_PAGES_CHARTS';
export const STORE_PAGES_CHARTS = 'STORE_PAGES_CHARTS';
export const UPDATE_PAGES_CHARTS = 'UPDATE_PAGES_CHARTS';



function normalizePagesFilterAndSorting(filter, sortBy, columns, segments) {
	filter = normalizeFilter(filter);

	const removeUnusedColumns = (value, key) => {
		return columns.indexOf(key) >= 0;
	};

	filter = filter.filter(removeUnusedColumns);

	const defaultFilter = DEFAULT_FILTER.filter(removeUnusedColumns);

	if (filter.has('segments') && segments) {
		let segmentsFilter = filter.get('segments');

		segmentsFilter = segmentsFilter.set(
			'included_in',
			segmentsFilter
				.get('included_in')
				.filter((segmentName) => getSegmentDefinitionByName(
					segments,
					segmentName,
				) !== null),
		);
		segmentsFilter = segmentsFilter.set(
			'not_included_in',
			segmentsFilter
				.get('not_included_in')
				.filter((segmentName) => getSegmentDefinitionByName(
					segments,
					segmentName,
				) !== null),
		);

		if (segmentsFilter.get('included_in').size > 0 || segmentsFilter.get('not_included_in').size > 0) {
			filter = filter.set('segments', segmentsFilter);
		} else {
			filter = filter.set('segments', DEFAULT_FILTER.get('segments'));
		}
	}

	if (JSON.stringify(filter.toJS()) === JSON.stringify(defaultFilter.toJS())) {
		filter = null;
	}

	if (JSON.stringify(sortBy) === JSON.stringify(DEFAULT_SORT_BY)) {
		sortBy = null;
	}

	if (filter || sortBy) {
		return {
			filter: filter ? filter.toJS() : null,
			sortBy: sortBy ? sortBy.toJS() : null,
		};
	}
}



export function createFilterParameter(filter, sortBy, columns, segments) {
	const normalizedFilterAndSorting = normalizePagesFilterAndSorting(
		filter,
		sortBy,
		columns,
		segments,
	);

	return normalizedFilterAndSorting
		? encodeInBase64(JSON.stringify(normalizedFilterAndSorting))
		: false;
}



export function resetPagesFilter() {
	return (dispatch, getState) => {
		const clearFilters = filterSelector(getState()).map((value, key) => {
			return DEFAULT_FILTER.get(key) || undefined;
		});

		dispatch(
			updatePagesFilter(clearFilters),
		);
	};
}



export function updatePagesFilter(filter) {
	filter = Immutable.fromJS(filter).map((value, key) => {
		if (value === undefined) {
			return DEFAULT_FILTER.get(key) || '';
		}

		if ([
			'is_disallowed_in_robots_txt',
			'is_in_sitemap',
			'is_indexable',
			'is_secured',
			'is_linked',
		].indexOf(key) !== -1) {
			value = value.map((value) => {
				if (value === 'true') {
					return true;
				} else if (value === 'false') {
					return false;
				}

				return value;
			});
		}

		return value;
	}).toJS();

	return function (dispatch) {
		dispatch({
			type: UPDATE_PAGES_FILTER,
			filter,
		});

		return Promise.resolve();
	};
}



export function updatePagesSorting(sortBy) {
	return function (dispatch) {
		dispatch({
			type: UPDATE_PAGES_SORTING,
			sortBy,
		});

		return Promise.resolve();
	};
}



export function loadPagesByIds(ids) {
	return (dispatch, getState) => {
		const websiteId = selectedWebsiteIdSelector(getState());

		if (!websiteId) {
			return Promise.resolve();
		}

		if (ids.size === undefined) {
			ids = Immutable.List(ids);
		}

		ids = ids.filter((id) => {
			const fullId = createFullId(websiteId, id);
			const page = getState().get('pages').get('basics').get(fullId);
			return !page || page.get('_status') === STATE_NOT_LOADED;
		});

		if (ids.size > 0) {
			dispatch(startLoadingPagesByIds(websiteId, ids.toJS()));

			return loadPagesByIdsViaApi(
				websiteId,
				ids,
			).then(
				(data) => {
					dispatch(storeLoadedPages(
						websiteId,
						data.internal_urls || data.urls,
						data.external_urls,
					));
				},
			);
		}

		return Promise.resolve();
	};
}



export function loadPagesCharts(columns, displayLoader = false, resetData = false) {
	return (dispatch, getState) => {
		const websiteId = (
			getState().get('ui').get('active').get('website')
			|| getState().get('lastSelectedWebsite').get('id')
		);

		const filter = filterSelector(getState());

		if (!websiteId) {
			return Promise.resolve();
		}

		if (displayLoader) {
			dispatch(startLoadingPagesCharts(websiteId));
		}

		if (resetData) {
			dispatch(clearLoadedPagesCharts(websiteId));
		}

		const params = {};
		if (filter !== undefined && filter !== null) {
			const {
				criteria,
				isInvalid,
			} = getFilter(filter);

			if (isInvalid) {
				dispatch(clearLoadedPagesCharts(websiteId));

				return Promise.resolve();
			}

			for (const key in criteria) {
				if (criteria.hasOwnProperty(key)) {
					params['filter[' + key + ']'] = criteria[key];
				}
			}
		}

		const graphableFields = columns
			.filter((field) => GRAPHABLE_FIELDS.includes(field))
			.toJS();

		const chunkedFields = chunk(graphableFields, 2);

		dispatch(updatePagesCharts(websiteId, graphableFields));

		const requests = chunkedFields.map((fields) => {
			return loadPagesChartsViaApi(
				websiteId,
				fields,
				params,
			).then(
				(data) => {
					dispatch(storeLoadedPagesCharts(websiteId, data.total, data.statistics));
				},
			);
		});

		return Promise.all(requests);
	};
}



function clearLoadedPagesCharts(websiteId) {
	return {
		type: CLEAR_PAGES_CHARTS,
		websiteId,
	};
}



function startLoadingPagesCharts(domainId) {
	return {
		type: START_LOADING_PAGES_CHARTS,
		domainId,
	};
}



function storeLoadedPagesCharts(websiteId, total, statistics) {
	return {
		type: STORE_PAGES_CHARTS,
		statistics,
		total,
		websiteId,
	};
}



function updatePagesCharts(websiteId, columns) {
	return {
		type: UPDATE_PAGES_CHARTS,
		columns,
		websiteId,
	};
}
