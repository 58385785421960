import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy, {
	linkInternal,
} from '~/components/logic/Copy';
import ExpirationTour from '~/components/patterns/tours/ExpirationTour';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SocialProof from '~/components/app/SocialProof';

import useAccountId from '~/hooks/useAccountId';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useUserFirstName from '~/hooks/useUserFirstName';

import {
	Intercom,
} from '~/globals';



const messages = defineMessages({
	contactUs: {
		id: 'ui.churnedAccountModal.contactUs',
	},
	content: {
		id: 'ui.churnedAccountModal.content',
	},
	footnote: {
		id: 'ui.churnedAccountModal.footnote',
	},
	greeting: {
		id: 'ui.churnedAccountModal.greeting',
	},
	oneMoreThing: {
		id: 'ui.churnedAccountModal.oneMoreThing',
	},
	signUp: {
		id: 'ui.churnedAccountModal.signUp',
	},
	title: {
		id: 'ui.churnedAccountModal.title',
	},
});



type Props = {
	closeCallback?: () => void,
};

const ChurnedAccountModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const accountId = useAccountId();
	const currentUserId = useCurrentUserId();

	const firstName = useUserFirstName(currentUserId);
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const isAllowedToViewSignup = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ViewSignup,
	);

	return (
		<SimpleModal
			canBeClosed={kingdomAdminFeatures.areVisible}
			closeCallback={closeCallback}
			sidebar={(
				<SocialProof hideBenefits={true} />
			)}
			size={SimpleModalSize.XLarge}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ExpirationTour
				additionalInfo={(
					<Copy
						{...messages.footnote}
						values={{
							linkAccount: linkInternal('account'),
						}}
					/>
				)}
				ctaButton={(
					<>
						{isAllowedToViewSignup.yes ? (
							<Button
								linkRouteName="account.pricing"
								size={ButtonSize.XLarge}
								style={ButtonStyle.Action}
							>
								<FormattedMessage {...messages.signUp} />
							</Button>
						) : (
							<Button
								onClick={() => {
									Intercom()('show');
								}}
								size={ButtonSize.XLarge}
								style={ButtonStyle.Action}
							>
								<FormattedMessage {...messages.contactUs} />
							</Button>
						)}
					</>
				)}
			>
				<RichText>
					{firstName && (
						<Copy
							{...messages.greeting}
							values={{
								firstName,
							}}
						/>
					)}
					<Copy {...messages.content} />
				</RichText>
			</ExpirationTour>
		</SimpleModal>
	);
};



export default ChurnedAccountModal;
