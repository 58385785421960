import {
	BUCKETS,
} from './buckets';



export function calculateRecommendedFetchingRate(numberOfPages) {
	let recommendedFetchingRate = 0.0;

	let numberOfPagesOutsideOfBuckets = numberOfPages;
	for (let i = 0; i < BUCKETS.length; i++) {
		const bucket = BUCKETS[i];

		if ((numberOfPagesOutsideOfBuckets - bucket.size) >= 0) {
			numberOfPagesOutsideOfBuckets = numberOfPagesOutsideOfBuckets - bucket.size;
			recommendedFetchingRate += bucket.size / bucket.frequencyInSeconds;
		} else {
			recommendedFetchingRate += numberOfPagesOutsideOfBuckets / bucket.frequencyInSeconds;
			break;
		}
	}

	return recommendedFetchingRate;
}
