import React from 'react';
import {
	format,
} from 'date-fns';

import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';



type Props = {
	/** Calendar component */
	children: React.ReactNode,
	/** Date of selected calendar view */
	currentDate: Date,
	/** Callback triggered when clicking on current date label */
	onCurrentMonthClickCallback?: () => void,
	/** Callback triggered when clicking on next month selection arrow */
	onNextMonthSelectionClickCallback?: () => void,
	/** Callback triggered when clicking on previous month selection arrow */
	onPrevMonthSelectionClickCallback?: () => void,
};



const CalendarController: React.FC<Props> = (props) => {
	const {
		children,
		currentDate,
		onCurrentMonthClickCallback,
		onNextMonthSelectionClickCallback,
		onPrevMonthSelectionClickCallback,
	} = props;

	const handleNextMonthSelection = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			if (onNextMonthSelectionClickCallback) {
				onNextMonthSelectionClickCallback();
			}
		},
		[
			onNextMonthSelectionClickCallback,
		],
	);

	const handlePrevMonthSelection = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();


			if (onPrevMonthSelectionClickCallback) {
				onPrevMonthSelectionClickCallback();
			}
		},
		[
			onPrevMonthSelectionClickCallback,
		],
	);

	return (
		<div className="calendar-controller">
			<div className="calendar-controller__elements">
				<div className="calendar-controller__skip-month-element">
					{onPrevMonthSelectionClickCallback && (
						<a
							className="calendar-controller__arrow calendar-controller__arrow--prev"
							href="#"
							onClick={handlePrevMonthSelection}
						>
							{'<'}
						</a>
					)}
				</div>
				<div className="calendar-controller__current-month">
					{onCurrentMonthClickCallback ? (
						<InternalLink
							onClickCallback={onCurrentMonthClickCallback}
							style={InternalLinkStyle.Decent}
						>
							{format(currentDate, 'MMM yyyy')}
						</InternalLink>
					) : (
						<span>{format(currentDate, 'MMM yyyy')}</span>
					)}
				</div>
				<div className="calendar-controller__skip-month-element">
					{onNextMonthSelectionClickCallback && (
						<a
							className="calendar-controller__arrow calendar-controller__arrow--next"
							href="#"
							onClick={handleNextMonthSelection}
						>
							{'>'}
						</a>
					)}
				</div>
			</div>
			<div className="calendar-controller__calendar">
				{children}
			</div>
		</div>
	);
};



export default CalendarController;
