import React from 'react';

import type CK from '~/types/contentking';

import BillingCycleSwitch from '../formFields/BillingCycleSwitch';
import BillingCycleSwitchBox from '~/components/patterns/pricing/BillingCycleSwitchBox';

import GraphQL from '~/types/graphql';

import useAccountSignup from '~/hooks/useAccountSignup';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';



type Props = {
	accountId: CK.AccountId | null,
};

const BillingCycleConfigurator: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountSignup = useAccountSignup(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);

	const handleChange = React.useCallback(
		(nextBillingCycle: GraphQL.Term) => {
			accountSignup.setProperty('billingCycle', nextBillingCycle);
		},
		[
			accountSignup,
		],
	);

	if (
		accountSignup.billingCycle === null
		|| allowedBillingCycles === null
		|| allowedBillingCycles.length <= 1
		|| !allowedBillingCycles.includes(GraphQL.Term.Monthly)
		|| !allowedBillingCycles.includes(GraphQL.Term.Annually)
	) {
		return null;
	}

	return (
		<BillingCycleSwitchBox>
			<BillingCycleSwitch
				billingCycle={accountSignup.billingCycle}
				name="annualBillingCycle"
				onChangeCallback={handleChange}
			/>
		</BillingCycleSwitchBox>
	);
};



export default BillingCycleConfigurator;
