import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const FoodIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M10.25,0a1,1,0,0,0-1,1V6a1.993,1.993,0,0,1-.571,1.4.251.251,0,0,1-.429-.175V1a1,1,0,0,0-2,0V7.223a.251.251,0,0,1-.429.175A1.993,1.993,0,0,1,5.25,6V1a1,1,0,0,0-2,0V6A4.009,4.009,0,0,0,5.917,9.772a.5.5,0,0,1,.333.471V23a1,1,0,0,0,2,0V10.243a.5.5,0,0,1,.333-.471A4.009,4.009,0,0,0,11.25,6V1A1,1,0,0,0,10.25,0Z"
				fill={color}
			/>
			<path
				d="M16.75,0a4,4,0,0,0-4,4V6a4.009,4.009,0,0,0,2.667,3.772.5.5,0,0,1,.333.471V23a1,1,0,0,0,2,0V10.243a.5.5,0,0,1,.333-.471A4.009,4.009,0,0,0,20.75,6V4A4,4,0,0,0,16.75,0Zm-.5,2.5a1,1,0,0,0-1,1,.5.5,0,0,1-1,0,2,2,0,0,1,2-2,.5.5,0,0,1,0,1Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default FoodIconBuilder;
