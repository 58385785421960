import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import Copy from '~/components/logic/Copy';
import FormsList from '~/components/atoms/lists/FormsList';
import IntercomActivator from '~/components/logic/IntercomActivator';
import InvoicesOverview from '~/components/app/InvoicesOverview';

import useAccountProblems from '~/hooks/useAccountProblems';



const messages = defineMessages({
	stoppedWebsitesDescription: {
		id: 'ui.teamDetail.billing.stoppedWebsites.description',
	},
	stoppedWebsitesTitle: {
		id: 'ui.teamDetail.billing.stoppedWebsites.title',
	},
	unpaidWebsitesDescription: {
		id: 'ui.teamDetail.billing.unpaidWebsites.description',
	},
	unpaidWebsitesTitle: {
		id: 'ui.teamDetail.billing.unpaidWebsites.title',
	},
});



type Props = {
	accountId: CK.AccountId | null,
};

const InvoicesGroup: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountProblems = useAccountProblems(accountId);

	let warningPaymentMessage;

	if (accountProblems.isSubscriptionSuspended) {
		warningPaymentMessage = (
			<CalloutMessage
				borders={true}
				message={(
					<FormattedMessage {...messages.stoppedWebsitesTitle} />
				)}
				status={CalloutMessageStatus.Critical}
			>
				<Copy
					{...messages.stoppedWebsitesDescription}
					values={{
						link_contact: (chunks) => (
							<IntercomActivator>
								<a href="#">
									{chunks}
								</a>
							</IntercomActivator>
						),
					}}
				/>
			</CalloutMessage>
		);
	} else if (accountProblems.hasPaymentProblem) {
		warningPaymentMessage = (
			<CalloutMessage
				borders={true}
				message={(
					<FormattedMessage {...messages.unpaidWebsitesTitle} />
				)}
				status={CalloutMessageStatus.Warning}
			>
				<Copy
					{...messages.unpaidWebsitesDescription}
					values={{
						link_contact: (chunks) => (
							<IntercomActivator>
								<a href="#">
									{chunks}
								</a>
							</IntercomActivator>
						),
					}}
				/>
			</CalloutMessage>
		);
	}

	return (
		<FormsList>
			{warningPaymentMessage}

			<InvoicesOverview
				accountId={accountId}
			/>
		</FormsList>
	);
};



export default InvoicesGroup;
