import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import {
	selectedTeamSelector,
} from '~/state/ui/selectors';



function useAccountId(): CK.AccountId | null {
	const accountId = useSelector(selectedTeamSelector);

	if (accountId === null) {
		return null;
	}

	return parseInt(accountId);
}



export default useAccountId;
