import Exception from './Exception';

import {
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	recurly_card_declined: {
		id: 'ui.recurlyErrors.cardDeclined',
	},
	recurly_card_type_not_accepted: {
		id: 'ui.recurlyErrors.cardTypeNotAccepted',
	},
	recurly_connection_failed: {
		id: 'ui.recurlyErrors.connectionFailed',
	},
	recurly_insufficient_funds: {
		id: 'ui.recurlyErrors.insufficientFunds',
	},
	recurly_invalid_billing_details_token: {
		id: 'ui.recurlyErrors.invalidBillingDetails',
	},
	recurly_invalid_request: {
		id: 'ui.recurlyErrors.invalidRequest',
	},
	recurly_server_down: {
		id: 'ui.recurlyErrors.serverDown',
	},
	recurly_transaction_failed: {
		id: 'ui.recurlyErrors.transactionFailed',
	},
	unknown_problem: {
		id: 'ui.recurlyErrors.unknownProblem',
	},
});



export default class ErrorHandler {

	isRecurlyError(errorCode) {
		return messages[errorCode] !== undefined;
	}



	createException(errorCode) {
		return new Exception(errorCode, messages[errorCode]);
	}

}
