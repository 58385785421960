import React from 'react';

import List, {
	ListSize,
} from '~/components/patterns/lists/List';



type Props = {
	children: React.ReactNode,
};



const LabeledValuesList: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<List size={ListSize.Large}>
			{children}
		</List>
	);
};



export default LabeledValuesList;
