import {
	List,
	type Map,
} from 'immutable';

import {
	ELEMENT_TYPE_BOOLEAN,
	ELEMENT_TYPE_DATE,
	ELEMENT_TYPE_INTEGER,
	ELEMENT_TYPE_NUMBER,
	ELEMENT_TYPE_STRING,
} from './customElements';

import {
	get,
} from './api/API';
import {
	isBefore,
} from 'date-fns';
import {
	assertString,
} from '~/utilities/typeCheck';



export const ALERT_GROUP_CUSTOM_ELEMENT_ALERTS = 'custom_element_alerts';
export const ALERT_GROUP_ENRICHMENT_FIELD_ALERTS = 'enrichment_field_alerts';
export const ALERT_GROUP_ENTERED_OR_LEFT_ALERTS = 'entered_or_left_alerts';
export const ALERT_GROUP_LIGHTHOUSE_ALERTS = 'lighthouse_alerts';
export const ALERT_GROUP_PAGE_ALERTS = 'page_alerts';
export const ALERT_GROUP_PLATFORM_ALERTS = 'platform_alerts';

export const ALERT_CANONICAL_LINK_CHANGED = 'canonical_link_changed';
export const ALERT_CONTENT_CHANGED_H1 = 'content_change.h1';
export const ALERT_CONTENT_CHANGED_META_DESCRIPTION = 'content_change.meta_description';
export const ALERT_CONTENT_CHANGED_TITLE = 'content_change.title';
export const ALERT_ENRICHMENT_FIELD_CHANGED = 'enrichment_field_changed';
export const ALERT_HREFLANGS_CHANGED = 'hreflangs_changed';
export const ALERT_PAGE_BECAME_NON_INDEXABLE = 'pages_became_non_indexable';
export const ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT = 'pages_entered_or_left_segment';
export const ALERT_PAGE_ISSUE_OPENED_ANALYTICS_ANALYTICS_MISSING = 'issue_opened.analytics/analytics_missing';
export const ALERT_PAGE_ISSUE_OPENED_ANALYTICS_VISUAL_ANALYTICS_MISSING = 'issue_opened.analytics/visual_analytics_missing';
export const ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_INCORRECTLY_CANONICALIZED = 'issue_opened.canonical_link/incorrectly_canonicalized';
export const ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_POINTS_TO_UNINDEXABLE = 'issue_opened.canonical_link/points_to_unindexable';
export const ALERT_PAGE_ISSUE_OPENED_H1_MISSING = 'issue_opened.h1/missing';
export const ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS = 'issue_opened.lighthouse/cls';
export const ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_FCP = 'issue_opened.lighthouse/fcp';
export const ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_LCP = 'issue_opened.lighthouse/lcp';
export const ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_PERFORMANCE = 'issue_opened.lighthouse/performance';
export const ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_SI = 'issue_opened.lighthouse/si';
export const ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TBT = 'issue_opened.lighthouse/tbt';
export const ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TTI = 'issue_opened.lighthouse/tti';
export const ALERT_PAGE_ISSUE_OPENED_LINKS_BROKEN = 'issue_opened.links/broken';
export const ALERT_PAGE_ISSUE_OPENED_META_DESCRIPTION_MISSING = 'issue_opened.meta_description/missing';
export const ALERT_PAGE_ISSUE_OPENED_TITLE_MISSING = 'issue_opened.title/missing';
export const ALERT_PAGE_TYPE_CHANGED = 'page_type_change';
export const ALERT_PLATFORM_ISSUE_OPENED_CORE_WEB_VITALS_ASSESSMENT_FAILED = 'platform_issue_opened.web_vitals_origin_summary/core_web_vitals';
export const ALERT_PLATFORM_ISSUE_OPENED_HOSTNAME_NONCANONICAL_PRESENT = 'platform_issue_opened.domain/hostname_noncanonical_present';
export const ALERT_PLATFORM_ISSUE_OPENED_HTTPS_CERTIFICATE_INVALID = 'platform_issue_opened.domain/https_certificate_invalid';
export const ALERT_PLATFORM_ISSUE_OPENED_HTTPS_NONCANONICAL_PRESENT = 'platform_issue_opened.domain/https_noncanonical_present';
export const ALERT_PLATFORM_ISSUE_ROBOTS_TXT_BECAME_INACCESSIBLE = 'robots_txt_became_inaccessible';
export const ALERT_PROPERTY_CHANGED = 'property_changed';
export const ALERT_ROBOTS_TXT_CHANGED = 'robots_txt_changed';
export const ALERT_SSL_CERTIFICATE_EXPIRING_SOON = 'ssl_certificate_expiring_soon';
export const ALERT_WEBSITE_HAS_TROUBLE_RESPONDING = 'website_has_trouble_responding';

export const LIST_OF_ALL_ALERTS = List([
	// Group 1 - Page-level types
	// Group 1.1
	ALERT_PAGE_ISSUE_OPENED_LINKS_BROKEN,
	ALERT_PAGE_TYPE_CHANGED,
	ALERT_PAGE_BECAME_NON_INDEXABLE,
	// Group 1.2
	ALERT_CANONICAL_LINK_CHANGED,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_INCORRECTLY_CANONICALIZED,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_POINTS_TO_UNINDEXABLE,
	// Group 1.3
	ALERT_PAGE_ISSUE_OPENED_TITLE_MISSING,
	ALERT_CONTENT_CHANGED_TITLE,
	ALERT_PAGE_ISSUE_OPENED_META_DESCRIPTION_MISSING,
	ALERT_CONTENT_CHANGED_META_DESCRIPTION,
	ALERT_PAGE_ISSUE_OPENED_H1_MISSING,
	ALERT_CONTENT_CHANGED_H1,
	// Group 1.4
	ALERT_HREFLANGS_CHANGED,
	// Group 1.5
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_ANALYTICS_MISSING,
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_VISUAL_ANALYTICS_MISSING,
	// Group 2 - Domain-level types
	// Group 2.1
	ALERT_PLATFORM_ISSUE_ROBOTS_TXT_BECAME_INACCESSIBLE,
	ALERT_ROBOTS_TXT_CHANGED,
	ALERT_WEBSITE_HAS_TROUBLE_RESPONDING,
	// Group 2.2
	ALERT_SSL_CERTIFICATE_EXPIRING_SOON,
	// Group 2.3
	ALERT_PLATFORM_ISSUE_OPENED_CORE_WEB_VITALS_ASSESSMENT_FAILED,
	ALERT_PLATFORM_ISSUE_OPENED_HOSTNAME_NONCANONICAL_PRESENT,
	ALERT_PLATFORM_ISSUE_OPENED_HTTPS_CERTIFICATE_INVALID,
	ALERT_PLATFORM_ISSUE_OPENED_HTTPS_NONCANONICAL_PRESENT,
	// Group 3 - Pages entering/leaving segment
	// Group 3.1
	ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
	// Group 4 - Page property changed
	// Group 4.1
	ALERT_PROPERTY_CHANGED,
	ALERT_ENRICHMENT_FIELD_CHANGED,
	// Group 5 - Lighthouse
	// Group 5.1
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_PERFORMANCE,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_FCP,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TTI,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_SI,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TBT,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_LCP,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS,
]);

export const LIST_OF_LIGHTHOUSE_ALERT_TYPES = List([
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_PERFORMANCE,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_FCP,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TTI,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_SI,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TBT,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_LCP,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS,
]);

export const LIST_OF_PAGE_ALERT_TYPES = List([
	ALERT_CANONICAL_LINK_CHANGED,
	ALERT_CONTENT_CHANGED_H1,
	ALERT_CONTENT_CHANGED_META_DESCRIPTION,
	ALERT_CONTENT_CHANGED_TITLE,
	ALERT_HREFLANGS_CHANGED,
	ALERT_PAGE_BECAME_NON_INDEXABLE,
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_ANALYTICS_MISSING,
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_VISUAL_ANALYTICS_MISSING,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_INCORRECTLY_CANONICALIZED,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_POINTS_TO_UNINDEXABLE,
	ALERT_PAGE_ISSUE_OPENED_H1_MISSING,
	ALERT_PAGE_ISSUE_OPENED_LINKS_BROKEN,
	ALERT_PAGE_ISSUE_OPENED_META_DESCRIPTION_MISSING,
	ALERT_PAGE_ISSUE_OPENED_TITLE_MISSING,
	ALERT_PAGE_TYPE_CHANGED,
]);

export const LIST_OF_PLATFORM_ALERT_TYPES = List([
	ALERT_PLATFORM_ISSUE_OPENED_CORE_WEB_VITALS_ASSESSMENT_FAILED,
	ALERT_PLATFORM_ISSUE_OPENED_HTTPS_CERTIFICATE_INVALID,
	ALERT_PLATFORM_ISSUE_OPENED_HTTPS_NONCANONICAL_PRESENT,
	ALERT_PLATFORM_ISSUE_OPENED_HOSTNAME_NONCANONICAL_PRESENT,
	ALERT_PLATFORM_ISSUE_ROBOTS_TXT_BECAME_INACCESSIBLE,
	ALERT_ROBOTS_TXT_CHANGED,
	ALERT_SSL_CERTIFICATE_EXPIRING_SOON,
	ALERT_WEBSITE_HAS_TROUBLE_RESPONDING,
]);

export const LIST_OF_NATIVE_ALERT_TYPES = List([
	ALERT_CANONICAL_LINK_CHANGED,
	ALERT_CONTENT_CHANGED_H1,
	ALERT_CONTENT_CHANGED_META_DESCRIPTION,
	ALERT_CONTENT_CHANGED_TITLE,
	ALERT_HREFLANGS_CHANGED,
	ALERT_PAGE_BECAME_NON_INDEXABLE,
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_ANALYTICS_MISSING,
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_VISUAL_ANALYTICS_MISSING,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_INCORRECTLY_CANONICALIZED,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_POINTS_TO_UNINDEXABLE,
	ALERT_PAGE_ISSUE_OPENED_H1_MISSING,
	ALERT_PAGE_ISSUE_OPENED_LINKS_BROKEN,
	ALERT_PAGE_ISSUE_OPENED_META_DESCRIPTION_MISSING,
	ALERT_PAGE_ISSUE_OPENED_TITLE_MISSING,
	ALERT_PAGE_TYPE_CHANGED,
	ALERT_PLATFORM_ISSUE_OPENED_CORE_WEB_VITALS_ASSESSMENT_FAILED,
	ALERT_PLATFORM_ISSUE_OPENED_HTTPS_CERTIFICATE_INVALID,
	ALERT_PLATFORM_ISSUE_OPENED_HTTPS_NONCANONICAL_PRESENT,
	ALERT_PLATFORM_ISSUE_OPENED_HOSTNAME_NONCANONICAL_PRESENT,
	ALERT_PLATFORM_ISSUE_ROBOTS_TXT_BECAME_INACCESSIBLE,
	ALERT_ROBOTS_TXT_CHANGED,
	ALERT_SSL_CERTIFICATE_EXPIRING_SOON,
]);


export const CUSTOM_ELEMENT_CHANGE_TYPE_ADDED = 'added';
export const CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED = 'changed';
export const CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED_TO_FALSE = 'changedToFalse';
export const CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED_TO_TRUE = 'changedToTrue';
export const CUSTOM_ELEMENT_CHANGE_TYPE_DECREASED = 'decreased';
export const CUSTOM_ELEMENT_CHANGE_TYPE_INCREASED = 'increased';
export const CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_BACKWARD = 'movedBackward';
export const CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_FORWARD = 'movedForward';
export const CUSTOM_ELEMENT_CHANGE_TYPE_REMOVED = 'removed';

export const CUSTOM_ELEMENT_CHANGE_TYPES_FOR_DATA_TYPE = {
	[ELEMENT_TYPE_BOOLEAN]: [
		CUSTOM_ELEMENT_CHANGE_TYPE_ADDED,
		CUSTOM_ELEMENT_CHANGE_TYPE_REMOVED,
		CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED_TO_TRUE,
		CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED_TO_FALSE,
	],
	[ELEMENT_TYPE_DATE]: [
		CUSTOM_ELEMENT_CHANGE_TYPE_ADDED,
		CUSTOM_ELEMENT_CHANGE_TYPE_REMOVED,
		CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_BACKWARD,
		CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_FORWARD,
	],
	[ELEMENT_TYPE_INTEGER]: [
		CUSTOM_ELEMENT_CHANGE_TYPE_ADDED,
		CUSTOM_ELEMENT_CHANGE_TYPE_REMOVED,
		CUSTOM_ELEMENT_CHANGE_TYPE_INCREASED,
		CUSTOM_ELEMENT_CHANGE_TYPE_DECREASED,
	],
	[ELEMENT_TYPE_NUMBER]: [
		CUSTOM_ELEMENT_CHANGE_TYPE_ADDED,
		CUSTOM_ELEMENT_CHANGE_TYPE_REMOVED,
		CUSTOM_ELEMENT_CHANGE_TYPE_INCREASED,
		CUSTOM_ELEMENT_CHANGE_TYPE_DECREASED,
	],
	[ELEMENT_TYPE_STRING]: [
		CUSTOM_ELEMENT_CHANGE_TYPE_ADDED,
		CUSTOM_ELEMENT_CHANGE_TYPE_REMOVED,
		CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED,
	],
};

export const SEGMENT_CHANGE_TYPE_PAGES_ENTERED = 'entered';
export const SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT = 'entered_or_left';
export const SEGMENT_CHANGE_TYPE_PAGES_LEFT = 'left';

export const SENSITIVITY_LEVEL_ALWAYS = 'always';
export const SENSITIVITY_LEVEL_HIGH = 'high';
export const SENSITIVITY_LEVEL_LOW = 'low';
export const SENSITIVITY_LEVEL_MEDIUM = 'medium';
export const SENSITIVITY_LEVEL_NEVER = 'never';

export const SENSITIVITY_LEVELS = List([
	SENSITIVITY_LEVEL_NEVER,
	SENSITIVITY_LEVEL_LOW,
	SENSITIVITY_LEVEL_MEDIUM,
	SENSITIVITY_LEVEL_HIGH,
	SENSITIVITY_LEVEL_ALWAYS,
]);

export const SENSITIVITY_LEVEL_NUMBERS = [...Array(SENSITIVITY_LEVELS.size).keys()].map((number) => number + 1);



export function countPagesSplit(incident: List<unknown>, descriptor: unknown) {
	return (
		incident.getIn(['state', 'pages', 'split', 'within_scope', descriptor], 0)
		+ incident.getIn(['state', 'pages', 'split', 'outside_scope', descriptor], 0)
	);
}



export function doesAlertTypeExist(alertType: string) {
	return LIST_OF_ALL_ALERTS.includes(alertType);
}



export function getAlertGroup(alertType: string) {
	if (alertType === ALERT_PROPERTY_CHANGED) {
		return ALERT_GROUP_CUSTOM_ELEMENT_ALERTS;
	}

	if (alertType === ALERT_ENRICHMENT_FIELD_CHANGED) {
		return ALERT_GROUP_ENRICHMENT_FIELD_ALERTS;
	}

	if (alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT) {
		return ALERT_GROUP_ENTERED_OR_LEFT_ALERTS;
	}

	if (LIST_OF_PAGE_ALERT_TYPES.includes(alertType)) {
		return ALERT_GROUP_PAGE_ALERTS;
	}

	if (LIST_OF_PLATFORM_ALERT_TYPES.includes(alertType)) {
		return ALERT_GROUP_PLATFORM_ALERTS;
	}

	if (LIST_OF_LIGHTHOUSE_ALERT_TYPES.includes(alertType)) {
		return ALERT_GROUP_LIGHTHOUSE_ALERTS;
	}

	return null;
}



export function getSensitivityLevel(levelNumber: number) {
	if (levelNumber < 1 || levelNumber > SENSITIVITY_LEVELS.size) {
		return undefined;
	}

	return SENSITIVITY_LEVELS.get(levelNumber - 1, undefined);
}



export function getNumberOfSensitivityLevel(level: string) {
	return SENSITIVITY_LEVELS.indexOf(level);
}



export function listIncidents(allIncidents: List<Map<unknown, unknown>>, phase: string) {
	return allIncidents
		.filter((incident) => incident?.get('phase') === phase)
		.sort((incidentA, incidentB) => {
			if (incidentA.get('last_evolved_at') === incidentB.get('last_evolved_at')) {
				return incidentA.get('id')! < incidentB.get('id')! ? 1 : -1;
			}

			const lastEvolvedAtA = incidentA.get('last_evolved_at');
			const lastEvolvedAtB = incidentB.get('last_evolved_at');

			assertString(lastEvolvedAtA);
			assertString(lastEvolvedAtB);

			return isBefore(lastEvolvedAtA, lastEvolvedAtB) ? 1 : -1;
		})
		.toArray();
}



export function loadIncidentsViaApi(websiteId: string) {
	return get('/websites/' + websiteId + '/incidents', {}, {
		timeout: 30000,
	});
}



export function getSortByType(type: string) {
	return LIST_OF_ALL_ALERTS.indexOf(type);
}
