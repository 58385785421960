import type CK from '~/types/contentking';

import {
	useWebsiteIsLighthouseMonitoredQuery,
} from './useWebsiteIsLighthouseMonitored.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteIsLighthouseMonitored(websiteId: CK.WebsiteId | null): boolean | null {
	const { data } = useWebsiteIsLighthouseMonitoredQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.isLighthouseMonitored) ?? null;
}



export default useWebsiteIsLighthouseMonitored;
