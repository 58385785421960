import React from 'react';

import CreditCardFields, {
	validateCardCvc,
	validateCardExpiration,
	validateCardHolder,
	validateCardNumber,
} from '~/components/app/CreditCardFields';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import PaymentMethodField, {
	validatePaymentMethodField,
} from '~/components/app/PaymentMethodField';
import SepaDirectDebitFields, {
	validateSepaIban,
	validateSepaName,
} from '~/components/app/SepaDirectDebitFields';

import {
	validateWhenField,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';

import {
	METHOD_CARD,
	METHOD_SEPA_DEBIT,
} from '~/model/payments';



type Props = {
	paymentMethods: Readonly<Array<string>>,
};

const ChoosePaymentMethodFields: React.FC<Props> = (props) => {
	const {
		paymentMethods,
	} = props;

	const paymentMethod = useFormContext().values.paymentMethod;

	return (
		<FormRows>
			<FormRow indentationSize={2}>
				<PaymentMethodField
					name="paymentMethod"
					paymentMethods={paymentMethods}
				/>
			</FormRow>

			{paymentMethod === METHOD_CARD && (
				<CreditCardFields />
			)}

			{paymentMethod === METHOD_SEPA_DEBIT && (
				<SepaDirectDebitFields />
			)}
		</FormRows>
	);
};



export default ChoosePaymentMethodFields;

export const validateCardFields = validateWhenField(
	'paymentMethod',
	({ value }) => value === METHOD_CARD,
	{
		validateCardCvc,
		validateCardExpiration,
		validateCardHolder,
		validateCardNumber,
	},
);

export const validatePaymentMethod = validatePaymentMethodField('paymentMethod');

export const validateSepaFields = validateWhenField(
	'paymentMethod',
	({ value }) => value === METHOD_SEPA_DEBIT,
	{
		validateSepaIban,
		validateSepaName,
	},
);
