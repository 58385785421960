import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';



const messages = defineMessages({
	no: {
		id: 'ui.contentOverview.indexable.no',
	},
	yes: {
		id: 'ui.contentOverview.indexable.yes',
	},
});



type Props = {
	value: 'yes' | 'no'| 'not_applicable' | undefined,
};

const IsIndexableDueToFormatter: React.FC<Props> = (props) => {
	const {
		value,
	} = props;

	if (value === undefined) {
		return null;
	} else if (value === 'yes') {
		return (
			<FormattedMessage {...messages.yes} />
		);
	} else if (value === 'no') {
		return (
			<FormattedMessage {...messages.no} />
		);
	}

	return (
		<NotApplicableValue />
	);
};



export default React.memo(IsIndexableDueToFormatter);
