/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateIndexNowIssueConditionsMutationVariables = GraphQL.Exact<{
  issues: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateIndexNowIssueConditionsMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteIndexNowIssueConditions: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly indexNowIssuesRequiredToPass: ReadonlyArray<string> } | null } } };


export const UpdateIndexNowIssueConditionsDocument = gql`
    mutation UpdateIndexNowIssueConditions($issues: [String!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteIndexNowIssueConditions(
    selectedIssues: $issues
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        indexNowIssuesRequiredToPass
      }
    }
  }
}
    `;
export type UpdateIndexNowIssueConditionsMutationFn = Apollo.MutationFunction<UpdateIndexNowIssueConditionsMutation, UpdateIndexNowIssueConditionsMutationVariables>;

/**
 * __useUpdateIndexNowIssueConditionsMutation__
 *
 * To run a mutation, you first call `useUpdateIndexNowIssueConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndexNowIssueConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndexNowIssueConditionsMutation, { data, loading, error }] = useUpdateIndexNowIssueConditionsMutation({
 *   variables: {
 *      issues: // value for 'issues'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateIndexNowIssueConditionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndexNowIssueConditionsMutation, UpdateIndexNowIssueConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIndexNowIssueConditionsMutation, UpdateIndexNowIssueConditionsMutationVariables>(UpdateIndexNowIssueConditionsDocument, options);
      }
export type UpdateIndexNowIssueConditionsMutationHookResult = ReturnType<typeof useUpdateIndexNowIssueConditionsMutation>;
export type UpdateIndexNowIssueConditionsMutationResult = Apollo.MutationResult<UpdateIndexNowIssueConditionsMutation>;
export type UpdateIndexNowIssueConditionsMutationOptions = Apollo.BaseMutationOptions<UpdateIndexNowIssueConditionsMutation, UpdateIndexNowIssueConditionsMutationVariables>;