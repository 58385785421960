import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Emphasis from '~/components/patterns/typography/Emphasis';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
	ButtonsLayoutUsageContext,
} from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import TesterOutput from '~/components/logic/customElements/tester/TesterOutput';
import TesterOutputError from '~/components/logic/customElements/tester/TesterOutputError';
import TextField from '~/components/atoms/forms/components/TextField';
import SubmitButton, {
	SIZE_SMALL as BUTTON_SIZE_SMALL,
	STYLE_HOLLOW as BUTTON_STYLE_HOLLOW,
} from '~/components/atoms/forms/components/SubmitButton';

import useWebsiteDomain from '~/hooks/useWebsiteDomain';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	runAction,
} from '~/model/actions';

import {
	type Extraction,
} from '~/model/customElements';



const messages = defineMessages({
	formErrorsBlank: {
		id: 'ui.formErrors.blank',
	},
	formFieldsUrlDescription: {
		id: 'ui.customElements.tester.fields.url.description',
	},
	formFieldsUrlLabel: {
		id: 'ui.customElements.tester.fields.url.label',
	},
	formFieldsUrlPlaceholder: {
		id: 'ui.customElements.tester.fields.url.placeholder',
	},
	submitButton: {
		id: 'ui.customElements.tester.submitButton.label',
	},
});

const validations = {
	url: [
		{
			message: (
				<FormattedMessage {...messages.formErrorsBlank} />
			),
			field: 'url',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
};



type Props = {
	extraction: Extraction,
	isValid: boolean,
};

const CustomElementTesterForm: React.FC<Props> = (props) => {
	const {
		extraction,
		isValid: isExtractionValid,
	} = props;

	const intl = useIntl();
	const websiteId = useWebsiteId();

	const websiteDomain = useWebsiteDomain(websiteId);

	const [result, setResult] = React.useState<any>(null);

	const handleExtractionInvalidSubmit = React.useCallback(
		(e) => {
			e.preventDefault();
		},
		[],
	);

	const handleFormSubmit = React.useCallback(
		(data) => {
			if (isExtractionValid === false) {
				return;
			}

			return runAction({
				action: 'TestCustomElementExtraction',
				input: {
					extraction,
					url: data.url,
					websiteId,
				},
			}).then((result) => {
				setResult({
					elementType: result.dataType,
					url: result.url,
					value: result.extractedValue,
				});
			}).catch((error) => {
				if (error.getCode) {
					if (error.getCode() === 'invalidExtraction') {
						return;
					}

					setResult({
						error,
					});
				}
			});
		},
		[
			extraction,
			isExtractionValid,
			websiteId,
		],
	);

	return (
		<List size={ListSize.XLarge}>
			<Form
				onSuccess={handleFormSubmit}
				validations={validations}
			>
				{({ isSubmitting, isValid }) => (
					<CenteredFormWrapper>
						<FormRow
							description={(
								<FormattedMessage
									{...messages.formFieldsUrlDescription}
									values={{
										'text__domain': websiteDomain ? (
											<Emphasis>{websiteDomain}</Emphasis>
										) : '',
									}}
								/>
							)}
							htmlFor="url"
							inline={false}
							label={(
								<FormattedMessage {...messages.formFieldsUrlLabel} />
							)}
						>
							<FieldStatus
								allowOk={false}
								name="url"
							>
								<TextField
									name="url"
									placeholder={intl.formatMessage(messages.formFieldsUrlPlaceholder)}
									width="100%"
								/>
							</FieldStatus>
						</FormRow>
						<ButtonsLayout
							alignment={ButtonsLayoutAlignment.Center}
							layout={ButtonsLayoutType.FormRow}
							usageContext={ButtonsLayoutUsageContext.Raw}
						>
							<SubmitButton
								inactive={!isExtractionValid || !isValid}
								onClickCallback={!isExtractionValid ? handleExtractionInvalidSubmit : null}
								progress={isSubmitting}
								size={BUTTON_SIZE_SMALL}
								style={BUTTON_STYLE_HOLLOW}
								uppercase={true}
							>
								<FormattedMessage {...messages.submitButton} />
							</SubmitButton>
						</ButtonsLayout>
					</CenteredFormWrapper>
				)}
			</Form>

			{result !== null && result.error !== undefined && (
				<TesterOutputError
					error={result.error}
				/>
			)}

			{result !== null && result.value !== undefined && (
				<TesterOutput
					elementType={result.elementType}
					url={result.url}
					value={result.value}
				/>
			)}
		</List>
	);
};



export default CustomElementTesterForm;
