import {
	storePageRelations,
	storeUniqueElementContentIssueUrls,
} from '~/actions';

import {
	SINGLE_PAGE_RELATION_TYPES,
	STATE_NOT_LOADED,
	createFullId,
	loadPageRelationsViaApi,
	loadUniqueElementContentIssueUrlsViaApi,
} from '~/model/pages';

import {
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';



export function loadPageRelations(websiteId, legacyUrlId, type, offset, limit) {
	return (dispatch, getState) => {
		if (SINGLE_PAGE_RELATION_TYPES.indexOf(type) < 0) {
			throw 'Undefined page relations type: "' + type + '"';
		}

		const currentState = getState().get('pages').get(type).get('id');
		const isNotLoaded = !currentState || currentState.get('_status') === STATE_NOT_LOADED;

		if (!isNotLoaded) {
			return Promise.resolve();
		}

		return loadPageRelationsViaApi(
			websiteId,
			legacyUrlId,
			type,
			offset,
			limit,
		).then((data) => {
			dispatch(
				storePageRelations(
					createFullId(websiteId, legacyUrlId),
					type,
					data,
					offset || 0,
				),
			);
		});
	};
}



export function loadUniqueElementContentIssueUrls(pageId, issueType, offset = 0, limit) {
	return (dispatch, getState) => {
		const parameters = {
			offset,
		};
		if (limit !== undefined) {
			parameters.limit = limit;
		}

		const websiteId = selectedWebsiteIdSelector(getState());

		return loadUniqueElementContentIssueUrlsViaApi(
			websiteId,
			pageId,
			issueType,
			parameters,
		).then((urls) => {
			dispatch(storeUniqueElementContentIssueUrls(websiteId, pageId, issueType, urls, offset));
		});
	};
}
