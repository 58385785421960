import type CK from '~/types/contentking';

import {
	usePageScrapbookLinkForRequestLogsQuery,
} from './usePageScrapbookLinkForRequestLogs.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageScrapbookLinkForRequestLogs(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageScrapbookLinkForRequestLogsQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.scrapbookLinkForRequestLogs ?? null;
}



export default usePageScrapbookLinkForRequestLogs;
