import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AffectedPagesFilterBar from './activeTableFilterBar/AffectedPagesFilterBar';
import Backlink from '~/components/logic/links/Backlink';
import ComparisonEntry from '~/components/logic/issuesOverview/components/ComparisonEntry';
import ExportHeaderButton from '~/components/app/ExportHeaderButton';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderFilterLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderFilterLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleWithNumericValue, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';

import useViewportType from '~/hooks/useViewportType';

import {
	assertDictionaryId,
} from '~/localization/dictionaries';

import {
	ExportType,
} from '~/model/exports';

import {
	type IssueCategoryName,
	type PageIssue,
} from '~/model/issuesNew';

import {
	horizontalScrollTo,
} from '~/utilities/scrollTo';



const messages = defineMessages({
	exportButton: {
		id: 'ui.exports.type.general.button.openDialog.label',
	},
	mobileTitle: {
		id: 'ui.affectedPages.general.titles.mobilePagesWithIssues',
	},
});



type Props = {
	backlinkCallback: (() => void) | null,
	backlinkLabel: React.ReactNode,
	issueCategoryName: IssueCategoryName,
	issueName?: PageIssue,
	numberOfAffectedPages: number | null,
};

const Header: React.FC<Props> = (props) => {
	const {
		backlinkCallback,
		backlinkLabel,
		issueCategoryName,
		issueName = null,
		numberOfAffectedPages,
	} = props;

	const viewportType = useViewportType();

	let title;

	if (viewportType.isSmall) {
		title = (
			<FormattedMessage
				{...messages.mobileTitle}
				values={{
					pagesCount: numberOfAffectedPages || 0,
				}}
			/>
		);
	} else if (issueName !== null) {
		title = (
			<FormattedMessage id={`issues.${issueName}.affectedPages`} />
		);
	} else {
		const dictionaryId = `issues.categories.${issueCategoryName}.affectedPagesTitle`;

		assertDictionaryId(dictionaryId);

		title = (
			<FormattedMessage id={dictionaryId} />
		);
	}

	return (
		<ScreenHeader>
			<HeaderTitleLayout>
				{backlinkCallback && backlinkLabel && (
					<Backlink
						callback={backlinkCallback}
						label={backlinkLabel}
					/>
				)}

				<HeaderTitleWithNumericValue
					size={TITLE_SIZE_SMALL}
					title={title}
					value={numberOfAffectedPages}
				/>
			</HeaderTitleLayout>

			<HeaderFilterLayout compactView={viewportType.isSmall || viewportType.isMedium}>
				<AffectedPagesFilterBar
					onFilterClickCallback={(filterName) => {
						horizontalScrollTo(
							document.querySelector('.rv-table__content'),
							(document.querySelector('.table-header-cell--cell-' + filterName) as any).offsetLeft,
						);
					}}
				/>
			</HeaderFilterLayout>

			<HeaderOptionsLayout>
				<HeaderButtonsLayout>
					<ComparisonEntry />

					<ExportHeaderButton
						exportType={issueName !== null ? ExportType.SingleIssueAffectedPages : ExportType.AffectedPages}
						linkLabel={(
							<FormattedMessage {...messages.exportButton} />
						)}
					/>
				</HeaderButtonsLayout>
			</HeaderOptionsLayout>
		</ScreenHeader>
	);
};



export default React.memo(Header);
