import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import LabeledValue, {
	LabeledValueContentAlignment,
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import MarginsList from '~/components/atoms/lists/MarginsList';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import RichText from '~/components/patterns/typography/RichText';
import SimpleBox, {
	SimpleBoxBackground,
} from '~/components/patterns/boxes/SimpleBox';
import SubmitButton, {
	STYLE_ALERT,
} from '~/components/atoms/forms/components/SubmitButton';

import {
	useAcceptAccessToClientMutation,
} from './AcceptAccessToClientModal.gql';

import useAccountClientConnections from '~/hooks/useAccountClientConnections';
import useAccountId from '~/hooks/useAccountId';
import useRemoveAccessToClient from '~/hooks/useRemoveAccessToClient';
import useUrlState from '~/hooks/useUrlState';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	acceptButton: {
		id: 'ui.connectedAccounts.modal.acceptAccessToClient.acceptButton',
	},
	description: {
		id: 'ui.connectedAccounts.modal.acceptAccessToClient.description',
	},
	identificationAccount: {
		id: 'ui.connectedAccounts.modal.acceptAccessToClient.identification.account',
	},
	identificationEmail: {
		id: 'ui.connectedAccounts.modal.acceptAccessToClient.identification.email',
	},
	rejectButton: {
		id: 'ui.connectedAccounts.modal.acceptAccessToClient.rejectButton',
	},
	title: {
		id: 'ui.connectedAccounts.modal.acceptAccessToClient.title',
	},
});



const AcceptAccessToClientModal = (props) => {
	const {
		closeCallback,
	} = props;

	const agencyAccountId = useAccountId();

	const accountClientConnections = useAccountClientConnections(agencyAccountId);
	const removeAccessToClient = useRemoveAccessToClient();
	const urlState = useUrlState();

	const clientId = urlState.params.clientId;

	const [isRejecting, setIsRejecting] = React.useState(false);

	const [acceptAccessToClient] = useAcceptAccessToClientMutation();

	const handleRejectRequest = React.useCallback(
		async () => {
			if (agencyAccountId === null) {
				return;
			}

			setIsRejecting(true);

			await removeAccessToClient({
				agencyId: agencyAccountId,
				clientId,
			});

			setIsRejecting(false);

			closeCallback();
		},
		[
			agencyAccountId,
			clientId,
			closeCallback,
			removeAccessToClient,
		],
	);

	const handleSubmit = React.useCallback(
		async () => {
			if (agencyAccountId === null) {
				return;
			}

			try {
				await acceptAccessToClient({
					variables: {
						agencyId: agencyAccountId,
						clientId,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}

			closeCallback();
		},
		[
			acceptAccessToClient,
			agencyAccountId,
			clientId,
			closeCallback,
		],
	);

	const connectingClient = accountClientConnections.getByAccountId(clientId);

	return (
		<ModalContainer
			header={(
				<ModalHeader
					title={(
						<FormattedMessage {...messages.title} />
					)}
				/>
			)}
			isLoading={connectingClient === null}
		>
			<ModalTextSection>
				<RichText>
					<Copy
						{...messages.description}
						values={{ clientAccountName: connectingClient?.clientAccountName }}
					/>
				</RichText>
			</ModalTextSection>

			<ModalTextSection>
				<MarginsList>
					<SimpleBox
						background={SimpleBoxBackground.Dark}
						paddingSize={2}
					>
						<LabeledValuesList>
							<LabeledValue
								contentAlignment={LabeledValueContentAlignment.Left}
								flow={LabeledValueFlow.ColumnOnSmall}
								label={(
									<FormattedMessage {...messages.identificationAccount} />
								)}
							>
								{connectingClient?.clientAccountName}
							</LabeledValue>
							<LabeledValue
								contentAlignment={LabeledValueContentAlignment.Left}
								flow={LabeledValueFlow.ColumnOnSmall}
								label={(
									<FormattedMessage {...messages.identificationEmail} />
								)}
							>
								<div>{connectingClient?.inviterEmail}</div>
							</LabeledValue>
						</LabeledValuesList>
					</SimpleBox>
				</MarginsList>
			</ModalTextSection>

			<CenteredFormWrapper>
				<Form
					onSuccess={handleSubmit}
				>
					{({ isSubmitting, isValid }) => {
						return (
							<div>
								<FormErrorMessages />

								<ButtonsLayout>
									<SubmitButton
										disabled={isSubmitting}
										inactive={!isValid || isSubmitting}
										onClickCallback={handleRejectRequest}
										progress={isRejecting}
										style={STYLE_ALERT}
									>
										<FormattedMessage {...messages.rejectButton} />
									</SubmitButton>

									<SubmitButton
										disabled={isRejecting}
										inactive={!isValid || isRejecting}
										progress={isSubmitting}
									>
										<FormattedMessage {...messages.acceptButton} />
									</SubmitButton>
								</ButtonsLayout>
							</div>
						);
					}}
				</Form>
			</CenteredFormWrapper>
		</ModalContainer>
	);
};



export default AcceptAccessToClientModal;
