import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RelationsIconBuilder: IconBuilder = {
	icon: ({ color = '#B277ED' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeWidth="1"
			>
				<g id="link1-copy-2">
					<path
						d="M16.5006,21.3978 L12.8146,25.0838 C11.5926,26.3058 9.6126,26.3058 8.3916,25.0838 L6.9166,23.6098 C5.6956,22.3878 5.6956,20.4078 6.9166,19.1858 L10.6026,15.4998 C11.8246,14.2788 13.8046,14.2788 15.0266,15.4998 L16.5006,16.9748 C17.7216,18.1958 17.7216,20.1768 16.5006,21.3978 L16.5006,21.3978 Z"
						id="Stroke-1311"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M26.0845,11.8139 L22.3985,15.4999 C21.1765,16.7219 19.1965,16.7219 17.9755,15.4999 L16.5005,14.0259 C15.2795,12.8039 15.2795,10.8239 16.5005,9.6019 L20.1865,5.9159 C21.4085,4.6949 23.3885,4.6949 24.6105,5.9159 L26.0845,7.3909 C27.3055,8.6119 27.3055,10.5929 26.0845,11.8139 L26.0845,11.8139 Z"
						id="Stroke-1312"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M12.8145,19.1861 L20.1865,11.8141"
						id="Stroke-1313"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default RelationsIconBuilder;
