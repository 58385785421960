import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import Emphasis from '~/components/patterns/typography/Emphasis';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import Measurer from '~/utilities/Measurer';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import RichText from '~/components/patterns/typography/RichText';
import SearchEngineActivityTable from '~/components/app/SearchEngineActivityTable';
import SearchEngineVisitsPerDayChart from '~/components/app/SearchEngineVisitsPerDayChart';

import {
	useSearchEngineActivityBlockQuery,
} from './SearchEngineActivityBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';



const messages = defineMessages({
	logFileAnalysisNotEnabled: {
		id: 'ui.pageDetail.searchEngineActivity.logFileAnalysisNotEnabled',
	},
	title: {
		id: 'ui.pageDetail.searchEngineActivity.title',
	},
	upsell: {
		id: 'ui.pageDetail.searchEngineActivity.upsell',
	},
});



const SearchEngineActivityBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const {
		isLoading: isLoadingLogFileAnalysisSetup,
		isLogFileAnalysisEnabled,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const {
		data,
		loading,
	} = usePageDetailPropertiesQuery(
		useSearchEngineActivityBlockQuery,
		legacyUrlId,
		websiteId,
		{
			skip: !isLogFileAnalysisEnabled,
		},
	);

	const isLoading = (
		isLoadingLogFileAnalysisSetup
		|| (loading && data?.lookupPageByLegacyId === undefined)
	);

	const searchEngineActivity = data?.lookupPageByLegacyId?.searchEngineActivity ?? null;

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.LogFileAnalysis}
			hideIfUnattainable={false}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => (
				<BorderedBox
					headerActionElements={premiumAnnotation}
					headerIcon={(
						<AttributesGroupIcon
							size={28}
							type={AttributesGroupIconType.SearchEngineActivity}
						/>
					)}
					headerLabel={(
						<FormattedMessage {...messages.title} />
					)}
					paddingSize={3}
				>
					{!isFeatureEnabled ? (
						<RichText>
							<Emphasis>
								<FormattedMessage {...messages.upsell} />
							</Emphasis>
						</RichText>
					) : !isLogFileAnalysisEnabled ? (
						<RichText>
							<Emphasis>
								<FormattedMessage
									{...messages.logFileAnalysisNotEnabled}
									values={{
										linkSettings: (chunks) => (
											<InternalLink
												routeName="website.detail.logFileAnalysis"
												routeParams={{ websiteId }}
												style={InternalLinkStyle.Decent}
											>
												{chunks}
											</InternalLink>
										),
									}}
								/>
							</Emphasis>
						</RichText>
					) : (
						<List size={ListSize.XLarge}>
							<Measurer>
								{({ containerWidth }) => (
									<SearchEngineActivityTable
										hasCompactLabels={containerWidth < 419}
										lastVisitOfBingDesktop={searchEngineActivity?.lastVisitOfBingDesktop ?? null}
										lastVisitOfBingMobile={searchEngineActivity?.lastVisitOfBingMobile ?? null}
										lastVisitOfGoogleDesktop={searchEngineActivity?.lastVisitOfGoogleDesktop ?? null}
										lastVisitOfGoogleMobile={searchEngineActivity?.lastVisitOfGoogleMobile ?? null}
										loading={isLoading}
										visitFrequencyOfBingDesktop={searchEngineActivity?.visitFrequencyOfBingDesktop ?? null}
										visitFrequencyOfBingMobile={searchEngineActivity?.visitFrequencyOfBingMobile ?? null}
										visitFrequencyOfGoogleDesktop={searchEngineActivity?.visitFrequencyOfGoogleDesktop ?? null}
										visitFrequencyOfGoogleMobile={searchEngineActivity?.visitFrequencyOfGoogleMobile ?? null}
									/>
								)}
							</Measurer>

							<SearchEngineVisitsPerDayChart
								showNoDataMessage={isLogFileAnalysisEnabled}
								visitsPerDayBingDesktop={searchEngineActivity?.visitsPerDayBingDesktop ?? null}
								visitsPerDayBingMobile={searchEngineActivity?.visitsPerDayBingMobile ?? null}
								visitsPerDayGoogleDesktop={searchEngineActivity?.visitsPerDayGoogleDesktop ?? null}
								visitsPerDayGoogleMobile={searchEngineActivity?.visitsPerDayGoogleMobile ?? null}
							/>
						</List>
					)}
				</BorderedBox>
			)}
		</PremiumFeatureSituation>
	);
};



export default SearchEngineActivityBlock;
