import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import HelpHint from '~/components/patterns/hints/HelpHint';

import styles from './PageRelevance.module.scss';



const messages = defineMessages({
	label: {
		id: 'ui.pageDetail.relevance.label',
	},
	tooltip: {
		id: 'ui.interfaceHints.hint.relevance',
	},
});



type Props = {
	relevance: number | null,
};

const PageRelevance: React.FC<Props> = (props) => {
	const {
		relevance,
	} = props;

	return (
		<div className={styles.component}>
			<div className={styles.label}>
				<FormattedMessage {...messages.label} />
				<span className={styles.helpIcon}>
					<HelpHint
						message={(
							<FormattedMessage {...messages.tooltip} />
						)}
					/>
				</span>
			</div>
			<big className={styles.number}>{relevance}</big>
		</div>
	);
};



export default PageRelevance;
