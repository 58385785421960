/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OutgoingExternalLinksQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  limit: GraphQL.Scalars['Int']['input'];
  offset: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type OutgoingExternalLinksQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly legacyId: number | null, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly outgoingExternalLinks: { readonly __typename?: 'PageOutgoingExternalLinksConnection', readonly totalCount: number, readonly edges: ReadonlyArray<{ readonly __typename?: 'PageOutgoingExternalLinksConnectionEdge', readonly cursor: any, readonly loadedAt: number, readonly node: { readonly __typename?: 'PageOutgoingExternalLinksConnectionNode', readonly anchorText: string, readonly anchorTitle: string | null, readonly id: CK.ID, readonly targetId: CK.UrlId, readonly targetLegacyId: number | null, readonly targetStatus: GraphQL.PageOutgoingExternalLinkStatus, readonly targetStatusCode: number | null, readonly targetUrl: string } } | null> } | null } | null } | null };


export const OutgoingExternalLinksDocument = gql`
    query OutgoingExternalLinks($legacyUrlId: Int!, $limit: Int!, $offset: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    legacyId
    pageTypeData {
      outgoingExternalLinks(limit: $limit, offset: $offset) {
        edges {
          cursor
          loadedAt @client
          node {
            anchorText
            anchorTitle
            id
            targetId
            targetLegacyId
            targetStatus
            targetStatusCode
            targetUrl
          }
        }
        totalCount
      }
    }
  }
}
    `;

/**
 * __useOutgoingExternalLinksQuery__
 *
 * To run a query within a React component, call `useOutgoingExternalLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutgoingExternalLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutgoingExternalLinksQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useOutgoingExternalLinksQuery(baseOptions: Apollo.QueryHookOptions<OutgoingExternalLinksQuery, OutgoingExternalLinksQueryVariables> & ({ variables: OutgoingExternalLinksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutgoingExternalLinksQuery, OutgoingExternalLinksQueryVariables>(OutgoingExternalLinksDocument, options);
      }
export function useOutgoingExternalLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutgoingExternalLinksQuery, OutgoingExternalLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutgoingExternalLinksQuery, OutgoingExternalLinksQueryVariables>(OutgoingExternalLinksDocument, options);
        }
export function useOutgoingExternalLinksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OutgoingExternalLinksQuery, OutgoingExternalLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OutgoingExternalLinksQuery, OutgoingExternalLinksQueryVariables>(OutgoingExternalLinksDocument, options);
        }
export type OutgoingExternalLinksQueryHookResult = ReturnType<typeof useOutgoingExternalLinksQuery>;
export type OutgoingExternalLinksLazyQueryHookResult = ReturnType<typeof useOutgoingExternalLinksLazyQuery>;
export type OutgoingExternalLinksSuspenseQueryHookResult = ReturnType<typeof useOutgoingExternalLinksSuspenseQuery>;
export type OutgoingExternalLinksQueryResult = Apollo.QueryResult<OutgoingExternalLinksQuery, OutgoingExternalLinksQueryVariables>;