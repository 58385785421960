import React from 'react';

import type GraphQL from '~/types/graphql';

import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import Emphasis from '~/components/patterns/typography/Emphasis';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WithSeparator from '~/components/atoms/WithSeparator';



type Props = {
	data: GraphQL.CustomerTeamContext['details'],
};

const CustomerTeamContextDetailsOverview: React.FC<Props> = (props) => {
	const {
		data,
	} = props;

	return (
		<EditableFormWrapper
			isForAdmins={true}
			isReadOnly={true}
			title="Customer Team Context"
		>
			{data === null && (
				<Emphasis>No data</Emphasis>
			)}

			{data !== null && (
				<FormRows>
					<FormRow label="Company Name">
						<StaticText>
							{data.companyName}
						</StaticText>
					</FormRow>

					<FormRow label="Global Alexa Rank">
						<StaticText>
							{data.globalAlexaRank}
						</StaticText>
					</FormRow>

					<FormRow label="Domain Aliases">
						<StaticText>
							<StaticList>
								{data.domainAliases}
							</StaticList>
						</StaticText>
					</FormRow>

					<FormRow label="Estimated Annual Revenue">
						<StaticText>
							{data.estimatedAnnualRevenue}
						</StaticText>
					</FormRow>

					<FormRow label="Number of Employees">
						<StaticText>
							{data.numberOfEmployees}
						</StaticText>
					</FormRow>

					<FormRow label="Sub-Industry">
						<StaticText>
							{data.subIndustry}
						</StaticText>
					</FormRow>

					<FormRow label="Tags">
						<StaticText>
							<WithSeparator separator=", ">
								{data.tags}
							</WithSeparator>
						</StaticText>
					</FormRow>

					<FormRow label="Ultimate Parent Company">
						<StaticText>
							{data.ultimateParentCompany}
						</StaticText>
					</FormRow>

					<FormRow label="Fiscal Year End">
						<StaticText>
							{data.fiscalYearEnd}
						</StaticText>
					</FormRow>

					<FormRow label="LinkedIn URL">
						<StaticText>
							{data.linkedInUrl ? (
								<ExternalLink href={'https://www.linkedin.com/' + data.linkedInUrl}>
									{data.linkedInUrl}
								</ExternalLink>
							) : null}
						</StaticText>
					</FormRow>

					<FormRow label="Crunchbase URL">
						<StaticText>
							{data.crunchbaseUrl ? (
								<ExternalLink href={'https://www.crunchbase.com/' + data.crunchbaseUrl}>
									{data.crunchbaseUrl}
								</ExternalLink>
							) : null}
						</StaticText>
					</FormRow>

					<FormRow label="City">
						<StaticText>
							{data.city}
						</StaticText>
					</FormRow>

					<FormRow label="Country">
						<StaticText>
							{data.country}
						</StaticText>
					</FormRow>

					<FormRow label="Indexed at">
						<StaticText>
							{data.indexedAt}
						</StaticText>
					</FormRow>
				</FormRows>
			)}
		</EditableFormWrapper>
	);
};



export default CustomerTeamContextDetailsOverview;
