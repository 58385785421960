import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useAlertTypeDefinitions from '~/hooks/useAlertTypeDefinitions';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteAlertDefinitions from '~/hooks/useWebsiteAlertDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';



type ReturnType = {
	all: Readonly<Array<GraphQL.AlertDefinition>>,
	allWithScope: Readonly<Array<GraphQL.AlertDefinition>>,
	allWithSensitivity: Readonly<Array<GraphQL.AlertDefinition>>,
	count: number,
	ids: Readonly<Array<GraphQL.AlertDefinition['id']>>,
	idsWithScope: Readonly<Array<GraphQL.AlertDefinition['id']>>,
	idsWithSensitivity: Readonly<Array<GraphQL.AlertDefinition['id']>>,
};



function useBulkEditedAlertDefinitions(): ReturnType {
	const websiteId = useWebsiteId();

	const alertDefinitions = useWebsiteAlertDefinitions(websiteId);
	const alertTypeDefinitions = useAlertTypeDefinitions();
	const urlState = useUrlState();

	return React.useMemo(
		() => {
			const alertDefinitionIds = (
				urlState.params.alertDefinitionIds
					? (urlState.params.alertDefinitionIds + '').split(',')
					: []
			) as Array<CK.AlertDefinitionId>;

			const selectedAlertDefinitions = alertDefinitions
				.listAll()
				.filter((alertDefinition) => alertDefinitionIds.includes(alertDefinition.id));

			const selectedAlertDefinitionsWithScope = selectedAlertDefinitions.filter(
				(alertDefinition) => {
					return alertTypeDefinitions.getByType(alertDefinition.alertType)?.hasScope ?? false;
				},
			);

			const selectedAlertDefinitionsWithSensitivity = selectedAlertDefinitions.filter(
				(alertDefinition) => {
					return alertTypeDefinitions.getByType(alertDefinition.alertType)?.hasSensitivity ?? false;
				},
			);

			return {
				all: selectedAlertDefinitions,
				allWithScope: selectedAlertDefinitionsWithScope,
				allWithSensitivity: selectedAlertDefinitionsWithSensitivity,
				count: alertDefinitions.isLoaded
					? selectedAlertDefinitions.length
					: alertDefinitionIds.length,
				ids: selectedAlertDefinitions.map(
					(alertDefinition) => alertDefinition.id,
				),
				idsWithScope: selectedAlertDefinitionsWithScope.map(
					(alertDefinition) => alertDefinition.id,
				),
				idsWithSensitivity: selectedAlertDefinitionsWithSensitivity.map(
					(alertDefinition) => alertDefinition.id,
				),
			};
		},
		[
			alertDefinitions,
			alertTypeDefinitions,
			urlState,
		],
	);
}



export default useBulkEditedAlertDefinitions;
