import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SheetIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m24 24h-24v-24h24z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="m18.1176471 19.5882353h-12.50000004v-15.44117648h7.03081054l5.4691895 5.404682v-.00231629z" />
					<path d="m17.3823529 9.29411765h-5.1470588v-5.14705883" />
					<path d="m8.55882353 15.9117647h6.61764707" />
					<path d="m8.55882353 13.7058824h6.61764707" />
					<path d="m8.55882353 11.5h6.61764707" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SheetIconBuilder;

