import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	usePageTypeQuery,
} from './usePageType.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageType(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
): GraphQL.PageType | null {
	const { data } = usePageDetailPropertiesQuery(
		usePageTypeQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.pageType ?? null;
}



export default usePageType;
