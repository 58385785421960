export default function isValidRegexp(value: string) {
	if (value.length < 2) {
		return false;
	}

	const delimiter = value.substring(0, 1);
	const pattern = value.substring(1, value.lastIndexOf(delimiter));
	const flags = value.substring(value.lastIndexOf(delimiter) + 1);

	try {
		new RegExp(delimiter + pattern + delimiter, flags);
	} catch (e) {
		return false;
	}

	return true;
}
