import Immutable from 'immutable';
import React from 'react';

import type CK from '~/types/contentking';

import {
	useFilteredPagesQuery,
} from './usePages.gql';

import useGraphQLConnection from '~/hooks/useGraphQLConnection';

import {
	getFilter,
} from '~/model/pages';

import {
	notEmpty,
} from '~/utilities/typeCheck';



const defaultSortBy = Immutable.Map({
	direction: true,
	key: 'url',
});

const invalidState = {
	distributions: undefined,
	fetchFurtherRange: () => Promise.resolve(),
	loading: false,
	rowGetter: () => null,
	total: 0,
};



function usePages(input: {
	columns: ReadonlyArray<string>,
	filter: any,
	limit: number,
	pollIntervalInMilliseconds: number,
	skip?: boolean,
	sortBy: any | null,
	websiteId: CK.WebsiteId | null,
}) {
	const {
		columns,
		filter,
		limit,
		pollIntervalInMilliseconds,
		skip = false,
		websiteId,
	} = input;

	const sortBy = input.sortBy ?? defaultSortBy;

	const after = null;
	const ascending = sortBy.get('direction');
	const before = null;
	const sort = sortBy.get('key');

	const {
		criteria,
		isInvalid,
	} = getFilter(filter.toJS ? filter : Immutable.fromJS(filter));

	const { data, fetchMore } = useFilteredPagesQuery({
		skip: websiteId === null || isInvalid || skip,
		variables: {
			after,
			ascending,
			before,
			columns,
			filter: criteria,
			limit,
			offset: 0,
			sort,
			websiteId: websiteId ?? '',
		},
	});

	const checkColumns = React.useCallback(
		(edges: ReadonlyArray<{
			node: {
				data: any,
			},
		} | null>) => {
			return columns.some((column: CK.PagesColumn) => {
				return edges.filter(notEmpty).some(
					({ node }) => node.data[column] === undefined,
				);
			});
		},
		[
			columns,
		],
	);

	const graphQLConnection = useGraphQLConnection({
		batchLimit: limit,
		context: {
			columns,
			filter,
			sortBy,
			websiteId,
		},
		customIsNotLoaded: checkColumns,
		data,
		fetchMore,
		getter: (data) => data.filteredPages,
		pollIntervalInMilliseconds,
	});

	const graphQLConnectionRowGetter = graphQLConnection.rowGetter;

	const rowGetter = React.useCallback(
		(input: { rowIndex: number }) => {
			return graphQLConnectionRowGetter(input)?.data ?? null;
		},
		[
			graphQLConnectionRowGetter,
		],
	);

	if (isInvalid) {
		return invalidState;
	}

	return {
		distributions: data?.filteredPages?.distributions ?? undefined,
		fetchFurtherRange: graphQLConnection.fetchFurtherRange,
		loading: (data?.filteredPages ?? undefined) === undefined,
		rowGetter,
		total: graphQLConnection.totalCount,
	};
}



export default usePages;
