import classNames from 'classnames';
import React from 'react';



export enum MarkStyle {
	Highlighted = 'highlighted',
	Underlined = 'underlined',
}



type Props = {
	children?: React.ReactNode,
	style?: MarkStyle,
};

const Mark: React.FC<Props> = (props) => {
	const {
		children,
		style = MarkStyle.Highlighted,
	} = props;

	const componentClasses = classNames({
		'mark': true,
		'mark--highlighted': style === MarkStyle.Highlighted,
		'mark--underlined': style === MarkStyle.Underlined,
	});

	return (
		<mark className={componentClasses}>
			{children}
		</mark>
	);
};



export default Mark;
