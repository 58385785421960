import classNames from 'classnames';
import React from 'react';



type Props = {
	children: React.ReactNode,
	/** Flag to enable skeleton mode */
	isLoading?: boolean,
	placeholder?: React.ReactNode,
};

const LoadingWrapper: React.FC<Props> = (props) => {
	const {
		children,
		isLoading = false,
		placeholder,
	} = props;

	const componentClasses = classNames({
		'loading-wrapper': true,
		'loading-wrapper--is-loading': isLoading,
	});

	return (
		<div className={componentClasses}>
			{isLoading && placeholder && (
				<div className="loading-wrapper__placeholder">
					{placeholder}
				</div>
			)}
			<div className="loading-wrapper__content">
				{children}
			</div>
		</div>
	);
};



export default LoadingWrapper;
