import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MegaphoneIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M24,9.495A2,2,0,0,0,22.67,7.622.5.5,0,0,0,22,8.093l.007,3.824a.5.5,0,0,0,.67.469A2,2,0,0,0,24,10.5Z"
				fill={color}
			/>
			<path
				d="M20.5,3.5a1.492,1.492,0,0,0-.7-1.26c-.834-.53-1.469-.086-2.726.519A22.5,22.5,0,0,1,6.972,5.447a.5.5,0,0,0-.472.5v8.606h0a8.312,8.312,0,0,0,4.6,7.334,1,1,0,1,0,.888-1.792,6.288,6.288,0,0,1-3.48-5.421,21.913,21.913,0,0,1,8.55,2.47c1.376.647,1.938,1.035,2.756.5a1.494,1.494,0,0,0,.683-1.264Z"
				fill={color}
			/>
			<path
				d="M5,6.017a.5.5,0,0,0-.5-.5l-1.013.008A3.505,3.505,0,0,0,0,9.042l.009,2a3.5,3.5,0,0,0,3.516,3.483H4.5a.5.5,0,0,0,.5-.5Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MegaphoneIconBuilder;
