import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum GoogleSearchConsoleIconVariants {
	Active = 'active',
	Inactive = 'inactive',
}



type GoogleSearchConsoleIconBuilderProps = {
	variant?: GoogleSearchConsoleIconVariants,
};

let googleSearchConsoleBasicIconCounter = 0;

const GoogleSearchConsoleIconBuilder: IconBuilder<GoogleSearchConsoleIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = GoogleSearchConsoleIconVariants.Active,
		} = props;

		const componentId = googleSearchConsoleBasicIconCounter++;

		if (variant === GoogleSearchConsoleIconVariants.Inactive) {
			return (
				<g opacity="0.8">
					<path
						clipRule="evenodd"
						d="M15.2149 4.54199V7.09605H16.5527V5.87982L15.2149 4.54199ZM9.98513 4.54199L8.64729 5.87982V7.09605H9.98513V4.54199Z"
						fill="#C0C0C0"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M9.98513 5.81901H15.2149V4.54198H9.98513V5.81901Z"
						fill="#ADADAD"
						fillRule="evenodd"
					/>
					<mask
						height="14"
						id={'gsc-basic-a-' + componentId}
						maskUnits="userSpaceOnUse"
						style={{
							maskType: 'alpha',
						}}
						width="19"
						x="3"
						y="7"
					>
						<path
							clipRule="evenodd"
							d="M3.6 7.09604H21.6V20.596H3.6V7.09604Z"
							fill="white"
							fillRule="evenodd"
						/>
					</mask>
					<g mask={'url(#gsc-basic-a-' + componentId + ')'}>
						<path
							clipRule="evenodd"
							d="M20.1405 20.596H5.05946C4.25342 20.596 3.6 19.9426 3.6 19.1366V9.60375L6.10772 7.09604H19.0923L21.6 9.60375V19.1366C21.6 19.9426 20.9466 20.596 20.1405 20.596"
							fill="#E6E7E8"
							fillRule="evenodd"
						/>
					</g>
					<path
						clipRule="evenodd"
						d="M3.6 12.265V9.60376L6.10772 7.09604H19.0923L21.6 9.60376V12.265H3.6Z"
						fill="#D0D1D2"
						fillRule="evenodd"
					/>
					<mask
						height="12"
						id={'gsc-basic-b-' + componentId}
						maskUnits="userSpaceOnUse"
						style={{
							maskType: 'alpha',
						}}
						width="15"
						x="5"
						y="9"
					>
						<path
							clipRule="evenodd"
							d="M5.66757 9.65009H19.5324V20.596H5.66757V9.65009Z"
							fill="white"
							fillRule="evenodd"
						/>
					</mask>
					<g mask={'url(#gsc-basic-b-' + componentId + ')'}>
						<path
							clipRule="evenodd"
							d="M5.66757 20.596V10.2582C5.66757 9.92235 5.93982 9.65009 6.27567 9.65009H18.9243C19.2602 9.65009 19.5324 9.92235 19.5324 10.2582V20.596H5.66757Z"
							fill="#B0B1B3"
							fillRule="evenodd"
						/>
					</g>
					<mask
						height="17"
						id={'gsc-basic-c-' + componentId}
						maskUnits="userSpaceOnUse"
						style={{
							maskType: 'alpha',
						}}
						width="19"
						x="3"
						y="4"
					>
						<path
							clipRule="evenodd"
							d="M3.6 20.596H21.6V4.54198H3.6V20.596Z"
							fill="white"
							fillRule="evenodd"
						/>
					</mask>
					<g mask={'url(#gsc-basic-c-' + componentId + ')'}>
						<path
							clipRule="evenodd"
							d="M5.66756 20.596H19.5324V12.265H5.66756V20.596Z"
							fill="white"
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M15.3973 20.596V18.1028L14.1811 16.8866L13.3905 14.6974L13.9378 13.2987L17.0392 16.4001L17.5865 14.0893L19.5324 16.0352V20.596H15.3973Z"
							fill="#D2D3D4"
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M6.57973 20.596H12.5392V19.5623H6.57973V20.596ZM6.57973 18.4069H11.2013V13.2379H6.57973V18.4069Z"
							fill="#D2D3D4"
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M16.5527 18.6562V20.596H13.9986V18.6837C12.4853 17.995 11.8169 16.2098 12.5056 14.6965C12.7971 14.0559 13.3039 13.5377 13.9378 13.232V16.096L15.2149 16.8866L16.5527 16.096V13.2318C18.0506 13.9544 18.6791 15.7544 17.9565 17.2523C17.6608 17.8655 17.1659 18.3604 16.5527 18.6562"
							fill="#C1C1C1"
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M8.61689 10.319C8.90236 10.319 9.13378 10.5504 9.13378 10.8359C9.13378 11.1214 8.90236 11.3528 8.61689 11.3528C8.33142 11.3528 8.09999 11.1214 8.09999 10.8359C8.09999 10.5504 8.33142 10.319 8.61689 10.319M7.09662 10.319C7.38209 10.319 7.61351 10.5504 7.61351 10.8359C7.61351 11.1214 7.38209 11.3528 7.09662 11.3528C6.81115 11.3528 6.57973 11.1214 6.57973 10.8359C6.57973 10.5504 6.81115 10.319 7.09662 10.319"
							fill="#E6E7E8"
							fillRule="evenodd"
						/>
					</g>
				</g>
			);
		}

		return (
			<g>
				<path
					clipRule="evenodd"
					d="M15.2148 3.60718V6.16123H16.5526V4.94501L15.2148 3.60718ZM9.98506 3.60718L8.64722 4.94501V6.16123H9.98506V3.60718Z"
					fill="#7B7B7B"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M9.98511 4.88421H15.2148V3.60718H9.98511V4.88421Z"
					fill="#5A5A5A"
					fillRule="evenodd"
				/>
				<mask
					height="14"
					id={'gsc-basic-a-' + componentId}
					maskUnits="userSpaceOnUse"
					style={{ maskType: 'alpha' }}
					width="19"
					x="3"
					y="6"
				>
					<path
						clipRule="evenodd"
						d="M3.59998 6.16125H21.6V19.6612H3.59998V6.16125Z"
						fill="white"
						fillRule="evenodd"
					/>
				</mask>
				<g mask={'url(#gsc-basic-a-' + componentId + ')'}>
					<path
						clipRule="evenodd"
						d="M20.1405 19.6613H5.05944C4.2534 19.6613 3.59998 19.0078 3.59998 18.2018V8.66896L6.10769 6.16125H19.0923L21.6 8.66896V18.2018C21.6 19.0078 20.9466 19.6613 20.1405 19.6613Z"
						fill="#E6E7E8"
						fillRule="evenodd"
					/>
				</g>
				<path
					clipRule="evenodd"
					d="M3.59998 11.3302V8.66897L6.10769 6.16125H19.0923L21.6 8.66897V11.3302H3.59998Z"
					fill="#D0D1D2"
					fillRule="evenodd"
				/>
				<mask
					height="12"
					id={'gsc-basic-b-' + componentId}
					maskUnits="userSpaceOnUse"
					style={{ maskType: 'alpha' }}
					width="15"
					x="5"
					y="8"
				>
					<path
						clipRule="evenodd"
						d="M5.6676 8.71527H19.5325V19.6612H5.6676V8.71527Z"
						fill="white"
						fillRule="evenodd"
					/>
				</mask>
				<g mask={'url(#gsc-basic-b-' + componentId + ')'}>
					<path
						clipRule="evenodd"
						d="M5.6676 19.6612V9.32338C5.6676 8.98753 5.93986 8.71527 6.27571 8.71527H18.9244C19.2602 8.71527 19.5325 8.98753 19.5325 9.32338V19.6612H5.6676Z"
						fill="#458CF5"
						fillRule="evenodd"
					/>
				</g>
				<mask
					height="17"
					id={'gsc-basic-c-' + componentId}
					maskUnits="userSpaceOnUse"
					style={{ maskType: 'alpha' }}
					width="19"
					x="3"
					y="3"
				>
					<path
						clipRule="evenodd"
						d="M3.59998 19.6612H21.6V3.60718H3.59998V19.6612Z"
						fill="white"
						fillRule="evenodd"
					/>
				</mask>
				<g mask={'url(#gsc-basic-c-' + componentId + ')'}>
					<path
						clipRule="evenodd"
						d="M5.6676 19.6612H19.5325V11.3301H5.6676V19.6612Z"
						fill="white"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M15.3973 19.6613V17.168L14.181 15.9518L13.3905 13.7626L13.9378 12.364L17.0392 15.4653L17.5864 13.1545L19.5324 15.1004V19.6613H15.3973Z"
						fill="#D2D3D4"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M6.57971 19.6612H12.5392V18.6274H6.57971V19.6612ZM6.57971 17.472H11.2013V12.3031H6.57971V17.472Z"
						fill="#D2D3D4"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M16.5527 17.7214V19.6613H13.9986V17.7489C12.4853 17.0602 11.8168 15.2751 12.5055 13.7617C12.7971 13.1211 13.3039 12.6029 13.9378 12.2972V15.1612L15.2148 15.9518L16.5527 15.1612V12.2971C18.0505 13.0196 18.6791 14.8197 17.9565 16.3175C17.6607 16.9307 17.1658 17.4256 16.5527 17.7214Z"
						fill="#505050"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M8.61687 9.38422C8.90234 9.38422 9.13377 9.61564 9.13377 9.90111C9.13377 10.1866 8.90234 10.418 8.61687 10.418C8.33141 10.418 8.09998 10.1866 8.09998 9.90111C8.09998 9.61564 8.33141 9.38422 8.61687 9.38422ZM7.09661 9.38422C7.38207 9.38422 7.6135 9.61564 7.6135 9.90111C7.6135 10.1866 7.38207 10.418 7.09661 10.418C6.81113 10.418 6.57971 10.1866 6.57971 9.90111C6.57971 9.61564 6.81113 9.38422 7.09661 9.38422Z"
						fill="#E6E7E8"
						fillRule="evenodd"
					/>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default GoogleSearchConsoleIconBuilder;

