import React from 'react';



type Props = {
	image: React.ReactNode,
	label: React.ReactNode,
};



const SmallImageLabel: React.FC<Props> = (props) => {
	const {
		image,
		label,
	} = props;

	return (
		<div className="small-image-label">
			<div className="small-image-label__image">
				{image}
			</div>
			<div className="small-image-label__label">
				{label}
			</div>
		</div>
	);
};



export default SmallImageLabel;
