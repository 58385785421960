import React from 'react';

import CancelButton from '~/components/app/CancelButton';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import GlobalFormMessage from '~/components/atoms/forms/basis/GlobalFormMessage';
import ModalButtonsLayout from '~/components/patterns/modals/parts/ModalButtonsLayout';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useImportWebsiteIntoAccountMutation,
} from '~/components/app/ImportWebsiteModal.gql';

import useAccountId from '~/hooks/useAccountId';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useAccountDisplayName from '~/hooks/useAccountDisplayName';



const validations = {
	validateWebsiteId: validateField(
		'websiteId',
		(f) => ([
			f.validateNonEmpty(),
		]),
	),
	validateSourceAccountId: validateField(
		'sourceAccountId',
		(f) => ([
			f.validateNonEmpty(),
			f.validateInteger(),
		]),
	),
};



const ImportWebsiteModal: React.FC = () => {
	const classicFormBehavior = useClassicFormBehavior();
	const targetAccountId = useAccountId();
	const targetAccountName = useAccountDisplayName(targetAccountId);

	const [importWebsiteIntoAccount] = useImportWebsiteIntoAccountMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (targetAccountId === null) {
				return;
			}

			await importWebsiteIntoAccount({
				variables: {
					sourceAccountId: parseInt(values.sourceAccountId, 10),
					targetAccountId,
					websiteId: values.websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			importWebsiteIntoAccount,
			targetAccountId,
		],
	);

	return (
		<SimpleModal
			size={SimpleModalSize.Small}
			title="Import website"
		>
			<Form
				defaultFocus="websiteId"
				defaultValues={{
					websiteId: '',
					sourceAccountId: '',
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRows>
					<FormRow
						label="Website ID"
					>
						<FieldStatus name="validateWebsiteId">
							<TextField name="websiteId" />
						</FieldStatus>
					</FormRow>

					<FormRow
						description={(
							<span>The ID of the account you want to import the website <em>from</em>.</span>
						)}
						label="From account"
					>
						<FieldStatus name="validateSourceAccountId">
							<TextField name="sourceAccountId" />
						</FieldStatus>
					</FormRow>

					<hr />

					<FormRow label="Into account">
						<StaticText>
							{targetAccountName} ({targetAccountId})
						</StaticText>
					</FormRow>

				</FormRows>

				<GlobalFormMessage>
					{({ globalError }) => globalError.getMessage()}
				</GlobalFormMessage>

				<ModalButtonsLayout>
					<CancelButton />

					<SubmitButton>
						Import website
					</SubmitButton>
				</ModalButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default ImportWebsiteModal;
