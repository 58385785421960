import Immutable from 'immutable';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	createFilterParameter,
} from '~/actions/issues';

import {
	DEFAULT_FILTER,
} from '~/model/issues';



function useNavigateToIssues() {
	const currentWebsiteId = useWebsiteId();

	return (input) => {
		const {
			filter = null,
			scope = 'website',
			websiteId = currentWebsiteId,
		} = input;

		let totalFilter = DEFAULT_FILTER.merge(
			Immutable.fromJS(filter ? filter : {}),
		);

		if (scope !== 'website') {
			totalFilter = totalFilter.set(
				'segment',
				scope.substring('segment/'.length),
			);
		}

		return {
			routeName: 'website.issues',
			routeParams: {
				filter: createFilterParameter(
					totalFilter,
				) || undefined,
				websiteId,
			},
		};
	};
}



export default useNavigateToIssues;
