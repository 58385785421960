import React from 'react';

import GraphQL from '~/types/graphql';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import NumberFormatter from '~/components/app/NumberFormatter';
import TimingFormatter, {
	TimingFormatterUnit,
} from '~/components/app/TimingFormatter';
import WebVitalsValue, {
	WebVitalsValueStatus,
} from '~/components/patterns/webVitals/WebVitalsValue';
import WebVitalsStatusIndicator, {
	WebVitalsStatusIndicatorStatus,
} from '~/components/patterns/webVitals/WebVitalsStatusIndicator';

import {
	WebVital,
} from '~/model/webVitals';



const verdictMapping: Record<string, WebVitalsValueStatus> = {
	[GraphQL.WebVitalRange.Good]: WebVitalsValueStatus.Good,
	[GraphQL.WebVitalRange.NeedsImprovement]: WebVitalsValueStatus.NeedsImprovement,
	[GraphQL.WebVitalRange.Bad]: WebVitalsValueStatus.Poor,
	[GraphQL.WebVitalVerdict.Fast]: WebVitalsValueStatus.Good,
	[GraphQL.WebVitalVerdict.Average]: WebVitalsValueStatus.NeedsImprovement,
	[GraphQL.WebVitalVerdict.Slow]: WebVitalsValueStatus.Poor,
	FAST: WebVitalsValueStatus.Good,
	AVERAGE: WebVitalsValueStatus.NeedsImprovement,
	SLOW: WebVitalsValueStatus.Poor,
};

const statusMapping = {
	[WebVitalsValueStatus.Good]: WebVitalsStatusIndicatorStatus.Good,
	[WebVitalsValueStatus.NeedsImprovement]: WebVitalsStatusIndicatorStatus.NeedsImprovement,
	[WebVitalsValueStatus.Poor]: WebVitalsStatusIndicatorStatus.Poor,
};



type Props = {
	showStatusIndicator?: boolean,
	type: WebVital,
	value: number,
	verdict?: GraphQL.WebVitalVerdict | GraphQL.WebVitalRange,
};

const WebVitalFormatter: React.FC<Props> = (props) => {
	const {
		showStatusIndicator,
		type,
		value,
		verdict,
	} = props;

	const status = verdict ? verdictMapping[verdict] : undefined;

	const formattedValue = React.useMemo(
		() => {
			switch (type) {

				case WebVital.CLS:
					return (
						<NumberFormatter
							maximumFractionDigits={3}
							minimumFractionDigits={0}
							value={value / 100}
						/>
					);

				case WebVital.FCP:
					return (
						<TimingFormatter
							maximumFractionDigits={1}
							minimumFractionDigits={1}
							plain={!showStatusIndicator}
							unit={TimingFormatterUnit.Seconds}
							value={value / 1000}
						/>
					);

				case WebVital.FID:
					return (
						<TimingFormatter
							plain={!showStatusIndicator}
							unit={TimingFormatterUnit.Milliseconds}
							value={value}
						/>
					);

				case WebVital.INP:
					return (
						<TimingFormatter
							plain={!showStatusIndicator}
							unit={TimingFormatterUnit.Milliseconds}
							value={value}
						/>
					);

				case WebVital.LCP:
					return (
						<TimingFormatter
							maximumFractionDigits={1}
							minimumFractionDigits={1}
							plain={!showStatusIndicator}
							unit={TimingFormatterUnit.Seconds}
							value={value / 1000}
						/>
					);

				case WebVital.Performance:
					return (
						<NumberFormatter
							maximumFractionDigits={0}
							value={value}
						/>
					);

				case WebVital.SI:
					return (
						<TimingFormatter
							maximumFractionDigits={1}
							minimumFractionDigits={1}
							plain={!showStatusIndicator}
							unit={TimingFormatterUnit.Seconds}
							value={value / 1000}
						/>
					);

				case WebVital.TBT:
					return (
						<TimingFormatter
							maximumFractionDigits={0}
							plain={!showStatusIndicator}
							unit={TimingFormatterUnit.Milliseconds}
							value={value}
						/>
					);

				case WebVital.TTI:
					return (
						<TimingFormatter
							maximumFractionDigits={1}
							minimumFractionDigits={1}
							plain={!showStatusIndicator}
							unit={TimingFormatterUnit.Seconds}
							value={value / 1000}
						/>
					);

			}
		},
		[
			showStatusIndicator,
			type,
			value,
		],
	);

	if (showStatusIndicator) {
		const statusIndicator = status ? statusMapping[status] : undefined;

		return (
			<AttachedIcon
				icon={(
					<WebVitalsStatusIndicator
						status={statusIndicator}
					/>
				)}
			>
				{formattedValue}
			</AttachedIcon>
		);
	}

	return (
		<WebVitalsValue status={status}>
			{formattedValue}
		</WebVitalsValue>
	);
};



export default WebVitalFormatter;
