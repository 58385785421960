import classNames from 'classnames';
import React from 'react';

import Measurer from '~/utilities/Measurer';

import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import RichText from '~/components/patterns/typography/RichText';



export enum ConfirmationMessageContext {
	Confirmation = 'confirmation',
	EmailSent = 'email-sent',
	Pending = 'pending',
}

type Props = {
	/** Play enter animation */
	animate?: boolean,
	/** Context of this message */
	context?: ConfirmationMessageContext,
	ctaButtons?: React.ReactNode,
	/** Additional description to the main message */
	children?: React.ReactNode,
	/** Main announcement of this message */
	message?: React.ReactNode,
};



const ConfirmationMessage: React.FC<Props> = (props) => {
	const {
		animate = false,
		context = ConfirmationMessageContext.Confirmation,
		ctaButtons,
		children,
		message,
	} = props;

	return (
		<Measurer>
			{({ containerWidth }) => (
				<div
					className={classNames({
						'confirmation-message': true,
						'confirmation-message--animated': animate,
						'confirmation-message--wider': containerWidth >= 760,
						['confirmation-message--' + context + '-context']: true,
					})}
				>
					<div className="confirmation-message__illustration" />

					<div className="confirmation-message__content">
						{message && (
							<h3 className="confirmation-message__message">{message}</h3>
						)}

						{children && (
							<div className="confirmation-message__text">
								<RichText>
									{children}
								</RichText>
							</div>
						)}

						{ctaButtons && (
							<div className="confirmation-message__cta-buttons">
								<ButtonsGroup>
									{ctaButtons}
								</ButtonsGroup>
							</div>
						)}
					</div>
				</div>
			)}
		</Measurer>
	);
};



export default ConfirmationMessage;
