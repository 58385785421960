/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveUnclickablePagesMutationVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type RemoveUnclickablePagesMutation = { readonly __typename?: 'Mutation', readonly RemoveUnclickablePages: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const RemoveUnclickablePagesDocument = gql`
    mutation RemoveUnclickablePages($websiteId: WebsiteId!) {
  RemoveUnclickablePages(websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type RemoveUnclickablePagesMutationFn = Apollo.MutationFunction<RemoveUnclickablePagesMutation, RemoveUnclickablePagesMutationVariables>;

/**
 * __useRemoveUnclickablePagesMutation__
 *
 * To run a mutation, you first call `useRemoveUnclickablePagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUnclickablePagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUnclickablePagesMutation, { data, loading, error }] = useRemoveUnclickablePagesMutation({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useRemoveUnclickablePagesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUnclickablePagesMutation, RemoveUnclickablePagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUnclickablePagesMutation, RemoveUnclickablePagesMutationVariables>(RemoveUnclickablePagesDocument, options);
      }
export type RemoveUnclickablePagesMutationHookResult = ReturnType<typeof useRemoveUnclickablePagesMutation>;
export type RemoveUnclickablePagesMutationResult = Apollo.MutationResult<RemoveUnclickablePagesMutation>;
export type RemoveUnclickablePagesMutationOptions = Apollo.BaseMutationOptions<RemoveUnclickablePagesMutation, RemoveUnclickablePagesMutationVariables>;