/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateIndexNowUrlBlocklistMutationVariables = GraphQL.Exact<{
  mode: GraphQL.UrlBlocklistMode;
  rules: ReadonlyArray<GraphQL.UrlBlocklistRuleInput> | GraphQL.UrlBlocklistRuleInput;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateIndexNowUrlBlocklistMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteIndexNowUrlBlocklist: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly indexNowUrlBlocklistMode: GraphQL.UrlBlocklistMode, readonly indexNowUrlBlocklistRules: ReadonlyArray<{ readonly __typename?: 'UrlBlocklistRule', readonly operator: GraphQL.UrlBlocklistRuleOperator, readonly value: string }> } | null } } };


export const UpdateIndexNowUrlBlocklistDocument = gql`
    mutation UpdateIndexNowUrlBlocklist($mode: UrlBlocklistMode!, $rules: [UrlBlocklistRuleInput!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteIndexNowUrlBlocklist(
    mode: $mode
    rules: $rules
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        indexNowUrlBlocklistMode
        indexNowUrlBlocklistRules {
          operator
          value
        }
      }
    }
  }
}
    `;
export type UpdateIndexNowUrlBlocklistMutationFn = Apollo.MutationFunction<UpdateIndexNowUrlBlocklistMutation, UpdateIndexNowUrlBlocklistMutationVariables>;

/**
 * __useUpdateIndexNowUrlBlocklistMutation__
 *
 * To run a mutation, you first call `useUpdateIndexNowUrlBlocklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndexNowUrlBlocklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndexNowUrlBlocklistMutation, { data, loading, error }] = useUpdateIndexNowUrlBlocklistMutation({
 *   variables: {
 *      mode: // value for 'mode'
 *      rules: // value for 'rules'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateIndexNowUrlBlocklistMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndexNowUrlBlocklistMutation, UpdateIndexNowUrlBlocklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIndexNowUrlBlocklistMutation, UpdateIndexNowUrlBlocklistMutationVariables>(UpdateIndexNowUrlBlocklistDocument, options);
      }
export type UpdateIndexNowUrlBlocklistMutationHookResult = ReturnType<typeof useUpdateIndexNowUrlBlocklistMutation>;
export type UpdateIndexNowUrlBlocklistMutationResult = Apollo.MutationResult<UpdateIndexNowUrlBlocklistMutation>;
export type UpdateIndexNowUrlBlocklistMutationOptions = Apollo.BaseMutationOptions<UpdateIndexNowUrlBlocklistMutation, UpdateIndexNowUrlBlocklistMutationVariables>;