import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import ColumnsConfigurator from '~/components/logic/columnsConfigurator/ColumnsConfigurator';
import ColumnsManagementLayout, {
	LAYOUT_OVERLAY,
} from '~/components/atoms/columnsConfigurator/ColumnsManagementLayout';
import IsWebsiteCrawledContext from '~/components/app/IsWebsiteCrawledContext';
import PagesColumnsDatatable from '~/components/app/PagesColumnsDatatable';

import useEnabledWebsiteColumnsList from '~/hooks/useEnabledWebsiteColumnsList';
import useFilteredPages from '~/hooks/useFilteredPages';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	resetPagesFilter,
	updatePagesFilter,
	updatePagesSorting,
} from '~/actions/pages/overview';

import {
	copyUrlIdToClipboardItem,
	copyUrlToClipboardItem,
	openPageDetailItem,
	openPageOnWebsiteItem,
} from '~/model/contextMenu';

import {
	removeDefaultFilterValues,
} from '~/model/pages';

import {
	getRouter,
} from '~/routing/router';

import {
	filterSelector,
	sortBySelector,
} from '~/state/ui/selectors';



const PagesOverview: React.FC = () => {
	const dispatch = useDispatch();
	const filteredPages = useFilteredPages();
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const websiteId = useWebsiteId();

	const columns = useEnabledWebsiteColumnsList();
	const filter = useSelector(filterSelector);
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const sortBy = useSelector(sortBySelector);

	const handleFilterChange = React.useCallback(
		(filter) => {
			dispatch(
				updatePagesFilter(filter),
			);
		},
		[
			dispatch,
		],
	);

	const handleSortingChange = React.useCallback(
		(sortBy) => {
			dispatch(
				updatePagesSorting(sortBy),
			);
		},
		[
			dispatch,
		],
	);

	const contextMenuEntries = React.useCallback(
		({ row }) => {
			const pageDetailUrl = getRouter().buildPath('website.pages.detail', {
				id: row.id,
				websiteId,
			});

			const result = [
				openPageDetailItem(pageDetailUrl),
				openPageOnWebsiteItem(row.url),
				copyUrlToClipboardItem(row.url),
			];

			if (kingdomAdminFeatures.areVisible) {
				result.push(
					copyUrlIdToClipboardItem(row.uniqueId),
				);
			}

			return result;
		},
		[
			kingdomAdminFeatures,
			websiteId,
		],
	);

	return (
		<ColumnsManagementLayout
			columnsConfigurator={(
				<ColumnsConfigurator />
			)}
			layout={LAYOUT_OVERLAY}
		>
			<IsWebsiteCrawledContext>
				{({ isCrawled }) => (
					<PagesColumnsDatatable
						columns={columns}
						contextMenuEntries={contextMenuEntries}
						distributions={filteredPages.distributions}
						filter={filter}
						isCrawled={isCrawled}
						isLoading={filteredPages.loading}
						isRowClickable={true}
						onFilterChangeCallback={handleFilterChange}
						onLoadPagesCallback={filteredPages.fetchFurtherRange}
						onSortChangeCallback={handleSortingChange}
						pagesCount={filteredPages.total}
						removeDefaultFilterValues={removeDefaultFilterValues}
						resetFilterAction={resetPagesFilter}
						rowGetter={filteredPages.rowGetter}
						segmentDefinitions={segmentDefinitions.listAll()}
						sortBy={sortBy}
						websiteId={websiteId}
					/>
				)}
			</IsWebsiteCrawledContext>
		</ColumnsManagementLayout>
	);
};



export default React.memo(PagesOverview);
