import classNames from 'classnames';
import React from 'react';

import styles from './TimelineCollapsedEntry.module.scss';



type Props = {
	actionButton: React.ReactNode,
	/** Flag that this entry is first from all entries */
	isFirstChild?: boolean,
	/** Flag that this entry is last from all entries */
	isLastChild?: boolean,
};

const TimelineCollapsedEntry: React.FC<Props> = (props) => {
	const {
		actionButton,
		isFirstChild,
		isLastChild,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isFirst]: isFirstChild,
		[styles.isLast]: isLastChild,
	});

	return (
		<section className={componentClasses}>
			<div className={styles.entry}>
				<div className={styles.actionButton}>
					{actionButton}
				</div>
			</div>
		</section>
	);
};



export default TimelineCollapsedEntry;
