import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



let googleSearchConsoleAttrsGroupIconCounter = 0;



const GoogleSearchConsoleIconBuilder: IconBuilder = {
	icon: () => {
		const componentId = googleSearchConsoleAttrsGroupIconCounter++;

		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					clipRule="evenodd"
					d="M20.2865 4.80957V8.21498H22.0703V6.59335L20.2865 4.80957ZM13.3136 4.80957L11.5298 6.59335V8.21498H13.3136V4.80957Z"
					fill="#7B7B7B"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M13.3136 6.51227H20.2866V4.80957H13.3136V6.51227Z"
					fill="#5A5A5A"
					fillRule="evenodd"
				/>
				<mask
					height="19"
					id={'gsc-attrs-group-a-' + componentId}
					maskUnits="userSpaceOnUse"
					style={{ maskType: 'alpha' }}
					width="25"
					x="4"
					y="8"
				>
					<path
						clipRule="evenodd"
						d="M4.80005 8.21497H28.8V26.215H4.80005V8.21497Z"
						fill="white"
						fillRule="evenodd"
					/>
				</mask>
				<g mask={'url(#gsc-attrs-group-a-' + componentId + ')'}>
					<path
						clipRule="evenodd"
						d="M26.8541 26.215H6.746C5.67128 26.215 4.80005 25.3437 4.80005 24.269V11.5586L8.14367 8.21497H25.4564L28.8 11.5586V24.269C28.8 25.3437 27.9288 26.215 26.8541 26.215Z"
						fill="#E6E7E8"
						fillRule="evenodd"
					/>
				</g>
				<path
					clipRule="evenodd"
					d="M4.80005 15.1069V11.5586L8.14367 8.21497H25.4564L28.8 11.5586V15.1069H4.80005Z"
					fill="#D0D1D2"
					fillRule="evenodd"
				/>
				<mask
					height="16"
					id={'gsc-attrs-group-b-' + componentId}
					maskUnits="userSpaceOnUse"
					style={{ maskType: 'alpha' }}
					width="20"
					x="7"
					y="11"
				>
					<path
						clipRule="evenodd"
						d="M7.55676 11.6204H26.0432V26.215H7.55676V11.6204Z"
						fill="white"
						fillRule="evenodd"
					/>
				</mask>
				<g mask={'url(#gsc-attrs-group-b-' + componentId + ')'}>
					<path
						clipRule="evenodd"
						d="M7.55676 26.215V12.4312C7.55676 11.9834 7.91977 11.6204 8.36757 11.6204H25.2324C25.6802 11.6204 26.0432 11.9834 26.0432 12.4312V26.215H7.55676Z"
						fill="#458CF5"
						fillRule="evenodd"
					/>
				</g>
				<mask
					height="23"
					id={'gsc-attrs-group-c-' + componentId}
					maskUnits="userSpaceOnUse"
					style={{ maskType: 'alpha' }}
					width="25"
					x="4"
					y="4"
				>
					<path
						clipRule="evenodd"
						d="M4.80005 26.215H28.8V4.80957H4.80005V26.215Z"
						fill="white"
						fillRule="evenodd"
					/>
				</mask>
				<g mask={'url(#gsc-attrs-group-c-' + componentId + ')'}>
					<path
						clipRule="evenodd"
						d="M7.55676 26.215H26.0432V15.1069H7.55676V26.215Z"
						fill="white"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M20.5298 26.215V22.8906L18.9082 21.269L17.8541 18.3501L18.5839 16.4852L22.719 20.6204L23.4487 17.5393L26.0433 20.1339V26.215H20.5298Z"
						fill="#D2D3D4"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M8.77307 26.215H16.719V24.8366H8.77307V26.215ZM8.77307 23.2961H14.9352V16.4042H8.77307V23.2961Z"
						fill="#D2D3D4"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M22.0703 23.6285V26.215H18.6649V23.6652C16.6471 22.7469 15.7559 20.3667 16.6742 18.349C17.0629 17.4948 17.7386 16.8039 18.5838 16.3962V20.215L20.2865 21.269L22.0703 20.215V16.3961C24.0675 17.3595 24.9055 19.7595 23.9421 21.7567C23.5477 22.5743 22.8879 23.2341 22.0703 23.6285Z"
						fill="#505050"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M11.4893 12.5123C11.8699 12.5123 12.1785 12.8208 12.1785 13.2015C12.1785 13.5821 11.8699 13.8906 11.4893 13.8906C11.1087 13.8906 10.8001 13.5821 10.8001 13.2015C10.8001 12.8208 11.1087 12.5123 11.4893 12.5123ZM9.46227 12.5123C9.84289 12.5123 10.1514 12.8208 10.1514 13.2015C10.1514 13.5821 9.84289 13.8906 9.46227 13.8906C9.08163 13.8906 8.77307 13.5821 8.77307 13.2015C8.77307 12.8208 9.08163 12.5123 9.46227 12.5123Z"
						fill="#E6E7E8"
						fillRule="evenodd"
					/>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default GoogleSearchConsoleIconBuilder;
