import React from 'react';

import CK from '~/types/contentking';

import {
	CheckboxFieldSize,
} from '~/components/atoms/forms/components/CheckboxField';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import CheckboxInGroup from '~/components/logic/checkboxGroup/CheckboxInGroup';
import Colorizer, {
	ColorizerStyle,
} from '~/components/patterns/utils/Colorizer';
import ColumnFilter, {
	type ColumnFilterRef,
} from '~/components/logic/filters/ColumnFilter';
import ColumnName from '~/components/names/ColumnName';
import ColumnSegmentFilter from '~/components/logic/segments/filters/ColumnSegmentFilter';
import DistributionFilter from '~/components/logic/filters/DistributionFilter';
import ExpandableFilterOperatorHeaderLabel from '~/components/logic/filters/ExpandableFilterOperatorHeaderLabel';
import FilterFieldLayout from '~/components/patterns/filtering/FilterFieldLayout';
import HealthIcon from '~/components/patterns/icons/HealthIcon';
import RelevanceHint from '~/components/logic/interfaceHints/RelevanceHint';
import TableLabel, {
	TableLabelActiveSortingState,
} from '~/components/patterns/tables/datatables/parts/TableLabel';
import WebVitalName from '~/components/app/WebVitalName';

import useDatatableContext from '~/hooks/useDatatableContext';
import useFormContext from '~/hooks/useFormContext';
import useGetFilterType from '~/hooks/useGetFilterType';

import {
	CHANGES_IN_ALL_SELECTED_COLUMNS,
	CHANGES_IN_ANY_SELECTED_COLUMN,
} from '~/model/historicalChanges';

import {
	isTrackedChangesColumnWhichWarrantsComparison,
} from '~/model/pagesColumns';

import {
	FILTER_TYPE_CUSTOM_STRING,
	FILTER_TYPE_STRING,
	FILTER_TYPE_STRING_NON_NULLABLE,
} from '~/model/pagesColumnsFiltering';

import {
	type SegmentDefinition,
} from '~/model/segments';

import {
	WebVital,
} from '~/model/webVitals';



export type ColumnHeaderRef = {
	updateFilterValue: (value: any) => void,
};



type Props = {
	appendix?: React.ReactElement,
	columnCount: number,
	columnIndex: number,
	columnName: CK.PagesColumn,
	columnWidth: number,
	disabledTooltip?: React.ReactNode,
	distributions?: Record<string, any>,
	filter: any,
	isDisabled?: boolean,
	isSegmentFilterActive: boolean,
	onFilterChangeCallback: (filter: any) => void,
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	segmentsNotAllowedForFiltering?: Record<string, React.ReactNode>,
	sortCallback: (sorting: {
		key: CK.PagesColumn,
		direction: boolean,
	}) => void,
	sortingActive: boolean,
	sortingDirection: boolean,
	sortingEnabled: boolean,
	sortingFixed: boolean,
	sortingFlipped: boolean,
	style?: any,
};

const NewColumnHeader = React.forwardRef<ColumnHeaderRef, Props>((props, ref) => {
	const {
		appendix,
		columnCount,
		columnIndex,
		columnName,
		columnWidth,
		distributions,
		filter,
		isDisabled = false,
		isSegmentFilterActive,
		onFilterChangeCallback,
		segmentDefinitions,
		segmentsNotAllowedForFiltering,
		sortCallback,
		sortingActive,
		sortingDirection,
		sortingEnabled,
		sortingFixed,
		sortingFlipped,
		disabledTooltip,
	} = props;

	const filterRef = React.useRef<ColumnFilterRef>(null);

	const datatableContext = useDatatableContext();
	const formContext = useFormContext();
	const getFilterType = useGetFilterType();

	React.useEffect(
		() => {
			if (
				columnName === formContext.focused
				&& datatableContext.isColumnVisible
				&& datatableContext.scrollToColumn
				&& !datatableContext.isColumnVisible(columnName)
			) {
				datatableContext.scrollToColumn(formContext.focused);
			}
		},
		[
			columnName,
			datatableContext,
			formContext.focused,
		],
	);

	React.useImperativeHandle(ref, () => ({
		updateFilterValue: (value) => {
			if (!filterRef.current) {
				return;
			}

			filterRef.current.changeValue(value);
		},
	}));

	const handleSort = React.useCallback(
		() => {
			if (sortingEnabled && sortingFixed === false) {
				sortCallback({
					key: columnName,
					direction: sortingActive
						? (sortingDirection === false)
						: (sortingFlipped === false),
				});
			}
		},
		[
			columnName,
			sortCallback,
			sortingActive,
			sortingDirection,
			sortingEnabled,
			sortingFixed,
			sortingFlipped,
		],
	);

	function renderLabel() {
		let icon: React.ReactNode = null;

		if (columnName === CK.PagesCommonColumn.Segments) {
			icon = (
				<BasicIcon
					size={14}
					type={BasicIconType.Segment}
				/>
			);
		}

		if (columnName === CK.PagesCommonColumn.Health) {
			icon = (
				<HealthIcon />
			);
		}

		let activeSortingState = TableLabelActiveSortingState.NoSorting;

		if (sortingActive && sortingDirection) {
			activeSortingState = TableLabelActiveSortingState.Ascending;
		}

		if (sortingActive && sortingDirection === false) {
			activeSortingState = TableLabelActiveSortingState.Descending;
		}

		let labelOperator: React.ReactNode;

		const filterType = getFilterType(columnName);

		if (
			filterType === FILTER_TYPE_CUSTOM_STRING
			|| filterType === FILTER_TYPE_STRING
			|| filterType === FILTER_TYPE_STRING_NON_NULLABLE
		) {
			labelOperator = (
				<ExpandableFilterOperatorHeaderLabel
					value={filter.get(columnName)}
				/>
			);
		}

		let checkbox: React.ReactNode;

		if (
			(filter.get('changes_in') === CHANGES_IN_ALL_SELECTED_COLUMNS || filter.get('changes_in') === CHANGES_IN_ANY_SELECTED_COLUMN)
			&& isTrackedChangesColumnWhichWarrantsComparison(columnName)
		) {
			checkbox = (
				<CheckboxInGroup
					name={columnName}
					size={CheckboxFieldSize.Small}
				/>
			);
		}

		let label = (
			<ColumnName
				column={columnName}
			/>
		);

		if (columnName === 'change_type') {
			label = (
				<Colorizer style={ColorizerStyle.History}>
					{label}
				</Colorizer>
			);
		}

		if (
			columnName === CK.PagesCommonColumn.LighthouseCumulativeLayoutShift
			|| columnName === CK.PagesCommonColumn.LighthouseFirstContentfulPaint
			|| columnName === CK.PagesCommonColumn.LighthouseLargestContentfulPaint
			|| columnName === CK.PagesCommonColumn.LighthouseSpeedIndex
			|| columnName === CK.PagesCommonColumn.LighthouseTimeToInteractive
			|| columnName === CK.PagesCommonColumn.LighthouseTotalBlockingTime
		) {
			const type = {
				[CK.PagesCommonColumn.LighthouseCumulativeLayoutShift]: WebVital.CLS,
				[CK.PagesCommonColumn.LighthouseFirstContentfulPaint]: WebVital.FCP,
				[CK.PagesCommonColumn.LighthouseLargestContentfulPaint]: WebVital.LCP,
				[CK.PagesCommonColumn.LighthouseSpeedIndex]: WebVital.SI,
				[CK.PagesCommonColumn.LighthouseTimeToInteractive]: WebVital.TTI,
				[CK.PagesCommonColumn.LighthouseTotalBlockingTime]: WebVital.TBT,
			}[columnName];

			label = (
				<WebVitalName
					abbreviated={true}
					showFullNameOnHover={true}
					type={type}
				/>
			);
		}

		return (
			<TableLabel
				activeSortingState={activeSortingState}
				disabledTooltip={disabledTooltip}
				isDisabled={isDisabled}
				isSortable={sortingEnabled}
				label={label}
				labelCheckbox={checkbox}
				labelIcon={icon}
				labelOperator={labelOperator}
				onClickCallback={(sortingEnabled && sortingFixed === false) ? handleSort : undefined}
				reversedSorting={sortingFlipped}
				suffix={appendix}
			/>
		);
	}

	let label = (
		<FilterFieldLayout
			distributionBarChart={(
				distributions !== undefined ? (
					<DistributionFilter
						columnName={columnName}
						distributions={distributions}
						onFilterChangeCallback={onFilterChangeCallback}
					/>
				) : undefined
			)}
			field={(
				<ColumnFilter
					columnName={columnName}
					columnWidth={columnWidth}
					isAtRightEdge={columnIndex + 1 === columnCount}
					ref={filterRef}
					segmentDefinitions={segmentDefinitions}
					segmentsNotAllowedForFiltering={segmentsNotAllowedForFiltering}
				/>
			)}
			filteringFlag={isSegmentFilterActive && (
				<ColumnSegmentFilter
					columnName={columnName}
					segmentDefinitions={segmentDefinitions}
					segmentFilter={filter.get('segments')}
				/>
			)}
			label={renderLabel()}
		/>
	);

	if (columnName === CK.PagesCommonColumn.Relevance) {
		label = (
			<RelevanceHint>
				{label}
			</RelevanceHint>
		);
	}

	return label;
});



export default React.memo(NewColumnHeader);
