/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSendingOfAlertsAndReportsToInvitedUserMutationVariables = GraphQL.Exact<{
  invitationId: GraphQL.Scalars['InvitationId']['input'];
  websites: ReadonlyArray<GraphQL.UpdateSendingOfAlertsAndReportsToInvitedUserWebsite> | GraphQL.UpdateSendingOfAlertsAndReportsToInvitedUserWebsite;
}>;


export type UpdateSendingOfAlertsAndReportsToInvitedUserMutation = { readonly __typename?: 'Mutation', readonly UpdateSendingOfAlertsAndReportsToInvitedUser: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly id: CK.InvitationId, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> }> } | null } } };


export const UpdateSendingOfAlertsAndReportsToInvitedUserDocument = gql`
    mutation UpdateSendingOfAlertsAndReportsToInvitedUser($invitationId: InvitationId!, $websites: [UpdateSendingOfAlertsAndReportsToInvitedUserWebsite!]!) {
  UpdateSendingOfAlertsAndReportsToInvitedUser(
    invitationId: $invitationId
    websites: $websites
  ) {
    query {
      authenticatedSession {
        accounts {
          id
          invitations {
            id
            websiteEmailSettings {
              alertEmailsSetup
              isWeeklyReportEnabled
              websiteId
            }
            websites {
              id
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateSendingOfAlertsAndReportsToInvitedUserMutationFn = Apollo.MutationFunction<UpdateSendingOfAlertsAndReportsToInvitedUserMutation, UpdateSendingOfAlertsAndReportsToInvitedUserMutationVariables>;

/**
 * __useUpdateSendingOfAlertsAndReportsToInvitedUserMutation__
 *
 * To run a mutation, you first call `useUpdateSendingOfAlertsAndReportsToInvitedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendingOfAlertsAndReportsToInvitedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendingOfAlertsAndReportsToInvitedUserMutation, { data, loading, error }] = useUpdateSendingOfAlertsAndReportsToInvitedUserMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      websites: // value for 'websites'
 *   },
 * });
 */
export function useUpdateSendingOfAlertsAndReportsToInvitedUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSendingOfAlertsAndReportsToInvitedUserMutation, UpdateSendingOfAlertsAndReportsToInvitedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSendingOfAlertsAndReportsToInvitedUserMutation, UpdateSendingOfAlertsAndReportsToInvitedUserMutationVariables>(UpdateSendingOfAlertsAndReportsToInvitedUserDocument, options);
      }
export type UpdateSendingOfAlertsAndReportsToInvitedUserMutationHookResult = ReturnType<typeof useUpdateSendingOfAlertsAndReportsToInvitedUserMutation>;
export type UpdateSendingOfAlertsAndReportsToInvitedUserMutationResult = Apollo.MutationResult<UpdateSendingOfAlertsAndReportsToInvitedUserMutation>;
export type UpdateSendingOfAlertsAndReportsToInvitedUserMutationOptions = Apollo.BaseMutationOptions<UpdateSendingOfAlertsAndReportsToInvitedUserMutation, UpdateSendingOfAlertsAndReportsToInvitedUserMutationVariables>;