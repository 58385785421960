import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DeliveryIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M22.566,4H11.934A1.47,1.47,0,0,0,10.5,5.5v9.777a.249.249,0,0,0,.115.21,2.993,2.993,0,0,1,1.376,2.282A.251.251,0,0,0,12.24,18h3.52a.25.25,0,0,0,.249-.231,3,3,0,0,1,5.982,0A.251.251,0,0,0,22.24,18H23.5a.5.5,0,0,0,.5-.5V5.5A1.47,1.47,0,0,0,22.566,4Z"
				fill={color}
			/>
			<path
				d="M0,16.5a2,2,0,0,0,2,2H5.763a.251.251,0,0,0,.249-.275C6.005,18.151,6,18.076,6,18a3,3,0,0,1,2.769-2.991A.251.251,0,0,0,9,14.76V7.5a1,1,0,0,0-1-1H5a5.006,5.006,0,0,0-5,5Zm5-8H6.75A.25.25,0,0,1,7,8.75v2.5a.25.25,0,0,1-.25.25H2.261a.25.25,0,0,1-.249-.273A3,3,0,0,1,5,8.5Z"
				fill={color}
			/>
			<circle
				cx="9"
				cy="18"
				fill={color}
				r="2"
			/>
			<circle
				cx="19"
				cy="18"
				fill={color}
				r="2"
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default DeliveryIconBuilder;
