import type CK from '~/types/contentking';

import {
	useWebsitePageCapacityQuery,
} from './useWebsitePageCapacity.gql';

import useBatchContext from '~/hooks/useBatchContext';



function useWebsitePageCapacity(websiteId: CK.WebsiteId | null): number | null {
	const { data } = useWebsitePageCapacityQuery({
		context: useBatchContext('websites', 50),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return data?.website?.pageCapacity ?? null;
}



export default useWebsitePageCapacity;
