import {
	Map,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import TableLabel, {
	TableLabelActiveSortingState,
} from '~/components/patterns/tables/datatables/parts/TableLabel';



class HeaderLabel extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleSort = this._handleSort.bind(this);
	}



	_handleSort(e) {
		const {
			sorting,
		} = this.props;

		if (e) {
			e.preventDefault();
		}

		const sortBy = this._getSortBy();

		sorting.callback({
			key: sorting.name,
			direction: sorting.name === sortBy.key
				? !sortBy.direction
				: !sorting.flipped,
		});
	}



	_getSortBy() {
		const {
			sorting,
		} = this.props;

		return sorting.status instanceof Map
			? sorting.status.toJS()
			: sorting.status;
	}



	render() {
		const {
			appendix,
			children,
			icon,
			labelOperator,
			sorting,
		} = this.props;

		const additionalOptions = {};

		if (sorting) {
			const sortBy = this._getSortBy();

			additionalOptions.isSortable = true;
			additionalOptions.onClickCallback = this._handleSort;

			if (sortBy.key === sorting.name) {
				additionalOptions.activeSortingState = sortBy.direction ? TableLabelActiveSortingState.Ascending : TableLabelActiveSortingState.Descending;
			}

			additionalOptions.reversedSorting = sorting.flipped;
		}

		if (labelOperator) {
			additionalOptions.labelOperator = labelOperator;
		}

		return (
			<TableLabel
				label={children}
				labelIcon={icon}
				suffix={appendix}
				{...additionalOptions}
			/>
		);
	}

}

HeaderLabel.propTypes = {
	appendix: PropTypes.node,
	icon: PropTypes.node,
	labelOperator: PropTypes.node,
	sorting: PropTypes.shape({
		callback: PropTypes.func.isRequired,
		flipped: PropTypes.bool.isRequired,
		name: PropTypes.string.isRequired,
		status: PropTypes.object.isRequired,
	}),
};



export default HeaderLabel;
