import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import {
	loadTeamBillingDetails,
} from '~/actions/teams';

import {
	teamsBillingSelector,
} from '~/state/teams/selectors';

import {
	teamSelector,
} from '~/state/ui/selectors';



const select = createSelector(
	teamSelector,
	teamsBillingSelector,
	(account, allBillingDetails) => {
		return {
			account,
			billingDetails: account ? allBillingDetails.get(account.get('id') + '') : null,
		};
	},
);



class WithBillingDetails extends Component {

	componentDidMount() {
		const {
			account,
			dispatch,
		} = this.props;

		if (account && account.get('has_billing_details')) {
			dispatch(
				loadTeamBillingDetails(
					account.get('id'),
				),
			);
		}
	}



	componentDidUpdate({
		account: prevAccount,
	}) {
		const {
			dispatch,
			account: nextAccount,
			billingDetails: nextBillingDetails,
		} = this.props;

		if (
			(
				!prevAccount
				&& nextAccount
			)
			|| (
				prevAccount
				&& nextAccount
				&& prevAccount.get('id') !== nextAccount.get('id')
			)
			|| (
				prevAccount
				&& nextAccount
				&& prevAccount.get('id') === nextAccount.get('id')
				&& prevAccount.get('has_billing_details') !== nextAccount.get('has_billing_details')
			)
			|| (
				nextAccount
				&& (
					!nextBillingDetails
					|| !nextBillingDetails.get('discounts')
				)
			)
		) {
			dispatch(
				loadTeamBillingDetails(
					nextAccount.get('id'),
				),
			);
		}
	}



	_renderChildren(billingDetails) {
		const {
			children,
		} = this.props;

		return typeof children === 'function'
			? children({ billingDetails })
			: children;
	}



	render() {
		const {
			account,
			billingDetails,
			placeholder,
			useChildrenAsPlaceholder,
		} = this.props;

		if (!account) {
			return placeholder || (useChildrenAsPlaceholder ? this._renderChildren() : false);
		}

		if (account.get('has_billing_details') && billingDetails) {
			return (
				<>{this._renderChildren(billingDetails)}</>
			);
		}

		return placeholder || (useChildrenAsPlaceholder ? this._renderChildren() : false);
	}

}

const ConnectedWithBillingDetails = connect(select)(WithBillingDetails);

ConnectedWithBillingDetails.propTypes = {
	account: PropTypes.object,
	billingDetails: PropTypes.object,
	placeholder: PropTypes.node,
	useChildrenAsPlaceholder: PropTypes.bool.isRequired,
};



export default ConnectedWithBillingDetails;
