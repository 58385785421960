import React from 'react';
import {
	FormattedMessage,
} from 'react-intl';

import ColumnName from '~/components/names/ColumnName';



const IncidentTitle = (props) => {
	const {
		incident,
	} = props;

	const id = 'alerts.types.' + incident.get('type') + '.title';
	const values = (incident.get('settings') && incident.get('settings').size > 0)
		? incident.get('settings').toJS()
		: {};

	if (values.property) {
		if (values.customElementName) {
			values.propertyName = values.customElementName;
		} else {
			values.propertyName = (
				<ColumnName
					column={values.property}
				/>
			);
		}
	}

	return (
		<FormattedMessage
			id={id}
			values={values}
		/>
	);
};



export default IncidentTitle;
