import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import DefinitionTermValueSkeleton from '~/components/logic/pageDetail/components/DefinitionTermValueSkeleton';
import EnrichmentFieldFormatter from '~/components/logic/formatters/EnrichmentFieldFormatter';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LinkTarget from '~/components/logic/LinkTarget';
import MissingValue from '~/components/app/MissingValue';
import RealtimeValueUpdateHighlight from '~/components/patterns/values/RealtimeValueUpdateHighlight';
import RichText from '~/components/patterns/typography/RichText';

import {
	useEnrichmentFieldsBlockQuery,
} from './EnrichmentFieldsBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	noDefinitionsLink: {
		id: 'ui.pageDetail.enrichmentFields.noDefinitions.link',
	},
	noDefinitionsText: {
		id: 'ui.pageDetail.enrichmentFields.noDefinitions.text',
	},
	title: {
		id: 'ui.pageDetail.enrichmentFields.title',
	},
});



const EnrichmentFieldsBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	const { data } = usePageDetailPropertiesQuery(
		useEnrichmentFieldsBlockQuery,
		legacyUrlId,
		websiteId,
	);

	const enrichmentFieldsData = data?.lookupPageByLegacyId?.enrichmentFields ?? null;

	return (
		<BorderedBox
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.EnrichmentFields}
				/>
			)}
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			paddingSize={3}
		>
			{enrichmentFieldDefinitions.count > 0 && (
				<DefinitionTermsList>
					{enrichmentFieldDefinitions.listAll().map((enrichmentFieldDefinition) => {
						const enrichmentFieldId = enrichmentFieldDefinition.id;
						const enrichmentFieldData = enrichmentFieldsData?.find((enrichmentFieldData) => enrichmentFieldData.type.id === enrichmentFieldId) ?? null;

						const enrichmentFieldIsProvided = enrichmentFieldData?.isProvided === true;
						const enrichmentFieldValue = enrichmentFieldData?.value ?? null;

						return (
							<DefinitionTerm
								description={enrichmentFieldsData !== null ? (
									<RealtimeValueUpdateHighlight
										value={enrichmentFieldValue}
									>
										<EnrichmentFieldFormatter
											column={enrichmentFieldDefinition.column}
											useTextInspector={true}
											value={{
												isProvided: enrichmentFieldIsProvided,
												value: enrichmentFieldValue,
											}}
										/>
									</RealtimeValueUpdateHighlight>
								) : (
									<DefinitionTermValueSkeleton />
								)}
								key={enrichmentFieldId}
								term={enrichmentFieldDefinition.label}
							/>
						);
					})}
				</DefinitionTermsList>
			)}

			{enrichmentFieldDefinitions.isLoaded && enrichmentFieldDefinitions.count === 0 && (
				<RichText>
					<MissingValue>
						<FormattedMessage
							{...messages.noDefinitionsText}
							values={{
								text__link: (
									<LinkTarget
										routeName="website.detail.enrichmentFields"
										routeParams={{
											action: 'create_enrichment_field',
											websiteId,
										}}
									>
										{({ routeName, routeParams }) => (
											<InternalLink
												routeName={routeName}
												routeParams={routeParams}
												style={InternalLinkStyle.Decent}
											>
												<FormattedMessage {...messages.noDefinitionsLink} />
											</InternalLink>
										)}
									</LinkTarget>
								),
							}}
						/>
					</MissingValue>
				</RichText>
			)}
		</BorderedBox>
	);
};



export default EnrichmentFieldsBlock;
