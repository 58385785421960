import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import ExternalLink from '~/components/patterns/links/ExternalLink';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';
import RichText from '~/components/patterns/typography/RichText';

import useAccountId from '~/hooks/useAccountId';
import useRoyalMode from '~/hooks/useRoyalMode';

import {
	userInfoSelector,
} from '~/state/authentication/selectors';



const messages = defineMessages({
	description: {
		id: 'ui.customElements.offerSetupService',
	},
});



const OfferSetupService: React.FC = () => {
	const accountId = useAccountId() ?? '';
	const royalMode = useRoyalMode();
	const userInfo = useSelector(userInfoSelector);

	const royaltyParameters = royalMode.isImpersonated === true
		? `&isroyaladmin=yes&royalemail=${royalMode.email}`
		: '';

	const userId = userInfo?.get('intercom_user_id') ?? '';

	return (
		<InterfaceMessage>
			<RichText>
				<FormattedMessage
					{...messages.description}
					values={{
						link_survey: (chunks) => (
							<ExternalLink href={`https://contentkingseo.typeform.com/to/E851soQ5#accountid=${accountId}&userid=${userId}${royaltyParameters}`}>
								{chunks}
							</ExternalLink>
						),
					}}
				/>
			</RichText>
		</InterfaceMessage>
	);
};



export default OfferSetupService;
