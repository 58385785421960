import classNames from 'classnames';
import React from 'react';



type Props = {
	chart?: React.ReactNode,
	/** Make header clickable */
	onHeaderClick?: () => void,
	overview: React.ReactNode,
	title: React.ReactNode,
};



const WebsiteStatisticsSheet: React.FC<Props> = (props) => {
	const {
		chart,
		onHeaderClick,
		overview,
		title,
	} = props;

	const headerClasses = classNames({
		'website-stats-sheet__header': true,
		'website-stats-sheet__header--is-clickable': onHeaderClick,
	});

	return (
		<div className="website-stats-sheet">
			<div
				className={headerClasses}
				onClick={onHeaderClick}
			>
				<div className="website-stats-sheet__title">
					{title}
				</div>
			</div>

			<div className="website-stats-sheet__overview">
				{overview}
			</div>

			{chart && (
				<div
					className="website-stats-sheet__chart"
					style={{
						['--bg' as any]: '#f8f9fa',
					}}
				>
					{chart}
				</div>
			)}
		</div>
	);
};



export default WebsiteStatisticsSheet;
