import React from 'react';



const HeaderButtonsLayout = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="header-buttons-layout">
			{children}
		</div>
	);
};



export default HeaderButtonsLayout;
