import React, {
	Component,
} from 'react';

import ConductorLogo from '~/components/patterns/images/ConductorLogo';



type Props = {
	/** Attached call-to-action button */
	ctaButton?: React.ReactNode,
	/** Form element */
	form?: React.ReactNode,
	/** Description */
	children: React.ReactNode,
};



class GuideKevin extends Component<Props> {

	_renderFormPart() {
		const {
			form,
		} = this.props;

		if (!form) {
			return false;
		}

		return (
			<div className="guided-tour__form">
				{form}
			</div>
		);
	}



	_renderCTAButtonPart() {
		const {
			ctaButton,
		} = this.props;

		if (!ctaButton) {
			return false;
		}

		return (
			<div className="guided-tour__cta-button">
				{ctaButton}
			</div>
		);
	}



	render() {
		const {
			children,
		} = this.props;

		return (
			<div className="guided-tour">
				<div className="guided-tour__logo">
					<ConductorLogo />
				</div>
				<div className="guided-tour__content">
					<div className="typo guided-tour__text">
						{children}
					</div>

					{this._renderFormPart()}
					{this._renderCTAButtonPart()}
				</div>
			</div>
		);
	}

}



export default GuideKevin;
