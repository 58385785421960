import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Ellipsis from '~/components/patterns/values/Ellipsis';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';



const messages = defineMessages({
	element: {
		id: 'ui.schemaOrgInvalidJson.tableCell',
	},
	header: {
		id: 'ui.schemaOrgInvalidJson.tableHeader',
	},
});



type Props = {
	issueContext: {
		elements: ReadonlyArray<{
			position: number,
		}>,
	},
	tableWidth: number,
};

const InvalidSchemaOrgJsonTable: React.FC<Props> = (props) => {
	const {
		issueContext,
		tableWidth,
	} = props;

	return (
		<SmallTable
			columns={[
				{
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis>
									<FormattedMessage
										{...messages.element}
										values={{
											position: row.position,
										}}
									/>
								</Ellipsis>
							);
						},
						header: () => <FormattedMessage {...messages.header} />,
					},
					width: tableWidth,
				},
			]}
			rows={issueContext.elements}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(InvalidSchemaOrgJsonTable);
