import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';
import MultiselectFieldFilter from '../datatables/MultiselectFieldFilter';

import useFormContext from '~/hooks/useFormContext';

/* eslint-disable sort-imports */
import {
	ISSUE_CHANGE_CLOSED,
	ISSUE_CHANGE_IGNORED,
	ISSUE_CHANGE_OPENED,
	ISSUE_CHANGE_OPENED_NEW_PAGE,
	ISSUE_CHANGE_UNIGNORED,
	ISSUE_CHANGE_OPEN_NOT_APPLICABLE,
	ISSUE_CHANGE_OPEN_NOT_PAGE,
	ISSUE_CHANGE_NOT_APPLICABLE_POINTS,
	ISSUE_CHANGE_NOT_PAGE_POINTS,
	ISSUE_CHANGE_NOT_REQUIRED_POINTS,
	ISSUE_CHANGE_POINTS_NOT_APPLICABLE,
	ISSUE_CHANGE_POINTS_NOT_PAGE,
	ISSUE_CHANGE_POINTS_NOT_REQUIRED,
	ISSUE_CHANGE_OPEN_LESS_IMPORTANT,
	ISSUE_CHANGE_OPEN_MORE_IMPORTANT,
	ISSUE_CHANGE_POINTS_LESS_IMPORTANT,
	ISSUE_CHANGE_POINTS_MORE_IMPORTANT,
	ISSUE_CHANGE_BROKEN_ENTERED,
	ISSUE_CHANGE_BROKEN_LEFT,
	ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS,
	ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT,
	ISSUE_CHANGES_GROUPING,
	ISSUE_CHANGES_GROUP_IGNORING,
	ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE,
	ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES,
	ISSUE_CHANGES_GROUP_OPENED,
	ISSUE_CHANGES_GROUP_RESOLVED,
	ISSUE_CHANGES_GROUP_SEGMENT_CHANGED,
	ISSUE_CHANGES_GROUP_UNAFFECTED,
	LIST_OF_PRIMARY_ISSUE_CHANGES_GROUPS,
} from '~/model/affectedPagesComparison';
/* eslint-enable */



const issueChangeMessages = defineMessages({
	[ISSUE_CHANGE_CLOSED]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.closed',
	},
	[ISSUE_CHANGE_IGNORED]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.ignored',
	},
	[ISSUE_CHANGE_OPENED]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.opened',
	},
	[ISSUE_CHANGE_OPENED_NEW_PAGE]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.openedNewPage',
	},
	[ISSUE_CHANGE_UNIGNORED]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.unignored',
	},
	[ISSUE_CHANGE_OPEN_NOT_APPLICABLE]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.openNotApplicable',
	},
	[ISSUE_CHANGE_OPEN_NOT_PAGE]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.openNotPage',
	},
	[ISSUE_CHANGE_NOT_APPLICABLE_POINTS]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.notApplicablePoints',
	},
	[ISSUE_CHANGE_NOT_PAGE_POINTS]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.notPagePoints',
	},
	[ISSUE_CHANGE_NOT_REQUIRED_POINTS]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.notRequiredPoints',
	},
	[ISSUE_CHANGE_POINTS_NOT_APPLICABLE]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.pointsNotApplicable',
	},
	[ISSUE_CHANGE_POINTS_NOT_PAGE]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.pointsNotPage',
	},
	[ISSUE_CHANGE_POINTS_NOT_REQUIRED]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.pointsNotRequired',
	},
	[ISSUE_CHANGE_OPEN_LESS_IMPORTANT]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.openLessImportant',
	},
	[ISSUE_CHANGE_OPEN_MORE_IMPORTANT]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.openMoreImportant',
	},
	[ISSUE_CHANGE_POINTS_LESS_IMPORTANT]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.pointsLessImportant',
	},
	[ISSUE_CHANGE_POINTS_MORE_IMPORTANT]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.pointsMoreImportant',
	},
	[ISSUE_CHANGE_BROKEN_ENTERED]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.brokenEntered',
	},
	[ISSUE_CHANGE_BROKEN_LEFT]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.brokenLeft',
	},
	[ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.notInSegmentPoints',
	},
	[ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT]: {
		id: 'ui.affectedPagesComparison.filter.issueChange.pointsNotInSegment',
	},
});

const issueChangeGroupMessages = defineMessages({
	[ISSUE_CHANGES_GROUP_IGNORING]: {
		id: 'ui.affectedPagesComparison.filter.issueChangesGroup.ignoring',
	},
	[ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE]: {
		id: 'ui.affectedPagesComparison.filter.issueChangesGroup.noLongerApplicable',
	},
	[ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES]: {
		id: 'ui.affectedPagesComparison.filter.issueChangesGroup.onlyImportanceChanges',
	},
	[ISSUE_CHANGES_GROUP_OPENED]: {
		id: 'ui.affectedPagesComparison.filter.issueChangesGroup.opened',
	},
	[ISSUE_CHANGES_GROUP_RESOLVED]: {
		id: 'ui.affectedPagesComparison.filter.issueChangesGroup.resolved',
	},
	[ISSUE_CHANGES_GROUP_SEGMENT_CHANGED]: {
		id: 'ui.affectedPagesComparison.filter.issueChangesGroup.segmentChanged',
	},
	[ISSUE_CHANGES_GROUP_UNAFFECTED]: {
		id: 'ui.affectedPagesComparison.filter.issueChangesGroup.unaffected',
	},
});



function formatValue(value) {
	const result: Array<string> = [];

	value.forEach((issueChange) => {
		ISSUE_CHANGES_GROUPING.some((issueChanges: any, issueChangesGroup: any) => {
			if (issueChanges.includes(issueChange)) {
				if (issueChanges.size > 1) {
					result.push(issueChangesGroup + '_' + issueChange);
				} else {
					result.push(issueChange);
				}

				return true;
			}

			return false;
		});
	});

	return result;
}



const ISSUE_CHANGES_GROUP_WIDTHS = {
	[ISSUE_CHANGES_GROUP_IGNORING]: 80,
	[ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE]: 250,
	[ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES]: 350,
	[ISSUE_CHANGES_GROUP_OPENED]: 80,
	[ISSUE_CHANGES_GROUP_SEGMENT_CHANGED]: 320,
	[ISSUE_CHANGES_GROUP_UNAFFECTED]: 250,
};



type Props = {
	name: string,
	showSegmentChanges: boolean,
	width: number,
};

const IssueChangeFieldFilter = React.forwardRef<any, Props>((props, ref) => {
	const {
		name,
		showSegmentChanges,
		width,
	} = props;

	const formContext = useFormContext();

	const newFormContext = React.useMemo(
		() => {
			const onChangeHandler = (name, value, options) => {
				const issueChanges = value.map((valueItem) => {
					let issueChange;

					ISSUE_CHANGES_GROUPING.some((issueChanges: any, group: any) => {
						if (issueChanges.size > 1) {
							if (valueItem.indexOf(group) === 0) {
								issueChange = valueItem.substring(group.length + 1);

								return true;
							}
						} else {
							if (valueItem === issueChanges.first()) {
								issueChange = valueItem;

								return true;
							}
						}

						return false;
					});

					return issueChange;
				});

				return formContext.onChangeHandler(name, issueChanges, options);
			};


			return {
				...formContext,
				defaultValues: {
					[name]: formatValue(formContext.defaultValues[name] || []),
				},
				onChangeHandler,
				values: {
					[name]: formatValue(formContext.values[name] || []),
				},
			};
		},
		[
			formContext,
			name,
		],
	);

	const options: Array<{
		children?: Array<{
			name: string,
			title: React.ReactNode,
		} | {
			divider: true,
		}>,
		innerPanelWidth?: number,
		name: string,
		title: React.ReactNode,
	}> = [];

	ISSUE_CHANGES_GROUPING.forEach((issueChanges: any, issueChangesGroup: any) => {
		if (!showSegmentChanges && issueChangesGroup === ISSUE_CHANGES_GROUP_SEGMENT_CHANGED) {
			return;
		}

		if (issueChanges.size > 1) {
			const children: Exclude<(typeof options[0])['children'], undefined> = [];

			issueChanges.forEach((issueChange) => {
				children.push({
					name: issueChange,
					title: (
						<FormattedMessage {...issueChangeMessages[issueChange]} />
					),
				});

				if (
					issueChange === ISSUE_CHANGE_BROKEN_LEFT
					|| issueChange === ISSUE_CHANGE_OPEN_MORE_IMPORTANT
					|| issueChange === ISSUE_CHANGE_POINTS_NOT_APPLICABLE
					|| issueChange === ISSUE_CHANGE_POINTS_NOT_REQUIRED
				) {
					children.push({
						divider: true,
					});
				}
			});

			options.push({
				innerPanelWidth: ISSUE_CHANGES_GROUP_WIDTHS[issueChangesGroup],
				name: issueChangesGroup,
				title: (
					<FormattedMessage {...issueChangeGroupMessages[issueChangesGroup]} />
				),
				children,
			});
		} else {
			options.push({
				name: issueChanges.first(),
				title: (
					<FormattedMessage {...issueChangeMessages[issueChanges.first()]} />
				),
			});
		}
	});

	return (
		<FormContext.Provider value={newFormContext}>
			<MultiselectFieldFilter
				dropdownInnerPanelWidth={330}
				dropdownWidth={220}
				formatSelectedValues={formatValue}
				hideInnerOptions={true}
				isOnlyLinkVisible={true}
				name={name}
				options={options}
				ref={ref}
				visibleOptionsCount={LIST_OF_PRIMARY_ISSUE_CHANGES_GROUPS.size}
				width={width}
			/>
		</FormContext.Provider>
	);
});



export default IssueChangeFieldFilter;
