/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsiteMonitoringCookiesMutationVariables = GraphQL.Exact<{
  cookies: ReadonlyArray<GraphQL.UpdateWebsiteMonitoringCookiesCookie> | GraphQL.UpdateWebsiteMonitoringCookiesCookie;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteMonitoringCookiesMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteMonitoringCookies: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly cookies: ReadonlyArray<{ readonly __typename?: 'WebsiteFetchingSettingsCookie', readonly name: string, readonly value: string }> } | null } } };


export const UpdateWebsiteMonitoringCookiesDocument = gql`
    mutation UpdateWebsiteMonitoringCookies($cookies: [UpdateWebsiteMonitoringCookiesCookie!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteMonitoringCookies(cookies: $cookies, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        cookies {
          name
          value
        }
        id
      }
    }
  }
}
    `;
export type UpdateWebsiteMonitoringCookiesMutationFn = Apollo.MutationFunction<UpdateWebsiteMonitoringCookiesMutation, UpdateWebsiteMonitoringCookiesMutationVariables>;

/**
 * __useUpdateWebsiteMonitoringCookiesMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteMonitoringCookiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteMonitoringCookiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteMonitoringCookiesMutation, { data, loading, error }] = useUpdateWebsiteMonitoringCookiesMutation({
 *   variables: {
 *      cookies: // value for 'cookies'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteMonitoringCookiesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteMonitoringCookiesMutation, UpdateWebsiteMonitoringCookiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteMonitoringCookiesMutation, UpdateWebsiteMonitoringCookiesMutationVariables>(UpdateWebsiteMonitoringCookiesDocument, options);
      }
export type UpdateWebsiteMonitoringCookiesMutationHookResult = ReturnType<typeof useUpdateWebsiteMonitoringCookiesMutation>;
export type UpdateWebsiteMonitoringCookiesMutationResult = Apollo.MutationResult<UpdateWebsiteMonitoringCookiesMutation>;
export type UpdateWebsiteMonitoringCookiesMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteMonitoringCookiesMutation, UpdateWebsiteMonitoringCookiesMutationVariables>;