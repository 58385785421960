import type CK from '~/types/contentking';

import {
	useAccountWebsiteRestrictionsQuery,
} from './useAccountWebsiteRestrictions.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountWebsiteRestrictions(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountWebsiteRestrictionsQuery,
		accountId,
	);

	return data?.account?.websiteRestrictions ?? null;
}



export default useAccountWebsiteRestrictions;
