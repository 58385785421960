/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddOverridableBlacklistDomainsMutationVariables = GraphQL.Exact<{
  domains: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type AddOverridableBlacklistDomainsMutation = { readonly __typename?: 'Mutation', readonly AddOverridableBlacklistDomains: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly kingdomSettings: { readonly __typename?: 'KingdomSettings', readonly overridableBlacklistDomains: ReadonlyArray<string> } | null } } };


export const AddOverridableBlacklistDomainsDocument = gql`
    mutation AddOverridableBlacklistDomains($domains: [String!]!) {
  AddOverridableBlacklistDomains(domains: $domains) {
    query {
      kingdomSettings {
        overridableBlacklistDomains
      }
    }
  }
}
    `;
export type AddOverridableBlacklistDomainsMutationFn = Apollo.MutationFunction<AddOverridableBlacklistDomainsMutation, AddOverridableBlacklistDomainsMutationVariables>;

/**
 * __useAddOverridableBlacklistDomainsMutation__
 *
 * To run a mutation, you first call `useAddOverridableBlacklistDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOverridableBlacklistDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOverridableBlacklistDomainsMutation, { data, loading, error }] = useAddOverridableBlacklistDomainsMutation({
 *   variables: {
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useAddOverridableBlacklistDomainsMutation(baseOptions?: Apollo.MutationHookOptions<AddOverridableBlacklistDomainsMutation, AddOverridableBlacklistDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOverridableBlacklistDomainsMutation, AddOverridableBlacklistDomainsMutationVariables>(AddOverridableBlacklistDomainsDocument, options);
      }
export type AddOverridableBlacklistDomainsMutationHookResult = ReturnType<typeof useAddOverridableBlacklistDomainsMutation>;
export type AddOverridableBlacklistDomainsMutationResult = Apollo.MutationResult<AddOverridableBlacklistDomainsMutation>;
export type AddOverridableBlacklistDomainsMutationOptions = Apollo.BaseMutationOptions<AddOverridableBlacklistDomainsMutation, AddOverridableBlacklistDomainsMutationVariables>;