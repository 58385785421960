import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MoneyBagIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M9,8h6a1,1,0,0,0,0-2H9A1,1,0,0,0,9,8Z"
				fill={color}
			/>
			<path
				d="M9.471,4.5a.5.5,0,0,0,.5.438h4.064a.5.5,0,0,0,.5-.438,9.165,9.165,0,0,1,1.206-3.049.5.5,0,0,0-.638-.7l-1.862.8a.25.25,0,0,1-.33-.137l-.441-1.1a.5.5,0,0,0-.928,0l-.441,1.1a.25.25,0,0,1-.33.137L8.9.755a.5.5,0,0,0-.638.7A9.165,9.165,0,0,1,9.471,4.5Z"
				fill={color}
			/>
			<path
				d="M15.438,9.121A.591.591,0,0,0,15.081,9H8.919a.585.585,0,0,0-.357.121c-2.551,1.95-5.4,5.4-5.4,8.4C3.16,21.75,5.518,24,12,24s8.84-2.25,8.84-6.482C20.84,14.523,17.99,11.071,15.438,9.121ZM12.955,20.13a.25.25,0,0,0-.205.246v.374a.75.75,0,0,1-1.5,0v-.323a.25.25,0,0,0-.25-.25h-.588a.75.75,0,1,1,0-1.5h2.152a.671.671,0,0,0,.25-1.294l-2.185-.873a2.164,2.164,0,0,1,.416-4.14.25.25,0,0,0,.205-.246V11.75a.75.75,0,0,1,1.5,0v.323a.25.25,0,0,0,.25.25h.588a.75.75,0,1,1,0,1.5H11.436a.671.671,0,0,0-.25,1.294l2.185.873a2.164,2.164,0,0,1-.416,4.14Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MoneyBagIconBuilder;
