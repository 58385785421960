import Immutable from 'immutable';
import React from 'react';

import CK from '~/types/contentking';

import ColumnValueFormatter from '../formatters/ColumnValueFormatter';
import ConcatenateFormatter from '~/components/app/ConcatenateFormatter';
import Emphasis from '~/components/patterns/typography/Emphasis';
import {
	OPERATOR_CONTAINS,
	OPERATOR_EMPTY,
	OPERATOR_EMPTY_OR_MISSING,
	OPERATOR_ENDS_WITH,
	OPERATOR_EQUALS,
	OPERATOR_MATCHES,
	OPERATOR_MISSING,
	OPERATOR_NOT_CONTAINING,
	OPERATOR_NOT_MATCHES,
	OPERATOR_PRESENT,
	OPERATOR_STARTS_WITH,
} from '~/components/atoms/forms/components/ExpandableFilterField';
import ExpandableFilterOperatorName from '~/components/names/ExpandableFilterOperatorName';
import SegmentFilterElementsRow from '~/components/atoms/segments/filters/SegmentFilterElementsRow';
import SegmentFilterOperator, {
	OPERATOR_AND,
	OPERATOR_AND_NOT_IN,
	OPERATOR_NOT_IN,
	OPERATOR_OR,
} from '~/components/atoms/segments/filters/SegmentFilterOperator';
import SegmentFilterSegment from '~/components/atoms/segments/filters/SegmentFilterSegment';

import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	filterSegmentDefinitionsByNames,
} from '~/model/segments';



type Props = {
	columnName: CK.PagesColumn,
	value: any,
};

const ColumnFilterFormatter: React.FC<Props> = (props) => {
	const {
		columnName,
		value,
	} = props;

	if (columnName === CK.PagesCommonColumn.Segments) {
		return (
			<SegmentFilterFormatter value={value} />
		);
	}

	if (typeof value === 'boolean') {
		return (
			<ColumnValueFormatter
				column={columnName}
				customElements={true}
				value={value}
			/>
		);
	}

	if (
		value instanceof Array
		|| value instanceof Immutable.List
	) {
		return (
			<ConcatenateFormatter
				value={(value as Array<any>).map((itemValue, index) => (
					<ColumnValueFormatter
						column={columnName}
						customElements={true}
						key={index}
						value={itemValue}
					/>
				))}
			/>
		);
	}

	if (typeof value === 'string') {
		if (
			value.indexOf('<') === 0
			|| value.indexOf('>') === 0
			|| value.indexOf('=') === 0
		) {
			return value;
		} else if (
			value.includes(OPERATOR_CONTAINS)
			|| value.includes(OPERATOR_EMPTY)
			|| value.includes(OPERATOR_EMPTY_OR_MISSING)
			|| value.includes(OPERATOR_ENDS_WITH)
			|| value.includes(OPERATOR_EQUALS)
			|| value.includes(OPERATOR_MATCHES)
			|| value.includes(OPERATOR_NOT_MATCHES)
			|| value.includes(OPERATOR_MISSING)
			|| value.includes(OPERATOR_NOT_CONTAINING)
			|| value.includes(OPERATOR_PRESENT)
			|| value.includes(OPERATOR_STARTS_WITH)
		) {
			return (
				<StringOperatorFilterFormatter value={value} />
			);
		}

		return `= ${value}`;
	}

	return value;
};



type StringOperatorFilterFormatterProps = {
	value: string,
};

const StringOperatorFilterFormatter: React.FC<StringOperatorFilterFormatterProps> = (props) => {
	const {
		value,
	} = props;

	if (
		value === 'is:empty'
		|| value === 'is:empty_or_missing'
		|| value === 'is:present'
		|| value === 'is:missing'
	) {
		const operator = value.split(':')[1] ?? '';

		return (
			<Emphasis>
				<ExpandableFilterOperatorName operator={operator} />
			</Emphasis>
		);
	}

	const [operator, content] = value.split(':').map((x) => x.trim());

	return (
		<>
			<ExpandableFilterOperatorName operator={operator ?? ''} />
			{' '}
			{content}
		</>
	);
};



type SegmentFilterFormatterProps = {
	value: any,
};

const SegmentFilterFormatter: React.FC<SegmentFilterFormatterProps> = (props) => {
	const {
		value,
	} = props;

	const websiteId = useWebsiteId();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	if (segmentDefinitions.isLoaded === false) {
		return null;
	}

	const operator = value.get
		? value.get('operator')
		: value.operator;

	const result: Array<React.ReactElement> = [];

	const includedIn = filterSegmentDefinitionsByNames(
		segmentDefinitions.listAll(),
		value.get
			? value.get('included_in').toArray()
			: value.included_in,
	);

	includedIn.forEach((segmentDefinition, index) => {
		result.push((
			<SegmentFilterSegment
				color={segmentDefinition.color}
				key={'in_segment/' + index}
				label={includedIn.length === 1 ? segmentDefinition.label : undefined}
			/>
		));

		if (index !== includedIn.length - 1) {
			result.push((
				<SegmentFilterOperator
					key={'in_separator/' + index}
					operator={operator === 'and' ? OPERATOR_AND : OPERATOR_OR}
				/>
			));
		}
	});

	const notIncludedIn = filterSegmentDefinitionsByNames(
		segmentDefinitions.listAll(),
		value.get
			? value.get('not_included_in').toArray()
			: value.not_included_in,
	);

	if (notIncludedIn.length > 0) {
		result.push((
			<SegmentFilterOperator
				key="first_not_in"
				operator={includedIn.length > 0 ? OPERATOR_AND_NOT_IN : OPERATOR_NOT_IN}
			/>
		));
	}

	notIncludedIn.forEach((segmentDefinition, index) => {
		result.push((
			<SegmentFilterSegment
				color={segmentDefinition.color}
				key={'not_in_segment/' + index}
				label={notIncludedIn.length === 1 ? segmentDefinition.label : undefined}
			/>
		));

		if (index !== notIncludedIn.length - 1) {
			result.push((
				<SegmentFilterOperator
					key={'not_in_separator/' + index}
					operator={OPERATOR_AND_NOT_IN}
				/>
			));
		}
	});

	return (
		<SegmentFilterElementsRow>
			{result}
		</SegmentFilterElementsRow>
	);
};



export default ColumnFilterFormatter;
