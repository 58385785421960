/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelUserInvitationMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  email: GraphQL.Scalars['String']['input'];
}>;


export type CancelUserInvitationMutation = { readonly __typename?: 'Mutation', readonly CancelUserInvitation: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly id: CK.InvitationId }> } | null } } };


export const CancelUserInvitationDocument = gql`
    mutation CancelUserInvitation($accountId: AccountId!, $email: String!) {
  CancelUserInvitation(accountId: $accountId, email: $email) {
    query {
      account(id: $accountId) {
        id
        invitations {
          id
        }
      }
    }
  }
}
    `;
export type CancelUserInvitationMutationFn = Apollo.MutationFunction<CancelUserInvitationMutation, CancelUserInvitationMutationVariables>;

/**
 * __useCancelUserInvitationMutation__
 *
 * To run a mutation, you first call `useCancelUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUserInvitationMutation, { data, loading, error }] = useCancelUserInvitationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCancelUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CancelUserInvitationMutation, CancelUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelUserInvitationMutation, CancelUserInvitationMutationVariables>(CancelUserInvitationDocument, options);
      }
export type CancelUserInvitationMutationHookResult = ReturnType<typeof useCancelUserInvitationMutation>;
export type CancelUserInvitationMutationResult = Apollo.MutationResult<CancelUserInvitationMutation>;
export type CancelUserInvitationMutationOptions = Apollo.BaseMutationOptions<CancelUserInvitationMutation, CancelUserInvitationMutationVariables>;