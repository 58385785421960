import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class OverflowingList extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			ellipsisWidth: false,
			forcedContentWidth: false,
			hiddenItemBreakpointIndex: false,
		};
	}



	_getChildren() {
		const {
			children,
		} = this.props;

		return React.Children.toArray(children).filter((child) => !!child);
	}



	_detectEllipsis(wrapperWidth) {
		// default states
		const newStates = {
			ellipsisWidth: false,
			hiddenItemBreakpointIndex: false,
		};

		const elements = this._getChildren();
		let contentWidth = 0;

		if (!this.ellipsisRef) {
			return;
		}

		const ellipsisWidth = this.ellipsisRef.offsetWidth;
		newStates.ellipsisWidth = ellipsisWidth;

		for (let index = 0; index < elements.length; index++) {
			const itemWidth = this['item' + index + 'Ref']?.offsetWidth || 0;

			if (contentWidth + itemWidth > wrapperWidth - ellipsisWidth) {
				newStates.forcedContentWidth = contentWidth;
				newStates.hiddenItemBreakpointIndex = index;

				break;
			}

			contentWidth += itemWidth;
		}

		this.setState(newStates);
	}



	componentDidMount() {
		const {
			width,
		} = this.props;

		if (width) {
			this._detectEllipsis(width);
		}
	}



	componentDidUpdate({
		children: prevChildren,
		width: prevWidth,
	}) {
		const {
			children: nextChildren,
			width: nextWidth,
		} = this.props;

		if (prevWidth !== nextWidth || prevChildren.length !== nextChildren.length) {
			// reset forced width first before setting new values
			this.setState({
				ellipsisWidth: false,
				forcedContentWidth: false,
				hiddenItemBreakpointIndex: false,
			}, () => {
				this._detectEllipsis(nextWidth);
			});
		}
	}



	_renderEllipsis() {
		const {
			ellipsisRenderer,
		} = this.props;

		const {
			hiddenItemBreakpointIndex,
		} = this.state;

		const elements = this._getChildren();

		if (ellipsisRenderer) {
			return ellipsisRenderer({
				hiddenItemsCount: elements.length - hiddenItemBreakpointIndex,
			});
		}

		return (
			<span>&hellip;</span>
		);
	}



	_renderPlaceholder() {
		const {
			placeholder,
		} = this.props;

		if (!placeholder) {
			return false;
		}

		return (
			<div className="overflowing-list__placeholder">
				{placeholder}
			</div>
		);
	}



	render() {
		const {
			width,
		} = this.props;

		const {
			ellipsisWidth,
			forcedContentWidth,
			hiddenItemBreakpointIndex,
		} = this.state;

		const elements = this._getChildren();

		const containerClasses = classNames({
			'overflowing-list__container': true,
			'overflowing-list__container--hidden': !hiddenItemBreakpointIndex,
			'overflowing-list__container--has-forced-width': hiddenItemBreakpointIndex !== false,
		});

		const ellipsisClasses = classNames({
			'overflowing-list__ellipsis': true,
			'overflowing-list__ellipsis--hidden': (ellipsisWidth || !width) && hiddenItemBreakpointIndex === false,
		});

		return (
			<div
				className="overflowing-list"
				style={{
					width,
				}}
			>
				<div
					className={containerClasses}
					style={{
						width: forcedContentWidth,
					}}
				>
					{elements.map((child, index) => {
						const itemClasses = classNames({
							'overflowing-list__item': true,
							'overflowing-list__item--hidden': hiddenItemBreakpointIndex !== false && hiddenItemBreakpointIndex <= index,
						});

						return (
							<div
								className={itemClasses}
								key={'list-item-' + index}
								ref={(ref) => this['item' + index + 'Ref'] = ref}
							>
								{child}
							</div>
						);
					})}
				</div>

				<div
					className={ellipsisClasses}
					ref={(ref) => this.ellipsisRef = ref}
				>
					{this._renderEllipsis()}
				</div>

				{elements.length === 0 && this._renderPlaceholder()}
			</div>
		);
	}

}

OverflowingList.propTypes = {
	/** Custom renderer of ellipsis element */
	ellipsisRenderer: PropTypes.func,
	/** Placeholder visible when no children present */
	placeholder: PropTypes.node,
	/** Breakpoint width of the list. Depends on width we will calculate when to show ellipsis element. */
	width: PropTypes.number,
};

export default OverflowingList;
