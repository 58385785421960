import Immutable, {
	List,
	Map,
} from 'immutable';

import {
	MARK_INTERFACE_HINT_AS_DISPLAYED,
	SET_INTERFACE_HINT_AS_CLOSED,
	SET_INTERFACE_HINT_AS_OPENED,
} from '~/actions/interfaceHints';

import {
	LOGIN_SUCCESSFUL,
} from '~/actions';



const createDefaultState = () => {
	return Map({
		hints: List(),
		openedHint: false,
	});
};



export const interfaceHints = (state, action) => {
	if (state === undefined) {
		state = createDefaultState();
	}

	const {
		type,
	} = action;

	switch (type) {

		case MARK_INTERFACE_HINT_AS_DISPLAYED: {
			const {
				name,
			} = action;

			const newHints = state.get('hints').map((hint) => {
				if (hint.get('name') === name) {
					hint = hint.set('displayed', true);
				}

				return hint;
			});

			state = state.mergeIn(['hints'], newHints);

			break;
		}

		case SET_INTERFACE_HINT_AS_OPENED: {
			const {
				name,
			} = action;

			state = state.set('openedHint', name);

			break;
		}

		case SET_INTERFACE_HINT_AS_CLOSED: {
			state = state.set('openedHint', false);

			break;
		}

		case LOGIN_SUCCESSFUL: {
			const userInfo = action.response;

			const newHints = Immutable.fromJS(userInfo).get('hints') || false;
			if (!newHints) {
				break;
			}

			const currentHints = state;

			if (!Immutable.is(currentHints, newHints)) {
				state = state.set('hints', newHints);
			}

			break;
		}

	}

	return state;
};
