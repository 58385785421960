import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import AcademyBox from '~/components/patterns/boxes/AcademyBox';
import CodeValue from '~/components/patterns/values/CodeValue';
import Copy, {
	formatCopy,
} from '~/components/logic/Copy';
import InternalLink from '~/components/patterns/links/InternalLink';
import IssueText, {
	type IssueTextLearnWhyProps,
} from '~/components/atoms/issues/components/issueDetail/IssueText';
import LearnLink from '~/components/logic/links/LearnLink';
import Term from '~/components/patterns/typography/Term';
import WebsiteIssueConfigurationLinkRoute from '~/components/logic/linkRoutes/WebsiteIssueConfigurationLinkRoute';

import {
	EMPTY,
} from '~/localization/languages/constants';

import {
	getLearnLinksRelatedToIssue,
} from '~/model/issuesAcademy';

import {
	type IssueName,
} from '~/model/issuesNew';



const messages = defineMessages({
	modifiedSettingsHint: {
		id: 'ui.issues.issueInstructionChanged',
	},
	modifiedSettingsHintLink: {
		id: 'ui.issues.issueInstructionChanged.link',
	},
});



type Props = {
	issue: {
		configuration: Record<string, any>,
		isConfigurationModified: boolean,
		name: IssueName,
	},
};

const IssueInstruction: React.FC<Props> = (props) => {
	const {
		issue,
	} = props;

	const intl = useIntl();

	function renderLearnWhy() {
		const messageId = `issues.${issue.name}.learnWhy` as const;

		const learnWhyText = formatCopy(intl, {
			id: messageId,
		});

		if (learnWhyText === messageId || learnWhyText === EMPTY) {
			return null;
		}

		return ({ handleCloseCallback }: IssueTextLearnWhyProps) => (
			<AcademyBox
				links={getLearnLinksRelatedToIssue(issue.name).map((link) => (
					<LearnLink
						key={link}
						link={link}
					/>
				))}
				onCloseClickCallback={handleCloseCallback}
			>
				<Copy id={messageId} />
			</AcademyBox>
		);
	}

	const variables = { ...issue.configuration };

	if (Object.values(issue.configuration).length > 0 && issue.isConfigurationModified) {
		variables.configuration = (chunks) => (
			<b>
				<Term
					explanation={(
						<FormattedMessage
							{...messages.modifiedSettingsHint}
							values={{
								text__link: (
									<WebsiteIssueConfigurationLinkRoute>
										{({ linkRouteName, linkRouteParams }) => (
											<InternalLink
												routeName={linkRouteName}
												routeParams={linkRouteParams}
											>
												<FormattedMessage {...messages.modifiedSettingsHintLink} />
											</InternalLink>
										)}
									</WebsiteIssueConfigurationLinkRoute>
								),
							}}
						/>
					)}
				>
					{chunks}
				</Term>
			</b>
		);
	} else {
		variables.configuration = (chunks) => (
			<b>{chunks}</b>
		);
	}

	variables.code = (chunks) => (
		<CodeValue>{chunks}</CodeValue>
	);

	const output = formatCopy(intl, {
		id: `issues.${issue.name}.instruction`,
	}, variables);

	return (
		<IssueText learnWhy={renderLearnWhy()}>
			{output}
		</IssueText>
	);
};



export default IssueInstruction;
