import React from 'react';



type Props = {
	children?: React.ReactNode,
	language: string,
};

const HtmlLanguageProvider: React.FC<Props> = (props) => {
	const {
		children,
		language,
	} = props;

	return (
		<span lang={language}>
			{children}
		</span>
	);
};



export default HtmlLanguageProvider;
