import {
	format,
} from 'date-fns';
import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import useLoadTrackedChangesOnPages from '~/hooks/useLoadTrackedChangesOnPages';

import {
	UPDATE_HISTORICAL_CHANGES_INTERVAL,
} from '~/actions/historicalChanges';

import {
	isInHistoricalChangesModeSelector,
	filterSelector as trackedChangesFilterSelector,
	sortBySelector as trackedChangesSortBySelector,
} from '~/state/historicalChanges/selectors';

import {
	filterSelector as pagesFilterSelector,
	sortBySelector as pagesSortBySelector,
} from '~/state/ui/selectors';



function useUpdateTrackedChangesDateInterval() {
	const alreadyInChangeTracking = useSelector(isInHistoricalChangesModeSelector);
	const dispatch = useDispatch();
	const loadTrackedChangesOnPages = useLoadTrackedChangesOnPages();
	const pagesFilter = useSelector(pagesFilterSelector);
	const pagesSortBy = useSelector(pagesSortBySelector);
	const trackedChangesFilter = useSelector(trackedChangesFilterSelector);
	const trackedChangesSortBy = useSelector(trackedChangesSortBySelector);

	return React.useCallback(
		async (startDate: Date, endDate: Date) => {
			dispatch({
				type: UPDATE_HISTORICAL_CHANGES_INTERVAL,
				pagesFilter: alreadyInChangeTracking
					? trackedChangesFilter
					: pagesFilter,
				pagesSortBy: alreadyInChangeTracking
					? trackedChangesSortBy
					: pagesSortBy,
				startDate: format(startDate, 'yyyy-MM-dd'),
				endDate: format(endDate, 'yyyy-MM-dd'),
			});

			loadTrackedChangesOnPages();
		},
		[
			alreadyInChangeTracking,
			dispatch,
			loadTrackedChangesOnPages,
			pagesFilter,
			pagesSortBy,
			trackedChangesFilter,
			trackedChangesSortBy,
		],
	);
}



export default useUpdateTrackedChangesDateInterval;
