import classNames from 'classnames';
import React from 'react';

import Hint, {
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';

import styles from './NonPrintableSymbol.module.scss';

import touchSupported from '~/utilities/touchSupported';



export enum NonPrintableSymbolType {
	Newline = 'newline',
	Tab = 'tab',
	Unknown = 'unknown',
}

type Props = {
	/** Popup message visible when hovering or clicking on � icon */
	message?: React.ReactNode,
	type: NonPrintableSymbolType,
};



const NonPrintableSymbol: React.FC<Props> = (props) => {
	const {
		message,
		type,
	} = props;

	const _getSymbol = () => {
		const symbolClasses = classNames({
			[styles.component]: true,
			[styles['type' + type] ?? '']: true,
		});

		return (
			<span className={symbolClasses}>
				{type === NonPrintableSymbolType.Newline && '⮐'}
				{type === NonPrintableSymbolType.Tab && '⇥'}
				{type === NonPrintableSymbolType.Unknown && '�'}
			</span>
		);
	};

	if (message) {
		return (
			<Hint
				className={styles.hint}
				popup={message}
				popupOffset={({ placement }) => {
					if (placement === 'bottom-start' || placement === 'top-start') {
						return [-5, 0];
					}

					if (placement === 'bottom-end' || placement === 'top-end') {
						return [5, 0];
					}

					return [0, 0];
				}}
				popupVisibility={touchSupported ? HintPopupVisibility.OnClick : HintPopupVisibility.OnHover}
				popupZIndex={3000}
			>
				{_getSymbol()}
			</Hint>
		);
	}

	return _getSymbol();
};



export default NonPrintableSymbol;
