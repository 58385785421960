import classNames from 'classnames';
import React from 'react';



type FieldDropdownClickableOptionsOptionProps = {
	description?: React.ReactNode,
	disabled?: boolean,
	divider?: never,
	ellipsis?: boolean,
	highlighted?: boolean,
	icon?: React.ReactNode,
	name: string,
	label: React.ReactNode,
	selected?: boolean,
	type?: FieldDropdownClickableOptionsOptionType,
};

type FieldDropdownClickableOptionsDividerProps = {
	divider: boolean,
};

export type FieldDropdownClickableOptionsProps = FieldDropdownClickableOptionsOptionProps | FieldDropdownClickableOptionsDividerProps;

export enum FieldDropdownClickableOptionsOptionType {
	Segment = 'segment',
}

type Props = {
	/** Possibility to enable ellipsis on options */
	ellipsis?: boolean,
	/** Options data */
	options: Array<FieldDropdownClickableOptionsProps>,
	/** Callback triggered on option click */
	onOptionClickCallback?: (name: string) => void,
	width?: React.CSSProperties['width'],
};



const FieldDropdownClickableOptions: React.FC<Props> = (props) => {
	const {
		ellipsis = false,
		onOptionClickCallback,
		options,
		width,
	} = props;

	if (options.length === 0) {
		return null;
	}

	return (
		<div className="field-dropdown-options">
			<ul
				className="field-dropdown-options__list"
				style={{
					minWidth: width,
					width,
				}}
			>
				{options.map((option, index) => {
					if ('divider' in option) {
						return (
							<li
								className="field-dropdown-options__divider"
								key={'filter-divider-' + index}
							/>
						);
					}

					const item = option as FieldDropdownClickableOptionsOptionProps;

					function handleClick(event: React.MouseEvent) {
						event.preventDefault();

						if (!item.disabled && onOptionClickCallback) {
							onOptionClickCallback(item.name);
						}
					}

					const optionClasses = classNames({
						'field-dropdown-options__option': true,
						'field-dropdown-options__option--disabled': item.disabled,
						'field-dropdown-options__option--ellipsis': item.ellipsis || ellipsis || false,
						'field-dropdown-options__option--highlighted': item.highlighted,
						'field-dropdown-options__option--selected': item.selected,
						['field-dropdown-options__option--type-' + item.type]: item.type,
					});

					return (
						<li
							className={optionClasses}
							key={'filter-option-' + index}
						>
							<span
								className="field-dropdown-options__link"
								onClick={handleClick}
							>
								{item.icon && (
									<span className="field-dropdown-options__icon">{item.icon}</span>
								)}
								<span className="field-dropdown-options__text-part">
									<span className="field-dropdown-options__link-label">{item.label}</span>
									{item.description && (
										<span className="field-dropdown-options__link-description">{item.description}</span>
									)}
								</span>
							</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};



export default FieldDropdownClickableOptions;
