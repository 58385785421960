import React from 'react';

import AnalyticsIcon from './builders/iconsData/issueCategoryIcon/Analytics';
import CanonicalLinkIcon from './builders/iconsData/issueCategoryIcon/CanonicalLink';
import Content from './builders/iconsData/issueCategoryIcon/Content';
import ContentHeadingsIcon from './builders/iconsData/issueCategoryIcon/ContentHeadings';
import ConversionsIcon from './builders/iconsData/issueCategoryIcon/Conversions';
import DomainIcon from './builders/iconsData/issueCategoryIcon/Domain';
import HreflangIcon from './builders/iconsData/issueCategoryIcon/Hreflang';
import ImagesIcon from './builders/iconsData/issueCategoryIcon/Images';
import IndexabilityIcon from './builders/iconsData/issueCategoryIcon/Indexability';
import LighthouseIcon from './builders/iconsData/issueCategoryIcon/Lighthouse';
import LinksIcon from './builders/iconsData/issueCategoryIcon/Links';
import MetaDescriptionIcon from './builders/iconsData/issueCategoryIcon/MetaDescription';
import MetaInformationIcon from './builders/iconsData/issueCategoryIcon/MetaInformation';
import NetworkIcon from './builders/iconsData/issueCategoryIcon/Network';
import OpenGraphIcon from './builders/iconsData/issueCategoryIcon/OpenGraph';
import RobotDirectivesIcon from './builders/iconsData/issueCategoryIcon/RobotDirectives';
import RobotsTxtIcon from './builders/iconsData/issueCategoryIcon/RobotsTxt';
import SchemaOrgIcon from './builders/iconsData/issueCategoryIcon/SchemaOrg';
import SocialIcon from './builders/iconsData/issueCategoryIcon/Social';
import TitleIcon from './builders/iconsData/issueCategoryIcon/Title';
import TwitterCardsIcon from './builders/iconsData/issueCategoryIcon/TwitterCards';
import WebVitalsIcon from './builders/iconsData/issueCategoryIcon/WebVitals';
import XmlSitemapIcon from './builders/iconsData/issueCategoryIcon/XmlSitemap';

import InlineIcon from './builders/InlineIcon.part';

import {
	type Merge,
} from '~/types/utilities';



export enum IssueCategoryIconType {
	Analytics = 'analytics',
	CanonicalLink = 'canonical_link',
	Content = 'content',
	ContentHeadings = 'h1',
	Conversions = 'conversions',
	Domain = 'domain',
	Hreflang = 'hreflang',
	Images = 'images',
	Indexability = 'indexability',
	Lighthouse = 'lighthouse',
	Links = 'links',
	MetaDescription = 'meta_description',
	MetaInformation = 'meta_information',
	Network = 'network',
	OpenGraph = 'open_graph',
	RobotDirectives = 'robot_directives',
	RobotsTxt = 'robots_txt',
	SchemaOrg = 'schema_org',
	Social = 'social',
	Title = 'title',
	TwitterCards = 'twitter_cards',
	WebVitals = 'web_vitals_origin_summary',
	XmlSitemap = 'xml_sitemap',
}

const componentMapping = {
	[IssueCategoryIconType.Analytics]: AnalyticsIcon,
	[IssueCategoryIconType.CanonicalLink]: CanonicalLinkIcon,
	[IssueCategoryIconType.Content]: Content,
	[IssueCategoryIconType.ContentHeadings]: ContentHeadingsIcon,
	[IssueCategoryIconType.Conversions]: ConversionsIcon,
	[IssueCategoryIconType.Domain]: DomainIcon,
	[IssueCategoryIconType.Hreflang]: HreflangIcon,
	[IssueCategoryIconType.Images]: ImagesIcon,
	[IssueCategoryIconType.Indexability]: IndexabilityIcon,
	[IssueCategoryIconType.Lighthouse]: LighthouseIcon,
	[IssueCategoryIconType.Links]: LinksIcon,
	[IssueCategoryIconType.MetaDescription]: MetaDescriptionIcon,
	[IssueCategoryIconType.MetaInformation]: MetaInformationIcon,
	[IssueCategoryIconType.Network]: NetworkIcon,
	[IssueCategoryIconType.OpenGraph]: OpenGraphIcon,
	[IssueCategoryIconType.RobotDirectives]: RobotDirectivesIcon,
	[IssueCategoryIconType.RobotsTxt]: RobotsTxtIcon,
	[IssueCategoryIconType.SchemaOrg]: SchemaOrgIcon,
	[IssueCategoryIconType.Social]: SocialIcon,
	[IssueCategoryIconType.Title]: TitleIcon,
	[IssueCategoryIconType.TwitterCards]: TwitterCardsIcon,
	[IssueCategoryIconType.WebVitals]: WebVitalsIcon,
	[IssueCategoryIconType.XmlSitemap]: XmlSitemapIcon,
} as const;



type BaseProps<IconType extends IssueCategoryIconType = IssueCategoryIconType> = {
	/** Custom icon color */
	color?: string,
	/** Square size of icon */
	size?: number,
	/** Type of icon */
	type: IconType,
};

type IconProps<IconType extends IssueCategoryIconType = IssueCategoryIconType> = (
	Parameters<typeof componentMapping[IconType]['icon']>[0]
);

type Props<IconType extends IssueCategoryIconType = IssueCategoryIconType> = (
	Merge<IconProps<IconType>, BaseProps<IconType>>
);

const IssueCategoryIcon = <IconType extends IssueCategoryIconType>(props: Props<IconType>): React.ReactElement => {
	const {
		color,
		size = 60,
		type,
		...rest
	} = props;

	const icon = componentMapping[type].icon({
		color,
		size,
		...rest,
	});

	return (
		<InlineIcon
			icon={icon}
			size={size}
			viewBoxHeight={componentMapping[type].viewBoxHeight}
			viewBoxWidth={componentMapping[type].viewBoxWidth}
		/>
	);
};



export default IssueCategoryIcon;
