import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CancelButton from '~/components/app/CancelButton';
import CodeValue from '~/components/patterns/values/CodeValue';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import DecentText, {
	DecentTextAlignment,
} from '~/components/patterns/typography/DecentText';
import Form from '~/components/atoms/forms/basis/Form';
import HelpHint from '~/components/patterns/hints/HelpHint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LabeledValue, {
	LabeledValueContentAlignment,
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import LazyImage from '~/components/patterns/images/LazyImage';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import QRCodeImage from '~/components/atoms/images/QRCodeImage';
import RichText from '~/components/patterns/typography/RichText';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';
import Spinner from '~/components/patterns/loaders/Spinner';
import SubmitButton from '~/components/app/SubmitButton';
import TwoFactorAuthenticationCodeField, {
	validateTwoFactorAuthenticationCode,
} from './TwoFactorAuthenticationCodeField';

import {
	useCompleteTwoFactorAuthenticationSetupMutation,
	useInitiateTwoFactorAuthenticationSetupMutation,
} from './SetupTwoFactorAuthenticationModalSetupStep.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useCurrentUserId from '~/hooks/useCurrentUserId';



const messages = defineMessages({
	authenticatorAppHint: {
		id: 'ui.twoFactorAuthenticationSetup.authenticatorAppHint',
	},
	backToQrCode: {
		id: 'ui.twoFactorAuthenticationSetup.backToQrCode',
	},
	codePrompt: {
		id: 'ui.twoFactorAuthenticationSetup.codePrompt',
	},
	havingTroubles: {
		id: 'ui.twoFactorAuthenticationSetup.havingTroubles',
	},
	manualEntryAccount: {
		id: 'ui.twoFactorAuthenticationSetup.manualEntryAccount',
	},
	manualEntryKey: {
		id: 'ui.twoFactorAuthenticationSetup.manualEntryKey',
	},
	manualEntryPrompt: {
		id: 'ui.twoFactorAuthenticationSetup.manualEntryPrompt',
	},
	qrcodePrompt: {
		id: 'ui.twoFactorAuthenticationSetup.qrCodePrompt',
	},
	setupStepButton: {
		id: 'ui.twoFactorAuthenticationSetup.submit',
	},
	sidebar: {
		id: 'ui.twoFactorAuthenticationSetup.sidebar',
	},
	title: {
		id: 'ui.twoFactorAuthenticationSetup.title',
	},
});

const validations = {
	validateTwoFactorAuthenticationCode,
};



type Secret = {
	email: string,
	key: string,
};



type Props = {
	onRequireSudoMode: () => void,
};

const SetupTwoFActorAuthenticationModalSetupStep: React.FC<Props> = (props) => {
	const {
		onRequireSudoMode,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const currentUserId = useCurrentUserId();

	const [completeTwoFactorAuthenticationSetup] = useCompleteTwoFactorAuthenticationSetupMutation();
	const [initiateTwoFactorAuthenticationSetup] = useInitiateTwoFactorAuthenticationSetupMutation();

	const [manualMode, setManualMode] = React.useState(false);

	const [qrcode, setQrcode] = React.useState<string | null>(null);
	const [secret, setSecret] = React.useState<Secret | null>(null);

	React.useEffect(
		() => {
			initiateTwoFactorAuthenticationSetup().then(({ data }) => {
				if (data === undefined || data === null) {
					return;
				}

				setQrcode(data.InitiateTwoFactorAuthenticationSetup.qrcode);
				setSecret({
					email: data.InitiateTwoFactorAuthenticationSetup.secretEmail,
					key: data.InitiateTwoFactorAuthenticationSetup.secretKey,
				});
			});
		},
		[
			initiateTwoFactorAuthenticationSetup,
		],
	);

	const handleSubmit = React.useCallback(
		async (values, { createError }) => {
			if (currentUserId === null) {
				return;
			}

			try {
				await completeTwoFactorAuthenticationSetup({
					variables: {
						code: values.twoFactorAuthenticationCode,
						legacyUserId: currentUserId,
					},
				});

				classicFormBehavior.finish();
			} catch (error) {
				if (error.getCode() === 'requiredSudoMode') {
					onRequireSudoMode();
				} else {
					throw createError(error.getCode(), {
						value: values.twoFactorAuthenticationCode,
					});
				}
			}
		},
		[
			classicFormBehavior,
			completeTwoFactorAuthenticationSetup,
			currentUserId,
			onRequireSudoMode,
		],
	);

	function renderAuthenticatorAppHint() {
		return (
			<HelpHint
				message={(
					<RichText>
						<Copy
							{...messages.authenticatorAppHint}
							values={{
								linkAndroidApp: linkExternal('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'),
								linkIosApp: linkExternal('https://apps.apple.com/us/app/google-authenticator/id388497605'),
							}}
						/>
					</RichText>
				)}
			/>
		);
	}

	return (
		<Form
			onSuccess={handleSubmit}
			validations={validations}
		>
			{manualMode && (
				<>
					<ModalTextSection>
						<RichText>
							<Copy
								{...messages.manualEntryPrompt}
								values={{
									helpHint: renderAuthenticatorAppHint(),
								}}
							/>
						</RichText>

						<DecentText alignment={DecentTextAlignment.Right}>
							<InternalLink
								onClickCallback={() => {
									setManualMode(false);
								}}
								style={InternalLinkStyle.Decent}
							>
								<FormattedMessage {...messages.backToQrCode} />
							</InternalLink>
						</DecentText>
					</ModalTextSection>

					<ModalTextSection>
						<SimpleBox>
							<LabeledValuesList>
								<LabeledValue
									contentAlignment={LabeledValueContentAlignment.Left}
									flow={LabeledValueFlow.ColumnOnSmall}
									label={(
										<FormattedMessage {...messages.manualEntryAccount} />
									)}
								>
									{secret?.email}
								</LabeledValue>
								<LabeledValue
									contentAlignment={LabeledValueContentAlignment.Left}
									flow={LabeledValueFlow.ColumnOnSmall}
									label={(
										<FormattedMessage {...messages.manualEntryKey} />
									)}
								>
									<CodeValue>{secret?.key}</CodeValue>
								</LabeledValue>
							</LabeledValuesList>
						</SimpleBox>
					</ModalTextSection>
				</>
			)}

			{!manualMode && (
				<>
					<ModalTextSection>
						<RichText>
							<Copy
								{...messages.qrcodePrompt}
								values={{
									helpHint: renderAuthenticatorAppHint(),
								}}
							/>
						</RichText>

						<DecentText alignment={DecentTextAlignment.Right}>
							<InternalLink
								onClickCallback={() => {
									setManualMode(true);
								}}
								style={InternalLinkStyle.Decent}
							>
								<FormattedMessage {...messages.havingTroubles} />
							</InternalLink>
						</DecentText>
					</ModalTextSection>

					<ModalContentSection centered={true}>
						<QRCodeImage>
							<LazyImage
								height={200}
								placeholder={(
									<Spinner />
								)}
								src={qrcode}
								width={200}
							/>
						</QRCodeImage>
					</ModalContentSection>
				</>
			)}

			<ModalTextSection>
				<RichText>
					<Copy {...messages.codePrompt} />
				</RichText>
			</ModalTextSection>

			<TwoFactorAuthenticationCodeField />

			<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
				<CancelButton />

				<SubmitButton>
					<FormattedMessage {...messages.setupStepButton} />
				</SubmitButton>
			</ModalButtonsLayout>
		</Form>
	);
};



export default SetupTwoFActorAuthenticationModalSetupStep;
