import type CK from '~/types/contentking';

import {
	useWebsiteDisplayNameQuery,
} from './useWebsiteDisplayName.gql';

import useBatchContext from '~/hooks/useBatchContext';



function useWebsiteDisplayName(websiteId: CK.WebsiteId | null): string | null {
	const { data } = useWebsiteDisplayNameQuery({
		context: useBatchContext('websites', 50),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return data?.website?.displayName ?? null;
}



export default useWebsiteDisplayName;
