import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DistributionBarChart from '~/components/patterns/charts/charts/DistributionBarChart';
import WebVitalName from './WebVitalName';
import WebVitalFormatter from './WebVitalFormatter';

import {
	type WebVital,
} from '~/model/webVitals';



const messages = defineMessages({
	tooltipFast: {
		id: 'ui.webVitals.tooltip.fast',
	},
	tooltipGood: {
		id: 'ui.webVitals.tooltip.good',
	},
	tooltipSlow: {
		id: 'ui.webVitals.tooltip.slow',
	},
});



type Props = {
	maximumFast: number,
	maximumGood: number,
	percentageFast: number,
	percentageGood: number,
	percentageSlow: number,
	type: WebVital,
};

const WebVitalDistributionBarChart: React.FC<Props> = (props) => {
	const {
		maximumFast,
		maximumGood,
		percentageFast,
		percentageGood,
		percentageSlow,
		type,
	} = props;

	const data: Array<{
		color: string,
		value: number,
		label: React.ReactNode,
	}> = [];

	if (Math.round(percentageFast * 100) > 0) {
		data.push({
			color: '#0cce6b',
			value: percentageFast,
			label: (
				<FormattedMessage
					{...messages.tooltipFast}
					values={{
						percentage: percentageFast,
						fastThreshold: (
							<WebVitalFormatter
								type={type}
								value={maximumFast}
							/>
						),
						name: (
							<WebVitalName
								showCoreWebVitalFlag={false}
								type={type}
							/>
						),
					}}
				/>
			),
		});
	}

	if (Math.round(percentageGood * 100) > 0) {
		data.push({
			color: '#ffa400',
			value: percentageGood,
			label: (
				<FormattedMessage
					{...messages.tooltipGood}
					values={{
						percentage: percentageGood,
						fastThreshold: (
							<WebVitalFormatter
								type={type}
								value={maximumFast}
							/>
						),
						goodThreshold: (
							<WebVitalFormatter
								type={type}
								value={maximumGood}
							/>
						),
						name: (
							<WebVitalName
								showCoreWebVitalFlag={false}
								type={type}
							/>
						),
					}}
				/>
			),
		});
	}

	if (Math.round(percentageSlow * 100) > 0) {
		data.push({
			color: '#ff4e42',
			value: percentageSlow,
			label: (
				<FormattedMessage
					{...messages.tooltipSlow}
					values={{
						percentage: percentageSlow,
						goodThreshold: (
							<WebVitalFormatter
								type={type}
								value={maximumGood}
							/>
						),
						name: (
							<WebVitalName
								showCoreWebVitalFlag={false}
								type={type}
							/>
						),
					}}
				/>
			),
		});
	}

	return (
		<DistributionBarChart
			data={data}
			hasDividedSlices={true}
		/>
	);
};



export default WebVitalDistributionBarChart;
