import {
	Map,
} from 'immutable';
import {
	createSelector,
} from 'reselect';



export const EmptyTeamBilling = new Map({
	details: new Map(),
	invoices: null,
});

const EmptyTeamsBilling = {
	get: () => EmptyTeamBilling,
};

export const teamsSelector = (state) => state.get('teams').get('basics');

export const teamsBillingSelector = (state) => state.get('teams').get('billing').size ? state.get('teams').get('billing') : EmptyTeamsBilling;

export const virtualRobotsRulesSelector = (state, props) => {
	return state.get('teams')
		.get('basics')
		.get(
			props.website.get('team_id'),
		)
		.get('websites')
		.find(
			(website) => website.get('id') === props.website.get('id'),
		)
		.get('virtualRobotsRules');
};

export const numberOfWebsitesSelector = createSelector(
	teamsSelector,
	(
		teams,
	) => {
		return teams.reduce(
			(total, team) => total + team.get('websites').size,
			0,
		);
	},
);
