import type CK from '~/types/contentking';

import {
	usePageIsPristineQuery,
} from './usePageIsPristine.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageIsPristine(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageIsPristineQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.isPristine ?? null;
}



export default usePageIsPristine;
