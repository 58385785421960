import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import SimpleNavigation from '~/components/patterns/navigations/simpleNavigation/SimpleNavigation';
import SimpleNavigationItem from '~/components/patterns/navigations/simpleNavigation/SimpleNavigationItem';
import SplitButton from '~/components/patterns/buttons/SplitButton';

import useGetLinkTarget from '~/hooks/useGetLinkTarget';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



export type ButtonWithDropdownAction = {
	includeBacklink?: boolean,
	label: React.ReactNode,
	linkRouteName: string,
	linkRouteParams: Record<string, any>,
};



type Props = {
	actions: ReadonlyArray<ButtonWithDropdownAction>,
	iconColor?: string,
	iconType: BasicIconType,
	isDisabled?: boolean,
	label: React.ReactNode,
	size?: ButtonSize,
	style?: ButtonStyle,
	tooltip?: React.ReactNode,
	uppercase?: boolean,
};

const ButtonWithDropdown: React.FC<Props> = (props) => {
	const {
		actions,
		iconColor,
		iconType,
		isDisabled = false,
		label,
		size,
		style,
		tooltip,
		uppercase,
	} = props;

	const getLinkTarget = useGetLinkTarget();

	const finalIconColor = isDisabled
		? 'rgba(92, 103, 115, 0.4)'
		: (iconColor ?? '#ffffff');

	const firstAction = getArrayItemAtSafeIndex(actions, 0);
	const firstActionLinkTarget = getLinkTarget({
		includeBacklink: firstAction.includeBacklink,
		routeName: firstAction.linkRouteName,
		routeParams: firstAction.linkRouteParams,
	});

	const showDropdown = actions.length > 1;

	if (showDropdown) {
		return (
			<SplitButton
				dropdown={(
					<SimpleNavigation>
						{actions.map((action, index) => {
							const actionLinkTarget = getLinkTarget({
								includeBacklink: action.includeBacklink,
								routeName: action.linkRouteName,
								routeParams: action.linkRouteParams,
							});

							return (
								<SimpleNavigationItem
									key={index}
									linkRouteName={actionLinkTarget.routeName}
									linkRouteParams={actionLinkTarget.routeParams}
								>
									{action.label}
								</SimpleNavigationItem>
							);
						})}
					</SimpleNavigation>
				)}
				dropdownWidth={250}
				icon={(
					<BasicIcon type={iconType} />
				)}
				iconColor={finalIconColor}
				isDisabled={isDisabled}
				linkRouteName={firstActionLinkTarget.routeName}
				linkRouteParams={firstActionLinkTarget.routeParams}
				size={size}
				style={style}
				uppercase={uppercase}
			>
				{label}
			</SplitButton>
		);
	}

	return (
		<Button
			disabled={isDisabled}
			icon={(
				<BasicIcon type={iconType} />
			)}
			iconColor={finalIconColor}
			linkRouteName={firstActionLinkTarget.routeName}
			linkRouteParams={firstActionLinkTarget.routeParams}
			size={size}
			style={style}
			tooltip={tooltip}
			uppercase={uppercase}
		>
			{label}
		</Button>
	);
};



export default ButtonWithDropdown;

export {
	BasicIconType as ButtonWithDropdownIconType,
	ButtonSize as ButtonWithDropdownSize,
	ButtonStyle as ButtonWithDropdownStyle,
};
