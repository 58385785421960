import classNames from 'classnames';
import React, {
	Component,
} from 'react';

import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Spinner, {
	SpinnerSize,
	SpinnerStyle,
} from '~/components/patterns/loaders/Spinner';



const messages = defineMessages({
	loading: {
		id: 'ui.general.loading',
		defaultMessage: 'Loading',
	},
	stillLoading: {
		id: 'ui.general.stillLoading',
		defaultMessage: 'Still loading',
	},
});



export enum ButtonLivingLoaderSize {
	Default = 'default',
	Small = 'small',
}

export enum ButtonLivingLoaderStyle {
	Dark = 'dark',
	Light = 'light',
}

enum ButtonLivingLoaderProgressState {
	Initial = 'initial',
	LoadingText = 'loading-text',
	StillLoadingText = 'still-loading-text',
}



const PROGRESS_STATE_1_TIMEOUT = 2000;
const PROGRESS_STATE_2_TIMEOUT = 10000;



type Props = {
	/** Size of loader */
	size: ButtonLivingLoaderSize,
	/** Style of button loader */
	style: ButtonLivingLoaderStyle,
};

type State = {
	progressState: ButtonLivingLoaderProgressState,
};

class ButtonLivingLoader extends Component<Props, State> {

	timer: any;

	static defaultProps = {
		size: ButtonLivingLoaderSize.Default,
		style: ButtonLivingLoaderStyle.Dark,
	};



	constructor(props, context) {
		super(props, context);

		this.state = {
			progressState: ButtonLivingLoaderProgressState.Initial,
		};
	}



	componentWillUnmount() {
		clearTimeout(this.timer);
	}



	componentDidMount() {
		this.timer = setTimeout(() => {
			this.setState({
				progressState: ButtonLivingLoaderProgressState.LoadingText,
			}, this._processSecondProgressState);
		}, PROGRESS_STATE_1_TIMEOUT);
	}



	_processSecondProgressState() {
		this.timer = setTimeout(() => {
			this.setState({
				progressState: ButtonLivingLoaderProgressState.StillLoadingText,
			});
		}, PROGRESS_STATE_2_TIMEOUT);
	}



	render() {
		const {
			size,
			style,
		} = this.props;

		const {
			progressState,
		} = this.state;

		const preloaderClasses = classNames({
			'button-living-loader__preloader': true,
			'button-living-loader__preloader--hidden': !progressState,
			'button-living-loader__preloader--left-aligned': progressState === ButtonLivingLoaderProgressState.LoadingText
				|| progressState === ButtonLivingLoaderProgressState.StillLoadingText,
		});

		let loaderText;

		if (progressState === ButtonLivingLoaderProgressState.LoadingText) {
			loaderText = (
				<span className="button-living-loader__progress-text button-living-loader__progress-text--delayed">
					<FormattedMessage {...messages.loading} />
					<span>&nbsp;&hellip;</span>
				</span>
			);
		} else if (progressState === ButtonLivingLoaderProgressState.StillLoadingText) {
			loaderText = (
				<span className="button-living-loader__progress-text">
					<FormattedMessage {...messages.stillLoading} />
					<span>&nbsp;&hellip;</span>
				</span>
			);
		}

		const componentClasses = classNames({
			'button-living-loader': true,
			'button-living-loader--small': size === ButtonLivingLoaderSize.Small,
		});

		return (
			<div className={componentClasses}>
				<div className={preloaderClasses}>
					<Spinner
						size={size === ButtonLivingLoaderSize.Small ? SpinnerSize.Small : SpinnerSize.Default}
						style={style === ButtonLivingLoaderStyle.Dark ? SpinnerStyle.Dark : SpinnerStyle.White}
					/>
				</div>
				{loaderText}
			</div>
		);
	}

}



export default ButtonLivingLoader;
