import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RealtimeAuditingIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M19.0876 26.2176C23.1648 26.2176 26.4701 22.9124 26.4701 18.8351C26.4701 14.7579 23.1648 11.4526 19.0876 11.4526C15.0103 11.4526 11.7051 14.7579 11.7051 18.8351C11.7051 22.9124 15.0103 26.2176 19.0876 26.2176Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M10.327 21.2051H2.22196C2.06974 21.2051 1.91902 21.1751 1.77842 21.1168C1.63782 21.0585 1.51011 20.973 1.40259 20.8652C1.29508 20.7575 1.20987 20.6296 1.15185 20.4889C1.09382 20.3481 1.06413 20.1974 1.06446 20.0451V3.04514C1.06512 2.73858 1.18736 2.44481 1.40436 2.22828C1.62136 2.01174 1.9154 1.89014 2.22196 1.89014H22.3145C22.6214 1.89014 22.9159 2.01209 23.1329 2.22916C23.35 2.44623 23.472 2.74065 23.472 3.04764V10.8426"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M3.24023 12.4351H5.81273L8.53273 9.88756L10.4127 11.6501L14.8902 7.45506L17.0177 9.44756L21.3477 5.37256"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M26.9325 24.2949L30.535 27.8974C30.6926 28.0542 30.8177 28.2406 30.903 28.4459C30.9884 28.6512 31.0323 28.8713 31.0323 29.0937C31.0323 29.316 30.9884 29.5361 30.903 29.7414C30.8177 29.9467 30.6926 30.1331 30.535 30.2899V30.2899C30.3782 30.4475 30.1918 30.5726 29.9865 30.6579C29.7812 30.7433 29.5611 30.7872 29.3388 30.7872C29.1165 30.7872 28.8963 30.7433 28.691 30.6579C28.4858 30.5726 28.2993 30.4475 28.1425 30.2899L24.54 26.6899L26.9325 24.2949Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M19.0876 23.5926C21.7151 23.5926 23.8451 21.4626 23.8451 18.8351C23.8451 16.2076 21.7151 14.0776 19.0876 14.0776C16.4601 14.0776 14.3301 16.2076 14.3301 18.8351C14.3301 21.4626 16.4601 23.5926 19.0876 23.5926Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M24.3076 24.0549L25.6926 25.4399"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M16.75 18.8349C16.7507 18.2118 16.9985 17.6145 17.439 17.1739C17.8796 16.7333 18.4769 16.4855 19.1 16.4849"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default RealtimeAuditingIconBuilder;

