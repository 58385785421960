/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAlertDefinitionsQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAlertDefinitionsQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly alertDefinitions: ReadonlyArray<{ readonly __typename?: 'AlertDefinition', readonly alertType: string, readonly id: CK.AlertDefinitionId, readonly recipients: ReadonlyArray<string>, readonly scope: string, readonly settings: Record<string, any>, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType, readonly ownerAccountId: CK.AccountId }> }> } | null };


export const WebsiteAlertDefinitionsDocument = gql`
    query WebsiteAlertDefinitions($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    alertDefinitions {
      alertType
      id
      messagingAppChannels {
        id
        label
        messagingAppType
        ownerAccountId
      }
      recipients
      scope
      settings
    }
    id
  }
}
    `;

/**
 * __useWebsiteAlertDefinitionsQuery__
 *
 * To run a query within a React component, call `useWebsiteAlertDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAlertDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAlertDefinitionsQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAlertDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAlertDefinitionsQuery, WebsiteAlertDefinitionsQueryVariables> & ({ variables: WebsiteAlertDefinitionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAlertDefinitionsQuery, WebsiteAlertDefinitionsQueryVariables>(WebsiteAlertDefinitionsDocument, options);
      }
export function useWebsiteAlertDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAlertDefinitionsQuery, WebsiteAlertDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAlertDefinitionsQuery, WebsiteAlertDefinitionsQueryVariables>(WebsiteAlertDefinitionsDocument, options);
        }
export function useWebsiteAlertDefinitionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteAlertDefinitionsQuery, WebsiteAlertDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAlertDefinitionsQuery, WebsiteAlertDefinitionsQueryVariables>(WebsiteAlertDefinitionsDocument, options);
        }
export type WebsiteAlertDefinitionsQueryHookResult = ReturnType<typeof useWebsiteAlertDefinitionsQuery>;
export type WebsiteAlertDefinitionsLazyQueryHookResult = ReturnType<typeof useWebsiteAlertDefinitionsLazyQuery>;
export type WebsiteAlertDefinitionsSuspenseQueryHookResult = ReturnType<typeof useWebsiteAlertDefinitionsSuspenseQuery>;
export type WebsiteAlertDefinitionsQueryResult = Apollo.QueryResult<WebsiteAlertDefinitionsQuery, WebsiteAlertDefinitionsQueryVariables>;