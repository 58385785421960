import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class DataLoadingFramework extends Component {

	constructor(props, context) {
		super(props, context);

		this.loadTimeout = null;
		this.synchronizationTimeout = undefined;

		this._handleScroll = this._handleScroll.bind(this);
		this._handleSectionRendered = this._handleSectionRendered.bind(this);
		this._triggerLoad = this._triggerLoad.bind(this);

		this._isMounted = false;

		this.offset = 0;
		this.lastSynced = Date.now();
	}



	componentDidMount() {
		this._isMounted = true;

		this._triggerLoad(0);
	}



	componentDidUpdate(prevProps) {
		const {
			columns: prevColumns,
			filter: prevFilter,
			sortBy: prevSortBy,
			websiteId: prevWebsiteId,
		} = prevProps;

		const {
			columns: nextColumns,
			filter: nextFilter,
			sortBy: nextSortBy,
			websiteId: nextWebsiteId,
		} = this.props;

		if (
			prevFilter !== nextFilter
			|| prevSortBy !== nextSortBy
			|| prevWebsiteId !== nextWebsiteId
		) {
			this.offset = 0;
			this.lastSynced = Date.now();

			this._triggerLoad(0);
		} else if (
			prevColumns && nextColumns && nextColumns.some((column) => prevColumns.indexOf(column) === -1)
		) {
			this.lastSynced = Date.now();

			this._triggerLoad(this.offset);
		}
	}



	componentWillUnmount() {
		this._isMounted = false;

		if (this.loadTimeout) {
			clearTimeout(this.loadTimeout);
			this.loadTimeout = null;
		}

		if (this.synchronizationTimeout) {
			clearTimeout(this.synchronizationTimeout);
			this.synchronizationTimeout = null;
		}
	}



	_handleScroll({ rowIndex }) {
		const {
			loadDataDelay,
		} = this.props;

		if (this.loadTimeout) {
			clearTimeout(this.loadTimeout);
		}

		this.offset = rowIndex;

		this.loadTimeout = setTimeout(
			() => {
				if (this._isMounted) {
					this._triggerLoad(rowIndex);
				}
			},
			loadDataDelay,
		);
	}



	_handleSectionRendered({ rowStartIndex }) {
		this.offset = rowStartIndex;

		this._handleScroll({
			rowIndex: rowStartIndex,
		});
	}



	_triggerLoad(offset) {
		const {
			loadDataCallback,
			syncInterval,
		} = this.props;

		if (this.synchronizationTimeout) {
			clearTimeout(this.synchronizationTimeout);
			this.synchronizationTimeout = undefined;
		}

		if (!this._isMounted) {
			return false;
		}

		loadDataCallback(offset, this.lastSynced)
			.catch(() => {})
			.then((status) => {
				if (typeof status !== 'object' || status.skip !== true) {
					this.lastSynced = Date.now();
				}

				if (!this._isMounted) {
					return false;
				}

				if (!syncInterval) {
					return false;
				}

				if (this.synchronizationTimeout) {
					clearTimeout(this.synchronizationTimeout);
					this.synchronizationTimeout = undefined;
				}

				if (offset === this.offset) {
					this.synchronizationTimeout = setTimeout(
						() => {
							this.synchronizationTimeout = undefined;

							this._triggerLoad(offset);
						},
						syncInterval,
					);
				}
			});
	}



	render() {
		const {
			children,
		} = this.props;

		const extraProps = {
			onScroll: this._handleScroll,
			onSectionRendered: this._handleSectionRendered,
		};

		if (typeof children === 'function') {
			return children(extraProps);
		}

		return React.cloneElement(
			React.Children.only(children),
			extraProps,
		);
	}

}

DataLoadingFramework.defaultProps = {
	loadDataDelay: 500,
};

DataLoadingFramework.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.func,
	]).isRequired,
	columns: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.instanceOf(List),
	]),
	filter: PropTypes.object,
	loadDataCallback: PropTypes.func.isRequired,
	loadDataDelay: PropTypes.number,
	parameters: PropTypes.object,
	sortBy: PropTypes.object,
	syncInterval: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.number,
	]).isRequired,
	websiteId: PropTypes.string,
};



export default DataLoadingFramework;
