import React from 'react';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import DatatableBodyCell, {
	type DatatableBodyCellOnClickInput,
	DatatableBodyCellPadding,
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableHeaderCell, {
	DatatableHeaderCellPadding,
	DatatableHeaderCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';
import DatatableLayout from '~/components/patterns/tables/datatables/DatatableLayout';
import CheckboxGroupContainer from '../checkboxGroup/CheckboxGroupContainer';
import CheckboxInGroup from '../checkboxGroup/CheckboxInGroup';
import FixedHeaderGrid from '~/components/patterns/tables/datatables/FixedHeaderGrid';
import Hint, {
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import MasterCheckbox from '../checkboxGroup/MasterCheckbox';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import touchSupported from '~/utilities/touchSupported';



const CHECKBOX_CELL_WIDTH = 40;
const HEADER_HEIGHT = 32;
const ROW_HEIGHT = 40;
const TABLE_HEIGHT = 220;

export type SelectableRowsTableBodyCellRendererInput = {
	columnIndex: number,
	isDisabled: boolean,
	isSelected: boolean,
	key: string,
	rowIndex: number,
	style: any,
};

export type SelectableRowsTableDisabledRowExplanationRendererInput = {
	rowIndex: number,
};

export type SelectableRowsTableGetColumnWidthInput = {
	index: number,
	width: number,
};

export type SelectableRowsTableHeaderCellRendererInput = {
	columnIndex: number,
};

export type SelectableRowsTableRef = {
	resetSelection: () => void,
};



type Props = {
	bodyCellRenderer: (input: SelectableRowsTableBodyCellRendererInput) => React.ReactNode,
	columnCount: number,
	columnWidth: number | ((input: SelectableRowsTableGetColumnWidthInput) => number | undefined),
	defaultSelectedRows?: ReadonlyArray<any>,
	disabledRowExplanationRenderer?: (input: SelectableRowsTableDisabledRowExplanationRendererInput) => React.ReactNode,
	disabledRows?: ReadonlyArray<any>,
	footerCTA?: RenderProp<{
		selectedData: ReadonlyArray<any>,
	}>,
	footerLabel?: RenderProp<{
		selectedData: ReadonlyArray<any>,
	}>,
	headerCellRenderer: (input: SelectableRowsTableHeaderCellRendererInput) => React.ReactNode,
	height?: number,
	masterCheckbox?: boolean,
	name: string,
	onSelectionChangeCallback?: (value: ReadonlyArray<any>) => void,
	rowCount: number,
	selectedRows?: ReadonlyArray<any>,
	width: number,
};

const SelectableRowsTable = React.forwardRef<SelectableRowsTableRef, Props>((props, ref) => {
	const {
		bodyCellRenderer,
		columnCount,
		columnWidth,
		defaultSelectedRows = [],
		disabledRowExplanationRenderer = null,
		disabledRows = [],
		footerCTA = null,
		footerLabel = null,
		headerCellRenderer,
		height = TABLE_HEIGHT,
		masterCheckbox = true,
		name,
		onSelectionChangeCallback = null,
		rowCount,
		selectedRows = null,
		width,
	} = props;

	const [highlightedRow, setHighlightedRow] = React.useState<number | null>(null);
	const [value, setValue] = React.useState(selectedRows ?? defaultSelectedRows);

	const gridRef = React.useRef<any>(null);

	React.useImperativeHandle(ref, () => ({
		resetSelection: () => {
			setValue(selectedRows ?? defaultSelectedRows);
		},
	}));

	const handleCellClick = React.useCallback(
		({ rowIndex }: DatatableBodyCellOnClickInput) => {
			if (disabledRows.includes(rowIndex)) {
				return;
			}

			let nextValue = [...value];

			if (value.includes(rowIndex)) {
				nextValue = nextValue.filter((index) => index !== rowIndex);
			} else {
				nextValue = [...nextValue, rowIndex];
			}

			setValue(nextValue);

			if (onSelectionChangeCallback !== null) {
				onSelectionChangeCallback(nextValue);
			}
		},
		[
			disabledRows,
			onSelectionChangeCallback,
			value,
		],
	);

	const handleSelection = React.useCallback(
		({ checked }) => {
			const value = checked.map((item) => parseInt(item.split('-')[2], 10));

			setValue(value);

			if (onSelectionChangeCallback !== null) {
				onSelectionChangeCallback(value);
			}
		},
		[
			onSelectionChangeCallback,
		],
	);

	const handleTableMouseEnter = React.useCallback(
		(event, rowIndex: number) => {
			setHighlightedRow(rowIndex);
		},
		[],
	);

	const handleTableMouseLeave = React.useCallback(
		(event, rowIndex: number) => {
			setHighlightedRow(
				(highlightedRow) => {
					return highlightedRow === rowIndex
						? null
						: highlightedRow;
				},
			);
		},
		[],
	);

	React.useEffect(
		() => {
			gridRef.current?.refs.BodyGrid?.forceUpdate();
		},
		[
			highlightedRow,
		],
	);

	const getColumnWidth = React.useCallback(
		({ index }) => {
			if (index === 0) {
				return CHECKBOX_CELL_WIDTH;
			}

			if (typeof columnWidth === 'function') {
				return columnWidth({ index: index - 1, width: width - CHECKBOX_CELL_WIDTH - scrollbarSize() });
			}

			return columnWidth;
		},
		[
			columnWidth,
			width,
		],
	);

	const renderCheckbox = React.useCallback(
		(name: string, rowIndex: number) => {
			let output = (
				<CheckboxInGroup
					name={name + '-data-' + rowIndex}
				/>
			);

			if (disabledRows.includes(rowIndex)) {
				const explanation = disabledRowExplanationRenderer
					? disabledRowExplanationRenderer({ rowIndex })
					: false;

				if (explanation) {
					output = (
						<Hint
							popup={explanation}
							popupVisibility={touchSupported ? HintPopupVisibility.OnClick : HintPopupVisibility.OnHover}
						>
							{output}
						</Hint>
					);
				}
			}

			return output;
		},
		[
			disabledRowExplanationRenderer,
			disabledRows,
		],
	);

	const renderBodyCell = React.useCallback(
		({ columnIndex, key, rowIndex, style }) => {
			const actualValue = selectedRows ?? value;

			let cellContent: React.ReactNode;

			if (columnIndex === 0) {
				cellContent = renderCheckbox(name, rowIndex);
			} else {
				cellContent = bodyCellRenderer({
					columnIndex: columnIndex - 1,
					isDisabled: disabledRows.includes(rowIndex),
					isSelected: actualValue.includes(rowIndex),
					key,
					rowIndex,
					style,
				});
			}

			return (
				<DatatableBodyCell
					cssStyle={style}
					isInHighlightedRow={highlightedRow === rowIndex}
					key={key}
					onClickCallback={handleCellClick}
					onMouseEnterCallback={handleTableMouseEnter}
					onMouseLeaveCallback={handleTableMouseLeave}
					padding={columnIndex === 0 ? DatatableBodyCellPadding.CheckboxPadding : DatatableBodyCellPadding.Default}
					rowIndex={rowIndex}
					separator={columnIndex !== 0 && columnIndex <= columnCount}
					size={DatatableBodyCellSize.Small}
				>
					{cellContent}
				</DatatableBodyCell>
			);
		},
		[
			bodyCellRenderer,
			columnCount,
			disabledRows,
			handleCellClick,
			handleTableMouseEnter,
			handleTableMouseLeave,
			highlightedRow,
			name,
			renderCheckbox,
			selectedRows,
			value,
		],
	);

	const renderHeaderCell = React.useCallback(
		({ columnIndex }: { columnIndex: number }) => {
			let cellContent;

			if (columnIndex === 0) {
				if (masterCheckbox) {
					cellContent = (
						<MasterCheckbox />
					);
				} else {
					cellContent = null;
				}
			} else {
				cellContent = headerCellRenderer({
					columnIndex: columnIndex - 1,
				});
			}

			return (
				<DatatableHeaderCell
					key={columnIndex}
					padding={columnIndex === 0 ? DatatableHeaderCellPadding.CheckboxPadding : DatatableHeaderCellPadding.Default}
					separator={columnIndex !== 0 && columnIndex <= columnCount}
					size={DatatableHeaderCellSize.Small}
					width={getColumnWidth({ index: columnIndex })}
				>
					{cellContent}
				</DatatableHeaderCell>
			);
		},
		[
			columnCount,
			getColumnWidth,
			headerCellRenderer,
			masterCheckbox,
		],
	);

	const actualValue = selectedRows ?? value;

	// -2 is because of table border around this component
	const tableWidthOffset = -2;

	const tableHeight = (() => {
		let calculatedHeight = ROW_HEIGHT * rowCount;

		if (height > calculatedHeight) {
			calculatedHeight += HEADER_HEIGHT;

			let allColumnsWidth = 0;

			for (let i = 0; i < columnCount + 1; i++) {
				allColumnsWidth += getColumnWidth({ index: i }) ?? 0;
			}

			if (width < allColumnsWidth) {
				calculatedHeight += scrollbarSize();
			}

			return calculatedHeight;
		}

		return height + HEADER_HEIGHT;
	})();

	return (
		<div style={{ maxWidth: width }}>
			<DatatableLayout
				footerCTA={footerCTA !== null && renderProp(footerCTA, {
					selectedData: selectedRows ?? value,
				})}
				footerLabel={footerLabel !== null && renderProp(footerLabel, {
					selectedData: selectedRows ?? value,
				})}
			>
				<CheckboxGroupContainer
					disabled={disabledRows.map((rowIndex) => name + '-data-' + rowIndex)}
					name={name}
					onChangeCallback={handleSelection}
					options={[...Array(rowCount).keys()].map((rowIndex) => name + '-data-' + rowIndex)}
					value={actualValue.map((rowIndex) => name + '-data-' + rowIndex)}
				>
					<FixedHeaderGrid
						bodyCellRenderer={renderBodyCell}
						columnCount={columnCount + 1}
						columnWidth={getColumnWidth}
						data={{
							defaultSelectedRows,
							selectedRows,
							value,
						}}
						headerCellRenderer={renderHeaderCell}
						headerHeight={HEADER_HEIGHT}
						height={tableHeight}
						key={width}
						overscanColumnCount={columnCount + 1}
						ref={gridRef}
						rowCount={rowCount}
						rowHeight={ROW_HEIGHT}
						width={width + tableWidthOffset}
					/>
				</CheckboxGroupContainer>
			</DatatableLayout>
		</div>
	);
});



export default SelectableRowsTable;
