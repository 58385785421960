import classNames from 'classnames';
import React from 'react';

import styles from './LoadingDots.module.scss';



export enum LoadingDotsSize {
	Default = 'default',
	Large = 'large',
}

export enum LoadingDotsStyle {
	Dark = 'dark',
	White = 'white',
}



type Props = {
	/** Stretch whole component to fill-in available container where it's placed */
	isStretched?: boolean,
	size?: LoadingDotsSize,
	style?: LoadingDotsStyle,
};

const LoadingDots: React.FC<Props> = (props) => {
	const {
		isStretched = false,
		size = LoadingDotsSize.Default,
		style = LoadingDotsStyle.Dark,
	} = props;

	// extend current classes in props
	const componentClasses = classNames({
		[styles.component]: true,
		[styles.large]: size === LoadingDotsSize.Large,
		[styles.isStretched]: isStretched,
		[styles.white]: style === LoadingDotsStyle.White,
	});

	return (
		<div className={componentClasses}>
			<div className={classNames({ [styles.item]: true, [styles.i1]: true })}></div>
			<div className={classNames({ [styles.item]: true, [styles.i2]: true })}></div>
			<div className={classNames({ [styles.item]: true, [styles.i3]: true })}></div>
			{size === LoadingDotsSize.Large && (
				<div className={classNames({ [styles.item]: true, [styles.i4]: true })}></div>
			)}
			{size === LoadingDotsSize.Large && (
				<div className={classNames({ [styles.item]: true, [styles.i5]: true })}></div>
			)}
		</div>
	);
};



export default LoadingDots;
