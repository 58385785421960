import {
	addMonths,
	differenceInMonths,
	endOfYear,
	format,
	isAfter,
	isBefore,
	isSameDay,
	isSameMonth,
	max as maxDate,
	startOfMonth,
	startOfYear,
	subMonths,
} from 'date-fns';
import React from 'react';

import Calendar from '~/components/patterns/time/calendar/Calendar';
import CalendarController from '~/components/patterns/time/calendar/CalendarController';
import CalendarPickerLayout from '~/components/patterns/time/calendar/CalendarPickerLayout';
import DateRangeSelector from '~/components/patterns/time/calendar/DateRangeSelector';
import MonthYearPicker from '~/components/patterns/time/calendar/MonthYearPicker';
import SimpleNavigation from '~/components/patterns/navigations/simpleNavigation/SimpleNavigation';
import SimpleNavigationItem, {
	SimpleNavigationItemHighlightStyle,
} from '~/components/patterns/navigations/simpleNavigation/SimpleNavigationItem';

import usePrevious from '~/hooks/usePrevious';

import {
	type DateRangePreset,
} from '~/model/dateRangePresets';

import {
	isFunction,
} from '~/utilities/typeCheck';



function formatDate(date: Date | null) {
	if (date === null) {
		return '';
	}

	return format(date, 'd MMM yyyy');
}



const DEFAULT_LOWER_BOUND = startOfYear(new Date());
const DEFAULT_UPPER_BOUND = endOfYear(new Date());



export enum DateRangeCalenderPickerControl {
	Start,
	End,
}

export type Props = {
	defaultActiveControl?: DateRangeCalenderPickerControl,
	disabledDate?: (date: Date, state: {
		activeControl: DateRangeCalenderPickerControl,
		endDate: Date | null,
		startDate: Date | null,
	}) => boolean,
	endDate: Date | null,
	lowerBound?: Date,
	onPresetSelect?: (preset: DateRangePreset) => void,
	onRangeSelect: (range: {
		startDate: Date | null,
		endDate: Date | null,
	}) => void,
	presetsOptions?: Array<{
		disabled?: boolean,
		disabledExplanation?: React.ReactNode,
		highlighted?: boolean,
		label: React.ReactNode,
		value: DateRangePreset,
	}>,
	startDate: Date | null,
	upperBound?: Date,
};

const DateRangeCalendarPicker: React.FC<Props> = (props) => {
	const {
		defaultActiveControl = DateRangeCalenderPickerControl.Start,
		disabledDate,
		endDate,
		lowerBound = DEFAULT_LOWER_BOUND,
		onPresetSelect,
		onRangeSelect,
		presetsOptions,
		startDate,
		upperBound = DEFAULT_UPPER_BOUND,
	} = props;

	const [visibleDate, setVisibleDate] = React.useState(
		startOfMonth(endDate ?? startDate ?? new Date()),
	);

	const [activeControl, setActiveControl] = React.useState<DateRangeCalenderPickerControl>(defaultActiveControl);
	const [pickMonthYearDate, setPickMonthYearDate] = React.useState<Date | null>(null);

	const previousStartDate = usePrevious(startDate) ?? null;
	const previousEndDate = usePrevious(endDate) ?? null;

	React.useEffect(
		() => {
			if (
				(endDate !== null && previousEndDate === null)
				|| (endDate !== null && previousEndDate !== null && !isSameDay(endDate, previousEndDate))
			) {
				const isEndVisible = (
					isSameMonth(endDate, visibleDate)
					|| isSameMonth(endDate, subMonths(visibleDate, 1))
				);

				setVisibleDate(
					isEndVisible ? visibleDate : endDate,
				);

				return;
			}

			if (
				(startDate !== null && previousStartDate === null)
				|| (startDate !== null && previousStartDate !== null && !isSameDay(startDate, previousStartDate))
			) {
				const isStartVisible = (
					isSameMonth(startDate, visibleDate)
					|| isSameMonth(startDate, subMonths(visibleDate, 1))
				);

				setVisibleDate(
					isStartVisible ? visibleDate : startDate,
				);

				return;
			}
		},
		[
			endDate,
			previousEndDate,
			previousStartDate,
			startDate,
			visibleDate,
		],
	);

	const checkDisabledDate = React.useCallback(
		(date) => {
			if (isFunction(disabledDate)) {
				const dateDisabled = disabledDate(
					date,
					{
						activeControl,
						endDate,
						startDate,
					},
				);

				if (dateDisabled) {
					return true;
				}
			}

			if (activeControl === DateRangeCalenderPickerControl.End && startDate !== null) {
				return !isSameDay(date, startDate) && isBefore(date, startDate);
			}

			return false;
		},
		[
			activeControl,
			disabledDate,
			endDate,
			startDate,
		],
	);

	const handlePresetClick = React.useCallback(
		(preset: DateRangePreset) => {
			if (isFunction(onPresetSelect)) {
				onPresetSelect(preset);
			}

			setActiveControl(DateRangeCalenderPickerControl.Start);
		},
		[
			onPresetSelect,
		],
	);

	const handleDayClick = React.useCallback(
		(selectedDate) => {
			if (activeControl === DateRangeCalenderPickerControl.Start) {
				onRangeSelect({
					startDate: selectedDate,
					endDate: (
						endDate !== null
							? maxDate([selectedDate, endDate])
							: null
					),
				});

				setActiveControl(DateRangeCalenderPickerControl.End);
			} else {
				onRangeSelect({
					startDate,
					endDate: selectedDate,
				});

				setActiveControl(DateRangeCalenderPickerControl.Start);
			}
		},
		[
			activeControl,
			endDate,
			onRangeSelect,
			startDate,
		],
	);

	const handleViewPreviousMonthClick = React.useCallback(
		() => {
			const nextVisibleDate = startOfMonth(
				subMonths(visibleDate, 1),
			);

			if (isBefore(lowerBound, nextVisibleDate)) {
				setVisibleDate(nextVisibleDate);
			}
		},
		[
			lowerBound,
			visibleDate,
		],
	);

	const handleViewNextMonthClick = React.useCallback(
		() => {
			const nextVisibleDate = startOfMonth(
				addMonths(visibleDate, 1),
			);

			if (isAfter(upperBound, nextVisibleDate)) {
				setVisibleDate(nextVisibleDate);
			}
		},
		[
			upperBound,
			visibleDate,
		],
	);

	const handleMonthYearPickerSubmit = React.useCallback(
		(date) => {
			if (pickMonthYearDate === null) {
				return;
			}

			const monthsDifference = differenceInMonths(pickMonthYearDate, visibleDate);

			setPickMonthYearDate(null);
			setVisibleDate(subMonths(date, monthsDifference));
		},
		[
			pickMonthYearDate,
			visibleDate,
		],
	);

	return (
		<CalendarPickerLayout
			overlay={pickMonthYearDate !== null && (
				<MonthYearPicker
					endYear={upperBound.getFullYear()}
					onSubmitCallback={handleMonthYearPickerSubmit}
					startYear={lowerBound.getFullYear()}
					submitButtonLabel="Apply"
					value={pickMonthYearDate}
				/>
			)}
			presets={presetsOptions && (
				<SimpleNavigation>
					{presetsOptions.map((option, index) => (
						<SimpleNavigationItem
							highlightStyle={SimpleNavigationItemHighlightStyle.Strong}
							hint={option.disabledExplanation}
							isDisabled={option.disabled}
							isHighlighted={option.highlighted}
							key={'nav-item-' + index}
							onClickCallback={() => handlePresetClick(option.value)}
						>
							{option.label}
						</SimpleNavigationItem>
					))}
				</SimpleNavigation>
			)}
			range={(
				<DateRangeSelector
					endDate={formatDate(endDate)}
					isEndDateSelected={activeControl === DateRangeCalenderPickerControl.End}
					isStartDateSelected={activeControl === DateRangeCalenderPickerControl.Start}
					onEndDateSelectionCallback={() => {
						setActiveControl(DateRangeCalenderPickerControl.End);
					}}
					onStartDateSelectionCallback={() => {
						setActiveControl(DateRangeCalenderPickerControl.Start);
					}}
					startDate={formatDate(startDate)}
				/>
			)}
		>
			<CalendarController
				currentDate={subMonths(visibleDate, 1)}
				onCurrentMonthClickCallback={() => setPickMonthYearDate(subMonths(visibleDate, 1))}
				onPrevMonthSelectionClickCallback={handleViewPreviousMonthClick}
			>
				<Calendar
					currentDate={subMonths(visibleDate, 1)}
					disabledDate={checkDisabledDate}
					onClickCallback={handleDayClick}
					selectedDate={[startDate, endDate]}
				/>
			</CalendarController>
			<CalendarController
				currentDate={visibleDate}
				onCurrentMonthClickCallback={() => setPickMonthYearDate(visibleDate)}
				onNextMonthSelectionClickCallback={handleViewNextMonthClick}
			>
				<Calendar
					currentDate={visibleDate}
					disabledDate={checkDisabledDate}
					onClickCallback={handleDayClick}
					selectedDate={[startDate, endDate]}
				/>
			</CalendarController>
		</CalendarPickerLayout>
	);
};



export default DateRangeCalendarPicker;
