import {
	get,
} from './api/API';



const REVISIONS_PARAM_SEPARATOR = '...';



export function loadInvalidSyntaxInRobotsTxt(revisionId, robotsTxtId, websiteId) {
	return get('/websites/' + websiteId + '/robots_txt/' + robotsTxtId + '/revision/' + revisionId + '/invalid_syntax');
}



export function packRobotsTxtRevisionsParameter(revisionA, revisionB) {
	return revisionA + REVISIONS_PARAM_SEPARATOR + revisionB;
}



export function unpackRobotsTxtRevisionsParameter(revisionsParameter) {
	const result = revisionsParameter.split(REVISIONS_PARAM_SEPARATOR);

	if (result.length !== 2) {
		return {
			revisionA: null,
			revisionB: null,
		};
	}

	return {
		revisionA: result[0],
		revisionB: result[1],
	};
}
