import React from 'react';

import {
	FormContext,
	type FormContextPayload,
} from '~/components/atoms/forms/basis/Form';



type Props = {
	children: React.ReactNode,
	context: FormContextPayload,
};

const FormFieldsContext: React.FC<Props> = (props) => {
	const {
		children,
		context,
	} = props;

	return (
		<FormContext.Provider value={context}>
			{children}
		</FormContext.Provider>
	);
};



export default FormFieldsContext;
