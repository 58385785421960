import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CancelButton from '~/components/app/CancelButton';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useRefreshReportingApiTokenMutation,
} from './RefreshReportingApiTokenModal.gql';

import useAccountId from '~/hooks/useAccountId';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const messages = defineMessages({
	description: {
		id: 'ui.teamDetail.accountSettings.changeReportingApiHint',
	},
	submitButton: {
		id: 'ui.button.changeReportingApiToken',
	},
	title: {
		id: 'ui.teamDetail.accountSettings.changeReportingApiLink',
	},
});



const RefreshReportingApiTokenModal: React.FC = () => {
	const accountId = useAccountId();
	const classicFormBehavior = useClassicFormBehavior();

	const [refreshReportingApiToken] = useRefreshReportingApiTokenMutation();

	const handleSubmit = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			await refreshReportingApiToken({
				variables: {
					accountId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			accountId,
			classicFormBehavior,
			refreshReportingApiToken,
		],
	);

	return (
		<ModalContainer
			header={(
				<ModalHeader
					title={(
						<FormattedMessage {...messages.title} />
					)}
				/>
			)}
		>
			<FormattedMessage {...messages.description} />

			<div className="modal__form">
				<Form
					defaultDataHasChanged={true}
					onSuccess={handleSubmit}
				>
					<ButtonsLayout>
						<CancelButton />

						<SubmitButton positive={false}>
							<FormattedMessage {...messages.submitButton} />
						</SubmitButton>
					</ButtonsLayout>
				</Form>
			</div>
		</ModalContainer>
	);
};



export default RefreshReportingApiTokenModal;
