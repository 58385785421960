import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FormRow from '../atoms/forms/basis/FormRow';
import FormRows from '../atoms/forms/basis/FormRows';
import StaticText from '../atoms/forms/components/StaticText';



const messages = defineMessages({
	cardHolderLabel: {
		id: 'ui.creditCard.cardHolder',
	},
	cardNumberLabel: {
		id: 'ui.creditCard.cardNumber',
	},
});



class CreditCardDetails extends Component {

	render() {
		const {
			billingDetails,
		} = this.props;

		const holder = billingDetails.get('payment_method')
			? billingDetails.get('payment_method').get('details').get('holder')
			: '';

		const number = '**** **** **** *' + (billingDetails.get('payment_method')
			? billingDetails.get('payment_method').get('details').get('number')
			: '');

		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.cardHolderLabel} />
					)}
				>
					<StaticText>
						{holder}
					</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.cardNumberLabel} />
					)}
				>
					<StaticText>
						{number}
					</StaticText>
				</FormRow>
			</FormRows>
		);
	}

}

CreditCardDetails.propTypes = {
	billingDetails: PropTypes.object.isRequired,
};



export default CreditCardDetails;
