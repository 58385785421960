import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import AttachedNote from '~/components/patterns/structuredValues/AttachedNote';
import ConnectedInlineTextParts from '~/components/patterns/lists/ConnectedInlineTextParts';
import EmptyValue from '~/components/app/EmptyValue';
import IgnoredValue from '~/components/patterns/issues/IgnoredValue';
import IssueInlineCaseDescription from '~/components/logic/issues/IssueInlineCaseDescription';
import MissingValue from '~/components/app/MissingValue';
import TextualList from '~/components/patterns/lists/TextualList';
import Ellipsis from '~/components/patterns/values/Ellipsis';



class OpenIssuesCellValue extends Component {

	render() {
		const {
			interestingIssues,
			mainContent,
			openIssues,
		} = this.props;

		let valueElement = mainContent;

		if (mainContent === null || mainContent === undefined) {
			valueElement = (
				<MissingValue ellipsis={true} />
			);
		} else if (mainContent === '') {
			valueElement = (
				<EmptyValue ellipsis={true} />
			);
		}

		const openIssuesLabels = [];

		openIssues
			.filter((issue) => interestingIssues.includes(issue.get('issue')))
			.forEach((issue) => {
				if (issue.get('is_ignored')) {
					openIssuesLabels.push(
						<IgnoredValue key={issue.get('issue')}>
							<IssueInlineCaseDescription
								issue={{
									context: issue.get('context')?.toJS() ?? {},
									name: issue.get('issue'),
								}}
							/>
						</IgnoredValue>,
					);
				} else {
					openIssuesLabels.push(
						<IssueInlineCaseDescription
							issue={{
								context: issue.get('context')?.toJS() ?? {},
								name: issue.get('issue'),
							}}
							key={issue.get('issue')}
						/>,
					);
				}
			});

		if (openIssuesLabels.length === 0) {
			openIssuesLabels.push((
				<span key="space">&nbsp;</span>
			));
		}

		return (
			<AttachedNote
				note={(
					<Ellipsis
						altPopupText={(
							<TextualList>
								{openIssuesLabels}
							</TextualList>
						)}
					>
						<ConnectedInlineTextParts>
							{openIssuesLabels}
						</ConnectedInlineTextParts>
					</Ellipsis>
				)}
			>
				{valueElement}
			</AttachedNote>
		);
	}

}

OpenIssuesCellValue.propTypes = {
	interestingIssues: PropTypes.array.isRequired,
	openIssues: PropTypes.any.isRequired,
	mainContent: PropTypes.node.isRequired,
};



export default OpenIssuesCellValue;
