/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisabledMembershipsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type DisabledMembershipsQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId }>, readonly allMembershipLabels: ReadonlyArray<{ readonly __typename?: 'AuthenticatedSessionAccountMembershipLabel', readonly accountDisplayName: string, readonly accountId: CK.AccountId, readonly numberOfWebsitesInLimitedWebsitesAccess: number | null, readonly role: GraphQL.UserRole }> } | null };


export const DisabledMembershipsDocument = gql`
    query DisabledMemberships {
  authenticatedSession {
    accounts {
      displayName
      id
    }
    allMembershipLabels {
      accountDisplayName
      accountId
      numberOfWebsitesInLimitedWebsitesAccess
      role
    }
  }
}
    `;

/**
 * __useDisabledMembershipsQuery__
 *
 * To run a query within a React component, call `useDisabledMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisabledMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisabledMembershipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisabledMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<DisabledMembershipsQuery, DisabledMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisabledMembershipsQuery, DisabledMembershipsQueryVariables>(DisabledMembershipsDocument, options);
      }
export function useDisabledMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisabledMembershipsQuery, DisabledMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisabledMembershipsQuery, DisabledMembershipsQueryVariables>(DisabledMembershipsDocument, options);
        }
export function useDisabledMembershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DisabledMembershipsQuery, DisabledMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DisabledMembershipsQuery, DisabledMembershipsQueryVariables>(DisabledMembershipsDocument, options);
        }
export type DisabledMembershipsQueryHookResult = ReturnType<typeof useDisabledMembershipsQuery>;
export type DisabledMembershipsLazyQueryHookResult = ReturnType<typeof useDisabledMembershipsLazyQuery>;
export type DisabledMembershipsSuspenseQueryHookResult = ReturnType<typeof useDisabledMembershipsSuspenseQuery>;
export type DisabledMembershipsQueryResult = Apollo.QueryResult<DisabledMembershipsQuery, DisabledMembershipsQueryVariables>;