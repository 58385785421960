import Immutable, {
	Map,
} from 'immutable';

import {
	LOGIN_SUCCESSFUL,
	LOGOUT_SUCCESSFUL,
	STORE_LOADED_TEAMS,
	STORE_UPDATED_TEAM,
} from '~/actions';
import {
	STORE_UPDATED_WEBSITE,
	STORE_VIRTUAL_ROBOTS_RULES,
} from '~/actions/websites';
import {
	createDisplayName,
	extractInitials,
} from '~/model/users';



function createDefaultState() {
	return new Map();
}



function createWebsiteEntity(website) {
	if (!(website instanceof Map)) {
		website = Immutable.fromJS(website);
	}

	if (website.has('custom_elements')) {
		website = website.set('numberOfCustomElements', website.get('custom_elements').size);
	}

	if (website.has('segments')) {
		website = website.set('numberOfSegments', website.get('segments').size);
	}

	website = website.remove('crawling_speed');
	website = website.remove('custom_elements');
	website = website.remove('segments');

	if (website.get('_already_entity')) {
		return website;
	}

	website = website.set(
		'id',
		website.get('id').toString(),
	);
	website = website.set(
		'team_id',
		website.get('team_id').toString(),
	);
	website = website.set(
		'displayName',
		website.get('name') || website.get('original_domain') || website.get('domain'),
	);
	website = website.set(
		'virtualRobotsRulesCount',
		website.get('virtual_robots_rules_count', 0),
	);

	website = website.set('_already_entity', true);

	return website;
}



function sortWebsites(unsortedWebsites) {
	return unsortedWebsites.sort((websiteA, websiteB) => {
		const displayNameA = websiteA.get('displayName').toLowerCase();
		const displayNameB = websiteB.get('displayName').toLowerCase();

		if (displayNameA < displayNameB) {
			return -1;
		}

		if (displayNameA > displayNameB) {
			return 1;
		}

		return 0;
	});
}



function processWebsites(state) {
	return state.map((team) => {
		team = team.remove('client_connections');
		team = team.remove('connected_agency');
		team = team.remove('connecting_agencies');

		return team.set('websites', sortWebsites(team.get('websites').map((website) => {
			return createWebsiteEntity(website);
		})));
	});
}



function processMembers(state) {
	return state.map((team) => {
		const members = team.get('members').map((member) => {
			const displayName = createDisplayName(member);

			member = member.set('invited', false);
			member = member.set('displayName', displayName);
			member = member.set('initials', extractInitials(displayName));

			member = member.remove('emailSettings');

			return member;
		});

		const invitations = team.get('invitations').map((invitation) => {
			const email = invitation.get('email');

			invitation = invitation.set('invited', true);
			invitation = invitation.set('invitation_id', invitation.get('id'));
			invitation = invitation.set('id', 'invited-' + invitation.get('id'));
			invitation = invitation.set('initials', extractInitials(email));
			return invitation.set('displayName', email);
		});

		team = team.set('membersCount', members.size);
		team = team.set('members', members.concat(invitations));

		return team;
	});
}



export const basics = function (state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case LOGIN_SUCCESSFUL: {
			const {
				response,
			} = action;

			if (response.team) {
				state = processWebsites(state.mergeDeep({
					[response.team.id]: response.team,
				}));
				state = processMembers(state);
			}

			break;
		}

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case STORE_LOADED_TEAMS: {
			const {
				teams,
			} = action;

			const oldTeams = state;
			let newTeams = Immutable.fromJS(teams);
			newTeams = processWebsites(newTeams);
			newTeams = processMembers(newTeams);

			if (!Immutable.is(oldTeams, newTeams)) {
				state = newTeams;
			}

			break;
		}

		case STORE_UPDATED_TEAM: {
			const {
				team,
			} = action;

			let newTeams = Immutable.fromJS({ [team.id.toString()]: team });
			newTeams = processWebsites(newTeams);
			newTeams = processMembers(newTeams);

			state = state.set(team.id.toString(), newTeams.get(team.id.toString()));

			break;
		}

		case STORE_UPDATED_WEBSITE: {
			const website = createWebsiteEntity(action.website);
			const websites = state.get(website.get('team_id')).get('websites');

			const key = websites.findKey((entry) => entry.get('id') === website.get('id'));

			state = state.mergeDeep({
				[website.get('team_id')]: {
					websites: websites.set(
						key,
						website,
					),
				},
			});

			break;
		}

		case STORE_VIRTUAL_ROBOTS_RULES: {
			const {
				accountId,
				websiteId,
				rules,
			} = action;

			const websiteKey = state
				.get(accountId.toString())
				.get('websites')
				.findKey((website) => website.get('id') === websiteId);

			state = state.updateIn(
				[
					accountId.toString(),
					'websites',
					websiteKey,
				],
				(website) => {
					const newRules = Immutable.fromJS(rules.sort().map((rule) => {
						return new Map({
							rule,
							deleted: false,
						});
					}));

					website = website.set('virtualRobotsRules', newRules);
					website = website.set('virtualRobotsRulesCount', newRules.size);

					return website;
				},
			);

			break;
		}

	}

	return state;
};
