import memoize from 'memoizee';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	type WebsiteLogFileAnalysisSetupQuery,
	useWebsiteLogFileAnalysisSetupQuery,
} from './useWebsiteLogFileAnalysisSetup.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



type ReturnType = {
	hasAvailableLogSources: boolean,
	isLoading: boolean,
	isLogFileAnalysisEnabled: boolean,
	isMissingLogSource: boolean,
	logSources: ReadonlyArray<{
		provider: GraphQL.LogSourceProvider,
		status: GraphQL.LogSourceStatus,
	}>,
	getStatusForLogSource: (logSource: GraphQL.LogSourceProvider) => GraphQL.LogSourceStatus,
};



const format = memoize(
	(
		data: WebsiteLogFileAnalysisSetupQuery | undefined,
		loading: boolean,
	) => {
		const isLogFileAnalysisEnabled = data?.website?.isLogFileAnalysisEnabled ?? false;
		const logSources = data?.website?.logSources ?? [];

		let hasAvailableLogSources = false;
		let isMissingLogSource = isLogFileAnalysisEnabled;

		logSources
			.forEach((source) => {
				if (
					source.status === GraphQL.LogSourceStatus.Enabled
					|| source.status === GraphQL.LogSourceStatus.Available
				) {
					hasAvailableLogSources = true;
				}

				if (isMissingLogSource && source.status === GraphQL.LogSourceStatus.Enabled) {
					isMissingLogSource = false;
				}
			});

		function getStatusForLogSource(provider: GraphQL.LogSourceProvider) {
			const logSource = logSources.find((logSource) => logSource.provider === provider);

			return logSource?.status ?? GraphQL.LogSourceStatus.NotAvailable;
		}

		return {
			getStatusForLogSource,
			hasAvailableLogSources,
			isLoading: loading,
			isLogFileAnalysisEnabled,
			isMissingLogSource,
			logSources,
		};
	},
);



function useWebsiteLogFileAnalysisSetup(websiteId: CK.WebsiteId | null): ReturnType {
	const { data, loading } = useWebsiteLogFileAnalysisSetupQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return format(
		data,
		loading,
	);
}



export default useWebsiteLogFileAnalysisSetup;
