import classNames from 'classnames';
import React from 'react';

import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';



type Props = {
	isOpen?: boolean,
	label: React.ReactNode,
};



const CompareModePickerToggler: React.FC<Props> = (props) => {
	const {
		isOpen,
		label,
	} = props;

	const togglerClasses = classNames({
		'compare-mode-picker-toggler': true,
		'compare-mode-picker-toggler--closed': !isOpen,
		'compare-mode-picker-toggler--open': isOpen,
	});

	return (
		<div className={togglerClasses}>
			<span className="compare-mode-picker-toggler__label">
				{label}
			</span>
			<span className="compare-mode-picker-toggler__dropdown-icon">
				<DropdownIcon
					isActive={isOpen}
					style={isOpen ? DropdownIconStyle.White : DropdownIconStyle.Default}
				/>
			</span>
		</div>
	);
};



export default CompareModePickerToggler;
