import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';
import {
	defineMessages,
	injectIntl,
} from 'react-intl';

import DonutPagesChart from '../components/DonutPagesChart';



const messages = defineMessages({
	allowed: {
		no: {
			id: 'ui.contentOverview.allowed.no',
		},
		yes: {
			id: 'ui.contentOverview.allowed.yes',
		},
	},
});



class RobotsTxtChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		const {
			intl,
		} = this.props;

		this.specificColors = {
			true: '#FF743B',
			false: '#42CC67',
		};

		this.labels = {
			true: intl.formatMessage(messages.allowed.no),
			false: intl.formatMessage(messages.allowed.yes),
		};

		this.order = [
			false,
			true,
		];
	}



	render() {
		const {
			data,
		} = this.props;

		return (
			<DonutPagesChart
				data={data}
				labels={this.labels}
				name="is_disallowed_in_robots_txt"
				order={this.order}
				percentageFactor={100}
				specificColors={this.specificColors}
			/>
		);
	}

}

RobotsTxtChart.propTypes = {
	data: PropTypes.instanceOf(List),
};



export default injectIntl(RobotsTxtChart);
