/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdministerUserSettingsMutationVariables = GraphQL.Exact<{
  isAlertRecipientByDefault: GraphQL.Scalars['Boolean']['input'];
  isReportRecipientByDefault: GraphQL.Scalars['Boolean']['input'];
  legacyUserId: GraphQL.Scalars['ID']['input'];
  mainAccountId: GraphQL.Scalars['AccountId']['input'];
  newEmail: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  organizationTeam: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  platformTourWebinarOffering: GraphQL.InputMaybe<GraphQL.PlatformTourWebinarOffering>;
  reviewSites: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type AdministerUserSettingsMutation = { readonly __typename?: 'Mutation', readonly AdministerUserSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly organizationTeams: ReadonlyArray<string> | null }> } | null, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly isAlertRecipientByDefault: boolean, readonly isReportRecipientByDefault: boolean, readonly legacyId: string, readonly mainAccountId: CK.AccountId | null, readonly organizationTeam: string | null, readonly platformTourWebinarOffering: GraphQL.PlatformTourWebinarOffering, readonly reviewSites: ReadonlyArray<string> | null } | null } } };


export const AdministerUserSettingsDocument = gql`
    mutation AdministerUserSettings($isAlertRecipientByDefault: Boolean!, $isReportRecipientByDefault: Boolean!, $legacyUserId: ID!, $mainAccountId: AccountId!, $newEmail: String, $organizationTeam: String, $platformTourWebinarOffering: PlatformTourWebinarOffering, $reviewSites: [String!]!) {
  AdministerUserSettings(
    isAlertRecipientByDefault: $isAlertRecipientByDefault
    isReportRecipientByDefault: $isReportRecipientByDefault
    legacyUserId: $legacyUserId
    mainAccountId: $mainAccountId
    newEmail: $newEmail
    organizationTeam: $organizationTeam
    platformTourWebinarOffering: $platformTourWebinarOffering
    reviewSites: $reviewSites
  ) {
    query {
      authenticatedSession {
        accounts {
          id
          organizationTeams
        }
      }
      user(legacyId: $legacyUserId) {
        email
        id
        isAlertRecipientByDefault
        isReportRecipientByDefault
        legacyId
        mainAccountId
        organizationTeam
        platformTourWebinarOffering
        reviewSites
      }
    }
  }
}
    `;
export type AdministerUserSettingsMutationFn = Apollo.MutationFunction<AdministerUserSettingsMutation, AdministerUserSettingsMutationVariables>;

/**
 * __useAdministerUserSettingsMutation__
 *
 * To run a mutation, you first call `useAdministerUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerUserSettingsMutation, { data, loading, error }] = useAdministerUserSettingsMutation({
 *   variables: {
 *      isAlertRecipientByDefault: // value for 'isAlertRecipientByDefault'
 *      isReportRecipientByDefault: // value for 'isReportRecipientByDefault'
 *      legacyUserId: // value for 'legacyUserId'
 *      mainAccountId: // value for 'mainAccountId'
 *      newEmail: // value for 'newEmail'
 *      organizationTeam: // value for 'organizationTeam'
 *      platformTourWebinarOffering: // value for 'platformTourWebinarOffering'
 *      reviewSites: // value for 'reviewSites'
 *   },
 * });
 */
export function useAdministerUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<AdministerUserSettingsMutation, AdministerUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerUserSettingsMutation, AdministerUserSettingsMutationVariables>(AdministerUserSettingsDocument, options);
      }
export type AdministerUserSettingsMutationHookResult = ReturnType<typeof useAdministerUserSettingsMutation>;
export type AdministerUserSettingsMutationResult = Apollo.MutationResult<AdministerUserSettingsMutation>;
export type AdministerUserSettingsMutationOptions = Apollo.BaseMutationOptions<AdministerUserSettingsMutation, AdministerUserSettingsMutationVariables>;