import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import Slider from 'rc-slider';
import {
	usePopper,
} from 'react-popper';
import {
	type Placement,
} from '@popperjs/core';

import DepthLayer from '~/components/patterns/utils/DepthLayer';
import PopupBubble, {
	PopupBubbleSize as CountSliderHandlerBubbleSize,
	PopupBubbleContentAlignment,
	type PopupBubbleRef,
	PopupBubbleStyle,
} from '~/components/patterns/popups/PopupBubble';



export enum CountSliderBubbleAlignment {
	Left = 'left',
	Center = 'center',
	Right = 'right',
}

export enum CountSliderBubbleAttachment {
	Bottom = 'bottom',
	Top = 'top',
}

export enum CountSliderBubbleContentAlignment {
	Left = 'left',
	Center = 'center',
}

export enum CountSliderStyle {
	Expressive = 'expressive',
	Neutral = 'neutral',
}

export { CountSliderHandlerBubbleSize };

type Props = {
	animatedHandle?: boolean,
	customHighlightColor?: string,
	disabled?: boolean,
	handlerBubble?: React.ReactNode,
	handlerBubbleAlignment?: CountSliderBubbleAlignment,
	handlerBubbleAttachment?: CountSliderBubbleAttachment,
	handlerBubbleContentAlignment?: CountSliderBubbleContentAlignment,
	handlerBubbleSize?: CountSliderHandlerBubbleSize,
	highlightedValue?: number,
	max: number,
	min?: number,
	onAfterChangeCallback?: () => void,
	onBeforeChangeCallback?: () => void,
	onChangeCallback?: (step: number) => void,
	/** Reversed highlight of active range */
	reversed?: boolean,
	/** Enable displaying of step marks */
	showStepMarks?: boolean,
	step?: number,
	style?: CountSliderStyle,
	value: number,
};

const popperDropdownContainer = document.getElementById('popper-elements');



const CountSlider: React.FC<Props> = (props) => {
	const {
		animatedHandle,
		customHighlightColor,
		disabled = false,
		handlerBubble,
		handlerBubbleAlignment = CountSliderBubbleAlignment.Center,
		handlerBubbleAttachment = CountSliderBubbleAttachment.Top,
		handlerBubbleContentAlignment = CountSliderBubbleContentAlignment.Left,
		handlerBubbleSize = CountSliderHandlerBubbleSize.Medium,
		highlightedValue,
		max = 100,
		min = 0,
		onAfterChangeCallback,
		onBeforeChangeCallback,
		onChangeCallback,
		reversed = false,
		showStepMarks = false,
		step = 1,
		style = CountSliderStyle.Neutral,
		value,
	} = props;

	const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
	const bubbleRef = React.useRef<PopupBubbleRef>(null);

	let placement = handlerBubbleAttachment === CountSliderBubbleAttachment.Top ? 'top' : 'bottom';

	if (handlerBubbleAlignment === CountSliderBubbleAlignment.Left) {
		placement += '-start';
	}

	if (handlerBubbleAlignment === CountSliderBubbleAlignment.Right) {
		placement += '-end';
	}

	const offsetModifier = React.useMemo(
		() => ({
			name: 'offset',
			options: {
				offset: ({ placement }) => {
					let xOffset = 0;

					if (placement === 'bottom-start' || placement === 'top-start') {
						xOffset = -10;
					}

					if (placement === 'bottom-end' || placement === 'top-end') {
						xOffset = 10;
					}

					if (placement === 'bottom-start' || placement === 'bottom-end' || placement === 'bottom') {
						return [xOffset, style === CountSliderStyle.Neutral ? 10 : 13];
					}

					if (placement === 'top-start' || placement === 'top-end' || placement === 'top') {
						return [xOffset, style === CountSliderStyle.Neutral ? 10 : 13];
					}

					return [0, 0];
				},
			},
		}),
		[
			style,
		],
	);
	const {
		state: popperState,
		styles,
		attributes,
		update,
	} = usePopper(
		referenceElement,
		popperElement,
		{
			placement: placement as Placement,
			modifiers: [
				{
					name: 'flip',
					options: {
						boundary: 'clippingParents',
						altBoundary: true,
						// First useful placement will be used when there won't be enough space
						// for default placement.
						fallbackPlacements: ['bottom', 'bottom-start', 'bottom-end', 'top', 'top-start', 'top-end'],
					},
				},
				{
					name: 'preventOverflow',
					options: {
						mainAxis: false,
					},
				},
				{
					name: 'arrow',
					options: {
						element: bubbleRef.current?.getArrowRef().current,
					},
				},
				offsetModifier,
			],
		},
	);

	React.useEffect(
		() => {
			if (value && update) {
				update();
			}
		},
		[
			update,
			value,
		],
	);

	const countChangeHandler = (step) => {
		if (update) {
			update();
		}

		if (!disabled && onChangeCallback) {
			onChangeCallback(step);
		}
	};

	const changeStartHandler = React.useCallback(
		() => {
			if (!disabled) {
				document.getElementsByTagName('body')[0]?.classList.add('js-count-slider-dragging');
			}

			if (onBeforeChangeCallback) {
				onBeforeChangeCallback();
			}
		},
		[
			disabled,
			onBeforeChangeCallback,
		],
	);

	const changeCompleteHandler = React.useCallback(
		() => {
			if (!disabled) {
				setTimeout(() => {
					document.getElementsByTagName('body')[0]?.classList.remove('js-count-slider-dragging');
				}, 0);
			}

			if (onAfterChangeCallback) {
				onAfterChangeCallback();
			}
		},
		[
			disabled,
			onAfterChangeCallback,
		],
	);

	const getHandlerPosition = (value) => {
		return `${((value / max) * 100)}%`;
	};

	const minValueHighlightMark = () => {
		if (!highlightedValue) {
			return null;
		}

		return (
			<div
				className="range-slider__limit-highlight"
				style={{
					width: getHandlerPosition(highlightedValue),
				}}
			/>
		);
	};

	const marks = () => {
		const marks = {};

		if (highlightedValue) {
			marks[highlightedValue] = (
				<span></span>
			);
		}

		if (showStepMarks) {
			for (let index = 0; index <= max; index += step) {
				if (highlightedValue && highlightedValue > index) {
					continue;
				}

				marks[index] = (
					<span></span>
				);
			}
		}

		return marks;
	};

	const handlerTooltip = () => {
		const popupBubbleLeft = getHandlerPosition(value);

		return (
			<DepthLayer>
				{({ depthLayer }) => (
					<>
						<div
							className="range-slider__bubble-attachment"
							data-popper-placement={popperState && popperState.placement}
							ref={setReferenceElement}
							style={{
								left: popupBubbleLeft,
							}}
						/>
						{handlerBubble && popperDropdownContainer && ReactDOM.createPortal(
							<div
								className={classNames({
									'range-slider__bubble': true,
									['range-slider__bubble--' + style]: true,
								})}
								ref={setPopperElement}
								style={{
									zIndex: depthLayer,
									...styles.popper,
								}}
								{...attributes.popper}
							>
								<PopupBubble
									arrowStyles={styles.arrow}
									contentAlignment={handlerBubbleContentAlignment === CountSliderBubbleContentAlignment.Center ? PopupBubbleContentAlignment.Center : PopupBubbleContentAlignment.Left}
									ref={bubbleRef}
									size={handlerBubbleSize}
									style={PopupBubbleStyle.Dark}
								>
									{handlerBubble}
								</PopupBubble>
							</div>,
							popperDropdownContainer,
						)}
					</>
				)}
			</DepthLayer>
		);
	};

	const componentClasses = classNames({
		'range-slider': true,
		'range-slider--disabled': disabled,
		['range-slider--' + style + '-style']: true,
	});

	return (
		<div
			className={componentClasses}
			style={{
				['--track-background' as any]: customHighlightColor,
			}}
		>
			<div className="range-slider__slider">
				{minValueHighlightMark()}
				{handlerTooltip()}

				<Slider
					className={classNames({
						'rc-slider-reversed': reversed,
						['rc-slider--' + style + '-style']: true,
						'rc-slider--animated-handle': animatedHandle,
					})}
					defaultValue={value}
					disabled={disabled}
					marks={marks()}
					max={max}
					min={min}
					onAfterChange={changeCompleteHandler}
					onBeforeChange={changeStartHandler}
					onChange={countChangeHandler}
					startPoint={highlightedValue}
					step={step}
					value={value}
				/>
			</div>
		</div>
	);
};



export default CountSlider;
