import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountPaymentStatusQuery,
} from './useAccountPaymentStatus.gql';



type ReturnType = GraphQL.Account['paymentStatus'] | null;



function useAccountPaymentStatus(accountId: CK.AccountId): ReturnType {
	const { data } = useAccountPaymentStatusQuery({
		variables: {
			accountId,
		},
	});

	return data?.account?.paymentStatus ?? null;
}



export default useAccountPaymentStatus;
