import React from 'react';

import type CK from '~/types/contentking';

import ExternalLink from '~/components/patterns/links/ExternalLink';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import WithSeparator from '~/components/atoms/WithSeparator';

import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import usePageScrapbookLinkForRequestLogs from '~/hooks/usePageScrapbookLinkForRequestLogs';
import useUrlId from '~/hooks/useUrlId';



type Props = {
	children: React.ReactNode,
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const PageHeaderTitleRoyalEnhancement: React.FC<Props> = (props) => {
	const {
		children,
		legacyUrlId,
		websiteId,
	} = props;

	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const scrapbookLinkForRequestLogs = usePageScrapbookLinkForRequestLogs(legacyUrlId, websiteId);
	const urlId = useUrlId(legacyUrlId, websiteId);

	if (
		kingdomAdminFeatures.areVisible === false
		|| scrapbookLinkForRequestLogs === null
		|| urlId === null
	) {
		return (
			<>{children}</>
		);
	}

	return (
		<Hint
			attachment={HintAttachment.Left}
			blurDelay={1000}
			popup={(
				<WithSeparator separator=", ">
					{urlId}
					<ExternalLink href={scrapbookLinkForRequestLogs}>
						Open request logs
					</ExternalLink>
				</WithSeparator>
			)}
			popupZIndex={2000}
		>
			{children}
		</Hint>
	);
};



export default PageHeaderTitleRoyalEnhancement;
