import React from 'react';



type Props = {
	children?: React.ReactNode,
	className?: string,
	url: string,
};



const NewTabLink: React.FC<Props> = (props) => {
	const {
		children,
		className,
		url,
	} = props;

	const handleClick = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			window.open(url, '_blank');
		},
		[
			url,
		],
	);

	return (
		<span
			className={className}
			onClick={handleClick}
		>
			{children}
		</span>
	);
};



export default NewTabLink;
