import React from 'react';
import {
	useSelector,
} from 'react-redux';

import PagesOverviewLink from '~/components/app/PagesOverviewLink';

import useCurrentColumnSetId from '~/hooks/useCurrentColumnSetId';
import useEnabledColumnsList from '~/hooks/useEnabledColumnsList';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	filterSelector as historicalChangesFilterSelector,
	dateIntervalSelector as historicalChangesIntervalSelector,
	sortBySelector as historicalChangesSortBySelector,
	isInHistoricalChangesModeSelector,
} from '~/state/historicalChanges/selectors';

import {
	appEmptyStateSelector,
	filterSelector as pagesFilterSelector,
	sortBySelector as pagesSortBySelector,
	selectedSectionSelector,
} from '~/state/ui/selectors';

import {
	pagesColumnsParameterSelector,
} from '~/state/ui/content/selectors';

import {
	type RenderProp,
} from '~/utilities/renderProp';



type ChildProps = {
	isActive: boolean,
	isDimmed: boolean,
};

type Props = {
	children: RenderProp<ChildProps>,
	className?: string,
	onClick?: () => void,
};

const CurrentPagesOverviewLink: React.FC<Props> = (props) => {
	const {
		children,
		className,
		onClick,
	} = props;

	const websiteId = useWebsiteId() as ReturnType<typeof useWebsiteId> | null;

	const appEmptyState = useSelector(appEmptyStateSelector);
	const columns = useEnabledColumnsList();
	const columnsParameter = useSelector(pagesColumnsParameterSelector);
	const historicalChangesFilter = useSelector(historicalChangesFilterSelector);
	const historicalChangesInterval = useSelector(historicalChangesIntervalSelector);
	const historicalChangesSortBy = useSelector(historicalChangesSortBySelector);
	const inHistoricalChangesMode = useSelector(isInHistoricalChangesModeSelector);
	const isActive = useSelector(selectedSectionSelector) === 'website.pages';
	const pagesFilter = useSelector(pagesFilterSelector);
	const pagesSortBy = useSelector(pagesSortBySelector);
	const selectedViewId = useCurrentColumnSetId();

	return (
		<PagesOverviewLink
			appEmptyState={appEmptyState}
			className={className}
			columns={columns}
			columnsParameter={columnsParameter}
			historicalChangesFilter={historicalChangesFilter}
			historicalChangesInterval={historicalChangesInterval}
			historicalChangesSortBy={historicalChangesSortBy}
			inHistoricalChangesMode={inHistoricalChangesMode}
			isActive={isActive}
			onClick={onClick}
			pagesFilter={pagesFilter}
			pagesSortBy={pagesSortBy}
			viewParameter={selectedViewId}
			websiteId={websiteId}
		>
			{children}
		</PagesOverviewLink>
	);
};



export default CurrentPagesOverviewLink;
