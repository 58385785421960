function getTariff({ tariff, tariffs }) {
	if (!tariffs.has(tariff)) {
		throw new Error(`Prices for tariff '${tariff}' aren't defined`);
	}

	return tariffs.get(tariff);
}



export function getAddonPrice({ addon, currency, plan, tariff, tariffs }) {
	return getTariff({
		tariff,
		tariffs,
	}).getIn([
		'plans',
		plan,
		addon,
		currency,
	]);
}



export function getPlanPrice({ currency, plan, tariff, tariffs }) {
	return getTariff({
		tariff,
		tariffs,
	}).getIn([
		'plans',
		plan,
		'base',
		currency,
	]);
}
