import classNames from 'classnames';
import React from 'react';

import styles from './TimelineMessageEntry.module.scss';



type Props = {
	/** Content of timeline entry */
	children: React.ReactNode,
	/** Flag that this entry is first from all entries */
	isFirstChild?: boolean,
	/** Highlighted type of message */
	isHighlighted?: boolean,
	/** Flag that this entry is last from all entries */
	isLastChild?: boolean,
};

const TimelineMessageEntry: React.FC<Props> = (props) => {
	const {
		children,
		isFirstChild,
		isHighlighted,
		isLastChild,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isFirst]: isFirstChild,
		[styles.isHighlighted]: isHighlighted,
		[styles.isLast]: isLastChild,
	});

	return (
		<section className={componentClasses}>
			<div className={styles.message}>
				{children}
			</div>
		</section>
	);
};



export default TimelineMessageEntry;
