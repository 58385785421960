import type CK from '~/types/contentking';

import {
	useWebsiteAccountIdQuery,
} from './useWebsiteAccountId.gql';

import useBatchContext from '~/hooks/useBatchContext';



function useWebsiteAccountId(websiteId: CK.WebsiteId | null) {
	const { data } = useWebsiteAccountIdQuery({
		context: useBatchContext('websites', 50),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return data?.website?.account?.id ?? null;
}



export default useWebsiteAccountId;
