/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageBundleQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type PageBundleQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly pageBundle: number } | null };


export const PageBundleDocument = gql`
    query PageBundle($accountId: AccountId!) {
  account(id: $accountId) {
    id
    pageBundle
  }
}
    `;

/**
 * __usePageBundleQuery__
 *
 * To run a query within a React component, call `usePageBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageBundleQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function usePageBundleQuery(baseOptions: Apollo.QueryHookOptions<PageBundleQuery, PageBundleQueryVariables> & ({ variables: PageBundleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageBundleQuery, PageBundleQueryVariables>(PageBundleDocument, options);
      }
export function usePageBundleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageBundleQuery, PageBundleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageBundleQuery, PageBundleQueryVariables>(PageBundleDocument, options);
        }
export function usePageBundleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageBundleQuery, PageBundleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageBundleQuery, PageBundleQueryVariables>(PageBundleDocument, options);
        }
export type PageBundleQueryHookResult = ReturnType<typeof usePageBundleQuery>;
export type PageBundleLazyQueryHookResult = ReturnType<typeof usePageBundleLazyQuery>;
export type PageBundleSuspenseQueryHookResult = ReturnType<typeof usePageBundleSuspenseQuery>;
export type PageBundleQueryResult = Apollo.QueryResult<PageBundleQuery, PageBundleQueryVariables>;