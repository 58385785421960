import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import Hint from '~/components/patterns/hints/hint/Hint';
import StatusFlag, {
	StatusFlagSize,
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';

import {
	useWebsiteStatusQuery,
} from './WebsiteStatus.gql';



const ICONS_MAPPING = {
	[GraphQL.WebsiteProblem.CapacityReached]: StatusFlagStatus.Critical,
	[GraphQL.WebsiteProblem.NearCapacity]: StatusFlagStatus.Warning,
	[GraphQL.WebsiteProblem.Paused]: StatusFlagStatus.Paused,
};

const messages = defineMessages({
	[GraphQL.WebsiteProblem.CapacityReached]: {
		id: 'ui.websites.websiteStatus.capacityReached',
	},
	[GraphQL.WebsiteProblem.NearCapacity]: {
		id: 'ui.websites.websiteStatus.nearCapacity',
	},
	[GraphQL.WebsiteProblem.Paused]: {
		id: 'ui.websites.websiteStatus.paused',
	},
});



type Props = {
	showStatus?: ReadonlyArray<GraphQL.WebsiteProblem | null>,
	websiteId: CK.WebsiteId,
};

const WebsiteStatus: React.FC<Props> = (props) => {
	const {
		showStatus = [
			GraphQL.WebsiteProblem.CapacityReached,
			GraphQL.WebsiteProblem.NearCapacity,
			null,
			GraphQL.WebsiteProblem.Paused,
		],
		websiteId,
	} = props;

	const { data } = useWebsiteStatusQuery({
		variables: {
			websiteId,
		},
	});

	const problem = data?.website?.problem ?? null;

	if (!showStatus.includes(problem)) {
		return null;
	}

	const icon = (
		<StatusFlag
			size={StatusFlagSize.Medium}
			status={problem !== null ? ICONS_MAPPING[problem] : StatusFlagStatus.Normal}
		/>
	);

	if (problem === null) {
		return icon;
	}

	return (
		<Hint
			popup={(
				<FormattedMessage {...messages[problem]} />
			)}
		>
			{icon}
		</Hint>
	);
};



export default WebsiteStatus;
