import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';
import TimelineCollapsedEntry from '~/components/patterns/time/timeline/TimelineCollapsedEntry';



const messages = defineMessages({
	newerChanges: {
		id: 'ui.pageDetail.history.newerChanges',
	},
	olderChanges: {
		id: 'ui.pageDetail.history.olderChanges',
	},
});

export enum MoreDataTimelineEntryDirection {
	Older,
	Newer,
}



type Props = {
	direction: MoreDataTimelineEntryDirection,
	onClick: () => void,
};

const MoreDataTimelineEntry: React.FC<Props> = (props) => {
	const {
		direction,
		onClick,
	} = props;

	return (
		<TimelineCollapsedEntry
			actionButton={(
				<Button
					onClick={onClick}
					size={ButtonSize.XXSmall}
					style={ButtonStyle.Hollow}
					suffixIcon={(
						<DropdownIcon
							isActive={direction === MoreDataTimelineEntryDirection.Newer}
							style={DropdownIconStyle.Greyscale}
						/>
					)}
				>
					{direction === MoreDataTimelineEntryDirection.Newer ? (
						<FormattedMessage {...messages.newerChanges} />
					) : (
						<FormattedMessage {...messages.olderChanges} />
					)}
				</Button>
			)}
			isFirstChild={direction === MoreDataTimelineEntryDirection.Newer}
			isLastChild={direction === MoreDataTimelineEntryDirection.Older}
		/>

	);
};



export default MoreDataTimelineEntry;
