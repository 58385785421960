import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import ActiveIncidentTimestamps from './ActiveIncidentTimestamps';
import IncidentDetails from './IncidentDetails';
import SharedIncidentCardLayout from './SharedIncidentCardLayout';



class ActiveIncidentCard extends Component {

	render() {
		const {
			incident,
			openAlertId,
			websiteId,
		} = this.props;

		return (
			<SharedIncidentCardLayout
				footer={(
					<ActiveIncidentTimestamps
						lastEvolvedAt={incident.get('last_evolved_at')}
						openedAt={incident.get('opened_at')}
					/>
				)}
				incident={incident}
				openAlertId={openAlertId}
			>
				<IncidentDetails
					incident={incident}
					websiteId={websiteId}
				/>
			</SharedIncidentCardLayout>
		);
	}

}

ActiveIncidentCard.propTypes = {
	incident: PropTypes.object.isRequired,
	openAlertId: PropTypes.number,
	websiteId: PropTypes.string.isRequired,
};



export default ActiveIncidentCard;
