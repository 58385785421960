import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



const messages = defineMessages({
	connect: {
		id: 'ui.googleLookerStudio.dashboard.connect',
	},
	open: {
		id: 'ui.googleLookerStudio.dashboard.open',
	},
});



const SendToGDSButton: React.FC = () => {
	const websiteId = useWebsiteId();
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.ReportingApi}
			hideIfUnattainable={true}
			style={PremiumFeatureSituationStyle.Tooltip}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => {
				const button = (
					<Button
						href={isFeatureEnabled ? 'https://datastudio.google.com/datasources/create?connectorId=AKfycbypxu88jN8zJKQvpkpoDcgy-c82trYIQV2WUrYJTzibOLfVy17I3_mMPhKgWO5-6lUV1g' : undefined}
						icon={(
							<BasicIcon type={BasicIconType.LookerStudio} />
						)}
						style={ButtonStyle.Hollow}
					>
						{websiteIntegrations.isEnabled(GraphQL.WebsiteIntegrationType.GoogleDataStudio)
							? <FormattedMessage {...messages.open} />
							: <FormattedMessage {...messages.connect} />
						}
					</Button>
				);

				if (isFeatureEnabled) {
					return button;
				} else if (premiumAnnotation !== null) {
					return React.cloneElement(premiumAnnotation, {
						children: button,
					});
				}

				return null;
			}}
		</PremiumFeatureSituation>
	);
};



export default SendToGDSButton;
