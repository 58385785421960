import React from 'react';

import EmbedImageIcon from '~/components/patterns/icons/EmbedImageIcon';
import SegmentFilterSegment from '~/components/atoms/segments/filters/SegmentFilterSegment';

import {
	type SegmentDefinition,
} from '~/model/segments';



type Props = {
	segmentDefinition: SegmentDefinition,
	showLabel?: boolean,
};

const SegmentDefinitionFilterIdentifier: React.FC<Props> = (props) => {
	const {
		segmentDefinition,
		showLabel = true,
	} = props;

	let symbol;

	if (segmentDefinition.icon !== null) {
		symbol = (
			<EmbedImageIcon
				files={segmentDefinition.icon.files}
				size={16}
			/>
		);
	} else if (segmentDefinition.shortcode !== null) {
		symbol = segmentDefinition.shortcode;
	}

	return (
		<SegmentFilterSegment
			color={segmentDefinition.color}
			label={showLabel && segmentDefinition.label}
			symbol={symbol}
		/>
	);
};



export default SegmentDefinitionFilterIdentifier;
