import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import MessageAnimation from '../../animations/MessageAnimation';



class ModalContentWithSidebar extends Component {

	_renderFlashMessage() {
		const {
			flashMessage,
		} = this.props;

		return (
			<MessageAnimation
				active={flashMessage}
				className="modal-content__flash-message"
			>
				{flashMessage}
			</MessageAnimation>
		);
	}



	render() {
		const {
			disabledSidebar,
			fullheight,
			gapsSize,
			hiddenSidebar,
			children,
			sidebar,
			slimSidebar,
		} = this.props;

		const componentClasses = classNames({
			'modal-content': true,
			'modal-content--fullheight': fullheight,
			'modal-content--has-gaps': gapsSize === 1,
			'modal-content--has-large-gaps': gapsSize === 2,
		});

		const sidebarClasses = classNames({
			'modal-content__sidebar': true,
			'modal-content__sidebar--is-disabled': disabledSidebar,
			'modal-content__sidebar--is-hidden': hiddenSidebar,
			'modal-content__sidebar--is-slim': slimSidebar,
		});

		return (
			<div className={componentClasses}>
				<div className="modal-content__body">
					{this._renderFlashMessage()}
					{children}
				</div>

				{sidebar && (
					<div className={sidebarClasses}>
						<div className="modal-content__sidebar-body">
							{sidebar}
						</div>
					</div>
				)}
			</div>
		);
	}

}

ModalContentWithSidebar.defaultProps = {
	disabledSidebar: false,
	gapsSize: 1,
	slimSidebar: false,
};

ModalContentWithSidebar.propTypes = {
	disabledSidebar: PropTypes.bool,
	/** Possible flash message component */
	flashMessage: PropTypes.node,
	/** Make content part full height and scrollable when content is higher that viewport */
	fullheight: PropTypes.bool,
	gapsSize: PropTypes.oneOf([
		0,
		1,
		2,
	]).isRequired,
	hiddenSidebar: PropTypes.bool,
	sidebar: PropTypes.node,
	/** Switch to slim version of sidebar */
	slimSidebar: PropTypes.bool,
};



export default ModalContentWithSidebar;
