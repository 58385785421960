import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import HeaderTitle from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import MarginBox from '~/components/atoms/boxes/MarginBox';
import MultiWebsitesDashboard from '~/components/app/MultiWebsitesDashboard';
import MultiWebsitesDashboardAlertsList from '~/components/app/MultiWebsitesDashboardAlertsList';
import ResponsiveContent from '~/components/atoms/layouts/responsiveLayoutElements/ResponsiveContent';
import ResponsiveRow from '~/components/atoms/layouts/responsiveLayoutElements/ResponsiveRow';
import ResponsiveSidebar from '~/components/atoms/layouts/responsiveLayoutElements/ResponsiveSidebar';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScrollableContentScreen from '~/components/logic/layouts/screens/ScrollableContentScreen';



const messages = defineMessages({
	title: {
		id: 'ui.multidashboard.title',
	},
});



const MultiDashboardScreen: React.FC = () => {
	return (
		<ScrollableContentScreen
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						<HeaderTitle>
							<FormattedMessage {...messages.title} />
						</HeaderTitle>
					</HeaderTitleLayout>
				</ScreenHeader>
			)}
		>
			<ResponsiveRow reversed={true}>
				<ResponsiveSidebar>
					<MarginBox>
						<MultiWebsitesDashboardAlertsList />
					</MarginBox>
				</ResponsiveSidebar>

				<ResponsiveContent>
					<MarginBox>
						<MultiWebsitesDashboard />
					</MarginBox>
				</ResponsiveContent>
			</ResponsiveRow>
		</ScrollableContentScreen>
	);
};



export default MultiDashboardScreen;
