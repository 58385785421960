import React from 'react';

import IncidentCard from '~/components/patterns/incidents/IncidentCard';

import useNavigation from '~/hooks/useNavigation';



const RecentAlertCard = (props) => {
	const {
		alertId,
		icon,
		message,
		websiteId,
	} = props;

	const navigation = useNavigation();

	const handleEventClick = React.useCallback(
		() => {
			navigation.navigate({
				routeName: 'website.alerts',
				routeParams: {
					alert: alertId,
					websiteId,
				},
			});
		},
		[
			alertId,
			navigation,
			websiteId,
		],
	);

	return (
		<IncidentCard
			icon={icon}
			onClickCallback={handleEventClick}
			title={message}
		/>
	);
};



export default RecentAlertCard;
