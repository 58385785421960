import React from 'react';

import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';
import useCurrentColumns from '~/hooks/useCurrentColumns';

import {
	calculatePersistableColumns,
} from '~/model/pagesColumns';



function usePersistableCurrentColumns() {
	const currentColumnSet = useCurrentColumnSet();
	const currentColumns = useCurrentColumns();

	return React.useMemo(
		() => {
			if (currentColumnSet === null) {
				return currentColumns;
			}

			return calculatePersistableColumns(
				currentColumnSet.columns.map(
					(currentColumnSetColumn) => ({
						enabled: currentColumnSetColumn.enabled,
						name: currentColumnSetColumn.name,
					}),
				),
				currentColumns,
			);
		},
		[
			currentColumnSet,
			currentColumns,
		],
	);
}



export default usePersistableCurrentColumns;
