/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrioritizeExternalUrlsMutationVariables = GraphQL.Exact<{
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PrioritizeExternalUrlsMutation = { readonly __typename?: 'Mutation', readonly PrioritizeExternalUrls: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const PrioritizeExternalUrlsDocument = gql`
    mutation PrioritizeExternalUrls($urls: [String!]!, $websiteId: WebsiteId!) {
  PrioritizeExternalUrls(urls: $urls, websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type PrioritizeExternalUrlsMutationFn = Apollo.MutationFunction<PrioritizeExternalUrlsMutation, PrioritizeExternalUrlsMutationVariables>;

/**
 * __usePrioritizeExternalUrlsMutation__
 *
 * To run a mutation, you first call `usePrioritizeExternalUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrioritizeExternalUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prioritizeExternalUrlsMutation, { data, loading, error }] = usePrioritizeExternalUrlsMutation({
 *   variables: {
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePrioritizeExternalUrlsMutation(baseOptions?: Apollo.MutationHookOptions<PrioritizeExternalUrlsMutation, PrioritizeExternalUrlsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrioritizeExternalUrlsMutation, PrioritizeExternalUrlsMutationVariables>(PrioritizeExternalUrlsDocument, options);
      }
export type PrioritizeExternalUrlsMutationHookResult = ReturnType<typeof usePrioritizeExternalUrlsMutation>;
export type PrioritizeExternalUrlsMutationResult = Apollo.MutationResult<PrioritizeExternalUrlsMutation>;
export type PrioritizeExternalUrlsMutationOptions = Apollo.BaseMutationOptions<PrioritizeExternalUrlsMutation, PrioritizeExternalUrlsMutationVariables>;