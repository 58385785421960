import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	DeviceType,
} from '~/model/websites/deviceTypes';



const messages = defineMessages({
	[DeviceType.Desktop]: {
		id: 'ui.deviceType.desktop',
	},
	[DeviceType.Mobile]: {
		id: 'ui.deviceType.mobile',
	},
});



type Props = {
	deviceType: DeviceType,
};



const DeviceTypeName: React.FC<Props> = (props) => {
	const {
		deviceType,
	} = props;

	return (
		<FormattedMessage {...messages[deviceType]} />
	);
};



export default DeviceTypeName;
