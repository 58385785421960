import {
	LOCALE_EN_US,
} from './locales';



export const GUIDE_BULK_EDIT_ALERT_DEFINITIONS = 'GUIDE_BULK_EDIT_ALERT_DEFINITIONS';
export const GUIDE_DASHBOARD = 'GUIDE_DASHBOARD';
export const GUIDE_ONBOARDING = 'GUIDE_ONBOARDING';



const guides = {
	[GUIDE_BULK_EDIT_ALERT_DEFINITIONS]: {
		[LOCALE_EN_US]: 'Bulk edit alert definitions [EN]',
	},
	[GUIDE_DASHBOARD]: {
		[LOCALE_EN_US]: 'Dashboard',
	},
	[GUIDE_ONBOARDING]: {
		[LOCALE_EN_US]: 'Onboarding [EN]',
	},
};



export function getPendoGuideName(guide, locale) {
	return guides[guide][locale];
}
