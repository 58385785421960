import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import EmailDomainNotWhitelistedErrorMessage from '../errorMessages/EmailDomainNotWhitelistedErrorMessage';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';
import UserRoleField, {
	validateUserRole,
} from '~/components/app/UserRoleField';

import {
	validateField,
} from '~/components/app/validations';

import useAccountUserRestrictions from '~/hooks/useAccountUserRestrictions';
import useAllInvitations from '~/hooks/useAllInvitations';
import useAllUsers from '~/hooks/useAllUsers';

import {
	isEmailWhitelisted,
} from '~/model/users';



const messages = defineMessages({
	email: {
		id: 'ui.newTeam.invitationForm.email',
	},
	emailPlaceholder: {
		id: 'ui.newTeam.invitationForm.emailPlaceholder',
	},
	sendInvitation: {
		id: 'ui.newTeam.invitationForm.sendInvitationButton',
	},
	selectWebsites: {
		id: 'ui.newTeam.invitationForm.selectWebsitesButton',
	},
});

const errorMessages = defineMessages({
	alreadyInvited: {
		id: 'ui.teamDetail.teamInvitation.errorAlreadyInvited',
	},
	alreadyMember: {
		id: 'ui.teamDetail.teamInvitation.errorAlreadyMember',
	},
	blank: {
		id: 'ui.formErrors.blank',
	},
	disallowedEmail: {
		id: 'ui.formErrors.disallowedEmail',
	},
	invalidEmail: {
		id: 'ui.formErrors.invalidEmail',
	},
	trialAbuserEmail: {
		id: 'ui.formErrors.trialAbuserEmail',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	isDisabled?: boolean,
	onRoleUpdate: (role: GraphQL.UserRole) => void,
	onSubmitCallback: (values: {
		email: string,
		role: GraphQL.UserRole,
	}) => void,
};

const InviteSingleMemberForm: React.FC<Props> = (props) => {
	const {
		accountId,
		isDisabled,
		onRoleUpdate,
		onSubmitCallback,
	} = props;

	const accountUserRestrictions = useAccountUserRestrictions(accountId);
	const allInvitations = useAllInvitations();
	const allUsers = useAllUsers();
	const intl = useIntl();

	const handleFormChange = React.useCallback(
		(field, value): void => {
			if (field === 'role') {
				onRoleUpdate(value);
			}
		},
		[
			onRoleUpdate,
		],
	);

	const validations = React.useMemo(
		() => {
			let invitationEmails: ReadonlyArray<string> = [];
			let userEmails: ReadonlyArray<string> = [];

			if (accountId !== null && allInvitations !== null) {
				invitationEmails = allInvitations
					.listByAccount(accountId)
					.map((invitation) => invitation.email);

				userEmails = allUsers
					.listByAccount(accountId)
					.map((user) => user.email);
			}

			return {
				validateEmail: validateField(
					'email',
					(f) => [
						f.validateNonEmpty(),
						f.validateEmail(),
						f.custom({
							message: (
								<EmailDomainNotWhitelistedErrorMessage
									accountId={accountId}
								/>
							),
							rule: ({ value }) => isEmailWhitelisted(
								value,
								accountUserRestrictions?.emailDomainsWhitelist ?? [],
							),
						}),
						f.custom({
							message: (
								<FormattedMessage {...errorMessages.alreadyInvited} />
							),
							rule: ({ value }) => invitationEmails.includes(value) === false,
						}),
						f.custom({
							message: (
								<FormattedMessage {...errorMessages.alreadyMember} />
							),
							rule: ({ value }) => userEmails.includes(value) === false,
						}),
						f.customGlobal({
							globalRule: 'alreadyInvitedIntoHomeAccount',
							message: (
								<FormattedMessage {...errorMessages.alreadyInvited} />
							),
						}),
						f.customGlobal({
							globalRule: 'alreadyUserOfHomeAccount',
							message: (
								<FormattedMessage {...errorMessages.alreadyMember} />
							),
						}),
						f.customGlobal({
							globalRule: 'emailDomainNotWhitelisted',
							message: (
								<EmailDomainNotWhitelistedErrorMessage
									accountId={accountId}
								/>
							),
						}),
						f.customGlobal({
							globalRule: 'disallowedEmail',
							message: (
								<FormattedMessage {...errorMessages.disallowedEmail} />
							),
						}),
						f.customGlobal({
							globalRule: 'trialAbuserEmail',
							message: (
								<FormattedMessage
									{...errorMessages.trialAbuserEmail}
									values={{
										contactLink: (chunks) => (
											<ExternalLink href="https://www.contentkingapp.com/support/">
												{chunks}
											</ExternalLink>
										),
									}}
								/>
							),
						}),
					],
				),
				validateUserRole,
			};
		},
		[
			accountId,
			accountUserRestrictions,
			allInvitations,
			allUsers,
		],
	);

	if (
		accountId === null
		|| accountUserRestrictions === null
		|| allInvitations === null
		|| allUsers.isLoaded === false
	) {
		return null;
	}

	return (
		<Form
			defaultFocus={isDisabled ? null : 'email'}
			defaultValues={{
				email: '',
				role: GraphQL.UserRole.Manager,
			}}
			isDisabled={isDisabled}
			onChangeCallback={handleFormChange}
			onSuccess={onSubmitCallback}
			validations={validations}
		>
			{({ values }) => (
				<>
					<div
						style={{
							minHeight: 343,
						}}
					>
						<FormRows>
							<FormRow
								htmlFor="email"
								label={(
									<FormattedMessage {...messages.email} />
								)}
							>
								<FieldStatus name="validateEmail">
									<TextField
										autoComplete={TextFieldAutocomplete.Off}
										name="email"
										placeholder={intl.formatMessage(messages.emailPlaceholder)}
										trimValue={true}
										type={TextFieldType.Email}
									/>
								</FieldStatus>
							</FormRow>

							<UserRoleField
								accountId={accountId}
								name="role"
							/>
						</FormRows>

						<FormErrorMessages />
					</div>

					<ButtonsLayout layout={ButtonsLayoutType.Steps}>
						<CancelButton />

						<SubmitButton>
							{values.role === GraphQL.UserRole.Editor || values.role === GraphQL.UserRole.Viewer ? (
								<FormattedMessage {...messages.selectWebsites} />
							) : (
								<FormattedMessage {...messages.sendInvitation} />
							)}
						</SubmitButton>
					</ButtonsLayout>
				</>
			)}
		</Form>
	);
};



export default InviteSingleMemberForm;
