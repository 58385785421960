import React from 'react';

import ChangeTrackingCalendar from '~/components/app/ChangeTrackingCalendar';
import DateRangeLabel from '~/components/app/DateRangeLabel';
import PopupButton, {
	PopupButtonSize,
	PopupButtonStyle,
} from '~/components/patterns/buttons/PopupButton';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useViewportType from '~/hooks/useViewportType';



type Props = {
	minDate: Date,
	onChangeCallback: (any) => void,
};

const DateRangeFilter: React.FC<Props> = (props) => {
	const {
		minDate,
		onChangeCallback,
	} = props;

	const viewportType = useViewportType();
	const dateRange = useDashboardDateRange();

	const handleRangeSelect = React.useCallback(
		({ endDate, startDate }) => {
			onChangeCallback({
				end: endDate,
				start: startDate,
			});
		},
		[
			onChangeCallback,
		],
	);

	const label = (
		<strong>
			<DateRangeLabel
				endDate={dateRange.end}
				startDate={dateRange.start}
			/>
		</strong>
	);

	if (viewportType.isSmall) {
		return label;
	}

	return (
		<PopupButton
			label={label}
			popupLayout={(children) => {
				return children;
			}}
			size={PopupButtonSize.Medium}
			style={PopupButtonStyle.Link}
		>
			<ChangeTrackingCalendar
				endDate={dateRange.end}
				minDate={minDate}
				onRangeSelect={handleRangeSelect}
				startDate={dateRange.start}
			/>
		</PopupButton>
	);
};



export default DateRangeFilter;
