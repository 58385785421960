/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IgnorePageIssueOnWholeWebsiteMutationVariables = GraphQL.Exact<{
  issueName: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IgnorePageIssueOnWholeWebsiteMutation = { readonly __typename?: 'Mutation', readonly IgnorePageIssueOnWholeWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type IgnorePlatformIssueOnWholeWebsiteMutationVariables = GraphQL.Exact<{
  issueName: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IgnorePlatformIssueOnWholeWebsiteMutation = { readonly __typename?: 'Mutation', readonly IgnorePlatformIssue: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type UnignorePageIssueOnWholeWebsiteMutationVariables = GraphQL.Exact<{
  issueName: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueOnWholeWebsiteMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueOnWholeWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type UnignorePlatformIssueOnWholeWebsiteMutationVariables = GraphQL.Exact<{
  issueName: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePlatformIssueOnWholeWebsiteMutation = { readonly __typename?: 'Mutation', readonly UnignorePlatformIssue: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const IgnorePageIssueOnWholeWebsiteDocument = gql`
    mutation IgnorePageIssueOnWholeWebsite($issueName: String!, $websiteId: WebsiteId!) {
  IgnorePageIssueOnWholeWebsite(type: $issueName, websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type IgnorePageIssueOnWholeWebsiteMutationFn = Apollo.MutationFunction<IgnorePageIssueOnWholeWebsiteMutation, IgnorePageIssueOnWholeWebsiteMutationVariables>;

/**
 * __useIgnorePageIssueOnWholeWebsiteMutation__
 *
 * To run a mutation, you first call `useIgnorePageIssueOnWholeWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnorePageIssueOnWholeWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignorePageIssueOnWholeWebsiteMutation, { data, loading, error }] = useIgnorePageIssueOnWholeWebsiteMutation({
 *   variables: {
 *      issueName: // value for 'issueName'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIgnorePageIssueOnWholeWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<IgnorePageIssueOnWholeWebsiteMutation, IgnorePageIssueOnWholeWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgnorePageIssueOnWholeWebsiteMutation, IgnorePageIssueOnWholeWebsiteMutationVariables>(IgnorePageIssueOnWholeWebsiteDocument, options);
      }
export type IgnorePageIssueOnWholeWebsiteMutationHookResult = ReturnType<typeof useIgnorePageIssueOnWholeWebsiteMutation>;
export type IgnorePageIssueOnWholeWebsiteMutationResult = Apollo.MutationResult<IgnorePageIssueOnWholeWebsiteMutation>;
export type IgnorePageIssueOnWholeWebsiteMutationOptions = Apollo.BaseMutationOptions<IgnorePageIssueOnWholeWebsiteMutation, IgnorePageIssueOnWholeWebsiteMutationVariables>;
export const IgnorePlatformIssueOnWholeWebsiteDocument = gql`
    mutation IgnorePlatformIssueOnWholeWebsite($issueName: String!, $websiteId: WebsiteId!) {
  IgnorePlatformIssue(type: $issueName, websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type IgnorePlatformIssueOnWholeWebsiteMutationFn = Apollo.MutationFunction<IgnorePlatformIssueOnWholeWebsiteMutation, IgnorePlatformIssueOnWholeWebsiteMutationVariables>;

/**
 * __useIgnorePlatformIssueOnWholeWebsiteMutation__
 *
 * To run a mutation, you first call `useIgnorePlatformIssueOnWholeWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnorePlatformIssueOnWholeWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignorePlatformIssueOnWholeWebsiteMutation, { data, loading, error }] = useIgnorePlatformIssueOnWholeWebsiteMutation({
 *   variables: {
 *      issueName: // value for 'issueName'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIgnorePlatformIssueOnWholeWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<IgnorePlatformIssueOnWholeWebsiteMutation, IgnorePlatformIssueOnWholeWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgnorePlatformIssueOnWholeWebsiteMutation, IgnorePlatformIssueOnWholeWebsiteMutationVariables>(IgnorePlatformIssueOnWholeWebsiteDocument, options);
      }
export type IgnorePlatformIssueOnWholeWebsiteMutationHookResult = ReturnType<typeof useIgnorePlatformIssueOnWholeWebsiteMutation>;
export type IgnorePlatformIssueOnWholeWebsiteMutationResult = Apollo.MutationResult<IgnorePlatformIssueOnWholeWebsiteMutation>;
export type IgnorePlatformIssueOnWholeWebsiteMutationOptions = Apollo.BaseMutationOptions<IgnorePlatformIssueOnWholeWebsiteMutation, IgnorePlatformIssueOnWholeWebsiteMutationVariables>;
export const UnignorePageIssueOnWholeWebsiteDocument = gql`
    mutation UnignorePageIssueOnWholeWebsite($issueName: String!, $websiteId: WebsiteId!) {
  UnignorePageIssueOnWholeWebsite(type: $issueName, websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePageIssueOnWholeWebsiteMutationFn = Apollo.MutationFunction<UnignorePageIssueOnWholeWebsiteMutation, UnignorePageIssueOnWholeWebsiteMutationVariables>;

/**
 * __useUnignorePageIssueOnWholeWebsiteMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueOnWholeWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueOnWholeWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueOnWholeWebsiteMutation, { data, loading, error }] = useUnignorePageIssueOnWholeWebsiteMutation({
 *   variables: {
 *      issueName: // value for 'issueName'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueOnWholeWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueOnWholeWebsiteMutation, UnignorePageIssueOnWholeWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueOnWholeWebsiteMutation, UnignorePageIssueOnWholeWebsiteMutationVariables>(UnignorePageIssueOnWholeWebsiteDocument, options);
      }
export type UnignorePageIssueOnWholeWebsiteMutationHookResult = ReturnType<typeof useUnignorePageIssueOnWholeWebsiteMutation>;
export type UnignorePageIssueOnWholeWebsiteMutationResult = Apollo.MutationResult<UnignorePageIssueOnWholeWebsiteMutation>;
export type UnignorePageIssueOnWholeWebsiteMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueOnWholeWebsiteMutation, UnignorePageIssueOnWholeWebsiteMutationVariables>;
export const UnignorePlatformIssueOnWholeWebsiteDocument = gql`
    mutation UnignorePlatformIssueOnWholeWebsite($issueName: String!, $websiteId: WebsiteId!) {
  UnignorePlatformIssue(type: $issueName, websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePlatformIssueOnWholeWebsiteMutationFn = Apollo.MutationFunction<UnignorePlatformIssueOnWholeWebsiteMutation, UnignorePlatformIssueOnWholeWebsiteMutationVariables>;

/**
 * __useUnignorePlatformIssueOnWholeWebsiteMutation__
 *
 * To run a mutation, you first call `useUnignorePlatformIssueOnWholeWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePlatformIssueOnWholeWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePlatformIssueOnWholeWebsiteMutation, { data, loading, error }] = useUnignorePlatformIssueOnWholeWebsiteMutation({
 *   variables: {
 *      issueName: // value for 'issueName'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePlatformIssueOnWholeWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePlatformIssueOnWholeWebsiteMutation, UnignorePlatformIssueOnWholeWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePlatformIssueOnWholeWebsiteMutation, UnignorePlatformIssueOnWholeWebsiteMutationVariables>(UnignorePlatformIssueOnWholeWebsiteDocument, options);
      }
export type UnignorePlatformIssueOnWholeWebsiteMutationHookResult = ReturnType<typeof useUnignorePlatformIssueOnWholeWebsiteMutation>;
export type UnignorePlatformIssueOnWholeWebsiteMutationResult = Apollo.MutationResult<UnignorePlatformIssueOnWholeWebsiteMutation>;
export type UnignorePlatformIssueOnWholeWebsiteMutationOptions = Apollo.BaseMutationOptions<UnignorePlatformIssueOnWholeWebsiteMutation, UnignorePlatformIssueOnWholeWebsiteMutationVariables>;