import React from 'react';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import HealthIcon from '~/components/patterns/icons/HealthIcon';



type Props = {
	/** Animate the heart icon to pulse as a heartbeat */
	animateHeartbeat?: boolean,
	children: React.ReactNode,
};



const HealthLabel: React.FC<Props> = (props) => {
	const {
		animateHeartbeat,
		children,
	} = props;

	return (
		<AttachedIcon
			icon={(
				<HealthIcon
					highlighted={animateHeartbeat}
					size={18}
				/>
			)}
		>
			{children}
		</AttachedIcon>
	);
};



export default HealthLabel;
