import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	color?: string,
	/** Icon prepended to title label */
	icon?: React.ReactNode,
	indented?: boolean,
	/** Callback triggered on component mouse enter */
	onMouseEnter?: () => void,
	/** Callback triggered on component mouse leave */
	onMouseLeave?: () => void,
	underlined?: boolean,
};



const Title: React.FC<Props> = (props) => {
	const {
		children,
		color,
		icon,
		indented,
		onMouseEnter,
		onMouseLeave,
		underlined,
	} = props;

	const componentClasses = classNames({
		'basic-title': true,
		'basic-title--indented': indented,
		'basic-title--underlined': underlined,
	});

	const componentStyle: React.CSSProperties = {};

	if (color) {
		componentStyle.color = color;
	}

	return (
		<h3
			className={componentClasses}
			style={componentStyle}
		>
			{icon && (
				<span
					className="basic-title__icon"
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				>
					{icon}
				</span>
			)}
			<span
				className="basic-title__label"
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{children}
			</span>
		</h3>
	);
};



export default Title;
