import React from 'react';
import Measure from 'react-measure';

import {
	UnifiedHeightGroupContext,
} from './UnifiedHeightGroup';



const UnifiedHeightContainer = (props) => {
	const {
		children,
	} = props;

	const [name] = React.useState(() => Symbol());

	const {
		maximumHeight,
		reportHeight,
	} = React.useContext(UnifiedHeightGroupContext);

	const handleResize = React.useCallback(
		(contentRect) => {
			reportHeight(name, contentRect.bounds.height);
		},
		[
			name,
			reportHeight,
		],
	);

	return (
		<div style={{ minHeight: maximumHeight }}>
			<Measure
				bounds={true}
				onResize={handleResize}
			>
				{({ measureRef }) => (
					<div ref={measureRef}>
						{children}
					</div>
				)}
			</Measure>
		</div>
	);
};



export default UnifiedHeightContainer;
