/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportWebsiteIntoAccountMutationVariables = GraphQL.Exact<{
  sourceAccountId: GraphQL.Scalars['AccountId']['input'];
  targetAccountId: GraphQL.Scalars['AccountId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ImportWebsiteIntoAccountMutation = { readonly __typename?: 'Mutation', readonly ImportWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly displayName: string, readonly domain: string, readonly logo: string | null, readonly pageCapacity: number, readonly pageCapacityUsageDescription: GraphQL.WebsitePageCapacityUsageDescription, readonly pageCapacityUsageRatio: number, readonly pagesCount: number, readonly problem: GraphQL.WebsiteProblem | null, readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElement', readonly name: string }>, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }>, readonly logSources: ReadonlyArray<{ readonly __typename?: 'LogSource', readonly provider: GraphQL.LogSourceProvider, readonly status: GraphQL.LogSourceStatus }>, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly id: CK.PageSegmentId }> }> } | null } } };


export const ImportWebsiteIntoAccountDocument = gql`
    mutation ImportWebsiteIntoAccount($sourceAccountId: AccountId!, $targetAccountId: AccountId!, $websiteId: WebsiteId!) {
  ImportWebsite(
    sourceAccountId: $sourceAccountId
    targetAccountId: $targetAccountId
    websiteId: $websiteId
  ) {
    query {
      account(id: $targetAccountId) {
        id
        websites {
          customElements {
            name
          }
          id
          displayName
          domain
          integrations {
            isNotOwned
            status
            type
          }
          logo
          logSources {
            provider
            status
          }
          pageCapacity
          pageCapacityUsageDescription
          pageCapacityUsageRatio
          pageSegments {
            id
          }
          pagesCount
          problem
        }
      }
    }
  }
}
    `;
export type ImportWebsiteIntoAccountMutationFn = Apollo.MutationFunction<ImportWebsiteIntoAccountMutation, ImportWebsiteIntoAccountMutationVariables>;

/**
 * __useImportWebsiteIntoAccountMutation__
 *
 * To run a mutation, you first call `useImportWebsiteIntoAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportWebsiteIntoAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importWebsiteIntoAccountMutation, { data, loading, error }] = useImportWebsiteIntoAccountMutation({
 *   variables: {
 *      sourceAccountId: // value for 'sourceAccountId'
 *      targetAccountId: // value for 'targetAccountId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useImportWebsiteIntoAccountMutation(baseOptions?: Apollo.MutationHookOptions<ImportWebsiteIntoAccountMutation, ImportWebsiteIntoAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportWebsiteIntoAccountMutation, ImportWebsiteIntoAccountMutationVariables>(ImportWebsiteIntoAccountDocument, options);
      }
export type ImportWebsiteIntoAccountMutationHookResult = ReturnType<typeof useImportWebsiteIntoAccountMutation>;
export type ImportWebsiteIntoAccountMutationResult = Apollo.MutationResult<ImportWebsiteIntoAccountMutation>;
export type ImportWebsiteIntoAccountMutationOptions = Apollo.BaseMutationOptions<ImportWebsiteIntoAccountMutation, ImportWebsiteIntoAccountMutationVariables>;