import React from 'react';

import GraphQL from '~/types/graphql';

import AgencyAccessOverview from '~/components/logic/accountAccess/AgencyAccessOverview';
import ConnectionDirectionForm from '../ConnectionDirectionForm';
import FormsList from '~/components/atoms/lists/FormsList';

import useAccountAgencyConnections from '~/hooks/useAccountAgencyConnections';
import useAccountClientConnections from '~/hooks/useAccountClientConnections';
import useAccountId from '~/hooks/useAccountId';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';



const ConnectedAccountsGroup: React.FC = () => {
	const accountId = useAccountId();

	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const agencyConnections = useAccountAgencyConnections(accountId);
	const clientConnections = useAccountClientConnections(accountId);

	const canManageAccountAccessDirection = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageAccountAccessDirection,
	);

	return (
		<FormsList>
			{accountIsOutOfBand === false && canManageAccountAccessDirection.yes && (
				<ConnectionDirectionForm
					accountId={accountId}
					agencyConnections={agencyConnections.listAll()}
					clientConnections={clientConnections.listAll()}
				/>
			)}

			{agencyConnections.count > 0 && (
				<AgencyAccessOverview
					accountId={accountId}
					agencyConnections={agencyConnections.listAll()}
				/>
			)}
		</FormsList>
	);
};



export default ConnectedAccountsGroup;
