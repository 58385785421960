import React from 'react';

import EmptyValue from '~/components/app/EmptyValue';
import MissingValue from '~/components/app/MissingValue';



type Props = {
	value: string | null,
};

const StringFormatter: React.FC<Props> = (props) => {
	const {
		value = null,
	} = props;

	if (value === null) {
		return (
			<MissingValue ellipsis={true} />
		);
	}

	if (value.length === 0) {
		return (
			<EmptyValue ellipsis={true} />
		);
	}

	return <>{value}</>;
};



export default React.memo(StringFormatter);
