import classNames from 'classnames';
import React from 'react';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';

import matchAndReturn from '~/utilities/matchAndReturn';



export enum PageStatusBarStatus {
	Critical = 'critical',
	Warning = 'warning',
}



type Props = {
	children: React.ReactNode,
	status: PageStatusBarStatus,
};

const PageStatusBar: React.FC<Props> = (props) => {
	const {
		children,
		status,
	} = props;

	const componentClasses = classNames({
		'page-status-bar': true,
		[ 'page-status-bar--' + status + '-status' ]: true,
	});

	const iconStatus = matchAndReturn(status, {
		[PageStatusBarStatus.Critical]: StatusFlagStatus.Critical,
		[PageStatusBarStatus.Warning]: StatusFlagStatus.Warning,
	});

	return (
		<div className={componentClasses}>
			<div className="page-status-bar__icon">
				<AttributesGroupIcon
					color="#5C6773"
					type={AttributesGroupIconType.Domain}
				/>
				<div className="page-status-bar__status-icon">
					<StatusFlag status={iconStatus} />
				</div>
			</div>

			<div className="page-status-bar__message">
				{children}
			</div>
		</div>
	);
};



export default PageStatusBar;
