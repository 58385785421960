import React from 'react';

import AddMicrosoftTeamsMessagingAppChannelOverlay from '~/components/app/AddMicrosoftTeamsMessagingAppChannelOverlay';
import {
	type MessagingAppChannelFieldRowRef,
} from '~/components/app/MessagingAppChannelFieldRow';



function useUglyMicrosoftTeamsMessagingAppChannelOverlay() {
	const [addMicrosoftTeamsMessagingAppChannel, setAddMicrosoftTeamsMessagingAppChannel] = React.useState(false);
	const messagingAppChannelFieldRowRef = React.useRef<MessagingAppChannelFieldRowRef>(null);

	const handleRegisteredMicrosoftTeamsMessagingAppChannel = React.useCallback(
		(registeredMessagingAppChannelId) => {
			messagingAppChannelFieldRowRef.current?.setMessageChannelId(
				registeredMessagingAppChannelId,
			);

			setAddMicrosoftTeamsMessagingAppChannel(false);
		},
		[
			messagingAppChannelFieldRowRef,
			setAddMicrosoftTeamsMessagingAppChannel,
		],
	);

	return React.useMemo(
		() => ({
			addMicrosoftTeamsMessagingAppChannelOverlay: addMicrosoftTeamsMessagingAppChannel && (
				<AddMicrosoftTeamsMessagingAppChannelOverlay
					onCancel={() => setAddMicrosoftTeamsMessagingAppChannel(false)}
					onSuccess={handleRegisteredMicrosoftTeamsMessagingAppChannel}
				/>
			),
			initiateAddMicrosoftTeamsMessagingAppChannel: () => setAddMicrosoftTeamsMessagingAppChannel(true),
			messagingAppChannelFieldRowRef,
		}),
		[
			addMicrosoftTeamsMessagingAppChannel,
			handleRegisteredMicrosoftTeamsMessagingAppChannel,
			messagingAppChannelFieldRowRef,
			setAddMicrosoftTeamsMessagingAppChannel,
		],
	);
}



export default useUglyMicrosoftTeamsMessagingAppChannelOverlay;
