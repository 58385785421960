import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import CK from '~/types/contentking';

import AffectedPagesComparisonTable from '../../atoms/dataTables/AffectedPagesComparisonTable';
import AffectedPagesTable from '../../atoms/dataTables/AffectedPagesTable';
import Header from '../../logic/affectedPages/Header';
import HistoricalAffectedPagesScreenHeader from '~/components/app/IssuesScreen/HistoricalAffectedPagesScreenHeader';
import IsWebsiteCrawledContext from '~/components/app/IsWebsiteCrawledContext';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';

import useProjectAffectedPagesUrl from '~/hooks/useProjectAffectedPagesUrl';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	updateFilter as affectedPagesUpdateFilter,
	updateSortBy as affectedPagesUpdateSortBy,
	loadAffectedPages,
} from '~/actions/affectedPages';

import {
	updateFilter as affectedPagesComparisonUpdateFilter,
	updateSortBy as affectedPagesComparisonUpdateSortBy,
	loadAffectedPagesComparison,
} from '~/actions/affectedPagesComparison';

import goTo from '~/routing/goTo';
import {
	getRouter,
} from '~/routing/router';

import {
	pagesSelector as currentPagesSelector,
	rangeSelector as currentRangeSelector,
	totalSelector as currentTotalSelector,
} from '~/state/affectedPages/currentWebsiteSelectors';

import {
	currentFilterSelector,
	loadingSelector as currentLoadingSelector,
	sortBySelector as currentSortBySelector,
} from '~/state/affectedPages/selectors';

import {
	totalSelector as comparisonTotalSelector,
} from '~/state/affectedPagesComparison/currentWebsiteSelectors';

import {
	sortBySelector as comparisonSortBySelector,
} from '~/state/affectedPagesComparison/selectors';

import {
	issuesOverviewCategorySelector,
	issuesOverviewIssueSelector,
} from '~/state/ui/content/selectors';

import {
	isActiveSelector as isComparisonModeActiveSelector,
} from '~/state/websiteIssuesComparison/selectors';

import {
	copyUrlToClipboardItem,
	openPageDetailItem,
	openPageOnWebsiteItem,
} from '~/model/contextMenu';

import {
	PageIssue,
} from '~/model/issuesNew';

import {
	getCategoryUrlIdentifier,
} from '~/model/issues/identifierMapping';



const SingleIssueAffectedPagesPanel = (props) => {
	const {
		backlink,
		closeCallback,
	} = props;

	const websiteId = useWebsiteId();

	const comparisonSortBy = useSelector(comparisonSortBySelector);
	const comparisonTotal = useSelector(comparisonTotalSelector);
	const currentFilter = useSelector(currentFilterSelector);
	const currentLoading = useSelector(currentLoadingSelector);
	const currentPages = useSelector(currentPagesSelector);
	const currentRange = useSelector(currentRangeSelector);
	const currentSortBy = useSelector(currentSortBySelector);
	const currentTotal = useSelector(currentTotalSelector);
	const dispatch = useDispatch();
	const isComparisonModeActive = useSelector(isComparisonModeActiveSelector);
	const issueCategory = useSelector(issuesOverviewCategorySelector);
	const issueType = useSelector(issuesOverviewIssueSelector);
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	useProjectAffectedPagesUrl();

	const contextMenuEntries = React.useCallback(
		({ row }) => {
			const pageDetailUrl = getRouter().buildPath('website.pages.detail.issuesCategory', {
				id: row.get('id'),
				pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategory),
				websiteId,
			});

			return [
				openPageDetailItem(pageDetailUrl),
				openPageOnWebsiteItem(row.get('url')),
				copyUrlToClipboardItem(row.get('url')),
			];
		},
		[
			issueCategory,
			websiteId,
		],
	);

	const handleOpenDetail = React.useCallback(
		({ row }, event) => {
			const legacyUrlId = row.get('id');

			goTo(
				event,
				'website.pages.detail.issuesCategory',
				{
					id: legacyUrlId,
					pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategory),
					websiteId,
				},
			);
		},
		[
			issueCategory,
			websiteId,
		],
	);

	const loadPages = React.useCallback(
		(offset) => {
			return dispatch(
				isComparisonModeActive
					? loadAffectedPagesComparison(offset, 300)
					: loadAffectedPages(offset, 300),
			);
		},
		[
			dispatch,
			isComparisonModeActive,
		],
	);

	const handleFilterChange = React.useCallback(
		(filter) => {
			dispatch(
				isComparisonModeActive
					? affectedPagesComparisonUpdateFilter(filter)
					: affectedPagesUpdateFilter(filter),
			);
		},
		[
			dispatch,
			isComparisonModeActive,
		],
	);

	const handleSortingChange = React.useCallback(
		(data) => {
			const sortBy = isComparisonModeActive
				? comparisonSortBy
				: currentSortBy;

			const alreadySelected = sortBy.get('key') === data.key;

			const newSortBy = {
				key: data.key,
				direction: alreadySelected
					? !sortBy.get('direction')
					: data.direction,
			};

			dispatch(
				isComparisonModeActive
					? affectedPagesComparisonUpdateSortBy(newSortBy)
					: affectedPagesUpdateSortBy(newSortBy),
			);
		},
		[
			comparisonSortBy,
			currentSortBy,
			dispatch,
			isComparisonModeActive,
		],
	);

	function renderComparisonTable(columns) {
		return (
			<IsWebsiteCrawledContext>
				{({ isCrawled }) => (
					<AffectedPagesComparisonTable
						columns={columns}
						contextMenuEntries={contextMenuEntries}
						filterCallback={handleFilterChange}
						isCrawled={isCrawled}
						pagesLoader={loadPages}
						sortCallback={handleSortingChange}
					/>
				)}
			</IsWebsiteCrawledContext>
		);
	}

	function renderCurrentTable(columns) {
		return (
			<IsWebsiteCrawledContext>
				{({ isCrawled }) => (
					<AffectedPagesTable
						affectedPages={currentPages}
						columns={columns}
						contextMenuEntries={contextMenuEntries}
						dataLoading={currentLoading}
						filter={currentFilter}
						filterCallback={handleFilterChange}
						isCrawled={isCrawled}
						onRowClick={handleOpenDetail}
						pagesCount={currentTotal || 0}
						pagesIndexes={currentRange}
						pagesLoader={loadPages}
						segmentDefinitions={segmentDefinitions.listAll()}
						sortBy={currentSortBy}
						sortCallback={handleSortingChange}
					/>
				)}
			</IsWebsiteCrawledContext>
		);
	}

	if (!issueCategory || !issueType) {
		return null;
	}

	let columns;

	switch (issueType) {

		case PageIssue.AnalyticsVisualAnalyticsMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.VisualAnalyticsServices,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.AnalyticsAnalyticsMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.AnalyticsServices,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.H1Duplicate:
		case PageIssue.H1IncorrectLength:
		case PageIssue.H1Missing:
		case PageIssue.H1TooMany: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.H1,
					sortable: true,
					width: 400,
				},
			];

			break;
		}

		case PageIssue.H1LevelsSkipped: {
			columns = [];

			break;
		}


		case PageIssue.HreflangConflictingTargets:
		case PageIssue.HreflangInvalidTarget:
		case PageIssue.HreflangInvalidValue:
		case PageIssue.HreflangMissingSelfReference:
		case PageIssue.HreflangMissingSpecificAudience:
		case PageIssue.HreflangMissingXDefault: {
			columns = [];

			break;
		}

		case PageIssue.ImagesAltAttribute: {
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.NumberOfImagesWithMissingAlt,
					sortable: true,
					width: 300,
					defaultSortingDirection: false,
				},
			];

			break;
		}

		case PageIssue.ImagesMixedTransport: {
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.NumberOfImagesWithMixedTransport,
					sortable: true,
					width: 300,
					defaultSortingDirection: false,
				},
			];

			break;
		}

		case PageIssue.LighthouseCls:
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.LighthouseCumulativeLayoutShift,
					sortable: true,
					width: 220,
					defaultSortingDirection: true,
				},
			];

			break;

		case PageIssue.LighthouseFcp:
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.LighthouseFirstContentfulPaint,
					sortable: true,
					width: 220,
					defaultSortingDirection: true,
				},
			];

			break;

		case PageIssue.LighthouseLcp:
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.LighthouseLargestContentfulPaint,
					sortable: true,
					width: 220,
					defaultSortingDirection: true,
				},
			];

			break;

		case PageIssue.LighthousePerformance:
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.LighthousePerformance,
					sortable: true,
					width: 220,
					defaultSortingDirection: true,
				},
			];

			break;

		case PageIssue.LighthouseSi:
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.LighthouseSpeedIndex,
					sortable: true,
					width: 220,
					defaultSortingDirection: true,
				},
			];

			break;

		case PageIssue.LighthouseTbt:
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.LighthouseTotalBlockingTime,
					sortable: true,
					width: 220,
					defaultSortingDirection: true,
				},
			];

			break;


		case PageIssue.LighthouseTti:
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.LighthouseTimeToInteractive,
					sortable: true,
					width: 220,
					defaultSortingDirection: true,
				},
			];

			break;

		case PageIssue.LinksBroken: {
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.NumberOfLinksToBrokenPages,
					sortable: true,
					width: 300,
					defaultSortingDirection: false,
				},
			];

			break;
		}

		case PageIssue.LinksRedirected: {
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.NumberOfLinksToRedirectedPages,
					sortable: true,
					width: 300,
					defaultSortingDirection: false,
				},
			];

			break;
		}

		case PageIssue.LinksToCanonicalized: {
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.NumberOfLinksToCanonicalizedPages,
					sortable: true,
					width: 300,
					defaultSortingDirection: false,
				},
			];

			break;
		}

		case PageIssue.MetaDescriptionDuplicate:
		case PageIssue.MetaDescriptionIncorrectLength:
		case PageIssue.MetaDescriptionMissing:
		case PageIssue.MetaDescriptionTooMany: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.MetaDescription,
					sortable: true,
					width: 400,
				},
			];

			break;
		}

		case PageIssue.RobotDirectivesConflicting:
		case PageIssue.RobotDirectivesInvalid:
		case PageIssue.RobotDirectivesUnsupported: {
			columns = [];

			break;
		}

		case PageIssue.SchemaOrgErrors:
		case PageIssue.SchemaOrgInvalidJson: {
			columns = [];

			break;
		}

		case PageIssue.TitleDuplicate:
		case PageIssue.TitleIncorrectLength:
		case PageIssue.TitleMissing:
		case PageIssue.TitleTooMany: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.Title,
					sortable: true,
					width: 400,
				},
			];

			break;
		}

		case PageIssue.XmlSitemapIncorrectlyMissing:
		case PageIssue.XmlSitemapIncorrectlyPresent: {
			columns = [];

			break;
		}

		case PageIssue.CanonicalLinkIncorrectlyCanonicalized:
		case PageIssue.CanonicalLinkMissing:
		case PageIssue.CanonicalLinkPointsToUnindexable:
		case PageIssue.CanonicalLinkTooMany: {
			columns = [
				{
					filterable: false,
					name: CK.PagesCommonColumn.CanonicalLinkElement,
					sortable: false,
					width: 400,
				},
			];

			break;
		}

		case PageIssue.OpenGraphDescriptionIncorrectLength:
		case PageIssue.OpenGraphDescriptionMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.OpenGraphDescription,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.OpenGraphImageMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.OpenGraphImage,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.OpenGraphUrlMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.OpenGraphUrl,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.OpenGraphTitleIncorrectLength:
		case PageIssue.OpenGraphTitleMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.OpenGraphTitle,
					sortable: true,
					width: 400,
				},
			];

			break;
		}

		case PageIssue.TwitterCardsDescriptionIncorrectLength:
		case PageIssue.TwitterCardsDescriptionMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.TwitterDescription,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.TwitterCardsImageMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.TwitterImage,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.TwitterCardsSiteMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.TwitterSite,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.TwitterCardsTypeMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.TwitterCard,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.TwitterCardsTypeInvalid: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.TwitterCard,
					sortable: true,
					width: 300,
				},
			];

			break;
		}

		case PageIssue.TwitterCardsTitleIncorrectLength:
		case PageIssue.TwitterCardsTitleMissing: {
			columns = [
				{
					filterable: true,
					name: CK.PagesCommonColumn.TwitterTitle,
					sortable: true,
					width: 400,
				},
			];

			break;
		}

	}

	return (
		<ScreenLayout
			header={isComparisonModeActive
				? (
					<HistoricalAffectedPagesScreenHeader
						backlinkCallback={closeCallback}
						backlinkLabel={backlink}
						issueCategoryName={issueCategory}
						issueName={issueType}
						numberOfAffectedPages={comparisonTotal}
						websiteId={websiteId}
					/>
				)
				: (
					<Header
						backlinkCallback={closeCallback}
						backlinkLabel={backlink}
						issueCategoryName={issueCategory}
						issueName={issueType}
						numberOfAffectedPages={currentTotal}
					/>
				)
			}
		>
			{isComparisonModeActive ? renderComparisonTable(columns) : renderCurrentTable(columns)}
		</ScreenLayout>
	);
};



export default SingleIssueAffectedPagesPanel;
