import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BulkSaveDefinitionsButton from '~/components/logic/alertsConfiguration/BulkSaveDefinitionsButton';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import {
	INDETERMINATE,
} from '~/components/atoms/forms/components/MultiselectField';
import RecipientsMultiselectField from '~/components/app/RecipientsMultiselectField';
import RichText from '~/components/patterns/typography/RichText';

import {
	useBulkUpdateAlertDefinitionsRecipientsMutation,
} from './EditAlertDefinitionsRecipientsModal.gql';

import useAllUsers from '~/hooks/useAllUsers';
import useBulkEditedAlertDefinitions from '~/hooks/useBulkEditedAlertDefinitions';
import useCanManageUserAsAlertRecipient from '~/hooks/useCanManageUserAsAlertRecipient';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useSetFlashMessage from '~/hooks/useSetFlashMessage';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	description: {
		id: 'ui.alertsConfiguration.modal.bulkEditRecipients.description',
	},
	fieldRecipients: {
		id: 'ui.alertsConfiguration.fields.recipients',
	},
	headerLabel: {
		id: 'ui.general.settings',
	},
	headerValue: {
		id: 'ui.alertsConfiguration.modal.bulk.header',
	},
});



const EditAlertDefinitionsRecipientsModal: React.FC = () => {
	const websiteId = useWebsiteId();

	const allUsers = useAllUsers();
	const canManageUserAsAlertRecipient = useCanManageUserAsAlertRecipient(websiteId);
	const classicFormBehavior = useClassicFormBehavior();
	const selectedAlertDefinitions = useBulkEditedAlertDefinitions();
	const setFlashMessage = useSetFlashMessage();

	const [bulkUpdateAlertDefinitionsRecipients] = useBulkUpdateAlertDefinitionsRecipientsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			const addedRecipients: Array<string> = [];
			const removedRecipients: Array<string> = [];

			for (const recipientEmail in values.recipients) {
				if (values.recipients.hasOwnProperty(recipientEmail)) {
					if (values.recipients[recipientEmail].selected === true) {
						addedRecipients.push(recipientEmail);
					}

					if (values.recipients[recipientEmail].selected === false) {
						removedRecipients.push(recipientEmail);
					}
				}
			}

			await bulkUpdateAlertDefinitionsRecipients({
				variables: {
					addedRecipients: addedRecipients.filter(canManageUserAsAlertRecipient),
					alertDefinitionIds: selectedAlertDefinitions.ids,
					removedRecipients: removedRecipients.filter(canManageUserAsAlertRecipient),
					websiteId,
				},
			});

			setFlashMessage({
				message: 'alertDefinitionsEdited',
				messageVariables: {
					number__alertDefinitions: selectedAlertDefinitions.count,
				},
				route: 'website.alerts.configuration',
				style: 'success',
			});

			classicFormBehavior.finish();
		},
		[
			bulkUpdateAlertDefinitionsRecipients,
			canManageUserAsAlertRecipient,
			classicFormBehavior,
			selectedAlertDefinitions,
			setFlashMessage,
			websiteId,
		],
	);

	let defaultRecipients: Record<string, { selected: boolean | typeof INDETERMINATE }> | undefined;

	allUsers.listAll().forEach((user) => {
		const numberOfAlertDefinitionsWhereUserIsRecipient = selectedAlertDefinitions.all
			.filter((alertDefinition) => alertDefinition.recipients.includes(user.email))
			.length;

		let value: boolean | typeof INDETERMINATE = INDETERMINATE;

		if (numberOfAlertDefinitionsWhereUserIsRecipient === selectedAlertDefinitions.count) {
			value = true;
		} else if (numberOfAlertDefinitionsWhereUserIsRecipient === 0) {
			value = false;
		}

		if (defaultRecipients === undefined) {
			defaultRecipients = {};
		}

		defaultRecipients[user.email] = {
			selected: value,
		};
	});

	return (
		<ModalContainer
			gapsSize={2}
			header={(
				<ModalHeader
					iconType={ModalHeaderIconType.CogWheel}
					title={(
						<FormattedMessage {...messages.headerLabel} />
					)}
					titleValue={(
						<FormattedMessage
							{...messages.headerValue}
							values={{
								count__definitions: selectedAlertDefinitions.count,
							}}
						/>
					)}
				/>
			)}
			headerGapsSize={1}
			isLoading={defaultRecipients === undefined}
		>
			<ModalTextSection>
				<RichText>
					<p>
						<FormattedMessage {...messages.description} />
					</p>
				</RichText>
			</ModalTextSection>

			{defaultRecipients !== undefined && (
				<Form
					defaultValues={{
						recipients: defaultRecipients,
					}}
					onSuccess={handleSubmit}
				>
					<CenteredFormWrapper>
						<FormRow
							htmlFor="recipients"
							label={(
								<FormattedMessage {...messages.fieldRecipients} />
							)}
						>
							<RecipientsMultiselectField
								name="recipients"
								websiteId={websiteId}
							/>
						</FormRow>
					</CenteredFormWrapper>

					<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
						<CancelButton />

						<BulkSaveDefinitionsButton
							numberOfAlertDefinitions={selectedAlertDefinitions.count}
						/>
					</ModalButtonsLayout>
				</Form>
			)}
		</ModalContainer>
	);
};



export default EditAlertDefinitionsRecipientsModal;
