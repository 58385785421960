/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAccountIdQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAccountIdQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId } | null } | null };


export const WebsiteAccountIdDocument = gql`
    query WebsiteAccountId($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    account {
      id
    }
    id
  }
}
    `;

/**
 * __useWebsiteAccountIdQuery__
 *
 * To run a query within a React component, call `useWebsiteAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAccountIdQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAccountIdQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAccountIdQuery, WebsiteAccountIdQueryVariables> & ({ variables: WebsiteAccountIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAccountIdQuery, WebsiteAccountIdQueryVariables>(WebsiteAccountIdDocument, options);
      }
export function useWebsiteAccountIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAccountIdQuery, WebsiteAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAccountIdQuery, WebsiteAccountIdQueryVariables>(WebsiteAccountIdDocument, options);
        }
export function useWebsiteAccountIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteAccountIdQuery, WebsiteAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAccountIdQuery, WebsiteAccountIdQueryVariables>(WebsiteAccountIdDocument, options);
        }
export type WebsiteAccountIdQueryHookResult = ReturnType<typeof useWebsiteAccountIdQuery>;
export type WebsiteAccountIdLazyQueryHookResult = ReturnType<typeof useWebsiteAccountIdLazyQuery>;
export type WebsiteAccountIdSuspenseQueryHookResult = ReturnType<typeof useWebsiteAccountIdSuspenseQuery>;
export type WebsiteAccountIdQueryResult = Apollo.QueryResult<WebsiteAccountIdQuery, WebsiteAccountIdQueryVariables>;