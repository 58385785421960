import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FetchingLocationFieldRow, {
	FetchingLocationFieldRowScope,
	validateFetchingLocation,
} from '~/components/app/FetchingLocationFieldRow';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import UserAgentFields, {
	UserAgentFieldsScope,
	validateUserAgent,
	validateUserAgentCustomValue,
} from '~/components/app/UserAgentFields';

import {
	useAccountMonitoringSettingsQuery,
	useUpdateAccountMonitoringSettingsMutation,
} from '~/components/app/AccountMonitoringForm.gql';

import useAccountId from '~/hooks/useAccountId';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';

import {
	type UserAgent,
} from '~/model/websites/userAgents';



const messages = defineMessages({
	title: {
		id: 'ui.websites.formGroups.websiteMonitoring',
	},
});

const validations = {
	validateFetchingLocation,
	validateUserAgent: validateUserAgent({ isWebsiteVerified: false }),
	validateUserAgentCustomValue: validateUserAgentCustomValue({ isWebsiteVerified: false }),
};



const AccountMonitoringForm: React.FC = () => {
	const accountId = useAccountId();
	const classicFormBehavior = useClassicFormBehavior();

	const { data } = useAccountMonitoringSettingsQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const [updateAccountMonitoringSettings] = useUpdateAccountMonitoringSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (accountId === null) {
				return;
			}

			await updateAccountMonitoringSettings({
				variables: {
					accountId,
					fetchingLocation: values.fetchingLocation,
					userAgentSettings: {
						customValue: values.userAgentCustomValue,
						type: values.userAgent,
					},
				},
			});

			classicFormBehavior.finish();
		},
		[
			accountId,
			classicFormBehavior,
			updateAccountMonitoringSettings,
		],
	);

	const fetchingLocation = (
		data?.account?.defaultFetchingSettings.fetchingLocation ?? null
	);

	const userAgent = (
		data?.account?.defaultFetchingSettings.userAgentSettings.type ?? null
	) as UserAgent | null;

	return (
		<EditableFormWrapper
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<UserAgentFields
						isEditable={false}
						scope={UserAgentFieldsScope.Account}
						userAgent={userAgent}
					/>

					<FetchingLocationFieldRow
						fetchingLocation={fetchingLocation}
						isEditable={false}
						scope={FetchingLocationFieldRowScope.Account}
					/>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						fetchingLocation,
						userAgent,
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<FormRows>
						<UserAgentFields
							isEditable={true}
							scope={UserAgentFieldsScope.Account}
							userAgent={userAgent}
						/>

						<FetchingLocationFieldRow
							fetchingLocation={fetchingLocation}
							isEditable={true}
							scope={FetchingLocationFieldRowScope.Account}
						/>
					</FormRows>

					<FormErrorMessages />

					<ButtonsLayout>
						<CancelButton />

						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default AccountMonitoringForm;
