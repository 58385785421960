import React from 'react';

import BadgeWithValue from '~/components/patterns/structuredValues/BadgeWithValue';
import BlankValue from '~/components/patterns/values/BlankValue';
import CodeValue from '~/components/patterns/values/CodeValue';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermValueSkeleton from './DefinitionTermValueSkeleton';
import DomSrcBadge, {
	DomSrcBadgeType,
} from '~/components/patterns/tags/DomSrcBadge';
import EmptyValue from '~/components/app/EmptyValue';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import MissingValue from '~/components/app/MissingValue';
import RealtimeValueUpdateHighlight from '~/components/patterns/values/RealtimeValueUpdateHighlight';
import TextInspector from '~/components/patterns/typography/TextInspector';



function renderValue(
	value: any,
	codeHighlight: boolean = false,
) {
	if (value === null) {
		return (
			<MissingValue />
		);
	} else if (value === '') {
		return (
			<EmptyValue />
		);
	} else if (codeHighlight) {
		return (
			<CodeValue>
				<TextInspector text={value} />
			</CodeValue>
		);
	} else if (typeof value === 'string') {
		return (
			<TextInspector text={value} />
		);
	}

	return value;
}



export function compileDefinitionTermsNew<Type extends string>(
	types: Array<{
		alwaysVisible?: boolean,
		codeHighlight?: boolean,
		label: React.ReactNode,
		name: string,
		property: Type,
		valueFormatter?: (content: any) => React.ReactNode,
	}>,
	{
		compare = false,
		limit = null,
		propertyData,
		srcPropertyData = null,
	}: {
		compare?: boolean,
		limit?: number | null,
		propertyData: Record<Type, {
			contentItems: ReadonlyArray<{
				content: any,
			}>,
		}> | null,
		srcPropertyData?: Record<Type, {
			contentItems: ReadonlyArray<{
				content: any,
			}>,
		}> | null,
	},
) {
	const elements: Array<React.ReactNode> = [];

	if (limit !== null) {
		types = types.slice(0, limit);
	}

	if (propertyData === null) {
		types.filter((type) => type.alwaysVisible).forEach((type) => {
			let content: React.ReactNode = undefined;

			if (type.valueFormatter) {
				content = type.valueFormatter(content);
			}

			if (content === undefined) {
				elements.push((
					<DefinitionTerm
						description={(
							<DefinitionTermValueSkeleton />
						)}
						key={type.name}
						showRowNumbers={false}
						term={type.label}
					/>
				));
			} else {
				elements.push((
					<DefinitionTerm
						description={[
							(
								<RealtimeValueUpdateHighlight
									disabled={true}
									key="loading"
									value={content}
								>
									{renderValue(content, type.codeHighlight || false)}
								</RealtimeValueUpdateHighlight>
							),
						]}
						key={type.name}
						showRowNumbers={true}
						term={type.label}
						visibleRowsCount={3}
					/>
				));
			}
		});
	} else {
		types.forEach((type) => {
			const primaryItems = propertyData[type.property].contentItems;
			const srcItems = srcPropertyData !== null ? srcPropertyData[type.property].contentItems : [];

			const numberOfItems = Math.max(
				primaryItems.length,
				compare ? srcItems.length : 0,
			);

			const items: Array<{
				primary: React.ReactNode,
				src: React.ReactNode,
			}> = [];

			if (numberOfItems === 0) {
				if (type.alwaysVisible) {
					items.push({
						primary: (
							<RealtimeValueUpdateHighlight
								key={0}
							>
								{renderValue(null, type.codeHighlight || false)}
							</RealtimeValueUpdateHighlight>
						),
						src: (
							<BlankValue>
								same as DOM
							</BlankValue>
						),
					});
				} else {
					return;
				}
			}

			for (let i = 0; i < numberOfItems; i++) {
				const item: {
					primary: React.ReactNode,
					src: React.ReactNode,
				} = {
					primary: null,
					src: null,
				};

				const fullPrimaryContent = primaryItems[i]?.content ?? null;
				let primaryContent = fullPrimaryContent;

				if (type.valueFormatter) {
					primaryContent = type.valueFormatter(primaryContent);
				}

				item.primary = (
					<RealtimeValueUpdateHighlight
						value={fullPrimaryContent}
					>
						{renderValue(fullPrimaryContent, type.codeHighlight || false)}
					</RealtimeValueUpdateHighlight>
				);

				if (compare) {
					if (srcPropertyData === null) {
						item.src = (
							<DefinitionTermValueSkeleton />
						);
					} else {
						const fullSrcContent = srcItems[i]?.content ?? null;
						let srcContent = fullSrcContent;

						let codeHighlight = type.codeHighlight || false;

						if (fullSrcContent === fullPrimaryContent) {
							srcContent = (
								<BlankValue>
									same as DOM
								</BlankValue>
							);

							codeHighlight = false;
						} else if (type.valueFormatter) {
							srcContent = type.valueFormatter(srcContent);
						}

						item.src = (
							<RealtimeValueUpdateHighlight
								value={fullSrcContent}
							>
								{renderValue(srcContent, codeHighlight)}
							</RealtimeValueUpdateHighlight>
						);
					}
				}

				items.push(item);
			}

			const descriptions = items.map(({ primary, src }, index) => {
				if (compare) {
					return (
						<List
							key={index}
							size={ListSize.Small}
						>
							<BadgeWithValue
								badge={(
									<DomSrcBadge type={DomSrcBadgeType.Dom} />
								)}
							>
								{primary}
							</BadgeWithValue>

							<BadgeWithValue
								badge={(
									<DomSrcBadge type={DomSrcBadgeType.Src} />
								)}
							>
								{src}
							</BadgeWithValue>
						</List>
					);
				}

				return (
					<React.Fragment key={index}>
						{primary}
					</React.Fragment>
				);
			});

			elements.push((
				<DefinitionTerm
					description={descriptions}
					key={type.name}
					showRowNumbers={true}
					term={type.label}
					visibleRowsCount={3}
				/>
			));
		});
	}

	return elements;
}
