import React from 'react';

import {
	DashboardDateRangeContext,
} from '~/components/app/Dashboard/DashboardScreen';

import {
	type DateRange,
} from '~/model/dashboard';

import {
	MissingContextProviderError,
} from '~/errors';



function useDashboardDateRange(): DateRange {
	const dateRange = React.useContext(DashboardDateRangeContext);

	if (dateRange === null) {
		throw new MissingContextProviderError('DashboardDateRangeContext');
	}

	return dateRange;
}



export default useDashboardDateRange;
