import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttachedHeading, {
	AttachedHeadingAlignment,
} from '~/components/patterns/structuredValues/AttachedHeading';
import Caption from '~/components/patterns/headings/Caption';
import CustomElementTesterForm from '~/components/app/CustomElementTesterForm';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import RichText from '~/components/patterns/typography/RichText';

import {
	type Extraction,
} from '~/model/customElements';



const messages = defineMessages({
	caption: {
		id: 'ui.customElements.tester.title',
	},
	text: {
		id: 'ui.customElements.tester.text',
	},
});



type Props = {
	extraction: Extraction,
	isValid: boolean,
};

const CustomElementTester: React.FC<Props> = (props) => {
	const {
		extraction,
		isValid,
	} = props;

	return (
		<List size={ListSize.XLarge}>
			<AttachedHeading
				heading={(
					<Caption>
						<FormattedMessage {...messages.caption} />
					</Caption>
				)}
				headingAlignment={AttachedHeadingAlignment.Left}
			>
				<RichText>
					<p>
						<FormattedMessage {...messages.text} />
					</p>
				</RichText>
			</AttachedHeading>

			<CustomElementTesterForm
				extraction={extraction}
				isValid={isValid}
			/>
		</List>
	);
};



export default CustomElementTester;
