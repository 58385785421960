/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserMainAccountIdQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserMainAccountIdQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly mainAccountId: CK.AccountId | null } | null };


export const UserMainAccountIdDocument = gql`
    query UserMainAccountId($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    legacyId
    mainAccountId
  }
}
    `;

/**
 * __useUserMainAccountIdQuery__
 *
 * To run a query within a React component, call `useUserMainAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMainAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMainAccountIdQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserMainAccountIdQuery(baseOptions: Apollo.QueryHookOptions<UserMainAccountIdQuery, UserMainAccountIdQueryVariables> & ({ variables: UserMainAccountIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserMainAccountIdQuery, UserMainAccountIdQueryVariables>(UserMainAccountIdDocument, options);
      }
export function useUserMainAccountIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMainAccountIdQuery, UserMainAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserMainAccountIdQuery, UserMainAccountIdQueryVariables>(UserMainAccountIdDocument, options);
        }
export function useUserMainAccountIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserMainAccountIdQuery, UserMainAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserMainAccountIdQuery, UserMainAccountIdQueryVariables>(UserMainAccountIdDocument, options);
        }
export type UserMainAccountIdQueryHookResult = ReturnType<typeof useUserMainAccountIdQuery>;
export type UserMainAccountIdLazyQueryHookResult = ReturnType<typeof useUserMainAccountIdLazyQuery>;
export type UserMainAccountIdSuspenseQueryHookResult = ReturnType<typeof useUserMainAccountIdSuspenseQuery>;
export type UserMainAccountIdQueryResult = Apollo.QueryResult<UserMainAccountIdQuery, UserMainAccountIdQueryVariables>;