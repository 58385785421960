import classNames from 'classnames';
import React from 'react';



export enum PerformanceScoreStatus {
	Good = 'good',
	NeedsImprovement = 'needs-improvement',
	Poor = 'poor',
}



type Props = {
	/** Status definition showing condition of score */
	status?: PerformanceScoreStatus,
	/** Score value */
	value?: number,
};

const PerformanceScore: React.FC<Props> = (props) => {
	const {
		status,
		value,
	} = props;

	const componentClasses = classNames({
		'performance-score': true,
		['performance-score--' + status + '-status']: status,
	});

	return (
		<div className={componentClasses}>
			<span className="performance-score__value">
				{value ?? '―'}
			</span>
		</div>
	);
};



export default PerformanceScore;
