import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Emphasis from '~/components/patterns/typography/Emphasis';



const messages = defineMessages({
	label: {
		id: 'ui.general.notApplicable',
		defaultMessage: 'not applicable',
	},
});



type Props = {
	/** Enable ellpsis support to prevent overflowing */
	ellipsis?: boolean,
	/** Custom message */
	message?: React.ReactNode,
};

const NotApplicableValue: React.FC<Props> = (props) => {
	const {
		ellipsis,
		message,
	} = props;

	return (
		<Emphasis ellipsis={ellipsis}>
			{message || <FormattedMessage {...messages.label} />}
		</Emphasis>
	);
};



export default NotApplicableValue;
