import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import ContentCellValue from './ContentCellValue';
import MissingValue from '~/components/app/MissingValue';
import SitemapReferenceName from '../../../names/SitemapReferenceName';
import WithSeparator from '../../../atoms/WithSeparator';

import {
	LIST_OF_ALL_SITEMAP_REFERENCES,
} from '~/model/sitemaps';



class SitemapReferencesCellValue extends Component {

	render() {
		const {
			sitemapReferences,
		} = this.props;

		if (sitemapReferences === null || sitemapReferences.size === 0) {
			return (
				<MissingValue ellipsis={true} />
			);
		}

		return (
			<ContentCellValue
				value={(
					<WithSeparator separator=", ">
						{
							sitemapReferences
								.filter((sitemapReference) => LIST_OF_ALL_SITEMAP_REFERENCES.includes(sitemapReference))
								.map((sitemapReference) => {
									return (
										<SitemapReferenceName
											key={sitemapReference}
											sitemapReference={sitemapReference}
										/>
									);
								})
						}
					</WithSeparator>
				)}
			/>
		);
	}

}

SitemapReferencesCellValue.propTypes = {
	sitemapReferences: PropTypes.object.isRequired,
};



export default SitemapReferencesCellValue;
