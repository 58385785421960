import React from 'react';

import useUrlState from '~/hooks/useUrlState';

import encodeInBase64 from '~/utilities/encodeInBase64';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



export const LinkTargetKeepParameter = {};



type Props = {
	children: RenderProp<{
		routeName: string,
		routeParams: Record<string, any>,
	}>,
	extraRouteParams?: Record<string, any>,
	includeBacklink?: boolean,
	routeName?: string,
	routeParams?: Record<string, any>,
};

const LinkTarget: React.FC<Props> = (props) => {
	const {
		children,
		extraRouteParams,
		includeBacklink = false,
		routeName: propRouteName,
		routeParams: propRouteParams,
	} = props;

	const urlState = useUrlState();

	const routeName = propRouteName ?? urlState.name;
	let routeParams = propRouteParams ?? { ...urlState.params };

	for (const param in routeParams) {
		if (routeParams[param] === LinkTargetKeepParameter) {
			routeParams[param] = urlState.params[param];
		}
	}

	if (extraRouteParams) {
		routeParams = {
			...routeParams,
			...extraRouteParams,
		};
	}

	if (includeBacklink) {
		routeParams.back = encodeInBase64(
			JSON.stringify({
				name: urlState.name,
				params: urlState.params,
			}),
		);
	}

	return (
		<>
			{renderProp(children, {
				routeName,
				routeParams,
			})}
		</>
	);
};



export default React.memo(LinkTarget);
