/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserPlatformTourWebinarOfferingQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserPlatformTourWebinarOfferingQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly platformTourWebinarOffering: GraphQL.PlatformTourWebinarOffering } | null };


export const UserPlatformTourWebinarOfferingDocument = gql`
    query UserPlatformTourWebinarOffering($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    legacyId
    platformTourWebinarOffering
  }
}
    `;

/**
 * __useUserPlatformTourWebinarOfferingQuery__
 *
 * To run a query within a React component, call `useUserPlatformTourWebinarOfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPlatformTourWebinarOfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPlatformTourWebinarOfferingQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserPlatformTourWebinarOfferingQuery(baseOptions: Apollo.QueryHookOptions<UserPlatformTourWebinarOfferingQuery, UserPlatformTourWebinarOfferingQueryVariables> & ({ variables: UserPlatformTourWebinarOfferingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPlatformTourWebinarOfferingQuery, UserPlatformTourWebinarOfferingQueryVariables>(UserPlatformTourWebinarOfferingDocument, options);
      }
export function useUserPlatformTourWebinarOfferingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPlatformTourWebinarOfferingQuery, UserPlatformTourWebinarOfferingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPlatformTourWebinarOfferingQuery, UserPlatformTourWebinarOfferingQueryVariables>(UserPlatformTourWebinarOfferingDocument, options);
        }
export function useUserPlatformTourWebinarOfferingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserPlatformTourWebinarOfferingQuery, UserPlatformTourWebinarOfferingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserPlatformTourWebinarOfferingQuery, UserPlatformTourWebinarOfferingQueryVariables>(UserPlatformTourWebinarOfferingDocument, options);
        }
export type UserPlatformTourWebinarOfferingQueryHookResult = ReturnType<typeof useUserPlatformTourWebinarOfferingQuery>;
export type UserPlatformTourWebinarOfferingLazyQueryHookResult = ReturnType<typeof useUserPlatformTourWebinarOfferingLazyQuery>;
export type UserPlatformTourWebinarOfferingSuspenseQueryHookResult = ReturnType<typeof useUserPlatformTourWebinarOfferingSuspenseQuery>;
export type UserPlatformTourWebinarOfferingQueryResult = Apollo.QueryResult<UserPlatformTourWebinarOfferingQuery, UserPlatformTourWebinarOfferingQueryVariables>;