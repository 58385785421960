import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import useColumnSets from '~/hooks/useColumnSets';

import {
	routerColumnSetIdSelector,
} from '~/state/pagesColumns/selectors';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



function useCurrentColumnSetId(): CK.ColumnSetId | null {
	const columnSets = useColumnSets();
	const routerColumnSetId = useSelector(routerColumnSetIdSelector);

	if (routerColumnSetId) {
		return routerColumnSetId as CK.ColumnSetId;
	}

	if (columnSets.columnSets.length > 0) {
		return getArrayItemAtSafeIndex(columnSets.columnSets, 0).id;
	}

	return null;
}



export default useCurrentColumnSetId;
