/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountPaidUntilQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountPaidUntilQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly paidUntil: CK.Timestamp | null } | null };


export const AccountPaidUntilDocument = gql`
    query AccountPaidUntil($accountId: AccountId!) {
  account(id: $accountId) {
    id
    paidUntil
  }
}
    `;

/**
 * __useAccountPaidUntilQuery__
 *
 * To run a query within a React component, call `useAccountPaidUntilQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPaidUntilQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPaidUntilQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountPaidUntilQuery(baseOptions: Apollo.QueryHookOptions<AccountPaidUntilQuery, AccountPaidUntilQueryVariables> & ({ variables: AccountPaidUntilQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountPaidUntilQuery, AccountPaidUntilQueryVariables>(AccountPaidUntilDocument, options);
      }
export function useAccountPaidUntilLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountPaidUntilQuery, AccountPaidUntilQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountPaidUntilQuery, AccountPaidUntilQueryVariables>(AccountPaidUntilDocument, options);
        }
export function useAccountPaidUntilSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountPaidUntilQuery, AccountPaidUntilQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountPaidUntilQuery, AccountPaidUntilQueryVariables>(AccountPaidUntilDocument, options);
        }
export type AccountPaidUntilQueryHookResult = ReturnType<typeof useAccountPaidUntilQuery>;
export type AccountPaidUntilLazyQueryHookResult = ReturnType<typeof useAccountPaidUntilLazyQuery>;
export type AccountPaidUntilSuspenseQueryHookResult = ReturnType<typeof useAccountPaidUntilSuspenseQuery>;
export type AccountPaidUntilQueryResult = Apollo.QueryResult<AccountPaidUntilQuery, AccountPaidUntilQueryVariables>;