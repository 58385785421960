import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import StatusLabel, {
	StatusLabelType,
} from '~/components/patterns/statuses/StatusLabel';
import WebsiteFavicon, {
	WebsiteFaviconUsageContext,
} from '~/components/logic/websites/WebsiteFavicon';
import WebsiteOverview, {
	WebsiteOverviewSize,
	WebsiteOverviewStatus,
} from '~/components/patterns/websites/WebsiteOverview';



const messages = defineMessages({
	monitoringPaused: {
		id: 'ui.plan.monitoringPaused',
	},
	nearPlanCapacity: {
		id: 'ui.websites.capacity.limitNear.label',
		defaultMessage: 'near plan capacity',
	},
	pages: {
		id: 'ui.websites.list.pages',
	},
	reachedPlanCapacity: {
		id: 'ui.websites.capacity.limitReached.label',
		defaultMessage: 'at plan capacity',
	},
});



type Props = {
	website: {
		displayName: string,
		id: CK.WebsiteId,
		pageCapacity: number,
		pagesCount: number,
		problem: GraphQL.WebsiteProblem | null,
	},
};

const WebsiteDetailedLabel: React.FC<Props> = (props) => {
	const {
		website,
	} = props;

	function renderPlanOverview() {
		if (website.problem === GraphQL.WebsiteProblem.Paused) {
			return (
				<StatusLabel type={StatusLabelType.Disabled}>
					<FormattedMessage {...messages.monitoringPaused} />
				</StatusLabel>
			);
		}

		if (website.problem === GraphQL.WebsiteProblem.NearCapacity) {
			return (
				<StatusLabel type={StatusLabelType.Warning}>
					<FormattedMessage {...messages.nearPlanCapacity} />
				</StatusLabel>
			);
		}

		if (website.problem === GraphQL.WebsiteProblem.CapacityReached) {
			return (
				<StatusLabel type={StatusLabelType.Alert}>
					<FormattedMessage {...messages.reachedPlanCapacity} />
				</StatusLabel>
			);
		}

		return false;
	}

	let status = WebsiteOverviewStatus.Default;

	if (website.problem === GraphQL.WebsiteProblem.CapacityReached) {
		status = WebsiteOverviewStatus.Critical;
	}

	if (website.problem === GraphQL.WebsiteProblem.Paused) {
		status = WebsiteOverviewStatus.Paused;
	}

	if (website.problem === GraphQL.WebsiteProblem.NearCapacity) {
		status = WebsiteOverviewStatus.Warning;
	}

	return (
		<WebsiteOverview
			details={renderPlanOverview()}
			favicon={(
				<WebsiteFavicon
					usageContext={WebsiteFaviconUsageContext.Card}
					websiteId={website.id}
				/>
			)}
			name={website.displayName}
			size={WebsiteOverviewSize.Small}
			status={status}
		/>
	);
};



export default WebsiteDetailedLabel;
