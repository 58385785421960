import classNames from 'classnames';
import React from 'react';

import {
	type DragHandleProps,
} from '~/components/patterns/tables/newTable/NewTable';



export enum DragHandlerVisibility {
	Hidden = 'hidden',
	Hoverable = 'hoverable',
	Visible = 'visible',
}

type Props = {
	additionalProps?: DragHandleProps,
	/** Possibility to set custom visibility of whole elements */
	visibility?: DragHandlerVisibility,
};



const DragHandler: React.FC<Props> = (props) => {
	const {
		additionalProps,
		visibility = DragHandlerVisibility.Visible,
	} = props;

	const componentClasses = classNames({
		'drag-handler': true,
		'drag-handler--is-hidden': visibility === DragHandlerVisibility.Hidden,
		'drag-handler--is-hoverable': visibility === DragHandlerVisibility.Hoverable,
	});

	return (
		<span
			className={componentClasses}
			{...additionalProps}
		>
			↕
		</span>
	);
};



export default DragHandler;
