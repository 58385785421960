import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AppAnnouncement from '~/components/patterns/messages/popup/AppAnnouncement';
import AppearAnimation from '~/components/patterns/animations/AppearAnimation';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';

import {
	useAppClientVersionQuery,
} from './AppUpdated.gql';

import {
	APP_CLIENT_VERSION,
} from '~/config';



const messages = defineMessages({
	button: {
		id: 'ui.appUpdated.button',
	},
	message: {
		id: 'ui.appUpdated.message',
	},
});



const currentAppClientVersion = APP_CLIENT_VERSION;

const AppUpdated: React.FC = () => {
	const { data } = useAppClientVersionQuery({
		pollInterval: 30_000,
	});

	if (currentAppClientVersion === null) {
		return null;
	}

	if (data && data.appClientVersion > currentAppClientVersion) {
		return (
			<AppearAnimation>
				<AppAnnouncement
					ctaButton={(
						<Button
							onClick={() => {
								location.reload();
							}}
							size={ButtonSize.Small}
							style={ButtonStyle.Action}
						>
							<FormattedMessage {...messages.button} />
						</Button>
					)}
				>
					<FormattedMessage {...messages.message} />
				</AppAnnouncement>
			</AppearAnimation>
		);
	}

	return null;
};



export default AppUpdated;
