import React from 'react';
import {
	FormattedMessage,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import {
	assertDictionaryId,
} from '~/localization/dictionaries';

import {
	type IssueName,
} from '~/model/issuesNew';

import matchAndReturn from '~/utilities/matchAndReturn';



enum IssueStateSuffix {
	Broken = 'broken',
	NotRequired = 'notRequired',
	Ok = 'ok',
	Unknown = 'unknown',
}



type Props = {
	issue: {
		context: any,
		name: IssueName,
		state: GraphQL.IssueState,
	},
};

const IssueTitle: React.FC<Props> = (props) => {
	const {
		issue,
	} = props;

	const state = matchAndReturn(issue.state, {
		[GraphQL.IssueState.Closed]: IssueStateSuffix.Ok,
		[GraphQL.IssueState.NotApplicable]: IssueStateSuffix.NotRequired,
		[GraphQL.IssueState.NotRequired]: IssueStateSuffix.NotRequired,
		[GraphQL.IssueState.Open]: IssueStateSuffix.Broken,
		[GraphQL.IssueState.Unknown]: IssueStateSuffix.Unknown,
	});

	const messageId = `issues.${issue.name}.title.${state}` as const;

	assertDictionaryId(messageId);

	return (
		<FormattedMessage
			id={messageId}
			values={issue.context}
		/>
	);
};



export default IssueTitle;
