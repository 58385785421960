import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import AbstractTextField from '~/components/patterns/forms/fields/AbstractTextField';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Hint, {
	HintAttachment,
	HintPopupSkin,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import IconOnlyButton from '~/components/patterns/buttons/IconOnlyButton';

import writeToClipboard from '~/utilities/writeToClipboard';



const messages = defineMessages({
	buttonLabel: {
		id: 'ui.clipboardTextField.buttonLabel',
	},
	valueCopied: {
		id: 'ui.clipboardTextField.valueCopied',
	},
});



type Props = {
	name: string,
	value: string,
	width?: React.CSSProperties['width'],
};

const ClipboardTextField: React.FC<Props> = (props) => {
	const {
		name,
		value,
		width,
	} = props;

	const intl = useIntl();
	const [isHintVisible, setHintVisibility] = React.useState(false);

	React.useEffect(
		() => {
			if (isHintVisible) {
				const hintVisibilityTimeout = setTimeout(() => {
					setHintVisibility(false);
				}, 1500);

				return () => clearTimeout(hintVisibilityTimeout);
			}
		},
		[
			isHintVisible,
		],
	);

	return (
		<AbstractTextField
			attributes={{
				disabled: true,
			}}
			buttons={[
				<Hint
					attachment={HintAttachment.Center}
					key="copy-button"
					popup={intl.formatMessage(messages.valueCopied)}
					popupSkin={HintPopupSkin.Light}
					popupVisibility={isHintVisible ? HintPopupVisibility.Always : HintPopupVisibility.Never}
				>
					<IconOnlyButton
						icon={(
							<BasicIcon
								size={24}
								type={BasicIconType.Clipboard}
							/>
						)}
						onClick={() => {
							setHintVisibility(true);
							writeToClipboard(value);
						}}
						title={intl.formatMessage(messages.buttonLabel)}
					/>
				</Hint>,
			]}
			code={true}
			name={name}
			value={value}
			width={width}
		/>
	);
};



export default ClipboardTextField;
