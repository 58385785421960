/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserIsReportRecipientByDefaultQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserIsReportRecipientByDefaultQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly isReportRecipientByDefault: boolean, readonly legacyId: string } | null };


export const UserIsReportRecipientByDefaultDocument = gql`
    query UserIsReportRecipientByDefault($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    isReportRecipientByDefault
    legacyId
  }
}
    `;

/**
 * __useUserIsReportRecipientByDefaultQuery__
 *
 * To run a query within a React component, call `useUserIsReportRecipientByDefaultQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIsReportRecipientByDefaultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIsReportRecipientByDefaultQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserIsReportRecipientByDefaultQuery(baseOptions: Apollo.QueryHookOptions<UserIsReportRecipientByDefaultQuery, UserIsReportRecipientByDefaultQueryVariables> & ({ variables: UserIsReportRecipientByDefaultQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIsReportRecipientByDefaultQuery, UserIsReportRecipientByDefaultQueryVariables>(UserIsReportRecipientByDefaultDocument, options);
      }
export function useUserIsReportRecipientByDefaultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIsReportRecipientByDefaultQuery, UserIsReportRecipientByDefaultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIsReportRecipientByDefaultQuery, UserIsReportRecipientByDefaultQueryVariables>(UserIsReportRecipientByDefaultDocument, options);
        }
export function useUserIsReportRecipientByDefaultSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserIsReportRecipientByDefaultQuery, UserIsReportRecipientByDefaultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserIsReportRecipientByDefaultQuery, UserIsReportRecipientByDefaultQueryVariables>(UserIsReportRecipientByDefaultDocument, options);
        }
export type UserIsReportRecipientByDefaultQueryHookResult = ReturnType<typeof useUserIsReportRecipientByDefaultQuery>;
export type UserIsReportRecipientByDefaultLazyQueryHookResult = ReturnType<typeof useUserIsReportRecipientByDefaultLazyQuery>;
export type UserIsReportRecipientByDefaultSuspenseQueryHookResult = ReturnType<typeof useUserIsReportRecipientByDefaultSuspenseQuery>;
export type UserIsReportRecipientByDefaultQueryResult = Apollo.QueryResult<UserIsReportRecipientByDefaultQuery, UserIsReportRecipientByDefaultQueryVariables>;