/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllUsersQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AllUsersQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly agencyConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessAgencyConnection', readonly agencyAccountId: CK.AccountId, readonly id: any, readonly isPending: boolean }>, readonly connectedClientAccounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly displayName: string, readonly email: string, readonly firstName: string | null, readonly hasTwoFactorAuthentication: boolean, readonly id: string, readonly isAlertRecipientByDefault: boolean, readonly isReportRecipientByDefault: boolean, readonly lastName: string | null, readonly legacyId: string, readonly uniqueId: CK.UserId }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> } | null };


export const AllUsersDocument = gql`
    query AllUsers {
  authenticatedSession {
    accounts {
      agencyConnections {
        agencyAccountId
        id
        isPending
      }
      connectedClientAccounts {
        id
      }
      id
      memberships {
        account {
          id
        }
        hasLimitedWebsitesAccess
        role
        user {
          displayName
          email
          firstName
          hasTwoFactorAuthentication
          id
          isAlertRecipientByDefault
          isReportRecipientByDefault
          lastName
          legacyId
          uniqueId
        }
        websites {
          id
        }
      }
      websites {
        id
      }
    }
  }
}
    `;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export function useAllUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersSuspenseQueryHookResult = ReturnType<typeof useAllUsersSuspenseQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;