/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteStatusQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteStatusQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly problem: GraphQL.WebsiteProblem | null } | null };


export const WebsiteStatusDocument = gql`
    query WebsiteStatus($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    problem
  }
}
    `;

/**
 * __useWebsiteStatusQuery__
 *
 * To run a query within a React component, call `useWebsiteStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteStatusQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteStatusQuery(baseOptions: Apollo.QueryHookOptions<WebsiteStatusQuery, WebsiteStatusQueryVariables> & ({ variables: WebsiteStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteStatusQuery, WebsiteStatusQueryVariables>(WebsiteStatusDocument, options);
      }
export function useWebsiteStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteStatusQuery, WebsiteStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteStatusQuery, WebsiteStatusQueryVariables>(WebsiteStatusDocument, options);
        }
export function useWebsiteStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteStatusQuery, WebsiteStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteStatusQuery, WebsiteStatusQueryVariables>(WebsiteStatusDocument, options);
        }
export type WebsiteStatusQueryHookResult = ReturnType<typeof useWebsiteStatusQuery>;
export type WebsiteStatusLazyQueryHookResult = ReturnType<typeof useWebsiteStatusLazyQuery>;
export type WebsiteStatusSuspenseQueryHookResult = ReturnType<typeof useWebsiteStatusSuspenseQuery>;
export type WebsiteStatusQueryResult = Apollo.QueryResult<WebsiteStatusQuery, WebsiteStatusQueryVariables>;