import classNames from 'classnames';
import React from 'react';

import BasicIcon, {
	BasicIconSpinning,
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';



export enum LastCheckTimestampCrawlingType {
	InProgress = 'in-progress',
	JustFinished = 'just-finished',
	Ready = 'ready',
}

export enum LastCheckTimestampIconAlignment {
	Left = 'left',
	Right = 'right',
}

export enum LastCheckTimestampSize {
	Default = 'default',
	Small = 'small,',
}

type Props = {
	/** Text label next to crawling icon */
	checkedAtLabel: React.ReactNode,
	/** Type of animation on component depends on this crawling type */
	crawlingType?: LastCheckTimestampCrawlingType,
	/** Explanation hint visible on hover */
	explanationMessage?: React.ReactNode,
	/** Alignment of crawling icon */
	iconAlignment?: LastCheckTimestampIconAlignment,
	/** State of crawling is reflected in the look of timestamp */
	isCrawlingPaused?: boolean,
	/** Make component clickable */
	onClick?: () => void,
	/** Font size of component */
	size?: LastCheckTimestampSize,
};



const LastCheckTimestamp: React.FC<Props> = (props) => {
	const {
		checkedAtLabel,
		crawlingType = LastCheckTimestampCrawlingType.Ready,
		explanationMessage,
		iconAlignment = LastCheckTimestampIconAlignment.Right,
		isCrawlingPaused = false,
		onClick,
		size = LastCheckTimestampSize.Default,
	} = props;

	let icon;

	if (isCrawlingPaused) {
		icon = (
			<BasicIcon
				size={size === LastCheckTimestampSize.Small ? 17 : 18}
				type={BasicIconType.Pause}
			/>
		);
	} else {
		let iconAnimationType = BasicIconSpinning.Disabled;

		if (crawlingType === LastCheckTimestampCrawlingType.JustFinished) {
			iconAnimationType = BasicIconSpinning.Once;
		} else if (crawlingType === LastCheckTimestampCrawlingType.InProgress) {
			iconAnimationType = BasicIconSpinning.Infinite;
		}

		icon = (
			<BasicIcon
				color={onClick ? '#5893eb' : '#5C6773'}
				size={size === LastCheckTimestampSize.Small ? 17 : 18}
				spinning={iconAnimationType}
				spinningAnimationSpeed={crawlingType === LastCheckTimestampCrawlingType.JustFinished ? 2 : 1}
				type={BasicIconType.Crawling}
			/>
		);
	}

	const componentClasses = classNames({
		'last-check': true,
		'last-check--icon-alignment-right': iconAlignment === LastCheckTimestampIconAlignment.Right,
		'last-check--small-size': size === LastCheckTimestampSize.Small,
		'last-check--is-clickable': onClick && !isCrawlingPaused,
		'last-check--is-disabled': isCrawlingPaused,
	});

	let output = (
		<span
			className={componentClasses}
			onClick={onClick}
		>
			<span className="last-check__icon">
				{icon}
			</span>

			<span className="last-check__timestamp">
				{checkedAtLabel}
			</span>
		</span>
	);

	if (explanationMessage) {
		output = (
			<Hint
				attachment={HintAttachment.Center}
				popup={explanationMessage}
				popupMaxWidth={230}
				targetCustomCursor={!isCrawlingPaused}
			>
				{output}
			</Hint>
		);
	}

	return output;
};



export default LastCheckTimestamp;
