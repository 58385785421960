import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const IndexabilityIconBuilder: IconBuilder = {
	icon: ({ color = '#6880D1' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeWidth="1"
			>
				<g id="Page-1-Copy">
					<path
						d="M20.3634,7.6354 C23.8784,11.1504 23.8784,16.8484 20.3634,20.3634 C16.8484,23.8784 11.1504,23.8784 7.6354,20.3634 C4.1204,16.8484 4.1204,11.1504 7.6354,7.6354 C11.1504,4.1204 16.8484,4.1204 20.3634,7.6354 L20.3634,7.6354 Z"
						id="Stroke-3"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M21.778,18.9496 L26.728,23.8996 C27.509,24.6806 27.509,25.9476 26.728,26.7286 L26.728,26.7286 C25.947,27.5096 24.681,27.5096 23.9,26.7286 L18.95,21.7776"
						id="Stroke-5"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default IndexabilityIconBuilder;
