import React from 'react';

import ContentHighlightBox from './ContentHighlightBox';
import StringSelectionHighlight from '~/components/patterns/values/StringSelectionHighlight';



type Props = {
	content: string,
	label: React.ReactNode,
	maxLength: number,
	minLength: number,
};

const ContentLengthHighlightBox: React.FC<Props> = (props) => {
	const {
		content,
		label,
		maxLength,
		minLength,
	} = props;

	const length = content.length;

	let element;

	if (length > maxLength) {
		element = (
			<StringSelectionHighlight
				highlightEndIndex={length}
				highlightStartIndex={maxLength}
			>
				{content}
			</StringSelectionHighlight>
		);
	} else if (length < minLength) {
		element = (
			<StringSelectionHighlight
				highlightEndIndex={minLength}
				highlightStartIndex={length}
			>
				{content}
			</StringSelectionHighlight>
		);
	} else {
		element = content;
	}

	return (
		<ContentHighlightBox
			content={element}
			label={label}
		/>
	);
};



export default ContentLengthHighlightBox;
