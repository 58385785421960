import Immutable from 'immutable';
import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';
import {
	type ArrayElement,
} from '~/types/utilities';

import BarChartLabels from '~/components/atoms/charts/components/BarChartLabels';
import IllustrationChartLayout from '~/components/patterns/charts/layouts/IllustrationChartLayout';
import LimitedRecordsOutput from '~/components/atoms/records/LimitedRecordsOutput';
import ScopeLabel, {
	ScopeLabelSize,
	ScopeLabelStyle,
} from '~/components/logic/scopes/ScopeLabel';
import StackedBarChart from '~/components/atoms/charts/charts/StackedBarChart';

import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteScopeIssues from '~/hooks/useWebsiteScopeIssues';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	AffectedPagesCategory,
} from '~/model/issuesNew';

import {
	getSegmentDefinitionByName,
} from '~/model/segments';



const messages = defineMessages({
	hideSegments: {
		id: 'ui.issueDetail.affectedPagesChart.controls.hideSegments',
	},
	showSegments: {
		id: 'ui.issueDetail.affectedPagesChart.controls.showSegments',
	},
});

const segmentMessages = defineMessages({
	[AffectedPagesCategory.Closed]: {
		id: 'ui.issueDetail.affectedPagesChart.notPresentOnSegment',
	},
	[AffectedPagesCategory.Ignored]: {
		id: 'ui.issueDetail.affectedPagesChart.ignoredOnSegment',
	},
	[AffectedPagesCategory.NotApplicable]: {
		id: 'ui.issueDetail.affectedPagesChart.notApplicableOnSegment',
	},
	[AffectedPagesCategory.NotRequired]: {
		id: 'ui.issueDetail.affectedPagesChart.notRequiredOnSegment',
	},
	[AffectedPagesCategory.Open]: {
		id: 'ui.issueDetail.affectedPagesChart.presentOnSegment',
	},
	[AffectedPagesCategory.Unknown]: {
		id: 'ui.issueDetail.affectedPagesChart.unknownOnSegment',
	},
});

const websiteMessages = defineMessages({
	[AffectedPagesCategory.Closed]: {
		id: 'ui.issueDetail.affectedPagesChart.notPresentOnWebsite',
	},
	[AffectedPagesCategory.Ignored]: {
		id: 'ui.issueDetail.affectedPagesChart.ignoredOnWebsite',
	},
	[AffectedPagesCategory.NotApplicable]: {
		id: 'ui.issueDetail.affectedPagesChart.notApplicableOnWebsite',
	},
	[AffectedPagesCategory.NotRequired]: {
		id: 'ui.issueDetail.affectedPagesChart.notRequiredOnWebsite',
	},
	[AffectedPagesCategory.Open]: {
		id: 'ui.issueDetail.affectedPagesChart.presentOnWebsite',
	},
	[AffectedPagesCategory.Unknown]: {
		id: 'ui.issueDetail.affectedPagesChart.unknownOnWebsite',
	},
});

export type SegmentsBarChartExtractCallbackInput = ArrayElement<ReturnType<typeof useWebsiteScopeIssues>>['issueCategories'];



type Props = {
	extractCallback: (issueCategories: SegmentsBarChartExtractCallbackInput) => {
		closed: number,
		ignored: number,
		notApplicable: number,
		notRequired: number,
		open: number,
		unknown: number,
	},
	filter: Immutable.Map<string, any>,
	numberOfIssues: number,
	onOpenBarClickCallback?: (input: { scope: AffectedPagesCategory }) => void,
	showSegmentsButtonOnHover?: boolean,
	websiteId: CK.WebsiteId,
};

const SegmentsBarChart: React.FC<Props> = (props) => {
	const {
		extractCallback,
		filter,
		numberOfIssues,
		onOpenBarClickCallback,
		showSegmentsButtonOnHover = true,
		websiteId,
	} = props;

	const intl = useIntl();
	const segments = useWebsiteSegmentDefinitions(websiteId);
	const viewportWidth = useViewportWidth();
	const websiteScopeIssues = useWebsiteScopeIssues(websiteId);

	const renderLabel = React.useCallback(
		(labelKey) => {
			return (
				<ScopeLabel
					scope={labelKey}
					size={ScopeLabelSize.Small}
					style={ScopeLabelStyle.Thumb}
				/>
			);
		},
		[],
	);

	const renderTooltip = React.useCallback(
		(formatter) => {
			const segment = formatter.key.indexOf('segment') === 0
				? getSegmentDefinitionByName(segments.listAll(), formatter.key.substr(formatter.key.indexOf('/') + 1))
				: null;

			const effectiveMessages = segment ? segmentMessages : websiteMessages;

			return intl.formatMessage(effectiveMessages[formatter.series.name], {
				issues: numberOfIssues,
				pages: formatter.y / 100,
				segmentName: segment ? segment.label : '',
			});
		},
		[
			intl,
			numberOfIssues,
			segments,
		],
	);

	if (websiteScopeIssues === null) {
		return null;
	}

	const categories: Array<string> = [];

	const data: Record<
		AffectedPagesCategory,
		Array<{
			color?: string,
			y: number,
		}>
	> = {
		[AffectedPagesCategory.Closed]: [],
		[AffectedPagesCategory.Ignored]: [],
		[AffectedPagesCategory.NotApplicable]: [],
		[AffectedPagesCategory.NotRequired]: [],
		[AffectedPagesCategory.Open]: [],
		[AffectedPagesCategory.Unknown]: [],
	};

	const groups: Array<(scope: { name: string }) => boolean> = [];

	if (filter.get('segment') !== 'all') {
		groups.push((scope) => scope.name === 'segment/' + filter.get('segment'));
	} else {
		groups.push((scope) => scope.name === 'website');
		groups.push((scope) => scope.name !== 'website');
	}

	groups.forEach((groupFilter) => {
		websiteScopeIssues
			.filter(groupFilter)
			.forEach((scope) => {
				categories.push(scope.name);

				const scopeData = extractCallback(scope.issueCategories);

				data[AffectedPagesCategory.Closed].push({
					y: scopeData.closed,
				});

				data[AffectedPagesCategory.Ignored].push({
					y: scopeData.ignored,
				});

				data[AffectedPagesCategory.NotApplicable].push({
					color: 'rgba(0,0,0,0.05)',
					y: scopeData.notApplicable,
				});

				data[AffectedPagesCategory.NotRequired].push({
					color: 'rgba(0,0,0,0.05)',
					y: scopeData.notRequired,
				});

				data[AffectedPagesCategory.Open].push({
					y: scopeData.open,
				});

				data[AffectedPagesCategory.Unknown].push({
					color: 'rgba(0,0,0,0.05)',
					y: scopeData.unknown,
				});
			});
	});

	return (
		<LimitedRecordsOutput
			limit={1}
			numberOfRecords={categories.length}
			showButtonOnHover={showSegmentsButtonOnHover}
			showLessMessageKey={messages.hideSegments}
			showMoreMessageKey={messages.showSegments}
		>
			{({ numberOfRecords }) => {
				const chartData = Immutable.List([{
					name: AffectedPagesCategory.NotRequired,
					data: data[AffectedPagesCategory.NotRequired].slice(0, numberOfRecords),
					showInLegend: false,
				}, {
					name: AffectedPagesCategory.NotApplicable,
					data: data[AffectedPagesCategory.NotApplicable].slice(0, numberOfRecords),
					showInLegend: false,
				}, {
					name: AffectedPagesCategory.Unknown,
					data: data[AffectedPagesCategory.Unknown].slice(0, numberOfRecords),
					showInLegend: false,
				}, {
					name: AffectedPagesCategory.Closed,
					color: '#42CC67',
					data: data[AffectedPagesCategory.Closed].slice(0, numberOfRecords),
				}, {
					name: AffectedPagesCategory.Ignored,
					color: '#98A5B3',
					data: data[AffectedPagesCategory.Ignored].slice(0, numberOfRecords),
				}, {
					name: AffectedPagesCategory.Open,
					color: '#FF5959',
					data: data[AffectedPagesCategory.Open].slice(0, numberOfRecords),
					cursor: onOpenBarClickCallback ? 'pointer' : undefined,
					point: {
						events: {
							click: onOpenBarClickCallback ? function () {
								onOpenBarClickCallback({
									scope: this.category,
								});
							} : undefined,
						},
					},
				}]);

				return (
					<IllustrationChartLayout>
						<BarChartLabels
							categories={categories.slice(0, numberOfRecords)}
							labelRenderer={renderLabel}
						>
							<StackedBarChart
								categories={categories.slice(0, numberOfRecords)}
								data={chartData}
								key={viewportWidth + '/' + numberOfRecords}
								maximum={100}
								tooltipRenderer={renderTooltip}
							/>
						</BarChartLabels>
					</IllustrationChartLayout>
				);
			}}
		</LimitedRecordsOutput>
	);
};



export default React.memo(SegmentsBarChart);
