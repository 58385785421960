/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAdminPreverifiedDomainsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountAdminPreverifiedDomainsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly preverifiedDomainsByCustomerTeam: ReadonlyArray<string> } | null } | null };

export type UpdateAccountAdminPreverifiedDomainsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  preverifiedDomains: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type UpdateAccountAdminPreverifiedDomainsMutation = { readonly __typename?: 'Mutation', readonly UpdateAccountAdminPreverifiedDomains: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly preverifiedDomainsByCustomerTeam: ReadonlyArray<string> } | null, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isVerified: boolean }> } | null } } };


export const AccountAdminPreverifiedDomainsFormDocument = gql`
    query AccountAdminPreverifiedDomainsForm($accountId: AccountId!) {
  account(id: $accountId) {
    adminSettings {
      preverifiedDomainsByCustomerTeam
    }
    id
  }
}
    `;

/**
 * __useAccountAdminPreverifiedDomainsFormQuery__
 *
 * To run a query within a React component, call `useAccountAdminPreverifiedDomainsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAdminPreverifiedDomainsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAdminPreverifiedDomainsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountAdminPreverifiedDomainsFormQuery(baseOptions: Apollo.QueryHookOptions<AccountAdminPreverifiedDomainsFormQuery, AccountAdminPreverifiedDomainsFormQueryVariables> & ({ variables: AccountAdminPreverifiedDomainsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountAdminPreverifiedDomainsFormQuery, AccountAdminPreverifiedDomainsFormQueryVariables>(AccountAdminPreverifiedDomainsFormDocument, options);
      }
export function useAccountAdminPreverifiedDomainsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountAdminPreverifiedDomainsFormQuery, AccountAdminPreverifiedDomainsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountAdminPreverifiedDomainsFormQuery, AccountAdminPreverifiedDomainsFormQueryVariables>(AccountAdminPreverifiedDomainsFormDocument, options);
        }
export function useAccountAdminPreverifiedDomainsFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountAdminPreverifiedDomainsFormQuery, AccountAdminPreverifiedDomainsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountAdminPreverifiedDomainsFormQuery, AccountAdminPreverifiedDomainsFormQueryVariables>(AccountAdminPreverifiedDomainsFormDocument, options);
        }
export type AccountAdminPreverifiedDomainsFormQueryHookResult = ReturnType<typeof useAccountAdminPreverifiedDomainsFormQuery>;
export type AccountAdminPreverifiedDomainsFormLazyQueryHookResult = ReturnType<typeof useAccountAdminPreverifiedDomainsFormLazyQuery>;
export type AccountAdminPreverifiedDomainsFormSuspenseQueryHookResult = ReturnType<typeof useAccountAdminPreverifiedDomainsFormSuspenseQuery>;
export type AccountAdminPreverifiedDomainsFormQueryResult = Apollo.QueryResult<AccountAdminPreverifiedDomainsFormQuery, AccountAdminPreverifiedDomainsFormQueryVariables>;
export const UpdateAccountAdminPreverifiedDomainsDocument = gql`
    mutation UpdateAccountAdminPreverifiedDomains($accountId: AccountId!, $preverifiedDomains: [String!]!) {
  UpdateAccountAdminPreverifiedDomains(
    accountId: $accountId
    preverifiedDomains: $preverifiedDomains
  ) {
    query {
      account(id: $accountId) {
        adminSettings {
          preverifiedDomainsByCustomerTeam
        }
        id
        websites {
          id
          isVerified
        }
      }
    }
  }
}
    `;
export type UpdateAccountAdminPreverifiedDomainsMutationFn = Apollo.MutationFunction<UpdateAccountAdminPreverifiedDomainsMutation, UpdateAccountAdminPreverifiedDomainsMutationVariables>;

/**
 * __useUpdateAccountAdminPreverifiedDomainsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountAdminPreverifiedDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountAdminPreverifiedDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountAdminPreverifiedDomainsMutation, { data, loading, error }] = useUpdateAccountAdminPreverifiedDomainsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      preverifiedDomains: // value for 'preverifiedDomains'
 *   },
 * });
 */
export function useUpdateAccountAdminPreverifiedDomainsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountAdminPreverifiedDomainsMutation, UpdateAccountAdminPreverifiedDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountAdminPreverifiedDomainsMutation, UpdateAccountAdminPreverifiedDomainsMutationVariables>(UpdateAccountAdminPreverifiedDomainsDocument, options);
      }
export type UpdateAccountAdminPreverifiedDomainsMutationHookResult = ReturnType<typeof useUpdateAccountAdminPreverifiedDomainsMutation>;
export type UpdateAccountAdminPreverifiedDomainsMutationResult = Apollo.MutationResult<UpdateAccountAdminPreverifiedDomainsMutation>;
export type UpdateAccountAdminPreverifiedDomainsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountAdminPreverifiedDomainsMutation, UpdateAccountAdminPreverifiedDomainsMutationVariables>;