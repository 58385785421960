import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BackButton from '~/components/app/BackButton';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import IntercomActivator from '~/components/logic/IntercomActivator';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useRequestAccessToClientMutation,
} from './ConnectExistingAccountStep.gql';

import useAccountCanHaveMoreConnectedTrialAccounts from '~/hooks/useAccountCanHaveMoreConnectedTrialAccounts';

import FormError from '~/utilities/FormError';

import {
	type Values,
} from './types';



const messages = defineMessages({
	continue: {
		id: 'ui.general.continue',
	},
	description: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectExisting.description',
	},
	emailLabel: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectExisting.emailLabel',
	},
	formErrorBlank: {
		id: 'ui.formErrors.blank',
	},
	formErrorConnectionAlreadyExists: {
		id: 'ui.accountAccess.connectToClient.errors.connectionAlreadyExists',
	},
	formErrorConnectionTargetAmbiguous: {
		id: 'ui.accountAccess.connectToAgency.errors.ambiguousTarget',
	},
	formErrorConnectionUnknownUser: {
		id: 'ui.accountAccess.connectToClient.errors.unknownUser',
	},
	formErrorConnectionUnsupportedByClient: {
		id: 'ui.accountAccess.connectToClient.errors.connectionUnsupportedByClient',
	},
	formErrorInvalidEmail: {
		id: 'ui.formErrors.invalidEmail',
	},
	formErrorMaximumAllowedTrialConnectionsLimitReached: {
		id: 'ui.accountAccess.connectToClient.errors.maximumAllowedTrialConnectionsLimitReached.description',
	},
	formErrorUnknownEmailAddress: {
		id: 'ui.accountAccess.connectToClient.errors.unknownEmailAddress',
	},
	sidebar: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectExisting.sidebar',
	},
	title: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectExisting.title',
	},
});


type Props = {
	accountId: CK.AccountId | null,
	goToCreateNewAccount: (values: Values) => void,
	onContinue: (values: Values) => void,
	values: Values,
};

const ConnectExistingAccountStep: React.FC<Props> = (props) => {
	const {
		accountId,
		goToCreateNewAccount,
		onContinue,
		values: defaultValues,
	} = props;

	const accountCanHaveMoreConnectedTrialAccounts = useAccountCanHaveMoreConnectedTrialAccounts(accountId);

	const [requestAccessToClient] = useRequestAccessToClientMutation();

	const handleSubmit = React.useCallback(
		async (values: Values) => {
			if (accountId === null || values.email === undefined) {
				return;
			}

			try {
				await requestAccessToClient({
					variables: {
						agencyId: accountId,
						clientContactEmail: values.email,
						level: GraphQL.AccountAccessConnectionLevel.Owner,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}

			onContinue(values);
		},
		[
			accountId,
			onContinue,
			requestAccessToClient,
		],
	);

	const validations = React.useMemo(
		() => ({
			email: validateField(
				'email',
				(f) => [
					f.validateNonEmpty(),
					f.validateEmail(),
					f.customGlobal({
						globalRule: 'account_access_unknown_invitee',
						message: ({ values }) => (
							<FormattedMessage
								{...messages.formErrorUnknownEmailAddress}
								values={{
									canCreateNewAccount: accountCanHaveMoreConnectedTrialAccounts ? 'yes' : 'no',
									link_step: (chunks) => (
										<InternalLink
											onClickCallback={() => goToCreateNewAccount({
												...defaultValues,
												email: values.email,
											})}
											style={InternalLinkStyle.Decent}
										>
											{chunks}
										</InternalLink>
									),
								}}
							/>
						),
					}),
				],
			),
		}),
		[
			accountCanHaveMoreConnectedTrialAccounts,
			defaultValues,
			goToCreateNewAccount,
		],
	);

	return (
		<MultiStepModalStep
			name="connectExistingAccount"
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							link_article: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
						}}
					/>
				</RichText>
			)}
			title={<FormattedMessage {...messages.title} />}
		>
			<Form
				defaultValues={{
					email: defaultValues.email,
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<ModalTextSection>
					<FormattedMessage {...messages.description} />
				</ModalTextSection>

				<CenteredFormWrapper>
					<FormRow
						htmlFor="email"
						label={(
							<FormattedMessage {...messages.emailLabel} />
						)}
					>
						<FieldStatus name="email">
							<TextField
								autoComplete={TextFieldAutocomplete.Off}
								name="email"
								placeholder="client@example.com"
								type={TextFieldType.Email}
							/>
						</FieldStatus>
					</FormRow>

					<FormErrorMessages
						errors={{
							'account_access_already_exists': (
								<FormattedMessage {...messages.formErrorConnectionAlreadyExists} />
							),
							'account_access_target_already_connected': (
								<FormattedMessage {...messages.formErrorConnectionAlreadyExists} />
							),
							'account_access_unsupported_by_target': (
								<FormattedMessage
									{...messages.formErrorConnectionUnsupportedByClient}
									values={{
										link: linkExternal('https://www.contentkingapp.com/support/connected-accounts/#connection-direction'),
									}}
								/>
							),
							'accountAccessAmbiguousTarget': (
								<FormattedMessage {...messages.formErrorConnectionTargetAmbiguous} />
							),
							'maximumAllowedTrialConnectionsLimitReached': (
								<FormattedMessage
									{...messages.formErrorMaximumAllowedTrialConnectionsLimitReached}
									values={{
										link_support: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
										show_intercom: (chunks) => (
											<IntercomActivator>
												<InternalLink>
													{chunks}
												</InternalLink>
											</IntercomActivator>
										),
									}}
								/>
							),
						}}
					/>
				</CenteredFormWrapper>

				<CenteredFormWrapper>
					<ButtonsLayout
						alignment={ButtonsLayoutAlignment.Right}
						layout={ButtonsLayoutType.Steps}
					>
						<BackButton />

						<SubmitButton>
							<FormattedMessage {...messages.continue} />
						</SubmitButton>
					</ButtonsLayout>
				</CenteredFormWrapper>
			</Form>
		</MultiStepModalStep>
	);
};


export default ConnectExistingAccountStep;
