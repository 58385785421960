import React from 'react';

import AbstractTextField, {
	type AbstractTextFieldRef,
	AbstractTextFieldSize,
	AbstractTextFieldStyle,
	AbstractTextFieldType,
} from '~/components/patterns/forms/fields/AbstractTextField';
import PasswordButton, {
	PasswordButtonSize,
} from '~/components/patterns/forms/fieldParts/buttons/PasswordButton';
import ResetButton from '~/components/patterns/forms/fieldParts/buttons/ResetButton';

import useFormContext from '~/hooks/useFormContext';



export enum TextFieldAutocomplete {
	Off = 'off',
	On = 'on',
	Name = 'name',
	HonorificPrefix = 'honorific-prefix',
	GivenName = 'given-name',
	AdditionalName = 'additional-name',
	FamilyName = 'family-name',
	HonorificSuffix = 'honorific-suffix',
	Nickname = 'nickname',
	Email = 'email',
	Username = 'username',
	NewPassword = 'new-password',
	CurrentPassword = 'current-password',
	OneTimeCode = 'one-time-code',
	OrganizationTitle = 'organization-title',
	Organization = 'organization',
	StreetAddress = 'street-address',
	AddressLine1 = 'address-line1"',
	AddressLine2 = 'address-line2"',
	AddressLine3 = 'address-line3',
	AddressLevel4 = 'address-level4',
	AddressLevel3 = 'address-level3',
	AddressLevel2 = 'address-level2',
	AddressLevel1 = 'address-level1',
	Country = 'country',
	CountryName = 'country-name',
	PostalCode = 'postal-code',
	CcName = 'cc-name',
	CcGivenName = 'cc-given-name',
	CcAdditionalName = 'cc-additional-name',
	CcFamilyName = 'cc-family-name',
	CcNumber = 'cc-number',
	CcExp = 'cc-exp',
	CcExpMonth = 'cc-exp-month',
	CcExpYear = 'cc-exp-year',
	CcCsc = 'cc-csc',
	CcType = 'cc-type',
	TransactionCurrency = 'transaction-currency',
	TransactionAmount = 'transaction-amount',
	Language = 'language',
	Bday = 'bday',
	BdayDay = 'bday-day',
	BdayMonth = 'bday-month',
	BdayYear = 'bday-year',
	Sex = 'sex',
	Tel = 'tel',
	TelCountryCode = 'tel-country-code',
	TelNational = 'tel-national',
	TelAreaCode = 'tel-area-code',
	TelLocal = 'tel-local',
	TelExtension = 'tel-extension',
	Impp = 'impp',
	Url = 'url',
	Photo = 'photo',
}

export type TextFieldRef = {
	changeValue: (value: any) => void,
	focus: () => void,
	getInputElement: () => HTMLInputElement | null,
};



type Props = {
	attributes?: React.AllHTMLAttributes<HTMLInputElement> & Record<`data-${string}`, string>,
	autoComplete?: TextFieldAutocomplete,
	disabled?: boolean,
	emptyAsNull?: boolean,
	name: string,
	placeholder?: string,
	resetButton?: boolean,
	spellCheck?: boolean,
	size?: AbstractTextFieldSize,
	style?: AbstractTextFieldStyle,
	trimValue?: boolean,
	type?: AbstractTextFieldType,
	width?: React.CSSProperties['width'] | false,
};

const TextField = React.forwardRef<TextFieldRef, Props>((props, ref) => {
	const {
		attributes = {},
		autoComplete = TextFieldAutocomplete.On,
		disabled = false,
		emptyAsNull = false,
		name,
		placeholder,
		resetButton = true,
		size = AbstractTextFieldSize.Default,
		spellCheck,
		style = AbstractTextFieldStyle.Light,
		trimValue,
		type = AbstractTextFieldType.Text,
		width,
	} = props;

	const formContext = useFormContext();

	const formContextDefaultValues = formContext.defaultValues;
	const formContextFocused = formContext.focused;
	const formContextIsDisabled = formContext.isDisabled;
	const formContextOnBlurHandler = formContext.onBlurHandler;
	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnFocusHandler = formContext.onFocusHandler;
	const formContextOnMountHandler = formContext.onMountHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;
	const formContextValues = formContext.values;

	const [manuallyRevealedCtaButton, setManuallyRevealedCtaButton] = React.useState(false);
	const [preventReset, setPreventReset] = React.useState(name !== formContextFocused);
	const [visibleType, setVisibleType] = React.useState(type);

	const fieldRef = React.useRef<AbstractTextFieldRef>(null);

	React.useImperativeHandle(ref, () => ({
		changeValue: (value) => {
			fieldRef.current?.setValue(value);
		},
		focus: () => {
			fieldRef.current?.focus();
		},
		getInputElement: () => {
			return fieldRef.current?.getFieldRef().current ?? null;
		},
	}));

	React.useEffect(
		() => {
			formContextOnMountHandler(name, {
				setValues: true,
			});

			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			name,
		],
	);

	const initialFocusRef = React.useRef(formContextFocused);

	React.useEffect(
		() => {
			if (name === initialFocusRef.current) {
				fieldRef.current?.focus();
			}
		},
		[
			name,
		],
	);

	const defaultValue = formContextDefaultValues[name];
	const value = formContextValues[name];

	React.useEffect(
		() => {
			if (defaultValue === undefined || defaultValue === null || defaultValue.length === 0) {
				fieldRef.current?.setValue('');
			}
		},
		[
			defaultValue,
		],
	);

	React.useEffect(
		() => {
			fieldRef.current?.setValue(value ?? '');
		},
		[
			value,
		],
	);

	const handleCTAButtonMouseEnter = React.useCallback(
		() => {
			setManuallyRevealedCtaButton(true);

			setTimeout(() => {
				setPreventReset(false);
			}, 100);
		},
		[],
	);

	const handleCTAButtonMouseLeave = React.useCallback(
		() => {
			setManuallyRevealedCtaButton(false);

			setTimeout(() => {
				setPreventReset(true);
			}, 50);
		},
		[],
	);

	const handleResetValue = React.useCallback(
		(e) => {
			e.preventDefault();

			// this prevents unwanted reset on touch devices
			if (preventReset) {
				return false;
			}

			if (!manuallyRevealedCtaButton && name !== formContextFocused) {
				return false;
			}

			formContextOnChangeHandler(name, emptyAsNull ? null : '');

			fieldRef.current?.setValue('');
			fieldRef.current?.focus();
		},
		[
			emptyAsNull,
			formContextFocused,
			formContextOnChangeHandler,
			manuallyRevealedCtaButton,
			name,
			preventReset,
		],
	);

	const handleTogglePasswordField = React.useCallback(
		(e) => {
			e.preventDefault();

			// allow this behavior only on password field
			if (type !== AbstractTextFieldType.Password) {
				return;
			}

			if (!manuallyRevealedCtaButton && name !== formContextFocused) {
				return;
			}

			setVisibleType(
				visibleType === AbstractTextFieldType.Password ? AbstractTextFieldType.Text : AbstractTextFieldType.Password,
			);

			fieldRef.current?.focus();
		},
		[
			formContextFocused,
			manuallyRevealedCtaButton,
			name,
			type,
			visibleType,
		],
	);

	const additionalAttributes = Object.assign({}, attributes, {
		autoComplete,
	});

	if (placeholder) {
		additionalAttributes.placeholder = placeholder;
	}

	if (disabled || formContextIsDisabled) {
		additionalAttributes.disabled = true;
	}

	if (spellCheck !== undefined) {
		additionalAttributes.spellCheck = spellCheck ? 'true' : 'false';
	}

	const hasCTAButton = resetButton || type === AbstractTextFieldType.Password;
	const buttons: Array<React.ReactElement> = [];

	if (type !== AbstractTextFieldType.Password) {
		if (resetButton && (manuallyRevealedCtaButton || name === formContextFocused) && formContextValues[name] && formContextValues[name].length > 0) {
			buttons.push((
				<ResetButton
					key="reset-button"
					onClick={handleResetValue}
					onMouseEnter={handleCTAButtonMouseEnter}
					onMouseLeave={handleCTAButtonMouseLeave}
					title="Cancel"
				/>
			));
		}
	}

	if (type === AbstractTextFieldType.Password) {
		if ((manuallyRevealedCtaButton || name === formContextFocused) && formContextValues[name] && formContextValues[name].length > 0) {
			buttons.push((
				<PasswordButton
					isPasswordVisible={visibleType !== AbstractTextFieldType.Password}
					key="password-button"
					onClick={handleTogglePasswordField}
					onMouseEnter={handleCTAButtonMouseEnter}
					onMouseLeave={handleCTAButtonMouseLeave}
					size={size === AbstractTextFieldSize.Large ? PasswordButtonSize.Large : PasswordButtonSize.Default}
				/>
			));
		}
	}

	return (
		<AbstractTextField
			attributes={additionalAttributes}
			buttons={!(disabled || formContextIsDisabled) ? buttons : []}
			name={name}
			onBlur={() => {
				if (!hasCTAButton || !manuallyRevealedCtaButton) {
					formContextOnBlurHandler(name);
				}
			}}
			onChangeCallback={(value) => {
				formContextOnChangeHandler(name, emptyAsNull && value === '' ? null : value, {
					timeout: 250,
				});
			}}
			onFocus={() => {
				formContextOnFocusHandler(name);
			}}
			onMouseEnter={() => {
				if (hasCTAButton) {
					handleCTAButtonMouseEnter();
				}
			}}
			onMouseLeave={() => {
				if (hasCTAButton) {
					handleCTAButtonMouseLeave();
				}
			}}
			ref={fieldRef}
			size={size}
			style={style}
			trimValue={trimValue}
			type={visibleType}
			value={formContextDefaultValues[name]}
			width={width}
		/>
	);
});



export default TextField;

export {
	AbstractTextFieldSize as TextFieldSize,
	AbstractTextFieldStyle as TextFieldStyle,
	AbstractTextFieldType as TextFieldType,
};
