const historicalChangesSelector = (state) => state.get('historicalChanges');



export const dateIntervalSelector = (state) => historicalChangesSelector(state).get('dateInterval');

export const filterSelector = (state) => historicalChangesSelector(state).get('filter');

export const filteredSelector = (state) => historicalChangesSelector(state).get('filtered');

export const isInHistoricalChangesModeSelector = (state) => {
	return !!(
		dateIntervalSelector(state).get('startDate')
		&& dateIntervalSelector(state).get('endDate')
	);
};

export const loadingSelector = (state) => historicalChangesSelector(state).get('loading');

export const rawSelector = (state) => historicalChangesSelector(state).get('raw');

export const sortBySelector = (state) => historicalChangesSelector(state).get('sortBy');

export const totalSelector = (state) => historicalChangesSelector(state).get('total');
