import React from 'react';

import GeneralIssueCategoryCardContent from './builders/GeneralIssueCategoryCardContent';
import IssueCategoryCardLayout from './builders/IssueCategoryCardLayout';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';



const IssueCategoryCardSkeleton: React.FC = () => {
	return (
		<IssueCategoryCardLayout isLoading={true}>
			<GeneralIssueCategoryCardContent
				score={(
					<SquareSkeleton
						height={32}
						maxWidth={56}
						style={SquareSkeletonStyle.Light}
					/>
				)}
				title={(
					<SquareSkeleton
						height={18}
						maxWidth={100}
						style={SquareSkeletonStyle.Light}
					/>
				)}
			>
				<SquareSkeleton
					height={16}
					maxWidth={160}
					style={SquareSkeletonStyle.Light}
				/>
			</GeneralIssueCategoryCardContent>
		</IssueCategoryCardLayout>
	);
};



export default IssueCategoryCardSkeleton;
