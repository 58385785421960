/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LargestContentfulPaintWidgetQueryVariables = GraphQL.Exact<{
  date: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type LargestContentfulPaintWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly webVitalsOriginSummaryData: { readonly __typename?: 'WebsiteDashboardWebVitalsOriginSummaryData', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: { readonly __typename?: 'WebVitalsOriginSummaryData', readonly largestContentfulPaint: { readonly __typename?: 'WebVitalsOriginMetric', readonly maximumFast: number, readonly maximumGood: number, readonly percentageFast: CK.Percentage, readonly percentageGood: CK.Percentage, readonly percentageSlow: CK.Percentage, readonly percentile: number, readonly verdict: GraphQL.WebVitalVerdict } | null } | null } } };


export const LargestContentfulPaintWidgetDocument = gql`
    query LargestContentfulPaintWidget($date: DateYMD!, $scope: String!, $websiteId: WebsiteId!) {
  dashboardData {
    webVitalsOriginSummaryData(date: $date, scope: $scope, websiteId: $websiteId) {
      data {
        largestContentfulPaint {
          maximumFast
          maximumGood
          percentageFast
          percentageGood
          percentageSlow
          percentile
          verdict
        }
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useLargestContentfulPaintWidgetQuery__
 *
 * To run a query within a React component, call `useLargestContentfulPaintWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLargestContentfulPaintWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLargestContentfulPaintWidgetQuery({
 *   variables: {
 *      date: // value for 'date'
 *      scope: // value for 'scope'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useLargestContentfulPaintWidgetQuery(baseOptions: Apollo.QueryHookOptions<LargestContentfulPaintWidgetQuery, LargestContentfulPaintWidgetQueryVariables> & ({ variables: LargestContentfulPaintWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LargestContentfulPaintWidgetQuery, LargestContentfulPaintWidgetQueryVariables>(LargestContentfulPaintWidgetDocument, options);
      }
export function useLargestContentfulPaintWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LargestContentfulPaintWidgetQuery, LargestContentfulPaintWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LargestContentfulPaintWidgetQuery, LargestContentfulPaintWidgetQueryVariables>(LargestContentfulPaintWidgetDocument, options);
        }
export function useLargestContentfulPaintWidgetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LargestContentfulPaintWidgetQuery, LargestContentfulPaintWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LargestContentfulPaintWidgetQuery, LargestContentfulPaintWidgetQueryVariables>(LargestContentfulPaintWidgetDocument, options);
        }
export type LargestContentfulPaintWidgetQueryHookResult = ReturnType<typeof useLargestContentfulPaintWidgetQuery>;
export type LargestContentfulPaintWidgetLazyQueryHookResult = ReturnType<typeof useLargestContentfulPaintWidgetLazyQuery>;
export type LargestContentfulPaintWidgetSuspenseQueryHookResult = ReturnType<typeof useLargestContentfulPaintWidgetSuspenseQuery>;
export type LargestContentfulPaintWidgetQueryResult = Apollo.QueryResult<LargestContentfulPaintWidgetQuery, LargestContentfulPaintWidgetQueryVariables>;