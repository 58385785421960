import useAccountId from '~/hooks/useAccountId';
import useHomeAccountIds from '~/hooks/useHomeAccountIds';



function useInHomeAccount(): boolean | null {
	const accountId = useAccountId();
	const homeAccountIds = useHomeAccountIds();

	if (accountId === null || homeAccountIds === null) {
		return null;
	}

	return homeAccountIds.includes(accountId);
}



export default useInHomeAccount;
