import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	PagesColumnsCategory,
} from '~/model/pagesColumns';



const messages = defineMessages({
	[PagesColumnsCategory.AdobeAnalytics]: {
		id: 'ui.contentOverview.category.adobeAnalytics',
	},
	[PagesColumnsCategory.Content]: {
		id: 'ui.contentOverview.category.content',
	},
	[PagesColumnsCategory.Conversions]: {
		id: 'ui.contentOverview.category.conversions',
	},
	[PagesColumnsCategory.CustomElements]: {
		id: 'ui.contentOverview.category.customElements',
	},
	[PagesColumnsCategory.EnrichmentFields]: {
		id: 'ui.contentOverview.category.enrichmentFields',
	},
	[PagesColumnsCategory.Fundamentals]: {
		id: 'ui.contentOverview.category.fundamentals',
	},
	[PagesColumnsCategory.GoogleAnalytics]: {
		id: 'ui.contentOverview.category.google_analytics',
	},
	[PagesColumnsCategory.GoogleSearchConsole]: {
		id: 'ui.contentOverview.category.google_search_console',
	},
	[PagesColumnsCategory.Indexability]: {
		id: 'ui.contentOverview.category.indexability',
	},
	[PagesColumnsCategory.Lighthouse]: {
		id: 'ui.contentOverview.category.lighthouse',
	},
	[PagesColumnsCategory.Relations]: {
		id: 'ui.contentOverview.category.relations',
	},
	[PagesColumnsCategory.SchemaOrg]: {
		id: 'ui.contentOverview.category.schemaOrg',
	},
	[PagesColumnsCategory.SearchEngineActivity]: {
		id: 'ui.contentOverview.category.searchEngineActivity',
	},
	[PagesColumnsCategory.Social]: {
		id: 'ui.contentOverview.category.social',
	},
});



type Props = {
	columnCategory: PagesColumnsCategory,
};

const ColumnCategoryName: React.FC<Props> = (props) => {
	const {
		columnCategory,
	} = props;

	return (
		<FormattedMessage {...messages[columnCategory]} />
	);
};



export default ColumnCategoryName;
