import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useAlertDefinitionDependencies from '~/hooks/useAlertDefinitionDependencies';
import useSegmentsDependingOnColumn from '~/hooks/useSegmentsDependingOnColumn';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	type CustomElementDefinition,
	CustomElementDeletionViabilityVerdict,
} from '~/model/customElements';

import {
	type SegmentDefinition,
	doesColumnRemovalResultInDuplicateSegments,
} from '~/model/segments';



type ReturnType = (customElementDefinition: CustomElementDefinition) => ({
	verdict: CustomElementDeletionViabilityVerdict.NotPrevented,
	dependentSegments: ReadonlyArray<SegmentDefinition>,
} | {
	verdict: CustomElementDeletionViabilityVerdict.PreventedDueToAlertDefinitions,
	dependentAlertDefinitions: ReadonlyArray<GraphQL.AlertDefinition>,
} | {
	verdict: CustomElementDeletionViabilityVerdict.PreventedDueToSegmentDefinitions,
	dependentSegments: ReadonlyArray<SegmentDefinition>,
});

function useGetCustomElementDeletability(
	websiteId: CK.WebsiteId,
): ReturnType {
	const alertDefinitionDependencies = useAlertDefinitionDependencies(websiteId);
	const segments = useWebsiteSegmentDefinitions(websiteId);
	const segmentsDependingOnColumn = useSegmentsDependingOnColumn(websiteId);

	return React.useCallback(
		(customElementDefinition: CustomElementDefinition) => {
			const dependentAlertDefinitions = alertDefinitionDependencies.getAlertDefinitionsDependingOnField(
				customElementDefinition.column,
			);

			if (dependentAlertDefinitions.length > 0) {
				return {
					verdict: CustomElementDeletionViabilityVerdict.PreventedDueToAlertDefinitions,
					dependentAlertDefinitions,
				};
			}

			const disableDeletionDueToSegments = doesColumnRemovalResultInDuplicateSegments(
				customElementDefinition.column,
				segments.listAll(),
			);

			if (disableDeletionDueToSegments) {
				return {
					verdict: CustomElementDeletionViabilityVerdict.PreventedDueToSegmentDefinitions,
					dependentSegments: segmentsDependingOnColumn(customElementDefinition.column) ?? [],
				};
			}

			return {
				verdict: CustomElementDeletionViabilityVerdict.NotPrevented,
				dependentSegments: segmentsDependingOnColumn(customElementDefinition.column) ?? [],
			};
		},
		[
			alertDefinitionDependencies,
			segments,
			segmentsDependingOnColumn,
		],
	);
}



export default useGetCustomElementDeletability;
