import classNames from 'classnames';
import React from 'react';

import styles from './HighlightedValue.module.scss';



export enum HighlightedValueStyle {
	Expressive = 'expressive',
	Normal = 'normal',
}



type Props = {
	children: React.ReactNode,
	/** Style of highlight */
	style?: HighlightedValueStyle,
	/** Enable tag style */
	tag?: boolean,
};

const HighlightedValue: React.FC<Props> = (props) => {
	const {
		children,
		style,
		tag,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isTag]: tag,
		[styles[style + 'Style'] ?? '']: true,
	});

	return (
		<span className={componentClasses}>
			<span>
				{children}
			</span>
		</span>
	);
};



export default HighlightedValue;
