import React from 'react';



type SetState<State> = State | ((previousState: State) => State);

function useStateCallback<T>(
	initialState: T | (() => T),
): [T, (state: SetState<T>, cb?: (state: T) => void) => void] {
	const [state, setState] = React.useState(initialState);
	const cbRef = React.useRef<((state: T) => void) | undefined>(undefined);

	const setStateCallback = React.useCallback(
		(state: SetState<T>, cb?: (state: T) => void) => {
			if (cb) {
				cbRef.current = cb;
			}

			setState(state);
		},
		[],
	);

	React.useEffect(
		() => {
			if (cbRef.current) {
				cbRef.current(state);
				cbRef.current = undefined;
			}
		},
		[
			state,
		],
	);

	return [state, setStateCallback];
}



export default useStateCallback;
