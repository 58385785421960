import React from 'react';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CodeValue from '~/components/patterns/values/CodeValue';
import ComplexListField, {
	type ComplexListFieldRendererInput,
	type ComplexListFieldValidationInput,
} from '~/components/logic/customElements/extractionSteps/ComplexListField';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import SubmitButton from '~/components/app/SubmitButton';
import TextArea from '~/components/atoms/forms/components/TextArea';
import TextualList from '~/components/patterns/lists/TextualList';

import {
	validateField,
} from '~/components/app/validations';

import {
	useValidateSchemaOrgMutation,
} from './AdminSchemaOrgValidator.gql';



const validations = {
	urlLines: validateField(
		'schemaOrgJsons',
		(f) => [
			f.validateMinimumLength(1),
		],
	),
};



const AdminSchemaOrgValidator: React.FC = () => {
	const [errors, setErrors] = React.useState<ReadonlyArray<{
		element: number,
		message: string,
		path: string,
	}>>([]);

	const [validateSchemaOrg] = useValidateSchemaOrgMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			const { data } = await validateSchemaOrg({
				variables: {
					schemaOrgJsons: values.schemaOrgJsons.map(
						(schemaOrgJson) => schemaOrgJson.element,
					),
				},
			});

			setErrors(
				data?.ValidateSchemaOrg.errors ?? [],
			);
		},
		[
			validateSchemaOrg,
		],
	);

	const fields = React.useMemo(
		() => {
			return [
				{
					label: 'Element',
					name: 'element',
					renderer: ({ fieldName }: ComplexListFieldRendererInput) => (
						<TextArea
							autoComplete={false}
							name={fieldName}
							resizable={true}
							spellCheck={false}
							width="100%"
						/>
					),
					validation: ({ f }: ComplexListFieldValidationInput) => ([
						f.validateNonEmpty(),
						f.validateJson(),
					]),
				},
			];
		},
		[],
	);

	return (
		<Form
			defaultFocus="schemaOrgJsons"
			defaultValues={{
				schemaOrgJsons: [],
			}}
			onSuccess={handleSubmit}
			validations={validations}
		>
			<FormRow
				fullwidth={true}
				htmlFor="schemaOrgJsons"
			>
				<ComplexListField
					addButtonLabel="Add element"
					addExtraEmptyRow={false}
					fields={fields}
					name="schemaOrgJsons"
					showAddButton={true}
					showRowNumbers={true}
				/>
			</FormRow>

			<TextualList
				items={errors.map(
					(error) => ({
						label: (
							<>
								Element #{error.element}<br />
								Path: <CodeValue>{error.path}</CodeValue><br />
								Error: {error.message}
							</>
						),
					}),
				)}
			/>

			<ButtonsLayout>
				<SubmitButton>
					Validate
				</SubmitButton>
			</ButtonsLayout>
		</Form>
	);
};



export default AdminSchemaOrgValidator;
