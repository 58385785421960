import React from 'react';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import HealthIcon from '~/components/patterns/icons/HealthIcon';
import ScoreBar, {
	ScoreBarSize,
} from '~/components/patterns/scores/components/ScoreBar';
import ScoreNumber, {
	ScoreNumberSize,
	ScoreNumberStyle,
} from '~/components/patterns/scores/components/ScoreNumber';

import useAnimatedValue from '~/hooks/useAnimatedValue';

import {
	easeInOutSine,
} from '~/utilities/easings';



type Props = {
	animateHeartbeat?: boolean,
	impact?: number,
	label: React.ReactNode,
	maximum?: number,
	paused?: boolean,
	value?: number | null,
};

const ScoreInfo: React.FC<Props> = (props) => {
	const {
		animateHeartbeat,
		impact,
		label,
		maximum,
		paused = false,
		value = null,
	} = props;

	return (
		<div className="score-info">
			<div className="score-info__label">
				<AttachedIcon
					icon={(
						<HealthIcon
							highlighted={animateHeartbeat}
							size={15}
						/>
					)}
				>
					{label}
				</AttachedIcon>
			</div>

			{value !== null ? (
				<Number
					maximum={maximum}
					paused={paused}
					value={value}
				/>
			) : (
				<ScoreNumber
					className="score-info__score"
					localize={false}
					maximum={maximum}
					size={ScoreNumberSize.Responsive}
					style={paused ? ScoreNumberStyle.Greyscale : ScoreNumberStyle.Colored}
				/>
			)}

			<div className="score-info__score-bar">
				<ScoreBar
					greyscale={paused}
					impact={impact ?? value ?? 0}
					impactAnimation={(impact ?? 0) > 0}
					maximum={maximum}
					size={ScoreBarSize.Large}
					value={value ?? undefined}
				/>
			</div>
		</div>
	);
};


type NumberProps = {
	maximum: number | undefined,
	paused: boolean,
	value: number,
};

const Number: React.FC<NumberProps> = (props) => {
	const {
		maximum,
		paused,
		value,
	} = props;

	const [animatedValue, setAnimatedValue] = useAnimatedValue(value, {
		duration: 3000,
		easing: easeInOutSine,
	});

	React.useEffect(
		() => {
			setAnimatedValue(value);
		},
		[
			value,
			setAnimatedValue,
		],
	);

	const renderedValue = Math.round(animatedValue);

	return (
		<ScoreNumber
			className="score-info__score"
			localize={false}
			maximum={maximum}
			size={ScoreNumberSize.Responsive}
			style={paused ? ScoreNumberStyle.Greyscale : ScoreNumberStyle.Colored}
			value={renderedValue}
		/>
	);
};



export default ScoreInfo;
