import classNames from 'classnames';
import React, {
	Component,
} from 'react';
import PropTypes from 'prop-types';



class HeaderDropdownLayout extends Component {

	render() {
		const {
			children,
			isOpen,
		} = this.props;

		const componentClasses = classNames({
			'header-dropdown-layout': true,
			'header-dropdown-layout--is-open': isOpen,
		});

		return (
			<div className={componentClasses}>
				{children}
			</div>
		);
	}

}

HeaderDropdownLayout.defaultProps = {
	isOpen: false,
};

HeaderDropdownLayout.defaultProps = {
	/** Option whether dropdown is open or closed */
	isOpen: PropTypes.bool,
};



export default HeaderDropdownLayout;
