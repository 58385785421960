import classNames from 'classnames';
import flattenChildren from 'react-keyed-flatten-children';
import React from 'react';

import StaticText from './StaticText';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type Props = {
	children?: React.ReactNode,
	ellipsis?: boolean,
	focusTarget?: string,
	items?: ReadonlyArray<React.ReactNode>,
	width?: React.CSSProperties['width'],
};

const StaticList: React.FC<Props> = (props) => {
	const {
		children,
		ellipsis,
		focusTarget,
		items,
		width,
	} = props;

	const listItemClasses = classNames({
		'form-static-text__list-item': true,
		'form-static-text__list-item--ellipsis': ellipsis,
	});

	const finalItems = (items ?? flattenChildren(children)).filter(notEmpty);
	const isEmpty = finalItems.length === 0;

	return (
		<StaticText
			focusTarget={focusTarget}
			width={width}
		>
			{!isEmpty && (
				<ul className="form-static-text__list">
					{finalItems.map((child, index) => {
						return (
							<li
								className={listItemClasses}
								key={'static-list-child-' + index}
							>
								<span>{child}</span>
							</li>
						);
					})}
				</ul>
			)}
			{isEmpty && (
				<span>-</span>
			)}
		</StaticText>
	);
};



export default StaticList;
