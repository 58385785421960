import {
	useUserFirstNameQuery,
} from './useUserFirstName.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserFirstName(legacyUserId: string | null): string | null {
	const { data } = useUserPropertiesQuery(
		useUserFirstNameQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.firstName ?? null;
}



export default useUserFirstName;
