import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';
import {
	IntlProvider,
} from 'react-intl';

import {
	currentUserLocaleSelector,
	localizationMessagesSelector,
} from '~/state/localization/selectors';



const select = createSelector(
	currentUserLocaleSelector,
	localizationMessagesSelector,
	(currentUserLocale, localizationMessages) => {
		return {
			locale: currentUserLocale,
			messages: localizationMessages,
			wrapRichTextChunksInFragment: true,
		};
	},
);

export default connect(select)(IntlProvider);
