import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import IconOnlyButton, {
	IconOnlyButtonSize,
} from '~/components/patterns/buttons/IconOnlyButton';



export enum PasswordButtonSize {
	Default = 'default',
	Large = 'large',
}

type Props = {
	/** If password visible we will display hide icon */
	isPasswordVisible?: boolean,
	/** Callback triggered on component click */
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
	/** Callback triggered on component mouse enter */
	onMouseEnter?: () => void,
	/** Callback triggered on component mouse leave */
	onMouseLeave?: () => void,
	size?: PasswordButtonSize,
	title?: string,
};



const PasswordButton: React.FC<Props> = (props) => {
	const {
		isPasswordVisible = false,
		onClick,
		onMouseEnter,
		onMouseLeave,
		size = PasswordButtonSize.Default,
		title,
	} = props;

	return (
		<IconOnlyButton
			hoverEffect={false}
			icon={(
				<BasicIcon
					type={isPasswordVisible ? BasicIconType.PasswordHide : BasicIconType.PasswordShow}
				/>
			)}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			size={size === PasswordButtonSize.Large ? IconOnlyButtonSize.Large : IconOnlyButtonSize.Default}
			title={title}
		/>
	);
};



export default PasswordButton;
