import classNames from 'classnames';
import React from 'react';

import Hint from '~/components/patterns/hints/hint/Hint';



type Props = {
	label: React.ReactNode,
	tooltip?: React.ReactNode,
};



const NoticeTag: React.FC<Props> = (props) => {
	const {
		label,
		tooltip,
	} = props;

	const tag = (
		<span
			className={classNames({
				'notice-tag': true,
				'notice-tag--is-hoverable': tooltip,
			})}
		>
			{label}
		</span>
	);

	return tooltip ? (
		<Hint
			popup={tooltip}
			popupOffset={({ placement }) => {
				if (placement === 'bottom-start' || placement === 'top-start') {
					return [-5, 0];
				}

				if (placement === 'bottom-end' || placement === 'top-end') {
					return [5, 0];
				}

				return [0, 0];
			}}
		>
			{tag}
		</Hint>
	) : tag;
};



export default NoticeTag;
