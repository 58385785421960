import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



export const SIZE_DEFAULT = 'default';
export const SIZE_LARGE = 'large';

class MarginBox extends Component {

	render() {
		const {
			children,
			size,
		} = this.props;

		const componentClasses = classNames({
			'margin-box': true,
			'margin-box--large': size === SIZE_LARGE,
		});

		return (
			<div className={componentClasses}>
				{children}
			</div>
		);
	}

}

MarginBox.defaultProps = {
	size: SIZE_DEFAULT,
};

MarginBox.propTypes = {
	/** Possibility to set different size of gap */
	size: PropTypes.oneOf([
		SIZE_DEFAULT,
		SIZE_LARGE,
	]).isRequired,
};



export default MarginBox;
