/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteLogFileAnalysisSetupQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteLogFileAnalysisSetupQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isLogFileAnalysisEnabled: boolean, readonly logSources: ReadonlyArray<{ readonly __typename?: 'LogSource', readonly provider: GraphQL.LogSourceProvider, readonly status: GraphQL.LogSourceStatus }> } | null };


export const WebsiteLogFileAnalysisSetupDocument = gql`
    query WebsiteLogFileAnalysisSetup($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isLogFileAnalysisEnabled
    logSources {
      provider
      status
    }
  }
}
    `;

/**
 * __useWebsiteLogFileAnalysisSetupQuery__
 *
 * To run a query within a React component, call `useWebsiteLogFileAnalysisSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteLogFileAnalysisSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteLogFileAnalysisSetupQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteLogFileAnalysisSetupQuery(baseOptions: Apollo.QueryHookOptions<WebsiteLogFileAnalysisSetupQuery, WebsiteLogFileAnalysisSetupQueryVariables> & ({ variables: WebsiteLogFileAnalysisSetupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteLogFileAnalysisSetupQuery, WebsiteLogFileAnalysisSetupQueryVariables>(WebsiteLogFileAnalysisSetupDocument, options);
      }
export function useWebsiteLogFileAnalysisSetupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteLogFileAnalysisSetupQuery, WebsiteLogFileAnalysisSetupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteLogFileAnalysisSetupQuery, WebsiteLogFileAnalysisSetupQueryVariables>(WebsiteLogFileAnalysisSetupDocument, options);
        }
export function useWebsiteLogFileAnalysisSetupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteLogFileAnalysisSetupQuery, WebsiteLogFileAnalysisSetupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteLogFileAnalysisSetupQuery, WebsiteLogFileAnalysisSetupQueryVariables>(WebsiteLogFileAnalysisSetupDocument, options);
        }
export type WebsiteLogFileAnalysisSetupQueryHookResult = ReturnType<typeof useWebsiteLogFileAnalysisSetupQuery>;
export type WebsiteLogFileAnalysisSetupLazyQueryHookResult = ReturnType<typeof useWebsiteLogFileAnalysisSetupLazyQuery>;
export type WebsiteLogFileAnalysisSetupSuspenseQueryHookResult = ReturnType<typeof useWebsiteLogFileAnalysisSetupSuspenseQuery>;
export type WebsiteLogFileAnalysisSetupQueryResult = Apollo.QueryResult<WebsiteLogFileAnalysisSetupQuery, WebsiteLogFileAnalysisSetupQueryVariables>;