import {
	useUserPlatformTourWebinarOfferingQuery,
} from './useUserPlatformTourWebinarOffering.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserPlatformTourWebinarOffering(legacyUserId: string | null) {
	const { data } = useUserPropertiesQuery(
		useUserPlatformTourWebinarOfferingQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.platformTourWebinarOffering ?? null;
}



export default useUserPlatformTourWebinarOffering;
