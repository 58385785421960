/* eslint-disable */
import CK from '~/types/contentking';
namespace GraphQL {
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };


/**
 * ❗️❗️ This file is autogenerated by `yarn generate-graphql-types` ❗️❗️
 * ❗️❗️ Any changes made to this file will be overwritten           ❗️❗️
 */


/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: CK.ID; output: CK.ID; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * - it's physically represented as Int
   * - uniquely identifies a ContentKing Account entity
   */
  AccountAccessAgencyConnectionId: { input: any; output: any; }
  AccountAccessClientConnectionId: { input: any; output: any; }
  AccountId: { input: CK.AccountId; output: CK.AccountId; }
  AlertDefinitionId: { input: CK.AlertDefinitionId; output: CK.AlertDefinitionId; }
  Any: { input: any; output: any; }
  Array: { input: Record<string, any>; output: Record<string, any>; }
  Base64JsonObject: { input: any; output: any; }
  ColumnSetId: { input: CK.ColumnSetId; output: CK.ColumnSetId; }
  CustomElementColumn: { input: `custom_${string}`; output: `custom_${string}`; }
  DateYMD: { input: CK.DateYMD; output: CK.DateYMD; }
  DurationInSeconds: { input: CK.DurationInSeconds; output: CK.DurationInSeconds; }
  EnrichmentFieldColumn: { input: `ef_${string}`; output: `ef_${string}`; }
  Error: { input: CK.MutationError; output: CK.MutationError; }
  FilterDefinition: { input: CK.FilterDefinition; output: CK.FilterDefinition; }
  InvitationId: { input: CK.InvitationId; output: CK.InvitationId; }
  PageSegmentId: { input: CK.PageSegmentId; output: CK.PageSegmentId; }
  PageSegmentName: { input: CK.PageSegmentName; output: CK.PageSegmentName; }
  PageSegmentSizeLimit: { input: CK.PageSegmentSizeLimit; output: CK.PageSegmentSizeLimit; }
  PagesColumn: { input: CK.PagesColumn; output: CK.PagesColumn; }
  Percentage: { input: CK.Percentage; output: CK.Percentage; }
  Timestamp: { input: CK.Timestamp; output: CK.Timestamp; }
  /**
   * - it's physically represented as String
   * - uniquely identifies a ContentKing Page entity
   * - uniquely identifies an absolute URL in context of specific ContentKing Website entity
   * - read more in [full documentation](https://github.com/contentking/codebase/blob/master/docs/full/page_data.md#url-unique-id)
   */
  UrlId: { input: CK.UrlId; output: CK.UrlId; }
  UserId: { input: CK.UserId; output: CK.UserId; }
  /**
   * - it's physically represented as String
   * - uniquely identifies a ContentKing Website entity
   * - has following format '1-23456' where '1' denotes a Datastore in which the Website is located and '23456' is auto-incremented ID generated in the Datastore
   * - read more in [full documentation](https://github.com/contentking/codebase/blob/master/docs/full/data_architecture.md#website-id)
   */
  WebsiteId: { input: CK.WebsiteId; output: CK.WebsiteId; }
};

export type Account = {
  readonly __typename?: 'Account';
  readonly accountAccessRestrictions: AccountAccessRestrictions;
  readonly addons: ReadonlyArray<AccountAddonState>;
  readonly adminSettings: Maybe<AccountAdminSettings>;
  readonly agencyConnections: ReadonlyArray<AccountAccessAgencyConnection>;
  readonly allowedBillingCycles: ReadonlyArray<Term>;
  readonly allowedCurrencies: ReadonlyArray<Currency>;
  readonly allowedPaymentMethods: ReadonlyArray<Scalars['String']['output']>;
  readonly allowedPlans: ReadonlyArray<AccountPlan>;
  readonly availableAddons: ReadonlyArray<AccountAvailableAddon>;
  readonly billingCycle: Term;
  readonly billingDetails: Maybe<AccountBillingDetails>;
  readonly billingEntity: SubscriptionsSellerCode;
  readonly canHaveMoreConnectedTrialAccounts: Scalars['Boolean']['output'];
  readonly canManagersAffectSubscription: Scalars['Boolean']['output'];
  readonly canManagersControlAgencyAccess: Scalars['Boolean']['output'];
  readonly canManagersSeeApiTokens: Scalars['Boolean']['output'];
  readonly canSignup: Scalars['Boolean']['output'];
  readonly canSignupRemoveWebsiteLimit: Scalars['Boolean']['output'];
  readonly clientConnections: ReadonlyArray<AccountAccessClientConnection>;
  readonly cmsApiToken: Maybe<Scalars['String']['output']>;
  readonly conductorOrganizationId: Maybe<Scalars['String']['output']>;
  readonly confirmationName: Scalars['String']['output'];
  readonly connectedAdobeAnalyticsAccounts: Maybe<ReadonlyArray<AdobeAnalyticsAccount>>;
  readonly connectedClientAccounts: ReadonlyArray<Account>;
  readonly connectedGoogleAnalyticsAccounts: Maybe<ReadonlyArray<GoogleAnalyticsAccount>>;
  readonly country: Scalars['String']['output'];
  readonly currency: Maybe<Currency>;
  readonly customElementsLimit: Scalars['Int']['output'];
  readonly customTermsOfUseAgreement: Maybe<Scalars['String']['output']>;
  readonly customerTeamContext: Maybe<CustomerTeamContext>;
  readonly customerType: Maybe<CustomerType>;
  readonly dataRetentionInDays: Scalars['Int']['output'];
  readonly dataRetentionInMonths: Scalars['Int']['output'];
  readonly defaultCountry: Scalars['String']['output'];
  readonly defaultFetchingSettings: AccountDefaultFetchingSettings;
  readonly defaultLighthouseThresholds: WebsiteLighthouseThresholds;
  readonly discounts: ReadonlyArray<Discount>;
  readonly displayName: Scalars['String']['output'];
  readonly endUserPlan: Maybe<EndUserPlan>;
  readonly enrichmentApiToken: Maybe<Scalars['String']['output']>;
  readonly enrichmentFieldsLimit: Scalars['Int']['output'];
  readonly featureFlags: ReadonlyArray<Scalars['String']['output']>;
  readonly features: ReadonlyArray<AccountFeatureState>;
  readonly fixedBillingEntity: Maybe<Scalars['String']['output']>;
  readonly grafanaLink: Maybe<Scalars['String']['output']>;
  readonly hasBillingHistory: Scalars['Boolean']['output'];
  readonly id: Scalars['AccountId']['output'];
  readonly intercomCompanyLink: Maybe<Scalars['String']['output']>;
  readonly invitations: ReadonlyArray<Invitation>;
  readonly invoices: ReadonlyArray<Invoice>;
  readonly isCleanedUp: Scalars['Boolean']['output'];
  readonly isDeliveryAddressEnabled: Scalars['Boolean']['output'];
  readonly isDemoAvailable: Scalars['Boolean']['output'];
  readonly isEnrichmentApiTermsOfUseAccepted: Scalars['Boolean']['output'];
  readonly isExtraInvoiceDetailsEnabled: Scalars['Boolean']['output'];
  readonly isInternalRestricted: Scalars['Boolean']['output'];
  readonly isManaged: Scalars['Boolean']['output'];
  readonly isNumberOfEmployeesEnoughForOutOfBand: Maybe<Scalars['Boolean']['output']>;
  readonly isOutOfBand: Scalars['Boolean']['output'];
  readonly isPartner: Scalars['Boolean']['output'];
  readonly isProfileCompletenessAvailable: Scalars['Boolean']['output'];
  readonly isReportingApiTermsOfUseAccepted: Scalars['Boolean']['output'];
  readonly isShadow: Scalars['Boolean']['output'];
  readonly isSignupOptimized: Scalars['Boolean']['output'];
  readonly isSubscriptionRenewalEnabled: Scalars['Boolean']['output'];
  readonly isSuitableForTrialRevival: Scalars['Boolean']['output'];
  readonly isTrialBarDisplayed: Scalars['Boolean']['output'];
  readonly isTrialExtensionAllowed: Scalars['Boolean']['output'];
  readonly isTrialWebsitesConfigurationCleanedUp: Scalars['Boolean']['output'];
  readonly isTrialWebsitesDataCleanedUp: Scalars['Boolean']['output'];
  readonly isTwoFactorAuthenticationEnforced: Scalars['Boolean']['output'];
  readonly legalDocumentRequirements: ReadonlyArray<LegalDocumentRequirement>;
  readonly logoUrl: Maybe<Scalars['String']['output']>;
  readonly managedActions: ReadonlyArray<ActionWithAccount>;
  readonly managedActionsForKingdomAdmin: ReadonlyArray<ActionWithAccount>;
  readonly manualPaymentNetTerms: Maybe<Scalars['Int']['output']>;
  readonly maximumRegularSignupPageBundle: Maybe<Scalars['Int']['output']>;
  readonly maximumSignupPageBundle: Maybe<Scalars['Int']['output']>;
  readonly members: ReadonlyArray<User>;
  readonly memberships: ReadonlyArray<AccountMembership>;
  readonly messagingAppChannels: ReadonlyArray<MessagingAppChannel>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly organizationTeams: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly pageBundle: Scalars['Int']['output'];
  readonly paidUntil: Maybe<Scalars['Timestamp']['output']>;
  readonly paymentMethod: Maybe<AccountPaymentMethod>;
  readonly paymentStatus: Maybe<AccountPaymentStatus>;
  readonly permittedActions: ReadonlyArray<ActionWithAccount>;
  readonly permittedActionsForKingdomAdmin: Maybe<ReadonlyArray<ActionWithAccount>>;
  readonly phase: AccountPhase;
  readonly plan: Maybe<AccountPlan>;
  readonly possibleBillingCycles: ReadonlyArray<Term>;
  readonly premiumTrial: Maybe<AccountPremiumTrial>;
  readonly premiumTrialOffer: Maybe<AccountPremiumTrialOffer>;
  readonly profileCompleteness: Maybe<AccountProfileCompleteness>;
  readonly recurlyLink: Maybe<Scalars['String']['output']>;
  readonly reportingApiToken: Maybe<Scalars['String']['output']>;
  readonly scrapbookLink: Maybe<Scalars['String']['output']>;
  readonly sharedColumnSets: ReadonlyArray<ColumnSet>;
  readonly state: AccountState;
  readonly stripeCustomerUrl: Maybe<Scalars['String']['output']>;
  readonly suggestedDomains: ReadonlyArray<Scalars['String']['output']>;
  readonly tariff: Scalars['String']['output'];
  readonly trialExpirationDate: Maybe<Scalars['Timestamp']['output']>;
  readonly trialRevivalDate: Maybe<Scalars['Timestamp']['output']>;
  readonly type: AccountType;
  readonly unpaidPhase: Maybe<AccountUnpaidPhase>;
  readonly unpaidSince: Maybe<Scalars['Timestamp']['output']>;
  readonly unpaidSituation: Maybe<AccountUnpaidSituation>;
  readonly userRestrictions: AccountUserRestrictions;
  readonly users: ReadonlyArray<User>;
  readonly websiteRestrictions: AccountWebsiteRestrictions;
  readonly websites: ReadonlyArray<Website>;
};

export type AccountAccessAgencyConnection = {
  readonly __typename?: 'AccountAccessAgencyConnection';
  readonly agencyAccountId: Scalars['AccountId']['output'];
  readonly agencyAccountName: Scalars['String']['output'];
  readonly id: Scalars['AccountAccessAgencyConnectionId']['output'];
  readonly inviterEmail: Maybe<Scalars['String']['output']>;
  readonly isInitiatedByAgency: Scalars['Boolean']['output'];
  readonly isPending: Scalars['Boolean']['output'];
  readonly level: AccountAccessConnectionLevel;
};

export type AccountAccessClientConnection = {
  readonly __typename?: 'AccountAccessClientConnection';
  readonly clientAccountId: Scalars['AccountId']['output'];
  readonly clientAccountName: Scalars['String']['output'];
  readonly id: Scalars['AccountAccessClientConnectionId']['output'];
  readonly inviterEmail: Maybe<Scalars['String']['output']>;
  readonly isInitiatedByAgency: Scalars['Boolean']['output'];
  readonly isPending: Scalars['Boolean']['output'];
  readonly level: AccountAccessConnectionLevel;
};

export enum AccountAccessConnectionDirection {
  ConnectToAgency = 'connectToAgency',
  ConnectToClient = 'connectToClient'
}

export enum AccountAccessConnectionLevel {
  MakeChanges = 'make_changes',
  Owner = 'owner',
  ReadOnly = 'read_only'
}

export type AccountAccessRestrictions = {
  readonly __typename?: 'AccountAccessRestrictions';
  readonly canCreateConnectedClientsWithoutUsers: Scalars['Boolean']['output'];
  readonly canModifyConnectionDirectionDuringTrial: Scalars['Boolean']['output'];
  readonly maximumAllowedConnectedTrialAccounts: Scalars['Int']['output'];
};

export type AccountAddonState = {
  readonly __typename?: 'AccountAddonState';
  readonly addonType: AddonType;
  readonly amount: Scalars['Int']['output'];
};

export enum AccountAdminBookDemoOffering {
  Auto = 'auto',
  Booked = 'booked',
  No = 'no',
  Yes = 'yes'
}

export enum AccountAdminLogoSignificance {
  Big = 'big',
  Huge = 'huge',
  Regular = 'regular'
}

export type AccountAdminSettings = {
  readonly __typename?: 'AccountAdminSettings';
  readonly areIntercomAutomaticMessagesForSalesEnabled: Scalars['Boolean']['output'];
  readonly areIntercomAutomaticMessagesForTrialRevivalEnabled: Scalars['Boolean']['output'];
  readonly areIntercomAutomaticMessagesForWelcomeEnabled: Scalars['Boolean']['output'];
  readonly areSalesHandledExternally: Scalars['Boolean']['output'];
  readonly bookDemoOffering: Maybe<AccountAdminBookDemoOffering>;
  readonly campaignSource: Maybe<Scalars['String']['output']>;
  readonly canHaveMultipleWebsiteCopies: Scalars['Boolean']['output'];
  readonly daysOfNonRenewalGracePeriod: Scalars['Int']['output'];
  readonly daysOfNonRenewalSuspension: Scalars['Int']['output'];
  readonly daysOfPaymentFailureGracePeriod: Scalars['Int']['output'];
  readonly daysOfPaymentFailureNoticeToAllUsers: Scalars['Int']['output'];
  readonly daysOfPaymentFailureNoticeToBillingManagers: Scalars['Int']['output'];
  readonly daysOfPaymentFailureSuspension: Scalars['Int']['output'];
  readonly daysToConfigurationCleanup: Scalars['Int']['output'];
  readonly daysToDataCleanup: Scalars['Int']['output'];
  readonly daysToPremiumTrialRevival: Scalars['Int']['output'];
  readonly daysToTrialRevival: Scalars['Int']['output'];
  readonly discoverAmpPagesByDefault: Scalars['Boolean']['output'];
  readonly isImportant: Scalars['Boolean']['output'];
  readonly isInteresting: Scalars['Boolean']['output'];
  readonly isPremiumTrialRevivalAllowedByCustomerTeam: Scalars['Boolean']['output'];
  readonly isTrialExtensionAllowedByCustomerTeam: BooleanOrAutomatic;
  readonly isTrialRevivalAllowedByCustomerTeam: Scalars['Boolean']['output'];
  readonly logoSignificance: AccountAdminLogoSignificance;
  readonly numberOfReviewsPerReviewSite: ReadonlyArray<AccountNumberOfReviewsOnReviewSite>;
  readonly partnerAffiliateId: Maybe<Scalars['String']['output']>;
  readonly pqlScore: Maybe<Scalars['Int']['output']>;
  readonly preverifiedDomainsByCustomerTeam: ReadonlyArray<Scalars['String']['output']>;
  readonly referringPartnerId: Maybe<Scalars['String']['output']>;
  readonly sqlScore: Maybe<Scalars['Int']['output']>;
  readonly trackH2ToH6HistoricallyByDefault: Scalars['Boolean']['output'];
  readonly trackNumericOnlyChangesByDefault: Scalars['Boolean']['output'];
};

export type AccountAvailableAddon = {
  readonly __typename?: 'AccountAvailableAddon';
  readonly addonType: AddonType;
  readonly maximumAmount: Scalars['Int']['output'];
};

export type AccountBillingDetails = {
  readonly __typename?: 'AccountBillingDetails';
  readonly customerAddress: BillingDetailsAddress;
  readonly deliveryAddress: Maybe<BillingDetailsAddress>;
  readonly email: Scalars['String']['output'];
  readonly emails: ReadonlyArray<Scalars['String']['output']>;
  readonly extraInvoiceDetails: Maybe<BillingExtraInvoiceDetails>;
  readonly tax: Tax;
  readonly vatNumber: Maybe<Scalars['String']['output']>;
};

export type AccountCustomLogoInput = {
  readonly fileContent: Scalars['String']['input'];
  readonly fileSize: Scalars['Int']['input'];
  readonly fileType: Scalars['String']['input'];
};

export type AccountDefaultFetchingSettings = {
  readonly __typename?: 'AccountDefaultFetchingSettings';
  readonly fetchingLocation: FetchingLocation;
  readonly userAgentSettings: UserAgentSettings;
};

export enum AccountFeature {
  AdminRole = 'admin_role',
  AdobeAnalyticsIntegration = 'adobe_analytics_integration',
  AkamaiLogSource = 'akamai_log_source',
  CloudflareLogpushLogSource = 'cloudflare_logpush_log_source',
  CloudfrontLogSource = 'cloudfront_log_source',
  CmsApi = 'cms_api',
  ConnectToAgency = 'connect_to_agency',
  ConnectToClient = 'connect_to_client',
  CustomAlertTrigger = 'custom_alert_trigger',
  CustomElements_2 = 'custom_elements_2',
  CustomElements_5 = 'custom_elements_5',
  CustomElements_10 = 'custom_elements_10',
  CustomElements_15 = 'custom_elements_15',
  CustomElements_20 = 'custom_elements_20',
  CustomElements_25 = 'custom_elements_25',
  CustomElements_30 = 'custom_elements_30',
  CustomElements_35 = 'custom_elements_35',
  CustomElements_40 = 'custom_elements_40',
  DataRetention_6Months = 'data_retention_6_months',
  DataRetention_9Months = 'data_retention_9_months',
  DataRetention_12Months = 'data_retention_12_months',
  DataRetention_14Days = 'data_retention_14_days',
  DataRetention_24Months = 'data_retention_24_months',
  DataRetention_36Months = 'data_retention_36_months',
  DataRetention_48Months = 'data_retention_48_months',
  DataRetention_60Months = 'data_retention_60_months',
  DomainsWhitelist = 'domains_whitelist',
  EmailDomainsWhitelist = 'email_domains_whitelist',
  EnforceTwoFactorAuthentication = 'enforce_two_factor_authentication',
  EnrichmentFields_5 = 'enrichment_fields_5',
  EnrichmentFields_10 = 'enrichment_fields_10',
  EnrichmentFields_15 = 'enrichment_fields_15',
  EnrichmentFields_20 = 'enrichment_fields_20',
  EnrichmentFields_25 = 'enrichment_fields_25',
  EnrichmentFields_30 = 'enrichment_fields_30',
  EnrichmentFields_35 = 'enrichment_fields_35',
  EnrichmentFields_40 = 'enrichment_fields_40',
  FastlyLogSource = 'fastly_log_source',
  GoogleAnalyticsIntegration = 'google_analytics_integration',
  GoogleSearchConsoleIntegration = 'google_search_console_integration',
  HighCrawlingSpeed = 'high_crawling_speed',
  IndexNow = 'index_now',
  IssuesConfiguration = 'issues_configuration',
  LighthouseMonitoring = 'lighthouse_monitoring',
  LogFileAnalysis = 'log_file_analysis',
  ManagersCannotAffectSubscription = 'managers_cannot_affect_subscription',
  ManagersCannotControlAgencyAccess = 'managers_cannot_control_agency_access',
  ManagersCannotSeeApiTokens = 'managers_cannot_see_api_tokens',
  MicrosoftTeams = 'microsoft_teams',
  Rendering = 'rendering',
  ReportingApi = 'reporting_api',
  RequestHeadersSnapshots = 'request_headers_snapshots',
  StoreResponseSnapshots = 'store_response_snapshots',
  WebVitalsOriginSummary = 'web_vitals_origin_summary'
}

export type AccountFeatureAvailability = {
  readonly __typename?: 'AccountFeatureAvailability';
  readonly accountFeature: AccountFeature;
  readonly plan: AccountPlan;
  readonly status: AccountFeatureAvailabilityStatus;
  readonly tariff: Scalars['String']['output'];
};

export enum AccountFeatureAvailabilityStatus {
  Available = 'Available',
  NotAvailable = 'NotAvailable'
}

export type AccountFeatureState = {
  readonly __typename?: 'AccountFeatureState';
  readonly feature: AccountFeature;
  readonly id: Scalars['ID']['output'];
  readonly inUse: Scalars['Boolean']['output'];
};

export type AccountMembership = {
  readonly __typename?: 'AccountMembership';
  readonly account: Account;
  readonly hasLimitedWebsitesAccess: Scalars['Boolean']['output'];
  readonly isOnboardingTourInProgress: Maybe<Scalars['Boolean']['output']>;
  readonly isOwner: Scalars['Boolean']['output'];
  readonly managedActions: ReadonlyArray<ActionWithAccountMembership>;
  readonly managedActionsForKingdomAdmin: ReadonlyArray<ActionWithAccountMembership>;
  readonly permittedActions: ReadonlyArray<ActionWithAccountMembership>;
  readonly permittedActionsForKingdomAdmin: Maybe<ReadonlyArray<ActionWithAccountMembership>>;
  readonly role: UserRole;
  readonly user: User;
  readonly websiteAccess: ReadonlyArray<UserWebsiteAccess>;
  readonly websites: ReadonlyArray<Website>;
};

export type AccountMembershipEmailInput = {
  readonly accountId: Scalars['AccountId']['input'];
  readonly email: Scalars['String']['input'];
};

export type AccountNumberOfReviewsOnReviewSite = {
  readonly __typename?: 'AccountNumberOfReviewsOnReviewSite';
  readonly numberOfReviews: Scalars['Int']['output'];
  readonly reviewSite: Scalars['String']['output'];
};

export type AccountPaymentMethod = {
  readonly __typename?: 'AccountPaymentMethod';
  readonly details: Scalars['Array']['output'];
  readonly type: Scalars['String']['output'];
};

export type AccountPaymentStatus = {
  readonly __typename?: 'AccountPaymentStatus';
  readonly isOkay: Scalars['Boolean']['output'];
  readonly stripeAction: Maybe<StripePaymentAction>;
};

export enum AccountPhase {
  Customer = 'customer',
  Trial = 'trial'
}

export enum AccountPlan {
  Basic = 'basic',
  C2Enterprise = 'c2Enterprise',
  C2Professional = 'c2Professional',
  C2Starter = 'c2Starter',
  Enterprise = 'enterprise',
  EnterpriseLite = 'enterpriseLite',
  Pro = 'pro',
  Prov4 = 'prov4',
  Standard = 'standard'
}

export type AccountPremiumTrial = {
  readonly __typename?: 'AccountPremiumTrial';
  readonly expirationDate: Scalars['Timestamp']['output'];
  readonly plan: AccountPlan;
};

export type AccountPremiumTrialOffer = {
  readonly __typename?: 'AccountPremiumTrialOffer';
  readonly durationInDays: Scalars['Int']['output'];
  readonly plan: AccountPlan;
};

export type AccountProfileCompleteness = {
  readonly __typename?: 'AccountProfileCompleteness';
  readonly percentage: Scalars['Int']['output'];
  readonly tasks: ReadonlyArray<AccountProfileCompletenessTaskStatus>;
};

export enum AccountProfileCompletenessTask {
  AddSecondWebsite = 'addSecondWebsite',
  ConnectGoogleAnalytics = 'connectGoogleAnalytics',
  ConnectGoogleDataStudio = 'connectGoogleDataStudio',
  ConnectGoogleSearchConsole = 'connectGoogleSearchConsole',
  ConnectSlack = 'connectSlack',
  InstallChromeExtension = 'installChromeExtension',
  InviteSecondUser = 'inviteSecondUser',
  InviteThirdUser = 'inviteThirdUser',
  SetAccountName = 'setAccountName'
}

export type AccountProfileCompletenessTaskStatus = {
  readonly __typename?: 'AccountProfileCompletenessTaskStatus';
  readonly isDone: Scalars['Boolean']['output'];
  readonly occurrences: Maybe<Scalars['Int']['output']>;
  readonly type: AccountProfileCompletenessTask;
};

export enum AccountState {
  Active = 'active',
  Churned = 'churned',
  Churning = 'churning',
  Expired = 'expired',
  Preactive = 'preactive'
}

export enum AccountType {
  EndUser = 'end_user',
  Universal = 'universal'
}

export enum AccountUnpaidPhase {
  GracePeriod = 'grace_period',
  NoticeToAllUsers = 'notice_to_all_users',
  NoticeToBillingManagers = 'notice_to_billing_managers',
  Suspension = 'suspension'
}

export enum AccountUnpaidSituation {
  NonRenewal = 'non_renewal',
  PaymentFailure = 'payment_failure'
}

export type AccountUserRestrictions = {
  readonly __typename?: 'AccountUserRestrictions';
  readonly emailDomainsWhitelist: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly maximumInvitations: Scalars['Int']['output'];
};

export type AccountWebsiteRestrictions = {
  readonly __typename?: 'AccountWebsiteRestrictions';
  readonly domainsWhitelist: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly maximumPageBundle: Maybe<Scalars['Int']['output']>;
  readonly maximumPageCapacity: Scalars['Int']['output'];
  readonly maximumUnverifiedWebsites: Maybe<Scalars['Int']['output']>;
  readonly maximumWebsites: Maybe<Scalars['Int']['output']>;
};

export enum ActionWithAccount {
  AddWebsite = 'add_website',
  ExtendTrial = 'extend_trial',
  InviteMember = 'invite_member',
  KnowExistence = 'know_existence',
  ManageAccountAccessDirection = 'manage_account_access_direction',
  ManageAdminAddons = 'manage_admin_addons',
  ManageAdminDefaultWebsiteSettings = 'manage_admin_default_website_settings',
  ManageAdminInvoiceDetails = 'manage_admin_invoice_details',
  ManageAdminMainSettings = 'manage_admin_main_settings',
  ManageAdminPhaseSettings = 'manage_admin_phase_settings',
  ManageAgencyAccess = 'manage_agency_access',
  ManageBilling = 'manage_billing',
  ManageDetails = 'manage_details',
  ManageInternals = 'manage_internals',
  ManageMessagingAppChannels = 'manage_messaging_app_channels',
  ManageOrganizationAccessControl = 'manage_organization_access_control',
  ManageSharedColumnSets = 'manage_shared_column_sets',
  ManageWebsiteDataSettings = 'manage_website_data_settings',
  MarkAccountAsOutOfBand = 'mark_account_as_out_of_band',
  ReviveTrial = 'revive_trial',
  SkipDomainValidation = 'skip_domain_validation',
  SpendMoney = 'spend_money',
  SubmitSignup = 'submit_signup',
  ViewApiTokens = 'view_api_tokens',
  ViewBasics = 'view_basics',
  ViewBilling = 'view_billing',
  ViewMessagingAppChannels = 'view_messaging_app_channels',
  ViewSignup = 'view_signup'
}

export enum ActionWithAccountMembership {
  ChangeRole = 'change_role',
  KnowExistence = 'know_existence',
  ManageAssignedWebsites = 'manage_assigned_websites',
  ManageDetails = 'manage_details',
  RemoveUser = 'remove_user',
  ViewDetails = 'view_details'
}

export enum ActionWithInvitation {
  KnowExistence = 'know_existence',
  ManageDetails = 'manage_details',
  ViewDetails = 'view_details'
}

export enum ActionWithPlatform {
  ManageInternals = 'manage_internals'
}

export enum ActionWithUser {
  KnowExistence = 'know_existence',
  ManageAlertEmailPreferences = 'manage_alert_email_preferences',
  ManageCredentials = 'manage_credentials',
  ManageDetails = 'manage_details',
  ManagePersonalDetails = 'manage_personal_details',
  ManageRoyalAccess = 'manage_royal_access',
  ViewDetails = 'view_details'
}

export enum ActionWithUserWebsiteAccess {
  ManageEmailPreferences = 'manage_email_preferences'
}

export enum ActionWithWebsite {
  DeleteIncorrectLinks = 'delete_incorrect_links',
  KnowExistence = 'know_existence',
  ManageAdminDangerousEscapeHatches = 'manage_admin_dangerous_escape_hatches',
  ManageAdminDebugging = 'manage_admin_debugging',
  ManageAdminFetchingSettings = 'manage_admin_fetching_settings',
  ManageAdminNonstandardBehavior = 'manage_admin_nonstandard_behavior',
  ManageAdminVerification = 'manage_admin_verification',
  ManageAlertDefinitions = 'manage_alert_definitions',
  ManageAuditingSettings = 'manage_auditing_settings',
  ManageCrawlingSpeedMoreThan_1x = 'manage_crawling_speed_more_than_1x',
  ManageEnrichmentFieldsData = 'manage_enrichment_fields_data',
  ManageIgnoring = 'manage_ignoring',
  ManageIndexNow = 'manage_index_now',
  ManageInternals = 'manage_internals',
  ManageIssuesConfiguration = 'manage_issues_configuration',
  ManageLogFileAnalysis = 'manage_log_file_analysis',
  ManageManagedSegments = 'manage_managed_segments',
  ManageMonitoringSettings = 'manage_monitoring_settings',
  ManageRendering = 'manage_rendering',
  ManageSegments = 'manage_segments',
  ManageSettings = 'manage_settings',
  ManageUrlExclusionList = 'manage_url_exclusion_list',
  PruneOrphanPages = 'prune_orphan_pages',
  ReadData = 'read_data',
  ReadGoogleAnalyticsProfiles = 'read_google_analytics_profiles',
  ReadSettings = 'read_settings',
  RemoveWebsite = 'remove_website',
  UpdatePageCapacity = 'update_page_capacity'
}

export type AddCustomerWebsiteResult = MutationResult & {
  readonly __typename?: 'AddCustomerWebsiteResult';
  readonly query: Query;
  readonly website: Website;
};

export type AddEndUserWebsiteResult = MutationResult & {
  readonly __typename?: 'AddEndUserWebsiteResult';
  readonly query: Query;
  readonly website: Website;
};

export type AddTrialWebsiteResult = MutationResult & {
  readonly __typename?: 'AddTrialWebsiteResult';
  readonly query: Query;
  readonly website: Website;
};

export type AddonAmountInput = {
  readonly addonType: AddonType;
  readonly amount: Scalars['Int']['input'];
};

export type AddonAvailability = {
  readonly __typename?: 'AddonAvailability';
  readonly addonType: AddonType;
  readonly maximumAmount: Scalars['Int']['output'];
  readonly plan: AccountPlan;
  readonly tariff: Scalars['String']['output'];
};

export enum AddonType {
  CustomElements_5 = 'custom_elements_5',
  DataRetention_12 = 'data_retention_12',
  EnrichmentFields_5 = 'enrichment_fields_5',
  IndexNow = 'index_now',
  LighthouseMonitoring = 'lighthouse_monitoring',
  MicrosoftTeams = 'microsoft_teams',
  Rendering = 'rendering',
  StoreResponseSnapshots = 'store_response_snapshots'
}

export enum AdministerAccountMainSettingsAccountTypeChange {
  None = 'None',
  ToEndUser = 'ToEndUser',
  ToUniversal = 'ToUniversal'
}

export type AdobeAnalyticsAccount = {
  readonly __typename?: 'AdobeAnalyticsAccount';
  readonly id: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly reportSuites: ReadonlyArray<AdobeAnalyticsReportSuite>;
  readonly status: AdobeAnalyticsAccountStatus;
};

export enum AdobeAnalyticsAccountStatus {
  Active = 'Active',
  Expired = 'Expired',
  Initializing = 'Initializing'
}

export enum AdobeAnalyticsDataMatchingMethod {
  FullUrl = 'fullUrl',
  PageTitle = 'pageTitle'
}

export type AdobeAnalyticsDimension = {
  readonly __typename?: 'AdobeAnalyticsDimension';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type AdobeAnalyticsReportSuite = {
  readonly __typename?: 'AdobeAnalyticsReportSuite';
  readonly currency: Scalars['String']['output'];
  readonly dimensions: ReadonlyArray<AdobeAnalyticsDimension>;
  readonly name: Scalars['String']['output'];
  readonly rsid: Scalars['String']['output'];
};

export type AffectedPagesComparisonConnection = Connection & {
  readonly __typename?: 'AffectedPagesComparisonConnection';
  readonly edges: ReadonlyArray<Maybe<AffectedPagesComparisonConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type AffectedPagesComparisonConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'AffectedPagesComparisonConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly node: AffectedPagesComparisonConnectionNode;
};

export type AffectedPagesComparisonConnectionNode = {
  readonly __typename?: 'AffectedPagesComparisonConnectionNode';
  readonly data: Scalars['Array']['output'];
  readonly id: Scalars['ID']['output'];
};

export type AlertDefinition = {
  readonly __typename?: 'AlertDefinition';
  readonly alertType: Scalars['String']['output'];
  readonly id: Scalars['AlertDefinitionId']['output'];
  readonly messagingAppChannels: ReadonlyArray<MessagingAppChannel>;
  readonly recipients: ReadonlyArray<Scalars['String']['output']>;
  readonly scope: Scalars['String']['output'];
  readonly settings: Scalars['Array']['output'];
};

export enum AlertEmailsSetup {
  All = 'All',
  None = 'None',
  Some = 'Some'
}

export type AlertPagesConnection = Connection & {
  readonly __typename?: 'AlertPagesConnection';
  readonly edges: ReadonlyArray<Maybe<AlertPagesConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type AlertPagesConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'AlertPagesConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly node: AlertPagesConnectionNode;
};

export type AlertPagesConnectionNode = {
  readonly __typename?: 'AlertPagesConnectionNode';
  readonly data: Scalars['Array']['output'];
  readonly id: Scalars['ID']['output'];
  readonly legacyId: Maybe<Scalars['Int']['output']>;
};

export enum AlertPagesSortBy {
  Relevance = 'relevance',
  Status = 'status',
  Url = 'url'
}

export enum AlertSensitivityLevel {
  Always = 'always',
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type AlertTypeDefinition = {
  readonly __typename?: 'AlertTypeDefinition';
  readonly hasScope: Scalars['Boolean']['output'];
  readonly hasSensitivity: Scalars['Boolean']['output'];
  readonly id: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
};

export type AnalyticServiceItemsCollection = {
  readonly __typename?: 'AnalyticServiceItemsCollection';
  readonly contentItems: ReadonlyArray<NullableContentItem>;
  readonly type: AnalyticServiceType;
};

export enum AnalyticServiceType {
  AdobeAnalytics = 'adobe_analytics',
  GoogleAnalytics = 'google_analytics'
}

export type AuthenticatedSession = {
  readonly __typename?: 'AuthenticatedSession';
  readonly accounts: ReadonlyArray<Account>;
  readonly allMembershipLabels: ReadonlyArray<AuthenticatedSessionAccountMembershipLabel>;
  readonly intercomUserHash: Maybe<Scalars['String']['output']>;
  readonly intercomUserId: Maybe<Scalars['String']['output']>;
  readonly isImpersonated: Scalars['Boolean']['output'];
  readonly keepAlive: Scalars['Boolean']['output'];
  readonly kingdomAdmin: Maybe<User>;
  readonly latestIncidents: ReadonlyArray<WebsiteIncident>;
  readonly memberships: ReadonlyArray<AccountMembership>;
  readonly personalColumnSets: ReadonlyArray<ColumnSet>;
  readonly user: User;
};

export type AuthenticatedSessionAccountMembershipLabel = {
  readonly __typename?: 'AuthenticatedSessionAccountMembershipLabel';
  readonly accountDisplayName: Scalars['String']['output'];
  readonly accountId: Scalars['AccountId']['output'];
  readonly numberOfWebsitesInLimitedWebsitesAccess: Maybe<Scalars['Int']['output']>;
  readonly role: UserRole;
};

export type BasicMutationResult = MutationResult & {
  readonly __typename?: 'BasicMutationResult';
  readonly query: Query;
};

export type BillingDetailsAddress = {
  readonly __typename?: 'BillingDetailsAddress';
  readonly address: Scalars['String']['output'];
  readonly city: Scalars['String']['output'];
  readonly country: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly postalCode: Scalars['String']['output'];
  readonly state: Maybe<Scalars['String']['output']>;
};

export type BillingEntity = {
  readonly __typename?: 'BillingEntity';
  readonly allowedCurrencies: ReadonlyArray<Currency>;
  readonly forCountries: ReadonlyArray<Scalars['String']['output']>;
  readonly id: SubscriptionsSellerCode;
  readonly isDataProcessingAgreementRequired: Scalars['Boolean']['output'];
};

export type BillingExtraInvoiceDetails = {
  readonly __typename?: 'BillingExtraInvoiceDetails';
  readonly attn: Maybe<Scalars['String']['output']>;
  readonly customerReference: Maybe<Scalars['String']['output']>;
  readonly purchaseOrderNumber: Maybe<Scalars['String']['output']>;
  readonly supplierNumber: Maybe<Scalars['String']['output']>;
};

export enum BooleanOrAutomatic {
  Automatic = 'automatic',
  No = 'no',
  Yes = 'yes'
}

export type CheckPageWithUrlResult = MutationResult & {
  readonly __typename?: 'CheckPageWithUrlResult';
  readonly query: Query;
  readonly urlIndexStatus: UrlIndexStatus;
};

export type ColumnSet = {
  readonly __typename?: 'ColumnSet';
  readonly columns: ReadonlyArray<ColumnSetColumn>;
  readonly id: Scalars['ColumnSetId']['output'];
  readonly isDefault: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
};

export type ColumnSetColumn = {
  readonly __typename?: 'ColumnSetColumn';
  readonly enabled: Scalars['Boolean']['output'];
  readonly name: Scalars['PagesColumn']['output'];
};

export type ColumnSetColumnInput = {
  readonly enabled: Scalars['Boolean']['input'];
  readonly name: Scalars['String']['input'];
};

export type ConductorOrganization = {
  readonly __typename?: 'ConductorOrganization';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type ConnectAdobeAnalyticsAccountResult = MutationResult & {
  readonly __typename?: 'ConnectAdobeAnalyticsAccountResult';
  readonly adobeAnalyticsAccount: AdobeAnalyticsAccount;
  readonly query: Query;
};

export type ConnectSlackAccountResult = MutationResult & {
  readonly __typename?: 'ConnectSlackAccountResult';
  readonly query: Query;
  readonly registeredMessagingAppChannelId: Maybe<Scalars['ID']['output']>;
};

export type Connection = {
  readonly edges: ReadonlyArray<Maybe<ConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type ConnectionEdge = {
  readonly cursor: Scalars['Base64JsonObject']['output'];
};

export type ContentData = {
  readonly __typename?: 'ContentData';
  readonly content: Scalars['String']['output'];
  readonly position: Scalars['Int']['output'];
  readonly type: ContentType;
};

export type ContentItem = {
  readonly __typename?: 'ContentItem';
  readonly content: Scalars['String']['output'];
  readonly position: Scalars['Int']['output'];
};

export type ContentItemsCollection = {
  readonly __typename?: 'ContentItemsCollection';
  readonly contentItems: ReadonlyArray<ContentItem>;
  readonly isExpectedToBeNonEmpty: Scalars['Boolean']['output'];
  readonly type: ContentType;
};

export enum ContentType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  MetaBingbot = 'meta_bingbot',
  MetaDescription = 'meta_description',
  MetaGooglebot = 'meta_googlebot',
  MetaRobots = 'meta_robots',
  MetaSlurp = 'meta_slurp',
  MetaYandex = 'meta_yandex',
  OpenGraphDescription = 'open_graph_description',
  OpenGraphImage = 'open_graph_image',
  OpenGraphTitle = 'open_graph_title',
  OpenGraphType = 'open_graph_type',
  OpenGraphUrl = 'open_graph_url',
  Title = 'title',
  TwitterCard = 'twitter_card',
  TwitterDescription = 'twitter_description',
  TwitterImage = 'twitter_image',
  TwitterSite = 'twitter_site',
  TwitterTitle = 'twitter_title',
  XRobotsTag = 'x_robots_tag'
}

export enum CoreWebVitalsAssessment {
  CannotBeAssessed = 'CannotBeAssessed',
  Failed = 'Failed',
  Passed = 'Passed'
}

export type CreateColumnSetResult = MutationResult & {
  readonly __typename?: 'CreateColumnSetResult';
  readonly createdColumnSet: ColumnSet;
  readonly query: Query;
};

export type CreateEnrichmentFieldResult = MutationResult & {
  readonly __typename?: 'CreateEnrichmentFieldResult';
  readonly enrichmentField: Maybe<EnrichmentField>;
  readonly query: Query;
};

export type CreateExportCriteria = {
  readonly alertId: InputMaybe<Scalars['Int']['input']>;
  readonly columns: InputMaybe<Scalars['String']['input']>;
  readonly endDate: InputMaybe<Scalars['String']['input']>;
  readonly filter: InputMaybe<Scalars['Array']['input']>;
  readonly issueCategory: InputMaybe<Scalars['String']['input']>;
  readonly issueType: InputMaybe<Scalars['String']['input']>;
  readonly relationType: InputMaybe<Scalars['String']['input']>;
  readonly sortBy: InputMaybe<Scalars['Array']['input']>;
  readonly startDate: InputMaybe<Scalars['String']['input']>;
};

export type CreateSegmentResult = MutationResult & {
  readonly __typename?: 'CreateSegmentResult';
  readonly createdPageSegment: PageSegment;
  readonly query: Query;
};

export type CreateStaticSegmentResult = MutationResult & {
  readonly __typename?: 'CreateStaticSegmentResult';
  readonly createdPageSegment: PageSegment;
  readonly createdPageSegmentUrls: StaticPageSegmentUrlsResult;
  readonly query: Query;
};

export type CreateTrialAccountFromConductorResult = MutationResult & {
  readonly __typename?: 'CreateTrialAccountFromConductorResult';
  readonly initialContentkingAppUrl: Scalars['String']['output'];
  readonly query: Query;
  readonly usersInConflict: ReadonlyArray<Scalars['String']['output']>;
};

export type CreateTrialAccountResult = MutationResult & {
  readonly __typename?: 'CreateTrialAccountResult';
  readonly accountId: Scalars['AccountId']['output'];
  readonly intercomUserId: Maybe<Scalars['String']['output']>;
  readonly query: Query;
};

export enum Currency {
  Czk = 'CZK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type CustomElement = {
  readonly __typename?: 'CustomElement';
  readonly column: Scalars['CustomElementColumn']['output'];
  readonly createdAt: Scalars['Timestamp']['output'];
  readonly dataType: CustomElementDataType;
  readonly extraction: Scalars['Array']['output'];
  readonly label: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type CustomElementData = {
  readonly __typename?: 'CustomElementData';
  readonly isExtracted: Scalars['Boolean']['output'];
  readonly type: CustomElement;
  readonly value: Maybe<Scalars['Any']['output']>;
};

export enum CustomElementDataType {
  Boolean = 'boolean',
  Date = 'date',
  Integer = 'integer',
  Number = 'number',
  String = 'string'
}

export type CustomerTeamContext = {
  readonly __typename?: 'CustomerTeamContext';
  readonly details: Maybe<Scalars['Array']['output']>;
};

export enum CustomerType {
  Agency = 'agency',
  Ecommerce = 'ecommerce',
  Marketplace = 'marketplace',
  Other = 'other',
  Publisher = 'publisher',
  ServiceProvider = 'service_provider',
  Travel = 'travel'
}

export type DashboardData = {
  readonly __typename?: 'DashboardData';
  readonly distributionOfIsIndexable: WebsiteDashboardDistributionOfIsIndexable;
  readonly distributionOfPageType: WebsiteDashboardDistributionOfPageType;
  readonly health: WebsiteDashboardHealth;
  readonly healthChanges: WebsiteDashboardHealthChanges;
  readonly issuesChanges: WebsiteDashboardIssuesChanges;
  readonly numberOfActiveIncidents: WebsiteDashboardNumberOfActiveIncidents;
  readonly numberOfIssues: WebsiteDashboardNumberOfIssues;
  readonly numberOfPages: WebsiteDashboardNumberOfPages;
  readonly pageChanges: WebsiteDashboardPageChanges;
  readonly webVitalsOriginSummaryData: WebsiteDashboardWebVitalsOriginSummaryData;
};


export type DashboardDataDistributionOfIsIndexableArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type DashboardDataDistributionOfPageTypeArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type DashboardDataHealthArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type DashboardDataHealthChangesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type DashboardDataIssuesChangesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type DashboardDataNumberOfActiveIncidentsArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type DashboardDataNumberOfIssuesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type DashboardDataNumberOfPagesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type DashboardDataPageChangesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type DashboardDataWebVitalsOriginSummaryDataArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};

export type DateRange = {
  readonly __typename?: 'DateRange';
  readonly since: Scalars['DateYMD']['output'];
  readonly until: Scalars['DateYMD']['output'];
};

export enum DetectedContentDeliveryNetwork {
  Akamai = 'Akamai',
  Automatic = 'Automatic',
  Cloudflare = 'Cloudflare',
  Cloudfront = 'Cloudfront',
  Fastly = 'Fastly'
}

export type DiagnoseExternalDomainResult = {
  readonly __typename?: 'DiagnoseExternalDomainResult';
  readonly externalDomainInfo: ReadonlyArray<ExternalDomainInfo>;
};

export type Discount = {
  readonly __typename?: 'Discount';
  readonly amount: Maybe<ReadonlyArray<DiscountExactAmount>>;
  readonly coupon: Scalars['String']['output'];
  readonly percentage: Maybe<Scalars['Percentage']['output']>;
  readonly type: DiscountType;
};

export type DiscountExactAmount = {
  readonly __typename?: 'DiscountExactAmount';
  readonly currency: Currency;
  readonly value: Scalars['Float']['output'];
};

export enum DiscountType {
  Dollars = 'dollars',
  Percent = 'percent'
}

export type DomainAddedInBulk = {
  readonly domain: Scalars['String']['input'];
  readonly pageCapacity: Scalars['Int']['input'];
};

export type DomainPreverifiedStatus = {
  readonly __typename?: 'DomainPreverifiedStatus';
  readonly isPreverified: Scalars['Boolean']['output'];
};

export enum DomainValidationFailure {
  BlacklistedDomain = 'blacklisted_domain',
  Connect = 'connect',
  EmptyResponse = 'empty_response',
  NotWhitelistedDomain = 'not_whitelisted_domain',
  SizeLimit = 'size_limit',
  TargetRedirected = 'target_redirected',
  TimeLimit = 'time_limit',
  UnresolvableDomain = 'unresolvable_domain',
  Unspecified = 'unspecified',
  Waf = 'waf',
  WebsiteAlreadyMonitored = 'website_already_monitored'
}

export type DomainsValidationFailedDomain = {
  readonly __typename?: 'DomainsValidationFailedDomain';
  readonly domain: Scalars['String']['output'];
  readonly failureReason: DomainValidationFailure;
  readonly isDomainVerified: Scalars['Boolean']['output'];
};

export type DomainsValidationFinishedDomain = {
  readonly __typename?: 'DomainsValidationFinishedDomain';
  readonly domain: Scalars['String']['output'];
  readonly isDomainVerified: Scalars['Boolean']['output'];
};

export type DomainsValidationPendingDomain = {
  readonly __typename?: 'DomainsValidationPendingDomain';
  readonly domain: Scalars['String']['output'];
  readonly isDomainVerified: Scalars['Boolean']['output'];
};

export enum EndUserPlan {
  EndUserEssentials = 'end_user_essentials',
  EndUserPro = 'end_user_pro'
}

export type EnrichmentField = {
  readonly __typename?: 'EnrichmentField';
  readonly column: Scalars['EnrichmentFieldColumn']['output'];
  readonly createdAt: Scalars['Timestamp']['output'];
  readonly dataType: EnrichmentFieldDataType;
  readonly id: Scalars['ID']['output'];
  readonly label: Scalars['String']['output'];
};

export type EnrichmentFieldData = {
  readonly __typename?: 'EnrichmentFieldData';
  readonly isProvided: Scalars['Boolean']['output'];
  readonly type: EnrichmentField;
  readonly value: Maybe<Scalars['Any']['output']>;
};

export enum EnrichmentFieldDataType {
  Boolean = 'boolean',
  Date = 'date',
  Number = 'number',
  String = 'string'
}

export type ExportFetchingSnapshotResult = MutationResult & {
  readonly __typename?: 'ExportFetchingSnapshotResult';
  readonly export: FetchingSnapshotExport;
  readonly query: Query;
};

export enum ExportType {
  AffectedLinks = 'affectedLinks',
  AffectedPages = 'affected_pages',
  AlertPages = 'alert_pages',
  ChangeTracking = 'change_tracking',
  PageRelations = 'page_relations',
  Pages = 'pages',
  SingleIssueAffectedPages = 'single_issue_affected_pages',
  SingleIssueHistoricalAffectedPages = 'single_issue_historical_affected_pages',
  SitemapsList = 'sitemaps_list'
}

export type ExternalDomainInfo = {
  readonly __typename?: 'ExternalDomainInfo';
  readonly averageFetchingRatePerCycle: Scalars['Float']['output'];
  readonly countAll: Scalars['Int']['output'];
  readonly createdAt: Scalars['Timestamp']['output'];
  readonly fetchingConnectTimeout: Scalars['Int']['output'];
  readonly fetchingCookies: ReadonlyArray<Scalars['String']['output']>;
  readonly fetchingHostIpAddress: Maybe<Scalars['String']['output']>;
  readonly fetchingHttpHeaders: ReadonlyArray<Scalars['Array']['output']>;
  readonly fetchingIpPool: FetchingIpPool;
  readonly fetchingLocation: Scalars['String']['output'];
  readonly fetchingSizeLimit: Scalars['Int']['output'];
  readonly fetchingTimeout: Scalars['Int']['output'];
  readonly fetchingUserAgent: Maybe<Scalars['String']['output']>;
  readonly isCrawlingStopped: Scalars['Boolean']['output'];
  readonly websiteId: Scalars['WebsiteId']['output'];
};

export type FailedJobStatistic = {
  readonly __typename?: 'FailedJobStatistic';
  readonly context: Scalars['String']['output'];
  readonly failure: Scalars['String']['output'];
  readonly jobQueue: Scalars['String']['output'];
  readonly jobType: Scalars['String']['output'];
  readonly numberOfFailedJobs: Scalars['Int']['output'];
};

export type FeedbackOpportunity = {
  readonly __typename?: 'FeedbackOpportunity';
  readonly id: Scalars['ID']['output'];
  readonly isProvided: Scalars['Boolean']['output'];
};

export enum FetchingFailureReason {
  Connect = 'connect',
  EmptyResponse = 'empty_response',
  LighthouseFailed = 'lighthouse_failed',
  RedirectClient = 'redirect_client',
  RenderTimeout = 'render_timeout',
  SizeLimit = 'size_limit',
  TimeLimit = 'time_limit',
  TooManyOnPageRequests = 'too_many_on_page_requests',
  UnresolvableDomain = 'unresolvable_domain',
  Unspecified = 'unspecified',
  Waf = 'waf'
}

export enum FetchingIpPool {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary'
}

export enum FetchingLocation {
  LeasewebEu = 'leaseweb_eu',
  LeasewebUk = 'leaseweb_uk',
  LeasewebUs = 'leaseweb_us'
}

export type FetchingSnapshotExport = {
  readonly __typename?: 'FetchingSnapshotExport';
  readonly downloadUrl: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['Int']['output'];
  readonly isFinished: Scalars['Boolean']['output'];
};

export type FilteredPagesConnection = Connection & {
  readonly __typename?: 'FilteredPagesConnection';
  readonly distributions: Scalars['Array']['output'];
  readonly edges: ReadonlyArray<Maybe<FilteredPagesConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type FilteredPagesConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'FilteredPagesConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly loadedAt: Scalars['Int']['output'];
  readonly node: FilteredPagesConnectionNode;
};

export type FilteredPagesConnectionNode = {
  readonly __typename?: 'FilteredPagesConnectionNode';
  readonly data: Scalars['Array']['output'];
  readonly id: Scalars['ID']['output'];
  readonly legacyId: Maybe<Scalars['Int']['output']>;
};

export type GoogleAnalyticsAccount = {
  readonly __typename?: 'GoogleAnalyticsAccount';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly properties: ReadonlyArray<GoogleAnalyticsProperty>;
};

export type GoogleAnalyticsProperty = {
  readonly __typename?: 'GoogleAnalyticsProperty';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly version: GoogleAnalyticsVersion;
  readonly views: Maybe<ReadonlyArray<GoogleAnalyticsView>>;
};

export enum GoogleAnalyticsVersion {
  V3 = 'v3',
  V4 = 'v4'
}

export type GoogleAnalyticsView = {
  readonly __typename?: 'GoogleAnalyticsView';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
};

export type HttpAuthCredentials = {
  readonly password: Scalars['String']['input'];
  readonly username: Scalars['String']['input'];
};

export type HttpHeader = {
  readonly __typename?: 'HttpHeader';
  readonly name: Scalars['String']['output'];
  readonly values: ReadonlyArray<Scalars['String']['output']>;
};

export type ImageSizeVersion = {
  readonly __typename?: 'ImageSizeVersion';
  readonly height: Scalars['Int']['output'];
  readonly scaleFactor: Scalars['Int']['output'];
  readonly url: Scalars['String']['output'];
  readonly width: Scalars['Int']['output'];
};

export type ImpersonateUserResult = MutationResult & {
  readonly __typename?: 'ImpersonateUserResult';
  readonly query: Query;
  readonly suggestedImpersonations: Maybe<ReadonlyArray<SuggestedImpersonation>>;
};

export type ImportSegmentsChangeCriteriaInstructionInput = {
  readonly filterDefinition: Scalars['FilterDefinition']['input'];
  readonly segmentId: Scalars['PageSegmentId']['input'];
  readonly sizeLimit: InputMaybe<Scalars['PageSegmentSizeLimit']['input']>;
  readonly sourceSegmentId: Scalars['PageSegmentId']['input'];
};

export type ImportSegmentsChangeIdentifierInstructionInput = {
  readonly color: Scalars['String']['input'];
  readonly iconName: InputMaybe<Scalars['String']['input']>;
  readonly label: Scalars['String']['input'];
  readonly segmentId: Scalars['PageSegmentId']['input'];
  readonly shortcode: InputMaybe<Scalars['String']['input']>;
  readonly sourceSegmentId: Scalars['PageSegmentId']['input'];
};

export type ImportSegmentsCreateInstructionInput = {
  readonly color: Scalars['String']['input'];
  readonly filterDefinition: Scalars['FilterDefinition']['input'];
  readonly iconName: InputMaybe<Scalars['String']['input']>;
  readonly label: Scalars['String']['input'];
  readonly shortcode: InputMaybe<Scalars['String']['input']>;
  readonly sizeLimit: InputMaybe<Scalars['PageSegmentSizeLimit']['input']>;
  readonly sourceSegmentId: Scalars['PageSegmentId']['input'];
};

export enum IndexNowSegmentsBlocklistMode {
  AllowAllExcept = 'AllowAllExcept',
  DenyAllExcept = 'DenyAllExcept'
}

export type IndexNowSubmission = {
  readonly __typename?: 'IndexNowSubmission';
  readonly disabledCustomElementTriggers: ReadonlyArray<Scalars['String']['output']>;
  readonly disabledNativeTriggers: ReadonlyArray<Scalars['String']['output']>;
  readonly requiredNotPassingIssues: ReadonlyArray<Scalars['String']['output']>;
  readonly skippedBecauseNotEnabled: Scalars['Boolean']['output'];
  readonly skippedBecauseNotImplemented: Scalars['Boolean']['output'];
  readonly skippedBecauseNotIndexable: Scalars['Boolean']['output'];
  readonly skippedBecauseNotPassingSegmentBlocklist: Scalars['Boolean']['output'];
  readonly skippedBecauseNotPassingUrlBlocklist: Scalars['Boolean']['output'];
  readonly skippedBecauseNothingTriggered: Scalars['Boolean']['output'];
};

export type InitiateAcceptInvitationWithTwoFactorAuthenticationSetupResult = MutationResult & {
  readonly __typename?: 'InitiateAcceptInvitationWithTwoFactorAuthenticationSetupResult';
  readonly email: Scalars['String']['output'];
  readonly qrcode: Scalars['String']['output'];
  readonly query: Query;
  readonly secretKey: Scalars['String']['output'];
};

export type InitiateLoginWithEmailResult = MutationResult & {
  readonly __typename?: 'InitiateLoginWithEmailResult';
  readonly hasPassword: Scalars['Boolean']['output'];
  readonly query: Query;
};

export type InitiateSepaDirectDebitSetupResult = MutationResult & {
  readonly __typename?: 'InitiateSepaDirectDebitSetupResult';
  readonly clientSecret: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly query: Query;
};

export type InitiateTwoFactorAuthenticationSetupResult = MutationResult & {
  readonly __typename?: 'InitiateTwoFactorAuthenticationSetupResult';
  readonly qrcode: Scalars['String']['output'];
  readonly query: Query;
  readonly secretEmail: Scalars['String']['output'];
  readonly secretKey: Scalars['String']['output'];
};

export type InitiateValidateDomainsSessionResult = MutationResult & {
  readonly __typename?: 'InitiateValidateDomainsSessionResult';
  readonly query: Query;
  readonly sessionId: Scalars['String']['output'];
};

export type InitiateValidateInvitationsSessionResult = MutationResult & {
  readonly __typename?: 'InitiateValidateInvitationsSessionResult';
  readonly query: Query;
  readonly sessionId: Scalars['String']['output'];
};

export type Invitation = {
  readonly __typename?: 'Invitation';
  readonly displayName: Scalars['String']['output'];
  readonly email: Scalars['String']['output'];
  readonly hasLimitedWebsitesAccess: Scalars['Boolean']['output'];
  readonly id: Scalars['InvitationId']['output'];
  readonly managedActions: ReadonlyArray<ActionWithInvitation>;
  readonly managedActionsForKingdomAdmin: ReadonlyArray<ActionWithInvitation>;
  readonly permittedActions: ReadonlyArray<ActionWithInvitation>;
  readonly permittedActionsForKingdomAdmin: Maybe<ReadonlyArray<ActionWithInvitation>>;
  readonly role: UserRole;
  readonly websiteEmailSettings: ReadonlyArray<WebsiteEmailSettings>;
  readonly websites: ReadonlyArray<Website>;
};

export enum InvitationValidationFailure {
  AlreadyInvitedIntoHomeAccount = 'AlreadyInvitedIntoHomeAccount',
  AlreadyPresentInHomeAccount = 'AlreadyPresentInHomeAccount',
  BlacklistedDomain = 'BlacklistedDomain',
  NotWhitelistedDomain = 'NotWhitelistedDomain'
}

export type InvitationsValidationFailedEmail = {
  readonly __typename?: 'InvitationsValidationFailedEmail';
  readonly email: Scalars['String']['output'];
  readonly failureReason: InvitationValidationFailure;
};

export type InvitationsValidationFinishedEmail = {
  readonly __typename?: 'InvitationsValidationFinishedEmail';
  readonly email: Scalars['String']['output'];
};

export type InvitationsValidationPendingEmail = {
  readonly __typename?: 'InvitationsValidationPendingEmail';
  readonly email: Scalars['String']['output'];
};

export type InviteNewUsersInBulkInvitationInput = {
  readonly email: Scalars['String']['input'];
  readonly role: UserRole;
};

export type Invoice = {
  readonly __typename?: 'Invoice';
  readonly currency: Currency;
  readonly downloadUrl: Scalars['String']['output'];
  readonly dueDate: Maybe<Scalars['DateYMD']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly issuedAt: Scalars['Timestamp']['output'];
  readonly number: Scalars['String']['output'];
  readonly status: InvoiceStatus;
  readonly totalAmount: Scalars['Int']['output'];
};

export enum InvoiceStatus {
  NotPaid = 'NotPaid',
  Paid = 'Paid',
  PastDue = 'PastDue',
  Pending = 'Pending'
}

export enum IssueCategoryState {
  Closed = 'closed',
  NotApplicable = 'not_applicable',
  NotRequired = 'not_required',
  Open = 'open',
  Unknown = 'unknown'
}

export type IssueIgnoringRuleOnWebsite = {
  readonly __typename?: 'IssueIgnoringRuleOnWebsite';
  readonly id: Scalars['ID']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly isEffective: Scalars['Boolean']['output'];
};

export enum IssueState {
  Closed = 'closed',
  NotApplicable = 'not_applicable',
  NotRequired = 'not_required',
  Open = 'open',
  Unknown = 'unknown'
}

export type IssuesAffectedPagesConnection = Connection & {
  readonly __typename?: 'IssuesAffectedPagesConnection';
  readonly edges: ReadonlyArray<Maybe<IssuesAffectedPagesConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type IssuesAffectedPagesConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'IssuesAffectedPagesConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly node: IssuesAffectedPagesConnectionNode;
};

export type IssuesAffectedPagesConnectionNode = {
  readonly __typename?: 'IssuesAffectedPagesConnectionNode';
  readonly data: Scalars['Array']['output'];
  readonly id: Scalars['ID']['output'];
  readonly legacyId: Maybe<Scalars['Int']['output']>;
};

export type KingdomSettings = {
  readonly __typename?: 'KingdomSettings';
  readonly conductorCustomerDomains: ReadonlyArray<Scalars['String']['output']>;
  readonly freeEmails: ReadonlyArray<Scalars['String']['output']>;
  readonly guaranteedBlacklistDomains: ReadonlyArray<Scalars['String']['output']>;
  readonly overridableBlacklistDomains: ReadonlyArray<Scalars['String']['output']>;
  readonly trialAbuserEmails: ReadonlyArray<Scalars['String']['output']>;
};

export type LegalDocumentRequirement = {
  readonly __typename?: 'LegalDocumentRequirement';
  readonly acceptedAt: Maybe<Scalars['Timestamp']['output']>;
  readonly documentType: LegalDocumentType;
  readonly requirement: LegalDocumentRequirementType;
};

export enum LegalDocumentRequirementType {
  Accepted = 'Accepted',
  NotApplicable = 'NotApplicable',
  Required = 'Required',
  RequiredWithUrgency = 'RequiredWithUrgency'
}

export enum LegalDocumentType {
  Dpa = 'dpa',
  Tou = 'tou',
  Woc = 'woc'
}

export type LinkRelItem = {
  readonly __typename?: 'LinkRelItem';
  readonly content: Maybe<Scalars['String']['output']>;
  readonly hreflang: Maybe<Scalars['String']['output']>;
  readonly isCrossdomain: Maybe<Scalars['Boolean']['output']>;
  readonly legacyUrlId: Maybe<Scalars['Int']['output']>;
  readonly position: Scalars['Int']['output'];
  readonly url: Maybe<Scalars['String']['output']>;
};

export type LinkRelItemsCollection = {
  readonly __typename?: 'LinkRelItemsCollection';
  readonly isExpectedToBeNonEmpty: Scalars['Boolean']['output'];
  readonly linkRelItems: ReadonlyArray<LinkRelItem>;
  readonly type: LinkRelType;
};

export enum LinkRelType {
  Canonical = 'canonical',
  LinkAlternateHreflang = 'link_alternate_hreflang',
  LinkAmp = 'link_amp',
  LinkCanonicalHeader = 'link_canonical_header',
  LinkNext = 'link_next',
  LinkPrev = 'link_prev',
  MobileVariant = 'mobile_variant'
}

export type LogFileAnalysisS3Bucket = {
  readonly __typename?: 'LogFileAnalysisS3Bucket';
  readonly accessKeyId: Scalars['String']['output'];
  readonly domain: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly region: LogFileAnalysisS3BucketRegion;
  readonly secretAccessKey: Scalars['String']['output'];
};

export enum LogFileAnalysisS3BucketRegion {
  EuCentral_1 = 'eu_central_1',
  UsEast_2 = 'us_east_2'
}

export type LogSource = {
  readonly __typename?: 'LogSource';
  readonly provider: LogSourceProvider;
  readonly status: LogSourceStatus;
};

export enum LogSourceProvider {
  Akamai = 'Akamai',
  Cloudflare = 'Cloudflare',
  CloudflareLogpush = 'CloudflareLogpush',
  Cloudfront = 'Cloudfront',
  Fastly = 'Fastly'
}

export enum LogSourceStatus {
  Available = 'Available',
  Enabled = 'Enabled',
  NotAvailable = 'NotAvailable'
}

export type MessagingAppChannel = {
  readonly __typename?: 'MessagingAppChannel';
  readonly id: Scalars['ID']['output'];
  readonly label: Scalars['String']['output'];
  readonly messagingAppType: MessagingAppType;
  readonly ownerAccountId: Scalars['AccountId']['output'];
};

export enum MessagingAppType {
  MicrosoftTeams = 'microsoftTeams',
  Slack = 'slack'
}

export enum MethodToEnterSudoMode {
  Password = 'Password',
  TwoFactorAuthentication = 'TwoFactorAuthentication'
}

export type MoneyAmount = {
  readonly __typename?: 'MoneyAmount';
  readonly currency: Scalars['String']['output'];
  readonly value: Scalars['Float']['output'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly AcceptAccessToClient: BasicMutationResult;
  readonly AcceptEnrichmentApiTermsOfUse: BasicMutationResult;
  readonly AcceptInvitationWithTwoFactorAuthenticationSetup: BasicMutationResult;
  readonly AcceptLegalAgreement: BasicMutationResult;
  readonly AcceptReportingApiTermsOfUse: BasicMutationResult;
  readonly ActivatePremiumTrial: BasicMutationResult;
  readonly AddConductorCustomerDomains: BasicMutationResult;
  readonly AddCustomerWebsite: AddCustomerWebsiteResult;
  readonly AddCustomerWebsitesInBulk: BasicMutationResult;
  readonly AddEndUserWebsite: AddEndUserWebsiteResult;
  readonly AddFreeEmailDomains: BasicMutationResult;
  readonly AddGuaranteedBlacklistDomains: BasicMutationResult;
  readonly AddOverridableBlacklistDomains: BasicMutationResult;
  readonly AddTrialAbuserEmails: BasicMutationResult;
  readonly AddTrialWebsite: AddTrialWebsiteResult;
  readonly AddTrialWebsitesInBulk: BasicMutationResult;
  readonly AdministerAccountAddons: BasicMutationResult;
  readonly AdministerAccountCertifiedPartnerProgramSettings: BasicMutationResult;
  readonly AdministerAccountEmailSettings: BasicMutationResult;
  readonly AdministerAccountFeatures: BasicMutationResult;
  readonly AdministerAccountInvoiceSettings: BasicMutationResult;
  readonly AdministerAccountMainSettings: BasicMutationResult;
  readonly AdministerAccountSalesSettings: BasicMutationResult;
  readonly AdministerAccountTransactionSettings: BasicMutationResult;
  readonly AdministerInvoiceDetails: BasicMutationResult;
  readonly AdministerUserSettings: BasicMutationResult;
  readonly AdministerWebsiteSettings: BasicMutationResult;
  readonly BookDemo: BasicMutationResult;
  readonly BulkCreateAlertDefinitions: BasicMutationResult;
  readonly BulkDeleteAlertDefinitions: BasicMutationResult;
  readonly BulkUpdateAlertDefinitionsMessagingAppChannels: BasicMutationResult;
  readonly BulkUpdateAlertDefinitionsRecipients: BasicMutationResult;
  readonly BulkUpdateAlertDefinitionsScope: BasicMutationResult;
  readonly BulkUpdateAlertDefinitionsSensitivityLevel: BasicMutationResult;
  readonly CancelUserInvitation: BasicMutationResult;
  readonly ChangePassword: BasicMutationResult;
  readonly ChangeUserRole: BasicMutationResult;
  readonly CheckPageWithUrl: CheckPageWithUrlResult;
  readonly ChooseCustomerType: BasicMutationResult;
  readonly CleanCustomerAccount: BasicMutationResult;
  readonly CleanupOrphanUrls: BasicMutationResult;
  readonly CompleteTwoFactorAuthenticationSetup: BasicMutationResult;
  readonly CompleteValidateDomainsSession: BasicMutationResult;
  readonly CompleteValidateInvitationsSession: BasicMutationResult;
  readonly ConnectAdobeAnalyticsAccount: ConnectAdobeAnalyticsAccountResult;
  readonly ConnectAdobeAnalyticsAccountToWebsite: BasicMutationResult;
  readonly ConnectConductorOrganization: BasicMutationResult;
  readonly ConnectGoogleAnalyticsAccount: BasicMutationResult;
  readonly ConnectGoogleAnalyticsAccountToWebsite: BasicMutationResult;
  readonly ConnectGoogleAnalyticsV4AccountToWebsite: BasicMutationResult;
  readonly ConnectGoogleSearchConsoleAccount: BasicMutationResult;
  readonly ConnectSlackAccount: ConnectSlackAccountResult;
  readonly CopyCustomElementToWebsites: BasicMutationResult;
  readonly CopySegmentToWebsites: BasicMutationResult;
  readonly CreateConnectedClient: BasicMutationResult;
  readonly CreateCustomElement: BasicMutationResult;
  readonly CreateCustomElementOnWebsites: BasicMutationResult;
  readonly CreateEnrichmentField: CreateEnrichmentFieldResult;
  readonly CreateExport: BasicMutationResult;
  readonly CreateLogFileAnalysisS3Bucket: BasicMutationResult;
  readonly CreatePasswordDuringOnboarding: BasicMutationResult;
  readonly CreatePersonalColumnSet: CreateColumnSetResult;
  readonly CreateSegment: CreateSegmentResult;
  readonly CreateSharedColumnSet: CreateColumnSetResult;
  readonly CreateStaticSegment: CreateStaticSegmentResult;
  readonly CreateTrialAccount: CreateTrialAccountResult;
  readonly CreateTrialAccountFromConductor: CreateTrialAccountFromConductorResult;
  readonly DeleteAccount: BasicMutationResult;
  readonly DeleteColumnSet: BasicMutationResult;
  readonly DeleteCustomElement: BasicMutationResult;
  readonly DeleteCustomUrls: BasicMutationResult;
  readonly DeleteEnrichmentField: BasicMutationResult;
  readonly DeleteIncorrectLinks: BasicMutationResult;
  readonly DeleteMessagingAppChannels: BasicMutationResult;
  readonly DeleteSegment: BasicMutationResult;
  readonly DeleteUrlsByFilter: BasicMutationResult;
  readonly DeleteWebsite: BasicMutationResult;
  readonly DisableAdobeAnalyticsForWebsite: BasicMutationResult;
  readonly DisableGoogleAnalyticsForWebsite: BasicMutationResult;
  readonly DisableSubscriptionRenewal: BasicMutationResult;
  readonly DisconnectConductorOrganization: BasicMutationResult;
  readonly EditCustomElement: BasicMutationResult;
  readonly EditEnrichmentField: BasicMutationResult;
  readonly EnterSudoModeUsingPassword: BasicMutationResult;
  readonly EnterSudoModeUsingTwoFactorAuthentication: BasicMutationResult;
  readonly ExportFetchingSnapshot: ExportFetchingSnapshotResult;
  readonly ExportRobotsTxtRevision: ExportFetchingSnapshotResult;
  readonly ExtendTrial: BasicMutationResult;
  readonly FinishLoginWithTwoFactorAuthentication: BasicMutationResult;
  readonly FinishOnboarding: BasicMutationResult;
  readonly FinishUnpaidSignup: BasicMutationResult;
  readonly GrantAgencyAccess: BasicMutationResult;
  readonly IgnoreIssueCategoryOnWholeWebsite: BasicMutationResult;
  readonly IgnorePageIssueCasesOnWholeWebsite: BasicMutationResult;
  readonly IgnorePageIssueCategoryOnSegments: BasicMutationResult;
  readonly IgnorePageIssueCategoryOnSpecificPages: BasicMutationResult;
  readonly IgnorePageIssueOnSegments: BasicMutationResult;
  readonly IgnorePageIssueOnSpecificPages: BasicMutationResult;
  readonly IgnorePageIssueOnWholeWebsite: BasicMutationResult;
  readonly IgnorePlatformIssue: BasicMutationResult;
  readonly ImpersonateUser: ImpersonateUserResult;
  readonly ImportCustomUrls: BasicMutationResult;
  readonly ImportSegments: BasicMutationResult;
  readonly ImportUser: BasicMutationResult;
  readonly ImportWebsite: BasicMutationResult;
  readonly InitiateAcceptInvitationWithTwoFactorAuthenticationSetup: InitiateAcceptInvitationWithTwoFactorAuthenticationSetupResult;
  readonly InitiateLoginWithEmail: InitiateLoginWithEmailResult;
  readonly InitiateResetPasswordWithTwoFactorAuthenticationSetup: BasicMutationResult;
  readonly InitiateSepaDirectDebitSetup: InitiateSepaDirectDebitSetupResult;
  readonly InitiateTwoFactorAuthenticationSetup: InitiateTwoFactorAuthenticationSetupResult;
  readonly InitiateValidateDomainsSession: InitiateValidateDomainsSessionResult;
  readonly InitiateValidateInvitationsSession: InitiateValidateInvitationsSessionResult;
  readonly InstallCloudflareWorker: BasicMutationResult;
  readonly InviteNewUser: BasicMutationResult;
  readonly InviteNewUsersInBulk: BasicMutationResult;
  readonly LoginWithCredentials: BasicMutationResult;
  readonly LoginWithGoogle: BasicMutationResult;
  readonly Logout: BasicMutationResult;
  readonly ManageRoyalAccess: BasicMutationResult;
  readonly ManageWebsiteSuperConductorConfiguration: BasicMutationResult;
  readonly MarkAccountAsOutOfBand: BasicMutationResult;
  readonly ModifyAccountAccessConnectionDirection: BasicMutationResult;
  readonly OfferAgencyAccess: BasicMutationResult;
  readonly PrioritizeExternalUrls: BasicMutationResult;
  readonly PrioritizePage: PrioritizePageResult;
  readonly PrioritizeUrlsByFilter: BasicMutationResult;
  readonly ProvideFeedback: BasicMutationResult;
  readonly PurgePagesHistoryByFilter: BasicMutationResult;
  readonly RefreshEnrichmentApiToken: BasicMutationResult;
  readonly RefreshPaymentStatus: BasicMutationResult;
  readonly RefreshReportingApiToken: BasicMutationResult;
  readonly RegisterMicrosoftTeamsIncomingWebhook: RegisterMicrosoftTeamsIncomingWebhookResult;
  readonly RememberDisplayedInterfaceHints: BasicMutationResult;
  readonly RemoveAccessToClient: BasicMutationResult;
  readonly RemoveTwoFactorAuthentication: BasicMutationResult;
  readonly RemoveUnclickablePages: BasicMutationResult;
  readonly RemoveUser: BasicMutationResult;
  readonly ReportBlockedIntercom: BasicMutationResult;
  readonly RequestAccessToClient: BasicMutationResult;
  readonly RequestPasswordReset: BasicMutationResult;
  readonly ResetEnrichmentField: BasicMutationResult;
  readonly ResetPassword: ResetPasswordResult;
  readonly ResumeSubscriptionRenewal: BasicMutationResult;
  readonly RetryFailedJobs: BasicMutationResult;
  readonly ReviveTrialByAddingWebsite: ReviveTrialByAddingWebsiteResult;
  readonly RevokeAgencyAccess: BasicMutationResult;
  readonly SetNameDuringOnboarding: BasicMutationResult;
  readonly SetupHttpAuthentication: BasicMutationResult;
  readonly SetupIndexNowKey: BasicMutationResult;
  readonly ShareColumnSet: BasicMutationResult;
  readonly Signup: BasicMutationResult;
  readonly StageDomainsForValidation: StageDomainsForValidationResult;
  readonly StageDomainsForValidationFromFreeInput: StageDomainsForValidationFromFreeInputResult;
  readonly StageEmailsForValidationFromFreeInput: StageEmailsForValidationFromFreeInputResult;
  readonly StoreCustomerDetails: BasicMutationResult;
  readonly StorePaymentMethod: BasicMutationResult;
  readonly TestCustomElementExtraction: TestCustomElementExtractionResult;
  readonly UnignoreIssueCategoryOnWholeWebsite: BasicMutationResult;
  readonly UnignorePageIssueCasesOnWholeWebsite: BasicMutationResult;
  readonly UnignorePageIssueCategoryOnSegments: BasicMutationResult;
  readonly UnignorePageIssueCategoryOnSpecificPages: BasicMutationResult;
  readonly UnignorePageIssueOnSegments: BasicMutationResult;
  readonly UnignorePageIssueOnSpecificPages: BasicMutationResult;
  readonly UnignorePageIssueOnWholeWebsite: BasicMutationResult;
  readonly UnignorePlatformIssue: BasicMutationResult;
  readonly Unsignup: BasicMutationResult;
  readonly UnstageDomainsFromValidationSession: UnstageDomainsFromValidationSessionResult;
  readonly UnstageEmailsFromValidationSession: UnstageEmailsFromValidationSessionResult;
  readonly UpdateAccountAdminCustomerPhaseSettings: BasicMutationResult;
  readonly UpdateAccountAdminDefaultWebsiteSettings: BasicMutationResult;
  readonly UpdateAccountAdminPreverifiedDomains: BasicMutationResult;
  readonly UpdateAccountAdminTrialPhaseSettings: BasicMutationResult;
  readonly UpdateAccountMonitoringSettings: BasicMutationResult;
  readonly UpdateAccountProfile: BasicMutationResult;
  readonly UpdateAdvancedWebsiteMonitoringSettings: BasicMutationResult;
  readonly UpdateAlertDefinition: BasicMutationResult;
  readonly UpdateColumnSet: BasicMutationResult;
  readonly UpdateEndUserPlan: BasicMutationResult;
  readonly UpdateLighthouseThresholds: BasicMutationResult;
  readonly UpdateLimitedWebsitesAccessOfWebsite: BasicMutationResult;
  readonly UpdateNumberOfEmployees: BasicMutationResult;
  readonly UpdateOrganizationAccessControl: BasicMutationResult;
  readonly UpdatePageIssuesConfiguration: BasicMutationResult;
  readonly UpdatePagesEnteredOrLeftSegmentAlertDefinition: BasicMutationResult;
  readonly UpdateSegment: UpdateSegmentResult;
  readonly UpdateSendingOfAlertsAndReportsFromWebsite: BasicMutationResult;
  readonly UpdateSendingOfAlertsAndReportsFromWebsites: BasicMutationResult;
  readonly UpdateSendingOfAlertsAndReportsToInvitedUser: BasicMutationResult;
  readonly UpdateSendingOfAlertsAndReportsToUser: BasicMutationResult;
  readonly UpdateStaticSegment: UpdateSegmentResult;
  readonly UpdateSubscription: BasicMutationResult;
  readonly UpdateUrlExclusionList: BasicMutationResult;
  readonly UpdateUserColumnWidth: BasicMutationResult;
  readonly UpdateUserHiddenDashboardScopes: BasicMutationResult;
  readonly UpdateUserMarketingEmailsPreferences: BasicMutationResult;
  readonly UpdateUserPageDetailSourceMode: BasicMutationResult;
  readonly UpdateUserProfile: BasicMutationResult;
  readonly UpdateUserSegmentsDisplayOrder: BasicMutationResult;
  readonly UpdateWebsiteAdminFetchingSettings: BasicMutationResult;
  readonly UpdateWebsiteAuditingSettings: BasicMutationResult;
  readonly UpdateWebsiteIndexNowContentChangeTriggers: BasicMutationResult;
  readonly UpdateWebsiteIndexNowCustomElementTriggers: BasicMutationResult;
  readonly UpdateWebsiteIndexNowIssueConditions: BasicMutationResult;
  readonly UpdateWebsiteIndexNowPageStatusChangeTriggers: BasicMutationResult;
  readonly UpdateWebsiteIndexNowSegmentConditions: BasicMutationResult;
  readonly UpdateWebsiteIndexNowStatus: BasicMutationResult;
  readonly UpdateWebsiteIndexNowUrlBlocklist: BasicMutationResult;
  readonly UpdateWebsiteIntegrations: BasicMutationResult;
  readonly UpdateWebsiteLogFileAnalysisAwsAccountId: BasicMutationResult;
  readonly UpdateWebsiteLogFileAnalysisSettings: BasicMutationResult;
  readonly UpdateWebsiteMonitoringCookies: BasicMutationResult;
  readonly UpdateWebsiteMonitoringHttpHeaders: BasicMutationResult;
  readonly UpdateWebsiteMonitoringSettings: BasicMutationResult;
  readonly UpdateWebsiteName: BasicMutationResult;
  readonly UpdateWebsiteOnPageRequestsBlockingRuleset: BasicMutationResult;
  readonly UpdateWebsiteOnPageRequestsWhitelist: BasicMutationResult;
  readonly UpdateWebsitePageCapacity: BasicMutationResult;
  readonly UpdateWebsiteRenderingSettings: BasicMutationResult;
  readonly ValidateConductorOrganizationConnection: ValidateConductorOrganizationConnectionResult;
  readonly ValidateEmail: BasicMutationResult;
  readonly ValidateSchemaOrg: ValidateSchemaOrgResult;
};


export type MutationAcceptAccessToClientArgs = {
  agencyId: Scalars['AccountId']['input'];
  clientId: Scalars['AccountId']['input'];
};


export type MutationAcceptEnrichmentApiTermsOfUseArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationAcceptInvitationWithTwoFactorAuthenticationSetupArgs = {
  code: Scalars['String']['input'];
};


export type MutationAcceptLegalAgreementArgs = {
  accountId: Scalars['AccountId']['input'];
  documentName: Scalars['String']['input'];
};


export type MutationAcceptReportingApiTermsOfUseArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationActivatePremiumTrialArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationAddConductorCustomerDomainsArgs = {
  domains: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationAddCustomerWebsiteArgs = {
  acceptedLegalDocuments: InputMaybe<ReadonlyArray<LegalDocumentType>>;
  accountId: Scalars['AccountId']['input'];
  domain: Scalars['String']['input'];
  fetchingLocation: InputMaybe<FetchingLocation>;
  httpAuthCredentials: InputMaybe<HttpAuthCredentials>;
  pageCapacity: Scalars['Int']['input'];
  skipValidation: Scalars['Boolean']['input'];
  threeDSecureToken: InputMaybe<Scalars['String']['input']>;
  userAgentSettings: InputMaybe<UserAgentSettingsInput>;
};


export type MutationAddCustomerWebsitesInBulkArgs = {
  acceptedLegalDocuments: InputMaybe<ReadonlyArray<LegalDocumentType>>;
  accountId: Scalars['AccountId']['input'];
  domains: ReadonlyArray<DomainAddedInBulk>;
  pageBundle: InputMaybe<Scalars['Int']['input']>;
  sessionId: Scalars['String']['input'];
  skipValidation: InputMaybe<Scalars['Boolean']['input']>;
  threeDSecureToken: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddEndUserWebsiteArgs = {
  acceptedLegalDocuments: InputMaybe<ReadonlyArray<LegalDocumentType>>;
  accountId: Scalars['AccountId']['input'];
  domain: Scalars['String']['input'];
  fetchingLocation: InputMaybe<FetchingLocation>;
  httpAuthCredentials: InputMaybe<HttpAuthCredentials>;
  pageCapacity: Scalars['Int']['input'];
  skipValidation: Scalars['Boolean']['input'];
  threeDSecureToken: InputMaybe<Scalars['String']['input']>;
  userAgentSettings: InputMaybe<UserAgentSettingsInput>;
};


export type MutationAddFreeEmailDomainsArgs = {
  emailDomains: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationAddGuaranteedBlacklistDomainsArgs = {
  domains: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationAddOverridableBlacklistDomainsArgs = {
  domains: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationAddTrialAbuserEmailsArgs = {
  emailPatterns: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationAddTrialWebsiteArgs = {
  acceptedLegalDocuments: InputMaybe<ReadonlyArray<LegalDocumentType>>;
  accountId: Scalars['AccountId']['input'];
  domain: Scalars['String']['input'];
  fetchingLocation: InputMaybe<FetchingLocation>;
  httpAuthCredentials: InputMaybe<HttpAuthCredentials>;
  pageCapacity: Scalars['Int']['input'];
  skipValidation: Scalars['Boolean']['input'];
  userAgentSettings: InputMaybe<UserAgentSettingsInput>;
};


export type MutationAddTrialWebsitesInBulkArgs = {
  acceptedLegalDocuments: InputMaybe<ReadonlyArray<LegalDocumentType>>;
  accountId: Scalars['AccountId']['input'];
  domains: ReadonlyArray<DomainAddedInBulk>;
  sessionId: Scalars['String']['input'];
  skipValidation: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationAdministerAccountAddonsArgs = {
  accountId: Scalars['AccountId']['input'];
  addons: ReadonlyArray<AddonAmountInput>;
};


export type MutationAdministerAccountCertifiedPartnerProgramSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  partnerAffiliateId: InputMaybe<Scalars['String']['input']>;
};


export type MutationAdministerAccountEmailSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  areIntercomAutomaticMessagesForSalesEnabled: Scalars['Boolean']['input'];
  areIntercomAutomaticMessagesForTrialRevivalEnabled: Scalars['Boolean']['input'];
  areIntercomAutomaticMessagesForWelcomeEnabled: Scalars['Boolean']['input'];
};


export type MutationAdministerAccountFeaturesArgs = {
  accountId: Scalars['AccountId']['input'];
  features: Scalars['Array']['input'];
};


export type MutationAdministerAccountInvoiceSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  isDeliveryAddressEnabled: Scalars['Boolean']['input'];
  isExtraInvoiceDetailsEnabled: Scalars['Boolean']['input'];
};


export type MutationAdministerAccountMainSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  accountTypeChange: AdministerAccountMainSettingsAccountTypeChange;
  canCreateConnectedClientsWithoutUsers: InputMaybe<Scalars['Boolean']['input']>;
  canHaveMultipleWebsiteCopies: Scalars['Boolean']['input'];
  canModifyConnectionDirectionDuringTrial: InputMaybe<Scalars['Boolean']['input']>;
  customerType: InputMaybe<CustomerType>;
  isManaged: Scalars['Boolean']['input'];
  isOutOfBand: InputMaybe<Scalars['Boolean']['input']>;
  maximumAllowedConnectedTrialAccounts: InputMaybe<Scalars['Int']['input']>;
  maximumInvitations: Scalars['Int']['input'];
  maximumPageBundle: InputMaybe<Scalars['Int']['input']>;
  maximumPageCapacity: Scalars['Int']['input'];
  maximumSignupPageBundle: InputMaybe<Scalars['Int']['input']>;
  maximumUnverifiedWebsites: InputMaybe<Scalars['Int']['input']>;
  maximumWebsites: InputMaybe<Scalars['Int']['input']>;
  referringPartnerId: InputMaybe<Scalars['String']['input']>;
};


export type MutationAdministerAccountSalesSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  areSalesHandledExternally: Scalars['Boolean']['input'];
  bookDemoOffering: InputMaybe<AccountAdminBookDemoOffering>;
  isImportant: Scalars['Boolean']['input'];
  isInteresting: InputMaybe<Scalars['Boolean']['input']>;
  isSignupOptimized: Scalars['Boolean']['input'];
  isTrialBarDisplayed: InputMaybe<Scalars['Boolean']['input']>;
  logoSignificance: AccountAdminLogoSignificance;
};


export type MutationAdministerAccountTransactionSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  allowedBillingCycles: ReadonlyArray<Term>;
  allowedCurrencies: ReadonlyArray<Currency>;
  allowedPaymentMethods: ReadonlyArray<Scalars['String']['input']>;
  allowedPlans: ReadonlyArray<AccountPlan>;
  customDiscount: Scalars['String']['input'];
  fixedBillingEntity: InputMaybe<Scalars['String']['input']>;
  isRenewalEnabled: Scalars['Boolean']['input'];
  manualPaymentNetTerms: InputMaybe<Scalars['Int']['input']>;
};


export type MutationAdministerInvoiceDetailsArgs = {
  accountId: Scalars['AccountId']['input'];
  attn: InputMaybe<Scalars['String']['input']>;
  invoiceNumber: Scalars['String']['input'];
  purchaseOrderNumber: InputMaybe<Scalars['String']['input']>;
};


export type MutationAdministerUserSettingsArgs = {
  isAlertRecipientByDefault: Scalars['Boolean']['input'];
  isReportRecipientByDefault: Scalars['Boolean']['input'];
  legacyUserId: Scalars['ID']['input'];
  mainAccountId: Scalars['AccountId']['input'];
  newEmail: InputMaybe<Scalars['String']['input']>;
  organizationTeam: InputMaybe<Scalars['String']['input']>;
  platformTourWebinarOffering: InputMaybe<PlatformTourWebinarOffering>;
  reviewSites: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationAdministerWebsiteSettingsArgs = {
  analyticsServicesDetectionSource: InputMaybe<Scalars['String']['input']>;
  areOtherSearchEnginesNotRelevant: InputMaybe<Scalars['Boolean']['input']>;
  collectDetailedDiagnostics: InputMaybe<Scalars['Boolean']['input']>;
  detectedContentDeliveryNetwork: InputMaybe<DetectedContentDeliveryNetwork>;
  discoverAmpPages: InputMaybe<Scalars['Boolean']['input']>;
  fetchingLogLevel: InputMaybe<Scalars['String']['input']>;
  isBlacklistDisabled: InputMaybe<Scalars['Boolean']['input']>;
  isDiscoveringNewUrlsEnabled: InputMaybe<Scalars['Boolean']['input']>;
  isShadowDomFlattened: InputMaybe<Scalars['Boolean']['input']>;
  pagePropertiesWithDisabledHistoricalTracking: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  verification: InputMaybe<Scalars['String']['input']>;
  webPropertyGroupId: InputMaybe<Scalars['Int']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationBookDemoArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationBulkCreateAlertDefinitionsArgs = {
  alertDefinitions: Scalars['Array']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationBulkDeleteAlertDefinitionsArgs = {
  alertDefinitionIds: ReadonlyArray<Scalars['AlertDefinitionId']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationBulkUpdateAlertDefinitionsMessagingAppChannelsArgs = {
  alertDefinitionIds: ReadonlyArray<Scalars['AlertDefinitionId']['input']>;
  messagingAppChannelIds: ReadonlyArray<Scalars['ID']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationBulkUpdateAlertDefinitionsRecipientsArgs = {
  addedRecipients: ReadonlyArray<Scalars['String']['input']>;
  alertDefinitionIds: ReadonlyArray<Scalars['AlertDefinitionId']['input']>;
  removedRecipients: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationBulkUpdateAlertDefinitionsScopeArgs = {
  alertDefinitionIds: ReadonlyArray<Scalars['AlertDefinitionId']['input']>;
  scope: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationBulkUpdateAlertDefinitionsSensitivityLevelArgs = {
  alertDefinitionIds: ReadonlyArray<Scalars['AlertDefinitionId']['input']>;
  sensitivityLevel: AlertSensitivityLevel;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationCancelUserInvitationArgs = {
  accountId: Scalars['AccountId']['input'];
  email: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  legacyUserId: Scalars['ID']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationChangeUserRoleArgs = {
  accountId: InputMaybe<Scalars['AccountId']['input']>;
  assignedWebsites: InputMaybe<ReadonlyArray<Scalars['WebsiteId']['input']>>;
  limitedWebsitesAccess: InputMaybe<Scalars['Boolean']['input']>;
  role: UserRole;
  userId: Scalars['ID']['input'];
};


export type MutationCheckPageWithUrlArgs = {
  url: Scalars['String']['input'];
};


export type MutationChooseCustomerTypeArgs = {
  accountId: Scalars['AccountId']['input'];
  customerType: CustomerType;
};


export type MutationCleanCustomerAccountArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationCleanupOrphanUrlsArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationCompleteTwoFactorAuthenticationSetupArgs = {
  code: Scalars['String']['input'];
};


export type MutationCompleteValidateDomainsSessionArgs = {
  accountId: Scalars['AccountId']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationCompleteValidateInvitationsSessionArgs = {
  accountId: Scalars['AccountId']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationConnectAdobeAnalyticsAccountArgs = {
  accountId: Scalars['AccountId']['input'];
  adobeAnalyticsClientId: Scalars['String']['input'];
  adobeAnalyticsClientSecret: Scalars['String']['input'];
  adobeAnalyticsOrganizationId: Scalars['String']['input'];
  adobeAnalyticsPrivateKey: Scalars['String']['input'];
  adobeAnalyticsTechnicalAccountId: Scalars['String']['input'];
  ownerAccountId: InputMaybe<Scalars['AccountId']['input']>;
};


export type MutationConnectAdobeAnalyticsAccountToWebsiteArgs = {
  adobeAnalyticsAccountId: Scalars['Int']['input'];
  dataMatchingMethod: AdobeAnalyticsDataMatchingMethod;
  dimensionId: InputMaybe<Scalars['String']['input']>;
  reportSuiteId: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationConnectConductorOrganizationArgs = {
  accountId: Scalars['AccountId']['input'];
  conductorAccountId: InputMaybe<Scalars['Int']['input']>;
  conductorOrganizationId: InputMaybe<Scalars['String']['input']>;
  sendWelcomeEmails: Scalars['Boolean']['input'];
};


export type MutationConnectGoogleAnalyticsAccountArgs = {
  authCode: Scalars['String']['input'];
  ownerAccountId: InputMaybe<Scalars['AccountId']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationConnectGoogleAnalyticsAccountToWebsiteArgs = {
  viewId: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationConnectGoogleAnalyticsV4AccountToWebsiteArgs = {
  propertyId: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationConnectGoogleSearchConsoleAccountArgs = {
  authCode: Scalars['String']['input'];
  ownerAccountId: Scalars['AccountId']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationConnectSlackAccountArgs = {
  accountId: Scalars['AccountId']['input'];
  authCode: Scalars['String']['input'];
  slackRedirectUrl: Scalars['String']['input'];
};


export type MutationCopyCustomElementToWebsitesArgs = {
  sourceCustomElementName: Scalars['String']['input'];
  sourceWebsiteId: Scalars['WebsiteId']['input'];
  targetWebsiteIds: ReadonlyArray<Scalars['WebsiteId']['input']>;
};


export type MutationCopySegmentToWebsitesArgs = {
  sourceSegmentId: Scalars['PageSegmentId']['input'];
  sourceWebsiteId: Scalars['WebsiteId']['input'];
  targetWebsiteIds: ReadonlyArray<Scalars['WebsiteId']['input']>;
};


export type MutationCreateConnectedClientArgs = {
  acceptedLegalDocuments: InputMaybe<ReadonlyArray<LegalDocumentType>>;
  agencyId: Scalars['AccountId']['input'];
  clientAccountName: InputMaybe<Scalars['String']['input']>;
  clientContactEmail: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  fetchingLocation: InputMaybe<FetchingLocation>;
};


export type MutationCreateCustomElementArgs = {
  extraction: Scalars['Array']['input'];
  label: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationCreateCustomElementOnWebsitesArgs = {
  accountId: Scalars['AccountId']['input'];
  extraction: Scalars['Array']['input'];
  label: Scalars['String']['input'];
  websiteIds: ReadonlyArray<Scalars['WebsiteId']['input']>;
};


export type MutationCreateEnrichmentFieldArgs = {
  dataType: EnrichmentFieldDataType;
  label: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationCreateExportArgs = {
  criteria: CreateExportCriteria;
  type: ExportType;
  url: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationCreateLogFileAnalysisS3BucketArgs = {
  logSource: LogSourceProvider;
  region: LogFileAnalysisS3BucketRegion;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationCreatePasswordDuringOnboardingArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCreatePersonalColumnSetArgs = {
  columns: ReadonlyArray<ColumnSetColumnInput>;
  name: Scalars['String']['input'];
};


export type MutationCreateSegmentArgs = {
  color: Scalars['String']['input'];
  filterDefinition: Scalars['FilterDefinition']['input'];
  iconName: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  shortcode: InputMaybe<Scalars['String']['input']>;
  sizeLimit: InputMaybe<Scalars['PageSegmentSizeLimit']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationCreateSharedColumnSetArgs = {
  accountId: Scalars['AccountId']['input'];
  columns: ReadonlyArray<ColumnSetColumnInput>;
  name: Scalars['String']['input'];
};


export type MutationCreateStaticSegmentArgs = {
  color: Scalars['String']['input'];
  iconName: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  shortcode: InputMaybe<Scalars['String']['input']>;
  urls: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationCreateTrialAccountArgs = {
  campaignSource: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  email: Scalars['String']['input'];
  googleAnalyticsClientId: InputMaybe<Scalars['String']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateTrialAccountFromConductorArgs = {
  conductorOrganizationId: Scalars['String']['input'];
  force: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDeleteAccountArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationDeleteColumnSetArgs = {
  id: Scalars['ColumnSetId']['input'];
};


export type MutationDeleteCustomElementArgs = {
  name: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationDeleteCustomUrlsArgs = {
  urls: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationDeleteEnrichmentFieldArgs = {
  id: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationDeleteIncorrectLinksArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationDeleteMessagingAppChannelsArgs = {
  accountId: Scalars['AccountId']['input'];
  messagingAppChannelIds: ReadonlyArray<Scalars['ID']['input']>;
};


export type MutationDeleteSegmentArgs = {
  segmentId: Scalars['PageSegmentId']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationDeleteUrlsByFilterArgs = {
  cleanupRobotsTxtRevisions: Scalars['Boolean']['input'];
  filter: Scalars['Array']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationDeleteWebsiteArgs = {
  accountId: Scalars['AccountId']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationDisableAdobeAnalyticsForWebsiteArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationDisableGoogleAnalyticsForWebsiteArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationDisableSubscriptionRenewalArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationDisconnectConductorOrganizationArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationEditCustomElementArgs = {
  extraction: Scalars['Array']['input'];
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  restartTracking: Scalars['Boolean']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationEditEnrichmentFieldArgs = {
  dataType: EnrichmentFieldDataType;
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationEnterSudoModeUsingPasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationEnterSudoModeUsingTwoFactorAuthenticationArgs = {
  code: Scalars['String']['input'];
};


export type MutationExportFetchingSnapshotArgs = {
  timeReceivedAt: Scalars['Timestamp']['input'];
  url: InputMaybe<Scalars['String']['input']>;
  urlId: InputMaybe<Scalars['UrlId']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationExportRobotsTxtRevisionArgs = {
  revisionId: Scalars['String']['input'];
  url: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationExtendTrialArgs = {
  accountId: Scalars['AccountId']['input'];
  feedback: Scalars['String']['input'];
};


export type MutationFinishLoginWithTwoFactorAuthenticationArgs = {
  code: Scalars['String']['input'];
};


export type MutationFinishUnpaidSignupArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationGrantAgencyAccessArgs = {
  agencyId: Scalars['AccountId']['input'];
  clientId: Scalars['AccountId']['input'];
};


export type MutationIgnoreIssueCategoryOnWholeWebsiteArgs = {
  issueCategory: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationIgnorePageIssueCasesOnWholeWebsiteArgs = {
  cases: ReadonlyArray<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationIgnorePageIssueCategoryOnSegmentsArgs = {
  issueCategory: Scalars['String']['input'];
  segments: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationIgnorePageIssueCategoryOnSpecificPagesArgs = {
  issueCategory: Scalars['String']['input'];
  urls: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationIgnorePageIssueOnSegmentsArgs = {
  segments: ReadonlyArray<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationIgnorePageIssueOnSpecificPagesArgs = {
  type: Scalars['String']['input'];
  urls: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationIgnorePageIssueOnWholeWebsiteArgs = {
  type: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationIgnorePlatformIssueArgs = {
  type: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationImpersonateUserArgs = {
  enableSearch: Scalars['Boolean']['input'];
  identifier: Scalars['String']['input'];
};


export type MutationImportCustomUrlsArgs = {
  urlLines: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationImportSegmentsArgs = {
  changeCriteriaInstructions: ReadonlyArray<ImportSegmentsChangeCriteriaInstructionInput>;
  changeIdentifierInstructions: ReadonlyArray<ImportSegmentsChangeIdentifierInstructionInput>;
  createInstructions: ReadonlyArray<ImportSegmentsCreateInstructionInput>;
  sourceWebsiteId: Scalars['WebsiteId']['input'];
  targetWebsiteId: Scalars['WebsiteId']['input'];
};


export type MutationImportUserArgs = {
  changeMainAccount: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  sourceAccountId: Scalars['AccountId']['input'];
  targetAccountId: Scalars['AccountId']['input'];
};


export type MutationImportWebsiteArgs = {
  sourceAccountId: Scalars['AccountId']['input'];
  targetAccountId: Scalars['AccountId']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationInitiateLoginWithEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationInitiateResetPasswordWithTwoFactorAuthenticationSetupArgs = {
  code: Scalars['String']['input'];
};


export type MutationInitiateSepaDirectDebitSetupArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationInitiateValidateDomainsSessionArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationInitiateValidateInvitationsSessionArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationInstallCloudflareWorkerArgs = {
  cloudflareApiToken: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationInviteNewUserArgs = {
  accountId: Scalars['AccountId']['input'];
  assignedWebsites: InputMaybe<ReadonlyArray<Scalars['WebsiteId']['input']>>;
  email: Scalars['String']['input'];
  limitedWebsitesAccess: InputMaybe<Scalars['Boolean']['input']>;
  role: UserRole;
};


export type MutationInviteNewUsersInBulkArgs = {
  accountId: Scalars['AccountId']['input'];
  invitations: ReadonlyArray<InputMaybe<InviteNewUsersInBulkInvitationInput>>;
  sessionId: Scalars['String']['input'];
};


export type MutationLoginWithCredentialsArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginWithGoogleArgs = {
  idToken: Scalars['String']['input'];
};


export type MutationManageRoyalAccessArgs = {
  legacyUserId: Scalars['ID']['input'];
  royalGroup: RoyalGroup;
};


export type MutationManageWebsiteSuperConductorConfigurationArgs = {
  attachedWebPropertyGroupIds: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationMarkAccountAsOutOfBandArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationModifyAccountAccessConnectionDirectionArgs = {
  accountId: Scalars['AccountId']['input'];
  connectionDirection: AccountAccessConnectionDirection;
};


export type MutationOfferAgencyAccessArgs = {
  agencyContactEmail: Scalars['String']['input'];
  clientId: Scalars['AccountId']['input'];
  level: AccountAccessConnectionLevel;
};


export type MutationPrioritizeExternalUrlsArgs = {
  urls: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationPrioritizePageArgs = {
  urlId: Scalars['Int']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationPrioritizeUrlsByFilterArgs = {
  filter: Scalars['Array']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationProvideFeedbackArgs = {
  explanation: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  verdict: Scalars['Boolean']['input'];
};


export type MutationPurgePagesHistoryByFilterArgs = {
  purgedProperties: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationRefreshEnrichmentApiTokenArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationRefreshPaymentStatusArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationRefreshReportingApiTokenArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationRegisterMicrosoftTeamsIncomingWebhookArgs = {
  accountId: Scalars['AccountId']['input'];
  incomingWebhookUrl: Scalars['String']['input'];
  label: Scalars['String']['input'];
};


export type MutationRememberDisplayedInterfaceHintsArgs = {
  email: Scalars['String']['input'];
  interfaceHints: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationRemoveAccessToClientArgs = {
  agencyId: Scalars['AccountId']['input'];
  clientId: Scalars['AccountId']['input'];
};


export type MutationRemoveTwoFactorAuthenticationArgs = {
  code: Scalars['String']['input'];
};


export type MutationRemoveUnclickablePagesArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationRemoveUserArgs = {
  accountId: Scalars['AccountId']['input'];
  email: Scalars['String']['input'];
};


export type MutationRequestAccessToClientArgs = {
  agencyId: Scalars['AccountId']['input'];
  clientContactEmail: Scalars['String']['input'];
  level: AccountAccessConnectionLevel;
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetEnrichmentFieldArgs = {
  id: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationResumeSubscriptionRenewalArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationRetryFailedJobsArgs = {
  jobQueue: Scalars['String']['input'];
};


export type MutationReviveTrialByAddingWebsiteArgs = {
  acceptedLegalDocuments: InputMaybe<ReadonlyArray<LegalDocumentType>>;
  accountId: Scalars['AccountId']['input'];
  domain: Scalars['String']['input'];
  fetchingLocation: InputMaybe<FetchingLocation>;
  httpAuthCredentials: InputMaybe<HttpAuthCredentials>;
  pageCapacity: Scalars['Int']['input'];
  skipValidation: Scalars['Boolean']['input'];
  userAgentSettings: InputMaybe<UserAgentSettingsInput>;
};


export type MutationRevokeAgencyAccessArgs = {
  agencyId: Scalars['AccountId']['input'];
  clientId: Scalars['AccountId']['input'];
};


export type MutationSetNameDuringOnboardingArgs = {
  firstName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetupHttpAuthenticationArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationSetupIndexNowKeyArgs = {
  indexNowKey: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationShareColumnSetArgs = {
  accountId: Scalars['AccountId']['input'];
  id: Scalars['ColumnSetId']['input'];
};


export type MutationSignupArgs = {
  acceptedLegalDocuments: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  accountId: Scalars['AccountId']['input'];
  addons: InputMaybe<ReadonlyArray<AddonAmountInput>>;
  billingCycle: Term;
  currency: Currency;
  pageBundle: Scalars['Int']['input'];
  plan: AccountPlan;
};


export type MutationStageDomainsForValidationArgs = {
  accountId: Scalars['AccountId']['input'];
  domains: ReadonlyArray<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
};


export type MutationStageDomainsForValidationFromFreeInputArgs = {
  accountId: Scalars['AccountId']['input'];
  bulkDomainsFreeInput: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationStageEmailsForValidationFromFreeInputArgs = {
  accountId: Scalars['AccountId']['input'];
  bulkEmailsFreeInput: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationStoreCustomerDetailsArgs = {
  accountId: Scalars['AccountId']['input'];
  customerDetails: Scalars['Array']['input'];
};


export type MutationStorePaymentMethodArgs = {
  accountId: Scalars['AccountId']['input'];
  paymentMethod: Scalars['Array']['input'];
};


export type MutationTestCustomElementExtractionArgs = {
  extraction: Scalars['Array']['input'];
  url: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUnignoreIssueCategoryOnWholeWebsiteArgs = {
  issueCategory: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUnignorePageIssueCasesOnWholeWebsiteArgs = {
  cases: ReadonlyArray<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUnignorePageIssueCategoryOnSegmentsArgs = {
  issueCategory: Scalars['String']['input'];
  segments: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUnignorePageIssueCategoryOnSpecificPagesArgs = {
  issueCategory: Scalars['String']['input'];
  urls: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUnignorePageIssueOnSegmentsArgs = {
  segments: ReadonlyArray<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUnignorePageIssueOnSpecificPagesArgs = {
  type: Scalars['String']['input'];
  urls: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUnignorePageIssueOnWholeWebsiteArgs = {
  type: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUnignorePlatformIssueArgs = {
  type: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUnsignupArgs = {
  accountId: Scalars['AccountId']['input'];
};


export type MutationUnstageDomainsFromValidationSessionArgs = {
  accountId: Scalars['AccountId']['input'];
  domains: ReadonlyArray<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
};


export type MutationUnstageEmailsFromValidationSessionArgs = {
  accountId: Scalars['AccountId']['input'];
  emails: ReadonlyArray<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
};


export type MutationUpdateAccountAdminCustomerPhaseSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  daysOfNonRenewalGracePeriod: Scalars['Int']['input'];
  daysOfNonRenewalSuspension: Scalars['Int']['input'];
  daysOfPaymentFailureGracePeriod: Scalars['Int']['input'];
  daysOfPaymentFailureNoticeToAllUsers: Scalars['Int']['input'];
  daysOfPaymentFailureNoticeToBillingManagers: Scalars['Int']['input'];
  daysOfPaymentFailureSuspension: Scalars['Int']['input'];
  daysToPremiumTrialRevival: Scalars['Int']['input'];
  isPremiumTrialRevivalAllowedByCustomerTeam: Scalars['Boolean']['input'];
  premiumTrialExpirationDate: InputMaybe<Scalars['Timestamp']['input']>;
};


export type MutationUpdateAccountAdminDefaultWebsiteSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  discoverAmpPagesByDefault: Scalars['Boolean']['input'];
  trackH2ToH6HistoricallyByDefault: Scalars['Boolean']['input'];
  trackNumericOnlyChangesByDefault: Scalars['Boolean']['input'];
};


export type MutationUpdateAccountAdminPreverifiedDomainsArgs = {
  accountId: Scalars['AccountId']['input'];
  preverifiedDomains: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationUpdateAccountAdminTrialPhaseSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  daysToDataCleanup: Scalars['Int']['input'];
  daysToTrialRevival: InputMaybe<Scalars['Int']['input']>;
  isTrialExtensionAllowedByCustomerTeam: BooleanOrAutomatic;
  isTrialRevivalAllowedByCustomerTeam: Scalars['Boolean']['input'];
  trialExpirationDate: Scalars['Timestamp']['input'];
};


export type MutationUpdateAccountMonitoringSettingsArgs = {
  accountId: Scalars['AccountId']['input'];
  fetchingLocation: FetchingLocation;
  userAgentSettings: UserAgentSettingsInput;
};


export type MutationUpdateAccountProfileArgs = {
  accountId: Scalars['AccountId']['input'];
  name: Scalars['String']['input'];
  removeCustomLogo: InputMaybe<Scalars['Boolean']['input']>;
  setCustomLogo: InputMaybe<AccountCustomLogoInput>;
};


export type MutationUpdateAdvancedWebsiteMonitoringSettingsArgs = {
  fetchingLocation: FetchingLocation;
  httpAuth: InputMaybe<Scalars['Boolean']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateAlertDefinitionArgs = {
  alertDefinitionId: Scalars['AlertDefinitionId']['input'];
  data: Scalars['Array']['input'];
  messagingAppChannelIds: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  recipients: Scalars['Array']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateColumnSetArgs = {
  columns: ReadonlyArray<ColumnSetColumnInput>;
  id: Scalars['ColumnSetId']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateEndUserPlanArgs = {
  accountId: Scalars['AccountId']['input'];
  billingCycle: Term;
  endUserPlan: Scalars['String']['input'];
  threeDSecureToken: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateLighthouseThresholdsArgs = {
  lighthouseThresholds: Scalars['Array']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateLimitedWebsitesAccessOfWebsiteArgs = {
  addedEmailMemberships: ReadonlyArray<AccountMembershipEmailInput>;
  addedInvitationIds: ReadonlyArray<Scalars['String']['input']>;
  removedEmailMemberships: ReadonlyArray<AccountMembershipEmailInput>;
  removedInvitationIds: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateNumberOfEmployeesArgs = {
  accountId: Scalars['AccountId']['input'];
  isNumberOfEmployeesEnoughForOutOfBand: Scalars['Boolean']['input'];
};


export type MutationUpdateOrganizationAccessControlArgs = {
  accountId: Scalars['AccountId']['input'];
  domainsWhitelist: Scalars['String']['input'];
  emailDomainsWhitelist: Scalars['String']['input'];
  isTwoFactorAuthenticationEnforced: Scalars['Boolean']['input'];
  managersCanAffectSubscription: Scalars['Boolean']['input'];
  managersCanControlAgencyAccess: Scalars['Boolean']['input'];
  managersCanSeeApiTokens: Scalars['Boolean']['input'];
};


export type MutationUpdatePageIssuesConfigurationArgs = {
  issueCategory: Scalars['String']['input'];
  issueCategoryConfiguration: Scalars['Array']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdatePagesEnteredOrLeftSegmentAlertDefinitionArgs = {
  alertDefinitionId: Scalars['AlertDefinitionId']['input'];
  data: InputMaybe<Scalars['Array']['input']>;
  messagingAppChannelIds: ReadonlyArray<Scalars['ID']['input']>;
  recipients: Scalars['Array']['input'];
  segmentChangeSizeNumberOfPages: InputMaybe<Scalars['Int']['input']>;
  segmentChangeSizePercentage: InputMaybe<Scalars['Percentage']['input']>;
  segmentChangeType: Scalars['String']['input'];
  segmentName: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateSegmentArgs = {
  color: Scalars['String']['input'];
  filterDefinition: Scalars['FilterDefinition']['input'];
  iconName: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  segmentId: Scalars['PageSegmentId']['input'];
  shortcode: InputMaybe<Scalars['String']['input']>;
  sizeLimit: InputMaybe<Scalars['PageSegmentSizeLimit']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateSendingOfAlertsAndReportsFromWebsiteArgs = {
  invitations: ReadonlyArray<UpdateSendingOfAlertsAndReportsFromWebsiteInvitation>;
  users: ReadonlyArray<UpdateSendingOfAlertsAndReportsFromWebsiteUser>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateSendingOfAlertsAndReportsFromWebsitesArgs = {
  accountId: Scalars['AccountId']['input'];
  domains: ReadonlyArray<Scalars['String']['input']>;
  invitations: ReadonlyArray<UpdateSendingOfAlertsAndReportsFromWebsiteInvitation>;
  users: ReadonlyArray<UpdateSendingOfAlertsAndReportsFromWebsiteUser>;
};


export type MutationUpdateSendingOfAlertsAndReportsToInvitedUserArgs = {
  invitationId: Scalars['InvitationId']['input'];
  websites: ReadonlyArray<UpdateSendingOfAlertsAndReportsToInvitedUserWebsite>;
};


export type MutationUpdateSendingOfAlertsAndReportsToUserArgs = {
  legacyUserId: Scalars['ID']['input'];
  websites: ReadonlyArray<UpdateSendingOfAlertsAndReportsToUserWebsite>;
};


export type MutationUpdateStaticSegmentArgs = {
  color: Scalars['String']['input'];
  iconName: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  segmentId: Scalars['PageSegmentId']['input'];
  shortcode: InputMaybe<Scalars['String']['input']>;
  urls: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateSubscriptionArgs = {
  accountId: Scalars['AccountId']['input'];
  billingCycle: Term;
  pageBundle: Scalars['Int']['input'];
  plan: AccountPlan;
  tariff: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUrlExclusionListArgs = {
  rules: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateUserColumnWidthArgs = {
  columnName: Scalars['String']['input'];
  columnWidth: Scalars['Int']['input'];
  email: Scalars['String']['input'];
};


export type MutationUpdateUserHiddenDashboardScopesArgs = {
  email: Scalars['String']['input'];
  hiddenDashboardScopes: ReadonlyArray<Scalars['Array']['input']>;
};


export type MutationUpdateUserMarketingEmailsPreferencesArgs = {
  isSubscribedToFeatureAnnouncementEmails: Scalars['Boolean']['input'];
  isSubscribedToNewsletterEmails: Scalars['Boolean']['input'];
  isSubscribedToOnboardingEmails: Scalars['Boolean']['input'];
  isSubscribedToServiceMessageEmails: Scalars['Boolean']['input'];
  legacyUserId: Scalars['ID']['input'];
};


export type MutationUpdateUserPageDetailSourceModeArgs = {
  email: Scalars['String']['input'];
  mode: Scalars['String']['input'];
};


export type MutationUpdateUserProfileArgs = {
  firstName: InputMaybe<Scalars['String']['input']>;
  jobTitle: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  legacyUserId: Scalars['ID']['input'];
};


export type MutationUpdateUserSegmentsDisplayOrderArgs = {
  email: Scalars['String']['input'];
  segmentsDisplayOrder: ReadonlyArray<SegmentIdentifierInput>;
};


export type MutationUpdateWebsiteAdminFetchingSettingsArgs = {
  domSizeLimit: InputMaybe<Scalars['Int']['input']>;
  domStabilizationPeriod: InputMaybe<Scalars['Int']['input']>;
  ipPools: InputMaybe<ReadonlyArray<FetchingIpPool>>;
  lighthouseChromiumResourceCacheSizeLimit: InputMaybe<Scalars['Int']['input']>;
  lighthouseFcpTimeout: InputMaybe<Scalars['Int']['input']>;
  mainRequestSizeLimit: InputMaybe<Scalars['Int']['input']>;
  mainRequestTimeout: InputMaybe<Scalars['Int']['input']>;
  onPageRequestsLimitLighthousePerDomain: InputMaybe<Scalars['Int']['input']>;
  onPageRequestsLimitLighthouseTotal: InputMaybe<Scalars['Int']['input']>;
  onPageRequestsLimitNormalPerDomain: InputMaybe<Scalars['Int']['input']>;
  onPageRequestsLimitNormalTotal: InputMaybe<Scalars['Int']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteAuditingSettingsArgs = {
  relevantSearchEngines: ReadonlyArray<Scalars['String']['input']>;
  trackH2ToH6Historically: Scalars['Boolean']['input'];
  trackNumericOnlyChanges: Scalars['Boolean']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteIndexNowContentChangeTriggersArgs = {
  contentChangeTriggers: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteIndexNowCustomElementTriggersArgs = {
  customElementNames: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteIndexNowIssueConditionsArgs = {
  selectedIssues: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteIndexNowPageStatusChangeTriggersArgs = {
  pageStatusChangeTriggers: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteIndexNowSegmentConditionsArgs = {
  mode: IndexNowSegmentsBlocklistMode;
  selectedSegments: ReadonlyArray<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteIndexNowStatusArgs = {
  isIndexNowEnabled: Scalars['Boolean']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteIndexNowUrlBlocklistArgs = {
  mode: UrlBlocklistMode;
  rules: ReadonlyArray<UrlBlocklistRuleInput>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteIntegrationsArgs = {
  enableGoogleSearchConsole: Scalars['Boolean']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteLogFileAnalysisAwsAccountIdArgs = {
  awsAccountId: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteLogFileAnalysisSettingsArgs = {
  status: InputMaybe<Scalars['Boolean']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteMonitoringCookiesArgs = {
  cookies: ReadonlyArray<UpdateWebsiteMonitoringCookiesCookie>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteMonitoringHttpHeadersArgs = {
  httpHeaders: ReadonlyArray<UpdateWebsiteMonitoringHttpHeadersHttpHeader>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteMonitoringSettingsArgs = {
  crawlingSpeed: Scalars['Array']['input'];
  fetchingLocation: InputMaybe<FetchingLocation>;
  httpAuth: InputMaybe<Scalars['Boolean']['input']>;
  renderingDeviceType: InputMaybe<Scalars['String']['input']>;
  userAgentType: Scalars['String']['input'];
  userAgentValue: InputMaybe<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteNameArgs = {
  name: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteOnPageRequestsBlockingRulesetArgs = {
  mode: OnPageRequestsBlockingMode;
  rules: ReadonlyArray<Scalars['Array']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteOnPageRequestsWhitelistArgs = {
  rules: ReadonlyArray<Scalars['Array']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsitePageCapacityArgs = {
  pageCapacity: Scalars['Int']['input'];
  threeDSecureToken: InputMaybe<Scalars['String']['input']>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationUpdateWebsiteRenderingSettingsArgs = {
  isDomTracked: Scalars['Boolean']['input'];
  isLighthouseMonitored: Scalars['Boolean']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type MutationValidateConductorOrganizationConnectionArgs = {
  accountId: Scalars['AccountId']['input'];
  conductorAccountId: Scalars['Int']['input'];
};


export type MutationValidateEmailArgs = {
  accountId: Scalars['AccountId']['input'];
  email: Scalars['String']['input'];
};


export type MutationValidateSchemaOrgArgs = {
  schemaOrgJsons: ReadonlyArray<Scalars['String']['input']>;
};

export type MutationResult = {
  readonly query: Query;
};

export type NonPagesConnection = Connection & {
  readonly __typename?: 'NonPagesConnection';
  readonly distributions: Scalars['Array']['output'];
  readonly edges: ReadonlyArray<Maybe<NonPagesConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type NonPagesConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'NonPagesConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly loadedAt: Scalars['Int']['output'];
  readonly node: NonPagesConnectionNode;
};

export type NonPagesConnectionNode = {
  readonly __typename?: 'NonPagesConnectionNode';
  readonly data: Scalars['Array']['output'];
  readonly id: Scalars['ID']['output'];
  readonly legacyId: Maybe<Scalars['Int']['output']>;
};

export type NullableContentItem = {
  readonly __typename?: 'NullableContentItem';
  readonly content: Maybe<Scalars['String']['output']>;
  readonly position: Scalars['Int']['output'];
};

export type OnPageProperties = {
  readonly __typename?: 'OnPageProperties';
  readonly h1: ContentItemsCollection;
  readonly h2: ContentItemsCollection;
  readonly h3: ContentItemsCollection;
  readonly h4: ContentItemsCollection;
  readonly h5: ContentItemsCollection;
  readonly h6: ContentItemsCollection;
  readonly invalidSchemaOrgJson: ReadonlyArray<Scalars['Int']['output']>;
  readonly linkAlternateHreflang: LinkRelItemsCollection;
  readonly linkAmp: LinkRelItemsCollection;
  readonly linkCanonical: LinkRelItemsCollection;
  readonly linkMobileVariant: LinkRelItemsCollection;
  readonly linkNext: LinkRelItemsCollection;
  readonly linkPrev: LinkRelItemsCollection;
  readonly metaBingbot: ContentItemsCollection;
  readonly metaDescription: ContentItemsCollection;
  readonly metaGooglebot: ContentItemsCollection;
  readonly metaRobots: ContentItemsCollection;
  readonly metaSlurp: ContentItemsCollection;
  readonly metaYandex: ContentItemsCollection;
  readonly openGraphDescription: ContentItemsCollection;
  readonly openGraphImage: ContentItemsCollection;
  readonly openGraphTitle: ContentItemsCollection;
  readonly openGraphType: ContentItemsCollection;
  readonly openGraphUrl: ContentItemsCollection;
  readonly schemaOrgJson: ReadonlyArray<Scalars['Any']['output']>;
  readonly title: ContentItemsCollection;
  readonly twitterCard: ContentItemsCollection;
  readonly twitterDescription: ContentItemsCollection;
  readonly twitterImage: ContentItemsCollection;
  readonly twitterSite: ContentItemsCollection;
  readonly twitterTitle: ContentItemsCollection;
};

export enum OnPageRequestsBlockingMode {
  AllowAllExcept = 'allowAllExcept',
  DenyAllExcept = 'denyAllExcept'
}

export type OnPageRequestsBlockingRule = {
  readonly __typename?: 'OnPageRequestsBlockingRule';
  readonly operator: OnPageRequestsBlockingRuleOperator;
  readonly value: Scalars['String']['output'];
};

export enum OnPageRequestsBlockingRuleOperator {
  Contains = 'contains',
  EndsWith = 'endsWith',
  Equals = 'equals',
  Matches = 'matches',
  NotContains = 'notContains',
  StartsWith = 'startsWith'
}

export type Page = {
  readonly __typename?: 'Page';
  readonly adobeAnalyticsData: Maybe<PageAdobeAnalyticsData>;
  readonly adobeAnalyticsMissingDataReason: Maybe<PageThirdPartyDataMissingReason>;
  readonly enrichmentFields: ReadonlyArray<EnrichmentFieldData>;
  readonly firstReferencedAtUrl: Maybe<Scalars['String']['output']>;
  readonly googleAnalyticsData: Maybe<PageGoogleAnalyticsData>;
  readonly googleAnalyticsMissingDataReason: Maybe<PageThirdPartyDataMissingReason>;
  readonly googleAnalyticsV4Data: Maybe<PageGoogleAnalyticsV4Data>;
  readonly googleAnalyticsV4MissingDataReason: Maybe<PageThirdPartyDataMissingReason>;
  readonly googleSearchConsoleData: Maybe<PageGoogleSearchConsoleData>;
  readonly googleSearchConsoleMissingDataReason: Maybe<PageThirdPartyDataMissingReason>;
  readonly googleSearchConsolePerformanceReportLink: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UrlId']['output'];
  readonly incomingCanonicalLinks: Maybe<PageIncomingCanonicalLinksConnection>;
  readonly incomingInternalLinks: Maybe<PageIncomingInternalLinksConnection>;
  readonly incomingRedirects: Maybe<PageIncomingRedirectsConnection>;
  /** If True, at least one of relevant search engines is prevented by robots.txt from accessing this Page. */
  readonly isDisallowedInRobotsTxt: Maybe<Scalars['Boolean']['output']>;
  /** If True, URL of this Page uses https scheme. */
  readonly isHttps: Scalars['Boolean']['output'];
  /** If True, this Page is placed in at least 1 XML sitemap. */
  readonly isInSitemap: Maybe<Scalars['Boolean']['output']>;
  /** If True, this Page is currently prioritized for recrawling. */
  readonly isPrioritized: Scalars['Boolean']['output'];
  /** If True, pageType of this Page has never changed. */
  readonly isPristine: Scalars['Boolean']['output'];
  /** If True, last recrawling of this Page ended with unreliable response (which is exposed in `Page.lastUnreliableResponse` field). */
  readonly isUnreliable: Scalars['Boolean']['output'];
  /** Moment when this Page was crawled last time. */
  readonly lastCheckedAt: Maybe<Scalars['Timestamp']['output']>;
  readonly lastUnreliableResponse: Maybe<FetchingFailureReason>;
  readonly legacyId: Maybe<Scalars['Int']['output']>;
  readonly nextCheckPlannedAt: Maybe<Scalars['Timestamp']['output']>;
  readonly nonIndexabilityReasons: Maybe<ReadonlyArray<PageNonIndexabilityReason>>;
  readonly numberOfIncomingCanonicalLinks: Maybe<Scalars['Int']['output']>;
  readonly numberOfIncomingInternalLinks: Maybe<Scalars['Int']['output']>;
  readonly numberOfIncomingRedirects: Maybe<Scalars['Int']['output']>;
  /** List of segments this Page is currently a part of. */
  readonly pageSegments: ReadonlyArray<PageSegment>;
  /** Last page type established for this Page. It's primary descriptor of a Page and its data. */
  readonly pageType: PageType;
  /** Further data applicable if pageType of this Page is `page`. */
  readonly pageTypeData: Maybe<PageTypeData>;
  readonly pageTypeLastChangedAt: Maybe<Scalars['Timestamp']['output']>;
  readonly primaryTrackedChanges: ReadonlyArray<PageHistoricalMoment>;
  readonly redirectTarget: Maybe<PageRedirectTarget>;
  readonly referencedInSitemaps: Maybe<ReadonlyArray<PageReferenceInSitemap>>;
  /** Link to Kibana logs of crawling this Page. Only exposed to Royalty. */
  readonly scrapbookLinkForRequestLogs: Maybe<Scalars['String']['output']>;
  readonly searchEngineActivity: Maybe<PageSearchEngineActivity>;
  /** Last status code received when crawling this Page. It can be NULL in case of not receiving a response or receiving an unreliable one. */
  readonly statusCode: Maybe<Scalars['Int']['output']>;
  /** Moment when this Page was crawled for the first time. It can be NULL in case of older pages for which this information was never calculated or reconstructed. */
  readonly timeDiscoveredAt: Maybe<Scalars['Timestamp']['output']>;
  readonly timeServerResponse: Maybe<Scalars['Int']['output']>;
  readonly trackedChanges: ReadonlyArray<PageHistoricalMoment>;
  /** Further data applicable if pageType of this Page is 'unreachable'. */
  readonly unreachableTypeData: Maybe<UnreachableTypeData>;
  /** Absolute URL of this Page. */
  readonly url: Scalars['String']['output'];
  /** ContentKing Website entity to which this Page belongs. */
  readonly website: Website;
  /** ID of ContentKing Website entity to which this Page belongs. */
  readonly websiteId: Scalars['WebsiteId']['output'];
  readonly wordpressEditLink: PageWordpressEditLink;
};


export type PageIncomingCanonicalLinksArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type PageIncomingInternalLinksArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type PageIncomingRedirectsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PageAdobeAnalyticsData = {
  readonly __typename?: 'PageAdobeAnalyticsData';
  readonly averageTimeSpentOnSite: Maybe<Scalars['DurationInSeconds']['output']>;
  readonly bounceRate: Maybe<Scalars['Percentage']['output']>;
  readonly dateRange: DateRange;
  readonly pageViews: Maybe<Scalars['Int']['output']>;
  readonly revenue: Maybe<MoneyAmount>;
  readonly uniqueVisitors: Maybe<Scalars['Int']['output']>;
};

export enum PageChangeType {
  Added = 'added',
  Changed = 'changed',
  Other = 'other',
  Redirected = 'redirected',
  Removed = 'removed'
}

export enum PageDetailSourceMode {
  Compare = 'compare',
  Primary = 'primary'
}

export enum PageElementDifference {
  Added = 'added',
  Changed = 'changed',
  Extracted = 'extracted',
  None = 'none',
  Removed = 'removed'
}

export type PageGoogleAnalyticsData = {
  readonly __typename?: 'PageGoogleAnalyticsData';
  readonly averageTime: Scalars['DurationInSeconds']['output'];
  readonly bounceRate: Scalars['Percentage']['output'];
  readonly dateRange: DateRange;
  readonly pageValue: MoneyAmount;
  readonly pageViews: Scalars['Int']['output'];
  readonly uniquePageViews: Scalars['Int']['output'];
};

export type PageGoogleAnalyticsV4Data = {
  readonly __typename?: 'PageGoogleAnalyticsV4Data';
  readonly activeUsers: Scalars['Int']['output'];
  readonly averageEngagementTime: Scalars['DurationInSeconds']['output'];
  readonly dateRange: DateRange;
  readonly engagementRate: Scalars['Percentage']['output'];
  readonly screenPageViews: Scalars['Int']['output'];
};

export type PageGoogleSearchConsoleData = {
  readonly __typename?: 'PageGoogleSearchConsoleData';
  readonly clicks: Scalars['Int']['output'];
  readonly ctr: Scalars['Percentage']['output'];
  readonly dateRange: DateRange;
  readonly impressions: Scalars['Int']['output'];
  readonly position: Scalars['Float']['output'];
};

export type PageHistoricalMoment = {
  readonly indexNowSubmission: Maybe<IndexNowSubmission>;
  readonly isDueToRequest: Scalars['Boolean']['output'];
  readonly snapshotStatusForFullResponse: PageHistoricalMomentSnapshotStatus;
  readonly snapshotStatusForHeaders: PageHistoricalMomentSnapshotStatus;
  readonly trackedAt: Scalars['Timestamp']['output'];
};

export type PageHistoricalMomentPageDiscovered = PageHistoricalMoment & {
  readonly __typename?: 'PageHistoricalMomentPageDiscovered';
  readonly indexNowSubmission: Maybe<IndexNowSubmission>;
  readonly isAnyDataDeletedDueToRetentionPeriod: Scalars['Boolean']['output'];
  readonly isDueToRequest: Scalars['Boolean']['output'];
  readonly observedAtUrl: Maybe<Scalars['String']['output']>;
  readonly snapshotStatusForFullResponse: PageHistoricalMomentSnapshotStatus;
  readonly snapshotStatusForHeaders: PageHistoricalMomentSnapshotStatus;
  readonly trackedAt: Scalars['Timestamp']['output'];
};

export enum PageHistoricalMomentSnapshotStatus {
  Available = 'Available',
  FeatureNotAvailable = 'FeatureNotAvailable',
  FeatureNotEnabled = 'FeatureNotEnabled',
  NotApplicable = 'NotApplicable',
  NotWithinDataRetention = 'NotWithinDataRetention'
}

export type PageHistoricalMomentTrackedChanges = PageHistoricalMoment & {
  readonly __typename?: 'PageHistoricalMomentTrackedChanges';
  readonly indexNowSubmission: Maybe<IndexNowSubmission>;
  readonly isDueToRequest: Scalars['Boolean']['output'];
  readonly snapshotStatusForFullResponse: PageHistoricalMomentSnapshotStatus;
  readonly snapshotStatusForHeaders: PageHistoricalMomentSnapshotStatus;
  readonly trackedAt: Scalars['Timestamp']['output'];
  readonly trackedChanges: ReadonlyArray<PageTrackedChange>;
};

export type PageIncomingCanonicalLinksConnection = Connection & {
  readonly __typename?: 'PageIncomingCanonicalLinksConnection';
  readonly edges: ReadonlyArray<Maybe<PageIncomingCanonicalLinksConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type PageIncomingCanonicalLinksConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'PageIncomingCanonicalLinksConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly loadedAt: Scalars['Int']['output'];
  readonly node: PageIncomingCanonicalLinksConnectionNode;
};

export type PageIncomingCanonicalLinksConnectionNode = {
  readonly __typename?: 'PageIncomingCanonicalLinksConnectionNode';
  readonly id: Scalars['ID']['output'];
  readonly sourceId: Scalars['UrlId']['output'];
  readonly sourceLegacyId: Maybe<Scalars['Int']['output']>;
  readonly sourceUrl: Scalars['String']['output'];
};

export type PageIncomingInternalLinksConnection = Connection & {
  readonly __typename?: 'PageIncomingInternalLinksConnection';
  readonly edges: ReadonlyArray<Maybe<PageIncomingInternalLinksConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type PageIncomingInternalLinksConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'PageIncomingInternalLinksConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly loadedAt: Scalars['Int']['output'];
  readonly node: PageIncomingInternalLinksConnectionNode;
};

export type PageIncomingInternalLinksConnectionNode = {
  readonly __typename?: 'PageIncomingInternalLinksConnectionNode';
  readonly anchorText: Scalars['String']['output'];
  readonly anchorTitle: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly sourceId: Scalars['UrlId']['output'];
  readonly sourceLegacyId: Maybe<Scalars['Int']['output']>;
  readonly sourceUrl: Scalars['String']['output'];
};

export type PageIncomingRedirectsConnection = Connection & {
  readonly __typename?: 'PageIncomingRedirectsConnection';
  readonly edges: ReadonlyArray<Maybe<PageIncomingRedirectsConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type PageIncomingRedirectsConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'PageIncomingRedirectsConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly loadedAt: Scalars['Int']['output'];
  readonly node: PageIncomingRedirectsConnectionNode;
};

export type PageIncomingRedirectsConnectionNode = {
  readonly __typename?: 'PageIncomingRedirectsConnectionNode';
  readonly id: Scalars['ID']['output'];
  readonly sourceId: Scalars['UrlId']['output'];
  readonly sourceLegacyId: Maybe<Scalars['Int']['output']>;
  readonly sourceStatusCode: Scalars['Int']['output'];
  readonly sourceUrl: Scalars['String']['output'];
};

export type PageInfo = {
  readonly __typename?: 'PageInfo';
  readonly endCursor: Maybe<Scalars['Base64JsonObject']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor: Maybe<Scalars['Base64JsonObject']['output']>;
};

export type PageIssue = {
  readonly __typename?: 'PageIssue';
  readonly configuration: Scalars['Array']['output'];
  readonly context: Scalars['Array']['output'];
  readonly followupIssues: ReadonlyArray<PageIssue>;
  readonly ignoringRuleOnCases: PageIssueIgnoringRuleOnCases;
  readonly ignoringRuleOnPage: PageIssueIgnoringRuleOnPage;
  readonly ignoringRuleOnSegments: PageIssueIgnoringRuleOnSegments;
  readonly ignoringRuleOnWebsite: IssueIgnoringRuleOnWebsite;
  readonly isActionable: Scalars['Boolean']['output'];
  readonly isConfigurationModified: Scalars['Boolean']['output'];
  readonly isIgnored: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly pointsGained: Scalars['Int']['output'];
  readonly pointsToGain: Scalars['Int']['output'];
  readonly state: IssueState;
};

export type PageIssueCategory = {
  readonly __typename?: 'PageIssueCategory';
  readonly configuration: Scalars['Array']['output'];
  readonly context: Scalars['Array']['output'];
  readonly isActionable: Scalars['Boolean']['output'];
  readonly isConfigurationModified: Scalars['Boolean']['output'];
  readonly isIgnored: Scalars['Boolean']['output'];
  readonly issues: ReadonlyArray<PageIssue>;
  readonly name: Scalars['String']['output'];
  readonly pointsGained: Scalars['Int']['output'];
  readonly pointsToGain: Scalars['Int']['output'];
  readonly state: IssueCategoryState;
};

export enum PageIssueChangeType {
  Opened = 'opened',
  Resolved = 'resolved'
}

export type PageIssueIgnoringRuleOnCases = {
  readonly __typename?: 'PageIssueIgnoringRuleOnCases';
  readonly id: Scalars['ID']['output'];
  readonly ignoredCases: ReadonlyArray<Scalars['Any']['output']>;
  readonly isEffective: Scalars['Boolean']['output'];
};

export type PageIssueIgnoringRuleOnPage = {
  readonly __typename?: 'PageIssueIgnoringRuleOnPage';
  readonly id: Scalars['ID']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly isEffective: Scalars['Boolean']['output'];
};

export type PageIssueIgnoringRuleOnPages = {
  readonly __typename?: 'PageIssueIgnoringRuleOnPages';
  readonly id: Scalars['ID']['output'];
  readonly ignoredPageLegacyIds: ReadonlyArray<Scalars['Int']['output']>;
  readonly isEffective: Scalars['Boolean']['output'];
};

export type PageIssueIgnoringRuleOnSegments = {
  readonly __typename?: 'PageIssueIgnoringRuleOnSegments';
  readonly id: Scalars['ID']['output'];
  readonly ignoredSegments: ReadonlyArray<Scalars['String']['output']>;
  readonly isEffective: Scalars['Boolean']['output'];
};

export type PageLighthouseData = {
  readonly __typename?: 'PageLighthouseData';
  readonly cls: Scalars['Float']['output'];
  readonly clsRange: WebVitalRange;
  readonly fcp: Scalars['Int']['output'];
  readonly fcpRange: WebVitalRange;
  readonly lcp: Scalars['Int']['output'];
  readonly lcpRange: WebVitalRange;
  readonly performance: Scalars['Int']['output'];
  readonly performanceRange: WebVitalRange;
  readonly si: Scalars['Int']['output'];
  readonly siRange: WebVitalRange;
  readonly tbt: Scalars['Int']['output'];
  readonly tbtRange: WebVitalRange;
  readonly tti: Scalars['Int']['output'];
  readonly ttiRange: WebVitalRange;
};

export enum PageNonIndexabilityReason {
  CanonicalLink = 'CanonicalLink',
  MetaRobots = 'MetaRobots',
  NotPage = 'NotPage',
  RobotsTxt = 'RobotsTxt',
  XRobotsTag = 'XRobotsTag'
}

export enum PageOutgoingExternalLinkStatus {
  Crawled = 'Crawled',
  ExoticPort = 'ExoticPort',
  Forbidden = 'Forbidden',
  NotBeingCrawled = 'NotBeingCrawled',
  NotCrawledYet = 'NotCrawledYet',
  Unreachable = 'Unreachable'
}

export type PageOutgoingExternalLinksConnection = Connection & {
  readonly __typename?: 'PageOutgoingExternalLinksConnection';
  readonly edges: ReadonlyArray<Maybe<PageOutgoingExternalLinksConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type PageOutgoingExternalLinksConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'PageOutgoingExternalLinksConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly loadedAt: Scalars['Int']['output'];
  readonly node: PageOutgoingExternalLinksConnectionNode;
};

export type PageOutgoingExternalLinksConnectionNode = {
  readonly __typename?: 'PageOutgoingExternalLinksConnectionNode';
  readonly anchorText: Scalars['String']['output'];
  readonly anchorTitle: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly targetId: Scalars['UrlId']['output'];
  readonly targetLegacyId: Maybe<Scalars['Int']['output']>;
  readonly targetStatus: PageOutgoingExternalLinkStatus;
  readonly targetStatusCode: Maybe<Scalars['Int']['output']>;
  readonly targetUrl: Scalars['String']['output'];
};

export enum PageOutgoingInternalLinkStatus {
  Crawled = 'Crawled',
  NotCrawledYet = 'NotCrawledYet',
  Unreachable = 'Unreachable'
}

export type PageOutgoingInternalLinksConnection = Connection & {
  readonly __typename?: 'PageOutgoingInternalLinksConnection';
  readonly edges: ReadonlyArray<Maybe<PageOutgoingInternalLinksConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type PageOutgoingInternalLinksConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'PageOutgoingInternalLinksConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly loadedAt: Scalars['Int']['output'];
  readonly node: PageOutgoingInternalLinksConnectionNode;
};

export type PageOutgoingInternalLinksConnectionNode = {
  readonly __typename?: 'PageOutgoingInternalLinksConnectionNode';
  readonly anchorText: Scalars['String']['output'];
  readonly anchorTitle: Maybe<Scalars['String']['output']>;
  readonly canonicalLink: Maybe<PageOutgoingInternalLinksConnectionNodeCanonicalLink>;
  readonly id: Scalars['ID']['output'];
  readonly redirectTarget: Maybe<PageOutgoingInternalLinksConnectionNodeRedirectTarget>;
  readonly targetId: Scalars['UrlId']['output'];
  readonly targetLegacyId: Maybe<Scalars['Int']['output']>;
  readonly targetStatus: PageOutgoingInternalLinkStatus;
  readonly targetStatusCode: Maybe<Scalars['Int']['output']>;
  readonly targetUrl: Scalars['String']['output'];
};

export type PageOutgoingInternalLinksConnectionNodeCanonicalLink = {
  readonly __typename?: 'PageOutgoingInternalLinksConnectionNodeCanonicalLink';
  readonly isCrossdomain: Scalars['Boolean']['output'];
  readonly legacyId: Maybe<Scalars['Int']['output']>;
  readonly url: Scalars['String']['output'];
};

export type PageOutgoingInternalLinksConnectionNodeRedirectTarget = {
  readonly __typename?: 'PageOutgoingInternalLinksConnectionNodeRedirectTarget';
  readonly isCrossdomain: Scalars['Boolean']['output'];
  readonly legacyId: Maybe<Scalars['Int']['output']>;
  readonly url: Scalars['String']['output'];
};

export type PageRedirectTarget = {
  readonly __typename?: 'PageRedirectTarget';
  readonly isCrossdomain: Scalars['Boolean']['output'];
  readonly legacyUrlId: Maybe<Scalars['Int']['output']>;
  readonly url: Scalars['String']['output'];
};

export type PageReferenceInSitemap = {
  readonly __typename?: 'PageReferenceInSitemap';
  readonly sitemapLegacyUrlId: Scalars['Int']['output'];
  readonly sitemapUrl: Scalars['String']['output'];
};

export type PageSearchEngineActivity = {
  readonly __typename?: 'PageSearchEngineActivity';
  readonly lastVisit: Maybe<Scalars['Timestamp']['output']>;
  readonly visitFrequency: Maybe<Scalars['Float']['output']>;
  readonly visitsPerDay: Maybe<ReadonlyArray<PageSearchEngineActivityVisitsPerDay>>;
};


export type PageSearchEngineActivityLastVisitArgs = {
  device: InputMaybe<SearchEngineActivityDevice>;
  searchEngine: SearchEngineActivitySearchEngine;
};


export type PageSearchEngineActivityVisitFrequencyArgs = {
  device: InputMaybe<SearchEngineActivityDevice>;
  periodUnit: PeriodUnit;
  searchEngine: SearchEngineActivitySearchEngine;
};


export type PageSearchEngineActivityVisitsPerDayArgs = {
  device: SearchEngineActivityDevice;
  searchEngine: SearchEngineActivitySearchEngine;
};

export type PageSearchEngineActivityVisitsPerDay = {
  readonly __typename?: 'PageSearchEngineActivityVisitsPerDay';
  readonly date: Scalars['DateYMD']['output'];
  readonly isDateComplete: Scalars['Boolean']['output'];
  readonly numberOfVisits: Scalars['Int']['output'];
};

export type PageSegment = {
  readonly __typename?: 'PageSegment';
  readonly color: Scalars['String']['output'];
  readonly filterDefinition: Scalars['FilterDefinition']['output'];
  readonly icon: Maybe<PageSegmentIcon>;
  readonly id: Scalars['PageSegmentId']['output'];
  readonly isBeingReevaluated: Scalars['Boolean']['output'];
  readonly isManaged: Scalars['Boolean']['output'];
  readonly label: Scalars['String']['output'];
  readonly name: Scalars['PageSegmentName']['output'];
  readonly shortcode: Maybe<Scalars['String']['output']>;
  readonly sizeLimit: Maybe<Scalars['PageSegmentSizeLimit']['output']>;
};

export type PageSegmentIcon = {
  readonly __typename?: 'PageSegmentIcon';
  readonly files: ReadonlyArray<ImageSizeVersion>;
  readonly name: Scalars['String']['output'];
};

export enum PageThirdPartyDataMissingReason {
  NoData = 'no_data',
  NotApplicable = 'not_applicable',
  NotConnected = 'not_connected',
  NotLoadedYet = 'not_loaded_yet'
}

export type PageTrackedChange = {
  readonly __typename?: 'PageTrackedChange';
  readonly difference: PageElementDifference;
  readonly newContent: Maybe<Scalars['Any']['output']>;
  readonly oldContent: Maybe<Scalars['Any']['output']>;
  readonly position: Scalars['Int']['output'];
  readonly searchEngineActivity: Maybe<ReadonlyArray<SearchEngineActivityAtMoment>>;
  readonly source: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
};

export enum PageType {
  Missing = 'missing',
  Other = 'other',
  OverQuota = 'over_quota',
  Page = 'page',
  Redirect = 'redirect',
  ServerError = 'server_error',
  Special = 'special',
  Unknown = 'unknown',
  Unreachable = 'unreachable'
}

export type PageTypeData = {
  readonly __typename?: 'PageTypeData';
  readonly analyticServices: ReadonlyArray<AnalyticServiceItemsCollection>;
  /** Effective absolute canonical link URL established from HTML & HTTP headers. It can be NULL if Page specifies no canonical link. */
  readonly canonicalUrl: Maybe<Scalars['String']['output']>;
  readonly content: ReadonlyArray<ContentData>;
  readonly customElements: ReadonlyArray<CustomElementData>;
  /** Latest Health score calculated according to issues of this Page. Read more in [full documentation](https://github.com/contentking/codebase/blob/master/docs/full/auditing.md#auditing). */
  readonly health: Scalars['Int']['output'];
  /** Latest Importance score calculated for this Page. Read more in [full documentation](https://github.com/contentking/codebase/blob/master/docs/full/importance_and_weight.md). */
  readonly importance: Scalars['Float']['output'];
  readonly isIndexable: Scalars['Boolean']['output'];
  readonly isIndexableDueToMetaRobots: Scalars['Boolean']['output'];
  readonly isIndexableDueToXRobotsTag: Scalars['Boolean']['output'];
  /** Latest state of issues of this Page. Read more in [full documentation](https://github.com/contentking/codebase/blob/master/docs/full/auditing.md#auditing). */
  readonly issueCategories: ReadonlyArray<PageIssueCategory>;
  readonly lighthouseData: Maybe<PageLighthouseData>;
  readonly linkCanonical: Maybe<LinkRelItem>;
  readonly linkCanonicalHeader: LinkRelItemsCollection;
  readonly numberOfOutgoingExternalLinks: Scalars['Int']['output'];
  readonly numberOfOutgoingInternalLinks: Scalars['Int']['output'];
  readonly openGraphDescription: ContentItemsCollection;
  readonly openGraphImage: ContentItemsCollection;
  readonly openGraphTitle: ContentItemsCollection;
  readonly openGraphType: ContentItemsCollection;
  readonly openGraphUrl: ContentItemsCollection;
  readonly outgoingExternalLinks: Maybe<PageOutgoingExternalLinksConnection>;
  readonly outgoingInternalLinks: Maybe<PageOutgoingInternalLinksConnection>;
  readonly primaryProperties: OnPageProperties;
  /** Link to screenshot image of this Page. Screenshot is generated using [URL2PNG third-party service](https://github.com/contentking/codebase/blob/master/docs/full/technologies.md#url2png). */
  readonly screenshotUrl: Scalars['String']['output'];
  readonly srcProperties: OnPageProperties;
  readonly tagManagers: ReadonlyArray<TagManagerItemsCollection>;
  readonly twitterCard: ContentItemsCollection;
  readonly twitterDescription: ContentItemsCollection;
  readonly twitterImage: ContentItemsCollection;
  readonly twitterSite: ContentItemsCollection;
  readonly twitterTitle: ContentItemsCollection;
  readonly visualAnalyticServices: ReadonlyArray<VisualAnalyticServiceItemsCollection>;
  readonly xRobotsTag: ContentItemsCollection;
};


export type PageTypeDataOutgoingExternalLinksArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type PageTypeDataOutgoingInternalLinksArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PageWordpressEditLink = {
  readonly __typename?: 'PageWordpressEditLink';
  readonly link: Maybe<Scalars['String']['output']>;
  readonly unavailabilityReason: Maybe<WordpressEditLinkUnavailabilityReason>;
};

export enum PeriodUnit {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Year = 'year'
}

export type PlatformData = {
  readonly __typename?: 'PlatformData';
  readonly canonicalDomain: Maybe<Scalars['String']['output']>;
  readonly canonicalRobotsTxtIsPrioritized: Scalars['Boolean']['output'];
  readonly canonicalRobotsTxtLastCheckedAt: Maybe<Scalars['Timestamp']['output']>;
  readonly canonicalRobotsTxtLegacyUrlId: Maybe<Scalars['Int']['output']>;
  readonly health: Maybe<Scalars['Int']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly isHttpsSupported: Maybe<Scalars['Boolean']['output']>;
  readonly issueCategories: ReadonlyArray<PlatformIssueCategory>;
  readonly robotsTxtSearchEngineActivity: Maybe<ReadonlyArray<SearchEngineActivityAtMoment>>;
  readonly robotsTxtUrls: ReadonlyArray<Scalars['String']['output']>;
  readonly robotsTxtXmlSitemapReference: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly webVitalsOriginSummaryData: Maybe<WebVitalsOriginSummaryData>;
  readonly xmlSitemapIndicesCount: Scalars['Int']['output'];
  readonly xmlSitemapUrlsCount: Scalars['Int']['output'];
  readonly xmlSitemapsCount: Scalars['Int']['output'];
};

export type PlatformIssue = {
  readonly __typename?: 'PlatformIssue';
  readonly configuration: Scalars['Array']['output'];
  readonly context: Scalars['Array']['output'];
  readonly followupIssues: ReadonlyArray<PlatformIssue>;
  readonly ignoringRuleOnWebsite: IssueIgnoringRuleOnWebsite;
  readonly isActionable: Scalars['Boolean']['output'];
  readonly isConfigurationModified: Scalars['Boolean']['output'];
  readonly isIgnored: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly pointsGained: Scalars['Int']['output'];
  readonly pointsToGain: Scalars['Int']['output'];
  readonly state: IssueState;
};

export type PlatformIssueCategory = {
  readonly __typename?: 'PlatformIssueCategory';
  readonly configuration: Scalars['Array']['output'];
  readonly context: Scalars['Array']['output'];
  readonly isActionable: Scalars['Boolean']['output'];
  readonly isConfigurationModified: Scalars['Boolean']['output'];
  readonly isIgnored: Scalars['Boolean']['output'];
  readonly issues: ReadonlyArray<PlatformIssue>;
  readonly name: Scalars['String']['output'];
  readonly pointsGained: Scalars['Int']['output'];
  readonly pointsToGain: Scalars['Int']['output'];
  readonly state: IssueCategoryState;
};

export enum PlatformTourWebinarOffering {
  Auto = 'auto',
  No = 'no',
  Registered = 'registered',
  Yes = 'yes'
}

export type PrioritizePageResult = MutationResult & {
  readonly __typename?: 'PrioritizePageResult';
  readonly page: Maybe<Page>;
  readonly pageNotFound: Maybe<Scalars['Error']['output']>;
  readonly query: Query;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly account: Maybe<Account>;
  readonly accountFeaturesAvailability: ReadonlyArray<AccountFeatureAvailability>;
  readonly addonsAvailability: ReadonlyArray<AddonAvailability>;
  readonly affectedPagesComparison: Maybe<AffectedPagesComparisonConnection>;
  readonly alertPages: Maybe<AlertPagesConnection>;
  readonly alertTypeDefinitions: ReadonlyArray<AlertTypeDefinition>;
  readonly appClientVersion: Scalars['Int']['output'];
  readonly authenticatedSession: Maybe<AuthenticatedSession>;
  readonly authenticatedUser: Maybe<User>;
  readonly billingEntities: ReadonlyArray<BillingEntity>;
  readonly cloudflareLogpushVerificationForS3Bucket: Maybe<Scalars['String']['output']>;
  readonly dashboardData: DashboardData;
  readonly diagnoseExternalDomain: Maybe<DiagnoseExternalDomainResult>;
  readonly domainBlacklist: ReadonlyArray<Scalars['String']['output']>;
  readonly domainsValidationSession: Maybe<StagingAreaOfDomainsValidationSession>;
  readonly failedJobStatistics: Maybe<ReadonlyArray<FailedJobStatistic>>;
  readonly fetchingSnapshotExport: Maybe<FetchingSnapshotExport>;
  readonly filteredPages: Maybe<FilteredPagesConnection>;
  readonly invitationsValidationSession: Maybe<StagingAreaOfInvitationsValidationSession>;
  readonly isDomainPreverified: Maybe<DomainPreverifiedStatus>;
  readonly issuesAffectedPages: Maybe<IssuesAffectedPagesConnection>;
  readonly kingdomSettings: Maybe<KingdomSettings>;
  readonly lookupPageById: Maybe<Page>;
  readonly lookupPageByLegacyId: Maybe<Page>;
  readonly lookupPageByUrl: Maybe<Page>;
  readonly nonPages: Maybe<NonPagesConnection>;
  readonly ping: Scalars['String']['output'];
  readonly platformData: Maybe<PlatformData>;
  readonly platformPermittedActionsForKingdomAdmin: Maybe<ReadonlyArray<ActionWithPlatform>>;
  readonly resetPasswordSession: Maybe<ResetPasswordSession>;
  readonly responseSnapshot: Maybe<ResponseSnapshot>;
  readonly robotsTxt: Maybe<RobotsTxt>;
  readonly robotsTxtRevisionById: Maybe<RobotsTxtRevision>;
  readonly robotsTxtRevisionLatest: Maybe<RobotsTxtRevision>;
  readonly robotsTxtRevisionsComparison: Maybe<RobotsTxtRevisionsComparison>;
  readonly robotsTxtRevisionsList: Maybe<ReadonlyArray<RobotsTxtRevision>>;
  readonly sitemaps: Maybe<SitemapsConnection>;
  readonly staticPageSegmentUrls: Maybe<StaticPageSegmentUrlsResult>;
  readonly sudoModeStatus: SudoModeStatus;
  readonly suggestedImpersonationsToAccessWebsite: ReadonlyArray<SuggestedImpersonation>;
  readonly tax: Tax;
  readonly testFetchViaRendering: Maybe<TestFetchViaRenderingResult>;
  readonly urlIndexStatus: UrlIndexStatus;
  readonly user: Maybe<User>;
  readonly userAccountMembership: Maybe<AccountMembership>;
  readonly userAgents: ReadonlyArray<UserAgent>;
  readonly validateSchemaOrg: Maybe<SchemaOrgValidationResult>;
  readonly website: Maybe<Website>;
  readonly websiteIncident: Maybe<WebsiteIncident>;
  readonly websiteIncidents: Maybe<ReadonlyArray<WebsiteIncident>>;
  readonly websitesIncidents: ReadonlyArray<WebsiteIncident>;
};


export type QueryAccountArgs = {
  id: Scalars['AccountId']['input'];
};


export type QueryAffectedPagesComparisonArgs = {
  ascending: Scalars['Boolean']['input'];
  dateFrom: Scalars['DateYMD']['input'];
  dateTo: Scalars['DateYMD']['input'];
  filter: Scalars['Array']['input'];
  issue: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: InputMaybe<Scalars['Int']['input']>;
  sort: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryAlertPagesArgs = {
  after: InputMaybe<Scalars['Base64JsonObject']['input']>;
  alertId: Scalars['Int']['input'];
  ascending: Scalars['Boolean']['input'];
  before: InputMaybe<Scalars['Base64JsonObject']['input']>;
  filter: Scalars['Array']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: AlertPagesSortBy;
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryCloudflareLogpushVerificationForS3BucketArgs = {
  s3BucketName: Scalars['String']['input'];
};


export type QueryDiagnoseExternalDomainArgs = {
  domain: Scalars['String']['input'];
};


export type QueryDomainsValidationSessionArgs = {
  accountId: Scalars['AccountId']['input'];
  sessionId: Scalars['String']['input'];
};


export type QueryFetchingSnapshotExportArgs = {
  exportId: Scalars['Int']['input'];
};


export type QueryFilteredPagesArgs = {
  after: InputMaybe<Scalars['Base64JsonObject']['input']>;
  ascending: Scalars['Boolean']['input'];
  before: InputMaybe<Scalars['Base64JsonObject']['input']>;
  columns: ReadonlyArray<Scalars['String']['input']>;
  filter: Scalars['Array']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryInvitationsValidationSessionArgs = {
  accountId: Scalars['AccountId']['input'];
  sessionId: Scalars['String']['input'];
};


export type QueryIsDomainPreverifiedArgs = {
  accountId: Scalars['AccountId']['input'];
  domain: Scalars['String']['input'];
};


export type QueryIssuesAffectedPagesArgs = {
  ascending: Scalars['Boolean']['input'];
  filter: Scalars['Array']['input'];
  issueCategory: InputMaybe<Scalars['String']['input']>;
  issueType: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<Scalars['String']['input']>;
  websiteId: InputMaybe<Scalars['WebsiteId']['input']>;
};


export type QueryLookupPageByIdArgs = {
  fullHistory: InputMaybe<Scalars['Boolean']['input']>;
  urlId: Scalars['UrlId']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryLookupPageByLegacyIdArgs = {
  fullHistory: InputMaybe<Scalars['Boolean']['input']>;
  legacyUrlId: Scalars['Int']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryLookupPageByUrlArgs = {
  url: Scalars['String']['input'];
};


export type QueryNonPagesArgs = {
  after: InputMaybe<Scalars['Base64JsonObject']['input']>;
  ascending: Scalars['Boolean']['input'];
  before: InputMaybe<Scalars['Base64JsonObject']['input']>;
  filter: Scalars['Array']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryPlatformDataArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryResponseSnapshotArgs = {
  timeReceivedAt: Scalars['Timestamp']['input'];
  urlId: Scalars['UrlId']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryRobotsTxtArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryRobotsTxtRevisionByIdArgs = {
  revisionId: Scalars['ID']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryRobotsTxtRevisionLatestArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryRobotsTxtRevisionsComparisonArgs = {
  nextRevisionId: Scalars['ID']['input'];
  previousRevisionId: Scalars['ID']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryRobotsTxtRevisionsListArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type QuerySitemapsArgs = {
  direction: Scalars['String']['input'];
  fields: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  filter: Scalars['Array']['input'];
  limit: Scalars['Int']['input'];
  offset: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<SitemapsSortBy>;
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryStaticPageSegmentUrlsArgs = {
  segmentId: Scalars['PageSegmentId']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QuerySudoModeStatusArgs = {
  disallowedMethod: InputMaybe<MethodToEnterSudoMode>;
};


export type QuerySuggestedImpersonationsToAccessWebsiteArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryTaxArgs = {
  country: Scalars['String']['input'];
  state: InputMaybe<Scalars['String']['input']>;
  vatNumber: InputMaybe<Scalars['String']['input']>;
};


export type QueryTestFetchViaRenderingArgs = {
  settings: InputMaybe<TestFetchViaRenderingSettings>;
  url: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryUrlIndexStatusArgs = {
  url: Scalars['String']['input'];
};


export type QueryUserArgs = {
  legacyId: InputMaybe<Scalars['ID']['input']>;
  uniqueId: InputMaybe<Scalars['UserId']['input']>;
};


export type QueryUserAccountMembershipArgs = {
  accountId: Scalars['AccountId']['input'];
  legacyUserId: Scalars['String']['input'];
};


export type QueryValidateSchemaOrgArgs = {
  schemaOrg: Scalars['String']['input'];
};


export type QueryWebsiteArgs = {
  id: Scalars['WebsiteId']['input'];
};


export type QueryWebsiteIncidentArgs = {
  incidentId: Scalars['Int']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryWebsiteIncidentsArgs = {
  websiteId: Scalars['WebsiteId']['input'];
};


export type QueryWebsitesIncidentsArgs = {
  websiteIds: ReadonlyArray<Scalars['WebsiteId']['input']>;
};

export type RegisterMicrosoftTeamsIncomingWebhookResult = MutationResult & {
  readonly __typename?: 'RegisterMicrosoftTeamsIncomingWebhookResult';
  readonly query: Query;
  readonly registeredMessagingAppChannelId: Maybe<Scalars['ID']['output']>;
};

export type ResetPasswordResult = MutationResult & {
  readonly __typename?: 'ResetPasswordResult';
  readonly email: Scalars['String']['output'];
  readonly query: Query;
};

export type ResetPasswordSession = {
  readonly __typename?: 'ResetPasswordSession';
  readonly isTwoFactorAuthenticationEnabled: Scalars['Boolean']['output'];
  readonly isTwoFactorAuthenticationVerified: Scalars['Boolean']['output'];
};

export type ResponseSnapshot = {
  readonly __typename?: 'ResponseSnapshot';
  readonly dom: Maybe<ResponseSnapshotCode>;
  readonly id: Maybe<Scalars['ID']['output']>;
  readonly isFailedResponse: Scalars['Boolean']['output'];
  readonly isRendered: Scalars['Boolean']['output'];
  readonly isResponseBodyTracked: Scalars['Boolean']['output'];
  readonly requestHeaders: ReadonlyArray<HttpHeader>;
  readonly responseFailureReason: Maybe<FetchingFailureReason>;
  readonly responseHeaders: Maybe<ReadonlyArray<HttpHeader>>;
  readonly responsePrimaryIp: Maybe<Scalars['String']['output']>;
  readonly responseStatusCode: Maybe<Scalars['Int']['output']>;
  readonly src: Maybe<ResponseSnapshotCode>;
};

export type ResponseSnapshotCode = {
  readonly __typename?: 'ResponseSnapshotCode';
  readonly isTooLarge: Scalars['Boolean']['output'];
  readonly longestLineCharacters: Maybe<Scalars['Int']['output']>;
  readonly rows: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

export type ReviveTrialByAddingWebsiteResult = MutationResult & {
  readonly __typename?: 'ReviveTrialByAddingWebsiteResult';
  readonly query: Query;
  readonly website: Website;
};

export type RobotsTxt = {
  readonly __typename?: 'RobotsTxt';
  readonly disallowedRules: ReadonlyArray<Scalars['String']['output']>;
};

export type RobotsTxtRevision = {
  readonly __typename?: 'RobotsTxtRevision';
  readonly content: Maybe<RobotsTxtRevisionContent>;
  readonly createdAt: Scalars['Timestamp']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isContentEmpty: Maybe<Scalars['Boolean']['output']>;
  readonly isDownloadAllowed: Maybe<Scalars['Boolean']['output']>;
  readonly isUnreliable: Scalars['Boolean']['output'];
  readonly lastUnreliableResponse: Maybe<FetchingFailureReason>;
  readonly linesWithInvalidSyntax: ReadonlyArray<RobotsTxtRevisionLineWithInvalidSyntax>;
  readonly requestHttpHeaders: ReadonlyArray<HttpHeader>;
  readonly responseFailureReason: Maybe<FetchingFailureReason>;
  readonly responseHttpHeaders: Maybe<ReadonlyArray<HttpHeader>>;
  readonly responsePrimaryIp: Maybe<Scalars['String']['output']>;
  readonly responseStatusCode: Maybe<Scalars['Int']['output']>;
  readonly searchEngineActivity: Maybe<ReadonlyArray<SearchEngineActivityAtMoment>>;
  readonly unreliableSince: Maybe<Scalars['Timestamp']['output']>;
  readonly url: Scalars['String']['output'];
};

export type RobotsTxtRevisionContent = {
  readonly __typename?: 'RobotsTxtRevisionContent';
  readonly isTooLarge: Scalars['Boolean']['output'];
  readonly longestRowCharacters: Scalars['Int']['output'];
  readonly rows: ReadonlyArray<Scalars['Array']['output']>;
};

export type RobotsTxtRevisionLineWithInvalidSyntax = {
  readonly __typename?: 'RobotsTxtRevisionLineWithInvalidSyntax';
  readonly lineContent: Scalars['String']['output'];
  readonly lineNumber: Scalars['Int']['output'];
};

export type RobotsTxtRevisionsComparison = {
  readonly __typename?: 'RobotsTxtRevisionsComparison';
  readonly id: Scalars['ID']['output'];
  readonly longestRowCharacters: Maybe<Scalars['Int']['output']>;
  readonly nextRevision: RobotsTxtRevision;
  readonly previousRevision: RobotsTxtRevision;
  readonly rows: Maybe<ReadonlyArray<Scalars['Array']['output']>>;
};

export enum RoyalGroup {
  Core = 'Core',
  Expert = 'Expert',
  None = 'None',
  Novice = 'Novice',
  OutOfBand = 'OutOfBand',
  Trainee = 'Trainee'
}

export type ScenarioIllustratingAlertSensitivity = {
  readonly __typename?: 'ScenarioIllustratingAlertSensitivity';
  readonly countOfLeastImportantPagesToTriggerAlert: Scalars['Int']['output'];
  readonly countOfMostImportantPagesToTriggerAlert: Scalars['Int']['output'];
  readonly scope: Scalars['String']['output'];
  readonly scopeSize: Scalars['Int']['output'];
  readonly sensitivity: AlertSensitivityLevel;
};

export type SchemaOrgError = {
  readonly __typename?: 'SchemaOrgError';
  readonly message: Scalars['String']['output'];
  readonly path: Scalars['String']['output'];
};

export type SchemaOrgValidationResult = {
  readonly __typename?: 'SchemaOrgValidationResult';
  readonly errors: ReadonlyArray<SchemaOrgError>;
};

export type SearchEngineActivityAtMoment = {
  readonly __typename?: 'SearchEngineActivityAtMoment';
  readonly lastVisit: Maybe<Scalars['Timestamp']['output']>;
  readonly searchEngine: SearchEngineActivitySearchEngine;
  readonly status: SearchEngineActivityAtMomentStatus;
};

export enum SearchEngineActivityAtMomentStatus {
  FeatureNotEnabled = 'featureNotEnabled',
  NotVisitedSince = 'notVisitedSince',
  VisitedSince = 'visitedSince'
}

export enum SearchEngineActivityDevice {
  Desktop = 'desktop',
  Mobile = 'mobile'
}

export enum SearchEngineActivitySearchEngine {
  Bing = 'bing',
  Google = 'google'
}

export type SegmentIdentifier = {
  readonly __typename?: 'SegmentIdentifier';
  readonly color: Scalars['String']['output'];
  readonly iconName: Maybe<Scalars['String']['output']>;
  readonly label: Scalars['String']['output'];
  readonly shortcode: Maybe<Scalars['String']['output']>;
};

export type SegmentIdentifierInput = {
  readonly color: Scalars['String']['input'];
  readonly iconName: InputMaybe<Scalars['String']['input']>;
  readonly label: Scalars['String']['input'];
  readonly shortcode: InputMaybe<Scalars['String']['input']>;
};

export type SitemapsConnection = Connection & {
  readonly __typename?: 'SitemapsConnection';
  readonly edges: ReadonlyArray<Maybe<SitemapsConnectionEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

export type SitemapsConnectionEdge = ConnectionEdge & {
  readonly __typename?: 'SitemapsConnectionEdge';
  readonly cursor: Scalars['Base64JsonObject']['output'];
  readonly node: SitemapsConnectionNode;
};

export type SitemapsConnectionNode = {
  readonly __typename?: 'SitemapsConnectionNode';
  readonly data: Scalars['Array']['output'];
  readonly id: Scalars['ID']['output'];
  readonly legacyId: Maybe<Scalars['Int']['output']>;
};

export enum SitemapsSortBy {
  CanBePrioritized = 'can_be_prioritized',
  ContainsMajorErrors = 'contains_major_errors',
  ContainsMinorErrors = 'contains_minor_errors',
  IsPrioritized = 'is_prioritized',
  LastCrawledAt = 'last_crawled_at',
  LfaBingFrequency = 'lfa_bing_frequency',
  LfaBingLastVisit = 'lfa_bing_last_visit',
  LfaGoogleFrequency = 'lfa_google_frequency',
  LfaGoogleLastVisit = 'lfa_google_last_visit',
  NumberOfErrors = 'number_of_errors',
  NumberOfRecords = 'number_of_records',
  References = 'references',
  SizeCompressed = 'size_compressed',
  SizeUncompressed = 'size_uncompressed',
  Status = 'status',
  StatusCode = 'status_code',
  Type = 'type',
  Url = 'url'
}

export type StageDomainsForValidationFromFreeInputResult = MutationResult & {
  readonly __typename?: 'StageDomainsForValidationFromFreeInputResult';
  readonly query: Query;
  readonly remainingFreeInputText: Scalars['String']['output'];
  readonly stagingArea: StagingAreaOfDomainsValidationSession;
};

export type StageDomainsForValidationResult = MutationResult & {
  readonly __typename?: 'StageDomainsForValidationResult';
  readonly query: Query;
  readonly stagingArea: StagingAreaOfDomainsValidationSession;
};

export type StageEmailsForValidationFromFreeInputResult = MutationResult & {
  readonly __typename?: 'StageEmailsForValidationFromFreeInputResult';
  readonly query: Query;
  readonly remainingFreeInputText: Scalars['String']['output'];
  readonly stagingArea: StagingAreaOfInvitationsValidationSession;
};

export type StagingAreaOfDomainsValidationSession = {
  readonly __typename?: 'StagingAreaOfDomainsValidationSession';
  readonly failedDomains: ReadonlyArray<DomainsValidationFailedDomain>;
  readonly finishedDomains: ReadonlyArray<DomainsValidationFinishedDomain>;
  readonly pendingDomains: ReadonlyArray<DomainsValidationPendingDomain>;
};

export type StagingAreaOfInvitationsValidationSession = {
  readonly __typename?: 'StagingAreaOfInvitationsValidationSession';
  readonly failedEmails: ReadonlyArray<InvitationsValidationFailedEmail>;
  readonly finishedEmails: ReadonlyArray<InvitationsValidationFinishedEmail>;
  readonly pendingEmails: ReadonlyArray<InvitationsValidationPendingEmail>;
};

export type StaticPageSegmentUrlsResult = {
  readonly __typename?: 'StaticPageSegmentUrlsResult';
  readonly id: Scalars['PageSegmentId']['output'];
  readonly urls: ReadonlyArray<Scalars['String']['output']>;
};

export type StripePaymentAction = {
  readonly __typename?: 'StripePaymentAction';
  readonly actionType: StripePaymentActionType;
  readonly clientSecret: Maybe<Scalars['String']['output']>;
  readonly paymentMethod: Maybe<Scalars['String']['output']>;
};

export enum StripePaymentActionType {
  Confirm = 'Confirm',
  FinishSignup = 'FinishSignup',
  Pay = 'Pay',
  Retry = 'Retry'
}

export enum SubscriptionsSellerCode {
  De = 'de',
  Llc = 'llc',
  Nl = 'nl',
  Old = 'old',
  Uk = 'uk',
  Us = 'us'
}

export type SudoModeStatus = {
  readonly __typename?: 'SudoModeStatus';
  readonly isSudoEnabled: Scalars['Boolean']['output'];
  readonly suggestedMethodToEnter: Maybe<MethodToEnterSudoMode>;
};

export type SuggestedGoogleAnalyticsAccount = {
  readonly __typename?: 'SuggestedGoogleAnalyticsAccount';
  readonly googleAnalyticsAccountId: Maybe<Scalars['ID']['output']>;
  readonly googleAnalyticsPropertyId: Maybe<Scalars['ID']['output']>;
  readonly googleAnalyticsViewId: Maybe<Scalars['ID']['output']>;
};

export type SuggestedImpersonation = {
  readonly __typename?: 'SuggestedImpersonation';
  readonly accountId: Scalars['AccountId']['output'];
  readonly accountPhase: AccountPhase;
  readonly accountState: Scalars['String']['output'];
  readonly email: Scalars['String']['output'];
  readonly memberships: ReadonlyArray<SuggestedImpersonationMembership>;
  readonly numberOfHomeWebsites: Scalars['Int']['output'];
  readonly role: UserRole;
  readonly signature: Scalars['String']['output'];
};

export type SuggestedImpersonationMembership = {
  readonly __typename?: 'SuggestedImpersonationMembership';
  readonly accountId: Scalars['Int']['output'];
  readonly accountPhase: AccountPhase;
  readonly accountState: Scalars['String']['output'];
  readonly numberOfHomeWebsites: Scalars['Int']['output'];
  readonly role: UserRole;
};

export type TagManagerItemsCollection = {
  readonly __typename?: 'TagManagerItemsCollection';
  readonly contentItems: ReadonlyArray<NullableContentItem>;
  readonly type: TagManagerType;
};

export enum TagManagerType {
  AdobeTagManager = 'adobe_tag_manager',
  GoogleTagManager = 'google_tag_manager',
  SegmentComTagManager = 'segment_com_tag_manager'
}

export type Tax = {
  readonly __typename?: 'Tax';
  readonly country: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly rate: Scalars['Percentage']['output'];
  readonly type: Maybe<TaxType>;
};

export enum TaxType {
  SalesTax = 'SalesTax',
  Vat = 'Vat'
}

export enum Term {
  Annually = 'annually',
  Monthly = 'monthly',
  Quarterly = 'quarterly'
}

export type TestCustomElementExtractionResult = MutationResult & {
  readonly __typename?: 'TestCustomElementExtractionResult';
  readonly dataType: CustomElementDataType;
  readonly extractedValue: Maybe<Scalars['Any']['output']>;
  readonly query: Query;
  readonly url: Scalars['String']['output'];
};

export type TestFetchViaRenderingResult = {
  readonly __typename?: 'TestFetchViaRenderingResult';
  readonly analyticsDetected: ReadonlyArray<Scalars['String']['output']>;
  readonly analyticsNotDetected: ReadonlyArray<Scalars['String']['output']>;
  readonly blockedInterceptedRequests: ReadonlyArray<TestFetchViaRenderingResultBlockedInterceptedRequest>;
  readonly console: ReadonlyArray<Scalars['String']['output']>;
  readonly failureReason: Maybe<FetchingFailureReason>;
  readonly interceptedRequestsPerDomain: ReadonlyArray<TestFetchViaRenderingResultDomainInterceptedRequests>;
  readonly interceptedRequestsStuckInBrowser: ReadonlyArray<TestFetchViaRenderingResultInterceptedRequestStuckInBrowser>;
  readonly response: Maybe<TestFetchViaRenderingResultResponse>;
  readonly totalNumberOfInterceptedRequests: Scalars['Int']['output'];
};

export type TestFetchViaRenderingResultBlockedInterceptedRequest = {
  readonly __typename?: 'TestFetchViaRenderingResultBlockedInterceptedRequest';
  readonly reason: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
};

export type TestFetchViaRenderingResultDomainInterceptedRequests = {
  readonly __typename?: 'TestFetchViaRenderingResultDomainInterceptedRequests';
  readonly domain: Scalars['String']['output'];
  readonly numberOfRequests: Scalars['Int']['output'];
};

export type TestFetchViaRenderingResultInterceptedRequestStuckInBrowser = {
  readonly __typename?: 'TestFetchViaRenderingResultInterceptedRequestStuckInBrowser';
  readonly isResponseBodyJson: Maybe<Scalars['Boolean']['output']>;
  readonly method: Scalars['String']['output'];
  readonly responseHttpHeaders: Maybe<ReadonlyArray<TestFetchViaRenderingResultResponseHttpHeaderValues>>;
  readonly responseStatusCode: Maybe<Scalars['Int']['output']>;
  readonly url: Scalars['String']['output'];
};

export type TestFetchViaRenderingResultResponse = {
  readonly __typename?: 'TestFetchViaRenderingResultResponse';
  readonly body: Scalars['String']['output'];
  readonly dom: Maybe<Scalars['String']['output']>;
  readonly httpHeaders: ReadonlyArray<TestFetchViaRenderingResultResponseHttpHeaderValues>;
  readonly statusCode: Scalars['Int']['output'];
};

export type TestFetchViaRenderingResultResponseHttpHeaderValues = {
  readonly __typename?: 'TestFetchViaRenderingResultResponseHttpHeaderValues';
  readonly headerName: Scalars['String']['output'];
  readonly values: ReadonlyArray<Scalars['String']['output']>;
};

export type TestFetchViaRenderingSettings = {
  readonly cookies: InputMaybe<ReadonlyArray<UpdateWebsiteMonitoringCookiesCookie>>;
  readonly domSizeLimit: InputMaybe<Scalars['Int']['input']>;
  readonly domStabilizationPeriod: InputMaybe<Scalars['Int']['input']>;
  readonly fetchingLocation: InputMaybe<FetchingLocation>;
  readonly httpHeaders: InputMaybe<ReadonlyArray<UpdateWebsiteMonitoringHttpHeadersHttpHeader>>;
  readonly ipPool: InputMaybe<FetchingIpPool>;
  readonly isBlacklistDisabled: InputMaybe<Scalars['Boolean']['input']>;
  readonly lighthouseChromiumResourceCacheSizeLimit: InputMaybe<Scalars['Int']['input']>;
  readonly lighthouseFcpTimeout: InputMaybe<Scalars['Int']['input']>;
  readonly mainRequestSizeLimit: InputMaybe<Scalars['Int']['input']>;
  readonly mainRequestTimeout: InputMaybe<Scalars['Int']['input']>;
  readonly onPageRequestsBlockingMode: InputMaybe<OnPageRequestsBlockingMode>;
  readonly onPageRequestsBlockingRules: InputMaybe<Scalars['Array']['input']>;
  readonly onPageRequestsLimitLighthousePerDomain: InputMaybe<Scalars['Int']['input']>;
  readonly onPageRequestsLimitLighthouseTotal: InputMaybe<Scalars['Int']['input']>;
  readonly onPageRequestsLimitNormalPerDomain: InputMaybe<Scalars['Int']['input']>;
  readonly onPageRequestsLimitNormalTotal: InputMaybe<Scalars['Int']['input']>;
  readonly onPageRequestsWhitelistRules: InputMaybe<Scalars['Array']['input']>;
  readonly userAgentSettings: InputMaybe<UserAgentSettingsInput>;
};

export enum UnreachableStatus {
  Connect = 'connect',
  EmptyResponse = 'empty_response',
  LighthouseFailed = 'lighthouse_failed',
  SizeLimit = 'size_limit',
  TimeLimit = 'time_limit',
  Unspecified = 'unspecified'
}

export type UnreachableTypeData = {
  readonly __typename?: 'UnreachableTypeData';
  readonly reason: UnreachableStatus;
};

export type UnstageDomainsFromValidationSessionResult = MutationResult & {
  readonly __typename?: 'UnstageDomainsFromValidationSessionResult';
  readonly query: Query;
  readonly stagingArea: StagingAreaOfDomainsValidationSession;
};

export type UnstageEmailsFromValidationSessionResult = MutationResult & {
  readonly __typename?: 'UnstageEmailsFromValidationSessionResult';
  readonly query: Query;
  readonly stagingArea: StagingAreaOfInvitationsValidationSession;
};

export type UpdateSegmentResult = MutationResult & {
  readonly __typename?: 'UpdateSegmentResult';
  readonly query: Query;
  readonly updatedPageSegment: PageSegment;
};

export type UpdateSendingOfAlertsAndReportsFromWebsiteInvitation = {
  readonly alerts: AlertEmailsSetup;
  readonly id: Scalars['InvitationId']['input'];
  readonly reports: Scalars['Boolean']['input'];
};

export type UpdateSendingOfAlertsAndReportsFromWebsiteUser = {
  readonly alerts: AlertEmailsSetup;
  readonly email: Scalars['String']['input'];
  readonly reports: Scalars['Boolean']['input'];
};

export type UpdateSendingOfAlertsAndReportsToInvitedUserWebsite = {
  readonly alerts: Scalars['String']['input'];
  readonly id: Scalars['WebsiteId']['input'];
  readonly reports: Scalars['Boolean']['input'];
};

export type UpdateSendingOfAlertsAndReportsToUserWebsite = {
  readonly alerts: AlertEmailsSetup;
  readonly id: Scalars['WebsiteId']['input'];
  readonly reports: Scalars['Boolean']['input'];
};

export type UpdateWebsiteMonitoringCookiesCookie = {
  readonly name: Scalars['String']['input'];
  readonly value: Scalars['String']['input'];
};

export type UpdateWebsiteMonitoringHttpHeadersHttpHeader = {
  readonly name: Scalars['String']['input'];
  readonly value: Scalars['String']['input'];
};

export enum UrlBlocklistMode {
  AllowAllExcept = 'AllowAllExcept',
  DenyAllExcept = 'DenyAllExcept'
}

export type UrlBlocklistRule = {
  readonly __typename?: 'UrlBlocklistRule';
  readonly operator: UrlBlocklistRuleOperator;
  readonly value: Scalars['String']['output'];
};

export type UrlBlocklistRuleInput = {
  readonly operator: UrlBlocklistRuleOperator;
  readonly value: Scalars['String']['input'];
};

export enum UrlBlocklistRuleOperator {
  Contains = 'Contains',
  EndsWith = 'EndsWith',
  Equals = 'Equals',
  Matches = 'Matches',
  NotContains = 'NotContains',
  NotMatches = 'NotMatches',
  StartsWith = 'StartsWith'
}

export type UrlIndexStatus = {
  readonly __typename?: 'UrlIndexStatus';
  readonly page: Maybe<Page>;
  readonly url: Scalars['String']['output'];
  readonly urlIsExcluded: Maybe<Scalars['Error']['output']>;
  readonly urlIsInvalid: Maybe<Scalars['Error']['output']>;
  readonly urlIsUndiscovered: Maybe<Scalars['Error']['output']>;
  readonly websiteNotFound: Maybe<Scalars['Error']['output']>;
};

export type User = {
  readonly __typename?: 'User';
  readonly customerTeamContext: Maybe<CustomerTeamContext>;
  readonly displayName: Scalars['String']['output'];
  readonly email: Scalars['String']['output'];
  readonly firstName: Maybe<Scalars['String']['output']>;
  readonly hasPassword: Maybe<Scalars['Boolean']['output']>;
  readonly hasTwoFactorAuthentication: Scalars['Boolean']['output'];
  readonly hiddenDashboardScopes: ReadonlyArray<Scalars['Array']['output']>;
  readonly id: Scalars['String']['output'];
  readonly initials: Scalars['String']['output'];
  readonly intercomLink: Maybe<Scalars['String']['output']>;
  readonly isAlertRecipientByDefault: Scalars['Boolean']['output'];
  readonly isAllowedToManageMonitoringSettings: Scalars['Boolean']['output'];
  readonly isChromeExtensionInstalled: Scalars['Boolean']['output'];
  readonly isEligibleForRoyalAccess: Scalars['Boolean']['output'];
  readonly isOnboardingTourInProgress: Scalars['Boolean']['output'];
  readonly isPlatformTourWebinarOfferingAvailable: Scalars['Boolean']['output'];
  readonly isReportRecipientByDefault: Scalars['Boolean']['output'];
  readonly isSubscribedToFeatureAnnouncementEmails: Scalars['Boolean']['output'];
  readonly isSubscribedToNewsletterEmails: Scalars['Boolean']['output'];
  readonly isSubscribedToOnboardingEmails: Scalars['Boolean']['output'];
  readonly isSubscribedToServiceMessageEmails: Scalars['Boolean']['output'];
  readonly jobTitle: Maybe<Scalars['String']['output']>;
  readonly lastName: Maybe<Scalars['String']['output']>;
  readonly legacyId: Scalars['String']['output'];
  readonly locale: Scalars['String']['output'];
  readonly mainAccountId: Maybe<Scalars['AccountId']['output']>;
  readonly managedActions: ReadonlyArray<ActionWithUser>;
  readonly managedActionsForKingdomAdmin: ReadonlyArray<ActionWithUser>;
  readonly memberships: ReadonlyArray<AccountMembership>;
  readonly organizationTeam: Maybe<Scalars['String']['output']>;
  readonly pageDetailSourceMode: PageDetailSourceMode;
  readonly permittedActions: ReadonlyArray<ActionWithUser>;
  readonly permittedActionsForKingdomAdmin: Maybe<ReadonlyArray<ActionWithUser>>;
  readonly personalTweaks: Maybe<UserPersonalTweaks>;
  readonly platformTourWebinarOffering: PlatformTourWebinarOffering;
  readonly reviewSites: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly royalGroup: Maybe<RoyalGroup>;
  readonly segmentsDisplayOrder: Maybe<ReadonlyArray<SegmentIdentifier>>;
  readonly signature: Scalars['String']['output'];
  readonly uniqueId: Scalars['UserId']['output'];
  readonly websiteEmailSettings: ReadonlyArray<WebsiteEmailSettings>;
};

export type UserAgent = {
  readonly __typename?: 'UserAgent';
  readonly headerValue: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
};

export type UserAgentOption = {
  readonly __typename?: 'UserAgentOption';
  readonly headerValue: Scalars['String']['output'];
  readonly isBranded: Scalars['Boolean']['output'];
  readonly type: Scalars['String']['output'];
};

export type UserAgentSettings = {
  readonly __typename?: 'UserAgentSettings';
  readonly customValue: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
};

export type UserAgentSettingsInput = {
  readonly customValue: InputMaybe<Scalars['String']['input']>;
  readonly type: Scalars['String']['input'];
};

export type UserPersonalTweaks = {
  readonly __typename?: 'UserPersonalTweaks';
  readonly hiddenDashboardScopes: ReadonlyArray<Scalars['Array']['output']>;
  readonly pageDetailSourceMode: PageDetailSourceMode;
};

export enum UserRole {
  Admin = 'admin',
  Editor = 'editor',
  Manager = 'manager',
  Viewer = 'viewer'
}

export type UserWebsiteAccess = {
  readonly __typename?: 'UserWebsiteAccess';
  readonly id: Scalars['ID']['output'];
  readonly managedActions: ReadonlyArray<ActionWithUserWebsiteAccess>;
  readonly managedActionsForKingdomAdmin: ReadonlyArray<ActionWithUserWebsiteAccess>;
  readonly permittedActions: ReadonlyArray<ActionWithUserWebsiteAccess>;
  readonly permittedActionsForKingdomAdmin: Maybe<ReadonlyArray<ActionWithUserWebsiteAccess>>;
  readonly user: User;
  readonly website: Website;
};

export type ValidateConductorOrganizationConnectionResult = MutationResult & {
  readonly __typename?: 'ValidateConductorOrganizationConnectionResult';
  readonly addedConductorUsers: ReadonlyArray<ValidateConductorOrganizationConnectionResultAddedUser>;
  readonly conductorOrganization: ConductorOrganization;
  readonly keptUsers: ReadonlyArray<AccountMembership>;
  readonly query: Query;
  readonly removedUsers: ReadonlyArray<User>;
  readonly stayingUsers: ReadonlyArray<User>;
};

export type ValidateConductorOrganizationConnectionResultAddedUser = {
  readonly __typename?: 'ValidateConductorOrganizationConnectionResultAddedUser';
  readonly email: Scalars['String']['output'];
  readonly userRole: UserRole;
};

export type ValidateSchemaOrgResult = MutationResult & {
  readonly __typename?: 'ValidateSchemaOrgResult';
  readonly errors: ReadonlyArray<Scalars['Any']['output']>;
  readonly query: Query;
};

export type VisualAnalyticServiceItemsCollection = {
  readonly __typename?: 'VisualAnalyticServiceItemsCollection';
  readonly contentItems: ReadonlyArray<NullableContentItem>;
  readonly type: VisualAnalyticServiceType;
};

export enum VisualAnalyticServiceType {
  Clicktale = 'clicktale',
  Clicky = 'clicky',
  Contentsquare = 'contentsquare',
  CrazyEgg = 'crazy_egg',
  Hotjar = 'hotjar',
  Inspectlet = 'inspectlet',
  MicrosoftClarity = 'microsoft_clarity',
  Mouseflow = 'mouseflow',
  Smartlook = 'smartlook'
}

export enum WebVitalRange {
  Bad = 'bad',
  Good = 'good',
  NeedsImprovement = 'needsImprovement'
}

export enum WebVitalVerdict {
  Average = 'Average',
  Fast = 'Fast',
  None = 'None',
  Slow = 'Slow'
}

export type WebVitalsOriginMetric = {
  readonly __typename?: 'WebVitalsOriginMetric';
  readonly maximumFast: Scalars['Int']['output'];
  readonly maximumGood: Scalars['Int']['output'];
  readonly percentageFast: Scalars['Percentage']['output'];
  readonly percentageGood: Scalars['Percentage']['output'];
  readonly percentageSlow: Scalars['Percentage']['output'];
  readonly percentile: Scalars['Int']['output'];
  readonly verdict: WebVitalVerdict;
};

export type WebVitalsOriginSummaryData = {
  readonly __typename?: 'WebVitalsOriginSummaryData';
  readonly coreWebVitalsAssessment: CoreWebVitalsAssessment;
  readonly cumulativeLayoutShiftScore: Maybe<WebVitalsOriginMetric>;
  readonly dateRange: DateRange;
  readonly firstContentfulPaint: Maybe<WebVitalsOriginMetric>;
  readonly firstInputDelay: Maybe<WebVitalsOriginMetric>;
  readonly interactionToNextPaint: Maybe<WebVitalsOriginMetric>;
  readonly isCoreWebVitalsAssessmentPassed: Scalars['Boolean']['output'];
  readonly largestContentfulPaint: Maybe<WebVitalsOriginMetric>;
};

export type Website = {
  readonly __typename?: 'Website';
  readonly account: Maybe<Account>;
  readonly adminFetchingSettings: Maybe<WebsiteAdminFetchingSettings>;
  readonly adminSettings: Maybe<WebsiteAdminSettings>;
  readonly adobeAnalyticsIntegration: Maybe<WebsiteAdobeAnalyticsIntegration>;
  readonly aggregateHistoricalComparisons: Maybe<ReadonlyArray<WebsiteAggregateHistoricalComparison>>;
  readonly alertDefinitions: ReadonlyArray<AlertDefinition>;
  readonly conductorWebsiteId: Maybe<Scalars['String']['output']>;
  readonly cookies: ReadonlyArray<WebsiteFetchingSettingsCookie>;
  readonly crawlingDiagnose: Maybe<WebsiteCrawlingDiagnose>;
  readonly customElements: ReadonlyArray<CustomElement>;
  readonly customerTeamContext: Maybe<CustomerTeamContext>;
  readonly dashboardData: Maybe<WebsiteDashboardData>;
  readonly displayName: Scalars['String']['output'];
  readonly domain: Scalars['String']['output'];
  readonly enrichmentFields: ReadonlyArray<EnrichmentField>;
  readonly fetchingLocation: FetchingLocation;
  readonly googleAnalyticsIntegration: Maybe<WebsiteGoogleAnalyticsIntegration>;
  readonly googleAnalyticsVisibleColumns: ReadonlyArray<GoogleAnalyticsVersion>;
  readonly googleSearchConsoleUsedProperties: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly grafanaLink: Maybe<Scalars['String']['output']>;
  readonly hasWebVitalsOriginSummaryData: Scalars['Boolean']['output'];
  readonly health: Maybe<Scalars['Int']['output']>;
  readonly historicalIssuesComparison: Maybe<WebsiteHistoricalIssuesComparison>;
  readonly httpHeaders: ReadonlyArray<WebsiteFetchingSettingsHttpHeader>;
  readonly id: Scalars['WebsiteId']['output'];
  readonly indexNowCustomElementTriggers: ReadonlyArray<Scalars['String']['output']>;
  readonly indexNowIssuesRequiredToPass: ReadonlyArray<Scalars['String']['output']>;
  readonly indexNowNativeTriggers: ReadonlyArray<Scalars['String']['output']>;
  readonly indexNowSegmentsBlocklistAllowedSegments: ReadonlyArray<Scalars['String']['output']>;
  readonly indexNowSegmentsBlocklistDisallowedSegments: ReadonlyArray<Scalars['String']['output']>;
  readonly indexNowSegmentsBlocklistMode: IndexNowSegmentsBlocklistMode;
  readonly indexNowUrlBlocklistMode: UrlBlocklistMode;
  readonly indexNowUrlBlocklistRules: ReadonlyArray<UrlBlocklistRule>;
  readonly integrations: ReadonlyArray<WebsiteIntegration>;
  readonly isArchived: Scalars['Boolean']['output'];
  readonly isCrawlingPaused: Scalars['Boolean']['output'];
  readonly isDiscovered: Scalars['Boolean']['output'];
  readonly isDomTracked: Scalars['Boolean']['output'];
  readonly isHttpAuthenticationEnabled: Scalars['Boolean']['output'];
  readonly isIndexNowEnabled: Scalars['Boolean']['output'];
  readonly isLighthouseMonitored: Scalars['Boolean']['output'];
  readonly isLogFileAnalysisEnabled: Scalars['Boolean']['output'];
  readonly isTrackingOfH2ToH6HistoricallyEnabled: Scalars['Boolean']['output'];
  readonly isTrackingOfNumericOnlyChangesEnabled: Scalars['Boolean']['output'];
  readonly isVerified: Scalars['Boolean']['output'];
  readonly lighthouseThresholds: WebsiteLighthouseThresholds;
  readonly logFileAnalysisForeignAwsAccountId: Maybe<Scalars['String']['output']>;
  readonly logFileAnalysisS3Buckets: ReadonlyArray<Maybe<LogFileAnalysisS3Bucket>>;
  readonly logSources: ReadonlyArray<LogSource>;
  readonly logo: Maybe<Scalars['String']['output']>;
  readonly logoUrl: Maybe<Scalars['String']['output']>;
  readonly managedActions: ReadonlyArray<ActionWithWebsite>;
  readonly managedActionsForKingdomAdmin: ReadonlyArray<ActionWithWebsite>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly onPageRequestsBlockingMode: OnPageRequestsBlockingMode;
  readonly onPageRequestsBlockingRules: ReadonlyArray<OnPageRequestsBlockingRule>;
  readonly pageCapacity: Scalars['Int']['output'];
  readonly pageCapacityUsageDescription: WebsitePageCapacityUsageDescription;
  readonly pageCapacityUsageRatio: Scalars['Int']['output'];
  readonly pageSegments: ReadonlyArray<PageSegment>;
  readonly pageTotal: Maybe<Scalars['Int']['output']>;
  readonly pagesCount: Scalars['Int']['output'];
  readonly permittedActions: ReadonlyArray<ActionWithWebsite>;
  readonly permittedActionsForKingdomAdmin: Maybe<ReadonlyArray<ActionWithWebsite>>;
  readonly problem: Maybe<WebsiteProblem>;
  readonly relevantSearchEngines: ReadonlyArray<Scalars['String']['output']>;
  readonly scenariosIllustratingAlertSensitivity: ReadonlyArray<ScenarioIllustratingAlertSensitivity>;
  readonly scopeIssues: ReadonlyArray<WebsiteScopeIssues>;
  readonly scrapbookLink: Maybe<Scalars['String']['output']>;
  readonly suggestedGoogleAnalyticsAccount: SuggestedGoogleAnalyticsAccount;
  readonly suggestedUrlExclusionListRules: ReadonlyArray<Scalars['String']['output']>;
  readonly timeCreatedAt: Scalars['Timestamp']['output'];
  readonly urlExclusionList: WebsiteUrlExclusionList;
  readonly userAccess: ReadonlyArray<UserWebsiteAccess>;
  readonly userAgentOptions: ReadonlyArray<UserAgentOption>;
  readonly verificationMethods: ReadonlyArray<WebsiteVerificationMethod>;
  readonly webPropertyGroupId: Maybe<Scalars['Int']['output']>;
};


export type WebsiteAggregateHistoricalComparisonsArgs = {
  scopes: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  since: Scalars['DateYMD']['input'];
  until: Scalars['DateYMD']['input'];
};


export type WebsiteHistoricalIssuesComparisonArgs = {
  scope: Scalars['String']['input'];
  since: Scalars['DateYMD']['input'];
  until: Scalars['DateYMD']['input'];
};


export type WebsiteLogFileAnalysisS3BucketsArgs = {
  logSource: LogSourceProvider;
};

export type WebsiteAdminFetchingSettings = {
  readonly __typename?: 'WebsiteAdminFetchingSettings';
  readonly domSizeLimit: Maybe<Scalars['Int']['output']>;
  readonly ipPools: ReadonlyArray<FetchingIpPool>;
  readonly isBlacklistDisabled: Scalars['Boolean']['output'];
  readonly lighthouseChromiumResourceCacheSizeLimit: Maybe<Scalars['Int']['output']>;
  readonly lighthouseFcpTimeout: Maybe<Scalars['Int']['output']>;
  readonly mainRequestSizeLimit: Maybe<Scalars['Int']['output']>;
  readonly mainRequestTimeout: Maybe<Scalars['Int']['output']>;
  readonly onPageRequestsLimitLighthousePerDomain: Maybe<Scalars['Int']['output']>;
  readonly onPageRequestsLimitLighthouseTotal: Maybe<Scalars['Int']['output']>;
  readonly onPageRequestsLimitNormalPerDomain: Maybe<Scalars['Int']['output']>;
  readonly onPageRequestsLimitNormalTotal: Maybe<Scalars['Int']['output']>;
};

export type WebsiteAdminSettings = {
  readonly __typename?: 'WebsiteAdminSettings';
  readonly analyticsServicesDetectionSource: Scalars['String']['output'];
  readonly areOtherSearchEnginesNotRelevant: Scalars['Boolean']['output'];
  readonly attachedWebPropertyGroupIds: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly collectDetailedDiagnostics: Scalars['Boolean']['output'];
  readonly detectedContentDeliveryNetwork: DetectedContentDeliveryNetwork;
  readonly discoverAmpPages: Scalars['Boolean']['output'];
  readonly domSizeLimit: Maybe<Scalars['Int']['output']>;
  readonly domStabilizationPeriod: Maybe<Scalars['Int']['output']>;
  readonly fetchingLogLevel: Scalars['String']['output'];
  readonly ipPools: ReadonlyArray<FetchingIpPool>;
  readonly isBlacklistDisabled: Scalars['Boolean']['output'];
  readonly isDiscoveringNewUrlsEnabled: Scalars['Boolean']['output'];
  readonly isShadowDomFlattened: Scalars['Boolean']['output'];
  readonly lighthouseChromiumResourceCacheSizeLimit: Maybe<Scalars['Int']['output']>;
  readonly lighthouseFcpTimeout: Maybe<Scalars['Int']['output']>;
  readonly mainRequestSizeLimit: Maybe<Scalars['Int']['output']>;
  readonly mainRequestTimeout: Maybe<Scalars['Int']['output']>;
  readonly onPageRequestsLimitLighthousePerDomain: Maybe<Scalars['Int']['output']>;
  readonly onPageRequestsLimitLighthouseTotal: Maybe<Scalars['Int']['output']>;
  readonly onPageRequestsLimitNormalPerDomain: Maybe<Scalars['Int']['output']>;
  readonly onPageRequestsLimitNormalTotal: Maybe<Scalars['Int']['output']>;
  readonly onPageRequestsWhitelistRules: ReadonlyArray<OnPageRequestsBlockingRule>;
  readonly pagePropertiesWithDisabledHistoricalTracking: ReadonlyArray<Scalars['String']['output']>;
  readonly verification: Scalars['String']['output'];
};

export type WebsiteAdobeAnalyticsIntegration = {
  readonly __typename?: 'WebsiteAdobeAnalyticsIntegration';
  readonly adobeAnalyticsAccount: AdobeAnalyticsAccount;
  readonly dataMatchingMethod: AdobeAnalyticsDataMatchingMethod;
  readonly dimensionId: Scalars['String']['output'];
  readonly ownerAccountId: Scalars['AccountId']['output'];
  readonly reportSuiteId: Scalars['String']['output'];
};

export type WebsiteAggregateHistoricalComparison = {
  readonly __typename?: 'WebsiteAggregateHistoricalComparison';
  readonly numberOfIssuesOpenedOnAddedPages: Scalars['Int']['output'];
  readonly numberOfIssuesOpenedOnExistingPages: Scalars['Int']['output'];
  readonly numberOfResolvedIssues: Scalars['Int']['output'];
  readonly scope: Scalars['String']['output'];
  readonly since: Scalars['DateYMD']['output'];
  readonly snapshotAfter: Maybe<WebsiteAggregateHistoricalSnapshot>;
  readonly snapshotBefore: Maybe<WebsiteAggregateHistoricalSnapshot>;
  readonly until: Scalars['DateYMD']['output'];
};

export type WebsiteAggregateHistoricalSnapshot = {
  readonly __typename?: 'WebsiteAggregateHistoricalSnapshot';
  readonly health: Maybe<Scalars['Int']['output']>;
};

export type WebsiteCrawlingDiagnose = {
  readonly __typename?: 'WebsiteCrawlingDiagnose';
  readonly countOfSpecialUrlsDelayedLittle: Scalars['Int']['output'];
  readonly countOfSpecialUrlsDelayedLot: Scalars['Int']['output'];
  readonly countOfSpecialUrlsDelayedMedium: Scalars['Int']['output'];
  readonly countOfSpecialUrlsPlannedInFuture: Scalars['Int']['output'];
  readonly countOfSpecialUrlsReadyForCrawling: Scalars['Int']['output'];
  readonly countOfUrlsDelayedLittle: Scalars['Int']['output'];
  readonly countOfUrlsDelayedLot: Scalars['Int']['output'];
  readonly countOfUrlsDelayedMedium: Scalars['Int']['output'];
  readonly countOfUrlsForDiscovery: Scalars['Int']['output'];
  readonly countOfUrlsNotPlanned: Scalars['Int']['output'];
  readonly countOfUrlsPlannedInFuture: Scalars['Int']['output'];
  readonly countOfUrlsPlannedInInfiniteFuture: Scalars['Int']['output'];
  readonly countOfUrlsPrioritizedDueToHeuristics: Scalars['Int']['output'];
  readonly countOfUrlsPrioritizedManually: Scalars['Int']['output'];
  readonly countOfUrlsReadyForCrawling: Scalars['Int']['output'];
};

export type WebsiteDashboardData = {
  readonly __typename?: 'WebsiteDashboardData';
  readonly distributionOfIsIndexable: WebsiteDashboardDistributionOfIsIndexable;
  readonly distributionOfPageType: WebsiteDashboardDistributionOfPageType;
  readonly health: WebsiteDashboardHealth;
  readonly healthChanges: WebsiteDashboardHealthChanges;
  readonly issuesChanges: WebsiteDashboardIssuesChanges;
  readonly numberOfActiveIncidents: WebsiteDashboardNumberOfActiveIncidents;
  readonly numberOfIssues: WebsiteDashboardNumberOfIssues;
  readonly numberOfPages: WebsiteDashboardNumberOfPages;
  readonly pageChanges: WebsiteDashboardPageChanges;
  readonly webVitalsOriginSummaryData: WebsiteDashboardWebVitalsOriginSummaryData;
};


export type WebsiteDashboardDataDistributionOfIsIndexableArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
};


export type WebsiteDashboardDataDistributionOfPageTypeArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
};


export type WebsiteDashboardDataHealthArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
};


export type WebsiteDashboardDataHealthChangesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
};


export type WebsiteDashboardDataIssuesChangesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
};


export type WebsiteDashboardDataNumberOfActiveIncidentsArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
};


export type WebsiteDashboardDataNumberOfIssuesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
};


export type WebsiteDashboardDataNumberOfPagesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
};


export type WebsiteDashboardDataPageChangesArgs = {
  endDate: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  startDate: Scalars['DateYMD']['input'];
};


export type WebsiteDashboardDataWebVitalsOriginSummaryDataArgs = {
  date: Scalars['DateYMD']['input'];
  scope: Scalars['String']['input'];
  websiteId: Scalars['WebsiteId']['input'];
};

export type WebsiteDashboardDistributionOfIsIndexable = {
  readonly __typename?: 'WebsiteDashboardDistributionOfIsIndexable';
  readonly data: Maybe<ReadonlyArray<WebsiteDashboardDistributionOfIsIndexableData>>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteDashboardDistributionOfIsIndexableData = {
  readonly __typename?: 'WebsiteDashboardDistributionOfIsIndexableData';
  readonly numberOfPages: Scalars['Int']['output'];
  readonly percentageOfPages: Scalars['Percentage']['output'];
  readonly state: Scalars['Boolean']['output'];
};

export type WebsiteDashboardDistributionOfPageType = {
  readonly __typename?: 'WebsiteDashboardDistributionOfPageType';
  readonly data: Maybe<ReadonlyArray<WebsiteDashboardDistributionOfPageTypeData>>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteDashboardDistributionOfPageTypeData = {
  readonly __typename?: 'WebsiteDashboardDistributionOfPageTypeData';
  readonly numberOfPages: Scalars['Int']['output'];
  readonly pageType: PageType;
  readonly percentageOfPages: Scalars['Percentage']['output'];
};

export type WebsiteDashboardHealth = {
  readonly __typename?: 'WebsiteDashboardHealth';
  readonly data: Maybe<WebsiteDashboardHealthData>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteDashboardHealthChanges = {
  readonly __typename?: 'WebsiteDashboardHealthChanges';
  readonly data: Maybe<ReadonlyArray<WebsiteDashboardHealthChangesData>>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteDashboardHealthChangesData = {
  readonly __typename?: 'WebsiteDashboardHealthChangesData';
  readonly date: Scalars['DateYMD']['output'];
  readonly health: Maybe<Scalars['Int']['output']>;
};

export type WebsiteDashboardHealthData = {
  readonly __typename?: 'WebsiteDashboardHealthData';
  readonly health: Scalars['Int']['output'];
};

export type WebsiteDashboardIssuesChange = {
  readonly __typename?: 'WebsiteDashboardIssuesChange';
  readonly changeType: PageIssueChangeType;
  readonly numberOfIssues: Scalars['Int']['output'];
};

export type WebsiteDashboardIssuesChanges = {
  readonly __typename?: 'WebsiteDashboardIssuesChanges';
  readonly data: Maybe<ReadonlyArray<WebsiteDashboardIssuesChangesData>>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteDashboardIssuesChangesData = {
  readonly __typename?: 'WebsiteDashboardIssuesChangesData';
  readonly changes: ReadonlyArray<WebsiteDashboardIssuesChange>;
  readonly endDate: Scalars['DateYMD']['output'];
  readonly startDate: Scalars['DateYMD']['output'];
};

export type WebsiteDashboardNumberOfActiveIncidents = {
  readonly __typename?: 'WebsiteDashboardNumberOfActiveIncidents';
  readonly data: Maybe<WebsiteDashboardNumberOfActiveIncidentsData>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteDashboardNumberOfActiveIncidentsData = {
  readonly __typename?: 'WebsiteDashboardNumberOfActiveIncidentsData';
  readonly numberOfActiveIncidentsRegardlessOfScope: Scalars['Int']['output'];
  readonly numberOfActiveIncidentsWithinScope: Scalars['Int']['output'];
};

export type WebsiteDashboardNumberOfIssues = {
  readonly __typename?: 'WebsiteDashboardNumberOfIssues';
  readonly data: Maybe<WebsiteDashboardNumberOfIssuesData>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteDashboardNumberOfIssuesData = {
  readonly __typename?: 'WebsiteDashboardNumberOfIssuesData';
  readonly deltaFromStartDate: Maybe<Scalars['Int']['output']>;
  readonly numberOfIssuesOnEndDate: Scalars['Int']['output'];
};

export type WebsiteDashboardNumberOfPages = {
  readonly __typename?: 'WebsiteDashboardNumberOfPages';
  readonly data: Maybe<WebsiteDashboardNumberOfPagesData>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteDashboardNumberOfPagesData = {
  readonly __typename?: 'WebsiteDashboardNumberOfPagesData';
  readonly deltaFromStartDate: Maybe<Scalars['Int']['output']>;
  readonly numberOfPagesOnEndDate: Scalars['Int']['output'];
};

export type WebsiteDashboardPageChange = {
  readonly __typename?: 'WebsiteDashboardPageChange';
  readonly changeType: PageChangeType;
  readonly numberOfPages: Scalars['Int']['output'];
};

export type WebsiteDashboardPageChanges = {
  readonly __typename?: 'WebsiteDashboardPageChanges';
  readonly data: Maybe<ReadonlyArray<WebsiteDashboardPageChangesData>>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteDashboardPageChangesData = {
  readonly __typename?: 'WebsiteDashboardPageChangesData';
  readonly changes: ReadonlyArray<WebsiteDashboardPageChange>;
  readonly endDate: Scalars['DateYMD']['output'];
  readonly startDate: Scalars['DateYMD']['output'];
};

export enum WebsiteDashboardReasonForNoData {
  DataAvailableOnlyForWebsiteScope = 'dataAvailableOnlyForWebsiteScope',
  DataGotTrackedLater = 'dataGotTrackedLater',
  FeatureNotSupported = 'featureNotSupported',
  SegmentCannotContainPages = 'segmentCannotContainPages',
  SegmentContainsNonPagesOnly = 'segmentContainsNonPagesOnly',
  SegmentDoesNotExist = 'segmentDoesNotExist',
  SegmentIsEmpty = 'segmentIsEmpty',
  SegmentWasAddedLater = 'segmentWasAddedLater',
  WebVitalsOriginSummaryDataNotDownloadedYet = 'webVitalsOriginSummaryDataNotDownloadedYet',
  WebVitalsOriginSummaryNotFound = 'webVitalsOriginSummaryNotFound',
  WebsiteContainsNonPagesOnly = 'websiteContainsNonPagesOnly',
  WebsiteIsEmpty = 'websiteIsEmpty',
  WebsiteWasAddedLater = 'websiteWasAddedLater'
}

export type WebsiteDashboardWebVitalsOriginSummaryData = {
  readonly __typename?: 'WebsiteDashboardWebVitalsOriginSummaryData';
  readonly data: Maybe<WebVitalsOriginSummaryData>;
  readonly id: Scalars['ID']['output'];
  readonly pollInterval: Maybe<Scalars['Int']['output']>;
  readonly reasonForNoData: Maybe<WebsiteDashboardReasonForNoData>;
};

export type WebsiteEmailSettings = {
  readonly __typename?: 'WebsiteEmailSettings';
  readonly alertEmailsSetup: AlertEmailsSetup;
  readonly isWeeklyReportEnabled: Scalars['Boolean']['output'];
  readonly websiteId: Scalars['WebsiteId']['output'];
};

export type WebsiteFetchingSettingsCookie = {
  readonly __typename?: 'WebsiteFetchingSettingsCookie';
  readonly name: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

export type WebsiteFetchingSettingsHttpHeader = {
  readonly __typename?: 'WebsiteFetchingSettingsHttpHeader';
  readonly name: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

export type WebsiteGoogleAnalyticsIntegration = {
  readonly __typename?: 'WebsiteGoogleAnalyticsIntegration';
  readonly googleAnalyticsAccount: GoogleAnalyticsAccount;
  readonly ownerAccountId: Scalars['AccountId']['output'];
  readonly propertyId: Scalars['String']['output'];
  readonly version: GoogleAnalyticsVersion;
  readonly viewId: Maybe<Scalars['String']['output']>;
};

export type WebsiteHistoricalIssuesComparison = {
  readonly __typename?: 'WebsiteHistoricalIssuesComparison';
  readonly issues: Scalars['Array']['output'];
  readonly scope: Scalars['String']['output'];
};

export type WebsiteIncident = {
  readonly __typename?: 'WebsiteIncident';
  readonly closedAt: Maybe<Scalars['Timestamp']['output']>;
  readonly feedback: FeedbackOpportunity;
  readonly id: Scalars['ID']['output'];
  readonly incidentId: Scalars['Int']['output'];
  readonly lastEvolvedAt: Scalars['Timestamp']['output'];
  readonly openedAt: Scalars['Timestamp']['output'];
  readonly phase: WebsiteIncidentPhase;
  readonly scope: Scalars['String']['output'];
  readonly settings: Scalars['Array']['output'];
  readonly state: Scalars['Array']['output'];
  readonly type: Scalars['String']['output'];
  readonly website: Website;
};

export enum WebsiteIncidentPhase {
  Active = 'active',
  Past = 'past'
}

export type WebsiteIntegration = {
  readonly __typename?: 'WebsiteIntegration';
  readonly isNotOwned: Scalars['Boolean']['output'];
  readonly status: WebsiteIntegrationStatus;
  readonly type: WebsiteIntegrationType;
};

export enum WebsiteIntegrationStatus {
  Available = 'Available',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  FeatureNotEnabled = 'FeatureNotEnabled',
  NotAvailable = 'NotAvailable'
}

export enum WebsiteIntegrationType {
  AdobeAnalytics = 'AdobeAnalytics',
  GoogleAnalytics = 'GoogleAnalytics',
  GoogleDataStudio = 'GoogleDataStudio',
  GoogleSearchConsole = 'GoogleSearchConsole',
  MicrosoftTeams = 'MicrosoftTeams',
  Slack = 'Slack',
  Wordpress = 'Wordpress'
}

export type WebsiteIssue = {
  readonly __typename?: 'WebsiteIssue';
  readonly affectedPagesPerState: Maybe<WebsiteIssueAffectedPagesPerState>;
  readonly configuration: Scalars['Array']['output'];
  readonly context: Maybe<Scalars['Any']['output']>;
  readonly ignoringRuleOnCases: Maybe<PageIssueIgnoringRuleOnCases>;
  readonly ignoringRuleOnPages: Maybe<PageIssueIgnoringRuleOnPages>;
  readonly ignoringRuleOnSegments: Maybe<PageIssueIgnoringRuleOnSegments>;
  readonly ignoringRuleOnWebsite: IssueIgnoringRuleOnWebsite;
  readonly isActionable: Scalars['Boolean']['output'];
  readonly isConfigurationModified: Scalars['Boolean']['output'];
  readonly isIgnored: Scalars['Boolean']['output'];
  readonly isPresentable: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly pointsGained: Scalars['Int']['output'];
  readonly pointsToGain: Scalars['Int']['output'];
  readonly state: IssueState;
};

export type WebsiteIssueAffectedPagesPerState = {
  readonly __typename?: 'WebsiteIssueAffectedPagesPerState';
  readonly absoluteNumberOfPages: WebsiteIssueAffectedPagesPerStateAbsoluteNumberOfPages;
  readonly percentage: WebsiteIssueAffectedPagesPerStatePercentage;
};

export type WebsiteIssueAffectedPagesPerStateAbsoluteNumberOfPages = {
  readonly __typename?: 'WebsiteIssueAffectedPagesPerStateAbsoluteNumberOfPages';
  readonly closed: Scalars['Int']['output'];
  readonly ignored: Scalars['Int']['output'];
  readonly notApplicable: Scalars['Int']['output'];
  readonly notRequired: Scalars['Int']['output'];
  readonly open: Scalars['Int']['output'];
  readonly unknown: Scalars['Int']['output'];
};

export type WebsiteIssueAffectedPagesPerStatePercentage = {
  readonly __typename?: 'WebsiteIssueAffectedPagesPerStatePercentage';
  readonly closed: Scalars['Float']['output'];
  readonly ignored: Scalars['Float']['output'];
  readonly notApplicable: Scalars['Float']['output'];
  readonly notRequired: Scalars['Float']['output'];
  readonly open: Scalars['Float']['output'];
  readonly unknown: Scalars['Float']['output'];
};

export type WebsiteIssueCategory = {
  readonly __typename?: 'WebsiteIssueCategory';
  readonly affectedPagesPerState: Maybe<WebsiteIssueAffectedPagesPerState>;
  readonly configuration: Scalars['Array']['output'];
  readonly defaultConfiguration: Scalars['Array']['output'];
  readonly isActionable: Scalars['Boolean']['output'];
  readonly isConfigurationModified: Scalars['Boolean']['output'];
  readonly isIgnored: Scalars['Boolean']['output'];
  readonly issues: ReadonlyArray<WebsiteIssue>;
  readonly name: Scalars['String']['output'];
  readonly pointsGained: Scalars['Int']['output'];
  readonly pointsToGain: Scalars['Int']['output'];
  readonly state: IssueCategoryState;
};

export type WebsiteLighthouseThresholds = {
  readonly __typename?: 'WebsiteLighthouseThresholds';
  readonly cls: ReadonlyArray<Scalars['Int']['output']>;
  readonly fcp: ReadonlyArray<Scalars['Int']['output']>;
  readonly lcp: ReadonlyArray<Scalars['Int']['output']>;
  readonly performance: ReadonlyArray<Scalars['Int']['output']>;
  readonly si: ReadonlyArray<Scalars['Int']['output']>;
  readonly tbt: ReadonlyArray<Scalars['Int']['output']>;
  readonly tti: ReadonlyArray<Scalars['Int']['output']>;
};

export enum WebsitePageCapacityUsageDescription {
  Near = 'Near',
  Reached = 'Reached',
  Within = 'Within'
}

export enum WebsiteProblem {
  CapacityReached = 'CapacityReached',
  NearCapacity = 'NearCapacity',
  Paused = 'Paused'
}

export type WebsiteScopeIssues = {
  readonly __typename?: 'WebsiteScopeIssues';
  readonly health: Scalars['Int']['output'];
  readonly issueCategories: ReadonlyArray<WebsiteIssueCategory>;
  readonly name: Scalars['String']['output'];
};

export type WebsiteUrlExclusionList = {
  readonly __typename?: 'WebsiteUrlExclusionList';
  readonly rules: ReadonlyArray<Scalars['String']['output']>;
};

export type WebsiteVerificationMethod = {
  readonly __typename?: 'WebsiteVerificationMethod';
  readonly type: WebsiteVerificationMethodType;
};

export enum WebsiteVerificationMethodType {
  CloudflareWorker = 'CloudflareWorker',
  GoogleSearchConsole = 'GoogleSearchConsole'
}

export enum WordpressEditLinkUnavailabilityReason {
  NotEditable = 'NotEditable',
  PluginNotActive = 'PluginNotActive',
  PluginNotInstalled = 'PluginNotInstalled'
}

}
export default GraphQL