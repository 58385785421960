import React from 'react';

import CK from '~/types/contentking';

import AlertPagesDatatable, {
	type AlertPagesDatatableIncident,
	type AlertPagesDatatableRef,
} from '~/components/logic/alertPages/AlertPagesDatatable';



type Props = {
	incident: AlertPagesDatatableIncident,
	scrollCallback: () => void,
};

const PagesEnteredOrLeftSegmentDatatable = React.forwardRef<AlertPagesDatatableRef, Props>((props, ref) => {
	const {
		incident,
		scrollCallback,
	} = props;

	const relevantFields = incident.get('settings').get('relevantFields');

	const extraColumns = React.useMemo(
		() => {
			return relevantFields.filter(
				(column) => column !== CK.PagesCommonColumn.Url,
			);
		},
		[
			relevantFields,
		],
	);

	return (
		<AlertPagesDatatable
			extraColumns={extraColumns}
			incident={incident}
			ref={ref}
			scrollCallback={scrollCallback}
			showImportance={false}
		/>
	);
});



export default PagesEnteredOrLeftSegmentDatatable;
