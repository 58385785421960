import React from 'react';
import {
	useSelector,
} from 'react-redux';

import useBuildTrackedChangesFilterDefinition from '~/hooks/useBuildTrackedChangesFilterDefinition';
import useCurrentColumns from '~/hooks/useCurrentColumns';
import useEnabledWebsiteColumnsList from '~/hooks/useEnabledWebsiteColumnsList';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ExportType,
	createExportViaApi,
} from '~/model/exports';

import {
	getVisibleColumns,
} from '~/model/historicalChanges';

import {
	peekedColumnsSelector,
} from '~/state/changeTrackingPeekedColumns/selectors';

import {
	dateIntervalSelector,
	sortBySelector,
	totalSelector,
} from '~/state/historicalChanges/selectors';



function useExportTrackedChangesOnPages() {
	const buildTrackedChangesFilterDefinition = useBuildTrackedChangesFilterDefinition();
	const currentColumns = useCurrentColumns();
	const dateInterval = useSelector(dateIntervalSelector);
	const enabledWebsiteColumnsList = useEnabledWebsiteColumnsList();
	const peekedColumns = useSelector(peekedColumnsSelector);
	const sortBy = useSelector(sortBySelector);
	const total = useSelector(totalSelector);
	const websiteId = useWebsiteId();

	const isLarge = total >= 200000;

	return React.useCallback(
		() => {
			return async () => {
				const fields = getVisibleColumns({
					allColumns: currentColumns.map((column) => column.name),
					enabledColumns: enabledWebsiteColumnsList,
					peekedColumns,
				});

				const filter = buildTrackedChangesFilterDefinition();

				const response = await createExportViaApi(
					websiteId,
					ExportType.ChangeTracking,
					{
						columns: fields,
						endDate: dateInterval.get('endDate'),
						filter: filter.toJS(),
						sortBy: sortBy.toJS(),
						startDate: dateInterval.get('startDate'),
					},
				);

				return ({
					exportData: response.export,
					isLarge,
				});
			};
		},
		[
			buildTrackedChangesFilterDefinition,
			currentColumns,
			dateInterval,
			enabledWebsiteColumnsList,
			isLarge,
			peekedColumns,
			sortBy,
			websiteId,
		],
	);
}



export default useExportTrackedChangesOnPages;
