import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SocialIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Group"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="share"
					transform="translate(5.000000, 4.000000)"
				>
					<path
						d="M18.6740625,25.7953125 C18.6740625,28.815625 16.225625,31.2640625 13.2053125,31.2640625 C10.185,31.2640625 7.7365625,28.815625 7.7365625,25.7953125 C7.7365625,22.775 10.185,20.3265625 13.2053125,20.3265625 C16.225625,20.3265625 18.6740625,22.775 18.6740625,25.7953125 L18.6740625,25.7953125 Z"
						id="Stroke-92"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M42.1115625,14.85875 C42.1115625,17.8790625 39.663125,20.3275 36.6428125,20.3275 C33.6225,20.3275 31.1740625,17.8790625 31.1740625,14.85875 C31.1740625,11.8384375 33.6225,9.39 36.6428125,9.39 C39.663125,9.39 42.1115625,11.8384375 42.1115625,14.85875 L42.1115625,14.85875 Z"
						id="Stroke-93"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M36.5178125,43.765 C33.4975,43.765 31.0490625,41.3165625 31.0490625,38.29625 C31.0490625,35.2759375 33.4975,32.8275 36.5178125,32.8275 C39.538125,32.8275 41.9865625,35.2759375 41.9865625,38.29625 C41.9865625,41.3165625 39.538125,43.765 36.5178125,43.765 Z"
						id="Stroke-94"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M18.1364063,23.430625 L31.5989063,16.974375"
						id="Stroke-95"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M18.1364063,28.6629687 L31.1907962,35.4570752"
						id="Stroke-96"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default SocialIconBuilder;

