import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import WithPermission from '~/components/logic/access/WithPermission';

import useAccountCustomElementsLimit from '~/hooks/useAccountCustomElementsLimit';
import useAccountId from '~/hooks/useAccountId';
import useAccountPotentialMaximumCustomElementsLimit from '~/hooks/useAccountPotentialMaximumCustomElementsLimit';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	label: {
		id: 'ui.customElements.overview.addButton.label',
	},
	limitReachedHint: {
		id: 'ui.customElements.overview.addButton.limitReachedHint',
	},
});



const AddCustomElementButton: React.FC = () => {
	const accountId = useAccountId();
	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const customElementsLimit = useAccountCustomElementsLimit(accountId);
	const potentialMaximumCustomElementsLimit = useAccountPotentialMaximumCustomElementsLimit();

	const showTooltip = (
		customElementsLimit !== null
		&& potentialMaximumCustomElementsLimit !== null
		&& customElementDefinitions.count >= customElementsLimit
		&& customElementsLimit >= potentialMaximumCustomElementsLimit
	);

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
		>
			{({ isAllowed }) => (
				<Button
					disabled={isAllowed.yes === false || showTooltip}
					icon={(
						<BasicIcon
							color="#42cc67"
							type={BasicIconType.Plus}
						/>
					)}
					linkRouteName="website.detail.customElements"
					linkRouteParams={{
						accountId,
						action: 'create_custom_element',
						websiteId,
					}}
					size={ButtonSize.Small}
					style={ButtonStyle.Hollow}
					tooltip={showTooltip && (
						<FormattedMessage {...messages.limitReachedHint} />
					)}
					uppercase={true}
				>
					<FormattedMessage {...messages.label} />
				</Button>
			)}
		</WithPermission>
	);
};



export default AddCustomElementButton;
