import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import PageStatusBar, {
	PageStatusBarStatus,
} from '~/components/atoms/pageDetail/components/PageStatusBar';
import UnreliableResponseName from '~/components/names/UnreliableResponseName';

import usePageLastUnreliableResponse from '~/hooks/usePageLastUnreliableResponse';



const messages = defineMessages({
	label: {
		id: 'ui.pageDetail.type.unreachable.label',
	},
});



type Props = {
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const Unreachable: React.FC<Props> = (props) => {
	const {
		legacyUrlId,
		websiteId,
	} = props;

	const pageLastUnreliableResponse = usePageLastUnreliableResponse(legacyUrlId, websiteId);

	return (
		<PageStatusBar status={PageStatusBarStatus.Critical}>
			<FormattedMessage {...messages.label} />

			{pageLastUnreliableResponse && (
				<span>
					{''} (<UnreliableResponseName reason={pageLastUnreliableResponse} />)
				</span>
			)}
		</PageStatusBar>
	);
};



export default Unreachable;
