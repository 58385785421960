import memoize from 'memoizee';

import type CK from '~/types/contentking';

import {
	PagesColumnsCategory,
	getColumnCategory,
} from '~/model/pagesColumns';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';



const createCallback = memoize(
	(
		customElementDefinitions: ReturnType<typeof useWebsiteCustomElementDefinitions>,
		enrichmentFieldDefinitions: ReturnType<typeof useWebsiteEnrichmentFieldDefinitions>,
	) => (columns: Array<CK.PagesColumn>) => {
		return columns.filter((column) => {
			const columnCategory = getColumnCategory(column);

			if (columnCategory === PagesColumnsCategory.CustomElements) {
				return customElementDefinitions.getByColumn(column) !== null;
			}

			if (columnCategory === PagesColumnsCategory.EnrichmentFields) {
				return enrichmentFieldDefinitions.getByColumn(column) !== null;
			}

			return true;
		});
	},
);



function useFilterKnownWebsiteColumns(websiteId: CK.WebsiteId | null) {
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	return createCallback(
		customElementDefinitions,
		enrichmentFieldDefinitions,
	);
}



export default useFilterKnownWebsiteColumns;
