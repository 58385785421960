import {
	useSelector,
} from 'react-redux';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useIsAnyOnboardingTourInProgress from '~/hooks/useIsAnyOnboardingTourInProgress';
import useUserMainAccountId from '~/hooks/useUserMainAccountId';

import {
	userInfoSelector,
} from '~/state/authentication/selectors';



function useIsIntercomEnabled() {
	const currentUserId = useCurrentUserId();

	const isAnyOnboardingTourInProgress = useIsAnyOnboardingTourInProgress();
	const userInfo = useSelector(userInfoSelector);
	const userMainAccountId = useUserMainAccountId(currentUserId);

	return (
		!!userInfo?.has('user_hash')
		&& userMainAccountId !== null
		&& isAnyOnboardingTourInProgress === false
	);
}



export default useIsIntercomEnabled;
