import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import OldValue from '~/components/patterns/values/OldValue';



type Props = {
	/** Currect currency code */
	currency: string,
	/** Price value */
	value: number,
};



const OldPrice: React.FC<Props> = (props) => {
	const {
		currency,
		value,
	} = props;

	const hasDecimalPart = value % 1 > 0;

	return (
		<OldValue>
			<FormattedNumber
				currency={currency}
				maximumFractionDigits={hasDecimalPart ? 2 : 0}
				minimumFractionDigits={hasDecimalPart ? 2 : 0}
				style="currency"
				value={value}
			/>
		</OldValue>
	);
};



export default OldPrice;
