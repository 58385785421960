import React from 'react';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';



type Props = {
	/** Button label explaining expected action */
	label: React.ReactNode,
	/** Callback triggered on button click */
	onButtonClick: () => void,
	/** Callback triggered when entering by mouse whole comparison element (not only button) */
	onMouseEnter?: () => void,
	/** Callback triggered when leaving by mouse whole comparison element (not only button) */
	onMouseLeave?: () => void,
};



const VersionsComparisonButton: React.FC<Props> = (props) => {
	const {
		label,
		onButtonClick,
		onMouseEnter,
		onMouseLeave,
	} = props;

	return (
		<div
			className="versions-comparison-button"
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="versions-comparison-button__button">
				<Button
					onClick={onButtonClick}
					size={ButtonSize.XXSmall}
					style={ButtonStyle.Hollow}
				>
					{label}
				</Button>
			</div>
		</div>
	);
};



export default VersionsComparisonButton;
