import React from 'react';

import CancelButton from '~/components/app/CancelButton';
import {
	PaymentAuthorizationContextContext,
} from '~/components/logic/PaymentAuthorizationContext';



type Props = {
	children?: React.ReactNode,
	labelDuringAuthorization?: React.ReactNode,
};

const PaymentCancelButton: React.FC<Props> = (props) => {
	const {
		children,
		labelDuringAuthorization,
	} = props;

	const {
		cancelAuthorization,
		isAuthorizing,
		isResubmitting,
	} = React.useContext(PaymentAuthorizationContextContext);

	if (isAuthorizing) {
		if (isResubmitting) {
			return null;
		}

		return (
			<CancelButton
				onClickCallback={cancelAuthorization}
			>
				{labelDuringAuthorization}
			</CancelButton>
		);
	}

	return (
		<CancelButton>
			{children}
		</CancelButton>
	);
};



export default PaymentCancelButton;
