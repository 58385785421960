import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import IntercomActivator from '~/components/logic/IntercomActivator';
import InternalLink from '~/components/patterns/links/InternalLink';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RadioList from '~/components/atoms/forms/components/RadioList';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';

import {
	validateField,
} from '~/components/app/validations';

import useAccountCanHaveMoreConnectedTrialAccounts from '~/hooks/useAccountCanHaveMoreConnectedTrialAccounts';
import useAccountDisplayName from '~/hooks/useAccountDisplayName';

import {
	Action,
	type Values,
} from './types';



const messages = defineMessages({
	continue: {
		id: 'ui.general.continue',
	},
	description: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.description',
	},
	invite: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.invite',
	},
	maximumAllowedTrialConnectionsLimitReachedDescription: {
		id: 'ui.accountAccess.connectToClient.errors.maximumAllowedTrialConnectionsLimitReached.description',
	},
	maximumAllowedTrialConnectionsLimitReachedTitle: {
		id: 'ui.accountAccess.connectToClient.errors.maximumAllowedTrialConnectionsLimitReached.title',
	},
	sidebar: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.sidebar',
	},
	title: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.title',
	},
});

const messagesAction = defineMessages({
	[Action.CreateNewAccount]: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.action.createNewAccount',
	},
	[Action.ConnectExistingAccount]: {
		id: 'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.action.connectExistingAccount',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	onContinue: (values: Values) => void,
	values: Values,
};

const ConnectToAccountStep: React.FC<Props> = (props) => {
	const {
		accountId,
		onContinue,
		values: defaultValues,
	} = props;

	const accountCanHaveMoreConnectedTrialAccounts = useAccountCanHaveMoreConnectedTrialAccounts(accountId);
	const accountName = useAccountDisplayName(accountId);

	const validations = React.useMemo(
		() => ({
			action: validateField(
				'action',
				(f) => [
					f.validateNonEmpty(),
					f.custom({
						message: 'maximumAllowedTrialConnectionsLimitReached',
						rule: ({ value }) => value === Action.CreateNewAccount
							? accountCanHaveMoreConnectedTrialAccounts
							: true,
					}),
				],
			),
		}),
		[
			accountCanHaveMoreConnectedTrialAccounts,
		],
	);

	return (
		<MultiStepModalStep
			name="connectToAccount"
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							link_article: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
						}}
					/>
				</RichText>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Form
				defaultValues={{
					action: defaultValues.action,
				}}
				key={defaultValues.action}
				onSuccess={onContinue}
				validations={validations}
			>
				{({ errors }) => (
					<>
						<ModalTextSection>
							<FormattedMessage {...messages.description} />
						</ModalTextSection>

						<ModalTextSection>
							<RadioList
								items={[
									{
										label: <FormattedMessage {...messagesAction[Action.CreateNewAccount]} />,
										value: Action.CreateNewAccount,
									},
									{
										label: <FormattedMessage {...messagesAction[Action.ConnectExistingAccount]} />,
										value: Action.ConnectExistingAccount,
									},
								]}
								name="action"
								width={false}
							/>
						</ModalTextSection>

						<ModalTextSection>
							<FormattedMessage
								{...messages.invite}
								values={{
									accountName,
									link_team_members: (chunks) => (
										<InternalLink routeName="account.members">{chunks}</InternalLink>
									),
								}}
							/>
						</ModalTextSection>

						<ModalTextSection>
							{errors.action === 'maximumAllowedTrialConnectionsLimitReached' && (
								<CalloutMessage
									borders={true}
									message={(
										<FormattedMessage {...messages.maximumAllowedTrialConnectionsLimitReachedTitle} />
									)}
									status={CalloutMessageStatus.Warning}
								>
									<FormattedMessage
										{...messages.maximumAllowedTrialConnectionsLimitReachedDescription}
										values={{
											link_support: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
											show_intercom: (chunks) => (
												<IntercomActivator>
													<InternalLink>
														{chunks}
													</InternalLink>
												</IntercomActivator>
											),
										}}
									/>
								</CalloutMessage>
							)}
						</ModalTextSection>

						<ModalTextSection>
							<ButtonsLayout
								alignment={ButtonsLayoutAlignment.Right}
								layout={ButtonsLayoutType.Steps}
							>
								<CancelButton />

								<SubmitButton>
									<FormattedMessage {...messages.continue} />
								</SubmitButton>
							</ButtonsLayout>
						</ModalTextSection>
					</>
				)}
			</Form>
		</MultiStepModalStep>
	);
};



export default ConnectToAccountStep;
