import classNames from 'classnames';
import React, {
	Component,
} from 'react';
import {
	TransitionGroup,
} from 'react-transition-group';

import MessageAnimation from '~/components/atoms/animations/MessageAnimation';
import PanelAnimation from '~/components/atoms/animations/PanelAnimation';

import {
	ScrollContainerProvider,
} from '~/hooks/useScrollContainer';



type Props = {
	/** Possible flash message component */
	flashMessage?: React.ReactNode,
	children?: React.ReactNode,
	header?: React.ReactNode,
	isHeaderFixed?: boolean,
	contentOverlay?: React.ReactNode,
	/** Possible sticky elements above content (not screen header) */
	stickyContentElements?: React.ReactNode,
};



class ScreenLayout extends Component<Props> {

	_renderContent() {
		const {
			children,
			isHeaderFixed,
		} = this.props;

		if (isHeaderFixed) {
			return (
				<ScrollContainerProvider>
					{({ containerRef }) => (
						<div
							className="screen__scrollable-content js-scrollable js-screen-scrollable-content"
							ref={containerRef}
						>
							{children}
						</div>
					)}
				</ScrollContainerProvider>
			);
		}

		return children;
	}



	_renderFlashMessage() {
		const {
			flashMessage,
		} = this.props;

		return (
			<MessageAnimation
				active={!!flashMessage}
				className="screen__flash-message"
			>
				{flashMessage}
			</MessageAnimation>
		);
	}



	render() {
		const {
			contentOverlay,
			header,
			isHeaderFixed,
			stickyContentElements,
		} = this.props;

		const componentClasses = classNames({
			'screen': true,
			'screen--is-header-fixed': isHeaderFixed,
		});

		return (
			<div className={componentClasses}>
				{header && (
					<div className="screen__header">
						{header}
					</div>
				)}

				<div className="screen__body">
					{this._renderFlashMessage()}
					{stickyContentElements}
					{this._renderContent()}

					<TransitionGroup>
						<PanelAnimation className="screen__overlay">
							{contentOverlay}
						</PanelAnimation>
					</TransitionGroup>
				</div>
			</div>
		);
	}

}



export default ScreenLayout;
