import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AlertsAndReportsForm from './AlertsAndReportsForm';
import Copy from '~/components/logic/Copy';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';

import useAllInvitations from '~/hooks/useAllInvitations';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';

import {
	useUpdateSendingOfAlertsAndReportsToInvitedUserMutation,
} from './AlertsAndReportsStep.gql';



const messages = defineMessages({
	sidebar: {
		id: 'ui.websites.new.steps.alertsReports.sidebar',
	},
	title: {
		id: 'ui.websites.new.steps.alertsReports.title',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	invitationEmail: string,
};

const AlertsAndReportsStep: React.FC<Props> = (props) => {
	const {
		accountId,
		invitationEmail,
	} = props;

	const allInvitations = useAllInvitations();
	const classicFormBehavior = useClassicFormBehavior();

	const [updateSendingOfAlertsAndReportsToInvitedUser] = useUpdateSendingOfAlertsAndReportsToInvitedUserMutation();

	const invitation = allInvitations
		?.listAll()
		?.find((invitation) => invitation.email === invitationEmail) ?? null;

	const handleFormSubmit = React.useCallback(
		async ({ emailSettings }) => {
			if (invitation === null) {
				return;
			}

			await updateSendingOfAlertsAndReportsToInvitedUser({
				variables: {
					invitationId: invitation.id,
					websites: emailSettings,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			invitation,
			updateSendingOfAlertsAndReportsToInvitedUser,
		],
	);

	return (
		<MultiStepModalStep
			name="alerts-and-reports-step"
			sidebar={(
				<RichText>
					<Copy {...messages.sidebar} />
				</RichText>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			{accountId !== null && invitation !== null ? (
				<AlertsAndReportsForm
					accountId={accountId}
					invitationWebsites={invitation.websites}
					onSubmitCallback={handleFormSubmit}
				/>
			) : null}
		</MultiStepModalStep>
	);
};



export default AlertsAndReportsStep;
