import classNames from 'classnames';
import React from 'react';

import useViewportType from '~/hooks/useViewportType';
import {
	isFunction,
} from '~/utilities/typeCheck';



export enum GridGapsSize {
	NoGaps = 'no-gaps',

	Small = 'small',
	Medium = 'medium',
	Large = 'large',
	XLarge = 'xlarge',

	Responsive = 'responsive',
}

type GridGapsSizeFunctionProp = (viewportType: {
	isExtraLarge: boolean,
	isLarge: boolean,
	isMedium: boolean,
	isSmall: boolean,
}) => GridGapsSize;

type Props = {
	/** Allow to align content to the center */
	centeredContent?: boolean,
	children?: React.ReactNode,
	columnsCount?: number,
	/** Possibility to set custom gaps of grid. Default gaps are "responsive". This means 10px on small and 20px on large devices. */
	gapsSize?: GridGapsSize | GridGapsSizeFunctionProp,
	/** Collapse multiple columns into one on smaller screens. When enabled we don't have to manually set columnsCount to 1 on smaller screens. */
	oneColumnOnSmall?: boolean,
};



const Grid: React.FC<Props> = (props) => {
	const {
		centeredContent,
		children,
		columnsCount = 1,
		gapsSize = GridGapsSize.NoGaps,
		oneColumnOnSmall = false,
	} = props;

	const viewportType = useViewportType();
	const forcedOneColumn = oneColumnOnSmall && viewportType.isSmall;

	const elements = React.Children.toArray(children).filter((child) => !!child);
	let gridStyle = {};
	let cellStyle = {};

	if (columnsCount > 1 && !forcedOneColumn) {
		gridStyle = {
			gridTemplateColumns: 'repeat(' + columnsCount + ', calc(' + (100 / columnsCount) + '%' + ' - (var(--gap-size) / 2)))',
		};

		cellStyle = {
			flex: '0 0 ' + (100 / columnsCount) + '%',
		};
	}

	let calculatedGapsSize;

	if (isFunction<GridGapsSizeFunctionProp>(gapsSize)) {
		calculatedGapsSize = gapsSize(viewportType);
	} else {
		calculatedGapsSize = gapsSize;
	}

	const componentClasses = classNames({
		'grid': true,

		'grid--large-gaps': calculatedGapsSize === GridGapsSize.Large,
		'grid--medium-gaps': calculatedGapsSize === GridGapsSize.Medium,
		'grid--responsive-gaps': calculatedGapsSize === GridGapsSize.Responsive,
		'grid--small-gaps': calculatedGapsSize === GridGapsSize.Small,
		'grid--xlarge-gaps': calculatedGapsSize === GridGapsSize.XLarge,

		'grid--grid-enabled': columnsCount > 1 && !forcedOneColumn,
	});

	const cellContainerClasses = classNames({
		'grid__cell-container': true,
		'grid__cell-container--centered-content': centeredContent,
	});

	return (
		<div
			className={componentClasses}
			style={gridStyle}
		>
			{elements.map((child, index) => {
				return (
					<div
						className="grid__cell"
						key={'cell-' + index}
						style={cellStyle}
					>
						<div className={cellContainerClasses}>
							{child}
						</div>
					</div>
				);
			})}
		</div>
	);
};



export default Grid;
