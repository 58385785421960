import classNames from 'classnames';
import React from 'react';

import styles from './Typo.module.scss';



export enum RichTextBottomMarginSize {
	Standard = 'standard',
	Smaller = 'smaller',
}

type Props = {
	/** Possibility to set different bottom margin for all HTML elements placed in this component */
	bottomMarginSize?: RichTextBottomMarginSize,
	/** Textual content containing also possible raw HTML tags */
	children: React.ReactNode,
};



const RichText: React.FC<Props> = (props) => {
	const {
		bottomMarginSize = RichTextBottomMarginSize.Standard,
		children,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isSmallerMargin]: bottomMarginSize === RichTextBottomMarginSize.Smaller,
		[styles.isWithoutBottomMargin]: true,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default RichText;
