import classNames from 'classnames';
import React from 'react';



export enum SpacerGap {
	NoGap = 'no-gap',
	Default = 'default',
	Small = 'small',
	XSmall = 'xsmall',

	Growing = 'growing', // responsive (growing) gap
}

type Props = {
	bottom?: SpacerGap,
	children: React.ReactNode,
	isStretched?: boolean,
	left?: SpacerGap,
	right?: SpacerGap,
	top?: SpacerGap,
};



const Spacer: React.FC<Props> = (props) => {
	const {
		bottom = SpacerGap.NoGap,
		children,
		isStretched,
		left = SpacerGap.NoGap,
		right = SpacerGap.NoGap,
		top = SpacerGap.NoGap,
	} = props;

	const componentClasses = classNames({
		'spacer': true,
		[ 'spacer--is-stretched' ]: isStretched,
		[ 'spacer--bottom-' + bottom + '-gap' ]: bottom != SpacerGap.NoGap,
		[ 'spacer--left-' + left + '-gap' ]: left != SpacerGap.NoGap,
		[ 'spacer--right-' + right + '-gap' ]: right != SpacerGap.NoGap,
		[ 'spacer--top-' + top + '-gap' ]: top != SpacerGap.NoGap,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default Spacer;
