export const TIME_OFFSET_UTC_MINUS_1200 = '-12:00';
export const TIME_OFFSET_UTC_MINUS_1100 = '-11:00';
export const TIME_OFFSET_UTC_MINUS_1000 = '-10:00';
export const TIME_OFFSET_UTC_MINUS_0930 = '-09:30';
export const TIME_OFFSET_UTC_MINUS_0900 = '-09:00';
export const TIME_OFFSET_UTC_MINUS_0800 = '-08:00';
export const TIME_OFFSET_UTC_MINUS_0700 = '-07:00';
export const TIME_OFFSET_UTC_MINUS_0600 = '-06:00';
export const TIME_OFFSET_UTC_MINUS_0500 = '-05:00';
export const TIME_OFFSET_UTC_MINUS_0400 = '-04:00';
export const TIME_OFFSET_UTC_MINUS_0330 = '-03:30';
export const TIME_OFFSET_UTC_MINUS_0300 = '-03:00';
export const TIME_OFFSET_UTC_MINUS_0200 = '-02:00';
export const TIME_OFFSET_UTC_MINUS_0100 = '-01:00';
export const TIME_OFFSET_UTC = '00:00';
export const TIME_OFFSET_UTC_PLUS_0100 = '+01:00';
export const TIME_OFFSET_UTC_PLUS_0200 = '+02:00';
export const TIME_OFFSET_UTC_PLUS_0300 = '+03:00';
export const TIME_OFFSET_UTC_PLUS_0330 = '+03:30';
export const TIME_OFFSET_UTC_PLUS_0400 = '+04:00';
export const TIME_OFFSET_UTC_PLUS_0430 = '+04:30';
export const TIME_OFFSET_UTC_PLUS_0500 = '+05:00';
export const TIME_OFFSET_UTC_PLUS_0530 = '+05:30';
export const TIME_OFFSET_UTC_PLUS_0545 = '+05:45';
export const TIME_OFFSET_UTC_PLUS_0600 = '+06:00';
export const TIME_OFFSET_UTC_PLUS_0630 = '+06:30';
export const TIME_OFFSET_UTC_PLUS_0700 = '+07:00';
export const TIME_OFFSET_UTC_PLUS_0800 = '+08:00';
export const TIME_OFFSET_UTC_PLUS_0830 = '+08:30';
export const TIME_OFFSET_UTC_PLUS_0845 = '+08:45';
export const TIME_OFFSET_UTC_PLUS_0900 = '+09:00';
export const TIME_OFFSET_UTC_PLUS_0930 = '+09:30';
export const TIME_OFFSET_UTC_PLUS_1000 = '+10:00';
export const TIME_OFFSET_UTC_PLUS_1030 = '+10:30';
export const TIME_OFFSET_UTC_PLUS_1100 = '+11:00';
export const TIME_OFFSET_UTC_PLUS_1200 = '+12:00';
export const TIME_OFFSET_UTC_PLUS_1245 = '+12:45';
export const TIME_OFFSET_UTC_PLUS_1300 = '+13:00';



export const TIME_OFFSETS = [
	{ timeOffset: TIME_OFFSET_UTC_MINUS_1200, utcTimeOffset: 'UTC−12:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_1100, utcTimeOffset: 'UTC−11:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_1000, utcTimeOffset: 'UTC−10:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0930, utcTimeOffset: 'UTC−09:30' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0900, utcTimeOffset: 'UTC−09:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0800, utcTimeOffset: 'UTC−08:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0700, utcTimeOffset: 'UTC−07:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0600, utcTimeOffset: 'UTC−06:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0500, utcTimeOffset: 'UTC−05:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0400, utcTimeOffset: 'UTC−04:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0330, utcTimeOffset: 'UTC−03:30' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0300, utcTimeOffset: 'UTC−03:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0200, utcTimeOffset: 'UTC−02:00' },
	{ timeOffset: TIME_OFFSET_UTC_MINUS_0100, utcTimeOffset: 'UTC−01:00' },
	{ timeOffset: TIME_OFFSET_UTC, utcTimeOffset: 'UTC±00:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0100, utcTimeOffset: 'UTC+01:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0200, utcTimeOffset: 'UTC+02:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0300, utcTimeOffset: 'UTC+03:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0330, utcTimeOffset: 'UTC+03:30' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0400, utcTimeOffset: 'UTC+04:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0430, utcTimeOffset: 'UTC+04:30' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0500, utcTimeOffset: 'UTC+05:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0530, utcTimeOffset: 'UTC+05:30' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0545, utcTimeOffset: 'UTC+05:45' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0600, utcTimeOffset: 'UTC+06:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0630, utcTimeOffset: 'UTC+06:30' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0700, utcTimeOffset: 'UTC+07:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0800, utcTimeOffset: 'UTC+08:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0830, utcTimeOffset: 'UTC+08:30' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0845, utcTimeOffset: 'UTC+08:45' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0900, utcTimeOffset: 'UTC+09:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_0930, utcTimeOffset: 'UTC+09:30' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_1000, utcTimeOffset: 'UTC+10:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_1030, utcTimeOffset: 'UTC+10:30' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_1100, utcTimeOffset: 'UTC+11:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_1200, utcTimeOffset: 'UTC+12:00' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_1245, utcTimeOffset: 'UTC+12:45' },
	{ timeOffset: TIME_OFFSET_UTC_PLUS_1300, utcTimeOffset: 'UTC+13:00' },
];
