import React from 'react';

import CK from '~/types/contentking';

import AlertPagesDatatable, {
	type AlertPagesDatatableIncident,
	type AlertPagesDatatableRef,
} from '~/components/logic/alertPages/AlertPagesDatatable';



type Props = {
	incident: AlertPagesDatatableIncident,
	scrollCallback: () => void,
};

const PageTypeChangeDatatable = React.forwardRef<AlertPagesDatatableRef, Props>((props, ref) => {
	const {
		incident,
		scrollCallback,
	} = props;

	const extraColumns = React.useMemo(
		() => {
			return [
				CK.PagesCommonColumn.Type,
			];
		},
		[],
	);

	return (
		<AlertPagesDatatable
			extraColumns={extraColumns}
			incident={incident}
			ref={ref}
			scrollCallback={scrollCallback}
			showImportance={false}
		/>
	);
});



export default PageTypeChangeDatatable;
