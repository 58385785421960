/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PagesOverviewHeaderQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PagesOverviewHeaderQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageCapacity: number, readonly pageTotal: number | null } | null };


export const PagesOverviewHeaderDocument = gql`
    query PagesOverviewHeader($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    pageCapacity
    pageTotal
  }
}
    `;

/**
 * __usePagesOverviewHeaderQuery__
 *
 * To run a query within a React component, call `usePagesOverviewHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesOverviewHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesOverviewHeaderQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePagesOverviewHeaderQuery(baseOptions: Apollo.QueryHookOptions<PagesOverviewHeaderQuery, PagesOverviewHeaderQueryVariables> & ({ variables: PagesOverviewHeaderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PagesOverviewHeaderQuery, PagesOverviewHeaderQueryVariables>(PagesOverviewHeaderDocument, options);
      }
export function usePagesOverviewHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagesOverviewHeaderQuery, PagesOverviewHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PagesOverviewHeaderQuery, PagesOverviewHeaderQueryVariables>(PagesOverviewHeaderDocument, options);
        }
export function usePagesOverviewHeaderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PagesOverviewHeaderQuery, PagesOverviewHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PagesOverviewHeaderQuery, PagesOverviewHeaderQueryVariables>(PagesOverviewHeaderDocument, options);
        }
export type PagesOverviewHeaderQueryHookResult = ReturnType<typeof usePagesOverviewHeaderQuery>;
export type PagesOverviewHeaderLazyQueryHookResult = ReturnType<typeof usePagesOverviewHeaderLazyQuery>;
export type PagesOverviewHeaderSuspenseQueryHookResult = ReturnType<typeof usePagesOverviewHeaderSuspenseQuery>;
export type PagesOverviewHeaderQueryResult = Apollo.QueryResult<PagesOverviewHeaderQuery, PagesOverviewHeaderQueryVariables>;