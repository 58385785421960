import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import IgnoringModalBodyLayout from '~/components/atoms/issues/components/modals/IgnoringModalBodyLayout';
import IgnoringStatus, {
	STATE_IGNORED,
} from '~/components/atoms/issues/components/IgnoringStatus';
import IgnoringTable, {
	type IgnoringTableCellRendererInput,
	type IgnoringTableGetColumnWidthInput,
} from '~/components/logic/issues/ignoring/IgnoringTable';
import InternalLink from '~/components/patterns/links/InternalLink';
import {
	renderEllipsisCell,
} from '~/components/atoms/dataTables/utils/ReactVirtualizedCells';
import RichText from '~/components/patterns/typography/RichText';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';
import UpcomingValueUpdateHighlight from '~/components/patterns/values/UpcomingValueUpdateHighlight';

import {
	useUnignorePageIssueCategoryOnSpecificPagesMutation,
} from './IgnoreCategoryOnPages.gql';

import useTrackIgnoringUpdateInPendo from '~/hooks/useTrackIgnoringUpdateInPendo';

import {
	loadPagesByIds,
} from '~/actions/pages/overview';

import {
	type IssueCategoryName,
} from '~/model/issuesNew';

import {
	getCategoryUrlIdentifier,
} from '~/model/issues/identifierMapping';

import {
	createFullId,
} from '~/model/pages';

import {
	pageBasicsSelector,
} from '~/state/pages/selectors';



const messages = defineMessages({
	alreadyIgnoredOnWebsite: {
		id: 'ui.issueDetail.ignoringModal.scope.pages.ignoredAlreadyOnWebsite.description',
	},
	headingsPage: {
		id: 'ui.issueDetail.ignoringModal.scope.pages.ignoringActive.pagesTable.columnHeading.page',
	},
	headingsStatus: {
		id: 'ui.issueDetail.ignoringModal.scope.general.column.status.heading',
	},
	ignoringActiveDescription: {
		id: 'ui.issueDetail.ignoringModal.scope.pages.ignoringActive.description',
	},
	ignoringStatusIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.ignored',
	},
	ignoringStatusChangeWillBeUnignored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.willBeUnignored',
	},
	noPagesIgnoredDescription: {
		id: 'ui.issueDetail.ignoringModal.scope.pages.ignoringInactive.description',
	},
});

const ELLIPSIS_POPUP_ZINDEX = 2000;

const columnWidths = {
	page: 300,
	status: 202,
};

function getColumnWidth({ index }: IgnoringTableGetColumnWidthInput) {
	switch (index) {

		case 0:
			return columnWidths.page;
		case 1:
			return columnWidths.status;

	}
}

function renderHeader({ columnIndex }) {
	let cellContent;

	switch (columnIndex) {

		case 0:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsPage} />
					)}
				/>
			);
			break;
		case 1:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsStatus} />
					)}
				/>
			);
			break;

	}

	return cellContent;
}

function renderStatusCell({ isChecked }) {
	const text = (
		<FormattedMessage {...messages.ignoringStatusIgnored} />
	);

	const possibleDescription = (
		<FormattedMessage {...messages.ignoringStatusChangeWillBeUnignored} />
	);

	const message = (
		<UpcomingValueUpdateHighlight updateDescription={isChecked && possibleDescription}>
			<IgnoringStatus state={STATE_IGNORED}>
				{text}
			</IgnoringStatus>
		</UpcomingValueUpdateHighlight>
	);

	return renderEllipsisCell(
		message,
		ELLIPSIS_POPUP_ZINDEX,
		isChecked ? possibleDescription : text,
	);
}



type Props = {
	isIgnoredOnWebsite: boolean,
	issueCategory: {
		issues: ReadonlyArray<{}>,
		name: IssueCategoryName,
	},
	pagesWhereAllIssuesAreIgnored: ReadonlyArray<number>,
	reloadCallback: () => Promise<void>,
	websiteId: CK.WebsiteId,
};

const IgnoreCategoryOnPages: React.FC<Props> = (props) => {
	const {
		isIgnoredOnWebsite,
		issueCategory,
		pagesWhereAllIssuesAreIgnored,
		reloadCallback,
		websiteId,
	} = props;

	const basics = useSelector(pageBasicsSelector);
	const dispatch = useDispatch();
	const trackIgnoringUpdateInPendo = useTrackIgnoringUpdateInPendo();

	const [unignorePageIssueCategoryOnSpecificPages] = useUnignorePageIssueCategoryOnSpecificPagesMutation();

	const issueCategoryName = issueCategory.name;

	React.useEffect(
		() => {
			dispatch(
				loadPagesByIds(pagesWhereAllIssuesAreIgnored),
			);
		},
		[
			dispatch,
			pagesWhereAllIssuesAreIgnored,
			websiteId,
		],
	);

	const handleSubmit = React.useCallback(
		async (selectedRows) => {
			await unignorePageIssueCategoryOnSpecificPages({
				variables: {
					issueCategoryName,
					urls: selectedRows.map(
						(pageId) => basics.get(createFullId(websiteId, pageId)).get('url'),
					),
					websiteId,
				},
			});

			trackIgnoringUpdateInPendo(
				websiteId,
				issueCategoryName,
				false,
				'page',
			);

			await reloadCallback();
		},
		[
			basics,
			issueCategoryName,
			reloadCallback,
			trackIgnoringUpdateInPendo,
			unignorePageIssueCategoryOnSpecificPages,
			websiteId,
		],
	);

	const renderPageUrlCell = React.useCallback(
		({ pageId }) => {
			const pageBasics = basics.get(createFullId(websiteId, pageId));

			if (!pageBasics) {
				return false;
			}

			const routeParams = {
				websiteId,
				id: pageId,
				pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategoryName),
			};

			return renderEllipsisCell(
				(
					<InternalLink
						ellipsis={true}
						routeName="website.pages.detail.issuesCategory"
						routeParams={routeParams}
					>
						{pageBasics.get('displayUrl')}
					</InternalLink>
				),
				ELLIPSIS_POPUP_ZINDEX,
				pageBasics.get('displayUrl'),
			);
		},
		[
			basics,
			issueCategoryName,
			websiteId,
		],
	);

	const renderCell = React.useCallback(
		({
			columnIndex,
			isChecked,
			item: pageId,
		}: IgnoringTableCellRendererInput<number>) => {
			let cellContent;

			switch (columnIndex) {

				case 0:
					cellContent = renderPageUrlCell({ pageId });

					break;

				case 1:
					cellContent = renderStatusCell({
						isChecked,
					});

					break;

			}

			return cellContent;
		},
		[
			renderPageUrlCell,
		],
	);

	if (isIgnoredOnWebsite) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					<FormattedMessage {...messages.alreadyIgnoredOnWebsite} />
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	if (pagesWhereAllIssuesAreIgnored.length === 0) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					<FormattedMessage
						{...messages.noPagesIgnoredDescription}
						values={{
							count__issues: issueCategory.issues.length,
						}}
					/>
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	return (
		<IgnoringModalBodyLayout>
			<RichText>
				<p>
					<Copy
						{...messages.ignoringActiveDescription}
						values={{
							ignoredPages: pagesWhereAllIssuesAreIgnored.length,
						}}
					/>
				</p>
			</RichText>

			<IgnoringTable
				bodyCellRenderer={renderCell}
				columnCount={2}
				columnWidth={getColumnWidth}
				headerCellRenderer={renderHeader}
				items={pagesWhereAllIssuesAreIgnored}
				name="pages"
				onSubmitChangesCallback={handleSubmit}
			/>
		</IgnoringModalBodyLayout>
	);
};



export default IgnoreCategoryOnPages;
