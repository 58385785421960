import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Hint from '~/components/patterns/hints/hint/Hint';

import {
	WebVital,
} from '~/model/webVitals';



const messages = defineMessages({
	coreWebVitalHint: {
		id: 'ui.webVitals.tooltip.coreWebVital',
	},
});

const names = defineMessages({
	[WebVital.CLS]: {
		id: 'ui.webVitals.name.cls',
	},
	[WebVital.FCP]: {
		id: 'ui.webVitals.name.fcp',
	},
	[WebVital.FID]: {
		id: 'ui.webVitals.name.fid',
	},
	[WebVital.INP]: {
		id: 'ui.webVitals.name.inp',
	},
	[WebVital.LCP]: {
		id: 'ui.webVitals.name.lcp',
	},
	[WebVital.Performance]: {
		id: 'ui.webVitals.name.performance',
	},
	[WebVital.SI]: {
		id: 'ui.webVitals.name.si',
	},
	[WebVital.TBT]: {
		id: 'ui.webVitals.name.tbt',
	},
	[WebVital.TTI]: {
		id: 'ui.webVitals.name.tti',
	},
});

const abbreviations = defineMessages({
	[WebVital.CLS]: {
		id: 'ui.webVitals.name.cls.short',
	},
	[WebVital.FCP]: {
		id: 'ui.webVitals.name.fcp.short',
	},
	[WebVital.FID]: {
		id: 'ui.webVitals.name.fid.short',
	},
	[WebVital.INP]: {
		id: 'ui.webVitals.name.inp.short',
	},
	[WebVital.LCP]: {
		id: 'ui.webVitals.name.lcp.short',
	},
	[WebVital.Performance]: {
		id: 'ui.webVitals.name.performance.short',
	},
	[WebVital.SI]: {
		id: 'ui.webVitals.name.si.short',
	},
	[WebVital.TBT]: {
		id: 'ui.webVitals.name.tbt.short',
	},
	[WebVital.TTI]: {
		id: 'ui.webVitals.name.tti.short',
	},
});



type Props = {
	/* Display the abbreviated name for this web vital, e.g. CLS */
	abbreviated?: boolean,
	/* Attach flag icon as suffix for core web vital types, FID, LCP & CLS */
	showCoreWebVitalFlag?: boolean,
	/* When combined with the `abbreviated` prop, show the full name on hover */
	showFullNameOnHover?: boolean,
	type: WebVital,
};

const WebVitalName: React.FC<Props> = (props) => {
	const {
		abbreviated = false,
		showCoreWebVitalFlag = true,
		showFullNameOnHover = false,
		type,
	} = props;

	const isCoreWebVital = [WebVital.FID, WebVital.LCP, WebVital.CLS].includes(type);

	let name: React.ReactElement | null = null;

	if (abbreviated) {
		name = <FormattedMessage {...abbreviations[type]} />;

		if (showFullNameOnHover) {
			name = (
				<Hint
					popup={(
						<FormattedMessage {...names[type]} />
					)}
				>
					{name}
				</Hint>
			);
		}
	} else {
		name = <FormattedMessage {...names[type]} />;
	}

	if (isCoreWebVital && showCoreWebVitalFlag) {
		return (
			<AttachedIcon
				ellipsis={false}
				icon={(
					<Hint
						popup={(
							<FormattedMessage {...messages.coreWebVitalHint} />
						)}
					>
						<BasicIcon
							size={20}
							type={BasicIconType.CoreWebVitals}
						/>
					</Hint>
				)}
				iconPosition={AttachedIconPosition.Suffix}
				inlineIcon={true}
			>
				{name}
			</AttachedIcon>
		);
	}

	return name;
};



export default WebVitalName;
