import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DedicatedSuccessManagerIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M24.4572 20.9999C25.9325 21.5891 27.1974 22.6069 28.0886 23.9219C28.9798 25.2369 29.4565 26.7888 29.4572 28.3774V28.9724H13.5547V28.3774C13.5542 26.7876 14.0303 25.2342 14.9216 23.9178C15.8129 22.6013 17.0784 21.5824 18.5547 20.9924"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M21.5027 21.8726C23.9134 21.8726 25.8677 19.9183 25.8677 17.5076C25.8677 15.0969 23.9134 13.1426 21.5027 13.1426C19.092 13.1426 17.1377 15.0969 17.1377 17.5076C17.1377 19.9183 19.092 21.8726 21.5027 21.8726Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M12.477 21.7751H2.33203V3.15259H23.357V11.2176"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M5.61719 18.8175V14.585"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M8.50488 18.8174V13.3149"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M11.3926 18.8174V11.4124"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.2793 18.8175V9.5625"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M17.167 12.3099V7.97241"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M20.0547 10.9124V6.16992"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default DedicatedSuccessManagerIconBuilder;

