import React from 'react';

import AttachedHeading from '~/components/patterns/structuredValues/AttachedHeading';
import HTMLWrapper from '~/components/atoms/HTMLWrapper';
import Title from '~/components/patterns/headings/Title';



type Props = {
	children: React.ReactNode,
} & ({
	showTitle?: false,
	title?: undefined,
} | {
	showTitle: true,
	title: React.ReactNode,
});

const SectionContent = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		children,
		showTitle = false,
		title,
	} = props;

	let result = children;

	if (showTitle) {
		result = (
			<AttachedHeading
				heading={(
					<Title>
						{title}
					</Title>
				)}
			>
				{result}
			</AttachedHeading>
		);
	}

	return (
		<HTMLWrapper ref={ref}>
			{result}
		</HTMLWrapper>
	);
});



export default SectionContent;
