import React from 'react';

import {
	ViewportContext,
} from '~/components/ViewportProvider';



function useViewportHeight(): number {
	const viewport = React.useContext(ViewportContext);

	return React.useMemo(
		() => {
			return viewport.windowSize.height;
		},
		[viewport.windowSize.height],
	);
}



export default useViewportHeight;
