import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import IssueValueCard, {
	IssueValueCardFlow as IssuesCountFlow,
	IssueValueCardSize as IssuesCountSize,
} from '~/components/patterns/issues/IssueValueCard.part';



export enum IssuesCountType {
	Fixed = 'fixed',
	Opened = 'opened',
}

type Props = {
	/** Flow of elements in layout of card */
	flow?: IssuesCountFlow,
	/** Different sizes of card */
	size?: IssuesCountSize,
	/** Type of displayed issues count */
	type: IssuesCountType,
	value: React.ReactNode,
};



const ICON_TYPE_MAPPING = {
	[ IssuesCountType.Fixed ]: BasicIconType.BugFixed,
	[ IssuesCountType.Opened ]: BasicIconType.BugOpened,
};



const IssuesCount: React.FC<Props> = (props) => {
	const {
		flow = IssuesCountFlow.Row,
		size = IssuesCountSize.Default,
		type,
		value,
	} = props;

	return (
		<IssueValueCard
			flow={flow}
			icon={(
				<BasicIcon
					type={ICON_TYPE_MAPPING[type]}
				/>
			)}
			size={size}
			value={value}
		/>
	);
};

export {
	IssuesCountFlow,
	IssuesCountSize,
};

export default IssuesCount;
