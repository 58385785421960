import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import CustomElementFormatter from '../../formatters/CustomElementFormatter';
import Declaration from '~/components/patterns/values/declarations/Declaration';
import DeclarationsList from '~/components/patterns/values/declarations/DeclarationsList';
import ElementTypeName from '../ElementTypeName';
import Emphasis from '~/components/patterns/typography/Emphasis';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';

import type useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';

import {
	type DATA_TYPE_BOOLEAN,
	type DATA_TYPE_DATE,
	type DATA_TYPE_HASH,
	type DATA_TYPE_INTEGER,
	type DATA_TYPE_NUMBER,
	type DATA_TYPE_STRING,
} from '~/model/customElements';



const messages = defineMessages({
	elementType: {
		id: 'ui.customElements.tester.output.elementType',
	},
	formErrorsNotFound: {
		id: 'ui.customElements.tester.formErrors.notFound',
	},
	url: {
		id: 'ui.customElements.tester.url',
	},
	value: {
		id: 'ui.customElements.tester.output.value',
	},
});



const WARNING_COLOR = 'rgba(255, 116, 59, 0.5)';



type Props = {
	elementType:
		| typeof DATA_TYPE_BOOLEAN
		| typeof DATA_TYPE_DATE
		| typeof DATA_TYPE_HASH
		| typeof DATA_TYPE_INTEGER
		| typeof DATA_TYPE_NUMBER
		| typeof DATA_TYPE_STRING,
	url: string,
	value: any,
};

const TesterOutput: React.FC<Props> = (props) => {
	const {
		elementType,
		url,
		value,
	} = props;

	const customElementDefinition = {
		column: ('' as CK.PagesCustomElementColumn),
		createdAt: '',
		dataType: elementType as GraphQL.CustomElement['dataType'],
		extraction: {},
		label: 'foo',
		name: 'foo',
	};

	const customElementDefinitions: ReturnType<typeof useWebsiteCustomElementDefinitions> = {
		count: 1,
		getByColumn: () => customElementDefinition,
		getByName: () => customElementDefinition,
		isLoaded: true,
		listAll: () => [customElementDefinition],
	};

	return (
		<SimpleBox
			borderColor={!value ? WARNING_COLOR : undefined}
			maxHeight={250}
			paddingSize={1}
		>
			<DeclarationsList>
				<Declaration
					property={(
						<FormattedMessage {...messages.url} />
					)}
					value={url}
				/>
				<Declaration
					property={(
						<FormattedMessage {...messages.elementType} />
					)}
					value={(
						<ElementTypeName elementType={elementType} />
					)}
				/>
				<Declaration
					property={(
						<FormattedMessage {...messages.value} />
					)}
					value={value !== null
						? (
							<CustomElementFormatter
								column="foo"
								customElements={['foo']}
								overriddenCustomElementDefinitions={customElementDefinitions}
								useTextInspector={false}
								value={value}
							/>
						)
						: (
							<Emphasis>
								<FormattedMessage {...messages.formErrorsNotFound} />
							</Emphasis>
						)
					}
				/>
			</DeclarationsList>
		</SimpleBox>
	);
};



export default TesterOutput;
