import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import IgnoreOnPage from '~/components/atoms/issues/components/ignoringCases/IgnoreOnPage';
import IgnoreCategoryOnSegments from '~/components/atoms/issues/components/ignoringCases/IgnoreCategoryOnSegments';
import IgnoreCategoryOnWebsite from '~/components/atoms/issues/components/ignoringCases/IgnoreCategoryOnWebsite';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import ModalLayout from '~/components/logic/issuesIgnoring/ModalLayout';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import useOpenedPageIssueCategory from '~/hooks/useOpenedPageIssueCategory';
import usePageUrl from '~/hooks/usePageUrl';
import useReloadPageIssueCategories from '~/hooks/useReloadPageIssueCategories';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	IgnoringScope,
} from '~/model/issuesNew';

import {
	pageDetailIgnoringScopeSelector,
} from '~/state/ui/content/selectors';



const navigationMessages = defineMessages({
	page: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.page',
	},
	segments: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.segments',
	},
	website: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.website',
	},
});



const IgnorePageIssueCategoryModal: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const issueCategory = useOpenedPageIssueCategory(legacyUrlId, websiteId);
	const pageUrl = usePageUrl(legacyUrlId, websiteId);
	const reloadPageIssueCategories = useReloadPageIssueCategories(legacyUrlId, websiteId);
	const scope = useSelector(pageDetailIgnoringScopeSelector);
	const segments = useWebsiteSegmentDefinitions(websiteId);

	function listScopes() {
		if (issueCategory === null || segments.isLoaded === false) {
			return [];
		}

		const isIgnoredOnWebsite = issueCategory.issues.every((issue) => issue.ignoringRuleOnWebsite.isActive);
		const isIgnoredOnPage = issueCategory.issues.every((issue) => issue.ignoringRuleOnPage.isActive);

		const segmentsWhereAllIssuesAreIgnored: Array<string> = [];

		const numberOfIgnoredIssuesPerSegment = {};

		issueCategory.issues.forEach((issue) => {
			issue.ignoringRuleOnSegments.ignoredSegments.forEach((segment) => {
				if (!numberOfIgnoredIssuesPerSegment[segment]) {
					numberOfIgnoredIssuesPerSegment[segment] = 0;
				}

				numberOfIgnoredIssuesPerSegment[segment]++;
			});
		});

		for (const segment in numberOfIgnoredIssuesPerSegment) {
			if (numberOfIgnoredIssuesPerSegment.hasOwnProperty(segment)) {
				if (numberOfIgnoredIssuesPerSegment[segment] === issueCategory.issues.length) {
					segmentsWhereAllIssuesAreIgnored.push(segment);
				}
			}
		}

		const scopes: Array<{
			badge: number | false,
			content: React.ReactNode,
			isNotReady: boolean,
			label: React.ReactNode,
			name: string,
		}> = [];

		scopes.push({
			badge: false,
			content: (
				<IgnoreCategoryOnWebsite
					isIgnored={isIgnoredOnWebsite}
					issueCategoryName={issueCategory.name}
					numberOfIssues={issueCategory.issues.filter((issue) => issue.ignoringRuleOnWebsite.isActive).length}
					reloadCallback={reloadPageIssueCategories}
					websiteId={websiteId}
				/>
			),
			isNotReady: false,
			label: (
				<FormattedMessage {...navigationMessages.website} />
			),
			name: IgnoringScope.Website,
		});

		scopes.push({
			badge: segmentsWhereAllIssuesAreIgnored.length,
			content: (
				<IgnoreCategoryOnSegments
					issueCategory={issueCategory}
					reloadCallback={reloadPageIssueCategories}
					segmentsWhereAllIssuesAreIgnored={segmentsWhereAllIssuesAreIgnored}
					websiteId={websiteId}
				/>
			),
			isNotReady: isIgnoredOnWebsite || segments.count === 0,
			label: (
				<FormattedMessage {...navigationMessages.segments} />
			),
			name: IgnoringScope.Segments,
		});

		scopes.push({
			badge: false,
			content: (
				<IgnoreOnPage
					isIgnoredOnPage={isIgnoredOnPage}
					isIgnoredOnWebsite={isIgnoredOnWebsite}
					issueCategoryName={issueCategory.name}
					numberOfIssues={issueCategory.issues.length}
					reloadCallback={reloadPageIssueCategories}
					url={pageUrl}
					websiteId={websiteId}
				/>
			),
			isNotReady: isIgnoredOnWebsite,
			label: (
				<FormattedMessage {...navigationMessages.page} />
			),
			name: IgnoringScope.Page,
		});


		return scopes;
	}

	return (
		<ModalLayout
			activeScope={scope}
			isLoading={issueCategory === null}
			scopes={listScopes()}
			title={issueCategory !== null && (
				<IssueCategoryTitle
					issueCategoryName={issueCategory.name}
				/>
			)}
		/>
	);
};



export default IgnorePageIssueCategoryModal;
