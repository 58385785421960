/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensitivityFieldQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type SensitivityFieldQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly scenariosIllustratingAlertSensitivity: ReadonlyArray<{ readonly __typename?: 'ScenarioIllustratingAlertSensitivity', readonly countOfLeastImportantPagesToTriggerAlert: number, readonly countOfMostImportantPagesToTriggerAlert: number, readonly scope: string, readonly scopeSize: number, readonly sensitivity: GraphQL.AlertSensitivityLevel }> } | null };


export const SensitivityFieldDocument = gql`
    query SensitivityField($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    scenariosIllustratingAlertSensitivity {
      countOfLeastImportantPagesToTriggerAlert
      countOfMostImportantPagesToTriggerAlert
      scope
      scopeSize
      sensitivity
    }
  }
}
    `;

/**
 * __useSensitivityFieldQuery__
 *
 * To run a query within a React component, call `useSensitivityFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensitivityFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensitivityFieldQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useSensitivityFieldQuery(baseOptions: Apollo.QueryHookOptions<SensitivityFieldQuery, SensitivityFieldQueryVariables> & ({ variables: SensitivityFieldQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensitivityFieldQuery, SensitivityFieldQueryVariables>(SensitivityFieldDocument, options);
      }
export function useSensitivityFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensitivityFieldQuery, SensitivityFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensitivityFieldQuery, SensitivityFieldQueryVariables>(SensitivityFieldDocument, options);
        }
export function useSensitivityFieldSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SensitivityFieldQuery, SensitivityFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SensitivityFieldQuery, SensitivityFieldQueryVariables>(SensitivityFieldDocument, options);
        }
export type SensitivityFieldQueryHookResult = ReturnType<typeof useSensitivityFieldQuery>;
export type SensitivityFieldLazyQueryHookResult = ReturnType<typeof useSensitivityFieldLazyQuery>;
export type SensitivityFieldSuspenseQueryHookResult = ReturnType<typeof useSensitivityFieldSuspenseQuery>;
export type SensitivityFieldQueryResult = Apollo.QueryResult<SensitivityFieldQuery, SensitivityFieldQueryVariables>;