export enum LoginScreenView {
	Login = 'login',
	LoginTwoFactorAuthentication = 'login.twoFactorAuthentication',
	PasswordLost = 'passwordLost',
	PasswordLostRequested = 'passwordLost.requested',
	PasswordReset = 'passwordReset',
	PasswordResetFinished = 'passwordReset.finished',
	PasswordResetTwoFactorAuthentication = 'passwordReset.twoFactorAuthentication',
}



export function isLoginScreenView(routeName: string) {
	return Object.keys(LoginScreenView).some(
		(view) => LoginScreenView[view] === routeName,
	);
}
