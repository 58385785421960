import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	useRequestAdminConfirmationLazyQuery,
} from './useRequestAdminConfirmationIfApplicable.gql';

import useAccountPhase from '~/hooks/useAccountPhase';
import useRoyalMode from '~/hooks/useRoyalMode';

import {
	CONTENTKING_ENVIRONMENT,
} from '~/config';



const CONFIRMATIONS = [
	'a hundred per cent',
	'absolutely',
	'affirmative',
	'alrighty',
	'as sure as eggs is eggs',
	'aye aye, sir',
	'beyond a shadow of a doubt',
	'by all means',
	'certainly',
	'do it',
	'hell yes',
	'most assuredly',
	'no second thoughts',
	'okay',
	'okey dokey',
	'please proceed',
	'sure as hell',
	'without a doubt',
	'yeah',
	'yep',
	'yes, please',
	'yup',
];



function useRequestAdminConfirmationIfApplicable(accountId: CK.AccountId | null) {
	const accountPhase = useAccountPhase(accountId);
	const royalMode = useRoyalMode();

	const isNotNeeded = (
		CONTENTKING_ENVIRONMENT !== 'production'
		|| accountPhase !== GraphQL.AccountPhase.Customer
		|| royalMode.isImpersonated !== true
	);

	const [loadRequestAdminConfirmation] = useRequestAdminConfirmationLazyQuery();

	return React.useCallback(
		async (message: string = 'make a subscription change for a customer') => {
			if (isNotNeeded || accountId === null) {
				return;
			}

			const { data } = await loadRequestAdminConfirmation({
				variables: {
					accountId,
				},
			});

			const accountHasNonCKUsers = data?.account?.memberships.some(({ user }) => {
				return user.email.includes('contentkingapp.com');
			}) ?? false;

			if (accountHasNonCKUsers) {
				return;
			}

			const confirmation = CONFIRMATIONS[Math.floor(Math.random() * CONFIRMATIONS.length)];

			if (confirmation !== prompt('CAUTION: you are about to ' + message + '! Are you sure? (say "' + confirmation + '")')) {
				throw new Error('Action was cancelled');
			}
		},
		[
			accountId,
			isNotNeeded,
			loadRequestAdminConfirmation,
		],
	);
}



export default useRequestAdminConfirmationIfApplicable;
