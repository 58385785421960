/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllowedPaymentMethodsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AllowedPaymentMethodsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly allowedPaymentMethods: ReadonlyArray<string> } | null };


export const AllowedPaymentMethodsDocument = gql`
    query AllowedPaymentMethods($accountId: AccountId!) {
  account(id: $accountId) {
    id
    allowedPaymentMethods
  }
}
    `;

/**
 * __useAllowedPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useAllowedPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedPaymentMethodsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAllowedPaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<AllowedPaymentMethodsQuery, AllowedPaymentMethodsQueryVariables> & ({ variables: AllowedPaymentMethodsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllowedPaymentMethodsQuery, AllowedPaymentMethodsQueryVariables>(AllowedPaymentMethodsDocument, options);
      }
export function useAllowedPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllowedPaymentMethodsQuery, AllowedPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllowedPaymentMethodsQuery, AllowedPaymentMethodsQueryVariables>(AllowedPaymentMethodsDocument, options);
        }
export function useAllowedPaymentMethodsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllowedPaymentMethodsQuery, AllowedPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllowedPaymentMethodsQuery, AllowedPaymentMethodsQueryVariables>(AllowedPaymentMethodsDocument, options);
        }
export type AllowedPaymentMethodsQueryHookResult = ReturnType<typeof useAllowedPaymentMethodsQuery>;
export type AllowedPaymentMethodsLazyQueryHookResult = ReturnType<typeof useAllowedPaymentMethodsLazyQuery>;
export type AllowedPaymentMethodsSuspenseQueryHookResult = ReturnType<typeof useAllowedPaymentMethodsSuspenseQuery>;
export type AllowedPaymentMethodsQueryResult = Apollo.QueryResult<AllowedPaymentMethodsQuery, AllowedPaymentMethodsQueryVariables>;