import classNames from 'classnames';
import React from 'react';



export enum ChangeStatusLabelChangeType {
	Added = 'added',
	Changed = 'changed',
	Other = 'other',
	Redirected = 'redirected',
	Removed = 'removed',
}

type Props = {
	/** Change type highlight */
	changeType: ChangeStatusLabelChangeType,
	/** Description of change */
	children: React.ReactNode,
};



const ChangeStatusLabel: React.FC<Props> = (props) => {
	const {
		changeType,
		children,
	} = props;

	const componentClasses = classNames({
		'change-status-label': true,
		['change-status-label--' + changeType]: changeType,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default ChangeStatusLabel;
