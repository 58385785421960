/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectSlackAccountMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  authCode: GraphQL.Scalars['String']['input'];
  slackRedirectUrl: GraphQL.Scalars['String']['input'];
}>;


export type ConnectSlackAccountMutation = { readonly __typename?: 'Mutation', readonly ConnectSlackAccount: { readonly __typename?: 'ConnectSlackAccountResult', readonly registeredMessagingAppChannelId: CK.ID | null, readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType }> } | null } } };


export const ConnectSlackAccountDocument = gql`
    mutation ConnectSlackAccount($accountId: AccountId!, $authCode: String!, $slackRedirectUrl: String!) {
  ConnectSlackAccount(
    accountId: $accountId
    authCode: $authCode
    slackRedirectUrl: $slackRedirectUrl
  ) {
    query {
      account(id: $accountId) {
        id
        messagingAppChannels {
          id
          label
          messagingAppType
        }
      }
    }
    registeredMessagingAppChannelId
  }
}
    `;
export type ConnectSlackAccountMutationFn = Apollo.MutationFunction<ConnectSlackAccountMutation, ConnectSlackAccountMutationVariables>;

/**
 * __useConnectSlackAccountMutation__
 *
 * To run a mutation, you first call `useConnectSlackAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectSlackAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectSlackAccountMutation, { data, loading, error }] = useConnectSlackAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      authCode: // value for 'authCode'
 *      slackRedirectUrl: // value for 'slackRedirectUrl'
 *   },
 * });
 */
export function useConnectSlackAccountMutation(baseOptions?: Apollo.MutationHookOptions<ConnectSlackAccountMutation, ConnectSlackAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectSlackAccountMutation, ConnectSlackAccountMutationVariables>(ConnectSlackAccountDocument, options);
      }
export type ConnectSlackAccountMutationHookResult = ReturnType<typeof useConnectSlackAccountMutation>;
export type ConnectSlackAccountMutationResult = Apollo.MutationResult<ConnectSlackAccountMutation>;
export type ConnectSlackAccountMutationOptions = Apollo.BaseMutationOptions<ConnectSlackAccountMutation, ConnectSlackAccountMutationVariables>;