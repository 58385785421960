import React from 'react';

import useLocalStorage from '~/hooks/useLocalStorage';



const SNOOZE_PERIOD = 7 * 24 * 60 * 60;



function useDataProcessingAgreementPromptSnooze() {
	const [openedAt, setOpenedAt] = useLocalStorage(
		'legalRequirements/dpa/openedAt',
		null,
	);

	const isSnoozed = React.useMemo(
		() => (
			openedAt !== null
			&& (openedAt + SNOOZE_PERIOD * 1000) > Date.now()
		),
		[
			openedAt,
		],
	);

	const snooze = React.useCallback(
		() => {
			setOpenedAt(Date.now());
		},
		[
			setOpenedAt,
		],
	);

	const tryResetSnooze = React.useCallback(
		() => {
			if (openedAt === null) {
				return;
			}

			if ((openedAt + SNOOZE_PERIOD * 1000) > Date.now()) {
				setOpenedAt(null);
			}
		},
		[
			openedAt,
			setOpenedAt,
		],
	);

	return React.useMemo(
		() => ({
			isSnoozed,
			snooze,
			tryResetSnooze,
		}),
		[
			isSnoozed,
			snooze,
			tryResetSnooze,
		],
	);
}



export default useDataProcessingAgreementPromptSnooze;
