import memoize from 'memoizee';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import usePermission from '~/hooks/usePermission';

import {
	ObjectType,
} from '~/model/permissions';



const createCallback = memoize(
	(isAllowed: ReturnType<typeof usePermission>) => (
		accountId: CK.AccountId | null,
		legacyUserId: string | null,
		action: GraphQL.ActionWithAccountMembership,
	) => {
		return isAllowed({
			action,
			objectId: (accountId !== null && legacyUserId !== null)
				? `${accountId}/${legacyUserId}/`
				: null,
			objectType: ObjectType.AccountMembership,
		});
	},
);



function useIsAllowedWithAccountMembershipOracle() {
	const isAllowed = usePermission();

	return createCallback(isAllowed);
}



export default useIsAllowedWithAccountMembershipOracle;
