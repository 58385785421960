/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetPasswordMutationVariables = GraphQL.Exact<{
  password: GraphQL.Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { readonly __typename?: 'Mutation', readonly ResetPassword: { readonly __typename?: 'ResetPasswordResult', readonly email: string, readonly query: { readonly __typename?: 'Query', readonly resetPasswordSession: { readonly __typename?: 'ResetPasswordSession', readonly isTwoFactorAuthenticationEnabled: boolean, readonly isTwoFactorAuthenticationVerified: boolean } | null } } };


export const ResetPasswordDocument = gql`
    mutation ResetPassword($password: String!) {
  ResetPassword(password: $password) {
    email
    query {
      resetPasswordSession {
        isTwoFactorAuthenticationEnabled
        isTwoFactorAuthenticationVerified
      }
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;