/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateIndexNowSegmentsBlocklistMutationVariables = GraphQL.Exact<{
  mode: GraphQL.IndexNowSegmentsBlocklistMode;
  segments: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateIndexNowSegmentsBlocklistMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteIndexNowSegmentConditions: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly indexNowSegmentsBlocklistAllowedSegments: ReadonlyArray<string>, readonly indexNowSegmentsBlocklistDisallowedSegments: ReadonlyArray<string>, readonly indexNowSegmentsBlocklistMode: GraphQL.IndexNowSegmentsBlocklistMode } | null } } };


export const UpdateIndexNowSegmentsBlocklistDocument = gql`
    mutation UpdateIndexNowSegmentsBlocklist($mode: IndexNowSegmentsBlocklistMode!, $segments: [String!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteIndexNowSegmentConditions(
    mode: $mode
    selectedSegments: $segments
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        indexNowSegmentsBlocklistAllowedSegments
        indexNowSegmentsBlocklistDisallowedSegments
        indexNowSegmentsBlocklistMode
      }
    }
  }
}
    `;
export type UpdateIndexNowSegmentsBlocklistMutationFn = Apollo.MutationFunction<UpdateIndexNowSegmentsBlocklistMutation, UpdateIndexNowSegmentsBlocklistMutationVariables>;

/**
 * __useUpdateIndexNowSegmentsBlocklistMutation__
 *
 * To run a mutation, you first call `useUpdateIndexNowSegmentsBlocklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndexNowSegmentsBlocklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndexNowSegmentsBlocklistMutation, { data, loading, error }] = useUpdateIndexNowSegmentsBlocklistMutation({
 *   variables: {
 *      mode: // value for 'mode'
 *      segments: // value for 'segments'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateIndexNowSegmentsBlocklistMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndexNowSegmentsBlocklistMutation, UpdateIndexNowSegmentsBlocklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIndexNowSegmentsBlocklistMutation, UpdateIndexNowSegmentsBlocklistMutationVariables>(UpdateIndexNowSegmentsBlocklistDocument, options);
      }
export type UpdateIndexNowSegmentsBlocklistMutationHookResult = ReturnType<typeof useUpdateIndexNowSegmentsBlocklistMutation>;
export type UpdateIndexNowSegmentsBlocklistMutationResult = Apollo.MutationResult<UpdateIndexNowSegmentsBlocklistMutation>;
export type UpdateIndexNowSegmentsBlocklistMutationOptions = Apollo.BaseMutationOptions<UpdateIndexNowSegmentsBlocklistMutation, UpdateIndexNowSegmentsBlocklistMutationVariables>;