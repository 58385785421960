import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import IssueScoreImpact, {
	IssueScoreImpactFlow as IssueHealthImpactFlow,
	IssueScoreImpactSize as IssueHealthImpactSize,
	IssueScoreImpactType as IssueHealthImpactType,
} from '~/components/patterns/issues/IssueScoreImpact';



export enum IssueHealthImpactAbout {
	Issue = 'issue',
	IssueCategory = 'issue_category',
}

export enum IssueHealthImpactScope {
	Segment = 'segment',
	Website = 'website',
}



const messages = {
	[IssueHealthImpactAbout.Issue]: defineMessages({
		alreadyBest: {
			id: 'ui.healthImpact.issue.alreadyBest',
		},
		alreadyWorst: {
			id: 'ui.healthImpact.issue.alreadyWorst',
		},
		[IssueHealthImpactType.Negative]: {
			id: 'ui.healthImpact.issue.negative',
		},
		[IssueHealthImpactType.None]: {
			id: 'ui.healthImpact.issue.none',
		},
		[IssueHealthImpactType.Positive]: {
			id: 'ui.healthImpact.issue.positive',
		},
		unavailable: {
			id: 'ui.healthImpact.unavailable',
		},
	}),
	[IssueHealthImpactAbout.IssueCategory]: defineMessages({
		[IssueHealthImpactType.Negative]: {
			id: 'ui.healthImpact.issueCategory.negative',
		},
		[IssueHealthImpactType.None]: {
			id: 'ui.healthImpact.issueCategory.none',
		},
		[IssueHealthImpactType.Positive]: {
			id: 'ui.healthImpact.issueCategory.positive',
		},
		unavailable: {
			id: 'ui.healthImpact.unavailable',
		},
	}),
};



type Props = {
	about: IssueHealthImpactAbout,
	flow: IssueHealthImpactFlow,
	issue: {
		health: {
			change: {
				next: {
					gained: number,
					toGain: number,
				},
				previous: {
					gained: number,
					toGain: number,
				},
				type: 'worse' | 'better' | 'same',
			},
			toGain: number,
		},
		isInitialComparison: boolean,
	},
	scope: IssueHealthImpactScope,
	size: IssueHealthImpactSize,
};

const IssueHealthImpact: React.FC<Props> = (props) => {
	const {
		about,
		flow,
		issue,
		scope,
		size,
	} = props;

	let impactType;
	let showPoints = true;
	let tooltip;

	if (issue.isInitialComparison) {
		impactType = IssueHealthImpactType.None;
		showPoints = false;
		tooltip = 'unavailable';
	} else if (issue.health.change.type === 'worse') {
		impactType = IssueHealthImpactType.Negative;
	} else if (issue.health.change.type === 'better') {
		impactType = IssueHealthImpactType.Positive;
	} else {
		impactType = IssueHealthImpactType.None;

		if (
			issue.health.change.previous.gained == 0
			&& issue.health.change.next.gained == 0
		) {
			showPoints = false;
			tooltip = 'alreadyWorst';
		} else if (
			issue.health.change.previous.toGain == 0
			&& issue.health.change.next.toGain == 0
		) {
			showPoints = false;
			tooltip = 'alreadyBest';
		}
	}

	if (!tooltip) {
		tooltip = impactType;
	}

	const value = -issue.health.toGain;

	return (
		<Hint
			attachment={HintAttachment.Center}
			popup={(
				<FormattedMessage
					{...messages[about][tooltip]}
					values={{
						count: Math.abs(value),
						scope,
					}}
				/>
			)}
			targetCustomCursor={false}
		>
			<IssueScoreImpact
				flow={flow}
				size={size}
				type={impactType}
				value={showPoints ? value : 0}
			/>
		</Hint>
	);
};



export default React.memo(IssueHealthImpact);

export {
	IssueHealthImpactFlow,
	IssueHealthImpactSize,
};
