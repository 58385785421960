import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DatatableOverlay, {
	DatatableOverlayStyle,
} from '~/components/patterns/tables/datatables/DatatableOverlay';
import ProcessingMessage from '~/components/patterns/loaders/ProcessingMessage';



const messages = defineMessages({
	title: {
		id: 'ui.reevaluationOverlay.title',
	},
	message: {
		id: 'ui.reevaluationOverlay.message',
	},
});



type Props = {
	datatableHeaderHeight?: number,
};

const ReevaluationOverlay: React.FC<Props> = (props) => {
	const {
		datatableHeaderHeight,
	} = props;

	return (
		<DatatableOverlay
			datatableHeaderHeight={datatableHeaderHeight}
			style={DatatableOverlayStyle.White}
		>
			<ProcessingMessage
				showLoader={true}
				title={(
					<FormattedMessage {...messages.title} />
				)}
			>
				<FormattedMessage {...messages.message} />
			</ProcessingMessage>
		</DatatableOverlay>
	);
};



export default ReevaluationOverlay;
