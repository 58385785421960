import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DomainIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
				<g id="issue_domain">
					<g id="Platform">
						<g id="[1440]-Platform-MissingStates">
							<g id="issues">
								<g id="Cards">
									<g id="issueCardCategoryOverview-Copy-3">
										<g id="thumbnail">
											<g id="globe">
												<path d="M18.75,30 C18.75,18.609375 23.78625,9.375 30,9.375 C36.21375,9.375 41.25,18.609375 41.25,30 C41.25,41.390625 36.21375,50.625 30,50.625 C23.78625,50.625 18.75,41.390625 18.75,30 Z" id="Stroke-1610" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></path>
												<path d="M9.375,30 L50.625,30" id="Stroke-1611" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></path>
												<path d="M12.8308125,18.75 L47.2258125,18.75" id="Stroke-1612" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></path>
												<path d="M30,9.375 L30,50.625" id="Stroke-1613" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></path>
												<path d="M50.625,30 C50.625,41.390625 41.390625,50.625 30,50.625 C18.609375,50.625 9.375,41.390625 9.375,30 C9.375,18.609375 18.609375,9.375 30,9.375 C41.390625,9.375 50.625,18.609375 50.625,30 L50.625,30 Z" id="Stroke-1614" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></path>
												<path d="M12.8308125,41.25 L47.2258125,41.25" id="Stroke-1615" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></path>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default DomainIconBuilder;

