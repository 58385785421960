import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import ExpirationTour from '~/components/patterns/tours/ExpirationTour';
import ModalPanel, {
	SIZE_XLARGE as MODAL_SIZE_XLARGE,
} from '~/components/atoms/panels/ModalPanel';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import Muted from '~/components/patterns/typography/Muted';
import NumberOfEmployeesForm from '~/components/app/NumberOfEmployeesForm';
import RichText from '~/components/patterns/typography/RichText';
import SimpleLogoutLink, {
	SimpleLogoutLinkStyle,
} from '~/components/app/SimpleLogoutLink';
import SocialProof from '~/components/app/SocialProof';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsNumberOfEmployeesEnoughForOutOfBand from '~/hooks/useAccountIsNumberOfEmployeesEnoughForOutOfBand';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useUserFirstName from '~/hooks/useUserFirstName';

import {
	Intercom,
} from '~/globals';

import {
	navigate,
} from '~/routing/router';



const messages = defineMessages({
	addWebsite: {
		id: 'ui.reactivateAccountModal.addWebsite',
	},
	clickToLogout: {
		id: 'ui.reactivateAccountModal.clickToLogout',
	},
	contactUs: {
		id: 'ui.reactivateAccountModal.contactUs',
	},
	content: {
		id: 'ui.reactivateAccountModal.content',
	},
	greeting: {
		id: 'ui.reactivateAccountModal.greeting',
	},
	oneMoreThing: {
		id: 'ui.reactivateAccountModal.oneMoreThing',
	},
	title: {
		id: 'ui.reactivateAccountModal.title',
	},
});



type Props = {
	closeCallback?: () => void,
};

const ReactivateAccountModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const modalRef = React.useRef<MultiStepModalRef | null>(null);

	const accountId = useAccountId();
	const currentUserId = useCurrentUserId();

	const firstName = useUserFirstName(currentUserId);
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const iNumberOfEmployeesEnoughForOutOfBand = useAccountIsNumberOfEmployeesEnoughForOutOfBand(accountId);

	const isAllowedToAddWebsite = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.AddWebsite,
	);

	const showNumberOfEmployeesStep = iNumberOfEmployeesEnoughForOutOfBand === null;

	const handleAddWebsiteClick = React.useCallback(
		() => {
			if (modalRef.current === null) {
				return;
			}

			if (
				modalRef.current.isStepActive('add-a-website')
				&& showNumberOfEmployeesStep
			) {
				modalRef.current.goToNextStep();
			} else {
				navigate('account.websites.new', { accountId });
			}
		},
		[
			accountId,
			showNumberOfEmployeesStep,
		],
	);
	return (
		<ModalPanel
			canBeClosed={kingdomAdminFeatures.areVisible}
			onCloseCallback={closeCallback}
			size={MODAL_SIZE_XLARGE}
		>
			<MultiStepModal
				minHeight={300}
				ref={modalRef}
			>
				<MultiStepModalStep
					name="add-a-website"
					sidebar={(
						<SocialProof hideBenefits={true} />
					)}
					slimSidebar={true}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<ExpirationTour
						additionalInfo={(
							<Muted>
								<FormattedMessage
									{...messages.clickToLogout}
									values={{
										logout: (
											<SimpleLogoutLink style={SimpleLogoutLinkStyle.Decent} />
										),
									}}
								/>
							</Muted>
						)}
						ctaButton={(
							<>
								{isAllowedToAddWebsite.yes ? (
									<Button
										onClick={handleAddWebsiteClick}
										size={ButtonSize.XLarge}
										style={ButtonStyle.Action}
									>
										<FormattedMessage {...messages.addWebsite} />
									</Button>
								) : (
									<Button
										onClick={() => {
											Intercom()('show');
										}}
										size={ButtonSize.XLarge}
										style={ButtonStyle.Action}
									>
										<FormattedMessage {...messages.contactUs} />
									</Button>
								)}
							</>
						)}
					>
						<RichText>
							{firstName && (
								<Copy
									{...messages.greeting}
									values={{
										firstName,
									}}
								/>
							)}
							<Copy {...messages.content} />
						</RichText>
					</ExpirationTour>
				</MultiStepModalStep>

				<MultiStepModalStep
					name="number-of-employees"
					sidebar={(
						<SocialProof hideBenefits={true} />
					)}
					slimSidebar={true}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<NumberOfEmployeesForm
						description={(
							<Copy {...messages.oneMoreThing} />
						)}
						onSubmitCallback={handleAddWebsiteClick}
					/>
				</MultiStepModalStep>
			</MultiStepModal>
		</ModalPanel>
	);
};



export default ReactivateAccountModal;
