import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';



export enum ContextNavigationButtonStyle {
	Hollow = 'hollow',
	Link = 'link',
}

type Props = {
	children: React.ReactNode,
	/** Set button as disabled */
	disabled?: boolean,
	/** When defined target of context button will be href */
	href?: string,
	/** Type of basic icon */
	iconType: BasicIconType,
	onClickCallback?: () => void,
	routeName?: string,
	routeParams?: Record<string, any>,
	tooltip?: React.ReactNode,
	style?: ContextNavigationButtonStyle,
};



const ContextNavigationButton: React.FC<Props> = (props) => {
	const {
		children,
		disabled = false,
		href,
		iconType,
		onClickCallback,
		routeName,
		routeParams,
		tooltip,
		style = ContextNavigationButtonStyle.Hollow,
	} = props;

	const handleClick = React.useCallback(
		(e) => {
			if (!onClickCallback) {
				return false;
			}

			e.preventDefault();
			e.stopPropagation();

			onClickCallback();
		},
		[
			onClickCallback,
		],
	);

	return (
		<Button
			disabled={disabled}
			href={href}
			icon={(
				<BasicIcon
					grayscale={style === ContextNavigationButtonStyle.Link}
					type={iconType}
				/>
			)}
			linkRouteName={routeName}
			linkRouteParams={routeParams}
			onClick={routeName ? undefined : handleClick}
			size={ButtonSize.Small}
			style={style === ContextNavigationButtonStyle.Link ? ButtonStyle.Link : ButtonStyle.Hollow}
			tooltip={tooltip}
			width={ButtonWidth.Fullwidth}
		>
			{children}
		</Button>
	);
};



export default ContextNavigationButton;

export {
	BasicIconType as ContextNavigationButtonIconType,
};
