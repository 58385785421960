import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';



type Props = {
	value: number,
};

const AbbreviatedNumber: React.FC<Props> = (props) => {
	const {
		value,
	} = props;

	return (
		<FormattedNumber
			compactDisplay="short"
			maximumFractionDigits={1}
			minimumFractionDigits={0}
			notation="compact"
			value={value}
		/>
	);
};



export default AbbreviatedNumber;
