/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountMaximumRegularSignupPageBundleQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountMaximumRegularSignupPageBundleQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly maximumRegularSignupPageBundle: number | null } | null };


export const AccountMaximumRegularSignupPageBundleDocument = gql`
    query AccountMaximumRegularSignupPageBundle($accountId: AccountId!) {
  account(id: $accountId) {
    id
    maximumRegularSignupPageBundle
  }
}
    `;

/**
 * __useAccountMaximumRegularSignupPageBundleQuery__
 *
 * To run a query within a React component, call `useAccountMaximumRegularSignupPageBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMaximumRegularSignupPageBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMaximumRegularSignupPageBundleQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountMaximumRegularSignupPageBundleQuery(baseOptions: Apollo.QueryHookOptions<AccountMaximumRegularSignupPageBundleQuery, AccountMaximumRegularSignupPageBundleQueryVariables> & ({ variables: AccountMaximumRegularSignupPageBundleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountMaximumRegularSignupPageBundleQuery, AccountMaximumRegularSignupPageBundleQueryVariables>(AccountMaximumRegularSignupPageBundleDocument, options);
      }
export function useAccountMaximumRegularSignupPageBundleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountMaximumRegularSignupPageBundleQuery, AccountMaximumRegularSignupPageBundleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountMaximumRegularSignupPageBundleQuery, AccountMaximumRegularSignupPageBundleQueryVariables>(AccountMaximumRegularSignupPageBundleDocument, options);
        }
export function useAccountMaximumRegularSignupPageBundleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountMaximumRegularSignupPageBundleQuery, AccountMaximumRegularSignupPageBundleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountMaximumRegularSignupPageBundleQuery, AccountMaximumRegularSignupPageBundleQueryVariables>(AccountMaximumRegularSignupPageBundleDocument, options);
        }
export type AccountMaximumRegularSignupPageBundleQueryHookResult = ReturnType<typeof useAccountMaximumRegularSignupPageBundleQuery>;
export type AccountMaximumRegularSignupPageBundleLazyQueryHookResult = ReturnType<typeof useAccountMaximumRegularSignupPageBundleLazyQuery>;
export type AccountMaximumRegularSignupPageBundleSuspenseQueryHookResult = ReturnType<typeof useAccountMaximumRegularSignupPageBundleSuspenseQuery>;
export type AccountMaximumRegularSignupPageBundleQueryResult = Apollo.QueryResult<AccountMaximumRegularSignupPageBundleQuery, AccountMaximumRegularSignupPageBundleQueryVariables>;