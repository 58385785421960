import React from 'react';

import {
	type NavigationOptions,
	type Params,
} from 'router5/dist/types/base';

import useUrlState from '~/hooks/useUrlState';

import goTo from '~/routing/goTo';
import {
	getRouter,
} from '~/routing/router';

import encodeInBase64 from '~/utilities/encodeInBase64';



const KEEP_PARAMETER = Symbol;

type CreateRouteInput = {
	extraRouteParams?: Params,
	includeBacklink?: boolean,
	keepParameters?: boolean,
	routeName?: string | null,
	routeParams?: Params | null,
};

type NavigateInput = CreateRouteInput & {
	after?: (() => void),
	event?: MouseEvent,
	options?: NavigationOptions,
};

type Navigation = {
	createRoute: (input: CreateRouteInput) => {
		name: string,
		params: Params,
	},
	keepParameter: () => typeof KEEP_PARAMETER,
	navigate: (input: NavigateInput) => void,
};



function useNavigation(): Navigation {
	const urlState = useUrlState();

	const createRoute = React.useCallback(
		(input: CreateRouteInput = {}) => {
			const routeName = input.routeName ?? urlState.name;
			let routeParams = { ...(input.routeParams ?? urlState.params) };

			for (const param in routeParams) {
				if (routeParams[param] === KEEP_PARAMETER) {
					routeParams[param] = urlState.params[param];
				}
			}

			if (input.keepParameters) {
				for (const param in urlState.params) {
					if (routeParams[param] === undefined) {
						routeParams[param] = urlState.params[param];
					}
				}
			}

			if (input.extraRouteParams) {
				routeParams = {
					...routeParams,
					...input.extraRouteParams,
				};
			}

			if (input.includeBacklink) {
				routeParams.back = encodeInBase64(
					JSON.stringify({
						name: urlState.name,
						params: urlState.params,
					}),
				);
			}

			return {
				name: routeName,
				params: routeParams,
			};
		},
		[
			urlState,
		],
	);

	const navigate = React.useCallback(
		(input: NavigateInput) => {
			const {
				name,
				params,
			} = createRoute(input);

			if (input.event) {
				goTo(
					input.event,
					name,
					params,
					input.options,
					input.after,
				);
			} else {
				const router = getRouter();

				router.navigate(
					name,
					params,
					input.options || {},
				);
			}
		},
		[
			createRoute,
		],
	);

	return React.useMemo(
		() => ({
			createRoute,
			keepParameter: () => KEEP_PARAMETER,
			navigate,
		}),
		[
			createRoute,
			navigate,
		],
	);
}



export default useNavigation;
