import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import IgnoringModalBodyLayout from '~/components/atoms/issues/components/modals/IgnoringModalBodyLayout';
import IgnoringStatus, {
	STATE_IGNORED,
	STATE_NOT_IGNORED,
} from '~/components/atoms/issues/components/IgnoringStatus';
import IgnoringTable, {
	type IgnoringTableCellRendererInput,
} from '~/components/logic/issues/ignoring/IgnoringTable';
import {
	renderEllipsisCell,
} from '~/components/atoms/dataTables/utils/ReactVirtualizedCells';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';
import UpcomingValueUpdateHighlight from '~/components/patterns/values/UpcomingValueUpdateHighlight';

import {
	useUnignorePageIssueCasesOnWholeWebsiteMutation,
} from './IgnoreWebsiteCases.gql';

import useTrackIgnoringUpdateInPendo from '~/hooks/useTrackIgnoringUpdateInPendo';

import {
	type IssueName,
	PageIssue,
} from '~/model/issuesNew';



const ELLIPSIS_POPUP_ZINDEX = 2000;

const columnWidths = {
	url: 300,
	scope: 202,
};

const messages = defineMessages({
	headingsStatus: {
		id: 'ui.issueDetail.ignoringModal.scope.general.column.status.heading',
	},
	headingsUrl: {
		id: 'ui.issueDetail.ignoringModal.casesTable.general.column.url.heading',
	},
	ignoredAlreadyOnWebsiteDescription: {
		id: 'ui.issueDetail.ignoringModal.scope.general.ignoredAlreadyOnWebsite.description',
	},
	ignoringStatusIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.ignored',
	},
	ignoringStatusNotIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.notIgnored',
	},
	ignoringStatusChangeWillBeIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.willBeIgnored',
	},
	ignoringStatusChangeWillBeUnignored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.willBeUnignored',
	},
	scopeWebsite: {
		id: 'ui.issueDetail.ignoringModal.casesTable.general.column.ignoredOn.website',
	},
});

const ignoredAlreadyOnPageDescriptionMessages = defineMessages({
	[PageIssue.ImagesAltAttribute]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.ignoredAlreadyOnPage.description',
	},
	[PageIssue.ImagesMixedTransport]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.ignoredAlreadyOnPage.description',
	},
	[PageIssue.LinksBroken]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnPage.description',
	},
	[PageIssue.LinksRedirected]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnPage.description',
	},
	[PageIssue.LinksToCanonicalized]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnPage.description',
	},
});

const noCasesIgnoredDescriptionMessages = defineMessages({
	[PageIssue.ImagesAltAttribute]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.noImagesIgnored.description',
	},
	[PageIssue.ImagesMixedTransport]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.noImagesIgnored.description',
	},
	[PageIssue.LinksBroken]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.noLinksIgnored.description',
	},
	[PageIssue.LinksRedirected]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.noLinksIgnored.description',
	},
	[PageIssue.LinksToCanonicalized]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.noLinksIgnored.description',
	},
});

function getColumnWidth({ index }) {
	switch (index) {

		case 0:
			return columnWidths.url;

		case 1:
			return columnWidths.scope;

	}
}

function renderHeader({ columnIndex }) {
	let cellContent;

	switch (columnIndex) {

		case 0:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsUrl} />
					)}
				/>
			);
			break;
		case 1:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsStatus} />
					)}
				/>
			);
			break;

	}

	return cellContent;
}

function renderStatusCell({ isSelected, status }) {
	const text = status ? (
		<FormattedMessage {...messages.ignoringStatusIgnored} />
	) : (
		<FormattedMessage {...messages.ignoringStatusNotIgnored} />
	);

	const possibleDescription = status ? (
		<FormattedMessage {...messages.ignoringStatusChangeWillBeUnignored} />
	) : (
		<FormattedMessage {...messages.ignoringStatusChangeWillBeIgnored} />
	);

	const message = (
		<UpcomingValueUpdateHighlight updateDescription={isSelected && possibleDescription}>
			<IgnoringStatus state={status ? STATE_IGNORED : STATE_NOT_IGNORED}>
				{text}
			</IgnoringStatus>
		</UpcomingValueUpdateHighlight>
	);

	return renderEllipsisCell(
		message,
		ELLIPSIS_POPUP_ZINDEX,
		isSelected ? possibleDescription : text,
	);
}



type Props = {
	ignoringRuleOnCases: {
		ignoredCases: ReadonlyArray<any>,
	},
	ignoringRuleOnPages: {
		isEffective: boolean,
	} | null,
	ignoringRuleOnWebsite: {
		isEffective: boolean,
	},
	issueName: IssueName,
	reloadCallback: () => Promise<void>,
	websiteId: CK.WebsiteId,
};

const IgnoreWebsiteCases: React.FC<Props> = (props) => {
	const {
		ignoringRuleOnCases,
		ignoringRuleOnPages,
		ignoringRuleOnWebsite,
		issueName,
		reloadCallback,
		websiteId,
	} = props;

	const trackIgnoringUpdateInPendo = useTrackIgnoringUpdateInPendo();

	const [unignorePageIssueCasesOnWholeWebsite] = useUnignorePageIssueCasesOnWholeWebsiteMutation();

	const handleSubmit = React.useCallback(
		async (casesToBeUnignored) => {
			if (casesToBeUnignored.length === 0) {
				return;
			}

			const caseIds = casesToBeUnignored.map((caseToBeUnignored) => caseToBeUnignored.id);

			await unignorePageIssueCasesOnWholeWebsite({
				variables: {
					cases: caseIds,
					issueName,
					websiteId,
				},
			});

			trackIgnoringUpdateInPendo(
				websiteId,
				issueName,
				false,
				'case',
			);

			await reloadCallback();
		},
		[
			issueName,
			reloadCallback,
			trackIgnoringUpdateInPendo,
			unignorePageIssueCasesOnWholeWebsite,
			websiteId,
		],
	);

	const renderURLCell = React.useCallback(
		({ item: ignoredCase }) => {
			const displayUrl = (
				<ExternalLink
					ellipsis={true}
					href={ignoredCase.url}
					target="_blank"
				>
					{ignoredCase.url}
				</ExternalLink>
			);

			const displayUrlTooltip = (
				<ExternalLink
					href={ignoredCase.url}
					target="_blank"
				>
					{ignoredCase.url}
				</ExternalLink>
			);

			return renderEllipsisCell(
				displayUrl,
				ELLIPSIS_POPUP_ZINDEX,
				displayUrlTooltip,
			);
		},
		[],
	);

	const renderCell = React.useCallback(
		({ columnIndex, isChecked, item }: IgnoringTableCellRendererInput<any>) => {
			let cellContent;

			switch (columnIndex) {

				case 0:
					cellContent = renderURLCell({ item });

					break;

				case 1:
					cellContent = renderStatusCell({
						isSelected: isChecked,
						status: true,
					});

					break;

			}

			return cellContent;
		},
		[
			renderURLCell,
		],
	);

	if (ignoringRuleOnCases.ignoredCases.length === 0) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					<FormattedMessage {...noCasesIgnoredDescriptionMessages[issueName]} />
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	if (
		ignoringRuleOnWebsite.isEffective
		|| (ignoringRuleOnPages?.isEffective ?? false)
	) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					{ignoringRuleOnWebsite.isEffective ? (
						<FormattedMessage
							{...messages.ignoredAlreadyOnWebsiteDescription}
							values={{
								count__issues: 1,
							}}
						/>
					) : (
						<FormattedMessage {...ignoredAlreadyOnPageDescriptionMessages[issueName]} />
					)}
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	return (
		<IgnoringModalBodyLayout>
			<IgnoringTable
				bodyCellRenderer={renderCell}
				columnCount={2}
				columnWidth={getColumnWidth}
				headerCellRenderer={renderHeader}
				items={ignoringRuleOnCases.ignoredCases}
				name="cases"
				onSubmitChangesCallback={handleSubmit}
			/>
		</IgnoringModalBodyLayout>
	);
};



export default IgnoreWebsiteCases;
