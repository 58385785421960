import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RobotsTxtIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeWidth="1"
			>
				<g id="clipboard">
					<polyline
						id="Stroke-771"
						points="11.0909 7.9994 7.9999 7.9994 7.9999 26.9994 23.9999 26.9994 23.9999 7.9994 20.8559 7.9994"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polyline>
					<path
						d="M17.9724,6.0006 L17.9724,6.0006 C17.9724,4.8956 17.0764,4.0006 15.9724,4.0006 L15.9714,4.0006 C14.8674,4.0006 13.9724,4.8956 13.9724,5.9996 L13.9724,6.0006 C13.9724,6.5526 13.5244,7.0006 12.9724,7.0006 L12.5674,7.0006 C11.6874,7.0006 10.9734,7.7136 10.9734,8.5936 L10.9734,8.5006 C10.9734,9.3286 11.6444,9.9996 12.4734,9.9996 L19.4744,9.9996 C20.3024,9.9996 20.9734,9.3286 20.9734,8.5006 L20.9734,8.5936 C20.9734,7.7136 20.2604,7.0006 19.3804,7.0006 L18.9724,7.0006 C18.4204,7.0006 17.9724,6.5526 17.9724,6.0006 L17.9724,6.0006 Z"
						id="Stroke-772"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<polyline
						id="Stroke-773"
						points="19.4374 10 21.0004 10 21.0004 24 11.0004 24 11.0004 10 12.4734 10"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polyline>
					<path
						d="M14.0006,14 L18.0006,14"
						id="Stroke-774"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M14.0006,17 L18.0006,17"
						id="Stroke-775"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M14.0006,20 L18.0006,20"
						id="Stroke-776"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default RobotsTxtIconBuilder;
