import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import EndUserPackageForm from '../../../atoms/forms/forms/EndUserPackageForm';
import FormsList from '~/components/atoms/lists/FormsList';
import LegacyPackageForm from '~/components/app/LegacyPackageForm';
import MoneyStuff from '~/components/app/MoneyStuff';
import PremiumTrialForm from '../PremiumTrialForm';
import SubscriptionForm from '~/components/app/SubscriptionForm';
import WithBillingDetails from '../../WithBillingDetails';

import {
	navigate,
} from '~/routing/router';

import {
	teamsBillingSelector,
} from '~/state/teams/selectors';

import {
	selectedTeamSelector,
	teamSelector,
	urlStateSelector,
} from '~/state/ui/selectors';



const SubscriptionGroup = () => {
	const dispatch = useDispatch();

	const [preselectedPlan, setPreselectedPlan] = React.useState(null);

	const selectedTeamId = useSelector(selectedTeamSelector);
	const teamBilling = useSelector(teamsBillingSelector).get(selectedTeamId);
	const teamDetails = useSelector(teamSelector);
	const urlState = useSelector(urlStateSelector);

	const [isEditingSubscription, setEditingSubscription] = React.useState(false);

	React.useEffect(
		() => {
			if (urlState && urlState.params.plan) {
				setPreselectedPlan(urlState.params.plan);

				navigate(
					urlState.name,
					Object.assign({}, urlState.params, {
						plan: false,
					}),
					{
						replace: true,
					},
				);
			}
		},
		[urlState],
	);

	if (!teamDetails.get('is_billable')) {
		return null;
	}

	if (teamDetails.get('type') === GraphQL.AccountType.Universal) {
		if (teamDetails.get('billing_entity') !== 'old') {
			return (
				<FormsList>
					<SubscriptionForm
						onEnterEditModeCallback={(isEditing) => {
							setEditingSubscription(isEditing);
						}}
						preselectedPlan={preselectedPlan}
						team={teamDetails}
					/>

					{!isEditingSubscription && (
						<PremiumTrialForm />
					)}
				</FormsList>
			);
		}
	}

	return (
		<FormsList>
			<WithBillingDetails useChildrenAsPlaceholder={true}>
				<MoneyStuff>
					{({ tariff }) => {
						if (teamDetails.get('type') === GraphQL.AccountType.EndUser) {
							return (
								<EndUserPackageForm
									dispatch={dispatch}
									tariff={tariff}
									team={teamDetails}
									teamBilling={teamBilling}
								/>
							);
						}

						if (teamDetails.get('type') === GraphQL.AccountType.Universal) {
							return (
								<LegacyPackageForm
									onEnterEditModeCallback={(isEditing) => {
										setEditingSubscription(isEditing);
									}}
									preselectedPlan={preselectedPlan}
									team={teamDetails}
									teamBilling={teamBilling}
								/>
							);
						}

						return null;
					}}
				</MoneyStuff>
			</WithBillingDetails>

			{!isEditingSubscription && (
				<PremiumTrialForm />
			)}
		</FormsList>
	);
};



export default SubscriptionGroup;
