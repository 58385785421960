import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AlertTypeTitle from '~/components/logic/alerts/AlertTypeTitle';
import BulkSaveDefinitionsButton from '~/components/logic/alertsConfiguration/BulkSaveDefinitionsButton';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MessagingAppChannelFieldRow from '~/components/app/MessagingAppChannelFieldRow';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalContentWithSidebar from '~/components/atoms/modals/parts/ModalContentWithSidebar';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RecipientsMultiselectField from '~/components/app/RecipientsMultiselectField';
import RichText from '~/components/patterns/typography/RichText';
import SegmentChangeTypeField from '~/components/logic/alertsConfiguration/fields/SegmentChangeTypeField';
import SegmentPercentageField from '~/components/logic/alertsConfiguration/fields/SegmentPercentageField';
import SegmentSelectField from '~/components/logic/alertsConfiguration/fields/SegmentSelectField';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	useUpdatePagesEnteredOrLeftSegmentAlertDefinitionMutation,
} from './EditPagesEnteredOrLeftSegmentAlertDefinitionModal.gql';

import useCanManageUserAsAlertRecipient from '~/hooks/useCanManageUserAsAlertRecipient';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useSetFlashMessage from '~/hooks/useSetFlashMessage';
import useUglyMicrosoftTeamsMessagingAppChannelOverlay from '~/hooks/useUglyMicrosoftTeamsMessagingAppChannelOverlay';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	description: {
		id: 'ui.alertsConfiguration.modal.edit.descriptionPagesEnteredOrLeftSegment',
	},
	fieldChangeType: {
		id: 'ui.alertsConfiguration.fields.segmentChangeType',
	},
	fieldRecipients: {
		id: 'ui.alertsConfiguration.fields.recipients',
	},
	fieldSegment: {
		id: 'ui.alertsConfiguration.fields.segment',
	},
	fieldSensitivity: {
		id: 'ui.alertsConfiguration.fields.sensitivity',
	},
	headerLabel: {
		id: 'ui.general.settings',
	},
	sidebar: {
		id: 'ui.alertsConfiguration.modal.edit.sidebarPagesEnteredOrLeftSegment',
	},
});



type Props = {
	alertDefinition: GraphQL.AlertDefinition,
};

const EditPagesEnteredOrLeftSegmentAlertDefinitionModal: React.FC<Props> = (props) => {
	const {
		alertDefinition,
	} = props;

	const websiteId = useWebsiteId();

	const canManageUserAsAlertRecipient = useCanManageUserAsAlertRecipient(websiteId);
	const classicFormBehavior = useClassicFormBehavior();
	const setFlashMessage = useSetFlashMessage();

	const [updatePagesEnteredOrLeftSegmentAlertDefinition] = useUpdatePagesEnteredOrLeftSegmentAlertDefinitionMutation();

	const {
		addMicrosoftTeamsMessagingAppChannelOverlay,
		initiateAddMicrosoftTeamsMessagingAppChannel,
		messagingAppChannelFieldRowRef,
	} = useUglyMicrosoftTeamsMessagingAppChannelOverlay();

	const handleSubmit = React.useCallback(
		async (values) => {
			const recipients = {
				added: values.recipients.filter(canManageUserAsAlertRecipient),
				removed: alertDefinition.recipients
					.filter((recipientEmail) => values.recipients.includes(recipientEmail) === false)
					.filter(canManageUserAsAlertRecipient),
			};

			const messagingAppChannelIds: Array<string> = [];

			if (values.messagingAppChannelId !== 'none') {
				messagingAppChannelIds.push(values.messagingAppChannelId);
			}

			await updatePagesEnteredOrLeftSegmentAlertDefinition({
				variables: {
					alertDefinitionId: alertDefinition.id,
					messagingAppChannelIds,
					recipients,
					segmentChangeSizePercentage: values.segmentChangeSizePercentage,
					segmentChangeType: values.segmentChangeType,
					segmentName: values.segmentName,
					websiteId,
				},
			});

			classicFormBehavior.finish();

			setFlashMessage({
				message: 'alertDefinitionsEdited',
				messageVariables: {
					number__alertDefinitions: 1,
				},
				route: 'website.alerts.configuration',
				style: 'success',
			});
		},
		[
			alertDefinition.id,
			alertDefinition.recipients,
			canManageUserAsAlertRecipient,
			classicFormBehavior,
			setFlashMessage,
			updatePagesEnteredOrLeftSegmentAlertDefinition,
			websiteId,
		],
	);

	return (
		<ModalContainer
			gapsSize={0}
			header={(
				<ModalHeader
					iconType={ModalHeaderIconType.CogWheel}
					title={(
						<FormattedMessage {...messages.headerLabel} />
					)}
					titleValue={(
						<AlertTypeTitle
							alertType={alertDefinition.alertType}
							settings={alertDefinition.settings}
						/>
					)}
				/>
			)}
			headerGapsSize={1}
		>
			<DisabledContent
				disabledContent={!!addMicrosoftTeamsMessagingAppChannelOverlay}
				disabledOverlay={addMicrosoftTeamsMessagingAppChannelOverlay}
			>
				<ModalContentWithSidebar
					gapsSize={2}
					sidebar={(
						<RichText>
							<Copy {...messages.sidebar} />
						</RichText>
					)}
				>
					<ModalTextSection>
						<RichText>
							<p>
								<FormattedMessage {...messages.description} />
							</p>
						</RichText>
					</ModalTextSection>

					<Form
						defaultValues={{
							messagingAppChannelId: alertDefinition.messagingAppChannels[0]?.id ?? 'none',
							recipients: alertDefinition.recipients,
							segmentChangeSizePercentage: alertDefinition.settings.segmentChangeSizePercentage,
							segmentChangeType: alertDefinition.settings.segmentChangeType,
							segmentName: alertDefinition.settings.segmentName,
						}}
						onSuccess={handleSubmit}
					>
						<CenteredFormWrapper>
							<FormRows>
								<WithPermission
									action={GraphQL.ActionWithWebsite.ManageAlertDefinitions}
									objectId={websiteId}
									objectType={ObjectType.Website}
								>
									{({ isAllowed }) => {
										if (isAllowed.yes === false) {
											return false;
										}

										return (
											<>
												<FormRow
													htmlFor="segmentName"
													label={(
														<FormattedMessage {...messages.fieldSegment} />
													)}
												>
													<SegmentSelectField
														name="segmentName"
														websiteId={websiteId}
													/>
												</FormRow>

												<FormRow
													htmlFor="segmentChangeType"
													label={(
														<FormattedMessage {...messages.fieldChangeType} />
													)}
												>
													<SegmentChangeTypeField
														name="segmentChangeType"
													/>
												</FormRow>

												<FormRow
													htmlFor="segmentChangeSizePercentage"
													label={(
														<FormattedMessage {...messages.fieldSensitivity} />
													)}
												>
													<SegmentPercentageField
														name="segmentChangeSizePercentage"
														websiteId={websiteId}
													/>
												</FormRow>
											</>
										);
									}}
								</WithPermission>

								<FormRow
									htmlFor="recipients"
									label={(
										<FormattedMessage {...messages.fieldRecipients} />
									)}
								>
									<RecipientsMultiselectField
										name="recipients"
										websiteId={websiteId}
									/>
								</FormRow>

								<WithPermission
									action={GraphQL.ActionWithWebsite.ManageAlertDefinitions}
									objectId={websiteId}
									objectType={ObjectType.Website}
								>
									{({ isAllowed }) => {
										if (isAllowed.yes === false) {
											return false;
										}

										return (
											<MessagingAppChannelFieldRow
												name="messagingAppChannelId"
												onInitiateAddMicrosoftChannel={initiateAddMicrosoftTeamsMessagingAppChannel}
												ref={messagingAppChannelFieldRowRef}
											/>
										);
									}}
								</WithPermission>
							</FormRows>

							<ButtonsLayout>
								<CancelButton />

								<BulkSaveDefinitionsButton numberOfAlertDefinitions={1} />
							</ButtonsLayout>
						</CenteredFormWrapper>
					</Form>
				</ModalContentWithSidebar>
			</DisabledContent>
		</ModalContainer>
	);
};



export default EditPagesEnteredOrLeftSegmentAlertDefinitionModal;
