import React, {
	PureComponent,
} from 'react';



type Props = {
	/** Custom star icon */
	customStarIcon?: React.ReactNode | ((input: { active: boolean }) => React.ReactNode),
	/** Custom icon for inactive star */
	inactiveStarIcon?: React.ReactNode,
	/** Rating percentage will be highlighted by rating stars */
	ratingPercentage: number,
	/** Possibility to change default count of visible stars */
	starsCount?: number,
};



class RatingStars extends PureComponent<Props> {

	static defaultProps = {
		starsCount: 5,
	};

	render() {
		const {
			customStarIcon,
			ratingPercentage,
			starsCount,
		} = this.props;

		const highlightedValueStyles = {
			width: ratingPercentage + '%',
		};

		let activeStar: React.ReactNode | string = '★';
		let inactiveStar: React.ReactNode | string = '☆';

		if (customStarIcon) {
			activeStar = typeof customStarIcon === 'function' ? customStarIcon({
				active: true,
			}) : customStarIcon;

			inactiveStar = typeof customStarIcon === 'function' ? customStarIcon({
				active: false,
			}) : customStarIcon;
		}

		return (
			<div className="rating-stars">
				<div
					className="rating-stars__stars rating-stars__stars--top"
					style={highlightedValueStyles}
				>
					{Array.from(Array(starsCount)).map((x, i) => (
						<span
							className="rating-stars__star"
							key={i + '-star-top'}
						>
							{activeStar}
						</span>
					))}
				</div>
				<div className="rating-stars__stars rating-stars__stars--bottom">
					{Array.from(Array(starsCount)).map((x, i) => (
						<span
							className="rating-stars__star"
							key={i + '-star-bottom'}
						>
							{inactiveStar}
						</span>
					))}
				</div>
			</div>
		);
	}

}



export default RatingStars;
