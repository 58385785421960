import type CK from '~/types/contentking';

import {
	useWebsiteOnPageRequestsWhitelistRulesQuery,
} from './useWebsiteOnPageRequestsWhitelistRules.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteOnPageRequestsWhitelistRules(websiteId: CK.WebsiteId | null) {
	const { data } = useWebsiteOnPageRequestsWhitelistRulesQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.adminSettings?.onPageRequestsWhitelistRules) ?? null;
}



export default useWebsiteOnPageRequestsWhitelistRules;
