import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import Form from '~/components/atoms/forms/basis/Form';
import HttpHeadersField from '~/components/app/HttpHeadersField';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useUpdateWebsiteMonitoringHttpHeadersMutation,
} from './WebsiteSettingsHttpHeadersForm.gql';

import useWebsiteHttpHeaders from '~/hooks/useWebsiteHttpHeaders';
import useWebsiteIsHttpAuthenticationEnabled from '~/hooks/useWebsiteIsHttpAuthenticationEnabled';



const messages = defineMessages({
	submitButton: {
		id: 'ui.general.applyChanges',
	},
});



type Props = {
	onSuccess: () => void,
	websiteId: CK.WebsiteId,
};

const WebsiteSettingsHttpHeadersForm: React.FC<Props> = (props) => {
	const {
		onSuccess,
		websiteId,
	} = props;

	const websiteHttpHeaders = useWebsiteHttpHeaders(websiteId);
	const websiteIsHttpAuthenticationEnabled = useWebsiteIsHttpAuthenticationEnabled(websiteId);

	const [updateWebsiteMonitoringHttpHeaders] = useUpdateWebsiteMonitoringHttpHeadersMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			const httpHeaders = values.httpHeaders.filter(
				(httpHeader) => !!httpHeader.value,
			);

			await updateWebsiteMonitoringHttpHeaders({
				variables: {
					httpHeaders,
					websiteId,
				},
			});

			onSuccess();
		},
		[
			onSuccess,
			updateWebsiteMonitoringHttpHeaders,
			websiteId,
		],
	);

	return (
		<Form
			defaultValues={{
				httpHeaders: websiteHttpHeaders,
			}}
			onSuccess={handleSubmit}
		>
			<HttpHeadersField
				isHttpAuthenticationEnabled={websiteIsHttpAuthenticationEnabled ?? false}
				name="httpHeaders"
			/>

			<ButtonsLayout
				alignment={ButtonsLayoutAlignment.Right}
				layout={ButtonsLayoutType.Steps}
			>
				<CancelButton />

				<SubmitButton>
					<FormattedMessage {...messages.submitButton} />
				</SubmitButton>
			</ButtonsLayout>
		</Form>
	);
};



export default WebsiteSettingsHttpHeadersForm;
