import React from 'react';

import {
	ButtonSize as SubmitButtonSize,
} from '~/components/patterns/buttons/Button';
import SubmitButtonAtom, {
	STYLE_ACTION,
	STYLE_ALERT,
} from '~/components/atoms/forms/components/SubmitButton';

import useFormContext from '~/hooks/useFormContext';
import useRoyalMode from '~/hooks/useRoyalMode';



type Props = {
	allowNoChanges?: boolean,
	children?: React.ReactNode,
	disallowImpersonation?: boolean,
	nextStepIcon?: boolean,
	positive?: boolean,
	size?: SubmitButtonSize,
	tabIndex?: number,
	testId?: string,
};

const SubmitButton: React.FC<Props> = (props) => {
	const {
		allowNoChanges = false,
		children,
		disallowImpersonation = false,
		nextStepIcon = false,
		positive = true,
		size,
		tabIndex,
		testId,
	} = props;

	const {
		dataHasChanged,
		isDisabled,
		isSubmitting,
		isValid,
	} = useFormContext();

	const royalMode = useRoyalMode();

	return (
		<SubmitButtonAtom
			disabled={isDisabled}
			inactive={!isValid || (!dataHasChanged && !allowNoChanges) || (disallowImpersonation && royalMode.isImpersonated === false)}
			nextStepIcon={nextStepIcon}
			progress={isSubmitting}
			size={size}
			style={positive ? STYLE_ACTION : STYLE_ALERT}
			tabIndex={tabIndex}
			testId={testId}
		>
			{children}
		</SubmitButtonAtom>
	);
};



export default SubmitButton;

export {
	SubmitButtonSize,
};
