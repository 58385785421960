import {
	Map,
} from 'immutable';

import {
	FORCE_UNAUTHENTICATED_SECTION,
	INITIALIZE_FROM_LOCAL_STORAGE,
	LOGIN_SUCCESSFUL,
	LOGOUT_SUCCESSFUL,
	MAKE_AUTHENTICATED,
	STORE_LOADED_TEAMS,
} from '~/actions';

import {
	createDisplayName,
	extractInitials,
} from '~/model/users';



function createDefaultState() {
	return new Map({
		authenticated: false,
		inAuthenticatedSection: false,
		userInfo: null,
	});
}



function processRawUserInfo(userInfo) {
	userInfo.displayName = createDisplayName(userInfo);
	userInfo.initials = extractInitials(userInfo.displayName);
	return userInfo;
}



export function authentication(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case FORCE_UNAUTHENTICATED_SECTION: {
			state = state.set('inAuthenticatedSection', false);
			break;
		}

		case INITIALIZE_FROM_LOCAL_STORAGE: {
			const {
				data,
			} = action;

			if (data.hasIn(['authentication', 'authenticated'])) {
				state = state.set('authenticated', data.getIn(['authentication', 'authenticated']));
			}

			if (data.hasIn(['authentication', 'inAuthenticatedSection'])) {
				state = state.set('inAuthenticatedSection', data.getIn(['authentication', 'inAuthenticatedSection']));
			}

			if (data.hasIn(['authentication', 'userInfo'])) {
				state = state.set('userInfo', data.getIn(['authentication', 'userInfo']));
			}

			if (data.hasIn(['authentication', 'userInfo']) && data.getIn(['authentication', 'userInfo'])) {
				state = state.mergeDeep({
					userInfo: state.get('userInfo').remove('onboarding_tour_in_progress'),
				});
			}

			break;
		}

		case MAKE_AUTHENTICATED: {
			state = state.set('authenticated', true);
			state = state.set('inAuthenticatedSection', true);
			break;
		}

		case LOGIN_SUCCESSFUL: {
			if (action.resetData) {
				state = createDefaultState();
			}

			state = state.set('authenticated', true);
			state = state.set('inAuthenticatedSection', true);

			if (action.response.fake === undefined) {
				state = state.set('userInfo', new Map(processRawUserInfo(action.response)));
			}

			break;
		}

		case LOGOUT_SUCCESSFUL: {
			state = state.set('authenticated', false);
			state = state.set('inAuthenticatedSection', false);
			break;
		}

		case STORE_LOADED_TEAMS: {
			const {
				teams,
			} = action;

			if (teams.length === 0) {
				state = state.set('authenticated', false);
				state = state.set('inAuthenticatedSection', false);
			}

			break;
		}

	}

	return state;
}
