import type Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import CodeValue from '~/components/patterns/values/CodeValue';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import EmptyValue from '~/components/app/EmptyValue';
import IgnoredValue from '~/components/patterns/issues/IgnoredValue';
import InternalLink from '~/components/patterns/links/InternalLink';
import MissingValue from '~/components/app/MissingValue';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';

import {
	loadPagesByIds,
} from '~/actions/pages/overview';

import {
	PageIssue,
} from '~/model/issuesNew';

import {
	pageBasicsSelector,
} from '~/state/pages/selectors';

import {
	notEmpty,
} from '~/utilities/typeCheck';



const ELLIPSIS_POPUP_ZINDEX = 2000;



const messages = defineMessages({
	columnCanonical: {
		id: 'ui.issueDetail.details.links.target',
	},
	columnLabel: {
		id: 'ui.issueDetail.details.links.label',
	},
	columnRedirectTarget: {
		id: 'ui.issueDetail.details.links.target',
	},
	columnStatusCode: {
		id: 'ui.issueDetail.details.links.statusCode',
	},
	columnUrl: {
		id: 'ui.issueDetail.details.links.url',
	},
	emptyLabel: {
		id: 'ui.general.empty',
	},
	externalDomain: {
		id: 'ui.pageDetail.linkData.externalDomain',
	},
	missingLabel: {
		id: 'ui.general.missing',
	},
});



type Props = {
	linkIssueName:
		| PageIssue.LinksBroken
		| PageIssue.LinksRedirected
		| PageIssue.LinksToCanonicalized,
	links: Immutable.List<any> | null,
	tableWidth: number,
	websiteId: CK.WebsiteId,
};

const LinksTable: React.FC<Props> = (props) => {
	const {
		links,
		linkIssueName,
		tableWidth,
		websiteId,
	} = props;

	const basics = useSelector(pageBasicsSelector);
	const dispatch = useDispatch();

	const linksList = React.useMemo(
		() => {
			if (links === null) {
				return null;
			}

			return links.sort((linkA, linkB) => {
				const isIgnoredA = linkA.get('ignoredCase') !== undefined;
				const isIgnoredB = linkB.get('ignoredCase') !== undefined;

				if (isIgnoredA && !isIgnoredB) {
					return 1;
				}

				if (!isIgnoredA && isIgnoredB) {
					return -1;
				}

				return 0;
			}).toJS() as Array<any>;
		},
		[
			links,
		],
	);

	const checkIsIgnored = React.useCallback(
		({ row }) => {
			return row.ignoredCase !== undefined;
		},
		[],
	);

	React.useEffect(
		() => {
			if (links === null) {
				return;
			}

			dispatch(
				loadPagesByIds(
					links
						.filter((link) => link.has('id'))
						.map((link) => link.get('id')),
				),
			);
		},
		[
			dispatch,
			links,
		],
	);

	return (
		<SmallTable
			columns={[
				{
					render: {
						cell: ({ row }) => {
							let value = row.anchor_text;
							let popupAltText;

							if (value === null || value === undefined) {
								value = (
									<MissingValue ellipsis={true} />
								);

								popupAltText = (
									<FormattedMessage {...messages.missingLabel} />
								);
							}

							if (value === '') {
								value = (
									<EmptyValue ellipsis={true} />
								);

								popupAltText = (
									<FormattedMessage {...messages.emptyLabel} />
								);
							}

							let content = (
								<Ellipsis
									altPopupText={popupAltText}
									popupZIndex={ELLIPSIS_POPUP_ZINDEX}
								>
									{value}
								</Ellipsis>
							);

							if (row.ignoredCase !== undefined) {
								content = (
									<IgnoredValue>
										{content}
									</IgnoredValue>
								);
							}

							return content;
						},
						header: (
							<FormattedMessage {...messages.columnLabel} />
						),
					},
					width: 200,
				},
				(linkIssueName === PageIssue.LinksBroken || linkIssueName === PageIssue.LinksRedirected) ? {
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis>
									<CodeValue>
										{row.status_code}
									</CodeValue>
								</Ellipsis>
							);
						},
						header: (
							<FormattedMessage {...messages.columnStatusCode} />
						),
					},
					width: 80,
				} : null,
				{
					render: {
						cell: ({ row }) => {
							let displayUrl;
							let displayUrlTooltip;

							if (row.id) {
								const url = basics.get(row.id.toString());

								if (url) {
									displayUrl = (
										<InternalLink
											ellipsis={true}
											routeName="website.pages.detail"
											routeParams={{
												id: url.get('id'),
												websiteId,
											}}
										>
											{url.get('url')}
										</InternalLink>
									);

									displayUrlTooltip = (
										<InternalLink
											routeName="website.pages.detail"
											routeParams={{
												id: url.get('id'),
												websiteId,
											}}
										>
											{url.get('url')}
										</InternalLink>
									);
								}
							} else {
								displayUrl = displayUrlTooltip = row.url;
							}

							return (
								<Ellipsis
									altPopupText={displayUrlTooltip}
									popupZIndex={ELLIPSIS_POPUP_ZINDEX}
								>
									{displayUrl}
								</Ellipsis>
							);
						},
						header: (
							<FormattedMessage {...messages.columnUrl} />
						),
					},
					width: 450,
				},
				linkIssueName === PageIssue.LinksRedirected ? {
					render: {
						cell: ({ row }) => {
							let displayUrl;
							let displayUrlTooltip;

							if (row.redirect) {
								if (!row.redirect.is_crossdomain) {
									displayUrl = (
										<InternalLink
											ellipsis={true}
											routeName="website.pages.detail"
											routeParams={{
												id: row.redirect.id,
												websiteId,
											}}
										>
											{row.redirect.url}
										</InternalLink>
									);

									displayUrlTooltip = (
										<InternalLink
											routeName="website.pages.detail"
											routeParams={{
												id: row.redirect.id,
												websiteId,
											}}
										>
											{row.redirect.url}
										</InternalLink>
									);
								} else {
									displayUrl = displayUrlTooltip = row.redirect.url;
								}
							} else {
								displayUrl = displayUrlTooltip = <FormattedMessage {...messages.externalDomain} />;
							}

							return (
								<Ellipsis
									altPopupText={displayUrlTooltip}
									popupZIndex={ELLIPSIS_POPUP_ZINDEX}
								>
									{displayUrl}
								</Ellipsis>
							);
						},
						header: (
							<FormattedMessage {...messages.columnRedirectTarget} />
						),
					},
					width: 300,
				} : null,
				linkIssueName === PageIssue.LinksToCanonicalized ? {
					render: {
						cell: ({ row }) => {
							let displayUrl;
							let displayUrlTooltip;

							if (!row.canonical.is_crossdomain) {
								displayUrl = (
									<InternalLink
										ellipsis={true}
										routeName="website.pages.detail"
										routeParams={{
											id: row.canonical.id,
											websiteId,
										}}
									>
										{row.canonical.url}
									</InternalLink>
								);

								displayUrlTooltip = (
									<InternalLink
										routeName="website.pages.detail"
										routeParams={{
											id: row.canonical.id,
											websiteId,
										}}
									>
										{row.canonical.url}
									</InternalLink>
								);
							} else {
								displayUrl = displayUrlTooltip = row.canonical.url;
							}

							return (
								<Ellipsis
									altPopupText={displayUrlTooltip}
									popupZIndex={ELLIPSIS_POPUP_ZINDEX}
								>
									{displayUrl}
								</Ellipsis>
							);
						},
						header: (
							<FormattedMessage {...messages.columnCanonical} />
						),
					},
					width: 450,
				} : null,
			].filter(notEmpty)}
			key={websiteId}
			rows={linksList}
			semitransparentGetter={checkIsIgnored}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(LinksTable);
