import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useUpdateWebsiteAdminNonstandardBehaviorMutation,
	useWebsiteAdminNonstandardBehaviorFormQuery,
} from './WebsiteAdminNonstandardBehaviorForm.gql';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useWebsiteIsDomTracked from '~/hooks/useWebsiteIsDomTracked';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';

import getOptionLabel from '~/utilities/getOptionLabel';



const analyticsServicesDetectionSourceOptions = [
	{
		name: 'dom',
		label: 'DOM',
	},
	{
		name: 'network',
		label: 'Network',
	},
	{
		name: 'src',
		label: 'SRC',
	},
];

const detectedContentDeliveryNetworkOptions = [
	{
		label: GraphQL.DetectedContentDeliveryNetwork.Automatic,
		name: GraphQL.DetectedContentDeliveryNetwork.Automatic,
	},
	{
		label: GraphQL.DetectedContentDeliveryNetwork.Cloudflare,
		name: GraphQL.DetectedContentDeliveryNetwork.Cloudflare,
	},
	{
		label: GraphQL.DetectedContentDeliveryNetwork.Akamai,
		name: GraphQL.DetectedContentDeliveryNetwork.Akamai,
	},
	{
		label: GraphQL.DetectedContentDeliveryNetwork.Cloudfront,
		name: GraphQL.DetectedContentDeliveryNetwork.Cloudfront,
	},
	{
		label: GraphQL.DetectedContentDeliveryNetwork.Fastly,
		name: GraphQL.DetectedContentDeliveryNetwork.Fastly,
	},
];

type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminNonstandardBehaviorForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const isDomTracked = useWebsiteIsDomTracked(websiteId);
	const isLighthouseMonitored = useWebsiteIsLighthouseMonitored(websiteId);

	const isAllowedToManageNonstandardBehavior = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageAdminNonstandardBehavior,
	);

	const { data } = useWebsiteAdminNonstandardBehaviorFormQuery({
		variables: {
			websiteId,
		},
	});

	const [updateWebsiteAdminNonstandardBehavior] = useUpdateWebsiteAdminNonstandardBehaviorMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteAdminNonstandardBehavior({
				variables: {
					analyticsServicesDetectionSource: values.analyticsServicesDetectionSource,
					areOtherSearchEnginesNotRelevant: values.areOtherSearchEnginesNotRelevant,
					detectedContentDeliveryNetwork: values.detectedContentDeliveryNetwork,
					discoverAmpPages: values.discoverAmpPages,
					isDiscoveringNewUrlsEnabled: values.isDiscoveringNewUrlsEnabled,
					websiteId,
				},
			});
		},
		[
			updateWebsiteAdminNonstandardBehavior,
			websiteId,
		],
	);

	const adminSettings = data?.website?.adminSettings ?? null;

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageNonstandardBehavior}
			isForAdmins={true}
			title="Non-standard behavior"
		>
			<DisplayPart>
				<FormRows>
					{(isDomTracked || isLighthouseMonitored) && (
						<FormRow label="Analytics Services detection source">
							<StaticList focusTarget="analyticsServicesDetectionSource">
								{getOptionLabel(analyticsServicesDetectionSourceOptions, adminSettings?.analyticsServicesDetectionSource ?? null)}
							</StaticList>
						</FormRow>
					)}

					<FormRow label="Discover AMP pages">
						<StaticText focusTarget="discoverAmpPages">
							{adminSettings?.discoverAmpPages ? 'enabled' : 'disabled'}
						</StaticText>
					</FormRow>

					<FormRow label="Discover new pages">
						<StaticText focusTarget="isDiscoveringNewUrlsEnabled">
							{adminSettings?.isDiscoveringNewUrlsEnabled ? 'enabled' : 'disabled'}
						</StaticText>
					</FormRow>

					<FormRow label="Loose robots.txt evaluation">
						<StaticText focusTarget="areOtherSearchEnginesNotRelevant">
							{adminSettings?.areOtherSearchEnginesNotRelevant ? 'enabled' : 'disabled'}
						</StaticText>
					</FormRow>

					<FormRow label="Detected CDN">
						<StaticText focusTarget="detectedContentDeliveryNetwork">
							{adminSettings?.detectedContentDeliveryNetwork ?? GraphQL.DetectedContentDeliveryNetwork.Automatic}
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				{adminSettings !== null && (
					<Form
						defaultValues={{
							analyticsServicesDetectionSource: adminSettings.analyticsServicesDetectionSource,
							areOtherSearchEnginesNotRelevant: adminSettings.areOtherSearchEnginesNotRelevant,
							detectedContentDeliveryNetwork: adminSettings.detectedContentDeliveryNetwork,
							discoverAmpPages: adminSettings.discoverAmpPages,
							isDiscoveringNewUrlsEnabled: adminSettings.isDiscoveringNewUrlsEnabled,
						}}
						onSuccess={handleSubmit}
					>
						<FormRows>
							{(isDomTracked || isLighthouseMonitored) && (
								<FormRow
									htmlFor="analyticsServicesDetectionSource"
									label="Analytics Services detection source"
								>
									<SelectField
										name="analyticsServicesDetectionSource"
										options={analyticsServicesDetectionSourceOptions}
									/>
								</FormRow>
							)}

							<FormRow
								htmlFor="discoverAmpPages"
								label="Discover AMP pages"
							>
								<CheckboxField
									label="enabled"
									name="discoverAmpPages"
									width={false}
								/>
							</FormRow>

							<FormRow
								htmlFor="isDiscoveringNewUrlsEnabled"
								label="Discover new pages"
							>
								<CheckboxField
									label="enabled"
									name="isDiscoveringNewUrlsEnabled"
									width={false}
								/>
							</FormRow>

							<FormRow
								htmlFor="areOtherSearchEnginesNotRelevant"
								label="Loose robots.txt evaluation"
							>
								<CheckboxField
									label="enabled"
									name="areOtherSearchEnginesNotRelevant"
									width={false}
								/>
							</FormRow>

							<FormRow
								htmlFor="detectedContentDeliveryNetwork"
								label="Detected CDN"
							>
								<SelectField
									name="detectedContentDeliveryNetwork"
									options={detectedContentDeliveryNetworkOptions}
								/>
							</FormRow>
						</FormRows>

						<FormErrorMessages />

						<ButtonsLayout>
							<CancelButton />
							<SaveSubmitButton />
						</ButtonsLayout>
					</Form>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default WebsiteAdminNonstandardBehaviorForm;
