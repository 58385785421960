import React from 'react';



function useFlashMessage<Node = React.ReactNode>(message: Node): {
	flashMessage: Node | null,
	showFlashMessage: (duration?: number) => void,
} {
	const [visible, setVisible] = React.useState(false);
	const timeout = React.useRef<ReturnType<typeof setTimeout>>();

	const showFlashMessage = React.useCallback(
		(duration: number = 5000) => {
			clearTimeout(timeout.current);

			timeout.current = setTimeout(() => {
				setVisible(false);
			}, duration);

			setVisible(true);
		},
		[],
	);

	React.useEffect(
		() => {
			return () => {
				clearTimeout(timeout.current);
			};
		},
		[],
	);

	return {
		flashMessage: visible ? message : null,
		showFlashMessage,
	};
}



export default useFlashMessage;
