import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CreditCardDetails from './CreditCardDetails';
import FormRow from '../atoms/forms/basis/FormRow';
import StaticText from '../atoms/forms/components/StaticText';

import {
	METHOD_CARD,
	METHOD_INVOICE,
	METHOD_PAYPAL,
} from '~/model/payments';



const messages = defineMessages({
	payByInvoice: {
		id: 'ui.newTeam.form.invoice',
		defaultMessage: 'Pay by invoice',
	},
	paymentMethod: {
		id: 'ui.paymentOverview.method',
	},
});



class PaymentMethodDetails extends Component {

	render() {
		const {
			billingDetails,
		} = this.props;

		if (!billingDetails || !billingDetails.get('payment_method') || !billingDetails.get('payment_method').get('type')) {
			return (
				<FormRow
					label={(
						<FormattedMessage {...messages.paymentMethod} />
					)}
				>
					<StaticText />
				</FormRow>
			);
		}

		if (billingDetails.get('payment_method').get('type') === METHOD_CARD) {
			return (
				<CreditCardDetails
					billingDetails={billingDetails}
				/>
			);
		}

		if (billingDetails.get('payment_method').get('type') === METHOD_PAYPAL) {
			return (
				<FormRow
					label={(
						<FormattedMessage {...messages.paymentMethod} />
					)}
				>
					<StaticText>PayPal</StaticText>
				</FormRow>
			);
		}

		if (billingDetails.get('payment_method').get('type') === METHOD_INVOICE) {
			return (
				<FormRow
					label={(
						<FormattedMessage {...messages.paymentMethod} />
					)}
				>
					<StaticText>
						<FormattedMessage {...messages.payByInvoice} />
					</StaticText>
				</FormRow>
			);
		}

		return false;
	}

}

PaymentMethodDetails.propTypes = {
	billingDetails: PropTypes.object,
};



export default PaymentMethodDetails;
