import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import BillingCyclePerMonthName from '../../../names/BillingCyclePerMonthName';
import DiscountOverview from '~/components/patterns/pricing/DiscountOverview';
import DiscountValue from '~/components/patterns/pricing/DiscountValue';
import OldPrice from '~/components/patterns/pricing/OldPrice';
import Price from '~/components/patterns/pricing/Price';
import PriceSideNote from '~/components/patterns/pricing/PriceSideNote';
import PriceSummary, {
	PriceSummarySize,
} from '~/components/patterns/pricing/PriceSummary';

import useAllowedPlans from '~/hooks/useAllowedPlans';
import useSignupPrice from '~/hooks/useSignupPrice';

import {
	isEnterprisePlan,
} from '~/model/plans';



const messages = defineMessages({
	contactForDetails: {
		id: 'ui.plan.contactForDetails',
	},
	priceStartingAtPrefix: {
		id: 'ui.plan.price.startingAtPrefix',
	},
	unavailablePlanBecauseAccountTooLarge: {
		id: 'ui.plans.unavailablePlan.accountTooLarge',
	},
	unavailablePlanBecauseDisallowed: {
		id: 'ui.plans.unavailablePlan.disallowed',
	},
});



export enum PlanPriceSummaryView {
	Full = 'full',
	Overview = 'overview',
	Reduced = 'reduced',
}

type Props = {
	accountId: number,
	disabledReason: 'accountTooLarge' | 'disallowed' | undefined,
	plan: GraphQL.AccountPlan,
	/** Possibility to set compact or overview version of price summary */
	view?: PlanPriceSummaryView,
};

const PlanPriceSummary: React.FC<Props> = (props) => {
	const {
		accountId,
		disabledReason,
		plan,
		view = PlanPriceSummaryView.Full,
	} = props;

	const allowedPlans = useAllowedPlans(accountId);
	const signupPrice = useSignupPrice(accountId, false);

	const isDisabled = disabledReason !== undefined;

	if (isDisabled) {
		return (
			<PriceSideNote>
				{disabledReason === 'accountTooLarge' && (
					<FormattedMessage {...messages.unavailablePlanBecauseAccountTooLarge} />
				)}

				{disabledReason === 'disallowed' && (
					<FormattedMessage {...messages.unavailablePlanBecauseDisallowed} />
				)}
			</PriceSideNote>
		);
	}

	if (allowedPlans === null || !signupPrice.isReady) {
		return null;
	}

	const calculatedPrice = signupPrice.calculatePrice({
		plan,
	});

	const hasOtherDiscounts = calculatedPrice.discounts.length > 0;

	let discount;

	if (hasOtherDiscounts && calculatedPrice.discountPercentage > 0 && calculatedPrice.discountSum === 0) {
		discount = (
			<DiscountValue>
				<FormattedNumber
					style="percent"
					value={-1 * calculatedPrice.discountPercentage}
				/>
			</DiscountValue>
		);
	}

	let oldPrice;

	if (hasOtherDiscounts && (calculatedPrice.discountPercentage > 0 || calculatedPrice.discountSum > 0)) {
		oldPrice = (
			<OldPrice
				currency={signupPrice.currency}
				value={calculatedPrice.priceWithoutDiscountsMonthly}
			/>
		);
	}

	const discountOverview = discount ? (
		<DiscountOverview
			discount={discount}
			oldPrice={oldPrice}
		/>
	) : (
		<span>&nbsp;</span>
	);

	const showPrice = !isEnterprisePlan(plan) || allowedPlans.includes(plan);
	const showBillingCycleInfo = view !== PlanPriceSummaryView.Reduced && showPrice;
	const showPricePrefix = view === PlanPriceSummaryView.Full && showPrice;

	return (
		<PriceSummary
			billingCycleInfo={(
				showBillingCycleInfo
					? (
						<BillingCyclePerMonthName
							billingCycle={signupPrice.billingCycle}
							inline={view === PlanPriceSummaryView.Overview}
							useMonthNotation={true}
						/>
					)
					: null
			)}
			price={(
				showPrice
					? (
						<Price
							currency={signupPrice.currency}
							value={calculatedPrice.priceWithDiscountsMonthly}
						/>
					)
					: <FormattedMessage {...messages.contactForDetails} />
			)}
			pricePrefix={(
				showPricePrefix
					? discountOverview
					: <></>
			)}
			size={(
				view === PlanPriceSummaryView.Overview
					? PriceSummarySize.Small
					: PriceSummarySize.Default
			)}
		/>
	);
};



export default PlanPriceSummary;
