import type CK from '~/types/contentking';

import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';



function useAccountWebsiteCount(accountId: CK.AccountId | null): number | null {
	const websiteSizes = useAccountWebsiteSizes(accountId);

	return websiteSizes?.websites.length ?? null;
}



export default useAccountWebsiteCount;
