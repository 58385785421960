import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import {
	compileDefinitionTermsNew,
} from './compileDefinitionTerms';
import Caption, {
	CaptionStyle,
} from '~/components/patterns/headings/Caption';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import ExternalLink from '~/components/patterns/links/ExternalLink';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import usePageDetailSourceMode from '~/hooks/usePageDetailSourceMode';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	useTwitterCardsBlockPartPrimaryQuery,
	useTwitterCardsBlockPartSrcQuery,
} from './TwitterCardsBlockPart.gql';



const messages = defineMessages({
	title: {
		id: 'ui.pageDetail.twitterCardsBlock.title',
	},
	twitterCard: {
		id: 'ui.contentData.twitter_card',
	},
	twitterDescription: {
		id: 'ui.contentData.twitter_description',
	},
	twitterImage: {
		id: 'ui.contentData.twitter_image',
	},
	twitterSite: {
		id: 'ui.contentData.twitter_site',
	},
	twitterTitle: {
		id: 'ui.contentData.twitter_title',
	},
});



const TwitterCardsBlockPart: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const pageDetailSourceMode = usePageDetailSourceMode();
	const websiteId = useWebsiteId();

	const {
		data: primaryData,
	} = usePageDetailPropertiesQuery(
		useTwitterCardsBlockPartPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	const {
		data: srcData,
	} = usePageDetailPropertiesQuery(
		useTwitterCardsBlockPartSrcQuery,
		legacyUrlId,
		websiteId,
		{
			skip: pageDetailSourceMode !== GraphQL.PageDetailSourceMode.Compare,
		},
	);

	const primaryPropertyData = primaryData?.lookupPageByLegacyId?.pageTypeData?.primaryProperties ?? null;
	const srcPropertyData = srcData?.lookupPageByLegacyId?.pageTypeData?.srcProperties ?? null;

	return (
		<DefinitionTermsList>
			<Caption style={CaptionStyle.Highlighted}>
				<FormattedMessage {...messages.title} />
			</Caption>
			{compileDefinitionTermsNew(
				[
					{
						alwaysVisible: true,
						property: 'twitterCard',
						name: 'twitter_card',
						label: (
							<FormattedMessage {...messages.twitterCard} />
						),
					},
					{
						alwaysVisible: true,
						property: 'twitterSite',
						name: 'twitter_site',
						label: (
							<FormattedMessage {...messages.twitterSite} />
						),
					},
					{
						alwaysVisible: false,
						property: 'twitterTitle',
						name: 'twitter_title',
						label: (
							<FormattedMessage {...messages.twitterTitle} />
						),
					},
					{
						alwaysVisible: false,
						property: 'twitterDescription',
						name: 'twitter_description',
						label: (
							<FormattedMessage {...messages.twitterDescription} />
						),
					},
					{
						alwaysVisible: false,
						property: 'twitterImage',
						name: 'twitter_image',
						label: (
							<FormattedMessage {...messages.twitterImage} />
						),
						valueFormatter: (value) => {
							if (value && value !== '') {
								return (
									<ExternalLink href={value}>
										{value}
									</ExternalLink>
								);
							}

							return value;
						},
					},
				],
				{
					compare: pageDetailSourceMode === GraphQL.PageDetailSourceMode.Compare,
					propertyData: primaryPropertyData,
					srcPropertyData,
				},
			)}
		</DefinitionTermsList>
	);
};



export default React.memo(TwitterCardsBlockPart);
