import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import ChangeSummary, {
	PAGES_DIFF_BUNDLE,
} from '~/components/logic/pricing/ChangeSummary';
import DisplayPart from '../basis/DisplayPart';
import EditableFormWrapper from '../basis/EditableFormWrapper';
import EditablePart from '../basis/EditablePart';
import Form from '../basis/Form';
import FormRow from '../basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import PageCapacityField from '~/components/app/PageCapacityField';
import PaymentAuthorizationContext from '../../../logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '../../../logic/PaymentAuthorizationError';
import PaymentCancelButton from '../../../logic/PaymentCancelButton';
import PaymentSubmitButton from '../../../logic/PaymentSubmitButton';
import StaticText from '../components/StaticText';
import SubscriptionChangeSummary from '~/components/app/SubscriptionChangeSummary';
import WebsiteStatus from '~/components/app/WebsiteStatus';
import WithPermission from '../../../logic/access/WithPermission';

import {
	ObjectType,
} from '~/model/permissions';

import {
	calculateCostDetails,
} from '~/model/pricing';

import {
	normalizePagesAmount,
} from '~/model/pricing/tariffs';



const messages = defineMessages({
	cannotSubmitDueToPaymentIssues: {
		id: 'ui.subscriptionForm.cannotSubmitDueToPaymentIssues',
	},
	formErrors: {
		blank: {
			id: 'ui.formErrors.blank',
		},
	},
	pagesLabel: {
		id: 'ui.websiteSettings.plan.pageCapacity',
	},
	saveButton: {
		id: 'ui.general.saveButton',
	},
	title: {
		id: 'ui.websiteSettings.plan.header.agency',
	},
});

const validations = {
	page_capacity: [
		{
			message: (
				<FormattedMessage {...messages.formErrors.blank} />
			),
			field: 'page_capacity',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
};



class EditWebsiteSubscriptionForm extends Component {

	constructor(props, context) {
		const {
			tariffs,
			team,
			teamBilling,
		} = props;

		super(props, context);

		this._handleFormChange = this._handleFormChange.bind(this);
		this._handleFormSubmit = this._handleFormSubmit.bind(this);
		this._handleToggleEditMode = this._handleToggleEditMode.bind(this);

		this.state = {
			priceOld: (team.get('is_billable') && teamBilling && teamBilling.get('tax'))
				? calculateCostDetails({
					account: team,
					accountBilling: teamBilling,
					tariffs,
				})
				: null,
			showChangeSummary: false,
		};
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			tariffs: prevTarrifs,
			team: prevTeam,
			teamBilling: prevTeamBilling,
		} = this.props;

		const {
			tariffs: nextTariffs,
			team: nextTeam,
			teamBilling: nextTeamBilling,
		} = nextProps;

		if (prevTarrifs !== nextTariffs || prevTeam !== nextTeam || prevTeamBilling !== nextTeamBilling) {
			this.setState({
				priceOld: (nextTeam.get('is_billable') && nextTeamBilling?.get('tax'))
					? calculateCostDetails({
						account: nextTeam,
						accountBilling: nextTeamBilling,
						tariffs: nextTariffs,
					})
					: null,
			});
		}
	}



	_handleToggleEditMode(editMode) {
		if (!editMode) {
			this.setState({
				showChangeSummary: false,
			});
		}
	}



	_handleFormChange(field, name, values) {
		const {
			tariffs,
			team,
			teamBilling,
			website,
		} = this.props;

		const pageBundleUsage = team.get('websites').reduce((reduction, website) => {
			return reduction + website.get('page_capacity');
		}, 0) - website.get('page_capacity');

		if (team.get('page_bundle') < (pageBundleUsage + values.page_capacity)) {
			const necessaryExtraPageBundle = normalizePagesAmount({
				accountType: team.get('type'),
				numberOfPages: pageBundleUsage + values.page_capacity,
				tariff: team.get('tariff'),
			}) - team.get('page_bundle');

			if (team.get('is_billable') && teamBilling.get('tax')) {
				this.setState({
					priceNew: calculateCostDetails({
						account: team,
						accountBilling: teamBilling,
						pageBundle: team.get('page_bundle') + necessaryExtraPageBundle,
						tariffs,
					}),
					showChangeSummary: true,
				});
			}
		} else {
			this.setState({
				priceNew: null,
				showChangeSummary: false,
			});
		}
	}



	_handleFormSubmit(values) {
		const {
			submitCallback,
			tariffs,
			team,
			teamBilling,
		} = this.props;

		return submitCallback(values).then(() => {
			if (team.get('is_billable') && teamBilling.get('tax')) {
				this.setState({
					priceOld: calculateCostDetails({
						account: team,
						accountBilling: teamBilling,
						tariffs,
					}),
				});
			}
		});
	}



	_renderOverview() {
		const {
			website,
		} = this.props;

		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.pagesLabel} />
					)}
				>
					<StaticText>
						<AttachedIcon
							ellipsis={false}
							icon={(
								<WebsiteStatus
									showStatus={[GraphQL.WebsiteProblem.CapacityReached, GraphQL.WebsiteProblem.NearCapacity]}
									websiteId={website.get('id')}
								/>
							)}
							iconPosition={AttachedIconPosition.Suffix}

						>
							<FormattedNumber value={website.get('pages_count')} />
								&nbsp;/&nbsp;
							<FormattedNumber value={website.get('page_capacity')} />
								&nbsp;
							<span className="percentage">
								({(
									<FormattedNumber
										maximumFractionDigits={2}
										minimumFractionDigits={1}
										style="percent"
										value={website.get('pages_count') / website.get('pages_limit')}
									/>
								)})
							</span>
						</AttachedIcon>
					</StaticText>
				</FormRow>
			</FormRows>
		);
	}



	_renderChangesOverview(values) {
		const {
			team,
			website,
		} = this.props;

		const {
			priceNew,
			priceOld,
		} = this.state;

		if (!priceNew || !priceOld) {
			return false;
		}

		const pageBundleUsage = team.get('websites').reduce((reduction, website) => {
			return reduction + website.get('page_capacity');
		}, 0) - website.get('page_capacity');

		const newPageBundle = normalizePagesAmount({
			accountType: team.get('type'),
			numberOfPages: pageBundleUsage + values.page_capacity,
			tariff: team.get('tariff'),
		});

		if (team.get('billing_entity') !== 'old') {
			return (
				<SubscriptionChangeSummary
					accountId={team.get('id')}
					hidePrice={team.get('is_before_first_billing') && !team.get('is_billable')}
					pageBundle={newPageBundle}
				/>
			);
		}

		return (
			<ChangeSummary
				accountId={team.get('id')}
				allowedBillingCycles={team.get('allowed_billing_cycles')}
				billingCycleNew={team.get('billing_cycle')}
				billingCycleOld={team.get('billing_cycle')}
				currency={team.get('currency')}
				hidePrice={team.get('is_before_first_billing') && !team.get('is_billable')}
				pagesDiff={PAGES_DIFF_BUNDLE}
				pagesNew={newPageBundle}
				pagesOld={team.get('page_bundle')}
				planNew={team.get('plan')}
				planOld={team.get('plan')}
				priceNew={priceNew.subtotal}
				priceOld={priceOld.subtotal}
				taxType={priceNew.tax > 0 ? 'Vat' : null}
			/>
		);
	}



	_renderForm() {
		const {
			team,
			website,
		} = this.props;

		const {
			showChangeSummary,
		} = this.state;

		const defaultValues = {
			page_capacity: website.get('page_capacity'),
		};

		return (
			<PaymentAuthorizationContext
				accountId={team.get('id')}
				onSubmit={this._handleFormSubmit}
				useModal={true}
			>
				{({ formRef, handleFormSuccess }) => (
					<Form
						defaultFocus="name"
						defaultValues={defaultValues}
						onChangeCallback={this._handleFormChange}
						onSuccess={handleFormSuccess}
						ref={formRef}
						validations={validations}
					>
						{({ values }) => (
							<>
								<FormRows>
									<FormRow
										htmlFor="page_capacity"
										label={(
											<FormattedMessage {...messages.pagesLabel} />
										)}
									>
										<PageCapacityField
											accountId={team.get('id')}
											name="page_capacity"
											numberOfPages={website.get('pages_count')}
										/>
									</FormRow>

									{showChangeSummary && this._renderChangesOverview(values)}
								</FormRows>

								<PaymentAuthorizationError />

								<ButtonsLayout>
									<PaymentCancelButton />

									<PaymentSubmitButton
										isDisabled={team.get('has_payment_issues')}
										tooltip={team.get('has_payment_issues') && (
											<FormattedMessage {...messages.cannotSubmitDueToPaymentIssues} />
										)}
									>
										<FormattedMessage {...messages.saveButton} />
									</PaymentSubmitButton>
								</ButtonsLayout>
							</>
						)}
					</Form>
				)}
			</PaymentAuthorizationContext>
		);
	}



	render() {
		const {
			website,
		} = this.props;

		return (
			<WithPermission
				action={GraphQL.ActionWithWebsite.UpdatePageCapacity}
				objectId={website.get('id')}
				objectType={ObjectType.Website}
				showMessage={false}
			>
				{({ isAllowed }) => (
					<EditableFormWrapper
						isAllowed={isAllowed}
						onEnterEditModeCallback={this._handleToggleEditMode}
						title={(
							<FormattedMessage {...messages.title} />
						)}
					>
						<DisplayPart>
							{this._renderOverview()}
						</DisplayPart>

						<EditablePart>
							{this._renderForm()}
						</EditablePart>
					</EditableFormWrapper>
				)}
			</WithPermission>
		);
	}

}

EditWebsiteSubscriptionForm.propTypes = {
	team: PropTypes.object.isRequired,
	teamBilling: PropTypes.object.isRequired,
	website: PropTypes.object.isRequired,
};



export default EditWebsiteSubscriptionForm;
