import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import usePermission from '~/hooks/usePermission';

import {
	ObjectType,
} from '~/model/permissions';



function useIsAllowedWithAccount(
	accountId: CK.AccountId | null,
	action: GraphQL.ActionWithAccount,
) {
	const isAllowed = usePermission();

	return isAllowed({
		action,
		objectId: accountId,
		objectType: ObjectType.Account,
	});
}



export default useIsAllowedWithAccount;
