import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import ExtraInvoiceDetailsFields from '~/components/app/ExtraInvoiceDetailsFields';
import ExtraInvoiceDetailsOverview from '~/components/app/ExtraInvoiceDetailsOverview';
import Form from '~/components/atoms/forms/basis/Form';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';

import {
	useUpdateExtraInvoiceDetailsInBillingTabMutation,
} from './ExtraInvoiceDetailsBlock.gql';



const messages = defineMessages({
	title: {
		id: 'ui.teamDetail.billing.invoiceDetailsTitle',
	},
});



type Props = {
	accountId: CK.AccountId,
	extraInvoiceDetails: GraphQL.BillingExtraInvoiceDetails | null,
};

const ExtraInvoiceDetailsBlock: React.FC<Props> = (props) => {
	const {
		accountId,
		extraInvoiceDetails,
	} = props;

	const [updateExtraInvoiceDetails] = useUpdateExtraInvoiceDetailsInBillingTabMutation();

	const handleSubmit = React.useCallback(
		(values) => {
			return updateExtraInvoiceDetails({
				variables: {
					accountId,
					customerDetails: {
						extraInvoiceDetails: values,
					},
				},
			});
		},
		[
			accountId,
			updateExtraInvoiceDetails,
		],
	);

	return (
		<EditableFormWrapper
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<ExtraInvoiceDetailsOverview
					extraInvoiceDetails={extraInvoiceDetails}
				/>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultFocus="attn"
					defaultValues={{
						attn: extraInvoiceDetails?.attn ?? null,
						customerReference: extraInvoiceDetails?.customerReference ?? null,
						purchaseOrderNumber: extraInvoiceDetails?.purchaseOrderNumber ?? null,
						supplierNumber: extraInvoiceDetails?.supplierNumber ?? null,
					}}
					onSuccess={handleSubmit}
				>
					<ExtraInvoiceDetailsFields />

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default ExtraInvoiceDetailsBlock;
