import React from 'react';

import AvailabilityLabel, {
	AvailabilityLabelStatus,
	AvailabilityLabelSize as AvailabilityListSize,
} from '~/components/patterns/statuses/AvailabilityLabel';
import List from '~/components/patterns/lists/List';



type Props = {
	items: Array<{
		/** Message for optional hint attached to label */
		hint?: React.ReactNode,
		/** Disabled state of availability */
		isDisabled?: boolean,
		label: React.ReactNode,
		/** Availability status */
		status: AvailabilityLabelStatus,
	}>,
	/** Size of items */
	size?: AvailabilityListSize,
};



const AvailabilityList: React.FC<Props> = (props) => {
	const {
		items,
		size = AvailabilityListSize.Default,
	} = props;

	return (
		<List>
			{items.map((item, index) => {
				return (
					<AvailabilityLabel
						hint={item.hint}
						isDisabled={item.isDisabled}
						key={'availability-label-' + index}
						size={size}
						status={item.status}
					>
						{item.label}
					</AvailabilityLabel>
				);
			})}
		</List>
	);
};



export default AvailabilityList;

export {
	AvailabilityLabelStatus,
	AvailabilityListSize,
};
