import React from 'react';

import IconPickerTextField from '~/components/patterns/forms/fields/IconPickerTextField';
import SegmentIcon, {
	SegmentIconType,
} from '~/components/patterns/icons/SegmentIcon';



const ICON_PICKER_ORDER = [
	SegmentIconType.Bell,
	SegmentIconType.Diamond,
	SegmentIconType.CrownKing,
	SegmentIconType.CrownQueen,
	SegmentIconType.Flag,
	SegmentIconType.Target,
	SegmentIconType.MoneyBag,
	SegmentIconType.Coins,
	SegmentIconType.GraphIncrease,
	SegmentIconType.GraphDecrease,
	SegmentIconType.StatsRising,
	SegmentIconType.StatsFailing,
	SegmentIconType.RobotAllowed,
	SegmentIconType.RobotDisallowed,
	SegmentIconType.Calendar,
	SegmentIconType.Stopwatch,
	SegmentIconType.Hourglass,
	SegmentIconType.Mobile,
	SegmentIconType.Categories,
	SegmentIconType.Blog,
	SegmentIconType.Article,
	SegmentIconType.Archive,
	SegmentIconType.Magazine,
	SegmentIconType.Book,
	SegmentIconType.Support,
	SegmentIconType.Contact,
	SegmentIconType.User,
	SegmentIconType.Toolbox,
	SegmentIconType.Briefcase,
	SegmentIconType.Health,
	SegmentIconType.Megaphone,
	SegmentIconType.PriceTag,
	SegmentIconType.Product,
	SegmentIconType.Discount,
	SegmentIconType.InStockBag,
	SegmentIconType.OutOfStockBag,
	SegmentIconType.ShoppingCart,
	SegmentIconType.ShoppingCartCheck,
	SegmentIconType.ShoppingCartDisable,
	SegmentIconType.Delivery,
	SegmentIconType.Gift,
	SegmentIconType.Key,
	SegmentIconType.Male,
	SegmentIconType.Female,
	SegmentIconType.Unisex,
	SegmentIconType.Child,
	SegmentIconType.Sport,
	SegmentIconType.Holiday,
	SegmentIconType.Art,
	SegmentIconType.Image,
	SegmentIconType.Camera,
	SegmentIconType.Music,
	SegmentIconType.Video,
	SegmentIconType.Airplane,
	SegmentIconType.Food,
	SegmentIconType.Fashion,
	SegmentIconType.Technology,
	SegmentIconType.Science,
	SegmentIconType.Theatre,
	SegmentIconType.History,
];



type Props = {
	name: string,
};

const SegmentSymbolField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	return (
		<IconPickerTextField
			attributes={{
				maxLength: 2,
			}}
			icons={ICON_PICKER_ORDER.map((name) => ({
				icon: (
					<SegmentIcon
						color="#8595a6"
						type={name}
					/>
				),
				name,
			}))}
			name={name}
			width={70}
		/>
	);
};



export default SegmentSymbolField;
