import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AppearAnimation from '~/components/patterns/animations/AppearAnimation';
import {
	linkInternal,
} from '../Copy';
import InviteSingleMemberForm from './InviteSingleMemberForm';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import Protip from '~/components/patterns/messages/embedded/Protip';

import useAccountType from '~/hooks/useAccountType';



const messages = defineMessages({
	bulkProtip: {
		id: 'ui.members.new.bulkProtip',
	},
	title: {
		id: 'ui.members.new.title',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	isContentDisabled?: boolean,
	onRoleUpdateCallback: (role: GraphQL.UserRole) => void,
	onSubmitCallback: (values: {
		email: string,
		role: GraphQL.UserRole,
	}) => void,
};

const InviteSingleMemberStep: React.FC<Props> = (props) => {
	const {
		accountId,
		isContentDisabled,
		onRoleUpdateCallback,
		onSubmitCallback,
	} = props;

	const accountType = useAccountType(accountId);

	const showProtip = (
		accountType !== GraphQL.AccountType.EndUser
	);

	return (
		<MultiStepModalStep
			name="invite-single-member"
			sidebar={(showProtip && (
				<AppearAnimation delay={500}>
					<Protip>
						<FormattedMessage
							{...messages.bulkProtip}
							values={{
								linkBulkStep: linkInternal('account.members.new', { bulk: true }),
							}}
						/>
					</Protip>
				</AppearAnimation>
			))}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<InviteSingleMemberForm
				accountId={accountId}
				isDisabled={isContentDisabled}
				onRoleUpdate={onRoleUpdateCallback}
				onSubmitCallback={onSubmitCallback}
			/>
		</MultiStepModalStep>
	);
};



export default InviteSingleMemberStep;
