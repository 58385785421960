import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';



const messages = defineMessages({
	title: {
		id: 'ui.platform.robots.sitemapReference',
	},
});



type Props = {
	issue: {
		context: {
			sitemapReferences: ReadonlyArray<string>,
		},
	},
	tableWidth: number,
};

const RobotsTxtRelativeSitemapReferencesTable: React.FC<Props> = (props) => {
	const {
		issue,
		tableWidth,
	} = props;

	return (
		<SmallTable
			columns={[
				{
					hasData: () => true,
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis>
									<CodeValue>
										{row}
									</CodeValue>
								</Ellipsis>
							);
						},
						header: () => <FormattedMessage {...messages.title} />,
					},
					width: tableWidth - 2,
				},
			]}
			rows={issue.context.sitemapReferences}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(RobotsTxtRelativeSitemapReferencesTable);
