import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountHasBillingHistoryQuery,
} from './useAccountHasBillingHistory.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountHasBillingHistory(accountId: CK.AccountId | null): GraphQL.Account['hasBillingHistory'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountHasBillingHistoryQuery,
		accountId,
	);

	return data?.account?.hasBillingHistory ?? null;
}



export default useAccountHasBillingHistory;
