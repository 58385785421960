import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import OnboardingGuide from '~/components/app/OnboardingGuide';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useUserFirstName from '~/hooks/useUserFirstName';
import useUserHasName from '~/hooks/useUserHasName';

import {
	teamSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	titleWithName: {
		id: 'ui.onboardingGuideModal.titleWithName',
	},
	titleWithoutName: {
		id: 'ui.onboardingGuideModal.titleWithoutName',
	},
});



type Props = {
	closeCallback: () => void,
};



const OnboardingGuideModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const currentUserId = useCurrentUserId();
	const currentUserFirstName = useUserFirstName(currentUserId);
	const currentUserHasName = useUserHasName(currentUserId);

	const team = useSelector(teamSelector);

	if (!team) {
		return null;
	}

	return (
		<SimpleModal
			canBeClosed={kingdomAdminFeatures.areVisible}
			closeCallback={closeCallback}
			size={SimpleModalSize.Medium}
			title={(
				currentUserFirstName !== null ? (
					<FormattedMessage
						{...messages.titleWithName}
						values={{ name: currentUserFirstName }}
					/>
				) : (
					<FormattedMessage {...messages.titleWithoutName} />
				)
			)}
		>
			<OnboardingGuide
				currentUserHasName={currentUserHasName}
				onCloseCallback={closeCallback}
				team={team}
			/>
		</SimpleModal>
	);
};



export default OnboardingGuideModal;
