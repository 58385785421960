import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountTaxRateQuery,
} from './useAccountTaxRate.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountTaxRate(accountId: CK.AccountId | null): GraphQL.Tax | null {
	const { data } = useAccountPropertiesQuery(
		useAccountTaxRateQuery,
		accountId,
	);

	if (data?.account && !data.account.billingDetails) {
		return {
			country: null,
			id: 'blank',
			rate: 0.0,
			type: null,
		};
	}

	return data?.account?.billingDetails?.tax ?? null;
}



export default useAccountTaxRate;
