import React from 'react';

import type CK from '~/types/contentking';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MultiselectField from '~/components/atoms/forms/components/MultiselectField';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';

import {
	validateField,
} from '~/components/app/validations';

import {
	usePurgePagesHistoryByFilterMutation,
} from '~/components/app/WebsiteAdminPruneHistoricalPageDataModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const purgedPropertiesOptions = [
	{
		name: 'h2',
		title: 'Heading 2',
	},
	{
		name: 'h3',
		title: 'Heading 3',
	},
	{
		name: 'h4',
		title: 'Heading 4',
	},
	{
		name: 'h5',
		title: 'Heading 5',
	},
	{
		name: 'h6',
		title: 'Heading 6',
	},
];



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminPruneHistoricalPageDataModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const [purgePagesHistoryByFilter] = usePurgePagesHistoryByFilterMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await purgePagesHistoryByFilter({
				variables: {
					purgedProperties: values.purgedProperties,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			purgePagesHistoryByFilter,
			websiteId,
		],
	);

	const validations = React.useMemo(
		() => ({
			validatePurgedProperties: validateField(
				'purgedProperties',
				(f) => [
					{
						...f.validateMinimumLength(1),
						message: 'At least 1 property must be selected',
					},
				],
			),
		}),
		[],
	);

	return (
		<SimpleModal
			alignToTop={true}
			size={SimpleModalSize.Medium}
			title="Prune historical page data"
		>
			<Form
				defaultFocus="purgedProperties"
				defaultValues={{
					purgedProperties: [],
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRows>
					<FormRow
						description={(
							<>
								Historical data for selected properties will be deleted from all pages.
							</>
						)}
						htmlFor="purgedProperties"
						label="Pruned properties"
					>
						<FieldStatus
							name="validatePurgedProperties"
						>
							<MultiselectField
								isOnlyLinkVisible={true}
								name="purgedProperties"
								options={purgedPropertiesOptions}
							/>
						</FieldStatus>
					</FormRow>
				</FormRows>

				<ButtonsLayout>
					<CancelButton />

					<SubmitButton>
						Delete historical data
					</SubmitButton>
				</ButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default WebsiteAdminPruneHistoricalPageDataModal;
