import React from 'react';



type Props = {
	/** Optional call-to-action button */
	ctaButton?: React.ReactNode,
	/** Text of message */
	children: React.ReactNode,
	/** When this callback defined we will show close button */
	onCloseClickCallback?: (e: Event) => void,
};



const AppAnnouncement: React.FC<Props> = (props) => {
	const {
		ctaButton,
		children,
		onCloseClickCallback,
	} = props;

	const _handleMessageClose = (e) => {
		e.preventDefault();

		if (!onCloseClickCallback) {
			return false;
		}

		onCloseClickCallback(e);
	};

	return (
		<div className="app-announcement">
			<div className="app-announcement__message">
				{children}
			</div>
			{ctaButton && (
				<div className="app-announcement__button">
					{ctaButton}
				</div>
			)}
			{onCloseClickCallback && (
				<a
					className="app-announcement__close-button"
					href="#"
					onClick={_handleMessageClose}
				>
					&times;
				</a>
			)}
		</div>
	);
};



export default AppAnnouncement;
