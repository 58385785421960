import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const StopwatchIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M19.159,8.256l1.548-1.548a1,1,0,1,0-1.414-1.414l-1.6,1.6A9.448,9.448,0,0,0,13,5.053V2h2a1,1,0,1,0,0-2H9A1,1,0,0,0,9,2h2V5.053a9.5,9.5,0,1,0,8.159,3.2ZM12,22a7.5,7.5,0,1,1,7.5-7.5A7.509,7.509,0,0,1,12,22Z"
				fill={color}
			/>
			<path
				d="M9.207,10.294a1,1,0,0,0-1.414,1.414l3.5,3.5a1.014,1.014,0,0,0,1.414,0,1,1,0,0,0,0-1.414Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default StopwatchIconBuilder;

