import classNames from 'classnames';
import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';



export enum NumberDiffImpact {
	Negative = 'negative',
	Neutral = 'neutral',
	Positive = 'positive',
}

export enum NumberDiffSize {
	Default = 'default',
	Large = 'large',
	Small = 'small',
}

export enum NumberDiffStyle {
	Currency = 'currency',
	Decimal = 'decimal',
	Percent = 'percent',
}

type Props = {
	diffValue: number,
	impact?: NumberDiffImpact,
	numberCurrency?: string,
	numberStyle?: NumberDiffStyle,
	size?: NumberDiffSize,
};



const NumberDiff: React.FC<Props> = (props) => {
	const {
		diffValue,
		impact = NumberDiffImpact.Neutral,
		numberCurrency,
		numberStyle = NumberDiffStyle.Decimal,
		size = NumberDiffSize.Default,
	} = props;

	const componentClasses = classNames({
		'number-diff': true,
		'number-diff--positive-impact': impact === NumberDiffImpact.Positive,
		'number-diff--negative-impact': impact === NumberDiffImpact.Negative,
		'number-diff--size-large': size === NumberDiffSize.Large,
		'number-diff--size-small': size === NumberDiffSize.Small,
	});

	let diffSign;
	if (diffValue > 0) {
		diffSign = '+';
	} else if (diffValue < 0) {
		diffSign = '−';
	} else {
		diffSign = '';
	}

	return (
		<span className={componentClasses}>
			{diffSign}
			<FormattedNumber
				currency={numberCurrency}
				style={numberStyle}
				value={diffValue < 0 ? -1 * diffValue : diffValue}
			/>
		</span>
	);
};



export default NumberDiff;
