import React from 'react';



type Props = {
	/** Close link for dropdown */
	closeLink?: React.ReactNode,
	ctaElement?: React.ReactNode,
	children: React.ReactNode,
	searchField?: React.ReactNode,
};



const WebsitesNavigation: React.FC<Props> = (props) => {
	const {
		closeLink,
		ctaElement,
		children,
		searchField,
	} = props;

	return (
		<nav
			aria-label="Selected website"
			className="websites-nav dark-bg-context"
			role="navigation"
		>
			{searchField && (
				<div className="websites-nav__search-field">
					{searchField}
				</div>
			)}
			{children}
			{(ctaElement || closeLink) && (
				<div className="websites-nav__cta-elements">
					{ctaElement && (
						<div className="websites-nav__cta-element">
							{ctaElement}
						</div>
					)}
					{closeLink && (
						<div className="websites-nav__close-link">
							{closeLink}
						</div>
					)}
				</div>
			)}
		</nav>
	);
};



export default WebsitesNavigation;
