import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HelpHint from '~/components/patterns/hints/HelpHint';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField from '~/components/atoms/forms/components/TextField';
import VerifyWebsiteLink from '~/components/app/VerifyWebsiteLink';
import YesOrNo from '~/components/app/YesOrNo';

import {
	validateField,
} from '~/components/app/validations';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useWebsiteDomain from '~/hooks/useWebsiteDomain';
import useWebsiteIsVerified from '~/hooks/useWebsiteIsVerified';
import useWebsiteName from '~/hooks/useWebsiteName';

import {
	useUpdateWebsiteNameMutation,
} from './EditWebsiteNameForm.gql';



const messages = defineMessages({
	domainLabel: {
		id: 'ui.websites.form.domain',
	},
	verifiedHint: {
		id: 'ui.websites.form.verified.hint',
	},
	verifiedLabel: {
		id: 'ui.websites.form.verified',
	},
	verify: {
		id: 'ui.websites.form.verify',
	},
	websiteDetailsTitle: {
		id: 'ui.websites.form.websiteDetailsTitle',
	},
	websiteNameLabel: {
		id: 'ui.websites.form.websiteName',
	},
});

const validations = {
	validateName: validateField(
		'name',
		(f) => [
			f.validateNonEmpty(),
		],
	),
};



type Props = {
	websiteId: CK.WebsiteId,
};

const EditWebsiteNameForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteDomain = useWebsiteDomain(websiteId);
	const websiteIsVerified = useWebsiteIsVerified(websiteId);
	const websiteName = useWebsiteName(websiteId);

	const isAllowedToManageWebsiteSettings = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageSettings,
	);

	const [updateWebsiteName] = useUpdateWebsiteNameMutation();

	const handleSubmit = React.useCallback(
		({ name }) => {
			return updateWebsiteName({
				variables: {
					name,
					websiteId,
				},
			});
		},
		[
			updateWebsiteName,
			websiteId,
		],
	);

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageWebsiteSettings}
			key={websiteId}
			title={(
				<FormattedMessage {...messages.websiteDetailsTitle} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.domainLabel} />
						)}
					>
						<StaticText>
							{websiteDomain}
						</StaticText>
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.websiteNameLabel} />
						)}
					>
						<StaticText>
							{websiteName || '-'}
						</StaticText>
					</FormRow>

					<FormRow
						label={(
							<AttachedElement
								element={(
									<HelpHint
										message={(
											<FormattedMessage {...messages.verifiedHint} />
										)}
									/>
								)}
							>
								<FormattedMessage {...messages.verifiedLabel} />
							</AttachedElement>
						)}
					>
						<StaticText>
							{websiteIsVerified !== null && (
								<YesOrNo state={websiteIsVerified} />
							)}
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultFocus="name"
					defaultValues={{
						name: websiteName,
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<FormRows>
						<FormRow
							label={(
								<FormattedMessage {...messages.domainLabel} />
							)}
						>
							<StaticText>
								{websiteDomain}
							</StaticText>
						</FormRow>

						<FormRow
							htmlFor="name"
							label={(
								<FormattedMessage {...messages.websiteNameLabel} />
							)}
						>
							<FieldStatus name="validateName">
								<TextField
									name="name"
									trimValue={true}
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							label={(
								<AttachedElement
									element={(
										<HelpHint
											message={(
												<FormattedMessage {...messages.verifiedHint} />
											)}
										/>
									)}
								>
									<FormattedMessage {...messages.verifiedLabel} />
								</AttachedElement>
							)}
						>
							<StaticText>
								{websiteIsVerified ? (
									<YesOrNo state={true} />
								) : (
									<VerifyWebsiteLink>
										<FormattedMessage {...messages.verify} />
									</VerifyWebsiteLink>
								)}
							</StaticText>
						</FormRow>
					</FormRows>

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default EditWebsiteNameForm;
