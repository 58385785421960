export const ANIMATE_HEARTBEAT = 'ANIMATE_HEARTBEAT';
export const COLUMNS_CONFIGURATOR_CLOSE = 'COLUMNS_CONFIGURATOR_CLOSE';
export const COLUMNS_CONFIGURATOR_OPEN = 'COLUMNS_CONFIGURATOR_OPEN';
export const COLUMNS_SETS_LIST_TOGGLE = 'COLUMNS_SETS_LIST_TOGGLE';
export const FORCE_UNAUTHENTICATED_SECTION = 'FORCE_UNAUTHENTICATED_SECTION';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_URL_STATE = 'CHANGE_URL_STATE';
export const INITIALIZE_FROM_LOCAL_STORAGE = 'INITIALIZE_FROM_LOCAL_STORAGE';
export const MAKE_AUTHENTICATED = 'MAKE_AUTHENTICATED';
export const MARK_APP_EMPTY_STATE = 'MARK_APP_EMPTY_STATE';
export const MENU_USER_NAVIGATION_CLOSE = 'MENU_USER_NAVIGATION_CLOSE';
export const MENU_USER_NAVIGATION_OPEN = 'MENU_USER_NAVIGATION_OPEN';
export const MENU_WEBSITE_SWITCHER_CLOSE = 'MENU_WEBSITE_SWITCHER_CLOSE';
export const MENU_WEBSITE_SWITCHER_OPEN = 'MENU_WEBSITE_SWITCHER_OPEN';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const LOGOUT_UNSUCCESSFUL = 'LOGOUT_UNSUCCESSFUL';
export const REMOVE_HOVERED_CATEGORY = 'REMOVE_HOVERED_CATEGORY';
export const START_LOADING_PAGES_BY_IDS = 'START_LOADING_PAGES_BY_IDS';
export const STORE_HOVERED_CATEGORY = 'STORE_HOVERED_CATEGORY';
export const STORE_LOADED_BILLING_DETAILS = 'STORE_LOADED_BILLING_DETAILS';
export const STORE_LOADED_DISCOUNTS = 'STORE_LOADED_DISCOUNTS';
export const STORE_LOADED_PAGES = 'STORE_LOADED_PAGES';
export const STORE_PAGE_RELATIONS = 'STORE_PAGE_RELATIONS';
export const STORE_UPDATED_TEAM = 'STORE_UPDATED_TEAM';
export const STORE_USER_SETTINGS = 'STORE_USER_SETTINGS';
export const UPDATE_PAGES_FILTER = 'UPDATE_PAGES_FILTER';
export const UPDATE_PAGES_SORTING = 'UPDATE_PAGES_SORTING';
export const UPDATE_ISSUES_FILTER = 'UPDATE_ISSUES_FILTER';
export const STORE_LAST_SEARCHED_WEBSITE = 'STORE_LAST_SEARCHED_WEBSITE';
export const STORE_LOADED_TEAMS = 'STORE_LOADED_TEAMS';
export const STORE_UNIQUE_ELEMENT_CONTENT_URLS = 'STORE_UNIQUE_ELEMENT_CONTENT_URLS';
export const WINDOW_HAS_FOCUS = 'WINDOW_HAS_FOCUS';



export function changeUrlState(state, previousState) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_URL_STATE,
			urlState: state,
			previousUrlState: previousState,
		});
	};
}



export function animateHeartbeat(animate = true) {
	return {
		animate,
		type: ANIMATE_HEARTBEAT,
	};
}



export function closeColumnsConfigurator() {
	return {
		type: COLUMNS_CONFIGURATOR_CLOSE,
	};
}



export function initializeFromLocalStorage(data) {
	return {
		type: INITIALIZE_FROM_LOCAL_STORAGE,
		data,
	};
}



export function markAppEmptyState(value) {
	return {
		type: MARK_APP_EMPTY_STATE,
		value,
	};
}



export function openColumnsConfigurator() {
	return {
		type: COLUMNS_CONFIGURATOR_OPEN,
	};
}



export function toggleColumnsSetsList(value) {
	return {
		type: COLUMNS_SETS_LIST_TOGGLE,
		value,
	};
}



export const menu = {
	closeUserNavigation: function () {
		return {
			type: MENU_USER_NAVIGATION_CLOSE,
		};
	},



	closeWebsiteSwitcher: function () {
		return {
			type: MENU_WEBSITE_SWITCHER_CLOSE,
		};
	},



	openUserNavigation: function () {
		return {
			type: MENU_USER_NAVIGATION_OPEN,
		};
	},



	openWebsiteSwitcher: function () {
		return {
			type: MENU_WEBSITE_SWITCHER_OPEN,
		};
	},
};



export function removeHoveredCategory() {
	return {
		type: REMOVE_HOVERED_CATEGORY,
	};
}



export function startLoadingPagesByIds(domainId, pagesIds) {
	return {
		type: START_LOADING_PAGES_BY_IDS,
		domainId,
		pagesIds,
	};
}



export function storeLastSearchedWebsite(value) {
	return {
		type: STORE_LAST_SEARCHED_WEBSITE,
		value,
	};
}



export function storeLoadedPages(domainId, pages, externalUrls = {}) {
	return {
		type: STORE_LOADED_PAGES,
		domainId,
		pages,
		externalUrls,
	};
}



export function storeUniqueElementContentIssueUrls(domainId, pageId, issueType, urls, offset) {
	return {
		type: STORE_UNIQUE_ELEMENT_CONTENT_URLS,
		domainId,
		pageId,
		issueType,
		urls,
		offset,
	};
}



export function storePageRelations(fullId, relationType, data, offset) {
	return {
		type: STORE_PAGE_RELATIONS,
		fullId,
		relationType,
		data,
		offset,
	};
}



export function storeHoveredCategory(category) {
	return {
		type: STORE_HOVERED_CATEGORY,
		category,
	};
}



export function storeLoadedBillingDetails(teamId, billingDetails, discounts, tax) {
	return {
		type: STORE_LOADED_BILLING_DETAILS,
		billingDetails,
		discounts,
		tax,
		teamId: teamId.toString(),
	};
}



export function storeLoadedDiscounts(teamId, discounts) {
	return {
		type: STORE_LOADED_DISCOUNTS,
		discounts,
		teamId,
	};
}



export function storeLoadedTeams(teams) {
	return {
		type: STORE_LOADED_TEAMS,
		teams,
	};
}



export function initialRouterState(routeState) {
	return changeUrlState(
		routeState || {
			name: '',
			params: {},
		},
		{
			name: '',
			params: {},
		},
	);
}



export function windowHasFocus(value) {
	return {
		type: WINDOW_HAS_FOCUS,
		value,
	};
}
