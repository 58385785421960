import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	ignoringScope: {
		id: 'ui.issueDetail.ignoringModal.header.tabsHeadline',
	},
});



type Props = {
	children: React.ReactNode,
	/** Navigation component */
	navigation?: React.ReactNode,
};

const IssueModalContentLayout: React.FC<Props> = (props) => {
	const {
		children,
		navigation,
	} = props;

	return (
		<div className="issue-modal-content-layout">
			<div className="issue-modal-content-layout__navigation-row">
				<div className="issue-modal-content-layout__navigation-label">
					<FormattedMessage {...messages.ignoringScope} />
				</div>
				<div className="issue-modal-content-layout__navigation">
					{navigation}
				</div>
			</div>
			<div className="issue-modal-content-layout__body">
				{children}
			</div>
		</div>
	);
};



export default IssueModalContentLayout;
