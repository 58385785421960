import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import UrlBlocklistForm from '~/components/app/UrlBlocklistForm';

import {
	useUpdateIndexNowUrlBlocklistMutation,
} from './IndexNowUrlConditionsModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useIndexNowConditions from '~/hooks/useIndexNowConditions';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	allDeniedWarning: {
		id: 'ui.indexNowUrlConditionsModal.allDeniedWarning',
	},
	description: {
		id: 'ui.indexNowUrlConditionsModal.description',
	},
	sidebar: {
		id: 'ui.indexNowUrlConditionsModal.sidebar',
	},
	title: {
		id: 'ui.indexNowUrlConditionsModal.title',
	},
});



const IndexNowUrlConditionsModal: React.FC = () => {
	const websiteId = useWebsiteId();
	const classicFormBehavior = useClassicFormBehavior();
	const {
		loading,
		urlBlocklistMode,
		urlBlocklistRules,
	} = useIndexNowConditions(websiteId);

	const [updateUrlBlocklist] = useUpdateIndexNowUrlBlocklistMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateUrlBlocklist({
				variables: {
					mode: values.mode,
					rules: values.rules,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			updateUrlBlocklist,
			websiteId,
		],
	);

	return (
		<SimpleModal
			alignToTop={true}
			iconType={BasicIconType.Plus}
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							linkSupport: linkExternal('https://www.contentkingapp.com/support/realtime-indexnow'),
						}}
					/>
				</RichText>
			)}
			size={SimpleModalSize.XXLarge}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ModalTextSection>
				<Copy {...messages.description} />
			</ModalTextSection>

			{!loading && (
				<UrlBlocklistForm
					allDeniedWarning={(
						<FormattedMessage {...messages.allDeniedWarning} />
					)}
					defaultValues={{
						mode: urlBlocklistMode ?? GraphQL.UrlBlocklistMode.AllowAllExcept,
						rules: urlBlocklistRules ?? [],
					}}
					onSubmit={handleSubmit}
				/>
			)}
		</SimpleModal>
	);
};



export default IndexNowUrlConditionsModal;
