import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountIsSubscriptionRenewalEnabledQuery,
} from './useAccountIsSubscriptionRenewalEnabled.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsSubscriptionRenewalEnabled(accountId: CK.AccountId | null): GraphQL.Account['isSubscriptionRenewalEnabled'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountIsSubscriptionRenewalEnabledQuery,
		accountId,
	);

	return data?.account?.isSubscriptionRenewalEnabled ?? null;
}



export default useAccountIsSubscriptionRenewalEnabled;
