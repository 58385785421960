import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttachedHeading, {
	AttachedHeadingAlignment,
} from '~/components/patterns/structuredValues/AttachedHeading';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import Copy, {
	linkExternal,
	linkInternal,
} from '~/components/logic/Copy';
import Declaration from '~/components/patterns/values/declarations/Declaration';
import Form from '~/components/atoms/forms/basis/Form';
import HighlightedBox from '~/components/patterns/boxes/HighlightedBox';
import IdentifierBox from '~/components/logic/segmentManagement/editor/IdentifierBox';
import IdentifierModal from '~/components/logic/segmentManagement/editor/IdentifierModal';
import MarginsList from '~/components/atoms/lists/MarginsList';
import RichText from '~/components/patterns/typography/RichText';
import SaveButton from '~/components/logic/segmentManagement/editor/SaveButton';
import ScreenBodyLayout, {
	ScreenBodyLayoutPosition,
} from '~/components/patterns/screens/parts/body/ScreenBodyLayout';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import SegmentEditorHeader from '~/components/logic/segmentManagement/editor/Header';
import SegmentsSidebarContent from '~/components/patterns/screens/segments/parts/SegmentsSidebarContent';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';
import StaticSegmentUrlsField from '~/components/app/StaticSegmentUrlsField';
import Title from '~/components/patterns/headings/Title';

import {
	validateField,
} from '~/components/app/validations';

import validateUrls from '~/components/validations/validateUrls';

import useIsIntercomEnabled from '~/hooks/useIsIntercomEnabled';
import useModals from '~/hooks/useModals';
import useProtectUnsavedChanges from '~/hooks/useProtectUnsavedChanges';
import useStaticSegmentEditorContext from '~/hooks/useStaticSegmentEditorContext';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteDomain from '~/hooks/useWebsiteDomain';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	normalizeFilter,
} from '~/model/pages';

import {
	type SegmentDefinition,
	getSegmentDefinitionByName,
} from '~/model/segments';

import {
	navigate,
} from '~/routing/router';

import decodeFromBase64 from '~/utilities/decodeFromBase64';
import encodeInBase64 from '~/utilities/encodeInBase64';



const messages = defineMessages({
	cannotDependOnDependant: {
		id: 'ui.segments.management.cannotDependOnDependant',
	},
	cannotDependOnSelf: {
		id: 'ui.segments.management.cannotDependOnSelf',
	},
	cautionText: {
		id: 'ui.staticSegmentCaution.text',
	},
	cautionTitle: {
		id: 'ui.staticSegmentCaution.title',
	},
	filterDefinition: {
		id: 'ui.segments.editor.filterBox.title',
	},
	filterDefinitionDescription: {
		id: 'ui.segments.editor.filterBox.staticSegmentDescription',
	},
	title: {
		id: 'ui.segments.editor.sidebar.title',
	},
});

const maximumAmount = 20_000;



const StaticSegmentEditorScreen: React.FC = () => {
	const websiteId = useWebsiteId();

	const isIntercomEnabled = useIsIntercomEnabled();
	const urlState = useUrlState();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const websiteDomain = useWebsiteDomain(websiteId);

	const {
		openModal,
	} = useModals();

	const {
		protectUnsavedChanges,
		disableProtection,
	} = useProtectUnsavedChanges();

	const editedSegment = getSegmentDefinitionByName(
		segmentDefinitions.listAll(),
		urlState.params.segmentName ?? '',
	);

	const staticSegmentEditorContext = useStaticSegmentEditorContext({
		editedSegment,
		websiteId,
	});

	const {
		hasLabel,
		isDirty,
		isSubmitting,
		saveSegment,
		segmentColor,
		segmentIconName,
		segmentLabel,
		segmentShortcode,
		segmentUrls,
		updateIdentifier,
		updateUrls,
		validity,
	} = staticSegmentEditorContext;

	React.useEffect(
		() => {
			if (isDirty) {
				protectUnsavedChanges();
			} else {
				disableProtection();
			}
		},
		[
			disableProtection,
			isDirty,
			protectUnsavedChanges,
		],
	);

	const close = React.useCallback(
		(savedSegment?: SegmentDefinition) => {
			let routeName = 'website.pages';
			let routeParams: any = {
				websiteId: urlState.params.websiteId,
			};

			if (urlState.params.back) {
				const back = JSON.parse(decodeFromBase64(urlState.params.back));
				routeName = back.name;
				routeParams = back.params || {};
			}

			if (savedSegment && routeName === 'website.pages') {
				routeParams.filter = encodeInBase64(JSON.stringify(normalizeFilter(Immutable.fromJS({
					segments: {
						included_in: [savedSegment.name],
						not_included_in: [],
						operator: 'and',
					},
				})).toJS()));
			}

			navigate(routeName, routeParams);
		},
		[
			urlState,
		],
	);

	const save = React.useCallback(
		async (input = {}) => {
			const savedSegment = await saveSegment(input);

			disableProtection();
			close(savedSegment);
		},
		[
			close,
			disableProtection,
			saveSegment,
		],
	);

	const openIdentifierModal = React.useCallback(
		(saveAfterSubmit: boolean) => {
			openModal(({ closeCallback }) => {
				async function submitCallback(data) {
					updateIdentifier({
						segmentColor: data.color,
						segmentIconName: data.icon,
						segmentLabel: data.label,
						segmentShortcode: data.shortcode,
					});

					closeCallback();

					if (saveAfterSubmit) {
						save({
							segmentColor: data.color,
							segmentIconName: data.icon,
							segmentLabel: data.label,
							segmentShortcode: data.shortcode,
						});
					}
				}

				return (
					<IdentifierModal
						closeCallback={closeCallback}
						defaultValues={{
							color: segmentColor,
							iconName: segmentIconName,
							label: segmentLabel,
							shortcode: segmentShortcode,
						}}
						editedSegment={editedSegment}
						hasLabel={hasLabel}
						segmentDefinitions={segmentDefinitions.listAll()}
						submitCallback={submitCallback}
					/>
				);
			});
		},
		[
			editedSegment,
			hasLabel,
			openModal,
			save,
			segmentColor,
			segmentDefinitions,
			segmentIconName,
			segmentLabel,
			segmentShortcode,
			updateIdentifier,
		],
	);

	function attemptSave() {
		if (!hasLabel) {
			openIdentifierModal(true);
		} else {
			save();
		}
	}

	const validations = React.useMemo(
		() => ({
			urls: validateField(
				'urls',
				(f) => validateUrls(f, {
					allowedDomains: websiteDomain !== null ? [websiteDomain] : [],
					maximumAmount,
					minimumAmount: 1,
				}),
			),
		}),
		[
			websiteDomain,
		],
	);

	return (
		<ScreenLayout
			header={(
				<SegmentEditorHeader
					isNewSegment={editedSegment === null}
					limitedNumberOfPages={segmentUrls?.length ?? null}
					onRequestClose={close}
					segmentLabel={segmentLabel}
				/>
			)}
		>
			<Form
				clearOnFieldUnmount={true}
				defaultValues={{
					urls: segmentUrls ?? undefined,
				}}
				onChangeCallback={(field, value) => {
					if (field === 'urls') {
						updateUrls(value);
					}
				}}
				validations={validations}
			>
				{({ errors }) => (
					<ScreenBodyLayout
						hideContentScrollbar={true}
						hideSidebarScrollbar={true}
						sidebar={(
							<SegmentsSidebarContent
								footer={(
									<SaveButton
										invalidStaticUrlsMessage={errors.urls}
										isNewSegment={editedSegment === null}
										isSubmitting={isSubmitting}
										onClickCallback={attemptSave}
										validity={validity}
									/>
								)}
								gapForIntercom={isIntercomEnabled}
							>
								<AttachedHeading
									heading={(
										<Title>
											<FormattedMessage {...messages.title} />
										</Title>
									)}
									headingAlignment={AttachedHeadingAlignment.Left}
								>
									<MarginsList>
										<IdentifierBox
											hasLabel={hasLabel}
											openIdentifierModal={openIdentifierModal}
											segmentColor={segmentColor}
											segmentIconName={segmentIconName}
											segmentLabel={segmentLabel}
											segmentShortcode={segmentShortcode}
										/>

										<HighlightedBox
											title={(
												<FormattedMessage {...messages.filterDefinition} />
											)}
										>
											<Declaration
												property={(
													<FormattedMessage {...messages.filterDefinitionDescription} />
												)}
												value={segmentUrls?.length ?? '-'}
											/>
										</HighlightedBox>
									</MarginsList>
								</AttachedHeading>
							</SegmentsSidebarContent>
						)}
						sidebarPosition={ScreenBodyLayoutPosition.Right}
						sidebarWidth={330}
					>
						<Spacer
							bottom={SpacerGap.Small}
							isStretched={true}
							left={SpacerGap.Small}
							right={SpacerGap.Small}
							top={SpacerGap.Small}
						>
							<Spacer
								bottom={SpacerGap.Small}
							>
								<CalloutMessage
									borders={true}
									message={(
										<Copy {...messages.cautionTitle} />
									)}
									status={CalloutMessageStatus.Warning}
								>
									<RichText>
										<Copy
											{...messages.cautionText}
											values={{
												linkClassic: linkInternal('website.segmentEditor.create', {
													websiteId,
												}),
												linkSupport: linkExternal('https://www.contentkingapp.com/support/segments/'),
											}}
										/>
									</RichText>
								</CalloutMessage>
							</Spacer>

							{(!urlState.params.segmentName || segmentUrls !== null) && (
								<StaticSegmentUrlsField
									domain={websiteDomain}
									maximumAmount={maximumAmount}
									name="urls"
								/>
							)}
						</Spacer>
					</ScreenBodyLayout>
				)}
			</Form>
		</ScreenLayout>
	);
};



export default StaticSegmentEditorScreen;
