import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserSegmentsDisplayOrder from '~/hooks/useUserSegmentsDisplayOrder';

import {
	type WebsiteSegmentDefinitionsQuery,
	useWebsiteSegmentDefinitionsQuery,
} from './useWebsiteSegmentDefinitions.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';
import usePollInterval from '~/hooks/usePollInterval';

import {
	type SegmentDefinition,
} from '~/model/segments';

import memoizeById from '~/utilities/memoizeById';



function getOrderIndexBySegmentDefinition(
	segmentsDisplayOrder: ReadonlyArray<GraphQL.SegmentIdentifier>,
	segmentDefinition: SegmentDefinition,
) {
	return segmentsDisplayOrder.findIndex((segmentIdentifier) => {
		return (
			segmentIdentifier.color === segmentDefinition.color
			&& segmentIdentifier.label === segmentDefinition.label
			&& segmentIdentifier.shortcode === segmentDefinition.shortcode
			&& segmentIdentifier.iconName === (segmentDefinition.icon?.name ?? null)
		);
	});
}

const emptyList = [];



type WebsiteSegmentDefinitions = Readonly<{
	count: number,
	isLoaded: boolean,
	listAll: () => ReadonlyArray<SegmentDefinition>,
}>;

const format = memoizeById(
	(
		data: WebsiteSegmentDefinitionsQuery | undefined,
		segmentsDisplayOrder: ReturnType<typeof useUserSegmentsDisplayOrder>,
	) => {
		const segmentDefinitions = data?.website?.pageSegments ?? null;

		const sortedSegmentDefinitions = segmentDefinitions !== null
			? [...segmentDefinitions]
			: emptyList;

		if (segmentsDisplayOrder !== null) {
			sortedSegmentDefinitions.sort((a, b) => {
				const orderIndexA = getOrderIndexBySegmentDefinition(segmentsDisplayOrder, a);
				const orderIndexB = getOrderIndexBySegmentDefinition(segmentsDisplayOrder, b);

				if (orderIndexA >= 0 && orderIndexB >= 0) {
					return orderIndexA - orderIndexB;
				} else if (orderIndexA >= 0) {
					return -1;
				} else if (orderIndexB >= 0) {
					return 1;
				}

				return 0;
			});
		}

		return {
			count: segmentDefinitions?.length ?? 0,
			isLoaded: segmentDefinitions !== null,
			listAll: () => sortedSegmentDefinitions,
		};
	},
);



function useWebsiteSegmentDefinitions(websiteId: CK.WebsiteId | null): WebsiteSegmentDefinitions {
	const currentUserId = useCurrentUserId();

	const segmentsDisplayOrder = useUserSegmentsDisplayOrder(currentUserId);

	const { data } = useWebsiteSegmentDefinitionsQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		pollInterval: usePollInterval(30_000),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return format(websiteId, data, segmentsDisplayOrder);
}



export default useWebsiteSegmentDefinitions;
