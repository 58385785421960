import Immutable from 'immutable';

import type CK from '~/types/contentking';

import {
	DEFAULT_FILTER,
	DEFAULT_SORT_BY,
	normalizeFilter,
} from '~/model/historicalChanges';

import {
	isTrackedChangesColumn,
} from '~/model/pagesColumns';

import {
	type SegmentDefinition,
	dependsOnlyOnColumns,
	filterSegmentDefinitionsByNames,
} from '~/model/segments';

import encodeInBase64 from '~/utilities/encodeInBase64';



export const PEEK_COLUMN = 'PEEK_COLUMN';
export const RESET_HISTORICAL_CHANGES_INTERVAL = 'RESET_HISTORICAL_CHANGES_INTERVAL';
export const STORE_HISTORICAL_CHANGES = 'STORE_HISTORICAL_CHANGES';
export const UPDATE_FILTER = 'HISTORICAL_CHANGES_UPDATE_FILTER';
export const UPDATE_HISTORICAL_CHANGES_INTERVAL = 'UPDATE_HISTORICAL_CHANGES_INTERVAL';
export const UPDATE_SORT_BY = 'HISTORICAL_CHANGES_UPDATE_SORT_BY';



function normalizePagesFilterAndSorting(
	filter,
	sortBy,
	columns,
	segments: ReadonlyArray<SegmentDefinition>,
) {
	filter = normalizeFilter(filter);

	const removeUnusedColumns = (value, key) => {
		if (key === 'changes_in') {
			return true;
		}

		return columns.indexOf(key) >= 0;
	};

	filter = filter.filter(removeUnusedColumns);

	const defaultFilter = DEFAULT_FILTER.filter(removeUnusedColumns);

	if (filter.has('segments')) {
		let segmentsFilter = filter.get('segments');

		const columnValidator = (columnName: CK.PagesColumn) => isTrackedChangesColumn(columnName);

		segmentsFilter = segmentsFilter.set(
			'included_in',
			Immutable.List(
				filterSegmentDefinitionsByNames(
					segments,
					segmentsFilter.get('included_in').toArray(),
				).filter((segment) => dependsOnlyOnColumns(
					segments,
					segment,
					columnValidator,
				)).map(
					(segment) => segment.name,
				),
			),
		);
		segmentsFilter = segmentsFilter.set(
			'not_included_in',
			Immutable.List(
				filterSegmentDefinitionsByNames(
					segments,
					segmentsFilter.get('not_included_in').toArray(),
				).filter((segment) => dependsOnlyOnColumns(
					segments,
					segment,
					columnValidator,
				)).map(
					(segment) => segment.name,
				),
			),
		);

		if (segmentsFilter.get('included_in').size > 0 || segmentsFilter.get('not_included_in').size > 0) {
			filter = filter.set('segments', segmentsFilter);
		} else {
			filter = filter.set('segments', DEFAULT_FILTER.get('segments'));
		}
	}

	if (JSON.stringify(filter.toJS()) === JSON.stringify(defaultFilter.toJS())) {
		filter = null;
	}

	if (JSON.stringify(sortBy) === JSON.stringify(DEFAULT_SORT_BY)) {
		sortBy = null;
	}

	if (filter || sortBy) {
		return {
			filter: filter ? filter.toJS() : null,
			sortBy: sortBy ? sortBy.toJS() : null,
		};
	}
}



export function createFilterParameter(
	filter,
	sortBy,
	columns,
	segments: ReadonlyArray<SegmentDefinition>,
) {
	const normalizedFilterAndSorting = normalizePagesFilterAndSorting(
		filter,
		sortBy,
		[
			...columns,
			'change_type',
			'changes_in_columns',
		],
		segments,
	);

	return normalizedFilterAndSorting
		? encodeInBase64(JSON.stringify(normalizedFilterAndSorting))
		: false;
}



export function peekColumn(columnName) {
	return {
		type: PEEK_COLUMN,
		columnName,
	};
}



export function storeHistoricalChanges(websiteId, filtered, pages, total, offset, limit, changesIn, dropOldFiltered = false) {
	return {
		type: STORE_HISTORICAL_CHANGES,
		changesIn,
		dropOldFiltered,
		filtered,
		limit,
		offset,
		pages,
		total,
		websiteId,
	};
}
