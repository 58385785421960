import memoize from 'lodash/memoize';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BackButton from '~/components/app/BackButton';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import ChooseImportedSegmentsTableField from '~/components/logic/segmentManagement/ChooseImportedSegmentsTableField';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import HeaderTitleWithValue, {
	SIZE_MODAL as TITLE_SIZE_MODAL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import MarginsList, {
	SIZE_LARGE as MARGINS_SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import SelectField from '~/components/atoms/forms/components/SelectField';
import SubmitButton from '~/components/app/SubmitButton';
import WebsiteFavicon, {
	WebsiteFaviconUsageContext,
} from '~/components/logic/websites/WebsiteFavicon';

import {
	validateField,
} from '~/components/app/validations';

import {
	useImportSegmentsModalQuery,
} from './ImportSegmentsModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useImportSegmentDefinitions from '~/hooks/useImportSegmentDefinitions';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	type SegmentDefinition,
	SegmentsComparisonVerdict,
	compareSegments,
} from '~/model/segments';



const messages = defineMessages({
	chooseSegmentsStepDescription: {
		id: 'ui.segments.importModal.steps.chooseSegments.description',
	},
	chooseSegmentsStepSubmitButton: {
		id: 'ui.segments.importModal.steps.chooseSegments.submitButton',
	},
	chooseSegmentsStepTitle: {
		id: 'ui.segments.importModal.steps.chooseSegments.title',
	},
	chooseWebsiteStepDescription: {
		id: 'ui.segments.importModal.steps.chooseWebsite.description',
	},
	chooseWebsiteStepNoUniqueSegments: {
		id: 'ui.segments.importModal.steps.chooseWebsite.noUniqueSegments',
	},
	chooseWebsiteStepNumberOfSegments: {
		id: 'ui.segments.importModal.steps.chooseWebsite.numberOfSegments',
	},
	chooseWebsiteStepSubmitButton: {
		id: 'ui.segments.importModal.steps.chooseWebsite.submitButton',
	},
	chooseWebsiteStepTitle: {
		id: 'ui.segments.importModal.steps.chooseWebsite.title',
	},
	chooseWebsiteStepWebsiteFieldLabel: {
		id: 'ui.segments.importModal.steps.chooseWebsite.websiteFieldLabel',
	},
	sidebarParagraph1: {
		id: 'ui.segments.importModal.sidebar.paragraph1',
	},
	sidebarParagraph2: {
		id: 'ui.segments.importModal.sidebar.paragraph2',
	},
	titleMainPart: {
		id: 'ui.segments.importModal.title.mainPart',
	},
	titlePrefix: {
		id: 'ui.segments.importModal.title.prefix',
	},
});

const chooseSegmentsValidations = {
	segments: [
		{
			message: '',
			field: 'segments',
			rule: ({ values, name }) => values[name] && values[name].length > 0,
		},
	],
};

const chooseWebsiteValidations = {
	website: validateField(
		'website',
		(f) => [
			f.validateNonEmpty(),
		],
	),
};



const memoizedCompareSegments = memoize(compareSegments);
const EMPTY_LIST = [];

function listWebsites(
	homeAccountData: {
		websites: ReadonlyArray<{
			displayName: string,
			id: CK.WebsiteId,
			pageSegments: ReadonlyArray<SegmentDefinition>,
		}>,
	},
	targetWebsiteId: CK.WebsiteId,
) {
	const targetWebsiteSegments = homeAccountData.websites.find(
		(website) => website.id === targetWebsiteId,
	)?.pageSegments ?? EMPTY_LIST;

	return homeAccountData.websites.map((website) => {
		const segments = website.pageSegments;

		const segmentsComparison = memoizedCompareSegments(
			segments,
			targetWebsiteSegments,
			{
				count: 0,
				getByColumn: () => null,
				getByName: () => null,
				isLoaded: true,
				listAll: () => [],
			},
			EMPTY_LIST,
		);

		const targetOnlyHasIdenticalSegments = segments.every(
			(segmentDefinition) => segmentsComparison(segmentDefinition.name).verdict === SegmentsComparisonVerdict.PresentIdentical,
		);

		return {
			description:
				segments.length > 0 && targetOnlyHasIdenticalSegments ? (
					<FormattedMessage {...messages.chooseWebsiteStepNoUniqueSegments} />
				) : (
					<FormattedMessage
						{...messages.chooseWebsiteStepNumberOfSegments}
						values={{
							numberOfSegments: segments.length,
						}}
					/>
				),
			disabled: (
				segments.length === 0
				|| website.id === targetWebsiteId
				|| targetOnlyHasIdenticalSegments
			),
			icon: (
				<WebsiteFavicon
					usageContext={WebsiteFaviconUsageContext.List}
					websiteId={website.id}
				/>
			),
			label: website.displayName,
			name: website.id,
		};
	});
}



const ImportSegmentsModal: React.FC = () => {
	const classicFormBehavior = useClassicFormBehavior();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const targetWebsiteId = useWebsiteId();

	const [sourceWebsiteId, setSourceWebsiteId] = React.useState<CK.WebsiteId | null>(null);

	const importSegments = useImportSegmentDefinitions({
		sourceWebsiteId,
		targetWebsiteId,
	});

	const sourceWebsiteDisplayName = useWebsiteDisplayName(sourceWebsiteId);

	const { data } = useImportSegmentsModalQuery({
		skip: effectiveHomeAccountId === null,
		variables: {
			accountId: effectiveHomeAccountId ?? 0,
		},
	});

	const homeAccountData = data?.account ?? null;

	const modalRef = React.useRef<MultiStepModalRef>();

	const handleChooseSegmentsSubmit = React.useCallback(
		async ({ segments }) => {
			await importSegments(segments);

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			importSegments,
		],
	);

	const handleChooseWebsiteSubmit = React.useCallback(
		({ website }) => {
			setSourceWebsiteId(website);

			modalRef.current?.goToNextStep();
		},
		[],
	);

	const renderChooseSegmentsStep = React.useCallback(
		() => {
			if (!sourceWebsiteId) {
				return false;
			}

			return (
				<>
					<ModalTextSection>
						<FormattedMessage
							{...messages.chooseSegmentsStepDescription}
							values={{
								sourceWebsite: sourceWebsiteDisplayName ?? '',
							}}
						/>
					</ModalTextSection>

					<ModalContentSection>
						<Form
							onSuccess={handleChooseSegmentsSubmit}
							validations={chooseSegmentsValidations}
						>
							<ChooseImportedSegmentsTableField
								name="segments"
								sourceWebsiteId={sourceWebsiteId}
								targetWebsiteId={targetWebsiteId}
							/>

							<ButtonsLayout
								alignment={ButtonsLayoutAlignment.Right}
								layout={ButtonsLayoutType.Steps}
							>
								<BackButton />

								<SubmitButton>
									<FormattedMessage {...messages.chooseSegmentsStepSubmitButton} />
								</SubmitButton>
							</ButtonsLayout>
						</Form>
					</ModalContentSection>
				</>
			);
		},
		[
			handleChooseSegmentsSubmit,
			sourceWebsiteDisplayName,
			sourceWebsiteId,
			targetWebsiteId,
		],
	);

	const renderChooseWebsiteStep = React.useCallback(
		() => (
			<>
				<ModalTextSection>
					<FormattedMessage {...messages.chooseWebsiteStepDescription} />
				</ModalTextSection>

				<ModalTextSection>
					<Form
						onSuccess={handleChooseWebsiteSubmit}
						validations={chooseWebsiteValidations}
					>
						<FormRow
							htmlFor="website"
							label={(
								<FormattedMessage {...messages.chooseWebsiteStepWebsiteFieldLabel} />
							)}
						>
							<FieldStatus name="website">
								{homeAccountData !== null && (
									<SelectField
										isInteractedByDefault={false}
										name="website"
										options={listWebsites(
											homeAccountData,
											targetWebsiteId,
										)}
										searchable={true}
									/>
								)}
							</FieldStatus>
						</FormRow>

						<ButtonsLayout
							alignment={ButtonsLayoutAlignment.Right}
							layout={ButtonsLayoutType.Steps}
						>
							<CancelButton />

							<SubmitButton>
								<FormattedMessage {...messages.chooseWebsiteStepSubmitButton} />
							</SubmitButton>
						</ButtonsLayout>
					</Form>
				</ModalTextSection>
			</>
		),
		[
			handleChooseWebsiteSubmit,
			homeAccountData,
			targetWebsiteId,
		],
	);

	const renderSidebar = React.useCallback(
		() => (
			<MarginsList size={MARGINS_SIZE_LARGE}>
				<RichText>
					<p>
						<FormattedMessage {...messages.sidebarParagraph1} />
					</p>
					<p>
						<FormattedMessage {...messages.sidebarParagraph2} />
					</p>
				</RichText>
			</MarginsList>
		),
		[],
	);

	const isLoaded = homeAccountData !== null;

	return (
		<MultiStepModal
			isContentDisabled={!isLoaded}
			preloader={!isLoaded && (
				<LoadingDots isStretched={true} />
			)}
			ref={modalRef}
		>
			<MultiStepModalStep
				breadcrumb={(
					<FormattedMessage {...messages.chooseWebsiteStepTitle} />
				)}
				headerIconType={MultiStepModalStepHeaderIconType.Import}
				name="choose-website-step"
				sidebar={renderSidebar}
				title={(
					<HeaderTitleWithValue
						size={TITLE_SIZE_MODAL}
						title={(
							<FormattedMessage {...messages.titlePrefix} />
						)}
						value={(
							<FormattedMessage {...messages.titleMainPart} />
						)}
					/>
				)}
			>
				{renderChooseWebsiteStep()}
			</MultiStepModalStep>

			<MultiStepModalStep
				breadcrumb={(
					<FormattedMessage {...messages.chooseSegmentsStepTitle} />
				)}
				headerIconType={MultiStepModalStepHeaderIconType.Import}
				name="choose-segments-step"
				sidebar={renderSidebar}
				title={(
					<HeaderTitleWithValue
						size={TITLE_SIZE_MODAL}
						title={(
							<FormattedMessage {...messages.titlePrefix} />
						)}
						value={(
							<FormattedMessage {...messages.titleMainPart} />
						)}
					/>
				)}
			>
				{renderChooseSegmentsStep()}
			</MultiStepModalStep>
		</MultiStepModal>
	);
};



export default ImportSegmentsModal;
