import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import Form from '~/components/atoms/forms/basis/Form';
import SubmitButton from '~/components/app/SubmitButton';
import UnifiedHeightContainer from '~/components/patterns/utils/UnifiedHeightContainer';
import UserRoleField, {
	validateUserRole,
} from '~/components/app/UserRoleField';

import useAccountId from '~/hooks/useAccountId';



const submitButtonMessages = defineMessages({
	selectWebsites: {
		id: 'ui.changeRoleModal.steps.changeRole.content.submitButton.selectWebsites',
	},
	sendInvitation: {
		id: 'ui.changeRoleModal.steps.changeRole.content.submitButton.changeRole',
	},
});

const validations = {
	validateUserRole,
};



type Props = {
	onRoleChange: (role: GraphQL.UserRole) => void,
	onSubmitCallback: (role: GraphQL.UserRole) => void,
	role: GraphQL.UserRole,
};

const ChangeRoleForm: React.FC<Props> = (props) => {
	const {
		onRoleChange,
		onSubmitCallback,
		role,
	} = props;

	const accountId = useAccountId();

	const handleFormChange = React.useCallback(
		(field, value) => {
			if (field === 'role') {
				onRoleChange(value);
			}
		},
		[
			onRoleChange,
		],
	);

	const handleSuccess = React.useCallback(
		(values) => {
			onSubmitCallback(values.role);
		},
		[
			onSubmitCallback,
		],
	);

	return (
		<Form
			defaultFocus="role"
			defaultValues={{
				role,
			}}
			onChangeCallback={handleFormChange}
			onSuccess={handleSuccess}
			validations={validations}
		>
			{({ values }) => (
				<>
					<UnifiedHeightContainer>
						<UserRoleField
							accountId={accountId}
							diffFrom={role}
							name="role"
						/>
					</UnifiedHeightContainer>

					<ButtonsLayout layout={ButtonsLayoutType.Steps}>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...submitButtonMessages[(values.role !== GraphQL.UserRole.Editor && values.role !== GraphQL.UserRole.Viewer) ? 'sendInvitation' : 'selectWebsites']} />
						</SubmitButton>
					</ButtonsLayout>
				</>
			)}
		</Form>
	);
};



export default ChangeRoleForm;
