import classNames from 'classnames';
import React from 'react';



export enum ColumnsMessageSize {
	Default = 'default',
	Large = 'large',
}

type Props = {
	children: React.ReactNode,
	size?: ColumnsMessageSize,
};



const ColumnsMessage: React.FC<Props> = (props) => {
	const {
		children,
		size = ColumnsMessageSize.Default,
	} = props;

	const componentClasses = classNames({
		'page-columns-message': true,
		['page-columns-message--' + size + '-size']: true,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default ColumnsMessage;
