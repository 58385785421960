import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



export const SIZE_DEFAULT = 'default';
export const SIZE_MODAL = 'modal';
export const SIZE_SMALL = 'small';
export const STYLE_CRITICAL = 'critical';
export const STYLE_PRIMARY = 'primary';



class HeaderTitle extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleClick = this._handleClick.bind(this);
	}



	_handleClick(e) {
		const {
			onClickCallback,
		} = this.props;

		e.preventDefault();

		if (!onClickCallback) {
			return false;
		}

		onClickCallback();
	}



	render() {
		const {
			baselineAlignment,
			children,
			icon,
			onClickCallback,
			size,
			style,
			value,
		} = this.props;

		const componentClasses = classNames({
			'header-title': true,
			'header-title--is-clickable': onClickCallback,
			'header-title--baseline-alignment': baselineAlignment,
			'header-title--small': size === SIZE_SMALL,
			'header-title--modal': size === SIZE_MODAL,
			['header-title--' + style + '-style']: true,
		});

		return (
			<div
				className={componentClasses}
				onClick={this._handleClick}
			>
				{icon && (
					<span className="header-title__icon">
						{icon}
					</span>
				)}
				{children && (
					<span className="header-title__main">
						{children}
					</span>
				)}
				{value && (
					<span className="header-title__value">
						{value}
					</span>
				)}
			</div>
		);
	}

}

HeaderTitle.defaultProps = {
	baselineAlignment: true,
	size: SIZE_DEFAULT,
	style: STYLE_PRIMARY,
};

HeaderTitle.propTypes = {
	/** Vertical alignment of items inside title */
	baselineAlignment: PropTypes.bool,
	/** Possibility to make title clickable */
	onClickCallback: PropTypes.func,
	/** Icon prepended to main label */
	icon: PropTypes.node,
	/** Title size */
	size: PropTypes.oneOf([
		SIZE_DEFAULT,
		SIZE_MODAL,
		SIZE_SMALL,
	]).isRequired,
	/** Title style */
	style: PropTypes.oneOf([
		STYLE_CRITICAL,
		STYLE_PRIMARY,
	]).isRequired,
	/** Dynamic part of title with ellipsis style to prevent unwanted overflowing */
	value: PropTypes.node,
};



export default HeaderTitle;
