import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InternalLink from '~/components/patterns/links/InternalLink';
import Parenthesis from '~/components/logic/Parenthesis';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import SetupIndexNowKeyModal from '~/components/app/SetupIndexNowKeyModal';
import Spinner from '~/components/patterns/loaders/Spinner';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubmitButton from '~/components/app/SubmitButton';
import SwitchField from '~/components/app/SwitchField';

import {
	useUpdateWebsiteIndexNowStatusMutation,
} from './WebsiteSettingsIndexNowForm.gql';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useOpenModal from '~/hooks/useOpenModal';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsIndexNowEnabled from '~/hooks/useWebsiteIsIndexNowEnabled';



const messages = defineMessages({
	changeKey: {
		id: 'ui.websiteSettingsIndexNowForm.changeKey',
	},
	disabled: {
		id: 'ui.websites.form.api.status.disabled',
	},
	enable: {
		id: 'ui.websiteSettingsIndexNowForm.enable',
	},
	enabled: {
		id: 'ui.websites.form.api.status.enabled',
	},
	saveButton: {
		id: 'ui.general.saveButton',
	},
	submitPages: {
		id: 'ui.websiteSettingsIndexNowForm.submitPages',
	},
	title: {
		id: 'ui.websiteSettingsIndexNowForm.title',
	},
});



const WebsiteSettingsIndexNowForm: React.FC = () => {
	const websiteId = useWebsiteId();
	const isIndexNowEnabled = useWebsiteIsIndexNowEnabled(websiteId);

	const isAllowedToManageIndexNow = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageIndexNow,
	);

	const loading = isIndexNowEnabled === null;

	const formRef = React.useRef<FormRef>(null);

	const [updateWebsiteIndexNowStatus] = useUpdateWebsiteIndexNowStatusMutation();

	const openSetupKeyModal = useOpenModal(
		() => (
			<SetupIndexNowKeyModal
				onFinish={(isIndexNowEnabled) => {
					formRef.current?.setValue('indexNowEnabled', isIndexNowEnabled);
				}}
			/>
		),
		[],
	);

	const handleFormSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteIndexNowStatus({
				variables: {
					isIndexNowEnabled: values.indexNowEnabled,
					websiteId,
				},
			});
		},
		[
			updateWebsiteIndexNowStatus,
			websiteId,
		],
	);

	function renderDisplayPart() {
		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.submitPages} />
					)}
				>
					<StaticText>
						{loading ? (
							<Spinner />
						) : isIndexNowEnabled ? (
							<FormattedMessage {...messages.enabled} />
						) : (
							<FormattedMessage {...messages.disabled} />
						)}
					</StaticText>
				</FormRow>
			</FormRows>
		);
	}

	function renderForm() {
		return (
			<Form
				defaultValues={{
					indexNowEnabled: isIndexNowEnabled,
				}}
				key={loading ? 'loading' : 'ready'}
				onSuccess={handleFormSubmit}
				ref={formRef}
			>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.submitPages} />
						)}
					>
						{isIndexNowEnabled ? (
							<SwitchField
								activeStateLabel={(
									<>
										<FormattedMessage {...messages.enabled} />
										{' '}
										<Parenthesis
											content={(
												<InternalLink onClickCallback={openSetupKeyModal}>
													<FormattedMessage {...messages.changeKey} />
												</InternalLink>
											)}
										/>
									</>
								)}
								inactiveStateLabel={(
									<FormattedMessage {...messages.disabled} />
								)}
								name="indexNowEnabled"
							/>
						) : (
							<StaticText>
								<InternalLink onClickCallback={openSetupKeyModal}>
									<FormattedMessage {...messages.enable} />
								</InternalLink>
							</StaticText>
						)}
					</FormRow>
				</FormRows>

				<ButtonsLayout>
					<CancelButton />

					<SubmitButton>
						<FormattedMessage {...messages.saveButton} />
					</SubmitButton>
				</ButtonsLayout>
			</Form>
		);
	}

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.IndexNow}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => (
				<EditableFormWrapper
					isAllowed={isAllowedToManageIndexNow}
					isReadOnly={isFeatureEnabled === false}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<DisabledContent
							disabledContent={!isFeatureEnabled}
							disabledOverlay={!isFeatureEnabled && premiumAnnotation}
						>
							{renderDisplayPart()}
						</DisabledContent>
					</DisplayPart>

					<EditablePart>
						{renderForm()}
					</EditablePart>
				</EditableFormWrapper>
			)}
		</PremiumFeatureSituation>
	);
};



export default WebsiteSettingsIndexNowForm;
