export function trimHostname(url) {
	if (!url) {
		return '';
	}

	url = url.substring(
		url.indexOf('://') + 3,
	);

	return url.substring(
		url.indexOf('/'),
	);
}
