import React from 'react';

import CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import ColumnName from '~/components/names/ColumnName';
import ConflictingHreflangTargetsTable from './ConflictingHreflangTargetsTable';
import ConflictingRobotDirectivesTable from './ConflictingRobotDirectivesTable';
import ContentHighlightBox from './ContentHighlightBox';
import ContentLengthHighlightBox from './ContentLengthHighlightBox';
import HeadingLevelsSkipped from './HeadingLevelsSkipped';
import IgnoringButton from '~/components/app/IgnoringButton';
import ImageMarkup from './ImageMarkup';
import InvalidHreflangTargetsTable from './InvalidHreflangTargetsTable';
import InvalidRobotDirectivesTable from './InvalidRobotDirectivesTable';
import InvalidSchemaOrgJsonTable from './InvalidSchemaOrgJsonTable';
import IssueDetail from '~/components/app/IssueDetail';
import LighthouseWebVitalsTable from './LighthouseWebVitalsTable';
import MetaRobotsCase from './MetaRobotsCase';
import OutgoingLinksTable from './OutgoingLinksTable';
import PagesWithSameDuplicateContent from './PagesWithSameDuplicateContent';
import SchemaOrgErrorsTable from './SchemaOrgErrorsTable';
import SitemapsTable from './SitemapsTable';
import TooManyCanonicalLinksCase from './TooManyCanonicalLinksCase';
import TooManyCase from './TooManyCase';

import {
	type IssueCategoryName,
	type IssueName,
	PageIssue,
	getEntryScopeInPageContext,
	isIssueOfName,
} from '~/model/issuesNew';

import {
	getCategoryUrlIdentifier,
	getUrlIdentifier,
} from '~/model/issues/identifierMapping';



type Props = {
	issue: {
		configuration: Record<string, any>,
		context: any,
		followupIssues: ReadonlyArray<{
			configuration: Record<string, any>,
			isConfigurationModified: boolean,
			name: string,
		}>,
		ignoringRuleOnCases: {
			ignoredCases: ReadonlyArray<any>,
		},
		ignoringRuleOnPage: {
			isEffective: boolean,
		},
		ignoringRuleOnSegments: {
			isEffective: boolean,
		},
		ignoringRuleOnWebsite: {
			isEffective: boolean,
		},
		isActionable: boolean,
		isConfigurationModified: boolean,
		isIgnored: boolean,
		name: IssueName,
		pointsGained: number,
		pointsToGain: number,
		state: GraphQL.IssueState,
	},
	issueCategoryName: IssueCategoryName,
	legacyUrlId: number,
	pageId: number,
	websiteId: CK.WebsiteId,
};

const PageIssueDetail: React.FC<Props> = (props) => {
	const {
		issue,
		issueCategoryName,
		legacyUrlId,
		pageId,
		websiteId,
	} = props;

	const renderCaseIllustration = React.useCallback(
		() => {
			if (isIssueOfName(issue, PageIssue.CanonicalLinkIncorrectlyCanonicalized)) {
				return (
					<MetaRobotsCase context={issue.context} />
				);
			}

			if (isIssueOfName(issue, PageIssue.CanonicalLinkTooMany)) {
				return (
					<TooManyCanonicalLinksCase
						canonicalLinks={issue.context.canonicalLinks}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.H1Duplicate)) {
				return ({ containerWidth }) => (
					<PagesWithSameDuplicateContent
						elementType={CK.PagesCommonColumn.H1}
						tableWidth={containerWidth}
						urlsCount={issue.context.urls_count}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.H1IncorrectLength)) {
				return (
					<ContentLengthHighlightBox
						content={issue.context.h1}
						label={(
							<ColumnName column={CK.PagesCommonColumn.H1} />
						)}
						maxLength={issue.configuration.maxChars}
						minLength={issue.configuration.minChars}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.H1TooMany)) {
				return (
					<TooManyCase
						elementLabel={(
							<ColumnName column={CK.PagesCommonColumn.H1} />
						)}
						elements={issue.context.h1}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.H1LevelsSkipped)) {
				return (
					<HeadingLevelsSkipped
						issueContext={issue.context}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.HreflangConflictingTargets)) {
				return ({ containerWidth }) => (
					<ConflictingHreflangTargetsTable
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.HreflangInvalidTarget)) {
				return ({ containerWidth }) => (
					<InvalidHreflangTargetsTable
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.HreflangInvalidValue)) {
				return ({ containerWidth }) => (
					<InvalidHreflangTargetsTable
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.ImagesAltAttribute)) {
				return ({ containerWidth }) => (
					<ImageMarkup
						attribute="alt"
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.ImagesMixedTransport)) {
				return ({ containerWidth }) => (
					<ImageMarkup
						attribute="mixedTransport"
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (
				isIssueOfName(issue, PageIssue.LighthouseCls)
				|| isIssueOfName(issue, PageIssue.LighthouseFcp)
				|| isIssueOfName(issue, PageIssue.LighthouseLcp)
				|| isIssueOfName(issue, PageIssue.LighthousePerformance)
				|| isIssueOfName(issue, PageIssue.LighthouseSi)
				|| isIssueOfName(issue, PageIssue.LighthouseTbt)
				|| isIssueOfName(issue, PageIssue.LighthouseTti)
			) {
				return ({ containerWidth }) => (
					<LighthouseWebVitalsTable
						issue={issue}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.LinksBroken)) {
				const issueName = issue.name;

				return ({ containerWidth }) => (
					<OutgoingLinksTable
						ignoredCases={issue.ignoringRuleOnCases.ignoredCases}
						legacyUrlId={legacyUrlId}
						linkIssueName={issueName}
						tableWidth={containerWidth}
						websiteId={websiteId}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.LinksRedirected)) {
				const issueName = issue.name;

				return ({ containerWidth }) => (
					<OutgoingLinksTable
						ignoredCases={issue.ignoringRuleOnCases.ignoredCases}
						legacyUrlId={legacyUrlId}
						linkIssueName={issueName}
						tableWidth={containerWidth}
						websiteId={websiteId}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.LinksToCanonicalized)) {
				const issueName = issue.name;

				return ({ containerWidth }) => (
					<OutgoingLinksTable
						ignoredCases={issue.ignoringRuleOnCases.ignoredCases}
						legacyUrlId={legacyUrlId}
						linkIssueName={issueName}
						tableWidth={containerWidth}
						websiteId={websiteId}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.MetaDescriptionDuplicate)) {
				return ({ containerWidth }) => (
					<PagesWithSameDuplicateContent
						elementType={CK.PagesCommonColumn.MetaDescription}
						tableWidth={containerWidth}
						urlsCount={issue.context.urls_count}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.MetaDescriptionIncorrectLength)) {
				return (
					<ContentLengthHighlightBox
						content={issue.context.metaDescription}
						label={(
							<ColumnName column={CK.PagesCommonColumn.MetaDescription} />
						)}
						maxLength={issue.configuration.maxChars}
						minLength={issue.configuration.minChars}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.MetaDescriptionTooMany)) {
				return (
					<TooManyCase
						elementLabel={(
							<ColumnName column={CK.PagesCommonColumn.MetaDescription} />
						)}
						elements={issue.context.metaDescription}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.OpenGraphDescriptionIncorrectLength)) {
				return (
					<ContentLengthHighlightBox
						content={issue.context.openGraphDescription}
						label={(
							<ColumnName column={CK.PagesCommonColumn.OpenGraphDescription} />
						)}
						maxLength={issue.configuration.maxChars}
						minLength={issue.configuration.minChars}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.OpenGraphTitleIncorrectLength)) {
				return (
					<ContentLengthHighlightBox
						content={issue.context.openGraphTitle}
						label={(
							<ColumnName column={CK.PagesCommonColumn.OpenGraphTitle} />
						)}
						maxLength={issue.configuration.maxChars}
						minLength={issue.configuration.minChars}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.RobotDirectivesConflicting)) {
				return ({ containerWidth }) => (
					<ConflictingRobotDirectivesTable
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.RobotDirectivesInvalid)) {
				return ({ containerWidth }) => (
					<InvalidRobotDirectivesTable
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.RobotDirectivesUnsupported)) {
				return ({ containerWidth }) => (
					<InvalidRobotDirectivesTable
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.SchemaOrgErrors)) {
				return ({ containerWidth }) => (
					<SchemaOrgErrorsTable
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.SchemaOrgInvalidJson)) {
				return ({ containerWidth }) => (
					<InvalidSchemaOrgJsonTable
						issueContext={issue.context}
						tableWidth={containerWidth}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.TitleDuplicate)) {
				return ({ containerWidth }) => (
					<PagesWithSameDuplicateContent
						elementType={CK.PagesCommonColumn.Title}
						tableWidth={containerWidth}
						urlsCount={issue.context.urls_count}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.TitleIncorrectLength)) {
				return (
					<ContentLengthHighlightBox
						content={issue.context.title}
						label={(
							<ColumnName column={CK.PagesCommonColumn.Title} />
						)}
						maxLength={issue.configuration.maxChars}
						minLength={issue.configuration.minChars}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.TitleTooMany)) {
				return (
					<TooManyCase
						elementLabel={(
							<ColumnName column={CK.PagesCommonColumn.Title} />
						)}
						elements={issue.context.title}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.TwitterCardsDescriptionIncorrectLength)) {
				return (
					<ContentLengthHighlightBox
						content={issue.context.twitterCardsDescription}
						label={(
							<ColumnName column={CK.PagesCommonColumn.TwitterDescription} />
						)}
						maxLength={issue.configuration.maxChars}
						minLength={issue.configuration.minChars}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.TwitterCardsTitleIncorrectLength)) {
				return (
					<ContentLengthHighlightBox
						content={issue.context.twitterCardsTitle}
						label={(
							<ColumnName column={CK.PagesCommonColumn.TwitterTitle} />
						)}
						maxLength={issue.configuration.maxChars}
						minLength={issue.configuration.minChars}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.TwitterCardsTypeInvalid)) {
				return (
					<ContentHighlightBox
						content={issue.context.twitterCardsType}
						label={(
							<ColumnName column={CK.PagesCommonColumn.TwitterCard} />
						)}
					/>
				);
			}

			if (isIssueOfName(issue, PageIssue.XmlSitemapIncorrectlyPresent)) {
				return ({ containerWidth }) => (
					<SitemapsTable
						sitemaps={issue.context.sitemaps}
						tableWidth={containerWidth}
					/>
				);
			}

			return null;
		},
		[
			issue,
			legacyUrlId,
			websiteId,
		],
	);

	const renderIgnoringButton = React.useCallback(
		() => (
			<IgnoringButton
				isIgnored={issue.isIgnored}
				routeName="website.pages.detail.issuesCategory.issueIgnoring"
				routeParams={{
					id: pageId,
					pageDetailIssue: getUrlIdentifier(issue.name),
					pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategoryName),
					scope: getEntryScopeInPageContext(issue),
					websiteId,
				}}
			/>
		),
		[
			issue,
			issueCategoryName,
			pageId,
			websiteId,
		],
	);

	return (
		<IssueDetail
			issue={issue}
			renderCaseIllustration={renderCaseIllustration}
			renderIgnoringButton={renderIgnoringButton}
		/>
	);
};



export default PageIssueDetail;
