import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';



export enum ButtonNextIconSize {
	Default = 18,
	Large = 24,
	Small = 16,
}



type Props = {
	disabled?: boolean,
	size?: ButtonNextIconSize,
};

const ButtonNextIcon: React.FC<Props> = (props) => {
	const {
		disabled = false,
		size = ButtonNextIconSize.Default,
	} = props;

	return (
		<BasicIcon
			color={disabled ? '#d3d3d3' : '#ffffff'}
			size={size}
			type={BasicIconType.ArrowRight}
		/>
	);
};



export default ButtonNextIcon;
