import React from 'react';
import {
	useIntl,
} from 'react-intl';



type Props = {
	/** Currect currency code */
	currency: string,
	/** Price value */
	value: number,
};



const Price: React.FC<Props> = (props) => {
	const {
		currency,
		value,
	} = props;

	const intl = useIntl();

	const formattedPriceWithDecimals = intl.formatNumber(
		value,
		{
			currency,
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
			style: 'currency',
		},
	);

	const formattedPriceWithoutDecimals = intl.formatNumber(
		Math.floor(value),
		{
			currency,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
			style: 'currency',
		},
	);

	// fallback for null or blank values (eg. during loading)
	if (formattedPriceWithDecimals.length === 0) {
		return (
			<>
				{formattedPriceWithDecimals}
			</>
		);
	}

	const hasDecimalPart = value % 1 > 0;

	// check whether currency sign is at the end of the string
	if ('0123456789'.includes(formattedPriceWithDecimals.charAt(0))) {
		const priceOnlyWithDecimals = formattedPriceWithDecimals.replace(/\D+$/i, '');
		const priceOnlyWithoutDecimals = formattedPriceWithoutDecimals.replace(/\D+$/i, '');
		const currencySign = formattedPriceWithDecimals.slice(
			priceOnlyWithDecimals.length,
			formattedPriceWithDecimals.length,
		);

		let decimals;

		if (hasDecimalPart) {
			decimals = priceOnlyWithDecimals.slice(
				priceOnlyWithoutDecimals.length,
				priceOnlyWithDecimals.length,
			);
		}

		return (
			<span className="price">
				<span className="price__value">
					{priceOnlyWithoutDecimals}
				</span>
				{decimals && (
					<span className="price__decimals">
						{decimals}
					</span>
				)}
				<span className="price__currency-sign">
					{currencySign}
				</span>
			</span>
		);
	}

	// currency sign is at the beginning of the string
	const priceOnlyWithDecimals = formattedPriceWithDecimals.replace(/^\D+/i, '');
	const priceOnlyWithoutDecimals = formattedPriceWithoutDecimals.replace(/^\D+/i, '');
	const currencySign = formattedPriceWithDecimals.slice(
		0,
		formattedPriceWithDecimals.length - priceOnlyWithDecimals.length,
	);

	let decimals;

	if (hasDecimalPart) {
		decimals = priceOnlyWithDecimals.slice(
			priceOnlyWithoutDecimals.length,
			priceOnlyWithDecimals.length,
		);
	}

	return (
		<span className="price">
			<span className="price__currency-sign">
				{currencySign}
			</span>
			<span className="price__value">
				{priceOnlyWithoutDecimals}
			</span>
			{decimals && (
				<span className="price__decimals">
					{decimals}
				</span>
			)}
		</span>
	);
};



export default Price;
