import {
	useAccountFeaturesAvailabilityQuery,
} from './useAccountFeaturesAvailability.gql';



function useAccountFeaturesAvailability() {
	const { data } = useAccountFeaturesAvailabilityQuery();

	return data?.accountFeaturesAvailability ?? null;
}



export default useAccountFeaturesAvailability;
