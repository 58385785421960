import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import DateLabel from '~/components/app/DateLabel';
import DefinitionTerm, {
	DefinitionTermStyle,
} from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import InternalLink from '~/components/patterns/links/InternalLink';
import MissingValue from '~/components/app/MissingValue';
import StatusMessage from '~/components/patterns/messages/embedded/StatusMessage';

import usePageNumberOfIncomingInternalLinks from '~/hooks/usePageNumberOfIncomingInternalLinks';
import usePageRedirectTarget from '~/hooks/usePageRedirectTarget';
import usePageStatusCode from '~/hooks/usePageStatusCode';
import usePageTypeLastChangedAt from '~/hooks/usePageTypeLastChangedAt';



const messages = defineMessages({
	incomingRelationsDescription: {
		id: 'ui.pageDetail.statusMessage.redirect.incomingRelationsDescription',
	},
	incomingRelationsLink: {
		id: 'ui.pageDetail.statusMessage.general.incomingRelationsLink',
	},
	incomingRelationsTitle: {
		id: 'ui.pageDetail.statusMessage.general.warning',
	},
	since: {
		id: 'ui.pageDetail.statusMessage.redirect.since',
	},
	title: {
		id: 'ui.pageDetail.statusMessage.redirect.title',
	},
	to: {
		id: 'ui.pageDetail.statusMessage.redirect.to',
	},
});



type Props = {
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const Redirect: React.FC<Props> = (props) => {
	const {
		legacyUrlId,
		websiteId,
	} = props;

	const pageNumberOfIncomingInternalLinks = usePageNumberOfIncomingInternalLinks(legacyUrlId, websiteId);
	const pageRedirectTarget = usePageRedirectTarget(legacyUrlId, websiteId);
	const pageStatusCode = usePageStatusCode(legacyUrlId, websiteId);
	const pageTypeLastChangedAt = usePageTypeLastChangedAt(legacyUrlId, websiteId);

	const numberOfIncomingLinks = pageNumberOfIncomingInternalLinks ?? 0;

	let redirectTarget: React.ReactNode;

	if (pageRedirectTarget !== null) {
		if (
			pageRedirectTarget.isCrossdomain === false
			&& pageRedirectTarget.legacyUrlId !== null
		) {
			redirectTarget = (
				<InternalLink
					routeName="website.pages.detail"
					routeParams={{
						id: pageRedirectTarget.legacyUrlId,
						websiteId,
					}}
				>
					{pageRedirectTarget.url}
				</InternalLink>
			);
		} else {
			redirectTarget = pageRedirectTarget.url;
		}
	} else {
		redirectTarget = (
			<MissingValue />
		);
	}

	const definitionTerms: Array<React.ReactNode> = [];

	if (pageTypeLastChangedAt !== null) {
		definitionTerms.push(
			<DefinitionTerm
				description={(
					<DateLabel date={pageTypeLastChangedAt} />
				)}
				key="term-1"
				term={(
					<FormattedMessage {...messages.since} />
				)}
			/>,
		);
	}

	definitionTerms.push(
		<DefinitionTerm
			description={redirectTarget}
			key="term-2"
			term={(
				<FormattedMessage {...messages.to} />
			)}
		/>,
	);

	if (numberOfIncomingLinks > 0) {
		definitionTerms.push(
			<DefinitionTerm
				description={[
					<FormattedMessage
						key="description"
						{...messages.incomingRelationsDescription}
					/>,
					<InternalLink
						breakLines={true}
						key="link"
						routeName="website.pages.detail.links"
						routeParams={{
							id: legacyUrlId,
							pageDetailLinksType: 'incoming',
							websiteId,
						}}
					>
						<FormattedMessage {...messages.incomingRelationsLink} />
					</InternalLink>,
				]}
				key="term-3"
				showRowNumbers={false}
				style={DefinitionTermStyle.Critical}
				term={(
					<FormattedMessage {...messages.incomingRelationsTitle} />
				)}
			/>,
		);
	}

	return (
		<StatusMessage
			message={(
				<FormattedMessage
					{...messages.title}
					values={{
						text__statusCode: pageStatusCode,
					}}
				/>
			)}
		>
			{definitionTerms.length > 0 && (
				<DefinitionTermsList>
					{definitionTerms}
				</DefinitionTermsList>
			)}
		</StatusMessage>
	);
};



export default Redirect;
