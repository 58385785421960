import classNames from 'classnames';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import React from 'react';
import {
	DragDropContext,
	Draggable,
	type DraggableProvided,
	type DraggableProvidedDragHandleProps,
	type DraggableProvidedDraggableProps,
	type DraggableRubric,
	type DraggableStateSnapshot,
	type DropResult,
	Droppable,
} from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';
import {
	isFragment,
} from 'react-is';
import {
	Grid,
	ScrollSync,
} from 'react-virtualized';
import useMeasure from 'react-use-measure';

import DisabledContent from '~/components/patterns/content/DisabledContent';
import NewTableRow, {
	NewTableRowType,
} from '~/components/patterns/tables/newTable/NewTableRow';

import {
	type Merge,
} from '~/types/utilities';

import arrayMove from '~/utilities/arrayMove';
import {
	renderProp,
} from '~/utilities/renderProp';
import {
	isArray,
	isNumber,
	isString,
} from '~/utilities/typeCheck';



function getStyle(
	provided: DraggableProvided,
	style?: React.CSSProperties,
) {
	style = {
		...provided.draggableProps.style,
		...style,
	};

	if (style.transform) {
		const y = style.transform.match(/translate\((.*),(.*)\)/)?.pop();
		style.transform = `translate(0px, ${y})`;
	}

	return style;
}



export const RowHeight = 32;



export type DragHandleProps = DraggableProvidedDragHandleProps;
export type DraggableProps = DraggableProvidedDraggableProps;

export type RowProps = Merge<
	DraggableProvidedDraggableProps,
	{
		ref: (element?: HTMLElement | null) => any,
		style: React.CSSProperties,
	}
>;



type Dimension = number | [number, number];

type ChildProps<Row extends {}> = {
	dragHandleProps: DragHandleProps | undefined,
	isDragging: boolean,
	isEven: boolean,
	row: Row,
	rowIndex: number,
};

export type Props<Row extends {}> = {
	children: (props: ChildProps<Row>) => React.ReactElement<HTMLElement>,
	columnWidths: Array<number | `${number}%` | 'auto'>,
	footer?: React.ReactNode,
	header?: React.ReactNode,
	headerRow?: React.ReactNode,
	headerRowHeight?: number,
	height: Dimension,
	isReorderable?: boolean,
	onReorder?: (
		rows: ReadonlyArray<Row>,
		rowSourceIndex: number,
		rowDestinationIndex: number,
	) => void,
	overlay?: React.ReactNode,
	rows: ReadonlyArray<Row>,
	rowHeight?: number,
	width: Dimension,
};

function NewTable<Row extends {}>(props: Props<Row>) {
	const {
		children,
		columnWidths,
		footer,
		header,
		headerRowHeight = RowHeight,
		headerRow,
		height,
		isReorderable = false,
		onReorder = null,
		overlay = null,
		rows,
		rowHeight = RowHeight,
		width,
	} = props;

	const tableRef = React.useRef<HTMLTableElement | null>(null);
	const portalRef = React.useRef<HTMLDivElement | null>(null);
	const gridRef = React.useRef<HTMLDivElement | null>(null);
	const droppableId = React.useRef(Math.random().toString(36).slice(2));

	const rowCount = rows.length;

	const [headerRef, headerBounds] = useMeasure();
	const [footerRef, footerBounds] = useMeasure();

	const [minWidth, maxWidth] = React.useMemo(
		() => isArray(width) ? width : [width, width],
		[width],
	);

	const [minHeight, maxHeight] = React.useMemo(
		() => isArray(height) ? height : [height, height],
		[height],
	);

	const [
		gridColumnWidth,
		isScrollableHorizontally,
		minTbodyHeight,
		styleVars,
		tableHeight,
		tableWidth,
		theadWidth,
	] = React.useMemo(
		() => {
			let isScrollableVertically = false;
			let isScrollableHorizontally = false;

			if (gridRef.current !== null) {
				isScrollableVertically = gridRef.current.scrollWidth > gridRef.current.clientWidth;
				isScrollableHorizontally = gridRef.current.scrollHeight > gridRef.current.clientHeight;
			}

			const scrollbarHeight = isScrollableVertically ? scrollbarSize() : 0;
			const scrollbarWidth = isScrollableHorizontally ? scrollbarSize() : 0;

			const tableWidth = tableRef.current?.clientWidth ?? gridRef.current?.clientWidth ?? 0;
			const contentWidth = isNumber(tableWidth) ? Math.max(tableWidth, minWidth) : minWidth;

			let tableHeight = maxHeight;
			tableHeight -= 2;
			tableHeight -= headerBounds.height;
			tableHeight -= footerBounds.height;
			tableHeight -= !!headerRow ? headerRowHeight : 0;
			tableHeight = Math.min(rowCount * rowHeight + scrollbarHeight, tableHeight);

			let minTbodyHeight = minHeight;
			minTbodyHeight -= 2;
			minTbodyHeight -= headerBounds.height;
			minTbodyHeight -= footerBounds.height;
			minTbodyHeight -= !!headerRow ? headerRowHeight : 0;
			minTbodyHeight = Math.floor(minTbodyHeight);

			let gridColumnWidth = minWidth;
			let totalColumnsWidth = 0;
			let autoWidthColumns = 0;

			const styleVars = {
				'--row-height': `${rowHeight}px`,
				'--header-row-height': `${headerRowHeight}px`,
			};

			columnWidths.forEach((columnWidth, index) => {
				let pixelWidth: number = 0;

				if (isString(columnWidth)) {
					if (columnWidth.endsWith('%')) {
						const ratio = parseFloat(columnWidth.replace('%', '')) / 100;

						pixelWidth = (contentWidth - scrollbarWidth) * ratio;
					} else if (columnWidth === 'auto') {
						autoWidthColumns += 1;
					}
				} else {
					pixelWidth = columnWidth;
				}

				styleVars[`--column-${index + 1}-width`] = `${pixelWidth}px`;
				totalColumnsWidth += pixelWidth;
			});

			const leftoverWidth = Math.max(contentWidth - totalColumnsWidth - scrollbarWidth, 0);
			const autoWidthColumnWidth = autoWidthColumns > 0 ? leftoverWidth / autoWidthColumns : 0;
			totalColumnsWidth += leftoverWidth;

			columnWidths.forEach((columnWidth, index) => {
				if (columnWidth === 'auto') {
					styleVars[`--column-${index + 1}-width`] = `${autoWidthColumnWidth}px`;
				}
			});

			if (isNumber(tableWidth)) {
				gridColumnWidth = Math.max(gridColumnWidth, tableWidth - scrollbarWidth);
			} else {
				gridColumnWidth = 1_000;
			}

			const theadWidth = gridColumnWidth + scrollbarWidth;

			return [
				gridColumnWidth,
				isScrollableHorizontally,
				minTbodyHeight,
				styleVars,
				tableHeight,
				tableWidth,
				theadWidth,
			];
		},
		[
			columnWidths,
			footerBounds,
			headerBounds,
			headerRow,
			headerRowHeight,
			maxHeight,
			minHeight,
			minWidth,
			rowCount,
			rowHeight,
		],
	);

	const handleDragEnd = React.useCallback(
		(result: DropResult) => {
			if (isReorderable === false || onReorder === null) {
				return;
			}

			if (!result.destination) {
				return;
			}

			if (result.destination.index === result.source.index) {
				return;
			}

			const nextRows = arrayMove(rows, result.source.index, result.destination.index);

			onReorder(nextRows, result.source.index, result.destination.index);
		},
		[
			isReorderable,
			onReorder,
			rows,
		],
	);

	const renderClone = React.useCallback(
		(
			provided: DraggableProvided,
			snapshot: DraggableStateSnapshot,
			rubric: DraggableRubric,
		) => {
			if (
				tableRef.current === null
				|| portalRef.current === null
			) {
				return <></>;
			}

			const rowIndex = rubric.source.index;
			const row = rows[rowIndex] as Row;
			const isEven = rowIndex % 2 === 1;

			// We change the positioning of the cloned element from `fixed` to `absolute` to prevent the
			// cloned element from overflowing the parent component. To achieve this we have to also
			// offset the `top` & `left` position by the bounds of the parent component.
			const style = {
				...getStyle(provided),
				...styleVars,
				position: 'absolute',
			} as React.CSSProperties;

			const bounds = tableRef.current.getBoundingClientRect();
			style.top = isNumber(style.top) ? style.top - bounds.y - headerRowHeight : style.top;
			style.left = isNumber(style.left) ? style.left - bounds.x : style.left;

			const child = children({
				dragHandleProps: provided.dragHandleProps ?? undefined,
				isEven,
				isDragging: true,
				row,
				rowIndex,
			});

			const props = {
				draggableProps: provided.draggableProps,
				isEven,
				isDragging: true,
				style,
			};

			let clone: React.ReactElement | null = null;

			if (isFragment(child)) {
				clone = (
					<NewTableRow {...props}>
						{child}
					</NewTableRow>
				);
			} else {
				clone = (
					React.cloneElement(child, props)
				);
			}

			return ReactDOM.createPortal(clone, portalRef.current);
		},
		[
			children,
			headerRowHeight,
			rows,
			styleVars,
		],
	);

	const renderRow = React.useCallback(
		({ rowIndex, style }) => {
			const row = rows[rowIndex] as Row;

			return (
				<Draggable
					draggableId={row['id'] ?? `${rowIndex}`}
					index={rowIndex}
					isDragDisabled={isReorderable === false}
					key={row['id'] ?? `${rowIndex}`}
				>
					{(provided) => {
						let isEven = rowIndex % 2 === 1;

						// When a row has a transform style applied to it, it means that its being pushed
						// up or down by another dragged row. This changes their visible order in the table
						// and thus need to switch from being an 'even' row to an 'odd' row and vice-versa.
						if (provided.draggableProps.style?.transform) {
							isEven = !isEven;
						}

						const child = renderProp(children, {
							dragHandleProps: provided.dragHandleProps ?? undefined,
							isEven,
							isDragging: false,
							row,
							rowIndex,
						}) as React.ReactElement;

						const props = {
							draggableProps: provided.draggableProps,
							isEven,
							ref: provided.innerRef,
							style: getStyle(provided, style),
						};

						if (isFragment(child)) {
							return (
								<NewTableRow {...props}>
									{child}
								</NewTableRow>
							);
						}

						return (
							React.cloneElement(child, props)
						);
					}}
				</Draggable>
			);
		},
		[
			children,
			isReorderable,
			rows,
		],
	);

	const tableClasses = classNames({
		'new-table__table': true,
		'new-table__table--is-scrollable': isScrollableHorizontally,
	});

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<div
				className="new-table"
				style={{
					...styleVars,
					maxHeight,
					maxWidth,
					minHeight,
				}}
			>
				{header && (
					<div
						className="new-table__header"
						ref={headerRef}
					>
						{header}
					</div>
				)}

				<ScrollSync>
					{({
						onScroll,
						scrollLeft,
					}) => (
						<DisabledContent
							disabledContent={overlay !== null}
							disabledOverlay={overlay}
						>
							<table
								className={tableClasses}
								ref={tableRef}
							>
								{headerRow && (
									<thead style={{ transform: `translateX(${-scrollLeft}px)`, width: theadWidth }}>
										<NewTableRow type={NewTableRowType.Header}>
											{headerRow}
										</NewTableRow>
									</thead>
								)}

								<Droppable
									droppableId={droppableId.current}
									isDropDisabled={isReorderable === false}
									mode="virtual"
									renderClone={renderClone}
								>
									{(provided) => (
										<tbody
											className="new-table__body"
											ref={provided.innerRef}
											style={{ minHeight: minTbodyHeight }}
											{...provided.droppableProps}
										>
											<div ref={portalRef} />

											<Grid
												cellRenderer={renderRow}
												columnCount={1}
												columnWidth={gridColumnWidth}
												height={tableHeight}
												onScroll={onScroll}
												ref={(ref) => {
													if (ref) {
														// eslint-disable-next-line react/no-find-dom-node
														const gridDomNode = ReactDOM.findDOMNode(ref);
														if (gridDomNode instanceof HTMLDivElement) {
															provided.innerRef(gridDomNode);
															gridRef.current = gridDomNode;
														}
													}
												}}
												rowCount={rowCount}
												rowHeight={rowHeight}
												width={tableWidth}
											/>
										</tbody>

									)}
								</Droppable>

							</table>
						</DisabledContent>
					)}
				</ScrollSync>

				{footer && (
					<div
						className="new-table__footer"
						ref={footerRef}
					>
						{footer}
					</div>
				)}
			</div>
		</DragDropContext>
	);
}



export default NewTable;
