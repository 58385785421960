import React, {
	Component,
} from 'react';



export const SIZE_DEFAULT = 'default';
export const SIZE_SMALL = 'small';

class SegmentFilterElementsRow extends Component {

	render() {
		const {
			children,
		} = this.props;

		return (
			<div className="segment-filter-elements-row">
				{children}
			</div>
		);
	}

}



export default SegmentFilterElementsRow;
