import type CK from '~/types/contentking';

import {
	useWebsiteFetchingLocationQuery,
} from './useWebsiteFetchingLocation.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteFetchingLocation(websiteId: CK.WebsiteId | null) {
	const { data } = useWebsiteFetchingLocationQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.fetchingLocation) ?? null;
}



export default useWebsiteFetchingLocation;
