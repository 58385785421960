import classNames from 'classnames';
import React from 'react';

import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';



type Props = {
	/** Content of drawer */
	children: React.ReactNode,
	/** Visibility state trial bar drawer */
	isCollapsed?: boolean,
	/** Callback triggered when trying to close drawer */
	onCloseCallback: () => void,
	/** Callback triggered when clicking on target area */
	onTargetClick: () => void,
	/** Target component where will be whole drawer attached */
	target: React.ReactNode,
};



const TrialBarDrawer: React.FC<Props> = (props) => {
	const {
		children,
		isCollapsed = true,
		onCloseCallback,
		onTargetClick,
		target,
	} = props;

	return (
		<div
			className={classNames({
				'trial-bar-drawer': true,
				'trial-bar-drawer--is-collapsed': isCollapsed,
			})}
		>
			<div
				className="trial-bar-drawer__drawer-container"
			>
				<div
					className="trial-bar-drawer__drawer"
				>
					<div
						className="trial-bar-drawer__close-icon"
						onClick={onCloseCallback}
					>
						<DropdownIcon
							isActive={isCollapsed}
							size={15}
							style={DropdownIconStyle.White}
						/>
					</div>
					{children}
				</div>
			</div>
			<div
				className="trial-bar-drawer__target"
				onClick={onTargetClick}
			>
				{target}
			</div>
		</div>
	);
};



export default TrialBarDrawer;
