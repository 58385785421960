import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RobotAllowedIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M10.85,22.19h0V17.36H7.17v4.83H5.79a1.2,1.2,0,0,0-1.2,1.2h0a.6.6,0,0,0,.6.6h5.66a.6.6,0,0,0,.6-.6v-.61A.6.6,0,0,0,10.85,22.19Z"
				fill={color}
			/>
			<path
				d="M12.06,0h0a4.2,4.2,0,0,1,4.2,4.2V6a0,0,0,0,1,0,0H7.86a0,0,0,0,1,0,0V4.2A4.2,4.2,0,0,1,12.06,0Z"
				fill={color}
			/>
			<path
				d="M5.34,7.2a3.91,3.91,0,0,0-3.9,3.9V14a1.8,1.8,0,1,0,1.8,0V11.1a2.1,2.1,0,0,1,1.62-2Z"
				fill={color}
			/>
			<path
				d="M13.27,22.19h0V17.36H17v4.83h1.38a1.2,1.2,0,0,1,1.2,1.2h0a.6.6,0,0,1-.6.6H13.27a.6.6,0,0,1-.6-.6v-.61A.6.6,0,0,1,13.27,22.19Z"
				fill={color}
			/>
			<path
				d="M16.86,7.2H7.26l-.2.52a11.36,11.36,0,0,0,.2,8.48h9.6a11.41,11.41,0,0,0,.19-8.48Zm-2.1,4.08a1.08,1.08,0,1,1,1.07-1.08A1.08,1.08,0,0,1,14.76,11.28Z"
				fill={color}
			/>
			<path
				d="M18.66,7.2a3.91,3.91,0,0,1,3.9,3.9V14a1.8,1.8,0,1,1-2.7,1.55,1.82,1.82,0,0,1,.9-1.55V11.1a2.1,2.1,0,0,0-1.61-2Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default RobotAllowedIconBuilder;
