const thresholds = {
	3: [
		6,
		15,
		30,
		45,
		60,
		90,
		120,
		150,
		240,
		300,
		450,
		525,
		600,
		900,
		1_200,
		1_500,
		2_100,
		2_400,
		3_000,
		4_500,
		6_000,
		9_000,
		12_000,
		15_000,
		21_000,
		24_000,
		30_000,
		45_000,
		60_000,
		90_000,
		120_000,
		150_000,
		210_000,
		240_000,
		300_000,
		450_000,
		600_000,
		900_000,
		1_200_000,
		1_500_000,
		2_100_000,
		2_400_000,
		3_000_000,
		4_500_000,
		6_000_000,
		9_000_000,
		12_000_000,
		15_000_000,
		21_000_000,
		24_000_000,
		30_000_000,
	],
	4: [
		8,
		12,
		20,
		40,
		80,
		100,
		200,
		300,
		400,
		500,
		700,
		1_000,
		1_600,
		2_000,
		2_400,
		4_000,
		6_000,
		10_000,
		24_000,
		40_000,
		60_000,
		100_000,
		200_000,
		400_000,
		600_000,
		1_000_000,
		2_000_000,
		4_000_000,
		6_000_000,
		10_000_000,
		12_000_000,
		16_000_000,
		20_000_000,
	],
	5: [
		5,
		10,
		25,
		50,
		100,
		200,
		250,
		300,
		350,
		400,
		500,
		750,
		1_000,
		1_500,
		2_000,
		2_500,
		5_000,
		10_000,
		25_000,
		50_000,
		100_000,
		200_000,
		500_000,
		1_000_000,
		2_500_000,
		5_000_000,
		10_000_000,
		15_000_000,
		20_000_000,
	],
};



function pickMax(max, numberOfPoints) {
	return Math.ceil(max / numberOfPoints) * numberOfPoints;
}



export function calculateTickIntervalNew(maxValue, numberOfPoints) {
	const usedThresholds = thresholds[numberOfPoints] || thresholds[5];

	for (let i = 0; i < usedThresholds.length; i++) {
		if (maxValue < usedThresholds[i]) {
			return pickMax(usedThresholds[i], numberOfPoints);
		}
	}

	return pickMax(usedThresholds[0], numberOfPoints);
}
