import classNames from 'classnames';
import React from 'react';



export enum StripedTableRadioCellType {
	Data = 'data',
	Header = 'header',
	Label = 'label',
}

type Props = {
	children: React.ReactNode | string,
	/** Type of cell */
	type?: StripedTableRadioCellType,
};



const StripedTableRadioCell: React.FC<Props> = (props) => {
	const {
		children,
		type = StripedTableRadioCellType.Label,
	} = props;

	const componentClasses = classNames({
		'striped-table-radio-cell': true,
		[ 'striped-table-radio-cell--' + type ]: true,
	});

	return (
		<div className={componentClasses}>
			<div className="striped-table-radio-cell__container">
				{React.Children.map(
					children,
					(child) => {
						if (!child) {
							return false;
						}

						return (
							<div className="striped-table-radio-cell__container-column">
								{child}
							</div>
						);
					},
				)}
			</div>
		</div>
	);
};



export default StripedTableRadioCell;
