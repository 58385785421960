import {
	createState,
} from './simpleKeyStore';

import {
	get,
} from '~/model/api/API';



export const {
	loadData,
	reducer,
	selector,
} = createState(
	'sitemapsErrors',
	({
		key: sitemapId,
		websiteId,
	}) => get(
		'/websites/' + websiteId + '/sitemaps/' + sitemapId + '/errors',
		{
			limit: 100000,
		},
	).then((errors) => errors.errors),
	60,
);
