import classNames from 'classnames';
import React from 'react';



export enum PaymentMethodLabelStyle {
	TextLabel = 'text',
	PayPal = 'paypal',
}

type Props = {
	children?: React.ReactNode,
	isDisabled?: boolean,
	style: PaymentMethodLabelStyle,
};



const PaymentMethodLabel: React.FC<Props> = (props) => {
	const {
		children,
		isDisabled = false,
		style = PaymentMethodLabelStyle.TextLabel,
	} = props;

	const componentClasses = classNames({
		'payment-method-label': true,
		'payment-method-label--text-only': style === PaymentMethodLabelStyle.TextLabel,
		'payment-method-label--paypal': style === PaymentMethodLabelStyle.PayPal,
		'payment-method-label--is-disabled': isDisabled,
	});

	return (
		<span className={componentClasses}>
			{children}
		</span>
	);
};



export default PaymentMethodLabel;
