import {
	List,
} from 'immutable';

import {
	get,
} from './api/API';



export const REFERENCE_DEFAULT_LOCATION = 'default_location';
export const REFERENCE_GOOGLE_SEARCH_CONSOLE = 'google_search_console';
export const REFERENCE_MISSING = 'missing';
export const REFERENCE_ROBOTS_TXT = 'robots_txt';
export const REFERENCE_SITEMAP_INDEX = 'sitemap_index';

export const LIST_OF_ALL_SITEMAP_REFERENCES = List([
	REFERENCE_DEFAULT_LOCATION,
	REFERENCE_GOOGLE_SEARCH_CONSOLE,
	REFERENCE_MISSING,
	REFERENCE_ROBOTS_TXT,
	REFERENCE_SITEMAP_INDEX,
]);

export const STATUS_INVALID = 'invalid';
export const STATUS_MISSING = 'missing';
export const STATUS_REDIRECTED = 'redirect';
export const STATUS_SERVER_ERROR = 'server_error';
export const STATUS_TIMEOUT = 'timeout';
export const STATUS_VALID = 'valid';

export const LIST_OF_ALL_SITEMAP_STATUSES = List([
	STATUS_INVALID,
	STATUS_MISSING,
	STATUS_REDIRECTED,
	STATUS_SERVER_ERROR,
	STATUS_TIMEOUT,
	STATUS_VALID,
]);

export const TYPE_SITEMAP = 'sitemap';
export const TYPE_SITEMAP_INDEX = 'sitemap_index';

export const LIST_OF_ALL_SITEMAP_TYPES = List([
	TYPE_SITEMAP,
	TYPE_SITEMAP_INDEX,
]);



export function loadSitemapsRangeViaApi(websiteId, parameters) {
	return get('/websites/' + websiteId + '/sitemaps', parameters);
}
