import React from 'react';
import {
	useSelector,
} from 'react-redux';

import useUpdateTrackedChangesFilter from '~/hooks/useUpdateTrackedChangesFilter';

import {
	DEFAULT_FILTER,
} from '~/model/historicalChanges';

import {
	filterSelector,
} from '~/state/historicalChanges/selectors';



function useResetTrackedChangesFilter() {
	const filter = useSelector(filterSelector);
	const updateTrackedChangesFilter = useUpdateTrackedChangesFilter();

	return React.useCallback(
		() => {
			updateTrackedChangesFilter(
				filter.map((value, key) => {
					return DEFAULT_FILTER.get(key) || undefined;
				}),
			);
		},
		[
			filter,
			updateTrackedChangesFilter,
		],
	);
}



export default useResetTrackedChangesFilter;
