import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AlertsIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => (
		<g id="Page-21">
			<g
				id="statistics1"
				transform="translate(4.000000, 4.000000)"
			>
				<polygon
					fill="none"
					id="Stroke-202"
					points="15.5,15.5 0.5,15.5 0.5,0.5 15.5,0.5"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<path
				d="M4.5,12.5h4.4l2.2,2.9l2.2-7.4l1.5,4.4h4.7"
				fill="none"
				points="15.5,15.5 0.5,15.5 0.5,0.5 15.5,0.5"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default AlertsIconBuilder;

