import React from 'react';

import {
	FieldRowBuilderContext,
} from '~/components/app/FieldRowBuilder';



type Props = {
	children: React.ReactElement,
};

const FieldRowBuilderFieldSlot: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const fieldRowBuilderContext = React.useContext(FieldRowBuilderContext);

	if (fieldRowBuilderContext === null) {
		return children;
	}

	if (fieldRowBuilderContext.isEditable === false) {
		return null;
	}

	if (fieldRowBuilderContext.renderField !== null) {
		return fieldRowBuilderContext.renderField(children);
	}

	return children;
};



export default FieldRowBuilderFieldSlot;
