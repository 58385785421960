import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import {
	compileDefinitionTermsNew,
} from './compileDefinitionTerms';
import ColumnName from '~/components/names/ColumnName';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermValueSkeleton from './DefinitionTermValueSkeleton';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import InternalLink from '~/components/patterns/links/InternalLink';
import LabeledValue from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import MissingValue from '~/components/app/MissingValue';
import NoticeTag from '~/components/patterns/tags/NoticeTag';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';
import StatusDot, {
	StatusDotStatus,
} from '~/components/patterns/statuses/StatusDot';
import TextualList from '~/components/patterns/lists/TextualList';

import {
	useIndexabilityBlockPrimaryQuery,
	useIndexabilityBlockSrcQuery,
} from './IndexabilityBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import usePageDetailSourceMode from '~/hooks/usePageDetailSourceMode';
import usePageType from '~/hooks/usePageType';
import usePageUrl from '~/hooks/usePageUrl';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	DEFAULT_FILTER as SITEMAPS_LIST_DEFAULT_FILTER,
	createSitemapsListFilterParameter,
} from '~/model/sitemapsList';



const messages = defineMessages({
	contentDataCanonicalLabel: {
		id: 'ui.contentData.canonical',
	},
	contentDataCanonicalValuesToSelf: {
		id: 'ui.contentData.canonicalToSelf',
	},
	contentDataIsDisallowedInRobotsTxtLabel: {
		id: 'ui.contentOverview.tableHeading.is_disallowed_in_robots_txt',
	},
	contentDataIsDisallowedInRobotsTxtValuesYes: {
		id: 'ui.pageDetail.robotsTxt.allowed.no',
	},
	contentDataIsIndexableLabel: {
		id: 'ui.contentData.is_indexable',
	},
	contentDataIsIndexableValuesNo: {
		id: 'ui.contentData.is_indexable_values.no',
	},
	contentDataIsIndexableValuesYes: {
		id: 'ui.contentData.is_indexable_values.yes',
	},
	contentDataIsInSitemapLabel: {
		id: 'ui.contentData.is_in_sitemap',
	},
	contentDataIsInSitemapValuesNo: {
		id: 'ui.contentData.is_in_sitemap_values.no',
	},
	contentDataIsInSitemapValuesYes: {
		id: 'ui.contentData.is_in_sitemap_values.yes',
	},
	contentDataIsSecuredLabel: {
		id: 'ui.contentData.is_secured',
	},
	contentDataIsSecuredValuesNo: {
		id: 'ui.contentData.is_secured_values.no',
	},
	contentDataIsSecuredValuesYes: {
		id: 'ui.contentData.is_secured_values.yes',
	},
	contentDataMetaBingbotLabel: {
		id: 'ui.contentData.meta_bingbot',
	},
	contentDataMetaGooglebotLabel: {
		id: 'ui.contentData.meta_googlebot',
	},
	contentDataMetaRobotsLabel: {
		id: 'ui.contentData.meta_robots',
	},
	contentDataMetaSlurpLabel: {
		id: 'ui.contentData.meta_slurp',
	},
	contentDataMetaYandexLabel: {
		id: 'ui.contentData.meta_yandex',
	},
	contentDataUrlLabel: {
		id: 'ui.contentData.url',
	},
	contentDataXRobotsTagLabel: {
		id: 'ui.contentData.x_robots_tag',
	},
	nonIndexableReasonsCanonicalLink: {
		id: 'ui.nonIndexableReasons.canonicalLink',
	},
	nonIndexableReasonsMetaRobots: {
		id: 'ui.nonIndexableReasons.metaRobots',
	},
	nonIndexableReasonsRobotsTxt: {
		id: 'ui.nonIndexableReasons.robotsTxt',
	},
	nonIndexableReasonsXRobotsTag: {
		id: 'ui.nonIndexableReasons.xRobotsTag',
	},
	title: {
		id: 'ui.pageDetail.indexability.title',
	},
	whyLabel: {
		id: 'ui.hints.why.label',
	},
	xmlSitemap: {
		id: 'ui.pageDetail.xmlSitemap',
	},
});

const nonIndexableReasonsNotPageMessages = defineMessages({
	[GraphQL.PageType.Missing]: {
		id: 'ui.pageDetail.statusMessage.missing.title',
	},
	[GraphQL.PageType.Redirect]: {
		id: 'ui.pageDetail.statusMessage.redirect.title',
	},
	[GraphQL.PageType.ServerError]: {
		id: 'ui.pageDetail.statusMessage.serverError.title',
	},
	[GraphQL.PageType.Unreachable]: {
		id: 'ui.pageDetail.type.unreachable.label',
	},
});



function renderIsIndexableValue({
	isDisallowedInRobotsTxt,
	isIndexable,
	isIndexableDueToMetaRobots,
	isIndexableDueToXRobotsTag,
	linkCanonical,
	loading,
	pageType,
	pageUrl,
	statusCode,
}) {
	if (loading) {
		return (
			<SquareSkeleton
				height={20}
				style={SquareSkeletonStyle.Light}
				width={40}
			/>
		);
	}

	if (isIndexable) {
		return (
			<AttachedIcon
				ellipsis={false}
				icon={(
					<StatusDot status={StatusDotStatus.Success} />
				)}
			>
				<FormattedMessage {...messages.contentDataIsIndexableValuesYes} />
			</AttachedIcon>
		);
	} else if ([
		GraphQL.PageType.Missing,
		GraphQL.PageType.Page,
		GraphQL.PageType.Redirect,
		GraphQL.PageType.ServerError,
		GraphQL.PageType.Unreachable,
	].includes(pageType)) {
		return (
			<AttachedElement
				element={(
					<NoticeTag
						label={(
							<FormattedMessage {...messages.whyLabel} />
						)}
						tooltip={(
							<TextualList textOnlyWhenSingle={true}>
								{linkCanonical !== null && pageUrl !== linkCanonical.url && (
									<FormattedMessage {...messages.nonIndexableReasonsCanonicalLink} />
								)}
								{isDisallowedInRobotsTxt && (
									<FormattedMessage {...messages.nonIndexableReasonsRobotsTxt} />
								)}
								{isIndexableDueToMetaRobots === false && (
									<FormattedMessage {...messages.nonIndexableReasonsMetaRobots} />
								)}
								{isIndexableDueToXRobotsTag === false && (
									<FormattedMessage {...messages.nonIndexableReasonsXRobotsTag} />
								)}
								{pageType !== GraphQL.PageType.Page && (
									<FormattedMessage
										{...nonIndexableReasonsNotPageMessages[pageType]}
										values={{
											text__statusCode: statusCode,
										}}
									/>
								)}
							</TextualList>
						)}
					/>
				)}
			>
				<AttachedIcon
					ellipsis={false}
					icon={(
						<StatusDot status={StatusDotStatus.Warning} />
					)}
				>
					<FormattedMessage {...messages.contentDataIsIndexableValuesNo} />
				</AttachedIcon>
			</AttachedElement>
		);
	}

	return (
		<AttachedIcon
			ellipsis={false}
			icon={(
				<StatusDot status={StatusDotStatus.Warning} />
			)}
		>
			<FormattedMessage {...messages.contentDataIsIndexableValuesNo} />
		</AttachedIcon>
	);
}



function renderIsSecuredValue({ isHttps }) {
	if (isHttps === null) {
		return (
			<SquareSkeleton
				height={20}
				style={SquareSkeletonStyle.Light}
				width={40}
			/>
		);
	}

	return (
		<FormattedMessage {...messages[isHttps ? 'contentDataIsSecuredValuesYes' : 'contentDataIsSecuredValuesNo']} />
	);
}



function renderIsInSiteMap({ isInSitemap }) {
	if (isInSitemap === null) {
		return (
			<SquareSkeleton
				height={20}
				style={SquareSkeletonStyle.Light}
				width={40}
			/>
		);
	}

	return (
		<FormattedMessage {...messages[isInSitemap ? 'contentDataIsInSitemapValuesYes' : 'contentDataIsInSitemapValuesNo']} />
	);
}



function renderCanonicalValue(websiteId, pageUrl, linkCanonical) {
	if (linkCanonical === null) {
		return (
			<MissingValue />
		);
	} else if (pageUrl === linkCanonical.url) {
		return (
			<FormattedMessage {...messages.contentDataCanonicalValuesToSelf} />
		);
	} else if (!linkCanonical.isCrossdomain && linkCanonical.legacyUrlId !== null) {
		return (
			<InternalLink
				breakLines={true}
				routeName="website.pages.detail"
				routeParams={{
					websiteId,
					id: linkCanonical.legacyUrlId,
				}}
			>
				{linkCanonical.content}
			</InternalLink>
		);
	}

	return (
		<ExternalLink
			breakLines={true}
			href={linkCanonical.content}
		>
			{linkCanonical.content}
		</ExternalLink>
	);
}



function renderIsDisallowedInRobotsTxt({ isDisallowedInRobotsTxt }) {
	if (isDisallowedInRobotsTxt) {
		return (
			<DefinitionTerm
				description={(
					<FormattedMessage {...messages.contentDataIsDisallowedInRobotsTxtValuesYes} />
				)}
				term={(
					<FormattedMessage {...messages.contentDataIsDisallowedInRobotsTxtLabel} />
				)}
			/>
		);
	}

	return null;
}



function renderTimeServerResponse(pageType, timeServerResponse) {
	if (timeServerResponse !== null && pageType !== GraphQL.PageType.Unreachable) {
		return (
			<DefinitionTerm
				description={(
					<span>
						<FormattedNumber
							maximumFractionDigits={1}
							value={timeServerResponse}
						/>
						<span> ms</span>
					</span>
				)}
				term={(
					<ColumnName column={CK.PagesCommonColumn.TimeServerResponse} />
				)}
			/>
		);
	}

	return null;
}



function renderXmlSitemaps({
	referencedInSitemaps,
	websiteId,
}) {
	if (referencedInSitemaps === null || referencedInSitemaps.length === 0) {
		return null;
	}

	const links = referencedInSitemaps.map((sitemap) => (
		<InternalLink
			key={sitemap.sitemapUrl}
			routeName="website.platform.sitemaps"
			routeParams={{
				filter: createSitemapsListFilterParameter(
					SITEMAPS_LIST_DEFAULT_FILTER.set(
						'url',
						`equals:${sitemap.sitemapUrl}`,
					),
					null,
				),
				websiteId,
			}}
		>
			{sitemap.sitemapUrl}
		</InternalLink>
	));

	return (
		<DefinitionTerm
			description={links}
			showRowNumbers={false}
			term={(
				<FormattedMessage {...messages.xmlSitemap} />
			)}
			visibleRowsCount={4}
		/>
	);
}



const IndexabilityBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const pageDetailSourceMode = usePageDetailSourceMode();
	const pageType = usePageType(legacyUrlId, websiteId);
	const pageUrl = usePageUrl(legacyUrlId, websiteId);

	const {
		data: primaryData,
		loading: primaryLoading,
	} = usePageDetailPropertiesQuery(
		useIndexabilityBlockPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	const {
		data: srcData,
	} = usePageDetailPropertiesQuery(
		useIndexabilityBlockSrcQuery,
		legacyUrlId,
		websiteId,
		{
			skip: pageDetailSourceMode !== GraphQL.PageDetailSourceMode.Compare,
		},
	);

	const isDisallowedInRobotsTxt = primaryData?.lookupPageByLegacyId?.isDisallowedInRobotsTxt ?? null;
	const isHttps = primaryData?.lookupPageByLegacyId?.isHttps ?? null;
	const isIndexable = primaryData?.lookupPageByLegacyId?.pageTypeData?.isIndexable ?? null;
	const isIndexableDueToMetaRobots = primaryData?.lookupPageByLegacyId?.pageTypeData?.isIndexableDueToMetaRobots ?? null;
	const isIndexableDueToXRobotsTag = primaryData?.lookupPageByLegacyId?.pageTypeData?.isIndexableDueToXRobotsTag ?? null;
	const isInSitemap = primaryData?.lookupPageByLegacyId?.isInSitemap ?? null;
	const linkCanonical = primaryData?.lookupPageByLegacyId?.pageTypeData?.linkCanonical ?? null;
	const primaryPropertyData = primaryData?.lookupPageByLegacyId?.pageTypeData?.primaryProperties ?? null;
	const referencedInSitemaps = primaryData?.lookupPageByLegacyId?.referencedInSitemaps ?? null;
	const srcPropertyData = srcData?.lookupPageByLegacyId?.pageTypeData?.srcProperties ?? null;
	const statusCode = primaryData?.lookupPageByLegacyId?.statusCode ?? null;
	const timeServerResponse = primaryData?.lookupPageByLegacyId?.timeServerResponse ?? null;
	const xRobotsTag = primaryData?.lookupPageByLegacyId?.pageTypeData?.xRobotsTag ?? null;

	const isPage = pageType === null || pageType === GraphQL.PageType.Page;

	return (
		<BorderedBox
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.Indexability}
				/>
			)}
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			paddingSize={3}
		>
			<DefinitionTermsList>
				<SeparatedBlocks>
					<LabeledValue
						label={(
							<FormattedMessage {...messages.contentDataIsIndexableLabel} />
						)}
					>
						{renderIsIndexableValue({
							isDisallowedInRobotsTxt,
							isIndexable,
							isIndexableDueToMetaRobots,
							isIndexableDueToXRobotsTag,
							linkCanonical,
							loading: primaryLoading,
							pageType,
							pageUrl,
							statusCode,
						})}
					</LabeledValue>

					<LabeledValue
						label={(
							<FormattedMessage {...messages.contentDataIsSecuredLabel} />
						)}
					>
						{renderIsSecuredValue({ isHttps })}
					</LabeledValue>

					<LabeledValue
						label={(
							<FormattedMessage {...messages.contentDataIsInSitemapLabel} />
						)}
					>
						{renderIsInSiteMap({ isInSitemap })}
					</LabeledValue>
				</SeparatedBlocks>

				<DefinitionTerm
					description={pageUrl !== null && (
						<ExternalLink
							breakLines={true}
							href={pageUrl}
						>
							{pageUrl}
						</ExternalLink>
					)}
					term={(
						<FormattedMessage {...messages.contentDataUrlLabel} />
					)}
				/>

				{isPage && primaryLoading && (
					<DefinitionTerm
						description={(
							<DefinitionTermValueSkeleton />
						)}
						term={(
							<FormattedMessage {...messages.contentDataCanonicalLabel} />
						)}
					/>
				)}

				{isPage && !primaryLoading && (
					<DefinitionTerm
						description={renderCanonicalValue(websiteId, pageUrl, linkCanonical)}
						term={(
							<FormattedMessage {...messages.contentDataCanonicalLabel} />
						)}
					/>
				)}

				{isPage && compileDefinitionTermsNew(
					[
						{
							property: 'metaRobots',
							name: 'meta_robots',
							label: (
								<FormattedMessage {...messages.contentDataMetaRobotsLabel} />
							),
							alwaysVisible: true,
							codeHighlight: true,
						},
						{
							property: 'metaGooglebot',
							name: 'meta_googlebot',
							label: (
								<FormattedMessage {...messages.contentDataMetaGooglebotLabel} />
							),
							alwaysVisible: false,
							codeHighlight: true,
						},
						{
							property: 'metaBingbot',
							name: 'meta_bingbot',
							label: (
								<FormattedMessage {...messages.contentDataMetaBingbotLabel} />
							),
							alwaysVisible: false,
							codeHighlight: true,
						},
						{
							property: 'metaSlurp',
							name: 'meta_slurp',
							label: (
								<FormattedMessage {...messages.contentDataMetaSlurpLabel} />
							),
							alwaysVisible: false,
							codeHighlight: true,
						},
						{
							property: 'metaYandex',
							name: 'meta_yandex',
							label: (
								<FormattedMessage {...messages.contentDataMetaYandexLabel} />
							),
							alwaysVisible: false,
							codeHighlight: true,
						},
					],
					{
						compare: pageDetailSourceMode === GraphQL.PageDetailSourceMode.Compare,
						propertyData: primaryPropertyData,
						srcPropertyData,
					},
				)}

				{compileDefinitionTermsNew(
					[
						{
							property: 'xRobotsTag',
							name: 'x_robots_tag',
							label: (
								<FormattedMessage {...messages.contentDataXRobotsTagLabel} />
							),
							alwaysVisible: false,
							codeHighlight: true,
						},
					],
					{
						compare: false,
						propertyData: xRobotsTag ? {
							xRobotsTag,
						} : null,
						srcPropertyData: null,
					},
				)}

				{renderIsDisallowedInRobotsTxt({ isDisallowedInRobotsTxt })}

				{renderTimeServerResponse(pageType, timeServerResponse)}

				{renderXmlSitemaps({
					referencedInSitemaps,
					websiteId,
				})}
			</DefinitionTermsList>
		</BorderedBox>
	);
};



export default React.memo(IndexabilityBlock);
