import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';

import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import FeaturesInUseWarning from '~/components/app/SignupModal/FeaturesInUseWarning';
import MarginsList, {
	SIZE_LARGE as MARGINS_SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import ModalButtonsLayout from '~/components/patterns/modals/parts/ModalButtonsLayout';
import PaymentServicesList from '~/components/atoms/lists/PaymentServicesList';
import PlanName from '~/components/names/PlanName';
import TwinButtons, {
	TwinButtonsStyle,
} from '~/components/logic/signup/TwinButtons';

import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountSignup from '~/hooks/useAccountSignup';
import useSignupPrice from '~/hooks/useSignupPrice';



const messages = defineMessages({
	back: {
		id: 'ui.signup.featuresInUse.back',
	},
	chooseBetterPlanLabel: {
		id: 'ui.signup.featuresInUse.chooseBetterPlan.label',
	},
	chooseBetterPlanNote: {
		id: 'ui.signup.featuresInUse.chooseBetterPlan.note',
	},
	keepCurrentPlanLabel: {
		id: 'ui.signup.featuresInUse.keepCurrentPlan.label',
	},
	keepCurrentPlanNote: {
		id: 'ui.signup.featuresInUse.keepCurrentPlan.note',
	},
});



type Props = {
	accountId: CK.AccountId,
	onCancelCallback: () => void,
	onChooseBetterPlan: () => void,
	onKeepCurrentPlan: () => void,
};

const FeaturesInUseSignupModalStep: React.FC<Props> = (props) => {
	const {
		accountId,
		onCancelCallback,
		onChooseBetterPlan,
		onKeepCurrentPlan,
	} = props;

	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountSignup = useAccountSignup(accountId);

	const intl = useIntl();

	const {
		calculatePrice,
		currency: signupCurrency,
	} = useSignupPrice(accountId, false);

	const currentPlan = accountSignup.plan;

	if (
		accountFeaturesUsage === null
		|| currentPlan === null
	) {
		return null;
	}

	const neededPlan = accountFeaturesUsage.getNecessaryPlan();

	if (neededPlan === null) {
		return null;
	}

	const planAvailability = accountFeaturesUsage.getPlanAvailability(currentPlan);

	let extra = 0;

	if (calculatePrice !== null) {
		extra = calculatePrice({
			plan: neededPlan,
		}).priceWithDiscountsMonthly - calculatePrice({
			plan: currentPlan,
		}).priceWithDiscountsMonthly;
	}

	return (
		<MarginsList size={MARGINS_SIZE_LARGE}>
			<MarginsList size={MARGINS_SIZE_LARGE}>
				<FeaturesInUseWarning
					lostFeatures={planAvailability.conflictingFeatures}
					plan={neededPlan}
				/>

				<TwinButtons
					buttonALabel={(
						<FormattedMessage
							{...messages.chooseBetterPlanLabel}
							values={{
								plan: (
									<PlanName plan={neededPlan} />
								),
							}}
						/>
					)}
					buttonANote={(
						<Copy
							{...messages.chooseBetterPlanNote}
							values={{
								amount: intl.formatNumber(extra, {
									currency: signupCurrency,
									maximumFractionDigits: 2,
									style: 'currency',
								}),
							}}
						/>
					)}
					buttonAOnClick={() => {
						accountSignup.setProperty('plan', neededPlan);
						onChooseBetterPlan();
					}}
					buttonAStyle={TwinButtonsStyle.Action}
					buttonBLabel={(
						<FormattedMessage
							{...messages.keepCurrentPlanLabel}
							values={{
								plan: (
									<PlanName plan={currentPlan} />
								),
							}}
						/>
					)}
					buttonBNote={(
						<Copy
							{...messages.keepCurrentPlanNote}
							values={{
								plan: (
									<PlanName plan={currentPlan} />
								),
							}}
						/>
					)}
					buttonBOnClick={onKeepCurrentPlan}
					buttonBStyle={TwinButtonsStyle.Hollow}
				/>
			</MarginsList>

			<ModalButtonsLayout>
				<span />

				<Button
					onClick={onCancelCallback}
					style={ButtonStyle.Link}
				>
					<FormattedMessage {...messages.back} />
				</Button>

				<span />
			</ModalButtonsLayout>

			<PaymentServicesList />
		</MarginsList>
	);
};



export default FeaturesInUseSignupModalStep;
