import useRoyalMode from '~/hooks/useRoyalMode';

import {
	pendo,
} from '~/globals';



function usePendo(): Promise<any> {
	const royalMode = useRoyalMode();

	if (royalMode.isImpersonated !== false) {
		return Promise.reject(new Error('Pendo disabled'));
	}

	return pendo();
}



export default usePendo;
