import React from 'react';

import type GraphQL from '~/types/graphql';

import ChangeSummary, {
	PAGES_DIFF_BUNDLE,
} from '~/components/logic/pricing/ChangeSummary';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountWebsiteCount from '~/hooks/useAccountWebsiteCount';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useCalculatePrice from '~/hooks/useCalculatePrice';
import usePageBundle from '~/hooks/usePageBundle';



type Props = {
	accountId: number,
	additionalWebsitesPageCapacity?: number | null,
	billingCycle?: GraphQL.Term | null,
	extraWebsites?: number | null,
	hidePrice: boolean,
	pageBundle?: number | null,
	plan?: GraphQL.AccountPlan | null,
};

const SubscriptionChangeSummary: React.FC<Props> = (props) => {
	const {
		accountId,
		additionalWebsitesPageCapacity = null,
		billingCycle = null,
		extraWebsites,
		hidePrice,
		pageBundle = null,
		plan = null,
	} = props;

	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountCurrency = useAccountCurrency(accountId);
	const accountPageBundle = usePageBundle(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountWebsiteCount = useAccountWebsiteCount(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);
	const calculatePrice = useCalculatePrice(accountId);

	const isSame = (
		(billingCycle === null || (accountBillingCycle === billingCycle))
		&& (pageBundle === null || (accountPageBundle === pageBundle))
		&& (plan === null || (accountPlan === plan))
	);

	const priceOld = React.useMemo(
		() => !isSame ? calculatePrice() : null,
		[
			calculatePrice,
			isSame,
		],
	);

	const priceNew = React.useMemo(
		() => !isSame ? calculatePrice({
			billingCycle: billingCycle ?? accountBillingCycle,
			pageBundle: pageBundle ?? accountPageBundle,
			plan: plan ?? accountPlan,
		}) : null,
		[
			accountBillingCycle,
			accountPageBundle,
			accountPlan,
			billingCycle,
			calculatePrice,
			isSame,
			pageBundle,
			plan,
		],
	);

	if (
		accountBillingCycle === null
		|| accountCurrency === null
		|| accountPageBundle === null
		|| accountPlan === null
		|| accountWebsiteCount === null
		|| allowedBillingCycles === null
		|| !priceNew
		|| !priceOld
	) {
		return null;
	}

	return (
		<ChangeSummary
			accountId={accountId}
			additionalWebsitesPageCapacity={additionalWebsitesPageCapacity ?? undefined}
			allowedBillingCycles={allowedBillingCycles}
			billingCycleNew={billingCycle ?? accountBillingCycle}
			billingCycleOld={accountBillingCycle}
			currency={accountCurrency}
			hidePrice={hidePrice}
			pagesDiff={PAGES_DIFF_BUNDLE}
			pagesNew={pageBundle ?? accountPageBundle}
			pagesOld={accountPageBundle}
			planNew={plan ?? accountPlan}
			planOld={accountPlan}
			priceNew={priceNew.subtotal}
			priceOld={priceOld.subtotal}
			taxType={priceNew.taxType}
			websitesNew={accountWebsiteCount + (extraWebsites ?? 0)}
			websitesOld={accountWebsiteCount}
		/>
	);
};



export default SubscriptionChangeSummary;
