import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Emphasis from '~/components/patterns/typography/Emphasis';



const messages = defineMessages({
	label: {
		id: 'ui.contentOverview.webVitals.noData',
	},
});



type Props = {
	ellipsis?: boolean,
};

const WebVitalNoData: React.FC<Props> = (props) => {
	const {
		ellipsis,
	} = props;

	return (
		<Emphasis ellipsis={ellipsis}>
			<FormattedMessage {...messages.label} />
		</Emphasis>
	);
};



export default WebVitalNoData;
