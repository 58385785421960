import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import BarPagesChart from '../components/BarPagesChart';



class HealthChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		this.colors = {
			'0-100': '#FF5959',
			'100-200': '#FF7437',
			'200-300': '#FF7437',
			'300-400': '#FF7437',
			'400-500': '#FF7437',
			'500-600': '#FF9830',
			'600-700': '#FF9830',
			'700-800': '#FFB131',
			'800-900': '#FFB131',
			'900-1000': '#42CC67',
		};

		this.labels = {
			'0-100': '<100',
			'100-200': '<200',
			'200-300': '<300',
			'300-400': '<400',
			'400-500': '<500',
			'500-600': '<600',
			'600-700': '<700',
			'700-800': '<800',
			'800-900': '<900',
			'900-1000': '>900',
		};
	}



	render() {
		const {
			data,
		} = this.props;

		return (
			<BarPagesChart
				colors={this.colors}
				data={data}
				labels={this.labels}
				name="health"
			/>
		);
	}

}

HealthChart.propTypes = {
	data: PropTypes.instanceOf(List),
};



export default HealthChart;
