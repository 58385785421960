import React, {
	Component,
} from 'react';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';
import ThreeDSecureIframe from '../atoms/payments/ThreeDSecureIframe';

import {
	getRecurly,
} from '~/recurly/main';

import {
	actionTokenSelector,
	failAuthorization,
	finishAuthorization,
} from '~/state/paymentAuthorization';



const select = createSelector(
	actionTokenSelector,
	(actionToken) => {
		return {
			actionToken,
		};
	},
);



class AuthorizePaymentIframe extends Component {

	constructor(props, context) {
		super(props, context);

		this.threeDSecureRef = React.createRef();
	}



	componentDidMount() {
		const {
			actionToken,
			dispatch,
		} = this.props;

		getRecurly((recurly) => {
			const risk = recurly.Risk();
			const threeDSecure = risk.ThreeDSecure({
				actionTokenId: actionToken,
			});

			threeDSecure.on('error', () => {
				dispatch(
					failAuthorization(),
				);
			});

			threeDSecure.on('token', (token) => {
				dispatch(
					finishAuthorization(token.id),
				);
			});

			threeDSecure.attach(
				this.threeDSecureRef.current,
			);
		});
	}



	shouldComponentUpdate() {
		return false;
	}



	render() {
		return (
			<ThreeDSecureIframe
				preloader={(
					<LoadingDots size={LoadingDotsSize.Large} />
				)}
				ref={this.threeDSecureRef}
			/>
		);
	}

}



export default connect(select)(AuthorizePaymentIframe);
