import classNames from 'classnames';
import React from 'react';



type Props = {
	actionElements?: React.ReactNode,
	icon?: React.ReactNode,
	/** The main box label */
	label: React.ReactNode,
	/** Additional tag attached to label */
	labelTag?: React.ReactNode,
	/** Sub-label visible under main box label */
	sublabel?: React.ReactNode,
};

const BorderedBoxHeader: React.FC<Props> = (props) => {
	const {
		actionElements,
		icon,
		label,
		labelTag,
		sublabel,
	} = props;

	const labelTextClasses = classNames({
		'bordered-box-header__label-text': true,
		'bordered-box-header__label-text--with-sublabel': sublabel,
	});

	return (
		<div className="bordered-box-header">
			<div className="bordered-box-header__label-container-with-icon">
				{icon && (
					<div className="bordered-box-header__icon">
						{icon}
					</div>
				)}
				<div className={labelTextClasses}>
					<div className="bordered-box-header__labels-group">
						<span className="bordered-box-header__label">
							{label}
						</span>
						{sublabel && (
							<span className="bordered-box-header__sublabel">{sublabel}</span>
						)}
					</div>
					{labelTag && (
						<div className="bordered-box-header__label-tag">
							{labelTag}
						</div>
					)}
				</div>
			</div>
			{actionElements && (
				<div className="bordered-box-header__cta">
					{actionElements}
				</div>
			)}
		</div>
	);
};



export default BorderedBoxHeader;
