import classNames from 'classnames';
import React from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	Link,
} from 'react-router5';

import type CK from '~/types/contentking';

import useUrlState from '~/hooks/useUrlState';

import {
	appEmptyStateSelector,
	selectedSectionSelector,
} from '~/state/ui/selectors';

import {
	EMPTY_STATE_NO_WEBSITES,
} from '~/model/ui/appEmpty';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type ChildProps = {
	isActive: boolean,
	isDimmed: boolean,
};

type Props = {
	children: RenderProp<ChildProps>,
	className?: string,
	onClick?: () => void,
	websiteId: CK.WebsiteId | null,
};

const WebsiteSettingsLink: React.FC<Props> = (props) => {
	const {
		children,
		className,
		onClick,
		websiteId,
	} = props;

	const appEmptyState = useSelector(appEmptyStateSelector);
	const selectedSection = useSelector(selectedSectionSelector);
	const urlState = useUrlState();

	const isActive = selectedSection === 'website.detail';

	if (websiteId === null || appEmptyState === EMPTY_STATE_NO_WEBSITES) {
		return (
			<Link routeName="home">
				{renderProp(
					children,
					{
						isActive: false,
						isDimmed: true,
					},
				)}
			</Link>
		);
	}

	const finalClassName = classNames({
		'active': isActive,
	}, className);

	return (
		<Link
			className={finalClassName}
			onClick={onClick}
			routeName={urlState.name.startsWith('website.detail') ? urlState.name : 'website.detail'}
			routeParams={{
				websiteId,
			}}
		>
			{renderProp(
				children,
				{
					isActive,
					isDimmed: false,
				},
			)}
		</Link>
	);
};



export default WebsiteSettingsLink;
