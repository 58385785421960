import {
	GOOGLE_API_CLIENT_ID,
} from '~/config';



function authenticateWithScopes({ scopes }) {
	return new Promise((resolve, reject) => {
		const client = window.google.accounts.oauth2.initCodeClient({
			client_id: GOOGLE_API_CLIENT_ID,
			scope: scopes.join(' '),
			ux_mode: 'popup',
			callback: (response) => {
				if (response.error) {
					reject(response);
				} else {
					resolve({
						code: response.code,
					});
				}
			},
		});

		client.requestCode();
	});
}



export function authenticateGoogleAnalytics() {
	return authenticateWithScopes({
		scopes: [
			'https://www.googleapis.com/auth/analytics.readonly',
			'https://www.googleapis.com/auth/userinfo.email',
			'openid',
		],
	});
}



export function authenticateGoogleSearchConsole() {
	return authenticateWithScopes({
		scopes: [
			'https://www.googleapis.com/auth/webmasters.readonly',
			'https://www.googleapis.com/auth/userinfo.email',
			'openid',
		],
	});
}
