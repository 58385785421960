import React from 'react';

import SegmentSelectField, {
	type SegmentSelectFieldRef,
	SegmentSelectFieldSize,
} from '~/components/atoms/forms/components/SegmentSelectField';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';



type Props = {
	name: string,
	width: number,
};

const SegmentFieldFilter = React.forwardRef<SegmentSelectFieldRef, Props>((props, ref) => {
	const {
		name,
		width,
	} = props;

	const websiteId = useWebsiteId();

	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	return (
		<SegmentSelectField
			dropdownWidth={Math.max(280, width)}
			name={name}
			ref={ref}
			segments={segmentDefinitions.listAll()}
			size={SegmentSelectFieldSize.Small}
			width={width}
		/>
	);
});



export default React.memo(SegmentFieldFilter);
