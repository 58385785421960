import React from 'react';

import ConductorLogo from '~/components/patterns/images/ConductorLogo';
import LinkContextProvider, {
	ExternalLinkStyle,
	InternalLinkStyle,
} from '~/components/patterns/links/LinkContextProvider';
import RichText from '~/components/patterns/typography/RichText';



type Props = {
	/** Additional info */
	additionalInfo?: React.ReactNode,
	/** Attached call-to-action button */
	ctaButton?: React.ReactNode,
	/** Note attached to call-to-action element */
	ctaNote?: React.ReactNode,
	/** Attached form element */
	form?: React.ReactNode,
	/** Description of expiration */
	children: React.ReactNode,
};



const ExpirationTour: React.FC<Props> = (props) => {
	const {
		additionalInfo,
		ctaButton,
		ctaNote,
		form,
		children,
	} = props;

	return (
		<div className="expiration-tour">
			<div className="expiration-tour__logo">
				<ConductorLogo />
			</div>

			<div className="expiration-tour__content">
				<div className="expiration-tour__text">
					<RichText>
						{children}
					</RichText>
				</div>

				<div className="expiration-tour__action">
					{ctaButton}

					{ctaNote && (
						<div className="expiration-tour__action-note">
							<LinkContextProvider
								externalLinkStyle={ExternalLinkStyle.Decent}
								internalLinkStyle={InternalLinkStyle.Decent}
							>
								{ctaNote}
							</LinkContextProvider>
						</div>
					)}
				</div>
			</div>

			{form && (
				<div className="expiration-tour__form">
					{form}
				</div>
			)}

			{additionalInfo && (
				<div className="expiration-tour__additional-info">
					<LinkContextProvider
						externalLinkStyle={ExternalLinkStyle.Decent}
						internalLinkStyle={InternalLinkStyle.Decent}
					>
						<RichText>
							{additionalInfo}
						</RichText>
					</LinkContextProvider>
				</div>
			)}
		</div>
	);
};



export default ExpirationTour;
