import React from 'react';

import {
	useRoyalModeQuery,
} from './useRoyalMode.gql';



type Output = {
	email: string | null,
	firstName: string | null,
	isActive: boolean,
	isImpersonated: boolean | null,
	isReady: boolean,
};



function useRoyalMode(): Output {
	const { data } = useRoyalModeQuery();

	const kingdomAdmin = data?.authenticatedSession?.kingdomAdmin ?? null;

	const isAdmin = kingdomAdmin !== null;
	const isImpersonated = data?.authenticatedSession?.isImpersonated ?? null;
	const isReady = data !== undefined;
	const email = kingdomAdmin?.email ?? null;
	const firstName = kingdomAdmin?.firstName ?? null;

	return React.useMemo(
		() => ({
			email,
			firstName,
			isActive: isAdmin,
			isImpersonated,
			isReady,
		}),
		[
			email,
			firstName,
			isAdmin,
			isImpersonated,
			isReady,
		],
	);
}



export default useRoyalMode;
