import {
	isSameDay,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import HealthLabel from '~/components/patterns/structuredValues/HealthLabel';
import NoDataMessage from './NoDataMessage';
import NumberWithBar from '~/components/patterns/charts/structures/NumberWithBar';
import ScoreBar, {
	ScoreBarSize,
} from '~/components/patterns/scores/components/ScoreBar';
import ScoreNumber, {
	ScoreNumberSize,
	ScoreNumberStyle,
} from '~/components/patterns/scores/components/ScoreNumber';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';

import {
	useHealthWidgetQuery,
} from './HealthWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useNavigateToHistoricalIssues from '~/hooks/useNavigateToHistoricalIssues';
import useNavigateToIssues from '~/hooks/useNavigateToIssues';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateForQuery,
} from '~/model/dashboard';



const messages = defineMessages({
	primaryActionLinkChanges: {
		id: 'ui.dashboardHealthWidget.primaryActionLink.changes',
	},
	primaryActionLinkCurrent: {
		id: 'ui.dashboardHealthWidget.primaryActionLink.current',
	},
	title: {
		id: 'ui.dashboardHealthWidget.title',
	},
});



const HealthWidget: React.FC = () => {
	const dateRange = useDashboardDateRange();
	const navigateToHistoricalIssues = useNavigateToHistoricalIssues();
	const navigateToIssues = useNavigateToIssues();
	const scope = useDashboardScopeSection();
	const websiteId = useWebsiteId();

	const {
		data: metricData,
		loading,
		reasonForNoData,
	} = useDashboardQuery('health', useHealthWidgetQuery, {
		variables: {
			scope,
			websiteId,
			...formatDateForQuery(dateRange),
		},
	});

	const isCurrent = isSameDay(
		Date.now(),
		dateRange.end,
	);

	const primaryActionLinkRoute = isCurrent
		? navigateToIssues({
			scope,
		})
		: navigateToHistoricalIssues({
			endDate: dateRange.end,
			scope,
			startDate: dateRange.start,
		});

	return (
		<WidgetCell type={WidgetType.Health}>
			<Widget
				label={(
					<HealthLabel>
						<FormattedMessage {...messages.title} />
					</HealthLabel>
				)}
				loading={loading}
				overlay={metricData === null && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages[isCurrent ? 'primaryActionLinkCurrent' : 'primaryActionLinkChanges']} />
					),
					...primaryActionLinkRoute,
				}}
				showEndDate={true}
			>
				{metricData !== null && (
					<NumberWithBar
						barChart={(
							<ScoreBar
								maximum={1000}
								size={ScoreBarSize.Large}
								value={metricData.health}
							/>
						)}
						number={(
							<ScoreNumber
								maximum={1000}
								size={ScoreNumberSize.Large}
								style={ScoreNumberStyle.Colored}
								value={metricData.health}
							/>
						)}
					/>
				)}
			</Widget>
		</WidgetCell>
	);
};



export default HealthWidget;
