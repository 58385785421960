import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import {
	CHANGE_TYPE_ADDED,
	CHANGE_TYPE_CHANGED,
	CHANGE_TYPE_OTHER,
	CHANGE_TYPE_REDIRECTED,
	CHANGE_TYPE_REMOVED,
} from '~/model/historicalChanges';



const messages = defineMessages({
	[CHANGE_TYPE_ADDED]: {
		id: 'ui.contentOverview.changeTracking.changeType.added',
	},
	[CHANGE_TYPE_CHANGED]: {
		id: 'ui.contentOverview.changeTracking.changeType.changed',
	},
	[CHANGE_TYPE_OTHER]: {
		id: 'ui.contentOverview.changeTracking.changeType.other',
	},
	[CHANGE_TYPE_REDIRECTED]: {
		id: 'ui.contentOverview.changeTracking.changeType.redirected',
	},
	[CHANGE_TYPE_REMOVED]: {
		id: 'ui.contentOverview.changeTracking.changeType.removed',
	},
});



function usePageChangeTypeTitles(): object {
	const intl = useIntl();

	return React.useMemo(
		() => {
			const result = {};

			[
				CHANGE_TYPE_ADDED,
				CHANGE_TYPE_CHANGED,
				CHANGE_TYPE_OTHER,
				CHANGE_TYPE_REDIRECTED,
				CHANGE_TYPE_REMOVED,
			].forEach((changeType) => {
				result[changeType] = intl.formatMessage(messages[changeType]);
			});

			return result;
		},
		[
			intl,
		],
	);
}



export default usePageChangeTypeTitles;
