import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PrioritySupportIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M12.3876 23.5068H2.47563C2.08656 23.5068 1.71323 23.3532 1.43687 23.0793C1.16052 22.8055 1.00351 22.4335 1 22.0445V7.68701"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M23.8311 7.67065V13.9746"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M3.55645 6.0332L1.01465 7.64709"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M16.9801 3.34786L13.1674 0.904435C12.935 0.753609 12.664 0.67334 12.387 0.67334C12.11 0.67334 11.839 0.753609 11.6067 0.904435L7.74609 3.34786"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M23.8201 7.64709L21.2783 6.0332"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M12.389 19.4467C12.1115 19.4467 11.84 19.3664 11.6073 19.2153L1.01465 12.4727"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M23.8209 12.4727L21.4492 13.9749"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M3.88184 11.7203V4.8925C3.88324 4.53048 4.02767 4.18369 4.28366 3.9277C4.53964 3.67171 4.88644 3.52728 5.24845 3.52588H19.5846C19.9467 3.52728 20.2935 3.67171 20.5494 3.9277C20.8054 4.18369 20.9499 4.53048 20.9513 4.8925V11.7203"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M13.0838 14.1954C11.9923 14.2356 10.9168 13.9243 10.0155 13.3074C8.47345 12.1667 8.19428 9.90145 9.07168 8.25566C9.48131 7.52253 10.1165 6.94107 10.8829 6.59764C11.6492 6.2542 12.5059 6.16712 13.3257 6.34931C14.0941 6.5086 14.7878 6.91855 15.2979 7.5148C15.808 8.11104 16.1056 8.85981 16.1441 9.64355V9.78712C16.2026 13.1691 13.5119 11.978 13.5119 11.0873V8.1626"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M13.2301 8.38344C12.7468 8.23121 12.2254 8.2538 11.7571 8.44725C10.8425 8.87 10.4783 10.008 10.7282 10.8933C10.7917 11.1258 10.9111 11.3392 11.0761 11.5149C11.2411 11.6905 11.4467 11.8231 11.6747 11.901C12.6239 12.1908 13.161 11.6697 13.4348 11.0236"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M29.1627 27.6277C29.3144 27.6273 29.4644 27.5965 29.6041 27.5373C29.746 27.4755 29.8736 27.385 29.9789 27.2715C30.0877 27.1655 30.1729 27.0377 30.2289 26.8966C30.287 26.7558 30.3168 26.6049 30.3166 26.4526V16.8809C30.317 16.7286 30.2872 16.5777 30.2291 16.4369C30.171 16.2961 30.0856 16.1681 29.9779 16.0604C29.8702 15.9527 29.7422 15.8673 29.6014 15.8092C29.4606 15.7511 29.3097 15.7213 29.1574 15.7217H15.9113C15.6059 15.7252 15.3142 15.8488 15.0992 16.0657C14.8843 16.2827 14.7634 16.5755 14.7627 16.8809V30.7066C14.7626 30.765 14.7792 30.8222 14.8106 30.8715C14.8427 30.9195 14.8885 30.9568 14.9421 30.9786C14.9956 31.0004 15.0544 31.0057 15.111 30.9938C15.1683 30.9834 15.2206 30.9544 15.2599 30.9113L18.5462 27.6277H29.1627Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M18.0879 18.854H26.9948"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M18.1348 21.5125H26.8609"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M18.1348 24.1074H26.8609"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default PrioritySupportIconBuilder;

