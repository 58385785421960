import React from 'react';

import type CK from '~/types/contentking';

import {
	useAccountActiveTrialQuery,
} from './useAccountActiveTrial.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountActiveTrial(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountActiveTrialQuery,
		accountId,
	);

	const accountData = data?.account ?? null;

	return React.useMemo(
		() => {
			if (
				accountData === null
				|| accountData.trialExpirationDate === null
			) {
				return null;
			}

			return {
				expirationDate: accountData.trialExpirationDate,
				isTrialBarDisplayed: accountData.isTrialBarDisplayed,
			};
		},
		[
			accountData,
		],
	);
}



export default useAccountActiveTrial;
