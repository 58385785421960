import useColumnSets from '~/hooks/useColumnSets';
import useCurrentColumnSetId from '~/hooks/useCurrentColumnSetId';



function useCurrentColumnSet() {
	const columnSets = useColumnSets();
	const currentColumnSetId = useCurrentColumnSetId();

	if (
		columnSets.columnSets.length === 0
		|| currentColumnSetId === null
	) {
		return null;
	}

	return columnSets.columnSets.find(
		(columnSet) => columnSet.id === currentColumnSetId,
	) ?? null;
}



export default useCurrentColumnSet;
