import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AnnouncementPopup from '~/components/patterns/messages/popup/AnnouncementPopup';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '../Copy';
import InsufficientPermissionMessage from '~/components/app/InsufficientPermissionMessage';

import {
	navigate,
} from '~/routing/router';



const messages = defineMessages({
	insufficientPermissionsForKingdomAdmin: {
		id: 'ui.general.notAllowedBecauseInsufficientRoyaltyLevel',
	},
});

const messagesMaximumInvitationsReached = defineMessages({
	button: {
		id: 'ui.members.new.overlay.maximumInvitationsReached.button',
	},
	description: {
		id: 'ui.members.new.overlay.maximumInvitationsReached.description',
	},
	title: {
		id: 'ui.members.new.overlay.maximumInvitationsReached.title',
	},
});



export const DisabledOverlayDueToInsufficientPermissions: React.FC = () => (
	<AnnouncementPopup
		title={(
			<InsufficientPermissionMessage />
		)}
	/>
);



export const DisabledOverlayDueToInsufficientPermissionsForKingdomAdmin: React.FC = () => (
	<AnnouncementPopup
		title={(
			<FormattedMessage {...messages.insufficientPermissionsForKingdomAdmin} />
		)}
	/>
);



type MaximumInvitationsReachedProps = {
	accountId: CK.AccountId,
	maximumInvitations: number,
};

export const DisabledOverlayDueToMaximumInvitationsReached: React.FC<MaximumInvitationsReachedProps> = (props) => {
	const {
		accountId,
		maximumInvitations,
	} = props;

	return (
		<AnnouncementPopup
			ctaElement={(
				<Button
					onClick={() => {
						navigate('account.members', {
							accountId,
						});
					}}
					style={ButtonStyle.Action}
				>
					<FormattedMessage {...messagesMaximumInvitationsReached.button} />
				</Button>
			)}
			title={(
				<FormattedMessage {...messagesMaximumInvitationsReached.title} />
			)}
		>
			<Copy
				{...messagesMaximumInvitationsReached.description}
				values={{
					maximumInvitations,
				}}
			/>
		</AnnouncementPopup>
	);
};
