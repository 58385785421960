import classNames from 'classnames';
import React from 'react';
import {
	Link,
} from 'react-router5';



export enum TabNavigationItemFloat {
	Left = 'left',
	Right = 'right',
}

export enum TabNavigationItemStyle {
	Default = 'default',
	Light = 'light',
}

type Props = {
	/** Specify how this element should float */
	float?: TabNavigationItemFloat,
	children: React.ReactNode,
	/** Icon can be prepended to label */
	icon?: React.ReactNode,
	/** Active style of menu item */
	isActive?: boolean,
	/** Highlight menu item that it's not ready */
	isNotReady?: boolean,
	linkRouteName?: string,
	linkRouteParams?: object,
	onClickCallback?: (e: Event) => void,
	/** Color style of component */
	style?: TabNavigationItemStyle,
	/** Icon can be attached to label */
	suffixIcon?: React.ReactNode,
	/** Make label uppercased */
	uppercase?: boolean,
};



const TabNavigationItem: React.FC<Props> = (props) => {
	const {
		float = TabNavigationItemFloat.Left,
		children,
		icon,
		isActive,
		isNotReady,
		linkRouteName,
		linkRouteParams,
		onClickCallback,
		style = TabNavigationItemStyle.Default,
		suffixIcon,
		uppercase = false,
	} = props;

	const _handleLinkClick = (e) => {
		if (!linkRouteName) {
			e.preventDefault();
		}

		if (!onClickCallback) {
			return false;
		}

		onClickCallback(e);
	};

	const _renderRouterLink = () => {
		return (
			<Link
				onClick={_handleLinkClick}
				routeName={linkRouteName}
				routeParams={linkRouteParams}
			>
				{icon && (
					<span className="tab-nav-item__icon">
						{icon}
					</span>
				)}
				<span className="tab-nav-item__label">
					{children}
				</span>
				{suffixIcon && (
					<span className="tab-nav-item__icon">
						{suffixIcon}
					</span>
				)}
			</Link>
		);
	};

	const _renderHtmlLink = () => {
		return (
			<a
				href="#"
				onClick={_handleLinkClick}
			>
				{icon && (
					<span className="tab-nav-item__icon">
						{icon}
					</span>
				)}
				<span className="tab-nav-item__label">
					{children}
				</span>
				{suffixIcon && (
					<span className="tab-nav-item__icon">
						{suffixIcon}
					</span>
				)}
			</a>
		);
	};

	const componentClasses = classNames({
		'tab-nav-item': true,
		'tab-nav-item--active': isActive,
		'tab-nav-item--not-ready': isNotReady,
		'tab-nav-item--ready-and-not-active': !isActive && !isNotReady,
		'tab-nav-item--light-style': style === TabNavigationItemStyle.Light,
		'tab-nav-item--uppercase': uppercase,
		['tab-nav-item--float-' + float]: true,
	});

	return (
		<div className={componentClasses}>
			{linkRouteName ? _renderRouterLink() : _renderHtmlLink()}
		</div>
	);
};



export default TabNavigationItem;
