import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import {
	useAuthenticatedSessionQuery,
} from './useAuthenticatedSession.gql';

import useHandleSuccessfulLogin from '~/hooks/useHandleSuccessfulLogin';
import usePollInterval from '~/hooks/usePollInterval';
import usePrevious from '~/hooks/usePrevious';

import {
	LOGOUT_SUCCESSFUL,
	MAKE_AUTHENTICATED,
} from '~/actions';

import {
	IsConductorClient,
} from '~/config';

import {
	redirectToConductorLogin,
} from '~/model/conductor';



type Options = {
	allowStart?: boolean,
};



function useAuthenticatedSession(options: Options = {}) {
	const {
		allowStart = false,
	} = options;

	const dispatch = useDispatch();
	const handleSuccessfulLogin = useHandleSuccessfulLogin();

	const { data } = useAuthenticatedSessionQuery({
		pollInterval: usePollInterval(30_000),
	});

	const isAuthenticated = data
		? (data.authenticatedSession ?? null) !== null
		: null;

	const previousIsAuthenticated = usePrevious(isAuthenticated) ?? null;

	React.useEffect(
		() => {
			const actOnEnd = (
				previousIsAuthenticated
				|| previousIsAuthenticated === null
			);

			if (isAuthenticated === false && actOnEnd) {
				dispatch({
					type: LOGOUT_SUCCESSFUL,
				});

				if (IsConductorClient) {
					redirectToConductorLogin();
				}
			}

			const actOnStart = allowStart && (
				previousIsAuthenticated === false
				|| previousIsAuthenticated === null
			);

			if (isAuthenticated && actOnStart) {
				dispatch({
					type: MAKE_AUTHENTICATED,
				});

				handleSuccessfulLogin();
			}
		},
		[
			allowStart,
			dispatch,
			handleSuccessfulLogin,
			isAuthenticated,
			previousIsAuthenticated,
		],
	);
}



export default useAuthenticatedSession;
