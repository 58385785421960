import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ContactIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M12.643,11.341a1.689,1.689,0,1,0,1.7,1.689A1.7,1.7,0,0,0,12.643,11.341Z"
				fill={color}
			/>
			<path
				d="M16.53,2.178a.5.5,0,0,1-.289-.293A2.892,2.892,0,0,0,13.532,0H11.754A2.892,2.892,0,0,0,9,2.013a.5.5,0,0,1-.286.311A8.212,8.212,0,0,0,3.3,9.6a2.013,2.013,0,0,1-.215.832c-.147.289-.347.638-.512.928-.7,1.235-1.5,2.633-1.423,3.75l0,.137a1.223,1.223,0,0,0,.189.742,1.247,1.247,0,0,0,.965.521c.173.007.364.01.551.01a.5.5,0,0,1,.5.5V19a2.5,2.5,0,0,0,2.5,2.5h1.5a.5.5,0,0,1,.5.5v1.5a.5.5,0,0,0,.5.5h10a.5.5,0,0,0,.5-.5V19.792a.5.5,0,0,1,.22-.415c2.509-1.69,3.78-4.314,3.78-7.814A9.939,9.939,0,0,0,16.53,2.178ZM12.978,16.987a1,1,0,0,0-.559.229,10.559,10.559,0,0,1-3.8,2.1.5.5,0,0,0-.265.181,1.249,1.249,0,0,1-1,.5h-.5a1.25,1.25,0,0,1,0-2.5h.5a1.236,1.236,0,0,1,.706.22.5.5,0,0,0,.441.063,8.48,8.48,0,0,0,1.995-.99.25.25,0,0,0,0-.419,3.94,3.94,0,0,1,.131-6.751.5.5,0,0,0,.247-.431V2.883A.886.886,0,0,1,11.754,2h1.778a.886.886,0,0,1,.889.882v6.31a.5.5,0,0,0,.248.431,3.952,3.952,0,0,1-1.691,7.363Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ContactIconBuilder;
