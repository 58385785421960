import React from 'react';

import PageTypeName from '~/components/names/PageTypeName';

import {
	type PageType,
} from '~/model/pagesNew';



type Props = {
	value: PageType,
};

const PageTypeFormatter: React.FC<Props> = (props) => {
	const {
		value,
	} = props;

	return (
		<PageTypeName pageType={value} />
	);
};



export default React.memo(PageTypeFormatter);
