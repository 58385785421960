import React from 'react';



type Props = {
	children: React.ReactNode,
	description?: React.ReactNode,
};

const StandaloneFieldRow: React.FC<Props> = (props) => {
	const {
		children,
		description,
	} = props;

	return (
		<div className="standalone-field-row">
			<div className="standalone-field-row__field">
				{children}

				{description && (
					<div className="standalone-field-row__description">
						{description}
					</div>
				)}
			</div>
		</div>
	);
};



export default StandaloneFieldRow;
