import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import WithSeparator from '~/components/atoms/WithSeparator';



const messages = defineMessages({
	level: {
		id: 'ui.headingLevelsSkipped.level',
	},
	h1: {
		id: 'ui.contentData.h1',
	},
	h2: {
		id: 'ui.contentData.h2',
	},
	h3: {
		id: 'ui.contentData.h3',
	},
	h4: {
		id: 'ui.contentData.h4',
	},
	h5: {
		id: 'ui.contentData.h5',
	},
	h6: {
		id: 'ui.contentData.h6',
	},
});



type Props = {
	issueContext: {
		levels: ReadonlyArray<{
			count: number,
			property: string,
		}>,
	},
};

const HeadingLevelsSkipped: React.FC<Props> = (props) => {
	const {
		issueContext,
	} = props;

	return (
		<BorderedBox reversedColors={true}>
			<WithSeparator separator=", ">
				{issueContext.levels.map((level) => (
					<FormattedMessage
						{...messages.level}
						key={level.property}
						values={{
							count: level.count,
							level: (
								<FormattedMessage {...messages[level.property]} />
							),
						}}
					/>
				))}
			</WithSeparator>
		</BorderedBox>
	);
};



export default HeadingLevelsSkipped;
