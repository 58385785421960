export const CATEGORY_ANALYTICS = 'analytics';
export const CATEGORY_VISUAL_ANALYTICS = 'visual_analytics';
export const CATEGORY_TAG_MANAGERS = 'tag_managers';

export const TYPE_ADOBE_ANALYTICS = 'adobe_analytics';
export const TYPE_GOOGLE_ANALYTICS = 'google_analytics';

export const TYPE_CLICKTALE = 'clicktale';
export const TYPE_CLICKY = 'clicky';
export const TYPE_CONTENTSQUARE = 'contentsquare';
export const TYPE_CRAZY_EGG = 'crazy_egg';
export const TYPE_HOTJAR = 'hotjar';
export const TYPE_INSPECTLET = 'inspectlet';
export const TYPE_MICROSOFT_CLARITY = 'microsoft_clarity';
export const TYPE_MOUSEFLOW = 'mouseflow';
export const TYPE_SMARTLOOK = 'smartlook';

export const TYPE_ADOBE_TAG_MANAGER = 'adobe_tag_manager';
export const TYPE_GOOGLE_TAG_MANAGER = 'google_tag_manager';
export const TYPE_SEGMENT_COM_TAG_MANAGER = 'segment_com_tag_manager';

export const LIST_OF_ANALYTICS_SERVICES = [
	TYPE_ADOBE_ANALYTICS,
	TYPE_GOOGLE_ANALYTICS,
];

export const LIST_OF_TAG_MANAGERS = [
	TYPE_ADOBE_TAG_MANAGER,
	TYPE_GOOGLE_TAG_MANAGER,
	TYPE_SEGMENT_COM_TAG_MANAGER,
];

export const LIST_OF_VISUAL_ANALYTICS_SERVICES = [
	TYPE_CLICKTALE,
	TYPE_CLICKY,
	TYPE_CONTENTSQUARE,
	TYPE_CRAZY_EGG,
	TYPE_HOTJAR,
	TYPE_INSPECTLET,
	TYPE_MICROSOFT_CLARITY,
	TYPE_MOUSEFLOW,
	TYPE_SMARTLOOK,
];

export const LIST_OF_ALL_SERVICES = [
	...LIST_OF_ANALYTICS_SERVICES,
	...LIST_OF_VISUAL_ANALYTICS_SERVICES,
	...LIST_OF_TAG_MANAGERS,
];
