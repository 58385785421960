import React from 'react';

import styles from './ConductorLogo.module.scss';



const ConductorLogo: React.FC = () => {
	return (
		<div className={styles.component} />
	);
};



export default ConductorLogo;
