/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupMutationVariables = GraphQL.Exact<{
  acceptedLegalDocuments: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  accountId: GraphQL.Scalars['AccountId']['input'];
  billingCycle: GraphQL.Term;
  currency: GraphQL.Currency;
  pageBundle: GraphQL.Scalars['Int']['input'];
  plan: GraphQL.AccountPlan;
}>;


export type SignupMutation = { readonly __typename?: 'Mutation', readonly Signup: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly billingCycle: GraphQL.Term, readonly billingEntity: GraphQL.SubscriptionsSellerCode, readonly canSignup: boolean, readonly currency: GraphQL.Currency | null, readonly hasBillingHistory: boolean, readonly isTrialBarDisplayed: boolean, readonly pageBundle: number, readonly phase: GraphQL.AccountPhase, readonly plan: GraphQL.AccountPlan | null, readonly state: GraphQL.AccountState, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageCapacity: number }> } | null } } };


export const SignupDocument = gql`
    mutation Signup($acceptedLegalDocuments: [String!]!, $accountId: AccountId!, $billingCycle: Term!, $currency: Currency!, $pageBundle: Int!, $plan: AccountPlan!) {
  Signup(
    acceptedLegalDocuments: $acceptedLegalDocuments
    accountId: $accountId
    billingCycle: $billingCycle
    currency: $currency
    pageBundle: $pageBundle
    plan: $plan
  ) {
    query {
      account(id: $accountId) {
        id
        billingCycle
        billingEntity
        canSignup
        currency
        features {
          feature
          id
        }
        hasBillingHistory
        isTrialBarDisplayed
        pageBundle
        phase
        plan
        state
        websites {
          id
          pageCapacity
        }
      }
    }
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      acceptedLegalDocuments: // value for 'acceptedLegalDocuments'
 *      accountId: // value for 'accountId'
 *      billingCycle: // value for 'billingCycle'
 *      currency: // value for 'currency'
 *      pageBundle: // value for 'pageBundle'
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;