import fetch from 'isomorphic-fetch';

import {
	APP_VERSION,
	CONTENTKING_API_URL,
} from '~/config';
import RecurlyErrorHandler from '~/recurly/ErrorHandler';
import {
	AccountSwitchAvailableException,
	InvalidInputException,
	NotFoundException,
	ServerErrorException,
	TimeoutException,
	UnauthorizedException,
	ValidationException,
} from './exceptions';



type QueryParameters = Record<string, string | number | boolean | undefined>;

type RequestOptions = {
	serverErrorHandler?: (path: any, response: any, json: any) => ServerErrorException,
	timeout?: number,
	timeoutHandler?: (any) => TimeoutException,
};



const DEFAULT_REQUEST_OPTIONS = {
	serverErrorHandler: (path, response, json) => {
		return new ServerErrorException(path, response, json);
	},
	timeout: 10000,
	timeoutHandler: ({ path, timeout }) => {
		return new TimeoutException(path, timeout);
	},
};



function prefixPath(path: string): string {
	return CONTENTKING_API_URL + path;
}



function appendQueryString(path: string, queryString?: string): string {
	return queryString ? path + '?' + queryString : path;
}



function buildQueryString(data: QueryParameters): string {
	const parameters: Array<string> = [];

	for (const [key, value] of Object.entries(data)) {
		if (value !== undefined) {
			parameters.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
		}
	}

	return parameters.join('&');
}



const timeoutEnhancedFetch = (
	endpoint: string,
	data: unknown,
	options: RequestOptions,
): Promise<Response> => {
	const requestOptions = Object.assign({}, DEFAULT_REQUEST_OPTIONS, options);

	return new Promise((resolve, reject) => {
		let timeout;
		if (requestOptions.timeout > 0) {
			timeout = setTimeout(() => {
				reject(requestOptions.timeoutHandler({
					path: endpoint,
					timeout: requestOptions.timeout,
				}));
			}, requestOptions.timeout);
		}

		fetch(endpoint, data)
			.then((response) => {
				clearTimeout(timeout);
				resolve(response);
			})
			.catch((e) => {
				clearTimeout(timeout);
				reject(e);
			});
	});
};



function validateStatus(path, response, serverErrorHandler) {
	const recurlyErrorHandler = new RecurlyErrorHandler();

	if (response.status === 204) {
		return Promise.resolve();
	}

	return response.json().then((json) => {
		if (recurlyErrorHandler.isRecurlyError(json.code)) {
			return Promise.reject(recurlyErrorHandler.createException(json.code));
		}

		if (response.status === 401) {
			if (json.code === 'account_switch_available') {
				return Promise.reject(new AccountSwitchAvailableException(path, response, json));
			}

			return Promise.reject(new UnauthorizedException(path, response, json));
		}

		if (response.status === 400) {
			return Promise.reject(new InvalidInputException(path, response, json));
		}

		if (response.status === 404) {
			return Promise.reject(new NotFoundException(path, response, json));
		}

		if (response.status === 422) {
			return Promise.reject(new ValidationException(path, response, json));
		}

		if (response.status === 500) {
			return Promise.reject(serverErrorHandler(path, response, json));
		}

		return Promise.resolve(json);
	});
}



export function get(path, parameters: QueryParameters = {}, options: RequestOptions = DEFAULT_REQUEST_OPTIONS) {
	options = { ...DEFAULT_REQUEST_OPTIONS, ...options };

	return timeoutEnhancedFetch(prefixPath(appendQueryString(path, buildQueryString(parameters))), {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'X-Client-Type': 'app',
			'X-Client-Version': APP_VERSION,
		},
		credentials: 'include',
	}, options).then((response) => {
		return validateStatus(path, response, options.serverErrorHandler);
	});
}



export function post(path, data, options: RequestOptions = DEFAULT_REQUEST_OPTIONS) {
	options = { ...DEFAULT_REQUEST_OPTIONS, ...options };

	return timeoutEnhancedFetch(prefixPath(path), {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'X-Client-Type': 'app',
			'X-Client-Version': APP_VERSION,
		},
		credentials: 'include',
		body: JSON.stringify(data),
	}, options).then((response) => {
		return validateStatus(path, response, options.serverErrorHandler);
	});
}



export function put(path, data, options: RequestOptions = DEFAULT_REQUEST_OPTIONS) {
	options = { ...DEFAULT_REQUEST_OPTIONS, ...options };

	return timeoutEnhancedFetch(prefixPath(path), {
		method: 'PUT',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'X-Client-Type': 'app',
			'X-Client-Version': APP_VERSION,
		},
		credentials: 'include',
		body: JSON.stringify(data),
	}, options).then((response) => {
		return validateStatus(path, response, options.serverErrorHandler);
	});
}
