import React from 'react';

import LinkContextProvider, {
	ExternalLinkStyle,
	InternalLinkStyle,
} from '~/components/patterns/links/LinkContextProvider';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';



type Props = {
	children: React.ReactNode,
};

const FormDescription: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="form-description">
			<List size={ListSize.Small}>
				<LinkContextProvider
					externalLinkStyle={ExternalLinkStyle.Decent}
					internalLinkStyle={InternalLinkStyle.Decent}
				>
					{children}
				</LinkContextProvider>
			</List>
		</div>
	);
};



export default FormDescription;
