import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import PlatformIssueButton from '../PlatformIssueButton';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';

import {
	IssueCategoryName,
} from '~/model/issuesNew';



const messages = defineMessages({
	description: {
		id: 'issues.domain/https_certificate_invalid.caseDescription',
	},
	solved: {
		id: 'ui.alerts.overview.incident.details.sslCertificate.replaced',
	},
});



class SslCertificateBecameInvalid extends Component {

	render() {
		const {
			incident,
			websiteId,
		} = this.props;

		const isIncidentActive = incident.get('phase') === 'active';

		return (
			<IncidentBoxContent
				button={isIncidentActive && (
					<PlatformIssueButton
						issueCategory={IssueCategoryName.Domain}
						websiteId={websiteId}
					/>
				)}
			>
				{isIncidentActive ? (
					<SeparatedBlocks>
						<FormattedMessage
							{...messages.description}
							values={{
								certificatesCount: incident.getIn(['state', 'context', 'certificatesCount'], 1),
							}}
						/>
					</SeparatedBlocks>
				) : (
					<SeparatedBlocks>
						<FormattedMessage {...messages.solved} />
					</SeparatedBlocks>
				)}
			</IncidentBoxContent>
		);
	}

}



SslCertificateBecameInvalid.propTypes = {
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string,
};



export default SslCertificateBecameInvalid;
