/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiateResetPasswordWithTwoFactorAuthenticationMutationVariables = GraphQL.Exact<{
  code: GraphQL.Scalars['String']['input'];
}>;


export type InitiateResetPasswordWithTwoFactorAuthenticationMutation = { readonly __typename?: 'Mutation', readonly InitiateResetPasswordWithTwoFactorAuthenticationSetup: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly resetPasswordSession: { readonly __typename?: 'ResetPasswordSession', readonly isTwoFactorAuthenticationEnabled: boolean, readonly isTwoFactorAuthenticationVerified: boolean } | null } } };


export const InitiateResetPasswordWithTwoFactorAuthenticationDocument = gql`
    mutation InitiateResetPasswordWithTwoFactorAuthentication($code: String!) {
  InitiateResetPasswordWithTwoFactorAuthenticationSetup(code: $code) {
    query {
      resetPasswordSession {
        isTwoFactorAuthenticationEnabled
        isTwoFactorAuthenticationVerified
      }
    }
  }
}
    `;
export type InitiateResetPasswordWithTwoFactorAuthenticationMutationFn = Apollo.MutationFunction<InitiateResetPasswordWithTwoFactorAuthenticationMutation, InitiateResetPasswordWithTwoFactorAuthenticationMutationVariables>;

/**
 * __useInitiateResetPasswordWithTwoFactorAuthenticationMutation__
 *
 * To run a mutation, you first call `useInitiateResetPasswordWithTwoFactorAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateResetPasswordWithTwoFactorAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateResetPasswordWithTwoFactorAuthenticationMutation, { data, loading, error }] = useInitiateResetPasswordWithTwoFactorAuthenticationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useInitiateResetPasswordWithTwoFactorAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<InitiateResetPasswordWithTwoFactorAuthenticationMutation, InitiateResetPasswordWithTwoFactorAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateResetPasswordWithTwoFactorAuthenticationMutation, InitiateResetPasswordWithTwoFactorAuthenticationMutationVariables>(InitiateResetPasswordWithTwoFactorAuthenticationDocument, options);
      }
export type InitiateResetPasswordWithTwoFactorAuthenticationMutationHookResult = ReturnType<typeof useInitiateResetPasswordWithTwoFactorAuthenticationMutation>;
export type InitiateResetPasswordWithTwoFactorAuthenticationMutationResult = Apollo.MutationResult<InitiateResetPasswordWithTwoFactorAuthenticationMutation>;
export type InitiateResetPasswordWithTwoFactorAuthenticationMutationOptions = Apollo.BaseMutationOptions<InitiateResetPasswordWithTwoFactorAuthenticationMutation, InitiateResetPasswordWithTwoFactorAuthenticationMutationVariables>;