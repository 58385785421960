import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import IgnoringModalBodyLayout from '../modals/IgnoringModalBodyLayout';
import IgnoringStatus, {
	STATE_IGNORED,
	STATE_NOT_IGNORED,
} from '../IgnoringStatus';
import IgnoringTable, {
	type IgnoringTableCellRendererInput,
} from '~/components/logic/issues/ignoring/IgnoringTable';
import {
	renderEllipsisCell,
} from '~/components/atoms/dataTables/utils/ReactVirtualizedCells';
import SegmentDefinitionIdentifier from '~/components/logic/segments/SegmentDefinitionIdentifier';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';
import UpcomingValueUpdateHighlight from '~/components/patterns/values/UpcomingValueUpdateHighlight';

import {
	useIgnorePageIssueOnSegmentsMutation,
	useUnignorePageIssueOnSegmentsMutation,
} from './IgnoreOnSegments.gql';

import useIsPageSegmentAllowedForIgnoring from '~/hooks/useIsPageSegmentAllowedForIgnoring';
import useTrackIgnoringUpdateInPendo from '~/hooks/useTrackIgnoringUpdateInPendo';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	type SegmentDefinition,
	filterSegmentDefinitionsByNames,
} from '~/model/segments';



const messages = defineMessages({
	headingsSegment: {
		id: 'ui.issueDetail.ignoringModal.segments.column.segment.heading',
	},
	headingsStatus: {
		id: 'ui.issueDetail.ignoringModal.scope.general.column.status.heading',
	},
	ignoringStatusIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.ignored',
	},
	ignoringStatusNotIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.notIgnored',
	},
	ignoringStatusChangeWillBeIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.willBeIgnored',
	},
	ignoringStatusChangeWillBeUnignored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.willBeUnignored',
	},
	noSegmentsDescription: {
		id: 'ui.issueDetail.ignoringModal.segments.noSegmentsDescription',
	},
	notAllowedDueToHealthExplanation: {
		id: 'ui.ignoringOnSegments.notAllowedDueToHealth.explanation',
	},
	notAllowedDueToHealthStatus: {
		id: 'ui.ignoringOnSegments.notAllowedDueToHealth.status',
	},
});

const ELLIPSIS_POPUP_ZINDEX = 2000;

const columnWidths = {
	label: 300,
	status: 202,
};

function getColumnWidth({ index }) {
	switch (index) {

		case 0:
			return columnWidths.label;
		case 1:
			return columnWidths.status;

	}
}

function renderHeader({ columnIndex }) {
	let cellContent;

	switch (columnIndex) {

		case 0:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsSegment} />
					)}
				/>
			);
			break;
		case 1:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsStatus} />
					)}
				/>
			);
			break;

	}

	return cellContent;
}

function renderStatusCell({ isChecked, isDisabled, status }) {
	if (isDisabled) {
		return (
			<UpcomingValueUpdateHighlight
				explanatoryTooltip={(
					<FormattedMessage {...messages.notAllowedDueToHealthExplanation} />
				)}
			>
				<FormattedMessage {...messages.notAllowedDueToHealthStatus} />
			</UpcomingValueUpdateHighlight>
		);
	}

	const text = status ? (
		<FormattedMessage {...messages.ignoringStatusIgnored} />
	) : (
		<FormattedMessage {...messages.ignoringStatusNotIgnored} />
	);

	const possibleDescription = status ? (
		<FormattedMessage {...messages.ignoringStatusChangeWillBeUnignored} />
	) : (
		<FormattedMessage {...messages.ignoringStatusChangeWillBeIgnored} />
	);

	const message = (
		<UpcomingValueUpdateHighlight updateDescription={isChecked && possibleDescription}>
			<IgnoringStatus state={status ? STATE_IGNORED : STATE_NOT_IGNORED}>
				{text}
			</IgnoringStatus>
		</UpcomingValueUpdateHighlight>
	);

	return renderEllipsisCell(
		message,
		ELLIPSIS_POPUP_ZINDEX,
		isChecked ? possibleDescription : text,
	);
}



type Props = {
	issue: {
		ignoringRuleOnSegments: {
			ignoredSegments: ReadonlyArray<string>,
		},
		name: string,
	},
	reloadCallback: () => Promise<void>,
	websiteId: CK.WebsiteId,
};

const IgnoreOnSegments: React.FC<Props> = (props) => {
	const {
		issue,
		reloadCallback,
		websiteId,
	} = props;

	const isPageSegmentAllowedForIgnoring = useIsPageSegmentAllowedForIgnoring(websiteId);
	const segments = useWebsiteSegmentDefinitions(websiteId);
	const trackIgnoringUpdateInPendo = useTrackIgnoringUpdateInPendo();

	const [ignorePageIssueOnSegments] = useIgnorePageIssueOnSegmentsMutation();
	const [unignorePageIssueOnSegments] = useUnignorePageIssueOnSegmentsMutation();

	const handleSubmit = React.useCallback(
		async (selectedRows) => {
			const segmentsToBeIgnored: Array<string> = [];
			const segmentsToBeUnignored: Array<string> = [];

			const selectedSegments = filterSegmentDefinitionsByNames(
				segments.listAll(),
				selectedRows,
			);

			selectedSegments.forEach((segment) => {
				const isIgnored = issue.ignoringRuleOnSegments.ignoredSegments.includes(segment.name);

				if (isIgnored) {
					segmentsToBeUnignored.push(segment.name);
				} else {
					segmentsToBeIgnored.push(segment.name);
				}
			});

			if (segmentsToBeIgnored.length > 0) {
				await ignorePageIssueOnSegments({
					variables: {
						issueName: issue.name,
						segments: segmentsToBeIgnored,
						websiteId,
					},
				});

				trackIgnoringUpdateInPendo(
					websiteId,
					issue.name,
					true,
					'segment',
				);
			}

			if (segmentsToBeUnignored.length > 0) {
				await unignorePageIssueOnSegments({
					variables: {
						segments: segmentsToBeUnignored,
						issueName: issue.name,
						websiteId,
					},
				});

				trackIgnoringUpdateInPendo(
					websiteId,
					issue.name,
					false,
					'segment',
				);
			}

			await reloadCallback();
		},
		[
			ignorePageIssueOnSegments,
			issue,
			reloadCallback,
			trackIgnoringUpdateInPendo,
			unignorePageIssueOnSegments,
			segments,
			websiteId,
		],
	);

	const renderCell = React.useCallback(
		({
			columnIndex,
			isChecked,
			isDisabled,
			item: segment,
		}: IgnoringTableCellRendererInput<SegmentDefinition>) => {
			let cellContent;

			switch (columnIndex) {

				case 0:
					cellContent = (
						<div>
							<SegmentDefinitionIdentifier
								segmentDefinition={segment}
								showEditLink={false}
							/>
						</div>
					);

					break;

				case 1:
					cellContent = renderStatusCell({
						isChecked,
						isDisabled,
						status: issue.ignoringRuleOnSegments.ignoredSegments.includes(segment.name),
					});

					break;

			}

			return cellContent;
		},
		[
			issue,
		],
	);

	if (segments.count === 0) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					<FormattedMessage {...messages.noSegmentsDescription} />
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	return (
		<IgnoringModalBodyLayout>
			<IgnoringTable
				bodyCellRenderer={renderCell}
				columnCount={2}
				columnWidth={getColumnWidth}
				disabledGetter={({ item: segment }) => isPageSegmentAllowedForIgnoring(segment) === false}
				disabledRowExplanationRenderer={() => (
					<FormattedMessage {...messages.notAllowedDueToHealthExplanation} />
				)}
				headerCellRenderer={renderHeader}
				items={segments.listAll()}
				name="segments"
				onSubmitChangesCallback={handleSubmit}
				valueGetter={(segment) => segment.name}
			/>
		</IgnoringModalBodyLayout>
	);
};



export default IgnoreOnSegments;
