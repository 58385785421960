import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SensitivityIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m0 0h24v24h-24z"
					fill="none"
				/>
				<circle
					cx="4.8"
					cy="12"
					fill={color}
					r="2.8"
				/>
				<circle
					cx="12"
					cy="12"
					fill={color}
					r="2.8"
				/>
				<circle
					cx="19.2"
					cy="12"
					fill={color}
					opacity="0.25"
					r="2.8"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SensitivityIconBuilder;

