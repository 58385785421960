import React from 'react';

import AccordionField from '~/components/patterns/forms/fields/AccordionField';
import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import AvailabilityList, {
	AvailabilityListSize,
} from '~/components/patterns/statuses/AvailabilityList';
import AvailabilityLabel, {
	AvailabilityLabelSize,
	AvailabilityLabelStatus,
} from '~/components/patterns/statuses/AvailabilityLabel';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';
import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';
import RadioTableField from '~/components/patterns/forms/fields/RadioTableField';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	capabilities: Array<{
		label: React.ReactNode,
		values: Record<string, boolean>,
		warning?: boolean,
	}>,
	items: Array<{
		disabled?: boolean,
		label: React.ReactNode,
		value: string,
	}>,
	name: string,
};

const RichRadioListField: React.FC<Props> = (props) => {
	const {
		capabilities,
		items,
		name,
	} = props;

	const {
		isDisabled,
		onBlurHandler,
		onChangeHandler,
		onFocusHandler,
		onMountHandler,
		onUnmountHandler,
		values,
	} = React.useContext(FormContext);

	const viewportType = useViewportType();

	React.useEffect(
		() => {
			onMountHandler(
				name,
				{
					interacted: true,
				},
			);

			return () => {
				onUnmountHandler(name);
			};
		},
		[
			name,
			onMountHandler,
			onUnmountHandler,
		],
	);

	const blurHandler = () => {
		onBlurHandler(name);
	};

	const focusHandler = () => {
		onFocusHandler(name);
	};

	const changeHandler = (value) => {
		onChangeHandler(name, value);
	};

	if (viewportType.isSmall) {
		return (
			<AccordionField
				name={name}
				onBlurCallback={blurHandler}
				onChangeCallback={changeHandler}
				onFocusCallback={focusHandler}
				options={items.map(({ disabled = false, label, value }) => {
					const isChecked = values[name] === value;

					return {
						content: (
							<AvailabilityList
								items={capabilities.map(({ label, values, warning = false }) => {
									return {
										disabled,
										label,
										status: warning
											? AvailabilityLabelStatus.Warning
											: (values[value] ? AvailabilityLabelStatus.Available : AvailabilityLabelStatus.Unavailable),
									};
								})}
								size={AvailabilityListSize.XSmall}
							/>
						),
						disabled: isDisabled || disabled,
						checked: isChecked,
						label: (
							<AttachedIcon
								icon={(
									<DropdownIcon
										isActive={isChecked}
										size={12}
										style={DropdownIconStyle.Greyscale}
									/>
								)}
								iconPosition={AttachedIconPosition.Suffix}
								stretched={true}
							>
								{label}
							</AttachedIcon>
						),
						showRadioElement: !disabled,
						value,
					};
				})}
			/>
		);
	}

	return (
		<RadioTableField
			data={capabilities.map(({ label, values, warning = false }) => {
				const capabilityValues = {};

				items.forEach(({ disabled = false, value }) => {
					capabilityValues[value] = (
						<BasicIcon
							grayscale={disabled}
							size={18}
							type={values[value] ? BasicIconType.Available : BasicIconType.Unavailable}
						/>
					);
				});

				if (warning) {
					label = (
						<AvailabilityLabel
							size={AvailabilityLabelSize.Small}
							status={AvailabilityLabelStatus.Warning}
						>
							{label}
						</AvailabilityLabel>
					);
				}

				return {
					label,
					values: capabilityValues,
				};
			})}
			name="role"
			onBlurCallback={blurHandler}
			onChangeCallback={changeHandler}
			onFocusCallback={focusHandler}
			options={items.map(({ disabled = false, label, value }) => {
				return {
					disabled: isDisabled || disabled,
					checked: values[name] === value,
					label,
					showRadioElement: !disabled,
					value,
				};
			})}
		/>
	);
};



export default RichRadioListField;
