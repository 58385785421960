import classNames from 'classnames';
import React from 'react';



type Props = {
	children: React.ReactNode,
	inline: boolean,
};

const IssuesFilterSection: React.FC<Props> = (props) => {
	const {
		children,
		inline,
	} = props;

	const componentClasses = classNames({
		'issues-filter-section': true,
		'issues-filter-section--is-inline': inline,
	});

	return (
		<div className={componentClasses}>
			<div className="issues-filter-section__content">
				{React.Children.map(
					children,
					(child) => {
						if (!child) {
							return false;
						}

						return (
							<div className="issues-filter-section__item">
								{child}
							</div>
						);
					},
				)}
			</div>
		</div>
	);
};



export default IssuesFilterSection;
