import React from 'react';

import type GraphQL from '~/types/graphql';

import {
	useMessagingAppChannelsQuery,
} from './useMessagingAppChannels.gql';

import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';



type MessagingAppChannels = {
	count: number,
	getById: (id: GraphQL.MessagingAppChannel['id']) => GraphQL.MessagingAppChannel | null,
	isLoaded: boolean,
	listAll: () => Readonly<Array<GraphQL.MessagingAppChannel>>,
	listByType: (type: GraphQL.MessagingAppType) => Readonly<Array<GraphQL.MessagingAppChannel>>,
};

const emptyList = [];



function useMessagingAppChannels(): MessagingAppChannels {
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const { data } = useMessagingAppChannelsQuery();

	const memberships = data?.authenticatedSession?.memberships ?? null;

	return React.useMemo(
		() => {
			const messagingAppChannels = Object.fromEntries(
				(memberships ?? []).map(
					(membership) => [
						membership.account.id,
						membership.account.messagingAppChannels,
					],
				),
			);

			return {
				count: messagingAppChannels[effectiveHomeAccountId ?? 0]?.length ?? 0,
				getById: (id: GraphQL.MessagingAppChannel['id']) => (messagingAppChannels[effectiveHomeAccountId ?? 0] ?? []).find(
					(messagingAppChannel) => messagingAppChannel.id === id,
				) ?? null,
				isLoaded: memberships !== null,
				listAll: () => messagingAppChannels[effectiveHomeAccountId ?? 0] ?? emptyList,
				listByType: (type: GraphQL.MessagingAppType) => (messagingAppChannels[effectiveHomeAccountId ?? 0] ?? []).filter(
					(messagingAppChannel) => messagingAppChannel.messagingAppType === type,
				),
			};
		},
		[
			effectiveHomeAccountId,
			memberships,
		],
	);
}



export default useMessagingAppChannels;
