/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateAlertDefinitionsScopeMutationVariables = GraphQL.Exact<{
  alertDefinitionIds: ReadonlyArray<GraphQL.Scalars['AlertDefinitionId']['input']> | GraphQL.Scalars['AlertDefinitionId']['input'];
  scope: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type BulkUpdateAlertDefinitionsScopeMutation = { readonly __typename?: 'Mutation', readonly BulkUpdateAlertDefinitionsScope: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly alertDefinitions: ReadonlyArray<{ readonly __typename?: 'AlertDefinition', readonly alertType: string, readonly id: CK.AlertDefinitionId, readonly recipients: ReadonlyArray<string>, readonly scope: string, readonly settings: Record<string, any>, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType }> }> } | null } } };


export const BulkUpdateAlertDefinitionsScopeDocument = gql`
    mutation BulkUpdateAlertDefinitionsScope($alertDefinitionIds: [AlertDefinitionId!]!, $scope: String!, $websiteId: WebsiteId!) {
  BulkUpdateAlertDefinitionsScope(
    alertDefinitionIds: $alertDefinitionIds
    scope: $scope
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        alertDefinitions {
          alertType
          id
          messagingAppChannels {
            id
            label
            messagingAppType
          }
          recipients
          scope
          settings
        }
        id
      }
    }
  }
}
    `;
export type BulkUpdateAlertDefinitionsScopeMutationFn = Apollo.MutationFunction<BulkUpdateAlertDefinitionsScopeMutation, BulkUpdateAlertDefinitionsScopeMutationVariables>;

/**
 * __useBulkUpdateAlertDefinitionsScopeMutation__
 *
 * To run a mutation, you first call `useBulkUpdateAlertDefinitionsScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAlertDefinitionsScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateAlertDefinitionsScopeMutation, { data, loading, error }] = useBulkUpdateAlertDefinitionsScopeMutation({
 *   variables: {
 *      alertDefinitionIds: // value for 'alertDefinitionIds'
 *      scope: // value for 'scope'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useBulkUpdateAlertDefinitionsScopeMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateAlertDefinitionsScopeMutation, BulkUpdateAlertDefinitionsScopeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateAlertDefinitionsScopeMutation, BulkUpdateAlertDefinitionsScopeMutationVariables>(BulkUpdateAlertDefinitionsScopeDocument, options);
      }
export type BulkUpdateAlertDefinitionsScopeMutationHookResult = ReturnType<typeof useBulkUpdateAlertDefinitionsScopeMutation>;
export type BulkUpdateAlertDefinitionsScopeMutationResult = Apollo.MutationResult<BulkUpdateAlertDefinitionsScopeMutation>;
export type BulkUpdateAlertDefinitionsScopeMutationOptions = Apollo.BaseMutationOptions<BulkUpdateAlertDefinitionsScopeMutation, BulkUpdateAlertDefinitionsScopeMutationVariables>;