import {
	List,
} from 'immutable';



export const HINT_AUTOPRIORITIZATION = 'last_crawl_tooltip';
export const HINT_COBRANDING_UPLOAD = 'cobranding_upload';
export const HINT_COLUMN_SETS_PICKER = 'column_sets_picker';
export const HINT_PENDO_GUIDE_BULK_EDIT_ALERT_DEFINITIONS = 'pendo_guide_bulk_edit_alert_definitions';
export const HINT_PENDO_GUIDE_DASHBOARD = 'pendo_guide_dashboard';
export const HINT_PERCENTAGE_OF_AFFECTED_PAGES = 'percentage_of_affected_pages';
export const HINT_RELEVANCE = 'relevance';

export const ALL_HINTS = List([
	HINT_AUTOPRIORITIZATION,
	HINT_COBRANDING_UPLOAD,
	HINT_COLUMN_SETS_PICKER,
	HINT_PENDO_GUIDE_BULK_EDIT_ALERT_DEFINITIONS,
	HINT_PENDO_GUIDE_DASHBOARD,
	HINT_PERCENTAGE_OF_AFFECTED_PAGES,
	HINT_RELEVANCE,
]);



export function shouldHintBeDisplayedAlready(hintName, conditionsAlready, conditionsNotAnymore, context, interfaceHints, openedHint) {
	const hintState = interfaceHints.find(
		(hint) => hint.get('name') === hintName,
	);

	if (!hintState) {
		return false;
	}

	if (hintState.get('displayed') && openedHint !== hintName) {
		return false;
	}

	if (conditionsAlready.length === 0 && conditionsNotAnymore.length === 0) {
		return true;
	}

	return (
		!conditionsAlready.some((condition) => !condition(context))
		&& !conditionsNotAnymore.some((condition) => !condition(context))
	);
}



export function shouldHintNotBeDisplayedAnymore(hintName, conditionsNotAnymore, context, interfaceHints) {
	const hintState = interfaceHints.find(
		(hint) => hint.get('name') === hintName,
	);

	if (!hintState) {
		return false;
	}

	if (hintState.get('displayed')) {
		return false;
	}

	if (conditionsNotAnymore.length === 0) {
		return false;
	}

	return conditionsNotAnymore.every((condition) => !condition(context));
}
