import React from 'react';
import {
	useSelector,
} from 'react-redux';

import HistoricalDesktopLayout from '~/components/app/IssuesScreen/HistoricalDesktopLayout';
import HistoricalIssuesList from '~/components/app/IssuesScreen/HistoricalIssuesList';
import HistoricalMobileLayout from '~/components/app/IssuesScreen/HistoricalMobileLayout';

import useHistoricalIssuesComparison from '~/hooks/useHistoricalIssuesComparison';
import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	issuesFilterParameterSelector,
} from '~/state/ui/content/selectors';

import {
	TABBED_VIEW_BREAKPOINT,
} from '~/utilities/viewportSize';



type Props = {
	innerPanel: any,
};

const HistoricalView: React.FC<Props> = (props) => {
	const {
		innerPanel,
	} = props;

	const websiteId = useWebsiteId();

	const historicalIssuesComparison = useHistoricalIssuesComparison(websiteId);
	const issuesFilterParameter = useSelector(issuesFilterParameterSelector);
	const viewportWidth = useViewportWidth();

	const tabbedView = viewportWidth < TABBED_VIEW_BREAKPOINT;

	const issueCategoriesList = (
		<HistoricalIssuesList
			loading={historicalIssuesComparison === null}
			numberOfPlaceholders={5}
			websiteIssuesComparisonData={historicalIssuesComparison}
		/>
	);

	if (tabbedView) {
		return (
			<HistoricalMobileLayout
				innerPanel={innerPanel}
				issueCategoriesList={issueCategoriesList}
				issuesFilterParameter={issuesFilterParameter}
			/>
		);
	}

	return (
		<HistoricalDesktopLayout
			innerPanel={innerPanel}
			isLoading={historicalIssuesComparison === null}
			issueCategoriesList={issueCategoriesList}
		/>
	);
};



export default HistoricalView;
