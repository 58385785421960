import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AbstractSelectField, {
	type AbstractSelectFieldRef,
} from '~/components/patterns/forms/fields/AbstractSelectField';
import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import ColumnController, {
	ColumnControllerSize,
} from '~/components/patterns/forms/fieldParts/pageColumns/ColumnController';
import ColumnsCategoryController, {
	ColumnsCategoryControllerSize,
} from '~/components/patterns/forms/fieldParts/pageColumns/ColumnsCategoryController';
import ColumnCategoryName from '~/components/names/ColumnCategoryName';
import ColumnName from '~/components/names/ColumnName';
import FieldDropdown from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdown';
import PureRadioFields, {
	SIZE_SMALL as RADIO_SIZE_SMALL,
} from '~/components/atoms/forms/components/builders/PureRadioFields';

import useFormContext from '~/hooks/useFormContext';
import useWebsiteColumnCategories from '~/hooks/useWebsiteColumnCategories';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	DATA_TYPE_DATE,
	DATA_TYPE_INTEGER,
	DATA_TYPE_NUMBER,
} from '~/model/customElements';

import {
	PagesColumnsCategory,
} from '~/model/pagesColumns';

import {
	COLUMNS_ALLOWED_FOR_SIZE_LIMIT,
} from '~/model/segments';



const messages = defineMessages({
	disabledColumn: {
		id: 'ui.segments.editor.sizeLimitModal.field.disabledColumn',
	},
});



const iconMapping = {
	[PagesColumnsCategory.AdobeAnalytics]: AttributesGroupIconType.AdobeAnalytics,
	[PagesColumnsCategory.Content]: AttributesGroupIconType.Content,
	[PagesColumnsCategory.Conversions]: AttributesGroupIconType.Conversions,
	[PagesColumnsCategory.CustomElements]: AttributesGroupIconType.CustomElements,
	[PagesColumnsCategory.EnrichmentFields]: AttributesGroupIconType.EnrichmentFields,
	[PagesColumnsCategory.Fundamentals]: AttributesGroupIconType.Fundamentals,
	[PagesColumnsCategory.GoogleAnalytics]: AttributesGroupIconType.GoogleAnalytics,
	[PagesColumnsCategory.GoogleSearchConsole]: AttributesGroupIconType.GoogleSearchConsole,
	[PagesColumnsCategory.Indexability]: AttributesGroupIconType.Indexability,
	[PagesColumnsCategory.Lighthouse]: AttributesGroupIconType.Lighthouse,
	[PagesColumnsCategory.Relations]: AttributesGroupIconType.Relations,
	[PagesColumnsCategory.SchemaOrg]: AttributesGroupIconType.SchemaOrg,
	[PagesColumnsCategory.SearchEngineActivity]: AttributesGroupIconType.SearchEngineActivity,
	[PagesColumnsCategory.Social]: AttributesGroupIconType.Social,
} as const;



type Props = {
	name: string,
	placeholder: React.ReactNode,
};

const ColumnSelectField: React.FC<Props> = (props) => {
	const {
		name,
		placeholder,
	} = props;

	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const formContext = useFormContext();
	const selectRef = React.useRef<AbstractSelectFieldRef | null>(null);
	const websiteColumnCategories = useWebsiteColumnCategories();

	const [expanded, setExpanded] = React.useState<string>();

	function onChange(category, value) {
		setExpanded(category);
		formContext.onChangeHandler(name, value);

		selectRef.current?.close();
	}

	return (
		<AbstractSelectField
			dropdownWidth={245}
			label={formContext.values.field ? (
				<ColumnName column={formContext.values.field} />
			) : placeholder}
			ref={selectRef}
			width={245}
		>
			<FieldDropdown contentMaxHeight={263}>
				{websiteColumnCategories.map((category) => {
					return (
						<ColumnsCategoryController
							defaultOpen={expanded === category.name}
							key={category.name}
							label={iconMapping[category.name] !== undefined && (
								<>
									<AttributesGroupIcon
										size={20}
										type={iconMapping[category.name]}
									/>
									<ColumnCategoryName columnCategory={category.name} />
								</>
							)}
							name={category.name}
							size={ColumnsCategoryControllerSize.Small}
						>
							<PureRadioFields
								isControlled={true}
								items={category.columns.map((columnName) => {
									const checked = columnName === formContext.values[name];
									let disabled = false;

									if (category.name === PagesColumnsCategory.CustomElements) {
										const dataType = customElementDefinitions.getByColumn(columnName)?.dataType ?? null;

										if (dataType !== DATA_TYPE_DATE && dataType !== DATA_TYPE_INTEGER && dataType !== DATA_TYPE_NUMBER) {
											disabled = true;
										}
									} else if (category.name === PagesColumnsCategory.EnrichmentFields) {
										const dataType = enrichmentFieldDefinitions.getByColumn(columnName)?.dataType ?? null;

										if (dataType !== DATA_TYPE_DATE && dataType !== DATA_TYPE_NUMBER) {
											disabled = true;
										}
									} else {
										disabled = !COLUMNS_ALLOWED_FOR_SIZE_LIMIT.includes(columnName);
									}

									return {
										checked,
										disabled,
										label: (
											<ColumnName column={columnName} />
										),
										readonly: disabled,
										value: columnName,
									};
								})}
								name={`select-column-${category.name}`}
								onChangeCallback={(column) => {
									onChange(category.name, column);
								}}
								size={RADIO_SIZE_SMALL}
							>
								{({ fields }) => fields.map((field) => (
									<ColumnController
										isDisabled={field.data.disabled}
										key={field.data.value}
										label={field.field}
										labelTooltip={
											field.data.disabled ? (
												<FormattedMessage {...messages.disabledColumn} />
											) : null
										}
										size={ColumnControllerSize.Small}
									/>
								))
								}
							</PureRadioFields>
						</ColumnsCategoryController>
					);
				})}
			</FieldDropdown>
		</AbstractSelectField>
	);
};



export default ColumnSelectField;
