import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';

import AddAnotherStepButton from './AddAnotherStepButton';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CustomElementDatePatternSelectField from '~/components/app/CustomElementDatePatternSelectField';
import CustomElementExtractedElementSelectField from '~/components/logic/customElements/CustomElementExtractedElementSelectField';
import CustomElementNumberPatternSelectField from '~/components/app/CustomElementNumberPatternSelectField';
import CustomElementSourceSelectField from '~/components/logic/customElements/CustomElementSourceSelectField';
import DataDeletionConfirmation from './DataDeletionConfirmation';
import ExtractionCaution from './ExtractionCaution';
import ExtractionCleanup from './extractionSteps/ExtractionCleanup';
import ExtractionStepsConfigurator from './extractionSteps/ExtractionStepsConfigurator';
import ExtractionSummaryInfo from './ExtractionSummaryInfo';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Fieldset from '~/components/patterns/forms/wrappers/Fieldset';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import FormDescription from '~/components/atoms/forms/components/rowParts/FormDescription';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import FormSentence from '~/components/atoms/forms/basis/FormSentence';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';
import TransformationStepsConfigurator, {
	type TransformationStepsConfiguratorRef,
} from './transformationSteps/TransformationStepsConfigurator';

import {
	validateField,
} from '~/components/app/validations';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	type CustomElementDefinition,
	DEFAULT_EXTRACTION_SETUP,
	EXTRACTED_ELEMENT_DATE,
	EXTRACTED_ELEMENT_NUMBER,
	EXTRACTED_ELEMENT_STRING,
	EXTRACTION_METHOD_CSS,
	EXTRACTION_METHOD_REGEXP,
	EXTRACTION_METHOD_XPATH,
	type Extraction,
	OCCURRENCES_SPECIFIC,
	TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES,
	convertDataTypeToElementType,
	detectDataType,
	detectExtractedElementOutputDataType,
	doesProduceSingleOccurrence,
	doesTrackingHaveToBeRestarted,
} from '~/model/customElements';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	elementToExtractDateDescription: {
		id: 'ui.customElements.elementToExtract.date.description',
	},
	elementToExtractInFormat: {
		id: 'ui.customElements.elementToExtract.elementFormat',
	},
	elementToExtractLabel: {
		id: 'ui.customElements.elementToExtract.element',
	},
	elementToExtractNumberDescription: {
		id: 'ui.customElements.elementToExtract.number.description',
	},
	extractElementFromLabel: {
		id: 'ui.customElements.extractElementFrom.label',
	},
	extractElementFromOptionsDom: {
		id: 'ui.customElements.extractElementFrom.options.dom',
	},
	extractElementFromOptionsSrc: {
		id: 'ui.customElements.extractElementFrom.options.src',
	},
	fieldsetsExtractionStepsCaption: {
		id: 'ui.customElements.form.fieldsets.extractionSteps.caption',
	},
	fieldsetsTransformationStepsAddAnotherStepButton: {
		id: 'ui.customElements.form.fieldsets.transformationSteps.addAnotherStepButton',
	},
	fieldsetsTransformationStepsCaption: {
		id: 'ui.customElements.form.fieldsets.transformationSteps.caption',
	},
	formErrorsAlreadyUsedLabel: {
		id: 'ui.customElements.formErrors.alreadyUsedLabel',
	},
	formErrorsBlank: {
		id: 'ui.formErrors.blank',
	},
	formFieldsLabelLabel: {
		id: 'ui.customElements.form.fields.name.label',
	},
	formFieldsLabelPlaceholder: {
		id: 'ui.customElements.form.fields.name.placeholder',
	},
	saveButton: {
		id: 'ui.customElements.form.saveButton',
	},
});



function convertValuesToExtraction(values: Record<string, any>) {
	const extraction: Extraction = {
		source: values.source,
		steps: values.extractionSteps.map((extractionStep) => {
			const ruleType = extractionStep.method;
			const ruleExpression = extractionStep.expression;

			let occurrencesType = extractionStep.occurrence;
			let occurrencesOptions = {};

			if (occurrencesType.startsWith('specific')) {
				const occurrencesPosition = parseInt(occurrencesType.split(':')[1]);

				occurrencesType = OCCURRENCES_SPECIFIC;
				occurrencesOptions = {
					position: occurrencesPosition,
				};
			}

			return {
				rule: {
					type: ruleType,
					options: matchAndReturn(ruleType, {
						[EXTRACTION_METHOD_XPATH]: {
							xpath: ruleExpression,
						},
						[EXTRACTION_METHOD_CSS]: {
							css: ruleExpression,
						},
						[EXTRACTION_METHOD_REGEXP]: {
							pattern: ruleExpression,
						},
					}),
				},
				occurences: {
					type: occurrencesType,
					options: occurrencesOptions,
				},
			};
		}),
		type: values.type,
		type_options: {},
		transformations: values.transformations.map(
			(transformation) => {
				const result = {
					type: transformation.type,
					options: { ...transformation.parameters },
				};

				if (result.type === TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES) {
					const whitelist = (result.options.whitelist ?? '').trim();

					if (whitelist === '') {
						result.options.whitelist = [];
					} else {
						result.options.whitelist = whitelist
							.split(',')
							.map((attribute) => attribute.trim())
							.filter((attribute) => attribute !== '');
					}
				}

				return result;
			},
		),
	};

	if (extraction.type === EXTRACTED_ELEMENT_DATE) {
		extraction.type_options.smart_pattern = values.date_pattern;
	}

	if (extraction.type === EXTRACTED_ELEMENT_NUMBER) {
		extraction.type_options.pattern = values.number_pattern;
	}

	if (extraction.type === EXTRACTED_ELEMENT_STRING) {
		extraction.type_options.condense_whitespace = values.string_condense_whitespace;
		extraction.type_options.decode_html_entities = values.string_decode_html_entities;
		extraction.type_options.drop_empty = values.string_drop_empty;
		extraction.type_options.strip_tags = values.string_strip_tags;
	}

	return extraction;
}



type Props = {
	defaultExtraction: Extraction,
	defaultLabel: string,
	editedCustomElement: CustomElementDefinition | null,
	onChangeCallback: (isValid: boolean, extraction: Extraction) => void,
	onSubmitCallback: (values: {
		extraction: Extraction,
		label: string,
		restartTracking: boolean,
	}) => Promise<void>,
	showSourceSelect: boolean,
	websiteId: CK.WebsiteId,
};

const CustomElementForm: React.FC<Props> = (props) => {
	const {
		defaultExtraction,
		defaultLabel,
		editedCustomElement,
		onChangeCallback,
		onSubmitCallback,
		showSourceSelect,
		websiteId,
	} = props;

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const intl = useIntl();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const formRef = React.useRef<FormRef>(null);
	const transformationsFieldRef = React.useRef<TransformationStepsConfiguratorRef>(null);

	const emitExtraction = React.useCallback(
		(values) => {
			const testedValues = values;
			values.label = 'CONTENTKING_UNIQUE_NAME';

			const validationResult = formRef.current?.validateValues(testedValues);

			if (validationResult?.isValid) {
				onChangeCallback(
					validationResult.isValid,
					convertValuesToExtraction(
						values,
					),
				);
			}
		},
		[
			onChangeCallback,
		],
	);

	React.useEffect(
		() => {
			emitExtraction(
				formRef.current?.getValues(),
			);
		},
		[
			emitExtraction,
		],
	);

	const handleFormChange = React.useCallback(
		(_, __, values) => {
			emitExtraction(values);
		},
		[
			emitExtraction,
		],
	);

	const handleFormSubmit = React.useCallback(
		(values) => {
			return onSubmitCallback({
				extraction: convertValuesToExtraction(
					values,
				),
				label: editedCustomElement !== null
					? editedCustomElement.label
					: values.label,
				restartTracking: values.restart_tracking,
			});
		},
		[
			editedCustomElement,
			onSubmitCallback,
		],
	);

	const validations = {
		label: validateField(
			'label',
			(f) => [
				f.validateNonEmpty(),
				f.custom({
					message: (
						<FormattedMessage {...messages.formErrorsAlreadyUsedLabel} />
					),
					rule: ({ value }) => {
						return customElementDefinitions.listAll().every((customElementDefinition) => {
							if (editedCustomElement !== null && editedCustomElement.name === customElementDefinition.name) {
								return true;
							}

							return customElementDefinition.label !== value;
						});
					},
				}),
			],
		),
		type: validateField(
			'type',
			(f) => [
				f.validateNonEmpty(),
			],
		),
		date_pattern: validateField(
			'date_pattern',
			(f) => [
				f.whenOtherField(
					'type',
					({ value }) => value === EXTRACTED_ELEMENT_DATE,
				),
				f.validateNonEmpty(),
			],
		),
		number_pattern: validateField(
			'number_pattern',
			(f) => [
				f.whenOtherField(
					'type',
					({ value }) => value === EXTRACTED_ELEMENT_NUMBER,
				),
				f.validateNonEmpty(),
			],
		),
	};

	const defaultValues: Record<string, any> = {
		label: defaultLabel,
		source: defaultExtraction.source,
		type: defaultExtraction.type,
	};

	defaultValues.date_pattern = defaultExtraction.type === EXTRACTED_ELEMENT_DATE
		? defaultExtraction.type_options.smart_pattern
		: undefined;

	defaultValues.number_pattern = defaultExtraction.type === EXTRACTED_ELEMENT_NUMBER
		? defaultExtraction.type_options.pattern
		: undefined;

	defaultValues.string_condense_whitespace = defaultExtraction.type_options.condense_whitespace ?? DEFAULT_EXTRACTION_SETUP.type_options.condense_whitespace;
	defaultValues.string_decode_html_entities = defaultExtraction.type_options.decode_html_entities ?? DEFAULT_EXTRACTION_SETUP.type_options.decode_html_entities;
	defaultValues.string_drop_empty = defaultExtraction.type_options.drop_empty ?? DEFAULT_EXTRACTION_SETUP.type_options.drop_empty;
	defaultValues.string_strip_tags = defaultExtraction.type_options.strip_tags ?? DEFAULT_EXTRACTION_SETUP.type_options.strip_tags;

	defaultValues.extractionSteps = defaultExtraction.steps.map((step) => {
		const occurencesType = step.occurences.type;
		const ruleType = step.rule.type;

		return {
			expression: matchAndReturn(ruleType ?? '', {
				[EXTRACTION_METHOD_CSS]: step.rule.options.css,
				[EXTRACTION_METHOD_REGEXP]: step.rule.options.pattern,
				[EXTRACTION_METHOD_XPATH]: step.rule.options.xpath,
				['']: undefined,
			}),
			method: ruleType,
			occurrence: occurencesType === 'specific'
				? 'specific:' + step.occurences.options.position
				: occurencesType,
		};
	});

	defaultValues.transformations = defaultExtraction.transformations.map((transformation) => {
		const transformationType = transformation.type;

		const result = {
			parameters: transformation.options,
			type: transformationType,
		};

		if (transformationType === TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES) {
			result.parameters = {
				...result.parameters,
				whitelist: result.parameters.whitelist.join(', '),
			};
		}

		return result;
	});

	return (
		<Form
			defaultFocus={defaultLabel ? null : 'label'}
			defaultValues={defaultValues}
			onChangeCallback={handleFormChange}
			onSuccess={handleFormSubmit}
			ref={formRef}
			validations={validations}
		>
			{({ values }) => {
				const extraction = convertValuesToExtraction(values);

				const elementType = detectDataType(extraction);
				const extractedElementDataType = detectExtractedElementOutputDataType(extraction.type ?? null);
				const producesSingleOccurrence = doesProduceSingleOccurrence(extraction);
				const trackingHasToBeRestarted = editedCustomElement !== null
					? doesTrackingHaveToBeRestarted(editedCustomElement.dataType, elementType)
					: false;

				const isDifferent = editedCustomElement !== null
					? (JSON.stringify(editedCustomElement.extraction) !== JSON.stringify(extraction))
					: false;

				return (
					<FormRows>
						<FormRow
							htmlFor="label"
							label={(
								<FormattedMessage {...messages.formFieldsLabelLabel} />
							)}
						>
							<FieldStatus name="label">
								<TextField
									disabled={editedCustomElement !== null}
									name="label"
									placeholder={intl.formatMessage(messages.formFieldsLabelPlaceholder)}
									trimValue={true}
								/>
							</FieldStatus>
						</FormRow>
						<Fieldset
							label={(
								<FormattedMessage {...messages.fieldsetsExtractionStepsCaption} />
							)}
						>
							<FormRows>
								<ExtractionStepsConfigurator
									name="extractionSteps"
									showAddButton={true}
								/>

								<FormSentence
									description={values.type === EXTRACTED_ELEMENT_DATE ? (
										<FormDescription>
											<FormattedMessage {...messages.elementToExtractDateDescription} />
										</FormDescription>
									) : values.type === EXTRACTED_ELEMENT_NUMBER ? (
										<FormDescription>
											<FormattedMessage {...messages.elementToExtractNumberDescription} />
										</FormDescription>
									) : undefined}
								>
									<FormattedMessage {...messages.elementToExtractLabel} />

									<FieldStatus
										name="type"
										showIcon={false}
									>
										<CustomElementExtractedElementSelectField
											name="type"
											width={150}
										/>
									</FieldStatus>

									{(values.type === EXTRACTED_ELEMENT_DATE || values.type === EXTRACTED_ELEMENT_NUMBER) && (
										<FormattedMessage {...messages.elementToExtractInFormat} />
									)}

									{values.type === EXTRACTED_ELEMENT_DATE && (
										<FieldStatus
											name="date_pattern"
											showIcon={false}
										>
											<CustomElementDatePatternSelectField
												name="date_pattern"
												width={150}
											/>
										</FieldStatus>
									)}

									{values.type === EXTRACTED_ELEMENT_NUMBER && (
										<FieldStatus
											name="number_pattern"
											showIcon={false}
										>
											<CustomElementNumberPatternSelectField
												name="number_pattern"
												width={150}
											/>
										</FieldStatus>
									)}
								</FormSentence>

								{values.type === EXTRACTED_ELEMENT_STRING && (
									<FormRow
										fullwidth={true}
										indentationSize={0}
									>
										<ExtractionCleanup />
									</FormRow>
								)}

								{showSourceSelect && (
									<FormSentence>
										<FormattedMessage {...messages.extractElementFromLabel} />

										<FieldStatus
											name="source"
											showIcon={false}
										>
											<CustomElementSourceSelectField
												name="source"
												width={150}
											/>
										</FieldStatus>
									</FormSentence>
								)}

							</FormRows>
						</Fieldset>

						<Fieldset
							headerActionElements={transformationsFieldRef.current?.numberOfTransformations === 0 && (
								<AddAnotherStepButton onClickCallback={transformationsFieldRef.current.addTransformation}>
									<FormattedMessage {...messages.fieldsetsTransformationStepsAddAnotherStepButton} />
								</AddAnotherStepButton>
							)}
							isCollapsed={(transformationsFieldRef.current?.numberOfTransformations ?? 0) === 0}
							isCollapsible={true}
							label={(
								<FormattedMessage {...messages.fieldsetsTransformationStepsCaption} />
							)}
						>
							<TransformationStepsConfigurator
								extractedElementDataType={extractedElementDataType}
								name="transformations"
								ref={transformationsFieldRef}
							/>
						</Fieldset>

						<ExtractionSummaryInfo
							elementType={elementType}
							isSingleOccurrence={producesSingleOccurrence}
						/>

						{editedCustomElement !== null && segmentDefinitions.isLoaded && (
							<ExtractionCaution
								isDifferent={isDifferent}
								newElementType={convertDataTypeToElementType(elementType)}
								oldElementType={convertDataTypeToElementType(editedCustomElement.dataType)}
								oldName={editedCustomElement.name}
								segments={segmentDefinitions.listAll()}
							/>
						)}

						{editedCustomElement !== null && !trackingHasToBeRestarted && isDifferent && (
							<DataDeletionConfirmation />
						)}

						<ButtonsLayout
							alignment={ButtonsLayoutAlignment.Right}
							layout={ButtonsLayoutType.Steps}
						>
							<CancelButton />

							<SubmitButton>
								<FormattedMessage {...messages.saveButton} />
							</SubmitButton>
						</ButtonsLayout>
					</FormRows>
				);
			}}
		</Form>
	);
};



export default CustomElementForm;
