import React from 'react';

import type CK from '~/types/contentking';

import AccountStripeContext from '~/components/app/Stripe/AccountStripeContext';
import {
	PaymentAuthorizationContextContext,
} from '~/components/logic/PaymentAuthorizationContext';

import useResolvePaymentStatus from '~/hooks/useResolvePaymentStatus';



type Props = {
	accountId: CK.AccountId,
	children: (props: Record<string, any>) => React.ReactElement,
	onError: (error: any, values: any, helpers: any) => any,
	onSubmit: (payload: { helpers: any, values: Record<string, any> }) => Promise<any>,
};

const StripePaymentAuthorizationContext: React.FC<Props> = (props) => {
	const {
		accountId,
		children,
		onError,
		onSubmit,
	} = props;

	const resolvePaymentStatus = useResolvePaymentStatus(accountId);

	const cancelAuthorization = React.useCallback(
		() => { },
		[],
	);

	const context = React.useMemo(
		() => ({
			cancelAuthorization,
			isAuthorizing: false,
			isResubmitting: false,
		}),
		[
			cancelAuthorization,
		],
	);

	const formRef = React.useRef();

	const handleFormSuccess = React.useCallback(
		async (values, helpers) => {
			try {
				const result = await onSubmit({ helpers, values });

				await resolvePaymentStatus();

				return result;
			} catch (error) {
				throw onError(error, values, helpers);
			}
		},
		[
			onError,
			onSubmit,
			resolvePaymentStatus,
		],
	);

	return (
		<PaymentAuthorizationContextContext.Provider value={context}>
			{children({
				cancelAuthorization,
				formRef,
				handleFormSuccess,
				isAuthorizing: false,
				isResubmitting: false,
			})}
		</PaymentAuthorizationContextContext.Provider>
	);
};

const StripePaymentAuthorizationContextWrapper: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	return (
		<AccountStripeContext accountId={accountId}>
			<StripePaymentAuthorizationContext {...props}>
				{props.children}
			</StripePaymentAuthorizationContext>
		</AccountStripeContext>
	);
};



export default StripePaymentAuthorizationContextWrapper;
