import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import Copy from '~/components/logic/Copy';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import InsufficientPermissionMessage from '~/components/app/InsufficientPermissionMessage';

import usePermission from '~/hooks/usePermission';

import {
	type Action,
	type ObjectType,
} from '~/model/permissions';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



const messages = defineMessages({
	insufficientRoyaltyLevel: {
		id: 'ui.general.notAllowedBecauseInsufficientRoyaltyLevel',
	},
	managed: {
		id: 'ui.general.notAllowedBecauseManaged',
	},
	managedViaConductor: {
		id: 'ui.general.notAllowedBecauseManagedViaConductor',
	},
});



type ChildProps = {
	isAllowed: ReturnType<ReturnType<typeof usePermission>>,
	message?: React.ReactNode,
};

type WithPermissionChildrenType = RenderProp<ChildProps>;

type Props = {
	action: Action,
	children: WithPermissionChildrenType,
	messageForInsufficientPermissions?: React.ReactNode,
	messageForManaged?: React.ReactNode,
	messageForManagedViaConductor?: React.ReactNode,
	objectId: string | number | null,
	objectType: ObjectType,
	showMessage?: boolean,
};

const WithPermission: React.FC<Props> = (props) => {
	const {
		action,
		children,
		messageForInsufficientPermissions,
		messageForManaged,
		messageForManagedViaConductor,
		objectId,
		objectType,
		showMessage = true,
	} = props;

	const isAllowedOracle = usePermission();

	const isAllowed = isAllowedOracle({
		action,
		objectId,
		objectType,
	});

	let disallowedMessage: React.ReactNode = null;

	if (isAllowed.no) {
		if (isAllowed.noBecauseInsufficientPermissions) {
			disallowedMessage = messageForInsufficientPermissions ?? (
				<InsufficientPermissionMessage />
			);
		} else if (isAllowed.noBecauseInsufficientPermissionsForKingdomAdmin) {
			disallowedMessage = messageForManaged ?? (
				<Copy {...messages.insufficientRoyaltyLevel} />
			);
		} else if (isAllowed.noBecauseManaged) {
			disallowedMessage = messageForManaged ?? (
				<Copy {...messages.managed} />
			);
		} else if (isAllowed.noBecauseManagedViaConductor) {
			disallowedMessage = messageForManagedViaConductor ?? (
				<Copy {...messages.managedViaConductor} />
			);
		}
	}

	let result = renderProp(
		children,
		{
			isAllowed,
			message: disallowedMessage,
		},
	);

	if (!result) {
		return null;
	}

	if (isAllowed.yes === false && showMessage) {
		result = (
			<Hint
				attachment={HintAttachment.Center}
				blurDelay={100}
				popup={disallowedMessage}
			>
				{result}
			</Hint>
		);
	}

	return <>{result}</>;
};



export default WithPermission;

export {
	WithPermissionChildrenType,
};
