import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class HeaderTitleLayout extends Component {

	render() {
		const {
			children,
			keepPredefinedWidth,
		} = this.props;

		const componentClasses = classNames({
			'header-title-layout': true,
			'header-title-layout--predefined-width': keepPredefinedWidth,
		});

		return (
			<div className={componentClasses}>
				{React.Children.map(
					children,
					(child, index) => {
						if (!child) {
							return false;
						}

						return (
							<div
								className="header-title-layout__row"
								key={'header-title-row-' + index}
							>
								{child}
							</div>
						);
					},
				)}
			</div>
		);
	}

}

HeaderTitleLayout.propTypes = {
	keepPredefinedWidth: PropTypes.bool,
};



export default HeaderTitleLayout;
