import useAccountIds from '~/hooks/useAccountIds';
import useDisabledMemberships from '~/hooks/useDisabledMemberships';



function useHasMultipleAccounts(): boolean {
	const accountIds = useAccountIds();
	const disabledMemberships = useDisabledMemberships();

	return ((accountIds?.length ?? 0) + (disabledMemberships?.length ?? 0)) > 1;
}



export default useHasMultipleAccounts;
