import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountPlanQuery,
} from './useAccountPlan.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountPlan(accountId: CK.AccountId | null): GraphQL.Account['plan'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountPlanQuery,
		accountId,
	);

	return data?.account?.plan ?? null;
}



export default useAccountPlan;
