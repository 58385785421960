import React from 'react';

import Emphasis from '~/components/patterns/typography/Emphasis';



type Props = {
	/** Enable ellpsis support to prevent overflowing */
	ellipsis?: boolean,
	children: React.ReactNode,
};



const BlankValue: React.FC<Props> = (props) => {
	const {
		children,
		ellipsis,
	} = props;

	return (
		<Emphasis
			ellipsis={ellipsis}
			muted={true}
		>
			{children}
		</Emphasis>
	);
};



export default BlankValue;
