class MissingContextProviderError extends Error {

	constructor(contextName: string) {
		super(`Missing provider for: ${contextName}, make sure to include the provider up the tree.`);
	}

}



export { MissingContextProviderError };
