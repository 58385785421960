import React from 'react';



type Props = {
	/** Source files per their scale factor. Using this we can define alternate version for retina screens. */
	files: ReadonlyArray<{
		scaleFactor: number,
		url: string,
	}>,
	/** Square size of icon */
	size?: number,
};



const EmbedImageIcon: React.FC<Props> = (props) => {
	const {
		files,
		size = 20,
	} = props;

	const defaultImage = files.find((file) => file.scaleFactor === 1);
	const src = defaultImage?.url ?? null;
	const srcSet = files.map((file) => `${file.url} ${file.scaleFactor}x`).join(',\n');

	if (src === null) {
		return null;
	}

	return (
		<img
			className="embed-image-icon"
			src={src}
			srcSet={srcSet}
			style={{
				height: size,
				width: size,
			}}
		/>
	);
};



export default EmbedImageIcon;
