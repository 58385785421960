import {
	btoa,
} from '~/globals';



export default function encodeInBase64(value: string) {
	return btoa(
		unescape(
			encodeURIComponent(value),
		),
	);
}
