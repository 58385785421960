import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AccountSettings from '~/components/logic/accountSettings/AccountSettings';
import HeaderTitle from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';



const messages = defineMessages({
	title: {
		id: 'ui.teamDetail.accountSettings.menuLabel',
	},
});



const AccountSettingsSection: React.FC = () => {
	return (
		<ScreenLayout
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						<HeaderTitle baselineAlignment={false}>
							<FormattedMessage {...messages.title} />
						</HeaderTitle>
					</HeaderTitleLayout>
				</ScreenHeader>
			)}
		>
			<AccountSettings />
		</ScreenLayout>
	);
};



export default AccountSettingsSection;
