import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import Form from '~/components/atoms/forms/basis/Form';
import IgnoringMessage from '~/components/patterns/messages/embedded/IgnoringMessage';
import IgnoringModalBodyLayout from '~/components/atoms/issues/components/modals/IgnoringModalBodyLayout';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useIgnorePageIssueCategoryOnSpecificPagesMutation,
	useIgnorePageIssueOnSpecificPagesMutation,
	useUnignorePageIssueCategoryOnSpecificPagesMutation,
	useUnignorePageIssueOnSpecificPagesMutation,
} from './IgnoreOnPage.gql';

import useTrackIgnoringUpdateInPendo from '~/hooks/useTrackIgnoringUpdateInPendo';

import {
	type IssueCategoryName,
	type IssueName,
} from '~/model/issuesNew';



const messages = defineMessages({
	descriptionDefault: {
		id: 'ui.issueDetail.ignoringModal.scope.page.ignore.description',
	},
	descriptionIgnored: {
		id: 'ui.issueDetail.ignoringModal.scope.page.ignoringActive.description',
	},
	ignoreButton: {
		id: 'ui.issueDetail.ignoringModal.scope.page.ignore.button',
	},
	ignoredAlreadyOnWebsiteDescription: {
		id: 'ui.issueDetail.ignoringModal.scope.general.ignoredAlreadyOnWebsite.description',
	},
	stopIgnoringButton: {
		id: 'ui.issueDetail.ignoringModal.scope.general.button.stopIgnoring',
	},
});

const alertWarningMessages = defineMessages({
	default: {
		id: 'ui.issueDetail.ignoringModal.scope.page.ignore.bulletList.alerts',
	},
	ignored: {
		id: 'ui.issueDetail.ignoringModal.scope.page.ignoringActive.bulletList.alerts',
	},
});

const reportsWarningMessages = defineMessages({
	default: {
		id: 'ui.issueDetail.ignoringModal.scope.page.ignore.bulletList.reports',
	},
	ignored: {
		id: 'ui.issueDetail.ignoringModal.scope.page.ignoringActive.bulletList.reports',
	},
});



type Props = {
	isIgnoredOnPage: boolean,
	isIgnoredOnWebsite: boolean,
	issueCategoryName: IssueCategoryName,
	issueName?: IssueName,
	numberOfIssues: number,
	reloadCallback: () => Promise<void>,
	url: string | null,
	websiteId: CK.WebsiteId,
};

const IgnoreOnPage: React.FC<Props> = (props) => {
	const {
		isIgnoredOnPage,
		isIgnoredOnWebsite,
		issueName = null,
		issueCategoryName,
		numberOfIssues,
		reloadCallback,
		url,
		websiteId,
	} = props;

	const trackIgnoringUpdateInPendo = useTrackIgnoringUpdateInPendo();

	const [ignorePageIssueCategoryOnSpecificPages] = useIgnorePageIssueCategoryOnSpecificPagesMutation();
	const [ignorePageIssueOnSpecificPages] = useIgnorePageIssueOnSpecificPagesMutation();
	const [unignorePageIssueCategoryOnSpecificPages] = useUnignorePageIssueCategoryOnSpecificPagesMutation();
	const [unignorePageIssueOnSpecificPages] = useUnignorePageIssueOnSpecificPagesMutation();

	const handleSubmit = React.useCallback(
		async () => {
			if (url === null) {
				throw new Error(
					`Property url can't be null in handleSubmit`,
				);
			}

			if (isIgnoredOnPage) {
				if (issueName !== null) {
					await unignorePageIssueOnSpecificPages({
						variables: {
							issueName,
							urls: [url],
							websiteId,
						},
					});
				} else {
					await unignorePageIssueCategoryOnSpecificPages({
						variables: {
							issueCategoryName,
							urls: [url],
							websiteId,
						},
					});
				}
			} else {
				if (issueName !== null) {
					await ignorePageIssueOnSpecificPages({
						variables: {
							issueName,
							urls: [url],
							websiteId,
						},
					});
				} else {
					await ignorePageIssueCategoryOnSpecificPages({
						variables: {
							issueCategoryName,
							urls: [url],
							websiteId,
						},
					});
				}
			}

			trackIgnoringUpdateInPendo(
				websiteId,
				issueName ?? issueCategoryName,
				isIgnoredOnPage === false,
				'page',
			);

			await reloadCallback();
		},
		[
			ignorePageIssueCategoryOnSpecificPages,
			ignorePageIssueOnSpecificPages,
			isIgnoredOnPage,
			issueCategoryName,
			issueName,
			reloadCallback,
			trackIgnoringUpdateInPendo,
			unignorePageIssueCategoryOnSpecificPages,
			unignorePageIssueOnSpecificPages,
			url,
			websiteId,
		],
	);

	if (isIgnoredOnWebsite) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					<FormattedMessage
						{...messages.ignoredAlreadyOnWebsiteDescription}
						values={{
							count__issues: numberOfIssues,
						}}
					/>
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	const flag = isIgnoredOnPage ? 'ignored' : 'default';

	return (
		<Form
			defaultDataHasChanged={true}
			onSuccess={handleSubmit}
		>
			<IgnoringModalBodyLayout
				button={(
					<SubmitButton>
						{isIgnoredOnPage ? (
							<FormattedMessage {...messages.stopIgnoringButton} />
						) : (
							<FormattedMessage {...messages.ignoreButton} />
						)}
					</SubmitButton>
				)}
			>
				{isIgnoredOnPage ? (
					<IgnoringMessage>
						<Copy
							{...messages.descriptionIgnored}
							values={{
								count__issues: numberOfIssues,
							}}
						/>
					</IgnoringMessage>
				) : (
					<RichText>
						<Copy
							{...messages.descriptionDefault}
							values={{
								count__issues: numberOfIssues,
							}}
						/>
					</RichText>
				)}

				<RichText>
					<ul>
						<li>
							<FormattedMessage
								{...alertWarningMessages[flag]}
								values={{
									count__issues: numberOfIssues,
								}}
							/>
						</li>
						<li>
							<FormattedMessage
								{...reportsWarningMessages[flag]}
								values={{
									count__issues: numberOfIssues,
								}}
							/>
						</li>
					</ul>
				</RichText>
			</IgnoringModalBodyLayout>
		</Form>
	);
};



export default IgnoreOnPage;
