import React from 'react';

import useFormContext from '~/hooks/useFormContext';
import usePrevious from '~/hooks/usePrevious';

import {
	isFieldRule,
	isFieldsRule,
} from '~/utilities/validations';



function useFieldStatus({
	allowError,
	allowOk,
	focusFieldErrorMessage,
	name,
	showErrorMessageOnFocus,
}: {
	allowError: boolean,
	allowOk: boolean,
	focusFieldErrorMessage?: string,
	name: string,
	showErrorMessageOnFocus: boolean,
}) {
	const formContext = useFormContext();

	const formContextClickInProgress = formContext.clickInProgress;
	const formContextFocused = formContext.focused;

	const prevClickInProgress = usePrevious(formContext.clickInProgress);
	const prevFocused = usePrevious(formContext.focused);

	// this state prevents showing error message when doing blur event
	const [blurInProgress, setBlurInProgress] = React.useState<boolean>(false);

	const fieldError = formContext.errors[name];
	const fieldOk = formContext.ok[name];

	React.useEffect(
		() => {
			if (fieldError && prevFocused && !formContextFocused) {
				if (focusFieldErrorMessage) {
					if (focusFieldErrorMessage !== prevFocused) {
						if (formContextClickInProgress) {
							setBlurInProgress(true);
						}
					}
				} else if (isFieldRule(fieldError.validation)) {
					if (fieldError.validation.field !== prevFocused) {
						if (formContextClickInProgress) {
							setBlurInProgress(true);
						}
					}
				} else if (isFieldsRule(fieldError.validation)) {
					if (!fieldError.validation.fields.includes(prevFocused)) {
						if (formContextClickInProgress) {
							setBlurInProgress(true);
						}
					}
				}
			}

			if (blurInProgress && prevClickInProgress && !formContextClickInProgress) {
				setBlurInProgress(false);
			}
		},
		[
			blurInProgress,
			fieldError,
			focusFieldErrorMessage,
			formContextClickInProgress,
			formContextFocused,
			prevClickInProgress,
			prevFocused,
		],
	);

	let errorMessage: React.ReactNode;
	let showError: boolean = false;
	let showOk: boolean = false;

	if (allowError && fieldError) {
		showError = true;

		if (isFieldRule(fieldError.validation)) {
			if (!formContext.interacted[fieldError.validation.field]) {
				showError = false;
			}
		} else if (isFieldsRule(fieldError.validation)) {
			let isFullyInteracted = true;
			for (const validationField of fieldError.validation.fields) {
				if (formContext.interacted[validationField] === undefined) {
					isFullyInteracted = false;
					break;
				}
			}

			if (!isFullyInteracted) {
				showError = false;
			}
		}

		if (showError) {
			errorMessage = fieldError.message;

			if (formContext.focused) {
				if (focusFieldErrorMessage) {
					if (focusFieldErrorMessage !== formContext.focused) {
						errorMessage = false;
					}
				} else if (isFieldRule(fieldError.validation)) {
					if (fieldError.validation.field !== formContext.focused) {
						errorMessage = false;
					}
				} else if (isFieldsRule(fieldError.validation)) {
					if (!fieldError.validation.fields.includes(formContext.focused)) {
						errorMessage = false;
					}
				}
			} else if (showErrorMessageOnFocus) {
				errorMessage = false;
			}
		} else {
			errorMessage = false;
		}

		showOk = false;
	} else {
		if (allowOk && fieldOk) {
			showOk = true;

			if (isFieldRule(fieldOk.validation)) {
				if (!formContext.interacted[fieldOk.validation.field]) {
					showOk = false;
				}
			} else if (isFieldsRule(fieldOk.validation)) {
				let isFullyInteracted = true;
				for (const validationField of fieldOk.validation.fields) {
					if (formContext.interacted[validationField] === undefined) {
						isFullyInteracted = false;
						break;
					}
				}

				if (!isFullyInteracted) {
					showOk = false;
				}
			}
		}

		showError = false;
		errorMessage = false;
	}

	if (blurInProgress && !formContext.focused) {
		errorMessage = false;
	}

	return {
		errorMessage,
		showError,
		showOk,
	};
}



export default useFieldStatus;
