import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	defineMessages,
	injectIntl,
} from 'react-intl';

import ChartOverlay from '~/components/patterns/charts/components/ChartOverlay';



const messages = defineMessages({
	notEnoughDataMessage: {
		id: 'ui.dashboard.kpi.graphs.notEnoughData.message',
	},
	pausedMessage: {
		id: 'ui.dashboard.kpi.health.paused.message',
	},
});



export const GLOBAL_CHART_OVERLAY_DISABLED = 'GLOBAL_CHART_OVERLAY_DISABLED';
export const GLOBAL_CHART_OVERLAY_NOT_ENOUGH_DATA = 'GLOBAL_CHART_OVERLAY_NOT_ENOUGH_DATA';



class GlobalChartOverlay extends Component {

	render() {
		const {
			intl,
			overlayStyle,
		} = this.props;

		if (overlayStyle === GLOBAL_CHART_OVERLAY_DISABLED) {
			return (
				<ChartOverlay
					text={intl.formatMessage(messages.pausedMessage)}
				/>
			);
		}

		if (overlayStyle === GLOBAL_CHART_OVERLAY_NOT_ENOUGH_DATA) {
			return (
				<ChartOverlay
					text={intl.formatMessage(messages.notEnoughDataMessage)}
				/>
			);
		}

		return false;
	}

}

GlobalChartOverlay.propTypes = {
	overlayStyle: PropTypes.oneOf([
		GLOBAL_CHART_OVERLAY_DISABLED,
		GLOBAL_CHART_OVERLAY_NOT_ENOUGH_DATA,
	]),
};



export default injectIntl(GlobalChartOverlay);
