import React from 'react';
import {
	useSelector,
} from 'react-redux';

import useAffectedLinksFilter from '~/hooks/useAffectedLinksFilter';
import useCountAffectedLinks from '~/hooks/useCountAffectedLinks';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ExportType,
	createExportViaApi,
} from '~/model/exports';

import {
	sortBySelector,
} from '~/state/affectedLinks/selectors';

import {
	issuesOverviewIssueSelector,
} from '~/state/ui/content/selectors';



function useExportAffectedLinks() {
	const issueName = useSelector(issuesOverviewIssueSelector);
	const websiteId = useWebsiteId();

	const affectedLinksCount = useCountAffectedLinks(issueName, websiteId);
	const filter = useAffectedLinksFilter(issueName);
	const sortBy = useSelector(sortBySelector);

	return React.useCallback(
		() => {
			return async () => {
				const response = await createExportViaApi(
					websiteId,
					ExportType.AffectedLinks,
					{
						filter,
						sortBy,
					},
				);

				return ({
					exportData: response.export,
					isLarge: affectedLinksCount.total >= 200000,
				});
			};
		},
		[
			affectedLinksCount,
			filter,
			sortBy,
			websiteId,
		],
	);
}



export default useExportAffectedLinks;
