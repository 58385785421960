/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiateAcceptInvitationWithTwoFactorMutationVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type InitiateAcceptInvitationWithTwoFactorMutation = { readonly __typename?: 'Mutation', readonly InitiateAcceptInvitationWithTwoFactorAuthenticationSetup: { readonly __typename?: 'InitiateAcceptInvitationWithTwoFactorAuthenticationSetupResult', readonly email: string, readonly qrcode: string, readonly secretKey: string } };

export type AcceptInvitationWithTwoFactorMutationVariables = GraphQL.Exact<{
  code: GraphQL.Scalars['String']['input'];
}>;


export type AcceptInvitationWithTwoFactorMutation = { readonly __typename?: 'Mutation', readonly AcceptInvitationWithTwoFactorAuthenticationSetup: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const InitiateAcceptInvitationWithTwoFactorDocument = gql`
    mutation InitiateAcceptInvitationWithTwoFactor {
  InitiateAcceptInvitationWithTwoFactorAuthenticationSetup {
    email
    qrcode
    secretKey
  }
}
    `;
export type InitiateAcceptInvitationWithTwoFactorMutationFn = Apollo.MutationFunction<InitiateAcceptInvitationWithTwoFactorMutation, InitiateAcceptInvitationWithTwoFactorMutationVariables>;

/**
 * __useInitiateAcceptInvitationWithTwoFactorMutation__
 *
 * To run a mutation, you first call `useInitiateAcceptInvitationWithTwoFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateAcceptInvitationWithTwoFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateAcceptInvitationWithTwoFactorMutation, { data, loading, error }] = useInitiateAcceptInvitationWithTwoFactorMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitiateAcceptInvitationWithTwoFactorMutation(baseOptions?: Apollo.MutationHookOptions<InitiateAcceptInvitationWithTwoFactorMutation, InitiateAcceptInvitationWithTwoFactorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateAcceptInvitationWithTwoFactorMutation, InitiateAcceptInvitationWithTwoFactorMutationVariables>(InitiateAcceptInvitationWithTwoFactorDocument, options);
      }
export type InitiateAcceptInvitationWithTwoFactorMutationHookResult = ReturnType<typeof useInitiateAcceptInvitationWithTwoFactorMutation>;
export type InitiateAcceptInvitationWithTwoFactorMutationResult = Apollo.MutationResult<InitiateAcceptInvitationWithTwoFactorMutation>;
export type InitiateAcceptInvitationWithTwoFactorMutationOptions = Apollo.BaseMutationOptions<InitiateAcceptInvitationWithTwoFactorMutation, InitiateAcceptInvitationWithTwoFactorMutationVariables>;
export const AcceptInvitationWithTwoFactorDocument = gql`
    mutation AcceptInvitationWithTwoFactor($code: String!) {
  AcceptInvitationWithTwoFactorAuthenticationSetup(code: $code) {
    query {
      ping
    }
  }
}
    `;
export type AcceptInvitationWithTwoFactorMutationFn = Apollo.MutationFunction<AcceptInvitationWithTwoFactorMutation, AcceptInvitationWithTwoFactorMutationVariables>;

/**
 * __useAcceptInvitationWithTwoFactorMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationWithTwoFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationWithTwoFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationWithTwoFactorMutation, { data, loading, error }] = useAcceptInvitationWithTwoFactorMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAcceptInvitationWithTwoFactorMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationWithTwoFactorMutation, AcceptInvitationWithTwoFactorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationWithTwoFactorMutation, AcceptInvitationWithTwoFactorMutationVariables>(AcceptInvitationWithTwoFactorDocument, options);
      }
export type AcceptInvitationWithTwoFactorMutationHookResult = ReturnType<typeof useAcceptInvitationWithTwoFactorMutation>;
export type AcceptInvitationWithTwoFactorMutationResult = Apollo.MutationResult<AcceptInvitationWithTwoFactorMutation>;
export type AcceptInvitationWithTwoFactorMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationWithTwoFactorMutation, AcceptInvitationWithTwoFactorMutationVariables>;