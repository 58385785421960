import React from 'react';

import DatatableBodyCell, {
	DatatableBodyCellAlignment,
	DatatableBodyCellColorStyle,
	type DatatableBodyCellOnClickInput,
	DatatableBodyCellPadding,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';



type Props = {
	children: React.ReactNode,
	columnIndex: number,
	height: number,
	inactive: boolean,
	isBlank: boolean,
	isInHighlightedColumn: boolean,
	isInHighlightedRow: boolean,
	isSticky: boolean,
	left: number,
	onClick: ((
		input: { rowIndex: number },
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => void) | undefined,
	onContextMenu: ((
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		input: {
			columnIndex: number,
			rowIndex: number,
		},
	) => void) | undefined,
	onMouseEnter: ((input: {
		rowIndex: number,
	}) => void) | undefined,
	onMouseLeave: ((input: {
		rowIndex: number,
	}) => void) | undefined,
	rowIndex: number,
	style: DatatableBodyCellColorStyle | null,
	top: number,
	width: number,
};

const DataCell: React.FC<Props> = (props) => {
	const {
		children,
		columnIndex,
		height,
		inactive,
		isBlank,
		isInHighlightedColumn,
		isInHighlightedRow,
		isSticky,
		left,
		onClick,
		onContextMenu,
		onMouseEnter,
		onMouseLeave,
		rowIndex,
		style,
		top,
		width,
	} = props;

	const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

	function handleClick({ event, rowIndex }: DatatableBodyCellOnClickInput) {
		if (onClick) {
			event.preventDefault();

			onClick({
				rowIndex,
			}, event);
		}
	}

	function handleContextMenu(event) {
		if (onContextMenu) {
			onContextMenu(event, {
				columnIndex,
				rowIndex,
			});
		}
	}

	function handleMouseEnter() {
		if (onMouseEnter) {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
				timeoutRef.current = null;
			}

			timeoutRef.current = setTimeout(
				() => {
					timeoutRef.current = null;

					onMouseEnter({
						rowIndex,
					});
				},
				1,
			);
		}
	}

	function handleMouseLeave() {
		if (onMouseLeave) {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
				timeoutRef.current = null;
			}

			onMouseLeave({
				rowIndex,
			});
		}
	}

	const cssStyle: React.CSSProperties = {
		height,
		left: 0,
		position: 'absolute',
		top: 0,
		transform: `translateX(${left}px) translateY(${top}px)`,
		width,
	};

	return (
		<DatatableBodyCell
			colorStyle={style ?? undefined}
			cssStyle={cssStyle}
			inactive={inactive}
			isInHighlightedColumn={isInHighlightedColumn}
			isInHighlightedRow={isInHighlightedRow}
			onClickCallback={onClick && handleClick}
			onContextMenuCallback={handleContextMenu}
			onMouseEnterCallback={handleMouseEnter}
			onMouseLeaveCallback={handleMouseLeave}
			padding={isBlank ? DatatableBodyCellPadding.NoPadding : DatatableBodyCellPadding.Default}
			rowIndex={rowIndex}
			separator={columnIndex !== 0 && isBlank === false}
			sticky={isSticky}
		>
			{children}
		</DatatableBodyCell>
	);
};



export default React.memo(DataCell);

export {
	DatatableBodyCellAlignment as DataCellAlignment,
	DatatableBodyCellColorStyle as DataCellColorStyle,
};
