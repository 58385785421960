import React from 'react';



type Props = {
	children: React.ReactNode,
	link: React.ReactNode,
};



const IssueAffectedPagesBar: React.FC<Props> = (props) => {
	const {
		children,
		link,
	} = props;

	return (
		<div className="issue-affected-pages-bar">
			<span className="issue-affected-pages-bar__text">
				{children}
			</span>

			<span className="issue-affected-pages-bar__link">
				{link}
			</span>
		</div>
	);
};



export default IssueAffectedPagesBar;
