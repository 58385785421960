import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import CustomElementForm from './CustomElementForm';
import CustomElementTester from '~/components/app/CustomElementTester';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import MarginsList, {
	SIZE_LARGE as LIST_SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import ModalContainer from '../../atoms/modals/parts/ModalContainer';
import ModalContentWithSidebar from '../../atoms/modals/parts/ModalContentWithSidebar';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import OfferSetupService from './OfferSetupService';
import PlaceholderCustomElementForm from './PlaceholderCustomElementForm';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';

import {
	useCreateCustomElementMutation,
	useEditCustomElementMutation,
} from './Modal.gql';

import useAccountCustomElementsLimit from '~/hooks/useAccountCustomElementsLimit';
import useAccountId from '~/hooks/useAccountId';
import useAccountPotentialMaximumCustomElementsLimit from '~/hooks/useAccountPotentialMaximumCustomElementsLimit';
import useAccountTariff from '~/hooks/useAccountTariff';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsDomTracked from '~/hooks/useWebsiteIsDomTracked';

import {
	DEFAULT_EXTRACTION_SETUP,
	type Extraction,
	detectDataType,
	doesTrackingHaveToBeRestarted,
} from '~/model/customElements';



const messages = defineMessages({
	createTitleMainPart: {
		id: 'ui.customElements.createModal.title.mainPart',
	},
	createTitlePrefix: {
		id: 'ui.customElements.createModal.title.prefix',
	},
	editTitleMainPart: {
		id: 'ui.customElements.editModal.title.mainPart',
	},
	editTitlePrefix: {
		id: 'ui.customElements.editModal.title.prefix',
	},
});



const Modal = (props) => {
	const {
		closeCallback,
	} = props;

	const accountId = useAccountId();
	const websiteId = useWebsiteId();

	const accountTariff = useAccountTariff(accountId);
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const customElementsLimit = useAccountCustomElementsLimit(accountId);
	const isDomTracked = useWebsiteIsDomTracked(websiteId);
	const maximumCustomElementsLimit = useAccountPotentialMaximumCustomElementsLimit() ?? 0;
	const urlState = useUrlState();

	const [createCustomElement] = useCreateCustomElementMutation();
	const [editCustomElement] = useEditCustomElementMutation();

	const editedCustomElement = urlState.params.customElementName
		? customElementDefinitions.getByName(urlState.params.customElementName)
		: null;

	const defaultExtraction = editedCustomElement !== null
		? (editedCustomElement.extraction as Extraction)
		: cloneDeep(DEFAULT_EXTRACTION_SETUP);

	const [testedExtraction, setTestedExtraction] = React.useState({
		extraction: defaultExtraction,
		isValid: false,
	});

	const handleChange = React.useCallback(
		(isValid, extraction) => {
			setTestedExtraction({
				extraction,
				isValid,
			});
		},
		[],
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			const editedCustomElement = urlState.params.customElementName
				? customElementDefinitions.getByName(urlState.params.customElementName)
				: null;

			if (editedCustomElement !== null) {
				const dataType = detectDataType(values.extraction);

				await editCustomElement({
					variables: {
						extraction: values.extraction,
						label: values.label,
						name: editedCustomElement.name,
						restartTracking: (
							doesTrackingHaveToBeRestarted(editedCustomElement.dataType, dataType)
							|| values.restartTracking
							|| false
						),
						websiteId,
					},
				});
			} else {
				await createCustomElement({
					variables: {
						extraction: values.extraction,
						label: values.label,
						websiteId,
					},
				});
			}

			closeCallback();
		},
		[
			closeCallback,
			createCustomElement,
			customElementDefinitions,
			editCustomElement,
			urlState,
			websiteId,
		],
	);

	if (customElementDefinitions.isLoaded === false) {
		return null;
	}

	// When creating a new custom element for a website where dom is being
	// tracked, we want to set the default extraction source to `dom`.
	if (editedCustomElement === null && isDomTracked) {
		defaultExtraction.source = 'dom';
	}

	const isPlanUpgradeNeeded = (
		!urlState.params.customElementName
		&& customElementsLimit !== null
		&& customElementDefinitions.count >= customElementsLimit
		&& customElementsLimit < maximumCustomElementsLimit
	);

	if (isPlanUpgradeNeeded) {
		let desiredFeature;

		if (accountTariff === 'v2') {
			desiredFeature = GraphQL.AccountFeature.CustomElements_2;
		} else if (accountTariff === 'v3') {
			desiredFeature = GraphQL.AccountFeature.CustomElements_5;
		} else {
			desiredFeature = GraphQL.AccountFeature.CustomElements_10;
		}

		return (
			<PremiumFeatureSituation
				featureName={desiredFeature}
				hideIfUnattainable={true}
				style={PremiumFeatureSituationStyle.Box}
			>
				{({ premiumAnnotation }) => (
					<ModalContainer
						gapsSize={0}
						header={(
							<ModalHeader
								iconType={ModalHeaderIconType.CogWheel}
								title={(
									<FormattedMessage {...messages.createTitlePrefix} />
								)}
								titleValue={(
									<FormattedMessage {...messages.editTitleMainPart} />
								)}
							/>
						)}
						headerGapsSize={1}
					>
						<ModalContentWithSidebar
							disabledSidebar={true}
							fullheight={true}
							gapsSize={2}
							sidebar={(
								<CustomElementTester
									extraction={testedExtraction.extraction}
									isValid={false}
								/>
							)}
						>
							<List size={ListSize.XLarge}>
								{premiumAnnotation}

								<PlaceholderCustomElementForm />
							</List>
						</ModalContentWithSidebar>
					</ModalContainer>
				)}
			</PremiumFeatureSituation>
		);
	}

	return (
		<ModalContainer
			gapsSize={0}
			header={(
				<ModalHeader
					iconType={ModalHeaderIconType.CogWheel}
					title={(
						<FormattedMessage {...messages[editedCustomElement !== null ? 'editTitlePrefix' : 'createTitlePrefix']} />
					)}
					titleValue={(
						<FormattedMessage {...messages[editedCustomElement !== null ? 'editTitleMainPart' : 'createTitleMainPart']} />
					)}
				/>
			)}
			headerGapsSize={1}
		>
			<ModalContentWithSidebar
				disabledSidebar={isPlanUpgradeNeeded}
				fullheight={true}
				gapsSize={2}
				sidebar={(
					<MarginsList size={LIST_SIZE_LARGE}>
						{editedCustomElement === null && <OfferSetupService />}

						<CustomElementTester
							extraction={testedExtraction.extraction}
							isValid={testedExtraction.isValid}
						/>
					</MarginsList>
				)}
			>
				<List size={ListSize.XLarge}>
					<CustomElementForm
						defaultExtraction={defaultExtraction}
						defaultLabel={editedCustomElement?.label ?? ''}
						editedCustomElement={editedCustomElement}
						onChangeCallback={handleChange}
						onSubmitCallback={handleSubmit}
						showSourceSelect={isDomTracked ?? false}
						websiteId={websiteId}
					/>
				</List>
			</ModalContentWithSidebar>
		</ModalContainer>
	);
};



export default Modal;
