import React from 'react';
import {
	useSelector,
} from 'react-redux';

import AffectedLinksDatatable from '~/components/logic/affectedLinks/AffectedLinksDatatable';
import Header from '~/components/logic/affectedLinks/Header';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';

import useCountAffectedLinks from '~/hooks/useCountAffectedLinks';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	isPageIssueWithAffectedLinks,
} from '~/model/issuesNew';

import {
	issuesOverviewIssueSelector,
} from '~/state/ui/content/selectors';



type Props = {
	backlink: any,
	closeCallback: () => void,
};

const AffectedLinksScreen: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
	} = props;

	const issueName = useSelector(issuesOverviewIssueSelector);
	const websiteId = useWebsiteId();

	const affectedLinksCount = useCountAffectedLinks(issueName, websiteId);

	if (!issueName || !isPageIssueWithAffectedLinks(issueName)) {
		return null;
	}

	return (
		<ScreenLayout
			header={(
				<Header
					backlinkCallback={closeCallback}
					backlinkLabel={backlink}
					issueName={issueName}
					numberOfAffectedLinks={affectedLinksCount.total}
				/>
			)}
		>
			<AffectedLinksDatatable
				issueName={issueName}
			/>
		</ScreenLayout>
	);
};



export default AffectedLinksScreen;
