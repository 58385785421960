/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RobotsTxtComparisonSidebarQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type RobotsTxtComparisonSidebarQuery = { readonly __typename?: 'Query', readonly latest: { readonly __typename?: 'RobotsTxtRevision', readonly id: CK.ID } | null, readonly list: ReadonlyArray<{ readonly __typename?: 'RobotsTxtRevision', readonly id: CK.ID, readonly createdAt: CK.Timestamp, readonly isContentEmpty: boolean | null, readonly responseFailureReason: GraphQL.FetchingFailureReason | null, readonly responseStatusCode: number | null }> | null };


export const RobotsTxtComparisonSidebarDocument = gql`
    query RobotsTxtComparisonSidebar($websiteId: WebsiteId!) {
  latest: robotsTxtRevisionLatest(websiteId: $websiteId) {
    id
  }
  list: robotsTxtRevisionsList(websiteId: $websiteId) {
    id
    createdAt
    isContentEmpty
    responseFailureReason
    responseStatusCode
  }
}
    `;

/**
 * __useRobotsTxtComparisonSidebarQuery__
 *
 * To run a query within a React component, call `useRobotsTxtComparisonSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useRobotsTxtComparisonSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRobotsTxtComparisonSidebarQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useRobotsTxtComparisonSidebarQuery(baseOptions: Apollo.QueryHookOptions<RobotsTxtComparisonSidebarQuery, RobotsTxtComparisonSidebarQueryVariables> & ({ variables: RobotsTxtComparisonSidebarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RobotsTxtComparisonSidebarQuery, RobotsTxtComparisonSidebarQueryVariables>(RobotsTxtComparisonSidebarDocument, options);
      }
export function useRobotsTxtComparisonSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RobotsTxtComparisonSidebarQuery, RobotsTxtComparisonSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RobotsTxtComparisonSidebarQuery, RobotsTxtComparisonSidebarQueryVariables>(RobotsTxtComparisonSidebarDocument, options);
        }
export function useRobotsTxtComparisonSidebarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RobotsTxtComparisonSidebarQuery, RobotsTxtComparisonSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RobotsTxtComparisonSidebarQuery, RobotsTxtComparisonSidebarQueryVariables>(RobotsTxtComparisonSidebarDocument, options);
        }
export type RobotsTxtComparisonSidebarQueryHookResult = ReturnType<typeof useRobotsTxtComparisonSidebarQuery>;
export type RobotsTxtComparisonSidebarLazyQueryHookResult = ReturnType<typeof useRobotsTxtComparisonSidebarLazyQuery>;
export type RobotsTxtComparisonSidebarSuspenseQueryHookResult = ReturnType<typeof useRobotsTxtComparisonSidebarSuspenseQuery>;
export type RobotsTxtComparisonSidebarQueryResult = Apollo.QueryResult<RobotsTxtComparisonSidebarQuery, RobotsTxtComparisonSidebarQueryVariables>;