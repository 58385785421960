import React from 'react';

import {
	type WidgetType,
} from '~/model/dashboard';



type Props = {
	children?: React.ReactNode,
	type: WidgetType,
};

const WidgetCell: React.FC<Props> = (props) => {
	const {
		children,
		type,
	} = props;

	return (
		<div
			className={`widget-grid-cell widget-grid-cell--${type}`}
		>
			{children}
		</div>
	);
};



export default WidgetCell;
