/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientConnectionsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type ClientConnectionsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly clientConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessClientConnection', readonly clientAccountId: CK.AccountId, readonly clientAccountName: string, readonly id: any, readonly inviterEmail: string | null, readonly isInitiatedByAgency: boolean, readonly isPending: boolean, readonly level: GraphQL.AccountAccessConnectionLevel }> } | null };


export const ClientConnectionsDocument = gql`
    query ClientConnections($accountId: AccountId!) {
  account(id: $accountId) {
    clientConnections {
      clientAccountId
      clientAccountName
      id
      inviterEmail
      isInitiatedByAgency
      isPending
      level
    }
    id
  }
}
    `;

/**
 * __useClientConnectionsQuery__
 *
 * To run a query within a React component, call `useClientConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientConnectionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useClientConnectionsQuery(baseOptions: Apollo.QueryHookOptions<ClientConnectionsQuery, ClientConnectionsQueryVariables> & ({ variables: ClientConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientConnectionsQuery, ClientConnectionsQueryVariables>(ClientConnectionsDocument, options);
      }
export function useClientConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientConnectionsQuery, ClientConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientConnectionsQuery, ClientConnectionsQueryVariables>(ClientConnectionsDocument, options);
        }
export function useClientConnectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientConnectionsQuery, ClientConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientConnectionsQuery, ClientConnectionsQueryVariables>(ClientConnectionsDocument, options);
        }
export type ClientConnectionsQueryHookResult = ReturnType<typeof useClientConnectionsQuery>;
export type ClientConnectionsLazyQueryHookResult = ReturnType<typeof useClientConnectionsLazyQuery>;
export type ClientConnectionsSuspenseQueryHookResult = ReturnType<typeof useClientConnectionsSuspenseQuery>;
export type ClientConnectionsQueryResult = Apollo.QueryResult<ClientConnectionsQuery, ClientConnectionsQueryVariables>;