import Immutable from 'immutable';

import {
	ACTION_CLEAR_FLASH_MESSAGE,
	ACTION_SET_FLASH_MESSAGE,
} from '~/actions/flashMessage';



function createDefaultState() {
	return null;
}



export function flashMessage(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case ACTION_CLEAR_FLASH_MESSAGE: {
			state = createDefaultState();

			break;
		}



		case ACTION_SET_FLASH_MESSAGE: {
			const {
				message,
				messageVariables,
				route,
				style,
			} = action;

			state = Immutable.Map({
				message,
				messageVariables,
				route,
				style,
			});

			break;
		}

	}

	return state;
}
