import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Emphasis from '~/components/patterns/typography/Emphasis';
import Measurer from '~/utilities/Measurer';
import NativeAlertTypeName from '~/components/logic/alerts/NativeAlertTypeName';
import SelectableRowsTable from '~/components/logic/datatables/SelectableRowsTable';
import Ellipsis from '~/components/patterns/values/Ellipsis';

import useAlertTypeDefinitions from '~/hooks/useAlertTypeDefinitions';
import useFormContext from '~/hooks/useFormContext';
import useWebsiteAlertDefinitions from '~/hooks/useWebsiteAlertDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ALERT_GROUP_LIGHTHOUSE_ALERTS,
	ALERT_GROUP_PAGE_ALERTS,
	ALERT_GROUP_PLATFORM_ALERTS,
	LIST_OF_LIGHTHOUSE_ALERT_TYPES,
	LIST_OF_PAGE_ALERT_TYPES,
	LIST_OF_PLATFORM_ALERT_TYPES,
	getSortByType,
} from '~/model/alerts';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import sortArrayByProperty from '~/utilities/sortArrayByProperty';



const messages = defineMessages({
	alreadyCreatedDefinitions: {
		id: 'ui.alertsConfiguration.modal.create.alreadyCreatedDefinitions',
	},
	alreadyExists: {
		id: 'ui.alertsConfiguration.modal.create.alreadyExists',
	},
	header: {
		id: 'ui.alertsConfiguration.fields.type',
	},
	tooltip: {
		id: 'ui.alertsConfiguration.modal.create.alreadyExists.tooltip',
	},
});



type Props = {
	alertGroup: string,
	disabled: boolean,
	height: number,
	name: string,
};

const AlertTypesSelectionTableField: React.FC<Props> = (props) => {
	const {
		alertGroup,
		disabled,
		height,
		name,
	} = props;

	const websiteId = useWebsiteId();

	const alertDefinitions = useWebsiteAlertDefinitions(websiteId);
	const alertTypeDefinitions = useAlertTypeDefinitions();
	const formContext = useFormContext();

	const alertTypesList = React.useMemo(
		() => {
			let list: Array<string> = [];

			if (alertGroup === ALERT_GROUP_LIGHTHOUSE_ALERTS) {
				list = LIST_OF_LIGHTHOUSE_ALERT_TYPES.toArray();
			} else if (alertGroup === ALERT_GROUP_PAGE_ALERTS) {
				list = LIST_OF_PAGE_ALERT_TYPES.toArray();
			} else if (alertGroup === ALERT_GROUP_PLATFORM_ALERTS) {
				list = LIST_OF_PLATFORM_ALERT_TYPES.toArray();
			}


			return sortArrayByProperty(
				alertTypeDefinitions.listAll().filter(
					(alertTypeDefinition) => list.includes(alertTypeDefinition.type),
				),
				(alertTypeDefinition) => getSortByType(alertTypeDefinition.type),
			);
		},
		[
			alertGroup,
			alertTypeDefinitions,
		],
	);

	const renderCell = React.useCallback(
		({ columnIndex, rowIndex }) => {
			const alertTypeDefinition = getArrayItemAtSafeIndex(alertTypesList, rowIndex);

			if (columnIndex === 0) {
				return (
					<Ellipsis>
						<NativeAlertTypeName
							alertType={alertTypeDefinition.type}
						/>
					</Ellipsis>
				);
			}

			if (columnIndex === 1 && alertDefinitions.isLoaded) {
				const numberOfExistingDefinitions = alertDefinitions
					.listAll()
					.filter((alertDefinition) => alertDefinition.alertType === alertTypeDefinition.type)
					.length;

				let text;

				if (alertTypeDefinition.hasScope) {
					text = (
						<FormattedMessage
							{...messages.alreadyCreatedDefinitions}
							values={{
								count__definitions: numberOfExistingDefinitions,
							}}
						/>
					);
				}

				if (alertTypeDefinition.hasScope === false) {
					if (numberOfExistingDefinitions > 0) {
						text = (
							<FormattedMessage {...messages.alreadyExists} />
						);
					} else {
						text = (
							<FormattedMessage
								{...messages.alreadyCreatedDefinitions}
								values={{
									count__definitions: numberOfExistingDefinitions,
								}}
							/>
						);
					}
				}

				return (
					<Ellipsis altPopupText={text}>
						<Emphasis>
							{text}
						</Emphasis>
					</Ellipsis>
				);
			}

			return null;
		},
		[
			alertDefinitions,
			alertTypesList,
		],
	);

	const renderHeader = React.useCallback(
		({ columnIndex }) => {
			if (columnIndex === 0) {
				return (
					<FormattedMessage {...messages.header} />
				);
			}

			return null;
		},
		[],
	);

	const defaultSelectedRows = React.useMemo(
		() => formContext.defaultValues[name].map(
			(alertType) => alertTypesList.indexOf(alertType),
		),
		[
			alertTypesList,
			formContext.defaultValues,
			name,
		],
	);

	const disabledRows = React.useMemo(
		() => {
			if (disabled) {
				return alertTypesList.map(
					(alertTypeDefinition, index) => index,
				);
			}

			if (alertDefinitions.isLoaded === false) {
				return [];
			}

			const result: Array<number> = [];

			alertTypesList.forEach((alertTypeDefinition, index) => {
				if (alertTypeDefinition.hasScope) {
					return;
				}

				const alreadyHasDefinitions = alertDefinitions.listAll().some(
					(alertDefinition) => alertDefinition.alertType === alertTypeDefinition.type,
				);

				if (!alreadyHasDefinitions) {
					return;
				}

				result.push(index);
			});

			return result;
		},
		[
			alertDefinitions,
			alertTypesList,
			disabled,
		],
	);

	return (
		<Measurer>
			{({ containerWidth }) => {
				return (
					<SelectableRowsTable
						bodyCellRenderer={renderCell}
						columnCount={2}
						columnWidth={({ index, width }) => {
							if (index === 0) {
								return Math.ceil(width * 0.6);
							}

							if (index === 1) {
								return width - Math.ceil(width * 0.6);
							}

							return 0;
						}}
						defaultSelectedRows={defaultSelectedRows}
						disabledRowExplanationRenderer={() => (
							<FormattedMessage {...messages.tooltip} />
						)}
						disabledRows={disabledRows}
						headerCellRenderer={renderHeader}
						height={height}
						key={containerWidth}
						name={name}
						onSelectionChangeCallback={(value) => {
							formContext.onChangeHandler(
								name,
								value.map((index) => getArrayItemAtSafeIndex(alertTypesList, index).type),
							);
						}}
						rowCount={alertTypesList.length}
						width={containerWidth}
					/>
				);
			}}
		</Measurer>
	);
};



export default AlertTypesSelectionTableField;
