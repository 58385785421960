import type CK from '~/types/contentking';

import {
	useUserUniqueIdQuery,
} from './useUserUniqueId.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserUniqueId(legacyUserId: string | null): CK.UserId | null {
	const { data } = useUserPropertiesQuery(
		useUserUniqueIdQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.uniqueId ?? null;
}



export default useUserUniqueId;
