import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AuthLogo from '~/components/atoms/auth/AuthLogo';
import AuthScreenLayout from '~/components/atoms/auth/AuthScreenLayout';
import AuthTitle from '~/components/atoms/auth/AuthTitle';

import useAuthenticatedSession from '~/hooks/useAuthenticatedSession';



const messages = defineMessages({
	title: {
		id: 'ui.kickstartPage.title',
	},
});



const KickstartPage: React.FC = () => {
	useAuthenticatedSession({
		allowStart: true,
	});

	return (
		<AuthScreenLayout
			logo={(
				<AuthLogo />
			)}
		>
			<AuthTitle>
				<FormattedMessage {...messages.title} />
			</AuthTitle>
		</AuthScreenLayout>
	);
};



export default KickstartPage;
