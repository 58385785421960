import {
	Map,
} from 'immutable';

import {
	ACTION_SET_SELECTED_ALERT_DEFINITIONS,
} from '~/actions/alertsConfiguration';



function createDefaultState() {
	return Map({
		selectedDefinitions: [],
	});
}



export function alertsConfiguration(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case ACTION_SET_SELECTED_ALERT_DEFINITIONS: {
			const {
				selectedDefinitions,
			} = action;

			state = state.set('selectedDefinitions', [...selectedDefinitions]);

			break;
		}

	}

	return state;
}
