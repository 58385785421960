import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AccountDisplayName from '~/components/app/AccountDisplayName';
import Copy from '~/components/logic/Copy';
import DestructConfirmationModal from '~/components/app/DestructConfirmationModal';
import Gravatar from '~/components/patterns/users/Gravatar';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import SmallImageLabel from '~/components/patterns/images/SmallImageLabel';

import {
	useCancelUserInvitationMutation,
} from './CancelInvitationConfirmationModal.gql';

import {
	extractInitials,
} from '~/model/users';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	confirmButton: {
		id: 'ui.cancelInvitationConfirmation.confirmButton',
	},
	description: {
		id: 'ui.cancelInvitationConfirmation.description',
	},
	labelAccount: {
		id: 'ui.cancelInvitationConfirmation.labelAccount',
	},
	labelInvitee: {
		id: 'ui.cancelInvitationConfirmation.labelInvitee',
	},
	title: {
		id: 'ui.cancelInvitationConfirmation.title',
	},
	verb: {
		id: 'ui.cancelInvitationConfirmation.verb',
	},
	warnPermanent: {
		id: 'ui.cancelInvitationConfirmation.warnPermanent',
	},
});



type Props = {
	accountId: CK.AccountId,
	email: string,
};

const CancelInvitationConfirmationModal: React.FC<Props> = (props) => {
	const {
		accountId,
		email,
	} = props;

	const [cancelUserInvitation] = useCancelUserInvitationMutation();

	const handleCancellation = React.useCallback(
		async () => {
			try {
				await cancelUserInvitation({
					variables: {
						accountId,
						email,
					},
				});
			} catch (error) {
				error = FormError.fromApolloError(error);

				if (error.getName() === 'notFoundException') {
					return;
				}

				throw error;
			}
		},
		[
			accountId,
			cancelUserInvitation,
			email,
		],
	);

	return (
		<DestructConfirmationModal
			confirmButton={(
				<FormattedMessage
					{...messages.confirmButton}
					values={{ email }}
				/>
			)}
			description={(
				<FormattedMessage
					{...messages.description}
					values={{ email }}
				/>
			)}
			onConfirmation={handleCancellation}
			titleAction={(
				<FormattedMessage {...messages.verb} />
			)}
			titleObject={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ModalTextSection>
				<Copy {...messages.warnPermanent} />
			</ModalTextSection>

			<LabeledValuesBox
				items={[
					{
						label: <FormattedMessage {...messages.labelInvitee} />,
						value: (
							<SmallImageLabel
								image={(
									<Gravatar
										email={email}
										placeholder={extractInitials(email)}
									/>
								)}
								label={email}
							/>
						),
					},
					{
						label: <FormattedMessage {...messages.labelAccount} />,
						value: (
							<AccountDisplayName accountId={accountId} />
						),
					},
				]}
			/>
		</DestructConfirmationModal>
	);
};



export default CancelInvitationConfirmationModal;
