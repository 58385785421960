import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CancelReversedIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="reset-button--reversed"
					transform="translate(3.000000, 3.000000)"
				>
					<path
						d="M9,18 C13.970563,18 18,13.970563 18,9 C18,4.02943701 13.970563,0 9,0 C4.02943701,0 0,4.02943701 0,9 C0,13.970563 4.02943701,18 9,18 Z"
						fill={color}
						id="button"
						opacity="0.5"
					>
					</path>
					<path
						d="M9.5,8.5 L9.5,4.00123084 C9.5,3.72975063 9.27614237,3.5 9,3.5 C8.73193359,3.5 8.5,3.72440869 8.5,4.00123084 L8.5,8.5 L4.00123084,8.5 C3.72975063,8.5 3.5,8.72385763 3.5,9 C3.5,9.26806641 3.72440869,9.5 4.00123084,9.5 L8.5,9.5 L8.5,13.9987692 C8.5,14.2702494 8.72385763,14.5 9,14.5 C9.26806641,14.5 9.5,14.2755913 9.5,13.9987692 L9.5,9.5 L13.9987692,9.5 C14.2702494,9.5 14.5,9.27614237 14.5,9 C14.5,8.73193359 14.2755913,8.5 13.9987692,8.5 L9.5,8.5 Z"
						fill="#FAFAFA"
						id="crossIcon"
						transform="translate(9.000000, 9.000000) rotate(-45.000000) translate(-9.000000, -9.000000) "
					>
					</path>
					<path
						d="M10,8 L10,4.00247329 C10,3.45576096 9.55228475,3 9,3 C8.44386482,3 8,3.44882258 8,4.00247329 L8,8 L4.00247329,8 C3.45576096,8 3,8.44771525 3,9 C3,9.55613518 3.44882258,10 4.00247329,10 L8,10 L8,13.9975267 C8,14.544239 8.44771525,15 9,15 C9.55613518,15 10,14.5511774 10,13.9975267 L10,10 L13.9975267,10 C14.544239,10 15,9.55228475 15,9 C15,8.44386482 14.5511774,8 13.9975267,8 L10,8 Z"
						fill="#FAFAFA"
						id="crossIcon"
						transform="translate(9.000000, 9.000000) rotate(45.000000) translate(-9.000000, -9.000000) "
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CancelReversedIconBuilder;

