import useNavigation from '~/hooks/useNavigation';



function useNavigate() {
	const navigation = useNavigation();

	return navigation.navigate;
}



export default useNavigate;
