import classNames from 'classnames';
import React from 'react';

import AttachedChartOverlay from '~/components/patterns/charts/structures/AttachedChartOverlay';

import touchSupported from '~/utilities/touchSupported';
import useViewportType from '~/hooks/useViewportType';



type Props = {
	/** Attached flag */
	flag?: React.ReactNode,
	children?: React.ReactNode,
	/** Possibility to disable borders around */
	hasBorders?: boolean,
	/** Additional elements displayed in the header */
	headerElements?: React.ReactNode,
	/** Possibility to limit width of area for control elements */
	headerElementsMaxWidth?: React.CSSProperties['maxWidth'],
	/** Enable wrapping of elements in the header */
	headerWrapping?: boolean,
	/** Show compact size of layout to fit more data into this container */
	isCompact?: boolean,
	/** Chart label */
	label: React.ReactNode,
	/** Link visible when whole component is set as clickable */
	link?: React.ReactNode,
	/** To make box clickable we can set custom onClick callback */
	onClickCallback?: () => void,
	/** Possible overlay of chart */
	overlay?: React.ReactNode,
	/** Timestamp info attached to the bottom right corner of component */
	timestamp?: React.ReactNode,
};



const ChartLayout: React.FC<Props> = (props) => {
	const {
		flag,
		hasBorders = true,
		headerElements,
		headerElementsMaxWidth,
		headerWrapping,
		children,
		isCompact,
		label,
		link,
		onClickCallback,
		overlay,
		timestamp,
	} = props;

	const viewportType = useViewportType();
	const isWholeComponentClickable = touchSupported || viewportType.isSmall;

	const componentClasses = classNames({
		'chart-layout': true,
		'chart-layout--is-clickable': onClickCallback && isWholeComponentClickable,
		'chart-layout--compact-size': isCompact,
		'chart-layout--has-borders': hasBorders,
	});

	const headerClasses = classNames({
		'chart-layout__header': true,
		'chart-layout__header--wrapping': headerWrapping,
	});

	return (
		<div
			className={componentClasses}
			onClick={isWholeComponentClickable ? onClickCallback : undefined}
		>
			<div className={headerClasses}>
				<div className="chart-layout__title-part">
					<h2 className="chart-layout__label">
						{label}
					</h2>
					{link && (
						<span className="chart-layout__click-link">
							{link}
						</span>
					)}
				</div>
				{headerElements && (
					<div
						className="chart-layout__header-elements"
						style={{
							maxWidth: headerElementsMaxWidth,
						}}
					>
						{headerElements}
					</div>
				)}
			</div>
			<div
				className="chart-layout__chart"
				style={{
					['--bg' as any]: '#ffffff',
				}}
			>
				<AttachedChartOverlay overlay={overlay}>
					{children}
				</AttachedChartOverlay>
			</div>
			{(flag || timestamp) && (
				<div className="chart-layout__footer">
					{flag && (
						<div className="chart-layout__flag">
							{flag}
						</div>
					)}
					{timestamp && (
						<div className="chart-layout__timestamp">
							{timestamp}
						</div>
					)}
				</div>
			)}
		</div>
	);
};



export default ChartLayout;
