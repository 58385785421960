import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AbstractSelectField, {
	AbstractSelectFieldSize,
} from '~/components/patterns/forms/fields/AbstractSelectField';
import ActiveFilterLabel from '../../atoms/filters/ActiveFilterLabel';
import ActiveFilterSelectToggler from '../../atoms/filters/ActiveFilterSelectToggler';
import ActiveMobileFilter from '../../atoms/filters/ActiveMobileFilter';
import ActiveFilterSegmentsCTA from './ActiveFilterSegmentsCTA';
import ColumnName from '../../names/ColumnName';
import FilterDropdownFilters from '../../atoms/filters/FilterDropdownFilters';

import useViewportType from '~/hooks/useViewportType';



const COLUMN_NAME_TO_INTL_KEY = {
	currentScore: 'health',
	displayUrl: 'url',
	segment: 'segments',
	state: 'status_code',
	url: 'url',
};

const messages = defineMessages({
	label: {
		columnsAndFilters: {
			id: 'ui.filteringToolbar.active.desktop.columnsAndFilters',
		},
		filters: {
			id: 'ui.filteringToolbar.active.desktop.filters',
			defaultMessage: '{count, number} {count, plural, =1 {filter active} other {filters active}}',
		},
	},
	columnsTitle: {
		id: 'ui.filteringToolbar.title.columns',
	},
	filtersTitle: {
		id: 'ui.filteringToolbar.title.filters',
	},
});



const ActiveFilter = (props) => {
	const {
		activeFilters,
		filter,
		filterNames = {},
		onFilterClickCallback,
		removeFilterCallback,
		segmentsManagement = false,
		selectedColumns,
		sortBy,
		supportsScrollToField = true,
	} = props;

	const viewportType = useViewportType();

	function handleFilterClick(name) {
		if (!onFilterClickCallback) {
			return false;
		}

		onFilterClickCallback(name);
	}

	function handleRemoveFilter(name) {
		removeFilterCallback(name);
	}

	function renderActiveFilters() {
		const output = [];

		activeFilters.forEach((filter, name) => {
			output.push(
				<ActiveFilterLabel
					key={name}
					label={filterNames[name] || (
						<ColumnName
							column={COLUMN_NAME_TO_INTL_KEY[name] || name}
						/>
					)}
					onClickCallback={supportsScrollToField ? () => handleFilterClick(name) : undefined}
					onRemoveClickCallback={() => handleRemoveFilter(name)}
				/>,
			);
		});

		return (
			<FilterDropdownFilters
				title={selectedColumns && selectedColumns.size > 0 && (
					<FormattedMessage {...messages.filtersTitle} />
				)}
			>
				{output}
			</FilterDropdownFilters>
		);
	}

	function renderSelectedColumns() {
		if (!selectedColumns || selectedColumns.size === 0) {
			return null;
		}

		const output = [];

		selectedColumns.forEach((columnName) => {
			output.push(
				<ActiveFilterLabel
					key={columnName}
					label={(
						<ColumnName
							column={COLUMN_NAME_TO_INTL_KEY[columnName] || columnName}
						/>
					)}
					onClickCallback={supportsScrollToField ? () => handleFilterClick(columnName) : undefined}
					onRemoveClickCallback={() => handleRemoveFilter('selected-' + columnName)}
				/>,
			);
		});

		return (
			<FilterDropdownFilters
				title={(
					<FormattedMessage {...messages.columnsTitle} />
				)}
			>
				{output}
			</FilterDropdownFilters>
		);
	}

	function renderInteractiveFilter() {
		if (activeFilters.size === 0 && !selectedColumns) {
			return null;
		}

		return (
			<AbstractSelectField
				dropdownWidth={200}
				hoverable={true}
				label={selectedColumns
					? (
						<FormattedMessage
							{...messages.label.columnsAndFilters}
							values={{
								count__columns: selectedColumns.size,
								count__filters: activeFilters.size,
							}}
						/>
					)
					: (
						<FormattedMessage
							{...messages.label.filters}
							values={{
								count: activeFilters.size,
							}}
						/>
					)
				}
				labelRenderer={(label, isOpen) => (
					<ActiveFilterSelectToggler
						isOpen={isOpen}
						label={label}
					/>
				)}
				size={AbstractSelectFieldSize.Small}
				width={200}
			>
				{renderActiveFilters()}
				{renderSelectedColumns()}

				{segmentsManagement && (
					<ActiveFilterSegmentsCTA
						activeFilters={activeFilters}
						pagesFilter={filter}
						pagesSortBy={sortBy}
					/>
				)}
			</AbstractSelectField>
		);
	}

	function renderMobileFilterTag() {
		if (activeFilters.size === 0) {
			return null;
		}

		return (
			<ActiveMobileFilter
				activeFiltersCount={activeFilters.size}
			/>
		);
	}

	if (viewportType.isSmall || viewportType.isMedium) {
		return renderMobileFilterTag();
	}

	return renderInteractiveFilter();
};



export default ActiveFilter;
