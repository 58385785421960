import classNames from 'classnames';
import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type Props = {
	/** Center aligned content */
	centered?: boolean,
	children?: React.ReactNode,
};



const IncidentBoxFooter: React.FC<Props> = (props) => {
	const {
		centered,
		children,
	} = props;

	const elements = flattenChildren(children).filter(notEmpty);

	const componentClasses = classNames({
		'incident-box-footer': true,
		'incident-box-footer--centered-content': centered,
	});

	return (
		<div className={componentClasses}>
			{elements.map((child, index) => (
				<div
					className="incident-box-footer__item"
					key={'incident-box-footer-item-' + index}
				>
					{child}
				</div>
			))}
		</div>
	);
};



export default IncidentBoxFooter;
