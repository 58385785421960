import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AvailableIconBuilder: IconBuilder = {
	icon: ({ color = '#42CC67' }) => (
		<g
			fill="none"
			fillRule="evenodd"
			id="Page-1"
			stroke="none"
			strokeWidth="1"
		>
			<g
				fill={color}
				id="checklist-bullet"
				transform="translate(2.000000, 5.000000)"
			>
				<path
					d="M9.30344197,11.4824555 L7.97123538,12.814662 C7.13623218,13.6496653 5.76807501,13.6008267 4.99479827,12.7081669 L1.07957606,8.19060283 C0.578981124,7.61268021 0.609505207,6.7464746 1.15012061,6.2058592 C1.69073601,5.66592212 2.55694161,5.63471972 3.13418592,6.13531466 L9.30344197,11.4824555 Z"
					id="Path"
				>
				</path>
				<path
					d="M5.03007054,12.404961 L4.02820235,11.0022099 L15.2780223,0.621308889 C15.9597267,-0.009522135 17.0178949,0.0108272586 17.6745018,0.667434171 C18.3494232,1.34235554 18.3494232,2.43647428 17.6745018,3.11139563 L8.12453411,12.6613633 C7.23594418,13.5506316 5.76061357,13.4285353 5.03007054,12.404961"
					id="Path"
				>
				</path>
			</g>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default AvailableIconBuilder;

