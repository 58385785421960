import React from 'react';

import type GraphQL from '~/types/graphql';

import {
	useSudoModeStatusQuery,
} from '~/hooks/useSudoModeStatus.gql';



type SudoModeStatus = {
	isLoading: true,
	isSudoEnabled: null,
	suggestedMethodToEnter: null,
} | {
	isLoading: false,
	isSudoEnabled: boolean,
	suggestedMethodToEnter: GraphQL.MethodToEnterSudoMode | null,
};

function useSudoModeStatus(
	disallowedMethod: GraphQL.MethodToEnterSudoMode | null = null,
): SudoModeStatus {
	const { loading, data } = useSudoModeStatusQuery({
		fetchPolicy: 'no-cache',
		variables: {
			disallowedMethod,
		},
	});

	return React.useMemo(
		() => {
			if (loading || data === undefined) {
				return {
					isLoading: true,
					isSudoEnabled: null,
					suggestedMethodToEnter: null,
				};
			}

			return {
				isLoading: false,
				isSudoEnabled: data.sudoModeStatus.isSudoEnabled,
				suggestedMethodToEnter: data.sudoModeStatus.suggestedMethodToEnter,
			};
		},
		[
			data,
			loading,
		],
	);
}



export default useSudoModeStatus;
