import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import DisabledContent from '~/components/patterns/content/DisabledContent';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import StaticText from '~/components/atoms/forms/components/StaticText';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



const messages = defineMessages({
	connect: {
		id: 'ui.googleLookerStudio.accountSettings.connect',
	},
	open: {
		id: 'ui.googleLookerStudio.accountSettings.open',
	},
	title: {
		id: 'ui.websites.form.api.gds.title',
	},
});



const GoogleDataStudioRow: React.FC = () => {
	const websiteId = useWebsiteId();
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const gdsIntegrationStatus = websiteIntegrations.getStatus(GraphQL.WebsiteIntegrationType.GoogleDataStudio);

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.ReportingApi}
			hideIfUnattainable={true}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => (
				<FormRow label={<FormattedMessage {...messages.title} />}>
					<DisabledContent
						disabledContent={!isFeatureEnabled}
						disabledOverlay={!isFeatureEnabled && premiumAnnotation}
					>
						<StaticText>
							<ExternalLink
								href="https://datastudio.google.com/datasources/create?connectorId=AKfycbypxu88jN8zJKQvpkpoDcgy-c82trYIQV2WUrYJTzibOLfVy17I3_mMPhKgWO5-6lUV1g"
							>
								{gdsIntegrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled
									? <FormattedMessage {...messages.open} />
									: <FormattedMessage {...messages.connect} />
								}
							</ExternalLink>
						</StaticText>
					</DisabledContent>
				</FormRow>
			)}
		</PremiumFeatureSituation>
	);
};



export default GoogleDataStudioRow;
