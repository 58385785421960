import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const OutOfStockBagIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M21.965,20.467h0l-.9-13A1.574,1.574,0,0,0,19.5,6H17.75a.25.25,0,0,1-.25-.25V5.5a5.5,5.5,0,0,0-11,0v.25A.25.25,0,0,1,6.25,6H4.5A1.574,1.574,0,0,0,2.933,7.463l-.9,13Q2,20.983,2,21.5A2.5,2.5,0,0,0,4.5,24h15A2.5,2.5,0,0,0,22,21.5Q22,20.983,21.965,20.467ZM8,12a1,1,0,1,1-1,1A1,1,0,0,1,8,12Zm-.366,7.082a5.251,5.251,0,0,1,8.731,0,.75.75,0,1,1-1.246.834,3.752,3.752,0,0,0-6.239,0,.75.75,0,0,1-1.246-.835ZM16,14a1,1,0,1,1,1-1A1,1,0,0,1,16,14ZM8.5,5.5a3.5,3.5,0,0,1,7,0v.25a.25.25,0,0,1-.25.25H8.75a.25.25,0,0,1-.25-.25Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default OutOfStockBagIconBuilder;
