import classNames from 'classnames';
import React from 'react';



type Props = {
	label: React.ReactNode,
	onClickCallback?: () => void,
	onRemoveClickCallback?: () => void,
};

const ActiveFilterLabel: React.FC<Props> = (props) => {
	const {
		label,
		onClickCallback,
		onRemoveClickCallback,
	} = props;

	const handleComponentClick = React.useCallback(
		(e) => {
			e.preventDefault();

			if (!onClickCallback) {
				return;
			}

			onClickCallback();
		},
		[
			onClickCallback,
		],
	);

	const handleRemoveButtonClick = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			if (!onRemoveClickCallback) {
				return;
			}

			onRemoveClickCallback();
		},
		[
			onRemoveClickCallback,
		],
	);

	return (
		<div
			className={classNames({
				'active-filter-label': true,
				'active-filter-label--is-clickable': onClickCallback,
			})}
			onClick={handleComponentClick}
		>
			<span className="active-filter-label__label">
				<span>{label}</span>
			</span>
			<a
				className="active-filter-label__remove-button"
				href="#"
				onClick={handleRemoveButtonClick}
			>
				x
			</a>
		</div>
	);
};



export default ActiveFilterLabel;
