import classNames from 'classnames';
import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import AbstractTextField, {
	type AbstractTextFieldRef,
	AbstractTextFieldSize,
	AbstractTextFieldType,
} from '~/components/patterns/forms/fields/AbstractTextField';

import useFormContext from '~/hooks/useFormContext';



type Props = {
	isDisabled?: boolean,
	max?: number,
	min?: number,
	name: string,
	onSliderValueChangeHandler: (value: number) => void,
	size?: AbstractTextFieldSize,
	value: number,
	/** Predefined width of component */
	width?: React.CSSProperties['width'],
};

const CountSliderInput: React.FC<Props> = (props) => {
	const {
		isDisabled = false,
		max,
		min,
		name,
		onSliderValueChangeHandler,
		size = AbstractTextFieldSize.Default,
		value,
		width,
	} = props;

	const formContext = useFormContext();

	const [editMode, setEditMode] = React.useState(false);

	const fieldRef = React.useRef<AbstractTextFieldRef>(null);
	const wrapperRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(
		() => {
			setEditMode(false);
		},
		[
			value,
		],
	);

	React.useEffect(
		() => {
			if (editMode) {
				setTimeout(() => {
					fieldRef.current?.getFieldRef().current?.select();
				}, 0);
			} else {
				wrapperRef.current?.focus();
			}
		},
		[
			editMode,
		],
	);

	const handleEnterEditMode = React.useCallback(
		(e) => {
			e.preventDefault();

			setEditMode(true);
		},
		[],
	);

	const handleInputFieldChange = React.useCallback(
		(value) => {
			setEditMode(false);

			onSliderValueChangeHandler(value);
		},
		[
			onSliderValueChangeHandler,
		],
	);

	function renderInput() {
		const additionalOptions: React.AllHTMLAttributes<HTMLInputElement> = {};

		if (max) {
			additionalOptions.max = max;
		}

		if (min) {
			additionalOptions.min = min;
		}

		if (isDisabled) {
			additionalOptions.disabled = true;
		}

		return (
			<AbstractTextField
				attributes={additionalOptions}
				name={name}
				onBlur={(event) => {
					formContext.onBlurHandler(name);

					const newValue = parseInt(event.target.value);

					if (!isNaN(newValue)) {
						handleInputFieldChange(newValue);
					} else {
						setEditMode(false);
					}
				}}
				onChangeCallback={(value) => {
					let changedValue = value;

					// remove non-numeric characters
					changedValue = changedValue.replace(/\D/g, '');

					const internalFieldRef = fieldRef.current?.getFieldRef().current;

					if (internalFieldRef) {
						internalFieldRef.value = changedValue;
					}
				}}
				onFocus={() => {
					formContext.onFocusHandler(name);
				}}
				onKeyDown={(event) => {
					if (event.keyCode === 27 || event.keyCode === 13) {
						event.stopPropagation();
						event.preventDefault();
					}
				}}
				onKeyUp={(event) => {
					// hit esc key and cancel changes
					if (event.keyCode === 27) {
						formContext.onBlurHandler(name);

						setEditMode(false);
					}

					// hit enter key
					if (event.keyCode === 13) {
						formContext.onBlurHandler(name);

						const newValue = parseInt((event.target as any).value);

						if (!isNaN(newValue)) {
							handleInputFieldChange(newValue);
						}
					}
				}}
				ref={fieldRef}
				size={size}
				type={AbstractTextFieldType.Number}
				value={value}
				width={false}
			/>
		);
	}

	function renderValue() {
		const valueClasses = classNames({
			'editable-value__value': true,
			'editable-value__value--disabled': isDisabled,
			'editable-value__value--small': size === AbstractTextFieldSize.Small,
		});

		return (
			<div
				className={valueClasses}
				onClick={handleEnterEditMode}
			>
				<FormattedNumber value={value} />
			</div>
		);
	}

	const style: React.CSSProperties = {};

	if (width) {
		style.width = width;
	}

	return (
		<div
			className="editable-value"
			ref={wrapperRef}
			style={style}
			tabIndex={-1}
		>
			{editMode ? renderInput() : renderValue()}
		</div>
	);
};



export default CountSliderInput;

export {
	AbstractTextFieldSize as CountSliderInputSize,
};
