import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';
import StringDiff from '~/components/app/StringDiff';



class RelevanceCellValue extends Component {

	render() {
		const {
			prevValue,
			value,
		} = this.props;

		if (value === null || value === undefined) {
			return (
				<NotApplicableValue ellipsis={true} />
			);
		}

		if (prevValue !== undefined) {
			return (
				<StringDiff
					prevValue={prevValue}
					value={value}
				/>
			);
		}

		return value;
	}

}

RelevanceCellValue.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.element,
	]),
};



export default RelevanceCellValue;
