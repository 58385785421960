import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import IconOnlyButton from '~/components/patterns/buttons/IconOnlyButton';



type Props = {
	/** Callback triggered on component click */
	onClick?: (event: React.SyntheticEvent) => void,
	/** Callback triggered on component mouse enter */
	onMouseEnter?: () => void,
	/** Callback triggered on component mouse leave */
	onMouseLeave?: () => void,
	/** Custom title visible on button hover (default is Cancel) */
	title?: string,
};



const ResetButton: React.FC<Props> = (props) => {
	const {
		onClick,
		onMouseEnter,
		onMouseLeave,
		title,
	} = props;

	return (
		<IconOnlyButton
			icon={(
				<BasicIcon
					color="#98A5B3"
					size={24}
					type={BasicIconType.CancelReversed}
				/>
			)}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			title={title}
		/>
	);
};



export default ResetButton;
