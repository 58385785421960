import classNames from 'classnames';
import React from 'react';



type Item = {
	label: React.ReactNode,
	name: string,
	selected: boolean,
};

type Props = {
	items: Array<Item>,
	onItemClickCallback: (item: Item) => void,
	onItemMouseEnterCallback: (item: Item) => void,
	onItemMouseLeaveCallback: (item: Item) => void,
};

const FieldDropdownSelectableTiles: React.FC<Props> = (props) => {
	const {
		items,
		onItemClickCallback,
		onItemMouseEnterCallback,
		onItemMouseLeaveCallback,
	} = props;

	return (
		<div className="field-dropdown-tiles">
			<ul className="field-dropdown-tiles__grid">
				{items.map((item, index) => {
					return (
						<li
							className="field-dropdown-tiles__cell"
							key={'tile-' + index}
						>
							<span
								className={classNames({
									'field-dropdown-tiles__tile': true,
									'field-dropdown-tiles__tile--clickable': onItemClickCallback,
									'field-dropdown-tiles__tile--selected': item.selected,
								})}
								onClick={(event) => {
									event.preventDefault();
									onItemClickCallback(item);
								}}
								onMouseEnter={() => onItemMouseEnterCallback(item)}
								onMouseLeave={() => onItemMouseLeaveCallback(item)}
							>
								{item.label}
							</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};



export default FieldDropdownSelectableTiles;
