import {
	format,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ChangeTrackingCalendar from '~/components/app/ChangeTrackingCalendar';
import DateRangeFilterLayout from '../../atoms/filters/DateRangeFilterLayout';
import DateRangeLabel from '~/components/app/DateRangeLabel';
import PopupButton, {
	PopupButtonSize,
	PopupButtonStyle,
} from '~/components/patterns/buttons/PopupButton';

import useViewportType from '~/hooks/useViewportType';

import {
	getDefaultDateRange,
} from '~/model/dateRangePresets';

import goTo from '~/routing/goTo';
import {
	getRouterState,
} from '~/routing/router';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	resetButtonTitle: {
		id: 'ui.dateRange.filter.resetButtonTitle',
	},
	selectDatesLabel: {
		id: 'ui.dateRange.filter.selectDatesLabel',
	},
	trackedChangesLabel: {
		id: 'ui.dateRange.filter.trackedChangesLabel',
	},
});



export enum TrackedChangesFilterScope {
	Issues = 'issues',
	Pages = 'pages',
	SingleIssueAffectedPages = 'single-issue-affected-pages',
}



type Props = {
	endDate: Date | null,
	onExit: () => void,
	onRangeSelect: (range: {
		startDate: Date | null,
		endDate: Date | null,
	}) => void,
	scope: TrackedChangesFilterScope,
	minDate: Date,
	startDate: Date | null,
};

const TrackedChangesFilter: React.FC<Props> = (props) => {
	const {
		endDate,
		minDate,
		onExit,
		onRangeSelect,
		scope,
		startDate,
	} = props;

	const viewportType = useViewportType();

	const enterTrackedChanges = React.useCallback(
		() => {
			const defaultDateRange = getDefaultDateRange(minDate);

			onRangeSelect({
				startDate: defaultDateRange.startDate(minDate),
				endDate: defaultDateRange.endDate(),
			});
		},
		[
			minDate,
			onRangeSelect,
		],
	);

	const openDatePickerScreen = React.useCallback(
		(event: MouseEvent) => {
			const routeParams = getRouterState().params;
			const routeName = matchAndReturn(scope, {
				[TrackedChangesFilterScope.Pages]: 'website.pages.datepicker',
				[TrackedChangesFilterScope.Issues]: 'website.issues.datepicker',
				[TrackedChangesFilterScope.SingleIssueAffectedPages]: 'website.issues.issueCategory.singleIssueAffectedPages.datepicker',
			});

			if (startDate === null || endDate === null) {
				const defaultDateRange = getDefaultDateRange(minDate);

				onRangeSelect({
					startDate: defaultDateRange.startDate(minDate),
					endDate: defaultDateRange.endDate(),
				});

				goTo(event, routeName, {
					issuesOverviewCategory: routeParams.issuesOverviewCategory,
					issuesOverviewIssue: routeParams.issuesOverviewIssue,
					websiteId: routeParams.websiteId,
					start_date: format(defaultDateRange.startDate(minDate), 'yyyy-MM-dd'),
					end_date: format(defaultDateRange.endDate(), 'yyyy-MM-dd'),
				});
			} else {
				goTo(event, routeName, {
					issuesOverviewCategory: routeParams.issuesOverviewCategory,
					issuesOverviewIssue: routeParams.issuesOverviewIssue,
					websiteId: routeParams.websiteId,
					start_date: format(startDate, 'yyyy-MM-dd'),
					end_date: format(endDate, 'yyyy-MM-dd'),
				});
			}
		},
		[
			endDate,
			minDate,
			onRangeSelect,
			scope,
			startDate,
		],
	);

	if (viewportType.isSmall) {
		return (
			<Button
				icon={(
					<BasicIcon
						type={BasicIconType.History}
					/>
				)}
				iconColor="#ffffff"
				onClick={openDatePickerScreen}
				style={ButtonStyle.History}
			>
				<FormattedMessage {...messages.trackedChangesLabel} />
			</Button>
		);
	}

	if (endDate === null || startDate === null) {
		return (
			<Button
				icon={(
					<BasicIcon
						type={BasicIconType.History}
					/>
				)}
				iconColor="#ffffff"
				onClick={enterTrackedChanges}
				style={ButtonStyle.History}
			>
				<FormattedMessage {...messages.trackedChangesLabel} />
			</Button>
		);
	}

	return (
		<DateRangeFilterLayout
			cancelButton={(
				<Button
					icon={(
						<BasicIcon type={BasicIconType.Cancel} />
					)}
					onClick={onExit}
					style={ButtonStyle.Hollow}
				>
					<FormattedMessage {...messages.resetButtonTitle} />
				</Button>
			)}
			label={(
				<AttachedIcon
					icon={(
						<BasicIcon
							size={14}
							type={BasicIconType.History}
						/>
					)}
				>
					<FormattedMessage {...messages.trackedChangesLabel} />
				</AttachedIcon>
			)}
			range={(
				<DateRangeLabel
					endDate={endDate}
					startDate={startDate}
				/>
			)}
			toggleButton={(
				<PopupButton
					buttonIcon={(
						<BasicIcon type={BasicIconType.Calendar} />
					)}
					label={(
						<FormattedMessage {...messages.selectDatesLabel} />
					)}
					popupLayout={(children) => {
						return children;
					}}
					size={PopupButtonSize.Small}
					style={PopupButtonStyle.Hollow}
					uppercase={true}
				>
					<ChangeTrackingCalendar
						endDate={endDate}
						minDate={minDate}
						onRangeSelect={onRangeSelect}
						startDate={startDate}
					/>
				</PopupButton>
			)}
		/>
	);
};



export default TrackedChangesFilter;
