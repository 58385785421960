import React from 'react';

import List, {
	ListSize,
} from '~/components/patterns/lists/List';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './DefinitionTermsList.module.scss';



type Props = {
	children?: React.ReactNode,
};

const DefinitionTermsList: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<List
			className="definition-terms-list"
			size={ListSize.Large}
		>
			{children}
		</List>
	);
};



export default DefinitionTermsList;
