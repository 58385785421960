/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiateValidateInvitationsSessionMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type InitiateValidateInvitationsSessionMutation = { readonly __typename?: 'Mutation', readonly InitiateValidateInvitationsSession: { readonly __typename?: 'InitiateValidateInvitationsSessionResult', readonly sessionId: string } };

export type CompleteValidateInvitationsSessionMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  sessionId: GraphQL.Scalars['String']['input'];
}>;


export type CompleteValidateInvitationsSessionMutation = { readonly __typename?: 'Mutation', readonly CompleteValidateInvitationsSession: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const InitiateValidateInvitationsSessionDocument = gql`
    mutation InitiateValidateInvitationsSession($accountId: AccountId!) {
  InitiateValidateInvitationsSession(accountId: $accountId) {
    sessionId
  }
}
    `;
export type InitiateValidateInvitationsSessionMutationFn = Apollo.MutationFunction<InitiateValidateInvitationsSessionMutation, InitiateValidateInvitationsSessionMutationVariables>;

/**
 * __useInitiateValidateInvitationsSessionMutation__
 *
 * To run a mutation, you first call `useInitiateValidateInvitationsSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateValidateInvitationsSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateValidateInvitationsSessionMutation, { data, loading, error }] = useInitiateValidateInvitationsSessionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useInitiateValidateInvitationsSessionMutation(baseOptions?: Apollo.MutationHookOptions<InitiateValidateInvitationsSessionMutation, InitiateValidateInvitationsSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateValidateInvitationsSessionMutation, InitiateValidateInvitationsSessionMutationVariables>(InitiateValidateInvitationsSessionDocument, options);
      }
export type InitiateValidateInvitationsSessionMutationHookResult = ReturnType<typeof useInitiateValidateInvitationsSessionMutation>;
export type InitiateValidateInvitationsSessionMutationResult = Apollo.MutationResult<InitiateValidateInvitationsSessionMutation>;
export type InitiateValidateInvitationsSessionMutationOptions = Apollo.BaseMutationOptions<InitiateValidateInvitationsSessionMutation, InitiateValidateInvitationsSessionMutationVariables>;
export const CompleteValidateInvitationsSessionDocument = gql`
    mutation CompleteValidateInvitationsSession($accountId: AccountId!, $sessionId: String!) {
  CompleteValidateInvitationsSession(accountId: $accountId, sessionId: $sessionId) {
    query {
      ping
    }
  }
}
    `;
export type CompleteValidateInvitationsSessionMutationFn = Apollo.MutationFunction<CompleteValidateInvitationsSessionMutation, CompleteValidateInvitationsSessionMutationVariables>;

/**
 * __useCompleteValidateInvitationsSessionMutation__
 *
 * To run a mutation, you first call `useCompleteValidateInvitationsSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteValidateInvitationsSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeValidateInvitationsSessionMutation, { data, loading, error }] = useCompleteValidateInvitationsSessionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCompleteValidateInvitationsSessionMutation(baseOptions?: Apollo.MutationHookOptions<CompleteValidateInvitationsSessionMutation, CompleteValidateInvitationsSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteValidateInvitationsSessionMutation, CompleteValidateInvitationsSessionMutationVariables>(CompleteValidateInvitationsSessionDocument, options);
      }
export type CompleteValidateInvitationsSessionMutationHookResult = ReturnType<typeof useCompleteValidateInvitationsSessionMutation>;
export type CompleteValidateInvitationsSessionMutationResult = Apollo.MutationResult<CompleteValidateInvitationsSessionMutation>;
export type CompleteValidateInvitationsSessionMutationOptions = Apollo.BaseMutationOptions<CompleteValidateInvitationsSessionMutation, CompleteValidateInvitationsSessionMutationVariables>;