import React, {
	Component,
} from 'react';



let alreadyLoaded = false;
let alreadyRendered = false;



class GoogleApi extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			alreadyLoaded,
			alreadyRendered,
		};
	}

	componentDidMount() {
		if (!this.state.alreadyRendered) {
			const clientScriptElement = document.createElement('script');
			clientScriptElement.src = `https://accounts.google.com/gsi/client?hl=en`;
			clientScriptElement.async = true;
			clientScriptElement.defer = true;

			clientScriptElement.onload = () => {
				alreadyLoaded = true;

				this.setState({
					alreadyLoaded,
				});
			};

			document.body.appendChild(clientScriptElement);
		}

		alreadyRendered = true;

		this.setState({
			alreadyRendered,
		});
	}



	render() {
		if (this.state.alreadyLoaded) {
			return <>{this.props.children}</>;
		}

		return false;
	}

}



export default GoogleApi;
