import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const DeclarationsList: React.FC<Props> = (props) => {
	const children = React.Children.toArray(props.children);
	const elements = children.filter((child) => !!child);

	return (
		<div className="declarations-list">
			{React.Children.map(
				elements,
				(child, index) => {
					return (
						<div
							className="declarations-list__item"
							key={'declarations-list-item-' + index}
						>
							{child}
						</div>
					);
				},
			)}
		</div>
	);
};



export default DeclarationsList;
