/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserHasPasswordQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type CurrentUserHasPasswordQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly user: { readonly __typename?: 'User', readonly hasPassword: boolean | null, readonly id: string, readonly legacyId: string } } | null };


export const CurrentUserHasPasswordDocument = gql`
    query CurrentUserHasPassword {
  authenticatedSession {
    user {
      hasPassword
      id
      legacyId
    }
  }
}
    `;

/**
 * __useCurrentUserHasPasswordQuery__
 *
 * To run a query within a React component, call `useCurrentUserHasPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserHasPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserHasPasswordQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserHasPasswordQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserHasPasswordQuery, CurrentUserHasPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserHasPasswordQuery, CurrentUserHasPasswordQueryVariables>(CurrentUserHasPasswordDocument, options);
      }
export function useCurrentUserHasPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserHasPasswordQuery, CurrentUserHasPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserHasPasswordQuery, CurrentUserHasPasswordQueryVariables>(CurrentUserHasPasswordDocument, options);
        }
export function useCurrentUserHasPasswordSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserHasPasswordQuery, CurrentUserHasPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserHasPasswordQuery, CurrentUserHasPasswordQueryVariables>(CurrentUserHasPasswordDocument, options);
        }
export type CurrentUserHasPasswordQueryHookResult = ReturnType<typeof useCurrentUserHasPasswordQuery>;
export type CurrentUserHasPasswordLazyQueryHookResult = ReturnType<typeof useCurrentUserHasPasswordLazyQuery>;
export type CurrentUserHasPasswordSuspenseQueryHookResult = ReturnType<typeof useCurrentUserHasPasswordSuspenseQuery>;
export type CurrentUserHasPasswordQueryResult = Apollo.QueryResult<CurrentUserHasPasswordQuery, CurrentUserHasPasswordQueryVariables>;