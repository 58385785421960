import {
	selectedWebsiteIdSelector,
} from '../ui/selectors';



const selector = (state) => state.get('incidents');



export const currentDataSelector = (state) => selector(state).get(selectedWebsiteIdSelector(state));
