import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CrownQueenIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M5,10.578a6.007,6.007,0,0,1,6,6V19a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,13,19V16.578a6.007,6.007,0,0,1,6-6,.134.134,0,0,0,.117-.069.135.135,0,0,0-.006-.136A8.5,8.5,0,0,0,13,6.572V4h1a1,1,0,0,0,0-2H13V1a1,1,0,0,0-2,0V2H10a1,1,0,0,0,0,2h1V6.572a8.5,8.5,0,0,0-6.111,3.8A.132.132,0,0,0,5,10.578Z"
				fill={color}
			/>
			<path
				d="M9.5,19.5A.5.5,0,0,0,10,19V16.578a5,5,0,0,0-10,0,4.947,4.947,0,0,0,.81,2.694.5.5,0,0,0,.42.228Z"
				fill={color}
			/>
			<path
				d="M19,11.578a5.005,5.005,0,0,0-5,5V19a.5.5,0,0,0,.5.5h8.277a.5.5,0,0,0,.42-.229,4.954,4.954,0,0,0,.8-2.693A5.005,5.005,0,0,0,19,11.578Z"
				fill={color}
			/>
			<path
				d="M9,20.5H1.87a.5.5,0,0,0-.382.177A2.052,2.052,0,0,0,1.01,22a1.937,1.937,0,0,0,2,2h18A1.932,1.932,0,0,0,23,22a2.057,2.057,0,0,0-.474-1.322.5.5,0,0,0-.383-.178Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CrownQueenIconBuilder;
