import {
	captureException,
} from '@sentry/browser';
import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';

import ColumnName from '~/components/names/ColumnName';
import ColumnValueFormatter from '~/components/logic/formatters/ColumnValueFormatter';
import ColumnFilter from '~/components/logic/filters/ColumnFilter';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import ExpandableFilterOperatorHeaderLabel from '~/components/logic/filters/ExpandableFilterOperatorHeaderLabel';
import MultiselectFieldFilter from '~/components/logic/datatables/MultiselectFieldFilter';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';
import NumberFieldFilter from '~/components/logic/datatables/NumberFieldFilter';
import PowerStaticTextFieldFilter from '~/components/logic/datatables/PowerStaticTextFieldFilter';
import RecrawlSitemapLink from '~/components/logic/sitemapsList/components/RecrawlSitemapLink';
import ResizableDatatable from '~/components/logic/datatables/ResizableDatatable';
import SitemapReferenceName from '~/components/names/SitemapReferenceName';
import SitemapReferencesCellValue from '~/components/logic/datatables/cellValues/SitemapReferencesCellValue';
import SitemapStatusName from '~/components/names/SitemapStatusName';
import SitemapTypeName from '~/components/names/SitemapTypeName';
import TextualCellValue from '~/components/logic/datatables/cellValues/TextualCellValue';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsLogFileAnalysisEnabled from '~/hooks/useWebsiteIsLogFileAnalysisEnabled';

import {
	loadSitemapsList,
	updateFilter,
	updateSortBy,
} from '~/actions/sitemapsList';

import {
	REFERENCE_DEFAULT_LOCATION,
	REFERENCE_GOOGLE_SEARCH_CONSOLE,
	REFERENCE_MISSING,
	REFERENCE_ROBOTS_TXT,
	REFERENCE_SITEMAP_INDEX,
	STATUS_INVALID,
	STATUS_MISSING,
	STATUS_REDIRECTED,
	STATUS_SERVER_ERROR,
	STATUS_TIMEOUT,
	STATUS_VALID,
	TYPE_SITEMAP,
	TYPE_SITEMAP_INDEX,
} from '~/model/sitemaps';

import {
	currentDataSelector,
	filterSelector,
	loadingSelector,
	sortBySelector,
} from '~/state/sitemapsList/selectors';



const messages = defineMessages({
	lastCrawledAtLabel: {
		id: 'ui.screen.sitemapsList.headings.lastChecked.label',
	},
	numberOfRecordsLabel: {
		id: 'ui.screen.sitemapsList.headings.items.label',
	},
	numberOfSitemaps: {
		id: 'ui.screen.sitemapsList.cells.items.inSitemapIndex',
	},
	numberOfUrls: {
		id: 'ui.screen.sitemapsList.cells.items.inSitemap',
	},
	referenceLabel: {
		id: 'ui.screen.sitemapsList.headings.reference.label',
	},
	statusLabel: {
		id: 'ui.screen.sitemapsList.headings.status.label',
	},
	typeLabel: {
		id: 'ui.screen.sitemapsList.headings.type.label',
	},
	urlLabel: {
		id: 'ui.screen.sitemapsList.headings.sitemap.label',
	},
});


const lastCrawledAtColumn = {
	filterName: undefined,
	name: 'sitemap_last_crawled_at',
	hasData: ({ row }) => !!row,
	render: {
		cell: ({ row }) => {
			const canBePrioritized = row.get('can_be_prioritized');
			const isPrioritized = row.get('is_prioritized');
			const lastCrawledAt = row.get('last_crawled_at');
			const sitemapId = row.get('id');

			return (
				<RecrawlSitemapLink
					canBePrioritized={canBePrioritized}
					isPrioritized={isPrioritized}
					lastCrawledAt={lastCrawledAt}
					sitemapId={sitemapId}
				/>
			);
		},
		label: () => (
			<FormattedMessage {...messages.lastCrawledAtLabel} />
		),
	},
	resizable: true,
	sortName: 'last_crawled_at',
	sortOrder: true,
	width: 250,
};

function lfaColumn(column: CK.PagesColumn) {
	return {
		filterName: column,
		name: column,
		hasData: ({ row }) => !!row,
		render: {
			cell: ({ row }) => {
				const value = row.get(column);

				return (
					<Ellipsis>
						<ColumnValueFormatter
							column={column}
							value={value}
						/>
					</Ellipsis>
				);
			},
			filter: ({ filterWidth, ref }) => (
				<ColumnFilter
					columnName={column}
					columnWidth={filterWidth}
					ref={ref}
				/>
			),
			label: () => (
				<ColumnName column={column} />
			),
		},
		resizable: false,
		sortName: column,
		sortOrder: true,
		width: 280,
	};
}

const numberOfRecordsColumn = {
	filterName: 'number_of_records',
	name: 'sitemap_number_of_records',
	hasData: ({ row }) => !!row,
	render: {
		cell({ row }) {
			const numberOfRecords = row.get('number_of_records');

			if (numberOfRecords !== null && row.get('type') === TYPE_SITEMAP) {
				return (
					<FormattedMessage
						{...messages.numberOfUrls}
						values={{
							count__items: numberOfRecords,
						}}
					/>
				);
			} else if (numberOfRecords !== null && row.get('type') === TYPE_SITEMAP_INDEX) {
				return (
					<FormattedMessage
						{...messages.numberOfSitemaps}
						values={{
							count__items: numberOfRecords,
						}}
					/>
				);
			}

			return (
				<NotApplicableValue ellipsis={true} />
			);
		},
		filter: ({ filterName, filterWidth, ref }) => (
			<NumberFieldFilter
				name={filterName}
				ref={ref}
				width={filterWidth}
			/>
		),
		label: () => (
			<FormattedMessage {...messages.numberOfRecordsLabel} />
		),
	},
	resizable: true,
	sortName: 'number_of_records',
	sortOrder: true,
	width: 250,
};

const referencesColumn = {
	filterName: 'references',
	name: 'sitemap_reference',
	hasData: ({ row }) => !!row,
	render: {
		cell({ row }) {
			return (
				<SitemapReferencesCellValue
					sitemapReferences={row.get('references')}
				/>
			);
		},
		filter: ({ filterName, filterWidth, ref }) => (
			<MultiselectFieldFilter
				isOnlyLinkVisible={true}
				name={filterName}
				options={[
					{
						name: REFERENCE_DEFAULT_LOCATION,
						title: (
							<SitemapReferenceName sitemapReference={REFERENCE_DEFAULT_LOCATION} />
						),
					},
					{
						name: REFERENCE_ROBOTS_TXT,
						title: (
							<SitemapReferenceName sitemapReference={REFERENCE_ROBOTS_TXT} />
						),
					},
					{
						name: REFERENCE_SITEMAP_INDEX,
						title: (
							<SitemapReferenceName sitemapReference={REFERENCE_SITEMAP_INDEX} />
						),
					},
					{
						name: REFERENCE_GOOGLE_SEARCH_CONSOLE,
						title: (
							<SitemapReferenceName sitemapReference={REFERENCE_GOOGLE_SEARCH_CONSOLE} />
						),
					},
					{
						name: REFERENCE_MISSING,
						title: (
							<SitemapReferenceName sitemapReference={REFERENCE_MISSING} />
						),
					},
				]}
				ref={ref}
				width={filterWidth}
			/>
		),
		label: () => (
			<FormattedMessage {...messages.referenceLabel} />
		),
	},
	resizable: true,
	sortName: 'references',
	sortOrder: true,
	width: 250,
};

const statusColumn =
{
	filterName: 'status',
	name: 'sitemap_status',
	hasData: ({ row }) => !!row,
	render: {
		cell({ row }) {
			const status = row.get('status');

			return (
				<SitemapStatusName sitemapStatus={status} />
			);
		},
		filter: ({ filterName, filterWidth, ref }) => (
			<MultiselectFieldFilter
				isOnlyLinkVisible={true}
				name={filterName}
				options={[
					{
						name: STATUS_VALID,
						title: (
							<SitemapStatusName sitemapStatus={STATUS_VALID} />
						),
					},
					{
						name: STATUS_INVALID,
						title: (
							<SitemapStatusName sitemapStatus={STATUS_INVALID} />
						),
					},
					{
						name: STATUS_REDIRECTED,
						title: (
							<SitemapStatusName sitemapStatus={STATUS_REDIRECTED} />
						),
					},
					{
						name: STATUS_MISSING,
						title: (
							<SitemapStatusName sitemapStatus={STATUS_MISSING} />
						),
					},
					{
						name: STATUS_SERVER_ERROR,
						title: (
							<SitemapStatusName sitemapStatus={STATUS_SERVER_ERROR} />
						),
					},
					{
						name: STATUS_TIMEOUT,
						title: (
							<SitemapStatusName sitemapStatus={STATUS_TIMEOUT} />
						),
					},
				]}
				ref={ref}
				width={filterWidth}
			/>
		),
		label: () => (
			<FormattedMessage {...messages.statusLabel} />
		),
	},
	resizable: true,
	sortName: 'status',
	sortOrder: true,
	width: 250,
};

const typeColumn = {
	filterName: 'type',
	name: 'sitemap_type',
	hasData: ({ row }) => !!row,
	render: {
		cell: ({ row }) => {
			return (
				<SitemapTypeName sitemapType={row.get('type')} />
			);
		},
		filter: ({ filterName, filterWidth, ref }) => (
			<MultiselectFieldFilter
				isOnlyLinkVisible={true}
				name={filterName}
				options={[
					{
						name: TYPE_SITEMAP,
						title: (
							<SitemapTypeName sitemapType={TYPE_SITEMAP} />
						),
					},
					{
						name: TYPE_SITEMAP_INDEX,
						title: (
							<SitemapTypeName sitemapType={TYPE_SITEMAP_INDEX} />
						),
					},
				]}
				ref={ref}
				width={filterWidth}
			/>
		),
		label: () => (
			<FormattedMessage {...messages.typeLabel} />
		),
	},
	resizable: true,
	sortName: 'type',
	sortOrder: true,
	width: 250,
};

const urlColumn = {
	filterName: 'url',
	name: 'sitemap_url',
	hasData: ({ row }) => !!row,
	render: {
		cell({ row }) {
			const url = row.get('url');

			return (
				<TextualCellValue
					value={url}
				/>
			);
		},
		filter: ({ filterName, filterWidth, ref }) => (
			<PowerStaticTextFieldFilter
				name={filterName}
				ref={ref}
				width={filterWidth}
			/>
		),
		label: () => (
			<FormattedMessage {...messages.urlLabel} />
		),
		labelFilterOperator: ({ filter }) => (
			<ExpandableFilterOperatorHeaderLabel
				value={filter.get('url')}
			/>
		),
	},
	resizable: true,
	sortName: 'url',
	sortOrder: true,
	width: 400,
};



export type SitemapsListDatatableHandle = {
	scrollToColumn: (filterName: string) => void,
};

const SitemapsListDatatable = React.forwardRef<SitemapsListDatatableHandle>((_, ref) => {
	const dispatch = useDispatch();

	const websiteId = useWebsiteId();
	const showLfaColumns = useWebsiteIsLogFileAnalysisEnabled(websiteId);

	const sitemaps = useSelector(currentDataSelector);
	const isLoading = useSelector(loadingSelector);
	const filter = useSelector(filterSelector);
	const sortBy = useSelector(sortBySelector);

	const datatableRef = React.useRef<any | null>(null);

	const columns = React.useMemo(
		() => {
			if (showLfaColumns) {
				return [
					urlColumn,
					typeColumn,
					statusColumn,
					numberOfRecordsColumn,
					referencesColumn,
					lfaColumn(CK.PagesCommonColumn.LfaGoogleFrequency),
					lfaColumn(CK.PagesCommonColumn.LfaGoogleLastVisit),
					lfaColumn(CK.PagesCommonColumn.LfaBingFrequency),
					lfaColumn(CK.PagesCommonColumn.LfaBingLastVisit),
					lastCrawledAtColumn,
				];
			}

			return [
				urlColumn,
				typeColumn,
				statusColumn,
				numberOfRecordsColumn,
				referencesColumn,
				lastCrawledAtColumn,
			];
		},
		[
			showLfaColumns,
		],
	);

	React.useImperativeHandle(ref, () => ({
		scrollToColumn: (filterName: string) => {
			const column = columns.find((column) => {
				return column.name === filterName || column.filterName === filterName;
			}) ?? null;

			if (column !== null && datatableRef.current !== null) {
				try {
					datatableRef.current.scrollToColumn(column.name);
				} catch (error) {
					captureException(error);
				}
			}
		},
	}));

	const handleFilterChange = React.useCallback(
		(nextFilter) => {
			dispatch(
				updateFilter(nextFilter),
			);
		},
		[
			dispatch,
		],
	);

	const loadingDebounce = React.useRef<any | undefined>();
	const handleScroll = React.useCallback(
		({ rowIndex }) => {
			const total = sitemaps.get('total') ?? Number.MAX_SAFE_INTEGER;

			if (
				!sitemaps.getIn(['range', rowIndex])
				|| !sitemaps.getIn(['range', Math.min(total, rowIndex + 150)])
				|| !sitemaps.getIn(['range', Math.max(0, rowIndex - 150)])
			) {
				if (loadingDebounce.current) {
					clearTimeout(loadingDebounce.current);
				}

				loadingDebounce.current = setTimeout(() => {
					dispatch(
						loadSitemapsList(
							!sitemaps.getIn(['range', rowIndex - 150])
								? rowIndex - 150
								: rowIndex,
							!sitemaps.getIn(['range', rowIndex - 150]) ? 2 : 1,
						),
					);
				}, 200);
			}
		},
		[
			dispatch,
			sitemaps,
		],
	);

	const handleSortChange = React.useCallback(
		({ key, direction }) => {
			dispatch(
				updateSortBy({
					key,
					direction,
				}),
			);
		},
		[
			dispatch,
		],
	);

	const renderBlankSlate = React.useCallback(
		() => (
			<NoSearchResults />
		),
		[],
	);

	const rowGetter = React.useCallback(
		({ rowIndex }) => {
			const sitemapId = sitemaps.get('range').get(rowIndex);

			if (!sitemapId) {
				return null;
			}

			return sitemaps.get('sitemaps').get(
				sitemapId.toString(),
			) ?? null;
		},
		[
			sitemaps,
		],
	);

	return (
		<ResizableDatatable
			blankSlate={renderBlankSlate}
			columns={columns}
			filter={filter}
			isLoading={isLoading}
			numberOfPinnedColumns={1}
			onFilterChange={handleFilterChange}
			onScroll={handleScroll}
			onSortChangeCallback={handleSortChange}
			ref={datatableRef}
			rowGetter={rowGetter}
			rowsCount={sitemaps.get('total') ?? 0}
			sortBy={sortBy}
		/>
	);
});



export default SitemapsListDatatable;
