/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsiteRenderingSettingsMutationVariables = GraphQL.Exact<{
  isDomTracked: GraphQL.Scalars['Boolean']['input'];
  isLighthouseMonitored: GraphQL.Scalars['Boolean']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteRenderingSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteRenderingSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isDomTracked: boolean, readonly isLighthouseMonitored: boolean, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null } | null } } };


export const UpdateWebsiteRenderingSettingsDocument = gql`
    mutation UpdateWebsiteRenderingSettings($isDomTracked: Boolean!, $isLighthouseMonitored: Boolean!, $websiteId: WebsiteId!) {
  UpdateWebsiteRenderingSettings(
    isDomTracked: $isDomTracked
    isLighthouseMonitored: $isLighthouseMonitored
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        account {
          features {
            feature
            id
            inUse
          }
          id
        }
        id
        isDomTracked
        isLighthouseMonitored
      }
    }
  }
}
    `;
export type UpdateWebsiteRenderingSettingsMutationFn = Apollo.MutationFunction<UpdateWebsiteRenderingSettingsMutation, UpdateWebsiteRenderingSettingsMutationVariables>;

/**
 * __useUpdateWebsiteRenderingSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteRenderingSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteRenderingSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteRenderingSettingsMutation, { data, loading, error }] = useUpdateWebsiteRenderingSettingsMutation({
 *   variables: {
 *      isDomTracked: // value for 'isDomTracked'
 *      isLighthouseMonitored: // value for 'isLighthouseMonitored'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteRenderingSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteRenderingSettingsMutation, UpdateWebsiteRenderingSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteRenderingSettingsMutation, UpdateWebsiteRenderingSettingsMutationVariables>(UpdateWebsiteRenderingSettingsDocument, options);
      }
export type UpdateWebsiteRenderingSettingsMutationHookResult = ReturnType<typeof useUpdateWebsiteRenderingSettingsMutation>;
export type UpdateWebsiteRenderingSettingsMutationResult = Apollo.MutationResult<UpdateWebsiteRenderingSettingsMutation>;
export type UpdateWebsiteRenderingSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteRenderingSettingsMutation, UpdateWebsiteRenderingSettingsMutationVariables>;