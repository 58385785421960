import classNames from 'classnames';
import React from 'react';

import LinkContextProvider, {
	ExternalLinkStyle,
	InternalLinkStyle,
} from '~/components/patterns/links/LinkContextProvider';

import styles from './DecentText.module.scss';



export enum DecentTextAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

export enum DecentTextSize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	/** Text alignment */
	alignment?: DecentTextAlignment,
	children?: React.ReactNode,
	/** Component size */
	size?: DecentTextSize,
	/** Text with strong importance */
	strong?: boolean,
};



const DecentText: React.FC<Props> = (props) => {
	const {
		alignment,
		children,
		size = DecentTextSize.Default,
		strong,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isStronger]: strong,
		[styles[alignment + '-aligned'] ?? '']: alignment,
		[styles[size + '-size'] ?? '']: size,
	});

	return (
		<div className={componentClasses}>
			<LinkContextProvider
				externalLinkStyle={ExternalLinkStyle.Decent}
				internalLinkStyle={InternalLinkStyle.Decent}
			>
				{children}
			</LinkContextProvider>
		</div>
	);
};



export default DecentText;
