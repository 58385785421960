import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	DragLayer,
} from 'react-dnd';

import SegmentDefinitionIdentifier, {
	SegmentDefinitionIdentifierType,
} from './SegmentDefinitionIdentifier';



class CustomDraggableSegment extends Component {

	renderItem(type, item) {
		switch (type) {

			case 'segment':
				return (
					<SegmentDefinitionIdentifier
						{...item.segmentLabelProps}
						type={SegmentDefinitionIdentifierType.BeingDragged}
					/>
				);
			default:
				return null;

		}
	}



	_getItemStyles() {
		const {
			currentOffset,
		} = this.props;

		if (!currentOffset) {
			return {
				display: 'none',
			};
		}

		const {
			x,
			y,
		} = currentOffset;

		const transform = `translate(${x}px, ${y}px)`;

		return {
			transform,
			WebkitTransform: transform,
		};
	}



	render() {
		const {
			item,
			itemType,
			isDragging,
		} = this.props;

		if (!isDragging) {
			return null;
		}

		const layerStyles = {
			position: 'fixed',
			pointerEvents: 'none',
			zIndex: 100000,
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
		};

		return (
			<div style={layerStyles}>
				<div style={this._getItemStyles()}>
					{this.renderItem(itemType, item)}
				</div>
			</div>
		);
	}

}

CustomDraggableSegment.propTypes = {
	item: PropTypes.object,
	itemType: PropTypes.string,
	currentOffset: PropTypes.shape({
		x: PropTypes.number.isRequired,
		y: PropTypes.number.isRequired,
	}),
	isDragging: PropTypes.bool.isRequired,
};



export default DragLayer((monitor) => ({
	item: monitor.getItem(),
	itemType: monitor.getItemType(),
	currentOffset: monitor.getClientOffset(),
	isDragging: monitor.isDragging(),
}))(CustomDraggableSegment);
