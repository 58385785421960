import React from 'react';

import Copy from '~/components/logic/Copy';

import {
	assertDictionaryId,
} from '~/localization/dictionaries';

import {
	type IssueName,
} from '~/model/issuesNew';



type Props = {
	issue: {
		configuration: Record<string, any>,
		context: any,
		name: IssueName,
	},
};

const IssueCaseDescription: React.FC<Props> = (props) => {
	const {
		issue,
	} = props;

	const messageId = `issues.${issue.name}.caseDescription` as const;

	assertDictionaryId(messageId);

	return (
		<Copy
			id={messageId}
			values={{
				...issue.context,
				...issue.configuration,
			}}
		/>
	);
};



export default IssueCaseDescription;
