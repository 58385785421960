import React from 'react';

import {
	gridIntersection,
	gridMultiselection,
} from '~/utilities/gridMultiselection';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';

import {
	notEmpty,
} from '~/utilities/typeCheck';



const Context = React.createContext({});

const {
	Consumer,
	Provider,
} = Context;



function formatOutput<RowId extends string>({
	indeterminate,
	optionGroups,
	options,
	value,
}: {
	indeterminate: ReadonlyArray<RowId>,
	optionGroups: ReadonlyArray<ReadonlyArray<RowId>> | undefined,
	options: ReadonlyArray<RowId> | undefined,
	value: ReadonlyArray<RowId>,
}) {
	const checked: Array<RowId> = [];
	const unchecked: Array<RowId> = [];

	if ((options ?? optionGroups ?? []).length === 0) {
		return {
			checked,
			unchecked,
		};
	}

	if (optionGroups !== undefined) {
		optionGroups.forEach((subOptions) => {
			subOptions.forEach((item) => {
				if (indeterminate.includes(item)) {
					return;
				}

				if (value.includes(item)) {
					checked.push(item);
				} else {
					unchecked.push(item);
				}
			});
		});

		return {
			checked,
			unchecked,
		};
	}

	if (options === undefined) {
		throw new Error(`This can't happen`);
	}

	return {
		checked: value,
		unchecked: options.filter((item) => value.includes(item) === false),
	};
}



export const ContextConsumer = Consumer;

const emptyArray = [];



// CheckboxGroupContainer.propTypes = {
// 	disabled: PropTypes.array,
// 	indeterminate: PropTypes.array,
// 	name: PropTypes.string.isRequired,
// 	onChangeCallback: PropTypes.func,
// 	options: PropTypes.array.isRequired,
// 	value: PropTypes.array.isRequired,
// };

type Props<RowId extends string> = {
	children: React.ReactNode,
	disabled?: ReadonlyArray<RowId>,
	indeterminate?: ReadonlyArray<RowId>,
	name: string,
	onChangeCallback?: (input: {
		checked: ReadonlyArray<RowId>,
		unchecked: ReadonlyArray<RowId>,
	}) => void,
	value: ReadonlyArray<RowId>,
} & ({
	optionGroups: ReadonlyArray<ReadonlyArray<RowId>>,
	options?: never,
} | {
	optionGroups?: never,
	options: ReadonlyArray<RowId>,
});

function CheckboxGroupContainer<RowId extends string>(props: Props<RowId>) {
	const {
		children,
		disabled = emptyArray,
		indeterminate = emptyArray,
		name,
		onChangeCallback,
		optionGroups,
		options,
		value,
	} = props;

	const [state, setState] = React.useState<{
		indeterminate: ReadonlyArray<RowId>,
		lastClick: RowId | null,
	}>({
		indeterminate,
		lastClick: null,
	});

	const context = React.useMemo(
		() => {
			const processChange = (fieldName, fieldValue) => {
				const newValue = [...value];

				const positionInIndeterminate = state.indeterminate.indexOf(fieldName);
				const positionInValue = newValue.indexOf(fieldName);
				const isIndeterminate = positionInIndeterminate !== -1;
				const currentValue = isIndeterminate ? false : positionInValue !== -1;

				if (currentValue !== fieldValue) {
					if (fieldValue) {
						newValue.push(fieldName);
					} else {
						newValue.splice(positionInValue, 1);
					}

					setState(
						(state) => ({
							...state,
							lastClick: fieldName,
						}),
					);

					if (onChangeCallback) {
						onChangeCallback(
							formatOutput({
								indeterminate: state.indeterminate,
								optionGroups,
								options,
								value: newValue,
							}),
						);
					}
				}
			};

			const processClick = (fieldName, event) => {
				const newIndeterminate = [...state.indeterminate];
				let newValue = [...value];

				const positionInIndeterminate = newIndeterminate.indexOf(fieldName);
				const positionInValue = newValue.indexOf(fieldName);
				const isIndeterminate = positionInIndeterminate !== -1;
				const newFieldValue = isIndeterminate ? false : positionInValue === -1;

				if (event.shiftKey) {
					const grid: Array<Array<boolean>> = [];

					let firstClickCoordinates;
					let lastClickCoordinates;

					if (optionGroups !== undefined) {
						optionGroups.forEach((subOptions, rowIndex) => {
							if (!grid[rowIndex]) {
								grid[rowIndex] = [];
							}

							subOptions.forEach((fieldItem, columnIndex) => {
								getArrayItemAtSafeIndex(grid, rowIndex)[columnIndex] = newValue.includes(fieldItem);

								if (fieldItem === state.lastClick) {
									firstClickCoordinates = {
										columnIndex,
										rowIndex,
									};
								}

								if (fieldItem === fieldName) {
									lastClickCoordinates = {
										columnIndex,
										rowIndex,
									};
								}
							});
						});
					} else {
						options.forEach((field, rowIndex) => {
							if (!grid[rowIndex]) {
								grid[rowIndex] = [];
							}

							getArrayItemAtSafeIndex(grid, rowIndex)[0] = newValue.includes(field);
						});

						firstClickCoordinates = {
							columnIndex: 0,
							rowIndex: state.lastClick !== null ? options.indexOf(state.lastClick) : -1,
						};

						lastClickCoordinates = {
							columnIndex: 0,
							rowIndex: options.indexOf(fieldName),
						};
					}

					newValue = gridMultiselection(
						grid,
						firstClickCoordinates,
						lastClickCoordinates,
						newFieldValue,
					);

					if (newIndeterminate.length > 0) {
						const intersection = gridIntersection(
							grid,
							firstClickCoordinates,
							lastClickCoordinates,
						);

						if (optionGroups !== undefined) {
							intersection.forEach((intersectionRow, rowIndex) => {
								intersectionRow.forEach((status, columnIndex) => {
									const interFieldName = getArrayItemAtSafeIndex(
										getArrayItemAtSafeIndex(
											optionGroups,
											rowIndex,
										),
										columnIndex,
									);

									const interPositionInIndeterminate = newIndeterminate.indexOf(interFieldName);

									if (interPositionInIndeterminate !== -1) {
										newIndeterminate.splice(interPositionInIndeterminate, 1);
									}
								});
							});
						} else {
							intersection.forEach((newValueRow, rowIndex) => {
								const interFieldName = options[rowIndex];

								// @ts-ignore
								const interPositionInIndeterminate = newIndeterminate.indexOf(interFieldName);

								if (interPositionInIndeterminate !== -1) {
									newIndeterminate.splice(interPositionInIndeterminate, 1);
								}
							});
						}
					}

					if (optionGroups !== undefined) {
						const finalNewValue: Array<RowId> = [];

						newValue.forEach((newValueRow, rowIndex) => {
							// @ts-ignore
							newValueRow.forEach((status, columnIndex) => {
								if (status) {
									finalNewValue.push(
										getArrayItemAtSafeIndex(
											getArrayItemAtSafeIndex(
												optionGroups,
												rowIndex,
											),
											columnIndex,
										),
									);
								}
							});
						});

						newValue = finalNewValue;
					} else {
						newValue = newValue
							.map((status, index) => status[0] ? options[index] : undefined)
							.filter(notEmpty);
					}
				} else {
					if (newFieldValue) {
						newValue.push(fieldName);
					} else if (positionInValue !== -1) {
						newValue.splice(positionInValue, 1);
					}

					if (isIndeterminate) {
						newIndeterminate.splice(positionInIndeterminate, 1);
					}
				}

				setState({
					indeterminate: newIndeterminate,
					lastClick: fieldName,
				});

				if (onChangeCallback) {
					onChangeCallback(
						formatOutput({
							indeterminate: newIndeterminate,
							optionGroups,
							options,
							value: newValue,
						}),
					);
				}
			};

			const toggleAll = ({ affectedOptions }) => {
				let newValue: Array<RowId> = [];
				let newIndeterminate: Array<RowId> | null = null;

				if (affectedOptions) {
					const relevantValue = value.filter((item) => affectedOptions.includes(item) && disabled.includes(item) === false);
					const relevantIndeterminate = state.indeterminate.filter((item) => affectedOptions.includes(item) && disabled.includes(item) === false);

					newValue = value.filter(
						(item) => (
							affectedOptions.includes(item) === false
							|| disabled.includes(item)
						),
					);
					newIndeterminate = state.indeterminate.filter(
						(item) => (
							affectedOptions.includes(item) === false
							|| disabled.includes(item)
						),
					);

					if (relevantValue.length === 0 && relevantIndeterminate.length === 0) {
						affectedOptions
							.filter((item) => disabled.includes(item) === false || relevantValue.includes(item))
							.forEach((item) => newValue.push(item));
					}
				} else {
					if (value.length > 0 || state.indeterminate.length > 0) {
						newValue = value.filter((item) => disabled.includes(item));
					} else {
						if (optionGroups !== undefined) {
							newValue = optionGroups.flatMap(
								(items) => items.filter(
									(item) => disabled.includes(item) === false || value.includes(item),
								),
							);
						} else {
							newValue = [...options].filter(
								(item) => disabled.includes(item) === false || value.includes(item),
							);
						}
					}

					newIndeterminate = [];
				}

				setState({
					indeterminate: newIndeterminate,
					lastClick: null,
				});

				if (onChangeCallback) {
					onChangeCallback(
						formatOutput({
							indeterminate: newIndeterminate,
							optionGroups,
							options,
							value: newValue,
						}),
					);
				}
			};

			return {
				disabled,
				indeterminate: state.indeterminate,
				name,
				optionGroups,
				options,
				processChange,
				processClick,
				toggleAll,
				value,
			};
		},
		[
			disabled,
			name,
			onChangeCallback,
			optionGroups,
			options,
			state,
			value,
		],
	);

	return (
		<Provider value={context}>
			{children}
		</Provider>
	);
}



export default CheckboxGroupContainer;
