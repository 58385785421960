import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const LockIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeLinecap="round"
				strokeWidth="1"
			>
				<g
					id="lock"
					stroke={color}
					strokeWidth="2"
					transform="translate(6.000000, 4.000000)"
				>
					<polygon
						id="Stroke-611"
						points="13.1772941 17.2957647 0.000823529412 17.2957647 0.000823529412 7.41341176 13.1772941 7.41341176"
						strokeLinejoin="round"
					>
					</polygon>
					<path
						d="M2.47108235,7.41127059 L2.47108235,4.11797647 C2.47108235,1.84338824 4.31496471,0.000329411765 6.58872941,0.000329411765 C8.86249412,0.000329411765 10.7063765,1.84338824 10.7063765,4.11797647 L10.7063765,7.41127059"
						id="Stroke-612"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M8.23529412,11.5299059 C8.23529412,12.4399059 7.49823529,13.1769647 6.58823529,13.1769647 C5.67823529,13.1769647 4.94117647,12.4399059 4.94117647,11.5299059 C4.94117647,10.6199059 5.67823529,9.88284706 6.58823529,9.88284706 C7.49823529,9.88284706 8.23529412,10.6199059 8.23529412,11.5299059 Z"
						id="Stroke-613"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M6.58823529,14.8225412 L6.58823529,13.1763059"
						id="Stroke-614"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default LockIconBuilder;

