import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import ContextNavigationButton, {
	ContextNavigationButtonIconType,
} from '~/components/patterns/navigations/ContextNavigationButton';
import FieldOptionsGroup from '~/components/atoms/forms/components/builders/FieldOptionsGroup';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalPanel from '~/components/atoms/panels/ModalPanel';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';

import {
	validateField,
} from '~/components/app/validations';

import {
	useDisableSubscriptionRenewalMutation,
} from './DisableSubscriptionRenewalButton.gql';

import useAccountId from '~/hooks/useAccountId';
import useAccountPaidUntil from '~/hooks/useAccountPaidUntil';
import useModals from '~/hooks/useModals';



const messages = defineMessages({
	buttonTitle: {
		id: 'ui.disableSubscriptionRenewal.button',
	},
	checkboxOne: {
		id: 'ui.disableSubscriptionRenewal.modal.checkboxOne',
	},
	checkboxTwo: {
		id: 'ui.disableSubscriptionRenewal.modal.checkboxTwo',
	},
	modalButton: {
		id: 'ui.disableSubscriptionRenewal.modal.button',
	},
	modalContent: {
		id: 'ui.disableSubscriptionRenewal.modal.content',
	},
	modalTitle: {
		id: 'ui.disableSubscriptionRenewal.modal.title',
	},
});

const validations = {
	checkboxOne: validateField('checkboxOne', (f) => [f.validateChecked()]),
	checkboxTwo: validateField('checkboxTwo', (f) => [f.validateChecked()]),
};



const DisableSubscriptionRenewalButton: React.FC = () => {
	const accountId = useAccountId();
	const accountPaidUntil = useAccountPaidUntil(accountId);
	const [disableSubscriptionRenewal] = useDisableSubscriptionRenewalMutation();
	const modals = useModals();

	const handleSubmit = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			await disableSubscriptionRenewal({
				variables: {
					accountId,
				},
			});

			modals.closeModal();
		},
		[
			accountId,
			disableSubscriptionRenewal,
			modals,
		],
	);

	const openModal = React.useCallback(
		() => {
			modals.openModal(
				({ closeCallback }) => (
					<ModalPanel
						canBeClosed={true}
						canBeClosedViaOverlay={true}
						onCloseCallback={closeCallback}
					>
						<ModalContainer
							gapsSize={2}
							header={(
								<ModalHeader
									title={(
										<FormattedMessage {...messages.modalTitle} />
									)}
								/>
							)}
							headerGapsSize={1}
						>
							<Form
								onSuccess={handleSubmit}
								validations={validations}
							>
								<ModalTextSection>
									<RichText>
										<p>
											<FormattedMessage {...messages.modalContent} />
										</p>
									</RichText>
								</ModalTextSection>

								<CenteredFormWrapper>
									<FormRow>
										<FieldOptionsGroup>
											<CheckboxField
												label={(
													<FormattedMessage {...messages.checkboxOne} />
												)}
												name="checkboxOne"
												width={false}
											/>

											<CheckboxField
												label={(
													<FormattedMessage
														{...messages.checkboxTwo}
														values={{
															paidUntil: accountPaidUntil,
														}}
													/>
												)}
												name="checkboxTwo"
												width={false}
											/>
										</FieldOptionsGroup>
									</FormRow>

									<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
										<CancelButton />

										<SubmitButton
											positive={false}
										>
											<FormattedMessage {...messages.modalButton} />
										</SubmitButton>
									</ModalButtonsLayout>
								</CenteredFormWrapper>
							</Form>
						</ModalContainer>
					</ModalPanel>
				),
			);
		},
		[
			accountPaidUntil,
			handleSubmit,
			modals,
		],
	);

	return (
		<ContextNavigationButton
			iconType={ContextNavigationButtonIconType.CancelReversed}
			onClickCallback={openModal}
		>
			<FormattedMessage {...messages.buttonTitle} />
		</ContextNavigationButton>
	);
};



export default DisableSubscriptionRenewalButton;
