import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import LabeledValue, {
	LabeledValueContentAlignment,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import RobotsTxtDiffButton from '../RobotsTxtDiffButton';



const messages = defineMessages({
	status: {
		id: 'ui.contentOverview.tableHeading.alertStatus',
	},
	url: {
		id: 'ui.contentOverview.tableHeading.url',
	},
});



class RobotsTxtBecameNonIndexableDetails extends Component {

	render() {
		const {
			incident,
			websiteId,
		} = this.props;

		const isModern = incident.hasIn(['state', 'url']);

		return (
			<IncidentBoxContent
				button={(
					<RobotsTxtDiffButton
						nextRevision={incident.getIn(['state', 'revision_next'])}
						previousRevision={incident.getIn(['state', 'revision_previous'])}
						websiteId={websiteId}
					/>
				)}
			>
				{isModern && (
					<LabeledValuesList>
						<LabeledValue
							contentAlignment={LabeledValueContentAlignment.Left}
							label={(
								<FormattedMessage {...messages.url} />
							)}
						>
							{incident.getIn(['state', 'url'])}
						</LabeledValue>

						<LabeledValue
							contentAlignment={LabeledValueContentAlignment.Left}
							label={(
								<FormattedMessage {...messages.status} />
							)}
						>
							<CodeValue>
								{incident.getIn(['state', 'status_code'])}
							</CodeValue>
						</LabeledValue>
					</LabeledValuesList>
				)}
			</IncidentBoxContent>
		);
	}

}

RobotsTxtBecameNonIndexableDetails.propTypes = {
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string,
};



export default RobotsTxtBecameNonIndexableDetails;
