import PropTypes from 'prop-types';
import React from 'react';



const ScoreNumberWithDelta = (props) => {
	const {
		delta,
		scoreNumber,
	} = props;

	return (
		<div className="score-number-with-delta">
			<div className="score-number-with-delta__number">
				{scoreNumber}
			</div>
			<div className="score-number-with-delta__delta">
				{delta}
			</div>
		</div>
	);
};

ScoreNumberWithDelta.propTypes = {
	/** Attached delta */
	delta: PropTypes.node.isRequired,
	/** Score number */
	scoreNumber: PropTypes.node.isRequired,
};



export default ScoreNumberWithDelta;
