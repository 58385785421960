import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAllowedBillingCyclesQuery,
} from './useAllowedBillingCycles.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAllowedBillingCycles(accountId: CK.AccountId | null): GraphQL.Account['allowedBillingCycles'] | null {
	const { data } = useAccountPropertiesQuery(
		useAllowedBillingCyclesQuery,
		accountId,
	);

	return data?.account?.allowedBillingCycles ?? null;
}



export default useAllowedBillingCycles;
