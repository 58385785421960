import findLastIndex from 'lodash/findLastIndex';
import memoize from 'memoizee';

import CK from '~/types/contentking';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';

import {
	unique,
} from '~/utilities/unique';



export enum PagesColumnsAlignment {
	Left,
	Right,
}

export enum PagesColumnsCategory {
	AdobeAnalytics = 'adobe_analytics',
	Content = 'content',
	Conversions = 'conversions',
	CustomElements = 'custom_elements',
	EnrichmentFields = 'enrichment_fields',
	Fundamentals = 'fundamentals',
	GoogleAnalytics = 'google_analytics',
	GoogleSearchConsole = 'google_search_console',
	Indexability = 'indexability',
	Lighthouse = 'lighthouse',
	Relations = 'relations',
	SchemaOrg = 'schema_org',
	SearchEngineActivity = 'search_engine_activity',
	Social = 'social',
	NoCategory = 'no_category',
}

enum Scope {
	Pages = 'pages',
	TrackedChanges = 'tracked_changes',
}

type ColumnDefinition = {
	category: PagesColumnsCategory,
	display?: {
		alignment?: PagesColumnsAlignment,
		defaultWidth?: number,
		isResizable?: boolean,
		maximumWidth?: number,
		minimumWidth?: number,
	},
	hasChangeTracking: boolean,
	isHistorical: boolean,
	isManageable: boolean,
	scopes: Array<Scope>,
};

type ColumnDefinitions = {
	[Key in CK.PagesCommonColumn]: ColumnDefinition
};



export const AllCategories = [
	PagesColumnsCategory.Fundamentals,
	PagesColumnsCategory.Indexability,
	PagesColumnsCategory.Relations,
	PagesColumnsCategory.Content,
	PagesColumnsCategory.Lighthouse,
	PagesColumnsCategory.Social,
	PagesColumnsCategory.GoogleSearchConsole,
	PagesColumnsCategory.GoogleAnalytics,
	PagesColumnsCategory.AdobeAnalytics,
	PagesColumnsCategory.Conversions,
	PagesColumnsCategory.SchemaOrg,
	PagesColumnsCategory.SearchEngineActivity,
	PagesColumnsCategory.CustomElements,
	PagesColumnsCategory.EnrichmentFields,
] as const;



const columnDefinitions: ColumnDefinitions = {
	[CK.PagesCommonColumn.AaAverageTimeSpentOnSite]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.AdobeAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.AaBounceRate]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.AdobeAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.AaPageViews]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.AdobeAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.AaRevenue]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.AdobeAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.AaUniqueVisitors]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.AdobeAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.AnalyticsServices]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Conversions,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.CanonicalLinkElement]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
	[CK.PagesCommonColumn.CanonicalLinkTarget]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
	[CK.PagesCommonColumn.CanonicalType]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Indexability,
		display: {
			defaultWidth: 220,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.CanonicalUrl]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Indexability,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.ChangeType]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		display: {
			defaultWidth: 220,
			isResizable: true,
		},
		isHistorical: false,
		scopes: [
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.Domain]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GaActiveUsers]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GaAverageEngagementTime]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GaEngagementRate]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GaScreenPageViews]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GaAverageTime]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GaBounceRate]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GaPageValue]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GaPageViews]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GaUniquePageViews]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleAnalytics,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GscClicks]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleSearchConsole,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GscCtr]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleSearchConsole,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GscImpressions]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleSearchConsole,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.GscPosition]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.GoogleSearchConsole,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.H1]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Content,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.Health]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 140,
			isResizable: false,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.HreflangChanges]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
	[CK.PagesCommonColumn.HreflangLanguage]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 220,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.IsDisallowedInRobotsTxt]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Indexability,
		display: {
			defaultWidth: 175,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.IsInSitemap]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Indexability,
		display: {
			defaultWidth: 175,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.IsIndexable]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Indexability,
		display: {
			defaultWidth: 165,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.IsIndexableDueToMetaRobots]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Indexability,
		display: {
			defaultWidth: 175,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.IsIndexableDueToXRobotsTag]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Indexability,
		display: {
			defaultWidth: 175,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.IsLinked]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 120,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.IsSecured]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 120,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.IssueChange]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
	[CK.PagesCommonColumn.LfaBingDesktopFrequency]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			alignment: PagesColumnsAlignment.Right,
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaBingDesktopLastVisit]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaBingFrequency]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			alignment: PagesColumnsAlignment.Right,
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaBingLastVisit]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaBingMobileFrequency]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			alignment: PagesColumnsAlignment.Right,
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaBingMobileLastVisit]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaGoogleDesktopFrequency]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			alignment: PagesColumnsAlignment.Right,
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaGoogleDesktopLastVisit]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaGoogleFrequency]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			alignment: PagesColumnsAlignment.Right,
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaGoogleLastVisit]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaGoogleMobileFrequency]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			alignment: PagesColumnsAlignment.Right,
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LfaGoogleMobileLastVisit]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.SearchEngineActivity,
		display: {
			defaultWidth: 220,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.LighthouseCumulativeLayoutShift]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Lighthouse,
		display: {
			defaultWidth: 160,
			minimumWidth: 120,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.LighthouseFirstContentfulPaint]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Lighthouse,
		display: {
			defaultWidth: 160,
			minimumWidth: 120,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.LighthouseLargestContentfulPaint]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Lighthouse,
		display: {
			defaultWidth: 160,
			minimumWidth: 160,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.LighthousePerformance]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Lighthouse,
		display: {
			defaultWidth: 180,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.LighthouseSpeedIndex]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Lighthouse,
		display: {
			defaultWidth: 160,
			minimumWidth: 160,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.LighthouseTimeToInteractive]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Lighthouse,
		display: {
			defaultWidth: 160,
			minimumWidth: 160,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.LighthouseTotalBlockingTime]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Lighthouse,
		display: {
			defaultWidth: 160,
			minimumWidth: 160,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.LinkAmp]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 270,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.LinkNext]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 270,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.LinkPrev]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 270,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.MetaDescription]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Content,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.MobileVariant]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 270,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.NumberOfHreflangs]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 270,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.NumberOfImagesWithMissingAlt]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
	[CK.PagesCommonColumn.NumberOfImagesWithMixedTransport]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
	[CK.PagesCommonColumn.NumberOfIncomingInternalCanonicals]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 205,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.NumberOfIncomingInternalLinks]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 285,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.NumberOfIncomingInternalRedirects]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 285,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.NumberOfLinksToBrokenPages]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
	[CK.PagesCommonColumn.NumberOfLinksToCanonicalizedPages]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
	[CK.PagesCommonColumn.NumberOfLinksToRedirectedPages]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
	[CK.PagesCommonColumn.NumberOfOutgoingExternalLinks]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 300,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.NumberOfOutgoingInternalLinks]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.Relations,
		display: {
			defaultWidth: 285,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.OpenGraphType]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.OpenGraphUrl]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.OpenGraphTitle]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.OpenGraphDescription]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.OpenGraphImage]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.Redirect]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.Relevance]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 150,
			isResizable: false,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.SchemaOrgNumberOfTypes]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.SchemaOrg,
		display: {
			defaultWidth: 290,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.SchemaOrgTypes]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.SchemaOrg,
		display: {
			defaultWidth: 290,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.Segments]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 290,
			minimumWidth: 290,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.StatusCode]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 140,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.TimeFound]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 165,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.TimeServerResponse]: {
		isManageable: true,
		hasChangeTracking: false,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 165,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
		],
	},
	[CK.PagesCommonColumn.Title]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Content,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.TwitterCard]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.TwitterTitle]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.TwitterSite]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 280,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.TwitterDescription]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.TwitterImage]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Social,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.Type]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 190,
			minimumWidth: 190,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.TagManagers]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Conversions,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.Url]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 320,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.UrlDepth]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 190,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.UrlFull]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Fundamentals,
		display: {
			defaultWidth: 360,
		},
		isHistorical: false,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.VisualAnalyticsServices]: {
		isManageable: true,
		hasChangeTracking: true,
		category: PagesColumnsCategory.Conversions,
		display: {
			defaultWidth: 320,
		},
		isHistorical: true,
		scopes: [
			Scope.Pages,
			Scope.TrackedChanges,
		],
	},
	[CK.PagesCommonColumn.XmlSitemapStatus]: {
		isManageable: false,
		hasChangeTracking: false,
		category: PagesColumnsCategory.NoCategory,
		isHistorical: false,
		scopes: [],
	},
};



export const CommonColumns = Object.keys(columnDefinitions) as Array<CK.PagesCommonColumn>;



export const GoogleAnalyticsV3Columns: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.GaAverageTime,
	CK.PagesCommonColumn.GaBounceRate,
	CK.PagesCommonColumn.GaPageValue,
	CK.PagesCommonColumn.GaPageViews,
	CK.PagesCommonColumn.GaUniquePageViews,
];



export const GoogleAnalyticsV4Columns: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.GaActiveUsers,
	CK.PagesCommonColumn.GaAverageEngagementTime,
	CK.PagesCommonColumn.GaEngagementRate,
	CK.PagesCommonColumn.GaScreenPageViews,
];



export const LfaGoogleColumns: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.LfaGoogleDesktopFrequency,
	CK.PagesCommonColumn.LfaGoogleDesktopLastVisit,
	CK.PagesCommonColumn.LfaGoogleFrequency,
	CK.PagesCommonColumn.LfaGoogleLastVisit,
	CK.PagesCommonColumn.LfaGoogleMobileFrequency,
	CK.PagesCommonColumn.LfaGoogleMobileLastVisit,
];



export const LfaBingColumns: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.LfaBingDesktopFrequency,
	CK.PagesCommonColumn.LfaBingDesktopLastVisit,
	CK.PagesCommonColumn.LfaBingFrequency,
	CK.PagesCommonColumn.LfaBingLastVisit,
	CK.PagesCommonColumn.LfaBingMobileFrequency,
	CK.PagesCommonColumn.LfaBingMobileLastVisit,
];



export const ManageableColumns: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.Url,
	CK.PagesCommonColumn.UrlDepth,
	CK.PagesCommonColumn.UrlFull,
	CK.PagesCommonColumn.Domain,
	CK.PagesCommonColumn.Type,
	CK.PagesCommonColumn.Segments,
	CK.PagesCommonColumn.Relevance,
	CK.PagesCommonColumn.Health,
	CK.PagesCommonColumn.IsSecured,
	CK.PagesCommonColumn.StatusCode,
	CK.PagesCommonColumn.Redirect,
	CK.PagesCommonColumn.TimeServerResponse,
	CK.PagesCommonColumn.TimeFound,
	CK.PagesCommonColumn.IsIndexable,
	CK.PagesCommonColumn.IsDisallowedInRobotsTxt,
	CK.PagesCommonColumn.IsIndexableDueToMetaRobots,
	CK.PagesCommonColumn.IsIndexableDueToXRobotsTag,
	CK.PagesCommonColumn.CanonicalUrl,
	CK.PagesCommonColumn.CanonicalType,
	CK.PagesCommonColumn.IsInSitemap,
	CK.PagesCommonColumn.IsLinked,
	CK.PagesCommonColumn.LinkPrev,
	CK.PagesCommonColumn.LinkNext,
	CK.PagesCommonColumn.NumberOfIncomingInternalLinks,
	CK.PagesCommonColumn.NumberOfOutgoingInternalLinks,
	CK.PagesCommonColumn.NumberOfOutgoingExternalLinks,
	CK.PagesCommonColumn.NumberOfIncomingInternalCanonicals,
	CK.PagesCommonColumn.NumberOfIncomingInternalRedirects,
	CK.PagesCommonColumn.HreflangLanguage,
	CK.PagesCommonColumn.NumberOfHreflangs,
	CK.PagesCommonColumn.MobileVariant,
	CK.PagesCommonColumn.LinkAmp,
	CK.PagesCommonColumn.Title,
	CK.PagesCommonColumn.MetaDescription,
	CK.PagesCommonColumn.H1,
	CK.PagesCommonColumn.LfaGoogleFrequency,
	CK.PagesCommonColumn.LfaGoogleLastVisit,
	CK.PagesCommonColumn.LfaGoogleDesktopFrequency,
	CK.PagesCommonColumn.LfaGoogleDesktopLastVisit,
	CK.PagesCommonColumn.LfaGoogleMobileFrequency,
	CK.PagesCommonColumn.LfaGoogleMobileLastVisit,
	CK.PagesCommonColumn.LfaBingFrequency,
	CK.PagesCommonColumn.LfaBingLastVisit,
	CK.PagesCommonColumn.LfaBingDesktopFrequency,
	CK.PagesCommonColumn.LfaBingDesktopLastVisit,
	CK.PagesCommonColumn.LfaBingMobileFrequency,
	CK.PagesCommonColumn.LfaBingMobileLastVisit,
	CK.PagesCommonColumn.LighthousePerformance,
	CK.PagesCommonColumn.LighthouseFirstContentfulPaint,
	CK.PagesCommonColumn.LighthouseTimeToInteractive,
	CK.PagesCommonColumn.LighthouseSpeedIndex,
	CK.PagesCommonColumn.LighthouseTotalBlockingTime,
	CK.PagesCommonColumn.LighthouseLargestContentfulPaint,
	CK.PagesCommonColumn.LighthouseCumulativeLayoutShift,
	CK.PagesCommonColumn.OpenGraphType,
	CK.PagesCommonColumn.OpenGraphUrl,
	CK.PagesCommonColumn.OpenGraphTitle,
	CK.PagesCommonColumn.OpenGraphDescription,
	CK.PagesCommonColumn.OpenGraphImage,
	CK.PagesCommonColumn.TwitterCard,
	CK.PagesCommonColumn.TwitterSite,
	CK.PagesCommonColumn.TwitterTitle,
	CK.PagesCommonColumn.TwitterDescription,
	CK.PagesCommonColumn.TwitterImage,
	CK.PagesCommonColumn.GscClicks,
	CK.PagesCommonColumn.GscCtr,
	CK.PagesCommonColumn.GscImpressions,
	CK.PagesCommonColumn.GscPosition,
	CK.PagesCommonColumn.GaActiveUsers,
	CK.PagesCommonColumn.GaAverageEngagementTime,
	CK.PagesCommonColumn.GaEngagementRate,
	CK.PagesCommonColumn.GaScreenPageViews,
	CK.PagesCommonColumn.GaPageViews,
	CK.PagesCommonColumn.GaUniquePageViews,
	CK.PagesCommonColumn.GaAverageTime,
	CK.PagesCommonColumn.GaBounceRate,
	CK.PagesCommonColumn.GaPageValue,
	CK.PagesCommonColumn.AaPageViews,
	CK.PagesCommonColumn.AaUniqueVisitors,
	CK.PagesCommonColumn.AaAverageTimeSpentOnSite,
	CK.PagesCommonColumn.AaBounceRate,
	CK.PagesCommonColumn.AaRevenue,
	CK.PagesCommonColumn.AnalyticsServices,
	CK.PagesCommonColumn.VisualAnalyticsServices,
	CK.PagesCommonColumn.TagManagers,
	CK.PagesCommonColumn.SchemaOrgNumberOfTypes,
	CK.PagesCommonColumn.SchemaOrgTypes,
];



export const ColumnsWithoutChangeTracking: ReadonlyArray<CK.PagesColumn> = (
	CommonColumns.filter((column) => {
		return columnDefinitions[column].hasChangeTracking === false;
	})
);



export const ColumnsWithPagesScope: ReadonlyArray<CK.PagesColumn> = (
	CommonColumns.filter((column) => {
		return columnDefinitions[column].scopes.includes(Scope.Pages);
	})
);



export const ColumnsWithTrackedChangesScope: ReadonlyArray<CK.PagesColumn> = (
	CommonColumns.filter((column) => {
		return columnDefinitions[column].scopes.includes(Scope.TrackedChanges);
	})
);



export const HistoricalColumns: ReadonlyArray<CK.PagesColumn> = (
	CommonColumns.filter((column) => {
		return columnDefinitions[column].isHistorical;
	})
);



export const ResizableColumns: ReadonlyArray<CK.PagesColumn> = (
	CommonColumns.filter((column) => {
		return columnDefinitions[column].display?.isResizable !== false;
	})
);



export const getColumnCategory = memoize((column: CK.PagesColumn): PagesColumnsCategory => {
	if (column.startsWith('custom_')) {
		return PagesColumnsCategory.CustomElements;
	}

	if (column.startsWith('ef_')) {
		return PagesColumnsCategory.EnrichmentFields;
	}

	return columnDefinitions[column]?.category ?? PagesColumnsCategory.NoCategory;
});



const DEFAULT_MAXIMUM_COLUMN_WIDTH = 700;
const DEFAULT_MINIMUM_COLUMN_WIDTH = 140;

export function getColumnAlignment(column: CK.PagesColumn): PagesColumnsAlignment {
	return columnDefinitions[column]?.display?.alignment ?? PagesColumnsAlignment.Left;
}



export function getColumnDefaultWidth(column: CK.PagesColumn) {
	return columnDefinitions[column]?.display?.defaultWidth ?? (DEFAULT_MINIMUM_COLUMN_WIDTH + 60);
}



export function getColumnMaximumSize(column: CK.PagesColumn) {
	return columnDefinitions[column]?.display?.maximumWidth ?? DEFAULT_MAXIMUM_COLUMN_WIDTH;
}



export function getColumnMinimumSize(column: CK.PagesColumn) {
	return columnDefinitions[column]?.display?.minimumWidth ?? DEFAULT_MINIMUM_COLUMN_WIDTH;
}



export function isColumnHistorical(column: CK.PagesColumn) {
	return columnDefinitions[column]?.isHistorical ?? false;
}



export function isColumnManagable(column: CK.PagesColumn) {
	return columnDefinitions[column]?.isManageable ?? false;
}



export function isColumn(column: string): column is CK.PagesColumn {
	if (isCustomElementColumn(column) || isEnrichmentFieldColumn(column)) {
		return true;
	}

	return columnDefinitions[column] !== undefined;
}



export function isCustomElementColumn(columnName: string) {
	return columnName.startsWith('custom_');
}



export function isEnrichmentFieldColumn(columnName: string) {
	return columnName.startsWith('ef_');
}



export function isResizableColumn(columnName: string) {
	if (ResizableColumns.includes(columnName as CK.PagesColumn)) {
		return true;
	}

	return (
		isCustomElementColumn(columnName)
		|| isEnrichmentFieldColumn(columnName)
	);
}



export function isTrackedChangesColumn(columnName: CK.PagesColumn) {
	if (ColumnsWithTrackedChangesScope.includes(columnName)) {
		return true;
	}

	return (
		isCustomElementColumn(columnName)
		|| isEnrichmentFieldColumn(columnName)
	);
}



export function isTrackedChangesColumnWhichWarrantsComparison(columnName: CK.PagesColumn) {
	if (isTrackedChangesColumn(columnName) === false) {
		return false;
	}

	if (columnName === CK.PagesCommonColumn.Relevance) {
		return false;
	}

	return (
		HistoricalColumns.includes(columnName)
		|| isCustomElementColumn(columnName)
	);
}



// true Pages-only columns



export function listCategoriesOfColumns(columns) {
	const result: Array<any> = [];

	columns.forEach((column) => {
		const category = getColumnCategory(column);

		if (!result.includes(category)) {
			result.push(category);
		}
	});

	return result;
}



export function flattenColumnsSetup(
	columnsSetup: ReadonlyArray<{
		columns: ReadonlyArray<{
			enabled: boolean,
			name: CK.PagesColumn,
		}>,
	}>,
): ReadonlyArray<{
	enabled: boolean,
	name: CK.PagesColumn,
}> {
	return columnsSetup.reduce(
		(reduction, category) => reduction.concat(category.columns),
		[] as ReadonlyArray<{
			enabled: boolean,
			name: CK.PagesColumn,
		}>,
	);
}



export function calculatePersistableColumns<TColumn extends {
	name: CK.PagesColumn,
}>(
	originalList: ReadonlyArray<TColumn>,
	newList: ReadonlyArray<TColumn>,
): ReadonlyArray<TColumn> {
	const originalNewList = newList;

	const originalCategories = originalList
		.map((column) => getColumnCategory(column.name))
		.filter(unique);

	originalList.forEach((column, columnIndex) => {
		const isPresent = newList.some(
			(desiredColumn) => desiredColumn.name === column.name,
		);

		const columnCategory = getColumnCategory(column.name);

		const presentCategories = newList.map(
			(column) => getColumnCategory(column.name),
		);

		if (presentCategories.includes(columnCategory) === false) {
			const originalCategoryIndex = originalCategories.indexOf(columnCategory);

			if (originalCategoryIndex === 0) {
				newList = [
					column,
					...newList,
				];

				return;
			}

			const previousCategory = getArrayItemAtSafeIndex(originalCategories, originalCategoryIndex - 1);

			const insertAt = findLastIndex(
				newList,
				(otherColumn) => getColumnCategory(otherColumn.name) === previousCategory,
			);

			newList = [
				...newList.slice(0, insertAt + 1),
				column,
				...newList.slice(insertAt + 1),
			];

			return;
		}

		if (isPresent === false) {
			const originalColumnsInCategory = originalNewList.filter(
				(column) => getColumnCategory(column.name) === columnCategory,
			);

			if (originalColumnsInCategory.length === 0) {
				const insertAt = findLastIndex(
					newList,
					(otherColumn) => getColumnCategory(otherColumn.name) === columnCategory,
				);

				newList = [
					...newList.slice(0, insertAt + 1),
					column,
					...newList.slice(insertAt + 1),
				];

				return;
			}

			const isBeforeAll = originalList.every(
				(originalColumn, originalColumnIndex) => (
					getColumnCategory(originalColumn.name) !== columnCategory
					|| originalColumn.name === column.name
					|| columnIndex < originalColumnIndex
					|| originalNewList.some((originalNewColumn) => originalNewColumn.name === originalColumn.name) === false
				),
			);

			if (isBeforeAll) {
				const insertAt = newList.findIndex(
					(otherColumn) => (
						getColumnCategory(otherColumn.name) === columnCategory
						&& originalNewList.some((originalNewColumn) => originalNewColumn.name === otherColumn.name)
					),
				);

				newList = [
					...newList.slice(0, insertAt),
					column,
					...newList.slice(insertAt),
				];

				return;
			}

			const isAfterAll = originalList.every(
				(originalColumn, originalColumnIndex) => (
					getColumnCategory(originalColumn.name) !== columnCategory
					|| originalColumn.name === column.name
					|| columnIndex > originalColumnIndex
					|| originalNewList.some((originalNewColumn) => originalNewColumn.name === originalColumn.name) === false
				),
			);

			if (isAfterAll) {
				const insertAt = findLastIndex(
					newList,
					(otherColumn) => getColumnCategory(otherColumn.name) === columnCategory,
				);

				newList = [
					...newList.slice(0, insertAt + 1),
					column,
					...newList.slice(insertAt + 1),
				];

				return;
			}

			const beforeColumn = originalList.find(
				(originalColumn, originalColumnIndex) => (
					originalColumnIndex > columnIndex
					&& originalNewList.some((originalNewColumn) => originalNewColumn.name === originalColumn.name)
				),
			);

			if (beforeColumn === undefined) {
				return;
			}

			const insertAt = newList.findIndex(
				(newColumn) => newColumn.name === beforeColumn.name,
			);

			newList = [
				...newList.slice(0, insertAt),
				column,
				...newList.slice(insertAt),
			];

			// put right before the first one it was before present in new
		}
	});

	return newList;
}
