/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddConductorCustomerDomainsMutationVariables = GraphQL.Exact<{
  domains: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type AddConductorCustomerDomainsMutation = { readonly __typename?: 'Mutation', readonly AddConductorCustomerDomains: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly kingdomSettings: { readonly __typename?: 'KingdomSettings', readonly conductorCustomerDomains: ReadonlyArray<string> } | null } } };


export const AddConductorCustomerDomainsDocument = gql`
    mutation AddConductorCustomerDomains($domains: [String!]!) {
  AddConductorCustomerDomains(domains: $domains) {
    query {
      kingdomSettings {
        conductorCustomerDomains
      }
    }
  }
}
    `;
export type AddConductorCustomerDomainsMutationFn = Apollo.MutationFunction<AddConductorCustomerDomainsMutation, AddConductorCustomerDomainsMutationVariables>;

/**
 * __useAddConductorCustomerDomainsMutation__
 *
 * To run a mutation, you first call `useAddConductorCustomerDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConductorCustomerDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConductorCustomerDomainsMutation, { data, loading, error }] = useAddConductorCustomerDomainsMutation({
 *   variables: {
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useAddConductorCustomerDomainsMutation(baseOptions?: Apollo.MutationHookOptions<AddConductorCustomerDomainsMutation, AddConductorCustomerDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddConductorCustomerDomainsMutation, AddConductorCustomerDomainsMutationVariables>(AddConductorCustomerDomainsDocument, options);
      }
export type AddConductorCustomerDomainsMutationHookResult = ReturnType<typeof useAddConductorCustomerDomainsMutation>;
export type AddConductorCustomerDomainsMutationResult = Apollo.MutationResult<AddConductorCustomerDomainsMutation>;
export type AddConductorCustomerDomainsMutationOptions = Apollo.BaseMutationOptions<AddConductorCustomerDomainsMutation, AddConductorCustomerDomainsMutationVariables>;