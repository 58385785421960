import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import IndexNowTriggerSelectField from '~/components/app/IndexNowTriggerSelectField';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useUpdateIndexNowContentTriggersMutation,
	useUpdateIndexNowPageStatusTriggersMutation,
} from './IndexNowNativeTriggersModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useIndexNowTriggers from '~/hooks/useIndexNowTriggers';
import useWebsiteId from '~/hooks/useWebsiteId';



enum ContentChange {
	H1 = 'contentChangedH1',
	H2H6 = 'contentChangedH2ToH6',
	MetaDescription = 'contentChangedMetaDescription',
	Title = 'contentChangedTitle',
}

enum PageStatusChange {
	PageBecameIndexable = 'pageBecameIndexable',
	PageBecameMissing = 'pageBecameMissing',
	PageBecameNonIndexable = 'pageBecameNonIndexable',
	PageBecameRedirect = 'pageBecameRedirect',
	PageWasAdded = 'pageWasAdded',
	PageWasRestored = 'pageWasRestored',
}



const contentTriggers = [
	ContentChange.Title,
	ContentChange.MetaDescription,
	ContentChange.H1,
	ContentChange.H2H6,
];

const pageStatusTriggers = [
	PageStatusChange.PageWasAdded,
	PageStatusChange.PageBecameRedirect,
	PageStatusChange.PageBecameMissing,
	PageStatusChange.PageWasRestored,
	PageStatusChange.PageBecameIndexable,
	PageStatusChange.PageBecameNonIndexable,
];



const messages = defineMessages({
	[ContentChange.H1]: {
		id: 'ui.indexNowNativeTriggersModal.contentChange.h1',
	},
	[ContentChange.H2H6]: {
		id: 'ui.indexNowNativeTriggersModal.contentChange.h2-h6',
	},
	[ContentChange.MetaDescription]: {
		id: 'ui.indexNowNativeTriggersModal.contentChange.metaDescription',
	},
	[ContentChange.Title]: {
		id: 'ui.indexNowNativeTriggersModal.contentChange.title',
	},
	[PageStatusChange.PageBecameIndexable]: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameIndexable',
	},
	[PageStatusChange.PageBecameMissing]: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameMissing',
	},
	[PageStatusChange.PageBecameNonIndexable]: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameNonIndexable',
	},
	[PageStatusChange.PageBecameRedirect]: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameRedirect',
	},
	[PageStatusChange.PageWasAdded]: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageWasAdded',
	},
	[PageStatusChange.PageWasRestored]: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageWasRestored',
	},
	applyChanges: {
		id: 'ui.general.applyChanges',
	},
	columnContentChange: {
		id: 'ui.indexNowNativeTriggersModal.columnContentChange',
	},
	columnPageStatusChange: {
		id: 'ui.indexNowNativeTriggersModal.columnPageStatusChange',
	},
	description: {
		id: 'ui.indexNowNativeTriggersModal.description',
	},
	sidebar: {
		id: 'ui.indexNowNativeTriggersModal.sidebar',
	},
	title: {
		id: 'ui.indexNowNativeTriggersModal.title',
	},
});



export enum IndexNowNativeTriggersCategory {
	ContentChanges = 'contentChanges',
	PageStatusChanges = 'pageStatusChanges',
}



type Props = {
	category: IndexNowNativeTriggersCategory,
};

const IndexNowNativeTriggersModal: React.FC<Props> = (props) => {
	const {
		category,
	} = props;

	const websiteId = useWebsiteId();
	const classicFormBehavior = useClassicFormBehavior();
	const indexNowTriggers = useIndexNowTriggers(websiteId);

	const [updateIndexNowContentTriggers] = useUpdateIndexNowContentTriggersMutation();
	const [updateIndexNowPageStatusTriggers] = useUpdateIndexNowPageStatusTriggersMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (category === IndexNowNativeTriggersCategory.ContentChanges) {
				await updateIndexNowContentTriggers({
					variables: {
						contentTriggers: values.triggers,
						websiteId,
					},
				});
			} else {
				await updateIndexNowPageStatusTriggers({
					variables: {
						pageStatusTriggers: values.triggers,
						websiteId,
					},
				});
			}

			classicFormBehavior.finish();
		},
		[
			category,
			classicFormBehavior,
			updateIndexNowContentTriggers,
			updateIndexNowPageStatusTriggers,
			websiteId,
		],
	);

	return (
		<SimpleModal
			iconType={BasicIconType.Plus}
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							linkArticle: linkExternal('https://www.contentkingapp.com/support/realtime-indexnow'),
						}}
					/>
				</RichText>
			)}
			size={SimpleModalSize.XLarge}
			title={(
				<FormattedMessage
					{...messages.title}
					values={{ category }}
				/>
			)}
		>
			<Form
				defaultValues={{
					triggers: (
						category === IndexNowNativeTriggersCategory.ContentChanges
							? indexNowTriggers.contentTriggers
							: indexNowTriggers.pageStatusTriggers
					),
				}}
				onSuccess={handleSubmit}
			>
				<ModalTextSection>
					<Copy
						{...messages.description}
						values={{ category }}
					/>
				</ModalTextSection>

				<IndexNowTriggerSelectField
					itemLabel={(item) => (
						<FormattedMessage {...messages[item]} />
					)}
					items={(
						category === IndexNowNativeTriggersCategory.ContentChanges
							? contentTriggers
							: pageStatusTriggers
					)}
					itemsHeader={(
						category === IndexNowNativeTriggersCategory.ContentChanges
							? <FormattedMessage {...messages.columnContentChange} />
							: <FormattedMessage {...messages.columnPageStatusChange} />
					)}
					name="triggers"
				/>

				<FormErrorMessages />

				<CenteredFormWrapper>
					<ButtonsLayout>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.applyChanges} />
						</SubmitButton>
					</ButtonsLayout>
				</CenteredFormWrapper>
			</Form>
		</SimpleModal>
	);
};



export default IndexNowNativeTriggersModal;
