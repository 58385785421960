import classNames from 'classnames';
import React from 'react';

import isScrollable from '~/utilities/isScrollable';



type Props = {
	/** Content of sticky footer */
	footer?: React.ReactNode,
	/** Reserve space for Intercom button at bottom left corner */
	gapForIntercom?: boolean,
	children: React.ReactNode,
};



const ScreenSidebarContent: React.FC<Props> = (props) => {
	const {
		footer,
		gapForIntercom,
		children,
	} = props;

	const containerRef = React.useRef<HTMLDivElement | null>(null);

	const [scrollableContent, setScrollableContent] = React.useState(false);

	React.useEffect(() => {
		setScrollableContent(isScrollable(containerRef.current));
	}, [children, footer]);

	const _renderStickyFooter = () => {
		if (!footer) {
			return null;
		}

		const footerClasses = classNames({
			'segments-sidebar-content__sticky-footer': true,
			'segments-sidebar-content__sticky-footer--is-scrollable': scrollableContent,
		});

		const footerBodyClasses = classNames({
			'segments-sidebar-content__sticky-footer-body': true,
			'segments-sidebar-content__sticky-footer-body--has-gap-for-intercom': gapForIntercom,
		});

		return (
			<div className={footerClasses}>
				<div className={footerBodyClasses}>
					{footer}
				</div>
			</div>
		);
	};

	const componentClasses = classNames({
		'segments-sidebar-content': true,
		'segments-sidebar-content--has-sticky-footer': true,
	});

	return (
		<div className={componentClasses}>
			<div
				className="segments-sidebar-content__container js-scrollable"
				ref={containerRef}
			>
				<div className="segments-sidebar-content__body">
					{children}
				</div>
			</div>
			{_renderStickyFooter()}
		</div>
	);
};



export default ScreenSidebarContent;
