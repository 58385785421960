import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import Copy from '~/components/logic/Copy';

import {
	type ValidationBuilder,
} from '~/components/app/validations';

import {
	isNotFalse,
	notEmpty,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	invalidUrls: {
		id: 'ui.validateUrls.invalidUrls',
	},
	maximumAmount: {
		id: 'ui.validateUrls.maximumAmount',
	},
	minimumAmount: {
		id: 'ui.validateUrls.minimumAmount',
	},
});



type Options = {
	allowedPrefixes?: ReadonlyArray<string>,
	allowedDomains?: ReadonlyArray<string>,
	maximumAmount?: number,
	minimumAmount?: number,
};

function validateUrls(f: ValidationBuilder, options: Options = {}) {
	const {
		allowedDomains,
		allowedPrefixes,
		maximumAmount,
		minimumAmount,
	} = options;

	let allowedStringStarts = [
		'http://',
		'https://',
		...(allowedPrefixes ?? []),
	];

	if (allowedDomains !== undefined) {
		allowedStringStarts = allowedDomains.flatMap(
			(allowedDomain) => allowedStringStarts.flatMap(
				(allowedStringStart) => [
					allowedStringStart + allowedDomain,
					allowedDomain.startsWith('www.') !== true ? (allowedStringStart + 'www.' + allowedDomain) : null,
				].filter(notEmpty),
			),
		);
	}

	return [
		minimumAmount !== undefined && f.custom({
			message: (
				<Copy {...messages.minimumAmount} />
			),
			rule: ({ value }) => {
				return (value ?? []).length > 0;
			},
		}),
		f.custom({
			message: ({ value }) => {
				const numberOfInvalidUrls = value.filter(
					(urlLine) => allowedStringStarts.some(
						(allowedStringStart) => urlLine.startsWith(allowedStringStart),
					) === false,
				).length;

				return (
					<Copy
						{...messages.invalidUrls}
						values={{
							allowedDomains: allowedDomains !== undefined
								? allowedDomains.join(', ')
								: 'none',
							numberOfInvalidUrls,
						}}
					/>
				);
			},
			rule: ({ value }) => {
				return value.every(
					(urlLine) => allowedStringStarts.some(
						(allowedStringStart) => urlLine.startsWith(allowedStringStart),
					),
				);
			},
		}),
		maximumAmount !== undefined && f.custom({
			message: (
				<Copy
					{...messages.maximumAmount}
					values={{
						maximumAmount,
					}}
				/>
			),
			rule: ({ value }) => {
				return value.length <= maximumAmount;
			},
		}),
	].filter(isNotFalse);
}



export default validateUrls;
