import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import {
	loadTeamBillingDetails,
} from '~/actions/teams';

import {
	teamsBillingSelector,
} from '~/state/teams/selectors';

import {
	teamSelector,
} from '~/state/ui/selectors';



function useLegacyBillingDetails(accountId: CK.AccountId | null) {
	const account = useSelector(teamSelector);
	const allBillingDetails = useSelector(teamsBillingSelector);

	const dispatch = useDispatch();

	const billingDetails = (accountId !== null && allBillingDetails)
		? allBillingDetails.get(accountId + '')
		: null;

	const billingEntity = account?.get('billing_entity') ?? null;
	const hasBillingDetails = account?.get('has_billing_details') ?? false;

	React.useEffect(
		() => {
			if (hasBillingDetails && billingEntity === 'old') {
				dispatch(
					loadTeamBillingDetails(
						accountId,
					),
				);
			}
		},
		[
			accountId,
			billingEntity,
			dispatch,
			hasBillingDetails,
		],
	);

	return billingDetails;
}



export default useLegacyBillingDetails;
