import {
	type SegmentDefinition,
	getSegmentDefinitionByName,
} from '~/model/segments';



export type Scope = string;



function isScopeAWebsite(scope: Scope): boolean {
	return scope === 'website';
}



function isScopeASegment(scope: Scope): boolean {
	return scope.indexOf('segment/') === 0;
}



export function createScopeIdentifierFromId(scopeIdentifierId: string): object {
	return JSON.parse(scopeIdentifierId);
}



export function createScopeIdentifierId(
	scope: Scope,
	segments: ReadonlyArray<SegmentDefinition>,
): string {
	if (isScopeAWebsite(scope)) {
		return JSON.stringify({
			type: 'website',
		});
	}

	if (isScopeASegment(scope)) {
		const segment = getSegmentDefinitionByName(
			segments,
			scope.substring('segment/'.length),
		);

		if (segment !== null) {
			return JSON.stringify({
				type: 'segment',
				color: segment.color,
				icon: segment.icon?.name ?? null,
				label: segment.label,
				shortcode: segment.shortcode,
			});
		}
	}

	throw new Error(`Unrecognized scope ${scope}`);
}
