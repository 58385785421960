/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SudoModeStatusQueryVariables = GraphQL.Exact<{
  disallowedMethod: GraphQL.InputMaybe<GraphQL.MethodToEnterSudoMode>;
}>;


export type SudoModeStatusQuery = { readonly __typename?: 'Query', readonly sudoModeStatus: { readonly __typename?: 'SudoModeStatus', readonly isSudoEnabled: boolean, readonly suggestedMethodToEnter: GraphQL.MethodToEnterSudoMode | null } };


export const SudoModeStatusDocument = gql`
    query SudoModeStatus($disallowedMethod: MethodToEnterSudoMode) {
  sudoModeStatus(disallowedMethod: $disallowedMethod) {
    isSudoEnabled
    suggestedMethodToEnter
  }
}
    `;

/**
 * __useSudoModeStatusQuery__
 *
 * To run a query within a React component, call `useSudoModeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSudoModeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSudoModeStatusQuery({
 *   variables: {
 *      disallowedMethod: // value for 'disallowedMethod'
 *   },
 * });
 */
export function useSudoModeStatusQuery(baseOptions?: Apollo.QueryHookOptions<SudoModeStatusQuery, SudoModeStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SudoModeStatusQuery, SudoModeStatusQueryVariables>(SudoModeStatusDocument, options);
      }
export function useSudoModeStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SudoModeStatusQuery, SudoModeStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SudoModeStatusQuery, SudoModeStatusQueryVariables>(SudoModeStatusDocument, options);
        }
export function useSudoModeStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SudoModeStatusQuery, SudoModeStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SudoModeStatusQuery, SudoModeStatusQueryVariables>(SudoModeStatusDocument, options);
        }
export type SudoModeStatusQueryHookResult = ReturnType<typeof useSudoModeStatusQuery>;
export type SudoModeStatusLazyQueryHookResult = ReturnType<typeof useSudoModeStatusLazyQuery>;
export type SudoModeStatusSuspenseQueryHookResult = ReturnType<typeof useSudoModeStatusSuspenseQuery>;
export type SudoModeStatusQueryResult = Apollo.QueryResult<SudoModeStatusQuery, SudoModeStatusQueryVariables>;