/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MultiWebsitesDashboardAlertsListQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type MultiWebsitesDashboardAlertsListQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly latestIncidents: ReadonlyArray<{ readonly __typename?: 'WebsiteIncident', readonly id: CK.ID, readonly incidentId: number, readonly openedAt: CK.Timestamp, readonly settings: Record<string, any>, readonly type: string, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId } }> } | null };


export const MultiWebsitesDashboardAlertsListDocument = gql`
    query MultiWebsitesDashboardAlertsList {
  authenticatedSession {
    latestIncidents {
      id
      incidentId
      openedAt
      settings
      type
      website {
        id
      }
    }
  }
}
    `;

/**
 * __useMultiWebsitesDashboardAlertsListQuery__
 *
 * To run a query within a React component, call `useMultiWebsitesDashboardAlertsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiWebsitesDashboardAlertsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiWebsitesDashboardAlertsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useMultiWebsitesDashboardAlertsListQuery(baseOptions?: Apollo.QueryHookOptions<MultiWebsitesDashboardAlertsListQuery, MultiWebsitesDashboardAlertsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MultiWebsitesDashboardAlertsListQuery, MultiWebsitesDashboardAlertsListQueryVariables>(MultiWebsitesDashboardAlertsListDocument, options);
      }
export function useMultiWebsitesDashboardAlertsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MultiWebsitesDashboardAlertsListQuery, MultiWebsitesDashboardAlertsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MultiWebsitesDashboardAlertsListQuery, MultiWebsitesDashboardAlertsListQueryVariables>(MultiWebsitesDashboardAlertsListDocument, options);
        }
export function useMultiWebsitesDashboardAlertsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MultiWebsitesDashboardAlertsListQuery, MultiWebsitesDashboardAlertsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MultiWebsitesDashboardAlertsListQuery, MultiWebsitesDashboardAlertsListQueryVariables>(MultiWebsitesDashboardAlertsListDocument, options);
        }
export type MultiWebsitesDashboardAlertsListQueryHookResult = ReturnType<typeof useMultiWebsitesDashboardAlertsListQuery>;
export type MultiWebsitesDashboardAlertsListLazyQueryHookResult = ReturnType<typeof useMultiWebsitesDashboardAlertsListLazyQuery>;
export type MultiWebsitesDashboardAlertsListSuspenseQueryHookResult = ReturnType<typeof useMultiWebsitesDashboardAlertsListSuspenseQuery>;
export type MultiWebsitesDashboardAlertsListQueryResult = Apollo.QueryResult<MultiWebsitesDashboardAlertsListQuery, MultiWebsitesDashboardAlertsListQueryVariables>;