import findLastIndex from 'lodash/findLastIndex';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import AbstractTextField, {
	type AbstractTextFieldRef,
	AbstractTextFieldSize,
} from '~/components/patterns/forms/fields/AbstractTextField';
import ChevronButton from '~/components/patterns/forms/fieldParts/buttons/ChevronButton';
import FieldDropdownSelectableOptions, {
	type FieldDropdownSelectableOptionsProps,
	isActualOption,
} from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdownSelectableOptions';
import ResetButton from '~/components/patterns/forms/fieldParts/buttons/ResetButton';

import useFormContext from '~/hooks/useFormContext';
import useStateCallback from '~/hooks/useStateCallback';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';

import {
	isNumber,
} from '~/utilities/typeCheck';



export const OPERATOR_CONTAINS = 'contains';
export const OPERATOR_EMPTY = 'empty';
export const OPERATOR_EMPTY_OR_MISSING = 'empty_or_missing';
export const OPERATOR_ENDS_WITH = 'ends_with';
export const OPERATOR_EQUALS = 'equals';
export const OPERATOR_MATCHES = 'matches';
export const OPERATOR_NOT_MATCHES = 'not_matches';
export const OPERATOR_MISSING = 'missing';
export const OPERATOR_NOT_CONTAINING = 'not_contains';
export const OPERATOR_PRESENT = 'present';
export const OPERATOR_STARTS_WITH = 'starts_with';

const messages = defineMessages({
	showMoreOptionsLabel: {
		id: 'ui.forms.options.showMore',
	},
});

const operatorMessages = defineMessages({
	[OPERATOR_CONTAINS]: {
		id: 'ui.expandableFilter.operator.contains',
	},
	[OPERATOR_EMPTY]: {
		id: 'ui.expandableFilter.operator.empty',
	},
	[OPERATOR_EMPTY_OR_MISSING]: {
		id: 'ui.expandableFilter.operator.empty_or_missing',
	},
	[OPERATOR_ENDS_WITH]: {
		id: 'ui.expandableFilter.operator.ends_with',
	},
	[OPERATOR_EQUALS]: {
		id: 'ui.expandableFilter.operator.equals',
	},
	[OPERATOR_MATCHES]: {
		id: 'ui.expandableFilter.operator.matches',
	},
	[OPERATOR_MISSING]: {
		id: 'ui.expandableFilter.operator.missing',
	},
	[OPERATOR_NOT_CONTAINING]: {
		id: 'ui.expandableFilter.operator.not_contains',
	},
	[OPERATOR_NOT_MATCHES]: {
		id: 'ui.expandableFilter.operator.not_matches',
	},
	[OPERATOR_PRESENT]: {
		id: 'ui.expandableFilter.operator.present',
	},
	[OPERATOR_STARTS_WITH]: {
		id: 'ui.expandableFilter.operator.starts_with',
	},
});

function getOutputValue(value: string | undefined, selectedOperator: string) {
	const index = FILTER_OPERATOR_NAMES.indexOf(selectedOperator);

	if (
		selectedOperator === OPERATOR_EMPTY
		|| selectedOperator === OPERATOR_EMPTY_OR_MISSING
		|| selectedOperator === OPERATOR_MISSING
		|| selectedOperator === OPERATOR_PRESENT
	) {
		return getArrayItemAtSafeIndex(FILTER_OPERATORS, index);
	}

	if (value === undefined || value === '') {
		return '';
	}

	return getArrayItemAtSafeIndex(FILTER_OPERATORS, index) + ':' + value;
}

function isMetaOperator(operator: string): boolean {
	return [
		OPERATOR_EMPTY,
		OPERATOR_EMPTY_OR_MISSING,
		OPERATOR_MISSING,
		OPERATOR_PRESENT,
	].includes(operator);
}



const DROPDOWN_MIN_WIDTH = 180;

const FILTER_OPERATOR_OPTIONS = [
	{
		operator: 'contains',
		name: OPERATOR_CONTAINS,
	},
	{
		operator: 'starts_with',
		name: OPERATOR_STARTS_WITH,
	},
	{
		operator: 'equals',
		name: OPERATOR_EQUALS,
	},
	{
		operator: 'is:empty_or_missing',
		name: OPERATOR_EMPTY_OR_MISSING,
	},
	{
		operator: 'matches',
		name: OPERATOR_MATCHES,
	},
	{
		operator: 'not_matches',
		name: OPERATOR_NOT_MATCHES,
	},
	{
		operator: 'not_contains',
		name: OPERATOR_NOT_CONTAINING,
	},
	{
		operator: 'ends_with',
		name: OPERATOR_ENDS_WITH,
	},
	{
		operator: 'is:present',
		name: OPERATOR_PRESENT,
	},
	{
		operator: 'is:empty',
		name: OPERATOR_EMPTY,
	},
	{
		operator: 'is:missing',
		name: OPERATOR_MISSING,
	},
];

const FILTER_OPERATORS = FILTER_OPERATOR_OPTIONS
	.map((item) => {
		return item.operator;
	});

const FILTER_OPERATOR_NAMES = FILTER_OPERATOR_OPTIONS
	.map((item) => {
		return item.name;
	});



export type ExpandableFilterFieldRef = {
	changeValue: (value: any) => void,
};



type Props = {
	attributes?: Record<string, any>,
	defaultOperator?: string,
	disabledOperators?: ReadonlyArray<string>,
	dropdownWidth?: number,
	/** Possibility to limit size of input */
	maxLength?: number,
	name: string,
	placeholder?: string,
	size?: AbstractTextFieldSize,
	width?: React.CSSProperties['width'],
};

const ExpandableFilterField = React.forwardRef<ExpandableFilterFieldRef, Props>((props, ref) => {
	const {
		attributes = {},
		defaultOperator = OPERATOR_CONTAINS,
		disabledOperators = [],
		dropdownWidth,
		maxLength,
		name,
		placeholder,
		size = AbstractTextFieldSize.Default,
		width,
	} = props;

	const formContext = useFormContext();
	const intl = useIntl();

	const formContextFocused = formContext.focused;
	const formContextIsDisabled = formContext.isDisabled;
	const formContextOnBlurHandler = formContext.onBlurHandler;
	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnFocusHandler = formContext.onFocusHandler;
	const formContextOnMountHandler = formContext.onMountHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const formDefaultValue = formContext.defaultValues[name];
	const formValue = formContext.values[name];

	const getOperatorNameFromGivenValue = React.useCallback(
		(value) => {
			if (!value) {
				value = '';
			}

			value = value.trim();

			if (value === '') {
				return defaultOperator;
			}

			let detectedOperator = defaultOperator;

			FILTER_OPERATOR_OPTIONS.forEach((operator) => {
				if (value === operator.operator || value.startsWith(`${operator.operator}:`)) {
					detectedOperator = operator.name;
				}
			});

			return detectedOperator;
		},
		[
			defaultOperator,
		],
	);

	const [doingDropdownInteraction, setDoingDropdownInteraction] = React.useState(false);
	const [dropdownVisible, setDropdownVisible] = React.useState(name === formContext.focused);
	const [resetButtonVisible, setResetButtonVisible] = React.useState(false);
	const [selectedOperator, setSelectedOperator] = useStateCallback(
		() => getOperatorNameFromGivenValue(formValue),
	);
	const [shadowValue, setShadowValue] = React.useState('');

	const fieldRef = React.useRef<AbstractTextFieldRef>(null);

	const closeDropdown = React.useCallback(
		() => {
			setDropdownVisible(false);

			formContextOnBlurHandler(name);
		},
		[
			formContextOnBlurHandler,
			name,
		],
	);

	const getInputValue = React.useCallback(
		(value) => {
			if (value === null || value === undefined) {
				return '';
			}

			const operator = getOperatorNameFromGivenValue(value);

			if (operator === OPERATOR_EMPTY) {
				return intl.formatMessage(operatorMessages[OPERATOR_EMPTY]);
			}

			if (operator === OPERATOR_EMPTY_OR_MISSING) {
				return intl.formatMessage(operatorMessages[OPERATOR_EMPTY_OR_MISSING]);
			}

			if (operator === OPERATOR_MISSING) {
				return intl.formatMessage(operatorMessages[OPERATOR_MISSING]);
			}

			if (operator === OPERATOR_PRESENT) {
				return intl.formatMessage(operatorMessages[OPERATOR_PRESENT]);
			}

			const operatorDividerIndex = value.indexOf(':');

			const isOperatorKnown = operatorDividerIndex > 0 ? FILTER_OPERATORS.includes(value.slice(0, operatorDividerIndex)) : false;

			if (isOperatorKnown) {
				return value.slice(operatorDividerIndex + 1, value.length);
			}

			return value;
		},
		[
			getOperatorNameFromGivenValue,
			intl,
		],
	);

	const isPossibleToDisplayResetButton = React.useCallback(
		() => {
			return formValue && formValue.length > 0 && name !== formContextFocused;
		},
		[
			formContextFocused,
			formValue,
			name,
		],
	);

	const listOperators = React.useCallback(
		() => {
			return FILTER_OPERATOR_OPTIONS.filter(
				(item) => disabledOperators.includes(item.name) === false,
			);
		},
		[
			disabledOperators,
		],
	);

	const revealDropdown = React.useCallback(
		() => {
			setDropdownVisible(true);

			fieldRef.current?.focus();
		},
		[],
	);

	React.useImperativeHandle(ref, () => ({
		changeValue: (value) => {
			fieldRef.current?.setValue(
				getInputValue(value),
			);

			if (value !== '' && value !== null && value !== undefined) {
				setSelectedOperator(
					getOperatorNameFromGivenValue(value),
				);
			}
		},
	}));

	React.useEffect(
		() => {
			formContextOnMountHandler(
				name,
				{
					interacted: true,
				},
			);

			return () => {
				formContextOnUnmountHandler(name);

				setDropdownVisible(false);
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			name,
		],
	);

	const initialFocusRef = React.useRef(formContext.focused);

	React.useEffect(
		() => {
			if (name === initialFocusRef.current) {
				fieldRef.current?.focus();
			}
		},
		[
			name,
		],
	);

	React.useEffect(
		() => {
			if (formDefaultValue === undefined || formDefaultValue === null || formDefaultValue.length === 0) {
				fieldRef.current?.setValue('');
			}
		},
		[
			formDefaultValue,
		],
	);

	React.useEffect(
		() => {
			if (formContextFocused !== name) {
				// reset component state when loosing focus
				setDoingDropdownInteraction(false);
				setDropdownVisible(false);
				setResetButtonVisible(false);
			}
		},
		[
			formContextFocused,
			name,
		],
	);

	const handleDropdownOperatorChange = React.useCallback(
		(operatorName) => {
			let value = fieldRef.current?.getFieldRef().current?.value;

			// When we changing selected operator from Empty or Missing or Present to different one.
			if (
				isMetaOperator(operatorName) === false
				&& isMetaOperator(selectedOperator)
			) {
				fieldRef.current?.setValue(shadowValue);
				value = shadowValue;
			}

			// When we set operator to Empty
			if (operatorName === OPERATOR_EMPTY) {
				fieldRef.current?.setValue(
					intl.formatMessage(operatorMessages[OPERATOR_EMPTY]),
				);
			}

			// When we set operator to "Empty or Missing"
			if (operatorName === OPERATOR_EMPTY_OR_MISSING) {
				fieldRef.current?.setValue(
					intl.formatMessage(operatorMessages[OPERATOR_EMPTY_OR_MISSING]),
				);
			}

			// When we set operator to Missing
			if (operatorName === OPERATOR_MISSING) {
				fieldRef.current?.setValue(
					intl.formatMessage(operatorMessages[OPERATOR_MISSING]),
				);
			}

			// When we set operator to Present
			if (operatorName === OPERATOR_PRESENT) {
				fieldRef.current?.setValue(
					intl.formatMessage(operatorMessages[OPERATOR_PRESENT]),
				);
			}

			setDoingDropdownInteraction(false);
			setSelectedOperator(
				operatorName,
				() => fieldRef.current?.focus(),
			);

			formContextOnChangeHandler(name, getOutputValue(value, operatorName));
		},
		[
			formContextOnChangeHandler,
			intl,
			name,
			selectedOperator,
			setSelectedOperator,
			shadowValue,
		],
	);

	const handleDropdownMouseEnter = React.useCallback(
		() => {
			setDoingDropdownInteraction(true);
			setResetButtonVisible(
				isPossibleToDisplayResetButton(),
			);
		},
		[
			isPossibleToDisplayResetButton,
		],
	);

	const handleDropdownMouseLeave = React.useCallback(
		() => {
			setDoingDropdownInteraction(false);
			setResetButtonVisible(false);
		},
		[],
	);

	const handleFieldBlur = React.useCallback(
		() => {
			if (!doingDropdownInteraction && !resetButtonVisible) {
				formContextOnBlurHandler(name);

				setShadowValue('');
				setDropdownVisible(false);
			}
		},
		[
			doingDropdownInteraction,
			formContextOnBlurHandler,
			name,
			resetButtonVisible,
		],
	);

	const handleFieldChange = React.useCallback(
		(value) => {
			formContextOnChangeHandler(name, getOutputValue(value, selectedOperator), {
				timeout: 250,
			});

			if (isMetaOperator(selectedOperator) === false) {
				setShadowValue(value);
			}
		},
		[
			formContextOnChangeHandler,
			name,
			selectedOperator,
		],
	);

	const handleFieldFocus = React.useCallback(
		() => {
			formContextOnFocusHandler(name);

			let newShadowValue = fieldRef.current?.getFieldRef().current?.value ?? '';

			if (isMetaOperator(selectedOperator)) {
				newShadowValue = shadowValue;
			}

			setShadowValue(newShadowValue);
			setDoingDropdownInteraction(false);
			setDropdownVisible(true);
			setResetButtonVisible(false);
		},
		[
			formContextOnFocusHandler,
			name,
			selectedOperator,
			shadowValue,
		],
	);

	const handleFieldMouseEnter = React.useCallback(
		() => {
			// set only when we do hover and we don't have focus on field
			setResetButtonVisible(
				isPossibleToDisplayResetButton(),
			);
		},
		[
			isPossibleToDisplayResetButton,
		],
	);

	const handleFieldMouseLeave = React.useCallback(
		() => {
			setResetButtonVisible(false);
		},
		[],
	);

	const handleKeyDown = React.useCallback(
		(e) => {
			e.stopPropagation();

			if (dropdownVisible === false) {
				return;
			}

			let operatorIndex;

			const availableOperators = listOperators().map((item) => item.name);

			// up key
			if (availableOperators.length > 0 && e.keyCode == 38) {
				operatorIndex = availableOperators.indexOf(selectedOperator);

				if (operatorIndex === false) {
					operatorIndex = availableOperators.length - 1;
				} else if (operatorIndex > 0) {
					operatorIndex = operatorIndex - 1;
				}

				handleDropdownOperatorChange(availableOperators[operatorIndex]);
			}

			// down key
			if (availableOperators.length > 0 && e.keyCode == 40) {
				operatorIndex = availableOperators.indexOf(selectedOperator);

				if (operatorIndex === false) {
					operatorIndex = 0;
				} else if (operatorIndex < availableOperators.length - 1) {
					operatorIndex = operatorIndex + 1;
				}

				handleDropdownOperatorChange(availableOperators[operatorIndex]);
			}
		},
		[
			dropdownVisible,
			handleDropdownOperatorChange,
			listOperators,
			selectedOperator,
		],
	);

	const handleKeyUp = React.useCallback(
		(e) => {
			e.stopPropagation();

			if (dropdownVisible) {
				// esc key
				if (e.keyCode === 27) {
					// we have to call it in async way to prevent closing modal
					setTimeout(() => {
						closeDropdown();
					}, 0);
				}
			}
		},
		[
			closeDropdown,
			dropdownVisible,
		],
	);

	const handleResetValue = React.useCallback(
		(e) => {
			e.preventDefault();

			if (!resetButtonVisible && name !== formContextFocused) {
				return false;
			}

			formContextOnChangeHandler(name, '');

			fieldRef.current?.setValue('');

			handleDropdownOperatorChange(
				isMetaOperator(selectedOperator)
					? defaultOperator
					: selectedOperator,
			);
		},
		[
			defaultOperator,
			formContextOnChangeHandler,
			formContextFocused,
			handleDropdownOperatorChange,
			name,
			resetButtonVisible,
			selectedOperator,
		],
	);

	const handleShowMoreClick = React.useCallback(
		() => {
			fieldRef.current?.focus();
		},
		[],
	);

	const handleToggleDropdown = React.useCallback(
		() => {
			if (dropdownVisible) {
				closeDropdown();
			} else {
				revealDropdown();
			}
		},
		[
			closeDropdown,
			dropdownVisible,
			revealDropdown,
		],
	);

	function renderDropdown() {
		const options: Array<FieldDropdownSelectableOptionsProps> = [];

		listOperators().forEach((item) => {
			options.push({
				label: (
					<FormattedMessage {...operatorMessages[item.name]} />
				),
				name: item.name,
				selected: selectedOperator === item.name,
				special: isMetaOperator(item.name),
			});
		});

		const groups = [
			[OPERATOR_CONTAINS, OPERATOR_STARTS_WITH, OPERATOR_EQUALS, OPERATOR_EMPTY_OR_MISSING],
			[OPERATOR_MATCHES, OPERATOR_NOT_MATCHES, OPERATOR_NOT_CONTAINING, OPERATOR_ENDS_WITH],
			[OPERATOR_PRESENT, OPERATOR_EMPTY, OPERATOR_MISSING],
		];

		const lastPositionInGroups = groups
			.map((group) => findLastIndex(options, (option) => isActualOption(option) && group.includes(option.name)))
			.filter((x) => x !== -1)
			.slice(0, -1);

		lastPositionInGroups.forEach((index) => {
			options.splice(index + 1, 0, { divider: true });
		});

		const visibleOptionsCount = getArrayItemAtSafeIndex(lastPositionInGroups, 0) + 1;

		return (
			<FieldDropdownSelectableOptions
				onOptionChangeCallback={handleDropdownOperatorChange}
				onOptionMouseEnterCallback={handleDropdownMouseEnter}
				onOptionMouseLeaveCallback={handleDropdownMouseLeave}
				onShowMoreClickCallback={handleShowMoreClick}
				options={options}
				showMoreLinkLabel={(
					<FormattedMessage {...messages.showMoreOptionsLabel} />
				)}
				visibleOptionsCount={visibleOptionsCount}
			/>
		);
	}

	const additionalAttributes = Object.assign({}, attributes, {
		autoComplete: 'off',
	});

	if (placeholder) {
		additionalAttributes.placeholder = placeholder;
	}

	if (isMetaOperator(selectedOperator)) {
		additionalAttributes.readOnly = 'readonly';
	}

	if (formContext.isDisabled) {
		additionalAttributes.disabled = 'disabled';
	}

	if (maxLength) {
		additionalAttributes.maxLength = maxLength;
	}

	let value = '';

	if (formContext.defaultValues[name]) {
		value = getInputValue(formContext.defaultValues[name]);
	} else {
		value = formContext.defaultValues[name];
	}

	const actionElements = React.useMemo(
		() => {
			if (formContextIsDisabled) {
				return [];
			}

			const actionElements: Array<React.ReactElement> = [];

			if (resetButtonVisible) {
				actionElements.push(
					<ResetButton
						key="reset-button"
						onClick={handleResetValue}
						onMouseEnter={handleFieldMouseEnter}
						onMouseLeave={handleFieldMouseLeave}
						title="Cancel"
					/>,
				);
			} else {
				actionElements.push(
					<ChevronButton
						active={dropdownVisible}
						ignorePointerEvents={!dropdownVisible}
						key="chevron-button"
						onClick={handleToggleDropdown}
						onMouseEnter={handleFieldMouseEnter}
						onMouseLeave={handleFieldMouseLeave}
					/>,
				);
			}

			return actionElements;
		},
		[
			dropdownVisible,
			formContextIsDisabled,
			handleFieldMouseEnter,
			handleFieldMouseLeave,
			handleResetValue,
			handleToggleDropdown,
			resetButtonVisible,
		],
	);

	let calculatedDropdownWidth = dropdownWidth || (isNumber(width) ? width : undefined);

	// don't allow to have dropdown too narrow
	if (isNumber(calculatedDropdownWidth) && calculatedDropdownWidth < DROPDOWN_MIN_WIDTH) {
		calculatedDropdownWidth = DROPDOWN_MIN_WIDTH;
	}

	return (
		<AbstractTextField
			attributes={additionalAttributes}
			buttons={actionElements}
			dropdown={renderDropdown()}
			dropdownHeight="calc(100vh - 240px)"
			dropdownVisible={dropdownVisible}
			dropdownWidth={calculatedDropdownWidth}
			name={name}
			onBlur={handleFieldBlur}
			onChangeCallback={handleFieldChange}
			onFocus={handleFieldFocus}
			onKeyDown={handleKeyDown}
			onKeyUp={handleKeyUp}
			onMouseEnter={handleFieldMouseEnter}
			onMouseLeave={handleFieldMouseLeave}
			ref={fieldRef}
			size={size}
			value={value}
			width={width}
		/>
	);
});



export default ExpandableFilterField;

export {
	AbstractTextFieldSize as ExpandableFilterFieldSize,
};
