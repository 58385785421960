import Immutable, {
	Map,
} from 'immutable';



import {
	CHANGE_URL_STATE,
} from '~/actions';

import {
	ACTION_WEBSITE_ISSUES_COMPARISON_UPDATE_FILTER,
} from '~/actions/websiteIssuesComparison';

import {
	decodeFilterParameter,
} from '~/model/filterParameter';

import {
	DEFAULT_FILTER,
} from '~/model/websiteIssuesComparison';



function createDefaultState() {
	return Map({
		dateFrom: null,
		dateTo: null,
		filter: DEFAULT_FILTER,
		scope: 'website',
		websiteId: null,
	});
}



export function websiteIssuesComparison(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case ACTION_WEBSITE_ISSUES_COMPARISON_UPDATE_FILTER: {
			const {
				filter,
			} = action;

			state = state.set('filter', Immutable.fromJS(Immutable.fromJS(filter).toJS()));

			break;
		}



		case CHANGE_URL_STATE: {
			const {
				urlState,
			} = action;

			if (urlState.params.websiteId !== state.get('websiteId')) {
				state = state.merge({
					filter: DEFAULT_FILTER,
					websiteId: null,
				});
			}

			if (urlState.params.websiteId) {
				state = state.set('websiteId', urlState.params.websiteId);
			}

			if (
				urlState.name.indexOf('website.issues') === 0
			) {
				state = state.set('dateFrom', urlState.params.start_date);
				state = state.set('dateTo', urlState.params.end_date);
			}

			if (
				urlState.name.indexOf('website.issues') === 0
				&& urlState.name.indexOf('website.issues.issueCategory.singleIssueAffectedPages') === -1
			) {
				if (urlState.params.start_date) {
					if (urlState.params.filter) {
						const filter = decodeFilterParameter(urlState.params.filter);

						state = state.set('filter', Immutable.fromJS(filter.filter));
					} else {
						state = state.set('filter', DEFAULT_FILTER);
					}
				}
			}

			break;
		}

	}

	return state;
}
