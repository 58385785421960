import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AssignedWebsitesField from '~/components/app/AssignedWebsitesField';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import LimitedWebsitesAccessField from '~/components/logic/formFields/LimitedWebsitesAccessField';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WebsiteDisplayName from '~/components/app/WebsiteDisplayName';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	useUpdateAssignedWebsitesMutation,
	useUserAssignedWebsitesFormQuery,
} from './UserAssignedWebsitesForm.gql';

import useAllWebsites from '~/hooks/useAllWebsites';
import useUserRole from '~/hooks/useUserRole';

import {
	ObjectType,
} from '~/model/permissions';

import {
	WebsiteAccess,
} from '~/model/users';



const messages = defineMessages({
	title: {
		id: 'ui.memberDetail.sections.websiteAccess.title',
	},
	websiteLabel: {
		id: 'ui.memberDetail.sections.websiteAccess.websites',
	},
});

const scopeMessages = defineMessages({
	[WebsiteAccess.All]: {
		id: 'ui.websiteAccess.allWebsites',
	},
	[WebsiteAccess.Selected]: {
		id: 'ui.websiteAccess.selectedWebsites',
	},
});



type Props = {
	userAccountId: CK.AccountId,
	userId: string | null,
};

const UserAssignedWebsitesForm: React.FC<Props> = (props) => {
	const {
		userAccountId,
		userId,
	} = props;

	const allWebsites = useAllWebsites();
	const userRole = useUserRole(userAccountId, userId);

	const { data } = useUserAssignedWebsitesFormQuery({
		skip: userId === null,
		variables: {
			accountId: userAccountId,
			legacyUserId: userId ?? '',
		},
	});

	const userAccountMembership = data?.userAccountMembership ?? null;

	const [updateAssignedWebsites] = useUpdateAssignedWebsitesMutation();

	const handleSubmit = React.useCallback(
		async ({ assignedWebsites, scope }) => {
			if (userId === null || userRole === null) {
				return;
			}

			await updateAssignedWebsites({
				variables: {
					accountId: userAccountId,
					assignedWebsites: scope === WebsiteAccess.Selected
						? assignedWebsites
						: null,
					hasLimitedWebsitesAccess: scope === WebsiteAccess.Selected,
					legacyId: userId,
					legacyUserId: userId,
					role: userRole,
				},
			});
		},
		[
			updateAssignedWebsites,
			userAccountId,
			userId,
			userRole,
		],
	);

	if (
		allWebsites.isLoaded === false
		|| userAccountMembership === null
		|| userRole === null
	) {
		return null;
	}

	const availableWebsites = allWebsites
		.listByUserAccount(userAccountId)
		.map((website) => website.id);

	const assignedWebsites = userAccountMembership.websites.map(
		(website) => website.id,
	);

	const assignedWebsitesNames = userAccountMembership.websites.map(
		(website) => (
			<WebsiteDisplayName
				key={website.id}
				websiteId={website.id}
			/>
		),
	);

	return (
		<WithPermission
			action={GraphQL.ActionWithAccountMembership.ManageAssignedWebsites}
			objectId={`${userAccountId}/${userId}`}
			objectType={ObjectType.AccountMembership}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							<FormRow
								label={(
									<FormattedMessage {...messages.websiteLabel} />
								)}
							>
								<StaticText focusTarget="scope">
									<FormattedMessage {...scopeMessages[userAccountMembership.hasLimitedWebsitesAccess ? WebsiteAccess.Selected : WebsiteAccess.All]} />
								</StaticText>
							</FormRow>

							{userAccountMembership.hasLimitedWebsitesAccess && (
								<FormRow label=" ">
									<StaticList
										ellipsis={true}
										focusTarget="assignedWebsites"
									>
										{assignedWebsitesNames}
									</StaticList>
								</FormRow>
							)}
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form
							defaultFocus="scope"
							defaultValues={{
								assignedWebsites,
								scope: userAccountMembership.hasLimitedWebsitesAccess
									? WebsiteAccess.Selected
									: WebsiteAccess.All,
							}}
							onSuccess={handleSubmit}
						>
							{({ values }) => {
								return (
									<>
										<FormRows>
											<FormRow
												fullwidth={true}
												htmlFor="scope"
											>
												<LimitedWebsitesAccessField
													name="scope"
												/>
											</FormRow>

											{values.scope === WebsiteAccess.Selected && (
												<FormRow htmlFor="assignedWebsites">
													<AssignedWebsitesField
														allWebsites={availableWebsites}
														name="assignedWebsites"
													/>
												</FormRow>
											)}
										</FormRows>

										<ButtonsLayout layout={ButtonsLayoutType.FormRowWithoutStatus}>
											<CancelButton />
											<SaveSubmitButton />
										</ButtonsLayout>
									</>
								);
							}}
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WithPermission>
	);
};



export default UserAssignedWebsitesForm;
