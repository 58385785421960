import React from 'react';
import {
	useSelector,
} from 'react-redux';

import FileExportForm from '~/components/atoms/forms/forms/FileExportForm';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserLocale from '~/hooks/useUserLocale';

import {
	contextTypeSelector,
} from '~/state/ui/content/selectors';



type Props = {
	closeCallback: () => void,
};

const ExportPanel: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const currentUserId = useCurrentUserId();

	const contextType = useSelector(contextTypeSelector);
	const userLocale = useUserLocale(currentUserId);

	if (!contextType || userLocale === null) {
		return null;
	}

	return (
		<FileExportForm
			currentLocale={userLocale}
			onCancelCallback={closeCallback}
			totalFilteredPages={undefined}
			type={contextType}
		/>
	);
};



export default ExportPanel;
