import React from 'react';

import InterfaceHint from '~/components/patterns/hints/InterfaceHint';

import useInterfaceHint from '~/hooks/useInterfaceHint';



const DotInterfaceHint = (props) => {
	const {
		children,
		conditionsAlready = [],
		conditionsNotAnymore = [],
		content,
		iconPosition,
		inline = true,
		isObsolete,
		name,
	} = props;

	const interfaceHint = useInterfaceHint({
		conditionsAlready,
		conditionsNotAnymore,
		isObsolete,
		name,
	});

	return (
		<InterfaceHint
			bubble={content}
			disabled={!interfaceHint.shouldBeDisplayed}
			inline={inline}
			onCloseCallback={interfaceHint.markClosed}
			onOpenCallback={interfaceHint.markOpened}
			toggleIconPosition={iconPosition}
		>
			{children}
		</InterfaceHint>
	);
};



export default DotInterfaceHint;
