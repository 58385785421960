/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageWordpressEditLinkQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageWordpressEditLinkQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly wordpressEditLink: { readonly __typename?: 'PageWordpressEditLink', readonly link: string | null, readonly unavailabilityReason: GraphQL.WordpressEditLinkUnavailabilityReason | null } } | null };


export const PageWordpressEditLinkDocument = gql`
    query PageWordpressEditLink($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    wordpressEditLink {
      link
      unavailabilityReason
    }
  }
}
    `;

/**
 * __usePageWordpressEditLinkQuery__
 *
 * To run a query within a React component, call `usePageWordpressEditLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageWordpressEditLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageWordpressEditLinkQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageWordpressEditLinkQuery(baseOptions: Apollo.QueryHookOptions<PageWordpressEditLinkQuery, PageWordpressEditLinkQueryVariables> & ({ variables: PageWordpressEditLinkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageWordpressEditLinkQuery, PageWordpressEditLinkQueryVariables>(PageWordpressEditLinkDocument, options);
      }
export function usePageWordpressEditLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageWordpressEditLinkQuery, PageWordpressEditLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageWordpressEditLinkQuery, PageWordpressEditLinkQueryVariables>(PageWordpressEditLinkDocument, options);
        }
export function usePageWordpressEditLinkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageWordpressEditLinkQuery, PageWordpressEditLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageWordpressEditLinkQuery, PageWordpressEditLinkQueryVariables>(PageWordpressEditLinkDocument, options);
        }
export type PageWordpressEditLinkQueryHookResult = ReturnType<typeof usePageWordpressEditLinkQuery>;
export type PageWordpressEditLinkLazyQueryHookResult = ReturnType<typeof usePageWordpressEditLinkLazyQuery>;
export type PageWordpressEditLinkSuspenseQueryHookResult = ReturnType<typeof usePageWordpressEditLinkSuspenseQuery>;
export type PageWordpressEditLinkQueryResult = Apollo.QueryResult<PageWordpressEditLinkQuery, PageWordpressEditLinkQueryVariables>;