/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteSettingsMiscellaneousFormQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteSettingsMiscellaneousFormQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isTrackingOfH2ToH6HistoricallyEnabled: boolean, readonly isTrackingOfNumericOnlyChangesEnabled: boolean, readonly relevantSearchEngines: ReadonlyArray<string> } | null };

export type UpdateWebsiteAuditingSettingsMutationVariables = GraphQL.Exact<{
  isTrackingOfH2ToH6HistoricallyEnabled: GraphQL.Scalars['Boolean']['input'];
  isTrackingOfNumericOnlyChangesEnabled: GraphQL.Scalars['Boolean']['input'];
  relevantSearchEngines: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteAuditingSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteAuditingSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isTrackingOfH2ToH6HistoricallyEnabled: boolean, readonly isTrackingOfNumericOnlyChangesEnabled: boolean, readonly relevantSearchEngines: ReadonlyArray<string> } | null } } };


export const WebsiteSettingsMiscellaneousFormDocument = gql`
    query WebsiteSettingsMiscellaneousForm($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isTrackingOfH2ToH6HistoricallyEnabled
    isTrackingOfNumericOnlyChangesEnabled
    relevantSearchEngines
  }
}
    `;

/**
 * __useWebsiteSettingsMiscellaneousFormQuery__
 *
 * To run a query within a React component, call `useWebsiteSettingsMiscellaneousFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteSettingsMiscellaneousFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteSettingsMiscellaneousFormQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteSettingsMiscellaneousFormQuery(baseOptions: Apollo.QueryHookOptions<WebsiteSettingsMiscellaneousFormQuery, WebsiteSettingsMiscellaneousFormQueryVariables> & ({ variables: WebsiteSettingsMiscellaneousFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteSettingsMiscellaneousFormQuery, WebsiteSettingsMiscellaneousFormQueryVariables>(WebsiteSettingsMiscellaneousFormDocument, options);
      }
export function useWebsiteSettingsMiscellaneousFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteSettingsMiscellaneousFormQuery, WebsiteSettingsMiscellaneousFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteSettingsMiscellaneousFormQuery, WebsiteSettingsMiscellaneousFormQueryVariables>(WebsiteSettingsMiscellaneousFormDocument, options);
        }
export function useWebsiteSettingsMiscellaneousFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteSettingsMiscellaneousFormQuery, WebsiteSettingsMiscellaneousFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteSettingsMiscellaneousFormQuery, WebsiteSettingsMiscellaneousFormQueryVariables>(WebsiteSettingsMiscellaneousFormDocument, options);
        }
export type WebsiteSettingsMiscellaneousFormQueryHookResult = ReturnType<typeof useWebsiteSettingsMiscellaneousFormQuery>;
export type WebsiteSettingsMiscellaneousFormLazyQueryHookResult = ReturnType<typeof useWebsiteSettingsMiscellaneousFormLazyQuery>;
export type WebsiteSettingsMiscellaneousFormSuspenseQueryHookResult = ReturnType<typeof useWebsiteSettingsMiscellaneousFormSuspenseQuery>;
export type WebsiteSettingsMiscellaneousFormQueryResult = Apollo.QueryResult<WebsiteSettingsMiscellaneousFormQuery, WebsiteSettingsMiscellaneousFormQueryVariables>;
export const UpdateWebsiteAuditingSettingsDocument = gql`
    mutation UpdateWebsiteAuditingSettings($isTrackingOfH2ToH6HistoricallyEnabled: Boolean!, $isTrackingOfNumericOnlyChangesEnabled: Boolean!, $relevantSearchEngines: [String!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteAuditingSettings(
    trackH2ToH6Historically: $isTrackingOfH2ToH6HistoricallyEnabled
    trackNumericOnlyChanges: $isTrackingOfNumericOnlyChangesEnabled
    relevantSearchEngines: $relevantSearchEngines
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        isTrackingOfH2ToH6HistoricallyEnabled
        isTrackingOfNumericOnlyChangesEnabled
        relevantSearchEngines
      }
    }
  }
}
    `;
export type UpdateWebsiteAuditingSettingsMutationFn = Apollo.MutationFunction<UpdateWebsiteAuditingSettingsMutation, UpdateWebsiteAuditingSettingsMutationVariables>;

/**
 * __useUpdateWebsiteAuditingSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteAuditingSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteAuditingSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteAuditingSettingsMutation, { data, loading, error }] = useUpdateWebsiteAuditingSettingsMutation({
 *   variables: {
 *      isTrackingOfH2ToH6HistoricallyEnabled: // value for 'isTrackingOfH2ToH6HistoricallyEnabled'
 *      isTrackingOfNumericOnlyChangesEnabled: // value for 'isTrackingOfNumericOnlyChangesEnabled'
 *      relevantSearchEngines: // value for 'relevantSearchEngines'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteAuditingSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteAuditingSettingsMutation, UpdateWebsiteAuditingSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteAuditingSettingsMutation, UpdateWebsiteAuditingSettingsMutationVariables>(UpdateWebsiteAuditingSettingsDocument, options);
      }
export type UpdateWebsiteAuditingSettingsMutationHookResult = ReturnType<typeof useUpdateWebsiteAuditingSettingsMutation>;
export type UpdateWebsiteAuditingSettingsMutationResult = Apollo.MutationResult<UpdateWebsiteAuditingSettingsMutation>;
export type UpdateWebsiteAuditingSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteAuditingSettingsMutation, UpdateWebsiteAuditingSettingsMutationVariables>;