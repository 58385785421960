import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useUserRole from '~/hooks/useUserRole';



const messages = defineMessages({
	[GraphQL.UserRole.Admin]: {
		id: 'ui.general.notAccessibleFeature',
	},
	[GraphQL.UserRole.Editor]: {
		id: 'ui.general.notAccessibleFeature',
	},
	[GraphQL.UserRole.Manager]: {
		id: 'ui.general.notAccessibleFeatureToManager',
	},
	[GraphQL.UserRole.Viewer]: {
		id: 'ui.general.notAccessibleFeature',
	},
});



const InsufficientPermissionMessage: React.FC = () => {
	const currentUserId = useCurrentUserId();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const role = useUserRole(effectiveHomeAccountId, currentUserId) || GraphQL.UserRole.Viewer;

	return (
		<FormattedMessage {...messages[role]} />
	);
};



export default InsufficientPermissionMessage;
