import classNames from 'classnames';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import styles from './TextPreloader.module.scss';



const messages = defineMessages({
	loading: {
		id: 'ui.general.loading',
		defaultMessage: 'Loading',
	},
});



export enum TextPreloaderSize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	size?: TextPreloaderSize,
};



const TextPreloader: React.FC<Props> = (props) => {
	const {
		size = TextPreloaderSize.Default,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles[size + 'Size'] ?? '']: true,
	});

	return (
		<div className={componentClasses}>
			<div className={styles.text}>
				<FormattedMessage {...messages.loading} />
			</div>
			<div className={styles.dots}>
				<div className={styles.dotsWrapper}>
					<span>.</span>
					<span>.</span>
					<span>.</span>
				</div>
			</div>
		</div>
	);
};



export default TextPreloader;
