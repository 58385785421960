import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import {
	linkInternal,
} from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InternalLink from '~/components/patterns/links/InternalLink';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WithPermission from '~/components/logic/access/WithPermission';

import useMessagingAppChannels from '~/hooks/useMessagingAppChannels';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	connect: {
		id: 'ui.slack.connect',
	},
	enabledAndChange: {
		id: 'ui.websites.form.api.slack.enabled',
	},
	labelMicrosoftTeams: {
		id: 'ui.websites.form.api.microsoftTeams.title',
	},
	labelSlack: {
		id: 'ui.websites.form.api.slack.title',
	},
	title: {
		id: 'ui.websites.form.messagingAppsTitle',
	},
});

const pluginStatusMessages = defineMessages({
	available: {
		id: 'ui.websites.form.api.status.available',
	},
	enabled: {
		id: 'ui.websites.form.api.status.enabled',
	},
});



const WebsiteIntegrationsMessagingAppsBlock: React.FC = () => {
	const messagingAppChannels = useMessagingAppChannels();
	const websiteId = useWebsiteId();

	const microsoftTeamsMessagingAppChannels = messagingAppChannels.listByType(GraphQL.MessagingAppType.MicrosoftTeams);
	const isMicrosoftTeamsConnected = microsoftTeamsMessagingAppChannels.length > 0;

	const slackMessagingAppChannels = messagingAppChannels.listByType(GraphQL.MessagingAppType.Slack);
	const isSlackConnected = slackMessagingAppChannels.length > 0;

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					key={websiteId}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							<FormRow
								label={(
									<FormattedMessage {...messages.labelSlack} />
								)}
							>
								<StaticText>
									<FormattedMessage {...pluginStatusMessages[isSlackConnected ? 'enabled' : 'available']} />
								</StaticText>
							</FormRow>

							<PremiumFeatureSituation
								featureName={GraphQL.AccountFeature.MicrosoftTeams}
								hideIfUnattainable={true}
								style={PremiumFeatureSituationStyle.Ribbon}
							>
								{({ isFeatureEnabled, premiumAnnotation }) => (
									<DisabledContent
										disabledContent={!isFeatureEnabled}
										disabledOverlay={!isFeatureEnabled && premiumAnnotation}
									>
										<FormRow
											label={(
												<FormattedMessage {...messages.labelMicrosoftTeams} />
											)}
										>
											<StaticText>
												{!isFeatureEnabled ? (
													<FormattedMessage {...messages.connect} />
												) : isMicrosoftTeamsConnected ? (
													<FormattedMessage {...pluginStatusMessages.enabled} />
												) : (
													<FormattedMessage {...pluginStatusMessages.available} />
												)}
											</StaticText>
										</FormRow>
									</DisabledContent>
								)}
							</PremiumFeatureSituation>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form>
							<FormRows>
								<FormRow
									label={(
										<FormattedMessage {...messages.labelSlack} />
									)}
								>
									<StaticText>
										{isSlackConnected ? (
											<FormattedMessage
												{...messages.enabledAndChange}
												values={{
													link_alert_definitions: linkInternal(
														'website.alerts.configuration',
														{
															websiteId,
														},
													),
												}}
											/>
										) : (
											<InternalLink
												routeName="website.alerts.configuration"
												routeParams={{
													websiteId,
												}}
											>
												<FormattedMessage {...messages.connect} />
											</InternalLink>
										)}
									</StaticText>
								</FormRow>

								<PremiumFeatureSituation
									featureName={GraphQL.AccountFeature.MicrosoftTeams}
									hideIfUnattainable={true}
									style={PremiumFeatureSituationStyle.Ribbon}
								>
									{({ isFeatureEnabled, premiumAnnotation }) => (
										<DisabledContent
											disabledContent={!isFeatureEnabled}
											disabledOverlay={!isFeatureEnabled && premiumAnnotation}
										>
											<FormRow
												label={(
													<FormattedMessage {...messages.labelMicrosoftTeams} />
												)}
											>
												<StaticText>
													{!isFeatureEnabled ? (
														<FormattedMessage {...messages.connect} />
													) : isMicrosoftTeamsConnected ? (
														<FormattedMessage
															{...messages.enabledAndChange}
															values={{
																link_alert_definitions: linkInternal(
																	'website.alerts.configuration',
																	{
																		websiteId,
																	},
																),
															}}
														/>
													) : (
														<InternalLink
															routeName="website.alerts.configuration"
															routeParams={{
																websiteId,
															}}
														>
															<FormattedMessage {...messages.connect} />
														</InternalLink>
													)}
												</StaticText>
											</FormRow>
										</DisabledContent>
									)}
								</PremiumFeatureSituation>
							</FormRows>

							<ButtonsLayout>
								<CancelButton />
							</ButtonsLayout>
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WithPermission>
	);
};



export default WebsiteIntegrationsMessagingAppsBlock;
