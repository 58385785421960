/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrialBarQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type TrialBarQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isDemoAvailable: boolean, readonly isProfileCompletenessAvailable: boolean, readonly profileCompleteness: { readonly __typename?: 'AccountProfileCompleteness', readonly tasks: ReadonlyArray<{ readonly __typename?: 'AccountProfileCompletenessTaskStatus', readonly isDone: boolean, readonly type: GraphQL.AccountProfileCompletenessTask }> } | null } | null, readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isDemoAvailable: boolean, readonly isProfileCompletenessAvailable: boolean, readonly profileCompleteness: { readonly __typename?: 'AccountProfileCompleteness', readonly tasks: ReadonlyArray<{ readonly __typename?: 'AccountProfileCompletenessTaskStatus', readonly isDone: boolean, readonly type: GraphQL.AccountProfileCompletenessTask }> } | null }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly user: { readonly __typename?: 'User', readonly id: string, readonly isPlatformTourWebinarOfferingAvailable: boolean, readonly legacyId: string } } | null };


export const TrialBarDocument = gql`
    query TrialBar($accountId: AccountId!) {
  account(id: $accountId) {
    id
    isDemoAvailable
    isProfileCompletenessAvailable
    profileCompleteness {
      tasks {
        isDone
        type
      }
    }
  }
  authenticatedSession {
    memberships {
      account {
        id
        isDemoAvailable
        isProfileCompletenessAvailable
        profileCompleteness {
          tasks {
            isDone
            type
          }
        }
      }
      user {
        id
        legacyId
      }
    }
    user {
      id
      isPlatformTourWebinarOfferingAvailable
      legacyId
    }
  }
}
    `;

/**
 * __useTrialBarQuery__
 *
 * To run a query within a React component, call `useTrialBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialBarQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useTrialBarQuery(baseOptions: Apollo.QueryHookOptions<TrialBarQuery, TrialBarQueryVariables> & ({ variables: TrialBarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialBarQuery, TrialBarQueryVariables>(TrialBarDocument, options);
      }
export function useTrialBarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialBarQuery, TrialBarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialBarQuery, TrialBarQueryVariables>(TrialBarDocument, options);
        }
export function useTrialBarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TrialBarQuery, TrialBarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrialBarQuery, TrialBarQueryVariables>(TrialBarDocument, options);
        }
export type TrialBarQueryHookResult = ReturnType<typeof useTrialBarQuery>;
export type TrialBarLazyQueryHookResult = ReturnType<typeof useTrialBarLazyQuery>;
export type TrialBarSuspenseQueryHookResult = ReturnType<typeof useTrialBarSuspenseQuery>;
export type TrialBarQueryResult = Apollo.QueryResult<TrialBarQuery, TrialBarQueryVariables>;