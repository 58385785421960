import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AssignedWebsitesField from '~/components/app/AssignedWebsitesField';
import BackButton from '~/components/app/BackButton';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import LimitedWebsitesAccessField from '~/components/logic/formFields/LimitedWebsitesAccessField';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import RichText from '~/components/patterns/typography/RichText';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';
import SubmitButton from '~/components/app/SubmitButton';
import UnifiedHeightContainer from '~/components/patterns/utils/UnifiedHeightContainer';

import useAccountId from '~/hooks/useAccountId';
import useAllWebsites from '~/hooks/useAllWebsites';

import {
	WebsiteAccess,
} from '~/model/users';



const messages = defineMessages({
	prompt: {
		id: 'ui.websiteAccess.prompt',
	},
	submitButton: {
		id: 'ui.changeRoleModal.steps.changeRole.content.submitButton.changeRole',
	},
});

const validations = {
	assignedWebsites: [
		{
			message: '',
			fields: ['assignedWebsites', 'scope'],
			rule: ({ name, values }) => {
				return values.scope !== WebsiteAccess.Selected || (values[name] && values[name].length > 0);
			},
		},
	],
	scope: [
		{
			message: '',
			fields: ['assignedWebsites', 'scope'],
			rule: ({ name, values }) => {
				return !!values[name];
			},
		},
	],
};



type Props = {
	hasLimitedWebsitesAccess: boolean,
	onSubmitCallback: (payload: {
		assignedWebsites: ReadonlyArray<CK.WebsiteId>,
		scope: WebsiteAccess,
	}) => void,
	websites: ReadonlyArray<{
		id: CK.WebsiteId,
	}>,
};

const ChangeUserRoleAssignWebsitesForm: React.FC<Props> = (props) => {
	const {
		hasLimitedWebsitesAccess,
		onSubmitCallback,
		websites,
	} = props;

	const accountId = useAccountId();
	const allWebsites = useAllWebsites();

	if (
		accountId === null
		|| allWebsites.isLoaded === false
	) {
		return null;
	}

	const availableWebsites = allWebsites
		.listByUserAccount(accountId)
		.map((website) => website.id);

	const scope = hasLimitedWebsitesAccess
		? WebsiteAccess.Selected
		: WebsiteAccess.All;

	const assignedWebsites = scope === WebsiteAccess.Selected
		? websites.map((website) => website.id)
		: availableWebsites;

	return (
		<Form
			defaultDataHasChanged={true}
			defaultFocus="assignedWebsites"
			defaultValues={{
				assignedWebsites,
				scope,
			}}
			onSuccess={onSubmitCallback}
			validations={validations}
		>
			{({ values }) => (
				<>
					<UnifiedHeightContainer>
						<ModalContentSection>
							<FormRows>
								<RichText>
									<Copy {...messages.prompt} />
								</RichText>

								<Spacer left={SpacerGap.Growing}>
									<FormRow
										fullwidth={true}
										htmlFor="scope"
									>
										<LimitedWebsitesAccessField
											name="scope"
										/>
									</FormRow>
								</Spacer>
							</FormRows>
						</ModalContentSection>

						<div style={{ display: values.scope === WebsiteAccess.Selected ? 'block' : 'none' }}>
							<FormRow
								fullwidth={true}
								htmlFor="assignedWebsites"
							>
								<AssignedWebsitesField
									allWebsites={availableWebsites}
									height={200}
									name="assignedWebsites"
								/>
							</FormRow>
						</div>
					</UnifiedHeightContainer>

					<ButtonsLayout layout={ButtonsLayoutType.Steps}>
						<BackButton />

						<SubmitButton>
							<FormattedMessage {...messages.submitButton} />
						</SubmitButton>
					</ButtonsLayout>
				</>
			)}
		</Form>
	);
};



export default ChangeUserRoleAssignWebsitesForm;
