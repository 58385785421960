import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const NetworkIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
				<g id="issue_network">
					<g id="Platform">
						<g id="[1440]-Platform-MissingStates">
							<g id="issues">
								<g id="Cards">
									<g id="issueCardCategoryOverview">
										<g id="thumbnail">
											<g id="network">
												<polygon fill="#A5A5A5" fillOpacity="0" fillRule="nonzero" id="Fill-334" points="60 60 0 60 0 0 60 0"></polygon>
												<polygon id="Stroke-335" points="35.623125 20.625 24.373125 20.625 24.373125 9.375 35.623125 9.375" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></polygon>
												<polygon id="Stroke-336" points="35.623125 48.75 24.373125 48.75 24.373125 37.5 35.623125 37.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></polygon>
												<polygon id="Stroke-337" points="18.748125 48.75 7.498125 48.75 7.498125 37.5 18.748125 37.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></polygon>
												<polygon id="Stroke-338" points="52.498125 48.75 41.248125 48.75 41.248125 37.5 52.498125 37.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></polygon>
												<path d="M29.998875,20.625 L29.998875,37.5" id="Stroke-339" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></path>
												<polyline id="Stroke-340" points="13.12275 37.5 13.12275 30 46.87275 30 46.87275 37.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={(60 / size) * 2}></polyline>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default NetworkIconBuilder;

