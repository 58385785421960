import {
	format,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Form from '../basis/Form';
import FormRow from '../basis/FormRow';
import SelectField from '../components/SelectField';

import usePredefinedDateRanges from '~/hooks/usePredefinedDateRanges';

import {
	DateRangePreset,
	findPredefinedRangeForDates,
	getDateRangeDefinition,
} from '~/model/dateRangePresets';



const messages = defineMessages({
	dateRangeLabel: {
		id: 'ui.dateRange.rangeLabel',
	},
	endDateLabel: {
		id: 'ui.dateRange.endDateLabel',
	},
	startDateLabel: {
		id: 'ui.dateRange.startDateLabel',
	},
});



type Props = {
	endDate: Date | null,
	minDate: Date,
	onSelectCallback: (any) => void,
	startDate: Date | null,
};


const DATE_FORMAT = 'yyyy-MM-dd';

const DateRangePickerForm: React.FC<Props> = (props) => {
	const {
		endDate,
		minDate,
		onSelectCallback,
		startDate,
	} = props;

	const rangeOptions = usePredefinedDateRanges(
		minDate,
		[DateRangePreset.Everything],
	).map((preset) => ({
		...preset,
		disabled: preset.value === DateRangePreset.Custom,
	}));

	const selectedDateRange = findPredefinedRangeForDates(
		startDate ?? null,
		endDate ?? null,
		minDate,
		[DateRangePreset.Everything],
	);

	function handleFormChange(field, value) {
		if (field === 'selectedDateRange' && value !== DateRangePreset.Custom) {
			const dateRange = getDateRangeDefinition(value);

			onSelectCallback({
				startDate: dateRange.startDate(minDate),
				endDate: dateRange.endDate(),
			});
		}
	}


	return (
		<Form
			defaultValues={{
				selectedDateRange,
				endDate: endDate ? format(endDate, DATE_FORMAT) : null,
				startDate: startDate ? format(startDate, DATE_FORMAT) : null,
			}}
			onChangeCallback={handleFormChange}
		>
			<FormRow
				htmlFor="selectedDateRange"
				label={(
					<FormattedMessage {...messages.dateRangeLabel} />
				)}
			>
				<SelectField
					name="selectedDateRange"
					options={rangeOptions}
				/>
			</FormRow>
		</Form>
	);
};



export default DateRangePickerForm;
