import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import AppearAnimation, {
	AppearAnimationDirection,
} from '~/components/patterns/animations/AppearAnimation';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SelectField from '~/components/atoms/forms/components/SelectField';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
	validateFields,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';

import {
	assertDictionaryId,
} from '~/localization/dictionaries';

import {
	countries,
	isCanada,
	isUSA,
	listStates,
} from '~/utilities/countries';



const messages = defineMessages({
	address: {
		id: 'ui.newTeam.form.address',
	},
	country: {
		id: 'ui.newTeam.form.country',
	},
	name: {
		id: 'ui.creditCard.name',
	},
	province: {
		id: 'ui.newTeam.form.province',
	},
	state: {
		id: 'ui.newTeam.form.state',
	},
	zipCodeAndCity: {
		id: 'ui.newTeam.form.zipCodeAndCity',
	},
});



const AddressFields: React.FC = () => {
	const country = useFormContext().values.country;
	const intl = useIntl();

	const showState = isUSA(country) || isCanada(country);

	const countryOptions = React.useMemo(
		() => {
			return countries.map((code) => {
				const messageId = `countries.${code}`;

				assertDictionaryId(messageId);

				return {
					label: intl.formatMessage({ id: messageId }),
					name: code,
				};
			}).sort(({ label: labelA }, { label: labelB }) => {
				return labelA.localeCompare(labelB);
			});
		},
		[
			intl,
		],
	);

	const stateOptions = React.useMemo(
		() => {
			if (country === null) {
				return [];
			}

			return listStates(country).map((code) => {
				const messageId = `states.${country}.${code}`;

				assertDictionaryId(messageId);

				return {
					label: intl.formatMessage({ id: messageId }),
					name: code,
				};
			}).sort(({ label: labelA }, { label: labelB }) => {
				return labelA.localeCompare(labelB);
			});
		},
		[
			country,
			intl,
		],
	);

	return (
		<FormRows>
			<FormRow
				htmlFor="name"
				label={(
					<FormattedMessage {...messages.name} />
				)}
			>
				<FieldStatus name="validateName">
					<TextField
						emptyAsNull={true}
						name="name"
						trimValue={true}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				htmlFor="address"
				label={(
					<FormattedMessage {...messages.address} />
				)}
			>
				<FieldStatus name="validateAddress">
					<TextField
						emptyAsNull={true}
						name="address"
						trimValue={true}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				htmlFor="postalCode"
				label={(
					<FormattedMessage {...messages.zipCodeAndCity} />
				)}
			>
				<FieldStatus name="validateCityAndPostalCode">
					<TextField
						emptyAsNull={true}
						name="postalCode"
						trimValue={true}
						width={85}
					/>

					<TextField
						emptyAsNull={true}
						name="city"
						trimValue={true}
						width={185}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				htmlFor="country"
				label={(
					<FormattedMessage {...messages.country} />
				)}
			>
				<FieldStatus name="validateCountry">
					<SelectField
						isInteractedByDefault={false}
						name="country"
						options={countryOptions}
						searchable={true}
					/>
				</FieldStatus>
			</FormRow>

			{showState && (
				<AppearAnimation
					delay={50}
					direction={AppearAnimationDirection.Down}
				>
					<FormRow
						htmlFor="state"
						label={(
							<FormattedMessage {...messages[isUSA(country) ? 'state' : 'province']} />
						)}
					>
						<FieldStatus name="validateState">
							<SelectField
								isInteractedByDefault={false}
								name="state"
								options={stateOptions}
								searchable={true}
							/>
						</FieldStatus>
					</FormRow>
				</AppearAnimation>
			)}
		</FormRows>
	);
};



export default AddressFields;

export const validateAddress = validateField(
	'address',
	(f) => [
		f.validateNonEmpty(),
		f.validateMaximumLength(50),
	],
);

export const validateCityAndPostalCode = validateFields(
	['city', 'postalCode'],
	{
		city: (f) => [
			f.validateNonEmpty(),
			f.validateMaximumLength(50),
		],
		postalCode: (f) => [
			f.validateNonEmpty(),
			f.validateMaximumLength(20),
		],
	},
);

export const validateCountry = validateField(
	'country',
	(f) => [
		f.validateNonEmpty(),
	],
);

export const validateName = validateField(
	'name',
	(f) => [
		f.validateNonEmpty(),
		f.validateMaximumLength(50),
	],
);

export const validateState = validateField(
	'state',
	(f) => [
		f.whenOtherField(
			'country',
			({ value }) => isUSA(value) || isCanada(value),
		),
		f.validateNonEmpty(),
		f.validateMaximumLength(50),
	],
);
