import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import Header from '../sitemapsList/Header';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import SitemapsListDatatable, {
	type SitemapsListDatatableHandle,
} from '~/components/logic/sitemapsList/SitemapsListDatatable';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	loadSitemapsList,
	syncSitemapsList,
} from '~/actions/sitemapsList';

import {
	SYNC_INTERVAL_SITEMAPS_LIST,
} from '~/model/sitemapsList';

import {
	currentDataSelector,
} from '~/state/sitemapsList/selectors';



type Props = {
	backlink?: string,
	closeCallback?: () => void,
};

const SitemapsListScreen: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
	} = props;

	const dispatch = useDispatch();
	const websiteId = useWebsiteId();
	const sitemapsList = useSelector(currentDataSelector);

	const datatableRef = React.useRef<SitemapsListDatatableHandle | null>(null);

	React.useEffect(
		() => {
			dispatch(
				loadSitemapsList(0),
			);

			const interval = setInterval(() => {
				dispatch(
					syncSitemapsList(),
				);
			}, SYNC_INTERVAL_SITEMAPS_LIST);

			return () => {
				clearInterval(interval);
			};
		},
		[
			dispatch,
			websiteId,
		],
	);

	const handleActiveFilterClick = React.useCallback(
		(columnName) => {
			datatableRef.current?.scrollToColumn(columnName);
		},
		[
			datatableRef,
		],
	);

	return (
		<ScreenLayout
			header={(
				<Header
					activeFilterClickCallback={handleActiveFilterClick}
					backlinkCallback={closeCallback}
					backlinkLabel={backlink}
					numberOfSitemaps={sitemapsList?.get('total') ?? null}
				/>
			)}
		>
			<SitemapsListDatatable
				ref={datatableRef}
			/>
		</ScreenLayout>
	);
};

export default SitemapsListScreen;
