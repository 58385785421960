import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';
import TextField from '~/components/atoms/forms/components/TextField';
import YesOrNo from '~/components/app/YesOrNo';

import {
	useOrganizationAccessFormQuery,
	useUpdateOrganizationAccessControlMutation,
} from './OrganizationAccessForm.gql';

import useAccountId from '~/hooks/useAccountId';
import useAllUsers from '~/hooks/useAllUsers';

import {
	isEmailWhitelisted,
} from '~/model/users';

import {
	isDomainWhitelisted,
} from '~/model/websites';



const messages = defineMessages({
	fieldDomainsWhitelist: {
		id: 'ui.organizationAccess.domainsWhitelist',
	},
	fieldEmailDomainsWhitelist: {
		id: 'ui.organizationAccess.emailDomainsWhitelist',
	},
	fieldEnforced2FA: {
		id: 'ui.organizationAccess.enforced2FA',
	},
	fieldManagersCanAffectSubscription: {
		id: 'ui.organizationAccess.managersCanAffectSubscription',
	},
	fieldManagersCanControlAgencyAccess: {
		id: 'ui.organizationAccess.managersCanControlAgencyAccess',
	},
	fieldManagersCanSeeApiTokens: {
		id: 'ui.organizationAccess.managersCanSeeApiTokens',
	},
	formErrorDomainsWhitelistViolated: {
		id: 'ui.organizationAccess.domainsWhitelist.errors.violated',
	},
	formErrorEmailDomainsWhitelistViolated: {
		id: 'ui.organizationAccess.emailDomainsWhitelist.errors.violated',
	},
	formErrorEnforced2FAViolated: {
		id: 'ui.organizationAccess.enforced2FA.errors.violated',
	},
	title: {
		id: 'ui.organizationAccess.title',
	},
});



const OrganizationAccessForm: React.FC = () => {
	const accountId = useAccountId();
	const allUsers = useAllUsers();

	const { data } = useOrganizationAccessFormQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const account = data?.account ?? null;
	const websiteDomains = account?.websites.map((website) => website.domain) ?? [];

	const [updateOrganizationAccessControl] = useUpdateOrganizationAccessControlMutation();

	const handleSubmit = React.useCallback(
		async ({
			canManagersAffectSubscription,
			canManagersControlAgencyAccess,
			canManagersSeeApiTokens,
			domainsWhitelist,
			emailDomainsWhitelist,
			isTwoFactorAuthenticationEnforced,
		}) => {
			if (accountId === null) {
				return;
			}

			await updateOrganizationAccessControl({
				variables: {
					accountId,
					canManagersAffectSubscription,
					canManagersControlAgencyAccess,
					canManagersSeeApiTokens,
					domainsWhitelist,
					emailDomainsWhitelist,
					isTwoFactorAuthenticationEnforced,
				},
			});
		},
		[
			accountId,
			updateOrganizationAccessControl,
		],
	);

	if (
		account === null
		|| accountId === null
		|| allUsers.isLoaded === false
	) {
		return null;
	}

	return (
		<EditableFormWrapper
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						htmlFor="isTwoFactorAuthenticationEnforced"
						label={(
							<FormattedMessage {...messages.fieldEnforced2FA} />
						)}
					>
						<StaticText focusTarget="isTwoFactorAuthenticationEnforced">
							<YesOrNo state={account.isTwoFactorAuthenticationEnforced} />
						</StaticText>
					</FormRow>

					<FormRow
						htmlFor="emailDomainsWhitelist"
						label={(
							<FormattedMessage {...messages.fieldEmailDomainsWhitelist} />
						)}
					>
						<StaticList focusTarget="emailDomainsWhitelist">
							{account.userRestrictions.emailDomainsWhitelist}
						</StaticList>
					</FormRow>

					<FormRow
						htmlFor="canManagersAffectSubscription"
						label={(
							<FormattedMessage {...messages.fieldManagersCanAffectSubscription} />
						)}
					>
						<StaticText focusTarget="canManagersAffectSubscription">
							<YesOrNo state={account.canManagersAffectSubscription} />
						</StaticText>
					</FormRow>

					<FormRow
						htmlFor="canManagersControlAgencyAccess"
						label={(
							<FormattedMessage {...messages.fieldManagersCanControlAgencyAccess} />
						)}
					>
						<StaticText focusTarget="canManagersControlAgencyAccess">
							<YesOrNo state={account.canManagersControlAgencyAccess} />
						</StaticText>
					</FormRow>

					<FormRow
						htmlFor="canManagersSeeApiTokens"
						label={(
							<FormattedMessage {...messages.fieldManagersCanSeeApiTokens} />
						)}
					>
						<StaticText focusTarget="canManagersSeeApiTokens">
							<YesOrNo state={account.canManagersSeeApiTokens} />
						</StaticText>
					</FormRow>

					<FormRow
						htmlFor="domainsWhitelist"
						label={(
							<FormattedMessage {...messages.fieldDomainsWhitelist} />
						)}
					>
						<StaticList focusTarget="domainsWhitelist">
							{account.websiteRestrictions.domainsWhitelist}
						</StaticList>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						canManagersAffectSubscription: account.canManagersAffectSubscription,
						canManagersControlAgencyAccess: account.canManagersControlAgencyAccess,
						canManagersSeeApiTokens: account.canManagersSeeApiTokens,
						domainsWhitelist: (account.websiteRestrictions.domainsWhitelist ?? []).join(','),
						emailDomainsWhitelist: (account.userRestrictions.emailDomainsWhitelist ?? []).join(','),
						isTwoFactorAuthenticationEnforced: account.isTwoFactorAuthenticationEnforced,
					}}
					onSuccess={handleSubmit}
					validations={{
						domainsWhitelist: [
							{
								message: (
									<FormattedMessage {...messages.formErrorDomainsWhitelistViolated} />
								),
								field: 'domainsWhitelist',
								rule: ({ values, name }) => {
									if (values[name] === '') {
										return true;
									}

									return websiteDomains.every(
										(websiteDomain) => isDomainWhitelisted(
											websiteDomain,
											values[name].split(',').map((rule) => rule.trim()),
										),
									);
								},
							},
						],
						emailDomainsWhitelist: [
							{
								message: (
									<FormattedMessage {...messages.formErrorEmailDomainsWhitelistViolated} />
								),
								field: 'emailDomainsWhitelist',
								rule: ({ values, name }) => {
									if (values[name] === '') {
										return true;
									}

									return allUsers.listByAccount(accountId).every(
										(user) => isEmailWhitelisted(
											user.email,
											values[name].split(',').map((rule) => rule.trim()),
										),
									);
								},
							},
						],
						isTwoFactorAuthenticationEnforced: [
							{
								message: (
									<FormattedMessage {...messages.formErrorEnforced2FAViolated} />
								),
								field: 'isTwoFactorAuthenticationEnforced',
								rule: ({ values, name }) => {
									if (!values[name]) {
										return true;
									}

									return allUsers.listByAccount(accountId).every(
										(user) => user.hasTwoFactorAuthentication,
									);
								},
							},
						],
					}}
				>
					<FormRows>
						<FormRow
							htmlFor="isTwoFactorAuthenticationEnforced"
							label={(
								<FormattedMessage {...messages.fieldEnforced2FA} />
							)}
						>
							<FieldStatus name="isTwoFactorAuthenticationEnforced">
								<SwitchField
									activeStateLabel={(
										<YesOrNo state={true} />
									)}
									inactiveStateLabel={(
										<YesOrNo state={false} />
									)}
									name="isTwoFactorAuthenticationEnforced"
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="emailDomainsWhitelist"
							label={(
								<FormattedMessage {...messages.fieldEmailDomainsWhitelist} />
							)}
						>
							<FieldStatus name="emailDomainsWhitelist">
								<TextField
									name="emailDomainsWhitelist"
									trimValue={true}
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="canManagersAffectSubscription"
							label={(
								<FormattedMessage {...messages.fieldManagersCanAffectSubscription} />
							)}
						>
							<FieldStatus name="canManagersAffectSubscription">
								<SwitchField
									activeStateLabel={(
										<YesOrNo state={true} />
									)}
									inactiveStateLabel={(
										<YesOrNo state={false} />
									)}
									name="canManagersAffectSubscription"
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="canManagersControlAgencyAccess"
							label={(
								<FormattedMessage {...messages.fieldManagersCanControlAgencyAccess} />
							)}
						>
							<FieldStatus name="canManagersControlAgencyAccess">
								<SwitchField
									activeStateLabel={(
										<YesOrNo state={true} />
									)}
									inactiveStateLabel={(
										<YesOrNo state={false} />
									)}
									name="canManagersControlAgencyAccess"
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="canManagersSeeApiTokens"
							label={(
								<FormattedMessage {...messages.fieldManagersCanSeeApiTokens} />
							)}
						>
							<FieldStatus name="canManagersSeeApiTokens">
								<SwitchField
									activeStateLabel={(
										<YesOrNo state={true} />
									)}
									inactiveStateLabel={(
										<YesOrNo state={false} />
									)}
									name="canManagersSeeApiTokens"
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="domainsWhitelist"
							label={(
								<FormattedMessage {...messages.fieldDomainsWhitelist} />
							)}
						>
							<FieldStatus name="domainsWhitelist">
								<TextField
									name="domainsWhitelist"
									trimValue={true}
								/>
							</FieldStatus>
						</FormRow>
					</FormRows>

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default OrganizationAccessForm;
