import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const TickIconBuilder: IconBuilder = {
	icon: ({ color = '#98A5B3' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="success"
					transform="translate(3.000000, 3.000000)"
				>
					<circle
						cx="9"
						cy="9"
						fill={color}
						id="base"
						r="9"
					>
					</circle>
					<g
						fill="#FAFAFA"
						id="checkIcon"
						transform="translate(4.500000, 5.875000)"
					>
						<path
							d="M4.43509804,5.78257089 L3.8024833,6.45622289 C3.40597173,6.87845589 2.75628541,6.85375989 2.38908541,6.40237188 L0.529893835,4.11799188 C0.29218015,3.82575589 0.306674888,3.38774489 0.563392782,3.11437389 C0.820110677,2.84134589 1.2314391,2.82556789 1.50555068,3.07870189 L4.43509804,5.78257089"
							id="Fill-2"
						>
						</path>
						<path
							d="M1.93008541,5.53972689 L7.27220121,0.290454888 C7.595917,-0.0285351111 8.09840121,-0.0182451111 8.4101991,0.313778889 C8.73069383,0.655063889 8.73069383,1.20832289 8.4101991,1.54960788 L3.8752791,6.37870489 C3.45332121,6.82837788 2.75274226,6.76663788 2.40583489,6.24905089 L1.93008541,5.53972689 Z"
							id="Fill-3"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default TickIconBuilder;

