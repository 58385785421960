import classNames from 'classnames';
import React from 'react';

import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';



export enum AbstractSelectFieldTogglerSize {
	Small = 'small',
	Default = 'default',
}

export enum AbstractSelectFieldTogglerStyle {
	Eminence = 'eminence',
	EminenceLight = 'eminence-light',
	Light = 'light',
}

type Props = {
	isDisabled?: boolean,
	isOpen?: boolean,
	label: React.ReactNode,
	size?: AbstractSelectFieldTogglerSize,
	style?: AbstractSelectFieldTogglerStyle,
};



const AbstractSelectFieldToggler: React.FC<Props> = (props) => {
	const {
		isDisabled,
		isOpen,
		label,
		size = AbstractSelectFieldTogglerSize.Default,
		style = AbstractSelectFieldTogglerStyle.Light,
	} = props;

	const togglerClasses = classNames({
		'select-field-toggler': true,
		'select-field-toggler--small': size === AbstractSelectFieldTogglerSize.Small,
		'select-field-toggler--closed': !isOpen,
		'select-field-toggler--open': isOpen,
		'select-field-toggler--is-disabled': isDisabled,
		['select-field-toggler--style-' + style]: true,
		'dark-bg-context': style === AbstractSelectFieldTogglerStyle.Eminence || style === AbstractSelectFieldTogglerStyle.EminenceLight || isOpen,
	});

	const attributes: React.HTMLProps<HTMLDivElement> = {};

	if (isDisabled) {
		attributes.disabled = true;
	}

	let iconStyle;

	if (style === AbstractSelectFieldTogglerStyle.Eminence || style === AbstractSelectFieldTogglerStyle.EminenceLight) {
		iconStyle = DropdownIconStyle.White;
	} else if (isDisabled) {
		iconStyle = DropdownIconStyle.Greyscale;
	} else if (isOpen) {
		iconStyle = DropdownIconStyle.White;
	} else {
		iconStyle = DropdownIconStyle.Default;
	}

	return (
		<div
			className={togglerClasses}
			{...attributes}
		>
			<span className="select-field-toggler__label">
				{label}
			</span>
			<span className="select-field-toggler__icon">
				<DropdownIcon
					isActive={isOpen}
					style={iconStyle}
				/>
			</span>
		</div>
	);
};



export default AbstractSelectFieldToggler;
