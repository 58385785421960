import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedCTAElement from '~/components/patterns/structuredValues/AttachedCTAElement';
import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HiddenContent from '~/components/patterns/content/HiddenContent';
import Hint from '~/components/patterns/hints/hint/Hint';
import InsufficientPermissionMessage from '~/components/app/InsufficientPermissionMessage';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import RichRadioListField from '~/components/logic/formFields/RichRadioListField';
import UserRoleName from '~/components/names/UserRoleName';

import {
	validateField,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';

import {
	ROLES_LIST,
} from '~/model/users';



const CAPABILITY_ACCESS_WEBSITES = 'accessWebsites';
const CAPABILITY_ADD_AND_REMOVE_WEBSITES = 'addAndRemoveWebsites';
const CAPABILITY_MANAGE_BILLING_SETTINGS = 'manageBillingSettings';
const CAPABILITY_MANAGE_TEAM_MEMBERS = 'manageTeamMembers';
const CAPABILITY_MANAGE_WEBSITE_SETTINGS = 'manageWebsiteSettings';
const CAPABILITY_ORGANIZATION_ACCESS_CONTROL = 'organizationAccessControl';
const CAPABILITY_RECEIVE_ALERTS_AND_REPORTS = 'receiveAlertsAndReports';

const LIST_OF_CAPABILITIES = [
	CAPABILITY_ACCESS_WEBSITES,
	CAPABILITY_RECEIVE_ALERTS_AND_REPORTS,
	CAPABILITY_MANAGE_WEBSITE_SETTINGS,
	CAPABILITY_ADD_AND_REMOVE_WEBSITES,
	CAPABILITY_MANAGE_TEAM_MEMBERS,
	CAPABILITY_MANAGE_BILLING_SETTINGS,
	CAPABILITY_ORGANIZATION_ACCESS_CONTROL,
];

const roleCapabilities = {
	[GraphQL.UserRole.Admin]: {
		[CAPABILITY_ACCESS_WEBSITES]: true,
		[CAPABILITY_ADD_AND_REMOVE_WEBSITES]: true,
		[CAPABILITY_MANAGE_BILLING_SETTINGS]: true,
		[CAPABILITY_MANAGE_TEAM_MEMBERS]: true,
		[CAPABILITY_MANAGE_WEBSITE_SETTINGS]: true,
		[CAPABILITY_ORGANIZATION_ACCESS_CONTROL]: true,
		[CAPABILITY_RECEIVE_ALERTS_AND_REPORTS]: true,
	},
	[GraphQL.UserRole.Editor]: {
		[CAPABILITY_ACCESS_WEBSITES]: true,
		[CAPABILITY_ADD_AND_REMOVE_WEBSITES]: false,
		[CAPABILITY_MANAGE_BILLING_SETTINGS]: false,
		[CAPABILITY_MANAGE_TEAM_MEMBERS]: false,
		[CAPABILITY_MANAGE_WEBSITE_SETTINGS]: true,
		[CAPABILITY_ORGANIZATION_ACCESS_CONTROL]: false,
		[CAPABILITY_RECEIVE_ALERTS_AND_REPORTS]: true,
	},
	[GraphQL.UserRole.Manager]: {
		[CAPABILITY_ACCESS_WEBSITES]: true,
		[CAPABILITY_ADD_AND_REMOVE_WEBSITES]: true,
		[CAPABILITY_MANAGE_BILLING_SETTINGS]: true,
		[CAPABILITY_MANAGE_TEAM_MEMBERS]: true,
		[CAPABILITY_MANAGE_WEBSITE_SETTINGS]: true,
		[CAPABILITY_ORGANIZATION_ACCESS_CONTROL]: false,
		[CAPABILITY_RECEIVE_ALERTS_AND_REPORTS]: true,
	},
	[GraphQL.UserRole.Viewer]: {
		[CAPABILITY_ACCESS_WEBSITES]: true,
		[CAPABILITY_ADD_AND_REMOVE_WEBSITES]: false,
		[CAPABILITY_MANAGE_BILLING_SETTINGS]: false,
		[CAPABILITY_MANAGE_TEAM_MEMBERS]: false,
		[CAPABILITY_MANAGE_WEBSITE_SETTINGS]: false,
		[CAPABILITY_ORGANIZATION_ACCESS_CONTROL]: false,
		[CAPABILITY_RECEIVE_ALERTS_AND_REPORTS]: true,
	},
};

function listLostCapabilities(oldRole, newRole) {
	if (!oldRole || !newRole) {
		return [];
	}

	return LIST_OF_CAPABILITIES.filter((capability) => {
		return (
			roleCapabilities[oldRole][capability]
			&& !roleCapabilities[newRole][capability]
		);
	});
}



const capabilityMessages = defineMessages({
	[CAPABILITY_ACCESS_WEBSITES]: {
		id: 'ui.roles.rights.accessWebsites',
	},
	[CAPABILITY_ADD_AND_REMOVE_WEBSITES]: {
		id: 'ui.roles.rights.addAndRemoveWebsites',
	},
	[CAPABILITY_MANAGE_BILLING_SETTINGS]: {
		id: 'ui.roles.rights.manageBillingSettings',
	},
	[CAPABILITY_MANAGE_TEAM_MEMBERS]: {
		id: 'ui.roles.rights.manageTeamMembers',
	},
	[CAPABILITY_MANAGE_WEBSITE_SETTINGS]: {
		id: 'ui.roles.rights.manageWebsiteSettings',
	},
	[CAPABILITY_ORGANIZATION_ACCESS_CONTROL]: {
		id: 'ui.roles.rights.organizationAccessControl',
	},
	[CAPABILITY_RECEIVE_ALERTS_AND_REPORTS]: {
		id: 'ui.roles.rights.receiveAlertsAndReports',
	},
});

const messages = defineMessages({
	downgradeDescription: {
		id: 'ui.changeRole.downgrade.description',
	},
	downgradeTitle: {
		id: 'ui.changeRole.downgrade.title',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	diffFrom?: GraphQL.UserRole,
	name: string,
};

const UserRoleField: React.FC<Props> = (props) => {
	const {
		accountId,
		diffFrom,
		name,
	} = props;

	const canUserControlAdminRole = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageOrganizationAccessControl,
	);

	const {
		values,
	} = useFormContext();

	const lostCapabilities = listLostCapabilities(diffFrom, values[name]);

	return (
		<FormRows>
			<FormRow
				fullwidth={true}
				htmlFor={name}
				inline={false}
			>
				<PremiumFeatureSituation
					featureName={GraphQL.AccountFeature.AdminRole}
					hideIfUnattainable={false}
					style={PremiumFeatureSituationStyle.Ribbon}
				>
					{({ isFeatureAttainable: isAdminRoleFeatureAttainable, isFeatureEnabled: isAdminRoleFeatureEnabled, premiumAnnotation }) => {
						const roles = ROLES_LIST.filter((role) => role !== GraphQL.UserRole.Admin || isAdminRoleFeatureAttainable);

						return (
							<RichRadioListField
								capabilities={LIST_OF_CAPABILITIES.filter((capability) => {
									for (const role of roles) {
										if (roleCapabilities[role][capability]) {
											return true;
										}
									}

									return false;
								}).map((capability) => {
									const label = (
										<FormattedMessage {...capabilityMessages[capability]} />
									);

									const values = {};

									roles.forEach((role) => {
										values[role] = roleCapabilities[role][capability];
									});

									return {
										label,
										values,
										warning: lostCapabilities.includes(capability),
									};
								})}
								items={roles.map((role) => {
									let label = (
										<UserRoleName role={role} />
									);

									const isAvailable = role !== GraphQL.UserRole.Admin || isAdminRoleFeatureEnabled;
									const isControllable = role !== GraphQL.UserRole.Admin || canUserControlAdminRole.yes;

									if (!isAvailable) {
										label = (
											<AttachedCTAElement
												compact={true}
												ctaElement={premiumAnnotation}
											>
												{label}
											</AttachedCTAElement>
										);
									} else if (!isControllable) {
										label = (
											<Hint
												popup={(
													<InsufficientPermissionMessage />
												)}
												popupOffset={({ placement }) => {
													if (placement === 'bottom-start' || placement === 'top-start') {
														return [-5, 0];
													}

													if (placement === 'bottom-end' || placement === 'top-end') {
														return [5, 0];
													}

													return [0, 0];
												}}
											>
												<AttachedIcon
													ellipsis={false}
													icon={(
														<BasicIcon
															color="#000000"
															type={BasicIconType.Lock}
														/>
													)}
												>
													{label}
												</AttachedIcon>
											</Hint>
										);
									}

									return {
										disabled: !isAvailable || !isControllable,
										label,
										value: role,
									};
								})}
								name={name}
							/>
						);
					}}
				</PremiumFeatureSituation>
			</FormRow>

			{diffFrom && (
				<HiddenContent hiddenContent={lostCapabilities.length === 0}>
					<FormRow fullwidth={true}>
						<CalloutMessage
							borders={true}
							message={(
								<FormattedMessage {...messages.downgradeTitle} />
							)}
							status={CalloutMessageStatus.Warning}
						>
							<FormattedMessage {...messages.downgradeDescription} />
						</CalloutMessage>
					</FormRow>
				</HiddenContent>
			)}
		</FormRows>
	);
};



export default UserRoleField;

export const validateUserRole = validateField(
	'role',
	(f) => [
		f.validateNonEmpty(),
	],
);
