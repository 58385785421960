import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AddWebsiteMembersReportsAlertsForm from '~/components/app/AddWebsiteMembersReportsAlertsForm';
import Copy from '~/components/logic/Copy';
import MarginsList, {
	SIZE_LARGE as MARGINS_SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';

import useAllUsers from '~/hooks/useAllUsers';
import useAllWebsiteEmailSettings from '~/hooks/useAllWebsiteEmailSettings';

import {
	useUpdateSendingOfAlertsAndReportsFromWebsiteMutation,
	useUpdateSendingOfAlertsAndReportsFromWebsitesMutation,
} from './AddWebsiteModalAlertsAndReportsStep.gql';



const messages = defineMessages({
	breadcrumb: {
		id: 'ui.websites.new.steps.alertsReports.inBreadcrumbs',
	},
	title: {
		id: 'ui.websites.new.steps.alertsReports.title',
	},
	sidebar: {
		id: 'ui.websites.new.steps.alertsReports.sidebar',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	domains: Array<string>,
	isBulk: boolean,
	onContinueCallback: () => void,
	websiteId: CK.WebsiteId | null,
};

const AddWebsiteModalAlertsAndReportsStep: React.FC<Props> = (props) => {
	const {
		accountId,
		domains,
		isBulk,
		onContinueCallback,
		websiteId,
	} = props;

	const allUsers = useAllUsers();
	const allWebsiteEmailSettings = useAllWebsiteEmailSettings();

	const [updateSendingOfAlertsAndReportsFromWebsite] = useUpdateSendingOfAlertsAndReportsFromWebsiteMutation();
	const [updateSendingOfAlertsAndReportsFromWebsites] = useUpdateSendingOfAlertsAndReportsFromWebsitesMutation();

	const handleSubmit = React.useCallback(
		async ({ emailSettings }) => {
			if (accountId === null) {
				return;
			}

			if (websiteId !== null) {
				await updateSendingOfAlertsAndReportsFromWebsite({
					variables: {
						invitations: [],
						users: emailSettings,
						websiteId,
					},
				});
			} else if (domains.length > 0) {
				await updateSendingOfAlertsAndReportsFromWebsites({
					variables: {
						accountId,
						domains,
						invitations: [],
						users: emailSettings,
					},
				});
			}

			onContinueCallback();
		},
		[
			accountId,
			domains,
			onContinueCallback,
			updateSendingOfAlertsAndReportsFromWebsite,
			updateSendingOfAlertsAndReportsFromWebsites,
			websiteId,
		],
	);

	const websiteEmailSettingsPerUser = React.useMemo(
		() => {
			if (
				allUsers.isLoaded === false
				|| allWebsiteEmailSettings.isLoaded === false
			) {
				return null;
			}

			if (isBulk || websiteId === null) {
				return allUsers
					.listByAccount(accountId ?? 0)
					.filter((user) => user.accountMembership.hasLimitedWebsitesAccess === false)
					.map((user) => ({
						alertEmailsSetup: user.isAlertRecipientByDefault
							? GraphQL.AlertEmailsSetup.All
							: GraphQL.AlertEmailsSetup.None,
						displayName: user.displayName,
						isWeeklyReportEnabled: user.isReportRecipientByDefault,
						userEmail: user.email,
						userUniqueId: user.uniqueId,
					}));
			}

			return allWebsiteEmailSettings.listUsersByWebsiteId(websiteId);
		},
		[
			accountId,
			allUsers,
			allWebsiteEmailSettings,
			isBulk,
			websiteId,
		],
	);

	return (
		<MultiStepModalStep
			breadcrumb={(
				<FormattedMessage {...messages.breadcrumb} />
			)}
			headerIconType={MultiStepModalStepHeaderIconType.Plus}
			name="alerts-and-reports"
			sidebar={(
				<MarginsList size={MARGINS_SIZE_LARGE}>
					<RichText>
						<Copy {...messages.sidebar} />
					</RichText>
				</MarginsList>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<AddWebsiteMembersReportsAlertsForm
				key={websiteId}
				onSubmitCallback={handleSubmit}
				websiteEmailSettingsPerUser={websiteEmailSettingsPerUser}
				websiteId={websiteId}
			/>
		</MultiStepModalStep>
	);
};



export default AddWebsiteModalAlertsAndReportsStep;
