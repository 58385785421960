import type Immutable from 'immutable';
import React from 'react';

import BooleanFormatter from '~/components/app/BooleanFormatter';
import DateFormatter from '~/components/app/DateFormatter';
import EmptyValue from '~/components/app/EmptyValue';
import NotExtractedYetValue from '../customElements/NotExtractedYetValue';
import NotFoundValue from '../customElements/NotFoundValue';
import NumberFormatter from '~/components/app/NumberFormatter';
import StringFormatter from '~/components/app/StringFormatter';
import TextInspector from '~/components/patterns/typography/TextInspector';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	DATA_TYPE_BOOLEAN,
	DATA_TYPE_DATE,
	DATA_TYPE_INTEGER,
	DATA_TYPE_NUMBER,
	DATA_TYPE_STRING,
} from '~/model/customElements';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	column: string,
	overriddenCustomElementDefinitions?: ReturnType<typeof useWebsiteCustomElementDefinitions>,
	customElements: boolean | Array<string> | Immutable.List<string>,
	useTextInspector: boolean,
	value: any,
};

const CustomElementFormatter: React.FC<Props> = (props) => {
	const {
		column,
		customElements,
		overriddenCustomElementDefinitions,
		useTextInspector,
		value,
	} = props;

	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);

	const customElementDefinition = (overriddenCustomElementDefinitions ?? customElementDefinitions).getByColumn(column);

	if (customElementDefinition === null) {
		return null;
	}

	if (typeof customElements === 'boolean') {
		if (customElements === false) {
			return (
				<NotExtractedYetValue />
			);
		}
	} else if (customElements.indexOf(customElementDefinition.name) === -1) {
		return (
			<NotExtractedYetValue />
		);
	}

	if (value === null || value === 'not_found') {
		return (
			<NotFoundValue />
		);
	}

	const dataType = customElementDefinition.dataType;

	if (dataType === DATA_TYPE_STRING) {
		if (useTextInspector) {
			if (value === '') {
				return (
					<EmptyValue />
				);
			}

			return (
				<TextInspector text={value} />
			);
		}

		return (
			<StringFormatter value={value} />
		);
	}

	return matchAndReturn(dataType, {
		[DATA_TYPE_BOOLEAN]: (
			<BooleanFormatter
				value={value}
			/>
		),
		[DATA_TYPE_DATE]: (
			<DateFormatter
				timeZone="Europe/Amsterdam"
				value={value * 1000}
			/>
		),
		[DATA_TYPE_INTEGER]: (
			<NumberFormatter
				maximumFractionDigits={0}
				minimumFractionDigits={0}
				value={value}
			/>
		),
		[DATA_TYPE_NUMBER]: (
			<NumberFormatter value={value} />
		),
	});
};



export default CustomElementFormatter;
