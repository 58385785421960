import React from 'react';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import DatatableBodyCell, {
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableHeaderCell, {
	DatatableHeaderCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';
import DatatableLayout from '~/components/patterns/tables/datatables/DatatableLayout';
import FixedHeaderGrid from '~/components/patterns/tables/datatables/FixedHeaderGrid';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const HEADER_HEIGHT = 32;
const ROW_HEIGHT = 40;
const TABLE_HEIGHT = 220;

type ColspanNode = {
	colspan: number,
	content: React.ReactNode,
};

function isColspanNode(input): input is ColspanNode {
	return (
		input !== null
		&& typeof input === 'object'
		&& input.colspan
	);
}



type HasDataInput<TRow> = {
	row: TRow,
	rowIndex: number,
};

type RenderCellInput<TRow> = {
	innerWidth: number,
	row: TRow,
	rowIndex: number,
};

type Props<TRow> = {
	columns: ReadonlyArray<{
		hasData?: (input: HasDataInput<TRow>) => boolean,
		render: {
			cell: (input: RenderCellInput<TRow>) => (React.ReactNode | ColspanNode),
			header: React.ReactNode | ((input: {
				columnIndex: number,
			}) => React.ReactNode),
		},
		width: number,
	}>,
	rows: ReadonlyArray<TRow> | null,
	semitransparentGetter?: (input: {
		columnIndex: number,
		row: TRow,
		rowIndex: number,
	}) => boolean,
	tableWidth: number,
};

function SmallTable<TRow>(props: Props<TRow>) {
	const {
		columns,
		rows,
		semitransparentGetter,
		tableWidth,
	} = props;

	const getColumnWidth = React.useCallback(
		({ index }) => {
			return getArrayItemAtSafeIndex(columns, index).width;
		},
		[
			columns,
		],
	);

	const renderHeader = React.useCallback(
		({ columnIndex }) => {
			const column = getArrayItemAtSafeIndex(columns, columnIndex);

			const headerContent = typeof column.render.header === 'function'
				? column.render.header({ columnIndex })
				: column.render.header;

			return (
				<DatatableHeaderCell
					key={columnIndex}
					separator={columnIndex !== 0}
					size={DatatableHeaderCellSize.Small}
					width={column.width}
				>
					<TableLabel
						label={headerContent}
					/>
				</DatatableHeaderCell>
			);
		},
		[
			columns,
		],
	);

	const renderCellSkeleton = React.useCallback(
		(columnIndex) => {
			const cellWidth = getColumnWidth({ index: columnIndex });
			let randomWidth = cellWidth;

			if (cellWidth > 100) {
				randomWidth = Math.floor(Math.random() * (cellWidth - 100 + 1)) + 100;
			}

			return (
				<SquareSkeleton maxWidth={randomWidth} />
			);
		},
		[
			getColumnWidth,
		],
	);

	const renderCell = React.useCallback(
		({ columnIndex, key, rowIndex, style }) => {
			if (columnIndex >= columns.length) {
				return null;
			}

			const cellStyle = { ...style };

			if (rows === null) {
				return (
					<DatatableBodyCell
						cssStyle={cellStyle}
						key={key}
						rowIndex={rowIndex}
						semitransparent={false}
						separator={columnIndex !== 0}
						size={DatatableBodyCellSize.Small}
					>
						{renderCellSkeleton(columnIndex)}
					</DatatableBodyCell>
				);
			}

			const column = getArrayItemAtSafeIndex(columns, columnIndex);
			const row = getArrayItemAtSafeIndex(rows, rowIndex);

			const cellContent = (column.hasData ? column.hasData({ row, rowIndex }) : true)
				? column.render.cell({ innerWidth: style.width - 16 - 1, row, rowIndex })
				: false;

			let isColspanUsed = false;
			let finalCellContent: React.ReactNode;

			if (isColspanNode(cellContent)) {
				isColspanUsed = true;

				let width = 0;

				for (let i = 0; i < cellContent.colspan; i++) {
					width += getColumnWidth({ index: columnIndex + i });
				}

				cellStyle.width = width;
				cellStyle.zIndex = 1300;

				finalCellContent = cellContent.content;
			} else {
				finalCellContent = cellContent;
			}

			return (
				<DatatableBodyCell
					colspan={isColspanUsed}
					cssStyle={cellStyle}
					key={key}
					rowIndex={rowIndex}
					semitransparent={semitransparentGetter ? semitransparentGetter({ columnIndex, row, rowIndex }) : false}
					separator={columnIndex !== 0}
					size={DatatableBodyCellSize.Small}
				>
					{finalCellContent}
				</DatatableBodyCell>
			);
		},
		[
			columns,
			getColumnWidth,
			renderCellSkeleton,
			rows,
			semitransparentGetter,
		],
	);

	const allColumnsWidth = columns.reduce((total, column) => total + column.width, 0);

	const tableHeight = (() => {
		const calculated = ROW_HEIGHT * (rows !== null ? rows.length : 1);

		if (TABLE_HEIGHT > calculated) {
			return calculated + HEADER_HEIGHT + scrollbarSize();
		}

		return TABLE_HEIGHT + HEADER_HEIGHT + scrollbarSize();
	})();

	// -2 is because of table border around this component
	const tableWidthOffset = -2;

	let calculatedTableHeight = tableHeight;

	if (allColumnsWidth <= (tableWidth + tableWidthOffset)) {
		calculatedTableHeight -= scrollbarSize();
	}

	return (
		<DatatableLayout>
			<FixedHeaderGrid
				bodyCellRenderer={renderCell}
				columnCount={columns.length}
				columnWidth={getColumnWidth}
				data={{
					rows,
				}}
				headerCellRenderer={renderHeader}
				headerHeight={HEADER_HEIGHT}
				height={calculatedTableHeight}
				overscanColumnCount={columns.length}
				rowCount={rows !== null ? rows.length : 1}
				rowHeight={ROW_HEIGHT}
				width={tableWidth + tableWidthOffset}
			/>
		</DatatableLayout>
	);
}



export default SmallTable;
