import {
	isAfter,
	isBefore,
	parseISO,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AnnouncementPopup from '~/components/patterns/messages/popup/AnnouncementPopup';
import Copy from '~/components/logic/Copy';
import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableOverlay from '~/components/patterns/tables/datatables/DatatableOverlay';
import HistoricalMomentTimelineEntry from './HistoricalMomentTimelineEntry';
import LoadingMessage from '~/components/patterns/loaders/LoadingMessage';
import MoreDataTimelineEntry, {
	MoreDataTimelineEntryDirection,
} from './MoreDataTimelineEntry';
import NoHistoryTimelineEntry from './NoHistoryTimelineEntry';
import NoResultsTimelineEntry from './NoResultsTimelineEntry';
import PageDiscoveredTimelineEntryContent from '~/components/app/PageDetail/PageDiscoveredTimelineEntryContent';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';
import StickToScreenBottom, {
	StickToScreenBottomPreset,
} from '~/components/patterns/utils/StickToScreenBottom';
import Timeline, {
	type TimelineRef,
	type TimelineRowRendererInput,
} from '~/components/patterns/time/timeline/Timeline';
import TimelineMessageEntry from '~/components/patterns/time/timeline/TimelineMessageEntry';
import TrackedChangesTimelineEntryContent from '~/components/app/PageDetail/TrackedChangesTimelineEntryContent';

import {
	type HistoricalMomentFragment,
	type HistoricalMoment_PageHistoricalMomentTrackedChanges_Fragment,
	useHistoricalDataQuery,
} from './HistoricalData.gql';

import useAccountDataRetentionInDays from '~/hooks/useAccountDataRetentionInDays';
import useAccountDataRetentionInMonths from '~/hooks/useAccountDataRetentionInMonths';
import useBatchContextPageDetail from '~/hooks/useBatchContextPageDetail';
import {
	type HistoryFilter,
} from './useHistoryFilter';
import usePageDetailSourceMode from '~/hooks/usePageDetailSourceMode';
import usePollInterval from '~/hooks/usePollInterval';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteAccountId from '~/hooks/useWebsiteAccountId';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	dataDeletedDueToRetentionPeriod: {
		id: 'ui.pageDetail.dataDeletedDueToRetentionPeriod',
	},
	loadingHistoricalData: {
		id: 'ui.pageDetail.loadingHistoricalData',
	},
});



type Props = {
	filter: HistoryFilter,
	urlId: number,
	websiteId: CK.WebsiteId,
};

type TrackedChange = HistoricalMoment_PageHistoricalMomentTrackedChanges_Fragment['trackedChanges'][0];

type HistoricalDataItem = HistoricalMomentFragment | {
	special: 'dataDeletedDueToRetentionPeriod',
} | {
	newerMoments: number,
	special: 'moreNewerResults',
} | {
	special: 'moreOlderResults',
} | {
	special: 'noHistory',
} | {
	special: 'noResults',
};



const HistoricalData: React.FC<Props> = (props) => {
	const {
		filter,
		urlId,
		websiteId,
	} = props;

	const websiteAccountId = useWebsiteAccountId(websiteId);

	const accountDataRetentionInDays = useAccountDataRetentionInDays(websiteAccountId);
	const accountDataRetentionInMonths = useAccountDataRetentionInMonths(websiteAccountId);

	const {
		historicalDataItems,
		hasNewerMoments,
		hasOlderMoments,
	} = useHistoricalData(urlId, websiteId, filter);
	const viewportType = useViewportType();

	const listRef = React.useRef<TimelineRef>(null);

	const rowRenderer = React.useCallback(
		({ isFirst, isLast, item }: TimelineRowRendererInput<HistoricalDataItem>) => {
			if ('special' in item) {
				if (item.special === 'moreNewerResults') {
					const newerMoments = item.newerMoments;

					function onClick() {
						filter.unsetEndDate();

						setTimeout(() => {
							const offset = listRef.current?.getOffsetForRow({
								alignment: 'start',
								index: newerMoments,
							}) ?? null;

							if (offset !== null) {
								listRef.current?.scrollToPosition(offset - 80);
							}
						});
					}

					return (
						<MoreDataTimelineEntry
							direction={MoreDataTimelineEntryDirection.Newer}
							onClick={onClick}
						/>
					);
				}

				const render = matchAndReturn(item.special, {
					dataDeletedDueToRetentionPeriod: () => (
						<TimelineMessageEntry
							isFirstChild={isFirst}
							isHighlighted={true}
							isLastChild={isLast}
						>
							<AnnouncementPopup>
								{accountDataRetentionInDays !== null && accountDataRetentionInMonths !== null && (
									<Copy
										{...messages.dataDeletedDueToRetentionPeriod}
										values={{
											dataRetentionInDays: accountDataRetentionInDays,
											dataRetentionInMonths: accountDataRetentionInMonths,
										}}
									/>
								)}
							</AnnouncementPopup>
						</TimelineMessageEntry>
					),
					moreOlderResults: () => (
						<MoreDataTimelineEntry
							direction={MoreDataTimelineEntryDirection.Older}
							onClick={filter.unsetStartDate}
						/>
					),
					noHistory: () => (
						<NoHistoryTimelineEntry />
					),
					noResults: () => (
						<NoResultsTimelineEntry
							isFirstEntry={hasNewerMoments === false}
							isLastEntry={hasOlderMoments === false}
						/>
					),
				});

				return render();
			}

			let content: React.ReactNode = null;

			if (item.__typename === 'PageHistoricalMomentPageDiscovered') {
				content = (
					<PageDiscoveredTimelineEntryContent
						historicalMoment={item}
					/>
				);
			} else if (item.__typename === 'PageHistoricalMomentTrackedChanges') {
				content = (
					<TrackedChangesTimelineEntryContent
						historicalMoment={item}
						isFirstEntry={isFirst}
						textHighlight={filter.searchTerm}
						websiteId={websiteId}
					/>
				);
			}

			return (
				<HistoricalMomentTimelineEntry
					historicalMoment={item}
					isFirstEntry={isFirst}
					isLastEntry={isLast}
					urlId={urlId}
					websiteId={websiteId}
				>
					{content}
				</HistoricalMomentTimelineEntry>
			);
		},
		[
			accountDataRetentionInDays,
			accountDataRetentionInMonths,
			filter,
			hasNewerMoments,
			hasOlderMoments,
			urlId,
			websiteId,
		],
	);

	const itemHeightProvider = React.useCallback(
		(historicalMoment: HistoricalDataItem) => {
			if ('special' in historicalMoment) {
				return 0;
			}

			let estimatedHeight = 0;

			// Header (date + snapshot link)
			estimatedHeight += 48;
			// Margin around entry
			estimatedHeight += 30;

			if (historicalMoment.__typename === 'PageHistoricalMomentPageDiscovered') {
				if (viewportType.isSmall) {
					// Extra spacing for content change title
					estimatedHeight += 21;
				} else {
					// Margin collapsing
					estimatedHeight -= 3;
				}
			}

			if (historicalMoment.__typename === 'PageHistoricalMomentTrackedChanges') {
				// Entries
				historicalMoment.trackedChanges.forEach((change) => {
					if (change.difference === 'added' || change.type === 'type' || change.type === 'last_unreliable_response') {
						// Single line change
						estimatedHeight += 24;
					} else {
						// Multiple line change
						estimatedHeight += 50;
					}

					if (viewportType.isSmall) {
						// Extra spacing for content change title
						estimatedHeight += 21;
					} else {
						// Margin collapsing
						estimatedHeight -= 3;
					}
				});

				// Gaps between entries
				estimatedHeight += (historicalMoment.trackedChanges.length - 1) * 15;
			}

			return estimatedHeight;
		},
		[
			viewportType,
		],
	);

	if (historicalDataItems === null) {
		return (
			<DatatableContainer
				overlay={(
					<DatatableOverlay>
						<LoadingMessage
							title={(
								<FormattedMessage {...messages.loadingHistoricalData} />
							)}
						/>
					</DatatableOverlay>
				)}
			>
				<SquareSkeleton
					height={500}
					style={SquareSkeletonStyle.Transparent}
				/>
			</DatatableContainer>
		);
	}

	return (
		<StickToScreenBottom preset={StickToScreenBottomPreset.Fullscreen}>
			{({ height, width }) => (
				<Timeline
					estimatedItemHeight={125}
					fill={hasOlderMoments}
					height={height}
					itemHeightProvider={itemHeightProvider}
					itemRenderer={rowRenderer}
					items={historicalDataItems}
					ref={listRef}
					width={width}
				/>
			)}
		</StickToScreenBottom>
	);
};



function useFilterHistoricalMoments(
	filter: HistoryFilter,
	websiteId: CK.WebsiteId,
) {
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	return React.useCallback(
		(historicalMoments: ReadonlyArray<HistoricalMomentFragment>) => {
			const result: Array<HistoricalMomentFragment> = [];

			historicalMoments.forEach((moment) => {
				if (moment.__typename === 'PageHistoricalMomentPageDiscovered') {
					if (
						filter.searchTerm !== null
						|| filter.changeTypes.includes('page_discovered') === false
					) {
						return;
					}

					result.push(moment);
				}

				if (moment.__typename === 'PageHistoricalMomentTrackedChanges') {
					const hasPageTypeChange = !!moment.trackedChanges.find((change) => change.type === 'type');
					const trackedChanges = moment.trackedChanges.filter((change) => {
						if (!filter.changeTypes.includes(change.type)) {
							return false;
						}

						if (
							change.type.indexOf('custom_') === 0
							&& customElementDefinitions.getByColumn(change.type) === null
						) {
							return false;
						}

						if (
							change.type.indexOf('ef_') === 0
							&& enrichmentFieldDefinitions.getByColumn(change.type) === null
							&& enrichmentFieldDefinitions.getById(change.type.substring(3)) === null
						) {
							return false;
						}

						if (change.type === 'status_code' && !hasPageTypeChange) {
							return false;
						}

						if (isIndexableIsAdded(change)) {
							return false;
						}

						return true;
					});

					if (trackedChanges.length > 0) {
						result.push({
							...moment,
							trackedChanges,
						});
					}
				}
			});

			return result;
		},
		[
			customElementDefinitions,
			enrichmentFieldDefinitions,
			filter.changeTypes,
			filter.searchTerm,
		],
	);
}



function useHistoricalData(
	urlId: number,
	websiteId: CK.WebsiteId,
	filter: HistoryFilter,
) {
	const pageDetailSourceMode = usePageDetailSourceMode();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	const filterHistoricalMoments = useFilterHistoricalMoments(filter, websiteId);

	const isLoadingDefinitions = (
		customElementDefinitions.isLoaded === false
		|| enrichmentFieldDefinitions.isLoaded === false
	);

	const { data } = useHistoricalDataQuery({
		context: useBatchContextPageDetail(urlId, websiteId),
		pollInterval: usePollInterval(30_000),
		variables: {
			fullHistory: true,
			getComparisonTrackedChanges: pageDetailSourceMode === GraphQL.PageDetailSourceMode.Compare,
			getPrimaryTrackedChanges: pageDetailSourceMode === GraphQL.PageDetailSourceMode.Primary,
			legacyUrlId: urlId,
			websiteId,
		},
	});

	const historicalData = (
		pageDetailSourceMode === GraphQL.PageDetailSourceMode.Primary
			? data?.lookupPageByLegacyId?.primaryTrackedChanges
			: data?.lookupPageByLegacyId?.comparisonTrackedChanges
	) ?? null;

	return React.useMemo(
		() => {
			if (isLoadingDefinitions || historicalData === null) {
				return {
					hasNewerMoments: false,
					hasOlderMoments: false,
					historicalDataItems: null,
				};
			}

			let filteredData = historicalData;

			let newerMoments = 0;
			let olderMoments = 0;

			if (filteredData.length > 0) {
				filteredData = filterHistoricalMoments(filteredData);

				filteredData = filteredData.filter((historicalMoment) => {
					const trackedAt = parseISO(historicalMoment.trackedAt);

					if (filter.dateRange.start && isBefore(trackedAt, filter.dateRange.start)) {
						olderMoments += 1;
						return false;
					}

					if (filter.dateRange.end && isAfter(trackedAt, filter.dateRange.end)) {
						newerMoments += 1;
						return false;
					}

					return true;
				});
			}

			let result: ReadonlyArray<HistoricalDataItem> = filteredData;

			if (historicalData.length === 0) {
				result = [
					{
						special: 'noHistory',
					},
				];
			}

			if (
				filteredData.length === 0
				&& historicalData.length > 0
			) {
				result = [
					{
						special: 'noResults',
					},
				];
			}

			const hasNewerMoments = newerMoments > 0;
			const hasOlderMoments = olderMoments > 0;

			if (hasNewerMoments) {
				result = [
					{
						newerMoments,
						special: 'moreNewerResults',
					},
					...result,
				];
			}

			if (hasOlderMoments) {
				result = [
					...result,
					{
						special: 'moreOlderResults',
					},
				];
			}

			const lastItem = historicalData.length > 0
				? getArrayItemAtSafeIndex(historicalData, historicalData.length - 1)
				: null;

			if (
				lastItem?.__typename === 'PageHistoricalMomentPageDiscovered'
				&& lastItem.isAnyDataDeletedDueToRetentionPeriod
				&& hasOlderMoments === false
			) {
				const isLastItemMomentWhenPageWasDiscovered = filteredData.length > 0
					? getArrayItemAtSafeIndex(filteredData, filteredData.length - 1).__typename === 'PageHistoricalMomentPageDiscovered'
					: false;

				if (isLastItemMomentWhenPageWasDiscovered) {
					result = [
						...result.slice(0, -1),
						{
							special: 'dataDeletedDueToRetentionPeriod',
						},
						...result.slice(-1),
					];
				} else {
					result = [
						...result,
						{
							special: 'dataDeletedDueToRetentionPeriod',
						},
					];
				}
			}

			return {
				hasNewerMoments,
				hasOlderMoments,
				historicalDataItems: result,
			};
		},
		[
			filter,
			filterHistoricalMoments,
			historicalData,
			isLoadingDefinitions,
		],
	);
}



function isIndexableIsAdded(change: TrackedChange): boolean {
	return (
		change.difference === 'added'
		&& (
			change.type === 'is_in_sitemap'
			|| change.type === 'is_indexable'
			|| change.type === 'is_disallowed_in_robots_txt'
		)
	);
}



export default HistoricalData;
