/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteTwoFactorAuthenticationSetupMutationVariables = GraphQL.Exact<{
  code: GraphQL.Scalars['String']['input'];
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type CompleteTwoFactorAuthenticationSetupMutation = { readonly __typename?: 'Mutation', readonly CompleteTwoFactorAuthenticationSetup: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly hasTwoFactorAuthentication: boolean, readonly id: string, readonly legacyId: string } | null } } };

export type InitiateTwoFactorAuthenticationSetupMutationVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type InitiateTwoFactorAuthenticationSetupMutation = { readonly __typename?: 'Mutation', readonly InitiateTwoFactorAuthenticationSetup: { readonly __typename?: 'InitiateTwoFactorAuthenticationSetupResult', readonly qrcode: string, readonly secretEmail: string, readonly secretKey: string } };


export const CompleteTwoFactorAuthenticationSetupDocument = gql`
    mutation CompleteTwoFactorAuthenticationSetup($code: String!, $legacyUserId: ID!) {
  CompleteTwoFactorAuthenticationSetup(code: $code) {
    query {
      user(legacyId: $legacyUserId) {
        hasTwoFactorAuthentication
        id
        legacyId
      }
    }
  }
}
    `;
export type CompleteTwoFactorAuthenticationSetupMutationFn = Apollo.MutationFunction<CompleteTwoFactorAuthenticationSetupMutation, CompleteTwoFactorAuthenticationSetupMutationVariables>;

/**
 * __useCompleteTwoFactorAuthenticationSetupMutation__
 *
 * To run a mutation, you first call `useCompleteTwoFactorAuthenticationSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTwoFactorAuthenticationSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTwoFactorAuthenticationSetupMutation, { data, loading, error }] = useCompleteTwoFactorAuthenticationSetupMutation({
 *   variables: {
 *      code: // value for 'code'
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useCompleteTwoFactorAuthenticationSetupMutation(baseOptions?: Apollo.MutationHookOptions<CompleteTwoFactorAuthenticationSetupMutation, CompleteTwoFactorAuthenticationSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteTwoFactorAuthenticationSetupMutation, CompleteTwoFactorAuthenticationSetupMutationVariables>(CompleteTwoFactorAuthenticationSetupDocument, options);
      }
export type CompleteTwoFactorAuthenticationSetupMutationHookResult = ReturnType<typeof useCompleteTwoFactorAuthenticationSetupMutation>;
export type CompleteTwoFactorAuthenticationSetupMutationResult = Apollo.MutationResult<CompleteTwoFactorAuthenticationSetupMutation>;
export type CompleteTwoFactorAuthenticationSetupMutationOptions = Apollo.BaseMutationOptions<CompleteTwoFactorAuthenticationSetupMutation, CompleteTwoFactorAuthenticationSetupMutationVariables>;
export const InitiateTwoFactorAuthenticationSetupDocument = gql`
    mutation InitiateTwoFactorAuthenticationSetup {
  InitiateTwoFactorAuthenticationSetup {
    qrcode
    secretEmail
    secretKey
  }
}
    `;
export type InitiateTwoFactorAuthenticationSetupMutationFn = Apollo.MutationFunction<InitiateTwoFactorAuthenticationSetupMutation, InitiateTwoFactorAuthenticationSetupMutationVariables>;

/**
 * __useInitiateTwoFactorAuthenticationSetupMutation__
 *
 * To run a mutation, you first call `useInitiateTwoFactorAuthenticationSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateTwoFactorAuthenticationSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateTwoFactorAuthenticationSetupMutation, { data, loading, error }] = useInitiateTwoFactorAuthenticationSetupMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitiateTwoFactorAuthenticationSetupMutation(baseOptions?: Apollo.MutationHookOptions<InitiateTwoFactorAuthenticationSetupMutation, InitiateTwoFactorAuthenticationSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateTwoFactorAuthenticationSetupMutation, InitiateTwoFactorAuthenticationSetupMutationVariables>(InitiateTwoFactorAuthenticationSetupDocument, options);
      }
export type InitiateTwoFactorAuthenticationSetupMutationHookResult = ReturnType<typeof useInitiateTwoFactorAuthenticationSetupMutation>;
export type InitiateTwoFactorAuthenticationSetupMutationResult = Apollo.MutationResult<InitiateTwoFactorAuthenticationSetupMutation>;
export type InitiateTwoFactorAuthenticationSetupMutationOptions = Apollo.BaseMutationOptions<InitiateTwoFactorAuthenticationSetupMutation, InitiateTwoFactorAuthenticationSetupMutationVariables>;