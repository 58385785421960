import React from 'react';
import {
	FormattedMessage,
	defineMessage,
} from 'react-intl';

import DatatableOverlay, {
	DatatableOverlayStyle,
} from '~/components/patterns/tables/datatables/DatatableOverlay';
import InterfaceMessage, {
	InterfaceMessageArrowDirection,
	InterfaceMessageArrowType,
} from '~/components/patterns/messages/popup/InterfaceMessage';



const message = defineMessage({
	id: 'ui.websites.new.steps.multiple.blankSlate',
});



const BlankSlateOverlay: React.FC = () => (
	<DatatableOverlay
		datatableHeaderHeight={40}
		style={DatatableOverlayStyle.Transparent}
	>
		<InterfaceMessage
			arrowDirection={InterfaceMessageArrowDirection.BottomRight}
			arrowType={InterfaceMessageArrowType.Drawn}
		>
			<FormattedMessage {...message} />
		</InterfaceMessage>
	</DatatableOverlay>
);

export default BlankSlateOverlay;
