import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DotInterfaceHint from '~/components/logic/DotInterfaceHint';

import {
	HINT_COBRANDING_UPLOAD,
} from '~/model/interfaceHints';



const messages = defineMessages({
	message: {
		id: 'ui.interfaceHints.hint.coBranding',
	},
});



type Props = {
	children: React.ReactNode,
	isTeamLogoActive: boolean,
};

const CobrandingUploadHint: React.FC<Props> = (props) => {
	const {
		children,
		isTeamLogoActive,
	} = props;

	return (
		<DotInterfaceHint
			content={(
				<FormattedMessage {...messages.message} />
			)}
			iconPosition={{
				left: 120,
				bottom: 8,
			}}
			isObsolete={isTeamLogoActive}
			name={HINT_COBRANDING_UPLOAD}
		>
			{children}
		</DotInterfaceHint>
	);
};



export default React.memo(CobrandingUploadHint);
