import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import {
	EditableFormWrapperContext,
} from '~/components/atoms/forms/basis/EditableFormWrapperBase';

import useFormContext from '~/hooks/useFormContext';
import useModalControls from '~/hooks/useModalControls';



const messages = defineMessages({
	cancelButton: {
		id: 'ui.general.cancelButton',
	},
});



type Props = {
	children?: React.ReactNode,
	onClickCallback?: () => void,
	visible?: boolean,
};

const CancelButton: React.FC<Props> = (props) => {
	const {
		children,
		onClickCallback,
		visible = true,
	} = props;

	const form = useFormContext() as ReturnType<typeof useFormContext> | null;
	const modalControls = useModalControls();

	const editableFormWrapperContext = React.useContext(EditableFormWrapperContext);


	const handleClick = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			if (onClickCallback) {
				onClickCallback();
			} else if (modalControls !== null) {
				modalControls.close();
			}

			if (editableFormWrapperContext) {
				editableFormWrapperContext.closeEditModeHandler(false);
			}
		},
		[
			editableFormWrapperContext,
			modalControls,
			onClickCallback,
		],
	);

	if (form && form.isSubmitting) {
		return null;
	}

	return (
		<Button
			hidden={!visible}
			onClick={handleClick}
			size={ButtonSize.Default}
			style={ButtonStyle.Link}
			uppercase={false}
		>
			{children || (
				<FormattedMessage {...messages.cancelButton} />
			)}
		</Button>
	);
};



export default CancelButton;
