/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSendingOfAlertsAndReportsToUserMutationVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
  websites: ReadonlyArray<GraphQL.UpdateSendingOfAlertsAndReportsToUserWebsite> | GraphQL.UpdateSendingOfAlertsAndReportsToUserWebsite;
}>;


export type UpdateSendingOfAlertsAndReportsToUserMutation = { readonly __typename?: 'Mutation', readonly UpdateSendingOfAlertsAndReportsToUser: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }> } | null } } };

export type WebsitesReportsAlertsFormQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type WebsitesReportsAlertsFormQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly displayName: string, readonly id: CK.WebsiteId }> }>, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }> } | null };


export const UpdateSendingOfAlertsAndReportsToUserDocument = gql`
    mutation UpdateSendingOfAlertsAndReportsToUser($legacyUserId: ID!, $websites: [UpdateSendingOfAlertsAndReportsToUserWebsite!]!) {
  UpdateSendingOfAlertsAndReportsToUser(
    legacyUserId: $legacyUserId
    websites: $websites
  ) {
    query {
      user(legacyId: $legacyUserId) {
        id
        legacyId
        websiteEmailSettings {
          alertEmailsSetup
          isWeeklyReportEnabled
          websiteId
        }
      }
    }
  }
}
    `;
export type UpdateSendingOfAlertsAndReportsToUserMutationFn = Apollo.MutationFunction<UpdateSendingOfAlertsAndReportsToUserMutation, UpdateSendingOfAlertsAndReportsToUserMutationVariables>;

/**
 * __useUpdateSendingOfAlertsAndReportsToUserMutation__
 *
 * To run a mutation, you first call `useUpdateSendingOfAlertsAndReportsToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendingOfAlertsAndReportsToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendingOfAlertsAndReportsToUserMutation, { data, loading, error }] = useUpdateSendingOfAlertsAndReportsToUserMutation({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *      websites: // value for 'websites'
 *   },
 * });
 */
export function useUpdateSendingOfAlertsAndReportsToUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSendingOfAlertsAndReportsToUserMutation, UpdateSendingOfAlertsAndReportsToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSendingOfAlertsAndReportsToUserMutation, UpdateSendingOfAlertsAndReportsToUserMutationVariables>(UpdateSendingOfAlertsAndReportsToUserDocument, options);
      }
export type UpdateSendingOfAlertsAndReportsToUserMutationHookResult = ReturnType<typeof useUpdateSendingOfAlertsAndReportsToUserMutation>;
export type UpdateSendingOfAlertsAndReportsToUserMutationResult = Apollo.MutationResult<UpdateSendingOfAlertsAndReportsToUserMutation>;
export type UpdateSendingOfAlertsAndReportsToUserMutationOptions = Apollo.BaseMutationOptions<UpdateSendingOfAlertsAndReportsToUserMutation, UpdateSendingOfAlertsAndReportsToUserMutationVariables>;
export const WebsitesReportsAlertsFormDocument = gql`
    query WebsitesReportsAlertsForm($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    legacyId
    memberships {
      account {
        id
      }
      user {
        id
        legacyId
      }
      websites {
        displayName
        id
      }
    }
    websiteEmailSettings {
      alertEmailsSetup
      isWeeklyReportEnabled
      websiteId
    }
  }
}
    `;

/**
 * __useWebsitesReportsAlertsFormQuery__
 *
 * To run a query within a React component, call `useWebsitesReportsAlertsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsitesReportsAlertsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsitesReportsAlertsFormQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useWebsitesReportsAlertsFormQuery(baseOptions: Apollo.QueryHookOptions<WebsitesReportsAlertsFormQuery, WebsitesReportsAlertsFormQueryVariables> & ({ variables: WebsitesReportsAlertsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsitesReportsAlertsFormQuery, WebsitesReportsAlertsFormQueryVariables>(WebsitesReportsAlertsFormDocument, options);
      }
export function useWebsitesReportsAlertsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsitesReportsAlertsFormQuery, WebsitesReportsAlertsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsitesReportsAlertsFormQuery, WebsitesReportsAlertsFormQueryVariables>(WebsitesReportsAlertsFormDocument, options);
        }
export function useWebsitesReportsAlertsFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsitesReportsAlertsFormQuery, WebsitesReportsAlertsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsitesReportsAlertsFormQuery, WebsitesReportsAlertsFormQueryVariables>(WebsitesReportsAlertsFormDocument, options);
        }
export type WebsitesReportsAlertsFormQueryHookResult = ReturnType<typeof useWebsitesReportsAlertsFormQuery>;
export type WebsitesReportsAlertsFormLazyQueryHookResult = ReturnType<typeof useWebsitesReportsAlertsFormLazyQuery>;
export type WebsitesReportsAlertsFormSuspenseQueryHookResult = ReturnType<typeof useWebsitesReportsAlertsFormSuspenseQuery>;
export type WebsitesReportsAlertsFormQueryResult = Apollo.QueryResult<WebsitesReportsAlertsFormQuery, WebsitesReportsAlertsFormQueryVariables>;