import classNames from 'classnames';
import React from 'react';



export enum SpinnerSize {
	XSmall = 'x-small',
	Small = 'small',
	Default = 'default',
	Large = 'large',
}

export enum SpinnerStyle {
	Dark = 'dark',
	White = 'white',
}

type Props = {
	className?: string,
	size?: SpinnerSize,
	style?: SpinnerStyle,
};



const Spinner: React.FC<Props> = (props) => {
	const {
		size = SpinnerSize.Default,
		style = SpinnerStyle.Dark,
	} = props;

	// extend current classes in props
	const componentClasses = classNames(
		{
			'spinner': true,

			// highlight that icon has no reserved gap around
			'icon': true,
			'icon--without-gap': true,

			'spinner--large': size === SpinnerSize.Large,
			'spinner--small': size === SpinnerSize.Small,
			'spinner--xsmall': size === SpinnerSize.XSmall,

			'spinner--dark': style === SpinnerStyle.Dark,
			'spinner--white': style === SpinnerStyle.White,
		},
		props.className,
	);

	return (
		<div className={componentClasses}>
			<div className="spinner__item spinner__item--i1"></div>
			<div className="spinner__item spinner__item--i2"></div>
			<div className="spinner__item spinner__item--i3"></div>
			<div className="spinner__item spinner__item--i4"></div>
			<div className="spinner__item spinner__item--i5"></div>
			<div className="spinner__item spinner__item--i6"></div>
			<div className="spinner__item spinner__item--i7"></div>
			<div className="spinner__item spinner__item--i8"></div>
			<div className="spinner__item spinner__item--i9"></div>
			<div className="spinner__item spinner__item--i10"></div>
			<div className="spinner__item spinner__item--i11"></div>
			<div className="spinner__item spinner__item--i12"></div>
		</div>
	);
};



export default Spinner;
