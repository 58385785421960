import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import FullUserName from '~/components/app/FullUserName';
import MultiselectFieldFilter, {
	type MultiselectFieldFilterRef,
} from '~/components/logic/datatables/MultiselectFieldFilter';

import useAllUsers from '~/hooks/useAllUsers';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserEmail from '~/hooks/useUserEmail';

import {
	sortUsers,
} from '~/model/users';



const messages = defineMessages({
	nobody: {
		id: 'ui.general.nobody',
	},
});



type Props = {
	name: string,
	websiteId: CK.WebsiteId,
	width: number,
};

const RecipientsFieldFilter = React.forwardRef<MultiselectFieldFilterRef, Props>((props, ref) => {
	const {
		name,
		websiteId,
		width,
	} = props;

	const currentUserId = useCurrentUserId();

	const allUsers = useAllUsers();
	const currentUserEmail = useUserEmail(currentUserId);

	const options = React.useMemo(
		() => {
			if (currentUserEmail === null) {
				return [];
			}

			const eligibleUsers = allUsers.listAll().filter(
				(user) => user.memberships.some(
					(membership) => membership.websites.some(
						(website) => website.id === websiteId,
					),
				),
			);

			const result = sortUsers(currentUserEmail, eligibleUsers).map(
				(user) => ({
					name: user.email,
					title: (
						<FullUserName
							email={user.email}
							firstName={user.firstName}
							lastName={user.lastName}
						/>
					),
				}),
			);

			result.push({
				name: 'nobody',
				title: (
					<FormattedMessage {...messages.nobody} />
				),
			});

			return result;
		},
		[
			allUsers,
			currentUserEmail,
			websiteId,
		],
	);

	if (
		allUsers.isLoaded === false
		|| currentUserEmail === null
	) {
		return null;
	}

	return (
		<MultiselectFieldFilter
			allValue="all"
			dropdownWidth={250}
			isOnlyLinkVisible={true}
			name={name}
			options={options}
			ref={ref}
			scrollableDropdown={true}
			width={width}
		/>
	);
});



export default RecipientsFieldFilter;
