import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';



type Props = {
	capacity: number,
	pagesCount: number,
	unit?: React.ReactNode,
};



const WebsiteCapacityOverview: React.FC<Props> = (props) => {
	const {
		capacity,
		pagesCount,
		unit,
	} = props;

	return (
		<div className="website-capacity-overview">
			<span className="website-capacity-overview__count">
				<FormattedNumber value={pagesCount} />
			</span>

			<span className="website-capacity-overview__capacity-with-unit">
				<span className="website-capacity-overview__capacity">
					<span className="website-capacity-overview__slash">/</span>
					<FormattedNumber value={capacity} />
				</span>

				{unit && (
					<span className="website-capacity-overview__unit">
						&nbsp;
						{unit}
					</span>
				)}
			</span>
		</div>
	);
};



export default WebsiteCapacityOverview;
