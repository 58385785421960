import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import SubmitButton from '~/components/app/SubmitButton';
import TwoFactorAuthenticationCodeField, {
	validateTwoFactorAuthenticationCode,
} from '~/components/app/TwoFactorAuthenticationCodeField';

import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import TextField, {
	TextFieldAutocomplete,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useEnterSudoModeUsingPasswordMutation,
	useEnterSudoModeUsingTwoFactorAuthenticationMutation,
} from '~/components/app/EnterSudoModeForm.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useSudoModeStatus from '~/hooks/useSudoModeStatus';

import FormError from '~/utilities/FormError';
import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	continue: {
		id: 'ui.general.continue',
	},
	password: {
		id: 'ui.enterSudoModeForm.password',
	},
	twoFactorHavingTrouble: {
		id: 'ui.enterSudoModeForm.twoFactorHavingTrouble',
	},
	wrongPasswordError: {
		id: 'ui.enterSudoModeForm.wrongPasswordError',
	},
});



function validations(method: GraphQL.MethodToEnterSudoMode | null) {
	if (method === GraphQL.MethodToEnterSudoMode.TwoFactorAuthentication) {
		return {
			validateTwoFactorAuthenticationCode,
		};
	}

	if (method === GraphQL.MethodToEnterSudoMode.Password) {
		return {
			validatePassword: validateField(
				'password',
				(f) => ([
					f.validateNonEmpty(),
					f.customGlobal({
						field: 'password',
						globalRule: 'wrongPassword',
						message: (
							<FormattedMessage {...messages.wrongPasswordError} />
						),
					}),
				]),
			),
		};
	}
}



type Props = {
	description?: React.ReactNode,
	disallowedMethod?: GraphQL.MethodToEnterSudoMode,
};

const EnterSudoModeForm: React.FC<Props> = (props) => {
	const {
		description = null,
		disallowedMethod,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();

	const sudoModeStatus = useSudoModeStatus(disallowedMethod);
	const [enterSudoModeUsingTwoFactorAuthentication] = useEnterSudoModeUsingTwoFactorAuthenticationMutation();
	const [enterSudoModeUsingPassword] = useEnterSudoModeUsingPasswordMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (
				sudoModeStatus.isSudoEnabled === true
				|| sudoModeStatus.suggestedMethodToEnter === null
			) {
				return;
			}

			const mutation = matchAndReturn(sudoModeStatus.suggestedMethodToEnter, {
				[GraphQL.MethodToEnterSudoMode.Password]: () => {
					return enterSudoModeUsingPassword({
						variables: {
							password: values.password,
						},
					});
				},
				[GraphQL.MethodToEnterSudoMode.TwoFactorAuthentication]: () => {
					return enterSudoModeUsingTwoFactorAuthentication({
						variables: {
							code: values.twoFactorAuthenticationCode,
						},
					});
				},
			});

			try {
				await mutation();

				classicFormBehavior.finish();
			} catch (error) {
				throw FormError.fromApolloError(error, {
					invalidCode: values.twoFactorAuthenticationCode,
					wrongPassword: values.password,
				});
			}
		},
		[
			classicFormBehavior,
			enterSudoModeUsingPassword,
			enterSudoModeUsingTwoFactorAuthentication,
			sudoModeStatus,
		],
	);

	if (sudoModeStatus.isLoading === true) {
		return null;
	}

	return (
		<Form
			onSuccess={handleSubmit}
			validations={validations(sudoModeStatus.suggestedMethodToEnter) as any}
		>
			{description !== null && (
				<ModalTextSection>
					<RichText>
						{description}
					</RichText>
				</ModalTextSection>
			)}

			{sudoModeStatus.suggestedMethodToEnter === GraphQL.MethodToEnterSudoMode.TwoFactorAuthentication && (
				<>
					<TwoFactorAuthenticationCodeField />

					<ModalTextSection>
						<RichText>
							<Copy
								{...messages.twoFactorHavingTrouble}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/support/two-factor-authentication/'),
								}}
							/>
						</RichText>
					</ModalTextSection>
				</>
			)}

			{sudoModeStatus.suggestedMethodToEnter === GraphQL.MethodToEnterSudoMode.Password && (
				<CenteredFormWrapper>
					<FormRow
						label={(
							<FormattedMessage {...messages.password} />
						)}
					>
						<FieldStatus
							allowOk={false}
							name="validatePassword"
						>
							<TextField
								autoComplete={TextFieldAutocomplete.CurrentPassword}
								name="password"
								type={TextFieldType.Password}
							/>
						</FieldStatus>
					</FormRow>
				</CenteredFormWrapper>
			)}

			<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
				<CancelButton />

				<SubmitButton>
					<FormattedMessage {...messages.continue} />
				</SubmitButton>
			</ModalButtonsLayout>
		</Form>
	);
};



export default EnterSudoModeForm;
