import React from 'react';



type Props = {
	children: React.ReactNode,
	/** Additional title */
	title?: React.ReactNode,
};

const FilterDropdownFilters: React.FC<Props> = (props) => {
	const {
		children,
		title,
	} = props;

	return (
		<div className="filter-dropdown-filters">
			{title && (
				<div className="filter-dropdown-filters__title">
					{title}
				</div>
			)}
			{React.Children.map(
				children,
				(child) => {
					if (!child) {
						return false;
					}

					return (
						<div className="filter-dropdown-filters__item">
							{child}
						</div>
					);
				},
			)}
		</div>
	);
};



export default FilterDropdownFilters;
