import classNames from 'classnames';
import React from 'react';



export enum WebsitePlanUsageStatus {
	Critical = 'critical',
	Normal = 'normal',
	Warning = 'warning',
}

type Props = {
	capacity: React.ReactNode,
	/** Highlighted app status caused by plan usage */
	status?: WebsitePlanUsageStatus,
	usage: React.ReactNode,
};



const WebsitePlanUsage: React.FC<Props> = (props) => {
	const {
		capacity,
		status = WebsitePlanUsageStatus.Normal,
		usage,
	} = props;

	const componentClasses = classNames({
		'website-plan-usage': true,
		['website-plan-usage--' + status + '-status']: true,
	});

	return (
		<div className={componentClasses}>
			<div className="website-plan-usage__usage">
				{usage}
			</div>

			<div className="website-plan-usage__capacity">
				{capacity}
			</div>
		</div>
	);
};



export default WebsitePlanUsage;
