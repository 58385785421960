import React from 'react';

import useNavigation from '~/hooks/useNavigation';



type LinkOptions = {
	extraRouteParams?: Record<string, any>,
	includeBacklink?: boolean,
	routeName?: string,
	routeParams?: Record<string, any>,
};

function useGetLinkTarget() {
	const navigation = useNavigation();

	const createRoute = navigation.createRoute;

	return React.useCallback(
		(options: LinkOptions) => {
			const {
				name,
				params,
			} = createRoute({
				extraRouteParams: options.extraRouteParams,
				includeBacklink: options.includeBacklink,
				keepParameters: true,
				routeName: options.routeName,
				routeParams: options.routeParams,
			});

			return {
				routeName: name,
				routeParams: params,
			};
		},
		[
			createRoute,
		],
	);
}



export default useGetLinkTarget;
