import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Attached bar chart */
	barChart: React.ReactNode,
	/** Number */
	number: React.ReactNode,
	/** Stretch bar chart on larger screens */
	stretchedBarChart?: boolean,
};



const NumberWithBar: React.FC<Props> = (props) => {
	const {
		barChart,
		number,
		stretchedBarChart,
	} = props;

	const componentClasses = classNames({
		'number-with-bar': true,
		'number-with-bar--stretched-bar': stretchedBarChart,
	});

	return (
		<div className={componentClasses}>
			<div className="number-with-bar__number">
				{number}
			</div>
			<div className="number-with-bar__bar">
				{barChart}
			</div>
		</div>
	);
};



export default NumberWithBar;
