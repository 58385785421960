import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class AnchorsNavigation extends Component {

	_handleClick(value, e) {
		e.preventDefault();

		const {
			onClickCallback,
		} = this.props;

		onClickCallback(value);
	}



	_renderOptions() {
		const {
			options,
		} = this.props;

		const list = [];

		options.forEach((option) => {
			list.push((
				<li
					className="anchors-nav__item"
					key={option.value}
				>
					<a
						className="anchors-nav__link"
						href="#"
						onClick={this._handleClick.bind(this, option.value)}
					>
						{option.label}
					</a>
				</li>
			));
		});

		return list;
	}



	render() {
		return (
			<ul className="anchors-nav">
				{this._renderOptions()}
			</ul>
		);
	}

}

AnchorsNavigation.propTypes = {
	onClickCallback: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
};



export default AnchorsNavigation;
