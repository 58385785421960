import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useAccountSignup from '~/hooks/useAccountSignup';
import useAllowedCurrencies from '~/hooks/useAllowedCurrencies';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



function useSignupCurrency(accountId: CK.AccountId | null): GraphQL.Currency | null {
	const accountSignup = useAccountSignup(accountId);
	const allowedCurrencies = useAllowedCurrencies(accountId);

	if (
		accountSignup.currency !== null
		&& allowedCurrencies !== null
		&& !allowedCurrencies.includes(accountSignup.currency)
	) {
		return getArrayItemAtSafeIndex(allowedCurrencies, 0);
	}

	return accountSignup.currency;
}



export default useSignupCurrency;
