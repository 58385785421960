import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import CreateLogFileAnalysisS3BucketForm from '~/components/app/CreateLogFileAnalysisS3BucketForm';
import ModalPanel, {
	SIZE_XXLARGE as MODAL_SIZE_XXLARGE,
} from '~/components/atoms/panels/ModalPanel';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModal from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import UpdateWebsiteAwsAccountIdForm from '~/components/app/UpdateWebsiteAwsAccountIdForm';

import useModals from '~/hooks/useModals';



const messages = defineMessages({
	awsAccountIdBreadcrumb: {
		id: 'ui.installCloudfrontStandardLoggingModal.breadcrumb.awsAccountId',
	},
	bucketBreadcrumb: {
		id: 'ui.installCloudfrontStandardLoggingModal.breadcrumb.bucket',
	},
	confirmation: {
		id: 'ui.installCloudfrontStandardLoggingModal.confirmation',
	},
	description: {
		id: 'ui.installCloudfrontStandardLoggingModal.description',
	},
	ok: {
		id: 'ui.general.ok',
	},
	oneMoreThing: {
		id: 'ui.installCloudfrontStandardLoggingModal.oneMoreThing',
	},
	sidebar: {
		id: 'ui.installCloudfrontStandardLoggingModal.sidebar',
	},
	title: {
		id: 'ui.installCloudfrontStandardLoggingModal.title',
	},
});



const InstallCloudfrontStandardLoggingModal: React.FC = () => {
	const modals = useModals();

	function renderSidebar() {
		return (
			<RichText>
				<Copy
					{...messages.sidebar}
					values={{
						linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudfront-integration/#2-configuring-cloudfront-standard-logging'),
					}}
				/>
			</RichText>
		);
	}

	return (
		<ModalPanel
			onCloseCallback={modals.closeCallback}
			size={MODAL_SIZE_XXLARGE}
		>
			<MultiStepModal>
				<MultiStepModalStep
					breadcrumb={(
						<FormattedMessage {...messages.awsAccountIdBreadcrumb} />
					)}
					name="aws-account-id"
					sidebar={renderSidebar()}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<UpdateWebsiteAwsAccountIdForm />
				</MultiStepModalStep>

				<MultiStepModalStep
					breadcrumb={(
						<FormattedMessage {...messages.bucketBreadcrumb} />
					)}
					name="create-bucket"
					sidebar={renderSidebar()}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<ModalTextSection>
						<RichText>
							<Copy
								{...messages.description}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudfront-integration/#2-configuring-cloudfront-standard-logging'),
								}}
							/>
						</RichText>
					</ModalTextSection>

					<CreateLogFileAnalysisS3BucketForm
						logSourceProvider={GraphQL.LogSourceProvider.Cloudfront}
						showAccessFields={false}
					/>
				</MultiStepModalStep>

				<MultiStepModalStep
					name="one-more-thing"
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					{({ isActive }) => (isActive && (
						<ConfirmationMessage
							animate={true}
							context={ConfirmationMessageContext.Pending}
							ctaButtons={(
								<Button
									onClick={() => modals.closeCallback()}
									style={ButtonStyle.Action}
									width={ButtonWidth.SubmitButton}
								>
									<FormattedMessage {...messages.ok} />
								</Button>
							)}
							message={(
								<FormattedMessage {...messages.oneMoreThing} />
							)}
						>
							<RichText>
								<Copy
									{...messages.confirmation}
									values={{
										linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudfront-integration/#2-configuring-cloudfront-standard-logging'),
									}}
								/>
							</RichText>
						</ConfirmationMessage>
					))}
				</MultiStepModalStep>
			</MultiStepModal>

		</ModalPanel>
	);
};



export default InstallCloudfrontStandardLoggingModal;
