import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import ExpandableFilterOperatorName from '~/components/names/ExpandableFilterOperatorName';



class ExpandableFilterOperatorHeaderLabel extends Component {

	render() {
		const {
			value,
		} = this.props;

		if (typeof value !== 'string') {
			return false;
		}

		if (value.indexOf(':') === -1) {
			return false;
		}

		const splitValue = value.split(':', 1);

		if (splitValue[0] === 'is') {
			return false;
		}

		return (
			<ExpandableFilterOperatorName
				operator={splitValue[0]}
			/>
		);
	}

}

ExpandableFilterOperatorHeaderLabel.propTypes = {
	value: PropTypes.string,
};



export default ExpandableFilterOperatorHeaderLabel;
