/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteHasWebVitalsOriginSummaryQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteHasWebVitalsOriginSummaryQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly hasWebVitalsOriginSummaryData: boolean } | null };


export const WebsiteHasWebVitalsOriginSummaryDocument = gql`
    query WebsiteHasWebVitalsOriginSummary($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    hasWebVitalsOriginSummaryData
  }
}
    `;

/**
 * __useWebsiteHasWebVitalsOriginSummaryQuery__
 *
 * To run a query within a React component, call `useWebsiteHasWebVitalsOriginSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteHasWebVitalsOriginSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteHasWebVitalsOriginSummaryQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteHasWebVitalsOriginSummaryQuery(baseOptions: Apollo.QueryHookOptions<WebsiteHasWebVitalsOriginSummaryQuery, WebsiteHasWebVitalsOriginSummaryQueryVariables> & ({ variables: WebsiteHasWebVitalsOriginSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteHasWebVitalsOriginSummaryQuery, WebsiteHasWebVitalsOriginSummaryQueryVariables>(WebsiteHasWebVitalsOriginSummaryDocument, options);
      }
export function useWebsiteHasWebVitalsOriginSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteHasWebVitalsOriginSummaryQuery, WebsiteHasWebVitalsOriginSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteHasWebVitalsOriginSummaryQuery, WebsiteHasWebVitalsOriginSummaryQueryVariables>(WebsiteHasWebVitalsOriginSummaryDocument, options);
        }
export function useWebsiteHasWebVitalsOriginSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteHasWebVitalsOriginSummaryQuery, WebsiteHasWebVitalsOriginSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteHasWebVitalsOriginSummaryQuery, WebsiteHasWebVitalsOriginSummaryQueryVariables>(WebsiteHasWebVitalsOriginSummaryDocument, options);
        }
export type WebsiteHasWebVitalsOriginSummaryQueryHookResult = ReturnType<typeof useWebsiteHasWebVitalsOriginSummaryQuery>;
export type WebsiteHasWebVitalsOriginSummaryLazyQueryHookResult = ReturnType<typeof useWebsiteHasWebVitalsOriginSummaryLazyQuery>;
export type WebsiteHasWebVitalsOriginSummarySuspenseQueryHookResult = ReturnType<typeof useWebsiteHasWebVitalsOriginSummarySuspenseQuery>;
export type WebsiteHasWebVitalsOriginSummaryQueryResult = Apollo.QueryResult<WebsiteHasWebVitalsOriginSummaryQuery, WebsiteHasWebVitalsOriginSummaryQueryVariables>;