import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import SelectField from '~/components/atoms/forms/components/SelectField';

import {
	EXTRACTION_SOURCE_DOM,
	EXTRACTION_SOURCE_SRC,
} from '~/model/customElements';



const messages = defineMessages({
	extractElementFromOptionsDom: {
		id: 'ui.customElements.extractElementFrom.options.dom',
	},
	extractElementFromOptionsSrc: {
		id: 'ui.customElements.extractElementFrom.options.src',
	},
});

const options = [
	{
		label: (
			<FormattedMessage {...messages.extractElementFromOptionsSrc} />
		),
		name: EXTRACTION_SOURCE_SRC,
	},
	{
		label: (
			<FormattedMessage {...messages.extractElementFromOptionsDom} />
		),
		name: EXTRACTION_SOURCE_DOM,
	},
];



type Props = {
	name: string,
	width?: React.CSSProperties['width'],
};

const CustomElementSourceSelectField: React.FC<Props> = (props) => {
	const {
		name,
		width,
	} = props;

	return (
		<SelectField
			dropdownWidth={200}
			isInteractedByDefault={false}
			name={name}
			options={options}
			width={width}
		/>
	);
};



export default CustomElementSourceSelectField;
