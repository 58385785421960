import React from 'react';

import ProfileCompletenessOverview from './ProfileCompletenessOverview';



type Props = {
	/** Message */
	children: React.ReactNode,
	/** Title of message */
	title: React.ReactNode,
};



const ProfileCompletenessMessage: React.FC<Props> = (props) => {
	const {
		children,
		title,
	} = props;

	return (
		<ProfileCompletenessOverview
			text={children}
			title={title}
		/>
	);
};



export default ProfileCompletenessMessage;
