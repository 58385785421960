import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import APIIntegrationStatus, {
	APIIntegrationStatusService,
	APIIntegrationStatusSize,
	APIIntegrationStatusStatus,
} from '~/components/patterns/statuses/APIIntegrationStatus';



const messages = defineMessages({
	propertyDeclaration: {
		id: 'ui.general.propertyDeclaration',
	},
});

const integrationMessages = defineMessages({
	[GraphQL.WebsiteIntegrationType.AdobeAnalytics]: {
		id: 'ui.websites.form.api.adobeAnalytics.title',
	},
	[GraphQL.WebsiteIntegrationType.GoogleAnalytics]: {
		id: 'ui.websites.form.api.googleAnalytics.title',
	},
	[GraphQL.WebsiteIntegrationType.GoogleDataStudio]: {
		id: 'ui.websites.form.api.gds.title',
	},
	[GraphQL.WebsiteIntegrationType.GoogleSearchConsole]: {
		id: 'ui.websites.form.api.gsc.title',
	},
	[GraphQL.WebsiteIntegrationType.MicrosoftTeams]: {
		id: 'ui.websites.form.api.microsoftTeams.title',
	},
	[GraphQL.WebsiteIntegrationType.Slack]: {
		id: 'ui.websites.form.api.slack.title',
	},
	[GraphQL.WebsiteIntegrationType.Wordpress]: {
		id: 'ui.websites.form.api.wp.title',
	},
});

const statusMessages = defineMessages({
	[GraphQL.WebsiteIntegrationStatus.Available]: {
		id: 'ui.websites.form.api.status.available',
	},
	[GraphQL.WebsiteIntegrationStatus.Disabled]: {
		id: 'ui.websites.form.api.status.disabled',
	},
	[GraphQL.WebsiteIntegrationStatus.Enabled]: {
		id: 'ui.websites.form.api.status.enabled',
	},
});

const serviceMapping = {
	[GraphQL.WebsiteIntegrationType.AdobeAnalytics]: APIIntegrationStatusService.AdobeAnalytics,
	[GraphQL.WebsiteIntegrationType.GoogleAnalytics]: APIIntegrationStatusService.GoogleAnalytics,
	[GraphQL.WebsiteIntegrationType.GoogleDataStudio]: APIIntegrationStatusService.GoogleLookerStudio,
	[GraphQL.WebsiteIntegrationType.GoogleSearchConsole]: APIIntegrationStatusService.GoogleSearchConsole,
	[GraphQL.WebsiteIntegrationType.MicrosoftTeams]: APIIntegrationStatusService.MicrosoftTeams,
	[GraphQL.WebsiteIntegrationType.Slack]: APIIntegrationStatusService.Slack,
	[GraphQL.WebsiteIntegrationType.Wordpress]: APIIntegrationStatusService.WordPress,
};

const statusMapping = {
	[GraphQL.WebsiteIntegrationStatus.Available]: APIIntegrationStatusStatus.Available,
	[GraphQL.WebsiteIntegrationStatus.Disabled]: APIIntegrationStatusStatus.Disabled,
	[GraphQL.WebsiteIntegrationStatus.Enabled]: APIIntegrationStatusStatus.Enabled,
};



type Props = {
	integrationType: GraphQL.WebsiteIntegrationType,
	size?: APIIntegrationStatusSize,
	status: GraphQL.WebsiteIntegrationStatus,
};

const WebsiteIntegrationStatusBadge: React.FC<Props> = (props) => {
	const {
		integrationType,
		size,
		status,
	} = props;

	return (
		<APIIntegrationStatus
			compact={true}
			label={(
				<FormattedMessage {...statusMessages[status]} />
			)}
			service={serviceMapping[integrationType]}
			size={size}
			status={statusMapping[status]}
			tooltip={(
				<>
					<FormattedMessage
						{...messages.propertyDeclaration}
						values={{
							text__property: (
								<FormattedMessage {...integrationMessages[integrationType]} />
							),
						}}
					/> <FormattedMessage {...statusMessages[status]} />
				</>
			)}
		/>
	);
};



export default React.memo(WebsiteIntegrationStatusBadge);

export {
	APIIntegrationStatusSize as WebsiteIntegrationStatusBadgeSize,
};
