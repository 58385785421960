import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';

import {
	useActivatePremiumTrialMutation,
} from './useActivatePremiumTrial.gql';

import useRequestAdminConfirmationIfApplicable from '~/hooks/useRequestAdminConfirmationIfApplicable';

import {
	loadTeams,
} from '~/actions/teams';



type ReturnType = () => Promise<void>;



function useActivatePremiumTrial(accountId: CK.AccountId | null): ReturnType {
	const [activatePremiumTrial] = useActivatePremiumTrialMutation();
	const dispatch = useDispatch();
	const requestAdminConfirmationIfApplicable = useRequestAdminConfirmationIfApplicable(accountId);

	return React.useCallback(
		async () => {
			if (accountId === null) {
				throw new Error(
					`accountId isn't ready yet`,
				);
			}

			await requestAdminConfirmationIfApplicable();

			await activatePremiumTrial({
				variables: {
					accountId,
				},
			});

			await dispatch(
				loadTeams(),
			);
		},
		[
			accountId,
			activatePremiumTrial,
			dispatch,
			requestAdminConfirmationIfApplicable,
		],
	);
}



export default useActivatePremiumTrial;
