/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLimitedWebsitesAccessOfWebsiteMutationVariables = GraphQL.Exact<{
  addedEmailMemberships: ReadonlyArray<GraphQL.AccountMembershipEmailInput> | GraphQL.AccountMembershipEmailInput;
  addedInvitations: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  removedEmailMemberships: ReadonlyArray<GraphQL.AccountMembershipEmailInput> | GraphQL.AccountMembershipEmailInput;
  removedInvitations: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateLimitedWebsitesAccessOfWebsiteMutation = { readonly __typename?: 'Mutation', readonly UpdateLimitedWebsitesAccessOfWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly connectedClientAccounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly id: CK.InvitationId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> }>, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly id: CK.InvitationId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> } | null } | null } } };


export const UpdateLimitedWebsitesAccessOfWebsiteDocument = gql`
    mutation UpdateLimitedWebsitesAccessOfWebsite($addedEmailMemberships: [AccountMembershipEmailInput!]!, $addedInvitations: [String!]!, $removedEmailMemberships: [AccountMembershipEmailInput!]!, $removedInvitations: [String!]!, $websiteId: WebsiteId!) {
  UpdateLimitedWebsitesAccessOfWebsite(
    addedEmailMemberships: $addedEmailMemberships
    addedInvitationIds: $addedInvitations
    removedEmailMemberships: $removedEmailMemberships
    removedInvitationIds: $removedInvitations
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        account {
          connectedClientAccounts {
            id
            invitations {
              id
              websites {
                id
              }
            }
            memberships {
              account {
                id
              }
              user {
                id
                legacyId
              }
              websites {
                id
              }
            }
          }
          id
          invitations {
            id
            websites {
              id
            }
          }
          memberships {
            account {
              id
            }
            user {
              id
              legacyId
            }
            websites {
              id
            }
          }
        }
        id
      }
    }
  }
}
    `;
export type UpdateLimitedWebsitesAccessOfWebsiteMutationFn = Apollo.MutationFunction<UpdateLimitedWebsitesAccessOfWebsiteMutation, UpdateLimitedWebsitesAccessOfWebsiteMutationVariables>;

/**
 * __useUpdateLimitedWebsitesAccessOfWebsiteMutation__
 *
 * To run a mutation, you first call `useUpdateLimitedWebsitesAccessOfWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLimitedWebsitesAccessOfWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLimitedWebsitesAccessOfWebsiteMutation, { data, loading, error }] = useUpdateLimitedWebsitesAccessOfWebsiteMutation({
 *   variables: {
 *      addedEmailMemberships: // value for 'addedEmailMemberships'
 *      addedInvitations: // value for 'addedInvitations'
 *      removedEmailMemberships: // value for 'removedEmailMemberships'
 *      removedInvitations: // value for 'removedInvitations'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateLimitedWebsitesAccessOfWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLimitedWebsitesAccessOfWebsiteMutation, UpdateLimitedWebsitesAccessOfWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLimitedWebsitesAccessOfWebsiteMutation, UpdateLimitedWebsitesAccessOfWebsiteMutationVariables>(UpdateLimitedWebsitesAccessOfWebsiteDocument, options);
      }
export type UpdateLimitedWebsitesAccessOfWebsiteMutationHookResult = ReturnType<typeof useUpdateLimitedWebsitesAccessOfWebsiteMutation>;
export type UpdateLimitedWebsitesAccessOfWebsiteMutationResult = Apollo.MutationResult<UpdateLimitedWebsitesAccessOfWebsiteMutation>;
export type UpdateLimitedWebsitesAccessOfWebsiteMutationOptions = Apollo.BaseMutationOptions<UpdateLimitedWebsitesAccessOfWebsiteMutation, UpdateLimitedWebsitesAccessOfWebsiteMutationVariables>;