/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptReportingApiTermsOfUseMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AcceptReportingApiTermsOfUseMutation = { readonly __typename?: 'Mutation', readonly AcceptReportingApiTermsOfUse: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isReportingApiTermsOfUseAccepted: boolean, readonly reportingApiToken: string | null } | null } } };


export const AcceptReportingApiTermsOfUseDocument = gql`
    mutation AcceptReportingApiTermsOfUse($accountId: AccountId!) {
  AcceptReportingApiTermsOfUse(accountId: $accountId) {
    query {
      account(id: $accountId) {
        id
        isReportingApiTermsOfUseAccepted
        reportingApiToken
      }
    }
  }
}
    `;
export type AcceptReportingApiTermsOfUseMutationFn = Apollo.MutationFunction<AcceptReportingApiTermsOfUseMutation, AcceptReportingApiTermsOfUseMutationVariables>;

/**
 * __useAcceptReportingApiTermsOfUseMutation__
 *
 * To run a mutation, you first call `useAcceptReportingApiTermsOfUseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptReportingApiTermsOfUseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptReportingApiTermsOfUseMutation, { data, loading, error }] = useAcceptReportingApiTermsOfUseMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAcceptReportingApiTermsOfUseMutation(baseOptions?: Apollo.MutationHookOptions<AcceptReportingApiTermsOfUseMutation, AcceptReportingApiTermsOfUseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptReportingApiTermsOfUseMutation, AcceptReportingApiTermsOfUseMutationVariables>(AcceptReportingApiTermsOfUseDocument, options);
      }
export type AcceptReportingApiTermsOfUseMutationHookResult = ReturnType<typeof useAcceptReportingApiTermsOfUseMutation>;
export type AcceptReportingApiTermsOfUseMutationResult = Apollo.MutationResult<AcceptReportingApiTermsOfUseMutation>;
export type AcceptReportingApiTermsOfUseMutationOptions = Apollo.BaseMutationOptions<AcceptReportingApiTermsOfUseMutation, AcceptReportingApiTermsOfUseMutationVariables>;