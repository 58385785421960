import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Basic description attached to buttons */
	description?: React.ReactNode,
	/** Additional bigger overview attached to buttons */
	overview?: React.ReactNode,
};



const SwitchButtonsDescription: React.FC<Props> = (props) => {
	const {
		description,
		children,
		overview,
	} = props;

	return (
		<div className="switch-buttons-description">
			<div className="switch-buttons-description__field">
				{children}
			</div>

			{(overview || description) && (
				<div className="switch-buttons-description__attached-data">
					{overview && (
						<div className="switch-buttons-description__overview">
							{overview}
						</div>
					)}

					{description && (
						<div className="switch-buttons-description__description">
							{description}
						</div>
					)}
				</div>
			)}
		</div>
	);
};



export default SwitchButtonsDescription;
