/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountEnrichmentFieldsLimitQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountEnrichmentFieldsLimitQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly enrichmentFieldsLimit: number } | null };


export const AccountEnrichmentFieldsLimitDocument = gql`
    query AccountEnrichmentFieldsLimit($accountId: AccountId!) {
  account(id: $accountId) {
    id
    enrichmentFieldsLimit
  }
}
    `;

/**
 * __useAccountEnrichmentFieldsLimitQuery__
 *
 * To run a query within a React component, call `useAccountEnrichmentFieldsLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountEnrichmentFieldsLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountEnrichmentFieldsLimitQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountEnrichmentFieldsLimitQuery(baseOptions: Apollo.QueryHookOptions<AccountEnrichmentFieldsLimitQuery, AccountEnrichmentFieldsLimitQueryVariables> & ({ variables: AccountEnrichmentFieldsLimitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountEnrichmentFieldsLimitQuery, AccountEnrichmentFieldsLimitQueryVariables>(AccountEnrichmentFieldsLimitDocument, options);
      }
export function useAccountEnrichmentFieldsLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountEnrichmentFieldsLimitQuery, AccountEnrichmentFieldsLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountEnrichmentFieldsLimitQuery, AccountEnrichmentFieldsLimitQueryVariables>(AccountEnrichmentFieldsLimitDocument, options);
        }
export function useAccountEnrichmentFieldsLimitSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountEnrichmentFieldsLimitQuery, AccountEnrichmentFieldsLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountEnrichmentFieldsLimitQuery, AccountEnrichmentFieldsLimitQueryVariables>(AccountEnrichmentFieldsLimitDocument, options);
        }
export type AccountEnrichmentFieldsLimitQueryHookResult = ReturnType<typeof useAccountEnrichmentFieldsLimitQuery>;
export type AccountEnrichmentFieldsLimitLazyQueryHookResult = ReturnType<typeof useAccountEnrichmentFieldsLimitLazyQuery>;
export type AccountEnrichmentFieldsLimitSuspenseQueryHookResult = ReturnType<typeof useAccountEnrichmentFieldsLimitSuspenseQuery>;
export type AccountEnrichmentFieldsLimitQueryResult = Apollo.QueryResult<AccountEnrichmentFieldsLimitQuery, AccountEnrichmentFieldsLimitQueryVariables>;