import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import Form from '~/components/atoms/forms/basis/Form';
import HelpHint from '~/components/patterns/hints/HelpHint';
import PageBundleSignupField from '~/components/app/PageBundleSignupField';
import TextualList from '~/components/patterns/lists/TextualList';

import useAccountSignup from '~/hooks/useAccountSignup';



const messages = defineMessages({
	title: {
		id: 'ui.signup.pagesConfigurator.title',
	},
	tooltip1: {
		id: 'ui.signup.pagesConfigurator.tooltip1',
	},
	tooltip2: {
		id: 'ui.signup.pagesConfigurator.tooltip2',
	},
});



type Props = {
	accountId: CK.AccountId,
};

const PagesConfiguratorSection: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountSignup = useAccountSignup(accountId);
	const accountSignupSetProperty = accountSignup.setProperty;

	const handleChange = React.useCallback(
		(field, value) => {
			accountSignupSetProperty('pageBundle', value);
		},
		[
			accountSignupSetProperty,
		],
	);

	return (
		<Form
			defaultValues={{
				pageBundle: accountSignup.pageBundle,
			}}
			onChangeCallback={handleChange}
		>
			<PageBundleSignupField
				name="pageBundle"
				optimalPageBundle={accountSignup.optimalPageBundle}
				title={({
					inputField,
				}) => (
					<AttachedElement
						element={(
							<HelpHint
								message={(
									<TextualList>
										<FormattedMessage {...messages.tooltip1} />
										<FormattedMessage {...messages.tooltip2} />
									</TextualList>
								)}
							/>
						)}
					>
						<FormattedMessage
							{...messages.title}
							values={{
								inputField,
							}}
						/>
					</AttachedElement>
				)}
			/>
		</Form>
	);
};



export default PagesConfiguratorSection;
