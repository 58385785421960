import React from 'react';

import GraphQL from '~/types/graphql';

import PageCurrentData from '~/components/atoms/pageDetail/components/PageCurrentData';
import PageLastCheckedInfo, {
	PageLastCheckedMessageType,
} from '~/components/app/PageLastCheckedInfo';
import PageTimestamp from '~/components/patterns/screens/pageDetail/sections/PageTimestamp';
import PageUnknownLastCheckedInfo from '~/components/app/PageUnknownLastCheckedInfo';
import StatusMessage from '~/components/logic/pageDetail/components/statuses/StatusMessage';
import Summary from '~/components/logic/pageDetail/components/Summary';
import UnreliableCircumstances from '~/components/logic/pageDetail/components/statuses/UnreliableCircumstances';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageIsUnreliable from '~/hooks/usePageIsUnreliable';
import usePageType from '~/hooks/usePageType';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';



const CurrentPage: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const pageIsUnreliable = usePageIsUnreliable(legacyUrlId, websiteId);
	const pageType = usePageType(legacyUrlId, websiteId);
	const websiteIsCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId);

	const statusMessages: Array<React.ReactNode> = [];

	if (pageIsUnreliable) {
		statusMessages.push(
			<UnreliableCircumstances
				key="unreliable_circumstances"
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>,
		);
	}

	statusMessages.push(
		<StatusMessage
			isCrawlingPaused={websiteIsCrawlingPaused ?? false}
			key="status_message"
			legacyUrlId={legacyUrlId}
			websiteId={websiteId}
		/>,
	);

	return (
		<>
			<PageTimestamp key="page-timestamp">
				{pageType === GraphQL.PageType.Unknown ? (
					<PageUnknownLastCheckedInfo isPaused={websiteIsCrawlingPaused ?? false} />
				) : (
					<PageLastCheckedInfo
						legacyUrlId={legacyUrlId}
						messageType={PageLastCheckedMessageType.Page}
						websiteId={websiteId}
					/>
				)}
			</PageTimestamp>

			<Summary
				key="summary"
				statusMessages={statusMessages}
			/>

			<PageCurrentData key={`${websiteId}/${legacyUrlId}`} />
		</>
	);
};



export default CurrentPage;
