import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	Benefit,
} from '~/model/benefits';

import {
	assertProperty,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	[Benefit.AdobeAnalytics]: { id: 'ui.planBenefits.benefit.adobeAnalytics' },
	[Benefit.Alerting]: { id: 'ui.planBenefits.benefit.alerting' },
	[Benefit.AlertsForCustomElements]: { id: 'ui.planBenefits.benefit.alertsForCustomElements' },
	[Benefit.AuditLog]: { id: 'ui.planBenefits.benefit.auditLog' },
	[Benefit.ChangeTracking]: { id: 'ui.planBenefits.benefit.changeTracking' },
	[Benefit.ChatSupport]: { id: 'ui.planBenefits.benefit.chatSupport' },
	[Benefit.ClientAccess]: { id: 'ui.planBenefits.benefit.clientAccess' },
	[Benefit.Cobranding]: { id: 'ui.planBenefits.benefit.cobranding' },
	[Benefit.CoreWebVitalsMonitoring]: { id: 'ui.planBenefits.benefit.coreWebVitalsMonitoring' },
	[Benefit.CustomElementExtraction]: { id: 'ui.planBenefits.benefit.customElementExtraction' },
	[Benefit.CustomMonitoringLocation]: { id: 'ui.planBenefits.benefit.customMonitoringLocation' },
	[Benefit.CustomTraining]: { id: 'ui.planBenefits.benefit.customTraining' },
	[Benefit.CWVMonitoringAndJSRendering]: { id: 'ui.planBenefits.benefit.cwvMonitoringAndJSRendering' },
	[Benefit.DataEnrichmentAPI]: { id: 'ui.planBenefits.benefit.dataEnrichmentAPI' },
	[Benefit.DataRetention]: { id: 'ui.planBenefits.benefit.dataRetention' },
	[Benefit.DedicatedSuccessManager]: { id: 'ui.planBenefits.benefit.dedicatedSuccessManager' },
	[Benefit.EmailSupport]: { id: 'ui.planBenefits.benefit.emailSupport' },
	[Benefit.Enforce2FA]: { id: 'ui.planBenefits.benefit.enforce2FA' },
	[Benefit.GoogleAnalytics]: { id: 'ui.planBenefits.benefit.googleAnalytics' },
	[Benefit.GoogleChromeExtension]: { id: 'ui.planBenefits.benefit.googleChromeExtension' },
	[Benefit.GoogleLookerStudio]: { id: 'ui.planBenefits.benefit.googleLookerStudio' },
	[Benefit.GoogleSearchConsole]: { id: 'ui.planBenefits.benefit.googleSearchConsole' },
	[Benefit.GuidesAndVideos]: { id: 'ui.planBenefits.benefit.guidesAndVideos' },
	[Benefit.HttpBodySnapshot]: { id: 'ui.planBenefits.benefit.httpBodySnapshot' },
	[Benefit.HttpHeaderSnapshot]: { id: 'ui.planBenefits.benefit.httpHeaderSnapshot' },
	[Benefit.JsRendering]: { id: 'ui.planBenefits.benefit.jsRendering' },
	[Benefit.LightspeedMonitoring]: { id: 'ui.planBenefits.benefit.lightspeedMonitoring' },
	[Benefit.LogFileAnalysis]: { id: 'ui.planBenefits.benefit.logFileAnalysis' },
	[Benefit.LogFileExplorer]: { id: 'ui.planBenefits.benefit.logFileExplorer' },
	[Benefit.MicrosoftTeams]: { id: 'ui.planBenefits.benefit.microsoftTeams' },
	[Benefit.MonitoringFromUSUKEU]: { id: 'ui.planBenefits.benefit.monitoringFromUSUKEU' },
	[Benefit.NonStopMonitoring]: { id: 'ui.planBenefits.benefit.nonStopMonitoring' },
	[Benefit.OrganizationAdminRole]: { id: 'ui.planBenefits.benefit.organizationAdminRole' },
	[Benefit.PageBundles]: { id: 'ui.planBenefits.benefit.pageBundles' },
	[Benefit.PhoneSupport]: { id: 'ui.planBenefits.benefit.phoneSupport' },
	[Benefit.RealtimeIndexNow]: { id: 'ui.planBenefits.benefit.realTimeIndexNow' },
	[Benefit.Rechecks]: { id: 'ui.planBenefits.benefit.rechecks' },
	[Benefit.ReportingApi]: { id: 'ui.planBenefits.benefit.reportingApi' },
	[Benefit.RestrictEmailDomains]: { id: 'ui.planBenefits.benefit.restrictEmailDomains' },
	[Benefit.Slack]: { id: 'ui.planBenefits.benefit.slack' },
	[Benefit.Users]: { id: 'ui.planBenefits.benefit.users' },
	[Benefit.WebsiteAuditing]: { id: 'ui.planBenefits.benefit.websiteAuditing' },
	[Benefit.Websites]: { id: 'ui.planBenefits.benefit.websites' },
});



type Props = {
	benefit: Benefit,
};

const BenefitName: React.FC<Props> = (props) => {
	const {
		benefit,
	} = props;

	assertProperty(messages, benefit);

	return (
		<FormattedMessage {...messages[benefit]} />
	);
};



export default BenefitName;
