/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptEnrichmentApiTermsOfUseMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AcceptEnrichmentApiTermsOfUseMutation = { readonly __typename?: 'Mutation', readonly AcceptEnrichmentApiTermsOfUse: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly enrichmentApiToken: string | null, readonly id: CK.AccountId, readonly isEnrichmentApiTermsOfUseAccepted: boolean } | null } } };


export const AcceptEnrichmentApiTermsOfUseDocument = gql`
    mutation AcceptEnrichmentApiTermsOfUse($accountId: AccountId!) {
  AcceptEnrichmentApiTermsOfUse(accountId: $accountId) {
    query {
      account(id: $accountId) {
        enrichmentApiToken
        id
        isEnrichmentApiTermsOfUseAccepted
      }
    }
  }
}
    `;
export type AcceptEnrichmentApiTermsOfUseMutationFn = Apollo.MutationFunction<AcceptEnrichmentApiTermsOfUseMutation, AcceptEnrichmentApiTermsOfUseMutationVariables>;

/**
 * __useAcceptEnrichmentApiTermsOfUseMutation__
 *
 * To run a mutation, you first call `useAcceptEnrichmentApiTermsOfUseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptEnrichmentApiTermsOfUseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptEnrichmentApiTermsOfUseMutation, { data, loading, error }] = useAcceptEnrichmentApiTermsOfUseMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAcceptEnrichmentApiTermsOfUseMutation(baseOptions?: Apollo.MutationHookOptions<AcceptEnrichmentApiTermsOfUseMutation, AcceptEnrichmentApiTermsOfUseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptEnrichmentApiTermsOfUseMutation, AcceptEnrichmentApiTermsOfUseMutationVariables>(AcceptEnrichmentApiTermsOfUseDocument, options);
      }
export type AcceptEnrichmentApiTermsOfUseMutationHookResult = ReturnType<typeof useAcceptEnrichmentApiTermsOfUseMutation>;
export type AcceptEnrichmentApiTermsOfUseMutationResult = Apollo.MutationResult<AcceptEnrichmentApiTermsOfUseMutation>;
export type AcceptEnrichmentApiTermsOfUseMutationOptions = Apollo.BaseMutationOptions<AcceptEnrichmentApiTermsOfUseMutation, AcceptEnrichmentApiTermsOfUseMutationVariables>;