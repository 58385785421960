import React from 'react';

import FormsList from '~/components/atoms/lists/FormsList';
import WebsiteSettingsIndexNowConditionsForm from '~/components/app/WebsiteSettingsIndexNowConditionsForm';
import WebsiteSettingsIndexNowTriggersForm from '~/components/app/WebsiteSettingsIndexNowTriggersForm';
import WebsiteSettingsIndexNowForm from '~/components/app/WebsiteSettingsIndexNowForm';

import useIndexNowConditions from '~/hooks/useIndexNowConditions';
import useIndexNowTriggers from '~/hooks/useIndexNowTriggers';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsIndexNowEnabled from '~/hooks/useWebsiteIsIndexNowEnabled';



const WebsiteSettingsIndexNowGroup: React.FC = () => {
	const websiteId = useWebsiteId();
	const isIndexNowEnabled = useWebsiteIsIndexNowEnabled(websiteId);
	useIndexNowTriggers(websiteId);
	useIndexNowConditions(websiteId);

	return (
		<FormsList>
			<WebsiteSettingsIndexNowForm />

			{isIndexNowEnabled && (
				<WebsiteSettingsIndexNowTriggersForm />
			)}

			{isIndexNowEnabled && (
				<WebsiteSettingsIndexNowConditionsForm />
			)}
		</FormsList>
	);
};



export default WebsiteSettingsIndexNowGroup;
