import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import IgnoreWebsiteCases from '~/components/atoms/issues/components/ignoringCases/IgnoreWebsiteCases';
import IgnorePages from '~/components/atoms/issues/components/ignoringCases/IgnorePages';
import IgnoreSegments from '~/components/atoms/issues/components/ignoringCases/IgnoreSegments';
import IgnoreOnWebsite from '~/components/atoms/issues/components/ignoringCases/IgnoreOnWebsite';
import IssueTitle from '~/components/logic/issues/IssueTitle';
import ModalLayout from '~/components/logic/issuesIgnoring/ModalLayout';

import useGetIssue from '~/hooks/useGetIssue';
import useOpenedWebsiteIssueCategory from '~/hooks/useOpenedWebsiteIssueCategory';
import useReloadWebsiteScopeIssues from '~/hooks/useReloadWebsiteScopeIssues';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	IgnoringScope,
	PAGE_ISSUES_WITH_CASES,
} from '~/model/issuesNew';

import {
	issuesOverviewIgnoringScopeSelector,
	issuesOverviewIssueSelector,
} from '~/state/ui/content/selectors';



const messages = defineMessages({
	navigationImages: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.images',
	},
	navigationLinks: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.links',
	},
	navigationPages: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.pages',
	},
	navigationSegments: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.segments',
	},
	navigationWebsite: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.website',
	},
});



const IgnoreWebsiteIssueModal: React.FC = () => {
	const websiteId = useWebsiteId();

	const issueCategory = useOpenedWebsiteIssueCategory(websiteId);
	const issueName = useSelector(issuesOverviewIssueSelector);
	const reloadWebsiteScopeIssues = useReloadWebsiteScopeIssues(websiteId);
	const scope = useSelector(issuesOverviewIgnoringScopeSelector);
	const segments = useWebsiteSegmentDefinitions(websiteId);

	const issue = useGetIssue(
		issueCategory,
		issueName,
	);

	const issueCategoryName = issueCategory?.name ?? null;

	const listScopes = () => {
		if (issue === null || issueCategoryName === null) {
			return [];
		}

		const scopes: Array<{
			badge: number | false,
			content: React.ReactNode,
			isNotReady: boolean,
			label: React.ReactNode,
			name: string,
		}> = [];

		scopes.push({
			badge: false,
			content: (
				<IgnoreOnWebsite
					isIgnored={issue.ignoringRuleOnWebsite.isActive}
					isPlatformIssue={issue.affectedPagesPerState === null}
					issueName={issue.name}
					reloadCallback={reloadWebsiteScopeIssues}
					websiteId={websiteId}
				/>
			),
			isNotReady: false,
			label: (
				<FormattedMessage {...messages.navigationWebsite} />
			),
			name: IgnoringScope.Website,
		});

		if (issue.ignoringRuleOnSegments !== null) {
			scopes.push({
				badge: issue.ignoringRuleOnSegments.ignoredSegments.length,
				content: (
					<IgnoreSegments
						ignoringRuleOnSegments={issue.ignoringRuleOnSegments}
						issueName={issueName}
						reloadCallback={reloadWebsiteScopeIssues}
						websiteId={websiteId}
					/>
				),
				isNotReady: segments.count === 0 || issue.ignoringRuleOnWebsite.isEffective,
				label: (
					<FormattedMessage {...messages.navigationSegments} />
				),
				name: IgnoringScope.Segments,
			});
		}

		if (issue.ignoringRuleOnPages !== null) {
			scopes.push({
				badge: issue.ignoringRuleOnPages.ignoredPageLegacyIds.length,
				content: (
					<IgnorePages
						ignoringRuleOnPages={issue.ignoringRuleOnPages}
						ignoringRuleOnWebsite={issue.ignoringRuleOnWebsite}
						issueCategoryName={issueCategoryName}
						issueName={issue.name}
						reloadCallback={reloadWebsiteScopeIssues}
						websiteId={websiteId}
					/>
				),
				isNotReady: issue.ignoringRuleOnPages.ignoredPageLegacyIds.length === 0 || issue.ignoringRuleOnWebsite.isEffective,
				label: (
					<FormattedMessage {...messages.navigationPages} />
				),
				name: IgnoringScope.Pages,
			});
		}

		if (issue.ignoringRuleOnCases !== null && PAGE_ISSUES_WITH_CASES.includes(issue.name)) {
			scopes.push({
				badge: issue.ignoringRuleOnCases.ignoredCases.length,
				content: (
					<IgnoreWebsiteCases
						ignoringRuleOnCases={issue.ignoringRuleOnCases}
						ignoringRuleOnPages={issue.ignoringRuleOnPages}
						ignoringRuleOnWebsite={issue.ignoringRuleOnWebsite}
						issueName={issueName}
						reloadCallback={reloadWebsiteScopeIssues}
						websiteId={websiteId}
					/>
				),
				isNotReady: issue.ignoringRuleOnCases.ignoredCases.length === 0 || issue.ignoringRuleOnWebsite.isEffective,
				label: (
					<FormattedMessage {...messages[issueCategoryName === 'images' ? 'navigationImages' : 'navigationLinks']} />
				),
				name: IgnoringScope.Cases,
			});
		}

		return scopes;
	};

	return (
		<ModalLayout
			activeScope={scope}
			isLoading={issue === null}
			scopes={listScopes()}
			title={issue !== null && (
				<IssueTitle
					issue={issue}
				/>
			)}
		/>
	);
};



export default IgnoreWebsiteIssueModal;
