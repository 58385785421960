import React from 'react';

import CancelButton from '~/components/app/CancelButton';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import ItemPerLineTextArea from '~/components/app/ItemPerLineTextArea';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';

import {
	validateField,
} from '~/components/app/validations';

import {
	useAddFreeEmailDomainsMutation,
} from './AdminAddFreeEmailDomainsModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const validations = {
	validateEmailDomains: validateField(
		'emailDomains',
		(f) => [
			f.validateDomainPatterns('email domains'),
		],
	),
};



const AdminAddFreeEmailDomainsModal: React.FC = () => {
	const classicFormBehavior = useClassicFormBehavior();

	const [addFreeEmailDomains] = useAddFreeEmailDomainsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await addFreeEmailDomains({
				variables: {
					emailDomains: values.emailDomains,
				},
			});

			classicFormBehavior.finish();
		},
		[
			addFreeEmailDomains,
			classicFormBehavior,
		],
	);

	return (
		<SimpleModal
			size={SimpleModalSize.Small}
			title="Add free emails"
		>
			<Form
				defaultFocus="emailDomains"
				defaultValues={{
					emailDomains: '',
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRow
					description={(
						<>
							Place one email domain per line. You can use wildcard at the beginning.
						</>
					)}
					fullwidth={true}
					htmlFor="emailDomains"
				>
					<FieldStatus name="validateEmailDomains">
						<ItemPerLineTextArea
							name="emailDomains"
							size={{
								rows: 8,
								width: '100%',
							}}
						/>
					</FieldStatus>
				</FormRow>

				<ButtonsLayout>
					<CancelButton />

					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default AdminAddFreeEmailDomainsModal;
