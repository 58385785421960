/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkCreateAlertDefinitionsMutationVariables = GraphQL.Exact<{
  alertDefinitions: GraphQL.Scalars['Array']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type BulkCreateAlertDefinitionsMutation = { readonly __typename?: 'Mutation', readonly BulkCreateAlertDefinitions: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly alertDefinitions: ReadonlyArray<{ readonly __typename?: 'AlertDefinition', readonly alertType: string, readonly id: CK.AlertDefinitionId, readonly recipients: ReadonlyArray<string>, readonly scope: string, readonly settings: Record<string, any>, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType }> }>, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null } } };


export const BulkCreateAlertDefinitionsDocument = gql`
    mutation BulkCreateAlertDefinitions($alertDefinitions: Array!, $websiteId: WebsiteId!) {
  BulkCreateAlertDefinitions(
    alertDefinitions: $alertDefinitions
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        alertDefinitions {
          alertType
          id
          messagingAppChannels {
            id
            label
            messagingAppType
          }
          recipients
          scope
          settings
        }
        id
        integrations {
          isNotOwned
          status
          type
        }
      }
    }
  }
}
    `;
export type BulkCreateAlertDefinitionsMutationFn = Apollo.MutationFunction<BulkCreateAlertDefinitionsMutation, BulkCreateAlertDefinitionsMutationVariables>;

/**
 * __useBulkCreateAlertDefinitionsMutation__
 *
 * To run a mutation, you first call `useBulkCreateAlertDefinitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateAlertDefinitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateAlertDefinitionsMutation, { data, loading, error }] = useBulkCreateAlertDefinitionsMutation({
 *   variables: {
 *      alertDefinitions: // value for 'alertDefinitions'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useBulkCreateAlertDefinitionsMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateAlertDefinitionsMutation, BulkCreateAlertDefinitionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateAlertDefinitionsMutation, BulkCreateAlertDefinitionsMutationVariables>(BulkCreateAlertDefinitionsDocument, options);
      }
export type BulkCreateAlertDefinitionsMutationHookResult = ReturnType<typeof useBulkCreateAlertDefinitionsMutation>;
export type BulkCreateAlertDefinitionsMutationResult = Apollo.MutationResult<BulkCreateAlertDefinitionsMutation>;
export type BulkCreateAlertDefinitionsMutationOptions = Apollo.BaseMutationOptions<BulkCreateAlertDefinitionsMutation, BulkCreateAlertDefinitionsMutationVariables>;