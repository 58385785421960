import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import DistributionBarChart, {
	DistributionBarChartSize,
} from '~/components/patterns/charts/charts/DistributionBarChart';
import ColumnValueFormatter from '~/components/logic/formatters/ColumnValueFormatter';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	TYPE_UNREACHABLE,
} from '~/model/pages';

import {
	notEmpty,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	label: {
		id: 'ui.distributionFilter.label',
	},
	lighthouseGood: {
		id: 'ui.webVitals.verdict.good',
	},
	lighthouseNeedsImprovement: {
		id: 'ui.webVitals.verdict.needsImprovement',
	},
	lighthousePoor: {
		id: 'ui.webVitals.verdict.poor',
	},
});



const GREEN = '#42CC67';
const BLUE = '#367be2';
const ORANGE = '#FE8719';
const RED = '#FF5959';
const GRAY = '#8595A6';

function getColumnDistributionParts(
	columnName: string,
	customElementDefinitions: ReturnType<typeof useWebsiteCustomElementDefinitions>,
	enrichmentFieldDefinitions: ReturnType<typeof useWebsiteEnrichmentFieldDefinitions>,
) {
	switch (columnName) {

		case CK.PagesCommonColumn.Type:
			return [
				{
					color: GREEN,
					filterable: true,
					value: GraphQL.PageType.Page,
				},
				{
					color: BLUE,
					filterable: true,
					value: GraphQL.PageType.Redirect,
				},
				{
					color: ORANGE,
					filterable: true,
					value: GraphQL.PageType.Missing,
				},
				{
					color: RED,
					filterable: true,
					value: GraphQL.PageType.ServerError,
				},
				{
					color: GRAY,
					filterable: true,
					value: TYPE_UNREACHABLE,
				},
			];

		case CK.PagesCommonColumn.IsLinked:
		case CK.PagesCommonColumn.IsIndexable:
		case CK.PagesCommonColumn.IsInSitemap:
		case CK.PagesCommonColumn.IsSecured:
			return [
				{
					color: GREEN,
					filterable: true,
					value: true,
				},
				{
					color: ORANGE,
					filterable: true,
					value: false,
				},
			];

		case CK.PagesCommonColumn.IsDisallowedInRobotsTxt:
			return [
				{
					color: GREEN,
					filterable: true,
					value: false,
				},
				{
					color: ORANGE,
					filterable: true,
					value: true,
				},
			];

		case CK.PagesCommonColumn.IsIndexableDueToMetaRobots:
		case CK.PagesCommonColumn.IsIndexableDueToXRobotsTag:
			return [
				{
					color: GREEN,
					filterable: true,
					value: 'yes',
				},
				{
					color: ORANGE,
					filterable: true,
					value: 'no',
				},
				{
					color: GRAY,
					filterable: false,
					value: 'not_applicable',
				},
			];

		case CK.PagesCommonColumn.LighthouseCumulativeLayoutShift:
		case CK.PagesCommonColumn.LighthouseFirstContentfulPaint:
		case CK.PagesCommonColumn.LighthouseLargestContentfulPaint:
		case CK.PagesCommonColumn.LighthousePerformance:
		case CK.PagesCommonColumn.LighthouseSpeedIndex:
		case CK.PagesCommonColumn.LighthouseTimeToInteractive:
		case CK.PagesCommonColumn.LighthouseTotalBlockingTime:
			return [
				{
					color: GREEN,
					displayValue: (
						<FormattedMessage {...messages.lighthouseGood} />
					),
					filterable: true,
					value: GraphQL.WebVitalRange.Good,
				},
				{
					color: ORANGE,
					displayValue: (
						<FormattedMessage {...messages.lighthouseNeedsImprovement} />
					),
					filterable: true,
					value: GraphQL.WebVitalRange.NeedsImprovement,
				},
				{
					color: RED,
					displayValue: (
						<FormattedMessage {...messages.lighthousePoor} />
					),
					filterable: true,
					value: GraphQL.WebVitalRange.Bad,
				},
				{
					color: GRAY,
					filterable: false,
					value: 'not_applicable',
				},
			];

	}

	const customElementDefinition = customElementDefinitions.getByColumn(columnName);

	if (customElementDefinition !== null) {
		const dataType = customElementDefinition.dataType;

		if (dataType === GraphQL.CustomElementDataType.Boolean) {
			return [
				{
					color: GREEN,
					displayValue: 'Yes',
					filterable: true,
					filterValue: 'yes',
					value: 'true',
				},
				{
					color: ORANGE,
					displayValue: 'No',
					filterable: true,
					filterValue: 'no',
					value: 'false',
				},
				{
					color: GRAY,
					filterable: false,
					value: 'not_found',
				},
			];
		}
	}

	const enrichmentFieldDefinition = enrichmentFieldDefinitions.getByColumn(columnName);

	if (enrichmentFieldDefinition !== null) {
		const dataType = enrichmentFieldDefinition.dataType;

		if (dataType === GraphQL.EnrichmentFieldDataType.Boolean) {
			return [
				{
					color: GREEN,
					displayValue: 'Yes',
					filterable: true,
					filterValue: 'yes',
					value: 'true',
				},
				{
					color: ORANGE,
					displayValue: 'No',
					filterable: true,
					filterValue: 'no',
					value: 'false',
				},
				{
					color: GRAY,
					filterable: false,
					value: 'not_found',
				},
			];
		}
	}

	return null;
}



type Props = {
	columnName: CK.PagesColumn,
	distributions: Record<string, {
		values: Array<{
			count: number,
			filter?: string,
			percentage: number,
			value: string,
		}>,
		not_applicable: number,
	}>,
	onFilterChangeCallback: (filter: any) => void,
};

const DistributionFilter: React.FC<Props> = (props) => {
	const {
		columnName,
		distributions,
		onFilterChangeCallback,
	} = props;

	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	const data = React.useMemo(
		() => {
			const distributionData = distributions[columnName]?.values;

			if (distributionData === undefined) {
				return null;
			}

			const columnDistributionParts = getColumnDistributionParts(
				columnName,
				customElementDefinitions,
				enrichmentFieldDefinitions,
			);

			if (columnDistributionParts === null) {
				return null;
			}

			const customElementNames = customElementDefinitions
				.listAll()
				.map((customElementDefinition) => customElementDefinition.name);

			const data = columnDistributionParts.map((part) => {
				const columnData = distributionData.find((entry) => {
					return entry.value === part.value;
				});

				if (columnData === undefined || columnData.count === 0) {
					return null;
				}

				let onClickCallback;

				if (part.filterable) {
					onClickCallback = () => {
						let filter: string | Array<string>;

						if (columnData.filter) {
							filter = columnData.filter;
						} else if (part.hasOwnProperty('filterValue')) {
							filter = part.filterValue;
						} else {
							filter = [columnData.value];
						}

						onFilterChangeCallback({
							[columnName]: filter,
						});
					};
				}

				return {
					color: part.color,
					value: columnData.count,
					onClickCallback,
					label: (
						<FormattedMessage
							{...messages.label}
							values={{
								count: columnData.count,
								percentage: columnData.percentage,
								value: part.displayValue || (
									<ColumnValueFormatter
										column={columnName}
										customElements={customElementNames}
										value={columnData.value}
									/>
								),
							}}
						/>
					),
				};
			});

			return data.filter(notEmpty);
		},
		[
			columnName,
			customElementDefinitions,
			distributions,
			enrichmentFieldDefinitions,
			onFilterChangeCallback,
		],
	);

	if (data === null) {
		return null;
	}

	return (
		<DistributionBarChart
			data={data}
			size={DistributionBarChartSize.Small}
		/>
	);
};



export default DistributionFilter;
