import React from 'react';

import Caption from '~/components/patterns/headings/Caption';



type Props = {
	/** Additional info */
	children?: React.ReactNode,
	/** Main announcement of this message */
	message: React.ReactNode,
};



const StatusMessage: React.FC<Props> = (props) => {
	const {
		children,
		message,
	} = props;

	return (
		<div className="status-message">
			<div className="status-message__message">
				<Caption>
					{message}
				</Caption>
			</div>

			{children && (
				<div className="status-message__details">
					{children}
				</div>
			)}
		</div>
	);
};



export default StatusMessage;
