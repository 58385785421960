import React from 'react';
import {
	Link,
} from 'react-router5';



type Props = {
	children: React.ReactNode,
	routeName: string,
	routeParams?: {},
};



const ClickableArea: React.FC<Props> = (props) => {
	const {
		children,
		routeName,
		routeParams = {},
	} = props;

	return (
		<Link
			className="clickable-area"
			routeName={routeName}
			routeParams={routeParams}
		>
			{children}
		</Link>
	);
};



export default ClickableArea;
