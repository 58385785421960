/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopySegmentToWebsitesModalQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type CopySegmentToWebsitesModalQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly displayName: string, readonly id: CK.WebsiteId, readonly account: { readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId } | null, readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElement', readonly name: string }>, readonly enrichmentFields: ReadonlyArray<{ readonly __typename?: 'EnrichmentField', readonly column: `ef_${string}`, readonly id: CK.ID }>, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly color: string, readonly id: CK.PageSegmentId, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string } | null }> }> }> } | null };

export type CopySegmentToWebsitesMutationVariables = GraphQL.Exact<{
  sourceSegmentId: GraphQL.Scalars['PageSegmentId']['input'];
  sourceWebsiteId: GraphQL.Scalars['WebsiteId']['input'];
  targetWebsiteIds: ReadonlyArray<GraphQL.Scalars['WebsiteId']['input']> | GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CopySegmentToWebsitesMutation = { readonly __typename?: 'Mutation', readonly CopySegmentToWebsites: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly id: CK.PageSegmentId, readonly name: CK.PageSegmentName }> }> }> } | null } } };


export const CopySegmentToWebsitesModalDocument = gql`
    query CopySegmentToWebsitesModal {
  authenticatedSession {
    accounts {
      displayName
      id
      websites {
        account {
          displayName
          id
        }
        customElements {
          name
        }
        displayName
        enrichmentFields {
          column
          id
        }
        id
        pageSegments {
          color
          icon {
            name
          }
          id
          label
          name
          shortcode
        }
      }
    }
  }
}
    `;

/**
 * __useCopySegmentToWebsitesModalQuery__
 *
 * To run a query within a React component, call `useCopySegmentToWebsitesModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopySegmentToWebsitesModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopySegmentToWebsitesModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCopySegmentToWebsitesModalQuery(baseOptions?: Apollo.QueryHookOptions<CopySegmentToWebsitesModalQuery, CopySegmentToWebsitesModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopySegmentToWebsitesModalQuery, CopySegmentToWebsitesModalQueryVariables>(CopySegmentToWebsitesModalDocument, options);
      }
export function useCopySegmentToWebsitesModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopySegmentToWebsitesModalQuery, CopySegmentToWebsitesModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopySegmentToWebsitesModalQuery, CopySegmentToWebsitesModalQueryVariables>(CopySegmentToWebsitesModalDocument, options);
        }
export function useCopySegmentToWebsitesModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CopySegmentToWebsitesModalQuery, CopySegmentToWebsitesModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CopySegmentToWebsitesModalQuery, CopySegmentToWebsitesModalQueryVariables>(CopySegmentToWebsitesModalDocument, options);
        }
export type CopySegmentToWebsitesModalQueryHookResult = ReturnType<typeof useCopySegmentToWebsitesModalQuery>;
export type CopySegmentToWebsitesModalLazyQueryHookResult = ReturnType<typeof useCopySegmentToWebsitesModalLazyQuery>;
export type CopySegmentToWebsitesModalSuspenseQueryHookResult = ReturnType<typeof useCopySegmentToWebsitesModalSuspenseQuery>;
export type CopySegmentToWebsitesModalQueryResult = Apollo.QueryResult<CopySegmentToWebsitesModalQuery, CopySegmentToWebsitesModalQueryVariables>;
export const CopySegmentToWebsitesDocument = gql`
    mutation CopySegmentToWebsites($sourceSegmentId: PageSegmentId!, $sourceWebsiteId: WebsiteId!, $targetWebsiteIds: [WebsiteId!]!) {
  CopySegmentToWebsites(
    sourceSegmentId: $sourceSegmentId
    sourceWebsiteId: $sourceWebsiteId
    targetWebsiteIds: $targetWebsiteIds
  ) {
    query {
      authenticatedSession {
        accounts {
          id
          memberships {
            account {
              id
            }
            websites {
              id
            }
          }
          websites {
            id
            pageSegments {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type CopySegmentToWebsitesMutationFn = Apollo.MutationFunction<CopySegmentToWebsitesMutation, CopySegmentToWebsitesMutationVariables>;

/**
 * __useCopySegmentToWebsitesMutation__
 *
 * To run a mutation, you first call `useCopySegmentToWebsitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySegmentToWebsitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySegmentToWebsitesMutation, { data, loading, error }] = useCopySegmentToWebsitesMutation({
 *   variables: {
 *      sourceSegmentId: // value for 'sourceSegmentId'
 *      sourceWebsiteId: // value for 'sourceWebsiteId'
 *      targetWebsiteIds: // value for 'targetWebsiteIds'
 *   },
 * });
 */
export function useCopySegmentToWebsitesMutation(baseOptions?: Apollo.MutationHookOptions<CopySegmentToWebsitesMutation, CopySegmentToWebsitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopySegmentToWebsitesMutation, CopySegmentToWebsitesMutationVariables>(CopySegmentToWebsitesDocument, options);
      }
export type CopySegmentToWebsitesMutationHookResult = ReturnType<typeof useCopySegmentToWebsitesMutation>;
export type CopySegmentToWebsitesMutationResult = Apollo.MutationResult<CopySegmentToWebsitesMutation>;
export type CopySegmentToWebsitesMutationOptions = Apollo.BaseMutationOptions<CopySegmentToWebsitesMutation, CopySegmentToWebsitesMutationVariables>;