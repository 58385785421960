export default class Timeout {

	constructor(action, delay) {
		if (typeof action !== 'function') {
			throw new Error('"action" argument of Timeout must be function');
		}

		this.action = action;
		this.delay = delay;
		this.timeout = null;
	}



	start(initialAction) {
		let initialActionResult;

		if (typeof initialAction === 'function') {
			initialActionResult = initialAction();
		}

		if (typeof initialAction === 'boolean' && initialAction) {
			initialActionResult = this.action();
		}

		const start = () => {
			this.timeout = setTimeout(() => {
				this.stop();
				this.action();
			}, this.delay);
		};

		if (initialActionResult instanceof Promise) {
			initialActionResult.then(start);
		} else {
			start();
		}

		return this;
	}



	stop() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.timeout = null;
		return this;
	}

}
