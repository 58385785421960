import classNames from 'classnames';
import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';

import Emphasis from '~/components/patterns/typography/Emphasis';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type Props = {
	/** Enable gaps around websites list items */
	hasGaps?: boolean,
	children: React.ReactNode,
	/** Enable multi-columns layout */
	multiColumns?: boolean,
	placeholder?: React.ReactNode,
};



const WebsitesList: React.FC<Props> = (props) => {
	const {
		hasGaps = true,
		children,
		multiColumns = false,
		placeholder,
	} = props;

	const elements = flattenChildren(children).filter(notEmpty);

	const componentClasses = classNames({
		'websites-list': true,
		'websites-list--has-gaps': hasGaps,
		'websites-list--multi-columns': multiColumns,
	});

	return (
		<div className={componentClasses}>
			{elements.length > 0 ? elements.map((child, index) => (
				<div
					className="websites-list__item"
					key={'website-' + index}
				>
					{child}
				</div>
			)) : (
				<div className="websites-list__placeholder">
					<Emphasis muted={true}>
						{placeholder}
					</Emphasis>
				</div>
			)}
		</div>
	);
};



export default WebsitesList;
