import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AbstractSelectField, {
	type AbstractSelectFieldRef,
	AbstractSelectFieldSize,
} from '~/components/patterns/forms/fields/AbstractSelectField';
import FieldDropdownClickableOptions, {
	FieldDropdownClickableOptionsOptionType,
	type FieldDropdownClickableOptionsProps,
} from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdownClickableOptions';
import SegmentDefinitionFilterIdentifier from '~/components/logic/segments/SegmentDefinitionFilterIdentifier';
import SegmentDefinitionIdentifier from '~/components/logic/segments/SegmentDefinitionIdentifier';
import SegmentFilterWebsiteScopeLabel from '~/components/app/SegmentFilterWebsiteScopeLabel';
import SegmentMultiselectToggler, {
	SegmentMultiselectTogglerSize,
} from '~/components/atoms/forms/components/segments/SegmentMultiselectToggler';
import WebsiteScopeLabel from '~/components/app/WebsiteScopeLabel';

import useFormContext from '~/hooks/useFormContext';

import {
	type SegmentDefinition,
	SegmentDepends,
	dependsOnColumnsWithoutChangeTracking,
	getSegmentDefinitionByName,
} from '~/model/segments';



const inAlertDefinitionsMessages = defineMessages({
	[SegmentDepends.OnLinkColumns]: {
		id: 'ui.segments.selection.inAlertDefinitions.disabled.dueToLinksColumns',
	},
	[SegmentDepends.OnSchemaOrgColumns]: {
		id: 'ui.segments.selection.inAlertDefinitions.disabled.dueToSchemaOrgColumns',
	},
	[SegmentDepends.OnTimeServerResponseColumn]: {
		id: 'ui.segments.selection.inAlertDefinitions.disabled.dueToTimeServerResponseColumn',
	},
});

const inTrackedChangesMessages = defineMessages({
	[SegmentDepends.OnLinkColumns]: {
		id: 'ui.segments.selection.inTrackedChanges.disabled.dueToLinksColumns',
	},
	[SegmentDepends.OnSchemaOrgColumns]: {
		id: 'ui.segments.selection.inTrackedChanges.disabled.dueToSchemaOrgColumns',
	},
	[SegmentDepends.OnTimeServerResponseColumn]: {
		id: 'ui.segments.selection.inTrackedChanges.disabled.dueToTimeServerResponseColumn',
	},
});



export type SegmentSelectFieldRef = {
	changeValue: (value: any, close?: boolean) => void,
};



type Props = {
	dropdownWidth?: number,
	inAlertDefinitions?: boolean,
	inTrackedChanges?: boolean,
	/** Name of field */
	name: string,
	/** Placeholder visible when no option is selected */
	placeholder?: string,
	popperEnabled?: boolean,
	/** Definition of other segment options */
	segments: ReadonlyArray<SegmentDefinition>,
	size?: AbstractSelectFieldSize,
	/** Possibility to set own name for website scope option */
	websiteScopeName?: string | null,
	width?: React.CSSProperties['width'],
};

const SegmentSelectField = React.forwardRef<SegmentSelectFieldRef, Props>((props, ref) => {
	const {
		dropdownWidth = 280,
		inAlertDefinitions = false,
		inTrackedChanges = false,
		name,
		placeholder,
		popperEnabled = true,
		segments,
		size = AbstractSelectFieldSize.Default,
		websiteScopeName = 'all',
		width = 280,
	} = props;

	const formContext = useFormContext();

	const formContextOnBlurHandler = formContext.onBlurHandler;
	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnFocusHandler = formContext.onFocusHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const [selectedSegment, setSelectedSegment] = React.useState(
		() => formContext.defaultValues[name],
	);

	const selectRef = React.useRef<AbstractSelectFieldRef>(null);

	React.useImperativeHandle(ref, () => ({
		changeValue: (value, close = true) => {
			if (close) {
				selectRef.current?.close();
			}

			setSelectedSegment(value);
		},
	}));

	React.useEffect(
		() => {
			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnUnmountHandler,
			name,
		],
	);

	const defaultFormValue = formContext.defaultValues[name];

	React.useEffect(
		() => {
			if (defaultFormValue && selectedSegment === undefined) {
				setSelectedSegment(defaultFormValue);

				selectRef.current?.close();
			}
		},
		[
			defaultFormValue,
			selectedSegment,
		],
	);

	const handleDropdownCloseCallback = React.useCallback(
		() => {
			formContextOnBlurHandler(name);
		},
		[
			formContextOnBlurHandler,
			name,
		],
	);

	const handleDropdownOpenCallback = React.useCallback(
		() => {
			formContextOnFocusHandler(name);
		},
		[
			formContextOnFocusHandler,
			name,
		],
	);

	const handleOptionClick = React.useCallback(
		(value) => {
			setSelectedSegment(value);

			selectRef.current?.close();

			formContextOnChangeHandler(name, value);
		},
		[
			formContextOnChangeHandler,
			name,
		],
	);

	function renderLabelText() {
		if (selectedSegment) {
			if (selectedSegment === websiteScopeName) {
				return (
					<SegmentFilterWebsiteScopeLabel />
				);
			}

			const segmentData = getSegmentDefinitionByName(
				segments,
				selectedSegment,
			);

			if (segmentData === null) {
				return null;
			}

			return (
				<SegmentDefinitionFilterIdentifier
					segmentDefinition={segmentData}
				/>
			);
		}

		// this means that we will use placeholder instead
		return null;
	}

	function renderDropdownOptions() {
		const options: Array<FieldDropdownClickableOptionsProps> = [];

		if (websiteScopeName !== null) {
			options.push({
				label: (
					<WebsiteScopeLabel
						showHint={false}
					/>
				),
				name: websiteScopeName,
				selected: selectedSegment === websiteScopeName,
				type: FieldDropdownClickableOptionsOptionType.Segment,
			});
		}

		segments.forEach((segment) => {
			const depends = dependsOnColumnsWithoutChangeTracking(segments, segment);

			const isAllowedForFiltering = (inTrackedChanges || inAlertDefinitions) ? !depends : true;
			const location = inAlertDefinitions ? inAlertDefinitionsMessages : inTrackedChangesMessages;
			const disabledExplanation = depends ? location[depends] : null;

			let label;

			if (isAllowedForFiltering) {
				label = (
					<SegmentDefinitionIdentifier
						segmentDefinition={segment}
						showCriteria={false}
					/>
				);
			} else {
				label = (
					<SegmentDefinitionIdentifier
						disabled={true}
						disabledExplanation={
							disabledExplanation ? <FormattedMessage {...disabledExplanation} /> : null
						}
						segmentDefinition={segment}
						showCriteria={false}
					/>
				);
			}

			options.push({
				disabled: !isAllowedForFiltering,
				label,
				name: segment.name,
				selected: segment.name === selectedSegment,
				type: FieldDropdownClickableOptionsOptionType.Segment,
			});
		});

		return (
			<FieldDropdownClickableOptions
				onOptionClickCallback={handleOptionClick}
				options={options}
			/>
		);
	}

	return (
		<AbstractSelectField
			dropdownWidth={dropdownWidth}
			isDisabled={formContext.isDisabled}
			label={renderLabelText()}
			labelRenderer={(label, isOpen) => (
				<SegmentMultiselectToggler
					isOpen={isOpen}
					label={label}
					placeholder={placeholder}
					size={size === AbstractSelectFieldSize.Small ? SegmentMultiselectTogglerSize.Small : SegmentMultiselectTogglerSize.Default}
				/>
			)}
			onDropdownCloseCallback={handleDropdownCloseCallback}
			onDropdownOpenCallback={handleDropdownOpenCallback}
			popperEnabled={popperEnabled}
			ref={selectRef}
			scrollableDropdown={true}
			size={size}
			width={width}
		>
			{renderDropdownOptions()}
		</AbstractSelectField>
	);
});



export default SegmentSelectField;

export {
	AbstractSelectFieldSize as SegmentSelectFieldSize,
};
