import React from 'react';
import {
	useSelector,
} from 'react-redux';

import ComparisonCategoryDetail from '~/components/logic/issuesOverview/categoryDetail/ComparisonCategoryDetail';
import CurrentState from '~/components/logic/issuesOverview/categoryDetail/CurrentState';

import {
	isActiveSelector as isComparisonModeActiveSelector,
} from '~/state/websiteIssuesComparison/selectors';



const WebsiteIssuesCategory: React.FC = () => {
	const isComparisonModeActive = useSelector(isComparisonModeActiveSelector);

	if (isComparisonModeActive) {
		return (
			<ComparisonCategoryDetail />
		);
	}

	return (
		<CurrentState />
	);
};



export default WebsiteIssuesCategory;
