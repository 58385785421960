import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AccountIconBuilder: IconBuilder = {
	icon: ({ color = '#8595a6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g
					id="users"
					stroke={color}
					strokeWidth="2"
					transform="translate(4.000000, 6.000000)"
				>
					<path
						d="M17.0312,16.0057 L24.0002,16.0057 C24.0002,12.2737 21.4442,9.1377 17.9872,8.2547"
						id="Stroke-1407"
					>
					</path>
					<path
						d="M6.8167,11.2842 C2.9027,12.2612 -0.0003,15.7902 -0.0003,20.0062 L17.9997,20.0062 C17.9997,15.7902 15.0977,12.2622 11.1837,11.2842"
						id="Stroke-1408"
					>
					</path>
					<path
						d="M13.7517,8.0857 C14.4097,8.4847 15.1747,8.7277 16.0007,8.7277 C18.4107,8.7277 20.3637,6.7737 20.3637,4.3637 C20.3637,1.9537 18.4107,-0.0003 16.0007,-0.0003 C14.0857,-0.0003 12.4767,1.2417 11.8887,2.9567"
						id="Stroke-1409"
					>
					</path>
					<path
						d="M13.9096,6.9091 C13.9096,9.6201 11.7116,11.8181 9.0006,11.8181 C6.2896,11.8181 4.0916,9.6201 4.0916,6.9091 C4.0916,4.1981 6.2896,2.0001 9.0006,2.0001 C11.7116,2.0001 13.9096,4.1981 13.9096,6.9091 L13.9096,6.9091 Z"
						id="Stroke-1410"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default AccountIconBuilder;
