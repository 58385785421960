import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import StatusMessage from '~/components/patterns/messages/embedded/StatusMessage';

import {
	isNumber,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	emptyShort: {
		id: 'ui.robotsTxtStatusMessage.empty.short',
	},
	emptyTitle: {
		id: 'ui.robotsTxtStatusMessage.empty.title',
	},
	missingShort: {
		id: 'ui.robotsTxtStatusMessage.missing.short',
	},
	missingTitle: {
		id: 'ui.robotsTxtStatusMessage.missing.title',
	},
	redirectedShort: {
		id: 'ui.robotsTxtStatusMessage.redirected.short',
	},
	redirectedTitle: {
		id: 'ui.robotsTxtStatusMessage.redirected.title',
	},
	timeoutShort: {
		id: 'ui.robotsTxtStatusMessage.timeout.short',
	},
	timeoutTitle: {
		id: 'ui.robotsTxtStatusMessage.timeout.title',
	},
	unavailableShort: {
		id: 'ui.robotsTxtStatusMessage.unavailable.short',
	},
	unavailableTitle: {
		id: 'ui.robotsTxtStatusMessage.unavailable.title',
	},
});



type Props = {
	failureReason?: GraphQL.FetchingFailureReason | null,
	isLatestRevision?: boolean,
	isContentEmpty?: boolean | null,
	shortMessage?: boolean,
	statusCode?: number | null,
};

const RobotsTxtStatusMessage: React.FC<Props> = (props) => {
	const {
		failureReason,
		isLatestRevision = false,
		isContentEmpty = null,
		shortMessage = false,
		statusCode,
	} = props;

	const values = {
		statusCode,
		latestRevision: isLatestRevision ? 'yes' : 'no',
	};

	if (isNumber(statusCode)) {
		if (statusCode >= 300 && statusCode < 400) {
			if (shortMessage) {
				return (
					<FormattedMessage
						{...messages.redirectedShort}
						values={values}
					/>
				);
			}

			return (
				<StatusMessage
					message={(
						<FormattedMessage
							{...messages.redirectedTitle}
							values={values}
						/>
					)}
				/>
			);
		}

		if (statusCode >= 400 && statusCode < 500) {
			if (shortMessage) {
				return (
					<FormattedMessage
						{...messages.missingShort}
						values={values}
					/>
				);
			}

			return (
				<StatusMessage
					message={(
						<FormattedMessage
							{...messages.missingTitle}
							values={values}
						/>
					)}
				/>
			);
		}

		if (statusCode >= 500) {
			if (shortMessage) {
				return (
					<FormattedMessage
						{...messages.unavailableShort}
						values={values}
					/>
				);
			}

			return (
				<StatusMessage
					message={(
						<FormattedMessage
							{...messages.unavailableTitle}
							values={values}
						/>
					)}
				/>
			);
		}
	}

	if (failureReason === GraphQL.FetchingFailureReason.TimeLimit) {
		if (shortMessage) {
			return (
				<FormattedMessage
					{...messages.timeoutShort}
					values={values}
				/>
			);
		}

		return (
			<StatusMessage
				message={(
					<FormattedMessage
						{...messages.timeoutTitle}
						values={values}
					/>
				)}
			/>
		);
	}

	if (
		failureReason === GraphQL.FetchingFailureReason.EmptyResponse
		|| isContentEmpty
		|| statusCode === 0
	) {
		if (shortMessage) {
			return (
				<FormattedMessage
					{...messages.emptyShort}
					values={values}
				/>
			);
		}

		return (
			<StatusMessage
				message={(
					<FormattedMessage
						{...messages.emptyTitle}
						values={values}
					/>
				)}
			/>
		);
	}

	return null;
};



export default RobotsTxtStatusMessage;
