/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteWebsiteMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DeleteWebsiteMutation = { readonly __typename?: 'Mutation', readonly DeleteWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> } | null, readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly id: CK.InvitationId, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }> }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> }> } | null } } };


export const DeleteWebsiteDocument = gql`
    mutation DeleteWebsite($accountId: AccountId!, $websiteId: WebsiteId!) {
  DeleteWebsite(accountId: $accountId, websiteId: $websiteId) {
    query {
      account(id: $accountId) {
        id
        websites {
          id
        }
      }
      authenticatedSession {
        accounts {
          id
          invitations {
            id
            websiteEmailSettings {
              alertEmailsSetup
              isWeeklyReportEnabled
              websiteId
            }
            websites {
              id
            }
          }
          memberships {
            account {
              id
            }
            user {
              email
              id
              legacyId
              websiteEmailSettings {
                alertEmailsSetup
                isWeeklyReportEnabled
                websiteId
              }
            }
            websites {
              id
            }
          }
        }
      }
    }
  }
}
    `;
export type DeleteWebsiteMutationFn = Apollo.MutationFunction<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>;

/**
 * __useDeleteWebsiteMutation__
 *
 * To run a mutation, you first call `useDeleteWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebsiteMutation, { data, loading, error }] = useDeleteWebsiteMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDeleteWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>(DeleteWebsiteDocument, options);
      }
export type DeleteWebsiteMutationHookResult = ReturnType<typeof useDeleteWebsiteMutation>;
export type DeleteWebsiteMutationResult = Apollo.MutationResult<DeleteWebsiteMutation>;
export type DeleteWebsiteMutationOptions = Apollo.BaseMutationOptions<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>;