import {
	createState,
} from './simpleDataList';

import {
	loadSitemapsRangeViaApi,
} from '~/model/sitemaps';



export const {
	loadData,
	reducer,
	selector,
} = createState(
	'invalidStructureSitemaps',
	(websiteId) => loadSitemapsRangeViaApi(websiteId, {
		'fields': 'url,number_of_errors',
		'filter[contains_major_errors]': true,
		'filter[strict]': true,
		'offset': 0,
		'limit': 1000,
	}),
	30,
);
