import Immutable from 'immutable';
import React from 'react';

import GraphQL from '~/types/graphql';

import AdminRetryFailedJobsModal from '~/components/app/AdminRetryFailedJobsModal';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import NewDatatable, {
	type NewDatatableColumnDefinitions,
} from '~/components/app/NewDatatable';
import PlainTextFieldFilter from '~/components/logic/datatables/PlainTextFieldFilter';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	useAdminJobStatisticsQuery,
} from './AdminJobStatistics.gql';

import useOpenModal from '~/hooks/useOpenModal';
import usePollInterval from '~/hooks/usePollInterval';

import {
	ObjectType,
} from '~/model/permissions';



const AdminJobStatistics: React.FC = () => {
	const [filter, setFilter] = React.useState(Immutable.Map({
		context: '',
		failure: '',
		jobQueue: '',
		jobType: '',
	}));

	const { data } = useAdminJobStatisticsQuery({
		pollInterval: usePollInterval(10000),
	});

	const failedJobStatistics = data?.failedJobStatistics ?? null;

	const handleRetryFailedJobsModal = useOpenModal(
		() => (
			<AdminRetryFailedJobsModal />
		),
		[],
	);

	const updateFilter = React.useCallback(
		(filterUpdate) => {
			setFilter((filter) => filter.merge(filterUpdate));
		},
		[],
	);

	const rows = React.useMemo(
		() => {
			return (failedJobStatistics ?? []).filter(
				(row) => {
					return [
						'context',
						'failure',
						'jobQueue',
						'jobType',
					].every((field) => (
						filter.get(field) === ''
						|| row[field].toLowerCase().includes(filter.get(field).toLowerCase())
					));
				},
			);
		},
		[
			failedJobStatistics,
			filter,
		],
	);

	const columns: NewDatatableColumnDefinitions<typeof rows> = React.useMemo(
		() => {
			return [
				{
					name: 'context',
					render: {
						cell: ({ row }) => (
							<Ellipsis>
								{row.context}
							</Ellipsis>
						),
						filter: ({ filterName, filterWidth, ref }) => (
							<PlainTextFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => 'Context',
					},
					width: '12%',
				},
				{
					name: 'jobQueue',
					render: {
						cell: ({ row }) => (
							<Ellipsis>
								{row.jobQueue}
							</Ellipsis>
						),
						filter: ({ filterName, filterWidth, ref }) => (
							<PlainTextFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => 'Job queue',
					},
					width: '15%',
				},
				{
					name: 'jobType',
					render: {
						cell: ({ row }) => (
							<Ellipsis>
								{row.jobType}
							</Ellipsis>
						),
						filter: ({ filterName, filterWidth, ref }) => (
							<PlainTextFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => 'Job type',
					},
					width: '15%',
				},
				{
					name: 'failure',
					render: {
						cell: ({ row }) => (
							<Ellipsis>
								{row.failure}
							</Ellipsis>
						),
						filter: ({ filterName, filterWidth, ref }) => (
							<PlainTextFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => 'Failure',
					},
					width: '53%',
				},
				{
					name: 'numberOfFailedJobs',
					render: {
						cell: ({ row }) => (
							<Ellipsis>
								{row.numberOfFailedJobs}
							</Ellipsis>
						),
						label: () => '# of failed jobs',
					},
					width: '5%',
				},
			];
		},
		[],
	);

	return (
		<NewDatatable
			buttons={(
				<ButtonsGroup>
					<WithPermission
						action={GraphQL.ActionWithWebsite.ManageInternals}
						objectId="platform"
						objectType={ObjectType.Platform}
						showMessage={false}
					>
						{({ isAllowed, message: permissionMessage }) => (
							<Button
								disabled={isAllowed.yes === false}
								icon={(
									<BasicIcon type={BasicIconType.Spinner} />
								)}
								onClick={handleRetryFailedJobsModal}
								size={ButtonSize.Small}
								style={ButtonStyle.Hollow}
								tooltip={permissionMessage}
								uppercase={true}
							>
								Retry failed jobs
							</Button>
						)}
					</WithPermission>
				</ButtonsGroup>
			)}
			columns={columns}
			filter={filter}
			isLoading={failedJobStatistics === null}
			onFilterChange={updateFilter}
			rows={rows}
			title="Failed job statistics"
		/>
	);
};



export default AdminJobStatistics;
