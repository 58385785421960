import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const TechnologyIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M22.055,4.7A1.945,1.945,0,1,0,20.111,2.75,1.945,1.945,0,0,0,22.055,4.7Z"
				fill={color}
			/>
			<path
				d="M8.666,10.529a1.945,1.945,0,1,0,1.945,1.944A1.944,1.944,0,0,0,8.666,10.529Z"
				fill={color}
			/>
			<path
				d="M20.956,6.483A.749.749,0,1,0,19.681,5.7c-.278.45-.593.922-.938,1.411A.5.5,0,0,1,18,7.187,8.7,8.7,0,0,0,15.775,5.71a.5.5,0,0,1-.086-.857A25.147,25.147,0,0,1,18.4,3.092a.75.75,0,1,0-.72-1.315,31.527,31.527,0,0,0-4.43,3.063.5.5,0,0,1-.356.109q-.364-.031-.737-.031a8.7,8.7,0,0,0-1.237.088.5.5,0,0,1-.385-.105C7.649,2.58,3.278-.426,1.656,1.191.25,2.6,2.259,6.023,4.477,8.952a.5.5,0,0,1,.041.539,8.624,8.624,0,0,0-1.03,4.1,8.9,8.9,0,0,0,.048.913.492.492,0,0,1-.1.355C1.165,17.826-.965,21.375.462,22.8a1.681,1.681,0,0,0,1.244.457c3.072,0,9.173-5.231,12.383-8.44A49.113,49.113,0,0,0,20.956,6.483ZM2.723,2.859a.5.5,0,0,1,.6-.592A16.467,16.467,0,0,1,8.587,5.283a.25.25,0,0,1-.047.426,8.712,8.712,0,0,0-2.75,2,.25.25,0,0,1-.385-.021A14.471,14.471,0,0,1,2.723,2.859ZM5.786,19.883a12.641,12.641,0,0,1-3.661,1.89.5.5,0,0,1-.595-.59,12.868,12.868,0,0,1,2.225-4.228.251.251,0,0,1,.23-.1.247.247,0,0,1,.2.15A8.692,8.692,0,0,0,5.83,19.5a.247.247,0,0,1,.065.2A.25.25,0,0,1,5.786,19.883Zm2.232-1.636a.5.5,0,0,1-.669-.046,6.664,6.664,0,0,1,9.5-9.346.5.5,0,0,1,.033.672c-1.141,1.387-2.452,2.829-3.855,4.231A57.134,57.134,0,0,1,8.018,18.247Z"
				fill={color}
			/>
			<path
				d="M13.18,20.176a6.785,6.785,0,0,1-2.662-.125,1,1,0,0,0-.491,1.939,8.689,8.689,0,0,0,2.129.264,8.874,8.874,0,0,0,1.328-.1,1,1,0,0,0-.3-1.977Z"
				fill={color}
			/>
			<path
				d="M20.688,15.1a8.9,8.9,0,0,0-.062-3.392,1,1,0,1,0-1.957.413,6.714,6.714,0,0,1-1.984,6.356.5.5,0,0,1-.645.029q-.7-.543-1.475-1.193a.756.756,0,0,0-1,.027.731.731,0,0,0,.029,1.1c2.469,2.067,6.182,4.825,8.418,4.825a1.71,1.71,0,0,0,1.26-.465c1.108-1.108.27-3.568-2.491-7.316A.5.5,0,0,1,20.688,15.1Zm1.378,6.523a.5.5,0,0,1-.459.132,11.561,11.561,0,0,1-3.3-1.632.252.252,0,0,1-.04-.388A8.6,8.6,0,0,0,19.9,17.482a.25.25,0,0,1,.431-.027A11.66,11.66,0,0,1,22.2,21.167.5.5,0,0,1,22.066,21.625Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default TechnologyIconBuilder;

