import {
	runAction,
} from './actions';

import {
	location,
} from '~/globals';



export enum ExportType {
	AffectedLinks = 'affectedLinks',
	AffectedPages = 'affectedPages',
	AlertPages = 'alertPages',
	ChangeTracking = 'change_tracking',
	ContentOverview = 'contentOverview',
	HistoricalSingleIssueAffectedPages = 'historicalSingleIssueAffectedPages',
	PageRelations = 'pageRelations',
	SingleIssueAffectedPages = 'singleIssueAffectedPages',
	SitemapsList = 'sitemaps',
}



export function createExportViaApi(
	websiteId: string,
	type: ExportType,
	criteria: any,
) {
	return runAction({
		action: 'CreateExport',
		input: {
			criteria,
			type,
			url: location.href,
			websiteId,
		},
	});
}
