import React from 'react';

import IssueCategoryDetail from '~/components/app/IssueCategoryDetail';
import PlatformScreenIssueDetail from './PlatformScreenIssueDetail';

import useOpenedPlatformIssueCategory from '~/hooks/useOpenedPlatformIssueCategory';
import useWebsiteId from '~/hooks/useWebsiteId';



const PlatformScreenIssuesCategory: React.FC = () => {
	const websiteId = useWebsiteId();

	const platformIssueCategory = useOpenedPlatformIssueCategory(websiteId);

	return (
		<IssueCategoryDetail
			issueCategory={platformIssueCategory}
			renderIssueDetail={({ issue, issueCategory }) => (
				<PlatformScreenIssueDetail
					issue={issue}
					issueCategoryName={issueCategory.name}
					websiteId={websiteId}
				/>
			)}
			websiteId={websiteId}
		/>
	);
};



export default PlatformScreenIssuesCategory;
