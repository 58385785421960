import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import FetchingLocationField from '~/components/app/FetchingLocationField';
import FetchingLocationName from '~/components/names/FetchingLocationName';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import HelpHint from '~/components/patterns/hints/HelpHint';
import NoData from '~/components/app/NoData';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	validateField,
} from '~/components/app/validations';



export enum FetchingLocationFieldRowScope {
	Account,
	Website,
}



const messages = defineMessages({
	label: {
		id: 'ui.fetchingLocationField.label',
	},
});

const hintMessages = defineMessages({
	[FetchingLocationFieldRowScope.Account]: {
		id: 'ui.fetchingLocationField.account.hint',
	},
	[FetchingLocationFieldRowScope.Website]: {
		id: 'ui.fetchingLocationField.website.hint',
	},
});



type Props = {
	fetchingLocation: GraphQL.FetchingLocation | null,
	isEditable?: boolean,
	scope: FetchingLocationFieldRowScope,
};

const FetchingLocationFieldRow: React.FC<Props> = (props) => {
	const {
		fetchingLocation,
		isEditable = true,
		scope,
	} = props;

	const hintMessage = hintMessages[scope];

	return (
		<FormRow
			htmlFor="fetchingLocation"
			label={(
				<AttachedElement
					element={(
						<HelpHint
							message={(
								<FormattedMessage {...hintMessage} />
							)}
						/>
					)}
				>
					<FormattedMessage {...messages.label} />
				</AttachedElement>
			)}
		>
			{isEditable === true && (
				<FieldStatus name="validateFetchingLocation">
					<FetchingLocationField
						name="fetchingLocation"
					/>
				</FieldStatus>
			)}

			{isEditable === false && (
				<StaticText focusTarget="fetchingLocation">
					{fetchingLocation === null ? (
						<NoData />
					) : (
						<FetchingLocationName fetchingLocation={fetchingLocation} />
					)}
				</StaticText>
			)}
		</FormRow>
	);
};



export default FetchingLocationFieldRow;

export const validateFetchingLocation = validateField(
	'fetchingLocation',
	(f) => ([
		f.validateNonEmpty(),
	]),
);
