import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CoreWebVitalsMonitoringIconBuilder: IconBuilder = {
	icon: () => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeWidth="1"
			>
				<g transform="translate(1.000000, 1.000000)">
					<polyline
						points="10.5209132 8.99378049 2.92710916 8.99378049 2.92710916 12.5574071 7.65733207 12.5574071"
						stroke="#5661AA"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="0.869565"
					>
					</polyline>
					<path
						d="M4.47612281,8.99381708 L4.47612281,6.32112758 C4.47612281,5.75901524 4.58217369,5.19690289 4.80489883,4.67722309 C5.01701279,4.15753109 5.33518982,3.68025945 5.73821975,3.2878529 C6.14123748,2.88482297 6.60789794,2.57725712 7.12758994,2.35453198 C7.64728194,2.14241803 8.20939428,2.02574377 8.77150663,2.02574377 C9.33361898,2.02574377 9.89571913,2.13180684 10.4154111,2.35453198 C10.9351031,2.56664594 11.4123626,2.88482297 11.8047447,3.2878529 C12.2078478,3.69087063 12.5153283,4.15753109 12.7380412,4.67722309 C12.950265,5.19690289 13.0668661,5.75901524 13.0668661,6.32112758 L13.0668661,7.81656085"
						stroke="#5661AA"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="0.869565"
					>
					</path>
					<path
						d="M17.4148911,7.07411932 L14.922482,6.54382833 L17.4148911,7.07411932 Z M4.67719869,5.22869986 L12.7695089,5.22869986 L4.67719869,5.22869986 Z M8.79227772,0 L8.79227772,2.03633056 L8.79227772,0 Z M4.47568373,7.49835942 L4.47568373,8.99379269 L4.47568373,7.49835942 Z M7.349876,5.22869986 L7.349876,8.9831815 L7.349876,5.22869986 Z M10.2134693,5.22869986 L10.2134693,8.9831815 L10.2134693,5.22869986 Z M4.25295859,12.6315635 L3.99842428,16.7466303 L4.25295859,12.6315635 Z M0,4.13629652 L2.49237983,4.66658751 L0,4.13629652 Z M0,7.07411932 L2.49237983,6.54382833 L0,7.07411932 Z M17.4148911,4.13629652 L14.922482,4.66658751 L17.4148911,4.13629652 Z"
						stroke="#5661AA"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="0.869565"
					>
					</path>
					<path
						d="M15.7495447,22.4312989 C12.1858937,22.4312989 9.29050341,19.5359087 9.29050341,15.9723796 C9.29050341,12.4088505 12.1858937,9.51338711 15.7495447,9.51338711 C19.3130738,9.51338711 22.208464,12.4088505 22.208464,15.9723796 C22.208464,19.5465199 19.323685,22.4312989 15.7495447,22.4312989 Z"
						stroke="#498AC9"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="0.869565"
					>
					</path>
					<path
						d="M19.2817281,12.4511733 L16.3438931,14.6572024 C16.6832071,14.7739254 16.958976,15.0284719 17.0756991,15.3890083 L19.2817281,12.4511733 Z"
						stroke="#498AC9"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="0.869565"
					>
					</path>
					<path
						d="M15.7494227,20.0874465 C15.4207199,20.0874465 15.1661734,19.8222888 15.1661734,19.5040751 C15.1661734,19.1752503 15.4313311,18.9208258 15.7494227,18.9208258 C16.0782475,18.9208258 16.332794,19.1858615 16.332794,19.5040751 C16.3434052,19.8222888 16.0782475,20.0874465 15.7494227,20.0874465 Z"
						fill="#498AC9"
						fillRule="nonzero"
					>
					</path>
					<path
						d="M13.9893075,20.0874465 C13.6606046,20.0874465 13.4060581,19.8222888 13.4060581,19.5040751 C13.4060581,19.1752503 13.6712158,18.9208258 13.9893075,18.9208258 C14.3181323,18.9208258 14.5726788,19.1858615 14.5726788,19.5040751 C14.5726788,19.8222888 14.3181323,20.0874465 13.9893075,20.0874465 Z"
						fill="#498AC9"
						fillRule="nonzero"
					>
					</path>
					<path
						d="M17.5102698,20.0874465 C17.181445,20.0874465 16.9268985,19.8222888 16.9268985,19.5040751 C16.9268985,19.1752503 17.1920562,18.9208258 17.5102698,18.9208258 C17.8389727,18.9208258 18.0935192,19.1858615 18.0935192,19.5040751 C18.1041304,19.8222888 17.8389727,20.0874465 17.5102698,20.0874465 Z"
						fill="#498AC9"
						fillRule="nonzero"
					>
					</path>
					<g
						stroke="#498AC9"
						transform="translate(9.290503, 9.513387)"
					>
						<line
							x1="6.45928523"
							x2="6.45928523"
							y1="1.76057876"
							y2="2.16658084e-15"
						>
						</line>
						<line
							x1="2.93820089"
							x2="1.88818132"
							y1="2.93790817"
							y2="1.90917196"
						>
						</line>
						<line
							x1="9.99061485"
							x2="11.0299013"
							y1="2.93790817"
							y2="1.8878764"
						>
						</line>
						<line
							x1="11.1576014"
							x2="12.9180826"
							y1="6.45911447"
							y2="6.45911447"
						>
						</line>
						<line
							x1="0"
							x2="1.76057876"
							y1="6.45911447"
							y2="6.45911447"
						>
						</line>
						<path d="M6.83055478,7.5726792 C6.21534991,7.77416977 5.54721108,7.43473378 5.34572051,6.81965087 C5.14422993,6.20456797 5.48354396,5.53630717 6.09874883,5.3348166 C6.71383173,5.13332603 7.38197057,5.47276202 7.58358311,6.08784492 C7.77446249,6.71353901 7.44563768,7.37118863 6.83055478,7.5726792 Z"></path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CoreWebVitalsMonitoringIconBuilder;

