import React from 'react';



const ManuallyTrackedLoadingContext = React.createContext<Context | null>(null);



export type Context = {
	isActive: boolean,
	finish: () => void,
};



type Props = {
	children?: React.ReactNode,
	defaultIsLoading: boolean,
};

const ManuallyTrackedLoading: React.FC<Props> = (props) => {
	const {
		children,
		defaultIsLoading,
	} = props;

	const [isLoading, setIsLoading] = React.useState<boolean>(defaultIsLoading);

	const context = React.useMemo<Context>(
		() => ({
			isActive: isLoading,
			finish: () => setIsLoading(false),
		}),
		[
			isLoading,
			setIsLoading,
		],
	);

	return (
		<ManuallyTrackedLoadingContext.Provider value={context}>
			{children}
		</ManuallyTrackedLoadingContext.Provider>
	);
};



export default ManuallyTrackedLoading;

export {
	ManuallyTrackedLoadingContext,
};
