import type CK from '~/types/contentking';

import {
	useAccountCountryQuery,
} from './useAccountCountry.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountCountry(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountCountryQuery,
		accountId,
	);

	return data?.account?.country ?? null;
}



export default useAccountCountry;
