/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserUniqueIdQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserUniqueIdQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly uniqueId: CK.UserId } | null };


export const UserUniqueIdDocument = gql`
    query UserUniqueId($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    legacyId
    uniqueId
  }
}
    `;

/**
 * __useUserUniqueIdQuery__
 *
 * To run a query within a React component, call `useUserUniqueIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserUniqueIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUniqueIdQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserUniqueIdQuery(baseOptions: Apollo.QueryHookOptions<UserUniqueIdQuery, UserUniqueIdQueryVariables> & ({ variables: UserUniqueIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserUniqueIdQuery, UserUniqueIdQueryVariables>(UserUniqueIdDocument, options);
      }
export function useUserUniqueIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserUniqueIdQuery, UserUniqueIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserUniqueIdQuery, UserUniqueIdQueryVariables>(UserUniqueIdDocument, options);
        }
export function useUserUniqueIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserUniqueIdQuery, UserUniqueIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserUniqueIdQuery, UserUniqueIdQueryVariables>(UserUniqueIdDocument, options);
        }
export type UserUniqueIdQueryHookResult = ReturnType<typeof useUserUniqueIdQuery>;
export type UserUniqueIdLazyQueryHookResult = ReturnType<typeof useUserUniqueIdLazyQuery>;
export type UserUniqueIdSuspenseQueryHookResult = ReturnType<typeof useUserUniqueIdSuspenseQuery>;
export type UserUniqueIdQueryResult = Apollo.QueryResult<UserUniqueIdQuery, UserUniqueIdQueryVariables>;