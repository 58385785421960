import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import FieldStatus, {
	FieldStatusErrorMessageAlignment,
} from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StripeField, {
	StripeFieldType,
	validateStripeField,
} from '~/components/app/Stripe/StripeField';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';



const messages = defineMessages({
	cardholderLabel: {
		id: 'ui.creditCard.cardHolder',
	},
	cardholderPlaceholder: {
		id: 'ui.creditCard.firstNamePlaceholder',
	},
	cardNumberLabel: {
		id: 'ui.creditCard.cardNumber',
	},
	cvcPlaceholder: {
		id: 'ui.creditCard.cvc',
	},
	expiryAndCvcLabel: {
		id: 'ui.creditCard.expiryAndCvc',
	},
});



const CreditCardFields: React.FC = () => {
	const formContext = useFormContext();
	const intl = useIntl();

	return (
		<FormRows>
			<FormRow
				htmlFor="cardHolder"
				label={(
					<FormattedMessage {...messages.cardholderLabel} />
				)}
			>
				<FieldStatus name="validateCardHolder">
					<TextField
						disabled={formContext.isSubmitting}
						name="cardHolder"
						placeholder={intl.formatMessage(messages.cardholderPlaceholder)}
						trimValue={true}
						width={280}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.cardNumberLabel} />
				)}
			>
				<FieldStatus name="validateCardNumber">
					<StripeField
						name="cardNumber"
						type={StripeFieldType.CardNumber}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.expiryAndCvcLabel} />
				)}
			>
				<FieldStatus
					errorMessageAlignment={FieldStatusErrorMessageAlignment.Right}
					errorMessageWidth={220}
					name="validateCardExpiration"
				>
					<StripeField
						name="cardExpiration"
						type={StripeFieldType.CardExpiry}
					/>
				</FieldStatus>

				<FieldStatus name="validateCardCvc">
					<StripeField
						name="cardCvc"
						type={StripeFieldType.CardCvc}
					/>
				</FieldStatus>
			</FormRow>
		</FormRows>
	);
};



export default CreditCardFields;

export const validateCardCvc = validateStripeField('cardCvc');

export const validateCardExpiration = validateStripeField('cardExpiration');

export const validateCardHolder = validateField(
	'cardHolder',
	(f) => [
		f.validateNonEmpty(),
	],
);

export const validateCardNumber = validateStripeField('cardNumber');
