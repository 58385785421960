import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import useGetIssueCategory from '~/hooks/useGetIssueCategory';
import usePageIssueCategories from '~/hooks/usePageIssueCategories';

import {
	pageDetailIssuesCategoryTypeSelector,
} from '~/state/ui/content/selectors';



function useOpenedPageIssueCategory(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const openedIssueCategoryName = useSelector(pageDetailIssuesCategoryTypeSelector);

	const pageIssueCategories = usePageIssueCategories(
		legacyUrlId,
		websiteId,
	);

	return useGetIssueCategory(
		pageIssueCategories,
		openedIssueCategoryName,
	);
}



export default useOpenedPageIssueCategory;
