import React from 'react';

import type CK from '~/types/contentking';

import AdminAccountDestructConfirmationModal from '~/components/app/AdminAccountDestructConfirmationModal';

import {
	useMarkAccountAsOutOfBandMutation,
} from '~/components/app/AdminMarkAccountAsOutOfBand.gql';

import useAccountName from '~/hooks/useAccountName';



type Props = {
	accountId: CK.AccountId | null,
};

const AdminMarkAccountAsOutOfBandModal: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountName = useAccountName(accountId);

	const [markAccountAsOutOfBand] = useMarkAccountAsOutOfBandMutation();

	const handleSubmit = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			await markAccountAsOutOfBand({
				variables: {
					accountId,
				},
			});
		},
		[
			accountId,
			markAccountAsOutOfBand,
		],
	);

	return (
		<AdminAccountDestructConfirmationModal
			accountId={accountId}
			confirmButton="Mark account as out of band"
			description={(
				<p>
					Mark account <strong>{accountName} ({accountId})</strong> as out-of-band.
				</p>
			)}
			globalFormErrors={{
				accountIsNotCustomer: 'Account is not a customer.',
				accountIsAlreadyOutOfBand: 'Account is already out-of-band',
			}}
			onConfirmation={handleSubmit}
			titleAction="Mark"
			titleObject="Account as out of band"
		/>
	);
};



export default AdminMarkAccountAsOutOfBandModal;
