import type React from 'react';

import {
	useIsWebsiteCrawledQuery,
} from './IsWebsiteCrawledContext.gql';

import usePollInterval from '~/hooks/usePollInterval';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	children: RenderProp<{
		isCrawled: boolean | null,
	}>,
};

const IsWebsiteCrawledContext: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const websiteId = useWebsiteId();

	const {
		data: queryData,
	} = useIsWebsiteCrawledQuery({
		pollInterval: usePollInterval(30000),
		variables: {
			websiteId,
		},
	});

	return renderProp(children, {
		isCrawled: (queryData && queryData.website !== null && queryData.website.pageTotal !== null)
			? queryData.website.pageTotal > 0
			: null,
	});
};



export default IsWebsiteCrawledContext;
