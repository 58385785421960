import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MaleIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M12,8.5A5.506,5.506,0,0,0,6.5,14v3.5A.5.5,0,0,0,7,18H8.81a.25.25,0,0,1,.249.229L9.5,23.542A.5.5,0,0,0,10,24h4a.5.5,0,0,0,.5-.458l.443-5.313A.25.25,0,0,1,15.19,18H17a.5.5,0,0,0,.5-.5V14A5.506,5.506,0,0,0,12,8.5Z"
				fill={color}
			/>
			<circle
				cx="12"
				cy="3.5"
				fill={color}
				r="3.5"
			/>
			<path
				d="M12,8.5A5.506,5.506,0,0,0,6.5,14v3.5A.5.5,0,0,0,7,18H8.81a.25.25,0,0,1,.249.229L9.5,23.542A.5.5,0,0,0,10,24h4a.5.5,0,0,0,.5-.458l.443-5.313A.25.25,0,0,1,15.19,18H17a.5.5,0,0,0,.5-.5V14A5.506,5.506,0,0,0,12,8.5Z"
				fill={color}
			/>
			<circle
				cx="12"
				cy="3.5"
				fill={color}
				r="3.5"
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MaleIconBuilder;
