import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const WebVitalsIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth={60 / size}
			>
				<g
					id="icon_issue_category_web_vitals_origin_summary"
					transform="translate(9.000000, 9.000000)"
				>
					<path
						d="M21,42 C9.4023,42 0,32.5977 0,21 C0,9.4023 9.4023,0 21,0 C32.5977,0 42,9.4023 42,21 C42,32.5977 32.5977,42 21,42 Z"
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M29.5909,12.4092 L22.9091,17.6592 C23.9961,18.0263 23.9456,17.9211 24.3409,19.091 L29.5909,12.4092 Z"
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2.46154}
					>
					</path>
					<path
						d="M21,33.409 C20.2096,33.409 19.5682,32.7676 19.5682,31.9772 C19.5682,31.1869 20.2096,30.5454 21,30.5454 C21.7904,30.5454 22.4318,31.1869 22.4318,31.9772 C22.4318,32.7676 21.7904,33.409 21,33.409 Z"
						fill={color}
						id="Path"
					>
					</path>
					<path
						d="M15.2727,33.409 C14.4823,33.409 13.8409,32.7676 13.8409,31.9772 C13.8409,31.1869 14.4823,30.5454 15.2727,30.5454 C16.0631,30.5454 16.7045,31.1869 16.7045,31.9772 C16.7045,32.7676 16.0631,33.409 15.2727,33.409 Z"
						fill={color}
						id="Path"
					>
					</path>
					<path
						d="M26.7272,33.409 C25.9369,33.409 25.2954,32.7676 25.2954,31.9772 C25.2954,31.1869 25.9369,30.5454 26.7272,30.5454 C27.5176,30.5454 28.159,31.1869 28.159,31.9772 C28.159,32.7676 27.5176,33.409 26.7272,33.409 Z"
						fill={color}
						id="Path"
					>
					</path>
					<line
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
						x1="21"
						x2="21"
						y1="5.72726"
						y2="0"
					>
					</line>
					<line
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
						x1="9.5456"
						x2="6.13184"
						y1="9.5451"
						y2="6.19678"
					>
					</line>
					<line
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
						x1="32.4545"
						x2="35.8431"
						y1="9.5455"
						y2="6.13184"
					>
					</line>
					<line
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
						x1="36.2727"
						x2="42"
						y1="21"
						y2="21"
					>
					</line>
					<line
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
						x1="0"
						x2="5.72727"
						y1="21"
						y2="21"
					>
					</line>
					<path
						d="M22.1853,24.6283 C20.1812,25.2829 18.0263,24.1893 17.3716,22.1851 C16.7169,20.181 17.8106,18.0261 19.8147,17.3714 C21.8188,16.7167 23.9737,17.8104 24.6284,19.8145 C25.2831,21.8187 24.1894,23.9736 22.1853,24.6283 L22.1853,24.6283 Z"
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2.46154}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default WebVitalsIconBuilder;

