import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PlatformIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m26 0v26h-26v-26z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				>
					<path d="m7.7865125 10.96875-3.7236875 2.03125 8.9375 4.875 8.9375-4.875-3.7245-2.03125" />
					<path d="m7.7865125 15.03125-3.7236875 2.03125 8.9375 4.875 8.9375-4.875-3.7245-2.03125" />
					<path d="m21.9375 8.9375-8.9375 4.875-8.9375-4.875 8.9375-4.875z" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default PlatformIconBuilder;

