import {
	differenceInDays,
	parseISO,
} from 'date-fns';
import React from 'react';
import {
	FormattedDate,
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import ManagementTable from '~/components/patterns/tables/ManagementTable';

import {
	useInvoicesOverviewQuery,
} from './InvoicesOverview.gql';



const messages = defineMessages({
	date: {
		id: 'ui.invoices.date',
	},
	daysPastDueDate: {
		id: 'ui.invoices.daysPastDueDate',
	},
	dueDate: {
		id: 'ui.invoices.dueDate',
	},
	orderId: {
		id: 'ui.invoices.orderId',
	},
	noTrialExpirationTablePlaceholder: {
		id: 'ui.invoices.tablePlaceholder',
	},
	paid: {
		id: 'ui.invoices.paid',
	},
	pending: {
		id: 'ui.invoices.pending',
	},
	price: {
		id: 'ui.invoices.price',
	},
	status: {
		id: 'ui.invoices.status',
	},
	title: {
		id: 'ui.invoices.title',
	},
});



type Props = {
	accountId: CK.AccountId | null,
};

const InvoicesOverview: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const { data } = useInvoicesOverviewQuery({
		pollInterval: 60_000,
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const invoices = data?.account?.invoices ?? null;
	const isLoading = invoices === null;

	let blankSlate: React.ReactNode | null = null;

	if (!isLoading && invoices.length === 0) {
		blankSlate = (
			<BlankSlate>
				<FormattedMessage {...messages.noTrialExpirationTablePlaceholder} />
			</BlankSlate>
		);
	}

	return (
		<ManagementTable
			blankSlate={blankSlate}
			columnLabels={(
				<>
					<FormattedMessage {...messages.orderId} />
					<FormattedMessage {...messages.date} />
					<FormattedMessage {...messages.status} />
					<FormattedMessage {...messages.price} />
				</>
			)}
			columnWidths={[170, '30%', '35%', 130]}
			height={[0, 500]}
			isLoading={isLoading}
			rows={invoices ?? []}
			title="Invoice history"
			width={[650, 900]}
		>
			{({ row: invoice }) => (
				<>
					<ExternalLink
						href={invoice.downloadUrl}
						target="_self"
					>
						{invoice.number}
					</ExternalLink>

					<FormattedDate
						day="numeric"
						month="short"
						value={invoice.issuedAt}
						year="numeric"
					/>

					<>
						{invoice.status === GraphQL.InvoiceStatus.Paid && (
							<FormattedMessage {...messages.paid} />
						)}

						{invoice.status === GraphQL.InvoiceStatus.NotPaid && invoice.dueDate !== null && (
							<FormattedMessage
								{...messages.dueDate}
								values={{
									date__dueDate: parseISO(invoice.dueDate),
								}}
							/>
						)}

						{invoice.status === GraphQL.InvoiceStatus.PastDue && invoice.dueDate !== null && (
							<span style={{ color: 'red' }}>
								<FormattedMessage
									{...messages.daysPastDueDate}
									values={{
										number__daysPastDueDate: differenceInDays(
											new Date(),
											parseISO(invoice.dueDate),
										),
									}}
								/>
							</span>
						)}

						{invoice.status === GraphQL.InvoiceStatus.Pending && (
							<FormattedMessage {...messages.pending} />
						)}
					</>

					<FormattedNumber
						currency={invoice.currency}
						style="currency"
						value={invoice.totalAmount / 100}
					/>
				</>
			)}
		</ManagementTable>
	);
};



export default InvoicesOverview;
