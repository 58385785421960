import {
	parseISO,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';

import {
	usePageTimeDiscoveredAtQuery,
} from './usePageTimeDiscoveredAt.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageTimeDiscoveredAt(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
): Date | null {
	const { data } = usePageDetailPropertiesQuery(
		usePageTimeDiscoveredAtQuery,
		legacyUrlId,
		websiteId,
	);

	const timeDiscoveredAt = data?.lookupPageByLegacyId?.timeDiscoveredAt ?? null;

	return React.useMemo(
		() => timeDiscoveredAt !== null
			? parseISO(timeDiscoveredAt)
			: null,
		[
			timeDiscoveredAt,
		],
	);
}



export default usePageTimeDiscoveredAt;
