import classNames from 'classnames';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import useScrollContainer from '~/hooks/useScrollContainer';
import useViewportOrientation from '~/hooks/useViewportOrientation';



export const CHART_HEIGHT = 175;



type Props = {
	chart: React.ReactElement,
	height?: number,
	name?: string,
	overlay?: React.ReactNode,
	padded?: boolean,
	type: 'column-chart' | 'donut-chart' | 'interval-overview-chart' | 'simple-line-chart' | 'date-time-chart',
};

type Size = {
	height: number,
	width: number,
};



const recalculateChartSizes = (
	containerRef: React.RefObject<HTMLDivElement>,
	height: number,
	setChartSize: (size: Size) => void,
) => {
	const container = containerRef.current;

	if (container === null) {
		return;
	}

	let chartHeight = Math.max(height, container.offsetHeight);

	const computedStyles = window.getComputedStyle(container);

	if (computedStyles.paddingTop) {
		// @ts-ignore
		chartHeight -= computedStyles.paddingTop.match(/\d+/)[0];
	}

	if (computedStyles.paddingBottom) {
		// @ts-ignore
		chartHeight -= computedStyles.paddingBottom.match(/\d+/)[0];
	}

	setChartSize({
		height: chartHeight,
		width: container.offsetWidth,
	});
};



const ChartContainer: React.FC<Props> = (props) => {
	const {
		chart,
		height = CHART_HEIGHT,
		name = undefined,
		overlay,
		padded = false,
		type,
	} = props;

	const containerRef = React.useRef<HTMLDivElement>(null);
	const [chartSize, setChartSize] = React.useState<Size | null>(null);
	const [isVisible, setIsVisible] = React.useState<boolean>(false);
	const scrollContainer = useScrollContainer();
	const viewportOrientation = useViewportOrientation();

	const handleVisibilitySensorInput = React.useCallback(
		(_isVisible) => setIsVisible(_isVisible),
		[],
	);

	React.useEffect(
		() => {
			recalculateChartSizes(
				containerRef,
				height,
				setChartSize,
			);
		},
		[
			height,
			setChartSize,
			viewportOrientation,
		],
	);

	const nameClassPrefix = type === 'interval-overview-chart'
		? 'js-interval-overview-chart'
		: type;

	const chartClasses = classNames({
		'chart-container': true,
		[type]: true,
		[nameClassPrefix + '--' + name]: name !== undefined,
		[type + '--padded']: padded,
	});

	return (
		<div
			className={chartClasses}
			ref={containerRef}
			style={{
				minHeight: height,
			}}
		>
			{overlay && (
				<div className="chart-container__overlay">
					{overlay}
				</div>
			)}

			{!isVisible && scrollContainer !== null && (
				<VisibilitySensor
					containment={scrollContainer}
					intervalCheck={true}
					offset={{
						bottom: -500,
						top: -500,
					}}
					onChange={handleVisibilitySensorInput}
					partialVisibility={true}
					scrollCheck={true}
				>
					<span>&nbsp;</span>
				</VisibilitySensor>
			)}

			{isVisible && chartSize !== null && React.cloneElement(
				chart,
				{
					chartHeight: chartSize.height,
					chartWidth: chartSize.width,
				},
			)}
		</div>
	);
};



export default ChartContainer;
