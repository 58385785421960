import React from 'react';

import IssueCategoryIcon, {
	IssueCategoryIconType,
} from '~/components/patterns/icons/IssueCategoryIcon';

import {
	IssueCategoryName,
} from '~/model/issuesNew';

import matchAndReturn from '~/utilities/matchAndReturn';



export enum IssueCategoryNameIconSize {
	Default = 'default',
	Small = 'small',
}



type Props = {
	issueCategoryName: IssueCategoryName,
	size?: IssueCategoryNameIconSize,
};

const IssueCategoryNameIcon: React.FC<Props> = (props) => {
	const {
		issueCategoryName,
		size = IssueCategoryNameIconSize.Default,
	} = props;

	return (
		<IssueCategoryIcon
			size={matchAndReturn(size, {
				[IssueCategoryNameIconSize.Small]: 34,
				[IssueCategoryNameIconSize.Default]: undefined,
			})}
			type={matchAndReturn(issueCategoryName, {
				[IssueCategoryName.Analytics]: IssueCategoryIconType.Analytics,
				[IssueCategoryName.CanonicalLink]: IssueCategoryIconType.CanonicalLink,
				[IssueCategoryName.Domain]: IssueCategoryIconType.Domain,
				[IssueCategoryName.H1]: IssueCategoryIconType.ContentHeadings,
				[IssueCategoryName.Hreflang]: IssueCategoryIconType.Hreflang,
				[IssueCategoryName.Images]: IssueCategoryIconType.Images,
				[IssueCategoryName.Lighthouse]: IssueCategoryIconType.Lighthouse,
				[IssueCategoryName.Links]: IssueCategoryIconType.Links,
				[IssueCategoryName.MetaDescription]: IssueCategoryIconType.MetaDescription,
				[IssueCategoryName.OpenGraph]: IssueCategoryIconType.OpenGraph,
				[IssueCategoryName.RobotDirectives]: IssueCategoryIconType.RobotDirectives,
				[IssueCategoryName.RobotsTxt]: IssueCategoryIconType.RobotsTxt,
				[IssueCategoryName.SchemaOrg]: IssueCategoryIconType.SchemaOrg,
				[IssueCategoryName.Title]: IssueCategoryIconType.Title,
				[IssueCategoryName.TwitterCards]: IssueCategoryIconType.TwitterCards,
				[IssueCategoryName.WebVitalsOriginSummary]: IssueCategoryIconType.WebVitals,
				[IssueCategoryName.XmlSitemap]: IssueCategoryIconType.XmlSitemap,
			})}
		/>
	);
};



export default IssueCategoryNameIcon;
