import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import Importer from './ExclusionsModal/Importer';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import Manager from './ExclusionsModal/Manager';
import MultiStepModal from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';

import useAccountId from '~/hooks/useAccountId';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	loadRobotsRules,
} from '~/actions/websites';

import {
	robotsRulesSelector,
	selectedWebsiteDetailSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	importStep: {
		breadcrumbs: {
			id: 'ui.modal.urlExclusionList.importStep.breadcrumbs',
		},
		sidebar: {
			id: 'ui.modal.urlExclusionList.importStep.sidebar',
		},
		title: {
			id: 'ui.websites.exclusionsModal.importer.title',
		},
	},
	manageStep: {
		breadcrumbs: {
			id: 'ui.modal.urlExclusionList.manageStep.breadcrumbs',
		},
		sidebar: {
			id: 'ui.modal.urlExclusionList.manageStep.sidebar',
		},
		title: {
			id: 'ui.websites.exclusionsModal.manager.title',
		},
	},
});



const ExclusionsModal = (props) => {
	const {
		closeCallback,
	} = props;

	const accountId = useAccountId();
	const dispatch = useDispatch();
	const robotsRules = useSelector(robotsRulesSelector);
	const website = useSelector(selectedWebsiteDetailSelector);
	const websiteId = useWebsiteId();

	const multiStepModalRef = React.useRef();

	const [selectedRules, setSelectedRules] = React.useState(null);

	React.useEffect(
		() => {
			dispatch(
				loadRobotsRules(
					accountId,
					websiteId,
				),
			);
		},
		[
			accountId,
			dispatch,
			websiteId,
		],
	);

	const goToStepWithRules = React.useCallback(
		(selectedRules) => {
			if (selectedRules && selectedRules.length > 0) {
				setSelectedRules(selectedRules);
			}

			multiStepModalRef.current?.goToNextStep();
		},
		[
			multiStepModalRef,
			setSelectedRules,
		],
	);

	const isLoaded = (
		website
		&& robotsRules
	);

	const allowImport = (
		isLoaded
		&& website.get('virtualRobotsRulesCount') === 0
		&& robotsRules.size > 0
	);

	return (
		<MultiStepModal
			isContentDisabled={!isLoaded}
			key={allowImport}
			preloader={!isLoaded && (
				<LoadingDots isStretched={true} />
			)}
			ref={multiStepModalRef}
		>
			{allowImport && (
				<MultiStepModalStep
					breadcrumb={(
						<FormattedMessage {...messages.importStep.breadcrumbs} />
					)}
					name="import-step"
					sidebar={(
						<RichText>
							<Copy
								{...messages.importStep.sidebar}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/support/removing-urls-from-monitoring/#url-exclusion-list'),
								}}
							/>
						</RichText>
					)}
					title={(
						<FormattedMessage {...messages.importStep.title} />
					)}
				>
					<Importer
						dispatch={dispatch}
						onSkipCallback={() => goToStepWithRules([])}
						onSubmitCallback={goToStepWithRules}
						robotsRules={robotsRules}
						website={website}
					/>
				</MultiStepModalStep>
			)}

			<MultiStepModalStep
				breadcrumb={(
					<FormattedMessage {...messages.manageStep.breadcrumbs} />
				)}
				name="manager-step"
				sidebar={(
					<RichText>
						<Copy
							{...messages.manageStep.sidebar}
							values={{
								linkArticle: linkExternal('https://www.contentkingapp.com/support/removing-urls-from-monitoring/#url-exclusion-list'),
							}}
						/>
					</RichText>
				)}
				title={(
					<FormattedMessage {...messages.manageStep.title} />
				)}
			>
				<Manager
					closeModal={closeCallback}
					rulesFromImporter={allowImport ? selectedRules : undefined}
					website={website}
				/>
			</MultiStepModalStep>
		</MultiStepModal>

	);
};



export default ExclusionsModal;
