import memoize from 'memoizee';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	type AllInvitationsQuery,
	useAllInvitationsQuery,
} from './useAllInvitations.gql';

import useHomeAccountIds from '~/hooks/useHomeAccountIds';



type Invitation = {
	displayName: string,
	email: string,
	hasLimitedWebsitesAccess: boolean,
	id: GraphQL.Invitation['id'],
	role: GraphQL.UserRole,
	websites: ReadonlyArray<{
		id: CK.WebsiteId,
	}>,
};

const format = memoize(
	(
		data: AllInvitationsQuery | undefined,
		homeAccountIds: ReadonlyArray<CK.AccountId> | null,
	) => {
		const accounts = data?.authenticatedSession?.accounts ?? null;

		if (accounts === null) {
			return null;
		}

		const all: Array<Invitation> = [];

		const perAccount: Record<CK.AccountId, ReadonlyArray<Invitation>> = {};

		accounts.forEach((account) => {
			account.invitations.forEach((invitation) => {
				all.push(invitation);
			});

			perAccount[account.id] = [...account.invitations];
		});

		return {
			listAll: () => all,
			listByAccount: (accountId: CK.AccountId) => perAccount[accountId] ?? [],
			listByWebsiteAccount: (accountId: CK.AccountId) => {
				let result = perAccount[accountId] ?? [];

				if (homeAccountIds !== null && homeAccountIds.includes(accountId) === false) {
					homeAccountIds.forEach((homeAccountId) => {
						result = [
							...result,
							...perAccount[homeAccountId] ?? [],
						];
					});
				}

				return result;
			},
		};
	},
);



function useAllInvitations() {
	const homeAccountIds = useHomeAccountIds();

	const { data } = useAllInvitationsQuery();

	return format(data, homeAccountIds);
}



export default useAllInvitations;
