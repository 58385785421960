import type Immutable from 'immutable';
import React from 'react';
import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useWebsiteScopeIssues from '~/hooks/useWebsiteScopeIssues';

import {
	isRecognizedIssueCategory,
} from '~/model/issuesNew';

import {
	issuesFilterSelector,
} from '~/state/ui/selectors';



type FilterableIssueCategory = {
	isIgnored: boolean,
	state: GraphQL.IssueCategoryState,
};

const filters = {
	state: (criteria) => (issueCategory: FilterableIssueCategory) => (
		(criteria.includes('ignored') && issueCategory.isIgnored)
		|| criteria.includes(issueCategory.state)
	),
};



function useFilteredWebsiteScopeIssues(websiteId: CK.WebsiteId | null) {
	const issuesFilter = useSelector(issuesFilterSelector) as Immutable.Map<string, any>;
	const websiteScopeIssues = useWebsiteScopeIssues(websiteId);

	return React.useMemo(
		() => {
			if (websiteScopeIssues === null) {
				return null;
			}

			const scopeIssues = issuesFilter.get('segment') === 'all'
				? websiteScopeIssues.find((scope) => scope.name === 'website')
				: websiteScopeIssues.find((scope) => scope.name === 'segment/' + issuesFilter.get('segment'));

			let issueCategories = [...scopeIssues?.issueCategories ?? []].filter(isRecognizedIssueCategory);

			const filterDefinition = Object.entries(issuesFilter.toJS())
				.filter(([key]) => filters[key] !== undefined)
				.map(([key, criteria]) => filters[key](criteria));

			if (filterDefinition.length > 0) {
				issueCategories = issueCategories.filter(
					(issueCategory) => filterDefinition.every(
						(test) => test(issueCategory),
					),
				);
			}

			issueCategories.sort((issueCategoryA, issueCategoryB) => {
				return issueCategoryB.pointsToGain - issueCategoryA.pointsToGain;
			});

			return issueCategories;
		},
		[
			issuesFilter,
			websiteScopeIssues,
		],
	);
}



export default useFilteredWebsiteScopeIssues;
