import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AddEnrichmentFieldButton from '~/components/app/AddEnrichmentFieldButton';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import CodeValue from '~/components/patterns/values/CodeValue';
import Copy from '~/components/logic/Copy';
import DeleteEnrichmentFieldModal from '~/components/app/DeleteEnrichmentFieldModal';
import ElementTypeName from '~/components/logic/customElements/ElementTypeName';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';
import ManagementTable from '~/components/patterns/tables/ManagementTable';
import TableActionButton, {
	TableActionButtonIconType,
} from '~/components/patterns/tables/datatables/buttons/TableActionButton';
import WithPermission from '~/components/logic/access/WithPermission';

import useAlertDefinitionDependencies from '~/hooks/useAlertDefinitionDependencies';
import useModals from '~/hooks/useModals';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ObjectType,
} from '~/model/permissions';

import sortArrayByProperty from '~/utilities/sortArrayByProperty';



const messages = defineMessages({
	blankSlate: {
		id: 'ui.enrichmentFieldsOverview.blankSlate',
	},
	cannotDeleteTooltip: {
		id: 'ui.enrichmentFieldsOverview.cannotDeleteTooltip',
	},
	dataType: {
		id: 'ui.enrichmentFieldsOverview.dataTypeColumn',
	},
	id: {
		id: 'ui.enrichmentFieldsOverview.idColumn',
	},
	name: {
		id: 'ui.enrichmentFieldsOverview.nameColumn',
	},
	title: {
		id: 'ui.enrichmentFieldsOverview.title',
	},
});



const EnrichmentFieldsOverview: React.FC = () => {
	const modals = useModals();
	const viewportType = useViewportType();

	const websiteId = useWebsiteId();

	const alertDefinitionDependencies = useAlertDefinitionDependencies(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	const isManipulationAllowed = !viewportType.isSmall && !viewportType.isMedium;

	let blankSlate: React.ReactNode | null = null;
	if (enrichmentFieldDefinitions.isLoaded && enrichmentFieldDefinitions.count === 0) {
		blankSlate = (
			<InterfaceMessage>
				<FormattedMessage {...messages.blankSlate} />
			</InterfaceMessage>
		);
	}

	function openDeleteEnrichmentFieldConfirmationModal(enrichmentField) {
		modals.openModal(
			() => (
				<DeleteEnrichmentFieldModal
					enrichmentFieldId={enrichmentField.id}
					websiteId={websiteId}
				/>
			),
		);
	}

	const rows = React.useMemo(
		() => sortArrayByProperty(
			enrichmentFieldDefinitions.listAll(),
			(enrichmentFieldDefinition) => enrichmentFieldDefinition.label,
		),
		[
			enrichmentFieldDefinitions,
		],
	);

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed, message: permissionMessage }) => (
				<ManagementTable
					blankSlate={blankSlate}
					buttons={isManipulationAllowed && (
						<AddEnrichmentFieldButton />
					)}
					columnLabels={(
						<>
							<FormattedMessage {...messages.id} />
							<FormattedMessage {...messages.name} />
							<FormattedMessage {...messages.dataType} />
						</>
					)}
					columnWidths={(
						isManipulationAllowed
							? [120, 210, 170, 50]
							: [120, 210, 50]
					)}
					height={[0, 500]}
					isLoading={enrichmentFieldDefinitions.isLoaded === false}
					rows={rows}
					title={(
						<FormattedMessage {...messages.title} />
					)}
					width={[500, 700]}
				>
					{({ row: enrichmentFieldDefinition }) => {
						const dependentAlertDefinitions = alertDefinitionDependencies.getAlertDefinitionsDependingOnField(
							enrichmentFieldDefinition.column,
						);

						const canBeDeleted = dependentAlertDefinitions.length === 0;

						return (
							<>
								<CodeValue>{enrichmentFieldDefinition.id}</CodeValue>

								{enrichmentFieldDefinition.label}

								<ElementTypeName elementType={enrichmentFieldDefinition.dataType} />

								{isManipulationAllowed && (
									<ButtonsGroup>
										<TableActionButton
											disabled={isAllowed.yes === false || canBeDeleted === false}
											iconType={TableActionButtonIconType.Delete}
											onClick={() => {
												openDeleteEnrichmentFieldConfirmationModal(enrichmentFieldDefinition);
											}}
											tooltip={(
												isAllowed.yes === false
													? permissionMessage
													: canBeDeleted === false
														? <Copy {...messages.cannotDeleteTooltip} />
														: 'Delete enrichment field'
											)}
										/>
									</ButtonsGroup>
								)}
							</>
						);
					}}
				</ManagementTable>
			)}
		</WithPermission>
	);
};



export default EnrichmentFieldsOverview;
