import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AbstractSelectField, {
	type AbstractSelectFieldRef,
	AbstractSelectFieldSize,
	AbstractSelectFieldStyle,
} from '~/components/patterns/forms/fields/AbstractSelectField';
import AccountDisplayName from '~/components/app/AccountDisplayName';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import SimpleNavigation from '~/components/patterns/navigations/simpleNavigation/SimpleNavigation';
import SimpleNavigationButton from '~/components/patterns/navigations/simpleNavigation/SimpleNavigationButton';
import SimpleNavigationItem from '~/components/patterns/navigations/simpleNavigation/SimpleNavigationItem';

import {
	useAccountContextSelectQuery,
} from './AccountContextSelect.gql';

import useAccountId from '~/hooks/useAccountId';
import useDisabledMemberships from '~/hooks/useDisabledMemberships';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useHomeAccountIds from '~/hooks/useHomeAccountIds';
import usePrevious from '~/hooks/usePrevious';
import useUrlState from '~/hooks/useUrlState';
import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	addAccountLabel: {
		id: 'ui.connectedAccounts.addAccount',
	},
	clientAccountsLabel: {
		id: 'ui.accountAccess.contextSelect.clientAccountsLabel',
	},
	yourAccountLabel: {
		id: 'ui.accountAccess.contextSelect.yourAccountLabel',
	},
});



const AccountContextSelect: React.FC = () => {
	const accountId = useAccountId();
	const disabledMemberships = useDisabledMemberships();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const homeAccountIds = useHomeAccountIds();
	const urlState = useUrlState();
	const viewportType = useViewportType();

	const { data } = useAccountContextSelectQuery();

	const accounts = data?.authenticatedSession?.accounts ?? null;

	const abstractSelectFieldRef = React.useRef<AbstractSelectFieldRef>(null);

	const linkRouteName = urlState.name.endsWith('connectedAccounts')
		? 'account.websites'
		: urlState.name;

	const previousAccountId = usePrevious(accountId);

	React.useEffect(
		() => {
			if (accountId !== previousAccountId) {
				abstractSelectFieldRef.current?.close();
			}
		},
		[
			abstractSelectFieldRef,
			accountId,
			previousAccountId,
			urlState,
		],
	);

	if (
		accounts === null
		|| disabledMemberships === null
		|| homeAccountIds === null
	) {
		return null;
	}

	if ((accounts.length + disabledMemberships.length) < 2) {
		return null;
	}

	const connectedClientAccounts = accounts.filter(
		({ id }) => homeAccountIds.includes(id) === false,
	);

	return (
		<AbstractSelectField
			dropdownWidth={viewportType.isSmall ? 200 : 240}
			label={(
				<AccountDisplayName accountId={accountId} />
			)}
			ref={abstractSelectFieldRef}
			scrollableDropdown={402}
			size={AbstractSelectFieldSize.Small}
			style={AbstractSelectFieldStyle.EminenceLight}
			width={viewportType.isSmall ? 140 : 200}
		>
			<SimpleNavigation
				title={(
					<FormattedMessage
						{...messages.yourAccountLabel}
						values={{
							numberOfAccounts: homeAccountIds.length,
						}}
					/>
				)}
			>
				{homeAccountIds.map((homeAccountId) => (
					<SimpleNavigationItem
						isHighlighted={accountId === homeAccountId}
						key={homeAccountId}
						linkRouteName={linkRouteName}
						linkRouteParams={{
							...urlState.params,
							accountId: homeAccountId,
						}}
					>
						<AccountDisplayName accountId={homeAccountId} />
					</SimpleNavigationItem>
				))}

				{disabledMemberships.map((membershipLabel) => (
					<SimpleNavigationItem
						hint={membershipLabel.explanation}
						isDisabled={true}
						key={membershipLabel.accountId}
					>
						{membershipLabel.accountDisplayName}
					</SimpleNavigationItem>
				))}
			</SimpleNavigation>

			{connectedClientAccounts.length > 0 && (
				<SimpleNavigation
					title={(
						<FormattedMessage {...messages.clientAccountsLabel} />
					)}
				>
					{connectedClientAccounts.map((connectedClientAccount) => {
						return (
							<SimpleNavigationItem
								isHighlighted={accountId === connectedClientAccount.id}
								key={connectedClientAccount.id}
								linkRouteName={linkRouteName}
								linkRouteParams={{
									...urlState.params,
									accountId: connectedClientAccount.id,
								}}
							>
								<AccountDisplayName accountId={connectedClientAccount.id} />
							</SimpleNavigationItem>
						);
					})}

					<SimpleNavigationButton>
						<Button
							icon={(
								<BasicIcon
									type={BasicIconType.Plus}
								/>
							)}
							linkRouteName="account.connectedAccounts.connectAccount"
							linkRouteParams={{
								accountId: effectiveHomeAccountId,
							}}
							onClick={() => {
								abstractSelectFieldRef.current?.close();
							}}
							size={ButtonSize.Small}
							style={ButtonStyle.Hollow}
							uppercase={true}
						>
							<FormattedMessage {...messages.addAccountLabel} />
						</Button>
					</SimpleNavigationButton>
				</SimpleNavigation>
			)}
		</AbstractSelectField>
	);
};



export default AccountContextSelect;
