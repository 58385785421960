import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ProtectedIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="icon-shield"
					transform="translate(1.000000, 2.000000)"
				>
					<g
						id="shield"
						stroke={color}
						strokeLinejoin="round"
						strokeWidth="0.916666667"
						transform="translate(2.750000, 0.000000)"
					>
						<path
							d="M8.05664062,20.625 L13.2335939,17.1201694 C15.035582,15.903066 16.116485,13.8539127 16.1132884,11.6609317 L16.1132884,2.06359185 C13.2885827,3.44295651 9.89344641,2.57334074 8.05664062,0 C6.21983482,2.57334074 2.82469855,3.44295651 -7.10209546e-06,2.06359185 L-7.10209546e-06,11.6500132 C-0.00320378102,13.8429942 1.0776992,15.8921476 2.87968738,17.1092509 L8.05664062,20.625 Z"
							id="Path"
						>
						</path>
						<polyline
							id="Path"
							points="3.8671875 9.45799773 7.21440294 12.890625 12.890625 7.08984375"
							strokeLinecap="round"
						>
						</polyline>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ProtectedIconBuilder;

