/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReorderSegmentsMutationVariables = GraphQL.Exact<{
  email: GraphQL.Scalars['String']['input'];
  legacyUserId: GraphQL.Scalars['ID']['input'];
  segmentsDisplayOrder: ReadonlyArray<GraphQL.SegmentIdentifierInput> | GraphQL.SegmentIdentifierInput;
}>;


export type ReorderSegmentsMutation = { readonly __typename?: 'Mutation', readonly UpdateUserSegmentsDisplayOrder: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly segmentsDisplayOrder: ReadonlyArray<{ readonly __typename?: 'SegmentIdentifier', readonly color: string, readonly iconName: string | null, readonly label: string, readonly shortcode: string | null }> | null } | null } } };


export const ReorderSegmentsDocument = gql`
    mutation ReorderSegments($email: String!, $legacyUserId: ID!, $segmentsDisplayOrder: [SegmentIdentifierInput!]!) {
  UpdateUserSegmentsDisplayOrder(
    email: $email
    segmentsDisplayOrder: $segmentsDisplayOrder
  ) {
    query {
      user(legacyId: $legacyUserId) {
        id
        legacyId
        segmentsDisplayOrder {
          color
          iconName
          label
          shortcode
        }
      }
    }
  }
}
    `;
export type ReorderSegmentsMutationFn = Apollo.MutationFunction<ReorderSegmentsMutation, ReorderSegmentsMutationVariables>;

/**
 * __useReorderSegmentsMutation__
 *
 * To run a mutation, you first call `useReorderSegmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderSegmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderSegmentsMutation, { data, loading, error }] = useReorderSegmentsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      legacyUserId: // value for 'legacyUserId'
 *      segmentsDisplayOrder: // value for 'segmentsDisplayOrder'
 *   },
 * });
 */
export function useReorderSegmentsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderSegmentsMutation, ReorderSegmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderSegmentsMutation, ReorderSegmentsMutationVariables>(ReorderSegmentsDocument, options);
      }
export type ReorderSegmentsMutationHookResult = ReturnType<typeof useReorderSegmentsMutation>;
export type ReorderSegmentsMutationResult = Apollo.MutationResult<ReorderSegmentsMutation>;
export type ReorderSegmentsMutationOptions = Apollo.BaseMutationOptions<ReorderSegmentsMutation, ReorderSegmentsMutationVariables>;