import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import MarginsList from '~/components/atoms/lists/MarginsList';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import StandaloneFieldRow from '~/components/atoms/forms/basis/StandaloneFieldRow';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';

import {
	isString,
} from '~/utilities/typeCheck';

import {
	type Rule,
} from '~/utilities/validations';



const messages = defineMessages({
	confirmationPhraseLabel: {
		id: 'ui.destructConfirmationModal.confirmationPhraseLabel',
	},
	confirmationRequired: {
		id: 'ui.destructConfirmationModal.confirmationRequired',
	},
});



type Props = {
	children?: React.ReactNode,
	confirmationPhrase?: string | null,
	confirmButton: React.ReactNode,
	description?: React.ReactNode,
	disabled?: boolean,
	globalFormErrors?: React.ComponentProps<typeof FormErrorMessages>['errors'],
	loading?: boolean,
	onConfirmation?: () => void | Promise<void>,
};

const DestructConfirmationModalForm: React.FC<Props> = (props) => {
	const {
		children,
		confirmationPhrase,
		confirmButton,
		description,
		disabled,
		globalFormErrors,
		loading,
		onConfirmation = null,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();

	const handleConfirmation = React.useCallback(
		async () => {
			const result = onConfirmation !== null
				? await onConfirmation()
				: null;

			classicFormBehavior.finish();

			return result;
		},
		[
			classicFormBehavior,
			onConfirmation,
		],
	);

	const confirmationRequired = isString(confirmationPhrase);

	const validations = React.useMemo(
		() => {
			return {
				validateConfirmationPhrase: validateConfirmationPhrase(confirmationPhrase),
			};
		},
		[
			confirmationPhrase,
		],
	);

	return (
		<Form
			defaultDataHasChanged={confirmationRequired === false}
			isDisabled={disabled}
			onSuccess={handleConfirmation}
			validations={validations}
		>
			{description && (
				<ModalTextSection>
					<RichText>
						{description}
					</RichText>
				</ModalTextSection>
			)}

			<ModalContentSection>
				{confirmationRequired ? (
					<CalloutMessage
						borders={true}
						message={(
							<FormattedMessage {...messages.confirmationRequired} />
						)}
						status={CalloutMessageStatus.Critical}
					>
						<MarginsList>
							{children}

							<Copy
								{...messages.confirmationPhraseLabel}
								values={{ confirmationPhrase }}
							/>

							<StandaloneFieldRow>
								<TextField
									disabled={loading}
									name="confirmationPhrase"
									placeholder={confirmationPhrase}
								/>
							</StandaloneFieldRow>
						</MarginsList>
					</CalloutMessage>
				) : (
					<MarginsList>
						{children}
					</MarginsList>
				)}
			</ModalContentSection>

			<CenteredFormWrapper>
				<FormErrorMessages
					errors={globalFormErrors}
				/>
			</CenteredFormWrapper>

			<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
				<CancelButton />

				<SubmitButton positive={false}>
					{confirmButton}
				</SubmitButton>
			</ModalButtonsLayout>
		</Form>
	);
};



function validateConfirmationPhrase(phrase?: string | null): Array<Rule> {
	if (phrase === null || phrase === undefined) {
		return [];
	}

	return validateField(
		'confirmationPhrase',
		(f) => [
			f.validateNonEmpty(),
			f.custom({
				message: '',
				rule: ({ value }) => {
					return value.toLowerCase() === phrase.toLowerCase();
				},
			}),
		],
	);
}



export default DestructConfirmationModalForm;
