import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Using this flag we can reveal Start here image by animation */
	visible?: boolean,
};



const StartHereHint: React.FC<Props> = (props) => {
	const {
		visible = true,
	} = props;

	const componentClasses = classNames({
		'start-here': true,
		'start-here--visible': visible,
	});

	return (
		<svg
			className={componentClasses}
			height="100"
			viewBox="0 0 169.1 100"
			width="169.10000610351562"
		>
			<g
				fill="none"
				stroke="#ffffff"
				strokeLinecap="round"
				strokeMiterlimit={10}
				strokeWidth={4}
			>
				<path
					className="svg-elem-1"
					d="M77.1,28.1c0-3-9.6-8.7-12,0c-2,7.3,15.9,6.6,12,14c-5.3,10.1-14-2-14-2"
				/>
				<line
					className="svg-elem-2"
					x1="88.3"
					x2="88.3"
					y1="46.5"
					y2="23.5"
				/>
				<path
					className="svg-elem-3"
					d="M80.9,23.5c0,0,13,0.2,14.6,0"
				/>
				<path
					className="svg-elem-4"
					d="M97.5,46.5c0,0,3.4-24.2,5.8-23.1c2.4,1.1,9.4,23.1,9.4,23.1"
				/>
				<path
					className="svg-elem-5"
					d="M97.1,36.3c0,0,11.8,0.1,14.3,0"
				/>
				<path
					className="svg-elem-6"
					d="M118.2,46.7v-23c0,0,9.2-2.7,10.8,6c1.5,8.6-10.8,6.8-10.8,6.8s13.1-2.6,13.1,10.2"
					strokeLinejoin="round"
				/>
				<line
					className="svg-elem-7"
					x1="141"
					x2="141"
					y1="46.5"
					y2="24.5"
				/>
				<path
					className="svg-elem-8"
					d="M133.7,24.4c0,0,10.7,0.5,14.6-0.9"
				/>
				<path
					className="svg-elem-9"
					d="M71,57.9c0,0,0.5,18.9-0.8,22.8"
				/>
				<path
					className="svg-elem-10"
					d="M83.5,57.9c0,0,0.5,18.9-0.8,22.8"
				/>
				<line
					className="svg-elem-11"
					x1="69.1"
					x2="83.6"
					y1="68.9"
					y2="68.9"
				/>
				<path
					className="svg-elem-12"
					d="M89.6,57.4c0,0,0.3,20.9,0,23.3"
				/>
				<line
					className="svg-elem-13"
					x1="101.9"
					x2="89.6"
					y1="57.9"
					y2="57.4"
				/>
				<line
					className="svg-elem-14"
					x1="101.9"
					x2="89.6"
					y1="80.6"
					y2="80.6"
				/>
				<line
					className="svg-elem-15"
					x1="99.2"
					x2="89.7"
					y1="69"
					y2="69.3"
				/>
				<path
					className="svg-elem-16"
					d="M107.9,80.1v-23c0,0,9.2-2.7,10.8,6c1.5,8.6-10.8,6.8-10.8,6.8s13.1-2.6,13.1,10.2"
					strokeLinejoin="round"
				/>
				<line
					className="svg-elem-17"
					x1="139.4"
					x2="127.1"
					y1="57.9"
					y2="57.4"
				/>
				<line
					className="svg-elem-18"
					x1="139.4"
					x2="127.1"
					y1="80.6"
					y2="80.6"
				/>
				<line
					className="svg-elem-19"
					x1="136.7"
					x2="127.2"
					y1="69"
					y2="69.3"
				/>
				<path
					className="svg-elem-20"
					d="M127.1,57.4c0,0,0.3,20.9,0,23.3"
				/>
				<path
					className="svg-elem-21"
					d="M56.9,33.2c0,0-27.9-4.8-40.5-2.3"
				/>
				<path
					className="svg-elem-22"
					d="M30,22.4l-16.6,9.3c0,0,10.8,3.3,17.5,7.1"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};



export default StartHereHint;
