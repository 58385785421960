import {
	max,
	subDays,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';

import LabeledValue, {
	LabeledValueSize,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import NumberDiff, {
	NumberDiffSize,
} from '~/components/patterns/values/NumberDiff';
import ScoreNumber, {
	ScoreNumberSize,
	ScoreNumberStyle,
} from '~/components/patterns/scores/components/ScoreNumber';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';
import WebsiteHealthNumberDiff from '~/components/logic/websites/WebsiteHealthNumberDiff';
import WebsiteStatisticsSkeletonBlock from '~/components/logic/websites/WebsiteStatisticsSkeletonBlock';

import useBatchContextMultiDashboardAccountSection from '~/hooks/useBatchContextMultiDashboardAccountSection';
import useNavigateToIssues from '~/hooks/useNavigateToIssues';
import useNavigateToTrackedChanges from '~/hooks/useNavigateToTrackedChanges';
import useNavigation from '~/hooks/useNavigation';
import usePollInterval from '~/hooks/usePollInterval';

import {
	useWebsiteStatisticsBlockQuery,
} from './MultiWebsitesDashboardWebsiteStatisticsBlock.gql';

import {
	CHANGES_IN_ANY_COLUMN,
	CHANGE_TYPE_ADDED,
	CHANGE_TYPE_CHANGED,
	CHANGE_TYPE_OTHER,
	CHANGE_TYPE_REDIRECTED,
	CHANGE_TYPE_REMOVED,
	formatDateForBackend,
	formatEndDateForBackend,
} from '~/model/historicalChanges';



const messages = defineMessages({
	health: {
		id: 'ui.multidashboard.segmentsTable.header.health',
	},
	issues: {
		id: 'ui.multidashboard.segmentsTable.header.issues',
	},
	pages: {
		id: 'ui.multidashboard.segmentsTable.header.pages',
	},
});



type Props = {
	accountId: CK.AccountId,
	hasBeenVisible: boolean,
	isVisible: boolean,
	websiteCreatedAt: Date,
	websiteId: CK.WebsiteId,
};

const MultiWebsitesDashboardWebsiteStatisticsBlock: React.FC<Props> = (props) => {
	const {
		accountId,
		hasBeenVisible,
		isVisible,
		websiteCreatedAt,
		websiteId,
	} = props;

	const navigateToIssues = useNavigateToIssues();
	const navigateToTrackedChanges = useNavigateToTrackedChanges();
	const navigation = useNavigation();

	const {
		endDate,
		startDate,
	} = React.useMemo(
		() => {
			const endDate = new Date();
			const startDate = max([
				subDays(endDate, 6),
				websiteCreatedAt,
			]);

			return {
				endDate,
				startDate,
			};
		},
		[
			websiteCreatedAt,
		],
	);

	const {
		data,
	} = useWebsiteStatisticsBlockQuery({
		context: useBatchContextMultiDashboardAccountSection(accountId),
		pollInterval: usePollInterval(isVisible ? 30000 : 0),
		skip: !hasBeenVisible,
		variables: {
			endDate: formatEndDateForBackend(startDate, endDate),
			scope: 'website',
			startDate: formatDateForBackend(startDate),
			websiteId,
		},
	});

	const handleHealthAndIssuesClick = React.useCallback(
		() => {
			navigation.navigate(
				navigateToIssues({
					websiteId,
				}),
			);
		},
		[
			navigateToIssues,
			navigation,
			websiteId,
		],
	);

	const handlePagesClick = React.useCallback(
		() => {
			navigation.navigate(
				navigateToTrackedChanges({
					endDate,
					filter: {
						[CK.PagesCommonColumn.ChangeType]: [
							CHANGE_TYPE_ADDED,
							CHANGE_TYPE_CHANGED,
							CHANGE_TYPE_OTHER,
							CHANGE_TYPE_REDIRECTED,
							CHANGE_TYPE_REMOVED,
						],
						changes_in: CHANGES_IN_ANY_COLUMN,
					},
					scope: 'website',
					startDate,
					websiteId,
				}),
			);
		},
		[
			endDate,
			navigateToTrackedChanges,
			navigation,
			startDate,
			websiteId,
		],
	);

	if (!data) {
		return (
			<WebsiteStatisticsSkeletonBlock />
		);
	}

	const currentHealth = data.dashboardData.healthChanges.data?.[data.dashboardData.healthChanges.data.length - 1]?.health ?? null;
	const currentIssues = data.dashboardData.numberOfIssues.data?.numberOfIssuesOnEndDate ?? null;
	const currentPages = data.dashboardData.numberOfPages.data?.numberOfPagesOnEndDate ?? null;

	const oldestHealth = data.dashboardData.healthChanges.data?.[0]?.health ?? null;
	const deltaIssues = data.dashboardData.numberOfIssues.data?.deltaFromStartDate ?? null;
	const deltaPages = data.dashboardData.numberOfPages.data?.deltaFromStartDate ?? null;

	return (
		<SeparatedBlocks>
			<LabeledValue
				label={(
					<FormattedMessage {...messages.health} />
				)}
				onClickCallback={handleHealthAndIssuesClick}
				size={LabeledValueSize.Small}
			>
				{currentHealth !== null && oldestHealth !== null ? (
					<WebsiteHealthNumberDiff
						number={currentHealth}
						prevNumber={oldestHealth}
					/>
				) : '-'}
				<ScoreNumber
					localize={false}
					maximum={1000}
					size={ScoreNumberSize.XSmall}
					style={ScoreNumberStyle.GreyscaleDark}
					value={currentHealth}
				/>
			</LabeledValue>

			<LabeledValue
				label={(
					<FormattedMessage {...messages.pages} />
				)}
				onClickCallback={handlePagesClick}
				size={LabeledValueSize.Small}
			>
				{deltaPages !== null ? (
					<NumberDiff
						diffValue={deltaPages}
						size={NumberDiffSize.Small}
					/>
				) : '-'}
				<ScoreNumber
					localize={true}
					size={ScoreNumberSize.XSmall}
					style={ScoreNumberStyle.GreyscaleDark}
					value={currentPages}
				/>
			</LabeledValue>

			<LabeledValue
				label={(
					<FormattedMessage {...messages.issues} />
				)}
				onClickCallback={handleHealthAndIssuesClick}
				size={LabeledValueSize.Small}
			>
				{deltaIssues !== null ? (
					<NumberDiff
						diffValue={deltaIssues}
						size={NumberDiffSize.Small}
					/>
				) : '-'}
				<ScoreNumber
					localize={true}
					size={ScoreNumberSize.XSmall}
					style={ScoreNumberStyle.GreyscaleDark}
					value={currentIssues}
				/>
			</LabeledValue>
		</SeparatedBlocks>
	);
};



export default MultiWebsitesDashboardWebsiteStatisticsBlock;
