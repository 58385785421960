import React from 'react';

import DesktopLayout from '../platform/DesktopLayout';
import MobileLayout from '../platform/MobileLayout';

import useViewportType from '~/hooks/useViewportType';
import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	TABBED_VIEW_BREAKPOINT,
} from '~/utilities/viewportSize';



type Props = {
	fullscreenDetailPanel: any,
	innerPanel: any,
};

const PlatformScreen: React.FC<Props> = (props) => {
	const {
		fullscreenDetailPanel,
		innerPanel,
	} = props;

	const viewportType = useViewportType();
	const viewportWidth = useViewportWidth();
	const websiteId = useWebsiteId();

	if (viewportWidth < TABBED_VIEW_BREAKPOINT) {
		return (
			<MobileLayout
				fullscreenDetailPanel={fullscreenDetailPanel}
				innerPanel={innerPanel}
				isHeaderFixed={viewportType.isSmall === false}
				websiteId={websiteId}
			/>
		);
	}

	return (
		<DesktopLayout
			fullscreenDetailPanel={fullscreenDetailPanel}
			innerPanel={innerPanel}
			websiteId={websiteId}
		/>
	);
};



export default PlatformScreen;
