import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const BriefcaseIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M9.375,13.5a.75.75,0,0,1,.75-.75h3.75a.75.75,0,0,1,.75.75v.25a.5.5,0,0,0,.5.5H23a.5.5,0,0,0,.5-.5V10a1,1,0,0,0-1-1H16.975a5,5,0,0,0-9.95,0H1.5a1,1,0,0,0-1,1v3.75a.5.5,0,0,0,.5.5H8.875a.5.5,0,0,0,.5-.5ZM12,6.5A3,3,0,0,1,14.958,9H9.042A3,3,0,0,1,12,6.5Z"
				fill={color}
			/>
			<path
				d="M14.625,16.626A2.629,2.629,0,0,1,11.476,19.2a2.7,2.7,0,0,1-2.1-2.678v-.271a.5.5,0,0,0-.5-.5H1a.5.5,0,0,0-.5.5V23a1,1,0,0,0,1,1h21a1,1,0,0,0,1-1v-6.75a.5.5,0,0,0-.5-.5l-7.83,0a.5.5,0,0,0-.545.5Z"
				fill={color}
			/>
			<path
				d="M12.625,14.251h-1.25a.5.5,0,0,0-.5.5v1.875a1.125,1.125,0,0,0,2.25,0V14.751A.5.5,0,0,0,12.625,14.251Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default BriefcaseIconBuilder;
