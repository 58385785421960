import classNames from 'classnames';
import React from 'react';



export enum HelpIconStyle {
	Dark = 'dark',
	Light = 'light',
}

type Props = {
	/** Style of help icon */
	style?: HelpIconStyle,
};



const HelpIcon: React.FC<Props> = (props) => {
	const {
		style = HelpIconStyle.Dark,
	} = props;

	const componentClasses = classNames({
		'help-icon': true,
		[ 'help-icon--' + style + '-style' ]: true,
	});

	return (
		<span className={componentClasses}>(?)</span>
	);
};



export default HelpIcon;
