import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ToggleAdminIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g>
				<g>
					<path
						d="M20,14.56a5.1,5.1,0,1,1-6.14,0"
						fill="none"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					/>
					<line
						fill="none"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						x1="16.95"
						x2="16.95"
						y1="12.97"
						y2="17.03"
					/>
				</g>
				<path
					d="M9.52,9.87A7.3,7.3,0,0,0,4,17H9.2"
					fill="none"
					id="Stroke-3"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					d="M14.7,10.47a7.08,7.08,0,0,0-1.57-.6"
					fill="none"
					id="Stroke-3-2"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<g id="User-menu-2">
					<g id="Teams-2">
						<g id="Group-7">
							<path
								d="M15.22,6.27a4,4,0,1,1-4-4A4,4,0,0,1,15.22,6.27Z"
								fill="none"
								id="Stroke-5"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							/>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default ToggleAdminIconBuilder;

