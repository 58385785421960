import React from 'react';

import type CK from '~/types/contentking';

import CountSliderContext from '~/components/atoms/forms/components/countSliders/abstractBuilders/CountSliderContext';

import useAccountType from '~/hooks/useAccountType';
import useAccountWebsiteRestrictions from '~/hooks/useAccountWebsiteRestrictions';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useTariffs from '~/hooks/useTariffs';

import {
	doesAccountTypeHavePageBundle,
} from '~/model/accounts';

import {
	REGULAR_MAXIMUM_PAGE_CAPACITY,
} from '~/model/pricing/endUser';

import {
	createPageCapacityRange,
} from '~/model/rangeChoices';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	accountId: CK.AccountId,
	applyLimit: boolean,
	children: RenderProp<any>,
	defaultValue: number,
	name: string,
	numberOfPages: number | undefined,
	value: number,
};

const PageCapacitySliderContext: React.FC<Props> = (props) => {
	const {
		accountId,
		applyLimit,
		children,
		defaultValue,
		name,
		numberOfPages = 0,
		value,
	} = props;

	const accountType = useAccountType(accountId);
	const accountWebsiteRestrictions = useAccountWebsiteRestrictions(accountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);
	const tariffs = useTariffs();

	const calculatedProps = React.useMemo(
		() => {
			if (
				accountType === null
				|| accountWebsiteRestrictions === null
				|| accountWebsiteSizes === null
				|| tariffs.isReady === false
			) {
				return null;
			}

			const maximumPageCapacity = (accountWebsiteRestrictions.maximumPageCapacity > REGULAR_MAXIMUM_PAGE_CAPACITY)
				? accountWebsiteRestrictions.maximumPageCapacity
				: REGULAR_MAXIMUM_PAGE_CAPACITY;

			const regularAllPossibilities = tariffs.tariff.listAllPossiblePageCapacities(
				maximumPageCapacity as any,
			);

			const regularMaxValue = regularAllPossibilities[regularAllPossibilities.length - 1];

			const roundValue = (pageCapacity) => {
				return tariffs.tariff.normalizePageCapacity({
					pageCapacity,
				});
			};

			const minLimit = roundValue(
				numberOfPages > 1
					? numberOfPages
					: 1,
			);

			const maxLimit = (() => {
				let result = applyLimit
					? accountWebsiteRestrictions.maximumPageCapacity
					: regularMaxValue;

				if (doesAccountTypeHavePageBundle(accountType)) {
					const allPossibilitiesOfPageBundle = tariffs.tariff.listAllPossiblePagesAmounts();

					const pageBundleUsage = accountWebsiteSizes.totalPageCapacity - defaultValue;

					const remainingPotentialPageBundle = (applyLimit && accountWebsiteRestrictions.maximumPageBundle !== null)
						? accountWebsiteRestrictions.maximumPageBundle - pageBundleUsage
						: allPossibilitiesOfPageBundle[allPossibilitiesOfPageBundle.length - 1] - pageBundleUsage;

					result = Math.min(result, remainingPotentialPageBundle);
				}

				return result;
			})();

			const minValue = regularAllPossibilities[0];

			const maxValue = Math.max(
				maxLimit,
				regularMaxValue,
			);

			const choices = createPageCapacityRange(
				tariffs.tariff.listAllPossiblePageCapacities(
					maxValue as any,
				),
			);

			return {
				choices,
				maxLimit,
				maxValue,
				minLimit,
				minValue,
				roundValue,
			};
		},
		[
			accountType,
			accountWebsiteRestrictions,
			accountWebsiteSizes,
			applyLimit,
			defaultValue,
			numberOfPages,
			tariffs,
		],
	);

	if (calculatedProps === null) {
		return null;
	}

	const {
		choices,
		maxLimit,
		maxValue,
		minLimit,
		minValue,
		roundValue,
	} = calculatedProps;

	return (
		<CountSliderContext
			ceilTo={roundValue}
			maxLimit={maxLimit}
			maxValue={maxValue}
			minLimit={minLimit}
			minValue={minValue}
			name={name}
			numberOfSteps={choices.size()}
			stepGetter={choices.getStepForValue}
			value={value}
			valueGetter={choices.getValueOfStep}
		>
			{(countSliderContextProps) => renderProp(children, countSliderContextProps)}
		</CountSliderContext>
	);
};



export default PageCapacitySliderContext;
