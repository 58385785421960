import classNames from 'classnames';
import React from 'react';

import styles from './PopupBubble.module.scss';



export enum PopupBubbleContentAlignment {
	Left = 'left',
	Center = 'center',
}

export enum PopupBubbleSize {
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
}

export enum PopupBubbleStyle {
	Dark = 'dark',
	Light = 'light',
	Premium = 'premium',
	Remark = 'remark',
}

type Props = {
	/** Additional arrow styles */
	arrowStyles?: React.CSSProperties,
	breakWords?: boolean,
	children: React.ReactNode,
	contentAlignment?: PopupBubbleContentAlignment,
	gaps?: boolean,
	maxWidth?: React.CSSProperties['maxWidth'],
	onClick?: (e: React.MouseEvent) => void,
	onMouseEnter?: () => void,
	onMouseLeave?: () => void,
	size?: PopupBubbleSize,
	style?: PopupBubbleStyle,
};

export type PopupBubbleRef = {
	containsTarget: (target: Node | null) => boolean,
	getArrowRef: () => React.RefObject<HTMLDivElement>,
};



const PopupBubble = React.forwardRef<any, Props>((props, ref: React.Ref<PopupBubbleRef>) => {
	const {
		arrowStyles,
		breakWords,
		children,
		contentAlignment = PopupBubbleContentAlignment.Left,
		gaps = true,
		maxWidth,
		onClick,
		onMouseEnter,
		onMouseLeave,
		size,
		style = PopupBubbleStyle.Light,
	} = props;

	const arrowRef = React.useRef<HTMLDivElement | null>(null);
	const componentRef = React.useRef<HTMLDivElement | null>(null);

	React.useImperativeHandle(ref, () => ({
		containsTarget: (target) => {
			return componentRef.current?.contains(target) ?? false;
		},
		getArrowRef: () => arrowRef,
	}));

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.breakWords]: breakWords,

		[styles.sizeSmall]: size === PopupBubbleSize.Small,
		[styles.sizeMedium]: size === PopupBubbleSize.Medium,
		[styles.sizeLarge]: size === PopupBubbleSize.Large,

		[styles.dark]: style === PopupBubbleStyle.Dark,
		// [styles.light]: style === PopupBubbleStyle.Light,
		[styles.premium]: style === PopupBubbleStyle.Premium,
		[styles.remark]: style === PopupBubbleStyle.Remark,
		'dark-bg-context': style === PopupBubbleStyle.Dark || style === PopupBubbleStyle.Premium || style === PopupBubbleStyle.Remark,
	});

	const contentClasses = classNames({
		[styles.content]: true,
		[styles.contentGaps]: gaps,
		[styles.contentCentered]: contentAlignment === PopupBubbleContentAlignment.Center,
	});

	return (
		<div
			className={componentClasses}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			ref={componentRef}
			style={{
				maxWidth,
			}}
		>
			<div
				className={styles.arrow}
				data-popper-arrow={true}
				ref={arrowRef}
				style={arrowStyles || {
					left: 0,
					right: 0,
				}}
			/>
			<div className={contentClasses}>
				{children}
			</div>
		</div>
	);
});



export default PopupBubble;
