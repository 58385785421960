import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import DepthLayer from '~/components/patterns/utils/DepthLayer';



class FullscreenPanel extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			visible: props.visible,
			overlaid: props.overlaid,
		};
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			overlaid: nextOverlaid,
		} = nextProps;

		this._tryApplyOverlaidWithDelay(nextOverlaid);
	}



	_tryApplyOverlaidWithDelay(nextOverlaid) {
		const {
			overlaid,
		} = this.props;

		if (!overlaid && nextOverlaid) {
			setTimeout(() => {
				this.setState({
					overlaid: true,
				});
			}, 450);
		} else if (overlaid && !nextOverlaid) {
			this.setState({
				overlaid: false,
			});
		}
	}



	render() {
		const {
			backlink,
			children,
			closeCallback,
			overlay,
			secondLevelOverlay,
			visible,
		} = this.props;

		const {
			overlaid,
		} = this.state;

		const panelClasses = classNames({
			'panel': true,
			'panel--fullscreen': true,
			'js-scrollable': true,
			'js-scrollable-panel': true,
			'panel--active': visible,
			'panel--overlaid': overlaid,
			'panel--overlay': overlay,
			'panel--second-level-overlay': secondLevelOverlay,
		});

		let content;

		if (children) {
			content = React.cloneElement(children, {
				backlink,
				closeCallback,
			});
		}

		return (
			<DepthLayer depthJump={3000}>
				<section
					className={panelClasses}
					ref={(panelRef) => this.panelRef = panelRef}
				>
					<div
						className="panel__content"
					>
						{content}
					</div>
				</section>
			</DepthLayer>
		);
	}

}

FullscreenPanel.propTypes = {
	backlink: PropTypes.string.isRequired,
	closeCallback: PropTypes.func.isRequired,
	overlaid: PropTypes.bool.isRequired,
	overlay: PropTypes.bool.isRequired,
	visible: PropTypes.bool.isRequired,
};



export default FullscreenPanel;
