import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DepthLayer from '~/components/patterns/utils/DepthLayer';
import {
	viewportTypeValidation,
} from '~/utilities/viewportSize';



const messages = defineMessages({
	backlink: {
		affectedLinks: {
			id: 'ui.backlink.mobile.affectedPages',
		},
		affectedPages: {
			id: 'ui.backlink.mobile.affectedPages',
		},
		alertPages: {
			id: 'ui.backlink.mobile.pagesOverview',
		},
		closeHistoricalChangesMobileFilter: {
			id: 'ui.exports.type.general.button.submit.label.inProgress',
		},
		closedPages: {
			id: 'ui.backlink.mobile.closedPages',
		},
		default: {
			id: 'ui.backlink.mobile.default',
		},
		issuesCategory: {
			id: 'ui.backlink.mobile.issueDetail',
		},
		issueDetail: {
			id: 'ui.backlink.mobile.issueDetail',
		},
		issuesOverview: {
			id: 'ui.backlink.mobile.issuesOverview',
		},
		members: {
			id: 'ui.backlink.mobile.members',
		},
		pageDetail: {
			id: 'ui.backlink.mobile.pageDetail',
		},
		pagesOverview: {
			id: 'ui.backlink.mobile.pagesOverview',
		},
		platform: {
			id: 'ui.backlink.mobile.platform',
		},
		website: {
			id: 'ui.backlink.mobile.website',
		},
		websites: {
			id: 'ui.backlink.mobile.websites',
		},
	},
});



class Panel extends Component {

	constructor(props, context) {
		super(props, context);

		this._animateClosing = this._animateClosing.bind(this);
		this._animateOpening = this._animateOpening.bind(this);
		this._close = this._close.bind(this);
		this._fixPanelPosition = this._fixPanelPosition.bind(this);
		this._handleCloseAreaOnClick = this._handleCloseAreaOnClick.bind(this);
		this._tryApplyOverlaidWithDelay = this._tryApplyOverlaidWithDelay.bind(this);
		this._handleAnimationEnd = this._handleAnimationEnd.bind(this);

		this.state = {
			visible: props.visible,
			overlaid: props.overlaid,

			beforeGettingOpened: false,
			gettingOpened: false,
			beforeGettingClosed: false,
			gettingClosed: false,

			fixedRightPadding: null,
		};
	}



	componentDidMount() {
		const {
			animateOnMount,
			visible,
		} = this.props;

		if (animateOnMount && visible) {
			this.setState({
				beforeGettingOpened: true,
				beforeGettingClosed: false,
				gettingClosed: false,
			});
		}

		this._fixPanelPosition();
	}



	componentWillUnmount() {
		this.panelRef.removeEventListener('animationend', this._handleAnimationEnd);
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!this.props.visible && nextProps.visible) {
			this.setState({
				beforeGettingOpened: true,
				beforeGettingClosed: false,
				gettingClosed: false,
			});
		}

		if (this.props.visible && !nextProps.visible) {
			this.setState({
				beforeGettingClosed: true,
			});
		}

		this._tryApplyOverlaidWithDelay(nextProps.overlaid);

		if (this.props.viewportType && this.props.viewportType != nextProps.viewportType) {
			this._fixPanelPosition();
		}
	}



	componentDidUpdate(prevProps, prevState) {
		if (!prevState.beforeGettingOpened && this.state.beforeGettingOpened) {
			this._animateOpening();
		}

		if (!prevState.beforeGettingClosed && this.state.beforeGettingClosed) {
			this._animateClosing();
		}
	}



	_tryApplyOverlaidWithDelay(nextOverlaid) {
		if (!this.props.overlaid && nextOverlaid) {
			setTimeout(() => {
				this.setState({
					overlaid: true,
				});
			}, 450);
		} else if (this.props.overlaid && !nextOverlaid) {
			this.setState({
				overlaid: false,
			});
		}
	}



	// Sometimes we need to recalculate right gap of panel,
	// because scrollbar can be always visible or like an overflowing element (MacOS and touch devices).
	_fixPanelPosition() {
		const panel = this.panelRef;
		const closeArea = this.closeAreaRef;

		// reset the old value first to recalculate everything from default state
		this.setState({
			fixedRightPadding: null,
		}, () => {
			const sidebarWidth = panel.offsetWidth - panel.clientWidth;
			const rightGap = parseInt(getComputedStyle(closeArea)['padding-right'], 10);

			this.setState({
				fixedRightPadding: rightGap - sidebarWidth,
			});
		});
	}



	_handleAnimationEnd() {
		const {
			gettingClosed,
			gettingOpened,
		} = this.state;

		if (gettingClosed) {
			this.setState({
				beforeGettingClosed: false,
				gettingClosed: false,
				fixedRightPadding: null,
			});
		}

		if (gettingOpened) {
			this.setState({
				beforeGettingOpened: false,
				gettingOpened: false,
			});
		}

		this.panelRef.removeEventListener('animationend', this._handleAnimationEnd);
	}



	_animateOpening() {
		setTimeout(() => {
			this.panelRef.addEventListener('animationend', this._handleAnimationEnd, { once: true });

			this.setState({
				gettingOpened: true,
			});
		}, 100);

		this._fixPanelPosition();
	}



	_animateClosing() {
		this.panelRef.addEventListener('animationend', this._handleAnimationEnd, { once: true });

		setTimeout(() => {
			this.setState({
				gettingClosed: true,
			});
		}, 50);
	}



	_handleCloseAreaOnClick(e) {
		if (e.target.classList.contains('panel__close-area')) {
			this._close();
		}
	}



	_close() {
		this.props.closeCallback();
	}



	render() {
		const { backlink } = this.props;

		const panelClasses = classNames({
			'panel': true,
			'js-scrollable': true,
			'js-scrollable-panel': true,
			'panel--active': (!this.state.beforeGettingOpened && this.props.visible) || this.state.beforeGettingClosed || this.state.gettingClosed,
			'panel--overlaid': this.state.overlaid,
			'panel--overlay': this.props.overlay,
			'panel--second-level-overlay': this.props.secondLevelOverlay,
			'panel--before-opening': this.state.beforeGettingOpened,
			'panel--opening': this.state.gettingOpened,
			'panel--before-closing': this.state.beforeGettingClosed,
			'panel--closing': this.state.gettingClosed,
		});

		let content;

		let closeAreaStyles;
		if (this.state.fixedRightPadding) {
			closeAreaStyles = {
				paddingRight: this.state.fixedRightPadding + 'px',
			};
		}

		if (this.props.children) {
			content = React.cloneElement(this.props.children, {
				closeCallback: this._close,
				onContentUpdate: this._fixPanelPosition,
			});
		}

		let backlinkMessage;
		if (backlink && messages.backlink[backlink]) {
			backlinkMessage = messages.backlink[backlink];
		} else {
			backlinkMessage = messages.backlink.default;
		}

		return (
			<DepthLayer depthJump={3000}>
				<section
					className={panelClasses}
					ref={(panelRef) => this.panelRef = panelRef}
				>
					<div
						className="panel__close-area"
						onClick={this._handleCloseAreaOnClick}
						ref={(closeAreaRef) => this.closeAreaRef = closeAreaRef}
						style={closeAreaStyles}
					>
						<div
							className="panel__content"
						>
							<div
								className="panel__close-btn"
								onClick={this._close}
							>
								<span>
									<FormattedMessage {...backlinkMessage} />
								</span>
							</div>
							{content}
						</div>
					</div>
				</section>
			</DepthLayer>
		);
	}

}

Panel.propTypes = {
	animateOnMount: PropTypes.bool.isRequired,
	backlink: PropTypes.string,
	closeCallback: PropTypes.func.isRequired,
	overlaid: PropTypes.bool.isRequired,
	overlay: PropTypes.bool.isRequired,
	visible: PropTypes.bool.isRequired,
	viewportType: viewportTypeValidation(),
};



export default Panel;
