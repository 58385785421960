import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SmileyIconBuilder: IconBuilder = {
	icon: ({ color = '#c5cdd5' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m0 0h24v24h-24z"
					fill="none"
				/>
				<path
					d="m11.93075 7.60196875c0-.96625.783125-1.749375 1.749375-1.749375s1.7496875.783125 1.7496875 1.749375-.7834375 1.749375-1.7496875 1.749375-1.749375-.783125-1.749375-1.749375zm-7.179375 4.69312495h10.4575c-.75875 2.1596875-2.8103125 3.7103125-5.22875 3.7103125-2.41875 0-4.4703125-1.550625-5.22875-3.7103125zm3.3178125-4.69312495c0 .96625-.783125 1.749375-1.749375 1.749375s-1.749375-.783125-1.749375-1.749375.783125-1.749375 1.749375-1.749375 1.749375.783125 1.749375 1.749375zm-8.0690625 2.39812495c0 5.5228125 4.4771875 10 10 10 5.5225 0 10-4.4771875 10-10 0-5.52312495-4.4775-9.99999995-10-9.99999995-5.5228125 0-10 4.476875-10 9.99999995z"
					fill={color}
					transform="translate(2 2)"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SmileyIconBuilder;

