import React from 'react';



 type Context = {
	isColumnVisible: ((column) => boolean) | null,
	scrollToColumn: ((column) => void) | null,
	setIsColumnVisible: (isColumnVisible) => void,
	setScrollToColumn: (scrollToColumn) => void,
};



export const DatatableContext = React.createContext<Context | null>(null);



const DatatableContextProvider = (props) => {
	const {
		children,
	} = props;

	const [isColumnVisible, setIsColumnVisible] = React.useState(null);
	const [scrollToColumn, setScrollToColumn] = React.useState(null);

	const value = React.useMemo(
		() => ({
			isColumnVisible,
			scrollToColumn,
			setIsColumnVisible: (newIsColumnVisible) => setIsColumnVisible(() => newIsColumnVisible),
			setScrollToColumn: (newScrollToColumn) => setScrollToColumn(() => newScrollToColumn),
		}),
		[
			isColumnVisible,
			scrollToColumn,
			setIsColumnVisible,
			setScrollToColumn,
		],
	);

	return (
		<DatatableContext.Provider value={value}>
			{children}
		</DatatableContext.Provider>
	);
};



export default DatatableContextProvider;

