export default function isVatNumberCompatibleWithCountry(country, vatNumber) {
	const vatCountryCode = vatNumber.substr(0, 2).toUpperCase();

	let expectedCountryCode = country;

	if (country === 'GR') {
		expectedCountryCode = 'EL';
	}

	return expectedCountryCode === vatCountryCode;
}
