import React from 'react';

import type CK from '~/types/contentking';

import {
	WebsiteIdContext,
} from '~/components/providers/WebsiteIdProvider';



function useWebsiteId(): CK.WebsiteId {
	return React.useContext(WebsiteIdContext) as CK.WebsiteId;
}



export default useWebsiteId;
