import {
	INITIALIZE_FROM_LOCAL_STORAGE,
	LOGIN_SUCCESSFUL,
	STORE_LAST_SEARCHED_WEBSITE,
} from '~/actions';



const createDefaultState = () => {
	return '';
};



export function lastSearchedWebsite(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case INITIALIZE_FROM_LOCAL_STORAGE: {
			const {
				data,
			} = action;

			state = data.get('lastSearchedWebsite') || createDefaultState();

			break;
		}

		case STORE_LAST_SEARCHED_WEBSITE: {
			const {
				value,
			} = action;

			state = value;

			break;
		}

		case LOGIN_SUCCESSFUL: {
			if (action.resetData) {
				state = createDefaultState();
			}

			break;
		}

	}

	return state;
}
