import classNames from 'classnames';
import React from 'react';

import RichText from '~/components/patterns/typography/RichText';

import styles from './AcademyBox.module.scss';



type Props = {
	/** Text content of box */
	children: React.ReactNode,
	/** Explanatory links attached to Academy box */
	links?: Array<React.ReactNode>,
	/** Callback triggered when user click on close button */
	onCloseClickCallback: (e: React.MouseEvent) => void,
};

const AcademyBox: React.FC<Props> = (props) => {
	const {
		children,
		links,
		onCloseClickCallback,
	} = props;

	const _handleBoxClose = (e) => {
		e.preventDefault();

		onCloseClickCallback(e);
	};

	const _renderLinks = () => {
		if (!links) {
			return null;
		}

		return (
			<RichText>
				<ul>
					{links.map((link, index) => {
						return (
							<li key={'link-item-' + index}>
								{link}
							</li>
						);
					})}
				</ul>
			</RichText>
		);
	};

	const classes = classNames({
		[styles.component]: true,
		'dark-bg-context': true,
	});

	return (
		<div className={classes}>
			<a
				className={styles.closeButton}
				href="#"
				onClick={_handleBoxClose}
			>
				&times;
			</a>
			<div className={styles.content}>
				<RichText>
					{children}
				</RichText>
			</div>
			<div className={styles.footer}>
				{_renderLinks()}
			</div>
		</div>
	);
};



export default AcademyBox;
