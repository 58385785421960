import React from 'react';

import usePollInterval from '~/hooks/usePollInterval';



enum Coverage {
	New,
	Sync,
	Loaded,
}

type Edges<TNode> = ReadonlyArray<{
	cursor: string,
	loadedAt: number,
	node: TNode,
} | null>;

type Connection<TNode> = {
	edges: Edges<TNode>,
	totalCount: number,
};



function calculateOffset(offset: number, limit: number): number {
	return Math.max(
		0,
		Math.floor(offset - limit * (2 / 5)),
	);
}



function isCovered<TNode>(
	edgeIndex: number,
	edges: Edges<TNode>,
	isNotCovered: boolean,
	now: number,
): Coverage {
	if (isNotCovered) {
		return (edges[edgeIndex] ?? null) === null
			? Coverage.New
			: Coverage.Sync;
	}

	const edge = edges[edgeIndex] ?? null;

	if (edge === null) {
		return Coverage.New;
	}

	return edge.loadedAt >= now
		? Coverage.Loaded
		: Coverage.Sync;
}



function getPatch<TNode>(
	now: number,
	edges: Edges<TNode>,
	coverFrom: number,
	coverSpan: number,
	pollIntervalMS: number,
	isNotCovered: boolean,
) {
	now = now - pollIntervalMS;

	let isForNew = false;
	let since: number | null = null;
	let until: number | null = null;

	for (let i = 0; i < coverSpan; i++) {
		if (isCovered(coverFrom + i, edges, isNotCovered, now) !== Coverage.Loaded) {
			since = coverFrom + i;

			break;
		}
	}

	for (let i = coverFrom + coverSpan - 1; i >= coverFrom; i--) {
		if (isCovered(i, edges, isNotCovered, now) !== Coverage.Loaded) {
			until = i;

			break;
		}
	}

	if (since === null) {
		since = coverFrom;
	}

	if (until === null) {
		return {
			coverFrom: since,
			coverSpan: 0,
			isEdge: false,
			coverCoefficient: 0,
		};
	}

	const half = Math.floor(coverSpan / 2);
	let coverCoefficient = 0;

	for (let i = 0; i < coverSpan; i++) {
		const state = isCovered(coverFrom + i, edges, isNotCovered, now);

		if (state !== Coverage.Loaded) {
			if (i >= half) {
				coverCoefficient++;
			} else {
				coverCoefficient--;
			}

			if (state === Coverage.New) {
				isForNew = true;
			}
		}
	}

	return {
		coverFrom: since,
		coverSpan: until - since + 1,
		isEdge: (since === coverFrom) || (until === (coverFrom + coverSpan - 1)),
		isSync: !isForNew,
		coverCoefficient: coverCoefficient / coverSpan,
	};
}



function useFetchFurtherRange<TNode>(
	connection: Connection<TNode> | null,
	defaultLimit: number,
	pollIntervalMS: number,
	fetchMore: (input: {
		variables: {
			limit: number,
			offset: number,
		},
	}) => Promise<any>,
	customIsNotLoaded: ((edges: Edges<TNode>) => boolean) | null,
) {
	pollIntervalMS = usePollInterval(pollIntervalMS);

	return React.useCallback(
		async (currentOffset: number, lastSynced: number) => {
			const now = Date.now();

			if (connection === null) {
				return {
					skip: true,
				};
			}

			const {
				edges,
				totalCount,
			} = connection;

			let offset;
			let limit;

			const isNotCovered = customIsNotLoaded !== null && customIsNotLoaded(edges);

			if (lastSynced < (now - pollIntervalMS)) {
				offset = calculateOffset(currentOffset, defaultLimit);
				limit = defaultLimit;
			} else {
				let patch = getPatch(
					now,
					edges,
					calculateOffset(currentOffset, defaultLimit),
					defaultLimit,
					pollIntervalMS,
					isNotCovered,
				);

				if (patch.isSync) {
					patch = getPatch(
						now,
						edges,
						calculateOffset(currentOffset, defaultLimit),
						defaultLimit,
						0,
						isNotCovered,
					);
				}

				if (patch.coverSpan === 0) {
					return {
						skip: true,
					};
				}

				if (patch.coverFrom >= totalCount) {
					return {
						skip: true,
					};
				}

				if (patch.coverSpan < 50 && patch.isEdge === true && (patch.coverFrom + patch.coverSpan) < totalCount) {
					return {
						skip: true,
					};
				}

				offset = patch.coverFrom;
				limit = patch.coverSpan;
			}

			await fetchMore({
				variables: {
					limit,
					offset,
				},
			});

			return {
				skip: false,
			};
		},
		[
			connection,
			customIsNotLoaded,
			defaultLimit,
			fetchMore,
			pollIntervalMS,
		],
	);
}



export default useFetchFurtherRange;
