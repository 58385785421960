import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy from '~/components/logic/Copy';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';

import {
	type Values,
} from './types';



const messages = defineMessages({
	description: {
		id: 'ui.connectedAccounts.modal.connectAccount.accessRequestedStep.description',
	},
	heading: {
		id: 'ui.connectedAccounts.modal.connectAccount.accessRequestedStep.heading',
	},
	ok: {
		id: 'ui.general.ok',
	},
	title: {
		id: 'ui.connectedAccounts.modal.connectAccount.accessRequestedStep.title',
	},
});



type Props = {
	values: Values,
	onClose: () => void,
};

const AccessRequestedStep: React.FC<Props> = (props) => {
	const {
		values,
		onClose,
	} = props;

	return (
		<MultiStepModalStep
			name="accessRequested"
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ConfirmationMessage
				animate={true}
				context={ConfirmationMessageContext.EmailSent}
				ctaButtons={(
					<Button
						onClick={onClose}
						style={ButtonStyle.Action}
						uppercase={false}
						width={ButtonWidth.SubmitButton}
					>
						<FormattedMessage {...messages.ok} />
					</Button>
				)}
				message={<FormattedMessage {...messages.heading} />}
			>
				<Copy
					{...messages.description}
					values={{ email: values.email }}
				/>
			</ConfirmationMessage>
		</MultiStepModalStep>
	);
};



export default AccessRequestedStep;
