import React from 'react';



const DEFAULT_WIDTH = 280;



type Props = {
	/** Slider component */
	slider: React.ReactNode,
	/** Additional slider info */
	sliderInfo?: React.ReactNode,
	/** Custom width of slider info part */
	sliderInfoWidth?: number,
	/** Additional slider labels */
	sliderLabels?: React.ReactNode,
	/** Custom width of element */
	width?: React.CSSProperties['width'],
};



const CountSliderFieldLayout: React.FC<Props> = (props) => {
	const {
		slider,
		sliderInfo,
		sliderInfoWidth,
		sliderLabels,
		width = DEFAULT_WIDTH,
	} = props;

	return (
		<div
			className="count-slider-field"
			style={{
				width,
			}}
		>
			<div className="count-slider-field__field-row">
				<div className="count-slider-field__slider">
					<div className="count-slider-field__slider-component">
						{slider}
					</div>

					{sliderLabels && (
						<div className="count-slider-field__slider-labels">
							{sliderLabels}
						</div>
					)}
				</div>

				{sliderInfo && (
					<div
						className="count-slider-field__info"
						style={{
							minWidth: sliderInfoWidth,
						}}
					>
						{sliderInfo}
					</div>
				)}
			</div>
		</div>
	);
};



export default CountSliderFieldLayout;
