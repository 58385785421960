import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import LabeledValue, {
	LabeledValueContentAlignment,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import PlatformIssueButton from '../PlatformIssueButton';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';

import {
	ALERT_PLATFORM_ISSUE_OPENED_HOSTNAME_NONCANONICAL_PRESENT,
	ALERT_PLATFORM_ISSUE_OPENED_HTTPS_NONCANONICAL_PRESENT,
} from '~/model/alerts';



const messages = defineMessages({
	solved: {
		id: 'ui.alerts.overview.incident.details.issueHasBeenResolved',
	},
	status: {
		id: 'ui.contentOverview.tableHeading.alertStatus',
	},
	url: {
		id: 'ui.contentOverview.tableHeading.url',
	},
});



class PlatformIssueOpenedDetails extends Component {

	render() {
		const {
			incident,
			websiteId,
		} = this.props;

		const isIncidentActive = incident.get('phase') === 'active';

		const isModern = incident.hasIn(['state', 'context']);

		const issueCategory = incident
			.get('type')
			.replace('platform_issue_opened.', '')
			.split('/')[0];

		const details = [];

		if (isModern) {
			if (
				incident.get('type') === ALERT_PLATFORM_ISSUE_OPENED_HOSTNAME_NONCANONICAL_PRESENT
				|| incident.get('type') === ALERT_PLATFORM_ISSUE_OPENED_HTTPS_NONCANONICAL_PRESENT
			) {
				if (incident.getIn(['state', 'state']) === 'open') {
					incident.getIn(['state', 'context', 'sample_urls']).map((sampleUrl) => {
						details.push(
							<LabeledValuesList key={sampleUrl.get('url')}>
								<LabeledValue
									contentAlignment={LabeledValueContentAlignment.Left}
									label={(
										<FormattedMessage {...messages.url} />
									)}
								>
									{sampleUrl.get('url')}
								</LabeledValue>

								<LabeledValue
									contentAlignment={LabeledValueContentAlignment.Left}
									label={(
										<FormattedMessage {...messages.status} />
									)}
								>
									<CodeValue>
										{sampleUrl.get('status_code')}

										{sampleUrl.get('redirect_target') !== null && (
											<span> ({sampleUrl.get('redirect_target')})</span>
										)}
									</CodeValue>
								</LabeledValue>
							</LabeledValuesList>,
						);
					});
				} else {
					details.push(
						<SeparatedBlocks key={issueCategory}>
							<FormattedMessage {...messages.solved} />
						</SeparatedBlocks>,
					);
				}
			}
		}

		return (
			<IncidentBoxContent
				button={isIncidentActive && (
					<PlatformIssueButton
						issueCategory={issueCategory}
						websiteId={websiteId}
					/>
				)}
			>
				{details}
			</IncidentBoxContent>
		);
	}

}

PlatformIssueOpenedDetails.propTypes = {
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string,
};



export default PlatformIssueOpenedDetails;
