import classNames from 'classnames';
import React from 'react';



type Props = {
	header: Array<React.ReactNode>,
	rows: Array<Array<React.ReactNode>>,
};



const SimpleTable: React.FC<Props> = (props) => {
	const {
		header = [],
		rows = [],
	} = props;

	function renderBody() {
		return rows.map((row, index) => {
			const rowClasses = classNames({
				'simple-table__row': true,
				'simple-table__row--body': true,
				'simple-table__row--even': (index + 1) % 2 === 0,
				'simple-table__row--odd': (index + 1) % 2 !== 0,
			});

			return (
				<tr
					className={rowClasses}
					key={index}
				>
					{row}
				</tr>
			);
		});
	}

	return (
		<div className="simple-table">
			<div className="simple-table__table-wrapper">
				<table className="simple-table__table">
					<thead className="simple-table__thead">
						<tr>
							{header}
						</tr>
					</thead>
					<tbody className="simple-table__body">
						{renderBody()}
					</tbody>
				</table>
			</div>
		</div>
	);
};



export default SimpleTable;
