import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	Link,
} from 'react-router5';
import goTo from '~/routing/goTo';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import CancelInvitationLink from '~/components/app/CancelInvitationLink';
import StatusLabel, {
	StatusLabelType,
} from '~/components/patterns/statuses/StatusLabel';
import UserCard, {
	UserCardCtaElementVisibility,
} from '~/components/patterns/users/UserCard';
import UserRoleAndAccess from '~/components/app/UserRoleAndAccess';
import ChromeExtension, {
	ChromeExtensionSize,
} from '~/components/logic/users/ChromeExtension';

import useViewportType from '~/hooks/useViewportType';

import {
	extractInitials,
} from '~/model/users';



const messages = defineMessages({
	editLink: {
		id: 'ui.forms.editLink',
	},
	invitationSentMessage: {
		id: 'ui.teamDetail.members.invitationSent',
	},
	loggedInMemberPlaceholder: {
		id: 'ui.settings.userNamePlaceholder',
	},
	yourProfileButtonLabel: {
		id: 'ui.settings.menuLabel',
	},
});



type Props = {
	accountId: CK.AccountId,
	currentUserEmail: string | null,
	member: {
		__typename?: 'Invitation',
		displayName: GraphQL.Invitation['displayName'],
		email: GraphQL.Invitation['email'],
		id: GraphQL.Invitation['id'],
	} | {
		__typename?: 'User',
		displayName: GraphQL.User['displayName'],
		email: GraphQL.User['email'],
		id: GraphQL.User['id'],
		isChromeExtensionInstalled: GraphQL.User['isChromeExtensionInstalled'],
		membership: {
			hasLimitedWebsitesAccess: boolean,
			role: GraphQL.UserRole,
			websites: ReadonlyArray<{
				id: CK.WebsiteId,
			}>,
		},
	},
};

const MemberCard: React.FC<Props> = (props) => {
	const {
		accountId,
		currentUserEmail,
		member,
	} = props;

	const viewportType = useViewportType();

	const handleClick = React.useCallback(
		(e) => {
			if (member.__typename === 'Invitation') {
				return false;
			}

			if (member.email !== currentUserEmail) {
				goTo(e, 'account.members.member', {
					accountId,
					memberDetailId: encodeURIComponent(member.email),
				});
			} else {
				goTo(e, 'userProfile');
			}
		},
		[
			accountId,
			member,
			currentUserEmail,
		],
	);

	function renderMemberDetail(member) {
		if (member.__typename === 'Invitation') {
			return (
				<StatusLabel type={StatusLabelType.Success}>
					<FormattedMessage {...messages.invitationSentMessage} />
				</StatusLabel>
			);
		}

		return (
			<UserRoleAndAccess
				userAccountMembership={member.membership}
			/>
		);
	}

	function renderEditMemberLink(memberId) {
		if (memberId !== currentUserEmail) {
			return (
				<Link
					key="edit-member-link"
					routeName="account.members.member"
					routeParams={{ memberDetailId: encodeURIComponent(memberId) }}
				>
					<FormattedMessage {...messages.editLink} />
				</Link>
			);
		}
	}

	function renderCtaElement() {
		const links: Array<React.ReactNode> = [];

		if (member.email === currentUserEmail) {
			links.push(
				<Button
					key="edit-profile-button"
					size={viewportType.isMedium || viewportType.isSmall ? ButtonSize.Small : ButtonSize.Default}
					style={ButtonStyle.Hollow}
				>
					<FormattedMessage {...messages.yourProfileButtonLabel} />
				</Button>,
			);
		}

		if (member.__typename === 'User') {
			links.push(renderEditMemberLink(member.email));
		}

		if (member.__typename === 'Invitation') {
			links.push(
				<CancelInvitationLink
					accountId={accountId}
					email={member.email}
					key="cancel-invitation-link"
				/>,
			);
		}

		return links;
	}

	return (
		<UserCard
			avatar={{
				email: member.__typename === 'Invitation' ? null : member.email,
				placeholder: member.__typename === 'Invitation' ? (
					<BasicIcon
						size={34}
						type={BasicIconType.Send}
					/>
				) : extractInitials(member.email),
			}}
			ctaElement={renderCtaElement()}
			ctaElementVisibility={member.email === currentUserEmail ? UserCardCtaElementVisibility.Always : UserCardCtaElementVisibility.OnHover}
			details={renderMemberDetail(member)}
			name={member.email === currentUserEmail ? (
				<FormattedMessage {...messages.loggedInMemberPlaceholder} />
			) : member.displayName}
			onClick={member.__typename === 'User' ? handleClick : undefined}
			tags={member.__typename === 'User' && (
				<ChromeExtension
					email={member.email}
					isChromeExtensionInstalled={member.isChromeExtensionInstalled}
					showLabel={true}
					size={ChromeExtensionSize.Small}
				/>
			)}
		/>
	);
};



export default MemberCard;
