import React from 'react';

import useNavigation from './useNavigation';

import {
	getRouterState,
} from '~/routing/router';



type Encode<T> = (value: T) => string | null;

function useWriteQuerystring<T = string>(
	name: string,
	value: T,
	encode?: Encode<T>,
): void {
	const navigation = useNavigation();

	React.useEffect(
		() => {
			const encodedValue = encode ? encode(value) : value;
			const {
				name: routeName,
				params: routeParams,
			} = getRouterState();

			if (encodedValue === null && !routeParams[name]) {
				return;
			}

			if (encodedValue === routeParams[name]) {
				return;
			}

			navigation.navigate({
				routeName,
				routeParams: {
					...routeParams,
					[name]: encode ? encode(value) : value,
				},
				options: {
					replace: true,
				},
			});
		},
		[
			encode,
			name,
			navigation,
			value,
		],
	);
}

export default useWriteQuerystring;
