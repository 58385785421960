import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderScoreInfo from '~/components/atoms/screenLayouts/components/header/HeaderScoreInfo';
import HeaderTitle from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';

import useGetIssueCategory from '~/hooks/useGetIssueCategory';
import usePlatformHealth from '~/hooks/usePlatformHealth';
import usePlatformIssueCategories from '~/hooks/usePlatformIssueCategories';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';

import {
	platformScreenIssuesCategoryTypeSelector,
} from '~/state/ui/content/selectors';

import {
	animateHeartbeatSelector,
	hoveredCategorySelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	title: {
		id: 'ui.platform.title',
	},
	scoreLabel: {
		id: 'ui.platform.score.label',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const HeaderContent: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const animateHeartbeat = useSelector(animateHeartbeatSelector);
	const hoveredIssueCategoryName = useSelector(hoveredCategorySelector);
	const isCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId);
	const openedIssueCategoryName = useSelector(platformScreenIssuesCategoryTypeSelector);
	const platformHealth = usePlatformHealth(websiteId);
	const platformIssueCategories = usePlatformIssueCategories(websiteId);

	const hoveredIssueCategory = useGetIssueCategory(
		platformIssueCategories,
		hoveredIssueCategoryName ?? null,
	);

	const openedIssueCategory = useGetIssueCategory(
		platformIssueCategories,
		openedIssueCategoryName ?? null,
	);

	function renderHealth() {
		if (platformHealth === null) {
			return null;
		}

		let scoreImpact = 0;

		if (hoveredIssueCategory !== null) {
			scoreImpact = hoveredIssueCategory.pointsToGain + platformHealth;
		} else if (openedIssueCategory !== null) {
			scoreImpact = openedIssueCategory.pointsToGain + platformHealth;
		}

		return (
			<HeaderOptionsLayout key="health">
				<HeaderScoreInfo
					animateHeartbeat={animateHeartbeat}
					impact={scoreImpact}
					label={(
						<FormattedMessage {...messages.scoreLabel} />
					)}
					maximum={1000}
					paused={isCrawlingPaused === true}
					value={platformHealth}
				/>
			</HeaderOptionsLayout>
		);
	}

	return (
		<>
			<HeaderTitleLayout key="title">
				<HeaderTitle>
					<FormattedMessage {...messages.title} />
				</HeaderTitle>
			</HeaderTitleLayout>

			{renderHealth()}
		</>
	);
};



export default React.memo(HeaderContent);
