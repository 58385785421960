import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class AuthFormRow extends Component {

	render() {
		const {
			children,
			id,
			label,
			sublabel,
		} = this.props;

		return (
			<div className="auth-form-row">
				<div className="auth-form-row__header">
					<label
						className="auth-form-row__label"
						htmlFor={id}
					>
						{label}
					</label>
					{sublabel && (
						<div className="auth-form-row__sublabel">
							{sublabel}
						</div>
					)}
				</div>
				<div className="auth-form-row__field">
					{children}
				</div>
			</div>
		);
	}

}

AuthFormRow.propTypes = {
	/** ID of form fields to connect label with possible fields in children */
	id: PropTypes.string,
	/** Form row label */
	label: PropTypes.node.isRequired,
	/** Additional sublabel */
	sublabel: PropTypes.node,
};



export default AuthFormRow;
