import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ThumbsUpIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-thumbs-up">
					<g id="thumbs-up">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<polygon
							id="Stroke-199"
							points="8.24625 19.4985 4.5 19.4985 4.5 8.9985 8.24625 8.9985"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</polygon>
						<path
							d="M8.2527,17.9973 L9.4227,17.9973 C10.1022,17.9973 10.75395,18.2673 11.23395,18.74805 L11.23395,18.74805 C11.71395,19.22805 12.3657,19.49805 13.0452,19.49805 L17.5332,19.49805 C18.65145,19.49805 19.59945,18.67755 19.75995,17.5713 L20.6307,11.5713 C20.82795,10.21455 19.77495,8.99805 18.40395,8.99805 L14.23545,8.99805 L15.1047,5.9568 C15.4212,4.8498 14.5902,3.74805 13.43895,3.74805 L13.43895,3.74805 C12.62745,3.74805 11.9292,4.30905 11.7492,5.1003 C11.4642,6.35355 10.8747,8.1468 9.73395,8.99805 L9.7362,8.99805 L8.2527,8.99805"
							id="Stroke-200"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ThumbsUpIconBuilder;

