import React from 'react';

import CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ColumnFilterFormatter from '~/components/logic/filters/ColumnFilterFormatter';
import SegmentFilterOperator, {
	OPERATOR_AND,
	OPERATOR_AND_NOT,
	OPERATOR_NOT,
	OPERATOR_OR,
} from '~/components/atoms/segments/filters/SegmentFilterOperator';
import SegmentFilterSegment from '~/components/atoms/segments/filters/SegmentFilterSegment';
import SegmentFilterValue from '~/components/atoms/segments/filters/SegmentFilterValue';
import SizeLimit from '~/components/logic/filters/SizeLimit';
import TableSegmentFilterLayout from '~/components/atoms/segments/filters/TableSegmentFilterLayout';
import WithSeparator from '~/components/atoms/WithSeparator';

import {
	type SegmentDefinition,
	filterSegmentDefinitionsByNames,
} from '~/model/segments';



type Operator =
	| typeof OPERATOR_AND
	| typeof OPERATOR_AND_NOT
	| typeof OPERATOR_NOT
	| typeof OPERATOR_OR;



type Props = {
	columnName: CK.PagesColumn,
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	segmentFilter: any,
};

const ColumnSegmentFilter: React.FC<Props> = (props) => {
	const {
		columnName,
		segmentDefinitions,
		segmentFilter,
	} = props;

	const segmentFilterRaw = segmentFilter.toJS();

	const renderValue = (segment: SegmentDefinition) => {
		const values: Array<React.ReactElement> = [];

		if (columnName in segment.filterDefinition) {
			values.push(
				<ColumnFilterFormatter
					columnName={columnName}
					key="filter"
					value={segment.filterDefinition[columnName]}
				/>,
			);
		}

		if (segment.sizeLimit?.field === columnName) {
			values.push(
				<SizeLimit
					key="sizeLimit"
					sizeLimitDefinition={segment.sizeLimit}
				/>,
			);
		}

		const value = (
			<WithSeparator separator=", ">
				{values}
			</WithSeparator>
		);

		if (columnName === CK.PagesCommonColumn.Segments) {
			return (
				<SegmentFilterSegment
					color={segment.color}
					key={segment.name}
				>
					{value}
				</SegmentFilterSegment>
			);
		}

		return (
			<SegmentFilterValue
				color={segment.color}
				key={segment.name}
				minWidth={30}
			>
				{value}
			</SegmentFilterValue>
		);
	};

	const renderSegments = (operator: Operator, segments: ReadonlyArray<SegmentDefinition>) => {
		return (
			<WithSeparator
				separator={(
					<SegmentFilterOperator operator={operator} />
				)}
				separatorComponent={false}
			>
				{segments.map((segment) => renderValue(segment))}
			</WithSeparator>
		);
	};

	const filterDependingOnColumn = (segmentDefinition: SegmentDefinition) => (
		columnName in segmentDefinition.filterDefinition
		|| segmentDefinition.sizeLimit?.field === columnName
	);

	const includedSegments = filterSegmentDefinitionsByNames(
		segmentDefinitions,
		segmentFilterRaw.included_in,
	).filter(filterDependingOnColumn);

	const notIncludedSegments = filterSegmentDefinitionsByNames(
		segmentDefinitions,
		segmentFilterRaw.not_included_in,
	).filter(filterDependingOnColumn);

	if ((includedSegments.length + notIncludedSegments.length) === 0) {
		return (
			<TableSegmentFilterLayout />
		);
	}

	return (
		<TableSegmentFilterLayout
			icon={(
				<BasicIcon
					size={14}
					type={BasicIconType.Segment}
				/>
			)}
		>
			{renderSegments(
				segmentFilterRaw.operator === 'and' ? OPERATOR_AND : OPERATOR_OR,
				includedSegments,
			)}

			{notIncludedSegments.length > 0 && (
				<SegmentFilterOperator
					operator={includedSegments.length > 0 ? OPERATOR_AND_NOT : OPERATOR_NOT}
				/>
			)}

			{renderSegments(
				OPERATOR_AND_NOT,
				notIncludedSegments,
			)}
		</TableSegmentFilterLayout>
	);
};



export default React.memo(ColumnSegmentFilter);
