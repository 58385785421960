import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ChatPlatformIntegrationsIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M8.0856 12.4824H3.8356C3.47065 12.4872 3.12224 12.6354 2.8656 12.8949C2.73794 13.0226 2.63668 13.1743 2.56762 13.3411C2.49856 13.508 2.46304 13.6868 2.4631 13.8674V30.3949C2.46056 30.4644 2.48075 30.5329 2.5206 30.5899C2.55888 30.6472 2.61329 30.6917 2.67694 30.718C2.74059 30.7443 2.81061 30.751 2.8781 30.7374C2.94564 30.7233 3.00743 30.6893 3.0556 30.6399L7.0006 26.7049H19.6906C19.8718 26.7043 20.0511 26.6677 20.2181 26.5974C20.3861 26.5275 20.5389 26.4256 20.6681 26.2974C20.9257 26.0362 21.0703 25.6843 21.0706 25.3174V18.3024"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M6.44531 16.23H8.42281"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M6.5 19.4075H16.9525"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M6.5 22.5125H16.9525"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M10.3379 9.91745V15.095C10.3384 15.3688 10.4195 15.6364 10.5711 15.8645C10.7227 16.0926 10.9381 16.271 11.1904 16.3775C11.3586 16.4468 11.5385 16.4833 11.7204 16.485H24.4129L28.3429 20.4174C28.3904 20.4678 28.4525 20.5019 28.5204 20.5149C28.5883 20.5298 28.6591 20.5236 28.7234 20.4973C28.7877 20.4709 28.8424 20.4257 28.8804 20.3675C28.9182 20.3094 28.9381 20.2417 28.9379 20.1725V3.64745C28.9352 3.28256 28.7909 2.93299 28.5354 2.67245C28.2782 2.41256 27.9285 2.26514 27.5629 2.26245H11.7229C11.3558 2.26311 11.0039 2.40924 10.7443 2.66884C10.4847 2.92843 10.3386 3.28033 10.3379 3.64745V5.87245"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M18.0703 6H24.9653"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M18.0703 9.1875H24.9028"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.4551 12.29H24.9026"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M3.18262 7.79492H16.4326"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.3105 5.67505L16.433 7.79505L14.3105 9.91755"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default ChatPlatformIntegrationsIconBuilder;

