import React from 'react';

import useCurrentColumns from '~/hooks/useCurrentColumns';



function useEnabledColumnsList() {
	const currentColumns = useCurrentColumns();

	return React.useMemo(
		() => {
			return currentColumns
				.filter((column) => column.enabled)
				.map((column) => column.name);
		},
		[
			currentColumns,
		],
	);
}



export default useEnabledColumnsList;
