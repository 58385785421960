import Immutable from 'immutable';
import React from 'react';
import {
	useIntl,
} from 'react-intl';

import AttachedChartLegend, {
	AttachedChartLegendAlignment,
} from '~/components/patterns/charts/structures/AttachedChartLegend';
import ChartContainer from '~/components/atoms/charts/components/ChartContainer';
import ChartLegend from '~/components/patterns/charts/components/ChartLegend';
import ColumnChart from '~/components/atoms/charts/charts/ColumnChart';
import NoChangesMessage from './NoChangesMessage';

import useManuallyTrackedLoading from '~/hooks/useManuallyTrackedLoading';
import useViewportType from '~/hooks/useViewportType';

import {
	calculateTickIntervalNew,
} from '~/model/websiteStatistics/charts/utilities';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import sum from '~/utilities/sum';



type Category = {
	color: string,
	label: string,
	name: string,
};

type DataItem = {
	changes: Record<string, number>,
	endDate: string,
	startDate: string,
};

type OnBarClickCallbackInput = {
	changeType: string,
	endDate: string,
	startDate: string,
};

type Props = {
	categories: Array<Category>,
	data: Array<DataItem>,
	onBarClickCallback: (input: OnBarClickCallbackInput) => void,
};

const ChangesChart: React.FC<Props> = (props) => {
	const {
		categories,
		data,
		onBarClickCallback,
	} = props;

	const intl = useIntl();
	const manuallyTrackedLoading = useManuallyTrackedLoading();
	const viewportType = useViewportType();

	const categoryTitles = React.useMemo(
		() => {
			const result = {};

			categories.forEach((category) => {
				result[category.name] = category.label;
			});

			return result;
		},
		[
			categories,
		],
	);

	const chartData = React.useMemo(
		() => {
			return {
				data: categories.map((category) => ({
					name: category.name,
					data: data.map((item) => item.changes[category.name] ?? 0),
				})),
				legendItems: categories.map((category) => ({
					color: category.color,
					label: category.label,
				})),
				seriesColors: categories.map((category) => category.color),
				xAxisCaptions: Immutable.List(
					data.map((item) => {
						if (item.startDate === item.endDate) {
							return intl.formatDate(item.endDate, {
								day: 'numeric',
								month: 'short',
								timeZone: 'Europe/Amsterdam',
							});
						}

						return intl.formatDate(item.startDate, {
							day: 'numeric',
							month: 'short',
							timeZone: 'Europe/Amsterdam',
						}) + ' -<br/>' + intl.formatDate(item.endDate, {
							day: 'numeric',
							month: 'short',
							timeZone: 'Europe/Amsterdam',
						});
					}),
				),
			};
		},
		[
			categories,
			data,
			intl,
		],
	);

	const renderTooltip = React.useCallback(
		({ count, seriesName }) => {
			return categoryTitles[seriesName] + ': ' + intl.formatNumber(count);
		},
		[
			categoryTitles,
			intl,
		],
	);

	const handleClickCallback = React.useCallback(
		(pointIndex, changeType) => {
			const {
				endDate,
				startDate,
			} = getArrayItemAtSafeIndex(data, pointIndex);

			onBarClickCallback({
				changeType,
				endDate,
				startDate,
			});
		},
		[
			data,
			onBarClickCallback,
		],
	);

	const handleRenderCallback = React.useCallback(
		() => {
			if (manuallyTrackedLoading !== null) {
				manuallyTrackedLoading.finish();
			}
		},
		[
			manuallyTrackedLoading,
		],
	);

	const numberOfYAxisPoints = 4;

	const totalNumberOfChanges = sum(chartData.data, (category) => sum(category.data));

	return (
		<AttachedChartLegend
			legend={(
				<ChartLegend
					inline={true}
					items={chartData.legendItems}
				/>
			)}
			legendAlignment={AttachedChartLegendAlignment.Bottom}
			stretched={true}
		>
			<ChartContainer
				chart={(
					<ColumnChart
						animate={false}
						barWidth={10}
						data={chartData.data}
						maximum={({ maxValue }) => calculateTickIntervalNew(maxValue * 1.1, numberOfYAxisPoints)}
						minBarLength={3}
						minimum={0}
						onClickCallback={handleClickCallback}
						onRenderCallback={handleRenderCallback}
						renderTooltipCallback={renderTooltip}
						seriesColors={chartData.seriesColors}
						viewportType={viewportType}
						xAxisCaptions={chartData.xAxisCaptions}
						yAxisPoints={numberOfYAxisPoints}
					/>
				)}
				height={120}
				name="page-changes"
				overlay={totalNumberOfChanges === 0 && (
					<NoChangesMessage />
				)}
				padded={true}
				type="column-chart"
			/>
		</AttachedChartLegend>
	);
};



export default ChangesChart;
