/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkDeleteAlertDefinitionsMutationVariables = GraphQL.Exact<{
  alertDefinitionIds: ReadonlyArray<GraphQL.Scalars['AlertDefinitionId']['input']> | GraphQL.Scalars['AlertDefinitionId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type BulkDeleteAlertDefinitionsMutation = { readonly __typename?: 'Mutation', readonly BulkDeleteAlertDefinitions: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly alertDefinitions: ReadonlyArray<{ readonly __typename?: 'AlertDefinition', readonly alertType: string, readonly id: CK.AlertDefinitionId, readonly recipients: ReadonlyArray<string>, readonly scope: string, readonly settings: Record<string, any>, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType }> }> } | null } } };


export const BulkDeleteAlertDefinitionsDocument = gql`
    mutation BulkDeleteAlertDefinitions($alertDefinitionIds: [AlertDefinitionId!]!, $websiteId: WebsiteId!) {
  BulkDeleteAlertDefinitions(
    alertDefinitionIds: $alertDefinitionIds
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        alertDefinitions {
          alertType
          id
          messagingAppChannels {
            id
            label
            messagingAppType
          }
          recipients
          scope
          settings
        }
        id
      }
    }
  }
}
    `;
export type BulkDeleteAlertDefinitionsMutationFn = Apollo.MutationFunction<BulkDeleteAlertDefinitionsMutation, BulkDeleteAlertDefinitionsMutationVariables>;

/**
 * __useBulkDeleteAlertDefinitionsMutation__
 *
 * To run a mutation, you first call `useBulkDeleteAlertDefinitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteAlertDefinitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteAlertDefinitionsMutation, { data, loading, error }] = useBulkDeleteAlertDefinitionsMutation({
 *   variables: {
 *      alertDefinitionIds: // value for 'alertDefinitionIds'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useBulkDeleteAlertDefinitionsMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteAlertDefinitionsMutation, BulkDeleteAlertDefinitionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteAlertDefinitionsMutation, BulkDeleteAlertDefinitionsMutationVariables>(BulkDeleteAlertDefinitionsDocument, options);
      }
export type BulkDeleteAlertDefinitionsMutationHookResult = ReturnType<typeof useBulkDeleteAlertDefinitionsMutation>;
export type BulkDeleteAlertDefinitionsMutationResult = Apollo.MutationResult<BulkDeleteAlertDefinitionsMutation>;
export type BulkDeleteAlertDefinitionsMutationOptions = Apollo.BaseMutationOptions<BulkDeleteAlertDefinitionsMutation, BulkDeleteAlertDefinitionsMutationVariables>;