import useBatchContext from '~/hooks/useBatchContext';
import usePollInterval from '~/hooks/usePollInterval';



function useUserPropertiesQuery<TQueryHookReturnType>(
	queryHook: (options: any) => TQueryHookReturnType,
	legacyUserId: string | null,
	options: { poll: boolean, skip?: boolean },
): TQueryHookReturnType {
	const pollInterval = usePollInterval(30_000);

	return queryHook({
		context: useBatchContext(
			`userProperties/${legacyUserId ?? ''}`,
			999,
		),
		pollInterval: options.poll ? pollInterval : undefined,
		skip: legacyUserId === null || options.skip === true,
		variables: {
			legacyUserId: legacyUserId ?? '',
		},
	});
}



export default useUserPropertiesQuery;
