import React from 'react';

import useIsCurrentUser from './useIsCurrentUser';
import useNavigation from './useNavigation';



function useNavigateToUserDetail() {
	const isCurrentUser = useIsCurrentUser();
	const navigation = useNavigation();

	const createRoute = React.useCallback(
		(email) => {
			const emailIsCurrentUser = isCurrentUser(email);

			if (emailIsCurrentUser === null) {
				return;
			}

			if (emailIsCurrentUser === false) {
				return navigation.createRoute({
					keepParameters: true,
					routeName: 'account.members.member',
					routeParams: {
						memberDetailId: encodeURIComponent(email),
					},
				});
			}

			return navigation.createRoute({
				routeName: 'userProfile',
				routeParams: {},
			});
		},
		[
			isCurrentUser,
			navigation,
		],
	);

	const navigate = React.useCallback(
		(email, e, after) => {
			const route = createRoute(email);

			if (route === null) {
				return;
			}

			navigation.navigate({
				after,
				e,
				keepParameters: true,
				routeName: route.name,
				routeParams: route.params,
			});
		},
		[
			createRoute,
			navigation,
		],
	);

	return {
		createRoute,
		navigate,
	};
}



export default useNavigateToUserDetail;
