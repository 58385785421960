export const ACTION_CLEAR_FLASH_MESSAGE = 'ACTION_CLEAR_FLASH_MESSAGE';
export const ACTION_SET_FLASH_MESSAGE = 'ACTION_SET_FLASH_MESSAGE';

export const STYLE_CRITICAL = 'critical';
export const STYLE_SUCCESS = 'success';
export const STYLE_WARNING = 'warning';



export function clearFlashMessage() {
	return {
		type: ACTION_CLEAR_FLASH_MESSAGE,
	};
}



export function setFlashMessage({
	message,
	messageVariables = {},
	route,
	style,
}) {
	return {
		type: ACTION_SET_FLASH_MESSAGE,
		message,
		messageVariables,
		route,
		style,
	};
}
