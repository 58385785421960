import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import DestructConfirmationModal from '~/components/app/DestructConfirmationModal';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';

import useRemoveAccessToClient from '~/hooks/useRemoveAccessToClient';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	confirmButton: {
		id: 'ui.removeAccessToConnectedAccountConfirmation.confirmButton',
	},
	description: {
		id: 'ui.removeAccessToConnectedAccountConfirmation.description',
	},
	labelConnectedAccount: {
		id: 'ui.removeAccessToConnectedAccountConfirmation.labelConnectedAccount',
	},
	title: {
		id: 'ui.removeAccessToConnectedAccountConfirmation.title',
	},
	verb: {
		id: 'ui.removeAccessToConnectedAccountConfirmation.verb',
	},
	warnPermanent: {
		id: 'ui.removeAccessToConnectedAccountConfirmation.warnPermanent',
	},
});



type Props = {
	agencyAccountId: CK.AccountId,
	connectedAccountId: CK.AccountId,
	connectedAccountName: string,
};

const RemoveAccessToConnectedAccountConfirmationModal: React.FC<Props> = (props) => {
	const {
		agencyAccountId,
		connectedAccountId,
		connectedAccountName,
	} = props;

	const removeAccessToClient = useRemoveAccessToClient();

	const handleRemoval = React.useCallback(
		async () => {
			try {
				await removeAccessToClient({
					agencyId: agencyAccountId,
					clientId: connectedAccountId,
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}
		},
		[
			agencyAccountId,
			connectedAccountId,
			removeAccessToClient,
		],
	);

	return (
		<DestructConfirmationModal
			confirmButton={(
				<FormattedMessage {...messages.confirmButton} />
			)}
			confirmationPhrase={connectedAccountName}
			description={(
				<FormattedMessage {...messages.description} />
			)}
			onConfirmation={handleRemoval}
			titleAction={(
				<FormattedMessage {...messages.verb} />
			)}
			titleObject={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Copy {...messages.warnPermanent} />

			<LabeledValuesBox
				items={[
					{
						label: (
							<FormattedMessage {...messages.labelConnectedAccount} />
						),
						value: connectedAccountName,
					},
				]}
			/>
		</DestructConfirmationModal>
	);
};



export default RemoveAccessToConnectedAccountConfirmationModal;
