/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GoogleAnalyticsIntegrationQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type GoogleAnalyticsIntegrationQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly googleAnalyticsVisibleColumns: ReadonlyArray<GraphQL.GoogleAnalyticsVersion>, readonly googleAnalyticsIntegration: { readonly __typename?: 'WebsiteGoogleAnalyticsIntegration', readonly ownerAccountId: CK.AccountId, readonly propertyId: string, readonly version: GraphQL.GoogleAnalyticsVersion, readonly viewId: string | null, readonly googleAnalyticsAccount: { readonly __typename?: 'GoogleAnalyticsAccount', readonly id: CK.ID, readonly name: string, readonly properties: ReadonlyArray<{ readonly __typename?: 'GoogleAnalyticsProperty', readonly id: CK.ID, readonly name: string, readonly version: GraphQL.GoogleAnalyticsVersion, readonly views: ReadonlyArray<{ readonly __typename?: 'GoogleAnalyticsView', readonly id: CK.ID, readonly name: string }> | null }> } } | null } | null };


export const GoogleAnalyticsIntegrationDocument = gql`
    query GoogleAnalyticsIntegration($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    googleAnalyticsIntegration {
      googleAnalyticsAccount {
        id
        name
        properties {
          id
          name
          version
          views {
            id
            name
          }
        }
      }
      ownerAccountId
      propertyId
      version
      viewId
    }
    googleAnalyticsVisibleColumns
  }
}
    `;

/**
 * __useGoogleAnalyticsIntegrationQuery__
 *
 * To run a query within a React component, call `useGoogleAnalyticsIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleAnalyticsIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleAnalyticsIntegrationQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useGoogleAnalyticsIntegrationQuery(baseOptions: Apollo.QueryHookOptions<GoogleAnalyticsIntegrationQuery, GoogleAnalyticsIntegrationQueryVariables> & ({ variables: GoogleAnalyticsIntegrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleAnalyticsIntegrationQuery, GoogleAnalyticsIntegrationQueryVariables>(GoogleAnalyticsIntegrationDocument, options);
      }
export function useGoogleAnalyticsIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleAnalyticsIntegrationQuery, GoogleAnalyticsIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleAnalyticsIntegrationQuery, GoogleAnalyticsIntegrationQueryVariables>(GoogleAnalyticsIntegrationDocument, options);
        }
export function useGoogleAnalyticsIntegrationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GoogleAnalyticsIntegrationQuery, GoogleAnalyticsIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GoogleAnalyticsIntegrationQuery, GoogleAnalyticsIntegrationQueryVariables>(GoogleAnalyticsIntegrationDocument, options);
        }
export type GoogleAnalyticsIntegrationQueryHookResult = ReturnType<typeof useGoogleAnalyticsIntegrationQuery>;
export type GoogleAnalyticsIntegrationLazyQueryHookResult = ReturnType<typeof useGoogleAnalyticsIntegrationLazyQuery>;
export type GoogleAnalyticsIntegrationSuspenseQueryHookResult = ReturnType<typeof useGoogleAnalyticsIntegrationSuspenseQuery>;
export type GoogleAnalyticsIntegrationQueryResult = Apollo.QueryResult<GoogleAnalyticsIntegrationQuery, GoogleAnalyticsIntegrationQueryVariables>;