/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetNameDuringOnboardingMutationVariables = GraphQL.Exact<{
  firstName: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  lastName: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
}>;


export type SetNameDuringOnboardingMutation = { readonly __typename?: 'Mutation', readonly SetNameDuringOnboarding: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly user: { readonly __typename?: 'User', readonly firstName: string | null, readonly id: string, readonly lastName: string | null, readonly legacyId: string } } | null } } };


export const SetNameDuringOnboardingDocument = gql`
    mutation SetNameDuringOnboarding($firstName: String, $lastName: String) {
  SetNameDuringOnboarding(firstName: $firstName, lastName: $lastName) {
    query {
      authenticatedSession {
        user {
          firstName
          id
          lastName
          legacyId
        }
      }
    }
  }
}
    `;
export type SetNameDuringOnboardingMutationFn = Apollo.MutationFunction<SetNameDuringOnboardingMutation, SetNameDuringOnboardingMutationVariables>;

/**
 * __useSetNameDuringOnboardingMutation__
 *
 * To run a mutation, you first call `useSetNameDuringOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNameDuringOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNameDuringOnboardingMutation, { data, loading, error }] = useSetNameDuringOnboardingMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useSetNameDuringOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<SetNameDuringOnboardingMutation, SetNameDuringOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNameDuringOnboardingMutation, SetNameDuringOnboardingMutationVariables>(SetNameDuringOnboardingDocument, options);
      }
export type SetNameDuringOnboardingMutationHookResult = ReturnType<typeof useSetNameDuringOnboardingMutation>;
export type SetNameDuringOnboardingMutationResult = Apollo.MutationResult<SetNameDuringOnboardingMutation>;
export type SetNameDuringOnboardingMutationOptions = Apollo.BaseMutationOptions<SetNameDuringOnboardingMutation, SetNameDuringOnboardingMutationVariables>;