/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrioritizeUrlsByFilterMutationVariables = GraphQL.Exact<{
  filter: GraphQL.Scalars['Array']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PrioritizeUrlsByFilterMutation = { readonly __typename?: 'Mutation', readonly PrioritizeUrlsByFilter: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const PrioritizeUrlsByFilterDocument = gql`
    mutation PrioritizeUrlsByFilter($filter: Array!, $websiteId: WebsiteId!) {
  PrioritizeUrlsByFilter(filter: $filter, websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type PrioritizeUrlsByFilterMutationFn = Apollo.MutationFunction<PrioritizeUrlsByFilterMutation, PrioritizeUrlsByFilterMutationVariables>;

/**
 * __usePrioritizeUrlsByFilterMutation__
 *
 * To run a mutation, you first call `usePrioritizeUrlsByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrioritizeUrlsByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prioritizeUrlsByFilterMutation, { data, loading, error }] = usePrioritizeUrlsByFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePrioritizeUrlsByFilterMutation(baseOptions?: Apollo.MutationHookOptions<PrioritizeUrlsByFilterMutation, PrioritizeUrlsByFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrioritizeUrlsByFilterMutation, PrioritizeUrlsByFilterMutationVariables>(PrioritizeUrlsByFilterDocument, options);
      }
export type PrioritizeUrlsByFilterMutationHookResult = ReturnType<typeof usePrioritizeUrlsByFilterMutation>;
export type PrioritizeUrlsByFilterMutationResult = Apollo.MutationResult<PrioritizeUrlsByFilterMutation>;
export type PrioritizeUrlsByFilterMutationOptions = Apollo.BaseMutationOptions<PrioritizeUrlsByFilterMutation, PrioritizeUrlsByFilterMutationVariables>;