import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import {
	compileDefinitionTermsNew,
} from './compileDefinitionTerms';
import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import LimitedRecordsOutput from '~/components/atoms/records/LimitedRecordsOutput';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import usePageDetailSourceMode from '~/hooks/usePageDetailSourceMode';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	useContentBlockPrimaryQuery,
	useContentBlockSrcQuery,
} from './ContentBlock.gql';



const messages = defineMessages({
	contentDataH1: {
		id: 'ui.contentData.h1',
	},
	contentDataH2: {
		id: 'ui.contentData.h2',
	},
	contentDataH3: {
		id: 'ui.contentData.h3',
	},
	contentDataH4: {
		id: 'ui.contentData.h4',
	},
	contentDataH5: {
		id: 'ui.contentData.h5',
	},
	contentDataH6: {
		id: 'ui.contentData.h6',
	},
	contentDataMetaDescription: {
		id: 'ui.contentData.meta_description',
	},
	contentDataTitle: {
		id: 'ui.contentData.title',
	},
	h3ToH6HeadingsButtonHide: {
		id: 'ui.pageDetail.contentBlock.hideH3ToH6Headings',
	},
	h3ToH6HeadingsButtonShow: {
		id: 'ui.pageDetail.contentBlock.showH3ToH6Headings',
	},
	title: {
		id: 'ui.pageDetail.content.title',
	},
});



const ContentBlock = () => {
	const legacyUrlId = useLegacyUrlId();
	const pageDetailSourceMode = usePageDetailSourceMode();
	const websiteId = useWebsiteId();

	const {
		data: primaryData,
	} = usePageDetailPropertiesQuery(
		useContentBlockPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	const {
		data: srcData,
	} = usePageDetailPropertiesQuery(
		useContentBlockSrcQuery,
		legacyUrlId,
		websiteId,
		{
			skip: pageDetailSourceMode !== GraphQL.PageDetailSourceMode.Compare,
		},
	);

	const primaryPropertyData = primaryData?.lookupPageByLegacyId?.pageTypeData?.primaryProperties ?? null;
	const srcPropertyData = srcData?.lookupPageByLegacyId?.pageTypeData?.srcProperties ?? null;

	const hideH3ToH6 = (
		primaryPropertyData === null
		|| primaryPropertyData.h3.contentItems.length > 0
		|| primaryPropertyData.h4.contentItems.length > 0
		|| primaryPropertyData.h5.contentItems.length > 0
		|| primaryPropertyData.h6.contentItems.length > 0
	);

	return (
		<LimitedRecordsOutput
			limit={hideH3ToH6 ? 1 : 2}
			numberOfRecords={2}
			showLessMessageKey={messages.h3ToH6HeadingsButtonHide}
			showMoreMessageKey={messages.h3ToH6HeadingsButtonShow}
		>
			{({ numberOfRecords }) => {
				return (
					<BorderedBox
						headerIcon={(
							<AttributesGroupIcon
								size={28}
								type={AttributesGroupIconType.Content}
							/>
						)}
						headerLabel={(
							<FormattedMessage {...messages.title} />
						)}
						paddingSize={3}
					>
						<DefinitionTermsList>
							{compileDefinitionTermsNew(
								[
									{
										property: 'title',
										name: 'title',
										label: (
											<FormattedMessage {...messages.contentDataTitle} />
										),
										alwaysVisible: true,
									},
									{
										property: 'metaDescription',
										name: 'meta_description',
										label: (
											<FormattedMessage {...messages.contentDataMetaDescription} />
										),
										alwaysVisible: true,
									},
									{
										property: 'h1',
										name: 'h1',
										label: (
											<FormattedMessage {...messages.contentDataH1} />
										),
										alwaysVisible: true,
									},
									{
										property: 'h2',
										name: 'h2',
										label: (
											<FormattedMessage {...messages.contentDataH2} />
										),
										alwaysVisible: false,
									},
									{
										property: 'h3',
										name: 'h3',
										label: (
											<FormattedMessage {...messages.contentDataH3} />
										),
										alwaysVisible: false,
									},
									{
										property: 'h4',
										name: 'h4',
										label: (
											<FormattedMessage {...messages.contentDataH4} />
										),
										alwaysVisible: false,
									},
									{
										property: 'h5',
										name: 'h5',
										label: (
											<FormattedMessage {...messages.contentDataH5} />
										),
										alwaysVisible: false,
									},
									{
										property: 'h6',
										name: 'h6',
										label: (
											<FormattedMessage {...messages.contentDataH6} />
										),
										alwaysVisible: false,
									},
								],
								{
									compare: pageDetailSourceMode === GraphQL.PageDetailSourceMode.Compare,
									limit: (hideH3ToH6 && numberOfRecords < 2) ? 4 : null,
									propertyData: primaryPropertyData,
									srcPropertyData,
								},
							)}
						</DefinitionTermsList>
					</BorderedBox>
				);
			}}
		</LimitedRecordsOutput>
	);
};



export default React.memo(ContentBlock);
