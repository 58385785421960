import React from 'react';



type Props = {
	children?: React.ReactNode,
	color: React.CSSProperties['backgroundColor'],
};

const ChartTooltip: React.FC<Props> = (props) => {
	const {
		children,
		color,
	} = props;

	return (
		<div
			className="chart-tooltip"
			style={{ backgroundColor: color }}
		>
			{children}
		</div>
	);
};

export default ChartTooltip;
