import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



let chromeIconCounter = 1;



const ChromeIconBuilder: IconBuilder = {
	icon: () => {
		const componentId = chromeIconCounter++;

		return (
			<g>
				<defs>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-1-' + componentId}
						r="10.082875"
					>
					</circle>
					<linearGradient
						id={'linearGradient-3-' + componentId}
						x1="4.547%"
						x2="36.948%"
						y1="55.356%"
						y2="42.741%"
					>
						<stop
							offset="0%"
							stopColor="#A52714"
							stopOpacity="0.6"
						>
						</stop>
						<stop
							offset="66%"
							stopColor="#A52714"
							stopOpacity="0"
						>
						</stop>
					</linearGradient>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-4-' + componentId}
						r="10.082875"
					>
					</circle>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-6-' + componentId}
						r="10.082875"
					>
					</circle>
					<linearGradient
						id={'linearGradient-8-' + componentId}
						x1="70.025%"
						x2="41.909%"
						y1="87.773%"
						y2="66.355%"
					>
						<stop
							offset="0%"
							stopColor="#055524"
							stopOpacity="0.4"
						>
						</stop>
						<stop
							offset="33%"
							stopColor="#055524"
							stopOpacity="0"
						>
						</stop>
					</linearGradient>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-9-' + componentId}
						r="10.082875"
					>
					</circle>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-11-' + componentId}
						r="10.082875"
					>
					</circle>
					<polygon
						id={'path-13-' + componentId}
						points="0 18.3333333 9.59864583 18.3333333 14.0536458 13.8783333 14.0536458 10.5416667 6.11416667 10.5416667 0 0.055"
					>
					</polygon>
					<linearGradient
						id={'linearGradient-15-' + componentId}
						x1="40.978%"
						x2="49.247%"
						y1="-4.841%"
						y2="45.414%"
					>
						<stop
							offset="0%"
							stopColor="#EA6100"
							stopOpacity="0.3"
						>
						</stop>
						<stop
							offset="66%"
							stopColor="#EA6100"
							stopOpacity="0"
						>
						</stop>
					</linearGradient>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-16-' + componentId}
						r="10.082875"
					>
					</circle>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-18-' + componentId}
						r="10.082875"
					>
					</circle>
					<polygon
						id={'path-20-' + componentId}
						points="0.572916667 0 4.54322917 6.875 0.0882291667 14.6666667 10.65625 14.6666667 10.65625 0"
					>
					</polygon>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-22-' + componentId}
						r="10.082875"
					>
					</circle>
					<radialGradient
						cx="-4.90349916%"
						cy="-0.248%"
						fx="-4.90349916%"
						fy="-0.248%"
						gradientTransform="translate(-0.049035,-0.002480),scale(0.267219,1.000000),translate(0.049035,0.002480)"
						id={'radialGradient-24-' + componentId}
						r="401.327923%"
					>
						<stop
							offset="0%"
							stopColor="#3E2723"
							stopOpacity="0.2"
						>
						</stop>
						<stop
							offset="100%"
							stopColor="#3E2723"
							stopOpacity="0"
						>
						</stop>
					</radialGradient>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-25-' + componentId}
						r="10.082875"
					>
					</circle>
					<polygon
						id={'path-27-' + componentId}
						points="0.111145833 0 0.111145833 4.62229167 4.62458333 12.375 8.59375 5.5 18.6770833 5.5 18.6770833 0"
					>
					</polygon>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-29-' + componentId}
						r="10.082875"
					>
					</circle>
					<radialGradient
						cx="-0.166%"
						cy="0.08814208%"
						fx="-0.166%"
						fy="0.08814208%"
						gradientTransform="translate(-0.001660,0.000881),scale(1.000000,0.847520),translate(0.001660,-0.000881)"
						id={'radialGradient-31-' + componentId}
						r="136.321%"
					>
						<stop
							offset="0%"
							stopColor="#3E2723"
							stopOpacity="0.2"
						>
						</stop>
						<stop
							offset="100%"
							stopColor="#3E2723"
							stopOpacity="0"
						>
						</stop>
					</radialGradient>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-32-' + componentId}
						r="10.082875"
					>
					</circle>
					<radialGradient
						cx="10.34%"
						cy="-12.2637703%"
						fx="10.34%"
						fy="-12.2637703%"
						gradientTransform="translate(0.103400,-0.122638),scale(1.000000,0.496419),translate(-0.103400,0.122638)"
						id={'radialGradient-34-' + componentId}
						r="226.352%"
					>
						<stop
							offset="0%"
							stopColor="#263238"
							stopOpacity="0.2"
						>
						</stop>
						<stop
							offset="100%"
							stopColor="#263238"
							stopOpacity="0"
						>
						</stop>
					</radialGradient>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-35-' + componentId}
						r="10.082875"
					>
					</circle>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-37-' + componentId}
						r="10.082875"
					>
					</circle>
					<circle
						cx="10.082875"
						cy="10.082875"
						id={'path-39-' + componentId}
						r="10.082875"
					>
					</circle>
					<radialGradient
						cx="14.935%"
						cy="13.644%"
						fx="14.935%"
						fy="13.644%"
						id={'radialGradient-41-' + componentId}
						r="100.424%"
					>
						<stop
							offset="0%"
							stopColor="#FFFFFF"
							stopOpacity="0.1"
						>
						</stop>
						<stop
							offset="100%"
							stopColor="#FFFFFF"
							stopOpacity="0"
						>
						</stop>
					</radialGradient>
				</defs>
				<g
					fill="none"
					fillRule="evenodd"
					id="Page-1"
					stroke="none"
					strokeWidth="1"
				>
					<g
						id="chrome-small"
						transform="translate(1.000000, 1.000000)"
					>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-2-' + componentId}
								>
									<use xlinkHref={'#path-1-' + componentId}></use>
								</mask>
								<g id="a"></g>
								<g
									fillRule="nonzero"
									id="Group"
									mask={'url(#mask-2-' + componentId + ')'}
								>
									<g
										id="Path"
										transform="translate(1.489125, 0.000000)"
									>
										<polygon
											fill="#DB4437"
											points="0.111145833 0 0.111145833 12.375 4.62458333 12.375 8.59375 5.5 18.6770833 5.5 18.6770833 0"
										>
										</polygon>
										<polygon
											fill={'url(#linearGradient-3-' + componentId + ')'}
											points="0.111145833 0 0.111145833 12.375 4.62458333 12.375 8.59375 5.5 18.6770833 5.5 18.6770833 0"
										>
										</polygon>
									</g>
								</g>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-5-' + componentId}
								>
									<use xlinkHref={'#path-4-' + componentId}></use>
								</mask>
								<g id="d"></g>
								<polygon
									fill="#3E2723"
									fillOpacity="0.15"
									fillRule="nonzero"
									id="Path"
									mask={'url(#mask-5-' + componentId + ')'}
									points="6.22302083 12.3348958 1.65916667 4.50770833 1.59270833 4.62229167 6.12333333 12.3910417"
								>
								</polygon>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-7-' + componentId}
								>
									<use xlinkHref={'#path-6-' + componentId}></use>
								</mask>
								<g id="f"></g>
								<g
									fillRule="nonzero"
									id="Group"
									mask={'url(#mask-7-' + componentId + ')'}
								>
									<g
										id="Path"
										transform="translate(0.000000, 1.832875)"
									>
										<polygon
											fill="#0F9D58"
											points="0 18.3333333 9.59864583 18.3333333 14.0536458 13.8783333 14.0536458 10.5416667 6.11416667 10.5416667 0 0.055"
										>
										</polygon>
										<polygon
											fill={'url(#linearGradient-8-' + componentId + ')'}
											points="0 18.3333333 9.59864583 18.3333333 14.0536458 13.8783333 14.0536458 10.5416667 6.11416667 10.5416667 0 0.055"
										>
										</polygon>
									</g>
								</g>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-10-' + componentId}
								>
									<use xlinkHref={'#path-9-' + componentId}></use>
								</mask>
								<g id="i"></g>
								<polygon
									fill="#263238"
									fillOpacity="0.15"
									fillRule="nonzero"
									id="Path"
									mask={'url(#mask-10-' + componentId + ')'}
									points="13.9608333 12.5273958 13.8657292 12.4723958 9.466875 20.1666667 9.59864583 20.1666667 13.9642708 12.5308333"
								>
								</polygon>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-12-' + componentId}
								>
									<use xlinkHref={'#path-11-' + componentId}></use>
								</mask>
								<g id="k"></g>
								<g
									id="Group"
									mask={'url(#mask-12-' + componentId + ')'}
								>
									<g
										id="Clipped"
										transform="translate(0.000000, 1.832875)"
									>
										<g>
											<mask
												fill="white"
												id={'mask-14-' + componentId}
											>
												<use xlinkHref={'#path-13-' + componentId}></use>
											</mask>
											<g id="m"></g>
											<g
												fillRule="nonzero"
												id="Group"
												mask={'url(#mask-14-' + componentId + ')'}
											>
												<g
													id="Path"
													transform="translate(9.510875, 3.667125)"
												>
													<polygon
														fill="#FFCD40"
														points="0.572916667 0 4.54322917 6.875 0.0882291667 14.6666667 10.65625 14.6666667 10.65625 0"
													>
													</polygon>
													<polygon
														fill={'url(#linearGradient-15-' + componentId + ')'}
														points="0.572916667 0 4.54322917 6.875 0.0882291667 14.6666667 10.65625 14.6666667 10.65625 0"
													>
													</polygon>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-17-' + componentId}
								>
									<use xlinkHref={'#path-16-' + componentId}></use>
								</mask>
								<g id="p"></g>
								<g
									fillRule="nonzero"
									id="Group"
									mask={'url(#mask-17-' + componentId + ')'}
								>
									<g
										id="Path"
										transform="translate(9.510875, 5.500000)"
									>
										<polygon
											fill="#FFCD40"
											points="0.572916667 0 4.54322917 6.875 0.0882291667 14.6666667 10.65625 14.6666667 10.65625 0"
										>
										</polygon>
										<polygon
											fill={'url(#linearGradient-15-' + componentId + ')'}
											points="0.572916667 0 4.54322917 6.875 0.0882291667 14.6666667 10.65625 14.6666667 10.65625 0"
										>
										</polygon>
									</g>
								</g>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-19-' + componentId}
								>
									<use xlinkHref={'#path-18-' + componentId}></use>
								</mask>
								<g id="r"></g>
								<g
									id="Group"
									mask={'url(#mask-19-' + componentId + ')'}
								>
									<g
										id="Clipped"
										transform="translate(9.510875, 5.500000)"
									>
										<g>
											<mask
												fill="white"
												id={'mask-21-' + componentId}
											>
												<use xlinkHref={'#path-20-' + componentId}></use>
											</mask>
											<g id="t"></g>
											<g
												fillRule="nonzero"
												id="Group"
												mask={'url(#mask-21-' + componentId + ')'}
											>
												<g
													id="Path"
													transform="translate(-8.020375, -5.500000)"
												>
													<polygon
														fill="#DB4437"
														points="0.111145833 0 0.111145833 12.375 4.62458333 12.375 8.59375 5.5 18.6770833 5.5 18.6770833 0"
													>
													</polygon>
													<polygon
														fill={'url(#linearGradient-3-' + componentId + ')'}
														points="0.111145833 0 0.111145833 12.375 4.62458333 12.375 8.59375 5.5 18.6770833 5.5 18.6770833 0"
													>
													</polygon>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-23-' + componentId}
								>
									<use xlinkHref={'#path-22-' + componentId}></use>
								</mask>
								<g id="v"></g>
								<polygon
									fill={'url(#radialGradient-24-' + componentId + ')'}
									fillRule="nonzero"
									id="Path"
									mask={'url(#mask-23-' + componentId + ')'}
									points="10.0833333 5.5 10.0833333 7.90052083 19.0666667 5.5"
								>
								</polygon>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-26-' + componentId}
								>
									<use xlinkHref={'#path-25-' + componentId}></use>
								</mask>
								<g id="y"></g>
								<g
									id="Group"
									mask={'url(#mask-26-' + componentId + ')'}
								>
									<g
										id="Clipped"
										transform="translate(1.489125, 0.000000)"
									>
										<g>
											<mask
												fill="white"
												id={'mask-28-' + componentId}
											>
												<use xlinkHref={'#path-27-' + componentId}></use>
											</mask>
											<g id="A"></g>
											<g
												fillRule="nonzero"
												id="Group"
												mask={'url(#mask-28-' + componentId + ')'}
											>
												<g
													id="Path"
													transform="translate(-1.489125, 1.832875)"
												>
													<polygon
														fill="#0F9D58"
														points="0 18.3333333 9.59864583 18.3333333 14.0536458 13.8783333 14.0536458 10.5416667 6.11416667 10.5416667 0 0.055"
													>
													</polygon>
													<polygon
														fill={'url(#linearGradient-8-' + componentId + ')'}
														points="0 18.3333333 9.59864583 18.3333333 14.0536458 13.8783333 14.0536458 10.5416667 6.11416667 10.5416667 0 0.055"
													>
													</polygon>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-30-' + componentId}
								>
									<use xlinkHref={'#path-29-' + componentId}></use>
								</mask>
								<g id="C"></g>
								<polygon
									fill={'url(#radialGradient-31-' + componentId + ')'}
									fillRule="nonzero"
									id="Path"
									mask={'url(#mask-30-' + componentId + ')'}
									points="1.60072917 4.63489583 8.160625 11.1936458 6.11416667 12.375"
								>
								</polygon>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-33-' + componentId}
								>
									<use xlinkHref={'#path-32-' + componentId}></use>
								</mask>
								<g id="F"></g>
								<polygon
									fill={'url(#radialGradient-34-' + componentId + ')'}
									fillRule="nonzero"
									id="Path"
									mask={'url(#mask-33-' + componentId + ')'}
									points="9.60552083 20.1540625 12.0071875 11.1936458 14.0536458 12.375"
								>
								</polygon>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-36-' + componentId}
								>
									<use xlinkHref={'#path-35-' + componentId}></use>
								</mask>
								<g id="I"></g>
								<g
									fillRule="nonzero"
									id="Group"
									mask={'url(#mask-36-' + componentId + ')'}
								>
									<g
										id="Oval"
										transform="translate(5.500000, 5.500000)"
									>
										<circle
											cx="4.582875"
											cy="4.582875"
											fill="#F1F1F1"
											r="4.582875"
										>
										</circle>
										<circle
											cx="4.582875"
											cy="4.582875"
											fill="#4285F4"
											r="3.667125"
										>
										</circle>
									</g>
								</g>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-38-' + componentId}
								>
									<use xlinkHref={'#path-37-' + componentId}></use>
								</mask>
								<g id="K"></g>
								<g
									fillRule="nonzero"
									id="Group"
									mask={'url(#mask-38-' + componentId + ')'}
								>
									<g
										id="Path"
										transform="translate(0.000000, 1.375000)"
									>
										<path
											d="M10.082875,4.010875 C7.55182303,4.010875 5.5,6.06269803 5.5,8.59375 L5.5,8.707875 C5.5,6.17682303 7.55182303,4.125 10.082875,4.125 L20.167125,4.125 L20.167125,4.010875 L10.082875,4.010875 Z"
											fill="#3E2723"
											fillOpacity="0.2"
										>
										</path>
										<path
											d="M14.048375,11 C13.231126,12.4177863 11.7193388,13.2916245 10.082875,13.292125 C8.44690257,13.2911332 6.9357535,12.417361 6.11875,11 L6.114625,11 L0,0.512875 L0,0.628375 L6.114625,11.114125 L6.11875,11.114125 C5.01045292,13.0374028 5.44230406,15.4787248 7.14312743,16.9050908 C8.8439508,18.3314567 11.3231742,18.3314567 13.0239976,16.9050908 C14.7248209,15.4787248 15.1566721,13.0374028 14.048375,11.114125 L14.05525,11.114125 L14.05525,11 L14.048375,11 Z"
											fill="#FFFFFF"
											fillOpacity="0.1"
										>
										</path>
										<path
											d="M10.198375,4.125 L10.140625,4.12775 C12.6264459,4.18866582 14.6098876,6.22130785 14.6098876,8.707875 C14.6098876,11.1944422 12.6264459,13.2270842 10.140625,13.288 C10.159875,13.288 10.17775,13.292125 10.198375,13.292125 C12.7298067,13.292125 14.7819375,11.2399942 14.7819375,8.7085625 C14.7819375,6.17713083 12.7298067,4.125 10.198375,4.125 L10.198375,4.125 Z"
											fill="#3E2723"
											opacity="0.1"
										>
										</path>
										<path
											d="M14.09375,11.15125 C14.74039,10.0392297 14.8862511,8.70550019 14.49525,7.48 C14.8291011,8.66407006 14.6693865,9.9328584 14.0525,10.99725 L14.0525,11 L9.5975,18.79075 L9.730875,18.79075 L14.0965,11.155375 L14.09375,11.15125 L14.09375,11.15125 Z"
											fill="#FFFFFF"
											fillOpacity="0.2"
										>
										</path>
									</g>
								</g>
							</g>
						</g>
						<g
							id="Group"
							transform="translate(0.917125, 0.917125)"
						>
							<g id="Clipped">
								<mask
									fill="white"
									id={'mask-40-' + componentId}
								>
									<use xlinkHref={'#path-39-' + componentId}></use>
								</mask>
								<g id="M"></g>
								<g
									fillRule="nonzero"
									id="Group"
									mask={'url(#mask-40-' + componentId + ')'}
								>
									<path
										d="M10.082875,0.114125 C15.632375,0.114125 20.134125,4.598 20.16575,10.140625 L20.16575,10.082875 C20.16575,4.5142569 15.6514931,0 10.082875,0 C4.5142569,0 0,4.5142569 0,10.082875 L0,10.140625 C0.0310153081,4.59349892 4.53703728,0.113281821 10.08425,0.114125 L10.082875,0.114125 Z"
										fill="#FFFFFF"
										fillOpacity="0.2"
										id="Path"
									>
									</path>
									<path
										d="M10.082875,20.051625 C15.632375,20.051625 20.134125,15.569125 20.16575,10.0265 L20.16575,10.082875 C20.16575,15.6514931 15.6514931,20.16575 10.082875,20.16575 C4.5142569,20.16575 0,15.6514931 0,10.082875 L0,10.0265 C0.031765717,15.5725521 4.53673194,20.051716 10.082875,20.051625 L10.082875,20.051625 Z"
										fill="#3E2723"
										fillOpacity="0.15"
										id="Path"
									>
									</path>
								</g>
							</g>
						</g>
						<circle
							cx="11"
							cy="11"
							fill={'url(#radialGradient-41-' + componentId + ')'}
							fillRule="nonzero"
							id="Oval"
							r="10.082875"
						>
						</circle>
						<polygon
							id="Path"
							points="0 0 22 0 22 22 0 22"
						>
						</polygon>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ChromeIconBuilder;

