/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAdminManageSuperConductorConfigurationFormQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAdminManageSuperConductorConfigurationFormQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly attachedWebPropertyGroupIds: ReadonlyArray<string> | null } | null } | null };

export type ManageWebsiteSuperConductorConfigurationMutationVariables = GraphQL.Exact<{
  attachedWebPropertyGroupIds: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ManageWebsiteSuperConductorConfigurationMutation = { readonly __typename?: 'Mutation', readonly ManageWebsiteSuperConductorConfiguration: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly attachedWebPropertyGroupIds: ReadonlyArray<string> | null } | null } | null } } };


export const WebsiteAdminManageSuperConductorConfigurationFormDocument = gql`
    query WebsiteAdminManageSuperConductorConfigurationForm($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    adminSettings {
      attachedWebPropertyGroupIds
    }
    id
  }
}
    `;

/**
 * __useWebsiteAdminManageSuperConductorConfigurationFormQuery__
 *
 * To run a query within a React component, call `useWebsiteAdminManageSuperConductorConfigurationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAdminManageSuperConductorConfigurationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAdminManageSuperConductorConfigurationFormQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAdminManageSuperConductorConfigurationFormQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAdminManageSuperConductorConfigurationFormQuery, WebsiteAdminManageSuperConductorConfigurationFormQueryVariables> & ({ variables: WebsiteAdminManageSuperConductorConfigurationFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAdminManageSuperConductorConfigurationFormQuery, WebsiteAdminManageSuperConductorConfigurationFormQueryVariables>(WebsiteAdminManageSuperConductorConfigurationFormDocument, options);
      }
export function useWebsiteAdminManageSuperConductorConfigurationFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAdminManageSuperConductorConfigurationFormQuery, WebsiteAdminManageSuperConductorConfigurationFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAdminManageSuperConductorConfigurationFormQuery, WebsiteAdminManageSuperConductorConfigurationFormQueryVariables>(WebsiteAdminManageSuperConductorConfigurationFormDocument, options);
        }
export function useWebsiteAdminManageSuperConductorConfigurationFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteAdminManageSuperConductorConfigurationFormQuery, WebsiteAdminManageSuperConductorConfigurationFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAdminManageSuperConductorConfigurationFormQuery, WebsiteAdminManageSuperConductorConfigurationFormQueryVariables>(WebsiteAdminManageSuperConductorConfigurationFormDocument, options);
        }
export type WebsiteAdminManageSuperConductorConfigurationFormQueryHookResult = ReturnType<typeof useWebsiteAdminManageSuperConductorConfigurationFormQuery>;
export type WebsiteAdminManageSuperConductorConfigurationFormLazyQueryHookResult = ReturnType<typeof useWebsiteAdminManageSuperConductorConfigurationFormLazyQuery>;
export type WebsiteAdminManageSuperConductorConfigurationFormSuspenseQueryHookResult = ReturnType<typeof useWebsiteAdminManageSuperConductorConfigurationFormSuspenseQuery>;
export type WebsiteAdminManageSuperConductorConfigurationFormQueryResult = Apollo.QueryResult<WebsiteAdminManageSuperConductorConfigurationFormQuery, WebsiteAdminManageSuperConductorConfigurationFormQueryVariables>;
export const ManageWebsiteSuperConductorConfigurationDocument = gql`
    mutation ManageWebsiteSuperConductorConfiguration($attachedWebPropertyGroupIds: [String!]!, $websiteId: WebsiteId!) {
  ManageWebsiteSuperConductorConfiguration(
    attachedWebPropertyGroupIds: $attachedWebPropertyGroupIds
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        adminSettings {
          attachedWebPropertyGroupIds
        }
        id
      }
    }
  }
}
    `;
export type ManageWebsiteSuperConductorConfigurationMutationFn = Apollo.MutationFunction<ManageWebsiteSuperConductorConfigurationMutation, ManageWebsiteSuperConductorConfigurationMutationVariables>;

/**
 * __useManageWebsiteSuperConductorConfigurationMutation__
 *
 * To run a mutation, you first call `useManageWebsiteSuperConductorConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageWebsiteSuperConductorConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageWebsiteSuperConductorConfigurationMutation, { data, loading, error }] = useManageWebsiteSuperConductorConfigurationMutation({
 *   variables: {
 *      attachedWebPropertyGroupIds: // value for 'attachedWebPropertyGroupIds'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useManageWebsiteSuperConductorConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<ManageWebsiteSuperConductorConfigurationMutation, ManageWebsiteSuperConductorConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageWebsiteSuperConductorConfigurationMutation, ManageWebsiteSuperConductorConfigurationMutationVariables>(ManageWebsiteSuperConductorConfigurationDocument, options);
      }
export type ManageWebsiteSuperConductorConfigurationMutationHookResult = ReturnType<typeof useManageWebsiteSuperConductorConfigurationMutation>;
export type ManageWebsiteSuperConductorConfigurationMutationResult = Apollo.MutationResult<ManageWebsiteSuperConductorConfigurationMutation>;
export type ManageWebsiteSuperConductorConfigurationMutationOptions = Apollo.BaseMutationOptions<ManageWebsiteSuperConductorConfigurationMutation, ManageWebsiteSuperConductorConfigurationMutationVariables>;