import classNames from 'classnames';
import React from 'react';



export enum VersionHighlightStyle {
	Comparison = 'comparison',
	Decent = 'decent',
	Hover = 'hover',
}

type Props = {
	/** Possible alert message attached to current displayed version */
	alert?: React.ReactNode,
	/** Date and time of this displayed version */
	date: React.ReactNode,
	/** Additional flag attached to date */
	flag?: React.ReactNode,
	/** We can choose stronger type of highlight */
	highlightStyle?: VersionHighlightStyle,
	/** Flag telling that this version is highlighted */
	isHighlighted?: boolean,
	/** onClick callback making whole version clickable */
	onClick?: () => void,
	radioElements?: Array<React.ReactNode>,
	/** State of search engine activity */
	searchEngineActivityStates?: React.ReactNode,
};



const Version: React.FC<Props> = (props) => {
	const {
		alert,
		date,
		flag,
		highlightStyle = VersionHighlightStyle.Decent,
		isHighlighted,
		onClick,
		radioElements,
		searchEngineActivityStates,
	} = props;

	const componentClasses = classNames({
		'version': true,
		'version--is-clickable': onClick,
		'version--is-hoverable': onClick || radioElements,
		'version--is-highlighted': isHighlighted,
		['version--' + highlightStyle + '-highlight-style']: true,
	});

	return (
		<div
			className={componentClasses}
			onClick={onClick}
		>
			{radioElements && (
				<div className="version__radio-elements">
					{radioElements}
				</div>
			)}
			<div className="version__main-content">
				<div className="version__date">
					{date}
				</div>
				{flag && (
					<div className="version__flag">
						{flag}
					</div>
				)}
				{alert && (
					<div className="version__alert-message">
						{alert}
					</div>
				)}
			</div>
			{searchEngineActivityStates && (
				<div className="version__search-engine-activity-states">
					{searchEngineActivityStates}
				</div>
			)}
		</div>
	);
};



export default Version;
