import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ExternalLink from '~/components/patterns/links/ExternalLink';
import FormFieldsWrapper from '~/components/patterns/forms/wrappers/FormFieldsWrapper';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StaticText from '~/components/atoms/forms/components/StaticText';



const messages = defineMessages({
	title: {
		id: 'ui.settings.browserExtension.title',
	},
	label: {
		id: 'ui.settings.browserExtension.form.label',
	},
	viewInStore: {
		id: 'ui.settings.browserExtension.form.viewInStore',
	},
});



const InstallBrowserExtensionForm: React.FC = () => (
	<FormFieldsWrapper
		title={(
			<FormattedMessage {...messages.title} />
		)}
	>
		<FormRows>
			<FormRow
				label={(
					<FormattedMessage {...messages.label} />
				)}
			>
				<StaticText>
					<ExternalLink
						href="https://chrome.google.com/webstore/detail/contentking-real-time-seo/kelobidgacbngfmplnjckcbgpkplnbnd"
					>
						<FormattedMessage {...messages.viewInStore} />
					</ExternalLink>
				</StaticText>
			</FormRow>
		</FormRows>
	</FormFieldsWrapper>
);



export default InstallBrowserExtensionForm;
