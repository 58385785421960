/**
 * Prevent pull-to-refresh feature in Google Chrome mobile browsers
 *
 * https://docs.google.com/document/d/12Ay4s3NWake8Qd6xQeGiYimGJ_gCe0UMDZKwP9Ni4m8/edit
 * http://output.jsbin.com/qofuwa/2/quiet
 */
export default function setupPreventPullToRefresh(): void {
	window.addEventListener('load', () => {
		let maybePreventPullToRefresh = false;
		let lastTouchY = 0;
		let preventNativeScroll = true;

		const touchstartHandler = function (e) {
			const scrollableElement = e.targetTouches[0].target.closest('.js-scrollable');
			if (scrollableElement && (scrollableElement.scrollTop !== 0 || scrollableElement.classList.contains('js-scrollable-vertical'))) {
				preventNativeScroll = false;
			} else {
				preventNativeScroll = true;
			}

			if (e.touches.length !== 1) {
				return;
			}

			lastTouchY = e.touches[0].clientY;
			// Pull-to-refresh will only trigger if the scroll begins when the
			// document's Y offset is zero.
			maybePreventPullToRefresh = window.pageYOffset === 0;
		};

		const touchmoveHandler = function (e) {
			if (!preventNativeScroll) {
				return;
			}

			const touchY = e.touches[0].clientY;
			const touchYDelta = touchY - lastTouchY;
			lastTouchY = touchY;

			if (maybePreventPullToRefresh) {
				// To suppress pull-to-refresh it is sufficient to preventDefault the
				// first overscrolling touchmove.
				maybePreventPullToRefresh = false;
				if (touchYDelta > 0) {
					e.preventDefault();
					return;
				}
			}

			if (window.pageYOffset === 0 && touchYDelta > 0) {
				e.preventDefault();
				return;
			}
		};

		document.addEventListener('touchstart', touchstartHandler, false);
		document.addEventListener('touchmove', touchmoveHandler, false);
	});
}
