import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import IgnoreCategoryOnPages from '../issues/components/ignoringCases/IgnoreCategoryOnPages';
import IgnoreCategoryOnSegments from '../issues/components/ignoringCases/IgnoreCategoryOnSegments';
import IgnoreCategoryOnWebsite from '../issues/components/ignoringCases/IgnoreCategoryOnWebsite';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import ModalLayout from '~/components/logic/issuesIgnoring/ModalLayout';

import useOpenedWebsiteIssueCategory from '~/hooks/useOpenedWebsiteIssueCategory';
import useReloadWebsiteScopeIssues from '~/hooks/useReloadWebsiteScopeIssues';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	IgnoringScope,
	listPagesWithAllIssuesIgnored,
	listSegmentsWithAllIssuesIgnored,
} from '~/model/issuesNew';

import {
	issuesOverviewIgnoringScopeSelector,
} from '~/state/ui/content/selectors';



const messages = defineMessages({
	navigationPages: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.pages',
	},
	navigationSegments: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.segments',
	},
	navigationWebsite: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.website',
	},
});



const IgnoreWebsiteIssueModal: React.FC = () => {
	const websiteId = useWebsiteId();

	const issueCategory = useOpenedWebsiteIssueCategory(websiteId);
	const reloadWebsiteScopeIssues = useReloadWebsiteScopeIssues(websiteId);
	const scope = useSelector(issuesOverviewIgnoringScopeSelector);
	const segments = useWebsiteSegmentDefinitions(websiteId);

	const listScopes = () => {
		if (issueCategory === null) {
			return [];
		}

		const isIgnoredOnWebsite = issueCategory.issues.every(
			(issue) => issue.ignoringRuleOnWebsite.isActive,
		);

		const supportsSegments = issueCategory.issues.some((issue) => issue.ignoringRuleOnSegments !== null);
		const supportsPages = issueCategory.issues.some((issue) => issue.ignoringRuleOnPages !== null);

		const scopes: Array<{}> = [];

		scopes.push({
			badge: false,
			content: (
				<IgnoreCategoryOnWebsite
					isIgnored={isIgnoredOnWebsite}
					issueCategoryName={issueCategory.name}
					numberOfIssues={issueCategory.issues.filter((issue) => issue.ignoringRuleOnWebsite.isActive).length}
					reloadCallback={reloadWebsiteScopeIssues}
					websiteId={websiteId}
				/>
			),
			isNotReady: false,
			label: (
				<FormattedMessage {...messages.navigationWebsite} />
			),
			name: IgnoringScope.Website,
		});

		if (supportsSegments) {
			const segmentsWhereAllIssuesAreIgnored = listSegmentsWithAllIssuesIgnored(issueCategory);

			scopes.push({
				badge: segmentsWhereAllIssuesAreIgnored.length,
				content: (
					<IgnoreCategoryOnSegments
						issueCategory={issueCategory}
						reloadCallback={reloadWebsiteScopeIssues}
						segmentsWhereAllIssuesAreIgnored={segmentsWhereAllIssuesAreIgnored}
						websiteId={websiteId}
					/>
				),
				isNotReady: segments.count === 0 || isIgnoredOnWebsite,
				label: (
					<FormattedMessage {...messages.navigationSegments} />
				),
				name: IgnoringScope.Segments,
			});
		}

		if (supportsPages) {
			const pagesWhereAllIssuesAreIgnored = listPagesWithAllIssuesIgnored(issueCategory);

			scopes.push({
				badge: pagesWhereAllIssuesAreIgnored.length,
				content: (
					<IgnoreCategoryOnPages
						isIgnoredOnWebsite={isIgnoredOnWebsite}
						issueCategory={issueCategory}
						pagesWhereAllIssuesAreIgnored={pagesWhereAllIssuesAreIgnored}
						reloadCallback={reloadWebsiteScopeIssues}
						websiteId={websiteId}
					/>
				),
				isNotReady: pagesWhereAllIssuesAreIgnored.length === 0 || isIgnoredOnWebsite,
				label: (
					<FormattedMessage {...messages.navigationPages} />
				),
				name: IgnoringScope.Pages,
			});
		}

		return scopes;
	};

	return (
		<ModalLayout
			activeScope={scope}
			isLoading={issueCategory === null}
			scopes={listScopes()}
			title={issueCategory !== null && (
				<IssueCategoryTitle
					issueCategoryName={issueCategory.name}
				/>
			)}
		/>
	);
};



export default IgnoreWebsiteIssueModal;
