import PropTypes from 'prop-types';
import React, {
	useContext,
} from 'react';

import {
	FormContext,
} from '../../atoms/forms/basis/Form';



const DefaultFilterValueHandler = ({
	children,
	convertIn,
	convertOut,
	name,
}) => {
	const formContext = useContext(FormContext);

	const modifiedFormContext = React.useMemo(
		() => ({
			...formContext,
			...{
				defaultValues: {
					...formContext.defaultValues,
					...{
						[name]: convertIn(formContext.defaultValues[name]),
					},
				},
				onChangeHandler: (field, value, options) => {
					return formContext.onChangeHandler(
						field,
						convertOut(value),
						options,
					);
				},
				values: {
					...formContext.values,
					...{
						[name]: convertIn(formContext.values[name]),
					},
				},
			},
		}),
		[
			convertIn,
			convertOut,
			formContext,
			name,
		],
	);

	return (
		<FormContext.Provider value={modifiedFormContext}>
			{children}
		</FormContext.Provider>
	);
};

DefaultFilterValueHandler.propTypes = {
	convertIn: PropTypes.func.isRequired,
	convertOut: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
};



export default React.memo(DefaultFilterValueHandler);
