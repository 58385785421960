import classNames from 'classnames';
import React from 'react';

import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';



type Props = {
	animation?: boolean,
	icon?: React.ReactNode,
	isDisabled?: boolean,
	isOpen?: boolean,
	label: React.ReactNode,
};

const SelectFieldButtonToggler: React.FC<Props> = (props) => {
	const {
		animation = true,
		icon,
		isDisabled = false,
		isOpen = false,
		label,
	} = props;

	const togglerClasses = classNames({
		'select-field-button-toggler': true,
		'select-field-button-toggler--closed': !isOpen,
		'select-field-button-toggler--open': isOpen,
		'select-field-button-toggler--is-disabled': isDisabled,
		'select-field-button-toggler--animation-disabled': !animation,
	});

	const attributes: Record<string, any> = {};

	if (isDisabled) {
		attributes.disabled = 'disabled';
	}

	let iconStyle;

	if (isDisabled) {
		iconStyle = DropdownIconStyle.Greyscale;
	} else if (isOpen) {
		iconStyle = DropdownIconStyle.White;
	} else {
		iconStyle = DropdownIconStyle.Default;
	}

	return (
		<div
			className={togglerClasses}
			{...attributes}
		>
			{icon && (
				<span className="select-field-button-toggler__icon">
					{icon}
				</span>
			)}
			<span className="select-field-button-toggler__label">
				{label}
			</span>
			<span className="select-field-button-toggler__dropdown-icon">
				<DropdownIcon
					isActive={isOpen}
					style={iconStyle}
				/>
			</span>
		</div>
	);
};



export default SelectFieldButtonToggler;
