/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KingdomSettingsConductorCustomerDomainsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type KingdomSettingsConductorCustomerDomainsQuery = { readonly __typename?: 'Query', readonly kingdomSettings: { readonly __typename?: 'KingdomSettings', readonly conductorCustomerDomains: ReadonlyArray<string> } | null };


export const KingdomSettingsConductorCustomerDomainsDocument = gql`
    query KingdomSettingsConductorCustomerDomains {
  kingdomSettings {
    conductorCustomerDomains
  }
}
    `;

/**
 * __useKingdomSettingsConductorCustomerDomainsQuery__
 *
 * To run a query within a React component, call `useKingdomSettingsConductorCustomerDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKingdomSettingsConductorCustomerDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKingdomSettingsConductorCustomerDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKingdomSettingsConductorCustomerDomainsQuery(baseOptions?: Apollo.QueryHookOptions<KingdomSettingsConductorCustomerDomainsQuery, KingdomSettingsConductorCustomerDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KingdomSettingsConductorCustomerDomainsQuery, KingdomSettingsConductorCustomerDomainsQueryVariables>(KingdomSettingsConductorCustomerDomainsDocument, options);
      }
export function useKingdomSettingsConductorCustomerDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KingdomSettingsConductorCustomerDomainsQuery, KingdomSettingsConductorCustomerDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KingdomSettingsConductorCustomerDomainsQuery, KingdomSettingsConductorCustomerDomainsQueryVariables>(KingdomSettingsConductorCustomerDomainsDocument, options);
        }
export function useKingdomSettingsConductorCustomerDomainsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<KingdomSettingsConductorCustomerDomainsQuery, KingdomSettingsConductorCustomerDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<KingdomSettingsConductorCustomerDomainsQuery, KingdomSettingsConductorCustomerDomainsQueryVariables>(KingdomSettingsConductorCustomerDomainsDocument, options);
        }
export type KingdomSettingsConductorCustomerDomainsQueryHookResult = ReturnType<typeof useKingdomSettingsConductorCustomerDomainsQuery>;
export type KingdomSettingsConductorCustomerDomainsLazyQueryHookResult = ReturnType<typeof useKingdomSettingsConductorCustomerDomainsLazyQuery>;
export type KingdomSettingsConductorCustomerDomainsSuspenseQueryHookResult = ReturnType<typeof useKingdomSettingsConductorCustomerDomainsSuspenseQuery>;
export type KingdomSettingsConductorCustomerDomainsQueryResult = Apollo.QueryResult<KingdomSettingsConductorCustomerDomainsQuery, KingdomSettingsConductorCustomerDomainsQueryVariables>;