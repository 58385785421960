import Immutable from 'immutable';

import CK from '~/types/contentking';

import useEnabledColumnsList from '~/hooks/useEnabledColumnsList';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	createFilterParameter,
} from '~/actions/pages/overview';

import {
	DEFAULT_FILTER,
	DEFAULT_SORT_BY,
} from '~/model/pages';



function useNavigateToPages() {
	const currentWebsiteId = useWebsiteId();

	const columns = useEnabledColumnsList();
	const segments = useWebsiteSegmentDefinitions(currentWebsiteId);

	return ({
		filter = null,
		graphs = false,
		scope = 'website',
		websiteId = currentWebsiteId,
	}: {
		filter?: any,
		graphs?: boolean,
		scope?: string,
		websiteId?: string,
	}) => {
		let totalFilter = DEFAULT_FILTER.merge(
			Immutable.fromJS(filter ? filter : {}),
		);

		if (scope !== 'website') {
			totalFilter = totalFilter.setIn(
				[CK.PagesCommonColumn.Segments, 'included_in'],
				totalFilter.getIn([CK.PagesCommonColumn.Segments, 'included_in']).push(scope.substring('segment/'.length)),
			);
		}

		return {
			routeName: 'website.pages',
			routeParams: {
				filter: createFilterParameter(
					totalFilter,
					DEFAULT_SORT_BY,
					columns,
					segments.listAll(),
				) || undefined,
				graphs: graphs || undefined,
				websiteId,
			},
		};
	};
}



export default useNavigateToPages;
