import type CK from '~/types/contentking';

import {
	type UsersWithWebsiteAccessQuery,
	useUsersWithWebsiteAccessQuery,
} from './useUsersWithWebsiteAccess.gql';

import memoizeById from '~/utilities/memoizeById';



const emptyList = [];

const format = memoizeById(
	(data: UsersWithWebsiteAccessQuery | undefined) => {
		const userAccess = data?.website?.userAccess ?? null;

		if (userAccess === null) {
			return {
				isLoaded: false,
				listAll: () => emptyList,
			};
		}

		const all = userAccess.map(
			(userAccess) => {
				return {
					displayName: userAccess.user.displayName,
					email: userAccess.user.email,
					firstName: userAccess.user.firstName,
					lastName: userAccess.user.lastName,
					uniqueId: userAccess.user.uniqueId,
				};
			},
		);

		return {
			isLoaded: true,
			listAll: () => all,
		};
	},
);



function useUsersWithWebsiteAccess(websiteId: CK.WebsiteId | null) {
	const { data } = useUsersWithWebsiteAccessQuery({
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return format(websiteId, data);
}



export default useUsersWithWebsiteAccess;
