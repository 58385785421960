import classNames from 'classnames';
import React from 'react';



export enum NewTableCellSize {
	Default = 'default',
	Small = 'small',
}

export enum NewTableCellType {
	Body = 'body',
	Header = 'header',
}



type Props = {
	children?: React.ReactNode,
	size?: NewTableCellSize,
	type?: NewTableCellType,
};

const NewTableCell: React.FC<Props> = (props) => {
	const {
		children,
		size = NewTableCellSize.Default,
		type = NewTableCellType.Body,
	} = props;

	const cellClasses = classNames({
		'new-table__cell': true,
		[`new-table__cell--${type}`]: true,
		[`new-table__cell--${size}`]: size !== NewTableCellSize.Default,
	});

	if (type === NewTableCellType.Header) {
		return (
			<th className={cellClasses}>
				{children}
			</th>
		);
	}

	return (
		<td className={cellClasses}>
			{children}
		</td>
	);
};


export default NewTableCell;
