import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import ModalContentSection from './ModalContentSection';



class ModalTextSection extends Component {

	render() {
		const {
			centered,
			children,
		} = this.props;

		return (
			<ModalContentSection
				centered={centered}
				enforceReadableTextLength={true}
			>
				{children}
			</ModalContentSection>
		);
	}

}

ModalTextSection.defaultProps = {
	centered: false,
};

ModalTextSection.propTypes = {
	/** Align content to the centre */
	centered: PropTypes.bool.isRequired,
};



export default ModalTextSection;
