import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import ExpirationTour from '~/components/patterns/tours/ExpirationTour';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import ModalPanel, {
	SIZE_XLARGE as MODAL_SIZE_XLARGE,
} from '~/components/atoms/panels/ModalPanel';
import NumberOfEmployeesForm from '~/components/app/NumberOfEmployeesForm';
import RichText from '~/components/patterns/typography/RichText';
import SimpleLogoutLink, {
	SimpleLogoutLinkStyle,
} from '~/components/app/SimpleLogoutLink';
import SocialProof from '~/components/app/SocialProof';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsNumberOfEmployeesEnoughForOutOfBand from '~/hooks/useAccountIsNumberOfEmployeesEnoughForOutOfBand';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useUserFirstName from '~/hooks/useUserFirstName';

import {
	Intercom,
} from '~/globals';

import {
	navigate,
} from '~/routing/router';



const messages = defineMessages({
	clickToLogout: {
		id: 'ui.lockedAccountModal.clickToLogout',
	},
	contactUs: {
		id: 'ui.lockedAccountModal.contactUs',
	},
	content: {
		id: 'ui.lockedAccountModal.content',
	},
	greeting: {
		id: 'ui.lockedAccountModal.greeting',
	},
	oneMoreThing: {
		id: 'ui.lockedAccountModal.oneMoreThing',
	},
	signUp: {
		id: 'ui.lockedAccountModal.signUp',
	},
	title: {
		id: 'ui.lockedAccountModal.title',
	},
});



type Props = {
	closeCallback?: () => void,
};

const LockedAccountModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const modalRef = React.useRef<MultiStepModalRef | null>(null);

	const accountId = useAccountId();
	const currentUserId = useCurrentUserId();

	const firstName = useUserFirstName(currentUserId);
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const isNumberOfEmployeesEnoughForOutOfBand = useAccountIsNumberOfEmployeesEnoughForOutOfBand(accountId);

	const isAllowedToViewSignup = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ViewSignup,
	);

	const showNumberOfEmployeesStep = isNumberOfEmployeesEnoughForOutOfBand === null;

	const handleSignUpClick = React.useCallback(
		() => {
			if (modalRef.current === null) {
				return;
			}

			if (
				modalRef.current.isStepActive('sign-up')
				&& showNumberOfEmployeesStep
			) {
				modalRef.current.goToNextStep();
			} else {
				navigate('account.pricing');
			}
		},
		[
			showNumberOfEmployeesStep,
		],
	);

	return (
		<ModalPanel
			canBeClosed={kingdomAdminFeatures.areVisible}
			onCloseCallback={closeCallback}
			size={MODAL_SIZE_XLARGE}
		>
			<MultiStepModal
				minHeight={300}
				ref={modalRef}
			>
				<MultiStepModalStep
					name="sign-up"
					sidebar={(
						<SocialProof hideBenefits={true} />
					)}
					slimSidebar={true}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<ExpirationTour
						additionalInfo={(
							<FormattedMessage
								{...messages.clickToLogout}
								values={{
									logout: (
										<SimpleLogoutLink style={SimpleLogoutLinkStyle.Decent} />
									),
								}}
							/>
						)}
						ctaButton={(
							<>
								{isAllowedToViewSignup.yes ? (
									<Button
										onClick={handleSignUpClick}
										size={ButtonSize.XLarge}
										style={ButtonStyle.Action}
									>
										<FormattedMessage {...messages.signUp} />
									</Button>
								) : (
									<Button
										onClick={() => {
											Intercom()('show');
										}}
										size={ButtonSize.XLarge}
										style={ButtonStyle.Action}
									>
										<FormattedMessage {...messages.contactUs} />
									</Button>
								)}
							</>
						)}
					>
						<RichText>
							{firstName && (
								<Copy
									{...messages.greeting}
									values={{
										firstName,
									}}
								/>
							)}
							<Copy {...messages.content} />
						</RichText>
					</ExpirationTour>
				</MultiStepModalStep>

				<MultiStepModalStep
					name="number-of-employees"
					sidebar={(
						<SocialProof hideBenefits={true} />
					)}
					slimSidebar={true}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<NumberOfEmployeesForm
						description={(
							<Copy {...messages.oneMoreThing} />
						)}
						onSubmitCallback={handleSignUpClick}
					/>
				</MultiStepModalStep>
			</MultiStepModal>
		</ModalPanel>
	);
};



export default LockedAccountModal;
