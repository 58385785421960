import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Set an absolute position */
	position?: {
		bottom?: number,
		left?: number,
		right?: number,
		top?: number,
	},
};



const ContextMenu: React.FC<Props> = (props) => {
	const {
		children,
		position,
	} = props;

	const style: React.CSSProperties = {};

	if (position) {
		style.position = 'absolute';
		style.bottom = position.bottom !== undefined ? `${position.bottom}px` : undefined;
		style.left = position.left !== undefined ? `${position.left}px` : undefined;
		style.right = position.right !== undefined ? `${position.right}px` : undefined;
		style.top = position.top !== undefined ? `${position.top}px` : undefined;
	}

	return (
		<div
			className="context-menu"
			onClick={(e) => e.stopPropagation()}
			onContextMenu={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
			onMouseDown={(e) => e.stopPropagation()}
			style={style}
		>
			{children}
		</div>
	);
};



export default ContextMenu;
