import React from 'react';

import DesktopLayout from '~/components/logic/issuesOverview/DesktopLayout';
import MobileLayout from '~/components/logic/issuesOverview/MobileLayout';
import WebsiteIssueCategoriesList from '~/components/logic/issues/issuesCategoriesList/WebsiteIssueCategoriesList';

import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	TABBED_VIEW_BREAKPOINT,
} from '~/utilities/viewportSize';



type Props = {
	innerPanel?: () => React.ReactElement,
};

const CurrentView: React.FC<Props> = (props) => {
	const {
		innerPanel,
	} = props;

	const viewportWidth = useViewportWidth();
	const websiteId = useWebsiteId();

	const issueCategoriesList = (
		<WebsiteIssueCategoriesList
			numberOfPlaceholders={5}
			websiteId={websiteId}
		/>
	);

	if (viewportWidth < TABBED_VIEW_BREAKPOINT) {
		return (
			<MobileLayout
				innerPanel={innerPanel}
				issueCategoriesList={issueCategoriesList}
				websiteId={websiteId}
			/>
		);
	}

	return (
		<DesktopLayout
			innerPanel={innerPanel}
			issueCategoriesList={issueCategoriesList}
			websiteId={websiteId}
		/>
	);
};



export default CurrentView;
