import React from 'react';

import List, {
	ListSize,
} from '~/components/patterns/lists/List';



type Props = {
	/** Additional button which can be attached to the bottom of body */
	button?: React.ReactNode,
	children: React.ReactNode,
};

const IgnoringModalBodyLayout: React.FC<Props> = (props) => {
	const {
		button,
		children,
	} = props;

	return (
		<div className="ignoring-modal-body-layout">
			<List size={ListSize.Large}>
				{children}
			</List>
			{button && (
				<div className="ignoring-modal-body-layout__button">
					{button}
				</div>
			)}
		</div>
	);
};



export default IgnoringModalBodyLayout;
