import React from 'react';



type Props = {
	billingCycleConfigurator?: React.ReactNode,
	businessSection?: React.ReactNode,
	currencyConfigurator: React.ReactNode,
	pagesConfigurator: React.ReactNode,
	plans?: React.ReactNode,
	/** Optional overview of one selected plan */
	selectedPlanOverview?: React.ReactNode,
};



const DesktopSignupScreenLayout: React.FC<Props> = (props) => {
	const {
		billingCycleConfigurator,
		businessSection,
		currencyConfigurator,
		pagesConfigurator,
		plans,
		selectedPlanOverview,
	} = props;

	return (
		<div className="desktop-signup-layout">
			<div className="desktop-signup-layout__basic-setup">
				<div className="desktop-signup-layout__sidebar">
					<div className="desktop-signup-layout__sidebar-guts">
						{businessSection && (
							<div className="desktop-signup-layout__business-section">
								{businessSection}
							</div>
						)}

						{billingCycleConfigurator && (
							<div className="desktop-signup-layout__billing-cycle-configurator">
								{billingCycleConfigurator}
							</div>
						)}

						<div className="desktop-signup-layout__currency-configurator">
							{currencyConfigurator}
						</div>
					</div>
				</div>
				<div className="desktop-signup-layout__content">
					<div className="desktop-signup-layout__pages-configurator">
						{pagesConfigurator}
					</div>

					{selectedPlanOverview && (
						<div className="desktop-signup-layout__selected-plan-overview">
							{selectedPlanOverview}
						</div>
					)}
				</div>
			</div>

			{plans && (
				<div className="desktop-signup-layout__plans">
					{plans}
				</div>
			)}

			<div className="desktop-signup-layout__footer-gaps" />
		</div>
	);
};



export default DesktopSignupScreenLayout;
