import classNames from 'classnames';
import React from 'react';

import PriceSideNote from '~/components/patterns/pricing/PriceSideNote';



export enum PriceSummarySize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	/** Information about selected billing cycle */
	billingCycleInfo?: React.ReactNode,
	/** Price value */
	price: React.ReactElement,
	/** Possible prefix placed directly before price */
	pricePrefix?: React.ReactNode,
	/** Size of price summary */
	size?: PriceSummarySize,
};



const PriceSummary: React.FC<Props> = (props) => {
	const {
		billingCycleInfo,
		price,
		pricePrefix,
		size = PriceSummarySize.Default,
	} = props;

	return (
		<div
			className={classNames({
				'price-summary': true,
				'price-summary--small': size === PriceSummarySize.Small,
			})}
		>
			<div className="price-summary__price">
				{pricePrefix && (
					<span className="price-summary__price-prefix">{pricePrefix}</span>
				)}
				{price}
			</div>

			{billingCycleInfo && (
				<div className="price-summary__billing-cycle-info">
					<PriceSideNote>
						{billingCycleInfo}
					</PriceSideNote>
				</div>
			)}
		</div>
	);
};



export default PriceSummary;
