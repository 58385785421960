import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import ComparisonActiveFilterBar from '../ComparisonActiveFilterBar';
import ComparisonEntry from './ComparisonEntry';
import HeaderButtonsLayout from '../../../atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderFilterLayout from '../../../atoms/screenLayouts/components/header/layouts/HeaderFilterLayout';
import HeaderOptionsLayout from '../../../atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitle from '../../../atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '../../../atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import IssuesFilterBar from '../../issues/activeTableFilterBar/IssuesFilterBar';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';

import useViewportType from '~/hooks/useViewportType';

import {
	isActiveSelector,
} from '~/state/websiteIssuesComparison/selectors';



const messages = defineMessages({
	title: {
		id: 'ui.issuesOverview.title',
	},
});



type Props = {
	buttons?: React.ReactNode,
	footer: React.ReactNode,
	score?: React.ReactNode,
};

const HeaderLayout: React.FC<Props> = (props) => {
	const {
		buttons,
		footer,
		score,
	} = props;

	const isComparisonActive = useSelector(isActiveSelector);
	const viewportType = useViewportType();

	const isCompact = viewportType.isSmall || viewportType.isMedium;

	return (
		<ScreenHeader
			footer={footer}
		>
			<HeaderTitleLayout keepPredefinedWidth={!viewportType.isSmall}>
				<HeaderTitle>
					<FormattedMessage {...messages.title} />
				</HeaderTitle>
			</HeaderTitleLayout>

			<HeaderFilterLayout
				compactView={isCompact}
				fixedWidth={!isCompact}
			>
				{isComparisonActive
					? (
						<ComparisonActiveFilterBar />
					)
					: (
						<IssuesFilterBar />
					)
				}
			</HeaderFilterLayout>

			<HeaderOptionsLayout>
				<HeaderButtonsLayout>
					<ComparisonEntry />

					{buttons}
				</HeaderButtonsLayout>

				{score}
			</HeaderOptionsLayout>
		</ScreenHeader>
	);
};



export default HeaderLayout;
