import throttle from 'lodash/throttle';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import BookADemoButton from '~/components/app/BookADemoButton';
import BookWebinarButton from '~/components/app/BookWebinarButton';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';
import ProfileCompletenessMessage from '../patterns/users/ProfileCompletenessMessage';
import ProfileCompletenessOverview from '../patterns/users/ProfileCompletenessOverview';
import Progress, {
	ProgressSize,
	ProgressStyle,
} from '~/components/patterns/values/Progress';
import RemainingTime, {
	RemainingTimeContext,
} from '~/components/app/RemainingTime';
import TrialBarAtom from '~/components/patterns/layout/TrialBar';
import TrialBarDrawer from '~/components/patterns/layout/TrialBarDrawer';

import {
	useTrialBarQuery,
} from './TrialBar.gql';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useIsoDate from '~/hooks/useIsoDate';
import useLocalStorage from '~/hooks/useLocalStorage';
import useNavigation from '~/hooks/useNavigation';
import useTimeoutSince from '~/hooks/useTimeoutSince';
import useUserFirstName from '~/hooks/useUserFirstName';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ProfileCompletenessTask,
} from '~/model/profileCompleteness';

import {
	notEmpty,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	accountCompleteness: {
		id: 'ui.trialBar.accountCompleteness',
	},
	buttonLabel: {
		id: 'ui.trialBar.button',
	},
	profileCompletenessCompletedDescription: {
		id: 'ui.profileCompleteness.completed.description',
	},
	profileCompletenessCompletedTitle: {
		id: 'ui.profileCompleteness.completed.title',
	},
	profileCompletenessTitle: {
		id: 'ui.profileCompleteness.title',
	},
});

const profileCompletenessTaskMessages = defineMessages({
	[GraphQL.AccountProfileCompletenessTask.AddSecondWebsite]: {
		id: 'ui.profileCompleteness.task.addSecondWebsite',
	},
	[GraphQL.AccountProfileCompletenessTask.ConnectGoogleAnalytics]: {
		id: 'ui.profileCompleteness.task.connectGoogleAnalytics',
	},
	[GraphQL.AccountProfileCompletenessTask.ConnectGoogleDataStudio]: {
		id: 'ui.profileCompleteness.task.connectGoogleLookerStudio',
	},
	[GraphQL.AccountProfileCompletenessTask.ConnectGoogleSearchConsole]: {
		id: 'ui.profileCompleteness.task.connectGoogleSearchConsole',
	},
	[GraphQL.AccountProfileCompletenessTask.ConnectSlack]: {
		id: 'ui.profileCompleteness.task.connectSlack',
	},
	[GraphQL.AccountProfileCompletenessTask.InstallChromeExtension]: {
		id: 'ui.profileCompleteness.task.installChromeExtension',
	},
	[GraphQL.AccountProfileCompletenessTask.InviteSecondUser]: {
		id: 'ui.profileCompleteness.task.inviteSecondUser',
	},
	[GraphQL.AccountProfileCompletenessTask.InviteThirdUser]: {
		id: 'ui.profileCompleteness.task.inviteThirdUser',
	},
	[GraphQL.AccountProfileCompletenessTask.SetAccountName]: {
		id: 'ui.profileCompleteness.task.setAccountName',
	},
});



const PROFILE_COMPLETENESS_TASKS = [
	GraphQL.AccountProfileCompletenessTask.SetAccountName,
	GraphQL.AccountProfileCompletenessTask.InstallChromeExtension,
	GraphQL.AccountProfileCompletenessTask.AddSecondWebsite,
	GraphQL.AccountProfileCompletenessTask.InviteSecondUser,
	GraphQL.AccountProfileCompletenessTask.ConnectSlack,
	GraphQL.AccountProfileCompletenessTask.ConnectGoogleSearchConsole,
	GraphQL.AccountProfileCompletenessTask.ConnectGoogleAnalytics,
	GraphQL.AccountProfileCompletenessTask.ConnectGoogleDataStudio,
	GraphQL.AccountProfileCompletenessTask.InviteThirdUser,
];



type Props = {
	accountId: CK.AccountId,
	expirationDate: CK.Timestamp,
};

const TrialBar: React.FC<Props> = (props) => {
	const {
		accountId,
		expirationDate,
	} = props;

	const currentUserId = useCurrentUserId();
	const websiteId = useWebsiteId();

	const currentUserFirstName = useUserFirstName(currentUserId);
	const navigation = useNavigation();
	const viewportType = useViewportType();

	const [collapsedAt, setCollapsedAt] = useLocalStorage(
		'trialBarClosedAt',
		null,
		(value) => value !== null ? new Date(value) : null,
	);
	const [collapsedDrawer, setCollapsedDrawer] = React.useState(true);

	const isCollapsed = collapsedAt !== null;

	const isAllowedToViewSignup = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ViewSignup,
	);

	const isAllowedToManageDetails = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageDetails,
	);

	const { data, refetch } = useTrialBarQuery({
		pollInterval: isCollapsed ? 300_000 : 20_000,
		skip: isAllowedToManageDetails.yes === false,
		variables: {
			accountId,
		},
	});

	const refetchProfileCompleteness = throttle(refetch, 5000);

	const isDemoAvailable = data?.account?.isDemoAvailable ?? false;
	const isWebinarAvailable = data?.authenticatedSession?.user.isPlatformTourWebinarOfferingAvailable ?? false;
	const tasks = data?.account?.profileCompleteness?.tasks ?? [];
	const showProgressBar = !!data?.account?.isProfileCompletenessAvailable;
	const isProfileCompletenessFinished = showProgressBar && tasks.every((task) => task.isDone);
	const isProfileCompletenessStarted = showProgressBar && tasks.some((task) => task.isDone);
	const trialExpirationDate = useIsoDate(expirationDate);

	const collapseBar = React.useCallback(
		() => {
			setCollapsedAt(new Date());
		},
		[
			setCollapsedAt,
		],
	);

	const revealBar = React.useCallback(
		() => {
			setCollapsedAt(null);
		},
		[
			setCollapsedAt,
		],
	);

	useTimeoutSince(
		revealBar,
		collapsedAt,
		3_600 * 1_000,
	);


	function handleDrawerClose() {
		setCollapsedDrawer(true);
	}

	function handleTargetClick() {
		refetchProfileCompleteness();
		setCollapsedDrawer(!collapsedDrawer);
	}

	function handleTaskClick(task) {
		setCollapsedDrawer(true);

		switch (task.type) {

			case ProfileCompletenessTask.AddSecondWebsite:
				navigation.navigate({
					routeName: 'account.websites.new',
				});
				break;

			case ProfileCompletenessTask.ConnectGoogleAnalytics:
			case ProfileCompletenessTask.ConnectGoogleDataStudio:
			case ProfileCompletenessTask.ConnectGoogleSearchConsole:
			case ProfileCompletenessTask.ConnectSlack:
				navigation.navigate({
					routeName: 'website.detail.integrations',
					routeParams: {
						websiteId: websiteId as ReturnType<typeof useWebsiteId> | null ?? '{id}',
					},
				});
				break;

			case ProfileCompletenessTask.InstallChromeExtension:
				window.open('https://chrome.google.com/webstore/detail/contentking-real-time-seo/kelobidgacbngfmplnjckcbgpkplnbnd', '_blank');
				break;

			case ProfileCompletenessTask.InviteSecondUser:
			case ProfileCompletenessTask.InviteThirdUser:
				navigation.navigate({
					routeName: 'account.members.new',
				});
				break;

			case ProfileCompletenessTask.SetAccountName:
				navigation.navigate({
					routeName: 'account.settings',
				});
				break;

		}
	}

	return (
		<TrialBarAtom
			buttons={[
				isAllowedToManageDetails.yes && !viewportType.isSmall && (
					<BookADemoButton
						accountId={accountId}
						hideButton={!isDemoAvailable}
						key="book-demo"
					/>
				),
				isAllowedToManageDetails.yes && !viewportType.isSmall && (
					<BookWebinarButton
						hideButton={!isWebinarAvailable}
						key="book-webinar"
					/>
				),
				isAllowedToViewSignup.yes && (
					<Button
						key="view-signup"
						linkRouteName="account.pricing"
						linkRouteParams={{
							accountId,
						}}
						style={ButtonStyle.Action}
					>
						<FormattedMessage {...messages.buttonLabel} />
					</Button>
				),
			].filter(notEmpty)}
			isCollapsed={isCollapsed}
			isCollapsible={!viewportType.isSmall}
			isPremium={false}
			onCloseCallback={collapseBar}
			onRevealCallback={revealBar}
			profileInfo={showProgressBar && !viewportType.isSmall && (
				<TrialBarDrawer
					isCollapsed={collapsedDrawer}
					onCloseCallback={handleDrawerClose}
					onTargetClick={handleTargetClick}
					target={(
						<Progress
							label={(
								<AttachedIcon
									icon={(
										<DropdownIcon
											isActive={collapsedDrawer}
											size={10}
											style={DropdownIconStyle.White}
										/>
									)}
									iconPosition={AttachedIconPosition.Suffix}
								>
									<FormattedMessage {...messages.accountCompleteness} />
								</AttachedIcon>
							)}
							max={tasks.length + 1}
							size={viewportType.isMedium ? ProgressSize.Small : ProgressSize.Default}
							style={ProgressStyle.Light}
							value={tasks.filter((task) => task.isDone).length + 1}
						/>
					)}
				>
					{isProfileCompletenessFinished ? (
						<ProfileCompletenessMessage
							title={(
								<FormattedMessage
									{...messages.profileCompletenessCompletedTitle}
									values={{
										name: currentUserFirstName || 'null',
									}}
								/>
							)}
						>
							<FormattedMessage
								{...messages.profileCompletenessCompletedDescription}
							/>
						</ProfileCompletenessMessage>
					) : (
						<ProfileCompletenessOverview
							achievements={PROFILE_COMPLETENESS_TASKS.map((type) => {
								const task = tasks.find((task) => task.type === type);

								if (task === undefined) {
									throw new Error(
										'Can\'t find task of type \'' + type + '\'',
									);
								}

								return {
									isDone: task.isDone,
									label: (
										<FormattedMessage {...profileCompletenessTaskMessages[type]} />
									),
									onClick: () => handleTaskClick(task),
								};
							})}
							title={(
								<Copy
									{...messages.profileCompletenessTitle}
									values={{
										name: currentUserFirstName || 'null',
									}}
								/>
							)}
						/>
					)}
				</TrialBarDrawer>
			)}
			remainingTime={(
				<RemainingTime
					context={RemainingTimeContext.Trial}
					until={trialExpirationDate}
				/>
			)}
			showStartHereHint={!isProfileCompletenessStarted}
		/>
	);
};



export default TrialBar;
