import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PriorityIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="M9.72 8.53L5.5 4.91v14.18l4.22-3.62M19.77 12l-8.27 7.09V4.91z"
					fill="none"
					stroke={color}
					strokeLinejoin="round"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default PriorityIconBuilder;

