import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormWrapperContainer, {
	FormWrapperContainerSize,
} from '~/components/patterns/forms/wrappers/FormWrapperContainer';
import PaymentAnnouncement from '~/components/patterns/messages/embedded/PaymentAnnouncement';
import PaymentFailureFormError from '~/components/app/PaymentFailureFormError';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useFinishUnpaidSignupMutation,
} from './IncompleteSignupBanner.gql';

import useResolvePaymentStatus from '~/hooks/useResolvePaymentStatus';



const messages = defineMessages({
	button: {
		id: 'ui.incomplete3DSecureChallengeBanner.button',
	},
	content: {
		id: 'ui.incomplete3DSecureChallengeBanner.content',
	},
	title: {
		id: 'ui.incomplete3DSecureChallengeBanner.title',
	},
});



type Props = {
	accountId: CK.AccountId,
};

const IncompleteSignupBanner: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const [finishUnpaidSignup] = useFinishUnpaidSignupMutation();

	const resolvePaymentStatus = useResolvePaymentStatus(accountId);

	const handleButton = React.useCallback(
		async () => {
			await finishUnpaidSignup({
				variables: {
					accountId,
				},
			});

			await resolvePaymentStatus();
		},
		[
			accountId,
			finishUnpaidSignup,
			resolvePaymentStatus,
		],
	);

	return (
		<FormWrapperContainer size={FormWrapperContainerSize.Form}>
			<Form
				defaultDataHasChanged={true}
				onSuccess={handleButton}
			>
				<PaymentAnnouncement
					ctaElement={(
						<SubmitButton>
							<Copy {...messages.button} />
						</SubmitButton>
					)}
					title={(
						<Copy {...messages.title} />
					)}
				>
					<Copy {...messages.content} />

					<PaymentFailureFormError
						errorCode="failedPayment"
					/>
				</PaymentAnnouncement>
			</Form>
		</FormWrapperContainer>
	);
};



export default IncompleteSignupBanner;
