import React from 'react';

import useNavigation from './useNavigation';
import useUrlState from '~/hooks/useUrlState';



function useFilteredView({
	ready,
	simple,
}) {
	const navigation = useNavigation();
	const urlState = useUrlState();

	const view = urlState.params.view || null;

	React.useEffect(
		() => {
			if (view || !ready) {
				return;
			}

			navigation.navigate({
				keepParameters: true,
				options: {
					replace: true,
				},
				routeParams: {
					view: simple() ? 'grid' : 'list',
				},
			});
		},
		[
			navigation,
			ready,
			simple,
			view,
		],
	);

	const activateRoute = React.useMemo(
		() => {
			return navigation.createRoute({
				keepParameters: true,
				routeParams: {
					view: 'list',
				},
			});
		},
		[
			navigation,
		],
	);

	const deactivateRoute = React.useMemo(
		() => {
			return navigation.createRoute({
				keepParameters: true,
				routeParams: {
					view: 'grid',
				},
			});
		},
		[
			navigation,
		],
	);

	const isActive = (view || (ready && (simple() ? 'grid' : 'list')) || null) === 'list';

	return {
		activateRoute,
		deactivateRoute,
		isActive,
	};
}



export default useFilteredView;
