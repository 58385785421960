/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddFreeEmailDomainsMutationVariables = GraphQL.Exact<{
  emailDomains: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type AddFreeEmailDomainsMutation = { readonly __typename?: 'Mutation', readonly AddFreeEmailDomains: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly kingdomSettings: { readonly __typename?: 'KingdomSettings', readonly freeEmails: ReadonlyArray<string> } | null } } };


export const AddFreeEmailDomainsDocument = gql`
    mutation AddFreeEmailDomains($emailDomains: [String!]!) {
  AddFreeEmailDomains(emailDomains: $emailDomains) {
    query {
      kingdomSettings {
        freeEmails
      }
    }
  }
}
    `;
export type AddFreeEmailDomainsMutationFn = Apollo.MutationFunction<AddFreeEmailDomainsMutation, AddFreeEmailDomainsMutationVariables>;

/**
 * __useAddFreeEmailDomainsMutation__
 *
 * To run a mutation, you first call `useAddFreeEmailDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFreeEmailDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFreeEmailDomainsMutation, { data, loading, error }] = useAddFreeEmailDomainsMutation({
 *   variables: {
 *      emailDomains: // value for 'emailDomains'
 *   },
 * });
 */
export function useAddFreeEmailDomainsMutation(baseOptions?: Apollo.MutationHookOptions<AddFreeEmailDomainsMutation, AddFreeEmailDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFreeEmailDomainsMutation, AddFreeEmailDomainsMutationVariables>(AddFreeEmailDomainsDocument, options);
      }
export type AddFreeEmailDomainsMutationHookResult = ReturnType<typeof useAddFreeEmailDomainsMutation>;
export type AddFreeEmailDomainsMutationResult = Apollo.MutationResult<AddFreeEmailDomainsMutation>;
export type AddFreeEmailDomainsMutationOptions = Apollo.BaseMutationOptions<AddFreeEmailDomainsMutation, AddFreeEmailDomainsMutationVariables>;