import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import AbstractTextField, {
	type AbstractTextFieldRef,
	AbstractTextFieldSize,
	AbstractTextFieldType,
} from '~/components/patterns/forms/fields/AbstractTextField';
import AbstractSelectField, {
	type AbstractSelectFieldRef,
	AbstractSelectFieldSize,
} from '~/components/patterns/forms/fields/AbstractSelectField';
import AbstractSelectFieldToggler from '~/components/patterns/forms/fieldParts/selectFieldTogglers/AbstractSelectFieldToggler';
import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BlankValue from '~/components/patterns/values/BlankValue';
import FieldDropdown from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdown';
import FieldDropdownClickableOptions, {
	type FieldDropdownClickableOptionsProps,
} from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdownClickableOptions';
import NativeSelectField, {
	NativeSelectFieldSize,
} from './builders/NativeSelectField';
import StringSelectionHighlight, {
	StringSelectionHighlightType,
} from '~/components/patterns/values/StringSelectionHighlight';
import VisuallyHidden from '~/components/patterns/utils/VisuallyHidden';

import useFormContext from '~/hooks/useFormContext';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import isMobileDevice from '~/utilities/isMobileDevice';
import matchAndReturn from '~/utilities/matchAndReturn';

import {
	isNumber,
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	noFilterResults: {
		id: 'ui.general.multiselect.noSearchResults',
		defaultMessage: 'No results match "{value}"',
	},
	placeholder: {
		id: 'ui.general.selectFieldPlaceholder',
		defaultMessage: 'Choose',
	},
	searchPlaceholder: {
		id: 'ui.general.search.placeholder',
		defaultMessage: 'search',
	},
});



type ActualOption = {
	description?: React.ReactNode,
	disabled?: boolean,
	icon?: React.ReactNode,
	label: React.ReactNode,
	name: string,
	onClickCallback?: () => void,
};

type DividerOption = {
	divider: true,
};

type Option = ActualOption | DividerOption;

function isActualOption(option: Option): option is ActualOption {
	return isDividerOption(option) === false;
}

function isDividerOption(option: Option): option is DividerOption {
	return 'divider' in option;
}

function filterOptions(options: ReadonlyArray<Option>, filterText: string) {
	filterText = filterText.toLowerCase();

	return options.filter((option) => {
		if (isDividerOption(option)) {
			return false;
		}

		if (!isString(option.label)) {
			return false;
		}

		return option.label.toLowerCase().includes(filterText);
	});
}

function getOptionTitle(options: ReadonlyArray<Option>, key) {
	if (key === undefined || key === null) {
		return false;
	}

	let output: React.ReactNode = null;

	const option = options
		.filter(isActualOption)
		.find((option) => option.name === key);

	if (option) {
		output = option.label;

		if (option.icon) {
			output = (
				<AttachedIcon
					ellipsis={true}
					icon={option.icon}
				>
					{output}
				</AttachedIcon>
			);
		}
	}

	return output;
}

function getSelectOptionIndex(options: ReadonlyArray<Option>, selectedOption: string) {
	return options
		.filter(isActualOption)
		.map((option) => option.name)
		.indexOf(selectedOption);
}



export type SelectFieldRef = {
	setValue: (value: any) => void,
};



type Props = {
	attributes?: Record<string, any>,
	/** Additional dropdown footer */
	dropdownFooter?: React.ReactNode,
	dropdownWidth?: number,
	/** Always inline options */
	ellipsis?: boolean,
	hasScrollableDropdown?: boolean,
	isDisabled?: boolean,
	isInteractedByDefault?: boolean,
	name: string,
	onChangeCallback?: (name: string, value: string) => void,
	options: ReadonlyArray<Option>,
	/** Custom empty state placeholder */
	placeholder?: boolean | string,
	/** Possibility to specify boundary for Popper where we will detect overflow */
	popperBoundary?: Element | null,
	popperEnabled?: boolean,
	searchable?: boolean,
	size?: AbstractSelectFieldSize,
	width?: React.CSSProperties['width'],
};

const SelectField = React.forwardRef<SelectFieldRef, Props>((props, ref) => {
	const {
		attributes = {},
		dropdownFooter,
		dropdownWidth,
		ellipsis = true,
		hasScrollableDropdown = true,
		isDisabled = false,
		isInteractedByDefault = true,
		name,
		onChangeCallback,
		options,
		placeholder = true,
		popperBoundary = null,
		popperEnabled = true,
		searchable = false,
		size = AbstractSelectFieldSize.Default,
		width = 280,
	} = props;

	const formContext = useFormContext();
	const intl = useIntl();

	const formContextOnBlurHandler = formContext.onBlurHandler;
	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnFocusHandler = formContext.onFocusHandler;
	const formContextOnMountHandler = formContext.onMountHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const defaultValue = formContext.defaultValues[name];
	const value = formContext.values[name];

	const getDropdownScrollTop = React.useCallback(
		(selectedOption) => {
			return options
				.slice(0, getSelectOptionIndex(options, selectedOption) + 1)
				.reduce(
					(total, option) => total + ('divider' in option ? 9 : 34),
					-50,
				);
		},
		[
			options,
		],
	);

	const [dropdownScrollTop, setDropdownScrollTop] = React.useState(
		() => getDropdownScrollTop(defaultValue),
	);
	const [filterText, setFilterText] = React.useState('');
	const [highlightedOptionIndex, setHighlightedOptionIndex] = React.useState<false | number>(
		() => getSelectOptionIndex(
			options,
			defaultValue,
		),
	);
	const [native] = React.useState(() => isMobileDevice());
	const [selectedOption, setSelectedOption] = React.useState(value ?? defaultValue);

	const searchFieldRef = React.useRef<AbstractTextFieldRef>(null);
	const selectRef = React.useRef<AbstractSelectFieldRef>(null);

	React.useEffect(
		() => {
			formContextOnMountHandler(
				name,
				{
					interacted: isInteractedByDefault,
				},
			);

			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			isInteractedByDefault,
			name,
		],
	);

	React.useEffect(
		() => {
			setSelectedOption(value);
		},
		[
			value,
		],
	);

	React.useEffect(
		() => {
			if (selectedOption !== undefined) {
				return;
			}

			setFilterText('');
			setSelectedOption(defaultValue);
		},
		[
			defaultValue,
			selectedOption,
		],
	);

	const setValue = React.useCallback(
		(value) => {
			setSelectedOption(value);

			formContextOnChangeHandler(name, value).then(() => {
				if (onChangeCallback) {
					onChangeCallback(name, value);
				}
			});

			selectRef.current?.close();
		},
		[
			formContextOnChangeHandler,
			name,
			onChangeCallback,
		],
	);

	React.useImperativeHandle(ref, () => ({
		setValue,
	}));

	const handleDropdownCloseCallback = React.useCallback(
		() => {
			formContextOnBlurHandler(name);

			setFilterText('');
			setHighlightedOptionIndex(false);
		},
		[
			formContextOnBlurHandler,
			name,
		],
	);

	const handleDropdownOpenCallback = React.useCallback(
		() => {
			formContextOnFocusHandler(name);

			// reset highlighted option index which can be already different
			setDropdownScrollTop(getDropdownScrollTop(selectedOption));
			setHighlightedOptionIndex(getSelectOptionIndex(options, selectedOption));

			if (!native && searchable) {
				setTimeout(() => {
					searchFieldRef.current?.focus();
				}, 100);
			}
		},
		[
			formContextOnFocusHandler,
			getDropdownScrollTop,
			name,
			native,
			options,
			searchable,
			selectedOption,
		],
	);

	const handleOptionClick = React.useCallback(
		(value) => {
			const option = getArrayItemAtSafeIndex(
				options
					.filter(isActualOption)
					.filter((option) => option.name === value),
				0,
			);

			if (option.onClickCallback) {
				option.onClickCallback();

				selectRef.current?.close();
			} else {
				setHighlightedOptionIndex(getSelectOptionIndex(options, value));
				setSelectedOption(value);

				selectRef.current?.close();

				formContextOnChangeHandler(name, value).then(() => {
					if (onChangeCallback) {
						onChangeCallback(name, value);
					}
				});
			}
		},
		[
			formContextOnChangeHandler,
			name,
			onChangeCallback,
			options,
		],
	);

	const handleKeyDown = React.useCallback(
		({ isDropdownOpen }, e) => {
			e.stopPropagation();

			let filteredOptions = options;

			if (!isDropdownOpen) {
				return;
			}

			if (!!filterText) {
				filteredOptions = filterOptions(filteredOptions, filterText);
			}

			let optionIndex: number | false = false;

			// up key
			if (filteredOptions.length > 0 && e.keyCode == 38) {
				optionIndex = highlightedOptionIndex === false
					? filteredOptions.length - 1
					: highlightedOptionIndex;

				if (isNumber(highlightedOptionIndex) && highlightedOptionIndex > 0) {
					optionIndex = highlightedOptionIndex - 1;
				}

				if (isDividerOption(getArrayItemAtSafeIndex(filteredOptions, optionIndex))) {
					optionIndex -= 1;
				}

				setHighlightedOptionIndex(optionIndex);
			}

			// down key
			if (filteredOptions.length > 0 && e.keyCode == 40) {
				optionIndex = highlightedOptionIndex || 0;

				if (isNumber(highlightedOptionIndex) && highlightedOptionIndex < filteredOptions.length - 1) {
					optionIndex = highlightedOptionIndex + 1;
				}

				if (isDividerOption(getArrayItemAtSafeIndex(filteredOptions, optionIndex))) {
					optionIndex += 1;
				}

				setHighlightedOptionIndex(optionIndex);
			}

			// scroll to right position when using keys for navigation
			if (optionIndex !== false) {
				const distance = options
					.slice(0, optionIndex + 1)
					.reduce(
						(total, option) => total + (isActualOption(option) ? 34 : 9),
						0,
					);

				setDropdownScrollTop(
					distance - 68,
				);
			}
		},
		[
			filterText,
			highlightedOptionIndex,
			options,
		],
	);

	const handleKeyUp = React.useCallback(
		({ isDropdownOpen }, e) => {
			e.stopPropagation();

			let filteredOptions = options;

			if (
				e.keyCode === 13
				&& isDropdownOpen
				&& highlightedOptionIndex !== false
			) {
				if (!!filterText) {
					filteredOptions = filterOptions(filteredOptions, filterText);
				}

				const highlightedOption = getArrayItemAtSafeIndex(filteredOptions, highlightedOptionIndex);

				if (isActualOption(highlightedOption)) {
					handleOptionClick(
						highlightedOption.name,
					);
				}
			}
		},
		[
			filterText,
			handleOptionClick,
			highlightedOptionIndex,
			options,
		],
	);

	const handleSelectChange = React.useCallback(
		(event) => {
			setValue(event.target.value);
		},
		[
			setValue,
		],
	);

	const handleFilterTextChange = React.useCallback(
		(value) => {
			setHighlightedOptionIndex(0);
			setFilterText(value);
		},
		[],
	);

	const nativeSelectOptions = React.useMemo(
		() => {
			return options.filter(isActualOption);
		},
		[
			options,
		],
	);

	function renderDropdownOptions() {
		let filteredOptions = options;

		if (!!filterText) {
			filteredOptions = filterOptions(options, filterText);
		}

		const dropdownOptions: Array<FieldDropdownClickableOptionsProps> = [];

		filteredOptions.forEach((option, index) => {
			if (isDividerOption(option)) {
				dropdownOptions.push({
					divider: true,
				});

				return;
			}

			let label = option.label;

			if (!!filterText && isString(label)) {
				const filterTextIndex = label.toLowerCase().indexOf(filterText.toLowerCase());

				label = (
					<StringSelectionHighlight
						highlightEndIndex={filterTextIndex + filterText.length}
						highlightStartIndex={filterTextIndex}
						highlightType={StringSelectionHighlightType.Underlined}
					>
						{label}
					</StringSelectionHighlight>
				);
			}

			dropdownOptions.push({
				description: option.description,
				disabled: option.disabled,
				highlighted: index === highlightedOptionIndex,
				icon: option.icon,
				label,
				name: option.name,
				selected: selectedOption == option.name,
			});
		});

		return (
			<FieldDropdown
				contentMaxHeight={hasScrollableDropdown ? 263 : undefined}
				contentScrollTop={dropdownScrollTop}
				footer={dropdownFooter}
				placeholder={(
					<FormattedMessage
						{...messages.noFilterResults}
						values={{
							value: filterText,
						}}
					/>
				)}
				searchField={searchable && (
					<AbstractTextField
						attributes={{
							autoComplete: 'off',
							placeholder: intl.formatMessage(messages.searchPlaceholder),
						}}
						name={name + '__search'}
						onChangeCallback={handleFilterTextChange}
						ref={searchFieldRef}
						size={AbstractTextFieldSize.Small}
						type={AbstractTextFieldType.Search}
						width="100%"
					/>
				)}
			>
				{!(searchable && dropdownOptions.length === 0 && !!filterText) && (
					<FieldDropdownClickableOptions
						ellipsis={ellipsis}
						onOptionClickCallback={handleOptionClick}
						options={dropdownOptions}
						width={dropdownWidth}
					/>
				)}
			</FieldDropdown>
		);
	}

	function renderDesignedSelect() {
		if (native) {
			return null;
		}

		return (
			<AbstractSelectField
				dropdownWidth={dropdownWidth}
				isDisabled={formContext.isDisabled || isDisabled}
				label={getOptionTitle(options, selectedOption)}
				labelRenderer={(label, isOpen, size, isDisabled) => {
					if (label === false) {
						if (placeholder === true) {
							label = (
								<BlankValue>
									<FormattedMessage {...messages.placeholder} />
								</BlankValue>
							);
						} else if (typeof placeholder === 'string') {
							label = (
								<BlankValue>
									{placeholder}
								</BlankValue>
							);
						} else {
							label = placeholder;
						}
					}

					return (
						<AbstractSelectFieldToggler
							isDisabled={isDisabled}
							isOpen={isOpen}
							label={label}
							size={size}
						/>
					);
				}}
				onDropdownCloseCallback={handleDropdownCloseCallback}
				onDropdownOpenCallback={handleDropdownOpenCallback}
				onKeyDownCallback={handleKeyDown}
				onKeyUpCallback={handleKeyUp}
				popperBoundary={popperBoundary ?? undefined}
				popperEnabled={popperEnabled}
				ref={selectRef}
				scrollableDropdown={false}
				size={size}
				width={width}
			>
				{renderDropdownOptions()}
			</AbstractSelectField>
		);
	}

	function renderNativeSelect() {
		const additionalAttributes = Object.assign({}, attributes);

		if (formContext.isDisabled || isDisabled) {
			additionalAttributes.disabled = 'disabled';
		}

		let placeholderText = placeholder;

		if (placeholderText === true) {
			placeholderText = intl.formatMessage(messages.placeholder);
		} else if (placeholderText === false) {
			placeholderText = '';
		}

		const nativeSelect = (
			<NativeSelectField
				attributes={additionalAttributes}
				name={name}
				onBlurCallback={() => {
					formContext.onBlurHandler(name);
				}}
				onChangeCallback={handleSelectChange}
				onFocusCallback={() => {
					formContext.onFocusHandler(name);
				}}
				options={nativeSelectOptions}
				placeholder={placeholderText}
				size={matchAndReturn(size, {
					[AbstractSelectFieldSize.Default]: NativeSelectFieldSize.Default,
					[AbstractSelectFieldSize.Small]: NativeSelectFieldSize.Small,
				})}
				tabIndex={native ? 0 : -1}
				value={selectedOption}
				width={width}
			/>
		);

		if (!native) {
			return (
				<VisuallyHidden>
					{nativeSelect}
				</VisuallyHidden>
			);
		}

		return nativeSelect;
	}

	return (
		<>
			{renderNativeSelect()}
			{renderDesignedSelect()}
		</>
	);
});



export default SelectField;

export {
	AbstractSelectFieldSize as SelectFieldSize,
};
