import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	parenthesis: {
		id: 'ui.general.parenthesis',
	},
});



type Props = {
	content: React.ReactNode,
};

const Parenthesis: React.FC<Props> = (props) => {
	const {
		content,
	} = props;

	return (
		<FormattedMessage
			{...messages.parenthesis}
			values={{
				content,
			}}
		/>
	);
};



export default Parenthesis;
