import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BackButton from '~/components/app/BackButton';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
	ButtonsLayoutUsageContext,
} from '~/components/patterns/buttons/ButtonsLayout';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy from '~/components/logic/Copy';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import SegmentChangeTypeField from '../../fields/SegmentChangeTypeField';
import SegmentPercentageField from '../../fields/SegmentPercentageField';
import SegmentSelectField from '../../fields/SegmentSelectField';
import SubmitButton from '~/components/app/SubmitButton';

import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	breadcrumb: {
		id: 'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.breadcrumb',
	},
	continue: {
		id: 'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.continue',
	},
	description: {
		id: 'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.description',
	},
	fieldsChangeType: {
		id: 'ui.alertsConfiguration.fields.segmentChangeType',
	},
	fieldsSegment: {
		id: 'ui.alertsConfiguration.fields.segment',
	},
	fieldsSensitivity: {
		id: 'ui.alertsConfiguration.fields.sensitivity',
	},
	sidebar: {
		id: 'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.sidebar',
	},
	title: {
		id: 'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.title',
	},
});

const validations = {
	segmentChangeType: [
		{
			message: '',
			field: 'segmentChangeType',
			rule: ({ name, values }) => !!values[name],
		},
	],
	segmentName: [
		{
			message: '',
			field: 'segmentName',
			rule: ({ name, values }) => !!values[name],
		},
	],
};



type Props = {
	onSubmit: (input: {
		segmentChangeSizePercentage: number,
		segmentChangeType: string,
		segmentName: string,
	}) => void,
};

const SetupEnteredOrLeftAlertStep: React.FC<Props> = (props) => {
	const {
		onSubmit,
	} = props;

	const websiteId = useWebsiteId();

	return (
		<MultiStepModalStep
			breadcrumb={(
				<FormattedMessage {...messages.breadcrumb} />
			)}
			headerIconType={MultiStepModalStepHeaderIconType.Plus}
			name="setupEnteredOrLeftAlert"
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							link_article: (chunks) => (
								<ExternalLink
									href="https://www.contentkingapp.com/support/configuring-alerts/#pages-entering-leaving-segment"
									key="link"
								>
									{chunks}
								</ExternalLink>
							),
						}}
					/>
				</RichText>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Form
				defaultValues={{
					segmentName: null,
					segmentChangeSizePercentage: 1,
				}}
				key="setup-entered-or-left"
				onSuccess={onSubmit}
				validations={validations}
			>
				<ModalTextSection>
					<FormattedMessage {...messages.description} />
				</ModalTextSection>

				<CenteredFormWrapper>
					<FormRows>
						<FormRow
							htmlFor="segmentName"
							label={(
								<FormattedMessage {...messages.fieldsSegment} />
							)}
						>
							<SegmentSelectField
								name="segmentName"
								websiteId={websiteId}
							/>
						</FormRow>

						<FormRow
							htmlFor="segmentChangeType"
							label={(
								<FormattedMessage {...messages.fieldsChangeType} />
							)}
						>
							<SegmentChangeTypeField
								name="segmentChangeType"
							/>
						</FormRow>

						<FormRow
							htmlFor="segmentChangeSizePercentage"
							label={(
								<FormattedMessage {...messages.fieldsSensitivity} />
							)}
						>
							<SegmentPercentageField
								name="segmentChangeSizePercentage"
								websiteId={websiteId}
							/>
						</FormRow>
					</FormRows>

					<ButtonsLayout
						alignment={ButtonsLayoutAlignment.Right}
						layout={ButtonsLayoutType.Steps}
						usageContext={ButtonsLayoutUsageContext.InModals}
					>
						<BackButton />

						<SubmitButton>
							<FormattedMessage {...messages.continue} />
						</SubmitButton>
					</ButtonsLayout>
				</CenteredFormWrapper>
			</Form>
		</MultiStepModalStep>
	);
};



export default SetupEnteredOrLeftAlertStep;
