import React from 'react';



type Props = {
	columnSet: {
		name: string,
	},
};

const ColumnSetName: React.FC<Props> = (props) => {
	const {
		columnSet,
	} = props;

	return (
		<div className="column-set-name">
			{columnSet.name}
		</div>
	);
};



export default ColumnSetName;
