import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DataRetentionIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M11.6849 6.85492C16.9965 6.85492 21.3024 5.97404 21.3024 4.88742C21.3024 3.8008 16.9965 2.91992 11.6849 2.91992C6.37328 2.91992 2.06738 3.8008 2.06738 4.88742C2.06738 5.97404 6.37328 6.85492 11.6849 6.85492Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M2.06738 9.75C2.06738 10.8375 6.37238 11.7175 11.6849 11.7175C16.9974 11.7175 21.3024 10.8425 21.3024 9.75"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M2.06738 14.9126C2.06738 16.0001 6.37238 16.8801 11.6849 16.8801"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M21.3027 11.345V4.88745"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M2.06738 4.88745V20.1375C2.06738 21.1375 5.76238 21.9725 10.5274 22.09C10.7324 22.09 10.9399 22.09 11.1474 22.09"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M13.3945 21.4177C13.3941 19.3858 14.1436 17.4252 15.4992 15.9117C16.8549 14.3982 18.7214 13.4382 20.741 13.2157C22.7607 12.9931 24.7914 13.5237 26.4441 14.7058C28.0967 15.8879 29.255 17.6383 29.697 19.6215C30.139 21.6047 29.8335 23.6813 28.8392 25.4533C27.8449 27.2252 26.2316 28.5679 24.3086 29.2241C22.3856 29.8802 20.2881 29.8036 18.418 29.0089C16.548 28.2143 15.0369 26.7575 14.1745 24.9177"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M21.6621 16.9578V20.5128V21.4178L24.5246 23.0753"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.7116 20.3452L13.2716 21.6552L12.2441 20.0977"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default DataRetentionIconBuilder;

