import Immutable, {
	List,
	Map,
} from 'immutable';
import memoizee from 'memoizee';

import CK from '~/types/contentking';

import {
	get,
} from './api/API';

import {
	encodeFilterParameter,
} from './filterParameter';

import {
	PageIssue,
} from './issuesNew';

import {
	TYPE_MISSING,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	TYPE_UNREACHABLE,
} from './pages';

import removeDefaultValues from '~/utilities/removeDefaultValues';



export const ISSUE_CHANGE_CLOSED = 'closed';
export const ISSUE_CHANGE_IGNORED = 'ignored';
export const ISSUE_CHANGE_OPENED = 'opened';
export const ISSUE_CHANGE_OPENED_NEW_PAGE = 'opened_new_page';
export const ISSUE_CHANGE_UNIGNORED = 'unignored';

// open became not applicable
export const ISSUE_CHANGE_OPEN_NOT_APPLICABLE = 'open_not_applicable';
export const ISSUE_CHANGE_OPEN_NOT_PAGE = 'open_not_page';
// points moving in/out
export const ISSUE_CHANGE_NOT_APPLICABLE_POINTS = 'not_applicable_points';
export const ISSUE_CHANGE_NOT_PAGE_POINTS = 'not_page_points';
export const ISSUE_CHANGE_NOT_REQUIRED_POINTS = 'not_required_points';
export const ISSUE_CHANGE_POINTS_NOT_APPLICABLE = 'points_not_applicable';
export const ISSUE_CHANGE_POINTS_NOT_PAGE = 'points_not_page';
export const ISSUE_CHANGE_POINTS_NOT_REQUIRED = 'points_not_required';
// gained/to gain points becoming more/less important
export const ISSUE_CHANGE_OPEN_LESS_IMPORTANT = 'open_less_important';
export const ISSUE_CHANGE_OPEN_MORE_IMPORTANT = 'open_more_important';
export const ISSUE_CHANGE_POINTS_LESS_IMPORTANT = 'points_less_important';
export const ISSUE_CHANGE_POINTS_MORE_IMPORTANT = 'points_more_important';
// segment-related only
export const ISSUE_CHANGE_BROKEN_ENTERED = 'broken_entered';
export const ISSUE_CHANGE_BROKEN_LEFT = 'broken_left';
export const ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS = 'not_in_segment_points';
export const ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT = 'points_not_in_segment';



export const ISSUE_CHANGES_GROUP_OPENED = 'opened';
export const ISSUE_CHANGES_GROUP_RESOLVED = 'resolved';
export const ISSUE_CHANGES_GROUP_IGNORING = 'ignoring';
export const ISSUE_CHANGES_GROUP_SEGMENT_CHANGED = 'segment_changed';
export const ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE = 'no_longer_applicable';
export const ISSUE_CHANGES_GROUP_UNAFFECTED = 'unaffected';
export const ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES = 'only_importance_changes';



export const LIST_OF_ALL_ISSUE_CHANGES = List([
	ISSUE_CHANGE_CLOSED,
	ISSUE_CHANGE_IGNORED,
	ISSUE_CHANGE_OPENED,
	ISSUE_CHANGE_OPENED_NEW_PAGE,
	ISSUE_CHANGE_UNIGNORED,
	// segment-related only
	ISSUE_CHANGE_BROKEN_ENTERED,
	ISSUE_CHANGE_BROKEN_LEFT,
	ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS,
	ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT,
	// open became not applicable
	ISSUE_CHANGE_OPEN_NOT_APPLICABLE,
	ISSUE_CHANGE_OPEN_NOT_PAGE,
	// points moving in/out
	ISSUE_CHANGE_NOT_APPLICABLE_POINTS,
	ISSUE_CHANGE_NOT_PAGE_POINTS,
	ISSUE_CHANGE_NOT_REQUIRED_POINTS,
	ISSUE_CHANGE_POINTS_NOT_APPLICABLE,
	ISSUE_CHANGE_POINTS_NOT_PAGE,
	ISSUE_CHANGE_POINTS_NOT_REQUIRED,
	// gained/to gain points becoming more/less important
	ISSUE_CHANGE_OPEN_LESS_IMPORTANT,
	ISSUE_CHANGE_OPEN_MORE_IMPORTANT,
	ISSUE_CHANGE_POINTS_LESS_IMPORTANT,
	ISSUE_CHANGE_POINTS_MORE_IMPORTANT,
]);

export const LIST_OF_PRIMARY_ISSUE_CHANGES = List([
	ISSUE_CHANGE_OPENED,
	ISSUE_CHANGE_OPENED_NEW_PAGE,
	ISSUE_CHANGE_CLOSED,
	ISSUE_CHANGE_IGNORED,
	ISSUE_CHANGE_UNIGNORED,
]);

export const LIST_OF_SECONDARY_ISSUE_CHANGES = List([
	ISSUE_CHANGE_BROKEN_ENTERED,
	ISSUE_CHANGE_BROKEN_LEFT,
	ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS,
	ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT,
	ISSUE_CHANGE_OPEN_NOT_APPLICABLE,
	ISSUE_CHANGE_OPEN_NOT_PAGE,
	ISSUE_CHANGE_NOT_APPLICABLE_POINTS,
	ISSUE_CHANGE_NOT_PAGE_POINTS,
	ISSUE_CHANGE_NOT_REQUIRED_POINTS,
	ISSUE_CHANGE_POINTS_NOT_APPLICABLE,
	ISSUE_CHANGE_POINTS_NOT_PAGE,
	ISSUE_CHANGE_POINTS_NOT_REQUIRED,
	ISSUE_CHANGE_OPEN_LESS_IMPORTANT,
	ISSUE_CHANGE_OPEN_MORE_IMPORTANT,
	ISSUE_CHANGE_POINTS_LESS_IMPORTANT,
	ISSUE_CHANGE_POINTS_MORE_IMPORTANT,
]);

export const LIST_OF_SEGMENT_RELATED_CHANGES = List([
	ISSUE_CHANGE_BROKEN_ENTERED,
	ISSUE_CHANGE_BROKEN_LEFT,
	ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS,
	ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT,
]);

export const ISSUE_CHANGES_GROUPING = Map({
	[ISSUE_CHANGES_GROUP_OPENED]: List([
		ISSUE_CHANGE_OPENED,
		ISSUE_CHANGE_OPENED_NEW_PAGE,
	]),
	[ISSUE_CHANGES_GROUP_RESOLVED]: List([
		ISSUE_CHANGE_CLOSED,
	]),
	[ISSUE_CHANGES_GROUP_IGNORING]: List([
		ISSUE_CHANGE_IGNORED,
		ISSUE_CHANGE_UNIGNORED,
	]),
	[ISSUE_CHANGES_GROUP_SEGMENT_CHANGED]: List([
		ISSUE_CHANGE_BROKEN_ENTERED,
		ISSUE_CHANGE_BROKEN_LEFT,
		ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS,
		ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT,
	]),
	[ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE]: List([
		ISSUE_CHANGE_OPEN_NOT_APPLICABLE,
		ISSUE_CHANGE_OPEN_NOT_PAGE,
	]),
	[ISSUE_CHANGES_GROUP_UNAFFECTED]: List([
		ISSUE_CHANGE_NOT_APPLICABLE_POINTS,
		ISSUE_CHANGE_POINTS_NOT_APPLICABLE,
		ISSUE_CHANGE_NOT_REQUIRED_POINTS,
		ISSUE_CHANGE_POINTS_NOT_REQUIRED,
		ISSUE_CHANGE_NOT_PAGE_POINTS,
		ISSUE_CHANGE_POINTS_NOT_PAGE,
	]),
	[ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES]: List([
		ISSUE_CHANGE_OPEN_LESS_IMPORTANT,
		ISSUE_CHANGE_OPEN_MORE_IMPORTANT,
		ISSUE_CHANGE_POINTS_LESS_IMPORTANT,
		ISSUE_CHANGE_POINTS_MORE_IMPORTANT,
	]),
});

export const LIST_OF_PRIMARY_ISSUE_CHANGES_GROUPS = ISSUE_CHANGES_GROUPING
	.keySeq()
	.toList()
	.filter((issueChangesGroup) => LIST_OF_PRIMARY_ISSUE_CHANGES.includes(ISSUE_CHANGES_GROUPING.get(issueChangesGroup).first()));

export const LIST_OF_SECONDARY_ISSUE_CHANGES_GROUPS = ISSUE_CHANGES_GROUPING
	.keySeq()
	.toList()
	.filter((issueChangesGroup) => LIST_OF_SECONDARY_ISSUE_CHANGES.includes(ISSUE_CHANGES_GROUPING.get(issueChangesGroup).first()));

const SHARED_DEFAULT_FILTER = Immutable.fromJS({
	[CK.PagesCommonColumn.IssueChange]: LIST_OF_ALL_ISSUE_CHANGES,
	segment: 'all',
	[CK.PagesCommonColumn.Type]: [
		TYPE_PAGE,
		TYPE_MISSING,
		TYPE_REDIRECT,
		TYPE_SERVER_ERROR,
		TYPE_UNREACHABLE,
	],
});

const ISSUE_DEFAULT_FILTERS = Immutable.fromJS({
	[PageIssue.AnalyticsAnalyticsMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.AnalyticsVisualAnalyticsMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.CanonicalLinkIncorrectlyCanonicalized]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.CanonicalLinkMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.CanonicalLinkPointsToUnindexable]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.CanonicalLinkTooMany]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1Duplicate]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1IncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1LevelsSkipped]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1Missing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.H1TooMany]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangConflictingTargets]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangInvalidTarget]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangInvalidValue]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangMissingSelfReference]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangMissingSpecificAudience]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.HreflangMissingXDefault]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.ImagesAltAttribute]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.ImagesMixedTransport]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseCls]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseFcp]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseLcp]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthousePerformance]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseSi]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseTbt]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LighthouseTti]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LinksBroken]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LinksRedirected]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.LinksToCanonicalized]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.MetaDescriptionDuplicate]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.MetaDescriptionIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.MetaDescriptionMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.MetaDescriptionTooMany]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphDescriptionIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphDescriptionMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphImageMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphTitleIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphTitleMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.OpenGraphUrlMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.RobotDirectivesConflicting]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.RobotDirectivesInvalid]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.RobotDirectivesUnsupported]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.SchemaOrgErrors]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.SchemaOrgInvalidJson]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TitleDuplicate]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TitleIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TitleMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TitleTooMany]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsDescriptionIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsDescriptionMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsImageMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsSiteMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsTitleIncorrectLength]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsTitleMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsTypeInvalid]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.TwitterCardsTypeMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.XmlSitemapIncorrectlyMissing]: SHARED_DEFAULT_FILTER.merge({}),
	[PageIssue.XmlSitemapIncorrectlyPresent]: SHARED_DEFAULT_FILTER.merge({}),
});

export const DEFAULT_SORT_BY = new Map({
	key: CK.PagesCommonColumn.Relevance,
	direction: false,
});



export function createAffectedPagesComparisonFilterParameter(filter, sortBy, issueCategory, issueType) {
	return encodeFilterParameter({
		defaultFilter: getDefaultFilter(issueCategory, issueType, filter ? filter.get('segment', 'all') : 'all'),
		defaultSortBy: DEFAULT_SORT_BY,
		filter,
		sortBy,
	});
}



export const createFilter = memoizee((filter, issueCategory, issueType) => {
	return getDefaultFilter(issueCategory, issueType, filter.get('segment', 'all')).merge(filter);
});



export function getGroupOfIssueChange(issueChange) {
	return ISSUE_CHANGES_GROUPING.findKey((changes) => changes.includes(issueChange));
}



export function getDefaultFilter(issueCategory, issueType, scope) {
	let defaultFilter = ISSUE_DEFAULT_FILTERS.get(issueType, SHARED_DEFAULT_FILTER);

	if (scope === 'all') {
		defaultFilter = defaultFilter.set(
			CK.PagesCommonColumn.IssueChange,
			defaultFilter.get(CK.PagesCommonColumn.IssueChange).filter((issueChange) => [
				ISSUE_CHANGE_BROKEN_ENTERED,
				ISSUE_CHANGE_BROKEN_LEFT,
				ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS,
				ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT,
			].indexOf(issueChange) === -1),
		);
	}

	return defaultFilter;
}



export function loadAffectedPagesComparisonViaApi(websiteId, dateFrom, dateTo, parameters) {
	return get('/websites/' + websiteId + '/issues/affected_pages/comparison/' + dateFrom + '...' + dateTo, parameters);
}



export function removeDefaultFilterValues(activeFilters, issueCategory, issueType) {
	return removeDefaultValues(
		activeFilters,
		getDefaultFilter(issueCategory, issueType, activeFilters.get('segment', 'all')),
	);
}
