import React from 'react';

import {
	IsConductorClient,
} from '~/config';

import AcceptInvitationWith2FAScreen from '~/components/app/AcceptInvitationWith2FAScreen';
import ConductorAuthenticationPage from '~/components/app/ConductorAuthenticationPage';
import KickstartPage from '~/components/app/KickstartPage';
import LoginScreen from './app/LoginScreen';

import useUrlState from '~/hooks/useUrlState';



const UnauthenticatedSection: React.FC = () => {
	const urlState = useUrlState();

	if (IsConductorClient) {
		return (
			<ConductorAuthenticationPage />
		);
	}

	if (urlState.name === 'acceptInvitationWith2FA') {
		return (
			<AcceptInvitationWith2FAScreen />
		);
	}

	if (urlState.name === 'kickstart') {
		return (
			<KickstartPage />
		);
	}

	return (
		<LoginScreen />
	);
};



export default UnauthenticatedSection;
