/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferAgencyAccessMutationVariables = GraphQL.Exact<{
  agencyContactEmail: GraphQL.Scalars['String']['input'];
  clientId: GraphQL.Scalars['AccountId']['input'];
  level: GraphQL.AccountAccessConnectionLevel;
}>;


export type OfferAgencyAccessMutation = { readonly __typename?: 'Mutation', readonly OfferAgencyAccess: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly agencyConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessAgencyConnection', readonly agencyAccountId: CK.AccountId, readonly agencyAccountName: string, readonly id: any, readonly inviterEmail: string | null, readonly isInitiatedByAgency: boolean, readonly isPending: boolean, readonly level: GraphQL.AccountAccessConnectionLevel }> } | null } } };


export const OfferAgencyAccessDocument = gql`
    mutation OfferAgencyAccess($agencyContactEmail: String!, $clientId: AccountId!, $level: AccountAccessConnectionLevel!) {
  OfferAgencyAccess(
    agencyContactEmail: $agencyContactEmail
    clientId: $clientId
    level: $level
  ) {
    query {
      account(id: $clientId) {
        agencyConnections {
          agencyAccountId
          agencyAccountName
          id
          inviterEmail
          isInitiatedByAgency
          isPending
          level
        }
        id
      }
    }
  }
}
    `;
export type OfferAgencyAccessMutationFn = Apollo.MutationFunction<OfferAgencyAccessMutation, OfferAgencyAccessMutationVariables>;

/**
 * __useOfferAgencyAccessMutation__
 *
 * To run a mutation, you first call `useOfferAgencyAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferAgencyAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerAgencyAccessMutation, { data, loading, error }] = useOfferAgencyAccessMutation({
 *   variables: {
 *      agencyContactEmail: // value for 'agencyContactEmail'
 *      clientId: // value for 'clientId'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useOfferAgencyAccessMutation(baseOptions?: Apollo.MutationHookOptions<OfferAgencyAccessMutation, OfferAgencyAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OfferAgencyAccessMutation, OfferAgencyAccessMutationVariables>(OfferAgencyAccessDocument, options);
      }
export type OfferAgencyAccessMutationHookResult = ReturnType<typeof useOfferAgencyAccessMutation>;
export type OfferAgencyAccessMutationResult = Apollo.MutationResult<OfferAgencyAccessMutation>;
export type OfferAgencyAccessMutationOptions = Apollo.BaseMutationOptions<OfferAgencyAccessMutation, OfferAgencyAccessMutationVariables>;