import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ShoppingCartCheckIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="m12.657 13.9999909c0-.5522756-.4477153-1-1-1h-3.418c-.11416427-.0019033-.21252619-.0809221-.239-.1919909l-1.15-5c-.01759216-.07371265-.00079878-.15142622.04566007-.21129787.04645884-.05987165.11756764-.09543788.19333993-.0967112h13.191c.1606336.00013101.3114271.07742194.4053174.20775912.0938904.13033718.1194429.29784731.0686826.45024995l-.749 2.248c-.1143566.3397509-.0383675.7147147.1992398.9831377s.6005796.38935.9516964.3170652c.3511168-.0722849.636806-.3267527.7490638-.6672029l1-3c.1967259-.58876326.0979341-1.23623314-.265421-1.73953851-.3633552-.50330537-.9468204-.80087121-1.567579-.79947056h-14.558c-.11638451.00007929-.21729259-.08048107-.243-.19399093l-.878-3.819c-.20280892-.87285581-.98189664-1.4897373-1.878-1.48700907h-2.515c-.55228475 0-1 .44772432-1 1 0 .55229382.44771525 1 1 1l2.24-.05499093c.11860844-.00223834.22271749.07855028.25.194l3.357 14.6c.02393086.1020702-.01890631.2081623-.107.265-.85058631.5534917-1.22564356 1.6076933-.91577721 2.5740436.30986636.9663504 1.22802711 1.6058734 2.24187287 1.5615232s1.87265486-.761606 2.09695394-1.7513231c.2242991-.9897172-.24138043-2.0071411-1.1370496-2.4842437-.06341763-.0347372-.10918724-.0946736-.126-.165l-.33-1.437c-.01567016-.0736887.00272527-.1505148.050071-.2091148.04734574-.0586001.1185927-.0927249.193929-.0928943h2.843c.5522847 0 1-.4477062 1-1z"
				fill={color}
			/>
			<path
				d="m18.25 12.5c-3.1756373 0-5.75 2.5743627-5.75 5.75s2.5743627 5.75 5.75 5.75 5.75-2.5743627 5.75-5.75c-.0033071-3.1742665-2.5757335-5.7466929-5.75-5.75zm2.939 4.673-2.542 3.389c-.2442104.3275918-.6193491.5321465-1.027.56h-.1c-.3719473-.0002379-.7285115-.1484765-.991-.412l-1.314-1.31c-.2753589-.2955098-.2672339-.7560097.0183782-1.0416218s.746112-.2937371 1.0416218-.0183782l1.034 1.034c.051144.0514113.1220915.0780816.1944374.0730923.0723459-.0049894.1389605-.0411468.1825626-.0990923l2.3-3.073c.1607695-.2143594.423686-.3266661.6897114-.2946153.2660255.0320508.4947442.2035899.6000001.45.1052558.2464102.071058.5302559-.0897115.7446153z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ShoppingCartCheckIconBuilder;

