import React from 'react';

import styles from './ConductorLink.module.scss';



type Props = {
	children: React.ReactNode,
	href: string,
	target?: React.AllHTMLAttributes<HTMLAnchorElement>['target'],
};

const ConductorLink: React.FC<Props> = (props) => {
	const {
		children,
		href,
		target = '_blank',
	} = props;

	return (
		<a
			className={styles.component}
			href={href}
			target={target}
		>
			{children}
		</a>
	);
};



export default ConductorLink;
