/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageTypeLastChangedAtQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageTypeLastChangedAtQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageTypeLastChangedAt: CK.Timestamp | null } | null };


export const PageTypeLastChangedAtDocument = gql`
    query PageTypeLastChangedAt($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageTypeLastChangedAt
  }
}
    `;

/**
 * __usePageTypeLastChangedAtQuery__
 *
 * To run a query within a React component, call `usePageTypeLastChangedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageTypeLastChangedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageTypeLastChangedAtQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageTypeLastChangedAtQuery(baseOptions: Apollo.QueryHookOptions<PageTypeLastChangedAtQuery, PageTypeLastChangedAtQueryVariables> & ({ variables: PageTypeLastChangedAtQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageTypeLastChangedAtQuery, PageTypeLastChangedAtQueryVariables>(PageTypeLastChangedAtDocument, options);
      }
export function usePageTypeLastChangedAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageTypeLastChangedAtQuery, PageTypeLastChangedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageTypeLastChangedAtQuery, PageTypeLastChangedAtQueryVariables>(PageTypeLastChangedAtDocument, options);
        }
export function usePageTypeLastChangedAtSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageTypeLastChangedAtQuery, PageTypeLastChangedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageTypeLastChangedAtQuery, PageTypeLastChangedAtQueryVariables>(PageTypeLastChangedAtDocument, options);
        }
export type PageTypeLastChangedAtQueryHookResult = ReturnType<typeof usePageTypeLastChangedAtQuery>;
export type PageTypeLastChangedAtLazyQueryHookResult = ReturnType<typeof usePageTypeLastChangedAtLazyQuery>;
export type PageTypeLastChangedAtSuspenseQueryHookResult = ReturnType<typeof usePageTypeLastChangedAtSuspenseQuery>;
export type PageTypeLastChangedAtQueryResult = Apollo.QueryResult<PageTypeLastChangedAtQuery, PageTypeLastChangedAtQueryVariables>;