import type Immutable from 'immutable';
import React from 'react';

import CK from '~/types/contentking';

import ColumnValueFormatter from './ColumnValueFormatter';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';

import {
	TYPE_MISSING,
	type TYPE_NOT_CRAWLED_YET,
	type TYPE_NO_DATA,
	TYPE_OTHER,
	TYPE_OVERQUOTA,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	TYPE_UNREACHABLE,
} from '~/model/pages';

import {
	CommonColumns,
	PagesColumnsCategory,
	getColumnCategory,
} from '~/model/pagesColumns';



const ColumnsWithFixedWidth: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.Health,
];

const PageTypeConstraints = [
	{
		pageTypes: [
			TYPE_PAGE,
		],
		columns: [
			CK.PagesCommonColumn.AnalyticsServices,
			CK.PagesCommonColumn.CanonicalUrl,
			CK.PagesCommonColumn.H1,
			CK.PagesCommonColumn.Health,
			CK.PagesCommonColumn.HreflangLanguage,
			CK.PagesCommonColumn.LighthouseCumulativeLayoutShift,
			CK.PagesCommonColumn.LighthouseFirstContentfulPaint,
			CK.PagesCommonColumn.LighthouseLargestContentfulPaint,
			CK.PagesCommonColumn.LighthousePerformance,
			CK.PagesCommonColumn.LighthouseSpeedIndex,
			CK.PagesCommonColumn.LighthouseTimeToInteractive,
			CK.PagesCommonColumn.LighthouseTotalBlockingTime,
			CK.PagesCommonColumn.LinkAmp,
			CK.PagesCommonColumn.LinkNext,
			CK.PagesCommonColumn.LinkPrev,
			CK.PagesCommonColumn.MetaDescription,
			CK.PagesCommonColumn.MobileVariant,
			CK.PagesCommonColumn.NumberOfHreflangs,
			CK.PagesCommonColumn.NumberOfOutgoingExternalLinks,
			CK.PagesCommonColumn.NumberOfOutgoingInternalLinks,
			CK.PagesCommonColumn.OpenGraphDescription,
			CK.PagesCommonColumn.OpenGraphImage,
			CK.PagesCommonColumn.OpenGraphTitle,
			CK.PagesCommonColumn.OpenGraphType,
			CK.PagesCommonColumn.OpenGraphUrl,
			CK.PagesCommonColumn.Relevance,
			CK.PagesCommonColumn.SchemaOrgNumberOfTypes,
			CK.PagesCommonColumn.SchemaOrgTypes,
			CK.PagesCommonColumn.TagManagers,
			CK.PagesCommonColumn.TimeServerResponse,
			CK.PagesCommonColumn.Title,
			CK.PagesCommonColumn.TwitterCard,
			CK.PagesCommonColumn.TwitterDescription,
			CK.PagesCommonColumn.TwitterImage,
			CK.PagesCommonColumn.TwitterSite,
			CK.PagesCommonColumn.TwitterTitle,
			CK.PagesCommonColumn.VisualAnalyticsServices,
		],
	},
	{
		pageTypes: [
			TYPE_REDIRECT,
		],
		columns: [
			CK.PagesCommonColumn.Redirect,
			CK.PagesCommonColumn.TimeServerResponse,
		],
	},
	{
		pageTypes: [
			TYPE_MISSING,
			TYPE_SERVER_ERROR,
		],
		columns: [
			CK.PagesCommonColumn.TimeServerResponse,
		],
	},
];



function compilePageTypeConstraints(): Readonly<Record<string, Array<CK.PagesColumn>>> {
	const result = {
		[TYPE_MISSING]: [],
		[TYPE_OTHER]: [
			CK.PagesCommonColumn.Url,
		],
		[TYPE_PAGE]: [],
		[TYPE_REDIRECT]: [],
		[TYPE_SERVER_ERROR]: [],
		[TYPE_UNREACHABLE]: [],
		[TYPE_OVERQUOTA]: [],
	} as Record<string, Array<CK.PagesColumn>>;

	PageTypeConstraints.forEach((constraint) => {
		constraint.pageTypes.forEach((pageType) => {
			result[pageType] = constraint.columns;
		});
	});

	CommonColumns.forEach((column) => {
		const isListed = PageTypeConstraints.some((constraint) => constraint.columns.includes(column));

		if (!isListed) {
			[
				TYPE_MISSING,
				TYPE_PAGE,
				TYPE_REDIRECT,
				TYPE_SERVER_ERROR,
				TYPE_UNREACHABLE,
			].forEach((pageType) => {
				result[pageType]?.push(column);
			});
		}
	});

	return result;
}

const CompiledPageTypeConstraints = compilePageTypeConstraints();



type Props = {
	column: CK.PagesColumn,
	customElements?: boolean | Array<string> | Immutable.List<string>,
	pageType: (
		| typeof TYPE_MISSING
		| typeof TYPE_NO_DATA
		| typeof TYPE_NOT_CRAWLED_YET
		| typeof TYPE_OVERQUOTA
		| typeof TYPE_PAGE
		| typeof TYPE_OTHER
		| typeof TYPE_REDIRECT
		| typeof TYPE_SERVER_ERROR
		| typeof TYPE_UNREACHABLE
	),
	value: any,
	zIndex?: number,
};

const FieldFormatter: React.FC<Props> = (props) => {
	const {
		column,
		customElements,
		pageType,
		value,
		zIndex,
	} = props;

	const columnCategory = getColumnCategory(column);

	if (columnCategory === PagesColumnsCategory.CustomElements) {
		if (pageType !== TYPE_PAGE) {
			return (
				<NotApplicableValue ellipsis={true} />
			);
		}
	} else if (columnCategory === PagesColumnsCategory.EnrichmentFields) {
		// no restrictions
	} else {
		if (!CompiledPageTypeConstraints[pageType]?.includes(column)) {
			return (
				<NotApplicableValue ellipsis={true} />
			);
		}
	}

	let formattedValue = (
		<ColumnValueFormatter
			column={column}
			customElements={customElements}
			value={value}
		/>
	);

	if (!ColumnsWithFixedWidth.includes(column)) {
		formattedValue = (
			<Ellipsis popupZIndex={zIndex}>
				{formattedValue}
			</Ellipsis>
		);
	}

	return formattedValue;
};



export default React.memo(FieldFormatter);
