import type CK from '~/types/contentking';

import {
	useWebsiteScopeIssuesQuery,
} from './useWebsiteScopeIssues.gql';

import usePollInterval from '~/hooks/usePollInterval';



function useWebsiteScopeIssues(websiteId: CK.WebsiteId | null) {
	const { data } = useWebsiteScopeIssuesQuery({
		pollInterval: usePollInterval(30000),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return data?.website?.scopeIssues ?? null;
}



export default useWebsiteScopeIssues;
