import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useInitiateSepaDirectDebitSetupMutation,
} from './useInitiateSepaDirectDebitSetup.gql';



function useInitiateSepaDirectDebitSetup() {
	const [initiateSepaDirectDebitSetup] = useInitiateSepaDirectDebitSetupMutation();

	return React.useCallback(
		async (accountId: CK.AccountId): Promise<GraphQL.InitiateSepaDirectDebitSetupResult['clientSecret'] | null> => {
			const { data } = await initiateSepaDirectDebitSetup({
				variables: {
					accountId,
				},
			});

			return data?.InitiateSepaDirectDebitSetup.clientSecret ?? null;
		},
		[
			initiateSepaDirectDebitSetup,
		],
	);
}



export default useInitiateSepaDirectDebitSetup;
