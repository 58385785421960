import Immutable from 'immutable';
import React from 'react';

import FilterAndSort from '~/utilities/FilterAndSort';
import removeDefaultValues from '~/utilities/removeDefaultValues';



type Setup<Item> = (filterAndSort: FilterAndSort<Item>) => {
	defaultFilter: Record<string, any>,
	defaultSortBy: {
		key: string,
		direction: boolean,
	},
};

function useDataSource<Item>(
	setup: Setup<Item>,
	deps: ReadonlyArray<any>,
	items: ReadonlyArray<Item> | null,
) {
	const {
		defaultFilter,
		defaultSortBy,
		filterAndSort,
	} = React.useMemo(
		() => {
			const filterAndSort = new FilterAndSort<Item>();

			const {
				defaultFilter,
				defaultSortBy,
			} = setup(filterAndSort);

			return {
				defaultFilter,
				defaultSortBy,
				filterAndSort: filterAndSort.createRunner(),
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		deps,
	);

	const defaults = React.useMemo(
		() => ({
			filter: Immutable.Map(defaultFilter),
			sortBy: Immutable.Map(defaultSortBy),
		}),
		[
			defaultFilter,
			defaultSortBy,
		],
	);

	const [filter, setFilter] = React.useState(defaults.filter);
	const [sortBy, setSortBy] = React.useState(defaults.sortBy);

	const updateFilter = React.useCallback(
		(filterUpdate) => {
			setFilter(
				(filter) => filter.merge(filterUpdate),
			);
		},
		[],
	);

	const removeFilter = React.useCallback(
		(field) => {
			setFilter(
				(filter) => {
					return filter.set(
						field,
						defaults.filter.get(field, null),
					);
				},
			);
		},
		[
			defaults,
		],
	);

	const updateSortBy = React.useCallback(
		(sortBy) => {
			setSortBy(
				sortBy,
			);
		},
		[],
	);

	const listAll = React.useCallback(
		(): ReadonlyArray<Item> => {
			return filterAndSort(
				items ?? [],
				defaults.filter,
				defaults.sortBy,
			);
		},
		[
			defaults,
			filterAndSort,
			items,
		],
	);

	const listFiltered = React.useCallback(
		(): ReadonlyArray<Item> => {
			return filterAndSort(
				items ?? [],
				filter,
				sortBy,
			);
		},
		[
			filter,
			filterAndSort,
			items,
			sortBy,
		],
	);

	const activeFilter = React.useMemo(
		() => removeDefaultValues(filter, defaults.filter),
		[
			defaults,
			filter,
		],
	);

	return {
		activeFilter,
		filter,
		listAll,
		listFiltered,
		removeFilter,
		sortBy,
		updateFilter,
		updateSortBy,
	};
}



export default useDataSource;
