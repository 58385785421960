import React from 'react';

import type GraphQL from '~/types/graphql';

import PlanFeaturesList from '~/components/logic/account/PlanFeaturesList';

import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountId from '~/hooks/useAccountId';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';

import {
	PLAN_LOWER,
} from '~/model/plans';

import {
	type Benefit,
} from '~/model/benefits';
import {
	PLAN_BENEFITS,
	comparePlans,
} from '~/model/universal';



type Props = {
	compact?: boolean,
	plan: GraphQL.AccountPlan,
};

const PlanFeatures: React.FC<Props> = (props) => {
	const {
		compact = false,
		plan,
	} = props;

	const accountId = useAccountId();
	const accountTariff = useAccountTariff(accountId);
	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const basePlan = useAccountPlan(accountId);

	const benefits = accountTariff !== null ? PLAN_BENEFITS[accountTariff][plan] : [];
	const lostBenefits: Array<Benefit> = [];

	if (benefits.length === 0) {
		return null;
	}

	if (basePlan && comparePlans(basePlan, plan) === PLAN_LOWER) {
		benefits.forEach((benefit) => {
			if (
				benefit.feature
				&& benefit.disabled
				&& accountFeaturesUsage !== null
				&& accountFeaturesUsage.usedFeatures.includes(benefit.feature)
			) {
				lostBenefits.push(benefit.name);
			}
		});
	}

	return (
		<PlanFeaturesList
			benefits={benefits}
			compact={compact}
			lostBenefits={lostBenefits}
		/>
	);
};



export default React.memo(PlanFeatures);
