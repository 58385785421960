import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



export const WIDTH_LIMIT_LARGE = 1205;

class ResponsiveRow extends Component {

	render() {
		const {
			children,
			reversed,
			widthLimit,
		} = this.props;

		const componentStyle = {};

		if (widthLimit) {
			componentStyle.maxWidth = widthLimit;
		}

		const componentClasses = classNames({
			'responsive-row': true,
			'responsive-row--is-reversed': reversed,
		});

		return (
			<section
				className={componentClasses}
				style={componentStyle}
			>
				{children}
			</section>
		);
	}

}

ResponsiveRow.propTypes = {
	reversed: PropTypes.bool,
	widthLimit: PropTypes.number,
};



export default ResponsiveRow;
