import type CK from '~/types/contentking';

import {
	useWebsiteIsDiscoveredQuery,
} from './useWebsiteIsDiscovered.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteIsDiscovered(websiteId: CK.WebsiteId | null): boolean | null {
	const { data } = useWebsiteIsDiscoveredQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.isDiscovered) ?? null;
}



export default useWebsiteIsDiscovered;
