import classNames from 'classnames';
import React from 'react';

import RichText from '~/components/patterns/typography/RichText';

import styles from './Announcement.module.scss';



export enum AnnouncementStyle {
	Default = 'default',
	Light = 'light',
}



type Props = {
	/** Call-to-action footer element */
	ctaElement?: React.ReactNode,
	children: React.ReactNode,
	/** Compact look of announcement */
	compact?: boolean,
	style?: AnnouncementStyle,
	/** Announcement title */
	title?: React.ReactNode,
};

const Announcement: React.FC<Props> = (props) => {
	const {
		children,
		compact,
		ctaElement,
		style = AnnouncementStyle.Default,
		title,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isLight]: style === AnnouncementStyle.Light,
		[styles.isCompact]: compact,
	});

	return (
		<div className={componentClasses}>
			<div className={styles.content}>
				{title && (
					<div className={styles.title}>
						{title}
					</div>
				)}
				{React.Children.count(children) > 0 && (
					<div className={styles.message}>
						<RichText>
							{children}
						</RichText>
					</div>
				)}
			</div>
			{ctaElement && (
				<div className={styles.ctaElement}>
					{ctaElement}
				</div>
			)}
		</div>
	);
};



export default Announcement;
