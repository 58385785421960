import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Emphasis from '~/components/patterns/typography/Emphasis';



const messages = defineMessages({
	label: {
		id: 'ui.enrichmentFields.notProvided',
	},
});



const EnrichmentFieldNotProvidedValue: React.FC = () => {
	return (
		<Emphasis>
			<FormattedMessage {...messages.label} />
		</Emphasis>
	);
};



export default EnrichmentFieldNotProvidedValue;
