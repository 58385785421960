import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';

import {
	getCategoryUrlIdentifier,
} from '~/model/issues/identifierMapping';

import {
	type IssueCategoryName,
} from '~/model/issuesNew';



const messages = defineMessages({
	notAvailable: {
		id: 'ui.issuesConfiguration.category.notAvailableTooltip',
	},
});



type Props = {
	hasConfiguration: boolean,
	issueCategoryName: IssueCategoryName,
	websiteId: CK.WebsiteId,
};

const IssueConfigButton: React.FC<Props> = (props) => {
	const {
		hasConfiguration,
		issueCategoryName,
		websiteId,
	} = props;

	return (
		<Button
			disabled={hasConfiguration === false}
			icon={(
				<BasicIcon
					size={26}
					type={BasicIconType.CogWheel}
				/>
			)}
			linkRouteName="website.issues.issueCategory.configuration"
			linkRouteParams={{
				websiteId,
				issuesOverviewCategory: getCategoryUrlIdentifier(issueCategoryName),
			}}
			size={ButtonSize.Medium}
			style={ButtonStyle.Hollow}
			tooltip={hasConfiguration ? (
				<FormattedMessage id={`issues.categories.${issueCategoryName}.configurationButton`} />
			) : (
				<FormattedMessage {...messages.notAvailable} />
			)}
		/>
	);
};



export default IssueConfigButton;
