import {
	format,
	isSameYear,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessage,
} from 'react-intl';



const message = defineMessage({
	id: 'ui.datePeriod',
});



type Props = {
	since: Date,
	until: Date,
};

const DatePeriodLabel: React.FC<Props> = (props) => {
	const {
		since,
		until,
	} = props;

	const isYearSame = isSameYear(since, until);

	const sinceLabel = (
		isYearSame
			? format(since, 'd MMM')
			: format(since, 'd MMM yyyy')
	);

	const untilLabel = format(until, 'd MMM yyyy');

	return (
		<FormattedMessage
			{...message}
			values={{
				since: sinceLabel,
				until: untilLabel,
			}}
		/>
	);
};



export default DatePeriodLabel;
