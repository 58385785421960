import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import SegmentDefinitionIdentifier, {
	SegmentDefinitionIdentifierSize,
} from '~/components/logic/segments/SegmentDefinitionIdentifier';

import {
	type SegmentDefinition,
} from '~/model/segments';



const messages = defineMessages({
	message: {
		id: 'ui.segments.management.errors.duplicateCriteria',
	},
});



type Props = {
	segmentWithSameCriteria: SegmentDefinition,
};

const SegmentWithDuplicateCriteriaExistsMessage: React.FC<Props> = (props) => {
	const {
		segmentWithSameCriteria,
	} = props;

	return (
		<FormattedMessage
			{...messages.message}
			values={{
				segment: (
					<SegmentDefinitionIdentifier
						segmentDefinition={segmentWithSameCriteria}
						showCriteria={false}
						size={SegmentDefinitionIdentifierSize.Small}
					/>
				),
			}}
		/>
	);
};



export default React.memo(SegmentWithDuplicateCriteriaExistsMessage);
