import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const BellIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M14.236,21H9.764a.25.25,0,0,0-.248.222A2.319,2.319,0,0,0,9.5,21.5a2.5,2.5,0,1,0,5,0,2.319,2.319,0,0,0-.016-.278A.248.248,0,0,0,14.236,21Z"
				fill={color}
			/>
			<path
				d="M21.446,19.276A16.366,16.366,0,0,1,19.7,11.944v-.768a9.114,9.114,0,0,0-3.866-7.622A6.379,6.379,0,0,0,13.5,2.636V1.5a1.5,1.5,0,1,0-3,0V2.649A6.738,6.738,0,0,0,7.649,3.926,9.2,9.2,0,0,0,4.3,11.176v.768a16.366,16.366,0,0,1-1.746,7.332A.5.5,0,0,0,3,20H21a.5.5,0,0,0,.446-.725Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default BellIconBuilder;
