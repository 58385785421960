import classNames from 'classnames';
import React from 'react';



type Props = React.PropsWithChildren<{
	/** Default scroll position of content when this component is scrollable */
	contentScrollTop?: number,
	/** Limit height of dropdown content and make it scrollable when content is higher */
	contentMaxHeight?: React.CSSProperties['maxHeight'],
	footer?: React.ReactNode,
	footerCta?: React.ReactNode,
	onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void,
	onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void,
	/** Optional placeholder visible when no children present */
	placeholder?: React.ReactNode,
	searchField?: React.ReactNode,
	width?: React.CSSProperties['width'],
}>;



const FieldDropdown = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		contentScrollTop,
		contentMaxHeight,
		footer,
		footerCta,
		children,
		onMouseEnter,
		onMouseLeave,
		placeholder,
		searchField,
		width,
	} = props;

	const contentRef = React.useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		const content = contentRef.current;

		if (contentScrollTop === undefined || !content) {
			return;
		}

		content.scrollTop = contentScrollTop;
	}, [contentScrollTop]);

	const contentClasses = classNames({
		'field-dropdown__content': true,
		'field-dropdown__content--has-limited-height': contentMaxHeight,
		'field-dropdown__content--placeholder': placeholder && !children,
		'js-scrollable': contentMaxHeight,
	});

	return (
		<div
			className="field-dropdown"
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			ref={ref}
			style={{
				width,
			}}
		>
			{searchField && (
				<div className="field-dropdown__search-field">
					{searchField}
				</div>
			)}
			<div
				className={contentClasses}
				ref={contentRef}
				style={{
					maxHeight: contentMaxHeight,
				}}
			>
				{children || placeholder}
			</div>
			{(footer || footerCta) && (
				<div className="field-dropdown__footer">
					<div className="field-dropdown__footer-content">
						{footer}
					</div>
					{footerCta && (
						<div className="field-dropdown__footer-button">
							{footerCta}
						</div>
					)}
				</div>
			)}
		</div>
	);
});



export default FieldDropdown;
