export const FILTER_TYPE_BOOLEAN = 'boolean';
export const FILTER_TYPE_BOOLEAN_OR_NA = 'boolean_or_na';
export const FILTER_TYPE_CUSTOM_DAYS_AGO = 'custom_days_ago';
export const FILTER_TYPE_CUSTOM_NUMBER = 'custom_number';
export const FILTER_TYPE_CUSTOM_STRING = 'custom_string';
export const FILTER_TYPE_DAYS_AGO = 'days_ago';
export const FILTER_TYPE_MILLISECONDS = 'milliseconds';
export const FILTER_TYPE_MONTHS = 'months';
export const FILTER_TYPE_NUMBER = 'number';
export const FILTER_TYPE_PERCENTAGE = 'percentage';
export const FILTER_TYPE_PSEUDO_ENUM = 'pseudo_enum';
export const FILTER_TYPE_SECONDS = 'seconds';
export const FILTER_TYPE_STRING = 'string';
export const FILTER_TYPE_STRING_NON_NULLABLE = 'string_non_nullable';
