import React from 'react';

import type CK from '~/types/contentking';

import {
	useCompleteValidateDomainsSessionMutation,
	useInitiateValidateDomainsSessionMutation,
} from './AddMultipleWebsitesStep.gql';



export default function useValidationSession(accountId: CK.AccountId | null) {
	const [initiateSession] = useInitiateValidateDomainsSessionMutation();
	const [closeSession] = useCompleteValidateDomainsSessionMutation();
	const [sessionId, setSessionId] = React.useState<string | undefined>(undefined);

	React.useEffect(
		() => {
			if (accountId === null) {
				return;
			}

			let localSessionId: string | undefined;

			initiateSession({
				variables: {
					accountId,
				},
			}).then((response) => {
				localSessionId = response.data?.InitiateValidateDomainsSession.sessionId ?? undefined;

				setSessionId(localSessionId);
			});

			return () => {
				if (localSessionId !== undefined) {
					closeSession({
						variables: {
							accountId,
							sessionId: localSessionId,
						},
					});
				}
			};
		},
		[
			accountId,
			closeSession,
			initiateSession,
		],
	);

	return sessionId;
}
