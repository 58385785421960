import React from 'react';



type Props = {
	/** Defined URL when we want to have logo clickable */
	href?: string,
};

const AuthLogo: React.FC<Props> = (props) => {
	const {
		href,
	} = props;

	let content: React.ReactNode = false;
	const title = 'ContentKing';

	if (href !== undefined) {
		content = (
			<a href={href}>
				{title}
			</a>
		);
	} else {
		content = (
			<span>
				{title}
			</span>
		);
	}

	return (
		<h1
			className="auth-logo"
			title={title}
		>
			{content}
		</h1>
	);
};



export default AuthLogo;
