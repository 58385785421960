import React from 'react';

import {
	type SettableStore,
} from '~/createKeyedStore';



function useKeyedStoreDefaultValue<TState, Property extends string & keyof TState>(
	state: TState & SettableStore<TState>,
	property: Property,
	defaultValue: TState[Property] | null,
): void {
	const value = state[property] as typeof state[typeof property] | null;

	React.useEffect(
		() => {
			if (
				value === null
				&& defaultValue !== null
			) {
				state.setProperty(property, defaultValue);
			}
		},
		[
			defaultValue,
			property,
			state,
			value,
		],
	);
}



export default useKeyedStoreDefaultValue;
