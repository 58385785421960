import React from 'react';

import AccountIcon from './builders/iconsData/attributesGroupIcon/Account';
import AdobeAnalyticsIcon from './builders/iconsData/attributesGroupIcon/AdobeAnalytics';
import ContentIcon from './builders/iconsData/attributesGroupIcon/Content';
import ConversionsIcon from './builders/iconsData/attributesGroupIcon/Conversions';
import CustomElementsIcon from './builders/iconsData/attributesGroupIcon/CustomElements';
import DomainIcon from './builders/iconsData/attributesGroupIcon/Domain';
import EnrichmentFieldsIcon from './builders/iconsData/attributesGroupIcon/EnrichmentFields';
import FundamentalsIcon from './builders/iconsData/attributesGroupIcon/Fundamentals';
import GoogleAnalyticsIcon from './builders/iconsData/attributesGroupIcon/GoogleAnalytics';
import GoogleSearchConsoleIcon from './builders/iconsData/attributesGroupIcon/GoogleSearchConsole';
import IndexabilityIcon from './builders/iconsData/attributesGroupIcon/Indexability';
import LighthouseIcon from './builders/iconsData/attributesGroupIcon/Lighthouse';
import RelationsIcon from './builders/iconsData/attributesGroupIcon/Relations';
import RobotDirectivesIcon from './builders/iconsData/attributesGroupIcon/RobotDirectives';
import RobotsTxtIcon from './builders/iconsData/attributesGroupIcon/RobotsTxt';
import SearchEnginesIcon from './builders/iconsData/attributesGroupIcon/SearchEngines';
import SchemaOrgIcon from './builders/iconsData/attributesGroupIcon/SchemaOrg';
import SearchEngineActivityIcon from './builders/iconsData/attributesGroupIcon/SearchEngineActivity';
import SocialIcon from './builders/iconsData/attributesGroupIcon/Social';
import WebVitalsIcon from './builders/iconsData/attributesGroupIcon/WebVitals';
import XmlSitemapIcon from './builders/iconsData/attributesGroupIcon/XmlSitemap';

import InlineIcon from './builders/InlineIcon.part';

import {
	type Merge,
} from '~/types/utilities';



export enum AttributesGroupIconType {
	Account = 'account',
	AdobeAnalytics = 'adobe_analytics',
	Content = 'content',
	Conversions = 'conversions',
	CustomElements = 'custom_elements',
	Domain = 'domain',
	EnrichmentFields = 'enrichment_fields',
	Fundamentals = 'fundamentals',
	GoogleAnalytics = 'google_analytics',
	GoogleSearchConsole = 'google_search_console',
	Indexability = 'indexability',
	Lighthouse = 'lighthouse',
	Relations = 'relations',
	RobotDirectives = 'robot-directives',
	RobotsTxt = 'robots.txt',
	SearchEngines = 'search-engines',
	SchemaOrg = 'schema_org',
	SearchEngineActivity = 'search_engine_activity',
	Social = 'social',
	WebVitals = 'web-vitals',
	XmlSitemap = 'xml-sitemap',
}

const componentMapping = {
	[AttributesGroupIconType.Account]: AccountIcon,
	[AttributesGroupIconType.AdobeAnalytics]: AdobeAnalyticsIcon,
	[AttributesGroupIconType.Content]: ContentIcon,
	[AttributesGroupIconType.Conversions]: ConversionsIcon,
	[AttributesGroupIconType.CustomElements]: CustomElementsIcon,
	[AttributesGroupIconType.Domain]: DomainIcon,
	[AttributesGroupIconType.EnrichmentFields]: EnrichmentFieldsIcon,
	[AttributesGroupIconType.Fundamentals]: FundamentalsIcon,
	[AttributesGroupIconType.GoogleAnalytics]: GoogleAnalyticsIcon,
	[AttributesGroupIconType.GoogleSearchConsole]: GoogleSearchConsoleIcon,
	[AttributesGroupIconType.Indexability]: IndexabilityIcon,
	[AttributesGroupIconType.Lighthouse]: LighthouseIcon,
	[AttributesGroupIconType.Relations]: RelationsIcon,
	[AttributesGroupIconType.RobotDirectives]: RobotDirectivesIcon,
	[AttributesGroupIconType.RobotsTxt]: RobotsTxtIcon,
	[AttributesGroupIconType.SearchEngines]: SearchEnginesIcon,
	[AttributesGroupIconType.SchemaOrg]: SchemaOrgIcon,
	[AttributesGroupIconType.SearchEngineActivity]: SearchEngineActivityIcon,
	[AttributesGroupIconType.Social]: SocialIcon,
	[AttributesGroupIconType.WebVitals]: WebVitalsIcon,
	[AttributesGroupIconType.XmlSitemap]: XmlSitemapIcon,
} as const;



type BaseProps<IconType extends AttributesGroupIconType = AttributesGroupIconType> = {
	/** Custom icon color */
	color?: string,
	/** Square size of icon */
	size?: number,
	/** Type of icon */
	type: IconType,
};

type IconProps<IconType extends AttributesGroupIconType = AttributesGroupIconType> = (
	Parameters<typeof componentMapping[IconType]['icon']>[0]
);

type Props<IconType extends AttributesGroupIconType = AttributesGroupIconType> = (
	Merge<IconProps<IconType>, BaseProps<IconType>>
);

const AttributesGroupIcon = <IconType extends AttributesGroupIconType>(props: Props<IconType>): React.ReactElement => {
	const {
		color,
		size = 32,
		type,
		...rest
	} = props;

	const icon = componentMapping[type].icon({
		color,
		size,
		...rest,
	});

	return (
		<InlineIcon
			icon={icon}
			size={size}
			viewBoxHeight={componentMapping[type].viewBoxHeight}
			viewBoxWidth={componentMapping[type].viewBoxWidth}
		/>
	);
};



export default AttributesGroupIcon;
