import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import TextArea from '~/components/atoms/forms/components/TextArea';
import TextField from '~/components/atoms/forms/components/TextField';



const messages = defineMessages({
	attn: {
		id: 'ui.invoiceDetails.customInvoiceFields.attn',
	},
	customerReference: {
		id: 'ui.invoiceDetails.customInvoiceFields.reference',
	},
	purchaseOrderNumber: {
		id: 'ui.invoiceDetails.customInvoiceFields.poNumber',
	},
	supplierNumber: {
		id: 'ui.invoiceDetails.customInvoiceFields.supplierNumber',
	},
});



const ExtraInvoiceDetailsFields: React.FC = () => {
	return (
		<FormRows>
			<FormRow
				htmlFor="attn"
				label={(
					<FormattedMessage {...messages.attn} />
				)}
			>
				<FieldStatus name="attn">
					<TextField
						emptyAsNull={true}
						name="attn"
						trimValue={true}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				htmlFor="purchaseOrderNumber"
				label={(
					<FormattedMessage {...messages.purchaseOrderNumber} />
				)}
			>
				<FieldStatus name="purchaseOrderNumber">
					<TextField
						emptyAsNull={true}
						name="purchaseOrderNumber"
						trimValue={true}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				htmlFor="supplierNumber"
				label={(
					<FormattedMessage {...messages.supplierNumber} />
				)}
			>
				<FieldStatus name="supplierNumber">
					<TextField
						emptyAsNull={true}
						name="supplierNumber"
						trimValue={true}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				htmlFor="customerReference"
				label={(
					<FormattedMessage {...messages.customerReference} />
				)}
			>
				<FieldStatus name="customerReference">
					<TextArea
						emptyAsNull={true}
						name="customerReference"
						resizable={true}
						rows={3}
					/>
				</FieldStatus>
			</FormRow>
		</FormRows>
	);
};



export default ExtraInvoiceDetailsFields;
