import type CK from '~/types/contentking';

import {
	usePageHealthQuery,
} from './usePageHealth.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageHealth(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageHealthQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.pageTypeData?.health ?? null;
}



export default usePageHealth;
