import React, {
	Component,
} from 'react';
import {
	DragSource,
} from 'react-dnd';
import {
	getEmptyImage,
} from 'react-dnd-html5-backend';

import DragAndDropSourceElement from '../../atoms/interactions/DragAndDropSourceElements';
import SegmentDefinitionIdentifier from './SegmentDefinitionIdentifier';



class DraggableSegmentDefinitionIdentifier extends Component {

	componentDidMount() {
		const {
			connectDragPreview,
		} = this.props;

		// Use empty image as a drag preview so browsers don't draw it
		// and we can draw whatever we want on the custom drag layer instead.
		connectDragPreview(getEmptyImage(), {
			// IE fallback: specify that we'd rather screenshot the node
			// when it already knows it's being dragged so we can hide it with CSS.
			captureDraggingState: true,
		});
	}



	render() {
		const {
			connectDragSource,
			isDragging,
		} = this.props;

		// Only native element nodes can now be passed to React DnD connectors.
		// To fix that we need to wrap the component into a <div>.
		return connectDragSource(
			<div>
				<DragAndDropSourceElement isDragging={isDragging}>
					<SegmentDefinitionIdentifier {...this.props}>
						{this.props.children}
					</SegmentDefinitionIdentifier>
				</DragAndDropSourceElement>
			</div>,
		);
	}

}

DraggableSegmentDefinitionIdentifier.defaultProps = SegmentDefinitionIdentifier.defaultProps;
DraggableSegmentDefinitionIdentifier.propTypes = SegmentDefinitionIdentifier.propTypes;

export default DragSource('segment', {
	beginDrag(props) {
		return {
			segment: props.segmentDefinition.name,
			segmentLabelProps: props,
		};
	},
}, (connect, monitor) => {
	return {
		connectDragPreview: connect.dragPreview(),
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	};
})(DraggableSegmentDefinitionIdentifier);
