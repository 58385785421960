import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const InvoiceBillingIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M14.1253 8.29758C13.9853 7.9223 13.7342 7.59867 13.4054 7.36994C13.0766 7.14122 12.6858 7.01829 12.2853 7.01758H11.2028C10.6931 7.01824 10.2045 7.221 9.84409 7.5814C9.4837 7.94179 9.28093 8.4304 9.28027 8.94008V8.94008C9.28027 9.44996 9.48282 9.93895 9.84336 10.2995C10.2039 10.66 10.6929 10.8626 11.2028 10.8626H12.3253C12.578 10.8619 12.8285 10.9112 13.0621 11.0075C13.2958 11.1039 13.5082 11.2454 13.687 11.424C13.8659 11.6027 14.0077 11.8148 14.1044 12.0484C14.201 12.282 14.2506 12.5323 14.2503 12.7851V12.7851C14.2503 13.2945 14.0481 13.7831 13.6881 14.1436C13.3281 14.5041 12.8397 14.7069 12.3303 14.7076H11.2503C10.7296 14.7089 10.2296 14.5035 9.86022 14.1365C9.49085 13.7694 9.28226 13.2708 9.28027 12.7501V12.7501"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M16.5898 7.61768H21.9998"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M16.4775 11.1477H21.995"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M16.4775 14.6802H21.995"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M9.28027 18.2126H21.9953"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M9.28027 21.7451H21.9953"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M25.2012 25.1401V4.81756C25.2005 4.25219 24.9756 3.71016 24.5758 3.31038C24.1761 2.91061 23.634 2.68572 23.0687 2.68506H4.20117"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M7.84328 29.6976L27.0333 29.6651C27.2745 29.6654 27.5134 29.6182 27.7363 29.5261C27.9592 29.434 28.1618 29.2989 28.3324 29.1285C28.5031 28.9581 28.6385 28.7557 28.7309 28.5329C28.8232 28.3101 28.8708 28.0712 28.8708 27.8301V25.1401L9.68078 25.1751V27.8626C9.68078 28.1037 9.63323 28.3426 9.54086 28.5654C9.44849 28.7882 9.3131 28.9906 9.14244 29.161C8.97178 29.3314 8.76919 29.4665 8.54627 29.5586C8.32335 29.6507 8.08447 29.6979 7.84328 29.6976V29.6976C7.60168 29.6985 7.36225 29.6518 7.13875 29.56C6.91526 29.4683 6.71208 29.3333 6.54089 29.1628C6.3697 28.9923 6.23386 28.7896 6.14118 28.5665C6.04849 28.3434 6.00078 28.1042 6.00078 27.8626L6.03328 4.51756C6.03361 4.27682 5.98644 4.03838 5.89446 3.8159C5.80249 3.59343 5.66752 3.39128 5.49729 3.22105C5.32706 3.05082 5.12492 2.91586 4.90244 2.82388C4.67996 2.73191 4.44152 2.68473 4.20078 2.68506V2.68506C3.95981 2.68473 3.72112 2.73187 3.49837 2.82378C3.27561 2.9157 3.07314 3.05059 2.90251 3.22075C2.73188 3.39091 2.59644 3.59302 2.50392 3.81553C2.4114 4.03803 2.36361 4.27658 2.36328 4.51756V14.6726H6.03328"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M11.7627 5.94995V7.01745"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M11.7627 14.7075V15.8375"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default InvoiceBillingIconBuilder;

