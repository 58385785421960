import {
	format,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ContentChangeComparison from '~/components/patterns/trackedChanges/ContentChangeComparison';
import Copy from '~/components/logic/Copy';
import {
	ValueUpdateComparisonHighlightType,
} from '~/components/patterns/values/ValueUpdateComparison';

import {
	type HistoricalMoment_PageHistoricalMomentPageDiscovered_Fragment,
} from './HistoricalData.gql';



const messages = defineMessages({
	foundAt: {
		id: 'ui.pageDetail.history.foundAt',
	},
	referencedBy: {
		id: 'ui.pageDetail.history.referencedBy',
	},
	title: {
		id: 'ui.pageDetail.history.pageDiscovered',
	},
});



type Props = {
	historicalMoment: HistoricalMoment_PageHistoricalMomentPageDiscovered_Fragment,
};

const PageDiscoveredTimelineEntryContent: React.FC<Props> = (props) => {
	const {
		historicalMoment,
	} = props;

	const timestamp = historicalMoment.trackedAt;

	return (
		<ContentChangeComparison
			highlightType={ValueUpdateComparisonHighlightType.Info}
			newContent={(
				historicalMoment.observedAtUrl ? (
					<Copy
						{...messages.referencedBy}
						values={{
							referenceUrl: historicalMoment.observedAtUrl,
						}}
					/>
				) : (
					<FormattedMessage
						{...messages.foundAt}
						values={{
							date: format(timestamp, 'iiii, d MMM yyyy'),
							time: format(timestamp, 'p'),
						}}
					/>
				)
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		/>
	);
};



export default PageDiscoveredTimelineEntryContent;
