import React from 'react';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



const ScrollContainerContext = React.createContext<HTMLDivElement | null>(null);



function useScrollContainer() {
	return React.useContext(ScrollContainerContext);
}



type Props = {
	children: RenderProp<{
		containerRef: React.RefObject<HTMLDivElement>,
	}>,
};



const ScrollContainerProvider: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const containerRef = React.useRef<HTMLDivElement>(null);

	const [scrollContainer, setScrollContainer] = React.useState<HTMLDivElement | null>(null);

	React.useEffect(
		() => {
			if (scrollContainer === null && containerRef.current !== null) {
				setScrollContainer(containerRef.current);
			}
		},
		[
			containerRef,
			scrollContainer,
		],
	);

	return (
		<ScrollContainerContext.Provider value={scrollContainer}>
			{renderProp(children, {
				containerRef,
			})}
		</ScrollContainerContext.Provider>
	);
};



export default useScrollContainer;

export {
	ScrollContainerProvider,
};
