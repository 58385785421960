import type CK from '~/types/contentking';

import useBatchContextPageDetail from '~/hooks/useBatchContextPageDetail';
import usePollInterval from '~/hooks/usePollInterval';



type Options = {
	skip?: boolean,
};



function usePageDetailPropertiesQuery<TQueryHookReturnType>(
	queryHook: (options: any) => TQueryHookReturnType,
	legacyUrlId: number | null,
	websiteId: CK.WebsiteId | null,
	options: Options = {},
): TQueryHookReturnType {
	return queryHook({
		context: useBatchContextPageDetail(legacyUrlId, websiteId),
		pollInterval: usePollInterval(10000),
		skip: options.skip || legacyUrlId === null || websiteId === null,
		variables: {
			legacyUrlId: legacyUrlId ?? 0,
			websiteId: websiteId ?? '',
		},
	});
}



export default usePageDetailPropertiesQuery;
