import classNames from 'classnames';
import React from 'react';

import styles from './SquareSkeleton.module.scss';

import {
	isNumber,
} from '~/utilities/typeCheck';



const DEFAULT_ROW_INDEX_MAX_WIDTH: Array<React.CSSProperties['maxWidth']> = [
	'79%',
	'62%',
	'81%',
	'53%',
	'42%',
];



export enum SquareSkeletonStyle {
	Dark = 'dark',
	Light = 'light',
	Transparent = 'transparent',
}

type Props = {
	height?: React.CSSProperties['height'],
	maxWidth?: React.CSSProperties['maxWidth'],
	rowIndex?: number,
	style?: SquareSkeletonStyle,
	width?: React.CSSProperties['width'],
};



const SquareSkeleton: React.FC<Props> = (props) => {
	const {
		height = 20,
		rowIndex,
		style = SquareSkeletonStyle.Dark,
		width,
	} = props;

	let maxWidth = props.maxWidth;
	if (isNumber(rowIndex) && maxWidth === undefined) {
		maxWidth = DEFAULT_ROW_INDEX_MAX_WIDTH[rowIndex % 5];
	}

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.darkBackground]: style === SquareSkeletonStyle.Dark,
		[styles.lightBackground]: style === SquareSkeletonStyle.Light,
	});

	return (
		<div
			className={componentClasses}
			style={{
				height,
				maxWidth,
				width,
			}}
		/>
	);
};



export default SquareSkeleton;
