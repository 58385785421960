/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountCustomElementsLimitQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountCustomElementsLimitQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly customElementsLimit: number } | null };


export const AccountCustomElementsLimitDocument = gql`
    query AccountCustomElementsLimit($accountId: AccountId!) {
  account(id: $accountId) {
    id
    customElementsLimit
  }
}
    `;

/**
 * __useAccountCustomElementsLimitQuery__
 *
 * To run a query within a React component, call `useAccountCustomElementsLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCustomElementsLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCustomElementsLimitQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountCustomElementsLimitQuery(baseOptions: Apollo.QueryHookOptions<AccountCustomElementsLimitQuery, AccountCustomElementsLimitQueryVariables> & ({ variables: AccountCustomElementsLimitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountCustomElementsLimitQuery, AccountCustomElementsLimitQueryVariables>(AccountCustomElementsLimitDocument, options);
      }
export function useAccountCustomElementsLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountCustomElementsLimitQuery, AccountCustomElementsLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountCustomElementsLimitQuery, AccountCustomElementsLimitQueryVariables>(AccountCustomElementsLimitDocument, options);
        }
export function useAccountCustomElementsLimitSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountCustomElementsLimitQuery, AccountCustomElementsLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountCustomElementsLimitQuery, AccountCustomElementsLimitQueryVariables>(AccountCustomElementsLimitDocument, options);
        }
export type AccountCustomElementsLimitQueryHookResult = ReturnType<typeof useAccountCustomElementsLimitQuery>;
export type AccountCustomElementsLimitLazyQueryHookResult = ReturnType<typeof useAccountCustomElementsLimitLazyQuery>;
export type AccountCustomElementsLimitSuspenseQueryHookResult = ReturnType<typeof useAccountCustomElementsLimitSuspenseQuery>;
export type AccountCustomElementsLimitQueryResult = Apollo.QueryResult<AccountCustomElementsLimitQuery, AccountCustomElementsLimitQueryVariables>;