import React from 'react';
import type Immutable from 'immutable';

import type GraphQL from '~/types/graphql';

import WebVitalNoData from '~/components/logic/values/blankValues/WebVitalNoData';
import WebVitalFormatter from '~/components/app/WebVitalFormatter';

import {
	type WebVital,
} from '~/model/webVitals';

import {
	isMap,
} from '~/utilities/typeCheck';



type Props = {
	type: WebVital,
	value: {
		value: number,
		range: GraphQL.WebVitalRange,
	} | Immutable.Map<any, any> | null,
};

const WebVitalValueFormatter: React.FC<Props> = (props) => {
	const {
		type,
		value,
	} = props;

	if (value === null) {
		return (
			<WebVitalNoData />
		);
	}

	if (isMap(value)) {
		return (
			<WebVitalFormatter
				showStatusIndicator={true}
				type={type}
				value={value.get('value')}
				verdict={value.get('range')}
			/>
		);
	}

	return (
		<WebVitalFormatter
			showStatusIndicator={true}
			type={type}
			value={value.value}
			verdict={value.range}
		/>
	);
};



export default WebVitalValueFormatter;
