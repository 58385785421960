import classNames from 'classnames';
import React from 'react';



type Props = {
	children: React.ReactNode,
	isDragging?: boolean,
};

const DragAndDropSourceElement: React.FC<Props> = (props) => {
	const {
		children,
		isDragging = false,
	} = props;

	return (
		<div
			className={classNames({
				'dnd-source': true,
				'dnd-source--is-dragging': isDragging,
			})}
		>
			{children}
		</div>
	);
};



export default DragAndDropSourceElement;
