import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const InStockCartIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M12.657,14a1,1,0,0,0-1-1H8.239A.25.25,0,0,1,8,12.808l-1.15-5A.25.25,0,0,1,7.089,7.5H20.28a.5.5,0,0,1,.474.658l-.749,2.248a1,1,0,1,0,1.9.633l1-3A1.928,1.928,0,0,0,21.072,5.5H6.514a.249.249,0,0,1-.243-.194L5.393,1.487A1.922,1.922,0,0,0,3.515,0H1A1,1,0,0,0,1,2l2.24-.055a.252.252,0,0,1,.25.194l3.357,14.6a.248.248,0,0,1-.107.265,2.251,2.251,0,1,0,2.286-.1.256.256,0,0,1-.126-.165l-.33-1.437A.25.25,0,0,1,8.814,15h2.843A1,1,0,0,0,12.657,14Z"
				fill={color}
			/>
			<path
				d="M18.25,12.5A5.75,5.75,0,1,0,24,18.25,5.756,5.756,0,0,0,18.25,12.5Zm2.939,4.673-2.542,3.389a1.4,1.4,0,0,1-1.027.56c-.034,0-.067,0-.1,0a1.4,1.4,0,0,1-.991-.412L15.215,19.4a.75.75,0,0,1,1.06-1.06l1.034,1.034a.25.25,0,0,0,.377-.026l2.3-3.073a.75.75,0,1,1,1.2.9Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default InStockCartIconBuilder;
