import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import SwitchButtons, {
	SwitchButtonsSize,
} from '~/components/patterns/buttons/SwitchButtons';

import useViewportWidth from '~/hooks/useViewportWidth';



const messages = defineMessages({
	grid: {
		id: 'ui.websites.views.grid',
	},
	list: {
		id: 'ui.websites.views.list',
	},
});



type Props = {
	isListActive: boolean,
	switchToGridRoute: {
		name: string,
		params: Record<string, any>,
	},
	switchToListRoute: {
		name: string,
		params: Record<string, any>,
	},
};

const ViewSwitch: React.FC<Props> = (props) => {
	const {
		isListActive,
		switchToGridRoute,
		switchToListRoute,
	} = props;

	const viewportWidth = useViewportWidth();

	return (
		<SwitchButtons
			buttons={[
				{
					icon: (
						<BasicIcon type={BasicIconType.Grid} />
					),
					isActive: !isListActive,
					label: viewportWidth > 780 && (
						<FormattedMessage {...messages.grid} />
					),
					linkRouteName: switchToGridRoute.name,
					linkRouteParams: switchToGridRoute.params,
				},
				{
					icon: (
						<BasicIcon type={BasicIconType.Columns} />
					),
					isActive: isListActive,
					label: viewportWidth > 780 && (
						<FormattedMessage {...messages.list} />
					),
					linkRouteName: switchToListRoute.name,
					linkRouteParams: switchToListRoute.params,
				},
			]}
			fullwidth={false}
			size={SwitchButtonsSize.Small}
			uppercase={true}
		/>
	);
};



export default ViewSwitch;
