import useAllWebsites from '~/hooks/useAllWebsites';



function useHasWebsitesScreen() {
	const allWebsites = useAllWebsites();

	return allWebsites.listAll().length >= 4;
}



export default useHasWebsitesScreen;
