import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AuthButtonsLayout from '~/components/atoms/auth/AuthButtonsLayout';
import AuthFormLayout, {
	type AuthFormLayoutRef,
} from '~/components/atoms/auth/AuthFormLayout';
import AuthFormRow from '~/components/atoms/auth/AuthFormRow';
import AuthTitle from '~/components/atoms/auth/AuthTitle';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ExternalLink, {
	ExternalLinkStyle,
} from '~/components/patterns/links/ExternalLink';
import FieldStatus, {
	FieldStatusErrorMessageSize,
} from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import SubmitButton, {
	SubmitButtonSize,
} from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldSize,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	LoginScreenView,
} from '~/model/loginScreen';

import touchSupported from '~/utilities/touchSupported';



const messages = defineMessages({
	backToLogin: {
		id: 'ui.verifyTwoFactorAuthenticationForm.backToLogin',
	},
	code: {
		id: 'ui.verifyTwoFactorAuthenticationForm.code',
	},
	havingTrouble: {
		id: 'ui.verifyTwoFactorAuthenticationForm.havingTrouble',
	},
	invalidCodeError: {
		id: 'ui.verifyTwoFactorAuthenticationForm.errors.invalidCodeError',
	},
	submit: {
		id: 'ui.verifyTwoFactorAuthenticationForm.submit',
	},
	title: {
		id: 'ui.verifyTwoFactorAuthenticationForm.title',
	},
});

const validations = {
	validateCode: validateField(
		'code',
		(f) => ([
			f.validateNonEmpty(),
			f.customGlobal({
				field: 'code',
				globalRule: 'invalidCode',
				message: (
					<FormattedMessage {...messages.invalidCodeError} />
				),
			}),
		]),
	),
};



type Values = {
	code: string,
};

type Props = {
	isDisabled?: boolean,
	onSubmit: (values: Values) => void | Promise<void>,
	setView: (view: LoginScreenView) => void,
};

const VerifyTwoFactorAuthenticationForm: React.FC<Props> = (props) => {
	const {
		isDisabled,
		onSubmit,
		setView,
	} = props;

	const layoutRef = React.useRef<AuthFormLayoutRef | null>(null);

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				await onSubmit(values);
			} catch (error) {
				layoutRef.current?.shake();

				throw error;
			}
		},
		[
			onSubmit,
		],
	);

	return (
		<AuthFormLayout
			header={(
				<AuthTitle>
					<FormattedMessage {...messages.title} />
				</AuthTitle>
			)}
			ref={layoutRef}
		>
			<Form
				defaultFocus={touchSupported ? null : 'code'}
				isDisabled={isDisabled}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<AuthFormRow
					id="code"
					label={(
						<FormattedMessage {...messages.code} />
					)}
					sublabel={(
						<ExternalLink
							href="https://www.contentkingapp.com/support/two-factor-authentication/"
							style={ExternalLinkStyle.Auth}
							tabIndex={-1}
						>
							<FormattedMessage {...messages.havingTrouble} />
						</ExternalLink>
					)}
				>
					<FieldStatus
						errorMessageSize={FieldStatusErrorMessageSize.Medium}
						name="validateCode"
						showIcon={false}
					>
						<TextField
							attributes={{
								inputMode: 'numeric',
								pattern: '[0-9]{6}',
							}}
							autoComplete={TextFieldAutocomplete.OneTimeCode}
							name="code"
							resetButton={false}
							size={TextFieldSize.Large}
							width="100%"
						/>
					</FieldStatus>
				</AuthFormRow>


				<AuthButtonsLayout
					leftButton={(
						<SubmitButton
							size={SubmitButtonSize.XLarge}
						>
							<FormattedMessage {...messages.submit} />
						</SubmitButton>
					)}
					rightButton={(
						<Button
							onClick={() => setView(LoginScreenView.Login)}
							size={ButtonSize.Medium}
							style={ButtonStyle.Link}
						>
							<FormattedMessage {...messages.backToLogin} />
						</Button>
					)}
				/>
			</Form>
		</AuthFormLayout>
	);
};



export default VerifyTwoFactorAuthenticationForm;
