import type CK from '~/types/contentking';

import {
	type AgencyConnectionsQuery,
	useAgencyConnectionsQuery,
} from './useAccountAgencyConnections.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';

import memoizeById from '~/utilities/memoizeById';



const emptyList = [];

const format = memoizeById(
	(
		data: AgencyConnectionsQuery | undefined,
	) => {
		const agencyConnections = data?.account?.agencyConnections ?? null;

		return {
			count: agencyConnections?.length ?? 0,
			getByAccountId: (accountId: CK.AccountId) => {
				return (agencyConnections ?? []).find(
					(agencyConnection) => agencyConnection.agencyAccountId === accountId,
				) ?? null;
			},
			isLoaded: agencyConnections !== null,
			listAll: () => agencyConnections ?? emptyList,
		};
	},
);



function useAccountAgencyConnections(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAgencyConnectionsQuery,
		accountId,
	);

	return format(accountId, data);
}



export default useAccountAgencyConnections;
