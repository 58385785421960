import React from 'react';

import type CK from '~/types/contentking';

import {
	useIndexNowTriggersQuery,
} from './useIndexNowTriggers.gql';



function useIndexNowTriggers(websiteId: CK.WebsiteId) {
	const { data, loading } = useIndexNowTriggersQuery({
		variables: {
			websiteId,
		},
	});

	return React.useMemo(
		() => {
			if (loading) {
				return {
					contentTriggers: null,
					customElementTriggers: null,
					loading: true,
					nativeTriggers: null,
					pageStatusTriggers: null,
				};
			}

			const nativeTriggers = data?.website?.indexNowNativeTriggers ?? null;
			const contentTriggers = nativeTriggers !== null
				? nativeTriggers.filter((trigger) => trigger.startsWith('contentChanged'))
				: null;
			const pageStatusTriggers = nativeTriggers !== null
				? nativeTriggers.filter((trigger) => trigger.startsWith('page'))
				: null;

			return {
				contentTriggers,
				customElementTriggers: data?.website?.indexNowCustomElementTriggers ?? null,
				loading: false,
				nativeTriggers,
				pageStatusTriggers,
			};
		},
		[
			data,
			loading,
		],
	);
}



export default useIndexNowTriggers;
