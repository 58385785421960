import {
	redirectOldCategory,
	redirectOldIssue,
} from '~/model/issues/identifierMapping';



export function configureRouter(router) {
	router.setRootPath('?_ga');

	router.add({ name: 'home', path: '/' });

	router.add({ name: 'login', path: '/login?error' });

	router.add({ name: 'login.twoFactorAuthentication', path: '/2fa' });

	router.add({ name: 'acceptInvitationWith2FA', path: '/accept_invitation_with_2fa' });

	router.add({ name: 'kickstart', path: '/kickstart' });

	router.add({ name: 'entry', path: '/entry?target' });

	router.add({ name: 'passwordLost', path: '/lost_password' });

	router.add({ name: 'passwordLost.requested', path: '/requested' });

	router.add({ name: 'passwordReset', path: '/reset_password' });

	router.add({ name: 'passwordReset.finished', path: '/finished' });

	router.add({ name: 'passwordReset.twoFactorAuthentication', path: '/2fa' });

	router.add({ name: 'website', path: '/websites/:websiteId' });

	router.add({ name: 'website.pages', path: '/pages?start_date&end_date&filter&view&columns&graphs' });

	router.add({ name: 'website.pages.datepicker', path: '/pick-date' });

	router.add({ name: 'website.pages.export', path: '/export' });

	router.add({ name: 'website.pages.detail', path: '/:id' });

	router.add({ name: 'website.pages.detail.history', path: '/history' });

	router.add({ name: 'website.pages.detail.history.snapshot', path: '/snapshots/:timestamp' });
	router.canActivate('website.pages.detail.history.snapshot', () => (toState, fromState, done) => {
		if (toState.name === 'website.pages.detail.history.snapshot') {
			done({
				redirect: {
					name: 'website.pages.detail.history.snapshot.headers',
					params: toState.params,
				},
			});
		} else {
			done();
		}
	});

	router.add({ name: 'website.pages.detail.history.snapshot.headers', path: '/headers' });
	router.add({ name: 'website.pages.detail.history.snapshot.responseBody', path: '/response-body' });
	router.add({ name: 'website.pages.detail.history.snapshot.renderedDom', path: '/rendered-dom' });

	router.add({ name: 'website.pages.detail.links', path: '/links/:pageDetailLinksType' });

	router.add({ name: 'website.pages.detail.top', path: '/top' });

	router.add({ name: 'website.pages.detail.issues', path: '/issues' });
	router.add({ name: 'website.pages.detail.issuesCategory', path: '/issues/:pageDetailIssuesCategoryType' });
	redirectOldCategory(router, 'website.pages.detail.issuesCategory', 'pageDetailIssuesCategoryType');

	router.add({ name: 'website.pages.detail.issuesCategory.categoryIgnoring', path: '/ignoring' });

	router.add({ name: 'website.pages.detail.issuesCategory.ignoring_deprecated', path: '/:pageDetailIssue/ignoring/:scope' });
	router.canActivate('website.pages.detail.issuesCategory.ignoring_deprecated', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.pages.detail.issuesCategory.issueIgnoring',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'website.pages.detail.issuesCategory.issueIgnoring', path: '/ignoring/:pageDetailIssue/:scope' });

	router.add({ name: 'website.dashboard', path: '/dashboard?period&startDate&endDate' });

	router.add({ name: 'website.alerts', path: '/alerts' });
	router.add({ name: 'website.events', path: '/events' });
	router.canActivate('website.events', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: toState.name.replace('events', 'alerts'),
				params: toState.params,
			},
		});
	});
	router.add({ name: 'website.alert', path: '/alert' });
	router.canActivate('website.alert', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: toState.name.replace('alert', 'alerts'),
				params: toState.params,
			},
		});
	});

	router.add({ name: 'website.alerts.configuration', path: '/configuration' });

	router.add({ name: 'website.alerts.configuration.createDefinitions', path: '/create' });

	router.add({ name: 'website.alerts.configuration.createPagesEnteredOrLeftSegmentDefinition', path: '/create_pages_entered_or_left_segment' });

	router.add({ name: 'website.alerts.configuration.editDefinition', path: '/edit/:alertDefinitionId' });

	router.add({ name: 'website.alerts.configuration.editDefinitionsMessagingApp', path: '/edit_messaging_app/:alertDefinitionIds<[\\d,]+>' });

	router.add({ name: 'website.alerts.configuration.editDefinitionsRecipients', path: '/edit_recipients/:alertDefinitionIds<[\\d,]+>' });

	router.add({ name: 'website.alerts.configuration.editDefinitionsScope', path: '/edit_scope/:alertDefinitionIds<[\\d,]+>' });

	router.add({ name: 'website.alerts.configuration.editDefinitionsSensitivity', path: '/edit_sensitivity/:alertDefinitionIds<[\\d,]+>' });

	router.add({ name: 'website.alerts.configuration.editDefinitionsSlack', path: '/edit_slack/:alertDefinitionIds<[\\d,]+>' });
	router.canActivate('website.alerts.configuration.editDefinitionsSlack', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.alerts.configuration.editDefinitionsMessagingApp',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'website.alerts.configuration.deleteDefinitions', path: '/delete/:alertDefinitionIds<[\\d,]+>' });

	router.add({ name: 'website.events.alertPages', path: '/:alertId/pages' });
	router.add({ name: 'website.alerts.alertPages', path: '/:alertId/pages' });
	router.canActivate('website.events.alertPages', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: toState.name.replace('events', 'alerts'),
				params: toState.params,
			},
		});
	});

	router.add({ name: 'website.platform', path: '/platform' });

	router.add({ name: 'website.platform.issuesCategory', path: '/issues/:platformScreenIssuesCategoryType' });

	router.add({ name: 'website.platform.issuesCategory.categoryIgnoring', path: '/ignoring' });

	router.add({ name: 'website.platform.issuesCategory.issueIgnoring', path: '/ignoring/:platformIssue/:scope' });

	router.add({ name: 'website.platform.sitemaps', path: '/sitemaps?filter' });

	router.add({ name: 'website.platform.robotsTxtViewer', path: '/robots_txt/snapshot/:revisionId' });

	router.add({ name: 'website.platform.robotsTxtComparison', path: '/robots_txt/diff/*revisions' });

	router.add({ name: 'website.platform.oldRobotsTxtViewer', path: '/robots_txt/:robotsTxtId/snapshot/:revisionId' });
	router.canActivate('website.platform.oldRobotsTxtViewer', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.platform.robotsTxtViewer',
				params: {
					websiteId: toState.params.websiteId,
					revisionId: toState.params.revisionId,
				},
			},
		});
	});

	router.add({ name: 'website.platform.robotsTxtSmallDevicesFilter', path: '/robots_txt/:robotsTxtId/filter?revisions' });

	router.add({ name: 'website.platform.oldRobotsTxtViewerChangeTracking', path: '/robots_txt/:robotsTxtId/diff/*revisions' });
	router.canActivate('website.platform.oldRobotsTxtViewerChangeTracking', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.platform.robotsTxtComparison',
				params: {
					websiteId: toState.params.websiteId,
					revisions: toState.params.revisions,
				},
			},
		});
	});

	router.add({ name: 'website.issues', path: '/issues?filter&start_date&end_date' });

	router.add({ name: 'website.issues.datepicker', path: '/pick-date' });

	router.add({ name: 'website.issues.filter', path: '/filter?filter' });

	router.add({ name: 'website.issues.issueCategory', path: '/:issuesOverviewCategory' });
	redirectOldCategory(router, 'website.issues.issueCategory', 'issuesOverviewCategory');

	router.add({ name: 'website.issues.issueCategory.affectedPages', path: '/pages' });

	router.add({ name: 'website.issues.issueCategory.affectedPages.export', path: '/export' });

	router.add({ name: 'website.issues.issueCategory.singleIssueAffectedPages', path: '/:issuesOverviewIssue/pages' });
	redirectOldIssue(router, 'website.issues.issueCategory.singleIssueAffectedPages', 'issuesOverviewCategory', 'issuesOverviewIssue');

	router.add({ name: 'website.issues.issueCategory.singleIssueAffectedLinks', path: '/:issuesOverviewIssue/links' });

	router.add({ name: 'website.issues.issueCategory.singleIssueAffectedPages.datepicker', path: '/pick-date' });
	router.add({ name: 'website.issues.issueCategory.singleIssueAffectedPages.export', path: '/export' });

	router.add({ name: 'website.issues.issueCategory.configuration', path: '/configuration' });

	router.add({ name: 'website.issues.issueCategory.categoryIgnoring', path: '/ignoring' });

	router.add({ name: 'website.issues.issueCategory.ignoring_deprecated', path: '/:issuesOverviewIssue/ignoring/:scope' });
	router.canActivate('website.issues.issueCategory.ignoring_deprecated', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.issues.issueCategory.issueIgnoring',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'website.issues.issueCategory.issueIgnoring', path: '/ignoring/:issuesOverviewIssue/:scope' });

	router.add({ name: 'website.detail', path: '/settings' });

	router.add({ name: 'website.detail.admin', path: '/admin' });
	router.add({ name: 'website.detail.auditing', path: '/auditing' });
	router.add({ name: 'website.detail.customElements', path: '/custom_elements' });
	router.add({ name: 'website.detail.enrichmentFields', path: '/enrichment_fields' });
	router.add({ name: 'website.detail.indexNow', path: '/indexnow' });
	router.add({ name: 'website.detail.integrations', path: '/integrations' });
	router.add({ name: 'website.detail.emailsSettings', path: '/emails_settings' });
	router.add({ name: 'website.detail.monitoring', path: '/monitoring' });
	router.add({ name: 'website.detail.nonPages', path: '/nonPages' });
	router.add({ name: 'website.detail.logFileAnalysis', path: '/log_file_analysis' });
	router.add({ name: 'website.detail.segments', path: '/segments' });

	router.add({ name: 'website.detail.exclusionList', path: '/exclusion_list' });
	router.canActivate('website.detail.exclusionList', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.detail',
				params: Object.assign({}, toState.params, {
					action: 'exclusion_list',
				}),
			},
		});
	});

	router.add({ name: 'website.detail.pruneOrphans', path: '/prune_orphans' });
	router.canActivate('website.detail.pruneOrphans', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.detail',
				params: Object.assign({}, toState.params, {
					action: 'purge_orphan_pages',
				}),
			},
		});
	});

	router.add({ name: 'website.segmentEditor', path: '/segments/editor' });
	router.add({ name: 'website.segmentEditor.create', path: '/new' });
	router.add({ name: 'website.segmentEditor.edit', path: '/:segmentName' });

	router.add({ name: 'website.segmentEditor_deprecated', path: '/segments/configuration' });
	router.canActivate('website.segmentEditor_deprecated', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.segmentEditor',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'website.segmentEditor_deprecated.create', path: '/new' });
	router.canActivate('website.segmentEditor_deprecated.create', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.segmentEditor.create',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'website.segmentEditor_deprecated.edit', path: '/:segmentName' });
	router.canActivate('website.segmentEditor_deprecated.edit', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'website.segmentEditor.edit',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'website.staticSegmentEditor', path: '/segments/static_editor' });
	router.add({ name: 'website.staticSegmentEditor.create', path: '/new' });
	router.add({ name: 'website.staticSegmentEditor.edit', path: '/:segmentName' });

	router.add({ name: 'settings', path: '/settings' });
	router.canActivate('settings', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'userProfile',
			},
		});
	});

	router.add({ name: 'account', path: '/account?accountId' });
	router.add({ name: 'team', path: '/team' });
	router.canActivate('team', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: toState.name.replace('team', 'account'),
				params: toState.params,
			},
		});
	});

	router.add({ name: 'multiDashboard', path: '/websites' });

	router.add({ name: 'account.websites', path: '/websites?view' });
	router.add({ name: 'team.websites', path: '/websites' });

	router.add({ name: 'account.websites.website', path: '/:websiteId' });
	router.canActivate('account.websites.website', () => (toState, fromState, done) => {
		// We handle redirecting to customElement specially because of its children
		// routes `new` and `edit`. These are moved from being routes to being
		// actions. In this redirect we don't handle the redirect to these actions
		// but instead lead them all to the customElements overview.
		if (toState.name.includes('customElements')) {
			done({
				redirect: {
					name: 'website.detail.customElements',
					params: {
						...toState.params,
						customElementName: false,
					},
				},
			});
		} else {
			done({
				redirect: {
					name: toState.name.replace('account.websites.website', 'website.detail'),
					params: toState.params,
				},
			});
		}
	});

	router.add({ name: 'team.websites.website', path: '/:websiteId' });

	router.add({ name: 'account.websites.website.auditing', path: '/auditing' });
	router.canActivate('account.websites.website.auditing', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.websites.website.monitoring',
				params: toState.params,
			},
		});
	});
	router.add({ name: 'account.websites.website.customElements', path: '/custom_elements' });
	router.add({ name: 'account.websites.website.customElements.delete', path: '/:customElementName/delete' });
	router.add({ name: 'account.websites.website.customElements.edit', path: '/:customElementName/edit' });
	router.add({ name: 'account.websites.website.indexNow', path: '/indexnow' });
	router.add({ name: 'account.websites.website.integrations', path: '/integrations' });
	router.add({ name: 'account.websites.website.emailsSettings', path: '/emails_settings' });
	router.add({ name: 'account.websites.website.monitoring', path: '/monitoring' });
	router.add({ name: 'account.websites.website.logFileAnalysis', path: '/log_file_analysis' });
	router.add({ name: 'account.websites.website.segments', path: '/segments' });

	router.add({ name: 'account.websites.website.customElement', path: '/custom_element' });

	router.add({ name: 'team.websites.website.exclusionList', path: '/exclusion_list' });
	router.add({ name: 'account.websites.website.exclusionList', path: '/exclusion_list' });
	router.canActivate('account.websites.website.exclusionList', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.websites.website',
				params: Object.assign({}, toState.params, {
					action: 'exclusion_list',
				}),
			},
		});
	});

	router.add({ name: 'account.websites.website.pruneOrphans', path: '/prune_orphans' });
	router.canActivate('account.websites.website.pruneOrphans', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.websites.website',
				params: Object.assign({}, toState.params, {
					action: 'purge_orphan_pages',
				}),
			},
		});
	});

	router.add({ name: 'account.websites.new', path: '/new?step&websiteId' });
	router.add({ name: 'team.websites.new', path: '/new' });

	router.add({ name: 'account.members', path: '/team_members?view' });
	router.add({ name: 'team.members', path: '/members' });
	router.add({ name: 'account.members.new', path: '/new' });

	router.add({ name: 'account.members.member', path: '/:memberDetailId' });
	router.add({ name: 'account.members.member.assignedWebsites', path: '/assigned_websites' });
	router.add({ name: 'account.members.member.emailSettings', path: '/email_settings' });
	router.add({ name: 'account.members.member.changeRole', path: '/change_role' });
	router.canActivate('account.members.member.changeRole', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.members.member',
				params: Object.assign({}, toState.params, {
					action: 'change_role',
				}),
			},
		});
	});

	router.add({ name: 'account.members.invite', path: '/invite' });
	router.add({ name: 'team.members.invite', path: '/invite' });

	router.add({ name: 'account.settings', path: '/settings?action' });
	router.add({ name: 'team.profile', path: '/profile' });
	router.add({ name: 'account.profile', path: '/team_profile' });
	router.canActivate('account.profile', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.settings',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'account.settings.subscription', path: '/subscription' });

	router.add({ name: 'account.settings.organizationAccess', path: '/organization_access' });

	router.add({ name: 'account.settings.integrationTokens', path: '/integration_tokens' });
	router.add({ name: 'account.settings.integrationTokens.acceptReportingApiTermsOfUse', path: '/reporting_api/terms_of_use' });
	router.add({ name: 'account.settings.acceptReportingApiTermsOfUse', path: '/reporting_api/terms_of_use' });
	router.canActivate('account.settings.acceptReportingApiTermsOfUse', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.settings.integrationTokens.acceptReportingApiTermsOfUse',
				params: toState.params,
			},
		});
	});
	router.add({ name: 'account.settings.integrationTokens.changeReportingApiToken', path: '/reporting_api/change_token' });
	router.add({ name: 'account.settings.changeReportingApiToken', path: '/reporting_api/change_token' });
	router.canActivate('account.settings.changeReportingApiToken', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.settings.integrationTokens.changeReportingApiToken',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'account.settings.integrationTokens.acceptEnrichmentApiTermsOfUse', path: '/data_enrichment_api/terms_of_use' });
	router.add({ name: 'account.settings.integrationTokens.changeEnrichmentApiToken', path: '/data_enrichment_api/change_token' });

	router.add({ name: 'account.settings.monitoring', path: '/monitoring' });

	router.add({ name: 'account.settings.connectedClients', path: '/connected_clients' });
	router.canActivate('account.settings.connectedClients', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.connectedAccounts',
			},
		});
	});
	router.add({ name: 'account.settings.connectedClients.acceptAccessToClient', path: '/accept_access_to_client/:clientId' });
	router.add({ name: 'account.settings.acceptAccessToClient', path: '/accept_access_to_client/:clientId' });
	router.canActivate('account.settings.acceptAccessToClient', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.settings.connectedClients.acceptAccessToClient',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'account.settings.agencyAccess', path: '/connected_accounts' });
	router.add({ name: 'account.settings.agencyAccess.grantAgencyAccess', path: '/grant_access/:agencyId' });
	router.add({ name: 'account.settings.grantAgencyAccess', path: '/grant_access/:agencyId' });
	router.canActivate('account.settings.grantAgencyAccess', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.settings.agencyAccess.grantAgencyAccess',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'account.settings.connectToAgency', path: '/connect_to_acount' });
	router.canActivate('account.settings.connectToAgency', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.settings',
				params: Object.assign({}, toState.params, {
					action: 'connect_to_acount',
				}),
			},
		});
	});

	router.add({ name: 'account.settings.billing', path: '/billing' });
	router.add({ name: 'account.settings.invoices', path: '/invoices' });

	router.add({ name: 'account.billing', path: '/billing' });
	router.add({ name: 'account.billing.invoices', path: '/invoices' });
	router.add({ name: 'team.billing', path: '/billing' });

	router.canActivate('account.billing', () => (toState, fromState, done) => {
		if (toState.name === 'account.billing.invoices') {
			done({
				redirect: {
					name: 'account.settings.invoices',
					params: toState.params,
				},
			});
			return;
		}

		done({
			redirect: {
				name: 'account.settings.billing',
				params: toState.params,
			},
		});
	});

	router.canActivate('team.billing', () => (toState, fromState, done) => {
		if (toState.name === 'team.billing.invoices') {
			done({
				redirect: {
					name: 'account.settings.invoices',
					params: toState.params,
				},
			});
			return;
		}

		done({
			redirect: {
				name: 'account.settings.billing',
				params: toState.params,
			},
		});
	});


	router.add({ name: 'account.user_profile', path: '/user_profile' });
	router.canActivate('account.user_profile', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'userProfile',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'account.user_profile.emailSettings', path: '/email_settings' });
	router.canActivate('account.user_profile.emailSettings', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'userProfile.emailSettings',
				params: toState.params,
			},
		});
	});

	router.add({ name: 'userProfile', path: '/your_profile' });
	router.add({ name: 'userProfile.emailSettings', path: '/email_settings' });

	router.add({ name: 'account.pricing', path: '/pricing' });
	router.add({ name: 'account.pricing.signup', path: '/signup' });

	router.add({ name: 'account.connectedAccounts', path: '/connected_accounts' });
	router.add({ name: 'account.connectedAccounts.connectAccount', path: '/connect' });
	router.add({ name: 'account.connectedAccounts.removeAccess', path: '/remove_access/:clientId' });
	router.add({ name: 'account.connectedAccounts.cancelRequest', path: '/cancel_request/:clientId' });
	router.add({ name: 'account.connectedAccounts.acceptAccessToClient', path: '/accept_access_to_client/:clientId' });

	router.add({ name: 'account.admin', path: '/admin' });
	router.add({ name: 'account.admin.customerTeamContext', path: '/customerTeamContext' });
	router.add({ name: 'account.admin.featureUsage', path: '/featureUsage' });
	router.add({ name: 'account.admin.integrations', path: '/integrations' });
	router.add({ name: 'account.admin.marketing', path: '/marketing' });
	router.add({ name: 'account.admin.phase', path: '/phase' });
	router.add({ name: 'account.admin.sales', path: '/sales' });
	router.add({ name: 'account.admin.websiteSettings', path: '/websiteSettings' });

	router.add({ name: 'generalAdmin', path: '/generalAdmin' });
	router.add({ name: 'generalAdmin.jobStatistics', path: '/jobStatistics' });
	router.add({ name: 'generalAdmin.schemaOrgValidator', path: '/schemaOrgValidator' });
	router.add({ name: 'generalAdmin.settings', path: '/settings' });
	router.add({ name: 'generalAdmin.settings.conductorCustomerDomains', path: '/conductorCustomerDomains' });
	router.add({ name: 'generalAdmin.settings.guaranteedBlacklistDomains', path: '/guaranteedBlacklistDomains' });
	router.add({ name: 'generalAdmin.settings.overridableBlacklistDomains', path: '/overridableBlacklistDomains' });
	router.add({ name: 'generalAdmin.settings.trialAbuserEmails', path: '/trialAbuserEmails' });

	router.add({ name: 'upgradeToPaid', path: '/signup' });
	router.canActivate('upgradeToPaid', () => (toState, fromState, done) => {
		done({
			redirect: {
				name: 'account.pricing',
				params: toState.params,
			},
		});
	});

	// 404
	router.add({ name: '404', path: '/:whatever' });

	router.add({ name: 'empty_state', path: '/no_website' });
}
