import classNames from 'classnames';
import React from 'react';



export enum GlobalFormMessagePatternStyle {
	AuthForm = 'auth-form',
	Default = 'default',
	Fullwidth = 'fullwidth',
	None = 'none',
}

type Props = {
	children: React.ReactNode,
	style?: GlobalFormMessagePatternStyle,
};



const GlobalFormMessagePattern: React.FC<Props> = (props) => {
	const {
		children,
		style = GlobalFormMessagePatternStyle.Default,
	} = props;

	if (style === GlobalFormMessagePatternStyle.None) {
		return <>{children}</>;
	}

	const componentClasses = classNames({
		'global-form-msg': true,
		'global-form-msg--auth-form': style === GlobalFormMessagePatternStyle.AuthForm,
		'global-form-msg--fullwidth': style === GlobalFormMessagePatternStyle.AuthForm || style === GlobalFormMessagePatternStyle.Fullwidth,
	});

	return (
		<div className={componentClasses}>
			<div className="global-form-msg__content">
				{children}
			</div>
		</div>
	);
};



export default GlobalFormMessagePattern;
