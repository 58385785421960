/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ColumnSetsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type ColumnSetsQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly sharedColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly personalColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> } | null };


export const ColumnSetsDocument = gql`
    query ColumnSets {
  authenticatedSession {
    memberships {
      account {
        id
        sharedColumnSets {
          columns {
            enabled
            name
          }
          id
          isDefault
          name
        }
      }
      user {
        id
        legacyId
      }
    }
    personalColumnSets {
      columns {
        enabled
        name
      }
      id
      isDefault
      name
    }
  }
}
    `;

/**
 * __useColumnSetsQuery__
 *
 * To run a query within a React component, call `useColumnSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useColumnSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColumnSetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useColumnSetsQuery(baseOptions?: Apollo.QueryHookOptions<ColumnSetsQuery, ColumnSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ColumnSetsQuery, ColumnSetsQueryVariables>(ColumnSetsDocument, options);
      }
export function useColumnSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ColumnSetsQuery, ColumnSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ColumnSetsQuery, ColumnSetsQueryVariables>(ColumnSetsDocument, options);
        }
export function useColumnSetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ColumnSetsQuery, ColumnSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ColumnSetsQuery, ColumnSetsQueryVariables>(ColumnSetsDocument, options);
        }
export type ColumnSetsQueryHookResult = ReturnType<typeof useColumnSetsQuery>;
export type ColumnSetsLazyQueryHookResult = ReturnType<typeof useColumnSetsLazyQuery>;
export type ColumnSetsSuspenseQueryHookResult = ReturnType<typeof useColumnSetsSuspenseQuery>;
export type ColumnSetsQueryResult = Apollo.QueryResult<ColumnSetsQuery, ColumnSetsQueryVariables>;