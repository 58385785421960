import type GraphQL from '~/types/graphql';



export type CustomElementDefinition = {
	column: GraphQL.CustomElement['column'],
	createdAt: GraphQL.CustomElement['createdAt'],
	dataType: GraphQL.CustomElement['dataType'],
	extraction: GraphQL.CustomElement['extraction'],
	name: GraphQL.CustomElement['name'],
	label: GraphQL.CustomElement['label'],
};

export const DATA_TYPE_BOOLEAN = 'boolean';
export const DATA_TYPE_DATE = 'date';
export const DATA_TYPE_HASH = 'hash';
export const DATA_TYPE_INTEGER = 'integer';
export const DATA_TYPE_NUMBER = 'number';

export const DATA_TYPE_STRING = 'string';

export const ELEMENT_TYPE_BOOLEAN = 'boolean';
export const ELEMENT_TYPE_DATE = 'date';
export const ELEMENT_TYPE_INTEGER = 'integer';
export const ELEMENT_TYPE_NUMBER = 'number';
export const ELEMENT_TYPE_STRING = 'string';

export const EXTRACTED_ELEMENT_DATE = 'date';
export const EXTRACTED_ELEMENT_NUMBER = 'number';
export const EXTRACTED_ELEMENT_STRING = 'string';

export const EXTRACTED_ELEMENT_OPTIONS_CONDENSE_WHITESPACE = 'condense_whitespace';
export const EXTRACTED_ELEMENT_OPTIONS_DECODE_HTML_ENTITIES = 'decode_html_entities';
export const EXTRACTED_ELEMENT_OPTIONS_DROP_EMPTY = 'drop_empty';
export const EXTRACTED_ELEMENT_OPTIONS_STRIP_TAGS = 'strip_tags';

export const EXTRACTION_METHOD_CSS = 'css';
export const EXTRACTION_METHOD_REGEXP = 'regexp';
export const EXTRACTION_METHOD_XPATH = 'xpath';

export const EXTRACTION_SOURCE_DOM = 'dom';
export const EXTRACTION_SOURCE_SRC = 'src';

export const OCCURRENCES_ALL = 'all';
export const OCCURRENCES_LAST = 'last';
export const OCCURRENCES_SPECIFIC = 'specific';

export const TRANSFORMATION_APPEND = 'append';
export const TRANSFORMATION_APPLY_PATTERN = 'apply_pattern';
export const TRANSFORMATION_CAST_TO_BOOLEAN = 'cast_to_boolean';
export const TRANSFORMATION_CHARACTER_LENGTH = 'character_length';
export const TRANSFORMATION_CHECKSUM = 'checksum';
export const TRANSFORMATION_CONCATENATE = 'concatenate';
export const TRANSFORMATION_EXTRACT_DATE = 'extract_date';
export const TRANSFORMATION_EXTRACT_FROM_JSON = 'extract_from_json';
export const TRANSFORMATION_EXTRACT_NUMBER = 'extract_number';
export const TRANSFORMATION_NUMBER_OF_ELEMENTS = 'number_of_elements';
export const TRANSFORMATION_PREPEND = 'prepend';
export const TRANSFORMATION_REPLACE = 'replace';
export const TRANSFORMATION_STRIP_TAGS = 'strip_tags';
export const TRANSFORMATION_TRUNCATE = 'truncate';
export const TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES = 'whitelist_html_attributes';



export const DEFAULT_EXTRACTION_SETUP: Extraction = {
	source: 'src',
	steps: [
		{
			rule: {
				type: null,
				options: {},
			},
			occurences: {
				type: OCCURRENCES_ALL,
				options: {},
			},
		},
	],
	transformations: [],
	type: null,
	type_options: {
		[EXTRACTED_ELEMENT_OPTIONS_CONDENSE_WHITESPACE]: true,
		[EXTRACTED_ELEMENT_OPTIONS_DECODE_HTML_ENTITIES]: true,
		[EXTRACTED_ELEMENT_OPTIONS_DROP_EMPTY]: false,
		[EXTRACTED_ELEMENT_OPTIONS_STRIP_TAGS]: true,
	},
};



const DATA_TYPES_TO_ELEMENT_TYPES_MAP = {
	[DATA_TYPE_BOOLEAN]: ELEMENT_TYPE_BOOLEAN,
	[DATA_TYPE_DATE]: ELEMENT_TYPE_DATE,
	[DATA_TYPE_HASH]: ELEMENT_TYPE_STRING,
	[DATA_TYPE_INTEGER]: ELEMENT_TYPE_INTEGER,
	[DATA_TYPE_NUMBER]: ELEMENT_TYPE_NUMBER,
	[DATA_TYPE_STRING]: ELEMENT_TYPE_STRING,
};



export function detectDataType(extraction) {
	if (extraction.transformations.length > 0) {
		const lastTransformation = extraction.transformations[extraction.transformations.length - 1];

		return detectTransformationOutputDataType(lastTransformation.type, lastTransformation.options);
	}

	return detectExtractedElementOutputDataType(extraction.type);
}



export function detectExtractedElementOutputDataType(extractedElementType) {
	if (extractedElementType === EXTRACTED_ELEMENT_DATE) {
		return DATA_TYPE_DATE;
	}

	if (extractedElementType === EXTRACTED_ELEMENT_NUMBER) {
		return DATA_TYPE_NUMBER;
	}

	if (extractedElementType === EXTRACTED_ELEMENT_STRING) {
		return DATA_TYPE_STRING;
	}

	return null;
}



export function convertDataTypeToElementType(dataType) {
	return DATA_TYPES_TO_ELEMENT_TYPES_MAP[dataType];
}



export function detectTransformationOutputDataType(transformationType, transformationOptions: Record<string, any> = {}) {
	if (transformationType === TRANSFORMATION_CAST_TO_BOOLEAN) {
		return DATA_TYPE_BOOLEAN;
	}

	if ([
		TRANSFORMATION_CHARACTER_LENGTH,
		TRANSFORMATION_NUMBER_OF_ELEMENTS,
	].includes(transformationType)) {
		return DATA_TYPE_INTEGER;
	}

	if (transformationType === TRANSFORMATION_CHECKSUM) {
		return DATA_TYPE_HASH;
	}

	if (transformationType === TRANSFORMATION_EXTRACT_DATE) {
		return DATA_TYPE_DATE;
	}

	if (transformationType === TRANSFORMATION_EXTRACT_FROM_JSON) {
		return transformationOptions.extracted_type ?? DATA_TYPE_STRING;
	}

	if (transformationType === TRANSFORMATION_EXTRACT_NUMBER) {
		return DATA_TYPE_NUMBER;
	}

	return DATA_TYPE_STRING;
}



export function doesProduceSingleOccurrence(extraction) {
	if (extraction.transformations.length > 0) {
		const lastTransformation = extraction.transformations[extraction.transformations.length - 1];

		if ([
			TRANSFORMATION_CHECKSUM,
			TRANSFORMATION_CONCATENATE,
			TRANSFORMATION_NUMBER_OF_ELEMENTS,
		].includes(lastTransformation.type)) {
			return true;
		}
	}

	if (extraction.steps.length > 0) {
		const lastExtractionStep = extraction.steps[extraction.steps.length - 1];

		return lastExtractionStep.occurences.type !== OCCURRENCES_ALL;
	}

	return null;
}



export function doesTrackingHaveToBeRestarted(oldDataType, newDataType) {
	return convertDataTypeToElementType(oldDataType) !== convertDataTypeToElementType(newDataType);
}



export function isTransformationCompatibleWithDataType(inputDataType, transformationType) {
	if ([
		TRANSFORMATION_APPEND,
		TRANSFORMATION_APPLY_PATTERN,
		TRANSFORMATION_CHARACTER_LENGTH,
		TRANSFORMATION_CONCATENATE,
		TRANSFORMATION_EXTRACT_DATE,
		TRANSFORMATION_EXTRACT_FROM_JSON,
		TRANSFORMATION_EXTRACT_NUMBER,
		TRANSFORMATION_PREPEND,
		TRANSFORMATION_REPLACE,
		TRANSFORMATION_STRIP_TAGS,
		TRANSFORMATION_TRUNCATE,
		TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES,
	].includes(transformationType)) {
		return inputDataType === DATA_TYPE_HASH || inputDataType === DATA_TYPE_STRING;
	}

	return true;
}



export function validateExpressionOfCss(value) {
	const element = document.createElement('div');

	try {
		element.querySelector(value);
	} catch (e) {
		return false;
	}

	return true;
}



export function validateExpressionOfRegexp(value) {
	if (value.length < 2) {
		return false;
	}

	const delimiter = value.substring(0, 1);
	const pattern = value.substring(1, value.lastIndexOf(delimiter));
	const flags = value.substring(value.lastIndexOf(delimiter) + 1);

	try {
		new RegExp(delimiter + pattern + delimiter, flags);
	} catch (e) {
		return false;
	}

	return true;
}



export function validateExpressionOfXPath(value) {
	const element = document.createElement('div');

	try {
		document.evaluate(value, element);
	} catch (e) {
		return false;
	}

	return (
		value.includes('| contains(')
	) === false;
}



type Transformation = any;

export type Extraction = {
	source:
		| typeof EXTRACTION_SOURCE_DOM
		| typeof EXTRACTION_SOURCE_SRC,
	steps: Array<{
		rule: {
			type:
				| typeof EXTRACTION_METHOD_CSS
				| typeof EXTRACTION_METHOD_REGEXP
				| typeof EXTRACTION_METHOD_XPATH
				| null,
			options: Record<string, any>,
		},
		occurences: {
			type:
				| typeof OCCURRENCES_ALL
				| typeof OCCURRENCES_SPECIFIC,
			options: Record<string, any>,
		},
	}>,
	transformations: Array<Transformation>,
	type:
		| typeof EXTRACTED_ELEMENT_DATE
		| typeof EXTRACTED_ELEMENT_NUMBER
		| typeof EXTRACTED_ELEMENT_STRING
		| null,
	type_options: Record<string, any>,
};



export enum CustomElementDeletionViabilityVerdict {
	NotPrevented,
	PreventedDueToAlertDefinitions,
	PreventedDueToSegmentDefinitions,
}
