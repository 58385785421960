import classNames from 'classnames';
import React from 'react';



export enum SegmentIdentifierPlaceholderSize {
	Default = 'default',
	Small = 'small',
}



type Props = {
	label?: React.ReactNode,
	size?: SegmentIdentifierPlaceholderSize,
};



const SegmentIdentifierPlaceholder: React.FC<Props> = (props) => {
	const {
		label,
		size = SegmentIdentifierPlaceholderSize.Default,
	} = props;

	const componentClasses = classNames({
		'segment-identifier-placeholder': true,
		'segment-identifier-placeholder--small': size === SegmentIdentifierPlaceholderSize.Small,
	});

	return (
		<div className={componentClasses}>
			{label && (
				<div className="segment-identifier-placeholder__content">
					<span className="segment-identifier-placeholder__label">
						{label}
					</span>
				</div>
			)}
		</div>
	);
};



export default SegmentIdentifierPlaceholder;
