import classNames from 'classnames';
import React from 'react';
import tinycolor from 'tinycolor2';

import Spinner, {
	SpinnerSize,
	SpinnerStyle,
} from '~/components/patterns/loaders/Spinner';

import styles from './SegmentIdentifier.module.scss';



export enum SegmentIdentifierSize {
	Default = 'default',
	Small = 'small',
}

export enum SegmentIdentifierStyle {
	Italic = 'italic',
	Normal = 'normal',
}

export enum SegmentIdentifierType {
	BeingDragged = 'being-dragged',
	Draggable = 'draggable',
}

function isColorTooLight(colorString) {
	const color = tinycolor(colorString);
	return color.getBrightness() > 180;
}



type Props = {
	color?: string,
	disabled?: boolean,
	inversedColors?: boolean,
	isLoading?: boolean,
	label?: React.ReactNode,
	onClickCallback?: () => void,
	size?: SegmentIdentifierSize,
	strikethrough?: boolean,
	style?: SegmentIdentifierStyle,
	symbol?: React.ReactNode,
	type?: SegmentIdentifierType,
};

const SegmentIdentifier: React.FC<Props> = (props) => {
	const {
		color,
		disabled = false,
		inversedColors = false,
		isLoading = false,
		label,
		onClickCallback,
		size = SegmentIdentifierSize.Default,
		strikethrough = false,
		style = SegmentIdentifierStyle.Normal,
		symbol,
		type,
	} = props;

	const clickHandler = (e) => {
		e.preventDefault();

		if (onClickCallback) {
			onClickCallback();
		}
	};

	const componentStyle: React.CSSProperties = {};

	if (color) {
		componentStyle.backgroundColor = color;
	}

	const additionalProps: React.HTMLProps<HTMLDivElement> = {};

	if (onClickCallback && !disabled) {
		additionalProps.onClick = clickHandler;
	}

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.small]: size === SegmentIdentifierSize.Small,

		[styles.isBeingDragged]: !disabled && type === SegmentIdentifierType.BeingDragged,
		[styles.isClickable]: !disabled && onClickCallback,
		[styles.isDraggable]: !disabled && type === SegmentIdentifierType.Draggable,
		[styles.isDisabled]: disabled,
		[styles.isItalicStyle]: style === SegmentIdentifierStyle.Italic,

		[styles.useDarkFont]: isColorTooLight(color),
		[styles.inversedStyle]: inversedColors,
		[styles.strikethrough]: strikethrough,
		[styles.isLoading]: isLoading,
	});

	return (
		<div
			className={componentClasses}
			style={componentStyle}
			{...additionalProps}
		>
			<span className={styles.symbol}>
				<span className={styles.symbolContent}>
					{symbol}
				</span>

				{isLoading && (
					<div className={styles.symbolLoadingOverlay}>
						<Spinner
							size={size === SegmentIdentifierSize.Small ? SpinnerSize.XSmall : SpinnerSize.Small}
							style={isColorTooLight(color) ? SpinnerStyle.Dark : SpinnerStyle.White}
						/>
					</div>
				)}
			</span>

			{label && (
				<div className={styles.content}>
					<span className={styles.label}>
						{label}
					</span>
				</div>
			)}
		</div>
	);
};



export default SegmentIdentifier;
