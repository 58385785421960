import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Emphasis from '~/components/patterns/typography/Emphasis';



const messages = defineMessages({
	label: {
		id: 'ui.general.missing',
		defaultMessage: 'missing',
	},
});



type Props = {
	/** Enable ellpsis support to prevent overflowing */
	ellipsis?: boolean,
	/** Custom message explaining missing value (instead of predefined word "missing") */
	children?: React.ReactNode,
};

const MissingValue: React.FC<Props> = (props) => {
	const {
		ellipsis,
		children,
	} = props;

	return (
		<Emphasis ellipsis={ellipsis}>
			{children || <FormattedMessage {...messages.label} />}
		</Emphasis>
	);
};



export default MissingValue;
