import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	fraction: {
		id: 'ui.general.fraction',
	},
});



type Props = {
	partA: React.ReactNode,
	partB: React.ReactNode,
};

const Fraction: React.FC<Props> = (props) => {
	const {
		partA,
		partB,
	} = props;

	return (
		<FormattedMessage
			{...messages.fraction}
			values={{
				partA,
				partB,
			}}
		/>
	);
};



export default Fraction;
