export enum Action {
	CreateNewAccount = 'CreateNewAccount',
	ConnectExistingAccount = 'ConnectExistingAccount',
}

export type Values = {
	action: Action | undefined,
	domain: string | undefined,
	email: string | undefined,
	accountName: string | undefined,
};
