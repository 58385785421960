import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import {
	useHandleSuccessfulLoginLazyQuery,
} from './useHandleSuccessfulLogin.gql';

import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';

import {
	tryLoadUserData,
} from '~/model/authentication';

import {
	isLoginScreenView,
} from '~/model/loginScreen';

import {
	getRouterState,
	navigate,
} from '~/routing/router';

import {
	notEmpty,
} from '~/utilities/typeCheck';



function useHandleSuccessfulLogin() {
	const dispatch = useDispatch();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const [loadData] = useHandleSuccessfulLoginLazyQuery();

	return React.useCallback(
		async () => {
			const { data } = await loadData();

			const currentUserFirstName = data?.authenticatedSession?.user.firstName ?? null;
			const currentUserLastName = data?.authenticatedSession?.user.lastName ?? null;
			const customerTypes = data?.authenticatedSession?.memberships.map(
				(membership) => membership.account.customerType,
			).filter(notEmpty) ?? [];
			const isCurrentUserOwner = data?.authenticatedSession?.memberships.some(
				(membership) => membership.isOwner,
			) ?? null;
			const isOnboardingTourInProgress = data?.authenticatedSession?.memberships.find(
				(membership) => membership.account.id === effectiveHomeAccountId,
			)?.isOnboardingTourInProgress ?? null;

			await dispatch(tryLoadUserData());

			const routerState = getRouterState();

			if (isLoginScreenView(routerState.name) || routerState.name === 'kickstart') {
				const routeParams: Record<string, any> = {};

				if (routerState.params.reactivate) {
					routeParams.reactivate = true;
				}

				const isOnboarding = (
					isOnboardingTourInProgress
					&& (
						!currentUserFirstName
						|| !currentUserLastName
						|| isCurrentUserOwner === false
						|| (isCurrentUserOwner && customerTypes.length === 0)
					)
				);

				if (isOnboarding && !routerState.params.reactivate) {
					navigate(
						'website.pages',
						{
							websiteId: '{id}',
						},
					);
				} else {
					navigate(
						'home',
						routeParams,
					);
				}
			}
		},
		[
			dispatch,
			effectiveHomeAccountId,
			loadData,
		],
	);
}



export default useHandleSuccessfulLogin;
