import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const WebsitesIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m26 25.99935h-26v-25.99935h26z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				>
					<path d="m10.5629875 11.3747156h-4.875c-.4485 0-.8125-.3639909-.8125-.8124797v-4.87487809c0-.44848878.364-.81247968.8125-.81247968h4.875c.4485 0 .8125.3639909.8125.81247968v4.87487809c0 .4484888-.364.8124797-.8125.8124797z" />
					<path d="m10.5629875 21.1303217h-4.875c-.4485 0-.8125-.3639909-.8125-.8124797v-4.8748781c0-.4484888.364-.8124797.8125-.8124797h4.875c.4485 0 .8125.3639909.8125.8124797v4.8748781c0 .4484888-.364.8124797-.8125.8124797z" />
					<path d="m20.3188375 11.3747156h-4.875c-.4485 0-.8125-.3639909-.8125-.8124797v-4.87487809c0-.44848878.364-.81247968.8125-.81247968h4.875c.4485 0 .8125.3639909.8125.81247968v4.87487809c0 .4484888-.364.8124797-.8125.8124797z" />
					<path d="m20.3188375 21.1303217h-4.875c-.4485 0-.8125-.3639909-.8125-.8124797v-4.8748781c0-.4484888.364-.8124797.8125-.8124797h4.875c.4485 0 .8125.3639909.8125.8124797v4.8748781c0 .4484888-.364.8124797-.8125.8124797z" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default WebsitesIconBuilder;

