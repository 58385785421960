import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Show customer logo in grayscale style */
	grayscale?: boolean,
	/** Name of customer */
	name: string,
	/** Alternate path to optional retina images */
	retinaSrc?: string,
	/** Path to logo file */
	src: string,
};



const CustomerLogo: React.FC<Props> = (props) => {
	const {
		grayscale,
		name,
		retinaSrc,
		src,
	} = props;

	let srcSet = src;

	if (retinaSrc) {
		srcSet += ', ' + retinaSrc + ' 2x';
	}

	const componentClasses = classNames({
		'customer-logo': true,
		'customer-logo--grayscale': grayscale,
	});

	return (
		<div className={componentClasses}>
			<img
				alt={'Logo: ' + name}
				className="customer-logo__image"
				src={src}
				srcSet={srcSet}
				title={name}
			/>
		</div>
	);
};



export default CustomerLogo;
