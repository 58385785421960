import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import {
	usePopper,
} from 'react-popper';

import DepthLayer from '~/components/patterns/utils/DepthLayer';
import TextFieldOverlay from './TextFieldOverlay.part';

import {
	isNumber,
} from '~/utilities/typeCheck';



export enum AbstractTextFieldSize {
	XSmall = 'xsmall',
	Small = 'small',
	Default = 'default',
	Large = 'large',
}

export enum AbstractTextFieldStyle {
	Eminence = 'eminence',
	Light = 'light',
}

export enum AbstractTextFieldType {
	Color = 'color',
	Date = 'date',
	DateTime = 'datetime',
	DateTimeLocal = 'datetime-local',
	Email = 'email',
	Month = 'month',
	Number = 'number',
	Password = 'password',
	Search = 'search',
	Tel = 'tel',
	Text = 'text',
	Time = 'time',
	URL = 'url',
	Week = 'week',
}

type Props = {
	/** Additional attributes - like another HTML attributes we need to have it attached to field */
	attributes?: React.AllHTMLAttributes<HTMLInputElement>,
	/** Attached additional buttons */
	buttons?: Array<React.ReactElement>,
	/** Special style for code showing value using monospace font */
	code?: boolean,
	/** Possibility to attach dropdown to field */
	dropdown?: React.ReactNode,
	dropdownHeight?: React.CSSProperties['height'],
	/** When dropdown attached we can control its visibility (revealed or not) */
	dropdownVisible?: boolean,
	/** Own width of dropdown when defined (otherwise it'll use width of field) */
	dropdownWidth?: number,
	/** Using this option we can define whether component value should be controlled. When enabled value can be changed everytime we update value prop */
	isControlled?: boolean,
	/** We will apply the same highlight which can be shown on :focus */
	isHighlighted?: boolean,
	/** Name of HTML field */
	name: string,
	/** Callback triggered on field blur */
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
	/** Callback triggered on value change */
	onChangeCallback?: (value: string) => void,
	/** Callback triggered on field click */
	onClick?: () => void,
	/** Callback triggered on field focus */
	onFocus?: () => void,
	/** Callback triggered on key press */
	onKeyDown?: (e: React.KeyboardEvent) => void,
	/** Callback triggered on key reveal */
	onKeyUp?: (e: React.KeyboardEvent) => void,
	/** Callback triggered on component mouse enter */
	onMouseEnter?: (e: React.MouseEvent) => void,
	/** Callback triggered on component mouse leave */
	onMouseLeave?: (e: React.MouseEvent) => void,
	/** Callback triggered when clicking on overlay */
	onOverlayClick?: (e: React.MouseEvent) => void,
	/** Possible overlay over field */
	overlay?: React.ReactNode,
	/** Possibility to disable Popper library used for attached dropdowns without overflowing */
	popperEnabled?: boolean,
	/** Size of whole element */
	size?: AbstractTextFieldSize,
	/** Color style of component */
	style?: AbstractTextFieldStyle,
	/** Possibility to enable trimming feature when copy-pasting value */
	trimValue?: boolean,
	/** Type of text field */
	type?: AbstractTextFieldType,
	/** Predefined value */
	value?: string | number,
	/** Width of field (must be number if dropdownWidth is set) */
	width?: React.CSSProperties['width'] | false,
};

export type AbstractTextFieldRef = {
	containsTarget: (target: Node | null) => boolean,
	focus: () => void,
	getFieldRef: () => React.RefObject<HTMLInputElement>,
	setValue: (value: any) => void,
};



const AbstractTextField = React.forwardRef<AbstractTextFieldRef, Props>((props, ref) => {
	const {
		attributes,
		buttons = [],
		code,
		dropdown,
		dropdownHeight,
		dropdownVisible,
		dropdownWidth,
		isControlled,
		isHighlighted,
		name,
		onBlur,
		onClick,
		onFocus,
		onChangeCallback,
		onKeyDown,
		onKeyUp,
		onMouseEnter,
		onMouseLeave,
		onOverlayClick,
		overlay,
		popperEnabled = true,
		size = AbstractTextFieldSize.Default,
		style = AbstractTextFieldStyle.Light,
		trimValue,
		type = AbstractTextFieldType.Text,
		value,
		width = 280,
	} = props;

	const [changed, setChanged] = React.useState(false);

	const componentRef = React.useRef<HTMLDivElement | null>(null);
	const fieldRef = React.useRef<HTMLInputElement | null>(null);

	const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);

	React.useImperativeHandle(ref, () => ({
		containsTarget: (target) => {
			return componentRef.current?.contains(target) ?? false;
		},
		focus: () => {
			fieldRef.current?.focus();
		},
		getFieldRef: () => fieldRef,
		setValue: (value) => {
			if (fieldRef.current && fieldRef.current.value !== value) {
				fieldRef.current.value = value;
			}
		},
	}));

	const {
		state: popperState,
		styles,
		attributes: popperAttributes,
	} = usePopper(
		referenceElement,
		popperElement,
		{
			placement: 'bottom-start',
			modifiers: [
				{
					name: 'flip',
					options: {
						altBoundary: true,
					},
				},
				{
					name: 'preventOverflow',
					options: {
						mainAxis: false,
					},
				},
			],
		},
	);

	const handleValueChange = (event) => {
		if (onChangeCallback) {
			let value = event.target.value;

			if (trimValue) {
				value = value.trim();
			}

			setChanged(true);
			onChangeCallback(value);
		}
	};

	const handleValuePaste = (event) => {
		if (trimValue) {
			const paste = event.clipboardData.getData('Text').trim();
			const selection = window.getSelection();

			if (selection && selection.toString() === fieldRef.current?.value) {
				event.preventDefault();
				fieldRef.current.value = paste;

				if (onChangeCallback) {
					onChangeCallback(paste);
				}
			}
		}
	};

	const renderButtons = () => {
		if (buttons.length === 0) {
			return null;
		}

		return (
			<div className="text-field__buttons">
				{buttons.map((button, index) => {
					return (
						<div
							className="text-field__button"
							key={button.key || 'text-field-button-' + index}
						>
							{React.cloneElement(button, {
								size,
							})}
						</div>
					);
				})}
			</div>
		);
	};

	const renderDropdown = (zIndex: number | undefined = undefined) => {
		if (!dropdown || !dropdownVisible) {
			return null;
		}

		const dropdownStyle: React.CSSProperties = {};

		if (dropdownWidth) {
			dropdownStyle.maxWidth = dropdownWidth;
			dropdownStyle.minWidth = dropdownWidth;
		}

		if (dropdownHeight) {
			dropdownStyle.maxHeight = dropdownHeight;
		}

		if (zIndex) {
			dropdownStyle.zIndex = zIndex;
		}

		const dropdownClasses = classNames({
			'text-field__dropdown': true,
			'text-field__dropdown--is-scrollable': dropdownHeight,
			'js-scrollable': dropdownHeight,
			'text-field__dropdown--is-wider': width && dropdownWidth && dropdownWidth > (isNumber(width) ? width + 4 : 0),
			'text-field__dropdown--is-pinned': !popperEnabled,
		});

		return (
			<div
				className={dropdownClasses}
				style={dropdownStyle}
			>
				{dropdown}
			</div>
		);
	};

	const additionalAttributes = Object.assign({}, attributes);
	const componentStyle: React.CSSProperties = {};

	if (width) {
		componentStyle.width = width;
	}

	if (overlay) {
		additionalAttributes.readOnly = true;
	}

	const componentClasses = classNames({
		'text-field': true,
		'text-field--large': size === AbstractTextFieldSize.Large,
		'text-field--small': size === AbstractTextFieldSize.Small,
		'text-field--xsmall': size === AbstractTextFieldSize.XSmall,
		'text-field--style-eminence': style === AbstractTextFieldStyle.Eminence,
		[ 'text-field--type-' + type ]: true,
	});

	const fieldClasses = classNames({
		'text-field__input': true,
		'text-field__input--with-dropdown': dropdown && dropdownVisible,
		'text-field__input--code-value': code,
		'text-field__input--highlighted': isHighlighted,
		[ 'text-field__input--buttons-gap-size-' + buttons.length || 0 ]: true,
	});

	// This workaround will prevent auto-correction of email fields.
	// When user was typing unsupported characters (like space), his input
	// was automatically corrected.
	// https://github.com/facebook/react/issues/6368#issuecomment-203316473
	if (!changed) {
		if (isControlled) {
			additionalAttributes.value = value;
		} else {
			additionalAttributes.defaultValue = value;
		}
	}

	if (type !== AbstractTextFieldType.Email && type !== AbstractTextFieldType.Password) {
		additionalAttributes['data-lpignore'] = 'true';
	}

	const inputField = (
		<input
			className={fieldClasses}
			id={name}
			name={name}
			onBlur={onBlur}
			onChange={handleValueChange}
			onClick={onClick}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			onKeyUp={onKeyUp}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onPaste={handleValuePaste}
			ref={fieldRef}
			type={type}
			{...additionalAttributes}
		/>
	);

	if (dropdown && popperEnabled) {
		const popperDropdownContainer = document.getElementById('popper-elements');

		return (
			<div
				className={componentClasses}
				ref={componentRef}
				style={componentStyle}
			>
				<DepthLayer>
					{({ depthLayer }) => {
						return (
							<>
								<div
									data-popper-placement={popperState && popperState.placement}
									ref={setReferenceElement}
								>
									{inputField}
									{renderButtons()}
								</div>
								{dropdownVisible && popperDropdownContainer && ReactDOM.createPortal(
									<div
										ref={setPopperElement}
										style={{
											zIndex: depthLayer,
											...styles.popper,
										}}
										{...popperAttributes.popper}
									>
										{renderDropdown()}
									</div>,
									popperDropdownContainer,
								)}
							</>
						);
					}}
				</DepthLayer>
			</div>
		);
	}

	return (
		<div
			className={componentClasses}
			ref={componentRef}
			style={componentStyle}
		>
			<DepthLayer>
				{({ depthLayer }) => {
					return (
						<>
							<TextFieldOverlay
								onOverlayClick={onOverlayClick}
								overlay={overlay}
							>
								{inputField}
							</TextFieldOverlay>
							{renderButtons()}
							{renderDropdown(depthLayer)}
						</>
					);
				}}
			</DepthLayer>
		</div>
	);
});



export default AbstractTextField;
