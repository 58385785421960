import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	closeCallback: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
	/** Make panel fullwidth */
	isFullwidth?: boolean,
};



const DetailPanel: React.FC<Props> = (props) => {
	const {
		children,
		closeCallback,
		isFullwidth,
	} = props;

	const handleCloseAreaOnClick = React.useCallback(
		(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
			const target = event.target as HTMLElement;

			if (target.classList.contains('detail-panel')) {
				closeCallback(event);
				event.stopPropagation();
			}
		},
		[
			closeCallback,
		],
	);

	const componentClasses = classNames({
		'detail-panel': true,
		'detail-panel--is-fullwidth': isFullwidth,
	});

	return (
		<div
			className={componentClasses}
			onClick={handleCloseAreaOnClick}
		>
			<div className="detail-panel__content">
				<div
					className="detail-panel__close-btn"
					onClick={closeCallback}
				>
					<span>&times;</span>
				</div>
				<div className="detail-panel__body">
					{children}
				</div>
			</div>
		</div>
	);
};



export default DetailPanel;
