import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const IndexabilityIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Group"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="Page-1"
					transform="translate(5.000000, 5.000000)"
				>
					<path
						d="M31.8178125,11.9303125 C37.31,17.4225 37.31,26.325625 31.8178125,31.8178125 C26.325625,37.31 17.4225,37.31 11.9303125,31.8178125 C6.438125,26.325625 6.438125,17.4225 11.9303125,11.9303125 C17.4225,6.438125 26.325625,6.438125 31.8178125,11.9303125 L31.8178125,11.9303125 Z"
						id="Stroke-3"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M34.028125,29.60875 L41.7625,37.343125 C42.9828125,38.5634375 42.9828125,40.543125 41.7625,41.7634375 L41.7625,41.7634375 C40.5421875,42.98375 38.5640625,42.98375 37.34375,41.7634375 L29.609375,34.0275"
						id="Stroke-5"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default IndexabilityIconBuilder;

