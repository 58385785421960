import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AccountDisplayName from '~/components/app/AccountDisplayName';
import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import Emphasis from '~/components/patterns/typography/Emphasis';
import FlexibleBlocks from '~/components/patterns/structures/FlexibleBlocks';
import GroupBox, {
	GroupBoxSize,
} from '~/components/patterns/boxes/GroupBox';
import MarginsList from '~/components/atoms/lists/MarginsList';
import MultiWebsitesDashboardWebsiteCard from '~/components/app/MultiWebsitesDashboardWebsiteCard';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';

import {
	useMultiWebsitesDashboardQuery,
} from './MultiWebsitesDashboard.gql';

import useHomeAccountIds from '~/hooks/useHomeAccountIds';
import useViewportType from '~/hooks/useViewportType';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import sortArrayByProperty from '~/utilities/sortArrayByProperty';



const messages = defineMessages({
	noAccountWebsite: {
		id: 'ui.dashboard.noAccountWebsite.message',
	},
});



const MultiWebsitesDashboard: React.FC = () => {
	const homeAccountIds = useHomeAccountIds();
	const viewportType = useViewportType();

	const { data } = useMultiWebsitesDashboardQuery();

	const accounts = data?.authenticatedSession?.accounts ?? [];

	if (homeAccountIds === null) {
		return null;
	}

	const websiteGroups: Array<{
		accountId: CK.AccountId,
		websiteCards: ReadonlyArray<React.ReactNode>,
	}> = [];

	accounts.forEach((account) => {
		let websiteCards: Array<React.ReactNode> = [];

		if (account.websites.length > 0) {
			const websites = sortArrayByProperty(account.websites, (website) => website.displayName);

			websiteCards = websites.map((website) => (
				<MultiWebsitesDashboardWebsiteCard
					accountId={account.id}
					key={website.id}
					website={website}
				/>
			));
		}

		websiteGroups.push({
			accountId: account.id,
			websiteCards,
		});
	});

	const websiteGroupsCards = websiteGroups.map(({ websiteCards }, i) => {
		if (websiteCards.length === 0) {
			return (
				<Spacer
					key={i}
					top={SpacerGap.Small}
				>
					<Emphasis>
						<FormattedMessage {...messages.noAccountWebsite} />
					</Emphasis>
				</Spacer>
			);
		}

		return (
			<FlexibleBlocks
				blockMinWidth={!viewportType.isSmall ? 300 : undefined}
				key={i}
			>
				{websiteCards}
			</FlexibleBlocks>
		);
	});

	if (websiteGroupsCards.length === 0) {
		return null;
	}

	if (websiteGroupsCards.length === 1) {
		return getArrayItemAtSafeIndex(websiteGroupsCards, 0);
	}

	return (
		<MarginsList>
			{websiteGroupsCards.map((websiteGroupCard, index) => {
				const { accountId } = getArrayItemAtSafeIndex(websiteGroups, index);

				return (
					<GroupBox
						defaultOpen={homeAccountIds.includes(accountId)}
						isCollapsible={true}
						key={accountId}
						size={GroupBoxSize.Large}
						title={(
							<AccountDisplayName accountId={accountId} />
						)}
						titleIcon={(
							<AttributesGroupIcon
								size={30}
								type={AttributesGroupIconType.Account}
							/>
						)}
					>
						{websiteGroupCard}
					</GroupBox>
				);
			})}
		</MarginsList>
	);
};



export default MultiWebsitesDashboard;
