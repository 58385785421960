import Immutable, {
	List,
} from 'immutable';



function normalizeValue(value) {
	if (value instanceof List) {
		return value.sort();
	} else if (value instanceof Array) {
		return List(value);
	}

	return value;
}



export default function removeDefaultValues(currentValues, defaultValues) {
	return currentValues.filter((currentValue, key) => {
		if (defaultValues.has !== undefined) {
			if (defaultValues.has(key)) {
				return !Immutable.is(
					normalizeValue(defaultValues.get(key)),
					normalizeValue(currentValue),
				);
			}
		} else {
			if (key in defaultValues) {
				return !Immutable.is(
					normalizeValue(defaultValues[key]),
					normalizeValue(currentValue),
				);
			}
		}

		return currentValue !== null;
	});
}
