import React from 'react';



function chooseValue(propValue, contextValue, defaultValue) {
	if (propValue !== undefined) {
		return propValue;
	}

	if (contextValue !== undefined) {
		return contextValue;
	}

	return defaultValue;
}



function useContextProps(context, directProps, defaultProps) {
	const contextProps = React.useContext(context) || {};

	const result = {};

	for (const prop in defaultProps) {
		result[prop] = chooseValue(directProps[prop], contextProps[prop], defaultProps[prop]);
	}

	return result;
}



export default useContextProps;
