import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class BarChartLabels extends Component {

	_renderLabels() {
		const {
			categories,
			labelRenderer,
		} = this.props;

		return categories.map((category, index) => {
			return (
				<div
					className="bar-chart-labels__label"
					key={'stacked-bar-chart-label-' + index}
				>
					{labelRenderer ? labelRenderer(category, index) : category}
				</div>
			);
		});
	}



	render() {
		const {
			children,
			isXAxisVisible,
		} = this.props;

		const componentClasses = classNames({
			'bar-chart-labels': true,
			'bar-chart-labels--visible-xaxis': isXAxisVisible,
		});

		return (
			<div className={componentClasses}>
				<div className="bar-chart-labels__labels">
					{this._renderLabels()}
				</div>
				<div className="bar-chart-labels__chart">
					{children}
				</div>
			</div>
		);
	}

}

BarChartLabels.propTypes = {
	/** Data categories (important info for grouping of data and rendering of labels) */
	categories: PropTypes.array.isRequired,
	/** When x-axis is visible, we will reserve space in the layout */
	isXAxisVisible: PropTypes.bool,
	/* Custom rendered of labels */
	labelRenderer: PropTypes.func,
};



export default BarChartLabels;
