import classNames from 'classnames';
import React from 'react';



export enum FontSize {
	Normal = 'normal',
	Small = 'small',
}

type Props = {
	children: React.ReactNode,
	/** Defined font size */
	size?: FontSize,
};



const Font: React.FC<Props> = (props) => {
	const {
		children,
		size = FontSize.Normal,
	} = props;

	const componentClasses = classNames({
		'font': true,
		['font--' + size + '-size']: size,
	});

	return (
		<span className={componentClasses}>
			{children}
		</span>
	);
};



export default Font;
