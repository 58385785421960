import React from 'react';



type Props = {
	children?: React.ReactNode,
	icon?: React.ReactNode,
	onClickCallback: () => void,
};



const ContextMenuItem: React.FC<Props> = (props) => {
	const {
		children,
		icon,
		onClickCallback,
	} = props;

	const itemClickHandler = (event) => {
		event.preventDefault();

		onClickCallback();
	};

	return (
		<button
			className="context-menu-item"
			onClick={itemClickHandler}
			type="button"
		>
			{icon && (
				<span className="context-menu-item__icon">
					{icon}
				</span>
			)}
			<span className="context-menu-item__label">
				{children}
			</span>
		</button>
	);
};



export default ContextMenuItem;
