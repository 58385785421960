import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import GoogleApi from '~/components/logic/GoogleApi';
import GoogleDataStudioRow from '~/components/logic/websiteIntegrations/GoogleDataStudioRow';
import GoogleSearchConsoleRow from '~/components/logic/websiteIntegrations/GoogleSearchConsoleRow';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WithPermission from '~/components/logic/access/WithPermission';
import WordpressPluginRow from '~/components/logic/websiteIntegrations/WordpressPluginRow';

import useUpdateWebsiteIntegrations from '~/hooks/useUpdateWebsiteIntegrations';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	gdsIntegrationTitle: {
		id: 'ui.websites.form.api.gds.title',
	},
	gscIntegrationTitle: {
		id: 'ui.websites.form.api.gsc.title',
	},
	integrationsTitle: {
		id: 'ui.websites.form.integrationsTitle',
	},
});

const pluginStatusMessages = defineMessages({
	[GraphQL.WebsiteIntegrationStatus.Available]: {
		id: 'ui.websites.form.api.status.available',
	},
	[GraphQL.WebsiteIntegrationStatus.Disabled]: {
		id: 'ui.websites.form.api.status.disabled',
	},
	[GraphQL.WebsiteIntegrationStatus.Enabled]: {
		id: 'ui.websites.form.api.status.enabled',
	},
	[GraphQL.WebsiteIntegrationStatus.FeatureNotEnabled]: {
		id: 'ui.websites.form.api.status.available',
	},
});



const WebsiteSettingsIntegrationsForm: React.FC = () => {
	const websiteId = useWebsiteId();

	const updateWebsiteIntegrations = useUpdateWebsiteIntegrations();
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const gscIntegrationStatus = websiteIntegrations.getStatus(GraphQL.WebsiteIntegrationType.GoogleSearchConsole);
	const gdsIntegrationStatus = websiteIntegrations.getStatus(GraphQL.WebsiteIntegrationType.GoogleDataStudio);

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteIntegrations({
				variables: {
					enableGoogleSearchConsole: values.gsc_integration_status ?? null,
					websiteId,
				},
			});
		},
		[
			updateWebsiteIntegrations,
			websiteId,
		],
	);

	function renderOverview() {
		if (
			gdsIntegrationStatus === null
			|| gscIntegrationStatus === null
		) {
			return null;
		}

		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.gscIntegrationTitle} />
					)}
				>
					<StaticText>
						<FormattedMessage {...pluginStatusMessages[gscIntegrationStatus]} />
					</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.gdsIntegrationTitle} />
					)}
				>
					<StaticText>
						<FormattedMessage {...pluginStatusMessages[gdsIntegrationStatus]} />
					</StaticText>
				</FormRow>

				<WordpressPluginRow
					showAvailabilityDetails={false}
				/>
			</FormRows>
		);
	}

	function renderForm() {
		if (gscIntegrationStatus === null) {
			return null;
		}

		let isGscIntegrationOwned;

		const defaultValues: Record<string, any> = {};

		if (gscIntegrationStatus !== GraphQL.WebsiteIntegrationStatus.Available) {
			isGscIntegrationOwned = websiteIntegrations.isNotOwned(GraphQL.WebsiteIntegrationType.GoogleSearchConsole) === false;

			defaultValues.gsc_integration_status = gscIntegrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled;
		}

		return (
			<Form
				defaultValues={defaultValues}
				onSuccess={handleSubmit}
			>
				<FormRows>
					<GoogleSearchConsoleRow
						isGscIntegrationOwned={isGscIntegrationOwned}
						name="gsc_integration_status"
					/>

					<GoogleDataStudioRow />

					<WordpressPluginRow
						showAvailabilityDetails={true}
					/>
				</FormRows>

				<ButtonsLayout>
					<CancelButton />

					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		);
	}

	return (
		<GoogleApi>
			<WithPermission
				action={GraphQL.ActionWithWebsite.ManageSettings}
				objectId={websiteId}
				objectType={ObjectType.Website}
				showMessage={false}
			>
				{({ isAllowed }) => (
					<EditableFormWrapper
						isAllowed={isAllowed}
						key={websiteId}
						title={(
							<FormattedMessage {...messages.integrationsTitle} />
						)}
					>
						<DisplayPart>
							{renderOverview()}
						</DisplayPart>

						<EditablePart>
							{renderForm()}
						</EditablePart>
					</EditableFormWrapper>
				)}
			</WithPermission>
		</GoogleApi>
	);
};



export default WebsiteSettingsIntegrationsForm;
