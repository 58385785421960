/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MultiWebsitesDashboardQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type MultiWebsitesDashboardQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly displayName: string, readonly id: CK.WebsiteId, readonly pageCapacity: number, readonly pageCapacityUsageDescription: GraphQL.WebsitePageCapacityUsageDescription, readonly pageCapacityUsageRatio: number, readonly pagesCount: number, readonly problem: GraphQL.WebsiteProblem | null }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } } | null };


export const MultiWebsitesDashboardDocument = gql`
    query MultiWebsitesDashboard {
  authenticatedSession {
    accounts {
      displayName
      id
      websites {
        displayName
        id
        pageCapacity
        pageCapacityUsageDescription
        pageCapacityUsageRatio
        pagesCount
        problem
      }
    }
    memberships {
      account {
        id
      }
      user {
        id
        legacyId
      }
    }
    user {
      id
      legacyId
    }
  }
}
    `;

/**
 * __useMultiWebsitesDashboardQuery__
 *
 * To run a query within a React component, call `useMultiWebsitesDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiWebsitesDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiWebsitesDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useMultiWebsitesDashboardQuery(baseOptions?: Apollo.QueryHookOptions<MultiWebsitesDashboardQuery, MultiWebsitesDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MultiWebsitesDashboardQuery, MultiWebsitesDashboardQueryVariables>(MultiWebsitesDashboardDocument, options);
      }
export function useMultiWebsitesDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MultiWebsitesDashboardQuery, MultiWebsitesDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MultiWebsitesDashboardQuery, MultiWebsitesDashboardQueryVariables>(MultiWebsitesDashboardDocument, options);
        }
export function useMultiWebsitesDashboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MultiWebsitesDashboardQuery, MultiWebsitesDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MultiWebsitesDashboardQuery, MultiWebsitesDashboardQueryVariables>(MultiWebsitesDashboardDocument, options);
        }
export type MultiWebsitesDashboardQueryHookResult = ReturnType<typeof useMultiWebsitesDashboardQuery>;
export type MultiWebsitesDashboardLazyQueryHookResult = ReturnType<typeof useMultiWebsitesDashboardLazyQuery>;
export type MultiWebsitesDashboardSuspenseQueryHookResult = ReturnType<typeof useMultiWebsitesDashboardSuspenseQuery>;
export type MultiWebsitesDashboardQueryResult = Apollo.QueryResult<MultiWebsitesDashboardQuery, MultiWebsitesDashboardQueryVariables>;