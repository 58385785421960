import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Divider between children */
	divider?: React.ReactNode,
	/** Prefix before whole group of children */
	prefix?: React.ReactNode,
	/** Suffix after whole group of children */
	suffix?: React.ReactNode,
};



const FormFieldsDecorator: React.FC<Props> = (props) => {
	const {
		divider,
		children,
		prefix,
		suffix,
	} = props;

	const elements = React.Children.toArray(children).filter((child) => !!child);

	return (
		<div className="form-fields-decorator">
			{prefix && (
				<div className="form-fields-decorator__prefix">
					{prefix}
				</div>
			)}
			{elements.map((child, index) => {
				return (
					<React.Fragment key={'chunk-' + index}>
						{divider && index > 0 && (
							<div className="form-fields-decorator__divider">
								{divider}
							</div>
						)}
						<div className="form-fields-decorator__chunk">
							{child}
						</div>
					</React.Fragment>
				);
			})}
			{suffix && (
				<div className="form-fields-decorator__suffix">
					{suffix}
				</div>
			)}
		</div>
	);
};



export default FormFieldsDecorator;
