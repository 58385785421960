import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RecheckIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-recheck">
					<g id="recheck">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<path
							d="M18.3720714,8.76985714 C17.1927857,6.44771429 14.7820714,4.857 11.9999286,4.857 C8.05492857,4.857 4.85707143,8.05485714 4.85707143,11.9998571 C4.85707143,15.9448571 8.05492857,19.1427143 11.9999286,19.1427143 C15.9442143,19.1427143 19.1427857,15.9448571 19.1427857,11.9998571"
							id="Stroke-3"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<polyline
							id="Stroke-5"
							points="18.6597143 4.49385714 18.3718571 8.76957143 14.0961429 8.48242857"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</polyline>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default RecheckIconBuilder;

