import type CK from '~/types/contentking';

import useAccountDisplayName from '~/hooks/useAccountDisplayName';
import useWebsiteAccountId from '~/hooks/useWebsiteAccountId';



function useWebsiteAccountName(websiteId: CK.WebsiteId | null): string | null {
	const websiteAccountId = useWebsiteAccountId(websiteId);

	return useAccountDisplayName(websiteAccountId);
}



export default useWebsiteAccountName;
