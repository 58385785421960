import {
	getRecurly,
} from '~/recurly/main';

import {
	get,
} from '~/model/api/API';

import {
	RECURLY_PUBLIC_KEY,
} from '~/config';



export function loadTaxDetails(country, vatNumber) {
	return new Promise((resolve) => {
		getRecurly((recurly) => {
			recurly.configure(RECURLY_PUBLIC_KEY);

			let pricing = recurly.Pricing();
			pricing = pricing.plan('v3-basic-monthly');
			pricing = pricing.address({
				country,
				vat_number: vatNumber,
			});
			pricing = pricing.tax({
				tax_code: 'digital',
				vat_number: vatNumber,
			});
			pricing.done((price) => {
				resolve({
					is_applied: parseFloat(price.now.tax) > 0,
					rate: price.taxes[0] ? parseFloat(price.taxes[0].rate) : 0.0,
				});
			});
		});
	});
}



export function loadDiscountDetailsViaRecurly(accountId) {
	return new Promise((resolve) => {
		get(
			'/discounts',
			{
				accountId,
			},
		).then(({ discounts }) => {
			const result = [];

			discounts.forEach(({ amount, code, percentageRate, type }) => {
				result.push({
					amount,
					code,
					rate: percentageRate,
					type,
				});
			});

			resolve(result);
		}).catch(() => {
			resolve([]);
		});
	});
}
