import {
	endOfDay,
	isBefore,
	min,
	startOfDay,
	subDays,
} from 'date-fns';
import random from 'lodash/random';
import times from 'lodash/times';
import React from 'react';
import {
	FormattedMessage,
	type IntlShape,
	defineMessages,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';

import AttachedChartLegend, {
	AttachedChartLegendAlignment,
} from '~/components/patterns/charts/structures/AttachedChartLegend';
import ChartContainer from '~/components/atoms/charts/components/ChartContainer';
import ChartLayout from '~/components/patterns/charts/layouts/ChartLayout';
import ChartLegend from '~/components/patterns/charts/components/ChartLegend';
import ChartOverlay from '~/components/patterns/charts/components/ChartOverlay';
import DateTimeChart from '~/components/atoms/charts/charts/DateTimeChart';
import SwitchButtons, {
	SwitchButtonsSize,
} from '~/components/patterns/buttons/SwitchButtons';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';

import {
	notEmpty,
} from '~/utilities/typeCheck';



enum Period {
	Month,
	Quarter,
	Year,
}

enum SearchEngine {
	GoogleDesktop,
	GoogleMobile,
	BingDesktop,
	BingMobile,
}



const messages = defineMessages({
	bingDesktop: {
		id: 'ui.pageDetail.searchEngineVisits.bingDesktop',
	},
	bingMobile: {
		id: 'ui.pageDetail.searchEngineVisits.bingMobile',
	},
	disabledPeriod: {
		id: 'ui.pageDetail.searchEngineVisits.disabledPeriod',
	},
	googleDesktop: {
		id: 'ui.pageDetail.searchEngineVisits.googleDesktop',
	},
	googleMobile: {
		id: 'ui.pageDetail.searchEngineVisits.googleMobile',
	},
	noData: {
		id: 'ui.pageDetail.searchEngineVisits.noData',
	},
	visitsPerDay: {
		id: 'ui.pageDetail.searchEngineVisits.title',
	},
	[Period.Month]: {
		id: 'ui.pageDetail.searchEngineVisits.period.month',
	},
	[Period.Quarter]: {
		id: 'ui.pageDetail.searchEngineVisits.period.quarter',
	},
	[Period.Year]: {
		id: 'ui.pageDetail.searchEngineVisits.period.year',
	},
});



function getSearchEngines(intl: IntlShape) {
	return [
		{
			color: '#FE8719',
			name: intl.formatMessage(messages.googleDesktop),
			searchEngine: SearchEngine.GoogleDesktop,
		},
		{
			color: '#C1620A',
			name: intl.formatMessage(messages.googleMobile),
			searchEngine: SearchEngine.GoogleMobile,
		},
		{
			color: '#72C035',
			name: intl.formatMessage(messages.bingDesktop),
			searchEngine: SearchEngine.BingDesktop,
		},
		{
			color: '#028739',
			name: intl.formatMessage(messages.bingMobile),
			searchEngine: SearchEngine.BingMobile,
		},
	];
}



function generateFakeSeries(intl: IntlShape): Array<Highcharts.SeriesLineOptions> {
	function fakeData() {
		const beginning = subDays(new Date(), 30).getTime();
		const day = 24 * 60 * 60 * 1000;

		return times(11, (i) => ([
			beginning + (day * 3 * i),
			random(1.6, 3.5, true) ** 1.8 - 2,
		]));
	}

	const series: Array<Highcharts.SeriesLineOptions> = getSearchEngines(intl).map((searchEngine) => ({
		color: searchEngine.color,
		name: searchEngine.name,
		opacity: 0.2,
		data: fakeData(),
		type: 'line',
	}));

	return series;
}



type VisitsPerDay = ReadonlyArray<{
	date: CK.DateYMD,
	isDateComplete?: boolean,
	numberOfVisits: number,
}>;

type Props = {
	showNoDataMessage?: boolean,
	visitsPerDayBingDesktop: VisitsPerDay | null,
	visitsPerDayBingMobile: VisitsPerDay | null,
	visitsPerDayGoogleDesktop: VisitsPerDay | null,
	visitsPerDayGoogleMobile: VisitsPerDay | null,
};

const SearchEngineVisitsPerDayChart: React.FC<Props> = (props) => {
	const {
		showNoDataMessage = true,
		visitsPerDayBingDesktop,
		visitsPerDayBingMobile,
		visitsPerDayGoogleDesktop,
		visitsPerDayGoogleMobile,
	} = props;

	const intl = useIntl();
	const [period, setPeriod] = React.useState<Period>(Period.Month);

	const hasData = (
		(visitsPerDayBingDesktop !== null && visitsPerDayBingDesktop.length > 0)
		|| (visitsPerDayBingMobile !== null && visitsPerDayBingMobile.length > 0)
		|| (visitsPerDayGoogleDesktop !== null && visitsPerDayGoogleDesktop.length > 0)
		|| (visitsPerDayGoogleMobile !== null && visitsPerDayGoogleMobile.length > 0)
	);

	const periods = React.useMemo(
		() => {
			const now = new Date();

			const periods = {
				[Period.Month]: {
					start: subDays(startOfDay(now), 30).getTime(),
					end: endOfDay(now).getTime(),
					isAvailable: true,
				},
				[Period.Quarter]: {
					start: subDays(startOfDay(now), 90).getTime(),
					end: endOfDay(now).getTime(),
					isAvailable: false,
				},
				[Period.Year]: {
					start: subDays(startOfDay(now), 365).getTime(),
					end: endOfDay(now).getTime(),
					isAvailable: false,
				},
			};

			const firstVisits = [
				visitsPerDayBingDesktop,
				visitsPerDayBingMobile,
				visitsPerDayGoogleDesktop,
				visitsPerDayGoogleMobile,
			].map((searchEngineVisits) => {
				if (searchEngineVisits === null) {
					return null;
				}

				return new Date(
					getArrayItemAtSafeIndex(searchEngineVisits, 0).date,
				);
			}).filter(notEmpty);

			if (firstVisits.length > 0) {
				const firstVisit = min(firstVisits);

				periods[Period.Quarter].isAvailable = isBefore(firstVisit, periods[Period.Month].start);
				periods[Period.Year].isAvailable = isBefore(firstVisit, periods[Period.Quarter].start);
			}

			return periods;
		},
		[
			visitsPerDayBingDesktop,
			visitsPerDayBingMobile,
			visitsPerDayGoogleDesktop,
			visitsPerDayGoogleMobile,
		],
	);

	const [valueMinimum, valueMaximum] = React.useMemo(
		() => {
			const highestNumberOfVisits = Math.max(
				...(visitsPerDayBingDesktop?.map((visitsPerDay) => visitsPerDay.numberOfVisits) ?? []),
				...(visitsPerDayBingMobile?.map((visitsPerDay) => visitsPerDay.numberOfVisits) ?? []),
				...(visitsPerDayGoogleDesktop?.map((visitsPerDay) => visitsPerDay.numberOfVisits) ?? []),
				...(visitsPerDayGoogleMobile?.map((visitsPerDay) => visitsPerDay.numberOfVisits) ?? []),
				0,
			);

			return [
				0,
				Math.max(highestNumberOfVisits, 10),
			];
		},
		[
			visitsPerDayBingDesktop,
			visitsPerDayBingMobile,
			visitsPerDayGoogleDesktop,
			visitsPerDayGoogleMobile,
		],
	);

	const series = React.useMemo(
		() => {
			if (!hasData) {
				return generateFakeSeries(intl);
			}

			const visitsPerSearchEngine = {
				[SearchEngine.GoogleDesktop]: visitsPerDayGoogleDesktop,
				[SearchEngine.GoogleMobile]: visitsPerDayGoogleMobile,
				[SearchEngine.BingDesktop]: visitsPerDayBingDesktop,
				[SearchEngine.BingMobile]: visitsPerDayBingMobile,
			};

			const series: Array<Highcharts.SeriesLineOptions> = getSearchEngines(intl).map((searchEngine) => {
				const visitsPerDay = visitsPerSearchEngine[searchEngine.searchEngine];

				const data = visitsPerDay?.map((visitPerDay) => ([
					new Date(visitPerDay.date).getTime(),
					visitPerDay.numberOfVisits,
				])) ?? [];

				const zones = visitsPerDay?.reduce((zones, visitPerDay) => {
					const dashStyle = visitPerDay.isDateComplete === false ? 'ShortDash' : 'Solid';
					const lastZone = zones.at(-1);

					if (dashStyle === lastZone?.dashStyle) {
						lastZone.value = startOfDay(new Date(visitPerDay.date)).getTime();
					} else {
						zones.push({
							value: endOfDay(new Date(visitPerDay.date)).getTime(),
							dashStyle,
						});
					}

					return zones;
				}, [] as Array<Highcharts.SeriesZonesOptionsObject>);

				return {
					color: searchEngine.color,
					opacity: 1,
					data,
					name: searchEngine.name,
					type: 'line',
					zoneAxis: 'x',
					zones,
				};
			});

			return series;
		},
		[
			hasData,
			intl,
			visitsPerDayBingDesktop,
			visitsPerDayBingMobile,
			visitsPerDayGoogleDesktop,
			visitsPerDayGoogleMobile,
		],
	);

	return (
		<ChartLayout
			hasBorders={false}
			headerElements={(
				<SwitchButtons
					buttons={[
						{
							disabledTooltip: <FormattedMessage {...messages.disabledPeriod} />,
							isActive: period === Period.Month,
							isDisabled: !periods[Period.Month].isAvailable,
							label: <FormattedMessage {...messages[Period.Month]} />,
							onClickCallback: () => setPeriod(Period.Month),
						},
						{
							disabledTooltip: <FormattedMessage {...messages.disabledPeriod} />,
							isActive: period === Period.Quarter,
							isDisabled: !periods[Period.Quarter].isAvailable,
							label: <FormattedMessage {...messages[Period.Quarter]} />,
							onClickCallback: () => setPeriod(Period.Quarter),
						},
						{
							disabledTooltip: <FormattedMessage {...messages.disabledPeriod} />,
							isActive: period === Period.Year,
							isDisabled: !periods[Period.Year].isAvailable,
							label: <FormattedMessage {...messages[Period.Year]} />,
							onClickCallback: () => setPeriod(Period.Year),
						},
					]}
					fullwidth={false}
					size={SwitchButtonsSize.XSmall}
					uppercase={true}
				/>
			)}
			headerElementsMaxWidth={210}
			headerWrapping={true}
			label={(
				<FormattedMessage {...messages.visitsPerDay} />
			)}
		>
			<AttachedChartLegend
				legend={(
					<ChartLegend
						inline={true}
						items={series.map((serie) => ({
							label: serie.name ?? '',
							color: (serie.color as string),
						}))}
					/>
				)}
				legendAlignment={AttachedChartLegendAlignment.Bottom}
				stretched={true}
			>
				<ChartContainer
					chart={(
						<DateTimeChart
							chartHeight={140}
							dateMaximum={periods[period].end}
							dateMinimum={periods[period].start}
							series={series}
							valueMaximum={valueMaximum}
							valueMinimum={valueMinimum}
						/>
					)}
					overlay={!hasData && showNoDataMessage && (
						<ChartOverlay
							text={(
								<FormattedMessage {...messages.noData} />
							)}
						/>
					)}
					type="date-time-chart"
				/>
			</AttachedChartLegend>
		</ChartLayout>
	);
};

export default SearchEngineVisitsPerDayChart;
