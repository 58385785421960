import React from 'react';

import FormsList from '~/components/atoms/lists/FormsList';
import OrganizationAccessForm from '~/components/app/OrganizationAccessForm';



const OrganizationAccessGroup: React.FC = () => {
	return (
		<FormsList>
			<OrganizationAccessForm />
		</FormsList>
	);
};



export default OrganizationAccessGroup;
