import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
	SnapshotIconVariants,
} from '~/components/patterns/icons/BasicIcon';
import Copy, {
	showIntercom,
} from '~/components/logic/Copy';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';



const messages = defineMessages({
	labelActive: {
		id: 'ui.snapshotViewer.viewSnapshotLink.label',
	},
	labelDisabled: {
		id: 'ui.snapshotViewer.viewSnapshotLink.disabledLabel',
	},
});

const tooltipMessages = defineMessages({
	[GraphQL.PageHistoricalMomentSnapshotStatus.FeatureNotAvailable]: {
		id: 'ui.snapshotViewer.viewSnapshotLink.tooltip.featureNotAvailable',
	},
	[GraphQL.PageHistoricalMomentSnapshotStatus.FeatureNotEnabled]: {
		id: 'ui.snapshotViewer.viewSnapshotLink.tooltip.featureNotEnabled',
	},
	[GraphQL.PageHistoricalMomentSnapshotStatus.NotApplicable]: {
		id: 'ui.snapshotViewer.viewSnapshotLink.tooltip.notApplicable',
	},
	[GraphQL.PageHistoricalMomentSnapshotStatus.NotWithinDataRetention]: {
		id: 'ui.snapshotViewer.viewSnapshotLink.tooltip.notWithinDataRetention',
	},
});



type Props = {
	snapshotStatus: GraphQL.PageHistoricalMomentSnapshotStatus,
	timestamp: Date,
	urlId: number,
	websiteId: CK.WebsiteId,
};

const ViewSnapshotLink: React.FC<Props> = (props) => {
	const {
		snapshotStatus,
		timestamp,
		urlId,
		websiteId,
	} = props;

	let link;

	if (snapshotStatus !== GraphQL.PageHistoricalMomentSnapshotStatus.Available) {
		link = (
			<AttachedIcon
				ellipsis={false}
				icon={(
					<BasicIcon
						size={23}
						type={BasicIconType.Snapshot}
						variant={SnapshotIconVariants.NotAvailable}
					/>
				)}
			>
				<FormattedMessage {...messages.labelDisabled} />
			</AttachedIcon>
		);
	} else {
		link = (
			<InternalLink
				icon={(
					<BasicIcon
						size={22}
						type={BasicIconType.Snapshot}
					/>
				)}
				routeName="website.pages.detail.history.snapshot"
				routeParams={{
					id: urlId,
					timestamp: timestamp.getTime() / 1000,
					websiteId,
				}}
				style={InternalLinkStyle.Decent}
			>
				<FormattedMessage {...messages.labelActive} />
			</InternalLink>
		);
	}

	if (snapshotStatus !== GraphQL.PageHistoricalMomentSnapshotStatus.Available) {
		link = (
			<Hint
				attachment={HintAttachment.Center}
				inline={false}
				popup={(
					<Copy
						{...tooltipMessages[snapshotStatus]}
						values={{
							showIntercom,
						}}
					/>
				)}
			>
				{link}
			</Hint>
		);
	}

	return link;
};



export default ViewSnapshotLink;
