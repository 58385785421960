import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import AccountDisplayName from '~/components/app/AccountDisplayName';
import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import HelpHint from '~/components/patterns/hints/HelpHint';
import Muted from '~/components/patterns/typography/Muted';
import NewDatatable, {
	type NewDatatableColumnDefinitions,
} from '~/components/app/NewDatatable';
import UserRoleAndAccess from '~/components/app/UserRoleAndAccess';

import useDisabledMemberships from '~/hooks/useDisabledMemberships';
import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	columnAccount: {
		id: 'ui.userProfile.membershipsColumnAccount',
	},
	columnRole: {
		id: 'ui.userProfile.membershipsColumnRole',
	},
	title: {
		id: 'ui.accountAccess.contextSelect.yourAccountLabel',
	},
});



type Props = {
	memberships: ReadonlyArray<{
		account: {
			id: CK.AccountId,
		},
		hasLimitedWebsitesAccess: boolean,
		role: GraphQL.UserRole,
		websites: ReadonlyArray<{
			id: CK.WebsiteId,
		}>,
	}>,
};

const CurrentUserMembershipsOverview: React.FC<Props> = (props) => {
	const {
		memberships,
	} = props;

	const disabledMemberships = useDisabledMemberships();
	const viewportType = useViewportType();

	const rows = React.useMemo(
		() => {
			return [
				...memberships.map(
					(membership) => ({
						accountDisplayName: (
							<Ellipsis>
								<AccountDisplayName accountId={membership.account.id} />
							</Ellipsis>
						),
						userRoleAndAccess: (
							<UserRoleAndAccess userAccountMembership={membership} />
						),
					}),
				),
				...(disabledMemberships ?? []).map(
					(membershipLabel) => ({
						accountDisplayName: (
							<AttachedElement
								element={(
									<HelpHint message={membershipLabel.explanation} />
								)}
								inline={true}
							>
								<Muted>
									<Ellipsis>
										{membershipLabel.accountDisplayName}
									</Ellipsis>
								</Muted>
							</AttachedElement>
						),
						userRoleAndAccess: (
							<Muted>
								<UserRoleAndAccess
									userAccountMembership={{
										hasLimitedWebsitesAccess: membershipLabel.numberOfWebsitesInLimitedWebsitesAccess !== null,
										role: membershipLabel.role,
										websites: membershipLabel.numberOfWebsitesInLimitedWebsitesAccess !== null
											? Array.from({ length: membershipLabel.numberOfWebsitesInLimitedWebsitesAccess })
											: [],
									}}
								/>
							</Muted>
						),
					}),
				),
			];
		},
		[
			disabledMemberships,
			memberships,
		],
	);

	const columns: NewDatatableColumnDefinitions<typeof rows> = React.useMemo(
		() => {
			return [
				{
					name: 'account',
					render: {
						cell: ({ row }) => row.accountDisplayName,
						filter: () => null,
						label: () => (
							<FormattedMessage {...messages.columnAccount} />
						),
					},
					width: '45%',
				},
				{
					name: 'role',
					render: {
						cell: ({ row }) => (
							<Ellipsis>
								{row.userRoleAndAccess}
							</Ellipsis>
						),
						filter: () => null,
						label: () => (
							<FormattedMessage {...messages.columnRole} />
						),
					},
					width: 'fill',
				},
			];
		},
		[],
	);

	return (
		<div style={{ width: viewportType.isSmall ? '100%' : 500 }}>
			<NewDatatable
				columns={columns}
				height={50 + 44 * rows.length}
				rows={rows}
				title={(
					<FormattedMessage
						{...messages.title}
						values={{
							numberOfAccounts: rows.length,
						}}
					/>
				)}
			/>
		</div>
	);
};



export default CurrentUserMembershipsOverview;
