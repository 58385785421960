import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';



type Props = {
	children: React.ReactNode,
};



const IgnoringMessage: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="ignoring-message">
			<div className="ignoring-message__container">
				<div className="ignoring-message__icon">
					<BasicIcon
						size={22}
						type={BasicIconType.Block}
					/>
				</div>
				<div className="ignoring-message__message">
					{children}
				</div>
			</div>
		</div>
	);
};



export default IgnoringMessage;
