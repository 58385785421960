import React from 'react';
import {
	useSelector,
} from 'react-redux';

import ActiveFilter from '~/components/logic/filters/ActiveFilter';

import useUpdateTrackedChangesFilter from '~/hooks/useUpdateTrackedChangesFilter';

import {
	removeDefaultFilterValues as removeHistoricalDefaultFilterValues,
} from '~/model/historicalChanges';

import {
	filterSelector as historicalFilterSelector,
} from '~/state/historicalChanges/selectors';



type Props = {
	onFilterClickCallback: (column: string) => void,
};

const TrackedChangesActiveFilterBar: React.FC<Props> = (props) => {
	const {
		onFilterClickCallback,
	} = props;

	const filter = useSelector(historicalFilterSelector);
	const updateTrackedChangesFilter = useUpdateTrackedChangesFilter();

	const handleRemoveFilter = React.useCallback(
		(filterId) => {
			if (filterId === 'change_type') {
				updateTrackedChangesFilter({
					changes_in: undefined,
					changes_in_columns: undefined,
					change_type: undefined,
				});
			} else if (filterId.indexOf('selected-') === 0) {
				const changesInColumns = filter.get('changes_in_columns');
				const columnIndex = changesInColumns.indexOf(filterId.substr('selected-'.length));

				updateTrackedChangesFilter({
					changes_in_columns: changesInColumns.delete(columnIndex),
				});
			} else {
				updateTrackedChangesFilter({
					[filterId]: undefined,
				});
			}
		},
		[
			filter,
			updateTrackedChangesFilter,
		],
	);

	let activeFilters = removeHistoricalDefaultFilterValues(filter);

	const selectedColumns = activeFilters.get('changes_in_columns')
		? activeFilters.get('changes_in_columns')
		: null;

	if (activeFilters.has('changes_in')) {
		activeFilters = activeFilters.set('change_type', filter.get('change_type'));

		activeFilters = activeFilters.remove('changes_in');
		activeFilters = activeFilters.remove('changes_in_columns');
	}

	return (
		<ActiveFilter
			activeFilters={activeFilters}
			clearLink={false}
			filter={filter}
			onFilterClickCallback={onFilterClickCallback}
			removeFilterCallback={handleRemoveFilter}
			segmentsManagement={false}
			selectedColumns={selectedColumns}
		/>
	);
};



export default TrackedChangesActiveFilterBar;
