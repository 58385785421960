import classNames from 'classnames';
import React from 'react';

import styles from './LineBreaks.module.scss';



type Props = {
	children: React.ReactNode,
	/** Possibility to disable usage of hyphens when breaking words. It can be useful for code values. */
	useHyphens?: boolean,
};

const LineBreaks: React.FC<Props> = (props) => {
	const {
		children,
		useHyphens = true,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.useHyphens]: useHyphens,
	});

	return (
		<span className={componentClasses}>
			<span className={styles.text}>
				{children}
			</span>
		</span>
	);
};



export default LineBreaks;
