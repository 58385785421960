import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import RichText from '~/components/patterns/typography/RichText';

import {
	type SegmentDefinition,
} from '~/model/segments';



const messages = defineMessages({
	elementTypeChanged: {
		id: 'ui.customElements.warning.items.elementTypeChanged',
	},
	segmentsMayBeImpacted: {
		id: 'ui.customElements.warning.items.segmentsMayBeImpacted',
	},
	segmentsWillBeUpdated: {
		id: 'ui.customElements.warning.items.segmentsWillBeUpdated',
	},
	title: {
		id: 'ui.general.caution',
	},
});



type Props = {
	isDifferent: boolean,
	newElementType: string,
	oldElementType: string,
	oldName: string,
	segments: ReadonlyArray<SegmentDefinition>,
};

const ExtractionCaution: React.FC<Props> = (props) => {
	const {
		isDifferent,
		newElementType,
		oldElementType,
		oldName,
		segments,
	} = props;

	const items: Array<React.ReactNode> = [];

	const numberOfAffectedSegments = segments.filter(
		(segment) => ('custom_' + oldName) in segment.filterDefinition,
	).length;

	if (newElementType !== oldElementType) {
		items.push((
			<FormattedMessage
				{...messages.elementTypeChanged}
				values={{
					text__newElementType: newElementType,
					text__oldElementType: oldElementType,
				}}
			/>
		));

		if (numberOfAffectedSegments > 0) {
			items.push((
				<FormattedMessage
					{...messages.segmentsWillBeUpdated}
					values={{
						numberOfAffectedSegments,
					}}
				/>
			));
		}
	} else if (isDifferent) {
		if (numberOfAffectedSegments > 0) {
			items.push((
				<FormattedMessage
					{...messages.segmentsMayBeImpacted}
					values={{
						numberOfAffectedSegments,
					}}
				/>
			));
		}
	}

	if (items.length === 0) {
		return null;
	}

	return (
		<CalloutMessage
			borders={true}
			message={(
				<FormattedMessage {...messages.title} />
			)}
			status={CalloutMessageStatus.Critical}
		>
			<RichText>
				<ul>
					{items.map((item, index) => (
						<li key={index}>{item}</li>
					))}
				</ul>
			</RichText>
		</CalloutMessage>
	);
};



export default ExtractionCaution;
