/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountUserRestrictionsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountUserRestrictionsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly userRestrictions: { readonly __typename?: 'AccountUserRestrictions', readonly emailDomainsWhitelist: ReadonlyArray<string> | null, readonly maximumInvitations: number } } | null };


export const AccountUserRestrictionsDocument = gql`
    query AccountUserRestrictions($accountId: AccountId!) {
  account(id: $accountId) {
    id
    userRestrictions {
      emailDomainsWhitelist
      maximumInvitations
    }
  }
}
    `;

/**
 * __useAccountUserRestrictionsQuery__
 *
 * To run a query within a React component, call `useAccountUserRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountUserRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountUserRestrictionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountUserRestrictionsQuery(baseOptions: Apollo.QueryHookOptions<AccountUserRestrictionsQuery, AccountUserRestrictionsQueryVariables> & ({ variables: AccountUserRestrictionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountUserRestrictionsQuery, AccountUserRestrictionsQueryVariables>(AccountUserRestrictionsDocument, options);
      }
export function useAccountUserRestrictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountUserRestrictionsQuery, AccountUserRestrictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountUserRestrictionsQuery, AccountUserRestrictionsQueryVariables>(AccountUserRestrictionsDocument, options);
        }
export function useAccountUserRestrictionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountUserRestrictionsQuery, AccountUserRestrictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountUserRestrictionsQuery, AccountUserRestrictionsQueryVariables>(AccountUserRestrictionsDocument, options);
        }
export type AccountUserRestrictionsQueryHookResult = ReturnType<typeof useAccountUserRestrictionsQuery>;
export type AccountUserRestrictionsLazyQueryHookResult = ReturnType<typeof useAccountUserRestrictionsLazyQuery>;
export type AccountUserRestrictionsSuspenseQueryHookResult = ReturnType<typeof useAccountUserRestrictionsSuspenseQuery>;
export type AccountUserRestrictionsQueryResult = Apollo.QueryResult<AccountUserRestrictionsQuery, AccountUserRestrictionsQueryVariables>;