import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ResizableDatatable, {
	type ResizableDatatableColumnDefinitions,
	ResizableDatatableHeaderHeight,
} from '~/components/logic/datatables/ResizableDatatable';
import TextualCellValue from '~/components/logic/datatables/cellValues/TextualCellValue';
import UrlCellValue from '~/components/logic/datatables/cellValues/UrlCellValue';

import {
	useOutgoingInternalLinksQuery,
} from './OutgoingInternalLinksTable.gql';

import useGraphQLConnection from '~/hooks/useGraphQLConnection';
import useNavigate from '~/hooks/useNavigate';



const filter = Immutable.Map({});

const messages = defineMessages({
	columnLabel: {
		id: 'ui.pageDetail.linkData.outbound.columns.label',
	},
	columnStatus: {
		id: 'ui.pageDetail.linkData.outbound.columns.status',
	},
	columnTitle: {
		id: 'ui.pageDetail.linkData.outbound.columns.title',
	},
	columnUrl: {
		id: 'ui.pageDetail.linkData.outbound.columns.url',
	},
	statusBroken: {
		id: 'ui.pageDetail.linkData.status.broken',
	},
	statusForbidden: {
		id: 'ui.pageDetail.linkData.status.forbidden',
	},
	statusOk: {
		id: 'ui.pageDetail.linkData.status.ok',
	},
	statusRedirect: {
		id: 'ui.pageDetail.linkData.status.redirect',
	},
	statusUnknown: {
		id: 'ui.pageDetail.linkData.status.unknown',
	},
	statusUnreachable: {
		id: 'ui.pageDetail.linkData.status.unreachable',
	},
});



type Props = {
	fallbackLinksCount: number | undefined,
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const OutgoingInternalLinksTable: React.FC<Props> = (props) => {
	const {
		fallbackLinksCount,
		legacyUrlId,
		websiteId,
	} = props;

	const navigate = useNavigate();

	const { data, fetchMore } = useOutgoingInternalLinksQuery({
		variables: {
			legacyUrlId,
			limit: 100,
			offset: 0,
			websiteId,
		},
	});

	const graphQLConnection = useGraphQLConnection({
		batchLimit: 100,
		context: {
			websiteId,
		},
		data,
		fallbackTotalCount: fallbackLinksCount,
		fetchMore,
		getter: (data) => data.lookupPageByLegacyId?.pageTypeData?.outgoingInternalLinks ?? null,
		pollIntervalInMilliseconds: 10_000,
	});

	const handleClick = React.useCallback(
		({ row }, event) => {
			if (row === null || row.targetLegacyId === null) {
				return;
			}

			navigate({
				event,
				routeName: 'website.pages.detail',
				routeParams: {
					id: row.targetLegacyId,
					websiteId,
				},
			});
		},
		[
			navigate,
			websiteId,
		],
	);

	const columns: ResizableDatatableColumnDefinitions<typeof graphQLConnection.rowGetter> = React.useMemo(
		() => {
			return [
				{
					name: 'outgoingInternalLinksTargetUrl',
					render: {
						cell: ({ row }) => (
							<UrlCellValue value={row.targetUrl} />
						),
						label: (
							<FormattedMessage {...messages.columnUrl} />
						),
					},
					resizable: true,
					width: 450,
				},
				{
					name: 'outgoingInternalLinksAnchorText',
					render: {
						cell: ({ row }) => (
							<TextualCellValue value={row.anchorText} />
						),
						label: (
							<FormattedMessage {...messages.columnLabel} />
						),
					},
					resizable: true,
					width: 200,
				},
				{
					name: 'outgoingInternalLinksAnchorTitle',
					render: {
						cell: ({ row }) => (
							<TextualCellValue value={row.anchorTitle} />
						),
						label: (
							<FormattedMessage {...messages.columnTitle} />
						),
					},
					resizable: true,
					width: 300,
				},
				{
					name: 'outgoingInternalLinksTargetStatus',
					render: {
						cell: ({ row }) => {
							let text;

							if (row.targetStatus === GraphQL.PageOutgoingInternalLinkStatus.NotCrawledYet) {
								text = (
									<FormattedMessage {...messages.statusUnknown} />
								);
							} else if (row.targetStatus === GraphQL.PageOutgoingInternalLinkStatus.Unreachable) {
								text = (
									<FormattedMessage {...messages.statusUnreachable} />
								);
							} else if (row.targetStatusCode === 403) {
								text = (
									<FormattedMessage {...messages.statusForbidden} />
								);
							} else if (row.targetStatusCode !== null && row.targetStatusCode >= 400) {
								text = (
									<FormattedMessage {...messages.statusBroken} />
								);
							} else if (row.targetStatusCode !== null && row.targetStatusCode >= 300) {
								text = (
									<FormattedMessage {...messages.statusRedirect} />
								);
							} else {
								text = (
									<FormattedMessage {...messages.statusOk} />
								);
							}

							return (
								<TextualCellValue value={text} />
							);
						},
						label: (
							<FormattedMessage {...messages.columnStatus} />
						),
					},
					resizable: true,
					width: 300,
				},
			];
		},
		[],
	);

	return (
		<ResizableDatatable
			blankSlate={null}
			columns={columns}
			filter={filter}
			headerHeight={ResizableDatatableHeaderHeight.Small}
			isLoading={graphQLConnection.isLoaded === false}
			onRowClick={handleClick}
			onScroll={graphQLConnection.fetchFurtherRange}
			rowGetter={graphQLConnection.rowGetter}
			rowsCount={graphQLConnection.totalCount}
		/>
	);
};



export default OutgoingInternalLinksTable;
