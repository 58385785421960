import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import Missing from './Missing';
import NotCrawledYet from './NotCrawledYet';
import Overquota from './Overquota';
import Redirect from './Redirect';
import ServerError from './ServerError';
import Unreachable from './Unreachable';

import usePageType from '~/hooks/usePageType';



type Props = {
	isCrawlingPaused: boolean,
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const StatusMessage: React.FC<Props> = (props) => {
	const {
		isCrawlingPaused,
		legacyUrlId,
		websiteId,
	} = props;

	const pageType = usePageType(legacyUrlId, websiteId);

	if (pageType === GraphQL.PageType.Missing) {
		return (
			<Missing
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>
		);
	}

	if (pageType === GraphQL.PageType.Redirect) {
		return (
			<Redirect
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>
		);
	}

	if (pageType === GraphQL.PageType.ServerError) {
		return (
			<ServerError
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>
		);
	}

	if (pageType === GraphQL.PageType.OverQuota) {
		return (
			<Overquota />
		);
	}

	if (pageType === GraphQL.PageType.Unknown) {
		return (
			<NotCrawledYet
				isCrawlingPaused={isCrawlingPaused}
			/>
		);
	}

	if (pageType === GraphQL.PageType.Unreachable) {
		return (
			<Unreachable
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>
		);
	}

	return null;
};



export default StatusMessage;
