import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import ColumnFilterFormatter from '~/components/logic/filters/ColumnFilterFormatter';
import ColumnName from '~/components/names/ColumnName';
import Declaration from '~/components/patterns/values/declarations/Declaration';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import SizeLimit from '~/components/logic/filters/SizeLimit';
import TextualList from '~/components/patterns/lists/TextualList';
import WithSeparator from '~/components/atoms/WithSeparator';

import matchAndReturn from '~/utilities/matchAndReturn';



export enum FilterDefinitionFormatterStyle {
	CompactList,
	Inline,
	List,
}

const messages = defineMessages({
	property: {
		id: 'ui.general.propertyDeclaration',
	},
	staticSegmentCriteria: {
		id: 'ui.staticSegmentCriteria',
	},
});



type Props = {
	filterDefinition?: CK.FilterDefinition,
	sizeLimitDefinition?: CK.PageSegmentSizeLimit | null,
	style: FilterDefinitionFormatterStyle,
};

const FilterDefinitionFormatter: React.FC<Props> = (props) => {
	const {
		filterDefinition = null,
		sizeLimitDefinition = null,
		style,
	} = props;

	if (filterDefinition && 'static' in filterDefinition) {
		return (
			<FormattedMessage
				{...messages.staticSegmentCriteria}
			/>
		);
	}

	const items: Array<{
		description: React.ReactNode,
		label: React.ReactNode,
	}> = [];

	if (filterDefinition) {
		Object.entries(filterDefinition).forEach(([column, value]) => {
			items.push({
				label: (
					<ColumnName
						column={column as CK.PagesColumn}
						textOnly={true}
					/>
				),
				description: (
					<ColumnFilterFormatter
						columnName={column as CK.PagesColumn}
						value={value}
					/>
				),
			});
		});
	}

	if (sizeLimitDefinition) {
		items.push({
			label: (
				<ColumnName
					column={sizeLimitDefinition.field as CK.PagesColumn}
					textOnly={true}
				/>
			),
			description: (
				<SizeLimit sizeLimitDefinition={sizeLimitDefinition} />
			),
		});
	}

	const renderCompactList = () => {
		return (
			<TextualList textOnlyWhenSingle={false}>
				{items.map((item, i) => (
					<Declaration
						key={i}
						property={(
							<FormattedMessage
								{...messages.property}
								values={{ text__property: item.label }}
							/>
						)}
						value={item.description}
					/>
				))}
			</TextualList>
		);
	};

	const renderInline = () => {
		return (
			<Ellipsis altPopupText={renderCompactList()}>
				<WithSeparator separator=",  ">
					{items.map((item, i) => (
						<Declaration
							key={i}
							property={(
								<FormattedMessage
									{...messages.property}
									values={{ text__property: item.label }}
								/>
							)}
							value={item.description}
						/>
					))}
				</WithSeparator>
			</Ellipsis>
		);
	};

	const renderList = () => {
		return (
			<TextualList
				items={items}
				textOnlyWhenSingle={false}
			/>
		);
	};

	return matchAndReturn(style, {
		[FilterDefinitionFormatterStyle.CompactList]: renderCompactList(),
		[FilterDefinitionFormatterStyle.Inline]: renderInline(),
		[FilterDefinitionFormatterStyle.List]: renderList(),
	});
};



export default React.memo(FilterDefinitionFormatter);
