export default class Exception {

	constructor(errorCode, intlMessageKey) {
		this.errorCode = errorCode;
		this.intlMessageKey = intlMessageKey;
	}



	getCode() {
		return this.errorCode;
	}

}
