import React from 'react';
import times from 'lodash/times';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



export type EditableFormTable = {
	addRow: () => number,
	moveRow: (fromRow: number, toRow: number) => void,
	removeRow: (row: number) => void,
	rows: Array<number>,
};


function useEditableFormTable({
	addExtraEmptyRow = false,
	defaultRows,
	minimumRows = 0,
}: {
	addExtraEmptyRow?: boolean,
	defaultRows: number,
	minimumRows?: number,
}): EditableFormTable {
	const highestRow = React.useRef(Math.max(minimumRows, defaultRows) - (addExtraEmptyRow ? 0 : 1));

	const [
		rows,
		setRows,
	] = React.useState(
		times(Math.max(minimumRows, defaultRows) + (addExtraEmptyRow ? 1 : 0)),
	);

	return React.useMemo(
		() => ({
			addRow: () => {
				const nextHighestRow = highestRow.current + 1;
				highestRow.current = nextHighestRow;

				setRows((rows) => ([
					...rows,
					nextHighestRow,
				]));

				return nextHighestRow;
			},
			moveRow: (fromRow: number, toRow: number) => {
				setRows((rows) => {
					if (fromRow < toRow) {
						return [
							...rows.slice(0, fromRow),
							...rows.slice(fromRow + 1, toRow + 1),
							getArrayItemAtSafeIndex(rows, fromRow),
							...rows.slice(toRow + 1),
						];
					}

					return [
						...rows.slice(0, toRow),
						getArrayItemAtSafeIndex(rows, fromRow),
						...rows.slice(toRow, fromRow),
						...rows.slice(fromRow + 1),
					];
				});
			},
			removeRow: (row: number) => {
				setRows((rows) => {
					const index = rows.indexOf(row);

					if (index === -1) {
						return rows;
					}

					const nextRows = [
						...rows.slice(0, index),
						...rows.slice(index + 1),
					];

					if (nextRows.length < minimumRows) {
						const nextHighestRow = highestRow.current + 1;
						highestRow.current = nextHighestRow;

						nextRows.push(nextHighestRow);
					}

					return nextRows;
				});
			},
			rows,
		}),
		[
			minimumRows,
			rows,
		],
	);
}



export default useEditableFormTable;
