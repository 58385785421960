import React from 'react';

import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';

import {
	type PageType,
} from '~/model/pagesNew';



type Props = {
	allowedPageTypes: ReadonlyArray<PageType>,
	pageType: PageType,
	value: React.ReactNode,
};

const OnlySpecificPageTypeFormatter: React.FC<Props> = (props) => {
	const {
		allowedPageTypes,
		pageType,
		value,
	} = props;

	if (!allowedPageTypes.includes(pageType)) {
		return (
			<NotApplicableValue />
		);
	}

	return (
		<>{value || null}</>
	);
};



export default OnlySpecificPageTypeFormatter;
