import React from 'react';

import ElementTypeName from '~/components/logic/customElements/ElementTypeName';
import SelectField from '~/components/atoms/forms/components/SelectField';

import {
	DATA_TYPE_DATE,
	DATA_TYPE_NUMBER,
	DATA_TYPE_STRING,
	EXTRACTED_ELEMENT_DATE,
	EXTRACTED_ELEMENT_NUMBER,
	EXTRACTED_ELEMENT_STRING,
} from '~/model/customElements';



const options = [
	{
		label: (
			<ElementTypeName elementType={DATA_TYPE_STRING} />
		),
		name: EXTRACTED_ELEMENT_STRING,
	},
	{
		label: (
			<ElementTypeName elementType={DATA_TYPE_NUMBER} />
		),
		name: EXTRACTED_ELEMENT_NUMBER,
	},
	{
		label: (
			<ElementTypeName elementType={DATA_TYPE_DATE} />
		),
		name: EXTRACTED_ELEMENT_DATE,
	},
];



type Props = {
	name: string,
	width?: React.CSSProperties['width'],
};

const CustomElementExtractedElementSelectField: React.FC<Props> = (props) => {
	const {
		name,
		width,
	} = props;

	return (
		<SelectField
			dropdownWidth={200}
			isInteractedByDefault={false}
			name={name}
			options={options}
			popperBoundary={document.querySelector('.modal.js-scrollable')}
			width={width}
		/>
	);
};



export default CustomElementExtractedElementSelectField;
