import React from 'react';

import {
	ViewportContext,
} from '~/components/ViewportProvider';



function useViewportWidth(): number {
	const viewport = React.useContext(ViewportContext);

	return React.useMemo(
		() => {
			return viewport.windowSize.width;
		},
		[viewport.windowSize.width],
	);
}



export default useViewportWidth;
