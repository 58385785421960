import {
	type RenderProp,
} from '~/utilities/renderProp';



export const NotApplicable = Symbol();

export type Values = Readonly<Record<string, any>>;

export type Message<Extra = {}> = RenderProp<{
	details: Record<any, any>,
	value: any,
	values: Values,
} & Extra>;

type GlobalRule = {
	field: string,
	message: Message<{
		globalError: any,
	}>,
	globalRule: string,
};

type RuleWithoutExtraFields = {
	field: string,
	message: Message,
	rule: (parameters: { name: string, values: Values, value: any }) => boolean | typeof NotApplicable,
};

type RuleWithExtraFields = {
	fields: Array<string>,
	message: Message,
	rule: (parameters: { values: Values }) => boolean | typeof NotApplicable,
};

export type Rule = GlobalRule | RuleWithoutExtraFields | RuleWithExtraFields;

export function isGlobalRule(rule: Rule): rule is GlobalRule {
	return 'globalRule' in rule;
}

export function isFieldRule(rule: Rule): rule is RuleWithoutExtraFields {
	return isGlobalRule(rule) === false && 'field' in rule;
}

export function isFieldsRule(rule: Rule): rule is RuleWithExtraFields {
	return isGlobalRule(rule) === false && 'fields' in rule;
}
