import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const StatsFailingIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M7,13.706a3.357,3.357,0,0,0,4.694-.075l2.138-2.137a.483.483,0,0,1,.682,0l3.572,3.572a.24.24,0,0,1,0,.341l-1.7,1.7a.964.964,0,0,0,.68,1.647h5.786a.965.965,0,0,0,.964-.964V12.02a.965.965,0,0,0-1.645-.683l-1.694,1.688a.24.24,0,0,1-.34,0L16.556,9.448a3.456,3.456,0,0,0-4.773,0L9.646,11.586a.481.481,0,0,1-.671.01L2.624,5.642A1.446,1.446,0,1,0,.645,7.752Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default StatsFailingIconBuilder;

