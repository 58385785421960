import React from 'react';

import {
	useRegisterMicrosoftTeamsIncomingWebhookMutation,
} from './useRegisterMicrosoftTeamsMessagingAppChannel.gql';

import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';



function useRegisterMicrosoftTeamsMessagingAppChannel() {
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const [registerMicrosoftTeamsIncomingWebhook] = useRegisterMicrosoftTeamsIncomingWebhookMutation();

	return React.useCallback(
		async (
			incomingWebhookUrl: string,
			label: string,
		) => {
			if (effectiveHomeAccountId === null) {
				throw new Error(
					`Effective home account ID isn't ready`,
				);
			}

			const { data } = await registerMicrosoftTeamsIncomingWebhook({
				variables: {
					accountId: effectiveHomeAccountId,
					incomingWebhookUrl,
					label,
				},
			});

			const registeredMessagingAppChannelId = data?.RegisterMicrosoftTeamsIncomingWebhook.registeredMessagingAppChannelId ?? null;

			if (registeredMessagingAppChannelId === null) {
				throw new Error(
					'Registering Microsoft Teams channel must succeed',
				);
			}

			return registeredMessagingAppChannelId;
		},
		[
			effectiveHomeAccountId,
			registerMicrosoftTeamsIncomingWebhook,
		],
	);
}



export default useRegisterMicrosoftTeamsMessagingAppChannel;
