import classNames from 'classnames';
import React from 'react';



export enum RemoveButtonStyle {
	Dark = 'dark',
	Light = 'light',
}



type Props = {
	onClickCallback: () => void,
	/** Custom size in pixels. Default size is 13 × 13 px. */
	size?: number,
	style?: RemoveButtonStyle,
};

const RemoveButton: React.FC<Props> = (props) => {
	const {
		onClickCallback,
		size,
		style = RemoveButtonStyle.Dark,
	} = props;

	const componentClasses = classNames({
		'remove-button': true,
		'remove-button--light': style === RemoveButtonStyle.Light,
	});

	return (
		<button
			className={componentClasses}
			onClick={onClickCallback}
			style={{
				height: size,
				width: size,
			}}
			type="button"
		>
			×
		</button>
	);
};



export default RemoveButton;
