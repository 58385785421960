/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScopeSizesQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ScopeSizesQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly scenariosIllustratingAlertSensitivity: ReadonlyArray<{ readonly __typename?: 'ScenarioIllustratingAlertSensitivity', readonly scope: string, readonly scopeSize: number }> } | null };


export const ScopeSizesDocument = gql`
    query ScopeSizes($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    scenariosIllustratingAlertSensitivity {
      scope
      scopeSize
    }
  }
}
    `;

/**
 * __useScopeSizesQuery__
 *
 * To run a query within a React component, call `useScopeSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScopeSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScopeSizesQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useScopeSizesQuery(baseOptions: Apollo.QueryHookOptions<ScopeSizesQuery, ScopeSizesQueryVariables> & ({ variables: ScopeSizesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScopeSizesQuery, ScopeSizesQueryVariables>(ScopeSizesDocument, options);
      }
export function useScopeSizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScopeSizesQuery, ScopeSizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScopeSizesQuery, ScopeSizesQueryVariables>(ScopeSizesDocument, options);
        }
export function useScopeSizesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ScopeSizesQuery, ScopeSizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ScopeSizesQuery, ScopeSizesQueryVariables>(ScopeSizesDocument, options);
        }
export type ScopeSizesQueryHookResult = ReturnType<typeof useScopeSizesQuery>;
export type ScopeSizesLazyQueryHookResult = ReturnType<typeof useScopeSizesLazyQuery>;
export type ScopeSizesSuspenseQueryHookResult = ReturnType<typeof useScopeSizesSuspenseQuery>;
export type ScopeSizesQueryResult = Apollo.QueryResult<ScopeSizesQuery, ScopeSizesQueryVariables>;