import {
	format,
} from 'date-fns';
import Immutable from 'immutable';
import React from 'react';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	DEFAULT_FILTER,
	createFilterParameter,
} from '~/model/websiteIssuesComparison';



function useNavigateToHistoricalIssues() {
	const websiteId = useWebsiteId();

	return React.useMemo(
		() => (input) => {
			const {
				endDate,
				filter = null,
				scope = 'website',
				startDate,
			} = input;

			let totalFilter = DEFAULT_FILTER.merge(
				Immutable.fromJS(filter ? filter : {}),
			);

			if (scope !== 'website') {
				totalFilter = totalFilter.set(
					'scope',
					scope.substring('segment/'.length),
				);
			}

			return {
				routeName: 'website.issues',
				routeParams: {
					end_date: endDate instanceof Date
						? format(endDate, 'yyyy-MM-dd')
						: endDate,
					filter: createFilterParameter(
						totalFilter,
					) || undefined,
					start_date: startDate instanceof Date
						? format(startDate, 'yyyy-MM-dd')
						: startDate,
					websiteId,
				},
			};
		},
		[
			websiteId,
		],
	);
}



export default useNavigateToHistoricalIssues;
