import React from 'react';



type Props = {
	controlElements?: React.ReactNode,
	filterFields?: React.ReactNode,
	navigation?: React.ReactNode,
};



const PageDetailContentHeaderLayout: React.FC<Props> = (props) => {
	const {
		controlElements,
		filterFields,
		navigation,
	} = props;

	if (!controlElements && !filterFields && !navigation) {
		return null;
	}

	const controlElementsArray = Array.isArray(controlElements) ? controlElements : [controlElements];
	const filterFieldsArray = Array.isArray(filterFields) ? filterFields : [filterFields];

	return (
		<div className="page-detail-content-header">
			{navigation && (
				<div className="page-detail-content-header__navigation">
					{navigation}
				</div>
			)}
			{controlElements && (
				<div className="page-detail-content-header__control-elements">
					{controlElementsArray.map((child, index) => {
						return (
							<div key={'control-element-' + index}>
								{child}
							</div>
						);
					})}
				</div>
			)}
			{filterFields && (
				<div className="page-detail-content-header__filter-fields">
					{filterFieldsArray.map((child, index) => {
						return (
							<div key={'filter-field-' + index}>
								{child}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};



export default PageDetailContentHeaderLayout;
