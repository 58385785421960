import {
	useCurrentUserIdQuery,
} from './useCurrentUserId.gql';



function useCurrentUserId(): string | null {
	const { data } = useCurrentUserIdQuery();

	return data?.authenticatedSession?.user.legacyId ?? null;
}



export default useCurrentUserId;
