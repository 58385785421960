import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import StandaloneFieldRow from '~/components/atoms/forms/basis/StandaloneFieldRow';
import TextField, {
	TextFieldAutocomplete,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';



const messages = defineMessages({
	invalidCode: {
		id: 'ui.twoFactorAuthenticationLogin.errors.invalidCode',
	},
});



const TwoFactorAuthenticationCodeField: React.FC = () => {
	return (
		<StandaloneFieldRow>
			<FieldStatus
				allowOk={false}
				name="validateTwoFactorAuthenticationCode"
			>
				<TextField
					attributes={{
						inputMode: 'numeric',
						pattern: '[0-9]{6}',
					}}
					autoComplete={TextFieldAutocomplete.OneTimeCode}
					name="twoFactorAuthenticationCode"
					placeholder="123456"
					resetButton={true}
				/>
			</FieldStatus>
		</StandaloneFieldRow>
	);
};

export default TwoFactorAuthenticationCodeField;



export const validateTwoFactorAuthenticationCode = validateField(
	'twoFactorAuthenticationCode',
	(f) => [
		f.validateNonEmpty(),
		f.customGlobal({
			globalRule: 'invalidCode',
			message: (
				<FormattedMessage {...messages.invalidCode} />
			),
		}),
	],
);
