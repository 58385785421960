import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import ResizableDatatable, {
	type ResizableDatatableColumnDefinitions,
	ResizableDatatableHeaderHeight,
} from '~/components/logic/datatables/ResizableDatatable';
import TextualCellValue from '~/components/logic/datatables/cellValues/TextualCellValue';
import UrlCellValue from '~/components/logic/datatables/cellValues/UrlCellValue';

import {
	useIncomingRedirectsQuery,
} from './IncomingRedirectsTable.gql';

import useGraphQLConnection from '~/hooks/useGraphQLConnection';
import useNavigate from '~/hooks/useNavigate';



const filter = Immutable.Map({});

const messages = defineMessages({
	columnStatus: {
		id: 'ui.pageDetail.linkData.incomingRedirects.columns.status',
	},
	columnUrl: {
		id: 'ui.pageDetail.linkData.incomingRedirects.columns.url',
	},
});



type Props = {
	fallbackLinksCount: number | undefined,
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const IncomingRedirectsTable: React.FC<Props> = (props) => {
	const {
		fallbackLinksCount,
		legacyUrlId,
		websiteId,
	} = props;

	const navigate = useNavigate();

	const { data, fetchMore } = useIncomingRedirectsQuery({
		variables: {
			legacyUrlId,
			limit: 100,
			offset: 0,
			websiteId,
		},
	});

	const graphQLConnection = useGraphQLConnection({
		batchLimit: 100,
		context: {
			websiteId,
		},
		data,
		fallbackTotalCount: fallbackLinksCount,
		fetchMore,
		getter: (data) => data.lookupPageByLegacyId?.incomingRedirects ?? null,
		pollIntervalInMilliseconds: 10_000,
	});

	const handleClick = React.useCallback(
		({ row }, event) => {
			if (row === null || row.sourceLegacyId === null) {
				return;
			}

			navigate({
				event,
				routeName: 'website.pages.detail',
				routeParams: {
					id: row.sourceLegacyId,
					websiteId,
				},
			});
		},
		[
			navigate,
			websiteId,
		],
	);

	const columns: ResizableDatatableColumnDefinitions<typeof graphQLConnection.rowGetter> = React.useMemo(
		() => {
			return [
				{
					name: 'incomingCanonicalLinksSourceUrl',
					render: {
						cell: ({ row }) => (
							<UrlCellValue value={row.sourceUrl} />
						),
						label: (
							<FormattedMessage {...messages.columnUrl} />
						),
					},
					resizable: true,
					width: 450,
				},
				{
					name: 'incomingRedirectsStatus',
					render: {
						cell: ({ row }) => (
							<TextualCellValue value={row.sourceStatusCode} />
						),
						label: (
							<FormattedMessage {...messages.columnStatus} />
						),
					},
					resizable: true,
					width: 200,
				},
			];
		},
		[],
	);

	return (
		<ResizableDatatable
			blankSlate={null}
			columns={columns}
			filter={filter}
			headerHeight={ResizableDatatableHeaderHeight.Small}
			isLoading={graphQLConnection.isLoaded === false}
			onRowClick={handleClick}
			onScroll={graphQLConnection.fetchFurtherRange}
			rowGetter={graphQLConnection.rowGetter}
			rowsCount={graphQLConnection.totalCount}
		/>
	);
};



export default IncomingRedirectsTable;
