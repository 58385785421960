import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import CodeValue from '~/components/patterns/values/CodeValue';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import FormFieldsWrapper from '~/components/patterns/forms/wrappers/FormFieldsWrapper';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HelpHint from '~/components/patterns/hints/HelpHint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useAccountIntegrationsFormQuery,
} from './AccountIntegrationsForm.gql';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountId from '~/hooks/useAccountId';



const messages = defineMessages({
	cmsApiHint: {
		id: 'ui.teamDetail.accountSettings.cmsApiHint',
	},
	cmsApiTokenLabel: {
		id: 'ui.teamDetail.accountSettings.apiTokenLabel',
	},
	enrichmentApiChangeTokenHint: {
		id: 'ui.enrichmentApi.changeTokenHint',
	},
	enrichmentApiChangeTokenLink: {
		id: 'ui.enrichmentApi.changeTokenLink',
	},
	enrichmentApiGetToken: {
		id: 'ui.enrichmentApi.getToken',
	},
	enrichmentApiHint: {
		id: 'ui.enrichmentApi.hint',
	},
	enrichmentApiTokenLabel: {
		id: 'ui.enrichmentApi.tokenLabel',
	},
	reportingApiChangeTokenHint: {
		id: 'ui.teamDetail.accountSettings.changeReportingApiHint',
	},
	reportingApiChangeTokenLink: {
		id: 'ui.teamDetail.accountSettings.changeReportingApiLink',
	},
	reportingApiGetToken: {
		id: 'ui.teamDetail.accountSettings.getReportingApiToken',
	},
	reportingApiHint: {
		id: 'ui.teamDetail.accountSettings.reportingApiHint',
	},
	reportingApiTokenLabel: {
		id: 'ui.teamDetail.accountSettings.reportingApiTokenLabel',
	},
	title: {
		id: 'ui.teamDetail.accountSettings.pluginsTitle',
	},
});



const AccountIntegrationsForm: React.FC = () => {
	const accountId = useAccountId();
	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);

	const { data } = useAccountIntegrationsFormQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const cmsApiToken = data?.account?.cmsApiToken ?? null;
	const enrichmentApiToken = data?.account?.enrichmentApiToken ?? null;
	const isEnrichmentApiTermsOfUseAccepted = data?.account?.isEnrichmentApiTermsOfUseAccepted ?? null;
	const isReportingApiTermsOfUseAccepted = data?.account?.isReportingApiTermsOfUseAccepted ?? null;
	const reportingApiToken = data?.account?.reportingApiToken ?? null;

	return (
		<FormFieldsWrapper
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<FormRows>
				{accountEnabledFeatures !== null && accountEnabledFeatures.includes(GraphQL.AccountFeature.CmsApi) && (
					<FormRow
						label={(
							<AttachedElement
								element={(
									<HelpHint
										message={(
											<Copy
												{...messages.cmsApiHint}
												values={{
													link: linkExternal('https://www.contentkingapp.com/support/cms-api/'),
												}}
											/>
										)}
									/>
								)}
							>
								<FormattedMessage {...messages.cmsApiTokenLabel} />
							</AttachedElement>
						)}
					>
						<StaticText>
							<CodeValue>
								{cmsApiToken ?? ''}
							</CodeValue>
						</StaticText>
					</FormRow>
				)}

				<PremiumFeatureSituation
					featureName={GraphQL.AccountFeature.ReportingApi}
					hideIfUnattainable={true}
					style={PremiumFeatureSituationStyle.Ribbon}
				>
					{({ isFeatureEnabled, premiumAnnotation }) => (
						<FormRow
							description={isFeatureEnabled && isReportingApiTermsOfUseAccepted === true && (
								<AttachedElement
									element={(
										<HelpHint
											message={(
												<FormattedMessage {...messages.reportingApiChangeTokenHint} />
											)}
										/>
									)}
								>
									<InternalLink
										routeName="account.settings.integrationTokens.changeReportingApiToken"
										routeParams={{
											accountId,
										}}
										style={InternalLinkStyle.Decent}
									>
										<FormattedMessage {...messages.reportingApiChangeTokenLink} />
									</InternalLink>
								</AttachedElement>
							)}
							label={(
								<AttachedElement
									element={(
										<HelpHint
											message={(
												<Copy
													{...messages.reportingApiHint}
													values={{
														link: linkExternal('https://www.contentkingapp.com/support/reporting-api/'),
													}}
												/>
											)}
										/>
									)}
								>
									<FormattedMessage {...messages.reportingApiTokenLabel} />
								</AttachedElement>
							)}
						>
							<DisabledContent
								disabledContent={!isFeatureEnabled}
								disabledOverlay={!isFeatureEnabled && premiumAnnotation}
							>
								{isFeatureEnabled && isReportingApiTermsOfUseAccepted === true && (
									<StaticText>
										<CodeValue>
											{reportingApiToken ?? ''}
										</CodeValue>
									</StaticText>
								)}

								{isFeatureEnabled && isReportingApiTermsOfUseAccepted === false && (
									<StaticText>
										<InternalLink
											routeName="account.settings.integrationTokens.acceptReportingApiTermsOfUse"
											routeParams={{
												accountId,
											}}
											style={InternalLinkStyle.Decent}
										>
											<FormattedMessage {...messages.reportingApiGetToken} />
										</InternalLink>
									</StaticText>
								)}

								{!isFeatureEnabled && (
									<StaticText>
										<CodeValue>044caa987435d2e60707a16e5ef68cb3</CodeValue>
									</StaticText>
								)}
							</DisabledContent>
						</FormRow>
					)}
				</PremiumFeatureSituation>

				<PremiumFeatureSituation
					featureName={GraphQL.AccountFeature.EnrichmentFields_5}
					hideIfUnattainable={true}
					style={PremiumFeatureSituationStyle.Ribbon}
				>
					{({ isFeatureEnabled, premiumAnnotation }) => (
						<FormRow
							description={isFeatureEnabled && isEnrichmentApiTermsOfUseAccepted === true && (
								<AttachedElement
									element={(
										<HelpHint
											message={(
												<FormattedMessage {...messages.enrichmentApiChangeTokenHint} />
											)}
										/>
									)}
								>
									<InternalLink
										routeName="account.settings.integrationTokens.changeEnrichmentApiToken"
										routeParams={{
											accountId,
										}}
										style={InternalLinkStyle.Decent}
									>
										<FormattedMessage {...messages.enrichmentApiChangeTokenLink} />
									</InternalLink>
								</AttachedElement>
							)}
							label={(
								<AttachedElement
									element={(
										<HelpHint
											message={(
												<Copy
													{...messages.enrichmentApiHint}
													values={{
														link: linkExternal('https://www.contentkingapp.com/support/data-enrichment-api/'),
													}}
												/>
											)}
										/>
									)}
								>
									<FormattedMessage {...messages.enrichmentApiTokenLabel} />
								</AttachedElement>
							)}
						>
							<DisabledContent
								disabledContent={!isFeatureEnabled}
								disabledOverlay={!isFeatureEnabled && premiumAnnotation}
							>
								{isFeatureEnabled && isEnrichmentApiTermsOfUseAccepted === true && (
									<StaticText>
										<CodeValue>
											{enrichmentApiToken ?? ''}
										</CodeValue>
									</StaticText>
								)}

								{isFeatureEnabled && isEnrichmentApiTermsOfUseAccepted === false && (
									<StaticText>
										<InternalLink
											routeName="account.settings.integrationTokens.acceptEnrichmentApiTermsOfUse"
											routeParams={{
												accountId,
											}}
											style={InternalLinkStyle.Decent}
										>
											<FormattedMessage {...messages.enrichmentApiGetToken} />
										</InternalLink>
									</StaticText>
								)}

								{!isFeatureEnabled && (
									<StaticText>
										<CodeValue>044caa987435d2e60707a16e5ef68cb3</CodeValue>
									</StaticText>
								)}
							</DisabledContent>
						</FormRow>
					)}
				</PremiumFeatureSituation>
			</FormRows>
		</FormFieldsWrapper>
	);
};



export default AccountIntegrationsForm;
