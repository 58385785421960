import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import {
	usePopper,
} from 'react-popper';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import DepthLayer from '~/components/patterns/utils/DepthLayer';
import PopupBubble, {
	PopupBubbleSize,
} from '~/components/patterns/popups/PopupBubble';

import useFieldStatus from '~/hooks/useFieldStatus';
import useFormContext from '~/hooks/useFormContext';



export const TAG_BLOCK = 'block';
export const TAG_INLINE = 'inline';

export enum FieldStatusErrorMessageAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

export enum FieldStatusErrorMessageSize {
	Small = 'small',
	Medium = 'medium',
}

type Props = {
	allowError?: boolean,
	allowOk?: boolean,
	children?: React.ReactNode,
	errorMessageAlignment?: FieldStatusErrorMessageAlignment,
	errorMessageSize?: FieldStatusErrorMessageSize,
	errorMessageWidth?: React.CSSProperties['maxWidth'],
	/** Definition of specific field in <FieldStatus> where will be visible err.message on :focus */
	focusFieldErrorMessage?: string,
	name: string,
	showErrorMessageOnFocus?: boolean,
	showIcon?: boolean,
};



const FieldStatus: React.FC<Props> = (props) => {
	const {
		allowError = true,
		allowOk = true,
		children,
		errorMessageAlignment = FieldStatusErrorMessageAlignment.Left,
		errorMessageSize = FieldStatusErrorMessageSize.Small,
		errorMessageWidth,
		focusFieldErrorMessage,
		name,
		showErrorMessageOnFocus = false,
		showIcon = true,
	} = props;

	const {
		errorMessage,
		showError,
		showOk,
	} = useFieldStatus({
		allowError,
		allowOk,
		focusFieldErrorMessage,
		name,
		showErrorMessageOnFocus,
	});

	const formContext = useFormContext();

	const formContextFocused = formContext.focused;

	const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);

	const offsetModifier = React.useMemo(
		() => ({
			name: 'offset',
			options: {
				offset: ({ placement }) => {
					if (placement === 'bottom-start' || placement === 'top-start') {
						return errorMessageSize === FieldStatusErrorMessageSize.Medium ? [5, -15] : [4, -10];
					}

					if (placement === 'bottom-end' || placement === 'top-end') {
						return errorMessageSize === FieldStatusErrorMessageSize.Medium ? [-5, -15] : [-4, -10];
					}

					return [0, 0];
				},
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			errorMessageSize,
			formContextFocused,
		],
	);

	const {
		state: popperState,
		styles,
		attributes,
	} = usePopper(
		referenceElement,
		popperElement,
		{
			placement: errorMessageAlignment === FieldStatusErrorMessageAlignment.Left ? 'top-start' : 'top-end',
			modifiers: [
				{
					name: 'flip',
					options: {
						boundary: 'clippingParents',
						flipVariations: false,
						altBoundary: false,
					},
				},
				{
					name: 'preventOverflow',
					options: {
						mainAxis: false,
					},
				},
				offsetModifier,
			],
		},
	);

	const renderStatusIcon = ({ error, ok }) => {
		if (!error && !ok) {
			return null;
		}

		if (error) {
			return (
				<BasicIcon
					size={26}
					type={BasicIconType.Exclamation}
				/>
			);
		}

		if (ok) {
			return (
				<BasicIcon
					color="#42CC67"
					size={26}
					type={BasicIconType.Tick}
				/>
			);
		}

		return null;
	};

	const componentClasses = classNames({
		'field-status': true,
		'field-status--with-icon': showIcon,
		'field-status--invalid': showError,
		'field-status--valid': showOk,
	});

	const bubbleClasses = classNames({
		'field-status__bubble': true,
		'field-status__bubble--untouchable': showErrorMessageOnFocus,
	});

	const popperDropdownContainer = document.getElementById('popper-elements');

	return (
		<div className={componentClasses}>
			<DepthLayer>
				{({ depthLayer }) => {
					return (
						<>
							<div
								className="field-status__value"
								data-popper-placement={popperState && popperState.placement}
								ref={setReferenceElement}
							>
								{children}
							</div>
							{errorMessage && popperDropdownContainer && ReactDOM.createPortal(
								<div
									className={bubbleClasses}
									ref={setPopperElement}
									style={{
										zIndex: depthLayer,
										...styles.popper,
									}}
									{...attributes.popper}
								>
									<PopupBubble
										arrowStyles={{
											left: errorMessageAlignment === FieldStatusErrorMessageAlignment.Left ? 17 : 'auto',
											right: errorMessageAlignment === FieldStatusErrorMessageAlignment.Right ? 17 : 'auto',
										}}
										maxWidth={errorMessageWidth}
										size={errorMessageSize === FieldStatusErrorMessageSize.Small ? PopupBubbleSize.Small : PopupBubbleSize.Medium}
									>
										{errorMessage}
									</PopupBubble>
								</div>,
								popperDropdownContainer,
							)}
						</>
					);
				}}
			</DepthLayer>

			{showIcon && (
				<div className="field-status__current-status">
					{renderStatusIcon({
						error: showError,
						ok: showOk,
					})}
				</div>
			)}
		</div>
	);
};



export default FieldStatus;
