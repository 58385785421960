import React from 'react';
import classNames from 'classnames';



export enum InterfaceMessageArrowDirection {
	Bottom = 'bottom',
	BottomLeft = 'bottom-left',
	BottomRight = 'bottom-right',
	Top = 'top',
	TopLeft = 'top-left',
	TopRight = 'top-right',
}

export enum InterfaceMessageArrowType {
	Drawn = 'drawn',
	Triangle = 'triangle',
}

type Props = {
	/** Direction of arrow when arrow is visible */
	arrowDirection?: InterfaceMessageArrowDirection,
	/** Additional arrow styles */
	arrowStyles?: React.CSSProperties,
	/** Possibility to show message arrow by specifying what kind of arrow to show */
	arrowType?: InterfaceMessageArrowType,
	/** Optional Call-to-Action element */
	ctaElement?: React.ReactNode,
	/** Message text placed as a content */
	children: React.ReactNode,
	fullwidth?: boolean,
	/** Possibility to make box closable */
	onCloseCallback?: (e: Event) => void,
};

export type InterfaceMessageRef = {
	containsTarget: (target: Node | null) => boolean,
	getArrowRef: () => React.RefObject<HTMLDivElement>,
};



const InterfaceMessage = React.forwardRef<any, Props>((props, ref: React.Ref<InterfaceMessageRef>) => {
	const {
		arrowDirection = InterfaceMessageArrowDirection.Top,
		arrowStyles,
		arrowType,
		children,
		ctaElement,
		fullwidth,
		onCloseCallback,
	} = props;

	const arrowRef = React.useRef<HTMLDivElement | null>(null);
	const componentRef = React.useRef<HTMLDivElement | null>(null);

	React.useImperativeHandle(ref, () => ({
		containsTarget: (target) => {
			return componentRef.current?.contains(target) ?? false;
		},
		getArrowRef: () => arrowRef,
	}));

	const onCloseClickHandler = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			if (onCloseCallback) {
				onCloseCallback(e);
			}
		},
		[
			onCloseCallback,
		],
	);

	const componentClasses = classNames({
		'interface-message': true,
		'interface-message--full-width': fullwidth,
		['interface-message--arrow-' + arrowType + '-type']: arrowType,
		['interface-message--arrow-' + arrowDirection + '-direction']: arrowType && arrowDirection,
		'dark-bg-context': true,
	});

	return (
		<div
			className={componentClasses}
			ref={componentRef}
		>
			{arrowType && (
				<div
					className="interface-message__arrow"
					data-popper-arrow={true}
					ref={arrowRef}
					style={arrowStyles}
				/>
			)}
			<div className="interface-message__bubble">
				{onCloseCallback && (
					<a
						className="interface-message__close-button"
						href="#"
						onClick={onCloseClickHandler}
					>
						&times;
					</a>
				)}
				<div className="interface-message__content">
					{children}
				</div>
				{ctaElement && (
					<div className="interface-message__cta-element">
						{ctaElement}
					</div>
				)}
			</div>
		</div>
	);
});



export default InterfaceMessage;
