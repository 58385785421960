import scrollbarSize from 'dom-helpers/scrollbarSize';
import isObject from 'lodash/isObject';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeDataCell, {
	CodeDataCellType,
} from '~/components/patterns/tables/datatables/cells/CodeDataCell';
import CodeNumberCell, {
	CodeNumberCellType,
} from '~/components/patterns/tables/datatables/cells/CodeNumberCell';
import CodeTruncatedCell from './cells/CodeTruncatedCell';
import CodeValue, {
	CodeValueRobotsTxtHighlightType,
	CodeValueWrapping,
} from '~/components/patterns/values/CodeValue';
import CodeUnfoldableCell from '~/components/patterns/tables/datatables/cells/CodeUnfoldableCell';
import SimpleGrid from '~/components/patterns/tables/datatables/SimpleGrid';
import TextInspector from '~/components/patterns/typography/TextInspector';

import {
	isArray,
	isNumber,
	isString,
} from '~/utilities/typeCheck';



const CHARACTER_WIDTH = 7.401;


const codeHighlighting = {
	'comment': CodeValueRobotsTxtHighlightType.Comment,
	'crawl-delay': CodeValueRobotsTxtHighlightType.CrawlDelay,
	'sitemap': CodeValueRobotsTxtHighlightType.Sitemap,
	'user-agent': CodeValueRobotsTxtHighlightType.UserAgent,
};



const messages = defineMessages({
	hiddenLines: {
		id: 'ui.codeViewer.hiddenLines',
	},
	showLines: {
		id: 'ui.codeViewer.showLines',
	},
});



function collapseLinesToRows(lines: ReadonlyArray<Line>): Array<Line | Array<Line>> {
	const rows: Array<Line | Array<Line>> = [];

	lines.forEach((line) => {
		if (!isObject(line) || line.is_collapsed !== true) {
			rows.push(line);
		} else {
			const lastRow = rows[rows.length - 1];

			if (isArray(lastRow)) {
				lastRow.push(line);
			} else {
				rows.push([line]);
			}
		}
	});

	return rows;
}



type Line = (
	| {
		is_collapsed?: boolean,
		next_number?: number,
		number?: number,
		op?: string,
		parts: Array<{
			content: string,
			type: string,
		}>,
		previous_number?: number,
	}
	| string
);

type Props = {
	height: number,
	/**
	 * Amount of characters in the longest row.
	 * If this value is not set the longest row will be determined by iterating
	 * over all rows.
	 */
	longestLine?: number,
	lines: ReadonlyArray<Line>,
	/** If set lines will truncated at this point */
	truncateLineAt?: number,
	/** Popup to show when hovering on truncated ellipsis */
	truncatePopup?: React.ReactNode,
	width: number,
};

const CodeViewer: React.FC<Props> = (props) => {
	const {
		height,
		lines,
		truncateLineAt = Number.MAX_SAFE_INTEGER,
		longestLine = Number.MAX_SAFE_INTEGER - 1,
		truncatePopup,
		width,
	} = props;

	const hasTruncatedLines = longestLine > truncateLineAt;
	const longestTruncatedLine = hasTruncatedLines ? truncateLineAt : longestLine;

	const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);

	const [rows, setRows] = React.useState(collapseLinesToRows(lines));

	React.useLayoutEffect(
		() => {
			setRows(collapseLinesToRows(lines));
		},
		[
			lines,
		],
	);

	const rowCount = rows.length;

	const getRowHeight = React.useCallback(
		({ index }) => {
			if (index === rowCount) {
				return height - 40;
			}

			if (isArray(rows[index])) {
				return 40;
			}

			return 20;
		},
		[
			height,
			rowCount,
			rows,
		],
	);

	const getColumnWidth = React.useCallback(
		({ index }) => {
			const doubleGutter = lines.some((line) => {
				return isObject(line) && line.next_number !== undefined && line.previous_number !== undefined;
			});

			const gutterWidth = doubleGutter ? 100 : 50;
			const codeWidth = Math.max(width - gutterWidth - scrollbarSize(), Math.ceil(longestTruncatedLine * CHARACTER_WIDTH) + 30);
			const truncatedWidth = hasTruncatedLines ? 36 : 0;

			if (index === 0) {
				return gutterWidth;
			}

			if (index === 1) {
				return codeWidth;
			}

			if (index === 2) {
				return Math.max(truncatedWidth, width - codeWidth - gutterWidth);
			}

			return 0;
		},
		[
			hasTruncatedLines,
			lines,
			longestTruncatedLine,
			width,
		],
	);

	function renderCell({ columnIndex, key, rowIndex, style }): React.ReactNode {
		const row = rows[rowIndex];

		if (isArray(row)) {
			return (
				<CodeUnfoldableCell
					cssStyle={style}
					isCompact={columnIndex === 0}
					isEmpty={columnIndex > 1}
					isHovered={hoveredRow === rowIndex}
					key={key}
					onClickCallback={() => {
						setRows((rows) => {
							return [
								...rows.slice(0, rowIndex),
								...row,
								...rows.slice(rowIndex + 1),
							];
						});
					}}
					onMouseEnter={() => setHoveredRow(rowIndex)}
					onMouseLeave={() => setHoveredRow(null)}
					rowIndex={rowIndex}
					showMoreLabel={(
						<FormattedMessage {...messages.showLines} />
					)}
				>
					<FormattedMessage
						{...messages.hiddenLines}
						values={{ count: row.length }}
					/>
				</CodeUnfoldableCell>
			);
		}

		// Add extra empty space after the last row of code to enable scrolling past the
		// end of code. Like vscode and other code editors.
		if (row === undefined) {
			if (columnIndex === 0) {
				return (
					<CodeNumberCell
						cssStyle={style}
						key={key}
					/>
				);
			}

			return (
				<CodeDataCell
					cssStyle={style}
					key={key}
				/>
			);
		}

		const line = row;

		if (columnIndex === 0) {
			let lineNumber: number | Array<number> = rowIndex + 1;
			let type = CodeNumberCellType.Default;

			if (!isString(line)) {
				if (line.op === 'added') {
					type = CodeNumberCellType.New;
				} else if (line.op === 'removed') {
					type = CodeNumberCellType.Old;
				}

				if (isNumber(line.number)) {
					lineNumber = line.number;
				} else if (isNumber(line.next_number) && isNumber(line.previous_number)) {
					lineNumber = [line.next_number, line.previous_number];
				}
			}

			return (
				<CodeNumberCell
					cssStyle={style}
					key={key}
					type={type}
				>
					{lineNumber}
				</CodeNumberCell>
			);
		}

		if (columnIndex === 1) {
			let content: React.ReactNode;
			let type = CodeDataCellType.Default;

			if (isString(line)) {
				content = line.substring(0, truncateLineAt);
			} else {
				content = line.parts.reduce((line, part, index) => {
					const highlight = codeHighlighting[part.type];

					line.push(
						<CodeValue
							highlightType={highlight}
							key={index}
							wrapping={CodeValueWrapping.None}
						>
							<TextInspector text={part.content} />
						</CodeValue>,
					);

					return line;
				}, ([] as Array<React.ReactNode>));

				if (line.op === 'added') {
					type = CodeDataCellType.New;
				} else if (line.op === 'removed') {
					type = CodeDataCellType.Old;
				}
			}

			return (
				<CodeDataCell
					cssStyle={style}
					key={key}
					type={type}
				>
					{content}
				</CodeDataCell>
			);
		}

		if (columnIndex === 2) {
			let truncated = false;

			if (isString(line)) {
				truncated = line.length > truncateLineAt;
			}

			return (
				<CodeTruncatedCell
					cssStyle={style}
					key={key}
					popup={truncatePopup}
					truncated={truncated}
				/>
			);
		}

		return null;
	}

	return (
		<SimpleGrid
			cellRenderer={renderCell}
			columnCount={hasTruncatedLines ? 3 : 2}
			columnWidth={getColumnWidth}
			height={height}
			rowCount={rowCount + 1}
			rowHeight={getRowHeight}
			width={width}
		/>
	);
};



export default CodeViewer;
