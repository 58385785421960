import {
	format,
} from 'date-fns-tz';
import React from 'react';

import MissingValue from '~/components/app/MissingValue';



type Props = {
	timeZone?: string,
	value: Date | number | string | null,
};

const DateFormatter: React.FC<Props> = (props) => {
	const {
		timeZone,
		value = null,
	} = props;

	if (value === null) {
		return (
			<MissingValue ellipsis={true} />
		);
	}

	return format(value, 'd MMM yyyy', { timeZone });
};



export default React.memo(DateFormatter);
