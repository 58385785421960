/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserAgentsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type UserAgentsQuery = { readonly __typename?: 'Query', readonly userAgents: ReadonlyArray<{ readonly __typename?: 'UserAgent', readonly headerValue: string, readonly type: string }> };


export const UserAgentsDocument = gql`
    query UserAgents {
  userAgents {
    headerValue
    type
  }
}
    `;

/**
 * __useUserAgentsQuery__
 *
 * To run a query within a React component, call `useUserAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAgentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAgentsQuery(baseOptions?: Apollo.QueryHookOptions<UserAgentsQuery, UserAgentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAgentsQuery, UserAgentsQueryVariables>(UserAgentsDocument, options);
      }
export function useUserAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAgentsQuery, UserAgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAgentsQuery, UserAgentsQueryVariables>(UserAgentsDocument, options);
        }
export function useUserAgentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserAgentsQuery, UserAgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserAgentsQuery, UserAgentsQueryVariables>(UserAgentsDocument, options);
        }
export type UserAgentsQueryHookResult = ReturnType<typeof useUserAgentsQuery>;
export type UserAgentsLazyQueryHookResult = ReturnType<typeof useUserAgentsLazyQuery>;
export type UserAgentsSuspenseQueryHookResult = ReturnType<typeof useUserAgentsSuspenseQuery>;
export type UserAgentsQueryResult = Apollo.QueryResult<UserAgentsQuery, UserAgentsQueryVariables>;