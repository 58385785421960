import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import AlertPagesButton from '../AlertPagesButton';
import ExpressiveValue, {
	ExpressiveValueLabelFlow,
	ExpressiveValueSize,
	ExpressiveValueStatus,
} from '~/components/patterns/values/ExpressiveValue';
import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import RemovedFromAlertMetric from './RemovedFromAlertMetric';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';

import {
	SEGMENT_CHANGE_TYPE_PAGES_ENTERED,
	SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT,
	SEGMENT_CHANGE_TYPE_PAGES_LEFT,
	countPagesSplit,
} from '~/model/alerts';



const messages = defineMessages({
	entered: {
		id: 'ui.alerts.overview.incident.details.entered',
	},
	left: {
		id: 'ui.alerts.overview.incident.details.left',
	},
	reverted: {
		id: 'ui.alerts.overview.incident.details.reverted',
	},
});



class PageEnteredOrLeftSegmentDetails extends Component {

	render() {
		const {
			incident,
			websiteId,
		} = this.props;

		const isIncidentActive = incident.get('phase') === 'active';

		const numberOfEntered = countPagesSplit(incident, 'entered');

		const numberOfLeft = countPagesSplit(incident, 'left');

		const numberOfAffected = numberOfEntered + numberOfLeft;

		const numberOfReverted = (
			countPagesSplit(incident, 'entered_reverted')
			+ countPagesSplit(incident, 'left_reverted')
		);

		const numberOfRemovedFromAlert = (
			countPagesSplit(incident, 'over_quota')
		);

		const numberOfAlertPages = (
			numberOfAffected
			+ numberOfReverted
			+ numberOfRemovedFromAlert
		);

		const showEntered = (
			incident.get('settings').get('segmentChangeType') === SEGMENT_CHANGE_TYPE_PAGES_ENTERED
			|| incident.get('settings').get('segmentChangeType') === SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT
		);

		const showLeft = (
			incident.get('settings').get('segmentChangeType') === SEGMENT_CHANGE_TYPE_PAGES_LEFT
			|| incident.get('settings').get('segmentChangeType') === SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT
		);

		return (
			<IncidentBoxContent
				button={numberOfAlertPages > 0 && (
					<AlertPagesButton
						focusOnPrimary={isIncidentActive && numberOfAffected > 0}
						incident={incident}
						websiteId={websiteId}
					/>
				)}
			>
				<SeparatedBlocks divider={false}>
					{showEntered && (
						<ExpressiveValue
							label={(
								<FormattedMessage
									{...messages.entered}
									values={{
										count__pages: numberOfEntered,
									}}
								/>
							)}
							labelFlow={ExpressiveValueLabelFlow.Column}
							size={ExpressiveValueSize.Large}
							status={isIncidentActive ? ExpressiveValueStatus.Warning : ExpressiveValueStatus.Neutral}
							value={(
								<FormattedNumber value={numberOfEntered} />
							)}
						/>
					)}

					{showLeft && (
						<ExpressiveValue
							label={(
								<FormattedMessage
									{...messages.left}
									values={{
										count__pages: numberOfLeft,
									}}
								/>
							)}
							labelFlow={ExpressiveValueLabelFlow.Column}
							size={ExpressiveValueSize.Large}
							status={isIncidentActive ? ExpressiveValueStatus.Warning : ExpressiveValueStatus.Neutral}
							value={(
								<FormattedNumber value={numberOfLeft} />
							)}
						/>
					)}

					<ExpressiveValue
						label={(
							<FormattedMessage
								{...messages.reverted}
								values={{
									count__pages: numberOfReverted,
								}}
							/>
						)}
						labelFlow={ExpressiveValueLabelFlow.Column}
						size={ExpressiveValueSize.Large}
						value={(
							<FormattedNumber value={numberOfReverted} />
						)}
					/>
				</SeparatedBlocks>

				{numberOfRemovedFromAlert > 0 && (
					<RemovedFromAlertMetric
						numberOfRemovedFromAlert={numberOfRemovedFromAlert}
					/>
				)}
			</IncidentBoxContent>
		);
	}

}

PageEnteredOrLeftSegmentDetails.propTypes = {
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string,
};



export default PageEnteredOrLeftSegmentDetails;
