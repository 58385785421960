import React from 'react';

import PagesBrokenIcon from './builders/iconsData/pagesIssueStatusIcon/PagesBroken';
import PagesFixedIcon from './builders/iconsData/pagesIssueStatusIcon/PagesFixed';
import PagesIgnoringIcon from './builders/iconsData/pagesIssueStatusIcon/PagesIgnoring';

import InlineIcon from './builders/InlineIcon.part';

import {
	type Merge,
} from '~/types/utilities';



export enum PagesIssueStatusIconType {
	PagesBroken = 'pages-broken',
	PagesFixed = 'pages-fixed',
	PagesIgnoring = 'pages-ignoring',
}

const componentMapping = {
	[PagesIssueStatusIconType.PagesBroken]: PagesBrokenIcon,
	[PagesIssueStatusIconType.PagesFixed]: PagesFixedIcon,
	[PagesIssueStatusIconType.PagesIgnoring]: PagesIgnoringIcon,
} as const;



type BaseProps<IconType extends PagesIssueStatusIconType = PagesIssueStatusIconType> = {
	/** Square size of icon */
	size?: number,
	/** Type of icon */
	type: IconType,
};

type IconProps<IconType extends PagesIssueStatusIconType = PagesIssueStatusIconType> = (
	Parameters<typeof componentMapping[IconType]['icon']>[0]
);

type Props<IconType extends PagesIssueStatusIconType = PagesIssueStatusIconType> = (
	Merge<IconProps<IconType>, BaseProps<IconType>>
);

const PagesIssueStatusIcon = <IconType extends PagesIssueStatusIconType>(props: Props<IconType>): React.ReactElement => {
	const {
		type,
		size = 28,
		...rest
	} = props;

	const icon = componentMapping[type].icon({
		size,
		...rest,
	});

	return (
		<InlineIcon
			gap={false}
			icon={icon}
			size={size}
			viewBoxHeight={componentMapping[type].viewBoxHeight}
			viewBoxWidth={componentMapping[type].viewBoxWidth}
		/>
	);
};



export default PagesIssueStatusIcon;
