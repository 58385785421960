import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import IssueCardsList from '~/components/atoms/issues/components/lists/IssueCardsList';



const messages = defineMessages({
	'404NoIssues': {
		id: 'ui.pageDetail.type.404.noIssues',
	},
	'404NoIssuesAlways': {
		id: 'ui.pageDetail.type.404.noIssuesAlways',
	},
	'4xxNoIssues': {
		id: 'ui.pageDetail.type.4xx.noIssues',
	},
	'4xxNoIssuesAlways': {
		id: 'ui.pageDetail.type.4xx.noIssuesAlways',
	},
	'5xxNoIssuesAlways': {
		id: 'ui.pageDetail.type.5xx.noIssuesAlways',
	},
	'5xxNoIssues': {
		id: 'ui.pageDetail.type.5xx.noIssues',
	},
	'noDataNoIssues': {
		id: 'ui.pageDetail.type.noData.noIssues',
	},
	'redirectNoIssuesAlways': {
		id: 'ui.pageDetail.type.redirect.noIssuesAlways',
	},
	'redirectNoIssues': {
		id: 'ui.pageDetail.type.redirect.noIssues',
	},
	'unreachableNoIssues': {
		id: 'ui.pageDetail.type.unreachable.noIssues',
	},
});



type Props = {
	pageIsPristine: boolean | null,
	pageStatusCode: number | null,
	pageType: GraphQL.PageType,
};

const NoIssuesNotification: React.FC<Props> = (props) => {
	const {
		pageIsPristine,
		pageStatusCode,
		pageType,
	} = props;

	let errorStatus;

	if (pageType === GraphQL.PageType.ServerError) {
		if (pageIsPristine) {
			errorStatus = '5xxNoIssuesAlways';
		} else {
			errorStatus = '5xxNoIssues';
		}
	} else if (pageType === GraphQL.PageType.Unknown) {
		errorStatus = 'noDataNoIssues';
	} else if (pageType === GraphQL.PageType.Redirect) {
		if (pageIsPristine) {
			errorStatus = 'redirectNoIssuesAlways';
		} else {
			errorStatus = 'redirectNoIssues';
		}
	} else if (pageType === GraphQL.PageType.Missing) {
		if (pageIsPristine) {
			if (pageStatusCode === 404 || pageStatusCode === 410) {
				errorStatus = '404NoIssuesAlways';
			} else {
				errorStatus = '4xxNoIssuesAlways';
			}
		} else {
			if (pageStatusCode === 404 || pageStatusCode === 410) {
				errorStatus = '404NoIssues';
			} else {
				errorStatus = '4xxNoIssues';
			}
		}
	} else if (pageType === GraphQL.PageType.Unreachable) {
		errorStatus = 'unreachableNoIssues';
	} else {
		return null;
	}

	return (
		<IssueCardsList
			blankSlate={(
				<BlankSlate>
					<FormattedMessage {...messages[errorStatus]} />
				</BlankSlate>
			)}
		/>
	);
};



export default NoIssuesNotification;
