/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsiteOnPageRequestsWhitelistMutationVariables = GraphQL.Exact<{
  rules: ReadonlyArray<GraphQL.Scalars['Array']['input']> | GraphQL.Scalars['Array']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteOnPageRequestsWhitelistMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteOnPageRequestsWhitelist: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly onPageRequestsWhitelistRules: ReadonlyArray<{ readonly __typename?: 'OnPageRequestsBlockingRule', readonly operator: GraphQL.OnPageRequestsBlockingRuleOperator, readonly value: string }> } | null } | null } } };


export const UpdateWebsiteOnPageRequestsWhitelistDocument = gql`
    mutation UpdateWebsiteOnPageRequestsWhitelist($rules: [Array!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteOnPageRequestsWhitelist(rules: $rules, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        adminSettings {
          onPageRequestsWhitelistRules {
            operator
            value
          }
        }
        id
      }
    }
  }
}
    `;
export type UpdateWebsiteOnPageRequestsWhitelistMutationFn = Apollo.MutationFunction<UpdateWebsiteOnPageRequestsWhitelistMutation, UpdateWebsiteOnPageRequestsWhitelistMutationVariables>;

/**
 * __useUpdateWebsiteOnPageRequestsWhitelistMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteOnPageRequestsWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteOnPageRequestsWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteOnPageRequestsWhitelistMutation, { data, loading, error }] = useUpdateWebsiteOnPageRequestsWhitelistMutation({
 *   variables: {
 *      rules: // value for 'rules'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteOnPageRequestsWhitelistMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteOnPageRequestsWhitelistMutation, UpdateWebsiteOnPageRequestsWhitelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteOnPageRequestsWhitelistMutation, UpdateWebsiteOnPageRequestsWhitelistMutationVariables>(UpdateWebsiteOnPageRequestsWhitelistDocument, options);
      }
export type UpdateWebsiteOnPageRequestsWhitelistMutationHookResult = ReturnType<typeof useUpdateWebsiteOnPageRequestsWhitelistMutation>;
export type UpdateWebsiteOnPageRequestsWhitelistMutationResult = Apollo.MutationResult<UpdateWebsiteOnPageRequestsWhitelistMutation>;
export type UpdateWebsiteOnPageRequestsWhitelistMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteOnPageRequestsWhitelistMutation, UpdateWebsiteOnPageRequestsWhitelistMutationVariables>;