/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsitePageCapacityMutationVariables = GraphQL.Exact<{
  pageCapacity: GraphQL.Scalars['Int']['input'];
  threeDSecureToken: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsitePageCapacityMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsitePageCapacity: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageCapacity: number, readonly pageCapacityUsageDescription: GraphQL.WebsitePageCapacityUsageDescription, readonly pageCapacityUsageRatio: number, readonly problem: GraphQL.WebsiteProblem | null } | null } } };


export const UpdateWebsitePageCapacityDocument = gql`
    mutation UpdateWebsitePageCapacity($pageCapacity: Int!, $threeDSecureToken: String, $websiteId: WebsiteId!) {
  UpdateWebsitePageCapacity(
    pageCapacity: $pageCapacity
    threeDSecureToken: $threeDSecureToken
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        pageCapacity
        pageCapacityUsageDescription
        pageCapacityUsageRatio
        problem
      }
    }
  }
}
    `;
export type UpdateWebsitePageCapacityMutationFn = Apollo.MutationFunction<UpdateWebsitePageCapacityMutation, UpdateWebsitePageCapacityMutationVariables>;

/**
 * __useUpdateWebsitePageCapacityMutation__
 *
 * To run a mutation, you first call `useUpdateWebsitePageCapacityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsitePageCapacityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsitePageCapacityMutation, { data, loading, error }] = useUpdateWebsitePageCapacityMutation({
 *   variables: {
 *      pageCapacity: // value for 'pageCapacity'
 *      threeDSecureToken: // value for 'threeDSecureToken'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsitePageCapacityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsitePageCapacityMutation, UpdateWebsitePageCapacityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsitePageCapacityMutation, UpdateWebsitePageCapacityMutationVariables>(UpdateWebsitePageCapacityDocument, options);
      }
export type UpdateWebsitePageCapacityMutationHookResult = ReturnType<typeof useUpdateWebsitePageCapacityMutation>;
export type UpdateWebsitePageCapacityMutationResult = Apollo.MutationResult<UpdateWebsitePageCapacityMutation>;
export type UpdateWebsitePageCapacityMutationOptions = Apollo.BaseMutationOptions<UpdateWebsitePageCapacityMutation, UpdateWebsitePageCapacityMutationVariables>;