import React from 'react';

import BillingCycleName from '~/components/names/BillingCycleName';
import SwitchButtonsField from '~/components/atoms/forms/components/SwitchButtonsField';

import {
	BillingCycleByDuration,
} from '~/model/pricing/billingCycle';



const BillingCycleField = (props) => {
	const {
		allowedBillingCycles,
		name,
	} = props;

	const billingCycles = BillingCycleByDuration.filter((billingCycle) => {
		return allowedBillingCycles.includes(billingCycle);
	});

	return (
		<SwitchButtonsField
			items={billingCycles.map((billingCycle) => {
				return {
					label: (
						<BillingCycleName billingCycle={billingCycle} />
					),
					value: billingCycle,
				};
			})}
			name={name}
		/>
	);
};



export default BillingCycleField;
