import React from 'react';
import {
	defineMessages,
} from 'react-intl';
import {
	useStripe,
} from '@stripe/react-stripe-js';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormWrapperContainer, {
	FormWrapperContainerSize,
} from '~/components/patterns/forms/wrappers/FormWrapperContainer';
import PaymentAnnouncement from '~/components/patterns/messages/embedded/PaymentAnnouncement';
import SubmitButton from '~/components/app/SubmitButton';

import useLoadPaymentStatus from '~/hooks/useLoadPaymentStatus';



const messages = defineMessages({
	button: {
		id: 'ui.incomplete3DSecureChallengeBanner.button',
	},
	content: {
		id: 'ui.incomplete3DSecureChallengeBanner.content',
	},
	title: {
		id: 'ui.incomplete3DSecureChallengeBanner.title',
	},
});



type Props = {
	accountId: CK.AccountId,
	clientSecret: string,
	paymentMethod: string,
};

const Incomplete3DSecureChallengeBanner: React.FC<Props> = (props) => {
	const {
		accountId,
		clientSecret,
		paymentMethod,
	} = props;

	const loadPaymentStatus = useLoadPaymentStatus();
	const stripe = useStripe();

	const handleVerifyPayment = React.useCallback(
		async () => {
			if (stripe === null) {
				return;
			}

			const result = await stripe.confirmCardPayment(clientSecret, {
				payment_method: paymentMethod,
			});

			if (result.error !== undefined) {
				return;
			}

			return loadPaymentStatus(accountId);
		},
		[
			accountId,
			clientSecret,
			loadPaymentStatus,
			paymentMethod,
			stripe,
		],
	);

	return (
		<FormWrapperContainer size={FormWrapperContainerSize.Form}>
			<Form
				defaultDataHasChanged={true}
				onSuccess={handleVerifyPayment}
			>
				<PaymentAnnouncement
					ctaElement={(
						<SubmitButton>
							<Copy {...messages.button} />
						</SubmitButton>
					)}
					title={(
						<Copy {...messages.title} />
					)}
				>
					<Copy {...messages.content} />
				</PaymentAnnouncement>
			</Form>
		</FormWrapperContainer>
	);
};



export default Incomplete3DSecureChallengeBanner;
