import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import BackLink from '~/components/patterns/links/BackLink';
import CustomElementsOverview from '~/components/app/CustomElementsOverview';
import EnrichmentFieldsOverview from '~/components/app/EnrichmentFieldsOverview';
import FormsList from '~/components/atoms/lists/FormsList';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderTitleWithValue from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import LogFileAnalysisSettingsGroup from '~/components/app/LogFileAnalysisSettingsGroup';
import MembersReportsAlertsForm from '~/components/atoms/forms/forms/MembersReportsAlertsForm';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScrollableContentScreen from '~/components/logic/layouts/screens/ScrollableContentScreen';
import Sections from '~/components/logic/lego/Sections';
import SegmentDefinitionsOverview from '~/components/app/SegmentDefinitionsOverview';

import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';
import WebsiteDetailContextActions from '~/components/app/WebsiteDetailContextActions';
import WebsiteDetailScreenAdminSection from '~/components/app/WebsiteDetailScreenAdminSection';
import WebsiteDetailScreenNonPagesSection from '~/components/app/WebsiteDetailScreenNonPagesSection';
import WebsiteDetailsGroup from '~/components/app/WebsiteDetailsGroup';
import WebsiteIntegrationsGroup from '~/components/app/WebsiteIntegrationsGroup';
import WebsiteMonitoringGroup from '~/components/app/WebsiteMonitoringGroup';
import WebsiteSettingsIndexNowGroup from '~/components/app/WebsiteSettingsIndexNowGroup';
import WebsiteStatus from '~/components/app/WebsiteStatus';

import {
	useWebsiteDetailScreenQuery,
} from './WebsiteDetailScreen.gql';

import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteHasConflictingGoogleAnalyticsDependencies from '~/hooks/useWebsiteHasConflictingGoogleAnalyticsDependencies';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';

import {
	usePrefetch as useUserAgentHeaderPrefetch,
} from '~/components/app/UserAgentHeaderValue';

import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';

import {
	currentCrawlingSpeedSelector,
} from '~/state/crawlingSpeed/selectors';

import {
	selectedWebsiteDetailSelector,
} from '~/state/ui/selectors';



enum Section {
	Admin = 'admin',
	CustomElements = 'customElements',
	Details = 'websiteDetails',
	EmailSettings = 'emailsSettings',
	EnrichmentFields = 'enrichmentFields',
	Integrations = 'integrations',
	LogFileAnalysis = 'logFileAnalysis',
	Monitoring = 'monitoring',
	NonPages = 'nonPages',
	IndexNow = 'indexNow',
	Segments = 'segments',
}

const SectionSorting = [
	Section.Details,
	Section.Segments,
	Section.EnrichmentFields,
	Section.CustomElements,
	Section.EmailSettings,
	Section.Integrations,
	Section.LogFileAnalysis,
	Section.IndexNow,
	Section.Monitoring,
	Section.Admin,
	Section.NonPages,
];

const SectionRoutes = {
	[Section.Admin]: 'website.detail.admin',
	[Section.CustomElements]: 'website.detail.customElements',
	[Section.Details]: 'website.detail',
	[Section.EmailSettings]: 'website.detail.emailsSettings',
	[Section.EnrichmentFields]: 'website.detail.enrichmentFields',
	[Section.IndexNow]: 'website.detail.indexNow',
	[Section.Integrations]: 'website.detail.integrations',
	[Section.LogFileAnalysis]: 'website.detail.logFileAnalysis',
	[Section.Monitoring]: 'website.detail.monitoring',
	[Section.NonPages]: 'website.detail.nonPages',
	[Section.Segments]: 'website.detail.segments',
};



const messages = defineMessages({
	[Section.Admin]: {
		id: 'ui.websites.formGroups.admin',
	},
	[Section.CustomElements]: {
		id: 'ui.websites.formGroups.customElements',
	},
	[Section.Details]: {
		id: 'ui.websites.formGroups.websiteDetails',
	},
	[Section.EmailSettings]: {
		id: 'ui.websites.formGroups.emailSettings',
	},
	[Section.EnrichmentFields]: {
		id: 'ui.websites.formGroups.enrichmentFields',
	},
	[Section.Integrations]: {
		id: 'ui.websites.formGroups.integrations',
	},
	[Section.Monitoring]: {
		id: 'ui.websites.formGroups.websiteMonitoring',
	},
	[Section.NonPages]: {
		id: 'ui.websites.formGroups.nonPages',
	},
	[Section.LogFileAnalysis]: {
		id: 'ui.websites.formGroups.logFileAnalysis',
	},
	[Section.IndexNow]: {
		id: 'ui.websites.formGroups.indexNow',
	},
	[Section.Segments]: {
		id: 'ui.websites.formGroups.segments',
	},
	backToWebsites: {
		id: 'ui.websiteDetail.backToWebsites',
	},
	title: {
		id: 'ui.websiteDetail.title',
	},
});



const WebsiteDetailScreen = (props) => {
	const {
		backlink,
		closeCallback,
	} = props;

	const websiteId = useWebsiteId();

	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const { data } = useWebsiteDetailScreenQuery({
		variables: {
			websiteId,
		},
	});

	const isAdobeAnalyticsCredentialsExpired = (
		data?.website?.adobeAnalyticsIntegration?.adobeAnalyticsAccount.status === GraphQL.AdobeAnalyticsAccountStatus.Expired
	);

	const crawlingSpeed = useSelector(currentCrawlingSpeedSelector);
	const websiteDetail = useSelector(selectedWebsiteDetailSelector);
	const websiteDisplayName = useWebsiteDisplayName(websiteId);
	const websiteIntegrations = useWebsiteIntegrations(websiteId);
	const hasConflictingGoogleAnalyticsDependencies = useWebsiteHasConflictingGoogleAnalyticsDependencies(websiteId);
	const {
		isMissingLogSource: logFileAnalysisIsMissingLogSource,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	useUserAgentHeaderPrefetch();

	const {
		isFeatureAttainable: isCustomElementsFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.CustomElements_5);

	const {
		isFeatureAttainable: isEnrichmentFieldsFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.EnrichmentFields_5);

	const {
		isFeatureAttainable: isIndexNowFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.IndexNow);

	const {
		isFeatureAttainable: isLogFileAnalysisFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const sections = React.useMemo(
		() => {
			return SectionSorting.filter(
				(section) => {
					if (section === Section.Admin) {
						return kingdomAdminFeatures.areVisible;
					}

					if (section === Section.CustomElements) {
						return isCustomElementsFeatureAttainable;
					}

					if (section === Section.EnrichmentFields) {
						return isEnrichmentFieldsFeatureAttainable;
					}

					if (section === Section.IndexNow) {
						return isIndexNowFeatureAttainable;
					}

					if (section === Section.LogFileAnalysis) {
						return isLogFileAnalysisFeatureAttainable;
					}

					if (section === Section.NonPages) {
						return kingdomAdminFeatures.areVisible;
					}

					return true;
				},
			);
		},
		[
			isCustomElementsFeatureAttainable,
			isEnrichmentFieldsFeatureAttainable,
			isIndexNowFeatureAttainable,
			isLogFileAnalysisFeatureAttainable,
			kingdomAdminFeatures,
		],
	);

	const renderSectionContent = React.useCallback(
		(section) => {
			switch (section) {

				case Section.Admin:
					return (
						<WebsiteDetailScreenAdminSection />
					);

				case Section.EmailSettings:
					return (
						<FormsList>
							<MembersReportsAlertsForm
								websiteId={websiteId}
							/>
						</FormsList>
					);

				case Section.CustomElements:
					return (
						<FormsList>
							<CustomElementsOverview />
						</FormsList>
					);

				case Section.EnrichmentFields:
					return (
						<FormsList>
							<EnrichmentFieldsOverview />
						</FormsList>
					);

				case Section.IndexNow:
					return (
						<WebsiteSettingsIndexNowGroup />
					);

				case Section.Integrations:
					return (
						<WebsiteIntegrationsGroup />
					);

				case Section.Details:
					return (
						<WebsiteDetailsGroup />
					);

				case Section.Monitoring:
					if (!websiteDetail || !crawlingSpeed) {
						return null;
					}

					return (
						<WebsiteMonitoringGroup
							crawlingSpeed={crawlingSpeed}
							websiteDetail={websiteDetail}
						/>
					);

				case Section.NonPages:
					return (
						<WebsiteDetailScreenNonPagesSection
							websiteId={websiteId}
						/>
					);

				case Section.LogFileAnalysis:
					return (
						<LogFileAnalysisSettingsGroup />
					);

				case Section.Segments:
					return (
						<FormsList>
							<SegmentDefinitionsOverview />
						</FormsList>
					);

			}
		},
		[
			crawlingSpeed,
			websiteDetail,
			websiteId,
		],
	);

	const renderSectionTitle = React.useCallback(
		(section) => {
			const result = (
				<FormattedMessage {...messages[section]} />
			);

			if (section === Section.Details) {
				return (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<WebsiteStatus
								showStatus={[GraphQL.WebsiteProblem.NearCapacity, GraphQL.WebsiteProblem.CapacityReached]}
								websiteId={websiteId}
							/>
						)}
						iconPosition={AttachedIconPosition.Suffix}
					>
						{result}
					</AttachedIcon>
				);
			} else if (section === Section.Monitoring) {
				return (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<WebsiteStatus
								showStatus={[GraphQL.WebsiteProblem.Paused]}
								websiteId={websiteId}
							/>
						)}
						iconPosition={AttachedIconPosition.Suffix}
					>
						{result}
					</AttachedIcon>
				);
			}

			let statusFlag: StatusFlagStatus | null = null;

			if (
				section === Section.Integrations
				&& websiteIntegrations.isEnabled(GraphQL.WebsiteIntegrationType.GoogleAnalytics)
				&& hasConflictingGoogleAnalyticsDependencies
			) {
				statusFlag = StatusFlagStatus.Critical;
			}

			if (
				section === Section.Integrations
				&& isAdobeAnalyticsCredentialsExpired
			) {
				statusFlag = StatusFlagStatus.Critical;
			}

			if (
				section === Section.LogFileAnalysis
				&& logFileAnalysisIsMissingLogSource
			) {
				statusFlag = StatusFlagStatus.Critical;
			}

			if (statusFlag !== null) {
				return (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<StatusFlag status={StatusFlagStatus.Critical} />
						)}
						iconPosition={AttachedIconPosition.Suffix}
					>
						{result}
					</AttachedIcon>
				);
			}

			return result;
		},
		[
			hasConflictingGoogleAnalyticsDependencies,
			isAdobeAnalyticsCredentialsExpired,
			logFileAnalysisIsMissingLogSource,
			websiteId,
			websiteIntegrations,
		],
	);

	return (
		<ScrollableContentScreen
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						{backlink === 'account.websites' && (
							<BackLink
								label={(
									<FormattedMessage {...messages.backToWebsites} />
								)}
								onClickCallback={closeCallback}
							/>
						)}

						<HeaderTitleWithValue
							title={(
								<FormattedMessage {...messages.title} />
							)}
							value={websiteDisplayName}
						/>
					</HeaderTitleLayout>
				</ScreenHeader>
			)}
			key={websiteId}
			withPadding={false}
		>
			<Sections
				actions={(
					<WebsiteDetailContextActions />
				)}
				getSectionRoute={(section) => SectionRoutes[section]}
				renderSectionContent={renderSectionContent}
				renderSectionTitle={renderSectionTitle}
				sections={sections}
			/>
		</ScrollableContentScreen>
	);
};



export default WebsiteDetailScreen;
