import {
	captureException,
	getCurrentScope,
	withScope,
} from '@sentry/browser';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
	injectIntl,
} from 'react-intl';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '../patterns/buttons/Button';
import ErrorAnnouncement from '~/components/patterns/messages/embedded/ErrorAnnouncement';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';

import {
	urlStateSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	linkLabel: {
		id: 'ui.mainError.linkLabel',
		defaultMessage: 'Or return to dashboard',
	},
	message: {
		id: 'ui.mainError.message',
		defaultMessage: 'We\'ve registered the problem and will try to fix it as soon as possible.',
	},
	reloadButtonLabel: {
		id: 'ui.mainError.reloadButtonLabel',
		defaultMessage: 'Reload',
	},
	title: {
		id: 'ui.mainError.title',
		defaultMessage: 'Whoops! We just had a technical error, sorry about that!',
	},
});



const select = createSelector(
	urlStateSelector,
	(
		urlState,
	) => ({
		urlState,
	}),
);



class MainErrorBoundary extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleHomepageRedirect = this._handleHomepageRedirect.bind(this);
		this._handlePageReload = this._handlePageReload.bind(this);

		this.state = {
			error: null,
			errorEventId: null,
		};
	}



	_handleHomepageRedirect(e) {
		e.preventDefault();

		// redirect to homepage with reload of whole screen!
		window.location.href = '/';
	}



	_handlePageReload(e) {
		e.preventDefault();

		location.reload();
	}



	componentDidUpdate(prevProps) {
		const {
			urlState: nextUrlState,
		} = this.props;

		const {
			urlState: prevUrlState,
		} = prevProps;

		if (prevUrlState.name !== nextUrlState.name) {
			this.setState({
				error: null,
				errorEventId: null,
			});
		}
	}



	componentDidCatch(error, errorInfo) {
		this.setState({
			error,
		});

		withScope((scope) => {
			scope.setExtras(errorInfo);
			scope.setTag('impact', 'main_error_screen');

			const errorEventId = captureException(error);

			this.setState({
				errorEventId,
			});
		});

		getCurrentScope().setTag('money_stuff', undefined);
	}



	render() {
		const {
			intl,
		} = this.props;

		const {
			error,
			errorEventId,
		} = this.state;

		if (!error) {
			if (this.props.children) {
				return React.cloneElement(this.props.children, this.props);
			}

			return false;
		}

		const content = errorEventId
			? (
				<span>
					{intl.formatMessage(messages.message)}
					<br />
					Error ID: {errorEventId}
				</span>
			)
			: intl.formatMessage(messages.message);

		return (
			<ScreenLayout>
				<ErrorAnnouncement
					button={(
						<Button
							onClick={this._handlePageReload}
							size={ButtonSize.XXLarge}
							style={ButtonStyle.Action}
						>
							<FormattedMessage {...messages.reloadButtonLabel} />
						</Button>
					)}
					footer={(
						<Button
							onClick={this._handleHomepageRedirect}
							size={ButtonSize.Large}
							style={ButtonStyle.Link}
						>
							<FormattedMessage {...messages.linkLabel} />
						</Button>
					)}
					title={intl.formatMessage(messages.title)}
				>
					{content}
				</ErrorAnnouncement>
			</ScreenLayout>
		);
	}

}

MainErrorBoundary.propTypes = {
};



export default connect(select)(injectIntl(MainErrorBoundary));
