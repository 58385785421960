import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const FashionIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M7.459,7.8a.5.5,0,0,0,.417.427A25.073,25.073,0,0,0,12,8.581a25.083,25.083,0,0,0,4.124-.354.5.5,0,0,0,.417-.427c.045-.336.046-.284,1.142-6.333a1,1,0,0,0-.748-1.15L15.751.03a1,1,0,0,0-1.173.626l-.65,2a.5.5,0,0,1-.471.346l-2.894.029a.5.5,0,0,1-.48-.345L9.409.621A1,1,0,0,0,8.248.03L7.065.317a1,1,0,0,0-.748,1.15C7.5,8,7.417,7.483,7.459,7.8Z"
				fill={color}
			/>
			<path
				d="M20.92,20.51l-.015-.039L16.878,11.3c-.077-.225-.144-.457-.2-.692a.5.5,0,0,0-.566-.378A25.108,25.108,0,0,1,12,10.584a24.966,24.966,0,0,1-4.11-.352.5.5,0,0,0-.566.379c-.052.223-.115.441-.187.652L3.08,20.51a1.487,1.487,0,0,0,.809,1.839A21.312,21.312,0,0,0,12,24a21.312,21.312,0,0,0,8.111-1.651A1.487,1.487,0,0,0,20.92,20.51Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default FashionIconBuilder;
