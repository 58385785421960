import {
	useSelector,
} from 'react-redux';

import useProjectUrlParams from '~/hooks/useProjectUrlParams';

import {
	createAffectedPagesFilterParameter,
} from '~/model/affectedPages';

import {
	createAffectedPagesComparisonFilterParameter,
} from '~/model/affectedPagesComparison';

import {
	getAbsoluteCategoryType,
	getAbsoluteIssueType,
} from '~/model/issues/identifierMapping';

import {
	currentFilterSelector as affectedPagesFilterSelector,
	sortBySelector as affectedPagesSortBySelector,
} from '~/state/affectedPages/selectors';

import {
	currentFilterSelector as affectedPagesComparisonFilterSelector,
	sortBySelector as affectedPagesComparisonSortBySelector,
} from '~/state/affectedPagesComparison/selectors';



const useProjectAffectedPagesUrl = (): void => {
	const affectedPagesComparisonFilter = useSelector(affectedPagesComparisonFilterSelector);
	const affectedPagesComparisonSortBy = useSelector(affectedPagesComparisonSortBySelector);
	const affectedPagesFilter = useSelector(affectedPagesFilterSelector);
	const affectedPagesSortBy = useSelector(affectedPagesSortBySelector);

	useProjectUrlParams(
		(urlState) => {
			if (
				urlState.name !== 'website.issues.issueCategory.affectedPages'
				&& urlState.name !== 'website.issues.issueCategory.singleIssueAffectedPages'
			) {
				return;
			}

			if (urlState.params.start_date && urlState.params.end_date) {
				return {
					end_date: urlState.params.end_date,
					filter: createAffectedPagesComparisonFilterParameter(
						affectedPagesComparisonFilter,
						affectedPagesComparisonSortBy,
						getAbsoluteCategoryType(urlState.params.issuesOverviewCategory),
						getAbsoluteIssueType(
							urlState.params.issuesOverviewCategory,
							urlState.params.issuesOverviewIssue,
						),
					),
					issuesOverviewCategory: urlState.params.issuesOverviewCategory,
					issuesOverviewIssue: urlState.params.issuesOverviewIssue,
					start_date: urlState.params.start_date,
					websiteId: urlState.params.websiteId,
				};
			}

			return {
				filter: createAffectedPagesFilterParameter(
					affectedPagesFilter,
					affectedPagesSortBy,
					getAbsoluteCategoryType(urlState.params.issuesOverviewCategory),
					getAbsoluteIssueType(
						urlState.params.issuesOverviewCategory,
						urlState.params.issuesOverviewIssue,
					),
				),
				issuesOverviewCategory: urlState.params.issuesOverviewCategory,
				issuesOverviewIssue: urlState.params.issuesOverviewIssue,
				websiteId: urlState.params.websiteId,
			};
		},
		[
			affectedPagesComparisonFilter,
			affectedPagesComparisonSortBy,
			affectedPagesFilter,
			affectedPagesSortBy,
		],
	);
};



export default useProjectAffectedPagesUrl;
