import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';

import {
	packRobotsTxtRevisionsParameter,
} from '~/model/robotsTxt';



const messages = defineMessages({
	seeChangesButton: {
		id: 'ui.alerts.overview.incident.followup.viewChanges',
	},
});



class RobotsTxtDiffButton extends Component {

	render() {
		const {
			nextRevision,
			previousRevision,
			websiteId,
		} = this.props;

		return (
			<Button
				linkRouteName="website.platform.robotsTxtComparison"
				linkRouteParams={{
					revisions: packRobotsTxtRevisionsParameter(
						previousRevision,
						nextRevision,
					),
					websiteId,
				}}
				size={ButtonSize.Small}
				style={ButtonStyle.Hollow}
			>
				<FormattedMessage {...messages.seeChangesButton} />
			</Button>
		);
	}

}

RobotsTxtDiffButton.propTypes = {
	nextRevision: PropTypes.string.isRequired,
	previousRevision: PropTypes.string.isRequired,
	websiteId: PropTypes.string.isRequired,
};



export default RobotsTxtDiffButton;
