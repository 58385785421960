import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SocialIconBuilder: IconBuilder = {
	icon: ({ color = '#EB67A9' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				opacity="0.796875"
				stroke="none"
				strokeWidth="1"
			>
				<g id="share-copy">
					<path
						d="M12.16,15.32 C12.16,17.2640457 10.5840457,18.84 8.64,18.84 C6.69595429,18.84 5.12,17.2640457 5.12,15.32 C5.12,13.3759543 6.69595429,11.8 8.64,11.8 C10.5840457,11.8 12.16,13.3759543 12.16,15.32 L12.16,15.32 Z"
						id="Stroke-92"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M26.88,8.28 C26.88,10.2240457 25.3040457,11.8 23.36,11.8 C21.4159543,11.8 19.84,10.2240457 19.84,8.28 C19.84,6.33595429 21.4159543,4.76 23.36,4.76 C25.3040457,4.76 26.88,6.33595429 26.88,8.28 L26.88,8.28 Z"
						id="Stroke-93"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M23.36,27.16 C21.4159543,27.16 19.84,25.5840457 19.84,23.64 C19.84,21.6959543 21.4159543,20.12 23.36,20.12 C25.3040457,20.12 26.88,21.6959543 26.88,23.64 C26.88,25.5840457 25.3040457,27.16 23.36,27.16 Z"
						id="Stroke-94"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M11.52,13.72 L19.84,9.88"
						id="Stroke-95"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M11.52,17.56 L19.8748096,21.9082281"
						id="Stroke-96"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default SocialIconBuilder;
