import React from 'react';

import GraphQL from '~/types/graphql';

import AnimateHeartbeat from '~/components/logic/score/AnimateHeartbeat';
import IssueCaseDescription from '~/components/logic/issues/IssueCaseDescription';
import IssueIllustration, {
	type IssueIllustrationChildrenProps,
} from '~/components/atoms/issues/components/issueDetail/IssueIllustration';
import IssueInstruction from '~/components/logic/issues/IssueInstruction';
import IssueLayout, {
	STATE_BROKEN as ISSUE_LAYOUT_STATE_BROKEN,
	STATE_CLOSED as ISSUE_LAYOUT_STATE_CLOSED,
	STATE_IGNORED as ISSUE_LAYOUT_STATE_IGNORED,
	STATE_NOT_REQUIRED as ISSUE_LAYOUT_STATE_NOT_REQUIRED,
	STATE_UNKNOWN as ISSUE_LAYOUT_STATE_UNKNOWN,
} from '~/components/atoms/issues/components/issueDetail/IssueLayout';
import IssueScore, {
	IssueScoreSize,
	IssueScoreState,
} from '~/components/patterns/issues/IssueScore';
import IssueText from '~/components/atoms/issues/components/issueDetail/IssueText';
import IssueTitle from '~/components/logic/issues/IssueTitle';

import {
	type IssueName,
	getPointsToBeDisplayed,
	isRecognizedIssue,
} from '~/model/issuesNew';

import matchAndReturn from '~/utilities/matchAndReturn';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	issue: {
		configuration: Record<string, any>,
		context: any,
		followupIssues?: ReadonlyArray<{
			configuration: Record<string, any>,
			isConfigurationModified: boolean,
			name: string,
		}>,
		isActionable: boolean,
		isConfigurationModified: boolean,
		isIgnored: boolean,
		name: IssueName,
		pointsGained: number,
		pointsToGain: number,
		state: GraphQL.IssueState,
	},
	renderCaseIllustration: () => RenderProp<IssueIllustrationChildrenProps>,
	renderIgnoringButton: RenderProp<{}>,
};

const IssueDetail: React.FC<Props> = (props) => {
	const {
		issue,
		renderCaseIllustration,
		renderIgnoringButton,
	} = props;

	function getIssueLayoutState() {
		if (issue.isIgnored) {
			return ISSUE_LAYOUT_STATE_IGNORED;
		}

		if (issue.state === GraphQL.IssueState.NotApplicable) {
			return ISSUE_LAYOUT_STATE_NOT_REQUIRED;
		}

		if (issue.state === GraphQL.IssueState.NotRequired) {
			return ISSUE_LAYOUT_STATE_NOT_REQUIRED;
		}

		if (issue.state === GraphQL.IssueState.Closed) {
			return ISSUE_LAYOUT_STATE_CLOSED;
		}

		if (issue.state === GraphQL.IssueState.Unknown) {
			return ISSUE_LAYOUT_STATE_UNKNOWN;
		}

		return ISSUE_LAYOUT_STATE_BROKEN;
	}

	const caseIllustration = issue.isActionable
		? renderCaseIllustration()
		: null;

	const ignoringButton = renderProp(renderIgnoringButton, {});

	return (
		<IssueLayout
			actionElements={(
				<AnimateHeartbeat
					enabled={issue.isActionable}
				>
					<IssueScore
						isIgnored={issue.isIgnored}
						key={issue.name}
						size={IssueScoreSize.Small}
						state={matchAndReturn(issue.state, {
							[GraphQL.IssueState.Closed]: IssueScoreState.Fixed,
							[GraphQL.IssueState.NotApplicable]: IssueScoreState.NotApplicable,
							[GraphQL.IssueState.NotRequired]: IssueScoreState.NotRequired,
							[GraphQL.IssueState.Open]: IssueScoreState.Broken,
							[GraphQL.IssueState.Unknown]: IssueScoreState.Unknown,
						})}
						value={getPointsToBeDisplayed(issue)}
					/>
				</AnimateHeartbeat>
			)}
			ignoreLink={ignoringButton}
			state={getIssueLayoutState()}
			title={(
				<IssueTitle
					issue={issue}
				/>
			)}
		>
			{caseIllustration && (
				<IssueText>
					<IssueCaseDescription
						issue={issue}
					/>
				</IssueText>
			)}

			{caseIllustration && (
				<IssueIllustration>
					{caseIllustration}
				</IssueIllustration>
			)}

			<IssueInstruction
				issue={issue}
			/>

			{issue.state !== GraphQL.IssueState.NotApplicable && issue.state !== GraphQL.IssueState.NotRequired && issue.followupIssues !== undefined && issue.followupIssues.filter(isRecognizedIssue).map((followupIssue) => (
				<IssueInstruction
					issue={followupIssue}
					key={followupIssue.name}
				/>
			))}
		</IssueLayout>
	);
};



export default IssueDetail;
