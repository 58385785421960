import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SupportIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm9.428,8.666A.25.25,0,0,1,21.193,9H17.337a.253.253,0,0,1-.214-.12,6.05,6.05,0,0,0-2-2A.253.253,0,0,1,15,6.664V2.808a.251.251,0,0,1,.334-.236A10.051,10.051,0,0,1,21.428,8.667ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16ZM8.666,2.572A.251.251,0,0,1,9,2.808V6.664a.251.251,0,0,1-.12.214,6.05,6.05,0,0,0-2,2A.253.253,0,0,1,6.663,9H2.807A.251.251,0,0,1,2.6,8.9a.247.247,0,0,1-.031-.228A10.051,10.051,0,0,1,8.666,2.572ZM2.572,15.334A.249.249,0,0,1,2.807,15H6.663a.251.251,0,0,1,.214.12,6.05,6.05,0,0,0,2,2,.252.252,0,0,1,.12.214v3.856a.25.25,0,0,1-.334.235A10.051,10.051,0,0,1,2.572,15.334Zm12.762,6.095A.25.25,0,0,1,15,21.194V17.338a.251.251,0,0,1,.12-.214,6.05,6.05,0,0,0,2-2,.251.251,0,0,1,.214-.12h3.856a.249.249,0,0,1,.2.106.245.245,0,0,1,.031.227A10.051,10.051,0,0,1,15.334,21.429Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SupportIconBuilder;

