import {
	useUserLocaleQuery,
} from './useUserLocale.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserLocale(legacyUserId: string | null): string | null {
	const { data } = useUserPropertiesQuery(
		useUserLocaleQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return (data?.user?.locale) ?? null;
}



export default useUserLocale;
