import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SummaryIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g>
				<polyline
					fill="none"
					points="8.2,7.3 5.3,7.3 5.3,20.6 18.6,20.6 18.6,7.3 15.7,7.3 "
					stroke={color}
					strokeLinejoin="round"
				/>
				<rect
					fill="none"
					height="2.8"
					stroke={color}
					strokeLinejoin="round"
					width="7.6"
					x="8.2"
					y="5.9"
				/>
				<path
					d="M13.6,5.9c0.2-0.3,0.3-0.6,0.3-0.9c0-1-0.8-1.9-1.9-1.9
					S10.1,3.9,10.1,5c0,0.3,0.1,0.7,0.3,0.9"
					fill="none"
					stroke={color}
					strokeLinejoin="round"
				/>
				<line
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					x1="7.9"
					x2="15.7"
					y1="11.5"
					y2="11.5"
				/>
				<line
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					x1="7.9"
					x2="15.7"
					y1="17.2"
					y2="17.2"
				/>
				<line
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					x1="7.9"
					x2="15.7"
					y1="14.4"
					y2="14.4"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SummaryIconBuilder;

