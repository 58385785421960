import {
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import React from 'react';
import {
	createPortal,
} from 'react-dom';
import {
	type VirtualItem,
	useVirtual,
} from 'react-virtual';

import CellDragHandler from '~/components/patterns/tables/datatables/cells/CellDragHandler';
import {
	type ContextMenuEntry,
} from '~/components/ContextMenuProvider';
import DataCell, {
	DataCellAlignment,
	DataCellColorStyle,
} from '~/components/atoms/dataTables/DataCell';
import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableOverlay, {
	DatatableOverlayStyle,
} from '~/components/patterns/tables/datatables/DatatableOverlay';
import FilterFieldLayout from '~/components/patterns/filtering/FilterFieldLayout';
import Form from '~/components/atoms/forms/basis/Form';
import HeaderCell, {
	HeaderCellAlignment,
	HeaderCellColorStyle,
	HeaderCellPadding,
} from '~/components/atoms/dataTables/HeaderCell';
import HeaderLabel from '~/components/atoms/dataTables/HeaderLabel';
import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StickToScreenBottom, {
	StickToScreenBottomPreset,
} from '~/components/patterns/utils/StickToScreenBottom';

import useContextMenu from '~/hooks/useContextMenu';
import useDatatableContext from '~/hooks/useDatatableContext';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import matchAndReturn from '~/utilities/matchAndReturn';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import {
	horizontalScrollTo,
} from '~/utilities/scrollTo';

import {
	isInteger,
	isString,
} from '~/utilities/typeCheck';



const Context = React.createContext<{
	datatableFilter: any,
	datatableHeaderHeight: number,
	datatableHeight: number,
	isScrollableHorizontally: boolean,
	isUserUpdatingFilter: boolean,
	scrollbarSize: number,
}>({
	datatableFilter: null,
	datatableHeaderHeight: 0,
	datatableHeight: 0,
	isScrollableHorizontally: false,
	isUserUpdatingFilter: false,
	scrollbarSize: 0,
});

type ContextProviderProps = {
	actualHeaderHeight: number,
	actualScrollbarSize: number,
	children: React.ReactNode,
	filter: any,
	height: number,
	isScrollableHorizontally: boolean,
	isUserUpdatingFilter: boolean,
};

const ContextProvider: React.FC<ContextProviderProps> = (props) => {
	const {
		actualHeaderHeight,
		actualScrollbarSize,
		children,
		filter,
		height,
		isScrollableHorizontally,
		isUserUpdatingFilter,
	} = props;

	const contextValue = React.useMemo(
		() => ({
			datatableFilter: filter,
			datatableHeaderHeight: actualHeaderHeight,
			datatableHeight: height,
			isScrollableHorizontally,
			isUserUpdatingFilter,
			scrollbarSize: actualScrollbarSize,
		}),
		[
			actualHeaderHeight,
			actualScrollbarSize,
			filter,
			height,
			isScrollableHorizontally,
			isUserUpdatingFilter,
		],
	);

	return (
		<Context.Provider value={contextValue}>
			{children}
		</Context.Provider>
	);
};



export function useIsScrolled<T extends HTMLElement = any>(
	direction: 'horizontal' | 'vertical',
	root: HTMLElement | null,
) {
	const [isScrolled, setIsScrolled] = React.useState<boolean | null>(null);

	const observer = React.useRef<IntersectionObserver | null>();

	const ref = React.useCallback(
		(element: T | null) => {
			if (observer.current) {
				observer.current.disconnect();
				observer.current = null;
			}

			if (element === null) {
				setIsScrolled(null);

				return;
			}

			let timeoutRef: ReturnType<typeof setTimeout> | null = null;

			observer.current = new IntersectionObserver(() => {
				if (root === null) {
					return;
				}

				if (timeoutRef !== null) {
					clearTimeout(timeoutRef);
				}

				timeoutRef = setTimeout(
					() => {
						const value = matchAndReturn(direction, {
							horizontal: root.scrollLeft,
							vertical: root.scrollTop,
						});

						setIsScrolled(value > 0);

						timeoutRef = null;
					},
					300,
				);
			}, {
				root,
				threshold: [0, 1],
			});

			observer.current.observe(element);
		},
		[
			direction,
			root,
		],
	);

	return {
		isNotScrolled: isScrolled !== true,
		isScrolled,
		ref,
	};
}



export enum DatatableHeaderHeight {
	Small = 'Small',
	Medium = 'Medium',
}

export enum DatatableRowHeight {
	Small,
	Medium,
	Large,
}

const DENSITIES = {
	[DatatableRowHeight.Small]: 44,
	[DatatableRowHeight.Medium]: 60,
	[DatatableRowHeight.Large]: 76,
};

const defaultDefaultFilterValues = {};
const tanstackRowsForTable = [1];

type DatatableRowGetterInput = { rowIndex: number };

type DatatableColumnWidth = number | `${number}%` | 'fill';

type DatatableColumnDefinition<RowData> = {
	alignment?: DataCellAlignment,
	clickable?: boolean,
	filterName?: string,
	hasData?: ((input: {
		columnName: string,
		row: NonNullable<RowData>,
		rowIndex: number,
	}) => boolean),
	hasPadding?: boolean,
	headerAlignment?: HeaderCellAlignment,
	headerColorStyle?: HeaderCellColorStyle,
	headerIsStriped?: boolean,
	name: string,
	render: {
		cell: (input: {
			innerWidth: number,
			row: NonNullable<RowData>,
			rowIndex: number,
		}) => React.ReactNode,
	} & ({
		filter?: never,
		header: (input: {
			columnIndex: number,
			columnWidth: number,
			filterName: string,
			filterRef: React.RefObject<any>,
			filterWidth: number,
		}) => React.ReactNode,
		label?: never,
		labelFilterOperator?: never,
	} | {
		filter?: (input: {
			filterName: string,
			filterWidth: number,
			ref: React.RefObject<any>,
		}) => React.ReactNode,
		header?: never,
		label: RenderProp<{}>,
		labelFilterOperator?: (input: {
			filter: any,
		}) => React.ReactNode,
	}),
	resizable?: boolean,
	sortName?: string,
	sortOrder?: boolean,
	width: DatatableColumnWidth,
	widthMaximum?: number,
	widthMinimum?: number,
};

type DatatableColumnDefinitions<RowGetter extends (input: DatatableRowGetterInput) => any> = Array<DatatableColumnDefinition<ReturnType<RowGetter>>>;

type DatatableContextMenuEntries<RowData> = (input: {
	columnIndex: number,
	row: RowData,
	rowIndex: number,
}) => Array<ContextMenuEntry>;

type DatatableRef = {
	scrollToColumn: (columnName: string) => void,
};



type ColumnMeta<RowData> = {
	alignment: DataCellAlignment | undefined,
	isClickable: boolean,
	filterName: string,
	hasData: ((input: {
		columnName: string,
		row: NonNullable<RowData>,
		rowIndex: number,
	}) => boolean) | undefined,
	hasPadding: boolean,
	headerAlignment: HeaderCellAlignment | undefined,
	headerColorStyle: HeaderCellColorStyle | undefined,
	headerIsStriped: boolean,
	renderCell: (input: {
		innerWidth: number,
		row: NonNullable<RowData>,
		rowIndex: number,
	}) => React.ReactNode,
	renderFilter: ((input: {
		filterName: string,
		filterWidth: number,
		ref: React.RefObject<any>,
	}) => React.ReactNode) | undefined,
	renderHeader: ((input: {
		columnIndex: number,
		columnWidth: number,
		filterName: string,
		filterRef: React.RefObject<any>,
		filterWidth: number,
	}) => React.ReactNode) | undefined,
	renderLabel: RenderProp<{}>,
	renderLabelFilterOperator?: ((input: {
		filter: any,
	}) => React.ReactNode) | undefined,
	sortName: string,
	sortOrder: boolean | undefined,
};

type HeaderHeight =
	| DatatableHeaderHeight
	| number;

type Props<RowData> = {
	activeRowsLimit?: number,
	blankSlate?: RenderProp<number>,
	columns: ReadonlyArray<DatatableColumnDefinition<RowData>>,
	contextMenuEntries?: DatatableContextMenuEntries<RowData>,
	defaultFilterValues?: Record<string, any>,
	filter?: any,
	headerHeight?: HeaderHeight,
	height: number,
	inactiveRowsFooter?: React.ReactNode,
	isDisabled?: boolean,
	isLoading?: boolean,
	isRowInactive?: (input: {
		row: RowData,
		rowIndex: number,
	}) => boolean,
	lastRowNote?: React.ReactNode,
	numberOfPinnedColumns?: number,
	onColumnResize?: (columnName: string, width: number) => void,
	onFilterChange?: (filterUpdate: Record<string, any>) => void,
	onRowClick?: (input: {
		row: RowData,
		rowIndex: number,
	}, event: React.MouseEvent) => void,
	onScroll?: (input: {
		rowIndex: number,
	}) => void,
	onSortChangeCallback?: (input: {
		direction: boolean,
		key: string,
	}) => void,
	overlay?: RenderProp<number>,
	overscanRows?: number,
	rowGetter: (input: DatatableRowGetterInput) => RowData,
	rowHeightStyle?: DatatableRowHeight,
	rowIsClickable?: (input: { row: RowData }) => boolean,
	rowStyleGetter?: (input: {
		row: RowData,
	}) => DataCellColorStyle | null,
	rowsCount: number,
	sortBy?: any,
	width: number,
};

function Datatable<RowData extends any>(props: Props<RowData>, ref: React.ForwardedRef<DatatableRef>) {
	const {
		activeRowsLimit = 0,
		blankSlate,
		columns,
		contextMenuEntries,
		defaultFilterValues = defaultDefaultFilterValues,
		filter,
		headerHeight = DatatableHeaderHeight.Medium,
		height,
		inactiveRowsFooter,
		isDisabled = false,
		isLoading = false,
		isRowInactive,
		lastRowNote = null,
		numberOfPinnedColumns = 0,
		onColumnResize,
		onFilterChange,
		onRowClick,
		onScroll,
		onSortChangeCallback,
		overlay,
		overscanRows = 100,
		rowGetter,
		rowHeightStyle = DatatableRowHeight.Small,
		rowIsClickable,
		rowStyleGetter,
		rowsCount,
		sortBy,
		width,
	} = props;

	const rowHeight = DENSITIES[rowHeightStyle];

	const columnMeta: ReadonlyArray<ColumnMeta<RowData>> = React.useMemo(
		() => columns.map(
			(column) => ({
				alignment: column.alignment,
				filterName: column.filterName ?? column.name,
				hasData: column.hasData,
				hasPadding: column.hasPadding ?? true,
				headerAlignment: column.headerAlignment,
				headerColorStyle: column.headerColorStyle,
				headerIsStriped: column.headerIsStriped ?? false,
				isClickable: column.clickable ?? true,
				renderCell: column.render.cell,
				renderFilter: column.render.filter,
				renderHeader: column.render.header,
				renderLabel: column.render.label,
				renderLabelFilterOperator: column.render.labelFilterOperator,
				sortName: column.sortName ?? column.name,
				sortOrder: column.sortOrder,
			}),
		),
		[
			columns,
		],
	);

	const actualScrollbarSize = scrollbarSize();
	const effectiveWidth = width - actualScrollbarSize;

	const tanstackColumns = React.useMemo(
		() => {
			const calculateColumnWidth = (column: {
				width: DatatableColumnWidth,
				widthMaximum?: number | undefined,
				widthMinimum?: number | undefined,
			}, widthOfPinnedColumns: number, widthOfPrecedingColumns: number) => {
				let result: number;

				if (isString(column.width)) {
					if (column.width === 'fill') {
						result = effectiveWidth - widthOfPrecedingColumns;
					} else {
						const ratio = parseFloat(column.width.replace('%', '')) / 100;

						result = Math.floor((effectiveWidth - widthOfPinnedColumns) * ratio);
					}
				} else {
					result = column.width;
				}

				if (column.widthMaximum !== undefined) {
					result = Math.min(result, column.widthMaximum);
				}

				if (column.widthMinimum !== undefined) {
					result = Math.max(result, column.widthMinimum);
				}

				return result;
			};

			let widthOfPrecedingColumns = 0;

			const widthOfPinnedColumns = columns.reduce(
				(total, column, columnIndex) => total + (columnIndex < numberOfPinnedColumns ? calculateColumnWidth(column, 0, 0) : 0),
				0,
			);

			return columns.map(
				(column, columnIndex) => {
					const enableResizing = column.resizable === true;
					const columnWidth = calculateColumnWidth(column, columnIndex < numberOfPinnedColumns ? 0 : widthOfPinnedColumns, widthOfPrecedingColumns);

					widthOfPrecedingColumns += columnWidth;

					return {
						enableResizing,
						id: column.name,
						maxSize: enableResizing ? column.widthMaximum : undefined,
						minSize: enableResizing ? column.widthMinimum : undefined,
						size: columnWidth,
					};
				},
			);
		},
		[
			columns,
			effectiveWidth,
			numberOfPinnedColumns,
		],
	);
	// disableContextMenuOnScrollableArea={!!onContextMenuCallback}

	const tanstackRows = React.useMemo(
		() => Array.from({ length: rowsCount }).map((_, rowIndex: number) => {
			return rowGetter({ rowIndex });
		}),
		[
			rowsCount,
			rowGetter,
		],
	);

	const table = useReactTable({
		data: tanstackRowsForTable,
		columnResizeMode: 'onEnd',
		columns: tanstackColumns,
		getCoreRowModel: getCoreRowModel(),
		debugTable: false,
	});

	const tableContainerRef = React.useRef<HTMLDivElement>(null);

	const rowVirtualizer = useVirtual({
		estimateSize: React.useCallback(() => rowHeight, [rowHeight]),
		overscan: overscanRows,
		parentRef: tableContainerRef,
		size: rowsCount + (lastRowNote !== null ? 1 : 0),
	});

	const filterFieldsRef = React.useRef<Record<string, any>>({});
	const previousFilterRef = React.useRef<any>();

	const [focusedColumn, setFocusedColumn] = React.useState<number | null>(null);
	const [highlightedColumn, setHighlightedColumn] = React.useState<number | null>(null);
	const [highlightedRow, setHighlightedRow] = React.useState<number | null>(null);

	const changeCallbackTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
	const rerenderFieldTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

	const tableContainerElement = tableContainerRef.current;

	const containerLeft = React.useMemo(
		() => {
			if (
				tableContainerElement === null
				|| !tableContainerElement.ownerDocument.defaultView
			) {
				return;
			}

			return (
				tableContainerElement.getBoundingClientRect().left
				+ tableContainerElement.ownerDocument.defaultView.pageXOffset
			);
		},
		[
			tableContainerElement,
		],
	);

	const rowVirtualizerScrollToOffset = rowVirtualizer.scrollToOffset;

	React.useEffect(
		() => {
			if (!filter) {
				return;
			}

			let isFilterUpdated: true | undefined;

			filter.forEach((value, field) => {
				if (!previousFilterRef.current || previousFilterRef.current.get(field) !== value) {
					if (
						rerenderFieldTimeoutRef.current === undefined
						&& filterFieldsRef.current[field]?.current !== undefined
					) {
						if (filterFieldsRef.current[field].current?.updateFilterValue) {
							filterFieldsRef.current[field].current.updateFilterValue(value);
						} else if (filterFieldsRef.current[field].current?.changeValue) {
							filterFieldsRef.current[field].current.changeValue(value);
						}
					}

					isFilterUpdated = true;
				}
			});

			previousFilterRef.current?.forEach((value, field) => {
				if (!filter.has(field)) {
					if (
						rerenderFieldTimeoutRef.current === undefined
						&& filterFieldsRef.current[field]?.current !== undefined
					) {
						if (filterFieldsRef.current[field].current?.updateFilterValue) {
							filterFieldsRef.current[field].current.updateFilterValue(null);
						} else if (filterFieldsRef.current[field].current?.changeValue) {
							filterFieldsRef.current[field].current.changeValue(null);
						}
					}

					isFilterUpdated = true;
				}
			});

			if (isFilterUpdated) {
				rowVirtualizerScrollToOffset(0);
			}

			previousFilterRef.current = filter;
		},
		[
			filter,
			rowVirtualizerScrollToOffset,
		],
	);

	const scrolledVirtualItemIndex = rowVirtualizer.virtualItems[overscanRows]?.index;

	React.useEffect(
		() => {
			if (scrolledVirtualItemIndex === undefined) {
				return;
			}

			if (onScroll) {
				onScroll({ rowIndex: scrolledVirtualItemIndex });
			}
		},
		[
			onScroll,
			scrolledVirtualItemIndex,
		],
	);

	const headers = table.getHeaderGroups()[0]?.headers;
	const headersRef = React.useRef<any>();
	headersRef.current = headers;

	const widthRef = React.useRef(effectiveWidth);
	widthRef.current = effectiveWidth;

	const getColumnVisibility = React.useCallback(
		(columnName: string) => {
			if (tableContainerRef.current === null) {
				return {
					verdict: 0,
					offset: 0,
				};
			}

			const header = headersRef.current?.find(
				(header) => header.id === columnName,
			);

			if (header === undefined) {
				return {
					verdict: 0,
					offset: 0,
				};
			}

			if (header.index < numberOfPinnedColumns) {
				return {
					verdict: 0,
					offset: 0,
				};
			}

			const pinnedOffsetHeader = (headersRef.current ?? []).find(
				(header) => header.index + 1 === numberOfPinnedColumns,
			);

			const pinnedOffset = pinnedOffsetHeader !== undefined
				? pinnedOffsetHeader.getStart() + pinnedOffsetHeader.getSize()
				: 0;

			const scrollLeft = tableContainerRef.current.scrollLeft;

			if (scrollLeft > (header.getStart() - pinnedOffset)) {
				return {
					verdict: -1,
					offset: scrollLeft - (header.getStart() - pinnedOffset),
				};
			}

			if ((widthRef.current + scrollLeft) < (header.getStart() + header.getSize())) {
				return {
					verdict: 1,
					offset: (widthRef.current + scrollLeft) - (header.getStart() + header.getSize()),
				};
			}

			return {
				verdict: 0,
				offset: scrollLeft - (header.getStart() - pinnedOffset),
			};
		},
		[
			numberOfPinnedColumns,
		],
	);

	const isColumnVisible = React.useCallback(
		(columnName: string) => {
			return getColumnVisibility(columnName).verdict === 0;
		},
		[
			getColumnVisibility,
		],
	);

	const scrollToColumn = React.useCallback(
		(columnName: string) => {
			if (tableContainerRef.current === null) {
				return;
			}

			const columnVisibility = getColumnVisibility(columnName);

			horizontalScrollTo(
				tableContainerRef.current,
				tableContainerRef.current.scrollLeft - columnVisibility.offset,
			);
		},
		[
			getColumnVisibility,
		],
	);

	React.useImperativeHandle(ref, () => ({
		scrollToColumn,
	}));

	const handleFormChange = React.useCallback(
		(field, value) => {
			if (onFilterChange) {
				if (changeCallbackTimeoutRef.current) {
					clearTimeout(changeCallbackTimeoutRef.current);
					changeCallbackTimeoutRef.current = undefined;
				}

				if (rerenderFieldTimeoutRef.current) {
					clearTimeout(rerenderFieldTimeoutRef.current);
					rerenderFieldTimeoutRef.current = undefined;
				}

				changeCallbackTimeoutRef.current = setTimeout(() => {
					if (rerenderFieldTimeoutRef.current) {
						clearTimeout(rerenderFieldTimeoutRef.current);
						rerenderFieldTimeoutRef.current = undefined;
					}

					rerenderFieldTimeoutRef.current = setTimeout(() => {
						rerenderFieldTimeoutRef.current = undefined;
					}, 500);

					onFilterChange({
						[field]: value === undefined ? '' : value,
					});

					changeCallbackTimeoutRef.current = undefined;
				}, 500);

				rerenderFieldTimeoutRef.current = setTimeout(() => {
					rerenderFieldTimeoutRef.current = undefined;
				}, 500);
			}
		},
		[
			onFilterChange,
		],
	);

	const handleHeaderMouseEnter = React.useCallback(
		({ columnIndex }: { columnIndex: number }) => {
			setTimeout(
				() => setHighlightedColumn(columnIndex),
				0,
			);
		},
		[],
	);

	const handleHeaderMouseLeave = React.useCallback(
		({ columnIndex }: { columnIndex: number }) => {
			setTimeout(
				() => setHighlightedColumn(
					(highlightedColumn) => {
						return highlightedColumn === columnIndex
							? null
							: highlightedColumn;
					},
				),
				0,
			);
		},
		[],
	);

	const handleInputFieldFocus = React.useCallback(
		(filterName: string) => {
			const columnIndex = columns.findIndex((column) => (column.filterName ?? column.name) === filterName);

			if (columnIndex === -1) {
				return;
			}

			setTimeout(
				() => setFocusedColumn(columnIndex),
				0,
			);
		},
		[
			columns,
		],
	);

	const handleInputFieldBlur = React.useCallback(
		() => {
			setFocusedColumn(null);
		},
		[],
	);

	const actualHeaderHeight = ((headerHeight: HeaderHeight) => {
		if (isInteger(headerHeight)) {
			return headerHeight;
		}

		return matchAndReturn(headerHeight, {
			[DatatableHeaderHeight.Medium]: 76,
			[DatatableHeaderHeight.Small]: 50,
		});
	})(headerHeight);

	const finalDefaultFilterValues = React.useMemo(
		() => {
			return {
				...filter.toJS(),
				...defaultFilterValues,
			};
		},
		[
			defaultFilterValues,
			filter,
		],
	);

	const overlayComponent = React.useMemo(
		() => {
			let result = renderProp(overlay, actualHeaderHeight);

			if (isLoading) {
				result = (
					<DatatableOverlay
						datatableHeaderHeight={actualHeaderHeight}
						style={DatatableOverlayStyle.Disabled}
					>
						<LoadingDots size={LoadingDotsSize.Large} />
					</DatatableOverlay>
				);
			}

			return result;
		},
		[
			actualHeaderHeight,
			isLoading,
			overlay,
		],
	);

	const isUserUpdatingFilter = !!rerenderFieldTimeoutRef.current;

	const columnSizingRef = React.useRef<any>(null);
	columnSizingRef.current = table.getState().columnSizing;

	const columnSizingInfoRef = React.useRef<any>(null);

	if (table.getState().columnSizingInfo.isResizingColumn) {
		columnSizingInfoRef.current = table.getState().columnSizingInfo;
	}

	const isResizing = table.getState().columnSizingInfo.startOffset !== null;

	React.useEffect(
		() => {
			if (onColumnResize === undefined || isResizing || !columnSizingInfoRef.current) {
				return;
			}

			const columnName = columnSizingInfoRef.current.isResizingColumn;
			const newWidth = columnSizingInfoRef.current.startSize + columnSizingInfoRef.current.deltaOffset;

			onColumnResize(columnName, newWidth);
		},
		[
			isResizing,
			onColumnResize,
		],
	);

	const pinnedColumnContainerWidth = (headers ?? []).slice(0, numberOfPinnedColumns).reduce(
		(total, header) => total + header.getSize(),
		0,
	);

	const renderVirtualDragHandler = (data, tableHeight: number) => {
		if (
			containerLeft === undefined
			|| data.isResizingColumn === false
		) {
			return;
		}

		const column = table.getColumn(data.isResizingColumn);

		if (column === undefined) {
			return;
		}

		const finalSize = data.startSize + data.deltaOffset;

		const renderedDeltaOffset = (column.columnDef.maxSize !== undefined && finalSize > column.columnDef.maxSize) ? (
			column.columnDef.maxSize - data.startSize
		) : (column.columnDef.minSize !== undefined && finalSize < column.columnDef.minSize) ? (
			column.columnDef.minSize - data.startSize
		) : data.deltaOffset;

		const positionLeft = data.startOffset - containerLeft + renderedDeltaOffset - 5;

		return (
			<CellDragHandler
				isActive={true}
				position={{
					left: 0,
					transform: `translateX(${positionLeft}px)`,
				}}
				tableHeight={tableHeight}
			/>
		);
	};

	const lastHeaderGroup = getArrayItemAtSafeIndex(
		table.getHeaderGroups(),
		table.getHeaderGroups().length - 1,
	);

	const lastHeader = getArrayItemAtSafeIndex(
		lastHeaderGroup.headers,
		lastHeaderGroup.headers.length - 1,
	);

	const lastHeaderSize = lastHeader.getSize();
	const lastHeaderStart = lastHeader.getStart();

	const {
		columnSizes,
		columnStarts,
	} = React.useMemo(
		() => ({
			columnSizes: (headers ?? []).map(
				(header) => header.getSize(),
			),
			columnStarts: (headers ?? []).map(
				(header) => header.getStart(),
			),
		}),
		[
			headers,
		],
	);

	const datatableContext = useDatatableContext();

	const {
		setIsColumnVisible,
		setScrollToColumn,
	} = datatableContext;

	React.useEffect(
		() => {
			setIsColumnVisible?.(
				isColumnVisible,
			);

			setScrollToColumn?.(
				scrollToColumn,
			);
		},
		[
			isColumnVisible,
			scrollToColumn,
			setIsColumnVisible,
			setScrollToColumn,
		],
	);

	const totalSize = table.getTotalSize();
	const isScrollableHorizontally = totalSize > effectiveWidth;

	const totalWidth = totalSize;

	const filledWidth = Math.max(effectiveWidth - lastHeaderStart - lastHeaderSize, 0);

	const {
		isNotScrolled: isAtTheTop,
		ref: isAtTheTopRef,
	} = useIsScrolled(
		'vertical',
		tableContainerRef.current,
	);

	const {
		isNotScrolled: isAtTheStart,
		ref: isAtTheStartRef,
	} = useIsScrolled(
		'horizontal',
		tableContainerRef.current,
	);

	return (
		<DatatableContainer
			disabled={isDisabled}
			overlay={overlayComponent}
		>
			<Form
				defaultValues={finalDefaultFilterValues}
				onBlurCallback={handleInputFieldBlur}
				onChangeCallback={handleFormChange}
				onFocusCallback={handleInputFieldFocus}
			>
				<ContextProvider
					actualHeaderHeight={actualHeaderHeight}
					actualScrollbarSize={actualScrollbarSize}
					filter={filter}
					height={height}
					isScrollableHorizontally={isScrollableHorizontally}
					isUserUpdatingFilter={isUserUpdatingFilter}
				>
					<div
						ref={tableContainerRef}
						style={{ height, overflowX: 'auto', overflowY: 'scroll' }}
					>
						<div
							className={classNames({
								'rv-table__grid-wrapper': true,
								'rv-table__grid-wrapper--not-sticky': isAtTheStart && pinnedColumnContainerWidth > 0,
								'rv-table__grid-wrapper--sticky': isAtTheStart === false && pinnedColumnContainerWidth > 0,
							})}
							style={{
								backgroundColor: 'white',
								height: actualHeaderHeight,
								left: 0,
								margin: 0,
								position: 'absolute',
								top: 0,
								width: pinnedColumnContainerWidth + (pinnedColumnContainerWidth > 0 ? 1 : 0),
								zIndex: 10000,
							}}
						>
							<MemoHeaders
								actualHeaderHeight={actualHeaderHeight}
								columnMeta={columnMeta}
								columnSizes={columnSizes}
								columnStarts={columnStarts}
								filledWidth={0}
								filter={filter}
								filterFieldsRef={filterFieldsRef}
								focusedColumn={focusedColumn}
								handleHeaderMouseEnter={handleHeaderMouseEnter}
								handleHeaderMouseLeave={handleHeaderMouseLeave}
								headerHeight={headerHeight}
								headers={headers}
								highlightedColumn={highlightedColumn}
								isAtTheStart={isAtTheStart === true}
								isResizing={isResizing}
								lastHeaderSize={lastHeaderSize}
								lastHeaderStart={lastHeaderStart}
								numberOfPinnedColumns={numberOfPinnedColumns}
								onSortChangeCallback={onSortChangeCallback}
								renderPinned={true}
								sortBy={sortBy}
							/>

							{renderVirtualDragHandler(
								table.getState().columnSizingInfo,
								height,
							)}
						</div>

						<div
							style={{
								height: `${rowVirtualizer.totalSize}px`,
								width: Math.max(totalWidth, effectiveWidth),
								position: 'relative',
							}}
						>
							<div
								className={classNames({
									'rv-table__sticky-grid-header': true,
									'rv-table__sticky-grid-header--is-scrolled': isAtTheTop === false,
								})}
								style={{
									backgroundColor: 'white',
									position: 'sticky',
									height: actualHeaderHeight,
									margin: 0,
									top: 0,
									zIndex: 9998,
								}}
							>
								<MemoHeaders
									actualHeaderHeight={actualHeaderHeight}
									columnMeta={columnMeta}
									columnSizes={columnSizes}
									columnStarts={columnStarts}
									filledWidth={filledWidth}
									filter={filter}
									filterFieldsRef={filterFieldsRef}
									focusedColumn={focusedColumn}
									handleHeaderMouseEnter={handleHeaderMouseEnter}
									handleHeaderMouseLeave={handleHeaderMouseLeave}
									headerHeight={headerHeight}
									headers={headers}
									highlightedColumn={highlightedColumn}
									isAtTheStart={false}
									isResizing={isResizing}
									lastHeaderSize={lastHeaderSize}
									lastHeaderStart={lastHeaderStart}
									numberOfPinnedColumns={numberOfPinnedColumns}
									onSortChangeCallback={onSortChangeCallback}
									renderPinned={false}
									sortBy={sortBy}
								/>

								<div
									ref={isAtTheStartRef}
									style={{
										backgroundColor: 'blue',
										height: 1,
										left: 0,
										position: 'absolute',
										top: 0,
										width: 1,
									}}
								/>
							</div>

							<div
								ref={isAtTheTopRef}
								style={{
									backgroundColor: 'red',
									height: 1,
									left: 0,
									position: 'absolute',
									top: 0,
									width: 1,
								}}
							/>

							<MemoRows
								actualHeaderHeight={actualHeaderHeight}
								blankSlate={blankSlate}
								columnMeta={columnMeta}
								columnSizes={columnSizes}
								columnStarts={columnStarts}
								contextMenuEntries={contextMenuEntries}
								filledWidth={filledWidth}
								focusedColumn={focusedColumn}
								forcedHighlightedRow={highlightedRow}
								handleCellClick={onRowClick}
								headers={headers}
								height={height}
								highlightedColumn={highlightedColumn}
								isAtTheStart={isAtTheStart === true}
								isDisabled={isDisabled}
								isResizing={isResizing}
								isRowInactive={isRowInactive}
								lastHeaderSize={lastHeaderSize}
								lastHeaderStart={lastHeaderStart}
								lastRowNote={lastRowNote}
								numberOfPinnedColumns={numberOfPinnedColumns}
								pinnedColumnContainerWidth={pinnedColumnContainerWidth}
								rowIsClickable={rowIsClickable}
								rowStyleGetter={rowStyleGetter}
								rows={tanstackRows}
								rowsCount={rowsCount}
								setForcedHighlightedRow={setHighlightedRow}
								virtualItems={rowVirtualizer.virtualItems}
								width={width}
							/>
						</div>
					</div>

					{inactiveRowsFooter && (
						<div
							className={classNames({
								'rv-table__footer-element': true,
								'rv-table__footer-element--hidden': (
									scrolledVirtualItemIndex !== undefined
									&& activeRowsLimit >= (scrolledVirtualItemIndex + (height * 0.75 / rowHeight))
								),
							})}
							style={{
								bottom: actualScrollbarSize,
								right: scrollbarSize() || 16,
								zIndex: 10001,
							}}
						>
							{inactiveRowsFooter}
						</div>
					)}
				</ContextProvider>
			</Form>
		</DatatableContainer>
	);
}



type HeadersProps<RowData> = {
	actualHeaderHeight: number,
	columnMeta: ReadonlyArray<ColumnMeta<RowData>>,
	columnSizes: ReadonlyArray<number>,
	columnStarts: ReadonlyArray<number>,
	filledWidth: number,
	filter,
	filterFieldsRef,
	focusedColumn: number | null,
	handleHeaderMouseEnter: (input: {
		columnIndex: number,
	}) => void,
	handleHeaderMouseLeave: (input: {
		columnIndex: number,
	}) => void,
	headerHeight:
		| DatatableHeaderHeight
		| number,
	headers,
	highlightedColumn: number | null,
	isAtTheStart: boolean,
	isResizing: boolean,
	lastHeaderSize: number,
	lastHeaderStart: number,
	numberOfPinnedColumns: number,
	onSortChangeCallback: ((input: {
		direction: boolean,
		key: string,
	}) => void) | undefined,
	renderPinned: boolean,
	sortBy,
};

function Headers<RowData>(props: HeadersProps<RowData>) {
	const {
		actualHeaderHeight,
		columnMeta,
		columnSizes,
		columnStarts,
		filledWidth,
		filter,
		filterFieldsRef,
		focusedColumn,
		handleHeaderMouseEnter,
		handleHeaderMouseLeave,
		headerHeight,
		headers,
		highlightedColumn,
		isAtTheStart,
		isResizing,
		lastHeaderSize,
		lastHeaderStart,
		numberOfPinnedColumns,
		onSortChangeCallback,
		renderPinned,
		sortBy,
	} = props;

	return (
		<>
			{(headers ?? []).map((header, columnIndex) => {
				if (renderPinned === (columnIndex >= numberOfPinnedColumns)) {
					return;
				}

				const columnSize = getArrayItemAtSafeIndex(columnSizes, columnIndex);
				const columnStart = getArrayItemAtSafeIndex(columnStarts, columnIndex);

				const meta = getArrayItemAtSafeIndex(columnMeta, columnIndex);

				let headerContent: React.ReactNode;

				filterFieldsRef.current[meta.filterName] ??= {
					current: undefined,
				};

				if (meta.renderHeader) {
					headerContent = meta.renderHeader({
						columnIndex,
						columnWidth: columnSize,
						filterName: meta.filterName,
						filterRef: filterFieldsRef.current[meta.filterName],
						filterWidth: columnSize - 40,
					});
				} else {
					headerContent = (
						<HeaderLabel
							labelOperator={(
								meta.renderLabelFilterOperator !== undefined
									? meta.renderLabelFilterOperator({ filter })
									: undefined
							)}
							sorting={meta.sortOrder !== undefined ? {
								callback: onSortChangeCallback,
								flipped: meta.sortOrder === false,
								name: meta.sortName,
								status: sortBy,
							} : undefined}
						>
							{renderProp(meta.renderLabel, {})}
						</HeaderLabel>
					);

					if (headerHeight !== DatatableHeaderHeight.Small) {
						headerContent = (
							<FilterFieldLayout
								field={meta.renderFilter ? meta.renderFilter({
									filterName: meta.filterName,
									filterWidth: columnSize - 40,
									ref: filterFieldsRef.current[meta.filterName],
								}) : false}
								label={headerContent}
							/>
						);
					}
				}

				return (
					<HeaderCell
						alignment={meta.headerAlignment}
						colorStyle={meta.headerColorStyle}
						columnIndex={columnIndex}
						columnName={header.id}
						height={actualHeaderHeight}
						isInHighlightedColumn={focusedColumn === columnIndex || highlightedColumn === columnIndex}
						isSticky={renderPinned && isAtTheStart === false}
						isStriped={meta.headerIsStriped}
						key={header.id}
						left={columnStart}
						onMouseEnter={isResizing ? undefined : handleHeaderMouseEnter}
						onMouseLeave={isResizing ? undefined : handleHeaderMouseLeave}
						padding={meta.hasPadding ? undefined : HeaderCellPadding.NoPadding}
						width={columnSize}
					>
						{headerContent}

						{header.column.getCanResize() && (
							<CellDragHandler
								onMouseDown={header.getResizeHandler()}
								onTouchStart={header.getResizeHandler()}
							/>
						)}
					</HeaderCell>
				);
			})}

			{filledWidth > 0 && (
				<HeaderCell
					alignment={undefined}
					colorStyle={undefined}
					columnIndex={columnSizes.length + 1}
					columnName="filler_header"
					height={actualHeaderHeight}
					isInHighlightedColumn={false}
					isSticky={false}
					isStriped={false}
					key="filler_header"
					left={lastHeaderStart + lastHeaderSize}
					onMouseEnter={undefined}
					onMouseLeave={undefined}
					padding={undefined}
					width={filledWidth}
				/>
			)}
		</>
	);
}

const MemoHeaders = React.memo(Headers);



type RowsProps<RowData> = {
	actualHeaderHeight: number,
	blankSlate: RenderProp<number> | undefined,
	columnMeta: ReadonlyArray<ColumnMeta<RowData>>,
	columnSizes: ReadonlyArray<number>,
	columnStarts: ReadonlyArray<number>,
	contextMenuEntries,
	filledWidth: number,
	focusedColumn: number | null,
	forcedHighlightedRow: number | null,
	handleCellClick,
	headers,
	height: number,
	highlightedColumn: number | null,
	isAtTheStart: boolean,
	isDisabled: boolean,
	isResizing: boolean,
	isRowInactive,
	lastHeaderSize: number,
	lastHeaderStart: number,
	lastRowNote: React.ReactNode | null,
	numberOfPinnedColumns: number,
	pinnedColumnContainerWidth: number,
	rowIsClickable,
	rowStyleGetter: ((input: {
		row: RowData,
	}) => DataCellColorStyle | null) | undefined,
	rows: ReadonlyArray<RowData>,
	rowsCount: number,
	setForcedHighlightedRow: (state: number | null) => void,
	virtualItems: Array<VirtualItem>,
	width: number,
};

function Rows<RowData>(props: RowsProps<RowData>) {
	const {
		actualHeaderHeight,
		blankSlate,
		columnMeta,
		columnSizes,
		columnStarts,
		contextMenuEntries,
		filledWidth,
		focusedColumn,
		forcedHighlightedRow,
		handleCellClick,
		headers,
		height,
		highlightedColumn,
		isAtTheStart,
		isDisabled,
		isResizing,
		isRowInactive,
		lastHeaderSize,
		lastHeaderStart,
		lastRowNote,
		numberOfPinnedColumns,
		pinnedColumnContainerWidth,
		rowIsClickable,
		rowStyleGetter,
		rows,
		rowsCount,
		setForcedHighlightedRow,
		virtualItems,
		width,
	} = props;

	const stickyContainerRef = React.useRef<HTMLDivElement>(null);

	return (
		<>
			<div
				className={classNames({
					'rv-table__grid-wrapper': true,
					'rv-table__grid-wrapper--not-sticky': isAtTheStart && pinnedColumnContainerWidth > 0,
					'rv-table__grid-wrapper--sticky': isAtTheStart === false && pinnedColumnContainerWidth > 0,
				})}
				ref={stickyContainerRef}
				style={{
					backgroundColor: 'white',
					position: 'sticky',
					margin: 0,
					left: 0,
					zIndex: 1001,
					height: '100%',
					width: pinnedColumnContainerWidth + (pinnedColumnContainerWidth > 0 ? 1 : 0),
				}}
			>
				{rowsCount === 0 && (() => {
					const blankSlateNode = renderProp(blankSlate, actualHeaderHeight);

					if (!blankSlateNode) {
						return null;
					}

					return (
						<div
							className="rv-table__no-data-message"
							style={{
								height: height - actualHeaderHeight - 20,
								width,
							}}
						>
							<DatatableOverlay definedZIndex={false}>
								{blankSlateNode}
							</DatatableOverlay>
						</div>
					);
				})()}
			</div>

			<div
				className={classNames({
					'rv-table__grid-wrapper': true,
					'rv-table__grid-wrapper--disabled': isDisabled,
				})}
				style={{
					height: '100%',
					position: 'absolute',
					top: actualHeaderHeight,
					width: '100%',
				}}
			>
				{virtualItems.map((virtualRow) => {
					const rowIndex = virtualRow.index;

					if (rowIndex >= rowsCount) {
						if (stickyContainerRef.current === null) {
							return;
						}

						return createPortal(
							(
								<div
									style={{
										position: 'absolute',
										left: 0,
										height: virtualRow.size,
										zIndex: 9998,
										transform: `translateY(${virtualRow.start}px)`,
										width,
									}}
								>
									{lastRowNote}
								</div>
							),
							stickyContainerRef.current,
						);
					}

					const row = getArrayItemAtSafeIndex(rows, rowIndex);

					return (
						<MemoRowComponent
							columnMeta={columnMeta}
							columnSizes={columnSizes}
							columnStarts={columnStarts}
							contextMenuEntries={contextMenuEntries}
							disabledHighlightingRow={forcedHighlightedRow !== null}
							filledWidth={filledWidth}
							focusedColumn={focusedColumn}
							handleCellClick={handleCellClick}
							headers={headers}
							highlightedColumn={highlightedColumn}
							isAtTheStart={isAtTheStart}
							isForcedHighlighted={forcedHighlightedRow === rowIndex}
							isResizing={isResizing}
							isRowClickable={rowIsClickable}
							isRowInactive={isRowInactive}
							key={rowIndex}
							lastHeaderSize={lastHeaderSize}
							lastHeaderStart={lastHeaderStart}
							numberOfPinnedColumns={numberOfPinnedColumns}
							renderPinned={false}
							row={row}
							rowIndex={rowIndex}
							rowStyleGetter={rowStyleGetter}
							setForcedHighlightedRow={setForcedHighlightedRow}
							stickyContainerRef={stickyContainerRef}
							virtualRowSize={virtualRow.size}
							virtualRowStart={virtualRow.start}
						/>
					);
				})}
			</div>
		</>
	);
}

const MemoRows = React.memo(Rows);



type RowComponentProps<RowData> = {
	columnMeta: ReadonlyArray<ColumnMeta<RowData>>,
	columnSizes: ReadonlyArray<number>,
	columnStarts: ReadonlyArray<number>,
	contextMenuEntries: any,
	disabledHighlightingRow: boolean,
	filledWidth: number,
	focusedColumn: number | null,
	handleCellClick: ((input: {
		row: RowData,
		rowIndex: number,
	}, event: React.MouseEvent) => void) | undefined,
	headers: any,
	highlightedColumn: number | null,
	isAtTheStart: boolean,
	isForcedHighlighted: boolean,
	isResizing: boolean,
	isRowClickable: ((input: {
		row: RowData,
	}) => boolean) | undefined,
	isRowInactive: ((input: {
		row: RowData | null,
		rowIndex: number,
	}) => boolean) | undefined,
	lastHeaderSize: number,
	lastHeaderStart: number,
	numberOfPinnedColumns: number,
	renderPinned: boolean,
	row: RowData | null,
	rowIndex: number,
	rowStyleGetter: ((input: {
		row: RowData,
	}) => DataCellColorStyle | null) | undefined,
	setForcedHighlightedRow: (state: number | null) => void,
	stickyContainerRef: React.RefObject<HTMLDivElement>,
	virtualRowSize: number,
	virtualRowStart: number,
};

function RowComponent<RowData>(props: RowComponentProps<RowData>) {
	const {
		columnMeta,
		columnSizes,
		columnStarts,
		contextMenuEntries,
		disabledHighlightingRow,
		filledWidth,
		focusedColumn,
		handleCellClick,
		headers,
		highlightedColumn,
		isAtTheStart,
		isForcedHighlighted,
		isResizing,
		isRowClickable,
		isRowInactive,
		lastHeaderSize,
		lastHeaderStart,
		numberOfPinnedColumns,
		row,
		rowIndex,
		rowStyleGetter,
		setForcedHighlightedRow,
		stickyContainerRef,
		virtualRowSize,
		virtualRowStart,
	} = props;

	const [isHighlighted, setIsHighlighted] = React.useState(false);

	const handleCellMouseEnter = React.useCallback(
		() => {
			setIsHighlighted(true);
		},
		[],
	);

	const handleCellMouseLeave = React.useCallback(
		() => {
			setIsHighlighted(false);
		},
		[],
	);

	const contextMenu = useContextMenu();

	const handleContextMenu = React.useCallback(
		(event, data) => {
			if (contextMenuEntries === undefined) {
				return;
			}

			contextMenu.openContextMenu(event, contextMenuEntries({ ...data, row }), {
				onOpen: () => {
					setForcedHighlightedRow(data.rowIndex);
				},
				onClose: () => {
					setForcedHighlightedRow(null);
				},
			});
		},
		[
			contextMenu,
			contextMenuEntries,
			row,
			setForcedHighlightedRow,
		],
	);

	const rowIsClickable = isRowClickable && row !== null ? isRowClickable({ row }) : true;
	const rowIsInactive = isRowInactive ? isRowInactive({ row, rowIndex }) : false;
	const rowStyle = rowStyleGetter && row !== null ? rowStyleGetter({ row }) : null;

	const handleClick = React.useMemo(
		() => {
			if (
				handleCellClick === undefined
				|| rowIsClickable === false
				|| row === null
			) {
				return;
			}

			return ({ rowIndex }, event) => {
				handleCellClick({
					row,
					rowIndex,
				}, event);
			};
		},
		[
			handleCellClick,
			row,
			rowIsClickable,
		],
	);

	return (
		<>
			{[true, false].flatMap((renderPinned) => {
				return (headers ?? []).map((header, columnIndex) => {
					if (renderPinned === (columnIndex >= numberOfPinnedColumns)) {
						return;
					}

					const columnSize = getArrayItemAtSafeIndex(columnSizes, columnIndex);
					const columnStart = getArrayItemAtSafeIndex(columnStarts, columnIndex);

					const meta = getArrayItemAtSafeIndex(columnMeta, columnIndex);

					const result = (
						<DataCell
							columnIndex={columnIndex}
							height={virtualRowSize}
							inactive={rowIsInactive}
							isBlank={false}
							isInHighlightedColumn={focusedColumn === columnIndex || highlightedColumn === columnIndex}
							isInHighlightedRow={isForcedHighlighted || isHighlighted}
							isSticky={renderPinned && isAtTheStart === false}
							key={rowIndex + '_' + header.id}
							left={columnStart}
							onClick={meta.isClickable ? handleClick : undefined}
							onContextMenu={handleContextMenu}
							onMouseEnter={(isResizing || disabledHighlightingRow) ? undefined : handleCellMouseEnter}
							onMouseLeave={(isResizing) ? undefined : handleCellMouseLeave}
							rowIndex={rowIndex}
							style={rowStyle}
							top={virtualRowStart}
							width={columnSize}
						>
							{((meta.hasData && row !== null) ? meta.hasData({ columnName: header.id, row: row as any, rowIndex }) : !!row) ? meta.renderCell({
								innerWidth: columnSize,
								row: row as any,
								rowIndex,
							}) : (
								<SquareSkeleton rowIndex={rowIndex} />
							)}
						</DataCell>
					);

					if (renderPinned) {
						if (stickyContainerRef.current === null) {
							return null;
						}

						return createPortal(
							result,
							stickyContainerRef.current,
						);
					}

					return result;
				});
			})}

			{filledWidth > 0 && (
				<DataCell
					children={undefined}
					columnIndex={columnSizes.length + 1}
					height={virtualRowSize}
					inactive={rowIsInactive}
					isBlank={true}
					isInHighlightedColumn={false}
					isInHighlightedRow={isForcedHighlighted || isHighlighted}
					isSticky={false}
					key={'filler_' + rowIndex}
					left={lastHeaderStart + lastHeaderSize}
					onClick={(handleCellClick !== undefined && rowIsClickable) ? handleCellClick : undefined}
					onContextMenu={undefined}
					onMouseEnter={(isResizing || disabledHighlightingRow) ? undefined : handleCellMouseEnter}
					onMouseLeave={(isResizing) ? undefined : handleCellMouseLeave}
					rowIndex={rowIndex}
					style={rowStyle}
					top={virtualRowStart}
					width={filledWidth}
				/>
			)}
		</>
	);
}

const MemoRowComponent = React.memo(RowComponent);

type SizedDatatableProps<RowData> = Omit<Props<RowData>, 'height' | 'width'> & {
	height?: number | 'auto',
	usageContext?: StickToScreenBottomPreset,
	width?: number | 'auto',
};

const MemoDatatable = React.memo(React.forwardRef(Datatable));

function SizedDatatable<RowData extends any>(props: SizedDatatableProps<RowData>, ref: React.ForwardedRef<DatatableRef>) {
	const {
		height = 'auto',
		usageContext = StickToScreenBottomPreset.Fullscreen,
		width = 'auto',
		...restProps
	} = props;

	if (height !== 'auto' && width !== 'auto') {
		return (
			<MemoDatatable
				height={height}
				ref={ref}
				width={width}
				{...restProps}
			/>
		);
	}

	return (
		<StickToScreenBottom preset={usageContext}>
			{({ height: containerHeight, width: containerWidth }) => (
				<MemoDatatable
					height={height === 'auto' ? containerHeight : height}
					ref={ref}
					width={width === 'auto' ? containerWidth : width}
					{...restProps}
				/>
			)}
		</StickToScreenBottom>
	);
}



export default React.forwardRef(SizedDatatable);

export {
	Context as DatatableContext,
	DataCellAlignment as DatatableCellAlignment,
	DataCellColorStyle as DatatableCellColorStyle,
	HeaderCellAlignment as DatatableHeaderCellAlignment,
	HeaderCellColorStyle as DatatableHeaderCellColorStyle,
	DatatableColumnDefinition,
	DatatableColumnDefinitions,
	DatatableContextMenuEntries,
	DatatableRef,
	DatatableRowGetterInput,
	StickToScreenBottomPreset as DatatableUsageContext,
};
