import React from 'react';
import {
	FormattedMessage,
	defineMessage,
	defineMessages,
} from 'react-intl';

import BlankValue from '~/components/patterns/values/BlankValue';
import Emphasis from '~/components/patterns/typography/Emphasis';



export enum ThirdPartyIntegrationValueFormatterType {
	AdobeAnalytics,
	GoogleAnalytics,
	GoogleSearchConsole,
}



const noDataMessage = defineMessage({
	id: 'ui.values.noData',
});

const noValueYetMessage = defineMessage({
	id: 'ui.values.noValueYet',
});

const notConnectedYetMessages = defineMessages({
	[ThirdPartyIntegrationValueFormatterType.AdobeAnalytics]: {
		id: 'ui.contentOverview.adobeAnalytics.notConnected',
	},
	[ThirdPartyIntegrationValueFormatterType.GoogleAnalytics]: {
		id: 'ui.contentOverview.googleAnalytics.notConnected',
	},
	[ThirdPartyIntegrationValueFormatterType.GoogleSearchConsole]: {
		id: 'ui.contentOverview.googleSearchConsole.notConnected',
	},
});



type Props = {
	formatter?: (value: any) => React.ReactNode,
	type: ThirdPartyIntegrationValueFormatterType,
	value: any,
};

const ThirdPartyIntegrationValueFormatter: React.FC<Props> = (props) => {
	const {
		formatter,
		type,
		value,
	} = props;

	let actualValue = value;

	if (value) {
		if (value.get) {
			actualValue = value.get('value');
		} else if (typeof value === 'object') {
			actualValue = value.value;
		}
	}

	if (actualValue === 'not_connected') {
		return (
			<Emphasis>
				<FormattedMessage {...notConnectedYetMessages[type]} />
			</Emphasis>
		);
	} else if (actualValue === 'no_data') {
		return (
			<BlankValue>
				<FormattedMessage {...noDataMessage} />
			</BlankValue>
		);
	} else if (actualValue === 'not_loaded_yet' || actualValue === null || actualValue === undefined) {
		return (
			<Emphasis>
				<FormattedMessage {...noValueYetMessage} />
			</Emphasis>
		);
	}

	return formatter ? formatter(value) : value;
};



export default ThirdPartyIntegrationValueFormatter;
