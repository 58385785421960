import React from 'react';
import ReactDOM from 'react-dom/client';

import App from '~/components/App';

import 'what-input/dist/what-input';

import {
	setupCss,
} from '~/css';

import {
	setupPolyfills,
} from '~/polyfill';

import setupPreventPullToRefresh from '~/utilities/setupPreventPullToRefresh';
import setupPreventShiftSelection from '~/utilities/setupPreventShiftSelection';

import touchSupported from '~/utilities/touchSupported';



async function setup() {
	await setupPolyfills();
	setupCss();
	setupClasses();
	setupPreventShiftSelection();
	setupPreventPullToRefresh();

	const container = document.getElementById('app');

	if (container === null) {
		throw new Error('#app element not found');
	}

	const root = ReactDOM.createRoot(container);
	root.render(<App />);
}

setup();



function setupClasses(): void {
	const html = document.documentElement;

	if (touchSupported) {
		html.classList.add('touch-support');
	} else {
		html.classList.add('no-touch-support');
	}

	if (
		navigator.userAgent.includes('Safari')
		&& !navigator.userAgent.includes('Chrome')
	) {
		html.classList.add('is-safari');
	}

	if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window)) {
		html.classList.add('iOS');
	}
}
