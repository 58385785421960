import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import SiteLogoImage, {
	SiteLogoBadge,
} from '~/components/patterns/layout/SiteLogo';

import useAccountDisplayName from '~/hooks/useAccountDisplayName';
import useAccountIsPartner from '~/hooks/useAccountIsPartner';
import useAccountLogoUrl from '~/hooks/useAccountLogoUrl';
import useHasWebsitesScreen from '~/hooks/useHasWebsitesScreen';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	EMPTY_STATE_NO_WEBSITES,
} from '~/model/ui/appEmpty';

import {
	appEmptyStateSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	logo: {
		id: 'ui.coBranding.interface.logo',
	},
});



type Props = {
	accountId: CK.AccountId | null,
};

const SiteLogo: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountDisplayName = useAccountDisplayName(accountId);
	const accountLogoUrl = useAccountLogoUrl(accountId);
	const appEmptyState = useSelector(appEmptyStateSelector);
	const hasWebsitesScreen = useHasWebsitesScreen();
	const intl = useIntl();
	const isCertifiedPartner = useAccountIsPartner(accountId);
	const websiteId = useWebsiteId() as ReturnType<typeof useWebsiteId> | null;

	const hasCustomLogo = accountLogoUrl !== null;
	let badge: SiteLogoBadge | undefined = undefined;
	let customLogoAlt: string | undefined = undefined;

	if (hasCustomLogo) {
		customLogoAlt = intl.formatMessage(messages.logo, {
			accountName: accountDisplayName,
		});
	}

	if (isCertifiedPartner !== null) {
		badge = isCertifiedPartner
			? SiteLogoBadge.CertifiedPartner
			: SiteLogoBadge.PoweredByContentKing;
	}

	let routeName;
	let routeParams;

	if (appEmptyState === EMPTY_STATE_NO_WEBSITES) {
		routeName = 'home';
		routeParams = {};
	} else if (hasWebsitesScreen) {
		routeName = 'multiDashboard';
		routeParams = {};
	} else if (websiteId !== null) {
		routeName = 'website.dashboard';
		routeParams = {
			websiteId,
		};
	}

	return (
		<SiteLogoImage
			badge={badge}
			customLogoAlt={customLogoAlt}
			customLogoSrc={accountLogoUrl ?? undefined}
			routeName={routeName}
			routeParams={routeParams}
		/>
	);
};


export default SiteLogo;
