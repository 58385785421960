import classNames from 'classnames';
import React from 'react';



type Props = {
	children: React.ReactNode,
	/** Stretched buttons to the full-width of available space in container */
	fullwidth?: boolean,
	/** Custom width of button */
	width?: React.CSSProperties['width'],
};



const SwitchButtonsGroup: React.FC<Props> = (props) => {
	const {
		fullwidth = true,
		children,
		width,
	} = props;

	const fieldClasses = classNames({
		'switch-buttons-group': true,
		'switch-buttons-group--fullwidth': fullwidth,
	});

	const style: React.CSSProperties = {};

	if (width) {
		style.width = width;
	}

	return (
		<div
			className={fieldClasses}
			style={style}
		>
			{children}
		</div>
	);
};



export default SwitchButtonsGroup;
