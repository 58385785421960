import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import BasicIcon, {
	BasicIconType,
	RealtimeIndexNowIconVariants,
} from '~/components/patterns/icons/BasicIcon';
import IssueTitle from '~/components/logic/issues/IssueTitle';
import NoticeTag from '~/components/patterns/tags/NoticeTag';
import TextualList from '~/components/patterns/lists/TextualList';

import {
	type IssueName,
} from '~/model/issuesNew';



const nativeTriggerLabels = defineMessages({
	contentChangedH1: {
		id: 'ui.indexNowNativeTriggersModal.contentChange.h1',
	},
	contentChangedH2ToH6: {
		id: 'ui.indexNowNativeTriggersModal.contentChange.h2-h6',
	},
	contentChangedMetaDescription: {
		id: 'ui.indexNowNativeTriggersModal.contentChange.metaDescription',
	},
	contentChangedTitle: {
		id: 'ui.indexNowNativeTriggersModal.contentChange.title',
	},
	pageBecameIndexable: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameIndexable',
	},
	pageBecameMissing: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameMissing',
	},
	pageBecameNonIndexable: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameNonIndexable',
	},
	pageBecameRedirect: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameRedirect',
	},
	pageWasAdded: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageWasAdded',
	},
	pageWasRestored: {
		id: 'ui.indexNowNativeTriggersModal.pageStatusChange.pageWasRestored',
	},
});



type Props = {
	indexNowSubmission: GraphQL.IndexNowSubmission,
};

const IndexNowSubmissionBadge: React.FC<Props> = (props) => {
	const {
		indexNowSubmission,
	} = props;

	const isSubmitted = (
		indexNowSubmission.disabledCustomElementTriggers.length === 0
		&& indexNowSubmission.disabledNativeTriggers.length === 0
		&& indexNowSubmission.requiredNotPassingIssues.length === 0
		&& indexNowSubmission.skippedBecauseNotEnabled === false
		&& indexNowSubmission.skippedBecauseNotImplemented === false
		&& indexNowSubmission.skippedBecauseNotIndexable === false
		&& indexNowSubmission.skippedBecauseNotPassingSegmentBlocklist === false
		&& indexNowSubmission.skippedBecauseNotPassingUrlBlocklist === false
		&& indexNowSubmission.skippedBecauseNothingTriggered === false
	);

	const reasons: Array<React.ReactNode> = [];

	if (indexNowSubmission.skippedBecauseNotImplemented) {
		reasons.push(
			<>Submitting to IndexNow was introduced after this change was detected.</>,
		);
	} else if (indexNowSubmission.skippedBecauseNotEnabled) {
		reasons.push(
			<>Submitting to IndexNow was not enabled when this change was detected.</>,
		);
	} else if (isSubmitted === false) {
		if (indexNowSubmission.skippedBecauseNothingTriggered) {
			reasons.push(
				<>None of these changes are possible triggers for IndexNow submission.</>,
			);
		} else if (
			indexNowSubmission.disabledNativeTriggers.length > 0
			|| indexNowSubmission.disabledCustomElementTriggers.length > 0
		) {
			const disabledElements: Array<React.ReactNode> = [];

			indexNowSubmission.disabledNativeTriggers.forEach(
				(nativeTrigger) => disabledElements.push(
					<FormattedMessage
						key={nativeTrigger}
						{...nativeTriggerLabels[nativeTrigger]}
					/>,
				),
			);

			indexNowSubmission.disabledCustomElementTriggers.forEach(
				(customElement) => disabledElements.push(customElement),
			);

			reasons.push(
				<>
					These changes could have triggered submission, but were not enabled in the IndexNow configuration:
					<TextualList>
						{disabledElements}
					</TextualList>
				</>,
			);
		}

		if (indexNowSubmission.skippedBecauseNotIndexable) {
			reasons.push(
				<>This page was non-indexable when this change was detected. Only indexable pages are submitted to IndexNow.</>,
			);
		} else if (
			indexNowSubmission.skippedBecauseNotPassingUrlBlocklist
			&& indexNowSubmission.skippedBecauseNotPassingSegmentBlocklist
		) {
			reasons.push(
				<>This page was excluded from submitting to IndexNow by the segment blocklist and URL blocklist.</>,
			);
		} else if (indexNowSubmission.skippedBecauseNotPassingUrlBlocklist) {
			reasons.push(
				<>This page was excluded from submitting to IndexNow by the URL blocklist.</>,
			);
		} else if (indexNowSubmission.skippedBecauseNotPassingSegmentBlocklist) {
			reasons.push(
				<>This page was excluded from submitting to IndexNow by the segment blocklist.</>,
			);
		} else if (indexNowSubmission.requiredNotPassingIssues.length > 0) {
			const blockingIssues: Array<React.ReactNode> = [];

			indexNowSubmission.requiredNotPassingIssues.forEach((issue) => {
				blockingIssues.push(
					<IssueTitle
						issue={{
							context: {
								type: null,
							},
							name: issue as IssueName,
							state: GraphQL.IssueState.Open,
						}}
						key={issue}
					/>,
				);
			});

			reasons.push(
				<>
					Issues required by the IndexNow configuration blocked the submission:
					<TextualList>
						{blockingIssues}
					</TextualList>
				</>,
			);
		}
	}

	return (
		<AttachedElement
			element={isSubmitted === false && (
				<NoticeTag
					label="why?"
					tooltip={(
						<TextualList textOnlyWhenSingle={true}>
							{reasons.map((reason, i) => (
								<React.Fragment key={i}>
									{reason}
								</React.Fragment>
							))}
						</TextualList>
					)}
				/>
			)}
		>
			<AttachedIcon
				ellipsis={false}
				icon={(
					<BasicIcon
						size={22}
						type={BasicIconType.RealtimeIndexNow}
						variant={isSubmitted ? RealtimeIndexNowIconVariants.Default : RealtimeIndexNowIconVariants.NotAvailable}
					/>
				)}
				inlineIcon={true}
			>
				{isSubmitted ? (
					<>Submitted to IndexNow</>
				) : (
					<>Not submitted to IndexNow</>
				)}
			</AttachedIcon>
		</AttachedElement>
	);
};



export default IndexNowSubmissionBadge;
