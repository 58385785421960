import React from 'react';

import MissingValue from '~/components/app/MissingValue';
import YesOrNo from '~/components/app/YesOrNo';



type Props = {
	value: boolean | null,
};

const BooleanFormatter: React.FC<Props> = (props) => {
	const {
		value = null,
	} = props;

	if (value === null) {
		return (
			<MissingValue ellipsis={true} />
		);
	}

	return (
		<YesOrNo state={value} />
	);
};



export default React.memo(BooleanFormatter);
