import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import PaymentMethodLabel, {
	PaymentMethodLabelStyle,
} from '~/components/patterns/forms/fieldParts/PaymentMethodLabel';
import RadioTagsList from '~/components/patterns/forms/fields/RadioTagsList';

import {
	validateField,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';

import {
	METHOD_CARD,
	METHOD_INVOICE,
	METHOD_SEPA_DEBIT,
} from '~/model/payments';



const messages = defineMessages({
	[METHOD_CARD]: {
		id: 'ui.newTeam.form.creditCard',
	},
	[METHOD_INVOICE]: {
		id: 'ui.newTeam.form.invoice',
	},
	[METHOD_SEPA_DEBIT]: {
		id: 'ui.newTeam.form.sepaDebit',
	},
});



type Props = {
	paymentMethods: Readonly<Array<string>>,
	name: string,
};

const PaymentMethodField: React.FC<Props> = (props) => {
	const {
		name,
		paymentMethods,
	} = props;

	const formContext = useFormContext();

	const isDisabled = formContext.isSubmitting;

	const items = paymentMethods.map((paymentMethod) => {
		return {
			disabled: isDisabled,
			label: (
				<PaymentMethodLabel
					isDisabled={isDisabled}
					style={PaymentMethodLabelStyle.TextLabel}
				>
					<FormattedMessage {...messages[paymentMethod]} />
				</PaymentMethodLabel>
			),
			value: paymentMethod,
		};
	});

	return (
		<RadioTagsList
			itemMaxWidth={170}
			items={items}
			name={name}
		/>
	);
};



export default PaymentMethodField;

export function validatePaymentMethodField(name: string) {
	return validateField(
		name,
		(f) => [
			f.validateNonEmpty(),
		],
	);
}
