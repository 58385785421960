import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AnimateHeartbeat from '~/components/logic/score/AnimateHeartbeat';
import IssueCategoryIgnoringButton from '~/components/logic/issues/IssueCategoryIgnoringButton';
import IssueCategoryNameIcon, {
	IssueCategoryNameIconSize,
} from '~/components/app/IssueCategoryNameIcon';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import IssueCategoryNotApplicable from '~/components/app/IssueCategoryNotApplicable';
import IssueConfigButton from '~/components/logic/issues/IssueConfigButton';
import IssueScore, {
	IssueScoreSize,
	IssueScoreState,
} from '~/components/patterns/issues/IssueScore';
import IssuesDetailLayout from '~/components/atoms/issues/components/issueDetail/IssuesDetailLayout';
import WithPermission from '~/components/logic/access/WithPermission';

import useKeepAround from '~/hooks/useKeepAround';
import useSortedIssues from '~/hooks/useSortedIssues';

import {
	type IssueCategoryName,
	type IssueName,
	getPointsToBeDisplayed,
	isRecognizedIssue,
	sortIssues,
} from '~/model/issuesNew';

import {
	ObjectType,
} from '~/model/permissions';

import matchAndReturn from '~/utilities/matchAndReturn';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type IssueCategory = {
	configuration: Record<string, any>,
	isActionable: boolean,
	isIgnored: boolean,
	issues: ReadonlyArray<{
		configuration: Record<string, any>,
		isConfigurationModified: boolean,
		name: string,
		state: GraphQL.IssueState,
	}>,
	name: IssueCategoryName,
	pointsGained: number,
	pointsToGain: number,
	state: GraphQL.IssueCategoryState,
};

type Props<TIssueCategory extends IssueCategory> = {
	issueCategory: TIssueCategory | null,
	renderCategoryIllustration?: RenderProp<{
		issueCategory: TIssueCategory,
	}>,
	renderIssueDetail: RenderProp<{
		issue: TIssueCategory['issues'][0] & {
			name: IssueName,
		},
		issueCategory: TIssueCategory,
	}>,
	websiteId: CK.WebsiteId,
};

function IssueCategoryDetail<TIssueCategory extends IssueCategory>(props: Props<TIssueCategory>) {
	const {
		issueCategory: propIssueCategory,
		renderCategoryIllustration,
		renderIssueDetail,
		websiteId,
	} = props;

	const issueCategory = useKeepAround(propIssueCategory);

	const sortedIssues = useSortedIssues(
		issueCategory?.issues ?? null,
		sortIssues,
	);

	if (issueCategory === null) {
		return (
			<IssuesDetailLayout
				isLoading={true}
			/>
		);
	}

	const categoryIllustration = renderProp(renderCategoryIllustration, {
		issueCategory,
	});

	return (
		<IssuesDetailLayout
			actionElements={[
				(
					<WithPermission
						action={GraphQL.ActionWithWebsite.ManageIssuesConfiguration}
						key={'configuration/' + issueCategory.name}
						objectId={websiteId}
						objectType={ObjectType.Website}
					>
						{({ isAllowed }) => {
							if (isAllowed.yes === false) {
								return null;
							}

							return (
								<IssueConfigButton
									hasConfiguration={Object.values(issueCategory.configuration).length > 0}
									issueCategoryName={issueCategory.name}
									websiteId={websiteId}
								/>
							);
						}}
					</WithPermission>
				),
				(
					<WithPermission
						action={GraphQL.ActionWithWebsite.ManageIgnoring}
						key={'ignoring/' + issueCategory.name}
						objectId={websiteId}
						objectType={ObjectType.Website}
					>
						{({ isAllowed }) => {
							if (isAllowed.yes === false) {
								return null;
							}

							return (
								<IssueCategoryIgnoringButton
									issueCategoryName={issueCategory.name}
								/>
							);
						}}
					</WithPermission>
				),
				(
					<AnimateHeartbeat
						enabled={issueCategory.isActionable}
						key={'score/' + issueCategory.name}
					>
						<IssueScore
							isIgnored={issueCategory.isIgnored}
							size={IssueScoreSize.Large}
							state={matchAndReturn(issueCategory.state, {
								[GraphQL.IssueCategoryState.Closed]: IssueScoreState.Fixed,
								[GraphQL.IssueCategoryState.NotApplicable]: IssueScoreState.NotApplicable,
								[GraphQL.IssueCategoryState.NotRequired]: IssueScoreState.NotRequired,
								[GraphQL.IssueCategoryState.Open]: IssueScoreState.Broken,
								[GraphQL.IssueCategoryState.Unknown]: IssueScoreState.Unknown,
							})}
							value={getPointsToBeDisplayed(issueCategory)}
						/>
					</AnimateHeartbeat>
				),
			]}
			icon={(
				<IssueCategoryNameIcon
					issueCategoryName={issueCategory.name}
					size={IssueCategoryNameIconSize.Small}
				/>
			)}
			illustration={categoryIllustration}
			title={(
				<IssueCategoryTitle
					issueCategoryName={issueCategory.name}
				/>
			)}
		>
			{issueCategory.state !== GraphQL.IssueCategoryState.NotApplicable ? (
				sortedIssues.filter(isRecognizedIssue).filter((issue) => issue.state !== GraphQL.IssueState.NotApplicable).map(
					(issue) => (
						<React.Fragment key={issue.name}>
							{renderProp(renderIssueDetail, {
								issue,
								issueCategory,
							})}
						</React.Fragment>
					),
				)
			) : (
				<IssueCategoryNotApplicable
					issueCategoryName={issueCategory.name}
					issueCategoryPoints={getPointsToBeDisplayed(issueCategory)}
					issues={issueCategory.issues}
				/>
			)}
		</IssuesDetailLayout>
	);
}



export default IssueCategoryDetail;
