import {
	easeOutQuint,
} from './easings';

/**
 * VanillaJS version on animated scrolling
 *
 * Inspired by https://stackoverflow.com/questions/8917921/cross-browser-javascript-not-jquery-scroll-to-top-animation
 */

export function verticalScrollTo(element, to, speed, easing) {
	// speed: time in pixels per second
	// easing: easing equation to use

	speed = speed || 1000;
	easing = easing || easeOutQuint;

	const from = element.scrollTop;
	let currentTime = 0;

	// min time .1, max time .8 seconds
	const time = Math.max(.1, Math.min(Math.abs(from - to) / speed, .8));

	// add animation loop
	function tick() {
		currentTime += 1 / 60;

		const p = currentTime / time;
		const t = easing(p);

		if (p < 1) {
			requestAnimationFrame(tick);
			element.scrollTop = from + ((to - from) * t);
		} else {
			element.scrollTop = to;
		}
	}

	// call it once to get started
	tick();
}



export function horizontalScrollTo(element, to, speed, easing) {
	// speed: time in pixels per second
	// easing: easing equation to use

	speed = speed || 1000;
	easing = easing || easeOutQuint;

	const from = element.scrollLeft;
	let currentTime = 0;

	// min time .1, max time .8 seconds
	const time = Math.max(.1, Math.min(Math.abs(from - to) / speed, .8));

	// add animation loop
	function tick() {
		currentTime += 1 / 60;

		const p = currentTime / time;
		const t = easing(p);

		if (p < 1) {
			requestAnimationFrame(tick);
			element.scrollLeft = from + ((to - from) * t);
		} else {
			element.scrollLeft = to;
		}
	}

	// call it once to get started
	tick();
}

