import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import IssueCategoriesSidebarSummary from '~/components/app/IssueCategoriesSidebarSummary';
import NoIssuesNotification from '~/components/atoms/pageDetail/components/NoIssuesNotification';
import PageIssueCategoriesList from '~/components/logic/issues/issuesCategoriesList/PageIssueCategoriesList';

import usePageIsPristine from '~/hooks/usePageIsPristine';
import usePageIssueCategories from '~/hooks/usePageIssueCategories';
import usePageStatusCode from '~/hooks/usePageStatusCode';
import usePageType from '~/hooks/usePageType';



type Props = {
	legacyUrlId: number,
	returnRouteName: string,
	websiteId: CK.WebsiteId,
};

const PageIssuesSidebar: React.FC<Props> = (props) => {
	const {
		legacyUrlId,
		returnRouteName,
		websiteId,
	} = props;

	const pageIsPristine = usePageIsPristine(legacyUrlId, websiteId);
	const pageIssueCategories = usePageIssueCategories(legacyUrlId, websiteId);
	const pageStatusCode = usePageStatusCode(legacyUrlId, websiteId);
	const pageType = usePageType(legacyUrlId, websiteId);

	if (
		pageType === null
		|| pageType === GraphQL.PageType.OverQuota
	) {
		return null;
	}

	if (pageType !== GraphQL.PageType.Page) {
		return (
			<NoIssuesNotification
				pageIsPristine={pageIsPristine}
				pageStatusCode={pageStatusCode}
				pageType={pageType}
			/>
		);
	}

	return (
		<IssueCategoriesSidebarSummary
			isLoading={pageIssueCategories === null}
			renderIssueCategoriesList={({ isActionable }) => (
				<PageIssueCategoriesList
					legacyUrlId={legacyUrlId}
					numberOfPlaceholders={4}
					returnRouteName={returnRouteName}
					showActionable={isActionable}
					websiteId={websiteId}
				/>
			)}
		/>
	);
};



export default PageIssuesSidebar;
