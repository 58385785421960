import React from 'react';

import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import VerifyWebsiteModal from '~/components/app/VerifyWebsiteModal';

import useOpenModal from '~/hooks/useOpenModal';



type Props = {
	children?: React.ReactNode,
	style?: InternalLinkStyle,
};

const VerifyWebsiteLink: React.FC<Props> = (props) => {
	const {
		children,
		style,
	} = props;

	const openVerifyWebsiteModal = useOpenModal(
		() => (
			<VerifyWebsiteModal />
		),
		[],
	);

	return (
		<InternalLink
			onClickCallback={openVerifyWebsiteModal}
			style={style}
		>
			{children}
		</InternalLink>
	);
};



export default VerifyWebsiteLink;

export {
	InternalLinkStyle as VerifyWebsiteLinkStyle,
};
