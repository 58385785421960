import classNames from 'classnames';
import React from 'react';



export enum FlashMessageType {
	Critical = 'critical',
	Success = 'success',
	Warning = 'warning',
}

type Props = {
	/** Text of message */
	children: React.ReactNode,
	/** Type of message */
	type: FlashMessageType,
};



const FlashMessage: React.FC<Props> = (props) => {
	const {
		children,
		type,
	} = props;

	const componentClasses = classNames({
		'flash-message': true,
		'flash-message--critical': type === FlashMessageType.Critical,
		'flash-message--success': type === FlashMessageType.Success,
		'flash-message--warning': type === FlashMessageType.Warning,
	});

	return (
		<div className={componentClasses}>
			<div className="flash-message__content">
				{children}
			</div>
		</div>
	);
};



export default FlashMessage;
