import React from 'react';



type Props = {
	children: React.ReactNode,
	/** Additional switch element prepended to whole navigation */
	switchElement?: React.ReactNode,
};



const HeaderNavigationLayout: React.FC<Props> = (props) => {
	const {
		children,
		switchElement,
	} = props;

	return (
		<div className="header-navigation-layout">
			{switchElement && (
				<div className="header-navigation-layout__switch-element">
					{switchElement}
				</div>
			)}
			<div className="header-navigation-layout__navigation">
				{children}
			</div>
		</div>
	);
};



export default HeaderNavigationLayout;
