import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const DiscountValue: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<span className="discount-value">
			<span className="discount-value__value">
				{children}
			</span>
		</span>
	);
};



export default DiscountValue;
