import React from 'react';

import SelectField from '~/components/atoms/forms/components/SelectField';

import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';



type Props = {
	name: string,
};

const EnrichmentFieldSelectField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	const websiteId = useWebsiteId();

	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	const options = enrichmentFieldDefinitions.listAll().map(
		(enrichmentFieldDefinition) => ({
			label: enrichmentFieldDefinition.label,
			name: enrichmentFieldDefinition.column,
		}),
	);

	return (
		<SelectField
			isDisabled={options.length === 0}
			name={name}
			options={options}
		/>
	);
};



export default React.memo(EnrichmentFieldSelectField);
