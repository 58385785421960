import React from 'react';

import type CK from '~/types/contentking';

import CancelButton from '~/components/app/CancelButton';
import Form from '~/components/atoms/forms/basis/Form';
import ModalButtonsLayout from '~/components/patterns/modals/parts/ModalButtonsLayout';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';

import {
	useRemoveUnclickablePagesMutation,
} from './RemoveUnclickablePagesModal.gql';



type Props = {
	websiteId: CK.WebsiteId,
};

const RemoveUnclickablePagesModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();

	const [removeUnclickablePages] = useRemoveUnclickablePagesMutation();

	const handleSubmit = React.useCallback(
		async () => {
			await removeUnclickablePages({
				variables: {
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			removeUnclickablePages,
			websiteId,
		],
	);

	return (
		<SimpleModal
			size={SimpleModalSize.Small}
			title="Remove unclickable pages"
		>
			<Form
				defaultDataHasChanged={true}
				onSuccess={handleSubmit}
			>
				<ModalButtonsLayout>
					<CancelButton />

					<SubmitButton>
						Remove unclickable pages
					</SubmitButton>
				</ModalButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default RemoveUnclickablePagesModal;
