import React from 'react';



const Context = React.createContext();



const UnifiedHeightGroup = (props) => {
	const {
		children,
	} = props;

	const [state, setState] = React.useState({});

	const reportHeight = React.useCallback(
		(name, height) => {
			setState((state) => {
				return {
					...state,
					[name]: height,
				};
			});
		},
		[],
	);

	const contextValue = React.useMemo(
		() => {
			let maximumHeight = null;

			for (const name of Object.getOwnPropertySymbols(state)) {
				const height = state[name];

				if (maximumHeight === null || maximumHeight < height) {
					maximumHeight = height;
				}
			}

			return {
				maximumHeight,
				reportHeight,
			};
		},
		[
			reportHeight,
			state,
		],
	);

	return (
		<Context.Provider value={contextValue}>
			{children}
		</Context.Provider>
	);
};



export const UnifiedHeightGroupContext = Context;

export default UnifiedHeightGroup;
