import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';



const ELLIPSIS_POPUP_ZINDEX = 2000;



const messagesColumn = defineMessages({
	directive: {
		id: 'ui.robotDirectives.directive',
	},
	method: {
		id: 'ui.robotDirectives.method',
	},
	searchEngine: {
		id: 'ui.robotDirectives.searchEngine',
	},
});

const messagesProperty = defineMessages({
	meta_bingbot: {
		id: 'ui.contentData.meta_bingbot',
	},
	meta_googlebot: {
		id: 'ui.contentData.meta_googlebot',
	},
	meta_robots: {
		id: 'ui.contentData.meta_robots',
	},
	meta_slurp: {
		id: 'ui.contentData.meta_slurp',
	},
	meta_yandex: {
		id: 'ui.contentData.meta_yandex',
	},
	x_robots_tag: {
		id: 'ui.contentData.x_robots_tag',
	},
});



type Props = {
	issueContext: {
		conflicts: ReadonlyArray<
			ReadonlyArray<{
				index: number,
				property: string,
				searchEngine: string,
				value: string,
			}>
		>,
	},
	tableWidth: number,
};

const ConflictingRobotDirectivesTable: React.FC<Props> = (props) => {
	const {
		issueContext,
		tableWidth,
	} = props;

	const conflicts = issueContext.conflicts;

	const rows = React.useMemo(
		() => {
			const result: Array<{
				header: true,
				conflictNumber: number,
			} | {
				index: number,
				property: string,
				searchEngine: string,
				value: string,
			}> = [];

			conflicts.forEach((conflict, i) => {
				result.push({
					header: true,
					conflictNumber: i + 1,
				});

				conflict.forEach((directive) => {
					result.push(directive);
				});
			});

			return result;
		},
		[
			conflicts,
		],
	);

	const firstColumnWidth = Math.floor(tableWidth * (1 / 3));
	const secondColumnWidth = Math.floor(tableWidth * (1 / 3));
	const lastColumnWidth = tableWidth - firstColumnWidth - secondColumnWidth - 3;

	return (
		<SmallTable
			columns={[
				{
					render: {
						cell: ({ row }) => {
							if ('header' in row) {
								return {
									colspan: 3,
									content: 'Conflict #' + row.conflictNumber,
								};
							}

							return (
								<Ellipsis popupZIndex={ELLIPSIS_POPUP_ZINDEX}>
									<FormattedMessage {...messagesProperty[row.property]} /> #{row.index + 1}
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messagesColumn.method} />
						),
					},
					width: firstColumnWidth,
				},
				{
					render: {
						cell: ({ row }) => {
							if ('header' in row) {
								return null;
							}

							return (
								<Ellipsis popupZIndex={ELLIPSIS_POPUP_ZINDEX}>
									{row.searchEngine}
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messagesColumn.searchEngine} />
						),
					},
					width: secondColumnWidth,
				},
				{
					render: {
						cell: ({ row }) => {
							if ('header' in row) {
								return null;
							}

							return (
								<Ellipsis>
									<CodeValue>
										{row.value}
									</CodeValue>
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messagesColumn.directive} />
						),
					},
					width: lastColumnWidth,
				},
			]}
			rows={rows}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(ConflictingRobotDirectivesTable);
