import classNames from 'classnames';
import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';



export type ChartLegendItemProps = {
	color: string | undefined,
	count?: number | null,
	countDisplay?: React.ReactNode,
	label: string,
};

type Props = {
	/** Show legend items inline */
	inline?: boolean,
	items: Array<ChartLegendItemProps>,
	/** Possibility to show number in defined color */
	showColoredNumbers?: boolean,
	/** Possibility to show muber sign. This means that we can prepend + for numbers greater than zero. And prepend - for numbers lower than zero. */
	showNumberSign?: boolean,
};



const ChartLegend: React.FC<Props> = (props) => {
	const {
		inline,
		items,
		showColoredNumbers,
		showNumberSign,
	} = props;

	const _renderCountElement = (count: number, countDisplay: React.ReactNode, color: string | undefined) => {
		return (
			<span
				className="chart-legend__count"
				style={showColoredNumbers ? {
					color,
				} : undefined}
			>
				{showNumberSign && count > 0 && '+'}{countDisplay || <FormattedNumber value={count} />}
			</span>
		);
	};

	const componentClasses = classNames({
		'chart-legend': true,
		'chart-legend--column-flow': !inline,
	});

	return (
		<ul className={componentClasses}>
			{items.map((item, index) => {
				return (
					<li
						className="chart-legend__item"
						key={'item-' + index}
					>
						<i
							className="chart-legend__mark"
							style={{
								backgroundColor: item.color,
							}}
						/>
						<span className="chart-legend__label">
							{item.label}
						</span>
						{item.count !== undefined && item.count !== null && (
							_renderCountElement(item.count, item.countDisplay, item.color)
						)}
					</li>
				);
			})}
		</ul>
	);
};



export default ChartLegend;
