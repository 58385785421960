import {
	type FieldFunctionOptions,
	type FieldMergeFunction,
} from '@apollo/client';



type FieldMergePolicyForExpiringEdgesFunction = FieldMergeFunction<
	ReadonlyArray<Record<string, any> | null>,
	ReadonlyArray<Record<string, any> | null>,
	FieldFunctionOptions & {
		variables: {
			after: string | null,
			before: string | null,
			limit: number,
			offset: number,
		},
	}
>;

export function fieldMergePolicyForExpiringEdges(): FieldMergePolicyForExpiringEdgesFunction {
	return (
		existing,
		incoming,
		{
			variables: {
				after,
				before,
				limit,
				offset,
			},
		},
	) => {
		const merged = existing ? existing.slice(0) : [];

		after ??= null;
		before ??= null;

		let effectiveOffset = offset;

		if (after !== null) {
			effectiveOffset = merged.findIndex(
				(edge) => edge?.cursor === after,
			) + 1;
		} else if (before !== null) {
			effectiveOffset = merged.findIndex(
				(edge) => edge?.cursor === before,
			) - limit;
		}

		const loadedAt = Date.now();

		for (let i = 0; i < incoming.length; i++) {
			merged[effectiveOffset + i] = {
				...incoming[i],
				loadedAt,
			};
		}

		return [...merged].map(
			(edge) => edge ?? null,
		);
	};
}
