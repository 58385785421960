/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAccountProfileMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  name: GraphQL.Scalars['String']['input'];
  removeCustomLogo: GraphQL.InputMaybe<GraphQL.Scalars['Boolean']['input']>;
  setCustomLogo: GraphQL.InputMaybe<GraphQL.AccountCustomLogoInput>;
}>;


export type UpdateAccountProfileMutation = { readonly __typename?: 'Mutation', readonly UpdateAccountProfile: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId, readonly logoUrl: string | null, readonly name: string | null } | null } } };


export const UpdateAccountProfileDocument = gql`
    mutation UpdateAccountProfile($accountId: AccountId!, $name: String!, $removeCustomLogo: Boolean, $setCustomLogo: AccountCustomLogoInput) {
  UpdateAccountProfile(
    accountId: $accountId
    name: $name
    removeCustomLogo: $removeCustomLogo
    setCustomLogo: $setCustomLogo
  ) {
    query {
      account(id: $accountId) {
        displayName
        id
        logoUrl
        name
      }
    }
  }
}
    `;
export type UpdateAccountProfileMutationFn = Apollo.MutationFunction<UpdateAccountProfileMutation, UpdateAccountProfileMutationVariables>;

/**
 * __useUpdateAccountProfileMutation__
 *
 * To run a mutation, you first call `useUpdateAccountProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountProfileMutation, { data, loading, error }] = useUpdateAccountProfileMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      name: // value for 'name'
 *      removeCustomLogo: // value for 'removeCustomLogo'
 *      setCustomLogo: // value for 'setCustomLogo'
 *   },
 * });
 */
export function useUpdateAccountProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountProfileMutation, UpdateAccountProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountProfileMutation, UpdateAccountProfileMutationVariables>(UpdateAccountProfileDocument, options);
      }
export type UpdateAccountProfileMutationHookResult = ReturnType<typeof useUpdateAccountProfileMutation>;
export type UpdateAccountProfileMutationResult = Apollo.MutationResult<UpdateAccountProfileMutation>;
export type UpdateAccountProfileMutationOptions = Apollo.BaseMutationOptions<UpdateAccountProfileMutation, UpdateAccountProfileMutationVariables>;