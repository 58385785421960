import React from 'react';

import type GraphQL from '~/types/graphql';

import ContactUsButton from '~/components/logic/account/components/ContactUsButton';
import SignUpButton from '~/components/logic/account/components/SignUpButton';

import useAccountId from '~/hooks/useAccountId';
import useAllowedPlans from '~/hooks/useAllowedPlans';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';



type Props = {
	dominant?: boolean,
	plan: GraphQL.AccountPlan,
};

const SignupPlanComparisonButton: React.FC<Props> = (props) => {
	const {
		dominant = false,
		plan,
	} = props;

	const accountId = useAccountId();
	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const allowedPlans = useAllowedPlans(accountId);

	if (accountId === null || allowedPlans === null || accountIsOutOfBand === null) {
		return null;
	}

	let showContactUsButton = false;

	if (accountIsOutOfBand) {
		showContactUsButton = true;
	}

	if (!allowedPlans.includes(plan)) {
		showContactUsButton = true;
	}

	if (showContactUsButton) {
		return (
			<ContactUsButton dominant={dominant} />
		);
	}

	return (
		<SignUpButton
			accountId={accountId}
			dominant={dominant}
			plan={plan}
		/>
	);
};



export default SignupPlanComparisonButton;
