import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	useSetupIndexNowKeyMutation,
} from './SetupIndexNowKeyModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	validateField,
} from './validations';



const messages = defineMessages({
	description: {
		id: 'ui.setupIndexNowKeyModal.description',
	},
	indexNowKey: {
		id: 'ui.setupIndexNowKeyModal.indexNowKey',
	},
	invalidIndexNowKey: {
		id: 'ui.setupIndexNowKeyModal.invalidIndexNowKey',
	},
	saveButton: {
		id: 'ui.general.saveButton',
	},
	sidebar: {
		id: 'ui.setupIndexNowKeyModal.sidebar',
	},
	title: {
		id: 'ui.setupIndexNowKeyModal.title',
	},
});

const validations = {
	validateIndexNowKey: validateField(
		'indexNowKey',
		(f) => ([
			f.validateNonEmpty(),
		]),
	),
};



type Props = {
	onFinish: (isIndexNowEnabled: boolean) => void,
};

const SetupIndexNowKeyModal: React.FC<Props> = (props) => {
	const {
		onFinish,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const websiteId = useWebsiteId();
	const [setupIndexNowKey] = useSetupIndexNowKeyMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			const response = await setupIndexNowKey({
				variables: {
					indexNowKey: values.indexNowKey,
					websiteId,
				},
			});

			onFinish(
				response.data?.SetupIndexNowKey.query.website?.isIndexNowEnabled ?? false,
			);

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			onFinish,
			setupIndexNowKey,
			websiteId,
		],
	);

	return (
		<SimpleModal
			iconType={BasicIconType.Plus}
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							linkSupport: linkExternal('https://www.contentkingapp.com/support/realtime-indexnow'),
						}}
					/>
				</RichText>
			)}
			size={SimpleModalSize.XLarge}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ModalTextSection>
				<Copy {...messages.description} />
			</ModalTextSection>

			<Form
				onSuccess={handleSubmit}
				validations={validations}
			>
				<CenteredFormWrapper>
					<FormRow
						htmlFor="indexNowKey"
						label={(
							<FormattedMessage {...messages.indexNowKey} />
						)}
					>
						<FieldStatus
							allowOk={false}
							name="validateIndexNowKey"
						>
							<TextField
								name="indexNowKey"
								spellCheck={false}
							/>
						</FieldStatus>
					</FormRow>

					<FormErrorMessages
						errors={{
							invalidIndexNowKey: (
								<FormattedMessage {...messages.invalidIndexNowKey} />
							),
						}}
					/>

					<ButtonsLayout>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.saveButton} />
						</SubmitButton>
					</ButtonsLayout>
				</CenteredFormWrapper>
			</Form>
		</SimpleModal>
	);
};



export default SetupIndexNowKeyModal;
