import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountNameQuery,
} from './useAccountName.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountName(accountId: CK.AccountId | null): GraphQL.Account['name'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountNameQuery,
		accountId,
	);

	return data?.account?.name ?? null;
}



export default useAccountName;
