import classNames from 'classnames';
import React from 'react';



export enum ExpressiveValueLabelFlow {
	Column = 'column',
	Row = 'row',
}

export enum ExpressiveValueSize {
	Default = 'default',
	Large = 'large',
}

export enum ExpressiveValueStatus {
	Critical = 'critical',
	Neutral = 'neutral',
	Success = 'success',
	Warning = 'warning',
}

type Props = {
	label?: React.ReactNode,
	/** Flow of label */
	labelFlow?: ExpressiveValueLabelFlow,
	/** Possible size of output */
	size?: ExpressiveValueSize,
	status?: ExpressiveValueStatus,
	value: React.ReactNode,
};



const ExpressiveValue: React.FC<Props> = (props) => {
	const {
		label,
		labelFlow = ExpressiveValueLabelFlow.Row,
		size = ExpressiveValueSize.Default,
		status = ExpressiveValueStatus.Neutral,
		value,
	} = props;

	const componentClasses = classNames({
		'expressive-value': true,
		'expressive-value--column-flow': labelFlow === ExpressiveValueLabelFlow.Column,
		'expressive-value--row-flow': labelFlow === ExpressiveValueLabelFlow.Row,
		'expressive-value--size-large': size === ExpressiveValueSize.Large,
		[ 'expressive-value--status-' + status ]: true,
	});

	return (
		<span className={componentClasses}>
			<span className="expressive-value__value">
				{value}
			</span>
			{label && (
				<span className="expressive-value__label">
					{label}
				</span>
			)}
		</span>
	);
};



export default ExpressiveValue;
