import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Look of card when plan is disabled */
	isDisabled?: boolean,
	/** Apply enterprise highlight */
	isEnterprise?: boolean,
	/** Plan name */
	name: React.ReactNode,
	/** callback triggered on click */
	onClick?: () => void,
	/** Price overview info */
	priceOverview: React.ReactNode,
};



const PlanCard: React.FC<Props> = (props) => {
	const {
		isDisabled,
		isEnterprise,
		name,
		onClick,
		priceOverview,
	} = props;

	const componentClasses = classNames({
		'plan-card': true,
		'plan-card--is-clickable': onClick,
		'plan-card--is-disabled': isDisabled,
		'plan-card--is-enterprise': isEnterprise,
		'dark-bg-context': isEnterprise,
	});

	return (
		<div
			className={componentClasses}
			onClick={onClick}
		>
			<div className="plan-card__name">
				{name}
			</div>
			<div className="plan-card__price-overview">
				{!isDisabled && priceOverview}
			</div>
		</div>
	);
};



export default PlanCard;
