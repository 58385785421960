import memoize from 'memoizee';

import {
	type AddonsAvailabilityQuery,
	useAddonsAvailabilityQuery,
} from './useAddonsAvailability.gql';



const emptyList = [];

const format = memoize(
	(data: AddonsAvailabilityQuery | undefined) => {
		const addonsAvailability = data?.addonsAvailability ?? null;

		return {
			listAvailableAddonsByTariffAndPlan: (tariff, plan) => {
				if (addonsAvailability === null) {
					return emptyList;
				}

				return addonsAvailability.filter(
					(availableAddon) => (
						availableAddon.plan === plan
						&& availableAddon.tariff === tariff
					),
				);
			},
			isLoaded: addonsAvailability !== null,
		};
	},
);



function useAddonsAvailability() {
	const { data } = useAddonsAvailabilityQuery();

	return format(data);
}



export default useAddonsAvailability;
