import Immutable, {
	List,
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
} from '~/actions';

import {
	MARK_START_OF_LOADING,
	STORE,
	UPDATE_FILTER,
	UPDATE_SORT_BY,
} from '~/actions/alertPages';

import {
	decodeFilterParameter,
} from '~/model/filterParameter';

import {
	coverSpan,
	createCoverage,
} from '~/utilities/coverage';



function createDefaultState() {
	return Map({
		data: Map(),
		filter: Map(),
		loading: true,
		sortBy: Map(),
		websiteId: null,
	});
}



function createDefaultEvent() {
	return Map({
		loaded: false,
		pages: Map(),
		range: List(),
		rangeInLoading: createCoverage(),
		total: null,
	});
}



export function alertPages(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			const {
				previousUrlState,
				urlState,
			} = action;

			if (urlState.params.websiteId !== previousUrlState.params.websiteId
				|| urlState.params.alertId !== previousUrlState.params.alertId) {
				state = createDefaultState();
			}

			if (urlState.name.indexOf('website.alerts') === 0) {
				const websiteId = urlState.params.websiteId;

				if (!state.get('data').has(websiteId)) {
					state = state.setIn(
						['data', websiteId],
						createDefaultEvent(websiteId),
					);
				}

				state = state.set('websiteId', urlState.params.websiteId);
			}

			if (urlState.name.indexOf('website.alerts.alertPages') > -1) {
				if (!state.get('data').has(urlState.params.websiteId)) {
					state = state.set('loading', true);
				}

				let newFilter;
				let newSortBy;

				if (urlState.params.filter) {
					const unpackedFilter = decodeFilterParameter(urlState.params.filter);

					if (unpackedFilter.filter) {
						newFilter = unpackedFilter.filter;
					}

					if (unpackedFilter.sortBy) {
						newSortBy = unpackedFilter.sortBy;
					}
				} else {
					newFilter = Map();
					newSortBy = Map();
				}

				if (!Immutable.is(state.get('filter'), newFilter)) {
					state = state.set('filter', newFilter);
					state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
				}

				if (!Immutable.is(state.get('sortBy'), newSortBy)) {
					state = state.set('sortBy', newSortBy);
					state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
				}
			}

			break;
		}

		case MARK_START_OF_LOADING: {
			const {
				initiatedAt,
				limit,
				offset,
				websiteId,
			} = action;

			state = state.setIn(
				['data', websiteId, 'rangeInLoading'],
				coverSpan(
					state.getIn(['data', websiteId, 'rangeInLoading']),
					offset,
					limit,
					(initiatedAt / 1000) + 30,
				),
			);

			break;
		}

		case STORE: {
			const {
				websiteId,
				total,
				offset,
				range,
				pages,
			} = action;

			state = state.set('loading', false);

			if (!state.get('data').has(websiteId)) {
				state = state.setIn(
					['data', websiteId],
					createDefaultEvent(websiteId),
				);
			}

			state = state.setIn(
				['data', websiteId, 'total'],
				total,
			);

			state = state.setIn(
				['data', websiteId, 'loaded'],
				true,
			);
			state = state.setIn(
				['data', websiteId, 'range'],
				state.get('data').get(websiteId).get('range').withMutations((mutableRange) => {
					for (const index in range) {
						mutableRange.set(offset + parseInt(index, 10), range[index]);
					}
				}),
			);
			state = state.setIn(
				['data', websiteId, 'pages'],
				state.get('data').get(websiteId).get('pages').withMutations((mutablePages) => {
					for (const index in pages) {
						const pageId = pages[index].unique_id;

						if (mutablePages.has(pageId)) {
							mutablePages.set(
								pageId,
								mutablePages.get(pageId).merge(pages[index]),
							);
						} else {
							mutablePages.set(
								pageId,
								Immutable.fromJS(pages[index]),
							);
						}
					}
				}),
			);

			break;
		}

		case UPDATE_FILTER: {
			const oldState = state;
			const {
				filter,
			} = action;

			state = state.mergeIn(['filter'], filter);
			state = state.setIn(['filter'], state.get('filter').filter((val) => val !== ''));

			if (state !== oldState) {
				state = state.set('loading', true);
				state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
			}

			break;
		}

		case UPDATE_SORT_BY: {
			const oldState = state;
			const {
				sortBy,
			} = action;

			state = state.mergeIn(['sortBy'], sortBy);

			if (state !== oldState) {
				state = state.set('loading', true);
				state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
			}

			break;
		}

	}

	return state;
}
