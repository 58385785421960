import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Hint, {
	HintAttachment,
	HintPopupSkin,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import IconOnlyButton from '~/components/patterns/buttons/IconOnlyButton';



type Props = {
	/** Callback triggered on component click */
	onClick?: (e: React.MouseEvent) => void,
	/** Callback triggered on component mouse enter */
	onMouseEnter?: (e: React.MouseEvent) => void,
	/** Callback triggered on component mouse leave */
	onMouseLeave?: (e: React.MouseEvent) => void,
	/** Button popup */
	popup: React.ReactNode,
	/** Popup is hidden by default. Use this prop to reveal it */
	popupVisible?: boolean,
};



const IconPickerButton: React.FC<Props> = (props) => {
	const {
		onClick,
		onMouseEnter,
		onMouseLeave,
		popup,
		popupVisible = false,
	} = props;

	return (
		<Hint
			attachment={HintAttachment.Right}
			popup={popup}
			popupLayout={(children) => children}
			popupSkin={HintPopupSkin.Light}
			popupVisibility={popupVisible ? HintPopupVisibility.Always : HintPopupVisibility.Never}
			popupZIndex={3000}
		>
			<IconOnlyButton
				icon={(
					<BasicIcon type={BasicIconType.Smiley} />
				)}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			/>
		</Hint>
	);
};



export default IconPickerButton;
