import {
	useUserLastNameQuery,
} from './useUserLastName.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserLastName(legacyUserId: string | null): string | null {
	const { data } = useUserPropertiesQuery(
		useUserLastNameQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.lastName ?? null;
}



export default useUserLastName;
