import React from 'react';

import GraphQL from '~/types/graphql';

import AdobeAnalyticsIntegrationForm from '~/components/app/AdobeAnalyticsIntegrationForm';
import AdobeAnalyticsIntegrationUpsellForm from '~/components/app/AdobeAnalyticsIntegrationUpsellForm';
import FormsList from '~/components/atoms/lists/FormsList';
import GoogleAnalyticsIntegrationForm from '~/components/app/GoogleAnalyticsIntegrationForm';
import WebsiteIntegrationsMessagingAppsBlock from '~/components/app/WebsiteIntegrationsMessagingAppsBlock';
import WebsiteSettingsIntegrationsForm from '~/components/app/WebsiteSettingsIntegrationsForm';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountId from '~/hooks/useAccountId';



const WebsiteIntegrationsGroup: React.FC = () => {
	const accountId = useAccountId();

	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);
	const isAdobeAnalyticsFeatureEnabled = accountEnabledFeatures?.includes(GraphQL.AccountFeature.AdobeAnalyticsIntegration);

	return (
		<FormsList>
			<GoogleAnalyticsIntegrationForm />

			<WebsiteSettingsIntegrationsForm />

			<WebsiteIntegrationsMessagingAppsBlock />

			{isAdobeAnalyticsFeatureEnabled ? (
				<AdobeAnalyticsIntegrationForm />
			) : (
				<AdobeAnalyticsIntegrationUpsellForm />
			)}
		</FormsList>
	);
};



export default WebsiteIntegrationsGroup;
