import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class SegmentFilterValue extends Component {

	render() {
		const {
			children,
			color,
			minWidth,
		} = this.props;

		const style = {};

		if (color) {
			style.color = color;
		}

		if (minWidth) {
			style.minWidth = minWidth;
		}

		return (
			<div
				className="segment-filter-value"
				style={style}
			>
				{children}
			</div>
		);
	}

}

SegmentFilterValue.propTypes = {
	color: PropTypes.string,
	// Allow to set minimum width in case of ellipsis usage
	minWidth: PropTypes.number,
};



export default SegmentFilterValue;
