import React from 'react';

import type CK from '~/types/contentking';

import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useReviewsFormQuery,
} from './ReviewsForm.gql';



type Props = {
	accountId: CK.AccountId,
};

const ReviewsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const { data } = useReviewsFormQuery({
		variables: {
			accountId,
		},
	});

	const numberOfReviewsPerReviewSite = data?.account?.adminSettings?.numberOfReviewsPerReviewSite ?? null;

	const totalNumberOfReviews = numberOfReviewsPerReviewSite !== null
		? numberOfReviewsPerReviewSite.reduce(
			(total, reviewSite) => total + reviewSite.numberOfReviews,
			0,
		)
		: null;

	return (
		<EditableFormWrapper
			isForAdmins={true}
			isReadOnly={true}
			title="Reviews"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Number of reviews">
						<StaticText>
							{totalNumberOfReviews}
						</StaticText>
					</FormRow>

					{(numberOfReviewsPerReviewSite ?? []).map((reviewSite) => {
						let siteLabel = reviewSite.reviewSite;

						if (reviewSite.reviewSite === 'capterra') {
							siteLabel = 'Capterra';
						} else if (reviewSite.reviewSite === 'g2') {
							siteLabel = 'G2';
						} else if (reviewSite.reviewSite === 'google') {
							siteLabel = 'Google';
						} else if (reviewSite.reviewSite === 'positive-nps') {
							siteLabel = 'Positive NPS';
						} else if (reviewSite.reviewSite === 'trustradius') {
							siteLabel = 'TrustRadius';
						} else if (reviewSite.reviewSite === 'producthunt') {
							siteLabel = 'Product Hunt';
						}

						return (
							<FormRow
								key={reviewSite.reviewSite}
								label={'Number of reviews on ' + siteLabel}
							>
								<StaticText>
									{reviewSite.numberOfReviews}
								</StaticText>
							</FormRow>
						);
					})}
				</FormRows>
			</DisplayPart>

			<EditablePart />
		</EditableFormWrapper>
	);
};



export default ReviewsForm;
