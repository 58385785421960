import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ResetIconBuilder: IconBuilder = {
	icon: ({ color = '#FF9100' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g
					id="Group"
					stroke={color}
					transform="translate(3.000000, 3.000000)"
				>
					<path
						d="M11.12744,6.35459325 L17.18234,0.29963625 C17.58184,-0.09987875 18.22954,-0.09987875 18.62914,0.29963625 C19.02864,0.69915325 19.02864,1.34686325 18.62914,1.74637325 L12.57414,7.80133325"
						id="Path"
					>
					</path>
					<path
						d="M8.14433,7.96486325 L9.03104,7.07815325 C9.80954,6.29963325 11.07244,6.29963325 11.85094,7.07815325 C12.62954,7.85668325 12.62954,9.11957325 11.85094,9.89805325 L10.96424,10.7848532"
						id="Path"
					>
					</path>
					<path
						d="M6.47568,18.9613533 L-4.4408921e-16,12.4856533 L3.29936,9.18630325 C5.08763,7.39802325 7.98677,7.39802325 9.77504,9.18630325 C11.56334,10.9745532 11.56334,13.8737533 9.77504,15.6619533 L6.47568,18.9613533 Z"
						id="Path"
					>
					</path>
					<line
						id="Path"
						x1="1.61885"
						x2="5.33045"
						y1="14.1045533"
						y2="10.3929532"
					>
					</line>
					<line
						id="Path"
						x1="3.23775"
						x2="6.94935"
						y1="15.7234533"
						y2="12.0118532"
					>
					</line>
					<line
						id="Path"
						x1="4.85676"
						x2="8.56837"
						y1="17.3424532"
						y2="13.6308532"
					>
					</line>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ResetIconBuilder;

