import classNames from 'classnames';
import React from 'react';



export enum TabNavigationStyle {
	Dark = 'dark',
	Default = 'default',
}

type Props = {
	children: React.ReactNode,
	className?: string,
	/** We can focus on specific menu item when navigation will be scrollable. Number represents order index. First item is 0. */
	scrollToItem?: number,
	/** Stretched navigation items or left aligned */
	stretchItems?: boolean,
	/** Color style of whole tab navigation */
	style?: TabNavigationStyle,
};



const TabNavigation: React.FC<Props> = (props) => {
	const {
		children,
		className,
		scrollToItem,
		stretchItems = true,
		style = TabNavigationStyle.Default,
	} = props;

	const listRef = React.useRef<HTMLDivElement | null>(null);
	const scrollableAreaRef = React.useRef<HTMLDivElement>(null);

	const [leftFadeOut, setLeftFadeOut] = React.useState(false);
	const [rightFadeOut, setRightFadeOut] = React.useState(false);

	function handleScroll(): void {
		const scrollableArea = scrollableAreaRef.current;
		const list = listRef.current;
		let hasLeftFadeOut = false;
		let hasRightFadeOut = false;
		let listWidth = 0;

		if (!list || !scrollableArea) {
			return;
		}

		for (let step = 0; step < list.children.length; step++) {
			const child = list.children[step];

			if (child instanceof HTMLElement) {
				listWidth += child.offsetWidth;
			}
		}

		if (scrollableArea.offsetWidth < listWidth) {
			hasLeftFadeOut = scrollableArea.scrollLeft > 0;
			hasRightFadeOut = listWidth - scrollableArea.scrollLeft - 1 > scrollableArea.offsetWidth;
		}

		setLeftFadeOut(hasLeftFadeOut);
		setRightFadeOut(hasRightFadeOut);
	}


	React.useEffect(
		() => {
			const list = listRef.current;
			const scrollableArea = scrollableAreaRef.current;

			if (!list || !scrollableArea || scrollToItem === undefined) {
				return;
			}

			const item = list.children[scrollToItem];
			const scrollLeft = scrollableArea.scrollLeft;

			if (!(item instanceof HTMLElement)) {
				return;
			}

			if (item.offsetLeft < scrollLeft) {
				scrollableArea.scrollLeft = item.offsetLeft - 30;
			}

			if (item.offsetLeft + item.offsetWidth > scrollLeft + scrollableArea.offsetWidth) {
				scrollableArea.scrollLeft = item.offsetLeft - scrollableArea.offsetWidth + item.offsetWidth + 30;
			}
		},
		[
			scrollToItem,
		],
	);

	// recalculate possible shadow when count of children has changed
	const numberOfChildren = React.Children.count(children);
	React.useEffect(
		() => {
			const timeout = setTimeout(() => {
				handleScroll();
			}, 0);

			return () => {
				clearTimeout(timeout);
			};
		},
		[
			numberOfChildren,
		],
	);

	const componentClasses = classNames({
		'tab-nav': true,
		'tab-nav--dark-style': style === TabNavigationStyle.Dark,
		'tab-nav--has-left-fadeout': leftFadeOut,
		'tab-nav--has-right-fadeout': rightFadeOut,
		'tab-nav--stretch-items': stretchItems,
	}, className);

	return (
		<div className={componentClasses}>
			<div
				className="tab-nav__scrollable-area js-scrollable js-scrollable-vertical"
				onScroll={handleScroll}
				ref={scrollableAreaRef}
			>
				<div
					className="tab-nav__list"
					ref={listRef}
				>
					{children}
				</div>
			</div>
		</div>
	);
};



export default TabNavigation;
