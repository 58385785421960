/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CleanupOrphanUrlsMutationVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CleanupOrphanUrlsMutation = { readonly __typename?: 'Mutation', readonly CleanupOrphanUrls: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const CleanupOrphanUrlsDocument = gql`
    mutation CleanupOrphanUrls($websiteId: WebsiteId!) {
  CleanupOrphanUrls(websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type CleanupOrphanUrlsMutationFn = Apollo.MutationFunction<CleanupOrphanUrlsMutation, CleanupOrphanUrlsMutationVariables>;

/**
 * __useCleanupOrphanUrlsMutation__
 *
 * To run a mutation, you first call `useCleanupOrphanUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCleanupOrphanUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cleanupOrphanUrlsMutation, { data, loading, error }] = useCleanupOrphanUrlsMutation({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCleanupOrphanUrlsMutation(baseOptions?: Apollo.MutationHookOptions<CleanupOrphanUrlsMutation, CleanupOrphanUrlsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CleanupOrphanUrlsMutation, CleanupOrphanUrlsMutationVariables>(CleanupOrphanUrlsDocument, options);
      }
export type CleanupOrphanUrlsMutationHookResult = ReturnType<typeof useCleanupOrphanUrlsMutation>;
export type CleanupOrphanUrlsMutationResult = Apollo.MutationResult<CleanupOrphanUrlsMutation>;
export type CleanupOrphanUrlsMutationOptions = Apollo.BaseMutationOptions<CleanupOrphanUrlsMutation, CleanupOrphanUrlsMutationVariables>;