import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GlobalFormMessage from '~/components/atoms/forms/basis/GlobalFormMessage';



const messages = defineMessages({
	cardDeclined: {
		id: 'ui.recurlyErrors.cardDeclined',
	},
	cardTypeNotAccepted: {
		id: 'ui.recurlyErrors.cardTypeNotAccepted',
	},
	connectionFailed: {
		id: 'ui.recurlyErrors.connectionFailed',
	},
	insufficientFunds: {
		id: 'ui.recurlyErrors.insufficientFunds',
	},
	invalidBillingDetailsToken: {
		id: 'ui.recurlyErrors.invalidBillingDetails',
	},
	invalidRequest: {
		id: 'ui.recurlyErrors.invalidRequest',
	},
	serverDown: {
		id: 'ui.recurlyErrors.serverDown',
	},
	transactionFailed: {
		id: 'ui.recurlyErrors.transactionFailed',
	},
	unknownProblem: {
		id: 'ui.recurlyErrors.unknownProblem',
	},
});



const RecurlyFormErrorMessages: React.FC = () => {
	return (
		<>
			<GlobalFormMessage name="recurly_card_declined">
				<FormattedMessage {...messages.cardDeclined} />
			</GlobalFormMessage>

			<GlobalFormMessage name="recurly_card_type_not_accepted">
				<FormattedMessage {...messages.cardTypeNotAccepted} />
			</GlobalFormMessage>

			<GlobalFormMessage name="recurly_connection_failed">
				<FormattedMessage {...messages.connectionFailed} />
			</GlobalFormMessage>

			<GlobalFormMessage name="recurly_insufficient_funds">
				<FormattedMessage {...messages.insufficientFunds} />
			</GlobalFormMessage>

			<GlobalFormMessage name="recurly_invalid_billing_details_token">
				<FormattedMessage {...messages.invalidBillingDetailsToken} />
			</GlobalFormMessage>

			<GlobalFormMessage name="recurly_invalid_request">
				<FormattedMessage {...messages.invalidRequest} />
			</GlobalFormMessage>

			<GlobalFormMessage name="recurly_server_down">
				<FormattedMessage {...messages.serverDown} />
			</GlobalFormMessage>

			<GlobalFormMessage name="recurly_transaction_failed">
				<FormattedMessage {...messages.transactionFailed} />
			</GlobalFormMessage>

			<GlobalFormMessage name="unknown_problem">
				<FormattedMessage {...messages.unknownProblem} />
			</GlobalFormMessage>
		</>
	);
};



export default RecurlyFormErrorMessages;
