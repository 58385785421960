import {
	formatDistanceToNowStrict,
	isFuture,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Muted from '~/components/patterns/typography/Muted';

import {
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	future: {
		id: 'ui.relativeTimeFormatter.future',
	},
	past: {
		id: 'ui.relativeTimeFormatter.past',
	},
});



function suffix(muted: boolean) {
	if (muted) {
		return (chunks) => <Muted>{chunks}</Muted>;
	}

	return (chunks) => chunks;
}



type Props = {
	date: Date | string,
	minimumStep?: number,
	muted?: boolean,
};

const RelativeTimeFormatter: React.FC<Props> = (props) => {
	const {
		minimumStep = 10_000,
		muted = false,
	} = props;

	const now = new Date();
	const date = isString(props.date)
		? new Date(props.date)
		: props.date;

	const difference = Math.abs(now.getTime() - date.getTime());
	const stepOffset = minimumStep - (difference % minimumStep);

	const [, forceRerender] = React.useState(0);

	React.useEffect(
		() => {
			const interval = setInterval(() => {
				forceRerender(Date.now());
			}, 10_000);

			return () => {
				clearInterval(interval);
			};
		},
		[],
	);

	if (isFuture(date)) {
		const distance = formatDistanceToNowStrict(
			date.getTime() + stepOffset,
		);

		return (
			<FormattedMessage
				{...messages.future}
				values={{
					distance,
					suffix: suffix(muted),
				}}
			/>
		);
	}

	const distance = formatDistanceToNowStrict(
		date.getTime() - stepOffset,
	);

	return (
		<FormattedMessage
			{...messages.past}
			values={{
				distance,
				suffix: suffix(muted),
			}}
		/>
	);
};



export default RelativeTimeFormatter;
