import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CopyURLIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g>
				<path
					d="M8.2,6.01H4.77v14.4h14.4V6.01h-3.32"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<g transform="translate(7 9)">
					<path
						d="M5.18,1.61L5.79,1
						c0.36-0.36,0.85-0.56,1.36-0.56s1,0.2,1.36,0.56c0.74,0.75,0.74,1.95,0,2.7L6.76,5.44c-0.75,0.74-1.95,0.74-2.7,0"
						fill="none"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M4.56,7.61L4,8.21
						C3.64,8.57,3.16,8.78,2.65,8.78S1.66,8.57,1.3,8.21C0.94,7.86,0.74,7.37,0.74,6.86s0.2-0.99,0.56-1.35L3,3.77
						c0.75-0.74,1.95-0.74,2.7,0"
						fill="none"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<path
					d="M13.4,4.9c0.09-0.2,0.14-0.41,0.14-0.63
					C13.42,3.54,12.78,3,12.04,3s-1.38,0.54-1.5,1.27c0,0.22,0.05,0.43,0.14,0.63H8.26v2.35h7.5V4.9H13.4z"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CopyURLIconBuilder;

