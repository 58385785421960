import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BulkSaveDefinitionsButton from '~/components/logic/alertsConfiguration/BulkSaveDefinitionsButton';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SegmentSelectField from '~/components/atoms/forms/components/SegmentSelectField';

import {
	useBulkUpdateAlertDefinitionsScopeMutation,
} from './EditAlertDefinitionsScopeModal.gql';

import useBulkEditedAlertDefinitions from '~/hooks/useBulkEditedAlertDefinitions';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useSetFlashMessage from '~/hooks/useSetFlashMessage';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';



const messages = defineMessages({
	description: {
		id: 'ui.alertsConfiguration.modal.bulkEditScope.description',
	},
	fieldScope: {
		id: 'ui.alertsConfiguration.fields.scope',
	},
	headerLabel: {
		id: 'ui.general.settings',
	},
	headerValue: {
		id: 'ui.alertsConfiguration.modal.bulk.header',
	},
});

const validations = {
	scope: [
		{
			message: '',
			field: 'scope',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
};



const EditAlertDefinitionsScopeModal: React.FC = () => {
	const websiteId = useWebsiteId();

	const classicFormBehavior = useClassicFormBehavior();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const selectedAlertDefinitions = useBulkEditedAlertDefinitions();
	const setFlashMessage = useSetFlashMessage();

	const [bulkUpdateAlertDefinitionsScope] = useBulkUpdateAlertDefinitionsScopeMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			let scope = values.scope;

			if (scope !== 'website') {
				scope = 'segment:' + scope;
			}

			await bulkUpdateAlertDefinitionsScope({
				variables: {
					alertDefinitionIds: selectedAlertDefinitions.idsWithScope,
					scope,
					websiteId,
				},
			});

			setFlashMessage({
				message: 'alertDefinitionsEdited',
				messageVariables: {
					number__alertDefinitions: selectedAlertDefinitions.count,
				},
				route: 'website.alerts.configuration',
				style: 'success',
			});

			classicFormBehavior.finish();
		},
		[
			bulkUpdateAlertDefinitionsScope,
			classicFormBehavior,
			selectedAlertDefinitions,
			setFlashMessage,
			websiteId,
		],
	);

	let defaultScope: string | undefined;

	selectedAlertDefinitions.allWithScope.forEach((alertDefinition) => {
		if (defaultScope === undefined) {
			defaultScope = alertDefinition.scope;
		} else if (defaultScope !== alertDefinition.scope) {
			defaultScope = '';
		}
	});

	if (defaultScope !== undefined && defaultScope.indexOf('segment:') === 0) {
		defaultScope = defaultScope.replace('segment:', '');
	}

	return (
		<ModalContainer
			gapsSize={2}
			header={(
				<ModalHeader
					iconType={ModalHeaderIconType.CogWheel}
					title={(
						<FormattedMessage {...messages.headerLabel} />
					)}
					titleValue={(
						<FormattedMessage
							{...messages.headerValue}
							values={{
								count__definitions: selectedAlertDefinitions.count,
							}}
						/>
					)}
				/>
			)}
			headerGapsSize={1}
			isLoading={defaultScope === undefined}
		>
			<ModalTextSection>
				<RichText>
					<p>
						<FormattedMessage {...messages.description} />
					</p>
				</RichText>
			</ModalTextSection>

			{defaultScope !== undefined && (
				<Form
					defaultValues={{
						scope: defaultScope,
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<CenteredFormWrapper>
						<FormRow
							htmlFor="scope"
							label={(
								<FormattedMessage {...messages.fieldScope} />
							)}
						>
							<FieldStatus
								name="scope"
								showIcon={false}
							>
								{segmentDefinitions.isLoaded && (
									<SegmentSelectField
										inAlertDefinitions={true}
										name="scope"
										segments={segmentDefinitions.listAll()}
										websiteScopeName="website"
									/>
								)}
							</FieldStatus>
						</FormRow>
					</CenteredFormWrapper>

					<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
						<CancelButton />

						<BulkSaveDefinitionsButton
							numberOfAlertDefinitions={selectedAlertDefinitions.count}
						/>
					</ModalButtonsLayout>
				</Form>
			)}
		</ModalContainer>
	);
};



export default EditAlertDefinitionsScopeModal;
