import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const EnrichmentFieldsIconBuilder: IconBuilder = {
	icon: ({ color = '#84BD4D' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g id="enrichment-fields">
					<path
						d="M26.6932,8.3916 C26.6932,9.7126 22.6632,10.7846 17.6932,10.7846 C12.7222,10.7846 8.69324,9.7126 8.69324,8.3916 C8.69324,7.0716 12.7222,5.9996 17.6932,5.9996 C22.6632,5.9996 26.6932,7.0716 26.6932,8.3916 Z"
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					/>
					<path
						d="M26.6932,13.4639 C26.6932,14.6829 23.2612,15.6899 18.8262,15.8369"
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					/>
					<path
						d="M26.6932,18.5361 C26.6932,19.8571 22.6632,20.9281 17.6932,20.9281 C17.4252,20.9281 17.1612,20.9251 16.8992,20.9191"
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					/>
					<path
						d="M8.69275,20.5977 L8.69275,23.6087 C8.69275,24.9287 12.7227,25.9997 17.6927,25.9997 C22.6627,25.9997 26.6927,24.9287 26.6927,23.6087 L26.6927,8.39169"
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					/>
					<line
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						x1="8.69275"
						x2="8.69275"
						y1="8.3916"
						y2="14.4176"
					/>
					<polyline
						id="Path"
						points="12.3645 20.4297 16.2325 17.4537 12.2845 14.4177"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					/>
					<line
						id="Path"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						x1="5.99976"
						x2="16.2318"
						y1="17.4541"
						y2="17.4541"
					/>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default EnrichmentFieldsIconBuilder;
