import React from 'react';

import type CK from '~/types/contentking';

import useAllowedPaymentMethods from '~/hooks/useAllowedPaymentMethods';

import {
	METHOD_SEPA_DEBIT,
} from '~/model/payments';

import isCountryInEuropeanUnion from '~/utilities/isCountryInEuropeanUnion';



function useEffectiveAllowedPaymentMethods(
	accountId: CK.AccountId | null,
	country: string,
): Readonly<Array<string>> | null {
	const allowedPaymentMethods = useAllowedPaymentMethods(accountId);

	return React.useMemo(
		() => {
			if (allowedPaymentMethods === null) {
				return null;
			}

			return allowedPaymentMethods.filter(
				(paymentMethod) => {
					if (paymentMethod === METHOD_SEPA_DEBIT) {
						return (
							isCountryInEuropeanUnion(country)
							|| country === 'IS' // Iceland
							|| country === 'LI' // Liechtenstein
							|| country === 'NO' // Norway
							|| country === 'CH' // Switzerland
							|| country === 'AD' // Andorra
							|| country === 'MC' // Monaco
							|| country === 'SM' // San Marino
							|| country === 'VA' // Vatican City
						);
					}

					return true;
				},
			);
		},
		[
			allowedPaymentMethods,
			country,
		],
	);
}



export default useEffectiveAllowedPaymentMethods;
