/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RefreshReportingApiTokenMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type RefreshReportingApiTokenMutation = { readonly __typename?: 'Mutation', readonly RefreshReportingApiToken: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly reportingApiToken: string | null } | null } } };


export const RefreshReportingApiTokenDocument = gql`
    mutation RefreshReportingApiToken($accountId: AccountId!) {
  RefreshReportingApiToken(accountId: $accountId) {
    query {
      account(id: $accountId) {
        id
        reportingApiToken
      }
    }
  }
}
    `;
export type RefreshReportingApiTokenMutationFn = Apollo.MutationFunction<RefreshReportingApiTokenMutation, RefreshReportingApiTokenMutationVariables>;

/**
 * __useRefreshReportingApiTokenMutation__
 *
 * To run a mutation, you first call `useRefreshReportingApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshReportingApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshReportingApiTokenMutation, { data, loading, error }] = useRefreshReportingApiTokenMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRefreshReportingApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshReportingApiTokenMutation, RefreshReportingApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshReportingApiTokenMutation, RefreshReportingApiTokenMutationVariables>(RefreshReportingApiTokenDocument, options);
      }
export type RefreshReportingApiTokenMutationHookResult = ReturnType<typeof useRefreshReportingApiTokenMutation>;
export type RefreshReportingApiTokenMutationResult = Apollo.MutationResult<RefreshReportingApiTokenMutation>;
export type RefreshReportingApiTokenMutationOptions = Apollo.BaseMutationOptions<RefreshReportingApiTokenMutation, RefreshReportingApiTokenMutationVariables>;