const ProtocolRegex = /^(http|https):\/\//i;
const ValidCharactersRegex = /^[0-9\p{L}][0-9\p{L}-]*$/ui;
const SomeNonNumberRegex = /[\p{L}]/ui;
const NotEndingWithDashRegex = /.*[^\-_]$/;
const TrailingSlashRegex = /\/$/;



export default function isDomain(value: string): boolean {
	let domain = value.trim();

	if (TrailingSlashRegex.test(domain)) {
		domain = domain.replace(TrailingSlashRegex, '');
	}

	if (domain.includes(':')) {
		if (ProtocolRegex.test(domain)) {
			domain = domain.replace(ProtocolRegex, '');
		} else {
			return false;
		}
	}

	if (domain.includes('.') === false) {
		return false;
	}

	const parts = domain.split('.');

	if (parts.some((part) => part.length === 0)) {
		return false;
	}

	const tld = parts[parts.length - 1] ?? '';
	if (SomeNonNumberRegex.test(tld) === false) {
		return false;
	}

	return parts.every((part) => {
		return (
			ValidCharactersRegex.test(part)
			&& NotEndingWithDashRegex.test(part)
		);
	});
}
