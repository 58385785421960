import {
	captureException,
} from '@sentry/browser';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';

import {
	useBookDemoMutation,
} from './BookADemoButton.gql';

import useExternalScript from '~/hooks/useExternalScript';
import useExternalStylesheet from '~/hooks/useExternalStylesheet';

import {
	userInfoSelector,
} from '~/state/authentication/selectors';

import {
	CALENDLY_BOOKING_URL,
} from '~/config';

import {
	getRouter,
} from '~/routing/router';



const messages = defineMessages({
	button: {
		id: 'ui.bookADemoButton',
	},
});



type Props = {
	accountId: CK.AccountId,
	hideButton: boolean,
};

const BookADemoButton: React.FC<Props> = (props) => {
	const {
		accountId,
		hideButton,
	} = props;



	const [bookDemo] = useBookDemoMutation({
		variables: {
			accountId,
		},
	});



	// Load Calendly resources.
	const [Calendly, setCalendly] = React.useState<any | null>(null);

	const onCalendlyLoad = React.useCallback(() => {
		// @ts-ignore
		setCalendly(window.Calendly);
	}, []);

	useExternalStylesheet('https://assets.calendly.com/assets/external/widget.css');
	useExternalScript('https://assets.calendly.com/assets/external/widget.js', onCalendlyLoad);



	// Open Calendly widget with prefilled user information
	const userInfo = useSelector(userInfoSelector);

	const onClickHandler = React.useCallback(
		() => {
			if (Calendly === null) {
				return;
			}

			const prefill: Record<string, string> = {};

			if (userInfo) {
				const firstName = userInfo.get('first_name') ?? '';
				const lastName = userInfo.get('last_name') ?? '';

				prefill.name = `${firstName} ${lastName}`.trim();
				prefill.email = userInfo.get('email');
			}

			try {
				Calendly.initPopupWidget({
					url: CALENDLY_BOOKING_URL,
					prefill,
				});
			} catch (error) {
				captureException(error);
			}
		},
		[
			Calendly,
			userInfo,
		],
	);



	const closeCalendlyPopup = React.useCallback(
		() => {
			try {
				const closeButton = document.querySelector<HTMLButtonElement>('.calendly-popup-close');

				if (closeButton !== null) {
					closeButton.click();
				}
			} catch (error) {
				captureException(error);
			}

			try {
				const overlay = document.querySelector('.calendly-overlay');

				if (overlay !== null) {
					overlay.remove();
				}
			} catch (error) {
				captureException(error);
			}
		},
		[],
	);



	// Listen to calendly events to detect when a user completes scheduling an
	// event.
	React.useEffect(
		() => {
			function listener(e: MessageEvent) {
				if (e.data?.event === 'calendly.event_scheduled') {
					bookDemo();
				}
			}

			window.addEventListener('message', listener);

			return () => {
				window.removeEventListener('message', listener);
			};
		},
		[
			bookDemo,
		],
	);

	React.useEffect(
		() => {
			if (Calendly === null) {
				return;
			}

			getRouter().addListener(closeCalendlyPopup);

			return () => {
				getRouter().removeListener(closeCalendlyPopup);
			};
		},
		[
			Calendly,
			closeCalendlyPopup,
		],
	);



	// Make sure the Calendly modal is removed from DOM when unmounting
	React.useEffect(
		() => {
			return () => {
				closeCalendlyPopup();
			};
		},
		[
			closeCalendlyPopup,
		],
	);



	if (hideButton) {
		return null;
	}



	return (
		<Button
			icon={(
				<BasicIcon
					color="#ffffff"
					type={BasicIconType.Calendar}
				/>
			)}
			onClick={onClickHandler}
			style={ButtonStyle.Highlight}
		>
			<FormattedMessage {...messages.button} />
		</Button>
	);
};



export default BookADemoButton;
