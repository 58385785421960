import Immutable from 'immutable';
import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import ActiveFilter from '~/components/logic/filters/ActiveFilter';

import {
	updateIssuesFilter,
} from '~/actions/issues';

import {
	removeDefaultFilterValues,
} from '~/model/issues';

import {
	issuesFilterSelector as issuesOverviewFilterSelector,
} from '~/state/ui/selectors';



const IssuesFilterBar: React.FC = () => {
	const dispatch = useDispatch();
	const filter = useSelector(issuesOverviewFilterSelector);

	const handleRemoveFilter = React.useCallback(
		(filterId) => {
			dispatch(
				updateIssuesFilter(Immutable.fromJS({
					[filterId]: undefined,
				})),
			);
		},
		[
			dispatch,
		],
	);

	const activeFilters = removeDefaultFilterValues(filter);

	return (
		<ActiveFilter
			activeFilters={activeFilters}
			clearLink={false}
			filter={filter}
			removeFilterCallback={handleRemoveFilter}
			segmentsManagement={false}
			supportsScrollToField={false}
		/>
	);
};



export default IssuesFilterBar;
