import type CK from '~/types/contentking';

import useAccountBillingEntity from './useAccountBillingEntity';



function useIsAccountUsingStripe(accountId: CK.AccountId | null): boolean | null {
	const billingEntity = useAccountBillingEntity(accountId);

	if (billingEntity === null) {
		return null;
	}

	return billingEntity !== 'old';
}



export default useIsAccountUsingStripe;
