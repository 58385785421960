import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import AbstractCheckboxField, {
	AbstractCheckboxFieldCheckedState,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import {
	ContextConsumer,
} from './CheckboxGroupContainer';



function calculateMasterStatus(disabled, indeterminate, items, optionGroups, options, value) {
	if (optionGroups !== undefined) {
		const flatOptions = [];

		optionGroups.forEach((subOptions) => {
			subOptions.forEach((item) => flatOptions.push(item));
		});

		options = flatOptions;
	}

	options = options.filter((item) => disabled.indexOf(item) === -1);

	const relevantOptions = items
		? options.filter((item) => items.indexOf(item) !== -1)
		: options;

	const relevantValue = value.filter((item) => relevantOptions.indexOf(item) !== -1);
	const relevantIndeterminate = indeterminate.filter((item) => relevantOptions.indexOf(item) !== -1);

	if (relevantOptions.length === 0) {
		return AbstractCheckboxFieldCheckedState.NotChecked;
	} else if (relevantOptions.length === relevantValue.length) {
		return AbstractCheckboxFieldCheckedState.Checked;
	} else if (
		(relevantOptions.length > relevantValue.length && relevantValue.length > 0)
		|| (relevantValue.length === 0 && relevantIndeterminate.length > 0)
	) {
		return AbstractCheckboxFieldCheckedState.Indeterminate;
	}

	return AbstractCheckboxFieldCheckedState.NotChecked;
}



class MasterCheckbox extends Component {

	render() {
		const {
			items,
			name,
		} = this.props;

		return (
			<ContextConsumer>
				{({ disabled, indeterminate, name: groupName, optionGroups, options, toggleAll, value }) => (
					<AbstractCheckboxField
						checkedState={calculateMasterStatus(disabled, indeterminate, items, optionGroups, options, value)}
						compact={true}
						isControlled={true}
						name={groupName + '_checkbox_group_' + name}
						onChangeCallback={() => {
							toggleAll({
								affectedOptions: items,
							});
						}}
					/>
				)}
			</ContextConsumer>
		);
	}

}

MasterCheckbox.defaultProps = {
	name: 'all',
};

MasterCheckbox.propTypes = {
	items: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
};



export default MasterCheckbox;
