import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import PercentageFormatter from '~/components/app/PercentageFormatter';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import WebsiteCapacityOverview from '~/components/patterns/websites/WebsiteCapacityOverview';
import WebsitePlanUsage, {
	WebsitePlanUsageStatus,
} from '~/components/patterns/websites/WebsitePlanUsage';



type Props = {
	website: {
		pageCapacity: number,
		pageCapacityUsageDescription: GraphQL.WebsitePageCapacityUsageDescription,
		pageCapacityUsageRatio: number,
		pagesCount: number,
	},
};

const WebsitesOverviewTablePlanUsage: React.FC<Props> = (props) => {
	const {
		website,
	} = props;

	let status = WebsitePlanUsageStatus.Normal;

	if (website.pageCapacityUsageDescription === GraphQL.WebsitePageCapacityUsageDescription.Reached) {
		status = WebsitePlanUsageStatus.Critical;
	} else if (website.pageCapacityUsageDescription === GraphQL.WebsitePageCapacityUsageDescription.Near) {
		status = WebsitePlanUsageStatus.Warning;
	}

	return (
		<WebsitePlanUsage
			capacity={(
				<Ellipsis
					altPopupText={(
						<>
							<FormattedNumber value={website.pagesCount} />
							<span> / </span>
							<FormattedNumber value={website.pageCapacity} />
						</>
					)}
				>
					<WebsiteCapacityOverview
						capacity={website.pageCapacity}
						pagesCount={website.pagesCount}
					/>
				</Ellipsis>
			)}
			status={status}
			usage={(
				<PercentageFormatter
					maximumFractionDigits={0}
					value={website.pageCapacityUsageRatio}
				/>
			)}
		/>
	);
};



export default WebsitesOverviewTablePlanUsage;
