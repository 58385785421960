import {
	format,
	parse,
} from 'date-fns';
import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import TrackedChangesFilter, {
	TrackedChangesFilterScope,
} from '~/components/logic/filters/TrackedChangesFilter';

import useUrlState from '~/hooks/useUrlState';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteTimeCreatedAt from '~/hooks/useWebsiteTimeCreatedAt';

import {
	enterComparisonMode,
	leaveComparisonMode,
} from '~/actions/issuesScreen';

import {
	dateFromSelector,
	dateToSelector,
} from '~/state/websiteIssuesComparison/selectors';

import {
	isString,
} from '~/utilities/typeCheck';



const ComparisonEntry: React.FC = () => {
	const dispatch = useDispatch();

	const urlState = useUrlState();
	const websiteId = useWebsiteId();
	const websiteCreatedAt = useWebsiteTimeCreatedAt(websiteId);

	const dateFrom = useSelector(dateFromSelector);
	const dateTo = useSelector(dateToSelector);

	const [startDate, endDate] = React.useMemo(
		() => {
			return [
				isString(dateFrom) ? parse(dateFrom, 'yyyy-MM-dd', new Date()) : null,
				isString(dateTo) ? parse(dateTo, 'yyyy-MM-dd', new Date()) : null,
			];
		},
		[
			dateFrom,
			dateTo,
		],
	);

	const handleRangeSelect = React.useCallback(
		(dateRange) => {
			dispatch(
				enterComparisonMode(
					format(dateRange.startDate, 'yyyy-MM-dd'),
					format(dateRange.endDate, 'yyyy-MM-dd'),
				),
			);
		},
		[
			dispatch,
		],
	);

	const handleExit = React.useCallback(
		() => {
			dispatch(
				leaveComparisonMode(),
			);
		},
		[
			dispatch,
		],
	);

	const scope = (
		urlState.name.startsWith('website.issues.issueCategory.singleIssueAffectedPages')
			? TrackedChangesFilterScope.SingleIssueAffectedPages
			: TrackedChangesFilterScope.Issues
	);

	if (websiteCreatedAt === null) {
		return null;
	}

	return (
		<TrackedChangesFilter
			endDate={endDate}
			minDate={websiteCreatedAt}
			onExit={handleExit}
			onRangeSelect={handleRangeSelect}
			scope={scope}
			startDate={startDate}
		/>
	);
};



export default ComparisonEntry;
