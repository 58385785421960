import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import WithPermission, {
	type WithPermissionChildrenType,
} from '~/components/logic/access/WithPermission';

import {
	ObjectType,
} from '~/model/permissions';



type Props = {
	accountId: CK.AccountId | null,
	action: GraphQL.ActionWithAccount,
	children: WithPermissionChildrenType,
	messageForInsufficientPermissions?: React.ReactNode,
	messageForManaged?: React.ReactNode,
	showMessage?: boolean,
};

const WhenAccountActionAllowed: React.FC<Props> = (props) => {
	const {
		accountId,
		action,
		children,
		messageForInsufficientPermissions,
		messageForManaged,
		showMessage = true,
	} = props;

	return (
		<WithPermission
			action={action}
			messageForInsufficientPermissions={messageForInsufficientPermissions}
			messageForManaged={messageForManaged}
			objectId={accountId}
			objectType={ObjectType.Account}
			showMessage={showMessage}
		>
			{children}
		</WithPermission>
	);
};



export default WhenAccountActionAllowed;
