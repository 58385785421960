import React from 'react';
import Immutable, {
	List,
} from 'immutable';
import isEmpty from 'lodash/isEmpty';

import {
	createFilterParameter as createPagesFilterParameter,
} from '~/actions/pages/overview';

import useNavigation from '~/hooks/useNavigation';
import useUrlState from '~/hooks/useUrlState';

import {
	DEFAULT_FILTER,
	DEFAULT_SORT_BY,
} from '~/model/pages';



const EntryPage: React.FC = () => {
	const navigation = useNavigation();
	const urlState = useUrlState();

	React.useEffect(
		() => {
			const {
				target,
				...params
			} = urlState.params;

			switch (target) {

				/**
			 * Redirect user to `website.pages` with a segment selected.
			 * If `arg_websiteId` parameter is empty, redirect to homepage.
			 * If `arg_segment` parameter is empty, redirect to `websites.pages` without filter.
			 *
			 * usage:
			 * - /entry?target=pages&arg_websiteId=1-25577&arg_segment=138512-indexable
			 */
				case 'pages':
					const {
						arg_segment: segment,
						arg_websiteId: websiteId,
					} = params;

					if (isEmpty(websiteId)) {
						navigation.navigate({
							routeName: 'home',
							routeParams: {},
							options: {
								replace: true,
							},
						});
						return;
					}

					const filter = createPagesFilterParameter(
						DEFAULT_FILTER.merge(Immutable.fromJS({
							segments: {
								included_in: isEmpty(segment) ? [] : [segment],
								not_included_in: [],
								operator: 'and',
							},
						})),
						DEFAULT_SORT_BY,
						List(['segments']),
					);

					navigation.navigate({
						routeName: 'website.pages',
						routeParams: {
							filter,
							websiteId,
						},
						options: {
							replace: true,
						},
					});
					return;

				default:
					navigation.navigate({
						routeName: 'home',
						routeParams: {},
						options: {
							replace: true,
						},
					});
					return;

			}
		},
		[
			navigation,
			urlState,
		],
	);

	return null;
};



export default EntryPage;
