import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import SelectField, {
	SelectFieldSize,
} from '~/components/atoms/forms/components/SelectField';

import {
	TRANSFORMATION_APPEND,
	TRANSFORMATION_APPLY_PATTERN,
	TRANSFORMATION_CAST_TO_BOOLEAN,
	TRANSFORMATION_CHARACTER_LENGTH,
	TRANSFORMATION_CHECKSUM,
	TRANSFORMATION_CONCATENATE,
	TRANSFORMATION_EXTRACT_DATE,
	TRANSFORMATION_EXTRACT_FROM_JSON,
	TRANSFORMATION_EXTRACT_NUMBER,
	TRANSFORMATION_NUMBER_OF_ELEMENTS,
	TRANSFORMATION_PREPEND,
	TRANSFORMATION_REPLACE,
	TRANSFORMATION_STRIP_TAGS,
	TRANSFORMATION_TRUNCATE,
	TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES,
} from '~/model/customElements';



const LIST_OF_TRANSFORMATIONS = [
	TRANSFORMATION_NUMBER_OF_ELEMENTS,
	TRANSFORMATION_CHARACTER_LENGTH,
	TRANSFORMATION_PREPEND,
	TRANSFORMATION_APPEND,
	TRANSFORMATION_REPLACE,
	TRANSFORMATION_TRUNCATE,
	TRANSFORMATION_CONCATENATE,
	TRANSFORMATION_APPLY_PATTERN,
	TRANSFORMATION_CHECKSUM,
	TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES,
	TRANSFORMATION_CAST_TO_BOOLEAN,
	TRANSFORMATION_EXTRACT_FROM_JSON,
	TRANSFORMATION_EXTRACT_NUMBER,
	TRANSFORMATION_EXTRACT_DATE,
	TRANSFORMATION_STRIP_TAGS,
] as const;



const messages = defineMessages({
	[TRANSFORMATION_APPEND]: {
		description: {
			id: 'ui.customElements.transformations.append.description',
		},
		label: {
			id: 'ui.customElements.transformations.append.label',
		},
	},
	[TRANSFORMATION_APPLY_PATTERN]: {
		description: {
			id: 'ui.customElements.transformations.applyPattern.description',
		},
		label: {
			id: 'ui.customElements.transformations.applyPattern.label',
		},
	},
	[TRANSFORMATION_CAST_TO_BOOLEAN]: {
		description: {
			id: 'ui.customElements.transformations.castToBoolean.description',
		},
		label: {
			id: 'ui.customElements.transformations.castToBoolean.label',
		},
	},
	[TRANSFORMATION_CHARACTER_LENGTH]: {
		description: {
			id: 'ui.customElements.transformations.characterLength.description',
		},
		label: {
			id: 'ui.customElements.transformations.characterLength.label',
		},
	},
	[TRANSFORMATION_CHECKSUM]: {
		description: {
			id: 'ui.customElements.transformations.checksum.description',
		},
		label: {
			id: 'ui.customElements.transformations.checksum.label',
		},
	},
	[TRANSFORMATION_CONCATENATE]: {
		description: {
			id: 'ui.customElements.transformations.concatenate.description',
		},
		label: {
			id: 'ui.customElements.transformations.concatenate.label',
		},
	},
	[TRANSFORMATION_EXTRACT_DATE]: {
		description: {
			id: 'ui.customElements.transformations.extractDate.description',
		},
		label: {
			id: 'ui.customElements.transformations.extractDate.label',
		},
	},
	[TRANSFORMATION_EXTRACT_FROM_JSON]: {
		description: {
			id: 'ui.customElements.transformations.extractFromJson.description',
		},
		label: {
			id: 'ui.customElements.transformations.extractFromJson.label',
		},
	},
	[TRANSFORMATION_EXTRACT_NUMBER]: {
		description: {
			id: 'ui.customElements.transformations.extractNumber.description',
		},
		label: {
			id: 'ui.customElements.transformations.extractNumber.label',
		},
	},
	[TRANSFORMATION_NUMBER_OF_ELEMENTS]: {
		description: {
			id: 'ui.customElements.transformations.numberOfElements.description',
		},
		label: {
			id: 'ui.customElements.transformations.numberOfElements.label',
		},
	},
	[TRANSFORMATION_PREPEND]: {
		description: {
			id: 'ui.customElements.transformations.prepend.description',
		},
		label: {
			id: 'ui.customElements.transformations.prepend.label',
		},
	},
	[TRANSFORMATION_REPLACE]: {
		description: {
			id: 'ui.customElements.transformations.replace.description',
		},
		label: {
			id: 'ui.customElements.transformations.replace.label',
		},
	},
	[TRANSFORMATION_STRIP_TAGS]: {
		description: {
			id: 'ui.customElements.transformations.stripTags.description',
		},
		label: {
			id: 'ui.customElements.transformations.stripTags.label',
		},
	},
	[TRANSFORMATION_TRUNCATE]: {
		description: {
			id: 'ui.customElements.transformations.truncate.description',
		},
		label: {
			id: 'ui.customElements.transformations.truncate.label',
		},
	},
	[TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES]: {
		description: {
			id: 'ui.customElements.transformations.whitelistHtmlAttributes.description',
		},
		label: {
			id: 'ui.customElements.transformations.whitelistHtmlAttributes.label',
		},
	},
});

const options = LIST_OF_TRANSFORMATIONS.map((transformation) => {
	return {
		description: (
			<FormattedMessage {...messages[transformation].description} />
		),
		label: (
			<FormattedMessage {...messages[transformation].label} />
		),
		name: transformation,
	};
});



type Props = {
	name: string,
	width: number,
};

const TransformationField: React.FC<Props> = (props) => {
	const {
		name,
		width,
	} = props;

	return (
		<FieldStatus
			focusFieldErrorMessage={name}
			name={name}
			showIcon={false}
		>
			<SelectField
				dropdownWidth={250}
				isInteractedByDefault={false}
				name={name}
				options={options}
				popperBoundary={document.querySelector('.modal.js-scrollable')}
				size={SelectFieldSize.Small}
				width={width}
			/>
		</FieldStatus>
	);
};



export default TransformationField;
