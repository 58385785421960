import classNames from 'classnames';
import React from 'react';
import throttle from 'lodash/throttle';



export enum DropdownAttachment {
	Bottom = 'bottom',
	Top = 'top',
}

export enum DropdownStyle {
	Sidebar = 'sidebar',
}

type Props = {
	/** Attachment of whole element (from what position will be growing) */
	attachment?: DropdownAttachment,
	/** Specific height of dropdown and not only maximum height for possible higher content */
	height?: React.CSSProperties['height'],
	/** Scrollbars when content will be higher can be hidden. This feature doesn't work in all browsers. */
	hiddenScrollbars?: boolean,
	children: React.ReactNode,
	isOpen?: boolean,
	/** Custom max height of dropdown. When not set we use 200px */
	maxHeight?: React.CSSProperties['maxHeight'],
	/** Color style of dropdown */
	style?: DropdownStyle,
};



const Dropdown: React.FC<Props> = (props) => {
	const {
		attachment = DropdownAttachment.Top,
		height,
		hiddenScrollbars,
		children,
		isOpen,
		maxHeight = 200,
		style,
	} = props;

	const contentRef = React.useRef<HTMLDivElement>(null);
	const scrollableAreaRef = React.useRef<HTMLDivElement>(null);

	const [hasBottomShadow, setHasBottomShadow] = React.useState(false);
	const [hasTopShadow, setHasTopShadow] = React.useState(false);

	const handleScroll = React.useCallback(
		() => {
			if (!contentRef.current || !scrollableAreaRef.current) {
				return;
			}

			const content = contentRef.current;
			let newHasBottomShadow = false;
			let newHasTopShadow = false;

			const contentHeight = content.offsetHeight;

			if (scrollableAreaRef.current.offsetHeight < contentHeight) {
				newHasTopShadow = scrollableAreaRef.current.scrollTop > 0;
				newHasBottomShadow = contentHeight - scrollableAreaRef.current.scrollTop > scrollableAreaRef.current.offsetHeight;
			}

			setHasTopShadow(newHasTopShadow);
			setHasBottomShadow(newHasBottomShadow);
		},
		[
			contentRef,
			scrollableAreaRef,
		],
	);

	const throttledHandleScroll = React.useMemo(
		() => throttle(handleScroll, 50),
		[
			handleScroll,
		],
	);

	React.useEffect(
		() => handleScroll(),
		[
			handleScroll,
			maxHeight,
		],
	);

	React.useEffect(
		() => {
			setTimeout(() => {
				if (!scrollableAreaRef.current) {
					return;
				}

				scrollableAreaRef.current.scrollTop = 0;
			}, 500);
		},
		[
			isOpen,
		],
	);

	const componentClasses = classNames({
		'dropdown': true,
		'dropdown--is-open': isOpen,
		[ 'dropdown--' + attachment + '-attachment' ]: true,
		[ 'dropdown--' + style + '-style' ]: style,
		'dropdown--has-top-shadow': hasTopShadow,
		'dropdown--has-bottom-shadow': hasBottomShadow,
	});

	const scrollableAreaClasses = classNames({
		'dropdown__scrollable-area': true,
		'dropdown__scrollable-area--hidden-scrollbars': hiddenScrollbars,
		'js-scrollable': true,
	});

	const bodyClasses = classNames({
		'dropdown__body': true,
		'dropdown__body--has-style': style,
	});

	const contentHeight = height || maxHeight;

	return (
		<div className={componentClasses}>
			<div
				className="dropdown__content"
				style={{
					maxHeight: isOpen ? contentHeight : 0,
				}}
			>
				<div
					className={scrollableAreaClasses}
					onScroll={throttledHandleScroll}
					ref={scrollableAreaRef}
					style={{
						height,
						maxHeight: contentHeight,
					}}
				>
					<div
						className={bodyClasses}
						ref={contentRef}
					>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};



export default Dropdown;
