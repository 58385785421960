import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import RadioList from '~/components/atoms/forms/components/RadioList';



const messages = defineMessages({
	[GraphQL.UrlBlocklistMode.AllowAllExcept]: {
		id: 'ui.urlBlocklistForm.mode.allowAllExcept',
	},
	[GraphQL.UrlBlocklistMode.DenyAllExcept]: {
		id: 'ui.urlBlocklistForm.mode.denyAllExcept',
	},
});



type Props = {
	/**
	 * GraphQL.OnPageRequestsBlockingRuleOperator & GraphQL.UrlBlocklistRuleOperator differ in casing,
	 * the former being in PascalCase and the latter in camelCase. Until this is aligned, we use this
	 * prop to change the case of the operators.
	 */
	__UGLY__transformEnumsToCamelCase?: boolean,
	name: string,
};

const WebsiteOnPageRequestBlockingModeField: React.FC<Props> = (props) => {
	const {
		__UGLY__transformEnumsToCamelCase = false,
		name,
	} = props;

	const enumCase = (
		__UGLY__transformEnumsToCamelCase
			? (string) => string.charAt(0).toLowerCase() + string.slice(1)
			: (string) => string
	);

	return (
		<RadioList
			inline={true}
			items={[
				{
					label: <FormattedMessage {...messages[GraphQL.UrlBlocklistMode.AllowAllExcept]} />,
					value: enumCase(GraphQL.UrlBlocklistMode.AllowAllExcept),
				},
				{
					label: <FormattedMessage {...messages[GraphQL.UrlBlocklistMode.DenyAllExcept]} />,
					value: enumCase(GraphQL.UrlBlocklistMode.DenyAllExcept),
				},
			]}
			name={name}
			wrapItems={false}
		/>
	);
};



export default WebsiteOnPageRequestBlockingModeField;
