import React from 'react';

import EmptyValue from '~/components/app/EmptyValue';
import MissingValue from '~/components/app/MissingValue';
import Ellipsis from '~/components/patterns/values/Ellipsis';



export function renderEllipsisCell(
	value: React.ReactNode,
	zIndex: number | false = false,
	altPopupText: React.ReactNode = false,
) {
	const additionalAttrs: Partial<React.ComponentProps<typeof Ellipsis>> = {};

	if (value === null || value === undefined) {
		value = (
			<MissingValue ellipsis={true} />
		);
	}

	if (value === '') {
		value = (
			<EmptyValue ellipsis={true} />
		);
	}

	if (zIndex) {
		additionalAttrs.popupZIndex = zIndex;
	}

	if (altPopupText) {
		additionalAttrs.altPopupText = altPopupText;
	}

	return (
		<Ellipsis {...additionalAttrs}>
			{value}
		</Ellipsis>
	);
}
