import classNames from 'classnames';
import React from 'react';
import {
	FormattedMessage,
	type MessageDescriptor,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';

import styles from './LimitedRecordsOutput.module.scss';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import touchSupported from '~/utilities/touchSupported';



type Props = {
	children: RenderProp<{
		limit: number,
		numberOfRecords: number,
	}>,
	limit: number,
	numberOfRecords: number,
	showButtonOnHover?: boolean,
	showLessMessageKey: MessageDescriptor,
	showMoreMessageKey: MessageDescriptor,
};

const LimitedRecordsOutput: React.FC<Props> = (props) => {
	const {
		children,
		limit,
		numberOfRecords,
		showButtonOnHover = false,
		showLessMessageKey,
		showMoreMessageKey,
	} = props;

	const [showAll, setShowAll] = React.useState(false);

	const handleShowAllRecords = React.useCallback(
		(e) => {
			e.preventDefault();
			setShowAll(true);
		},
		[],
	);

	const handleShowLessRecords = React.useCallback(
		(e) => {
			e.preventDefault();
			setShowAll(false);
		},
		[],
	);

	function renderShowMoreButton() {
		if (showAll || numberOfRecords <= limit) {
			return null;
		}

		return (
			<div className={styles.actionElements}>
				<Button
					onClick={handleShowAllRecords}
					size={ButtonSize.XXSmall}
					style={ButtonStyle.Hollow}
					suffixIcon={(
						<DropdownIcon
							style={DropdownIconStyle.Greyscale}
						/>
					)}
				>
					<FormattedMessage
						{...showMoreMessageKey}
						values={{
							count: Math.min(0, numberOfRecords - limit),
						}}
					/>
				</Button>
			</div>
		);
	}

	function renderShowLessButton() {
		if (!showAll || numberOfRecords <= limit) {
			return null;
		}

		return (
			<div className={styles.actionElements}>
				<Button
					onClick={handleShowLessRecords}
					size={ButtonSize.XXSmall}
					style={ButtonStyle.Hollow}
					suffixIcon={(
						<DropdownIcon
							isActive={true}
							style={DropdownIconStyle.Greyscale}
						/>
					)}
				>
					<FormattedMessage {...showLessMessageKey} />
				</Button>
			</div>
		);
	}

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.showButtonOnHover]: showButtonOnHover && !showAll && !touchSupported,
	});

	return (
		<div className={componentClasses}>
			{renderProp(children, {
				limit,
				numberOfRecords: showAll ? numberOfRecords : Math.min(limit, numberOfRecords),
			})}

			{renderShowMoreButton()}
			{renderShowLessButton()}
		</div>
	);
};



export default LimitedRecordsOutput;
