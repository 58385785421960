import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import Spinner from '~/components/patterns/loaders/Spinner';

import useOpenModal from '~/hooks/useOpenModal';



const messages = defineMessages({
	button: {
		id: 'ui.bookWebinar.button',
	},
	description: {
		id: 'ui.bookWebinar.description',
	},
	title: {
		id: 'ui.bookWebinar.title',
	},
});



type Props = {
	hideButton?: boolean,
};

const BookWebinarButton: React.FC<Props> = (props) => {
	const {
		hideButton,
	} = props;

	const openWebinarModal = useOpenModal(
		({ closeCallback }) => (
			<WebinarModal closeCallback={closeCallback} />
		),
		[],
	);

	if (hideButton) {
		return null;
	}

	return (
		<Button
			icon={(
				<BasicIcon
					color="#ffffff"
					type={BasicIconType.Calendar}
				/>
			)}
			onClick={openWebinarModal}
			style={ButtonStyle.Highlight}
		>
			<FormattedMessage {...messages.button} />
		</Button>
	);
};



const WebinarModal: React.FC<{
	closeCallback: () => void,
}> = (props) => {
	const {
		closeCallback,
	} = props;

	const [isLoading, setLoading] = React.useState(true);

	return (
		<SimpleModal
			closeCallback={closeCallback}
			size={SimpleModalSize.Large}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ModalContentSection>
				<RichText>
					<Copy {...messages.description} />
				</RichText>
			</ModalContentSection>

			{isLoading && (
				<div style={{ height: 520 - 14 }}>
					<Spinner />
				</div>
			)}

			<iframe
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowFullScreen={true}
				frameBorder="0"
				height={isLoading ? 0 : 520}
				key={isLoading ? 'loading' : 'ready'}
				onLoad={() => {
					setLoading(false);
				}}
				src="https://www.youtube.com/embed/c1rf0EkBeY0?si=X5W6NH8QBlZ9AIj2"
				style={{
					opacity: isLoading ? 0 : 1,
				}}
				title="ContentKing Webinar: Platform Tour | ContentKing"
				width="100%"
			/>
		</SimpleModal>
	);
};



export default BookWebinarButton;
