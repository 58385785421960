import React from 'react';



type Props = {
	children: React.ReactNode,
};

const ColumnsStatusMessage: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="columns-status-message columns-status-message--success">
			{children}
		</div>
	);
};



export default ColumnsStatusMessage;
