import React from 'react';

import Emphasis from '~/components/patterns/typography/Emphasis';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import Gravatar from '~/components/patterns/users/Gravatar';
import OverflowingList from '~/components/atoms/lists/OverflowingList';
import TextualList from '~/components/patterns/lists/TextualList';



type GravatarDataProps = {
	/** Gravatar element */
	gravatar: React.ReactNode,
	/** Uniq key of item important for upcoming updates */
	key: string,
	/** Name visible on hover over gravatar */
	name: React.ReactNode,
};

type Props = {
	people: Array<GravatarDataProps>,
	placeholder?: React.ReactNode,
	/** Breakpoint width of the list. Depends on width we will calculate when to show ellipsis element. */
	width?: number,
};



const GravatarsList: React.FC<Props> = (props) => {
	const {
		people,
		placeholder,
		width,
	} = props;

	const elements = people.map(({ gravatar, key, name }) => (
		<Hint
			attachment={HintAttachment.Center}
			key={key}
			popup={name}
			popupOffset={({ placement }) => {
				if (placement === 'bottom-start' || placement === 'top-start') {
					return [-3, 0];
				}

				if (placement === 'bottom-end' || placement === 'top-end') {
					return [3, 0];
				}

				return [0, 0];
			}}
			popupZIndex={2200}
		>
			{gravatar}
		</Hint>
	));

	return (
		<OverflowingList
			ellipsisRenderer={({ hiddenItemsCount }) => (
				<Hint
					attachment={HintAttachment.Center}
					popup={(
						<TextualList>
							{people.slice(-hiddenItemsCount).map(({ key, name }) => (
								<React.Fragment key={key}>
									{name}
								</React.Fragment>
							))}
						</TextualList>
					)}
					popupOffset={({ placement }) => {
						if (placement === 'bottom-start' || placement === 'top-start') {
							return [-3, 0];
						}

						if (placement === 'bottom-end' || placement === 'top-end') {
							return [3, 0];
						}

						return [0, 0];
					}}
					popupZIndex={2200}
				>
					<Gravatar
						email={null}
						placeholder={'+' + hiddenItemsCount}
					/>
				</Hint>
			)}
			placeholder={placeholder && (
				<Emphasis>
					{placeholder}
				</Emphasis>
			)}
			width={width}
		>
			{elements}
		</OverflowingList>
	);
};



export default GravatarsList;
