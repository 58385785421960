import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import LabeledValue, {
	LabeledValueContentAlignment,
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import MarginsList from '~/components/atoms/lists/MarginsList';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import RichText from '~/components/patterns/typography/RichText';
import SimpleBox, {
	SimpleBoxBackground,
} from '~/components/patterns/boxes/SimpleBox';
import SubmitButton, {
	STYLE_ALERT,
} from '~/components/atoms/forms/components/SubmitButton';

import {
	useGrantAgencyAccessMutation,
} from './GrantAccessToAgencyModal.gql';

import useAccountAgencyConnections from '~/hooks/useAccountAgencyConnections';
import useAccountId from '~/hooks/useAccountId';
import useRevokeAgencyAccess from '~/hooks/useRevokeAgencyAccess';
import useUrlState from '~/hooks/useUrlState';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	cautionDescription: {
		id: 'ui.accountAccess.grantAccessToAgency.caution.description',
	},
	cautionTitle: {
		id: 'ui.general.caution',
	},
	description: {
		id: 'ui.accountAccess.grantAccessToAgency.description',
	},
	denyButton: {
		id: 'ui.accountAccess.grantAccessToAgency.denyButton',
	},
	identificationAgency: {
		id: 'ui.accountAccess.grantAccessToAgency.identification.agency',
	},
	identificationEmail: {
		id: 'ui.accountAccess.connectToAgency.emailLabel',
	},
	submitButton: {
		id: 'ui.accountAccess.grantAccessToAgency.submitButton',
	},
	title: {
		id: 'ui.accountAccess.grantAccessToAgency.title',
	},
});



const GrantAccessToAgencyModal = (props) => {
	const {
		closeCallback,
	} = props;

	const accountId = useAccountId();

	const accountAgencyConnections = useAccountAgencyConnections(accountId);
	const revokeAgencyAccess = useRevokeAgencyAccess();
	const urlState = useUrlState();

	const agencyId = urlState.params.agencyId;

	const [isDenying, setIsDenying] = React.useState(false);

	const handleDenyRequest = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			setIsDenying(true);

			await revokeAgencyAccess({
				agencyId,
				clientId: accountId,
			});

			setIsDenying(false);

			closeCallback();
		},
		[
			accountId,
			agencyId,
			closeCallback,
			revokeAgencyAccess,
		],
	);

	const [grantAgencyAccess] = useGrantAgencyAccessMutation();

	const handleSubmit = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			try {
				await grantAgencyAccess({
					variables: {
						agencyId,
						clientId: accountId,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}

			closeCallback();
		},
		[
			accountId,
			agencyId,
			closeCallback,
			grantAgencyAccess,
		],
	);

	if (!agencyId || accountAgencyConnections.isLoaded === false) {
		return false;
	}

	const connectingAgency = accountAgencyConnections.getByAccountId(agencyId);

	if (connectingAgency === null) {
		return false;
	}

	return (
		<ModalContainer
			header={(
				<ModalHeader
					title={(
						<FormattedMessage {...messages.title} />
					)}
				/>
			)}
		>
			<ModalTextSection>
				<RichText>
					<Copy {...messages.description} />
				</RichText>
			</ModalTextSection>

			<ModalTextSection>
				<MarginsList>
					<SimpleBox
						background={SimpleBoxBackground.Dark}
						paddingSize={2}
					>
						<LabeledValuesList>
							<LabeledValue
								contentAlignment={LabeledValueContentAlignment.Left}
								flow={LabeledValueFlow.ColumnOnSmall}
								label={(
									<FormattedMessage {...messages.identificationAgency} />
								)}
							>
								{connectingAgency.agencyAccountName}
							</LabeledValue>

							<LabeledValue
								contentAlignment={LabeledValueContentAlignment.Left}
								flow={LabeledValueFlow.ColumnOnSmall}
								label={(
									<FormattedMessage {...messages.identificationEmail} />
								)}
							>
								<div>{connectingAgency.inviterEmail}</div>
							</LabeledValue>
						</LabeledValuesList>
					</SimpleBox>

					<CalloutMessage
						borders={true}
						message={(
							<FormattedMessage {...messages.cautionTitle} />
						)}
						status={CalloutMessageStatus.Critical}
					>
						<p>
							<Copy
								{...messages.cautionDescription}
							/>
						</p>
					</CalloutMessage>
				</MarginsList>
			</ModalTextSection>

			<CenteredFormWrapper>
				<Form
					onSuccess={handleSubmit}
				>
					{({ isSubmitting, isValid }) => {
						return (
							<div>
								<FormErrorMessages />

								<ButtonsLayout>
									<SubmitButton
										disabled={isSubmitting}
										inactive={!isValid || isSubmitting}
										onClickCallback={handleDenyRequest}
										progress={isDenying}
										style={STYLE_ALERT}
									>
										<FormattedMessage {...messages.denyButton} />
									</SubmitButton>

									<SubmitButton
										disabled={isDenying}
										inactive={!isValid || isDenying}
										progress={isSubmitting}
									>
										<FormattedMessage {...messages.submitButton} />
									</SubmitButton>
								</ButtonsLayout>
							</div>
						);
					}}
				</Form>
			</CenteredFormWrapper>
		</ModalContainer>
	);
};



export default GrantAccessToAgencyModal;
