import React from 'react';

import KingdomSettingsConductorCustomerDomainsSection from '~/components/app/KingdomSettingsConductorCustomerDomainsSection';
import KingdomSettingsFreeEmailsSection from '~/components/app/KingdomSettingsFreeEmailsSection';
import KingdomSettingsGuaranteedBlacklistDomainsSection from '~/components/app/KingdomSettingsGuaranteedBlacklistDomainsSection';
import KingdomSettingsOverridableBlacklistDomainsSection from '~/components/app/KingdomSettingsOverridableBlacklistDomainsSection';
import KingdomSettingsTrialAbuserEmailsSection from '~/components/app/KingdomSettingsTrialAbuserEmailsSection';
import Sections from '~/components/logic/lego/Sections';

import matchAndReturn from '~/utilities/matchAndReturn';



enum Section {
	ConductorCustomerDomains = 'ConductorCustomerDomains',
	FreeEmails = 'FreeEmails',
	GuaranteedBlacklistDomains = 'GuaranteedBlacklistDomains',
	OverridableBlacklistDomains = 'OverridableBlacklistDomains',
	TrialAbuserEmails = 'TrialAbuserEmails',
}

const sectionSorting = [
	Section.FreeEmails,
	Section.TrialAbuserEmails,
	Section.ConductorCustomerDomains,
	Section.GuaranteedBlacklistDomains,
	Section.OverridableBlacklistDomains,
];

const sectionRoutes = {
	[Section.ConductorCustomerDomains]: 'generalAdmin.settings.conductorCustomerDomains',
	[Section.FreeEmails]: 'generalAdmin.settings',
	[Section.GuaranteedBlacklistDomains]: 'generalAdmin.settings.guaranteedBlacklistDomains',
	[Section.OverridableBlacklistDomains]: 'generalAdmin.settings.overridableBlacklistDomains',
	[Section.TrialAbuserEmails]: 'generalAdmin.settings.trialAbuserEmails',
};

const sectionTitles = {
	[Section.ConductorCustomerDomains]: 'Conductor domains',
	[Section.FreeEmails]: 'Free emails',
	[Section.GuaranteedBlacklistDomains]: 'Guaranteed blacklist domains',
	[Section.OverridableBlacklistDomains]: 'Overridable blacklist domains',
	[Section.TrialAbuserEmails]: 'Trial abuser emails',
};



const AdminKingdomSettings: React.FC = () => {
	const renderSectionTitle = React.useCallback(
		(section: Section) => sectionTitles[section],
		[],
	);

	const renderSectionContent = React.useCallback(
		(section: Section) => matchAndReturn(section, {
			[Section.ConductorCustomerDomains]: (
				<KingdomSettingsConductorCustomerDomainsSection />
			),
			[Section.FreeEmails]: (
				<KingdomSettingsFreeEmailsSection />
			),
			[Section.GuaranteedBlacklistDomains]: (
				<KingdomSettingsGuaranteedBlacklistDomainsSection />
			),
			[Section.OverridableBlacklistDomains]: (
				<KingdomSettingsOverridableBlacklistDomainsSection />
			),
			[Section.TrialAbuserEmails]: (
				<KingdomSettingsTrialAbuserEmailsSection />
			),
		}),
		[],
	);

	return (
		<Sections
			getSectionRoute={(section) => sectionRoutes[section]}
			renderSectionContent={renderSectionContent}
			renderSectionTitle={renderSectionTitle}
			sections={sectionSorting}
		/>
	);
};



export default AdminKingdomSettings;
