import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
} from '~/components/patterns/buttons/Button';
import ButtonContextProvider from '~/components/atoms/buttons/ButtonContextProvider';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	/** Part with buttons */
	buttons?: React.ReactNode,
	/** Required callback triggered when clicking on "Enter mobile filter" button */
	onMobileFilterButtonClickCallback: (() => void) | null,
	/** Part with overview of selected date */
	selectedDateOverview: React.ReactNode,
	/** Title part */
	title: React.ReactNode,
};



const RobotsTxtViewerHeader: React.FC<Props> = (props) => {
	const {
		buttons,
		onMobileFilterButtonClickCallback,
		selectedDateOverview,
		title,
	} = props;

	const viewportType = useViewportType();

	return (
		<ButtonContextProvider
			compact={viewportType.isSmall}
			iconSize={24}
			size={ButtonSize.Small}
			uppercase={true}
		>
			<section className="robots-txt-viewer-header">
				<div className="robots-txt-viewer-header__container">
					<div className="robots-txt-viewer-header__title">
						{title}
					</div>

					<div className="robots-txt-viewer-header__selected-date-overview">
						{!viewportType.isSmall && selectedDateOverview}
					</div>

					<div className="robots-txt-viewer-header__options">
						{buttons}
					</div>
				</div>

				{viewportType.isSmall && (
					<div className="robots-txt-viewer-header__footer">
						<div className="robots-txt-viewer-header__footer-content">
							{selectedDateOverview}
						</div>
						{onMobileFilterButtonClickCallback !== null && (
							<div className="robots-txt-viewer-header__mobile-filter-button">
								<Button
									compact={true}
									icon={(
										<BasicIcon type={BasicIconType.Versions} />
									)}
									onClick={onMobileFilterButtonClickCallback}
								/>
							</div>
						)}
					</div>
				)}
			</section>
		</ButtonContextProvider>
	);
};



export default RobotsTxtViewerHeader;
