import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import MissingValue from '~/components/app/MissingValue';
import ValueWithUnit from '~/components/patterns/values/ValueWithUnit';



export enum TimingFormatterUnit {
	Milliseconds = 'milliseconds',
	Seconds = 'seconds',
}



const messages = defineMessages({
	[TimingFormatterUnit.Milliseconds]: {
		id: 'ui.timingFormatter.milliseconds',
	},
	[TimingFormatterUnit.Seconds]: {
		id: 'ui.timingFormatter.seconds',
	},
});



type Props = {
	maximumFractionDigits?: number,
	minimumFractionDigits?: number,
	plain?: boolean,
	unit: TimingFormatterUnit,
	value: number | null,
};

const TimingFormatter: React.FC<Props> = (props) => {
	const {
		maximumFractionDigits,
		minimumFractionDigits,
		plain = false,
		unit,
		value = null,
	} = props;

	if (value === null) {
		return (
			<MissingValue ellipsis={true} />
		);
	}

	return (
		<ValueWithUnit
			plain={plain}
			unit={(
				<FormattedMessage {...messages[unit]} />
			)}
		>
			<FormattedNumber
				maximumFractionDigits={maximumFractionDigits}
				minimumFractionDigits={minimumFractionDigits}
				value={value}
			/>
		</ValueWithUnit>
	);
};



export default React.memo(TimingFormatter);
