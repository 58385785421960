import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SearchEngineActivityIconBuilder: IconBuilder = {
	icon: ({ color = '#30C1B9' }) => {
		return (
			<g fill="none">
				<path
					clipRule="evenodd"
					d="M32 32H0V0H32V32Z"
					fill="#A5A5A5"
					fillOpacity="0.01"
					fillRule="evenodd"
				/>
				<path
					d="M18.7971 24.9794C22.6999 24.9794 25.8638 21.8155 25.8638 17.9127C25.8638 14.0098 22.6999 10.8459 18.7971 10.8459C14.8942 10.8459 11.7303 14.0098 11.7303 17.9127C11.7303 21.8155 14.8942 24.9794 18.7971 24.9794Z"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					d="M24.9216 21.572L29.1204 25.7706C29.2828 25.9318 29.4117 26.1235 29.4997 26.3349C29.5877 26.5462 29.633 26.773 29.633 27.0019C29.633 27.2309 29.5877 27.4577 29.4997 27.669C29.4117 27.8803 29.2828 28.0721 29.1204 28.2333C28.9582 28.3964 28.7655 28.5259 28.5533 28.6142C28.3411 28.7026 28.1136 28.7481 27.8837 28.7481C27.6539 28.7481 27.4264 28.7026 27.2142 28.6142C27.002 28.5259 26.8093 28.3964 26.6471 28.2333L22.4506 24.0369"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					d="M9.8459 23.3785H4.38418C4.01708 23.3785 3.66501 23.2327 3.40543 22.9731C3.14584 22.7135 3 22.3614 3 21.9943V5.38418C3 5.01707 3.14584 4.665 3.40543 4.40542C3.66501 4.14583 4.01708 4 4.38418 4H25.3268C25.6939 4 26.046 4.14583 26.3056 4.40542C26.5652 4.665 26.711 5.01707 26.711 5.38418V11.788"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					d="M3 7.92493H26.711"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					d="M6.5481 12.0835H8.48351H10.4189"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					d="M6.5481 15.2534H8.53446"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					d="M6.5481 18.4231H8.53446"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default SearchEngineActivityIconBuilder;
