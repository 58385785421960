import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Ellipsis from '~/components/patterns/values/Ellipsis';
import EmptyValue from '~/components/app/EmptyValue';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import IgnoredValue from '~/components/patterns/issues/IgnoredValue';
import MissingValue from '~/components/app/MissingValue';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';
import StringFormatter from '~/components/app/StringFormatter';

import {
	isNotFalse,
} from '~/utilities/typeCheck';



const ELLIPSIS_POPUP_ZINDEX = 2000;

const messages = defineMessages({
	columnAlt: {
		id: 'ui.issueDetail.details.imageMarkup.alt',
	},
	columnSrc: {
		id: 'ui.issueDetail.details.imageMarkup.src',
	},
	emptyLabel: {
		id: 'ui.general.empty',
	},
	missingLabel: {
		id: 'ui.general.missing',
	},
});



type Props = {
	attribute: 'alt' | 'mixedTransport',
	issueContext: {
		images: ReadonlyArray<{
			is_ignored_on: boolean,
			src: string,
		}>,
	},
	tableWidth: number,
};

const ImageMarkup: React.FC<Props> = (props) => {
	const {
		attribute,
		issueContext,
		tableWidth,
	} = props;

	const images = issueContext.images;

	const imagesList = React.useMemo(
		() => {
			return [...images].sort((imageA, imageB) => {
				const isIgnoredA = imageA.is_ignored_on;
				const isIgnoredB = imageB.is_ignored_on;

				if (isIgnoredA && !isIgnoredB) {
					return 1;
				}

				if (!isIgnoredA && isIgnoredB) {
					return -1;
				}

				return 0;
			});
		},
		[
			images,
		],
	);

	const checkIsIgnored = React.useCallback(
		({ row }) => {
			return row.is_ignored_on !== undefined;
		},
		[],
	);

	if (imagesList.length === 0) {
		return null;
	}

	return (
		<SmallTable
			columns={[
				{
					render: {
						cell: ({ row }) => {
							let value = row.src;
							let popupAltText;

							if (value === null || value === undefined) {
								value = (
									<MissingValue ellipsis={true} />
								);

								popupAltText = (
									<FormattedMessage {...messages.missingLabel} />
								);
							} else if (value === '') {
								value = (
									<EmptyValue ellipsis={true} />
								);

								popupAltText = (
									<FormattedMessage {...messages.emptyLabel} />
								);
							} else {
								value = (
									<Ellipsis
										altPopupText={(
											<ExternalLink href={row.src}>
												{popupAltText || value}
											</ExternalLink>
										)}
										popupZIndex={ELLIPSIS_POPUP_ZINDEX}
									>
										<ExternalLink
											ellipsis={true}
											href={row.src}
										>
											{value}
										</ExternalLink>
									</Ellipsis>
								);
							}

							if (row.is_ignored_on === undefined) {
								return value;
							}

							return (
								<IgnoredValue>
									{value}
								</IgnoredValue>
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnSrc} />
						),
					},
					width: 450,
				},
				attribute === 'alt' && {
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis popupZIndex={ELLIPSIS_POPUP_ZINDEX}>
									<StringFormatter
										value={row.alt}
									/>
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnAlt} />
						),
					},
					width: 300,
				},
			].filter(isNotFalse)}
			rows={imagesList}
			semitransparentGetter={checkIsIgnored}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(ImageMarkup);
