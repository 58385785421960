/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveTwoFactorAuthenticationMutationVariables = GraphQL.Exact<{
  code: GraphQL.Scalars['String']['input'];
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type RemoveTwoFactorAuthenticationMutation = { readonly __typename?: 'Mutation', readonly RemoveTwoFactorAuthentication: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly hasTwoFactorAuthentication: boolean, readonly id: string, readonly legacyId: string } | null } } };


export const RemoveTwoFactorAuthenticationDocument = gql`
    mutation RemoveTwoFactorAuthentication($code: String!, $legacyUserId: ID!) {
  RemoveTwoFactorAuthentication(code: $code) {
    query {
      user(legacyId: $legacyUserId) {
        hasTwoFactorAuthentication
        id
        legacyId
      }
    }
  }
}
    `;
export type RemoveTwoFactorAuthenticationMutationFn = Apollo.MutationFunction<RemoveTwoFactorAuthenticationMutation, RemoveTwoFactorAuthenticationMutationVariables>;

/**
 * __useRemoveTwoFactorAuthenticationMutation__
 *
 * To run a mutation, you first call `useRemoveTwoFactorAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTwoFactorAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTwoFactorAuthenticationMutation, { data, loading, error }] = useRemoveTwoFactorAuthenticationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useRemoveTwoFactorAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTwoFactorAuthenticationMutation, RemoveTwoFactorAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTwoFactorAuthenticationMutation, RemoveTwoFactorAuthenticationMutationVariables>(RemoveTwoFactorAuthenticationDocument, options);
      }
export type RemoveTwoFactorAuthenticationMutationHookResult = ReturnType<typeof useRemoveTwoFactorAuthenticationMutation>;
export type RemoveTwoFactorAuthenticationMutationResult = Apollo.MutationResult<RemoveTwoFactorAuthenticationMutation>;
export type RemoveTwoFactorAuthenticationMutationOptions = Apollo.BaseMutationOptions<RemoveTwoFactorAuthenticationMutation, RemoveTwoFactorAuthenticationMutationVariables>;