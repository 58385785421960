import React from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';

import ActivePanelProvider from '~/components/providers/ActivePanelProvider';
import CloseByEscape from './CloseByEscape';
import MainErrorBoundary from '../../logic/MainErrorBoundary';
import ModalPanel from './ModalPanel';
import PageContent from '~/components/patterns/layout/PageContent';
import Panel from './Panel';

import {
	ScrollContainerProvider,
} from '~/hooks/useScrollContainer';

import {
	navigate,
} from '~/routing/router';

import {
	contentCurrentSelector,
	contentStackSelector,
} from '~/state/ui/content/selectors';



const MobilePanelStack = (props) => {
	const {
		animations,
	} = props;

	const current = useSelector(contentCurrentSelector);
	const stack = useSelector(contentStackSelector);

	let activePanelFound = false;
	let activePanelCloseCallback;

	const somePanelIsActive = stack.slice(1).some((panel) => {
		return current === panel.content;
	});

	let mainContent = stack.slice(0, 1)[0];
	if (!mainContent) {
		return null;
	}

	let panels = stack.slice(1);

	panels = panels.map((panelSetup, i) => {
		const PanelContent = panelSetup.content;
		const currentPanelIsActive = current === PanelContent;
		let isOverlaid = false;

		const closeCallback = function () {
			navigate(panelSetup.closeRoute.name, panelSetup.closeRoute.params || {});
		};

		if (!activePanelFound) {
			if (currentPanelIsActive) {
				activePanelFound = true;
				activePanelCloseCallback = closeCallback;
			} else {
				isOverlaid = true;
			}
		}

		const isVisible = (currentPanelIsActive || !activePanelFound) && somePanelIsActive;
		const isOverlay = i > 0;

		let content;
		if (PanelContent) {
			content = (
				<MainErrorBoundary>
					<PanelContent />
				</MainErrorBoundary>
			);
		}

		const panelProps = {
			animateOnMount: animations,
			backlink: panelSetup.backlink,
			visible: isVisible,
			overlaid: isOverlaid,
			overlay: isOverlay,
			closeCallback,
			key: i,
		};

		if (Panel === ModalPanel) {
			return (
				<CSSTransition
					classNames={{
						exit: 'modal--closing',
					}}
					in={true}
					key={`panel-${i}`}
					timeout={200}
				>
					<Panel {...panelProps}>
						{content}
					</Panel>
				</CSSTransition>
			);
		}

		return (
			<CSSTransition
				classNames={{
					exit: 'panel--before-closing',
					exitActive: 'panel--closing',
				}}
				in={true}
				key={`panel-${i}`}
				timeout={200}
			>
				<div>
					<Panel {...panelProps}>
						{content}
					</Panel>
				</div>
			</CSSTransition>
		);
	});

	let closeByEscape;
	if (activePanelFound) {
		closeByEscape = (
			<CloseByEscape
				callback={activePanelCloseCallback}
			/>
		);
	}

	const ContentContent = mainContent.content;
	mainContent = (
		<ContentContent />
	);

	return (
		<ActivePanelProvider
			close={activePanelCloseCallback ?? null}
			isModal={true}
		>
			<ScrollContainerProvider>
				{({ containerRef }) => (
					<div>
						<PageContent ref={containerRef}>
							<MainErrorBoundary>
								{mainContent}
							</MainErrorBoundary>
						</PageContent>

						{closeByEscape}

						<TransitionGroup
							enter={false}
						>
							{panels}
						</TransitionGroup>
					</div>
				)}
			</ScrollContainerProvider>
		</ActivePanelProvider>
	);
};



export default MobilePanelStack;
