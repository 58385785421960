import Immutable from 'immutable';

import {
	CHANGE_URL_STATE,
} from '~/actions';

import {
	UPDATE_FILTER,
	UPDATE_SORT_BY,
} from '~/actions/affectedLinks';

import {
	DEFAULT_FILTER,
	DEFAULT_SORT_BY,
} from '~/model/affectedLinks';

import {
	decodeFilterParameter,
} from '~/model/filterParameter';



function createDefaultState() {
	return Immutable.Map({
		filter: DEFAULT_FILTER,
		sortBy: DEFAULT_SORT_BY,
	});
}



export function affectedLinks(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			const {
				previousUrlState,
				urlState,
			} = action;

			if (urlState.params.websiteId !== previousUrlState.params.websiteId) {
				state = createDefaultState();
			}

			if (['website.issues.issueCategory.affectedLinks', 'website.issues.issueCategory.singleIssueAffectedLinks'].indexOf(urlState.name) > -1) {
				if (urlState.params.filter) {
					const unpackedFilter = decodeFilterParameter(urlState.params.filter);

					if (unpackedFilter.filter) {
						state = state.set('filter', unpackedFilter.filter);
					}

					if (unpackedFilter.sortBy) {
						state = state.set('sortBy', unpackedFilter.sortBy);
					}
				} else {
					state = state.set('filter', DEFAULT_FILTER);
					state = state.set('sortBy', DEFAULT_SORT_BY);
				}
			}

			break;
		}

		case UPDATE_FILTER: {
			const {
				filter,
			} = action;

			state = state.mergeIn(['filter'], filter);
			state = state.setIn(['filter'], state.get('filter').filter((val) => val !== ''));

			break;
		}

		case UPDATE_SORT_BY: {
			const {
				sortBy,
			} = action;

			state = state.mergeIn(['sortBy'], sortBy);

			break;
		}

	}

	return state;
}
