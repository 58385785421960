import React from 'react';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BooleanFormatter from '~/components/app/BooleanFormatter';
import StatusDot, {
	StatusDotStatus,
} from '~/components/patterns/statuses/StatusDot';



type Props = {
	value: boolean,
};

const IsIndexableFormatter: React.FC<Props> = (props) => {
	const {
		value,
	} = props;

	return (
		<AttachedIcon
			ellipsis={false}
			icon={(
				<StatusDot status={value ? StatusDotStatus.Success : StatusDotStatus.Warning} />
			)}
		>
			<BooleanFormatter value={value} />
		</AttachedIcon>
	);
};



export default React.memo(IsIndexableFormatter);
