import React from 'react';

import styles from './ScreenPlaceholder.module.scss';



type Props = {
	/** Message visible in attached speech bubble */
	message: React.ReactNode,
};



const ScreenPlaceholder: React.FC<Props> = (props) => {
	const {
		message,
	} = props;

	return (
		<div className={styles.component}>
			<div className={styles.bubble}>
				{message}
			</div>
		</div>
	);
};



export default ScreenPlaceholder;
