import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	getBillingCycleDurationInMonths,
} from '~/model/pricing/billingCycle';



const messages = defineMessages({
	[GraphQL.Term.Annually]: {
		id: 'ui.plan.form.annuallyPaymentTerm',
	},
	[GraphQL.Term.Monthly]: {
		id: 'ui.plan.form.monthlyPaymentTerm',
	},
	[GraphQL.Term.Quarterly]: {
		id: 'ui.plan.form.quarterlyPaymentTerm',
	},
	monthNotation: {
		id: 'ui.billing.cycle.anyInMonths',
	},
});



type Props = {
	billingCycle: GraphQL.Term,
	useMonthNotation?: boolean,
};

const BillingCycleName: React.FC<Props> = (props) => {
	const {
		billingCycle,
		useMonthNotation = false,
	} = props;

	if (useMonthNotation) {
		return (
			<FormattedMessage
				{...messages.monthNotation}
				tagName="span"
				values={{
					count__months: getBillingCycleDurationInMonths(billingCycle),
				}}
			/>
		);
	}

	const message = messages[billingCycle];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage
			{...message}
			tagName="span"
		/>
	);
};



export default BillingCycleName;
