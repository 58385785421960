import React from 'react';

import GraphQL from '~/types/graphql';

import AdminCleanCustomerAccountModal from '~/components/app/AdminCleanCustomerAccountModal';
import AdminConnectConductorOrganizationModal from '~/components/app/AdminConnectConductorOrganizationModal';
import AdminDisconnectConductorOrganizationModal from '~/components/app/AdminDisconnectConductorOrganizationModal';
import AdminMarkAccountAsOutOfBandModal from '~/components/app/AdminMarkAccountAsOutOfBandModal';
import AdminRemoveAccountModal from '~/components/app/AdminRemoveAccountModal';
import AdminSignupOutOfBandAccountModal from '~/components/app/AdminSignupOutOfBandAccountModal';
import AdminUnsignupAccountModal from '~/components/app/AdminUnsignupAccountModal';
import ContextNavigationButton, {
	ContextNavigationButtonIconType,
} from '~/components/patterns/navigations/ContextNavigationButton';
import List from '~/components/patterns/lists/List';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useAccountAdminContextActionsQuery,
} from './AccountAdminContextActions.gql';

import useAccountCanSignup from '~/hooks/useAccountCanSignup';
import useAccountId from '~/hooks/useAccountId';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';
import useAccountPhase from '~/hooks/useAccountPhase';
import useOpenModal from '~/hooks/useOpenModal';

import {
	CONTENTKING_ENVIRONMENT,
} from '~/config';



const AccountAdminContextActions: React.FC = () => {
	const accountId = useAccountId();
	const accountCanSignUp = useAccountCanSignup(accountId);
	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const accountPhase = useAccountPhase(accountId);

	const { data } = useAccountAdminContextActionsQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const conductorOrganizationId = data?.account?.conductorOrganizationId ?? null;
	const grafanaLink = data?.account?.grafanaLink ?? null;
	const intercomCompanyLink = data?.account?.intercomCompanyLink ?? null;
	const isInternalRestricted = data?.account?.isInternalRestricted ?? false;
	const recurlyLink = data?.account?.recurlyLink ?? null;
	const scrapbookLink = data?.account?.scrapbookLink ?? null;
	const stripeCustomerUrl = data?.account?.stripeCustomerUrl ?? null;

	const handleOpenCleanCustomerAccountModal = useOpenModal(
		() => (
			<AdminCleanCustomerAccountModal
				accountId={accountId}
			/>
		),
		[
			accountId,
		],
	);

	const handleOpenConnectConductorAccountModal = useOpenModal(
		() => {
			if (conductorOrganizationId === null) {
				return (
					<AdminConnectConductorOrganizationModal accountId={accountId} />
				);
			}

			return (
				<AdminDisconnectConductorOrganizationModal
					accountId={accountId}
					conductorOrganizationId={conductorOrganizationId}
				/>
			);
		},
		[
			accountId,
			conductorOrganizationId,
		],
	);

	const handleOpenMarkAccountAsOutOfBandModal = useOpenModal(
		() => (
			<AdminMarkAccountAsOutOfBandModal
				accountId={accountId}
			/>
		),
		[
			accountId,
		],
	);

	const handleOpenDeleteTeamModal = useOpenModal(
		() => (
			<AdminRemoveAccountModal
				accountId={accountId}
			/>
		),
		[
			accountId,
		],
	);

	const handleOpenUnsignupAccountModal = useOpenModal(
		() => (
			<AdminUnsignupAccountModal
				accountId={accountId}
			/>
		),
		[
			accountId,
		],
	);

	const handleOpenSignupOutOfBandAccount = useOpenModal(
		() => (
			<AdminSignupOutOfBandAccountModal
				accountId={accountId}
			/>
		),
		[
			accountId,
		],
	);

	return (
		<List>
			{accountPhase === GraphQL.AccountPhase.Customer && (
				<WhenAccountActionAllowed
					accountId={accountId}
					action={GraphQL.ActionWithAccount.ManageInternals}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false || isInternalRestricted}
							iconType={ContextNavigationButtonIconType.Delete}
							onClickCallback={handleOpenCleanCustomerAccountModal}
							tooltip={message}
						>
							Clean Account
						</ContextNavigationButton>
					)}
				</WhenAccountActionAllowed>
			)}

			{accountCanSignUp === false && (
				<WhenAccountActionAllowed
					accountId={accountId}
					action={GraphQL.ActionWithAccount.ManageInternals}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false}
							iconType={ContextNavigationButtonIconType.Delete}
							onClickCallback={handleOpenUnsignupAccountModal}
							tooltip={message}
						>
							Unsignup Account
						</ContextNavigationButton>
					)}
				</WhenAccountActionAllowed>
			)}

			<WhenAccountActionAllowed
				accountId={accountId}
				action={GraphQL.ActionWithAccount.ManageInternals}
				showMessage={false}
			>
				{({ isAllowed, message }) => (
					<ContextNavigationButton
						disabled={isAllowed.yes === false || isInternalRestricted}
						iconType={ContextNavigationButtonIconType.Delete}
						onClickCallback={handleOpenDeleteTeamModal}
						tooltip={message}
					>
						Delete Account
					</ContextNavigationButton>
				)}
			</WhenAccountActionAllowed>

			{accountPhase === GraphQL.AccountPhase.Customer && accountIsOutOfBand === false && (
				<WhenAccountActionAllowed
					accountId={accountId}
					action={GraphQL.ActionWithAccount.MarkAccountAsOutOfBand}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false}
							iconType={ContextNavigationButtonIconType.Export}
							onClickCallback={handleOpenMarkAccountAsOutOfBandModal}
							tooltip={message}
						>
							Mark Account as out of band
						</ContextNavigationButton>
					)}
				</WhenAccountActionAllowed>
			)}

			{grafanaLink !== null && (
				<ContextNavigationButton
					href={grafanaLink}
					iconType={ContextNavigationButtonIconType.Graphs}
				>
					Diagnose
				</ContextNavigationButton>
			)}

			{intercomCompanyLink !== null && (
				<ContextNavigationButton
					href={intercomCompanyLink}
					iconType={ContextNavigationButtonIconType.Link}
				>
					Intercom link
				</ContextNavigationButton>
			)}

			{recurlyLink !== null && (
				<ContextNavigationButton
					href={recurlyLink}
					iconType={ContextNavigationButtonIconType.Link}
				>
					Recurly link
				</ContextNavigationButton>
			)}

			{stripeCustomerUrl !== null && (
				<ContextNavigationButton
					href={stripeCustomerUrl}
					iconType={ContextNavigationButtonIconType.Link}
				>
					Stripe link
				</ContextNavigationButton>
			)}

			{accountCanSignUp === true && accountIsOutOfBand && (
				<WhenAccountActionAllowed
					accountId={accountId}
					action={GraphQL.ActionWithAccount.SubmitSignup}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false}
							iconType={ContextNavigationButtonIconType.Link}
							onClickCallback={handleOpenSignupOutOfBandAccount}
							tooltip={message}
						>
							Signup
						</ContextNavigationButton>
					)}
				</WhenAccountActionAllowed>
			)}

			{accountIsOutOfBand && (
				<WhenAccountActionAllowed
					accountId={accountId}
					action={GraphQL.ActionWithAccount.ManageInternals}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false || isInternalRestricted}
							iconType={ContextNavigationButtonIconType.CogWheel}
							onClickCallback={handleOpenConnectConductorAccountModal}
							tooltip={message}
						>
							{conductorOrganizationId === null
								? 'Connect Conductor organization'
								: 'Disconnect Conductor org.'
							}
						</ContextNavigationButton>
					)}
				</WhenAccountActionAllowed>
			)}

			{CONTENTKING_ENVIRONMENT === 'production' && scrapbookLink !== null && (
				<ContextNavigationButton
					href={scrapbookLink}
					iconType={ContextNavigationButtonIconType.Graphs}
				>
					Show action log
				</ContextNavigationButton>
			)}
		</List>
	);
};



export default AccountAdminContextActions;
