import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum RealtimeIndexNowIconVariants {
	Default = 'default',
	NotAvailable = 'not-available',
}



type RealtimeIndexNowIconBuilderProps = {
	variant?: RealtimeIndexNowIconVariants,
};

const RealtimeIndexNowIconBuilder: IconBuilder<RealtimeIndexNowIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = RealtimeIndexNowIconVariants.Default,
		} = props;

		if (variant === RealtimeIndexNowIconVariants.NotAvailable) {
			return (
				<g
					fill="none"
					fillRule="evenodd"
					id="Page-1"
					opacity="0.7"
					stroke="none"
					strokeLinecap="round"
					strokeWidth="1"
				>
					<g
						id="Path"
						stroke="#99ACBF"
						transform="translate(4.000000, 5.000000)"
					>
						<path d="M8.69968254,13.2006349 L0.874285714,13.2006349 C0.642405714,13.2006349 0.420034921,13.1085651 0.256068571,12.9446095 C0.0921130159,12.780546 0,12.5581968 0,12.3263492 L0,0.582867939 C0.00282793651,0.430159368 0.0654742857,0.284660955 0.174479365,0.177674288 C0.283484444,0.0706876215 0.43011619,0.0107720142 0.582846349,0.0107720142 L4.99744952,0.0107720142 C5.09486222,0.00975746281 5.19089333,0.034075558 5.27605524,0.0813949231 C5.36121714,0.128703495 5.43258476,0.197383495 5.48316381,0.280645717 L6.8431746,2.62286794 L15.2190476,2.62286794"></path>
						<path d="M10.5346032,2.24507937 L10.5346032,0.863492066 C10.5332,0.750612066 10.5541397,0.638573971 10.5963429,0.533864764 C10.638654,0.429166352 10.7011492,0.333880002 10.7804825,0.253553653 C10.8598159,0.173227304 10.9543683,0.109458415 11.058527,0.0659276215 C11.1626857,0.0224076215 11.2744,2.39667193e-16 11.3873016,2.39667193e-16 L14.3555556,2.39667193e-16 C14.5845968,2.39667193e-16 14.8042476,0.0909688914 14.9661524,0.252906034 C15.1280571,0.414843177 15.2190476,0.634483177 15.2190476,0.863492066 L15.2190476,2.23429651"></path>
						<path d="M5.85014794,0.863492066 C5.84871238,0.750612066 5.86970603,0.638573971 5.91192,0.533864764 C5.95413397,0.429166352 6.01671556,0.333880002 6.0960381,0.253553653 C6.17536063,0.173227304 6.26984825,0.109458415 6.37401778,0.0659276215 C6.47817651,0.0224076215 6.58994476,2.39667193e-16 6.70283556,2.39667193e-16 L9.67108952,2.39667193e-16 C9.78444444,2.39667193e-16 9.89680635,0.022332066 10.0015048,0.0657333358 C10.1063111,0.109123812 10.2015111,0.172730796 10.2817079,0.252906034 C10.3619048,0.333092066 10.4254794,0.428281272 10.4688698,0.533044447 C10.5122603,0.637807622 10.5346032,0.750104764 10.5346032,0.863492066 L10.5346032,2.23429651"></path>
						<line
							x1="2.26664508"
							x2="8.88315302"
							y1="5.18095238"
							y2="5.18095238"
						>
						</line>
						<line
							x1="2.26664508"
							x2="7.12378794"
							y1="10.0380952"
							y2="10.0380952"
						>
						</line>
						<line
							x1="2.26664508"
							x2="7.60950222"
							y1="7.60952381"
							y2="7.60952381"
						>
						</line>
						<polyline points="13.168254 12.3479365 10.1460317 15.5860317 11.7434921 11.1714286 9.85460317 11.1714286 10.6965079 9.41206349"></polyline>
						<polyline points="15.0787302 9.22857143 16.0825397 9.22857143 15.6615873 9.69269842"></polyline>
						<polyline points="12.1320635 6.38984127 13.0387302 4.49015873 17 4.49015873 14.5174603 8.27873016"></polyline>
						<line
							x1="9.87619048"
							x2="16.5250794"
							y1="6.2063708"
							y2="12.8552381"
						>
						</line>
					</g>
				</g>
			);
		}

		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				opacity="0.7"
				stroke="none"
				strokeLinecap="round"
				strokeWidth="1"
			>
				<g
					id="icon"
					stroke="#367be2"
					transform="translate(4.000000, 4.000000)"
				>
					<g
						id="Group"
						transform="translate(0.000000, 0.000000)"
					>
						<path
							d="M9.21142857,14.3483787 L0.925714286,14.3483787 C0.680194286,14.3483787 0.444742857,14.2483037 0.271131429,14.0700929 C0.0975314286,13.8917647 0,13.6500831 0,13.3980772 L0,0.633546035 C0.00299428571,0.467560048 0.0693257143,0.309411116 0.184742857,0.193122375 C0.30016,0.0768336348 0.455417143,0.0117085988 0.617131429,0.0117085988 L5.29141714,0.0117085988 C5.39456,0.0106058362 5.49624,0.037038295 5.58641143,0.0884718945 C5.67658286,0.139893762 5.75216,0.21454522 5.80571429,0.305046768 L7.24571429,2.85091609 L16.1142857,2.85091609"
							id="Path"
						>
						</path>
						<path
							d="M11.1542857,2.44028027 L11.1542857,0.938569336 C11.1528,0.81587486 11.1749714,0.694095489 11.2196571,0.580282225 C11.2644571,0.466480694 11.3307429,0.362909568 11.4146286,0.275599155 C11.4986286,0.188288743 11.5987429,0.118975398 11.7090286,0.0716597713 C11.8193143,0.0243558769 11.9376,3.52659078e-16 12.0571429,3.52659078e-16 L15.2,3.52659078e-16 C15.4425143,3.52659078e-16 15.6750857,0.098878282 15.8465143,0.274895228 C16.0179429,0.450912175 16.1142857,0.689649017 16.1142857,0.938569336 L16.1142857,2.42855989"
							id="Path"
						>
						</path>
						<path
							d="M6.19427429,0.938569336 C6.19275429,0.81587486 6.21498286,0.694095489 6.25968,0.580282225 C6.30437714,0.466480694 6.37064,0.362909568 6.45462857,0.275599155 C6.53861714,0.188288743 6.63866286,0.118975398 6.74896,0.0716597713 C6.85924571,0.0243558769 6.97758857,3.52659078e-16 7.09712,3.52659078e-16 L10.2399771,3.52659078e-16 C10.4825143,3.52659078e-16 10.7149714,0.098878282 10.8865143,0.274895228 C11.0579429,0.450912175 11.1542857,0.689649017 11.1542857,0.938569336 L11.1542857,2.42855989"
							id="Path"
						>
						</path>
						<line
							id="Path"
							x1="2"
							x2="9.87428571"
							y1="5.86605834"
							y2="5.86605834"
						>
						</line>
						<line
							id="Path"
							x1="2"
							x2="7.54285714"
							y1="11.1455108"
							y2="11.1455108"
						>
						</line>
						<line
							id="Path"
							x1="2"
							x2="9.04"
							y1="8.50578459"
							y2="8.50578459"
						>
						</line>
						<polygon
							id="Path"
							points="13.8057143 4.88056054 18 4.88056054 14.72 10.0309598 17.0285714 10.0309598 10.7428571 16.9411765 12.4342857 12.1427408 10.4342857 12.1427408"
						>
						</polygon>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default RealtimeIndexNowIconBuilder;

