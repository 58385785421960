import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import BillingCycleSwitchLabel from '~/components/patterns/pricing/BillingCycleSwitchLabel';
import SwitchField from '~/components/patterns/forms/fields/SwitchField';

import {
	teamSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	annually: {
		id: 'ui.billing.cycleDescription.annually',
	},
	annuallyDiscount: {
		id: 'ui.billing.cycleDescription.annuallyDiscount',
	},
	monthly: {
		id: 'ui.billing.cycleDescription.monthly',
	},
});



type Props = {
	billingCycle: GraphQL.Term,
	name: string,
	onChangeCallback: (billingCycle: GraphQL.Term) => void,
};

const BillingCycleSwitch: React.FC<Props> = (props) => {
	const {
		billingCycle,
		onChangeCallback,
		name,
	} = props;

	const account = useSelector(teamSelector);

	const showAnnualDiscount = (
		!account?.get('allowed_plans').includes(GraphQL.AccountPlan.Enterprise)
		&& account?.get('tariff') === 'v4'
	);

	const handleChange = React.useCallback(
		(_, checked) => {
			onChangeCallback(checked ? GraphQL.Term.Annually : GraphQL.Term.Monthly);
		},
		[
			onChangeCallback,
		],
	);

	return (
		<SwitchField
			isChecked={billingCycle === GraphQL.Term.Annually}
			label={(
				billingCycle === GraphQL.Term.Annually ? (
					<BillingCycleSwitchLabel
						additionalInfo={showAnnualDiscount && (
							<FormattedMessage {...messages.annuallyDiscount} />
						)}
						billingCycleLabel={(
							<FormattedMessage {...messages.annually} />
						)}
					/>
				) : (
					<BillingCycleSwitchLabel
						billingCycleLabel={(
							<FormattedMessage {...messages.monthly} />
						)}
					/>
				)
			)}
			name={name}
			onChangeCallback={handleChange}
		/>
	);
};



export default BillingCycleSwitch;
