import type GraphQL from '~/types/graphql';



export function getBySearchEngine<T extends {
		searchEngine: GraphQL.SearchEngineActivitySearchEngine,
	}>(
	searchEngineActivity: ReadonlyArray<T> | null,
	searchEngine: GraphQL.SearchEngineActivitySearchEngine,
) {
	return (searchEngineActivity ?? []).find(
		(item) => item.searchEngine === searchEngine,
	) ?? null;
}
