import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';
import FullUserName from '~/components/app/FullUserName';
import MultiselectField from '~/components/atoms/forms/components/MultiselectField';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useFormContext from '~/hooks/useFormContext';
import useIsAllowedWithUserWebsiteAccessOracle from '~/hooks/useIsAllowedWithUserWebsiteAccessOracle';
import useUserEmail from '~/hooks/useUserEmail';
import useUsersWithWebsiteAccess from '~/hooks/useUsersWithWebsiteAccess';

import {
	sortUsers,
} from '~/model/users';



const messages = defineMessages({
	everybody: {
		id: 'ui.general.everybody',
	},
	nobody: {
		id: 'ui.general.nobody',
	},
});



type Props = {
	name: string,
	websiteId: CK.WebsiteId,
	width?: number,
};

const RecipientsMultiselectField: React.FC<Props> = (props) => {
	const {
		name,
		websiteId,
		width,
	} = props;

	const currentUserId = useCurrentUserId();

	const currentUserEmail = useUserEmail(currentUserId);
	const formContext = useFormContext();
	const isAllowedWithUserWebsiteAccessOracle = useIsAllowedWithUserWebsiteAccessOracle();
	const usersWithWebsiteAccess = useUsersWithWebsiteAccess(websiteId);

	const newFormContext = React.useMemo(
		() => {
			const modifiedDefaultValues = { ...formContext.defaultValues };

			if (modifiedDefaultValues[name]) {
				if (modifiedDefaultValues[name] instanceof Array) {
					modifiedDefaultValues[name] = modifiedDefaultValues[name].filter((recipientEmail) => {
						const member = usersWithWebsiteAccess.listAll().find(
							(user) => user.email === recipientEmail,
						);

						if (member === undefined) {
							return false;
						}

						return true;
					});
				} else {
					for (const recipientEmail in modifiedDefaultValues[name]) {
						if (modifiedDefaultValues[name].hasOwnProperty(recipientEmail)) {
							const member = usersWithWebsiteAccess.listAll().find(
								(user) => user.email === recipientEmail,
							);

							if (member === undefined) {
								delete modifiedDefaultValues[name][recipientEmail];
							}
						}
					}
				}
			}

			return {
				...formContext,
				defaultValues: modifiedDefaultValues,
			};
		},
		[
			formContext,
			name,
			usersWithWebsiteAccess,
		],
	);

	const data = React.useMemo(
		() => {
			if (
				currentUserEmail === null
				|| usersWithWebsiteAccess.isLoaded === false
			) {
				return null;
			}

			const eligibleUsers = sortUsers(
				currentUserEmail,
				usersWithWebsiteAccess.listAll(),
			);

			return {
				eligibleUsers,
				options: eligibleUsers.map((user) => {
					return {
						disabled: isAllowedWithUserWebsiteAccessOracle({
							action: GraphQL.ActionWithUserWebsiteAccess.ManageEmailPreferences,
							from: 'website',
							uniqueUserId: user.uniqueId,
							websiteId,
						}).yes === false,
						title: (
							<FullUserName
								email={user.email}
								firstName={user.firstName}
								lastName={user.lastName}
							/>
						),
						name: user.email,
					};
				}),
			};
		},
		[
			currentUserEmail,
			isAllowedWithUserWebsiteAccessOracle,
			usersWithWebsiteAccess,
			websiteId,
		],
	);

	if (data === null) {
		return null;
	}

	return (
		<FormContext.Provider value={newFormContext}>
			<MultiselectField
				dropdownWidth={350}
				isOnlyLinkVisible={true}
				name={name}
				options={data.options}
				scrollableDropdown={true}
				selectedLabelRenderer={({ selectedOptions, defaultSelectedLabelRenderer }) => {
					if (selectedOptions.length === 0) {
						return (
							<FormattedMessage {...messages.nobody} />
						);
					} else if (selectedOptions.length === data.eligibleUsers.length) {
						if (selectedOptions.length === 1) {
							return defaultSelectedLabelRenderer();
						}

						return (
							<FormattedMessage {...messages.everybody} />
						);
					}

					return defaultSelectedLabelRenderer();
				}}
				width={width}
			/>
		</FormContext.Provider>
	);
};



export default RecipientsMultiselectField;
