import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AddAccountHeaderButton from '../buttons/AddAccountHeaderButton';
import BlankSlateMessage from './BlankSlateMessage';
import ConnectedAccountsOverviewTable, {
	ConnectedAccountsOverviewTableColumn,
} from './ConnectedAccountsOverviewTable';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderTitleWithNumericValue from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenMessage from '~/components/patterns/messages/embedded/ScreenMessage';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import ScreenOverlay, {
	ScreenOverlayGapsSize,
	ScreenOverlayStyle,
} from '~/components/patterns/screens/parts/ScreenOverlay';

import useAccountClientConnections from '~/hooks/useAccountClientConnections';
import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountId from '~/hooks/useAccountId';
import useAccountIsPartner from '~/hooks/useAccountIsPartner';
import useAccountPhase from '~/hooks/useAccountPhase';
import useDataSource from '~/hooks/useDataSource';
import useFilteredView from '~/hooks/useFilteredView';
import useNavigate from '~/hooks/useNavigate';
import useUrlState from '~/hooks/useUrlState';

import {
	ConnectedClientsStatus,
	determineConnectionStatus,
} from '~/model/accountAccess';

import sortArrayByProperty from '~/utilities/sortArrayByProperty';



const messages = defineMessages({
	blankSlate: {
		id: 'ui.connectedAccounts.overview.blankSlate',
	},
	featureUnavailable: {
		id: 'ui.connectedAccounts.overview.featureUnavailable',
	},
	title: {
		id: 'ui.connectedAccounts.title',
	},
});



const ConnectedAccountsSection: React.FC = () => {
	const accountId = useAccountId();

	const accountClientConnections = useAccountClientConnections(accountId);
	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);
	const accountIsPartner = useAccountIsPartner(accountId) ?? false;
	const accountPhase = useAccountPhase(accountId);
	const navigate = useNavigate();
	const urlState = useUrlState();

	const {
		filter,
		sortBy,
		listAll,
		listFiltered,
		updateFilter,
		updateSortBy,
	} = useDataSource(
		(filterAndSort) => {
			filterAndSort.addField(ConnectedAccountsOverviewTableColumn.Name, {
				filtering: (connections, name) => {
					return connections.filter(
						(connection) => connection
							.clientAccountName
							.toLowerCase()
							.includes(name.toLowerCase()),
					);
				},
				sorting: (connections) => {
					return sortArrayByProperty(connections, (connection) => connection.clientAccountName);
				},
			});

			filterAndSort.addField(ConnectedAccountsOverviewTableColumn.Status, {
				filtering: (connections, options) => {
					return connections.filter(
						(connection) => options.includes(determineConnectionStatus(connection)),
					);
				},
				sorting: (connections) => {
					return sortArrayByProperty(connections, (connection) => {
						return Object.values(ConnectedClientsStatus).indexOf(
							determineConnectionStatus(connection),
						);
					});
				},
			});

			return {
				defaultFilter: {
					[ConnectedAccountsOverviewTableColumn.Status]: [
						ConnectedClientsStatus.AccessOffered,
						ConnectedClientsStatus.AccessRequested,
						ConnectedClientsStatus.Connected,
					],
				},
				defaultSortBy: {
					key: ConnectedAccountsOverviewTableColumn.Name,
					direction: true,
				},
			};
		},
		[
		],
		accountClientConnections.listAll(),
	);

	const filteredView = useFilteredView({
		ready: accountClientConnections.isLoaded,
		simple: () => false,
	});

	const connectedAccounts = filteredView.isActive
		? listFiltered()
		: listAll();

	const showAccessRightsColumn = accountClientConnections.listAll().some((clientConnection) => {
		return clientConnection.level === GraphQL.AccountAccessConnectionLevel.ReadOnly;
	});

	const onRowClick = React.useCallback(
		({ row }) => {
			if (row.isPending) {
				return false;
			}

			navigate({
				routeName: 'account.websites',
				routeParams: {
					...urlState.params,
					accountId: row.clientAccountId,
				},
			});
		},
		[
			navigate,
			urlState,
		],
	);

	const showBlankSlate = accountClientConnections.count === 0;

	if (accountEnabledFeatures !== null && !accountEnabledFeatures.includes(GraphQL.AccountFeature.ConnectToClient)) {
		return (
			<ScreenMessage indented={true}>
				<Copy
					{...messages.featureUnavailable}
					values={{
						accountPhase,
						linkSupport: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
					}}
				/>
			</ScreenMessage>
		);
	}

	return (
		<ScreenLayout
			contentOverlay={(
				showBlankSlate && (
					<ScreenOverlay
						gapsSize={ScreenOverlayGapsSize.Small}
						style={ScreenOverlayStyle.Light}
					>
						<BlankSlateMessage
							isCertifiedPartner={accountIsPartner}
						/>
					</ScreenOverlay>
				)
			)}
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						<HeaderTitleWithNumericValue
							title={
								<FormattedMessage {...messages.title} />
							}
							value={accountClientConnections.count}
						/>
					</HeaderTitleLayout>

					<HeaderOptionsLayout>
						<HeaderButtonsLayout>
							<AddAccountHeaderButton
								accountId={accountId}
							/>
						</HeaderButtonsLayout>
					</HeaderOptionsLayout>
				</ScreenHeader>
			)}
		>
			<ConnectedAccountsOverviewTable
				accountId={accountId}
				connectedAccounts={connectedAccounts}
				filter={filter}
				isLoading={accountClientConnections.isLoaded === false}
				onFilterCallback={updateFilter}
				onRowClick={onRowClick}
				onSortCallback={updateSortBy}
				showAccessRightsColumn={showAccessRightsColumn}
				sortBy={sortBy}
			/>
		</ScreenLayout>
	);
};



export default ConnectedAccountsSection;
