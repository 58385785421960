import classNames from 'classnames';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import List from '~/components/patterns/lists/List';
import RichText from '~/components/patterns/typography/RichText';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



const messages = defineMessages({
	learnWhyLink: {
		id: 'ui.issueDetail.learnWhyLink',
		defaultMessage: 'Learn why',
	},
});

export type IssueTextLearnWhyProps = {
	handleCloseCallback: (e: React.MouseEvent) => void,
};



type Props = {
	children: React.ReactNode,
	learnWhy?: RenderProp<IssueTextLearnWhyProps> | null,
	subtext?: React.ReactNode,
};

const IssueText: React.FC<Props> = (props) => {
	const {
		children,
		learnWhy,
		subtext,
	} = props;

	const [learnWhyVisible, setLearnWhyVisible] = React.useState(false);

	const toggleLearnWhy = React.useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();

			setLearnWhyVisible(
				(learnWhyVisible) => learnWhyVisible === false,
			);
		},
		[],
	);

	function renderLearnWhyLink() {
		if (!learnWhy || learnWhyVisible) {
			return null;
		}

		return (
			<span className="issue-text__learn-why-link">
				<InternalLink
					onClickCallback={toggleLearnWhy}
					style={InternalLinkStyle.Decent}
				>
					<FormattedMessage {...messages.learnWhyLink} />
				</InternalLink>
			</span>
		);
	}

	return (
		<div className="issue-text">
			<RichText>
				<p>
					<span className="issue-text__text">{children}</span>
					{renderLearnWhyLink()}
				</p>
			</RichText>
			{learnWhy && (
				<div
					className={classNames({
						'issue-text__learn-why': true,
						'issue-text__learn-why--revealed': learnWhyVisible,
					})}
				>
					{renderProp(learnWhy, {
						handleCloseCallback: toggleLearnWhy,
					})}
				</div>
			)}
			{subtext && (
				<div className="issue-text__subtext">
					{Array.isArray(subtext) ? (
						<List>{subtext}</List>
					) : subtext}
				</div>
			)}
		</div>
	);
};



export default IssueText;
