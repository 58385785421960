/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangePasswordMutationVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
  newPassword: GraphQL.Scalars['String']['input'];
  oldPassword: GraphQL.Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { readonly __typename?: 'Mutation', readonly ChangePassword: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly hasPassword: boolean | null, readonly id: string, readonly legacyId: string } | null } } };


export const ChangePasswordDocument = gql`
    mutation ChangePassword($legacyUserId: ID!, $newPassword: String!, $oldPassword: String!) {
  ChangePassword(
    legacyUserId: $legacyUserId
    newPassword: $newPassword
    oldPassword: $oldPassword
  ) {
    query {
      user(legacyId: $legacyUserId) {
        hasPassword
        id
        legacyId
      }
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;