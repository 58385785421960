import React from 'react';
import {
	FormattedMessage,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import Emphasis from '~/components/patterns/typography/Emphasis';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import InternalLink from '~/components/patterns/links/InternalLink';
import Muted from '~/components/patterns/typography/Muted';

import {
	CLIENT_TYPE,
	CONDUCTOR_APP_URL,
	CONTENTKING_APP_URL,
} from '~/config';

import {
	Intercom,
} from '~/globals';

import {
	type DictionaryId,
} from '~/localization/dictionaries';



const br = <br />;
const nbsp = (
	<>
		&nbsp;
	</>
);



function renderBullet(chunks) {
	return (
		<li>{chunks}</li>
	);
}



function renderCode(chunks) {
	return (
		<CodeValue>{chunks}</CodeValue>
	);
}



function renderEm(chunks) {
	return (
		<em>{chunks}</em>
	);
}



function renderEmphasis(chunks) {
	return (
		<Emphasis>{chunks}</Emphasis>
	);
}



function renderList(chunks) {
	return (
		<ul>{chunks}</ul>
	);
}



function renderMuted(chunks) {
	return (
		<Muted>{chunks}</Muted>
	);
}



function renderP(chunks) {
	return (
		<p>{chunks}</p>
	);
}



function renderSmall(chunks) {
	return (
		<small>{chunks}</small>
	);
}



function renderStrong(chunks) {
	return (
		<strong>{chunks}</strong>
	);
}



type Props = {
	defaultMessage?: string,
	id: DictionaryId,
	values?: Record<string, any>,
};

const Copy: React.FC<Props> = (props) => {
	const {
		defaultMessage,
		id,
		values = {},
		...extraStuff
	} = props;

	return (
		<span key={id}>
			<FormattedMessage
				defaultMessage={defaultMessage}
				id={id}
				tagName={React.Fragment}
				values={{
					clientType: CLIENT_TYPE,
					code: renderCode,
					...values,
					...extraStuff,
					br,
					nbsp,
					b: renderStrong,
					emphasis: renderEmphasis,
					i: renderEm,
					li: renderBullet,
					muted: renderMuted,
					p: renderP,
					small: renderSmall,
					showIntercom: showIntercomHere,
					linkConductor: linkConductorHere,
					linkContentKing: linkContentKingHere,
					ul: renderList,
				}}
			/>
		</span>
	);
};



export default React.memo(Copy);

export function formatCopy(intl, message: { id: DictionaryId }, values = {}) {
	values = {
		clientType: CLIENT_TYPE,
		...values,
		br,
		nbsp,
		b: renderStrong,
		emphasis: renderEmphasis,
		code: renderCode,
		i: renderEm,
		li: renderBullet,
		muted: renderMuted,
		p: renderP,
		small: renderSmall,
		ul: renderList,
	};

	return intl.formatMessage(message, values);
}



export function linkExternal(
	href: string,
	props = {},
) {
	return (chunks) => (
		<ExternalLink
			{...props}
			href={href}
		>
			{chunks}
		</ExternalLink>
	);
}

export function linkInternal(
	routeName: string,
	routeParams?: {},
	props = {},
) {
	return (chunks) => (
		<InternalLink
			{...props}
			routeName={routeName}
			routeParams={routeParams}
		>
			{chunks}
		</InternalLink>
	);
}

export function linkConductor() {
	return (chunks) => (
		<ExternalLink
			href={CONDUCTOR_APP_URL}
		>
			{chunks}
		</ExternalLink>
	);
}

export function linkContentKing() {
	return (chunks) => (
		<ExternalLink
			href={CONTENTKING_APP_URL}
		>
			{chunks}
		</ExternalLink>
	);
}

export function showIntercom(props = {}) {
	return (chunks) => (
		<InternalLink
			{...props}
			onClickCallback={() => Intercom()('show')}
		>
			{chunks}
		</InternalLink>
	);
}

function showIntercomHere(chunks) {
	return showIntercom()(chunks);
}

function linkConductorHere(chunks) {
	return linkConductor()(chunks);
}

function linkContentKingHere(chunks) {
	return linkContentKing()(chunks);
}
