import classNames from 'classnames';
import React, {
	Component,
} from 'react';



export enum AttachedIconAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

export enum AttachedIconPosition {
	Prefix = 'prefix',
	Suffix = 'suffix',
}

type Props = {
	/** Alignment of whole content of this component */
	alignment: AttachedIconAlignment,
	/** Possibility to show icon and label in column */
	columnFlow?: boolean,
	children: React.ReactNode,
	ellipsis?: boolean,
	/** Attached icon to children element */
	icon: React.ReactNode,
	/** Decision whether icon will be displayed as prefix of suffix */
	iconPosition?: AttachedIconPosition,
	/** When ellipsis is disabled we can optionally set icon as inline (as part of text) */
	inlineIcon?: boolean,
	/** Handle entering mouse cursor */
	onMouseEnterCallback?: () => void,
	/** Handle leaving mouse cursor */
	onMouseLeaveCallback?: () => void,
	/** Make whole component stretched to the width of available area */
	stretched?: boolean,
};



class AttachedIcon extends Component<Props> {

	static defaultProps = {
		alignment: AttachedIconAlignment.Center,
		ellipsis: true,
		iconPosition: AttachedIconPosition.Prefix,
	};



	constructor(props, context) {
		super(props, context);

		this._handleMouseEnter = this._handleMouseEnter.bind(this);
		this._handleMouseLeave = this._handleMouseLeave.bind(this);
	}



	_handleMouseEnter() {
		const {
			onMouseEnterCallback,
		} = this.props;

		if (!onMouseEnterCallback) {
			return false;
		}

		onMouseEnterCallback();
	}



	_handleMouseLeave() {
		const {
			onMouseLeaveCallback,
		} = this.props;

		if (!onMouseLeaveCallback) {
			return false;
		}

		onMouseLeaveCallback();
	}



	render() {
		const {
			alignment,
			columnFlow,
			children,
			ellipsis,
			icon,
			iconPosition,
			inlineIcon,
			onMouseEnterCallback,
			stretched,
		} = this.props;

		const componentClasses = classNames({
			'attached-icon': true,
			'attached-icon--column-flow': columnFlow,
			'attached-icon--hoverable': onMouseEnterCallback,
			'attached-icon--prefixed-icon': iconPosition === AttachedIconPosition.Prefix,
			'attached-icon--suffixed-icon': iconPosition === AttachedIconPosition.Suffix,
			'attached-icon--ellipsis': ellipsis,
			'attached-icon--inline-icon': !ellipsis && inlineIcon,
			'attached-icon--stretched': stretched,
			['attached-icon--' + alignment + '-aligned']: true,
		});

		const iconElement = (
			<span className="attached-icon__icon">
				{icon}
			</span>
		);

		return (
			<span
				className={componentClasses}
				onMouseEnter={this._handleMouseEnter}
				onMouseLeave={this._handleMouseLeave}
			>
				{iconPosition === AttachedIconPosition.Prefix && !inlineIcon && iconElement}
				<span className="attached-icon__source">
					{iconPosition === AttachedIconPosition.Prefix && inlineIcon && iconElement}
					<span className="attached-icon__source-ellipsis">
						{children}
					</span>
					{iconPosition === AttachedIconPosition.Suffix && inlineIcon && iconElement}
				</span>
				{iconPosition === AttachedIconPosition.Suffix && !inlineIcon && iconElement}
			</span>
		);
	}

}



export default AttachedIcon;
