/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnignorePageIssueCategoryOnSpecificPagesMutationVariables = GraphQL.Exact<{
  issueCategoryName: GraphQL.Scalars['String']['input'];
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueCategoryOnSpecificPagesMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueCategoryOnSpecificPages: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const UnignorePageIssueCategoryOnSpecificPagesDocument = gql`
    mutation UnignorePageIssueCategoryOnSpecificPages($issueCategoryName: String!, $urls: [String!]!, $websiteId: WebsiteId!) {
  UnignorePageIssueCategoryOnSpecificPages(
    issueCategory: $issueCategoryName
    urls: $urls
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePageIssueCategoryOnSpecificPagesMutationFn = Apollo.MutationFunction<UnignorePageIssueCategoryOnSpecificPagesMutation, UnignorePageIssueCategoryOnSpecificPagesMutationVariables>;

/**
 * __useUnignorePageIssueCategoryOnSpecificPagesMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueCategoryOnSpecificPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueCategoryOnSpecificPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueCategoryOnSpecificPagesMutation, { data, loading, error }] = useUnignorePageIssueCategoryOnSpecificPagesMutation({
 *   variables: {
 *      issueCategoryName: // value for 'issueCategoryName'
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueCategoryOnSpecificPagesMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueCategoryOnSpecificPagesMutation, UnignorePageIssueCategoryOnSpecificPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueCategoryOnSpecificPagesMutation, UnignorePageIssueCategoryOnSpecificPagesMutationVariables>(UnignorePageIssueCategoryOnSpecificPagesDocument, options);
      }
export type UnignorePageIssueCategoryOnSpecificPagesMutationHookResult = ReturnType<typeof useUnignorePageIssueCategoryOnSpecificPagesMutation>;
export type UnignorePageIssueCategoryOnSpecificPagesMutationResult = Apollo.MutationResult<UnignorePageIssueCategoryOnSpecificPagesMutation>;
export type UnignorePageIssueCategoryOnSpecificPagesMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueCategoryOnSpecificPagesMutation, UnignorePageIssueCategoryOnSpecificPagesMutationVariables>;