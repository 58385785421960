import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PagesIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-affected-pages">
					<g id="affected-pages">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<polygon
							id="Stroke-3"
							points="5.5 20.0957189 5.5 6 16.7778438 6 16.7778438 20.0957189"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</polygon>
						<path
							d="M7.5,10 L14.5,10 L7.5,10 Z"
							id="Stroke-3-Copy-2"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M7.5,13 L14.5,13 L7.5,13 Z"
							id="Stroke-3-Copy-3"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M7.5,16 L14.5,16 L7.5,16 Z"
							id="Stroke-3-Copy-4"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<polyline
							id="Stroke-3-Copy"
							points="7.5 5.84963845 7.5 4 19.0459848 4 19.0459848 18.4308574 16.9310697 18.4308574"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeOpacity="0.703153306"
						>
						</polyline>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default PagesIconBuilder;

