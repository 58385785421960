import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import BlankValue from '~/components/patterns/values/BlankValue';
import Copy from '~/components/logic/Copy';
import HeaderLabel from '~/components/atoms/dataTables/HeaderLabel';
import Hint from '~/components/patterns/hints/hint/Hint';
import RealtimeValueUpdateHighlight from '~/components/patterns/values/RealtimeValueUpdateHighlight';
import SearchEngineLastVisitFormatter from '~/components/logic/formatters/SearchEngineLastVisitFormatter';
import SearchEngineVisitFrequencyFormatter from '~/components/logic/formatters/SearchEngineVisitFrequencyFormatter';
import SimpleTable from '~/components/patterns/tables/simpleTable/SimpleTable';
import SimpleTableCell, {
	SimpleTableCellAlignment,
	SimpleTableCellType,
} from '~/components/patterns/tables/simpleTable/SimpleTableCell';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';



const messages = defineMessages({
	bingDesktop: {
		id: 'ui.searchEngineActivity.bingDesktop',
	},
	bingMobile: {
		id: 'ui.searchEngineActivity.bingMobile',
	},
	frequency: {
		id: 'ui.searchEngineActivity.frequency',
	},
	googleDesktop: {
		id: 'ui.searchEngineActivity.googleDesktop',
	},
	googleMobile: {
		id: 'ui.searchEngineActivity.googleMobile',
	},
	lastVisited: {
		id: 'ui.searchEngineActivity.lastVisited',
	},
	noData: {
		id: 'ui.values.noData',
	},
	searchEngine: {
		id: 'ui.searchEngineActivity.searchEngine',
	},
});



type LastVisit = CK.Timestamp | null;
type VisitFrequency = number | null;

type Props = {
	hasCompactLabels?: boolean,
	lastVisitOfBingDesktop: LastVisit,
	lastVisitOfBingMobile: LastVisit,
	lastVisitOfGoogleDesktop: LastVisit,
	lastVisitOfGoogleMobile: LastVisit,
	loading?: boolean,
	visitFrequencyOfBingDesktop: VisitFrequency,
	visitFrequencyOfBingMobile: VisitFrequency,
	visitFrequencyOfGoogleDesktop: VisitFrequency,
	visitFrequencyOfGoogleMobile: VisitFrequency,
};

const SearchEngineActivityTable: React.FC<Props> = (props) => {
	const {
		hasCompactLabels = false,
		lastVisitOfBingDesktop,
		lastVisitOfBingMobile,
		lastVisitOfGoogleDesktop,
		lastVisitOfGoogleMobile,
		loading,
		visitFrequencyOfBingDesktop,
		visitFrequencyOfBingMobile,
		visitFrequencyOfGoogleDesktop,
		visitFrequencyOfGoogleMobile,
	} = props;

	function renderLastVisit(value: LastVisit) {
		return (
			<RealtimeValueUpdateHighlight
				disabled={loading}
				value={value}
			>
				{loading ? (
					<SquareSkeleton width={100} />
				) : value !== null ? (
					<SearchEngineLastVisitFormatter value={value} />
				) : (
					<BlankValue>
						<FormattedMessage {...messages.noData} />
					</BlankValue>
				)}
			</RealtimeValueUpdateHighlight>
		);
	}

	function renderVisitFrequency(frequency: VisitFrequency) {
		return (
			<RealtimeValueUpdateHighlight
				disabled={loading}
				value={frequency}
			>
				{loading ? (
					<SquareSkeleton width={50} />
				) : frequency !== null ? (
					<SearchEngineVisitFrequencyFormatter
						unit="per month"
						value={frequency}
					/>
				) : (
					<BlankValue>
						<FormattedMessage {...messages.noData} />
					</BlankValue>
				)}
			</RealtimeValueUpdateHighlight>
		);
	}

	const googleDesktopIcons = (
		<>
			<BasicIcon type={BasicIconType.Google} />
			<BasicIcon type={BasicIconType.Desktop} />
		</>
	);

	const googleMobileIcons = (
		<>
			<BasicIcon type={BasicIconType.Google} />
			<BasicIcon type={BasicIconType.Mobile} />
		</>
	);

	const bingDesktopIcons = (
		<>
			<BasicIcon type={BasicIconType.Bing} />
			<BasicIcon type={BasicIconType.Desktop} />
		</>
	);

	const bingMobileIcons = (
		<>
			<BasicIcon type={BasicIconType.Bing} />
			<BasicIcon type={BasicIconType.Mobile} />
		</>
	);

	return (
		<SimpleTable
			header={[
				<SimpleTableCell
					key="searchEngine"
					type={SimpleTableCellType.Header}
				>
					{!hasCompactLabels && (
						<HeaderLabel>
							<FormattedMessage {...messages.searchEngine} />
						</HeaderLabel>
					)}
				</SimpleTableCell>,
				<SimpleTableCell
					alignment={SimpleTableCellAlignment.Right}
					key="frequency"
					type={SimpleTableCellType.Header}
					width={140}
				>
					<HeaderLabel>
						<FormattedMessage {...messages.frequency} />
					</HeaderLabel>
				</SimpleTableCell>,
				<SimpleTableCell
					key="last-visited"
					type={SimpleTableCellType.Header}
				>
					<HeaderLabel>
						<FormattedMessage {...messages.lastVisited} />
					</HeaderLabel>
				</SimpleTableCell>,
			]}
			rows={
				[
					[
						<SimpleTableCell key="google-desktop">
							{hasCompactLabels ? (
								<Hint
									popup={(
										<Copy {...messages.googleDesktop} />
									)}
								>
									{googleDesktopIcons}
								</Hint>
							) : (
								<AttachedIcon icon={googleDesktopIcons}>
									<Copy {...messages.googleDesktop} />
								</AttachedIcon>
							)}
						</SimpleTableCell>,
						<SimpleTableCell
							alignment={SimpleTableCellAlignment.Right}
							key="google-desktop-visit-frequency"
							width={140}
						>
							{renderVisitFrequency(visitFrequencyOfGoogleDesktop)}
						</SimpleTableCell>,
						<SimpleTableCell key="google-desktop-lastVisited">
							{renderLastVisit(lastVisitOfGoogleDesktop)}
						</SimpleTableCell>,
					],
					[
						<SimpleTableCell key="google-desktop">
							{hasCompactLabels ? (
								<Hint
									popup={(
										<Copy {...messages.googleMobile} />
									)}
								>
									{googleMobileIcons}
								</Hint>
							) : (
								<AttachedIcon icon={googleMobileIcons}>
									<Copy {...messages.googleMobile} />
								</AttachedIcon>
							)}
						</SimpleTableCell>,
						<SimpleTableCell
							alignment={SimpleTableCellAlignment.Right}
							key="google-mobile-visit-frequency"
							width={140}
						>
							{renderVisitFrequency(visitFrequencyOfGoogleMobile)}
						</SimpleTableCell>,
						<SimpleTableCell key="google-mobile-lastVisited">
							{renderLastVisit(lastVisitOfGoogleMobile)}
						</SimpleTableCell>,
					],
					[
						<SimpleTableCell key="bing-desktop">
							{hasCompactLabels ? (
								<Hint
									popup={(
										<Copy {...messages.bingDesktop} />
									)}
								>
									{bingDesktopIcons}
								</Hint>
							) : (
								<AttachedIcon icon={bingDesktopIcons}>
									<Copy {...messages.bingDesktop} />
								</AttachedIcon>
							)}
						</SimpleTableCell>,
						<SimpleTableCell
							alignment={SimpleTableCellAlignment.Right}
							key="bing-desktop-visit-frequency"
							width={140}
						>
							{renderVisitFrequency(visitFrequencyOfBingDesktop)}
						</SimpleTableCell>,
						<SimpleTableCell key="bing-desktop-lastVisited">
							{renderLastVisit(lastVisitOfBingDesktop)}
						</SimpleTableCell>,
					],
					[
						<SimpleTableCell key="bing-desktop">
							{hasCompactLabels ? (
								<Hint
									popup={(
										<Copy {...messages.bingMobile} />
									)}
								>
									{bingMobileIcons}
								</Hint>
							) : (
								<AttachedIcon icon={bingMobileIcons}>
									<Copy {...messages.bingMobile} />
								</AttachedIcon>
							)}
						</SimpleTableCell>,
						<SimpleTableCell
							alignment={SimpleTableCellAlignment.Right}
							key="bing-mobile-visit-frequency"
							width={140}
						>
							{renderVisitFrequency(visitFrequencyOfBingMobile)}
						</SimpleTableCell>,
						<SimpleTableCell key="bing-mobile-lastVisited">
							{renderLastVisit(lastVisitOfBingMobile)}
						</SimpleTableCell>,
					],
				]}
		/>
	);
};



export default SearchEngineActivityTable;
