import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	List,
	Map,
} from 'immutable';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import CancelButton from '~/components/app/CancelButton';
import ExclusionRulesDataTable from '../../dataTables/ExclusionRulesDataTable';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import ListElement from '~/components/patterns/lists/List';
import Measurer from '~/utilities/Measurer';
import ModalContentSection from '../parts/ModalContentSection';
import ModalTextSection from '../parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';

import {
	loadVirtualRobotsRules,
	saveVirtualRobotsRules,
} from '~/actions/websites';
import {
	virtualRobotsRulesSelector,
} from '~/state/teams/selectors';



const messages = defineMessages({
	applyChanges: {
		id: 'ui.general.applyChanges',
	},
	cancel: {
		id: 'ui.general.cancelButton',
	},
	content: {
		id: 'ui.websites.exclusionsModal.manager.description',
	},
	propagationTimeWarning: {
		id: 'ui.websites.exclusionsModal.manager.propagationTimeWarning',
	},
});



const select = createSelector(
	virtualRobotsRulesSelector,
	(virtualRobots) => {
		return {
			virtualRobots,
		};
	},
);



class Manager extends Component {

	constructor(props, context) {
		super(props, context);

		const {
			virtualRobots,
		} = props;

		this._handleFormSubmit = this._handleFormSubmit.bind(this);

		const rules = virtualRobots;

		this.state = {
			canSubmit: false,
			isLoading: !rules,
			isSubmitting: false,
			rules,
		};
	}



	componentDidMount() {
		const {
			dispatch,
			rulesFromImporter,
			website,
		} = this.props;

		if (!rulesFromImporter) {
			dispatch(
				loadVirtualRobotsRules(
					website.get('team_id'),
					website.get('id'),
				),
			);
		}
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			rulesFromImporter: prevRulesFromImporter,
			virtualRobots: prevVirtualRobots,
		} = this.props;
		const {
			rulesFromImporter: nextRulesFromImporter,
			virtualRobots: nextVirtualRobots,
		} = nextProps;

		if (!prevVirtualRobots && nextVirtualRobots) {
			this.setState({
				isLoading: false,
				rules: nextVirtualRobots,
			});
		}

		if (!prevRulesFromImporter && nextRulesFromImporter) {
			this.setState({
				canSubmit: true,
				rules: List(nextRulesFromImporter.sort().map((rule) => {
					return Map({
						rule,
						deleted: false,
						unsaved: false,
					});
				})),
			});
		}
	}



	_handleCancelLinkClick(e) {
		e.preventDefault();

		const {
			closeModal,
		} = this.props;

		closeModal();
	}



	_handleApplyChangesButtonClick() {
		const {
			closeModal,
			dispatch,
			website,
		} = this.props;

		const {
			rules,
		} = this.state;

		this.setState({
			isSubmitting: true,
		});

		dispatch(
			saveVirtualRobotsRules(
				website.get('team_id'),
				website.get('id'),
				rules.filter((rule) => rule.get('deleted') === false).map((rule) => rule.get('rule')),
			),
		).then(() => {
			this.setState({
				isSubmitting: false,
			});

			closeModal();
		}).catch(() => {
			this.setState({
				isSubmitting: false,
			});
		});
	}



	_handleFormSubmit(model) {
		const rules = this.state.rules.push(new Map({
			rule: model.rule,
			deleted: false,
			unsaved: true,
		}));

		this.setState({
			canSubmit: true,
			rules,
		});
	}



	_toggleRule(index) {
		const rules = this.state.rules.updateIn(
			[
				index,
				'deleted',
			],
			(deleted) => {
				return !deleted;
			},
		);

		this.setState({
			canSubmit: true,
			rules,
		});
	}



	render() {
		const {
			closeModal,
		} = this.props;

		const {
			canSubmit,
			isLoading,
			isSubmitting,
			rules,
		} = this.state;

		return (
			<>
				<ModalTextSection>
					<FormattedMessage {...messages.content} />
				</ModalTextSection>

				<ModalContentSection enforceReadableTextLength={true}>
					<ListElement>
						<Measurer>
							{({ containerWidth }) => (
								<ExclusionRulesDataTable
									allowDeletion={true}
									formSubmitCallback={this._handleFormSubmit}
									isLoading={isLoading}
									key={'table-' + containerWidth}
									onToggleRule={(index) => this._toggleRule(index)}
									rules={rules}
									tableWidth={containerWidth - 2}
								/>
							)}
						</Measurer>
						{canSubmit && (
							<RichText>
								<p>
									<FormattedMessage {...messages.propagationTimeWarning} />
								</p>
							</RichText>
						)}
					</ListElement>

					<ButtonsLayout layout={ButtonsLayoutType.Steps}>
						<CancelButton onClickCallback={closeModal} />

						<Button
							disabled={!canSubmit}
							onClick={() => this._handleApplyChangesButtonClick()}
							progress={isSubmitting}
							style={ButtonStyle.Action}
							width={ButtonWidth.SubmitButton}
						>
							<FormattedMessage {...messages.applyChanges} />
						</Button>
					</ButtonsLayout>
				</ModalContentSection>
			</>
		);
	}

}

Manager.propTypes = {
	closeModal: PropTypes.func.isRequired,
	rulesFromImporter: PropTypes.array,
	website: PropTypes.object.isRequired,
};



export default connect(select)(Manager);
