import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DropdownIconBuilder: IconBuilder = {
	icon: ({ color = '#4383CC' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					fill={color}
					fillRule="nonzero"
					id="dropdown-arrow"
					transform="translate(0.000000, 2.000000)"
				>
					<path
						d="M1.4314583,0.2590714 C1.1064583,-0.0863571 0.5797917,-0.0863571 0.255625,0.2590714 C-0.0810417,0.6173571 -0.0810417,1.1770714 0.243125,1.5225 L4.0547917,5.5776429 C4.580625,6.1373571 5.4189583,6.1373571 5.935625,5.5879286 L9.756458,1.5225 C10.080625,1.1770714 10.080625,0.6173571 9.756458,0.2719286 C9.419792,-0.0863571 8.893125,-0.0863571 8.568958,0.2590714 L4.9997917,4.0562143 L1.4314583,0.2590714 L1.4314583,0.2590714 Z"
						id="arrow-drop-down"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 10,
	viewBoxWidth: 10,
};



export default DropdownIconBuilder;
