import React from 'react';



type Props = {
	children?: React.ReactNode,
	requestCloseOverlay: () => void,
};



const ContextMenuOverlay: React.FC<Props> = (props) => {
	const {
		children,
		requestCloseOverlay,
	} = props;

	return (
		<div
			className="context-menu-overlay"
			onClick={requestCloseOverlay}
			onContextMenu={requestCloseOverlay}
			onMouseDown={requestCloseOverlay}
		>
			{children}
		</div>
	);
};



export default ContextMenuOverlay;
