import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import ContentChangeDetails from './types/ContentChangeDetails';
import PageBecameNonIndexableDetails from './types/PageBecameNonIndexableDetails';
import PageBecameNonPageDetails from './types/PageBecameNonPageDetails';
import PageEnteredOrLeftSegmentDetails from './types/PageEnteredOrLeftSegmentDetails';
import PageIssueOpenedDetails from './types/PageIssueOpenedDetails';
import PagePropertyChangedDetails from './types/PagePropertyChangedDetails';
import PlatformIssueOpenedDetails from './types/PlatformIssueOpenedDetails';
import RobotsTxtBecameNonIndexableDetails from './types/RobotsTxtBecameNonIndexableDetails';
import RobotsTxtChangedDetails from './types/RobotsTxtChangedDetails';
import SslCertificateBecameInvalid from './types/SslCertificateBecameInvalid';
import SslCertificateExpiringSoonDetails from './types/SslCertificateExpiringSoonDetails';
import WebsiteHasTroubleResponding from './types/WebsiteHasTroubleResponding';

import {
	ALERT_CANONICAL_LINK_CHANGED,
	ALERT_ENRICHMENT_FIELD_CHANGED,
	ALERT_HREFLANGS_CHANGED,
	ALERT_PAGE_BECAME_NON_INDEXABLE,
	ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
	ALERT_PAGE_TYPE_CHANGED,
	ALERT_PLATFORM_ISSUE_OPENED_HTTPS_CERTIFICATE_INVALID,
	ALERT_PLATFORM_ISSUE_ROBOTS_TXT_BECAME_INACCESSIBLE,
	ALERT_PROPERTY_CHANGED,
	ALERT_ROBOTS_TXT_CHANGED,
	ALERT_SSL_CERTIFICATE_EXPIRING_SOON,
	ALERT_WEBSITE_HAS_TROUBLE_RESPONDING,
} from '~/model/alerts';



class IncidentDetails extends Component {

	render() {
		const {
			incident,
			websiteId,
		} = this.props;

		if (
			incident.get('type').indexOf('content_change') === 0
			|| incident.get('type') === ALERT_CANONICAL_LINK_CHANGED
			|| incident.get('type') === ALERT_HREFLANGS_CHANGED
		) {
			return (
				<ContentChangeDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT) {
			return (
				<PageEnteredOrLeftSegmentDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_PAGE_TYPE_CHANGED) {
			return (
				<PageBecameNonPageDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_PAGE_BECAME_NON_INDEXABLE) {
			return (
				<PageBecameNonIndexableDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_ROBOTS_TXT_CHANGED) {
			return (
				<RobotsTxtChangedDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_SSL_CERTIFICATE_EXPIRING_SOON) {
			return (
				<SslCertificateExpiringSoonDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_PLATFORM_ISSUE_ROBOTS_TXT_BECAME_INACCESSIBLE) {
			return (
				<RobotsTxtBecameNonIndexableDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_PLATFORM_ISSUE_OPENED_HTTPS_CERTIFICATE_INVALID) {
			return (
				<SslCertificateBecameInvalid
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_WEBSITE_HAS_TROUBLE_RESPONDING) {
			return (
				<WebsiteHasTroubleResponding
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_ENRICHMENT_FIELD_CHANGED) {
			return (
				<PagePropertyChangedDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type') === ALERT_PROPERTY_CHANGED) {
			return (
				<PagePropertyChangedDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type').indexOf('issue_opened') === 0) {
			return (
				<PageIssueOpenedDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		} else if (incident.get('type').indexOf('platform_issue_opened') === 0) {
			return (
				<PlatformIssueOpenedDetails
					incident={incident}
					websiteId={websiteId}
				/>
			);
		}

		return false;
	}

}

IncidentDetails.propTypes = {
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string.isRequired,
};



export default IncidentDetails;
