import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MessagingAppIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g>
				<path
					d="M2.5,4.8c0-0.7,0.6-1.4,1.4-1.4h16.3c0.7,0,1.4,0.6,1.4,1.4v10.8c0,0.7-0.6,1.4-1.4,1.4H12L7.3,21V17H3.9 c-0.7,0-1.4-0.6-1.4-1.4L2.5,4.8z"
					fill="none"
					stroke={color}
					strokeLinecap="square"
					strokeWidth="1.2981"
				/>
				<path
					d="M12.7,14.6h-1.5V13h1.5V14.6z M12.7,12h-1.5V5.5h1.5V12z"
					fill={color}
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MessagingAppIconBuilder;

