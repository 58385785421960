import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const BodySnapshotsIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M7.29453 21.0674H2.31953C2.0079 21.0674 1.70904 20.9436 1.48868 20.7233C1.26833 20.5029 1.14453 20.2041 1.14453 19.8924V2.65742C1.14453 2.3458 1.26833 2.04693 1.48868 1.82657C1.70904 1.60622 2.0079 1.48242 2.31953 1.48242H22.6945C22.8489 1.48209 23.0019 1.51226 23.1446 1.57119C23.2873 1.63013 23.4169 1.71666 23.5261 1.82584C23.6353 1.93501 23.7218 2.06468 23.7808 2.20738C23.8397 2.35009 23.8699 2.50303 23.8695 2.65742V9.45743"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M1.24805 5.69507H23.613"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M28.9926 15.1748H11.1301C10.1014 15.1748 9.26758 16.0087 9.26758 17.0373V28.1948C9.26758 29.2234 10.1014 30.0573 11.1301 30.0573H28.9926C30.0212 30.0573 30.8551 29.2234 30.8551 28.1948V17.0373C30.8551 16.0087 30.0212 15.1748 28.9926 15.1748Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.75 15.1749L15.3775 12.6749C15.4573 12.3721 15.6354 12.1045 15.8838 11.914C16.1323 11.7235 16.4369 11.6209 16.75 11.6224H23.3725C23.6882 11.62 23.9955 11.7237 24.2453 11.9168C24.495 12.1099 24.6728 12.3812 24.75 12.6874L25.38 15.1874"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M20.0601 27.9298C22.9955 27.9298 25.3751 25.5502 25.3751 22.6148C25.3751 19.6794 22.9955 17.2998 20.0601 17.2998C17.1247 17.2998 14.7451 19.6794 14.7451 22.6148C14.7451 25.5502 17.1247 27.9298 20.0601 27.9298Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M9.26758 19.3274H15.7626"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M24.3604 19.3274H30.8554"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M20.06 19.75C19.2993 19.7493 18.5694 20.0507 18.0308 20.588C17.4921 21.1252 17.1888 21.8543 17.1875 22.615"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M20.4779 9.05249H4.42285"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M11.9179 12.8049H4.42285"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M6.84535 16.5549H4.42285"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default BodySnapshotsIconBuilder;

