import classNames from 'classnames';
import React from 'react';

import {
	EditableFormWrapperContext,
} from '~/components/atoms/forms/basis/EditableFormWrapperBase';

import useFormContext from '~/hooks/useFormContext';



export enum StaticTextAlignment {
	Center = 'center',
	Left = 'flex-start',
	Right = 'flex-end',
}



type Props = {
	alignment?: StaticTextAlignment,
	children?: React.ReactNode,
	ellipsis?: boolean,
	focusTarget?: string,
	width?: React.CSSProperties['width'],
};



const StaticText: React.FC<Props> = (props) => {
	const {
		alignment = StaticTextAlignment.Left,
		children,
		ellipsis = false,
		focusTarget = null,
		width = 280,
	} = props;

	const editableFormWrapperContext = React.useContext(EditableFormWrapperContext);
	const formContext = useFormContext() as ReturnType<typeof useFormContext> | null;

	const handleTextClick = React.useCallback(
		(e) => {
			if (editableFormWrapperContext === null) {
				return false;
			}

			const {
				editMode,
				openEditModeHandler,
			} = editableFormWrapperContext;

			// prevent calling of openEditModeHandler when edit mode enabled
			if (editMode) {
				return false;
			}

			e.preventDefault();
			e.stopPropagation();

			if (window.getSelection()?.toString().length === 0) {
				openEditModeHandler(focusTarget);
			}
		},
		[
			editableFormWrapperContext,
			focusTarget,
		],
	);

	const isEditable = editableFormWrapperContext?.isEditable ?? false;

	const style: React.CSSProperties = {};
	const contentStyle: React.CSSProperties = {};
	const textStyle: React.CSSProperties = {
		justifyContent: alignment,
	};

	if (width) {
		style.width = width;
		contentStyle.maxWidth = width;
	}

	const componentClasses = classNames({
		'form-static-text': true,
		'form-static-text--clickable': isEditable && (!formContext || formContext.isDisabled === false),
		'form-static-text--ellipsis': ellipsis,
	});

	return (
		<div
			className={componentClasses}
			style={style}
		>
			<div
				className="form-static-text__content"
				onClick={isEditable ? handleTextClick : undefined}
				style={contentStyle}
			>
				<span
					className="form-static-text__text"
					style={textStyle}
				>
					{children}
				</span>
			</div>
		</div>
	);
};



export default StaticText;
