import memoize from 'memoizee';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import Emphasis from '~/components/patterns/typography/Emphasis';
import FilterDefinitionFormatter, {
	FilterDefinitionFormatterStyle,
} from '~/components/logic/filters/FilterDefinitionFormatter';
import MultiselectTableField from '~/components/logic/formFields/MultiselectTableField';
import SegmentDefinitionIdentifier, {
	SegmentDefinitionIdentifierSize,
} from '~/components/logic/segments/SegmentDefinitionIdentifier';
import SegmentsList from '~/components/atoms/segments/SegmentsList';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';
import UpcomingValueUpdateHighlight from '~/components/patterns/values/UpcomingValueUpdateHighlight';
import WebsiteIdProvider from '~/components/providers/WebsiteIdProvider';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	type SegmentDefinition,
	SegmentsComparisonVerdict,
	compareSegments,
} from '~/model/segments';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	actionsAlreadyExistsLabel: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.alreadyExists.label',
	},
	actionsCannotBeImportedLabel: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.label',
	},
	actionsCannotBeImportedTooltipsConflictWithManaged: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.conflictWithManaged',
	},
	actionsCannotBeImportedTooltipsDependsOnOtherSegment: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.dependsOnOtherSegment',
	},
	actionsCannotBeImportedTooltipsDualConflict: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.dualConflict',
	},
	actionsCannotBeImportedTooltipsIsStatic: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.isStatic',
	},
	actionsCannotBeImportedTooltipsMissingCustomElement: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.missingCustomElement',
	},
	actionsWillBeImportedLabel: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.willBeImported.label',
	},
	actionsWillBeRenamedLabel: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.willBeRenamed.label',
	},
	actionsWillBeRenamedTooltip: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.willBeRenamed.tooltip',
	},
	actionsWillBeReplacedLabel: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.willBeReplaced.label',
	},
	actionsWillBeReplacedTooltip: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.willBeReplaced.tooltip',
	},
	actionsWillNotBeImportedLabel: {
		id: 'ui.segments.importModal.steps.chooseSegments.actions.willNotBeImported.label',
	},
	columnsSegment: {
		id: 'ui.segments.importModal.steps.chooseSegments.columns.segment',
	},
	columnsStatus: {
		id: 'ui.segments.importModal.steps.chooseSegments.columns.status',
	},
	tableSummary: {
		id: 'ui.segments.importModal.steps.chooseSegments.tableSummary',
	},
});



const memoizedCompareSegments = memoize(compareSegments);

const sortSegments = memoize((
	sourceSegmentDefinitions: ReadonlyArray<SegmentDefinition>,
	targetSegmentDefinitions: ReadonlyArray<SegmentDefinition>,
	targetCustomElements,
) => {
	const segmentsComparison = memoizedCompareSegments(
		sourceSegmentDefinitions,
		targetSegmentDefinitions,
		targetCustomElements,
		[],
	);

	return [...sourceSegmentDefinitions].sort((segmentA, segmentB) => {
		const verdictA = segmentsComparison(segmentA.name).verdict;
		const verdictB = segmentsComparison(segmentB.name).verdict;

		const isANotImportable = (
			verdictA === SegmentsComparisonVerdict.IsStatic
			|| verdictA === SegmentsComparisonVerdict.PresentIdentical
			|| verdictA === SegmentsComparisonVerdict.PresentInDualConflict
			|| verdictA === SegmentsComparisonVerdict.PresentManaged
			|| verdictA === SegmentsComparisonVerdict.DependsOnCustomElement
		);

		const isBNotImportable = (
			verdictB === SegmentsComparisonVerdict.IsStatic
			|| verdictB === SegmentsComparisonVerdict.PresentIdentical
			|| verdictB === SegmentsComparisonVerdict.PresentInDualConflict
			|| verdictB === SegmentsComparisonVerdict.PresentManaged
			|| verdictB === SegmentsComparisonVerdict.DependsOnCustomElement
		);

		if (isANotImportable && !isBNotImportable) {
			return 1;
		} else if (!isANotImportable && isBNotImportable) {
			return -1;
		}

		return sourceSegmentDefinitions.indexOf(segmentA) < sourceSegmentDefinitions.indexOf(segmentB) ? -1 : 1;
	});
});



type Props = {
	height?: number,
	name: string,
	sourceWebsiteId: CK.WebsiteId,
	targetWebsiteId: CK.WebsiteId,
};

const ChooseImportedSegmentsTableField: React.FC<Props> = (props) => {
	const {
		height,
		name,
		sourceWebsiteId,
		targetWebsiteId,
	} = props;

	const sourceSegmentDefinitions = useWebsiteSegmentDefinitions(sourceWebsiteId);
	const targetCustomElements = useWebsiteCustomElementDefinitions(targetWebsiteId);
	const targetSegmentDefinitions = useWebsiteSegmentDefinitions(targetWebsiteId);
	const websiteDisplayName = useWebsiteDisplayName(sourceWebsiteId);

	const getColumnWidth = React.useCallback(
		({ index, width }) => {
			const firstColumnWidth = Math.floor(width * 1 / 3);

			switch (index) {

				case 0:
					return firstColumnWidth;

				case 1:
					return width - firstColumnWidth;

			}
		},
		[],
	);

	const getValueFromItem = React.useCallback(
		(item) => {
			return item.name;
		},
		[],
	);

	const isValueDisabled = React.useCallback(
		({ checkedItems, item }) => {
			const segmentsComparison = memoizedCompareSegments(
				sourceSegmentDefinitions.listAll(),
				targetSegmentDefinitions.listAll(),
				targetCustomElements,
				checkedItems,
			);

			const itemSegmentsComparison = segmentsComparison(item.name);

			return (
				itemSegmentsComparison.verdict === SegmentsComparisonVerdict.DependsOnCustomElement
				|| itemSegmentsComparison.verdict === SegmentsComparisonVerdict.DependsOnOtherSegments
				|| itemSegmentsComparison.verdict === SegmentsComparisonVerdict.IsStatic
				|| itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentManaged
				|| itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentIdentical
				|| itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentInDualConflict
			);
		},
		[
			sourceSegmentDefinitions,
			targetSegmentDefinitions,
			targetCustomElements,
		],
	);

	const renderDisabledRowExplanation = React.useCallback(
		({ checkedItems, item }) => {
			const segmentsComparison = memoizedCompareSegments(
				sourceSegmentDefinitions.listAll(),
				targetSegmentDefinitions.listAll(),
				targetCustomElements,
				checkedItems,
			);

			const itemSegmentsComparison = segmentsComparison(item.name);

			if (itemSegmentsComparison.verdict === SegmentsComparisonVerdict.IsStatic) {
				return (
					<FormattedMessage {...messages.actionsCannotBeImportedTooltipsIsStatic} />
				);
			}

			if (itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentIdentical) {
				return (
					<FormattedMessage {...messages.actionsAlreadyExistsLabel} />
				);
			}

			if (itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentManaged) {
				return (
					<FormattedMessage
						{...messages.actionsCannotBeImportedTooltipsConflictWithManaged}
						values={{
							targetSegmentIdentifier: (
								<WebsiteIdProvider websiteId={targetWebsiteId}>
									<SegmentDefinitionIdentifier
										segmentDefinition={itemSegmentsComparison.similarSegment}
										showCriteria={false}
										size={SegmentDefinitionIdentifierSize.Small}
									/>
								</WebsiteIdProvider>
							),
						}}
					/>
				);
			}

			if (itemSegmentsComparison.verdict === SegmentsComparisonVerdict.DependsOnCustomElement) {
				return (
					<FormattedMessage {...messages.actionsCannotBeImportedTooltipsMissingCustomElement} />
				);
			}

			if (itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentInDualConflict) {
				return (
					<FormattedMessage
						{...messages.actionsCannotBeImportedTooltipsDualConflict}
						values={{
							segmentA: (
								<Spacer
									bottom={SpacerGap.XSmall}
									top={SpacerGap.XSmall}
								>
									<WebsiteIdProvider websiteId={targetWebsiteId}>
										<SegmentDefinitionIdentifier
											segmentDefinition={getArrayItemAtSafeIndex(itemSegmentsComparison.similarSegments, 0)}
											showCriteria={false}
											size={SegmentDefinitionIdentifierSize.Small}
										/>
									</WebsiteIdProvider>
								</Spacer>
							),
							segmentB: (
								<Spacer
									bottom={SpacerGap.XSmall}
									top={SpacerGap.XSmall}
								>
									<WebsiteIdProvider websiteId={targetWebsiteId}>
										<SegmentDefinitionIdentifier
											segmentDefinition={getArrayItemAtSafeIndex(itemSegmentsComparison.similarSegments, 1)}
											showCriteria={false}
											size={SegmentDefinitionIdentifierSize.Small}
										/>
									</WebsiteIdProvider>
								</Spacer>
							),
						}}
					/>
				);
			}

			if (itemSegmentsComparison.verdict === SegmentsComparisonVerdict.DependsOnOtherSegments) {
				return (
					<FormattedMessage
						{...messages.actionsCannotBeImportedTooltipsDependsOnOtherSegment}
						values={{
							dependencies: (
								<Spacer
									bottom={SpacerGap.XSmall}
									top={SpacerGap.XSmall}
								>
									<WebsiteIdProvider websiteId={sourceWebsiteId}>
										<SegmentsList inline={true}>
											{itemSegmentsComparison.neededSegments.map((segment) => (
												<SegmentDefinitionIdentifier
													key={segment.name}
													segmentDefinition={segment}
													showCriteria={false}
													size={SegmentDefinitionIdentifierSize.Small}
												/>
											))}
										</SegmentsList>
									</WebsiteIdProvider>
								</Spacer>
							),
						}}
					/>
				);
			}

			return null;
		},
		[
			sourceSegmentDefinitions,
			sourceWebsiteId,
			targetCustomElements,
			targetSegmentDefinitions,
			targetWebsiteId,
		],
	);

	const renderCell = React.useCallback(
		({ checkedItems, columnIndex, isChecked, item }) => {
			switch (columnIndex) {

				case 0:
					return (
						<div>
							<WebsiteIdProvider websiteId={sourceWebsiteId}>
								<SegmentDefinitionIdentifier
									segmentDefinition={item}
									showEditLink={false}
								/>
							</WebsiteIdProvider>
						</div>
					);

				case 1:
					const segmentsComparison = memoizedCompareSegments(
						sourceSegmentDefinitions.listAll(),
						targetSegmentDefinitions.listAll(),
						targetCustomElements,
						checkedItems,
					);

					const itemSegmentsComparison = segmentsComparison(item.name);

					let content = (
						<FormattedMessage {...messages.actionsWillNotBeImportedLabel} />
					);
					let explanatoryTooltip: React.ReactNode = null;
					let isUpdateCritical = false;
					let updateDescription;

					if (itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentIdentical) {
						content = (
							<Emphasis>
								<FormattedMessage {...messages.actionsAlreadyExistsLabel} />
							</Emphasis>
						);
					} else if (
						itemSegmentsComparison.verdict === SegmentsComparisonVerdict.DependsOnCustomElement
						|| itemSegmentsComparison.verdict === SegmentsComparisonVerdict.DependsOnOtherSegments
						|| itemSegmentsComparison.verdict === SegmentsComparisonVerdict.IsStatic
						|| itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentInDualConflict
						|| itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentManaged
					) {
						content = (
							<FormattedMessage {...messages.actionsCannotBeImportedLabel} />
						);
						explanatoryTooltip = renderDisabledRowExplanation({
							checkedItems,
							item,
						});
					}

					if (isChecked) {
						if (itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentWithSameCriteria) {
							isUpdateCritical = true;
							explanatoryTooltip = (
								<FormattedMessage
									{...messages.actionsWillBeRenamedTooltip}
									values={{
										targetSegmentIdentifier: (
											<WebsiteIdProvider websiteId={targetWebsiteId}>
												<SegmentDefinitionIdentifier
													segmentDefinition={itemSegmentsComparison.similarSegment}
													showCriteria={false}
													size={SegmentDefinitionIdentifierSize.Small}
												/>
											</WebsiteIdProvider>
										),
									}}
								/>
							);
							updateDescription = (
								<FormattedMessage {...messages.actionsWillBeRenamedLabel} />
							);
						} else if (itemSegmentsComparison.verdict === SegmentsComparisonVerdict.PresentWithSameIdentifier) {
							isUpdateCritical = true;
							explanatoryTooltip = (
								<FormattedMessage
									{...messages.actionsWillBeReplacedTooltip}
									values={{
										sourceSegmentCriteria: (
											<Spacer
												bottom={SpacerGap.XSmall}
												top={SpacerGap.XSmall}
											>
												<WebsiteIdProvider websiteId={sourceWebsiteId}>
													<FilterDefinitionFormatter
														filterDefinition={item.filterDefinition}
														sizeLimitDefinition={item.sizeLimit}
														style={FilterDefinitionFormatterStyle.CompactList}
													/>
												</WebsiteIdProvider>
											</Spacer>
										),
										targetSegmentCriteria: (
											<Spacer
												bottom={SpacerGap.XSmall}
												top={SpacerGap.XSmall}
											>
												<WebsiteIdProvider websiteId={targetWebsiteId}>
													<FilterDefinitionFormatter
														filterDefinition={itemSegmentsComparison.similarSegment.filterDefinition}
														sizeLimitDefinition={itemSegmentsComparison.similarSegment.sizeLimit}
														style={FilterDefinitionFormatterStyle.CompactList}
													/>
												</WebsiteIdProvider>
											</Spacer>
										),
									}}
								/>
							);
							updateDescription = (
								<FormattedMessage {...messages.actionsWillBeReplacedLabel} />
							);
						} else {
							updateDescription = (
								<FormattedMessage {...messages.actionsWillBeImportedLabel} />
							);
						}
					}

					return (
						<UpcomingValueUpdateHighlight
							critical={isUpdateCritical}
							explanatoryTooltip={explanatoryTooltip}
							updateDescription={updateDescription}
						>
							{content}
						</UpcomingValueUpdateHighlight>
					);

			}

			return null;
		},
		[
			renderDisabledRowExplanation,
			sourceSegmentDefinitions,
			sourceWebsiteId,
			targetCustomElements,
			targetSegmentDefinitions,
			targetWebsiteId,
		],
	);

	const renderFooterLabel = React.useCallback(
		({ selectedData }) => {
			if (selectedData.length === 0) {
				return ' ';
			}

			return (
				<Copy
					{...messages.tableSummary}
					values={{
						count: selectedData.length,
						websiteName: websiteDisplayName,
					}}
				/>
			);
		},
		[
			websiteDisplayName,
		],
	);

	const renderHeader = React.useCallback(
		({ columnIndex }) => {
			switch (columnIndex) {

				case 0:
					return (
						<FormattedMessage {...messages.columnsSegment} />
					);

				case 1:
					return (
						<FormattedMessage {...messages.columnsStatus} />
					);

			}

			return null;
		},
		[],
	);

	if (sourceSegmentDefinitions.isLoaded === false) {
		return null;
	}

	return (
		<MultiselectTableField
			cellRenderer={renderCell}
			columnCount={2}
			columnWidth={getColumnWidth}
			disabledGetter={isValueDisabled}
			disabledRowExplanationRenderer={renderDisabledRowExplanation}
			footerLabelRenderer={renderFooterLabel}
			headerRenderer={renderHeader}
			items={sortSegments(
				sourceSegmentDefinitions.listAll(),
				targetSegmentDefinitions.listAll(),
				targetCustomElements,
			)}
			name={name}
			tableHeight={height}
			valueGetter={getValueFromItem}
		/>
	);
};



export default ChooseImportedSegmentsTableField;
