import React from 'react';

import RedirectToHomepage from '~/components/atoms/RedirectToHomepage';



const HomePage: React.FC = () => {
	return (
		<RedirectToHomepage />
	);
};



export default HomePage;
