import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import CK from '~/types/contentking';

import AffectedPagesTable from '~/components/atoms/dataTables/AffectedPagesTable';
import Header from '~/components/logic/affectedPages/Header';
import IsWebsiteCrawledContext from '~/components/app/IsWebsiteCrawledContext';
import MultiselectFieldFilter from '~/components/logic/datatables/MultiselectFieldFilter';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';

import useProjectAffectedPagesUrl from '~/hooks/useProjectAffectedPagesUrl';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	loadAffectedPages,
	updateFilter,
	updateSortBy,
} from '~/actions/affectedPages';

import goTo from '~/routing/goTo';
import {
	getRouter,
} from '~/routing/router';

import {
	pagesSelector,
	rangeSelector,
	totalSelector,
} from '~/state/affectedPages/currentWebsiteSelectors';

import {
	currentFilterSelector,
	loadingSelector,
	sortBySelector,
} from '~/state/affectedPages/selectors';

import {
	issuesOverviewCategorySelector,
} from '~/state/ui/content/selectors';

import {
	copyUrlToClipboardItem,
	openPageDetailItem,
	openPageOnWebsiteItem,
} from '~/model/contextMenu';

import {
	IssueCategoryName,
	PageIssue,
} from '~/model/issuesNew';

import {
	getCategoryUrlIdentifier,
} from '~/model/issues/identifierMapping';



const messages = defineMessages({
	titleXmlSitemapStatusIncorrectlyMissing: {
		id: 'ui.affectedPages.specific.xmlSitemap.status.incorrectlyMissing',
	},
	titleXmlSitemapStatusIncorrectlyPresent: {
		id: 'ui.affectedPages.specific.xmlSitemap.status.incorrectlyPresent',
	},
});



type Props = {
	backlink: any,
	closeCallback: () => void,
};

const AffectedPagesPanel: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
	} = props;

	const websiteId = useWebsiteId();

	const affectedPages = useSelector(pagesSelector);
	const dispatch = useDispatch();
	const filter = useSelector(currentFilterSelector);
	const issueCategory = useSelector(issuesOverviewCategorySelector);
	const loading = useSelector(loadingSelector);
	const range = useSelector(rangeSelector);
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const sortBy = useSelector(sortBySelector);
	const total = useSelector(totalSelector);

	useProjectAffectedPagesUrl();

	const loadPages = React.useCallback(
		(offset) => {
			return dispatch(
				loadAffectedPages(offset, 300),
			);
		},
		[
			dispatch,
		],
	);

	React.useEffect(
		() => {
			loadPages(0);
		},
		[
			issueCategory,
			loadPages,
		],
	);

	const getColumns = React.useCallback(
		() => {
			let columns;

			switch (issueCategory) {

				case IssueCategoryName.CanonicalLink: {
					columns = [
						{
							filterable: false,
							issues: [
								PageIssue.CanonicalLinkIncorrectlyCanonicalized,
								PageIssue.CanonicalLinkMissing,
								PageIssue.CanonicalLinkPointsToUnindexable,
								PageIssue.CanonicalLinkTooMany,
							],
							name: CK.PagesCommonColumn.CanonicalLinkElement,
							sortable: false,
							width: 400,
						},
					];

					break;
				}

				case IssueCategoryName.H1: {
					columns = [
						{
							filterable: true,
							issues: [
								PageIssue.H1Duplicate,
								PageIssue.H1IncorrectLength,
								PageIssue.H1LevelsSkipped,
								PageIssue.H1Missing,
								PageIssue.H1TooMany,
							],
							name: CK.PagesCommonColumn.H1,
							sortable: true,
							width: 400,
						},
					];

					break;
				}

				case IssueCategoryName.Hreflang: {
					columns = [];

					break;
				}

				case IssueCategoryName.Images: {
					columns = [
						{
							filterable: false,
							issues: [],
							name: CK.PagesCommonColumn.NumberOfImagesWithMissingAlt,
							sortable: true,
							width: 300,
							defaultSortingDirection: false,
						},
						{
							filterable: false,
							issues: [],
							name: CK.PagesCommonColumn.NumberOfImagesWithMixedTransport,
							sortable: true,
							width: 300,
							defaultSortingDirection: false,
						},
					];

					break;
				}

				case IssueCategoryName.Lighthouse: {
					columns = [];

					break;
				}

				case IssueCategoryName.Links: {
					columns = [
						{
							filterable: false,
							issues: [],
							name: CK.PagesCommonColumn.NumberOfLinksToBrokenPages,
							sortable: true,
							width: 300,
							defaultSortingDirection: false,
						},
						{
							filterable: false,
							issues: [],
							name: CK.PagesCommonColumn.NumberOfLinksToRedirectedPages,
							sortable: true,
							width: 300,
							defaultSortingDirection: false,
						},
						{
							filterable: false,
							issues: [],
							name: CK.PagesCommonColumn.NumberOfLinksToCanonicalizedPages,
							sortable: true,
							width: 300,
							defaultSortingDirection: false,
						},
					];

					break;
				}

				case IssueCategoryName.MetaDescription: {
					columns = [
						{
							filterable: true,
							issues: [
								PageIssue.MetaDescriptionDuplicate,
								PageIssue.MetaDescriptionIncorrectLength,
								PageIssue.MetaDescriptionMissing,
								PageIssue.MetaDescriptionTooMany,
							],
							name: CK.PagesCommonColumn.MetaDescription,
							sortable: true,
							width: 400,
						},
					];

					break;
				}

				case IssueCategoryName.OpenGraph: {
					columns = [
						{
							filterable: true,
							issues: [
								PageIssue.OpenGraphTitleIncorrectLength,
							],
							name: CK.PagesCommonColumn.OpenGraphTitle,
							sortable: true,
							width: 400,
						},
						{
							filterable: true,
							issues: [],
							name: CK.PagesCommonColumn.OpenGraphUrl,
							sortable: true,
							width: 400,
						},
						{
							filterable: true,
							issues: [
								PageIssue.OpenGraphDescriptionIncorrectLength,
							],
							name: CK.PagesCommonColumn.OpenGraphDescription,
							sortable: true,
							width: 300,
						},
						{
							filterable: true,
							issues: [],
							name: CK.PagesCommonColumn.OpenGraphImage,
							sortable: true,
							width: 300,
						},
					];

					break;
				}

				case IssueCategoryName.RobotDirectives: {
					columns = [];

					break;
				}

				case IssueCategoryName.SchemaOrg: {
					columns = [];

					break;
				}

				case IssueCategoryName.Title: {
					columns = [
						{
							filterable: true,
							issues: [
								PageIssue.TitleDuplicate,
								PageIssue.TitleIncorrectLength,
								PageIssue.TitleMissing,
								PageIssue.TitleTooMany,
							],
							name: CK.PagesCommonColumn.Title,
							sortable: true,
							width: 400,
						},
					];

					break;
				}

				case IssueCategoryName.Analytics: {
					columns = [
						{
							filterable: true,
							issues: [],
							name: CK.PagesCommonColumn.AnalyticsServices,
							sortable: true,
							width: 300,
						},
						{
							filterable: true,
							issues: [],
							name: CK.PagesCommonColumn.VisualAnalyticsServices,
							sortable: true,
							width: 300,
						},
					];

					break;
				}

				case IssueCategoryName.TwitterCards: {
					columns = [
						{
							filterable: true,
							issues: [],
							name: CK.PagesCommonColumn.TwitterCard,
							sortable: true,
							width: 300,
						},
						{
							filterable: true,
							issues: [
								PageIssue.TwitterCardsDescriptionIncorrectLength,
							],
							name: CK.PagesCommonColumn.TwitterTitle,
							sortable: true,
							width: 400,
						},
						{
							filterable: true,
							issues: [],
							name: CK.PagesCommonColumn.TwitterSite,
							sortable: true,
							width: 300,
						},
						{
							filterable: true,
							issues: [],
							name: CK.PagesCommonColumn.TwitterImage,
							sortable: true,
							width: 300,
						},
						{
							filterable: true,
							issues: [
								PageIssue.TwitterCardsTitleIncorrectLength,
							],
							name: CK.PagesCommonColumn.TwitterDescription,
							sortable: true,
							width: 300,
						},
					];

					break;
				}

				case IssueCategoryName.XmlSitemap: {
					columns = [
						{
							cellRenderer: ({ openIssues }) => {
								return openIssues.some((issue) => issue.get('issue') === PageIssue.XmlSitemapIncorrectlyMissing)
									? <FormattedMessage {...messages.titleXmlSitemapStatusIncorrectlyMissing} />
									: <FormattedMessage {...messages.titleXmlSitemapStatusIncorrectlyPresent} />;
							},
							filterable: true,
							filterRenderer: ({ name, width }) => {
								return (
									<MultiselectFieldFilter
										isOnlyLinkVisible={true}
										name={name}
										options={[
											{
												name: 'incorrectly_missing',
												title: (
													<FormattedMessage {...messages.titleXmlSitemapStatusIncorrectlyMissing} />
												),
											},
											{
												name: 'incorrectly_present',
												title: (
													<FormattedMessage {...messages.titleXmlSitemapStatusIncorrectlyPresent} />
												),
											},
										]}
										width={width}
									/>
								);
							},
							issues: [],
							name: CK.PagesCommonColumn.XmlSitemapStatus,
							sortable: true,
							width: 250,
						},
					];

					break;
				}

			}

			return columns;
		},
		[
			issueCategory,
		],
	);

	const handleOpenDetail = React.useCallback(
		({ row }, event) => {
			const legacyUrlId = row.get('id');

			if (!legacyUrlId) {
				return;
			}

			goTo(
				event,
				'website.pages.detail.issuesCategory',
				{
					id: legacyUrlId,
					pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategory),
					websiteId,
				},
			);
		},
		[
			issueCategory,
			websiteId,
		],
	);

	const contextMenuEntries = React.useCallback(
		({ row }) => {
			const pageDetailUrl = getRouter().buildPath('website.pages.detail.issuesCategory', {
				id: row.get('id'),
				pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategory),
				websiteId,
			});

			return [
				openPageDetailItem(pageDetailUrl),
				openPageOnWebsiteItem(row.get('url')),
				copyUrlToClipboardItem(row.get('url')),
			];
		},
		[
			issueCategory,
			websiteId,
		],
	);

	const handleFilterChange = React.useCallback(
		(filter) => {
			getColumns().forEach((column) => {
				if (filter[column.name] && column.filterType === 'multiselect') {
					if (filter[column.name].length === column.filterConfiguration.defaultValues.length) {
						filter[column.name] = undefined;
					}
				}
			});

			dispatch(
				updateFilter(filter),
			);
		},
		[
			dispatch,
			getColumns,
		],
	);

	const handleSortingChange = React.useCallback(
		({ key, direction }) => {
			const alreadySelected = sortBy.get('key') === key;

			dispatch(
				updateSortBy(
					{
						key,
						direction: alreadySelected
							? !sortBy.get('direction')
							: direction,
					},
				),
			);
		},
		[
			dispatch,
			sortBy,
		],
	);

	if (!issueCategory) {
		return null;
	}

	return (
		<ScreenLayout
			header={(
				<Header
					backlinkCallback={closeCallback}
					backlinkLabel={backlink}
					issueCategoryName={issueCategory}
					numberOfAffectedPages={total}
				/>
			)}
		>
			<IsWebsiteCrawledContext>
				{({ isCrawled }) => (
					<AffectedPagesTable
						affectedPages={affectedPages}
						columns={getColumns()}
						contextMenuEntries={contextMenuEntries}
						dataLoading={loading}
						filter={filter}
						filterCallback={handleFilterChange}
						isCrawled={isCrawled}
						onRowClick={handleOpenDetail}
						pagesCount={total || 0}
						pagesIndexes={range}
						pagesLoader={loadPages}
						segmentDefinitions={segmentDefinitions.listAll()}
						sortBy={sortBy}
						sortCallback={handleSortingChange}
					/>
				)}
			</IsWebsiteCrawledContext>
		</ScreenLayout>
	);
};



export default AffectedPagesPanel;
