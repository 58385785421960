import React from 'react';

import SelectField from '~/components/atoms/forms/components/SelectField';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';



type Props = {
	name: string,
};

const CustomElementSelectField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);

	const options = customElementDefinitions
		.listAll()
		.map((customElementDefinition) => ({
			label: customElementDefinition.label,
			name: customElementDefinition.column,
		}));

	return (
		<SelectField
			isDisabled={options.length === 0}
			name={name}
			options={options}
		/>
	);
};



export default React.memo(CustomElementSelectField);
