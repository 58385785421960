import React from 'react';

import AlertStatusCellValue from '../../datatables/cellValues/AlertStatusCellValue';
import MultiselectFieldFilter from '../../datatables/MultiselectFieldFilter';

import {
	LIST_OF_TERTIARY_STATUSES,
} from '~/model/alertPages';



type Props = {
	name: string,
	states: Array<string>,
	width: number,
};

const IssueStatusFilter = React.forwardRef<any, Props>((props, ref) => {
	const {
		name,
		states,
		width,
	} = props;

	const primaryStates = states.filter((state) => !LIST_OF_TERTIARY_STATUSES.includes(state));

	return (
		<MultiselectFieldFilter
			isOnlyLinkVisible={true}
			name={name}
			options={states.map((state) => {
				return {
					name: state,
					title: (
						<AlertStatusCellValue value={state} />
					),
				};
			})}
			ref={ref}
			visibleOptionsCount={primaryStates.length}
			width={width}
		/>
	);
});



export default IssueStatusFilter;
