import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import IgnorePageCases from '../issues/components/ignoringCases/IgnorePageCases';
import IgnoreOnPage from '../issues/components/ignoringCases/IgnoreOnPage';
import IgnoreOnSegments from '../issues/components/ignoringCases/IgnoreOnSegments';
import IgnoreOnWebsite from '../issues/components/ignoringCases/IgnoreOnWebsite';
import IssueTitle from '~/components/logic/issues/IssueTitle';
import ModalLayout from '~/components/logic/issuesIgnoring/ModalLayout';

import useGetIssue from '~/hooks/useGetIssue';
import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import useOpenedPageIssueCategory from '~/hooks/useOpenedPageIssueCategory';
import usePageUrl from '~/hooks/usePageUrl';
import useReloadPageIssueCategories from '~/hooks/useReloadPageIssueCategories';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	IgnoringScope,
	PAGE_ISSUES_WITH_CASES,
} from '~/model/issuesNew';

import {
	pageDetailIgnoringScopeSelector,
	pageDetailIssueSelector,
} from '~/state/ui/content/selectors';



const navigationMessages = defineMessages({
	images: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.images',
	},
	links: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.links',
	},
	page: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.page',
	},
	segments: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.segments',
	},
	website: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.website',
	},
});



const IgnorePageIssueModal: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const issueCategory = useOpenedPageIssueCategory(legacyUrlId, websiteId);
	const issueName = useSelector(pageDetailIssueSelector);
	const pageUrl = usePageUrl(legacyUrlId, websiteId);
	const reloadPageIssueCategories = useReloadPageIssueCategories(legacyUrlId, websiteId);
	const scope = useSelector(pageDetailIgnoringScopeSelector);
	const segments = useWebsiteSegmentDefinitions(websiteId);

	const issue = useGetIssue(
		issueCategory,
		issueName,
	);

	const issueCategoryName = issueCategory?.name ?? null;

	const scopes = React.useMemo(
		() => {
			if (issue === null || issueCategoryName === null) {
				return [];
			}

			const hasCases = PAGE_ISSUES_WITH_CASES.includes(issue.name);

			const scopes: Array<{
				badge: number | false,
				content: React.ReactNode,
				isNotReady: boolean,
				label: React.ReactNode,
				name: string,
			}> = [];

			scopes.push({
				badge: false,
				content: (
					<IgnoreOnWebsite
						isIgnored={issue.ignoringRuleOnWebsite.isActive}
						isPlatformIssue={false}
						issueName={issue.name}
						reloadCallback={reloadPageIssueCategories}
						websiteId={websiteId}
					/>
				),
				isNotReady: false,
				label: (
					<FormattedMessage {...navigationMessages.website} />
				),
				name: IgnoringScope.Website,
			});

			scopes.push({
				badge: issue.ignoringRuleOnSegments.ignoredSegments.length,
				content: (
					<IgnoreOnSegments
						issue={issue}
						reloadCallback={reloadPageIssueCategories}
						websiteId={websiteId}
					/>
				),
				isNotReady: issue.ignoringRuleOnWebsite.isEffective || segments.count === 0,
				label: (
					<FormattedMessage {...navigationMessages.segments} />
				),
				name: IgnoringScope.Segments,
			});

			scopes.push({
				badge: false,
				content: (
					<IgnoreOnPage
						isIgnoredOnPage={issue.ignoringRuleOnPage.isActive}
						isIgnoredOnWebsite={issue.ignoringRuleOnWebsite.isActive}
						issueCategoryName={issueCategoryName}
						issueName={issue.name}
						numberOfIssues={1}
						reloadCallback={reloadPageIssueCategories}
						url={pageUrl}
						websiteId={websiteId}
					/>
				),
				isNotReady: issue.ignoringRuleOnWebsite.isEffective || issue.ignoringRuleOnSegments.isEffective,
				label: (
					<FormattedMessage {...navigationMessages.page} />
				),
				name: IgnoringScope.Page,
			});

			if (hasCases) {
				scopes.push({
					badge: issue.ignoringRuleOnCases.ignoredCases.length,
					content: (
						<IgnorePageCases
							issue={issue}
							legacyUrlId={legacyUrlId}
							reloadCallback={reloadPageIssueCategories}
							websiteId={websiteId}
						/>
					),
					isNotReady: issue.ignoringRuleOnWebsite.isEffective || issue.ignoringRuleOnSegments.isEffective || issue.ignoringRuleOnPage.isEffective,
					label: (
						<FormattedMessage {...navigationMessages[issueCategoryName === 'images' ? 'images' : 'links']} />
					),
					name: IgnoringScope.Cases,
				});
			}

			return scopes;
		},
		[
			issue,
			issueCategoryName,
			legacyUrlId,
			pageUrl,
			reloadPageIssueCategories,
			segments,
			websiteId,
		],
	);

	return (
		<ModalLayout
			activeScope={scope}
			isLoading={issue === null}
			scopes={scopes}
			title={issue !== null && (
				<IssueTitle
					issue={issue}
				/>
			)}
		/>
	);
};



export default IgnorePageIssueModal;
