import classNames from 'classnames';
import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';



export enum FormWrapperHeaderStyle {
	Default = 'default',
	EditMode = 'edit-mode',
}

type Props = {
	/** Possibility to attach call-to-action element */
	ctaElement?: React.ReactNode,
	/** Enable special background style for admins */
	isForAdmins?: boolean,
	/** Title text */
	title: React.ReactNode,
	style?: FormWrapperHeaderStyle,
	/** Secondary title */
	subtitle?: React.ReactNode,
};



const FormWrapperHeader: React.FC<Props> = (props) => {
	const {
		ctaElement,
		isForAdmins = false,
		style = FormWrapperHeaderStyle.Default,
		subtitle,
		title,
	} = props;

	const componentClasses = classNames({
		'form-wrapper-header': true,
		'form-wrapper-header--for-admins': isForAdmins,
		'form-wrapper-header--has-subtitle': subtitle,
		'form-wrapper-header--style-default': style === FormWrapperHeaderStyle.Default,
		'form-wrapper-header--style-edit-mode': style === FormWrapperHeaderStyle.EditMode,
	});

	return (
		<div className={componentClasses}>
			<div className="form-wrapper-header__title">
				{isForAdmins && (
					<div className="form-wrapper-header__icon">
						<BasicIcon
							color="#263340"
							size={32}
							type={BasicIconType.Lock}
						/>
					</div>
				)}
				{title}
			</div>

			{subtitle && (
				<div className="form-wrapper-header__subtitle">
					{subtitle}
				</div>
			)}

			{ctaElement && (
				<div className="form-wrapper-header__cta-element">
					{ctaElement}
				</div>
			)}
		</div>
	);
};



export default FormWrapperHeader;
