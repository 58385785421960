import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import classNames from 'classnames';



type ChildType = React.ReactElement;

type Props = {
	/** Calendar component */
	children: [ChildType, ChildType] | ChildType,
	/** Possible overlay visible above calendar component */
	overlay?: React.ReactNode,
	/** Preset for calendar in form of navigation */
	presets?: React.ReactNode,
	/** Possibility to show seleted range above calendar */
	range?: React.ReactNode,
};



const CalendarPickerLayout: React.FC<Props> = (props) => {
	const {
		children,
		overlay,
		presets,
		range,
	} = props;

	const childrenCount = flattenChildren(children).length;

	return (
		<div
			className={classNames({
				'calendar-picker': true,
				['calendar-picker--' + childrenCount + '-children']: true,
			})}
		>
			<div className="calendar-picker__content">
				{range && (
					<div className="calendar-picker__range">
						{range}
					</div>
				)}
				<div className="calendar-picker__calendars">
					{flattenChildren(children).map((child, index) => {
						return (
							<div
								className="calendar-picker__calendar"
								key={'calendar-' + index}
							>
								{child}
							</div>
						);
					})}
				</div>
				{overlay && (
					<div className="calendar-picker__overlay">
						{overlay}
					</div>
				)}
			</div>
			{presets && (
				<div className="calendar-picker__sidebar">
					{presets}
				</div>
			)}
		</div>
	);
};



export default CalendarPickerLayout;
