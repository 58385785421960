import React from 'react';

import WebsiteAdminFetchingSettingNumericFieldRow, {
	WebsiteAdminFetchingSettingNumericFieldRowUnit,
} from '~/components/app/WebsiteAdminFetchingSettingNumericFieldRow';



type Props = {
	name: string,
};

const WebsiteAdminFetchingSettingOnPageRequestsLimitNormalPerDomainFieldRow: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	return (
		<WebsiteAdminFetchingSettingNumericFieldRow
			autoValue={100}
			label="Max. on-page requests in per domain"
			name={name}
			unit={WebsiteAdminFetchingSettingNumericFieldRowUnit.None}
		/>
	);
};



export default WebsiteAdminFetchingSettingOnPageRequestsLimitNormalPerDomainFieldRow;
