import React from 'react';

import AbstractCheckboxField, {
	AbstractCheckboxFieldCheckedState,
	AbstractCheckboxFieldSize,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import AttachedOptionsPanel from './parts/AttachedOptionsPanel.part';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import CheckboxLabel from '~/components/patterns/forms/fieldParts/checkboxes/CheckboxLabel';
import FieldDropdownDivider from '~/components/patterns/forms/fieldParts/dropdowns/parts/FieldDropdownDivider.part';
import FieldDropdownOption from '~/components/patterns/forms/fieldParts/dropdowns/parts/FieldDropdownOption.part';
import FieldDropdownOptionsGroup from '~/components/patterns/forms/fieldParts/dropdowns/parts/FieldDropdownOptionsGroup.part';
import FieldDropdownSelectableOptions from './FieldDropdownSelectableOptions';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';

import touchSupported from '~/utilities/touchSupported';

import {
	notEmpty,
} from '~/utilities/typeCheck';



function getSelectedOptionsCount(options) {
	if (!options || options.length === 0) {
		return 0;
	}

	return options.filter((option) => {
		return option.selected === true;
	}).length;
}

function areSomeOptionsHidden(visibleOptionsCount: number, options: FieldDropdownMultiselectableOptionsOptions) {
	const lastSelectedOptionIndex = [...options].reverse().findIndex((item) => {
		if ('children' in item) {
			const selectedChildrenCount = getSelectedOptionsCount(item.children);

			return selectedChildrenCount > 0;
		}

		return 'selected' in item && item.selected;
	});

	if (lastSelectedOptionIndex === -1) {
		return false;
	}

	return (
		lastSelectedOptionIndex < visibleOptionsCount
		|| options.length > visibleOptionsCount
	);
}

type FieldDropdownMultiselectableOptionsChildrenOptionProps = {
	description?: React.ReactNode,
	divider?: never,
	name: string,
	selected?: boolean | AbstractCheckboxFieldCheckedState.Indeterminate,
	suffix?: React.ReactNode,
	title: React.ReactNode,
};

type FieldDropdownMultiselectableOptionsChildrenDividerProps = {
	divider: boolean,
};

type FieldDropdownMultiselectableOptionsChildrenProps = FieldDropdownMultiselectableOptionsChildrenOptionProps | FieldDropdownMultiselectableOptionsChildrenDividerProps;

type FieldDropdownMultiselectableOptionsOptionProps = {
	description?: React.ReactNode,
	disabled?: boolean,
	divider?: never,
	children?: ReadonlyArray<FieldDropdownMultiselectableOptionsChildrenProps>,
	innerPanelWidth?: number,
	name: string,
	onlyOneChildSelectable?: boolean,
	selected?: boolean | AbstractCheckboxFieldCheckedState.Indeterminate,
	suffix?: React.ReactNode,
	title: React.ReactNode,
	visibleChildrenCount?: number,
};

type FieldDropdownMultiselectableOptionsDividerProps = {
	divider: boolean,
};

type FieldDropdownMultiselectableOptionsOptions = ReadonlyArray<FieldDropdownMultiselectableOptionsOptionProps | FieldDropdownMultiselectableOptionsDividerProps>;

type FieldDropdownMultiselectableOptionsOptionChangeCallbackInput = {
	optionName: string,
	children: ReadonlyArray<FieldDropdownMultiselectableOptionsChildrenProps> | undefined,
	toggleMode: boolean,
	defaultSelectedChild: string | null,
};

type FieldDropdownMultiselectableOptionsOptionClickCallbackInput = {
	optionName: string,
	children: ReadonlyArray<FieldDropdownMultiselectableOptionsChildrenProps> | undefined,
	toggleMode: boolean,
	defaultSelectedChild: string | null,
	event: React.MouseEvent,
};



type Props = {
	/** Allow to hide inner options for multiselect with deeper structure of options */
	hideInnerOptions?: boolean,
	/** Possibility to set width for inner panels (when available) */
	innerPanelWidth?: number,
	/** Field name */
	name: string,
	/** Show 'only' link */
	isOnlyLinkVisible?: boolean,
	/** Options data */
	options: FieldDropdownMultiselectableOptionsOptions,
	/** Label for "Only" link visible when isOnlyLinkVisible set */
	onlyLinkLabel?: React.ReactNode,
	/** Callback triggered on 'only' link click */
	onOnlyLinkClickCallback?: (
		optionName: string,
	) => void,
	/** Callback triggered on option change */
	onOptionChangeCallback?: (input: FieldDropdownMultiselectableOptionsOptionChangeCallbackInput) => void,
	/** Callback triggered on option click */
	onOptionClickCallback?: (input: FieldDropdownMultiselectableOptionsOptionClickCallbackInput) => void,
	/** Callback triggered on component mouse enter */
	onOptionMouseEnterCallback?: (name: string) => void,
	/** Callback triggered on component mouse leave */
	onOptionMouseLeaveCallback?: (name: string) => void,
	/** Label for "Show more" link visible when visibleOptionsCount set */
	showMoreLinkLabel?: React.ReactNode,
	/** Possibility to limit amount of visible options */
	visibleOptionsCount?: number,
};

const FieldDropdownMultiselectableOptions: React.FC<Props> = (props) => {
	const {
		hideInnerOptions = false,
		innerPanelWidth,
		isOnlyLinkVisible = false,
		name,
		onlyLinkLabel,
		onOnlyLinkClickCallback = null,
		onOptionClickCallback = null,
		onOptionChangeCallback = null,
		onOptionMouseEnterCallback = null,
		onOptionMouseLeaveCallback = null,
		options,
		showMoreLinkLabel,
		visibleOptionsCount = null,
	} = props;

	const [revealedPanelName, setRevealedPanelName] = React.useState<string | null>(null);
	const [showAllOptions, setShowAllOptions] = React.useState<boolean>(
		() => visibleOptionsCount !== null
			? areSomeOptionsHidden(visibleOptionsCount, options) === false
			: false,
	);

	const handleShowAllOptions = React.useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();

			// Delay unmounting of the 'show more' link to prevent the dropdown being
			// closed because the click target (the link) doesn't exist in the dropdown
			// element anymore.
			setTimeout(() => {
				setShowAllOptions(true);
			});
		},
		[],
	);

	const revealInnerPanel = React.useCallback(
		(panelName) => {
			// manually revealed panel (with inner level of options)
			setRevealedPanelName(panelName);
		},
		[],
	);

	const hideRevealedInnerPanel = React.useCallback(
		() => {
			setRevealedPanelName(null);
		},
		[],
	);

	const handleOptionChange = (
		optionName: string,
		children: ReadonlyArray<FieldDropdownMultiselectableOptionsChildrenProps> | undefined,
		selectFirstChildOnly: boolean,
	) => {
		if (touchSupported) {
			hideRevealedInnerPanel();
		}

		if (onOptionChangeCallback !== null) {
			optionName = optionName.substring(name.length + 1);

			let defaultSelectedChild: string | null = null;

			if (selectFirstChildOnly && children !== undefined) {
				defaultSelectedChild = children
					.map((child) => 'name' in child ? child.name : null)
					.filter(notEmpty)[0] ?? null;
			}

			onOptionChangeCallback({
				optionName,
				children,
				toggleMode: true,
				defaultSelectedChild,
			});
		}
	};

	const handleOptionClick = (
		event: React.MouseEvent,
		optionName: string,
		children: ReadonlyArray<FieldDropdownMultiselectableOptionsChildrenProps> | undefined,
		selectFirstChildOnly: boolean,
	) => {
		if (touchSupported) {
			hideRevealedInnerPanel();
		}

		if (onOptionClickCallback !== null) {
			optionName = optionName.substring(name.length + 1);

			let defaultSelectedChild: string | null = null;

			if (selectFirstChildOnly && children !== undefined) {
				defaultSelectedChild = children
					.map((child) => 'name' in child ? child.name : null)
					.filter(notEmpty)[0] ?? null;
			}

			onOptionClickCallback({
				optionName,
				children,
				toggleMode: true,
				defaultSelectedChild,
				event,
			});
		}
	};

	const handleOnlyLinkClick = React.useCallback(
		(event: React.MouseEvent, optionName: string) => {
			event.stopPropagation();
			event.preventDefault();

			optionName = optionName.substring(name.length + 1);

			if (onOnlyLinkClickCallback !== null) {
				onOnlyLinkClickCallback(optionName);
			}
		},
		[
			name,
			onOnlyLinkClickCallback,
		],
	);

	const handleVariantChange = (
		optionName: string,
		children: ReadonlyArray<FieldDropdownMultiselectableOptionsChildrenProps>,
		newVariant: string,
	) => {
		if (touchSupported) {
			hideRevealedInnerPanel();
		}

		optionName = optionName.substring(name.length + 1);

		if (onOptionChangeCallback) {
			onOptionChangeCallback({
				optionName,
				children,
				toggleMode: false,
				defaultSelectedChild: newVariant,
			});
		}
	};

	const handleOptionMouseEnter = (name) => {
		if (onOptionMouseEnterCallback) {
			onOptionMouseEnterCallback(name);
		}
	};

	const handleOptionMouseLeave = (name) => {
		if (onOptionMouseLeaveCallback) {
			onOptionMouseLeaveCallback(name);
		}
	};

	const renderVariants = (
		options: ReadonlyArray<FieldDropdownMultiselectableOptionsChildrenProps>,
		name: string,
		visibleOptionsCount: number | undefined,
	) => {
		return (
			<FieldDropdownSelectableOptions
				onOptionChangeCallback={(value) => handleVariantChange(name, options, value)}
				options={options.map((item) => {
					if ('divider' in item) {
						return {
							divider: true,
						};
					}

					if (item.selected === AbstractCheckboxFieldCheckedState.Indeterminate) {
						throw new Error(
							"Variant can't have indeterminate state",
						);
					}

					return {
						selected: item.selected,
						label: item.title,
						name: item.name,
					};
				})}
				showMoreLinkLabel={showMoreLinkLabel}
				visibleOptionsCount={visibleOptionsCount}
				width={innerPanelWidth}
			/>
		);
	};

	const renderOptions = (
		options: FieldDropdownMultiselectableOptionsOptions,
		namePrefix: string,
		level: number,
		inPanelView: boolean,
	) => {
		let data = options;

		const isOutputLimited = (
			visibleOptionsCount !== null
			&& level === 1
			&& data.length > visibleOptionsCount
			&& showAllOptions === false
		);

		if (isOutputLimited) {
			data = data.slice(0, visibleOptionsCount);
		}

		const structure = data.map((option, i) => {
			if ('divider' in option) {
				return (
					<FieldDropdownDivider key={level + '-divider-' + i} />
				);
			}

			const optionName = name + '/' + namePrefix + option.name;

			let children: React.ReactNode;
			let isChecked = false;
			let deselectFeature = false;
			let areChildrenInPanel = false;
			let isChildrenPanelRevealed = false;

			let selectedChildrenCount;

			if ('children' in option && option.children !== undefined) {
				if (option.onlyOneChildSelectable) {
					selectedChildrenCount = getSelectedOptionsCount(option.children);

					if (selectedChildrenCount > 0) {
						children = renderVariants(option.children, optionName, option.visibleChildrenCount);
						isChecked = true;
						areChildrenInPanel = true;
						isChildrenPanelRevealed = areChildrenInPanel;
					}
				} else {
					areChildrenInPanel = hideInnerOptions;
					children = renderOptions(option.children, option.name + '_', level + 1, areChildrenInPanel);
					selectedChildrenCount = getSelectedOptionsCount(option.children);
					isChildrenPanelRevealed = areChildrenInPanel && revealedPanelName ? revealedPanelName === option.name : false;

					if (selectedChildrenCount === option.children.length) {
						isChecked = true;
					} else if (selectedChildrenCount > 0) {
						isChecked = true;
						deselectFeature = true;
					}
				}
			} else if (option.selected === AbstractCheckboxFieldCheckedState.Indeterminate) {
				deselectFeature = true;
			} else {
				isChecked = option.selected ?? false;
			}

			let checkedState = AbstractCheckboxFieldCheckedState.NotChecked;

			if (deselectFeature) {
				checkedState = AbstractCheckboxFieldCheckedState.Indeterminate;
			} else if (isChecked) {
				checkedState = AbstractCheckboxFieldCheckedState.Checked;
			}

			let field = (
				<AbstractCheckboxField
					checkedState={checkedState}
					compact={true}
					description={option.description}
					isControlled={true}
					isDisabled={!!option.disabled}
					label={(
						<CheckboxLabel
							ctaIcon={(hideInnerOptions || option.onlyOneChildSelectable) && option.children && option.children.length > 0 && (
								<BasicIcon
									color="#5893eb"
									size={18}
									type={BasicIconType.ArrowRight}
								/>
							)}
							ctaIconOnClickCallback={touchSupported && (() => revealInnerPanel(option.name))}
							label={option.title}
							labelHoverStamp={isOnlyLinkVisible && (
								<InternalLink
									onClickCallback={(event) => handleOnlyLinkClick(event, optionName)}
									style={InternalLinkStyle.Decent}
								>
									{onlyLinkLabel || '[ONLY]'}
								</InternalLink>
							)}
							suffix={option.suffix}
						/>
					)
					}
					name={optionName}
					onChangeCallback={(
						!option.disabled
							? () => handleOptionChange(optionName, option.children, option.onlyOneChildSelectable ?? false)
							: undefined
					)}
					onClick={(
						!option.disabled
							? (event) => handleOptionClick(event, optionName, option.children, option.onlyOneChildSelectable ?? false)
							: undefined
					)}
					onMouseEnter={() => handleOptionMouseEnter(optionName)}
					onMouseLeave={() => handleOptionMouseLeave(optionName)}
					size={hideInnerOptions && level > 1 ? AbstractCheckboxFieldSize.Small : AbstractCheckboxFieldSize.Default}
				/>
			);

			field = (
				<FieldDropdownOption>
					{field}
				</FieldDropdownOption>
			);

			return (
				<React.Fragment key={level + '-' + i}>
					{children && areChildrenInPanel ? (
						<AttachedOptionsPanel
							isPanelRevealed={isChildrenPanelRevealed}
							onMouseEnter={() => revealInnerPanel(option.name)}
							onMouseLeave={() => hideRevealedInnerPanel()}
							panel={children}
							panelWidth={option.innerPanelWidth ?? innerPanelWidth}
						>
							{field}
						</AttachedOptionsPanel>
					) : (
						<>
							{field}
							{children}
						</>
					)}
				</React.Fragment>
			);
		});

		return (
			<FieldDropdownOptionsGroup
				isInner={level > 1 && !inPanelView}
				showMoreLink={isOutputLimited ? {
					label: showMoreLinkLabel,
					onClickCallback: handleShowAllOptions,
				} : undefined}
			>
				{structure}
			</FieldDropdownOptionsGroup>
		);
	};

	return renderOptions(options, '', 1, false);
};



export default FieldDropdownMultiselectableOptions;

export {
	FieldDropdownMultiselectableOptionsChildrenProps,
	FieldDropdownMultiselectableOptionsOptionChangeCallbackInput,
	FieldDropdownMultiselectableOptionsOptionClickCallbackInput,
	FieldDropdownMultiselectableOptionsOptions,
};
