import classNames from 'classnames';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import styles from './NoSearchResults.module.scss';



export enum NoSearchResultsContext {
	General = 'general',
	Scope = 'scope',
}

const messages = defineMessages({
	noResults: {
		id: 'ui.general.noSearchResults',
		defaultMessage: 'We could not find anything based on your filtering criteria.',
	},
});



type Props = {
	/** Additional text attached bellow main message */
	additionalInfo?: React.ReactNode,
	children?: React.ReactNode,
	/** Message can be shown in different context of the app. Different context means different visual representation. */
	context?: NoSearchResultsContext,
};

const NoSearchResults: React.FC<Props> = (props) => {
	const {
		additionalInfo,
		children,
		context = NoSearchResultsContext.General,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles[context + 'Context'] ?? '']: true,
	});

	return (
		<div className={componentClasses}>
			<div>
				{children || (
					<FormattedMessage {...messages.noResults} />
				)}
			</div>
			{additionalInfo && (
				<div className={styles.text}>
					{additionalInfo}
				</div>
			)}
		</div>
	);
};



export default NoSearchResults;
