import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import AffectedButton, {
	AffectedButtonScope,
	AffectedButtonSize,
} from '~/components/logic/issues/detail/components/AffectedButton';
import AnimateHeartbeat from '~/components/logic/score/AnimateHeartbeat';
import CurrentIssueDetail from '../issueDetail/CurrentState';
import IssueCategoryIgnoringButton from '~/components/logic/issues/IssueCategoryIgnoringButton';
import IssueCategoryNameIcon, {
	IssueCategoryNameIconSize,
} from '~/components/app/IssueCategoryNameIcon';
import IssueCategoryNotApplicable from '~/components/app/IssueCategoryNotApplicable';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import IssueConfigButton from '~/components/logic/issues/IssueConfigButton';
import IssueScore, {
	IssueScoreSize,
	IssueScoreState,
} from '~/components/patterns/issues/IssueScore';
import IssuesDetailLayout from '~/components/atoms/issues/components/issueDetail/IssuesDetailLayout';
import PercentageOfAffectedPagesHint from '~/components/logic/interfaceHints/PercentageOfAffectedPagesHint';
import SegmentsBarChart, {
	type SegmentsBarChartExtractCallbackInput,
} from '~/components/logic/charts/SegmentsBarChart';
import WithPermission from '~/components/logic/access/WithPermission';

import useKeepAround from '~/hooks/useKeepAround';
import useOpenedWebsiteIssueCategory from '~/hooks/useOpenedWebsiteIssueCategory';
import useSortedIssues from '~/hooks/useSortedIssues';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	openCategoryAffectedPages,
} from '~/actions/issuesScreen';

import {
	getPointsToBeDisplayed,
	isRecognizedIssue,
	sortIssues,
} from '~/model/issuesNew';

import {
	ObjectType,
} from '~/model/permissions';

import {
	issuesFilterSelector,
} from '~/state/ui/selectors';

import matchAndReturn from '~/utilities/matchAndReturn';



const CurrentState: React.FC = () => {
	const websiteId = useWebsiteId();

	const dispatch = useDispatch();
	const issuesFilter = useSelector(issuesFilterSelector);

	const [showSegmentsButtonOnHover, setShowSegmentsButtonOnHover] = React.useState(true);

	const issueCategory = useKeepAround(
		useOpenedWebsiteIssueCategory(websiteId),
	);

	const sortedIssues = useSortedIssues(
		issueCategory?.issues ?? null,
		sortIssues,
	);

	const issueCategoryName = issueCategory?.name ?? null;

	const handleAffectedPagesButtonClick = React.useCallback(
		() => {
			if (issueCategoryName === null) {
				return;
			}

			let filter: Record<string, any> | null = null;

			if (issuesFilter.get('segment') !== 'website') {
				filter = {
					segment: issuesFilter.get('segment'),
				};
			}

			dispatch(
				openCategoryAffectedPages(
					issueCategoryName,
					filter,
				),
			);
		},
		[
			dispatch,
			issueCategoryName,
			issuesFilter,
		],
	);

	const handleHeaderOnMouseEnter = React.useCallback(
		() => {
			setShowSegmentsButtonOnHover(false);
		},
		[
			setShowSegmentsButtonOnHover,
		],
	);

	const handleHeaderOnMouseLeave = React.useCallback(
		() => {
			setShowSegmentsButtonOnHover(true);
		},
		[
			setShowSegmentsButtonOnHover,
		],
	);

	const handleOpenBarClick = React.useCallback(
		({ scope }) => {
			if (issueCategoryName === null) {
				return;
			}

			let filter: Record<string, any> | null = null;

			if (scope.indexOf('segment/') === 0) {
				filter = {
					segment: scope.substr('segment/'.length),
				};
			}

			dispatch(
				openCategoryAffectedPages(
					issueCategoryName,
					filter,
				),
			);
		},
		[
			dispatch,
			issueCategoryName,
		],
	);

	const extractDataForSegmentsChart = React.useCallback(
		(issueCategories: SegmentsBarChartExtractCallbackInput) => {
			if (issueCategoryName === null) {
				throw new Error(
					'issueCategoryName must be ready when extracting data for SegmentsChart',
				);
			}

			const result = issueCategories
				.find((issueCategory) => issueCategory.name === issueCategoryName)
				?.affectedPagesPerState
				?.percentage;

			if (result === undefined) {
				throw new Error(
					`Can't find data for SegmentsChart for issue category '${issueCategoryName}'`,
				);
			}

			return result;
		},
		[
			issueCategoryName,
		],
	);

	if (issueCategory === null) {
		return (
			<IssuesDetailLayout
				isLoading={true}
			/>
		);
	}

	const renderCategoryScore = () => {
		const scores = [
			(
				<WithPermission
					action={GraphQL.ActionWithWebsite.ManageIssuesConfiguration}
					key="configuration"
					objectId={websiteId}
					objectType={ObjectType.Website}
				>
					{({ isAllowed }) => {
						if (isAllowed.yes === false) {
							return null;
						}

						return (
							<IssueConfigButton
								hasConfiguration={Object.values(issueCategory.configuration).length > 0}
								issueCategoryName={issueCategory.name}
								websiteId={websiteId}
							/>
						);
					}}
				</WithPermission>
			),
			(
				<WithPermission
					action={GraphQL.ActionWithWebsite.ManageIgnoring}
					key="ignoring"
					objectId={websiteId}
					objectType={ObjectType.Website}
				>
					{({ isAllowed }) => {
						if (isAllowed.yes === false) {
							return null;
						}

						return (
							<IssueCategoryIgnoringButton
								issueCategoryName={issueCategory.name}
							/>
						);
					}}
				</WithPermission>
			),
		];

		if (issueCategory.affectedPagesPerState !== null) {
			scores.push(
				<PercentageOfAffectedPagesHint key="affected-pages-button">
					<AffectedButton
						count={issueCategory.affectedPagesPerState.absoluteNumberOfPages.open}
						onClickCallback={handleAffectedPagesButtonClick}
						percentage={issueCategory.affectedPagesPerState.percentage.open}
						scope={AffectedButtonScope.Pages}
						size={AffectedButtonSize.Medium}
					/>
				</PercentageOfAffectedPagesHint>,
			);
		}

		scores.push(
			<AnimateHeartbeat
				enabled={issueCategory.isActionable}
				key="issues-score"
			>
				<IssueScore
					isIgnored={issueCategory.isIgnored}
					size={IssueScoreSize.Large}
					state={matchAndReturn(issueCategory.state, {
						[GraphQL.IssueCategoryState.Closed]: IssueScoreState.Fixed,
						[GraphQL.IssueCategoryState.Open]: IssueScoreState.Broken,
						[GraphQL.IssueCategoryState.NotApplicable]: IssueScoreState.NotApplicable,
						[GraphQL.IssueCategoryState.NotRequired]: IssueScoreState.NotRequired,
						[GraphQL.IssueCategoryState.Unknown]: IssueScoreState.Unknown,
					})}
					value={getPointsToBeDisplayed(issueCategory)}
				/>
			</AnimateHeartbeat>,
		);

		return scores;
	};

	const renderCategoryDescription = () => {
		if (issueCategory.state === GraphQL.IssueCategoryState.NotApplicable) {
			return (
				<IssueCategoryNotApplicable
					issueCategoryName={issueCategory.name}
					issueCategoryPoints={getPointsToBeDisplayed(issueCategory)}
					issues={issueCategory.issues}
				/>
			);
		}

		return sortedIssues.filter(isRecognizedIssue).filter((issue) => issue.state !== GraphQL.IssueState.NotApplicable).map((issue) => (
			<CurrentIssueDetail
				issue={issue}
				issueCategoryName={issueCategory.name}
				key={issue.name}
				websiteId={websiteId}
			/>
		));
	};

	return (
		<IssuesDetailLayout
			actionElements={renderCategoryScore()}
			headerOnMouseEnterCallback={handleHeaderOnMouseEnter}
			headerOnMouseLeaveCallback={handleHeaderOnMouseLeave}
			icon={(
				<IssueCategoryNameIcon
					issueCategoryName={issueCategory.name}
					size={IssueCategoryNameIconSize.Small}
				/>
			)}
			illustration={issueCategory.affectedPagesPerState !== null && issuesFilter && (issueCategory.affectedPagesPerState.percentage.open > 0 || issueCategory.affectedPagesPerState.percentage.ignored > 0) && (
				<SegmentsBarChart
					extractCallback={extractDataForSegmentsChart}
					filter={issuesFilter}
					key={`${websiteId}/${issuesFilter.get('segment')}/${issueCategoryName}`}
					numberOfIssues={issueCategory.issues.filter((issue) => issue.affectedPagesPerState !== null).length}
					onOpenBarClickCallback={handleOpenBarClick}
					showSegmentsButtonOnHover={showSegmentsButtonOnHover}
					websiteId={websiteId}
				/>
			)}
			title={(
				<IssueCategoryTitle
					issueCategoryName={issueCategory.name}
				/>
			)}
		>
			{renderCategoryDescription()}
		</IssuesDetailLayout>
	);
};



export default CurrentState;
