import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Hint from '~/components/patterns/hints/hint/Hint';
import RemoveButton from '~/components/patterns/buttons/RemoveButton';



const messages = defineMessages({
	default: {
		id: 'ui.segments.editor.removeColumn.default',
	},
	withFilter: {
		id: 'ui.segments.editor.removeColumn.withFilter',
	},
	withFilterAndSizeLimit: {
		id: 'ui.segments.editor.removeColumn.withFilterAndSizeLimit',
	},
	withSizeLimit: {
		id: 'ui.segments.editor.removeColumn.withSizeLimit',
	},
});



type Props = {
	columnName: CK.PagesColumn,
	filterDefinition: CK.FilterDefinition,
	onClickCallback: () => void,
	sizeLimit: CK.PageSegmentSizeLimit | null,
};

const RemoveColumnButton: React.FC<Props> = (props) => {
	const {
		columnName,
		filterDefinition,
		onClickCallback,
		sizeLimit,
	} = props;

	let popup;

	const hasFilter = columnName in filterDefinition;
	const hasSizeLimit = sizeLimit?.field === columnName;

	if (hasFilter && hasSizeLimit) {
		popup = <FormattedMessage {...messages.withFilterAndSizeLimit} />;
	} else if (hasFilter) {
		popup = <FormattedMessage {...messages.withFilter} />;
	} else if (hasSizeLimit) {
		popup = <FormattedMessage {...messages.withSizeLimit} />;
	} else {
		popup = <FormattedMessage {...messages.default} />;
	}

	return (
		<Hint popup={popup}>
			<RemoveButton
				onClickCallback={onClickCallback}
			/>
		</Hint>
	);
};



export default RemoveColumnButton;
