/**
 * Prevent selecting of the text when using SHIFT key interaction.
 * We will add class .user-select-disabled by Javascript during interaction.
 */
export default function setupPreventShiftSelection(): void {
	const html = document.documentElement;

	document.onkeydown = function (event) {
		if (event.key === 'Shift') {
			html.classList.add('user-select-disabled'); // user-select-disabled contains aforementioned css rules

			document.onkeyup = function () {
				html.classList.remove('user-select-disabled'); // and simply remove user-select-disabled class making text selection available
			};
		}
	};
}
