import {
	format,
	isSameDay,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	today: {
		id: 'ui.relativeDate.today',
	},
});



type Props = {
	endDate: Date,
	startDate: Date,
};

const DateRangeLabel: React.FC<Props> = (props) => {
	const {
		endDate,
		startDate,
	} = props;

	const isToday = isSameDay(endDate, new Date());
	const showSingleDataLabel = isSameDay(startDate, endDate);

	if (showSingleDataLabel) {
		if (isToday) {
			return (
				<FormattedMessage {...messages.today} />
			);
		}

		return (
			<>
				{format(endDate, 'd MMM yyyy')}
			</>
		);
	}

	let endDateLabel;

	if (isToday) {
		endDateLabel = (
			<FormattedMessage {...messages.today} />
		);
	} else {
		endDateLabel = (
			format(endDate, 'd MMM yyyy')
		);
	}

	return (
		<span>
			{format(startDate, 'd MMM yyyy')}
			{' - '}
			{endDateLabel}
		</span>
	);
};



export default DateRangeLabel;
