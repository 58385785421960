import classNames from 'classnames';
import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';



export enum ProgressSize {
	Default = 'default',
	Small = 'small',
}

export enum ProgressStyle {
	Light = 'light',
	Primary = 'primary',
}

type Props = {
	/** Optional explanatory label */
	label?: React.ReactElement | string,
	/** How much work the task requires in total */
	max: number,
	/** Size of component */
	size?: ProgressSize,
	/** Possibility to change look (style) of component */
	style?: ProgressStyle,
	/** How much of the task has been completed */
	value: number,
};



const Progress: React.FC<Props> = (props) => {
	const {
		label,
		max,
		size = ProgressSize.Default,
		style = ProgressStyle.Primary,
		value,
	} = props;

	const percentValue = value / (max / 100);

	const componentClasses = classNames({
		'progress': true,
		['progress--' + size + '-size']: true,
		['progress--' + style + '-style']: true,
	});

	return (
		<div className={componentClasses}>
			{label && (
				<div className="progress__label">{label}</div>
			)}
			<div className="progress__element">
				<div className="progress__indicator">
					{Array.from(Array(10)).map((_, i) => {
						const stepClasses = classNames({
							'progress__indicator-step': true,
							'progress__indicator-step--active': (i * 10) <= (percentValue - 10),
						});

						return (
							<div
								className={stepClasses}
								key={'progress-indicator-step-' + i}
							/>
						);
					})}
				</div>
				<div className="progress__value">
					<FormattedNumber
						maximumFractionDigits={0}
						style="percent"
						value={percentValue / 100}
					/>
				</div>
			</div>
		</div>
	);
};



export default Progress;
