import React from 'react';

import type CK from '~/types/contentking';

import useWebsiteSegmentDefinitions from './useWebsiteSegmentDefinitions';

import {
	type SegmentDefinition,
	dependsOnColumns,
} from '~/model/segments';



function useSegmentsDependingOnColumn(websiteId: CK.WebsiteId): (columnName: string) => ReadonlyArray<SegmentDefinition> | null {
	const segments = useWebsiteSegmentDefinitions(websiteId);

	return React.useCallback(
		(columnName: string) => {
			if (segments.isLoaded === false) {
				return null;
			}

			return segments.listAll().filter(
				(segmentDefinition) => dependsOnColumns(
					segments.listAll(),
					segmentDefinition,
					[columnName],
				),
			);
		},
		[
			segments,
		],
	);
}



export default useSegmentsDependingOnColumn;
