import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class BreadcrumbNavigation extends Component {

	render() {
		const {
			items,
		} = this.props;

		return (
			<ul className="breadcrumb-nav">
				{items.map((item, index) => {
					return (
						<li
							className={classNames({
								'breadcrumb-nav__item': true,
								'breadcrumb-nav__item--is-active': item.active,
							})}
							key={'nav-item-' + index}
						>
							{item.label}
						</li>
					);
				})}
			</ul>
		);
	}

}

BreadcrumbNavigation.propTypes = {
	items: PropTypes.array.isRequired,
};



export default BreadcrumbNavigation;
