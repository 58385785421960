import {
	format,
	isToday,
	isYesterday,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DateTime from '~/components/app/DateTime';
import Hint from '~/components/patterns/hints/hint/Hint';



const messages = defineMessages({
	today: {
		id: 'ui.relativeDate.today',
	},
	yesterday: {
		id: 'ui.relativeDate.yesterday',
	},
});



type Props = {
	date: Date,
};

const DateLabel: React.FC<Props> = (props) => {
	const {
		date,
	} = props;

	let label: React.ReactChild | null = null;

	if (isToday(date)) {
		label = (
			<FormattedMessage {...messages.today} />
		);
	} else if (isYesterday(date)) {
		label = (
			<FormattedMessage {...messages.yesterday} />
		);
	} else {
		label = format(date, 'iiii, d MMM yyyy');
	}

	return (
		<Hint
			popup={(
				<DateTime datetime={date} />
			)}
		>
			{label}
		</Hint>
	);
};



export default DateLabel;
