import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const LogoutIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				transform="matrix(0 1 -1 0 25.933002 .439394)"
			>
				<path
					d="m25.2121212 25.2121212h-25.2121212v-25.2121212h25.2121212z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				>
					<path d="m7.09469091 10.2495152h-1.57890909v10.2400606h14.18181818v-10.2400606h-1.5726061" />
					<path d="m9.45501818 7.87878788 3.15151512-3.15151515 3.1515152 3.15151515" />
					<path d="m12.6060606 4.73341818v11.82054542" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default LogoutIconBuilder;

