import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Backlink from '~/components/logic/links/Backlink';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button from '~/components/patterns/buttons/Button';
import HeaderTitle, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HighlightedDate from '~/components/patterns/time/HighlightedDate';
import RobotsTxtViewerHeaderLayout from '~/components/patterns/screens/robotsTxtViewer/header/RobotsTxtViewerHeader';
import SelectedDateOverview from '~/components/patterns/screens/robotsTxtViewer/sections/SelectedDateOverview';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import TrackedChangesModeHighlight from '~/components/patterns/screens/parts/header/TrackedChangesModeHighlight';
import ValuesRange from '~/components/patterns/values/ValuesRange';

import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	comparingVersions: {
		id: 'ui.robotsTxtComparisonHeader.comparingVersions',
	},
	exit: {
		id: 'ui.general.exit',
	},
	title: {
		id: 'ui.robotsTxtComparisonHeader.title',
	},
	trackedChanges: {
		id: 'ui.robotsTxtComparisonHeader.trackedChanges',
	},
});



type Props = {
	backlink?: string,
	closeCallback?: () => void,
	nextRevisionTimestamp: CK.Timestamp | null,
	onMobileFilterButtonClick: () => void,
	previousRevisionTimestamp: CK.Timestamp | null,
};

const RobotsTxtComparisonHeader: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
		onMobileFilterButtonClick,
	} = props;

	const nextRevisionTimestamp = props.nextRevisionTimestamp !== null ? new Date(props.nextRevisionTimestamp) : null;
	const previousRevisionTimestamp = props.previousRevisionTimestamp !== null ? new Date(props.previousRevisionTimestamp) : null;

	const viewportType = useViewportType();
	const websiteId = useWebsiteId();

	return (
		<RobotsTxtViewerHeaderLayout
			buttons={(
				<TrackedChangesModeHighlight
					exitButton={(
						<Button
							compact={false}
							icon={(
								<BasicIcon type={BasicIconType.Cancel} />
							)}
							linkRouteName="website.platform.robotsTxtViewer"
							linkRouteParams={{
								websiteId,
								revisionId: 'latest',
							}}
						>
							<FormattedMessage {...messages.exit} />
						</Button>
					)}
					label={(
						<FormattedMessage {...messages.trackedChanges} />
					)}
				/>
			)}
			onMobileFilterButtonClickCallback={onMobileFilterButtonClick}
			selectedDateOverview={(
				<SelectedDateOverview
					trackedChangesLabel={(
						<FormattedMessage {...messages.comparingVersions} />
					)}
				>
					{previousRevisionTimestamp !== null && nextRevisionTimestamp !== null ? (
						<ValuesRange
							from={(
								<HighlightedDate timestamp={nextRevisionTimestamp} />
							)}
							to={(
								<HighlightedDate timestamp={previousRevisionTimestamp} />
							)}
						/>
					) : (
						<SquareSkeleton
							height={16}
							maxWidth={200}
						/>
					)}
				</SelectedDateOverview>
			)}
			title={(
				<HeaderTitleLayout>
					{!viewportType.isSmall && backlink && closeCallback && (
						<Backlink
							callback={closeCallback}
							label={backlink}
						/>
					)}
					<HeaderTitle size={TITLE_SIZE_SMALL}>
						<FormattedMessage {...messages.title} />
					</HeaderTitle>
				</HeaderTitleLayout>
			)}
		/>
	);
};

export default RobotsTxtComparisonHeader;
