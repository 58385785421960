import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ScreenMessage from '~/components/patterns/messages/embedded/ScreenMessage';
import ScreenOverlay, {
	ScreenOverlayStyle,
} from '~/components/patterns/screens/parts/ScreenOverlay';



const messages = defineMessages({
	screenNotFound: {
		id: 'ui.general.screenNotFound',
	},
});



const Error404: React.FC = () => {
	return (
		<ScreenOverlay style={ScreenOverlayStyle.Light}>
			<ScreenMessage>
				<FormattedMessage {...messages.screenNotFound} />
			</ScreenMessage>
		</ScreenOverlay>
	);
};



export default Error404;
