import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SendIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g id="send">
					<polyline
						id="Stroke-100"
						points="8.08822503 15.2316 3.000225 11.64285 21.000225 4.1361 16.493475 20.6316 11.379225 17.45085"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</polyline>
					<polygon
						id="Stroke-101"
						points="11.379525 17.451075 20.999775 4.136325 8.08852497 15.231825 9.25252497 20.267325"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</polygon>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SendIconBuilder;

