import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import DonutPagesChart from '../components/DonutPagesChart';



const messages = defineMessages({
	good: {
		id: 'ui.screen.pagesGraphs.webVitals.good',
	},
	needsImprovement: {
		id: 'ui.screen.pagesGraphs.webVitals.needsImprovement',
	},
	poor: {
		id: 'ui.screen.pagesGraphs.webVitals.poor',
	},
});



type Props = {
	field: string,
	data: any,
};

const WebVitalsChart: React.FC<Props> = (props) => {
	const {
		field,
		data,
	} = props;

	const intl = useIntl();

	const labels = {
		good: intl.formatMessage(messages.good),
		needsImprovement: intl.formatMessage(messages.needsImprovement),
		bad: intl.formatMessage(messages.poor),
	};

	const order = [
		'good',
		'needsImprovement',
		'bad',
	];

	const specificColors = {
		good: '#0cce6b',
		needsImprovement: '#ffa400',
		bad: '#ff4e42',
	};

	return (
		<DonutPagesChart
			data={data}
			labels={labels}
			name={field}
			order={order}
			percentageFactor={100}
			specificColors={specificColors}
		/>
	);
};

export default WebVitalsChart;
