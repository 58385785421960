import React from 'react';

import HistoricalIssuesCategoryChangesBadge, {
	HistoricalIssuesCategoryChangesBadgeType,
} from '~/components/app/IssuesScreen/HistoricalIssuesCategoryChangesBadge';
import IssueCategoryCardLayout, {
	HIGHLIGHT_INDENTATION_RIGHT,
	ICON_ALIGNMENT_CENTER,
} from '~/components/atoms/issues/components/categoryCards/builders/IssueCategoryCardLayout';
import IssueCategoryNameIcon from '~/components/app/IssueCategoryNameIcon';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import IssueHealthImpact, {
	IssueHealthImpactAbout,
	IssueHealthImpactFlow,
	IssueHealthImpactScope,
	IssueHealthImpactSize,
} from '../IssueHealthImpact';
import WebsiteIssueCategoryCardContent from '~/components/atoms/issues/components/categoryCards/builders/WebsiteIssueCategoryCardContent';

import {
	type IssueCategoryName,
} from '~/model/issuesNew';



type Props = {
	filter: any,
	isActive: boolean,
	issueCategory: {
		health: {
			change: {
				next: {
					gained: number,
					toGain: number,
				},
				previous: {
					gained: number,
					toGain: number,
				},
				type: 'worse' | 'better' | 'same',
			},
			toGain: number,
		},
		isInitialComparison: boolean,
		issues: ReadonlyArray<{
			health: {
				change: {
					next: {
						gained: number,
						toGain: number,
					},
					previous: {
						gained: number,
						toGain: number,
					},
					type: 'worse' | 'better' | 'same',
				},
				toGain: number,
			},
			isInitialComparison: boolean,
			scope: {
				movements: Record<string, number>,
				name: 'pages' | 'platform',
			},
		}>,
		name: IssueCategoryName,
	},
	onBlurIssueCallback: () => void,
	onFocusIssueCallback: (issueCategoryName: string) => void,
	onMouseEnterCallback: (issueCategoryName: string) => void,
	onMouseLeaveCallback: () => void,
};

const WebsiteIssueCategoryComparisonCard: React.FC<Props> = (props) => {
	const {
		filter,
		isActive,
		issueCategory,
		onBlurIssueCallback,
		onFocusIssueCallback,
		onMouseEnterCallback,
		onMouseLeaveCallback,
	} = props;

	const issueCategoryName = issueCategory.name;

	const handleHeaderMouseEnter = React.useCallback(
		() => {
			onMouseEnterCallback(
				issueCategoryName,
			);
		},
		[
			issueCategoryName,
			onMouseEnterCallback,
		],
	);

	const handleHeaderClick = React.useCallback(
		() => {
			if (!isActive) {
				onFocusIssueCallback(issueCategoryName);
			} else {
				onBlurIssueCallback();
			}
		},
		[
			isActive,
			issueCategoryName,
			onBlurIssueCallback,
			onFocusIssueCallback,
		],
	);

	return (
		<IssueCategoryCardLayout
			highlightIndentation={HIGHLIGHT_INDENTATION_RIGHT}
			icon={(
				<IssueCategoryNameIcon
					issueCategoryName={issueCategoryName}
				/>
			)}
			iconAlignment={ICON_ALIGNMENT_CENTER}
			isHighlighted={isActive}
			onClickCallback={handleHeaderClick}
			onMouseEnterCallback={handleHeaderMouseEnter}
			onMouseLeaveCallback={onMouseLeaveCallback}
		>
			<WebsiteIssueCategoryCardContent
				title={(
					<IssueCategoryTitle
						issueCategoryName={issueCategoryName}
					/>
				)}
				values={[
					(
						<HistoricalIssuesCategoryChangesBadge
							inList={true}
							issueCategory={issueCategory}
							key="opened"
							type={HistoricalIssuesCategoryChangesBadgeType.Opened}
						/>
					),
					(
						<HistoricalIssuesCategoryChangesBadge
							inList={true}
							issueCategory={issueCategory}
							key="resolved"
							type={HistoricalIssuesCategoryChangesBadgeType.Resolved}
						/>
					),
					(
						<IssueHealthImpact
							about={IssueHealthImpactAbout.IssueCategory}
							flow={IssueHealthImpactFlow.Column}
							issue={issueCategory}
							key="score"
							scope={filter.scope === 'website' ? IssueHealthImpactScope.Website : IssueHealthImpactScope.Segment}
							size={IssueHealthImpactSize.Default}
						/>
					),
				]}
			/>
		</IssueCategoryCardLayout>
	);
};



export default WebsiteIssueCategoryComparisonCard;
