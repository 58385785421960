import React from 'react';

import type CK from '~/types/contentking';

import {
	useRemoveAccessToClientMutation,
} from './useRemoveAccessToClient.gql';



function useRemoveAccessToClient() {
	const [removeAccessToClient] = useRemoveAccessToClientMutation();

	return React.useCallback(
		async ({
			agencyId,
			clientId,
		}: {
			agencyId: CK.AccountId,
			clientId: CK.AccountId,
		}) => {
			await removeAccessToClient({
				variables: {
					agencyId,
					clientId,
				},
			});
		},
		[
			removeAccessToClient,
		],
	);
}



export default useRemoveAccessToClient;
