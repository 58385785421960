import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class HintPopupNavigationItem extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleClick = this._handleClick.bind(this);
	}



	_handleClick(e) {
		e.preventDefault();
		e.stopPropagation();

		const {
			onClickCallback,
		} = this.props;

		onClickCallback();
	}



	render() {
		const {
			children,
		} = this.props;

		return (
			<li className="hint-nav__item">
				<a
					className="hint-nav__link"
					href="#"
					onClick={this._handleClick}
				>
					<span className="hint-nav__label">
						{children}
					</span>
				</a>
			</li>
		);
	}

}

HintPopupNavigationItem.propTypes = {
	onClickCallback: PropTypes.func.isRequired,
};



export default HintPopupNavigationItem;
