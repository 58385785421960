import React from 'react';

import NoName from '~/components/app/NoName';



type Props = {
	firstName: string | null,
	email: string,
};

const FirstName: React.FC<Props> = (props) => {
	const {
		email,
		firstName,
	} = props;

	if (firstName !== null && firstName.trim().length > 0) {
		return (
			<span>{firstName}</span>
		);
	}

	return (
		<NoName email={email} />
	);
};



export default FirstName;
