import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import DestructConfirmationModal from './DestructConfirmationModal';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';

import {
	useDeleteEnrichmentFieldMutation,
} from './DeleteEnrichmentFieldModal.gql';

import useAlertDefinitionDependencies from '~/hooks/useAlertDefinitionDependencies';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';



const messages = defineMessages({
	confirmButton: {
		id: 'ui.deleteEnrichmentFieldConfirmation.confirmButton',
	},
	description: {
		id: 'ui.deleteEnrichmentFieldConfirmation.description',
	},
	labelName: {
		id: 'ui.deleteEnrichmentFieldConfirmation.labelName',
	},
	labelElementType: {
		id: 'ui.deleteEnrichmentFieldConfirmation.labelElementType',
	},
	labelFromWebsite: {
		id: 'ui.deleteEnrichmentFieldConfirmation.labelFromWebsite',
	},
	title: {
		id: 'ui.deleteEnrichmentFieldConfirmation.title',
	},
	verb: {
		id: 'ui.deleteEnrichmentFieldConfirmation.verb',
	},
	warnPermanent: {
		id: 'ui.deleteEnrichmentFieldConfirmation.warnPermanent',
	},
});



type Props = {
	enrichmentFieldId: string,
	websiteId: CK.WebsiteId,
};

const DeleteEnrichmentFieldModal: React.FC<Props> = (props) => {
	const {
		enrichmentFieldId,
		websiteId,
	} = props;

	const [deleteEnrichmentField] = useDeleteEnrichmentFieldMutation();
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const websiteDisplayName = useWebsiteDisplayName(websiteId);

	const enrichmentFieldDefinition = enrichmentFieldDefinitions.getById(enrichmentFieldId);

	const alertDefinitionDependencies = useAlertDefinitionDependencies(websiteId);
	const alertDefinitionsDependingOnEnrichmentField = enrichmentFieldDefinition?.column
		? alertDefinitionDependencies.getAlertDefinitionsDependingOnField(enrichmentFieldDefinition.column)
		: [];

	const label = enrichmentFieldDefinition?.label ?? '...';
	const dataType = enrichmentFieldDefinition?.dataType ?? '';

	const handleDeletion = React.useCallback(
		async () => {
			await deleteEnrichmentField({
				variables: {
					id: enrichmentFieldId,
					websiteId,
				},
			});
		},
		[
			deleteEnrichmentField,
			enrichmentFieldId,
			websiteId,
		],
	);

	return (
		<DestructConfirmationModal
			confirmButton={(
				<FormattedMessage {...messages.confirmButton} />
			)}
			confirmationPhrase={label}
			description={(
				<FormattedMessage {...messages.description} />
			)}
			disabled={alertDefinitionsDependingOnEnrichmentField.length > 0}
			loading={enrichmentFieldDefinition === null || !alertDefinitionDependencies.isLoaded}
			onConfirmation={handleDeletion}
			titleAction={(
				<FormattedMessage {...messages.verb} />
			)}
			titleObject={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Copy {...messages.warnPermanent} />

			<LabeledValuesBox
				items={[
					{
						label: <FormattedMessage {...messages.labelName} />,
						value: label,
					},
					{
						label: <FormattedMessage {...messages.labelElementType} />,
						value: dataType,
					},
					{
						label: <FormattedMessage {...messages.labelFromWebsite} />,
						value: websiteDisplayName,
					},
				]}
			/>
		</DestructConfirmationModal>
	);
};



export default DeleteEnrichmentFieldModal;
