import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	no: {
		id: 'ui.general.boolean.values.no',
	},
	yes: {
		id: 'ui.general.boolean.values.yes',
	},
});



type Props = {
	state: boolean,
};

const YesOrNo: React.FC<Props> = (props) => {
	const {
		state,
	} = props;

	return (
		<FormattedMessage {...messages[state ? 'yes' : 'no']} />
	);
};



export default YesOrNo;
