import React, {
	Component,
} from 'react';



class HintPopupNavigation extends Component {

	render() {
		const {
			children,
		} = this.props;

		if (React.Children.count(children) === 0) {
			return false;
		}

		return (
			<ul className="hint-nav">
				{children}
			</ul>
		);
	}

}



export default HintPopupNavigation;
