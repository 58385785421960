import {
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
	LOGOUT_SUCCESSFUL,
	STORE_LOADED_PAGES,
} from '~/actions';

import {
	START_LOADING_PAGES_CHARTS,
	STORE_PAGES_CHARTS,
} from '~/actions/pages/overview';



function createDefaultState() {
	return new Map({
		inProgressLoading: false,
		show: true,
	});
}



export function dataLoading(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			const {
				previousUrlState,
				urlState,
			} = action;

			if (
				urlState.params.websiteId !== previousUrlState.params.websiteId
				&& urlState.name === 'website.pages'
			) {
				state = state.set('show', true);
			}

			break;
		}

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case STORE_LOADED_PAGES:
		case STORE_PAGES_CHARTS: {
			state = state.set('show', false);
			state = state.set('inProgressLoading', false);

			break;
		}

		case START_LOADING_PAGES_CHARTS: {
			state = state.set('show', true);
			state = state.set('inProgressLoading', true);

			break;
		}

	}

	return state;
}
