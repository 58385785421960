import React from 'react';
import {
	Provider as ReduxProvider,
} from 'react-redux';

import {
	setupFeatureFlagTools,
} from '~/featureFlags';

import {
	setupStore,
} from '~/store';



const reduxStore = setupStore();

setupFeatureFlagTools(reduxStore);



type Props = {
	children: React.ReactNode,
};

const ReduxStoreProvider: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<ReduxProvider store={reduxStore}>
			{children}
		</ReduxProvider>
	);
};



export default ReduxStoreProvider;
