import React from 'react';

import type CK from '~/types/contentking';

import CountSlider from '~/components/patterns/forms/fields/CountSlider';
import CountSliderFieldLayout from '~/components/patterns/forms/fieldParts/countSliders/CountSliderFieldLayout';
import CountSliderInput from '~/components/atoms/forms/components/countSliders/abstractBuilders/CountSliderInput';
import PageBundleFieldContext from '~/components/app/PageBundleFieldContext';

import useAccountWebsiteRestrictions from '~/hooks/useAccountWebsiteRestrictions';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useFormContext from '~/hooks/useFormContext';
import useTariffs from '~/hooks/useTariffs';



type Props = {
	accountId: CK.AccountId,
	name: string,
	unlimited?: boolean,
	width?: number,
};

const PageBundleField: React.FC<Props> = (props) => {
	const {
		accountId,
		name,
		unlimited = false,
		width = 308,
	} = props;

	const accountWebsiteRestrictions = useAccountWebsiteRestrictions(accountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);
	const formContext = useFormContext();
	const tariffs = useTariffs();

	if (
		accountWebsiteRestrictions === null
		|| accountWebsiteSizes === null
		|| tariffs.isReady === false
	) {
		return null;
	}

	const value = formContext.values[name] ?? formContext.defaultValues[name] ?? 0;

	const maximumPageBundle = unlimited === false ? accountWebsiteRestrictions.maximumPageBundle : undefined;

	return (
		<PageBundleFieldContext
			maximumPageBundle={maximumPageBundle}
			minimumNumberOfPages={accountWebsiteSizes.totalPageCapacity}
			name={name}
			showExtraStep={false}
			tariff={tariffs.tariff}
			value={value}
		>
			{({ currentStep, currentValue, maxValue, minStep, minValue, onSliderStepChangeHandler, onSliderValueChangeHandler, sliderMax }) => (
				<CountSliderFieldLayout
					slider={(
						<CountSlider
							highlightedValue={minStep || undefined}
							max={sliderMax}
							onChangeCallback={onSliderStepChangeHandler}
							value={currentStep}
						/>
					)}
					sliderInfo={(
						<CountSliderInput
							max={maxValue}
							min={minValue}
							name={name}
							onSliderValueChangeHandler={onSliderValueChangeHandler}
							value={currentValue}
						/>
					)}
					width={width}
				/>
			)}
		</PageBundleFieldContext>
	);
};



export default PageBundleField;
