import classNames from 'classnames';
import React from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	Link,
} from 'react-router5';

import {
	appEmptyStateSelector,
	selectedSectionSelector,
} from '~/state/ui/selectors';

import {
	EMPTY_STATE_NO_WEBSITES,
} from '~/model/ui/appEmpty';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type ChildProps = {
	isActive: boolean,
	isDimmed: boolean,
};

type Props = {
	children: RenderProp<ChildProps>,
	className?: string,
	isActive?: boolean,
	onClick?: () => void,
};

const MultiWebsiteDashboardLink: React.FC<Props> = (props) => {
	const {
		children,
		className,
	} = props;

	const appEmptyState = useSelector(appEmptyStateSelector);
	const selectedSection = useSelector(selectedSectionSelector);

	const isActive = selectedSection === 'multiDashboard';

	function handleClick() {
		const {
			onClick,
		} = props;

		if (onClick) {
			onClick();
		}
	}

	if (appEmptyState === EMPTY_STATE_NO_WEBSITES) {
		return (
			<Link routeName="home">
				{renderProp(
					children,
					{
						isActive: false,
						isDimmed: true,
					},
				)}
			</Link>
		);
	}

	const finalClassName = classNames({
		'active': isActive,
	}, className);

	return (
		<Link
			className={finalClassName}
			onClick={handleClick}
			routeName="multiDashboard"
		>
			{renderProp(
				children,
				{
					isActive,
					isDimmed: false,
				},
			)}
		</Link>
	);
};



export default MultiWebsiteDashboardLink;
