import classNames from 'classnames';
import React from 'react';



export enum ScreenOverlayGapsSize {
	Default = 'default',
	Small = 'small',
}

export enum ScreenOverlayStyle {
	Dark = 'dark',
	Light = 'light',
	LightGradient = 'light-gradient',
	Neutral = 'neutral',
}

type Props = {
	gapsSize?: ScreenOverlayGapsSize,
	children: React.ReactNode,
	style?: ScreenOverlayStyle,
};



const ScreenOverlay: React.FC<Props> = (props) => {
	const {
		gapsSize = ScreenOverlayGapsSize.Default,
		children,
		style = ScreenOverlayStyle.Neutral,
	} = props;

	const componentClasses = classNames({
		'screen-overlay': true,
		[ 'screen-overlay--' + gapsSize + '-gaps-size' ]: true,
		[ 'screen-overlay--' + style ]: true,
		'js-scrollable': true,
	});

	return (
		<div className={componentClasses}>
			<div className="screen-overlay__content">
				{children}
			</div>
		</div>
	);
};



export default ScreenOverlay;
