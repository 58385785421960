import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import NoWebsites from '~/components/app/NoWebsites';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';

import useAccountId from '~/hooks/useAccountId';
import useAccountWebsiteCount from '~/hooks/useAccountWebsiteCount';
import usePrevious from '~/hooks/usePrevious';

import {
	markAppEmptyState,
} from '~/actions';

import {
	navigate,
} from '~/routing/router';



const EmptyAppScreen: React.FC = () => {
	const accountId = useAccountId();

	const accountWebsiteCount = useAccountWebsiteCount(accountId);
	const dispatch = useDispatch();

	const previousAccountWebsiteCount = usePrevious(accountWebsiteCount);

	React.useEffect(
		() => {
			if (
				previousAccountWebsiteCount === 0
				&& accountWebsiteCount !== null
				&& accountWebsiteCount > 0
			) {
				dispatch(markAppEmptyState(false));
				navigate('home');
			}
		},
		[
			accountWebsiteCount,
			dispatch,
			previousAccountWebsiteCount,
		],
	);

	if (accountWebsiteCount === null) {
		return null;
	}

	return (
		<ScreenLayout>
			<ScreenBody>
				<NoWebsites />
			</ScreenBody>
		</ScreenLayout>
	);
};



export default EmptyAppScreen;
