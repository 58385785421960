import type CK from '~/types/contentking';

import usePageUrl from '~/hooks/usePageUrl';

import {
	trimHostname,
} from '~/model//utils';



function usePageDisplayUrl(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
): string | null {
	const url = usePageUrl(legacyUrlId, websiteId);

	return url !== null
		? trimHostname(url)
		: null;
}



export default usePageDisplayUrl;
