import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const HourglassIconBuilder: IconBuilder = {
	icon: ({ color = '#98a5b3' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="hourglass"
					transform="translate(3.000000, 3.000000)"
				>
					<circle
						cx="9"
						cy="9"
						fill={color}
						id="Oval"
						r="9"
					>
					</circle>
					<g
						id="Group"
						transform="translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) translate(0.900000, 0.900000)"
					>
						<polygon
							id="Path"
							points="15.8925403 15.6089634 0.592540326 15.6089634 0.592540326 0.308963466 15.8925403 0.308963466"
						>
						</polygon>
						<g
							id="Path"
							transform="translate(0.810000, 0.810000)"
						>
							<polygon points="14.58 14.58 0 14.58 0 0 14.58 0"></polygon>
							<g
								stroke="#FFFFFF"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="1.5"
								transform="translate(3.600000, 1.800000)"
							>
								<polygon points="7.335 0.792 0.045 0.792 0.045 0.387 7.335 0.387"></polygon>
								<polygon points="7.335 11.169 0.045 11.169 0.045 10.764 7.335 10.764"></polygon>
								<polygon points="6.435 10.269 0.945 10.269 0.945 9.864 6.435 9.864"></polygon>
								<polygon points="4.635 4.869 2.745 4.869 2.745 4.464 4.635 4.464"></polygon>
								<path d="M6.606,0.945 L6.606,2.5698375 C6.606,3.4924725 6.128748,4.32363937 5.39829,4.67303625 L3.69,5.49 L1.98171,4.67303625 C1.251252,4.32363937 0.774,3.4924725 0.774,2.5698375 L0.774,0.945"></path>
								<path d="M6.606,10.035 L6.606,8.4101625 C6.606,7.4875275 6.128748,6.65636062 5.39829,6.30696375 L3.69,5.49 L1.98171,6.30696375 C1.251252,6.65636062 0.774,7.4875275 0.774,8.4101625 L0.774,10.035"></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default HourglassIconBuilder;

