import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CodeViewer from '~/components/patterns/tables/datatables/CodeViewer';
import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableOverlay from '~/components/patterns/tables/datatables/DatatableOverlay';
import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';

import {
	useResponseBodySnapshotQuery,
} from './PageSnapshotResponseBody.gql';



const messages = defineMessages({
	notAvailable: {
		id: 'ui.snapshotViewer.overlay.responseBodyNotAvailable',
	},
	notTracked: {
		id: 'ui.snapshotViewer.overlay.responseBodyNotTracked',
	},
	tooLarge: {
		id: 'ui.snapshotViewer.overlay.tooLarge',
	},
	truncatedLinePopup: {
		id: 'ui.snapshotViewer.truncatedLinePopup',
	},
});



type Props = {
	height: number,
	timestamp: CK.Timestamp,
	urlId: CK.UrlId,
	websiteId: CK.WebsiteId,
	width: number,
};

const PageSnapshotResponseBody: React.FC<Props> = (props) => {
	const {
		height,
		timestamp,
		urlId,
		websiteId,
		width,
	} = props;

	const { data, loading } = useResponseBodySnapshotQuery({
		variables: {
			timestamp,
			urlId,
			websiteId,
		},
	});

	const lines = data?.responseSnapshot?.src?.rows ?? [];
	const longestLine = data?.responseSnapshot?.src?.longestLineCharacters ?? undefined;

	return (
		<DatatableContainer
			overlay={
				loading ? (
					<DatatableOverlay>
						<LoadingDots size={LoadingDotsSize.Large} />
					</DatatableOverlay>
				) : data?.responseSnapshot?.isResponseBodyTracked === false ? (
					<DatatableOverlay>
						<BlankSlate>
							<FormattedMessage {...messages.notTracked} />
						</BlankSlate>
					</DatatableOverlay>
				) : data?.responseSnapshot?.src === null ? (
					<DatatableOverlay>
						<BlankSlate>
							<FormattedMessage {...messages.notAvailable} />
						</BlankSlate>
					</DatatableOverlay>
				) : data?.responseSnapshot?.src?.isTooLarge ? (
					<DatatableOverlay>
						<BlankSlate>
							<FormattedMessage {...messages.tooLarge} />
						</BlankSlate>
					</DatatableOverlay>
				) : null
			}
		>
			<CodeViewer
				height={height}
				lines={lines}
				longestLine={longestLine}
				truncateLineAt={1000}
				truncatePopup={(
					<FormattedMessage {...messages.truncatedLinePopup} />
				)}
				width={width}
			/>
		</DatatableContainer>
	);
};



export default PageSnapshotResponseBody;
