import React, {
	Component,
} from 'react';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import ActiveFilter from '../../filters/ActiveFilter';

import {
	updateFilter as affectedPagesUpdateFilter,
} from '~/actions/affectedPages';

import {
	removeDefaultFilterValues as affectedPagesRemoveDefaultFilterValues,
} from '~/model/affectedPages';

import {
	currentFilterSelector as affectedPagesFilterSelector,
	categorySelector as affectedPagesIssueCategorySelector,
	singleTypeSelector as affectedPagesIssueTypeSelector,
} from '~/state/affectedPages/selectors';



const select = createSelector(
	affectedPagesFilterSelector,
	affectedPagesIssueCategorySelector,
	affectedPagesIssueTypeSelector,
	(
		affectedPagesFilter,
		affectedPagesIssueCategory,
		affectedPagesIssueType,
	) => {
		return {
			affectedPagesFilter,
			affectedPagesIssueCategory,
			affectedPagesIssueType,
		};
	},
);



class AffectedPagesFilterBar extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleRemoveFilter = this._handleRemoveFilter.bind(this);
	}



	_handleRemoveFilter(filterId) {
		const {
			dispatch,
		} = this.props;

		dispatch(
			affectedPagesUpdateFilter({
				[filterId]: undefined,
			}),
		);
	}



	render() {
		const {
			affectedPagesFilter,
			affectedPagesIssueCategory,
			affectedPagesIssueType,
			onFilterClickCallback,
		} = this.props;

		const activeFilters = affectedPagesRemoveDefaultFilterValues(
			affectedPagesFilter,
			affectedPagesIssueCategory,
			affectedPagesIssueType,
		);

		return (
			<ActiveFilter
				activeFilters={activeFilters}
				clearLink={false}
				filter={affectedPagesFilter}
				onFilterClickCallback={onFilterClickCallback}
				removeFilterCallback={this._handleRemoveFilter}
				segmentsManagement={false}
			/>
		);
	}

}



export default connect(select)(AffectedPagesFilterBar);
