import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ComplexListField, {
	type ComplexListFieldMaximumNumberOfRowsReachedTooltipInput,
	type ComplexListFieldRef,
	type ComplexListFieldRendererInput,
	type ComplexListFieldValidationInput,
} from '~/components/logic/customElements/extractionSteps/ComplexListField';
import TransformationField from './TransformationField';
import TransformationParameterFields from '~/components/logic/customElements/transformationSteps/TransformationParameterFields';

import {
	type DATA_TYPE_BOOLEAN,
	type DATA_TYPE_DATE,
	type DATA_TYPE_HASH,
	type DATA_TYPE_INTEGER,
	type DATA_TYPE_NUMBER,
	DATA_TYPE_STRING,
	detectTransformationOutputDataType,
	isTransformationCompatibleWithDataType,
} from '~/model/customElements';

import {
	isNotFalse,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	addButton: {
		id: 'ui.customElements.form.fieldsets.transformationSteps.addAnotherStepButton',
	},
	columnParameters: {
		id: 'ui.customElements.transformationSteps.header.parameters',
	},
	columnStep: {
		id: 'ui.customElements.transformationSteps.header.step',
	},
	columnType: {
		id: 'ui.customElements.transformationSteps.header.type',
	},
	deleteStepButtonLabel: {
		id: 'ui.customElements.transformationSteps.actions.deleteStep.button',
	},
	formErrorsStringTransformationIncompatibleWithElement: {
		id: 'ui.customElements.formErrors.stringTransformationIncompatibleWithElement',
	},
	formErrorsStringTransformationIncompatibleWithPreviousTransformation: {
		id: 'ui.customElements.formErrors.stringTransformationIncompatibleWithPreviousTransformation',
	},
	tooMany: {
		id: 'ui.customElements.transformationSteps.tooMany',
	},
});

const defaultParameters = {};

export type TransformationStepsConfiguratorRef = {
	addTransformation: () => void,
	numberOfTransformations: number,
};



type Props = {
	extractedElementDataType:
		| typeof DATA_TYPE_BOOLEAN
		| typeof DATA_TYPE_DATE
		| typeof DATA_TYPE_HASH
		| typeof DATA_TYPE_INTEGER
		| typeof DATA_TYPE_NUMBER
		| typeof DATA_TYPE_STRING
		| null,
	name: string,
};

const TransformationStepsConfigurator = React.forwardRef<TransformationStepsConfiguratorRef, Props>((props, ref) => {
	const {
		extractedElementDataType = DATA_TYPE_STRING,
		name,
	} = props;

	const fieldRef = React.useRef<ComplexListFieldRef>(null);

	React.useImperativeHandle(ref, () => ({
		addTransformation: () => {
			fieldRef.current?.addRow();
		},
		numberOfTransformations: fieldRef.current?.numberOfRows ?? 0,
	}));

	const fields = React.useMemo(
		() => {
			return [
				{
					label: (
						<FormattedMessage {...messages.columnType} />
					),
					name: 'type',
					renderer: ({ fieldName }: ComplexListFieldRendererInput) => (
						<TransformationField
							name={fieldName}
							width={200 - 8}
						/>
					),
					validation: ({ f, listItems, rowIndex }: ComplexListFieldValidationInput) => ([
						f.validateNonEmpty(),
						rowIndex === 0 && f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsStringTransformationIncompatibleWithElement} />
							),
							rule: ({ value }) => {
								return isTransformationCompatibleWithDataType(
									extractedElementDataType,
									value,
								);
							},
						}),
						rowIndex > 0 && f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsStringTransformationIncompatibleWithPreviousTransformation} />
							),
							rule: ({ value, values }) => {
								let type = extractedElementDataType;

								if (value) {
									for (const { itemValues: otherItemValues, rowIndex: otherRowIndex } of listItems(values)) {
										if (otherRowIndex === rowIndex) {
											return isTransformationCompatibleWithDataType(type, value);
										}

										const otherTransformationType = otherItemValues.type;
										const otherTransformationOptions = otherItemValues.parameters;

										if (!isTransformationCompatibleWithDataType(type, otherTransformationType)) {
											return true;
										}

										type = detectTransformationOutputDataType(
											otherTransformationType,
											otherTransformationOptions,
										);
									}
								}

								return true;
							},
						}),
					].filter(isNotFalse)),
					width: 200,
				},
				{
					defaultValue: defaultParameters,
					label: (
						<FormattedMessage {...messages.columnParameters} />
					),
					name: 'parameters',
					renderer: ({ fieldName, itemValues }: ComplexListFieldRendererInput) => (
						<TransformationParameterFields
							columnWidth={240}
							key={itemValues.type ?? null}
							name={fieldName}
							transformationType={itemValues.type ?? null}
						/>
					),
					width: 240,
				},
			];
		},
		[
			extractedElementDataType,
		],
	);

	const maximumNumberOfRowsReachedTooltip = React.useCallback(
		({ maximumNumberOfRows }: ComplexListFieldMaximumNumberOfRowsReachedTooltipInput) => (
			<FormattedMessage
				{...messages.tooMany}
				values={{
					number: maximumNumberOfRows,
				}}
			/>
		),
		[],
	);

	return (
		<ComplexListField
			addButtonLabel={(
				<FormattedMessage {...messages.addButton} />
			)}
			addExtraEmptyRow={false}
			fields={fields}
			isReorderable={true}
			maximumNumberOfRows={6}
			maximumNumberOfRowsReachedTooltip={maximumNumberOfRowsReachedTooltip}
			name={name}
			ref={fieldRef}
			showAddButton={true}
			showRowNumbers={true}
		/>
	);
});



export default TransformationStepsConfigurator;
