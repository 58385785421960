/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAdminDefaultWebsiteSettingsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountAdminDefaultWebsiteSettingsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly discoverAmpPagesByDefault: boolean, readonly trackNumericOnlyChangesByDefault: boolean, readonly trackH2ToH6HistoricallyByDefault: boolean } | null } | null };

export type UpdateAccountAdminDefaultWebsiteSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  discoverAmpPagesByDefault: GraphQL.Scalars['Boolean']['input'];
  trackH2ToH6HistoricallyByDefault: GraphQL.Scalars['Boolean']['input'];
  trackNumericOnlyChangesByDefault: GraphQL.Scalars['Boolean']['input'];
}>;


export type UpdateAccountAdminDefaultWebsiteSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateAccountAdminDefaultWebsiteSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly discoverAmpPagesByDefault: boolean, readonly trackNumericOnlyChangesByDefault: boolean, readonly trackH2ToH6HistoricallyByDefault: boolean } | null } | null } } };


export const AccountAdminDefaultWebsiteSettingsFormDocument = gql`
    query AccountAdminDefaultWebsiteSettingsForm($accountId: AccountId!) {
  account(id: $accountId) {
    adminSettings {
      discoverAmpPagesByDefault
      trackNumericOnlyChangesByDefault
      trackH2ToH6HistoricallyByDefault
    }
    id
  }
}
    `;

/**
 * __useAccountAdminDefaultWebsiteSettingsFormQuery__
 *
 * To run a query within a React component, call `useAccountAdminDefaultWebsiteSettingsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAdminDefaultWebsiteSettingsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAdminDefaultWebsiteSettingsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountAdminDefaultWebsiteSettingsFormQuery(baseOptions: Apollo.QueryHookOptions<AccountAdminDefaultWebsiteSettingsFormQuery, AccountAdminDefaultWebsiteSettingsFormQueryVariables> & ({ variables: AccountAdminDefaultWebsiteSettingsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountAdminDefaultWebsiteSettingsFormQuery, AccountAdminDefaultWebsiteSettingsFormQueryVariables>(AccountAdminDefaultWebsiteSettingsFormDocument, options);
      }
export function useAccountAdminDefaultWebsiteSettingsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountAdminDefaultWebsiteSettingsFormQuery, AccountAdminDefaultWebsiteSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountAdminDefaultWebsiteSettingsFormQuery, AccountAdminDefaultWebsiteSettingsFormQueryVariables>(AccountAdminDefaultWebsiteSettingsFormDocument, options);
        }
export function useAccountAdminDefaultWebsiteSettingsFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountAdminDefaultWebsiteSettingsFormQuery, AccountAdminDefaultWebsiteSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountAdminDefaultWebsiteSettingsFormQuery, AccountAdminDefaultWebsiteSettingsFormQueryVariables>(AccountAdminDefaultWebsiteSettingsFormDocument, options);
        }
export type AccountAdminDefaultWebsiteSettingsFormQueryHookResult = ReturnType<typeof useAccountAdminDefaultWebsiteSettingsFormQuery>;
export type AccountAdminDefaultWebsiteSettingsFormLazyQueryHookResult = ReturnType<typeof useAccountAdminDefaultWebsiteSettingsFormLazyQuery>;
export type AccountAdminDefaultWebsiteSettingsFormSuspenseQueryHookResult = ReturnType<typeof useAccountAdminDefaultWebsiteSettingsFormSuspenseQuery>;
export type AccountAdminDefaultWebsiteSettingsFormQueryResult = Apollo.QueryResult<AccountAdminDefaultWebsiteSettingsFormQuery, AccountAdminDefaultWebsiteSettingsFormQueryVariables>;
export const UpdateAccountAdminDefaultWebsiteSettingsDocument = gql`
    mutation UpdateAccountAdminDefaultWebsiteSettings($accountId: AccountId!, $discoverAmpPagesByDefault: Boolean!, $trackH2ToH6HistoricallyByDefault: Boolean!, $trackNumericOnlyChangesByDefault: Boolean!) {
  UpdateAccountAdminDefaultWebsiteSettings(
    accountId: $accountId
    discoverAmpPagesByDefault: $discoverAmpPagesByDefault
    trackH2ToH6HistoricallyByDefault: $trackH2ToH6HistoricallyByDefault
    trackNumericOnlyChangesByDefault: $trackNumericOnlyChangesByDefault
  ) {
    query {
      account(id: $accountId) {
        adminSettings {
          discoverAmpPagesByDefault
          trackNumericOnlyChangesByDefault
          trackH2ToH6HistoricallyByDefault
        }
        id
      }
    }
  }
}
    `;
export type UpdateAccountAdminDefaultWebsiteSettingsMutationFn = Apollo.MutationFunction<UpdateAccountAdminDefaultWebsiteSettingsMutation, UpdateAccountAdminDefaultWebsiteSettingsMutationVariables>;

/**
 * __useUpdateAccountAdminDefaultWebsiteSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountAdminDefaultWebsiteSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountAdminDefaultWebsiteSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountAdminDefaultWebsiteSettingsMutation, { data, loading, error }] = useUpdateAccountAdminDefaultWebsiteSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      discoverAmpPagesByDefault: // value for 'discoverAmpPagesByDefault'
 *      trackH2ToH6HistoricallyByDefault: // value for 'trackH2ToH6HistoricallyByDefault'
 *      trackNumericOnlyChangesByDefault: // value for 'trackNumericOnlyChangesByDefault'
 *   },
 * });
 */
export function useUpdateAccountAdminDefaultWebsiteSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountAdminDefaultWebsiteSettingsMutation, UpdateAccountAdminDefaultWebsiteSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountAdminDefaultWebsiteSettingsMutation, UpdateAccountAdminDefaultWebsiteSettingsMutationVariables>(UpdateAccountAdminDefaultWebsiteSettingsDocument, options);
      }
export type UpdateAccountAdminDefaultWebsiteSettingsMutationHookResult = ReturnType<typeof useUpdateAccountAdminDefaultWebsiteSettingsMutation>;
export type UpdateAccountAdminDefaultWebsiteSettingsMutationResult = Apollo.MutationResult<UpdateAccountAdminDefaultWebsiteSettingsMutation>;
export type UpdateAccountAdminDefaultWebsiteSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountAdminDefaultWebsiteSettingsMutation, UpdateAccountAdminDefaultWebsiteSettingsMutationVariables>;