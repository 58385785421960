import React from 'react';



type Props = {
	/** Sublabel with additional info */
	additionalInfo?: React.ReactNode,
	/** Label of displayed billing cycle */
	billingCycleLabel: React.ReactNode,
};



const BillingCycleSwitchLabel: React.FC<Props> = (props) => {
	const {
		additionalInfo,
		billingCycleLabel,
	} = props;

	return (
		<div className="billing-cycle-switch-label">
			<div className="billing-cycle-switch-label__label">
				{billingCycleLabel}
			</div>
			{additionalInfo && (
				<div className="billing-cycle-switch-label__additional-info">
					{additionalInfo}
				</div>
			)}
		</div>
	);
};



export default BillingCycleSwitchLabel;
