import {
	Map,
} from 'immutable';

import {
	INITIALIZE_FROM_LOCAL_STORAGE,
	STORE_LOADED_TEAMS,
} from '~/actions';

import {
	FeatureFlag,
	FeatureFlagValue,
	RESET_FEATURE_FLAGS,
	TOGGLE_FEATURE_FLAG,
} from '~/featureFlags';



function createDefaultState() {
	const initialState = {};

	Object.values(FeatureFlag).forEach((featureFlag) => {
		initialState[featureFlag] = FeatureFlagValue.Unknown;
	});

	return Map(initialState);
}



export function featureFlags(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case INITIALIZE_FROM_LOCAL_STORAGE: {
			const {
				data,
			} = action;

			data.get('featureFlags', Map()).forEach((value, featureFlag) => {
				if (Object.values(FeatureFlag).includes(featureFlag)) {
					if (value === true) {
						value = FeatureFlagValue.Positive;
					} else if (value === false) {
						value = FeatureFlagValue.Negative;
					} else if (value === null) {
						value = FeatureFlagValue.Unknown;
					}

					state = state.set(featureFlag, value);

					if (data.hasIn(['featureFlags', featureFlag + '_dueToBackend'])) {
						state = state.set(featureFlag + '_dueToBackend', data.getIn(['featureFlags', featureFlag + '_dueToBackend']));
					}
				}
			});

			break;
		}

		case RESET_FEATURE_FLAGS: {
			state = createDefaultState();

			break;
		}

		case STORE_LOADED_TEAMS: {
			const {
				teams,
			} = action;

			for (const teamId in teams) {
				if (teams.hasOwnProperty(teamId) && teams[teamId].is_home) {
					const team = teams[teamId];

					team.feature_flags.forEach((featureFlag) => {
						if (!state.get(featureFlag + '_dueToBackend')) {
							state = state.set(featureFlag, FeatureFlagValue.Positive);
							state = state.set(featureFlag + '_dueToBackend', true);
						}
					});

					state.forEach((value, featureFlag) => {
						if (
							state.get(featureFlag + '_dueToBackend')
							&& team.feature_flags.indexOf(featureFlag) === -1
						) {
							state = state.set(featureFlag, FeatureFlagValue.Negative);
							state = state.remove(featureFlag + '_dueToBackend');
						}
					});

					break;
				}
			}

			break;
		}

		case TOGGLE_FEATURE_FLAG: {
			const {
				featureFlag,
				value,
			} = action;

			state = state.set(featureFlag, value);

			break;
		}

	}

	return state;
}
