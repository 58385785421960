import {
	loadTariffsViaApi,
} from '~/model/pricing/tariffs';

import {
	loadingSelector,
} from '~/state/tariffs/selectors';



export const ACTION_TARIFFS_START_LOADING_TARIFFS = 'ACTION_TARIFFS_START_LOADING_TARIFFS';
export const ACTION_TARIFFS_STORE_TARIFFS = 'ACTION_TARIFFS_STORE_TARIFFS';



export function loadTariffs() {
	return (dispatch, getState) => {
		const isLoading = loadingSelector(getState());

		if (!isLoading) {
			dispatch({
				type: ACTION_TARIFFS_START_LOADING_TARIFFS,
			});

			return loadTariffsViaApi()
				.then((response) => {
					dispatch({
						type: ACTION_TARIFFS_STORE_TARIFFS,
						tariffs: response.tariffs,
					});
				});
		}

		return Promise.resolve();
	};
}
