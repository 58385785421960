import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Enable gaps around content */
	gaps?: boolean,
};



const FormWrapperContent: React.FC<Props> = (props) => {
	const {
		gaps = true,
		children,
	} = props;

	const componentClasses = classNames({
		'form-wrapper-content': true,
		'form-wrapper-content--has-gaps': gaps,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default FormWrapperContent;
