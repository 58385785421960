import React from 'react';
import {
	type State,
} from 'zustand';

import createStore from '~/createStore';

import {
	isFunction,
} from '~/utilities/typeCheck';



type PropertyUpdate<TState, Property extends string & keyof TState> = TState[Property] | ((propertyState: TState[Property]) => TState[Property]);

type SetProperty<TState> = <Property extends string & keyof TState>(property: Property, value: PropertyUpdate<TState, Property>) => void;



export type SettableStore<TState> = TState & {
	setProperty: SetProperty<TState>,
};

export type KeyedStore<TKey extends number | string, TState> = {
	stores: Record<TKey, TState | null>,
	getStore: (key: TKey) => TState,
	setProperty: <Property extends string & keyof TState>(property: Property, key: TKey, value: PropertyUpdate<TState, Property>) => void,
};



function createKeyedStore<TKey extends number | string, TState extends State & {}>(createDefaultState: () => TState) {
	const defaultState = createDefaultState();

	const useStore = createStore<KeyedStore<TKey, TState>>(({ get, modify }) => ({
		stores: ({} as KeyedStore<TKey, TState>['stores']),

		getStore: (key) => get().stores[key] ?? defaultState,

		setProperty: (property, key, newValue) => modify((state) => {
			const store = state.stores[key] ?? createDefaultState();

			state.stores[key] = {
				...store,
				[property]: isFunction(newValue)
					? newValue(store[property])
					: newValue,
			};
		}),
	}));

	function useKeyedStore(
		key: TKey,
	): SettableStore<TState> {
		const keyedStore = useStore();

		const store = keyedStore.getStore(key);

		const setKeyedStoreProperty = keyedStore.setProperty;

		const setPropertyCallback: SetProperty<TState> = (property, value) => {
			setKeyedStoreProperty(property, key, value);
		};

		const setProperty = React.useCallback(
			setPropertyCallback,
			[
				key,
				setKeyedStoreProperty,
			],
		);

		return React.useMemo(
			() => ({
				...store,
				setProperty,
			}),
			[
				setProperty,
				store,
			],
		);
	}

	return useKeyedStore;
}



export default createKeyedStore;
