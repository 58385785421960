import type CK from '~/types/contentking';

import {
	useWebsiteIsHttpAuthenticationEnabledQuery,
} from './useWebsiteIsHttpAuthenticationEnabled.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteIsHttpAuthenticationEnabled(websiteId: CK.WebsiteId | null): boolean | null {
	const { data } = useWebsiteIsHttpAuthenticationEnabledQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.isHttpAuthenticationEnabled) ?? null;
}



export default useWebsiteIsHttpAuthenticationEnabled;
