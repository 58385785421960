import React from 'react';

import type CK from '~/types/contentking';

import AppearAnimation, {
	AppearAnimationDirection,
} from '~/components/patterns/animations/AppearAnimation';
import CodeViewer from '~/components/patterns/tables/datatables/CodeViewer';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import RobotsTxtComparisonAnnotation from '~/components/app/RobotsTxtComparisonAnnotation';
import RobotsTxtComparisonHeader from '~/components/app/RobotsTxtComparisonHeader';
import RobotsTxtComparisonSidebar from '~/components/app/RobotsTxtComparisonSidebar';
import ScreenBodyLayout, {
	ScreenBodyLayoutPosition,
} from '~/components/patterns/screens/parts/body/ScreenBodyLayout';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import Spinner from '~/components/patterns/loaders/Spinner';
import StickToScreenBottom, {
	StickToScreenBottomPreset,
} from '~/components/patterns/utils/StickToScreenBottom';

import {
	useRobotsTxtComparisonScreenQuery,
} from '~/components/app/RobotsTxtComparisonScreen.gql';

import useBatchContextRobotsTxt from '~/hooks/useBatchContextRobotsTxt';
import useNavigation from '~/hooks/useNavigation';
import useUrlState from '~/hooks/useUrlState';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	packRobotsTxtRevisionsParameter,
	unpackRobotsTxtRevisionsParameter,
} from '~/model/robotsTxt';



type Props = {
	backlink?: string,
	closeCallback?: () => void,
};

const RobotsTxtComparisonScreen: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
	} = props;

	const navigation = useNavigation();
	const urlState = useUrlState();
	const viewportType = useViewportType();
	const websiteId = useWebsiteId();

	const [showMobileFilter, setShowMobileFilter] = React.useState(false);

	const {
		revisionA: nextRevisionId,
		revisionB: previousRevisionId,
	} = unpackRobotsTxtRevisionsParameter(urlState.params.revisions ?? '');

	const { data } = useRobotsTxtComparisonScreenQuery({
		context: useBatchContextRobotsTxt(websiteId),
		variables: {
			nextRevisionId: nextRevisionId ?? '',
			previousRevisionId: previousRevisionId ?? '',
			websiteId,
		},
		returnPartialData: true,
		skip: nextRevisionId === null || previousRevisionId === null,
	});

	const nextRevision = data?.comparison?.nextRevision ?? null;
	const previousRevision = data?.comparison?.previousRevision ?? null;

	const handleComparisonSelect = React.useCallback(
		(options: { nextRevisionId: CK.ID, previousRevisionId: CK.ID }) => {
			navigation.navigate({
				keepParameters: true,
				routeName: urlState.name,
				routeParams: {
					revisions: packRobotsTxtRevisionsParameter(options.nextRevisionId, options.previousRevisionId),
				},
			});
		},
		[
			navigation,
			urlState,
		],
	);

	function renderComparison() {
		return (
			<React.Fragment key={data?.comparison?.id}>
				<RobotsTxtComparisonAnnotation
					isNextContentEmpty={nextRevision?.isContentEmpty ?? false}
					lines={(data?.comparison?.rows as any) ?? []}
					nextFailureReason={nextRevision?.responseFailureReason ?? null}
					nextStatusCode={nextRevision?.responseStatusCode ?? null}
					previousFailureReason={previousRevision?.responseFailureReason ?? null}
					previousStatusCode={previousRevision?.responseStatusCode ?? null}
				/>

				<StickToScreenBottom preset={StickToScreenBottomPreset.Fullscreen}>
					{({ height, width }) => (
						<DisabledContent
							disabledContent={!data?.comparison?.rows}
							disabledOverlay={(
								<Spinner />
							)}
						>
							<CodeViewer
								height={height}
								lines={(data?.comparison?.rows as any) ?? []}
								longestLine={data?.comparison?.longestRowCharacters ?? 0}
								width={width}
							/>
						</DisabledContent>
					)}
				</StickToScreenBottom>
			</React.Fragment>
		);
	}

	return (
		<ScreenLayout
			header={(
				<RobotsTxtComparisonHeader
					backlink={backlink}
					closeCallback={closeCallback}
					nextRevisionTimestamp={nextRevision?.createdAt ?? null}
					onMobileFilterButtonClick={() => setShowMobileFilter((showMobileFilter) => !showMobileFilter)}
					previousRevisionTimestamp={previousRevision?.createdAt ?? null}
				/>
			)}
		>
			{viewportType.isSmall ? (
				showMobileFilter ? (
					<AppearAnimation direction={AppearAnimationDirection.Down}>
						<RobotsTxtComparisonSidebar
							nextRevisionId={nextRevisionId}
							onComparisonSelect={handleComparisonSelect}
							previousRevisionId={previousRevisionId}
						/>
					</AppearAnimation>
				) : (
					renderComparison()
				)
			) : (
				<ScreenBodyLayout
					hideContentScrollbar={true}
					hideSidebarScrollbar={true}
					sidebar={(
						<RobotsTxtComparisonSidebar
							nextRevisionId={nextRevisionId}
							onComparisonSelect={handleComparisonSelect}
							previousRevisionId={previousRevisionId}
						/>
					)}
					sidebarPosition={ScreenBodyLayoutPosition.Left}
					sidebarWidth={viewportType.isMedium ? 240 : 260}
				>
					{renderComparison()}
				</ScreenBodyLayout>
			)}
		</ScreenLayout>
	);
};



export default RobotsTxtComparisonScreen;
