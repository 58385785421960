import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InstallAkamaiDataStreamModal from '~/components/app/InstallAkamaiDataStreamModal';
import InstallCloudflareWorkerModal from '~/components/app/InstallCloudflareWorkerModal';
import InstallCloudfrontStandardLoggingModal from '~/components/app/InstallCloudfrontStandardLoggingModal';
import InstallFastlyLogStreamingModal from '~/components/app/InstallFastlyLogStreamingModal';
import InternalLink from '~/components/patterns/links/InternalLink';
import LogFileAnalysisLogSourceStillPresentWarning from '~/components/app/LogFileAnalysisLogSourceStillPresentWarning';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import StaticText from '~/components/atoms/forms/components/StaticText';

import useModals from '~/hooks/useModals';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';
import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	[GraphQL.LogSourceProvider.Akamai]: {
		id: 'ui.logFileAnalysisLogSourceForm.akamaiDataStream',
	},
	[GraphQL.LogSourceProvider.Cloudflare]: {
		id: 'ui.logFileAnalysisLogSourceForm.cloudflareWorker',
	},
	[GraphQL.LogSourceProvider.Cloudfront]: {
		id: 'ui.logFileAnalysisLogSourceForm.cloudfrontStandardLogging',
	},
	[GraphQL.LogSourceProvider.Fastly]: {
		id: 'ui.logFileAnalysisLogSourceForm.fastlyRealtimeLogStreaming',
	},
	akamaiExtraUpsell: {
		id: 'ui.logFileAnalysisLogSourceForm.akamaiExtraUpsell',
	},
	available: {
		id: 'ui.general.available',
	},
	cloudfrontExtraUpsell: {
		id: 'ui.logFileAnalysisLogSourceForm.cloudfrontExtraUpsell',
	},
	enableLogFileAnalysisWarning: {
		id: 'ui.logFileAnalysisLogSourceForm.enableLogFileAnalysisWarning',
	},
	fastlyExtraUpsell: {
		id: 'ui.logFileAnalysisLogSourceForm.fastlyExtraUpsell',
	},
	install: {
		id: 'ui.logFileAnalysisLogSourceForm.install',
	},
	installed: {
		id: 'ui.general.installed',
	},
	reinstall: {
		id: 'ui.logFileAnalysisLogSourceForm.reinstall',
	},
	title: {
		id: 'ui.logFileAnalysisLogSourceForm.title',
	},
	unavailable: {
		id: 'ui.general.unavailable',
	},
});



type Props = {
	provider: (
		| GraphQL.LogSourceProvider.Akamai
		| GraphQL.LogSourceProvider.Cloudflare
		| GraphQL.LogSourceProvider.Cloudfront
		| GraphQL.LogSourceProvider.Fastly
	),
};

const LogFileAnalysisLogSourceForm: React.FC<Props> = (props) => {
	const {
		provider,
	} = props;

	const modals = useModals();
	const websiteId = useWebsiteId();

	const {
		isFeatureEnabled: isLogFileAnalysisFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const {
		getStatusForLogSource,
		isLogFileAnalysisEnabled,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const isAllowedToManageLogFileAnalysis = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageLogFileAnalysis,
	);

	const providerStatus = getStatusForLogSource(provider);

	const renderOpenModalLink = React.useCallback(
		(chunks) => {
			const modal = matchAndReturn(provider, {
				[GraphQL.LogSourceProvider.Akamai]: () => <InstallAkamaiDataStreamModal />,
				[GraphQL.LogSourceProvider.Cloudflare]: () => <InstallCloudflareWorkerModal />,
				[GraphQL.LogSourceProvider.Cloudfront]: () => <InstallCloudfrontStandardLoggingModal />,
				[GraphQL.LogSourceProvider.Fastly]: () => <InstallFastlyLogStreamingModal />,
			});

			return (
				<InternalLink
					onClickCallback={() => {
						modals.openModal(modal);
					}}
				>
					{chunks}
				</InternalLink>
			);
		},
		[
			modals,
			provider,
		],
	);

	const isManagable = (
		isLogFileAnalysisEnabled
		&& isAllowedToManageLogFileAnalysis.yes
	);

	const showStillPresentWarning = (
		!isLogFileAnalysisEnabled
		&& isAllowedToManageLogFileAnalysis.yes
		&& providerStatus === GraphQL.LogSourceStatus.Enabled
	);

	const showEnableLogFileAnalysisWarning = (
		!isLogFileAnalysisEnabled
		&& isAllowedToManageLogFileAnalysis.yes
		&& isLogFileAnalysisFeatureEnabled
		&& providerStatus === GraphQL.LogSourceStatus.Available
	);

	const requiredFeatureName = matchAndReturn(provider, {
		[GraphQL.LogSourceProvider.Akamai]: GraphQL.AccountFeature.AkamaiLogSource,
		[GraphQL.LogSourceProvider.Cloudflare]: GraphQL.AccountFeature.LogFileAnalysis,
		[GraphQL.LogSourceProvider.Cloudfront]: GraphQL.AccountFeature.CloudfrontLogSource,
		[GraphQL.LogSourceProvider.Fastly]: GraphQL.AccountFeature.FastlyLogSource,
	});

	const extraAnnotation = matchAndReturn(provider, {
		[GraphQL.LogSourceProvider.Akamai]: (
			<FormattedMessage {...messages.akamaiExtraUpsell} />
		),
		[GraphQL.LogSourceProvider.Cloudflare]: null,
		[GraphQL.LogSourceProvider.Cloudfront]: (
			<FormattedMessage {...messages.cloudfrontExtraUpsell} />
		),
		[GraphQL.LogSourceProvider.Fastly]: (
			<FormattedMessage {...messages.fastlyExtraUpsell} />
		),
	});

	return (
		<PremiumFeatureSituation
			extraAnnotation={extraAnnotation}
			featureName={requiredFeatureName}
			hideIfUnattainable={false}
			showUnavailablePlan={true}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => (
				<EditableFormWrapper
					isReadOnly={true}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							<DisabledContent
								disabledContent={!isFeatureEnabled}
								disabledOverlay={!isFeatureEnabled && premiumAnnotation}
							>
								<FormRow
									description={(
										showEnableLogFileAnalysisWarning ? (
											<Copy
												{...messages.enableLogFileAnalysisWarning}
												values={{
													provider: <FormattedMessage {...messages[provider]} />,
												}}
											/>
										) : (
											null
										)
									)}
									label={(
										<FormattedMessage {...messages[provider]} />
									)}
								>
									{isManagable && (
										<StaticText>
											{providerStatus === GraphQL.LogSourceStatus.Enabled ? (
												<FormattedMessage
													{...messages.reinstall}
													values={{
														openModal: renderOpenModalLink,
													}}
												/>
											) : providerStatus === GraphQL.LogSourceStatus.Available ? (
												<FormattedMessage
													{...messages.install}
													values={{
														openModal: renderOpenModalLink,
													}}
												/>
											) : (
												<FormattedMessage {...messages.unavailable} />
											)}
										</StaticText>
									)}

									{!isManagable && (
										<StaticText>
											{providerStatus === GraphQL.LogSourceStatus.Enabled ? (
												<FormattedMessage {...messages.installed} />
											) : providerStatus === GraphQL.LogSourceStatus.Available ? (
												<FormattedMessage {...messages.available} />
											) : (
												<FormattedMessage {...messages.unavailable} />
											)}
										</StaticText>
									)}
								</FormRow>
							</DisabledContent>

							{showStillPresentWarning && (
								<LogFileAnalysisLogSourceStillPresentWarning provider={provider} />
							)}
						</FormRows>

					</DisplayPart>
				</EditableFormWrapper>
			)
			}
		</PremiumFeatureSituation>
	);
};



export default LogFileAnalysisLogSourceForm;
