import differenceInDays from 'date-fns/differenceInDays';
import {
	selectUnit,
} from '@formatjs/intl-utils';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	FormattedRelativeTime,
	defineMessages,
} from 'react-intl';

import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import LabeledValue, {
	LabeledValueContentAlignment,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';



const messages = defineMessages({
	domain: {
		id: 'ui.alerts.overview.incident.details.sslCertificate.domain',
	},
	expiration: {
		id: 'ui.alerts.overview.incident.details.sslCertificate.expiration',
	},
	onDate: {
		id: 'ui.general.onDate',
	},
	renewed: {
		id: 'ui.alerts.overview.incident.details.sslCertificate.renewed',
	},
});



class SslCertificateExpiringSoonDetails extends Component {

	_renderExpiresAt(timeCertificateExpiresAt) {
		const diffInDays = differenceInDays(
			new Date(timeCertificateExpiresAt * 1000),
			new Date(),
		);

		if (diffInDays > 2) {
			return (
				<FormattedRelativeTime
					style="short"
					unit="day"
					value={diffInDays}
				/>
			);
		}

		const {
			value,
			unit,
		} = selectUnit(timeCertificateExpiresAt * 1000);

		return (
			<FormattedRelativeTime
				unit={unit}
				value={value}
			/>
		);
	}



	render() {
		const {
			incident,
		} = this.props;

		const isIncidentActive = incident.get('phase') === 'active';

		const details = [];

		if (incident.getIn(['state', 'affected_domains']).size > 0) {
			incident.getIn(['state', 'affected_domains']).map((affectedDomain) => {
				details.push(
					<LabeledValuesList key={affectedDomain.get('domain')}>
						<LabeledValue
							contentAlignment={LabeledValueContentAlignment.Left}
							label={(
								<FormattedMessage {...messages.domain} />
							)}
						>
							{affectedDomain.get('domain')}
						</LabeledValue>

						<LabeledValue
							contentAlignment={LabeledValueContentAlignment.Left}
							label={(
								<FormattedMessage
									{...messages.expiration}
									values={{
										bool_isExpired: affectedDomain.get('is_expired') ? 'yes' : 'no',
									}}
								/>
							)}
						>
							{isIncidentActive && this._renderExpiresAt(affectedDomain.get('time_certificate_expires_at'))}

							{!isIncidentActive && (
								<FormattedMessage
									{...messages.onDate}
									values={{
										date: affectedDomain.get('time_certificate_expires_at') * 1000,
									}}
								/>
							)}
						</LabeledValue>
					</LabeledValuesList>,
				);
			});
		} else {
			details.push(
				<SeparatedBlocks key="renewed">
					<FormattedMessage {...messages.renewed} />
				</SeparatedBlocks>,
			);
		}

		return (
			<IncidentBoxContent>
				{details}
			</IncidentBoxContent>
		);
	}

}

SslCertificateExpiringSoonDetails.propTypes = {
	incident: PropTypes.object.isRequired,
};



export default SslCertificateExpiringSoonDetails;
