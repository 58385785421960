import React from 'react';

import CK from '~/types/contentking';

import AlertPagesDatatable, {
	type AlertPagesDatatableIncident,
	type AlertPagesDatatableRef,
} from '~/components/logic/alertPages/AlertPagesDatatable';
import ColumnName from '../../names/ColumnName';
import LinksBrokenCellValue from '../datatables/cellValues/LinksBrokenCellValue';

import {
	IssueCategoryName,
} from '~/model/issuesNew';



type Props = {
	incident: AlertPagesDatatableIncident,
	scrollCallback: () => void,
};

const IssueLinksBrokenDatatable = React.forwardRef<AlertPagesDatatableRef, Props>((props, ref) => {
	const {
		incident,
		scrollCallback,
	} = props;

	const linkRouteParams = React.useMemo(
		() => ({
			pageDetailIssuesCategoryType: IssueCategoryName.Links,
		}),
		[],
	);

	const extraColumns = React.useMemo(
		() => {
			return [
				{
					name: 'links_to_missing',
					render: {
						cell({ row }) {
							return (
								<LinksBrokenCellValue value={row.get('links_to_missing')} />
							);
						},
						label: () => (
							<ColumnName column={CK.PagesCommonColumn.NumberOfLinksToBrokenPages} />
						),
					},
					width: 250,
				},
			];
		},
		[],
	);

	return (
		<AlertPagesDatatable
			extraColumns={extraColumns}
			incident={incident}
			linkRouteName="website.pages.detail.issuesCategory"
			linkRouteParams={linkRouteParams}
			ref={ref}
			scrollCallback={scrollCallback}
			showImportance={true}
		/>
	);
});



export default IssueLinksBrokenDatatable;
