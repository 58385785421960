/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserIsEligibleForRoyalAccessQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserIsEligibleForRoyalAccessQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly isEligibleForRoyalAccess: boolean, readonly legacyId: string } | null };


export const UserIsEligibleForRoyalAccessDocument = gql`
    query UserIsEligibleForRoyalAccess($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    isEligibleForRoyalAccess
    legacyId
  }
}
    `;

/**
 * __useUserIsEligibleForRoyalAccessQuery__
 *
 * To run a query within a React component, call `useUserIsEligibleForRoyalAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIsEligibleForRoyalAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIsEligibleForRoyalAccessQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserIsEligibleForRoyalAccessQuery(baseOptions: Apollo.QueryHookOptions<UserIsEligibleForRoyalAccessQuery, UserIsEligibleForRoyalAccessQueryVariables> & ({ variables: UserIsEligibleForRoyalAccessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIsEligibleForRoyalAccessQuery, UserIsEligibleForRoyalAccessQueryVariables>(UserIsEligibleForRoyalAccessDocument, options);
      }
export function useUserIsEligibleForRoyalAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIsEligibleForRoyalAccessQuery, UserIsEligibleForRoyalAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIsEligibleForRoyalAccessQuery, UserIsEligibleForRoyalAccessQueryVariables>(UserIsEligibleForRoyalAccessDocument, options);
        }
export function useUserIsEligibleForRoyalAccessSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserIsEligibleForRoyalAccessQuery, UserIsEligibleForRoyalAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserIsEligibleForRoyalAccessQuery, UserIsEligibleForRoyalAccessQueryVariables>(UserIsEligibleForRoyalAccessDocument, options);
        }
export type UserIsEligibleForRoyalAccessQueryHookResult = ReturnType<typeof useUserIsEligibleForRoyalAccessQuery>;
export type UserIsEligibleForRoyalAccessLazyQueryHookResult = ReturnType<typeof useUserIsEligibleForRoyalAccessLazyQuery>;
export type UserIsEligibleForRoyalAccessSuspenseQueryHookResult = ReturnType<typeof useUserIsEligibleForRoyalAccessSuspenseQuery>;
export type UserIsEligibleForRoyalAccessQueryResult = Apollo.QueryResult<UserIsEligibleForRoyalAccessQuery, UserIsEligibleForRoyalAccessQueryVariables>;