import React from 'react';

import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';
import WebVitalName from '~/components/app/WebVitalName';
import WebVitalFormatter from '~/components/app/WebVitalFormatter';

import {
	PageIssue,
} from '~/model/issuesNew';

import {
	WebVital,
} from '~/model/webVitals';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	issue: {
		context: any,
		name: PageIssue.LighthouseCls | PageIssue.LighthouseFcp | PageIssue.LighthouseLcp | PageIssue.LighthousePerformance | PageIssue.LighthouseSi | PageIssue.LighthouseTbt | PageIssue.LighthouseTti,
	},
	tableWidth: number,
};

const LighthouseWebVitalsTable: React.FC<Props> = (props) => {
	const {
		issue,
		tableWidth,
	} = props;

	const firstColumnWidth = Math.ceil(tableWidth * 2 / 3);

	const details = issue.context;
	const type = matchAndReturn(issue.name, {
		[PageIssue.LighthouseCls]: WebVital.CLS,
		[PageIssue.LighthouseFcp]: WebVital.FCP,
		[PageIssue.LighthouseLcp]: WebVital.LCP,
		[PageIssue.LighthousePerformance]: WebVital.Performance,
		[PageIssue.LighthouseSi]: WebVital.SI,
		[PageIssue.LighthouseTbt]: WebVital.TBT,
		[PageIssue.LighthouseTti]: WebVital.TTI,
	});

	const rows = React.useMemo(
		() => {
			if (details === null) {
				return null;
			}

			return [
				details,
			];
		},
		[
			details,
		],
	);

	if (rows === null) {
		return null;
	}

	return (
		<SmallTable
			columns={[
				{
					hasData: () => true,
					render: {
						cell: () => {
							return (
								<WebVitalName
									showCoreWebVitalFlag={true}
									type={type}
								/>
							);
						},
						header: () => 'Metric',
					},
					width: firstColumnWidth,
				},
				{
					hasData: () => true,
					render: {
						cell: ({ row }) => {
							return (
								<WebVitalFormatter
									showStatusIndicator={true}
									type={type}
									value={row.value}
									verdict={row.range}
								/>
							);
						},
						header: () => 'Value',
					},
					width: tableWidth - firstColumnWidth - 2,
				},
			]}
			rows={rows}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(LighthouseWebVitalsTable);
