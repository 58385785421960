import React from 'react';
import {
	useSelector,
} from 'react-redux';

import ComparisonIssueDetail from '~/components/logic/issuesOverview/issueDetail/ComparisonIssueDetail';
import HistoricalIssuesCategoryChangesBadge, {
	HistoricalIssuesCategoryChangesBadgeType,
} from '~/components/app/IssuesScreen/HistoricalIssuesCategoryChangesBadge';
import IssueCategoryNameIcon, {
	IssueCategoryNameIconSize,
} from '~/components/app/IssueCategoryNameIcon';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import IssueHealthImpact, {
	IssueHealthImpactAbout,
	IssueHealthImpactFlow,
	IssueHealthImpactScope,
	IssueHealthImpactSize,
} from '~/components/logic/issues/IssueHealthImpact';
import IssuesDetailLayout from '~/components/atoms/issues/components/issueDetail/IssuesDetailLayout';

import useGetIssueCategory from '~/hooks/useGetIssueCategory';
import useHistoricalIssuesComparison from '~/hooks/useHistoricalIssuesComparison';
import useSortedIssues from '~/hooks/useSortedIssues';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	isRecognizedIssue,
} from '~/model/issuesNew';

import {
	sortIssuesComparison,
} from '~/model/websiteIssuesComparison';

import {
	issuesOverviewCategorySelector,
} from '~/state/ui/content/selectors';

import {
	filterSelector as issuesComparisonFilterSelector,
} from '~/state/websiteIssuesComparison/selectors';



const ComparisonCategoryDetail = () => {
	const websiteId = useWebsiteId();

	const filter = useSelector(issuesComparisonFilterSelector);
	const historicalIssuesComparison = useHistoricalIssuesComparison(websiteId);
	const openedIssueCategory = useSelector(issuesOverviewCategorySelector);

	const issueCategory = useGetIssueCategory(
		historicalIssuesComparison,
		openedIssueCategory,
	);

	const sortedIssues = useSortedIssues(
		issueCategory?.issues ?? null,
		sortIssuesComparison,
	);

	if (issueCategory === null) {
		return (
			<IssuesDetailLayout
				isLoading={true}
			/>
		);
	}

	const issuesDetails: Array<React.ReactNode> = [];

	sortedIssues.filter(isRecognizedIssue).forEach((issue) => {
		issuesDetails.push((
			<ComparisonIssueDetail
				issue={issue}
				issueCategoryName={issueCategory.name}
				key={issue.name}
			/>
		));
	});

	return (
		<IssuesDetailLayout
			actionElements={(
				<>
					<HistoricalIssuesCategoryChangesBadge
						inList={false}
						issueCategory={issueCategory}
						type={HistoricalIssuesCategoryChangesBadgeType.Opened}
					/>

					<HistoricalIssuesCategoryChangesBadge
						inList={false}
						issueCategory={issueCategory}
						type={HistoricalIssuesCategoryChangesBadgeType.Resolved}
					/>

					<IssueHealthImpact
						about={IssueHealthImpactAbout.IssueCategory}
						flow={IssueHealthImpactFlow.Row}
						issue={issueCategory}
						key={issueCategory.name}
						scope={filter.scope === 'website' ? IssueHealthImpactScope.Website : IssueHealthImpactScope.Segment}
						size={IssueHealthImpactSize.Large}
					/>
				</>
			)}
			icon={(
				<IssueCategoryNameIcon
					issueCategoryName={issueCategory.name}
					size={IssueCategoryNameIconSize.Small}
				/>
			)}
			title={(
				<IssueCategoryTitle
					issueCategoryName={issueCategory.name}
				/>
			)}
		>
			{issuesDetails}
		</IssuesDetailLayout>
	);
};



export default ComparisonCategoryDetail;
