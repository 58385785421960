import classNames from 'classnames';
import React from 'react';
import {
	TransitionGroup,
} from 'react-transition-group';

import PanelAnimation from '~/components/atoms/animations/PanelAnimation';

import {
	ScrollContainerProvider,
} from '~/hooks/useScrollContainer';



export enum ScreenBodyLayoutPosition {
	Left = 'left',
	Right = 'right',
}

type Props = {
	contentOverlay?: React.ReactNode,
	hideContentScrollbar?: boolean,
	hideSidebarScrollbar?: boolean,
	children: React.ReactNode,
	isContentLoading?: boolean,
	sidebar: React.ReactNode,
	/** Position of sidebar in whole layout */
	sidebarPosition?: ScreenBodyLayoutPosition,
	/** Possibility to set custom width of sidebar */
	sidebarWidth?: React.CSSProperties['width'],
};



const ScreenBodyLayout: React.FC<Props> = (props) => {
	const {
		contentOverlay,
		hideContentScrollbar = false,
		hideSidebarScrollbar = false,
		isContentLoading = false,
		children,
		sidebar,
		sidebarWidth,
		sidebarPosition = ScreenBodyLayoutPosition.Left,
	} = props;

	const _renderSidebar = () => {
		const sidebarClasses = classNames({
			'screen-body-layout__block': true,
			'screen-body-layout__block--sidebar': true,
		});

		const sidebarScrollableAreaClasses = classNames({
			'screen-body-layout__scrollable-area': true,
			'screen-body-layout__scrollable-area--always-visible-scrollbar': !hideSidebarScrollbar,
			'js-scrollable': true,
		});

		let sidebarStyle = {};

		if (sidebarWidth) {
			sidebarStyle = {
				flexBasis: sidebarWidth,
				maxWidth: sidebarWidth,
			};
		}

		return (
			<div
				className={sidebarClasses}
				style={sidebarStyle}
			>
				<div className={sidebarScrollableAreaClasses}>
					{sidebar}
				</div>
			</div>
		);
	};

	const contentClasses = classNames({
		'screen-body-layout__block': true,
		'screen-body-layout__block--content': true,
		'screen-body-layout__block--blur-overlay': isContentLoading && !contentOverlay,
	});

	const contentScrollbableAreaClasses = classNames({
		'screen-body-layout__scrollable-area': true,
		'screen-body-layout__scrollable-area--always-visible-scrollbar': !hideContentScrollbar,
		'js-scrollable': true,
	});

	const overlayClasses = classNames({
		'screen-body-layout__overlay': true,
		'screen-body-layout__overlay--content': true,
		'screen-body-layout__overlay--always-visible-scrollbar': !hideContentScrollbar,
		'js-scrollable': true,
		'js-scrollable-overlay': true,
	});

	return (
		<TransitionGroup className="screen-body-layout">
			{sidebarPosition === ScreenBodyLayoutPosition.Left && _renderSidebar()}

			<div className={contentClasses}>
				<ScrollContainerProvider>
					{({ containerRef }) => (
						<div
							className={contentScrollbableAreaClasses}
							ref={containerRef}
						>
							{children}
						</div>
					)}
				</ScrollContainerProvider>
				<PanelAnimation
					className={overlayClasses}
				>
					{contentOverlay}
				</PanelAnimation>
			</div>

			{sidebarPosition === ScreenBodyLayoutPosition.Right && _renderSidebar()}
		</TransitionGroup>
	);
};



export default ScreenBodyLayout;
