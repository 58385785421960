import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';
import Website from '~/components/patterns/websites/Website';
import WebsiteFavicon, {
	WebsiteFaviconUsageContext,
} from '~/components/logic/websites/WebsiteFavicon';

import useHasMultipleAccounts from '~/hooks/useHasMultipleAccounts';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';
import useWebsiteIsDiscovered from '~/hooks/useWebsiteIsDiscovered';

import {
	menu,
} from '~/actions';

import {
	isWebsiteSwitcherOpenSelector,
} from '~/state/ui/menu/selectors';



const messages = defineMessages({
	progressDescription: {
		id: 'ui.menu.progress.tooltip',
	},
});



type Props = {
	accountName: string | null,
};

const WebsitesNavToggle: React.FC<Props> = (props) => {
	const {
		accountName,
	} = props;

	const websiteId = useWebsiteId();

	const hasMultipleAccounts = useHasMultipleAccounts();
	const open = useSelector(isWebsiteSwitcherOpenSelector);
	const websiteIsCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId);
	const websiteIsDiscovered = useWebsiteIsDiscovered(websiteId);

	const websiteDisplayName = useWebsiteDisplayName(websiteId);

	const dispatch = useDispatch();

	const handleToggleSelect = React.useCallback(
		(e: React.MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();

			if (open) {
				dispatch(menu.closeWebsiteSwitcher());
			} else {
				dispatch(menu.openWebsiteSwitcher());
			}
		},
		[
			dispatch,
			open,
		],
	);

	function renderStatusFlag() {
		let iconStatus: StatusFlagStatus | null = null;

		if (websiteIsCrawlingPaused) {
			iconStatus = StatusFlagStatus.Paused;
		} else if (websiteIsDiscovered === false) {
			iconStatus = StatusFlagStatus.DiscoveryInProgress;
		}

		if (iconStatus === null) {
			return null;
		}

		if (iconStatus === StatusFlagStatus.DiscoveryInProgress) {
			return (
				<Hint
					attachment={HintAttachment.Left}
					popup={(
						<FormattedMessage {...messages.progressDescription} />
					)}
					popupMaxWidth={260}
					popupZIndex={1500}
				>
					<StatusFlag status={iconStatus} />
				</Hint>
			);
		}

		return (
			<StatusFlag status={iconStatus} />
		);
	}

	return (
		<Website
			accountName={hasMultipleAccounts && accountName ? accountName : undefined}
			additionalIcon={(
				<DropdownIcon
					isActive={open}
					style={DropdownIconStyle.White}
				/>
			)}
			favicon={(
				<WebsiteFavicon
					usageContext={WebsiteFaviconUsageContext.Sidebar}
					websiteId={websiteId}
				/>
			)}
			isHighlighted={open}
			isMain={true}
			name={websiteDisplayName}
			onClick={handleToggleSelect}
			statusFlag={renderStatusFlag()}
		/>
	);
};



export default WebsitesNavToggle;
