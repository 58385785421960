import React from 'react';

import HeaderFilterLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderFilterLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';

import useViewportType from '~/hooks/useViewportType';



const HeaderLayout = (props) => {
	const {
		filter,
		title,
		switchElement,
	} = props;

	const viewportType = useViewportType();

	return (
		<ScreenHeader>
			{title}

			{!viewportType.isSmall && switchElement}

			<HeaderFilterLayout
				compactView={viewportType.isSmall || viewportType.isMedium}
				fixedWidth={!viewportType.isSmall && !viewportType.isMedium}
			>
				{filter}
			</HeaderFilterLayout>

			<HeaderOptionsLayout>
				{viewportType.isSmall && switchElement}
				{props.children}
			</HeaderOptionsLayout>
		</ScreenHeader>
	);
};



export default HeaderLayout;
