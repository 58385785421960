/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageLastUnreliableResponseQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageLastUnreliableResponseQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly lastUnreliableResponse: GraphQL.FetchingFailureReason | null } | null };


export const PageLastUnreliableResponseDocument = gql`
    query PageLastUnreliableResponse($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    lastUnreliableResponse
  }
}
    `;

/**
 * __usePageLastUnreliableResponseQuery__
 *
 * To run a query within a React component, call `usePageLastUnreliableResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageLastUnreliableResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageLastUnreliableResponseQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageLastUnreliableResponseQuery(baseOptions: Apollo.QueryHookOptions<PageLastUnreliableResponseQuery, PageLastUnreliableResponseQueryVariables> & ({ variables: PageLastUnreliableResponseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageLastUnreliableResponseQuery, PageLastUnreliableResponseQueryVariables>(PageLastUnreliableResponseDocument, options);
      }
export function usePageLastUnreliableResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageLastUnreliableResponseQuery, PageLastUnreliableResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageLastUnreliableResponseQuery, PageLastUnreliableResponseQueryVariables>(PageLastUnreliableResponseDocument, options);
        }
export function usePageLastUnreliableResponseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageLastUnreliableResponseQuery, PageLastUnreliableResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageLastUnreliableResponseQuery, PageLastUnreliableResponseQueryVariables>(PageLastUnreliableResponseDocument, options);
        }
export type PageLastUnreliableResponseQueryHookResult = ReturnType<typeof usePageLastUnreliableResponseQuery>;
export type PageLastUnreliableResponseLazyQueryHookResult = ReturnType<typeof usePageLastUnreliableResponseLazyQuery>;
export type PageLastUnreliableResponseSuspenseQueryHookResult = ReturnType<typeof usePageLastUnreliableResponseSuspenseQuery>;
export type PageLastUnreliableResponseQueryResult = Apollo.QueryResult<PageLastUnreliableResponseQuery, PageLastUnreliableResponseQueryVariables>;