import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';

import {
	LIST_OF_ALL_STATUSES,
	STATUS_ADDED,
	STATUS_CHANGED,
	STATUS_CHANGED_TO_FALSE,
	STATUS_CHANGED_TO_TRUE,
	STATUS_CLOSED,
	STATUS_DECREASED,
	STATUS_ENTERED,
	STATUS_IGNORED,
	STATUS_INCREASED,
	STATUS_LEFT,
	STATUS_MOVED_BACKWARD,
	STATUS_MOVED_FORWARD,
	STATUS_NOT_APPLICABLE,
	STATUS_NOT_PAGE,
	STATUS_NOT_REQUIRED,
	STATUS_NO_LONGER_MATCHING_CRITERIA,
	STATUS_OPEN,
	STATUS_OVER_QUOTA,
	STATUS_REMOVED,
	STATUS_RESTORED,
	STATUS_REVERTED,
	STATUS_UNKNOWN,
} from '~/model/alertPages';



const messages = defineMessages({
	[STATUS_ADDED]: {
		id: 'ui.screen.alertPages.columns.status.added',
	},
	[STATUS_CHANGED]: {
		id: 'ui.screen.alertPages.columns.status.changed',
	},
	[STATUS_CHANGED_TO_FALSE]: {
		id: 'ui.screen.alertPages.columns.status.changedToFalse',
	},
	[STATUS_CHANGED_TO_TRUE]: {
		id: 'ui.screen.alertPages.columns.status.changedToTrue',
	},
	[STATUS_CLOSED]: {
		id: 'ui.screen.alertPages.columns.status.resolved',
	},
	[STATUS_DECREASED]: {
		id: 'ui.screen.alertPages.columns.status.decreased',
	},
	[STATUS_ENTERED]: {
		id: 'ui.screen.alertPages.columns.status.entered',
	},
	[STATUS_IGNORED]: {
		id: 'ui.screen.alertPages.columns.status.ignored',
	},
	[STATUS_INCREASED]: {
		id: 'ui.screen.alertPages.columns.status.increased',
	},
	[STATUS_LEFT]: {
		id: 'ui.screen.alertPages.columns.status.left',
	},
	[STATUS_MOVED_BACKWARD]: {
		id: 'ui.screen.alertPages.columns.status.movedBackward',
	},
	[STATUS_MOVED_FORWARD]: {
		id: 'ui.screen.alertPages.columns.status.movedForward',
	},
	[STATUS_NO_LONGER_MATCHING_CRITERIA]: {
		id: 'ui.screen.alertPages.columns.status.noLongerMatchingCriteria',
	},
	[STATUS_NOT_PAGE]: {
		id: 'ui.screen.alertPages.columns.status.notPage',
	},
	[STATUS_NOT_REQUIRED]: {
		id: 'ui.screen.alertPages.columns.status.notRequired',
	},
	[STATUS_OPEN]: {
		id: 'ui.screen.alertPages.columns.status.open',
	},
	[STATUS_OVER_QUOTA]: {
		id: 'ui.screen.alertPages.columns.status.overQuota',
	},
	[STATUS_REMOVED]: {
		id: 'ui.screen.alertPages.columns.status.removed',
	},
	[STATUS_RESTORED]: {
		id: 'ui.screen.alertPages.columns.status.restored',
	},
	[STATUS_REVERTED]: {
		id: 'ui.screen.alertPages.columns.status.reverted',
	},
	[STATUS_UNKNOWN]: {
		id: 'ui.screen.alertPages.columns.status.unknown',
	},
});



class AlertStatusCellValue extends Component {

	render() {
		const {
			value,
		} = this.props;

		if (value === STATUS_NOT_APPLICABLE) {
			return (
				<NotApplicableValue ellipsis={true} />
			);
		}

		try {
			return (
				<FormattedMessage {...messages[value]} />
			);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);

			return value;
		}
	}

}

AlertStatusCellValue.propTypes = {
	value: PropTypes.oneOf(LIST_OF_ALL_STATUSES.toArray()).isRequired,
};



export default AlertStatusCellValue;
