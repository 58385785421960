import {
	type Store,
} from 'redux';



export enum FeatureFlag {
	AdminFeatures = 'ADMIN_FEATURES',
}

export enum FeatureFlagValue {
	Negative = 'disabled',
	Positive = 'enabled',
	Unknown = 'unknown',
}

export const RESET_FEATURE_FLAGS = 'RESET_FEATURE_FLAGS';
export const TOGGLE_FEATURE_FLAG = 'TOGGLE_FEATURE_FLAG';



export function disableFeatureFlag(featureFlag: FeatureFlag) {
	return {
		type: TOGGLE_FEATURE_FLAG,
		featureFlag,
		value: FeatureFlagValue.Negative,
	};
}



export function enableFeatureFlag(featureFlag: FeatureFlag) {
	return {
		type: TOGGLE_FEATURE_FLAG,
		featureFlag,
		value: FeatureFlagValue.Positive,
	};
}



export function resetFeatureFlags() {
	return {
		type: RESET_FEATURE_FLAGS,
	};
}



export function isEnabled(featureFlags, featureFlag: FeatureFlag) {
	return featureFlags.get(featureFlag) === FeatureFlagValue.Positive;
}


/* eslint-disable no-console */
export function setupFeatureFlagTools(store: Store) {
	window.ContentKing = {
		toggleFeatureFlag(featureFlag) {
			if (Object.values(FeatureFlag).includes(featureFlag)) {
				return store.getState().get('featureFlags').get(featureFlag) === FeatureFlagValue.Positive
					? window.ContentKing.disableFeatureFlag(featureFlag)
					: window.ContentKing.enableFeatureFlag(featureFlag);
			}

			console.warn('ContentKing: Feature ' + featureFlag + ' doesn\'t exist.');
			return false;
		},

		disableFeatureFlag(featureFlag) {
			if (Object.values(FeatureFlag).includes(featureFlag)) {
				store.dispatch(
					disableFeatureFlag(featureFlag),
				);

				console.log('ContentKing: %cFeature ' + featureFlag + ' was disabled.', 'color:red');
				return true;
			}

			console.warn('ContentKing: Feature ' + featureFlag + ' doesn\'t exist.');
			return false;
		},

		enableFeatureFlag(featureFlag) {
			if (Object.values(FeatureFlag).includes(featureFlag)) {
				store.dispatch(
					enableFeatureFlag(featureFlag),
				);

				console.log('ContentKing: %cFeature ' + featureFlag + ' was enabled.', 'color:green');
				return true;
			}

			console.warn('ContentKing: Feature ' + featureFlag + ' doesn\'t exist.');
			return false;
		},

		resetFeatureFlags() {
			store.dispatch(
				resetFeatureFlags(),
			);

			console.log('ContentKing: %cAll feature flags were reset.', 'color:green');
			return true;
		},

		showFeatureFlags() {
			console.log(store.getState().get('featureFlags').toJS());

			return true;
		},

		features() {
			console.log(Object.values(FeatureFlag));
		},
	};
}
/* eslint-enable no-console */
