import classNames from 'classnames';
import React from 'react';

import matchAndReturn from '~/utilities/matchAndReturn';



export enum SimpleTableCellAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

export enum SimpleTableCellType {
	Body = 'body',
	Header = 'header',
}

type Props = {
	alignment?: SimpleTableCellAlignment,
	children?: React.ReactNode,
	maxWidth?: React.CSSProperties['maxWidth'],
	type?: SimpleTableCellType,
	width?: React.CSSProperties['width'],
	wrapText?: boolean,
};



const SimpleTableCell: React.FC<Props> = (props) => {
	const {
		alignment = SimpleTableCellAlignment.Left,
		children,
		maxWidth,
		type = SimpleTableCellType.Body,
		width,
		wrapText = false,
	} = props;

	const componentClasses = classNames({
		'simple-table-cell': true,
		['simple-table-cell--' + type]: true,
		['simple-table-cell--' + alignment + '-alignment']: alignment,
		['simple-table-cell--wrap']: wrapText,
	});

	const content = (
		<div style={{ width }}>
			<div
				className="simple-table-cell__container"
				style={{ maxWidth }}
			>
				{children}
			</div>
		</div>
	);

	return matchAndReturn(type, {
		[SimpleTableCellType.Header]: (
			<th className={componentClasses}>
				{content}
			</th>
		),
		[SimpleTableCellType.Body]: (
			<td className={componentClasses}>
				{content}
			</td>
		),
	});
};



export default SimpleTableCell;
