import type CK from '~/types/contentking';

import {
	useWebsiteHasWebVitalsOriginSummaryQuery,
} from './useWebsiteHasWebVitalsOriginSummary.gql';



type Options = {
	skip?: boolean,
};

function useWebsiteHasWebVitalsOriginSummary(
	websiteId: CK.WebsiteId,
	options: Options = {},
): boolean | null {
	const {
		skip,
	} = options;

	const { data } = useWebsiteHasWebVitalsOriginSummaryQuery({
		variables: {
			websiteId,
		},
		skip,
	});

	return (data?.website?.hasWebVitalsOriginSummaryData) ?? null;
}



export default useWebsiteHasWebVitalsOriginSummary;
