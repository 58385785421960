import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RealtimeIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-real-time">
					<g id="real-time">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<polyline
							id="Stroke-1401"
							points="9 10.9934171 12.0216954 13.98975 20.0805 6"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</polyline>
						<path
							d="M10.7676419,20.222141 C6.77633876,19.6443487 3.70992456,16.2090155 3.70992456,12.0576669 C3.70992456,7.50141692 7.40367456,3.80766692 11.9599246,3.80766692 C14.1754758,3.80766692 16.1870845,4.68107256 17.6692134,6.10234639 C18.4853559,6.8849787 19.1409487,7.83373643 19.5816366,8.8942644 C19.9864511,9.86846205 20.2099246,10.9369737 20.2099246,12.0576669 C20.2099246,14.6566661 19.0080346,16.9750209 17.1297564,18.4872299"
							id="Stroke-592-Copy"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
							transform="translate(11.959925, 12.014904) rotate(250.699997) translate(-11.959925, -12.014904) "
						>
						</path>
						<path
							d="M11.9582609,3.95478261 L11.9600254,5.25"
							id="Stroke-593"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M4.10086957,12.0151916 L5.5026087,12.0151916"
							id="Stroke-593-Copy-2"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M11.9600254,18.75 L11.9600254,20.0452174"
							id="Stroke-593-Copy"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default RealtimeIconBuilder;

