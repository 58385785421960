import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	getBillingCycleDurationInMonths,
} from '~/model/pricing/billingCycle';



const messages = defineMessages({
	monthNotation: {
		id: 'ui.billing.perCycle.inMonths',
	},
});

const inlineMessages = defineMessages({
	[GraphQL.Term.Annually]: {
		id: 'ui.billing.paymentBillingCycle.annually.inline',
	},
	[GraphQL.Term.Monthly]: {
		id: 'ui.billing.paymentBillingCycle.monthly.inline',
	},
	[GraphQL.Term.Quarterly]: {
		id: 'ui.billing.paymentBillingCycle.quarterly.inline',
	},
	monthNotation: {
		id: 'ui.billing.paymentBillingCycle.monthsNotation.inline',
	},
});

const multilineMessages = defineMessages({
	[GraphQL.Term.Annually]: {
		id: 'ui.billing.paymentBillingCycle.annually.multiline',
	},
	[GraphQL.Term.Monthly]: {
		id: 'ui.billing.paymentBillingCycle.monthly.multiline',
	},
	[GraphQL.Term.Quarterly]: {
		id: 'ui.billing.paymentBillingCycle.quarterly.multiline',
	},
	monthNotation: {
		id: 'ui.billing.paymentBillingCycle.monthsNotation.multiline',
	},
});



type Props = {
	billingCycle: GraphQL.Term,
	inline?: boolean,
	useMonthNotation?: boolean,
};

const BillingCyclePerMonthName: React.FC<Props> = (props) => {
	const {
		billingCycle,
		inline = true,
		useMonthNotation = false,
	} = props;

	if (!useMonthNotation) {
		return (
			<Copy
				{...messages.monthNotation}
				values={{
					count__months: getBillingCycleDurationInMonths(billingCycle),
				}}
			/>
		);
	}

	const message = (
		inline
			? inlineMessages[billingCycle]
			: multilineMessages[billingCycle]
	);

	assertDictionaryMessage(message);

	return (
		<Copy {...message} />
	);
};



export default BillingCyclePerMonthName;
