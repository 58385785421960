import React from 'react';

import useMultiStepModal from './useMultiStepModal';
import useModalControls from './useModalControls';



function useClassicFormBehavior() {
	const modalControls = useModalControls();
	const multiStepModal = useMultiStepModal();

	const cancel = React.useCallback(
		() => {
			if (multiStepModal !== null && modalControls !== null) {
				if (multiStepModal.activeStep === 0) {
					modalControls.close();
				} else {
					multiStepModal.goToPreviousStep();
				}
			} else if (modalControls !== null) {
				modalControls.close();
			}
		},
		[
			modalControls,
			multiStepModal,
		],
	);

	const finish = React.useCallback(
		() => {
			if (multiStepModal !== null && modalControls !== null) {
				if (multiStepModal.activeStep === multiStepModal.numberOfSteps - 1) {
					modalControls.close();
				} else {
					multiStepModal.goToNextStep();
				}
			} else if (modalControls !== null) {
				modalControls.close();
			}
		},
		[
			modalControls,
			multiStepModal,
		],
	);

	return React.useMemo(
		() => ({
			cancel,
			finish,
		}),
		[
			cancel,
			finish,
		],
	);
}



export default useClassicFormBehavior;
