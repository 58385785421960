import classNames from 'classnames';
import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import useViewportHeight from '~/hooks/useViewportHeight';
import useViewportOrientation from '~/hooks/useViewportOrientation';
import useViewportWidth from '~/hooks/useViewportWidth';

import {
	closeColumnsConfigurator,
} from '~/actions';

import {
	columnsConfiguratorVisibilitySelector,
} from '~/state/ui/selectors';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



export const LAYOUT_OVERLAY = 'overlay';
export const LAYOUT_SIDEBAR = 'sidebar';

const SIDEBAR_WIDTH = 304;



type Props = {
	children: RenderProp<{
		containerHeight: number,
		containerWidth: number,
	}>,
	columnsConfigurator: React.ReactNode,
	layout:
		| typeof LAYOUT_OVERLAY
		| typeof LAYOUT_SIDEBAR,
};

const ColumnsManagementLayout: React.FC<Props> = (props) => {
	const {
		children,
		columnsConfigurator,
		layout,
	} = props;

	const columnsConfiguratorVisibility: boolean = useSelector(columnsConfiguratorVisibilitySelector);
	const dispatch = useDispatch();
	const viewportHeight = useViewportHeight();
	const viewportOrientation = useViewportOrientation();
	const viewportWidth = useViewportWidth();

	const configuratorRef = React.useRef<HTMLDivElement>(null);

	const close = React.useCallback(
		() => {
			dispatch(closeColumnsConfigurator());
		},
		[
			dispatch,
		],
	);


	React.useEffect(
		() => {
			if (!columnsConfiguratorVisibility || layout !== LAYOUT_OVERLAY) {
				return;
			}

			const handleOutsideClick = (event) => {
				const target = event.target;

				const hints = Array.from(document.querySelectorAll('.interface-hint__bubble-target, .hint__bubble'));

				if (
					!configuratorRef.current?.contains(target)
					&& !document.querySelector('.manage-columns-link')?.contains(target)
					&& !hints.some((hint) => hint.contains(target))
				) {
					close();
				}
			};

			document.addEventListener('click', handleOutsideClick, { capture: true });

			return () => {
				document.removeEventListener('click', handleOutsideClick, { capture: true });
			};
		},
		[
			close,
			columnsConfiguratorVisibility,
			layout,
		],
	);

	const isColumnsConfiguratorVisible = (
		columnsConfiguratorVisibility
		|| layout === LAYOUT_SIDEBAR
	);

	const {
		tableHeight,
		tableWidth,
	} = React.useMemo(
		() => {
			let widthOffset = 0;

			if (viewportWidth > 1168) {
				widthOffset = 200;
			} else if (viewportOrientation.isLandscape && viewportWidth > 640) {
				widthOffset = 56;
			}

			let heightOffset = 92;

			if (viewportWidth > 1168) {
				heightOffset = 65;
			} else if (viewportOrientation.isLandscape && viewportWidth > 640) {
				heightOffset = 56;
			} else if (viewportOrientation.isPortrait && viewportWidth > 640) {
				heightOffset = 100;
			}

			return {
				tableWidth: viewportWidth - widthOffset,
				tableHeight: viewportHeight - heightOffset,
			};
		},
		[
			viewportHeight,
			viewportWidth,
			viewportOrientation,
		],
	);

	let calculatedWidth = tableWidth;

	if (isColumnsConfiguratorVisible && layout === LAYOUT_SIDEBAR) {
		calculatedWidth -= SIDEBAR_WIDTH;
	}

	const componentClasses = classNames({
		'columns-management': true,
		'columns-management--columns-configurator-visible': isColumnsConfiguratorVisible,
		'columns-management--sidebar-layout': layout === LAYOUT_SIDEBAR,
		'columns-management--overlay-layout': layout === LAYOUT_OVERLAY,
	});

	return (
		<div className={componentClasses}>
			<div className="columns-management__columns-configurator">
				<div
					className="columns-management__columns-configurator-content"
					ref={configuratorRef}
				>
					{columnsConfigurator}
				</div>
			</div>

			<div className="columns-management__table">
				{renderProp(children, {
					containerHeight: tableHeight,
					containerWidth: calculatedWidth,
				})}
			</div>
		</div>
	);
};



export default ColumnsManagementLayout;
