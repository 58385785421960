import React from 'react';



type Props = {
	/** Content where will be attached Enterprise ribbon */
	children: React.ReactNode,
};



const AttachedEnterpriseRibbon: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="attached-enterprise-ribbon">
			{children}
		</div>
	);
};



export default AttachedEnterpriseRibbon;
