import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CountSlider, {
	CountSliderHandlerBubbleSize,
} from '~/components/patterns/forms/fields/CountSlider';
import CountSliderFieldLayout from '~/components/patterns/forms/fieldParts/countSliders/CountSliderFieldLayout';

import useFormContext from '~/hooks/useFormContext';



const messages = defineMessages({
	from: {
		id: 'ui.contentOverview.tableHeading.relevance.tooltip',
	},
	upTo: {
		id: 'ui.contentOverview.tableHeading.score.tooltip',
	},
});

export const DEFAULT_WIDTH = 280;



export type InputRangeFieldRef = {
	changeValue: (value: number) => void,
};

export enum InputRangeFieldDirection {
	GreaterOrEqual = 'greater-or-equal',
	LesserOrEqual = 'lesser-or-equal',
}

type Props = {
	direction: InputRangeFieldDirection,
	highlightColorFactory?: (value: number) => React.CSSProperties['backgroundColor'],
	max?: number,
	min?: number,
	name: string,
	step?: number,
	width?: React.CSSProperties['width'],
};



const InputRangeField = React.forwardRef<any, Props>((props, ref: React.Ref<InputRangeFieldRef>) => {
	const {
		direction,
		highlightColorFactory,
		max = 100,
		min = 0,
		name,
		step = 1,
		width = DEFAULT_WIDTH,
	} = props;

	const {
		defaultValues,
		onChangeHandler,
		onMountHandler,
		onUnmountHandler,
	} = useFormContext();

	const createInternalValue = (value) => {
		if (value === null || value === undefined) {
			return direction === InputRangeFieldDirection.GreaterOrEqual
				? min
				: max;
		}

		return step < 1
			? parseFloat(value.substr(1))
			: parseInt(value.substr(1), 10);
	};

	const [dragging, setDragging] = React.useState<boolean>(false);
	const [value, setValue] = React.useState(createInternalValue(defaultValues[name]));

	React.useEffect(
		() => {
			onMountHandler(
				name,
				{
					setValues: true,
				},
			);

			return () => {
				onUnmountHandler(name);
			};
		},
		[
			name,
			onMountHandler,
			onUnmountHandler,
		],
	);

	React.useImperativeHandle(ref, () => ({
		changeValue: (value) => {
			setValue(createInternalValue(value || null));
		},
	}));

	const scoreRangeChangeHandler = (value) => {
		setValue(value);

		if (direction === InputRangeFieldDirection.GreaterOrEqual) {
			if (value > min) {
				value = '>' + parseFloat(value).toFixed(2);
			} else {
				value = null;
			}
		} else {
			if (value < max) {
				value = '<' + parseFloat(value).toFixed(2);
			} else {
				value = null;
			}
		}

		onChangeHandler(name, value);
	};

	return (
		<CountSliderFieldLayout
			slider={(
				<CountSlider
					customHighlightColor={highlightColorFactory ? highlightColorFactory(value) : undefined}
					handlerBubble={dragging && (
						<FormattedMessage
							{...(direction === InputRangeFieldDirection.GreaterOrEqual ? messages.from : messages.upTo)}
							values={{
								big: (chunks) => (
									<big>{chunks}</big>
								),
								value,
							}}
						/>
					)}
					handlerBubbleSize={CountSliderHandlerBubbleSize.Small}
					max={max}
					min={min}
					onAfterChangeCallback={() => setDragging(false)}
					onBeforeChangeCallback={() => setDragging(true)}
					onChangeCallback={scoreRangeChangeHandler}
					reversed={direction === InputRangeFieldDirection.GreaterOrEqual}
					step={step}
					value={value}
				/>
			)}
			width={width}
		/>
	);
});

export default InputRangeField;
