import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ComplexListField, {
	type ComplexListFieldIsDisabledGetterInput,
	type ComplexListFieldRendererInput,
	type ComplexListFieldValidationInput,
} from '~/components/logic/customElements/extractionSteps/ComplexListField';
import TextField from '~/components/atoms/forms/components/TextField';

import useFormContext from '~/hooks/useFormContext';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';



const messages = defineMessages({
	addButton: {
		id: 'ui.customHttpHeadersSetup.addButton',
	},
	columnName: {
		id: 'ui.customHttpHeadersSetup.columns.name',
	},
	columnValue: {
		id: 'ui.customHttpHeadersSetup.columns.value',
	},
	formErrorAuthorization: {
		id: 'ui.customHttpHeadersSetup.errors.authorization',
	},
	formErrorBlacklisted: {
		id: 'ui.customHttpHeadersSetup.errors.blacklisted',
	},
	formErrorBlank: {
		id: 'ui.formErrors.blank',
	},
	formErrorUserAgent: {
		id: 'ui.customHttpHeadersSetup.errors.userAgent',
	},
});

const HEADERS_WHITELIST = [
	/^Accept$/i,
	/^Accept-Language$/i,
	/^Authorization$/i,
	/^Cache-Control$/i,
	/^Forwarded$/i,
	/^Proxy-Authorization$/i,
	/^Referer$/i,
	/^X-[a-z0-9_-]+$/i,
];



type Props = {
	isHttpAuthenticationEnabled: boolean,
	name: string,
};

const HttpHeadersField: React.FC<Props> = (props) => {
	const {
		isHttpAuthenticationEnabled,
		name,
	} = props;

	const formContext = useFormContext();
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const defaultValue = formContext.defaultValues[name];

	const isRowDisabled = React.useCallback(
		(headerName: string) => {
			return (
				kingdomAdminFeatures.areVisible === false
				&& !!headerName
				&& !HEADERS_WHITELIST.some(
					(pattern) => pattern.test(headerName),
				)
			);
		},
		[
			kingdomAdminFeatures,
		],
	);

	const isDisabledGetter = React.useCallback(
		({ rowIndex }: ComplexListFieldIsDisabledGetterInput) => {
			return isRowDisabled(
				defaultValue?.[rowIndex]?.name,
			);
		},
		[
			defaultValue,
			isRowDisabled,
		],
	);

	const fields = React.useMemo(
		() => {
			return [
				{
					defaultValue: '',
					label: (
						<FormattedMessage {...messages.columnName} />
					),
					name: 'name',
					renderer: ({ fieldName, isDisabled }: ComplexListFieldRendererInput) => (
						<TextField
							disabled={isDisabled}
							name={fieldName}
							width="100%"
						/>
					),
					validation: ({ f, getItemValue, rowIndex }: ComplexListFieldValidationInput) => ([
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorUserAgent} />
							),
							rule: ({ value }) => {
								return !/^User-Agent$/i.test(value);
							},
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorAuthorization} />
							),
							rule: ({ value }) => {
								const isAuthorizationHeader = /^Authorization$/i.test(value);

								return isHttpAuthenticationEnabled ? !isAuthorizationHeader : true;
							},
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorBlacklisted} />
							),
							rule: ({ value, values }) => {
								const headerName = value?.trim() ?? '';
								const headerValue = getItemValue(values, 'value')?.trim() ?? '';

								if (headerName === '' && headerValue === '') {
									return true;
								}

								if (kingdomAdminFeatures.areVisible) {
									return true;
								}

								const originalHeaderName = defaultValue?.[rowIndex]?.name;

								if (headerName === originalHeaderName) {
									return true;
								}

								return HEADERS_WHITELIST.some((pattern) => pattern.test(headerName));
							},
						}),
					]),
					width: 220,
				},
				{
					defaultValue: '',
					label: (
						<FormattedMessage {...messages.columnValue} />
					),
					name: 'value',
					renderer: ({ fieldName, isDisabled }: ComplexListFieldRendererInput) => (
						<TextField
							disabled={isDisabled}
							name={fieldName}
							width="100%"
						/>
					),
					validation: ({ f, getItemValue }: ComplexListFieldValidationInput) => ([
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorBlank} />
							),
							rule: ({ value, values }) => {
								const headerName = getItemValue(values, 'name')?.trim() ?? '';
								const headerValue = value?.trim() ?? '';

								return headerName !== ''
									? headerValue !== ''
									: true;
							},
						}),
					]),
				},
			];
		},
		[
			defaultValue,
			isHttpAuthenticationEnabled,
			kingdomAdminFeatures,
		],
	);

	return (
		<ComplexListField
			addButtonLabel={(
				<FormattedMessage {...messages.addButton} />
			)}
			addExtraEmptyRow={false}
			fields={fields}
			isDisabledGetter={isDisabledGetter}
			name={name}
			showAddButton={true}
			showRowNumbers={false}
		/>
	);
};



export default HttpHeadersField;
