import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CustomElementExtractedElementSelectField from '~/components/logic/customElements/CustomElementExtractedElementSelectField';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import ExtractionStepsConfigurator from './extractionSteps/ExtractionStepsConfigurator';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Fieldset from '~/components/patterns/forms/wrappers/Fieldset';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import FormSentence from '~/components/atoms/forms/basis/FormSentence';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	OCCURRENCES_ALL,
} from '~/model/customElements';



const messages = defineMessages({
	elementToExtractLabel: {
		id: 'ui.customElements.elementToExtract.element',
	},
	fieldsetsExtractionStepsCaption: {
		id: 'ui.customElements.form.fieldsets.extractionSteps.caption',
	},
	formFieldsLabelLabel: {
		id: 'ui.customElements.form.fields.name.label',
	},
	formFieldsLabelPlaceholder: {
		id: 'ui.customElements.form.fields.name.placeholder',
	},
	saveButton: {
		id: 'ui.customElements.form.saveButton',
	},
});



const PlaceholderCustomElementForm: React.FC = () => {
	const intl = useIntl();

	const defaultValues = {
		extraction_step_occurrences_0: OCCURRENCES_ALL,
	};

	return (
		<DisabledContent disabledContent={true}>
			<Form
				defaultValues={defaultValues}
				isDisabled={true}
			>
				<FormRows>
					<FormRow
						htmlFor="label"
						label={(
							<FormattedMessage {...messages.formFieldsLabelLabel} />
						)}
					>
						<FieldStatus name="label">
							<TextField
								name="label"
								placeholder={intl.formatMessage(messages.formFieldsLabelPlaceholder)}
							/>
						</FieldStatus>
					</FormRow>
					<Fieldset
						label={(
							<FormattedMessage {...messages.fieldsetsExtractionStepsCaption} />
						)}
					>
						<FormRows>
							<ExtractionStepsConfigurator
								name="extractionSteps"
								showAddButton={false}
							/>

							<FormSentence>
								<FormattedMessage {...messages.elementToExtractLabel} />

								<FieldStatus
									name="type"
									showIcon={false}
								>
									<CustomElementExtractedElementSelectField
										name="type"
									/>
								</FieldStatus>
							</FormSentence>
						</FormRows>
					</Fieldset>

					<ButtonsLayout
						alignment={ButtonsLayoutAlignment.Right}
						layout={ButtonsLayoutType.Steps}
					>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.saveButton} />
						</SubmitButton>
					</ButtonsLayout>
				</FormRows>
			</Form>
		</DisabledContent>
	);
};



export default PlaceholderCustomElementForm;
