export const CHANGE_VIEW = 'COLUMN_CONFIGURATOR_CHANGE_VIEW';
export const MARK_CURRENT_VIEW_AS_DELETED = 'COLUMN_CONFIGURATOR_MARK_CURRENT_VIEW_AS_DELETED';
export const SET_COLUMNS = 'COLUMN_CONFIGURATOR_SET_COLUMNS';
export const STORE_NEW_VIEW = 'COLUMN_CONFIGURATOR_STORE_NEW_VIEW';
export const UPDATE_STORED_VIEW = 'COLUMN_CONFIGURATOR_UPDATE_STORED_VIEW';



export const setColumns = (columns) => {
	return {
		type: SET_COLUMNS,
		columns,
	};
};
