import React, {
	Component,
} from 'react';

import Button, {
	ButtonSize as PopupButtonSize,
	ButtonStyle as PopupButtonStyle,
} from './Button';
import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';
import Hint, {
	HintAttachment,
	HintPopupSize,
	HintPopupSkin,
	HintPopupVisibility,
	type HintRef,
} from '~/components/patterns/hints/hint/Hint';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



export { PopupButtonSize };
export { PopupButtonStyle };



type ChildProps = {
	closePopupHandler: () => void,
};

type Props = {
	/** Button icon placed before button label */
	buttonIcon?: React.ReactElement,
	/** Content of popup panel */
	children: RenderProp<ChildProps>,
	/** Button label */
	label?: React.ReactNode,
	/** Make button muted (applied greyscale fiter) and make it normal on press, hover or focus */
	size?: PopupButtonSize,
	popupLayout?: (children: React.ReactNode) => React.ReactNode,
	/** Manually set z-index CSS property. There is always built-in DepthLayer */
	popupZIndex?: number,
	preventOutsideClickCallback?: (target: HTMLElement) => boolean,
	/** Style of button. We support only limited range of styles comparing to general button. */
	style: PopupButtonStyle,
	uppercase?: boolean,
};

type State = {
	/** Visibility of popup */
	openPopup: boolean,
};



class PopupButton extends Component<Props, State> {

	private popupRef = React.createRef<HintRef>();

	_boundButtonToggle: () => void;
	_boundClosePopup: () => void;



	static defaultProps = {
		style: PopupButtonStyle.Hollow,
		uppercase: false,
	};



	constructor(props, context) {
		super(props, context);

		this.state = {
			openPopup: false,
		};

		this._boundButtonToggle = this._handleButtonToggle.bind(this);
		this._boundClosePopup = this._handleClosePopup.bind(this);
	}



	_handleButtonToggle(openState) {
		this.setState({
			openPopup: openState,
		});
	}



	_handleClosePopup() {
		this.popupRef.current?.close();
	}



	_renderPopup() {
		const {
			children,
		} = this.props;

		return renderProp(
			children,
			{
				closePopupHandler: this._boundClosePopup,
			},
		);
	}



	render() {
		const {
			buttonIcon,
			label,
			popupLayout,
			popupZIndex,
			preventOutsideClickCallback,
			size: buttonSize,
			style,
			uppercase,
		} = this.props;

		const {
			openPopup,
		} = this.state;

		let buttonStyle = style;

		if (style === PopupButtonStyle.Alert && !openPopup) {
			buttonStyle = PopupButtonStyle.Alert;
		}

		if (style === PopupButtonStyle.Hollow || (
			(style === PopupButtonStyle.Action || style === PopupButtonStyle.Alert || style === PopupButtonStyle.History || style === PopupButtonStyle.Highlight) && openPopup
		)) {
			buttonStyle = PopupButtonStyle.Hollow;
		}

		if (style === PopupButtonStyle.Action && !openPopup) {
			buttonStyle = PopupButtonStyle.Action;
		}

		const dropdownIconStyle = !openPopup && (style === PopupButtonStyle.Alert || style === PopupButtonStyle.Action || style === PopupButtonStyle.History || style === PopupButtonStyle.Highlight) ? DropdownIconStyle.White : DropdownIconStyle.Default;

		return (
			<Hint
				attachment={HintAttachment.Right}
				onToggleCallback={this._boundButtonToggle}
				popup={this._renderPopup()}
				popupLayout={popupLayout}
				popupMaxWidth="none"
				popupSize={HintPopupSize.Large}
				popupSkin={HintPopupSkin.Light}
				popupVisibility={HintPopupVisibility.OnClick}
				popupZIndex={popupZIndex}
				preventOutsideClickCallback={preventOutsideClickCallback}
				ref={this.popupRef}
			>
				<Button
					icon={buttonIcon}
					pressed={openPopup}
					size={buttonSize}
					style={buttonStyle}
					suffixIcon={(
						<DropdownIcon
							isActive={openPopup}
							style={dropdownIconStyle}
						/>
					)}
					uppercase={uppercase}
				>
					{label}
				</Button>
			</Hint>
		);
	}

}



export default PopupButton;
