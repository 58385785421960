import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';

import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	buttonLabel: {
		id: 'ui.alertsConfiguration.buttons.backToOverview.label',
	},
});



type Props = {
	compact: boolean,
};

const BackToAlertsOverviewButton: React.FC<Props> = (props) => {
	const {
		compact,
	} = props;

	const websiteId = useWebsiteId();

	return (
		<Button
			compact={compact}
			icon={(
				<BasicIcon type={BasicIconType.Cancel} />
			)}
			linkRouteName="website.alerts"
			linkRouteParams={{
				websiteId,
			}}
			style={ButtonStyle.Hollow}
		>
			<FormattedMessage {...messages.buttonLabel} />
		</Button>
	);
};



export default BackToAlertsOverviewButton;
