import {
	useUserMainAccountIdQuery,
} from './useUserMainAccountId.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserMainAccountId(legacyUserId: string | null) {
	const { data } = useUserPropertiesQuery(
		useUserMainAccountIdQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.mainAccountId ?? null;
}



export default useUserMainAccountId;
