import type CK from '~/types/contentking';

import {
	usePlatformHealthQuery,
} from './usePlatformHealth.gql';

import usePlatformQuery from '~/hooks/usePlatformQuery';



function usePlatformHealth(
	websiteId: CK.WebsiteId,
) {
	const { data } = usePlatformQuery(
		usePlatformHealthQuery,
		websiteId,
	);

	return data?.platformData?.health ?? null;
}



export default usePlatformHealth;
