import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import BarChart from '~/components/atoms/charts/charts/BarChart';
import BarChartLabels from '~/components/atoms/charts/components/BarChartLabels';
import ChartLayout from '~/components/patterns/charts/layouts/ChartLayout';
import HealthLabel from '~/components/patterns/structuredValues/HealthLabel';
import ScopeLabel, {
	ScopeLabelSize,
	ScopeLabelStyle,
} from '~/components/logic/scopes/ScopeLabel';

import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteScopeIssues from '~/hooks/useWebsiteScopeIssues';

import {
	issuesFilterSelector,
} from '~/state/ui/selectors';

import {
	SCORE_CONDITION_COLORS,
	getValueByScoreCondition,
} from '~/utilities/scores';



const messages = defineMessages({
	chartTitle: {
		id: 'ui.issuesOverview.charts.healthPerSegmentChart.title',
	},
});

const HEALTH_CHART_MAXIMUM = 1000;



type Props = {
	websiteId: CK.WebsiteId | null,
};

const HealthPerSegmentChart: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const issuesFilter = useSelector(issuesFilterSelector);
	const viewportWidth = useViewportWidth();
	const websiteScopeIssues = useWebsiteScopeIssues(websiteId);

	const renderLabel = React.useCallback(
		(labelKey) => {
			return (
				<ScopeLabel
					scope={labelKey}
					size={ScopeLabelSize.Small}
					style={ScopeLabelStyle.Thumb}
				/>
			);
		},
		[],
	);

	const renderTooltip = React.useCallback(
		(formatter) => {
			return formatter.y;
		},
		[],
	);

	if (websiteScopeIssues === null) {
		return null;
	}

	const filteredAndSortedWebsiteScopeIssues = websiteScopeIssues.filter(
		(scope) => issuesFilter.get('segment') === 'all'
			? true
			: scope.name === ('segment/' + issuesFilter.get('segment')),
	).sort(
		(scopeA, scopeB) => {
			if (scopeA.name === 'website' && scopeB.name !== 'website') {
				return -1;
			}

			if (scopeA.name !== 'website' && scopeB.name === 'website') {
				return 1;
			}

			if (scopeA.health === scopeB.health) {
				return 0;
			}

			return scopeA.health < scopeB.health ? -1 : 1;
		},
	);

	const categories = filteredAndSortedWebsiteScopeIssues.map(
		(scope) => scope.name,
	);

	const chartData = Immutable.List([{
		name: 'health',
		data: filteredAndSortedWebsiteScopeIssues.map(
			(scope) => ({
				y: scope.health,
				color: getValueByScoreCondition(
					scope.health,
					HEALTH_CHART_MAXIMUM,
					SCORE_CONDITION_COLORS,
				),
			}),
		),
	}]);

	return (
		<ChartLayout
			label={(
				<HealthLabel>
					<FormattedMessage {...messages.chartTitle} />
				</HealthLabel>
			)}
		>
			<BarChartLabels
				categories={categories}
				isXAxisVisible={true}
				labelRenderer={renderLabel}
			>
				<BarChart
					categories={categories}
					data={chartData}
					key={viewportWidth + '/' + categories.length}
					maximum={HEALTH_CHART_MAXIMUM}
					showXAxis={true}
					tickInterval={100}
					tooltipRenderer={renderTooltip}
				/>
			</BarChartLabels>
		</ChartLayout>
	);
};



export default HealthPerSegmentChart;
