import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import CarouselBlocks from '~/components/patterns/structures/CarouselBlocks';
import IssueModalContentLayout from '../../atoms/issues/components/modals/IssueModalContentLayout';
import ModalContainer from '../../atoms/modals/parts/ModalContainer';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import NumberBadge from '~/components/patterns/tags/NumberBadge';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';

import {
	urlStateSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	ignoringLabel: {
		id: 'ui.issueDetail.ignoringModal.header.ignoringLabel',
	},
});



const select = createSelector(
	urlStateSelector,
	(
		urlState,
	) => {
		return {
			urlState,
		};
	},
);



class ModalLayout extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			tabsNavigationProgress: false,
		};

		this._handlePreventFastClick = this._handlePreventFastClick.bind(this);
	}



	_handlePreventFastClick(e) {
		const {
			tabsNavigationProgress,
		} = this.state;

		if (tabsNavigationProgress) {
			e.preventDefault();
		} else {
			this.setState({
				tabsNavigationProgress: true,
			}, () => {
				setTimeout(() => {
					this.setState({
						tabsNavigationProgress: false,
					});
				}, 300);
			});
		}
	}



	_renderIgnoringDetail() {
		const {
			activeScope,
			scopes,
			urlState,
		} = this.props;

		const activeSlideIndex = scopes.map(({ name }, index) => {
			if (activeScope === name) {
				return index;
			}

			return null;
		}).filter((index) => index !== null)[0];

		return (
			<IssueModalContentLayout
				navigation={(
					<TabNavigation>
						{scopes.map((scope) => (
							<TabNavigationItem
								isActive={scope.name === activeScope}
								isNotReady={scope.isNotReady}
								key={scope.name}
								linkRouteName={urlState.name}
								linkRouteParams={Object.assign({}, urlState.params, {
									scope: scope.name,
								})}
								onClickCallback={this._handlePreventFastClick}
								suffixIcon={scope.badge && scope.badge > 0 ? (
									<NumberBadge
										color="#98A5B3"
										value={scope.badge}
									/>
								) : false}
							>
								{scope.label}
							</TabNavigationItem>
						))}
					</TabNavigation>
				)}
			>
				<CarouselBlocks
					activeSlideIndex={activeSlideIndex}
					adaptiveHeight={false}
					draggable={false}
					selectableText={true}
					swipe={false}
				>
					{scopes.map((scope) => (
						<React.Fragment key={scope.name}>
							{scope.content}
						</React.Fragment>
					))}
				</CarouselBlocks>
			</IssueModalContentLayout>
		);
	}



	render() {
		const {
			isLoading,
			title,
		} = this.props;

		return (
			<ModalContainer
				gapsSize={0}
				header={(
					<ModalHeader
						iconType={ModalHeaderIconType.Block}
						title={(
							<FormattedMessage {...messages.ignoringLabel} />
						)}
						titleValue={title}
					/>
				)}
				headerGapsSize={1}
				isLoading={isLoading}
			>
				{this._renderIgnoringDetail()}
			</ModalContainer>
		);
	}

}



ModalLayout.propTypes = {
	activeScope: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	scopes: PropTypes.arrayOf(
		PropTypes.shape({
			badge: PropTypes.node,
			content: PropTypes.node,
			isNotReady: PropTypes.bool,
			label: PropTypes.node,
			linkRouteName: PropTypes.string,
			linkRouteParams: PropTypes.object,
			name: PropTypes.string,
		}),
	).isRequired,
	title: PropTypes.any,
	urlState: PropTypes.object.isRequired,
};



export default connect(select)(ModalLayout);
