import React from 'react';

import GraphQL from '~/types/graphql';

import AffectedPagesCount, {
	AffectedPagesCountMode,
} from '~/components/patterns/issues/AffectedPagesCount';
import AnimateHeartbeat from '~/components/logic/score/AnimateHeartbeat';
import IssueCategoryCardLayout, {
	HIGHLIGHT_INDENTATION_RIGHT,
	ICON_ALIGNMENT_CENTER,
} from '~/components/atoms/issues/components/categoryCards/builders/IssueCategoryCardLayout';
import IssueCategoryNameIcon from '~/components/app/IssueCategoryNameIcon';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import IssueScore, {
	IssueScoreFlow,
	IssueScoreState,
} from '~/components/patterns/issues/IssueScore';
import WebsiteIssueCategoryCardContent from '~/components/atoms/issues/components/categoryCards/builders/WebsiteIssueCategoryCardContent';

import useHighlightIssueCategoryHealthImpact from '~/hooks/useHighlightIssueCategoryHealthImpact';

import {
	type IssueCategoryName,
	getPointsToBeDisplayed,
} from '~/model/issuesNew';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	isActive: boolean,
	issueCategory: {
		affectedPagesPerState: {
			percentage: {
				open: number,
			},
		} | null,
		isActionable: boolean,
		isIgnored: boolean,
		name: IssueCategoryName,
		pointsGained: number,
		pointsToGain: number,
		state: GraphQL.IssueCategoryState,
	},
	onBlurIssueCallback: () => void,
	onFocusIssueCallback: (issueCategoryName: string) => void,
};

const WebsiteIssueCategoryCard: React.FC<Props> = (props) => {
	const {
		isActive,
		issueCategory,
		onBlurIssueCallback,
		onFocusIssueCallback,
	} = props;

	const highlightIssueCategoryHealthImpact = useHighlightIssueCategoryHealthImpact(issueCategory.name);

	const issueCategoryName = issueCategory.name;

	const handleHeaderClick = React.useCallback(
		() => {
			if (!isActive) {
				onFocusIssueCallback(issueCategoryName);
			} else {
				onBlurIssueCallback();
			}
		},
		[
			isActive,
			issueCategoryName,
			onBlurIssueCallback,
			onFocusIssueCallback,
		],
	);

	const values: Array<React.ReactNode> = [];

	if (
		issueCategory.isIgnored === false
		&& issueCategory.affectedPagesPerState !== null
		&& issueCategory.affectedPagesPerState.percentage.open > 0
	) {
		values.push(
			<AffectedPagesCount
				key="affected-pages-count"
				mode={AffectedPagesCountMode.Percent}
				value={issueCategory.affectedPagesPerState.percentage.open}
			/>,
		);
	}

	values.push(
		<AnimateHeartbeat
			enabled={issueCategory.isActionable}
			key="score"
		>
			<IssueScore
				flow={IssueScoreFlow.Column}
				isIgnored={issueCategory.isIgnored}
				state={matchAndReturn(issueCategory.state, {
					[GraphQL.IssueCategoryState.Closed]: IssueScoreState.Fixed,
					[GraphQL.IssueCategoryState.Open]: IssueScoreState.Broken,
					[GraphQL.IssueCategoryState.NotApplicable]: IssueScoreState.NotApplicable,
					[GraphQL.IssueCategoryState.NotRequired]: IssueScoreState.NotRequired,
					[GraphQL.IssueCategoryState.Unknown]: IssueScoreState.Unknown,
				})}
				value={getPointsToBeDisplayed(issueCategory)}
			/>
		</AnimateHeartbeat>,
	);

	return (
		<IssueCategoryCardLayout
			highlightIndentation={HIGHLIGHT_INDENTATION_RIGHT}
			icon={(
				<IssueCategoryNameIcon
					issueCategoryName={issueCategory.name}
				/>
			)}
			iconAlignment={ICON_ALIGNMENT_CENTER}
			isHighlighted={isActive}
			onClickCallback={handleHeaderClick}
			onMouseEnterCallback={highlightIssueCategoryHealthImpact.start}
			onMouseLeaveCallback={highlightIssueCategoryHealthImpact.stop}
		>
			<WebsiteIssueCategoryCardContent
				title={(
					<IssueCategoryTitle
						issueCategoryName={issueCategory.name}
					/>
				)}
				values={values}
			/>
		</IssueCategoryCardLayout>
	);
};



export default WebsiteIssueCategoryCard;
