import memoize from 'memoizee';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useUserViaLegacyIdWebsiteAccessPermissionsLazyQuery,
	useUserViaUniqueIdWebsiteAccessPermissionsLazyQuery,
	useWebsiteUserAccessPermissionsLazyQuery,
} from './useIsAllowedWithUserWebsiteAccessOracle.gql';

import usePermission from '~/hooks/usePermission';

import {
	ObjectType,
} from '~/model/permissions';



function createObjectId(
	websiteId: CK.WebsiteId | null,
	uniqueUserId: CK.UserId | null,
	legacyUserId: string | null,
) {
	if (websiteId === null) {
		return null;
	}

	if (uniqueUserId !== null) {
		return `${websiteId}/${uniqueUserId}`;
	}

	if (legacyUserId !== null) {
		return `${websiteId}/compat/${legacyUserId}`;
	}

	return null;
}



const createCallback = memoize(
	(
		isAllowed: ReturnType<typeof usePermission>,
		userViaLegacyIdWebsiteAccessPermissionsQuery: ReturnType<typeof useUserViaLegacyIdWebsiteAccessPermissionsLazyQuery>[0],
		userViaUniqueIdWebsiteAccessPermissionsQuery: ReturnType<typeof useUserViaUniqueIdWebsiteAccessPermissionsLazyQuery>[0],
		websiteUserAccessPermissionsQuery: ReturnType<typeof useWebsiteUserAccessPermissionsLazyQuery>[0],
	) => ({
		action,
		from,
		legacyUserId = null,
		uniqueUserId = null,
		websiteId,
	}: {
		action: GraphQL.ActionWithUserWebsiteAccess,
		from: 'user' | 'website',
		websiteId: CK.WebsiteId | null,
	} & ({
		legacyUserId?: never,
		uniqueUserId: CK.UserId | null,
	} | {
		legacyUserId: string | null,
		uniqueUserId?: never,
	})) => {
		if (from === 'user' && legacyUserId !== null) {
			userViaLegacyIdWebsiteAccessPermissionsQuery({
				variables: {
					legacyUserId,
				},
			});
		}

		if (from === 'user' && uniqueUserId !== null) {
			userViaUniqueIdWebsiteAccessPermissionsQuery({
				variables: {
					uniqueUserId,
				},
			});
		}

		if (from === 'website' && websiteId !== null) {
			websiteUserAccessPermissionsQuery({
				variables: {
					websiteId,
				},
			});
		}

		return isAllowed({
			action,
			objectId: createObjectId(websiteId, uniqueUserId, legacyUserId),
			objectType: ObjectType.UserWebsiteAccess,
		});
	},
);



function useIsAllowedWithUserWebsiteAccessOracle() {
	const isAllowed = usePermission();

	const [userViaLegacyIdWebsiteAccessPermissionsQuery] = useUserViaLegacyIdWebsiteAccessPermissionsLazyQuery();
	const [userViaUniqueIdWebsiteAccessPermissionsQuery] = useUserViaUniqueIdWebsiteAccessPermissionsLazyQuery();
	const [websiteUserAccessPermissionsQuery] = useWebsiteUserAccessPermissionsLazyQuery();

	return createCallback(
		isAllowed,
		userViaLegacyIdWebsiteAccessPermissionsQuery,
		userViaUniqueIdWebsiteAccessPermissionsQuery,
		websiteUserAccessPermissionsQuery,
	);
}



export default useIsAllowedWithUserWebsiteAccessOracle;
