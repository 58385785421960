import React from 'react';

import type CK from '~/types/contentking';

import usePendoEvent from '~/hooks/usePendoEvent';



function useTrackIgnoringUpdateInPendo() {
	const pendoEvent = usePendoEvent();

	return React.useCallback(
		(websiteId: CK.WebsiteId, issueName: string, newStatus: boolean, scope: string) => {
			const message = newStatus
				? 'Issue ignored'
				: 'Issue un-ignored';

			pendoEvent(message, {
				'Website ID': websiteId,
				'Issue name': issueName,
				'Scope': scope,
			});
		},
		[
			pendoEvent,
		],
	);
}



export default useTrackIgnoringUpdateInPendo;
