import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import HeaderTitleWithValue, {
	SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';

import usePageDisplayUrl from '~/hooks/usePageDisplayUrl';
import usePageStatusCode from '~/hooks/usePageStatusCode';
import usePageType from '~/hooks/usePageType';
import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	error3xx: {
		id: 'ui.pageDetail.error3xx',
	},
	error4xx: {
		id: 'ui.pageDetail.error4xx',
	},
	error5xx: {
		id: 'ui.pageDetail.error5xx',
	},
	nonPage: {
		id: 'ui.pageDetail.nonPage',
	},
	overquota: {
		id: 'ui.pageDetail.overquota',
	},
	page: {
		id: 'ui.pageDetail.page',
	},
	unreachable: {
		id: 'ui.pageDetail.unreachable',
	},
});



type Props = {
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const PageTitle: React.FC<Props> = (props) => {
	const {
		legacyUrlId,
		websiteId,
	} = props;

	const pageDisplayUrl = usePageDisplayUrl(legacyUrlId, websiteId);
	const pageStatusCode = usePageStatusCode(legacyUrlId, websiteId);
	const pageType = usePageType(legacyUrlId, websiteId);
	const viewportType = useViewportType();

	function renderPageLabel() {
		if (pageType === GraphQL.PageType.Unreachable) {
			return (
				<FormattedMessage {...messages.unreachable} />
			);
		}

		if (pageType === GraphQL.PageType.Other) {
			return (
				<FormattedMessage {...messages.nonPage} />
			);
		}

		if (pageType === GraphQL.PageType.OverQuota) {
			return (
				<FormattedMessage {...messages.overquota} />
			);
		}

		if (pageStatusCode === null || pageStatusCode < 300) {
			return (
				<FormattedMessage {...messages.page} />
			);
		}

		if (pageStatusCode < 400) {
			return (
				<FormattedMessage
					{...messages.error3xx}
					values={{
						statusCode: pageStatusCode,
					}}
				/>
			);
		}

		if (pageStatusCode < 500) {
			return (
				<FormattedMessage
					{...messages.error4xx}
					values={{
						statusCode: pageStatusCode,
					}}
				/>
			);
		}

		if (pageStatusCode <= 505) {
			return (
				<FormattedMessage
					{...messages.error5xx}
					values={{
						statusCode: pageStatusCode,
					}}
				/>
			);
		}

		return null;
	}

	const pageLabel = (!viewportType.isSmall && !viewportType.isMedium)
		? renderPageLabel()
		: null;

	return (
		<HeaderTitleWithValue
			size={SIZE_SMALL}
			title={pageLabel}
			value={pageDisplayUrl ?? (
				<span>&nbsp;</span>
			)}
		/>
	);
};



export default PageTitle;
