import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import MissingValue from '~/components/app/MissingValue';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';
import WebVitalName from '~/components/app/WebVitalName';
import WebVitalFormatter from '~/components/app/WebVitalFormatter';

import {
	getWebVitalByName,
} from '~/model/webVitals';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	columnMetric: {
		id: 'ui.webVitalMetricsTable.column.metric',
	},
	columnValue: {
		id: 'ui.webVitalMetricsTable.column.value',
	},
	insufficientData: {
		id: 'ui.webVitalMetricsTable.insufficientData',
	},
});



type Props = {
	issue: {
		context: {
			metrics: ReadonlyArray<{
				name: string,
				percentile: number,
				verdict: 'SLOW' | 'AVERAGE' | 'FAST',
			} | {
				name: string,
				percentile: null,
				verdict: null,
			}>,
		},
	},
	tableWidth: number,
};

const WebVitalMetricsTable: React.FC<Props> = (props) => {
	const {
		issue,
		tableWidth,
	} = props;

	const firstColumnWidth = Math.ceil(tableWidth * 2 / 3);

	const metrics = issue.context.metrics;

	return (
		<SmallTable
			columns={[
				{
					hasData: () => true,
					render: {
						cell: ({ row }) => {
							const type = getWebVitalByName(row.name);

							if (type === null) {
								return null;
							}

							return (
								<WebVitalName
									showCoreWebVitalFlag={true}
									type={type}
								/>
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnMetric} />
						),
					},
					width: firstColumnWidth,
				},
				{
					hasData: () => true,
					render: {
						cell: ({ row }) => {
							const type = getWebVitalByName(row.name);

							if (type === null) {
								return null;
							}

							if (row.percentile === null) {
								return (
									<MissingValue>
										<FormattedMessage {...messages.insufficientData} />
									</MissingValue>
								);
							}

							return (
								<WebVitalFormatter
									showStatusIndicator={true}
									type={type}
									value={row.percentile}
									verdict={matchAndReturn(row.verdict, {
										AVERAGE: GraphQL.WebVitalVerdict.Average,
										FAST: GraphQL.WebVitalVerdict.Fast,
										SLOW: GraphQL.WebVitalVerdict.Slow,
									})}
								/>
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnValue} />
						),
					},
					width: tableWidth - firstColumnWidth - 2,
				},
			]}
			rows={metrics}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(WebVitalMetricsTable);
