/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisconnectConductorOrganizationMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type DisconnectConductorOrganizationMutation = { readonly __typename?: 'Mutation', readonly DisconnectConductorOrganization: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly conductorOrganizationId: string | null } | null } } };


export const DisconnectConductorOrganizationDocument = gql`
    mutation DisconnectConductorOrganization($accountId: AccountId!) {
  DisconnectConductorOrganization(accountId: $accountId) {
    query {
      account(id: $accountId) {
        id
        conductorOrganizationId
      }
    }
  }
}
    `;
export type DisconnectConductorOrganizationMutationFn = Apollo.MutationFunction<DisconnectConductorOrganizationMutation, DisconnectConductorOrganizationMutationVariables>;

/**
 * __useDisconnectConductorOrganizationMutation__
 *
 * To run a mutation, you first call `useDisconnectConductorOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectConductorOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectConductorOrganizationMutation, { data, loading, error }] = useDisconnectConductorOrganizationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDisconnectConductorOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectConductorOrganizationMutation, DisconnectConductorOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectConductorOrganizationMutation, DisconnectConductorOrganizationMutationVariables>(DisconnectConductorOrganizationDocument, options);
      }
export type DisconnectConductorOrganizationMutationHookResult = ReturnType<typeof useDisconnectConductorOrganizationMutation>;
export type DisconnectConductorOrganizationMutationResult = Apollo.MutationResult<DisconnectConductorOrganizationMutation>;
export type DisconnectConductorOrganizationMutationOptions = Apollo.BaseMutationOptions<DisconnectConductorOrganizationMutation, DisconnectConductorOrganizationMutationVariables>;