import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Additional account name */
	accountName?: React.ReactNode,
	/** Other additional icon */
	additionalIcon?: React.ReactNode,
	/** Website favicon */
	favicon: React.ReactNode,
	/** Highlighted look (e.g. when active) */
	isHighlighted?: boolean,
	/** Main website overview flag */
	isMain?: boolean,
	/** Website name */
	name: React.ReactNode,
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void,
	/** Website status shown as flag (icon) */
	statusFlag: React.ReactNode,
};



const Website: React.FC<Props> = (props) => {
	const {
		accountName,
		additionalIcon,
		favicon,
		isHighlighted,
		isMain,
		name,
		onClick,
		statusFlag,
	} = props;

	const componentClasses = classNames({
		'website': true,
		'website--is-highlighted': isHighlighted,
		'website--main': isMain,
		'website--with-account-name': accountName,
	});

	const elementContent = (
		<>
			{accountName && (
				<div className="website__account-name">{accountName}</div>
			)}
			<div className="website__overview">
				<div className="website__favicon">
					{favicon}
				</div>
				<div className="website__data">
					<div className="website__label">
						<div className="website__name">
							{name}
						</div>
						{statusFlag && (
							<span className="website__icon-wrapper">
								{statusFlag}
							</span>
						)}
						{additionalIcon}
					</div>
				</div>
			</div>
		</>
	);

	if (onClick) {
		return (
			<a
				className={componentClasses}
				href="#"
				onClick={onClick}
			>
				{elementContent}
			</a>
		);
	}

	return (
		<div className={componentClasses}>
			{elementContent}
		</div>
	);
};



export default Website;
