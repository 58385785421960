import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type Props = {
	children?: React.ReactNode,
	footer?: React.ReactNode,
	header?: React.ReactNode,
};

const ColumnsConfiguratorLayout: React.FC<Props> = (props) => {
	const {
		children,
		footer,
		header,
	} = props;

	const elements = flattenChildren(children).filter(notEmpty);

	return (
		<div className="columns-configurator">
			{header}

			<TransitionGroup
				className="columns-configurator__columns js-scrollable"
				exit={false}
			>
				{elements.map((child, index) => {
					return (
						<CSSTransition
							className="columns-configurator__fade-in-container"
							classNames={{
								enter: 'columns-configurator__fade-in-container--before-opening',
								enterActive: 'columns-configurator__fade-in-container--opening',
							}}
							in={true}
							key={'columns-configurator-container-' + index}
							timeout={500}
						>
							<div>
								{child}
							</div>
						</CSSTransition>
					);
				})}
			</TransitionGroup>

			{footer}
		</div>
	);
};


export default ColumnsConfiguratorLayout;
