import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountIsPartnerQuery,
} from './useAccountIsPartner.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsPartner(accountId: CK.AccountId | null): GraphQL.Account['isPartner'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountIsPartnerQuery,
		accountId,
	);

	return data?.account?.isPartner ?? null;
}



export default useAccountIsPartner;
