import type React from 'react';

import {
	useSelector,
} from 'react-redux';

import useColumnSets from '~/hooks/useColumnSets';
import useCurrentColumnSetId from '~/hooks/useCurrentColumnSetId';
import useCurrentColumns from '~/hooks/useCurrentColumns';
import useEnabledColumnsList from '~/hooks/useEnabledColumnsList';
import useIsColumnSetModified from '~/hooks/useIsColumnSetModified';
import useProjectUrlParams from '~/hooks/useProjectUrlParams';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	createFilterParameter as createChangeTrackingFilterParameter,
} from '~/actions/historicalChanges';

import {
	createFilterParameter as createIssuesFilterParameter,
} from '~/actions/issues';

import {
	createFilterParameter as createPagesFilterParameter,
} from '~/actions/pages/overview';

import {
	createSitemapsListFilterParameter,
} from '~/actions/sitemapsList';

import {
	createAffectedLinksFilterParameter,
} from '~/model/affectedLinks';

import {
	createAlertPagesFilterParameter,
} from '~/model/alertPages';

import {
	PAGES_SECTION_GRAPHS,
} from '~/model/ui/pagesScreen';

import {
	createFilterParameter as createIssuesComparisonFilterParameter,
} from '~/model/websiteIssuesComparison';

import {
	filterSelector as affectedLinksFilterSelector,
	sortBySelector as affectedLinksSortBySelector,
} from '~/state/affectedLinks/selectors';

import {
	currentFilterSelector as alertPagesFilterSelector,
	currentSortBySelector as alertPagesSortBySelector,
} from '~/state/alertPages/selectors';

import {
	filterSelector as changeTrackingFilterSelector,
	sortBySelector as changeTrackingSortBySelector,
	dateIntervalSelector,
	isInHistoricalChangesModeSelector,
} from '~/state/historicalChanges/selectors';

import {
	filterSelector as sitemapsListFilterSelector,
	sortBySelector as sitemapsListSortBySelector,
} from '~/state/sitemapsList/selectors';

import {
	issuesFilterSelector,
	filterSelector as pagesFilterSelector,
	sortBySelector as pagesSortBySelector,
} from '~/state/ui/selectors';

import {
	pagesSectionSelector,
} from '~/state/ui/content/selectors';

import {
	filterSelector as issuesComparisonFilterSelector,
} from '~/state/websiteIssuesComparison/selectors';



const RouterTransitions: React.FC = () => {
	const websiteId = useWebsiteId();

	const affectedLinksFilter = useSelector(affectedLinksFilterSelector);
	const affectedLinksSortBy = useSelector(affectedLinksSortBySelector);
	const alertPagesFilter = useSelector(alertPagesFilterSelector);
	const alertPagesSortBy = useSelector(alertPagesSortBySelector);
	const changeTrackingFilter = useSelector(changeTrackingFilterSelector);
	const changeTrackingSortBy = useSelector(changeTrackingSortBySelector);
	const columnSets = useColumnSets();
	const currentColumns = useCurrentColumns();
	const dateInterval = useSelector(dateIntervalSelector);
	const enabledColumnsList = useEnabledColumnsList();
	const isInHistoricalChangesMode = useSelector(isInHistoricalChangesModeSelector);
	const issuesComparisonFilter = useSelector(issuesComparisonFilterSelector);
	const issuesFilter = useSelector(issuesFilterSelector);
	const pagesFilter = useSelector(pagesFilterSelector);
	const pagesSection = useSelector(pagesSectionSelector);
	const pagesSortBy = useSelector(pagesSortBySelector);
	const segments = useWebsiteSegmentDefinitions(websiteId);
	const sitemapsListFilter = useSelector(sitemapsListFilterSelector);
	const sitemapsListSortBy = useSelector(sitemapsListSortBySelector);
	const viewId = useCurrentColumnSetId();
	const viewIsModified = useIsColumnSetModified();

	useProjectUrlParams(
		(urlState) => {
			if (urlState.name === 'website.alerts.alertPages') {
				return {
					filter: createAlertPagesFilterParameter(
						alertPagesFilter,
						alertPagesSortBy,
						null,
					),
					alertId: urlState.params.alertId,
					websiteId: urlState.params.websiteId,
				};
			}

			if (
				urlState.name === 'website.issues'
				|| urlState.name === 'website.issues.issueCategory'
			) {
				const isComparisonModeActive = !!urlState.params.start_date;

				const result: Record<string, any> = {
					end_date: urlState.params.end_date,
					filter: isComparisonModeActive
						? createIssuesComparisonFilterParameter(issuesComparisonFilter)
						: createIssuesFilterParameter(issuesFilter),
					issuesOverviewCategory: urlState.params.issuesOverviewCategory,
					start_date: urlState.params.start_date,
					websiteId: urlState.params.websiteId,
				};

				if (!isComparisonModeActive) {
					delete result.end_date;
					delete result.start_date;
				}

				return result;
			}

			if (urlState.name === 'website.issues.issueCategory.singleIssueAffectedLinks') {
				return {
					filter: createAffectedLinksFilterParameter(
						affectedLinksFilter,
						affectedLinksSortBy,
					),
					issuesOverviewCategory: urlState.params.issuesOverviewCategory,
					issuesOverviewIssue: urlState.params.issuesOverviewIssue,
					websiteId: urlState.params.websiteId,
				};
			}

			if (urlState.name === 'website.pages') {
				if (columnSets.isLoaded === false || segments.isLoaded === false) {
					return;
				}

				if (isInHistoricalChangesMode) {
					const result: Record<string, any> = {
						columns: urlState.params.columns,
						end_date: dateInterval.get('endDate'),
						filter: createChangeTrackingFilterParameter(
							changeTrackingFilter,
							changeTrackingSortBy,
							enabledColumnsList,
							segments.listAll(),
						),
						start_date: dateInterval.get('startDate'),
						websiteId: urlState.params.websiteId,
					};

					if (viewIsModified === true) {
						result.columns = currentColumns
							.map((column) => column.name + ':' + (column.enabled ? '1' : '0'))
							.join(',');
					} else if (viewIsModified === false) {
						delete result.columns;
					}

					if (viewId) {
						result.view = viewId;
					} else {
						delete result.view;
					}

					return result;
				}

				const result: Record<string, any> = {
					columns: urlState.params.columns,
					filter: createPagesFilterParameter(
						pagesFilter,
						pagesSortBy,
						enabledColumnsList,
						segments.listAll(),
					),
					websiteId: urlState.params.websiteId,
				};

				if (pagesSection === PAGES_SECTION_GRAPHS) {
					result.graphs = true;
				} else {
					delete result.graphs;
				}

				if (viewIsModified === true) {
					result.columns = currentColumns
						.map((column) => column.name + ':' + (column.enabled ? '1' : '0'))
						.join(',');
				} else if (viewIsModified === false) {
					delete result.columns;
				}

				if (viewId) {
					result.view = viewId;
				}

				return result;
			}

			if (urlState.name === 'website.platform.sitemaps') {
				return {
					filter: createSitemapsListFilterParameter(
						sitemapsListFilter,
						sitemapsListSortBy,
					),
					websiteId: urlState.params.websiteId,
				};
			}
		},
		[
			affectedLinksFilter,
			affectedLinksSortBy,
			alertPagesFilter,
			alertPagesSortBy,
			changeTrackingFilter,
			changeTrackingSortBy,
			columnSets,
			currentColumns,
			dateInterval,
			enabledColumnsList,
			isInHistoricalChangesMode,
			issuesComparisonFilter,
			issuesFilter,
			pagesFilter,
			pagesSection,
			pagesSortBy,
			segments,
			sitemapsListFilter,
			sitemapsListSortBy,
			viewId,
			viewIsModified,
		],
	);

	return null;
};



export default RouterTransitions;
