import React from 'react';

import BasicIcon, {
	BasicIconSpinning,
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';



export enum StatusFlagSize {
	Default = 'default',
	Large = 'large',
	Medium = 'medium',
}

export enum StatusFlagStatus {
	Critical = 'critical',
	Disabled = 'disabled',
	DiscoveryInProgress = 'discovery-in-progress',
	Indefinite = 'indefinite',
	Normal = 'normal',
	NotRequired = 'not-required',
	Paused = 'paused',
	Success = 'success',
	Waiting = 'waiting',
	Warning = 'warning',
}

type Props = {
	/** Possible size of icon */
	size?: StatusFlagSize,
	/** Status we'd like to highlight */
	status: StatusFlagStatus,
};

const statusMapping = {
	[ StatusFlagStatus.Critical ]: {
		type: BasicIconType.Exclamation,
	},
	[ StatusFlagStatus.Disabled ]: {
		type: BasicIconType.Block,
	},
	[ StatusFlagStatus.DiscoveryInProgress ]: {
		spinning: BasicIconSpinning.Infinite,
		type: BasicIconType.Spinner,
	},
	[ StatusFlagStatus.Indefinite ]: {
		type: BasicIconType.Hourglass,
	},
	[ StatusFlagStatus.Normal ]: {
		color: '#e4e6e9',
		type: BasicIconType.Tick,
	},
	[ StatusFlagStatus.NotRequired ]: {
		type: BasicIconType.Tick,
	},
	[ StatusFlagStatus.Paused ]: {
		type: BasicIconType.Pause,
	},
	[ StatusFlagStatus.Success ]: {
		color: '#89c32e',
		type: BasicIconType.Tick,
	},
	[ StatusFlagStatus.Waiting ]: {
		color: '#ff743b',
		type: BasicIconType.Hourglass,
	},
	[ StatusFlagStatus.Warning ]: {
		color: '#ff743b',
		type: BasicIconType.Exclamation,
	},
};



const StatusFlag: React.FC<Props> = (props) => {
	const {
		size = StatusFlagSize.Default,
		status,
	} = props;

	let iconSize = 22;

	if (size === StatusFlagSize.Medium) {
		iconSize = 26;
	} else if (size === StatusFlagSize.Large) {
		iconSize = 32;
	}

	const attributes = {};

	if (statusMapping[status]['color']) {
		attributes['color'] = statusMapping[status]['color'];
	}

	if (statusMapping[status]['spinning']) {
		attributes['spinning'] = statusMapping[status]['spinning'];
	}

	return (
		<BasicIcon
			size={iconSize}
			type={statusMapping[status].type}
			{...attributes}
		/>
	);
};



export default StatusFlag;
