/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountPaymentMethodQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountPaymentMethodQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly paymentMethod: { readonly __typename?: 'AccountPaymentMethod', readonly details: Record<string, any>, readonly type: string } | null } | null };


export const AccountPaymentMethodDocument = gql`
    query AccountPaymentMethod($accountId: AccountId!) {
  account(id: $accountId) {
    id
    paymentMethod {
      details
      type
    }
  }
}
    `;

/**
 * __useAccountPaymentMethodQuery__
 *
 * To run a query within a React component, call `useAccountPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPaymentMethodQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountPaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<AccountPaymentMethodQuery, AccountPaymentMethodQueryVariables> & ({ variables: AccountPaymentMethodQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountPaymentMethodQuery, AccountPaymentMethodQueryVariables>(AccountPaymentMethodDocument, options);
      }
export function useAccountPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountPaymentMethodQuery, AccountPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountPaymentMethodQuery, AccountPaymentMethodQueryVariables>(AccountPaymentMethodDocument, options);
        }
export function useAccountPaymentMethodSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountPaymentMethodQuery, AccountPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountPaymentMethodQuery, AccountPaymentMethodQueryVariables>(AccountPaymentMethodDocument, options);
        }
export type AccountPaymentMethodQueryHookResult = ReturnType<typeof useAccountPaymentMethodQuery>;
export type AccountPaymentMethodLazyQueryHookResult = ReturnType<typeof useAccountPaymentMethodLazyQuery>;
export type AccountPaymentMethodSuspenseQueryHookResult = ReturnType<typeof useAccountPaymentMethodSuspenseQuery>;
export type AccountPaymentMethodQueryResult = Apollo.QueryResult<AccountPaymentMethodQuery, AccountPaymentMethodQueryVariables>;