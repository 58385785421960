/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsiteMonitoringHttpHeadersMutationVariables = GraphQL.Exact<{
  httpHeaders: ReadonlyArray<GraphQL.UpdateWebsiteMonitoringHttpHeadersHttpHeader> | GraphQL.UpdateWebsiteMonitoringHttpHeadersHttpHeader;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteMonitoringHttpHeadersMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteMonitoringHttpHeaders: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly httpHeaders: ReadonlyArray<{ readonly __typename?: 'WebsiteFetchingSettingsHttpHeader', readonly name: string, readonly value: string }> } | null } } };


export const UpdateWebsiteMonitoringHttpHeadersDocument = gql`
    mutation UpdateWebsiteMonitoringHttpHeaders($httpHeaders: [UpdateWebsiteMonitoringHttpHeadersHttpHeader!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteMonitoringHttpHeaders(
    httpHeaders: $httpHeaders
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        httpHeaders {
          name
          value
        }
        id
      }
    }
  }
}
    `;
export type UpdateWebsiteMonitoringHttpHeadersMutationFn = Apollo.MutationFunction<UpdateWebsiteMonitoringHttpHeadersMutation, UpdateWebsiteMonitoringHttpHeadersMutationVariables>;

/**
 * __useUpdateWebsiteMonitoringHttpHeadersMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteMonitoringHttpHeadersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteMonitoringHttpHeadersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteMonitoringHttpHeadersMutation, { data, loading, error }] = useUpdateWebsiteMonitoringHttpHeadersMutation({
 *   variables: {
 *      httpHeaders: // value for 'httpHeaders'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteMonitoringHttpHeadersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteMonitoringHttpHeadersMutation, UpdateWebsiteMonitoringHttpHeadersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteMonitoringHttpHeadersMutation, UpdateWebsiteMonitoringHttpHeadersMutationVariables>(UpdateWebsiteMonitoringHttpHeadersDocument, options);
      }
export type UpdateWebsiteMonitoringHttpHeadersMutationHookResult = ReturnType<typeof useUpdateWebsiteMonitoringHttpHeadersMutation>;
export type UpdateWebsiteMonitoringHttpHeadersMutationResult = Apollo.MutationResult<UpdateWebsiteMonitoringHttpHeadersMutation>;
export type UpdateWebsiteMonitoringHttpHeadersMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteMonitoringHttpHeadersMutation, UpdateWebsiteMonitoringHttpHeadersMutationVariables>;