import React from 'react';

import loadScript from '~/utilities/loadScript';



function useExternalScript(src: string, onLoad?: () => void): void {
	React.useEffect(
		() => {
			loadScript(src).then(onLoad);
		},
		[
			onLoad,
			src,
		],
	);
}



export default useExternalScript;
