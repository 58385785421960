import type CK from '~/types/contentking';

import {
	useWebsiteIsDomTrackedQuery,
} from './useWebsiteIsDomTracked.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteIsDomTracked(websiteId: CK.WebsiteId | null): boolean | null {
	const { data } = useWebsiteIsDomTrackedQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.isDomTracked) ?? null;
}



export default useWebsiteIsDomTracked;
