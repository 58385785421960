import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	type WebsiteIntegrationsQuery,
	useWebsiteIntegrationsQuery,
} from './useWebsiteIntegrations.gql';

import memoizeById from '~/utilities/memoizeById';



const format = memoizeById(
	(data: WebsiteIntegrationsQuery | undefined) => {
		const integrations = data?.website?.integrations ?? null;

		return {
			isEnabled: (type: GraphQL.WebsiteIntegrationType) => integrations?.find((integration) => integration.type === type)?.status === GraphQL.WebsiteIntegrationStatus.Enabled,
			isNotOwned: (type: GraphQL.WebsiteIntegrationType) => integrations?.find((integration) => integration.type === type)?.isNotOwned ?? null,
			getStatus: (type: GraphQL.WebsiteIntegrationType) => integrations?.find((integration) => integration.type === type)?.status ?? null,
			listAll: () => integrations ?? [],
		};
	},
);



function useWebsiteIntegrations(websiteId: CK.WebsiteId | null) {
	const { data } = useWebsiteIntegrationsQuery({
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return format(websiteId, data);
}



export default useWebsiteIntegrations;
