import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const ButtonsGroup: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const elements = React.Children.toArray(children).filter((child) => !!child);

	return (
		<div className="buttons-group">
			{elements.map((child, index) => {
				return (
					<div
						className="buttons-group__button"
						key={'buttons-group-button-' + index}
					>
						{child}
					</div>
				);
			})}
		</div>
	);
};



export default ButtonsGroup;
