import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const TeamIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g
					id="user-team"
					stroke={color}
					strokeWidth="2"
					transform="translate(3.000000, 5.000000)"
				>
					<path
						d="M4.95242788,6.48377404 C2.46372596,7.12415865 0.624783654,9.3828125 0.624783654,12.071274 L4.60411058,12.071274"
						id="Stroke-1046"
					>
					</path>
					<path
						d="M13.6060577,6.8225 C15.3440385,6.8225 16.7531731,5.41336538 16.7531731,3.67538462 C16.7531731,1.93740385 15.3440385,0.528990385 13.6060577,0.528990385 C12.1911538,0.528990385 11.0084615,1.46865385 10.6125481,2.75375"
						id="Stroke-1047"
					>
					</path>
					<path
						d="M9.38817308,2.75403846 C8.99225962,1.46894231 7.80956731,0.528557692 6.39466346,0.528557692 C4.65668269,0.528557692 3.24754808,1.93769231 3.24754808,3.67567308 C3.24754808,5.41365385 4.65668269,6.82278846 6.39466346,6.82278846"
						id="Stroke-1048"
					>
					</path>
					<path
						d="M11.575,9.38778846 C11.0990385,9.62576923 10.5689904,9.77288462 10,9.77288462 C9.43173077,9.77288462 8.90168269,9.62576923 8.42572115,9.38778846 C5.603125,10.0923558 3.50961538,12.6373077 3.50961538,15.6776923 L16.4903846,15.6776923 C16.4903846,12.6373077 14.396875,10.0923558 11.575,9.38778846 L11.575,9.38778846 Z"
						id="Stroke-1049"
					>
					</path>
					<path
						d="M15.3956731,12.0714183 L19.375,12.0714183 C19.375,9.37935096 17.5310096,7.1178125 15.0372596,6.48103365"
						id="Stroke-1050"
					>
					</path>
					<path
						d="M13.5405769,6.23252404 C13.5405769,8.18757212 11.9554808,9.77266827 10.0004327,9.77266827 C8.04538462,9.77266827 6.46028846,8.18757212 6.46028846,6.23252404 C6.46028846,4.27747596 8.04538462,2.69237981 10.0004327,2.69237981 C11.9554808,2.69237981 13.5405769,4.27747596 13.5405769,6.23252404 L13.5405769,6.23252404 Z"
						id="Stroke-1051"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default TeamIconBuilder;

