import {
	get,
} from './api/API';
import {
	List,
} from 'immutable';

import {
	runAction,
} from './actions';



export const COLUMN_DOMAIN = 'domain';
export const COLUMN_INTEGRATIONS = 'integrations';
export const COLUMN_LOG_SOURCES = 'log_sources';
export const COLUMN_NAME = 'name';
export const COLUMN_NUMBER_OF_CUSTOM_ELEMENTS = 'number_of_custom_elements';
export const COLUMN_NUMBER_OF_SEGMENTS = 'number_of_segments';
export const COLUMN_PAGE_CAPACITY_USAGE = 'page_capacity_usage';
export const COLUMN_STATUS = 'status';



export const SYNC_INTERVAL = 10000;



export function filterWebsitesByDomain({ websites, domain }) {
	return websites.filter((website) => {
		return website.domain.toLowerCase().indexOf(domain) !== -1;
	});
}



export function filterWebsitesByName({ websites, name }) {
	name = name.toLowerCase();

	return websites.filter((website) => {
		const displayName = website.displayName;
		const domain = website.domain ?? '';

		return (
			displayName.toLowerCase().indexOf(name) !== -1
			|| domain.toLowerCase().indexOf(name) !== -1
		);
	});
}



export function getWebsiteInitials(websiteName) {
	let initials = '';

	if (websiteName !== null) {
		// remove blank spaces
		initials = websiteName.replace(/ /g, '');

		// get only first 2 characters
		initials = initials.substring(0, 2);
	}

	return initials;
}



export function isDomainWhitelisted(domain, whitelist) {
	if (whitelist === null) {
		return true;
	}

	if (whitelist.size === undefined) {
		whitelist = List(whitelist);
	}

	if (whitelist.size === 0) {
		return true;
	}

	if (domain.substring(0, 4) === 'www.') {
		domain = domain.substring(4);
	}

	return whitelist.some((rule) => {
		if (rule.substring(0, 2) === '*.') {
			rule = rule.substring(1);

			return (
				domain.substring(domain.length - rule.length) === rule
				|| domain === rule.substring(1)
			);
		}

		return domain === rule;
	});
}



export function loadRobotsRulesViaApi(accountId, websiteId) {
	return get('/teams/' + accountId + '/websites/' + websiteId + '/robots');
}



export function loadVirtualRobotsRulesViaApi(accountId, websiteId) {
	return get('/teams/' + accountId + '/websites/' + websiteId + '/virtual_robots');
}



export function saveVirtualRobotsRulesViaApi(websiteId, rules) {
	return runAction({
		action: 'UpdateUrlExclusionList',
		input: {
			rules,
			websiteId,
		},
	});
}
