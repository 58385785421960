import classNames from 'classnames';
import {
	format,
} from 'date-fns';
import React from 'react';

import ValuesRange from '~/components/patterns/values/ValuesRange';



export enum DateRangeSelectTogglerSize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	endDate: Date | null,
	isOpen?: boolean,
	label: React.ReactNode,
	/** Size of toggler */
	size?: DateRangeSelectTogglerSize,
	startDate: Date | null,
};



const DateRangeSelectToggler: React.FC<Props> = (props) => {
	const {
		endDate,
		isOpen,
		label,
		size = DateRangeSelectTogglerSize.Default,
		startDate,
	} = props;

	const componentClasses = classNames({
		'date-range-select-toggler': true,
		'date-range-select-toggler--open': isOpen,
		'date-range-select-toggler--closed': !isOpen,
		'date-range-select-toggler--small': size === DateRangeSelectTogglerSize.Small,
	});

	const labelClasses = classNames({
		'date-range-select-toggler__label': true,
		'date-range-select-toggler__label--placeholder': !label,
	});

	return (
		<div className={componentClasses}>
			<div className={labelClasses}>
				<div className="date-range-select-toggler__ellipsis">
					{label || '-'}
				</div>
			</div>
			{(startDate || endDate) && (
				<div className="date-range-select-toggler__date-range">
					<div className="date-range-select-toggler__ellipsis">
						<ValuesRange
							from={startDate ? format(startDate, 'd MMM yyyy') : undefined}
							to={endDate ? format(endDate, 'd MMM yyyy') : undefined}
						/>
					</div>
				</div>
			)}
		</div>
	);
};



export default DateRangeSelectToggler;
