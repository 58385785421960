import {
	format,
} from 'date-fns';
import React from 'react';
import times from 'lodash/times';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ScrollWheelPicker from '~/components/patterns/forms/fieldParts/ScrollWheelPicker';



type Props = {
	/** End year of picker */
	endYear?: number,
	/** Callback triggered when doing confirmation of selected date */
	onSubmitCallback: (date: Date) => void,
	/** Start year of picker */
	startYear?: number,
	/** Label of submit button */
	submitButtonLabel: React.ReactNode,
	/** Date of selected calendar view */
	value: Date,
};



const monthOptions = times(12, (index) => ({
	label: format(new Date().setMonth(index), 'MMM'),
	value: index,
}));



const MonthYearPicker: React.FC<Props> = (props) => {
	const {
		value,
		endYear = new Date().getFullYear() + 5,
		onSubmitCallback,
		startYear = new Date().getFullYear() - 5,
		submitButtonLabel,
	} = props;

	const [selectedYear, setSelectedYear] = React.useState(value.getFullYear());
	const [selectedMonth, setSelectedMonth] = React.useState(value.getMonth());

	const yearOptions = React.useMemo(
		() => {
			return times(endYear - startYear + 1, (index) => ({
				label: startYear + index,
				value: startYear + index,
			}));
		},
		[
			endYear,
			startYear,
		],
	);

	const handleSubmit = React.useCallback(
		() => {
			const finalDate = new Date();
			finalDate.setMonth(selectedMonth);
			finalDate.setFullYear(selectedYear);

			onSubmitCallback(finalDate);
		},
		[
			onSubmitCallback,
			selectedMonth,
			selectedYear,
		],
	);

	return (
		<div className="month-year-picker">
			<div className="month-year-picker__current-month">
				{format(value, 'MMM yyyy')}
			</div>
			<div className="month-year-picker__picker">
				<ScrollWheelPicker
					onClickCallback={(value) => setSelectedMonth(value)}
					options={monthOptions}
					value={selectedMonth}
				/>
				<ScrollWheelPicker
					onClickCallback={(value) => setSelectedYear(value)}
					options={yearOptions}
					value={selectedYear}
				/>
			</div>
			<div className="month-year-picker__button">
				<Button
					onClick={handleSubmit}
					size={ButtonSize.XSmall}
					style={ButtonStyle.Hollow}
				>
					{submitButtonLabel}
				</Button>
			</div>
		</div>
	);
};



export default MonthYearPicker;
