import React from 'react';

import {
	useIsAnyOnboardingTourInProgressQuery,
} from './useIsAnyOnboardingTourInProgress.gql';



function useIsAnyOnboardingTourInProgress() {
	const { data } = useIsAnyOnboardingTourInProgressQuery();

	return React.useMemo(
		() => {
			return data?.authenticatedSession?.memberships.some(
				(membership) => membership.isOnboardingTourInProgress,
			) ?? null;
		},
		[
			data,
		],
	);
}



export default useIsAnyOnboardingTourInProgress;
