import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import {
	CSSTransition,
} from 'react-transition-group';
import {
	usePopper,
} from 'react-popper';

import DepthLayer from '~/components/patterns/utils/DepthLayer';
import InterfaceMessage, {
	InterfaceMessageArrowDirection,
	InterfaceMessageArrowType,
	type InterfaceMessageRef,
} from '~/components/patterns/messages/popup/InterfaceMessage';



type Props = {
	bubble: React.ReactNode,
	disabled?: boolean,
	children?: React.ReactNode,
	inline?: boolean,
	onCloseCallback?: () => void,
	onOpenCallback?: () => void,
	showOpenedByDefault?: boolean,
	toggleIconPosition?: {
		bottom?: number | string,
		left?: number | string,
		right?: number | string,
		top?: number | string,
	},
};



const popperDropdownContainer = document.getElementById('popper-elements');

const InterfaceHint: React.FC<Props> = (props) => {
	const {
		bubble,
		children,
		disabled = false,
		inline = true,
		onCloseCallback,
		onOpenCallback,
		showOpenedByDefault = false,
		toggleIconPosition = {
			bottom: -14,
			left: '50%',
		},
	} = props;

	const [alreadyClosed, setAlreadyClosed] = React.useState<boolean>(false);
	const [visiblePopup, setVisiblePopup] = React.useState<boolean>(showOpenedByDefault);

	const bubbleRef = React.useRef<InterfaceMessageRef>(null);
	const hintRef = React.useRef<HTMLSpanElement | null>(null);
	const [referenceElement, setReferenceElement] = React.useState<HTMLSpanElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);

	const offsetModifier = React.useMemo(
		() => ({
			name: 'offset',
			options: {
				offset: ({ placement }) => {
					if (placement === 'bottom-start' || placement === 'top-start') {
						return [-15, -7];
					}

					if (placement === 'bottom-end' || placement === 'top-end') {
						return [15, -7];
					}

					return [0, 0];
				},
			},
		}),
		[],
	);

	const {
		state: popperState,
		styles,
		attributes,
		update: updatePopper,
	} = usePopper(
		referenceElement,
		popperElement,
		{
			placement: 'bottom-start',
			modifiers: [
				{
					name: 'flip',
					options: {
						boundary: 'clippingParents',
						altBoundary: false,
						// First useful placement will be used when there won't be enough space
						// for default placement.
						fallbackPlacements: ['bottom-start', 'bottom-end', 'top-start', 'top-end'],
					},
				},
				{
					name: 'preventOverflow',
					options: {
						mainAxis: false,
					},
				},
				{
					name: 'arrow',
					options: {
						element: bubbleRef.current?.getArrowRef().current,
					},
				},
				offsetModifier,
			],
		},
	);

	const handleOpen = React.useCallback(
		() => {
			if (visiblePopup) {
				return false;
			}

			if (onOpenCallback) {
				onOpenCallback();
			}

			setVisiblePopup(true);
		},
		[
			onOpenCallback,
			visiblePopup,
		],
	);

	const handleClose = React.useCallback(
		() => {
			setAlreadyClosed(true);
			setVisiblePopup(false);

			if (onCloseCallback) {
				onCloseCallback();
			}
		},
		[
			onCloseCallback,
		],
	);

	React.useEffect(
		() => {
			if (
				!disabled
				&& !visiblePopup
				&& !alreadyClosed
				&& showOpenedByDefault
			) {
				handleOpen();
			}
		},
		[
			alreadyClosed,
			disabled,
			handleOpen,
			showOpenedByDefault,
			visiblePopup,
		],
	);

	React.useEffect(
		() => {
			if (visiblePopup && updatePopper) {
				updatePopper();
			}
		},
		[
			updatePopper,
			visiblePopup,
		],
	);

	React.useEffect(
		() => {
			if (!visiblePopup) {
				return;
			}

			function handleOutsideClick(event: MouseEvent) {
				const target = event.target;

				if (target === null || !(target instanceof HTMLElement)) {
					return;
				}

				if (!(hintRef.current?.contains(target) || bubbleRef.current?.containsTarget(target))) {
					handleClose();
				}
			}

			document.addEventListener('click', handleOutsideClick, { capture: true });

			return () => {
				document.removeEventListener('click', handleOutsideClick, { capture: true });
			};
		},
		[
			handleClose,
			visiblePopup,
		],
	);

	const togglePopup = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (!visiblePopup) {
			handleOpen();
		} else {
			handleClose();
		}
	};

	const componentClasses = classNames({
		'interface-hint': true,
		'interface-hint--open': visiblePopup,
		'interface-hint--inline': inline,
	});

	return (
		<DepthLayer>
			{({ depthLayer }) => (
				<span
					className={componentClasses}
					ref={hintRef}
				>
					{children}

					{!disabled && (
						<>
							<span
								className={classNames({
									'interface-hint__toggle-icon': true,
									'interface-hint__toggle-icon--closed': alreadyClosed,
								})}
								data-popper-placement={popperState && popperState.placement}
								onClick={togglePopup}
								ref={setReferenceElement}
								style={toggleIconPosition}
							>
								(?)
							</span>
							{popperDropdownContainer && ReactDOM.createPortal(
								<div
									ref={setPopperElement}
									style={{
										zIndex: depthLayer,
										...styles.popper,
									}}
									{...attributes.popper}
								>
									<CSSTransition
										className="interface-hint__bubble-target"
										classNames={{
											enter: 'interface-hint__bubble--before-opening',
											enterActive: 'interface-hint__bubble--opening',
											exit: 'interface-hint__bubble--before-closing',
											exitActive: 'interface-hint__bubble--closing',
										}}
										in={visiblePopup}
										mountOnEnter={true}
										timeout={250}
										unmountOnExit={true}
									>
										<div className="interface-hint__bubble">
											<InterfaceMessage
												arrowDirection={InterfaceMessageArrowDirection.Top}
												arrowStyles={styles.arrow}
												arrowType={InterfaceMessageArrowType.Triangle}
												onCloseCallback={handleClose}
												ref={bubbleRef}
											>
												{bubble}
											</InterfaceMessage>
										</div>
									</CSSTransition>
								</div>,
								popperDropdownContainer,
							)}
						</>
					)}
				</span>
			)}
		</DepthLayer>
	);
};



export default InterfaceHint;
