import React from 'react';

import {
	useDispatch,
	useSelector,
} from 'react-redux';

import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountId from '~/hooks/useAccountId';
import useAccountMaximumSignupPageBundle from '~/hooks/useAccountMaximumSignupPageBundle';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountType from '~/hooks/useAccountType';

import {
	loadTariffs,
} from '~/actions/tariffs';

import {
	TailoredTariff,
} from '~/model/pricing/tailoredTariff';

import {
	tariffsSelector,
} from '~/state/tariffs/selectors';



export type LoadingTariffs = {
	isReady: false,
	tariff: null,
	tariffs?: Array<unknown>,
};

export type LoadedTariffs = {
	isReady: true,
	tariff: TailoredTariff,
	tariffs: Array<unknown>,
};



function useTariffs(): LoadingTariffs | LoadedTariffs {
	const accountId = useAccountId();

	const accountCurrency = useAccountCurrency(accountId);
	const accountMaximumSignupPageBundle = useAccountMaximumSignupPageBundle(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountType = useAccountType(accountId);
	const dispatch = useDispatch();
	const tariffs = useSelector(tariffsSelector);

	React.useEffect(
		() => {
			dispatch(loadTariffs());
		},
		[
			dispatch,
		],
	);

	return React.useMemo(
		() => {
			if (
				accountCurrency === null
				|| accountMaximumSignupPageBundle === null
				|| accountType === null
				|| accountTariff === null
				|| !tariffs
			) {
				return {
					isReady: false,
					tariff: null,
					tariffs,
				};
			}

			return {
				isReady: true,
				tariff: new TailoredTariff(
					accountType,
					accountCurrency,
					accountMaximumSignupPageBundle,
					accountTariff,
					tariffs,
				),
				tariffs,
			};
		},
		[
			accountCurrency,
			accountMaximumSignupPageBundle,
			accountType,
			accountTariff,
			tariffs,
		],
	);
}



export default useTariffs;
