import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import BlankValue from '~/components/patterns/values/BlankValue';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ButtonWithDropdown, {
	ButtonWithDropdownIconType,
	ButtonWithDropdownSize,
} from '~/components/app/ButtonWithDropdown';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import Copy from '~/components/logic/Copy';
import CopySegmentToWebsitesModal from '~/components/app/CopySegmentToWebsitesModal';
import DeleteSegmentConfirmationModal from '~/components/app/DeleteSegmentConfirmationModal';
import DragHandler, {
	DragHandlerVisibility,
} from '~/components/patterns/icons/DragHandler';
import FilterDefinitionFormatter, {
	FilterDefinitionFormatterStyle,
} from '~/components/logic/filters/FilterDefinitionFormatter';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';
import LinkTarget, {
	LinkTargetKeepParameter,
} from '~/components/logic/LinkTarget';
import ManagementTable from '~/components/patterns/tables/ManagementTable';
import NewTableCell, {
	NewTableCellSize,
	NewTableCellType,
} from '~/components/patterns/tables/newTable/NewTableCell';
import NewTableRow from '~/components/patterns/tables/newTable/NewTableRow';
import SegmentDefinitionIdentifier from '~/components/logic/segments/SegmentDefinitionIdentifier';
import StickToScreenBottom, {
	StickToScreenBottomPreset,
} from '~/components/patterns/utils/StickToScreenBottom';
import TableActionButton, {
	TableActionButtonIconType,
} from '~/components/patterns/tables/datatables/buttons/TableActionButton';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	useReorderSegmentsMutation,
} from '~/components/app/SegmentDefinitionsOverview.gql';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useModals from '~/hooks/useModals';
import useUserEmail from '~/hooks/useUserEmail';
import useUserSegmentsDisplayOrder from '~/hooks/useUserSegmentsDisplayOrder';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	ObjectType,
} from '~/model/permissions';

import {
	type SegmentDefinition,
	isSegmentStatic,
	modifySegmentsDisplayOrder,
} from '~/model/segments';



const messages = defineMessages({
	blankSlate: {
		id: 'ui.segments.overview.blankSlate',
	},
	cannotModifyManagedSegment: {
		id: 'ui.segmentsManagement.cannotModifyManaged',
	},
	createSegmentButton: {
		id: 'ui.segments.management.createSegmentButton',
	},
	createSegmentButtonClassicOption: {
		id: 'ui.segments.management.createSegmentButtonClassicOption',
	},
	createSegmentButtonStaticOption: {
		id: 'ui.segments.management.createSegmentButtonStaticOption',
	},
	criteria: {
		id: 'ui.segments.overview.columns.criteria',
	},
	identifier: {
		id: 'ui.segments.overview.columns.identifier',
	},
	importSegmentsButton: {
		id: 'ui.segments.management.importSegmentsButton',
	},
	noCriteria: {
		id: 'ui.segments.overview.noCriteria',
	},
	noSizeLimit: {
		id: 'ui.segments.overview.noSizeLimit',
	},
	sizeLimit: {
		id: 'ui.segments.overview.columns.sizeLimit',
	},
	title: {
		id: 'ui.segments.overview.title',
	},
});



const SegmentDefinitionsOverview: React.FC = () => {
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const modals = useModals();
	const viewportType = useViewportType();

	const legacyUserId = useCurrentUserId();
	const userEmail = useUserEmail(legacyUserId);
	const userSegmentsDisplayOrder = useUserSegmentsDisplayOrder(legacyUserId);

	const websiteId = useWebsiteId();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const [reorderSegments] = useReorderSegmentsMutation();

	const showManipulationButtons = viewportType.isSmall === false && viewportType.isMedium === false;
	const showAdminFeatures = kingdomAdminFeatures.areVisible;

	const handleReorder = React.useCallback(
		async (rows, _, destinationIndex) => {
			if (
				userEmail === null
				|| legacyUserId === null
				|| userSegmentsDisplayOrder === null
			) {
				return;
			}

			const nextSegmentsDisplayOrder = modifySegmentsDisplayOrder(
				rows[destinationIndex].segmentIdentifier,
				userSegmentsDisplayOrder,
				rows.map((row) => row.segmentIdentifier),
			);

			await reorderSegments({
				variables: {
					email: userEmail,
					legacyUserId,
					segmentsDisplayOrder: nextSegmentsDisplayOrder,
				},
				optimisticResponse: {
					__typename: 'Mutation',
					UpdateUserSegmentsDisplayOrder: {
						__typename: 'BasicMutationResult',
						query: {
							__typename: 'Query',
							user: {
								__typename: 'User',
								id: legacyUserId,
								legacyId: legacyUserId,
								segmentsDisplayOrder: nextSegmentsDisplayOrder,
							},
						},
					},
				},
			});
		},
		[
			legacyUserId,
			reorderSegments,
			userSegmentsDisplayOrder,
			userEmail,
		],
	);

	const rows = React.useMemo(
		() => {
			if (segmentDefinitions.isLoaded === false) {
				return [];
			}

			return segmentDefinitions.listAll().map((definition) => ({
				id: definition.id,
				segmentDefinition: definition,
				segmentIdentifier: {
					color: definition.color,
					iconName: definition.icon?.name ?? null,
					label: definition.label,
					shortcode: definition.shortcode,
				},
			}));
		},
		[
			segmentDefinitions,
		],
	);

	const openDeleteSegmentConfirmationModal = React.useCallback(
		(segmentDefinition: SegmentDefinition) => {
			modals.openModal(
				() => (
					<DeleteSegmentConfirmationModal
						segmentDefinition={segmentDefinition}
						websiteId={websiteId}
					/>
				),
			);
		},
		[
			modals,
			websiteId,
		],
	);

	const openCopySegmentToWebsitesModal = React.useCallback(
		(segmentDefinition: SegmentDefinition) => {
			modals.openModal(
				() => (
					<CopySegmentToWebsitesModal
						segmentDefinition={segmentDefinition}
					/>
				),
			);
		},
		[
			modals,
		],
	);

	function renderHeaderButtons() {
		return (
			<WithPermission
				action={GraphQL.ActionWithWebsite.ManageSegments}
				objectId={websiteId}
				objectType={ObjectType.Website}
				showMessage={false}
			>
				{({ isAllowed, message: permissionMessage }) => (
					<ButtonsGroup>
						<ButtonWithDropdown
							actions={[
								{
									includeBacklink: true,
									label: (
										<Copy {...messages.createSegmentButtonClassicOption} />
									),
									linkRouteName: 'website.segmentEditor.create',
									linkRouteParams: {
										websiteId,
									},
								},
								{
									includeBacklink: true,
									label: (
										<Copy {...messages.createSegmentButtonStaticOption} />
									),
									linkRouteName: 'website.staticSegmentEditor.create',
									linkRouteParams: {
										websiteId,
									},
								},
							]}
							iconColor="#42cc67"
							iconType={ButtonWithDropdownIconType.Plus}
							isDisabled={isAllowed.yes === false}
							label={(
								<FormattedMessage {...messages.createSegmentButton} />
							)}
							size={ButtonWithDropdownSize.Small}
							tooltip={permissionMessage}
						/>

						<LinkTarget
							extraRouteParams={{
								action: 'import_segments',
							}}
						>
							{({ routeName, routeParams }) => (
								<Button
									disabled={isAllowed.yes === false}
									icon={(
										<BasicIcon type={BasicIconType.Import} />
									)}
									linkRouteName={routeName}
									linkRouteParams={routeParams}
									size={ButtonSize.Small}
									style={ButtonStyle.Hollow}
									tooltip={permissionMessage}
									uppercase={true}
								>
									<FormattedMessage {...messages.importSegmentsButton} />
								</Button>
							)}
						</LinkTarget>
					</ButtonsGroup>
				)}
			</WithPermission>
		);
	}

	const blankSlate = segmentDefinitions.count === 0 ? (
		<InterfaceMessage>
			<FormattedMessage {...messages.blankSlate} />
		</InterfaceMessage>
	) : null;

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageSegments}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed, message: permissionMessage }) => (
				<StickToScreenBottom
					minHeight={450}
					preset={StickToScreenBottomPreset.FullscreenDetail}
				>
					{({ width, height }) => (
						<ManagementTable
							blankSlate={blankSlate}
							buttons={renderHeaderButtons()}
							columnLabels={(
								<>
									<FormattedMessage {...messages.identifier} />
									<FormattedMessage {...messages.criteria} />
									<FormattedMessage {...messages.sizeLimit} />
									{showManipulationButtons && (
										<NewTableCell type={NewTableCellType.Header} />
									)}
								</>
							)}
							columnWidths={(
								showAdminFeatures ? (
									[220, 'auto', 'auto', 165]
								) : showManipulationButtons ? (
									[220, 'auto', 'auto', 120]
								) : (
									[220, 'auto', 'auto']
								)
							)}
							height={[0, height]}
							isLoading={segmentDefinitions.isLoaded === false}
							isReorderable={true}
							onReorder={handleReorder}
							rows={rows}
							title={(
								<FormattedMessage {...messages.title} />
							)}
							width={[750, Math.min(width, 900)]}
						>
							{({ row, dragHandleProps, isDragging }) => {
								const {
									segmentDefinition,
								} = row;

								const isManagedSegment = segmentDefinition.isManaged;

								return (
									<NewTableRow>
										{({ hovered }) => (
											<>
												<NewTableCell size={NewTableCellSize.Small}>
													<SegmentDefinitionIdentifier
														segmentDefinition={segmentDefinition}
														showCriteria={false}
													/>
												</NewTableCell>

												<NewTableCell size={NewTableCellSize.Small}>
													{Object.values(segmentDefinition.filterDefinition).length > 0 ? (
														<FilterDefinitionFormatter
															filterDefinition={segmentDefinition.filterDefinition}
															style={FilterDefinitionFormatterStyle.Inline}
														/>
													) : (
														<BlankValue>
															<FormattedMessage {...messages.noCriteria} />
														</BlankValue>
													)}
												</NewTableCell>

												<NewTableCell size={NewTableCellSize.Small}>
													{segmentDefinition.sizeLimit !== null ? (
														<FilterDefinitionFormatter
															sizeLimitDefinition={segmentDefinition.sizeLimit}
															style={FilterDefinitionFormatterStyle.Inline}
														/>
													) : (
														<BlankValue>
															<FormattedMessage {...messages.noSizeLimit} />
														</BlankValue>
													)}
												</NewTableCell>

												{showManipulationButtons && (
													<NewTableCell>
														<ButtonsGroup>
															<LinkTarget
																includeBacklink={true}
																routeName={isSegmentStatic(segmentDefinition) ? 'website.staticSegmentEditor.edit' : 'website.segmentEditor.edit'}
																routeParams={{
																	segmentName: segmentDefinition.name,
																	websiteId: LinkTargetKeepParameter,
																}}
															>
																{({ routeName, routeParams }) => (
																	<TableActionButton
																		disabled={(
																			isAllowed.yes === false
																			|| isManagedSegment === true
																		)}
																		iconType={TableActionButtonIconType.CogWheel}
																		linkRouteName={routeName}
																		linkRouteParams={routeParams}
																		tooltip={(
																			isAllowed.yes === false
																				? permissionMessage
																				: isManagedSegment
																					? <FormattedMessage {...messages.cannotModifyManagedSegment} />
																					: 'Edit segment'
																		)}
																	/>
																)}
															</LinkTarget>

															<TableActionButton
																disabled={isAllowed.yes === false}
																iconType={TableActionButtonIconType.Delete}
																onClick={() => {
																	openDeleteSegmentConfirmationModal(segmentDefinition);
																}}
																tooltip={permissionMessage ?? 'Delete segment'}
															/>

															{showAdminFeatures && (
																<TableActionButton
																	iconType={TableActionButtonIconType.Export}
																	onClick={() => {
																		openCopySegmentToWebsitesModal(segmentDefinition);
																	}}
																	tooltip="Export segment"
																/>
															)}

															<DragHandler
																additionalProps={dragHandleProps}
																visibility={(
																	isDragging ? (
																		DragHandlerVisibility.Visible
																	) : hovered ? (
																		DragHandlerVisibility.Hoverable
																	) : (
																		DragHandlerVisibility.Hidden
																	)
																)}
															/>
														</ButtonsGroup>
													</NewTableCell>
												)}
											</>
										)}
									</NewTableRow>
								);
							}}
						</ManagementTable>
					)}
				</StickToScreenBottom>
			)}
		</WithPermission>
	);
};



export default SegmentDefinitionsOverview;
