import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import EnterSudoModeForm from '~/components/app/EnterSudoModeForm';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import SetupTwoFActorAuthenticationModalSetupStep from '~/components/app/SetupTwoFactorAuthenticationModalSetupStep';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useSudoModeStatus from '~/hooks/useSudoModeStatus';



const messages = defineMessages({
	closeButton: {
		id: 'ui.general.close',
	},
	codePrompt: {
		id: 'ui.twoFactorAuthenticationSetup.codePrompt',
	},
	qrcodePrompt: {
		id: 'ui.twoFactorAuthenticationSetup.qrCodePrompt',
	},
	setupStepButton: {
		id: 'ui.twoFactorAuthenticationSetup.submit',
	},
	sidebar: {
		id: 'ui.twoFactorAuthenticationSetup.sidebar',
	},
	successMessage: {
		id: 'ui.twoFactorAuthenticationSetup.successMessage',
	},
	successTitle: {
		id: 'ui.twoFactorAuthenticationSetup.successTitle',
	},
	title: {
		id: 'ui.twoFactorAuthenticationSetup.title',
	},
});



const SetupTwoFactorAuthenticationModal: React.FC = () => {
	const multiStepModalRef = React.useRef<MultiStepModalRef | null>(null);

	const classicFormBehavior = useClassicFormBehavior();
	const sudoModeStatus = useSudoModeStatus(GraphQL.MethodToEnterSudoMode.TwoFactorAuthentication);

	const handleRequireSudoMode = React.useCallback(
		() => {
			multiStepModalRef.current?.goToStep('enterSudoMode');
		},
		[
			multiStepModalRef,
		],
	);

	function renderSidebar() {
		return (
			<RichText>
				<Copy
					{...messages.sidebar}
					values={{
						linkAndroidApp: linkExternal('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'),
						linkArticle: linkExternal('https://www.contentkingapp.com/support/two-factor-authentication/'),
						linkIosApp: linkExternal('https://apps.apple.com/us/app/google-authenticator/id388497605'),
					}}
				/>
			</RichText>
		);
	}

	return (
		<MultiStepModal
			initialStep={(
				sudoModeStatus.suggestedMethodToEnter === GraphQL.MethodToEnterSudoMode.Password ? 0 : 1
			)}
			key={sudoModeStatus.isLoading ? 'loading' : 'ready'}
			ref={multiStepModalRef}
		>
			<MultiStepModalStep
				name="enterSudoMode"
				sidebar={renderSidebar()}
				title={(
					<FormattedMessage {...messages.title} />
				)}
			>
				{({ isActive }) => isActive && (
					<EnterSudoModeForm
						description="Before setting up the two-factor authentication, please enter your password."
						disallowedMethod={GraphQL.MethodToEnterSudoMode.TwoFactorAuthentication}
					/>
				)}
			</MultiStepModalStep>

			<MultiStepModalStep
				name="setupStep"
				sidebar={renderSidebar()}
				title={(
					<FormattedMessage {...messages.title} />
				)}
			>
				{({ isActive }) => (
					<SetupTwoFActorAuthenticationModalSetupStep
						key={isActive ? 'active' : 'inactive'}
						onRequireSudoMode={handleRequireSudoMode}
					/>
				)}
			</MultiStepModalStep>

			<MultiStepModalStep
				name="confirmationStep"
				title={(
					<FormattedMessage {...messages.title} />
				)}
			>
				{({ isActive }) => (isActive ? (
					<ConfirmationMessage
						animate={true}
						context={ConfirmationMessageContext.Confirmation}
						ctaButtons={(
							<Button
								onClick={classicFormBehavior.finish}
								style={ButtonStyle.Action}
								uppercase={false}
								width={ButtonWidth.SubmitButton}
							>
								<FormattedMessage {...messages.closeButton} />
							</Button>
						)}
						message={(
							<FormattedMessage {...messages.successTitle} />
						)}
					>
						<FormattedMessage {...messages.successMessage} />
					</ConfirmationMessage>
				) : null)}
			</MultiStepModalStep>
		</MultiStepModal>
	);
};

export default SetupTwoFactorAuthenticationModal;
