import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';



type Props = {
	/** Enable animations when adding/removing new cards */
	animations?: boolean,
	/** Blank slate visible when no children present */
	blankSlate?: React.ReactNode,
	children?: React.ReactNode,
	/** Attached overview above list */
	overview?: React.ReactNode,
};

const IssueCardsList: React.FC<Props> = (props) => {
	const {
		animations = true,
		blankSlate,
		children,
		overview,
	} = props;

	function renderOverview() {
		if (!overview) {
			return null;
		}

		return (
			<div className="issue-cards-list__overview">
				{overview}
			</div>
		);
	}

	function renderBlankSlate() {
		const childrenCount = flattenChildren(children).length;

		if (childrenCount > 0) {
			return null;
		}

		return (
			<div className="issue-cards-list__blank-slate">
				{blankSlate}
			</div>
		);
	}

	return (
		<TransitionGroup
			className="issue-cards-list"
			enter={animations}
			exit={animations}
		>
			{renderOverview()}

			{renderBlankSlate()}

			{flattenChildren(children).filter(React.isValidElement).map(
				(child, index) => {
					return (
						<CSSTransition
							className="issue-cards-list__item"
							classNames={{
								enter: 'issue-cards-list__item--before-appearing',
								enterActive: 'issue-cards-list__item--appearing',
								exit: 'issue-cards-list__item--before-disappearing',
								exitActive: 'issue-cards-list__item--disappearing',
							}}
							key={child.key ?? 'issue-cards-list-card-' + index}
							timeout={600}
						>
							<div>
								{child}
							</div>
						</CSSTransition>
					);
				},
			)}
		</TransitionGroup>
	);
};


export default IssueCardsList;
