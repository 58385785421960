import classNames from 'classnames';
import React from 'react';

import ModalContentWithSidebar from '~/components/atoms/modals/parts/ModalContentWithSidebar';
import {
	MultiStepModalContext,
	MultiStepModalStepIndex,
	type Step,
} from './MultiStepModal';
import MultiStepModalHeader, {
	MultiStepModalHeaderIconType as MultiStepModalStepHeaderIconType,
} from './MultiStepModalHeader';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	breadcrumb?: React.ReactNode,
	headerIconType?: MultiStepModalStepHeaderIconType,
	children?: RenderProp<ChildProps>,
	name: string,
	preloader?: React.ReactNode,
	sidebar?: RenderProp<ChildProps>,
	slimSidebar?: boolean,
	title?: React.ReactNode,
};

type ChildProps = {
	goToNextStep: () => void,
	goToPreviousStep: () => void,
	goToStep: (name: string) => void,
	isActive: boolean,
	step: Step,
};



const MultiStepModalStep: React.FC<Props> = (props) => {
	const {
		breadcrumb,
		headerIconType,
		children,
		name,
		preloader: preloaderProps,
		sidebar,
		slimSidebar,
		title,
	} = props;

	const multiStepModalContext = React.useContext(MultiStepModalContext);

	if (multiStepModalContext === null) {
		throw new Error(
			`Can't be used outside of MultiStepModalContext`,
		);
	}

	const {
		activeStep,
		fullheight,
		goToNextStep,
		goToPreviousStep,
		goToStep,
		minHeight,
		preloader: preloaderContext,
		registerStep,
	} = multiStepModalContext;

	const preloader = preloaderProps || preloaderContext;

	const index = React.useContext(MultiStepModalStepIndex);

	const step = {
		breadcrumb,
		headerIconType,
		name,
		title,
	};

	React.useEffect(
		() => {
			return registerStep(step, index);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	if (index === -1) {
		return null;
	}

	const isActive = activeStep === index;
	const isBeforeActive = activeStep > index;
	const isAfterActive = activeStep < index;

	const hasSidebar = !!sidebar;

	const childProps = {
		goToNextStep,
		goToPreviousStep,
		goToStep,
		isActive,
		step,
	};

	return (
		<ModalContentWithSidebar
			fullheight={fullheight}
			gapsSize={2}
			hiddenSidebar={hasSidebar && !isActive}
			sidebar={
				hasSidebar ? (
					isActive ? (
						renderProp(sidebar, childProps) || <></>
					) : (
						<></>
					)
				) : null
			}
			slimSidebar={slimSidebar}
		>
			{isActive ? (
				<MultiStepModalHeader
					iconType={headerIconType}
					title={title}
				/>
			) : null}

			<div
				className={classNames({
					'multi-step-modal-step': true,
					'multi-step-modal-step--active': isActive,
					'multi-step-modal-step--before-active': isBeforeActive,
					'multi-step-modal-step--after-active': isAfterActive,
				})}
				style={{ minHeight }}
			>
				{preloader || renderProp(children, childProps) || null}
			</div>
		</ModalContentWithSidebar>
	);
};

export { MultiStepModalStepHeaderIconType };

export default MultiStepModalStep;
