import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import DateTime from '~/components/app/DateTime';
import FeedbackControls from './FeedbackControls';
import IncidentBoxFooter from '~/components/patterns/incidents/IncidentBoxFooter';
import IncidentDetails from './IncidentDetails';
import SharedIncidentCardLayout from './SharedIncidentCardLayout';
import ValuesRange from '~/components/patterns/values/ValuesRange';



class PastIncidentCard extends Component {

	_renderTimestamps() {
		const {
			incident,
		} = this.props;

		if (incident.get('opened_at') === incident.get('closed_at')) {
			return (
				<div>
					<DateTime datetime={incident.get('opened_at')} />
				</div>
			);
		}

		return (
			<ValuesRange
				from={(
					<DateTime datetime={incident.get('opened_at')} />
				)}
				to={(
					<DateTime datetime={incident.get('closed_at')} />
				)}
			/>
		);
	}



	render() {
		const {
			incident,
			openAlertId,
			websiteId,
		} = this.props;

		return (
			<SharedIncidentCardLayout
				footer={[
					(
						<IncidentBoxFooter
							centered={true}
							key="timestamps"
						>
							{this._renderTimestamps()}
						</IncidentBoxFooter>
					),
					(
						<FeedbackControls
							incident={incident}
							key="feedback"
						/>
					),
				]}
				incident={incident}
				openAlertId={openAlertId}
			>
				<IncidentDetails
					incident={incident}
					websiteId={websiteId}
				/>
			</SharedIncidentCardLayout>
		);
	}

}

PastIncidentCard.propTypes = {
	incident: PropTypes.object.isRequired,
	openAlertId: PropTypes.number,
	websiteId: PropTypes.string.isRequired,
};



export default PastIncidentCard;
