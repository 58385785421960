/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAdminDebuggingFormQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAdminDebuggingFormQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly collectDetailedDiagnostics: boolean, readonly fetchingLogLevel: string } | null } | null };

export type UpdateWebsiteAdminDebuggingMutationVariables = GraphQL.Exact<{
  collectDetailedDiagnostics: GraphQL.Scalars['Boolean']['input'];
  fetchingLogLevel: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteAdminDebuggingMutation = { readonly __typename?: 'Mutation', readonly AdministerWebsiteSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly collectDetailedDiagnostics: boolean, readonly fetchingLogLevel: string } | null } | null } } };


export const WebsiteAdminDebuggingFormDocument = gql`
    query WebsiteAdminDebuggingForm($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    adminSettings {
      collectDetailedDiagnostics
      fetchingLogLevel
    }
  }
}
    `;

/**
 * __useWebsiteAdminDebuggingFormQuery__
 *
 * To run a query within a React component, call `useWebsiteAdminDebuggingFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAdminDebuggingFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAdminDebuggingFormQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAdminDebuggingFormQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAdminDebuggingFormQuery, WebsiteAdminDebuggingFormQueryVariables> & ({ variables: WebsiteAdminDebuggingFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAdminDebuggingFormQuery, WebsiteAdminDebuggingFormQueryVariables>(WebsiteAdminDebuggingFormDocument, options);
      }
export function useWebsiteAdminDebuggingFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAdminDebuggingFormQuery, WebsiteAdminDebuggingFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAdminDebuggingFormQuery, WebsiteAdminDebuggingFormQueryVariables>(WebsiteAdminDebuggingFormDocument, options);
        }
export function useWebsiteAdminDebuggingFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteAdminDebuggingFormQuery, WebsiteAdminDebuggingFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAdminDebuggingFormQuery, WebsiteAdminDebuggingFormQueryVariables>(WebsiteAdminDebuggingFormDocument, options);
        }
export type WebsiteAdminDebuggingFormQueryHookResult = ReturnType<typeof useWebsiteAdminDebuggingFormQuery>;
export type WebsiteAdminDebuggingFormLazyQueryHookResult = ReturnType<typeof useWebsiteAdminDebuggingFormLazyQuery>;
export type WebsiteAdminDebuggingFormSuspenseQueryHookResult = ReturnType<typeof useWebsiteAdminDebuggingFormSuspenseQuery>;
export type WebsiteAdminDebuggingFormQueryResult = Apollo.QueryResult<WebsiteAdminDebuggingFormQuery, WebsiteAdminDebuggingFormQueryVariables>;
export const UpdateWebsiteAdminDebuggingDocument = gql`
    mutation UpdateWebsiteAdminDebugging($collectDetailedDiagnostics: Boolean!, $fetchingLogLevel: String!, $websiteId: WebsiteId!) {
  AdministerWebsiteSettings(
    collectDetailedDiagnostics: $collectDetailedDiagnostics
    fetchingLogLevel: $fetchingLogLevel
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        adminSettings {
          collectDetailedDiagnostics
          fetchingLogLevel
        }
      }
    }
  }
}
    `;
export type UpdateWebsiteAdminDebuggingMutationFn = Apollo.MutationFunction<UpdateWebsiteAdminDebuggingMutation, UpdateWebsiteAdminDebuggingMutationVariables>;

/**
 * __useUpdateWebsiteAdminDebuggingMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteAdminDebuggingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteAdminDebuggingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteAdminDebuggingMutation, { data, loading, error }] = useUpdateWebsiteAdminDebuggingMutation({
 *   variables: {
 *      collectDetailedDiagnostics: // value for 'collectDetailedDiagnostics'
 *      fetchingLogLevel: // value for 'fetchingLogLevel'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteAdminDebuggingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteAdminDebuggingMutation, UpdateWebsiteAdminDebuggingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteAdminDebuggingMutation, UpdateWebsiteAdminDebuggingMutationVariables>(UpdateWebsiteAdminDebuggingDocument, options);
      }
export type UpdateWebsiteAdminDebuggingMutationHookResult = ReturnType<typeof useUpdateWebsiteAdminDebuggingMutation>;
export type UpdateWebsiteAdminDebuggingMutationResult = Apollo.MutationResult<UpdateWebsiteAdminDebuggingMutation>;
export type UpdateWebsiteAdminDebuggingMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteAdminDebuggingMutation, UpdateWebsiteAdminDebuggingMutationVariables>;