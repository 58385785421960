import {
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
	INITIALIZE_FROM_LOCAL_STORAGE,
	LOGIN_SUCCESSFUL,
	STORE_LOADED_TEAMS,
} from '~/actions';



const createDefaultState = () => {
	return new Map({
		id: null,
	});
};



export function lastSelectedWebsite(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			const urlState = action.urlState;

			if (urlState.params.websiteId) {
				state = state.set('id', urlState.params.websiteId);
			}

			break;
		}

		case INITIALIZE_FROM_LOCAL_STORAGE: {
			const {
				data,
			} = action;

			state = data.get('lastSelectedWebsite') || createDefaultState();

			break;
		}

		case STORE_LOADED_TEAMS: {
			const {
				teams,
			} = action;

			let currentWebsiteIdFound = false;

			for (const accountId in teams) {
				if (teams.hasOwnProperty(accountId)) {
					const account = teams[accountId];

					for (let i = 0; i < account.websites.length; i++) {
						const website = account.websites[i];

						if (state.get('id') === website.id) {
							currentWebsiteIdFound = true;
						}
					}
				}
			}

			if (!currentWebsiteIdFound) {
				state = state.set('id', null);
			}

			break;
		}

		case LOGIN_SUCCESSFUL: {
			if (action.resetData) {
				state = createDefaultState();
			}

			break;
		}

	}

	return state;
}
