import React from 'react';

import NoData from '~/components/app/NoData';
import RelativeTimeFormatter from '~/components/app/RelativeTimeFormatter';

import {
	isNumber,
} from '~/utilities/typeCheck';



type Props = {
	value?: string | number | Date,
};

const SearchEngineLastVisitFormatter: React.FC<Props> = (props) => {
	const {
		value,
	} = props;

	if (!value) {
		return (
			<NoData />
		);
	}

	let date: Date;
	if (isNumber(value)) {
		date = new Date(value * 1000);
	} else {
		date = new Date(value);
	}

	return (
		<RelativeTimeFormatter
			date={date}
			muted={true}
		/>
	);
};



export default SearchEngineLastVisitFormatter;
