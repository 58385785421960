import {
	useUserHasNameQuery,
} from './useUserHasName.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserHasName(legacyUserId: string | null): boolean | null {
	const { data } = useUserPropertiesQuery(
		useUserHasNameQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	if (!data || !data.user) {
		return null;
	}

	return data.user.firstName !== null && data.user.lastName !== null;
}



export default useUserHasName;
