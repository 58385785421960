import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import FieldStatus, {
	FieldStatusErrorMessageAlignment,
} from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import FormFieldsDecorator from '~/components/patterns/forms/others/FormFieldsDecorator';
import RecurlyField from '~/components/atoms/forms/components/RecurlyField';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	RECURLY_PUBLIC_KEY,
} from '~/config';

import {
	getRecurly,
} from '~/recurly/main';



const messages = defineMessages({
	cardNumberLabel: {
		id: 'ui.creditCard.cardNumber',
	},
	cvcPlaceholder: {
		id: 'ui.creditCard.cvc',
	},
	expiryAndCvcLabel: {
		id: 'ui.creditCard.expiryAndCvc',
	},
	firstNamePlaceholder: {
		id: 'ui.creditCard.firstNamePlaceholder',
	},
	lastNamePlaceholder: {
		id: 'ui.creditCard.lastNamePlaceholder',
	},
	monthShortcut: {
		id: 'ui.creditCard.monthShortcut',
	},
	nameLabel: {
		id: 'ui.creditCard.name',
	},
	yearShortcut: {
		id: 'ui.creditCard.yearShortcut',
	},
});



const CreditCardFields: React.FC = () => {
	const intl = useIntl();

	React.useEffect(
		() => {
			getRecurly((recurly) => {
				recurly.configure({
					publicKey: RECURLY_PUBLIC_KEY,
					style: {
						all: {
							fontColor: '#263340',
							fontFamily: 'Roboto:300,300i&amp;subset=latin,latin-ext',
							fontSize: '16px',
							fontSmoothing: 'antialiased',
							fontWeight: '300',
							placeholder: {
								color: '#98A5B3',
								fontFamily: 'Roboto',
								fontStyle: 'italic',
								fontWeight: '300',
								fontSmoothing: 'antialiased',
							},
						},
						cvv: {
							placeholder: {
								content: intl.formatMessage(messages.cvcPlaceholder),
							},
						},
						number: {
							placeholder: {
								content: intl.formatMessage(messages.cardNumberLabel),
							},
						},
						month: {
							placeholder: {
								content: intl.formatMessage(messages.monthShortcut),
							},
						},
						year: {
							placeholder: {
								content: intl.formatMessage(messages.yearShortcut),
							},
						},
					},
				});
			});
		},
		[
			intl,
		],
	);

	return (
		<FormRows>
			<FormRow
				htmlFor="first_name"
				label={(
					<FormattedMessage {...messages.nameLabel} />
				)}
			>
				<FieldStatus name="name">
					<TextField
						attributes={{ 'data-recurly': 'first_name' }}
						name="first_name"
						placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
						trimValue={true}
						width={100}
					/>
					<TextField
						attributes={{ 'data-recurly': 'last_name' }}
						name="last_name"
						placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
						trimValue={true}
						width={170}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.cardNumberLabel} />
				)}
			>
				<FieldStatus name="number">
					<RecurlyField name="number" />
				</FieldStatus>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.expiryAndCvcLabel} />
				)}
			>
				<FieldStatus
					errorMessageAlignment={FieldStatusErrorMessageAlignment.Right}
					errorMessageWidth={220}
					name="year_and_month"
				>
					<FormFieldsDecorator divider="/">
						<RecurlyField
							name="month"
							width={53}
						/>
						<RecurlyField
							name="year"
							width={53}
						/>
					</FormFieldsDecorator>
				</FieldStatus>
				<FieldStatus name="cvc">
					<RecurlyField
						name="cvv"
						width={56}
					/>
				</FieldStatus>
			</FormRow>
		</FormRows>
	);
};



export default CreditCardFields;
