import React from 'react';
import {
	useSelector,
} from 'react-redux';

import WebsiteIdProvider from './WebsiteIdProvider';

import {
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';

import useAllWebsites from '~/hooks/useAllWebsites';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useOpenSwitchAccountModal from '~/hooks/useOpenSwitchAccountModal';



const CurrentWebsiteIdProvider = (props) => {
	const {
		children,
	} = props;

	const allWebsites = useAllWebsites();
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const openSwitchAccountModal = useOpenSwitchAccountModal(false);
	const websiteId = useSelector<any, string | null>(selectedWebsiteIdSelector);

	const [autoSwitchTriggered, setAutoSwitchTriggered] = React.useState(false);

	React.useEffect(
		() => {
			if (
				allWebsites.isLoaded === false
				|| autoSwitchTriggered
				|| kingdomAdminFeatures.areVisible === false
				|| websiteId === '{id}'
				|| websiteId === null
			) {
				return;
			}

			if (allWebsites.listAll().some((website) => website.id === websiteId)) {
				return;
			}

			openSwitchAccountModal(websiteId);
			setAutoSwitchTriggered(true);
		},
		[
			allWebsites,
			autoSwitchTriggered,
			kingdomAdminFeatures,
			openSwitchAccountModal,
			setAutoSwitchTriggered,
			websiteId,
		],
	);

	if (websiteId === '{id}') {
		return null;
	}

	return (
		<WebsiteIdProvider websiteId={websiteId}>
			{children}
		</WebsiteIdProvider>
	);
};



export default CurrentWebsiteIdProvider;
