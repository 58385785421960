import React from 'react';

import type DistributionBarChart from '~/components/patterns/charts/charts/DistributionBarChart';
import LabeledValue, {
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import type WebVitalsStatusIndicator from '~/components/patterns/webVitals/WebVitalsStatusIndicator';
import type WebVitalsValue from '~/components/patterns/webVitals/WebVitalsValue';



type Props = {
	/** Distribution bar chart */
	children?: React.ReactElement<typeof DistributionBarChart> | null,
	/** Metric label */
	label: React.ReactNode,
	/** Web Vitals status indicator */
	statusIndicator?: React.ReactElement<typeof WebVitalsStatusIndicator> | null,
	/** Web Vitals value */
	value?: React.ReactElement<typeof WebVitalsValue> | null,
};

const WebVitalsMetric: React.FC<Props> = (props) => {
	const {
		children,
		label,
		statusIndicator,
		value,
	} = props;

	return (
		<div className="web-vitals-chart-layout">
			<div className="web-vitals-chart-layout__status-indicator">
				{statusIndicator}
			</div>
			<div className="web-vitals-chart-layout__content">
				<div className="web-vitals-chart-layout__header">
					<LabeledValue
						flow={LabeledValueFlow.Row}
						label={label}
					>
						{value}
					</LabeledValue>
				</div>
				{children && (
					<div className="web-vitals-chart-layout__chart">
						{children}
					</div>
				)}
			</div>
		</div>
	);
};



export default WebVitalsMetric;
