import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Autoplay video when we will show this element */
	autoplay?: boolean,
	/** Unique ID of video uploaded to Cloudflare Stream */
	cloudflareStreamId: string,
	/** Showing of control elements of video */
	controls?: boolean,
	/** Custom height of video. When not set we will keep aspect ratio 16:9  */
	height?: number,
	/** Video playback in infinite loop */
	loop?: boolean,
	/** Play video with muted sound */
	muted?: boolean,
	/** Path to custom video thumbnail */
	posterSrc?: string,
	/** Custom width of video. When not set we will keep aspect ratio 16:9  */
	width?: number,
};



const CloudflareStreamVideo: React.FC<Props> = (props) => {
	const {
		autoplay,
		cloudflareStreamId,
		controls = true,
		height,
		loop,
		muted = autoplay,
		posterSrc,
		width,
	} = props;

	let ratio;

	if (width && height) {
		ratio = ((height / width) * 100).toFixed(4);
	}

	const componentClasses = classNames({
		'video': true,
		'video--clickable': !autoplay,
	});

	let attributes = '';

	if (autoplay) {
		attributes += 'autoplay=true&';
	}

	if (!controls) {
		attributes += 'controls=false&';
	}

	if (loop) {
		attributes += 'loop=true&';
	}

	if (muted) {
		attributes += 'muted=true&';
	}

	if (posterSrc) {
		attributes += 'poster=' + posterSrc;
	}

	return (
		<div
			className={componentClasses}
			style={{
				maxWidth: width,
			}}
		>
			<div
				className="video__container"
				style={{
					paddingTop: ratio ? `${ratio}%` : undefined,
				}}
			>
				<iframe
					allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
					allowFullScreen={controls}
					className="video__iframe"
					loading="lazy"
					src={'https://iframe.videodelivery.net/' + cloudflareStreamId + '?' + attributes}
				/>
			</div>
		</div>
	);
};



export default CloudflareStreamVideo;
