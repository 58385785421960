import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Collapse navigation on smaller viewports */
	isResponsive?: boolean,
	items: Array<{
		content: React.ReactNode,
		highlighted?: boolean,
		name: string,
	}>,
};



const UserNav: React.FC<Props> = (props) => {
	const {
		isResponsive = true,
		items,
	} = props;

	const componentClasses = classNames({
		'user-nav': true,
		'user-nav--is-responsive': isResponsive,
	});

	return (
		<nav
			aria-label="User navigation"
			className={componentClasses}
			role="navigation"
		>
			<ul className="user-nav__nav">
				{items.map(({
					content,
					highlighted = false,
					name,
				}) => {
					const itemClasses = classNames({
						'user-nav__item': true,
						'user-nav__item--highlighted': highlighted,
					});

					return (
						<li
							className={itemClasses}
							key={name}
						>
							{content}
						</li>
					);
				})}
			</ul>
		</nav>
	);
};



export default UserNav;
