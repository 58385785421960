import React from 'react';

import Announcement from '~/components/patterns/messages/embedded/Announcement';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';



type Props = {
	children?: React.ReactNode,
	/** Call-to-action footer element */
	ctaElement?: React.ReactNode,
	/** Custom width of box */
	maxWidth?: number | string,
	/** Announcement title */
	title?: React.ReactNode,
};



const AnnouncementPopup: React.FC<Props> = (props) => {
	const {
		ctaElement,
		children,
		maxWidth = 460,
		title,
	} = props;

	return (
		<SimpleBox
			dropShadow={true}
			maxWidth={maxWidth}
		>
			<Announcement
				ctaElement={ctaElement}
				title={title}
			>
				{children}
			</Announcement>
		</SimpleBox>
	);
};



export default AnnouncementPopup;
