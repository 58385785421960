import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	LEVEL_MAKE_CHANGES,
	LEVEL_OWNER,
	LEVEL_READ_ONLY,
} from '~/model/accountAccess';



const messages = defineMessages({
	[LEVEL_MAKE_CHANGES]: {
		id: 'ui.accountAccess.accessRights.makeChanges',
	},
	[LEVEL_OWNER]: {
		id: 'ui.accountAccess.accessRights.makeChanges',
	},
	[LEVEL_READ_ONLY]: {
		id: 'ui.accountAccess.accessRights.readOnly',
	},
});



type Props = {
	level: (
		| typeof LEVEL_MAKE_CHANGES
		| typeof LEVEL_OWNER
		| typeof LEVEL_READ_ONLY
	),
};

const AccountAccessLevelName: React.FC<Props> = (props) => {
	const {
		level,
	} = props;

	const message = messages[level];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default AccountAccessLevelName;
