import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CancelIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g
					id="reset-button"
					stroke={color}
					transform="translate(3.000000, 3.000000)"
				>
					<path
						d="M17.5441767,9 C17.5441767,13.7189732 13.7185071,17.5446429 8.99953389,17.5446429 C4.28056071,17.5446429 0.454891071,13.7189732 0.454891071,9 C0.454891071,4.28102679 4.28056071,0.455357143 8.99953389,0.455357143 C13.7185071,0.455357143 17.5441767,4.28102679 17.5441767,9 Z"
						id="Stroke-231"
					>
					</path>
					<path
						d="M12.2726759,5.70402051 L5.68087234,12.2958241"
						id="Stroke-232"
					>
					</path>
					<path
						d="M5.68071694,5.70402051 L12.2725205,12.2958241"
						id="Stroke-233"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CancelIconBuilder;

