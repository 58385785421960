import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import LabeledValue, {
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LoadingWrapper from '~/components/atoms/preloaders/LoadingWrapper';
import MarginBox from '~/components/atoms/boxes/MarginBox';
import ScoreNumber, {
	ScoreNumberSize,
	ScoreNumberStyle,
} from '~/components/patterns/scores/components/ScoreNumber';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';

import {
	useXmlSitemapsBoxQuery,
} from './XmlSitemapsBox.gql';

import usePlatformQuery from '~/hooks/usePlatformQuery';
import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	blockIndexesLabel: {
		id: 'ui.platform.sitemaps.indexes',
	},
	blockSitemapsLabel: {
		id: 'ui.platform.sitemaps.sitemaps',
	},
	blockSitemapUrlsLabel: {
		id: 'ui.platform.sitemaps.sitemapUrls',
	},
	title: {
		id: 'ui.platform.sitemaps.title',
	},
	viewerButtonLabel: {
		id: 'ui.platform.sitemaps.viewer.sitemaps.button',
	},
	viewerMobileButtonLabel: {
		id: 'ui.platform.sitemaps.viewer.sitemaps.button.mobile',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const XmlSitemapsBox: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const viewportType = useViewportType();

	const { data } = usePlatformQuery(
		useXmlSitemapsBoxQuery,
		websiteId,
	);

	const isLoaded = data?.platformData ?? false;

	const xmlSitemapIndicesCount = data?.platformData?.xmlSitemapIndicesCount ?? 0;
	const xmlSitemapsCount = data?.platformData?.xmlSitemapsCount ?? 0;
	const xmlSitemapUrlsCount = data?.platformData?.xmlSitemapUrlsCount ?? 0;

	const headerButtons: Array<React.ReactNode> = [];

	if (
		xmlSitemapsCount > 0
		|| xmlSitemapIndicesCount > 0
	) {
		headerButtons.push(
			<Button
				key="sitemapsList"
				linkRouteName="website.platform.sitemaps"
				linkRouteParams={{
					websiteId,
				}}
				size={ButtonSize.Small}
				style={ButtonStyle.Hollow}
				uppercase={true}
			>
				{(viewportType.isSmall || viewportType.isMedium) ? (
					<FormattedMessage {...messages.viewerMobileButtonLabel} />
				) : (
					<FormattedMessage {...messages.viewerButtonLabel} />
				)}
			</Button>,
		);
	}

	return (
		<MarginBox>
			<BorderedBox
				headerActionElements={isLoaded ? headerButtons : null}
				headerIcon={(
					<AttributesGroupIcon
						color="#964fa5"
						size={28}
						type={AttributesGroupIconType.XmlSitemap}
					/>
				)}
				headerLabel={(
					<FormattedMessage {...messages.title} />
				)}
				paddingSize={3}
			>
				<LoadingWrapper isLoading={isLoaded === false}>
					<SeparatedBlocks responsive={true}>
						<LabeledValue
							flow={LabeledValueFlow.RowOnSmall}
							label={(
								<FormattedMessage {...messages.blockIndexesLabel} />
							)}
						>
							<ScoreNumber
								size={ScoreNumberSize.Responsive}
								style={ScoreNumberStyle.GreyscaleDark}
								value={xmlSitemapIndicesCount}
							/>
						</LabeledValue>
						<LabeledValue
							flow={LabeledValueFlow.RowOnSmall}
							label={(
								<FormattedMessage {...messages.blockSitemapsLabel} />
							)}
						>
							<ScoreNumber
								size={ScoreNumberSize.Responsive}
								style={ScoreNumberStyle.GreyscaleDark}
								value={xmlSitemapsCount}
							/>
						</LabeledValue>
						{xmlSitemapsCount > 0 && (
							<LabeledValue
								flow={LabeledValueFlow.RowOnSmall}
								label={(
									<FormattedMessage {...messages.blockSitemapUrlsLabel} />
								)}
							>
								<ScoreNumber
									size={ScoreNumberSize.Responsive}
									style={ScoreNumberStyle.GreyscaleDark}
									value={xmlSitemapUrlsCount}
								/>
							</LabeledValue>
						)}
					</SeparatedBlocks>
				</LoadingWrapper>
			</BorderedBox>
		</MarginBox>
	);
};



export default XmlSitemapsBox;
