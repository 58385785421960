import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ScopeLabel, {
	ScopeLabelHintDisplayOn,
	ScopeLabelSize,
	ScopeLabelStyle,
} from '~/components/logic/scopes/ScopeLabel';
import Title from '~/components/patterns/headings/Title';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	type SegmentDefinition,
	getSegmentDefinitionByName,
} from '~/model/segments';

import {
	type Scope,
} from '~/model/scopes';



const messages = defineMessages({
	websiteScopeTitle: {
		id: 'ui.scopes.website.title',
	},
});



type Props = {
	scope: Scope,
};

const ScopeTitle: React.FC<Props> = (props) => {
	const {
		scope,
	} = props;

	const websiteId = useWebsiteId();

	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const [manuallyRevealedHint, setManuallyRevealedHint] = React.useState(false);

	let segment: SegmentDefinition | null = null;

	if (scope.indexOf('segment:') === 0) {
		const segmentName = scope.substr('segment:'.length);
		segment = getSegmentDefinitionByName(segmentDefinitions.listAll(), segmentName);
	}

	if (scope.indexOf('segment/') === 0) {
		const segmentName = scope.substr('segment/'.length);
		segment = getSegmentDefinitionByName(segmentDefinitions.listAll(), segmentName);
	}

	let title;

	if (scope === 'website') {
		title = (
			<FormattedMessage {...messages.websiteScopeTitle} />
		);
	} else if (segment !== null) {
		title = segment.label;
	} else {
		title = '';
	}

	return (
		<Title
			color={segment?.color}
			icon={(
				<ScopeLabel
					hintDisplayOn={manuallyRevealedHint ? ScopeLabelHintDisplayOn.Always : ScopeLabelHintDisplayOn.OnHover}
					scope={scope}
					size={ScopeLabelSize.Default}
					style={ScopeLabelStyle.Thumb}
				/>
			)}
			indented={true}
			onMouseEnter={() => setManuallyRevealedHint(true)}
			onMouseLeave={() => setManuallyRevealedHint(false)}
			underlined={true}
		>
			{title}
		</Title>
	);
};



export default ScopeTitle;
