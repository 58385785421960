import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import HealthIcon, {
	HealthIconImpact,
} from '~/components/patterns/icons/HealthIcon';
import IssueValueCard, {
	IssueValueCardFlow as IssueScoreImpactFlow,
	IssueValueCardSize as IssueScoreImpactSize,
	IssueValueCardStyle,
} from '~/components/patterns/issues/IssueValueCard.part';



export enum IssueScoreImpactType {
	Negative = 'negative',
	None = 'none',
	Positive = 'positive',
}

type Props = {
	/** Flow of elements in layout of card */
	flow?: IssueScoreImpactFlow,
	/** Different sizes of card */
	size?: IssueScoreImpactSize,
	/** Type of score impact */
	type: IssueScoreImpactType,
	/** Score value */
	value: number,
};

const IssueScoreImpact: React.FC<Props> = (props) => {
	const {
		flow = IssueScoreImpactFlow.Row,
		size = IssueScoreImpactSize.Default,
		type,
		value,
	} = props;

	let formattedValue = (
		<FormattedNumber value={Math.abs(value)} />
	);

	let iconSize = 20;

	if (size === IssueScoreImpactSize.Small) {
		iconSize = 18;
	}

	if (size === IssueScoreImpactSize.Large) {
		iconSize = 22;
	}

	let cardStyle = IssueValueCardStyle.Neutral;

	let icon = (
		<HealthIcon size={iconSize} />
	);

	if (type === IssueScoreImpactType.None) {
		formattedValue = (
			<>–</>
		);
	}

	if (type === IssueScoreImpactType.Positive) {
		cardStyle = IssueValueCardStyle.Positive;

		icon = (
			<HealthIcon
				impact={HealthIconImpact.Positive}
				size={iconSize}
			/>
		);

		if (value === 0) {
			formattedValue = (
				<span>&lt; 1</span>
			);
		}
	}

	if (type === IssueScoreImpactType.Negative) {
		cardStyle = IssueValueCardStyle.Negative;

		icon = (
			<HealthIcon
				impact={HealthIconImpact.Negative}
				size={iconSize}
			/>
		);

		if (value === 0) {
			formattedValue = (
				<span>&lt; 1</span>
			);
		}
	}

	return (
		<IssueValueCard
			flow={flow}
			icon={icon}
			size={size}
			style={cardStyle}
			value={formattedValue}
		/>
	);
};

export {
	IssueScoreImpactFlow,
	IssueScoreImpactSize,
};

export default IssueScoreImpact;
