import React from 'react';
import {
	IntlProvider,
} from 'react-intl';

import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';

import {
	dictionaries,
} from '~/localization/dictionaries';



type Props = {
	canBeHidden?: boolean,
	children?: React.ReactNode,
};

const OnlyForAdmin: React.FC<Props> = (props) => {
	const {
		canBeHidden = false,
		children,
	} = props;

	const kingdomAdminFeatures = useKingdomAdminFeatures();

	if (!children) {
		return null;
	}

	const shouldDisplay = canBeHidden
		? kingdomAdminFeatures.areVisible
		: kingdomAdminFeatures.areAlwaysVisible;

	if (!shouldDisplay) {
		return null;
	}

	return (
		<IntlProvider
			locale="en-US"
			messages={dictionaries.get('en-US')}
		>
			{children}
		</IntlProvider>
	);
};



export default OnlyForAdmin;
