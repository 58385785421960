import classNames from 'classnames';
import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import RatingStars from '~/components/patterns/scores/components/RatingStars';



export enum ReviewBadgeService {
	Capterra = 'capterra',
	G2 = 'g2',
	GetApp = 'get-app',
	TrustRadius = 'trust-radius',
}

type Props = {
	ratingMaxScore: number,
	ratingScore: number,
	service: ReviewBadgeService,
};



const ReviewBadge: React.FC<Props> = (props) => {
	const {
		ratingMaxScore,
		ratingScore,
		service,
	} = props;

	const percentage = Math.round(100 * (ratingScore / ratingMaxScore));
	let nextPercentageStep = percentage;

	if (percentage % 10 !== 0) {
		nextPercentageStep += (10 - (percentage % 10));
	}

	const componentClasses = classNames({
		'review-badge': true,
		['review-badge--' + service + '-service']: true,
	});

	return (
		<div className={componentClasses}>
			<div className="review-badge__header">
				<div className="review-badge__rating-score">
					<FormattedNumber
						maximumFractionDigits={2}
						minimumFractionDigits={0}
						value={ratingScore}
					/>
					<span>/</span>
					<FormattedNumber
						maximumFractionDigits={2}
						minimumFractionDigits={0}
						value={ratingMaxScore}
					/>
				</div>
			</div>
			<div className="review-badge__rating-stars">
				<RatingStars
					customStarIcon={({ active }) => (
						<BasicIcon
							color={active ? '#fe8719' : '#8595a6'}
							size={19}
							type={BasicIconType.Star}
						/>
					)}
					ratingPercentage={nextPercentageStep}
				/>
			</div>
		</div>
	);
};



export default ReviewBadge;
