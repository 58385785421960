import memoize from 'memoizee';
import React from 'react';

import CK from '~/types/contentking';

import ColumnFormatter from '~/components/logic/formatters/ColumnFormatter';
import CrawlingInProgress from '~/components/logic/pagesOverview/CrawlingInProgress';
import DatatableOverlay from '~/components/patterns/tables/datatables/DatatableOverlay';
import EmptyResultOverlay from '~/components/logic/datatables/EmptyResultOverlay';
import FieldFormatter from '~/components/logic/formatters/FieldFormatter';
import FullwidthCell from '~/components/atoms/dataTables/parts/FullwidthCell';
import NewColumnHeader from '~/components/atoms/dataTables/parts/NewColumnHeader';
import ReevaluationOverlay from '~/components/app/ReevaluationOverlay';
import ResizableDatatable, {
	ResizableDatatableCellAlignment,
	type ResizableDatatableColumnDefinition,
	type ResizableDatatableContextMenuEntries,
	ResizableDatatableHeaderCellColorStyle,
	type ResizableDatatableRowGetterInput,
} from '~/components/logic/datatables/ResizableDatatable';
import SegmentLabelThumbList from '~/components/logic/segments/SegmentLabelThumbList';

import useNavigate from '~/hooks/useNavigate';

import {
	PagesColumnsAlignment,
	PagesColumnsCategory,
	getColumnAlignment,
	getColumnCategory,
	getColumnDefaultWidth,
} from '~/model/pagesColumns';

import {
	type SegmentDefinition,
	filterSegmentDefinitionsByNames,
} from '~/model/segments';

import {
	trimHostname,
} from '~/model/utils';

import matchAndReturn from '~/utilities/matchAndReturn';



const COLUMNS_WITH_FLIPPED_SORTING = [
	CK.PagesCommonColumn.AaAverageTimeSpentOnSite,
	CK.PagesCommonColumn.AaPageViews,
	CK.PagesCommonColumn.AaRevenue,
	CK.PagesCommonColumn.AaUniqueVisitors,
	CK.PagesCommonColumn.GaAverageTime,
	CK.PagesCommonColumn.GaPageValue,
	CK.PagesCommonColumn.GaPageViews,
	CK.PagesCommonColumn.GaUniquePageViews,
	CK.PagesCommonColumn.GscClicks,
	CK.PagesCommonColumn.GscCtr,
	CK.PagesCommonColumn.GscImpressions,
	CK.PagesCommonColumn.LfaBingDesktopFrequency,
	CK.PagesCommonColumn.LfaBingDesktopLastVisit,
	CK.PagesCommonColumn.LfaBingFrequency,
	CK.PagesCommonColumn.LfaBingLastVisit,
	CK.PagesCommonColumn.LfaBingMobileFrequency,
	CK.PagesCommonColumn.LfaBingMobileLastVisit,
	CK.PagesCommonColumn.LfaGoogleDesktopFrequency,
	CK.PagesCommonColumn.LfaGoogleDesktopLastVisit,
	CK.PagesCommonColumn.LfaGoogleFrequency,
	CK.PagesCommonColumn.LfaGoogleLastVisit,
	CK.PagesCommonColumn.LfaGoogleMobileFrequency,
	CK.PagesCommonColumn.LfaGoogleMobileLastVisit,
	CK.PagesCommonColumn.NumberOfIncomingInternalCanonicals,
	CK.PagesCommonColumn.NumberOfIncomingInternalLinks,
	CK.PagesCommonColumn.NumberOfIncomingInternalRedirects,
	CK.PagesCommonColumn.NumberOfOutgoingExternalLinks,
	CK.PagesCommonColumn.NumberOfOutgoingInternalLinks,
	CK.PagesCommonColumn.Relevance,
];

const defaultCustomColumnDefinitions = {};

const HEADER_HEIGHT = 76;
const HEADER_DISTRIBUTION_FILTER_HEIGHT = 10;
const HEADER_SEGMENT_FILTER_HEIGHT = 25;
const MAXIMUM_ROWS = 350000;



const hasData = ({ columnName, row }) => row?.[columnName] !== undefined;

const isSegmentFilterActive = memoize((columns, filter, segments) => {
	return (
		filter.has('segments')
		&& filterSegmentDefinitionsByNames(
			segments,
			[
				...filter.get('segments').get('included_in').toArray(),
				...filter.get('segments').get('not_included_in').toArray(),
			],
		).some(
			(segmentDefinition) => Object.keys(segmentDefinition.filterDefinition).some(
				(field) => columns.indexOf(field) !== -1,
			),
		)
	);
});

const isFilteredSegmentBeingReevaluated = memoize((filter, segments) => {
	return (
		filter.has('segments')
		&& filterSegmentDefinitionsByNames(
			segments,
			[
				...filter.get('segments').get('included_in').toArray(),
				...filter.get('segments').get('not_included_in').toArray(),
			],
		).some(
			(segmentDefinition) => segmentDefinition.isBeingReevaluated,
		)
	);
});

export type PagesColumnsDatatableFixedColumnWidths = Record<string, ResizableDatatableColumnDefinition<any>['width']>;



type ResizableDatatableProps = React.ComponentProps<typeof ResizableDatatable>;

type Props = {
	activeRowsLimit?: number | null,
	columns: any,
	contextMenuEntries?: ResizableDatatableContextMenuEntries<any>,
	customColumnDefinitions?: Record<string, any>,
	distributions?: any,
	filter: any,
	fixedColumnWidths?: PagesColumnsDatatableFixedColumnWidths,
	headerPropsProvider?: (input: {
		columnName: string,
	}) => Record<string, any>,
	height?: ResizableDatatableProps['height'],
	inactiveRowsFooter?: React.ReactNode,
	isColumnWidthFixed?: boolean,
	isCrawled: boolean | null,
	isDisabled?: boolean,
	isLoading: boolean,
	isRowClickable: boolean,
	isRowInactive?: (input: {
		rowIndex: number,
	}) => boolean,
	onFilterChangeCallback: (filter: any) => void,
	onLoadPagesCallback: (input: {
		rowIndex: number,
	}) => void,
	onResetFilterCallback?: any,
	onSortChangeCallback: (sortBy: any) => void,
	pagesCount: number,
	removeDefaultFilterValues: any,
	resetFilterAction: any,
	rowGetter: (input: ResizableDatatableRowGetterInput) => any,
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	segmentsNotAllowedForFiltering?: Record<string, React.ReactNode>,
	sortBy: any,
	websiteId: CK.WebsiteId,
	width?: ResizableDatatableProps['width'],
};

const PagesColumnsDatatable: React.FC<Props> = (props) => {
	const {
		activeRowsLimit = null,
		columns,
		contextMenuEntries,
		customColumnDefinitions = defaultCustomColumnDefinitions,
		distributions,
		filter,
		fixedColumnWidths,
		headerPropsProvider,
		height,
		inactiveRowsFooter,
		isCrawled,
		isDisabled,
		isLoading,
		isRowClickable,
		isRowInactive,
		onFilterChangeCallback,
		onLoadPagesCallback,
		onResetFilterCallback,
		onSortChangeCallback,
		pagesCount,
		removeDefaultFilterValues,
		resetFilterAction,
		rowGetter,
		segmentDefinitions,
		segmentsNotAllowedForFiltering,
		sortBy,
		websiteId,
		width,
	} = props;

	const navigate = useNavigate();

	let calculatedHeaderHeight = HEADER_HEIGHT;

	// make table header higher when filtering on segments
	if (isSegmentFilterActive(columns, filter, segmentDefinitions)) {
		calculatedHeaderHeight += HEADER_SEGMENT_FILTER_HEIGHT;
	}

	if (distributions !== undefined) {
		calculatedHeaderHeight += HEADER_DISTRIBUTION_FILTER_HEIGHT;
	}

	const handleRowClick = React.useCallback(
		({ row }, event) => {
			navigate({
				event,
				routeName: 'website.pages.detail',
				routeParams: {
					websiteId,
					id: row.id,
				},
			});
		},
		[
			navigate,
			websiteId,
		],
	);

	const overlay = React.useCallback(
		(actualHeaderHeight) => {
			if (isLoading || pagesCount !== 0) {
				return;
			}

			if (isFilteredSegmentBeingReevaluated(filter, segmentDefinitions)) {
				return (
					<ReevaluationOverlay datatableHeaderHeight={actualHeaderHeight} />
				);
			}

			if (isCrawled === false) {
				return (
					<DatatableOverlay datatableHeaderHeight={actualHeaderHeight}>
						<CrawlingInProgress />
					</DatatableOverlay>
				);
			}
		},
		[
			filter,
			isCrawled,
			isLoading,
			pagesCount,
			segmentDefinitions,
		],
	);

	const renderBlankState = React.useCallback(
		() => {
			if (
				isFilteredSegmentBeingReevaluated(filter, segmentDefinitions)
				|| isCrawled !== true
			) {
				return;
			}

			return (
				<EmptyResultOverlay
					filter={filter}
					onResetFilterCallback={onResetFilterCallback}
					removeDefaultValues={removeDefaultFilterValues}
					resetAction={resetFilterAction}
				/>
			);
		},
		[
			filter,
			isCrawled,
			onResetFilterCallback,
			removeDefaultFilterValues,
			resetFilterAction,
			segmentDefinitions,
		],
	);

	const realColumns = React.useMemo(
		() => {
			return columns.toArray().map(
				(columnName) => {
					if (columnName in customColumnDefinitions) {
						return customColumnDefinitions[columnName];
					}

					const additionalOptions = headerPropsProvider
						? headerPropsProvider({ columnName })
						: {};

					const columnAlignment = getColumnAlignment(columnName);
					const columnCategory = getColumnCategory(columnName);

					const extraFields: Record<string, any> = {};

					if (fixedColumnWidths !== undefined && columnName in fixedColumnWidths) {
						extraFields.resizable = false;
						extraFields.width = fixedColumnWidths[columnName];
					}

					return {
						alignment: columnAlignment === PagesColumnsAlignment.Right
							? ResizableDatatableCellAlignment.Right
							: ResizableDatatableCellAlignment.Left,
						filterName: columnName,
						headerColorStyle: matchAndReturn(columnCategory, {
							[PagesColumnsCategory.AdobeAnalytics]: ResizableDatatableHeaderCellColorStyle.AdobeAnalytics,
							[PagesColumnsCategory.Content]: ResizableDatatableHeaderCellColorStyle.Content,
							[PagesColumnsCategory.Conversions]: ResizableDatatableHeaderCellColorStyle.Conversions,
							[PagesColumnsCategory.CustomElements]: ResizableDatatableHeaderCellColorStyle.CustomElements,
							[PagesColumnsCategory.EnrichmentFields]: ResizableDatatableHeaderCellColorStyle.EnrichmentFields,
							[PagesColumnsCategory.Fundamentals]: ResizableDatatableHeaderCellColorStyle.Fundamentals,
							[PagesColumnsCategory.GoogleAnalytics]: ResizableDatatableHeaderCellColorStyle.GoogleAnalytics,
							[PagesColumnsCategory.GoogleSearchConsole]: ResizableDatatableHeaderCellColorStyle.GoogleSearchConsole,
							[PagesColumnsCategory.Indexability]: ResizableDatatableHeaderCellColorStyle.Indexability,
							[PagesColumnsCategory.Lighthouse]: ResizableDatatableHeaderCellColorStyle.Lighthouse,
							[PagesColumnsCategory.NoCategory]: ResizableDatatableHeaderCellColorStyle,
							[PagesColumnsCategory.Relations]: ResizableDatatableHeaderCellColorStyle.Realtions,
							[PagesColumnsCategory.SchemaOrg]: ResizableDatatableHeaderCellColorStyle.SchemaOrg,
							[PagesColumnsCategory.SearchEngineActivity]: ResizableDatatableHeaderCellColorStyle.SearchEngineActivity,
							[PagesColumnsCategory.Social]: ResizableDatatableHeaderCellColorStyle.Social,
						}),
						hasData,
						name: columnName,
						render: {
							cell: ({ innerWidth, row }) => {
								let cell;
								let content;

								if (columnName === CK.PagesCommonColumn.Segments) {
									content = (
										<SegmentLabelThumbList
											segmentDefinitions={segmentDefinitions}
											segmentNames={row[columnName]}
											width={innerWidth}
										/>
									);
								} else if (columnName === CK.PagesCommonColumn.Url) {
									content = trimHostname(row.url);
								}

								if (content) {
									cell = (
										<ColumnFormatter
											column={columnName}
											pageType={row.type}
											value={content}
										/>
									);
								} else {
									cell = (
										<FieldFormatter
											column={columnName}
											customElements={row['custom_elements']}
											key={columnName === CK.PagesCommonColumn.Health ? row.domainId + '-' + row.id : undefined}
											pageType={row.type}
											value={row[columnName]}
										/>
									);
								}

								return cell;
							},
							header: ({ columnIndex, filterRef, filterWidth }) => {
								return (
									<NewColumnHeader
										columnCount={columns.size}
										columnIndex={columnIndex}
										columnName={columnName}
										columnWidth={filterWidth}
										distributions={distributions}
										filter={filter}
										isSegmentFilterActive={isSegmentFilterActive(columns, filter, segmentDefinitions)}
										key={columnName}
										onFilterChangeCallback={onFilterChangeCallback}
										ref={filterRef}
										segmentDefinitions={segmentDefinitions}
										segmentsNotAllowedForFiltering={segmentsNotAllowedForFiltering}
										sortCallback={onSortChangeCallback}
										sortingActive={sortBy.get('key') === columnName}
										sortingDirection={
											sortBy.get('key') === columnName
												? sortBy.get('direction')
												: null
										}
										sortingEnabled={columnName !== CK.PagesCommonColumn.Segments}
										sortingFixed={sortBy.get('fixed') ?? false}
										sortingFlipped={COLUMNS_WITH_FLIPPED_SORTING.includes(columnName)}
										{...additionalOptions}
									/>
								);
							},
						},
						width: getColumnDefaultWidth(columnName) || 200,
						...extraFields,
					};
				},
			);
		},
		[
			columns,
			customColumnDefinitions,
			distributions,
			filter,
			fixedColumnWidths,
			headerPropsProvider,
			onFilterChangeCallback,
			onSortChangeCallback,
			segmentDefinitions,
			segmentsNotAllowedForFiltering,
			sortBy,
		],
	);

	return (
		<ResizableDatatable
			activeRowsLimit={activeRowsLimit ?? undefined}
			blankSlate={renderBlankState}
			columns={realColumns}
			contextMenuEntries={contextMenuEntries}
			filter={filter}
			headerHeight={calculatedHeaderHeight}
			height={height}
			inactiveRowsFooter={inactiveRowsFooter}
			isDisabled={isDisabled}
			isLoading={isLoading}
			isRowInactive={isRowInactive}
			lastRowNote={pagesCount > MAXIMUM_ROWS ? (
				<FullwidthCell
					limit={5000}
					rowIndex={Math.min(MAXIMUM_ROWS + 1, pagesCount) + 1}
					total={pagesCount}
				/>
			) : null}
			numberOfPinnedColumns={1}
			onFilterChange={onFilterChangeCallback}
			onRowClick={isRowClickable ? handleRowClick : undefined}
			onScroll={onLoadPagesCallback}
			overlay={overlay}
			rowGetter={rowGetter}
			rowsCount={Math.min(MAXIMUM_ROWS + 1, pagesCount)}
			width={width}
		/>
	);
};



export default PagesColumnsDatatable;
