import Immutable from 'immutable';
import React from 'react';

import type CK from '~/types/contentking';

import {
	useNonPagesQuery,
} from './useNonPages.gql';

import useGraphQLConnection from '~/hooks/useGraphQLConnection';

import {
	getFilter,
} from '~/model/pages';



const defaultSortBy = Immutable.Map({
	direction: true,
	key: 'url',
});

const invalidState = {
	distributions: undefined,
	fetchFurtherRange: () => Promise.resolve(),
	loading: false,
	rowGetter: () => null,
	total: 0,
};



function useNonPages(input: {
	filter: any,
	limit: number,
	pollIntervalInMilliseconds: number,
	skip?: boolean,
	sortBy: any | null,
	websiteId: CK.WebsiteId | null,
}) {
	const {
		filter,
		limit,
		pollIntervalInMilliseconds,
		skip = false,
		websiteId,
	} = input;

	const sortBy = input.sortBy ?? defaultSortBy;

	const after = null;
	const ascending = sortBy.get('direction');
	const before = null;
	const sort = sortBy.get('key');

	const {
		criteria,
		isInvalid,
	} = getFilter(filter.toJS ? filter : Immutable.fromJS(filter));

	const { data, fetchMore } = useNonPagesQuery({
		skip: websiteId === null || isInvalid || skip,
		variables: {
			after,
			ascending,
			before,
			filter: criteria,
			limit,
			offset: 0,
			sort,
			websiteId: websiteId ?? '',
		},
	});

	const graphQLConnection = useGraphQLConnection({
		batchLimit: limit,
		context: {
			filter,
			sortBy,
			websiteId,
		},
		data,
		fetchMore,
		getter: (data) => data.nonPages,
		pollIntervalInMilliseconds,
	});

	const graphQLConnectionRowGetter = graphQLConnection.rowGetter;

	const rowGetter = React.useCallback(
		(input: { rowIndex: number }) => {
			return graphQLConnectionRowGetter(input)?.data ?? null;
		},
		[
			graphQLConnectionRowGetter,
		],
	);

	if (isInvalid) {
		return invalidState;
	}

	return {
		distributions: data?.nonPages?.distributions ?? undefined,
		fetchFurtherRange: graphQLConnection.fetchFurtherRange,
		loading: (data?.nonPages ?? undefined) === undefined,
		rowGetter,
		total: graphQLConnection.totalCount,
	};
}



export default useNonPages;
