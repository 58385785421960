/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateAlertDefinitionsMessagingAppChannelsMutationVariables = GraphQL.Exact<{
  alertDefinitionIds: ReadonlyArray<GraphQL.Scalars['AlertDefinitionId']['input']> | GraphQL.Scalars['AlertDefinitionId']['input'];
  messagingAppChannelIds: ReadonlyArray<GraphQL.Scalars['ID']['input']> | GraphQL.Scalars['ID']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type BulkUpdateAlertDefinitionsMessagingAppChannelsMutation = { readonly __typename?: 'Mutation', readonly BulkUpdateAlertDefinitionsMessagingAppChannels: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly alertDefinitions: ReadonlyArray<{ readonly __typename?: 'AlertDefinition', readonly alertType: string, readonly id: CK.AlertDefinitionId, readonly recipients: ReadonlyArray<string>, readonly scope: string, readonly settings: Record<string, any>, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType }> }>, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null } } };


export const BulkUpdateAlertDefinitionsMessagingAppChannelsDocument = gql`
    mutation BulkUpdateAlertDefinitionsMessagingAppChannels($alertDefinitionIds: [AlertDefinitionId!]!, $messagingAppChannelIds: [ID!]!, $websiteId: WebsiteId!) {
  BulkUpdateAlertDefinitionsMessagingAppChannels(
    alertDefinitionIds: $alertDefinitionIds
    messagingAppChannelIds: $messagingAppChannelIds
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        alertDefinitions {
          alertType
          id
          messagingAppChannels {
            id
            label
            messagingAppType
          }
          recipients
          scope
          settings
        }
        id
        integrations {
          isNotOwned
          status
          type
        }
      }
    }
  }
}
    `;
export type BulkUpdateAlertDefinitionsMessagingAppChannelsMutationFn = Apollo.MutationFunction<BulkUpdateAlertDefinitionsMessagingAppChannelsMutation, BulkUpdateAlertDefinitionsMessagingAppChannelsMutationVariables>;

/**
 * __useBulkUpdateAlertDefinitionsMessagingAppChannelsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateAlertDefinitionsMessagingAppChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAlertDefinitionsMessagingAppChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateAlertDefinitionsMessagingAppChannelsMutation, { data, loading, error }] = useBulkUpdateAlertDefinitionsMessagingAppChannelsMutation({
 *   variables: {
 *      alertDefinitionIds: // value for 'alertDefinitionIds'
 *      messagingAppChannelIds: // value for 'messagingAppChannelIds'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useBulkUpdateAlertDefinitionsMessagingAppChannelsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateAlertDefinitionsMessagingAppChannelsMutation, BulkUpdateAlertDefinitionsMessagingAppChannelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateAlertDefinitionsMessagingAppChannelsMutation, BulkUpdateAlertDefinitionsMessagingAppChannelsMutationVariables>(BulkUpdateAlertDefinitionsMessagingAppChannelsDocument, options);
      }
export type BulkUpdateAlertDefinitionsMessagingAppChannelsMutationHookResult = ReturnType<typeof useBulkUpdateAlertDefinitionsMessagingAppChannelsMutation>;
export type BulkUpdateAlertDefinitionsMessagingAppChannelsMutationResult = Apollo.MutationResult<BulkUpdateAlertDefinitionsMessagingAppChannelsMutation>;
export type BulkUpdateAlertDefinitionsMessagingAppChannelsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateAlertDefinitionsMessagingAppChannelsMutation, BulkUpdateAlertDefinitionsMessagingAppChannelsMutationVariables>;