import {
	isSameDay,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';

import ClickableArea from '~/components/patterns/content/ClickableArea';
import NoDataMessage from './NoDataMessage';
import NumberDiff from '~/components/patterns/values/NumberDiff';
import ScoreNumber, {
	ScoreNumberSize,
	ScoreNumberStyle,
} from '~/components/patterns/scores/components/ScoreNumber';
import ScoreNumberWithDelta from '~/components/patterns/scores/structures/ScoreNumberWithDelta';
import UnchangedValue from '~/components/patterns/values/UnchangedValue';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';

import {
	useNumberOfPagesWidgetQuery,
} from './NumberOfPagesWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useNavigateToPages from '~/hooks/useNavigateToPages';
import useNavigateToTrackedChanges from '~/hooks/useNavigateToTrackedChanges';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateRangeForQuery,
} from '~/model/dashboard';

import {
	CHANGE_TYPE_ADDED,
	CHANGE_TYPE_OTHER,
	CHANGE_TYPE_REDIRECTED,
	CHANGE_TYPE_REMOVED,
} from '~/model/historicalChanges';

import {
	TYPE_PAGE,
} from '~/model/pages';



const messages = defineMessages({
	primaryActionLinkChanges: {
		id: 'ui.dashboardNumberOfPagesWidget.primaryActionLink.changes',
	},
	primaryActionLinkCurrent: {
		id: 'ui.dashboardNumberOfPagesWidget.primaryActionLink.current',
	},
	title: {
		id: 'ui.dashboardNumberOfPagesWidget.title',
	},
});



const NumberOfPagesWidget: React.FC = () => {
	const dateRange = useDashboardDateRange();
	const navigateToPages = useNavigateToPages();
	const navigateToTrackedChanges = useNavigateToTrackedChanges();
	const scope = useDashboardScopeSection();
	const websiteId = useWebsiteId();

	const {
		data: metricData,
		loading,
		reasonForNoData,
	} = useDashboardQuery('numberOfPages', useNumberOfPagesWidgetQuery, {
		variables: {
			scope,
			websiteId,
			...formatDateRangeForQuery(dateRange),
		},
	});

	const isCurrent = isSameDay(
		Date.now(),
		dateRange.end,
	);

	const primaryActionLinkRoute = isCurrent
		? navigateToPages({
			filter: {
				[CK.PagesCommonColumn.Type]: [
					TYPE_PAGE,
				],
			},
			scope,
		})
		: navigateToTrackedChanges({
			endDate: dateRange.end,
			filter: {
				[CK.PagesCommonColumn.Type]: [
					TYPE_PAGE,
				],
			},
			scope,
			startDate: dateRange.start,
		});

	const deltaRoute = navigateToTrackedChanges({
		endDate: dateRange.end,
		filter: {
			[CK.PagesCommonColumn.ChangeType]: [
				CHANGE_TYPE_ADDED,
				CHANGE_TYPE_OTHER,
				CHANGE_TYPE_REDIRECTED,
				CHANGE_TYPE_REMOVED,
			],
			[CK.PagesCommonColumn.Type]: [
				TYPE_PAGE,
			],
		},
		scope,
		startDate: dateRange.start,
	});

	const primaryActionLink = {
		label: (
			<FormattedMessage {...messages[isCurrent ? 'primaryActionLinkCurrent' : 'primaryActionLinkChanges']} />
		),
		routeName: primaryActionLinkRoute.routeName,
		routeParams: primaryActionLinkRoute.routeParams,
	};

	let scoreNumber = metricData !== null && (
		<ScoreNumber
			size={ScoreNumberSize.Large}
			style={ScoreNumberStyle.GreyscaleDark}
			value={metricData.numberOfPagesOnEndDate}
		/>
	);

	if (isCurrent) {
		scoreNumber = (
			<ClickableArea
				routeName={primaryActionLink.routeName}
				routeParams={primaryActionLink.routeParams}
			>
				{scoreNumber}
			</ClickableArea>
		);
	}

	return (
		<WidgetCell type={WidgetType.Pages}>
			<Widget
				label={(
					<FormattedMessage {...messages.title} />
				)}
				loading={loading}
				overlay={metricData === null && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={primaryActionLink}
				showEndDate={true}
			>
				{metricData !== null && (
					metricData.deltaFromStartDate !== null
						? (
							<ScoreNumberWithDelta
								delta={(
									<ClickableArea
										routeName={deltaRoute.routeName}
										routeParams={deltaRoute.routeParams}
									>
										{metricData.deltaFromStartDate !== 0 ? (
											<NumberDiff diffValue={metricData.deltaFromStartDate} />
										) : (
											<UnchangedValue>
												no change
											</UnchangedValue>
										)}
									</ClickableArea>
								)}
								scoreNumber={scoreNumber}
							/>
						)
						: scoreNumber
				)}
			</Widget>
		</WidgetCell>
	);
};



export default NumberOfPagesWidget;
