import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import HeaderTitleNumericValue from './builders/HeaderTitleNumericValue';
import HeaderTitle, {
	SIZE_DEFAULT as TITLE_SIZE_DEFAULT,
	SIZE_SMALL as TITLE_SIZE_SMALL,
	STYLE_CRITICAL as TITLE_STYLE_CRITICAL,
	STYLE_PRIMARY as TITLE_STYLE_PRIMARY,
} from './HeaderTitle';



export const SIZE_DEFAULT = TITLE_SIZE_DEFAULT;
export const SIZE_SMALL = TITLE_SIZE_SMALL;
export const STYLE_CRITICAL = TITLE_STYLE_CRITICAL;
export const STYLE_PRIMARY = TITLE_STYLE_PRIMARY;

class HeaderTitleWithNumericValue extends Component {

	render() {
		const {
			baselineAlignment,
			size,
			style,
			title,
			value,
		} = this.props;

		return (
			<HeaderTitle
				baselineAlignment={baselineAlignment}
				size={size}
				style={style}
				value={value !== undefined && value !== null && value !== false && (
					<HeaderTitleNumericValue value={value} />
				)}
			>
				{title}
			</HeaderTitle>
		);
	}

}

HeaderTitleWithNumericValue.defaultProps = {
	baselineAlignment: true,
	size: SIZE_DEFAULT,
};

HeaderTitleWithNumericValue.propTypes = {
	baselineAlignment: PropTypes.bool,
	size: PropTypes.oneOf([
		SIZE_DEFAULT,
		SIZE_SMALL,
	]).isRequired,
	style: PropTypes.oneOf([
		STYLE_CRITICAL,
		STYLE_PRIMARY,
	]),
	title: PropTypes.node,
	value: PropTypes.number,
};



export default HeaderTitleWithNumericValue;
