import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import Copy, {
	linkExternal,
	linkInternal,
} from '~/components/logic/Copy';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import Hint, {
	HintAttachment,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import InternalLink from '~/components/patterns/links/InternalLink';
import RadioList from '~/components/atoms/forms/components/RadioList';
import RevokeAccessForConnectedAccountConfirmationModal from '~/components/app/RevokeAccessForConnectedAccountConfirmationModal';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useModifyAccountAccessConnectionDirectionMutation,
} from './ConnectionDirectionForm.gql';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useModals from '~/hooks/useModals';
import useRevokeAgencyAccess from '~/hooks/useRevokeAgencyAccess';

import FormError from '~/utilities/FormError';
import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	cannotModifyConnectionDirectionDueToExistingConnectionToClient: {
		id: 'ui.connectedAccounts.settings.connectionDirection.cannotModifyConnectionDirectionDueToExistingConnectionToClient',
	},
	cannotModifyConnectionDueToExistingConnectionToAgency: {
		id: 'ui.connectedAccounts.settings.connectionDirection.cannotModifyConnectionDueToExistingConnectionToAgency',
	},
	directionDescription: {
		id: 'ui.connectedAccounts.settings.connectionDirection.description',
	},
	directionLabel: {
		id: 'ui.connectedAccounts.settings.connectionDirection.label',
	},
	directionValueConnectToAgency: {
		id: 'ui.connectedAccounts.settings.connectionDirection.value.connectToAgency',
	},
	directionValueConnectToClient: {
		id: 'ui.connectedAccounts.settings.connectionDirection.value.connectToClient',
	},
	title: {
		id: 'ui.connectedAccounts.settings.connectionDirection.title',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	agencyConnections: ReadonlyArray<{
		agencyAccountId: CK.AccountId,
		agencyAccountName: string,
		isPending: boolean,
	}>,
	clientConnections: ReadonlyArray<{
		clientAccountId: CK.AccountId,
	}>,
};



enum ConnectionDirection {
	ConnectToAgency = 'connectToAgency',
	ConnectToClient = 'connectToClient',
}



const ConnectionDirectionForm: React.FC<Props> = (props) => {
	const {
		accountId,
		agencyConnections,
		clientConnections,
	} = props;

	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);
	const modals = useModals();
	const revokeAgencyAccess = useRevokeAgencyAccess();

	const [modifyAccountAccessConnectionDirection] = useModifyAccountAccessConnectionDirectionMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (accountId === null) {
				return;
			}

			try {
				await modifyAccountAccessConnectionDirection({
					variables: {
						accountId,
						connectionDirection: values.connectionDirection,
					},
				});
			} catch (e) {
				throw FormError.fromApolloError(e);
			}
		},
		[
			accountId,
			modifyAccountAccessConnectionDirection,
		],
	);

	const canConnectToAgency = (
		accountEnabledFeatures !== null
		&& accountEnabledFeatures.includes(GraphQL.AccountFeature.ConnectToAgency)
	);

	const canConnectToClient = (
		accountEnabledFeatures !== null
		&& accountEnabledFeatures.includes(GraphQL.AccountFeature.ConnectToClient)
	);

	const canConnectToBoth = canConnectToAgency && canConnectToClient;

	const connectionDirection = canConnectToClient
		? ConnectionDirection.ConnectToClient
		: ConnectionDirection.ConnectToAgency;

	const agencyBlockingConnectToClient = (
		connectionDirection === ConnectionDirection.ConnectToAgency
		&& agencyConnections.length > 0
	) ? getArrayItemAtSafeIndex(agencyConnections, 0) : null;

	const isConnectToAgencyDisabled = (
		connectionDirection === ConnectionDirection.ConnectToClient
		&& clientConnections.length > 0
	);

	const options = React.useMemo(
		() => {
			let connectToClientLabel = (
				<FormattedMessage {...messages.directionValueConnectToClient} />
			);

			if (agencyBlockingConnectToClient !== null) {
				const linkCancelRequest = () => {
					if (accountId === null) {
						return;
					}

					revokeAgencyAccess({
						agencyId: agencyBlockingConnectToClient.agencyAccountId,
						clientId: accountId,
					});
				};

				const linkRemoveConnection = () => {
					if (accountId === null) {
						return;
					}

					modals.openModal(
						() => (
							<RevokeAccessForConnectedAccountConfirmationModal
								agencyAccountId={agencyBlockingConnectToClient.agencyAccountId}
								agencyAccountName={agencyBlockingConnectToClient.agencyAccountName}
								clientAccountId={accountId}
							/>
						),
					);
				};

				connectToClientLabel = (
					<Hint
						attachment={HintAttachment.Center}
						blurDelay={300}
						popup={(
							<FormattedMessage
								{...messages.cannotModifyConnectionDueToExistingConnectionToAgency}
								values={{
									accountName: agencyBlockingConnectToClient.agencyAccountName,
									connectionStatus: agencyBlockingConnectToClient.isPending ? 'pending' : 'established',
									linkCancelRequest: (chunks) => (
										<InternalLink onClickCallback={linkCancelRequest}>
											{chunks}
										</InternalLink>
									),
									linkRemoveConnection: (chunks) => (
										<InternalLink onClickCallback={linkRemoveConnection}>
											{chunks}
										</InternalLink>
									),
								}}
							/>
						)}
						popupVisibility={HintPopupVisibility.OnHover}
					>
						{connectToClientLabel}
					</Hint>
				);
			}

			let connectToAgencyLabel = (
				<FormattedMessage {...messages.directionValueConnectToAgency} />
			);

			if (isConnectToAgencyDisabled) {
				connectToAgencyLabel = (
					<Hint
						attachment={HintAttachment.Center}
						blurDelay={300}
						popup={(
							<FormattedMessage
								{...messages.cannotModifyConnectionDirectionDueToExistingConnectionToClient}
								values={{
									link_connected_accounts: linkInternal('account.connectedAccounts'),
								}}
							/>
						)}
						popupVisibility={HintPopupVisibility.OnHover}
					>
						{connectToAgencyLabel}
					</Hint>
				);
			}

			return [
				{
					disabled: agencyBlockingConnectToClient !== null,
					label: connectToClientLabel,
					value: ConnectionDirection.ConnectToClient,
				},
				{
					disabled: isConnectToAgencyDisabled,
					label: connectToAgencyLabel,
					value: ConnectionDirection.ConnectToAgency,
				},
			];
		},
		[
			accountId,
			agencyBlockingConnectToClient,
			isConnectToAgencyDisabled,
			modals,
			revokeAgencyAccess,
		],
	);

	return (
		<EditableFormWrapper
			isReadOnly={canConnectToBoth}
			title={<FormattedMessage {...messages.title} />}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						description={(
							<Copy
								{...messages.directionDescription}
								values={{
									link_support: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
								}}
							/>
						)}
						htmlFor="connectionDirection"
						label={(
							<Copy {...messages.directionLabel} />
						)}
					>
						<StaticText focusTarget="connectionDirection">
							{matchAndReturn(connectionDirection, {
								[ConnectionDirection.ConnectToClient]: (
									<FormattedMessage {...messages.directionValueConnectToClient} />
								),
								[ConnectionDirection.ConnectToAgency]: (
									<FormattedMessage {...messages.directionValueConnectToAgency} />
								),
							})}
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						connectionDirection,
					}}
					onSuccess={handleSubmit}
				>
					<FormRows>
						<FormRow
							description={(
								<Copy
									{...messages.directionDescription}
									values={{
										link_support: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
									}}
								/>
							)}
							htmlFor="connectionDirection"
							label={(
								<Copy {...messages.directionLabel} />
							)}
						>
							<RadioList
								items={options}
								name="connectionDirection"
							/>
						</FormRow>
					</FormRows>

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default ConnectionDirectionForm;
