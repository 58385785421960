import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import NoDataMessage from './NoDataMessage';
import PageTypeChart from '~/components/logic/pagesOverview/pagesCharts/fields/PageTypeChart';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';

import {
	useDistributionOfPageTypeWidgetQuery,
} from './DistributionOfPageTypeWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useNavigateToPages from '~/hooks/useNavigateToPages';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateForQuery,
} from '~/model/dashboard';



const messages = defineMessages({
	primaryActionLink: {
		id: 'ui.dashboardDistributionOfPageTypeWidget.primaryActionLink',
	},
	title: {
		id: 'ui.dashboardDistributionOfPageTypeWidget.title',
	},
});



const DistributionOfPageTypeWidget: React.FC = () => {
	const dateRange = useDashboardDateRange();
	const navigateToPages = useNavigateToPages();
	const scope = useDashboardScopeSection();
	const websiteId = useWebsiteId();

	const {
		data,
		loading,
		reasonForNoData,
	} = useDashboardQuery('distributionOfPageType', useDistributionOfPageTypeWidgetQuery, {
		variables: {
			scope,
			websiteId,
			...formatDateForQuery(dateRange),
		},
	});

	const chartData = React.useMemo(
		() => {
			if (data === null) {
				return null;
			}

			return data.map((item) => ({
				count: item.numberOfPages,
				percentage: item.percentageOfPages,
				value: item.pageType,
			}));
		},
		[
			data,
		],
	);

	const hasData = (
		chartData !== null
		&& chartData.reduce((total, { count }) => total + count, 0) > 0
	);

	return (
		<WidgetCell type={WidgetType.PageType}>
			<Widget
				label={(
					<FormattedMessage {...messages.title} />
				)}
				loading={loading}
				overlay={!hasData && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages.primaryActionLink} />
					),
					...navigateToPages({
						graphs: true,
						scope,
					}),
				}}
				showEndDate={true}
			>
				{hasData && (
					<PageTypeChart
						data={chartData}
						height={140}
					/>
				)}
			</Widget>
		</WidgetCell>
	);
};



export default DistributionOfPageTypeWidget;
