/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdministerAccountMainSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  accountTypeChange: GraphQL.AdministerAccountMainSettingsAccountTypeChange;
  canCreateConnectedClientsWithoutUsers: GraphQL.InputMaybe<GraphQL.Scalars['Boolean']['input']>;
  canHaveMultipleWebsiteCopies: GraphQL.Scalars['Boolean']['input'];
  canModifyConnectionDirectionDuringTrial: GraphQL.InputMaybe<GraphQL.Scalars['Boolean']['input']>;
  customerType: GraphQL.InputMaybe<GraphQL.CustomerType>;
  isManaged: GraphQL.Scalars['Boolean']['input'];
  isOutOfBand: GraphQL.InputMaybe<GraphQL.Scalars['Boolean']['input']>;
  maximumAllowedConnectedTrialAccounts: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  maximumInvitations: GraphQL.Scalars['Int']['input'];
  maximumPageBundle: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  maximumPageCapacity: GraphQL.Scalars['Int']['input'];
  maximumSignupPageBundle: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  maximumUnverifiedWebsites: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  maximumWebsites: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  referringPartnerId: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
}>;


export type AdministerAccountMainSettingsMutation = { readonly __typename?: 'Mutation', readonly AdministerAccountMainSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly conductorOrganizationId: string | null, readonly customerType: GraphQL.CustomerType | null, readonly id: CK.AccountId, readonly isManaged: boolean, readonly isOutOfBand: boolean, readonly maximumRegularSignupPageBundle: number | null, readonly maximumSignupPageBundle: number | null, readonly phase: GraphQL.AccountPhase, readonly state: GraphQL.AccountState, readonly tariff: string, readonly type: GraphQL.AccountType, readonly accountAccessRestrictions: { readonly __typename?: 'AccountAccessRestrictions', readonly canModifyConnectionDirectionDuringTrial: boolean }, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly campaignSource: string | null, readonly canHaveMultipleWebsiteCopies: boolean, readonly referringPartnerId: string | null } | null, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }>, readonly userRestrictions: { readonly __typename?: 'AccountUserRestrictions', readonly emailDomainsWhitelist: ReadonlyArray<string> | null, readonly maximumInvitations: number }, readonly websiteRestrictions: { readonly __typename?: 'AccountWebsiteRestrictions', readonly domainsWhitelist: ReadonlyArray<string> | null, readonly maximumPageBundle: number | null, readonly maximumPageCapacity: number, readonly maximumUnverifiedWebsites: number | null, readonly maximumWebsites: number | null } } | null } } };

export type MainSettingsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type MainSettingsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly conductorOrganizationId: string | null, readonly customerType: GraphQL.CustomerType | null, readonly id: CK.AccountId, readonly isManaged: boolean, readonly isOutOfBand: boolean, readonly phase: GraphQL.AccountPhase, readonly state: GraphQL.AccountState, readonly tariff: string, readonly type: GraphQL.AccountType, readonly accountAccessRestrictions: { readonly __typename?: 'AccountAccessRestrictions', readonly canModifyConnectionDirectionDuringTrial: boolean }, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly campaignSource: string | null, readonly canHaveMultipleWebsiteCopies: boolean, readonly referringPartnerId: string | null } | null, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }>, readonly userRestrictions: { readonly __typename?: 'AccountUserRestrictions', readonly emailDomainsWhitelist: ReadonlyArray<string> | null, readonly maximumInvitations: number }, readonly websiteRestrictions: { readonly __typename?: 'AccountWebsiteRestrictions', readonly domainsWhitelist: ReadonlyArray<string> | null, readonly maximumPageBundle: number | null, readonly maximumPageCapacity: number, readonly maximumUnverifiedWebsites: number | null, readonly maximumWebsites: number | null } } | null };


export const AdministerAccountMainSettingsDocument = gql`
    mutation AdministerAccountMainSettings($accountId: AccountId!, $accountTypeChange: AdministerAccountMainSettingsAccountTypeChange!, $canCreateConnectedClientsWithoutUsers: Boolean, $canHaveMultipleWebsiteCopies: Boolean!, $canModifyConnectionDirectionDuringTrial: Boolean, $customerType: CustomerType, $isManaged: Boolean!, $isOutOfBand: Boolean, $maximumAllowedConnectedTrialAccounts: Int, $maximumInvitations: Int!, $maximumPageBundle: Int, $maximumPageCapacity: Int!, $maximumSignupPageBundle: Int, $maximumUnverifiedWebsites: Int, $maximumWebsites: Int, $referringPartnerId: String) {
  AdministerAccountMainSettings(
    accountId: $accountId
    accountTypeChange: $accountTypeChange
    canCreateConnectedClientsWithoutUsers: $canCreateConnectedClientsWithoutUsers
    canHaveMultipleWebsiteCopies: $canHaveMultipleWebsiteCopies
    canModifyConnectionDirectionDuringTrial: $canModifyConnectionDirectionDuringTrial
    customerType: $customerType
    isManaged: $isManaged
    isOutOfBand: $isOutOfBand
    maximumAllowedConnectedTrialAccounts: $maximumAllowedConnectedTrialAccounts
    maximumInvitations: $maximumInvitations
    maximumPageBundle: $maximumPageBundle
    maximumPageCapacity: $maximumPageCapacity
    maximumSignupPageBundle: $maximumSignupPageBundle
    maximumUnverifiedWebsites: $maximumUnverifiedWebsites
    maximumWebsites: $maximumWebsites
    referringPartnerId: $referringPartnerId
  ) {
    query {
      account(id: $accountId) {
        accountAccessRestrictions {
          canModifyConnectionDirectionDuringTrial
        }
        adminSettings {
          campaignSource
          canHaveMultipleWebsiteCopies
          referringPartnerId
        }
        conductorOrganizationId
        customerType
        features {
          feature
          id
        }
        id
        isManaged
        isOutOfBand
        maximumRegularSignupPageBundle
        maximumSignupPageBundle
        phase
        state
        tariff
        type
        userRestrictions {
          emailDomainsWhitelist
          maximumInvitations
        }
        websiteRestrictions {
          domainsWhitelist
          maximumPageBundle
          maximumPageCapacity
          maximumUnverifiedWebsites
          maximumWebsites
        }
      }
    }
  }
}
    `;
export type AdministerAccountMainSettingsMutationFn = Apollo.MutationFunction<AdministerAccountMainSettingsMutation, AdministerAccountMainSettingsMutationVariables>;

/**
 * __useAdministerAccountMainSettingsMutation__
 *
 * To run a mutation, you first call `useAdministerAccountMainSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerAccountMainSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerAccountMainSettingsMutation, { data, loading, error }] = useAdministerAccountMainSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      accountTypeChange: // value for 'accountTypeChange'
 *      canCreateConnectedClientsWithoutUsers: // value for 'canCreateConnectedClientsWithoutUsers'
 *      canHaveMultipleWebsiteCopies: // value for 'canHaveMultipleWebsiteCopies'
 *      canModifyConnectionDirectionDuringTrial: // value for 'canModifyConnectionDirectionDuringTrial'
 *      customerType: // value for 'customerType'
 *      isManaged: // value for 'isManaged'
 *      isOutOfBand: // value for 'isOutOfBand'
 *      maximumAllowedConnectedTrialAccounts: // value for 'maximumAllowedConnectedTrialAccounts'
 *      maximumInvitations: // value for 'maximumInvitations'
 *      maximumPageBundle: // value for 'maximumPageBundle'
 *      maximumPageCapacity: // value for 'maximumPageCapacity'
 *      maximumSignupPageBundle: // value for 'maximumSignupPageBundle'
 *      maximumUnverifiedWebsites: // value for 'maximumUnverifiedWebsites'
 *      maximumWebsites: // value for 'maximumWebsites'
 *      referringPartnerId: // value for 'referringPartnerId'
 *   },
 * });
 */
export function useAdministerAccountMainSettingsMutation(baseOptions?: Apollo.MutationHookOptions<AdministerAccountMainSettingsMutation, AdministerAccountMainSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerAccountMainSettingsMutation, AdministerAccountMainSettingsMutationVariables>(AdministerAccountMainSettingsDocument, options);
      }
export type AdministerAccountMainSettingsMutationHookResult = ReturnType<typeof useAdministerAccountMainSettingsMutation>;
export type AdministerAccountMainSettingsMutationResult = Apollo.MutationResult<AdministerAccountMainSettingsMutation>;
export type AdministerAccountMainSettingsMutationOptions = Apollo.BaseMutationOptions<AdministerAccountMainSettingsMutation, AdministerAccountMainSettingsMutationVariables>;
export const MainSettingsFormDocument = gql`
    query MainSettingsForm($accountId: AccountId!) {
  account(id: $accountId) {
    accountAccessRestrictions {
      canModifyConnectionDirectionDuringTrial
    }
    adminSettings {
      campaignSource
      canHaveMultipleWebsiteCopies
      referringPartnerId
    }
    conductorOrganizationId
    customerType
    features {
      feature
      id
    }
    id
    isManaged
    isOutOfBand
    phase
    state
    tariff
    type
    userRestrictions {
      emailDomainsWhitelist
      maximumInvitations
    }
    websiteRestrictions {
      domainsWhitelist
      maximumPageBundle
      maximumPageCapacity
      maximumUnverifiedWebsites
      maximumWebsites
    }
  }
}
    `;

/**
 * __useMainSettingsFormQuery__
 *
 * To run a query within a React component, call `useMainSettingsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainSettingsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainSettingsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useMainSettingsFormQuery(baseOptions: Apollo.QueryHookOptions<MainSettingsFormQuery, MainSettingsFormQueryVariables> & ({ variables: MainSettingsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MainSettingsFormQuery, MainSettingsFormQueryVariables>(MainSettingsFormDocument, options);
      }
export function useMainSettingsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MainSettingsFormQuery, MainSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MainSettingsFormQuery, MainSettingsFormQueryVariables>(MainSettingsFormDocument, options);
        }
export function useMainSettingsFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MainSettingsFormQuery, MainSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MainSettingsFormQuery, MainSettingsFormQueryVariables>(MainSettingsFormDocument, options);
        }
export type MainSettingsFormQueryHookResult = ReturnType<typeof useMainSettingsFormQuery>;
export type MainSettingsFormLazyQueryHookResult = ReturnType<typeof useMainSettingsFormLazyQuery>;
export type MainSettingsFormSuspenseQueryHookResult = ReturnType<typeof useMainSettingsFormSuspenseQuery>;
export type MainSettingsFormQueryResult = Apollo.QueryResult<MainSettingsFormQuery, MainSettingsFormQueryVariables>;