import type CK from '~/types/contentking';

import {
	useAllowedPaymentMethodsQuery,
} from './useAllowedPaymentMethods.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAllowedPaymentMethods(accountId: CK.AccountId | null): Readonly<Array<string>> | null {
	const { data } = useAccountPropertiesQuery(
		useAllowedPaymentMethodsQuery,
		accountId,
	);

	return data?.account?.allowedPaymentMethods ?? null;
}



export default useAllowedPaymentMethods;
