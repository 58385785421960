import React from 'react';

import type CK from '~/types/contentking';

import AlertPagesDatatable, {
	type AlertPagesDatatableIncident,
	type AlertPagesDatatableRef,
} from '~/components/logic/alertPages/AlertPagesDatatable';

import {
	type IssueCategoryName,
} from '~/model/issuesNew';

import {
	getCategoryUrlIdentifier,
} from '~/model/issues/identifierMapping';



type Props = {
	extraColumns: Array<CK.PagesColumn>,
	incident: AlertPagesDatatableIncident,
	issueCategory: IssueCategoryName,
	scrollCallback: () => void,
};

const IssueOpenedDatatable = React.forwardRef<AlertPagesDatatableRef, Props>((props, ref) => {
	const {
		extraColumns,
		incident,
		issueCategory,
		scrollCallback,
	} = props;

	const linkRouteParams = React.useMemo(
		() => ({
			pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategory),
		}),
		[
			issueCategory,
		],
	);

	return (
		<AlertPagesDatatable
			extraColumns={extraColumns}
			incident={incident}
			linkRouteName="website.pages.detail.issuesCategory"
			linkRouteParams={linkRouteParams}
			ref={ref}
			scrollCallback={scrollCallback}
			showImportance={true}
		/>
	);
});



export default IssueOpenedDatatable;
