/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteCustomerTeamContextDetailsQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteCustomerTeamContextDetailsQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly customerTeamContext: { readonly __typename?: 'CustomerTeamContext', readonly details: Record<string, any> | null } | null } | null };


export const WebsiteCustomerTeamContextDetailsDocument = gql`
    query WebsiteCustomerTeamContextDetails($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    customerTeamContext {
      details
    }
  }
}
    `;

/**
 * __useWebsiteCustomerTeamContextDetailsQuery__
 *
 * To run a query within a React component, call `useWebsiteCustomerTeamContextDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteCustomerTeamContextDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteCustomerTeamContextDetailsQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteCustomerTeamContextDetailsQuery(baseOptions: Apollo.QueryHookOptions<WebsiteCustomerTeamContextDetailsQuery, WebsiteCustomerTeamContextDetailsQueryVariables> & ({ variables: WebsiteCustomerTeamContextDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteCustomerTeamContextDetailsQuery, WebsiteCustomerTeamContextDetailsQueryVariables>(WebsiteCustomerTeamContextDetailsDocument, options);
      }
export function useWebsiteCustomerTeamContextDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteCustomerTeamContextDetailsQuery, WebsiteCustomerTeamContextDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteCustomerTeamContextDetailsQuery, WebsiteCustomerTeamContextDetailsQueryVariables>(WebsiteCustomerTeamContextDetailsDocument, options);
        }
export function useWebsiteCustomerTeamContextDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteCustomerTeamContextDetailsQuery, WebsiteCustomerTeamContextDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteCustomerTeamContextDetailsQuery, WebsiteCustomerTeamContextDetailsQueryVariables>(WebsiteCustomerTeamContextDetailsDocument, options);
        }
export type WebsiteCustomerTeamContextDetailsQueryHookResult = ReturnType<typeof useWebsiteCustomerTeamContextDetailsQuery>;
export type WebsiteCustomerTeamContextDetailsLazyQueryHookResult = ReturnType<typeof useWebsiteCustomerTeamContextDetailsLazyQuery>;
export type WebsiteCustomerTeamContextDetailsSuspenseQueryHookResult = ReturnType<typeof useWebsiteCustomerTeamContextDetailsSuspenseQuery>;
export type WebsiteCustomerTeamContextDetailsQueryResult = Apollo.QueryResult<WebsiteCustomerTeamContextDetailsQuery, WebsiteCustomerTeamContextDetailsQueryVariables>;