import {
	defineMessages,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';

import {
	useAccountDisplayNamesQuery,
} from './useAccountDisplayName.gql';

import useHomeAccountIds from '~/hooks/useHomeAccountIds';
import usePollInterval from '~/hooks/usePollInterval';



const messages = defineMessages({
	yourAccount: {
		id: 'ui.accountAccess.contextSelect.yourAccountLabel',
	},
});



function useAccountDisplayName(accountId: CK.AccountId | null): string {
	const homeAccountIds = useHomeAccountIds();
	const intl = useIntl();

	const { data } = useAccountDisplayNamesQuery({
		pollInterval: usePollInterval(30_000),
	});

	const accounts = data?.authenticatedSession?.accounts ?? null;

	if (
		accounts === null
		|| accountId === null
		|| homeAccountIds === null
	) {
		return '';
	}

	const accountDisplayName = accounts.find((account) => account.id === accountId)?.displayName ?? null;

	if (accountDisplayName === null) {
		return homeAccountIds.includes(accountId)
			? intl.formatMessage(messages.yourAccount, { numberOfAccounts: 1 })
			: '';
	}

	return (homeAccountIds.includes(accountId) && accountDisplayName === '')
		? intl.formatMessage(messages.yourAccount, { numberOfAccounts: 1 })
		: accountDisplayName;
}



export default useAccountDisplayName;
