import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountPremiumTrialOfferQuery,
} from './useAccountPremiumTrialOffer.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountPremiumTrialOffer(accountId: CK.AccountId | null): GraphQL.AccountPremiumTrialOffer | null {
	const { data } = useAccountPropertiesQuery(
		useAccountPremiumTrialOfferQuery,
		accountId,
	);

	return data?.account?.premiumTrialOffer ?? null;
}



export default useAccountPremiumTrialOffer;
