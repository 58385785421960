import Immutable from 'immutable';

import {
	LOGIN_SUCCESSFUL,
} from '~/actions';

import {
	SET_COLUMN_SETTINGS_IS_SAVING,
	STORE_COLUMN_SETTINGS,
} from '~/actions/columnsSettings';



function createDefaultState() {
	return Immutable.Map();
}


export function columnsSettings(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case LOGIN_SUCCESSFUL: {
			const {
				response,
			} = action;

			if (response.is_impersonated && state.size > 0) {
				break;
			}

			if (!response.column_settings) {
				break;
			}

			const columnsSettingsArray = response.column_settings;
			let currentUserSettings = Immutable.Map();

			columnsSettingsArray.forEach((columnSetting) => {
				const isSaving = state.get(columnSetting.name)
					? state.get(columnSetting.name).get('isSaving')
					: false;

				currentUserSettings = !isSaving
					? currentUserSettings.set(columnSetting.name, Immutable.Map(columnSetting))
					: currentUserSettings.set(columnSetting.name, Immutable.Map({
						name: columnSetting.name,
						width: state.get(columnSetting.name).get('width'),
					}));
			});

			if (currentUserSettings.size >= state.size) {
				state = state.mergeDeep(currentUserSettings);
			} else {
				state = state.set(currentUserSettings);
			}

			break;
		}

		case SET_COLUMN_SETTINGS_IS_SAVING: {
			const {
				columnName,
				value,
			} = action;

			state = state.setIn([columnName, 'isSaving'], value);

			break;
		}

		case STORE_COLUMN_SETTINGS: {
			const {
				columnName,
				width,
			} = action;

			state = state.mergeIn([columnName], Immutable.Map({
				isSaving: true,
				name: columnName,
				width,
			}));

			break;
		}

	}

	return state;
}
