import React from 'react';

import type CK from '~/types/contentking';

import {
	useImportSegmentsMutation,
} from './useImportSegmentDefinitions.gql';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	SegmentsComparisonVerdict,
	compareSegments,
	filterSegmentDefinitionsByNames,
} from '~/model/segments';



function useImportSegmentDefinitions({
	sourceWebsiteId,
	targetWebsiteId,
}: {
	sourceWebsiteId: CK.WebsiteId | null,
	targetWebsiteId: CK.WebsiteId | null,
}) {
	const sourceSegmentDefinitions = useWebsiteSegmentDefinitions(sourceWebsiteId);
	const targetCustomElements = useWebsiteCustomElementDefinitions(targetWebsiteId);
	const targetSegmentDefinitions = useWebsiteSegmentDefinitions(targetWebsiteId);

	const [importSegments] = useImportSegmentsMutation();

	return React.useCallback(
		async (segmentNames: ReadonlyArray<string>) => {
			if (sourceWebsiteId === null) {
				throw new Error(`sourceWebsiteId can't be null`);
			}

			if (targetWebsiteId === null) {
				throw new Error(`targetWebsiteId can't be null`);
			}

			const selectedSegments = filterSegmentDefinitionsByNames(
				sourceSegmentDefinitions.listAll(),
				segmentNames,
			);

			const segmentsComparison = compareSegments(
				sourceSegmentDefinitions.listAll(),
				targetSegmentDefinitions.listAll(),
				targetCustomElements,
				selectedSegments,
			);

			const changeCriteriaInstructions: Array<{
				filterDefinition: CK.FilterDefinition,
				segmentId: CK.PageSegmentId,
				sizeLimit: CK.PageSegmentSizeLimit | null,
				sourceSegmentId: CK.PageSegmentId,
			}> = [];

			const changeIdentifierInstructions: Array<{
				color: string,
				iconName: string | null,
				label: string,
				segmentId: CK.PageSegmentId,
				shortcode: string | null,
				sourceSegmentId: CK.PageSegmentId,
			}> = [];

			const createInstructions: Array<{
				color: string,
				filterDefinition: CK.FilterDefinition,
				iconName: string | null,
				label: string,
				shortcode: string | null,
				sizeLimit: CK.PageSegmentSizeLimit | null,
				sourceSegmentId: CK.PageSegmentId,
			}> = [];

			const exposeDependencies = (filterDefinition: CK.FilterDefinition) => {
				return Object.fromEntries(
					Object.entries(filterDefinition).map(([key, value]) => {
						if (key === 'segments') {
							return [
								key,
								{
									included_in: filterSegmentDefinitionsByNames(
										sourceSegmentDefinitions.listAll(),
										value.included_in,
									).map(
										(segmentDefinition) => exposeDependencies(segmentDefinition.filterDefinition),
									),
									not_included_in: filterSegmentDefinitionsByNames(
										sourceSegmentDefinitions.listAll(),
										value.not_included_in,
									).map(
										(segmentDefinition) => exposeDependencies(segmentDefinition.filterDefinition),
									),
									operator: value.operator,
								},
							];
						}

						return [key, value];
					}),
				);
			};

			selectedSegments.forEach((sourceSegment) => {
				const sourceSegmentComparison = segmentsComparison(sourceSegment.name);

				if (sourceSegmentComparison.verdict === SegmentsComparisonVerdict.NotPresent) {
					createInstructions.push({
						color: sourceSegment.color,
						filterDefinition: exposeDependencies(sourceSegment.filterDefinition),
						iconName: sourceSegment.icon?.name ?? null,
						label: sourceSegment.label,
						shortcode: sourceSegment.shortcode,
						sizeLimit: sourceSegment.sizeLimit,
						sourceSegmentId: sourceSegment.id,
					});
				}

				if (sourceSegmentComparison.verdict === SegmentsComparisonVerdict.PresentWithSameCriteria) {
					changeIdentifierInstructions.push({
						color: sourceSegment.color,
						iconName: sourceSegment.icon?.name ?? null,
						label: sourceSegment.label,
						segmentId: sourceSegmentComparison.similarSegment.id,
						shortcode: sourceSegment.shortcode,
						sourceSegmentId: sourceSegment.id,
					});
				}

				if (sourceSegmentComparison.verdict === SegmentsComparisonVerdict.PresentWithSameIdentifier) {
					changeCriteriaInstructions.push({
						filterDefinition: exposeDependencies(sourceSegment.filterDefinition),
						segmentId: sourceSegmentComparison.similarSegment.id,
						sizeLimit: sourceSegment.sizeLimit,
						sourceSegmentId: sourceSegment.id,
					});
				}
			});

			await importSegments({
				variables: {
					changeCriteriaInstructions,
					changeIdentifierInstructions,
					createInstructions,
					sourceWebsiteId,
					targetWebsiteId,
				},
			});
		},
		[
			importSegments,
			sourceSegmentDefinitions,
			sourceWebsiteId,
			targetCustomElements,
			targetSegmentDefinitions,
			targetWebsiteId,
		],
	);
}



export default useImportSegmentDefinitions;
