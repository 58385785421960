import React from 'react';
import {
	FormattedMessage,
} from 'react-intl';



const NativeAlertTypeName = (props) => {
	const {
		alertType,
	} = props;

	return (
		<FormattedMessage
			id={'alerts.types.' + alertType + '.title'}
		/>
	);
};



export default NativeAlertTypeName;
