import React from 'react';
import {
	FormattedMessage,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BillingCycleName from '~/components/names/BillingCycleName';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MultiselectField from '~/components/atoms/forms/components/MultiselectField';
import PlanName from '~/components/names/PlanName';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useAdministerAccountTransactionSettingsMutation,
	useTransactionsSettingsFormQuery,
} from './TransactionsSettingsForm.gql';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';
import useAccountCountry from '~/hooks/useAccountCountry';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountDetailedDiscounts from '~/hooks/useAccountDetailedDiscounts';
import useAccountFixedBillingEntity from '~/hooks/useAccountFixedBillingEntity';
import useAccountIsSubscriptionRenewalEnabled from '~/hooks/useAccountIsSubscriptionRenewalEnabled';
import useAccountPaymentMethod from '~/hooks/useAccountPaymentMethod';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useAllowedCurrencies from '~/hooks/useAllowedCurrencies';
import useAllowedPaymentMethods from '~/hooks/useAllowedPaymentMethods';
import useAllowedPlans from '~/hooks/useAllowedPlans';

import {
	listUsedBillingCycles,
} from '~/model/accounts';

import {
	METHOD_CARD,
	METHOD_INVOICE,
	METHOD_SEPA_DEBIT,
} from '~/model/payments';

import {
	BillingCycleByDuration,
} from '~/model/pricing/billingCycle';

import {
	LIST_OF_ALL_PLANS,
} from '~/model/universal';

import {
	countries,
} from '~/utilities/countries';

import {
	currencies,
} from '~/utilities/currencies';

import getOptionLabel from '~/utilities/getOptionLabel';
import getOptionsLabel from '~/utilities/getOptionsLabel';



const validations = {
	allowedBillingCycles: [
		{
			message: 'At least one billing cycle must be allowed',
			field: 'allowedBillingCycles',
			rule: ({ values, name }) => {
				return values.allowedPlans.indexOf(GraphQL.AccountPlan.Enterprise) === -1 || (values[name] && values[name].length > 0);
			},
		},
	],
	allowedCurrencies: [
		{
			message: 'At least one currency must be allowed',
			field: 'allowedCurrencies',
			rule: ({ values, name }) => {
				return values[name] && values[name].length > 0;
			},
		},
	],
	allowedPaymentMethods: [
		{
			message: 'At least one payment method must be allowed',
			field: 'allowedPaymentMethods',
			rule: ({ values, name }) => {
				return values[name] && values[name].length > 0;
			},
		},
	],
	allowedPlans: [
		{
			message: 'At least one plan must be allowed',
			field: 'allowedPlans',
			rule: ({ values, name }) => {
				return values[name] && values[name].length > 0;
			},
		},
	],
};



type Props = {
	accountId: CK.AccountId,
};

const TransactionsSettingsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountBillingEntity = useAccountBillingEntity(accountId);
	const accountCountry = useAccountCountry(accountId);
	const accountCurrency = useAccountCurrency(accountId);
	const accountDetailedDiscounts = useAccountDetailedDiscounts(accountId);
	const accountFixedBillingEntity = useAccountFixedBillingEntity(accountId);
	const accountIsSubscriptionRenewalEnabled = useAccountIsSubscriptionRenewalEnabled(accountId);
	const accountPaymentMethod = useAccountPaymentMethod(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);
	const allowedCurrencies = useAllowedCurrencies(accountId);
	const allowedPaymentMethods = useAllowedPaymentMethods(accountId);
	const allowedPlans = useAllowedPlans(accountId);
	const intl = useIntl();

	const { data } = useAccountPropertiesQuery(
		useTransactionsSettingsFormQuery,
		accountId,
	);

	const isLoaded = (data?.account ?? null) !== null;
	const manualPaymentNetTerms = data?.account?.manualPaymentNetTerms ?? null;
	const possibleBillingCycles = data?.account?.possibleBillingCycles ?? null;

	const [administerAccountTransactionSettings] = useAdministerAccountTransactionSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (values.allowedPlans.includes(GraphQL.AccountPlan.Enterprise)) {
				values.allowedBillingCycles = [];

				if (values.allowedQuarterly) {
					values.allowedBillingCycles.push(GraphQL.Term.Quarterly);
				}

				values.allowedBillingCycles.push(GraphQL.Term.Annually);

				delete values.allowedQuarterly;
			}

			if (values.fixedBillingEntity === 'auto') {
				values.fixedBillingEntity = null;
			}

			await administerAccountTransactionSettings({
				variables: {
					accountId,
					allowedBillingCycles: values.allowedBillingCycles,
					allowedCurrencies: values.allowedCurrencies,
					allowedPaymentMethods: values.allowedPaymentMethods,
					allowedPlans: values.allowedPlans,
					customDiscount: values.customDiscount,
					fixedBillingEntity: values.fixedBillingEntity,
					isRenewalEnabled: values.isRenewalEnabled,
					manualPaymentNetTerms: values.manualPaymentNetTerms,
				},
			});
		},
		[
			accountId,
			administerAccountTransactionSettings,
		],
	);

	const billingCycleOptions = React.useMemo(
		() => {
			const usedBillingCycles = listUsedBillingCycles(
				accountPhase,
				accountBillingCycle,
			);

			return BillingCycleByDuration
				.filter((billingCycle) => (possibleBillingCycles ?? []).includes(billingCycle))
				.map((billingCycle) => {
					return {
						disabled: usedBillingCycles.includes(billingCycle),
						title: (
							<BillingCycleName billingCycle={billingCycle} />
						),
						name: billingCycle,
					};
				});
		},
		[
			accountBillingCycle,
			accountPhase,
			possibleBillingCycles,
		],
	);

	const countryOptions = React.useMemo(
		() => {
			return countries.map((code) => {
				return {
					label: intl.formatMessage({ id: 'countries.' + code } as any),
					name: code,
				};
			}).sort(({ label: labelA }, { label: labelB }) => {
				return labelA.localeCompare(labelB);
			});
		},
		[
			intl,
		],
	);

	const currencyOptions = React.useMemo(
		() => {
			return currencies.map(({ code }) => {
				return {
					label: code,
					name: code,
				};
			});
		},
		[],
	);

	const getMultipleCurrencyOptions = (currency: string | null = null) => {
		return currencies.map(({ code }) => {
			return {
				disabled: (currency !== null && code === currency),
				title: code,
				name: code,
			};
		});
	};

	const fixedBillingEntityOptions = React.useMemo(
		() => {
			return [
				{
					name: 'auto',
					label: 'Auto',
				},
				{
					name: 'nl',
					label: 'Force NL',
				},
				{
					name: 'us',
					label: 'Force US',
				},
			];
		},
		[],
	);

	const paymentMethodOptions = React.useMemo(
		() => {
			return [
				{
					disabled: accountPaymentMethod?.type === METHOD_CARD,
					name: METHOD_CARD,
					title: 'Credit card',
				},
				{
					disabled: accountPaymentMethod?.type === METHOD_INVOICE,
					name: METHOD_INVOICE,
					title: 'By invoice',
				},
				{
					disabled: accountPaymentMethod?.type === METHOD_SEPA_DEBIT,
					name: METHOD_SEPA_DEBIT,
					title: 'SEPA (experimental)',
				},
			];
		},
		[
			accountPaymentMethod,
		],
	);

	const planOptions = React.useMemo(
		() => {
			if (accountTariff === null) {
				return [];
			}

			return LIST_OF_ALL_PLANS[accountTariff]?.map((plan) => {
				return {
					disabled: accountPhase === GraphQL.AccountPhase.Customer && accountPlan === plan,
					title: <PlanName plan={plan} />,
					name: plan,
				};
			}).toArray() ?? [];
		},
		[
			accountPhase,
			accountPlan,
			accountTariff,
		],
	);

	if (
		accountCountry === null
		|| accountDetailedDiscounts === null
		|| accountIsSubscriptionRenewalEnabled === null
		|| allowedBillingCycles === null
		|| allowedCurrencies === null
		|| allowedPaymentMethods === null
		|| allowedPlans === null
		|| isLoaded === false
	) {
		return null;
	}

	const defaultValues: Record<string, any> = {
		allowedBillingCycles: allowedPlans.includes(GraphQL.AccountPlan.Enterprise) === false
			? allowedBillingCycles
			: [
				GraphQL.Term.Annually,
				GraphQL.Term.Monthly,
				GraphQL.Term.Quarterly,
			],
		allowedQuarterly: allowedPlans.includes(GraphQL.AccountPlan.Enterprise)
			? allowedBillingCycles.includes(GraphQL.Term.Quarterly)
			: accountBillingCycle === GraphQL.Term.Quarterly,
		allowedCurrencies,
		allowedPaymentMethods: allowedPaymentMethods.filter(
			(paymentMethod) => paymentMethod !== 'paypal',
		),
		allowedPlans,
		customDiscount: accountDetailedDiscounts.map((discount) => discount.coupon).join(', '),
		defaultCountry: accountCountry,
		fixedBillingEntity: accountFixedBillingEntity,
		isRenewalEnabled: accountIsSubscriptionRenewalEnabled,
		manualPaymentNetTerms: manualPaymentNetTerms ?? 14,
	};

	if (accountPhase === GraphQL.AccountPhase.Trial) {
		defaultValues.currency = accountCurrency;
	}

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageInternals}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					isForAdmins={true}
					title="Transactions"
				>
					<DisplayPart>
						<FormRows>
							<FormRow label="Discount coupon">
								<StaticList
									focusTarget="customDiscount"
									items={
										accountDetailedDiscounts.length > 0
											? accountDetailedDiscounts.map((discount) => discount.coupon)
											: ['none']
									}
								/>
							</FormRow>

							<FormRow label="Country">
								<StaticText focusTarget={accountPhase === GraphQL.AccountPhase.Trial ? 'defaultCountry' : undefined}>
									<FormattedMessage id={'countries.' + accountCountry as any} />
								</StaticText>
							</FormRow>

							<FormRow label="Currency">
								<StaticText focusTarget={accountPhase === GraphQL.AccountPhase.Trial ? 'currency' : undefined}>
									{getOptionLabel(currencyOptions, accountCurrency)}
								</StaticText>
							</FormRow>

							<FormRow label="Available currencies">
								<StaticList
									focusTarget="allowedCurrencies"
									items={getOptionsLabel(getMultipleCurrencyOptions(), allowedCurrencies)}
								/>
							</FormRow>

							<FormRow label="Available plans">
								<StaticList
									focusTarget="allowedPlans"
									items={getOptionsLabel(planOptions, allowedPlans)}
								/>
							</FormRow>

							<FormRow label="Available billing cycles">
								<StaticList
									focusTarget="allowedBillingCycles"
									items={getOptionsLabel(billingCycleOptions, allowedBillingCycles)}
								/>
							</FormRow>

							<FormRow label="Automatic renewal">
								<StaticText focusTarget="isRenewalEnabled">
									{accountIsSubscriptionRenewalEnabled ? 'on' : 'off'}
								</StaticText>
							</FormRow>

							<FormRow label="Available payment methods">
								<StaticList
									focusTarget="allowedPaymentMethods"
									items={getOptionsLabel(paymentMethodOptions, allowedPaymentMethods)}
								/>
							</FormRow>

							{allowedPaymentMethods.includes(METHOD_INVOICE) && (
								<FormRow label="Net terms">
									<StaticText focusTarget="manualPaymentNetTerms">
										{manualPaymentNetTerms}
									</StaticText>
								</FormRow>
							)}

							<FormRow label="Billing entity">
								<StaticText focusTarget={accountPhase === GraphQL.AccountPhase.Trial ? 'fixedBillingEntity' : undefined}>
									{accountPhase === GraphQL.AccountPhase.Customer ? (
										accountBillingEntity
									) : (
										getOptionLabel(fixedBillingEntityOptions, accountFixedBillingEntity ?? 'auto')
									)}
								</StaticText>
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form
							defaultValues={defaultValues}
							onSuccess={handleSubmit}
							validations={validations}
						>
							{({ values }) => {
								return (
									<>
										<FormRows>
											<FormRow
												htmlFor="customDiscount"
												label="Discount coupon"
											>
												<FieldStatus name="customDiscount">
													<TextField
														name="customDiscount"
														placeholder="no special discount"
														trimValue={true}
													/>
												</FieldStatus>
											</FormRow>

											{accountPhase === GraphQL.AccountPhase.Trial && (
												<FormRow
													htmlFor="defaultCountry"
													label="Country"
												>
													<FieldStatus name="defaultCountry">
														<SelectField
															name="defaultCountry"
															options={countryOptions}
															searchable={true}
														/>
													</FieldStatus>
												</FormRow>
											)}

											{accountPhase === GraphQL.AccountPhase.Trial ? (
												<FormRow
													htmlFor="currency"
													label="Currency"
												>
													<FieldStatus name="currency">
														<SelectField
															name="currency"
															options={currencyOptions}
														/>
													</FieldStatus>
												</FormRow>
											) : (
												<FormRow label="Currency">
													<StaticText>
														{getOptionLabel(currencyOptions, accountCurrency)}
													</StaticText>
												</FormRow>
											)}

											{accountPhase === GraphQL.AccountPhase.Trial ? (
												<FormRow
													htmlFor="allowedCurrencies"
													label="Available currencies"
												>
													<FieldStatus name="allowedCurrencies">
														<MultiselectField
															name="allowedCurrencies"
															options={getMultipleCurrencyOptions(values.currency)}
														/>
													</FieldStatus>
												</FormRow>
											) : (
												<FormRow label="Allowed currencies">
													<StaticList
														items={getOptionsLabel(getMultipleCurrencyOptions(), allowedCurrencies)}
													/>
												</FormRow>
											)}

											<FormRow label="Available plans">
												<FieldStatus name="allowedPlans">
													<MultiselectField
														name="allowedPlans"
														options={planOptions}
													/>
												</FieldStatus>
											</FormRow>

											<FormRow label="Available billing cycles">
												<div
													className={values.allowedPlans.includes(GraphQL.AccountPlan.Enterprise) ? 'visually-hidden' : ''}
													style={{ width: '100%' }}
												>
													<FieldStatus name="allowedBillingCycles">
														<MultiselectField
															name="allowedBillingCycles"
															options={billingCycleOptions}
														/>
													</FieldStatus>
												</div>

												<div className={values.allowedPlans.includes(GraphQL.AccountPlan.Enterprise) ? '' : 'visually-hidden'}>
													<CheckboxField
														disabled={accountBillingCycle === GraphQL.Term.Quarterly}
														label={
															values.allowedQuarterly
																? 'Quarterly available, Annually available'
																: 'Quarterly not available, Annually available'
														}
														name="allowedQuarterly"
														width={false}
													/>
												</div>
											</FormRow>

											<FormRow
												htmlFor="isRenewalEnabled"
												label="Automatic renewal"
											>
												<CheckboxField
													disabled={accountPhase !== GraphQL.AccountPhase.Customer}
													label="on"
													name="isRenewalEnabled"
													width={false}
												/>
											</FormRow>

											<FormRow label="Available payment methods">
												<FieldStatus name="allowedPaymentMethods">
													<MultiselectField
														name="allowedPaymentMethods"
														options={paymentMethodOptions}
													/>
												</FieldStatus>
											</FormRow>

											{values.allowedPaymentMethods.includes(METHOD_INVOICE) && (
												<FormRow
													htmlFor="manualPaymentNetTerms"
													label="Net terms"
												>
													<FieldStatus name="manualPaymentNetTerms">
														<TextField
															attributes={{
																min: 1,
															}}
															name="manualPaymentNetTerms"
															placeholder="days"
															trimValue={true}
															type={TextFieldType.Number}
														/>
													</FieldStatus>
												</FormRow>
											)}

											<FormRow label="Billing entity">
												{accountPhase === GraphQL.AccountPhase.Customer ? (
													<StaticText>
														{accountBillingEntity}
													</StaticText>
												) : (
													<FieldStatus name="fixedBillingEntity">
														<SelectField
															name="fixedBillingEntity"
															options={fixedBillingEntityOptions}
														/>
													</FieldStatus>
												)}
											</FormRow>
										</FormRows>

										<ButtonsLayout>
											<CancelButton />
											<SaveSubmitButton />
										</ButtonsLayout>
									</>
								);
							}}
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)
			}
		</WhenAccountActionAllowed>
	);
};



export default TransactionsSettingsForm;
