import GraphQL from '~/types/graphql';

import {
	DETAIL_PLAN,
} from './universal';

import {
	createTariff,
} from './tariff';



const PAGES_POINTS = [
	1_000,
	5_000,
	10_000,
	25_000,
	50_000,
	75_000,
	100_000,
	250_000,
	500_000,
	750_000,
	1_000_000,
	2_000_000,
	3_000_000,
	4_000_000,
	5_000_000,
	6_000_000,
	7_000_000,
	8_000_000,
	9_000_000,
	10_000_000,
];



function choosePlan({ billingCycle, details }) {
	if (billingCycle !== GraphQL.Term.Annually) {
		return null;
	}

	return details.get(DETAIL_PLAN) + '.' + billingCycle;
}



function convertPageBundleToAddons(pageBundle) {
	for (const pagesPoint of PAGES_POINTS) {
		if (pageBundle <= pagesPoint) {
			return {
				['pages.' + pagesPoint]: 1,
			};
		}
	}
}



function normalizePageCapacity(numberOfPages) {
	return Math.ceil(numberOfPages / 100) * 100;
}



function normalizePageBundle(numberOfPages) {
	for (const pagesPoint of PAGES_POINTS) {
		if (numberOfPages <= pagesPoint) {
			return pagesPoint;
		}
	}

	return;
}



export default createTariff({
	accountTypes: {
		[GraphQL.AccountType.Universal]: {
			choosePlan,
			convertDetailsToAddons: () => { },
			convertPagesToAddons: convertPageBundleToAddons,
			normalizePageCapacity,
			normalizePagesAmount: normalizePageBundle,
		},
	},
});
