import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import ExpandableFilterOperatorHeaderLabel from '~/components/logic/filters/ExpandableFilterOperatorHeaderLabel';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import NumberFieldFilter from '~/components/logic/datatables/NumberFieldFilter';
import PowerStaticTextFieldFilter from '~/components/logic/datatables/PowerStaticTextFieldFilter';
import ResizableDatatable from '~/components/logic/datatables/ResizableDatatable';
import TextualCellValue from '~/components/logic/datatables/cellValues/TextualCellValue';

import useAffectedLinks from '~/hooks/useAffectedLinks';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	updateFilter,
	updateSortBy,
} from '~/actions/affectedLinks';

import {
	PageIssue,
	type PageIssueWithAffectedLinks,
} from '~/model/issuesNew';

import {
	navigate,
} from '~/routing/router';

import {
	filterSelector,
	sortBySelector,
} from '~/state/affectedLinks/selectors';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	headingsBrokenUrl: {
		id: 'ui.pageDetail.linkData.incoming.columns.urlBroken',
	},
	headingsNumberOfIncomingInternalLinks: {
		id: 'ui.pageDetail.linkData.incoming.linkLabel',
	},
	headingsRedirectedUrl: {
		id: 'ui.pageDetail.linkData.incoming.columns.urlRedirected',
	},
	headingsUrl: {
		id: 'ui.pageDetail.linkData.incoming.columns.url',
	},
});



type Props = {
	issueName: PageIssueWithAffectedLinks,
};

const AffectedLinksDatatable: React.FC<Props> = (props) => {
	const {
		issueName,
	} = props;

	const websiteId = useWebsiteId();

	const affectedLinks = useAffectedLinks(issueName, websiteId);
	const dispatch = useDispatch();
	const filter = useSelector(filterSelector);
	const sortBy = useSelector(sortBySelector);

	const handleFilterChange = React.useCallback(
		(filter) => {
			dispatch(
				updateFilter(filter),
			);
		},
		[
			dispatch,
		],
	);

	const handleRowClick = React.useCallback(
		({ row }) => {
			navigate('website.pages.detail', {
				websiteId,
				id: row.id,
			});
		},
		[
			websiteId,
		],
	);

	const handleSort = React.useCallback(
		({ key, direction }) => {
			dispatch(
				updateSortBy({
					key,
					direction,
				}),
			);
		},
		[
			dispatch,
		],
	);

	const columns = React.useMemo(
		() => [
			{
				name: 'url_full',
				render: {
					cell({ row }) {
						return (
							<TextualCellValue
								value={row.url_full}
							/>
						);
					},
					filter: ({ filterName, filterWidth, ref }) => (
						<PowerStaticTextFieldFilter
							name={filterName}
							ref={ref}
							width={filterWidth}
						/>
					),
					label: () => {
						return matchAndReturn(issueName, {
							[PageIssue.LinksBroken]: (
								<FormattedMessage {...messages.headingsBrokenUrl} />
							),
							[PageIssue.LinksRedirected]: (
								<FormattedMessage {...messages.headingsRedirectedUrl} />
							),
						});
					},
					labelFilterOperator: () => (
						<ExpandableFilterOperatorHeaderLabel
							value={filter.url_full}
						/>
					),
				},
				width: 400,
			},
			{
				name: 'number_of_incoming_internal_links',
				render: {
					cell: ({ row }) => {
						return (
							<FormattedNumber value={row.number_of_incoming_internal_links} />
						);
					},
					filter: ({ filterName, filterWidth }) => (
						<NumberFieldFilter
							name={filterName}
							width={filterWidth}
						/>
					),
					label: () => (
						<FormattedMessage {...messages.headingsNumberOfIncomingInternalLinks} />
					),
				},
				width: 250,
			},
		],
		[
			filter,
			issueName,
		],
	);

	return (
		<ResizableDatatable
			blankSlate={(
				<NoSearchResults />
			)}
			columns={columns}
			filter={filter}
			isLoading={affectedLinks.loading}
			onFilterChange={handleFilterChange}
			onRowClick={handleRowClick}
			onScroll={affectedLinks.fetchFurtherRange}
			onSortChangeCallback={handleSort}
			rowGetter={affectedLinks.rowGetter}
			rowsCount={affectedLinks.total}
			sortBy={sortBy}
		/>
	);
};



export default AffectedLinksDatatable;
