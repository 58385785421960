import {
	createFullId,
} from '~/model/pages';

import {
	pageBasicsSelector as generalBasicsSelector,
	pageOutboundLinksSelector as generalOutboundLinksSelector,
	pageOutgoingInternalLinksSelector as generalOutgoingInternalLinksSelector,
} from './selectors';



export const fullIdSelector = (state) => createFullId(
	state.get('ui').get('active').get('website'),
	state.get('ui').get('active').get('page'),
);



function selectCurrent(state, sectionSelector) {
	return sectionSelector(state).get(fullIdSelector(state));
}



export const pageBasicsSelector = (state) => selectCurrent(state, generalBasicsSelector);

export const pageOutgoingInternalLinksSelector = (state) => selectCurrent(state, generalOutgoingInternalLinksSelector);

export const pageOutboundLinksSelector = (state) => selectCurrent(state, generalOutboundLinksSelector);
