import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ChangeTrackingAndAlertingIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M18.5005 11.1201L13.288 2.05507C13.114 1.754 12.8637 1.50402 12.5625 1.33022C12.2612 1.15643 11.9196 1.06494 11.5718 1.06494C11.224 1.06494 10.8823 1.15643 10.5811 1.33022C10.2799 1.50402 10.0296 1.754 9.85554 2.05507L0.940544 17.5001C0.766128 17.8012 0.674134 18.1429 0.673829 18.4908C0.673524 18.8388 0.764917 19.1807 0.938805 19.4821C1.11269 19.7835 1.36293 20.0337 1.66432 20.2076C1.9657 20.3815 2.30759 20.4729 2.65554 20.4726H9.76304"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M11.5703 7.53003V12.555"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M11.5705 15.77C11.8522 15.77 12.0805 15.5417 12.0805 15.26C12.0805 14.9783 11.8522 14.75 11.5705 14.75C11.2889 14.75 11.0605 14.9783 11.0605 15.26C11.0605 15.5417 11.2889 15.77 11.5705 15.77Z"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M12.9473 21.7499C12.9487 19.488 13.7847 17.3062 15.2952 15.6224C16.8056 13.9387 18.8842 12.8715 21.1327 12.6254C23.3811 12.3792 25.6414 12.9714 27.4803 14.2884C29.3193 15.6054 30.6076 17.5547 31.0984 19.7627C31.5892 21.9707 31.2481 24.2822 30.1403 26.2543C29.0325 28.2264 27.2359 29.7203 25.0949 30.4499C22.9539 31.1795 20.619 31.0934 18.5375 30.2081C16.456 29.3229 14.7743 27.7007 13.8148 25.6524"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M22.1396 16.7874V20.7399V21.7449L25.3196 23.5874"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M14.4144 20.5523L12.8119 22.0098L11.6719 20.2773"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default ChangeTrackingAndAlertingIconBuilder;

