import classNames from 'classnames';
import flattenChildren from 'react-keyed-flatten-children';
import React from 'react';

import styles from './List.module.scss';



export enum ListSize {
	Default = 'default',
	Large = 'large',
	Small = 'small',
	XLarge = 'xlarge',
}

export enum ListAlignment {
	Baseline = 'baseline',
	Center = 'center',
}

type Props = {
	/** Content of the list */
	children: React.ReactNode,
	/** Possibility to extent default set of class names and add own name */
	className?: string,
	/** Inline list */
	inline?: boolean,
	size?: ListSize,
	/** Vertical alignment when list is displayed as inline */
	verticalAlignment?: ListAlignment,
};



const List = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		className,
		children,
		inline = false,
		size = ListSize.Default,
		verticalAlignment = ListAlignment.Center,
	} = props;

	const componentClasses = classNames({
		[styles.isInline]: inline,
		[styles.baselineVerticalAlignment]: verticalAlignment === ListAlignment.Baseline,
		[styles.sizeLarge]: size === ListSize.Large,
		[styles.sizeSmall]: size === ListSize.Small,
		[styles.sizeXLarge]: size === ListSize.XLarge,
	}, className);

	return (
		<div
			className={componentClasses}
			ref={ref}
		>
			{flattenChildren(children)
				.map((child, index) => {
					if (!child) {
						return false;
					}

					let key: string | number = index;

					if (React.isValidElement(child) && child.key) {
						key = child.key;
					}

					return (
						<div
							className="abstract-list__item"
							key={key}
						>
							{child}
						</div>
					);
				},
				)}
		</div>
	);
});



export default List;
