import classNames from 'classnames';
import React from 'react';

import useFormContext from '~/hooks/useFormContext';
import useForwardedRef from '~/hooks/useForwardedRef';



type Props = {
	attributes?: React.AllHTMLAttributes<HTMLTextAreaElement>,
	autoComplete?: boolean,
	className?: string,
	emptyAsNull?: boolean,
	height?: number,
	isDisabled?: boolean,
	name: string,
	placeholder?: string,
	readonly?: boolean,
	resizable?: boolean,
	rows?: number,
	spellCheck?: boolean,
	trimValue?: boolean,
	width?: number | string,
};

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
	const {
		attributes = {},
		autoComplete,
		className,
		emptyAsNull = false,
		height,
		isDisabled,
		name,
		placeholder,
		readonly = false,
		resizable = false,
		rows = 5,
		spellCheck,
		trimValue,
		width = 280,
	} = props;

	const formContext = useFormContext();

	const formContextFocused = formContext.focused;
	const formContextOnMountHandler = formContext.onMountHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const fieldRef = useForwardedRef(ref);
	const mountRef = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

	React.useEffect(
		() => {
			formContextOnMountHandler(
				name,
				{},
			);

			return () => {
				formContextOnUnmountHandler(name);

				if (mountRef.current) {
					clearTimeout(mountRef.current);
				}
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			name,
		],
	);

	React.useEffect(
		() => {
			// when allowed focus this field everytime when component is mounted
			if (name === formContextFocused) {
				mountRef.current = setTimeout(() => {
					fieldRef.current?.focus();

					mountRef.current = undefined;
				}, 0);
			}
		},
		[
			fieldRef,
			formContextFocused,
			name,
		],
	);

	const defaultValue = formContext.defaultValues[name];

	React.useEffect(
		() => {
			if (
				defaultValue !== undefined
				&& defaultValue !== null
				&& defaultValue.length !== 0
			) {
				return;
			}

			if (fieldRef.current && fieldRef.current.value !== '') {
				fieldRef.current.value = '';
			}
		},
		[
			defaultValue,
			fieldRef,
		],
	);

	const style: React.CSSProperties = {};

	if (height) {
		style.height = height;
	}

	if (width) {
		style.width = width;
	}

	const additionalAttributes = { ...attributes };

	if (isDisabled || formContext.isDisabled) {
		additionalAttributes.disabled = true;
	}

	if (autoComplete !== undefined) {
		additionalAttributes.autoComplete = autoComplete ? 'on' : 'off';
	}

	if (spellCheck !== undefined) {
		additionalAttributes.spellCheck = spellCheck ? 'true' : 'false';
	}

	if (height) {
		additionalAttributes.style = {
			height,
		};
	}

	const fieldClasses = classNames({
		'text-field__input': true,
		'text-field__input--textarea': true,
		'text-field__input--resizeable-textarea': resizable,
	}, className);

	return (
		<div
			className="text-field"
			style={style}
		>
			<textarea
				className={fieldClasses}
				defaultValue={formContext.defaultValues[name]}
				id={name}
				name={name}
				onBlur={() => {
					formContext.onBlurHandler(name);
				}}
				onChange={!readonly ? (event) => {
					let value = event.target.value;

					if (trimValue) {
						value = value.trim();
					}

					formContext.onChangeHandler(name, emptyAsNull && value === '' ? null : value, {
						timeout: 250,
					});
				} : undefined}
				onFocus={() => {
					formContext.onFocusHandler(name);
				}}
				onKeyDown={(event) => {
					if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
						formContext.submit();
						event.preventDefault();
					}
				}}
				placeholder={placeholder}
				readOnly={readonly}
				ref={fieldRef}
				rows={rows}
				{...additionalAttributes}
			>
			</textarea>
		</div>
	);
});



export default TextArea;
