import {
	ExternalLinkDestination,
} from '~/model/externalLinks';

import {
	type IssueName,
	PageIssue,
	PlatformIssue,
} from '~/model/issuesNew';



const ISSUES_TO_LINKS = {
	[PageIssue.AnalyticsAnalyticsMissing]: [
		ExternalLinkDestination.SupportAnalytics,
	],
	[PageIssue.AnalyticsVisualAnalyticsMissing]: [
		ExternalLinkDestination.SupportAnalytics,
	],
	[PageIssue.CanonicalLinkIncorrectlyCanonicalized]: [
		ExternalLinkDestination.AcademyCanonicalFaqNoindex,
		ExternalLinkDestination.AcademyCanonicalLink,
	],
	[PageIssue.CanonicalLinkMissing]: [
		ExternalLinkDestination.AcademyCanonicalLink,
	],
	[PageIssue.CanonicalLinkPointsToUnindexable]: [
		ExternalLinkDestination.AcademyCanonicalLink,
	],
	[PageIssue.CanonicalLinkTooMany]: [
		ExternalLinkDestination.AcademyCanonicalLink,
	],
	[PlatformIssue.DomainHostnameNoncanonicalPresent]: [
		ExternalLinkDestination.AcademyRedirectsPreferredVersion,
	],
	[PlatformIssue.DomainHttpsAvailable]: [
		ExternalLinkDestination.AcademySeoRequirementsNewWebsiteHttps,
	],
	[PlatformIssue.DomainHttpsCertificateInvalid]: [
		ExternalLinkDestination.AcademySeoRequirementsNewWebsiteHttps,
	],
	[PlatformIssue.DomainHttpsNoncanonicalPresent]: [
		ExternalLinkDestination.AcademyRedirectsPreferredVersion,
	],
	[PlatformIssue.DomainSoft404sPresent]: [
		ExternalLinkDestination.AcademyDuplicateContent,
		ExternalLinkDestination.AcademyCrawlBudget,
	],
	[PageIssue.H1Duplicate]: [
		ExternalLinkDestination.AcademyHeadings,
	],
	[PageIssue.H1IncorrectLength]: [
		ExternalLinkDestination.AcademyHeadingsLength,
	],
	[PageIssue.H1LevelsSkipped]: [
		ExternalLinkDestination.AcademyHeadings,
	],
	[PageIssue.H1Missing]: [
		ExternalLinkDestination.AcademyHeadings,
	],
	[PageIssue.H1TooMany]: [
		ExternalLinkDestination.AcademyHeadingsH1Multiple,
	],
	[PageIssue.HreflangConflictingTargets]: [
		ExternalLinkDestination.AcademyHreflang,
	],
	[PageIssue.HreflangInvalidTarget]: [
		ExternalLinkDestination.AcademyHreflangAbsoluteUrl,
		ExternalLinkDestination.AcademyHreflang,
	],
	[PageIssue.HreflangInvalidValue]: [
		ExternalLinkDestination.AcademyHreflangLanguageAndRegion,
		ExternalLinkDestination.AcademyHreflang,
	],
	[PageIssue.HreflangMissingSelfReference]: [
		ExternalLinkDestination.AcademyHreflangSelfReference,
		ExternalLinkDestination.AcademyHreflang,
	],
	[PageIssue.HreflangMissingSpecificAudience]: [
		ExternalLinkDestination.AcademyHreflang,
	],
	[PageIssue.HreflangMissingXDefault]: [
		ExternalLinkDestination.AcademyHreflangXDefault,
		ExternalLinkDestination.AcademyHreflang,
	],
	[PageIssue.ImagesAltAttribute]: [
		ExternalLinkDestination.AcademyImageSeoAltAttribute,
		ExternalLinkDestination.AcademyImageSeo,
	],
	[PageIssue.ImagesMixedTransport]: [
		ExternalLinkDestination.AcademyImageSeo,
	],
	[PageIssue.LighthouseCls]: [
		ExternalLinkDestination.AcademyCoreWebVitalsCumulativeLayoutShift,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PageIssue.LighthouseFcp]: [
		ExternalLinkDestination.AcademyCoreWebVitalsFirstContentfulPaint,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PageIssue.LighthouseLcp]: [
		ExternalLinkDestination.AcademyCoreWebVitalsLargestContentfulPaint,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PageIssue.LighthousePerformance]: [
		ExternalLinkDestination.AcademyCoreWebVitalsPerformanceCalculation,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PageIssue.LighthouseSi]: [
		ExternalLinkDestination.AcademyCoreWebVitalsSpeedIndex,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PageIssue.LighthouseTbt]: [
		ExternalLinkDestination.AcademyCoreWebVitalsTotalBlockingTime,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PageIssue.LighthouseTti]: [
		ExternalLinkDestination.AcademyCoreWebVitalsTimeToInteractive,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PageIssue.LinksBroken]: [
		ExternalLinkDestination.AcademyCrawlBudgetBrokenLinks,
	],
	[PageIssue.LinksRedirected]: [
		ExternalLinkDestination.AcademyRedirects,
		ExternalLinkDestination.AcademyLinkAuthority,
	],
	[PageIssue.LinksToCanonicalized]: [
		ExternalLinkDestination.AcademyCanonicalLink,
	],
	[PageIssue.MetaDescriptionDuplicate]: [
		ExternalLinkDestination.AcademyMetaDescriptionUniqueness,
	],
	[PageIssue.MetaDescriptionIncorrectLength]: [
		ExternalLinkDestination.AcademyMetaDescriptionLength,
	],
	[PageIssue.MetaDescriptionMissing]: [
		ExternalLinkDestination.AcademyMetaDescription,
	],
	[PageIssue.MetaDescriptionTooMany]: [
		ExternalLinkDestination.AcademyMetaDescription,
	],
	[PageIssue.OpenGraphDescriptionIncorrectLength]: [
		ExternalLinkDestination.AcademyOpenGraphDescription,
	],
	[PageIssue.OpenGraphDescriptionMissing]: [
		ExternalLinkDestination.AcademyOpenGraphDescription,
		ExternalLinkDestination.AcademyOpenGraph,
	],
	[PageIssue.OpenGraphImageMissing]: [
		ExternalLinkDestination.AcademyOpenGraphImage,
		ExternalLinkDestination.AcademyOpenGraph,
	],
	[PageIssue.OpenGraphTitleIncorrectLength]: [
		ExternalLinkDestination.AcademyOpenGraphTitle,
	],
	[PageIssue.OpenGraphTitleMissing]: [
		ExternalLinkDestination.AcademyOpenGraphTitle,
		ExternalLinkDestination.AcademyOpenGraph,
	],
	[PageIssue.OpenGraphUrlMissing]: [
		ExternalLinkDestination.AcademyOpenGraphUrl,
		ExternalLinkDestination.AcademyOpenGraph,
	],
	[PlatformIssue.RobotsTxtAssetsDisallowed]: [
		ExternalLinkDestination.AcademyRobotsTxt,
	],
	[PlatformIssue.RobotsTxtCrawldelayPresent]: [
		ExternalLinkDestination.AcademyRobotsTxtCrawlDelay,
	],
	[PlatformIssue.RobotsTxtInvalidDirective]: [
		ExternalLinkDestination.AcademyRobotsTxt,
	],
	[PlatformIssue.RobotsTxtInvalidSyntax]: [
		ExternalLinkDestination.AcademyRobotsTxt,
	],
	[PlatformIssue.RobotsTxtInaccessible]: [
		ExternalLinkDestination.AcademyRobotsTxt,
		ExternalLinkDestination.AcademyCrawlBudget,
	],
	[PlatformIssue.RobotsTxtNoncanonicalPresent]: [
		ExternalLinkDestination.AcademyRobotsTxt,
	],
	[PlatformIssue.RobotsTxtXmlsitemapReferenceMissing]: [
		ExternalLinkDestination.AcademyRobotsTxtXmlSitemap,
		ExternalLinkDestination.AcademyXmlSitemap,
	],
	[PlatformIssue.RobotsTxtXmlsitemapReferenceRelative]: [
		ExternalLinkDestination.AcademyRobotsTxtXmlSitemap,
		ExternalLinkDestination.AcademyXmlSitemap,
	],
	[PageIssue.SchemaOrgErrors]: [
		ExternalLinkDestination.AcademySchema,
	],
	[PageIssue.SchemaOrgInvalidJson]: [
		ExternalLinkDestination.AcademySchema,
	],
	[PageIssue.TitleDuplicate]: [
		ExternalLinkDestination.AcademyTitleUniqueness,
	],
	[PageIssue.TitleIncorrectLength]: [
		ExternalLinkDestination.AcademyTitleLength,
	],
	[PageIssue.TitleMissing]: [
		ExternalLinkDestination.AcademyTitle,
	],
	[PageIssue.TitleTooMany]: [
		ExternalLinkDestination.AcademyTitle,
	],
	[PageIssue.TwitterCardsDescriptionIncorrectLength]: [
		ExternalLinkDestination.AcademyTwitterCardsDescription,
	],
	[PageIssue.TwitterCardsDescriptionMissing]: [
		ExternalLinkDestination.AcademyTwitterCardsDescription,
		ExternalLinkDestination.AcademyTwitterCards,
	],
	[PageIssue.TwitterCardsImageMissing]: [
		ExternalLinkDestination.AcademyTwitterCardsImage,
		ExternalLinkDestination.AcademyTwitterCards,
	],
	[PageIssue.TwitterCardsSiteMissing]: [
		ExternalLinkDestination.AcademyTwitterCardsSite,
		ExternalLinkDestination.AcademyTwitterCards,
	],
	[PageIssue.TwitterCardsTitleIncorrectLength]: [
		ExternalLinkDestination.AcademyTwitterCardsTitle,
	],
	[PageIssue.TwitterCardsTitleMissing]: [
		ExternalLinkDestination.AcademyTwitterCardsTitle,
		ExternalLinkDestination.AcademyTwitterCards,
	],
	[PageIssue.TwitterCardsTypeInvalid]: [
		ExternalLinkDestination.AcademyTwitterCardsType,
		ExternalLinkDestination.AcademyTwitterCards,
	],
	[PageIssue.TwitterCardsTypeMissing]: [
		ExternalLinkDestination.AcademyTwitterCardsType,
		ExternalLinkDestination.AcademyTwitterCards,
	],
	[PlatformIssue.WebVitalsOriginSummaryCoreWebVitals]: [
		ExternalLinkDestination.AcademyCoreWebVitalsWhyCare,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PlatformIssue.WebVitalsOriginSummaryCumulativeLayoutShift]: [
		ExternalLinkDestination.AcademyCoreWebVitalsCumulativeLayoutShift,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PlatformIssue.WebVitalsOriginSummaryFirstContentfulPaint]: [
		ExternalLinkDestination.AcademyCoreWebVitalsFirstContentfulPaint,
		ExternalLinkDestination.AcademyCoreWebVitalsWhatAreThey,
	],
	[PlatformIssue.WebVitalsOriginSummaryFirstInputDelay]: [
		ExternalLinkDestination.AcademyCoreWebVitalsFirstInputDelay,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PlatformIssue.WebVitalsOriginSummaryLargestContentfulPaint]: [
		ExternalLinkDestination.AcademyCoreWebVitalsLargestContentfulPaint,
		ExternalLinkDestination.AcademyCoreWebVitals,
	],
	[PlatformIssue.XmlSitemapFilesizeLimit]: [
		ExternalLinkDestination.AcademyXmlSitemap,
	],
	[PlatformIssue.XmlSitemapInaccessible]: [
		ExternalLinkDestination.AcademyXmlSitemap,
	],
	[PageIssue.XmlSitemapIncorrectlyMissing]: [
		ExternalLinkDestination.AcademyXmlSitemap,
	],
	[PageIssue.XmlSitemapIncorrectlyPresent]: [
		ExternalLinkDestination.AcademyXmlSitemap,
		ExternalLinkDestination.AcademyCrawlBudgetXmlSitemaps,
	],
	[PlatformIssue.XmlSitemapInvalidContents]: [
		ExternalLinkDestination.AcademyXmlSitemap,
	],
	[PlatformIssue.XmlSitemapInvalidStructure]: [
		ExternalLinkDestination.AcademyXmlSitemap,
		ExternalLinkDestination.EexternaLSitemapsOrgProtocol,
	],
	[PlatformIssue.XmlSitemapInvalidStructureAll]: [
		ExternalLinkDestination.AcademyXmlSitemap,
		ExternalLinkDestination.EexternaLSitemapsOrgProtocol,
	],
	[PlatformIssue.XmlSitemapMissing]: [
		ExternalLinkDestination.AcademyXmlSitemap,
	],
	[PlatformIssue.XmlSitemapOrphaned]: [
		ExternalLinkDestination.AcademyXmlSitemap,
	],
	[PlatformIssue.XmlSitemapUrlcountLimit]: [
		ExternalLinkDestination.AcademyXmlSitemap,
	],
};



export function getLearnLinksRelatedToIssue(
	issueName: IssueName,
): Array<ExternalLinkDestination> {
	return ISSUES_TO_LINKS[issueName] ?? [];
}
