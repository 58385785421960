import React from 'react';

import ColorList from '~/components/atoms/forms/components/ColorList';

import {
	COLORS,
} from '~/model/segments';



type Props = {
	name: string,
};

const SegmentColorField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	return (
		<ColorList
			colors={COLORS}
			name={name}
		/>
	);
};



export default SegmentColorField;
