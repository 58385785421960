import classNames from 'classnames';
import React from 'react';

import Spinner from '~/components/patterns/loaders/Spinner';

import styles from './TimelineLoader.module.scss';



type Props = {
	/** Loading state message */
	message: React.ReactNode,
	/** Flag that this entry is first from all entries */
	isFirstChild?: boolean,
	/** Flag that this entry is last from all entries */
	isLastChild?: boolean,
};

const TimelineLoader: React.FC<Props> = (props) => {
	const {
		message,
		isFirstChild,
		isLastChild,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isFirst]: isFirstChild,
		[styles.isLast]: isLastChild,
	});

	return (
		<section className={componentClasses}>
			<div className={styles.spinner}>
				<Spinner />
			</div>
			<div className={styles.message}>
				{message}
			</div>
		</section>
	);
};



export default TimelineLoader;
