import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import AffectedPagesPerCategoryChart from '../charts/AffectedPagesPerCategoryChart';
import FlexibleBlocks from '~/components/patterns/structures/FlexibleBlocks';
import HeaderLayout from './components/HeaderLayout';
import HeaderScoreInfo from '../../atoms/screenLayouts/components/header/HeaderScoreInfo';
import HealthPerSegmentChart from '../charts/HealthPerSegmentChart';
import IssuesOverviewFilter from '~/components/app/IssuesOverviewFilter';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenBodyLayout from '~/components/patterns/screens/parts/body/ScreenBodyLayout';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';

import {
	useIssuesScreenDesktopHeaderQuery,
} from './DesktopLayout.gql';

import useFilteredWebsiteScopeIssues from '~/hooks/useFilteredWebsiteScopeIssues';
import useGetIssueCategory from '~/hooks/useGetIssueCategory';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';

import {
	issuesOverviewCategorySelector,
} from '~/state/ui/content/selectors';

import {
	animateHeartbeatSelector,
	hoveredCategorySelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	score: {
		id: 'ui.issuesOverview.score',
	},
});



type Props = {
	innerPanel?: () => React.ReactElement,
	issueCategoriesList: React.ReactNode,
	websiteId: CK.WebsiteId | null,
};

const DesktopLayout: React.FC<Props> = (props) => {
	const {
		innerPanel,
		issueCategoriesList,
		websiteId,
	} = props;

	const animateHeartbeat = useSelector(animateHeartbeatSelector);
	const hoveredIssueCategoryName = useSelector(hoveredCategorySelector);
	const issueCategories = useFilteredWebsiteScopeIssues(websiteId);
	const openedIssueCategoryName = useSelector(issuesOverviewCategorySelector);
	const websiteIsCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId);

	const {
		data,
	} = useIssuesScreenDesktopHeaderQuery({
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	const hoveredIssueCategory = useGetIssueCategory(
		issueCategories,
		hoveredIssueCategoryName,
	);

	const openedIssueCategory = useGetIssueCategory(
		issueCategories,
		openedIssueCategoryName,
	);

	let scoreImpact = 0;

	const websiteHealth = data?.website?.health ?? null;

	if (websiteHealth !== null) {
		if (openedIssueCategory !== null) {
			scoreImpact = openedIssueCategory.pointsToGain + websiteHealth;
		}

		if (hoveredIssueCategory !== null) {
			scoreImpact = hoveredIssueCategory.pointsToGain + websiteHealth;
		}
	}

	return (
		<ScreenLayout
			header={(
				<HeaderLayout
					footer={(
						<IssuesOverviewFilter
							inline={true}
						/>
					)}
					score={websiteIsCrawlingPaused !== null && websiteHealth !== null && (
						<HeaderScoreInfo
							animateHeartbeat={animateHeartbeat}
							impact={scoreImpact}
							key={websiteId}
							label={(
								<FormattedMessage {...messages.score} />
							)}
							maximum={1000}
							paused={websiteIsCrawlingPaused}
							value={websiteHealth}
						/>
					)}
				/>
			)}
		>
			<ScreenBodyLayout
				contentOverlay={innerPanel ? innerPanel() : null}
				isContentLoading={issueCategories === null}
				sidebar={issueCategoriesList}
			>
				<ScreenBody>
					<FlexibleBlocks
						blockMinWidth={500}
						contentMaxWidth={740}
					>
						<HealthPerSegmentChart
							websiteId={websiteId}
						/>

						<AffectedPagesPerCategoryChart
							websiteId={websiteId}
						/>
					</FlexibleBlocks>
				</ScreenBody>
			</ScreenBodyLayout>
		</ScreenLayout>
	);
};



export default DesktopLayout;
