import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CountSlider from '~/components/patterns/forms/fields/CountSlider';
import CountSliderFieldLayout from '~/components/patterns/forms/fieldParts/countSliders/CountSliderFieldLayout';
import CountSliderInput, {
	CountSliderInputSize,
} from '~/components/atoms/forms/components/countSliders/abstractBuilders/CountSliderInput';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import PageCapacitySliderContext from '~/components/app/PageCapacitySliderContext';

import useAccountCanSignup from '~/hooks/useAccountCanSignup';
import useAccountWebsiteRestrictions from '~/hooks/useAccountWebsiteRestrictions';
import useFormContext from '~/hooks/useFormContext';



const messages = defineMessages({
	link: {
		id: 'ui.websiteSettings.plan.trialLimit.link',
	},
	pageBundleMessage: {
		id: 'ui.websiteSettings.plan.trialLimit.pageBundle.message',
	},
	pageCapacityMessage: {
		id: 'ui.websiteSettings.plan.trialLimit.pageCapacity.message',
	},
});



const DEFAULT_COMPONENT_WIDTH = 308;



type Props = {
	accountId: CK.AccountId | null,
	countSliderSize?: CountSliderInputSize,
	isDisabled?: boolean,
	name: string,
	numberOfPages?: number,
	showHighlightedValue?: boolean,
	sliderInfoWidth?: number,
	width?: number,
};

const PageCapacityField: React.FC<Props> = (props) => {
	const {
		accountId,
		countSliderSize = CountSliderInputSize.Default,
		isDisabled = false,
		name,
		numberOfPages,
		showHighlightedValue = true,
		sliderInfoWidth,
		width = null,
	} = props;

	const {
		defaultValues,
		focused,
		values,
	} = useFormContext();

	const accountCanSignup = useAccountCanSignup(accountId);
	const accountWebsiteRestrictions = useAccountWebsiteRestrictions(accountId);

	if (
		accountCanSignup === null
		|| accountId === null
		|| accountWebsiteRestrictions === null
	) {
		return null;
	}

	return (
		<PageCapacitySliderContext
			accountId={accountId}
			applyLimit={true}
			defaultValue={defaultValues[name]}
			name={name}
			numberOfPages={numberOfPages}
			value={values[name] || defaultValues[name]}
		>
			{({
				currentStep,
				currentValue,
				isMaxLimitReached,
				maxValue,
				minStep,
				minValue,
				onSliderStepChangeHandler,
				onSliderValueChangeHandler,
				sliderMax,
			}) => {
				const countSliderOptions: Record<string, any> = {};

				if (accountCanSignup && isMaxLimitReached && focused === name) {
					const message = accountWebsiteRestrictions.maximumPageCapacity > currentValue
						? messages.pageBundleMessage
						: messages.pageCapacityMessage;

					countSliderOptions.handlerBubble = (
						<FormattedMessage
							{...message}
							values={{
								count__pageBundleSelected: currentValue,
								linkToSignUp: (
									<InternalLink
										routeName="account.pricing"
										routeParams={{
											accountId,
										}}
										style={InternalLinkStyle.Light}
									>
										<FormattedMessage {...messages.link} />
									</InternalLink>
								),
							}}
						/>
					);

					countSliderOptions.handlerBubbleAlwaysVisible = true;
				}

				if (minStep && showHighlightedValue) {
					countSliderOptions.highlightedValue = minStep;
				}

				return (
					<CountSliderFieldLayout
						slider={(
							<CountSlider
								disabled={isDisabled}
								max={sliderMax}
								onChangeCallback={onSliderStepChangeHandler}
								value={currentStep}
								{...countSliderOptions}
							/>
						)}
						sliderInfo={(
							<CountSliderInput
								isDisabled={isDisabled}
								max={maxValue}
								min={minValue}
								name={name}
								onSliderValueChangeHandler={onSliderValueChangeHandler}
								size={countSliderSize}
								value={currentValue}
							/>
						)}
						sliderInfoWidth={sliderInfoWidth}
						width={width ?? DEFAULT_COMPONENT_WIDTH}
					/>
				);
			}}
		</PageCapacitySliderContext>
	);
};



export default PageCapacityField;

export {
	CountSliderInputSize as PageCapacityFieldSize,
};
