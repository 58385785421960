/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCustomUrlsMutationVariables = GraphQL.Exact<{
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DeleteCustomUrlsMutation = { readonly __typename?: 'Mutation', readonly DeleteCustomUrls: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type DeleteUrlsByFilterMutationVariables = GraphQL.Exact<{
  cleanupRobotsTxtRevisions: GraphQL.Scalars['Boolean']['input'];
  filter: GraphQL.Scalars['Array']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DeleteUrlsByFilterMutation = { readonly __typename?: 'Mutation', readonly DeleteUrlsByFilter: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const DeleteCustomUrlsDocument = gql`
    mutation DeleteCustomUrls($urls: [String!]!, $websiteId: WebsiteId!) {
  DeleteCustomUrls(urls: $urls, websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type DeleteCustomUrlsMutationFn = Apollo.MutationFunction<DeleteCustomUrlsMutation, DeleteCustomUrlsMutationVariables>;

/**
 * __useDeleteCustomUrlsMutation__
 *
 * To run a mutation, you first call `useDeleteCustomUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomUrlsMutation, { data, loading, error }] = useDeleteCustomUrlsMutation({
 *   variables: {
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDeleteCustomUrlsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomUrlsMutation, DeleteCustomUrlsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomUrlsMutation, DeleteCustomUrlsMutationVariables>(DeleteCustomUrlsDocument, options);
      }
export type DeleteCustomUrlsMutationHookResult = ReturnType<typeof useDeleteCustomUrlsMutation>;
export type DeleteCustomUrlsMutationResult = Apollo.MutationResult<DeleteCustomUrlsMutation>;
export type DeleteCustomUrlsMutationOptions = Apollo.BaseMutationOptions<DeleteCustomUrlsMutation, DeleteCustomUrlsMutationVariables>;
export const DeleteUrlsByFilterDocument = gql`
    mutation DeleteUrlsByFilter($cleanupRobotsTxtRevisions: Boolean!, $filter: Array!, $websiteId: WebsiteId!) {
  DeleteUrlsByFilter(
    cleanupRobotsTxtRevisions: $cleanupRobotsTxtRevisions
    filter: $filter
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type DeleteUrlsByFilterMutationFn = Apollo.MutationFunction<DeleteUrlsByFilterMutation, DeleteUrlsByFilterMutationVariables>;

/**
 * __useDeleteUrlsByFilterMutation__
 *
 * To run a mutation, you first call `useDeleteUrlsByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUrlsByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUrlsByFilterMutation, { data, loading, error }] = useDeleteUrlsByFilterMutation({
 *   variables: {
 *      cleanupRobotsTxtRevisions: // value for 'cleanupRobotsTxtRevisions'
 *      filter: // value for 'filter'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDeleteUrlsByFilterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUrlsByFilterMutation, DeleteUrlsByFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUrlsByFilterMutation, DeleteUrlsByFilterMutationVariables>(DeleteUrlsByFilterDocument, options);
      }
export type DeleteUrlsByFilterMutationHookResult = ReturnType<typeof useDeleteUrlsByFilterMutation>;
export type DeleteUrlsByFilterMutationResult = Apollo.MutationResult<DeleteUrlsByFilterMutation>;
export type DeleteUrlsByFilterMutationOptions = Apollo.BaseMutationOptions<DeleteUrlsByFilterMutation, DeleteUrlsByFilterMutationVariables>;