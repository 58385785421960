import React from 'react';
import classNames from 'classnames';



type Props = React.PropsWithChildren<{
	/** Possibility to highlight this option (like selected state) */
	isHighlighted?: boolean,
	/** Different look for special type of option */
	isSpecial?: boolean,
}>;



const FieldDropdownOption: React.FC<Props> = (props) => {
	const {
		children,
		isHighlighted,
		isSpecial,
	} = props;

	const componentClasses = classNames({
		'field-dropdown-option': true,
		'field-dropdown-option--is-highlighted': isHighlighted,
		'field-dropdown-option--is-special': isSpecial,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default FieldDropdownOption;
