import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import GuidedTour from '~/components/patterns/tours/GuidedTour';
import RadioList from '~/components/atoms/forms/components/RadioList';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useChooseCustomerTypeMutation,
} from './ChooseCustomerTypeModal.gql';

import useAccountId from '~/hooks/useAccountId';
import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	nextButton: {
		id: 'ui.onboarding.step1.nextButton',
	},
	prompt: {
		id: 'ui.chooseCustomerType.prompt',
	},
});

const customerTypeMessages = defineMessages({
	[GraphQL.CustomerType.Agency]: {
		id: 'ui.customerTypes.agency',
	},
	[GraphQL.CustomerType.Ecommerce]: {
		id: 'ui.customerTypes.ecommerce',
	},
	[GraphQL.CustomerType.Marketplace]: {
		id: 'ui.customerTypes.marketplace',
	},
	[GraphQL.CustomerType.Other]: {
		id: 'ui.customerTypes.other',
	},
	[GraphQL.CustomerType.Publisher]: {
		id: 'ui.customerTypes.publisher',
	},
	[GraphQL.CustomerType.Travel]: {
		id: 'ui.customerTypes.travel',
	},
});

const validations = {
	customerType: [
		{
			field: 'customerType',
			message: '',
			rule: ({ values, name }) => {
				return values[name] !== undefined;
			},
		},
	],
};



type Props = {
	onSubmitCallback: () => void,
};

const ChooseCustomerTypeModal: React.FC<Props> = (props) => {
	const {
		onSubmitCallback,
	} = props;

	const accountId = useAccountId();
	const viewportType = useViewportType();

	const [chooseCustomerType] = useChooseCustomerTypeMutation();

	const handleSubmit = React.useCallback(
		async ({ customerType }) => {
			if (accountId === null) {
				return;
			}

			await chooseCustomerType({
				variables: {
					accountId,
					customerType,
				},
			});

			onSubmitCallback();
		},
		[
			accountId,
			chooseCustomerType,
			onSubmitCallback,
		],
	);

	const options = React.useMemo(
		() => [
			GraphQL.CustomerType.Agency,
			GraphQL.CustomerType.Ecommerce,
			GraphQL.CustomerType.Marketplace,
			GraphQL.CustomerType.Publisher,
			GraphQL.CustomerType.Travel,
			GraphQL.CustomerType.Other,
		].map((customerType) => {
			return {
				label: (
					<FormattedMessage {...customerTypeMessages[customerType]} />
				),
				value: customerType,
			};
		}),
		[],
	);

	return (
		<GuidedTour
			form={(
				<Form
					onSuccess={handleSubmit}
					validations={validations}
				>
					<FormRow fullwidth={viewportType.isSmall}>
						<RadioList
							items={options}
							name="customerType"
							width={false}
						/>
					</FormRow>

					<ButtonsLayout layout={ButtonsLayoutType.Steps}>
						<SubmitButton nextStepIcon={true}>
							<FormattedMessage {...messages.nextButton} />
						</SubmitButton>
					</ButtonsLayout>
				</Form>
			)}
		>
			<FormattedMessage {...messages.prompt} />
		</GuidedTour>
	);
};



export default ChooseCustomerTypeModal;
