import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import useGetIssueCategory from '~/hooks/useGetIssueCategory';
import usePlatformIssueCategories from '~/hooks/usePlatformIssueCategories';

import {
	platformScreenIssuesCategoryTypeSelector,
} from '~/state/ui/content/selectors';



function useOpenedPlatformIssueCategory(websiteId: CK.WebsiteId) {
	const openedIssueCategoryName = useSelector(platformScreenIssuesCategoryTypeSelector);

	const platformIssueCategories = usePlatformIssueCategories(websiteId);

	return useGetIssueCategory(
		platformIssueCategories,
		openedIssueCategoryName,
	);
}



export default useOpenedPlatformIssueCategory;
