import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';



type Props = {
	disabled?: boolean,
	/** Type of basic icon */
	iconType: BasicIconType,
	/** By setting routeName we can change this component to Link instance */
	linkRouteName?: string,
	linkRouteParams?: Record<string, any>,
	/** Callback called when clicking on whole item */
	onClick?: () => void,
	tooltip?: React.ReactNode,
};



const TableActionButton: React.FC<Props> = (props) => {
	const {
		disabled,
		iconType,
		linkRouteName,
		linkRouteParams,
		onClick,
		tooltip,
	} = props;

	return (
		<Button
			disabled={disabled}
			icon={(
				<BasicIcon type={iconType} />
			)}
			linkRouteName={linkRouteName}
			linkRouteParams={linkRouteParams}
			muted={true}
			onClick={onClick}
			size={ButtonSize.Small}
			style={ButtonStyle.Hollow}
			tooltip={tooltip}
		/>
	);
};



export default TableActionButton;

export {
	BasicIconType as TableActionButtonIconType,
};
