import React from 'react';

import BasicIcon, {
	BasicIconType as ModalHeaderIconType,
} from '~/components/patterns/icons/BasicIcon';
import HeaderTitleWithValue, {
	SIZE_MODAL as TITLE_SIZE_MODAL,
	STYLE_CRITICAL as TITLE_STYLE_CRITICAL,
	STYLE_PRIMARY as TITLE_STYLE_PRIMARY,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';



export enum ModalHeaderTitleStyle {
	Default = 'default',
	Critical = 'critical',
}

type Props = {
	/** Additional info next to title */
	additionalInfo?: React.ReactNode,
	/** Just optional icon */
	iconType?: ModalHeaderIconType,
	/** Main title in header */
	title: React.ReactNode,
	/** Possibility to change default style of title */
	titleStyle?: ModalHeaderTitleStyle,
	/** Dynamic part of title highlighted by different color */
	titleValue?: React.ReactNode,
};



const ModalHeader: React.FC<Props> = (props) => {
	const {
		additionalInfo,
		iconType,
		title,
		titleStyle = ModalHeaderTitleStyle.Default,
		titleValue,
	} = props;

	return (
		<div className="modal-header">
			<div className="modal-header__title-container">
				{iconType && (
					<div className="modal-header__icon">
						<BasicIcon
							size={30}
							type={iconType}
						/>
					</div>
				)}
				<div className="modal-header__title">
					{titleValue ? (
						<HeaderTitleWithValue
							size={TITLE_SIZE_MODAL}
							style={titleStyle === ModalHeaderTitleStyle.Critical ? TITLE_STYLE_CRITICAL : TITLE_STYLE_PRIMARY}
							title={title}
							value={titleValue}
						/>
					) : title}
				</div>
			</div>

			{additionalInfo && (
				<div className="modal-header__additional-info">
					{additionalInfo}
				</div>
			)}
		</div>
	);
};

export { ModalHeaderIconType };

export default ModalHeader;
