import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AuthButtonsLayout from '~/components/atoms/auth/AuthButtonsLayout';
import AuthFormLayout, {
	type AuthFormLayoutRef,
} from '~/components/atoms/auth/AuthFormLayout';
import AuthFormRow from '~/components/atoms/auth/AuthFormRow';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import CarouselBlocks from '~/components/patterns/structures/CarouselBlocks';
import FieldStatus, {
	FieldStatusErrorMessageSize,
} from '~/components/patterns/forms/basis/FieldStatus';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import FormErrorMessages, {
	FormErrorMessagesStyle,
} from '~/components/app/FormErrorMessages';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LoginScreenEmailSentMessage from '~/components/app/LoginScreenEmailSentMessage';
import SubmitButton, {
	SubmitButtonSize,
} from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	type TextFieldRef,
	TextFieldSize,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';
import WaitForRetry from '~/components/app/WaitForRetry';

import {
	useInitiateLoginWithEmailMutation,
	useLoginWithCredentialsMutation,
} from '~/components/app/LoginWithCredentialsForm.gql';

import {
	validateField,
} from '~/components/app/validations';

import useHandleSuccessfulLogin from '~/hooks/useHandleSuccessfulLogin';

import {
	redirectToConductorLogin,
} from '~/model/conductor';

import {
	LoginScreenView,
} from '~/model/loginScreen';

import FormError from '~/utilities/FormError';
import touchSupported from '~/utilities/touchSupported';



const messages = defineMessages({
	backToEmail: {
		id: 'ui.loginWithCredentialsForm.backToEmail',
	},
	continue: {
		id: 'ui.general.continue',
	},
	email: {
		id: 'ui.loginWithCredentialsForm.email',
	},
	forgottenPassword: {
		id: 'ui.loginWithCredentialsForm.forgottenPassword',
	},
	login: {
		id: 'ui.loginWithCredentialsForm.login',
	},
	magicLinkSent: {
		id: 'ui.loginWithCredentialsForm.magicLinkSent',
	},
	password: {
		id: 'ui.loginWithCredentialsForm.password',
	},
	title: {
		id: 'ui.loginWithCredentialsForm.title',
	},
	userNotFoundError: {
		id: 'ui.loginWithCredentialsForm.errors.userNotFound',
	},
	wrongPasswordError: {
		id: 'ui.loginWithCredentialsForm.errors.wrongPassword',
	},
});



const validateEmail = validateField(
	'email',
	(f) => ([
		f.validateNonEmpty(),
		f.validateEmail(),
		f.customGlobal({
			field: 'email',
			globalRule: 'user_not_found',
			message: (
				<FormattedMessage {...messages.userNotFoundError} />
			),
		}),
		f.customGlobal({
			field: 'email',
			globalRule: 'unknownEmail',
			message: (
				<FormattedMessage {...messages.userNotFoundError} />
			),
		}),
	]),
);

const validatePassword = validateField(
	'password',
	(f) => ([
		f.validateNonEmpty(),
		f.customGlobal({
			field: 'password',
			globalRule: 'wrongPassword',
			message: (
				<FormattedMessage {...messages.wrongPasswordError} />
			),
		}),
	]),
);



enum FormStep {
	Email = 0,
	Password = 1,
	MagicLink = 3,
}



type Props = {
	defaultEmail?: string,
	isDisabled?: boolean,
	onEmailChange?: (email: string) => void,
	setView: (view: LoginScreenView) => void,
};

const LoginWithCredentialsForm: React.FC<Props> = (props) => {
	const {
		defaultEmail,
		isDisabled = false,
		onEmailChange,
		setView,
	} = props;

	const handleSuccessfulLogin = useHandleSuccessfulLogin();

	const emailRef = React.useRef<TextFieldRef | null>(null);
	const formRef = React.useRef<FormRef>(null);
	const layoutRef = React.useRef<AuthFormLayoutRef | null>(null);
	const passwordRef = React.useRef<TextFieldRef | null>(null);

	const [activeStep, setActiveStep] = React.useState(FormStep.Email);

	const [initiateLoginWithEmailMutation] = useInitiateLoginWithEmailMutation();
	const [loginWithCredentialsMutation] = useLoginWithCredentialsMutation();

	const goToStep = React.useCallback(
		(nextStep: FormStep) => {
			setActiveStep(nextStep);

			if (nextStep === FormStep.Email) {
				emailRef.current?.focus();
			} else if (nextStep === FormStep.Password) {
				passwordRef.current?.focus();
			}

			setTimeout(() => {
				formRef.current?.resetGlobalError([
					'user_not_found',
					'unknownEmail',
					'wrongPassword',
				]);
			});
		},
		[],
	);

	const resetForm = React.useCallback(
		() => {
			formRef.current?.setValues({
				password: '',
				email: '',
			});

			goToStep(FormStep.Email);
		},
		[
			goToStep,
		],
	);

	const handleChange = React.useCallback(
		(field, value) => {
			if (!onEmailChange) {
				return;
			}

			if (field === 'email') {
				onEmailChange(value);
			}
		},
		[
			onEmailChange,
		],
	);

	const handleFocus = React.useMemo(
		() => {
			return (field) => {
				if (field === 'email') {
					setActiveStep(FormStep.Email);
				} else if (field === 'password') {
					setActiveStep(FormStep.Password);
				}
			};
		},
		[],
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			const {
				email,
				password,
			} = values;

			try {
				if (email !== '' && password === '') {
					const { data } = await initiateLoginWithEmailMutation({
						variables: {
							email,
						},
					});

					if (data?.InitiateLoginWithEmail.hasPassword) {
						goToStep(FormStep.Password);
					} else {
						goToStep(FormStep.MagicLink);
					}
				} else if (email !== '' && password !== '') {
					await loginWithCredentialsMutation({
						variables: {
							email,
							password,
						},
					});

					await handleSuccessfulLogin();
				}
			} catch (error) {
				const formError = FormError.fromApolloError(error, {
					unknownEmail: email,
					user_not_found: email,
					wrongPassword: password,
				});

				if (formError?.name === 'conductorLoginRequired') {
					redirectToConductorLogin();

					return;
				}

				if (formError?.name === 'twoFactorAuthenticationRequired') {
					setView(LoginScreenView.LoginTwoFactorAuthentication);

					return;
				}

				if (formError?.name === 'too_many_attempts') {
					formError.details.nextPossibleRetryAt = formError.details.too_many_failed_attempts.next_retry_possible_at;
				}

				if (
					formError?.name === 'user_not_found'
					|| formError?.name === 'unknownEmail'
				) {
					goToStep(FormStep.Email);
				} else if (formError?.name === 'wrongPassword') {
					goToStep(FormStep.Password);
				}

				layoutRef.current?.shake();

				throw formError;
			}
		},
		[
			handleSuccessfulLogin,
			initiateLoginWithEmailMutation,
			loginWithCredentialsMutation,
			goToStep,
			setView,
		],
	);

	const validations = React.useMemo(
		() => {
			return {
				validateEmail: activeStep === FormStep.Email ? validateEmail : [],
				validatePassword: activeStep === FormStep.Password ? validatePassword : [],
			};
		},
		[
			activeStep,
		],
	);

	if (activeStep === FormStep.MagicLink) {
		return (
			<LoginScreenEmailSentMessage
				message={(
					<FormattedMessage {...messages.magicLinkSent} />
				)}
				onClick={resetForm}
			/>
		);
	}

	return (
		<AuthFormLayout
			ref={layoutRef}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Form
				defaultFocus={touchSupported ? null : 'email'}
				defaultValues={{
					email: defaultEmail ?? '',
					password: '',
				}}
				isDisabled={isDisabled}
				onChangeCallback={handleChange}
				onFocusCallback={handleFocus}
				onSuccess={handleSubmit}
				ref={formRef}
				validations={validations}
			>
				<FormErrorMessages
					errors={{
						too_many_attempts: ({ globalError }) => (
							<WaitForRetry
								until={globalError.details.nextPossibleRetryAt}
							/>
						),
						tooManyFailedAttempts: ({ globalError }) => (
							<WaitForRetry
								until={globalError.details.nextRetryPossibleAt}
							/>
						),
					}}
					style={FormErrorMessagesStyle.AuthForm}
				/>

				<CarouselBlocks
					activeSlideIndex={activeStep}
					adaptiveHeight={false}
					draggable={false}
					selectableText={true}
					slideGaps={20}
					swipe={false}
				>
					<>
						<AuthFormRow
							id="email"
							label={(
								<FormattedMessage {...messages.email} />
							)}
							sublabel={(
								<InternalLink
									onClickCallback={() => setView(LoginScreenView.PasswordLost)}
									style={InternalLinkStyle.Auth}
									tabIndex={activeStep === FormStep.Email ? 7 : -1}
								>
									<FormattedMessage {...messages.forgottenPassword} />
								</InternalLink>
							)}
						>
							<FieldStatus
								errorMessageSize={FieldStatusErrorMessageSize.Medium}
								name="validateEmail"
								showIcon={false}
							>
								<TextField
									attributes={{
										tabIndex: activeStep === FormStep.Email ? 1 : -1,
									}}
									autoComplete={TextFieldAutocomplete.Email}
									name="email"
									ref={emailRef}
									resetButton={false}
									size={TextFieldSize.Large}
									type={TextFieldType.Email}
									width="100%"
								/>
							</FieldStatus>
						</AuthFormRow>

						<AuthButtonsLayout
							leftButton={(

								<SubmitButton
									nextStepIcon={true}
									size={ButtonSize.XLarge}
									tabIndex={activeStep === FormStep.Email ? 2 : -1}
									testId="continue"
								>
									<FormattedMessage {...messages.continue} />
								</SubmitButton>

							)}
						/>
					</>

					<>
						<AuthFormRow
							id="password"
							label={(
								<FormattedMessage {...messages.password} />
							)}
							sublabel={(
								<InternalLink
									onClickCallback={() => setView(LoginScreenView.PasswordLost)}
									style={InternalLinkStyle.Auth}
									tabIndex={activeStep === FormStep.Password ? 7 : -1}
								>
									<FormattedMessage {...messages.forgottenPassword} />
								</InternalLink>
							)}
						>
							<FieldStatus
								errorMessageSize={FieldStatusErrorMessageSize.Medium}
								name="validatePassword"
								showIcon={false}
							>
								<TextField
									attributes={{
										tabIndex: activeStep === FormStep.Password ? 3 : -1,
									}}
									autoComplete={TextFieldAutocomplete.CurrentPassword}
									name="password"
									ref={passwordRef}
									resetButton={false}
									size={TextFieldSize.Large}
									type={TextFieldType.Password}
									width="100%"
								/>
							</FieldStatus>
						</AuthFormRow>

						<AuthButtonsLayout
							leftButton={(
								<SubmitButton
									size={SubmitButtonSize.XLarge}
									tabIndex={activeStep === FormStep.Password ? 4 : -1}
									testId="log-me-in"
								>
									<FormattedMessage {...messages.login} />
								</SubmitButton>
							)}
							rightButton={(
								<Button
									onClick={() => setActiveStep(FormStep.Email)}
									size={ButtonSize.Medium}
									style={ButtonStyle.Link}
									tabIndex={activeStep === FormStep.Password ? 5 : -1}
								>
									<FormattedMessage {...messages.backToEmail} />
								</Button>
							)}
						/>
					</>
				</CarouselBlocks>
			</Form>
		</AuthFormLayout>
	);
};



export default LoginWithCredentialsForm;
