import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';
import {
	Link,
} from 'react-router5';

import ConductorLink from '~/components/patterns/links/ConductorLink';
import Dropdown, {
	DropdownAttachment,
	DropdownStyle,
} from '~/components/patterns/navigations/Dropdown';
import IntercomActivator from '~/components/logic/IntercomActivator';
import LogoutMenuItem from '~/components/app/LogoutMenuItem';
import NavigationIcon, {
	NavigationIconType,
} from '~/components/patterns/icons/NavigationIcon';
import MenuItemLayout from '~/components/atoms/navigations/builders/MenuItemLayout';
import StatusDot, {
	StatusDotStatus,
} from '~/components/patterns/statuses/StatusDot';
import UserNav from '~/components/patterns/navigations/UserNav';

import useAccountProblems from '~/hooks/useAccountProblems';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useOpenSwitchAccountModal from '~/hooks/useOpenSwitchAccountModal';
import useViewportOrientation from '~/hooks/useViewportOrientation';
import useViewportType from '~/hooks/useViewportType';

import {
	IsConductorClient,
} from '~/config';

import {
	isUserNavigationOpenSelector,
} from '~/state/ui/menu/selectors';



const messages = defineMessages({
	account: {
		id: 'ui.menu.account',
	},
	conductor: {
		id: 'ui.menu.conductor',
	},
	profile: {
		id: 'ui.menu.profile',
	},
	support: {
		id: 'ui.menu.support',
	},
	team: {
		id: 'ui.menu.team',
	},
});



const UserNavigationDropdown: React.FC = () => {
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const homeAccountProblems = useAccountProblems(effectiveHomeAccountId);
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const openSwitchAccountModal = useOpenSwitchAccountModal(true);
	const viewportOrientation = useViewportOrientation();
	const viewportType = useViewportType();

	const open = useSelector(isUserNavigationOpenSelector);

	const hasDropdownLook = viewportType.isSmall || (viewportType.isMedium && viewportOrientation.isPortrait);

	let statusIcon: React.ReactElement | null = null;

	// we don't display paused websites here #7367
	if (homeAccountProblems.isSubscriptionSuspended) {
		statusIcon = (
			<StatusDot status={StatusDotStatus.Critical} />
		);
	} else if (homeAccountProblems.hasPaymentProblem) {
		statusIcon = (
			<StatusDot status={StatusDotStatus.Warning} />
		);
	}

	const items: Array<{
		content: React.ReactNode,
		name: string,
	}> = [];

	if (kingdomAdminFeatures.areVisible) {
		items.push({
			content: (
				<Link routeName="generalAdmin">
					<MenuItemLayout
						icon={(
							<NavigationIcon type={NavigationIconType.Lock} />
						)}
						isLabelHidden={!hasDropdownLook}
						label="General Admin"
					/>
				</Link>
			),
			name: 'generalAdmin',
		});

		items.push({
			content: (
				<MenuItemLayout
					icon={(
						<NavigationIcon type={NavigationIconType.SwitchAccount} />
					)}
					isLabelHidden={!hasDropdownLook}
					label="Switch Account"
					onClickCallback={openSwitchAccountModal}
				/>
			),
			name: 'switchAccount',
		});
	}

	if (kingdomAdminFeatures.areAlwaysVisible) {
		items.push({
			content: (
				<MenuItemLayout
					icon={(
						<NavigationIcon type={NavigationIconType.ToggleAdmin} />
					)}
					isLabelHidden={!hasDropdownLook}
					label="Toggle Admin"
					onClickCallback={kingdomAdminFeatures.toggle}
				/>
			),
			name: 'toggleAdmin',
		});
	}

	if (viewportType.isSmall) {
		items.push({
			content: (
				<IntercomActivator>
					<MenuItemLayout
						icon={(
							<NavigationIcon type={NavigationIconType.Support} />
						)}
						isLabelHidden={!hasDropdownLook}
						label={(
							<FormattedMessage {...messages.support} />
						)}
					/>
				</IntercomActivator>
			),
			name: 'support',
		});

		items.push({
			content: (
				<Link routeName="account.websites">
					<MenuItemLayout
						icon={(
							<NavigationIcon type={NavigationIconType.Account} />
						)}
						iconTag={statusIcon}
						isLabelHidden={!hasDropdownLook}
						label={(
							<FormattedMessage {...messages.account} />
						)}
					/>
				</Link>
			),
			name: 'account',
		});

		items.push({
			content: (
				<Link routeName="userProfile">
					<MenuItemLayout
						icon={(
							<NavigationIcon type={NavigationIconType.Profile} />
						)}
						isLabelHidden={!hasDropdownLook}
						label={(
							<FormattedMessage {...messages.profile} />
						)}
					/>
				</Link>
			),
			name: 'profile',
		});

		if (IsConductorClient) {
			items.push({
				content: (
					<ConductorLink href="https://app.conductor.com">
						<MenuItemLayout
							icon={(
								<NavigationIcon type={NavigationIconType.Conductor} />
							)}
							isLabelHidden={!hasDropdownLook}
							label={(
								<FormattedMessage {...messages.conductor} />
							)}
						/>
					</ConductorLink>
				),
				name: 'conductor',
			});
		}
	}

	if (IsConductorClient === false) {
		items.push({
			content: (
				<LogoutMenuItem
					isLabelHidden={!hasDropdownLook}
				/>
			),
			name: 'logout',
		});
	}

	return (
		<Dropdown
			attachment={hasDropdownLook ? DropdownAttachment.Top : DropdownAttachment.Bottom}
			hiddenScrollbars={true}
			isOpen={open}
			style={DropdownStyle.Sidebar}
		>
			<UserNav
				isResponsive={false}
				items={items}
			/>
		</Dropdown>
	);
};



export default UserNavigationDropdown;
