/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteIncorrectLinksMutationVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DeleteIncorrectLinksMutation = { readonly __typename?: 'Mutation', readonly DeleteIncorrectLinks: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const DeleteIncorrectLinksDocument = gql`
    mutation DeleteIncorrectLinks($websiteId: WebsiteId!) {
  DeleteIncorrectLinks(websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type DeleteIncorrectLinksMutationFn = Apollo.MutationFunction<DeleteIncorrectLinksMutation, DeleteIncorrectLinksMutationVariables>;

/**
 * __useDeleteIncorrectLinksMutation__
 *
 * To run a mutation, you first call `useDeleteIncorrectLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncorrectLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncorrectLinksMutation, { data, loading, error }] = useDeleteIncorrectLinksMutation({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDeleteIncorrectLinksMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIncorrectLinksMutation, DeleteIncorrectLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIncorrectLinksMutation, DeleteIncorrectLinksMutationVariables>(DeleteIncorrectLinksDocument, options);
      }
export type DeleteIncorrectLinksMutationHookResult = ReturnType<typeof useDeleteIncorrectLinksMutation>;
export type DeleteIncorrectLinksMutationResult = Apollo.MutationResult<DeleteIncorrectLinksMutation>;
export type DeleteIncorrectLinksMutationOptions = Apollo.BaseMutationOptions<DeleteIncorrectLinksMutation, DeleteIncorrectLinksMutationVariables>;