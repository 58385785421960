import React from 'react';

import ListOfBenefits from '~/components/logic/account/components/ListOfBenefits';
import ReviewsOverview from '~/components/logic/account/components/ReviewsOverview';
import SatisfiedCustomers from '~/components/logic/account/components/SatisfiedCustomers';
import UnderlinedRows from '~/components/patterns/lists/UnderlinedRows';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';



type Props = {
	hideBenefits?: boolean,
};

const SocialProof: React.FC<Props> = (props) => {
	const {
		hideBenefits = false,
	} = props;

	const accountId = useAccountId();
	const outOfBand = useAccountIsOutOfBand(accountId);

	return (
		<UnderlinedRows gapsSize={3}>
			{outOfBand === false && hideBenefits === false && (
				<ListOfBenefits />
			)}
			<ReviewsOverview reviewsCount={4} />
			<SatisfiedCustomers />
		</UnderlinedRows>
	);
};



export default SocialProof;
