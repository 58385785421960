import type CK from '~/types/contentking';

import {
	usePageRedirectTargetQuery,
} from './usePageRedirectTarget.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageRedirectTarget(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageRedirectTargetQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.redirectTarget ?? null;
}



export default usePageRedirectTarget;
