import classNames from 'classnames';
import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';



type Props = {
	isOpen: boolean,
	label: React.ReactNode,
};

const ActiveFilterSelectToggler: React.FC<Props> = (props) => {
	const {
		isOpen,
		label,
	} = props;

	const componentClasses = classNames({
		'filter-select-toggler': true,
		'filter-select-toggler--open': isOpen,
		'filter-select-toggler--closed': !isOpen,
	});

	return (
		<div className={componentClasses}>
			<div className="filter-select-toggler__icon">
				<BasicIcon
					color="#ffffff"
					size={20}
					type={BasicIconType.Filter}
				/>
			</div>
			<div className="filter-select-toggler__label">
				{label}
			</div>
			<div className="filter-select-toggler__icon">
				<DropdownIcon
					isActive={isOpen}
					style={DropdownIconStyle.White}
				/>
			</div>
		</div>
	);
};



export default ActiveFilterSelectToggler;
