import type React from 'react';

import useAuthenticatedSession from '~/hooks/useAuthenticatedSession';



const AuthenticatedSession: React.FC = () => {
	useAuthenticatedSession();

	return null;
};



export default AuthenticatedSession;
