import classNames from 'classnames';
import React from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	Link,
} from 'react-router5';

import type CK from '~/types/contentking';

import {
	appEmptyStateSelector,
	selectedSectionSelector,
} from '~/state/ui/selectors';

import {
	EMPTY_STATE_NO_WEBSITES,
} from '~/model/ui/appEmpty';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type ChildProps = {
	isActive: boolean,
	isDimmed: boolean,
};

type Props = {
	children: RenderProp<ChildProps>,
	className?: string,
	onClick?: () => void,
	websiteId: CK.WebsiteId | null,
};

const PlatformLink: React.FC<Props> = (props) => {
	const {
		children,
		className,
		onClick,
		websiteId,
	} = props;

	const appEmptyState = useSelector(appEmptyStateSelector);
	const selectedSection = useSelector(selectedSectionSelector);

	const isActive = selectedSection === 'website.platform';

	function handleClick() {
		if (onClick) {
			onClick();
		}
	}

	if (websiteId === null || appEmptyState === EMPTY_STATE_NO_WEBSITES) {
		return (
			<Link routeName="home">
				{renderProp(
					children,
					{
						isActive: false,
						isDimmed: true,
					},
				)}
			</Link>
		);
	}

	const finalClassName = classNames({
		'active': isActive,
	}, className);

	return (
		<Link
			className={finalClassName}
			onClick={handleClick}
			routeName="website.platform"
			routeParams={{
				websiteId,
			}}
		>
			{renderProp(
				children,
				{
					isActive,
					isDimmed: false,
				},
			)}
		</Link>
	);
};



export default PlatformLink;
