import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeSnippets from '~/components/patterns/structuredValues/CodeSnippets';
import DateTime from '~/components/app/DateTime';
import List from '~/components/patterns/lists/List';



const FACTOR_MATCH_NONE = 'none';
const FACTOR_STATUS_EXPIRED = 'expired';
const FACTOR_STATUS_NOT_VALID_YET = 'notValidYet';

const PROPERTY_COMMON_NAME = 'commonName';
const PROPERTY_ISSUER_NAME = 'issuerName';
const PROPERTY_VALID_FROM = 'validFrom';
const PROPERTY_VALID_UNTIL = 'validUntil';



const messages = defineMessages({
	domainVariant: {
		id: 'ui.platformIssueDetail.invalidHttpsCertificate.domainVariant',
	},
	error: {
		[FACTOR_MATCH_NONE]: {
			id: 'ui.platformIssueDetail.invalidHttpsCertificate.error.matchNone',
		},
		[FACTOR_STATUS_EXPIRED]: {
			id: 'ui.platformIssueDetail.invalidHttpsCertificate.error.statusExpired',
		},
		[FACTOR_STATUS_NOT_VALID_YET]: {
			id: 'ui.platformIssueDetail.invalidHttpsCertificate.error.statusNotValidYet',
		},
		label: {
			id: 'ui.platformIssueDetail.invalidHttpsCertificate.error.label',
		},
	},
	property: {
		[PROPERTY_COMMON_NAME]: {
			id: 'ui.platformIssueDetail.invalidHttpsCertificate.property.commonName',
		},
		[PROPERTY_ISSUER_NAME]: {
			id: 'ui.platformIssueDetail.invalidHttpsCertificate.property.issuerName',
		},
		[PROPERTY_VALID_FROM]: {
			id: 'ui.platformIssueDetail.invalidHttpsCertificate.property.validFrom',
		},
		[PROPERTY_VALID_UNTIL]: {
			id: 'ui.platformIssueDetail.invalidHttpsCertificate.property.validUntil',
		},
	},
});



const InvalidHttpsCertificateStatus = (props) => {
	const {
		issue,
	} = props;

	const domainsWithInvalidCertificate = issue.context.domainsWithInvalidCertificate ?? [];

	const getDomainSnippetData = (domain) => {
		return {
			label: (
				<FormattedMessage {...messages.domainVariant} />
			),
			content: domain,
		};
	};

	const getErrorSnippetData = (factor) => {
		return {
			label: (
				<FormattedMessage {...messages.error.label} />
			),
			content: (
				<FormattedMessage {...messages.error[factor]} />
			),
		};
	};

	const getCertificateSnippetData = ({ certificate, property }) => {
		return {
			label: (
				<FormattedMessage {...messages.property[property]} />
			),
			content: property === PROPERTY_VALID_FROM || property === PROPERTY_VALID_UNTIL
				? <DateTime datetime={certificate[property]} />
				: certificate[property],
		};
	};

	const cases = [];

	domainsWithInvalidCertificate.forEach((domain) => {
		const snippets = [];

		if (domain.factors.match === FACTOR_MATCH_NONE) {
			snippets.push(getDomainSnippetData(domain.domain));
			snippets.push(getErrorSnippetData(FACTOR_MATCH_NONE));
			snippets.push(getCertificateSnippetData({
				certificate: domain.certificate,
				property: PROPERTY_COMMON_NAME,
			}));
			snippets.push(getCertificateSnippetData({
				certificate: domain.certificate,
				property: PROPERTY_ISSUER_NAME,
			}));

			cases.push(
				<CodeSnippets snippets={snippets} />,
			);
		}

		if (domain.factors.status === FACTOR_STATUS_EXPIRED) {
			snippets.push(getDomainSnippetData(domain.domain));
			snippets.push(getErrorSnippetData(FACTOR_STATUS_EXPIRED));
			snippets.push(getCertificateSnippetData({
				certificate: domain.certificate,
				property: PROPERTY_COMMON_NAME,
			}));
			snippets.push(getCertificateSnippetData({
				certificate: domain.certificate,
				property: PROPERTY_ISSUER_NAME,
			}));
			snippets.push(getCertificateSnippetData({
				certificate: domain.certificate,
				property: PROPERTY_VALID_UNTIL,
			}));

			cases.push(
				<CodeSnippets snippets={snippets} />,
			);
		}

		if (domain.factors.status === FACTOR_STATUS_NOT_VALID_YET) {
			snippets.push(getDomainSnippetData(domain.domain));
			snippets.push(getErrorSnippetData(FACTOR_STATUS_NOT_VALID_YET));
			snippets.push(getCertificateSnippetData({
				certificate: domain.certificate,
				property: PROPERTY_COMMON_NAME,
			}));
			snippets.push(getCertificateSnippetData({
				certificate: domain.certificate,
				property: PROPERTY_ISSUER_NAME,
			}));
			snippets.push(getCertificateSnippetData({
				certificate: domain.certificate,
				property: PROPERTY_VALID_FROM,
			}));
			snippets.push(getCertificateSnippetData({
				certificate: domain.certificate,
				property: PROPERTY_VALID_UNTIL,
			}));

			cases.push(
				<CodeSnippets snippets={snippets} />,
			);
		}
	});

	return (
		<List>
			{cases}
		</List>
	);
};



export default InvalidHttpsCertificateStatus;
