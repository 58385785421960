import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ClipboardIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g
					id="icon-clipboard"
					stroke={color}
					strokeWidth="1.5"
					transform="translate(6.000000, 4.000000)"
				>
					<g id="Group">
						<path
							d="M11.17689,2.85059 L12.20509,2.85059 C12.39409,2.85059 12.54709,3.00359 12.54709,3.19146 L12.54709,16.597 C12.54709,16.7848 12.39409,16.9378 12.20509,16.9378 L0.342,16.9378 C0.153,16.9378 0,16.7848 0,16.597 L0,3.19146 C0,3.00359 0.153,2.85059 0.342,2.85059 L1.31513,2.85059"
							id="Path"
						>
						</path>
						<path
							d="M7.59434,1.32075 C7.59434,0.59175 7.00372,0 6.27359,0 C5.54459,0 4.95284,0.59175 4.95284,1.32075 L3.06397,1.32075 L3.06397,3.84672 L9.48439,3.84672 L9.48439,1.32075 L7.59434,1.32075 Z"
							id="Path"
						>
						</path>
						<line
							id="Path"
							x1="3.06348"
							x2="9.48389"
							y1="7.44556"
							y2="7.44556"
						>
						</line>
						<line
							id="Path"
							x1="3.06348"
							x2="9.48389"
							y1="10.5087"
							y2="10.5087"
						>
						</line>
						<line
							id="Path"
							x1="3.06348"
							x2="9.48389"
							y1="13.5717"
							y2="13.5717"
						>
						</line>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ClipboardIconBuilder;

