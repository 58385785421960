import throttle from 'lodash/throttle';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AccountDisplayName from '~/components/app/AccountDisplayName';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import Dropdown, {
	DropdownStyle,
} from '~/components/patterns/navigations/Dropdown';
import GroupBox, {
	GroupBoxSize,
	GroupBoxStyle,
} from '~/components/patterns/boxes/GroupBox';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import Small from '~/components/patterns/typography/Small';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';
import Website from '~/components/patterns/websites/Website';
import WebsiteFavicon, {
	WebsiteFaviconUsageContext,
} from '~/components/logic/websites/WebsiteFavicon';
import WebsiteLink from '~/components/app/WebsiteLink';
import WebsitesList from '~/components/patterns/websites/WebsitesList';
import WebsitesNavigation from '~/components/patterns/websites/WebsitesNavigation';
import WebsitesSearchForm from '~/components/atoms/forms/forms/WebsitesSearchForm';

import {
	useTeamWebsitesDropdownQuery,
} from './TeamWebsitesDropdown.gql';

import useDisabledMemberships from '~/hooks/useDisabledMemberships';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useEnabledColumnsList from '~/hooks/useEnabledColumnsList';
import useHasWebsitesScreen from '~/hooks/useHasWebsitesScreen';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import usePollInterval from '~/hooks/usePollInterval';
import useSelectedWebsiteId from '~/hooks/useSelectedWebsiteId';
import useViewportOrientation from '~/hooks/useViewportOrientation';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';

import {
	menu,
	storeLastSearchedWebsite,
} from '~/actions';

import {
	IsConductorClient,
} from '~/config';

import {
	filterWebsitesByName,
} from '~/model/websites';

import {
	filterSelector as historicalChangesFilterSelector,
	dateIntervalSelector as historicalChangesIntervalSelector,
	sortBySelector as historicalChangesSortBySelector,
	isInHistoricalChangesModeSelector,
} from '~/state/historicalChanges/selectors';

import {
	lastSearchedWebsiteSelector,
} from '~/state/lastSearchedWebsite/selectors';

import {
	pagesColumnsParameterSelector,
	pagesViewParameterSelector,
} from '~/state/ui/content/selectors';

import {
	isWebsiteSwitcherOpenSelector,
} from '~/state/ui/menu/selectors';

import {
	filterSelector as pagesFilterSelector,
	sortBySelector as pagesSortBySelector,
	selectedSectionSelector,
} from '~/state/ui/selectors';

import sortArrayByProperty from '~/utilities/sortArrayByProperty';
import touchSupported from '~/utilities/touchSupported';



const messages = defineMessages({
	addWebsite: {
		id: 'ui.menu.websites.addWebsiteLabel',
	},
	closeLink: {
		id: 'ui.menu.websites.closeLink',
	},
	progressDescription: {
		id: 'ui.menu.progress.tooltip',
	},
});



function filterWebsites(lastSearchedWebsite, websites) {
	return (
		(lastSearchedWebsite.length > 0 && websites.length > 0)
			? filterWebsitesByName({
				websites,
				name: lastSearchedWebsite,
			})
			: websites
	);
}



const TeamWebsitesDropdown = () => {
	const [maxHeight, setMaxHeight] = React.useState(0);

	const dispatch = useDispatch();
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const columns = useEnabledColumnsList();
	const columnsParameter = useSelector(pagesColumnsParameterSelector);
	const disabledMemberships = useDisabledMemberships();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const hasWebsitesScreen = useHasWebsitesScreen();
	const historicalChangesFilter = useSelector(historicalChangesFilterSelector);
	const historicalChangesInterval = useSelector(historicalChangesIntervalSelector);
	const historicalChangesSortBy = useSelector(historicalChangesSortBySelector);
	const inHistoricalChangesMode = useSelector(isInHistoricalChangesModeSelector);
	const lastSearchedWebsite = useSelector(lastSearchedWebsiteSelector);
	const open = useSelector(isWebsiteSwitcherOpenSelector);
	const pagesFilter = useSelector(pagesFilterSelector);
	const pagesSortBy = useSelector(pagesSortBySelector);
	const selectedSection = useSelector(selectedSectionSelector);
	const viewParameter = useSelector(pagesViewParameterSelector);
	const viewportOrientation = useViewportOrientation();
	const viewportType = useViewportType();

	const { data } = useTeamWebsitesDropdownQuery({
		pollInterval: usePollInterval(60000),
	});

	const accounts = data?.authenticatedSession?.accounts ?? [];

	const numberOfWebsites = accounts.reduce(
		(total, account) => total + account.websites.length,
		0,
	);
	const hasVisibleAccountName = (accounts.length + (disabledMemberships?.length ?? 0)) > 1;

	const handleDropdownClose = React.useCallback(
		(e) => {
			e.preventDefault();

			if (open) {
				dispatch(menu.closeWebsiteSwitcher());
			}
		},
		[
			dispatch,
			open,
		],
	);

	const isDisplayedAsDropdown = viewportType.isSmall || (viewportType.isMedium && viewportOrientation.isPortrait);

	React.useEffect(
		() => {
			let newMaxHeight;

			if (!open) {
				newMaxHeight = 0;
			} else if (isDisplayedAsDropdown) {
				let itemsCount = numberOfWebsites;
				itemsCount += 1; // add website button (website search is hidden)

				let height = Math.ceil(itemsCount / 2) * 42;
				height += 2; // 1px bottom and top padding
				newMaxHeight = 415;

				newMaxHeight = Math.min(height, newMaxHeight);
			} else {
				newMaxHeight = window.innerHeight;

				// websites toggle element (2 sizes depends on present account name)
				newMaxHeight -= hasVisibleAccountName && !viewportType.isMedium ? 49 : 44;

				// always visible menu items
				newMaxHeight -= (42 * 3) + (viewportType.isMedium ? 56 : 64);

				if (hasWebsitesScreen) {
					newMaxHeight -= 42;
				}

				if (IsConductorClient) {
					newMaxHeight -= 59;
				}

				// conditional menu items
				if (!viewportType.isSmall && !viewportType.isMedium) {
					if (kingdomAdminFeatures.areVisible) {
						newMaxHeight -= 42 * 2; // switch account & general admin
					}

					if (kingdomAdminFeatures.areAlwaysVisible) {
						newMaxHeight -= 42; // toggle admin
					}
				}

				newMaxHeight -= 15;
			}

			setMaxHeight(newMaxHeight);
		},
		[
			hasVisibleAccountName,
			hasWebsitesScreen,
			isDisplayedAsDropdown,
			kingdomAdminFeatures,
			numberOfWebsites,
			open,
			viewportType,
		],
	);

	const handleClick = React.useCallback(
		() => {
			dispatch(
				menu.closeWebsiteSwitcher(),
			);
		},
		[
			dispatch,
		],
	);

	const handleSearchCallback = React.useCallback(
		(field, value) => {
			if (field === 'search') {
				dispatch(
					storeLastSearchedWebsite(value),
				);
			}
		},
		[
			dispatch,
		],
	);

	let websiteSearch: React.ReactNode = null;

	if (
		lastSearchedWebsite
		|| (
			numberOfWebsites >= 7
			&& !viewportType.isSmall && !viewportType.isMedium
		)
	) {
		websiteSearch = (
			<WebsitesSearchForm
				autoFocus={!touchSupported && open}
				defaultValue={lastSearchedWebsite}
				onChangeCallback={throttle(handleSearchCallback, 100)}
			/>
		);
	}

	const output = accounts.map((account) => {
		let result = (
			<SingleAccountWebsitesList
				account={account}
				columns={columns}
				columnsParameter={columnsParameter}
				handleClick={handleClick}
				historicalChangesFilter={historicalChangesFilter}
				historicalChangesInterval={historicalChangesInterval}
				historicalChangesSortBy={historicalChangesSortBy}
				inHistoricalChangesMode={inHistoricalChangesMode}
				isDisplayedAsDropdown={isDisplayedAsDropdown}
				key={account.id}
				lastSearchedWebsite={lastSearchedWebsite}
				pagesFilter={pagesFilter}
				pagesSortBy={pagesSortBy}
				selectedSection={selectedSection}
				viewParameter={viewParameter}
			/>
		);

		if (hasVisibleAccountName) {
			result = (
				<GroupBox
					defaultOpen={account.id === effectiveHomeAccountId}
					isCollapsible={lastSearchedWebsite.length === 0}
					key={account.id}
					size={GroupBoxSize.Small}
					style={GroupBoxStyle.Eminence}
					title={(
						<AccountDisplayName accountId={account.id} />
					)}
					titleEllipsis={true}
				>
					{result}
				</GroupBox>
			);
		}

		return result;
	});

	(disabledMemberships ?? []).forEach((membershipLabel) => {
		output.push((
			<GroupBox
				defaultOpen={false}
				isCollapsible={true}
				key={'x' + membershipLabel.accountId}
				size={GroupBoxSize.Small}
				style={GroupBoxStyle.Eminence}
				title={membershipLabel.accountDisplayName}
				titleEllipsis={true}
			>
				<Small muted={true}>
					{membershipLabel.explanation}
				</Small>
			</GroupBox>
		));
	});

	return (
		<Dropdown
			height={maxHeight > 0 && !isDisplayedAsDropdown ? maxHeight : undefined}
			hiddenScrollbars={true}
			isOpen={open}
			style={DropdownStyle.Sidebar}
		>
			<WebsitesNavigation
				closeLink={!(viewportType.isSmall || (viewportType.isMedium && viewportOrientation.isPortrait)) && (
					<Button
						compact={viewportType.isMedium && viewportOrientation.isLandscape}
						icon={(
							<BasicIcon
								color="#ffffff"
								type={BasicIconType.Cancel}
							/>
						)}
						onClick={handleDropdownClose}
						size={ButtonSize.Small}
						style={ButtonStyle.Link}
						width={ButtonWidth.Fullwidth}
					>
						<FormattedMessage {...messages.closeLink} />
					</Button>
				)}
				searchField={websiteSearch}
			>
				<WebsitesList>
					{output}
				</WebsitesList>
			</WebsitesNavigation>
		</Dropdown>
	);
};



type SingleAccountWebsitesListProps = {
	account: {
		id: CK.AccountId,
		websites: ReadonlyArray<{
			displayName: string,
		}>,
	},
	columns,
	columnsParameter,
	handleClick: () => void,
	historicalChangesFilter,
	historicalChangesInterval,
	historicalChangesSortBy,
	inHistoricalChangesMode,
	isDisplayedAsDropdown: boolean,
	lastSearchedWebsite: string | null,
	pagesFilter,
	pagesSortBy,
	selectedSection,
	viewParameter,
};

const SingleAccountWebsitesList: React.FC<SingleAccountWebsitesListProps> = (props) => {
	const {
		account,
		columns,
		columnsParameter,
		handleClick,
		historicalChangesFilter,
		historicalChangesInterval,
		historicalChangesSortBy,
		inHistoricalChangesMode,
		isDisplayedAsDropdown,
		lastSearchedWebsite,
		pagesFilter,
		pagesSortBy,
		selectedSection,
		viewParameter,
	} = props;

	const viewportOrientation = useViewportOrientation();
	const viewportType = useViewportType();

	const isAllowedToAddWebsite = useIsAllowedWithAccount(
		account.id,
		GraphQL.ActionWithAccount.AddWebsite,
	);

	const websites = sortArrayByProperty(account.websites, (website) => website.displayName);
	const filteredWebsites = filterWebsites(lastSearchedWebsite, websites);

	const addWebsiteButton = isAllowedToAddWebsite.yes ? (
		<Button
			linkRouteName="account.websites.new"
			linkRouteParams={{
				accountId: account.id,
			}}
			size={ButtonSize.Small}
			style={ButtonStyle.Action}
			width={ButtonWidth.Fullwidth}
		>
			{(viewportType.isMedium && viewportOrientation.isLandscape) ? (
				<BasicIcon
					color="#ffffff"
					type={BasicIconType.Plus}
				/>
			) : (
				<FormattedMessage {...messages.addWebsite} />
			)}
		</Button>
	) : null;

	return (
		<List size={ListSize.Small}>
			<WebsitesList
				hasGaps={false}
				key={account.id}
				multiColumns={isDisplayedAsDropdown}
				placeholder="No websites"
			>
				{filteredWebsites.map((website) => (
					<WebsiteLinkInTeamWebsitesDropdown
						columns={columns}
						columnsParameter={columnsParameter}
						historicalChangesFilter={historicalChangesFilter}
						historicalChangesInterval={historicalChangesInterval}
						historicalChangesSortBy={historicalChangesSortBy}
						inHistoricalChangesMode={inHistoricalChangesMode}
						isWebsiteDiscovered={website.isDiscovered}
						key={website.id}
						onClick={handleClick}
						pagesFilter={pagesFilter}
						pagesSortBy={pagesSortBy}
						selectedSection={selectedSection}
						viewParameter={viewParameter}
						websiteId={website.id}
					/>
				))}
			</WebsitesList>
			{addWebsiteButton}
		</List>
	);
};



const WebsiteLinkInTeamWebsitesDropdown = (props) => {
	const {
		columns,
		columnsParameter,
		historicalChangesFilter,
		historicalChangesInterval,
		historicalChangesSortBy,
		inHistoricalChangesMode,
		isWebsiteDiscovered,
		onClick,
		pagesFilter,
		pagesSortBy,
		selectedSection,
		viewParameter,
		websiteId,
	} = props;

	const isCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId);
	const selectedWebsiteId = useSelectedWebsiteId();
	const websiteDisplayName = useWebsiteDisplayName(websiteId);

	let iconStatus: StatusFlagStatus | null = null;

	if (isWebsiteDiscovered === false) {
		iconStatus = StatusFlagStatus.DiscoveryInProgress;
	}

	if (isCrawlingPaused) {
		iconStatus = StatusFlagStatus.Paused;
	}

	const renderStatusFlag = (iconStatus) => {
		if (iconStatus === null) {
			return undefined;
		}

		if (iconStatus === StatusFlagStatus.DiscoveryInProgress) {
			return (
				<Hint
					attachment={HintAttachment.Left}
					popup={(
						<FormattedMessage {...messages.progressDescription} />
					)}
					popupMaxWidth={260}
					popupZIndex={1500}
				>
					<StatusFlag status={iconStatus} />
				</Hint>
			);
		}

		return (
			<StatusFlag status={iconStatus} />
		);
	};

	return (
		<WebsiteLink
			columns={columns}
			columnsParameter={columnsParameter}
			historicalChangesFilter={historicalChangesFilter}
			historicalChangesInterval={historicalChangesInterval}
			historicalChangesSortBy={historicalChangesSortBy}
			inHistoricalChangesMode={inHistoricalChangesMode}
			isActive={false}
			onClick={onClick}
			pagesFilter={pagesFilter}
			pagesSortBy={pagesSortBy}
			section={selectedSection}
			viewParameter={viewParameter}
			websiteId={websiteId}
		>
			<Website
				favicon={(
					<WebsiteFavicon
						usageContext={WebsiteFaviconUsageContext.Sidebar}
						websiteId={websiteId}
					/>
				)}
				isHighlighted={websiteId === selectedWebsiteId}
				name={websiteDisplayName}
				statusFlag={renderStatusFlag(iconStatus)}
			/>
		</WebsiteLink>
	);
};



export default TeamWebsitesDropdown;
