import GraphQL from '~/types/graphql';

import type CK from '~/types/contentking';

import useGoogleAnalyticsDependencies from '~/hooks/useGoogleAnalyticsDependencies';
import useWebsiteGoogleAnalyticsIntegration from '~/hooks/useWebsiteGoogleAnalyticsIntegration';



function useWebsiteHasConflictingGoogleAnalyticsDependencies(websiteId: CK.WebsiteId) {
	const googleAnalyticsIntegration = useWebsiteGoogleAnalyticsIntegration(websiteId);

	const otherVersion = (
		googleAnalyticsIntegration.integration?.version === GraphQL.GoogleAnalyticsVersion.V4
			? GraphQL.GoogleAnalyticsVersion.V3
			: GraphQL.GoogleAnalyticsVersion.V4
	);

	const otherVersionDependencies = useGoogleAnalyticsDependencies(websiteId, otherVersion);

	if (googleAnalyticsIntegration.integration === null) {
		return false;
	}

	return otherVersionDependencies.hasDependencies;
}



export default useWebsiteHasConflictingGoogleAnalyticsDependencies;
