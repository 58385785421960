import type CK from '~/types/contentking';

import {
	usePageIssueCategoriesQuery,
} from './usePageIssueCategories.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageIssueCategories(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageIssueCategoriesQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.pageTypeData?.issueCategories ?? null;
}



export default usePageIssueCategories;
