import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const TwitterCardsIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="social-twitter"
					transform="translate(2.000000, 3.000000)"
				>
					<path
						d="M49.84375,14.1600937 C48.3260937,14.8338437 46.695,15.2875937 44.983125,15.492125 C46.7310937,14.4454062 48.0717188,12.7868125 48.7042187,10.81025 C47.0696875,11.779625 45.258125,12.4843125 43.3296875,12.8641562 C41.78625,11.2193125 39.58625,10.1915 37.1525,10.1915 C32.4792188,10.1915 28.689375,13.9813437 28.689375,18.654625 C28.689375,19.3180625 28.765,19.9643125 28.909375,20.5830625 C21.8745312,20.2307187 15.6389063,16.86025 11.4657813,11.7400937 C10.7370312,12.9913437 10.319375,14.4436875 10.319375,15.9957187 C10.319375,18.9313437 11.8129688,21.5215 14.0851563,23.0391562 C12.6964062,22.9961875 11.391875,22.614625 10.250625,21.9804062 L10.250625,22.0869687 C10.250625,26.1879062 13.1673437,29.6082187 17.0396875,30.3850937 C16.3298437,30.5793125 15.5821875,30.6824375 14.8104687,30.6824375 C14.2639062,30.6824375 13.7345312,30.6291562 13.2171875,30.5311875 C14.2948438,33.8930625 17.4195312,36.3405625 21.1234375,36.4075937 C18.2273438,38.6780625 14.5767187,40.0307187 10.6132812,40.0307187 C9.92921875,40.0307187 9.25546875,39.9911875 8.59375,39.912125 C12.3389062,42.3132187 16.7870313,43.714 21.566875,43.714 C37.1335938,43.714 45.6448438,30.8199375 45.6448438,19.6360312 C45.6448438,19.2682187 45.6379687,18.9038437 45.6207813,18.5411875 C47.2742187,17.348375 48.709375,15.8565 49.84375,14.1600937 L49.84375,14.1600937 Z"
						id="Stroke-1366"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default TwitterCardsIconBuilder;

