import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import {
	MultiStepModalContext,
} from '~/components/patterns/modals/MultiStepModal';



const messages = defineMessages({
	backButton: {
		id: 'ui.general.backButton',
	},
});



type Props = {
	children?: React.ReactNode,
	onClickCallback?: (() => void),
};

const BackButton: React.FC<Props> = (props) => {
	const {
		children,
		onClickCallback,
	} = props;

	const multiStepModal = React.useContext(MultiStepModalContext);

	const handleClick = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			if (onClickCallback !== undefined) {
				onClickCallback();
			} else if (multiStepModal !== null) {
				multiStepModal.goToPreviousStep();
			}
		},
		[
			multiStepModal,
			onClickCallback,
		],
	);

	return (
		<Button
			onClick={handleClick}
			style={ButtonStyle.Link}
		>
			{children || (
				<FormattedMessage {...messages.backButton} />
			)}
		</Button>
	);
};



export default React.memo(BackButton);
