import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SportIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M9.73,6.205a.5.5,0,0,0,.129.691q.411.285.821.595a.5.5,0,0,0,.6,0,21.334,21.334,0,0,1,8.728-3.882.25.25,0,0,0,.119-.43A11.953,11.953,0,0,0,12.038.038c-.1,0-.193,0-.288.007a.5.5,0,0,0-.476.524A9.192,9.192,0,0,1,9.73,6.205Z"
				fill={color}
			/>
			<path
				d="M12.489,8.75a.5.5,0,0,0,.155.4,21.807,21.807,0,0,1,1.525,1.591.5.5,0,0,0,.616.111,8.761,8.761,0,0,1,8.8.163.251.251,0,0,0,.374-.243,11.918,11.918,0,0,0-2.133-5.656.5.5,0,0,0-.488-.2A20.258,20.258,0,0,0,12.7,8.378.5.5,0,0,0,12.489,8.75Z"
				fill={color}
			/>
			<path
				d="M12.835,12.251a.5.5,0,0,0,.037-.7,20.811,20.811,0,0,0-1.506-1.532.5.5,0,0,0-.678,0A24.692,24.692,0,0,0,4.167,20.684a.5.5,0,0,0,.156.528,11.922,11.922,0,0,0,6.448,2.745.25.25,0,0,0,.243-.373A8.921,8.921,0,0,1,12.835,12.251Z"
				fill={color}
			/>
			<path
				d="M14.448,13.577a.5.5,0,0,0-.361-.216.506.506,0,0,0-.4.136,7.527,7.527,0,0,0-.8,10.262.5.5,0,0,0,.457.2,11.923,11.923,0,0,0,4.116-1.213.5.5,0,0,0,.269-.517A20.363,20.363,0,0,0,14.448,13.577Z"
				fill={color}
			/>
			<path
				d="M1.131,8.737A7.648,7.648,0,0,0,6.445,7.391a.5.5,0,0,0-.052-.852A26.619,26.619,0,0,0,2.8,4.968a.5.5,0,0,0-.573.185A11.911,11.911,0,0,0,.717,8.074a.5.5,0,0,0,.414.663Z"
				fill={color}
			/>
			<path
				d="M4.223,3.907A28.174,28.174,0,0,1,7.772,5.586a.5.5,0,0,0,.657-.152A7.71,7.71,0,0,0,9.758.871.5.5,0,0,0,9.137.4,11.949,11.949,0,0,0,4.074,3.068a.5.5,0,0,0,.149.839Z"
				fill={color}
			/>
			<path
				d="M23.761,12.9a7.449,7.449,0,0,0-7.879-.93.5.5,0,0,0-.2.735,21.554,21.554,0,0,1,3.422,8.559.25.25,0,0,0,.4.153,11.965,11.965,0,0,0,4.452-8.062A.5.5,0,0,0,23.761,12.9Z"
				fill={color}
			/>
			<path
				d="M9.374,9.116a.5.5,0,0,0,.153-.4.5.5,0,0,0-.206-.372q-.285-.2-.57-.4a.5.5,0,0,0-.608.038A9.139,9.139,0,0,1,.705,10.177a.5.5,0,0,0-.376.1.507.507,0,0,0-.195.336,11.247,11.247,0,0,0-.1,1.43A11.943,11.943,0,0,0,2.58,19.415a.251.251,0,0,0,.434-.074A25.8,25.8,0,0,1,9.374,9.116Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SportIconBuilder;

