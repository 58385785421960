import type React from 'react';



export default function getOptionsLabel(options, values) {
	if (values.length === 0 || values.size === 0) {
		return ['none'];
	}

	const labels: Array<React.ReactNode> = [];

	options.forEach((option) => {
		if (values.indexOf(option.name) !== -1) {
			labels.push(option.title || option.label);
		}
	});

	return labels;
}
