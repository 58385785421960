import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import Copy from '../../Copy';
import ValidationMessage, {
	ValidationMessageStatus,
} from '~/components/patterns/messages/embedded/ValidationMessage';

import {
	ValidationStatus,
} from './AddMultipleWebsitesStep';



const messages = defineMessages({
	failed: {
		id: 'ui.websites.new.steps.multiple.validationStatus.failed',
	},
	failedExplanation: {
		id: 'ui.websites.new.steps.multiple.validationStatus.failedExplanation',
	},
	pending: {
		id: 'ui.websites.new.steps.multiple.validationStatus.pending',
	},
});



type Props = {
	stagedDomains: Array<string>,
	values: Record<string, string | ValidationStatus>,
};

const ValidationStatusMessage: React.FC<Props> = ({ stagedDomains, values }) => {
	let pendingDomains = 0;
	let failedDomains = 0;

	stagedDomains.forEach((domain) => {
		if (values[`status_${domain}`] === ValidationStatus.Pending) {
			pendingDomains += 1;
		}

		if (values[`status_${domain}`] === ValidationStatus.Failed) {
			failedDomains += 1;
		}
	});

	if (pendingDomains > 0) {
		return (
			<ValidationMessage status={ValidationMessageStatus.ValidationInProgress}>
				<Copy
					{...messages.pending}
					values={{
						domains: pendingDomains,
					}}
				/>
			</ValidationMessage>
		);
	}

	if (failedDomains > 0) {
		return (
			<ValidationMessage
				explanatoryTooltip={(
					<Copy {...messages.failedExplanation} />
				)}
				status={ValidationMessageStatus.Invalid}
			>
				<Copy
					{...messages.failed}
					values={{
						domains: failedDomains,
					}}
				/>
			</ValidationMessage>
		);
	}

	return null;
};

export default ValidationStatusMessage;
