/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloudflareLogpushVerificationQueryVariables = GraphQL.Exact<{
  bucketName: GraphQL.Scalars['String']['input'];
}>;


export type CloudflareLogpushVerificationQuery = { readonly __typename?: 'Query', readonly cloudflareLogpushVerificationForS3Bucket: string | null };


export const CloudflareLogpushVerificationDocument = gql`
    query CloudflareLogpushVerification($bucketName: String!) {
  cloudflareLogpushVerificationForS3Bucket(s3BucketName: $bucketName)
}
    `;

/**
 * __useCloudflareLogpushVerificationQuery__
 *
 * To run a query within a React component, call `useCloudflareLogpushVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudflareLogpushVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudflareLogpushVerificationQuery({
 *   variables: {
 *      bucketName: // value for 'bucketName'
 *   },
 * });
 */
export function useCloudflareLogpushVerificationQuery(baseOptions: Apollo.QueryHookOptions<CloudflareLogpushVerificationQuery, CloudflareLogpushVerificationQueryVariables> & ({ variables: CloudflareLogpushVerificationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CloudflareLogpushVerificationQuery, CloudflareLogpushVerificationQueryVariables>(CloudflareLogpushVerificationDocument, options);
      }
export function useCloudflareLogpushVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CloudflareLogpushVerificationQuery, CloudflareLogpushVerificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CloudflareLogpushVerificationQuery, CloudflareLogpushVerificationQueryVariables>(CloudflareLogpushVerificationDocument, options);
        }
export function useCloudflareLogpushVerificationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CloudflareLogpushVerificationQuery, CloudflareLogpushVerificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CloudflareLogpushVerificationQuery, CloudflareLogpushVerificationQueryVariables>(CloudflareLogpushVerificationDocument, options);
        }
export type CloudflareLogpushVerificationQueryHookResult = ReturnType<typeof useCloudflareLogpushVerificationQuery>;
export type CloudflareLogpushVerificationLazyQueryHookResult = ReturnType<typeof useCloudflareLogpushVerificationLazyQuery>;
export type CloudflareLogpushVerificationSuspenseQueryHookResult = ReturnType<typeof useCloudflareLogpushVerificationSuspenseQuery>;
export type CloudflareLogpushVerificationQueryResult = Apollo.QueryResult<CloudflareLogpushVerificationQuery, CloudflareLogpushVerificationQueryVariables>;