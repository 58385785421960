import React from 'react';
import {
	FormattedDate,
} from 'react-intl';

import ChartLayout from '~/components/patterns/charts/layouts/ChartLayout';
import ChartOverlay from '~/components/patterns/charts/components/ChartOverlay';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import ScopeLabel, {
	ScopeLabelSize,
	ScopeLabelStyle,
} from '~/components/logic/scopes/ScopeLabel';
import TextPreloader, {
	TextPreloaderSize,
} from '~/components/patterns/loaders/TextPreloader';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useManuallyTrackedLoading from '~/hooks/useManuallyTrackedLoading';
import useNavigation from '~/hooks/useNavigation';



type PrimaryActionLink = {
	label: React.ReactNode,
	routeName: string,
	routeParams: Record<string, any>,
};

type Props = {
	children?: React.ReactNode,
	flag?: React.ReactNode,
	label: React.ReactElement,
	loading: boolean,
	overlay?: React.ReactNode | false,
	primaryActionLink: PrimaryActionLink,
	showEndDate?: boolean,
};

const Widget: React.FC<Props> = (props) => {
	const {
		children,
		flag,
		label,
		loading,
		overlay,
		primaryActionLink,
		showEndDate = false,
	} = props;

	const manuallyTrackedLoading = useManuallyTrackedLoading();
	const navigation = useNavigation();

	const dateRange = useDashboardDateRange();
	const scope = useDashboardScopeSection();

	const link = (
		<InternalLink
			routeName={primaryActionLink.routeName}
			routeParams={primaryActionLink.routeParams}
			style={InternalLinkStyle.Decent}
		>
			{primaryActionLink.label}
		</InternalLink>
	);

	const onClickCallback = () => {
		navigation.navigate({
			routeName: primaryActionLink.routeName,
			routeParams: primaryActionLink.routeParams,
		});
	};

	const isRendering = manuallyTrackedLoading !== null && manuallyTrackedLoading.isActive;

	return (
		<ChartLayout
			flag={flag}
			headerElements={(
				<ScopeLabel
					scope={scope}
					size={ScopeLabelSize.Small}
					style={ScopeLabelStyle.Thumb}
				/>
			)}
			isCompact={true}
			label={label}
			link={link}
			onClickCallback={onClickCallback}
			overlay={(loading || isRendering) ? (
				<ChartOverlay>
					<TextPreloader size={TextPreloaderSize.Small} />
				</ChartOverlay>
			) : overlay}
			timestamp={showEndDate ? (
				<FormattedDate
					day="numeric"
					month="short"
					timeZone="Europe/Amsterdam"
					value={dateRange.end}
				/>
			) : null}
		>
			{children}
		</ChartLayout>
	);
};



export default Widget;
