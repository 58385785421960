import classNames from 'classnames';
import React from 'react';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import HelpIcon from '~/components/patterns/icons/HelpIcon';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';



type Props = {
	/** Possibility to highlight critical update */
	critical?: boolean,
	/** Possibility to display explanatory tooltip */
	explanatoryTooltip?: React.ReactNode,
	children: React.ReactNode,
	/** When component will be updated we have to define description */
	updateDescription?: React.ReactNode,
};



const UpcomingValueUpdateHighlight: React.FC<Props> = (props) => {
	const {
		children,
		critical,
		explanatoryTooltip,
		updateDescription,
	} = props;

	const componentClasses = classNames({
		'upcoming-value-update-highlight': true,
		'upcoming-value-update-highlight--is-critical': critical,
		'upcoming-value-update-highlight--will-change': updateDescription,
	});

	let content = (
		<span className={componentClasses}>
			{updateDescription || children}
		</span>
	);

	if (explanatoryTooltip) {
		content = (
			<Hint
				attachment={HintAttachment.Center}
				blurDelay={0}
				popup={explanatoryTooltip}
				popupUntouchable={true}
				targetCustomCursor={false}
			>
				<AttachedIcon
					icon={(
						<HelpIcon />
					)}
				>
					{content}
				</AttachedIcon>
			</Hint>
		);
	}

	return content;
};



export default UpcomingValueUpdateHighlight;
