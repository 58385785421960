import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const CrossfadeAnimation: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const elements = React.Children.toArray(children).filter((child) => !!child);

	const componentClasses = classNames({
		'crossfade-animation': true,
		['crossfade-animation--' + elements.length + '-groups']: true,
	});

	return (
		<div className={componentClasses}>
			{elements.map((child, index) => {
				return (
					<div
						className="crossfade-animation__group"
						key={index}
					>
						{child}
					</div>
				);
			})}
		</div>
	);
};



export default CrossfadeAnimation;
