import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum ChromeWebStoreIconVariants {
	Active = 'active',
	Inactive = 'inactive',
}



type ChromeWebStoreIconBuilderProps = {
	variant?: ChromeWebStoreIconVariants,
};

const ChromeWebStoreIconBuilder: IconBuilder<ChromeWebStoreIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = ChromeWebStoreIconVariants.Active,
		} = props;

		if (variant === ChromeWebStoreIconVariants.Inactive) {
			return (
				<g>
					<path d="M23.864 1.75H0v21.472h23.864V1.75z" fill="#fff"/>
					<path d="M.884 2.89H22.95v18.145a1.172 1.172 0 0 1-1.171 1.172H2.051A1.172 1.172 0 0 1 .88 21.035V2.89h.004z" fill="#DBDBDD"/>
					<path d="M.896 12.605h22.068v8.418c0 .688-.558 1.245-1.245 1.245H2.141a1.245 1.245 0 0 1-1.245-1.245v-8.418z" fill="#E2E2E2"/>
					<path d="M14.66 5.63H9.192a.945.945 0 1 0 0 1.89h5.466a.945.945 0 1 0 0-1.89z" fill="#fff"/>
					<path d="M9.2 5.66h5.465c.509 0 .927.463.944.97a.945.945 0 0 0-.939-1.057H9.204c-.523 0-.95.422-.956.945v.033c.056-.47.467-.892.951-.892z" fill="#B9B9BD"/>
					<path d="M14.678 7.521H9.21a.962.962 0 0 1-.951-.804c0 .522.423.945.944.945h5.466a.931.931 0 0 0 .93-.945v-.032a.93.93 0 0 1-.922.836z" fill="#E0E1E3"/>
					<path d="M11.837 10.978s5.515-.248 8.441 5.295h-8.91s-.213.067-.55.149c-.61.149-1.64.518-2.566 1.836-.413.855-.855 1.738-.358 3.475-.717-1.213-3.806-6.59-3.806-6.59s2.178-3.945 7.75-4.165z" fill="silver"/>
					<path d="M15.376 18.306c1.05 2.262.262 3.691.262 3.691l-.158.274h5.526c.748-3.53-.748-6.013-.748-6.013s-6.759-.014-8.168 0c1.752.438 2.753 1.262 3.286 2.048z" fill="#CDCDCD"/>
					<path d="M8.212 22.271l-4.119-7.138c-1.72 2.738-1.766 5.297-1.404 7.138h5.523z" fill="#B5B5B5"/>
					<path d="M15.557 22.271a4.17 4.17 0 1 0-7.485 0h7.485z" fill="#fff"/>
					<path d="M14.757 22.271a3.475 3.475 0 1 0-5.886 0h5.886z" fill="#A7A7A7"/>
					<path d="M21.695 22.067H2.144a1.26 1.26 0 0 1-1.26-1.26v.203a1.26 1.26 0 0 0 1.26 1.259h19.55a1.26 1.26 0 0 0 1.26-1.259v-.203a1.26 1.26 0 0 1-1.26 1.26z" fill="#000" opacity=".1"/>
				</g>
			);
		}

		return (
			<g>
				<path d="m23.864 1.25h-23.864v21.472h23.864z" fill="#fff"/>
				<path d="m.884 2.39h22.066v18.145a1.172 1.172 0 0 1 -1.171 1.172h-19.728a1.172 1.172 0 0 1 -1.171-1.172v-18.145z" fill="#dbdbdd"/>
				<path d="m.896 12.105h22.068v8.418c0 .688-.558 1.245-1.245 1.245h-19.578a1.245 1.245 0 0 1 -1.245-1.245z" fill="#e2e2e2"/>
				<path d="m14.66 5.13h-5.468a.945.945 0 1 0 0 1.89h5.466a.945.945 0 1 0 0-1.89z" fill="#fff"/>
				<path d="m9.2 5.16h5.465c.509 0 .927.463.944.97a.945.945 0 0 0 -.939-1.057h-5.466c-.523 0-.95.422-.956.945v.033c.056-.47.467-.892.951-.892z" fill="#b9b9bd"/>
				<path d="m14.678 7.021h-5.468a.962.962 0 0 1 -.951-.804c0 .522.423.945.944.945h5.466a.931.931 0 0 0 .93-.945v-.032a.93.93 0 0 1 -.922.836z" fill="#e0e1e3"/>
				<path d="m11.837 10.478s5.515-.248 8.441 5.295h-8.91s-.213.067-.55.149c-.61.149-1.64.518-2.566 1.836-.413.855-.855 1.738-.358 3.475-.717-1.213-3.806-6.59-3.806-6.59s2.178-3.945 7.75-4.165z" fill="#dd4e41"/>
				<path d="m15.376 17.806c1.05 2.262.262 3.691.262 3.691l-.158.274h5.526c.748-3.53-.748-6.013-.748-6.013s-6.759-.014-8.168 0c1.752.438 2.753 1.262 3.286 2.048z" fill="#ffcd43"/>
				<path d="m8.212 21.771-4.119-7.138c-1.72 2.738-1.766 5.297-1.404 7.138z" fill="#1aa15f"/>
				<path d="m15.557 21.771a4.17 4.17 0 1 0 -7.485 0z" fill="#fff"/>
				<path d="m14.757 21.771a3.475 3.475 0 1 0 -5.886 0z" fill="#4889f4"/>
				<path d="m21.695 21.567h-19.551a1.26 1.26 0 0 1 -1.26-1.26v.203a1.26 1.26 0 0 0 1.26 1.259h19.55a1.26 1.26 0 0 0 1.26-1.259v-.203a1.26 1.26 0 0 1 -1.26 1.26z" fill="#000" opacity=".1"/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ChromeWebStoreIconBuilder;

