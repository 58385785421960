import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	off: {
		id: 'ui.general.off',
	},
	on: {
		id: 'ui.general.on',
	},
});



type Props = {
	state: boolean | null,
};

const OnOrOff: React.FC<Props> = (props) => {
	const {
		state,
	} = props;

	if (state === null) {
		return null;
	}

	return (
		<FormattedMessage {...messages[state ? 'on' : 'off']} />
	);
};



export default OnOrOff;
