import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import CodeValue from '~/components/patterns/values/CodeValue';
import FieldOptionsGroup from '~/components/atoms/forms/components/builders/FieldOptionsGroup';
import HelpHint from '~/components/patterns/hints/HelpHint';

import {
	EXTRACTED_ELEMENT_OPTIONS_CONDENSE_WHITESPACE,
	EXTRACTED_ELEMENT_OPTIONS_DECODE_HTML_ENTITIES,
	EXTRACTED_ELEMENT_OPTIONS_DROP_EMPTY,
	EXTRACTED_ELEMENT_OPTIONS_STRIP_TAGS,
} from '~/model/customElements';



const LIST_OF_OPTIONS = [
	EXTRACTED_ELEMENT_OPTIONS_CONDENSE_WHITESPACE,
	EXTRACTED_ELEMENT_OPTIONS_DECODE_HTML_ENTITIES,
	EXTRACTED_ELEMENT_OPTIONS_STRIP_TAGS,
	EXTRACTED_ELEMENT_OPTIONS_DROP_EMPTY,
] as const;



const hintMessages = defineMessages({
	[EXTRACTED_ELEMENT_OPTIONS_CONDENSE_WHITESPACE]: {
		id: 'ui.customElements.extractionCleanup.condenseWhitespace.description',
	},
	[EXTRACTED_ELEMENT_OPTIONS_DECODE_HTML_ENTITIES]: {
		id: 'ui.customElements.extractionCleanup.decodeHtmlEntities.description',
	},
	[EXTRACTED_ELEMENT_OPTIONS_DROP_EMPTY]: {
		id: 'ui.customElements.extractionCleanup.dropEmpty.description',
	},
	[EXTRACTED_ELEMENT_OPTIONS_STRIP_TAGS]: {
		id: 'ui.customElements.extractionCleanup.stripTags.description',
	},
});

const labelMessages = defineMessages({
	[EXTRACTED_ELEMENT_OPTIONS_CONDENSE_WHITESPACE]: {
		id: 'ui.customElements.extractionCleanup.condenseWhitespace.label',
	},
	[EXTRACTED_ELEMENT_OPTIONS_DECODE_HTML_ENTITIES]: {
		id: 'ui.customElements.extractionCleanup.decodeHtmlEntities.label',
	},
	[EXTRACTED_ELEMENT_OPTIONS_DROP_EMPTY]: {
		id: 'ui.customElements.extractionCleanup.dropEmpty.label',
	},
	[EXTRACTED_ELEMENT_OPTIONS_STRIP_TAGS]: {
		id: 'ui.customElements.extractionCleanup.stripTags.label',
	},
});



const ExtractionCleanup: React.FC = () => {
	return (
		<FieldOptionsGroup
			columnMinWidth={228}
			columnsCount={2}
		>
			{LIST_OF_OPTIONS.map((option) => (
				<CheckboxField
					key={option}
					label={(
						<AttachedElement
							element={(
								<HelpHint
									message={(
										<FormattedMessage
											{...hintMessages[option]}
											values={{
												code: (chunks) => (
													<CodeValue>{chunks}</CodeValue>
												),
											}}
										/>
									)}
								/>
							)}
						>
							<FormattedMessage {...labelMessages[option]} />
						</AttachedElement>
					)}
					name={'string_' + option}
					width={false}
				/>
			))}
		</FieldOptionsGroup>
	);
};



export default ExtractionCleanup;
