import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import HelpHint from '~/components/patterns/hints/HelpHint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import IssueChangeName from './IssueChangeName';
import IssueChangesOverview from '~/components/patterns/issues/IssueChangesOverview';
import IssueIllustration from '~/components/atoms/issues/components/issueDetail/IssueIllustration';
import IssueText from '~/components/atoms/issues/components/issueDetail/IssueText';
import List from '~/components/patterns/lists/List';
import NumberOfAffectedPages, {
	NumberOfAffectedPagesImpact,
} from '~/components/patterns/issues/NumberOfAffectedPages';
import PagesIssueStatusIcon, {
	PagesIssueStatusIconType,
} from '~/components/patterns/icons/PagesIssueStatusIcon';
import SecondaryIssueChangesGroupName from './SecondaryIssueChangesGroupName';

import {
	ISSUE_CHANGE_BROKEN_ENTERED,
	ISSUE_CHANGE_BROKEN_LEFT,
	ISSUE_CHANGE_CLOSED,
	ISSUE_CHANGE_IGNORED,
	ISSUE_CHANGE_OPENED,
	ISSUE_CHANGE_OPENED_NEW_PAGE,
	ISSUE_CHANGE_UNIGNORED,
	LIST_OF_PRIMARY_ISSUE_CHANGES,
	LIST_OF_SECONDARY_ISSUE_CHANGES,
	LIST_OF_SECONDARY_ISSUE_CHANGES_GROUPS,
	getGroupOfIssueChange,
} from '~/model/affectedPagesComparison';



const messages = defineMessages({
	noPrimaryChanges: {
		id: 'ui.historicalIssueDetail.noPrimaryChanges',
	},
	toggleSecondaryChangesHide: {
		id: 'ui.issueChangesTable.toggleSecondary.hide',
	},
	toggleSecondaryChangesShow: {
		id: 'ui.issueChangesTable.toggleSecondary.show',
	},
	toggleSecondaryChangesTooltip: {
		id: 'ui.issueChangesTable.toggleSecondary.tooltip',
	},
});

const ICONS = {
	[ISSUE_CHANGE_BROKEN_ENTERED]: PagesIssueStatusIconType.PagesBroken,
	[ISSUE_CHANGE_BROKEN_LEFT]: PagesIssueStatusIconType.PagesFixed,
	[ISSUE_CHANGE_CLOSED]: PagesIssueStatusIconType.PagesFixed,
	[ISSUE_CHANGE_IGNORED]: PagesIssueStatusIconType.PagesIgnoring,
	[ISSUE_CHANGE_OPENED]: PagesIssueStatusIconType.PagesBroken,
	[ISSUE_CHANGE_OPENED_NEW_PAGE]: PagesIssueStatusIconType.PagesBroken,
	[ISSUE_CHANGE_UNIGNORED]: PagesIssueStatusIconType.PagesIgnoring,
};

const IMPACTS = {
	[ISSUE_CHANGE_BROKEN_ENTERED]: NumberOfAffectedPagesImpact.Negative,
	[ISSUE_CHANGE_BROKEN_LEFT]: NumberOfAffectedPagesImpact.Positive,
	[ISSUE_CHANGE_CLOSED]: NumberOfAffectedPagesImpact.Positive,
	[ISSUE_CHANGE_IGNORED]: NumberOfAffectedPagesImpact.Neutral,
	[ISSUE_CHANGE_OPENED]: NumberOfAffectedPagesImpact.Negative,
	[ISSUE_CHANGE_OPENED_NEW_PAGE]: NumberOfAffectedPagesImpact.Negative,
	[ISSUE_CHANGE_UNIGNORED]: NumberOfAffectedPagesImpact.Neutral,
};

function sumList(list) {
	return list.reduce((total, value) => total + value, 0);
}



const HistoricalIssueHealthImpactExplanationsTable = (props) => {
	const {
		movements,
		onClickCallback,
		scope,
	} = props;

	const primaryChanges = LIST_OF_PRIMARY_ISSUE_CHANGES
		.toArray()
		.filter((issueChange) => movements[issueChange] > 0)
		.map((issueChange: string) => ({
			icon: ICONS[issueChange] && (
				<PagesIssueStatusIcon type={ICONS[issueChange]} />
			),
			label: (
				<IssueChangeName issueChange={issueChange} />
			),
			onClickCallback: (e) => onClickCallback([issueChange], e),
			value: (
				<NumberOfAffectedPages impact={IMPACTS[issueChange]}>
					<FormattedNumber value={movements[issueChange]} />
				</NumberOfAffectedPages>
			),
		}));

	const secondaryChanges = LIST_OF_SECONDARY_ISSUE_CHANGES
		.toArray()
		.filter((issueChange) => movements[issueChange] > 0)
		.map((issueChange: string) => ({
			group: getGroupOfIssueChange(issueChange),
			label: (
				<IssueChangeName issueChange={issueChange} />
			),
			onClickCallback: (e) => onClickCallback([issueChange], e),
			value: (
				<NumberOfAffectedPages impact={NumberOfAffectedPagesImpact.Neutral}>
					<FormattedNumber value={movements[issueChange]} />
				</NumberOfAffectedPages>
			),
		}));

	const secondaryChangesGroups = LIST_OF_SECONDARY_ISSUE_CHANGES_GROUPS
		.toArray()
		.map((issueChangesGroup: string) => ({
			label: (
				<SecondaryIssueChangesGroupName issueChangesGroup={issueChangesGroup} />
			),
			name: issueChangesGroup,
			value: (
				<NumberOfAffectedPages impact={NumberOfAffectedPagesImpact.Neutral}>
					<FormattedNumber
						value={
							sumList(
								[...Object.keys(movements)]
									.filter((issueChange) => getGroupOfIssueChange(issueChange) === issueChangesGroup)
									.map((issueChange) => movements[issueChange]),
							)
						}
					/>
				</NumberOfAffectedPages>
			),
		}));

	return (
		<List>
			{primaryChanges.length === 0 && (
				<IssueText>
					<FormattedMessage {...messages.noPrimaryChanges} />
				</IssueText>
			)}

			<IssueIllustration>
				<IssueChangesOverview
					primaryChanges={primaryChanges}
					secondaryChanges={secondaryChanges}
					secondaryChangesGroups={secondaryChangesGroups}
					toggleSecondaryChangesLink={(visibility) => (
						<AttachedElement
							element={(
								<HelpHint
									message={(
										<FormattedMessage
											{...messages.toggleSecondaryChangesTooltip}
											values={{
												link: (chunks) => (
													<ExternalLink href="https://www.contentkingapp.com/support/issues-change-tracking/">
														{chunks}
													</ExternalLink>
												),
												scope: scope === 'website' ? 'website' : 'segment',
											}}
										/>
									)}
								/>
							)}
						>
							<InternalLink style={InternalLinkStyle.Decent}>
								<FormattedMessage
									{...messages[visibility ? 'toggleSecondaryChangesHide' : 'toggleSecondaryChangesShow']}
									values={{
										scope: scope === 'website' ? 'website' : 'segment',
									}}
								/>
							</InternalLink>
						</AttachedElement>
					)}
				/>
			</IssueIllustration>
		</List>
	);
};



export default React.memo(HistoricalIssueHealthImpactExplanationsTable);
