import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AddMemberHeaderButton from '~/components/logic/buttons/AddMemberHeaderButton';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button from '~/components/patterns/buttons/Button';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderTitleWithNumericValue from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import ImportUserModal from '~/components/app/ImportUserModal';
import MembersOverviewGrid from '~/components/app/MembersOverviewGrid';
import MembersOverviewTable from '~/components/app/MembersOverviewTable';
import OnlyForAdmin from '~/components/app/OnlyForAdmin';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import ViewSwitch from '~/components/logic/ViewSwitch';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useMembersOverviewSectionQuery,
} from './MembersOverviewSection.gql';

import useAccountId from '~/hooks/useAccountId';
import useDataSource from '~/hooks/useDataSource';
import useFilteredView from '~/hooks/useFilteredView';
import useHomeAccountIds from '~/hooks/useHomeAccountIds';
import useNavigateToUserDetail from '~/hooks/useNavigateToUserDetail';
import useOpenModal from '~/hooks/useOpenModal';
import usePollInterval from '~/hooks/usePollInterval';

import {
	IsContentKingClient,
} from '~/config';

import {
	Field,
} from '~/model/members';

import {
	ROLES_LIST,
} from '~/model/users';

import sortArrayByProperty from '~/utilities/sortArrayByProperty';



const messages = defineMessages({
	title: {
		id: 'ui.teamDetail.members.menuLabel',
	},
});



const MembersOverviewSection = (props) => {
	const {
		panel,
	} = props;

	const accountId = useAccountId();
	const homeAccountIds = useHomeAccountIds();

	const isHomeAccount = accountId !== null && homeAccountIds !== null && homeAccountIds.includes(accountId);

	const { data } = useMembersOverviewSectionQuery({
		pollInterval: usePollInterval(30_000),
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const isLoading = (data?.account ?? null) === null;
	const invitations = data?.account?.invitations ?? null;
	const memberships = data?.account?.memberships ?? null;

	const navigateToUserDetail = useNavigateToUserDetail();
	const roles = ROLES_LIST;

	const unfilteredMembers = React.useMemo(
		() => [
			...(memberships ?? []).map((membership) => ({
				displayName: membership.user.displayName,
				email: membership.user.email,
				hasTwoFactorAuthentication: membership.user.hasTwoFactorAuthentication,
				id: membership.user.id,
				isChromeExtensionInstalled: membership.user.isChromeExtensionInstalled,
				membership: {
					hasLimitedWebsitesAccess: membership.hasLimitedWebsitesAccess,
					role: membership.role,
					websites: membership.websites,
				},
				role: membership.role,
				__typename: 'User' as const,
			})),
			...invitations ?? [],
		],
		[
			invitations,
			memberships,
		],
	);

	const {
		filter,
		listAll,
		listFiltered,
		sortBy,
		updateFilter,
		updateSortBy,
	} = useDataSource(
		(filterAndSort) => {
			filterAndSort.addField(Field.TwoFactor, {
				filtering: (users, value) => {
					return users.filter((member) => {
						const has2fa = 'hasTwoFactorAuthentication' in member
							? member.hasTwoFactorAuthentication
							: false;

						return value.includes(has2fa.toString());
					});
				},
				sorting: (members) => {
					return sortArrayByProperty(members, (member) => (
						!('hasTwoFactorAuthentication' in member!)
						|| member.hasTwoFactorAuthentication === false
					));
				},
			});

			filterAndSort.addField(Field.DisplayName, {
				filtering: (members, value) => {
					value = value.toLowerCase();

					return members.filter((member) => {
						return (
							member.displayName.toLowerCase().includes(value)
							|| member.email.toLowerCase().includes(value)
						);
					});
				},
				sorting: (members) => {
					return sortArrayByProperty(members, (member) => member.displayName.toLowerCase());
				},
			});

			filterAndSort.addField(Field.Role, {
				filtering: (members, value) => {
					return members.filter(
						(member) => value.includes(member.role),
					);
				},
				sorting: (members) => {
					return sortArrayByProperty(members, (member) => roles.indexOf(member.role));
				},
			});

			return {
				defaultFilter: {
					[Field.TwoFactor]: ['true', 'false'],
					[Field.Role]: roles,
				},
				defaultSortBy: {
					key: Field.DisplayName,
					direction: true,
				},
			};
		},
		[
			roles,
		],
		unfilteredMembers,
	);

	const filteredView = useFilteredView({
		ready: isLoading === false,
		simple: () => unfilteredMembers.length <= 4,
	});

	const members = filteredView.isActive
		? listFiltered()
		: listAll();

	const enterDetail = React.useCallback(
		(member, e) => {
			navigateToUserDetail.navigate(
				member.email,
				e,
			);
		},
		[
			navigateToUserDetail,
		],
	);

	const openImportUserModal = useOpenModal(
		() => (
			<ImportUserModal />
		),
		[],
	);

	return (
		<ScreenLayout
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						<HeaderTitleWithNumericValue
							title={(
								<FormattedMessage {...messages.title} />
							)}
							value={!isLoading ? members.length : null}
						/>
					</HeaderTitleLayout>
					<HeaderOptionsLayout>
						<HeaderButtonsLayout>
							{isHomeAccount && IsContentKingClient && (
								<OnlyForAdmin canBeHidden={true}>
									<WhenAccountActionAllowed
										accountId={accountId}
										action={GraphQL.ActionWithAccount.ManageInternals}
									>
										<Button
											icon={(
												<BasicIcon type={BasicIconType.Import} />
											)}
											onClick={openImportUserModal}
										>
											Import user
										</Button>
									</WhenAccountActionAllowed>
								</OnlyForAdmin>
							)}

							<AddMemberHeaderButton />

							<ViewSwitch
								isListActive={filteredView.isActive}
								switchToGridRoute={filteredView.deactivateRoute}
								switchToListRoute={filteredView.activateRoute}
							/>
						</HeaderButtonsLayout>
					</HeaderOptionsLayout>
				</ScreenHeader>
			)}
		>
			{panel && (
				<ScreenBody>
					{panel()}
				</ScreenBody>
			)}

			{!panel && !filteredView.isActive && (
				<ScreenBody>
					<MembersOverviewGrid
						accountId={accountId}
						isLoading={isLoading}
						members={members}
					/>
				</ScreenBody>
			)}

			{!panel && filteredView.isActive && (
				<MembersOverviewTable
					accountId={accountId}
					filter={filter}
					isLoading={isLoading}
					members={members}
					onFilterCallback={updateFilter}
					onRowClickCallback={enterDetail}
					onSortCallback={updateSortBy}
					roles={roles}
					sortBy={sortBy}
				/>
			)}
		</ScreenLayout>
	);
};



export default MembersOverviewSection;
