import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import IgnoreOnWebsite from '~/components/atoms/issues/components/ignoringCases/IgnoreOnWebsite';
import IssueTitle from '~/components/logic/issues/IssueTitle';
import ModalLayout from '~/components/logic/issuesIgnoring/ModalLayout';

import useGetIssue from '~/hooks/useGetIssue';
import useOpenedPlatformIssueCategory from '~/hooks/useOpenedPlatformIssueCategory';
import useReloadPlatformIssueCategories from '~/hooks/useReloadPlatformIssueCategories';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	IgnoringScope,
} from '~/model/issuesNew';

import {
	platformScreenIgnoringScopeSelector,
	platformScreenIssueSelector,
} from '~/state/ui/content/selectors';



const messages = defineMessages({
	navigationWebsite: {
		id: 'ui.issueDetail.ignoringModal.header.tabLabel.website',
	},
});



const IgnorePlatformIssueModal: React.FC = () => {
	const websiteId = useWebsiteId();

	const issueCategory = useOpenedPlatformIssueCategory(websiteId);
	const issueName = useSelector(platformScreenIssueSelector);
	const reloadPlatformIssueCategories = useReloadPlatformIssueCategories(websiteId);
	const scope = useSelector(platformScreenIgnoringScopeSelector);

	const issue = useGetIssue(
		issueCategory,
		issueName,
	);

	const listScopes = () => {
		if (issue === null) {
			return [];
		}

		return [
			{
				badge: false,
				content: (
					<IgnoreOnWebsite
						isIgnored={issue.ignoringRuleOnWebsite.isActive}
						isPlatformIssue={true}
						issueName={issue.name}
						reloadCallback={reloadPlatformIssueCategories}
						websiteId={websiteId}
					/>
				),
				isNotReady: false,
				label: (
					<FormattedMessage {...messages.navigationWebsite} />
				),
				name: IgnoringScope.Website,
			},
		];
	};

	return (
		<ModalLayout
			activeScope={scope}
			isLoading={issue === null}
			scopes={listScopes()}
			title={issue !== null && (
				<IssueTitle
					issue={issue}
				/>
			)}
		/>
	);
};



export default IgnorePlatformIssueModal;
