import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import AddressFields from '~/components/app/AddressFields';
import AddressOverview from '~/components/app/AddressOverview';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';

import {
	useUpdateDeliveryAddressInBillingTabMutation,
} from './DeliveryAddressBlock.gql';



const messages = defineMessages({
	title: {
		id: 'ui.teamDetail.billing.deliveryAddressTitle',
	},
});



type Props = {
	accountId: CK.AccountId,
	deliveryAddress: GraphQL.BillingDetailsAddress | null,
};

const DeliveryAddressBlock: React.FC<Props> = (props) => {
	const {
		accountId,
		deliveryAddress,
	} = props;

	const [updateDeliveryAddress] = useUpdateDeliveryAddressInBillingTabMutation();

	const handleSubmit = React.useCallback(
		(values) => {
			return updateDeliveryAddress({
				variables: {
					accountId,
					customerDetails: {
						deliveryAddress: values.name
							? values
							: null,
					},
				},
			});
		},
		[
			accountId,
			updateDeliveryAddress,
		],
	);

	return (
		<EditableFormWrapper
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<AddressOverview
					address={deliveryAddress}
				/>
			</DisplayPart>

			<EditablePart>
				<Form
					clearOnFieldUnmount={true}
					defaultFocus="name"
					defaultValues={{
						address: deliveryAddress?.address,
						city: deliveryAddress?.city,
						country: deliveryAddress?.country,
						name: deliveryAddress?.name,
						postalCode: deliveryAddress?.postalCode,
						state: deliveryAddress?.state,
					}}
					onSuccess={handleSubmit}
				>
					<AddressFields />

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default DeliveryAddressBlock;
