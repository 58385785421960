import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountIsTrialExtensionAllowedQuery,
} from './useAccountIsTrialExtensionAllowed.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsTrialExtensionAllowed(accountId: CK.AccountId | null): GraphQL.Account['isTrialExtensionAllowed'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountIsTrialExtensionAllowedQuery,
		accountId,
	);

	return data?.account?.isTrialExtensionAllowed ?? null;
}



export default useAccountIsTrialExtensionAllowed;
