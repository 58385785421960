import React from 'react';

import {
	EditableFormWrapperContext,
} from '~/components/atoms/forms/basis/EditableFormWrapperBase';
import {
	FieldRowBuilderContext,
} from '~/components/app/FieldRowBuilder';
import FieldRowBuilderDisplaySlot from '~/components/app/FieldRowBuilderDisplaySlot';
import FieldRowBuilderFieldSlot from '~/components/app/FieldRowBuilderFieldSlot';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormFieldsDecorator from '~/components/patterns/forms/others/FormFieldsDecorator';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import matchAndReturn from '~/utilities/matchAndReturn';



export enum WebsiteAdminFetchingSettingNumericFieldRowUnit {
	Byte,
	Millisecond,
	None,
}



type Props = {
	autoValue: number,
	label: string,
	name: string,
	unit: WebsiteAdminFetchingSettingNumericFieldRowUnit,
	value?: number | null,
};

const WebsiteAdminFetchingSettingNumericFieldRow: React.FC<Props> = (props) => {
	const {
		autoValue,
		label,
		name,
		unit,
		value,
	} = props;

	const editableFormWrapperContext = React.useContext(EditableFormWrapperContext);
	const fieldRowBuilderContext = React.useContext(FieldRowBuilderContext);

	const unitLabel = matchAndReturn(unit, {
		[WebsiteAdminFetchingSettingNumericFieldRowUnit.Byte]: 'b',
		[WebsiteAdminFetchingSettingNumericFieldRowUnit.Millisecond]: 'ms',
		[WebsiteAdminFetchingSettingNumericFieldRowUnit.None]: undefined,
	});

	const readableAutoValue = matchAndReturn(unit, {
		[WebsiteAdminFetchingSettingNumericFieldRowUnit.Byte]: ` (${autoValue / 1048576} MB)`,
		[WebsiteAdminFetchingSettingNumericFieldRowUnit.Millisecond]: ` (${autoValue / 1000} s)`,
		[WebsiteAdminFetchingSettingNumericFieldRowUnit.None]: ``,
	});

	const formatValue = (value: number) => unitLabel !== undefined
		? `${value} ${unitLabel}${readableAutoValue}`
		: `${value}`;

	const description = `auto: ${formatValue(autoValue)}`;

	const isEditable = (() => {
		if (fieldRowBuilderContext !== null) {
			return fieldRowBuilderContext.isEditable;
		}

		if (editableFormWrapperContext !== null) {
			return editableFormWrapperContext.editMode;
		}

		return null;
	})();

	const showDescription = (
		isEditable === null
		|| isEditable
	);

	return (
		<FormRow
			description={showDescription ? description : undefined}
			htmlFor={name}
			label={label}
		>
			<FieldStatus name={name}>
				<FieldRowBuilderDisplaySlot>
					{value !== undefined ? formatValue(value ?? autoValue) : ''}
				</FieldRowBuilderDisplaySlot>

				<FieldRowBuilderFieldSlot>
					<FormFieldsDecorator suffix={unitLabel}>
						<TextField
							attributes={{
								min: 0,
							}}
							name={name}
							placeholder="auto"
							trimValue={true}
							type={TextFieldType.Number}
							width={100}
						/>
					</FormFieldsDecorator>
				</FieldRowBuilderFieldSlot>
			</FieldStatus>
		</FormRow>
	);
};



export default WebsiteAdminFetchingSettingNumericFieldRow;
