import {
	atob,
} from '~/globals';



export default function decodeFromBase64(hash: string) {
	return decodeURIComponent(
		escape(
			atob(hash),
		),
	);
}
