import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '../atoms/forms/components/layout/CenteredFormWrapper';
import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';
import ModalContainer from '../atoms/modals/parts/ModalContainer';
import ModalTextSection from '../atoms/modals/parts/ModalTextSection';
import ModalHeader from '../patterns/modals/parts/ModalHeader';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '../atoms/forms/components/SubmitButton';
import ThreeDSecureIframe from '../atoms/payments/ThreeDSecureIframe';

import {
	getRecurly,
} from '~/recurly/main';

import {
	actionTokenSelector,
	cancelAuthorization,
	failAuthorization,
	finishAuthorization,
	isAuthorizedSelector,
} from '~/state/paymentAuthorization';



const messages = defineMessages({
	description: {
		id: 'ui.3dsecure.prompt',
	},
	submitButton: {
		id: 'ui.3dsecure.button',
	},
	title: {
		id: 'ui.3dsecure.title',
	},
});



const select = createSelector(
	actionTokenSelector,
	isAuthorizedSelector,
	(actionToken, isAuthorized) => {
		return {
			actionToken,
			isAuthorized,
		};
	},
);



class AuthorizePaymentModal extends Component {

	constructor(props, context) {
		super(props, context);

		this.threeDSecureRef = React.createRef();

		this._handleCancel = this._handleCancel.bind(this);
	}



	_handleCancel() {
		const {
			dispatch,
		} = this.props;

		dispatch(
			cancelAuthorization(),
		);
	}



	componentDidMount() {
		const {
			actionToken,
			dispatch,
		} = this.props;

		getRecurly((recurly) => {
			const risk = recurly.Risk();
			const threeDSecure = risk.ThreeDSecure({
				actionTokenId: actionToken,
			});

			threeDSecure.on('error', () => {
				dispatch(
					failAuthorization(),
				);
			});

			threeDSecure.on('token', (token) => {
				dispatch(
					finishAuthorization(token.id),
				);
			});

			threeDSecure.attach(
				this.threeDSecureRef.current,
			);
		});
	}



	render() {
		const {
			isAuthorized,
		} = this.props;

		return (
			<ModalContainer
				header={(
					<ModalHeader
						title={(
							<FormattedMessage {...messages.title} />
						)}
					/>
				)}
			>
				<ModalTextSection>
					<RichText>
						<FormattedMessage {...messages.description} />
					</RichText>
				</ModalTextSection>

				<ThreeDSecureIframe
					preloader={(
						<LoadingDots size={LoadingDotsSize.Large} />
					)}
					ref={this.threeDSecureRef}
				/>

				<CenteredFormWrapper>
					<ButtonsLayout>
						<CancelButton onClickCallback={this._handleCancel} />

						<SubmitButton
							disabled={true}
							inactive={true}
							progress={isAuthorized}
						>
							<FormattedMessage {...messages.submitButton} />
						</SubmitButton>
					</ButtonsLayout>
				</CenteredFormWrapper>
			</ModalContainer>
		);
	}

}

AuthorizePaymentModal.propTypes = {};



export default connect(select)(AuthorizePaymentModal);
