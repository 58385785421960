import React from 'react';

import AttachedHeading, {
	AttachedHeadingAlignment,
} from '~/components/patterns/structuredValues/AttachedHeading';
import Caption, {
	CaptionStyle,
} from '~/components/patterns/headings/Caption';



const SegmentPopupLayout = (props) => {
	const {
		filterCriteria,
		link,
		message,
		segmentIdentifier,
		sizeLimit,
	} = props;

	const renderSectionContent = (data) => {
		let content = data.data;

		if (data.caption) {
			content = (
				<AttachedHeading
					heading={(
						<Caption style={CaptionStyle.Light}>
							{data.caption}
						</Caption>
					)}
					headingAlignment={AttachedHeadingAlignment.Left}
				>
					{content}
				</AttachedHeading>
			);
		}

		return (
			<div className="segment-popup-layout__section-content">
				{content}
			</div>
		);
	};

	return (
		<div className="segment-popup-layout">
			<div className="segment-popup-layout__section">
				<div className="segment-popup-layout__section-content">
					{segmentIdentifier}
					{message && (
						<div className="segment-popup-layout__message">
							{message}
						</div>
					)}
				</div>
			</div>
			{filterCriteria && (
				<div className="segment-popup-layout__section segment-popup-layout__section--filter-criteria">
					{renderSectionContent(filterCriteria)}
				</div>
			)}
			{sizeLimit && (
				<div className="segment-popup-layout__section">
					{renderSectionContent(sizeLimit)}
				</div>
			)}
			{link && (
				<div className="segment-popup-layout__section">
					<div className="segment-popup-layout__section-content">
						<div className="segment-popup-layout__link">
							{link}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};



export default SegmentPopupLayout;
