import type CK from '~/types/contentking';

import {
	usePageScreenshotUrlQuery,
} from './usePageScreenshotUrl.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageScreenshotUrl(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
): string | null {
	const { data } = usePageDetailPropertiesQuery(
		usePageScreenshotUrlQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.pageTypeData?.screenshotUrl ?? null;
}



export default usePageScreenshotUrl;
