import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	type WebsiteAlertDefinitionsQuery,
	useWebsiteAlertDefinitionsQuery,
} from './useWebsiteAlertDefinitions.gql';

import usePollInterval from '~/hooks/usePollInterval';

import memoizeById from '~/utilities/memoizeById';



type WebsiteAlertDefinitions = {
	count: number,
	getById: (id: string) => GraphQL.AlertDefinition | null,
	isLoaded: boolean,
	listAll: () => Readonly<Array<GraphQL.AlertDefinition>>,
};

const format = memoizeById(
	(data: WebsiteAlertDefinitionsQuery | undefined) => {
		const alertDefinitions = data?.website?.alertDefinitions ?? null;

		return {
			count: alertDefinitions?.length ?? 0,
			getById: (id: CK.AlertDefinitionId) => alertDefinitions?.find((alertDefinition) => alertDefinition.id === id) ?? null,
			isLoaded: alertDefinitions !== null,
			listAll: () => alertDefinitions ?? [],
		};
	},
);



function useWebsiteAlertDefinitions(
	websiteId: CK.WebsiteId | null,
): WebsiteAlertDefinitions {
	const { data } = useWebsiteAlertDefinitionsQuery({
		pollInterval: usePollInterval(30_000),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return format(websiteId, data);
}



export default useWebsiteAlertDefinitions;
