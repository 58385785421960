import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	CardNumberElement,
	IbanElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';

import type CK from '~/types/contentking';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import ChoosePaymentMethodFields, {
	validateCardFields,
	validatePaymentMethod,
	validateSepaFields,
} from '~/components/app/ChoosePaymentMethodFields';
import Form from '~/components/atoms/forms/basis/Form';
import MarginsList, {
	SIZE_LARGE as MARGINS_SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import PaymentFailureFormError from '~/components/app/PaymentFailureFormError';
import PaymentServicesList from '~/components/atoms/lists/PaymentServicesList';
import StripeContext from '~/components/app/Stripe/StripeContext';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useStoreSignupPaymentMethodMutation,
} from './PaymentMethodSignupModalStep.gql';

import useInitiateSepaDirectDebitSetup from '~/hooks/useInitiateSepaDirectDebitSetup';
import useResolvePaymentStatus from '~/hooks/useResolvePaymentStatus';
import useSignupBillingEntity from '~/hooks/useSignupBillingEntity';

import {
	METHOD_CARD,
	METHOD_SEPA_DEBIT,
} from '~/model/payments';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	previousButton: {
		id: 'ui.pricing.signUp.back',
	},
	saveButton: {
		id: 'ui.pricing.signUp.confirm',
	},
});

const validations = {
	...validateCardFields,
	validatePaymentMethod,
	...validateSepaFields,
};



type Props = {
	accountId: CK.AccountId,
	cancelCallback: () => void,
	email: string | null,
	isModalStepActive: boolean,
	legalDocumentsLinks: React.ReactNode,
	onFinishedSignupCallback: () => void,
	onPaymentMethodSubmitCallback: () => Promise<any>,
	paymentMethods: Readonly<Array<string>>,
};

const PaymentMethodSignupModalStep: React.FC<Props> = (props) => {
	const {
		accountId,
		cancelCallback,
		email,
		isModalStepActive,
		legalDocumentsLinks,
		onFinishedSignupCallback,
		onPaymentMethodSubmitCallback,
		paymentMethods,
	} = props;

	const elements = useElements();
	const initiateSepaDirectDebitSetup = useInitiateSepaDirectDebitSetup();
	const resolvePaymentStatus = useResolvePaymentStatus(accountId);
	const stripe = useStripe();

	const [storeSignupPaymentMethod] = useStoreSignupPaymentMethodMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			const paymentMethodDetails: Record<string, any> = {};

			if (values.paymentMethod === METHOD_CARD) {
				if (elements === null || stripe === null) {
					return;
				}

				const cardElement = elements.getElement(CardNumberElement);

				if (cardElement === null) {
					throw FormError.fromUnknownError(
						new Error(`Can't acquire cardElement data`),
					);
				}

				const stripeAction = await stripe.createPaymentMethod({
					type: 'card',
					card: cardElement,
					billing_details: {
						name: values.cardHolder,
					},
				});

				if (stripeAction.error) {
					throw FormError.fromStripeError(stripeAction.error);
				}

				paymentMethodDetails.paymentMethodId = stripeAction.paymentMethod.id;
			} else if (values.paymentMethod === METHOD_SEPA_DEBIT) {
				if (elements === null || stripe === null) {
					return;
				}

				const sepaDebit = elements.getElement(IbanElement);

				if (email === null) {
					throw FormError.fromUnknownError(
						new Error(`Email isn't loaded yet for storing Sepa payment method`),
					);
				}

				if (sepaDebit === null) {
					throw FormError.fromUnknownError(
						new Error(`Sepa element can't be accessed`),
					);
				}

				const clientSecret = await initiateSepaDirectDebitSetup(accountId);

				if (clientSecret === null) {
					throw FormError.fromUnknownError(
						new Error(`Sepa setup intent client secret can't be generated`),
					);
				}

				const sepaDebitInput = {
					sepa_debit: sepaDebit,
					billing_details: {
						email,
						name: values.sepaName,
					},
				};

				const stripeAction = await stripe.confirmSepaDebitSetup(clientSecret, {
					payment_method: sepaDebitInput,
				});

				if (stripeAction.error !== undefined) {
					throw FormError.fromStripeError(stripeAction.error);
				}

				if (stripeAction.setupIntent.payment_method === null) {
					throw FormError.fromUnknownError(
						new Error(`stripe.confirmSepaDebitSetup didn't produce error, but setupIntent.payment_method is null`),
					);
				}

				paymentMethodDetails.paymentMethodId = stripeAction.setupIntent.payment_method;
			}

			await storeSignupPaymentMethod({
				variables: {
					accountId,
					paymentMethod: {
						type: values.paymentMethod,
						details: paymentMethodDetails,
					},
				},
			});

			await onPaymentMethodSubmitCallback();

			await resolvePaymentStatus();

			onFinishedSignupCallback();
		},
		[
			accountId,
			elements,
			email,
			initiateSepaDirectDebitSetup,
			onFinishedSignupCallback,
			onPaymentMethodSubmitCallback,
			resolvePaymentStatus,
			storeSignupPaymentMethod,
			stripe,
		],
	);

	const defaultValues: Record<string, any> = {};

	if (paymentMethods.length === 1) {
		defaultValues.paymentMethod = paymentMethods[0];
	}

	return (
		<MarginsList size={MARGINS_SIZE_LARGE}>
			<CenteredFormWrapper>
				<Form
					clearOnFieldUnmount={true}
					defaultDataHasChanged={true}
					defaultFocus=""
					defaultValues={defaultValues}
					isDisabled={!isModalStepActive}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<div
						style={{
							minHeight: 320,
						}}
					>
						<ChoosePaymentMethodFields
							paymentMethods={paymentMethods}
						/>

						<PaymentFailureFormError
							errorCode="invalidPaymentMethod"
						/>

						<PaymentFailureFormError
							errorCode="failedPayment"
						/>
					</div>

					<ButtonsLayout>
						<CancelButton
							onClickCallback={cancelCallback}
						>
							<FormattedMessage {...messages.previousButton} />
						</CancelButton>

						<SubmitButton nextStepIcon={true}>
							<FormattedMessage {...messages.saveButton} />
						</SubmitButton>
					</ButtonsLayout>
				</Form>
			</CenteredFormWrapper>

			{legalDocumentsLinks}

			<PaymentServicesList />
		</MarginsList>
	);
};

const PaymentMethodSignupModalStepWrapper: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const billingEntity = useSignupBillingEntity(accountId);

	return (
		<StripeContext billingEntity={billingEntity}>
			<PaymentMethodSignupModalStep {...props} />
		</StripeContext>
	);
};



export default PaymentMethodSignupModalStepWrapper;
