import classNames from 'classnames';
import React from 'react';



export enum SegmentMultiselectTogglerSize {
	Default = 'default',
	Small = 'small',
}



type Props = {
	isOpen: boolean,
	label: React.ReactNode,
	placeholder?: React.ReactNode,
	size?: SegmentMultiselectTogglerSize,
};

const SegmentMultiselectToggler: React.FC<Props> = (props) => {
	const {
		isOpen,
		label,
		placeholder,
		size = SegmentMultiselectTogglerSize.Default,
	} = props;

	const componentClasses = classNames({
		'segment-multiselect-toggler': true,
		'segment-multiselect-toggler--open': isOpen,
		'segment-multiselect-toggler--closed': !isOpen,
		'segment-multiselect-toggler--small': size === SegmentMultiselectTogglerSize.Small,
	});

	const labelClasses = classNames({
		'segment-multiselect-toggler__label': true,
		'segment-multiselect-toggler__label--placeholder': !label,
	});

	return (
		<div className={componentClasses}>
			<div className={labelClasses}>
				{label || placeholder || '-'}
			</div>
		</div>
	);
};



export default SegmentMultiselectToggler;
