import classNames from 'classnames';
import AutoHeight from 'embla-carousel-auto-height';
import useEmblaCarousel, {
	type UseEmblaCarouselType,
} from 'embla-carousel-react';
import React from 'react';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type EmblaCarouselType = NonNullable<UseEmblaCarouselType[1]>;



function useActiveSlide(
	emblaApi: EmblaCarouselType | undefined,
	activeSlideIndex?: number,
	swipe?: boolean,
): void {
	React.useLayoutEffect(
		() => {
			if (emblaApi && activeSlideIndex !== undefined) {
				emblaApi.scrollTo(activeSlideIndex, !swipe);
			}
		},
		[
			activeSlideIndex,
			emblaApi,
			swipe,
		],
	);
}



function useSlideChangeListener(
	emblaApi: EmblaCarouselType | undefined,
	callback?: (index: number) => void,
): void {
	React.useEffect(
		() => {
			if (!emblaApi || !callback) {
				return;
			}

			const listener = () => {
				const index = emblaApi.selectedScrollSnap();

				callback(index);
			};

			emblaApi.on('select', listener);

			return () => {
				emblaApi.off('select', listener);
			};
		},
		[
			callback,
			emblaApi,
		],
	);
}



type Props = {
	activeSlideIndex?: number,
	adaptiveHeight?: boolean,
	animatedHeightChange?: boolean,
	children?: React.ReactNode,
	draggable?: boolean,
	infinite?: boolean,
	onSlideChangeCallback?: (index: number) => void,
	selectableText?: boolean,
	slideGaps?: number,
	swipe?: boolean,
};

const CarouselBlocks: React.FC<Props> = (props) => {
	const {
		activeSlideIndex,
		adaptiveHeight = true,
		animatedHeightChange = false,
		children,
		draggable = true,
		infinite = false,
		onSlideChangeCallback,
		selectableText = false,
		slideGaps = 0,
		swipe = true,
	} = props;

	const [startIndex] = React.useState(activeSlideIndex ?? 0);

	const [emblaRef, emblaApi] = useEmblaCarousel(
		{
			loop: infinite,
			skipSnaps: false,
			watchDrag: draggable,
			startIndex,
		},
		[
			AutoHeight({
				active: adaptiveHeight,
			}),
		],
	);

	const slides = React.Children.toArray(children)
		.filter(notEmpty);

	useActiveSlide(emblaApi, activeSlideIndex, swipe);
	useSlideChangeListener(emblaApi, onSlideChangeCallback);

	const componentClasses = classNames({
		'carousel-blocks': true,
		'carousel-blocks--selectable-text': selectableText,
	});

	const containerClasses = classNames({
		'carousel-blocks__container': true,
		'carousel-blocks__container--animated-height-change': animatedHeightChange,
	});

	return (
		<div
			className={componentClasses}
			ref={emblaRef}
		>
			<div
				className={containerClasses}
				style={{
					marginLeft: `-${slideGaps}px`,
				}}
			>
				{slides.map((slide, index) => {
					return (
						<div
							className="carousel-blocks__slide"
							key={`slide-${index}`}
							style={{
								paddingLeft: `${slideGaps}px`,
							}}
						>
							{slide}
						</div>
					);
				})}
			</div>
		</div>
	);
};



export default CarouselBlocks;
