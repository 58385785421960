import classNames from 'classnames';
import React, {
	Component,
} from 'react';



export enum LabeledValueContentAlignment {
	Left = 'left',
	Stretched = 'stretched',
}

export enum LabeledValueFlow {
	Column = 'column',
	ColumnOnSmall = 'column-on-small',
	Row = 'row',
	RowOnSmall = 'row-on-small',
}

export enum LabeledValueSize {
	Default = 'default',
	Small = 'small',
}

export enum LabeledValueStyle {
	Dark = 'dark',
	Light = 'light',
}

type Props = {
	children?: React.ReactNode,
	contentAlignment?: LabeledValueContentAlignment,
	/** Flow of elements */
	flow?: LabeledValueFlow,
	/** Attached label */
	label: React.ReactNode,
	labelWidth?: React.CSSProperties['flexBasis'],
	maxWidth?: React.CSSProperties['maxWidth'],
	onClickCallback?: (e: Event) => void,
	/** Size of title */
	size?: LabeledValueSize,
	style?: LabeledValueStyle,
};



class LabeledValue extends Component<Props> {

	static defaultProps = {
		contentAlignment: LabeledValueContentAlignment.Stretched,
		flow: LabeledValueFlow.Column,
		size: LabeledValueSize.Default,
		style: LabeledValueStyle.Dark,
	};



	constructor(props, context) {
		super(props, context);

		this._handleClick = this._handleClick.bind(this);
	}



	_handleClick(e) {
		e.preventDefault();

		const {
			onClickCallback,
		} = this.props;

		if (!onClickCallback) {
			return false;
		}

		onClickCallback(e);
	}



	render() {
		const {
			contentAlignment,
			flow,
			children,
			label,
			labelWidth,
			maxWidth,
			onClickCallback,
			size,
			style,
		} = this.props;

		const componentClasses = classNames({
			'labeled-value': true,
			'labeled-value--column-flow': flow === LabeledValueFlow.Column,
			'labeled-value--column-on-small-flow': flow === LabeledValueFlow.ColumnOnSmall,
			'labeled-value--row-flow': flow === LabeledValueFlow.Row,
			'labeled-value--row-on-small-flow': flow === LabeledValueFlow.RowOnSmall,
			'labeled-value--small': size === LabeledValueSize.Small,
			'labeled-value--is-clickable': !!onClickCallback,
			'labeled-value--left-aligned-content': contentAlignment === LabeledValueContentAlignment.Left,
			'labeled-value--stretched-content': contentAlignment === LabeledValueContentAlignment.Stretched,
			['labeled-value--style-' + style]: true,
		});

		const cssStyle: React.CSSProperties = {};
		const labelStyle: React.CSSProperties = {};

		if (maxWidth) {
			cssStyle.maxWidth = maxWidth;
		}

		if (labelWidth) {
			labelStyle.flexBasis = labelWidth;
		}

		return (
			<div
				className={componentClasses}
				onClick={this._handleClick}
				style={cssStyle}
			>
				<h4
					className="labeled-value__label"
					style={labelStyle}
				>
					{label}
				</h4>

				<div>
					{React.Children.map(
						children,
						(child) => {
							if (!child) {
								return false;
							}

							return (
								<div className="labeled-value__row">
									<div>
										{child}
									</div>
								</div>
							);
						},
					)}
				</div>
			</div>
		);
	}

}



export default LabeledValue;
