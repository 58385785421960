import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Optional card icon */
	icon?: React.ReactNode,
	onClickCallback?: () => void,
	/** Title of incident */
	title: React.ReactNode,
};



const IncidentCard: React.FC<Props> = (props) => {
	const {
		icon,
		onClickCallback,
		title,
	} = props;

	const clickHandler = (e) => {
		e.preventDefault();

		if (!onClickCallback) {
			return false;
		}

		onClickCallback();
	};

	const componentClasses = classNames({
		'incident-card': true,
		'incident-card--is-clickable': onClickCallback,
	});

	return (
		<div
			className={componentClasses}
			onClick={clickHandler}
		>
			{icon && (
				<div className="incident-card__icon">{icon}</div>
			)}

			<div className="incident-card__title">
				{title}
			</div>
		</div>
	);
};



export default IncidentCard;
