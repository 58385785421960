import React from 'react';

import styles from './BadgeWithValue.module.scss';



type Props = {
	badge: React.ReactNode,
	children: React.ReactNode,
};

const BadgeWithValue: React.FC<Props> = (props) => {
	const {
		badge,
		children,
	} = props;

	return (
		<span className={styles.component}>
			<span className={styles.badge}>
				{badge}
			</span>
			<span className={styles.value}>
				{children}
			</span>
		</span>
	);
};



export default BadgeWithValue;
