import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	Link,
} from 'react-router5';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import FullUserName from '~/components/app/FullUserName';
import LinkTarget from '~/components/logic/LinkTarget';
import UserOverview from '~/components/patterns/users/UserOverview';
import UserRoleAndAccess from '~/components/app/UserRoleAndAccess';
import WithPermission from '~/components/logic/access/WithPermission';

import useUserAccountMembership from '~/hooks/useUserAccountMembership';
import useUserEmail from '~/hooks/useUserEmail';
import useUserFirstName from '~/hooks/useUserFirstName';
import useUserLastName from '~/hooks/useUserLastName';

import {
	ObjectType,
} from '~/model/permissions';

import {
	extractInitials,
} from '~/model/users';



const messages = defineMessages({
	changeRoleLink: {
		id: 'ui.teamDetail.members.roles.change',
	},
	label: {
		id: 'ui.members.detail.label',
	},
});



type Props = {
	accountId: CK.AccountId,
	userId: string,
};

const MemberProfile: React.FC<Props> = (props) => {
	const {
		accountId,
		userId,
	} = props;

	const userAccountMembership = useUserAccountMembership(accountId, userId);
	const userEmail = useUserEmail(userId);
	const userFirstName = useUserFirstName(userId);
	const userLastName = useUserLastName(userId);

	if (
		userAccountMembership === null
		|| userEmail === null
	) {
		return null;
	}

	return (
		<UserOverview
			avatar={{
				email: userEmail,
				placeholder: extractInitials(userEmail),
			}}
			changeRoleLink={(
				<WithPermission
					action={GraphQL.ActionWithAccountMembership.ChangeRole}
					objectId={`${accountId}/${userEmail}`}
					objectType={ObjectType.AccountMembership}
				>
					{({ isAllowed }) => (
						<LinkTarget
							extraRouteParams={{
								action: 'change_role',
							}}
						>
							{({ routeName, routeParams }) => {
								let link = (
									<FormattedMessage {...messages.changeRoleLink} />
								);

								if (isAllowed.yes) {
									link = (
										<Link
											routeName={routeName}
											routeParams={routeParams}
										>
											{link}
										</Link>
									);
								}

								return link;
							}}
						</LinkTarget>
					)}
				</WithPermission>
			)}
			label={(
				<FormattedMessage {...messages.label} />
			)}
			name={(
				<FullUserName
					email={userEmail}
					firstName={userFirstName}
					lastName={userLastName}
				/>
			)}
			role={(
				<UserRoleAndAccess
					userAccountMembership={userAccountMembership}
				/>
			)}
		/>
	);
};



export default MemberProfile;
