import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import EmailSettingsGroup from '~/components/logic/memberDetail/formGroups/EmailSettingsGroup';
import FormsList from '~/components/atoms/lists/FormsList';
import MemberDetailContextActions from '~/components/app/MemberDetailContextActions';
import MemberProfile from '~/components/app/MemberProfile';
import PersonalSettingsGroup from '~/components/logic/memberDetail/formGroups/PersonalSettingsGroup';
import Sections from '~/components/logic/lego/Sections';
import UserAssignedWebsitesForm from '~/components/app/UserAssignedWebsitesForm';

import useAccountId from '~/hooks/useAccountId';
import useUserRole from '~/hooks/useUserRole';

import {
	selectedMemberDetailSelector,
} from '~/state/ui/selectors';

import matchAndReturn from '~/utilities/matchAndReturn';



enum Section {
	AssignedWebsites = 'assignedWebsites',
	EmailSettings = 'emailSettings',
	PersonalSettings = 'personalSettings',
}

const SectionSorting = [
	Section.PersonalSettings,
	Section.EmailSettings,
	Section.AssignedWebsites,
];

const SectionRoutes = {
	[Section.AssignedWebsites]: 'account.members.member.assignedWebsites',
	[Section.EmailSettings]: 'account.members.member.emailSettings',
	[Section.PersonalSettings]: 'account.members.member',
};



const messages = defineMessages({
	actionsTitle: {
		id: 'ui.general.actions',
	},
	[Section.AssignedWebsites]: {
		id: 'ui.members.formGroups.assignedWebsites',
	},
	[Section.EmailSettings]: {
		id: 'ui.members.formGroups.emailSettings',
	},
	[Section.PersonalSettings]: {
		id: 'ui.members.formGroups.personalSettings',
	},
});



const MemberDetailPanel: React.FC = () => {
	const accountId = useAccountId();

	const memberDetail = useSelector(selectedMemberDetailSelector);

	const legacyUserId = memberDetail ? btoa(memberDetail.get('id')) : null;
	const userRole = useUserRole(accountId, legacyUserId);

	const sections = React.useMemo(
		() => {
			return SectionSorting.filter(
				(section) => {
					if (section === Section.AssignedWebsites) {
						return (
							userRole === GraphQL.UserRole.Editor
							|| userRole === GraphQL.UserRole.Viewer
						);
					}

					return true;
				},
			);
		},
		[
			userRole,
		],
	);

	if (
		accountId === null
		|| legacyUserId === null
	) {
		return null;
	}

	return (
		<Sections
			actions={(
				<MemberDetailContextActions
					accountId={accountId}
					allowChangeOfRole={true}
					userId={legacyUserId}
				/>
			)}
			getSectionRoute={(section) => SectionRoutes[section]}
			overview={(
				<MemberProfile
					accountId={accountId}
					userId={legacyUserId}
				/>
			)}
			renderSectionContent={(section) => matchAndReturn(section, {
				[Section.AssignedWebsites]: (
					<FormsList>
						<UserAssignedWebsitesForm
							userAccountId={accountId}
							userId={legacyUserId}
						/>
					</FormsList>
				),
				[Section.EmailSettings]: (
					<EmailSettingsGroup
						accountId={accountId}
						isForCurrentUser={false}
						legacyUserId={legacyUserId}
					/>
				),
				[Section.PersonalSettings]: (
					<PersonalSettingsGroup
						isForCurrentUser={false}
						userId={legacyUserId}
					/>
				),
			})}
			renderSectionTitle={(section) => (
				<FormattedMessage {...messages[section]} />
			)}
			sections={sections}
		/>
	);
};



export default MemberDetailPanel;
