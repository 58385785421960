import React from 'react';

import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';
import Row, {
	TYPE_PRIMARY as ROW_TYPE_PRIMARY,
	TYPE_SECONDARY as ROW_TYPE_SECONDARY,
	TYPE_TERNARY as ROW_TYPE_TERNARY,
} from './Row.part';



export const TYPE_PRIMARY = ROW_TYPE_PRIMARY;
export const TYPE_SECONDARY = ROW_TYPE_SECONDARY;
export const TYPE_TERNARY = ROW_TYPE_TERNARY;



type Props = {
	children: React.ReactNode,
	label: React.ReactNode,
	onClickCallback?: (e: any) => void,
	type:
		| typeof TYPE_PRIMARY
		| typeof TYPE_SECONDARY
		| typeof TYPE_TERNARY,
	value?: React.ReactNode,
};

const CollapsibleRow: React.FC<Props> = (props) => {
	const {
		children,
		label,
		onClickCallback,
		type,
		value,
	} = props;

	const [collapsed, setCollapsed] = React.useState(true);

	const _toggleCollapsibleRow = (e) => {
		setCollapsed(!collapsed);

		if (onClickCallback) {
			onClickCallback(e);
		}
	};

	return (
		<Row
			icon={(
				<DropdownIcon
					isActive={!collapsed}
					style={DropdownIconStyle.Greyscale}
				/>
			)}
			isCollapsed={collapsed}
			isCollapsible={true}
			label={label}
			onClickCallback={_toggleCollapsibleRow}
			type={type}
			value={value}
		>
			{children}
		</Row>
	);
};



export default CollapsibleRow;
