import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import StatusLabel, {
	StatusLabelType,
} from '~/components/patterns/statuses/StatusLabel';
import WebsiteCapacityOverview from '~/components/patterns/websites/WebsiteCapacityOverview';
import WebsiteCard, {
	WebsiteCardStatus,
} from '~/components/patterns/websites/WebsiteCard';
import WebsiteFavicon from '~/components/logic/websites/WebsiteFavicon';
import WebsiteIntegrationStatusBadge, {
	WebsiteIntegrationStatusBadgeSize,
} from '~/components/app/WebsiteIntegrationStatusBadge';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



const RECORD_STATUSES_MAPPING = {
	[ GraphQL.WebsiteProblem.CapacityReached ]: WebsiteCardStatus.Critical,
	[ GraphQL.WebsiteProblem.NearCapacity ]: WebsiteCardStatus.Warning,
	[ GraphQL.WebsiteProblem.Paused ]: WebsiteCardStatus.Paused,
};



const messages = defineMessages({
	monitoringPaused: {
		id: 'ui.plan.monitoringPaused',
	},
	nearPlanCapacity: {
		id: 'ui.websites.capacity.limitNear.label',
		defaultMessage: 'near plan capacity',
	},
	pages: {
		id: 'ui.websites.list.pages',
	},
	reachedPlanCapacity: {
		id: 'ui.websites.capacity.limitReached.label',
		defaultMessage: 'at plan capacity',
	},
});



type Props = {
	onWebsiteClickCallback: (websiteId: CK.WebsiteId, event: React.MouseEvent) => void,
	website: {
		displayName: string,
		id: CK.WebsiteId,
		pageCapacity: number,
		pagesCount: number,
		problem: GraphQL.WebsiteProblem | null,
	},
};

const WebsitesOverviewGridCard: React.FC<Props> = (props) => {
	const {
		onWebsiteClickCallback,
		website,
	} = props;

	const websiteIntegrations = useWebsiteIntegrations(website.id);

	const handleClick = React.useCallback(
		(event) => {
			onWebsiteClickCallback(website.id, event);
		},
		[
			onWebsiteClickCallback,
			website,
		],
	);

	const integrationIcons: Array<React.ReactElement> = [];

	websiteIntegrations.listAll().forEach((integration) => {
		if (
			integration.status === GraphQL.WebsiteIntegrationStatus.FeatureNotEnabled
			|| integration.status === GraphQL.WebsiteIntegrationStatus.NotAvailable
		) {
			return;
		}

		integrationIcons.push(
			<WebsiteIntegrationStatusBadge
				integrationType={integration.type}
				key={integration.type}
				size={WebsiteIntegrationStatusBadgeSize.Small}
				status={integration.status}
			/>,
		);
	});

	const renderPlanOverview = () => {
		if (website.problem === GraphQL.WebsiteProblem.Paused) {
			return (
				<StatusLabel type={StatusLabelType.Disabled}>
					<FormattedMessage {...messages.monitoringPaused} />
				</StatusLabel>
			);
		}

		if (website.problem === GraphQL.WebsiteProblem.NearCapacity) {
			return (
				<StatusLabel type={StatusLabelType.Warning}>
					<FormattedMessage {...messages.nearPlanCapacity} />
				</StatusLabel>
			);
		}

		if (website.problem === GraphQL.WebsiteProblem.CapacityReached) {
			return (
				<StatusLabel type={StatusLabelType.Alert}>
					<FormattedMessage {...messages.reachedPlanCapacity} />
				</StatusLabel>
			);
		}

		return false;
	};

	return (
		<WebsiteCard
			capacityInfo={(
				<WebsiteCapacityOverview
					capacity={website.pageCapacity}
					pagesCount={website.pagesCount}
					unit={(
						<FormattedMessage {...messages.pages} />
					)}
				/>
			)}
			details={renderPlanOverview()}
			favicon={(
				<WebsiteFavicon
					websiteId={website.id}
				/>
			)}
			integrationIcons={integrationIcons}
			name={website.displayName}
			onClick={handleClick}
			status={website.problem !== null ? RECORD_STATUSES_MAPPING[website.problem] : WebsiteCardStatus.Default}
		/>
	);
};



export default WebsitesOverviewGridCard;
