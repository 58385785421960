import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AuthButtonsLayout from '~/components/atoms/auth/AuthButtonsLayout';
import AuthFormLayout, {
	type AuthFormLayoutRef,
} from '~/components/atoms/auth/AuthFormLayout';
import AuthFormRow from '~/components/atoms/auth/AuthFormRow';
import AuthTitle from '~/components/atoms/auth/AuthTitle';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import FieldStatus, {
	FieldStatusErrorMessageSize,
} from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages, {
	FormErrorMessagesStyle,
} from '~/components/app/FormErrorMessages';
import GlobalFormMessagePattern, {
	GlobalFormMessagePatternStyle,
} from '~/components/atoms/forms/basis/GlobalFormMessagePattern';
import SubmitButton, {
	SubmitButtonSize,
} from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldSize,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	useRequestPasswordResetMutation,
} from '~/components/app/RequestPasswordResetForm.gql';

import {
	validateField,
} from '~/components/app/validations';

import useUrlState from '~/hooks/useUrlState';

import {
	LoginScreenView,
} from '~/model/loginScreen';

import {
	navigate,
} from '~/routing/router';

import FormError from '~/utilities/FormError';
import touchSupported from '~/utilities/touchSupported';



const messages = defineMessages({
	backToLogin: {
		id: 'ui.requestPasswordResetForm.backToLogin',
	},
	email: {
		id: 'ui.requestPasswordResetForm.email',
	},
	invalidTokenError: {
		id: 'ui.requestPasswordResetForm.errors.invalidToken',
	},
	submit: {
		id: 'ui.requestPasswordResetForm.submit',
	},
	title: {
		id: 'ui.requestPasswordResetForm.title',
	},
	unknownEmailError: {
		id: 'ui.requestPasswordResetForm.errors.unknownEmail',
	},
});

const validations = {
	validateEmail: validateField(
		'email',
		(f) => ([
			f.validateNonEmpty(),
			f.validateEmail(),
			f.customGlobal({
				field: 'email',
				globalRule: 'unknownEmail',
				message: (
					<FormattedMessage {...messages.unknownEmailError} />
				),
			}),
		]),
	),
};



type Props = {
	defaultEmail?: string,
	isDisabled?: boolean,
	onEmailChange: (email: string) => void,
	setView: (view: LoginScreenView) => void,
};

const RequestPasswordResetForm: React.FC<Props> = (props) => {
	const {
		defaultEmail,
		isDisabled,
		onEmailChange,
		setView,
	} = props;

	const urlState = useUrlState();
	const layoutRef = React.useRef<AuthFormLayoutRef | null>(null);
	const [invalidToken, setInvalidToken] = React.useState(false);

	const [requestPasswordResetMutation] = useRequestPasswordResetMutation();

	React.useEffect(
		() => {
			if (
				urlState.params.error === 'expired_token'
				|| urlState.params.error === 'invalid_token'
			) {
				setInvalidToken(true);

				navigate(
					urlState.name,
					{
						...urlState.params,
						error: null,
					},
					{
						replace: true,
					},
				);
			}
		},
		[
			urlState,
		],
	);

	const handleChange = React.useCallback(
		(field, value) => {
			if (field === 'email') {
				onEmailChange(value);
			}
		},
		[
			onEmailChange,
		],
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				setInvalidToken(false);

				await requestPasswordResetMutation({
					variables: {
						email: values.email,
					},
				});

				setView(LoginScreenView.PasswordLostRequested);
			} catch (error) {
				layoutRef.current?.shake();

				const formError = FormError.fromApolloError(error, {
					unknownEmail: values.email,
				});

				throw formError;
			}
		},
		[
			requestPasswordResetMutation,
			setView,
		],
	);

	return (
		<AuthFormLayout
			header={(
				<AuthTitle>
					<FormattedMessage {...messages.title} />
				</AuthTitle>
			)}
			ref={layoutRef}
		>
			<Form
				defaultFocus={touchSupported ? null : 'email'}
				defaultValues={{
					email: defaultEmail,
				}}
				isDisabled={isDisabled}
				onChangeCallback={handleChange}
				onSuccess={handleSubmit}
				validations={validations}
			>
				{invalidToken && (
					<GlobalFormMessagePattern
						style={GlobalFormMessagePatternStyle.AuthForm}
					>
						<FormattedMessage {...messages.invalidTokenError} />
					</GlobalFormMessagePattern>
				)}

				<FormErrorMessages
					style={FormErrorMessagesStyle.AuthForm}
				/>

				<AuthFormRow
					id="email"
					label={(
						<FormattedMessage {...messages.email} />
					)}
				>
					<FieldStatus
						errorMessageSize={FieldStatusErrorMessageSize.Medium}
						name="validateEmail"
						showIcon={false}
					>
						<TextField
							autoComplete={TextFieldAutocomplete.Email}
							name="email"
							resetButton={false}
							size={TextFieldSize.Large}
							type={TextFieldType.Email}
							width="100%"
						/>
					</FieldStatus>
				</AuthFormRow>

				<AuthButtonsLayout
					leftButton={(
						<SubmitButton
							allowNoChanges={true}
							size={SubmitButtonSize.XLarge}
						>
							<FormattedMessage {...messages.submit} />
						</SubmitButton>
					)}
					rightButton={(
						<Button
							onClick={() => setView(LoginScreenView.Login)}
							size={ButtonSize.Medium}
							style={ButtonStyle.Link}
						>
							<FormattedMessage {...messages.backToLogin} />
						</Button>
					)}
				/>
			</Form>
		</AuthFormLayout>
	);
};



export default RequestPasswordResetForm;
