export default <const>{
	'alerts.types.canonical_link_changed.title': `Canonical links changed`,
	'alerts.types.content_change.h1.title': `H1 headings changed`,
	'alerts.types.content_change.meta_description.title': `Meta descriptions changed`,
	'alerts.types.content_change.title.title': `Page titles changed`,
	'alerts.types.enrichment_field_changed.title': `{property, select,
		other {{propertyChangeTypesId, select,
			added {{propertyName} was added}
			added__changed {{propertyName} was added or changed}
			added__changed__removed {{propertyName} was added, removed or changed}
			added__removed {{propertyName} was added or removed}
			changed {{propertyName} was changed}
			changed__removed {{propertyName} was removed or changed}
			changedToFalse {{propertyName} changed to No}
			changedToTrue {{propertyName} changed to Yes}
			decreased {{propertyName} decreased}
			increased {{propertyName} increased}
			removed {{propertyName} was removed}
			other {{propertyName} changed}
		}}
	}`,
	'alerts.types.hreflangs_changed.title': `Hreflang implementation changed`,
	'alerts.types.issue_opened.analytics/analytics_missing.title': `Analytics tracking removed`,
	'alerts.types.issue_opened.analytics/visual_analytics_missing.title': `Visual Analytics tracking removed`,
	'alerts.types.issue_opened.canonical_link/incorrectly_canonicalized.title': `Off-page canonical links on non-indexable pages`,
	'alerts.types.issue_opened.canonical_link/points_to_unindexable.title': `Canonical link target is not indexable`,
	'alerts.types.issue_opened.h1/missing.title': `H1 headings were removed`,
	'alerts.types.issue_opened.links/broken.title': `Links became broken`,
	'alerts.types.issue_opened.lighthouse/cls.title': `Cumulative Layout Shift became too large`,
	'alerts.types.issue_opened.lighthouse/fcp.title': `First Contentful Paint became too long`,
	'alerts.types.issue_opened.lighthouse/lcp.title': `Largest Contentful Paint became too long`,
	'alerts.types.issue_opened.lighthouse/performance.title': `Performance score became too low`,
	'alerts.types.issue_opened.lighthouse/si.title': `Speed Index became too high`,
	'alerts.types.issue_opened.lighthouse/tbt.title': `Total Blocking Time became too long`,
	'alerts.types.issue_opened.lighthouse/tti.title': `Time to Interactive became too long`,
	'alerts.types.issue_opened.meta_description/missing.title': `Meta descriptions were removed`,
	'alerts.types.issue_opened.title/missing.title': `Page titles were removed`,
	'alerts.types.page_type_change.title': `Pages changed type`,
	'alerts.types.pages_became_non_indexable.title': `Pages became non-indexable`,
	'alerts.types.pages_entered_or_left_segment.title': `Pages {segmentChangeType, select,
		entered {entered}
		entered_or_left {entered or left}
		left {left}
		other {}
	} segment`,
	'alerts.types.platform_issue_opened.domain/hostname_noncanonical_present.title': `Non-canonical hostname domain variant stopped redirecting`,
	'alerts.types.platform_issue_opened.domain/https_certificate_invalid.title': `SSL certificate became invalid`,
	'alerts.types.platform_issue_opened.domain/https_noncanonical_present.title': `Non-canonical protocol domain variant stopped redirecting`,
	'alerts.types.platform_issue_opened.web_vitals_origin_summary/core_web_vitals.title': `Website stopped passing Core Web Vitals assessment`,
	'alerts.types.property_changed.title': `{property, select,
		other {{propertyChangeTypesId, select,
			added {{propertyName} was added}
			added__changed {{propertyName} was added or changed}
			added__changed__removed {{propertyName} was added, removed or changed}
			added__removed {{propertyName} was added or removed}
			changed {{propertyName} was changed}
			changed__removed {{propertyName} was removed or changed}
			changedToFalse {{propertyName} changed to No}
			changedToTrue {{propertyName} changed to Yes}
			decreased {{propertyName} decreased}
			increased {{propertyName} increased}
			removed {{propertyName} was removed}
			other {{propertyName} changed}
		}}
	}`,
	'alerts.types.robots_txt_became_inaccessible.title': `Robots.txt became inaccessible`,
	'alerts.types.robots_txt_changed.title': `Robots.txt changed`,
	'alerts.types.ssl_certificate_expiring_soon.title': `SSL certificate expiring soon`,
	'alerts.types.website_has_trouble_responding.title': `Website has trouble responding`,
};
