import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SegmentIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				opacity="0.799999952"
				stroke="none"
				strokeWidth="1"
			>
				<g
					fill={color}
					fillRule="nonzero"
					id="segment"
					transform="translate(1.863593, 0.500000)"
				>
					<g id="Icons/segment-16">
						<path
							d="M11.4864074,10.1538462 C17.0942218,10.1538462 16.309485,10.1574097 21.6402536,10.1538462 C21.6402536,4.54603177 17.0942218,0 11.4864074,0 C11.4864074,3.72307701 11.5004187,5.64444579 11.4864074,10.1538462 Z"
							id="Path"
						>
						</path>
						<g
							id="Group-2"
							transform="translate(0.000000, 1.363465)"
						>
							<path
								d="M9.30928797,-1.87883896e-16 C4.09661785,0.429214209 0,4.79581038 0,10.1192229 C0,15.7270373 4.54603177,20.2730691 10.1538462,20.2730691 C15.4762096,20.2730691 19.8421339,16.1780657 20.2728149,10.9668627 C15.8639398,10.9688793 15.7274214,10.9653768 9.30769231,10.9653768 C9.32287767,6.07810907 9.3133763,3.4707923 9.30928797,-1.87883896e-16 Z"
								id="Combined-Shape"
							>
							</path>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SegmentIconBuilder;

