import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const UploadIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m24 24h-24v-24h24z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="m5.5 11.5v5.5995799c0 .7737321.64638889 1.4004201 1.44444444 1.4004201h10.11111116c.7980555 0 1.4444444-.626688 1.4444444-1.4004201v-5.5995799" />
					<path d="m9 7.5 3-3 3 3" />
					<path d="m12 5v9" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default UploadIconBuilder;

