import classNames from 'classnames';
import React from 'react';

import styles from './CellDragHandler.module.scss';



type Props = {
	isActive?: boolean,
	onMouseDown?: (e: any) => void,
	onTouchStart?: (e: any) => void,
	position?: {},
	tableHeight?: number,
};



const CellDragHandler: React.FC<Props> = (props) => {
	const {
		isActive,
		onMouseDown,
		onTouchStart,
		position,
		tableHeight,
	} = props;

	return (
		<div
			className={classNames({
				[styles.component]: true,
				[styles.isActive]: isActive,
			})}
			onMouseDown={onMouseDown}
			onTouchStart={onTouchStart}
			style={{
				height: isActive ? tableHeight : undefined,
				...position,
			}}
		/>
	);
};



export default CellDragHandler;
