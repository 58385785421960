import React from 'react';



type Props = {
	children: React.ReactNode,
};



const IllustrationChartLayout: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="illustration-chart-layout">
			{children}
		</div>
	);
};



export default IllustrationChartLayout;
