import type CK from '~/types/contentking';

import useBatchContextPlatform from '~/hooks/useBatchContextPlatform';
import usePollInterval from '~/hooks/usePollInterval';



type Options = {
	skip?: boolean,
};



function usePlatformQuery<TQueryHookReturnType>(
	queryHook: (options: any) => TQueryHookReturnType,
	websiteId: CK.WebsiteId | null,
	options: Options = {},
): TQueryHookReturnType {
	return queryHook({
		context: useBatchContextPlatform(websiteId),
		pollInterval: usePollInterval(10000),
		skip: options.skip || websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});
}



export default usePlatformQuery;
