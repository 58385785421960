import React from 'react';

import LabeledValue, {
	LabeledValueContentAlignment,
	LabeledValueFlow,
} from './LabeledValue';
import LabeledValuesList from './LabeledValuesList';
import SimpleBox, {
	SimpleBoxBackground,
} from '~/components/patterns/boxes/SimpleBox';



type Props = {
	children?: React.ReactNode,
	items: Array<{
		key?: number | string,
		label: React.ReactNode,
		value: React.ReactNode,
	}>,
	labelWidth?: React.CSSProperties['flexBasis'],
};



const LabeledValuesBox: React.FC<Props> = (props) => {
	const {
		items,
		labelWidth,
	} = props;

	return (
		<SimpleBox
			background={SimpleBoxBackground.Dark}
			paddingSize={2}
		>
			<LabeledValuesList>
				{items.map((item, index) => (
					<LabeledValue
						contentAlignment={LabeledValueContentAlignment.Left}
						flow={LabeledValueFlow.Row}
						key={item.key ?? index}
						label={item.label}
						labelWidth={labelWidth}
					>
						{item.value}
					</LabeledValue>
				))}

			</LabeledValuesList>
		</SimpleBox>
	);
};



export default LabeledValuesBox;
