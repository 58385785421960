import React from 'react';
import {
	defineMessage,
} from 'react-intl';

import CloudflareStreamVideo from '~/components/patterns/videos/CloudflareStreamVideo';
import Copy from '~/components/logic/Copy';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';
import RichText from '~/components/patterns/typography/RichText';
import WidthLimiter from '~/components/patterns/utils/WidthLimiter';



const message = defineMessage({
	id: 'ui.alerts.overview.blankSlate',
});



const BlankSlateMessage: React.FC = () => {
	return (
		<WidthLimiter width={750}>
			<InterfaceMessage fullwidth={true}>
				<RichText>
					<Copy {...message} />
					<CloudflareStreamVideo
						cloudflareStreamId="58276e821126a3050c20ea691093e71b"
						height={360}
						posterSrc="https://www.contentkingapp.com/media/video-posters/alerts/alerts@2x.png"
						width={637}
					/>
				</RichText>
			</InterfaceMessage>
		</WidthLimiter>
	);
};



export default BlankSlateMessage;
