import classNames from 'classnames';
import React, {
	Component,
} from 'react';
import {
	Grid,
} from 'react-virtualized';
import throttle from 'lodash/throttle';

import scrollbarSize from 'dom-helpers/scrollbarSize';



type Props = {
	cellRenderer: (any) => React.ReactNode,
	columnCount: number,
	columnWidth: number | ((input: { index: number }) => number),
	height: number,
	overscanColumnCount?: number,
	onSectionRendered?: (any) => void,
	onScrollCallback?: (any) => void,
	rowCount: number,
	rowHeight: number | ((input: { index: number }) => number),
	scrollTop?: number,
	width: number,
};

type State = {
	shorterThanViewport: number,
	topShadow: boolean,
};



class SimpleGrid extends Component<Props, State> {

	constructor(props, context) {
		super(props, context);

		this._handleGridScroll = this._handleGridScroll.bind(this);
		this._getColumnWidth = this._getColumnWidth.bind(this);

		this.state = {
			// This property will be false or will contain difference between
			// table width and table container width (in case of smaller table)
			shorterThanViewport: 0,
			topShadow: props.scrollTop && props.scrollTop > 0,
		};
	}



	_getAllColumnsWidth(columnCount, columnWidth) {
		let width = 0;
		const columnWidthGetter = this._wrapSizeGetter(columnWidth);

		for (let i = 0; i < columnCount; i++) {
			width += columnWidthGetter({ index: i });
		}

		return width;
	}



	_calculateGridSizes(props) {
		const {
			columnCount,
			columnWidth,
			height,
			rowCount,
			rowHeight,
			width,
		} = props;

		const allColumnsWidth = this._getAllColumnsWidth(columnCount, columnWidth);
		const gridHeight = rowHeight * rowCount;
		let shorterThanViewport = 0;

		if (width >= allColumnsWidth) {
			shorterThanViewport = width - allColumnsWidth;

			if (gridHeight > height) {
				shorterThanViewport = shorterThanViewport - scrollbarSize();
			}
		} else {
			shorterThanViewport = 0;
		}

		this.setState({
			shorterThanViewport,
		}, () => {
			if (this.refs.Grid) {
				(this.refs.Grid as Grid).recomputeGridSize();
			}
		});
	}



	componentDidMount() {
		this._calculateGridSizes(this.props);
	}



	componentDidUpdate(prevProps) {
		const {
			columnCount,
			rowCount,
			width,
		} = this.props;

		const {
			columnCount: prevColumnCount,
			rowCount: prevRowCount,
			width: prevWidth,
		} = prevProps;

		if (
			width !== prevWidth
			|| rowCount !== prevRowCount
			|| columnCount !== prevColumnCount
		) {
			this._calculateGridSizes(this.props);
		}
	}



	_wrapPropertyGetter(value) {
		return value instanceof Function
			? value
			: () => value;
	}



	_wrapSizeGetter(size) {
		return this._wrapPropertyGetter(size);
	}



	_getColumnCount() {
		const {
			columnCount,
		} = this.props;

		const {
			shorterThanViewport,
		} = this.state;

		if (shorterThanViewport) {
			return columnCount + 1;
		}

		return columnCount;
	}



	_getColumnWidth({ index }) {
		const {
			columnCount,
			columnWidth,
		} = this.props;

		const {
			shorterThanViewport,
		} = this.state;

		if (shorterThanViewport && columnCount === index) {
			return shorterThanViewport;
		}

		const columnWidthGetter = this._wrapSizeGetter(columnWidth);

		return columnWidthGetter({
			index,
		});
	}



	_handleGridScroll(props) {
		const {
			onScrollCallback,
		} = this.props;

		const {
			topShadow,
		} = this.state;

		if (onScrollCallback) {
			onScrollCallback(props);
		}

		if (props.scrollTop === 0 && topShadow) {
			this.setState({
				topShadow: false,
			});
		}

		if (props.scrollTop > 0 && !topShadow) {
			this.setState({
				topShadow: true,
			});
		}
	}



	render() {
		const {
			cellRenderer,
			height,
			onSectionRendered,
			overscanColumnCount,
			rowCount,
			rowHeight,
			scrollTop,
			width,
		} = this.props;

		const {
			shorterThanViewport,
			topShadow,
		} = this.state;

		const componentClasses = classNames({
			'simple-grid': true,
			'simple-grid--with-top-shadow': topShadow,
		});

		return (
			<div className={componentClasses}>
				<Grid
					cellRenderer={cellRenderer}
					className="js-scrollable"
					columnCount={this._getColumnCount()}
					columnWidth={this._getColumnWidth}
					height={height}
					key={'table' + shorterThanViewport}
					onScroll={throttle(this._handleGridScroll, 50)}
					onSectionRendered={onSectionRendered}
					overscanColumnCount={overscanColumnCount}
					ref="Grid"
					rowCount={rowCount}
					rowHeight={rowHeight}
					scrollTop={scrollTop}
					width={width}
				/>
			</div>
		);
	}

}



export default SimpleGrid;
