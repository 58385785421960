import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MagazineIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M11.278,6.134a.25.25,0,0,0-.116-.247C9.9,5.1,7.033,3.859,1.507,3.787a.5.5,0,0,0-.507.5V20.052a.5.5,0,0,0,.493.5c5.151.066,7.994,1.15,9.385,1.932a.247.247,0,0,0,.248,0,.25.25,0,0,0,.124-.216V6.446A2.534,2.534,0,0,1,11.278,6.134Z"
				fill={color}
			/>
			<path
				d="M19.25,17.446a.751.751,0,0,1-.624.739,8.419,8.419,0,0,0-4.292,2.258,8.007,8.007,0,0,0-1.027,1.429.25.25,0,0,0,.328.346,20.992,20.992,0,0,1,8.872-1.666.5.5,0,0,0,.493-.5V4.287a.5.5,0,0,0-.507-.5c-1.114.014-2.114.079-3.02.176a.25.25,0,0,0-.223.249Z"
				fill={color}
			/>
			<path
				d="M17.568,16.9a.251.251,0,0,0,.182-.241V1.734a.25.25,0,0,0-.336-.235,7.679,7.679,0,0,0-3.08,1.945A5.455,5.455,0,0,0,12.75,6.458V19.31a.25.25,0,0,0,.438.165l.023-.026A9.322,9.322,0,0,1,17.568,16.9Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MagazineIconBuilder;
