import React from 'react';

import type CK from '~/types/contentking';

import CustomerTeamContextDetailsOverview from '~/components/app/CustomerTeamContextDetailsOverview';

import {
	useAccountCustomerTeamContextDetailsQuery,
} from './AccountCustomerTeamContextDetailsOverview.gql';



type Props = {
	accountId: CK.AccountId,
};

const AccountCustomerTeamContextDetailsOverview: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const { data } = useAccountCustomerTeamContextDetailsQuery({
		variables: {
			accountId,
		},
	});

	const details = data?.account?.customerTeamContext?.details ?? null;

	return (
		<CustomerTeamContextDetailsOverview
			data={details}
		/>
	);
};



export default AccountCustomerTeamContextDetailsOverview;
