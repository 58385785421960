import {
	selectedWebsiteIdSelector,
} from '../ui/selectors';



const selector = (state) => state.get('affectedLinks');

const dataSelector = (state) => selector(state).get('data');

export const filterSelector = (state) => selector(state).get('filter');

export const loadingSelector = (state) => selector(state).get('loading');

export const sortBySelector = (state) => selector(state).get('sortBy');

const totalsSelector = (state) => selector(state).get('totals');



export const currentDataSelector = (state) => dataSelector(state).get(selectedWebsiteIdSelector(state));

export const currentTotalsSelector = (state) => totalsSelector(state).get(selectedWebsiteIdSelector(state));
