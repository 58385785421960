import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CWVMonitoringAndJSRenderingIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M7.74453 20.0199H1.83203V2.22241H22.4795V12.0874"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M4.23047 12.6799H6.20797L8.71297 10.3349L10.4455 11.9549L14.573 8.09241L16.533 9.92741L20.223 6.47241"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M29.5879 14.1626H9.73535V28.9251H29.5879V14.1626Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M13.0625 17.2349H18.6275V23.0699C18.6275 23.8078 18.3343 24.5156 17.8125 25.0374C17.2907 25.5592 16.583 25.8524 15.845 25.8524C15.107 25.8524 14.3993 25.5592 13.8775 25.0374C13.3557 24.5156 13.0625 23.8078 13.0625 23.0699"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M26.1224 18.6699C25.9662 18.2487 25.6848 17.8855 25.3161 17.629C24.9474 17.3724 24.509 17.2349 24.0599 17.2349H22.8474C22.2758 17.2349 21.7277 17.4619 21.3236 17.866C20.9194 18.2702 20.6924 18.8183 20.6924 19.3899C20.6924 19.6729 20.7481 19.9531 20.8564 20.2145C20.9647 20.476 21.1235 20.7136 21.3236 20.9137C21.5237 21.1138 21.7612 21.2725 22.0227 21.3808C22.2842 21.4891 22.5644 21.5449 22.8474 21.5449H24.0974C24.6685 21.5455 25.216 21.7729 25.6196 22.1769C26.0232 22.581 26.2499 23.1288 26.2499 23.6999C26.2499 23.9827 26.1941 24.2628 26.0858 24.5242C25.9775 24.7855 25.8187 25.0229 25.6185 25.2228C25.4184 25.4227 25.1808 25.5812 24.9194 25.6892C24.6579 25.7973 24.3778 25.8527 24.0949 25.8524H22.8924C22.3093 25.8524 21.7501 25.6209 21.3376 25.2089C20.9251 24.7968 20.693 24.2379 20.6924 23.6549V23.6549"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M7.17773 22.4551V26.4201"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M25.21 7.59253V11.5575"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default CWVMonitoringAndJSRenderingIconBuilder;

