import Immutable from 'immutable';

import {
	createFilter,
	createSortBy,
	getDefaultFilter,
	loadAlertPagesViaApi,
} from '~/model/alertPages';

import {
	transformBooleanOrNAFilterForApi,
} from '~/model/filter';

import {
	currentAlertTypeSelector,
	currentDataSelector,
	filterSelector,
	sortBySelector,
} from '~/state/alertPages/selectors';

import {
	currentDataSelector as currentIncidentsDataSelector,
} from '~/state/incidents/selectors';

import {
	alertIdSelector,
} from '~/state/ui/content/selectors';

import {
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';

import {
	getPatch,
} from '~/utilities/coverage';



export const MARK_START_OF_LOADING = 'ALERT_PAGES_MARK_START_OF_LOADING';
export const STORE = 'ALERT_PAGES_STORE';
export const UPDATE_FILTER = 'ALERT_PAGES_UPDATE_FILTER';
export const UPDATE_SORT_BY = 'ALERT_PAGES_UPDATE_SORT_BY';



export function loadAlertPages(offset, limit) {
	const prescanLimit = Math.ceil(limit * (1 / 3));
	const pureOffset = offset - prescanLimit;

	offset = Math.max(0, pureOffset);

	return (dispatch, getState) => {
		const currentAlertPagesData = currentDataSelector(getState());
		const alertId = alertIdSelector(getState());
		const incidents = currentIncidentsDataSelector(getState());
		const websiteId = selectedWebsiteIdSelector(getState());

		if (!websiteId || !alertId || !incidents) {
			return Promise.resolve();
		}

		const alertData = incidents.get(alertId) || incidents.get(alertId + '');

		if (!alertData) {
			return Promise.resolve();
		}

		const alertType = alertData.get('type');

		let filter = filterSelector(getState());

		filter = transformBooleanOrNAFilterForApi(filter);

		filter = createFilter(
			filter,
			alertType,
			alertData.get('settings'),
		).toJS();

		const sortBy = createSortBy(sortBySelector(getState()), alertType);

		if (sortBy === undefined || !sortBy.get('key')) {
			return Promise.resolve();
		}

		const patch = getPatch(
			currentAlertPagesData.get('rangeInLoading'),
			offset,
			limit,
		);

		if (patch.coverSpan === 0) {
			return Promise.resolve();
		}

		if (patch.coverSpan < 50 && patch.isEdge === true && (patch.coverFrom + patch.coverSpan) < currentAlertPagesData.get('total')) {
			return Promise.resolve();
		}

		offset = patch.coverFrom;
		limit = patch.coverSpan;

		const params = {
			limit,
			offset: Math.max(0, offset),
		};

		for (const key in filter) {
			if (filter.hasOwnProperty(key)) {
				params['filter[' + key + ']'] = filter[key];
			}
		}

		if (sortBy !== undefined && sortBy !== null) {
			params.sort = sortBy.get('key');
			params.direction = sortBy.get('direction') ? 'asc' : 'desc';
		}

		const initiatedAt = Date.now();

		dispatch({
			type: MARK_START_OF_LOADING,
			initiatedAt,
			limit,
			offset,
			websiteId,
		});

		return loadAlertPagesViaApi(
			websiteId,
			alertId,
			params,
		).then((data) => {
			dispatch({
				type: STORE,
				initiatedAt,
				websiteId,
				total: data.total,
				offset,
				range: data.pages_urlUniqueIds,
				pages: data.pages,
			});
		});
	};
}



export function updateFilter(filter) {
	return (dispatch, getState) => {
		const alertId = alertIdSelector(getState());
		const incidents = currentIncidentsDataSelector(getState());

		const alertData = incidents.get(alertId) || incidents.get(alertId + '');

		if (!alertData) {
			return Promise.resolve();
		}

		const defaultFilter = getDefaultFilter(
			currentAlertTypeSelector(getState()),
			alertData.get('settings'),
		);

		filter = Immutable.fromJS(filter).map((value, key) => {
			if (value === undefined || value === null) {
				return defaultFilter.get(key) || '';
			}

			return value;
		});

		const originalFilter = filterSelector(getState());

		if (Immutable.is(originalFilter, filter)) {
			return Promise.resolve();
		}

		dispatch({
			type: UPDATE_FILTER,
			filter,
		});

		return Promise.resolve();
	};
}



export function updateSortBy(sortBy) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SORT_BY,
			sortBy: Immutable.fromJS(sortBy),
		});

		return Promise.resolve();
	};
}
