import classNames from 'classnames';
import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';

import Hint, {
	HintPopupSkin,
} from '~/components/patterns/hints/hint/Hint';



export enum SearchEngineActivityStateStatus {
	Visited = 'visited',
	NotVisited = 'not-visited',
	NotEnabled = 'not-enabled',
	NotOnPlan = 'not-on-plan',
	Unknown = 'unknown',
}

type Props = {
	/** Additional explanatory tooltip visible also in non-compact state of component */
	explanatoryTooltip?: React.ReactNode,
	/** Show compact version: icon with hint on :hover */
	isCompact?: boolean,
	label: React.ReactNode,
	searchEngineIconType: BasicIconType,
	/** Status of search engine activity */
	status: SearchEngineActivityStateStatus,
};



const SearchEngineActivityState: React.FC<Props> = (props) => {
	const {
		explanatoryTooltip,
		isCompact,
		label,
		searchEngineIconType,
		status,
	} = props;

	const componentClasses = classNames({
		'search-engine-activity-state': true,
		'search-engine-activity-state--is-compact': isCompact,
		['search-engine-activity-state--' + status + '-status']: true,
	});

	const icon = (
		<BasicIcon
			grayscale={status === SearchEngineActivityStateStatus.Unknown}
			size={isCompact ? 13 : 15}
			type={searchEngineIconType}
		/>
	);

	let component = (
		<span className={componentClasses}>
			<span className="search-engine-activity-state__icon">
				{icon}
			</span>
			{!isCompact && (
				<span className="search-engine-activity-state__label">
					{label}
				</span>
			)}
		</span>
	);

	if (isCompact || explanatoryTooltip) {
		component = (
			<Hint
				popup={explanatoryTooltip || label}
				popupOffset={({ placement }) => {
					if (placement === 'bottom-start' || placement === 'top-start') {
						return [-5, 4];
					}

					if (placement === 'bottom-end' || placement === 'top-end') {
						return [5, 4];
					}

					return [0, 0];
				}}
				popupSkin={status === SearchEngineActivityStateStatus.NotOnPlan ? HintPopupSkin.Premium : HintPopupSkin.Dark}
			>
				{component}
			</Hint>
		);
	}

	return component;
};



export default SearchEngineActivityState;

export {
	BasicIconType as SearchEngineActivityStateIconType,
};
