import React from 'react';

import ScrollableBox from '~/components/atoms/boxes/ScrollableBox';



type Props = {
	children?: React.ReactNode,
	/** Title of panel */
	ctaElement: React.ReactNode,
	/** Title of confirmation panel */
	title: React.ReactNode,
};



const ConfirmationPanel: React.FC<Props> = (props) => {
	const {
		ctaElement,
		children,
		title,
	} = props;

	return (
		<section className="confirmation-panel">
			<div className="confirmation-panel__title">
				{title}
			</div>
			<div className="confirmation-panel__body">
				<ScrollableBox maxHeight="100%">
					{children}
				</ScrollableBox>
			</div>
			<div className="confirmation-panel__cta-element">
				{ctaElement}
			</div>
		</section>
	);
};



export default ConfirmationPanel;
