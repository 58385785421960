/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserEmailQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserEmailQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string } | null };


export const UserEmailDocument = gql`
    query UserEmail($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    email
    id
    legacyId
  }
}
    `;

/**
 * __useUserEmailQuery__
 *
 * To run a query within a React component, call `useUserEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserEmailQuery(baseOptions: Apollo.QueryHookOptions<UserEmailQuery, UserEmailQueryVariables> & ({ variables: UserEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEmailQuery, UserEmailQueryVariables>(UserEmailDocument, options);
      }
export function useUserEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEmailQuery, UserEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEmailQuery, UserEmailQueryVariables>(UserEmailDocument, options);
        }
export function useUserEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserEmailQuery, UserEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserEmailQuery, UserEmailQueryVariables>(UserEmailDocument, options);
        }
export type UserEmailQueryHookResult = ReturnType<typeof useUserEmailQuery>;
export type UserEmailLazyQueryHookResult = ReturnType<typeof useUserEmailLazyQuery>;
export type UserEmailSuspenseQueryHookResult = ReturnType<typeof useUserEmailSuspenseQuery>;
export type UserEmailQueryResult = Apollo.QueryResult<UserEmailQuery, UserEmailQueryVariables>;