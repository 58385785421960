import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Attached element to source */
	element: React.ReactNode,
	/** Enable ellipsis support and show attached element on one line */
	inline?: boolean,
};



const AttachedElement: React.FC<Props> = (props) => {
	const {
		children,
		element,
		inline = false,
	} = props;

	const componentClasses = classNames({
		'attached-element': true,
		'attached-element--inline': inline,
	});

	return (
		<span className={componentClasses}>
			<span className="attached-element__source-text">
				{children}
			</span>
			{element && (
				<span className="attached-element__attached-component">
					<span className="attached-element__gap">&nbsp;</span>
					{element}
				</span>
			)}
		</span>
	);
};



export default AttachedElement;
