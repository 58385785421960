/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserProfileMutationVariables = GraphQL.Exact<{
  firstName: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  jobTitle: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  lastName: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UpdateUserProfileMutation = { readonly __typename?: 'Mutation', readonly UpdateUserProfile: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly firstName: string | null, readonly id: string, readonly jobTitle: string | null, readonly lastName: string | null, readonly legacyId: string } | null } } };


export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($firstName: String, $jobTitle: String, $lastName: String, $legacyUserId: ID!) {
  UpdateUserProfile(
    firstName: $firstName
    jobTitle: $jobTitle
    lastName: $lastName
    legacyUserId: $legacyUserId
  ) {
    query {
      user(legacyId: $legacyUserId) {
        firstName
        id
        jobTitle
        lastName
        legacyId
      }
    }
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      jobTitle: // value for 'jobTitle'
 *      lastName: // value for 'lastName'
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;