import React from 'react';

import PureRadioFields, {
	SIZE_SMALL as RADIO_FIELDS_SIZE_SMALL,
} from '~/components/atoms/forms/components/builders/PureRadioFields';
import StripedTable from '~/components/patterns/tables/stripedTable/StripedTable';
import StripedTableRadioCell, {
	StripedTableRadioCellType,
} from '~/components/patterns/tables/stripedTable/StripedTableRadioCell';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



type Props = {
	/** Data displayed in the table */
	data: Array<{
		/** First column */
		label: React.ReactNode,
		/** Another columns in table with attached checkbox in header. Key of value has to be the same as value of option */
		values: Record<string, React.ReactNode>,
	}>,
	isControlled?: boolean,
	name: string,
	onBlurCallback?: () => void,
	onChangeCallback?: (value: string) => void,
	onFocusCallback?: () => void,
	/** List of data for every option */
	options: Array<{
		disabled?: boolean,
		checked?: boolean,
		label: React.ReactNode,
		/** Possibility to hide radio element */
		showRadioElement?: boolean,
		value: string,
	}>,
};



const RadioTableField: React.FC<Props> = (props) => {
	const {
		data,
		isControlled,
		name,
		onBlurCallback,
		onChangeCallback,
		onFocusCallback,
		options,
	} = props;

	return (
		<PureRadioFields
			isControlled={isControlled}
			items={options}
			name={name}
			onBlurCallback={onBlurCallback}
			onChangeCallback={onChangeCallback}
			onFocusCallback={onFocusCallback}
			size={RADIO_FIELDS_SIZE_SMALL}
			stretchedFields={true}
		>
			{({ fields }) => {
				let highlightedColumnIndex = fields.findIndex((element) => element.isChecked);

				if (highlightedColumnIndex >= 0) {
					// fix column is without highlight and contains labels
					highlightedColumnIndex += 1;
				}

				return (
					<StripedTable
						bodyCellRenderer={({ columnIndex, rowIndex }) => {
							const rowData = getArrayItemAtSafeIndex(data, rowIndex);

							if (columnIndex === 0) {
								return (
									<StripedTableRadioCell type={StripedTableRadioCellType.Label}>
										{rowData.label}
									</StripedTableRadioCell>
								);
							}

							return (
								<StripedTableRadioCell type={StripedTableRadioCellType.Data}>
									{rowData.values[fields[columnIndex - 1].data.value]}
								</StripedTableRadioCell>
							);
						}}
						columnCount={options.length + 1}
						headerCellRenderer={({ columnIndex }) => (
							<StripedTableRadioCell type={StripedTableRadioCellType.Header}>
								{columnIndex > 0 && fields[columnIndex - 1].field}
							</StripedTableRadioCell>
						)}
						highlightedColumnIndex={highlightedColumnIndex}
						rowKeys={Array.from(Array(data.length).keys())}
					/>
				);
			}}
		</PureRadioFields>
	);
};



export default RadioTableField;
