/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountMonitoringSettingsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountMonitoringSettingsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly defaultFetchingSettings: { readonly __typename?: 'AccountDefaultFetchingSettings', readonly fetchingLocation: GraphQL.FetchingLocation, readonly userAgentSettings: { readonly __typename?: 'UserAgentSettings', readonly type: string } } } | null };

export type UpdateAccountMonitoringSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  fetchingLocation: GraphQL.FetchingLocation;
  userAgentSettings: GraphQL.UserAgentSettingsInput;
}>;


export type UpdateAccountMonitoringSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateAccountMonitoringSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly defaultFetchingSettings: { readonly __typename?: 'AccountDefaultFetchingSettings', readonly fetchingLocation: GraphQL.FetchingLocation, readonly userAgentSettings: { readonly __typename?: 'UserAgentSettings', readonly type: string } } } | null } } };


export const AccountMonitoringSettingsDocument = gql`
    query AccountMonitoringSettings($accountId: AccountId!) {
  account(id: $accountId) {
    id
    defaultFetchingSettings {
      fetchingLocation
      userAgentSettings {
        type
      }
    }
  }
}
    `;

/**
 * __useAccountMonitoringSettingsQuery__
 *
 * To run a query within a React component, call `useAccountMonitoringSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMonitoringSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMonitoringSettingsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountMonitoringSettingsQuery(baseOptions: Apollo.QueryHookOptions<AccountMonitoringSettingsQuery, AccountMonitoringSettingsQueryVariables> & ({ variables: AccountMonitoringSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountMonitoringSettingsQuery, AccountMonitoringSettingsQueryVariables>(AccountMonitoringSettingsDocument, options);
      }
export function useAccountMonitoringSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountMonitoringSettingsQuery, AccountMonitoringSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountMonitoringSettingsQuery, AccountMonitoringSettingsQueryVariables>(AccountMonitoringSettingsDocument, options);
        }
export function useAccountMonitoringSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountMonitoringSettingsQuery, AccountMonitoringSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountMonitoringSettingsQuery, AccountMonitoringSettingsQueryVariables>(AccountMonitoringSettingsDocument, options);
        }
export type AccountMonitoringSettingsQueryHookResult = ReturnType<typeof useAccountMonitoringSettingsQuery>;
export type AccountMonitoringSettingsLazyQueryHookResult = ReturnType<typeof useAccountMonitoringSettingsLazyQuery>;
export type AccountMonitoringSettingsSuspenseQueryHookResult = ReturnType<typeof useAccountMonitoringSettingsSuspenseQuery>;
export type AccountMonitoringSettingsQueryResult = Apollo.QueryResult<AccountMonitoringSettingsQuery, AccountMonitoringSettingsQueryVariables>;
export const UpdateAccountMonitoringSettingsDocument = gql`
    mutation UpdateAccountMonitoringSettings($accountId: AccountId!, $fetchingLocation: FetchingLocation!, $userAgentSettings: UserAgentSettingsInput!) {
  UpdateAccountMonitoringSettings(
    accountId: $accountId
    fetchingLocation: $fetchingLocation
    userAgentSettings: $userAgentSettings
  ) {
    query {
      account(id: $accountId) {
        id
        defaultFetchingSettings {
          fetchingLocation
          userAgentSettings {
            type
          }
        }
      }
    }
  }
}
    `;
export type UpdateAccountMonitoringSettingsMutationFn = Apollo.MutationFunction<UpdateAccountMonitoringSettingsMutation, UpdateAccountMonitoringSettingsMutationVariables>;

/**
 * __useUpdateAccountMonitoringSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMonitoringSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMonitoringSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMonitoringSettingsMutation, { data, loading, error }] = useUpdateAccountMonitoringSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      fetchingLocation: // value for 'fetchingLocation'
 *      userAgentSettings: // value for 'userAgentSettings'
 *   },
 * });
 */
export function useUpdateAccountMonitoringSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMonitoringSettingsMutation, UpdateAccountMonitoringSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMonitoringSettingsMutation, UpdateAccountMonitoringSettingsMutationVariables>(UpdateAccountMonitoringSettingsDocument, options);
      }
export type UpdateAccountMonitoringSettingsMutationHookResult = ReturnType<typeof useUpdateAccountMonitoringSettingsMutation>;
export type UpdateAccountMonitoringSettingsMutationResult = Apollo.MutationResult<UpdateAccountMonitoringSettingsMutation>;
export type UpdateAccountMonitoringSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMonitoringSettingsMutation, UpdateAccountMonitoringSettingsMutationVariables>;