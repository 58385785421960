/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserAccountMembershipQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  legacyUserId: GraphQL.Scalars['String']['input'];
}>;


export type UserAccountMembershipQuery = { readonly __typename?: 'Query', readonly userAccountMembership: { readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> } | null };


export const UserAccountMembershipDocument = gql`
    query UserAccountMembership($accountId: AccountId!, $legacyUserId: String!) {
  userAccountMembership(accountId: $accountId, legacyUserId: $legacyUserId) {
    account {
      id
    }
    hasLimitedWebsitesAccess
    role
    user {
      id
      legacyId
    }
    websites {
      id
    }
  }
}
    `;

/**
 * __useUserAccountMembershipQuery__
 *
 * To run a query within a React component, call `useUserAccountMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountMembershipQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserAccountMembershipQuery(baseOptions: Apollo.QueryHookOptions<UserAccountMembershipQuery, UserAccountMembershipQueryVariables> & ({ variables: UserAccountMembershipQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAccountMembershipQuery, UserAccountMembershipQueryVariables>(UserAccountMembershipDocument, options);
      }
export function useUserAccountMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAccountMembershipQuery, UserAccountMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAccountMembershipQuery, UserAccountMembershipQueryVariables>(UserAccountMembershipDocument, options);
        }
export function useUserAccountMembershipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserAccountMembershipQuery, UserAccountMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserAccountMembershipQuery, UserAccountMembershipQueryVariables>(UserAccountMembershipDocument, options);
        }
export type UserAccountMembershipQueryHookResult = ReturnType<typeof useUserAccountMembershipQuery>;
export type UserAccountMembershipLazyQueryHookResult = ReturnType<typeof useUserAccountMembershipLazyQuery>;
export type UserAccountMembershipSuspenseQueryHookResult = ReturnType<typeof useUserAccountMembershipSuspenseQuery>;
export type UserAccountMembershipQueryResult = Apollo.QueryResult<UserAccountMembershipQuery, UserAccountMembershipQueryVariables>;