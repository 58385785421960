import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const UnlimitedUsersWebsitesRechecksIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M11.6285 16.0049H4.85352"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M18.5785 19.5027H4.85352"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M12.281 23H4.85352"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M10.8175 26.5275H2.7925C2.65658 26.5285 2.5218 26.5027 2.39585 26.4516C2.2699 26.4005 2.15525 26.3251 2.05844 26.2297C1.96163 26.1343 1.88457 26.0207 1.83165 25.8955C1.77873 25.7703 1.75098 25.6359 1.75 25.5V10.315C1.75 10.0405 1.85904 9.77727 2.05314 9.58317C2.24724 9.38907 2.5105 9.28003 2.785 9.28003H14.25"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M21.7695 18.5276V22.9501"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M1.84766 12.9897H12.0277"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M24.7257 8.65765C26.201 9.24681 27.4659 10.2646 28.3572 11.5796C29.2484 12.8946 29.7251 14.4466 29.7257 16.0351V16.6301H13.8232V16.0351C13.8224 14.4453 14.2984 12.8917 15.1897 11.5752C16.0811 10.2587 17.3468 9.23978 18.8232 8.65015"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M21.7703 9.54519C24.181 9.54519 26.1353 7.59091 26.1353 5.18018C26.1353 2.76946 24.181 0.815186 21.7703 0.815186C19.3596 0.815186 17.4053 2.76946 17.4053 5.18018C17.4053 7.59091 19.3596 9.54519 21.7703 9.54519Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M15.748 23.0922C16.1656 22.9789 16.5955 22.9167 17.028 22.9072C17.7205 22.9075 18.4008 23.0887 19.0016 23.433C19.6023 23.7772 20.1027 24.2725 20.453 24.8697C20.5255 24.9947 22.878 28.5547 22.953 28.6747C23.788 29.9247 24.953 30.8497 26.4955 30.8497C27.5315 30.8237 28.5164 30.3939 29.2399 29.652C29.9634 28.9101 30.3684 27.9148 30.3684 26.8785C30.3684 25.8422 29.9634 24.8469 29.2399 24.1049C28.5164 23.363 27.5315 22.9332 26.4955 22.9072C25.143 22.9072 24.113 23.6997 23.293 24.6572L22.793 25.1797"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M20.7491 28.4998C20.5991 28.7498 20.2191 29.2498 20.2141 29.2498C19.5963 30.0705 18.6843 30.6198 17.6699 30.7823C16.6555 30.9447 15.6177 30.7075 14.7745 30.1207C13.9313 29.5339 13.3484 28.643 13.1483 27.6353C12.9482 26.6277 13.1466 25.5817 13.7016 24.7173C13.8905 24.4528 14.1104 24.2119 14.3566 23.9998"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M17.1985 24.5248L15.751 23.0923L17.4535 21.5398"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default UnlimitedUsersWebsitesRechecksIconBuilder;

