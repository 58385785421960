import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CancelButton from '~/components/app/CancelButton';
import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import ModalButtonsLayout from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from './parts/ModalContainer';
import ModalPanel from '../panels/ModalPanel';
import ModalTextSection from './parts/ModalTextSection';
import ModalHeader from '../../patterns/modals/parts/ModalHeader';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton, {
	SubmitButtonSize,
} from '~/components/app/SubmitButton';

import useLogout from '~/hooks/useLogout';



const messages = defineMessages({
	confirmButton: {
		id: 'ui.general.logout.modal.button',
	},
	content: {
		id: 'ui.general.logout.modal.content',
	},
	title: {
		id: 'ui.general.logout.modal.title',
	},
});



const LogoutConfirmationModal = (props) => {
	const {
		closeCallback,
	} = props;

	const logout = useLogout();

	return (
		<ModalPanel
			canBeClosed={true}
			canBeClosedViaOverlay={true}
			onCloseCallback={closeCallback}
		>
			<ModalContainer
				gapsSize={2}
				header={(
					<ModalHeader
						title={(
							<Copy {...messages.title} />
						)}
					/>
				)}
				headerGapsSize={1}
			>
				<ModalTextSection centered={true}>
					<RichText>
						<p>
							<Copy {...messages.content} />
						</p>
					</RichText>
				</ModalTextSection>

				<Form
					defaultDataHasChanged={true}
					onSuccess={logout}
				>
					<ModalButtonsLayout>
						<CancelButton onClickCallback={closeCallback} />

						<SubmitButton
							size={SubmitButtonSize.Large}
						>
							<FormattedMessage {...messages.confirmButton} />
						</SubmitButton>
					</ModalButtonsLayout>
				</Form>
			</ModalContainer>
		</ModalPanel>
	);
};



export default LogoutConfirmationModal;
