import type GraphQL from '~/types/graphql';

import usePermission from '~/hooks/usePermission';

import {
	ObjectType,
} from '~/model/permissions';



function useIsAllowedWithUser(
	legacyUserId: string | null,
	action: GraphQL.ActionWithUser,
) {
	const isAllowed = usePermission();

	return isAllowed({
		action,
		objectId: legacyUserId,
		objectType: ObjectType.User,
	});
}



export default useIsAllowedWithUser;
