import React from 'react';

import InputRangeField, {
	InputRangeFieldDirection,
	type InputRangeFieldRef,
} from '~/components/atoms/forms/components/InputRangeField';



type Props = {
	name: string,
	width: number,
};

const ImportanceFieldFilter = React.forwardRef<InputRangeFieldRef, Props>((props, ref) => {
	const {
		name,
		width,
	} = props;

	return (
		<InputRangeField
			direction={InputRangeFieldDirection.GreaterOrEqual}
			max={10}
			min={0}
			name={name}
			ref={ref}
			step={0.1}
			width={width}
		/>
	);
});



export default React.memo(ImportanceFieldFilter);
