/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnignorePageIssueCasesOnWholeWebsiteMutationVariables = GraphQL.Exact<{
  cases: ReadonlyArray<GraphQL.Scalars['Int']['input']> | GraphQL.Scalars['Int']['input'];
  issueName: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueCasesOnWholeWebsiteMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueCasesOnWholeWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const UnignorePageIssueCasesOnWholeWebsiteDocument = gql`
    mutation UnignorePageIssueCasesOnWholeWebsite($cases: [Int!]!, $issueName: String!, $websiteId: WebsiteId!) {
  UnignorePageIssueCasesOnWholeWebsite(
    cases: $cases
    type: $issueName
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePageIssueCasesOnWholeWebsiteMutationFn = Apollo.MutationFunction<UnignorePageIssueCasesOnWholeWebsiteMutation, UnignorePageIssueCasesOnWholeWebsiteMutationVariables>;

/**
 * __useUnignorePageIssueCasesOnWholeWebsiteMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueCasesOnWholeWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueCasesOnWholeWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueCasesOnWholeWebsiteMutation, { data, loading, error }] = useUnignorePageIssueCasesOnWholeWebsiteMutation({
 *   variables: {
 *      cases: // value for 'cases'
 *      issueName: // value for 'issueName'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueCasesOnWholeWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueCasesOnWholeWebsiteMutation, UnignorePageIssueCasesOnWholeWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueCasesOnWholeWebsiteMutation, UnignorePageIssueCasesOnWholeWebsiteMutationVariables>(UnignorePageIssueCasesOnWholeWebsiteDocument, options);
      }
export type UnignorePageIssueCasesOnWholeWebsiteMutationHookResult = ReturnType<typeof useUnignorePageIssueCasesOnWholeWebsiteMutation>;
export type UnignorePageIssueCasesOnWholeWebsiteMutationResult = Apollo.MutationResult<UnignorePageIssueCasesOnWholeWebsiteMutation>;
export type UnignorePageIssueCasesOnWholeWebsiteMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueCasesOnWholeWebsiteMutation, UnignorePageIssueCasesOnWholeWebsiteMutationVariables>;