import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ColumnsSetsList from './ColumnsSetsList';
import SimpleNavigation from '~/components/patterns/navigations/simpleNavigation/SimpleNavigation';

import useColumnSets from '~/hooks/useColumnSets';
import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';



enum Type {
	Personal = 'personal',
	Shared = 'shared',
}

const titleMessages = defineMessages({
	[Type.Personal]: {
		id: 'ui.columnsConfigurator.heading.personal',
	},
	[Type.Shared]: {
		id: 'ui.columnsConfigurator.heading.shared',
	},
});

function sortSets(
	setA: {
		name: string,
	},
	setB: {
		name: string,
	},
) {
	const setNameA = setA.name.toLowerCase();
	const setNameB = setB.name.toLowerCase();

	if (setNameA < setNameB) {
		return -1;
	}

	if (setNameA > setNameB) {
		return 1;
	}

	return 0;
}



const ColumnsSets: React.FC = () => {
	const columnSets = useColumnSets();
	const currentColumnSet = useCurrentColumnSet();

	function renderGroup(
		type: Type,
		columnSets: ReadonlyArray<{
			id: CK.ColumnSetId,
			name: string,
		}>,
	) {
		return (
			<SimpleNavigation
				icon={(
					<BasicIcon type={type === Type.Personal ? BasicIconType.Person : BasicIconType.Team} />
				)}
				key={'group-' + type}
				title={(
					<FormattedMessage {...titleMessages[type]} />
				)}
			>
				<ColumnsSetsList
					columnSets={columnSets}
					currentColumnSet={currentColumnSet}
				/>
			</SimpleNavigation>
		);
	}

	const personalSets = columnSets.columnSets.filter((set) => set.sharedWithAccountId === null).sort(sortSets);
	const sharedSets = columnSets.columnSets.filter((set) => set.sharedWithAccountId !== null).sort(sortSets);

	const output: Array<React.ReactNode> = [];

	if (sharedSets.length > 0) {
		output.push(
			renderGroup(Type.Shared, sharedSets),
		);
	}

	if (personalSets.length > 0) {
		output.push(
			renderGroup(Type.Personal, personalSets),
		);
	}

	return (
		<>{output}</>
	);
};



export default ColumnsSets;
