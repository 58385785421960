import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import DonutPagesChart from '../components/DonutPagesChart';



class DomainChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		this.randomColors = [
			'#367be2',
			'#FE8719',
		];
	}



	render() {
		const {
			data,
		} = this.props;

		return (
			<DonutPagesChart
				data={data}
				name="domain"
				percentageFactor={100}
				randomColors={this.randomColors}
			/>
		);
	}

}

DomainChart.propTypes = {
	data: PropTypes.instanceOf(List),
};



export default DomainChart;
