import type CK from '~/types/contentking';

import useBatchContext from '~/hooks/useBatchContext';
import usePollInterval from '~/hooks/usePollInterval';



function useAccountPropertiesQuery<TQueryHookReturnType>(
	queryHook: (options: any) => TQueryHookReturnType,
	accountId: CK.AccountId | null,
): TQueryHookReturnType {
	return queryHook({
		context: useBatchContext(
			`accountProperties/${accountId ?? 0}`,
			999,
		),
		pollInterval: usePollInterval(30000),
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});
}



export default useAccountPropertiesQuery;
