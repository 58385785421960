import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const LinkIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-link">
					<g id="link">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<path
							d="M11.8910937,16.7170312 L9.01140625,19.5967187 C8.05671875,20.5514062 6.50984375,20.5514062 5.5559375,19.5967187 L4.40359375,18.4451562 C3.4496875,17.4904687 3.4496875,15.9435937 4.40359375,14.9889062 L7.28328125,12.1092187 C8.23796875,11.1553125 9.78484375,11.1553125 10.7395312,12.1092187 L11.8910937,13.2615625 C12.845,14.2154687 12.845,15.763125 11.8910937,16.7170312 L11.8910937,16.7170312 Z"
							id="Stroke-1311"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M19.3785156,9.22960937 L16.4988281,12.1092969 C15.5441406,13.0639844 13.9972656,13.0639844 13.0433594,12.1092969 L11.8910156,10.9577344 C10.9371094,10.0030469 10.9371094,8.45617187 11.8910156,7.50148437 L14.7707031,4.62179687 C15.7253906,3.66789062 17.2722656,3.66789062 18.2269531,4.62179687 L19.3785156,5.77414062 C20.3324219,6.72804687 20.3324219,8.27570312 19.3785156,9.22960937 L19.3785156,9.22960937 Z"
							id="Stroke-1312"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M9.01132812,14.9891406 L14.7707031,9.22976563"
							id="Stroke-1313"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default LinkIconBuilder;

