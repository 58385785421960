import {
	isApolloError,
} from '@apollo/client';
import memoize from 'memoizee';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import StripePaymentAuthorizationContext from '~/components/app/StripePaymentAuthorizationContext';

import {
	actionTokenSelector,
	cancelAuthorization,
	initiateAuthorization,
	isActiveSelector,
	resultTokenSelector,
} from '~/state/paymentAuthorization';

import {
	teamSelector,
} from '~/state/ui/selectors';

import FormError from '~/utilities/FormError';



const Context = React.createContext();

const select = createSelector(
	actionTokenSelector,
	isActiveSelector,
	resultTokenSelector,
	teamSelector,
	(actionToken, isAuthorizing, resultToken, team) => {
		return {
			actionToken,
			isAuthorizing,
			resultToken,
			isAccountUsingStripe: team !== null
				? team.get('billing_entity') !== 'old'
				: null,
		};
	},
);

const createContextValue = memoize((cancelAuthorization, isAuthorizing, isResubmitting) => {
	return {
		cancelAuthorization,
		isAuthorizing,
		isResubmitting,
	};
});



class PaymentAuthorizationContext extends Component {

	constructor(props, context) {
		super(props, context);

		this.formRef = props.formRef || React.createRef();

		this.lastSubmitReject = null;

		this._cancelAuthorization = this._cancelAuthorization.bind(this);
		this._handleFormSuccess = this._handleFormSuccess.bind(this);

		this.state = {
			unauthorizedBillingInfo: null,
		};
	}



	componentDidUpdate({ actionToken: prevActionToken, isAuthorizing: prevIsAuthorizing, resultToken: prevResultToken }) {
		const {
			actionToken: nextActionToken,
			isAuthorizing: nextIsAuthorizing,
			resultToken: nextResultToken,
		} = this.props;

		const {
			unauthorizedBillingInfo,
		} = this.state;

		if (this.lastSubmitReject) {
			if (
				prevIsAuthorizing
				&& !nextIsAuthorizing
			) {
				this.lastSubmitReject(
					new FormError('authorization-failed'),
				);

				this.lastSubmitReject = null;
			}
		}

		if (this.formRef.current) {
			if (
				prevResultToken !== nextResultToken
				&& nextResultToken
				&& unauthorizedBillingInfo
			) {
				this.formRef.current.submit();
			} else if (
				nextIsAuthorizing
				&& prevActionToken
				&& !nextActionToken
			) {
				this.formRef.current.submit();
			}
		}
	}



	componentWillUnmount() {
		if (this.lastSubmitReject) {
			this.lastSubmitReject();

			this.lastSubmitReject = null;
		}
	}



	_cancelAuthorization() {
		const {
			dispatch,
		} = this.props;

		dispatch(
			cancelAuthorization(),
		);
	}



	_handleFormSuccess(values, helpers) {
		const {
			dispatch,
			onBillingInfoTokenization,
			onError,
			onSubmit,
			resultToken,
			useModal,
		} = this.props;

		if (this.lastSubmitReject) {
			this.lastSubmitReject = null;
		}

		const {
			unauthorizedBillingInfo,
		} = this.state;

		if (resultToken && (!onBillingInfoTokenization || unauthorizedBillingInfo)) {
			const parameters = {
				threeDSecureToken: resultToken,
				values,
			};

			if (unauthorizedBillingInfo) {
				parameters.billingInfo = unauthorizedBillingInfo;
			}

			return new Promise((resolve, reject) => {
				onSubmit(parameters)
					.then((values) => {
						dispatch(
							cancelAuthorization(),
						);

						resolve(values);
					})
					.catch((error) => {
						dispatch(
							cancelAuthorization(),
						);

						reject(
							onError
								? onError(error, values, helpers)
								: error,
						);
					});
			});
		}

		let promise;

		if (onBillingInfoTokenization) {
			promise = onBillingInfoTokenization(values)
				.then((billingInfo) => {
					this.setState({
						unauthorizedBillingInfo: billingInfo,
					});

					return {
						billingInfo,
						values,
					};
				});
		} else {
			promise = new Promise((resolve) => {
				resolve({ values });
			});
		}

		return new Promise((resolve, reject) => {
			promise
				.then(({ billingInfo, values }) => {
					const parameters = {
						billingInfo,
						threeDSecureToken: null,
						values,
					};

					return onSubmit(parameters)
						.then(resolve)
						.catch((error) => {
							let threeDSecureActionToken = null;

							if (isApolloError(error)) {
								for (const graphQLError of (error.networkError?.result?.errors ?? [])) {
									if (
										graphQLError?.extensions?.code === 'actionFailure'
											&& graphQLError.extensions.failureCode === 'three_d_secure_action_required'
									) {
										threeDSecureActionToken = graphQLError.extensions.payload.token;
									}
								}
							} else if (error.getCode && error.getCode() === 'three_d_secure_action_required') {
								threeDSecureActionToken = error.getError('three_d_secure_action_required').token;
							}

							if (threeDSecureActionToken !== null) {
								this.lastSubmitReject = reject;

								dispatch(
									initiateAuthorization(
										threeDSecureActionToken,
										useModal,
									),
								);
							} else {
								reject(
									onError
										? onError(error, values, helpers)
										: error,
								);
							}
						});
				})
				.catch((error) => {
					reject(
						onError
							? onError(error, values, helpers)
							: error,
					);
				});
		});
	}



	submit() {
		if (this.formRef.current) {
			this.formRef.current.submit();
		}
	}



	render() {
		const {
			accountId,
			children,
			isAccountUsingStripe,
			isAuthorizing,
			onError,
			onSubmit,
			resultToken,
		} = this.props;

		if (isAccountUsingStripe === null) {
			return null;
		}

		if (isAccountUsingStripe) {
			return (
				<StripePaymentAuthorizationContext
					accountId={accountId}
					onError={onError}
					onSubmit={onSubmit}
				>
					{children}
				</StripePaymentAuthorizationContext>
			);
		}

		const isResubmitting = isAuthorizing && !!resultToken;

		return (
			<Context.Provider value={createContextValue(this._cancelAuthorization, isAuthorizing, isResubmitting)}>
				{children({
					cancelAuthorization: this._cancelAuthorization,
					formRef: this.formRef,
					handleFormSuccess: this._handleFormSuccess,
					isAuthorizing,
					isResubmitting,
				})}
			</Context.Provider>
		);
	}

}

const ConnectedPaymentAuthorizationContext = connect(select)(PaymentAuthorizationContext);

ConnectedPaymentAuthorizationContext.propTypes = {
	formRef: PropTypes.object,
	onBillingInfoTokenization: PropTypes.func,
	onError: PropTypes.func,
	onSubmit: PropTypes.func.isRequired,
	useModal: PropTypes.bool.isRequired,
};



export const PaymentAuthorizationContextContext = Context;

export default ConnectedPaymentAuthorizationContext;
