import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum AdobeAnalyticsIconVariants {
	Active = 'active',
	Inactive = 'inactive',
}



type AdobeAnalyticsIconBuilderProps = {
	variant?: AdobeAnalyticsIconVariants,
};

const AdobeAnalyticsIconBuilder: IconBuilder<AdobeAnalyticsIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = AdobeAnalyticsIconVariants.Active,
		} = props;

		if (variant === AdobeAnalyticsIconVariants.Inactive) {
			return (
				<g
					fill="none"
					fillRule="evenodd"
					id="Page-1"
					stroke="none"
					strokeWidth="1"
				>
					<path
						d="M21.4643 21.1595H17.7957C17.6363 21.1625 17.4797 21.1167 17.3457 21.0282C17.2117 20.9396 17.1064 20.8121 17.0433 20.662L13.0606 11.1111C13.0502 11.0739 13.0284 11.0411 12.9984 11.0176C12.9685 10.9942 12.9319 10.9812 12.8942 10.9807C12.8565 10.9802 12.8196 10.9922 12.7891 11.0148C12.7585 11.0375 12.7359 11.0697 12.7245 11.1066L10.2426 17.1707C10.2291 17.2035 10.2237 17.2392 10.227 17.2746C10.2302 17.3101 10.242 17.3441 10.2612 17.3738C10.2804 17.4034 10.3064 17.4278 10.337 17.4446C10.3676 17.4614 10.4017 17.4702 10.4365 17.4702H13.1646C13.2473 17.4702 13.3281 17.4952 13.397 17.542C13.4659 17.5888 13.5198 17.6554 13.5521 17.7334L14.7465 20.4597C14.7782 20.5362 14.7909 20.6195 14.7835 20.7023C14.776 20.785 14.7488 20.8646 14.7041 20.9339C14.6594 21.0032 14.5986 21.0601 14.5272 21.0995C14.4559 21.1388 14.3761 21.1594 14.2951 21.1595H4.36313C4.28842 21.159 4.21499 21.1396 4.14938 21.103C4.08376 21.0664 4.028 21.0137 3.98706 20.9496C3.94612 20.8855 3.92129 20.812 3.91476 20.7357C3.90823 20.6593 3.92022 20.5825 3.94965 20.5121L10.2671 5.08184C10.3317 4.91852 10.4428 4.77897 10.5859 4.6814C10.729 4.58383 10.8974 4.53277 11.0692 4.53489H14.713C14.8848 4.53256 15.0534 4.58353 15.1965 4.68113C15.3396 4.77872 15.4507 4.9184 15.5151 5.08184L21.8763 20.5121C21.9058 20.5824 21.9178 20.6591 21.9113 20.7353C21.9049 20.8116 21.8802 20.885 21.8394 20.949C21.7986 21.0131 21.743 21.0658 21.6776 21.1025C21.6121 21.1393 21.5389 21.1588 21.4643 21.1595V21.1595Z"
						fill="#ACB5C0"
					/>
				</g>
			);
		}

		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<path
					d="M20.5512 20.2246H16.8827C16.7233 20.2276 16.5666 20.1819 16.4326 20.0933C16.2987 20.0047 16.1934 19.8773 16.1303 19.7271L12.1476 10.1762C12.1372 10.139 12.1154 10.1063 12.0854 10.0828C12.0554 10.0593 12.0189 10.0464 11.9812 10.0459C11.9435 10.0454 11.9066 10.0573 11.876 10.08C11.8455 10.1027 11.8229 10.1348 11.8115 10.1717L9.32954 16.2358C9.31607 16.2686 9.31072 16.3043 9.31397 16.3398C9.31721 16.3752 9.32896 16.4093 9.34815 16.4389C9.36735 16.4686 9.39339 16.4929 9.42398 16.5097C9.45456 16.5266 9.48872 16.5354 9.52343 16.5354H12.2516C12.3343 16.5354 12.4151 16.5603 12.484 16.6071C12.5529 16.654 12.6068 16.7205 12.6391 16.7986L13.8335 19.5248C13.8652 19.6013 13.8778 19.6847 13.8704 19.7674C13.863 19.8502 13.8357 19.9298 13.791 19.9991C13.7463 20.0684 13.6856 20.1253 13.6142 20.1646C13.5429 20.204 13.4631 20.2246 13.3821 20.2246H3.4501C3.3754 20.2242 3.30197 20.2048 3.23635 20.1681C3.17073 20.1315 3.11497 20.0788 3.07404 20.0147C3.0331 19.9506 3.00826 19.8772 3.00173 19.8008C2.9952 19.7245 3.00719 19.6477 3.03662 19.5773L9.35412 4.147C9.41869 3.98369 9.52976 3.84414 9.67284 3.74656C9.81593 3.64899 9.98437 3.59793 10.1562 3.60005H13.7999C13.9718 3.59772 14.1403 3.64869 14.2835 3.74629C14.4266 3.84389 14.5377 3.98356 14.6021 4.147L20.9633 19.5773C20.9927 19.6476 21.0048 19.7243 20.9983 19.8005C20.9919 19.8767 20.9672 19.9501 20.9264 20.0142C20.8856 20.0783 20.83 20.131 20.7646 20.1677C20.6991 20.2044 20.6258 20.224 20.5512 20.2246Z"
					fill="#EB1101"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default AdobeAnalyticsIconBuilder;

