import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



let fastlyIconCounter = 1;



export enum FastlyIconVariants {
	Default = 'default',
	NotAvailable = 'not-available',
	NotUsed = 'not-used',
}

type FastlyIconBuilderProps = {
	variant?: FastlyIconVariants,
};

const FastlyIconBuilder: IconBuilder<FastlyIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = FastlyIconVariants.Default,
		} = props;

		const componentId = fastlyIconCounter++;

		if (variant === FastlyIconVariants.NotAvailable) {
			return (
				<g>
					<clipPath id={'fastly-na-icon-' + componentId}>
						<path d="m3 2h17.92v20.98h-17.92z" />
					</clipPath>
					<g clipPath={'url(#fastly-na-icon-' + componentId + ')'}>
						<path
							d="m19.64 3.11c.12 0 .24.04.34.12.23.19.27.53.08.76l-14.73001 18.04c-.11.13-.26.2-.42.2-.12 0-.24-.04-.34-.12-.23-.19-.27-.53-.08-.76l14.72001-18.04c.11-.13.26-.2.42-.2z"
							fill="#bcc5cf"
						/><g fill="#c3c9cf"><path d="m7.95 15.53c-.37-.62-.61-1.33-.65-2.1h.51v-.51h-.51c.14-2.28 1.97-4.11 4.25-4.24v.51h.51v-.51c.44.03.86.13 1.26.28l2.79-3.42c-.82-.46-1.72-.8-2.68-.98h.02v-1.47h.52v-1.09h-4.33v1.09h.52v1.47h.03c-4.09.75-7.19 4.33-7.19 8.65 0 2.2.82 4.2 2.16 5.75l2.79-3.42z" /><path d="m15.68 10.93c.35.59.58 1.27.62 2h-.51v.51h.51c-.12 2.3-1.95 4.15-4.25 4.28v-.5h-.51v.5c-.42-.02-.82-.12-1.2-.26l-2.79999 3.43c1.26.7 2.69999 1.11 4.23999 1.11h.02c4.86 0 8.79-3.94 8.79-8.79 0-2.19-.81-4.17-2.13-5.71l-2.8 3.43z" /></g>
					</g>
				</g>
			);
		}

		if (variant === FastlyIconVariants.NotUsed) {
			return (
				<g>
					<g clipPath={'url(#fastly-nu-icon-' + componentId + ')'}>
						<path
							d="M12.575 12.95C12.5969 13.025 12.6094 13.1125 12.6094 13.1969C12.6094 13.65 12.2469 14.025 11.8 14.025C11.3531 14.025 10.9906 13.6562 10.9906 13.1969C10.9906 12.7375 11.3531 12.3687 11.8 12.3687C11.8906 12.3687 11.9812 12.3844 12.0687 12.4156L13.7187 10.975L14.0406 11.2969L12.5719 12.95H12.575Z"
							fill="#96A0AB"
						/>
						<path
							d="M13.4156 4.5625H13.4469V3.0875H13.9656V2H9.64062V3.0875H10.1594V4.5625H10.1906C6.1 5.3125 3 8.89375 3 13.2063C3 18.0625 6.93437 22 11.7937 22H11.8156C16.675 22 20.6094 18.0625 20.6094 13.2063C20.6094 8.89375 17.5094 5.3125 13.4156 4.55937V4.5625ZM12.0594 17.7156V17.2156H11.55V17.7156C9.25313 17.5844 7.41875 15.7375 7.29688 13.4344H7.80625V12.925H7.3C7.4375 10.6437 9.26562 8.8125 11.5469 8.68125V9.1875H12.0562V8.68125C14.3375 8.8125 16.1687 10.6406 16.3031 12.925H15.7969V13.4344H16.3063C16.1844 15.7344 14.3531 17.5844 12.0531 17.7156H12.0594Z"
							fill="#96A0AB"
						/>
					</g>
					<defs>
						<clipPath id={'fastly-nu-icon-' + componentId}>
							<rect
								fill="white"
								height="20"
								transform="translate(3 2)"
								width="17.6062"
							/>
						</clipPath>
					</defs>
				</g>
			);
		}

		return (
			<g>
				<clipPath id={'fastly-icon-' + componentId}>
					<path d="m3 2h17.6062v20h-17.6062z" />
				</clipPath>
				<g
					clipPath={'url(#fastly-icon-' + componentId + ')'}
					fill="#e82c2a"
				>
					<path d="m12.575 12.95c.0219.075.0344.1625.0344.2469 0 .4531-.3625.8281-.8094.8281s-.8094-.3688-.8094-.8281c0-.4594.3625-.8282.8094-.8282.0906 0 .1812.0157.2687.0469l1.65-1.4406.3219.3219-1.4687 1.6531z" />
					<path d="m13.4156 4.5625h.0313v-1.475h.5187v-1.0875h-4.32498v1.0875h.51878v1.475h.0312c-4.0906.75-7.1906 4.33125-7.1906 8.6438 0 4.8562 3.93437 8.7937 8.7937 8.7937h.0219c4.8594 0 8.7938-3.9375 8.7938-8.7937 0-4.31255-3.1-7.8938-7.1938-8.64693zm-1.3562 13.1531v-.5h-.5094v.5c-2.29687-.1312-4.13125-1.9781-4.25312-4.2812h.50937v-.5094h-.50625c.1375-2.2813 1.96562-4.1125 4.2469-4.24375v.50625h.5093v-.50625c2.2813.13125 4.1125 1.95935 4.2469 4.24375h-.5062v.5094h.5094c-.1219 2.3-1.9532 4.15-4.2532 4.2812z" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default FastlyIconBuilder;

