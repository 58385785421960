import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const UnavailableIconBuilder: IconBuilder = {
	icon: ({ color = '#FF5859' }) => (
		<g
			fill="none"
			fillRule="evenodd"
			id="Page-1"
			opacity="0.3"
			stroke="none"
			strokeWidth="1"
		>
			<g
				fill={color}
				fillRule="nonzero"
				id="checklist-bullet--disabled"
				transform="translate(-3.000000, -3.000000)"
			>
				<g
					id="Group"
					transform="translate(15.000000, 14.955434) rotate(45.000000) translate(-15.000000, -14.955434) translate(4.821429, 4.776863)"
				>
					<g
						id="Rectangle"
						transform="translate(0.504202, 0.504202)"
					>
						<rect
							height="2.77310924"
							rx="1"
							width="19.4117647"
							x="0"
							y="8.31932772"
						>
						</rect>
						<rect
							height="19.4117647"
							rx="1"
							width="2.77310924"
							x="8.31932772"
							y="0"
						>
						</rect>
					</g>
				</g>
			</g>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default UnavailableIconBuilder;

