/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSegmentMutationVariables = GraphQL.Exact<{
  color: GraphQL.Scalars['String']['input'];
  filterDefinition: GraphQL.Scalars['FilterDefinition']['input'];
  iconName: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  label: GraphQL.Scalars['String']['input'];
  shortcode: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  sizeLimit: GraphQL.InputMaybe<GraphQL.Scalars['PageSegmentSizeLimit']['input']>;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CreateSegmentMutation = { readonly __typename?: 'Mutation', readonly CreateSegment: { readonly __typename?: 'CreateSegmentResult', readonly createdPageSegment: { readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null }, readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null }> } | null } } };

export type UpdateSegmentMutationVariables = GraphQL.Exact<{
  color: GraphQL.Scalars['String']['input'];
  filterDefinition: GraphQL.Scalars['FilterDefinition']['input'];
  iconName: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  label: GraphQL.Scalars['String']['input'];
  shortcode: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  segmentId: GraphQL.Scalars['PageSegmentId']['input'];
  sizeLimit: GraphQL.InputMaybe<GraphQL.Scalars['PageSegmentSizeLimit']['input']>;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateSegmentMutation = { readonly __typename?: 'Mutation', readonly UpdateSegment: { readonly __typename?: 'UpdateSegmentResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null }> } | null }, readonly updatedPageSegment: { readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null } } };

export type PageSegmentFragmentFragment = { readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null };

export const PageSegmentFragmentFragmentDoc = gql`
    fragment PageSegmentFragment on PageSegment {
  color
  filterDefinition
  icon {
    files {
      scaleFactor
      url
    }
    name
  }
  id
  isBeingReevaluated
  isManaged
  label
  name
  shortcode
  sizeLimit
}
    `;
export const CreateSegmentDocument = gql`
    mutation CreateSegment($color: String!, $filterDefinition: FilterDefinition!, $iconName: String, $label: String!, $shortcode: String, $sizeLimit: PageSegmentSizeLimit, $websiteId: WebsiteId!) {
  CreateSegment(
    color: $color
    filterDefinition: $filterDefinition
    iconName: $iconName
    label: $label
    shortcode: $shortcode
    sizeLimit: $sizeLimit
    websiteId: $websiteId
  ) {
    createdPageSegment {
      ...PageSegmentFragment
    }
    query {
      website(id: $websiteId) {
        id
        pageSegments {
          ...PageSegmentFragment
        }
      }
    }
  }
}
    ${PageSegmentFragmentFragmentDoc}`;
export type CreateSegmentMutationFn = Apollo.MutationFunction<CreateSegmentMutation, CreateSegmentMutationVariables>;

/**
 * __useCreateSegmentMutation__
 *
 * To run a mutation, you first call `useCreateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSegmentMutation, { data, loading, error }] = useCreateSegmentMutation({
 *   variables: {
 *      color: // value for 'color'
 *      filterDefinition: // value for 'filterDefinition'
 *      iconName: // value for 'iconName'
 *      label: // value for 'label'
 *      shortcode: // value for 'shortcode'
 *      sizeLimit: // value for 'sizeLimit'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCreateSegmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSegmentMutation, CreateSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSegmentMutation, CreateSegmentMutationVariables>(CreateSegmentDocument, options);
      }
export type CreateSegmentMutationHookResult = ReturnType<typeof useCreateSegmentMutation>;
export type CreateSegmentMutationResult = Apollo.MutationResult<CreateSegmentMutation>;
export type CreateSegmentMutationOptions = Apollo.BaseMutationOptions<CreateSegmentMutation, CreateSegmentMutationVariables>;
export const UpdateSegmentDocument = gql`
    mutation UpdateSegment($color: String!, $filterDefinition: FilterDefinition!, $iconName: String, $label: String!, $shortcode: String, $segmentId: PageSegmentId!, $sizeLimit: PageSegmentSizeLimit, $websiteId: WebsiteId!) {
  UpdateSegment(
    color: $color
    filterDefinition: $filterDefinition
    iconName: $iconName
    label: $label
    shortcode: $shortcode
    segmentId: $segmentId
    sizeLimit: $sizeLimit
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        pageSegments {
          ...PageSegmentFragment
        }
      }
    }
    updatedPageSegment {
      ...PageSegmentFragment
    }
  }
}
    ${PageSegmentFragmentFragmentDoc}`;
export type UpdateSegmentMutationFn = Apollo.MutationFunction<UpdateSegmentMutation, UpdateSegmentMutationVariables>;

/**
 * __useUpdateSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSegmentMutation, { data, loading, error }] = useUpdateSegmentMutation({
 *   variables: {
 *      color: // value for 'color'
 *      filterDefinition: // value for 'filterDefinition'
 *      iconName: // value for 'iconName'
 *      label: // value for 'label'
 *      shortcode: // value for 'shortcode'
 *      segmentId: // value for 'segmentId'
 *      sizeLimit: // value for 'sizeLimit'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateSegmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSegmentMutation, UpdateSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(UpdateSegmentDocument, options);
      }
export type UpdateSegmentMutationHookResult = ReturnType<typeof useUpdateSegmentMutation>;
export type UpdateSegmentMutationResult = Apollo.MutationResult<UpdateSegmentMutation>;
export type UpdateSegmentMutationOptions = Apollo.BaseMutationOptions<UpdateSegmentMutation, UpdateSegmentMutationVariables>;