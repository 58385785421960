import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import Button, {
	ButtonSize,
	ButtonStyle,
	ButtonType,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ButtonLivingLoader, {
	ButtonLivingLoaderSize,
	ButtonLivingLoaderStyle,
} from '~/components/patterns/buttons/parts/ButtonLivingLoader';
import ButtonNextIcon, {
	ButtonNextIconSize,
} from '~/components/logic/buttons/buttonParts/ButtonNextIcon';



const CLICK_TIMEOUT = 500;
export const SIZE_XXSMALL = ButtonSize.XXSmall;
export const SIZE_XSMALL = ButtonSize.XSmall;
export const SIZE_SMALL = ButtonSize.Small;
export const SIZE_MEDIUM = ButtonSize.Medium;
export const SIZE_DEFAULT = ButtonSize.Default;
export const SIZE_LARGE = ButtonSize.Large;
export const SIZE_XLARGE = ButtonSize.XLarge;
export const SIZE_XXLARGE = ButtonSize.XXLarge;
export const STYLE_ACTION = ButtonStyle.Action;
export const STYLE_ALERT = ButtonStyle.Alert;
export const STYLE_HIGHLIGHT = ButtonStyle.Highlight;
export const STYLE_HOLLOW = ButtonStyle.Hollow;
export const STYLE_HOLLOW_HIGHLIGHT = ButtonStyle.HollowHighlight;
export const TYPE_BUTTON = ButtonType.Button;
export const TYPE_RESET = ButtonType.Reset;
export const TYPE_SUBMIT = ButtonType.Submit;
export const WIDTH_AUTO = ButtonWidth.Auto;
export const WIDTH_FULLWIDTH = ButtonWidth.Fullwidth;
export const WIDTH_SUBMIT_BUTTON = ButtonWidth.SubmitButton;



class SubmitButton extends Component {

	constructor(props, context) {
		super(props, context);

		const {
			progress,
		} = props;

		this._handleClick = this._handleClick.bind(this);

		this.state = {
			preventClick: false,
			submissionInProgress: !!progress,
		};
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			progress,
		} = this.props;

		const {
			progress: nextProgress,
		} = nextProps;

		if (!progress && nextProgress) {
			this._startProgressState();
		} else if (progress && !nextProgress) {
			this._removeProgressState();
		}
	}



	componentWillUnmount() {
		clearTimeout(this.clickTimer);
	}



	_startProgressState() {
		clearTimeout(this.timer);

		this.setState({
			submissionInProgress: true,
		}, this._processFirstProgressState);
	}



	_removeProgressState() {
		clearTimeout(this.timer);

		this.setState({
			submissionInProgress: false,
		});
	}



	_setClickTimeout() {
		this.clickTimer = setTimeout(() => {
			this.setState({
				preventClick: false,
			});
		}, CLICK_TIMEOUT);
	}



	_handleClick(e) {
		e.stopPropagation();

		const {
			preventClick,
		} = this.state;

		const {
			disabled,
			onClickCallback,
		} = this.props;

		if (disabled) {
			e.preventDefault();

			return;
		}

		if (preventClick) {
			e.preventDefault();

			clearTimeout(this.clickTimer);
			this._setClickTimeout();
		} else {
			this.setState({
				preventClick: true,
			}, () => {
				this._setClickTimeout();
			});

			if (onClickCallback) {
				onClickCallback(e);
			}
		}
	}



	render() {
		const {
			disabled,
			children,
			className,
			icon,
			inactive,
			nextStepIcon,
			size,
			style,
			tabIndex,
			testId,
			tooltip,
			type,
			uppercase,
			width,
		} = this.props;

		const {
			submissionInProgress,
		} = this.state;

		let suffixIcon;

		if (nextStepIcon) {
			let iconSize = ButtonNextIconSize.Default;

			if (size === SIZE_LARGE || size === SIZE_XLARGE || size === SIZE_XXLARGE) {
				iconSize = ButtonNextIconSize.Large;
			}

			if (size === SIZE_SMALL || size === SIZE_XSMALL || size === SIZE_XXSMALL) {
				iconSize = ButtonNextIconSize.Small;
			}

			suffixIcon = (
				<ButtonNextIcon
					disabled={disabled || inactive}
					size={iconSize}
				/>
			);
		}

		let customProgressLoader;

		if (submissionInProgress !== false) {
			customProgressLoader = (
				<ButtonLivingLoader
					size={size === SIZE_SMALL ? ButtonLivingLoaderSize.Small : ButtonLivingLoaderSize.Default}
					style={style === STYLE_HOLLOW ? ButtonLivingLoaderStyle.Dark : ButtonLivingLoaderStyle.Light}
				/>
			);
		}

		let componentStyle = style;

		if (inactive) {
			componentStyle = ButtonStyle.Inactive;
		}

		return (
			<Button
				className={className}
				customProgressLoader={customProgressLoader}
				disabled={disabled}
				icon={icon}
				onClick={this._handleClick}
				progress={submissionInProgress}
				size={size}
				style={componentStyle}
				suffixIcon={suffixIcon}
				tabIndex={tabIndex}
				testId={testId}
				tooltip={tooltip}
				type={type}
				uppercase={uppercase}
				width={width}
			>
				{children}
			</Button>
		);
	}

}

SubmitButton.defaultProps = {
	disabled: false,
	inactive: false,
	progress: false,
	size: SIZE_DEFAULT,
	style: STYLE_ACTION,
	type: TYPE_SUBMIT,
	uppercase: false,
	width: WIDTH_SUBMIT_BUTTON,
};

SubmitButton.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.node,
	inactive: PropTypes.bool,
	nextStepIcon: PropTypes.bool,
	onClickCallback: PropTypes.func,
	progress: PropTypes.bool,
	size: PropTypes.oneOf([
		SIZE_XXSMALL,
		SIZE_XSMALL,
		SIZE_SMALL,
		SIZE_MEDIUM,
		SIZE_DEFAULT,
		SIZE_LARGE,
		SIZE_XLARGE,
		SIZE_XXLARGE,
	]),
	style: PropTypes.oneOf([
		STYLE_ACTION,
		STYLE_ALERT,
		STYLE_HIGHLIGHT,
		STYLE_HOLLOW,
		STYLE_HOLLOW_HIGHLIGHT,
	]).isRequired,
	tabIndex: PropTypes.number,
	testId: PropTypes.string,
	type: PropTypes.oneOf([
		TYPE_BUTTON,
		TYPE_RESET,
		TYPE_SUBMIT,
	]).isRequired,
	uppercase: PropTypes.bool,
	width: PropTypes.oneOf([
		WIDTH_AUTO,
		WIDTH_FULLWIDTH,
		WIDTH_SUBMIT_BUTTON,
	]),
};



export default SubmitButton;
