import memoize from 'memoizee';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import usePermission from '~/hooks/usePermission';

import {
	ObjectType,
} from '~/model/permissions';



const createCallback = memoize(
	(isAllowed: ReturnType<typeof usePermission>) => (
		invitationId: CK.InvitationId | null,
		action: GraphQL.ActionWithInvitation,
	) => {
		return isAllowed({
			action,
			objectId: invitationId,
			objectType: ObjectType.Invitation,
		});
	},
);



function useIsAllowedWithInvitationOracle() {
	const isAllowed = usePermission();

	return createCallback(isAllowed);
}



export default useIsAllowedWithInvitationOracle;
