import Immutable, {
	List,
	Map,
} from 'immutable';
import memoizee from 'memoizee';

import CK from '~/types/contentking';

import {
	get,
} from './api/API';

import {
	ALERT_CANONICAL_LINK_CHANGED,
	ALERT_CONTENT_CHANGED_H1,
	ALERT_CONTENT_CHANGED_META_DESCRIPTION,
	ALERT_CONTENT_CHANGED_TITLE,
	ALERT_ENRICHMENT_FIELD_CHANGED,
	ALERT_HREFLANGS_CHANGED,
	ALERT_PAGE_BECAME_NON_INDEXABLE,
	ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_ANALYTICS_MISSING,
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_VISUAL_ANALYTICS_MISSING,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_INCORRECTLY_CANONICALIZED,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_POINTS_TO_UNINDEXABLE,
	ALERT_PAGE_ISSUE_OPENED_H1_MISSING,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_FCP,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_LCP,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_PERFORMANCE,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_SI,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TBT,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TTI,
	ALERT_PAGE_ISSUE_OPENED_LINKS_BROKEN,
	ALERT_PAGE_ISSUE_OPENED_META_DESCRIPTION_MISSING,
	ALERT_PAGE_ISSUE_OPENED_TITLE_MISSING,
	ALERT_PAGE_TYPE_CHANGED,
	ALERT_PROPERTY_CHANGED,
	SEGMENT_CHANGE_TYPE_PAGES_ENTERED,
	SEGMENT_CHANGE_TYPE_PAGES_LEFT,
} from './alerts';

import {
	encodeFilterParameter,
} from './filterParameter';

import removeDefaultValues from '~/utilities/removeDefaultValues';



export const STATUS_ADDED = 'added';
export const STATUS_CHANGED = 'changed';
export const STATUS_CHANGED_TO_FALSE = 'changedToFalse';
export const STATUS_CHANGED_TO_TRUE = 'changedToTrue';
export const STATUS_CLOSED = 'closed';
export const STATUS_DECREASED = 'decreased';
export const STATUS_ENTERED = 'entered';
export const STATUS_IGNORED = 'ignored';
export const STATUS_INCREASED = 'increased';
export const STATUS_LEFT = 'left';
export const STATUS_MOVED_BACKWARD = 'movedBackward';
export const STATUS_MOVED_FORWARD = 'movedForward';
export const STATUS_NO_LONGER_MATCHING_CRITERIA = 'no_longer_matching_criteria';
export const STATUS_NOT_APPLICABLE = 'not_applicable';
export const STATUS_NOT_PAGE = 'not_page';
export const STATUS_NOT_REQUIRED = 'not_required';
export const STATUS_OPEN = 'open';
export const STATUS_OVER_QUOTA = 'over_quota';
export const STATUS_REMOVED = 'removed';
export const STATUS_RESTORED = 'restored';
export const STATUS_REVERTED = 'reverted';
export const STATUS_UNKNOWN = 'unknown';

export const LIST_OF_PRIMARY_STATUSES = List([
	STATUS_ADDED,
	STATUS_CHANGED,
	STATUS_CHANGED_TO_FALSE,
	STATUS_CHANGED_TO_TRUE,
	STATUS_DECREASED,
	STATUS_ENTERED,
	STATUS_INCREASED,
	STATUS_LEFT,
	STATUS_MOVED_BACKWARD,
	STATUS_MOVED_FORWARD,
	STATUS_OPEN,
	STATUS_REMOVED,
]);

export const LIST_OF_SECONDARY_STATUSES = List([
	STATUS_CLOSED,
	STATUS_NO_LONGER_MATCHING_CRITERIA,
	STATUS_RESTORED,
	STATUS_REVERTED,
]);

export const LIST_OF_TERTIARY_STATUSES = List([
	STATUS_IGNORED,
	STATUS_NOT_REQUIRED,
	STATUS_NOT_APPLICABLE,
	STATUS_UNKNOWN,
	STATUS_NOT_PAGE,
	STATUS_OVER_QUOTA,
]);

export const LIST_OF_ALL_STATUSES = List()
	.concat(LIST_OF_PRIMARY_STATUSES)
	.concat(LIST_OF_SECONDARY_STATUSES)
	.concat(LIST_OF_TERTIARY_STATUSES);



const DEFAULT_FILTERS = Immutable.fromJS({
	[ALERT_CANONICAL_LINK_CHANGED]: {
		status: List([
			STATUS_CHANGED,
			STATUS_REVERTED,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_CONTENT_CHANGED_H1]: {
		status: List([
			STATUS_CHANGED,
			STATUS_REVERTED,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_CONTENT_CHANGED_META_DESCRIPTION]: {
		status: List([
			STATUS_CHANGED,
			STATUS_REVERTED,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_CONTENT_CHANGED_TITLE]: {
		status: List([
			STATUS_CHANGED,
			STATUS_REVERTED,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_HREFLANGS_CHANGED]: {
		status: List([
			STATUS_CHANGED,
			STATUS_REVERTED,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_BECAME_NON_INDEXABLE]: {
		status: List([
			STATUS_CHANGED,
			STATUS_REVERTED,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT]: {
		status: List([
			STATUS_ENTERED,
			STATUS_LEFT,
			STATUS_REVERTED,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_ANALYTICS_ANALYTICS_MISSING]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_ANALYTICS_VISUAL_ANALYTICS_MISSING]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_INCORRECTLY_CANONICALIZED]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_NOT_APPLICABLE,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_POINTS_TO_UNINDEXABLE]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_H1_MISSING]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_NOT_REQUIRED,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_FCP]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_LCP]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_PERFORMANCE]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_SI]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TBT]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TTI]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_LINKS_BROKEN]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_UNKNOWN,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_META_DESCRIPTION_MISSING]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_NOT_REQUIRED,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_ISSUE_OPENED_TITLE_MISSING]: {
		status: List([
			STATUS_OPEN,
			STATUS_CLOSED,
			STATUS_IGNORED,
			STATUS_NOT_REQUIRED,
			STATUS_NOT_PAGE,
			STATUS_OVER_QUOTA,
		]),
	},
	[ALERT_PAGE_TYPE_CHANGED]: {
		status: List([
			STATUS_CHANGED,
			STATUS_RESTORED,
			STATUS_NOT_APPLICABLE,
			STATUS_OVER_QUOTA,
		]),
	},
});

const DEFAULT_SORT_BYS = Immutable.fromJS({
	[ALERT_CANONICAL_LINK_CHANGED]: {
		key: CK.PagesCommonColumn.Relevance,
		direction: false,
	},
	[ALERT_CONTENT_CHANGED_H1]: {
		key: CK.PagesCommonColumn.Relevance,
		direction: false,
	},
	[ALERT_CONTENT_CHANGED_META_DESCRIPTION]: {
		key: CK.PagesCommonColumn.Relevance,
		direction: false,
	},
	[ALERT_CONTENT_CHANGED_TITLE]: {
		key: CK.PagesCommonColumn.Relevance,
		direction: false,
	},
	[ALERT_ENRICHMENT_FIELD_CHANGED]: {
		key: 'status',
		direction: false,
	},
	[ALERT_HREFLANGS_CHANGED]: {
		key: CK.PagesCommonColumn.Relevance,
		direction: false,
	},
	[ALERT_PAGE_BECAME_NON_INDEXABLE]: {
		key: CK.PagesCommonColumn.Relevance,
		direction: false,
	},
	[ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT]: {
		key: CK.PagesCommonColumn.Url,
		direction: true,
	},
	[ALERT_PAGE_ISSUE_OPENED_ANALYTICS_ANALYTICS_MISSING]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_ANALYTICS_VISUAL_ANALYTICS_MISSING]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_INCORRECTLY_CANONICALIZED]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_POINTS_TO_UNINDEXABLE]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_H1_MISSING]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_FCP]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_LCP]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_PERFORMANCE]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_SI]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TBT]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TTI]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_LINKS_BROKEN]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_META_DESCRIPTION_MISSING]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_ISSUE_OPENED_TITLE_MISSING]: {
		key: 'status',
		direction: false,
	},
	[ALERT_PAGE_TYPE_CHANGED]: {
		key: CK.PagesCommonColumn.Url,
		direction: false,
	},
	[ALERT_PROPERTY_CHANGED]: {
		key: 'status',
		direction: false,
	},
});



export function createAlertPagesFilterParameter(filter, sortBy, alertType, settings) {
	return encodeFilterParameter({
		defaultFilter: getDefaultFilter(alertType, settings),
		defaultSortBy: getDefaultSortBy(alertType),
		filter,
		sortBy,
	});
}



export const createFilter = memoizee((filter, alertType, settings) => {
	return getDefaultFilter(alertType, settings).merge(filter);
});



export const createSortBy = memoizee((sortBy, alertType) => {
	return getDefaultSortBy(alertType).merge(sortBy);
});



export function getDefaultFilter(alertType, settings) {
	if (alertType === ALERT_ENRICHMENT_FIELD_CHANGED) {
		return Immutable.Map({
			status: Immutable.List(listApplicableStatuses(alertType, settings)),
		});
	}

	if (alertType === ALERT_PROPERTY_CHANGED) {
		return Immutable.Map({
			status: Immutable.List(listApplicableStatuses(alertType, settings)),
		});
	}

	return DEFAULT_FILTERS.get(alertType, Map());
}



export function getDefaultSortBy(alertType) {
	return DEFAULT_SORT_BYS.get(alertType, Map());
}



export function listApplicableStatuses(alertType, settings) {
	if (settings.has('statuses')) {
		return settings.get('statuses').get('primary')
			.concat(Immutable.List(
				settings.get('statuses').get('reverted') !== null
					? [settings.get('statuses').get('reverted')]
					: [],
			))
			.concat(settings.get('statuses').get('secondary'))
			.concat(settings.get('statuses').get('tertiary'))
			.toArray();
	}

	return getDefaultFilter(alertType, settings).get('status').toArray().filter((status) => {
		if (
			alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT
			&& status === STATUS_LEFT
			&& settings.get('segmentChangeType') === SEGMENT_CHANGE_TYPE_PAGES_ENTERED
		) {
			return false;
		}

		if (
			alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT
			&& status === STATUS_ENTERED
			&& settings.get('segmentChangeType') === SEGMENT_CHANGE_TYPE_PAGES_LEFT
		) {
			return false;
		}

		return true;
	});
}



export function loadAlertPagesViaApi(websiteId, alertId, params) {
	return get('/websites/' + websiteId + '/alerts/' + alertId + '/pages', params, {
		timeout: 60000,
	});
}



export function removeDefaultFilterValues(activeFilters, alertType, settings) {
	return removeDefaultValues(
		activeFilters,
		getDefaultFilter(alertType, settings),
	);
}
