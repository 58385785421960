import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import DataLoadingFramework from '~/components/logic/datatables/DataLoadingFramework';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';



type Props = {
	columns: any,
	loadData: () => any,
	sitemaps: any,
	tableWidth: number,
};

const SitemapsTable: React.FC<Props> = (props) => {
	const {
		columns,
		loadData,
		sitemaps,
		tableWidth,
	} = props;

	const dispatch = useDispatch();

	const loadDataCallback = React.useCallback(
		() => {
			return dispatch(loadData());
		},
		[
			dispatch,
			loadData,
		],
	);

	React.useEffect(
		() => {
			loadDataCallback();
		},
		[
			loadDataCallback,
		],
	);

	const rows = React.useMemo(
		() => {
			if (!sitemaps.get('loaded')) {
				return null;
			}

			return sitemaps
				.get('range')
				.map((sitemapId) => sitemaps.get('records').get(sitemapId.toString()))
				.toJS();
		},
		[
			sitemaps,
		],
	);

	return (
		<DataLoadingFramework
			loadDataCallback={loadDataCallback}
			syncInterval={1000}
		>
			{() => (
				<SmallTable
					columns={columns}
					rows={rows}
					tableWidth={tableWidth}
				/>
			)}
		</DataLoadingFramework>
	);
};



export default React.memo(SitemapsTable);
