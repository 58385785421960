import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	TYPE_SITEMAP,
	TYPE_SITEMAP_INDEX,
} from '~/model/sitemaps';



const messages = defineMessages({
	[TYPE_SITEMAP]: {
		id: 'ui.screen.sitemapsList.cells.type.sitemap',
	},
	[TYPE_SITEMAP_INDEX]: {
		id: 'ui.screen.sitemapsList.cells.type.index',
	},
});



type Props = {
	sitemapType: (
		| typeof TYPE_SITEMAP
		| typeof TYPE_SITEMAP_INDEX
	),
};

const SitemapTypeName: React.FC<Props> = (props) => {
	const {
		sitemapType,
	} = props;

	const message = messages[sitemapType];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};


export default SitemapTypeName;
