import {
	createSelector,
} from 'reselect';

import {
	currentDataSelector as currentIncidentsDataSelector,
} from '../incidents/selectors';

import {
	alertIdSelector,
} from '../ui/content/selectors';

import {
	selectedWebsiteIdSelector,
} from '../ui/selectors';

import {
	createFilter,
	createSortBy,
} from '~/model/alertPages';



const selector = (state) => state.get('alertPages');

const dataSelector = (state) => selector(state).get('data');

export const filterSelector = (state) => selector(state).get('filter');

export const loadingSelector = (state) => selector(state).get('loading');

export const sortBySelector = (state) => selector(state).get('sortBy');



export const currentAlertSelector = (state) => {
	const alertId = alertIdSelector(state);
	const incidents = currentIncidentsDataSelector(state);

	let currentAlert = null;

	if (incidents) {
		currentAlert = incidents.get(alertId) ?? incidents.get(alertId + '') ?? null;
	}

	return currentAlert;
};

export const currentAlertTypeSelector = (state) => {
	const currentAlert = currentAlertSelector(state);

	return currentAlert
		? currentAlert.get('type')
		: null;
};

export const currentDataSelector = (state) => dataSelector(state).get(selectedWebsiteIdSelector(state));

export const currentFilterSelector = createSelector(
	currentAlertSelector,
	currentAlertTypeSelector,
	filterSelector,
	(alert, alertType, filter) => {
		return createFilter(
			filter,
			alertType,
			alert ? alert.get('settings') : null,
		);
	},
);

export const currentSortBySelector = (state) => {
	return createSortBy(
		sortBySelector(state),
		currentAlertTypeSelector(state),
	);
};
