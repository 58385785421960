import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import FormDescription from '~/components/atoms/forms/components/rowParts/FormDescription';
import GenericSegmentSelectField from '~/components/atoms/forms/components/SegmentSelectField';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';

import useFormContext from '~/hooks/useFormContext';
import useScopeSize from '../hooks/useScopeSize';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';



const messages = defineMessages({
	currentSize: {
		id: 'ui.alertsConfiguration.fields.segment.currentSize',
	},
});



type Props = {
	name: string,
	websiteId: CK.WebsiteId,
};

const SegmentSelectField: React.FC<Props> = (props) => {
	const {
		name,
		websiteId,
	} = props;

	const formContext = useFormContext();
	const scopeSize = useScopeSize(websiteId, `segment/${formContext.values[name]}`);
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	return (
		<List size={ListSize.Small}>
			<GenericSegmentSelectField
				inAlertDefinitions={true}
				name={name}
				segments={segmentDefinitions.listAll()}
				websiteScopeName={null}
			/>

			<FormDescription>
				{scopeSize !== null ? (
					<FormattedMessage
						{...messages.currentSize}
						values={{
							size: scopeSize,
						}}
					/>
				) : (
					<span>&nbsp;</span>
				)}
			</FormDescription>
		</List>
	);
};



export default SegmentSelectField;
