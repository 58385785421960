import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	UNRELIABLE_RESPONSE_CONNECT_ISSUE,
	UNRELIABLE_RESPONSE_EMPTY_RESPONSE,
	UNRELIABLE_RESPONSE_LIGHTHOUSE_FAILED,
	UNRELIABLE_RESPONSE_REDIRECT_CLIENT,
	UNRELIABLE_RESPONSE_RENDER_TIMEOUT,
	UNRELIABLE_RESPONSE_SIZE_LIMIT,
	UNRELIABLE_RESPONSE_TIMEOUT,
	UNRELIABLE_RESPONSE_TOO_MANY_ON_PAGE_REQUESTS,
	UNRELIABLE_RESPONSE_WEB_APPLICATION_FIREWALL,
} from '~/model/pages';



const messages = defineMessages({
	[UNRELIABLE_RESPONSE_CONNECT_ISSUE]: {
		id: 'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.connectIssue',
	},
	[UNRELIABLE_RESPONSE_EMPTY_RESPONSE]: {
		id: 'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.emptyResponse',
	},
	[UNRELIABLE_RESPONSE_LIGHTHOUSE_FAILED]: {
		id: 'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.lighthouseTimeout',
	},
	[UNRELIABLE_RESPONSE_REDIRECT_CLIENT]: {
		id: 'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.redirectClient',
	},
	[UNRELIABLE_RESPONSE_RENDER_TIMEOUT]: {
		id: 'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.renderTimeout',
	},
	[UNRELIABLE_RESPONSE_SIZE_LIMIT]: {
		id: 'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.sizeLimit',
	},
	[UNRELIABLE_RESPONSE_TIMEOUT]: {
		id: 'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.timeout',
	},
	[UNRELIABLE_RESPONSE_TOO_MANY_ON_PAGE_REQUESTS]: {
		id: 'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.too_many_on_page_requests',
	},
	[UNRELIABLE_RESPONSE_WEB_APPLICATION_FIREWALL]: {
		id: 'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.waf',
	},
});



type Props = {
	reason: (
		| typeof UNRELIABLE_RESPONSE_CONNECT_ISSUE
		| typeof UNRELIABLE_RESPONSE_EMPTY_RESPONSE
		| typeof UNRELIABLE_RESPONSE_LIGHTHOUSE_FAILED
		| typeof UNRELIABLE_RESPONSE_REDIRECT_CLIENT
		| typeof UNRELIABLE_RESPONSE_RENDER_TIMEOUT
		| typeof UNRELIABLE_RESPONSE_SIZE_LIMIT
		| typeof UNRELIABLE_RESPONSE_TIMEOUT
		| typeof UNRELIABLE_RESPONSE_TOO_MANY_ON_PAGE_REQUESTS
		| typeof UNRELIABLE_RESPONSE_WEB_APPLICATION_FIREWALL
		| string
	),
};

const UnreliableResponseName: React.FC<Props> = (props) => {
	let {
		reason,
	} = props;

	if (reason === 'time_limit') {
		reason = UNRELIABLE_RESPONSE_TIMEOUT;
	}

	if (reason === 'connect') {
		reason = UNRELIABLE_RESPONSE_CONNECT_ISSUE;
	}

	if (messages[reason]) {
		return (
			<FormattedMessage {...messages[reason]} />
		);
	}

	return <>{reason}</>;
};



export default UnreliableResponseName;
