import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import {
	linkInternal,
} from '~/components/logic/Copy';
import Emphasis from '~/components/patterns/typography/Emphasis';
import Grid, {
	GridGapsSize,
} from '~/components/patterns/structures/Grid';
import {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LighthousePerformanceScore from '~/components/app/LighthousePerformanceScore';
import LighthouseWebVitalMetric from '~/components/app/LighthouseWebVitalMetric';
import Measurer from '~/utilities/Measurer';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import RichText from '~/components/patterns/typography/RichText';

import {
	useLighthouseBlockQuery,
} from './LighthouseBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';

import {
	WebVital,
} from '~/model/webVitals';



const messages = defineMessages({
	dataNotAvailableYet: {
		id: 'ui.pageDetail.lighthouse.dataNotAvailableYet',
	},
	lighthouseNotEnabled: {
		id: 'ui.pageDetail.lighthouse.lighthouseNotEnabled',
	},
	title: {
		id: 'ui.pageDetail.lighthouse.title',
	},
	upsell: {
		id: 'ui.pageDetail.lighthouse.upsell',
	},
});



const LighthouseBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const isLighthouseMonitored = useWebsiteIsLighthouseMonitored(websiteId);
	const viewportType = useViewportType();

	const {
		data,
		loading,
	} = usePageDetailPropertiesQuery(
		useLighthouseBlockQuery,
		legacyUrlId,
		websiteId,
		{
			skip: !isLighthouseMonitored,
		},
	);

	const lighthouseData = data?.lookupPageByLegacyId?.pageTypeData?.lighthouseData;

	const noDataAvailableYet = (
		isLighthouseMonitored
		&& !loading
		&& lighthouseData === null
	);

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.LighthouseMonitoring}
			hideIfUnattainable={false}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => (
				<BorderedBox
					headerActionElements={premiumAnnotation}
					headerIcon={(
						<AttributesGroupIcon
							size={28}
							type={AttributesGroupIconType.Lighthouse}
						/>
					)}
					headerLabel={(
						<FormattedMessage {...messages.title} />
					)}
					paddingSize={3}
				>
					{!isFeatureEnabled ? (
						<RichText>
							<Emphasis>
								<FormattedMessage {...messages.upsell} />
							</Emphasis>
						</RichText>
					) : !isLighthouseMonitored ? (
						<RichText>
							<Emphasis>
								<FormattedMessage
									{...messages.lighthouseNotEnabled}
									values={{
										linkSettings: linkInternal(
											'website.detail.monitoring',
											{ websiteId },
											{ style: InternalLinkStyle.Decent },
										),
									}}
								/>
							</Emphasis>
						</RichText>
					) : noDataAvailableYet ? (
						<RichText>
							<Emphasis>
								<FormattedMessage {...messages.dataNotAvailableYet} />
							</Emphasis>
						</RichText>
					) : null}

					{isLighthouseMonitored && isFeatureEnabled && !noDataAvailableYet && (
						<Grid gapsSize={GridGapsSize.XLarge}>
							<LighthousePerformanceScore
								range={lighthouseData?.performanceRange}
								value={lighthouseData?.performance}
							/>

							<Measurer>
								{({ containerWidth }) => (
									<Grid
										columnsCount={viewportType.isSmall || containerWidth < 450 ? 1 : 2}
										gapsSize={
											viewportType.isSmall
												? GridGapsSize.Medium
												: GridGapsSize.Large
										}
									>
										<LighthouseWebVitalMetric
											range={lighthouseData?.fcpRange}
											type={WebVital.FCP}
											value={lighthouseData?.fcp}
										/>

										<LighthouseWebVitalMetric
											range={lighthouseData?.ttiRange}
											type={WebVital.TTI}
											value={lighthouseData?.tti}
										/>

										<LighthouseWebVitalMetric
											range={lighthouseData?.siRange}
											type={WebVital.SI}
											value={lighthouseData?.si}
										/>

										<LighthouseWebVitalMetric
											range={lighthouseData?.tbtRange}
											type={WebVital.TBT}
											value={lighthouseData?.tbt}
										/>

										<LighthouseWebVitalMetric
											range={lighthouseData?.lcpRange}
											type={WebVital.LCP}
											value={lighthouseData?.lcp}
										/>

										<LighthouseWebVitalMetric
											range={lighthouseData?.clsRange}
											type={WebVital.CLS}
											value={lighthouseData?.cls}
										/>
									</Grid>
								)}
							</Measurer>
						</Grid>
					)}
				</BorderedBox>
			)}
		</PremiumFeatureSituation>
	);
};



export default LighthouseBlock;
