export const BUCKETS = [
	{
		frequencyInSeconds: 1 * 60 * 60,
		size: 50,
	},
	{
		frequencyInSeconds: 2 * 60 * 60,
		size: 100,
	},
	{
		frequencyInSeconds: 4 * 60 * 60,
		size: 250,
	},
	{
		frequencyInSeconds: 6 * 60 * 60,
		size: 600,
	},
	{
		frequencyInSeconds: 8 * 60 * 60,
		size: 1_000,
	},
	{
		frequencyInSeconds: 12 * 60 * 60,
		size: 1_500,
	},
	{
		frequencyInSeconds: 18 * 60 * 60,
		size: 6_500,
	},
	{
		frequencyInSeconds: 1 * 60 * 60 * 24,
		size: 8_000,
	},
	{
		frequencyInSeconds: 2 * 60 * 60 * 24,
		size: 32_000,
	},
	{
		frequencyInSeconds: 4 * 60 * 60 * 24,
		size: 150_000,
	},
	{
		frequencyInSeconds: 8 * 60 * 60 * 24,
		size: 300_000,
	},
	{
		frequencyInSeconds: 250 * 60 * 60,
		size: 500_000,
	},
	{
		frequencyInSeconds: 333 * 60 * 60,
		size: 1_000_000,
	},
	{
		frequencyInSeconds: 542 * 60 * 60,
		size: 3_000_000,
	},
	{
		frequencyInSeconds: 750 * 60 * 60,
		size: 5_000_000,
	},
];
