import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const ScreenAnnotation: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="screen-annotation">
			<div className="screen-annotation__message">
				{children}
			</div>
		</div>
	);
};



export default ScreenAnnotation;
