import React from 'react';

import Caption from '~/components/patterns/headings/Caption';



type Props = {
	controlElements?: React.ReactNode,
	title?: React.ReactNode,
	titleSuffix?: React.ReactNode,
};



const SectionHeader: React.FC<Props> = (props) => {
	const {
		controlElements,
		title,
		titleSuffix,
	} = props;

	return (
		<section className="section-header">
			<div className="section-header__title">
				{title && (
					<Caption>
						{title}
					</Caption>
				)}
				{titleSuffix && (
					<div className="section-header__title-suffix">
						{titleSuffix}
					</div>
				)}
			</div>
			{controlElements && (
				<span className="section-header__control-elements">
					{controlElements}
				</span>
			)}
		</section>
	);
};



export default SectionHeader;
