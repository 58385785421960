import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



export const EXCLUDE_ELEMENTS = '.modal, .select-field-toggler, .select-field__dropdown, .popup-bubble, .field-status__bubble, .rc-slider-tooltip';



class CarouselDetailPanel extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleClose = this._handleClose.bind(this);
		this._handleOutsideClick = this._handleOutsideClick.bind(this);
	}



	_handleClose() {
		const {
			closeCallback,
		} = this.props;

		closeCallback();
	}



	_handleOutsideClick(e) {
		// Prevent closing during slider dragging
		// This trick solves situation when hovering Popper element during dragging in carousel detail panel
		if (document.getElementsByTagName('body')[0].classList.contains('js-count-slider-dragging')) {
			return false;
		}

		if (
			!this.refs.panelContent.contains(e.target)
			&& e.target.closest(EXCLUDE_ELEMENTS) === null
		) {
			this._handleClose();
		}
	}



	_disableCloseOnClick() {
		document.removeEventListener('click', this._handleOutsideClick, { capture: true });
	}



	_enableCloseOnClick() {
		document.addEventListener('click', this._handleOutsideClick, { capture: true });
	}



	componentDidMount() {
		const {
			visible,
		} = this.props;

		if (visible) {
			this._enableCloseOnClick();
		}
	}



	componentWillUnmount() {
		this._disableCloseOnClick();
	}



	UNSAFE_componentWillReceiveProps({ visible: nextVisible }) {
		const {
			visible,
		} = this.props;

		if (!visible && nextVisible) {
			this._enableCloseOnClick();
		}

		if (visible && !nextVisible) {
			this._disableCloseOnClick();
		}
	}



	render() {
		const {
			children,
			closeCallback,
			visible,
		} = this.props;

		const panelClasses = classNames({
			'carousel-panel': true,
			'js-scrollable': true,
			'js-scrollable-panel': true,
			'carousel-panel--active': visible,
		});

		let content = false;

		if (children) {
			content = React.cloneElement(children, {
				closeCallback,
			});
		}

		return (
			<section className={panelClasses}>
				<div
					className="carousel-panel__content"
					ref="panelContent"
				>
					<div
						className="carousel-panel__close-btn"
						onClick={this._handleClose}
					>
						<span>&times;</span>
					</div>
					{content}
				</div>
			</section>
		);
	}

}

CarouselDetailPanel.propTypes = {
	closeCallback: PropTypes.func.isRequired,
	visible: PropTypes.bool.isRequired,
};



export default CarouselDetailPanel;
