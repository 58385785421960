import {
	CellMeasurerCache,
} from 'react-virtualized';



type Cache = Record<string, number>;



class EstimatedCellMeasurerCache extends CellMeasurerCache {

	_cellEstimatedHeightCache: Cache = {};
	_cellEstimatedWidthCache: Cache = {};
	_columnEstimatedWidthCache: Cache = {};
	_rowEstimatedHeightCache: Cache = {};



	clear(rowIndex: number, columnIndex: number = 0): void {
		super.clear(rowIndex, columnIndex);

		this._updateCachedEstimatedColumnAndRowSizes(rowIndex, columnIndex);
	}



	clearAll(): void {
		super.clearAll();

		this._cellEstimatedHeightCache = {};
		this._cellEstimatedWidthCache = {};
		this._columnEstimatedWidthCache = {};
		this._rowEstimatedHeightCache = {};
	}



	columnWidth = ({ index }: { index: number }): number => {
		const key = (this as any)._keyMapper(0, index);

		return (
			(this as any)._columnWidthCache[key]
			?? (this as any)._columnEstimatedWidthCache[key]
			?? (this as any)._defaultWidth
		);
	};



	estimate(
		rowIndex: number,
		columnIndex: number,
		estimatedWidth: number | undefined,
		estimatedHeight: number | undefined,
	): void {
		const key = (this as any)._keyMapper(rowIndex, columnIndex);

		if (columnIndex >= (this as any)._columnCount) {
			(this as any)._columnCount = columnIndex + 1;
		}

		if (rowIndex >= (this as any)._rowCount) {
			(this as any)._rowCount = rowIndex + 1;
		}

		if (estimatedHeight !== undefined) {
			this._cellEstimatedHeightCache[key] = estimatedHeight;
		}

		if (estimatedWidth !== undefined) {
			this._cellEstimatedWidthCache[key] = estimatedWidth;
		}

		this._updateCachedEstimatedColumnAndRowSizes(rowIndex, columnIndex);
	}



	getAverageRowHeight(): number {
		if ((this as any)._rowCount === 0) {
			return (this as any)._defaultHeight;
		}

		let total = 0;

		for (let i = 0; i < (this as any)._rowCount; i++) {
			total += this.getHeight(i);
		}

		return total / (this as any)._rowCount;
	}



	getAverageColumnWidth(): number {
		if ((this as any)._columnCount === 0) {
			return (this as any)._defaultWidth;
		}

		let total = 0;

		for (let i = 0; i < (this as any)._columnCount; i++) {
			total += this.getWidth(0, i);
		}

		return total / (this as any)._columnCount;
	}



	getHeight(rowIndex: number, columnIndex: number = 0): number {
		if ((this as any)._hasFixedHeight) {
			return (this as any)._defaultHeight;
		}

		const key = (this as any)._keyMapper(rowIndex, columnIndex);

		const cellHeight = (
			(this as any)._cellHeightCache[key]
			?? this._cellEstimatedHeightCache[key]
		);

		if (cellHeight !== undefined) {
			return Math.max((this as any)._minHeight, cellHeight);
		}

		return (this as any)._defaultHeight;
	}



	getWidth(rowIndex: number, columnIndex: number = 0): number {
		if ((this as any)._hasFixedWidth) {
			return (this as any)._defaultWidth;
		}

		const key = (this as any)._keyMapper(rowIndex, columnIndex);

		const cellWidth = (
			(this as any)._cellWidthCache[key]
			?? this._cellEstimatedWidthCache[key]
		);

		if (cellWidth !== undefined) {
			return Math.max((this as any)._minWidth, cellWidth);
		}

		return (this as any)._defaultWidth;
	}



	getTotalHeight(): number {
		let total = 0;

		for (let i = 0; i < (this as any)._rowCount; i++) {
			total += this.getHeight(i, 0);
		}

		return total;
	}



	getTotalWidth(): number {
		let total = 0;

		for (let i = 0; i < (this as any)._columnCount; i++) {
			total += this.getWidth(0, i);
		}

		return total;
	}



	rowHeight = ({ index }: { index: number }): number => {
		const key = (this as any)._keyMapper(index, 0);

		return (
			(this as any)._rowHeightCache[key]
			?? this._rowEstimatedHeightCache[key]
			?? (this as any)._defaultHeight
		);
	};



	_updateCachedEstimatedColumnAndRowSizes(rowIndex: number, columnIndex: number): void {
		if (!(this as any)._hasFixedWidth) {
			let columnWidth = 0;

			for (let i = 0; i < (this as any)._rowCount; i++) {
				columnWidth = Math.max(columnWidth, this.getWidth(i, columnIndex));
			}

			const columnKey = (this as any)._keyMapper(0, columnIndex);
			this._columnEstimatedWidthCache[columnKey] = columnWidth;
		}

		if (!(this as any)._hasFixedHeight) {
			let rowHeight = 0;

			for (let i = 0; i < (this as any)._columnCount; i++) {
				rowHeight = Math.max(rowHeight, this.getHeight(rowIndex, i));
			}

			const rowKey = (this as any)._keyMapper(rowIndex, 0);
			this._rowEstimatedHeightCache[rowKey] = rowHeight;
		}
	}

}



export default EstimatedCellMeasurerCache;
