import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Default state of component - whether we will show string or directly edit mode */
	editMode?: boolean,
	/** Optionally we can set value visible in edit mode. When not set, we will use `value` */
	editableValue?: string | number,
	/** Additional HTML attributes to input field in enabled edit mode */
	inputAttributes?: React.HTMLProps<HTMLInputElement>,
	/** Type of input field. Default type is "text" */
	inputType?: React.HTMLInputTypeAttribute,
	/** Required callback triggered on change of value in input field */
	onChangeCallback: (value: any) => void,
	/** Value we would like to use as editable string */
	value: string | number,
};



const EditableString: React.FC<Props> = (props) => {
	const {
		editMode = false,
		editableValue,
		inputAttributes,
		inputType = 'text',
		onChangeCallback,
		value,
	} = props;

	const [isInEditMode, setEditMode] = React.useState(editMode);
	const [inputWidth, setInputWidth] = React.useState(0);

	const inputRef = React.useRef<HTMLInputElement | null>(null);
	const stringRef = React.useRef<HTMLSpanElement | null>(null);
	const wrapperRef = React.useRef<HTMLSpanElement | null>(null);

	const virtualElement = document.createElement('canvas');

	const getInputTextWidth = React.useCallback(
		(text) => {
			let fontWeight = '';
			let fontSize = '';
			let fontFamily = '';

			if (!wrapperRef.current) {
				return inputWidth;
			}

			const styles = window.getComputedStyle(wrapperRef.current, null);
			fontWeight = styles.getPropertyValue('font-weight');
			fontSize = styles.getPropertyValue('font-size');
			fontFamily = styles.getPropertyValue('font-family');

			const context = virtualElement.getContext('2d');

			if (!context) {
				return inputWidth;
			}

			context.font = fontWeight + ' ' + fontSize + ' ' + fontFamily;
			const metrics = context.measureText(text);
			return metrics.width;
		},
		[
			inputWidth,
			virtualElement,
		],
	);

	const handleInputFieldChange = React.useCallback(
		(value) => {
			setEditMode(false);
			onChangeCallback(value);
		},
		[
			onChangeCallback,
		],
	);

	const handleInputKeyDown = React.useCallback(
		(e) => {
			e.stopPropagation();

			if (isInEditMode) {
				// esc key
				if (e.key === 'Escape') {
					setEditMode(false);
				}

				// hit enter key
				if (e.key === 'Enter') {
					let newValue = e.target.value;

					if (inputType === 'number' || inputType === 'tel') {
						newValue = parseInt(newValue);

						if (!isNaN(newValue)) {
							handleInputFieldChange(newValue);
						}
					} else {
						handleInputFieldChange(newValue);
					}
				}
			}
		},
		[
			handleInputFieldChange,
			inputType,
			isInEditMode,
		],
	);

	const handleStringKeyDown = React.useCallback(
		(e) => {
			e.stopPropagation();

			if (!isInEditMode) {
				// hit enter key
				if (e.key === 'Enter') {
					setEditMode(true);
				}
			}
		},
		[
			isInEditMode,
		],
	);

	const handleBlur = React.useCallback(
		(e) => {
			let newValue = e.target.value;

			if ((inputType === 'number' || inputType === 'tel')) {
				newValue = parseInt(newValue);

				if (!isNaN(newValue)) {
					handleInputFieldChange(newValue);
				}
			} else {
				handleInputFieldChange(newValue);
			}

			setEditMode(false);
		},
		[
			handleInputFieldChange,
			inputType,
		],
	);

	const handleChange = React.useCallback(
		(e) => {
			let changedValue: string = e.target.value;
			const oldValue = editableValue || value;

			if (inputType == 'number' || inputType == 'tel') {
				const caretPosition = e.target.selectionStart;

				// Remove non-numeric characters.
				// We will replace field values only when removing some non-numeric
				// characters. When we replace current value, we loose cursor position.
				const numericOnlyValue = changedValue.toString().replace(/\D/g, '');
				if (inputRef.current && changedValue.toString() != numericOnlyValue) {
					changedValue = numericOnlyValue;
					inputRef.current.value = changedValue;

					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					if (caretPosition && inputRef.current.setSelectionRange) {
						inputRef.current.setSelectionRange(caretPosition - 1, caretPosition - 1);
					}
				}
			}

			if (oldValue != changedValue && changedValue) {
				const width = getInputTextWidth(changedValue);
				setInputWidth(width < 33 ? 33 : width);
			}
		},
		[
			editableValue,
			getInputTextWidth,
			inputType,
			value,
		],
	);

	const handleEnterEditMode = React.useCallback(
		() => {
			if (stringRef.current) {
				setInputWidth(stringRef.current.getBoundingClientRect().width);
			}

			setEditMode(true);
		},
		[],
	);

	React.useEffect(
		() => {
			if (isInEditMode) {
				inputRef.current?.focus();
			} else {
				stringRef.current?.focus();
			}
		},
		[isInEditMode],
	);

	const componentClasses = classNames({
		'editable-string': true,
		'editable-string--in-edit-mode': isInEditMode,
	});

	return (
		<span
			className={componentClasses}
			ref={wrapperRef}
		>
			{isInEditMode ? (
				<input
					className="editable-string__input"
					defaultValue={editableValue || value}
					onBlur={handleBlur}
					onChange={handleChange}
					onKeyDown={handleInputKeyDown}
					ref={inputRef}
					style={{
						width: inputWidth,
					}}
					type={inputType}
					{...inputAttributes}
				/>
			) : (
				<span
					className="editable-string__string"
					onClick={handleEnterEditMode}
					onKeyDown={handleStringKeyDown}
					ref={stringRef}
					tabIndex={0}
				>
					{value}
				</span>
			)}
		</span>
	);
};



export default EditableString;
