import type Immutable from 'immutable';
import React from 'react';

import CK from '~/types/contentking';

import AnalyticServicesFormatter from './AnalyticServicesFormatter';
import BooleanFormatter from '~/components/app/BooleanFormatter';
import CanonicalTypeFormatter from './CanonicalTypeFormatter';
import ConcatenateFormatter from '~/components/app/ConcatenateFormatter';
import CurrencyFormatter from '~/components/app/CurrencyFormatter';
import CustomElementFormatter from './CustomElementFormatter';
import DateFormatter from '~/components/app/DateFormatter';
import DurationFormatter, {
	DurationFormatterUnit,
} from '~/components/app/DurationFormatter';
import EnrichmentFieldFormatter from '~/components/logic/formatters/EnrichmentFieldFormatter';
import HealthFormatter from './HealthFormatter';
import IsDisallowedFormatter from './IsDisallowedFormatter';
import IsIndexableDueToFormatter from './IsIndexableDueToFormatter';
import IsIndexableFormatter from './IsIndexableFormatter';
import NumberFormatter from '~/components/app/NumberFormatter';
import PageTypeFormatter from './PageTypeFormatter';
import SearchEngineLastVisitFormatter from './SearchEngineLastVisitFormatter';
import SearchEngineVisitFrequencyFormatter from './SearchEngineVisitFrequencyFormatter';
import PercentageFormatter from '~/components/app/PercentageFormatter';
import StringFormatter from '~/components/app/StringFormatter';
import ThirdPartyIntegrationValueFormatter, {
	ThirdPartyIntegrationValueFormatterType,
} from './ThirdPartyIntegrationValueFormatter';
import TimingFormatter, {
	TimingFormatterUnit,
} from '~/components/app/TimingFormatter';
import WebVitalValueFormatter from './WebVitalValueFormatter';

import {
	PagesColumnsCategory,
	getColumnCategory,
} from '~/model/pagesColumns';

import {
	WebVital,
} from '~/model/webVitals';



const WebVitalsMapping = {
	[CK.PagesCommonColumn.LighthouseCumulativeLayoutShift]: WebVital.CLS,
	[CK.PagesCommonColumn.LighthouseFirstContentfulPaint]: WebVital.FCP,
	[CK.PagesCommonColumn.LighthouseLargestContentfulPaint]: WebVital.LCP,
	[CK.PagesCommonColumn.LighthousePerformance]: WebVital.Performance,
	[CK.PagesCommonColumn.LighthouseSpeedIndex]: WebVital.SI,
	[CK.PagesCommonColumn.LighthouseTimeToInteractive]: WebVital.TTI,
	[CK.PagesCommonColumn.LighthouseTotalBlockingTime]: WebVital.TBT,
};



type Props = {
	column: CK.PagesColumn,
	customElements?: boolean | Array<string> | Immutable.List<string>,
	value: any,
};

const ColumnValueFormatter: React.FC<Props> = (props) => {
	const {
		column,
		customElements = false,
		value,
	} = props;

	const columnCategory = getColumnCategory(column);

	if (columnCategory === PagesColumnsCategory.CustomElements) {
		return (
			<CustomElementFormatter
				column={column}
				customElements={customElements}
				useTextInspector={false}
				value={value}
			/>
		);
	}

	if (columnCategory === PagesColumnsCategory.EnrichmentFields) {
		return (
			<EnrichmentFieldFormatter
				column={column}
				useTextInspector={false}
				value={value}
			/>
		);
	}

	if (
		column === CK.PagesCommonColumn.AaAverageTimeSpentOnSite
	) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<DurationFormatter
						unit={DurationFormatterUnit.Seconds}
						value={value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.AdobeAnalytics}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.AaBounceRate
	) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<PercentageFormatter
						maximumFractionDigits={1}
						value={value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.AdobeAnalytics}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.AaRevenue
	) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<CurrencyFormatter
						currency={value.get ? value.get('currency') : value.currency}
						value={value.get ? value.get('value') : value.value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.AdobeAnalytics}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.AaPageViews
		|| column === CK.PagesCommonColumn.AaUniqueVisitors
	) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<NumberFormatter
						maximumFractionDigits={1}
						value={value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.AdobeAnalytics}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.CanonicalUrl
		|| column === CK.PagesCommonColumn.Domain
		|| column === CK.PagesCommonColumn.H1
		|| column === CK.PagesCommonColumn.HreflangLanguage
		|| column === CK.PagesCommonColumn.LinkAmp
		|| column === CK.PagesCommonColumn.LinkNext
		|| column === CK.PagesCommonColumn.LinkPrev
		|| column === CK.PagesCommonColumn.MetaDescription
		|| column === CK.PagesCommonColumn.MobileVariant
		|| column === CK.PagesCommonColumn.OpenGraphDescription
		|| column === CK.PagesCommonColumn.OpenGraphImage
		|| column === CK.PagesCommonColumn.OpenGraphTitle
		|| column === CK.PagesCommonColumn.OpenGraphType
		|| column === CK.PagesCommonColumn.OpenGraphUrl
		|| column === CK.PagesCommonColumn.TwitterCard
		|| column === CK.PagesCommonColumn.TwitterDescription
		|| column === CK.PagesCommonColumn.TwitterImage
		|| column === CK.PagesCommonColumn.TwitterSite
		|| column === CK.PagesCommonColumn.TwitterTitle
		|| column === CK.PagesCommonColumn.Title
		|| column === CK.PagesCommonColumn.UrlFull
	) {
		return (
			<StringFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.AnalyticsServices
		|| column === CK.PagesCommonColumn.TagManagers
		|| column === CK.PagesCommonColumn.VisualAnalyticsServices
	) {
		return (
			<AnalyticServicesFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.CanonicalType
	) {
		return (
			<CanonicalTypeFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.TimeFound
	) {
		return (
			<DateFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.LfaBingLastVisit
		|| column === CK.PagesCommonColumn.LfaBingDesktopLastVisit
		|| column === CK.PagesCommonColumn.LfaBingMobileLastVisit
		|| column === CK.PagesCommonColumn.LfaGoogleLastVisit
		|| column === CK.PagesCommonColumn.LfaGoogleDesktopLastVisit
		|| column === CK.PagesCommonColumn.LfaGoogleMobileLastVisit
	) {
		return (
			<SearchEngineLastVisitFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.LfaBingFrequency
		|| column === CK.PagesCommonColumn.LfaBingDesktopFrequency
		|| column === CK.PagesCommonColumn.LfaBingMobileFrequency
		|| column === CK.PagesCommonColumn.LfaGoogleFrequency
		|| column === CK.PagesCommonColumn.LfaGoogleDesktopFrequency
		|| column === CK.PagesCommonColumn.LfaGoogleMobileFrequency
	) {
		return (
			<SearchEngineVisitFrequencyFormatter
				unit="per month"
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.GaAverageEngagementTime
		|| column === CK.PagesCommonColumn.GaAverageTime
	) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<DurationFormatter
						unit={DurationFormatterUnit.Seconds}
						value={value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.GoogleAnalytics}
				value={value}
			/>
		);
	} else if (column === CK.PagesCommonColumn.GaBounceRate) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<PercentageFormatter
						maximumFractionDigits={1}
						value={value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.GoogleAnalytics}
				value={value}
			/>
		);
	} else if (column === CK.PagesCommonColumn.GaEngagementRate) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<PercentageFormatter
						maximumFractionDigits={1}
						value={value * 100}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.GoogleAnalytics}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.GaPageValue
	) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<CurrencyFormatter
						currency={value.get ? value.get('currency') : value.currency}
						value={value.get ? value.get('value') : value.value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.GoogleAnalytics}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.GaActiveUsers
		|| column === CK.PagesCommonColumn.GaPageViews
		|| column === CK.PagesCommonColumn.GaScreenPageViews
		|| column === CK.PagesCommonColumn.GaUniquePageViews
	) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<NumberFormatter
						maximumFractionDigits={1}
						value={value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.GoogleAnalytics}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.GscClicks
		|| column === CK.PagesCommonColumn.GscImpressions
		|| column === CK.PagesCommonColumn.GscPosition
	) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<NumberFormatter
						maximumFractionDigits={1}
						value={value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.GoogleSearchConsole}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.GscCtr
	) {
		return (
			<ThirdPartyIntegrationValueFormatter
				formatter={(value) => (
					<PercentageFormatter
						maximumFractionDigits={1}
						value={value}
					/>
				)}
				type={ThirdPartyIntegrationValueFormatterType.GoogleSearchConsole}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.Health
	) {
		return (
			<HealthFormatter
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.LighthouseCumulativeLayoutShift
		|| column === CK.PagesCommonColumn.LighthouseFirstContentfulPaint
		|| column === CK.PagesCommonColumn.LighthouseLargestContentfulPaint
		|| column === CK.PagesCommonColumn.LighthousePerformance
		|| column === CK.PagesCommonColumn.LighthouseSpeedIndex
		|| column === CK.PagesCommonColumn.LighthouseTimeToInteractive
		|| column === CK.PagesCommonColumn.LighthouseTotalBlockingTime
	) {
		const type = WebVitalsMapping[column];

		return (
			<WebVitalValueFormatter
				type={type}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.HreflangChanges
		|| column === CK.PagesCommonColumn.NumberOfHreflangs
		|| column === CK.PagesCommonColumn.NumberOfIncomingInternalCanonicals
		|| column === CK.PagesCommonColumn.NumberOfIncomingInternalLinks
		|| column === CK.PagesCommonColumn.NumberOfIncomingInternalRedirects
		|| column === CK.PagesCommonColumn.NumberOfOutgoingExternalLinks
		|| column === CK.PagesCommonColumn.NumberOfOutgoingInternalLinks
		|| column === CK.PagesCommonColumn.UrlDepth
	) {
		return (
			<NumberFormatter value={value || 0} />
		);
	} else if (
		column === CK.PagesCommonColumn.SchemaOrgNumberOfTypes
	) {
		if (value === null) {
			return '';
		}

		return (
			<NumberFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.IsDisallowedInRobotsTxt
	) {
		return (
			<IsDisallowedFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.IsInSitemap
		|| column === CK.PagesCommonColumn.IsLinked
		|| column === CK.PagesCommonColumn.IsSecured
	) {
		return (
			<BooleanFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.IsIndexable
	) {
		return (
			<IsIndexableFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.IsIndexableDueToMetaRobots
		|| column === CK.PagesCommonColumn.IsIndexableDueToXRobotsTag
	) {
		return (
			<IsIndexableDueToFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.Redirect
	) {
		return (
			<StringFormatter value={value !== '' ? value : null} />
		);
	} else if (
		column === CK.PagesCommonColumn.Relevance
	) {
		return (
			<NumberFormatter
				maximumFractionDigits={2}
				minimumFractionDigits={2}
				value={parseFloat(value)}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.SchemaOrgTypes
	) {
		return (
			<ConcatenateFormatter value={value} />
		);
	} else if (
		column === CK.PagesCommonColumn.TimeServerResponse
	) {
		return (
			<TimingFormatter
				maximumFractionDigits={1}
				unit={TimingFormatterUnit.Milliseconds}
				value={value}
			/>
		);
	} else if (
		column === CK.PagesCommonColumn.Type
	) {
		return (
			<PageTypeFormatter value={value} />
		);
	}

	return value || false;
};



export default React.memo(ColumnValueFormatter);
