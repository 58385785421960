import React from 'react';

import type CK from '~/types/contentking';

import BillingCycleConfigurator from '~/components/logic/payments/BillingCycleConfigurator';
import List from '~/components/patterns/lists/List';
import ListOfBenefits from '~/components/logic/account/components/ListOfBenefits';
import MarginsList from '~/components/atoms/lists/MarginsList';
import ReviewsOverview from '~/components/logic/account/components/ReviewsOverview';
import SatisfiedCustomers from '~/components/logic/account/components/SatisfiedCustomers';
import SignupPriceSummary from './SignupPriceSummary';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';
import UnderlinedRows from '~/components/patterns/lists/UnderlinedRows';

import useAccountCustomerType from '~/hooks/useAccountCustomerType';

import {
	Screen,
} from '~/model/satisfiedCustomers';



type Props = {
	accountId: CK.AccountId | null,
	allowCustomization: boolean,
	assumeVatNumberWillBeGiven?: boolean,
	customerDetailsProvided: boolean,
};

const SignupModalSidebar: React.FC<Props> = (props) => {
	const {
		accountId,
		allowCustomization,
		assumeVatNumberWillBeGiven = false,
		customerDetailsProvided,
	} = props;

	const accountCustomerType = useAccountCustomerType(accountId);

	return (
		<MarginsList>
			{accountId !== null && (
				<SignupPriceSummary
					accountId={accountId}
					assumeVatNumberWillBeGiven={assumeVatNumberWillBeGiven}
					customerDetailsProvided={customerDetailsProvided}
					renderCurrencySelect={allowCustomization}
				/>
			)}

			{accountId !== null && allowCustomization && (
				<BillingCycleConfigurator
					accountId={accountId}
				/>
			)}

			<Spacer top={SpacerGap.Default}>
				<UnderlinedRows gapsSize={3}>
					<ListOfBenefits />
					<List>
						<SatisfiedCustomers
							customerType={accountCustomerType}
							screen={Screen.SignUpModal}
						/>
						<ReviewsOverview reviewsCount={3} />
					</List>
				</UnderlinedRows>
			</Spacer>
		</MarginsList>
	);
};



export default SignupModalSidebar;
