import classNames from 'classnames';
import React from 'react';



type Props = {
	children: React.ReactNode,
	plain?: boolean,
	unit: React.ReactNode,
};



const ValueWithUnit: React.FC<Props> = (props) => {
	const {
		children,
		plain = false,
		unit,
	} = props;

	const componentClasses = classNames({
		'value-with-unit': true,
		'value-with-unit--plain': plain,
	});

	return (
		<span className={componentClasses}>
			{children}
			<span>&nbsp;</span>
			<span className="value-with-unit__unit">{unit}</span>
		</span>
	);
};



export default ValueWithUnit;
