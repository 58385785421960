export function createNumberFilter<Item = any>(valueGetter: (item: Item) => number) {
	return (items: Array<Item>, filterValue: string): Array<Item> => {
		let number: number;
		let evaluator: (value: number) => boolean = (value) => value === number;

		filterValue = filterValue.trim();

		if (filterValue.startsWith('>')) {
			number = parseFloat(filterValue.substring(1));
			evaluator = (value): boolean => value > number;
		} else if (filterValue.startsWith('<')) {
			number = parseFloat(filterValue.substring(1));
			evaluator = (value): boolean => value < number;
		} else if (filterValue.startsWith('=')) {
			number = parseFloat(filterValue.substring(1));
		} else if (filterValue.startsWith('(') || filterValue.startsWith('[')) {
			const range = filterValue
				.replace(/[\(\)\[\]]/g, '')
				.split(';')
				.map((value) => parseFloat(value));

			const start = range[0] || -Infinity;
			const end = range[1] || Infinity;

			let startEvaluator: (value: number) => boolean = (): boolean => false;
			let endEvaluator: (value: number) => boolean = (): boolean => false;

			if (filterValue.startsWith('(')) {
				startEvaluator = (value: number): boolean => value > start;
			} else if (filterValue.startsWith('[')) {
				startEvaluator = (value: number): boolean => value >= start;
			}

			if (filterValue.endsWith(')')) {
				endEvaluator = (value: number): boolean => value < end;
			} else if (filterValue.endsWith(']')) {
				endEvaluator = (value: number): boolean => value <= end;
			}

			evaluator = (value): boolean => startEvaluator(value) && endEvaluator(value);
		} else {
			number = parseFloat(filterValue);
		}

		return items.filter((item) => evaluator(valueGetter(item)));
	};
}
