import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	DATA_TYPE_BOOLEAN,
	DATA_TYPE_DATE,
	DATA_TYPE_HASH,
	DATA_TYPE_INTEGER,
	DATA_TYPE_NUMBER,
	DATA_TYPE_STRING,
} from '~/model/customElements';



const messages = defineMessages({
	[DATA_TYPE_BOOLEAN]: {
		id: 'ui.customElements.elementTypes.boolean',
	},
	[DATA_TYPE_DATE]: {
		id: 'ui.customElements.elementTypes.date',
	},
	[DATA_TYPE_HASH]: {
		id: 'ui.customElements.elementTypes.string',
	},
	[DATA_TYPE_INTEGER]: {
		id: 'ui.customElements.elementTypes.number',
	},
	[DATA_TYPE_NUMBER]: {
		id: 'ui.customElements.elementTypes.number',
	},
	[DATA_TYPE_STRING]: {
		id: 'ui.customElements.elementTypes.string',
	},
});



type Props = {
	elementType:
		| typeof DATA_TYPE_BOOLEAN
		| typeof DATA_TYPE_DATE
		| typeof DATA_TYPE_HASH
		| typeof DATA_TYPE_INTEGER
		| typeof DATA_TYPE_NUMBER
		| typeof DATA_TYPE_STRING,
};

const ElementTypeName: React.FC<Props> = (props) => {
	const {
		elementType,
	} = props;

	return (
		<FormattedMessage {...messages[elementType]} />
	);
};



export default ElementTypeName;
