/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UrlIdQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UrlIdQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId } | null };


export const UrlIdDocument = gql`
    query UrlId($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
  }
}
    `;

/**
 * __useUrlIdQuery__
 *
 * To run a query within a React component, call `useUrlIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUrlIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUrlIdQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUrlIdQuery(baseOptions: Apollo.QueryHookOptions<UrlIdQuery, UrlIdQueryVariables> & ({ variables: UrlIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UrlIdQuery, UrlIdQueryVariables>(UrlIdDocument, options);
      }
export function useUrlIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UrlIdQuery, UrlIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UrlIdQuery, UrlIdQueryVariables>(UrlIdDocument, options);
        }
export function useUrlIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UrlIdQuery, UrlIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UrlIdQuery, UrlIdQueryVariables>(UrlIdDocument, options);
        }
export type UrlIdQueryHookResult = ReturnType<typeof useUrlIdQuery>;
export type UrlIdLazyQueryHookResult = ReturnType<typeof useUrlIdLazyQuery>;
export type UrlIdSuspenseQueryHookResult = ReturnType<typeof useUrlIdSuspenseQuery>;
export type UrlIdQueryResult = Apollo.QueryResult<UrlIdQuery, UrlIdQueryVariables>;