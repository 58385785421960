import {
	Map,
} from 'immutable';

import {
	encodeFilterParameter,
} from './filterParameter';



export const DEFAULT_FILTER = Map();

export const DEFAULT_SORT_BY = Map({
	key: 'number_of_incoming_internal_links',
	direction: false,
});



export function createAffectedLinksFilterParameter(filter, sortBy) {
	return encodeFilterParameter({
		defaultFilter: DEFAULT_FILTER,
		defaultSortBy: DEFAULT_SORT_BY,
		filter,
		sortBy,
	});
}
