import React from 'react';

import ChangeTrackingHeader from './ChangeTrackingHeader';
import PagesOverviewHeader from './PagesOverviewHeader';

import matchAndReturn from '~/utilities/matchAndReturn';



export const enum PagesScreenHeaderType {
	ChangeTracking = 'ChangeTracking',
	ContentOverview = 'ContentOverview',
}



type Props = {
	activeFilter: any,
	pagesSection: any,
	type: PagesScreenHeaderType,
};

const PagesScreenHeader: React.FC<Props> = (props) => {
	const {
		activeFilter,
		pagesSection,
		type,
	} = props;

	return matchAndReturn(type, {
		[PagesScreenHeaderType.ChangeTracking]: (
			<ChangeTrackingHeader />
		),
		[PagesScreenHeaderType.ContentOverview]: (
			<PagesOverviewHeader
				activeFilter={activeFilter}
				pagesSection={pagesSection}
			/>
		),
	});
};



export default PagesScreenHeader;
