import classNames from 'classnames';
import React from 'react';

import LineBreaks from '~/components/patterns/values/LineBreaks';
import {
	LinkContext,
} from '~/components/patterns/links/LinkContextProvider';

import styles from './Link.module.scss';



export enum ExternalLinkStyle {
	Auth = 'auth',
	Decent = 'decent',
	Default = 'default',
	Light = 'light',
}



type Props = {
	breakLines?: boolean,
	cssStyle?: React.CSSProperties,
	disabled?: boolean,
	ellipsis?: boolean,
	href?: string,
	children: React.ReactNode,
	icon?: React.ReactNode,
	onClickCallback?: (e: React.MouseEvent) => void,
	style?: ExternalLinkStyle,
	tabIndex?: number,
	/** link HTML target */
	target?: React.AllHTMLAttributes<HTMLAnchorElement>['target'],
};

const ExternalLink: React.FC<Props> = (props) => {
	const {
		breakLines = false,
		cssStyle,
		disabled = false,
		ellipsis = false,
		href,
		children,
		icon,
		onClickCallback,
		style = ExternalLinkStyle.Default,
		tabIndex,
		target = '_blank',
	} = props;

	const context = React.useContext(LinkContext);

	const clickHandler = (e) => {
		if (href) {
			e.stopPropagation();
		}

		if (disabled || onClickCallback) {
			e.preventDefault();
		}

		if (disabled) {
			return false;
		}

		if (onClickCallback) {
			onClickCallback(e);
		}
	};

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isExternal]: true,
		[styles.isDisabled]: disabled,
		[styles.isHoverable]: !disabled,
		[styles.isWithEllipsis]: ellipsis,
		[styles['style-' + (context?.externalLinkStyle || style)] ?? '']: true,
	});

	const additionalAttributes: React.HTMLProps<HTMLAnchorElement> = {};

	if (disabled) {
		additionalAttributes.disabled = true;
	}

	if (tabIndex) {
		additionalAttributes.tabIndex = tabIndex;
	}

	let iconElement;

	if (icon) {
		iconElement = (
			<span className={styles.icon}>
				{icon}
			</span>
		);
	}

	const linkContent = (
		<span className={styles.text}>
			{children}
		</span>
	);

	const link = (
		<a
			className={componentClasses}
			href={disabled ? '#' : href}
			onClick={clickHandler}
			style={cssStyle}
			target={target}
			{...additionalAttributes}
		>
			{iconElement}
			{linkContent}
		</a>
	);

	if (ellipsis) {
		return link;
	} else if (breakLines) {
		return (
			<LineBreaks>
				{link}
			</LineBreaks>
		);
	}

	return link;
};



export default ExternalLink;
