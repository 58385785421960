import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';



const messages = defineMessages({
	[GraphQL.AccountFeature.AdminRole]: {
		id: 'ui.planBenefits.benefit.organizationAdminRole',
	},
	[GraphQL.AccountFeature.CustomAlertTrigger]: {
		id: 'ui.downgradeBox.features.alertsForCustomElements',
	},
	[GraphQL.AccountFeature.CustomElements_2]: {
		id: 'ui.downgradeBox.features.customElements',
	},
	[GraphQL.AccountFeature.CustomElements_5]: {
		id: 'ui.downgradeBox.features.customElementsMoreThan',
	},
	[GraphQL.AccountFeature.CustomElements_10]: {
		id: 'ui.downgradeBox.features.customElementsMoreThan',
	},
	[GraphQL.AccountFeature.CustomElements_15]: {
		id: 'ui.downgradeBox.features.customElementsMoreThan',
	},
	[GraphQL.AccountFeature.CustomElements_20]: {
		id: 'ui.downgradeBox.features.customElementsMoreThan',
	},
	[GraphQL.AccountFeature.CustomElements_25]: {
		id: 'ui.downgradeBox.features.customElementsMoreThan',
	},
	[GraphQL.AccountFeature.CustomElements_30]: {
		id: 'ui.downgradeBox.features.customElementsMoreThan',
	},
	[GraphQL.AccountFeature.CustomElements_35]: {
		id: 'ui.downgradeBox.features.customElementsMoreThan',
	},
	[GraphQL.AccountFeature.CustomElements_40]: {
		id: 'ui.downgradeBox.features.customElementsMoreThan',
	},
	[GraphQL.AccountFeature.EnrichmentFields_5]: {
		id: 'ui.downgradeBox.features.enrichmentFields',
	},
	[GraphQL.AccountFeature.EnrichmentFields_10]: {
		id: 'ui.downgradeBox.features.enrichmentFieldsMoreThan',
	},
	[GraphQL.AccountFeature.EnrichmentFields_15]: {
		id: 'ui.downgradeBox.features.enrichmentFieldsMoreThan',
	},
	[GraphQL.AccountFeature.EnrichmentFields_20]: {
		id: 'ui.downgradeBox.features.enrichmentFieldsMoreThan',
	},
	[GraphQL.AccountFeature.EnrichmentFields_25]: {
		id: 'ui.downgradeBox.features.enrichmentFieldsMoreThan',
	},
	[GraphQL.AccountFeature.EnrichmentFields_30]: {
		id: 'ui.downgradeBox.features.enrichmentFieldsMoreThan',
	},
	[GraphQL.AccountFeature.EnrichmentFields_35]: {
		id: 'ui.downgradeBox.features.enrichmentFieldsMoreThan',
	},
	[GraphQL.AccountFeature.EnrichmentFields_40]: {
		id: 'ui.downgradeBox.features.enrichmentFieldsMoreThan',
	},
	[GraphQL.AccountFeature.IssuesConfiguration]: {
		id: 'ui.downgradeBox.features.issuesConfiguration',
	},
	[GraphQL.AccountFeature.LighthouseMonitoring]: {
		id: 'ui.downgradeBox.features.lighthouseMonitoring',
	},
	[GraphQL.AccountFeature.LogFileAnalysis]: {
		id: 'ui.downgradeBox.features.logFileAnalysis',
	},
	[GraphQL.AccountFeature.MicrosoftTeams]: {
		id: 'ui.downgradeBox.features.microsoftTeams',
	},
	[GraphQL.AccountFeature.IndexNow]: {
		id: 'ui.downgradeBox.features.indexNow',
	},
	[GraphQL.AccountFeature.Rendering]: {
		id: 'ui.downgradeBox.features.rendering',
	},
	[GraphQL.AccountFeature.WebVitalsOriginSummary]: {
		id: 'ui.downgradeBox.features.webVitalsOriginSummary',
	},
});



type Props = {
	lostFeatures: Readonly<Array<GraphQL.AccountFeature>>,
};

const ListOfFeaturesInUse: React.FC<Props> = (props) => {
	const {
		lostFeatures,
	} = props;

	return (
		<ul>
			{lostFeatures.map((feature) => {
				const values: Record<string, any> = {};

				if (feature === GraphQL.AccountFeature.CustomElements_5) {
					values.numberOfElements = 2;
				} else if (feature === GraphQL.AccountFeature.CustomElements_10) {
					values.numberOfElements = 5;
				} else if (feature === GraphQL.AccountFeature.CustomElements_15) {
					values.numberOfElements = 10;
				} else if (feature === GraphQL.AccountFeature.CustomElements_20) {
					values.numberOfElements = 15;
				} else if (feature === GraphQL.AccountFeature.CustomElements_25) {
					values.numberOfElements = 20;
				} else if (feature === GraphQL.AccountFeature.CustomElements_30) {
					values.numberOfElements = 25;
				} else if (feature === GraphQL.AccountFeature.CustomElements_35) {
					values.numberOfElements = 30;
				} else if (feature === GraphQL.AccountFeature.CustomElements_40) {
					values.numberOfElements = 35;
				}

				if (feature === GraphQL.AccountFeature.EnrichmentFields_10) {
					values.numberOfElements = 5;
				} else if (feature === GraphQL.AccountFeature.EnrichmentFields_15) {
					values.numberOfElements = 10;
				} else if (feature === GraphQL.AccountFeature.EnrichmentFields_20) {
					values.numberOfElements = 15;
				} else if (feature === GraphQL.AccountFeature.EnrichmentFields_25) {
					values.numberOfElements = 20;
				} else if (feature === GraphQL.AccountFeature.EnrichmentFields_30) {
					values.numberOfElements = 25;
				} else if (feature === GraphQL.AccountFeature.EnrichmentFields_35) {
					values.numberOfElements = 30;
				} else if (feature === GraphQL.AccountFeature.EnrichmentFields_40) {
					values.numberOfElements = 35;
				}

				return (
					<li key={feature}>
						<FormattedMessage
							{...messages[feature]}
							values={values}
						/>
					</li>
				);
			})}
		</ul>
	);
};



export default ListOfFeaturesInUse;
