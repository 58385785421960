import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ContentIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Group"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="document1"
					transform="translate(5.000000, 5.000000)"
				>
					<polygon
						id="Stroke-681"
						points="37.5015625 40.625 12.5015625 40.625 12.5015625 9.375 37.5015625 9.375"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polygon>
					<path
						d="M17.1884375,17.1875 L32.8134375,17.1875"
						id="Stroke-682"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M17.1884375,21.875 L32.8134375,21.875"
						id="Stroke-683"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M17.1884375,26.5625 L32.8134375,26.5625"
						id="Stroke-684"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M17.1884375,31.25 L26.5634375,31.25"
						id="Stroke-685"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default ContentIconBuilder;

