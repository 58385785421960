import React from 'react';
import {
	FormattedMessage,
} from 'react-intl';

import {
	assertDictionaryId,
} from '~/localization/dictionaries';

import {
	type IssueCategoryName,
} from '~/model/issuesNew';



type Props = {
	issueCategoryName: IssueCategoryName,
};

const IssueCategoryTitle: React.FC<Props> = (props) => {
	const {
		issueCategoryName,
	} = props;

	const messageId = `issues.categories.${issueCategoryName}.title`;

	assertDictionaryId(messageId);

	return (
		<FormattedMessage id={messageId} />
	);
};



export default IssueCategoryTitle;
