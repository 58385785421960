import type CK from '~/types/contentking';

import {
	usePageBundleQuery,
} from './usePageBundle.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function usePageBundle(accountId: CK.AccountId | null): number | null {
	const { data } = useAccountPropertiesQuery(
		usePageBundleQuery,
		accountId,
	);

	return data?.account?.pageBundle ?? null;
}



export default usePageBundle;
