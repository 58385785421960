import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ProductIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M15.973,8.271a.25.25,0,0,0,0-.455L6.216,3.368a.5.5,0,0,0-.406,0L1.993,5.024a.25.25,0,0,0,0,.46l10.29,4.288a.5.5,0,0,0,.4-.006Z"
				fill={color}
			/>
			<path
				d="M22.062,5.5a.25.25,0,0,0,0-.456L12.4.847a1,1,0,0,0-.8,0L8.38,2.247a.25.25,0,0,0,0,.457l9.7,4.422a.5.5,0,0,0,.414,0Z"
				fill={color}
			/>
			<path
				d="M.846,6.63A.25.25,0,0,0,.5,6.861V18.007a1,1,0,0,0,.615.923L11.4,23.217a.25.25,0,0,0,.346-.231V11.34a.251.251,0,0,0-.154-.231Z"
				fill={color}
			/>
			<path
				d="M13.25,22.944a.251.251,0,0,0,.353.228l9.311-4.232a1,1,0,0,0,.586-.91V6.886a.25.25,0,0,0-.353-.228L13.4,11.09a.248.248,0,0,0-.147.227Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ProductIconBuilder;
