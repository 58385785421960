import classNames from 'classnames';
import Mark from 'mark.js';
import React from 'react';
import {
	MarkStyle,
} from '~/components/patterns/typography/Mark';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type ChildProps = {
	matchingElements: Array<React.ReactNode>,
};

type Props = {
	children?: RenderProp<ChildProps>,
	elements: Array<React.ReactNode>,
	markStyle?: MarkStyle | null,
	searchTerm: string,
};

const FilterSearchResults: React.FC<Props> = (props) => {
	const {
		children = null,
		elements,
		markStyle = MarkStyle.Underlined,
		searchTerm,
	} = props;

	const backgroundRef = React.useRef<HTMLDivElement | null>(null);
	const resultRef = React.useRef<HTMLDivElement | null>(null);

	const [matchingElements, setMatchingElements] = React.useState<Array<React.ReactNode>>([]);

	React.useLayoutEffect(
		() => {
			if (
				backgroundRef.current === null
				|| resultRef.current === null
			) {
				return;
			}

			const matchingElements: Array<React.ReactNode> = [];
			Array.from(backgroundRef.current.children).forEach((child, index) => {
				if (
					(child as HTMLElement).innerText
						.toLowerCase()
						.includes(searchTerm.toLowerCase())
				) {
					matchingElements.push(elements[index]);
				}
			});

			setMatchingElements(matchingElements);

			if (markStyle !== null) {
				const marker = new Mark(resultRef.current);
				marker.mark(searchTerm, {
					caseSensitive: false,
					className: classNames({
						'mark': true,
						'mark--highlighted': markStyle === MarkStyle.Highlighted,
						'mark--underlined': markStyle === MarkStyle.Underlined,
					}),
					element: 'mark',
					separateWordSearch: false,
				});

				const markTimeout = setTimeout(() => {
					marker.mark(searchTerm, {
						caseSensitive: false,
						className: classNames({
							'mark': true,
							'mark--highlighted': markStyle === MarkStyle.Highlighted,
							'mark--underlined': markStyle === MarkStyle.Underlined,
						}),
						element: 'mark',
						separateWordSearch: false,
					});
				});

				return () => {
					clearTimeout(markTimeout);
					marker.unmark();
				};
			}
		},
		[
			backgroundRef,
			elements,
			markStyle,
			searchTerm,
		],
	);

	return (
		<>
			<div
				ref={backgroundRef}
				style={{ display: 'none', height: 0 }}
			>
				{elements}
			</div>
			<div ref={resultRef}>
				{children !== null ? renderProp(children, { matchingElements }) : matchingElements}
			</div>
		</>
	);
};



export default FilterSearchResults;
