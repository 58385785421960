import React from 'react';

import type CK from '~/types/contentking';

import AdminAccountDestructConfirmationModal from '~/components/app/AdminAccountDestructConfirmationModal';

import {
	useUnsignupMutation,
} from './AdminUnsignupAccountModal.gql';

import useAccountName from '~/hooks/useAccountName';



type Props = {
	accountId: CK.AccountId | null,
};

const AdminUnsignupAccountModal: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountName = useAccountName(accountId);

	const [unsignup] = useUnsignupMutation();

	const handleSubmit = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			await unsignup({
				variables: {
					accountId,
				},
			});
		},
		[
			accountId,
			unsignup,
		],
	);

	return (
		<AdminAccountDestructConfirmationModal
			accountId={accountId}
			confirmButton="Unsignup Account"
			description={(
				<>
					<p>
						Unsignup account <strong>{accountName} ({accountId})</strong>.
					</p>
					<p>
						This action will preserve existing websites, settings and integrations.
					</p>
				</>
			)}
			onConfirmation={handleSubmit}
			titleAction="Unsignup"
			titleObject="Account"
		/>
	);
};



export default AdminUnsignupAccountModal;
