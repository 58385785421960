import React from 'react';

import type CK from '~/types/contentking';

import useWebsiteScopeIssues from '~/hooks/useWebsiteScopeIssues';

import {
	type SegmentDefinition,
} from '~/model/segments';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



function useIsPageSegmentUsedForIgnoring(websiteId: CK.WebsiteId) {
	const websiteIssueCategories = useWebsiteScopeIssues(websiteId);

	return React.useCallback(
		(segmentDefinition: SegmentDefinition) => {
			if (websiteIssueCategories === null) {
				return true;
			}

			const issueCategories = getArrayItemAtSafeIndex(websiteIssueCategories, 0).issueCategories;

			return issueCategories.some(
				(issueCategory) => {
					return issueCategory.issues.some(
						(issue) => issue.ignoringRuleOnSegments?.ignoredSegments.includes(segmentDefinition.name),
					);
				},
			);
		},
		[
			websiteIssueCategories,
		],
	);
}



export default useIsPageSegmentUsedForIgnoring;
