/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountOrganizationTeamsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountOrganizationTeamsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly organizationTeams: ReadonlyArray<string> | null } | null };


export const AccountOrganizationTeamsDocument = gql`
    query AccountOrganizationTeams($accountId: AccountId!) {
  account(id: $accountId) {
    id
    organizationTeams
  }
}
    `;

/**
 * __useAccountOrganizationTeamsQuery__
 *
 * To run a query within a React component, call `useAccountOrganizationTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountOrganizationTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountOrganizationTeamsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountOrganizationTeamsQuery(baseOptions: Apollo.QueryHookOptions<AccountOrganizationTeamsQuery, AccountOrganizationTeamsQueryVariables> & ({ variables: AccountOrganizationTeamsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountOrganizationTeamsQuery, AccountOrganizationTeamsQueryVariables>(AccountOrganizationTeamsDocument, options);
      }
export function useAccountOrganizationTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountOrganizationTeamsQuery, AccountOrganizationTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountOrganizationTeamsQuery, AccountOrganizationTeamsQueryVariables>(AccountOrganizationTeamsDocument, options);
        }
export function useAccountOrganizationTeamsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountOrganizationTeamsQuery, AccountOrganizationTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountOrganizationTeamsQuery, AccountOrganizationTeamsQueryVariables>(AccountOrganizationTeamsDocument, options);
        }
export type AccountOrganizationTeamsQueryHookResult = ReturnType<typeof useAccountOrganizationTeamsQuery>;
export type AccountOrganizationTeamsLazyQueryHookResult = ReturnType<typeof useAccountOrganizationTeamsLazyQuery>;
export type AccountOrganizationTeamsSuspenseQueryHookResult = ReturnType<typeof useAccountOrganizationTeamsSuspenseQuery>;
export type AccountOrganizationTeamsQueryResult = Apollo.QueryResult<AccountOrganizationTeamsQuery, AccountOrganizationTeamsQueryVariables>;