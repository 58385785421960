import memoize from 'memoizee';

import type CK from '~/types/contentking';

import {
	type AllWebsitesQuery,
	useAllWebsitesQuery,
} from './useAllWebsites.gql';



const emptyList = [];



type Website = {
	displayName: string,
	id: CK.WebsiteId,
};

const format = memoize(
	(data: AllWebsitesQuery | undefined) => {
		const accounts = data?.authenticatedSession?.accounts ?? null;

		if (accounts === null) {
			return {
				isLoaded: false,
				listAll: () => emptyList,
				listByAccount: () => emptyList,
				listByUserAccount: () => emptyList,
				listByUserAccounts: () => emptyList,
			};
		}

		const all: Array<Website> = [];

		const perAccount: Record<CK.AccountId, ReadonlyArray<Website>> = {};

		accounts.forEach((account) => {
			account.websites.forEach((website) => {
				all.push(website);
			});

			perAccount[account.id] = account.websites;
		});

		const listByUserAccounts = (accountIds: ReadonlyArray<CK.AccountId>) => {
			const effectiveAccountIds = [...accountIds];

			accounts.forEach((account) => {
				if (effectiveAccountIds.includes(account.id) === false) {
					return;
				}

				account.connectedClientAccounts.forEach((connectedClientAccount) => {
					if (effectiveAccountIds.includes(connectedClientAccount.id) === false) {
						effectiveAccountIds.push(connectedClientAccount.id);
					}
				});
			});

			let result: ReadonlyArray<Website> = [];

			effectiveAccountIds.forEach((accountId) => {
				result = [...result, ...perAccount[accountId] ?? []];
			});

			return result;
		};

		return {
			isLoaded: true,
			listAll: () => all,
			listByAccount: (accountId: CK.AccountId) => perAccount[accountId] ?? [],
			listByUserAccount: (accountId: CK.AccountId) => listByUserAccounts([accountId]),
			listByUserAccounts,
		};
	},
);



function useAllWebsites() {
	const { data } = useAllWebsitesQuery();

	return format(data);
}



export default useAllWebsites;
