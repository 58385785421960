/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAdminVerificationFormQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAdminVerificationFormQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly verification: string } | null } | null };

export type UpdateWebsiteAdminVerificationMutationVariables = GraphQL.Exact<{
  verification: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteAdminVerificationMutation = { readonly __typename?: 'Mutation', readonly AdministerWebsiteSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isVerified: boolean, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly verification: string } | null } | null } } };


export const WebsiteAdminVerificationFormDocument = gql`
    query WebsiteAdminVerificationForm($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    adminSettings {
      verification
    }
    id
  }
}
    `;

/**
 * __useWebsiteAdminVerificationFormQuery__
 *
 * To run a query within a React component, call `useWebsiteAdminVerificationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAdminVerificationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAdminVerificationFormQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAdminVerificationFormQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAdminVerificationFormQuery, WebsiteAdminVerificationFormQueryVariables> & ({ variables: WebsiteAdminVerificationFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAdminVerificationFormQuery, WebsiteAdminVerificationFormQueryVariables>(WebsiteAdminVerificationFormDocument, options);
      }
export function useWebsiteAdminVerificationFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAdminVerificationFormQuery, WebsiteAdminVerificationFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAdminVerificationFormQuery, WebsiteAdminVerificationFormQueryVariables>(WebsiteAdminVerificationFormDocument, options);
        }
export function useWebsiteAdminVerificationFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteAdminVerificationFormQuery, WebsiteAdminVerificationFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAdminVerificationFormQuery, WebsiteAdminVerificationFormQueryVariables>(WebsiteAdminVerificationFormDocument, options);
        }
export type WebsiteAdminVerificationFormQueryHookResult = ReturnType<typeof useWebsiteAdminVerificationFormQuery>;
export type WebsiteAdminVerificationFormLazyQueryHookResult = ReturnType<typeof useWebsiteAdminVerificationFormLazyQuery>;
export type WebsiteAdminVerificationFormSuspenseQueryHookResult = ReturnType<typeof useWebsiteAdminVerificationFormSuspenseQuery>;
export type WebsiteAdminVerificationFormQueryResult = Apollo.QueryResult<WebsiteAdminVerificationFormQuery, WebsiteAdminVerificationFormQueryVariables>;
export const UpdateWebsiteAdminVerificationDocument = gql`
    mutation UpdateWebsiteAdminVerification($verification: String!, $websiteId: WebsiteId!) {
  AdministerWebsiteSettings(verification: $verification, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        adminSettings {
          verification
        }
        id
        isVerified
      }
    }
  }
}
    `;
export type UpdateWebsiteAdminVerificationMutationFn = Apollo.MutationFunction<UpdateWebsiteAdminVerificationMutation, UpdateWebsiteAdminVerificationMutationVariables>;

/**
 * __useUpdateWebsiteAdminVerificationMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteAdminVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteAdminVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteAdminVerificationMutation, { data, loading, error }] = useUpdateWebsiteAdminVerificationMutation({
 *   variables: {
 *      verification: // value for 'verification'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteAdminVerificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteAdminVerificationMutation, UpdateWebsiteAdminVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteAdminVerificationMutation, UpdateWebsiteAdminVerificationMutationVariables>(UpdateWebsiteAdminVerificationDocument, options);
      }
export type UpdateWebsiteAdminVerificationMutationHookResult = ReturnType<typeof useUpdateWebsiteAdminVerificationMutation>;
export type UpdateWebsiteAdminVerificationMutationResult = Apollo.MutationResult<UpdateWebsiteAdminVerificationMutation>;
export type UpdateWebsiteAdminVerificationMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteAdminVerificationMutation, UpdateWebsiteAdminVerificationMutationVariables>;