import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MobileIconBuilder: IconBuilder = {
	icon: ({ color = '#5C6773' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				opacity="0.7"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="Group"
					stroke={color}
					transform="translate(6.000000, 3.000000)"
				>
					<path
						d="M-1.678295,3.011625 L13.655045,3.011625 C14.3914228,3.011625 14.988375,3.60857717 14.988375,4.344955 L14.988375,13.655045 C14.988375,14.3914228 14.3914228,14.988375 13.655045,14.988375 L-1.678295,14.988375 C-2.41467283,14.988375 -3.011625,14.3914228 -3.011625,13.655045 L-3.011625,4.344955 C-3.011625,3.60857717 -2.41467283,3.011625 -1.678295,3.011625 Z"
						id="Rectangle"
						transform="translate(5.988375, 9.000000) rotate(90.000000) translate(-5.988375, -9.000000) "
					>
					</path>
					<line
						id="Path"
						strokeLinecap="round"
						x1="3.7426275"
						x2="8.12696625"
						y1="15.374925"
						y2="15.374925"
					>
					</line>
					<polygon
						id="Path"
						points="2.61968625 2.62504125 9.3566025 2.62504125 9.3566025 12.750075 2.61968625 12.750075"
						strokeLinejoin="round"
					>
					</polygon>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MobileIconBuilder;

