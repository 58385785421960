import {
	format,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';

import {
	isDate,
} from '~/utilities/typeCheck';



type Props = {
	timestamp: CK.Timestamp | Date | number,
	showTime?: boolean,
};



const HighlightedDate: React.FC<Props> = (props) => {
	const {
		showTime = true,
	} = props;

	const timestamp = isDate(props.timestamp)
		? props.timestamp
		: new Date(props.timestamp);

	return (
		<span className="highlighted-date">
			<span className="highlighted-date__date">
				{format(timestamp, 'd MMM yyyy')}
			</span>
			{showTime && (
				<span className="highlighted-date__time">
					{format(timestamp, 'h:mm a')}
				</span>
			)}
		</span>
	);
};



export default HighlightedDate;
