import React from 'react';
import {
	FormattedMessage,
} from 'react-intl';

import ColumnName from '~/components/names/ColumnName';



const AlertTypeTitle = (props) => {
	const {
		alertType,
		settings,
	} = props;

	const values = { ...(settings.toJS ? settings.toJS() : settings) };

	if (values.property) {
		if (values.customElementName) {
			values.propertyName = values.customElementName;
		} else {
			values.propertyName = (
				<ColumnName
					column={values.property}
				/>
			);
		}
	}

	return (
		<FormattedMessage
			id={'alerts.types.' + alertType + '.title'}
			values={values}
		/>
	);
};



export default AlertTypeTitle;
