import React from 'react';

import CollapsibleRow, {
	TYPE_SECONDARY as COLLAPSIBLE_ROW_TYPE_SECONDARY,
} from './CollapsibleRow.part';
import Row, {
	TYPE_PRIMARY,
	TYPE_SECONDARY,
	TYPE_TERNARY,
} from './Row.part';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	primaryChanges?: ReadonlyArray<{
		icon?: React.ReactNode,
		label: React.ReactNode,
		name?: string,
		onClickCallback?: any,
		value?: React.ReactNode,
	}>,
	secondaryChanges?: ReadonlyArray<{
		group?: string,
		label: React.ReactNode,
		name?: string,
		onClickCallback?: any,
		value?: React.ReactNode,
	}>,
	secondaryChangesGroups?: ReadonlyArray<{
		label: React.ReactNode,
		name: string,
		value?: React.ReactNode,
	}>,
	toggleSecondaryChangesLink: RenderProp<boolean>,
};

const IssueChangesOverview: React.FC<Props> = (props) => {
	const {
		primaryChanges = [],
		secondaryChanges = [],
		secondaryChangesGroups = [],
		toggleSecondaryChangesLink,
	} = props;

	const [secondaryChangesVisibility, setSecondaryChangesVisibility] = React.useState(false);

	const _toggleSecondaryChangesVisibility = (e) => {
		e.preventDefault();

		setSecondaryChangesVisibility(!secondaryChangesVisibility);
	};

	const secondaryChangesContent = secondaryChangesGroups
		.map(({ label, name, value }) => ({
			changes: secondaryChanges.filter(({ group }) => group === name),
			name,
			label,
			value,
		}))
		.filter(({ changes }) => changes.length > 0)
		.map(({ changes, label, name, value }) => (
			<CollapsibleRow
				key={'secondary-change-' + name}
				label={label}
				type={COLLAPSIBLE_ROW_TYPE_SECONDARY}
				value={value}
			>
				{changes.map(({ label, onClickCallback, value }, index) => (
					<Row
						key={index}
						label={label}
						onClickCallback={onClickCallback}
						type={TYPE_SECONDARY}
						value={value}
					/>
				))}
			</CollapsibleRow>
		));

	return (
		<div className="issue-changes-overview">
			{primaryChanges.map((change, index) => {
				return (
					<Row
						icon={change.icon}
						key={'primary-change-' + index}
						label={change.label}
						onClickCallback={change.onClickCallback}
						type={TYPE_PRIMARY}
						value={change.value}
					/>
				);
			})}
			{secondaryChangesContent.length > 0 && (
				<Row
					label={renderProp(toggleSecondaryChangesLink, secondaryChangesVisibility)}
					onClickCallback={_toggleSecondaryChangesVisibility}
					type={TYPE_TERNARY}
				/>
			)}
			{secondaryChangesVisibility && secondaryChangesContent}
		</div>
	);
};



export default IssueChangesOverview;
