import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SettingsIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g id="cog_wheel">
				<path
					d="M12.941,15.524A2.524,2.524,0,1,1,15.506,13,2.577,2.577,0,0,1,12.941,15.524Z"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					d="M19.74,13.95a6.723,6.723,0,0,0,0-1.809L21.671,10.6a.46.46,0,0,0,.091-.543L19.924,6.894c-.083-.142-.367-.166-.551-.091l-2.228.926a8.138,8.138,0,0,0-1.612-.941l-.387-2.336c0-.155-.184-.452-.368-.452H11.1c-.184,0-.461.2-.461.362l-.338,2.417a9.626,9.626,0,0,0-1.591.929L6.509,6.8a.622.622,0,0,0-.644.182L4.028,10.151a.508.508,0,0,0,.183.543l1.943,1.449a7.2,7.2,0,0,0,.011,1.826L4.211,15.487a.423.423,0,0,0-.091.543L5.957,19.2c.094.157.4.158.552.089l2.28-.871a8.158,8.158,0,0,0,1.567.911l.38,2.313c0,.18.27.361.459.361h3.676c.183,0,.458-.185.458-.361l.369-2.353a8.1,8.1,0,0,0,1.535-.932l2.324.932a.54.54,0,0,0,.552-.181l1.837-3.165a.443.443,0,0,0-.092-.543Z"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default SettingsIconBuilder;

