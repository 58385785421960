import React from 'react';

import type CK from '~/types/contentking';

import CancelButton from '~/components/app/CancelButton';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ItemPerLineTextArea from '~/components/app/ItemPerLineTextArea';
import ModalButtonsLayout from '~/components/patterns/modals/parts/ModalButtonsLayout';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';

import {
	validateField,
} from '~/components/app/validations';

import validateUrls from '~/components/validations/validateUrls';

import {
	useImportCustomUrlsMutation,
} from './ImportUrlModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const validations = {
	urlLines: validateField(
		'urlLines',
		(f) => validateUrls(f, {
			allowedPrefixes: [
				'robots: http://',
				'robots: https://',
				'sitemap: http://',
				'sitemap: https://',
			],
			minimumAmount: 1,
		}),
	),
};



type Props = {
	websiteId: CK.WebsiteId,
};

const ImportUrlModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();

	const [importCustomUrls] = useImportCustomUrlsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await importCustomUrls({
				variables: {
					urlLines: values.urlLines,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			importCustomUrls,
			websiteId,
		],
	);

	return (
		<SimpleModal
			size={SimpleModalSize.Large}
			title="Import URL"
		>
			<Form
				defaultFocus="urlLines"
				defaultValues={{
					urlLines: '',
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRow
					description={(
						<>
							Place one URL per line. You can use only absolute URLs.
						</>
					)}
					fullwidth={true}
					htmlFor="urlLines"
				>
					<ItemPerLineTextArea
						name="urlLines"
						size={{
							rows: 8,
							width: '100%',
						}}
					/>
				</FormRow>

				<ModalButtonsLayout>
					<CancelButton />

					<SubmitButton>
						Import
					</SubmitButton>
				</ModalButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default ImportUrlModal;
