import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Benefits of plan */
	benefits?: React.ReactNode,
	/** Additional details about plan */
	details?: React.ReactNode,
	/** Attach flag to whole plan */
	flag?: React.ReactNode,
	/** Additional content */
	children?: React.ReactNode,
	/** Look of box when plan is disabled */
	isDisabled?: boolean,
	/** Apply enterprise highlight */
	isEnterprise?: boolean,
	/** Optional title */
	title?: React.ReactNode,
};



const PlanBox: React.FC<Props> = (props) => {
	const {
		benefits,
		children,
		details,
		flag,
		isDisabled,
		isEnterprise,
		title,
	} = props;

	const componentClasses = classNames({
		'plan-box': true,
		'plan-box--is-disabled': isDisabled,
		'plan-box--is-enterprise': isEnterprise,
		'dark-bg-context': isEnterprise,
	});

	return (
		<div className={componentClasses}>
			{flag && (
				<div className="plan-box__flag">
					{flag}
				</div>
			)}

			{title && (
				<h3 className="plan-box__title">
					{title}
				</h3>
			)}

			{details && (
				<div className="plan-box__plan-details">
					{details}
				</div>
			)}

			{children && (
				<div className="plan-box__content">
					{children}
				</div>
			)}

			{benefits && (
				<div className="plan-box__benefits">
					{benefits}
				</div>
			)}
		</div>
	);
};



export default PlanBox;
