/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EffectiveHomeAccountIdQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type EffectiveHomeAccountIdQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly connectedClientAccounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> } | null };


export const EffectiveHomeAccountIdDocument = gql`
    query EffectiveHomeAccountId {
  authenticatedSession {
    memberships {
      account {
        connectedClientAccounts {
          id
          websites {
            id
          }
        }
        id
        websites {
          id
        }
      }
      user {
        id
        legacyId
      }
      websites {
        id
      }
    }
  }
}
    `;

/**
 * __useEffectiveHomeAccountIdQuery__
 *
 * To run a query within a React component, call `useEffectiveHomeAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEffectiveHomeAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffectiveHomeAccountIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useEffectiveHomeAccountIdQuery(baseOptions?: Apollo.QueryHookOptions<EffectiveHomeAccountIdQuery, EffectiveHomeAccountIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EffectiveHomeAccountIdQuery, EffectiveHomeAccountIdQueryVariables>(EffectiveHomeAccountIdDocument, options);
      }
export function useEffectiveHomeAccountIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EffectiveHomeAccountIdQuery, EffectiveHomeAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EffectiveHomeAccountIdQuery, EffectiveHomeAccountIdQueryVariables>(EffectiveHomeAccountIdDocument, options);
        }
export function useEffectiveHomeAccountIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EffectiveHomeAccountIdQuery, EffectiveHomeAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EffectiveHomeAccountIdQuery, EffectiveHomeAccountIdQueryVariables>(EffectiveHomeAccountIdDocument, options);
        }
export type EffectiveHomeAccountIdQueryHookResult = ReturnType<typeof useEffectiveHomeAccountIdQuery>;
export type EffectiveHomeAccountIdLazyQueryHookResult = ReturnType<typeof useEffectiveHomeAccountIdLazyQuery>;
export type EffectiveHomeAccountIdSuspenseQueryHookResult = ReturnType<typeof useEffectiveHomeAccountIdSuspenseQuery>;
export type EffectiveHomeAccountIdQueryResult = Apollo.QueryResult<EffectiveHomeAccountIdQuery, EffectiveHomeAccountIdQueryVariables>;