import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ColumnFilter from '~/components/logic/filters/ColumnFilter';
import ColumnFormatter from '~/components/logic/formatters/ColumnFormatter';
import ColumnName from '~/components/names/ColumnName';
import DataLoadingFramework from '~/components/logic/datatables/DataLoadingFramework';
import DatatableOverlay from '~/components/patterns/tables/datatables/DatatableOverlay';
import ExpandableFilterOperatorHeaderLabel from '~/components/logic/filters/ExpandableFilterOperatorHeaderLabel';
import FieldFormatter from '~/components/logic/formatters/FieldFormatter';
import FilterFieldLayout from '~/components/patterns/filtering/FilterFieldLayout';
import HeaderLabel from './HeaderLabel';
import ImportanceFieldFilter from '../../logic/datatables/ImportanceFieldFilter';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import OpenIssuesCellValue from '~/components/logic/datatables/cellValues/OpenIssuesCellValue';
import PowerStaticTextFieldFilter from '~/components/logic/datatables/PowerStaticTextFieldFilter';
import RelevanceCellValue from '~/components/logic/datatables/cellValues/RelevanceCellValue';
import ResizableDatatable, {
	type ResizableDatatableColumnDefinition,
	type ResizableDatatableContextMenuEntries,
	ResizableDatatableRowHeight,
} from '~/components/logic/datatables/ResizableDatatable';
import ScreenMessage from '~/components/patterns/messages/embedded/ScreenMessage';
import SegmentFieldFilter from '~/components/logic/datatables/SegmentFieldFilter';
import SegmentLabelThumbList from '~/components/logic/segments/SegmentLabelThumbList';
import UrlCellValue from '~/components/logic/datatables/cellValues/UrlCellValue';

import {
	TYPE_PAGE,
} from '~/model/pages';



const messages = defineMessages({
	noData: {
		id: 'ui.general.noCrawledData',
	},
});



type Props = {
	affectedPages: any,
	columns: any,
	contextMenuEntries: ResizableDatatableContextMenuEntries<any>,
	dataLoading: any,
	filter: any,
	filterCallback: any,
	isCrawled: any,
	onRowClick: any,
	pagesCount: any,
	pagesIndexes: any,
	pagesLoader: any,
	segmentDefinitions: any,
	sortBy: any,
	sortCallback: any,
};

const AffectedPagesTable: React.FC<Props> = (props) => {
	const {
		affectedPages,
		columns,
		contextMenuEntries,
		dataLoading,
		filter,
		filterCallback,
		isCrawled,
		onRowClick,
		pagesCount,
		pagesIndexes,
		pagesLoader,
		segmentDefinitions,
		sortBy,
		sortCallback,
	} = props;

	const rowGetter = React.useCallback(
		({ rowIndex }) => {
			if (pagesIndexes) {
				const pageId = pagesIndexes.get(rowIndex);

				if (pageId) {
					return affectedPages.get(pageId.toString()) ?? null;
				}
			}

			return null;
		},
		[
			affectedPages,
			pagesIndexes,
		],
	);

	const columnDefinitions: Array<ResizableDatatableColumnDefinition<any>> = [
		{
			name: CK.PagesCommonColumn.Url,
			render: {
				cell({ innerWidth, row }) {
					return (
						<OpenIssuesCellValue
							interestingIssues={[]}
							mainContent={(
								<UrlCellValue
									value={row.get(CK.PagesCommonColumn.Url)}
									width={innerWidth}
								/>
							)}
							openIssues={[]}
						/>
					);
				},
				filter: ({ filterName, filterWidth, ref }) => (
					<PowerStaticTextFieldFilter
						name={filterName}
						ref={ref}
						width={filterWidth}
					/>
				),
				label: () => (
					<ColumnName column={CK.PagesCommonColumn.Url} />
				),
				labelFilterOperator: () => (
					<ExpandableFilterOperatorHeaderLabel
						value={filter.get(CK.PagesCommonColumn.Url)}
					/>
				),
			},
			sortOrder: true,
			width: 400,
		},
		{
			name: CK.PagesCommonColumn.Relevance,
			render: {
				cell({ row }) {
					return (
						<OpenIssuesCellValue
							interestingIssues={[]}
							mainContent={(
								<RelevanceCellValue value={row.get(CK.PagesCommonColumn.Relevance)} />
							)}
							openIssues={[]}
						/>
					);
				},
				filter: ({ filterName, filterWidth, ref }) => (
					<ImportanceFieldFilter
						name={filterName}
						ref={ref}
						width={filterWidth}
					/>
				),
				label: () => (
					<ColumnName column={CK.PagesCommonColumn.Relevance} />
				),
			},
			sortOrder: false,
			width: 150,
		},
		{
			filterName: 'segment',
			name: CK.PagesCommonColumn.Segments,
			render: {
				cell({ innerWidth, row }) {
					return (
						<SegmentLabelThumbList
							segmentDefinitions={segmentDefinitions}
							segmentNames={row.get('data').get('segments')}
							width={innerWidth}
						/>
					);
				},
				header: ({ filterName, filterRef, filterWidth }) => {
					return (
						<FilterFieldLayout
							field={(
								<SegmentFieldFilter
									name={filterName}
									ref={filterRef}
									width={filterWidth}
								/>
							)}
							label={(
								<HeaderLabel
									icon={(
										<BasicIcon
											size={14}
											type={BasicIconType.Segment}
										/>
									)}
								>
									<ColumnName column={CK.PagesCommonColumn.Segments} />
								</HeaderLabel>
							)}
						/>
					);
				},
			},
			width: 280,
		},
	];

	columns.forEach((column) => {
		columnDefinitions.push({
			name: column.name,
			render: {
				cell({ row }) {
					const value = column.name !== CK.PagesCommonColumn.CanonicalLinkElement
						? row.get('data').get(column.name)
						: row.get('data').get('canonical');

					return (
						<OpenIssuesCellValue
							interestingIssues={column.issues || []}
							mainContent={column.cellRenderer
								? (
									<ColumnFormatter
										column={column.name}
										pageType={TYPE_PAGE}
										value={column.cellRenderer({
											openIssues: row.get('open_issues'),
											value,
										})}
										zIndex={1200}
									/>
								)
								: (
									<FieldFormatter
										column={column.name}
										pageType={TYPE_PAGE}
										value={value}
									/>
								)
							}
							openIssues={row.get('open_issues')}
						/>
					);
				},
				header: ({ columnWidth, filterRef, filterWidth }) => {
					return (
						<FilterFieldLayout
							field={column.filterable && (
								column.filterRenderer
									? column.filterRenderer({
										name: column.name,
										width: columnWidth,
									})
									: (
										<ColumnFilter
											columnName={column.name}
											columnWidth={filterWidth}
											isAtRightEdge={false}
											ref={filterRef}
											segmentDefinitions={segmentDefinitions}
										/>
									)
							)}
							label={(
								<HeaderLabel
									labelOperator={(column.filterable && !column.filterRenderer) ? (
										<ExpandableFilterOperatorHeaderLabel
											value={filter.get(column.name)}
										/>
									) : undefined}
									sorting={column.sortable ? {
										callback: sortCallback,
										flipped: column.defaultSortingDirection === undefined ? false : !column.defaultSortingDirection,
										name: column.name,
										status: sortBy,
									} : undefined}
								>
									<ColumnName column={column.name} />
								</HeaderLabel>
							)}
						/>
					);
				},
			},
			width: column.width,
		});
	});

	return (
		<DataLoadingFramework
			filter={filter}
			loadDataCallback={pagesLoader}
			sortBy={sortBy}
			syncInterval={10000}
		>
			<ResizableDatatable
				blankSlate={isCrawled && (
					<NoSearchResults />
				)}
				columns={columnDefinitions}
				contextMenuEntries={contextMenuEntries}
				filter={filter}
				isLoading={dataLoading}
				onFilterChange={filterCallback}
				onRowClick={onRowClick}
				onSortChangeCallback={sortCallback}
				overlay={pagesCount === 0 && isCrawled === false && (
					<DatatableOverlay>
						<ScreenMessage>
							<FormattedMessage {...messages.noData} />
						</ScreenMessage>
					</DatatableOverlay>
				)}
				rowGetter={rowGetter}
				rowHeightStyle={ResizableDatatableRowHeight.Medium}
				rowsCount={pagesCount}
				sortBy={sortBy}
			/>
		</DataLoadingFramework>
	);
};



export default AffectedPagesTable;
