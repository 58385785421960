import React from 'react';



type Props = {
	children: React.ReactNode,
	/** Size of image */
	size?: number,
	/** Image path to QR code */
	src?: string,
};

const QRCodeImage: React.FC<Props> = (props) => {
	const {
		children,
		size = 200,
		src,
	} = props;

	const componentStyle = {
		height: size,
		width: size,
	};

	return (
		<div
			className="qr-code-image"
			style={componentStyle}
		>
			{src && size && (
				<img
					alt="QR code"
					className="qr-code-image__image"
					height={size}
					src={src}
					width={size}
				/>
			)}
			{children}
		</div>
	);
};



export default QRCodeImage;
