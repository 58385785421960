import {
	Map,
} from 'immutable';



import {
	dictionaries,
} from '~/localization/dictionaries';
import {
	CHANGE_LANGUAGE,
	INITIALIZE_FROM_LOCAL_STORAGE,
	LOGIN_SUCCESSFUL,
} from '~/actions';



const defaultLocale = 'en-US';

const createDefaultState = () => {
	return new Map({
		availableLocales: dictionaries.keySeq().toList(),
		currentLocale: defaultLocale,
		locales: [defaultLocale],
		messages: dictionaries.get(defaultLocale),
	});
};



export function localization(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_LANGUAGE: {
			if (state.get('locales')[0] === action.language) {
				break;
			}

			state = state.set('currentLocale', action.language);
			state = state.set('locales', [action.language]);
			state = state.set('messages', dictionaries.get(action.language));

			break;
		}

		case INITIALIZE_FROM_LOCAL_STORAGE: {
			const {
				data,
			} = action;

			if (data.has('localization')) {
				state = state.set(
					'currentLocale',
					data.getIn(['localization', 'currentLocale']),
				);
				state = state.set(
					'locales',
					data.getIn(['localization', 'locales']).toJS(),
				);
				state = state.set(
					'messages',
					dictionaries.get(state.get('currentLocale')),
				);
			}

			break;
		}

		case LOGIN_SUCCESSFUL: {
			state = state.set('currentLocale', action.response.locale);
			state = state.set('locales', [action.response.locale]);
			state = state.set('messages', dictionaries.get(state.get('currentLocale')));

			break;
		}

	}

	if (!state.get('currentLocale')) {
		state = state.set('currentLocale', defaultLocale);
		state = state.set('locales', [defaultLocale]);
	}

	if (!state.get('messages')) {
		state = state.set('messages', dictionaries.get(state.get('currentLocale')));
	}

	// Reset to default state when we have a `currentLocale` that is not/no longer
	// supported.
	if (!dictionaries.has(state.get('currentLocale'))) {
		state = createDefaultState();
	}

	return state;
}
