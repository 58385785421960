import {
	isFuture,
	isPast,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';

import {
	useAccountIsTrialRevivableQuery,
} from './useAccountIsTrialRevivable.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsTrialRevivable(accountId: CK.AccountId | null): boolean {
	const [isTrialRevivable, setIsTrialRevivable] = React.useState(false);

	const { data } = useAccountPropertiesQuery(
		useAccountIsTrialRevivableQuery,
		accountId,
	);

	const trialRevivalDate = data?.account?.trialRevivalDate ?? null;

	React.useEffect(
		() => {
			if (trialRevivalDate === null) {
				return;
			}

			const revivalDate = new Date(trialRevivalDate);

			if (isFuture(revivalDate)) {
				const interval = setInterval(
					() => {
						const isTrialRevivable = isPast(revivalDate);

						if (isTrialRevivable) {
							setIsTrialRevivable(isTrialRevivable);
							clearInterval(interval);
						}
					},
					1_000,
				);

				return () => {
					clearInterval(interval);
				};
			} else if (
				isTrialRevivable === false
				&& isPast(revivalDate)
			) {
				setIsTrialRevivable(true);
			}
		},
		[
			isTrialRevivable,
			trialRevivalDate,
		],
	);

	return isTrialRevivable;
}



export default useAccountIsTrialRevivable;
