import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import SimpleModal, {
	SimpleModalIconType,
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import WebsiteSettingsHttpHeadersForm from '~/components/app/WebsiteSettingsHttpHeadersForm';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const messages = defineMessages({
	title: {
		id: 'ui.websites.form.monitoring.httpHeaders.modalTitle',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const HttpHeadersModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();

	return (
		<SimpleModal
			iconType={SimpleModalIconType.CogWheel}
			size={SimpleModalSize.Large}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<WebsiteSettingsHttpHeadersForm
				onSuccess={classicFormBehavior.finish}
				websiteId={websiteId}
			/>
		</SimpleModal>
	);
};



export default HttpHeadersModal;
