import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';

import useColumnSets from '~/hooks/useColumnSets';
import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';
import useCurrentColumnSetId from '~/hooks/useCurrentColumnSetId';

import {
	toggleColumnsSetsList,
} from '~/actions';

import {
	columnsSetsListVisibilitySelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	tagChangedSet: {
		id: 'ui.columnsConfigurator.heading.tag.changed',
	},
	tagDeletedSet: {
		id: 'ui.columnsConfigurator.heading.tag.deleted',
	},
	tagUnsavedSet: {
		id: 'ui.columnsConfigurator.heading.tag.unsaved',
	},
	unsavedLabel: {
		id: 'ui.columnsConfigurator.heading.view.unsaved',
	},
	viewLabel: {
		id: 'ui.columnsConfigurator.heading.view',
	},
});



type Props = {
	isColumnSetModified: boolean | undefined,
};

const ColumnsSetPicker: React.FC<Props> = (props) => {
	const {
		isColumnSetModified = false,
	} = props;

	const columnSets = useColumnSets();
	const columnsSetsListVisible = useSelector(columnsSetsListVisibilitySelector);
	const currentColumnSet = useCurrentColumnSet();
	const currentColumnSetId = useCurrentColumnSetId();
	const dispatch = useDispatch();

	const areThereNoColumnSets = (
		columnSets.isLoaded
		&& columnSets.columnSets.length === 0
	);

	const isColumnSetDeleted = (
		columnSets.isLoaded
		&& currentColumnSetId !== null
		&& currentColumnSet === null
	);

	const handleToggleColumnsSetsList = React.useCallback(
		(e) => {
			e.preventDefault();

			return dispatch(
				toggleColumnsSetsList(!columnsSetsListVisible),
			);
		},
		[
			columnsSetsListVisible,
			dispatch,
		],
	);

	const renderColumnsSetName = () => {
		if (isColumnSetDeleted || areThereNoColumnSets) {
			return (
				<FormattedMessage {...messages.unsavedLabel} />
			);
		}

		if (currentColumnSet !== null) {
			return currentColumnSet.name;
		}

		return false;
	};

	const renderStateTag = () => {
		if (isColumnSetDeleted) {
			return (
				<span className="columns-set-picker__state-tag">
					<span className="show-for-large-up">
						<FormattedMessage {...messages.tagDeletedSet} />
					</span>
					<span className="hide-for-large-up">(*)</span>
				</span>
			);
		}

		if (isColumnSetModified) {
			return (
				<span className="columns-set-picker__state-tag">
					<span className="show-for-large-up">
						<FormattedMessage {...messages.tagChangedSet} />
					</span>
					<span className="hide-for-large-up">(*)</span>
				</span>
			);
		}

		if (areThereNoColumnSets) {
			return (
				<span className="columns-set-picker__state-tag">
					<span className="show-for-large-up">
						<FormattedMessage {...messages.tagUnsavedSet} />
					</span>
					<span className="hide-for-large-up">(*)</span>
				</span>
			);
		}

		return false;
	};

	return (
		<a
			className="columns-set-picker"
			href="#"
			onClick={handleToggleColumnsSetsList}
		>
			<span className="columns-set-picker__content">
				<span className="columns-set-picker__caption">
					<FormattedMessage {...messages.viewLabel} />
				</span>
				<span className="columns-set-picker__name">
					{renderColumnsSetName()}
					{renderStateTag()}
				</span>
			</span>
			<span className="columns-set-picker__toggle-icon">
				<DropdownIcon
					isActive={columnsSetsListVisible}
					style={DropdownIconStyle.White}
				/>
			</span>
		</a>
	);
};



export default ColumnsSetPicker;
