import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CoreWebVitalsIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				opacity="0.7"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="Group"
					transform="translate(6.000000, 3.000000)"
				>
					<path
						d="M0,1.5 C0,0.675 0.675,0 1.5,0 L10.5,0 C11.325,0 12,0.675 12,1.5 L12,15.75 C12,16.365 11.295,16.725 10.8,16.35 L6.45,13.11 C6.18,12.915 5.82,12.915 5.55,13.11 L1.2,16.35 C0.705,16.725 0,16.365 0,15.75 L0,1.5 Z"
						fill={color}
						id="Path"
					>
					</path>
					<path
						d="M0.6,1.5 C0.6,1.005 1.005,0.6 1.5,0.6 L10.5,0.6 C10.995,0.6 11.4,1.005 11.4,1.5 L11.4,15.75 C11.4,15.87 11.265,15.945 11.16,15.87 L6.81,12.63 C6.33,12.27 5.67,12.27 5.205,12.63 L0.84,15.87 C0.735,15.945 0.6,15.87 0.6,15.75 L0.6,1.5 Z"
						id="Path"
						stroke="#000000"
						strokeOpacity="0.05"
						strokeWidth="0.8"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CoreWebVitalsIconBuilder;

