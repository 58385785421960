/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProvideFeedbackMutationVariables = GraphQL.Exact<{
  explanation: GraphQL.Scalars['String']['input'];
  subject: GraphQL.Scalars['String']['input'];
  verdict: GraphQL.Scalars['Boolean']['input'];
}>;


export type ProvideFeedbackMutation = { readonly __typename?: 'Mutation', readonly ProvideFeedback: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const ProvideFeedbackDocument = gql`
    mutation ProvideFeedback($explanation: String!, $subject: String!, $verdict: Boolean!) {
  ProvideFeedback(explanation: $explanation, subject: $subject, verdict: $verdict) {
    query {
      ping
    }
  }
}
    `;
export type ProvideFeedbackMutationFn = Apollo.MutationFunction<ProvideFeedbackMutation, ProvideFeedbackMutationVariables>;

/**
 * __useProvideFeedbackMutation__
 *
 * To run a mutation, you first call `useProvideFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvideFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provideFeedbackMutation, { data, loading, error }] = useProvideFeedbackMutation({
 *   variables: {
 *      explanation: // value for 'explanation'
 *      subject: // value for 'subject'
 *      verdict: // value for 'verdict'
 *   },
 * });
 */
export function useProvideFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<ProvideFeedbackMutation, ProvideFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProvideFeedbackMutation, ProvideFeedbackMutationVariables>(ProvideFeedbackDocument, options);
      }
export type ProvideFeedbackMutationHookResult = ReturnType<typeof useProvideFeedbackMutation>;
export type ProvideFeedbackMutationResult = Apollo.MutationResult<ProvideFeedbackMutation>;
export type ProvideFeedbackMutationOptions = Apollo.BaseMutationOptions<ProvideFeedbackMutation, ProvideFeedbackMutationVariables>;