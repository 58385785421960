import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import DecentText from '~/components/patterns/typography/DecentText';
import Emphasis from '~/components/patterns/typography/Emphasis';
import HighlightedBox from '~/components/patterns/boxes/HighlightedBox';
import SizeLimitDefinition from '../../filters/SizeLimitDefinition';



const messages = defineMessages({
	changeLimit: {
		id: 'ui.segments.editor.sizeLimitBox.changeLimit',
	},
	empty: {
		id: 'ui.segments.editor.sizeLimitBox.empty',
	},
	removeLimit: {
		id: 'ui.segments.editor.sizeLimitBox.removeLimit',
	},
	removeTooltip: {
		id: 'ui.segments.editor.sizeLimitBox.removeTooltip',
	},
	setLimit: {
		id: 'ui.segments.editor.sizeLimitBox.setLimit',
	},
	title: {
		id: 'ui.segments.editor.sizeLimitBox.title',
	},
	withoutLimit: {
		id: 'ui.segments.editor.sizeLimitBox.withoutLimit',
	},
});



const SizeLimitBox = (props) => {
	const {
		hasSizeLimit,
		openSizeLimitModal,
		removeSizeLimit,
		sizeLimit,
		totalNumberOfPages,
	} = props;

	return (
		<HighlightedBox
			ctaElements={(
				<>
					<Button
						onClick={openSizeLimitModal}
						size={ButtonSize.XXSmall}
						style={ButtonStyle.Hollow}
						uppercase={true}
					>
						{hasSizeLimit ? (
							<FormattedMessage {...messages.changeLimit} />
						) : (
							<FormattedMessage {...messages.setLimit} />
						)}
					</Button>

					{hasSizeLimit && (
						<Button
							onClick={removeSizeLimit}
							size={ButtonSize.XXSmall}
							style={ButtonStyle.Hollow}
							tooltip={
								<FormattedMessage {...messages.removeTooltip} />
							}
							uppercase={true}
						>
							<FormattedMessage {...messages.removeLimit} />
						</Button>
					)}
				</>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			{hasSizeLimit && (
				<>
					<SizeLimitDefinition
						sizeLimitDefinition={sizeLimit}
					/>
					<DecentText>
						<FormattedMessage
							{...messages.withoutLimit}
							values={{ numberOfPages: totalNumberOfPages }}
						/>
					</DecentText>
				</>
			)}

			{!hasSizeLimit && (
				<Emphasis>
					<FormattedMessage {...messages.empty} />
				</Emphasis>
			)}
		</HighlightedBox>
	);
};



export default SizeLimitBox;
