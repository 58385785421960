import type Immutable from 'immutable';
import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import CreditCardFields from '~/components/atoms/forms/sharedFields/CreditCardFields';
import InvoicePaymentStates from '~/components/app/InvoicePaymentStates';
import PayPalButton from '~/components/atoms/forms/components/PayPalButton';

import {
	METHOD_CARD,
	METHOD_INVOICE,
	METHOD_PAYPAL,
} from '~/model/payments';

import {
	getTokenUsingData,
	getTokenUsingPayPal,
} from '~/recurly/getToken';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	orderSummary: {
		id: 'ui.paypal.orderSummary',
	},
	unknownError: {
		id: 'ui.general.unknownError',
	},
});



export class PaypalException extends FormError {

	private error;

	constructor(error) {
		super('paypal-authorization');

		this.error = error;
	}

}

export type PaymentMethodFieldsRef = {
	getValues: (formValues: Record<string, any>) => Promise<string>,
};



type Props = {
	billingDetails: Immutable.Map<string, any> | null | undefined,
	isPaymentMethodAcquired: boolean,
	paymentMethod:
		| typeof METHOD_CARD
		| typeof METHOD_INVOICE
		| typeof METHOD_PAYPAL
		| '',
};

const PaymentMethodFields = React.forwardRef<PaymentMethodFieldsRef, Props>((props, ref) => {
	const {
		billingDetails,
		isPaymentMethodAcquired,
		paymentMethod,
	} = props;

	const intl = useIntl();

	React.useImperativeHandle(ref, () => ({
		getValues: (formValues) => {
			if (paymentMethod === METHOD_CARD) {
				const data = billingDetails
					? {
						address1: billingDetails.get('address'),
						city: billingDetails.get('city'),
						country: billingDetails.get('country'),
						state: billingDetails.get('state'),
						first_name: formValues.first_name || billingDetails.get('first_name'),
						last_name: formValues.last_name || billingDetails.get('last_name'),
						vat_number: billingDetails.get('vat_number'),
						postal_code: billingDetails.get('zip_code'),
					}
					: formValues;

				return getTokenUsingData(data);
			} else if (paymentMethod === METHOD_INVOICE) {
				return Promise.resolve();
			} else if (paymentMethod === METHOD_PAYPAL) {
				const configuration = {
					description: intl.formatMessage(messages.orderSummary),
				};

				return getTokenUsingPayPal(configuration)
					.catch((error) => {
						return Promise.reject(
							new PaypalException(error),
						);
					});
			}

			throw new Error(
				`Payment method isn't selected yet`,
			);
		},
	}));

	if (paymentMethod === METHOD_CARD) {
		return (
			<CreditCardFields />
		);
	} else if (paymentMethod === METHOD_PAYPAL) {
		if (!isPaymentMethodAcquired) {
			return (
				<PayPalButton />
			);
		}

		return null;
	} else if (paymentMethod === METHOD_INVOICE) {
		if (!billingDetails) {
			return null;
		}

		return (
			<InvoicePaymentStates
				email={billingDetails.get('email')}
			/>
		);
	}

	return null;
});



export default PaymentMethodFields;
