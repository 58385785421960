import type Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageSize,
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import CrawlingSpeedField from '~/components/app/CrawlingSpeedField';
import {
	linkExternal,
} from '~/components/logic/Copy';
import DeviceTypeName from '~/components/names/DeviceTypeName';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldSeparator from '~/components/atoms/forms/components/FieldSeparator';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HelpHint from '~/components/patterns/hints/HelpHint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticText, {
	StaticTextAlignment,
} from '~/components/atoms/forms/components/StaticText';
import TimeField from '~/components/atoms/forms/components/TimeField';
import UserAgentFields, {
	UserAgentFieldsScope,
	validateUserAgent,
	validateUserAgentCustomValue,
} from '~/components/app/UserAgentFields';
import WebsiteStatus from '~/components/app/WebsiteStatus';
import WithPermission from '~/components/logic/access/WithPermission';

import useWebsiteIsVerified from '~/hooks/useWebsiteIsVerified';
import useWebsitePageCapacity from '~/hooks/useWebsitePageCapacity';

import {
	updateWebsiteMonitoringSettings,
} from '~/actions/websites';

import {
	ObjectType,
} from '~/model/permissions';

import {
	TIME_OFFSETS,
	TIME_OFFSET_UTC,
} from '~/model/time';

import {
	DeviceType,
} from '~/model/websites/deviceTypes';



const messages = defineMessages({
	caution: {
		id: 'ui.general.caution',
	},
	deviceTypeHint: {
		id: 'ui.websites.form.deviceType.hint',
	},
	deviceTypeLabel: {
		id: 'ui.websites.form.deviceType.label',
	},
	disablePeakModeLink: {
		id: 'ui.websites.form.peakMode.disableLink',
	},
	enablePeakModeLink: {
		id: 'ui.websites.form.peakMode.enableLink',
	},
	monitoringSpeed: {
		id: 'ui.websites.form.monitoringSpeed.label',
	},
	monitoringSpeedHigherThanRecommended: {
		id: 'ui.websites.form.monitoringSpeed.higherThanRecommended',
	},
	monitoringSpeedLowerThanRecommended: {
		id: 'ui.websites.form.monitoringSpeed.lowerThanRecommended',
	},
	monitoringSpeedPaused: {
		id: 'ui.forms.websiteSpeed.paused.label',
	},
	monitoringSpeedZero: {
		id: 'ui.websites.form.monitoringSpeed.zero',
	},
	offPeakSpeed: {
		id: 'ui.websites.form.offPeakSpeed.label',
	},
	peakSpeed: {
		id: 'ui.websites.form.peakSpeed.label',
	},
	peakTime: {
		id: 'ui.websites.form.peakTime.label',
	},
	throttledWarning: {
		id: 'ui.websites.form.monitoringSpeed.throttledWarning',
	},
	title: {
		id: 'ui.websites.form.monitoring.titleSettings',
	},
});

const deviceTypeOptions = [
	{
		name: DeviceType.Desktop,
		label: (
			<DeviceTypeName deviceType={DeviceType.Desktop} />
		),
	},
	{
		name: DeviceType.Mobile,
		label: (
			<DeviceTypeName deviceType={DeviceType.Mobile} />
		),
	},
];

const timeZoneOptions = TIME_OFFSETS
	.map(({ timeOffset, utcTimeOffset }) => ({
		name: timeOffset,
		label: utcTimeOffset,
	}))
	.sort((offsetA: any, offsetB: any) => {
		offsetA = parseInt(offsetA.name.replace(':', ''));
		offsetB = parseInt(offsetB.name.replace(':', ''));

		return offsetA < offsetB ? -1 : 1;
	});



const WebsiteMonitoringForm = (props) => {
	const {
		crawlingSpeed,
		website,
	} = props;

	const websiteId = website.get('id');

	const dispatch = useDispatch();
	const websiteIsVerified = useWebsiteIsVerified(websiteId) ?? false;
	const websitePageCapacity = useWebsitePageCapacity(websiteId);

	const [peakModeEnabled, setPeakModeEnabled] = React.useState<boolean | null>(null);

	const disablePeakMode = React.useCallback(
		(e) => {
			e.preventDefault();

			setPeakModeEnabled(false);
		},
		[
			setPeakModeEnabled,
		],
	);

	const enablePeakMode = React.useCallback(
		(e) => {
			e.preventDefault();

			setPeakModeEnabled(true);
		},
		[
			setPeakModeEnabled,
		],
	);

	const handleFormModeSwitch = React.useCallback(
		(inEditMode) => {
			if (!inEditMode) {
				setPeakModeEnabled(null);
			}
		},
		[
			setPeakModeEnabled,
		],
	);

	const handleSubmitForm = React.useCallback(
		(values) => {
			const data: Record<string, any> = {
				renderingDeviceType: values.deviceType,
				userAgentType: values.userAgent,
				userAgentValue: values.userAgentCustomValue,
				websiteId,
			};

			const inPeakMode = peakModeEnabled !== null
				? peakModeEnabled
				: crawlingSpeed.get('intervals').filter(
					(interval) => interval.get('tags').includes('peak'),
				).size > 0;

			if (inPeakMode) {
				const offPeakSpeed = values.monitoring_speed * 1.0;
				const peakSpeed = values.peak_speed * 1.0;

				const intervals: Array<{
					since: string,
					until: string,
					scale_factor: string,
					tags: Array<string>,
				}> = [];

				const peakTimeSince = values.peak_speed_time_from.match(/(\d+):(\d+)/);
				const peakTimeSinceInSeconds = parseInt(peakTimeSince[1]) * 3660 + parseInt(peakTimeSince[2]);

				const peakTimeUntil = values.peak_speed_time_to.match(/(\d+):(\d+)/);
				const peakTimeUntilInSeconds = parseInt(peakTimeUntil[1]) * 3600 + parseInt(peakTimeUntil[2]);
				const peakTimeUntilInHours = Math.round(peakTimeUntilInSeconds / 3600);

				if (peakTimeSinceInSeconds === 0) {
					if (peakTimeUntilInHours === 24) {
						intervals.push({
							since: '00:00',
							until: '24:00',
							scale_factor: peakSpeed.toFixed(1),
							tags: [
								'peak',
							],
						});
					} else {
						intervals.push({
							since: '00:00',
							until: values.peak_speed_time_to,
							scale_factor: peakSpeed.toFixed(1),
							tags: [
								'peak',
							],
						});
						intervals.push({
							since: values.peak_speed_time_to,
							until: '24:00',
							scale_factor: offPeakSpeed.toFixed(1),
							tags: [
								'off_peak',
							],
						});
					}
				} else if (peakTimeSinceInSeconds < peakTimeUntilInSeconds) {
					if (peakTimeUntilInHours === 24) {
						intervals.push({
							since: '00:00',
							until: values.peak_speed_time_from,
							scale_factor: offPeakSpeed.toFixed(1),
							tags: [
								'off_peak',
							],
						});
						intervals.push({
							since: values.peak_speed_time_from,
							until: '24:00',
							scale_factor: peakSpeed.toFixed(1),
							tags: [
								'peak',
							],
						});
					} else {
						intervals.push({
							since: '00:00',
							until: values.peak_speed_time_from,
							scale_factor: offPeakSpeed.toFixed(1),
							tags: [
								'off_peak',
							],
						});
						intervals.push({
							since: values.peak_speed_time_from,
							until: values.peak_speed_time_to,
							scale_factor: peakSpeed.toFixed(1),
							tags: [
								'peak',
							],
						});
						intervals.push({
							since: values.peak_speed_time_to,
							until: '24:00',
							scale_factor: offPeakSpeed.toFixed(1),
							tags: [
								'off_peak',
							],
						});
					}
				} else {
					intervals.push({
						since: '00:00',
						until: values.peak_speed_time_to,
						scale_factor: peakSpeed.toFixed(1),
						tags: [
							'peak',
						],
					});
					intervals.push({
						since: values.peak_speed_time_to,
						until: values.peak_speed_time_from,
						scale_factor: offPeakSpeed.toFixed(1),
						tags: [
							'off_peak',
						],
					});
					intervals.push({
						since: values.peak_speed_time_from,
						until: '24:00',
						scale_factor: peakSpeed.toFixed(1),
						tags: [
							'peak',
						],
					});
				}

				data.crawlingSpeed = {
					timezone_offset: values.peak_speed_time_zone,
					intervals,
				};
			} else {
				const monitoringSpeed = values.monitoring_speed * 1.0;

				data.crawlingSpeed = {
					timezone_offset: crawlingSpeed.get('timezone_offset'),
					intervals: [
						{
							since: '00:00',
							until: '24:00',
							scale_factor: monitoringSpeed.toFixed(1),
							tags: [],
						},
					],
				};
			}

			return dispatch(
				updateWebsiteMonitoringSettings(data),
			);
		},
		[
			crawlingSpeed,
			dispatch,
			peakModeEnabled,
			websiteId,
		],
	);

	function renderOverview(crawlingSpeed) {
		const peakIntervals = crawlingSpeed
			.get('intervals')
			.filter((interval) => interval.get('tags').includes('peak'));

		const monitoringSpeed = peakIntervals.size > 0
			? crawlingSpeed
				.get('intervals')
				.filter((interval) => !interval.get('tags').includes('peak'))
				.get(0)
				.get('scale_factor')
			: crawlingSpeed
				.get('intervals')
				.get(0)
				.get('scale_factor');

		const monitoringSpeedThrottled = peakIntervals.size > 0
			? crawlingSpeed
				.get('intervals')
				.filter((interval) => !interval.get('tags').includes('peak'))
				.get(0)
				.get('is_set_due_to_throttling')
			: crawlingSpeed
				.get('intervals')
				.get(0)
				.get('is_set_due_to_throttling');

		const peakSpeed = peakIntervals.size > 0
			? peakIntervals.get(0).get('scale_factor')
			: null;

		const peakSpeedThrottled = peakIntervals.size > 0
			? peakIntervals.get(0).get('is_set_due_to_throttling')
			: null;

		let peakTimeSince = null;
		let peakTimeUntil = null;

		if (peakIntervals.size > 0) {
			if (peakIntervals.size > 1) {
				peakTimeSince = peakIntervals.get(1).get('since');
				peakTimeUntil = peakIntervals.get(0).get('until');
			} else {
				peakTimeSince = peakIntervals.get(0).get('since');
				peakTimeUntil = peakIntervals.get(0).get('until');
			}
		}

		return (
			<FormRows>
				<FormRow
					label={(
						<AttachedElement
							element={(
								<HelpHint
									message={(
										<FormattedMessage
											{...messages.deviceTypeHint}
											values={{
												linkSupport: linkExternal('https://www.contentkingapp.com/support/monitoring-settings/'),
											}}
										/>
									)}
								/>
							)}
						>
							<FormattedMessage {...messages.deviceTypeLabel} />
						</AttachedElement>
					)}
				>
					<StaticText focusTarget="device_type">
						<DeviceTypeName deviceType={website.get('rendering_device_type')} />
					</StaticText>
				</FormRow>

				<UserAgentFields
					customHeaderValue={website.get('custom_user_agent')}
					isEditable={false}
					scope={UserAgentFieldsScope.Website}
					userAgent={website.get('user_agent')}
				/>

				<FormRow
					description={[
						monitoringSpeedThrottled && (
							<FormattedMessage {...messages.throttledWarning} />
						),
						(monitoringSpeed == 0.0) && (
							<FormattedMessage {...messages.monitoringSpeedZero} />
						),
					]}
					label={peakSpeed !== null
						? (
							<FormattedMessage {...messages.offPeakSpeed} />
						)
						: (
							<FormattedMessage {...messages.monitoringSpeed} />
						)
					}
				>
					<StaticText focusTarget="monitoring_speed">
						{monitoringSpeed > 0.0 && (
							<FormattedNumber
								style="percent"
								value={monitoringSpeed}
							/>
						)}
						{monitoringSpeed == 0.0 && (
							<AttachedIcon
								ellipsis={false}
								icon={(
									<WebsiteStatus
										showStatus={[GraphQL.WebsiteProblem.Paused]}
										websiteId={websiteId}
									/>
								)}
								iconPosition={AttachedIconPosition.Suffix}
							>
								<FormattedMessage {...messages.monitoringSpeedPaused} />
							</AttachedIcon>
						)}
					</StaticText>
				</FormRow>
				{peakSpeed !== null && (
					<FormRow
						description={[
							peakSpeedThrottled && (
								<FormattedMessage {...messages.throttledWarning} />
							),
							(peakSpeed == 0.0) && (
								<FormattedMessage {...messages.monitoringSpeedZero} />
							),
						]}
						label={(
							<FormattedMessage {...messages.peakSpeed} />
						)}
					>
						<StaticText focusTarget="peak_speed">
							{peakSpeed > 0.0 && (
								<FormattedNumber
									style="percent"
									value={peakSpeed}
								/>
							)}
							{peakSpeed == 0.0 && (
								<FormattedMessage {...messages.monitoringSpeedPaused} />
							)}
						</StaticText>
					</FormRow>
				)}
				{peakSpeed !== null && (
					<FormRow
						label={(
							<FormattedMessage {...messages.peakTime} />
						)}
					>
						<StaticText focusTarget="peak_speed_time_from">
							{peakTimeSince} - {peakTimeUntil} ({TIME_OFFSETS.find(
								({ timeOffset }) => timeOffset === crawlingSpeed.get('timezone_offset'),
							)?.utcTimeOffset})
						</StaticText>
					</FormRow>
				)}
			</FormRows>
		);
	}

	function renderForm(crawlingSpeed) {
		const timeZone = crawlingSpeed.get('timezone_offset') || TIME_OFFSET_UTC;

		let peakTimeSince = '09:00';
		let peakTimeUntil = '17:00';

		let offPeakInterval: Immutable.Map<string, any> | null = null;

		const peakIntervals = crawlingSpeed
			.get('intervals')
			.filter((interval) => interval.get('tags').includes('peak'));

		if (peakIntervals.size > 0) {
			if (peakIntervals.size > 1) {
				peakTimeSince = peakIntervals.get(1).get('since');
				peakTimeUntil = peakIntervals.get(0).get('until');
			} else {
				peakTimeSince = peakIntervals.get(0).get('since');
				peakTimeUntil = peakIntervals.get(0).get('until');
			}

			offPeakInterval = crawlingSpeed
				.get('intervals')
				.filter((interval) => !interval.get('tags').includes('peak'))
				.get(0);
		}

		const monitoringSpeed: number = offPeakInterval !== null
			? offPeakInterval.get('scale_factor')
			: crawlingSpeed.getIn(['intervals', 0, 'scale_factor']);

		const monitoringSpeedThrottled: boolean = offPeakInterval !== null
			? offPeakInterval.get('is_set_due_to_throttling')
			: crawlingSpeed.getIn(['intervals', 0, 'is_set_due_to_throttling']);

		const peakSpeed = peakIntervals.size > 0
			? peakIntervals.get(0).get('scale_factor')
			: monitoringSpeed;

		const peakSpeedThrottled = peakIntervals.size > 0
			? peakIntervals.get(0).get('is_set_due_to_throttling')
			: null;

		const inPeakMode = peakModeEnabled !== null
			? peakModeEnabled
			: peakIntervals.size > 0;

		return (
			<PremiumFeatureSituation
				featureName={GraphQL.AccountFeature.HighCrawlingSpeed}
				hideIfUnattainable={false}
				showUnavailablePlan={true}
				style={PremiumFeatureSituationStyle.Box}
			>
				{({ isFeatureEnabled }) => (
					<Form
						defaultValues={{
							deviceType: website.get('rendering_device_type'),
							monitoring_speed: monitoringSpeed * 1.0,
							peak_speed: peakSpeed * 1.0,
							peak_speed_time_from: peakTimeSince,
							peak_speed_time_to: peakTimeUntil,
							peak_speed_time_zone: timeZone,
							userAgent: website.get('user_agent'),
							userAgentCustomValue: website.get('custom_user_agent'),
						}}
						ignoreFieldUnmounts={true}
						onSuccess={handleSubmitForm}
						validations={{
							monitoring_speed: [
								{
									message: '',
									field: 'monitoring_speed',
									rule: ({ values, name }) => {
										if (!websiteIsVerified) {
											return values[name] <= 1.0;
										}

										return true;
									},
								},
								{
									message: '',
									field: 'monitoring_speed',
									rule: ({ values, name }) => {
										if (!isFeatureEnabled) {
											return values[name] !== 10;
										}

										return true;
									},
								},
							],
							peak_speed: [
								{
									message: '',
									field: 'peak_speed',
									rule: ({ values, name }) => {
										if (!inPeakMode) {
											return true;
										}

										if (!websiteIsVerified) {
											return values[name] <= 1.0;
										}

										return true;
									},
								},
								{
									message: '',
									field: 'peak_speed',
									rule: ({ values, name }) => {
										if (!inPeakMode) {
											return true;
										}

										if (!isFeatureEnabled) {
											return values[name] !== 10;
										}

										return true;
									},
								},
							],
							peak_speed_time: [
								{
									message: '',
									field: 'peak_speed_time_from',
									rule: ({ values }) => {
										if (!inPeakMode) {
											return true;
										}

										const value = values['peak_speed_time_from']?.trim() ?? '';

										return /^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]|24:00)$/.test(value);
									},
								},
								{
									message: '',
									field: 'peak_speed_time_to',
									rule: ({ values }) => {
										if (!inPeakMode) {
											return true;
										}

										const value = values['peak_speed_time_to']?.trim() ?? '';

										return /^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]|24:00)$/.test(value);
									},
								},
							],
							validateUserAgent: validateUserAgent({ isWebsiteVerified: websiteIsVerified }),
							validateUserAgentCustomValue: validateUserAgentCustomValue({ isWebsiteVerified: websiteIsVerified }),
						}}
					>
						{({ values }) => (
							<>
								<FormRows>
									<FormRow
										htmlFor="deviceType"
										label={(
											<AttachedElement
												element={(
													<HelpHint
														message={(
															<FormattedMessage
																{...messages.deviceTypeHint}
																values={{
																	linkSupport: linkExternal('https://www.contentkingapp.com/support/monitoring-settings/'),
																}}
															/>
														)}
													/>
												)}
											>
												<FormattedMessage {...messages.deviceTypeLabel} />
											</AttachedElement>
										)}
									>
										<FieldStatus name="deviceType">
											<SelectField
												name="deviceType"
												options={deviceTypeOptions}
											/>
										</FieldStatus>
									</FormRow>

									<UserAgentFields
										customHeaderValue={website.get('custom_user_agent')}
										isEditable={true}
										isWebsiteVerified={websiteIsVerified}
										scope={UserAgentFieldsScope.Website}
										userAgent={website.get('user_agent')}
									/>

									<FormRow
										htmlFor="monitoring_speed"
										key="monitoringSpeed"
										label={inPeakMode
											? (
												<FormattedMessage {...messages.offPeakSpeed} />
											)
											: (
												<FormattedMessage {...messages.monitoringSpeed} />
											)
										}
									>
										<FieldStatus name="monitoring_speed">
											{websitePageCapacity !== null && (
												<CrawlingSpeedField
													isVerified={websiteIsVerified}
													name="monitoring_speed"
													pageTotal={websitePageCapacity}
												/>
											)}
										</FieldStatus>
									</FormRow>

									{renderMonitorSpeedCaution('monitoringSpeedCaution', values.monitoring_speed, monitoringSpeedThrottled)}

									{!inPeakMode && (
										<StaticText
											alignment={StaticTextAlignment.Center}
											key="enablePeakModeLink"
											width="100%"
										>
											<InternalLink
												onClickCallback={enablePeakMode}
												style={InternalLinkStyle.Decent}
											>
												<FormattedMessage {...messages.enablePeakModeLink} />
											</InternalLink>
										</StaticText>
									)}

									{inPeakMode && (
										<FormRow
											htmlFor="peak_speed"
											key="peakSpeed"
											label={(
												<FormattedMessage {...messages.peakSpeed} />
											)}
										>
											<FieldStatus name="peak_speed">
												{websitePageCapacity !== null && (
													<CrawlingSpeedField
														isVerified={websiteIsVerified}
														name="peak_speed"
														pageTotal={websitePageCapacity}
													/>
												)}
											</FieldStatus>
										</FormRow>
									)}

									{inPeakMode && renderMonitorSpeedCaution('peakSpeedCaution', values.peak_speed, peakSpeedThrottled)}

									{inPeakMode && (
										<FormRow
											htmlFor="peak_speed_time_from"
											key="peakTime"
											label={(
												<FormattedMessage {...messages.peakTime} />
											)}
										>
											<FieldStatus name="peak_speed_time">
												<TimeField
													name="peak_speed_time_from"
												/>
												<FieldSeparator
													separator="—"
												/>
												<TimeField
													name="peak_speed_time_to"
												/>
												<SelectField
													dropdownWidth={125}
													name="peak_speed_time_zone"
													options={timeZoneOptions}
													width={125}
												/>
											</FieldStatus>
										</FormRow>
									)}

									{inPeakMode && (
										<StaticText
											alignment={StaticTextAlignment.Center}
											key="disablePeakModeLink"
											width="100%"
										>
											<InternalLink
												onClickCallback={disablePeakMode}
												style={InternalLinkStyle.Decent}
											>
												<FormattedMessage {...messages.disablePeakModeLink} />
											</InternalLink>
										</StaticText>
									)}
								</FormRows>

								<ButtonsLayout>
									<CancelButton />

									<SaveSubmitButton />
								</ButtonsLayout>
							</>
						)}
					</Form>
				)}
			</PremiumFeatureSituation>
		);
	}



	function renderMonitorSpeedCaution(key: string, monitoringSpeed, monitoringSpeedThrottled = false) {
		if (monitoringSpeed > 0.6 && monitoringSpeed < 3) {
			return null;
		}

		return (
			<PremiumFeatureSituation
				featureName={GraphQL.AccountFeature.HighCrawlingSpeed}
				hideIfUnattainable={false}
				key={key}
				showUnavailablePlan={true}
				style={PremiumFeatureSituationStyle.Box}
			>
				{({ isFeatureEnabled, premiumAnnotation }) => {
					if (!isFeatureEnabled && monitoringSpeed === 10) {
						return (
							<FormRow>
								{premiumAnnotation}
							</FormRow>
						);
					}

					return (
						<FormRow>
							<CalloutMessage
								borders={true}
								message={
									<FormattedMessage {...messages.caution} />
								}
								size={CalloutMessageSize.Small}
								status={CalloutMessageStatus.Warning}
							>
								{monitoringSpeed === 0 && (
									<FormattedMessage {...messages.monitoringSpeedZero} />
								)}

								{monitoringSpeed > 0 && monitoringSpeed <= 0.6 && (
									<FormattedMessage {...messages.monitoringSpeedLowerThanRecommended} />
								)}

								{monitoringSpeed >= 3 && (
									<FormattedMessage {...messages.monitoringSpeedHigherThanRecommended} />
								)}

								{monitoringSpeed >= 3 && monitoringSpeedThrottled && (
									<FormattedMessage {...messages.throttledWarning} />
								)}
							</CalloutMessage>
						</FormRow>
					);
				}}
			</PremiumFeatureSituation>
		);
	}

	if (!crawlingSpeed) {
		return null;
	}

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageMonitoringSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					onEnterEditModeCallback={handleFormModeSwitch}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						{renderOverview(crawlingSpeed)}
					</DisplayPart>

					<EditablePart>
						{renderForm(crawlingSpeed)}
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WithPermission>
	);
};



export default WebsiteMonitoringForm;
