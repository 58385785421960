import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const LockIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				stroke={color}
				strokeLinecap="round"
				strokeWidth="2"
				transform="translate(8 5.5)"
			>
				<g strokeLinejoin="round">
					<path d="m15.9759583 20.2971429h-15.3333333v-11.42857146h15.3333333z" />
					<path d="m3.51724167 8.86609525v-3.80857143c0-2.63047619 2.14570833-4.76190476 4.79166666-4.76190476 2.64595837 0 4.79166667 2.13142857 4.79166667 4.76190476v3.80857143" />
					<path d="m10.225 13.6291429c0 1.0523809-.85770833 1.9047619-1.91666667 1.9047619-1.05895833 0-1.91666666-.852381-1.91666666-1.9047619 0-1.052381.85770833-1.9047619 1.91666666-1.9047619 1.05895834 0 1.91666667.8523809 1.91666667 1.9047619z" />
				</g>
				<path d="m8.30833333 17.4369524v-1.9038095" />
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default LockIconBuilder;

