import flattenChildren from 'react-keyed-flatten-children';
import React from 'react';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type Props = {
	children?: React.ReactNode,
	ctaElements?: React.ReactNode,
};

const PriceItems: React.FC<Props> = (props) => {
	const {
		children,
		ctaElements,
	} = props;

	const elements = flattenChildren(children).filter(notEmpty);

	return (
		<div className="price-items">
			<div className="price-items__price-items">
				{elements.map((child, index) => (
					<div
						className="price-items__row"
						key={`row-${index}`}
					>
						{child}
					</div>
				))}
			</div>

			{ctaElements && (
				<div className="price-items__cta">
					{ctaElements}
				</div>
			)}
		</div>
	);
};



export default PriceItems;
