import React from 'react';

import {
	useLegalDocumentsQuery,
} from './useLegalDocument.gql';

import useAccountId from '~/hooks/useAccountId';

import DataProcessingAgreementDocument from '../../data/data-processing-agreement.html?raw';
import PrivacyPolicyDocument from '../../data/privacy-policy.html?raw';
import TermsOfUseDocument from '../../data/terms-of-use.html?raw';



export enum LegalDocument {
	DataProcessingAgreement,
	PrivacyPolicy,
	TermsOfUse,
}



const ARTICLES = {
	[LegalDocument.DataProcessingAgreement]: 'https://www.contentkingapp.com/legal/data-processing-agreement/',
	[LegalDocument.PrivacyPolicy]: 'https://www.contentkingapp.com/legal/privacy-policy/',
	[LegalDocument.TermsOfUse]: 'https://www.contentkingapp.com/legal/terms-of-use/',
};

const DOCUMENTS = {
	[LegalDocument.DataProcessingAgreement]: DataProcessingAgreementDocument,
	[LegalDocument.PrivacyPolicy]: PrivacyPolicyDocument,
	[LegalDocument.TermsOfUse]: TermsOfUseDocument,
};

const PDFS = {
	[LegalDocument.DataProcessingAgreement]: 'https://www.contentkingapp.com/media/general/ContentKing-Data-Processing-Agreement.pdf',
	[LegalDocument.PrivacyPolicy]: 'https://www.contentkingapp.com/media/general/ContentKing-Privacy-Policy.pdf',
	[LegalDocument.TermsOfUse]: 'https://www.contentkingapp.com/media/general/ContentKing-Terms-of-Use.pdf',
};



function useLegalDocument(legalDocument: LegalDocument) {
	const [document, setDocument] = React.useState<{
		article: string | null,
		content: React.ReactNode | null,
		pdf: string | null,
	}>({
		article: null,
		content: null,
		pdf: null,
	});

	const accountId = useAccountId();
	const { data } = useLegalDocumentsQuery({
		variables: {
			accountId: accountId ?? 0,
		},
		skip: accountId === null || legalDocument !== LegalDocument.TermsOfUse,
	});

	const customTermsOfUse = data?.account?.customTermsOfUseAgreement ?? null;

	React.useEffect(
		() => {
			if (legalDocument === LegalDocument.TermsOfUse && customTermsOfUse !== null) {
				setDocument({
					article: null,
					content: customTermsOfUse,
					pdf: null,
				});

				return;
			}

			setDocument({
				article: ARTICLES[legalDocument],
				content: (
					<div
						className="typo typo--without-bottom-margin"
						dangerouslySetInnerHTML={{ __html: DOCUMENTS[legalDocument] }}
					/>
				),
				pdf: PDFS[legalDocument],
			});
		},
		[
			customTermsOfUse,
			legalDocument,
		],
	);

	return document;
}

export default useLegalDocument;
