import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import OpenGraphBlockPart from './OpenGraphBlockPart';
import TwitterCardsBlockPart from './TwitterCardsBlockPart';



const messages = defineMessages({
	title: {
		id: 'ui.pageDetail.social.title',
	},
});



const SocialBlock: React.FC = () => {
	return (
		<BorderedBox
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.Social}
				/>
			)}
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			paddingSize={3}
		>
			<OpenGraphBlockPart />
			<TwitterCardsBlockPart />
		</BorderedBox>
	);
};



export default SocialBlock;
