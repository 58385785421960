export const ACTION_SET_SELECTED_ALERT_DEFINITIONS = 'ACTION_SET_SELECTED_ALERT_DEFINITIONS';



export function setSelectedDefinitions({
	selectedDefinitions,
}) {
	return (dispatch) => {
		dispatch({
			type: ACTION_SET_SELECTED_ALERT_DEFINITIONS,
			selectedDefinitions,
		});
	};
}
