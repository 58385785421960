/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountIdsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AccountIdsQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId }> } | null };


export const AccountIdsDocument = gql`
    query AccountIds {
  authenticatedSession {
    accounts {
      displayName
      id
    }
  }
}
    `;

/**
 * __useAccountIdsQuery__
 *
 * To run a query within a React component, call `useAccountIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountIdsQuery(baseOptions?: Apollo.QueryHookOptions<AccountIdsQuery, AccountIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountIdsQuery, AccountIdsQueryVariables>(AccountIdsDocument, options);
      }
export function useAccountIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountIdsQuery, AccountIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountIdsQuery, AccountIdsQueryVariables>(AccountIdsDocument, options);
        }
export function useAccountIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountIdsQuery, AccountIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountIdsQuery, AccountIdsQueryVariables>(AccountIdsDocument, options);
        }
export type AccountIdsQueryHookResult = ReturnType<typeof useAccountIdsQuery>;
export type AccountIdsLazyQueryHookResult = ReturnType<typeof useAccountIdsLazyQuery>;
export type AccountIdsSuspenseQueryHookResult = ReturnType<typeof useAccountIdsSuspenseQuery>;
export type AccountIdsQueryResult = Apollo.QueryResult<AccountIdsQuery, AccountIdsQueryVariables>;