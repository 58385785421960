import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import ContributorsAssignmentForm from '~/components/app/ContributorsAssignmentForm';
import EditEndUserWebsiteSubscriptionForm from '~/components/atoms/forms/forms/EditEndUserWebsiteSubscriptionForm';
import EditWebsiteNameForm from '~/components/app/EditWebsiteNameForm';
import EditWebsiteSubscriptionForm from '~/components/atoms/forms/forms/EditWebsiteSubscriptionForm';
import FormsList from '~/components/atoms/lists/FormsList';
import MoneyStuff from '~/components/app/MoneyStuff';
import WithBillingDetails from '~/components/logic/WithBillingDetails';

import {
	useUpdateWebsitePageCapacityMutation,
} from '~/components/app/WebsiteDetailsGroup.gql';

import useAccountType from '~/hooks/useAccountType';
import useRequestAdminConfirmationIfApplicable from '~/hooks/useRequestAdminConfirmationIfApplicable';
import useAccountId from '~/hooks/useAccountId';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	teamsBillingSelector,
} from '~/state/teams/selectors';

import {
	selectedWebsiteDetailSelector,
	teamSelector,
} from '~/state/ui/selectors';



const WebsiteDetailsGroup: React.FC = () => {
	const accountId = useAccountId();
	const websiteId = useWebsiteId();

	const accountType = useAccountType(accountId);
	const requestAdminConfirmationIfApplicable = useRequestAdminConfirmationIfApplicable(accountId);

	const team = useSelector(teamSelector);
	const teamsBilling = useSelector(teamsBillingSelector);
	const teamBilling = accountId !== null ? teamsBilling.get(accountId.toString()) : null;
	const websiteDetail = useSelector(selectedWebsiteDetailSelector);

	const [updateWebsitePageCapacity] = useUpdateWebsitePageCapacityMutation();

	const handleWebsiteSubscriptionSubmit = React.useCallback(
		async ({ threeDSecureToken, values }) => {
			await requestAdminConfirmationIfApplicable();

			await updateWebsitePageCapacity({
				variables: {
					pageCapacity: values.page_capacity,
					threeDSecureToken,
					websiteId,
				},
			});
		},
		[
			requestAdminConfirmationIfApplicable,
			updateWebsitePageCapacity,
			websiteId,
		],
	);

	if (!team || accountId === null) {
		return null;
	}

	return (
		<FormsList>
			<EditWebsiteNameForm
				websiteId={websiteDetail.get('id')}
			/>

			<WithBillingDetails useChildrenAsPlaceholder={true}>
				<MoneyStuff>
					{({ tariff, tariffs }) => (
						<>
							{accountType === GraphQL.AccountType.EndUser && (
								<EditEndUserWebsiteSubscriptionForm
									submitCallback={handleWebsiteSubscriptionSubmit}
									tariff={tariff}
									team={team}
									teamBilling={teamBilling}
									website={websiteDetail}
								/>
							)}

							{accountType === GraphQL.AccountType.Universal && (
								<EditWebsiteSubscriptionForm
									submitCallback={handleWebsiteSubscriptionSubmit}
									tariffs={tariffs}
									team={team}
									teamBilling={teamBilling}
									website={websiteDetail}
								/>
							)}
						</>
					)}
				</MoneyStuff>
			</WithBillingDetails>

			<ContributorsAssignmentForm
				websiteAccountId={accountId}
				websiteId={websiteId}
			/>
		</FormsList>
	);
};



export default WebsiteDetailsGroup;
