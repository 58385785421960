import classNames from 'classnames';
import React from 'react';



export enum IssueValueCardFlow {
	Column = 'column',
	Row = 'row',
}

export enum IssueValueCardSize {
	Default = 'default',
	Large = 'large',
	Small = 'small',
}

export enum IssueValueCardStyle {
	Informative = 'informative',
	Negative = 'negative',
	Neutral = 'neutral',
	Positive = 'positive',
}



type Props = {
	/** Flow of elements in layout of card */
	flow?: IssueValueCardFlow,
	/** Icon representing type of displayed value */
	icon: React.ReactNode,
	/** Different sizes of card */
	size?: IssueValueCardSize,
	/** Color style of card */
	style?: IssueValueCardStyle,
	/** Displayed value on the card */
	value?: React.ReactNode,
};



const IssueValueCard: React.FC<Props> = (props) => {
	const {
		flow = IssueValueCardFlow.Row,
		icon,
		size = IssueValueCardSize.Default,
		style = IssueValueCardStyle.Neutral,
		value,
	} = props;

	const componentClasses = classNames({
		'issue-value-card': true,
		['issue-value-card--' + flow + '-flow']: true,
		['issue-value-card--' + size + '-size']: true,
		['issue-value-card--' + style + '-style']: true,
	});

	return (
		<div className={componentClasses}>
			<div className="issue-value-card__icon">{icon}</div>
			{value && (
				<div className="issue-value-card__value">{value}</div>
			)}
		</div>
	);
};



export default IssueValueCard;
