import React from 'react';

import SelectField, {
	SelectFieldSize,
} from '~/components/atoms/forms/components/SelectField';



type Props = {
	name: string,
	size?: SelectFieldSize,
	width?: number,
};

const CustomElementNumberPatternSelectField: React.FC<Props> = (props) => {
	const {
		name,
		size = SelectFieldSize.Default,
		width,
	} = props;

	return (
		<SelectField
			dropdownWidth={200}
			name={name}
			options={[
				{
					label: '1,234.56',
					name: 'comma_dot',
				},
				{
					label: '1.234,56',
					name: 'dot_comma',
				},
				{
					label: '1234.56',
					name: 'none_dot',
				},
				{
					label: '1234,56',
					name: 'none_comma',
				},
				{
					label: '1 234.56',
					name: 'space_dot',
				},
				{
					label: '1 234,56',
					name: 'space_comma',
				},
			]}
			size={size}
			width={width}
		/>
	);
};



export default CustomElementNumberPatternSelectField;

export {
	SelectFieldSize as CustomElementNumberPatternSelectFieldSize,
};
