import React from 'react';

import RichText from '~/components/patterns/typography/RichText';



type Props = {
	children: React.ReactNode,
};



const Protip: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="protip">
			<div className="protip__message">
				<RichText>
					{children}
				</RichText>
			</div>
		</div>
	);
};



export default Protip;
