import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttachedCTAElement, {
	AttachedCTAElementAlignment,
} from '~/components/patterns/structuredValues/AttachedCTAElement';
import AuthButtonsLayout from '~/components/atoms/auth/AuthButtonsLayout';
import AuthFormLayout from '~/components/atoms/auth/AuthFormLayout';
import AuthSectionsGroup from '~/components/atoms/auth/AuthSectionsGroup';
import AuthTextSection from '~/components/atoms/auth/AuthTextSection';
import AuthTitle from '~/components/atoms/auth/AuthTitle';
import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import CodeValue from '~/components/patterns/values/CodeValue';
import Copy from '~/components/logic/Copy';
import ExternalLink, {
	ExternalLinkStyle,
} from '~/components/patterns/links/ExternalLink';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import HelpHint, {
	HelpHintMessageSize,
	HelpHintStyle,
} from '~/components/patterns/hints/HelpHint';
import LabeledValue, {
	LabeledValueContentAlignment,
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import LazyImage from '~/components/patterns/images/LazyImage';
import NumberedItem from '~/components/logic/NumberedItem';
import QRCodeImage from '~/components/atoms/images/QRCodeImage';
import RichText from '~/components/patterns/typography/RichText';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';
import Spinner from '~/components/patterns/loaders/Spinner';
import SubmitButton, {
	SubmitButtonSize,
} from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldSize,
} from '~/components/atoms/forms/components/TextField';

import {
	useAcceptInvitationWithTwoFactorMutation,
	useInitiateAcceptInvitationWithTwoFactorMutation,
} from '~/components/app/AcceptInvitationWith2FASetup.gql';

import {
	validateField,
} from '~/components/app/validations';



const messages = defineMessages({
	authenticatorAppHint: {
		id: 'ui.twoFactorAuthenticationSetup.authenticatorAppHint',
	},
	backToQrCode: {
		id: 'ui.twoFactorAuthenticationSetup.backToQrCode',
	},
	codePrompt: {
		id: 'ui.twoFactorAuthenticationSetup.codePrompt',
	},
	havingTroubles: {
		id: 'ui.twoFactorAuthenticationSetup.havingTroubles',
	},
	invalidCodeError: {
		id: 'ui.twoFactorAuthenticationLogin.errors.invalidCode',
	},
	linkTo2faArticle: {
		id: 'ui.twoFactorAuthenticationSetup.sidebar.linkSupportTarget',
	},
	manualEntryAccount: {
		id: 'ui.twoFactorAuthenticationSetup.manualEntryAccount',
	},
	manualEntryKey: {
		id: 'ui.twoFactorAuthenticationSetup.manualEntryKey',
	},
	manualEntryPrompt: {
		id: 'ui.twoFactorAuthenticationSetup.manualEntryPrompt',
	},
	qrcodePrompt: {
		id: 'ui.twoFactorAuthenticationSetup.qrCodePrompt',
	},
	submit: {
		id: 'ui.twoFactorAuthenticationSetup.submit',
	},
	title: {
		id: 'ui.twoFactorAuthenticationSetupBeforeJoining.title',
	},
});



const validations = {
	validateCode: validateField(
		'code',
		(f) => ([
			f.validateNonEmpty(),
			f.customGlobal({
				message: (
					<FormattedMessage {...messages.invalidCodeError} />
				),
				globalRule: 'invalidCode',
			}),
		]),
	),
};



function renderAuthenticatorHelpHint() {
	return (
		<HelpHint
			message={(
				<RichText>
					<Copy
						{...messages.authenticatorAppHint}
						values={{
							linkAndroidApp: (chunks) => (
								<ExternalLink
									href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
									style={ExternalLinkStyle.Auth}
								>
									{chunks}
								</ExternalLink>
							),
							linkIosApp: (chunks) => (
								<ExternalLink
									href="https://apps.apple.com/us/app/google-authenticator/id388497605"
									style={ExternalLinkStyle.Auth}
								>
									{chunks}
								</ExternalLink>
							),
						}}
					/>
				</RichText>
			)}
			messageSize={HelpHintMessageSize.Large}
			style={HelpHintStyle.Light}
		/>
	);
}



type Props = {
	onFinish: () => void,
};

const AcceptInvitationWith2FASetup: React.FC<Props> = (props) => {
	const {
		onFinish,
	} = props;

	const [showManualEntry, setShowManualEntry] = React.useState(false);

	const toggleShowManualEntry = React.useCallback(
		() => setShowManualEntry(!showManualEntry),
		[showManualEntry],
	);

	const [initiateAcceptInvitationWithTwoFactor, { data }] = useInitiateAcceptInvitationWithTwoFactorMutation();
	const [acceptInvitationWithTwoFactor] = useAcceptInvitationWithTwoFactorMutation();

	React.useEffect(
		() => {
			initiateAcceptInvitationWithTwoFactor();
		},
		[
			initiateAcceptInvitationWithTwoFactor,
		],
	);

	const email = data?.InitiateAcceptInvitationWithTwoFactorAuthenticationSetup.email ?? null;
	const qrcode = data?.InitiateAcceptInvitationWithTwoFactorAuthenticationSetup.qrcode ?? null;
	const secretKey = data?.InitiateAcceptInvitationWithTwoFactorAuthenticationSetup.secretKey ?? null;

	const handleSubmit = React.useCallback(
		async (values) => {
			await acceptInvitationWithTwoFactor({
				variables: {
					code: values.code,
				},
			});

			onFinish();
		},
		[
			acceptInvitationWithTwoFactor,
			onFinish,
		],
	);

	return (
		<AuthFormLayout
			header={(
				<AuthTitle iconType={BasicIconType.Protected}>
					<FormattedMessage {...messages.title} />
				</AuthTitle>
			)}
		>
			<AuthSectionsGroup divider={true}>
				{!showManualEntry && (
					<AuthSectionsGroup>
						<AuthTextSection>
							<RichText>
								<p>
									<NumberedItem
										content={(
											<Copy
												{...messages.qrcodePrompt}
												values={{
													helpHint: renderAuthenticatorHelpHint(),
												}}
											/>
										)}
										number={1}
									/>
								</p>
							</RichText>
						</AuthTextSection>
						<AttachedCTAElement
							ctaElement={(
								<InternalLink
									onClickCallback={toggleShowManualEntry}
									style={InternalLinkStyle.Auth}
								>
									<FormattedMessage {...messages.havingTroubles} />
								</InternalLink>
							)}
						>
							<QRCodeImage>
								<LazyImage
									height={200}
									placeholder={(
										<Spinner />
									)}
									src={qrcode}
									width={200}
								/>
							</QRCodeImage>
						</AttachedCTAElement>
					</AuthSectionsGroup>
				)}

				{showManualEntry && (
					<AuthSectionsGroup>
						<AuthTextSection>
							<RichText>
								<p>
									<NumberedItem
										content={(
											<Copy
												{...messages.manualEntryPrompt}
												values={{
													helpHint: renderAuthenticatorHelpHint(),
												}}
											/>
										)}
										number={1}
									/>
								</p>
							</RichText>
						</AuthTextSection>
						<AttachedCTAElement
							alignment={AttachedCTAElementAlignment.Right}
							ctaElement={(
								<InternalLink
									onClickCallback={toggleShowManualEntry}
									style={InternalLinkStyle.Auth}
								>
									<FormattedMessage {...messages.backToQrCode} />
								</InternalLink>
							)}
						>
							<SimpleBox>
								<LabeledValuesList>
									<LabeledValue
										contentAlignment={LabeledValueContentAlignment.Left}
										flow={LabeledValueFlow.ColumnOnSmall}
										label={(
											<FormattedMessage {...messages.manualEntryAccount} />
										)}
									>
										{email}
									</LabeledValue>
									<LabeledValue
										contentAlignment={LabeledValueContentAlignment.Left}
										flow={LabeledValueFlow.ColumnOnSmall}
										label={(
											<FormattedMessage {...messages.manualEntryKey} />
										)}
									>
										<CodeValue>{secretKey}</CodeValue>
									</LabeledValue>
								</LabeledValuesList>
							</SimpleBox>
						</AttachedCTAElement>
					</AuthSectionsGroup>
				)}

				<AuthSectionsGroup>
					<AuthTextSection>
						<RichText>
							<p>
								2. <FormattedMessage {...messages.codePrompt} />
							</p>
						</RichText>
					</AuthTextSection>

					<Form
						defaultFocus="code"
						defaultValues={{
							code: '',
						}}
						onSuccess={handleSubmit}
						validations={validations}
					>
						<FormRow fullwidth={true}>
							<FieldStatus
								allowOk={false}
								name="validateCode"
								showIcon={false}
							>
								<TextField
									attributes={{
										inputMode: 'numeric',
										pattern: '[0-9]{6}',
									}}
									autoComplete={TextFieldAutocomplete.OneTimeCode}
									name="code"
									placeholder="123456"
									resetButton={true}
									size={TextFieldSize.Large}
									width="100%"
								/>
							</FieldStatus>
						</FormRow>

						<AuthButtonsLayout
							indentationSize={1}
							leftButton={(
								<SubmitButton
									size={SubmitButtonSize.XLarge}
								>
									<FormattedMessage {...messages.submit} />
								</SubmitButton>
							)}
						/>
					</Form>
				</AuthSectionsGroup>
			</AuthSectionsGroup>
		</AuthFormLayout>
	);
};



export default AcceptInvitationWith2FASetup;
