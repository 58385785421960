import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';

import {
	useUpdateWebsiteAdminDebuggingMutation,
	useWebsiteAdminDebuggingFormQuery,
} from './WebsiteAdminDebuggingForm.gql';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';

import getOptionLabel from '~/utilities/getOptionLabel';



const fetchingLogLevelOptions = [
	{
		name: 'none',
		label: 'off',
	},
	{
		name: 'headers_only',
		label: 'headers only',
	},
	{
		name: 'all',
		label: 'everything',
	},
];



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminDebuggingForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const isAllowedToManageDebugging = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageAdminDebugging,
	);

	const { data } = useWebsiteAdminDebuggingFormQuery({
		variables: {
			websiteId,
		},
	});

	const [updateWebsiteAdminDebugging] = useUpdateWebsiteAdminDebuggingMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteAdminDebugging({
				variables: {
					collectDetailedDiagnostics: values.collectDetailedDiagnostics,
					fetchingLogLevel: values.fetchingLogLevel,
					websiteId,
				},
			});
		},
		[
			updateWebsiteAdminDebugging,
			websiteId,
		],
	);

	const adminSettings = data?.website?.adminSettings ?? null;

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageDebugging}
			isForAdmins={true}
			title="Debugging"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Request logging">
						<StaticText focusTarget="fetchingLogLevel">
							{getOptionLabel(fetchingLogLevelOptions, adminSettings?.fetchingLogLevel ?? null)}
						</StaticText>
					</FormRow>

					<FormRow label="Collect detailed diagnostics">
						<StaticText focusTarget="collectDetailedDiagnostics">
							{adminSettings !== null ? (adminSettings.collectDetailedDiagnostics ? 'yes' : 'no') : '-'}
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				{adminSettings !== null && (
					<Form
						defaultValues={{
							collectDetailedDiagnostics: adminSettings.collectDetailedDiagnostics,
							fetchingLogLevel: adminSettings.fetchingLogLevel,
						}}
						onSuccess={handleSubmit}
					>
						<FormRows>
							<FormRow
								htmlFor="fetchingLogLevel"
								label="Request logging"
							>
								<SelectField
									name="fetchingLogLevel"
									options={fetchingLogLevelOptions}
								/>
							</FormRow>

							<FormRow
								htmlFor="collectDetailedDiagnostics"
								label="Collect detailed diagnostics"
							>
								<SwitchField
									activeStateLabel="yes"
									inactiveStateLabel="no"
									name="collectDetailedDiagnostics"
								/>
							</FormRow>
						</FormRows>

						<FormErrorMessages />

						<ButtonsLayout>
							<CancelButton />
							<SaveSubmitButton />
						</ButtonsLayout>
					</Form>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default WebsiteAdminDebuggingForm;
