import createRouter, {
	type Router,
} from 'router5';
import {
	type NavigationOptions,
	type Params,
} from 'router5/dist/types/base';



let router: Router | undefined;



export function getRouter(): Router {
	if (router === undefined) {
		router = recreateRouter();
	}

	return router;
}



export function recreateRouter() {
	router = createRouter([], {
		queryParamsMode: 'loose',
		strictTrailingSlash: true,
	});

	return router;
}



export function navigate(
	routeName: string,
	routeParams: Params = {},
	options: NavigationOptions = {},
): void {
	getRouter().navigate(routeName, routeParams, options);
}



export function getRouterState() {
	return getRouter().getState();
}
