import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const TrashIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="trash-bin"
					transform="translate(3.000000, 3.000000)"
				>
					<polygon
						id="Stroke-3"
						points="15.8333333 18.3325 4.16666667 18.3325 4.16666667 4.16583333 15.8333333 4.16583333"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polygon>
					<path
						d="M1.66733333,4.16616667 L18.3331667,4.16616667"
						id="Stroke-4"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<polyline
						id="Stroke-5"
						points="7.50016667 4.16566667 7.50016667 0.832333333 12.5001667 0.832333333 12.5001667 4.16566667"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polyline>
					<path
						d="M8.33383333,8.33333333 L8.33383333,14.9991667"
						id="Stroke-6"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M11.6691667,8.33333333 L11.6691667,14.9991667"
						id="Stroke-7"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default TrashIconBuilder;

