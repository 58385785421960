import {
	Map,
} from 'immutable';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DisplayPart from '../basis/DisplayPart';
import EditableFormWrapper from '../basis/EditableFormWrapper';
import EditablePart from '../basis/EditablePart';
import Form from '../basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormTitle from '../basis/FormTitle';
import CancelButton from '~/components/app/CancelButton';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';

import CustomInvoiceFields from '../sharedFields/CustomInvoiceFields';
import DeliveryAddressFields from '../sharedFields/DeliveryAddressFields';



const messages = defineMessages({
	deliveryAddressTitle: {
		id: 'ui.teamDetail.billing.deliveryAddressTitle',
	},
	title: {
		id: 'ui.teamDetail.billing.invoiceDetailsTitle',
	},
});



class InvoiceDetailsForm extends Component {

	_renderOverview() {
		const {
			billingDetails,
			team,
		} = this.props;

		if (!billingDetails) {
			return false;
		}

		return (
			<>
				{team.get('is_invoice_details_enabled') && (
					<CustomInvoiceFields
						editMode={false}
						invoiceDetails={Map({
							attn: billingDetails.get('attn'),
							po_number: billingDetails.get('po_number'),
							supplier_number: billingDetails.get('supplier_number'),
							customer_reference: billingDetails.get('customer_reference'),
						})}
					/>
				)}

				{team.get('is_delivery_address_enabled') && (
					<FormTitle>
						<FormattedMessage {...messages.deliveryAddressTitle} />
					</FormTitle>
				)}

				{team.get('is_delivery_address_enabled') && (
					<DeliveryAddressFields
						deliveryAddress={billingDetails.get('delivery_address') || Map()}
						editMode={false}
					/>
				)}
			</>
		);
	}



	_renderForm() {
		const {
			billingDetails,
			submitCallback,
			team,
		} = this.props;

		const defaultValues = {};

		if (team.get('is_delivery_address_enabled')) {
			defaultValues.address = billingDetails.getIn(['delivery_address', 'address']);
			defaultValues.city = billingDetails.getIn(['delivery_address', 'city']);
			defaultValues.company = billingDetails.getIn(['delivery_address', 'company']);
			defaultValues.country = billingDetails.getIn(['delivery_address', 'country']);
			defaultValues.first_name = billingDetails.getIn(['delivery_address', 'first_name']);
			defaultValues.last_name = billingDetails.getIn(['delivery_address', 'last_name']);
			defaultValues.state = billingDetails.getIn(['delivery_address', 'state']);
			defaultValues.zip_code = billingDetails.getIn(['delivery_address', 'zip_code']);
		}

		if (team.get('is_invoice_details_enabled')) {
			defaultValues.attn = billingDetails.get('attn');
			defaultValues.po_number = billingDetails.get('po_number');
			defaultValues.supplier_number = billingDetails.get('supplier_number');
			defaultValues.customer_reference = billingDetails.get('customer_reference');
		}

		return (
			<Form
				defaultFocus={team.get('is_invoice_details_enabled') ? 'attn' : 'company'}
				defaultValues={defaultValues}
				onSuccess={submitCallback}
			>
				{({ values }) => {
					return (
						<>
							{team.get('is_invoice_details_enabled') && (
								<CustomInvoiceFields
									editMode={true}
								/>
							)}

							{team.get('is_delivery_address_enabled') && (
								<FormTitle>
									<FormattedMessage {...messages.deliveryAddressTitle} />
								</FormTitle>
							)}

							{team.get('is_delivery_address_enabled') && (
								<DeliveryAddressFields
									country={values.country}
									editMode={true}
								/>
							)}

							<ButtonsLayout>
								<CancelButton />
								<SaveSubmitButton />
							</ButtonsLayout>
						</>
					);
				}}
			</Form>
		);
	}



	render() {
		return (
			<EditableFormWrapper
				title={(
					<FormattedMessage {...messages.title} />
				)}
			>
				<DisplayPart>
					{this._renderOverview()}
				</DisplayPart>

				<EditablePart>
					{this._renderForm()}
				</EditablePart>
			</EditableFormWrapper>
		);
	}

}



export default InvoiceDetailsForm;
