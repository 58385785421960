import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';

import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	openPage: {
		id: 'ui.pageDetail.buttons.openPage',
	},
});



type Props = {
	url: string | null,
};

const OpenPageButton: React.FC<Props> = (props) => {
	const {
		url,
	} = props;

	const viewportType = useViewportType();

	return (
		<Button
			compact={viewportType.isSmall || viewportType.isMedium}
			disabled={url === null}
			href={url ?? ''}
			icon={(
				<BasicIcon type={BasicIconType.ExternalLink} />
			)}
			size={ButtonSize.Small}
			style={ButtonStyle.Hollow}
			uppercase={true}
		>
			<FormattedMessage {...messages.openPage} />
		</Button>
	);
};



export default OpenPageButton;
