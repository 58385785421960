import classNames from 'classnames';
import React from 'react';

import ButtonContextProvider from '~/components/atoms/buttons/ButtonContextProvider';
import {
	ButtonSize,
} from '~/components/patterns/buttons/Button';

import useViewportType from '~/hooks/useViewportType';



export enum ScreenHeaderScope {
	Panel = 'panel',
	Screen = 'screen',
}

type Props = {
	children?: React.ReactNode,
	footer?: React.ReactNode,
	header?: React.ReactNode,
	/** Different spacing when header is used in different scope */
	scope?: ScreenHeaderScope,
};



const ScreenHeader: React.FC<Props> = (props) => {
	const {
		children,
		footer,
		header,
		scope = ScreenHeaderScope.Screen,
	} = props;

	const viewportType = useViewportType();

	const componentClasses = classNames({
		'header': true,
		'header--panel-layout': scope === ScreenHeaderScope.Panel,
		'header--screen-layout': scope === ScreenHeaderScope.Screen,
	});

	return (
		<ButtonContextProvider
			compact={viewportType.isSmall}
			iconColor="#5893eb"
			iconSize={24}
			size={ButtonSize.Small}
			uppercase={true}
		>
			<section className={componentClasses}>
				{header && (
					<div className="header__header">
						{header}
					</div>
				)}

				{children && (
					<div className="header__container">
						{children}
					</div>
				)}

				{footer && (
					<div className="header__footer">
						{footer}
					</div>
				)}
			</section>
		</ButtonContextProvider>
	);
};



export default ScreenHeader;
