import React from 'react';

import {
	FieldRowBuilderContext,
} from '~/components/app/FieldRowBuilder';



type Props = {
	children?: React.ReactNode,
};

const FieldRowBuilderDisplaySlot: React.FC<Props> = (props) => {
	const {
		children = null,
	} = props;

	const fieldRowBuilderContext = React.useContext(FieldRowBuilderContext);

	if (fieldRowBuilderContext === null) {
		return <>{children}</>;
	}

	if (fieldRowBuilderContext.isEditable) {
		return null;
	}

	if (fieldRowBuilderContext.isEditable === null && fieldRowBuilderContext.renderField !== null) {
		return null;
	}

	if (fieldRowBuilderContext.renderDisplay !== null) {
		return fieldRowBuilderContext.renderDisplay(children);
	}

	return <>{children}</>;
};



export default FieldRowBuilderDisplaySlot;
