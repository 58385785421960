import React from 'react';

import useBatchContextDashboard from '~/hooks/useBatchContextDashboard';
import useManuallyTrackedLoading from '~/hooks/useManuallyTrackedLoading';
import usePollInterval from '~/hooks/usePollInterval';

import {
	ScopeSectionContext,
} from '~/components/app/Dashboard/ScopeSection';



function useDashboardQuery(mainField, useQueryHook, options) {
	const isVisible = React.useContext(ScopeSectionContext).isVisible;

	const [hasBeenVisible, setHasBeenVisible] = React.useState(false);
	const [pollInterval, setPollInterval] = React.useState(0);

	const manuallyTrackedLoading = useManuallyTrackedLoading();

	React.useEffect(
		() => {
			if (!hasBeenVisible && isVisible) {
				setHasBeenVisible(true);
			}
		},
		[
			hasBeenVisible,
			isVisible,
		],
	);

	const skip = options.skip || !hasBeenVisible;

	options = {
		...options,
		context: useBatchContextDashboard(options.variables.websiteId, options.variables.scope),
		pollInterval: usePollInterval(isVisible ? pollInterval : 0),
		skip,
	};

	const result = useQueryHook(options);

	const dashboardFieldPayload = (
		result.data
		&& result.data.dashboardData[mainField]
	) || null;

	const data = dashboardFieldPayload !== null ? dashboardFieldPayload.data : null;

	React.useEffect(
		() => setPollInterval((dashboardFieldPayload && dashboardFieldPayload.pollInterval) || 0),
		[
			dashboardFieldPayload,
		],
	);

	React.useEffect(
		() => {
			if (
				data === null
				&& !result.loading
				&& !skip
				&& manuallyTrackedLoading !== null
			) {
				manuallyTrackedLoading.finish();
			}
		},
		[
			data,
			result.loading,
			skip,
			manuallyTrackedLoading,
		],
	);

	return {
		...result,
		data,
		// when skip is "true", loading remains "false",
		// so to prevent flash of "no data" message, we override loading to "true"
		loading: result.loading || !hasBeenVisible,
		reasonForNoData: dashboardFieldPayload !== null ? dashboardFieldPayload.reasonForNoData : null,
	};
}



export default useDashboardQuery;
