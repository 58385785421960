import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const LighthouseIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g fill="none" stroke={color} strokeLinejoin="round" strokeWidth={(60 / size) * 2}>
				<path d="M44.08,30.37H16.3v8.44H44.08Z" />
				<g strokeLinecap="round">
					<path d="M20,30.37V24.06a10.13,10.13,0,0,1,.77-3.88,10.32,10.32,0,0,1,2.2-3.3,10.12,10.12,0,0,1,7.17-3h0a10.14,10.14,0,0,1,3.89.76,10.2,10.2,0,0,1,5.5,5.5,10.22,10.22,0,0,1,.77,3.89v6.31" />
					<path d="M20.44 21.49H40M30.18 9.1v4.81M20 26.85v3.52m6.8-8.88v8.88m6.77-8.88v8.88m6.77-3.52v3.52m.91 18.38l-.34-10m-21.45.04l-.6 10m-9.47-29.9l5.9 1.25m-5.9 5.69l5.9-1.26m35.32-5.68l-5.9 1.25m5.9 5.69l-5.9-1.26" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default LighthouseIconBuilder;

