import classNames from 'classnames';
import React, {
	Component,
} from 'react';



type Props = {
	/** Flag to make content disabled */
	disabledContent: boolean,
	/** Overlay elements visible when content is disabled */
	disabledOverlay?: React.ReactNode,
	children: React.ReactNode,
};



class DisabledContent extends Component<Props> {

	static defaultProps = {
		disabledContent: true,
	};



	constructor(props, context) {
		super(props, context);

		this._handleClick = this._handleClick.bind(this);
	}



	_handleClick(e) {
		const {
			disabledContent,
		} = this.props;

		if (disabledContent) {
			e.preventDefault();
			e.stopPropagation();

			return false;
		}
	}



	render() {
		const {
			disabledContent,
			disabledOverlay,
			children,
		} = this.props;

		const componentClasses = classNames({
			'disabled-content': true,
			'disabled-content--is-disabled': disabledContent,
		});

		return (
			<div className={componentClasses}>
				<div
					className="disabled-content__body"
					onClickCapture={this._handleClick}
				>
					{children}
				</div>
				{disabledContent && disabledOverlay && (
					<div className="disabled-content__overlay">
						{disabledOverlay}
					</div>
				)}
			</div>
		);
	}

}



export default DisabledContent;
