import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import ModalPanel, {
	SIZE_LARGE as MODAL_SIZE_LARGE,
	SIZE_MEDIUM as MODAL_SIZE_MEDIUM,
} from '~/components/atoms/panels/ModalPanel';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import ScrollableBox from '~/components/atoms/boxes/ScrollableBox';
import Spinner from '~/components/patterns/loaders/Spinner';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useAcceptDataProcessingAgreementMutation,
} from '~/components/app/DataProcessingAgreementModal.gql';

import useAccountId from '~/hooks/useAccountId';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useDataProcessingAgreementPromptSnooze from '~/hooks/useDataProcessingAgreementPromptSnooze';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useInitialValue from '~/hooks/useInitialValue';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useLegalDocument, {
	LegalDocument,
} from '~/hooks/useLegalDocument';
import useLegalDocumentRequirement from '~/hooks/useLegalDocumentRequirement';
import useModals from '~/hooks/useModals';
import useUserRole from '~/hooks/useUserRole';



const messages = defineMessages({
	accept: {
		id: 'ui.dataProcessingAgreementModal.accept',
	},
	alreadyAccepted: {
		id: 'ui.dataProcessingAgreementModal.alreadyAccepted',
	},
	description: {
		id: 'ui.dataProcessingAgreementModal.description',
	},
	cannotAccept: {
		id: 'ui.dataProcessingAgreementModal.cannotAccept',
	},
	hasAccepted: {
		id: 'ui.dataProcessingAgreementModal.hasAccepted',
	},
	insufficientPermissions: {
		id: 'ui.general.insufficientPermissions',
	},
	ok: {
		id: 'ui.general.ok',
	},
	title: {
		id: 'ui.dataProcessingAgreementModal.title',
	},
});



type Props = {
	canBeClosed: boolean,
};

const DataProcessingAgreementModal: React.FC<Props> = (props) => {
	const {
		canBeClosed,
	} = props;

	const accountId = useAccountId();
	const currentUserId = useCurrentUserId();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const currentUserRole = useUserRole(effectiveHomeAccountId, currentUserId);
	const dataProcessingAgreementPromptSnooze = useDataProcessingAgreementPromptSnooze();
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const modals = useModals();
	const modalRef = React.useRef<MultiStepModalRef | null>(null);

	const [acceptDataProcessingAgreement] = useAcceptDataProcessingAgreementMutation();
	const dataProcessingAgreement = useLegalDocument(LegalDocument.DataProcessingAgreement);

	const requirement = useLegalDocumentRequirement(accountId, GraphQL.LegalDocumentType.Dpa);
	const initialRequirement = useInitialValue(requirement.document?.requirement ?? null);

	const loading = (
		accountId === null
		|| requirement.loading
	);

	const didAlreadyAcceptAgreement = (
		initialRequirement === GraphQL.LegalDocumentRequirementType.Accepted
	);

	const hasAcceptedAgreement = (
		requirement.document?.requirement === GraphQL.LegalDocumentRequirementType.Accepted
	);

	const canAcceptAgreement = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageBilling,
	);

	React.useEffect(
		() => {
			if (requirement.document?.requirement === GraphQL.LegalDocumentRequirementType.NotApplicable) {
				modals.closeModal();
			}
		},
		[
			modals,
			requirement,
		],
	);

	const handleSubmit = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			await acceptDataProcessingAgreement({
				variables: {
					accountId,
				},
			});

			modalRef.current?.goToNextStep();
		},
		[
			acceptDataProcessingAgreement,
			accountId,
			modalRef,
		],
	);

	return (
		<ModalPanel
			canBeClosed={canBeClosed || canAcceptAgreement.no || hasAcceptedAgreement || kingdomAdminFeatures.areVisible}
			canBeClosedViaOverlay={false}
			onCloseCallback={() => {
				dataProcessingAgreementPromptSnooze.snooze();

				modals.closeCallback();
			}}
			size={canAcceptAgreement.no === false ? MODAL_SIZE_LARGE : MODAL_SIZE_MEDIUM}
		>
			<MultiStepModal
				initialStep={didAlreadyAcceptAgreement ? 1 : 0}
				key={loading ? 'loading' : 'ready'}
				minHeight={loading ? 420 : 0}
				preloader={(
					loading && <LoadingDots isStretched={true} />
				)}
				ref={modalRef}
			>
				{canAcceptAgreement.no && (
					<MultiStepModalStep
						name="insufficient-permissions"
						title={(
							<FormattedMessage {...messages.insufficientPermissions} />
						)}
					>
						<RichText>
							<Copy
								{...messages.cannotAccept}
								values={{ userRole: currentUserRole }}
							/>
						</RichText>

						<ButtonsLayout
							layout={ButtonsLayoutType.Steps}
						>
							<Button
								onClick={() => modals.closeCallback()}
								style={ButtonStyle.Action}
								width={ButtonWidth.SubmitButton}
							>
								<FormattedMessage {...messages.ok} />
							</Button>
						</ButtonsLayout>
					</MultiStepModalStep>
				)}

				{canAcceptAgreement.no === false && (
					<MultiStepModalStep
						name="data-processing-agreement"
						title={(
							<FormattedMessage {...messages.title} />
						)}
					>
						<ModalContentSection>
							<FormattedMessage {...messages.description} />
						</ModalContentSection>

						<Form
							defaultDataHasChanged={true}
							onSuccess={handleSubmit}
						>
							<ScrollableBox maxHeight={400}>
								{dataProcessingAgreement.content !== null ? (
									dataProcessingAgreement.content
								) : (
									<div style={{ minHeight: '50vh', width: '100%' }}>
										<Spinner />
									</div>
								)}
							</ScrollableBox>

							<FormErrorMessages />

							<ButtonsLayout
								layout={ButtonsLayoutType.Steps}
							>
								<SubmitButton>
									<FormattedMessage {...messages.accept} />
								</SubmitButton>
							</ButtonsLayout>
						</Form>
					</MultiStepModalStep>
				)}

				{(canAcceptAgreement.no === false || didAlreadyAcceptAgreement) && (
					<MultiStepModalStep
						name="success"
						title={(
							<FormattedMessage {...messages.title} />
						)}
					>
						<ConfirmationMessage
							animate={true}
							context={ConfirmationMessageContext.Confirmation}
							ctaButtons={(
								<Button
									onClick={() => modals.closeCallback()}
									style={ButtonStyle.Action}
									uppercase={false}
									width={ButtonWidth.SubmitButton}
								>
									<FormattedMessage {...messages.ok} />
								</Button>
							)}
						>
							{didAlreadyAcceptAgreement ? (
								<Copy
									{...messages.alreadyAccepted}
									values={{
										linkDocument: linkExternal(dataProcessingAgreement.article ?? ''),
									}}
								/>
							) : (
								<Copy
									{...messages.hasAccepted}
									values={{
										linkDocument: linkExternal(dataProcessingAgreement.article ?? ''),
									}}
								/>
							)}
						</ConfirmationMessage>
					</MultiStepModalStep>
				)}
			</MultiStepModal>
		</ModalPanel>
	);
};



export default DataProcessingAgreementModal;
