import {
	Map,
} from 'immutable';

import {
	MENU_USER_NAVIGATION_CLOSE,
	MENU_USER_NAVIGATION_OPEN,
	MENU_WEBSITE_SWITCHER_CLOSE,
	MENU_WEBSITE_SWITCHER_OPEN,
} from '~/actions';



export function menu(state, action) {
	if (state === undefined) {
		state = new Map({
			userNavigation: false,
			websiteSwitcher: false,
		});
	}

	switch (action.type) {

		case MENU_USER_NAVIGATION_CLOSE: {
			state = state.set('userNavigation', false);
			break;
		}

		case MENU_USER_NAVIGATION_OPEN: {
			state = state.set('userNavigation', true);
			state = state.set('websiteSwitcher', false);
			break;
		}

		case MENU_WEBSITE_SWITCHER_CLOSE: {
			state = state.set('websiteSwitcher', false);
			break;
		}

		case MENU_WEBSITE_SWITCHER_OPEN: {
			state = state.set('userNavigation', false);
			state = state.set('websiteSwitcher', true);
			break;
		}

	}

	return state;
}
