const selector = (state) => state.get('websiteIssuesComparison');

export const dateFromSelector = (state) => selector(state).get('dateFrom');

export const dateToSelector = (state) => selector(state).get('dateTo');

export const filterSelector = (state) => selector(state).get('filter');

export const isActiveSelector = (state) => !!(dateFromSelector(state) && dateToSelector(state));

export const scopeSelector = (state) => filterSelector(state).get('scope');
