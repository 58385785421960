import React from 'react';
import {
	FormattedList,
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';

import useGoogleAnalyticsDependencies from '~/hooks/useGoogleAnalyticsDependencies';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	caution: {
		id: 'ui.general.caution',
	},
	alertDefinitions: {
		id: 'ui.googleAnalyticsDependenciesWarning.alertDefinitions',
	},
	segments: {
		id: 'ui.googleAnalyticsDependenciesWarning.segments',
	},
	warning: {
		id: 'ui.googleAnalyticsDependenciesWarning.warning',
	},
});



type Props = {
	googleAnalyticsVersion: GraphQL.GoogleAnalyticsVersion,
};

const GoogleAnalyticsDependenciesWarning: React.FC<Props> = (props) => {
	const {
		googleAnalyticsVersion,
	} = props;

	const websiteId = useWebsiteId();
	const googleAnalyticsDependencies = useGoogleAnalyticsDependencies(websiteId, googleAnalyticsVersion);

	if (!googleAnalyticsDependencies.hasDependencies) {
		return null;
	}

	const dependencies: Array<React.ReactElement> = [];

	const alertDefinitions = googleAnalyticsDependencies.alertDefinitions;
	const segments = googleAnalyticsDependencies.segments;

	if (segments.length > 0) {
		dependencies.push(
			<FormattedMessage
				{...messages.segments}
				key="segments-message"
				values={{
					segments: segments.length,
				}}
			/>,
		);
	}

	if (alertDefinitions.length > 0) {
		dependencies.push(
			<FormattedMessage
				{...messages.alertDefinitions}
				key="alert-definitions-message"
				values={{
					alertDefinitions: alertDefinitions.length,
				}}
			/>,
		);
	}

	return (
		<CalloutMessage
			borders={true}
			message={(
				<FormattedMessage {...messages.caution} />
			)}
			status={CalloutMessageStatus.Critical}
		>
			<Copy
				{...messages.warning}
				values={{
					googleAnalyticsVersion,
					dependencies: (
						<FormattedList
							type="conjunction"
							value={dependencies}
						/>
					),
				}}
			/>
		</CalloutMessage>
	);
};



export default GoogleAnalyticsDependenciesWarning;
