import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const HistoryIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M23.884,23.18l-2.5-3A.5.5,0,0,0,21,20H3a.5.5,0,0,0-.384.18l-2.5,3A.5.5,0,0,0,.5,24h23a.5.5,0,0,0,.384-.82Z"
				fill={color}
			/>
			<rect
				fill={color}
				height="8.5"
				rx="0.5"
				ry="0.5"
				width="4"
				x="3"
				y="10.5"
			/>
			<rect
				fill={color}
				height="8.5"
				rx="0.5"
				ry="0.5"
				width="4"
				x="10"
				y="10.5"
			/>
			<rect
				fill={color}
				height="8.5"
				rx="0.5"
				ry="0.5"
				width="4"
				x="17"
				y="10.5"
			/>
			<path
				d="M2.084,9.277A.5.5,0,0,0,2.5,9.5h19a.5.5,0,0,0,.416-.223l1-1.5A.5.5,0,0,0,22.5,7H1.5a.5.5,0,0,0-.416.777Z"
				fill={color}
			/>
			<path
				d="M1.5,6h21a.5.5,0,0,0,.215-.952l-10.5-5a.506.506,0,0,0-.43,0l-10.5,5A.5.5,0,0,0,1.5,6Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default HistoryIconBuilder;
