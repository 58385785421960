import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import Measure from 'react-measure';



export const ICON_ALIGNMENT_TOP = 'top';
export const ICON_ALIGNMENT_CENTER = 'center';
const ICON_VISIBILITY_BREAKPOINT = 340;
export const HIGHLIGHT_INDENTATION_NONE = 'none';
export const HIGHLIGHT_INDENTATION_LEFT = 'left';
export const HIGHLIGHT_INDENTATION_RIGHT = 'right';

class IssueCategoryCardLayout extends Component {

	constructor(props, context) {
		super(props, context);

		this._refMeasure = React.createRef();

		this._handleClick = this._handleClick.bind(this);
		this._handleComponentResize = this._handleComponentResize.bind(this);
		this._handleMouseEnter = this._handleMouseEnter.bind(this);
		this._handleMouseLeave = this._handleMouseLeave.bind(this);

		this.state = {
			isIconVisible: false,
		};
	}



	_handleClick(e) {
		const {
			onClickCallback,
		} = this.props;

		if (!onClickCallback) {
			return false;
		}

		e.preventDefault();

		onClickCallback();
	}



	_handleMouseEnter() {
		const {
			onMouseEnterCallback,
		} = this.props;

		if (!onMouseEnterCallback) {
			return false;
		}

		onMouseEnterCallback();
	}



	_handleMouseLeave() {
		const {
			onMouseLeaveCallback,
		} = this.props;

		if (!onMouseLeaveCallback) {
			return false;
		}

		onMouseLeaveCallback();
	}



	_setIconVisibility(elementWidth) {
		this.setState({
			isIconVisible: elementWidth > ICON_VISIBILITY_BREAKPOINT,
		});
	}



	componentDidMount() {
		const elementWidth = this._refMeasure.current.offsetWidth;
		this._setIconVisibility(elementWidth);
	}



	_handleComponentResize(contentRect) {
		this._setIconVisibility(contentRect.bounds.width);
	}



	_renderIcon() {
		const {
			icon,
			iconAlignment,
			isLoading,
		} = this.props;

		const {
			isIconVisible,
		} = this.state;

		const iconClasses = classNames({
			'issue-category-card__icon': true,
			'issue-category-card__icon--is-visible': isIconVisible,
			'issue-category-card__icon--center-aligned': iconAlignment === ICON_ALIGNMENT_CENTER,
		});

		return (
			<span className={iconClasses}>
				{!isLoading && icon}
			</span>
		);
	}



	render() {
		const {
			highlightIndentation,
			children,
			isHighlighted,
			isLoading,
			onClickCallback,
		} = this.props;

		const componentClasses = classNames({
			'issue-category-card': true,
			'issue-category-card--is-clickable': onClickCallback,
			'issue-category-card--highlighted': isHighlighted,
			'issue-category-card--left-indented': isHighlighted && highlightIndentation === HIGHLIGHT_INDENTATION_LEFT,
			'issue-category-card--right-indented': isHighlighted && highlightIndentation === HIGHLIGHT_INDENTATION_RIGHT,
			'issue-category-card--skeleton': isLoading,
		});

		return (
			<Measure
				bounds={true}
				innerRef={this._refMeasure}
				onResize={this._handleComponentResize}
			>
				{({ measureRef }) => (
					<section
						className={componentClasses}
						onClick={this._handleClick}
						onMouseEnter={this._handleMouseEnter}
						onMouseLeave={this._handleMouseLeave}
						ref={measureRef}
					>
						<div className="issue-category-card__card">
							{this._renderIcon()}
							<div className="issue-category-card__content">
								{children}
							</div>
						</div>
					</section>
				)}
			</Measure>
		);
	}

}

IssueCategoryCardLayout.defaultProps = {
	highlightIndentation: HIGHLIGHT_INDENTATION_NONE,
	iconAlignment: ICON_ALIGNMENT_TOP,
	isHighlighted: false,
	isLoading: false,
};

IssueCategoryCardLayout.propTypes = {
	/** Special card indentation when highlighted */
	highlightIndentation: PropTypes.oneOf([
		HIGHLIGHT_INDENTATION_NONE,
		HIGHLIGHT_INDENTATION_LEFT,
		HIGHLIGHT_INDENTATION_RIGHT,
	]),
	/** Icon representing issue category */
	icon: PropTypes.node,
	/** Vertical alignment of icon */
	iconAlignment: PropTypes.oneOf([
		ICON_ALIGNMENT_TOP,
		ICON_ALIGNMENT_CENTER,
	]).isRequired,
	/** Enable highlighted look of card */
	isHighlighted: PropTypes.bool,
	/** Enable special look of card in loading state */
	isLoading: PropTypes.bool,
	/** Handle clicking on issue card */
	onClickCallback: PropTypes.func,
	/** Handle entering mouse cursor */
	onMouseEnterCallback: PropTypes.func,
	/** Handle leaving mouse cursor */
	onMouseLeaveCallback: PropTypes.func,
};



export default IssueCategoryCardLayout;
