import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const BlogIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M22,0H5.5a2,2,0,0,0-2,2V18.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0ZM14.5,15a.5.5,0,0,1-.5.5H9a.5.5,0,0,1-.5-.5V14a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5Zm5-4.5a.5.5,0,0,1-.5.5H9a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,9,9H19a.5.5,0,0,1,.5.5Zm0-4.5a.5.5,0,0,1-.5.5H9A.5.5,0,0,1,8.5,6V5A.5.5,0,0,1,9,4.5H19a.5.5,0,0,1,.5.5Z"
				fill={color}
			/>
			<path
				d="M19.5,22H2.5a.5.5,0,0,1-.5-.5V4.5a1,1,0,0,0-2,0V22a2,2,0,0,0,2,2H19.5a1,1,0,0,0,0-2Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default BlogIconBuilder;
