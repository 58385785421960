import React from 'react';

import Grid, {
	GridGapsSize,
} from '~/components/patterns/structures/Grid';
import Measurer from '~/utilities/Measurer';
import ReviewBadge, {
	ReviewBadgeService,
} from '~/components/patterns/reviews/ReviewBadge';



type Props = {
	reviewsCount?: number,
};

const ReviewsOverview: React.FC<Props> = (props) => {
	const {
		reviewsCount = 1,
	} = props;

	const reviews = [
		<ReviewBadge
			key={ReviewBadgeService.G2}
			ratingMaxScore={5.0}
			ratingScore={4.8}
			service={ReviewBadgeService.G2}
		/>,
		<ReviewBadge
			key={ReviewBadgeService.Capterra}
			ratingMaxScore={5.0}
			ratingScore={4.9}
			service={ReviewBadgeService.Capterra}
		/>,
		<ReviewBadge
			key={ReviewBadgeService.TrustRadius}
			ratingMaxScore={10}
			ratingScore={9}
			service={ReviewBadgeService.TrustRadius}
		/>,
		<ReviewBadge
			key={ReviewBadgeService.GetApp}
			ratingMaxScore={5}
			ratingScore={4.9}
			service={ReviewBadgeService.GetApp}
		/>,
	];

	return (
		<Measurer>
			{({ containerWidth }) => {
				let columnsCount = Math.trunc(containerWidth / 100);

				if (reviewsCount < columnsCount) {
					columnsCount = reviewsCount;
				}

				return (
					<Grid
						columnsCount={columnsCount}
						gapsSize={GridGapsSize.Small}
					>
						{reviews.slice(0, reviewsCount)}
					</Grid>
				);
			}}
		</Measurer>
	);
};



export default ReviewsOverview;
