import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import EnterSudoModeForm from '~/components/app/EnterSudoModeForm';
import Form from '~/components/atoms/forms/basis/Form';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';
import TwoFactorAuthenticationCodeField, {
	validateTwoFactorAuthenticationCode,
} from '~/components/app/TwoFactorAuthenticationCodeField';

import {
	useRemoveTwoFactorAuthenticationMutation,
} from './DisableTwoFactorAuthenticationModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useSudoModeStatus from '~/hooks/useSudoModeStatus';



const messages = defineMessages({
	description: {
		id: 'ui.twoFactorAuthenticationDisabling.description',
	},
	havingTrouble: {
		id: 'ui.twoFactorAuthenticationDisabling.havingTrouble',
	},
	submitButton: {
		id: 'ui.twoFactorAuthenticationDisabling.submit',
	},
	title: {
		id: 'ui.twoFactorAuthenticationDisabling.title',
	},
});

const validations = {
	validateTwoFactorAuthenticationCode,
};



const DisableTwoFactorAuthenticationModal: React.FC = () => {
	const multiStepModalRef = React.useRef<MultiStepModalRef | null>(null);

	const classicFormBehavior = useClassicFormBehavior();
	const currentUserId = useCurrentUserId();
	const sudoModeStatus = useSudoModeStatus(GraphQL.MethodToEnterSudoMode.TwoFactorAuthentication);

	const [removeTwoFactorAuthentication] = useRemoveTwoFactorAuthenticationMutation();

	const handleSubmit = React.useCallback(
		async (values, { createError }) => {
			if (currentUserId === null) {
				return;
			}

			try {
				await removeTwoFactorAuthentication({
					variables: {
						code: values.twoFactorAuthenticationCode,
						legacyUserId: currentUserId,
					},
				});

				classicFormBehavior.finish();
			} catch (error) {
				if (error.getCode() === 'requiredSudoMode') {
					multiStepModalRef.current?.goToStep('enterSudoMode');
				} else {
					throw createError(error.getCode(), {
						value: values.twoFactorAuthenticationCode,
					});
				}
			}
		},
		[
			classicFormBehavior,
			currentUserId,
			removeTwoFactorAuthentication,
		],
	);

	return (
		<MultiStepModal
			initialStep={(
				sudoModeStatus.suggestedMethodToEnter === GraphQL.MethodToEnterSudoMode.Password ? 0 : 1
			)}
			key={sudoModeStatus.isLoading ? 'loading' : 'ready'}
			minHeight={0}
			ref={multiStepModalRef}
		>
			<MultiStepModalStep
				name="enterSudoMode"
				title={(
					<FormattedMessage {...messages.title} />
				)}
			>
				{({ isActive }) => isActive && (
					<EnterSudoModeForm
						description="Before disabling the two-factor authentication, please enter your password."
						disallowedMethod={GraphQL.MethodToEnterSudoMode.TwoFactorAuthentication}
					/>
				)}
			</MultiStepModalStep>
			<MultiStepModalStep
				name="disableStep"
				title={(
					<FormattedMessage {...messages.title} />
				)}
			>
				{({ isActive }) => (isActive ? (
					<Form
						onSuccess={handleSubmit}
						validations={validations}
					>
						<ModalTextSection>
							<RichText>
								<Copy {...messages.description} />
							</RichText>
						</ModalTextSection>

						<TwoFactorAuthenticationCodeField />

						<ModalTextSection>
							<RichText>
								<Copy
									{...messages.havingTrouble}
									values={{
										linkArticle: linkExternal('https://www.contentkingapp.com/support/two-factor-authentication/'),
									}}
								/>
							</RichText>
						</ModalTextSection>

						<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
							<CancelButton />

							<SubmitButton>
								<FormattedMessage {...messages.submitButton} />
							</SubmitButton>
						</ModalButtonsLayout>
					</Form>
				) : null)}
			</MultiStepModalStep>
		</MultiStepModal>
	);
};



export default DisableTwoFactorAuthenticationModal;
