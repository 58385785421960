/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLighthouseThresholdsMutationVariables = GraphQL.Exact<{
  thresholds: GraphQL.Scalars['Array']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateLighthouseThresholdsMutation = { readonly __typename?: 'Mutation', readonly UpdateLighthouseThresholds: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly lighthouseThresholds: { readonly __typename?: 'WebsiteLighthouseThresholds', readonly cls: ReadonlyArray<number>, readonly fcp: ReadonlyArray<number>, readonly lcp: ReadonlyArray<number>, readonly performance: ReadonlyArray<number>, readonly si: ReadonlyArray<number>, readonly tbt: ReadonlyArray<number>, readonly tti: ReadonlyArray<number> } } | null } } };


export const UpdateLighthouseThresholdsDocument = gql`
    mutation UpdateLighthouseThresholds($thresholds: Array!, $websiteId: WebsiteId!) {
  UpdateLighthouseThresholds(
    lighthouseThresholds: $thresholds
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        lighthouseThresholds {
          cls
          fcp
          lcp
          performance
          si
          tbt
          tti
        }
      }
    }
  }
}
    `;
export type UpdateLighthouseThresholdsMutationFn = Apollo.MutationFunction<UpdateLighthouseThresholdsMutation, UpdateLighthouseThresholdsMutationVariables>;

/**
 * __useUpdateLighthouseThresholdsMutation__
 *
 * To run a mutation, you first call `useUpdateLighthouseThresholdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLighthouseThresholdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLighthouseThresholdsMutation, { data, loading, error }] = useUpdateLighthouseThresholdsMutation({
 *   variables: {
 *      thresholds: // value for 'thresholds'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateLighthouseThresholdsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLighthouseThresholdsMutation, UpdateLighthouseThresholdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLighthouseThresholdsMutation, UpdateLighthouseThresholdsMutationVariables>(UpdateLighthouseThresholdsDocument, options);
      }
export type UpdateLighthouseThresholdsMutationHookResult = ReturnType<typeof useUpdateLighthouseThresholdsMutation>;
export type UpdateLighthouseThresholdsMutationResult = Apollo.MutationResult<UpdateLighthouseThresholdsMutation>;
export type UpdateLighthouseThresholdsMutationOptions = Apollo.BaseMutationOptions<UpdateLighthouseThresholdsMutation, UpdateLighthouseThresholdsMutationVariables>;