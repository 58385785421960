import React from 'react';

import type CK from '~/types/contentking';

import DestructConfirmationModal from '~/components/app/DestructConfirmationModal';

import {
	useDisconnectConductorOrganizationMutation,
} from './AdminDisconnectConductorOrganizationModal.gql';

import useAccountName from '~/hooks/useAccountName';



type Props = {
	accountId: CK.AccountId | null,
	conductorOrganizationId: string,
};

const AdminDisconnectConductorOrganizationModal: React.FC<Props> = (props) => {
	const {
		accountId,
		conductorOrganizationId,
	} = props;

	const accountName = useAccountName(accountId);

	const [disconnectConductorOrganization] = useDisconnectConductorOrganizationMutation();

	const handleSubmit = async () => {
		if (accountId === null) {
			return;
		}

		await disconnectConductorOrganization({
			variables: {
				accountId,
			},
		});
	};

	return (
		<DestructConfirmationModal
			confirmButton="Disconnect Conductor organization"
			confirmationPhrase={conductorOrganizationId}
			description={(
				<p>
					Disconnect this ContentKing account <strong>{accountName} ({accountId})</strong> from the
					connected Conductor organization <em>{conductorOrganizationId}</em>.
				</p>
			)}
			onConfirmation={handleSubmit}
			titleAction="Disconnect"
			titleObject="Conductor organization"
		/>
	);
};



export default AdminDisconnectConductorOrganizationModal;
