import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Form from '~/components/atoms/forms/basis/Form';
import Hint, {
	HintAttachment,
	HintPopupSkin,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import HintPopupLayout from '~/components/patterns/hints/hint/HintPopupLayout';
import IntercomActivator from '~/components/logic/IntercomActivator';
import List from '~/components/patterns/lists/List';
import PlanName from '~/components/names/PlanName';
import PremiumAnnouncement from '~/components/patterns/messages/embedded/PremiumAnnouncement';
import Ribbon, {
	RibbonStyle,
	RibbonTooltipVisibility,
} from '~/components/patterns/tags/Ribbon';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton, {
	SIZE_SMALL as SUBMIT_BUTTON_SIZE_SMALL,
	STYLE_ACTION as SUBMIT_BUTTON_STYLE_ACTION,
} from '~/components/atoms/forms/components/SubmitButton';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import useAccountId from '~/hooks/useAccountId';
import useAccountPremiumTrialOffer from '~/hooks/useAccountPremiumTrialOffer';
import useAccountType from '~/hooks/useAccountType';
import useActivatePremiumTrial from '~/hooks/useActivatePremiumTrial';
import useCalculatePrice from '~/hooks/useCalculatePrice';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';

import {
	applyBillingCycleToCost,
} from '~/model/pricing/billingCycle';

import {
	LIST_OF_ALL_PLANS,
} from '~/model/universal';



const messages = defineMessages({
	featureRequiresPlan: {
		id: 'ui.upsell.tooltip.firstParagraph',
	},
	legacyPlanButton: {
		id: 'ui.upsell.legacy.button',
	},
	legacyPlanDescription: {
		id: 'ui.upsell.legacy.description',
	},
	premiumTrialAsManager: {
		id: 'ui.upsell.trial.tooltip.secondParagraph.manager',
	},
	premiumTrialAsMember: {
		id: 'ui.upsell.trial.tooltip.secondParagraph.member',
	},
	premiumTrialButton: {
		id: 'ui.upsell.trial.button',
	},
	premiumTrialEntry: {
		id: 'ui.upsell.trial.ribbon',
	},
	unavailablePlanButton: {
		id: 'ui.upsell.unavailablePlan.button',
	},
	unavailablePlanDescription: {
		id: 'ui.upsell.unavailablePlan.description',
	},
	unavailablePlanEnterpiseDescription: {
		id: 'ui.upsell.unavailablePlan.enterpriseDescription',
	},
	upgradeAsManager: {
		id: 'ui.upsell.upgrade.tooltip.secondParagraph.manager',
	},
	upgradeAsMember: {
		id: 'ui.upsell.upgrade.tooltip.secondParagraph.member',
	},
	upgradeButton: {
		id: 'ui.upsell.upgrade.button',
	},
	upgradeEntry: {
		id: 'ui.upsell.upgrade.ribbon',
	},
});



function renderPlanName(plan) {
	if (LIST_OF_ALL_PLANS.v3.includes(plan)) {
		return (
			<PlanName plan={plan} />
		);
	}

	return null;
}



export enum PremiumFeatureSituationStyle {
	Box = 'STYLE_BOX',
	Ribbon = 'STYLE_RIBBON',
	Tooltip = 'STYLE_TOOLTIP',
}

type Props = {
	children: (childProps: {
		isFeatureAttainable: boolean,
		isFeatureEnabled: boolean,
		premiumAnnotation: React.ReactElement | null,
	}) => React.ReactElement | null,
	extraAnnotation?: React.ReactNode,
	featureName: GraphQL.AccountFeature,
	hideIfUnattainable?: boolean,
	showUnavailablePlan?: boolean,
	style: PremiumFeatureSituationStyle,
};

const PremiumFeatureSituation: React.FC<Props> = (props) => {
	const {
		children,
		extraAnnotation,
		featureName,
		hideIfUnattainable,
		showUnavailablePlan = false,
		style,
	} = props;

	const accountId = useAccountId();
	const accountType = useAccountType(accountId);
	const activatePremiumTrial = useActivatePremiumTrial(accountId);

	const {
		isFeatureAttainable,
		isFeatureEnabled,
		requiredPlan,
		requiredUniversalPlan,
		shouldBeOfferedIfAttainable,
	} = usePremiumFeatureSituation(featureName);

	const [isSubmitting, setIsSubmitting] = React.useState(false);

	const handleActivatePremiumTrial = React.useCallback(
		async () => {
			setIsSubmitting(true);

			await activatePremiumTrial();

			setIsSubmitting(false);
		},
		[
			activatePremiumTrial,
			setIsSubmitting,
		],
	);

	if (isFeatureEnabled) {
		return children({
			isFeatureAttainable,
			isFeatureEnabled,
			premiumAnnotation: null,
		});
	}

	if (!shouldBeOfferedIfAttainable) {
		return null;
	}

	if (requiredPlan === null) {
		if (accountType !== GraphQL.AccountType.Universal) {
			if (requiredUniversalPlan === null) {
				return null;
			}

			return children({
				isFeatureAttainable,
				isFeatureEnabled,
				premiumAnnotation: (
					<RequiresUniversalPlan
						accountType={accountType}
						extraAnnotation={extraAnnotation}
						isSubmitting={isSubmitting}
						requiredPlan={requiredUniversalPlan}
						style={style}
					/>
				),
			});
		} else if (hideIfUnattainable) {
			return null;
		} else if (showUnavailablePlan) {
			if (requiredUniversalPlan === null) {
				return null;
			}

			return children({
				isFeatureAttainable,
				isFeatureEnabled,
				premiumAnnotation: (
					<RequiresUniversalPlan
						accountType={accountType}
						extraAnnotation={extraAnnotation}
						isSubmitting={isSubmitting}
						requiredPlan={requiredUniversalPlan}
						style={style}
					/>
				),
			});
		}

		return children({
			isFeatureAttainable,
			isFeatureEnabled,
			premiumAnnotation: null,
		});
	}

	return children({
		isFeatureAttainable,
		isFeatureEnabled,
		premiumAnnotation: (
			<PremiumFeatureSituationUI
				accountId={accountId}
				extraAnnotation={extraAnnotation}
				handleActivation={handleActivatePremiumTrial}
				isSubmitting={isSubmitting}
				requiredPlan={requiredPlan}
				style={style}
			/>
		),
	});
};



const PremiumFeatureSituationUI = (props) => {
	const {
		accountId,
		children,
		extraAnnotation,
		handleActivation,
		isSubmitting,
		requiredPlan,
		style,
	} = props;

	const accountPremiumTrialOffer = useAccountPremiumTrialOffer(accountId);

	const calculatePrice = useCalculatePrice(accountId);

	function renderProposition({ isAllowed }) {
		let ctaElement;
		let label;
		let proposition;

		if (accountPremiumTrialOffer !== null) {
			ctaElement = isAllowed && (
				<Form
					onSuccess={handleActivation}
				>
					{({ isSubmitting }) => (
						<SubmitButton
							progress={isSubmitting}
							size={SUBMIT_BUTTON_SIZE_SMALL}
							style={SUBMIT_BUTTON_STYLE_ACTION}
							uppercase={true}
						>
							<FormattedMessage {...messages.premiumTrialButton} />
						</SubmitButton>
					)}
				</Form>
			);

			label = (
				<FormattedMessage {...messages.premiumTrialEntry} />
			);

			proposition = isAllowed
				? (
					<FormattedMessage
						{...messages.premiumTrialAsManager}
						values={{
							days: accountPremiumTrialOffer.durationInDays,
						}}
					/>
				)
				: (
					<FormattedMessage
						{...messages.premiumTrialAsMember}
						values={{
							days: accountPremiumTrialOffer.durationInDays,
						}}
					/>
				);
		} else {
			ctaElement = isAllowed && (
				<Button
					linkRouteName="account.settings.subscription"
					linkRouteParams={{
						accountId: accountId + '',
						plan: requiredPlan,
					}}
					size={ButtonSize.Small}
					style={ButtonStyle.Action}
					uppercase={true}
				>
					<FormattedMessage {...messages.upgradeButton} />
				</Button>
			);

			label = (
				<FormattedMessage {...messages.upgradeEntry} />
			);

			if (isAllowed) {
				const currentPrice = calculatePrice();
				const upgradePrice = calculatePrice({
					plan: requiredPlan,
				});

				if (currentPrice && upgradePrice) {
					const extraCost = applyBillingCycleToCost({
						baseBillingCycle: upgradePrice.billingCycle,
						cost: upgradePrice.total - currentPrice.total,
						newBillingCycle: GraphQL.Term.Monthly,
					});

					const isExtraCostWithDecimals = (extraCost % 1) > 0;

					proposition = (
						<FormattedMessage
							{...messages.upgradeAsManager}
							values={{
								text__price: (
									<FormattedNumber
										currency={upgradePrice.currency}
										maximumFractionDigits={isExtraCostWithDecimals ? 2 : 0}
										minimumFractionDigits={isExtraCostWithDecimals ? 2 : 0}
										style="currency"
										value={extraCost}
									/>
								),
								text__pricingPlan: renderPlanName(requiredPlan),
							}}
						/>
					);
				} else {
					proposition = null;
				}
			} else {
				proposition = (
					<FormattedMessage {...messages.upgradeAsMember} />
				);
			}
		}

		return {
			ctaElement,
			label,
			proposition,
		};
	}

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageDetails}
		>
			{({ isAllowed }) => {
				const {
					ctaElement,
					label,
					proposition,
				} = renderProposition({
					isAllowed,
				});

				const requiresPlanMessage = (
					<FormattedMessage
						{...messages.featureRequiresPlan}
						values={{
							text__pricingPlan: renderPlanName(requiredPlan),
						}}
					/>
				);

				if (style === PremiumFeatureSituationStyle.Box) {
					return (
						<PremiumAnnouncement
							ctaElement={ctaElement}
							title={requiresPlanMessage}
						>
							<List>
								{extraAnnotation}
								{proposition}
							</List>
						</PremiumAnnouncement>
					);
				} else if (style === PremiumFeatureSituationStyle.Ribbon) {
					return (
						<Ribbon
							style={RibbonStyle.Premium}
							tooltipCtaElement={ctaElement}
							tooltipMessage={(
								<List>
									{extraAnnotation}
									{requiresPlanMessage}
									{proposition}
								</List>
							)}
							tooltipVisibility={isSubmitting ? RibbonTooltipVisibility.Always : RibbonTooltipVisibility.Auto}
						>
							{label}
						</Ribbon>
					);
				} else if (style === PremiumFeatureSituationStyle.Tooltip) {
					return (
						<Hint
							attachment={HintAttachment.Right}
							blurDelay={200}
							popup={(
								<List>
									{extraAnnotation}
									{requiresPlanMessage}
									{proposition}
								</List>
							)}
							popupLayout={(children) => (
								<HintPopupLayout
									ctaElement={ctaElement}
								>
									{children}
								</HintPopupLayout>
							)}
							popupMaxWidth={280}
							popupSkin={HintPopupSkin.Premium}
							popupVisibility={isSubmitting ? HintPopupVisibility.Always : HintPopupVisibility.OnHover}
						>
							{children}
						</Hint>
					);
				}

				return null;
			}}
		</WhenAccountActionAllowed>
	);
};



const RequiresUniversalPlan = (props) => {
	const {
		accountType,
		extraAnnotation,
		isSubmitting,
		requiredPlan,
		style,
	} = props;

	const accountIsLegacy = accountType !== GraphQL.AccountType.Universal;
	const requiredPlanIsEnterprise = requiredPlan === GraphQL.AccountPlan.Enterprise;

	const requiresUniversalPlanMessage = (
		<FormattedMessage
			{...messages.featureRequiresPlan}
			values={{
				text__pricingPlan: <PlanName plan={requiredPlan} />,
			}}
		/>
	);

	const requiresUniversalPlanButton = (
		<IntercomActivator>
			<Button
				size={ButtonSize.Small}
				style={ButtonStyle.Action}
				uppercase={true}
			>
				{accountIsLegacy ? (
					<FormattedMessage {...messages.legacyPlanButton} />
				) : (
					<FormattedMessage {...messages.unavailablePlanButton} />
				)}
			</Button>
		</IntercomActivator>
	);

	const requiresUniversalPlanDescription = accountIsLegacy ? (
		<FormattedMessage {...messages.legacyPlanDescription} />
	) : requiredPlanIsEnterprise ? (
		<FormattedMessage {...messages.unavailablePlanEnterpiseDescription} />
	) : (
		<FormattedMessage {...messages.unavailablePlanDescription} />
	);

	if (style === PremiumFeatureSituationStyle.Box) {
		return (
			<PremiumAnnouncement
				ctaElement={requiresUniversalPlanButton}
				title={requiresUniversalPlanMessage}
			>
				<p>{extraAnnotation}</p>
				<p>{requiresUniversalPlanDescription}</p>
			</PremiumAnnouncement>
		);
	} else if (style === PremiumFeatureSituationStyle.Ribbon) {
		return (
			<Ribbon
				style={RibbonStyle.Premium}
				tooltipCtaElement={requiresUniversalPlanButton}
				tooltipMessage={(
					<>
						<p>{extraAnnotation}</p>
						<p>{requiresUniversalPlanMessage}</p>
						<p>{requiresUniversalPlanDescription}</p>
					</>
				)}
				tooltipVisibility={isSubmitting ? RibbonTooltipVisibility.Always : RibbonTooltipVisibility.Auto}
			>
				{accountIsLegacy ? (
					<FormattedMessage {...messages.legacyPlanButton} />
				) : (
					<FormattedMessage {...messages.unavailablePlanButton} />
				)}
			</Ribbon>
		);
	} else if (style === PremiumFeatureSituationStyle.Tooltip) {
		return (
			<Hint
				attachment={HintAttachment.Right}
				blurDelay={200}
				popup={(
					<RichText>
						<p>{extraAnnotation}</p>
						<p>{requiresUniversalPlanMessage}</p>
						<p>{requiresUniversalPlanDescription}</p>
					</RichText>
				)}
				popupLayout={(children) => (
					<HintPopupLayout
						ctaElement={requiresUniversalPlanButton}
					>
						{children}
					</HintPopupLayout>
				)}
				popupMaxWidth={280}
				popupSkin={HintPopupSkin.Premium}
				popupVisibility={isSubmitting ? HintPopupVisibility.Always : HintPopupVisibility.OnHover}
			>
				{false}
			</Hint>
		);
	}

	return null;
};



export default PremiumFeatureSituation;
