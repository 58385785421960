import loadScript from '~/utilities/loadScript';



let reference;

export async function getRecurly(callback) {
	if (reference) {
		callback(reference);
	}

	try {
		await loadScript('https://js.recurly.com/v4/recurly.js');

		reference = window.recurly;

		callback(reference);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error);
	}
}
