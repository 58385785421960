import Immutable from 'immutable';
import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import CK from '~/types/contentking';

import useBuildTrackedChangesFilterDefinition from '~/hooks/useBuildTrackedChangesFilterDefinition';
import useEnabledWebsiteColumnsList from '~/hooks/useEnabledWebsiteColumnsList';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	storeHistoricalChanges,
} from '~/actions/historicalChanges';

import {
	transformBooleanOrNAFilterForApi,
} from '~/model/filter';

import {
	CHANGE_TYPES,
	DEFAULT_FILTER,
	formatDateForBackend,
	formatEndDateForBackend,
	loadHistoricalChangesViaApi,
	removeUnknownColumns,
} from '~/model/historicalChanges';

import {
	trimHostname,
} from '~/model/utils';

import {
	peekedColumnsSelector,
} from '~/state/changeTrackingPeekedColumns/selectors';

import {
	dateIntervalSelector,
	sortBySelector,
} from '~/state/historicalChanges/selectors';



function getFilter(filter) {
	let isInvalid = false;

	filter = transformBooleanOrNAFilterForApi(filter);

	filter = filter.map((value, key) => {
		if (
			key === CK.PagesCommonColumn.IsSecured
			|| key === CK.PagesCommonColumn.IsInSitemap
			|| key === CK.PagesCommonColumn.IsIndexable
			|| key === CK.PagesCommonColumn.IsLinked
			|| key === CK.PagesCommonColumn.IsDisallowedInRobotsTxt
		) {
			if (value.size === 2) {
				return null;
			} else if (value.size === 1) {
				return value.get(0);
			} else if (value.size === 0) {
				isInvalid = true;

				return null;
			}
		}

		if (
			key === CK.PagesCommonColumn.CanonicalType
			|| key === CK.PagesCommonColumn.IsIndexableDueToMetaRobots
			|| key === CK.PagesCommonColumn.IsIndexableDueToXRobotsTag
		) {
			if (value.size === 0) {
				isInvalid = true;

				return null;
			}

			if (
				key === CK.PagesCommonColumn.CanonicalType
				&& value.size === DEFAULT_FILTER.get(CK.PagesCommonColumn.CanonicalType).size
			) {
				return null;
			}

			value = value.join(',');
		}

		if (key === 'changes_in') {
			if (value.size === 0 && filter.get(CK.PagesCommonColumn.ChangeType).size === 0) {
				isInvalid = true;

				return null;
			}

			value = value.join(',');
		}

		if (key === CK.PagesCommonColumn.ChangeType) {
			if (value.size === CHANGE_TYPES.length) {
				return null;
			}

			value = value.join(',');
		}

		if (key === CK.PagesCommonColumn.Type) {
			if (value.size === 0) {
				isInvalid = true;

				return null;
			}

			value = value.join(',');
		}

		if (key === CK.PagesCommonColumn.Segments) {
			if (value.get('included_in').size > 0 || value.get('not_included_in').size > 0) {
				let formattedValue = '';

				if (value.get('included_in').size > 0) {
					formattedValue += 'included_in:' + value.get('included_in').join(',') + ';';
				}

				if (value.get('not_included_in').size > 0) {
					formattedValue += 'not_included_in:' + value.get('not_included_in').join(',') + ';';
				}

				formattedValue += 'operator:' + value.get('operator');

				value = formattedValue;
			} else {
				return null;
			}
		}

		return value;
	}).filter((value) => {
		return value !== null;
	});

	[
		CK.PagesCommonColumn.AnalyticsServices,
		CK.PagesCommonColumn.TagManagers,
		CK.PagesCommonColumn.VisualAnalyticsServices,
	].forEach((field) => {
		if (filter.has(field)) {
			filter = filter.set(field + '_legacy', filter.get(field));
			filter = filter.remove(field);
		}
	});

	return {
		criteria: filter.toJS(),
		isInvalid,
	};
}



function useLoadTrackedChangesOnPages() {
	const buildTrackedChangesFilterDefinition = useBuildTrackedChangesFilterDefinition();
	const dateInterval = useSelector(dateIntervalSelector);
	const dispatch = useDispatch();
	const enabledWebsiteColumnsList = useEnabledWebsiteColumnsList();
	const peekedColumns = useSelector(peekedColumnsSelector);
	const sortBy = useSelector(sortBySelector);
	const websiteId = useWebsiteId();

	return React.useCallback(
		(offset = 0, dropOldFiltered = false) => {
			const startDate = dateInterval.get('startDate');
			const endDate = dateInterval.get('endDate');

			if (startDate === null || endDate === null) {
				return Promise.resolve();
			}

			let limit = 100;

			if (offset > 100) {
				offset -= 100;
				limit = 200;
			}

			const fields = enabledWebsiteColumnsList
				.concat(peekedColumns)
				.toList()
				.push(CK.PagesCommonColumn.ChangeType)
				.toArray();

			const params: Record<string, any> = {
				offset: Math.max(offset - 20, 0),
				limit,
			};

			if (sortBy !== undefined && sortBy !== null) {
				params.sort = sortBy.get('key');
				params.direction = sortBy.get('direction') ? 'asc' : 'desc';
			}

			const {
				criteria,
				isInvalid,
			} = getFilter(
				buildTrackedChangesFilterDefinition(),
			);

			if (isInvalid) {
				dispatch(
					storeHistoricalChanges(
						websiteId,
						Immutable.fromJS([]),
						Immutable.fromJS([]),
						0,
						params.offset,
						params.limit,
						Immutable.fromJS([]),
					),
				);

				return Promise.resolve();
			}

			for (const key in criteria) {
				if (criteria.hasOwnProperty(key)) {
					params['filter[' + key + ']'] = criteria[key];
				}
			}

			if (fields.length > 0) {
				params.fields = fields;
			}

			return loadHistoricalChangesViaApi({
				websiteId,
				period: {
					type: 'interval',
					start_date: formatDateForBackend(startDate),
					end_date: formatEndDateForBackend(startDate, endDate),
				},
				params,
			}).then((data) => {
				for (const row of data.pages) {
					row.displayUrl = trimHostname(row.url);
				}

				const changesIn = Immutable
					.fromJS(data.changes_in)
					.filter(removeUnknownColumns());

				dispatch(
					storeHistoricalChanges(
						websiteId,
						data.order,
						Immutable.fromJS(data.pages),
						data.count,
						params.offset,
						params.limit,
						changesIn,
						dropOldFiltered,
					),
				);
			});
		},
		[
			buildTrackedChangesFilterDefinition,
			dateInterval,
			dispatch,
			enabledWebsiteColumnsList,
			peekedColumns,
			sortBy,
			websiteId,
		],
	);
}



export default useLoadTrackedChangesOnPages;
