import React from 'react';

import ScreenMessage from '~/components/patterns/messages/embedded/ScreenMessage';
import Spinner, {
	SpinnerSize,
} from '~/components/patterns/loaders/Spinner';



type Props = {
	children?: React.ReactNode,
	/** Call-to-action element like button or link */
	ctaElement?: React.ReactNode,
	/** Allow to hide loader (spinner) */
	showLoader?: boolean,
	/** Title */
	title: React.ReactNode,
};



const ProcessingMessage: React.FC<Props> = (props) => {
	const {
		children,
		ctaElement,
		showLoader = true,
		title,
	} = props;

	return (
		<ScreenMessage
			ctaElement={ctaElement}
			title={title}
			titleIcon={showLoader && (
				<Spinner size={SpinnerSize.Large} />
			)}
		>
			{children}
		</ScreenMessage>
	);
};



export default ProcessingMessage;
