import type CK from '~/types/contentking';

import useBatchContext from '~/hooks/useBatchContext';



function useBatchContextPageDetail(
	legacyUrlId: number | null,
	websiteId: CK.WebsiteId | null,
) {
	return useBatchContext(
		`pageDetail/${websiteId ?? ''}/${legacyUrlId ?? 0}`,
		999,
	);
}



export default useBatchContextPageDetail;
