/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAdminContextActionsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountAdminContextActionsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly conductorOrganizationId: string | null, readonly grafanaLink: string | null, readonly id: CK.AccountId, readonly intercomCompanyLink: string | null, readonly isInternalRestricted: boolean, readonly recurlyLink: string | null, readonly scrapbookLink: string | null, readonly stripeCustomerUrl: string | null } | null };


export const AccountAdminContextActionsDocument = gql`
    query AccountAdminContextActions($accountId: AccountId!) {
  account(id: $accountId) {
    conductorOrganizationId
    grafanaLink
    id
    intercomCompanyLink
    isInternalRestricted
    recurlyLink
    scrapbookLink
    stripeCustomerUrl
  }
}
    `;

/**
 * __useAccountAdminContextActionsQuery__
 *
 * To run a query within a React component, call `useAccountAdminContextActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAdminContextActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAdminContextActionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountAdminContextActionsQuery(baseOptions: Apollo.QueryHookOptions<AccountAdminContextActionsQuery, AccountAdminContextActionsQueryVariables> & ({ variables: AccountAdminContextActionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountAdminContextActionsQuery, AccountAdminContextActionsQueryVariables>(AccountAdminContextActionsDocument, options);
      }
export function useAccountAdminContextActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountAdminContextActionsQuery, AccountAdminContextActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountAdminContextActionsQuery, AccountAdminContextActionsQueryVariables>(AccountAdminContextActionsDocument, options);
        }
export function useAccountAdminContextActionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountAdminContextActionsQuery, AccountAdminContextActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountAdminContextActionsQuery, AccountAdminContextActionsQueryVariables>(AccountAdminContextActionsDocument, options);
        }
export type AccountAdminContextActionsQueryHookResult = ReturnType<typeof useAccountAdminContextActionsQuery>;
export type AccountAdminContextActionsLazyQueryHookResult = ReturnType<typeof useAccountAdminContextActionsLazyQuery>;
export type AccountAdminContextActionsSuspenseQueryHookResult = ReturnType<typeof useAccountAdminContextActionsSuspenseQuery>;
export type AccountAdminContextActionsQueryResult = Apollo.QueryResult<AccountAdminContextActionsQuery, AccountAdminContextActionsQueryVariables>;