/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestAccessToClientMutationVariables = GraphQL.Exact<{
  agencyId: GraphQL.Scalars['AccountId']['input'];
  clientContactEmail: GraphQL.Scalars['String']['input'];
  level: GraphQL.AccountAccessConnectionLevel;
}>;


export type RequestAccessToClientMutation = { readonly __typename?: 'Mutation', readonly RequestAccessToClient: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly clientConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessClientConnection', readonly clientAccountId: CK.AccountId, readonly clientAccountName: string, readonly id: any, readonly inviterEmail: string | null, readonly isInitiatedByAgency: boolean, readonly isPending: boolean, readonly level: GraphQL.AccountAccessConnectionLevel }> } | null } } };


export const RequestAccessToClientDocument = gql`
    mutation RequestAccessToClient($agencyId: AccountId!, $clientContactEmail: String!, $level: AccountAccessConnectionLevel!) {
  RequestAccessToClient(
    agencyId: $agencyId
    clientContactEmail: $clientContactEmail
    level: $level
  ) {
    query {
      account(id: $agencyId) {
        clientConnections {
          clientAccountId
          clientAccountName
          id
          inviterEmail
          isInitiatedByAgency
          isPending
          level
        }
        id
      }
    }
  }
}
    `;
export type RequestAccessToClientMutationFn = Apollo.MutationFunction<RequestAccessToClientMutation, RequestAccessToClientMutationVariables>;

/**
 * __useRequestAccessToClientMutation__
 *
 * To run a mutation, you first call `useRequestAccessToClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccessToClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccessToClientMutation, { data, loading, error }] = useRequestAccessToClientMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      clientContactEmail: // value for 'clientContactEmail'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useRequestAccessToClientMutation(baseOptions?: Apollo.MutationHookOptions<RequestAccessToClientMutation, RequestAccessToClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAccessToClientMutation, RequestAccessToClientMutationVariables>(RequestAccessToClientDocument, options);
      }
export type RequestAccessToClientMutationHookResult = ReturnType<typeof useRequestAccessToClientMutation>;
export type RequestAccessToClientMutationResult = Apollo.MutationResult<RequestAccessToClientMutation>;
export type RequestAccessToClientMutationOptions = Apollo.BaseMutationOptions<RequestAccessToClientMutation, RequestAccessToClientMutationVariables>;