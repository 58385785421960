import type GraphQL from '~/types/graphql';

import {
	calculatePurchaseCost,
	calculatePurchaseCostDetails,
} from './costs';

import {
	listAllPossiblePageCapacities,
	listAllPossiblePagesAmounts,
	normalizePageCapacity,
	normalizePagesAmount,
} from './tariffs';



export class TailoredTariff {

	accountType: GraphQL.AccountType;
	currency: string;
	maximumPagesAmount: number;
	tariff: string;
	tariffs: any;

	constructor(
		accountType: GraphQL.AccountType,
		currency: string,
		maximumSignupPageBundle: number,
		tariff: string,
		tariffs: any,
	) {
		this.accountType = accountType;
		this.currency = currency;
		this.maximumPagesAmount = maximumSignupPageBundle;
		this.tariff = tariff;
		this.tariffs = tariffs;
	}



	calculatePurchaseCost({ billingCycle, currency, purchase }: Record<string, any>) {
		return calculatePurchaseCost({
			accountType: this.accountType,
			billingCycle,
			currency: currency ?? this.currency,
			purchase,
			tariff: this.tariff,
			tariffs: this.tariffs,
		});
	}



	calculatePurchaseCostDetails({ billingCycle, currency, discounts, purchases, taxRate }: Record<string, any>) {
		return calculatePurchaseCostDetails({
			accountType: this.accountType,
			billingCycle,
			currency: currency || this.currency,
			discounts,
			purchases,
			tariff: this.tariff,
			tariffs: this.tariffs,
			taxRate,
			taxType: null,
		});
	}



	normalizePageCapacity({ pageCapacity }) {
		return normalizePageCapacity({
			accountType: this.accountType,
			pageCapacity,
			tariff: this.tariff,
		});
	}



	normalizePagesAmount({ numberOfPages }) {
		return normalizePagesAmount({
			accountType: this.accountType,
			numberOfPages,
			tariff: this.tariff,
		});
	}



	listAllPossiblePageCapacities(maximumPageCapacity: number | null = null) {
		return listAllPossiblePageCapacities(
			this.accountType,
			this.tariff,
			maximumPageCapacity,
		);
	}



	listAllPossiblePagesAmounts() {
		return listAllPossiblePagesAmounts(
			this.accountType,
			this.tariff,
			this.maximumPagesAmount,
		);
	}

}
