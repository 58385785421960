import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import Separator from '~/components/patterns/values/Separator';



function createSeparator(SeparatorComponent, separator, idx) {
	SeparatorComponent = SeparatorComponent || React.Fragment;

	return (
		<SeparatorComponent key={'separator-' + idx}>
			{separator}
		</SeparatorComponent>
	);
}



class WithSeparator extends Component {

	render() {
		const {
			children,
			separator,
			separatorComponent,
		} = this.props;

		const childrenArray = React.Children.toArray(children);

		for (let i = 1; i < childrenArray.length; i += 2) {
			childrenArray.splice(i, 0, createSeparator(separatorComponent, separator, (i - 1) / 2));
		}

		return childrenArray;
	}

}

WithSeparator.defaultProps = {
	separatorComponent: Separator,
};

WithSeparator.propTypes = {
	separator: PropTypes.node.isRequired,
	separatorComponent: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.func,
	]),
};



export default WithSeparator;
