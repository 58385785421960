/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsWebsiteCrawledQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IsWebsiteCrawledQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageTotal: number | null } | null };


export const IsWebsiteCrawledDocument = gql`
    query IsWebsiteCrawled($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    pageTotal
  }
}
    `;

/**
 * __useIsWebsiteCrawledQuery__
 *
 * To run a query within a React component, call `useIsWebsiteCrawledQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsWebsiteCrawledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsWebsiteCrawledQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIsWebsiteCrawledQuery(baseOptions: Apollo.QueryHookOptions<IsWebsiteCrawledQuery, IsWebsiteCrawledQueryVariables> & ({ variables: IsWebsiteCrawledQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsWebsiteCrawledQuery, IsWebsiteCrawledQueryVariables>(IsWebsiteCrawledDocument, options);
      }
export function useIsWebsiteCrawledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsWebsiteCrawledQuery, IsWebsiteCrawledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsWebsiteCrawledQuery, IsWebsiteCrawledQueryVariables>(IsWebsiteCrawledDocument, options);
        }
export function useIsWebsiteCrawledSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsWebsiteCrawledQuery, IsWebsiteCrawledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsWebsiteCrawledQuery, IsWebsiteCrawledQueryVariables>(IsWebsiteCrawledDocument, options);
        }
export type IsWebsiteCrawledQueryHookResult = ReturnType<typeof useIsWebsiteCrawledQuery>;
export type IsWebsiteCrawledLazyQueryHookResult = ReturnType<typeof useIsWebsiteCrawledLazyQuery>;
export type IsWebsiteCrawledSuspenseQueryHookResult = ReturnType<typeof useIsWebsiteCrawledSuspenseQuery>;
export type IsWebsiteCrawledQueryResult = Apollo.QueryResult<IsWebsiteCrawledQuery, IsWebsiteCrawledQueryVariables>;