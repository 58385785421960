import React from 'react';



type Props = {
	children: React.ReactNode,
	ctaElement: React.ReactNode,
};

const FloatingTableMessage: React.FC<Props> = (props) => {
	const {
		children,
		ctaElement,
	} = props;

	return (
		<div className="floating-table-message">
			<div className="floating-table-message__text">
				{children}
			</div>
			<div className="floating-table-message__cta-element">
				{ctaElement}
			</div>
		</div>
	);
};



export default FloatingTableMessage;
