import React from 'react';



type Props = {
	fieldWidth?: React.CSSProperties['minWidth'],
	children: React.ReactNode,
	prefix?: React.ReactNode,
	/** Additional label suffix */
	suffix?: React.ReactNode,
};



const TableFieldLeveler: React.FC<Props> = (props) => {
	const {
		fieldWidth,
		children,
		prefix,
		suffix,
	} = props;

	return (
		<div className="table-field-leveler">
			{prefix !== undefined && (
				<div className="table-field-leveler__prefix">
					{prefix}
				</div>
			)}
			<div
				className="table-field-leveler__field"
				style={{
					maxWidth: fieldWidth,
					minWidth: fieldWidth,
				}}
			>
				{children}
			</div>
			{suffix !== undefined && (
				<div className="table-field-leveler__suffix">
					{suffix}
				</div>
			)}
		</div>
	);
};



export default TableFieldLeveler;
