import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';



const messages = defineMessages({
	resetFilter: {
		id: 'ui.general.clearFilters',
	},
});



type Props = {
	onClickCallback: () => void,
};

const ClearFiltersLink: React.FC<Props> = (props) => {
	const {
		onClickCallback,
	} = props;

	const handleClick = (e) => {
		e.preventDefault();

		onClickCallback();
	};

	return (
		<InternalLink
			onClickCallback={handleClick}
			style={InternalLinkStyle.Decent}
		>
			<FormattedMessage {...messages.resetFilter} />
		</InternalLink>
	);
};



export default ClearFiltersLink;
