import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import LinksTable from './LinksTable';

import {
	loadPageRelations,
} from '~/actions/pages/detail';

import {
	PageIssue,
} from '~/model/issuesNew';

import {
	STATE_LOADED,
} from '~/model/pages';

import {
	pageOutboundLinksSelector,
	pageOutgoingInternalLinksSelector,
} from '~/state/pages/currentPageSelectors';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	ignoredCases: ReadonlyArray<any>,
	legacyUrlId: number,
	linkIssueName:
		| PageIssue.LinksBroken
		| PageIssue.LinksRedirected
		| PageIssue.LinksToCanonicalized,
	tableWidth: number,
	websiteId: CK.WebsiteId,
};

const OutgoingLinksTable: React.FC<Props> = (props) => {
	const {
		ignoredCases,
		legacyUrlId,
		linkIssueName,
		tableWidth,
		websiteId,
	} = props;

	const dispatch = useDispatch();
	const outboundLinks = useSelector(pageOutboundLinksSelector);
	const outgoingInternalLinks = useSelector(pageOutgoingInternalLinksSelector);

	React.useEffect(
		() => {
			dispatch(loadPageRelations(websiteId, legacyUrlId, 'outgoing_internal_links', 0));
			dispatch(loadPageRelations(websiteId, legacyUrlId, 'outbound_links', 0));
		},
		[
			dispatch,
			legacyUrlId,
			websiteId,
		],
	);

	const allLinks = React.useMemo(
		() => {
			if (
				!outgoingInternalLinks
				|| outgoingInternalLinks.get('_status') !== STATE_LOADED
				|| !outboundLinks
				|| outboundLinks.get('_status') !== STATE_LOADED
			) {
				return null;
			}

			const outgoingInternalLinksSeq = outgoingInternalLinks.get('data').valueSeq();
			const outboundLinksSeq = outboundLinks.get('data').valueSeq();

			let allLinks = outgoingInternalLinksSeq;

			if (linkIssueName !== PageIssue.LinksRedirected) {
				allLinks = outgoingInternalLinksSeq.concat(outboundLinksSeq);
			}

			return allLinks.map((link) => {
				const ignoredCase = ignoredCases.find((ignoredLink) => ignoredLink.url === link.get('url'));

				if (ignoredCase) {
					link = link.set('ignoredCase', ignoredCase);
				}

				return link;
			}).filter(
				matchAndReturn(linkIssueName, {
					[PageIssue.LinksBroken]: (link) => (
						(link.get('ignoredCase') !== undefined)
						|| [400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417].includes(link.get('status_code'))
					),
					[PageIssue.LinksRedirected]: (link) => (
						(link.get('ignoredCase') !== undefined)
						|| [300, 301, 302, 303, 304, 305, 306, 307, 308].includes(link.get('status_code'))
					),
					[PageIssue.LinksToCanonicalized]: (link) => (
						(link.get('ignoredCase') !== undefined)
						|| (link.get('canonical') && (link.get('id') !== link.get('canonical').get('id')))
					),
				}),
			);
		},
		[
			ignoredCases,
			linkIssueName,
			outboundLinks,
			outgoingInternalLinks,
		],
	);

	return (
		<LinksTable
			linkIssueName={linkIssueName}
			links={allLinks}
			tableWidth={tableWidth}
			websiteId={websiteId}
		/>
	);
};



export default OutgoingLinksTable;
