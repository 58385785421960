import React from 'react';



type Props = {
	form: React.ReactNode,
	formWidth: number,
	message?: React.ReactNode,
	messageWidth: number,
	submitButton: React.ReactNode,
	submitButtonWidth: number,
};



const DatatableFooterBulkActionsLayout: React.FC<Props> = (props) => {
	const {
		form,
		formWidth,
		message,
		messageWidth,
		submitButton,
		submitButtonWidth,
	} = props;

	return (
		<div className="datatable-footer-bulk-actions-layout">
			<div
				className="datatable-footer-bulk-actions-layout__message"
				style={{
					flexBasis: messageWidth + 'px',
				}}
			>
				{message}
			</div>
			<div
				className="datatable-footer-bulk-actions-layout__control-elements"
				style={{
					flexBasis: (formWidth + submitButtonWidth) + 'px',
				}}
			>
				<div
					className="datatable-footer-bulk-actions-layout__form"
					style={{
						flexBasis: formWidth + 'px',
					}}
				>
					{form}
				</div>
				<div
					className="datatable-footer-bulk-actions-layout__button"
					style={{
						flexBasis: submitButtonWidth + 'px',
					}}
				>
					{submitButton}
				</div>
			</div>
		</div>
	);
};



export default DatatableFooterBulkActionsLayout;
