import type CK from '~/types/contentking';

import {
	useWebsiteHttpHeadersQuery,
} from './useWebsiteHttpHeaders.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteHttpHeaders(websiteId: CK.WebsiteId | null) {
	const { data } = useWebsiteHttpHeadersQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.httpHeaders) ?? null;
}



export default useWebsiteHttpHeaders;
