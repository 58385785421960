import type CK from '~/types/contentking';

import {
	usePlatformCanonicalDomainQuery,
} from './usePlatformCanonicalDomain.gql';

import usePlatformQuery from '~/hooks/usePlatformQuery';



function usePlatformCanonicalDomain(
	websiteId: CK.WebsiteId,
) {
	const { data } = usePlatformQuery(
		usePlatformCanonicalDomainQuery,
		websiteId,
	);

	return data?.platformData?.canonicalDomain ?? null;
}



export default usePlatformCanonicalDomain;
