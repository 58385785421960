import type CK from '~/types/contentking';

import usePages from '~/hooks/usePages';



const columns = [];



function useCountPages(input: {
	filter: any,
	pollIntervalInMilliseconds: number,
	skip?: boolean,
	websiteId: CK.WebsiteId,
}): {
	total: number,
} {
	const {
		filter,
		pollIntervalInMilliseconds,
		skip = false,
		websiteId,
	} = input;

	return usePages({
		columns,
		filter,
		limit: 0,
		pollIntervalInMilliseconds,
		sortBy: null,
		skip,
		websiteId,
	});
}



export default useCountPages;
