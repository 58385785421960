import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class WebsiteIssueCategoryCardContent extends Component {

	_renderValues() {
		const {
			values,
		} = this.props;

		if (values && values.length > 0) {
			return (
				<div className="website-issue-category-card-content__values">
					{values.map((value, index) => {
						return (
							<div
								className="website-issue-category-card-content__value"
								key={'value-' + index}
							>
								{value}
							</div>
						);
					})}
				</div>
			);
		}
	}



	render() {
		const {
			isLoading,
			title,
		} = this.props;

		const componentClasses = classNames({
			'website-issue-category-card-content': true,
			'website-issue-category-card-content--is-loading': isLoading,
		});

		return (
			<div className={componentClasses}>
				<div className="website-issue-category-card-content__title">
					{title}
				</div>
				{this._renderValues()}
			</div>
		);
	}

}

WebsiteIssueCategoryCardContent.propTypes = {
	/** Enable special look of card in loading state */
	isLoading: PropTypes.bool,
	/** Data values of issue category */
	values: PropTypes.array,
	/** Title of issue category */
	title: PropTypes.node.isRequired,
};



export default WebsiteIssueCategoryCardContent;
