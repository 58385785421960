import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class HeaderFilterLayout extends Component {

	render() {
		const {
			compactView,
			children,
		} = this.props;

		const componentClasses = classNames({
			'header-filter-layout': true,
			'header-filter-layout--compact-view': compactView,
		});

		return (
			<div className={componentClasses}>
				{children}
			</div>
		);
	}

}

HeaderFilterLayout.propTypes = {
	compactView: PropTypes.bool,
};



export default HeaderFilterLayout;
