import {
	format,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import DateRangePickerForm from '~/components/atoms/forms/forms/DateRangePickerForm';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';

import useUpdateTrackedChangesDateInterval from '~/hooks/useUpdateTrackedChangesDateInterval';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteTimeCreatedAt from '~/hooks/useWebsiteTimeCreatedAt';

import {
	enterComparisonMode,
} from '~/actions/issuesScreen';

import {
	dateIntervalSelector,
} from '~/state/historicalChanges/selectors';

import {
	dateRangePanelScopeSelector,
} from '~/state/ui/content/selectors';

import {
	getRouterState,
} from '~/routing/router';

import goTo from '~/routing/goTo';



const messages = defineMessages({
	title: {
		id: 'ui.dateRange.filter.mobile.displayingLabel',
	},
});

const goToButtonMessages = defineMessages({
	affectedPages: {
		id: 'ui.backlink.mobile.affectedPages',
	},
	issues: {
		id: 'ui.backlink.mobile.issuesOverview',
	},
	pages: {
		id: 'ui.backlink.mobile.pagesOverview',
	},
	singleIssueAffectedPages: {
		id: 'ui.backlink.mobile.affectedPages',
	},
});



export const SCOPE_AFFECTED_PAGES = 'affectedPages';
export const SCOPE_ISSUES = 'issues';
export const SCOPE_PAGES = 'pages';
export const SCOPE_SINGLE_ISSUE_AFFECTED_PAGES = 'singleIssueAffectedPages';



const DatePickerPanel: React.FC = () => {
	const websiteId = useWebsiteId();

	const dateInterval = useSelector(dateIntervalSelector);
	const dispatch = useDispatch();
	const panelScope = useSelector(dateRangePanelScopeSelector);
	const updateTrackedChangesDateInterval = useUpdateTrackedChangesDateInterval();
	const websiteTimeCreatedAt = useWebsiteTimeCreatedAt(websiteId);

	const [selectedDate, setSelectedDate] = React.useState(
		(dateInterval.get('endDate') || dateInterval.get('startDate'))
			? {
				startDate: dateInterval.get('startDate') ? new Date(dateInterval.get('startDate')) : null,
				endDate: dateInterval.get('endDate') ? new Date(dateInterval.get('endDate')) : null,
			}
			: null,
	);

	const handleGoToDefaultScreen = React.useCallback(
		(e) => {
			e.preventDefault();

			const routeParams = getRouterState().params;
			let targetRouteName;

			if (panelScope === SCOPE_ISSUES) {
				targetRouteName = 'website.issues';
			}

			if (panelScope === SCOPE_PAGES) {
				targetRouteName = 'website.pages';
			}

			if (panelScope === SCOPE_AFFECTED_PAGES) {
				targetRouteName = 'website.issues.issueCategory.affectedPages';
			}

			if (panelScope === SCOPE_SINGLE_ISSUE_AFFECTED_PAGES) {
				targetRouteName = 'website.issues.issueCategory.singleIssueAffectedLinks';
			}

			goTo(e, targetRouteName, {
				issuesOverviewCategory: routeParams.issuesOverviewCategory,
				issuesOverviewIssue: routeParams.issuesOverviewIssue,
				websiteId: routeParams.websiteId,
			});
		},
		[
			panelScope,
		],
	);

	const handleSelectRange = React.useCallback(
		(range) => {
			setSelectedDate({
				startDate: range.startDate,
				endDate: range.endDate,
			});

			if (range.startDate && range.endDate) {
				updateTrackedChangesDateInterval(
					range.startDate,
					range.endDate,
				);

				dispatch(
					enterComparisonMode(
						format(range.startDate, 'yyyy-MM-dd'),
						format(range.endDate, 'yyyy-MM-dd'),
					),
				);
			}
		},
		[
			dispatch,
			setSelectedDate,
			updateTrackedChangesDateInterval,
		],
	);

	if (websiteTimeCreatedAt === null) {
		return null;
	}

	return (
		<ModalContainer
			header={(
				<ModalHeader
					title={(
						<FormattedMessage {...messages.title} />
					)}
				/>
			)}
		>
			<ModalContentSection>
				<Button
					onClick={handleGoToDefaultScreen}
					style={ButtonStyle.Hollow}
					width={ButtonWidth.Fullwidth}
				>
					<FormattedMessage {...goToButtonMessages[panelScope]} />
				</Button>
			</ModalContentSection>

			<DateRangePickerForm
				endDate={selectedDate?.endDate ?? null}
				minDate={websiteTimeCreatedAt}
				onSelectCallback={handleSelectRange}
				startDate={selectedDate?.startDate ?? null}
			/>
		</ModalContainer>
	);
};



export default DatePickerPanel;
