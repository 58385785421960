import type CK from '~/types/contentking';

import {
	useWebsiteDomainQuery,
} from './useWebsiteDomain.gql';

import useBatchContext from '~/hooks/useBatchContext';



function useWebsiteDomain(websiteId: CK.WebsiteId | null): string | null {
	const { data } = useWebsiteDomainQuery({
		context: useBatchContext('websites', 50),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return data?.website?.domain ?? null;
}



export default useWebsiteDomain;
