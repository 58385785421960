import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AssignedWebsitesField from '~/components/app/AssignedWebsitesField';
import BackButton from '~/components/app/BackButton';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import HiddenContent from '~/components/patterns/content/HiddenContent';
import LimitedWebsitesAccessField from '~/components/logic/formFields/LimitedWebsitesAccessField';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';
import SubmitButton from '~/components/app/SubmitButton';

import useAllWebsites from '~/hooks/useAllWebsites';

import {
	WebsiteAccess,
} from '~/model/users';

import type FormError from '~/utilities/FormError';



const messages = defineMessages({
	prompt: {
		id: 'ui.websiteAccess.prompt',
	},
	sidebar: {
		id: 'ui.members.new.assignWebsites.sidebar',
	},
	submitButton: {
		id: 'ui.newTeam.invitationForm.sendInvitationButton',
	},
	title: {
		id: 'ui.members.new.title',
	},
});

const validations = {
	assignedWebsites: [
		{
			message: '',
			fields: ['assignedWebsites', 'scope'],
			rule: ({ name, values }) => {
				return values.scope !== WebsiteAccess.Selected || values[name].length > 0;
			},
		},
	],
	scope: [
		{
			message: '',
			fields: ['assignedWebsites', 'scope'],
			rule: ({ name, values }) => {
				return !!values[name];
			},
		},
	],
};



type Props ={
	inviteeAccountId: CK.AccountId | null,
	onSubmitCallback: (
		values: {
			assignedWebsites: ReadonlyArray<CK.WebsiteId>,
			scope: WebsiteAccess,
		},
		extra: { createError: () => FormError }
	) => void,
};

const AddMemberModalAssignWebsitesStep: React.FC<Props> = (props) => {
	const {
		inviteeAccountId,
		onSubmitCallback,
	} = props;

	const allWebsites = useAllWebsites();

	if (inviteeAccountId === null) {
		return null;
	}

	return (
		<MultiStepModalStep
			name="assign-websites-step"
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{ linkSupport: linkExternal('https://www.contentkingapp.com/support/account-users/') }}
					/>
				</RichText>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Form
				defaultDataHasChanged={true}
				defaultFocus="scope"
				defaultValues={{
					assignedWebsites: [],
					scope: WebsiteAccess.All,
				}}
				onSuccess={onSubmitCallback}
				validations={validations}
			>
				{({ values }) => (
					<>
						<div
							style={{
								minHeight: 343,
							}}
						>
							<ModalContentSection>
								<FormRows>
									<RichText>
										<Copy {...messages.prompt} />
									</RichText>

									<Spacer left={SpacerGap.Growing}>
										<FormRow
											fullwidth={true}
											htmlFor="scope"
										>
											<LimitedWebsitesAccessField
												name="scope"
											/>
										</FormRow>
									</Spacer>
								</FormRows>
							</ModalContentSection>

							<HiddenContent hiddenContent={values.scope !== WebsiteAccess.Selected}>
								<FormRow
									fullwidth={true}
									htmlFor="assignedWebsites"
								>
									<AssignedWebsitesField
										allWebsites={allWebsites.listByUserAccount(inviteeAccountId).map((website) => website.id)}
										height={200}
										name="assignedWebsites"
									/>
								</FormRow>
							</HiddenContent>
						</div>

						<ButtonsLayout layout={ButtonsLayoutType.Steps}>
							<BackButton />

							<SubmitButton>
								<FormattedMessage {...messages.submitButton} />
							</SubmitButton>
						</ButtonsLayout>
					</>
				)}
			</Form>
		</MultiStepModalStep>
	);
};



export default AddMemberModalAssignWebsitesStep;
