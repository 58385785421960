import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RobotDirectivesIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				stroke={color}
				strokeLinejoin="round"
				strokeWidth="2"
			>
				<path d="M9.71 7.49h12.53v8.61H9.71zm1.93 10.97h8.67a1.93 1.93 0 0 1 1.93 1.93v1.51A6.26 6.26 0 0 1 16 28.17h0a6.26 6.26 0 0 1-6.29-6.27v-1.51a1.93 1.93 0 0 1 1.93-1.93z" />
				<circle
					cx="13.53"
					cy="11.63"
					r="1.23"
				/>
				<circle
					cx="15.97"
					cy="3.52"
					r=".95"
				/>
				<circle
					cx="18.42"
					cy="11.63"
					r="1.23"
				/>
				<g strokeLinecap="round">
					<path d="M15.97 4.47v3.02M9.71 19.83H7.55a1.63 1.63 0 0 0-1.62 1.62v3.26" />
					<path d="M22.24 19.83h2.21a1.63 1.63 0 0 1 1.62 1.62v3.26M4.63 26.68v-1.97h2.61v1.97" />
					<path d="M24.76 26.68v-1.97h2.61v1.97" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default RobotDirectivesIconBuilder;
