import React from 'react';

import FirstName from '~/components/atoms/FirstName';
import Gravatar from '~/components/patterns/users/Gravatar';
import MenuItemLayout from '~/components/atoms/navigations/builders/MenuItemLayout';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useRoyalMode from '~/hooks/useRoyalMode';
import useUserEmail from '~/hooks/useUserEmail';
import useUserFirstName from '~/hooks/useUserFirstName';

import {
	extractInitials,
} from '~/model/users';



type Props = {
	onClick?: (e: React.MouseEvent) => void,
};

const SidebarProfileInfo: React.FC<Props> = (props) => {
	const {
		onClick,
	} = props;

	const currentUserId = useCurrentUserId();

	const currentUserEmail = useUserEmail(currentUserId);
	const currentUserFirstName = useUserFirstName(currentUserId);
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const royalMode = useRoyalMode();

	let email = currentUserEmail;
	let firstName = currentUserFirstName;

	if (
		royalMode.isActive === true
		&& kingdomAdminFeatures.areVisible === false
	) {
		email = royalMode.email;
		firstName = royalMode.firstName;
	}

	return (
		<MenuItemLayout
			icon={(
				<Gravatar
					email={email}
					key={email}
					placeholder={email !== null ? extractInitials(email) : null}
				/>
			)}
			label={email !== null && (
				<FirstName
					email={email}
					firstName={firstName}
				/>
			)}
			onClick={onClick}
		/>
	);
};



export default React.memo(SidebarProfileInfo);
