import classNames from 'classnames';
import React from 'react';



type Props = {
	children: React.ReactNode,
	/** List header */
	header?: React.ReactElement,
	/** Enable fixed header */
	isHeaderFixed?: boolean,
};

const IssueListContainer: React.FC<Props> = (props) => {
	const {
		header,
		children,
		isHeaderFixed = false,
	} = props;

	const componentClasses = classNames({
		'issue-list-container': true,
		'issue-list-container--is-header-fixed': header && isHeaderFixed,
	});

	return (
		<div className={componentClasses}>
			{header && (
				<div className="issue-list-container__header">
					<div className="issue-list-container__header-body">
						{header}
					</div>
				</div>
			)}

			<div className="issue-list-container__body">
				{children}
			</div>
		</div>
	);
};



export default IssueListContainer;
