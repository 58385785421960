import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import CK from '~/types/contentking';

import AlertPagesHeader from '~/components/app/AlertPagesHeader';
import AlertTypeTitle from '~/components/logic/alerts/AlertTypeTitle';
import ContentChangeDatatable from '~/components/logic/alertPages/ContentChangeDatatable';
import IssueLinksBrokenDatatable from '~/components/logic/alertPages/IssueLinksBrokenDatatable';
import IssueOpenedDatatable from '~/components/logic/alertPages/IssueOpenedDatatable';
import PagePropertyChangedDatatable from '~/components/logic/alertPages/PagePropertyChangedDatatable';
import PageTypeChangeDatatable from '~/components/logic/alertPages/PageTypeChangeDatatable';
import PagesBecameNonIndexableDatatable from '~/components/logic/alertPages/PagesBecameNonIndexableDatatable';
import PagesEnteredOrLeftSegmentDatatable from '~/components/logic/alertPages/PagesEnteredOrLeftSegmentDatatable';
import ScopeLabel, {
	ScopeLabelStyle,
} from '~/components/logic/scopes/ScopeLabel';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';

import {
	loadAlertPages,
} from '~/actions/alertPages';

import {
	ALERT_CANONICAL_LINK_CHANGED,
	ALERT_CONTENT_CHANGED_H1,
	ALERT_CONTENT_CHANGED_META_DESCRIPTION,
	ALERT_CONTENT_CHANGED_TITLE,
	ALERT_ENRICHMENT_FIELD_CHANGED,
	ALERT_HREFLANGS_CHANGED,
	ALERT_PAGE_BECAME_NON_INDEXABLE,
	ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_ANALYTICS_MISSING,
	ALERT_PAGE_ISSUE_OPENED_ANALYTICS_VISUAL_ANALYTICS_MISSING,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_INCORRECTLY_CANONICALIZED,
	ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_POINTS_TO_UNINDEXABLE,
	ALERT_PAGE_ISSUE_OPENED_H1_MISSING,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_FCP,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_LCP,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_PERFORMANCE,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_SI,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TBT,
	ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TTI,
	ALERT_PAGE_ISSUE_OPENED_LINKS_BROKEN,
	ALERT_PAGE_ISSUE_OPENED_META_DESCRIPTION_MISSING,
	ALERT_PAGE_ISSUE_OPENED_TITLE_MISSING,
	ALERT_PAGE_TYPE_CHANGED,
	ALERT_PROPERTY_CHANGED,
} from '~/model/alerts';

import {
	IssueCategoryName,
} from '~/model/issuesNew';

import {
	currentDataSelector as currentAlertPagesDataSelector,
	currentAlertSelector,
	filterSelector,
	sortBySelector,
} from '~/state/alertPages/selectors';

import {
	currentDataSelector as currentIncidentsDataSelector,
} from '~/state/incidents/selectors';

import {
	alertIdSelector,
} from '~/state/ui/content/selectors';

import {
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	property: {
		id: 'ui.general.propertyDeclaration',
	},
});



const select = createSelector(
	alertIdSelector,
	currentAlertPagesDataSelector,
	currentAlertSelector,
	filterSelector,
	currentIncidentsDataSelector,
	selectedWebsiteIdSelector,
	sortBySelector,
	(alertId, alertPages, currentAlert, filter, incidents, selectedWebsiteId, sortBy) => {
		let alertType = null;

		if (currentAlert) {
			alertType = currentAlert.get('type');
		}

		return {
			alertId,
			alertType,
			alertPages,
			filter,
			incident: currentAlert,
			incidents,
			selectedWebsiteId,
			sortBy,
		};
	},
);


class AlertPagesScreen extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleActiveFilterClick = this._handleActiveFilterClick.bind(this);
		this._handleScroll = this._handleScroll.bind(this);
	}



	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(loadAlertPages(0, 100));
	}



	UNSAFE_componentWillReceiveProps({
		alertId: nextAlertId,
		alertType: nextAlertType,
		filter: nextFilter,
		incidents: nextIncidents,
		selectedWebsiteId: nextSelectedWebsiteId,
		sortBy: nextSortBy,
	}) {
		const { dispatch } = this.props;

		const {
			alertId: prevAlertId,
			alertType: prevAlertType,
			filter: prevFilter,
			incidents: prevIncidents,
			selectedWebsiteId: prevSelectedWebsiteId,
			sortBy: prevSortBy,
		} = this.props;

		if (
			prevAlertType !== nextAlertType
			|| prevAlertId !== nextAlertId
			|| prevFilter !== nextFilter
			|| prevIncidents !== nextIncidents
			|| prevSelectedWebsiteId !== nextSelectedWebsiteId
			|| prevSortBy !== nextSortBy
		) {
			dispatch(loadAlertPages(0, 100));
		}
	}



	_handleActiveFilterClick(columnName) {
		if (this._datatableRef) {
			this._datatableRef.scrollToColumn(columnName);
		}
	}



	_handleScroll({ rowIndex }) {
		const { dispatch } = this.props;

		if (this.loadingTimeout) {
			clearTimeout(this.loadingTimeout);
		}

		this.loadingTimeout = setTimeout(() => {
			dispatch(
				loadAlertPages(rowIndex, 100),
			);
		}, 100);
	}



	render() {
		const {
			alertPages,
			alertType,
			backlink,
			closeCallback,
			incident,
			selectedWebsiteId,
		} = this.props;

		if (!alertPages || !alertType) {
			return false;
		}

		const alert = incident;

		let datatable = null;

		if (alertType === ALERT_CANONICAL_LINK_CHANGED) {
			datatable = (
				<ContentChangeDatatable
					extraColumns={[
						CK.PagesCommonColumn.CanonicalUrl,
					]}
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_CONTENT_CHANGED_H1) {
			datatable = (
				<ContentChangeDatatable
					extraColumns={[
						CK.PagesCommonColumn.H1,
						CK.PagesCommonColumn.Title,
						CK.PagesCommonColumn.MetaDescription,
					]}
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_CONTENT_CHANGED_META_DESCRIPTION) {
			datatable = (
				<ContentChangeDatatable
					extraColumns={[
						CK.PagesCommonColumn.MetaDescription,
						CK.PagesCommonColumn.H1,
						CK.PagesCommonColumn.Title,
					]}
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_CONTENT_CHANGED_TITLE) {
			datatable = (
				<ContentChangeDatatable
					extraColumns={[
						CK.PagesCommonColumn.Title,
						CK.PagesCommonColumn.H1,
						CK.PagesCommonColumn.MetaDescription,
					]}
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_HREFLANGS_CHANGED) {
			datatable = (
				<ContentChangeDatatable
					extraColumns={[
						CK.PagesCommonColumn.HreflangChanges,
					]}
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT) {
			datatable = (
				<PagesEnteredOrLeftSegmentDatatable
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
				/>
			);
		} else if (alertType === ALERT_PAGE_ISSUE_OPENED_ANALYTICS_ANALYTICS_MISSING) {
			datatable = (
				<IssueOpenedDatatable
					extraColumns={[
						CK.PagesCommonColumn.AnalyticsServices,
					]}
					incident={incident}
					issueCategory={IssueCategoryName.Analytics}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_ISSUE_OPENED_ANALYTICS_VISUAL_ANALYTICS_MISSING) {
			datatable = (
				<IssueOpenedDatatable
					extraColumns={[
						CK.PagesCommonColumn.VisualAnalyticsServices,
					]}
					incident={incident}
					issueCategory={IssueCategoryName.Analytics}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_INCORRECTLY_CANONICALIZED) {
			datatable = (
				<IssueOpenedDatatable
					extraColumns={[
						CK.PagesCommonColumn.CanonicalUrl,
					]}
					incident={incident}
					issueCategory={IssueCategoryName.CanonicalLink}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_ISSUE_OPENED_CANONICAL_LINK_POINTS_TO_UNINDEXABLE) {
			datatable = (
				<IssueOpenedDatatable
					extraColumns={[
						CK.PagesCommonColumn.CanonicalUrl,
					]}
					incident={incident}
					issueCategory={IssueCategoryName.CanonicalLink}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_ISSUE_OPENED_H1_MISSING) {
			datatable = (
				<IssueOpenedDatatable
					extraColumns={[
						CK.PagesCommonColumn.H1,
					]}
					incident={incident}
					issueCategory={IssueCategoryName.H1}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (
			alertType === ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_CLS
			|| alertType === ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_FCP
			|| alertType === ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_LCP
			|| alertType === ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_PERFORMANCE
			|| alertType === ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_SI
			|| alertType === ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TBT
			|| alertType === ALERT_PAGE_ISSUE_OPENED_LIGHTHOUSE_TTI
		) {
			datatable = (
				<IssueOpenedDatatable
					extraColumns={[
						CK.PagesCommonColumn.LighthouseCumulativeLayoutShift,
						CK.PagesCommonColumn.LighthouseFirstContentfulPaint,
						CK.PagesCommonColumn.LighthouseLargestContentfulPaint,
						CK.PagesCommonColumn.LighthousePerformance,
						CK.PagesCommonColumn.LighthouseSpeedIndex,
						CK.PagesCommonColumn.LighthouseTimeToInteractive,
						CK.PagesCommonColumn.LighthouseTotalBlockingTime,
					]}
					incident={incident}
					issueCategory={IssueCategoryName.Lighthouse}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_ISSUE_OPENED_LINKS_BROKEN) {
			datatable = (
				<IssueLinksBrokenDatatable
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_ISSUE_OPENED_META_DESCRIPTION_MISSING) {
			datatable = (
				<IssueOpenedDatatable
					extraColumns={[
						CK.PagesCommonColumn.MetaDescription,
					]}
					incident={incident}
					issueCategory={IssueCategoryName.MetaDescription}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_ISSUE_OPENED_TITLE_MISSING) {
			datatable = (
				<IssueOpenedDatatable
					extraColumns={[
						CK.PagesCommonColumn.Title,
					]}
					incident={incident}
					issueCategory={IssueCategoryName.Title}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_TYPE_CHANGED) {
			datatable = (
				<PageTypeChangeDatatable
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PAGE_BECAME_NON_INDEXABLE) {
			datatable = (
				<PagesBecameNonIndexableDatatable
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_ENRICHMENT_FIELD_CHANGED) {
			datatable = (
				<PagePropertyChangedDatatable
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else if (alertType === ALERT_PROPERTY_CHANGED) {
			datatable = (
				<PagePropertyChangedDatatable
					incident={incident}
					ref={(ref) => this._datatableRef = ref}
					scrollCallback={this._handleScroll}
					websiteId={selectedWebsiteId}
				/>
			);
		} else {
			return false;
		}

		let title;

		if (alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT) {
			title = (
				<>
					<FormattedMessage
						{...messages.property}
						values={{
							text__property: (
								<AlertTypeTitle
									alertType={alertType}
									settings={incident.get('settings')}
								/>
							),
						}}
					/>

					<ScopeLabel
						scope={incident.get('scope')}
						style={ScopeLabelStyle.Label}
					/>
				</>
			);
		} else {
			title = (
				<AlertTypeTitle
					alertType={alertType}
					settings={incident.get('settings')}
				/>
			);
		}

		return (
			<ScreenLayout
				header={(
					<AlertPagesHeader
						activeFilterClickCallback={this._handleActiveFilterClick}
						alert={alert}
						backlinkCallback={closeCallback}
						backlinkLabel={backlink}
						numberOfAlertPages={alertPages.get('total') || null}
						title={title}
					/>
				)}
			>
				{datatable}
			</ScreenLayout>
		);
	}

}

AlertPagesScreen.propTypes = {
	alertPages: PropTypes.object.isRequired,
	selectedWebsiteId: PropTypes.string.isRequired,
};



export default connect(select)(AlertPagesScreen);
