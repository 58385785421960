import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import IssueValueCard, {
	IssueValueCardFlow,
} from '~/components/patterns/issues/IssueValueCard.part';



export enum AffectedPagesCountMode {
	Exact = 'exact',
	Percent = 'percent',
}

type Props = {
	mode: AffectedPagesCountMode,
	value: number,
};



const AffectedPagesCount: React.FC<Props> = (props) => {
	const {
		mode,
		value,
	} = props;

	let formattedValue;

	if (value === 0) {
		formattedValue = '–';
	} else {
		if (mode === AffectedPagesCountMode.Percent) {
			if (Math.abs(value) < 1) {
				formattedValue = '<1';
			} else {
				formattedValue = (
					<>
						<FormattedNumber
							maximumFractionDigits={0}
							minimumFractionDigits={0}
							value={value}
						/>
						<span>%</span>
					</>
				);
			}
		} else {
			formattedValue = (
				<FormattedNumber
					maximumFractionDigits={0}
					minimumFractionDigits={0}
					value={value}
				/>
			);
		}
	}

	return (
		<IssueValueCard
			flow={IssueValueCardFlow.Column}
			icon={(
				<BasicIcon
					type={BasicIconType.Pages}
				/>
			)}
			value={formattedValue}
		/>
	);
};



export default AffectedPagesCount;
