import React from 'react';

import BenefitIcon, {
	type BenefitIconType,
} from '~/components/patterns/icons/BenefitIcon';
import Grid from '~/components/patterns/structures/Grid';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	benefits: Array<{
		iconType: BenefitIconType,
		label: React.ReactNode,
	}>,
};



const EnterpriseBenefits: React.FC<Props> = (props) => {
	const {
		benefits,
	} = props;

	const viewportType = useViewportType();

	return (
		<Grid columnsCount={viewportType.isSmall || viewportType.isMedium ? 2 : 3}>
			{benefits.map((benefit, index) => (
				<div
					className="enterprise-benefit"
					key={'benefit-' + index}
				>
					<div className="enterprise-benefit__icon">
						<BenefitIcon
							color="#ffffff"
							type={benefit.iconType}
						/>
					</div>
					<div className="enterprise-benefit__label">{benefit.label}</div>
				</div>
			))}
		</Grid>
	);
};



export default EnterpriseBenefits;
