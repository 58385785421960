import React from 'react';



type Props = {
	children: React.ReactNode,
};



const UnchangedValue: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<span className="unchanged-value">
			{children}
		</span>
	);
};



export default UnchangedValue;
