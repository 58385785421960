import {
	type IssueName,
	isRecognizedIssue,
	isRecognizedIssueName,
} from '~/model/issuesNew';



function useGetIssue<
	TIssueCategory extends {
		issues: ReadonlyArray<{ name: string }>,
		name: string,
	},
>(
	issueCategory: TIssueCategory | null,
	issueName: string,
): (TIssueCategory['issues'][0] & { name: IssueName }) | null {
	if (!isRecognizedIssueName(issueName)) {
		return null;
	}

	if (issueCategory === null) {
		return null;
	}

	const issue = issueCategory.issues
		.filter(isRecognizedIssue)
		.find((issue) => issue.name === issueName);

	return issue ?? null;
}



export default useGetIssue;
