import React from 'react';

import {
	type FormOnChangeHandlerOptions,
} from '~/components/atoms/forms/basis/Form';
import FormFieldsContext from '~/components/atoms/forms/basis/FormFieldsContext';
import TextListField from '~/components/patterns/forms/fields/TextListField';

import useFormContext from '~/hooks/useFormContext';



function normalizeLines(input: string): Array<string> {
	return input
		.split('\n')
		.filter((line) => !line.match(/^\s*$/))
		.map((line) => line.trim());
}

type TextListFieldProps = React.ComponentProps<typeof TextListField>;



type Props = {
	instruction?: TextListFieldProps['instruction'],
	isDisabled?: TextListFieldProps['isDisabled'],
	name: string,
	placeholder?: TextListFieldProps['placeholder'],
	size?: TextListFieldProps['size'],
};

const ItemPerLineTextArea: React.FC<Props> = (props) => {
	const {
		name,
		...textListFieldProps
	} = props;

	const formContext = useFormContext();

	const formContextDefaultValues = formContext.defaultValues;
	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextValues = formContext.values;

	const updatedDefaultValues = React.useMemo(
		() => {
			if (formContextDefaultValues[name] instanceof Array) {
				return {
					...formContextDefaultValues,
					[name]: formContextDefaultValues[name].join('\n'),
				};
			}

			return formContextDefaultValues;
		},
		[
			formContextDefaultValues,
			name,
		],
	);

	const updatedOnChangeHandler = React.useCallback(
		(fieldName: string, value: any, options: FormOnChangeHandlerOptions = {}) => {
			if (typeof value !== 'string') {
				throw new Error(
					`TextListField in ItemPerLineTextArea should always publish string value in onChangeHandler`,
				);
			}

			return formContextOnChangeHandler(
				fieldName,
				normalizeLines(value),
				options,
			);
		},
		[
			formContextOnChangeHandler,
		],
	);

	const updatedValues = React.useMemo(
		() => {
			if (formContextValues[name] instanceof Array) {
				return {
					...formContextValues,
					[name]: formContextValues[name].join('\n'),
				};
			}

			return formContextValues;
		},
		[
			formContextValues,
			name,
		],
	);

	const updatedFormContext = React.useMemo(
		() => ({
			...formContext,
			defaultValues: updatedDefaultValues,
			onChangeHandler: updatedOnChangeHandler,
			values: updatedValues,
		}),
		[
			formContext,
			updatedDefaultValues,
			updatedOnChangeHandler,
			updatedValues,
		],
	);

	return (
		<FormFieldsContext
			context={updatedFormContext}
		>
			<TextListField
				name={name}
				{...textListFieldProps}
			/>
		</FormFieldsContext>
	);
};



export default ItemPerLineTextArea;
