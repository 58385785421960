import classNames from 'classnames';
import React from 'react';

import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



export enum DistributionBarChartSize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	/** Chart data */
	data: Array<{
		/** Slice color */
		color: string,
		/** Optional label visible on hover. When not set we will just show value */
		label?: RenderProp<number>,
		/** Optional onClick callback */
		onClickCallback?: (index: number) => void,
		/** Number representing current value of slice */
		value: number,
	}>,
	/** Show divider between every slice of chart */
	hasDividedSlices?: boolean,
	/** Size of chart */
	size?: DistributionBarChartSize,
};



const DistributionBarChart: React.FC<Props> = (props) => {
	const {
		data,
		hasDividedSlices = false,
		size = DistributionBarChartSize.Default,
	} = props;

	const sumValue = data.reduce((sum, item) => sum + item.value, 0);

	const componentClasses = classNames({
		'distribution-bar-chart': true,
		'distribution-bar-chart--empty': data.length == 0,
		'distribution-bar-chart--divided-slices': hasDividedSlices,
		['distribution-bar-chart--' + size + '-size']: true,
	});

	return (
		<div className={componentClasses}>
			{data.map((item, index) => {
				let popupText: React.ReactNode = item.value.toString();

				if (item.label) {
					popupText = renderProp(item.label, item.value);
				}

				return (
					<div
						className="distribution-bar-chart__slice distribution-bar-chart__slice--hoverable"
						key={'distribution-bar-chart-item-' + index}
						style={{
							width: (100 / sumValue) * item.value + '%',
						}}
					>
						<Hint
							attachment={HintAttachment.Center}
							className="distribution-bar-chart__hoverable-area"
							popup={popupText}
							popupOffset={({ placement }) => {
								if (placement === 'bottom-start' || placement === 'top-start') {
									return [-10, 0];
								}

								if (placement === 'bottom-end' || placement === 'top-end') {
									return [10, 0];
								}

								return [0, 0];
							}}
						>
							<div
								className={classNames({
									'distribution-bar-chart__bg': true,
									'distribution-bar-chart__bg--clickable': item.onClickCallback !== undefined,
								})}
								onClick={() => item.onClickCallback?.(index)}
								style={{
									backgroundColor: item.color,
								}}
							/>
						</Hint>
					</div>
				);
			})}
		</div>
	);
};



export default DistributionBarChart;
