import React from 'react';

import MastercardLogo from '../../../../images/payment-services/mastercard.png';
import VisaLogo from '../../../../images/payment-services/visa.png';
import AmericanexpressLogo from '../../../../images/payment-services/americanexpress.png';
import DiscoverLogo from '../../../../images/payment-services/discover.png';
import JcbLogo from '../../../../images/payment-services/jcb.png';



const PaymentServicesList: React.FC = () => {
	return (
		<ul className="payment-services">
			<li className="payment-services__service">
				<img
					alt="MasterCard"
					src={MastercardLogo}
				/>
			</li>
			<li className="payment-services__service">
				<img
					alt="Visa"
					src={VisaLogo}
				/>
			</li>
			<li className="payment-services__service">
				<img
					alt="American Express"
					src={AmericanexpressLogo}
				/>
			</li>
			<li className="payment-services__service">
				<img
					alt="DISCOVER"
					src={DiscoverLogo}
				/>
			</li>
			<li className="payment-services__service">
				<img
					alt="JCB"
					src={JcbLogo}
				/>
			</li>
		</ul>
	);
};



export default React.memo(PaymentServicesList);
