import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AbbreviatedNumber from '~/components/app/AbbreviatedNumber';
import Copy from '~/components/logic/Copy';
import InternalLink from '~/components/patterns/links/InternalLink';
import IssueAffectedPagesBar from '~/components/patterns/issues/IssueAffectedPagesBar';
import NumberOfAffectedPages, {
	NumberOfAffectedPagesImpact,
} from '~/components/patterns/issues/NumberOfAffectedPages';



const messages = defineMessages({
	amountOfPages: {
		id: 'ui.issueDetail.affectedPagesBar.amountOfPages',
	},
	link: {
		id: 'ui.issueDetail.affectedPagesBar.link',
	},
	percentageOfSegment: {
		id: 'ui.issueDetail.affectedPagesBar.percentageOfSegment',
	},
	percentageOfWebsite: {
		id: 'ui.issueDetail.affectedPagesBar.percentageOfWebsite',
	},
});

export enum Scope {
	Segment = 'segment',
	Website = 'website',
}



type Props = {
	amount: number,
	onClickCallback: () => void,
	percentage: number,
	scope: Scope,
};

const IssueAffectedPagesBarLogic: React.FC<Props> = (props) => {
	const {
		amount,
		onClickCallback,
		percentage,
		scope,
	} = props;

	return (
		<IssueAffectedPagesBar
			link={(
				<InternalLink onClickCallback={onClickCallback}>
					<FormattedMessage {...messages.link} />
				</InternalLink>
			)}
		>
			<Copy {...messages.amountOfPages} />

			{' '}

			<NumberOfAffectedPages impact={NumberOfAffectedPagesImpact.Negative}>
				<AbbreviatedNumber value={amount} />
			</NumberOfAffectedPages>

			{' '}

			{scope === Scope.Website && (
				<Copy
					{...messages.percentageOfWebsite}
					values={{
						percentage: percentage / 100,
					}}
				/>
			)}

			{scope === Scope.Segment && (
				<Copy
					{...messages.percentageOfSegment}
					values={{
						percentage: percentage / 100,
					}}
				/>
			)}
		</IssueAffectedPagesBar>
	);
};



export default IssueAffectedPagesBarLogic;
