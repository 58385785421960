import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import BlankValue from '~/components/patterns/values/BlankValue';
import HelpHint from '~/components/patterns/hints/HelpHint';
import ResizableDatatable, {
	type ResizableDatatableColumnDefinitions,
	ResizableDatatableHeaderHeight,
} from '~/components/logic/datatables/ResizableDatatable';
import TextualCellValue from '~/components/logic/datatables/cellValues/TextualCellValue';

import {
	useOutgoingExternalLinksQuery,
} from './OutgoingExternalLinksTable.gql';

import useGraphQLConnection from '~/hooks/useGraphQLConnection';



const filter = Immutable.Map({});

const messages = defineMessages({
	columnLabel: {
		id: 'ui.pageDetail.linkData.outbound.columns.label',
	},
	columnStatus: {
		id: 'ui.pageDetail.linkData.outbound.columns.status',
	},
	columnTitle: {
		id: 'ui.pageDetail.linkData.outbound.columns.title',
	},
	columnUrl: {
		id: 'ui.pageDetail.linkData.outbound.columns.url',
	},
	statusBroken: {
		id: 'ui.pageDetail.linkData.status.broken',
	},
	statusForbidden: {
		id: 'ui.pageDetail.linkData.status.forbidden',
	},
	statusNotBeingCheckedBecauseOfDomain: {
		id: 'ui.pageDetail.linkData.status.notBeingCheckedBecauseOfDomain',
	},
	statusNotBeingCheckedBecauseOfDomainExplanation: {
		id: 'ui.pageDetail.linkData.status.notBeingCheckedBecauseOfDomain.hint',
	},
	statusNotBeingCheckedBecauseOfPort: {
		id: 'ui.pageDetail.linkData.status.notBeingCheckedBecauseOfPort',
	},
	statusNotBeingCheckedBecauseOfPortExplanation: {
		id: 'ui.pageDetail.linkData.status.notBeingCheckedBecauseOfPort.hint',
	},
	statusOk: {
		id: 'ui.pageDetail.linkData.status.ok',
	},
	statusRedirect: {
		id: 'ui.pageDetail.linkData.status.redirect',
	},
	statusUnknown: {
		id: 'ui.pageDetail.linkData.status.unknown',
	},
	statusUnreachable: {
		id: 'ui.pageDetail.linkData.status.unreachable',
	},
});



type Props = {
	fallbackLinksCount: number | undefined,
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const OutgoingExternalLinksTable: React.FC<Props> = (props) => {
	const {
		fallbackLinksCount,
		legacyUrlId,
		websiteId,
	} = props;

	const { data, fetchMore } = useOutgoingExternalLinksQuery({
		variables: {
			legacyUrlId,
			limit: 100,
			offset: 0,
			websiteId,
		},
	});

	const graphQLConnection = useGraphQLConnection({
		batchLimit: 100,
		context: {
			websiteId,
		},
		data,
		fallbackTotalCount: fallbackLinksCount,
		fetchMore,
		getter: (data) => data.lookupPageByLegacyId?.pageTypeData?.outgoingExternalLinks ?? null,
		pollIntervalInMilliseconds: 10_000,
	});

	const columns: ResizableDatatableColumnDefinitions<typeof graphQLConnection.rowGetter> = React.useMemo(
		() => {
			return [
				{
					name: 'outgoingExternalLinksTargetUrl',
					render: {
						cell: ({ row }) => (
							<TextualCellValue value={row.targetUrl} />
						),
						label: (
							<FormattedMessage {...messages.columnUrl} />
						),
					},
					resizable: true,
					width: 450,
				},
				{
					name: 'outgoingExternalLinksAnchorText',
					render: {
						cell: ({ row }) => (
							<TextualCellValue value={row.anchorText} />
						),
						label: (
							<FormattedMessage {...messages.columnLabel} />
						),
					},
					resizable: true,
					width: 200,
				},
				{
					name: 'outgoingExternalLinksAnchorTitle',
					render: {
						cell: ({ row }) => (
							<TextualCellValue value={row.anchorTitle} />
						),
						label: (
							<FormattedMessage {...messages.columnTitle} />
						),
					},
					resizable: true,
					width: 300,
				},
				{
					name: 'outgoingExternalLinksTargetStatus',
					render: {
						cell: ({ row }) => {
							let text;

							if (row.targetStatus === GraphQL.PageOutgoingExternalLinkStatus.NotCrawledYet) {
								text = (
									<FormattedMessage {...messages.statusUnknown} />
								);
							} else if (row.targetStatus === GraphQL.PageOutgoingExternalLinkStatus.NotBeingCrawled) {
								text = (
									<AttachedElement
										element={(
											<HelpHint
												message={(
													<FormattedMessage {...messages.statusNotBeingCheckedBecauseOfDomainExplanation} />
												)}
											/>
										)}
									>
										<BlankValue>
											<FormattedMessage {...messages.statusNotBeingCheckedBecauseOfDomain} />
										</BlankValue>
									</AttachedElement>
								);
							} else if (row.targetStatus === GraphQL.PageOutgoingExternalLinkStatus.ExoticPort) {
								text = (
									<AttachedElement
										element={(
											<HelpHint
												message={(
													<FormattedMessage {...messages.statusNotBeingCheckedBecauseOfPortExplanation} />
												)}
											/>
										)}
									>
										<BlankValue>
											<FormattedMessage {...messages.statusNotBeingCheckedBecauseOfPort} />
										</BlankValue>
									</AttachedElement>
								);
							} else if (row.targetStatus === GraphQL.PageOutgoingExternalLinkStatus.Forbidden) {
								text = (
									<FormattedMessage {...messages.statusForbidden} />
								);
							} else if (row.targetStatus === GraphQL.PageOutgoingExternalLinkStatus.Unreachable) {
								text = (
									<FormattedMessage {...messages.statusUnreachable} />
								);
							} else if (row.targetStatusCode !== null && row.targetStatusCode >= 400) {
								text = (
									<FormattedMessage {...messages.statusBroken} />
								);
							} else if (row.targetStatusCode !== null && row.targetStatusCode >= 300) {
								text = (
									<FormattedMessage {...messages.statusRedirect} />
								);
							} else {
								text = (
									<FormattedMessage {...messages.statusOk} />
								);
							}

							return (
								<TextualCellValue value={text} />
							);
						},
						label: (
							<FormattedMessage {...messages.columnStatus} />
						),
					},
					resizable: true,
					width: 300,
				},
			];
		},
		[],
	);

	return (
		<ResizableDatatable
			blankSlate={null}
			columns={columns}
			filter={filter}
			headerHeight={ResizableDatatableHeaderHeight.Small}
			isLoading={graphQLConnection.isLoaded === false}
			onScroll={graphQLConnection.fetchFurtherRange}
			rowGetter={graphQLConnection.rowGetter}
			rowsCount={graphQLConnection.totalCount}
		/>
	);
};



export default OutgoingExternalLinksTable;
