import React from 'react';

import AbstractCheckboxField, {
	AbstractCheckboxFieldCheckedState,
	AbstractCheckboxFieldSize,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';

import useFormContext from '~/hooks/useFormContext';



type Props = {
	disabled?: boolean,
	isControlled?: boolean,
	label: React.ReactNode,
	name: string,
	onChangeCallback?: (name: string, isChecked: boolean) => void,
	onClickCallback?: () => void,
	size?: AbstractCheckboxFieldSize,
	width?: false | number,
};

const CheckboxField: React.FC<Props> = (props) => {
	const {
		disabled = false,
		isControlled = false,
		label,
		name,
		onChangeCallback,
		onClickCallback,
		size = AbstractCheckboxFieldSize.Default,
		width = 280,
	} = props;

	const formContext = useFormContext();

	const formContextOnMountHandler = formContext.onMountHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	React.useEffect(
		() => {
			formContextOnMountHandler(
				name,
				{
					interacted: true,
				},
			);

			return () => {
				if (isControlled) {
					return;
				}

				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			isControlled,
			name,
		],
	);

	const isChecked = isControlled ? formContext.values[name] : formContext.defaultValues[name];
	let checkedState = AbstractCheckboxFieldCheckedState.NotChecked;

	if (isChecked) {
		checkedState = AbstractCheckboxFieldCheckedState.Checked;
	}

	return (
		<AbstractCheckboxField
			checkedState={checkedState}
			isControlled={isControlled}
			isDisabled={formContext.isDisabled || disabled}
			label={label}
			maxWidth={width === false ? undefined : width}
			name={name}
			onBlur={() => {
				formContext.onBlurHandler(name);
			}}
			onChangeCallback={(isChecked) => {
				formContext.onChangeHandler(name, isChecked).then(() => {
					if (onChangeCallback) {
						onChangeCallback(name, isChecked);
					}
				});
			}}
			onClick={onClickCallback}
			onFocus={() => {
				formContext.onFocusHandler(name);
			}}
			size={size}
		/>
	);
};



export default CheckboxField;

export {
	AbstractCheckboxFieldSize as CheckboxFieldSize,
};
