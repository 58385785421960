import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const XmlSitemapIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Group"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="network"
					transform="translate(3.000000, 3.000000)"
				>
					<polygon
						id="Stroke-335"
						points="32.6545313 18.90625 22.3420313 18.90625 22.3420313 8.59375 32.6545313 8.59375"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polygon>
					<polygon
						id="Stroke-336"
						points="32.6545313 44.6875 22.3420313 44.6875 22.3420313 34.375 32.6545313 34.375"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polygon>
					<polygon
						id="Stroke-337"
						points="17.1857813 44.6875 6.87328125 44.6875 6.87328125 34.375 17.1857813 34.375"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polygon>
					<polygon
						id="Stroke-338"
						points="48.1232813 44.6875 37.8107813 44.6875 37.8107813 34.375 48.1232813 34.375"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polygon>
					<path
						d="M27.4989688,18.90625 L27.4989688,34.375"
						id="Stroke-339"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<polyline
						id="Stroke-340"
						points="12.0291875 34.375 12.0291875 27.5 42.9666875 27.5 42.9666875 34.375"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polyline>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default XmlSitemapIconBuilder;

