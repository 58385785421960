import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AccountStripeContext from '~/components/app/Stripe/AccountStripeContext';
import Incomplete3DSecureChallengeBanner from '~/components/app/Incomplete3DSecureChallengeBanner';
import IncompleteSignupBanner from '~/components/app/IncompleteSignupBanner';

import useAccountPaymentStatus from '~/hooks/useAccountPaymentStatus';



type Props = {
	accountId: CK.AccountId,
};

const PaymentStatusBanner: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const paymentStatus = useAccountPaymentStatus(accountId);

	const actionType = paymentStatus?.stripeAction?.actionType ?? null;
	const clientSecret = paymentStatus?.stripeAction?.clientSecret ?? null;
	const paymentMethod = paymentStatus?.stripeAction?.paymentMethod ?? null;

	if (actionType === GraphQL.StripePaymentActionType.Confirm) {
		if (clientSecret === null || paymentMethod === null) {
			return null;
		}

		return (
			<Incomplete3DSecureChallengeBanner
				accountId={accountId}
				clientSecret={clientSecret}
				paymentMethod={paymentMethod}
			/>
		);
	}

	if (actionType === GraphQL.StripePaymentActionType.FinishSignup) {
		return (
			<IncompleteSignupBanner
				accountId={accountId}
			/>
		);
	}

	return null;
};

const PaymentStatusBannerWrapper: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	return (
		<AccountStripeContext accountId={accountId}>
			<PaymentStatusBanner {...props} />
		</AccountStripeContext>
	);
};



export default PaymentStatusBannerWrapper;
