import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import CodeValue, {
	CodeValueWrapping,
} from '~/components/patterns/values/CodeValue';
import DataLoadingFramework from '~/components/logic/datatables/DataLoadingFramework';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';

import {
	loadData as loadSitemapsErrors,
	selector as sitemapsErrorsSelector,
} from '~/state/sitemapsErrors';



const messages = defineMessages({
	error: {
		id: 'ui.sitemapsErrorsTable.error',
	},
	lineNumber: {
		id: 'ui.sitemapsErrorsTable.lineNumber',
	},
	string: {
		id: 'ui.sitemapsErrorsTable.string',
	},
});



type Props = {
	loadSitemaps: () => any,
	sitemaps: any,
	tableWidth: number,
};

const SitemapsErrorsTable: React.FC<Props> = (props) => {
	const {
		loadSitemaps,
		sitemaps,
		tableWidth,
	} = props;

	const dispatch = useDispatch();
	const sitemapsErrors = useSelector(sitemapsErrorsSelector);

	const rows = React.useMemo(
		() => {
			if (!sitemaps.get('loaded')) {
				return null;
			}

			const result: Array<{
				header: true,
				headerLabel: React.ReactNode,
			} | {
				line: string,
				line_number: number,
				message: string,
			} | null> = [];

			sitemaps.get('records').forEach(
				(sitemap) => {
					result.push({
						header: true,
						headerLabel: sitemap.get('url'),
					});

					const sitemapErrors = sitemapsErrors.get(sitemap.get('full_id'));

					for (let i = 0; i < sitemap.get('number_of_errors'); i++) {
						result.push(sitemapErrors ? sitemapErrors[i] : null);
					}
				},
			);

			return result;
		},
		[
			sitemaps,
			sitemapsErrors,
		],
	);

	const loadDataCallback = React.useCallback(
		async () => {
			await dispatch(loadSitemaps());

			const promises: Array<Promise<void>> = [];

			sitemaps.get('records').forEach((sitemap) => {
				promises.push(
					dispatch(
						loadSitemapsErrors(sitemap.get('full_id')),
					),
				);
			});

			await Promise.all(promises);
		},
		[
			dispatch,
			loadSitemaps,
			sitemaps,
		],
	);

	return (
		<DataLoadingFramework
			loadDataCallback={loadDataCallback}
			syncInterval={1000}
		>
			{() => (
				<SmallTable
					columns={[
						{
							render: {
								cell: ({ row }) => {
									if (row === null) {
										return '';
									}

									if ('header' in row) {
										return {
											content: (
												<strong>{row.headerLabel}</strong>
											),
											colspan: 3,
										};
									}

									return row.line_number;
								},
								header: (
									<FormattedMessage {...messages.lineNumber} />
								),
							},
							width: 75,
						},
						{
							render: {
								cell: ({ row }) => {
									if (row === null || 'header' in row) {
										return '';
									}

									return (
										<Ellipsis>
											{row.message}
										</Ellipsis>
									);
								},
								header: (
									<FormattedMessage {...messages.error} />
								),
							},
							width: 250,
						},
						{
							render: {
								cell: ({ row }) => {
									if (row === null || 'header' in row) {
										return '';
									}

									return (
										<CodeValue wrapping={CodeValueWrapping.None}>
											{row.line.trim()}
										</CodeValue>
									);
								},
								header: (
									<FormattedMessage {...messages.string} />
								),
							},
							width: 1000,
						},
					]}
					rows={rows}
					tableWidth={tableWidth}
				/>
			)}
		</DataLoadingFramework>
	);
};



export default React.memo(SitemapsErrorsTable);
