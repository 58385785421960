/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserMembershipsQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserMembershipsQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> } | null };


export const UserMembershipsDocument = gql`
    query UserMemberships($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    legacyId
    memberships {
      account {
        displayName
        id
      }
      hasLimitedWebsitesAccess
      role
      user {
        id
        legacyId
      }
      websites {
        id
      }
    }
  }
}
    `;

/**
 * __useUserMembershipsQuery__
 *
 * To run a query within a React component, call `useUserMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMembershipsQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserMembershipsQuery(baseOptions: Apollo.QueryHookOptions<UserMembershipsQuery, UserMembershipsQueryVariables> & ({ variables: UserMembershipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserMembershipsQuery, UserMembershipsQueryVariables>(UserMembershipsDocument, options);
      }
export function useUserMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMembershipsQuery, UserMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserMembershipsQuery, UserMembershipsQueryVariables>(UserMembershipsDocument, options);
        }
export function useUserMembershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserMembershipsQuery, UserMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserMembershipsQuery, UserMembershipsQueryVariables>(UserMembershipsDocument, options);
        }
export type UserMembershipsQueryHookResult = ReturnType<typeof useUserMembershipsQuery>;
export type UserMembershipsLazyQueryHookResult = ReturnType<typeof useUserMembershipsLazyQuery>;
export type UserMembershipsSuspenseQueryHookResult = ReturnType<typeof useUserMembershipsSuspenseQuery>;
export type UserMembershipsQueryResult = Apollo.QueryResult<UserMembershipsQuery, UserMembershipsQueryVariables>;