import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';

import AbstractCheckboxField, {
	AbstractCheckboxFieldCheckedState,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import AbstractSelectField from '~/components/patterns/forms/fields/AbstractSelectField';
import CheckboxLabel from '~/components/patterns/forms/fieldParts/checkboxes/CheckboxLabel';
import ColumnCategoryName from '~/components/names/ColumnCategoryName';
import ColumnController from '~/components/patterns/forms/fieldParts/pageColumns/ColumnController';
import ColumnName from '~/components/names/ColumnName';
import ColumnsCategoryController from '~/components/patterns/forms/fieldParts/pageColumns/ColumnsCategoryController';
import FieldDropdown from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdown';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	TrackedChangesProperty,
} from './useHistoryFilter';

import {
	PagesColumnsCategory,
} from '~/model/pagesColumns';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	[TrackedChangesProperty.H1Heading]: {
		id: 'ui.contentData.h1',
	},
	[TrackedChangesProperty.H2Heading]: {
		id: 'ui.contentData.h2',
	},
	[TrackedChangesProperty.H3Heading]: {
		id: 'ui.contentData.h3',
	},
	[TrackedChangesProperty.H4Heading]: {
		id: 'ui.contentData.h4',
	},
	[TrackedChangesProperty.H5Heading]: {
		id: 'ui.contentData.h5',
	},
	[TrackedChangesProperty.H6Heading]: {
		id: 'ui.contentData.h6',
	},
	[TrackedChangesProperty.PageDiscovered]: {
		id: 'ui.pageDetail.history.pageDiscovered',
	},
	[TrackedChangesProperty.UnreliableResponse]: {
		id: 'ui.pageDetail.history.unreliableResponse',
	},
	allSelected: {
		id: 'ui.pageDetail.pagePropertiesFilter.allSelected',
	},
	multipleSelected: {
		id: 'ui.pageDetail.pagePropertiesFilter.multipleSelected',
	},
	noneSelected: {
		id: 'ui.pageDetail.pagePropertiesFilter.noneSelected',
	},
	only: {
		id: 'ui.forms.options.only',
	},
});



type Category = {
	category: PagesColumnsCategory,
	properties: Array<CK.PagesColumn | TrackedChangesProperty>,
};

const CATEGORIES: Array<Category> = [
	{
		category: PagesColumnsCategory.Fundamentals,
		properties: [
			CK.PagesCommonColumn.Type,
			TrackedChangesProperty.UnreliableResponse,
			TrackedChangesProperty.PageDiscovered,
			CK.PagesCommonColumn.Redirect,
		],
	},
	{
		category: PagesColumnsCategory.Indexability,
		properties: [
			CK.PagesCommonColumn.IsInSitemap,
			CK.PagesCommonColumn.IsIndexable,
			CK.PagesCommonColumn.IsDisallowedInRobotsTxt,
			CK.PagesCommonColumn.IsIndexableDueToMetaRobots,
			CK.PagesCommonColumn.IsIndexableDueToXRobotsTag,
			CK.PagesCommonColumn.CanonicalUrl,
		],
	},
	{
		category: PagesColumnsCategory.Relations,
		properties: [
			CK.PagesCommonColumn.LinkPrev,
			CK.PagesCommonColumn.LinkNext,
			CK.PagesCommonColumn.HreflangLanguage,
			CK.PagesCommonColumn.MobileVariant,
			CK.PagesCommonColumn.LinkAmp,
		],
	},
	{
		category: PagesColumnsCategory.Content,
		properties: [
			CK.PagesCommonColumn.Title,
			CK.PagesCommonColumn.MetaDescription,
			TrackedChangesProperty.H1Heading,
			TrackedChangesProperty.H2Heading,
			TrackedChangesProperty.H3Heading,
			TrackedChangesProperty.H4Heading,
			TrackedChangesProperty.H5Heading,
			TrackedChangesProperty.H6Heading,
		],
	},
	{
		category: PagesColumnsCategory.Conversions,
		properties: [
			CK.PagesCommonColumn.AnalyticsServices,
			CK.PagesCommonColumn.VisualAnalyticsServices,
			CK.PagesCommonColumn.TagManagers,
		],
	},
	{
		category: PagesColumnsCategory.SchemaOrg,
		properties: [
			CK.PagesCommonColumn.SchemaOrgTypes,
		],
	},
	{
		category: PagesColumnsCategory.Social,
		properties: [
			CK.PagesCommonColumn.OpenGraphTitle,
			CK.PagesCommonColumn.OpenGraphType,
			CK.PagesCommonColumn.OpenGraphDescription,
			CK.PagesCommonColumn.OpenGraphImage,
			CK.PagesCommonColumn.OpenGraphUrl,
			CK.PagesCommonColumn.TwitterCard,
			CK.PagesCommonColumn.TwitterTitle,
			CK.PagesCommonColumn.TwitterDescription,
			CK.PagesCommonColumn.TwitterImage,
			CK.PagesCommonColumn.TwitterSite,
		],
	},
	{
		category: PagesColumnsCategory.CustomElements,
		properties: [],
	},
	{
		category: PagesColumnsCategory.EnrichmentFields,
		properties: [],
	},
	{
		category: PagesColumnsCategory.Lighthouse,
		properties: [
			CK.PagesCommonColumn.LighthousePerformance,
			CK.PagesCommonColumn.LighthouseFirstContentfulPaint,
			CK.PagesCommonColumn.LighthouseTimeToInteractive,
			CK.PagesCommonColumn.LighthouseSpeedIndex,
			CK.PagesCommonColumn.LighthouseTotalBlockingTime,
			CK.PagesCommonColumn.LighthouseLargestContentfulPaint,
			CK.PagesCommonColumn.LighthouseCumulativeLayoutShift,
		],
	},
];

function getCategoryCheckedState(
	category: Category,
	selectedProperties: Array<string>,
): AbstractCheckboxFieldCheckedState {
	const selectedPropertiesInCategory = category.properties
		.filter((property) => selectedProperties.includes(property));

	if (selectedPropertiesInCategory.length === category.properties.length) {
		return AbstractCheckboxFieldCheckedState.Checked;
	}

	if (selectedPropertiesInCategory.length === 0) {
		return AbstractCheckboxFieldCheckedState.NotChecked;
	}

	return AbstractCheckboxFieldCheckedState.Indeterminate;
}



type Props = {
	selectedProperties: Array<string>,
	onSelectProperties: (properties: Array<string>) => void,
	width?: number,
};

const PagePropertiesFilter: React.FC<Props> = (props) => {
	const {
		selectedProperties,
		onSelectProperties,
		width,
	} = props;

	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	const categories = React.useMemo(
		() => {
			const categories = [
				...CATEGORIES,
			];

			const customElementsCategory = CATEGORIES.find((category) => category.category === PagesColumnsCategory.CustomElements);
			const enrichmentFieldsCategory = CATEGORIES.find((category) => category.category === PagesColumnsCategory.EnrichmentFields);

			if (customElementDefinitions.isLoaded && customElementsCategory) {
				customElementsCategory.properties = [];
				customElementDefinitions.listAll().forEach((customElementDefinition) => {
					customElementsCategory.properties.push(customElementDefinition.column);
				});
			}

			if (enrichmentFieldDefinitions.isLoaded && enrichmentFieldsCategory) {
				enrichmentFieldsCategory.properties = [];
				enrichmentFieldDefinitions.listAll().forEach((enrichmentField) => {
					enrichmentFieldsCategory.properties.push(enrichmentField.column);
				});
			}

			return categories;
		},
		[
			customElementDefinitions,
			enrichmentFieldDefinitions,
		],
	);

	function handleCategoryChange(category: Category): void {
		const checkedState = getCategoryCheckedState(category, selectedProperties);

		if (checkedState === AbstractCheckboxFieldCheckedState.Checked) {
			const nextSelectedProperties = without(selectedProperties, ...category.properties);

			onSelectProperties(nextSelectedProperties);
		} else {
			const nextSelectedProperties = uniq([
				...selectedProperties,
				...category.properties,
			]);

			onSelectProperties(nextSelectedProperties);
		}
	}

	function handlePropertyChange(property: string, checked: boolean): void {
		if (checked) {
			const nextSelectedProperties = uniq([
				...selectedProperties,
				property,
			]);

			onSelectProperties(nextSelectedProperties);
		} else {
			const nextSelectedProperties = without(selectedProperties, property);
			onSelectProperties(nextSelectedProperties);
		}
	}

	function handleCategoryOnlyClick(category: Category): void {
		onSelectProperties(category.properties);
	}

	function handlePropertyOnlyClick(property: string): void {
		onSelectProperties([property]);
	}

	return (
		<AbstractSelectField
			dropdownWidth={Math.max(width || 0, 260)}
			label={(
				<PagePropertiesFilterLabel
					categories={categories}
					selectedProperties={selectedProperties}
				/>
			)}
			width={width}
		>
			<FieldDropdown
				contentMaxHeight={350}
			>
				{categories
					.filter((item) => item.properties.length > 0)
					.map((item) => (
						<ColumnsCategoryController
							checkboxField={(
								<AbstractCheckboxField
									checkedState={getCategoryCheckedState(item, selectedProperties)}
									isControlled={true}
									name={`page-property-${item.category}`}
									onChangeCallback={() => handleCategoryChange(item)}
								/>
							)}
							key={item.category}
							label={(
								<ColumnCategoryName columnCategory={item.category} />
							)}
							name={item.category}
							suffix={(
								<InternalLink
									onClickCallback={() => handleCategoryOnlyClick(item)}
									style={InternalLinkStyle.Decent}
								>
									<FormattedMessage {...messages.only} />
								</InternalLink>
							)}
						>
							{item.properties.map((property) => (
								<ColumnController
									key={property}
									label={(
										<AbstractCheckboxField
											checkedState={selectedProperties.includes(property)
												? AbstractCheckboxFieldCheckedState.Checked
												: AbstractCheckboxFieldCheckedState.NotChecked
											}
											isControlled={true}
											label={(
												<CheckboxLabel
													label={(
														messages[property] ? (
															<FormattedMessage {...messages[property]} />
														) : (
															<ColumnName column={property as CK.PagesColumn} />
														)
													)}
													labelHoverStamp={(
														<InternalLink
															onClickCallback={() => handlePropertyOnlyClick(property)}
															style={InternalLinkStyle.Decent}
														>
															<FormattedMessage {...messages.only} />
														</InternalLink>
													)}
												/>
											)}
											name={`page-property-${item.category}-${property}`}
											onChangeCallback={(checked) => handlePropertyChange(property, checked)}
										/>
									)}
								/>
							))}
						</ColumnsCategoryController>
					))}
			</FieldDropdown>
		</AbstractSelectField>
	);
};

export default PagePropertiesFilter;



type PagePropertiesFilterLabelProps = {
	categories: Array<Category>,
	selectedProperties: Array<string | CK.PagesColumn>,
};

const PagePropertiesFilterLabel: React.FC<PagePropertiesFilterLabelProps> = (props) => {
	const {
		categories,
		selectedProperties,
	} = props;

	const allProperties = categories.flatMap((category) => category.properties);

	if (selectedProperties.length === 1) {
		const property = getArrayItemAtSafeIndex(selectedProperties, 0);

		return (
			messages[property] ? (
				<FormattedMessage {...messages[property]} />
			) : (
				<ColumnName column={property as CK.PagesColumn} />
			)
		);
	} else if (allProperties.length === selectedProperties.length) {
		return (
			<FormattedMessage {...messages.allSelected} />
		);
	} else if (selectedProperties.length === 0) {
		return (
			<FormattedMessage {...messages.noneSelected} />
		);
	}

	// Check whether all selected properties come from a single category
	const selectedCategory = categories.find((category) => {
		return (
			selectedProperties.length === category.properties.length
			&& intersection(selectedProperties, category.properties).length === selectedProperties.length
		);
	});

	if (selectedCategory) {
		return (
			<ColumnCategoryName columnCategory={selectedCategory.category} />
		);
	}

	return (
		<FormattedMessage {...messages.multipleSelected} />
	);
};
