import classNames from 'classnames';
import React from 'react';



export enum TabNavigationStaticItemFloat {
	Left = 'left',
	Right = 'right',
}

export enum TabNavigationStaticItemStyle {
	Default = 'default',
	Light = 'light',
}

type Props = {
	float?: TabNavigationStaticItemFloat,
	children: React.ReactNode,
	/** Color style of component */
	style?: TabNavigationStaticItemStyle,
	/** Make label uppercased */
	uppercase?: boolean,
};



const TabNavigationStaticItem: React.FC<Props> = (props) => {
	const {
		float = TabNavigationStaticItemFloat.Left,
		children,
		style = TabNavigationStaticItemStyle.Default,
		uppercase = false,
	} = props;

	const componentClasses = classNames({
		'tab-nav-static-item': true,
		'tab-nav-static-item--light-style': style === TabNavigationStaticItemStyle.Light,
		'tab-nav-static-item--uppercase': uppercase,
		['tab-nav-static-item--float-' + float]: true,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default TabNavigationStaticItem;
