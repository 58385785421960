import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import GraphQL from '~/types/graphql';

import WithPermission from '~/components/logic/access/WithPermission';

import {
	ObjectType,
} from '~/model/permissions';



const buttonLabelMessages = defineMessages({
	ignored: {
		id: 'ui.issueDetail.ignoringButton.ignoringActive',
	},
	notIgnored: {
		id: 'ui.issueDetail.ignoringButton.setupIgnoring',
	},
});



type Props = {
	isIgnored: boolean,
	routeName: string,
	routeParams: Record<string, any>,
};

const IgnoringButton: React.FC<Props> = (props) => {
	const {
		isIgnored,
		routeName,
		routeParams,
	} = props;

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageIgnoring}
			objectId={routeParams.websiteId}
			objectType={ObjectType.Website}
		>
			{({ isAllowed }) => {
				if (isAllowed.yes === false) {
					return false;
				}

				return (
					<Button
						icon={(
							<BasicIcon
								size={18}
								type={BasicIconType.Block}
							/>
						)}
						linkRouteName={routeName}
						linkRouteParams={routeParams}
						size={ButtonSize.XSmall}
						style={ButtonStyle.Hollow}
						tooltip={(
							<FormattedMessage {...buttonLabelMessages[isIgnored ? 'ignored' : 'notIgnored']} />
						)}
					/>
				);
			}}
		</WithPermission>
	);
};



export default IgnoringButton;
