import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import BillingDetailsForm from '~/components/atoms/forms/forms/BillingDetailsForm';
import FormsList from '~/components/atoms/lists/FormsList';
import InvoiceDetailsForm from '~/components/atoms/forms/forms/InvoiceDetailsForm';
import PaymentMethodForm from '~/components/atoms/forms/forms/PaymentMethodForm';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsDeliveryAddressEnabled from '~/hooks/useAccountIsDeliveryAddressEnabled';
import useAccountIsExtraInvoiceDetailsEnabled from '~/hooks/useAccountIsExtraInvoiceDetailsEnabled';
import useAllowedPaymentMethods from '~/hooks/useAllowedPaymentMethods';
import useIsAccountUsingStripe from '~/hooks/useIsAccountUsingStripe';
import useLegacyBillingDetails from '~/hooks/useLegacyBillingDetails';

import {
	updateBillingDetails,
} from '~/actions/teams';

import {
	runAction,
} from '~/model/actions';

import {
	METHOD_CARD,
	METHOD_INVOICE,
	METHOD_PAYPAL,
} from '~/model/payments';

import {
	teamSelector,
} from '~/state/ui/selectors';



const BillingDetailsGroup: React.FC = () => {
	const accountId = useAccountId();

	const accountAllowedPaymentMethods = useAllowedPaymentMethods(accountId);
	const accountIsDeliveryAddressEnabled = useAccountIsDeliveryAddressEnabled(accountId);
	const accountIsExtraInvoiceDetailsEnabled = useAccountIsExtraInvoiceDetailsEnabled(accountId);
	const billingDetails = useLegacyBillingDetails(accountId);
	const dispatch = useDispatch();
	const team = useSelector(teamSelector);
	const isAccountUsingStripe = useIsAccountUsingStripe(accountId);

	const handleFormSubmitWithCreditCard = React.useCallback(
		(data) => {
			return dispatch(
				updateBillingDetails(
					accountId,
					{
						payment_method: {
							type: METHOD_CARD,
							token: data.values.recurlyToken,
							three_d_secure_result_token: data.values.threeDSecureToken || null,
						},
					},
				),
			);
		},
		[
			accountId,
			dispatch,
		],
	);

	const handleFormSubmitWithInvoice = React.useCallback(
		() => {
			return dispatch(
				updateBillingDetails(
					accountId,
					{
						payment_method: {
							type: METHOD_INVOICE,
						},
					},
				),
			);
		},
		[
			accountId,
			dispatch,
		],
	);

	const handleFormSubmitWithPaypal = React.useCallback(
		(data) => {
			return dispatch(
				updateBillingDetails(
					accountId,
					{
						payment_method: {
							type: METHOD_PAYPAL,
							token: data.values.recurlyToken,
						},
					},
				),
			);
		},
		[
			accountId,
			dispatch,
		],
	);

	const handleFormSubmitWithoutPaymentMethod = React.useCallback(
		(values) => {
			if (isAccountUsingStripe) {
				return runAction({
					action: 'StoreCustomerDetails',
					input: {
						accountId,
						customerDetails: {
							address: {
								address: values.model.address,
								city: values.model.city,
								country: values.model.country,
								name: values.model.name,
								postalCode: values.model.zip_code,
								state: values.model.state || null,
							},
							email: values.model.email,
							vatNumber: values.model.vatNumber || null,
						},
					},
				});
			}

			return dispatch(
				updateBillingDetails(
					accountId,
					{
						address: values.model.address,
						city: values.model.city,
						company_name: values.model.name,
						country: values.model.country,
						email: values.model.email,
						zip_code: values.model.zip_code,
						state: values.model.state || null,
						vat_number: values.model.vatNumber || null,
					},
				),
			);
		},
		[
			accountId,
			dispatch,
			isAccountUsingStripe,
		],
	);

	const handleFormSubmitInvoiceDetails = React.useCallback(
		(values) => {
			const deliveryAddressFields = [
				'address',
				'city',
				'company',
				'country',
				'first_name',
				'last_name',
				'state',
				'zip_code',
			];

			deliveryAddressFields.forEach((field) => {
				if (values[field] !== undefined) {
					if (values.delivery_address === undefined) {
						values.delivery_address = {};
					}

					values.delivery_address[field] = values[field];

					delete values[field];
				}
			});

			if (isAccountUsingStripe) {
				const customerDetails: any = {};

				if (accountIsDeliveryAddressEnabled) {
					customerDetails.deliveryAddress = {
						address: values.delivery_address.address,
						city: values.delivery_address.city,
						country: values.delivery_address.country,
						name: values.delivery_address.company,
						postalCode: values.delivery_address.zip_code,
						state: values.delivery_address.state ?? null,
					};
				}

				if (accountIsExtraInvoiceDetailsEnabled) {
					customerDetails.extraInvoiceDetails = {
						attn: values.attn,
						customerReference: values.customer_reference,
						purchaseOrderNumber: values.po_number,
						supplierNumber: values.supplier_number,
					};
				}

				return runAction({
					action: 'StoreCustomerDetails',
					input: {
						accountId,
						customerDetails,
					},
				});
			}

			return dispatch(
				updateBillingDetails(
					accountId,
					values,
				),
			);
		},
		[
			accountId,
			accountIsDeliveryAddressEnabled,
			accountIsExtraInvoiceDetailsEnabled,
			dispatch,
			isAccountUsingStripe,
		],
	);

	if (accountAllowedPaymentMethods === null || !billingDetails || isAccountUsingStripe === null) {
		return null;
	}

	return (
		<FormsList>
			<BillingDetailsForm
				billingDetails={billingDetails.get('details')}
				submitCallback={handleFormSubmitWithoutPaymentMethod}
				team={team}
			/>

			<PaymentMethodForm
				accountId={accountId}
				allowedPaymentMethods={accountAllowedPaymentMethods}
				billingDetails={billingDetails.get('details')}
				submitWithCreditCardCallback={handleFormSubmitWithCreditCard}
				submitWithInvoiceCallback={handleFormSubmitWithInvoice}
				submitWithPaypalCallback={handleFormSubmitWithPaypal}
			/>

			{(accountIsDeliveryAddressEnabled || accountIsExtraInvoiceDetailsEnabled) && (
				<InvoiceDetailsForm
					billingDetails={billingDetails.get('details')}
					submitCallback={handleFormSubmitInvoiceDetails}
					team={team}
				/>
			)}
		</FormsList>
	);
};



export default BillingDetailsGroup;
