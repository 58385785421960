import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MobileIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M17.25,0H6.75a2.5,2.5,0,0,0-2.5,2.5v19A2.5,2.5,0,0,0,6.75,24h10.5a2.5,2.5,0,0,0,2.5-2.5V2.5A2.5,2.5,0,0,0,17.25,0Zm.5,17.5a1.5,1.5,0,0,1-1.5,1.5H7.75a1.5,1.5,0,0,1-1.5-1.5V3.5A1.5,1.5,0,0,1,7.75,2h8.5a1.5,1.5,0,0,1,1.5,1.5Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MobileIconBuilder;
