/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateIndexNowCustomElementTriggersMutationVariables = GraphQL.Exact<{
  customElementNames: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateIndexNowCustomElementTriggersMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteIndexNowCustomElementTriggers: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly indexNowCustomElementTriggers: ReadonlyArray<string> } | null } } };


export const UpdateIndexNowCustomElementTriggersDocument = gql`
    mutation UpdateIndexNowCustomElementTriggers($customElementNames: [String!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteIndexNowCustomElementTriggers(
    customElementNames: $customElementNames
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        indexNowCustomElementTriggers
      }
    }
  }
}
    `;
export type UpdateIndexNowCustomElementTriggersMutationFn = Apollo.MutationFunction<UpdateIndexNowCustomElementTriggersMutation, UpdateIndexNowCustomElementTriggersMutationVariables>;

/**
 * __useUpdateIndexNowCustomElementTriggersMutation__
 *
 * To run a mutation, you first call `useUpdateIndexNowCustomElementTriggersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndexNowCustomElementTriggersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndexNowCustomElementTriggersMutation, { data, loading, error }] = useUpdateIndexNowCustomElementTriggersMutation({
 *   variables: {
 *      customElementNames: // value for 'customElementNames'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateIndexNowCustomElementTriggersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndexNowCustomElementTriggersMutation, UpdateIndexNowCustomElementTriggersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIndexNowCustomElementTriggersMutation, UpdateIndexNowCustomElementTriggersMutationVariables>(UpdateIndexNowCustomElementTriggersDocument, options);
      }
export type UpdateIndexNowCustomElementTriggersMutationHookResult = ReturnType<typeof useUpdateIndexNowCustomElementTriggersMutation>;
export type UpdateIndexNowCustomElementTriggersMutationResult = Apollo.MutationResult<UpdateIndexNowCustomElementTriggersMutation>;
export type UpdateIndexNowCustomElementTriggersMutationOptions = Apollo.BaseMutationOptions<UpdateIndexNowCustomElementTriggersMutation, UpdateIndexNowCustomElementTriggersMutationVariables>;