import classNames from 'classnames';
import React, {
	Component,
} from 'react';
import tinycolor from 'tinycolor2';

import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';



type Props = {
	color?: string,
	onClickCallback?: (e: React.MouseEvent) => void,
	tooltip?: React.ReactNode,
	value: number,
};



class NumberBadge extends Component<Props> {

	constructor(props, context) {
		super(props, context);

		this._handleBubbleClick = this._handleBubbleClick.bind(this);
	}



	_handleBubbleClick(e) {
		const {
			onClickCallback,
		} = this.props;

		e.preventDefault();
		e.stopPropagation();

		if (onClickCallback) {
			onClickCallback(e);
		}

		return false;
	}



	_getValue() {
		const {
			value,
		} = this.props;

		if (value > 99) {
			return '99+';
		}

		return value;
	}



	_renderBadgeHint(target, tooltip) {
		return (
			<Hint
				attachment={HintAttachment.Center}
				popup={tooltip}
			>
				{target}
			</Hint>
		);
	}



	_isColorTooLight(colorString) {
		const color = tinycolor(colorString);
		return color.getBrightness() > 180;
	}



	render() {
		const {
			color,
			onClickCallback,
			tooltip,
			value,
		} = this.props;

		const bubbleClasses = classNames({
			'number-badge__bubble': true,
			'number-badge__bubble--use-dark-font': color && this._isColorTooLight(color),
			'number-badge__bubble--is-clickable': onClickCallback,
		});

		const numberClasses = classNames({
			'number-badge__number': true,
			'number-badge__number--smaller-font': value > 99,
		});

		const bubbleStyle: React.CSSProperties = {};

		if (color) {
			bubbleStyle.backgroundColor = color;
		}

		let badge = (
			<span
				className={bubbleClasses}
				onClick={this._handleBubbleClick}
				style={bubbleStyle}
			>
				<span className={numberClasses}>
					{this._getValue()}
				</span>
			</span>
		);

		if (tooltip) {
			badge = this._renderBadgeHint(badge, tooltip);
		}

		return (
			<span className="number-badge">
				{badge}
			</span>
		);
	}

}



export default NumberBadge;
