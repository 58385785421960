import Immutable from 'immutable';

import {
	getDefaultFilter,
	loadAffectedPagesViaApi,
} from '~/model/affectedPages';

import {
	transformBooleanOrNAFilterForApi,
} from '~/model/filter';

import {
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';

import {
	categorySelector,
	dataSelector,
	filterSelector,
	singleTypeSelector,
	sortBySelector,
} from '~/state/affectedPages/selectors';

import {
	getPatch,
} from '~/utilities/coverage';



export const MARK_START_OF_LOADING = 'MARK_START_OF_LOADING';
export const STORE = 'AFFECTED_PAGES_STORE';
export const UPDATE_FILTER = 'AFFECTED_PAGES_UPDATE_FILTER';
export const UPDATE_SORT_BY = 'AFFECTED_PAGES_UPDATE_SORT_BY';



export function loadAffectedPages(offset, limit) {
	const prescanLimit = limit * (1 / 3);
	const pureOffset = offset - prescanLimit;

	offset = Math.max(0, pureOffset);

	return (dispatch, getState) => {
		const websiteId = selectedWebsiteIdSelector(getState());
		const issueCategory = categorySelector(getState());
		const issueType = singleTypeSelector(getState());

		if (!websiteId || issueCategory === undefined || issueCategory === null) {
			return Promise.resolve();
		}

		let filter = filterSelector(getState());
		const sortBy = sortBySelector(getState());

		const currentData = dataSelector(getState()).get(websiteId);

		if (!currentData) {
			return Promise.resolve();
		}

		const patch = getPatch(
			currentData.get('rangeInLoading'),
			offset,
			limit,
		);

		if (patch.coverSpan === 0) {
			return Promise.resolve();
		}

		if (patch.coverSpan < 50 && patch.isEdge === true && (patch.coverFrom + patch.coverSpan) < currentData.get('total')) {
			return Promise.resolve();
		}

		const originalOffset = offset;
		const originalLimit = limit;

		offset = patch.coverFrom;
		limit = patch.coverSpan;

		const params = {
			limit,
			offset,
		};

		filter = transformBooleanOrNAFilterForApi(filter);

		filter = filter.filter((value) => {
			return value !== null;
		});

		filter = filter.toJS();

		for (const key in filter) {
			if (key === 'xml_sitemap_status') {
				continue;
			}

			if (filter.hasOwnProperty(key)) {
				let apiKey = key;

				if (key === 'analytics_services') {
					apiKey = 'analytics_services_legacy';
				}

				if (key === 'visual_analytics_services') {
					apiKey = 'visual_analytics_services_legacy';
				}

				params['filter[' + apiKey + ']'] = filter[key];
			}
		}

		if (sortBy !== undefined && sortBy !== null) {
			params.sort = sortBy.get('key');
			params.direction = sortBy.get('direction') ? 'asc' : 'desc';
		}

		const initiatedAt = Date.now();

		dispatch({
			type: MARK_START_OF_LOADING,
			initiatedAt,
			offset: originalOffset,
			limit: originalLimit,
			websiteId,
		});

		if (issueType) {
			params.issue = issueType;
		} else {
			params.category = issueCategory;
		}

		if (filter['xml_sitemap_status'] && filter['xml_sitemap_status'].length === 1) {
			if (filter['xml_sitemap_status'].indexOf('incorrectly_present') !== -1) {
				params.issue = 'xml_sitemap_incorrectly_present';
			} else {
				params.issue = 'is_in_sitemap';
			}

			delete params.category;
		}

		return loadAffectedPagesViaApi(
			websiteId,
			params,
		).then((data) => {
			dispatch({
				type: STORE,
				initiatedAt,
				websiteId,
				total: data.count,
				offset,
				range: data.orderUrlUniqueId,
				pages: data.pages,
			});
		});
	};
}



export function updateFilter(filter) {
	return (dispatch, getState) => {
		const defaultFilter = getDefaultFilter(
			categorySelector(getState()),
			singleTypeSelector(getState()),
		);

		filter = Immutable.fromJS(filter).map((value, key) => {
			if (value === undefined || value === null) {
				return defaultFilter.get(key) || '';
			}

			return value;
		});

		dispatch({
			type: UPDATE_FILTER,
			filter,
		});

		return Promise.resolve();
	};
}



export function updateSortBy(sortBy) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SORT_BY,
			sortBy: Immutable.fromJS(sortBy),
		});

		return Promise.resolve();
	};
}
