import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';
import Ellipsis from '~/components/patterns/values/Ellipsis';



const messages = defineMessages({
	hreflang: {
		id: 'ui.invalidHreflangs.hreflang',
	},
	target: {
		id: 'ui.invalidHreflangs.target',
	},
});



type Props = {
	issueContext: {
		conflicts: ReadonlyArray<{
			elements: ReadonlyArray<{
				href: string,
			}>,
			hreflang: string,
		}>,
	},
	tableWidth: number,
};

const ConflictingHreflangTargetsTable: React.FC<Props> = (props) => {
	const {
		issueContext,
		tableWidth,
	} = props;

	const conflicts = issueContext.conflicts;

	const rows = React.useMemo(
		() => {
			const result: Array<{
				header: true,
				hreflang: string,
				conflictNumber: number,
			} | {
				href: string,
				hreflang: string,
			}> = [];

			conflicts.forEach((conflict, i) => {
				result.push({
					header: true,
					hreflang: conflict.hreflang,
					conflictNumber: i + 1,
				});

				conflict.elements.forEach((element) => {
					result.push({
						...element,
						hreflang: conflict.hreflang,
					});
				});
			});

			return result;
		},
		[
			conflicts,
		],
	);

	const firstColumnWidth = Math.floor(tableWidth * (1 / 3));
	const lastColumnWidth = tableWidth - firstColumnWidth - 2;

	return (
		<SmallTable
			columns={[
				{
					hasData: () => true,
					render: {
						cell: ({ row }) => {
							if ('header' in row) {
								return {
									colspan: 2,
									content: (
										<b>Hreflang attributes with value {row.hreflang}</b>
									),
								};
							}

							return (
								<Ellipsis>
									<CodeValue>
										{row.hreflang}
									</CodeValue>
								</Ellipsis>
							);
						},
						header: () => <FormattedMessage {...messages.hreflang} />,
					},
					width: firstColumnWidth,
				},
				{
					hasData: () => true,
					render: {
						cell: ({ row }) => {
							if ('header' in row) {
								return null;
							}

							return (
								<Ellipsis>
									{row.href}
								</Ellipsis>
							);
						},
						header: () => <FormattedMessage {...messages.target} />,
					},
					width: lastColumnWidth,
				},
			]}
			rows={rows}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(ConflictingHreflangTargetsTable);
