import React from 'react';

import CK from '~/types/contentking';

import ColumnName from '~/components/names/ColumnName';
import CodeSnippets from '~/components/patterns/structuredValues/CodeSnippets';
import InternalLink from '~/components/patterns/links/InternalLink';
import IssueCategoryDetail from '~/components/app/IssueCategoryDetail';
import PageIssueDetail from '~/components/logic/pageIssueDetail/PageIssueDetail';
import Value from '~/components/logic/pageIssueDetail/Value';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import useOpenedPageIssueCategory from '~/hooks/useOpenedPageIssueCategory';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	IssueCategoryName,
} from '~/model/issuesNew';



const PageIssuesCategory: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const pageIssueCategory = useOpenedPageIssueCategory(legacyUrlId, websiteId);

	return (
		<IssueCategoryDetail
			issueCategory={pageIssueCategory}
			renderCategoryIllustration={({ issueCategory }) => {
				if (issueCategory.name === IssueCategoryName.CanonicalLink) {
					const isLinked = (
						issueCategory.context.targetUrlId !== null
						&& !issueCategory.context.isCrossdomain
						&& issueCategory.context.targetUrlId !== legacyUrlId
					);

					return (
						<CodeSnippets
							snippets={[
								{
									label: (
										<ColumnName column={CK.PagesCommonColumn.CanonicalLinkElement} />
									),
									content: (
										<Value>
											{isLinked ? (
												<InternalLink
													routeName="website.pages.detail"
													routeParams={{
														websiteId,
														id: issueCategory.context.targetUrlId,
													}}
												>
													{issueCategory.context.href}
												</InternalLink>
											) : issueCategory.context.href}
										</Value>
									),
								},
							]}
						/>
					);
				}

				if (issueCategory.name === IssueCategoryName.H1) {
					return (
						<CodeSnippets
							snippets={[
								{
									label: (
										<ColumnName column={CK.PagesCommonColumn.H1} />
									),
									content: (
										<Value>
											{issueCategory.context.h1}
										</Value>
									),
								},
							]}
						/>
					);
				}

				if (issueCategory.name === IssueCategoryName.MetaDescription) {
					return (
						<CodeSnippets
							snippets={[
								{
									label: (
										<ColumnName column={CK.PagesCommonColumn.MetaDescription} />
									),
									content: (
										<Value>
											{issueCategory.context.metaDescription}
										</Value>
									),
								},
							]}
						/>
					);
				}

				if (issueCategory.name === IssueCategoryName.OpenGraph) {
					const snippets: Array<{
						content: React.ReactNode,
						label: React.ReactNode,
					}> = [];

					if (issueCategory.context.openGraphTitle !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.OpenGraphTitle} />
							),
							content: (
								<Value>
									{issueCategory.context.openGraphTitle}
								</Value>
							),
						});
					}

					if (issueCategory.context.openGraphDescription !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.OpenGraphDescription} />
							),
							content: (
								<Value>
									{issueCategory.context.openGraphDescription}
								</Value>
							),
						});
					}

					if (issueCategory.context.openGraphUrl !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.OpenGraphUrl} />
							),
							content: (
								<Value>
									{issueCategory.context.openGraphUrl}
								</Value>
							),
						});
					}

					if (issueCategory.context.openGraphImage !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.OpenGraphImage} />
							),
							content: (
								<Value>
									{issueCategory.context.openGraphImage}
								</Value>
							),
						});
					}

					if (issueCategory.context.openGraphType !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.OpenGraphType} />
							),
							content: (
								<Value>
									{issueCategory.context.openGraphType}
								</Value>
							),
						});
					}

					return (
						<CodeSnippets snippets={snippets} />
					);
				}

				if (issueCategory.name === IssueCategoryName.Title) {
					return (
						<CodeSnippets
							snippets={[
								{
									label: (
										<ColumnName column={CK.PagesCommonColumn.Title} />
									),
									content: (
										<Value>
											{issueCategory.context.title}
										</Value>
									),
								},
							]}
						/>
					);
				}

				if (issueCategory.name === IssueCategoryName.TwitterCards) {
					const snippets: Array<{
						content: React.ReactNode,
						label: React.ReactNode,
					}> = [];

					if (issueCategory.context.twitterCard !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.TwitterCard} />
							),
							content: (
								<Value>
									{issueCategory.context.twitterCard}
								</Value>
							),
						});
					}

					if (issueCategory.context.twitterTitle !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.TwitterTitle} />
							),
							content: (
								<Value>
									{issueCategory.context.twitterTitle}
								</Value>
							),
						});
					}

					if (issueCategory.context.twitterDescription !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.TwitterDescription} />
							),
							content: (
								<Value>
									{issueCategory.context.twitterDescription}
								</Value>
							),
						});
					}

					if (issueCategory.context.twitterSite !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.TwitterSite} />
							),
							content: (
								<Value>
									{issueCategory.context.twitterSite}
								</Value>
							),
						});
					}

					if (issueCategory.context.twitterImage !== undefined) {
						snippets.push({
							label: (
								<ColumnName column={CK.PagesCommonColumn.TwitterImage} />
							),
							content: (
								<Value>
									{issueCategory.context.twitterImage}
								</Value>
							),
						});
					}

					return (
						<CodeSnippets snippets={snippets} />
					);
				}

				return null;
			}}
			renderIssueDetail={({ issue, issueCategory }) => (
				<PageIssueDetail
					issue={issue}
					issueCategoryName={issueCategory.name}
					legacyUrlId={legacyUrlId}
					pageId={legacyUrlId}
					websiteId={websiteId}
				/>
			)}
			websiteId={websiteId}
		/>
	);
};



export default PageIssuesCategory;
