import React from 'react';



const PriceItemsDivider: React.FC = () => {
	return (
		<hr />
	);
};



export default PriceItemsDivider;
