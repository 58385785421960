import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';



function useLogFileAnalysisFeatureNudge(websiteId: CK.WebsiteId) {
	const {
		isFeatureAttainable,
		isFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const {
		hasAvailableLogSources,
		isLogFileAnalysisEnabled,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const nudgeLogFileAnalysis = (
		isFeatureEnabled
		&& !isLogFileAnalysisEnabled
		&& hasAvailableLogSources
	);

	const upsellLogFileAnalysis = (
		isFeatureAttainable
		&& !isFeatureEnabled
		&& hasAvailableLogSources
	);

	return {
		isFeatureEnabled,
		isLogFileAnalysisEnabled,
		nudgeLogFileAnalysis,
		upsellLogFileAnalysis,
	};
}



export default useLogFileAnalysisFeatureNudge;
