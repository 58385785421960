import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ContextNavigationButton, {
	ContextNavigationButtonIconType,
} from '~/components/patterns/navigations/ContextNavigationButton';
import DisableSubscriptionRenewalButton from '~/components/app/DisableSubscriptionRenewalButton';
import IntercomActivator from '~/components/logic/IntercomActivator';
import LinkTarget from '../../LinkTarget';
import List from '~/components/patterns/lists/List';
import ResumeSubscriptionRenewalButton from '~/components/app/ResumeSubscriptionRenewalButton';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountId from '~/hooks/useAccountId';
import useAccountIsSubscriptionRenewalEnabled from '~/hooks/useAccountIsSubscriptionRenewalEnabled';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountState from '~/hooks/useAccountState';
import useAccountType from '~/hooks/useAccountType';
import useAccountUnpaidSituation from '~/hooks/useAccountUnpaidSituation';
import useAccountWebsiteCount from '~/hooks/useAccountWebsiteCount';
import useInHomeAccount from '~/hooks/useInHomeAccount';

import {
	IsContentKingClient,
} from '~/config';



const messages = defineMessages({
	connectAgency: {
		id: 'ui.accountAccess.buttons.connectAgencyAccount',
	},
	requestTeamDeletion: {
		id: 'ui.teamDetail.accountSettings.requestTeamDeletion',
	},
});



const AccountSettingsContextActions: React.FC = () => {
	const accountId = useAccountId();
	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountIsSubscriptionRenewalEnabled = useAccountIsSubscriptionRenewalEnabled(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountState = useAccountState(accountId);
	const accountType = useAccountType(accountId);
	const accountUnpaidSituation = useAccountUnpaidSituation(accountId);
	const accountWebsiteCount = useAccountWebsiteCount(accountId);
	const inHomeAccount = useInHomeAccount();

	const canManageSubscriptionRenewalDisabling = (
		accountType === GraphQL.AccountType.Universal
		&& accountState !== GraphQL.AccountState.Churned
		&& accountUnpaidSituation === null
		&& accountWebsiteCount !== null
		&& accountWebsiteCount === 0
	);

	const canManageSubscriptionRenewalResuming = (
		accountType === GraphQL.AccountType.Universal
		&& accountState !== GraphQL.AccountState.Churned
		&& accountUnpaidSituation === null
	);

	const showConnectToAgencyButton = (
		inHomeAccount
		&& accountFeaturesUsage !== null
		&& accountFeaturesUsage.enabledFeatures.includes(GraphQL.AccountFeature.ConnectToAgency)
		&& !accountFeaturesUsage.usedFeatures.includes(GraphQL.AccountFeature.ConnectToAgency)
	);

	const showDisableSubscriptionRenewalButton = (
		accountPhase === GraphQL.AccountPhase.Customer
		&& canManageSubscriptionRenewalDisabling
		&& accountIsSubscriptionRenewalEnabled
	);

	const showResumeSubscriptionRenewalButton = (
		accountPhase === GraphQL.AccountPhase.Customer
		&& canManageSubscriptionRenewalResuming
		&& accountIsSubscriptionRenewalEnabled === false
	);

	const showRequestAccountDeletionButton = (
		accountPhase === GraphQL.AccountPhase.Customer
		&& (
			canManageSubscriptionRenewalDisabling === false
			|| accountIsSubscriptionRenewalEnabled === false
		)
		&& IsContentKingClient === true
	);

	const buttons: Array<React.ReactNode> = [];

	if (showConnectToAgencyButton) {
		buttons.push(
			<WhenAccountActionAllowed
				accountId={accountId}
				action={GraphQL.ActionWithAccount.ManageAgencyAccess}
				key="connectToAccount"
				showMessage={false}
			>
				{({ isAllowed, message }) => (
					<LinkTarget
						extraRouteParams={{
							action: 'connect_to_account',
						}}
					>
						{({ routeName, routeParams }) => (
							<ContextNavigationButton
								disabled={isAllowed.yes === false}
								iconType={ContextNavigationButtonIconType.Team}
								routeName={routeName}
								routeParams={routeParams}
								tooltip={isAllowed.yes === false && message}
							>
								<FormattedMessage {...messages.connectAgency} />
							</ContextNavigationButton>
						)}
					</LinkTarget>
				)}
			</WhenAccountActionAllowed>,
		);
	}

	if (showDisableSubscriptionRenewalButton) {
		buttons.push(
			<DisableSubscriptionRenewalButton
				key="disableSubscriptionRenewal"
			/>,
		);
	}

	if (showResumeSubscriptionRenewalButton) {
		buttons.push(
			<ResumeSubscriptionRenewalButton
				key="resumeSubscriptionRenewal"
			/>,
		);
	}

	if (showRequestAccountDeletionButton) {
		buttons.push(
			<IntercomActivator
				key="requestTeamDeletion"
			>
				<ContextNavigationButton
					iconType={ContextNavigationButtonIconType.Delete}
				>
					<FormattedMessage {...messages.requestTeamDeletion} />
				</ContextNavigationButton>
			</IntercomActivator>,
		);
	}

	if (buttons.length === 0) {
		return null;
	}

	return (
		<List>
			{buttons}
		</List>
	);
};



export default AccountSettingsContextActions;
