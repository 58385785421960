import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Possibility to show higher cell */
	higher?: boolean,
	/** Available labels */
	labels: Array<React.ReactNode>,
};



const PlansComparisonTableLabels: React.FC<Props> = (props) => {
	const {
		higher,
		labels,
	} = props;

	const componentClasses = classNames({
		'plan-comparison-table-labels': true,
		'plan-comparison-table-labels--higher': higher,
	});

	return (
		<div className={componentClasses}>
			{labels.map((label, i) => {
				return (
					<div
						className="plan-comparison-table-labels__label"
						key={'plan-comparison-table-label-' + i}
					>
						{label}
					</div>
				);
			})}
		</div>
	);
};



export default PlansComparisonTableLabels;
