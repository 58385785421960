import classNames from 'classnames';
import React from 'react';

import styles from './HintPopupLayout.module.scss';



type Props = {
	/** Optional call-to-action element visible in popup bubble */
	ctaElement?: React.ReactNode,
	gutter?: boolean,
	children?: React.ReactNode,
	maxHeight?: React.CSSProperties['maxHeight'],
};



const HintPopupLayout: React.FC<Props> = (props) => {
	const {
		ctaElement,
		gutter = true,
		children,
		maxHeight,
	} = props;

	const cssStyle: React.CSSProperties = {};

	if (maxHeight) {
		cssStyle.maxHeight = maxHeight;
	}

	return (
		<div
			className={styles.component}
			style={cssStyle}
		>
			<div className={styles.scrollableContainer}>
				<div
					className={classNames({
						[styles.content]: gutter,
					})}
				>
					{children && (
						<div className={styles.dialog}>
							{children}
						</div>
					)}

					{ctaElement && (
						<div className={styles.ctaElement}>
							{ctaElement}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};



export default HintPopupLayout;
