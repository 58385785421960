import Immutable, {
	Map,
} from 'immutable';

import {
	LOGOUT_SUCCESSFUL,
	STORE_UNIQUE_ELEMENT_CONTENT_URLS,
} from '~/actions';
import {
	trimHostname,
} from '~/model/utils';



function createDefaultState() {
	return new Map({
		domainId: undefined,
		urls: new Map(),
	});
}



export function duplicateContentUrls(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case STORE_UNIQUE_ELEMENT_CONTENT_URLS: {
			const {
				domainId,
				issueType,
				offset,
				pageId,
				urls,
			} = action;

			let newUrls = new Map();

			let currentOffset = offset;
			urls.forEach((urlData) => {
				urlData.url = trimHostname(urlData.url);
				newUrls = newUrls.set(currentOffset, Immutable.fromJS(urlData));
				currentOffset++;
			});

			const loadedDomainId = state.get('domainId');
			if (loadedDomainId !== undefined && loadedDomainId !== domainId) {
				state = createDefaultState();
			}

			state = state.set('domainId', domainId);
			state = state.mergeIn([
				'urls',
				pageId,
				issueType,
			], newUrls);

			break;
		}

	}

	return state;
}
