import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	buttonLabel: {
		id: 'ui.connectedAccounts.addAccount',
	},
});



type Props = {
	accountId: CK.AccountId | null,
};

const AddAccountHeaderButton: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	return (
		<WithPermission
			action={GraphQL.ActionWithAccount.ManageAgencyAccess}
			objectId={accountId}
			objectType={ObjectType.Account}
			showMessage={false}
		>
			{({ isAllowed, message: permissionMessage }) => (
				<Button
					disabled={isAllowed.yes === false}
					icon={(
						<BasicIcon type={BasicIconType.Plus} />
					)}
					iconColor={isAllowed.yes === false ? undefined : '#ffffff'}
					linkRouteName="account.connectedAccounts.connectAccount"
					linkRouteParams={{
						accountId,
					}}
					style={ButtonStyle.Action}
					tooltip={permissionMessage}
				>
					<FormattedMessage {...messages.buttonLabel} />
				</Button>
			)}
		</WithPermission>
	);
};



export default AddAccountHeaderButton;
