import classNames from 'classnames';
import React from 'react';



export enum CaptionStyle {
	Default = 'default',
	Highlighted = 'highlighted',
	Light = 'light',
}

type Props = {
	children?: React.ReactNode,
	style?: CaptionStyle,
};



const Caption: React.FC<Props> = (props) => {
	const {
		children,
		style = CaptionStyle.Default,
	} = props;

	const componentClasses = classNames({
		caption: true,
		[ 'caption--' + style + '-style' ]: true,
	});

	return (
		<h3 className={componentClasses}>
			{children}
		</h3>
	);
};



export default Caption;
