/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportCustomUrlsMutationVariables = GraphQL.Exact<{
  urlLines: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ImportCustomUrlsMutation = { readonly __typename?: 'Mutation', readonly ImportCustomUrls: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const ImportCustomUrlsDocument = gql`
    mutation ImportCustomUrls($urlLines: [String!]!, $websiteId: WebsiteId!) {
  ImportCustomUrls(urlLines: $urlLines, websiteId: $websiteId) {
    query {
      ping
    }
  }
}
    `;
export type ImportCustomUrlsMutationFn = Apollo.MutationFunction<ImportCustomUrlsMutation, ImportCustomUrlsMutationVariables>;

/**
 * __useImportCustomUrlsMutation__
 *
 * To run a mutation, you first call `useImportCustomUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCustomUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCustomUrlsMutation, { data, loading, error }] = useImportCustomUrlsMutation({
 *   variables: {
 *      urlLines: // value for 'urlLines'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useImportCustomUrlsMutation(baseOptions?: Apollo.MutationHookOptions<ImportCustomUrlsMutation, ImportCustomUrlsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCustomUrlsMutation, ImportCustomUrlsMutationVariables>(ImportCustomUrlsDocument, options);
      }
export type ImportCustomUrlsMutationHookResult = ReturnType<typeof useImportCustomUrlsMutation>;
export type ImportCustomUrlsMutationResult = Apollo.MutationResult<ImportCustomUrlsMutation>;
export type ImportCustomUrlsMutationOptions = Apollo.BaseMutationOptions<ImportCustomUrlsMutation, ImportCustomUrlsMutationVariables>;