import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AccountAdminCustomerPhaseForm from '~/components/app/AccountAdminCustomerPhaseForm';
import AccountAdminTrialPhaseForm from '~/components/app/AccountAdminTrialPhaseForm';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';

import useAccountPhase from '~/hooks/useAccountPhase';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	accountId: CK.AccountId,
};

const AccountAdminPhaseForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountPhase = useAccountPhase(accountId);

	const isAllowedToManageAdminPhaseSettings = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageAdminPhaseSettings,
	);

	if (accountPhase === null) {
		return null;
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageAdminPhaseSettings}
			isForAdmins={true}
			title="Account phase"
		>
			{matchAndReturn(accountPhase, {
				[GraphQL.AccountPhase.Customer]: (
					<AccountAdminCustomerPhaseForm
						accountId={accountId}
						accountPhase={accountPhase}
					/>
				),
				[GraphQL.AccountPhase.Trial]: (
					<AccountAdminTrialPhaseForm
						accountId={accountId}
						accountPhase={accountPhase}
					/>
				),
			})}
		</EditableFormWrapper>
	);
};



export default AccountAdminPhaseForm;
