import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import Form from '~/components/atoms/forms/basis/Form';
import MembersReportsAlertsFields, {
	MembersReportsAlertsFieldsCheckedState,
} from '~/components/atoms/forms/sharedFields/MembersReportsAlertsFields';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	onSubmitCallback,
	websiteEmailSettingsPerUser: ReadonlyArray<{
		alertEmailsSetup: GraphQL.AlertEmailsSetup,
		displayName: string,
		isWeeklyReportEnabled: boolean,
		userEmail: string,
		userUniqueId: CK.UserId,
	}> | null,
	websiteId: CK.WebsiteId | null,
};

const AddWebsiteMembersReportsAlertsForm: React.FC<Props> = (props) => {
	const {
		onSubmitCallback,
		websiteEmailSettingsPerUser,
		websiteId,
	} = props;

	const handleSubmit = React.useCallback(
		(data) => {
			const newEmailSettings: Array<{
				alerts: string,
				email: string,
				reports: boolean,
			}> = [];

			if (websiteEmailSettingsPerUser === null) {
				return newEmailSettings;
			}

			websiteEmailSettingsPerUser.forEach((user) => {
				if (data.assignedMembers.disabled[user.userEmail]) {
					return;
				}

				let alertsStatus = GraphQL.AlertEmailsSetup.Some;

				if (data.assignedMembers.alerts[user.userEmail] === true) {
					alertsStatus = GraphQL.AlertEmailsSetup.All;
				} else if (data.assignedMembers.alerts[user.userEmail] === false) {
					alertsStatus = GraphQL.AlertEmailsSetup.None;
				}

				newEmailSettings.push({
					email: user.userEmail,
					alerts: alertsStatus,
					reports: data.assignedMembers.weeklyReports[user.userEmail],
				});
			});

			return onSubmitCallback({
				emailSettings: newEmailSettings,
			});
		},
		[
			onSubmitCallback,
			websiteEmailSettingsPerUser,
		],
	);

	const assignedMembers = {
		alerts: {},
		disabled: {},
		weeklyReports: {},
	};

	if (websiteEmailSettingsPerUser !== null) {
		websiteEmailSettingsPerUser.forEach((user) => {
			assignedMembers.alerts[user.userEmail] = matchAndReturn(user.alertEmailsSetup, {
				[GraphQL.AlertEmailsSetup.All]: true,
				[GraphQL.AlertEmailsSetup.None]: false,
				[GraphQL.AlertEmailsSetup.Some]: MembersReportsAlertsFieldsCheckedState.Indeterminate,
			});
			assignedMembers.weeklyReports[user.userEmail] = user.isWeeklyReportEnabled;
		});
	}

	return (
		<Form
			defaultDataHasChanged={true}
			defaultFocus="assignedMembers"
			defaultValues={{
				assignedMembers,
			}}
			isDisabled={websiteEmailSettingsPerUser === null}
			key={websiteEmailSettingsPerUser !== null ? 'ready' : 'loading'}
			onSuccess={handleSubmit}
		>
			<div
				style={{
					minHeight: 376,
				}}
			>
				<MembersReportsAlertsFields
					isLoaded={websiteEmailSettingsPerUser !== null}
					membersList={websiteEmailSettingsPerUser?.map(
						(user) => ({
							label: user.displayName,
							name: user.userEmail,
							uniqueUserId: user.userUniqueId,
						}),
					) ?? []}
					name="assignedMembers"
					websiteId={websiteId}
				/>
			</div>

			<ButtonsLayout layout={ButtonsLayoutType.Steps}>
				<SaveSubmitButton />
			</ButtonsLayout>
		</Form>
	);
};



export default AddWebsiteMembersReportsAlertsForm;
