/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserCustomerTeamContextDetailsQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserCustomerTeamContextDetailsQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly customerTeamContext: { readonly __typename?: 'CustomerTeamContext', readonly details: Record<string, any> | null } | null } | null };


export const UserCustomerTeamContextDetailsDocument = gql`
    query UserCustomerTeamContextDetails($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    customerTeamContext {
      details
    }
    id
    legacyId
  }
}
    `;

/**
 * __useUserCustomerTeamContextDetailsQuery__
 *
 * To run a query within a React component, call `useUserCustomerTeamContextDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCustomerTeamContextDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCustomerTeamContextDetailsQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserCustomerTeamContextDetailsQuery(baseOptions: Apollo.QueryHookOptions<UserCustomerTeamContextDetailsQuery, UserCustomerTeamContextDetailsQueryVariables> & ({ variables: UserCustomerTeamContextDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCustomerTeamContextDetailsQuery, UserCustomerTeamContextDetailsQueryVariables>(UserCustomerTeamContextDetailsDocument, options);
      }
export function useUserCustomerTeamContextDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCustomerTeamContextDetailsQuery, UserCustomerTeamContextDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCustomerTeamContextDetailsQuery, UserCustomerTeamContextDetailsQueryVariables>(UserCustomerTeamContextDetailsDocument, options);
        }
export function useUserCustomerTeamContextDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserCustomerTeamContextDetailsQuery, UserCustomerTeamContextDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserCustomerTeamContextDetailsQuery, UserCustomerTeamContextDetailsQueryVariables>(UserCustomerTeamContextDetailsDocument, options);
        }
export type UserCustomerTeamContextDetailsQueryHookResult = ReturnType<typeof useUserCustomerTeamContextDetailsQuery>;
export type UserCustomerTeamContextDetailsLazyQueryHookResult = ReturnType<typeof useUserCustomerTeamContextDetailsLazyQuery>;
export type UserCustomerTeamContextDetailsSuspenseQueryHookResult = ReturnType<typeof useUserCustomerTeamContextDetailsSuspenseQuery>;
export type UserCustomerTeamContextDetailsQueryResult = Apollo.QueryResult<UserCustomerTeamContextDetailsQuery, UserCustomerTeamContextDetailsQueryVariables>;