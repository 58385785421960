import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import BackButton from '~/components/app/BackButton';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import DestructConfirmationModalForm from '~/components/app/DestructConfirmationModalForm';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';
import Measurer from '~/utilities/Measurer';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalPanel, {
	SIZE_MEDIUM as MODAL_SIZE_MEDIUM,
} from '~/components/atoms/panels/ModalPanel';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';
import SubmitButton from '~/components/app/SubmitButton';
import SwitchField from '~/components/app/SwitchField';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useConnectConductorOrganizationMutation,
	useValidateConductorOrganizationConnectionMutation,
} from './AdminConnectConductorOrganizationModal.gql';

import useAccountConfirmationName from '~/hooks/useAccountConfirmationName';
import useModals from '~/hooks/useModals';



const validations = {
	validateConductorAccountId: validateField(
		'conductorAccountId',
		(f) => [
			f.validateInteger(),
			f.validateNonEmpty(),
		],
	),
};



type Props = {
	accountId: CK.AccountId | null,
};

const AdminConnectConductorOrganizationModal: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountConfirmationName = useAccountConfirmationName(accountId);
	const modals = useModals();
	const multiStepModalRef = React.useRef<MultiStepModalRef | null>(null);

	const [connectConductorOrganizationData, setConnectConductorOrganizationData] = React.useState<{
		addedConductorUsers: ReadonlyArray<{
			email: string,
			userRole: GraphQL.UserRole,
		}>,
		conductorAccountId: number,
		conductorOrganizationName: string,
		removedUsers: ReadonlyArray<{
			email: string,
		}>,
		stayingUsers: ReadonlyArray<{
			email: string,
			role: GraphQL.UserRole,
		}>,
	} | null>(null);

	const [connectConductorOrganization] = useConnectConductorOrganizationMutation();
	const [validateConductorOrganizationConnection] = useValidateConductorOrganizationConnectionMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (accountId === null || connectConductorOrganizationData === null) {
				return;
			}

			await connectConductorOrganization({
				variables: {
					accountId,
					conductorAccountId: connectConductorOrganizationData.conductorAccountId,
					sendWelcomeEmails: values.sendWelcomeEmails,
				},
			});
		},
		[
			accountId,
			connectConductorOrganization,
			connectConductorOrganizationData,
		],
	);

	const handleValidateConductorOrganizationConnection = React.useCallback(
		async (values) => {
			if (accountId === null) {
				return;
			}

			const { data } = await validateConductorOrganizationConnection({
				variables: {
					accountId,
					conductorAccountId: values.conductorAccountId,
				},
			});

			if (data === undefined || data === null) {
				return;
			}

			setConnectConductorOrganizationData({
				addedConductorUsers: data.ValidateConductorOrganizationConnection.addedConductorUsers,
				conductorAccountId: values.conductorAccountId,
				conductorOrganizationName: data.ValidateConductorOrganizationConnection.conductorOrganization.name,
				removedUsers: data.ValidateConductorOrganizationConnection.removedUsers,
				stayingUsers: data.ValidateConductorOrganizationConnection.keptUsers.map(
					(membership) => ({
						email: membership.user.email,
						role: membership.role,
					}),
				),
			});

			multiStepModalRef.current?.goToNextStep();
		},
		[
			accountId,
			multiStepModalRef,
			validateConductorOrganizationConnection,
		],
	);

	return (
		<ModalPanel
			onCloseCallback={modals.closeCallback}
			size={MODAL_SIZE_MEDIUM}
		>
			<MultiStepModal
				ref={multiStepModalRef}
			>
				<MultiStepModalStep
					name="validate-conductor-organization"
					title="Connect Conductor organization"
				>
					<ModalTextSection>
						<p>After connecting this account to a Conductor organization:</p>
						<ul>
							<li>All users in this account will be replaced by the users in the Conductor organization, and</li>
							<li>Users will be able to login to this account only via Conductor</li>
						</ul>
						<p>If you want to proceed, enter the account ID of any account in the Conductor organization you want to connect.</p>
					</ModalTextSection>

					<Form
						onSuccess={handleValidateConductorOrganizationConnection}
						validations={validations}
					>
						<CenteredFormWrapper>
							<FormRows>
								<FormRow
									htmlFor="conductorAccountId"
									label="Conductor Account ID"
								>
									<FieldStatus name="validateConductorAccountId">
										<TextField name="conductorAccountId" />
									</FieldStatus>
								</FormRow>
							</FormRows>

							<FormErrorMessages
								errors={{
									accountAlreadyConnectedToOrganization: `That Conductor organization is already connected to a different ContentKing account.`,
									organizationDoesNotExist: `This account ID is not linked with any Conductor organization.`,
									organizationDoesNotHavePermission: `The CONTENTKING feature flag is not enabled for this Conductor account. Please enable it first and try again.`,
								}}
							/>

							<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
								<CancelButton />

								<SubmitButton>
									Next
								</SubmitButton>
							</ModalButtonsLayout>
						</CenteredFormWrapper>
					</Form>
				</MultiStepModalStep>

				<MultiStepModalStep
					name="validate-accounts-names"
					title="Connect Conductor organization"
				>
					<DestructConfirmationModalForm
						confirmButton="Next"
						confirmationPhrase={connectConductorOrganizationData?.conductorOrganizationName ?? ''}
						description={(
							<p>Are you sure you are connecting the right accounts?</p>
						)}
					>
						<p>This action will <b>connect</b> the following ContentKing account and Conductor organization:</p>

						<LabeledValuesBox
							items={[
								{
									label: 'CONTENTKING ACCOUNT',
									value: accountConfirmationName,
								},
								{
									label: 'CONDUCTOR ORGANIZATION',
									value: connectConductorOrganizationData?.conductorOrganizationName,
								},
							]}
						/>
					</DestructConfirmationModalForm>
				</MultiStepModalStep>

				{connectConductorOrganizationData !== null && connectConductorOrganizationData.removedUsers.length > 0 && (
					<MultiStepModalStep
						name="confirm-removed-users"
						title="Connect Conductor organization"
					>
						<DestructConfirmationModalForm
							confirmButton="Next"
							confirmationPhrase={`DELETE ${connectConductorOrganizationData.removedUsers.length} team members`}
						>
							<p>These team members will be <b>PERMANENTLY REMOVED</b> from this ContentKing account:</p>

							<Measurer>
								{({ containerWidth }) => {
									return (
										<SmallTable
											columns={[
												{
													render: {
														cell: ({ row }) => (
															<Ellipsis>
																{row.email}
															</Ellipsis>
														),
														header: 'Team member',
													},
													width: containerWidth - 2,
												},
											]}
											key={containerWidth}
											rows={connectConductorOrganizationData.removedUsers}
											tableWidth={containerWidth}
										/>
									);
								}}
							</Measurer>

							<p>Are you sure they should lose access to this ContentKing account? To prevent that, you should add them to that Conductor organization before connecting it to this ContentKing account.</p>
						</DestructConfirmationModalForm>
					</MultiStepModalStep>
				)}

				{connectConductorOrganizationData !== null && connectConductorOrganizationData.addedConductorUsers.length > 0 && (
					<MultiStepModalStep
						name="confirm-added-users"
						title="Connect Conductor organization"
					>
						<DestructConfirmationModalForm
							confirmButton="Next"
							confirmationPhrase={`ADD ${connectConductorOrganizationData.addedConductorUsers.length} team members`}
						>
							<p>These team members will be <b>ADDED</b> to this ContentKing account:</p>

							<Measurer>
								{({ containerWidth }) => {
									return (
										<SmallTable
											columns={[
												{
													render: {
														cell: ({ row }) => (
															<Ellipsis>
																{row.email}
															</Ellipsis>
														),
														header: 'Team member',
													},
													width: containerWidth - 2,
												},
											]}
											key={containerWidth}
											rows={connectConductorOrganizationData.addedConductorUsers}
											tableWidth={containerWidth}
										/>
									);
								}}
							</Measurer>

							<p>Are you sure they should have access to this ContentKing account? This account might contain confidential information, such as Google Analytics or Search Console data, and these users will get access to it.</p>
						</DestructConfirmationModalForm>
					</MultiStepModalStep>
				)}

				{connectConductorOrganizationData !== null && (
					<MultiStepModalStep
						name="connect-conductor-organization"
						title="Connect Conductor organization"
					>
						<ModalTextSection>
							<p>After connecting, these team members will be in this ContentKing account:</p>
						</ModalTextSection>

						<Form
							defaultDataHasChanged={true}
							defaultValues={{
								sendWelcomeEmails: false,
							}}
							onSuccess={handleSubmit}
						>
							<Measurer>
								{({ containerWidth }) => {
									const firstColumnWidth = Math.floor(containerWidth * 0.5) - 2;
									const secondColumnWidth = containerWidth - firstColumnWidth - 2;

									return (
										<SmallTable
											columns={[
												{
													render: {
														cell: ({ row }) => (
															<Ellipsis>
																{row.email}
															</Ellipsis>
														),
														header: 'Team member',
													},
													width: firstColumnWidth,
												},
												{
													render: {
														cell: ({ row }) => (
															<Ellipsis>
																{'userRole' in row ? row.userRole : row.role}
															</Ellipsis>
														),
														header: 'Role',
													},
													width: secondColumnWidth,
												},
											]}
											key={containerWidth}
											rows={[
												...connectConductorOrganizationData.addedConductorUsers,
												...connectConductorOrganizationData.stayingUsers,
											]}
											tableWidth={containerWidth}
										/>
									);
								}}
							</Measurer>

							<ModalTextSection>
								<p>Do you want to notify them about this connection?</p>
							</ModalTextSection>

							<CenteredFormWrapper>
								<FormRows>
									<FormRow
										htmlFor="sendWelcomeEmails"
										label="Notify team members"
									>
										<SwitchField
											activeStateLabel="yes"
											inactiveStateLabel="no"
											name="sendWelcomeEmails"
										/>
									</FormRow>
								</FormRows>

								<FormErrorMessages
									errors={{
										accountAlreadyConnectedToOrganization: `That Conductor organization is already connected to a different ContentKing account.`,
										organizationDoesNotExist: `This account ID is not linked with any Conductor organization.`,
										organizationDoesNotHavePermission: `The CONTENTKING feature flag is not enabled for this Conductor account. Please enable it first and try again.`,
									}}
								/>

								<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
									<BackButton />

									<SubmitButton
										positive={false}
									>
										Connect Conductor organization
									</SubmitButton>
								</ModalButtonsLayout>
							</CenteredFormWrapper>
						</Form>
					</MultiStepModalStep>
				)}
			</MultiStepModal>
		</ModalPanel>
	);
};



export default AdminConnectConductorOrganizationModal;
