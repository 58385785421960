import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const GlobeIconBuilder: IconBuilder = {
	icon: ({ color = '#5C6773' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="Segment-Labels/website"
					transform="translate(-1.000000, -1.000000)"
				>
					<g
						id="Segment"
						transform="translate(0.928571, 0.928571)"
					>
						<g id="globe">
							<path
								d="M12.0714286,20.3705357 C9.57113393,20.3705357 7.54464286,16.6547991 7.54464286,12.0714286 C7.54464286,7.48805804 9.57113393,3.77232143 12.0714286,3.77232143 C14.5717232,3.77232143 16.5982143,7.48805804 16.5982143,12.0714286 C16.5982143,16.6547991 14.5717232,20.3705357 12.0714286,20.3705357 L12.0714286,20.3705357 Z"
								id="Stroke-1610"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
							>
							</path>
							<path
								d="M3.77232143,12.0714286 L20.3705357,12.0714286"
								id="Stroke-1611"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
							>
							</path>
							<path
								d="M5.16287455,7.54464286 L19.0027674,7.54464286"
								id="Stroke-1612"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
							>
							</path>
							<path
								d="M12.0714286,3.77232143 L12.0714286,20.3705357"
								id="Stroke-1613"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
							>
							</path>
							<path
								d="M20.3705357,12.0714286 C20.3705357,16.6547991 16.6547991,20.3705357 12.0714286,20.3705357 C7.48805804,20.3705357 3.77232143,16.6547991 3.77232143,12.0714286 C3.77232143,7.48805804 7.48805804,3.77232143 12.0714286,3.77232143 C16.6547991,3.77232143 20.3705357,7.48805804 20.3705357,12.0714286 L20.3705357,12.0714286 Z"
								id="Stroke-1614"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
							>
							</path>
							<path
								d="M5.16287455,16.5982143 L19.0027674,16.5982143"
								id="Stroke-1615"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
							>
							</path>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default GlobeIconBuilder;

