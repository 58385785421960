import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import touchSupported from '~/utilities/touchSupported';

import AddExclusionRuleForm from '../forms/forms/AddExclusionRuleForm';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import DatatableBodyCell, {
	DatatableBodyCellColorStyle,
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableHeaderCell, {
	DatatableHeaderCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';
import DatatableLayout from '~/components/patterns/tables/datatables/DatatableLayout';
import DatatableOverlay, {
	DatatableOverlayStyle,
} from '~/components/patterns/tables/datatables/DatatableOverlay';
import FixedHeaderGrid from '~/components/patterns/tables/datatables/FixedHeaderGrid';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';



const messages = defineMessages({
	rulesTitle: {
		id: 'ui.websites.exclusionsModal.importer.rule',
	},
});



export const HEADER_HEIGHT = 32;
const ROW_HEIGHT = 40;
const TABLE_HEIGHT = 330;
const DELETION_CELL_WIDTH = 38;

class ExclusionRulesDataTable extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			highlightedRow: null,
			scrollTop: 0,
		};
	}



	_handleTableMouseEnter(rowIndex) {
		this.setState({
			highlightedRow: rowIndex,
		}, () => {
			this.refs.Grid.forceUpdate();
		});
	}



	_handleTableMouseLeave(rowIndex) {
		const {
			highlightedRow,
		} = this.state;

		if (highlightedRow === rowIndex) {
			this.setState({
				highlightedRow: null,
			}, () => {
				this.refs.Grid.forceUpdate();
			});
		}
	}



	_getColumnWidth({ index }) {
		const {
			rules,
			tableWidth,
		} = this.props;

		if (index === 1) {
			return DELETION_CELL_WIDTH;
		}

		let widthOffset = 0;

		if (rules && rules.size > 7) {
			widthOffset = scrollbarSize();
		}

		return tableWidth - DELETION_CELL_WIDTH - widthOffset;
	}



	_handleToggleRule(index) {
		const {
			onToggleRule,
		} = this.props;

		onToggleRule(index);
	}



	_renderCell({ columnIndex, key, rowIndex, style }) {
		const {
			highlightedRow,
		} = this.state;

		const {
			rules,
		} = this.props;

		let cellContent;

		if (columnIndex === 1) {
			const isUndoButton = rules.get(rowIndex).get('deleted') === true;

			if (touchSupported || highlightedRow === rowIndex || isUndoButton) {
				cellContent = (
					<Button
						icon={(
							<BasicIcon
								color="#A5A5A5"
								size={16}
								type={isUndoButton ? BasicIconType.DeleteUndo : BasicIconType.Delete}
							/>
						)}
						onClick={this._handleToggleRule.bind(this, rowIndex)}
						size={ButtonSize.XXSmall}
						style={ButtonStyle.Hollow}
					/>
				);
			}
		} else {
			cellContent = rules.get(rowIndex).get('rule');
		}

		let colorStyle;

		if (rules.get(rowIndex).get('unsaved') === true && rules.get(rowIndex).get('deleted') !== true) {
			colorStyle = DatatableBodyCellColorStyle.Added;
		}

		if (rules.get(rowIndex).get('deleted') === true) {
			colorStyle = DatatableBodyCellColorStyle.Removed;
		}

		return (
			<DatatableBodyCell
				colorStyle={colorStyle}
				cssStyle={style}
				isInHighlightedRow={highlightedRow === rowIndex}
				key={key}
				onMouseEnterCallback={this._handleTableMouseEnter.bind(this, rowIndex)}
				onMouseLeaveCallback={this._handleTableMouseLeave.bind(this, rowIndex)}
				rowIndex={rowIndex}
				size={DatatableBodyCellSize.Small}
			>
				{cellContent}
			</DatatableBodyCell>
		);
	}



	_renderHeader({ columnIndex }) {
		let cellContent;

		if (columnIndex === 0) {
			cellContent = (
				<FormattedMessage {...messages.rulesTitle} />
			);
		}

		return (
			<DatatableHeaderCell
				key={'header_' + columnIndex}
				separator={columnIndex !== 0}
				size={DatatableHeaderCellSize.Small}
				width={this._getColumnWidth({ index: columnIndex })}
			>
				{cellContent}
			</DatatableHeaderCell>
		);
	}



	render() {
		const {
			scrollTop,
		} = this.state;

		const {
			formSubmitCallback,
			isLoading,
			rules,
			tableWidth,
		} = this.props;

		return (
			<DatatableLayout
				footerCTA={(
					<AddExclusionRuleForm submitCallback={formSubmitCallback} />
				)}
			>
				<DatatableContainer
					overlay={isLoading && (
						<DatatableOverlay
							datatableHeaderHeight={HEADER_HEIGHT}
							style={DatatableOverlayStyle.Disabled}
						>
							<LoadingDots />
						</DatatableOverlay>
					)}
				>
					<FixedHeaderGrid
						bodyCellRenderer={this._renderCell.bind(this)}
						columnCount={2}
						columnWidth={this._getColumnWidth.bind(this)}
						headerCellRenderer={this._renderHeader.bind(this)}
						headerHeight={HEADER_HEIGHT}
						height={TABLE_HEIGHT}
						overscanColumnCount={2}
						ref="Grid"
						rowCount={rules ? rules.size : 0}
						rowHeight={ROW_HEIGHT}
						scrollTop={scrollTop - TABLE_HEIGHT > -ROW_HEIGHT ? scrollTop : 0}
						width={tableWidth}
					/>
				</DatatableContainer>
			</DatatableLayout>
		);
	}

}

ExclusionRulesDataTable.defaultProps = {
	isLoading: false,
};

ExclusionRulesDataTable.propTypes = {
	formSubmitCallback: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	rules: PropTypes.object,
	onToggleRule: PropTypes.func.isRequired,
	tableWidth: PropTypes.number.isRequired,
};



export default ExclusionRulesDataTable;
