/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageIsUnreliableQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageIsUnreliableQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly isUnreliable: boolean, readonly lastUnreliableResponse: GraphQL.FetchingFailureReason | null } | null };


export const PageIsUnreliableDocument = gql`
    query PageIsUnreliable($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    isUnreliable
    lastUnreliableResponse
  }
}
    `;

/**
 * __usePageIsUnreliableQuery__
 *
 * To run a query within a React component, call `usePageIsUnreliableQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageIsUnreliableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageIsUnreliableQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageIsUnreliableQuery(baseOptions: Apollo.QueryHookOptions<PageIsUnreliableQuery, PageIsUnreliableQueryVariables> & ({ variables: PageIsUnreliableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageIsUnreliableQuery, PageIsUnreliableQueryVariables>(PageIsUnreliableDocument, options);
      }
export function usePageIsUnreliableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageIsUnreliableQuery, PageIsUnreliableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageIsUnreliableQuery, PageIsUnreliableQueryVariables>(PageIsUnreliableDocument, options);
        }
export function usePageIsUnreliableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageIsUnreliableQuery, PageIsUnreliableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageIsUnreliableQuery, PageIsUnreliableQueryVariables>(PageIsUnreliableDocument, options);
        }
export type PageIsUnreliableQueryHookResult = ReturnType<typeof usePageIsUnreliableQuery>;
export type PageIsUnreliableLazyQueryHookResult = ReturnType<typeof usePageIsUnreliableLazyQuery>;
export type PageIsUnreliableSuspenseQueryHookResult = ReturnType<typeof usePageIsUnreliableSuspenseQuery>;
export type PageIsUnreliableQueryResult = Apollo.QueryResult<PageIsUnreliableQuery, PageIsUnreliableQueryVariables>;