import React from 'react';

import CustomerDetailsBlock from '~/components/app/CustomerDetailsBlock';
import DeliveryAddressBlock from '~/components/app/DeliveryAddressBlock';
import ExtraInvoiceDetailsBlock from '~/components/app/ExtraInvoiceDetailsBlock';
import FormsList from '~/components/atoms/lists/FormsList';
import PaymentMethodBlock from '~/components/app/PaymentMethodBlock';
import PaymentStatusBanner from '~/components/app/PaymentStatusBanner';

import {
	useBillingDetailsGroupQuery,
} from './BillingDetailsGroup.gql';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsDeliveryAddressEnabled from '~/hooks/useAccountIsDeliveryAddressEnabled';
import useAccountIsExtraInvoiceDetailsEnabled from '~/hooks/useAccountIsExtraInvoiceDetailsEnabled';



const BillingDetailsGroup: React.FC = () => {
	const accountId = useAccountId();

	const { data } = useBillingDetailsGroupQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const isDeliveryAddressEnabled = useAccountIsDeliveryAddressEnabled(accountId) ?? false;
	const isExtraInvoiceDetailsEnabled = useAccountIsExtraInvoiceDetailsEnabled(accountId) ?? false;

	const customerAddress = data?.account?.billingDetails?.customerAddress ?? null;
	const deliveryAddress = data?.account?.billingDetails?.deliveryAddress ?? null;
	const emails = data?.account?.billingDetails?.emails ?? null;
	const extraInvoiceDetails = data?.account?.billingDetails?.extraInvoiceDetails ?? null;
	const vatNumber = data?.account?.billingDetails?.vatNumber ?? null;

	return (
		<FormsList>
			{accountId !== null && (
				<PaymentStatusBanner
					accountId={accountId}
				/>
			)}

			<CustomerDetailsBlock
				accountId={accountId}
				customerAddress={customerAddress}
				emails={emails}
				vatNumber={vatNumber}
			/>

			{accountId !== null && (
				<PaymentMethodBlock
					accountId={accountId}
					country={customerAddress?.country ?? null}
					emails={emails}
				/>
			)}

			{accountId !== null && isExtraInvoiceDetailsEnabled && (
				<ExtraInvoiceDetailsBlock
					accountId={accountId}
					extraInvoiceDetails={extraInvoiceDetails}
				/>
			)}

			{accountId !== null && isDeliveryAddressEnabled && (
				<DeliveryAddressBlock
					accountId={accountId}
					deliveryAddress={deliveryAddress}
				/>
			)}
		</FormsList>
	);
};



export default BillingDetailsGroup;
