import type Immutable from 'immutable';

import CK from '~/types/contentking';



export function transformBooleanOrNAFilterForApi(filter: Immutable.Map<any, any>) {
	const booleanOrNAColumns = [
		CK.PagesCommonColumn.IsIndexableDueToMetaRobots,
		CK.PagesCommonColumn.IsIndexableDueToXRobotsTag,
	];

	booleanOrNAColumns.forEach((columnName) => {
		const value = filter.get(columnName);

		if (!value) {
			return;
		}

		if (value.size <= 2 && value.contains('not_applicable')) {
			filter = filter.set(columnName, value.filter((x) => x !== 'not_applicable'));
		} else if (value.size === 2 && !value.contains('not_applicable')) {
			filter = filter.set(columnName, value.push('not_applicable'));
		}
	});

	return filter;
}
