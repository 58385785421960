/**
 * Get value depends on score condition.
 *
 * We have defined ranges for whole detection.
 */

export function getValueByScoreCondition(value, maxValue, classNames) {
	const scorePercentage = parseInt(
		value / maxValue * 100,
		10,
	);

	if (scorePercentage === 100) {
		return classNames.good;
	} else if (scorePercentage > 90 && scorePercentage < 100) {
		return classNames.fine;
	} else if (scorePercentage > 70 && scorePercentage <= 90) {
		return classNames.normal;
	} else if (scorePercentage > 40 && scorePercentage <= 70) {
		return classNames.worse;
	} else if (scorePercentage <= 40) {
		return classNames.bad;
	}
}



export const SCORE_CONDITION_COLORS = {
	good: '#42cc67',
	fine: '#ffb131',
	normal: '#ff9830',
	worse: '#ff7437',
	bad: '#ff5959',
};
