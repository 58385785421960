import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



export const SIZE_DEFAULT = 'default';
export const SIZE_SMALL = 'small';
export const ITEM_STYLE_TAG = 'tag';
export const ITEM_STYLE_TEXTUAL = 'textual';



class PureRadioFields extends Component {

	_handleFieldClick(index, value) {
		this['inputElement' + index].click();

		const {
			onClickCallback,
		} = this.props;

		if (onClickCallback) {
			onClickCallback(value);
		}
	}



	_wrapPropertyGetter(value) {
		return value instanceof Function
			? value
			: () => value;
	}



	_wrapItemClassNameGetter(itemClassName, value, checked, disabled, readonly) {
		return this._wrapPropertyGetter(itemClassName).call(this, value, checked, disabled, readonly);
	}



	_renderRadioField(itemData, index) {
		const {
			fieldClassName,
			isControlled,
			itemStyle,
			name,
			onBlurCallback,
			onChangeCallback,
			onFocusCallback,
			onMouseEnterCallback,
			onMouseLeaveCallback,
			onMouseOverCallback,
			size,
			stretchedFields,
		} = this.props;

		const isChecked = itemData.checked;

		const radioFieldClasses = classNames({
			'radio-field': true,
			'radio-field--is-disabled': itemData.disabled,
			'radio-field--is-readonly': itemData.readonly,
			'radio-field--is-checked': isChecked,
			'radio-field--small': size === SIZE_SMALL,
			[ 'radio-field--' + itemStyle + '-style' ]: true,
		}, fieldClassName && this._wrapItemClassNameGetter(fieldClassName, itemData.value, isChecked, itemData.disabled, itemData.readonly));

		const radioLabelClasses = classNames({
			'radio-field__label': true,
			'radio-field__label--hidden-radio-element': itemData.showRadioElement === false,
		});

		const wrapperClasses = classNames({
			'radio-field__wrapper': true,
			'radio-field__wrapper--is-disabled': itemData.disabled,
			'radio-field__wrapper--is-readonly': itemData.readonly,
			'radio-field__wrapper--stretched': stretchedFields,
		});

		return (
			<label
				className={wrapperClasses}
				htmlFor={name + '-' + index}
				key={name + '-' + index}
				onClick={this._handleFieldClick.bind(this, index, itemData.value)}
			>
				{itemData.prefix}
				<div className={radioFieldClasses}>
					<input
						checked={isControlled ? isChecked : undefined}
						className="radio-field__input"
						defaultChecked={isControlled ? undefined : isChecked}
						disabled={itemData.disabled || itemData.readonly || false}
						id={name + '-' + index}
						name={name}
						onBlur={onBlurCallback}
						onChange={() => {
							if (onChangeCallback) {
								onChangeCallback(itemData.value);
							}
						}}
						onFocus={onFocusCallback}
						ref={(input) => this['inputElement' + index] = input}
						type="radio"
						value={itemData.value}
					/>
					<div
						className="radio-field__element"
						htmlFor={name + '-' + index}
						onMouseEnter={onMouseEnterCallback && onMouseEnterCallback.bind(this, name)}
						onMouseLeave={onMouseLeaveCallback && onMouseLeaveCallback.bind(this, name)}
						onMouseOver={onMouseOverCallback && onMouseOverCallback.bind(this, name)}
					>
						<span className={radioLabelClasses}>
							<span className="radio-field__label-text">
								{itemData.label}
							</span>
							{itemData.labelSuffix && (
								<span className="radio-field__label-suffix">
									{itemData.labelSuffix}
								</span>
							)}
						</span>
						{itemData.description && (
							<span className="radio-field__description">
								{itemData.description}
							</span>
						)}
					</div>
				</div>
				{itemData.suffix}
			</label>
		);
	}


	render() {
		const {
			children,
			itemRenderer,
			items,
		} = this.props;

		const fields = items.map((item, i) => {
			let fieldComponent;

			if (itemRenderer && typeof itemRenderer === 'function') {
				fieldComponent = itemRenderer({
					data: item,
					description: item.description,
					index: i,
					isChecked: item.checked,
					field: this._renderRadioField(item, i),
				});
			} else {
				fieldComponent = this._renderRadioField(item, i);
			}

			return {
				data: item,
				description: item.description,
				field: fieldComponent,
				fieldRenderer: this._renderRadioField.bind(this),
				isChecked: item.checked,
				isDisabled: item.disabled,
				isReadOnly: item.readonly,
			};
		});

		return (
			<>
				{typeof children === 'function' ? children({
					fields,
				}) : fields.map(({ field }) => field)}
			</>
		);
	}

}

PureRadioFields.defaultProps = {
	isControlled: false,
	itemStyle: ITEM_STYLE_TEXTUAL,
	size: SIZE_DEFAULT,
};

PureRadioFields.propTypes = {
	fieldClassName: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string,
	]),
	isControlled: PropTypes.bool,
	itemRenderer: PropTypes.func,
	itemStyle: PropTypes.oneOf([
		ITEM_STYLE_TEXTUAL,
		ITEM_STYLE_TAG,
	]),
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	name: PropTypes.string.isRequired,
	/** Callback triggered on field blur */
	onBlurCallback: PropTypes.func,
	/** Callback triggered on field click */
	onClickCallback: PropTypes.func,
	/** Callback triggered on value change */
	onChangeCallback: PropTypes.func,
	/** Callback triggered on field focus */
	onFocusCallback: PropTypes.func,
	/** Callback triggered when entering to label by mouse */
	onMouseEnterCallback: PropTypes.func,
	/** Callback triggered when leaving from label by mouse */
	onMouseLeaveCallback: PropTypes.func,
	/** Callback triggered when moving over label by mouse */
	onMouseOverCallback: PropTypes.func,
	size: PropTypes.oneOf([
		SIZE_DEFAULT,
		SIZE_SMALL,
	]).isRequired,
	/** Stretch every field to the size of available container */
	stretchedFields: PropTypes.bool,
};



export default PureRadioFields;
