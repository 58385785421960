import classNames from 'classnames';
import React from 'react';



type Props = {
	children: React.ReactNode,
	/** Look of plan in disabled state */
	isDisabled?: boolean,
	/** Apply enterprise highlight */
	isEnterprise?: boolean,
	/** Flag whether plan is first child in the row */
	isFirst?: boolean,
	/** Possible to indent this element by left gap from others */
	isIndented?: boolean,
	/** Flag whether plan is last child in the row */
	isLast?: boolean,
};



const PlansComparisonTableStickyColumn: React.FC<Props> = (props) => {
	const {
		children,
		isDisabled,
		isEnterprise,
		isFirst,
		isIndented,
		isLast,
	} = props;

	const componentClasses = classNames({
		'plans-comparison-table-sticky-column': true,
		'plans-comparison-table-sticky-column--is-disabled': isDisabled,
		'plans-comparison-table-sticky-column--is-first': isFirst,
		'plans-comparison-table-sticky-column--is-enterprise': isEnterprise,
		'plans-comparison-table-sticky-column--is-indented': isIndented,
		'plans-comparison-table-sticky-column--is-last': isLast,
		'dark-bg-context': isEnterprise,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default PlansComparisonTableStickyColumn;
