import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BlankValue from '~/components/patterns/values/BlankValue';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import Caption, {
	CaptionStyle,
} from '~/components/patterns/headings/Caption';
import CodeValue from '~/components/patterns/values/CodeValue';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import DefinitionTermValueSkeleton from './DefinitionTermValueSkeleton';
import DomSrcBadge, {
	DomSrcBadgeType,
} from '~/components/patterns/tags/DomSrcBadge';
import EmptyValue from '~/components/app/EmptyValue';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import MissingValue from '~/components/app/MissingValue';
import TextInspector from '~/components/patterns/typography/TextInspector';

import {
	useSchemaOrgBlockPrimaryQuery,
	useSchemaOrgBlockSrcQuery,
} from './SchemaOrgBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import usePageDetailSourceMode from '~/hooks/usePageDetailSourceMode';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	invalid: {
		id: 'ui.schemaOrg.invalidElements',
	},
});



function renderValue(field, value) {
	if (value instanceof Array) {
		if (value.length > 0) {
			if (typeof value[0] === 'object' && value[0] !== null) {
				return [value.map((subValue, subIndex) => {
					return (
						<DefinitionTerm
							description={renderValue(subIndex, subValue)}
							key={subIndex}
							term=""
						/>
					);
				})];
			}

			return (
				<DefinitionTermsList>
					{value.map((subValue, subIndex) => (
						<React.Fragment key={subIndex}>
							{renderValue(field, subValue)}
						</React.Fragment>
					))}
				</DefinitionTermsList>
			);
		}

		return false;
	} else if (typeof value === 'object' && value !== null) {
		return (
			<DefinitionTermsList>
				{Object.keys(value).map((subField) => {
					return (
						<DefinitionTerm
							description={renderValue(subField, value[subField])}
							key={subField}
							term={subField}
							uppercaseTerm={false}
						/>
					);
				})}
			</DefinitionTermsList>
		);
	} else if (value === '') {
		return (
			<EmptyValue />
		);
	} else if (field === 'url') {
		return (
			<ExternalLink href={value}>
				{value}
			</ExternalLink>
		);
	} else if (field === 'dateModified' || field === 'datePublished') {
		return (
			<CodeValue>
				<TextInspector text={value} />
			</CodeValue>
		);
	} else if (typeof value === 'string') {
		return (
			<TextInspector text={value} />
		);
	}

	return value;
}



const SchemaOrgBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const pageDetailSourceMode = usePageDetailSourceMode();
	const websiteId = useWebsiteId();

	const {
		data: primaryData,
	} = usePageDetailPropertiesQuery(
		useSchemaOrgBlockPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	const {
		data: srcData,
	} = usePageDetailPropertiesQuery(
		useSchemaOrgBlockSrcQuery,
		legacyUrlId,
		websiteId,
		{
			skip: pageDetailSourceMode !== GraphQL.PageDetailSourceMode.Compare,
		},
	);

	const primarySchemaOrg = primaryData?.lookupPageByLegacyId?.pageTypeData?.primaryProperties.schemaOrgJson ?? null;
	const srcSchemaOrg = srcData?.lookupPageByLegacyId?.pageTypeData?.srcProperties.schemaOrgJson ?? null;

	const primaryInvalidSchemaOrg = primaryData?.lookupPageByLegacyId?.pageTypeData?.primaryProperties.invalidSchemaOrgJson.length ?? null;
	const srcInvalidSchemaOrg = srcData?.lookupPageByLegacyId?.pageTypeData?.srcProperties.invalidSchemaOrgJson.length ?? null;

	if (pageDetailSourceMode === GraphQL.PageDetailSourceMode.Compare) {
		const isSrcSame = srcSchemaOrg !== null && JSON.stringify(primarySchemaOrg) === JSON.stringify(srcSchemaOrg);

		return (
			<BorderedBox
				headerIcon={(
					<AttributesGroupIcon
						size={28}
						type={AttributesGroupIconType.SchemaOrg}
					/>
				)}
				headerLabel="Schema.org"
				paddingSize={3}
			>
				<DomSrcBadge type={DomSrcBadgeType.Dom} />

				{(primaryInvalidSchemaOrg ?? 0) > 0 && (
					<MissingValue>
						<FormattedMessage
							{...messages.invalid}
							values={{
								count: primaryInvalidSchemaOrg ?? 0,
							}}
						/>
					</MissingValue>
				)}

				{primarySchemaOrg === null && (
					<DefinitionTermValueSkeleton />
				)}

				{primarySchemaOrg !== null && primarySchemaOrg.length === 0 && primaryInvalidSchemaOrg === 0 && (
					<MissingValue />
				)}

				{primarySchemaOrg !== null && primarySchemaOrg.map((element, index) => {
					return (
						<DefinitionTermsList key={index}>
							<Caption style={CaptionStyle.Highlighted}>
								{element['@type']}
							</Caption>

							{Object.keys(element).map((field) => {
								return (
									<DefinitionTerm
										description={renderValue(field, element[field])}
										key={field}
										term={field}
										uppercaseTerm={false}
									/>
								);
							})}
						</DefinitionTermsList>
					);
				})}

				<DomSrcBadge type={DomSrcBadgeType.Src} />

				{(srcInvalidSchemaOrg ?? 0) > 0 && (
					<MissingValue>
						<FormattedMessage
							{...messages.invalid}
							values={{
								count: srcInvalidSchemaOrg ?? 0,
							}}
						/>
					</MissingValue>
				)}

				{srcSchemaOrg === null && (
					<DefinitionTermValueSkeleton />
				)}

				{isSrcSame && (
					<BlankValue>
						same as DOM
					</BlankValue>
				)}

				{srcSchemaOrg !== null && !isSrcSame && srcSchemaOrg.length === 0 && srcInvalidSchemaOrg === 0 && (
					<MissingValue />
				)}

				{srcSchemaOrg !== null && !isSrcSame && srcSchemaOrg.map((element, index) => {
					return (
						<DefinitionTermsList key={index}>
							<Caption style={CaptionStyle.Highlighted}>
								{element['@type']}
							</Caption>

							{Object.keys(element).map((field) => {
								return (
									<DefinitionTerm
										description={renderValue(field, element[field])}
										key={field}
										term={field}
										uppercaseTerm={false}
									/>
								);
							})}
						</DefinitionTermsList>
					);
				})}
			</BorderedBox>
		);
	}

	return (
		<BorderedBox
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.SchemaOrg}
				/>
			)}
			headerLabel="Schema.org"
			paddingSize={3}
		>
			{(primaryInvalidSchemaOrg ?? 0) > 0 && (
				<MissingValue>
					<FormattedMessage
						{...messages.invalid}
						values={{
							count: primaryInvalidSchemaOrg ?? 0,
						}}
					/>
				</MissingValue>
			)}

			{primarySchemaOrg === null && (
				<DefinitionTermValueSkeleton />
			)}

			{primarySchemaOrg !== null && primarySchemaOrg.length === 0 && primaryInvalidSchemaOrg === 0 && (
				<MissingValue />
			)}

			{primarySchemaOrg !== null && primarySchemaOrg.map((element, index) => {
				return (
					<DefinitionTermsList key={index}>
						<Caption style={CaptionStyle.Highlighted}>
							{element['@type']}
						</Caption>

						{Object.keys(element).map((field) => {
							return (
								<DefinitionTerm
									description={renderValue(field, element[field])}
									key={field}
									term={field}
									uppercaseTerm={false}
								/>
							);
						})}
					</DefinitionTermsList>
				);
			})}
		</BorderedBox>
	);
};



export default React.memo(SchemaOrgBlock);
