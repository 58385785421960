import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import RichText from '~/components/patterns/typography/RichText';
import SelectField from '~/components/atoms/forms/components/SelectField';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	useUpdatePageIssuesConfigurationMutation,
} from './IssueCategoryConfigurationModal.gql';

import useOpenedWebsiteIssueCategory from '~/hooks/useOpenedWebsiteIssueCategory';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	IssueCategoryName,
} from '~/model/issuesNew';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	headerLabel: {
		id: 'ui.general.settings',
	},
	introduction: {
		id: 'ui.issuesConfiguration.modal.introduction',
	},
	revert: {
		id: 'ui.issuesConfiguration.modal.revert',
	},
	revertLink: {
		id: 'ui.issuesConfiguration.modal.revert.link',
	},
	settingsLengthUnitLabel: {
		id: 'ui.issuesConfiguration.settings.lengthUnit',
	},
	settingsLengthUnitOptionsCharacters: {
		id: 'ui.issuesConfiguration.settings.lengthUnit.characters',
	},
	settingsLengthUnitOptionsPixels: {
		id: 'ui.issuesConfiguration.settings.lengthUnit.pixels',
	},
	settingsLinksToCanonicalizedRequiredOnScope: {
		id: 'ui.issuesConfiguration.settings.linksToCanonicalizedRequiredOnScope',
	},
	settingsMaximumLength: {
		id: 'ui.issuesConfiguration.settings.maximumLength',
	},
	settingsMaximumLengthOfDescription: {
		id: 'ui.issuesConfiguration.settings.maximumLength.ofDescription',
	},
	settingsMaximumLengthOfTitle: {
		id: 'ui.issuesConfiguration.settings.maximumLength.ofTitle',
	},
	settingsMinimumLength: {
		id: 'ui.issuesConfiguration.settings.minimumLength',
	},
	settingsMinimumLengthOfDescription: {
		id: 'ui.issuesConfiguration.settings.minimumLength.ofDescription',
	},
	settingsMinimumLengthOfTitle: {
		id: 'ui.issuesConfiguration.settings.minimumLength.ofTitle',
	},
	settingsRequiredOnScopeLabel: {
		id: 'ui.issuesConfiguration.settings.requiredOnScope',
	},
	settingsRequiredOnScopeOptionsAllPages: {
		id: 'ui.issuesConfiguration.settings.requiredOnScope.allPages',
	},
	settingsRequiredOnScopeOptionsIndexablePages: {
		id: 'ui.issuesConfiguration.settings.requiredOnScope.indexablePages',
	},
	settingsRequiredOnScopeOptionsSearchEngineAccessiblePages: {
		id: 'ui.issuesConfiguration.settings.requiredOnScope.searchEngineAccessiblePages',
	},
	submitButton: {
		id: 'ui.general.saveButton',
	},
});



type Props = {
	closeCallback: () => void,
};

const IssueCategoryConfigurationModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const websiteId = useWebsiteId();

	const issueCategory = useOpenedWebsiteIssueCategory(websiteId);

	const [numberOfResets, setNumberOfResets] = React.useState(0);

	const [updatePageIssuesConfiguration] = useUpdatePageIssuesConfigurationMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (issueCategory === null) {
				throw new Error(
					`issueCategory can't be null in handleSubmit`,
				);
			}

			for (const key in values) {
				if (values.hasOwnProperty(key) && key.includes('__')) {
					const finalKey = getArrayItemAtSafeIndex(key.split('__'), 0);
					const finalKeyUnit = getArrayItemAtSafeIndex(key.split('__'), 1);

					if (values.length_unit === finalKeyUnit) {
						values[finalKey] = values[key];
					}

					delete values[key];
				}
			}

			const oldIssueCategoryConfiguration = issueCategory.configuration;

			Object.entries(oldIssueCategoryConfiguration).forEach(([field, value]) => {
				if (typeof value === 'number' && Number.isInteger(value)) {
					values[field] = parseInt(values[field], 10);
				}
			});

			const limitPairs = [
				['min', 'max'],
				['description_min', 'description_max'],
				['title_min', 'title_max'],
			] as const;

			limitPairs.forEach(([min, max]) => {
				if (values[min] !== undefined && values[max] !== undefined) {
					if (values[min] > values[max]) {
						const minValue = values[min];
						const maxValue = values[max];

						values[min] = maxValue;
						values[max] = minValue;
					}
				}
			});

			await updatePageIssuesConfiguration({
				variables: {
					issueCategoryName: issueCategory.name,
					issueCategoryConfiguration: values,
					websiteId,
				},
			});

			closeCallback();
		},
		[
			closeCallback,
			issueCategory,
			updatePageIssuesConfiguration,
			websiteId,
		],
	);

	const resetValues = React.useCallback(
		() => {
			setNumberOfResets(
				(numberOfResets) => numberOfResets + 1,
			);
		},
		[],
	);

	const renderFields = ({ values }) => {
		if (issueCategory === null) {
			return false;
		}

		if (issueCategory.name === IssueCategoryName.CanonicalLink) {
			return (
				<FormRow
					htmlFor="required_on"
					label={(
						<FormattedMessage {...messages.settingsRequiredOnScopeLabel} />
					)}
				>
					<SelectField
						name="required_on"
						options={[
							{
								name: 'indexable_pages',
								label: (
									<FormattedMessage {...messages.settingsRequiredOnScopeOptionsIndexablePages} />
								),
							},
							{
								name: 'all_pages',
								label: (
									<FormattedMessage {...messages.settingsRequiredOnScopeOptionsAllPages} />
								),
							},
						]}
					/>
				</FormRow>
			);
		}

		if (issueCategory.name === IssueCategoryName.H1) {
			return (
				<FormRows>
					<FormRow
						htmlFor="required_on"
						label={(
							<FormattedMessage {...messages.settingsRequiredOnScopeLabel} />
						)}
					>
						<SelectField
							name="required_on"
							options={[
								{
									name: 'indexable_pages',
									label: (
										<FormattedMessage {...messages.settingsRequiredOnScopeOptionsIndexablePages} />
									),
								},
								{
									name: 'all_pages',
									label: (
										<FormattedMessage {...messages.settingsRequiredOnScopeOptionsAllPages} />
									),
								},
							]}
						/>
					</FormRow>

					<FormRow
						htmlFor="min"
						label={(
							<FormattedMessage {...messages.settingsMinimumLength} />
						)}
					>
						<TextField
							name="min"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>

					<FormRow
						htmlFor="max"
						label={(
							<FormattedMessage {...messages.settingsMaximumLength} />
						)}
					>
						<TextField
							name="max"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>
				</FormRows>
			);
		}

		if (issueCategory.name === IssueCategoryName.Links) {
			return (
				<FormRow
					htmlFor="links_to_canonicalized_required_on"
					label={(
						<FormattedMessage {...messages.settingsLinksToCanonicalizedRequiredOnScope} />
					)}
				>
					<SelectField
						name="links_to_canonicalized_required_on"
						options={[
							{
								name: 'search_engine_accessible_pages',
								label: (
									<FormattedMessage {...messages.settingsRequiredOnScopeOptionsSearchEngineAccessiblePages} />
								),
							},
							{
								name: 'all_pages',
								label: (
									<FormattedMessage {...messages.settingsRequiredOnScopeOptionsAllPages} />
								),
							},
						]}
					/>
				</FormRow>
			);
		}

		if (issueCategory.name === IssueCategoryName.MetaDescription) {
			return (
				<FormRows>
					<FormRow
						htmlFor="required_on"
						label={(
							<FormattedMessage {...messages.settingsRequiredOnScopeLabel} />
						)}
					>
						<SelectField
							name="required_on"
							options={[
								{
									name: 'indexable_pages',
									label: (
										<FormattedMessage {...messages.settingsRequiredOnScopeOptionsIndexablePages} />
									),
								},
								{
									name: 'all_pages',
									label: (
										<FormattedMessage {...messages.settingsRequiredOnScopeOptionsAllPages} />
									),
								},
							]}
						/>
					</FormRow>

					<FormRow
						htmlFor="length_unit"
						label={(
							<FormattedMessage {...messages.settingsLengthUnitLabel} />
						)}
					>
						<SelectField
							name="length_unit"
							options={[
								{
									name: 'pixels',
									label: (
										<FormattedMessage {...messages.settingsLengthUnitOptionsPixels} />
									),
								},
								{
									name: 'characters',
									label: (
										<FormattedMessage {...messages.settingsLengthUnitOptionsCharacters} />
									),
								},
							]}
						/>
					</FormRow>

					<FormRow
						htmlFor={'min__' + values.length_unit}
						label={(
							<FormattedMessage {...messages.settingsMinimumLength} />
						)}
					>
						<TextField
							key={values.length_unit}
							name={'min__' + values.length_unit}
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>

					<FormRow
						htmlFor={'max__' + values.length_unit}
						label={(
							<FormattedMessage {...messages.settingsMaximumLength} />
						)}
					>
						<TextField
							key={values.length_unit}
							name={'max__' + values.length_unit}
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>
				</FormRows>
			);
		}

		if (issueCategory.name === IssueCategoryName.OpenGraph) {
			return (
				<FormRows>
					<FormRow
						htmlFor="title_min"
						label={(
							<FormattedMessage {...messages.settingsMinimumLengthOfTitle} />
						)}
					>
						<TextField
							name="title_min"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>

					<FormRow
						htmlFor="title_max"
						label={(
							<FormattedMessage {...messages.settingsMaximumLengthOfTitle} />
						)}
					>
						<TextField
							name="title_max"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>

					<FormRow
						htmlFor="description_min"
						label={(
							<FormattedMessage {...messages.settingsMinimumLengthOfDescription} />
						)}
					>
						<TextField
							name="description_min"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>

					<FormRow
						htmlFor="description_max"
						label={(
							<FormattedMessage {...messages.settingsMaximumLengthOfDescription} />
						)}
					>
						<TextField
							name="description_max"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>
				</FormRows>
			);
		}

		if (issueCategory.name === IssueCategoryName.Title) {
			return (
				<FormRows>
					<FormRow
						htmlFor="required_on"
						label={(
							<FormattedMessage {...messages.settingsRequiredOnScopeLabel} />
						)}
					>
						<SelectField
							name="required_on"
							options={[
								{
									name: 'indexable_pages',
									label: (
										<FormattedMessage {...messages.settingsRequiredOnScopeOptionsIndexablePages} />
									),
								},
								{
									name: 'all_pages',
									label: (
										<FormattedMessage {...messages.settingsRequiredOnScopeOptionsAllPages} />
									),
								},
							]}
						/>
					</FormRow>

					<FormRow
						htmlFor="length_unit"
						label={(
							<FormattedMessage {...messages.settingsLengthUnitLabel} />
						)}
					>
						<SelectField
							name="length_unit"
							options={[
								{
									name: 'pixels',
									label: (
										<FormattedMessage {...messages.settingsLengthUnitOptionsPixels} />
									),
								},
								{
									name: 'characters',
									label: (
										<FormattedMessage {...messages.settingsLengthUnitOptionsCharacters} />
									),
								},
							]}
						/>
					</FormRow>

					<FormRow
						htmlFor={'min__' + values.length_unit}
						label={(
							<FormattedMessage {...messages.settingsMinimumLength} />
						)}
					>
						<TextField
							key={values.length_unit}
							name={'min__' + values.length_unit}
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>

					<FormRow
						htmlFor={'max__' + values.length_unit}
						label={(
							<FormattedMessage {...messages.settingsMaximumLength} />
						)}
					>
						<TextField
							key={values.length_unit}
							name={'max__' + values.length_unit}
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>
				</FormRows>
			);
		}

		if (issueCategory.name === IssueCategoryName.TwitterCards) {
			return (
				<FormRows>
					<FormRow
						htmlFor="title_min"
						label={(
							<FormattedMessage {...messages.settingsMinimumLengthOfTitle} />
						)}
					>
						<TextField
							name="title_min"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>

					<FormRow
						htmlFor="title_max"
						label={(
							<FormattedMessage {...messages.settingsMaximumLengthOfTitle} />
						)}
					>
						<TextField
							name="title_max"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>

					<FormRow
						htmlFor="description_min"
						label={(
							<FormattedMessage {...messages.settingsMinimumLengthOfDescription} />
						)}
					>
						<TextField
							name="description_min"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>

					<FormRow
						htmlFor="description_max"
						label={(
							<FormattedMessage {...messages.settingsMaximumLengthOfDescription} />
						)}
					>
						<TextField
							name="description_max"
							type={TextFieldType.Number}
							width={85}
						/>
					</FormRow>
				</FormRows>
			);
		}

		return false;
	};

	const renderContent = () => {
		if (issueCategory === null) {
			return false;
		}

		const issueCategoryConfiguration = { ...issueCategory.configuration };
		const defaultIssueCategoryConfiguration = { ...issueCategory.defaultConfiguration };

		const defaultValues = numberOfResets > 0
			? defaultIssueCategoryConfiguration
			: issueCategoryConfiguration;

		if (defaultValues.length_unit && numberOfResets === 0) {
			defaultValues.max__pixels = defaultValues.length_unit === 'pixels'
				? defaultValues.max
				: defaultIssueCategoryConfiguration.max__pixels;
			defaultValues.min__pixels = defaultValues.length_unit === 'pixels'
				? defaultValues.min
				: defaultIssueCategoryConfiguration.min__pixels;

			defaultValues.max__characters = defaultValues.length_unit === 'characters'
				? defaultValues.max
				: defaultIssueCategoryConfiguration.max__characters;
			defaultValues.min__characters = defaultValues.length_unit === 'characters'
				? defaultValues.min
				: defaultIssueCategoryConfiguration.min__characters;

			delete defaultValues.max;
			delete defaultValues.min;
		}

		return (
			<PremiumFeatureSituation
				featureName={GraphQL.AccountFeature.IssuesConfiguration}
				hideIfUnattainable={true}
				style={PremiumFeatureSituationStyle.Box}
			>
				{({ isFeatureEnabled, premiumAnnotation }) => (
					<>
						{premiumAnnotation && (
							<ModalTextSection>
								{premiumAnnotation}
							</ModalTextSection>
						)}

						<DisabledContent disabledContent={!isFeatureEnabled}>
							<ModalTextSection>
								<RichText>
									<p>
										<FormattedMessage {...messages.introduction} />
									</p>
									<p>
										<FormattedMessage
											{...messages.revert}
											values={{
												text__link: (
													<InternalLink
														onClickCallback={resetValues}
														style={InternalLinkStyle.Decent}
													>
														<FormattedMessage {...messages.revertLink} />
													</InternalLink>
												),
											}}
										/>
									</p>
								</RichText>
							</ModalTextSection>

							<Form
								defaultValues={defaultValues}
								isDisabled={!isFeatureEnabled}
								key={numberOfResets}
								onSuccess={handleSubmit}
							>
								{({ values }) => {
									return (
										<>
											<CenteredFormWrapper>
												{renderFields({ values })}
											</CenteredFormWrapper>

											<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
												<CancelButton onClickCallback={closeCallback} />

												<SubmitButton
													allowNoChanges={numberOfResets > 0}
												>
													<FormattedMessage {...messages.submitButton} />
												</SubmitButton>
											</ModalButtonsLayout>
										</>
									);
								}}
							</Form>
						</DisabledContent>
					</>
				)}
			</PremiumFeatureSituation>
		);
	};

	return (
		<ModalContainer
			gapsSize={2}
			header={(
				<ModalHeader
					iconType={ModalHeaderIconType.CogWheel}
					title={(
						<FormattedMessage {...messages.headerLabel} />
					)}
					titleValue={issueCategory !== null && (
						<IssueCategoryTitle
							issueCategoryName={issueCategory.name}
						/>
					)}
				/>
			)}
			headerGapsSize={1}
			isLoading={issueCategory === null}
		>
			{renderContent()}
		</ModalContainer>
	);
};



export default IssueCategoryConfigurationModal;
