/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IgnorePageIssueOnSegmentsMutationVariables = GraphQL.Exact<{
  issueName: GraphQL.Scalars['String']['input'];
  segments: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IgnorePageIssueOnSegmentsMutation = { readonly __typename?: 'Mutation', readonly IgnorePageIssueOnSegments: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type UnignorePageIssueOnSegmentsMutationVariables = GraphQL.Exact<{
  issueName: GraphQL.Scalars['String']['input'];
  segments: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueOnSegmentsMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueOnSegments: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const IgnorePageIssueOnSegmentsDocument = gql`
    mutation IgnorePageIssueOnSegments($issueName: String!, $segments: [String!]!, $websiteId: WebsiteId!) {
  IgnorePageIssueOnSegments(
    segments: $segments
    type: $issueName
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type IgnorePageIssueOnSegmentsMutationFn = Apollo.MutationFunction<IgnorePageIssueOnSegmentsMutation, IgnorePageIssueOnSegmentsMutationVariables>;

/**
 * __useIgnorePageIssueOnSegmentsMutation__
 *
 * To run a mutation, you first call `useIgnorePageIssueOnSegmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnorePageIssueOnSegmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignorePageIssueOnSegmentsMutation, { data, loading, error }] = useIgnorePageIssueOnSegmentsMutation({
 *   variables: {
 *      issueName: // value for 'issueName'
 *      segments: // value for 'segments'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIgnorePageIssueOnSegmentsMutation(baseOptions?: Apollo.MutationHookOptions<IgnorePageIssueOnSegmentsMutation, IgnorePageIssueOnSegmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgnorePageIssueOnSegmentsMutation, IgnorePageIssueOnSegmentsMutationVariables>(IgnorePageIssueOnSegmentsDocument, options);
      }
export type IgnorePageIssueOnSegmentsMutationHookResult = ReturnType<typeof useIgnorePageIssueOnSegmentsMutation>;
export type IgnorePageIssueOnSegmentsMutationResult = Apollo.MutationResult<IgnorePageIssueOnSegmentsMutation>;
export type IgnorePageIssueOnSegmentsMutationOptions = Apollo.BaseMutationOptions<IgnorePageIssueOnSegmentsMutation, IgnorePageIssueOnSegmentsMutationVariables>;
export const UnignorePageIssueOnSegmentsDocument = gql`
    mutation UnignorePageIssueOnSegments($issueName: String!, $segments: [String!]!, $websiteId: WebsiteId!) {
  UnignorePageIssueOnSegments(
    segments: $segments
    type: $issueName
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePageIssueOnSegmentsMutationFn = Apollo.MutationFunction<UnignorePageIssueOnSegmentsMutation, UnignorePageIssueOnSegmentsMutationVariables>;

/**
 * __useUnignorePageIssueOnSegmentsMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueOnSegmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueOnSegmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueOnSegmentsMutation, { data, loading, error }] = useUnignorePageIssueOnSegmentsMutation({
 *   variables: {
 *      issueName: // value for 'issueName'
 *      segments: // value for 'segments'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueOnSegmentsMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueOnSegmentsMutation, UnignorePageIssueOnSegmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueOnSegmentsMutation, UnignorePageIssueOnSegmentsMutationVariables>(UnignorePageIssueOnSegmentsDocument, options);
      }
export type UnignorePageIssueOnSegmentsMutationHookResult = ReturnType<typeof useUnignorePageIssueOnSegmentsMutation>;
export type UnignorePageIssueOnSegmentsMutationResult = Apollo.MutationResult<UnignorePageIssueOnSegmentsMutation>;
export type UnignorePageIssueOnSegmentsMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueOnSegmentsMutation, UnignorePageIssueOnSegmentsMutationVariables>;