import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BulkSaveDefinitionsButton from '~/components/logic/alertsConfiguration/BulkSaveDefinitionsButton';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SensitivityField from '~/components/logic/alertsConfiguration/fields/SensitivityField';

import {
	useBulkUpdateAlertDefinitionsSensitivityLevelMutation,
} from './EditAlertDefinitionsSensitivityModal.gql';

import useBulkEditedAlertDefinitions from '~/hooks/useBulkEditedAlertDefinitions';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useSetFlashMessage from '~/hooks/useSetFlashMessage';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	description: {
		id: 'ui.alertsConfiguration.modal.bulkEditSensitivity.description',
	},
	fieldSensitivity: {
		id: 'ui.alertsConfiguration.fields.sensitivity',
	},
	headerLabel: {
		id: 'ui.general.settings',
	},
	headerValue: {
		id: 'ui.alertsConfiguration.modal.bulk.header',
	},
});

const validations = {
	sensitivity: [
		{
			message: '',
			field: 'sensitivity',
			rule: ({ values, name }) => {
				return values[name] !== '';
			},
		},
	],
};



const EditAlertDefinitionsSensitivityModal: React.FC = () => {
	const websiteId = useWebsiteId();

	const classicFormBehavior = useClassicFormBehavior();
	const selectedAlertDefinitions = useBulkEditedAlertDefinitions();
	const setFlashMessage = useSetFlashMessage();

	const [bulkUpdateAlertDefinitionsSensitivityLevel] = useBulkUpdateAlertDefinitionsSensitivityLevelMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await bulkUpdateAlertDefinitionsSensitivityLevel({
				variables: {
					alertDefinitionIds: selectedAlertDefinitions.idsWithSensitivity,
					sensitivityLevel: values.sensitivity,
					websiteId,
				},
			});

			setFlashMessage({
				message: 'alertDefinitionsEdited',
				messageVariables: {
					number__alertDefinitions: selectedAlertDefinitions.count,
				},
				route: 'website.alerts.configuration',
				style: 'success',
			});

			classicFormBehavior.finish();
		},
		[
			bulkUpdateAlertDefinitionsSensitivityLevel,
			classicFormBehavior,
			selectedAlertDefinitions,
			setFlashMessage,
			websiteId,
		],
	);

	let defaultSensitivity: string | undefined;

	selectedAlertDefinitions.allWithSensitivity.forEach((alertDefinition) => {
		if (defaultSensitivity === undefined) {
			defaultSensitivity = alertDefinition.settings.sensitivityLevel;
		} else if (defaultSensitivity !== alertDefinition.settings.sensitivityLevel) {
			defaultSensitivity = '';
		}
	});

	return (
		<ModalContainer
			gapsSize={2}
			header={(
				<ModalHeader
					iconType={ModalHeaderIconType.CogWheel}
					title={(
						<FormattedMessage {...messages.headerLabel} />
					)}
					titleValue={(
						<FormattedMessage
							{...messages.headerValue}
							values={{
								count__definitions: selectedAlertDefinitions.count,
							}}
						/>
					)}
				/>
			)}
			headerGapsSize={1}
			isLoading={defaultSensitivity === undefined}
		>
			<ModalTextSection>
				<RichText>
					<p>
						<FormattedMessage {...messages.description} />
					</p>
				</RichText>
			</ModalTextSection>

			{defaultSensitivity !== undefined && (
				<Form
					defaultValues={{
						sensitivity: defaultSensitivity,
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<CenteredFormWrapper>
						<FormRow
							htmlFor="sensitivity"
							label={(
								<FormattedMessage {...messages.fieldSensitivity} />
							)}
						>
							<FieldStatus
								name="sensitivity"
								showIcon={false}
							>
								<SensitivityField
									name="sensitivity"
									websiteId={websiteId}
								/>
							</FieldStatus>
						</FormRow>
					</CenteredFormWrapper>

					<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
						<CancelButton />

						<BulkSaveDefinitionsButton
							numberOfAlertDefinitions={selectedAlertDefinitions.count}
						/>
					</ModalButtonsLayout>
				</Form>
			)}
		</ModalContainer>
	);
};



export default EditAlertDefinitionsSensitivityModal;
