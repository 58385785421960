import useBatchContext from '~/hooks/useBatchContext';



function useInitialUserPropertiesQuery<TQueryHookReturnType>(
	queryHook: (options: any) => TQueryHookReturnType,
): TQueryHookReturnType {
	return queryHook({
		context: useBatchContext(
			'initialUserProperties',
			999,
		),
	});
}



export default useInitialUserPropertiesQuery;
