import type Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '../basis/FormRow';
import FormRows from '../basis/FormRows';
import StaticText from '../components/StaticText';
import TextArea from '../components/TextArea';
import TextField from '../components/TextField';



const messages = defineMessages({
	attn: {
		id: 'ui.invoiceDetails.customInvoiceFields.attn',
	},
	poNumber: {
		id: 'ui.invoiceDetails.customInvoiceFields.poNumber',
	},
	reference: {
		id: 'ui.invoiceDetails.customInvoiceFields.reference',
	},
	supplierNumber: {
		id: 'ui.invoiceDetails.customInvoiceFields.supplierNumber',
	},
});



type Props = {
	editMode: boolean,
	invoiceDetails: Immutable.Map<string, any>,
};

const CustomInvoiceFields: React.FC<Props> = (props) => {
	const {
		editMode,
		invoiceDetails,
	} = props;

	function renderOverview() {
		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.attn} />
					)}
				>
					<StaticText focusTarget="attn">{invoiceDetails.get('attn') || '-'}</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.poNumber} />
					)}
				>
					<StaticText focusTarget="po_number">{invoiceDetails.get('po_number') || '-'}</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.supplierNumber} />
					)}
				>
					<StaticText focusTarget="supplier_number">{invoiceDetails.get('supplier_number') || '-'}</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.reference} />
					)}
				>
					<StaticText focusTarget="customer_reference">{invoiceDetails.get('customer_reference') || '-'}</StaticText>
				</FormRow>
			</FormRows>
		);
	}

	function renderForm() {
		return (
			<FormRows>
				<FormRow
					htmlFor="attn"
					label={(
						<FormattedMessage {...messages.attn} />
					)}
				>
					<FieldStatus name="attn">
						<TextField
							name="attn"
							trimValue={true}
						/>
					</FieldStatus>
				</FormRow>

				<FormRow
					htmlFor="po_number"
					label={(
						<FormattedMessage {...messages.poNumber} />
					)}
				>
					<FieldStatus name="po_number">
						<TextField
							name="po_number"
							trimValue={true}
						/>
					</FieldStatus>
				</FormRow>

				<FormRow
					htmlFor="supplier_number"
					label={(
						<FormattedMessage {...messages.supplierNumber} />
					)}
				>
					<FieldStatus name="supplier_number">
						<TextField
							name="supplier_number"
							trimValue={true}
						/>
					</FieldStatus>
				</FormRow>

				<FormRow
					htmlFor="customer_reference"
					label={(
						<FormattedMessage {...messages.reference} />
					)}
				>
					<FieldStatus name="customer_reference">
						<TextArea
							name="customer_reference"
							resizable={true}
							rows={3}
						/>
					</FieldStatus>
				</FormRow>
			</FormRows>
		);
	}

	return editMode ? renderForm() : renderOverview();
};



export default CustomInvoiceFields;
