import type CK from '~/types/contentking';

import {
	useScopeSizesQuery,
} from './scopeSizes.gql';



function usePrefetch({ websiteId }) {
	useScopeSizesQuery({
		variables: {
			websiteId,
		},
	});
}



function useScopeSize(websiteId: CK.WebsiteId, scope: string): number | null {
	const { data } = useScopeSizesQuery({ variables: { websiteId } });

	const scenario = data?.website?.scenariosIllustratingAlertSensitivity
		.find((scenario) => scenario.scope === scope);

	if (!scenario) {
		return null;
	}

	return scenario.scopeSize;
}



export default useScopeSize;

export {
	usePrefetch,
};
