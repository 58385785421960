import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	defineMessages,
	injectIntl,
} from 'react-intl';

import AppearAnimation, {
	AppearAnimationDirection,
} from '~/components/patterns/animations/AppearAnimation';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '../basis/FormRow';
import FormRows from '../basis/FormRows';
import SelectField from '../components/SelectField';
import StaticText from '../components/StaticText';
import TextField from '../components/TextField';

import {
	countries as countriesList,
} from '~/utilities/countries';
import {
	states as statesList,
} from '~/utilities/states';



function isCanada(country) {
	return country === 'CA';
}


function isUSA(country) {
	return country === 'US';
}



const messages = defineMessages({
	address: {
		id: 'ui.newTeam.form.address',
	},
	company: {
		id: 'ui.newTeam.form.company',
	},
	country: {
		id: 'ui.newTeam.form.country',
	},
	firstNamePlaceholder: {
		id: 'ui.creditCard.firstNamePlaceholder',
	},
	lastNamePlaceholder: {
		id: 'ui.creditCard.lastNamePlaceholder',
	},
	name: {
		id: 'ui.creditCard.name',
	},
	province: {
		id: 'ui.newTeam.form.province',
	},
	state: {
		id: 'ui.newTeam.form.state',
	},
	zipCodeAndCity: {
		id: 'ui.newTeam.form.zipCodeAndCity',
	},
});



class DeliveryAddressFields extends Component {

	constructor(props, context) {
		super(props, context);

		const {
			country,
		} = props;

		this.state = {
			showState: country && country.length > 0
				? isCanada(country) || isUSA(country)
				: false,
		};
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			country,
		} = this.props;

		const {
			country: nextCountry,
		} = nextProps;

		if (country !== nextCountry) {
			this.setState({
				showState: nextCountry && nextCountry.length > 0
					? isCanada(nextCountry) || isUSA(nextCountry)
					: false,
			});
		}
	}



	_getCountryOptions() {
		const {
			intl,
		} = this.props;

		const countries = countriesList.map((code) => {
			return {
				label: intl.formatMessage({ id: 'countries.' + code }),
				name: code,
			};
		}).sort(({ label: labelA }, { label: labelB }) => {
			return labelA.localeCompare(labelB);
		});

		return countries;
	}



	_getStateOptions() {
		const {
			country,
			intl,
		} = this.props;

		const states = statesList[country].map((code) => {
			return {
				label: intl.formatMessage({ id: 'states.' + country + '.' + code }),
				name: code,
			};
		}).sort(({ label: labelA }, { label: labelB }) => {
			return labelA.localeCompare(labelB);
		});

		return states;
	}



	_renderOverview() {
		const {
			deliveryAddress,
			country,
			intl,
		} = this.props;

		const {
			showState,
		} = this.state;

		return (
			<FormRows>
				<FormRow label={intl.formatMessage(messages.company)}>
					<StaticText focusTarget="company">
						{deliveryAddress ? deliveryAddress.get('company') : ''}
					</StaticText>
				</FormRow>

				<FormRow
					htmlFor="first_name"
					label={intl.formatMessage(messages.name)}
				>
					<StaticText
						name="first_name"
						width={100}
					>
						{deliveryAddress ? deliveryAddress.get('first_name') : ''}
					</StaticText>

					<StaticText
						name="last_name"
						width={130}
					>
						{deliveryAddress ? deliveryAddress.get('last_name') : ''}
					</StaticText>
				</FormRow>

				<FormRow label={intl.formatMessage(messages.address)}>
					<StaticText focusTarget="address">
						{deliveryAddress ? deliveryAddress.get('address') : ''}
					</StaticText>
				</FormRow>

				<FormRow label={intl.formatMessage(messages.zipCodeAndCity)}>
					<StaticText
						focusTarget="zip_code"
						width={70}
					>
						{deliveryAddress ? deliveryAddress.get('zip_code') : ''}
					</StaticText>

					<StaticText
						focusTarget="city"
						width={160}
					>
						{deliveryAddress ? deliveryAddress.get('city') : ''}
					</StaticText>
				</FormRow>

				<FormRow label={intl.formatMessage(messages.country)}>
					<StaticText focusTarget="country">
						{deliveryAddress && deliveryAddress.get('country')
							? intl.formatMessage({ id: 'countries.' + deliveryAddress.get('country') })
							: '-'
						}
					</StaticText>
				</FormRow>

				{showState && (
					<FormRow label={intl.formatMessage(isUSA(country) ? messages.state : messages.province)}>
						<StaticText focusTarget="state">
							{deliveryAddress && deliveryAddress.get('country') && deliveryAddress.get('state')
								? intl.formatMessage({ id: 'states.' + deliveryAddress.get('country') + '.' + deliveryAddress.get('state') })
								: '-'
							}
						</StaticText>
					</FormRow>
				)}
			</FormRows>
		);
	}



	_renderForm() {
		const {
			country,
			intl,
		} = this.props;

		const {
			showState,
		} = this.state;

		return (
			<FormRows>
				<FormRow
					htmlFor="company"
					label={intl.formatMessage(messages.company)}
				>
					<FieldStatus name="company">
						<TextField
							name="company"
							trimValue={true}
						/>
					</FieldStatus>
				</FormRow>

				<FormRow
					htmlFor="first_name"
					label={intl.formatMessage(messages.name)}
				>
					<FieldStatus name="first_name_last_name">
						<TextField
							name="first_name"
							placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
							trimValue={true}
							width={120}
						/>

						<TextField
							name="last_name"
							placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
							trimValue={true}
							width={150}
						/>
					</FieldStatus>
				</FormRow>

				<FormRow
					htmlFor="address"
					label={intl.formatMessage(messages.address)}
				>
					<FieldStatus name="address">
						<TextField
							name="address"
							trimValue={true}
						/>
					</FieldStatus>
				</FormRow>

				<FormRow
					htmlFor="zip_code"
					label={intl.formatMessage(messages.zipCodeAndCity)}
				>
					<FieldStatus name="city_zip_code">
						<TextField
							name="zip_code"
							trimValue={true}
							width={85}
						/>

						<TextField
							name="city"
							trimValue={true}
							width={185}
						/>
					</FieldStatus>
				</FormRow>

				<FormRow
					htmlFor="country"
					label={intl.formatMessage(messages.country)}
				>
					<FieldStatus name="country">
						<SelectField
							name="country"
							options={this._getCountryOptions()}
							searchable={true}
						/>
					</FieldStatus>
				</FormRow>

				{showState && (
					<AppearAnimation
						delay={50}
						direction={AppearAnimationDirection.Down}
					>
						<FormRow
							htmlFor="state"
							label={intl.formatMessage(isUSA(country) ? messages.state : messages.province)}
						>
							<FieldStatus name="state">
								<SelectField
									name="state"
									options={this._getStateOptions()}
									searchable={true}
								/>
							</FieldStatus>
						</FormRow>
					</AppearAnimation>
				)}
			</FormRows>
		);
	}



	render() {
		const {
			editMode,
		} = this.props;

		return editMode ? this._renderForm() : this._renderOverview();
	}

}

DeliveryAddressFields.propTypes = {
	country: PropTypes.string,
	deliveryAddress: PropTypes.object,
	editMode: PropTypes.bool.isRequired,
};



export default injectIntl(DeliveryAddressFields);
