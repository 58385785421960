import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import WithPermission from '~/components/logic/access/WithPermission';

import useAccountEnrichmentFieldsLimit from '~/hooks/useAccountEnrichmentFieldsLimit';
import useAccountId from '~/hooks/useAccountId';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	label: {
		id: 'ui.enrichmentFieldsOverview.addButtonLabel',
	},
	limitReachedHint: {
		id: 'ui.enrichmentFieldsOverview.addButtonLimitReachedHint',
	},
});



const AddEnrichmentFieldButton: React.FC = () => {
	const accountId = useAccountId();
	const websiteId = useWebsiteId();

	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const enrichmentFieldsLimit = useAccountEnrichmentFieldsLimit(accountId);

	const showTooltip = (
		enrichmentFieldDefinitions.isLoaded
		&& enrichmentFieldsLimit !== null
		&& enrichmentFieldDefinitions.count >= enrichmentFieldsLimit
	);

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
		>
			{({ isAllowed }) => (
				<Button
					disabled={isAllowed.yes === false || showTooltip}
					icon={(
						<BasicIcon
							color="#42cc67"
							type={BasicIconType.Plus}
						/>
					)}
					linkRouteName="website.detail.enrichmentFields"
					linkRouteParams={{
						accountId,
						action: 'create_enrichment_field',
						websiteId,
					}}
					size={ButtonSize.Small}
					style={ButtonStyle.Hollow}
					tooltip={showTooltip && (
						<FormattedMessage {...messages.limitReachedHint} />
					)}
					uppercase={true}
				>
					<FormattedMessage {...messages.label} />
				</Button>
			)}
		</WithPermission>
	);
};



export default AddEnrichmentFieldButton;
