import classNames from 'classnames';
import React from 'react';



export enum CodeNumberCellType {
	Default = 'default',
	New = 'new',
	Old = 'old',
}

type Props = {
	children?: React.ReactNode,
	/** Additional style object generated by react-virtualized */
	cssStyle?: React.CSSProperties,
	/** on mouse hover callback */
	onMouseEnter?: (e: any) => void,
	/** on mouse leave callback */
	onMouseLeave?: (e: any) => void,
	/** Specific type of cell reflectin eg. added value, removed value, ... */
	type?: CodeNumberCellType,
};



const CodeNumberCell: React.FC<Props> = (props) => {
	const {
		cssStyle,
		children,
		onMouseEnter,
		onMouseLeave,
		type = CodeNumberCellType.Default,
	} = props;

	const componentClasses = classNames({
		'code-number-cell': true,
		['code-number-cell--' + type + '-type']: true,
	});

	return (
		<div
			className={componentClasses}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={cssStyle}
		>
			{React.Children.map(
				children,
				(child) => {
					return (
						<div className="code-number-cell__content">
							{child}
						</div>
					);
				},
			)}
		</div>
	);
};



export default CodeNumberCell;
