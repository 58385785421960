import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import Copy from '~/components/logic/Copy';
import CancelInvitationConfirmationModal from '~/components/app/CancelInvitationConfirmationModal';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import Gravatar from '~/components/patterns/users/Gravatar';
import MultiselectFieldFilter from '~/components/logic/datatables/MultiselectFieldFilter';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import PlainTextFieldFilter from '~/components/logic/datatables/PlainTextFieldFilter';
import RemoveTeamMemberConfirmationModal from '~/components/app/RemoveTeamMemberConfirmationModal';
import ResizableDatatable, {
	type ResizableDatatableColumnDefinitions,
} from '~/components/logic/datatables/ResizableDatatable';
import Small from '~/components/patterns/typography/Small';
import SmallImageLabel from '~/components/patterns/images/SmallImageLabel';
import TableActionButton, {
	TableActionButtonIconType,
} from '~/components/patterns/tables/datatables/buttons/TableActionButton';
import UserRoleName from '~/components/names/UserRoleName';
import ChromeExtension from '~/components/logic/users/ChromeExtension';
import UserRoleAndAccess from '~/components/app/UserRoleAndAccess';
import WithPermission from '~/components/logic/access/WithPermission';

import useIsCurrentUser from '~/hooks/useIsCurrentUser';
import useModals from '~/hooks/useModals';
import useNavigateToUserDetail from '~/hooks/useNavigateToUserDetail';

import {
	IsConductorClient,
} from '~/config';

import {
	Field,
} from '~/model/members';

import {
	ObjectType,
} from '~/model/permissions';

import {
	extractInitials,
} from '~/model/users';

import encodeInBase64 from '~/utilities/encodeInBase64';



const columnMessages = defineMessages({
	[Field.BrowserExtension]: {
		id: 'ui.membersOverview.columns.browserExtension',
	},
	[Field.DisplayName]: {
		id: 'ui.membersOverview.columns.teamMember',
	},
	[Field.Role]: {
		id: 'ui.membersOverview.columns.role',
	},
	[Field.TwoFactor]: {
		id: 'ui.membersOverview.columns.2fa',
	},
});

const messages = defineMessages({
	editInvitee: {
		id: 'ui.membersOverviewTable.editInvitee',
	},
	invited: {
		id: 'ui.membersOverviewTable.invited',
	},
	removeSelf: {
		id: 'ui.membersOverviewTable.removeSelf',
	},
	you: {
		id: 'ui.membersOverviewTable.you',
	},
});

const twoFactorAuthenticationMessages = defineMessages({
	active: {
		id: 'ui.membersOverview.2fa.active',
	},
	inactive: {
		id: 'ui.membersOverview.2fa.inactive',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	filter: any,
	isLoading: boolean,
	members: ReadonlyArray<{
		__typename?: 'Invitation',
		displayName: GraphQL.Invitation['displayName'],
		email: GraphQL.Invitation['email'],
		id: GraphQL.Invitation['id'],
	} | {
		__typename?: 'User',
		displayName: GraphQL.User['displayName'],
		email: GraphQL.User['email'],
		id: GraphQL.User['id'],
		membership: {
			hasLimitedWebsitesAccess: boolean,
			role: GraphQL.UserRole,
			websites: ReadonlyArray<{
				id: CK.WebsiteId,
			}>,
		},
	}>,
	onFilterCallback: any,
	onRowClickCallback: any,
	onSortCallback: any,
	roles: ReadonlyArray<GraphQL.UserRole>,
	sortBy: any,
};

const MembersOverviewTable: React.FC<Props> = (props) => {
	const {
		accountId,
		filter,
		isLoading,
		members,
		onFilterCallback,
		onRowClickCallback,
		onSortCallback,
		roles,
		sortBy,
	} = props;

	const modals = useModals();
	const isCurrentUser = useIsCurrentUser();
	const navigationToUserDetail = useNavigateToUserDetail();

	const handleCancelInvitation = (invitation) => {
		if (accountId === null) {
			return;
		}

		modals.openModal(
			() => (
				<CancelInvitationConfirmationModal
					accountId={accountId}
					email={invitation.email}
				/>
			),
		);
	};

	const handleRemoveMember = (user) => {
		if (accountId === null) {
			return;
		}

		const userId = encodeInBase64(user.email);

		modals.openModal(
			() => (
				<RemoveTeamMemberConfirmationModal
					accountId={accountId}
					userId={userId}
				/>
			),
		);
	};

	const handleRowClick = React.useCallback(
		({ row }, e) => onRowClickCallback(row, e),
		[onRowClickCallback],
	);

	const rowGetter = React.useCallback(
		({ rowIndex }) => members[rowIndex],
		[members],
	);

	const rowIsClickable = React.useCallback(
		({ row }) => row.__typename === 'User',
		[],
	);

	function isInvitationRow(row) {
		return row.__typename === 'Invitation';
	}

	const columnDefinitions: ResizableDatatableColumnDefinitions<typeof rowGetter> = [
		{
			name: Field.DisplayName,
			render: {
				cell: ({ row }) => {
					const isInvitation = isInvitationRow(row);
					const isYou = isCurrentUser(row.email);

					return (
						<SmallImageLabel
							image={(
								<Gravatar
									email={row.email}
									key={row.email}
									placeholder={extractInitials(row.email)}
								/>
							)}
							label={(
								<Ellipsis>
									{row.displayName}

									{isInvitation && (
										<Small muted={true}> (<FormattedMessage {...messages.invited} />)</Small>
									)}

									{isYou && (
										<Small muted={true}> (<FormattedMessage {...messages.you} />)</Small>
									)}
								</Ellipsis>
							)}
						/>
					);
				},
				filter: ({ filterName, filterWidth, ref }) => (
					<PlainTextFieldFilter
						name={filterName}
						ref={ref}
						width={filterWidth}
					/>
				),
				label: () => (
					<FormattedMessage {...columnMessages[Field.DisplayName]} />
				),
			},
			resizable: true,
			sortOrder: true,
			width: 400,
		},
		{
			name: Field.Role,
			render: {
				cell: ({ row }) => {
					let content;

					if (isInvitationRow(row)) {
						content = (
							<UserRoleName role={row.role} />
						);
					} else {
						content = (
							<UserRoleAndAccess
								userAccountMembership={row.membership}
							/>
						);
					}

					return (
						<Ellipsis>{content}</Ellipsis>
					);
				},
				filter: ({ filterName, filterWidth, ref }) => (
					<MultiselectFieldFilter
						isOnlyLinkVisible={true}
						name={filterName}
						options={roles.map((role) => {
							return {
								name: role,
								title: (
									<UserRoleName role={role} />
								),
							};
						})}
						ref={ref}
						width={filterWidth}
					/>
				),
				label: () => (
					<FormattedMessage {...columnMessages[Field.Role]} />
				),
			},
			resizable: true,
			sortOrder: true,
			width: 230,
		},
		{
			name: Field.BrowserExtension,
			render: {
				cell: ({ row }) => !isInvitationRow(row) && (
					<ChromeExtension
						email={row.email}
						isChromeExtensionInstalled={row.isChromeExtensionInstalled}
						showLabel={false}
					/>
				),
				label: (
					<FormattedMessage {...columnMessages[Field.BrowserExtension]} />
				),
			},
			resizable: true,
			width: 140,
		},
		{
			name: Field.TwoFactor,
			render: {
				cell: ({ row }) => {
					const message = row.hasTwoFactorAuthentication ? 'active' : 'inactive';

					return (
						<FormattedMessage {...twoFactorAuthenticationMessages[message]} />
					);
				},
				filter: ({ filterName, filterWidth, ref }) => (
					<MultiselectFieldFilter
						isOnlyLinkVisible={true}
						name={filterName}
						options={[
							{
								name: 'true',
								title: <FormattedMessage {...twoFactorAuthenticationMessages.active} />,
							},
							{
								name: 'false',
								title: <FormattedMessage {...twoFactorAuthenticationMessages.inactive} />,
							},
						]}
						ref={ref}
						width={filterWidth}
					/>
				),
				label: () => (
					<FormattedMessage {...columnMessages[Field.TwoFactor]} />
				),
			},
			resizable: true,
			sortOrder: true,
			width: 200,
		},
		{
			clickable: false,
			name: 'member_actions',
			render: {
				cell: ({ row }) => {
					const detailRoute = navigationToUserDetail.createRoute(row.email);

					return (
						<ButtonsGroup>
							{isInvitationRow(row) && (
								<TableActionButton
									disabled={true}
									iconType={TableActionButtonIconType.CogWheel}
									tooltip={<FormattedMessage {...messages.editInvitee} />}
								/>
							)}

							{!isInvitationRow(row) && detailRoute !== undefined && (
								<TableActionButton
									iconType={TableActionButtonIconType.CogWheel}
									linkRouteName={detailRoute.name}
									linkRouteParams={detailRoute.params}
									tooltip={isCurrentUser(row.email) ? 'Edit your profile' : 'Edit team member'}
								/>
							)}

							{isCurrentUser(row.email) && (
								<TableActionButton
									disabled={true}
									iconType={TableActionButtonIconType.Delete}
									tooltip={(
										<Copy {...messages.removeSelf} />
									)}
								/>
							)}

							{!isCurrentUser(row.email) && isInvitationRow(row) && (
								<WithPermission
									action={GraphQL.ActionWithInvitation.ManageDetails}
									objectId={row.id}
									objectType={ObjectType.Invitation}
									showMessage={false}
								>
									{({ isAllowed, message }) => (
										<TableActionButton
											disabled={isAllowed.yes === false}
											iconType={TableActionButtonIconType.Delete}
											onClick={() => handleCancelInvitation(row)}
											tooltip={message ?? 'Cancel invitation'}
										/>
									)}
								</WithPermission>
							)}

							{!isCurrentUser(row.email) && !isInvitationRow(row) && (
								<WithPermission
									action={GraphQL.ActionWithAccountMembership.RemoveUser}
									objectId={`${accountId}/${row.email}`}
									objectType={ObjectType.AccountMembership}
									showMessage={false}
								>
									{({ isAllowed, message }) => (
										<TableActionButton
											disabled={isAllowed.yes === false}
											iconType={TableActionButtonIconType.Delete}
											onClick={() => handleRemoveMember(row)}
											tooltip={message ?? 'Remove team member'}
										/>
									)}
								</WithPermission>
							)}
						</ButtonsGroup>
					);
				},
				header: () => false,
			},
			width: 250,
		},
	].filter((column) => {
		if (IsConductorClient && column.name === Field.TwoFactor) {
			return false;
		}

		return true;
	});

	return (
		<ResizableDatatable
			blankSlate={!isLoading && members.length === 0 && (
				<NoSearchResults />
			)}
			columns={columnDefinitions}
			filter={filter}
			isLoading={isLoading}
			onFilterChange={onFilterCallback}
			onRowClick={handleRowClick}
			onSortChangeCallback={onSortCallback}
			rowGetter={rowGetter}
			rowIsClickable={rowIsClickable}
			rowsCount={members.length}
			sortBy={sortBy}
		/>
	);
};



export default MembersOverviewTable;
