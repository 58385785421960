import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PersonIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="translate(3 3)"
			>
				<path d="m6.1465 8.55621875c-3.2786875.82775-5.709 3.78812495-5.709 7.32393745h15.125c0-3.5358125-2.429625-6.49549995-5.7083125-7.32393745" />
				<path d="m12.125 4.875c0 2.278375-1.846625 4.125-4.125 4.125s-4.125-1.846625-4.125-4.125 1.846625-4.125 4.125-4.125 4.125 1.846625 4.125 4.125z" />
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default PersonIconBuilder;

