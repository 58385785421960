import React, {
	PureComponent,
} from 'react';



type Props = {
	children?: React.ReactNode,
	/** Limited width of whole container */
	width: React.CSSProperties['maxWidth'],
};



class WidthLimiter extends PureComponent<Props> {

	render() {
		const {
			children,
			width,
		} = this.props;

		const styles: React.CSSProperties = {
			maxWidth: width,
		};

		return (
			<div
				className="width-limiter"
				style={styles}
			>
				{children}
			</div>
		);
	}

}



export default WidthLimiter;
