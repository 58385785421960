import React from 'react';

import {
	ViewportContext,
} from '~/components/ViewportProvider';

import {
	Orientation,
} from '~/utilities/viewportSize';



function useViewportOrientation() {
	const viewport = React.useContext(ViewportContext);

	return React.useMemo(
		() => {
			return {
				isLandscape: viewport.orientation === Orientation.Landscape,
				isPortrait: viewport.orientation === Orientation.Portrait,
			};
		},
		[viewport.orientation],
	);
}



export default useViewportOrientation;
