import throttle from 'lodash/throttle';
import React from 'react';

import useViewportType from '~/hooks/useViewportType';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



export enum StickToScreenBottomPreset {
	Fullscreen = 'fullscreen',
	FullscreenDetail = 'fullscreen-detail',
}

type Props = {
	children?: RenderProp<ChildProps>,
	minHeight?: number,
	offset?: never,
	preset: StickToScreenBottomPreset,
	presetOffset?: number,
} | {
	children?: RenderProp<ChildProps>,
	minHeight?: number,
	offset: number,
	preset?: never,
	presetOffset?: never,
};

type ChildProps = {
	width: number,
	height: number,
};



const StickToScreenBottom: React.FC<Props> = (props) => {
	const {
		children,
		minHeight = 0,
		offset,
		preset,
		presetOffset = 0,
	} = props;

	const ref = React.useRef<HTMLDivElement | null>(null);
	const [height, setHeight] = React.useState<number>(minHeight);
	const [width, setWidth] = React.useState(0);

	const viewportType = useViewportType();
	let distanceToBottom: number = 0;

	if (preset === StickToScreenBottomPreset.Fullscreen) {
		distanceToBottom = 0 + presetOffset;
	} else if (preset === StickToScreenBottomPreset.FullscreenDetail) {
		if (viewportType.isSmall) {
			distanceToBottom = 0;
		} else if (viewportType.isMedium) {
			distanceToBottom = 15;
		} else if (viewportType.isLarge || viewportType.isExtraLarge) {
			distanceToBottom = 20;
		}

		distanceToBottom += presetOffset;
	} else {
		distanceToBottom = offset ?? 0;
	}



	React.useLayoutEffect(
		() => {
			if (ref.current === null) {
				return;
			}

			const div = ref.current;

			const resize = throttle(() => {
				const bounds = div.getBoundingClientRect();

				const height = Math.max(minHeight, window.innerHeight - bounds.top - distanceToBottom);

				setHeight(height);
				setWidth(bounds.width);
			}, 16, { leading: true, trailing: true });

			resize();
			window.addEventListener('resize', resize);

			return () => {
				window.removeEventListener('resize', resize);
			};
		},
		[
			distanceToBottom,
			minHeight,
			ref,
		],
	);



	return (
		<div
			data-testid="StickToScreenBottom"
			ref={ref}
			style={{ minHeight }}
		>
			{renderProp(children, { width, height })}
		</div>
	);
};

export default StickToScreenBottom;
