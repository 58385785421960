import type CK from '~/types/contentking';

import {
	useAccountDataRetentionInDaysQuery,
} from './useAccountDataRetentionInDays.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountDataRetentionInDays(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountDataRetentionInDaysQuery,
		accountId,
	);

	return data?.account?.dataRetentionInDays ?? null;
}



export default useAccountDataRetentionInDays;
