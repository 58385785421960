import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AdobeAnalyticsIconBuilder: IconBuilder = {
	icon: () => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<path
					d="M27.4017 26.9662H22.5102C22.2977 26.9702 22.0888 26.9092 21.9102 26.7911C21.7315 26.673 21.5912 26.503 21.507 26.3029L16.1968 13.5683C16.1829 13.5187 16.1539 13.4751 16.1139 13.4438C16.0739 13.4125 16.0252 13.3952 15.9749 13.3945C15.9246 13.3938 15.8755 13.4098 15.8347 13.44C15.794 13.4703 15.7638 13.5131 15.7487 13.5623L12.4394 21.6478C12.4214 21.6915 12.4143 21.7391 12.4186 21.7864C12.423 21.8336 12.4386 21.8791 12.4642 21.9186C12.4898 21.9581 12.5245 21.9906 12.5653 22.013C12.6061 22.0354 12.6516 22.0472 12.6979 22.0472H16.3355C16.4457 22.0472 16.5534 22.0805 16.6453 22.1429C16.7372 22.2053 16.8091 22.2941 16.8521 22.3981L18.4447 26.0331C18.4869 26.1351 18.5038 26.2463 18.4939 26.3566C18.484 26.4669 18.4477 26.5731 18.388 26.6655C18.3284 26.7579 18.2474 26.8337 18.1523 26.8862C18.0571 26.9387 17.9508 26.9661 17.8428 26.9662H4.60013C4.50053 26.9656 4.40262 26.9397 4.31513 26.8909C4.22765 26.8421 4.1533 26.7718 4.09871 26.6863C4.04413 26.6009 4.01101 26.5029 4.00231 26.4011C3.99361 26.2994 4.00959 26.197 4.04883 26.1031L12.4722 5.52935C12.5582 5.3116 12.7063 5.12553 12.8971 4.99544C13.0879 4.86534 13.3125 4.79726 13.5416 4.80009H18.3999C18.6291 4.79698 18.8538 4.86494 19.0446 4.99507C19.2355 5.1252 19.3835 5.31143 19.4694 5.52935L27.9511 26.1031C27.9903 26.1968 28.0063 26.299 27.9977 26.4007C27.9892 26.5023 27.9562 26.6002 27.9018 26.6856C27.8475 26.771 27.7734 26.8413 27.6861 26.8903C27.5988 26.9392 27.5011 26.9653 27.4017 26.9662Z"
					fill="#EB1101"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default AdobeAnalyticsIconBuilder;
