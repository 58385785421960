import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import AbstractTextField, {
	type AbstractTextFieldRef,
	AbstractTextFieldSize,
	AbstractTextFieldType,
} from '~/components/patterns/forms/fields/AbstractTextField';
import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import FieldDropdown from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdown';
import FieldDropdownSelectableTiles from '~/components/atoms/forms/components/builders/FieldDropdownSelectableTiles';
import IconPickerButton from '~/components/patterns/forms/fieldParts/buttons/IconPickerButton';

import useFormContext from '~/hooks/useFormContext';



const messages = defineMessages({
	clearButtonLabel: {
		id: 'ui.forms.iconPicker.clearIconButton.label',
		defaultMessage: 'Clear icon',
	},
	searchFieldPlaceholder: {
		id: 'ui.general.search.placeholder',
		defaultMessage: 'Search',
	},
});



type Props = {
	/** Additional attributes - like another HTML attributes we need to have it attached to core field */
	attributes?: React.AllHTMLAttributes<HTMLInputElement>,
	/** List of icons for icon picker */
	icons: Array<{
		icon: React.ReactElement,
		name: string,
	}>,
	name: string,
	/** Show search filter for icon picker */
	searchable?: boolean,
	trimValue?: boolean,
	width?: React.CSSProperties['width'],
};

const IconPickerTextField: React.FC<Props> = (props) => {
	const {
		attributes,
		icons,
		name,
		searchable,
		trimValue = true,
		width,
	} = props;

	const {
		defaultValues,
		isDisabled,
		onBlurHandler,
		onChangeHandler,
		onFocusHandler,
		onMountHandler,
		onUnmountHandler,
		values,
	} = useFormContext();

	const intl = useIntl();

	const dropdownRef = React.useRef<HTMLDivElement | null>(null);
	const fieldRef = React.useRef<AbstractTextFieldRef | null>(null);
	const searchFieldRef = React.useRef<AbstractTextFieldRef | null>(null);

	const doingFieldInteraction = React.useRef(false);
	const doingPickerInteraction = React.useRef(false);
	const [dropdownVisibility, setDropdownVisibility] = React.useState(false);
	const [hoveredIconName, setHoveredIconName] = React.useState<string | undefined>(undefined);
	const [selectedIconName, setSelectedIconName] = React.useState<string | undefined>(undefined);

	const additionalAttributes = Object.assign({}, attributes);

	if (isDisabled) {
		additionalAttributes.disabled = true;
	}

	const dropdownOutsideClickHandler = React.useCallback(
		(event) => {
			if (!dropdownRef.current || !dropdownVisibility) {
				return false;
			}

			if (!event) {
				event = window.event;
			}

			const target = event.target || event.srcElement;
			const isDropdownClickInside = dropdownRef.current.contains(target);
			const isFieldClickInside = fieldRef.current?.containsTarget(target);

			if (!isDropdownClickInside && !isFieldClickInside) {
				setDropdownVisibility(false);
				fieldRef.current?.focus();
			}
		},
		[
			dropdownRef,
			dropdownVisibility,
		],
	);

	const togglePickerVisibility = () => {
		if (dropdownVisibility) {
			setDropdownVisibility(false);

			fieldRef.current?.focus();
		} else {
			setDropdownVisibility(true);
		}
	};

	const iconPickerButtonClickHandler = (e) => {
		e.preventDefault();

		togglePickerVisibility();
	};

	const fieldMouseEnterHandler = (e) => {
		e.preventDefault();

		doingFieldInteraction.current = false;
	};

	const fieldMouseLeaveHandler = (e) => {
		e.preventDefault();

		doingFieldInteraction.current = false;
	};

	const fieldKeyUpHandler = (e) => {
		e.stopPropagation();

		if (dropdownVisibility) {
			// esc key
			if (e.keyCode === 27) {
				// we have to call it in async way to prevent closing modal
				setTimeout(() => {
					togglePickerVisibility();
				}, 0);
			}
		}
	};

	const iconMouseEnterHandler = (icon) => {
		setHoveredIconName(icon.name);
	};

	const iconMouseLeaveHandler = () => {
		setHoveredIconName(undefined);
	};

	const pickerMouseEnterHandler = (e) => {
		e.preventDefault();

		doingFieldInteraction.current = false;
		doingPickerInteraction.current = true;
	};

	const pickerMouseLeaveHandler = (e) => {
		e.preventDefault();

		doingFieldInteraction.current = false;
		doingPickerInteraction.current = false;
		setHoveredIconName(undefined);
	};

	const selectedIconSelectionHandler = (icon) => {
		setSelectedIconName(icon.name);

		if (searchable) {
			searchFieldRef.current?.focus();
		}

		togglePickerVisibility();

		onChangeHandler(name, {
			icon: icon.name,
			value: values[name] && values[name].value,
		}, {
			timeout: 250,
		});
	};

	const renderPickerFilter = () => {
		return (
			<AbstractTextField
				attributes={{
					autoComplete: 'off',
					placeholder: intl.formatMessage(messages.searchFieldPlaceholder),
				}}
				name={name + '__search'}
				onChangeCallback={(value) => {
					onChangeHandler(name + '__search', value, {
						timeout: 250,
					});
				}}
				onKeyUp={fieldKeyUpHandler}
				ref={searchFieldRef}
				size={AbstractTextFieldSize.XSmall}
				type={AbstractTextFieldType.Search}
				width={false}
			/>
		);
	};

	const renderIcon = (iconName) => {
		const selectedIconData = icons.find((obj) => obj.name === iconName);

		if (selectedIconData) {
			return selectedIconData.icon;
		}

		return undefined;
	};

	const renderSelectedIcon = () => {
		if (!selectedIconName && !hoveredIconName) {
			return <></>;
		}

		const iconName = hoveredIconName || selectedIconName;

		return (
			<AttachedIcon icon={renderIcon(iconName)}>
				{iconName}
			</AttachedIcon>
		);
	};

	const actionButtons = [
		(
			<IconPickerButton
				key="smiley-button"
				onClick={iconPickerButtonClickHandler}
				onMouseEnter={fieldMouseEnterHandler}
				onMouseLeave={fieldMouseLeaveHandler}
				popup={(
					<FieldDropdown
						contentMaxHeight={240}
						footer={renderSelectedIcon()}
						footerCta={selectedIconName ? (
							<Button
								icon={(
									<BasicIcon
										size={16}
										type={BasicIconType.CancelReversed}
									/>
								)}
								onClick={(event) => {
									// workaround for disappearing "Clear icon" button
									// to cooperate with click outside handler
									setTimeout(() => {
										event.preventDefault();
										setSelectedIconName(undefined);

										onChangeHandler(name, {
											icon: null,
											value: values[name] && values[name].value,
										});

										setDropdownVisibility(false);
									}, 0);
								}}
								size={ButtonSize.XXSmall}
								style={ButtonStyle.Hollow}
								uppercase={true}
							>
								<FormattedMessage {...messages.clearButtonLabel} />
							</Button>
						) : null}
						onMouseEnter={pickerMouseEnterHandler}
						onMouseLeave={pickerMouseLeaveHandler}
						ref={dropdownRef}
						searchField={searchable && renderPickerFilter()}
						width={300}
					>
						<FieldDropdownSelectableTiles
							items={icons.map((data) => {
								return {
									label: data.icon,
									name: data.name,
									selected: data.name === selectedIconName,
								};
							})}
							onItemClickCallback={selectedIconSelectionHandler}
							onItemMouseEnterCallback={iconMouseEnterHandler}
							onItemMouseLeaveCallback={iconMouseLeaveHandler}
						/>
					</FieldDropdown>
				)}
				popupVisible={dropdownVisibility}
			/>
		),
	];

	React.useEffect(
		() => {
			onMountHandler(name, {
				interacted: true,
			});

			setSelectedIconName((selectedIconName) => selectedIconName ?? defaultValues[name]?.icon);

			return () => {
				onUnmountHandler(name);
			};
		},
		[
			defaultValues,
			name,
			onMountHandler,
			onUnmountHandler,
		],
	);

	React.useEffect(
		() => {
			let timeout;
			if (searchable && dropdownVisibility) {
				timeout = setTimeout(() => {
					searchFieldRef.current?.focus();
				}, 100);
			}

			if (dropdownVisibility) {
				document.addEventListener('click', dropdownOutsideClickHandler, { capture: true });
			}

			return () => {
				document.removeEventListener('click', dropdownOutsideClickHandler, { capture: true });
				clearTimeout(timeout);
			};
		},
		[
			dropdownOutsideClickHandler,
			dropdownVisibility,
			searchable,
		],
	);

	return (
		<AbstractTextField
			attributes={additionalAttributes}
			buttons={!isDisabled ? actionButtons : []}
			name={name}
			onBlur={() => {
				if (!doingFieldInteraction.current && !doingPickerInteraction.current) {
					onBlurHandler(name);

					setDropdownVisibility(false);
				}
			}}
			onChangeCallback={(value) => {
				onChangeHandler(name, {
					icon: selectedIconName,
					value,
				}, {
					timeout: 250,
				});
			}}
			onFocus={() => {
				onFocusHandler(name);

				doingFieldInteraction.current = true;
				doingPickerInteraction.current = false;
			}}
			onMouseEnter={fieldMouseEnterHandler}
			onMouseLeave={fieldMouseLeaveHandler}
			onOverlayClick={togglePickerVisibility}
			overlay={selectedIconName && renderIcon(selectedIconName)}
			ref={fieldRef}
			trimValue={trimValue}
			value={defaultValues[name] && defaultValues[name].value}
			width={width}
		/>
	);
};



export default IconPickerTextField;
