import {
	format,
} from 'date-fns';
import React from 'react';

import DateLabel from '~/components/app/DateLabel';



type Props = {
	timestamp: Date,
};

const DateAndTimeLabel: React.FC<Props> = (props) => {
	const {
		timestamp,
	} = props;

	return (
		<>
			<DateLabel date={timestamp} />, {format(timestamp, 'p')}
		</>
	);
};



export default DateAndTimeLabel;
