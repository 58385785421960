import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import SubmitButton from '~/components/app/SubmitButton';



const messages = defineMessages({
	label: {
		id: 'ui.general.saveButton',
	},
});



const SaveSubmitButton: React.FC = () => {
	return (
		<SubmitButton>
			<FormattedMessage {...messages.label} />
		</SubmitButton>
	);
};



export default SaveSubmitButton;
