import {
	createExportViaApi,
} from '~/model/exports';

import {
	IssueCategoryName,
	PageIssue,
} from '~/model/issuesNew';

import {
	getFilter,
} from '~/model/pages';

import {
	currentDataSelector as currentIncidentsDataSelector,
} from '~/state/incidents/selectors';

import {
	filterSelector as pagesFilterSelector,
	sortBySelector as pagesSortBySelector,
	selectedPageIdSelector,
	selectedWebsiteSelector,
	urlStateSelector,
} from '~/state/ui/selectors';

import {
	currentDataSelector as alertPagesDataSelector,
	currentFilterSelector as alertPagesFilterSelector,
	currentSortBySelector as alertPagesSortBySelector,
} from '~/state/alertPages/selectors';

import {
	alertIdSelector,
	issuesOverviewIssueSelector,
} from '~/state/ui/content/selectors';

import {
	filterSelector as affectedPagesFilterSelector,
	categorySelector as affectedPagesSelectedCategorySelector,
	sortBySelector as affectedPagesSortBySelector,
} from '~/state/affectedPages/selectors';

import {
	totalSelector as affectedPagesTotalSelector,
} from '~/state/affectedPages/currentWebsiteSelectors';

import {
	totalSelector as affectedPagesComparisonTotalSelector,
} from '~/state/affectedPagesComparison/currentWebsiteSelectors';

import {
	currentFilterSelector as affectedPagesComparisonFilterSelector,
	sortBySelector as affectedPagesComparisonSortBySelector,
} from '~/state/affectedPagesComparison/selectors';

import {
	currentDataSelector as sitemapsListCurrentDataSelector,
	filterSelector as sitemapsListFilterSelector,
	sortBySelector as sitemapsListSortBySelector,
} from '~/state/sitemapsList/selectors';

import {
	dateFromSelector as historicalIssuesDateFromSelector,
	dateToSelector as historicalIssuesDateToSelector,
} from '~/state/websiteIssuesComparison/selectors';



const LARGE_EXPORT_THRESHOLD = 200000;
const LARGE_RELATIONS_EXPORT_THRESHOLD = 2000;



const issuesWithRelations = [
	IssueCategoryName.Images,
	IssueCategoryName.Links,
	PageIssue.ImagesAltAttribute,
	PageIssue.ImagesMixedTransport,
	PageIssue.LinksBroken,
	PageIssue.LinksRedirected,
	PageIssue.LinksToCanonicalized,
];



export function exportCurrentAlertPages() {
	return function (dispatch, getState) {
		const alertId = alertIdSelector(getState());
		const incidents = currentIncidentsDataSelector(getState());

		if (!alertId) {
			throw new Error();
		}

		const alertData = incidents.get(alertId) || incidents.get(alertId + '');

		if (!alertData) {
			throw new Error();
		}

		const websiteId = selectedWebsiteSelector(getState()).get('id');

		const criteria = {
			alertId,
			filter: alertPagesFilterSelector(getState()).toJS(),
			sortBy: alertPagesSortBySelector(getState()).toJS(),
		};

		const isLarge = alertPagesDataSelector(getState()).get('total') >= LARGE_EXPORT_THRESHOLD;

		return createExportViaApi(websiteId, 'alert_pages', criteria)
			.then((response) => {
				return Promise.resolve({
					exportData: response.export,
					isLarge,
				});
			});
	};
}



export function exportCurrentPages(enabledWebsiteColumnsList, totalFilteredPages) {
	return function (dispatch, getState) {
		const websiteId = selectedWebsiteSelector(getState()).get('id');

		const criteria = {
			columns: enabledWebsiteColumnsList.toArray(),
			filter: getFilter(pagesFilterSelector(getState())),
			sortBy: pagesSortBySelector(getState()).toJS(),
		};

		const isLarge = totalFilteredPages !== undefined && totalFilteredPages >= LARGE_EXPORT_THRESHOLD;

		return createExportViaApi(websiteId, 'pages', criteria)
			.then((response) => {
				return Promise.resolve({
					exportData: response.export,
					isLarge,
				});
			});
	};
}



export function exportCurrentAffectedPages() {
	return function (dispatch, getState) {
		const websiteId = selectedWebsiteSelector(getState()).get('id');

		const issueCategory = affectedPagesSelectedCategorySelector(getState());

		const criteria = {
			filter: affectedPagesFilterSelector(getState()).toJS(),
			issueCategory,
			sortBy: affectedPagesSortBySelector(getState()).toJS(),
		};

		if (criteria.filter.is_indexable) {
			if (criteria.filter.is_indexable.length === 1) {
				criteria.filter.is_indexable = criteria.filter.is_indexable[0];
			} else if (criteria.filter.is_indexable.length > 1) {
				criteria.filter.is_indexable = 'invalid';
			}
		}

		if (criteria.filter.is_linked) {
			if (criteria.filter.is_linked.length === 1) {
				criteria.filter.is_linked = criteria.filter.is_linked[0];
			} else if (criteria.filter.is_linked.length > 1) {
				criteria.filter.is_linked = 'invalid';
			}
		}

		if (criteria.filter.is_secured) {
			if (criteria.filter.is_secured.length === 1) {
				criteria.filter.is_secured = criteria.filter.is_secured[0];
			} else if (criteria.filter.is_secured.length > 1) {
				criteria.filter.is_secured = 'invalid';
			}
		}

		if (criteria.filter.xml_sitemap_status && criteria.filter.xml_sitemap_status.length === 1) {
			if (criteria.filter.xml_sitemap_status.indexOf('incorrectly_present') !== -1) {
				criteria.filter.issue = {
					issue: 'xml_sitemap_incorrectly_present',
				};
			} else {
				criteria.filter.issue = {
					issue: 'is_in_sitemap',
				};
			}
		}

		delete criteria.filter.xml_sitemap_status;

		const largeExportThreshold = (
			issuesWithRelations.includes(issueCategory)
				? LARGE_RELATIONS_EXPORT_THRESHOLD
				: LARGE_EXPORT_THRESHOLD
		);

		const isLarge = affectedPagesTotalSelector(getState()) >= largeExportThreshold;

		return createExportViaApi(websiteId, 'affected_pages', criteria)
			.then((response) => {
				return Promise.resolve({
					exportData: response.export,
					isLarge,
				});
			});
	};
}



export function exportCurrentSingleIssueAffectedPages() {
	return function (dispatch, getState) {
		const websiteId = selectedWebsiteSelector(getState()).get('id');

		const issueType = getState().get('ui').get('content').get('issuesOverviewIssue');

		const criteria = {
			filter: affectedPagesFilterSelector(getState()).toJS(),
			issueType,
			sortBy: affectedPagesSortBySelector(getState()).toJS(),
		};

		const largeExportThreshold = (
			issuesWithRelations.includes(issueType)
				? LARGE_RELATIONS_EXPORT_THRESHOLD
				: LARGE_EXPORT_THRESHOLD
		);

		const isLarge = affectedPagesTotalSelector(getState()) >= largeExportThreshold;

		return createExportViaApi(websiteId, 'single_issue_affected_pages', criteria)
			.then((response) => {
				return Promise.resolve({
					exportData: response.export,
					isLarge,
				});
			});
	};
}



export function exportCurrentSitemapsList() {
	return function (dispatch, getState) {
		const websiteId = selectedWebsiteSelector(getState()).get('id');

		const criteria = {
			filter: sitemapsListFilterSelector(getState()).toJS(),
			sortBy: sitemapsListSortBySelector(getState()).toJS(),
		};

		const isLarge = sitemapsListCurrentDataSelector(getState()).get('total') >= LARGE_EXPORT_THRESHOLD;

		return createExportViaApi(websiteId, 'sitemaps_list', criteria)
			.then((response) => {
				return Promise.resolve({
					exportData: response.export,
					isLarge,
				});
			});
	};
}



export function exportHistoricalSingleIssueAffectedPages() {
	return function (dispatch, getState) {
		const websiteId = selectedWebsiteSelector(getState()).get('id');

		const issueType = issuesOverviewIssueSelector(getState());

		const criteria = {
			filter: affectedPagesComparisonFilterSelector(getState()).toJS(),
			issueType,
			sortBy: affectedPagesComparisonSortBySelector(getState()).toJS(),
			startDate: historicalIssuesDateFromSelector(getState()),
			endDate: historicalIssuesDateToSelector(getState()),
		};

		const largeExportThreshold = (
			issuesWithRelations.includes(issueType)
				? LARGE_RELATIONS_EXPORT_THRESHOLD
				: LARGE_EXPORT_THRESHOLD
		);

		const isLarge = affectedPagesComparisonTotalSelector(getState()) >= largeExportThreshold;

		return createExportViaApi(websiteId, 'single_issue_historical_affected_pages', criteria)
			.then((response) => {
				return Promise.resolve({
					exportData: response.export,
					isLarge,
				});
			});
	};
}



export function exportPageRelations() {
	return (dispatch, getState) => {
		const relationType = urlStateSelector(getState()).params.pageDetailLinksType;

		const type2ExportType = {
			incoming: 'incoming_links_internal',
			outgoing_internal: 'outgoing_links_internal',
			outbound: 'outgoing_links_external',
			canonicals: 'incoming_canonicals_internal',
			redirects: 'incoming_redirects_internal',
		};

		const websiteId = selectedWebsiteSelector(getState()).get('id');

		const criteria = {
			relationType: type2ExportType[relationType],
			urlId: selectedPageIdSelector(getState()),
		};

		return createExportViaApi(websiteId, 'page_relations', criteria)
			.then((response) => {
				return Promise.resolve({
					exportData: response.export,
					isLarge: false,
				});
			});
	};
}

