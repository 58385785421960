import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BulkSaveDefinitionsButton from '~/components/logic/alertsConfiguration/BulkSaveDefinitionsButton';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import Form from '~/components/atoms/forms/basis/Form';
import MessagingAppChannelFieldRow from '~/components/app/MessagingAppChannelFieldRow';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';

import useBulkEditedAlertDefinitions from '~/hooks/useBulkEditedAlertDefinitions';
import useBulkUpdateAlertDefinitionsMessagingAppChannels from '~/hooks/useBulkUpdateAlertDefinitionsMessagingAppChannels';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useSetFlashMessage from '~/hooks/useSetFlashMessage';
import useUglyMicrosoftTeamsMessagingAppChannelOverlay from '~/hooks/useUglyMicrosoftTeamsMessagingAppChannelOverlay';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	description: {
		id: 'ui.alertsConfiguration.modal.bulkEditMessagingApp.description',
	},
	headerLabel: {
		id: 'ui.general.settings',
	},
	headerValue: {
		id: 'ui.alertsConfiguration.modal.bulk.header',
	},
});

const validations = {
	messagingAppChannelId: [
		{
			message: '',
			field: 'messagingAppChannelId',
			rule: ({ values, name }) => {
				return values[name] !== '';
			},
		},
	],
};



const EditAlertDefinitionsMessagingAppModal: React.FC = () => {
	const bulkUpdateAlertDefinitionsMessagingAppChannels = useBulkUpdateAlertDefinitionsMessagingAppChannels();
	const classicFormBehavior = useClassicFormBehavior();
	const selectedAlertDefinitions = useBulkEditedAlertDefinitions();
	const setFlashMessage = useSetFlashMessage();
	const websiteId = useWebsiteId();

	const {
		addMicrosoftTeamsMessagingAppChannelOverlay,
		initiateAddMicrosoftTeamsMessagingAppChannel,
		messagingAppChannelFieldRowRef,
	} = useUglyMicrosoftTeamsMessagingAppChannelOverlay();

	const handleSubmit = React.useCallback(
		async (values) => {
			await bulkUpdateAlertDefinitionsMessagingAppChannels(
				selectedAlertDefinitions.ids,
				values.messagingAppChannelId === 'none'
					? []
					: [values.messagingAppChannelId],
				websiteId,
			);

			setFlashMessage({
				message: 'alertDefinitionsEdited',
				messageVariables: {
					number__alertDefinitions: selectedAlertDefinitions.count,
				},
				route: 'website.alerts.configuration',
				style: 'success',
			});

			classicFormBehavior.finish();
		},
		[
			bulkUpdateAlertDefinitionsMessagingAppChannels,
			classicFormBehavior,
			selectedAlertDefinitions,
			setFlashMessage,
			websiteId,
		],
	);

	let defaultMessagingAppChannelId: string | undefined;

	selectedAlertDefinitions.all.forEach((alertDefinition) => {
		const messagingAppChannel = alertDefinition.messagingAppChannels[0];

		if (defaultMessagingAppChannelId === undefined) {
			if (messagingAppChannel === undefined) {
				defaultMessagingAppChannelId = 'none';
			} else {
				defaultMessagingAppChannelId = messagingAppChannel.id;
			}
		} else if (
			defaultMessagingAppChannelId !== (messagingAppChannel === undefined ? 'none' : messagingAppChannel.id)
		) {
			defaultMessagingAppChannelId = '';
		}
	});

	return (
		<ModalContainer
			gapsSize={2}
			header={(
				<ModalHeader
					iconType={ModalHeaderIconType.CogWheel}
					title={(
						<FormattedMessage {...messages.headerLabel} />
					)}
					titleValue={(
						<FormattedMessage
							{...messages.headerValue}
							values={{
								count__definitions: selectedAlertDefinitions.count,
							}}
						/>
					)}
				/>
			)}
			headerGapsSize={1}
			isLoading={defaultMessagingAppChannelId === undefined}
		>
			<DisabledContent
				disabledContent={!!addMicrosoftTeamsMessagingAppChannelOverlay}
				disabledOverlay={addMicrosoftTeamsMessagingAppChannelOverlay}
			>
				<ModalTextSection>
					<RichText>
						<p>
							<FormattedMessage {...messages.description} />
						</p>
					</RichText>
				</ModalTextSection>

				{defaultMessagingAppChannelId !== undefined && (
					<Form
						defaultValues={{
							messagingAppChannelId: defaultMessagingAppChannelId,
						}}
						onSuccess={handleSubmit}
						validations={validations}
					>
						<CenteredFormWrapper>
							<MessagingAppChannelFieldRow
								name="messagingAppChannelId"
								onInitiateAddMicrosoftChannel={initiateAddMicrosoftTeamsMessagingAppChannel}
								ref={messagingAppChannelFieldRowRef}
							/>
						</CenteredFormWrapper>

						<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
							<CancelButton />

							<BulkSaveDefinitionsButton
								numberOfAlertDefinitions={selectedAlertDefinitions.count}
							/>
						</ModalButtonsLayout>
					</Form>
				)}
			</DisabledContent>
		</ModalContainer>
	);
};



export default EditAlertDefinitionsMessagingAppModal;
