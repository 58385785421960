import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class FieldOptionsGroup extends Component {

	render() {
		const {
			children,
			columnMinWidth,
			columnsCount,
		} = this.props;

		const elements = React.Children.toArray(children).filter((child) => !!child);
		let cellStyle = {};

		if (columnsCount > 1) {
			cellStyle = {
				flex: '1 0 ' + (100 / columnsCount) + '%',
			};

			if (columnMinWidth) {
				cellStyle.minWidth = columnMinWidth;
			}
		}

		const componentClasses = classNames({
			'field-options-group': true,
			'field-options-group--grid-enabled': columnsCount > 1,
		});

		return (
			<div className={componentClasses}>
				{elements.map((child, index) => {
					return (
						<div
							className="field-options-group__option"
							key={'option-' + index}
							style={cellStyle}
						>
							<div className="field-options-group__option-container">
								{child}
							</div>
						</div>
					);
				})}

				{[...Array(columnsCount)].map((_, index) => {
					// these blank elements prevent collapsing of grid when having minimum width set
					return (
						<div
							className="field-options-group__option field-options-group__option--blank"
							key={'blank-option-' + index}
							style={cellStyle}
						/>
					);
				})}
			</div>
		);
	}

}

FieldOptionsGroup.defaultProps = {
	columnsCount: 1,
};

FieldOptionsGroup.propTypes = {
	/** Minimum width of column (to prevent overflowing) */
	columnMinWidth: PropTypes.number,
	/** Possibility to display options in columns */
	columnsCount: PropTypes.number,
};



export default FieldOptionsGroup;
