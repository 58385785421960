type GetPropertyCallback<TItem> = (item: TItem) => any;



export default function sortArrayByProperty<TItem>(list: ReadonlyArray<TItem>, getProperty: GetPropertyCallback<TItem>) {
	return [...list].sort(
		(itemA: TItem, itemB: TItem) => {
			const propertyA = getProperty(itemA);
			const propertyB = getProperty(itemB);

			if (propertyA < propertyB) {
				return -1;
			}

			if (propertyA > propertyB) {
				return 1;
			}

			return 0;
		},
	);
}
