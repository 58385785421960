import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GlobalFormMessage from '~/components/atoms/forms/basis/GlobalFormMessage';



const messages = defineMessages({
	text: {
		id: 'ui.3dsecure.failure',
	},
});



const PaymentAuthorizationError: React.FC = () => {
	return (
		<GlobalFormMessage name="authorization-failed">
			<FormattedMessage {...messages.text} />
		</GlobalFormMessage>
	);
};



export default PaymentAuthorizationError;
