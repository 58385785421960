import React from 'react';



const Context = React.createContext<string | null>(null);



type Props = {
	children?: React.ReactNode,
	websiteId: string | null,
};



const WebsiteIdProvider: React.FC<Props> = (props) => {
	const {
		children,
		websiteId,
	} = props;

	return (
		<Context.Provider value={websiteId}>
			{children}
		</Context.Provider>
	);
};



export default WebsiteIdProvider;

export {
	Context as WebsiteIdContext,
};
