import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const FormWrapperFooter: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="form-wrapper-footer">
			{children}
		</div>
	);
};



export default FormWrapperFooter;
