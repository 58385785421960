import React from 'react';

import type CK from '~/types/contentking';

import AdminAccountDestructConfirmationModal from '~/components/app/AdminAccountDestructConfirmationModal';

import {
	useDeleteAccountMutation,
} from './AdminRemoveAccountModal.gql';

import useAccountName from '~/hooks/useAccountName';
import useLogout from '~/hooks/useLogout';

import {
	navigate,
} from '~/routing/router';



type Props = {
	accountId: CK.AccountId | null,
};

const AdminRemoveAccountModal: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountName = useAccountName(accountId);
	const logout = useLogout();

	const [deleteAccount] = useDeleteAccountMutation();

	const handleSubmit = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			const { data } = await deleteAccount({
				variables: {
					accountId,
				},
			});

			const memberships = data?.DeleteAccount.query.authenticatedSession?.memberships ?? [];

			if (memberships.length > 0) {
				navigate('account');
			} else {
				await logout();
			}
		},
		[
			accountId,
			deleteAccount,
			logout,
		],
	);

	return (
		<AdminAccountDestructConfirmationModal
			accountId={accountId}
			confirmButton="Delete Account"
			description={(
				<p>
					Delete account <strong>{accountName} ({accountId})</strong>.
				</p>
			)}
			onConfirmation={handleSubmit}
			titleAction="Delete"
			titleObject="Account"
		/>
	);
};



export default AdminRemoveAccountModal;
