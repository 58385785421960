import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useUpdateWebsiteAwsAccountIdMutation,
	useWebsiteAwsAccountIdQuery,
} from '~/components/app/UpdateWebsiteAwsAccountIdform.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	awsAccountId: {
		id: 'ui.updateWebsiteAwsAccountIdForm.awsAccountId',
	},
	awsAccountIdValidation: {
		id: 'ui.updateWebsiteAwsAccountIdForm.awsAccountIdValidation',
	},
	description: {
		id: 'ui.updateWebsiteAwsAccountIdForm.description',
	},
	next: {
		id: 'ui.general.next',
	},
});

const validations = {
	validateAwsAccountId: validateField(
		'awsAccountId',
		(f) => ([
			f.custom({
				message: (
					<FormattedMessage {...messages.awsAccountIdValidation} />
				),
				rule: ({ value }) => {
					return /^[0-9]{12}/i.test(value);
				},
			}),
		]),
	),
};



const UpdateWebsiteAwsAccountIdForm: React.FC = () => {
	const websiteId = useWebsiteId();
	const classicFormBehavior = useClassicFormBehavior();

	const [updateWebsiteAwsAccountId] = useUpdateWebsiteAwsAccountIdMutation();
	const { data, loading } = useWebsiteAwsAccountIdQuery({
		variables: {
			websiteId,
		},
	});

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteAwsAccountId({
				variables: {
					awsAccountId: values.awsAccountId,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			updateWebsiteAwsAccountId,
			websiteId,
		],
	);

	const awsAccountId = data?.website?.logFileAnalysisForeignAwsAccountId ?? null;

	return (
		<Form
			defaultDataHasChanged={true}
			defaultValues={{
				awsAccountId,
			}}
			isDisabled={loading}
			key={loading ? 'loading' : 'ready'}
			onSuccess={handleSubmit}
			validations={validations}
		>
			<ModalTextSection>
				<RichText>
					<Copy
						{...messages.description}
						values={{
							linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudfront-integration/#2-configuring-cloudfront-standard-logging'),
						}}
					/>
				</RichText>
			</ModalTextSection>

			<CenteredFormWrapper>
				<FormRow
					label={(
						<FormattedMessage {...messages.awsAccountId} />
					)}
				>
					<FieldStatus name="validateAwsAccountId">
						<TextField
							attributes={{
								maxLength: 12,
							}}
							name="awsAccountId"
						/>
					</FieldStatus>
				</FormRow>
			</CenteredFormWrapper>

			<ModalContentSection enforceReadableTextLength={true}>
				<FormErrorMessages />
			</ModalContentSection>

			<ButtonsLayout>
				<CancelButton />

				<SubmitButton>
					<FormattedMessage {...messages.next} />
				</SubmitButton>
			</ButtonsLayout>
		</Form>
	);
};



export default UpdateWebsiteAwsAccountIdForm;
