import type CK from '~/types/contentking';

import useAffectedLinksFilter from '~/hooks/useAffectedLinksFilter';
import useCountPages from '~/hooks/useCountPages';

import {
	type IssueName,
} from '~/model/issuesNew';



function useCountAffectedLinks(
	issueName: IssueName,
	websiteId: CK.WebsiteId,
) {
	const filter = useAffectedLinksFilter(issueName);

	return useCountPages({
		filter: filter ?? {},
		pollIntervalInMilliseconds: 30000,
		skip: filter === null,
		websiteId,
	});
}



export default useCountAffectedLinks;
