/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountTariffQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountTariffQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly tariff: string } | null };


export const AccountTariffDocument = gql`
    query AccountTariff($accountId: AccountId!) {
  account(id: $accountId) {
    id
    tariff
  }
}
    `;

/**
 * __useAccountTariffQuery__
 *
 * To run a query within a React component, call `useAccountTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTariffQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountTariffQuery(baseOptions: Apollo.QueryHookOptions<AccountTariffQuery, AccountTariffQueryVariables> & ({ variables: AccountTariffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTariffQuery, AccountTariffQueryVariables>(AccountTariffDocument, options);
      }
export function useAccountTariffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTariffQuery, AccountTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTariffQuery, AccountTariffQueryVariables>(AccountTariffDocument, options);
        }
export function useAccountTariffSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountTariffQuery, AccountTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountTariffQuery, AccountTariffQueryVariables>(AccountTariffDocument, options);
        }
export type AccountTariffQueryHookResult = ReturnType<typeof useAccountTariffQuery>;
export type AccountTariffLazyQueryHookResult = ReturnType<typeof useAccountTariffLazyQuery>;
export type AccountTariffSuspenseQueryHookResult = ReturnType<typeof useAccountTariffSuspenseQuery>;
export type AccountTariffQueryResult = Apollo.QueryResult<AccountTariffQuery, AccountTariffQueryVariables>;