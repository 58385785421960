import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ComplexListField, {
	type ComplexListFieldRendererInput,
	type ComplexListFieldValidationInput,
} from '~/components/logic/customElements/extractionSteps/ComplexListField';
import Copy from '~/components/logic/Copy';
import TextField from '~/components/atoms/forms/components/TextField';



const messages = defineMessages({
	addButton: {
		id: 'ui.cookiesSetup.addButton',
	},
	columnName: {
		id: 'ui.cookiesSetup.columns.name',
	},
	columnValue: {
		id: 'ui.cookiesSetup.columns.value',
	},
	formErrorCannotContainSemicolon: {
		id: 'ui.formErrors.cannotContainSemicolon',
	},
});



type Props = {
	name: string,
};

const CookiesField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	const fields = React.useMemo(
		() => {
			return [
				{
					defaultValue: '',
					label: (
						<FormattedMessage {...messages.columnName} />
					),
					name: 'name',
					renderer: ({ fieldName }: ComplexListFieldRendererInput) => (
						<TextField
							name={fieldName}
							width="100%"
						/>
					),
					validation: ({ f, getItemValue }: ComplexListFieldValidationInput) => ([
						f.when(
							({ values }) => (getItemValue(values, 'value')?.trim() ?? '') !== '',
						),
						f.validateNonEmpty(),
						f.custom({
							message: (
								<Copy {...messages.formErrorCannotContainSemicolon} />
							),
							rule: ({ value }) => /^[^;]*$/.test(value),
						}),
					]),
					width: 220,
				},
				{
					defaultValue: '',
					label: (
						<FormattedMessage {...messages.columnValue} />
					),
					name: 'value',
					renderer: ({ fieldName }: ComplexListFieldRendererInput) => (
						<TextField
							name={fieldName}
							width="100%"
						/>
					),
					validation: ({ f, getItemValue }: ComplexListFieldValidationInput) => ([
						f.when(
							({ values }) => (getItemValue(values, 'name')?.trim() ?? '') !== '',
						),
						f.validateNonEmpty(),
						f.custom({
							message: (
								<Copy {...messages.formErrorCannotContainSemicolon} />
							),
							rule: ({ value }) => /^[^;]*$/.test(value),
						}),
					]),
				},
			];
		},
		[],
	);

	return (
		<ComplexListField
			addButtonLabel={(
				<FormattedMessage {...messages.addButton} />
			)}
			addExtraEmptyRow={false}
			fields={fields}
			name={name}
			showAddButton={true}
			showRowNumbers={false}
		/>
	);
};



export default CookiesField;
