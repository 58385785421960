/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiateSepaDirectDebitSetupMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type InitiateSepaDirectDebitSetupMutation = { readonly __typename?: 'Mutation', readonly InitiateSepaDirectDebitSetup: { readonly __typename?: 'InitiateSepaDirectDebitSetupResult', readonly id: CK.ID, readonly clientSecret: string } };


export const InitiateSepaDirectDebitSetupDocument = gql`
    mutation InitiateSepaDirectDebitSetup($accountId: AccountId!) {
  InitiateSepaDirectDebitSetup(accountId: $accountId) {
    id
    clientSecret
  }
}
    `;
export type InitiateSepaDirectDebitSetupMutationFn = Apollo.MutationFunction<InitiateSepaDirectDebitSetupMutation, InitiateSepaDirectDebitSetupMutationVariables>;

/**
 * __useInitiateSepaDirectDebitSetupMutation__
 *
 * To run a mutation, you first call `useInitiateSepaDirectDebitSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateSepaDirectDebitSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateSepaDirectDebitSetupMutation, { data, loading, error }] = useInitiateSepaDirectDebitSetupMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useInitiateSepaDirectDebitSetupMutation(baseOptions?: Apollo.MutationHookOptions<InitiateSepaDirectDebitSetupMutation, InitiateSepaDirectDebitSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateSepaDirectDebitSetupMutation, InitiateSepaDirectDebitSetupMutationVariables>(InitiateSepaDirectDebitSetupDocument, options);
      }
export type InitiateSepaDirectDebitSetupMutationHookResult = ReturnType<typeof useInitiateSepaDirectDebitSetupMutation>;
export type InitiateSepaDirectDebitSetupMutationResult = Apollo.MutationResult<InitiateSepaDirectDebitSetupMutation>;
export type InitiateSepaDirectDebitSetupMutationOptions = Apollo.BaseMutationOptions<InitiateSepaDirectDebitSetupMutation, InitiateSepaDirectDebitSetupMutationVariables>;