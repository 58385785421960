import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useAlertDefinitionDependencies from '~/hooks/useAlertDefinitionDependencies';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	GoogleAnalyticsV3Columns,
	GoogleAnalyticsV4Columns,
} from '~/model/pagesColumns';

import {
	type SegmentDefinition,
	dependsOnColumns,
} from '~/model/segments';



type GoogleAnalyticsDependencies = {
	hasDependencies: boolean,
	alertDefinitions: ReadonlyArray<GraphQL.AlertDefinition>,
	segments: ReadonlyArray<SegmentDefinition>,
};

function useGoogleAnalyticsDependencies(
	websiteId: CK.WebsiteId,
	version: GraphQL.GoogleAnalyticsVersion,
): GoogleAnalyticsDependencies {
	const alertDefinitionDependencies = useAlertDefinitionDependencies(websiteId);
	const segments = useWebsiteSegmentDefinitions(websiteId);

	const columns = (
		version === GraphQL.GoogleAnalyticsVersion.V4
			? GoogleAnalyticsV4Columns
			: GoogleAnalyticsV3Columns
	);

	return React.useMemo(
		() => {
			let dependentAlertDefinitions: Array<GraphQL.AlertDefinition> = [];

			columns.forEach((column) => {
				const columnDependentAlertDefinitions = alertDefinitionDependencies.getAlertDefinitionsDependingOnField(column);
				dependentAlertDefinitions = dependentAlertDefinitions.concat(columnDependentAlertDefinitions);
			});

			const dependentSegments = segments.listAll().filter((segment) => {
				return columns.some((column) => {
					return dependsOnColumns(
						segments.listAll(),
						segment,
						[column],
					);
				});
			});

			return {
				hasDependencies: (
					dependentAlertDefinitions.length !== 0 || dependentSegments.length !== 0
				),
				alertDefinitions: dependentAlertDefinitions,
				segments: dependentSegments,
			};
		},
		[
			alertDefinitionDependencies,
			columns,
			segments,
		],
	);
}



export default useGoogleAnalyticsDependencies;
