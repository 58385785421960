import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import Copy from '~/components/logic/Copy';
import RadioList from '~/components/atoms/forms/components/RadioList';
import Small from '~/components/patterns/typography/Small';

import {
	WebsiteAccess,
} from '~/model/users';



const messages = defineMessages({
	[WebsiteAccess.All]: {
		id: 'ui.websiteAccess.allWebsites.option',
	},
	[WebsiteAccess.Selected]: {
		id: 'ui.websiteAccess.selectedWebsites.option',
	},
});



type Props = {
	name: string,
};

const LimitedWebsitesAccessField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	const scopes = [
		WebsiteAccess.All,
		WebsiteAccess.Selected,
	];

	return (
		<RadioList
			items={scopes.map((scope) => {
				return {
					label: (
						<Copy
							{...messages[scope]}
							values={{
								small: (chunks) => (
									<Small muted={true}>{chunks}</Small>
								),
							}}
						/>
					),
					value: scope,
				};
			})}
			name={name}
			width={false}
		/>
	);
};



export default React.memo(LimitedWebsitesAccessField);
