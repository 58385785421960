import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AccountIntegrationsForm from '~/components/app/AccountIntegrationsForm';
import AccountProfileGroup from './formGroups/AccountProfileGroup';
import AccountSettingsContextActions from './navigations/AccountSettingsContextActions';
import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import BillingDetailsGroup from './formGroups/BillingDetailsGroup';
import ConnectedAccountsGroup from './formGroups/ConnectedAccountsGroup';
import FormsList from '~/components/atoms/lists/FormsList';
import InvoicesGroup from './formGroups/InvoicesGroup';
import ModernBillingDetailsGroup from '~/components/app/BillingDetailsGroup';
import MonitoringGroup from '~/components/logic/accountSettings/formGroups/MonitoringGroup';
import OrganizationAccessGroup from './formGroups/OrganizationAccessGroup';
import Sections from '../lego/Sections';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';
import SubscriptionGroup from './formGroups/SubscriptionGroup';

import useAccountAgencyConnections from '~/hooks/useAccountAgencyConnections';
import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';
import useAccountCanSignup from '~/hooks/useAccountCanSignup';
import useAccountHasBillingHistory from '~/hooks/useAccountHasBillingHistory';
import useAccountId from '~/hooks/useAccountId';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountProblems from '~/hooks/useAccountProblems';
import useAccountState from '~/hooks/useAccountState';
import useInHomeAccount from '~/hooks/useInHomeAccount';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';



enum Section {
	AgencyAccess = 'agencyAccess',
	BillingDetails = 'billingDetails',
	IntegrationTokens = 'integrationTokens',
	Invoices = 'invoices',
	Monitoring = 'monitoring',
	OrganizationAccess = 'organizationAccess',
	Profile = 'accountProfile',
	Subscription = 'subscription',
}

const SECTIONS_ROUTES = {
	[Section.AgencyAccess]: 'account.settings.agencyAccess',
	[Section.BillingDetails]: 'account.settings.billing',
	[Section.IntegrationTokens]: 'account.settings.integrationTokens',
	[Section.Invoices]: 'account.settings.invoices',
	[Section.Monitoring]: 'account.settings.monitoring',
	[Section.OrganizationAccess]: 'account.settings.organizationAccess',
	[Section.Profile]: 'account.settings',
	[Section.Subscription]: 'account.settings.subscription',
};



const messages = defineMessages({
	[Section.AgencyAccess]: {
		id: 'ui.websites.formGroups.connectedAccounts',
	},
	[Section.BillingDetails]: {
		id: 'ui.billing.formGroups.billingDetails',
	},
	[Section.IntegrationTokens]: {
		id: 'ui.websites.formGroups.integrationTokens',
	},
	[Section.Invoices]: {
		id: 'ui.billing.formGroups.invoices',
	},
	[Section.Monitoring]: {
		id: 'ui.websites.formGroups.monitoring',
	},
	[Section.OrganizationAccess]: {
		id: 'ui.websites.formGroups.organizationAccess',
	},
	[Section.Profile]: {
		id: 'ui.websites.formGroups.accountProfile',
	},
	[Section.Subscription]: {
		id: 'ui.websites.formGroups.subscription',
	},
});



const AccountSettings: React.FC = () => {
	const accountId = useAccountId();

	const accountAgencyConnections = useAccountAgencyConnections(accountId);
	const accountBillingEntity = useAccountBillingEntity(accountId);
	const accountCanSignup = useAccountCanSignup(accountId);
	const accountHasBillingHistory = useAccountHasBillingHistory(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountProblems = useAccountProblems(accountId);
	const accountState = useAccountState(accountId);
	const inHomeAccount = useInHomeAccount();

	const canManageOrganizationAccess = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageOrganizationAccessControl,
	);

	const canManageAccountAccessDirection = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageAccountAccessDirection,
	);

	const canViewApiTokens = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ViewApiTokens,
	);

	const canViewBilling = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ViewBilling,
	);

	function listSections() {
		const formGroups = [
			Section.Profile,
		];

		if (canViewApiTokens.yes) {
			formGroups.push(Section.IntegrationTokens);
		}

		formGroups.push(Section.Monitoring);

		const hasPackageForm = (
			accountPhase === GraphQL.AccountPhase.Customer
			&& accountState !== GraphQL.AccountState.Churned
		);

		if (hasPackageForm) {
			formGroups.push(Section.Subscription);
		}

		if (inHomeAccount) {
			const hasConnectedAgency = accountAgencyConnections.count > 0;

			if (canManageAccountAccessDirection.yes || hasConnectedAgency) {
				formGroups.push(Section.AgencyAccess);
			}
		}

		if (canManageOrganizationAccess.yes) {
			formGroups.push(Section.OrganizationAccess);
		}

		if (canViewBilling.yes && accountBillingEntity !== null && !accountCanSignup) {
			formGroups.push(Section.BillingDetails);
		}

		if (canViewBilling.yes && accountHasBillingHistory) {
			formGroups.push(Section.Invoices);
		}

		return formGroups;
	}

	const renderSectionTitle = React.useCallback(
		(section: Section) => {
			switch (section) {

				case Section.Invoices:

					if (accountProblems.isSubscriptionSuspended) {
						return (
							<AttachedIcon
								ellipsis={false}
								icon={(
									<StatusFlag status={StatusFlagStatus.Critical} />
								)}
								iconPosition={AttachedIconPosition.Suffix}
							>
								<FormattedMessage {...messages[section]} />
							</AttachedIcon>
						);
					} else if (accountProblems.hasPaymentProblem) {
						return (
							<AttachedIcon
								ellipsis={false}
								icon={(
									<StatusFlag status={StatusFlagStatus.Warning} />
								)}
								iconPosition={AttachedIconPosition.Suffix}
							>
								<FormattedMessage {...messages[section]} />
							</AttachedIcon>
						);
					}

					return (
						<FormattedMessage {...messages[section]} />
					);

				default:
					return (
						<FormattedMessage {...messages[section]} />
					);

			}
		},
		[
			accountProblems,
		],
	);

	const renderSectionContent = React.useCallback(
		(section: Section) => {
			switch (section) {

				case Section.Profile:
					return (
						<AccountProfileGroup
							accountId={accountId}
						/>
					);

				case Section.AgencyAccess:
					return (
						<ConnectedAccountsGroup />
					);

				case Section.IntegrationTokens:
					return (
						<FormsList>
							<AccountIntegrationsForm />
						</FormsList>
					);

				case Section.Monitoring:
					return (
						<MonitoringGroup />
					);

				case Section.OrganizationAccess:
					return (
						<OrganizationAccessGroup />
					);

				case Section.Subscription:
					return (
						<SubscriptionGroup />
					);

				case Section.BillingDetails:
					if (accountBillingEntity === 'old') {
						return (
							<BillingDetailsGroup />
						);
					}

					return (
						<ModernBillingDetailsGroup />
					);


				case Section.Invoices:
					return (
						<InvoicesGroup
							accountId={accountId}
						/>
					);

			}
		},
		[
			accountId,
			accountBillingEntity,
		],
	);

	return (
		<Sections
			actions={(
				<AccountSettingsContextActions />
			)}
			getSectionRoute={(section) => SECTIONS_ROUTES[section]}
			renderSectionContent={renderSectionContent}
			renderSectionTitle={renderSectionTitle}
			sections={listSections()}
		/>
	);
};



export default AccountSettings;
