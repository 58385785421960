import React from 'react';



type Props = {
	children: React.ReactNode,
	/** Icon in title */
	icon?: React.ReactNode,
	/** Title of navigation */
	title?: React.ReactNode,
};



const SimpleNavigation: React.FC<Props> = (props) => {
	const {
		children,
		icon,
		title,
	} = props;

	const _renderTitle = () => {
		if (!title && !icon) {
			return null;
		}

		return (
			<div className="simple-nav__header">
				{icon && (
					<div className="simple-nav__icon">
						{icon}
					</div>
				)}
				{title && (
					<div className="simple-nav__title">
						{title}
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="simple-nav">
			{_renderTitle()}
			<div className="simple-nav__list">
				{children}
			</div>
		</div>
	);
};



export default SimpleNavigation;
