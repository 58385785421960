import {
	get,
} from './api/API';

import {
	LOGIN_SUCCESSFUL,
} from '~/actions';



export function tryLoadUserData() {
	return function (dispatch) {
		return get('/user_data').then((response) => {
			dispatch({
				type: LOGIN_SUCCESSFUL,
				response,
			});
		});
	};
}
