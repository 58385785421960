import React from 'react';

import HelpIcon, {
	HelpIconStyle as HelpHintStyle,
} from '~/components/patterns/icons/HelpIcon';
import Hint, {
	HintPopupSize as HelpHintMessageSize,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import touchSupported from '~/utilities/touchSupported';



type Props = {
	/** Popup message visible when clicking on / hovering over (?) icon */
	message: React.ReactNode,
	/** Possibility to set different size of popup message */
	messageSize?: HelpHintMessageSize,
	/** Style of help icon */
	style?: HelpHintStyle,
};



const HelpHint: React.FC<Props> = (props) => {
	const {
		message,
		messageSize = HelpHintMessageSize.Medium,
		style = HelpHintStyle.Dark,
	} = props;

	return (
		<Hint
			blurDelay={200}
			className="help-hint"
			popup={message}
			popupOffset={({ placement }) => {
				if (placement === 'bottom-start' || placement === 'top-start') {
					return [-10, 0];
				}

				if (placement === 'bottom-end' || placement === 'top-end') {
					return [10, 0];
				}

				return [0, 0];
			}}
			popupSize={messageSize}
			popupVisibility={touchSupported ? HintPopupVisibility.OnClick : HintPopupVisibility.OnHover}
		>
			<HelpIcon style={style} />
		</Hint>
	);
};

export {
	HelpHintStyle,
	HelpHintMessageSize,
};

export default HelpHint;
