import classNames from 'classnames';
import React from 'react';

import DepthLayer from '~/components/patterns/utils/DepthLayer';



type Props = {
	appAnnouncement?: React.ReactNode,
	/** Page content */
	children: React.ReactNode,
	/** Reserve space for Intercom button when showing trial bar */
	gapForIntercom?: boolean,
	/** Page sidebar */
	sidebar: React.ReactNode,
	/** Component with trial bar (or premium trail bar) */
	trialBar?: React.ReactNode,
};



const PageLayout: React.FC<Props> = (props) => {
	const {
		appAnnouncement,
		children,
		gapForIntercom = false,
		sidebar,
		trialBar,
	} = props;

	const componentClasses = classNames({
		'page': true,
		'page--in-trial': trialBar,
	});

	const trialBarClasses = classNames({
		'page__trial-bar': true,
		'page__trial-bar--has-gap-for-intercom': gapForIntercom,
	});

	return (
		<div className={componentClasses}>
			{trialBar && (
				<div className={trialBarClasses}>
					{trialBar}
				</div>
			)}
			{appAnnouncement && (
				<div className="page__app-announcement">
					{appAnnouncement}
				</div>
			)}
			{sidebar && (
				<aside
					className={classNames({
						'page__sidebar': true,
						'dark-bg-context': true,
					})}
					role="complementary"
				>
					{sidebar}
				</aside>
			)}
			<div className="page__body">
				<DepthLayer depthJump={1500}>
					{children}
				</DepthLayer>
			</div>
		</div>
	);
};



export default PageLayout;
