import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';



const messages = defineMessages({
	title: {
		id: 'ui.contentData.url',
	},
});



type Props = {
	issue: {
		context: {
			variants: ReadonlyArray<string>,
		},
	},
	tableWidth: number,
};

const RobotsTxtNonCanonicalTable: React.FC<Props> = (props) => {
	const {
		issue,
		tableWidth,
	} = props;

	return (
		<SmallTable
			columns={[
				{
					hasData: () => true,
					render: {
						cell: ({ row }) => row,
						header: () => <FormattedMessage {...messages.title} />,
					},
					width: 500,
				},
			]}
			rows={issue.context.variants}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(RobotsTxtNonCanonicalTable);
