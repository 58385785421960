import React from 'react';



let counter = 0;

function useUniqueInstanceId(): number {
	const [uniqueInstanceId] = React.useState(() => ++counter);

	return uniqueInstanceId;
}



export default useUniqueInstanceId;
