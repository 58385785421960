import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const TargetIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M1.92,20.549a.249.249,0,0,1,0,.309L.716,22.38a1,1,0,1,0,1.568,1.24l1.052-1.329a.25.25,0,0,1,.344-.046,8.964,8.964,0,0,0,10.856-.153.249.249,0,0,1,.341.03c.391.439,1.375,1.543,1.376,1.543a1.013,1.013,0,0,0,1.412.081,1,1,0,0,0,.082-1.411l-1.5-1.685a.25.25,0,0,1-.014-.314,8.956,8.956,0,0,0,.41-10.084.256.256,0,0,0-.4-.041L14.6,11.859a.242.242,0,0,0-.042.285,6.278,6.278,0,1,1-1.94-2.232.25.25,0,0,1,.032.38l-.724.724a.25.25,0,0,1-.315.031,4.69,4.69,0,0,0-3.656-.681,4.751,4.751,0,1,0,5.659,5.773,4.712,4.712,0,0,0-.12-2.671.252.252,0,0,0-.415-.087L10.961,15.5a.236.236,0,0,0-.061.1A2,2,0,1,1,9,13a2.059,2.059,0,0,1,.353.031.251.251,0,0,1,.133.423L8.6,14.34a.749.749,0,0,0,0,1.06.763.763,0,0,0,1.061,0l7.326-7.327A.251.251,0,0,1,17.164,8H20.5a.5.5,0,0,0,.339-.132l3-2.757a.5.5,0,0,0-.18-.842l-2.826-.942a.253.253,0,0,1-.158-.158L19.732.342a.5.5,0,0,0-.37-.331.506.506,0,0,0-.473.151l-2.758,3A.5.5,0,0,0,16,3.5V6.836a.255.255,0,0,1-.073.177l-.961.961a.25.25,0,0,1-.333.017A8.992,8.992,0,0,0,1.92,20.549Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default TargetIconBuilder;

