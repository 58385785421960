import React from 'react';

import styles from './ShadowLogo.module.scss';



const ShadowLogo: React.FC = () => {
	return (
		<div className={styles.component}>
			<span className={styles.text}>SHADOW</span>
		</div>
	);
};



export default ShadowLogo;
