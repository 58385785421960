import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Ellipsis from '~/components/patterns/values/Ellipsis';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';



const ELLIPSIS_POPUP_ZINDEX = 2000;



const messages = defineMessages({
	columnSitemap: {
		id: 'ui.platform.sitemapUrlsViewer.tableHeading.sitemap',
	},
});



type Props = {
	sitemaps: ReadonlyArray<{
		url: string,
	}>,
	tableWidth: number,
};

const SitemapsTable: React.FC<Props> = (props) => {
	const {
		sitemaps,
		tableWidth,
	} = props;

	return (
		<SmallTable
			columns={[
				{
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis popupZIndex={ELLIPSIS_POPUP_ZINDEX}>
									{row.url}
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnSitemap} />
						),
					},
					width: tableWidth - 2,
				},
			]}
			rows={sitemaps}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(SitemapsTable);
