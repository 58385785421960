/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvitationsSessionStagingAreaQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  sessionId: GraphQL.Scalars['String']['input'];
}>;


export type InvitationsSessionStagingAreaQuery = { readonly __typename?: 'Query', readonly invitationsValidationSession: { readonly __typename?: 'StagingAreaOfInvitationsValidationSession', readonly failedEmails: ReadonlyArray<{ readonly __typename?: 'InvitationsValidationFailedEmail', readonly email: string, readonly failureReason: GraphQL.InvitationValidationFailure }>, readonly finishedEmails: ReadonlyArray<{ readonly __typename?: 'InvitationsValidationFinishedEmail', readonly email: string }>, readonly pendingEmails: ReadonlyArray<{ readonly __typename?: 'InvitationsValidationPendingEmail', readonly email: string }> } | null };

export type UnstageEmailsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  sessionId: GraphQL.Scalars['String']['input'];
  emails: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type UnstageEmailsMutation = { readonly __typename?: 'Mutation', readonly UnstageEmailsFromValidationSession: { readonly __typename?: 'UnstageEmailsFromValidationSessionResult', readonly stagingArea: { readonly __typename?: 'StagingAreaOfInvitationsValidationSession', readonly failedEmails: ReadonlyArray<{ readonly __typename?: 'InvitationsValidationFailedEmail', readonly email: string, readonly failureReason: GraphQL.InvitationValidationFailure }>, readonly finishedEmails: ReadonlyArray<{ readonly __typename?: 'InvitationsValidationFinishedEmail', readonly email: string }>, readonly pendingEmails: ReadonlyArray<{ readonly __typename?: 'InvitationsValidationPendingEmail', readonly email: string }> } } };

export type InviteNewUsersInBulkMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  invitations: ReadonlyArray<GraphQL.InputMaybe<GraphQL.InviteNewUsersInBulkInvitationInput>> | GraphQL.InputMaybe<GraphQL.InviteNewUsersInBulkInvitationInput>;
  sessionId: GraphQL.Scalars['String']['input'];
}>;


export type InviteNewUsersInBulkMutation = { readonly __typename?: 'Mutation', readonly InviteNewUsersInBulk: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly displayName: string, readonly email: string, readonly hasLimitedWebsitesAccess: boolean, readonly id: CK.InvitationId, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithInvitation>, readonly role: GraphQL.UserRole, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> } | null } } };


export const InvitationsSessionStagingAreaDocument = gql`
    query InvitationsSessionStagingArea($accountId: AccountId!, $sessionId: String!) {
  invitationsValidationSession(accountId: $accountId, sessionId: $sessionId) {
    failedEmails {
      email
      failureReason
    }
    finishedEmails {
      email
    }
    pendingEmails {
      email
    }
  }
}
    `;

/**
 * __useInvitationsSessionStagingAreaQuery__
 *
 * To run a query within a React component, call `useInvitationsSessionStagingAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsSessionStagingAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsSessionStagingAreaQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useInvitationsSessionStagingAreaQuery(baseOptions: Apollo.QueryHookOptions<InvitationsSessionStagingAreaQuery, InvitationsSessionStagingAreaQueryVariables> & ({ variables: InvitationsSessionStagingAreaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationsSessionStagingAreaQuery, InvitationsSessionStagingAreaQueryVariables>(InvitationsSessionStagingAreaDocument, options);
      }
export function useInvitationsSessionStagingAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationsSessionStagingAreaQuery, InvitationsSessionStagingAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationsSessionStagingAreaQuery, InvitationsSessionStagingAreaQueryVariables>(InvitationsSessionStagingAreaDocument, options);
        }
export function useInvitationsSessionStagingAreaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationsSessionStagingAreaQuery, InvitationsSessionStagingAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvitationsSessionStagingAreaQuery, InvitationsSessionStagingAreaQueryVariables>(InvitationsSessionStagingAreaDocument, options);
        }
export type InvitationsSessionStagingAreaQueryHookResult = ReturnType<typeof useInvitationsSessionStagingAreaQuery>;
export type InvitationsSessionStagingAreaLazyQueryHookResult = ReturnType<typeof useInvitationsSessionStagingAreaLazyQuery>;
export type InvitationsSessionStagingAreaSuspenseQueryHookResult = ReturnType<typeof useInvitationsSessionStagingAreaSuspenseQuery>;
export type InvitationsSessionStagingAreaQueryResult = Apollo.QueryResult<InvitationsSessionStagingAreaQuery, InvitationsSessionStagingAreaQueryVariables>;
export const UnstageEmailsDocument = gql`
    mutation UnstageEmails($accountId: AccountId!, $sessionId: String!, $emails: [String!]!) {
  UnstageEmailsFromValidationSession(
    accountId: $accountId
    sessionId: $sessionId
    emails: $emails
  ) {
    stagingArea {
      failedEmails {
        email
        failureReason
      }
      finishedEmails {
        email
      }
      pendingEmails {
        email
      }
    }
  }
}
    `;
export type UnstageEmailsMutationFn = Apollo.MutationFunction<UnstageEmailsMutation, UnstageEmailsMutationVariables>;

/**
 * __useUnstageEmailsMutation__
 *
 * To run a mutation, you first call `useUnstageEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnstageEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unstageEmailsMutation, { data, loading, error }] = useUnstageEmailsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionId: // value for 'sessionId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useUnstageEmailsMutation(baseOptions?: Apollo.MutationHookOptions<UnstageEmailsMutation, UnstageEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnstageEmailsMutation, UnstageEmailsMutationVariables>(UnstageEmailsDocument, options);
      }
export type UnstageEmailsMutationHookResult = ReturnType<typeof useUnstageEmailsMutation>;
export type UnstageEmailsMutationResult = Apollo.MutationResult<UnstageEmailsMutation>;
export type UnstageEmailsMutationOptions = Apollo.BaseMutationOptions<UnstageEmailsMutation, UnstageEmailsMutationVariables>;
export const InviteNewUsersInBulkDocument = gql`
    mutation InviteNewUsersInBulk($accountId: AccountId!, $invitations: [InviteNewUsersInBulkInvitationInput]!, $sessionId: String!) {
  InviteNewUsersInBulk(
    accountId: $accountId
    invitations: $invitations
    sessionId: $sessionId
  ) {
    query {
      account(id: $accountId) {
        id
        invitations {
          displayName
          email
          hasLimitedWebsitesAccess
          id
          permittedActions
          role
          websites {
            id
          }
        }
      }
    }
  }
}
    `;
export type InviteNewUsersInBulkMutationFn = Apollo.MutationFunction<InviteNewUsersInBulkMutation, InviteNewUsersInBulkMutationVariables>;

/**
 * __useInviteNewUsersInBulkMutation__
 *
 * To run a mutation, you first call `useInviteNewUsersInBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteNewUsersInBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteNewUsersInBulkMutation, { data, loading, error }] = useInviteNewUsersInBulkMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      invitations: // value for 'invitations'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useInviteNewUsersInBulkMutation(baseOptions?: Apollo.MutationHookOptions<InviteNewUsersInBulkMutation, InviteNewUsersInBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteNewUsersInBulkMutation, InviteNewUsersInBulkMutationVariables>(InviteNewUsersInBulkDocument, options);
      }
export type InviteNewUsersInBulkMutationHookResult = ReturnType<typeof useInviteNewUsersInBulkMutation>;
export type InviteNewUsersInBulkMutationResult = Apollo.MutationResult<InviteNewUsersInBulkMutation>;
export type InviteNewUsersInBulkMutationOptions = Apollo.BaseMutationOptions<InviteNewUsersInBulkMutation, InviteNewUsersInBulkMutationVariables>;