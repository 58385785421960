import React from 'react';



type Props = React.PropsWithChildren<{}>;



export default React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		children,
	} = props;

	return (
		<div
			{...props}
			ref={ref}
		>
			{children}
		</div>
	);
});
