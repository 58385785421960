import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import matchAndReturn from '~/utilities/matchAndReturn';
import PageBundleField from '~/components/app/PageBundleField';
import PlanName from '~/components/names/PlanName';
import SelectField from '~/components/atoms/forms/components/SelectField';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubmitButton from '~/components/app/SubmitButton';
import SwitchField from '~/components/app/SwitchField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useSignupOutOfBandAccountModalQuery,
	useSignupOutOfBandAccountMutation,
} from './AdminSignupOutOfBandAccountModal.gql';

import useAccountCustomElementsLimit from '~/hooks/useAccountCustomElementsLimit';
import useAccountEnrichmentFieldsLimit from '~/hooks/useAccountEnrichmentFieldsLimit';
import useAccountName from '~/hooks/useAccountName';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useAddonsAvailability from '~/hooks/useAddonsAvailability';
import useAllowedPlans from '~/hooks/useAllowedPlans';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const validations = {
	validatePlan: validateField(
		'plan',
		(f) => ([
			f.validateNonEmpty(),
		]),
	),
};



type Props = {
	accountId: CK.AccountId | null,
};

const AdminSignupOutOfBandAccountModal: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountName = useAccountName(accountId);
	const accountCustomElementsLimit = useAccountCustomElementsLimit(accountId);
	const accountEnrichmentFieldsLimit = useAccountEnrichmentFieldsLimit(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);
	const addonsAvailability = useAddonsAvailability();
	const allowedPlans = useAllowedPlans(accountId);
	const classicFormBehavior = useClassicFormBehavior();

	const [signupOutOfBandAccount] = useSignupOutOfBandAccountMutation();

	const { data, loading: dataLoading } = useSignupOutOfBandAccountModalQuery({
		variables: {
			accountId: accountId ?? 0,
		},
		skip: accountId === null,
	});

	const addons = data?.account?.addons ?? null;

	const isLoading = (
		addonsAvailability.isLoaded === false
		|| allowedPlans === null
		|| dataLoading
	);

	const handleSignup = React.useCallback(
		async (values) => {
			if (
				accountId === null
				|| accountTariff === null
				|| addonsAvailability.isLoaded === false
			) {
				return;
			}

			const availableAddons = addonsAvailability.listAvailableAddonsByTariffAndPlan(
				accountTariff,
				values.plan,
			);

			const addons = availableAddons.map(
				(addon) => ({
					addonType: addon.addonType,
					amount: addon.maximumAmount > 1
						? parseInt(values[addon.addonType])
						: (values[addon.addonType] ? 1 : 0),
				}),
			);

			await signupOutOfBandAccount({
				variables: {
					accountId,
					addons,
					pageBundle: values.pageBundle,
					billingCycle: GraphQL.Term.Annually,
					currency: GraphQL.Currency.Usd,
					plan: values.plan,
				},
			});

			classicFormBehavior.finish();
		},
		[
			accountId,
			accountTariff,
			addonsAvailability,
			classicFormBehavior,
			signupOutOfBandAccount,
		],
	);

	if (
		accountId === null
		|| accountPlan === null
		|| accountTariff === null
		|| accountCustomElementsLimit === null
		|| accountEnrichmentFieldsLimit === null
		|| addons === null
		|| addonsAvailability.isLoaded === false
	) {
		return null;
	}

	const getCurrentAddonAmount = (addonType: GraphQL.AddonType): number => {
		return addons.find((addon) => addon.addonType === addonType)?.amount ?? 0;
	};

	const formatOptions = (addonType: GraphQL.AddonType, value: number) => {
		if (value === 0) {
			return 'no extra';
		}

		if (addonType === GraphQL.AddonType.CustomElements_5) {
			const baseCustomElementsAmount = accountCustomElementsLimit - getCurrentAddonAmount(addonType) * 5;

			return `+ ${value * 5} (total ${value * 5 + baseCustomElementsAmount})`;
		}

		if (addonType === GraphQL.AddonType.DataRetention_12) {
			return `+ ${value}×12 months (total ${value * 12})`;
		}

		if (addonType === GraphQL.AddonType.EnrichmentFields_5) {
			const baseEnrichmentFieldsAmount = accountEnrichmentFieldsLimit - getCurrentAddonAmount(addonType) * 5;

			return `+ ${value * 5} (total ${value * 5 + baseEnrichmentFieldsAmount})`;
		}
	};

	const formatAddonLabel = (addonType: GraphQL.AddonType) => matchAndReturn(addonType, {
		[GraphQL.AddonType.CustomElements_5]: 'Custom elements',
		[GraphQL.AddonType.DataRetention_12]: 'Data retention',
		[GraphQL.AddonType.EnrichmentFields_5]: 'Enrichment fields',
		[GraphQL.AddonType.IndexNow]: 'IndexNow',
		[GraphQL.AddonType.LighthouseMonitoring]: 'Lighthouse Monitoring',
		[GraphQL.AddonType.MicrosoftTeams]: 'Microsoft Teams',
		[GraphQL.AddonType.Rendering]: 'Javascript Rendering',
		[GraphQL.AddonType.StoreResponseSnapshots]: 'Store response snapshots',
	});

	const defaultValues: Record<string, string | boolean | number> = {
		plan: allowedPlans?.[0] ?? '',
		pageBundle: accountWebsiteSizes?.totalPageCapacity ?? 0,
	};

	addonsAvailability.listAvailableAddonsByTariffAndPlan(
		accountTariff,
		accountPlan,
	).forEach((addon) => {
		defaultValues[addon.addonType] = addon.maximumAmount > 1
			? getCurrentAddonAmount(addon.addonType) + ''
			: getCurrentAddonAmount(addon.addonType) > 0;
	});

	return (
		<SimpleModal
			size={SimpleModalSize.Medium}
			title={`Signup ${accountName !== null ? accountName : `account (${accountId})`}`}
		>
			<CenteredFormWrapper>
				<Form
					defaultDataHasChanged={true}
					defaultValues={defaultValues}
					isDisabled={isLoading}
					key={isLoading ? 'loading' : 'ready'}
					onSuccess={handleSignup}
					validations={validations}
				>
					{({ values }) => (
						<>
							<FormRows>
								<FormRow label="Plan">
									<FieldStatus
										name="validatePlan"
										showIcon={false}
									>
										<SelectField
											name="plan"
											options={allowedPlans?.map((plan) => ({
												label: <PlanName plan={plan} />,
												name: plan,
											})) ?? []}
										/>
									</FieldStatus>
								</FormRow>

								<FormRow label="Page bundle">
									<PageBundleField
										accountId={accountId}
										name="pageBundle"
										unlimited={true}
									/>
								</FormRow>

								{addonsAvailability.listAvailableAddonsByTariffAndPlan(
									accountTariff,
									values.plan,
								).map((addon) => (
									<FormRow
										key={addon.addonType}
										label={formatAddonLabel(addon.addonType)}
									>
										<StaticText focusTarget={addon.addonType}>
											{addon.maximumAmount > 1 ? (
												<SelectField
													name={addon.addonType}
													options={Array.from(Array(addon.maximumAmount).keys()).map((value) => ({
														label: formatOptions(addon.addonType, value),
														name: value + '',
													}))}
												/>
											) : (
												<SwitchField
													activeStateLabel="enabled"
													inactiveStateLabel="disabled"
													name={addon.addonType}
												/>
											)}
										</StaticText>
									</FormRow>
								))}
							</FormRows>

							<FormErrorMessages />

							<ButtonsLayout>
								<CancelButton />
								<SubmitButton>
									Signup account
								</SubmitButton>
							</ButtonsLayout>
						</>
					)}
				</Form>
			</CenteredFormWrapper>
		</SimpleModal>
	);
};



export default AdminSignupOutOfBandAccountModal;
