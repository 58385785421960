import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import RemainingTime, {
	RemainingTimeContext,
} from '~/components/app/RemainingTime';
import TrialBarAtom from '~/components/patterns/layout/TrialBar';

import useAllowedPlans from '~/hooks/useAllowedPlans';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useIsoDate from '~/hooks/useIsoDate';
import useLocalStorage from '~/hooks/useLocalStorage';
import usePlanNecessaryForCurrentFeatures from '~/hooks/usePlanNecessaryForCurrentFeatures';
import useTimeoutSince from '~/hooks/useTimeoutSince';
import useUrlState from '~/hooks/useUrlState';
import useViewportType from '~/hooks/useViewportType';

import {
	findHighestPlan,
} from '~/model/universal';



const messages = defineMessages({
	buttonLabel: {
		id: 'ui.premiumTrialBar.button',
	},
});



type Props = {
	accountId: CK.AccountId,
	expirationDate: CK.Timestamp,
};

const PremiumTrialBar: React.FC<Props> = (props) => {
	const {
		accountId,
		expirationDate,
	} = props;

	const allowedPlans = useAllowedPlans(accountId);
	const urlState = useUrlState();
	const universalNecessaryPlan = usePlanNecessaryForCurrentFeatures(accountId);
	const viewportType = useViewportType();

	const isAllowedToSpendMoney = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.SpendMoney,
	);

	const premiumTrialExpiration = useIsoDate(expirationDate);

	const [collapsedDueToSubscription, setCollapsedDueToSubscription] = React.useState(false);
	const [collapsedAt, setCollapsedAt] = useLocalStorage(
		'premiumTrialBarClosedAt',
		null,
		(value) => value !== null ? new Date(value) : null,
	);

	const isCollapsed = collapsedAt !== null || collapsedDueToSubscription;

	const collapseCallback = React.useCallback(
		() => {
			setCollapsedAt(new Date());
		},
		[
			setCollapsedAt,
		],
	);

	const uncollapseCallback = React.useCallback(
		() => {
			setCollapsedAt(null);
			setCollapsedDueToSubscription(false);
		},
		[
			setCollapsedAt,
		],
	);

	useTimeoutSince(
		uncollapseCallback,
		collapsedAt,
		48 * 3_600 * 1_000,
	);

	React.useEffect(
		() => {
			if (urlState.name === 'account.settings.subscription') {
				setCollapsedDueToSubscription(true);
			} else {
				setCollapsedDueToSubscription(false);
			}
		},
		[
			urlState.name,
		],
	);

	const button = (
		<Button
			linkRouteName="account.settings.subscription"
			linkRouteParams={{
				accountId,
				plan: universalNecessaryPlan,
			}}
			size={viewportType.isSmall ? ButtonSize.XSmall : ButtonSize.Medium}
			style={ButtonStyle.Action}
		>
			<FormattedMessage {...messages.buttonLabel} />
		</Button>
	);

	return (
		<TrialBarAtom
			buttons={isAllowedToSpendMoney.yes ? button : undefined}
			isCollapsed={isCollapsed}
			isCollapsible={!viewportType.isSmall}
			isPremium={true}
			onCloseCallback={collapseCallback}
			onRevealCallback={uncollapseCallback}
			remainingTime={(
				allowedPlans !== null && (
					<RemainingTime
						context={RemainingTimeContext.PremiumTrial}
						plan={findHighestPlan(allowedPlans)}
						until={premiumTrialExpiration}
					/>
				)
			)}
		/>
	);
};



export default PremiumTrialBar;
