import Immutable from 'immutable';

import {
	CLEAR_PAGES_CHARTS,
	STORE_PAGES_CHARTS,
	UPDATE_PAGES_CHARTS,
} from '~/actions/pages/overview';

import {
	CHANGE_URL_STATE,
} from '~/actions';



function createDefaultState() {
	return Immutable.fromJS({});
}



function createDefaultWebsiteState() {
	return Immutable.fromJS({
		statistics: {},
		total: null,
	});
}



function createKey(websiteId, path) {
	return [websiteId, path];
}



function formatStatistics(statistics) {
	const result = {};

	statistics.forEach(({ field, scopes }) => {
		scopes.forEach(({ scope, values }) => {
			if (result[field] === undefined) {
				result[field] = {};
			}

			result[field][scope] = values;
		});
	});

	return Immutable.fromJS(result);
}



export function pagesGraphs(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			const {
				urlState,
			} = action;

			if (
				urlState.name.indexOf('website.') === 0
				&& !state.has(urlState.params.websiteId)
			) {
				state = state.set(
					urlState.params.websiteId,
					createDefaultWebsiteState(),
				);
			}

			break;
		}

		case CLEAR_PAGES_CHARTS: {
			const {
				websiteId,
			} = action;

			state = state.setIn(createKey(websiteId, 'statistics'), Immutable.fromJS({}));
			state = state.setIn(createKey(websiteId, 'total'), Immutable.fromJS(null));

			break;
		}

		case STORE_PAGES_CHARTS: {
			const {
				statistics,
				total,
				websiteId,
			} = action;

			const oldStatistics = state.getIn(createKey(websiteId, 'statistics'));
			const newStatistics = formatStatistics(statistics);

			if (!Immutable.is(oldStatistics, newStatistics)) {
				state = state.setIn(
					createKey(websiteId, 'statistics'),
					oldStatistics.merge(newStatistics),
				);

				state = state.setIn(
					createKey(websiteId, 'total'),
					total,
				);

				newStatistics.keySeq().forEach((column) => {
					state = state.setIn(
						[websiteId, 'statistics', column, 'loading'],
						false,
					);
				});
			}

			break;
		}

		case UPDATE_PAGES_CHARTS: {
			const {
				columns,
				websiteId,
			} = action;

			columns.forEach((column) => {
				state = state.setIn(
					[websiteId, 'statistics', column, 'loading'],
					true,
				);
			});

			break;
		}

	}

	return state;
}
