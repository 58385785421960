import React from 'react';

import type CK from '~/types/contentking';

import AlertPagesDatatable, {
	type AlertPagesDatatableIncident,
	type AlertPagesDatatableRef,
} from '~/components/logic/alertPages/AlertPagesDatatable';



type Props = {
	extraColumns: Array<CK.PagesColumn>,
	incident: AlertPagesDatatableIncident,
	scrollCallback: () => void,
};

const ContentChangeDatatable = React.forwardRef<AlertPagesDatatableRef, Props>((props, ref) => {
	const {
		extraColumns,
		incident,
		scrollCallback,
	} = props;

	return (
		<AlertPagesDatatable
			extraColumns={extraColumns}
			incident={incident}
			ref={ref}
			scrollCallback={scrollCallback}
			showImportance={true}
		/>
	);
});



export default ContentChangeDatatable;
