import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type Props = {
	/** Optional button which is possible to add to layout of content */
	button?: React.ReactNode,
	children?: React.ReactNode,
};



const IncidentBoxContent: React.FC<Props> = (props) => {
	const {
		button,
		children,
	} = props;

	const elements = flattenChildren(children).filter(notEmpty);

	return (
		<div className="incident-box-content">
			<div className="incident-box-content__body">
				{elements.map((child, index) => (
					<div
						className="incident-box-content__item"
						key={'incident-box-content-item-' + index}
					>
						<div className="incident-box-content__item-content">
							{child}
						</div>
					</div>
				))}
			</div>
			{button && (
				<div className="incident-box-content__button">
					{button}
				</div>
			)}
		</div>
	);
};



export default IncidentBoxContent;
