import Immutable from 'immutable';

import {
	createFilterParameter as createIssuesFilterParameter,
} from './issues';

import {
	createAffectedLinksFilterParameter,
} from '~/model/affectedLinks';

import {
	createAffectedPagesFilterParameter,
	getDefaultFilter,
} from '~/model/affectedPages';

import {
	createFilter as createAffectedPagesComparisonFilter,
	createAffectedPagesComparisonFilterParameter,
} from '~/model/affectedPagesComparison';

import {
	getCategoryUrlIdentifier,
	getUrlIdentifier,
} from '~/model/issues/identifierMapping';

import {
	createFilterParameter as createHistoricalIssuesFilterParameter,
} from '~/model/websiteIssuesComparison';

import {
	navigate,
} from '~/routing/router';

import {
	filterSelector as affectedLinksFilterSelector,
	sortBySelector as affectedLinksSortBySelector,
} from '~/state/affectedLinks/selectors';

import {
	currentFilterSelector as affectedPagesFilterSelector,
	categorySelector as affectedPagesIssueCategorySelector,
	singleTypeSelector as affectedPagesIssueTypeSelector,
	sortBySelector as affectedPagesSortBySelector,
} from '~/state/affectedPages/selectors';

import {
	currentFilterSelector as affectedPagesComparisonFilterSelector,
	categorySelector as affectedPagesComparisonIssueCategorySelector,
	singleTypeSelector as affectedPagesComparisonIssueTypeSelector,
	sortBySelector as affectedPagesComparisonSortBySelector,
} from '~/state/affectedPagesComparison/selectors';

import {
	issuesFilterParameterSelector,
} from '~/state/ui/content/selectors';

import {
	issuesFilterSelector,
	selectedWebsiteIdSelector,
	urlStateSelector,
} from '~/state/ui/selectors';

import {
	dateFromSelector,
	dateToSelector,
	isActiveSelector as isComparisonModeActiveSelector,
	filterSelector as websiteIssuesComparisonFilterSelector,
} from '~/state/websiteIssuesComparison/selectors';



export function closeWebsiteIssueCategoryDetail() {
	return (dispatch, getState) => {
		navigate('website.issues', {
			end_date: dateToSelector(getState()),
			filter: issuesFilterParameterSelector(getState()),
			start_date: dateFromSelector(getState()),
			websiteId: selectedWebsiteIdSelector(getState()),
		});
	};
}



export function enterComparisonMode(dateFrom, dateTo) {
	return (dispatch, getState) => {
		const urlState = urlStateSelector(getState());

		const params = Object.assign({}, urlState.params);
		params.start_date = dateFrom;
		params.end_date = dateTo;

		if (urlState.params.start_date && urlState.params.end_date) {
			if (urlState.name === 'website.issues.issueCategory.singleIssueAffectedPages') {
				params.filter = createAffectedPagesComparisonFilterParameter(
					affectedPagesComparisonFilterSelector(getState()),
					affectedPagesComparisonSortBySelector(getState()),
					affectedPagesComparisonIssueCategorySelector(getState()),
					affectedPagesComparisonIssueTypeSelector(getState()),
				);
			} else {
				params.filter = createHistoricalIssuesFilterParameter(
					websiteIssuesComparisonFilterSelector(getState()),
				);
			}
		} else {
			if (urlState.name === 'website.issues.issueCategory.singleIssueAffectedPages') {
				params.filter = createAffectedPagesComparisonFilterParameter(
					affectedPagesComparisonFilterSelector(getState()),
					affectedPagesComparisonSortBySelector(getState()),
					affectedPagesComparisonIssueCategorySelector(getState()),
					affectedPagesComparisonIssueTypeSelector(getState()),
				);
			} else {
				const segment = issuesFilterSelector(getState()).get('segment');

				const filter = websiteIssuesComparisonFilterSelector(getState()).set(
					'scope',
					segment === 'all' ? 'website' : segment,
				);

				params.filter = createHistoricalIssuesFilterParameter(filter);
			}
		}

		navigate(urlState.name, params);
	};
}



export function leaveComparisonMode() {
	return (dispatch, getState) => {
		const urlState = urlStateSelector(getState());

		const params = Object.assign({}, urlState.params);
		delete params.start_date;
		delete params.end_date;

		if (urlState.name === 'website.issues.issueCategory.singleIssueAffectedPages') {
			params.filter = createAffectedPagesFilterParameter(
				affectedPagesFilterSelector(getState()),
				affectedPagesSortBySelector(getState()),
				affectedPagesIssueCategorySelector(getState()),
				affectedPagesIssueTypeSelector(getState()),
			) || undefined;
		} else {
			const segment = websiteIssuesComparisonFilterSelector(getState()).get('scope');

			const filter = issuesFilterSelector(getState()).set(
				'segment',
				segment === 'website' ? 'all' : segment,
			);

			params.filter = createIssuesFilterParameter(filter);
		}

		navigate(urlState.name, params);
	};
}



export function openCategoryAffectedPages(issueCategory, filter) {
	return (dispatch, getState) => {
		const issuesFilter = issuesFilterSelector(getState());
		const defaultFilter = getDefaultFilter(issueCategory);

		let affectedPagesFilter = affectedPagesFilterSelector(getState());
		if (issuesFilter.get('segment') && issuesFilter.get('segment') !== 'all') {
			affectedPagesFilter = affectedPagesFilter.set('segment', issuesFilter.get('segment'));
		} else {
			affectedPagesFilter = affectedPagesFilter.set('segment', defaultFilter.get('segment'));
		}

		if (filter) {
			affectedPagesFilter = affectedPagesFilter.merge(
				Immutable.fromJS(filter),
			);
		}

		navigate('website.issues.issueCategory.affectedPages', {
			filter: createAffectedPagesFilterParameter(
				affectedPagesFilter,
				affectedPagesSortBySelector(getState()),
				issueCategory,
			) || undefined,
			issuesOverviewCategory: getCategoryUrlIdentifier(issueCategory),
			websiteId: selectedWebsiteIdSelector(getState()),
		});
	};
}



export function openSingleIssueAffectedLinks(issueCategory, issueType) {
	return (dispatch, getState) => {
		navigate('website.issues.issueCategory.singleIssueAffectedLinks', {
			filter: createAffectedLinksFilterParameter(
				affectedLinksFilterSelector(getState()),
				affectedLinksSortBySelector(getState()),
			) || undefined,
			issuesOverviewCategory: getCategoryUrlIdentifier(issueCategory),
			issuesOverviewIssue: getUrlIdentifier(issueType),
			websiteId: selectedWebsiteIdSelector(getState()),
		});
	};
}



export function openSingleIssueAffectedPages(issueCategory, issueType, filter) {
	if (filter === undefined) {
		filter = null;
	}

	return (dispatch, getState) => {
		const inComparisonMode = isComparisonModeActiveSelector(getState());

		const issuesFilter = issuesFilterSelector(getState());
		const defaultFilter = getDefaultFilter(
			issueCategory,
			issueType,
			filter
				? (filter.size !== undefined ? filter.get('segment') : filter.segment)
				: 'all',
		);

		let affectedPagesFilter = inComparisonMode
			? createAffectedPagesComparisonFilter(defaultFilter, issueCategory, issueType)
			: affectedPagesFilterSelector(getState());

		if (issuesFilter.get('segment') && issuesFilter.get('segment') !== 'all') {
			affectedPagesFilter = affectedPagesFilter.set('segment', issuesFilter.get('segment'));
		} else {
			affectedPagesFilter = affectedPagesFilter.set('segment', defaultFilter.get('segment'));
		}

		if (filter) {
			affectedPagesFilter = affectedPagesFilter.merge(
				Immutable.fromJS(filter),
			);
		}

		navigate('website.issues.issueCategory.singleIssueAffectedPages', {
			end_date: dateToSelector(getState()),
			filter: (
				inComparisonMode
					? createAffectedPagesComparisonFilterParameter(
						affectedPagesFilter,
						affectedPagesComparisonSortBySelector(getState()),
						issueCategory,
						issueType,
					)
					: createAffectedPagesFilterParameter(
						affectedPagesFilter,
						affectedPagesSortBySelector(getState()),
						issueCategory,
						issueType,
					)
			) || undefined,
			issuesOverviewCategory: getCategoryUrlIdentifier(issueCategory),
			issuesOverviewIssue: getUrlIdentifier(issueType),
			start_date: dateFromSelector(getState()),
			websiteId: selectedWebsiteIdSelector(getState()),
		});
	};
}



export function openWebsiteIssueCategoryDetail(issueCategory) {
	return (dispatch, getState) => {
		navigate('website.issues.issueCategory', {
			end_date: dateToSelector(getState()),
			filter: issuesFilterParameterSelector(getState()),
			issuesOverviewCategory: getCategoryUrlIdentifier(issueCategory),
			start_date: dateFromSelector(getState()),
			websiteId: selectedWebsiteIdSelector(getState()),
		});
	};
}
