import {
	parse,
} from 'date-fns';
import React from 'react';
import {
	useSelector,
} from 'react-redux';

import TrackedChangesFilter, {
	TrackedChangesFilterScope,
} from '~/components/logic/filters/TrackedChangesFilter';

import useResetTrackedChangesDateInterval from '~/hooks/useResetTrackedChangesDateInterval';
import useUpdateTrackedChangesDateInterval from '~/hooks/useUpdateTrackedChangesDateInterval';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteTimeCreatedAt from '~/hooks/useWebsiteTimeCreatedAt';

import {
	dateIntervalSelector,
} from '~/state/historicalChanges/selectors';



const TrackedChangesCalendar = () => {
	const historicalDateInterval = useSelector(dateIntervalSelector);
	const resetTrackedChangesDateInterval = useResetTrackedChangesDateInterval();
	const updateTrackedChangesDateInterval = useUpdateTrackedChangesDateInterval();

	const websiteId = useWebsiteId();
	const websiteCreatedAt = useWebsiteTimeCreatedAt(websiteId);

	const handleRangeSelect = React.useCallback(
		(dateRange) => {
			updateTrackedChangesDateInterval(
				dateRange.startDate,
				dateRange.endDate,
			);
		},
		[
			updateTrackedChangesDateInterval,
		],
	);

	const [startDate, endDate] = React.useMemo(
		() => {
			const startDate = historicalDateInterval.get('startDate');
			const endDate = historicalDateInterval.get('endDate');

			return [
				startDate !== null ? parse(startDate, 'yyyy-MM-dd', new Date()) : null,
				endDate !== null ? parse(endDate, 'yyyy-MM-dd', new Date()) : null,
			];
		},
		[
			historicalDateInterval,
		],
	);

	if (websiteCreatedAt === null) {
		return null;
	}

	return (
		<TrackedChangesFilter
			endDate={endDate}
			minDate={websiteCreatedAt}
			onExit={resetTrackedChangesDateInterval}
			onRangeSelect={handleRangeSelect}
			scope={TrackedChangesFilterScope.Pages}
			startDate={startDate}
		/>
	);
};



export default TrackedChangesCalendar;
