import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import MarginBox from '~/components/atoms/boxes/MarginBox';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';

import {
	useDomainsBoxQuery,
} from './DomainsBox.gql';

import usePlatformCanonicalDomain from '~/hooks/usePlatformCanonicalDomain';
import usePlatformQuery from '~/hooks/usePlatformQuery';



const messages = defineMessages({
	domainsCanonicalDomainLabel: {
		id: 'ui.platform.domains.canonical',
	},
	domainsHttpsAvailable: {
		id: 'ui.platform.domains.https.available',
	},
	domainsHttpsLabel: {
		id: 'ui.platform.domains.https',
	},
	domainsHttpsNotAvailable: {
		id: 'ui.platform.domains.https.notAvailable',
	},
	domainsTitle: {
		id: 'ui.platform.domains.title',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const DomainsBox: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const canonicalDomain = usePlatformCanonicalDomain(websiteId);

	const { data } = usePlatformQuery(
		useDomainsBoxQuery,
		websiteId,
	);

	const isHttpsSupported = data?.platformData?.isHttpsSupported ?? null;

	return (
		<MarginBox>
			<BorderedBox
				headerIcon={(
					<AttributesGroupIcon
						color="#964fa5"
						size={28}
						type={AttributesGroupIconType.Domain}
					/>
				)}
				headerLabel={(
					<FormattedMessage {...messages.domainsTitle} />
				)}
				paddingSize={3}
			>
				<DefinitionTermsList>
					<DefinitionTerm
						description={canonicalDomain !== null ? (
							<ExternalLink
								breakLines={true}
								href={canonicalDomain}
							>
								{canonicalDomain}
							</ExternalLink>
						) : (
							<SquareSkeleton maxWidth={220} />
						)}
						term={(
							<FormattedMessage {...messages.domainsCanonicalDomainLabel} />
						)}
					/>
					<DefinitionTerm
						description={isHttpsSupported !== null ? (
							isHttpsSupported ? (
								<FormattedMessage {...messages.domainsHttpsAvailable} />
							) : (
								<FormattedMessage {...messages.domainsHttpsNotAvailable} />
							)
						) : (
							<SquareSkeleton maxWidth={50} />
						)}
						term={(
							<FormattedMessage {...messages.domainsHttpsLabel} />
						)}
					/>
				</DefinitionTermsList>
			</BorderedBox>
		</MarginBox>
	);
};



export default DomainsBox;
