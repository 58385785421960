import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import {
	useImpersonateUserMutation,
} from './useImpersonateUser.gql';

import useFeatureFlags from '~/hooks/useFeatureFlags';

import {
	LOGIN_SUCCESSFUL,
} from '~/actions';

import {
	CONTENTKING_ENVIRONMENT,
} from '~/config';

import {
	FeatureFlag,
} from '~/featureFlags';

import {
	tryLoadUserData,
} from '~/model/authentication';



const ENVIRONMENT_PREFIX = CONTENTKING_ENVIRONMENT + '-';



function useImpersonateUser() {
	const dispatch = useDispatch();
	const featureFlags = useFeatureFlags();

	const [impersonateUser] = useImpersonateUserMutation();

	const enableSearch = featureFlags.isEnabled(FeatureFlag.AdminFeatures);

	return React.useCallback(
		async (identifier: string, forgetLocation: boolean) => {
			if (identifier.indexOf(ENVIRONMENT_PREFIX) === 0) {
				identifier = identifier.substr(ENVIRONMENT_PREFIX.length);
			}

			const { data } = await impersonateUser({
				variables: {
					enableSearch,
					identifier,
				},
			});

			if (data === null || data === undefined) {
				throw new Error(`Failed to resolve`);
			}

			if (data.ImpersonateUser.suggestedImpersonations === null) {
				dispatch({
					type: LOGIN_SUCCESSFUL,
					response: {
						fake: true,
					},
					resetData: true,
				});

				await dispatch(
					tryLoadUserData(),
				);

				await new Promise(() => {
					setTimeout(() => {
						if (forgetLocation) {
							location.href = '/';
						} else {
							location.reload();
						}
					}, 500);
				});

				throw new Error(`Can't happen`);
			}

			return data.ImpersonateUser.suggestedImpersonations;
		},
		[
			dispatch,
			enableSearch,
			impersonateUser,
		],
	);
}



export default useImpersonateUser;
