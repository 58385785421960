import {
	useUserIsEligibleForRoyalAccessQuery,
} from './useUserIsEligibleForRoyalAccess.gql';

import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserIsEligibleForRoyalAccess(legacyUserId: string | null) {
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const { data } = useUserPropertiesQuery(
		useUserIsEligibleForRoyalAccessQuery,
		legacyUserId,
		{
			poll: false,
			skip: kingdomAdminFeatures.areVisible === false,
		},
	);

	return data?.user?.isEligibleForRoyalAccess ?? null;
}



export default useUserIsEligibleForRoyalAccess;
