import React from 'react';

import type CK from '~/types/contentking';

import DestructConfirmationModal from '~/components/app/DestructConfirmationModal';

import useAccountConfirmationName from '~/hooks/useAccountConfirmationName';



type Props = {
	accountId: CK.AccountId | null,
	confirmButton: React.ReactNode,
	description: React.ReactNode,
	globalFormErrors?: React.ComponentProps<typeof DestructConfirmationModal>['globalFormErrors'],
	onConfirmation: () => Promise<void>,
	titleAction: React.ReactNode,
	titleObject: React.ReactNode,
};

const AdminAccountDestructConfirmationModal: React.FC<Props> = (props) => {
	const {
		accountId,
		confirmButton,
		description,
		globalFormErrors,
		onConfirmation,
		titleAction,
		titleObject,
	} = props;

	const accountConfirmationName = useAccountConfirmationName(accountId);

	return (
		<DestructConfirmationModal
			confirmButton={confirmButton}
			confirmationPhrase={accountConfirmationName ?? ''}
			description={description}
			globalFormErrors={globalFormErrors}
			onConfirmation={onConfirmation}
			titleAction={titleAction}
			titleObject={titleObject}
		/>
	);
};



export default AdminAccountDestructConfirmationModal;
