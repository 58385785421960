import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const OnboardingAndCustomTrainingIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M21.2773 1.75V2.635"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M28.75 5.60504L29.4 4.97754"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M25.3203 3.40246L25.6728 2.58496"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M17.2504 3.40246L16.9004 2.58496"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M17.7495 23.3726C17.7495 23.3726 17.4045 21.5601 16.7045 20.6226C15.0995 18.5126 13.332 15.9351 13.332 13.2226C13.332 11.1154 14.1691 9.0946 15.6591 7.60462C17.149 6.11465 19.1699 5.27759 21.277 5.27759C23.3842 5.27759 25.405 6.11465 26.895 7.60462C28.385 9.0946 29.222 11.1154 29.222 13.2226L30.5445 17.1951H28.3395V19.8476C28.3362 20.3137 28.1484 20.7596 27.8171 21.0876C27.4859 21.4155 27.0382 21.5989 26.572 21.5976H25.847C25.5525 21.598 25.2666 21.6966 25.0343 21.8777C24.8021 22.0588 24.6368 22.3121 24.5645 22.5976C24.4377 23.1045 24.3739 23.6251 24.3745 24.1476V25.1276H17.7495"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M19.955 20.7251C19.955 18.0776 19.955 15.4301 18.1875 13.6626H24.3675C22.6175 15.4301 22.6175 18.0776 22.6175 20.7251"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M15.2906 4.27252C14.7976 4.03276 14.2817 3.84347 13.7506 3.70752V1.45752H9.64062V3.70752C8.88855 3.90076 8.16793 4.20045 7.50063 4.59752L5.90812 3.00002L3.00063 5.91252L4.59313 7.50002C4.19667 8.16763 3.89701 8.88816 3.70312 9.64002H1.45312V13.75H3.70312C3.89701 14.5019 4.19667 15.2224 4.59313 15.89L3.00063 17.5L5.90812 20.3975L7.50063 18.805C8.16793 19.2021 8.88855 19.5018 9.64062 19.695V21.945H13.7506V19.7675"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M12.8851 7.35257C12.3078 7.22518 11.711 7.21276 11.1289 7.31601C10.5467 7.41925 9.99065 7.63615 9.49235 7.9543C8.99405 8.27246 8.56329 8.68565 8.22467 9.17028C7.88606 9.65491 7.64621 10.2015 7.51883 10.7788C7.39144 11.3561 7.37902 11.9529 7.48227 12.535C7.58551 13.1171 7.80241 13.6732 8.12056 14.1715C8.43872 14.6698 8.85191 15.1006 9.33654 15.4392C9.82117 15.7778 10.3678 16.0177 10.9451 16.1451C11.2288 16.2229 11.521 16.2657 11.8151 16.2726"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M17.8027 27.0349V28.6799C17.8027 29.1739 17.999 29.6476 18.3482 29.9969C18.6975 30.3462 19.1713 30.5424 19.6652 30.5424H22.5002C22.9938 30.5417 23.4669 30.3452 23.8156 29.996C24.1644 29.6468 24.3602 29.1734 24.3602 28.6799V27.0349"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default OnboardingAndCustomTrainingIconBuilder;

