import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import {
	usePopper,
} from 'react-popper';

import Button, {
	ButtonWidth,
	ButtonSize as SplitButtonSize,
	ButtonStyle as SplitButtonStyle,
} from './Button';
import ButtonContext from '~/components/atoms/buttons/ButtonContext';
import DepthLayer from '~/components/patterns/utils/DepthLayer';
import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';

import useContextProps from '~/hooks/useContextProps';



type Props = {
	/** Show basic button in his compact version (icon only with tooltip visible on hover) */
	compact?: boolean,
	/** Dropdown content */
	dropdown: React.ReactNode,
	/** Possibility to set custom width of dropdown */
	dropdownWidth?: number,
	/** When defined basis of whole button will be HTML link with defined href */
	href?: string,
	children: React.ReactNode,
	icon?: React.ReactElement,
	iconColor?: string | false,
	isDisabled?: boolean,
	/** By setting routeName we can change this component to Link instance */
	linkRouteName?: string,
	linkRouteParams?: {},
	/** Callback called when clicking on whole item */
	onClick?: (e: any) => void,
	/** Possibility to specify boundary for Popper where we will detect overflow */
	popperBoundary?: HTMLElement,
	size?: SplitButtonSize,
	style?: SplitButtonStyle,
	uppercase?: boolean,
};

const SplitButton: React.FC<Props> = (props) => {
	const {
		dropdown,
		dropdownWidth: customDropdownWidth,
		href,
		children,
		icon,
		isDisabled,
		linkRouteName,
		linkRouteParams,
		onClick,
		popperBoundary = 'clippingParents',
		style = SplitButtonStyle.Hollow,
	} = props;

	const {
		compact,
		iconColor,
		size,
		uppercase,
	} = useContextProps(ButtonContext, props, {
		compact: undefined,
		iconColor: undefined,
		size: SplitButtonSize.Default,
		uppercase: true,
	});

	const dropdownRef = React.useRef<HTMLDivElement>(null);
	const splitButtonRef = React.useRef<HTMLDivElement>(null);

	const [open, setOpen] = React.useState(false);
	const [dropdownWidth, setDropdownWidth] = React.useState<number | null>(null);

	const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);

	const {
		state: popperState,
		styles,
		attributes,
	} = usePopper(
		referenceElement,
		popperElement,
		{
			placement: 'bottom-start',
			modifiers: [
				{
					name: 'flip',
					options: {
						boundary: popperBoundary,
						altBoundary: popperBoundary === 'clippingParents',
					},
				},
				{
					name: 'preventOverflow',
					options: {
						mainAxis: false,
					},
				},
			],
		},
	);

	React.useEffect(() => {
		if (open) {
			const splitButtonEl = splitButtonRef.current;
			if (splitButtonEl) {
				setDropdownWidth(splitButtonEl.offsetWidth);
			}
		}
	}, [open]);

	React.useEffect(() => {
		let timeout;

		function clickHandler(event: MouseEvent): void {
			if (!dropdownRef.current || !open) {
				return;
			}

			const target = event.target;

			if (!target || !(target instanceof HTMLElement)) {
				return;
			}

			const isDropdownClickInside = dropdownRef.current.contains(target);

			if (!isDropdownClickInside) {
				setOpen(false);
			} else {
				timeout = setTimeout(() => {
					setOpen(false);
				});
			}
		}

		document.addEventListener('mouseup', clickHandler, { capture: true });

		return (): void => {
			document.removeEventListener('mouseup', clickHandler, { capture: true });
			clearTimeout(timeout);
		};
	}, [open]);


	const dropdownToggleHandler = () => {
		setOpen(!open);
	};

	let dropdownIconSize = 14;

	if (size == SplitButtonSize.XXSmall || size == SplitButtonSize.XSmall) {
		dropdownIconSize = 10;
	}

	if (size == SplitButtonSize.XXLarge || size == SplitButtonSize.XLarge) {
		dropdownIconSize = 18;
	}

	let dropdownIconStyle = DropdownIconStyle.White;

	if (style == SplitButtonStyle.Hollow || style == SplitButtonStyle.HollowHighlight) {
		dropdownIconStyle = DropdownIconStyle.Default;
	}

	if (style == SplitButtonStyle.Inactive || style == SplitButtonStyle.Link) {
		dropdownIconStyle = DropdownIconStyle.Greyscale;
	}

	const popperDropdownContainer = document.getElementById('popper-elements');

	return (
		<DepthLayer>
			{({ depthLayer }) => {
				return (
					<>
						<div
							data-popper-placement={popperState && popperState.placement}
							ref={setReferenceElement}
						>
							<div
								className={classNames({
									'split-button': true,
									'split-button--open': open,
								})}
								ref={splitButtonRef}
							>
								<div className="split-button__basic-button">
									<Button
										className="split-button__button"
										compact={compact}
										disabled={isDisabled}
										href={href}
										icon={icon}
										iconColor={iconColor}
										linkRouteName={linkRouteName}
										linkRouteParams={linkRouteParams}
										onClick={onClick}
										size={size}
										style={style}
										uppercase={uppercase}
									>
										{children}
									</Button>
								</div>
								<div className="split-button__dropdown-button">
									<Button
										className="split-button__button"
										disabled={isDisabled}
										icon={(
											<DropdownIcon
												isActive={open}
												size={dropdownIconSize}
												style={dropdownIconStyle}
											/>
										)}
										iconSize={dropdownIconSize}
										onClick={dropdownToggleHandler}
										size={size}
										style={style}
										width={ButtonWidth.Auto}
									/>
								</div>
							</div>
						</div>
						{open && popperDropdownContainer && ReactDOM.createPortal(
							<div
								ref={setPopperElement}
								style={{
									zIndex: depthLayer,
									...styles.popper,
								}}
								{...attributes.popper}
							>
								<div
									className={classNames({
										'split-button__dropdown': true,
										'split-button__dropdown--is-wider': customDropdownWidth && dropdownWidth && customDropdownWidth > dropdownWidth,
									})}
									ref={dropdownRef}
									style={{
										width: customDropdownWidth || dropdownWidth || 'auto',
									}}
								>
									{dropdown}
								</div>
							</div>,
							popperDropdownContainer,
						)}
					</>
				);
			}}
		</DepthLayer>
	);
};



export default SplitButton;

export {
	SplitButtonSize,
	SplitButtonStyle,
};
