import classNames from 'classnames';
import React from 'react';
import {
	Link,
} from 'react-router5';

import {
	SwitchButtonsSize,
} from './index';
import Hint, {
	HintAttachment,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';



type Props = {
	/** Tooltip available for disabled buttons to explain reason of disabled state */
	disabledTooltip?: React.ReactNode,
	/** Button label */
	children: React.ReactNode,
	/** Icon can be attached to label */
	icon?: React.ReactElement,
	/** Active style of menu item */
	isActive?: boolean,
	/** Disabled menu item */
	isDisabled?: boolean,
	/** By setting routeName we can change this component to Link instance */
	linkRouteName?: string,
	linkRouteParams?: {},
	onClickCallback?: () => void,
	/** Size of button */
	size?: SwitchButtonsSize,
	/** Make font uppercased */
	uppercase?: boolean,
};



const SwitchButton: React.FC<Props> = (props) => {
	const {
		disabledTooltip,
		children,
		icon,
		isActive,
		isDisabled,
		linkRouteName,
		linkRouteParams,
		onClickCallback,
		size = SwitchButtonsSize.Default,
		uppercase,
	} = props;

	const _handleClick = (e) => {
		if (isDisabled) {
			return false;
		}

		if (!linkRouteName) {
			e.preventDefault();
		}

		if (!onClickCallback) {
			return false;
		}

		onClickCallback();
	};

	const _renderButton = () => {
		let button = (
			<span className="switch-button__button">
				{icon && (
					<span className="switch-button__icon">
						{icon}
					</span>
				)}
				{children && (
					<span className="switch-button__label">
						{children}
					</span>
				)}
			</span>
		);

		if (isDisabled && disabledTooltip) {
			button = (
				<Hint
					attachment={HintAttachment.Center}
					className="switch-button__hint"
					inline={false}
					popup={disabledTooltip}
					popupMaxWidth={170}
					popupVisibility={HintPopupVisibility.OnClick}
				>
					{button}
				</Hint>
			);
		}

		return button;
	};

	const componentClasses = classNames({
		'switch-button': true,
		'switch-button--is-active': isActive,
		'switch-button--is-disabled': isDisabled,
		'switch-button--uppercase': uppercase,
		'switch-button--small-size': size === SwitchButtonsSize.Small,
		'switch-button--xsmall-size': size === SwitchButtonsSize.XSmall,
	});

	if (linkRouteName) {
		return (
			<Link
				className={componentClasses}
				onClick={_handleClick}
				routeName={linkRouteName}
				routeParams={linkRouteParams}
			>
				{_renderButton()}
			</Link>
		);
	}

	return (
		<div
			className={componentClasses}
			onClick={_handleClick}
		>
			{_renderButton()}
		</div>
	);
};



export default SwitchButton;
