import classNames from 'classnames';
import React from 'react';

import {
	ButtonSize,
} from '~/components/patterns/buttons/Button';
import ButtonContextProvider from '~/components/atoms/buttons/ButtonContextProvider';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import RemoveButton, {
	RemoveButtonStyle,
} from '~/components/patterns/buttons/RemoveButton';
import StartHereHint from '~/components/patterns/images/StartHereHint';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	/** Attached call-to-action button or buttons */
	buttons?: React.ReactNode,
	/** Visibility state of collapsible bar */
	isCollapsed?: boolean,
	/** Make bar collapsible */
	isCollapsible?: boolean,
	/** Possibility to set premium type of trial bar */
	isPremium?: boolean,
	/** Callback called when clicking on cross button for collapsing */
	onCloseCallback?: () => void,
	/** Callback called when clicking on collapsed element for uncollapsing */
	onRevealCallback?,
	/** Additional profile info (eg. profile completeness overview) */
	profileInfo?: React.ReactNode,
	/** Text describing remaining time of trial */
	remainingTime: React.ReactNode,
	/** Show 'start here' hint, appears after ~2 seconds */
	showStartHereHint?: boolean,
};



const TrialBar: React.FC<Props> = (props) => {
	const {
		buttons,
		isCollapsed = false,
		isCollapsible = false,
		isPremium = false,
		onCloseCallback,
		onRevealCallback,
		profileInfo,
		remainingTime,
		showStartHereHint,
	} = props;

	const viewportType = useViewportType();
	const startHereHintTimeout = React.useRef<any | null>(null);
	const [isStartHereHintVisible, setStartHereHintVisibility] = React.useState(false);

	let buttonSize;

	if (viewportType.isSmall) {
		buttonSize = ButtonSize.XSmall;
	} else if (viewportType.isMedium) {
		buttonSize = ButtonSize.Small;
	} else {
		buttonSize = ButtonSize.Medium;
	}

	React.useEffect(() => {
		if (showStartHereHint && !isCollapsed) {
			startHereHintTimeout.current = setTimeout(() => {
				setStartHereHintVisibility(true);
			}, 2000);

			return () => {
				clearTimeout(startHereHintTimeout.current);
			};
		}

		setStartHereHintVisibility(false);
	}, [isCollapsed, showStartHereHint]);

	return (
		<div
			className={classNames({
				'trial-bar': true,
				'trial-bar--is-premium': isPremium,
				'trial-bar--is-collapsible': isCollapsible,
				'trial-bar--is-collapsed': isCollapsible && isCollapsed,
			})}
			onClick={isCollapsible && isCollapsed ? onRevealCallback : null}
		>
			<div className="trial-bar__content">
				<div className="trial-bar__remaining-time-info">
					{remainingTime}
				</div>
				{profileInfo && (
					<div className="trial-bar__profile-info">
						{profileInfo}
						<div className="trial-bar__profile-info-hint">
							<StartHereHint visible={isStartHereHintVisible} />
						</div>
					</div>
				)}
				{buttons && (
					<ButtonContextProvider
						iconSize={buttonSize === ButtonSize.XSmall ? 22 : 24}
						size={buttonSize}
					>
						<div className="trial-bar__buttons">
							<ButtonsGroup>
								{buttons}
							</ButtonsGroup>
						</div>
					</ButtonContextProvider>
				)}
				{isCollapsible && onCloseCallback && (
					<div className="trial-bar__close-btn">
						<RemoveButton
							onClickCallback={onCloseCallback}
							size={20}
							style={RemoveButtonStyle.Light}
						/>
					</div>
				)}
			</div>
		</div>
	);
};



export default TrialBar;
