import type CK from '~/types/contentking';

import {
	useUserAccountMembershipQuery,
} from './useUserAccountMembership.gql';



function useUserAccountMembership(accountId: CK.AccountId | null, legacyUserId: string | null) {
	const { data } = useUserAccountMembershipQuery({
		skip: accountId === null || legacyUserId === null,
		variables: {
			accountId: accountId ?? 0,
			legacyUserId: legacyUserId ?? '',
		},
	});

	return data?.userAccountMembership ?? null;
}



export default useUserAccountMembership;
