import React from 'react';

import GraphQL from '~/types/graphql';

import AnimateHeartbeat from '~/components/logic/score/AnimateHeartbeat';
import GeneralIssueCategoryCardContent from '~/components/atoms/issues/components/categoryCards/builders/GeneralIssueCategoryCardContent';
import IssueCategoryCardLayout, {
	HIGHLIGHT_INDENTATION_LEFT,
} from '~/components/atoms/issues/components/categoryCards/builders/IssueCategoryCardLayout';
import IssueCategoryNameIcon from '~/components/app/IssueCategoryNameIcon';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import IssueScore, {
	IssueScoreState,
} from '~/components/patterns/issues/IssueScore';
import IssueTitle from '~/components/logic/issues/IssueTitle';
import List from '~/components/patterns/lists/List';

import useHighlightIssueCategoryHealthImpact from '~/hooks/useHighlightIssueCategoryHealthImpact';
import useSortedIssues from '~/hooks/useSortedIssues';

import {
	type IssueCategoryName,
	getPointsToBeDisplayed,
	isRecognizedIssue,
	sortIssues,
} from '~/model/issuesNew';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	issueCategory: {
		isActionable: boolean,
		isIgnored: boolean,
		issues: ReadonlyArray<{
			context: any,
			isActionable: boolean,
			name: string,
			state: GraphQL.IssueState,
		}>,
		name: IssueCategoryName,
		pointsGained: number,
		pointsToGain: number,
		state: GraphQL.IssueCategoryState,
	},
	onBlurIssueCallback: () => void,
	onClickIssueCallback: (issueCategoryName: string) => void,
	openedIssueCategoryName: string | null,
};

const IssueCategoriesListCard: React.FC<Props> = (props) => {
	const {
		issueCategory,
		onBlurIssueCallback,
		onClickIssueCallback,
		openedIssueCategoryName,
	} = props;

	const highlightIssueCategoryHealthImpact = useHighlightIssueCategoryHealthImpact(issueCategory.name);

	const isActive = issueCategory.name === openedIssueCategoryName;
	const issueCategoryName = issueCategory.name;

	const handleOpenIssueDetail = React.useCallback(
		() => {
			if (isActive) {
				onBlurIssueCallback();
			} else {
				onClickIssueCallback(issueCategoryName);
			}
		},
		[
			isActive,
			issueCategoryName,
			onBlurIssueCallback,
			onClickIssueCallback,
		],
	);

	const issues: Array<React.ReactNode> = [];

	const sortedIssues = useSortedIssues(
		issueCategory.issues,
		sortIssues,
	);

	if (issueCategory.state === GraphQL.IssueCategoryState.Open) {
		sortedIssues.filter(isRecognizedIssue).filter((issue) => issue.isActionable).forEach((issue) => {
			issues.push((
				<div key={issue.name}>
					<IssueTitle
						issue={issue}
					/>
				</div>
			));
		});
	}

	return (
		<IssueCategoryCardLayout
			highlightIndentation={HIGHLIGHT_INDENTATION_LEFT}
			icon={(
				<IssueCategoryNameIcon
					issueCategoryName={issueCategory.name}
				/>
			)}
			isHighlighted={isActive}
			onClickCallback={handleOpenIssueDetail}
			onMouseEnterCallback={highlightIssueCategoryHealthImpact.start}
			onMouseLeaveCallback={highlightIssueCategoryHealthImpact.stop}
		>
			<GeneralIssueCategoryCardContent
				score={(
					<AnimateHeartbeat
						enabled={issueCategory.isActionable}
					>
						<IssueScore
							isIgnored={issueCategory.isIgnored}
							state={matchAndReturn(issueCategory.state, {
								[GraphQL.IssueCategoryState.Closed]: IssueScoreState.Fixed,
								[GraphQL.IssueCategoryState.NotApplicable]: IssueScoreState.NotApplicable,
								[GraphQL.IssueCategoryState.NotRequired]: IssueScoreState.NotRequired,
								[GraphQL.IssueCategoryState.Open]: IssueScoreState.Broken,
								[GraphQL.IssueCategoryState.Unknown]: IssueScoreState.Unknown,
							})}
							value={getPointsToBeDisplayed(issueCategory)}
						/>
					</AnimateHeartbeat>
				)}
				title={(
					<IssueCategoryTitle
						issueCategoryName={issueCategory.name}
					/>
				)}
			>
				<List>
					{issues}
				</List>
			</GeneralIssueCategoryCardContent>
		</IssueCategoryCardLayout>
	);
};



export default IssueCategoriesListCard;
