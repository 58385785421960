import React from 'react';

import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import FormWrapperContainer, {
	FormWrapperContainerSize,
} from '~/components/patterns/forms/wrappers/FormWrapperContainer';
import FormWrapperContent from '~/components/patterns/forms/wrappers/FormWrapperContent.part';
import FormWrapperFooter from '~/components/patterns/forms/wrappers/FormWrapperFooter.part';
import FormWrapperHeader, {
	FormWrapperHeaderStyle,
} from '~/components/patterns/forms/wrappers/FormWrapperHeader.part';



type Props = React.PropsWithChildren<{
	/** Flag for edit mode highlight */
	editMode?: boolean,
	footer?: React.ReactNode,
	/** Predefined gaps around content */
	gaps?: boolean,
	children: React.ReactNode,
	/** Special highlight of forms only for admins */
	isForAdmins?: boolean,
	onClickCallback?: () => void,
	onKeyUpCallback?: () => void,
	subtitle?: React.ReactNode,
	title: React.ReactNode,
}>;



const FormFieldsWrapper = React.forwardRef<HTMLDivElement | null, Props>((props, ref) => {
	const {
		editMode,
		footer,
		gaps = true,
		children,
		isForAdmins,
		onClickCallback,
		onKeyUpCallback,
		subtitle,
		title,
	} = props;

	return (
		<FormWrapperContainer
			editMode={editMode}
			onClickCallback={onClickCallback}
			onKeyUpCallback={onKeyUpCallback}
			ref={ref}
			size={FormWrapperContainerSize.Form}
		>
			<BorderedBox
				footer={footer && (
					<FormWrapperFooter>
						{footer}
					</FormWrapperFooter>
				)}
				footerPaddingSize={0}
				header={(
					<FormWrapperHeader
						isForAdmins={isForAdmins}
						style={editMode ? FormWrapperHeaderStyle.EditMode : FormWrapperHeaderStyle.Default}
						subtitle={subtitle}
						title={title}
					/>
				)}
				headerPaddingSize={0}
				paddingSize={0}
			>
				<FormWrapperContent gaps={gaps}>
					{children}
				</FormWrapperContent>
			</BorderedBox>
		</FormWrapperContainer>
	);
});



export default FormFieldsWrapper;
