import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ConversionsIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Group"
				stroke="none"
				strokeWidth="1"
			>
				<path
					d="M37.5333,23.79615 C37.5333,22.99065 38.1858,22.33815 38.9913,22.33815 L43.5768,22.33815 C44.3808,22.33815 45.0348,22.99065 45.0348,23.79615 L45.0348,46.38015 C45.0348,47.18565 44.3808,47.83815 43.5768,47.83815 L38.9913,47.83815 C38.1858,47.83815 37.5333,47.18565 37.5333,46.38015 L37.5333,23.79615 Z"
					id="Stroke-3"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 2}
				>
				</path>
				<path
					d="M25.5774,46.3398 L25.5774,14.8383 C25.5774,14.0103 26.2494,13.3383 27.0774,13.3383 L31.5789,13.3383 C32.4069,13.3383 33.0789,14.0103 33.0789,14.8383 L33.0789,46.3398 C33.0789,47.1678 32.4069,47.8398 31.5789,47.8398 L27.0774,47.8398 C26.2494,47.8398 25.5774,47.1678 25.5774,46.3398 L25.5774,46.3398 Z"
					id="Stroke-5"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 2}
				>
				</path>
				<path
					d="M13.5333,46.3308 L13.5333,31.3398 C13.5333,30.5118 14.2053,29.8398 15.0333,29.8398 L19.5348,29.8398 C20.3628,29.8398 21.0348,30.5118 21.0348,31.3398 L21.0348,46.3308 C21.0348,47.1588 20.3628,47.8308 19.5348,47.8308 L15.0333,47.8308 C14.2053,47.8308 13.5333,47.1588 13.5333,46.3308 L13.5333,46.3308 Z"
					id="Stroke-7"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 2}
				>
				</path>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default ConversionsIconBuilder;

