import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeSnippets from '~/components/patterns/structuredValues/CodeSnippets';
import UnreliableResponseName from '~/components/names/UnreliableResponseName';



const messages = defineMessages({
	redirectTarget: {
		id: 'ui.general.redirectTarget',
	},
	response: {
		id: 'ui.platformIssueDetail.domain.columns.response',
	},
	unreachable: {
		id: 'ui.platformIssueDetail.robotsTxtInaccessible.unreachable',
	},
	url: {
		id: 'ui.contentData.url',
	},
});



class RobotsTxtStatus extends Component {

	render() {
		const {
			lastUnreliableCircumstance,
			redirectTarget,
			statusCode,
			url,
		} = this.props;

		let redirectTargetElement;
		let statusElement;

		if (statusCode === null) {
			statusElement = (
				<FormattedMessage
					{...messages.unreachable}
					values={{
						reason: (
							<UnreliableResponseName reason={lastUnreliableCircumstance} />
						),
					}}
				/>
			);
		} else {
			statusElement = statusCode;

			if (statusCode < 400) {
				redirectTargetElement = redirectTarget;
			}
		}

		const snippets = [
			{
				label: (
					<FormattedMessage {...messages.url} />
				),
				content: url,
			},
			{
				label: (
					<FormattedMessage {...messages.response} />
				),
				content: statusElement,
			},
		];

		if (redirectTargetElement) {
			snippets.push({
				label: (
					<FormattedMessage {...messages.redirectTarget} />
				),
				content: redirectTargetElement,
			});
		}

		return (
			<CodeSnippets snippets={snippets} />
		);
	}

}

RobotsTxtStatus.propTypes = {
	lastUnreliableCircumstance: PropTypes.string,
	redirectTarget: PropTypes.string,
	statusCode: PropTypes.number,
	url: PropTypes.string,
};



export default RobotsTxtStatus;
