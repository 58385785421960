import type GraphQL from '~/types/graphql';

import {
	useCalculateTaxQuery,
} from './useCalculateTax.gql';



function useCalculateTax(
	country: string | null,
	state: string | null,
	vatNumber: string | null,
): GraphQL.Tax | null {
	const { data } = useCalculateTaxQuery({
		skip: country === null,
		variables: {
			country: country ?? '',
			state,
			vatNumber: vatNumber !== null ? 'foo' : null,
		},
	});

	return data?.tax ?? null;
}



export default useCalculateTax;
