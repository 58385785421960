import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AddAnotherStepButton from '~/components/logic/customElements/AddAnotherStepButton';
import {
	BorderedBoxContext,
} from '~/components/patterns/boxes/BorderedBox';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import DragHandler, {
	DragHandlerVisibility,
} from '~/components/patterns/icons/DragHandler';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormFieldsContext from '~/components/atoms/forms/basis/FormFieldsContext';
import StripedTable, {
	type StripedTableDropResult,
	type StripedTableRendererInputBodyCell,
	type StripedTableRendererInputHeaderCell,
} from '~/components/patterns/tables/stripedTable/StripedTable';
import StripedTableCell, {
	StripedTableCellType,
} from '~/components/patterns/tables/stripedTable/StripedTableCell';
import TableDeleteButton from '~/components/patterns/tables/datatables/buttons/TableDeleteButton';

import {
	validateField,
} from '~/components/app/validations';

import useEditableFormTable from '~/hooks/useEditableFormTable';
import useFormContext from '~/hooks/useFormContext';
import useFormFieldsContext from '~/hooks/useFormFieldsContext';
import useFormValidations from '~/hooks/useFormValidations';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import touchSupported from '~/utilities/touchSupported';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import {
	type Rule,
	type Values,
} from '~/utilities/validations';



const messages = defineMessages({
	deleteStepButtonLabel: {
		id: 'ui.customElements.extractionSteps.actions.deleteStep.button',
	},
});

export type ComplexListFieldIsDisabledGetterInput = {
	itemValues: Record<string, any>,
	rowIndex: number,
};

export type ComplexListFieldMaximumNumberOfRowsReachedTooltipInput = {
	maximumNumberOfRows: number,
};

export type ComplexListFieldRef = {
	addRow: () => void,
	numberOfRows: number,
};

export type ComplexListFieldRendererInput = {
	fieldName: string,
	isDisabled: boolean,
	itemValues: Record<string, any>,
};

export type ComplexListFieldValidationInput = {
	f: Parameters<Parameters<typeof validateField>[1]>[0],
	getItemValue: (values: Values, fieldName: string) => any,
	getRowItemValue: (values: Values, rowIndex: number, fieldName: string) => any,
	listItems: (values: Values) => ReadonlyArray<{
		itemValues: Values,
		rowIndex: number,
	}>,
	rowIndex: number,
};

type Field = {
	defaultValue?: any,
	label: React.ReactNode,
	name: string,
	renderer: RenderProp<ComplexListFieldRendererInput>,
	validation?: (input: ComplexListFieldValidationInput) => ReadonlyArray<Rule>,
	width?: number,
};

function formatFieldName(
	name: string,
	field: Field,
	rowKey: number,
) {
	return name + '/' + rowKey + '/' + field.name;
}

function createItemValues(
	fields: ReadonlyArray<Field>,
	name: string,
	rowKey: number,
	values: Values,
) {
	return Object.fromEntries(
		fields.map(
			(field) => [field.name, values[formatFieldName(name, field, rowKey)]],
		),
	);
}

const defaultValidation = () => [];

const fallbackDefaultValue = [];



type Props = {
	addButtonLabel?: React.ReactNode,
	addExtraEmptyRow: boolean,
	deleteConfirmationRequirement?: (item: any) => boolean,
	fields: ReadonlyArray<Field>,
	isDisabledGetter?: (input: ComplexListFieldIsDisabledGetterInput) => boolean,
	isReorderable?: boolean,
	maximumNumberOfRows?: number,
	maximumNumberOfRowsReachedTooltip?: RenderProp<ComplexListFieldMaximumNumberOfRowsReachedTooltipInput>,
	name: string,
	showAddButton: boolean,
	showRowNumbers: boolean,
};

const ComplexListField = React.forwardRef<ComplexListFieldRef, Props>((props, ref) => {
	const {
		addButtonLabel,
		addExtraEmptyRow,
		deleteConfirmationRequirement,
		fields,
		isDisabledGetter,
		isReorderable = false,
		maximumNumberOfRows,
		maximumNumberOfRowsReachedTooltip,
		name,
		showAddButton,
		showRowNumbers,
	} = props;

	const borderedBoxContentContext = React.useContext(BorderedBoxContext);
	const formContext = useFormContext();

	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;
	const formContextValuesSelector = formContext.valuesSelector;

	const formDefaultValue = formContext.defaultValues[name] ?? fallbackDefaultValue;

	const editableFormTable = useEditableFormTable({
		addExtraEmptyRow,
		defaultRows: formDefaultValue.length ?? 0,
	});

	const internalFormFields = React.useMemo(
		() => editableFormTable.rows.flatMap(
			(rowKey, rowIndex) => fields.map(
				(field) => ({
					defaultValue: formDefaultValue?.[rowIndex]?.[field.name] ?? field.defaultValue,
					name: formatFieldName(name, field, rowKey),
				}),
			),
		),
		[
			editableFormTable.rows,
			fields,
			formDefaultValue,
			name,
		],
	);

	const formFieldsContext = useFormFieldsContext(internalFormFields);

	const formFieldsContextValues = formFieldsContext.state.values;

	const [highlightedRowIndex, setHighlightedRowIndex] = React.useState<number | null>(null);

	useFormValidations(
		name,
		React.useCallback(
			() => Object.fromEntries(
				editableFormTable.rows.flatMap(
					(rowKey, rowIndex) => fields.map(
						(field) => {
							const fieldName = formatFieldName(name, field, rowKey);

							const getRowItemValue = (values: Values, rowIndex: number, fieldName: string) => {
								return formContextValuesSelector(values)[name]?.[rowIndex]?.[fieldName];
							};

							const getItemValue = (values: Values, fieldName: string) => {
								return getRowItemValue(values, rowIndex, fieldName);
							};

							const listItems = (values: Values) => {
								return [...editableFormTable.rows.keys()].map(
									(rowIndex) => ({
										itemValues: Object.fromEntries(
											fields.map(
												(field) => ([
													field.name,
													getRowItemValue(values, rowIndex, field.name),
												]),
											),
										),
										rowIndex,
									}),
								);
							};

							const fieldValidation = field.validation ?? defaultValidation;

							return [
								fieldName,
								validateField(fieldName, (f) => {
									f.setValueSelector(
										(values) => getItemValue(values, field.name),
									);

									return fieldValidation({ f, getItemValue, getRowItemValue, listItems, rowIndex });
								}),
							];
						},
					),
				),
			),
			[
				editableFormTable,
				fields,
				formContextValuesSelector,
				name,
			],
		),
	);

	React.useImperativeHandle(ref, () => ({
		addRow: editableFormTable.addRow,
		numberOfRows: editableFormTable.rows.length,
	}));

	React.useEffect(
		() => {
			const value: Array<Record<string, any>> = [];

			editableFormTable.rows.forEach(
				(rowKey) => {
					const itemValues = createItemValues(
						fields,
						name,
						rowKey,
						formFieldsContextValues,
					);

					if (
						addExtraEmptyRow
						&& fields.every(
							(field) => (
								itemValues[field.name] === field.defaultValue
								|| itemValues[field.name] === undefined
							),
						)
					) {
						return;
					}

					value.push(itemValues);
				},
			);

			formContextOnChangeHandler(name, value, {
				interacted: false,
			});
		},
		[
			addExtraEmptyRow,
			editableFormTable,
			fields,
			formContextOnChangeHandler,
			formFieldsContextValues,
			name,
		],
	);

	React.useEffect(
		() => {
			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnUnmountHandler,
			name,
		],
	);

	const footer = React.useMemo(
		() => showAddButton && (
			<StripedTableCell type={StripedTableCellType.Cta}>
				<AddAnotherStepButton
					limitReachedTooltip={
						maximumNumberOfRows !== undefined && editableFormTable.rows.length >= maximumNumberOfRows ? (
							renderProp(maximumNumberOfRowsReachedTooltip, {
								maximumNumberOfRows,
							})
						) : null
					}
					onClickCallback={editableFormTable.addRow}
				>
					{addButtonLabel}
				</AddAnotherStepButton>
			</StripedTableCell>
		),
		[
			addButtonLabel,
			editableFormTable,
			maximumNumberOfRows,
			maximumNumberOfRowsReachedTooltip,
			showAddButton,
		],
	);

	const getColumnWidth = React.useCallback(
		({ columnIndex }) => {
			const effectiveColumnIndex = columnIndex - (showRowNumbers ? 1 : 0);

			if (effectiveColumnIndex === -1) {
				return 70;
			}

			if (effectiveColumnIndex >= fields.length) {
				return 55 + (isReorderable ? 30 : 0);
			}

			return getArrayItemAtSafeIndex(fields, effectiveColumnIndex).width;
		},
		[
			fields,
			isReorderable,
			showRowNumbers,
		],
	);

	const handleRowMouseEnter = React.useMemo(
		() => isReorderable ? (rowIndex: number) => {
			setHighlightedRowIndex(rowIndex);
		} : undefined,
		[
			isReorderable,
		],
	);

	const handleRowMouseLeave = React.useMemo(
		() => isReorderable ? (rowIndex: number) => {
			setHighlightedRowIndex(
				(highlightedRowIndex) => {
					return highlightedRowIndex === rowIndex
						? null
						: highlightedRowIndex;
				},
			);
		} : undefined,
		[
			isReorderable,
		],
	);

	const renderHeaderCell = React.useCallback(
		({ columnIndex }: StripedTableRendererInputHeaderCell) => {
			const effectiveColumnIndex = columnIndex - (showRowNumbers ? 1 : 0);

			if (effectiveColumnIndex >= 0 && effectiveColumnIndex < fields.length) {
				const field = getArrayItemAtSafeIndex(fields, effectiveColumnIndex);

				return (
					<StripedTableCell type={StripedTableCellType.Header}>
						{field.label}
					</StripedTableCell>
				);
			}

			return (
				<StripedTableCell type={StripedTableCellType.Header}>
					{''}
				</StripedTableCell>
			);
		},
		[
			fields,
			showRowNumbers,
		],
	);

	const renderBodyCell = React.useCallback(
		({ columnIndex, dragHandleProps, rowIndex, rowKey }: StripedTableRendererInputBodyCell) => {
			const effectiveColumnIndex = columnIndex - (showRowNumbers ? 1 : 0);

			if (effectiveColumnIndex === -1) {
				return (
					<StripedTableCell type={StripedTableCellType.Body}>
						{(rowIndex + 1) + '.'}
					</StripedTableCell>
				);
			}

			const itemValues = createItemValues(fields, name, rowKey, formFieldsContextValues);

			const isDisabled = isDisabledGetter
				? isDisabledGetter({ itemValues, rowIndex })
				: false;

			if (effectiveColumnIndex < fields.length) {
				const field = getArrayItemAtSafeIndex(fields, effectiveColumnIndex);
				const fieldName = formatFieldName(name, field, rowKey);

				let renderedField = renderProp(field.renderer, {
					fieldName,
					isDisabled,
					itemValues,
				});

				if (field.validation) {
					renderedField = (
						<FieldStatus
							name={fieldName}
							showIcon={false}
						>
							{renderedField}
						</FieldStatus>
					);
				}

				return (
					<StripedTableCell type={StripedTableCellType.Cta}>
						{renderedField}
					</StripedTableCell>
				);
			}

			const isDeleteConfirmationRequired = deleteConfirmationRequirement !== undefined
				? deleteConfirmationRequirement(itemValues)
				: false;

			return (
				<StripedTableCell type={StripedTableCellType.Cta}>
					<ButtonsGroup>
						<TableDeleteButton
							confirmation={isDeleteConfirmationRequired ? {
								buttonLabel: (
									<FormattedMessage {...messages.deleteStepButtonLabel} />
								),
							} : undefined}
							isDisabled={isDisabled}
							onClick={() => editableFormTable.removeRow(rowKey)}
						/>

						{isReorderable && (
							<DragHandler
								additionalProps={dragHandleProps}
								visibility={(editableFormTable.rows.length > 1 && (touchSupported || highlightedRowIndex == rowIndex)) ? DragHandlerVisibility.Hoverable : DragHandlerVisibility.Hidden}
							/>
						)}
					</ButtonsGroup>
				</StripedTableCell>
			);
		},
		[
			deleteConfirmationRequirement,
			editableFormTable,
			fields,
			formFieldsContextValues,
			highlightedRowIndex,
			isDisabledGetter,
			isReorderable,
			name,
			showRowNumbers,
		],
	);

	const handleRowDragAndDrop = React.useMemo(
		() => isReorderable ? (result: StripedTableDropResult) => {
			if (!result.destination) {
				return;
			}

			if (borderedBoxContentContext?.recalculateHeight) {
				setTimeout(() => {
					borderedBoxContentContext.recalculateHeight();
				}, 0);
			}

			if (result.destination.index !== result.source.index) {
				editableFormTable.moveRow(
					result.source.index,
					result.destination.index,
				);
			}
		} : undefined,
		[
			borderedBoxContentContext,
			editableFormTable,
			isReorderable,
		],
	);

	const columnCount = (
		fields.length
		+ (showRowNumbers ? 1 : 0)
		+ 1
	);

	const valuesSelector = React.useCallback(
		(values: Values) => {
			values = formContextValuesSelector(values);

			return Object.fromEntries(
				editableFormTable.rows.flatMap(
					(rowKey, rowIndex) => fields.map((field) => {
						return [
							formatFieldName(name, field, rowKey),
							values[name]?.[rowIndex]?.[field.name],
						];
					}),
				),
			);
		},
		[
			editableFormTable,
			fields,
			formContextValuesSelector,
			name,
		],
	);

	const updatedFormContext = React.useMemo(
		() => {
			return {
				...formFieldsContext.contextForProvider,
				valuesSelector,
			};
		},
		[
			formFieldsContext.contextForProvider,
			valuesSelector,
		],
	);

	return (
		<FormFieldsContext context={updatedFormContext}>
			<StripedTable
				bodyCellRenderer={renderBodyCell}
				columnCount={columnCount}
				columnWidth={getColumnWidth}
				footer={footer}
				hasDragHandler={isReorderable}
				hasDraggableRows={isReorderable}
				headerCellRenderer={renderHeaderCell}
				onDragEndCallback={handleRowDragAndDrop}
				onRowMouseEnterCallback={handleRowMouseEnter}
				onRowMouseLeaveCallback={handleRowMouseLeave}
				rowKeys={editableFormTable.rows}
			/>
		</FormFieldsContext>
	);
});



export default ComplexListField;
