import React from 'react';



function useTimeoutSince(
	callback: () => void,
	since: Date|null,
	delay: number,
) {
	React.useEffect(
		() => {
			if (since === null) {
				return;
			}

			const remainingTime = since.getTime() - Date.now() + delay;

			if (remainingTime < 0) {
				callback();
			}

			const timeout = setTimeout(
				() => callback(),
				remainingTime,
			);

			return () => clearTimeout(timeout);
		},
		[
			callback,
			delay,
			since,
		],
	);
}



export default useTimeoutSince;
