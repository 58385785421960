import React from 'react';

import type CK from '~/types/contentking';

import CustomerTeamContextDetailsOverview from '~/components/app/CustomerTeamContextDetailsOverview';

import {
	useWebsiteCustomerTeamContextDetailsQuery,
} from './WebsiteCustomerTeamContextDetailsOverview.gql';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteCustomerTeamContextDetailsOverview: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const { data } = useWebsiteCustomerTeamContextDetailsQuery({
		variables: {
			websiteId,
		},
	});

	const details = data?.website?.customerTeamContext?.details ?? null;

	return (
		<CustomerTeamContextDetailsOverview
			data={details}
		/>
	);
};



export default WebsiteCustomerTeamContextDetailsOverview;
