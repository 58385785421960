import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import TextualCellValue from '~/components/logic/datatables/cellValues/TextualCellValue';



class ContentCellValue extends Component {

	render() {
		const {
			value,
		} = this.props;

		return (
			<TextualCellValue
				value={value}
				zIndex={1200}
			/>
		);
	}

}

ContentCellValue.propTypes = {
	value: PropTypes.node.isRequired,
};



export default ContentCellValue;
