import {
	runAction,
} from './actions';

import {
	getColumnMaximumSize,
	getColumnMinimumSize,
} from './pagesColumns';



export function setColumnSettingsViaApi({ columnName, columnWidth, email }) {
	return runAction({
		action: 'UpdateUserColumnWidth',
		input: {
			columnName,
			columnWidth,
			email,
		},
	});
}



export function validateColumnWidth(column, width) {
	return (
		width >= getColumnMinimumSize(column)
		&& width <= getColumnMaximumSize(column)
	);
}
