import classNames from 'classnames';
import React from 'react';



export const STATE_IGNORED = 'ignored';
export const STATE_NOT_IGNORED = 'not-ignored';



type Props = {
	children: React.ReactNode,
	state:
		| typeof STATE_IGNORED
		| typeof STATE_NOT_IGNORED,
};

const IgnoringStatus: React.FC<Props> = (props) => {
	const {
		children,
		state,
	} = props;

	const componentClasses = classNames({
		'ignoring-status': true,

		// States
		'ignoring-status--ignored-state': state === STATE_IGNORED,
		'ignoring-status--not-ignored-state': state === STATE_NOT_IGNORED,
	});

	return (
		<span className={componentClasses}>
			{children}
		</span>
	);
};



export default IgnoringStatus;
