import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const IgnoredValue: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="ignored-value">
			<div className="ignored-value__message">
				{children}
			</div>
		</div>
	);
};



export default IgnoredValue;
