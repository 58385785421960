import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import IndexNowTriggerSelectField from '~/components/app/IndexNowTriggerSelectField';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';
import InternalLink from '~/components/patterns/links/InternalLink';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useUpdateIndexNowCustomElementTriggersMutation,
} from './IndexNowCustomElementChangesTriggerModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useIndexNowTriggers from '~/hooks/useIndexNowTriggers';
import useNavigation from '~/hooks/useNavigation';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	applyChanges: {
		id: 'ui.general.applyChanges',
	},
	customElementColumn: {
		id: 'ui.indexNowCustomElementChangesTriggerModal.columnCustomElement',
	},
	noCustomElements: {
		id: 'ui.indexNowCustomElementChangesTriggerModal.noCustomElements',
	},
	description: {
		id: 'ui.indexNowCustomElementChangesTriggerModal.description',
	},
	sidebar: {
		id: 'ui.indexNowCustomElementChangesTriggerModal.sidebar',
	},
	title: {
		id: 'ui.indexNowCustomElementChangesTriggerModal.title',
	},
});



const IndexNowCustomElementChangesTriggerModal: React.FC = () => {
	const websiteId = useWebsiteId();
	const classicFormBehavior = useClassicFormBehavior();
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const navigation = useNavigation();
	const { customElementTriggers } = useIndexNowTriggers(websiteId);

	const items = customElementDefinitions
		.listAll()
		.map((customElementDefinition) => customElementDefinition.name);

	const [updateCustomElementTriggers] = useUpdateIndexNowCustomElementTriggersMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateCustomElementTriggers({
				variables: {
					customElementNames: values.customElementNames,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			updateCustomElementTriggers,
			websiteId,
		],
	);

	return (
		<SimpleModal
			iconType={BasicIconType.Plus}
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							linkSupport: linkExternal('https://www.contentkingapp.com/support/realtime-indexnow'),
						}}
					/>
				</RichText>
			)}
			size={SimpleModalSize.XLarge}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Form
				defaultValues={{
					customElementNames: customElementTriggers,
				}}
				onSuccess={handleSubmit}
			>
				<ModalTextSection>
					<Copy {...messages.description} />
				</ModalTextSection>

				{customElementDefinitions.count > 0 && (
					<IndexNowTriggerSelectField
						itemLabel={(item) => {
							return item;
						}}
						items={items}
						itemsHeader={(
							<FormattedMessage {...messages.customElementColumn} />
						)}
						name="customElementNames"
					/>
				)}

				{customElementDefinitions.count === 0 && (
					<CenteredFormWrapper>
						<InterfaceMessage>
							<RichText>
								<Copy
									{...messages.noCustomElements}
									values={{
										linkCreateCustomElement: (chunks) => (
											<InternalLink
												onClickCallback={() => {
													classicFormBehavior.cancel();
													navigation.navigate({
														routeName: 'website.detail.customElements',
														routeParams: {
															action: 'create_custom_element',
															websiteId,
														},
													});
												}}
												routeName="website.detail.customElements"
												routeParams={{
													action: 'create_custom_element',
													websiteId,
												}}
											>
												{chunks}
											</InternalLink>
										),
									}}
								/>
							</RichText>
						</InterfaceMessage>
					</CenteredFormWrapper>
				)}

				<FormErrorMessages />

				<CenteredFormWrapper>
					<ButtonsLayout>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.applyChanges} />
						</SubmitButton>
					</ButtonsLayout>
				</CenteredFormWrapper>
			</Form>
		</SimpleModal>
	);
};



export default IndexNowCustomElementChangesTriggerModal;
