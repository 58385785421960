import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import NoDataMessage from './NoDataMessage';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';
import WebVitalsValue, {
	WebVitalsValueStatus,
} from '~/components/patterns/webVitals/WebVitalsValue';

import {
	useCoreWebVitalsAssessmentWidgetQuery,
} from './CoreWebVitalsAssessmentWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateForQuery,
} from '~/model/dashboard';



const messages = defineMessages({
	primaryActionLink: {
		id: 'ui.dashboardCoreWebVitalsWidget.primaryActionLink',
	},
	title: {
		id: 'ui.dashboardCoreWebVitalsWidget.title',
	},
	subtitle: {
		id: 'ui.dashboardCoreWebVitalsWidget.subtitle',
	},
	verdictCannotBeAssessed: {
		id: 'ui.dashboardCoreWebVitalsWidget.verdict.cannotBeAssessed',
	},
	verdictFail: {
		id: 'ui.dashboardCoreWebVitalsWidget.verdict.fail',
	},
	verdictPass: {
		id: 'ui.dashboardCoreWebVitalsWidget.verdict.pass',
	},
});



const CoreWebVitalsAssessmentWidget: React.FC = () => {
	const dateRange = useDashboardDateRange();
	const websiteId = useWebsiteId();
	const scope = 'website';

	const {
		data: metricData,
		loading,
		reasonForNoData,
	} = useDashboardQuery('webVitalsOriginSummaryData', useCoreWebVitalsAssessmentWidgetQuery, {
		variables: {
			scope,
			websiteId,
			...formatDateForQuery(dateRange),
		},
	});

	return (
		<WidgetCell type={WidgetType.CoreWebVitals}>
			<Widget
				flag={(
					<FormattedMessage {...messages.subtitle} />
				)}
				label={(
					<FormattedMessage {...messages.title} />
				)}
				loading={loading}
				overlay={metricData === null && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages.primaryActionLink} />
					),
					routeName: 'website.platform',
					routeParams: {
						websiteId,
					},
				}}
				showEndDate={true}
			>
				{metricData?.coreWebVitalsAssessment === GraphQL.CoreWebVitalsAssessment.Passed && (
					<WebVitalsValue status={WebVitalsValueStatus.Good}>
						<FormattedMessage {...messages.verdictPass} />
					</WebVitalsValue>
				)}

				{metricData?.coreWebVitalsAssessment === GraphQL.CoreWebVitalsAssessment.Failed && (
					<WebVitalsValue status={WebVitalsValueStatus.Poor}>
						<FormattedMessage {...messages.verdictFail} />
					</WebVitalsValue>
				)}

				{metricData?.coreWebVitalsAssessment === GraphQL.CoreWebVitalsAssessment.CannotBeAssessed && (
					<WebVitalsValue status={WebVitalsValueStatus.Unknown}>
						<FormattedMessage {...messages.verdictCannotBeAssessed} />
					</WebVitalsValue>
				)}
			</Widget>
		</WidgetCell>
	);
};


export default CoreWebVitalsAssessmentWidget;
