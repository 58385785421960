import React from 'react';

import HelpHint from '~/components/patterns/hints/HelpHint';
import Spinner, {
	SpinnerSize,
} from '~/components/patterns/loaders/Spinner';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';



export enum ValidationMessageStatus {
	Invalid = 'invalid',
	Valid = 'valid',
	ValidationInProgress = 'validating',
}

type Props = {
	/** Attached help hint with explanation tooltip */
	explanatoryTooltip?: React.ReactNode,
	/** Message */
	children?: React.ReactNode,
	/** Validation status */
	status: ValidationMessageStatus,
};



const statusMapping = {
	[ValidationMessageStatus.Invalid]: (
		<StatusFlag status={StatusFlagStatus.Critical} />
	),
	[ValidationMessageStatus.Valid]: (
		<StatusFlag status={StatusFlagStatus.Success} />
	),
	[ValidationMessageStatus.ValidationInProgress]: (
		<Spinner size={SpinnerSize.XSmall} />
	),
};



const ValidationMessage: React.FC<Props> = (props) => {
	const {
		explanatoryTooltip,
		children,
		status,
	} = props;

	return (
		<div className="validation-msg">
			<div className="validation-msg__status-icon">
				{statusMapping[status]}
			</div>
			<span className="validation-msg__text">
				{children}
			</span>
			{explanatoryTooltip && (
				<span className="validation-msg__help-hint">
					<HelpHint message={explanatoryTooltip} />
				</span>
			)}
		</div>
	);
};



export default ValidationMessage;
