import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderCaption from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderCaption';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitle, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderTitleNumericValue from '~/components/atoms/screenLayouts/components/header/headerTitle/builders/HeaderTitleNumericValue';
import HeaderTitleValue from '~/components/atoms/screenLayouts/components/header/headerTitle/builders/HeaderTitleValue';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';



const messages = defineMessages({
	createHeading: {
		id: 'ui.segments.editor.heading.create',
	},
	editHeading: {
		id: 'ui.segments.editor.heading.edit',
	},
	exit: {
		id: 'ui.segments.editor.exit',
	},
	placeholderLabel: {
		id: 'ui.segments.editor.placeholderLabel',
	},
	title: {
		id: 'ui.segments.editor.title',
	},
});



type Props = {
	isNewSegment?: boolean,
	limitedNumberOfPages: number | null,
	onRequestClose: () => void,
	segmentLabel: string | null,
};

const SegmentEditorHeader: React.FC<Props> = (props) => {
	const {
		isNewSegment,
		limitedNumberOfPages,
		onRequestClose,
		segmentLabel,
	} = props;

	return (
		<ScreenHeader>
			<HeaderTitleLayout>
				<HeaderCaption>
					<FormattedMessage {...messages.title} />
				</HeaderCaption>

				<HeaderTitle
					size={TITLE_SIZE_SMALL}
					value={(
						<>
							{segmentLabel !== null ? (
								<HeaderTitleValue value={segmentLabel} />
							) : (
								<HeaderTitleValue
									value={<FormattedMessage {...messages.placeholderLabel} />}
								/>
							)}

							{limitedNumberOfPages !== null && <HeaderTitleNumericValue value={limitedNumberOfPages} />}
						</>
					)}
				>
					{isNewSegment ? (
						<FormattedMessage {...messages.createHeading} />
					) : (
						<FormattedMessage {...messages.editHeading} />
					)}
				</HeaderTitle>
			</HeaderTitleLayout>

			<HeaderOptionsLayout>
				<HeaderButtonsLayout>
					<Button
						icon={(
							<BasicIcon type={BasicIconType.Cancel} />
						)}
						onClick={onRequestClose}
						style={ButtonStyle.Hollow}
					>
						<FormattedMessage {...messages.exit} />
					</Button>
				</HeaderButtonsLayout>
			</HeaderOptionsLayout>
		</ScreenHeader>
	);
};



export default SegmentEditorHeader;
