import classNames from 'classnames';
import React from 'react';



export enum PlansComparisonTableScope {
	DefaultComparison = 'default',
	MainFeaturesComparison = 'main-features',
}

type Props = React.PropsWithChildren<{
	/** Attached table labels */
	labels: React.ReactElement,
	scope?: PlansComparisonTableScope,
	/** Main title of table */
	title?: React.ReactNode,
}>;



const PlansComparisonTable = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		children,
		labels,
		scope = PlansComparisonTableScope.DefaultComparison,
		title,
	} = props;

	const componentClasses = classNames({
		'plans-comparison-table': true,
		['plans-comparison-table--' + scope + '-scope']: true,
	});

	return (
		<div
			className={componentClasses}
			ref={ref}
		>
			<div className="plans-comparison-table__sidebar">
				{title && (
					<div className="plans-comparison-table__title">
						{title}
					</div>
				)}
				<div className="plans-comparison-table__labels">
					{labels}
				</div>
			</div>
			<div className="plans-comparison-table__table">
				<div className="plans-comparison-table__table-content">
					{title && (
						<div className="plans-comparison-table__title plans-comparison-table__title--fake">
							{title}
						</div>
					)}
					<div className="plans-comparison-table__body">
						{children}
					</div>
				</div>
			</div>
		</div>
	);
});



export default PlansComparisonTable;
