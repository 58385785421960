import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import CountSlider, {
	CountSliderBubbleContentAlignment,
} from '~/components/patterns/forms/fields/CountSlider';
import CountSliderContext from '~/components/atoms/forms/components/countSliders/abstractBuilders/CountSliderContext';
import CountSliderFieldLayout from '~/components/patterns/forms/fieldParts/countSliders/CountSliderFieldLayout';
import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';
import FormDescription from '~/components/atoms/forms/components/rowParts/FormDescription';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';

import {
	useSensitivityFieldQuery,
} from './SensitivityField.gql';

import {
	SENSITIVITY_LEVEL_ALWAYS,
	SENSITIVITY_LEVEL_HIGH,
	SENSITIVITY_LEVEL_NUMBERS,
	getNumberOfSensitivityLevel,
	getSensitivityLevel,
} from '~/model/alerts';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	scenarioAlways: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.always',
	},
	scenarioComplex: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.complex',
	},
	scenarioEmpty: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.empty',
	},
	scenarioSingle: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.single',
	},
});



function usePrefetch({ websiteId }) {
	useSensitivityFieldQuery({
		variables: {
			websiteId,
		},
	});
}



function useScenariosIllustratingAlertSensitivity(websiteId) {
	const {
		data,
	} = useSensitivityFieldQuery({
		pollInterval: 60_000,
		variables: {
			websiteId,
		},
	});

	return {
		getScenarioForScopeAndSensitivityLevel: React.useCallback(
			({ scope, sensitivityLevel }) => {
				if (!data || !data.website) {
					return null;
				}

				if (scope !== 'website') {
					scope = 'segment/' + scope;
				}

				return data.website.scenariosIllustratingAlertSensitivity.find((scenario) => {
					return (
						scenario.scope === scope
						&& scenario.sensitivity === sensitivityLevel
					);
				});
			},
			[
				data,
			],
		),
	};
}



const SensitivityScenario = (props) => {
	const {
		scenarios,
		scope,
		sensitivityLevel,
	} = props;

	const scenario = scenarios.getScenarioForScopeAndSensitivityLevel({
		scope,
		sensitivityLevel: sensitivityLevel === SENSITIVITY_LEVEL_ALWAYS
			? SENSITIVITY_LEVEL_HIGH
			: sensitivityLevel,
	});

	if (!scenario) {
		return null;
	}

	if (scenario.scopeSize === 0) {
		return (
			<Copy {...messages.scenarioEmpty} />
		);
	} else if (sensitivityLevel === SENSITIVITY_LEVEL_ALWAYS) {
		return (
			<Copy {...messages.scenarioAlways} />
		);
	} else if (scenario.countOfLeastImportantPagesToTriggerAlert === 1) {
		return (
			<Copy
				{...messages.scenarioSingle}
				values={{
					necessaryPages: 1,
					scopePages: scenario.scopeSize,
				}}
			/>
		);
	}

	return (
		<Copy
			{...messages.scenarioComplex}
			values={{
				leastImportantPages: scenario.countOfLeastImportantPagesToTriggerAlert,
				mostImportantPages: scenario.countOfMostImportantPagesToTriggerAlert,
				scopePages: scenario.scopeSize,
			}}
		/>
	);
};



type Props = {
	name: string,
	websiteId: CK.WebsiteId,
};

const SensitivityField: React.FC<Props> = (props) => {
	const {
		name,
		websiteId,
	} = props;

	const formContext = React.useContext(FormContext);

	const {
		defaultValues,
		focused,
		onChangeHandler: parentOnChangeHandler,
		values,
	} = formContext;

	const onChangeHandler = React.useCallback(
		(name, value, options) => {
			return parentOnChangeHandler(
				name,
				getSensitivityLevel(value),
				options,
			);
		},
		[
			parentOnChangeHandler,
		],
	);

	const newFormContext = React.useMemo(
		() => {
			return {
				...formContext,
				onChangeHandler,
			};
		},
		[
			formContext,
			onChangeHandler,
		],
	);

	const scenariosIllustratingAlertSensitivity = useScenariosIllustratingAlertSensitivity(websiteId);

	const renderField = React.useCallback(
		({ currentStep, currentValue, minStep, onSliderStepChangeHandler, sliderMax }) => {
			const countSliderOptions: Partial<React.ComponentProps<typeof CountSlider>> = {};

			const currentLevel = currentValue !== null
				? getSensitivityLevel(currentValue)
				: null;

			if (minStep) {
				countSliderOptions.highlightedValue = minStep;
			}

			if (focused === name) {
				countSliderOptions.handlerBubble = currentLevel;
			}

			if (currentStep === null) {
				currentStep = 0;
			}

			return (
				<List size={ListSize.Small}>
					<CountSliderFieldLayout
						slider={(
							<CountSlider
								handlerBubbleContentAlignment={CountSliderBubbleContentAlignment.Center}
								max={sliderMax}
								onChangeCallback={onSliderStepChangeHandler}
								showStepMarks={true}
								value={currentStep}
								{...countSliderOptions}
							/>
						)}

					/>
					<FormDescription>
						<div style={{ minHeight: '96px' }}>
							<SensitivityScenario
								scenarios={scenariosIllustratingAlertSensitivity}
								scope={values.scope}
								sensitivityLevel={currentLevel}
							/>
						</div>
					</FormDescription>
				</List>
			);
		},
		[
			focused,
			name,
			scenariosIllustratingAlertSensitivity,
			values.scope,
		],
	);

	let value = values[name] ?? defaultValues[name];

	if (value === undefined) {
		return null;
	}

	if (value !== '') {
		value = getNumberOfSensitivityLevel(value) + 1;
	} else {
		value = undefined;
	}

	return (
		<FormContext.Provider value={newFormContext}>
			<CountSliderContext
				maxLimit={Math.max(...SENSITIVITY_LEVEL_NUMBERS)}
				maxValue={Math.max(...SENSITIVITY_LEVEL_NUMBERS)}
				minLimit={getArrayItemAtSafeIndex(SENSITIVITY_LEVEL_NUMBERS, 1)}
				minValue={Math.min(...SENSITIVITY_LEVEL_NUMBERS)}
				name={name}
				numberOfSteps={SENSITIVITY_LEVEL_NUMBERS.length}
				stepGetter={(value) => {
					return SENSITIVITY_LEVEL_NUMBERS.indexOf(value);
				}}
				value={value}
				valueGetter={(step) => {
					return getArrayItemAtSafeIndex(SENSITIVITY_LEVEL_NUMBERS, step);
				}}
			>
				{renderField}
			</CountSliderContext>
		</FormContext.Provider>
	);
};



export default SensitivityField;

export {
	usePrefetch,
};
