import React from 'react';



type Props = {
	children: React.ReactNode,
};



const OldValue: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<span className="old-value">
			{children}
		</span>
	);
};


export default OldValue;
