import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type Props = {
	children?: React.ReactNode,
};

const FormRows: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const elements = flattenChildren(children).filter(notEmpty);

	return (
		<div className="form-rows">
			{elements.map((child, index) => {
				const key = React.isValidElement(child)
					? child.key
					: index;

				return (
					<div
						className="form-rows__row"
						key={'form-row-' + key}
					>
						{child}
					</div>
				);
			})}
		</div>
	);
};



export default FormRows;
