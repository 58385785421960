import classNames from 'classnames';
import React from 'react';

import AttachedChartOverlay from '~/components/patterns/charts/structures/AttachedChartOverlay';
import Measurer from '~/utilities/Measurer';



type Props = {
	children?: React.ReactNode,
	details?: React.ReactNode,
	label: React.ReactNode,
	name?: string,
	overlay?: React.ReactNode,
};



const LargeChartLayout: React.FC<Props> = (props) => {
	const {
		children,
		details,
		label,
		name,
		overlay,
	} = props;

	return (
		<Measurer>
			{({ containerWidth }) => {
				const componentClasses = classNames({
					'large-chart-layout': true,
					'large-chart-layout--larger': containerWidth > 750,
					['js-large-chart-layout--' + name]: name,
				});

				return (
					<div className={componentClasses}>
						<div className="large-chart-layout__overview">
							<h2 className="large-chart-layout__label">
								{label}
							</h2>

							{details && (
								<div className="large-chart-layout__details">
									{details}
								</div>
							)}
						</div>
						<div
							className="large-chart-layout__chart"
							style={{
								['--bg' as any]: '#f8f9fa',
							}}
						>
							<AttachedChartOverlay overlay={overlay}>
								{children}
							</AttachedChartOverlay>
						</div>
					</div>
				);
			}}
		</Measurer>
	);
};



export default LargeChartLayout;
