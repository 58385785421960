import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useWebsiteAlertDefinitions from './useWebsiteAlertDefinitions';
import useWebsiteSegmentDefinitions from './useWebsiteSegmentDefinitions';

import {
	ALERT_ENRICHMENT_FIELD_CHANGED,
	ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
	ALERT_PROPERTY_CHANGED,
} from '~/model/alerts';

import {
	dependsOnColumns,
} from '~/model/segments';



type ReturnType = {
	isLoaded: boolean,
	getAlertDefinitionsDependingOnField: (field: string) => Array<GraphQL.AlertDefinition>,
};



function useAlertDefinitionDependencies(
	websiteId: CK.WebsiteId,
): ReturnType {
	const alertDefinitions = useWebsiteAlertDefinitions(websiteId);
	const segments = useWebsiteSegmentDefinitions(websiteId);

	return React.useMemo(
		() => {
			const isLoaded = alertDefinitions.isLoaded && segments.isLoaded;

			function getAlertDefinitionsDependingOnField(field: string) {
				if (!isLoaded) {
					return [];
				}

				const segmentsDependingOnCustomElement = segments.listAll().filter((segment) => {
					return dependsOnColumns(
						segments.listAll(),
						segment,
						[field],
					);
				});

				const dependentSegmentScopes = segmentsDependingOnCustomElement.map((segment) => {
					return `segment:${segment.name}`;
				});

				return alertDefinitions
					.listAll()
					.filter((alertDefinition) => {
						if (
							alertDefinition.alertType === ALERT_PROPERTY_CHANGED
							&& alertDefinition.settings.property === field
						) {
							return true;
						}

						if (
							alertDefinition.alertType === ALERT_ENRICHMENT_FIELD_CHANGED
							&& alertDefinition.settings.property === field
						) {
							return true;
						}

						if (
							alertDefinition.alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT
							&& alertDefinition.settings.relevantFields.includes(field)
						) {
							return true;
						}

						if (dependentSegmentScopes.includes(alertDefinition.scope)) {
							return true;
						}

						return false;
					});
			}

			return {
				isLoaded,
				getAlertDefinitionsDependingOnField,
			};
		},
		[
			alertDefinitions,
			segments,
		],
	);
}



export default useAlertDefinitionDependencies;
