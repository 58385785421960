import type CK from '~/types/contentking';

import {
	useWebsiteCookiesQuery,
} from './useWebsiteCookies.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteCookies(websiteId: CK.WebsiteId | null) {
	const { data } = useWebsiteCookiesQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.cookies) ?? null;
}



export default useWebsiteCookies;
