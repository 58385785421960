import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import HistoricalIssueHealthImpactExplanationsTable from '~/components/app/HistoricalIssueHealthImpactExplanationsTable';
import IssueHealthImpact, {
	IssueHealthImpactAbout,
	IssueHealthImpactFlow,
	IssueHealthImpactScope,
	IssueHealthImpactSize,
} from '~/components/logic/issues/IssueHealthImpact';
import IssueInstruction from '~/components/logic/issues/IssueInstruction';
import IssueLayout from '~/components/atoms/issues/components/issueDetail/IssueLayout';
import IssueTitle from '~/components/logic/issues/IssueTitle';
import SeeChangesButton from '~/components/logic/issuesOverview/SeeChangesButton';

import {
	openSingleIssueAffectedPages,
} from '~/actions/issuesScreen';

import {
	type IssueCategoryName,
	type IssueName,
} from '~/model/issuesNew';

import {
	scopeSelector,
} from '~/state/websiteIssuesComparison/selectors';



type Props = {
	issueCategoryName: IssueCategoryName,
	issue: {
		configuration: Record<string, any>,
		context: any,
		health: {
			change: {
				next: {
					gained: number,
					toGain: number,
				},
				previous: {
					gained: number,
					toGain: number,
				},
				type: 'worse' | 'better' | 'same',
			},
			toGain: number,
		},
		isConfigurationModified: boolean,
		isInitialComparison: boolean,
		name: IssueName,
		scope: {
			movements: Record<string, any>,
			name: 'pages' | 'platform',
		},
		state: GraphQL.IssueState,
	},
};

const ComparisonIssueDetail: React.FC<Props> = (props) => {
	const {
		issueCategoryName,
		issue,
	} = props;

	const dispatch = useDispatch();
	const scope = useSelector(scopeSelector);

	const issueContext = issue.context;
	const issueName = issue.name;

	const handleAffectedPagesButtonClick = React.useCallback(
		(issueChanges, e) => {
			e.preventDefault();

			dispatch(
				openSingleIssueAffectedPages(
					issueCategoryName,
					issueName,
					{
						issue_change: issueChanges,
						segment: scope === 'website' ? 'all' : scope,
					},
				),
			);
		},
		[
			dispatch,
			issueCategoryName,
			issueName,
			scope,
		],
	);

	const issueForTitle = React.useMemo(
		() => ({
			context: issueContext,
			name: issueName,
			state: GraphQL.IssueState.Open,
		}),
		[
			issueContext,
			issueName,
		],
	);

	let illustration: React.ReactNode = null;

	const scores: Array<React.ReactNode> = [];

	const canHaveExplainableChanges = issue.scope.name === 'pages';

	if (canHaveExplainableChanges) {
		const totalNumberOfChanges = Object.values(issue.scope.movements).reduce(
			(total, numberOfPages) => total + numberOfPages,
			0,
		);

		const hasExplainableChanges = totalNumberOfChanges > 0;

		scores.push(
			<SeeChangesButton
				isDisabled={hasExplainableChanges === false}
				issueCategory={issueCategoryName}
				issueType={issue.name}
				key="main-changes-button"
			/>,
		);

		illustration = (
			<HistoricalIssueHealthImpactExplanationsTable
				movements={issue.scope.movements}
				onClickCallback={handleAffectedPagesButtonClick}
				scope={scope}
			/>
		);
	}

	scores.push(
		<IssueHealthImpact
			about={IssueHealthImpactAbout.Issue}
			flow={IssueHealthImpactFlow.Row}
			issue={issue}
			key="score"
			scope={scope === 'website' ? IssueHealthImpactScope.Website : IssueHealthImpactScope.Segment}
			size={IssueHealthImpactSize.Small}
		/>,
	);

	return (
		<IssueLayout
			actionElements={scores}
			title={(
				<IssueTitle
					issue={issueForTitle}
				/>
			)}
		>
			{illustration}

			<IssueInstruction
				issue={issue}
			/>
		</IssueLayout>
	);
};



export default ComparisonIssueDetail;
