/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirstInputDelayWidgetQueryVariables = GraphQL.Exact<{
  date: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type FirstInputDelayWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly webVitalsOriginSummaryData: { readonly __typename?: 'WebsiteDashboardWebVitalsOriginSummaryData', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: { readonly __typename?: 'WebVitalsOriginSummaryData', readonly firstInputDelay: { readonly __typename?: 'WebVitalsOriginMetric', readonly maximumFast: number, readonly maximumGood: number, readonly percentageFast: CK.Percentage, readonly percentageGood: CK.Percentage, readonly percentageSlow: CK.Percentage, readonly percentile: number, readonly verdict: GraphQL.WebVitalVerdict } | null } | null } } };


export const FirstInputDelayWidgetDocument = gql`
    query FirstInputDelayWidget($date: DateYMD!, $scope: String!, $websiteId: WebsiteId!) {
  dashboardData {
    webVitalsOriginSummaryData(date: $date, scope: $scope, websiteId: $websiteId) {
      data {
        firstInputDelay {
          maximumFast
          maximumGood
          percentageFast
          percentageGood
          percentageSlow
          percentile
          verdict
        }
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useFirstInputDelayWidgetQuery__
 *
 * To run a query within a React component, call `useFirstInputDelayWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirstInputDelayWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirstInputDelayWidgetQuery({
 *   variables: {
 *      date: // value for 'date'
 *      scope: // value for 'scope'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useFirstInputDelayWidgetQuery(baseOptions: Apollo.QueryHookOptions<FirstInputDelayWidgetQuery, FirstInputDelayWidgetQueryVariables> & ({ variables: FirstInputDelayWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FirstInputDelayWidgetQuery, FirstInputDelayWidgetQueryVariables>(FirstInputDelayWidgetDocument, options);
      }
export function useFirstInputDelayWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FirstInputDelayWidgetQuery, FirstInputDelayWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FirstInputDelayWidgetQuery, FirstInputDelayWidgetQueryVariables>(FirstInputDelayWidgetDocument, options);
        }
export function useFirstInputDelayWidgetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FirstInputDelayWidgetQuery, FirstInputDelayWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FirstInputDelayWidgetQuery, FirstInputDelayWidgetQueryVariables>(FirstInputDelayWidgetDocument, options);
        }
export type FirstInputDelayWidgetQueryHookResult = ReturnType<typeof useFirstInputDelayWidgetQuery>;
export type FirstInputDelayWidgetLazyQueryHookResult = ReturnType<typeof useFirstInputDelayWidgetLazyQuery>;
export type FirstInputDelayWidgetSuspenseQueryHookResult = ReturnType<typeof useFirstInputDelayWidgetSuspenseQuery>;
export type FirstInputDelayWidgetQueryResult = Apollo.QueryResult<FirstInputDelayWidgetQuery, FirstInputDelayWidgetQueryVariables>;