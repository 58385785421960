import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import ExpressiveValue from '~/components/patterns/values/ExpressiveValue';
import HelpHint from '~/components/patterns/hints/HelpHint';
import List from '~/components/patterns/lists/List';



const messages = defineMessages({
	removedFromAlert: {
		id: 'ui.alerts.overview.incident.details.removedFromAlert',
	},
	tooltip: {
		id: 'ui.alerts.overview.incident.details.removedFromAlert.tooltip',
	},
});



class RemovedFromAlertMetric extends Component {

	render() {
		const {
			numberOfRemovedFromAlert,
		} = this.props;

		return (
			<List>
				<ExpressiveValue
					label={(
						<AttachedElement
							element={(
								<HelpHint
									message={(
										<FormattedMessage {...messages.tooltip} />
									)}
								/>
							)}
						>
							<FormattedMessage
								{...messages.removedFromAlert}
								values={{
									count__pages: numberOfRemovedFromAlert,
								}}
							/>
						</AttachedElement>
					)}
					value={(
						<FormattedNumber value={numberOfRemovedFromAlert} />
					)}
				/>
			</List>
		);
	}

}

RemovedFromAlertMetric.propTypes = {
	numberOfRemovedFromAlert: PropTypes.number.isRequired,
};



export default RemovedFromAlertMetric;
