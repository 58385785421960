import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ContextNavigationButton, {
	ContextNavigationButtonIconType,
} from '~/components/patterns/navigations/ContextNavigationButton';
import List from '~/components/patterns/lists/List';
import OnlyForAdmin from '~/components/app/OnlyForAdmin';
import RemoveTeamMemberConfirmationModal from '~/components/app/RemoveTeamMemberConfirmationModal';
import WithPermission from '~/components/logic/access/WithPermission';

import useAllUsers from '~/hooks/useAllUsers';
import useModals from '~/hooks/useModals';
import useUrlState from '~/hooks/useUrlState';
import useUserIntercomLink from '~/hooks/useUserIntercomLink';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	changeRole: {
		id: 'ui.teamDetail.members.roles.change',
	},
	removeFromTeam: {
		id: 'ui.teamDetail.members.removeFromTeam',
	},
});



type Props = {
	accountId: CK.AccountId,
	allowChangeOfRole: boolean,
	userId: string,
};

const MemberDetailContextActions: React.FC<Props> = (props) => {
	const {
		accountId,
		allowChangeOfRole,
		userId,
	} = props;

	const allUsers = useAllUsers();
	const modals = useModals();
	const urlState = useUrlState();
	const userIntercomLink = useUserIntercomLink(userId);

	const handleRemoveMemberClick = React.useCallback(
		() => {
			modals.openModal(
				() => (
					<RemoveTeamMemberConfirmationModal
						accountId={accountId}
						userId={userId}
					/>
				),
			);
		},
		[
			accountId,
			modals,
			userId,
		],
	);

	if (allUsers.listByAccount(accountId).length === 0) {
		return null;
	}

	return (
		<List>
			{allowChangeOfRole && (
				<WithPermission
					action={GraphQL.ActionWithAccountMembership.ChangeRole}
					objectId={`${accountId}/${userId}`}
					objectType={ObjectType.AccountMembership}
					showMessage={false}
				>
					{({ isAllowed, message }) => (
						<ContextNavigationButton
							disabled={isAllowed.yes === false}
							iconType={ContextNavigationButtonIconType.Person}
							routeName={urlState.name}
							routeParams={Object.assign({}, urlState.params, {
								action: 'change_role',
							})}
							tooltip={message}
						>
							<FormattedMessage {...messages.changeRole} />
						</ContextNavigationButton>
					)}
				</WithPermission>
			)}

			<WithPermission
				action={GraphQL.ActionWithAccountMembership.RemoveUser}
				objectId={`${accountId}/${userId}`}
				objectType={ObjectType.AccountMembership}
				showMessage={false}
			>
				{({ isAllowed, message }) => (
					<ContextNavigationButton
						disabled={isAllowed.yes === false}
						iconType={ContextNavigationButtonIconType.Delete}
						onClickCallback={handleRemoveMemberClick}
						tooltip={message}
					>
						<FormattedMessage {...messages.removeFromTeam} />
					</ContextNavigationButton>
				)}
			</WithPermission>

			<OnlyForAdmin canBeHidden={true}>
				{userIntercomLink !== null && (
					<ContextNavigationButton
						href={userIntercomLink}
						iconType={ContextNavigationButtonIconType.Link}
					>
						<span>Intercom link</span>
					</ContextNavigationButton>
				)}
			</OnlyForAdmin>
		</List>
	);
};



export default MemberDetailContextActions;
