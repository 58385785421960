import type CK from '~/types/contentking';

import {
	usePageStatusCodeQuery,
} from './usePageStatusCode.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageStatusCode(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageStatusCodeQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.statusCode ?? null;
}



export default usePageStatusCode;
