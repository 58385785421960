/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAdminCustomerPhaseFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountAdminCustomerPhaseFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly daysOfNonRenewalGracePeriod: number, readonly daysOfNonRenewalSuspension: number, readonly daysOfPaymentFailureGracePeriod: number, readonly daysOfPaymentFailureNoticeToAllUsers: number, readonly daysOfPaymentFailureNoticeToBillingManagers: number, readonly daysOfPaymentFailureSuspension: number, readonly daysToPremiumTrialRevival: number, readonly isPremiumTrialRevivalAllowedByCustomerTeam: boolean } | null, readonly premiumTrial: { readonly __typename?: 'AccountPremiumTrial', readonly expirationDate: CK.Timestamp } | null } | null };

export type UpdateAccountAdminCustomerPhaseSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  daysOfNonRenewalGracePeriod: GraphQL.Scalars['Int']['input'];
  daysOfNonRenewalSuspension: GraphQL.Scalars['Int']['input'];
  daysOfPaymentFailureGracePeriod: GraphQL.Scalars['Int']['input'];
  daysOfPaymentFailureNoticeToAllUsers: GraphQL.Scalars['Int']['input'];
  daysOfPaymentFailureNoticeToBillingManagers: GraphQL.Scalars['Int']['input'];
  daysOfPaymentFailureSuspension: GraphQL.Scalars['Int']['input'];
  daysToPremiumTrialRevival: GraphQL.Scalars['Int']['input'];
  isPremiumTrialRevivalAllowedByCustomerTeam: GraphQL.Scalars['Boolean']['input'];
  premiumTrialExpirationDate: GraphQL.InputMaybe<GraphQL.Scalars['Timestamp']['input']>;
}>;


export type UpdateAccountAdminCustomerPhaseSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateAccountAdminCustomerPhaseSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly daysOfNonRenewalGracePeriod: number, readonly daysOfNonRenewalSuspension: number, readonly daysOfPaymentFailureGracePeriod: number, readonly daysOfPaymentFailureNoticeToAllUsers: number, readonly daysOfPaymentFailureNoticeToBillingManagers: number, readonly daysOfPaymentFailureSuspension: number, readonly daysToPremiumTrialRevival: number, readonly isPremiumTrialRevivalAllowedByCustomerTeam: boolean } | null, readonly premiumTrial: { readonly __typename?: 'AccountPremiumTrial', readonly expirationDate: CK.Timestamp } | null, readonly premiumTrialOffer: { readonly __typename?: 'AccountPremiumTrialOffer', readonly durationInDays: number, readonly plan: GraphQL.AccountPlan } | null } | null } } };


export const AccountAdminCustomerPhaseFormDocument = gql`
    query AccountAdminCustomerPhaseForm($accountId: AccountId!) {
  account(id: $accountId) {
    adminSettings {
      daysOfNonRenewalGracePeriod
      daysOfNonRenewalSuspension
      daysOfPaymentFailureGracePeriod
      daysOfPaymentFailureNoticeToAllUsers
      daysOfPaymentFailureNoticeToBillingManagers
      daysOfPaymentFailureSuspension
      daysToPremiumTrialRevival
      isPremiumTrialRevivalAllowedByCustomerTeam
    }
    id
    premiumTrial {
      expirationDate
    }
  }
}
    `;

/**
 * __useAccountAdminCustomerPhaseFormQuery__
 *
 * To run a query within a React component, call `useAccountAdminCustomerPhaseFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAdminCustomerPhaseFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAdminCustomerPhaseFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountAdminCustomerPhaseFormQuery(baseOptions: Apollo.QueryHookOptions<AccountAdminCustomerPhaseFormQuery, AccountAdminCustomerPhaseFormQueryVariables> & ({ variables: AccountAdminCustomerPhaseFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountAdminCustomerPhaseFormQuery, AccountAdminCustomerPhaseFormQueryVariables>(AccountAdminCustomerPhaseFormDocument, options);
      }
export function useAccountAdminCustomerPhaseFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountAdminCustomerPhaseFormQuery, AccountAdminCustomerPhaseFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountAdminCustomerPhaseFormQuery, AccountAdminCustomerPhaseFormQueryVariables>(AccountAdminCustomerPhaseFormDocument, options);
        }
export function useAccountAdminCustomerPhaseFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountAdminCustomerPhaseFormQuery, AccountAdminCustomerPhaseFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountAdminCustomerPhaseFormQuery, AccountAdminCustomerPhaseFormQueryVariables>(AccountAdminCustomerPhaseFormDocument, options);
        }
export type AccountAdminCustomerPhaseFormQueryHookResult = ReturnType<typeof useAccountAdminCustomerPhaseFormQuery>;
export type AccountAdminCustomerPhaseFormLazyQueryHookResult = ReturnType<typeof useAccountAdminCustomerPhaseFormLazyQuery>;
export type AccountAdminCustomerPhaseFormSuspenseQueryHookResult = ReturnType<typeof useAccountAdminCustomerPhaseFormSuspenseQuery>;
export type AccountAdminCustomerPhaseFormQueryResult = Apollo.QueryResult<AccountAdminCustomerPhaseFormQuery, AccountAdminCustomerPhaseFormQueryVariables>;
export const UpdateAccountAdminCustomerPhaseSettingsDocument = gql`
    mutation UpdateAccountAdminCustomerPhaseSettings($accountId: AccountId!, $daysOfNonRenewalGracePeriod: Int!, $daysOfNonRenewalSuspension: Int!, $daysOfPaymentFailureGracePeriod: Int!, $daysOfPaymentFailureNoticeToAllUsers: Int!, $daysOfPaymentFailureNoticeToBillingManagers: Int!, $daysOfPaymentFailureSuspension: Int!, $daysToPremiumTrialRevival: Int!, $isPremiumTrialRevivalAllowedByCustomerTeam: Boolean!, $premiumTrialExpirationDate: Timestamp) {
  UpdateAccountAdminCustomerPhaseSettings(
    accountId: $accountId
    daysOfNonRenewalGracePeriod: $daysOfNonRenewalGracePeriod
    daysOfNonRenewalSuspension: $daysOfNonRenewalSuspension
    daysOfPaymentFailureGracePeriod: $daysOfPaymentFailureGracePeriod
    daysOfPaymentFailureNoticeToAllUsers: $daysOfPaymentFailureNoticeToAllUsers
    daysOfPaymentFailureNoticeToBillingManagers: $daysOfPaymentFailureNoticeToBillingManagers
    daysOfPaymentFailureSuspension: $daysOfPaymentFailureSuspension
    daysToPremiumTrialRevival: $daysToPremiumTrialRevival
    isPremiumTrialRevivalAllowedByCustomerTeam: $isPremiumTrialRevivalAllowedByCustomerTeam
    premiumTrialExpirationDate: $premiumTrialExpirationDate
  ) {
    query {
      account(id: $accountId) {
        adminSettings {
          daysOfNonRenewalGracePeriod
          daysOfNonRenewalSuspension
          daysOfPaymentFailureGracePeriod
          daysOfPaymentFailureNoticeToAllUsers
          daysOfPaymentFailureNoticeToBillingManagers
          daysOfPaymentFailureSuspension
          daysToPremiumTrialRevival
          isPremiumTrialRevivalAllowedByCustomerTeam
        }
        id
        premiumTrial {
          expirationDate
        }
        premiumTrialOffer {
          durationInDays
          plan
        }
      }
    }
  }
}
    `;
export type UpdateAccountAdminCustomerPhaseSettingsMutationFn = Apollo.MutationFunction<UpdateAccountAdminCustomerPhaseSettingsMutation, UpdateAccountAdminCustomerPhaseSettingsMutationVariables>;

/**
 * __useUpdateAccountAdminCustomerPhaseSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountAdminCustomerPhaseSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountAdminCustomerPhaseSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountAdminCustomerPhaseSettingsMutation, { data, loading, error }] = useUpdateAccountAdminCustomerPhaseSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      daysOfNonRenewalGracePeriod: // value for 'daysOfNonRenewalGracePeriod'
 *      daysOfNonRenewalSuspension: // value for 'daysOfNonRenewalSuspension'
 *      daysOfPaymentFailureGracePeriod: // value for 'daysOfPaymentFailureGracePeriod'
 *      daysOfPaymentFailureNoticeToAllUsers: // value for 'daysOfPaymentFailureNoticeToAllUsers'
 *      daysOfPaymentFailureNoticeToBillingManagers: // value for 'daysOfPaymentFailureNoticeToBillingManagers'
 *      daysOfPaymentFailureSuspension: // value for 'daysOfPaymentFailureSuspension'
 *      daysToPremiumTrialRevival: // value for 'daysToPremiumTrialRevival'
 *      isPremiumTrialRevivalAllowedByCustomerTeam: // value for 'isPremiumTrialRevivalAllowedByCustomerTeam'
 *      premiumTrialExpirationDate: // value for 'premiumTrialExpirationDate'
 *   },
 * });
 */
export function useUpdateAccountAdminCustomerPhaseSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountAdminCustomerPhaseSettingsMutation, UpdateAccountAdminCustomerPhaseSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountAdminCustomerPhaseSettingsMutation, UpdateAccountAdminCustomerPhaseSettingsMutationVariables>(UpdateAccountAdminCustomerPhaseSettingsDocument, options);
      }
export type UpdateAccountAdminCustomerPhaseSettingsMutationHookResult = ReturnType<typeof useUpdateAccountAdminCustomerPhaseSettingsMutation>;
export type UpdateAccountAdminCustomerPhaseSettingsMutationResult = Apollo.MutationResult<UpdateAccountAdminCustomerPhaseSettingsMutation>;
export type UpdateAccountAdminCustomerPhaseSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountAdminCustomerPhaseSettingsMutation, UpdateAccountAdminCustomerPhaseSettingsMutationVariables>;