/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookDemoMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type BookDemoMutation = { readonly __typename?: 'Mutation', readonly BookDemo: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isDemoAvailable: boolean } | null } } };


export const BookDemoDocument = gql`
    mutation BookDemo($accountId: AccountId!) {
  BookDemo(accountId: $accountId) {
    query {
      account(id: $accountId) {
        id
        isDemoAvailable
      }
    }
  }
}
    `;
export type BookDemoMutationFn = Apollo.MutationFunction<BookDemoMutation, BookDemoMutationVariables>;

/**
 * __useBookDemoMutation__
 *
 * To run a mutation, you first call `useBookDemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookDemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookDemoMutation, { data, loading, error }] = useBookDemoMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useBookDemoMutation(baseOptions?: Apollo.MutationHookOptions<BookDemoMutation, BookDemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookDemoMutation, BookDemoMutationVariables>(BookDemoDocument, options);
      }
export type BookDemoMutationHookResult = ReturnType<typeof useBookDemoMutation>;
export type BookDemoMutationResult = Apollo.MutationResult<BookDemoMutation>;
export type BookDemoMutationOptions = Apollo.BaseMutationOptions<BookDemoMutation, BookDemoMutationVariables>;