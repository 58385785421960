import type CK from '~/types/contentking';

import useBatchContext from '~/hooks/useBatchContext';



function useBatchContextRobotsTxt(websiteId: CK.WebsiteId | null) {
	return useBatchContext(
		`robotstxt/${websiteId ?? ''}`,
		10,
	);
}



export default useBatchContextRobotsTxt;
