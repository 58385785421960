import React from 'react';

import GraphQL from '~/types/graphql';

import DesktopAccountSignup from './DesktopAccountSignup';
import MobileAccountSignup from './MobileAccountSignup';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';
import useAccountMaximumRegularSignupPageBundle from '~/hooks/useAccountMaximumRegularSignupPageBundle';
import useAccountSignup from '~/hooks/useAccountSignup';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useAllowedPlans from '~/hooks/useAllowedPlans';
import useCalculatePrice from '~/hooks/useCalculatePrice';
import useViewportWidth from '~/hooks/useViewportWidth';

import {
	BenefitSection,
} from '~/model/benefits';

import {
	Tariff,
} from '~/model/pricing/tariffs';

import {
	isEnterprisePlan,
} from '~/model/plans';

import {
	LIST_OF_ALL_PLANS,
} from '~/model/universal';



const V4Sections = [
	BenefitSection.MainFeatures,
	BenefitSection.AdvancedFeatures,
	BenefitSection.AdvancedAnalysis,
	BenefitSection.Integrations,
	BenefitSection.MonitoringSettings,
	BenefitSection.Collaboration,
	BenefitSection.Services,
	BenefitSection.AccessControl,
];

const C2Sections = [
	BenefitSection.MainFeatures,
	BenefitSection.AdvancedFeatures,
	BenefitSection.AdvancedAnalysis,
	BenefitSection.Integrations,
	BenefitSection.MonitoringSettings,
	BenefitSection.Services,
	BenefitSection.AccessControl,
];

const AccountSignup: React.FC = () => {
	const accountId = useAccountId();

	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const accountMaximumRegularSignupPageBundle = useAccountMaximumRegularSignupPageBundle(accountId);
	const accountSignup = useAccountSignup(accountId);
	const accountTariff = useAccountTariff(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);
	const allowedPlans = useAllowedPlans(accountId);
	const calculatePrice = useCalculatePrice(accountId);
	const viewportWidth = useViewportWidth();

	if (
		accountId === null
		|| allowedBillingCycles === null
		|| allowedPlans === null
		|| accountTariff === null
	) {
		return null;
	}

	const showBillingCycleConfigurator = allowedBillingCycles.length > 1;
	const showCurrencyConfigurator = accountIsOutOfBand !== true;
	const showOnlyEnterprisePlan = allowedPlans.every(isEnterprisePlan);

	const sections = accountTariff === Tariff.C2 ? C2Sections : V4Sections;

	const plans = LIST_OF_ALL_PLANS[accountTariff].toArray();
	const disabledPlans: {
		[K in GraphQL.AccountPlan]?: 'accountTooLarge' | 'disallowed' | undefined
	} = {};

	plans.forEach((plan) => {
		if (isEnterprisePlan(plan)) {
			return;
		}

		if (!allowedPlans.includes(plan)) {
			disabledPlans[plan] = 'disallowed';
		} else if (
			accountSignup.pageBundle !== null
			&& accountMaximumRegularSignupPageBundle !== null
			&& accountSignup.pageBundle > accountMaximumRegularSignupPageBundle
		) {
			disabledPlans[plan] = 'accountTooLarge';
		} else {
			const price = calculatePrice({
				billingCycle: accountSignup.billingCycle,
				pageBundle: accountSignup.pageBundle,
				plan,
			});

			if (price === null) {
				disabledPlans[plan] = 'disallowed';
			} else if (Number.isNaN(price.total)) {
				disabledPlans[plan] = 'accountTooLarge';
			}
		}
	});

	let bestChoicePlan;
	let mostPopularPlan;

	if (!allowedPlans.includes(GraphQL.AccountPlan.Enterprise)) {
		bestChoicePlan = GraphQL.AccountPlan.Pro;
		mostPopularPlan = GraphQL.AccountPlan.Standard;
	}

	if (disabledPlans[bestChoicePlan] !== undefined) {
		bestChoicePlan = null;
	}

	if (disabledPlans[mostPopularPlan] !== undefined) {
		mostPopularPlan = null;
	}

	if (viewportWidth <= 800) {
		return (
			<MobileAccountSignup
				accountId={accountId}
				bestChoicePlan={bestChoicePlan}
				disabledPlans={disabledPlans}
				mostPopularPlan={mostPopularPlan}
				plans={plans}
				sections={sections}
				showBillingCycleConfigurator={showBillingCycleConfigurator}
				showCurrencyConfigurator={showCurrencyConfigurator}
				showOnlyEnterprisePlan={showOnlyEnterprisePlan}
				tariff={accountTariff}
			/>
		);
	}

	return (
		<DesktopAccountSignup
			accountId={accountId}
			bestChoicePlan={bestChoicePlan}
			disabledPlans={disabledPlans}
			mostPopularPlan={mostPopularPlan}
			plans={plans}
			sections={sections}
			showBillingCycleConfigurator={showBillingCycleConfigurator}
			showCurrencyConfigurator={showCurrencyConfigurator}
			showOnlyEnterprisePlan={showOnlyEnterprisePlan}
			tariff={accountTariff}
		/>
	);
};



export default AccountSignup;
