import React from 'react';

import FormsList from '~/components/atoms/lists/FormsList';
import JavascriptRenderingAndLighthouseForm from '~/components/app/JavascriptRenderingAndLighthouseForm';
import WebsiteMonitoringAdvancedSettingsForm from '~/components/atoms/forms/forms/WebsiteMonitoringAdvancedSettingsForm';
import WebsiteMonitoringForm from '~/components/atoms/forms/forms/WebsiteMonitoringForm';
import WebsiteSettingsMiscellaneousForm from '~/components/app/WebsiteSettingsMiscellaneousForm';



type Props = {
	crawlingSpeed: any,
	websiteDetail: any,
};

const WebsiteMonitoringGroup: React.FC<Props> = (props) => {
	const {
		crawlingSpeed,
		websiteDetail,
	} = props;

	return (
		<FormsList>
			<WebsiteMonitoringForm
				crawlingSpeed={crawlingSpeed}
				website={websiteDetail}
			/>

			<JavascriptRenderingAndLighthouseForm />

			<WebsiteMonitoringAdvancedSettingsForm
				websiteId={websiteDetail.get('id')}
			/>

			<WebsiteSettingsMiscellaneousForm
				websiteId={websiteDetail.get('id')}
			/>
		</FormsList>
	);
};



export default WebsiteMonitoringGroup;
