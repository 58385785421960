import classNames from 'classnames';
import React, {
	Component,
} from 'react';
import PropTypes from 'prop-types';



export const SIZE_DEFAULT = 'default';
export const SIZE_SMALL = 'small';

class SegmentsList extends Component {

	render() {
		const {
			children,
			inline,
			size,
		} = this.props;

		const elements = React.Children.toArray(children).filter((child) => !!child);

		const componentClasses = classNames({
			'segments-list': true,
			'segments-list--is-inline': inline,
			'segments-list--small': size === SIZE_SMALL,
		});

		return (
			<div className={componentClasses}>
				{React.Children.map(
					elements,
					(child) => {
						return (
							<div className="segments-list__item">
								{child}
							</div>
						);
					},
				)}
			</div>
		);
	}

}

SegmentsList.defaultProps = {
	inline: false,
	size: SIZE_DEFAULT,
};

SegmentsList.propTypes = {
	inline: PropTypes.bool,
	size: PropTypes.oneOf([
		SIZE_DEFAULT,
		SIZE_SMALL,
	]),
};



export default SegmentsList;
