import {
	setColumnSettingsViaApi,
	validateColumnWidth,
} from '~/model/columnsSettings';

import {
	userInfoSelector,
} from '~/state/authentication/selectors';



export const SET_COLUMN_SETTINGS_IS_SAVING = 'SET_COLUMN_SETTINGS_IS_SAVING';
export const STORE_COLUMN_SETTINGS = 'STORE_COLUMN_SETTINGS';



export function setColumnSettings(columnName, columnWidth) {
	return (dispatch, getState) => {
		if (validateColumnWidth(columnName, columnWidth)) {
			dispatch({
				type: STORE_COLUMN_SETTINGS,
				columnName,
				width: columnWidth,
			});

			const userInfo = userInfoSelector(getState());

			return setColumnSettingsViaApi({
				columnName,
				columnWidth,
				email: userInfo.get('email'),
			}).then(() => {
				return dispatch({
					type: SET_COLUMN_SETTINGS_IS_SAVING,
					columnName,
					value: false,
				});
			});
		}

		return Promise.resolve();
	};
}
