import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	labelLong: {
		id: 'ui.segments.management.segmentLabel.long',
	},
	labelShort: {
		id: 'ui.segments.management.segmentLabel.short',
	},
});



type Props = {
	label: React.ReactNode,
	labelThumb: React.ReactNode,
};

const SegmentsPreview: React.FC<Props> = (props) => {
	const {
		label,
		labelThumb,
	} = props;

	return (
		<div className="segments-preview">
			<div className="segments-preview__label">
				<FormattedMessage {...messages.labelShort} />
			</div>
			<div className="segments-preview__component segments-preview__component--thumb">
				{labelThumb}
			</div>

			<div className="segments-preview__label">
				<FormattedMessage {...messages.labelLong} />
			</div>
			<div className="segments-preview__component">
				{label}
			</div>
		</div>
	);
};



export default SegmentsPreview;
