import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import SwitchButtons, {
	SwitchButtonsSize,
} from '~/components/patterns/buttons/SwitchButtons';

import useCurrentColumnSetId from '~/hooks/useCurrentColumnSetId';
import useEnabledColumnsList from '~/hooks/useEnabledColumnsList';
import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	createFilterParameter as createPagesFilterParameter,
} from '~/actions/pages/overview';

import {
	PAGES_SECTION_GRAPHS,
	PAGES_SECTION_TABLE,
} from '~/model/ui/pagesScreen';

import {
	filterSelector as pagesFilterSelector,
	sortBySelector as pagesSortBySelector,
} from '~/state/ui/selectors';

import {
	pagesColumnsParameterSelector,
	pagesSectionSelector,
} from '~/state/ui/content/selectors';



const messages = defineMessages({
	graphs: {
		id: 'ui.contentOverview.views.graphs',
	},
	table: {
		id: 'ui.contentOverview.views.table',
	},
});



const PagesViewSwitch: React.FC = () => {
	const columns = useEnabledColumnsList();
	const columnsParameter = useSelector(pagesColumnsParameterSelector);
	const pagesFilter = useSelector(pagesFilterSelector);
	const pagesSection = useSelector(pagesSectionSelector);
	const pagesSortBy = useSelector(pagesSortBySelector);
	const selectedWebsiteId = useWebsiteId();
	const viewportWidth = useViewportWidth();
	const viewParameter = useCurrentColumnSetId();

	const sharedRouteParams = {
		columns: columnsParameter || undefined,
		filter: createPagesFilterParameter(
			pagesFilter,
			pagesSortBy,
			columns,
		) || undefined,
		view: viewParameter || undefined,
		websiteId: selectedWebsiteId,
	};

	return (
		<SwitchButtons
			buttons={[
				{
					icon: (
						<BasicIcon type={BasicIconType.Columns} />
					),
					isActive: pagesSection === PAGES_SECTION_TABLE,
					label: viewportWidth > 780 && (
						<FormattedMessage {...messages.table} />
					),
					linkRouteName: 'website.pages',
					linkRouteParams: sharedRouteParams,
					uppercase: true,
				},
				{
					icon: (
						<BasicIcon type={BasicIconType.Graphs} />
					),
					isActive: pagesSection === PAGES_SECTION_GRAPHS,
					label: viewportWidth > 780 && (
						<FormattedMessage {...messages.graphs} />
					),
					linkRouteName: 'website.pages',
					linkRouteParams: Object.assign({}, sharedRouteParams, {
						graphs: true,
					}),
					uppercase: true,
				},
			]}
			fullwidth={false}
			size={SwitchButtonsSize.Small}
		/>
	);
};



export default PagesViewSwitch;
