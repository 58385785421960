import React from 'react';



type Props = {
	separator?: string,
	width?: number,
};

const FieldSeparator: React.FC<Props> = (props) => {
	const {
		separator = '-',
		width = 15,
	} = props;

	return (
		<div
			className="field-separator"
			style={{
				width,
			}}
		>
			{separator}
		</div>
	);
};



export default FieldSeparator;
