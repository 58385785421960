import React from 'react';



type Props = {
	/** Discount value */
	discount: React.ReactElement,
	/** Possibility to display previous price before discount */
	oldPrice?: React.ReactElement,
};



const DiscountOverview: React.FC<Props> = (props) => {
	const {
		discount,
		oldPrice,
	} = props;

	return (
		<div className="discount-overview">
			{oldPrice && (
				<div className="discount-overview__old-price">
					{oldPrice}
				</div>
			)}

			<div className="discount-overview__discount">
				{discount}
			</div>
		</div>
	);
};



export default DiscountOverview;
