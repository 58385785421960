import classNames from 'classnames';
import React from 'react';

import styles from './TimelineEntry.module.scss';



type Props = {
	/** Additional info elements visible next to time */
	additionalInfo?: React.ReactNode,
	/** Date shown as title on timeline entry */
	date: React.ReactNode,
	/** Content of timeline entry */
	children: React.ReactNode,
	/** Flag that this entry is first from all entries */
	isFirstChild?: boolean,
	/** Flag that this entry is last from all entries */
	isLastChild?: boolean,
	/** Time shown on timeline entry */
	time: React.ReactNode,
};

const TimelineEntry: React.FC<Props> = (props) => {
	const {
		additionalInfo,
		date,
		children,
		isFirstChild,
		isLastChild,
		time,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isFirst]: isFirstChild,
		[styles.isLast]: isLastChild,
	});

	const additionalInfoArray = Array.isArray(additionalInfo) ? additionalInfo : [additionalInfo];

	return (
		<section className={componentClasses}>
			<div className={styles.header}>
				<div className={styles.title}>
					{date}
				</div>
				<div className={styles.otherInfo}>
					<span className={styles.time}>
						{time}
					</span>
					{additionalInfo && (
						<span className={styles.additionalElements}>
							{additionalInfoArray.filter((item) => !!item).map((item, index) => (
								<React.Fragment key={index}>
									<span className={styles.separator}>|</span>
									<span className={styles.additionalElement}>
										{item}
									</span>
								</React.Fragment>
							))}
						</span>
					)}
				</div>
			</div>
			<div className={styles.data}>
				{children}
			</div>
		</section>
	);
};



export default TimelineEntry;
