import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import {
	setFlashMessage,
} from '~/actions/flashMessage';



type FlashMessage = {
	message: string,
	messageVariables: Record<string, any>,
	route: string,
	style: 'success',
};



function useSetFlashMessage() {
	const dispatch = useDispatch();

	return React.useCallback(
		(flashMessage: FlashMessage) => {
			dispatch(
				setFlashMessage(flashMessage),
			);
		},
		[
			dispatch,
		],
	);
}



export default useSetFlashMessage;
