import memoize from 'memoizee';

import {
	type HiddenDashboardScopesQuery,
	useHiddenDashboardScopesQuery,
} from './useHiddenDashboardScopeIdentifiers.gql';

import useInitialUserPropertiesQuery from '~/hooks/useInitialUserPropertiesQuery';



const format = memoize(
	(data: HiddenDashboardScopesQuery | undefined) => {
		if (!data || !data.authenticatedSession || !data.authenticatedSession.user.personalTweaks) {
			return null;
		}

		return data.authenticatedSession.user.personalTweaks.hiddenDashboardScopes.map(
			(scopeIdentifier) => JSON.stringify(scopeIdentifier),
		);
	},
);



function useHiddenDashboardScopeIdentifiers(): Array<string> | null {
	const { data } = useInitialUserPropertiesQuery(
		useHiddenDashboardScopesQuery,
	);

	return format(data);
}



export default useHiddenDashboardScopeIdentifiers;
