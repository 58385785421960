import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttachedEnterpriseRibbon from '~/components/patterns/pricingPlans/AttachedEnterpriseRibbon';
import BenefitName from '../plans/BenefitName';
import Copy from '~/components/logic/Copy';
import EnterpriseBenefits from '~/components/patterns/pricingPlans/EnterpriseBenefits';
import PlanBox from '~/components/patterns/pricingPlans/PlanBox';
import PlanDetails, {
	PlanDetailsPlanNameSize,
} from '~/components/patterns/pricingPlans/PlanDetails';
import PlanName from '~/components/names/PlanName';
import PlanPriceSummary from '~/components/logic/account/components/PlanPriceSummary';
import SignupPlanComparisonButton from '~/components/app/SignupPlanComparisonButton';

import {
	Benefit,
} from '~/model/benefits';
import {
	getEnterprisePlanBenefits,
} from '~/model/universal';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';



const messages = defineMessages({
	[Benefit.AdminAndAccessControlFeatures]: {
		id: 'ui.planBenefits.enterprise.adminAndAccessControlFeatures',
	},
	[Benefit.AnalyticsAndGSCIntegrations]: {
		id: 'ui.planBenefits.enterprise.analyticsAndGSCIntegrations',
	},
	[Benefit.BodySnapshots]: {
		id: 'ui.planBenefits.enterprise.bodySnapshots',
	},
	[Benefit.ChangeTrackingAndAlerting]: {
		id: 'ui.planBenefits.enterprise.changeTrackingAndAlerting',
	},
	[Benefit.ChatPlatformIntegrations]: {
		id: 'ui.planBenefits.enterprise.chatPlatformIntegrations',
	},
	[Benefit.CustomElements]: {
		id: 'ui.planBenefits.enterprise.customElements',
	},
	[Benefit.CustomMonitoringLocation]: {
		id: 'ui.planBenefits.enterprise.customMonitoringLocation',
	},
	[Benefit.CWVMonitoringAndJSRendering]: {
		id: 'ui.planBenefits.enterprise.CWVMonitoringAndJSRendering',
	},
	[Benefit.DataRetention]: {
		id: 'ui.planBenefits.enterprise.dataRetention',
	},
	[Benefit.DataStudioAndAPIs]: {
		id: 'ui.planBenefits.enterprise.dataStudioAndAPIs',
	},
	[Benefit.DedicatedSuccessManager]: {
		id: 'ui.planBenefits.enterprise.dedicatedSuccessManager',
	},
	[Benefit.InvoiceBilling]: {
		id: 'ui.planBenefits.enterprise.invoiceBilling',
	},
	[Benefit.LogFileAnalysis]: {
		id: 'ui.planBenefits.enterprise.logFileAnalysis',
	},
	[Benefit.OnboardingAndCustomTraining]: {
		id: 'ui.planBenefits.enterprise.onboardingAndCustomTraining',
	},
	[Benefit.PrioritySupport]: {
		id: 'ui.planBenefits.enterprise.prioritySupport',
	},
	[Benefit.RealtimeAuditing]: {
		id: 'ui.planBenefits.enterprise.realtimeAuditing',
	},
	[Benefit.UnlimitedUsersRechecks]: {
		id: 'ui.planBenefits.enterprise.unlimitedUsersRechecks',
	},
	[Benefit.UnlimitedUsersWebsitesRechecks]: {
		id: 'ui.planBenefits.enterprise.unlimitedUsersWebsitesRechecks',
	},
});



const EnterprisePlanOverview: React.FC = () => {
	const accountId = useAccountId();
	const accountIsOutOfBand = useAccountIsOutOfBand(accountId) ?? true;

	const benefits = getEnterprisePlanBenefits({ accountIsOutOfBand });

	return (
		<PlanBox
			details={(
				<PlanDetails
					collapsePrice={true}
					ctaButton={(
						<SignupPlanComparisonButton
							dominant={true}
							plan={GraphQL.AccountPlan.Enterprise}
						/>
					)}
					name={(
						<AttachedEnterpriseRibbon>
							<PlanName plan={GraphQL.AccountPlan.Enterprise} />
						</AttachedEnterpriseRibbon>
					)}
					planNameSize={PlanDetailsPlanNameSize.Large}
					priceOverview={accountId !== null && (
						<PlanPriceSummary
							accountId={accountId}
							disabledReason={undefined}
							plan={GraphQL.AccountPlan.Enterprise}
						/>
					)}
				/>
			)}
			isEnterprise={true}
		>
			<EnterpriseBenefits
				benefits={benefits.map((benefit) => {
					const label = (
						benefit.name in messages
							? <Copy {...messages[benefit.name]} />
							: <BenefitName benefit={benefit.name} />
					);

					return {
						iconType: benefit.iconType,
						label,
					};
				})}
			/>
		</PlanBox>
	);
};



export default EnterprisePlanOverview;
