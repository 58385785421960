import {
	get,
} from './api/API';



export function loadTeamBillingDetailsViaApi(teamId) {
	return get('/teams/' + teamId + '/billing_details');
}



export function loadViaApi() {
	return get('/teams', {}, {
		timeout: 30000,
	});
}
