import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import EmptyValue from '~/components/app/EmptyValue';
import MissingValue from '~/components/app/MissingValue';
import TextInspector from '~/components/patterns/typography/TextInspector';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import ValueUpdateComparison, {
	ValueUpdateComparisonHighlightType,
} from '~/components/patterns/values/ValueUpdateComparison';



const messages = defineMessages({
	emptyLabel: {
		id: 'ui.general.empty',
	},
	missingLabel: {
		id: 'ui.general.missing',
	},
});



type Props = {
	prevValue?: React.ReactNode,
	value: React.ReactNode,
};

const StringDiff: React.FC<Props> = (props) => {
	const {
		prevValue,
		value,
	} = props;

	const isValueMissing = value === null || value === undefined;
	const isPrevValueMissing = prevValue === null || prevValue === undefined;
	let valueElement = value;
	let valueElementPopup;
	let prevValueElement = prevValue;
	let prevValueElementPopup;

	if (isValueMissing) {
		valueElement = (
			<MissingValue ellipsis={true} />
		);

		valueElementPopup = (
			<FormattedMessage {...messages.missingLabel} />
		);
	}

	if (value === '') {
		valueElement = (
			<EmptyValue ellipsis={true} />
		);

		valueElementPopup = (
			<FormattedMessage {...messages.emptyLabel} />
		);
	}

	if (typeof valueElement === 'string') {
		valueElement = (
			<TextInspector text={valueElement} />
		);
	}

	if (isPrevValueMissing) {
		prevValueElement = (
			<MissingValue ellipsis={true} />
		);

		prevValueElementPopup = (
			<FormattedMessage {...messages.missingLabel} />
		);
	}

	if (prevValue === '') {
		prevValueElement = (
			<EmptyValue ellipsis={true} />
		);

		prevValueElementPopup = (
			<FormattedMessage {...messages.emptyLabel} />
		);
	}

	if (typeof prevValueElement === 'string') {
		prevValueElement = (
			<TextInspector text={prevValueElement} />
		);
	}

	let highlightType = ValueUpdateComparisonHighlightType.Changed;

	if (isValueMissing && !isPrevValueMissing) {
		highlightType = ValueUpdateComparisonHighlightType.Removed;
	}

	if (!isValueMissing && isPrevValueMissing) {
		highlightType = ValueUpdateComparisonHighlightType.Added;
	}

	return (
		<ValueUpdateComparison
			highlightType={highlightType}
			prevValue={(
				<Ellipsis
					altPopupText={prevValueElementPopup}
					popupZIndex={1500}
				>
					{prevValueElement}
				</Ellipsis>
			)}
			value={(
				<Ellipsis
					altPopupText={valueElementPopup}
					popupZIndex={1500}
				>
					{valueElement}
				</Ellipsis>
			)}
		/>
	);
};



export default StringDiff;
