import useWindowHasFocus from '~/hooks/useWindowHasFocus';



function usePollInterval(pollInterval: number): number {
	const windowHasFocus = useWindowHasFocus();

	return windowHasFocus
		? pollInterval
		: (pollInterval * 10);
}



export default usePollInterval;
