import React from 'react';

import HTMLWrapper from '~/components/atoms/HTMLWrapper';
import SegmentLabelThumbList, {
	SegmentLabelThumbListChange,
	SegmentLabelThumbListSize,
} from '~/components/logic/segments/SegmentLabelThumbList';

import {
	type SegmentDefinition,
} from '~/model/segments';



type Props = {
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	segmentNamesEntered: ReadonlyArray<string>,
	segmentNamesLeft: ReadonlyArray<string>,
	segmentNamesSame: ReadonlyArray<string>,
	width: number,
};

const SegmentsListsDiff: React.FC<Props> = (props) => {
	const {
		segmentDefinitions,
		segmentNamesEntered,
		segmentNamesLeft,
		segmentNamesSame,
		width,
	} = props;

	const segmentChangesEntered = Object.fromEntries(
		segmentNamesEntered.map(
			(segmentName) => [segmentName, SegmentLabelThumbListChange.Entered],
		),
	);

	const segmentChangesLeft = Object.fromEntries(
		segmentNamesLeft.map(
			(segmentName) => [segmentName, SegmentLabelThumbListChange.Left],
		),
	);

	return (
		<HTMLWrapper>
			<SegmentLabelThumbList
				segmentChanges={segmentChangesEntered}
				segmentDefinitions={segmentDefinitions}
				segmentNames={segmentNamesSame.concat(segmentNamesEntered)}
				size={SegmentLabelThumbListSize.Small}
				width={width}
			/>
			<SegmentLabelThumbList
				segmentChanges={segmentChangesLeft}
				segmentDefinitions={segmentDefinitions}
				segmentNames={segmentNamesSame.concat(segmentNamesLeft)}
				size={SegmentLabelThumbListSize.Small}
				width={width}
			/>
		</HTMLWrapper>
	);
};



export default SegmentsListsDiff;
