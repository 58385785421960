import React from 'react';

import AccordionWrapper from '~/components/atoms/forms/components/builders/AccordionWrapper';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import Font, {
	FontSize,
} from '~/components/patterns/typography/Font';
import PureRadioFields, {
	SIZE_SMALL as RADIO_FIELDS_SIZE_SMALL,
} from '~/components/atoms/forms/components/builders/PureRadioFields';



type Props = {
	isControlled?: boolean,
	name: string,
	onBlurCallback?: () => void,
	onChangeCallback?: (value: string) => void,
	onFocusCallback?: () => void,
	/** List of data for every option */
	options: Array<{
		content: React.ReactNode,
		disabled?: boolean,
		checked?: boolean,
		label: React.ReactNode,
		/** Possibility to hide radio element */
		showRadioElement?: boolean,
		value: string,
	}>,
};



const AccordionField: React.FC<Props> = (props) => {
	const {
		isControlled,
		name,
		onBlurCallback,
		onChangeCallback,
		onFocusCallback,
		options,
	} = props;

	return (
		<AccordionWrapper>
			<PureRadioFields
				isControlled={isControlled}
				itemRenderer={({ data, field, isChecked, index }) => {
					return (
						<BorderedBox
							animations={false}
							header={field}
							isCollapsed={!isChecked}
							isCollapsible={true}
							key={'accordion-card-' + index}
						>
							<Font size={FontSize.Small}>
								{data.content}
							</Font>
						</BorderedBox>
					);
				}}
				items={options}
				name={name}
				onBlurCallback={onBlurCallback}
				onChangeCallback={onChangeCallback}
				onFocusCallback={onFocusCallback}
				size={RADIO_FIELDS_SIZE_SMALL}
			/>
		</AccordionWrapper>
	);
};



export default AccordionField;
