/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCustomElementMutationVariables = GraphQL.Exact<{
  extraction: GraphQL.Scalars['Array']['input'];
  label: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CreateCustomElementMutation = { readonly __typename?: 'Mutation', readonly CreateCustomElement: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null, readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElement', readonly column: `custom_${string}`, readonly createdAt: CK.Timestamp, readonly dataType: GraphQL.CustomElementDataType, readonly extraction: Record<string, any>, readonly label: string, readonly name: string }> } | null } } };

export type EditCustomElementMutationVariables = GraphQL.Exact<{
  extraction: GraphQL.Scalars['Array']['input'];
  label: GraphQL.Scalars['String']['input'];
  name: GraphQL.Scalars['String']['input'];
  restartTracking: GraphQL.Scalars['Boolean']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type EditCustomElementMutation = { readonly __typename?: 'Mutation', readonly EditCustomElement: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null, readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElement', readonly column: `custom_${string}`, readonly createdAt: CK.Timestamp, readonly dataType: GraphQL.CustomElementDataType, readonly extraction: Record<string, any>, readonly label: string, readonly name: string }> } | null } } };


export const CreateCustomElementDocument = gql`
    mutation CreateCustomElement($extraction: Array!, $label: String!, $websiteId: WebsiteId!) {
  CreateCustomElement(
    extraction: $extraction
    label: $label
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        account {
          features {
            feature
            id
            inUse
          }
          id
        }
        customElements {
          column
          createdAt
          dataType
          extraction
          label
          name
        }
        id
      }
    }
  }
}
    `;
export type CreateCustomElementMutationFn = Apollo.MutationFunction<CreateCustomElementMutation, CreateCustomElementMutationVariables>;

/**
 * __useCreateCustomElementMutation__
 *
 * To run a mutation, you first call `useCreateCustomElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomElementMutation, { data, loading, error }] = useCreateCustomElementMutation({
 *   variables: {
 *      extraction: // value for 'extraction'
 *      label: // value for 'label'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCreateCustomElementMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomElementMutation, CreateCustomElementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomElementMutation, CreateCustomElementMutationVariables>(CreateCustomElementDocument, options);
      }
export type CreateCustomElementMutationHookResult = ReturnType<typeof useCreateCustomElementMutation>;
export type CreateCustomElementMutationResult = Apollo.MutationResult<CreateCustomElementMutation>;
export type CreateCustomElementMutationOptions = Apollo.BaseMutationOptions<CreateCustomElementMutation, CreateCustomElementMutationVariables>;
export const EditCustomElementDocument = gql`
    mutation EditCustomElement($extraction: Array!, $label: String!, $name: String!, $restartTracking: Boolean!, $websiteId: WebsiteId!) {
  EditCustomElement(
    extraction: $extraction
    label: $label
    name: $name
    restartTracking: $restartTracking
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        account {
          features {
            feature
            id
            inUse
          }
          id
        }
        customElements {
          column
          createdAt
          dataType
          extraction
          label
          name
        }
        id
      }
    }
  }
}
    `;
export type EditCustomElementMutationFn = Apollo.MutationFunction<EditCustomElementMutation, EditCustomElementMutationVariables>;

/**
 * __useEditCustomElementMutation__
 *
 * To run a mutation, you first call `useEditCustomElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomElementMutation, { data, loading, error }] = useEditCustomElementMutation({
 *   variables: {
 *      extraction: // value for 'extraction'
 *      label: // value for 'label'
 *      name: // value for 'name'
 *      restartTracking: // value for 'restartTracking'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useEditCustomElementMutation(baseOptions?: Apollo.MutationHookOptions<EditCustomElementMutation, EditCustomElementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCustomElementMutation, EditCustomElementMutationVariables>(EditCustomElementDocument, options);
      }
export type EditCustomElementMutationHookResult = ReturnType<typeof useEditCustomElementMutation>;
export type EditCustomElementMutationResult = Apollo.MutationResult<EditCustomElementMutation>;
export type EditCustomElementMutationOptions = Apollo.BaseMutationOptions<EditCustomElementMutation, EditCustomElementMutationVariables>;