/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserHiddenDashboardScopesMutationVariables = GraphQL.Exact<{
  email: GraphQL.Scalars['String']['input'];
  hiddenDashboardScopes: ReadonlyArray<GraphQL.Scalars['Array']['input']> | GraphQL.Scalars['Array']['input'];
}>;


export type UpdateUserHiddenDashboardScopesMutation = { readonly __typename?: 'Mutation', readonly UpdateUserHiddenDashboardScopes: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly personalTweaks: { readonly __typename?: 'UserPersonalTweaks', readonly hiddenDashboardScopes: ReadonlyArray<Record<string, any>> } | null } } | null } } };


export const UpdateUserHiddenDashboardScopesDocument = gql`
    mutation UpdateUserHiddenDashboardScopes($email: String!, $hiddenDashboardScopes: [Array!]!) {
  UpdateUserHiddenDashboardScopes(
    email: $email
    hiddenDashboardScopes: $hiddenDashboardScopes
  ) {
    query {
      authenticatedSession {
        user {
          id
          legacyId
          personalTweaks {
            hiddenDashboardScopes
          }
        }
      }
    }
  }
}
    `;
export type UpdateUserHiddenDashboardScopesMutationFn = Apollo.MutationFunction<UpdateUserHiddenDashboardScopesMutation, UpdateUserHiddenDashboardScopesMutationVariables>;

/**
 * __useUpdateUserHiddenDashboardScopesMutation__
 *
 * To run a mutation, you first call `useUpdateUserHiddenDashboardScopesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserHiddenDashboardScopesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserHiddenDashboardScopesMutation, { data, loading, error }] = useUpdateUserHiddenDashboardScopesMutation({
 *   variables: {
 *      email: // value for 'email'
 *      hiddenDashboardScopes: // value for 'hiddenDashboardScopes'
 *   },
 * });
 */
export function useUpdateUserHiddenDashboardScopesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserHiddenDashboardScopesMutation, UpdateUserHiddenDashboardScopesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserHiddenDashboardScopesMutation, UpdateUserHiddenDashboardScopesMutationVariables>(UpdateUserHiddenDashboardScopesDocument, options);
      }
export type UpdateUserHiddenDashboardScopesMutationHookResult = ReturnType<typeof useUpdateUserHiddenDashboardScopesMutation>;
export type UpdateUserHiddenDashboardScopesMutationResult = Apollo.MutationResult<UpdateUserHiddenDashboardScopesMutation>;
export type UpdateUserHiddenDashboardScopesMutationOptions = Apollo.BaseMutationOptions<UpdateUserHiddenDashboardScopesMutation, UpdateUserHiddenDashboardScopesMutationVariables>;