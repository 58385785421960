import classNames from 'classnames';
import React from 'react';

import styles from './CodeValue.module.scss';



export enum CodeValueColorStyle {
	Dark = 'dark',
	Light = 'light',
}

export enum CodeValueHtmlHighlightType {
	Comment = 'html-comment',
}

export enum CodeValueRobotsTxtHighlightType {
	Comment = 'robots-comment',
	CrawlDelay = 'crawl-delay',
	Sitemap = 'sitemap',
	UserAgent = 'user-agent',
}

export enum CodeValueWrapping {
	Anywhere = 'anywhere',
	None = 'none',
	Word = 'word',
}

type Props = {
	/** Enable bold style */
	bold?: boolean,
	children?: React.ReactNode,
	/** Color style of font */
	colorStyle?: CodeValueColorStyle,
	/** Specific syntax highlight */
	highlightType?: CodeValueHtmlHighlightType | CodeValueRobotsTxtHighlightType,
	/** Possibility to disable text wrapping */
	wrapping?: CodeValueWrapping,
};



const HIGHLIGHTS = {
	[CodeValueHtmlHighlightType.Comment]: {
		color: '#8595a6',
	},
	[CodeValueRobotsTxtHighlightType.Comment]: {
		color: '#8595a6',
	},
	[CodeValueRobotsTxtHighlightType.CrawlDelay]: {
		color: '#c484d4',
	},
	[CodeValueRobotsTxtHighlightType.Sitemap]: {
		color: '#ad8e00',
	},
	[CodeValueRobotsTxtHighlightType.UserAgent]: {
		color: '#2768a8',
	},
};



const CodeValue: React.FC<Props> = (props) => {
	const {
		bold,
		colorStyle = CodeValueColorStyle.Dark,
		children,
		highlightType,
		wrapping = CodeValueWrapping.Word,
	} = props;

	const _getHighlightStyle = () => {
		return highlightType ? HIGHLIGHTS[highlightType] : {};
	};

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isBold]: bold,
		[styles[`wrapping-${wrapping}`] ?? '']: true,
		[styles[`style-${colorStyle}`] ?? '']: true,
	});

	return (
		<span
			className={componentClasses}
			style={_getHighlightStyle()}
		>
			{children}
		</span>
	);
};



export default CodeValue;
