import {
	Map,
} from 'immutable';
import memoizee from 'memoizee';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BillingCycleField from '../../../logic/formFields/BillingCycleField';
import BillingCycleName from '../../../names/BillingCycleName';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import ChangeSummary, {
	PAGES_DIFF_BUNDLE,
} from '~/components/logic/pricing/ChangeSummary';
import DisplayPart from '../basis/DisplayPart';
import EditableFormWrapper from '../basis/EditableFormWrapper';
import EditablePart from '../basis/EditablePart';
import EndUserPlanName from '../../../names/EndUserPlanName';
import Form from '../basis/Form';
import FormRow from '../basis/FormRow';
import FormRows from '../basis/FormRows';
import PaymentAuthorizationContext from '../../../logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '../../../logic/PaymentAuthorizationError';
import PaymentCancelButton from '../../../logic/PaymentCancelButton';
import PaymentSubmitButton from '../../../logic/PaymentSubmitButton';
import StaticText from '../components/StaticText';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	updateEndUser,
} from '~/actions/teams';

import {
	createPurchase,
} from '~/model/pricing/costs';

import {
	DETAIL_END_USER_PLAN,
	DETAIL_END_USER_PLAN_WEBSITES,
	DETAIL_END_USER_PLAN_WEBSITES_PAGES,
} from '~/model/pricing/endUser';



const messages = defineMessages({
	billingCycleLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.billingCycle',
	},
	contactBoxTitle: {
		id: 'ui.billing.needHelpPickingPlan.header',
	},
	formErrors: {
		blank: {
			id: 'ui.formErrors.blank',
		},
	},
	plan: {
		id: 'ui.foo',
		defaultMessage: 'Plan',
	},
	saveButton: {
		id: 'ui.teamDetail.billing.package.confirmationButton',
	},
	title: {
		id: 'ui.teamDetail.subscriptionForm.header',
	},
});



class EndUserPackageForm extends Component {

	constructor(props, context) {
		super(props, context);

		this._calculatePrice = memoizee(this._calculatePrice.bind(this), {
			length: 5,
		});

		this._handleSubmit = this._handleSubmit.bind(this);
	}



	_calculatePrice(team, billingCycle, endUserPlan, tariff, teamBilling) {
		const discounts = teamBilling.get('discounts').filter((discount) => discount.get('type') !== 'dollars');
		const taxRate = teamBilling.get('tax');

		return tariff.calculatePurchaseCostDetails({
			billingCycle,
			discounts,
			purchases: [
				createPurchase({
					details: {
						[DETAIL_END_USER_PLAN]: endUserPlan,
						[DETAIL_END_USER_PLAN_WEBSITES]: team.get('websites').map((website) => {
							return Map({
								[DETAIL_END_USER_PLAN_WEBSITES_PAGES]: website.get('page_capacity'),
							});
						}).toList(),
					},
					numberOfPages: 0,
				}),
			],
			taxRate,
		});
	}



	_handleSubmit({ threeDSecureToken, values }) {
		const {
			dispatch,
			team,
		} = this.props;

		return dispatch(
			updateEndUser(
				team.get('id'),
				team.get('end_user_plan'),
				values.billing_cycle,
				threeDSecureToken,
			),
		);
	}



	_renderOverview() {
		const {
			team,
		} = this.props;

		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.billingCycleLabel} />
					)}
				>
					<StaticText>
						<BillingCycleName billingCycle={team.get('billing_cycle')} />
					</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.plan} />
					)}
				>
					<StaticText>
						<EndUserPlanName
							plan={team.get('end_user_plan')}
						/>
					</StaticText>
				</FormRow>
			</FormRows>
		);
	}



	_renderChangesOverview(values, oldValues) {
		const {
			tariff,
			team,
			teamBilling,
		} = this.props;

		if (
			!teamBilling.get('details')
			|| teamBilling.get('details').size === 0
			|| !teamBilling.has('discounts')
			|| !teamBilling.has('tax')
		) {
			return false;
		}

		if (!values.billing_cycle || (oldValues.billing_cycle === values.billing_cycle)) {
			return false;
		}

		const priceOld = this._calculatePrice(
			team,
			oldValues.billing_cycle,
			team.get('end_user_plan'),
			tariff,
			teamBilling,
		);

		const priceNew = this._calculatePrice(
			team,
			values.billing_cycle || oldValues.billing_cycle,
			team.get('end_user_plan'),
			tariff,
			teamBilling,
		);

		if (!priceNew || !priceOld) {
			return false;
		}

		return (
			<ChangeSummary
				accountId={team.get('id')}
				allowedBillingCycles={team.get('allowed_billing_cycles')}
				billingCycleNew={values.billing_cycle || oldValues.billing_cycle}
				billingCycleOld={oldValues.billing_cycle}
				currency={team.get('currency')}
				hidePrice={false}
				pagesDiff={PAGES_DIFF_BUNDLE}
				pagesNew={values.page_bundle}
				pagesOld={oldValues.page_bundle}
				priceNew={priceNew.subtotal}
				priceOld={priceOld.subtotal}
				taxType={priceNew.tax > 0 ? 'Vat' : null}
			/>
		);
	}



	_renderForm() {
		const {
			team,
		} = this.props;

		const defaultValues = {
			billing_cycle: team.get('billing_cycle'),
		};

		return (
			<PaymentAuthorizationContext
				onSubmit={this._handleSubmit}
				useModal={true}
			>
				{({ formRef, handleFormSuccess }) => (
					<Form
						defaultValues={defaultValues}
						onSuccess={handleFormSuccess}
						ref={formRef}
					>
						{({ values }) => {
							return (
								<>
									<FormRows>
										{team.get('allowed_billing_cycles').size > 1 && (
											<FormRow
												htmlFor="billing_cycle"
												label={(
													<FormattedMessage {...messages.billingCycleLabel} />
												)}
											>
												<BillingCycleField
													allowedBillingCycles={team.get('allowed_billing_cycles').toArray()}
													name="billing_cycle"
												/>
											</FormRow>
										)}

										<FormRow
											label={(
												<FormattedMessage {...messages.plan} />
											)}
										>
											<StaticText>
												<EndUserPlanName
													plan={team.get('end_user_plan')}
												/>
											</StaticText>
										</FormRow>

										{this._renderChangesOverview(values, defaultValues)}
									</FormRows>

									<PaymentAuthorizationError />

									<ButtonsLayout>
										<PaymentCancelButton />

										<PaymentSubmitButton>
											<FormattedMessage {...messages.saveButton} />
										</PaymentSubmitButton>
									</ButtonsLayout>
								</>
							);
						}}
					</Form>
				)}
			</PaymentAuthorizationContext>
		);
	}



	render() {
		const {
			team,
			teamBilling,
		} = this.props;

		if (team.get('is_billable') && !teamBilling) {
			return false;
		}

		return (
			<WhenAccountActionAllowed
				accountId={team.get('id')}
				action={GraphQL.ActionWithAccount.ManageDetails}
				showMessage={false}
			>
				{({ isAllowed }) => (
					<EditableFormWrapper
						isAllowed={isAllowed}
						key={team.get('id')}
						title={(
							<FormattedMessage {...messages.title} />
						)}
					>
						<DisplayPart>
							{this._renderOverview()}
						</DisplayPart>

						<EditablePart>
							{this._renderForm()}
						</EditablePart>
					</EditableFormWrapper>
				)}
			</WhenAccountActionAllowed>
		);
	}

}

EndUserPackageForm.propTypes = {
	team: PropTypes.object.isRequired,
	teamBilling: PropTypes.object.isRequired,
};



export default EndUserPackageForm;
