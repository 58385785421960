import {
	addMilliseconds,
	differenceInMilliseconds,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';

import {
	usePageIsPrioritizedQuery,
	usePrioritizePageMutation,
} from './usePrioritizePage.gql';

import usePageLastCheckedAt from '~/hooks/usePageLastCheckedAt';
import useWindowHasFocus from '~/hooks/useWindowHasFocus';



function usePrioritizePage(
	legacyUrlId: number | null,
	websiteId: CK.WebsiteId,
): void {
	const now = React.useMemo(
		() => new Date(),
		[],
	);

	const lastCheckedAt = usePageLastCheckedAt(legacyUrlId, websiteId) ?? now;
	const windowHasFocus = useWindowHasFocus();

	const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

	const { data } = usePageIsPrioritizedQuery({
		skip: legacyUrlId === null,
		variables: {
			legacyUrlId: legacyUrlId ?? 0,
			websiteId,
		},
	});

	const isPrioritized = data?.lookupPageByLegacyId?.isPrioritized ?? null;

	const [prioritizePage] = usePrioritizePageMutation();

	React.useEffect(
		() => {
			if (
				isPrioritized !== false
				|| legacyUrlId === null
				|| windowHasFocus === false
			) {
				return;
			}

			const shouldPrioritizeAt = addMilliseconds(lastCheckedAt, 10000);
			const waitForPrioritization = Math.max(differenceInMilliseconds(shouldPrioritizeAt, new Date()), 0);

			timeoutRef.current = setTimeout(
				() => prioritizePage({
					variables: {
						legacyUrlId,
						websiteId,
					},
				}),
				waitForPrioritization,
			);

			return () => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
			};
		},
		[
			isPrioritized,
			lastCheckedAt,
			legacyUrlId,
			prioritizePage,
			websiteId,
			windowHasFocus,
		],
	);
}



export default usePrioritizePage;
