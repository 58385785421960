import {
	differenceInDays,
	differenceInMilliseconds,
	milliseconds,
	startOfDay,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	isFunction,
	isString,
} from '~/utilities/typeCheck';



export enum PageLastCheckedMessageType {
	Generic = 'generic',
	Page = 'page',
	RobotsTxt = 'robotsTxt',
	Sitemap = 'sitemap',
}



const messages = {
	[PageLastCheckedMessageType.Generic]: defineMessages({
		pastSeconds: {
			id: 'ui.pageLastCheckedMessage.generic.pastSeconds',
		},
		pastMinutes: {
			id: 'ui.pageLastCheckedMessage.generic.pastMinutes',
		},
		pastHours: {
			id: 'ui.pageLastCheckedMessage.generic.pastHours',
		},
		pastDays: {
			id: 'ui.pageLastCheckedMessage.generic.pastDays',
		},
		pastWeeks: {
			id: 'ui.pageLastCheckedMessage.generic.pastWeeks',
		},
		pastMonths: {
			id: 'ui.pageLastCheckedMessage.generic.pastMonths',
		},
	}),
	[PageLastCheckedMessageType.Page]: defineMessages({
		pastSeconds: {
			id: 'ui.pageLastCheckedMessage.page.pastSeconds',
		},
		pastMinutes: {
			id: 'ui.pageLastCheckedMessage.page.pastMinutes',
		},
		pastHours: {
			id: 'ui.pageLastCheckedMessage.page.pastHours',
		},
		pastDays: {
			id: 'ui.pageLastCheckedMessage.page.pastDays',
		},
		pastWeeks: {
			id: 'ui.pageLastCheckedMessage.page.pastWeeks',
		},
		pastMonths: {
			id: 'ui.pageLastCheckedMessage.page.pastMonths',
		},
	}),
	[PageLastCheckedMessageType.RobotsTxt]: defineMessages({
		pastSeconds: {
			id: 'ui.pageLastCheckedMessage.robotsTxt.pastSeconds',
		},
		pastMinutes: {
			id: 'ui.pageLastCheckedMessage.robotsTxt.pastMinutes',
		},
		pastHours: {
			id: 'ui.pageLastCheckedMessage.robotsTxt.pastHours',
		},
		pastDays: {
			id: 'ui.pageLastCheckedMessage.robotsTxt.pastDays',
		},
		pastWeeks: {
			id: 'ui.pageLastCheckedMessage.robotsTxt.pastWeeks',
		},
		pastMonths: {
			id: 'ui.pageLastCheckedMessage.robotsTxt.pastMonths',
		},
	}),
	[PageLastCheckedMessageType.Sitemap]: defineMessages({
		pastSeconds: {
			id: 'ui.pageLastCheckedMessage.sitemap.pastSeconds',
		},
		pastMinutes: {
			id: 'ui.pageLastCheckedMessage.sitemap.pastMinutes',
		},
		pastHours: {
			id: 'ui.pageLastCheckedMessage.sitemap.pastHours',
		},
		pastDays: {
			id: 'ui.pageLastCheckedMessage.sitemap.pastDays',
		},
		pastWeeks: {
			id: 'ui.pageLastCheckedMessage.sitemap.pastWeeks',
		},
		pastMonths: {
			id: 'ui.pageLastCheckedMessage.sitemap.pastMonths',
		},
	}),
};



const breakpoints = [
	{
		duration: milliseconds({ seconds: 20 }),
		representation: milliseconds({ seconds: 10 }),
	},
	{
		duration: milliseconds({ seconds: 40 }),
		representation: milliseconds({ seconds: 20 }),
	},
	{
		duration: milliseconds({ minutes: 1 }),
		representation: milliseconds({ seconds: 30 }),
	},
	{
		duration: milliseconds({ minutes: 2 }),
		representation: milliseconds({ minutes: 1 }),
	},
	{
		duration: milliseconds({ hours: 1 }),
		representation: (duration: number) => duration - milliseconds({ minutes: 1 }),
	},
	{
		duration: milliseconds({ hours: 2 }),
		representation: milliseconds({ hours: 1 }),
	},
	{
		duration: milliseconds({ days: 1 }),
		representation: (duration: number) => duration - milliseconds({ hours: 1 }),
	},
	{
		duration: milliseconds({ days: 2 }),
		representation: milliseconds({ days: 1 }),
	},
	{
		duration: milliseconds({ weeks: 1 }),
		representation: (duration: number) => duration,
	},
	{
		duration: milliseconds({ weeks: 2 }),
		representation: milliseconds({ weeks: 1 }),
	},
	{
		duration: milliseconds({ weeks: 5 }),
		representation: (duration: number) => duration - milliseconds({ weeks: 1 }),
	},
	{
		duration: milliseconds({ weeks: 11 }),
		representation: milliseconds({ months: 1 }),
	},
	{
		duration: milliseconds({ years: 999 }),
		representation: (duration: number) => duration - milliseconds({ months: 1 }),
	},
];



type Formatter = {
	duration: number,
	formatter: (duration: number, messageType: PageLastCheckedMessageType) => React.ReactElement,
};

const formatters: Array<Formatter> = [
	{
		duration: milliseconds({ minutes: 1 }),
		formatter: (duration, messageType) => (
			<FormattedMessage
				{...messages[messageType].pastSeconds}
				values={{
					seconds: Math.floor(duration / 1000),
				}}
			/>
		),
	},
	{
		duration: milliseconds({ hours: 1 }),
		formatter: (duration, messageType) => (
			<FormattedMessage
				{...messages[messageType].pastMinutes}
				values={{
					minutes: Math.floor(duration / 1000 / 60),
				}}
			/>
		),
	},
	{
		duration: milliseconds({ days: 1 }),
		formatter: (duration, messageType) => (
			<FormattedMessage
				{...messages[messageType].pastHours}
				values={{
					hours: Math.floor(duration / 1000 / 60 / 60),
				}}
			/>
		),
	},
	{
		duration: milliseconds({ weeks: 1 }),
		formatter: (duration, messageType) => (
			<FormattedMessage
				{...messages[messageType].pastDays}
				values={{
					days: Math.floor(duration / 1000 / 60 / 60 / 24),
				}}
			/>
		),
	},
	{
		duration: milliseconds({ months: 1 }),
		formatter: (duration, messageType) => (
			<FormattedMessage
				{...messages[messageType].pastWeeks}
				values={{
					weeks: Math.floor(duration / 1000 / 60 / 60 / 24 / 7),
				}}
			/>
		),
	},
	{
		duration: milliseconds({ years: 999 }),
		formatter: (duration, messageType) => (
			<FormattedMessage
				{...messages[messageType].pastMonths}
				values={{
					months: Math.floor(duration / 1000 / 60 / 60 / 24 / 30.436875),
				}}
			/>
		),
	},
];



type Props = {
	date: Date | string,
	messageType?: PageLastCheckedMessageType,
};

const PageLastCheckedMessage: React.FC<Props> = (props) => {
	const {
		date,
		messageType = PageLastCheckedMessageType.Generic,
	} = props;


	const now = new Date();
	const past = isString(date)
		? new Date(date)
		: date;

	let duration = differenceInMilliseconds(now, past);

	if (differenceInDays(now, past) >= 1) {
		duration = differenceInMilliseconds(startOfDay(now), startOfDay(past));
	}

	const breakpoint = breakpoints.find(
		(breakpoint) => (
			duration < breakpoint.duration
		),
	);

	if (!breakpoint) {
		return null;
	}

	const representation = isFunction(breakpoint.representation)
		? breakpoint.representation(duration)
		: breakpoint.representation;

	const formatter = formatters.find(
		(formatter) => {
			return (representation < formatter.duration);
		},
	);

	if (!formatter) {
		return null;
	}

	return formatter.formatter(representation, messageType);
};



export default PageLastCheckedMessage;
