/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddCustomerWebsitesInBulkMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  acceptedLegalDocuments: ReadonlyArray<GraphQL.LegalDocumentType> | GraphQL.LegalDocumentType;
  domains: ReadonlyArray<GraphQL.DomainAddedInBulk> | GraphQL.DomainAddedInBulk;
  pageBundle: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  sessionId: GraphQL.Scalars['String']['input'];
  threeDSecureToken: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
}>;


export type AddCustomerWebsitesInBulkMutation = { readonly __typename?: 'Mutation', readonly AddCustomerWebsitesInBulk: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly id: CK.InvitationId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly domain: string, readonly id: CK.WebsiteId, readonly displayName: string, readonly pageCapacity: number, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithWebsite> }> } | null } } };

export type AddTrialWebsitesInBulkMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  acceptedLegalDocuments: ReadonlyArray<GraphQL.LegalDocumentType> | GraphQL.LegalDocumentType;
  domains: ReadonlyArray<GraphQL.DomainAddedInBulk> | GraphQL.DomainAddedInBulk;
  sessionId: GraphQL.Scalars['String']['input'];
}>;


export type AddTrialWebsitesInBulkMutation = { readonly __typename?: 'Mutation', readonly AddTrialWebsitesInBulk: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly id: CK.InvitationId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly domain: string, readonly id: CK.WebsiteId, readonly displayName: string, readonly pageCapacity: number, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithWebsite> }> } | null } } };


export const AddCustomerWebsitesInBulkDocument = gql`
    mutation AddCustomerWebsitesInBulk($accountId: AccountId!, $acceptedLegalDocuments: [LegalDocumentType!]!, $domains: [DomainAddedInBulk!]!, $pageBundle: Int, $sessionId: String!, $threeDSecureToken: String) {
  AddCustomerWebsitesInBulk(
    accountId: $accountId
    acceptedLegalDocuments: $acceptedLegalDocuments
    domains: $domains
    pageBundle: $pageBundle
    sessionId: $sessionId
    threeDSecureToken: $threeDSecureToken
  ) {
    query {
      account(id: $accountId) {
        id
        invitations {
          id
          websites {
            id
          }
        }
        memberships {
          account {
            id
          }
          user {
            id
            legacyId
          }
          websites {
            id
          }
        }
        websites {
          domain
          id
          displayName
          pageCapacity
          permittedActions
        }
      }
    }
  }
}
    `;
export type AddCustomerWebsitesInBulkMutationFn = Apollo.MutationFunction<AddCustomerWebsitesInBulkMutation, AddCustomerWebsitesInBulkMutationVariables>;

/**
 * __useAddCustomerWebsitesInBulkMutation__
 *
 * To run a mutation, you first call `useAddCustomerWebsitesInBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerWebsitesInBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerWebsitesInBulkMutation, { data, loading, error }] = useAddCustomerWebsitesInBulkMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      acceptedLegalDocuments: // value for 'acceptedLegalDocuments'
 *      domains: // value for 'domains'
 *      pageBundle: // value for 'pageBundle'
 *      sessionId: // value for 'sessionId'
 *      threeDSecureToken: // value for 'threeDSecureToken'
 *   },
 * });
 */
export function useAddCustomerWebsitesInBulkMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomerWebsitesInBulkMutation, AddCustomerWebsitesInBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomerWebsitesInBulkMutation, AddCustomerWebsitesInBulkMutationVariables>(AddCustomerWebsitesInBulkDocument, options);
      }
export type AddCustomerWebsitesInBulkMutationHookResult = ReturnType<typeof useAddCustomerWebsitesInBulkMutation>;
export type AddCustomerWebsitesInBulkMutationResult = Apollo.MutationResult<AddCustomerWebsitesInBulkMutation>;
export type AddCustomerWebsitesInBulkMutationOptions = Apollo.BaseMutationOptions<AddCustomerWebsitesInBulkMutation, AddCustomerWebsitesInBulkMutationVariables>;
export const AddTrialWebsitesInBulkDocument = gql`
    mutation AddTrialWebsitesInBulk($accountId: AccountId!, $acceptedLegalDocuments: [LegalDocumentType!]!, $domains: [DomainAddedInBulk!]!, $sessionId: String!) {
  AddTrialWebsitesInBulk(
    accountId: $accountId
    acceptedLegalDocuments: $acceptedLegalDocuments
    domains: $domains
    sessionId: $sessionId
  ) {
    query {
      account(id: $accountId) {
        id
        invitations {
          id
          websites {
            id
          }
        }
        memberships {
          account {
            id
          }
          user {
            id
            legacyId
          }
          websites {
            id
          }
        }
        websites {
          domain
          id
          displayName
          pageCapacity
          permittedActions
        }
      }
    }
  }
}
    `;
export type AddTrialWebsitesInBulkMutationFn = Apollo.MutationFunction<AddTrialWebsitesInBulkMutation, AddTrialWebsitesInBulkMutationVariables>;

/**
 * __useAddTrialWebsitesInBulkMutation__
 *
 * To run a mutation, you first call `useAddTrialWebsitesInBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrialWebsitesInBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrialWebsitesInBulkMutation, { data, loading, error }] = useAddTrialWebsitesInBulkMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      acceptedLegalDocuments: // value for 'acceptedLegalDocuments'
 *      domains: // value for 'domains'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAddTrialWebsitesInBulkMutation(baseOptions?: Apollo.MutationHookOptions<AddTrialWebsitesInBulkMutation, AddTrialWebsitesInBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrialWebsitesInBulkMutation, AddTrialWebsitesInBulkMutationVariables>(AddTrialWebsitesInBulkDocument, options);
      }
export type AddTrialWebsitesInBulkMutationHookResult = ReturnType<typeof useAddTrialWebsitesInBulkMutation>;
export type AddTrialWebsitesInBulkMutationResult = Apollo.MutationResult<AddTrialWebsitesInBulkMutation>;
export type AddTrialWebsitesInBulkMutationOptions = Apollo.BaseMutationOptions<AddTrialWebsitesInBulkMutation, AddTrialWebsitesInBulkMutationVariables>;