import {
	useSelector,
} from 'react-redux';

import {
	selectedPageIdSelector,
} from '~/state/ui/selectors';



function useLegacyUrlId(): number {
	const legacyUrlId = useSelector(selectedPageIdSelector);

	return parseInt(legacyUrlId, 10);
}



export default useLegacyUrlId;
