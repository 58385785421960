import React from 'react';
import {
	usePopper,
} from 'react-popper';

import DepthLayer from '~/components/patterns/utils/DepthLayer';



type Props = React.PropsWithChildren<{
	/** Reference element defining area to which is panel attached */
	children: React.ReactNode,
	/** Flag for visibility of attached panel */
	isPanelRevealed?: boolean,
	onMouseEnter?: (e: React.MouseEvent) => void,
	onMouseLeave?: (e: React.MouseEvent) => void,
	/** Attached panel */
	panel?: React.ReactNode,
	/** Custom panel width */
	panelWidth?: React.CSSProperties['width'],
}>;



const AttachedOptionsPanel: React.FC<Props> = (props) => {
	const {
		children,
		isPanelRevealed,
		onMouseEnter,
		onMouseLeave,
		panel,
		panelWidth,
	} = props;

	const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);

	const {
		styles,
		attributes,
	} = usePopper(
		referenceElement,
		popperElement,
		{
			placement: 'right-start',
			modifiers: [
				{
					name: 'flip',
					options: {
						altBoundary: true,
					},
				},
				{
					name: 'offset',
					options: {
						offset: [0, 1],
					},
				},
				{
					name: 'preventOverflow',
					options: {
						mainAxis: false,
					},
				},
			],
		},
	);

	return (
		<DepthLayer>
			{({ depthLayer }) => {
				return (
					<>
						<div
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							ref={setReferenceElement}
						>
							{children}
						</div>
						{isPanelRevealed && (
							<div
								className="field-dropdown-attached-panel"
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								ref={setPopperElement}
								style={{
									zIndex: depthLayer,
									width: panelWidth,
									...styles.popper,
								}}
								{...attributes.popper}
							>
								{panel}
							</div>
						)}
					</>
				);
			}}
		</DepthLayer>
	);
};



export default AttachedOptionsPanel;
