import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DeleteUndoIconBuilder: IconBuilder = {
	icon: ({ color = '#FF5959' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g id="delete2">
					<path
						d="M8,8.99055 L17.25225,8.99055 L17.25225,18.74055 C17.25225,19.5693 16.581,20.24055 15.75225,20.24055 L8.25225,20.24055 C7.4235,20.24055 6.75225,19.5693 6.75225,18.74055 L6.75225,9.33333333 L8,8.99055 Z"
						id="Stroke-533"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M9.33333333,12 L9.33333333,16.4985"
						id="Stroke-534"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M12,12 L12,16.4985"
						id="Stroke-535"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M14.6666667,12 L14.6666667,16.4985"
						id="Stroke-536"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M17.4202667,7.65721667 L3.92026667,7.65721667 L3.92026667,6.47746667 C3.92026667,5.47246667 4.73551667,4.65721667 5.74051667,4.65721667 L15.6000167,4.65721667 C16.6050167,4.65721667 17.4202667,5.47246667 17.4202667,6.47746667 L17.4202667,7.65721667 L17.4202667,7.65721667 Z"
						id="Stroke-537"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						transform="translate(10.670267, 6.157217) rotate(-20.000000) translate(-10.670267, -6.157217) "
					>
					</path>
					<polygon
						id="Stroke-538"
						points="11.5860333 4.65811667 7.08603333 4.65811667 7.83678333 2.40811667 10.8352833 2.40811667"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						transform="translate(9.336033, 3.533117) rotate(-20.000000) translate(-9.336033, -3.533117) "
					>
					</polygon>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default DeleteUndoIconBuilder;

