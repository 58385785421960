import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import {
	removeHoveredCategory,
	storeHoveredCategory,
} from '~/actions';

import {
	type IssueCategoryName,
} from '~/model/issuesNew';



function useHighlightIssueCategoryHealthImpact(issueCategoryName: IssueCategoryName) {
	const dispatch = useDispatch();

	return React.useMemo(
		() => {
			return {
				start: () => {
					dispatch(
						storeHoveredCategory(issueCategoryName),
					);
				},
				stop: () => {
					dispatch(
						removeHoveredCategory(),
					);
				},
			};
		},
		[
			dispatch,
			issueCategoryName,
		],
	);
}



export default useHighlightIssueCategoryHealthImpact;
