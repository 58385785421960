import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ChartOverlay from '~/components/patterns/charts/components/ChartOverlay';



const messages = defineMessages({
	message: {
		id: 'ui.dashboard.noChanges.message',
	},
});



const NoChangesMessage: React.FC = () => {
	return (
		<ChartOverlay
			text={(
				<FormattedMessage {...messages.message} />
			)}
		/>
	);
};



export default NoChangesMessage;
