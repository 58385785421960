import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import MessageAnimation from '../../animations/MessageAnimation';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';



class ModalContainer extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			headerHeight: null,
		};
	}



	_renderFlashMessage() {
		const {
			flashMessage,
		} = this.props;

		return (
			<MessageAnimation
				active={flashMessage}
				className="modal-container__flash-message"
			>
				{flashMessage}
			</MessageAnimation>
		);
	}



	_calculateHeaderHeight() {
		const header = this._headerRef;

		if (header && this.state.headerHeight !== header.offsetHeight) {
			this.setState({
				headerHeight: header.offsetHeight,
			});
		}
	}



	componentDidMount() {
		this._calculateHeaderHeight();
	}



	componentDidUpdate() {
		this._calculateHeaderHeight();
	}



	render() {
		const {
			gapsSize,
			header,
			headerGapsSize,
			children,
			isLoading,
		} = this.props;

		const {
			headerHeight,
		} = this.state;

		const headerClasses = classNames({
			'modal-container__header': true,
			'modal-container__header--has-gaps': headerGapsSize ? headerGapsSize === 1 : gapsSize === 1,
			'modal-container__header--has-large-gaps': headerGapsSize ? headerGapsSize === 2 : gapsSize === 2,
		});

		const bodyClasses = classNames({
			'modal-container__body': true,
			'modal-container__body--is-loading': isLoading,
			'modal-container__body--has-gaps': gapsSize === 1,
			'modal-container__body--has-large-gaps': gapsSize === 2,
		});

		return (
			<div
				className="modal-container"
				style={{
					'--modal-header-height': headerHeight ? headerHeight + 'px' : false,
				}}
			>
				{header && (
					<div
						className={headerClasses}
						ref={(ref) => this._headerRef = ref}
					>
						{header}
					</div>
				)}

				<div className={bodyClasses}>
					{this._renderFlashMessage()}

					{isLoading ? (
						<LoadingDots />
					) : children}
				</div>
			</div>
		);
	}

}

ModalContainer.defaultProps = {
	gapsSize: 1,
};

ModalContainer.propTypes = {
	/** Possible flash message component */
	flashMessage: PropTypes.node,
	/** Definition of modal container gaps */
	gapsSize: PropTypes.oneOf([
		0,
		1,
		2,
	]).isRequired,
	/** Header component */
	header: PropTypes.node,
	/** Definition of gaps for modal header. Applied only when it's defined. Otherwise we use gapsSize value. */
	headerGapsSize: PropTypes.oneOf([
		0,
		1,
		2,
	]),
	/** Show preloader instead of content */
	isLoading: PropTypes.bool,
};



export default ModalContainer;
