import React from 'react';



function useExternalStylesheet(href: string) {
	React.useEffect(() => {
		// Find or create the LinkElement
		let link = document.querySelector<HTMLLinkElement>(`link[href="${href}"]`);

		if (!link) {
			link = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = href;

			document.body.appendChild(link);
		}
	}, [href]);
}



export default useExternalStylesheet;
