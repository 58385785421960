import {
	isSameDay,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ChartOverlay from '~/components/patterns/charts/components/ChartOverlay';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';

import {
	assertDictionaryId,
} from '~/localization/dictionaries';



const messages = defineMessages({
	dataGotTrackedLater: {
		id: 'ui.dashboard.noData.dataGotTrackedLater',
	},
	segmentCannotContainPages: {
		id: 'ui.dashboard.noData.segmentCannotContainPages',
	},
	segmentContainsNonPagesOnlyHistorical: {
		id: 'ui.dashboard.noData.segmentContainsNonPagesOnly.historical',
	},
	segmentContainsNonPagesOnlyLive: {
		id: 'ui.dashboard.noData.segmentContainsNonPagesOnly.live',
	},
	segmentDoesNotExist: {
		id: 'ui.dashboard.noData.segmentDoesNotExist',
	},
	segmentIsEmptyHistorical: {
		id: 'ui.dashboard.noData.segmentIsEmpty.historical',
	},
	segmentIsEmptyLive: {
		id: 'ui.dashboard.noData.segmentIsEmpty.live',
	},
	segmentWasAddedLater: {
		id: 'ui.dashboard.noData.segmentWasAddedLater',
	},
	websiteContainsNonPagesOnlyHistorical: {
		id: 'ui.dashboard.noData.websiteContainsNonPagesOnly.historical',
	},
	websiteContainsNonPagesOnlyLive: {
		id: 'ui.dashboard.noData.websiteContainsNonPagesOnly.live',
	},
	websiteNotFound: {
		id: 'ui.dashboard.noData.websiteNotFound',
	},
	websiteIsEmptyHistorical: {
		id: 'ui.dashboard.noData.websiteIsEmpty.historical',
	},
	websiteIsEmptyLive: {
		id: 'ui.dashboard.noData.websiteIsEmpty.live',
	},
	websiteWasAddedLater: {
		id: 'ui.dashboard.noData.websiteWasAddedLater',
	},
	webVitalsOriginSummaryDataNotDownloadedYet: {
		id: 'ui.dashboard.noData.webVitalsOriginSummaryDataNotDownloadedYet',
	},
	webVitalsOriginSummaryNotFound: {
		id: 'ui.dashboard.noData.webVitalsOriginSummaryNotFound',
	},
});



type Props = {
	reasonForNoData?: string | null,
};

const NoDataMessage: React.FC<Props> = (props) => {
	const {
		reasonForNoData,
	} = props;

	const dateRange = useDashboardDateRange();
	const isLive = isSameDay(dateRange.end, new Date());

	let messageId: string | undefined = undefined;

	if (reasonForNoData) {
		if (isLive) {
			messageId = messages[`${reasonForNoData}Live`]?.id;
		} else {
			messageId = messages[`${reasonForNoData}Historical`]?.id;
		}

		if (!messageId) {
			messageId = messages[reasonForNoData]?.id;
		}
	}

	let note: React.ReactChild | null = null;

	if (messageId !== undefined) {
		assertDictionaryId(messageId);

		note = (
			<FormattedMessage id={messageId} />
		);
	}

	return (
		<ChartOverlay
			subtext={note}
			text="No data to display"
		/>
	);
};



export default NoDataMessage;
