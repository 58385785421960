import {
	useUserOrganizationTeamQuery,
} from './useUserOrganizationTeam.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserOrganizationTeam(legacyUserId: string | null): string | null {
	const { data } = useUserPropertiesQuery(
		useUserOrganizationTeamQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.organizationTeam ?? null;
}



export default useUserOrganizationTeam;
