import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CobrandingUploadHint from '~/components/logic/interfaceHints/CobrandingUploadHint';
import Copy from '~/components/logic/Copy';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FileUploadField from '~/components/atoms/forms/components/FileUploadField';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useUpdateAccountProfileMutation,
} from './AccountSettingsForm.gql';

import useAccountLogoUrl from '~/hooks/useAccountLogoUrl';
import useAccountName from '~/hooks/useAccountName';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';



const messages = defineMessages({
	accountName: {
		id: 'ui.teamDetail.accountSettings.accountName',
	},
	customLogoNote: {
		id: 'ui.coBranding.customLogo.paragraph',
	},
	customLogoLabel: {
		id: 'ui.coBranding.customLogo.label',
	},
	formErrorsFileFormat: {
		id: 'ui.coBranding.customLogo.uploadError.fileFormat',
	},
	formErrorsFileSize: {
		id: 'ui.coBranding.customLogo.uploadError.fileSize',
	},
	logoNotUploaded: {
		id: 'ui.coBranding.customLogo.view.notUploaded',
	},
	logoUploaded: {
		id: 'ui.coBranding.customLogo.view.uploaded',
	},
	profileTitle: {
		id: 'ui.teamDetail.accountSettings.title',
	},
});

const validateName = validateField(
	'name',
	(f) => [
		f.validateNonEmpty(),
		f.validateMaximumLength(24),
	],
);



type Props = {
	accountId: CK.AccountId,
};

const AccountSettingsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountLogoUrl = useAccountLogoUrl(accountId);
	const accountName = useAccountName(accountId);
	const intl = useIntl();

	const [updateAccountProfile] = useUpdateAccountProfileMutation();

	const isAllowedToManageDetails = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageDetails,
	);

	const fileMessage = accountLogoUrl !== null
		? intl.formatMessage(messages.logoUploaded)
		: intl.formatMessage(messages.logoNotUploaded);

	const handleSubmit = React.useCallback(
		async (values) => {
			const payload: {
				name: string,
				removeCustomLogo: true | null,
				setCustomLogo: {
					fileContent: string,
					fileSize: number,
					fileType: string,
				} | null,
			} = {
				name: values.name,
				removeCustomLogo: null,
				setCustomLogo: null,
			};

			if (accountLogoUrl !== null && values.custom_logo.length < 1) {
				payload.removeCustomLogo = true;
			} else if (values.custom_logo !== fileMessage && values.custom_logo.file_content) {
				payload.setCustomLogo = {
					fileContent: values.custom_logo.file_content,
					fileSize: values.custom_logo.file_size,
					fileType: values.custom_logo.file_type,
				};
			}

			await updateAccountProfile({
				variables: {
					accountId,
					...payload,
				},
			});
		},
		[
			accountId,
			accountLogoUrl,
			fileMessage,
			updateAccountProfile,
		],
	);

	const validations = React.useMemo(
		() => {
			return {
				validateName,
				custom_logo: [
					{
						message: (
							<FormattedMessage {...messages.formErrorsFileFormat} />
						),
						field: 'custom_logo',
						rule: ({ values, name }) => {
							return (
								values[name].file_type === 'image/gif'
								|| values[name].file_type === 'image/jpeg'
								|| values[name].file_type === 'image/png'
								|| values[name].file_type === 'image/svg+xml'
								|| values[name].file_type === 'image/bmp'
								|| values[name] === fileMessage
								|| values[name] === ''
								|| values[name].length === 0
							);
						},
					},
					{
						message: (
							<FormattedMessage {...messages.formErrorsFileSize} />
						),
						field: 'custom_logo',
						rule: ({ values, name }) => {
							return !(values[name] !== fileMessage && values[name] !== '' && values[name].length !== 0 && values[name].file_size > 1000000);
						},
					},
				],
			};
		},
		[
			fileMessage,
		],
	);

	function renderOverview() {
		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.accountName} />
					)}
				>
					<StaticText focusTarget="name">
						{accountName}
					</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.customLogoLabel} />
					)}
				>
					<CobrandingUploadHint isTeamLogoActive={accountLogoUrl !== null}>
						<StaticText focusTarget="custom_logo">
							{fileMessage}
						</StaticText>
					</CobrandingUploadHint>
				</FormRow>
			</FormRows>
		);
	}

	function renderForm() {
		return (
			<Form
				defaultFocus="name"
				defaultValues={{
					name: accountName,
					custom_logo: accountLogoUrl !== null ? fileMessage : '',
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRows>
					<FormRow
						htmlFor="name"
						label={(
							<FormattedMessage {...messages.accountName} />
						)}
					>
						<FieldStatus name="validateName">
							<TextField
								name="name"
								trimValue={true}
							/>
						</FieldStatus>
					</FormRow>

					<FormRow
						description={(
							<Copy {...messages.customLogoNote} />
						)}
						htmlFor="custom_logo"
						label={(
							<FormattedMessage {...messages.customLogoLabel} />
						)}
					>
						<FieldStatus
							allowOk={false}
							name="custom_logo"
						>
							<FileUploadField
								accept="image/gif, image/jpg, image/jpeg, image/png"
								name="custom_logo"
							/>
						</FieldStatus>
					</FormRow>
				</FormRows>

				<ButtonsLayout>
					<CancelButton />
					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		);
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageDetails}
			key={accountId}
			title={(
				<FormattedMessage {...messages.profileTitle} />
			)}
		>
			<DisplayPart>
				{renderOverview()}
			</DisplayPart>

			<EditablePart>
				{renderForm()}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default AccountSettingsForm;
