import classNames from 'classnames';
import React, {
	Component,
} from 'react';



export enum ButtonsLayoutAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

export enum ButtonsLayoutType {
	ButtonsInRows = 'buttons-in-rows',
	CenterActionButton = 'center-action-button',
	FormRow = 'form-row',
	FormRowWithoutStatus = 'form-row-without-status',
	Steps = 'steps',
}

export enum ButtonsLayoutUsageContext {
	InModals = 'in-modals',
	InPage = 'in-page',
	Raw = 'raw',
}

type Props = {
	alignment?: ButtonsLayoutAlignment,
	children?: React.ReactNode,
	/** Structure (layout) of button */
	layout: ButtonsLayoutType,
	/** Possible width limitation of whole layout */
	layoutWidth?: number,
	/** Usage context modifies indentation of whole layout from other elements around */
	usageContext?: ButtonsLayoutUsageContext,
};



class ButtonsLayout extends Component<Props> {

	static defaultProps = {
		alignment: ButtonsLayoutAlignment.Right,
		layout: ButtonsLayoutType.FormRow,
		usageContext: ButtonsLayoutUsageContext.InPage,
	};



	_renderGeneralLayout() {
		const {
			alignment,
			children,
			layout,
			layoutWidth,
			usageContext,
		} = this.props;

		const layoutClasses = classNames({
			'buttons-layout': true,
			'buttons-layout--centered': layoutWidth,
			'buttons-layout--indentation': usageContext == ButtonsLayoutUsageContext.InPage,
			'buttons-layout--bigger-indentation': usageContext == ButtonsLayoutUsageContext.InModals,

			'buttons-layout--center-aligned-content': alignment === ButtonsLayoutAlignment.Center,
			'buttons-layout--left-aligned-content': alignment === ButtonsLayoutAlignment.Left,
			'buttons-layout--right-aligned-content': alignment === ButtonsLayoutAlignment.Right,

			'buttons-layout--form-row-layout': layout === ButtonsLayoutType.FormRow,
			'buttons-layout--form-row-without-status-layout': layout === ButtonsLayoutType.FormRowWithoutStatus,
			'buttons-layout--steps-layout': layout === ButtonsLayoutType.Steps,
		});

		const elements = React.Children.toArray(children).filter((child) => !!child);

		return (
			<div
				className={layoutClasses}
				style={{
					maxWidth: layoutWidth,
				}}
			>
				<div className="buttons-layout__container">
					{elements.map((child, index) => {
						return (
							<span
								className="buttons-layout__button"
								key={'form-action-button-' + index}
							>
								{child}
							</span>
						);
					})}
				</div>
			</div>
		);
	}



	_renderStepsLayout() {
		const {
			children,
			layoutWidth,
			usageContext,
		} = this.props;

		const layoutClasses = classNames({
			'buttons-layout': true,
			'buttons-layout--centered': layoutWidth,
			'buttons-layout--steps-layout': true,
			'buttons-layout--indentation': usageContext == ButtonsLayoutUsageContext.InPage,
			'buttons-layout--bigger-indentation': usageContext == ButtonsLayoutUsageContext.InModals,
		});

		const elements = React.Children.toArray(children).filter((child) => !!child);

		return (
			<div
				className={layoutClasses}
				style={{
					maxWidth: layoutWidth,
				}}
			>
				<div className="buttons-layout__container">
					{elements.map((child, index) => {
						return (
							<span
								className="buttons-layout__button"
								key={'form-action-button-' + index}
							>
								{child}
							</span>
						);
					})}
				</div>
			</div>
		);
	}



	_renderCenterActionButtonLayout() {
		const {
			children,
			layoutWidth,
			usageContext,
		} = this.props;

		// in this layout we ignore custom alignment of elements
		const layoutClasses = classNames({
			'buttons-layout': true,
			'buttons-layout--centered': layoutWidth,
			'buttons-layout--center-action-button-layout': true,
			'buttons-layout--indentation': usageContext == ButtonsLayoutUsageContext.InPage,
			'buttons-layout--bigger-indentation': usageContext == ButtonsLayoutUsageContext.InModals,
		});

		if (!children) {
			return false;
		}

		return (
			<div
				className={layoutClasses}
				style={{
					maxWidth: layoutWidth,
				}}
			>
				<div className="buttons-layout__container">
					<span className="buttons-layout__button">
						{children[0]}
					</span>

					<span className="buttons-layout__button">
						{children[1]}
					</span>

					<span className="buttons-layout__button">
						{children[2]}
					</span>
				</div>
			</div>
		);
	}



	_renderButtonsInRowsLayout() {
		const {
			children,
			layoutWidth,
			usageContext,
		} = this.props;

		const layoutClasses = classNames({
			'buttons-layout': true,
			'buttons-layout--centered': layoutWidth,
			'buttons-layout--buttons-in-rows-layout': true,
			'buttons-layout--indentation': usageContext == ButtonsLayoutUsageContext.InPage,
			'buttons-layout--bigger-indentation': usageContext == ButtonsLayoutUsageContext.InModals,
		});

		if (!children) {
			return false;
		}

		const elements = React.Children.toArray(children).filter((child) => !!child);

		return (
			<div
				className={layoutClasses}
				style={{
					maxWidth: layoutWidth,
				}}
			>
				<div className="buttons-layout__container">
					{elements.map((child, index) => (
						<span
							className="buttons-layout__button"
							key={'button_' + index}
						>
							{child}
						</span>
					))}
				</div>
			</div>
		);
	}



	render() {
		const {
			layout,
		} = this.props;

		if (layout === ButtonsLayoutType.ButtonsInRows) {
			return this._renderButtonsInRowsLayout();
		}

		if (layout === ButtonsLayoutType.CenterActionButton) {
			return this._renderCenterActionButtonLayout();
		}

		if (layout === ButtonsLayoutType.FormRow || layout === ButtonsLayoutType.FormRowWithoutStatus) {
			return this._renderGeneralLayout();
		}

		return this._renderStepsLayout();
	}

}



export default ButtonsLayout;
