import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MetaInformationIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeWidth="1"
			>
				<polygon
					id="Stroke-3"
					points="44.364168 48.0609048 14.7884257 48.0609048 14.7884257 11.0953857 31.423741 11.0953857 44.364168 24.0339643 44.364168 24.0284192"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 2}
				>
				</polygon>
				<polyline
					id="Stroke-4"
					points="44.363059 24.0287889 31.4244804 24.0287889 31.4244804 11.0902102"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 2}
				>
				</polyline>
				<polyline
					id="Stroke-5"
					points="24.3995715 29.573894 20.7028348 33.2706307 24.3995715 36.9673675"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 1.5}
				>
				</polyline>
				<polyline
					id="Stroke-6"
					points="34.7504344 29.573894 38.4471712 33.2706307 34.7504344 36.9673675"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 1.5}
				>
				</polyline>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default MetaInformationIconBuilder;

