import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	Link,
} from 'react-router5';



class SidebarNavigation extends Component {

	_handleClick(value, e) {
		const {
			onClickCallback,
		} = this.props;

		if (onClickCallback) {
			onClickCallback(value, e);
		}
	}



	_renderOptions() {
		const {
			options,
		} = this.props;

		const list = [];

		options.forEach((option, i) => {
			const optionClasses = classNames({
				'sidebar-nav__item': true,
				'sidebar-nav__item--highlighted': option.highlighted,
			});

			list.push((
				<li
					className={optionClasses}
					key={i}
				>
					{option.routeName && (
						<Link
							className="sidebar-nav__link"
							onClick={this._handleClick.bind(this, option.value)}
							routeName={option.routeName}
							routeParams={option.routeParams}
						>
							{option.label}
						</Link>
					)}

					{!option.routeName && (
						<a
							className="sidebar-nav__link"
							href="#"
							onClick={this._handleClick.bind(this, option.value)}
						>
							{option.label}
						</a>
					)}
				</li>
			));
		});

		return list;
	}



	render() {
		return (
			<ul className="sidebar-nav">
				{this._renderOptions()}
			</ul>
		);
	}

}

SidebarNavigation.propTypes = {
	onClickCallback: PropTypes.func,
};



export default SidebarNavigation;
