import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CheckListTable from '~/components/atoms/forms/components/CheckListTable';
import WebsiteDisplayName from '~/components/app/WebsiteDisplayName';



const messages = defineMessages({
	label: {
		id: 'ui.teamDetail.websites.title',
	},
});



type Props = {
	allWebsites: ReadonlyArray<CK.WebsiteId>,
	height?: number,
	name: string,
};

const AssignedWebsitesField: React.FC<Props> = (props) => {
	const {
		allWebsites,
		height,
		name,
	} = props;

	const websites = allWebsites.map((websiteId) => ({
		label: (
			<WebsiteDisplayName
				websiteId={websiteId}
			/>
		),
		value: websiteId,
	}));

	return (
		<CheckListTable
			items={websites}
			label={(
				<FormattedMessage {...messages.label} />
			)}
			name={name}
			tableHeight={height ?? 309}
		/>
	);
};



export default AssignedWebsitesField;
