import {
	useUserIsReportRecipientByDefaultQuery,
} from './useUserIsReportRecipientByDefault.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserIsReportRecipientByDefault(legacyUserId: string | null) {
	const { data } = useUserPropertiesQuery(
		useUserIsReportRecipientByDefaultQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.isReportRecipientByDefault ?? null;
}



export default useUserIsReportRecipientByDefault;
