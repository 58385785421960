import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import BarPagesChart from '../components/BarPagesChart';



class NumberOfHreflangsChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		this.colors = [
			'#367be2',
		];
	}



	render() {
		const {
			data,
		} = this.props;

		return (
			<BarPagesChart
				colors={this.colors}
				data={data}
				name="number_of_hreflangs"
				order={data ? data.map((item) => item.get('value')).toJS().sort() : undefined}
			/>
		);
	}

}

NumberOfHreflangsChart.propTypes = {
	data: PropTypes.instanceOf(List),
};



export default NumberOfHreflangsChart;
