import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import HighlightedBox from '~/components/patterns/boxes/HighlightedBox';
import SegmentIcon from '~/components/patterns/icons/SegmentIcon';
import SegmentIdentifier, {
	SegmentIdentifierStyle,
} from '~/components/patterns/segments/SegmentIdentifier';



const messages = defineMessages({
	changeLabel: {
		id: 'ui.segments.editor.identifierBox.changeLabel',
	},
	placeholderLabel: {
		id: 'ui.segments.editor.placeholderLabel',
	},
	setLabel: {
		id: 'ui.segments.editor.identifierBox.setLabel',
	},
	title: {
		id: 'ui.segments.editor.identifierBox.title',
	},
});



const IdentifierBox = (props) => {
	const {
		hasLabel,
		segmentColor,
		segmentIconName,
		segmentLabel,
		segmentShortcode,
		openIdentifierModal,
	} = props;

	const intl = useIntl();

	return (
		<HighlightedBox
			ctaElements={(
				<Button
					onClick={() => openIdentifierModal(false)}
					size={ButtonSize.XXSmall}
					style={ButtonStyle.Hollow}
					uppercase={true}
				>
					{hasLabel ? (
						<FormattedMessage {...messages.changeLabel} />
					) : (
						<FormattedMessage {...messages.setLabel} />
					)}
				</Button>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<SegmentIdentifier
				color={segmentColor}
				label={segmentLabel || intl.formatMessage(messages.placeholderLabel)}
				style={hasLabel ? SegmentIdentifierStyle.Normal : SegmentIdentifierStyle.Italic}
				symbol={segmentIconName ? (
					<SegmentIcon type={segmentIconName} />
				) : segmentShortcode}
			/>
		</HighlightedBox>
	);
};



export default IdentifierBox;
