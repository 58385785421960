import React from 'react';



type Props = {
	ctaElement?: React.ReactNode,
	label: React.ReactNode,
};

const InteractiveRadioLabel: React.FC<Props> = (props) => {
	const {
		ctaElement,
		label,
	} = props;

	return (
		<div className="interactive-radio-label">
			<div className="interactive-radio-label__label">
				{label}
			</div>
			{ctaElement && (
				<div className="interactive-radio-label__cta-element">
					{ctaElement}
				</div>
			)}
		</div>
	);
};



export default InteractiveRadioLabel;
