import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FloatingTableMessage from '~/components/atoms/dataTables/parts/FloatingTableMessage';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';



const messages = defineMessages({
	link: {
		id: 'ui.segments.editor.sizeLimitTableFooter.link',
	},
	message: {
		id: 'ui.segments.editor.sizeLimitTableFooter.message',
	},
});



type Props = {
	inactiveNumberOfPages: number,
	onClick: () => void,
};

const SizeLimitTableFooter: React.FC<Props> = (props) => {
	const {
		inactiveNumberOfPages,
		onClick,
	} = props;

	return (
		<FloatingTableMessage
			ctaElement={(
				<InternalLink
					onClickCallback={onClick}
					style={InternalLinkStyle.Light}
				>
					<FormattedMessage {...messages.link} />
				</InternalLink>
			)}
		>
			<FormattedMessage
				{...messages.message}
				values={{
					inactiveNumberOfPages,
				}}
			/>
		</FloatingTableMessage>
	);
};



export default SizeLimitTableFooter;
