import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CancelButton from '~/components/app/CancelButton';
import ContextNavigationButton, {
	ContextNavigationButtonIconType,
} from '~/components/patterns/navigations/ContextNavigationButton';
import Form from '~/components/atoms/forms/basis/Form';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalPanel from '~/components/atoms/panels/ModalPanel';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useResumeSubscriptionRenewalMutation,
} from './ResumeSubscriptionRenewalButton.gql';

import useAccountId from '~/hooks/useAccountId';
import useModals from '~/hooks/useModals';



const messages = defineMessages({
	buttonTitle: {
		id: 'ui.resumeSubscriptionRenewal.button',
	},
	modalButton: {
		id: 'ui.resumeSubscriptionRenewal.modal.button',
	},
	modalContent: {
		id: 'ui.resumeSubscriptionRenewal.modal.content',
	},
	modalTitle: {
		id: 'ui.resumeSubscriptionRenewal.modal.title',
	},
});



const ResumeSubscriptionRenewalButton: React.FC = () => {
	const accountId = useAccountId();
	const [resumeSubscriptionRenewal] = useResumeSubscriptionRenewalMutation();
	const modals = useModals();

	const handleSubmit = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			await resumeSubscriptionRenewal({
				variables: {
					accountId,
				},
			});

			modals.closeModal();
		},
		[
			accountId,
			modals,
			resumeSubscriptionRenewal,
		],
	);

	const openModal = React.useCallback(
		() => {
			modals.openModal(
				({ closeCallback }) => (
					<ModalPanel
						canBeClosed={true}
						canBeClosedViaOverlay={true}
						onCloseCallback={closeCallback}
					>
						<ModalContainer
							gapsSize={2}
							header={(
								<ModalHeader
									title={(
										<FormattedMessage {...messages.modalTitle} />
									)}
								/>
							)}
							headerGapsSize={1}
						>
							<Form
								defaultDataHasChanged={true}
								onSuccess={handleSubmit}
							>
								<ModalTextSection>
									<RichText>
										<p>
											<FormattedMessage {...messages.modalContent} />
										</p>
									</RichText>
								</ModalTextSection>

								<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
									<CancelButton />

									<SubmitButton>
										<FormattedMessage {...messages.modalButton} />
									</SubmitButton>
								</ModalButtonsLayout>
							</Form>
						</ModalContainer>
					</ModalPanel>
				),
			);
		},
		[
			handleSubmit,
			modals,
		],
	);

	return (
		<ContextNavigationButton
			iconType={ContextNavigationButtonIconType.Priority}
			onClickCallback={openModal}
		>
			<FormattedMessage {...messages.buttonTitle} />
		</ContextNavigationButton>
	);
};



export default ResumeSubscriptionRenewalButton;
