/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIsLighthouseMonitoredQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIsLighthouseMonitoredQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isLighthouseMonitored: boolean } | null };


export const WebsiteIsLighthouseMonitoredDocument = gql`
    query WebsiteIsLighthouseMonitored($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isLighthouseMonitored
  }
}
    `;

/**
 * __useWebsiteIsLighthouseMonitoredQuery__
 *
 * To run a query within a React component, call `useWebsiteIsLighthouseMonitoredQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIsLighthouseMonitoredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIsLighthouseMonitoredQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIsLighthouseMonitoredQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIsLighthouseMonitoredQuery, WebsiteIsLighthouseMonitoredQueryVariables> & ({ variables: WebsiteIsLighthouseMonitoredQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIsLighthouseMonitoredQuery, WebsiteIsLighthouseMonitoredQueryVariables>(WebsiteIsLighthouseMonitoredDocument, options);
      }
export function useWebsiteIsLighthouseMonitoredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIsLighthouseMonitoredQuery, WebsiteIsLighthouseMonitoredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIsLighthouseMonitoredQuery, WebsiteIsLighthouseMonitoredQueryVariables>(WebsiteIsLighthouseMonitoredDocument, options);
        }
export function useWebsiteIsLighthouseMonitoredSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteIsLighthouseMonitoredQuery, WebsiteIsLighthouseMonitoredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIsLighthouseMonitoredQuery, WebsiteIsLighthouseMonitoredQueryVariables>(WebsiteIsLighthouseMonitoredDocument, options);
        }
export type WebsiteIsLighthouseMonitoredQueryHookResult = ReturnType<typeof useWebsiteIsLighthouseMonitoredQuery>;
export type WebsiteIsLighthouseMonitoredLazyQueryHookResult = ReturnType<typeof useWebsiteIsLighthouseMonitoredLazyQuery>;
export type WebsiteIsLighthouseMonitoredSuspenseQueryHookResult = ReturnType<typeof useWebsiteIsLighthouseMonitoredSuspenseQuery>;
export type WebsiteIsLighthouseMonitoredQueryResult = Apollo.QueryResult<WebsiteIsLighthouseMonitoredQuery, WebsiteIsLighthouseMonitoredQueryVariables>;