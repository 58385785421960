import {
	addDays,
	differenceInCalendarDays,
	isSameDay,
	setISODay,
	subDays,
} from 'date-fns';



export enum DateRangePreset {
	Custom = 'Custom',
	Everything = 'Everything',
	Last14Days = 'Last14Days',
	Last4Weeks = 'Last4Weeks',
	Last7Days = 'Last7Days',
	PreviousWeek = 'PreviousWeek',
	ThisWeek = 'ThisWeek',
	Today = 'Today',
	Yesterday = 'Yesterday',
}

const ALL_DATE_RANGES = [
	DateRangePreset.Everything,
	DateRangePreset.Today,
	DateRangePreset.Yesterday,
	DateRangePreset.Last7Days,
	DateRangePreset.Last14Days,
	DateRangePreset.Last4Weeks,
	DateRangePreset.PreviousWeek,
	DateRangePreset.ThisWeek,
];

const PREFERRED_DATE_RANGES = [
	DateRangePreset.Last14Days,
	DateRangePreset.Last7Days,
];



type DateRangeDefinition = {
	isAvailable: (minDate: Date) => boolean,
	startDate: (minDate: Date) => Date,
	endDate: () => Date,
};



const FILTER_RANGES: Record<Exclude<DateRangePreset, DateRangePreset.Custom>, DateRangeDefinition> = {
	[DateRangePreset.Everything]: {
		isAvailable: () => true,
		startDate: (minDate) => minDate,
		endDate: () => new Date(),
	},
	[DateRangePreset.Last14Days]: {
		isAvailable: (minDate) => differenceInCalendarDays(new Date(), minDate) > 12,
		startDate: () => subDays(new Date(), 13),
		endDate: () => new Date(),
	},
	[DateRangePreset.Last4Weeks]: {
		isAvailable: (minDate) => {
			return differenceInCalendarDays(minDate, setISODay(subDays(new Date(), 7 * 3), 1)) < 7;
		},
		startDate: () => setISODay(subDays(new Date(), 7 * 3), 1),
		endDate: () => new Date(),
	},
	[DateRangePreset.Last7Days]: {
		isAvailable: (minDate) => differenceInCalendarDays(new Date(), minDate) > 1,
		startDate: (minDate) => {
			return differenceInCalendarDays(new Date(), minDate) > 8
				? subDays(new Date(), 6)
				: addDays(minDate, 1);
		},
		endDate: () => new Date(),
	},
	[DateRangePreset.PreviousWeek]: {
		isAvailable: (minDate) => differenceInCalendarDays(minDate, setISODay(subDays(new Date(), 7), 7)) <= 0,
		startDate: (minDate) => {
			const startDate = setISODay(subDays(new Date(), 7), 1);

			return differenceInCalendarDays(minDate, startDate) > 0
				? minDate
				: startDate;
		},
		endDate: () => setISODay(subDays(new Date(), 7), 7),
	},
	[DateRangePreset.ThisWeek]: {
		isAvailable: () => true,
		startDate: (minDate) => {
			const startDate = setISODay(new Date(), 1);

			return differenceInCalendarDays(minDate, startDate) > 0
				? minDate
				: startDate;
		},
		endDate: () => new Date(),
	},
	[DateRangePreset.Today]: {
		isAvailable: () => true,
		startDate: () => new Date(),
		endDate: () => new Date(),
	},
	[DateRangePreset.Yesterday]: {
		isAvailable: (minDate) => differenceInCalendarDays(new Date(), minDate) > 0,
		startDate: () => subDays(new Date(), 1),
		endDate: () => subDays(new Date(), 1),
	},
};



export function getDateRangeDefinition(dateRange: DateRangePreset | string): DateRangeDefinition {
	if (!FILTER_RANGES[dateRange]) {
		throw new Error(`Unknown date range ${dateRange}`);
	}

	return FILTER_RANGES[dateRange];
}



export function listApplicableRanges(
	minDate: Date,
	excludeRanges: Array<DateRangePreset> = [],
): Array<DateRangePreset> {
	const result: Array<DateRangePreset> = [];

	for (const dateRange of ALL_DATE_RANGES) {
		if (
			!excludeRanges.includes(dateRange)
			&& getDateRangeDefinition(dateRange).isAvailable(minDate)
		) {
			result.push(dateRange);
		}
	}

	return result;
}



export function getDefaultDateRange(
	minDate: Date,
): DateRangeDefinition {
	const applicableDateRanges = listApplicableRanges(minDate);

	for (const preferredDefaultDateRange of PREFERRED_DATE_RANGES) {
		if (applicableDateRanges.includes(preferredDefaultDateRange)) {
			return getDateRangeDefinition(preferredDefaultDateRange);
		}
	}

	return getDateRangeDefinition(DateRangePreset.Today);
}



export function findPredefinedRangeForDates(
	startDate: Date | null,
	endDate: Date | null,
	minDate: Date | null,
	excludeDateRangePresets: Array<DateRangePreset> = [],
): DateRangePreset {
	let result = DateRangePreset.Custom;

	if (startDate && endDate && minDate) {
		listApplicableRanges(minDate, excludeDateRangePresets)
			.forEach((dateRangeName) => {
				const dateRangeDefinition = getDateRangeDefinition(dateRangeName);

				const rangeStartDate = dateRangeDefinition.startDate(minDate);
				const rangeEndDate = dateRangeDefinition.endDate();

				if (
					isSameDay(startDate, rangeStartDate)
					&& isSameDay(endDate, rangeEndDate)
				) {
					result = dateRangeName;
				}
			});
	}

	return result;
}
