import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '../basis/Form';

import CancelButton from '~/components/app/CancelButton';
import SubmitButton, {
	SubmitButtonSize,
} from '~/components/app/SubmitButton';
import SwitchField from '~/components/app/SwitchField';
import TextField from '../components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import useAccountId from '~/hooks/useAccountId';
import useColumnSets from '~/hooks/useColumnSets';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';



const messages = defineMessages({
	formErrorsUnavailable: {
		id: 'ui.columnsConfigurator.view.unavailable',
	},
	shareableLabel: {
		id: 'ui.columnsConfigurator.sharing.toggleLabel',
	},
	submitButton: {
		id: 'ui.columnsConfigurator.addForm.submit',
	},
	title: {
		id: 'ui.columnsConfigurator.addForm.title',
	},
});



type Props = {
	cancelCallback: (e?: any) => void,
	submitCallback: (values: {
		name: string,
		shareable: boolean,
	}) => Promise<void>,
};

const CreateViewForm: React.FC<Props> = (props) => {
	const {
		cancelCallback,
		submitCallback,
	} = props;

	const accountId = useAccountId();
	const columnSets = useColumnSets();

	const isAllowedToManageSharedColumnSets = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageSharedColumnSets,
	);

	const validations = React.useMemo(
		() => {
			return {
				validateName: validateField(
					'name',
					(f) => [
						f.validateNonEmpty(),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsUnavailable} />
							),
							rule: ({ value }) => columnSets.columnSets.some(
								(columnSet) => columnSet.name === value,
							) === false,
						}),
					],
				),
			};
		},
		[
			columnSets,
		],
	);

	return (
		<Form
			defaultFocus="name"
			onSuccess={submitCallback}
			validations={validations}
		>
			<div className="columns-cta__form-title">
				<FormattedMessage {...messages.title} />
			</div>

			<div className="columns-cta__form-row">
				<FieldStatus
					name="validateName"
					showIcon={false}
				>
					<TextField name="name" />
				</FieldStatus>
			</div>

			{isAllowedToManageSharedColumnSets.yes ? (
				<div className="columns-cta__form-row">
					<FieldStatus
						name="shareable"
						showIcon={false}
					>
						<SwitchField
							activeStateLabel={(
								<FormattedMessage {...messages.shareableLabel} />
							)}
							inactiveStateLabel={(
								<FormattedMessage {...messages.shareableLabel} />
							)}
							name="shareable"
						/>
					</FieldStatus>
				</div>
			) : undefined}

			<div className="columns-cta__form-buttons">
				<CancelButton onClickCallback={cancelCallback} />

				<SubmitButton
					size={SubmitButtonSize.Small}
				>
					<FormattedMessage {...messages.submitButton} />
				</SubmitButton>
			</div>
		</Form>
	);
};



export default CreateViewForm;
