import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const HealthIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M21.5,5.867H17.25a.5.5,0,0,1-.5-.5V3.883A2.752,2.752,0,0,0,14,1.133H10a2.752,2.752,0,0,0-2.75,2.75V5.367a.5.5,0,0,1-.5.5H2.5A2.5,2.5,0,0,0,0,8.367v12a2.5,2.5,0,0,0,2.5,2.5h19a2.5,2.5,0,0,0,2.5-2.5v-12A2.5,2.5,0,0,0,21.5,5.867ZM9.75,3.883a.253.253,0,0,1,.25-.25h4a.253.253,0,0,1,.25.25V5.367a.5.5,0,0,1-.5.5h-3.5a.5.5,0,0,1-.5-.5ZM16.5,15.867a.5.5,0,0,1-.5.5H14a.5.5,0,0,0-.5.5v2a.5.5,0,0,1-.5.5H11a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,0-.5-.5H8a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h2a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,1,.5.5Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default HealthIconBuilder;
