import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Show or hide divider between blocks */
	divider?: boolean,
	/** Possibility to remove gaps between blocks */
	gaps?: boolean,
	/** Enable change of the flow on smaller screens */
	responsive?: boolean,
};



const SeparatedBlocks: React.FC<Props> = (props) => {
	const {
		children,
		divider = true,
		gaps = true,
		responsive = false,
	} = props;

	const componentClasses = classNames({
		'separated-blocks': true,
		'separated-blocks--gaps': gaps,
		'separated-blocks--responsive-flow': responsive,
		'separated-blocks--has-divider': divider,
	});

	return (
		<div className={componentClasses}>
			{React.Children.map(
				children,
				(child) => {
					if (!child) {
						return false;
					}

					return (
						<div className="separated-blocks__block">
							{child}
						</div>
					);
				},
			)}
		</div>
	);
};



export default SeparatedBlocks;
