import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import AlertPagesButton from '../AlertPagesButton';
import ExpressiveValue, {
	ExpressiveValueLabelFlow,
	ExpressiveValueSize,
	ExpressiveValueStatus,
} from '~/components/patterns/values/ExpressiveValue';
import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import RemovedFromAlertMetric from './RemovedFromAlertMetric';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';

import {
	countPagesSplit,
} from '~/model/alerts';



const messages = defineMessages({
	affected: {
		id: 'ui.alerts.overview.incident.details.affected',
	},
	resolved: {
		id: 'ui.alerts.overview.incident.details.resolved',
	},
});



class PageIssueOpenedDetails extends Component {

	render() {
		const {
			incident,
			websiteId,
		} = this.props;

		const isIncidentActive = incident.get('phase') === 'active';

		const isModern = incident.hasIn(['state', 'pages', 'split']);

		const numberOfAffected = isModern
			? countPagesSplit(incident, 'open')
			: incident.getIn(['state', 'affected_pages']);

		const numberOfResolved = isModern
			? countPagesSplit(incident, 'resolved')
			: incident.getIn(['state', 'resolved_pages']);

		const numberOfRemovedFromAlert = isModern
			? (
				countPagesSplit(incident, 'ignored')
				+ countPagesSplit(incident, 'not_applicable')
				+ countPagesSplit(incident, 'not_page')
				+ countPagesSplit(incident, 'not_required')
				+ countPagesSplit(incident, 'over_quota')
				+ countPagesSplit(incident, 'unknown')
			)
			: 0;

		const numberOfAlertPages = (
			numberOfAffected
			+ numberOfResolved
			+ numberOfRemovedFromAlert
		);

		return (
			<IncidentBoxContent
				button={numberOfAlertPages > 0 && (
					<AlertPagesButton
						focusOnPrimary={isIncidentActive && numberOfAffected > 0}
						incident={incident}
						websiteId={websiteId}
					/>
				)}
			>
				<SeparatedBlocks divider={false}>
					<ExpressiveValue
						label={(
							<FormattedMessage
								{...messages.affected}
								values={{
									count__pages: numberOfAffected,
								}}
							/>
						)}
						labelFlow={ExpressiveValueLabelFlow.Column}
						size={ExpressiveValueSize.Large}
						status={isIncidentActive ? ExpressiveValueStatus.Warning : ExpressiveValueStatus.Neutral}
						value={(
							<FormattedNumber value={numberOfAffected} />
						)}
					/>

					<ExpressiveValue
						label={(
							<FormattedMessage
								{...messages.resolved}
								values={{
									count__pages: numberOfResolved,
								}}
							/>
						)}
						labelFlow={ExpressiveValueLabelFlow.Column}
						size={ExpressiveValueSize.Large}
						status={isIncidentActive ? ExpressiveValueStatus.Success : ExpressiveValueStatus.Neutral}
						value={(
							<FormattedNumber value={numberOfResolved} />
						)}
					/>
				</SeparatedBlocks>


				{numberOfRemovedFromAlert > 0 && (
					<RemovedFromAlertMetric
						numberOfRemovedFromAlert={numberOfRemovedFromAlert}
					/>
				)}
			</IncidentBoxContent>
		);
	}

}

PageIssueOpenedDetails.propTypes = {
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string,
};



export default PageIssueOpenedDetails;
