import Immutable from 'immutable';
import React from 'react';

import CK from '~/types/contentking';

import {
	useCreateSegmentMutation,
	useUpdateSegmentMutation,
} from './useSegmentEditorContext.gql';

import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';
import useIsPageSegmentUsedForIgnoring from '~/hooks/useIsPageSegmentUsedForIgnoring';
import usePages from '~/hooks/usePages';
import usePendo from '~/hooks/usePendo';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	DEFAULT_FILTER as DEFAULT_PAGES_FILTER,
	removeDefaultFilterValues,
} from '~/model/pages';

import {
	type SegmentDefinition,
	denormalizeFilter,
	getSegmentDefinitionValidity,
} from '~/model/segments';



const DEFAULT_COLUMNS = Immutable.List([
	CK.PagesCommonColumn.Url,
	CK.PagesCommonColumn.Relevance,
	CK.PagesCommonColumn.Type,
]);

const DEFAULT_FILTER = Immutable.Map({
	[CK.PagesCommonColumn.Type]: DEFAULT_PAGES_FILTER.get(CK.PagesCommonColumn.Type),
});

const DEFAULT_SORT_BY = Immutable.Map({
	direction: false,
	key: CK.PagesCommonColumn.Relevance,
	static: false,
});

const DEFAULT_SIZE_LIMIT = null;



function createFilter({
	columns,
	filter,
}) {
	columns.forEach((columnName) => {
		if (!filter.has(columnName) && DEFAULT_PAGES_FILTER.has(columnName)) {
			filter = filter.set(
				columnName,
				DEFAULT_PAGES_FILTER.get(columnName),
			);
		}
	});

	filter.keySeq().forEach((columnName) => {
		if (columns.indexOf(columnName) === -1) {
			filter = filter.remove(columnName);
		}
	});

	return filter;
}



function createSortBy({
	columns,
	sortBy,
}) {
	if (columns.indexOf(sortBy.get('key')) === -1) {
		return DEFAULT_SORT_BY;
	}

	return sortBy;
}

type State = {
	columns: Array<string> | null,
	filter: Immutable.Map<string, any> | null,
	isDirty: boolean,
	isSubmitting: boolean,
	segmentColor: string | undefined,
	segmentIconName: string | null | undefined,
	segmentLabel: string | undefined,
	segmentShortcode: string | null | undefined,
	sizeLimit: CK.PageSegmentSizeLimit | null | undefined,
	sortBy: Immutable.Map<string, any> | null,
};



function useSegmentEditorContext(input: {
	defaultFilter: any,
	defaultSortBy: any,
	editedSegment: SegmentDefinition | null,
	websiteId: CK.WebsiteId,
}) {
	const {
		defaultFilter,
		defaultSortBy,
		editedSegment,
		websiteId,
	} = input;

	const currentColumnSet = useCurrentColumnSet();
	const isPageSegmentUsedForIgnoring = useIsPageSegmentUsedForIgnoring(websiteId);
	const pendo = usePendo();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const [createSegment] = useCreateSegmentMutation();
	const [updateSegment] = useUpdateSegmentMutation();

	const [state, setState] = React.useState<State>(() => ({
		columns: null,
		filter: null,
		isDirty: false,
		isSubmitting: false,
		segmentColor: undefined,
		segmentIconName: undefined,
		segmentLabel: undefined,
		segmentShortcode: undefined,
		sizeLimit: undefined,
		sortBy: editedSegment?.sizeLimit ? Immutable.Map({
			direction: editedSegment.sizeLimit.order === 'asc',
			fixed: true,
			key: editedSegment.sizeLimit.field,
		}) : null,
	}));

	const segmentColor = state.segmentColor ?? editedSegment?.color ?? null;
	const segmentIconName = (state.segmentIconName === undefined ? editedSegment?.icon?.name : state.segmentIconName) ?? null;
	const segmentLabel = state.segmentLabel ?? editedSegment?.label ?? null;
	const segmentShortcode = (state.segmentShortcode === undefined ? editedSegment?.shortcode : state.segmentShortcode) ?? null;

	const {
		columns,
		filter,
		isDirty,
		isSubmitting,
		sizeLimit,
		sortBy,
	} = state;

	const currentColumns = React.useMemo(
		() => {
			let result = columns as any;

			if (result === null) {
				result = DEFAULT_COLUMNS;

				if (editedSegment !== null) {
					result = result.concat(
						Immutable.Map(editedSegment.filterDefinition)
							.keySeq()
							.toList()
							.filter((column) => column !== CK.PagesCommonColumn.Url),
					);

					if (editedSegment.sizeLimit !== null) {
						const sizeLimitColumn = editedSegment.sizeLimit.field;

						if (!result.includes(sizeLimitColumn)) {
							result = result.push(sizeLimitColumn);
						}
					}
				}

				if (defaultFilter) {
					result = result.concat(
						defaultFilter.keySeq().toList(),
					);
				}

				if (defaultSortBy) {
					result = result.push(
						defaultSortBy.get('key'),
					);
				}

				if (editedSegment !== null || defaultFilter) {
					result = result.toSet().toList();
				}

				if (currentColumnSet !== null) {
					const columnsInSegment = result;
					const columnsInColumnSet = Immutable.List(
						currentColumnSet
							.columns
							.map((column) => column.name),
					);

					// Pick every column of the selected columns from the columnset to
					// preserve the order.
					result = columnsInColumnSet
						.filter((column) => columnsInSegment.includes(column));

					// Add selected columns not present in the columnset and append them in
					// order to the end.
					result = result
						.concat(columnsInSegment.filter((column) => !result.includes(column)));
				}
			}

			const columnUrlIndex = result.indexOf(CK.PagesCommonColumn.Url);
			if (columnUrlIndex !== 0) {
				result = result.remove(columnUrlIndex);
				result = result.unshift(CK.PagesCommonColumn.Url);
			}

			return result;
		},
		[
			columns,
			currentColumnSet,
			defaultFilter,
			defaultSortBy,
			editedSegment,
		],
	);

	const currentFilter = React.useMemo(
		() => {
			let result = filter as any;

			if (result === null) {
				result = Immutable.Map();

				if (defaultFilter) {
					result = defaultFilter;
				}

				result = DEFAULT_FILTER.concat(result);

				if (editedSegment !== null) {
					result = result.concat(Immutable.fromJS(editedSegment.filterDefinition));
				}

				result = denormalizeFilter(result);
				result = createFilter({ columns: currentColumns, filter: result });
			}

			return result;
		},
		[
			currentColumns,
			defaultFilter,
			editedSegment,
			filter,
		],
	);

	const currentFilterDefinition = React.useMemo(
		() => {
			return removeDefaultFilterValues(currentFilter).toJS();
		},
		[
			currentFilter,
		],
	);

	const currentSizeLimit = sizeLimit !== undefined
		? sizeLimit
		: (editedSegment?.sizeLimit ?? DEFAULT_SIZE_LIMIT);

	const currentSortBy = sortBy ?? defaultSortBy ?? DEFAULT_SORT_BY;

	const pages = usePages({
		columns: currentColumns,
		filter: currentFilter,
		limit: 1000,
		pollIntervalInMilliseconds: 10000,
		sortBy: currentSortBy,
		websiteId,
	});

	const validity = React.useMemo(
		() => {
			return getSegmentDefinitionValidity({
				currentFilterDefinition,
				currentSizeLimit,
				editedSegment,
				segmentColor,
				segmentDefinitions: segmentDefinitions.listAll(),
				segmentIconName,
				segmentLabel,
				segmentShortcode,
				isPageSegmentUsedForIgnoring,
			});
		},
		[
			currentFilterDefinition,
			currentSizeLimit,
			editedSegment,
			isPageSegmentUsedForIgnoring,
			segmentColor,
			segmentDefinitions,
			segmentIconName,
			segmentLabel,
			segmentShortcode,
		],
	);

	const updateColumns = React.useCallback(
		(nextColumns) => {
			const filter = createFilter({
				columns: nextColumns,
				filter: currentFilter,
			});

			const sortBy = createSortBy({
				columns: nextColumns,
				sortBy: currentSortBy,
			});

			setState(
				(state) => ({
					...state,
					columns: nextColumns,
					filter,
					sortBy,
				}),
			);
		},
		[
			currentFilter,
			currentSortBy,
		],
	);

	const addColumn = React.useCallback(
		(columnName: CK.PagesColumn) => {
			updateColumns(
				currentColumns.push(columnName),
			);
		},
		[
			currentColumns,
			updateColumns,
		],
	);

	const removeSizeLimit = React.useCallback(
		() => {
			setState(
				(state) => ({
					...state,
					sizeLimit: DEFAULT_SIZE_LIMIT,
				}),
			);
		},
		[],
	);

	const removeColumn = React.useCallback(
		(columnName) => {
			updateColumns(
				currentColumns.filter((column) => column !== columnName),
			);

			if (currentSizeLimit?.field === columnName) {
				removeSizeLimit();
			}
		},
		[
			currentColumns,
			currentSizeLimit,
			removeSizeLimit,
			updateColumns,
		],
	);

	const resetFilter = React.useCallback(
		() => {
			setState(
				(state) => ({
					...state,
					filter: editedSegment
						? denormalizeFilter(Immutable.Map(editedSegment.filterDefinition))
						: DEFAULT_FILTER,
				}),
			);
		},
		[
			editedSegment,
		],
	);

	const updateFilter = React.useCallback(
		(filter) => {
			filter = Immutable.fromJS(filter).map((value, key) => {
				if (value === undefined || value === null) {
					return DEFAULT_PAGES_FILTER.get(key) || '';
				}

				if ([
					'is_disallowed_in_robots_txt',
					'is_in_sitemap',
					'is_indexable',
					'is_secured',
					'is_linked',
				].includes(key)) {
					value = value.map((value) => {
						if (value === 'true') {
							return true;
						} else if (value === 'false') {
							return false;
						}

						return value;
					});
				}

				return value;
			});

			filter = currentFilter
				.merge(filter)
				.filter((value) => value !== '');

			setState(
				(state) => ({
					...state,
					filter,
					isDirty: true,
				}),
			);
		},
		[
			currentFilter,
		],
	);

	const updateIdentifier = React.useCallback(
		({
			segmentColor,
			segmentIconName,
			segmentLabel,
			segmentShortcode,
		}) => {
			setState(
				(state) => ({
					...state,
					isDirty: true,
					segmentColor,
					segmentIconName,
					segmentLabel,
					segmentShortcode,
				}),
			);
		},
		[],
	);

	const updateSizeLimit = React.useCallback(
		(sizeLimit) => {
			if (!currentColumns.includes(sizeLimit.field)) {
				addColumn(sizeLimit.field);
			}

			setState(
				(state) => ({
					...state,
					isDirty: true,
					sizeLimit,
					sortBy: Immutable.Map({
						direction: sizeLimit.order === 'asc',
						fixed: true,
						key: sizeLimit.field,
					}),
				}),
			);
		},
		[
			addColumn,
			currentColumns,
		],
	);

	const updateSortBy = React.useCallback(
		(sortBy) => {
			if (currentSizeLimit !== null) {
				throw new Error('Sorting should not be changed when sizelimit is set.');
			}

			setState(
				(state) => ({
					...state,
					sortBy: Immutable.fromJS(sortBy),
				}),
			);
		},
		[
			currentSizeLimit,
		],
	);

	const saveSegment = React.useCallback(
		async (props: Record<string, any> = {}): Promise<SegmentDefinition> => {
			const segmentData = {
				color: props.segmentColor ?? segmentColor,
				filterDefinition: currentFilterDefinition,
				iconName: props.segmentIconName ?? segmentIconName,
				label: props.segmentLabel ?? segmentLabel,
				shortcode: props.segmentShortcode ?? segmentShortcode,
				sizeLimit: currentSizeLimit,
				websiteId,
			};

			setState(
				(state) => ({
					...state,
					isSubmitting: true,
				}),
			);

			let savedSegment: SegmentDefinition;

			if (editedSegment !== null) {
				const { data } = await updateSegment({
					variables: {
						...segmentData,
						segmentId: editedSegment.id,
					},
				});

				if (data?.UpdateSegment.updatedPageSegment === undefined) {
					throw new Error(
						`Segment hasn't been retrieved after update mutation`,
					);
				}

				savedSegment = data.UpdateSegment.updatedPageSegment;
			} else {
				const { data } = await createSegment({
					variables: segmentData,
				});

				if (data?.CreateSegment.createdPageSegment === undefined) {
					throw new Error(
						`Segment hasn't been retrieved after create mutation`,
					);
				}

				savedSegment = data.CreateSegment.createdPageSegment;
			}

			setState(
				(state) => ({
					...state,
					isDirty: false,
					isSubmitting: false,
				}),
			);

			if (editedSegment === null) {
				pendo.then((pendo) => {
					pendo.track('Segment created', {
						'Website ID': websiteId,
						'Segment ID': savedSegment.id,
						'Segment name': savedSegment.label,
					});
				});
			}

			return savedSegment;
		},
		[
			createSegment,
			currentFilterDefinition,
			currentSizeLimit,
			editedSegment,
			pendo,
			segmentColor,
			segmentIconName,
			segmentLabel,
			segmentShortcode,
			updateSegment,
			websiteId,
		],
	);

	const hasLabel = !!segmentLabel && segmentColor !== null;

	let limitedNumberOfPages = pages.total;

	if (currentSizeLimit !== null) {
		const type = currentSizeLimit.limit.type;

		if (type === 'percentage') {
			limitedNumberOfPages = Math.ceil(pages.total * currentSizeLimit.limit.percentage);
		} else {
			limitedNumberOfPages = currentSizeLimit.limit.numberOfPages;
		}
	}

	return {
		addColumn,
		columns: currentColumns,
		filter: currentFilter,
		filterDefinition: currentFilterDefinition,
		hasLabel,
		isDirty,
		isSubmitting,
		limitedNumberOfPages,
		pages,
		removeColumn,
		removeSizeLimit,
		resetFilter,
		saveSegment,
		segmentColor,
		segmentIconName,
		segmentLabel,
		segmentShortcode,
		sizeLimit: currentSizeLimit,
		sortBy: currentSortBy,
		updateFilter,
		updateIdentifier,
		updateSizeLimit,
		updateSortBy,
		validity,
	};
}



export default useSegmentEditorContext;
