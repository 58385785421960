import Immutable from 'immutable';
import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import DonutPagesChart from '../components/DonutPagesChart';

import {
	TYPE_MISSING,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	TYPE_UNREACHABLE,
} from '~/model/pages';



const messages = defineMessages({
	[TYPE_MISSING]: {
		id: 'ui.contentOverview.pageTypes.missing',
	},
	[TYPE_PAGE]: {
		id: 'ui.contentOverview.pageTypes.page',
	},
	[TYPE_REDIRECT]: {
		id: 'ui.contentOverview.pageTypes.redirect',
	},
	[TYPE_SERVER_ERROR]: {
		id: 'ui.contentOverview.pageTypes.error',
	},
	[TYPE_UNREACHABLE]: {
		id: 'ui.contentOverview.pageTypes.unreachable',
	},
});



const PageTypeChart = (props) => {
	const {
		data,
		height,
		percentageFactor,
	} = props;

	const intl = useIntl();

	const labels = {
		[TYPE_MISSING]: intl.formatMessage(messages[TYPE_MISSING]),
		[TYPE_PAGE]: intl.formatMessage(messages[TYPE_PAGE]),
		[TYPE_REDIRECT]: intl.formatMessage(messages[TYPE_REDIRECT]),
		[TYPE_SERVER_ERROR]: intl.formatMessage(messages[TYPE_SERVER_ERROR]),
		[TYPE_UNREACHABLE]: intl.formatMessage(messages[TYPE_UNREACHABLE]),
	};

	const order = [
		TYPE_PAGE,
		TYPE_REDIRECT,
		TYPE_MISSING,
		TYPE_SERVER_ERROR,
		TYPE_UNREACHABLE,
	];

	const specificColors = {
		[TYPE_MISSING]: '#FE8719',
		[TYPE_PAGE]: '#42CC67',
		[TYPE_REDIRECT]: '#367be2',
		[TYPE_SERVER_ERROR]: '#FF5959',
		[TYPE_UNREACHABLE]: '#8595A6',
	};

	return (
		<DonutPagesChart
			data={(data && !data.toJS) ? Immutable.fromJS(data) : data}
			height={height}
			labels={labels}
			name="type"
			order={order}
			percentageFactor={percentageFactor}
			specificColors={specificColors}
		/>
	);
};



export default React.memo(PageTypeChart);
