import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy from '~/components/logic/Copy';



const messages = defineMessages({
	button: {
		id: 'ui.upgradeToPaid.congratulations.button',
	},
	description: {
		id: 'ui.upgradeToPaid.congratulations.description',
	},
	title: {
		id: 'ui.upgradeToPaid.congratulations.title',
	},
});



type Props = {
	isActive: boolean,
	onCloseCallback: () => void,
};

const SuccessSignupModalStep: React.FC<Props> = (props) => {
	const {
		isActive,
		onCloseCallback,
	} = props;

	if (!isActive) {
		return null;
	}

	return (

		<ConfirmationMessage
			animate={true}
			context={ConfirmationMessageContext.Confirmation}
			ctaButtons={(
				<Button
					onClick={onCloseCallback}
					style={ButtonStyle.Action}
					uppercase={false}
					width={ButtonWidth.SubmitButton}
				>
					<FormattedMessage {...messages.button} />
				</Button>
			)}
			message={(
				<FormattedMessage
					{...messages.title}
				/>
			)}
		>
			<Copy
				{...messages.description}
			/>
		</ConfirmationMessage>
	);
};



export default SuccessSignupModalStep;
