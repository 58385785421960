import type CK from '~/types/contentking';

import {
	useAccountDataRetentionInMonthsQuery,
} from './useAccountDataRetentionInMonths.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountDataRetentionInMonths(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountDataRetentionInMonthsQuery,
		accountId,
	);

	return data?.account?.dataRetentionInMonths ?? null;
}



export default useAccountDataRetentionInMonths;
