import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FullUserName from '~/components/app/FullUserName';
import UserOverview from '~/components/patterns/users/UserOverview';
import UserRoleAndAccess from '~/components/app/UserRoleAndAccess';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useDisabledMemberships from '~/hooks/useDisabledMemberships';
import useUserEmail from '~/hooks/useUserEmail';
import useUserFirstName from '~/hooks/useUserFirstName';
import useUserLastName from '~/hooks/useUserLastName';
import useUserMemberships from '~/hooks/useUserMemberships';

import {
	extractInitials,
} from '~/model/users';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	label: {
		id: 'ui.members.detail.label',
	},
	numberOfMemberships: {
		id: 'ui.userProfile.numberOfMemberships',
	},
});



type Props = {
	userId: string,
};

const UserProfile: React.FC<Props> = (props) => {
	const {
		userId,
	} = props;

	const currentUserId = useCurrentUserId();

	const disabledMemberships = useDisabledMemberships();
	const userEmail = useUserEmail(userId);
	const userFirstName = useUserFirstName(userId);
	const userLastName = useUserLastName(userId);
	const userMemberships = useUserMemberships(userId);

	if (
		currentUserId === null
		|| disabledMemberships === null
		|| userEmail === null
		|| userMemberships === null
	) {
		return null;
	}

	return (
		<UserOverview
			avatar={{
				email: userEmail,
				placeholder: extractInitials(userEmail),
			}}
			label={(
				<FormattedMessage {...messages.label} />
			)}
			name={(
				<FullUserName
					email={userEmail}
					firstName={userFirstName}
					lastName={userLastName}
				/>
			)}
			role={
				(userMemberships.length + disabledMemberships.length) === 1 ? (
					<UserRoleAndAccess
						userAccountMembership={getArrayItemAtSafeIndex(userMemberships, 0)}
					/>
				) : (
					<FormattedMessage
						{...messages.numberOfMemberships}
						values={{
							numberOfMemberships: userMemberships.length + disabledMemberships.length,
						}}
					/>
				)
			}
		/>
	);
};



export default UserProfile;
