import PropTypes from 'prop-types';



export enum pagesSectionType {
	Graphs = 'graphs',
	Table = 'table',
}

export const PAGES_SECTION_GRAPHS = pagesSectionType.Graphs;
export const PAGES_SECTION_TABLE = pagesSectionType.Table;

export function pagesSectionValidation() {
	return PropTypes.oneOf([
		pagesSectionType.Graphs,
		pagesSectionType.Table,
	]);
}
