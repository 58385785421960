import classNames from 'classnames';
import React from 'react';

import useUniqueInstanceId from '~/hooks/useUniqueInstanceId';

import {
	isFunction,
} from '~/utilities/typeCheck';



export enum SwitchFieldStyle {
	Expressive = 'expressive',
	Neutral = 'neutral',
}

type SwitchFieldLabelFunctionProp = (isChecked: boolean) => React.ReactNode;

type Props = {
	/** Disabled state of component */
	isDisabled?: boolean,
	/** Default state */
	isChecked?: boolean,
	label: React.ReactNode | SwitchFieldLabelFunctionProp,
	/** Name of HTML field */
	name: string,
	onBlurCallback?: (name: string) => void,
	onFocusCallback?: (name: string) => void,
	onChangeCallback?: (name: string, isChecked: boolean) => void,
	style?: SwitchFieldStyle,
};



const SwitchField: React.FC<Props> = (props) => {
	const {
		isDisabled,
		isChecked = false,
		label,
		name,
		onBlurCallback,
		onFocusCallback,
		onChangeCallback,
		style = SwitchFieldStyle.Expressive,
	} = props;

	const inputRef = React.useRef<HTMLInputElement | null>(null);
	const instanceId = useUniqueInstanceId();

	const [checked, setChecked] = React.useState(isChecked);

	React.useEffect(
		() => {
			if (isChecked !== checked) {
				setChecked(isChecked);

				if (inputRef.current) {
					inputRef.current.checked = isChecked;
				}
			}
		},
		[
			checked,
			isChecked,
		],
	);

	const changeHandler = (event) => {
		if (isDisabled) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		}

		const isChecked = inputRef.current?.checked || false;

		setChecked(isChecked);

		if (onChangeCallback) {
			onChangeCallback(name, isChecked);
		}
	};

	const componentClasses = classNames({
		'switch-field': true,
		'switch-field--disabled': isDisabled,
		['switch-field--' + style + '-style']: true,
	});

	let fieldLabel;

	if (isFunction<SwitchFieldLabelFunctionProp>(label)) {
		fieldLabel = label(checked);
	} else {
		fieldLabel = label;
	}

	return (
		<div className={componentClasses}>
			<input
				className="switch-field__input"
				defaultChecked={isChecked}
				disabled={isDisabled}
				id={`${name}-${instanceId}`}
				name={name}
				onBlur={() => {
					if (!isDisabled && onBlurCallback) {
						onBlurCallback(name);
					}
				}}
				onChange={changeHandler}
				onFocus={() => {
					if (!isDisabled && onFocusCallback) {
						onFocusCallback(name);
					}
				}}
				ref={inputRef}
				type="checkbox"
			/>
			<label
				className="switch-field__paddle"
				htmlFor={`${name}-${instanceId}`}
			>
				<span className="switch-field__screen-readers-label">
					{fieldLabel}
				</span>
			</label>
			<label
				className="switch-field__label"
				htmlFor={`${name}-${instanceId}`}
			>
				{fieldLabel}
			</label>
		</div>
	);
};



export default SwitchField;
