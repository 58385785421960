import React from 'react';

import usePendo from '~/hooks/usePendo';



function useTrackPendoEvent() {
	const pendo = usePendo();

	return React.useCallback(
		(message: string, payload: Record<string, any>) => {
			pendo.then((pendo) => {
				pendo.track(message, payload);
			});
		},
		[
			pendo,
		],
	);
}



export default useTrackPendoEvent;
