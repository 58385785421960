import Immutable, {
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
	LOGOUT_SUCCESSFUL,
} from '~/actions';

import {
	CHANGE_VIEW,
	MARK_CURRENT_VIEW_AS_DELETED,
	SET_COLUMNS,
	STORE_NEW_VIEW,
	UPDATE_STORED_VIEW,
} from '~/actions/teams/views';



function createRouterColumnsAnalysis(columnsParam) {
	if (!columnsParam) {
		return null;
	}

	const isOrdered = columnsParam.indexOf(':') !== -1;
	const columns = isOrdered
		? columnsParam.split(',').map((column) => ({
			name: column.split(':')[0],
			enabled: column.split(':')[1] === '1',
		}))
		: columnsParam.split(',');

	return {
		columns,
		isOrdered,
	};
}



function createDefaultState() {
	return Map({
		routerColumns: null,
		routerColumnSetId: null,
		selectedColumns: null,
	});
}



export function pagesColumns(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: { // initial setup & reaction only
			const {
				urlState,
			} = action;

			let routerColumnsAnalysis;

			if (!Immutable.is(state.get('routerColumns'), urlState.params.columns)) {
				state = state.set(
					'routerColumns',
					urlState.params.columns,
				);

				routerColumnsAnalysis = createRouterColumnsAnalysis(urlState.params.columns);
			}

			let routerColumnSetId = urlState.params.view ? parseInt(urlState.params.view) : null;

			if (isNaN(routerColumnSetId)) {
				routerColumnSetId = null;
			}

			state = state.merge({
				routerColumnSetId,
			});

			if (routerColumnsAnalysis && routerColumnsAnalysis.isOrdered) {
				state = state.set(
					'selectedColumns',
					routerColumnsAnalysis.columns,
				);
			}

			break;
		}

		case CHANGE_VIEW: {
			const {
				viewId,
			} = action;

			state = state.set('routerColumnSetId', viewId);
			state = state.set('routerColumns', null);
			state = state.set('selectedColumns', null);

			break;
		}

		case SET_COLUMNS: {
			const {
				columns,
			} = action;

			state = state.set(
				'selectedColumns',
				columns,
			);

			break;
		}

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case MARK_CURRENT_VIEW_AS_DELETED: {
			state = state.set('routerColumnSetId', null);
			state = state.set('routerColumns', null);
			state = state.set('selectedColumns', null);

			break;
		}

		case STORE_NEW_VIEW: {
			const {
				columnSetId,
			} = action;

			state = state.set('routerColumnSetId', columnSetId);
			state = state.set('routerColumns', null);
			state = state.set('selectedColumns', null);

			break;
		}

		case UPDATE_STORED_VIEW: {
			state = state.set('selectedColumns', null);

			break;
		}

	}

	return state;
}
