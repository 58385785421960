/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveAccessToClientMutationVariables = GraphQL.Exact<{
  agencyId: GraphQL.Scalars['AccountId']['input'];
  clientId: GraphQL.Scalars['AccountId']['input'];
}>;


export type RemoveAccessToClientMutation = { readonly __typename?: 'Mutation', readonly RemoveAccessToClient: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly agency: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly clientConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessClientConnection', readonly clientAccountId: CK.AccountId, readonly clientAccountName: string, readonly id: any, readonly inviterEmail: string | null, readonly isInitiatedByAgency: boolean, readonly isPending: boolean, readonly level: GraphQL.AccountAccessConnectionLevel }>, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null, readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId }> } | null, readonly client: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly agencyConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessAgencyConnection', readonly agencyAccountId: CK.AccountId, readonly agencyAccountName: string, readonly id: any, readonly inviterEmail: string | null, readonly isInitiatedByAgency: boolean, readonly isPending: boolean, readonly level: GraphQL.AccountAccessConnectionLevel }>, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null } } };


export const RemoveAccessToClientDocument = gql`
    mutation RemoveAccessToClient($agencyId: AccountId!, $clientId: AccountId!) {
  RemoveAccessToClient(agencyId: $agencyId, clientId: $clientId) {
    query {
      agency: account(id: $agencyId) {
        clientConnections {
          clientAccountId
          clientAccountName
          id
          inviterEmail
          isInitiatedByAgency
          isPending
          level
        }
        features {
          feature
          id
          inUse
        }
        id
      }
      authenticatedSession {
        accounts {
          id
        }
      }
      client: account(id: $clientId) {
        agencyConnections {
          agencyAccountId
          agencyAccountName
          id
          inviterEmail
          isInitiatedByAgency
          isPending
          level
        }
        features {
          feature
          id
          inUse
        }
        id
      }
    }
  }
}
    `;
export type RemoveAccessToClientMutationFn = Apollo.MutationFunction<RemoveAccessToClientMutation, RemoveAccessToClientMutationVariables>;

/**
 * __useRemoveAccessToClientMutation__
 *
 * To run a mutation, you first call `useRemoveAccessToClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccessToClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccessToClientMutation, { data, loading, error }] = useRemoveAccessToClientMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useRemoveAccessToClientMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccessToClientMutation, RemoveAccessToClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAccessToClientMutation, RemoveAccessToClientMutationVariables>(RemoveAccessToClientDocument, options);
      }
export type RemoveAccessToClientMutationHookResult = ReturnType<typeof useRemoveAccessToClientMutation>;
export type RemoveAccessToClientMutationResult = Apollo.MutationResult<RemoveAccessToClientMutation>;
export type RemoveAccessToClientMutationOptions = Apollo.BaseMutationOptions<RemoveAccessToClientMutation, RemoveAccessToClientMutationVariables>;