/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupOutOfBandAccountModalQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type SignupOutOfBandAccountModalQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly customElementsLimit: number, readonly enrichmentFieldsLimit: number, readonly id: CK.AccountId, readonly addons: ReadonlyArray<{ readonly __typename?: 'AccountAddonState', readonly addonType: GraphQL.AddonType, readonly amount: number }>, readonly availableAddons: ReadonlyArray<{ readonly __typename?: 'AccountAvailableAddon', readonly addonType: GraphQL.AddonType, readonly maximumAmount: number }> } | null };

export type SignupOutOfBandAccountMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  addons: ReadonlyArray<GraphQL.AddonAmountInput> | GraphQL.AddonAmountInput;
  billingCycle: GraphQL.Term;
  currency: GraphQL.Currency;
  pageBundle: GraphQL.Scalars['Int']['input'];
  plan: GraphQL.AccountPlan;
}>;


export type SignupOutOfBandAccountMutation = { readonly __typename?: 'Mutation', readonly Signup: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly billingCycle: GraphQL.Term, readonly billingEntity: GraphQL.SubscriptionsSellerCode, readonly canSignup: boolean, readonly currency: GraphQL.Currency | null, readonly hasBillingHistory: boolean, readonly isTrialBarDisplayed: boolean, readonly pageBundle: number, readonly phase: GraphQL.AccountPhase, readonly plan: GraphQL.AccountPlan | null, readonly state: GraphQL.AccountState, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageCapacity: number }> } | null } } };


export const SignupOutOfBandAccountModalDocument = gql`
    query SignupOutOfBandAccountModal($accountId: AccountId!) {
  account(id: $accountId) {
    addons {
      addonType
      amount
    }
    availableAddons {
      addonType
      maximumAmount
    }
    customElementsLimit
    enrichmentFieldsLimit
    id
  }
}
    `;

/**
 * __useSignupOutOfBandAccountModalQuery__
 *
 * To run a query within a React component, call `useSignupOutOfBandAccountModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupOutOfBandAccountModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupOutOfBandAccountModalQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSignupOutOfBandAccountModalQuery(baseOptions: Apollo.QueryHookOptions<SignupOutOfBandAccountModalQuery, SignupOutOfBandAccountModalQueryVariables> & ({ variables: SignupOutOfBandAccountModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignupOutOfBandAccountModalQuery, SignupOutOfBandAccountModalQueryVariables>(SignupOutOfBandAccountModalDocument, options);
      }
export function useSignupOutOfBandAccountModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignupOutOfBandAccountModalQuery, SignupOutOfBandAccountModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignupOutOfBandAccountModalQuery, SignupOutOfBandAccountModalQueryVariables>(SignupOutOfBandAccountModalDocument, options);
        }
export function useSignupOutOfBandAccountModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SignupOutOfBandAccountModalQuery, SignupOutOfBandAccountModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SignupOutOfBandAccountModalQuery, SignupOutOfBandAccountModalQueryVariables>(SignupOutOfBandAccountModalDocument, options);
        }
export type SignupOutOfBandAccountModalQueryHookResult = ReturnType<typeof useSignupOutOfBandAccountModalQuery>;
export type SignupOutOfBandAccountModalLazyQueryHookResult = ReturnType<typeof useSignupOutOfBandAccountModalLazyQuery>;
export type SignupOutOfBandAccountModalSuspenseQueryHookResult = ReturnType<typeof useSignupOutOfBandAccountModalSuspenseQuery>;
export type SignupOutOfBandAccountModalQueryResult = Apollo.QueryResult<SignupOutOfBandAccountModalQuery, SignupOutOfBandAccountModalQueryVariables>;
export const SignupOutOfBandAccountDocument = gql`
    mutation SignupOutOfBandAccount($accountId: AccountId!, $addons: [AddonAmountInput!]!, $billingCycle: Term!, $currency: Currency!, $pageBundle: Int!, $plan: AccountPlan!) {
  Signup(
    accountId: $accountId
    addons: $addons
    billingCycle: $billingCycle
    currency: $currency
    pageBundle: $pageBundle
    plan: $plan
  ) {
    query {
      account(id: $accountId) {
        id
        billingCycle
        billingEntity
        canSignup
        currency
        features {
          feature
          id
        }
        hasBillingHistory
        isTrialBarDisplayed
        pageBundle
        phase
        plan
        state
        websites {
          id
          pageCapacity
        }
      }
    }
  }
}
    `;
export type SignupOutOfBandAccountMutationFn = Apollo.MutationFunction<SignupOutOfBandAccountMutation, SignupOutOfBandAccountMutationVariables>;

/**
 * __useSignupOutOfBandAccountMutation__
 *
 * To run a mutation, you first call `useSignupOutOfBandAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupOutOfBandAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupOutOfBandAccountMutation, { data, loading, error }] = useSignupOutOfBandAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      addons: // value for 'addons'
 *      billingCycle: // value for 'billingCycle'
 *      currency: // value for 'currency'
 *      pageBundle: // value for 'pageBundle'
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function useSignupOutOfBandAccountMutation(baseOptions?: Apollo.MutationHookOptions<SignupOutOfBandAccountMutation, SignupOutOfBandAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupOutOfBandAccountMutation, SignupOutOfBandAccountMutationVariables>(SignupOutOfBandAccountDocument, options);
      }
export type SignupOutOfBandAccountMutationHookResult = ReturnType<typeof useSignupOutOfBandAccountMutation>;
export type SignupOutOfBandAccountMutationResult = Apollo.MutationResult<SignupOutOfBandAccountMutation>;
export type SignupOutOfBandAccountMutationOptions = Apollo.BaseMutationOptions<SignupOutOfBandAccountMutation, SignupOutOfBandAccountMutationVariables>;