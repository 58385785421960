import React from 'react';

import Favicon, {
	FaviconUsageContext,
} from '~/components/patterns/websites/Favicon';

import type CK from '~/types/contentking';

import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteLogo from '~/hooks/useWebsiteLogo';

import {
	getWebsiteInitials,
} from '~/model/websites';



type Props = {
	usageContext?: FaviconUsageContext,
	websiteId: CK.WebsiteId | null,
};

const WebsiteFavicon: React.FC<Props> = (props) => {
	const {
		usageContext = FaviconUsageContext.Default,
		websiteId,
	} = props;

	const displayName = useWebsiteDisplayName(websiteId);
	const logo = useWebsiteLogo(websiteId);

	return (
		<Favicon
			initials={getWebsiteInitials(displayName)}
			logoURL={logo === null ? undefined : logo}
			usageContext={usageContext}
		/>
	);
};



export default WebsiteFavicon;

export {
	FaviconUsageContext as WebsiteFaviconUsageContext,
};
