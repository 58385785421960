import classNames from 'classnames';
import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
} from 'react-intl';



type Props = {
	children: React.ReactNode,
	description?: React.ReactNode,
	indentationSize?: 0 | 1 | 2,
};

const FormSentence: React.FC<Props> = (props) => {
	const {
		description,
		children,
		indentationSize = 0,
	} = props;

	const componentClasses = classNames({
		'form-sentence': true,
		'form-sentence--indentation': indentationSize === 1,
		'form-sentence--bigger-indentation': indentationSize === 2,
	});

	return (
		<div className={componentClasses}>
			<div className="form-sentence__sentence">
				{React.Children.map(
					children,
					(child) => {
						if (typeof child === 'string') {
							child = child.trim();
						}

						if (!child) {
							return false;
						}

						let textOnlyObject = false;

						if (typeof child === 'string' || (child as any).props.type === 'string' || [FormattedMessage, FormattedNumber].includes((child as any).type)) {
							textOnlyObject = true;
						}

						const fragmentClasses = classNames({
							'form-sentence__sentence-fragment': true,
							'form-sentence__sentence-fragment--block-element': !textOnlyObject,
						});

						return (
							<span className={fragmentClasses}>
								{child}
							</span>
						);
					},
				)}
			</div>
			{description && (
				<div className="form-sentence__description">
					{description}
				</div>
			)}
		</div>
	);
};



export default FormSentence;
