import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';
import Small from '~/components/patterns/typography/Small';
import UserRoleName from '~/components/names/UserRoleName';



const messages = defineMessages({
	content: {
		id: 'ui.userRoleAndAccess',
	},
});



type Props = {
	userAccountMembership: {
		hasLimitedWebsitesAccess: boolean,
		role: GraphQL.UserRole,
		websites: ReadonlyArray<{
			id: CK.WebsiteId,
		}>,
	},
};

const UserRoleAndAccess: React.FC<Props> = (props) => {
	const {
		userAccountMembership,
	} = props;

	const roleName = (
		<UserRoleName role={userAccountMembership.role} />
	);

	if (userAccountMembership.hasLimitedWebsitesAccess === false) {
		return roleName;
	}

	return (
		<Copy
			{...messages.content}
			values={{
				numberOfWebsites: userAccountMembership.websites.length,
				role: roleName,
				small: (chunks) => (
					<Small muted={true}>{chunks}</Small>
				),
			}}
		/>
	);
};



export default UserRoleAndAccess;
