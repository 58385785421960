/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NonPagesQueryVariables = GraphQL.Exact<{
  after: GraphQL.InputMaybe<GraphQL.Scalars['Base64JsonObject']['input']>;
  ascending: GraphQL.Scalars['Boolean']['input'];
  before: GraphQL.InputMaybe<GraphQL.Scalars['Base64JsonObject']['input']>;
  filter: GraphQL.Scalars['Array']['input'];
  limit: GraphQL.Scalars['Int']['input'];
  offset: GraphQL.Scalars['Int']['input'];
  sort: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type NonPagesQuery = { readonly __typename?: 'Query', readonly nonPages: { readonly __typename?: 'NonPagesConnection', readonly distributions: Record<string, any>, readonly totalCount: number, readonly edges: ReadonlyArray<{ readonly __typename?: 'NonPagesConnectionEdge', readonly cursor: any, readonly loadedAt: number, readonly node: { readonly __typename?: 'NonPagesConnectionNode', readonly data: Record<string, any>, readonly id: CK.ID, readonly legacyId: number | null } } | null> } | null };


export const NonPagesDocument = gql`
    query NonPages($after: Base64JsonObject, $ascending: Boolean!, $before: Base64JsonObject, $filter: Array!, $limit: Int!, $offset: Int!, $sort: String!, $websiteId: WebsiteId!) {
  nonPages(
    after: $after
    ascending: $ascending
    before: $before
    filter: $filter
    limit: $limit
    offset: $offset
    sort: $sort
    websiteId: $websiteId
  ) {
    distributions
    edges {
      cursor
      loadedAt @client
      node {
        data
        id
        legacyId
      }
    }
    totalCount
  }
}
    `;

/**
 * __useNonPagesQuery__
 *
 * To run a query within a React component, call `useNonPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonPagesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      ascending: // value for 'ascending'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useNonPagesQuery(baseOptions: Apollo.QueryHookOptions<NonPagesQuery, NonPagesQueryVariables> & ({ variables: NonPagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NonPagesQuery, NonPagesQueryVariables>(NonPagesDocument, options);
      }
export function useNonPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NonPagesQuery, NonPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NonPagesQuery, NonPagesQueryVariables>(NonPagesDocument, options);
        }
export function useNonPagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NonPagesQuery, NonPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NonPagesQuery, NonPagesQueryVariables>(NonPagesDocument, options);
        }
export type NonPagesQueryHookResult = ReturnType<typeof useNonPagesQuery>;
export type NonPagesLazyQueryHookResult = ReturnType<typeof useNonPagesLazyQuery>;
export type NonPagesSuspenseQueryHookResult = ReturnType<typeof useNonPagesSuspenseQuery>;
export type NonPagesQueryResult = Apollo.QueryResult<NonPagesQuery, NonPagesQueryVariables>;