import {
	List,
} from 'immutable';
import memoize from 'memoizee';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, {
	Component,
} from 'react';
import {
	Grid,
} from 'react-virtualized';
import throttle from 'lodash/throttle';

import DatatableOverlay from '~/components/patterns/tables/datatables/DatatableOverlay';
import ResizableTableHeaderCells from './ResizableTableHeaderCells.part';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import {
	horizontalScrollTo,
} from '~/utilities/scrollTo';



export const FixedHeaderGridContext = React.createContext();



function overscanIndicesGetter({
	cellCount,
	overscanCellsCount,
	startIndex,
	stopIndex,
}) {
	return {
		overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
		overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
	};
}



class FixedHeaderGrid extends Component {

	constructor(props, context) {
		super(props, context);

		this._scrollTimeout = null;
		this._getColumnWidth = this._getColumnWidth.bind(this);
		this._handleColumnsResizingStart = this._handleColumnsResizingStart.bind(this);
		this._handleColumnsResizingStop = this._handleColumnsResizingStop.bind(this);
		this._handleGridScroll = throttle(this._handleGridScroll.bind(this), 50);
		this._handleHorizontalGridScroll = throttle(this._handleHorizontalGridScroll.bind(this), 50, {
			leading: true,
		});
		this._handleScrollbarScroll = throttle(this._handleScrollbarScroll.bind(this), 500);
		this._attachScrollListener = this._attachScrollListener.bind(this);
		this._removeScrollListener = this._removeScrollListener.bind(this);

		const gridHeight = props.rowHeight * props.rowCount;

		this.state = {
			gridHeight: gridHeight < 10000000 ? gridHeight : 10000000,
			hasHorizontalScrollbars: false,
			readonly: false,
			scrollHeight: 0,
			scrollPosition: props.scrollTop,
			scrollTop: props.scrollTop,
			// This property will be false or will contain difference between
			// table width and table container width (in case of smaller table)
			shorterThanViewport: false,
			showInactiveRowsFooter: false,
			horizontalScrollbarSize: scrollbarSize(),
		};
	}



	_handleColumnsResizingStart() {
		this.setState({
			readonly: true,
		});
	}



	_handleColumnsResizingStop(columnIndex, width) {
		const {
			onColumnResize,
		} = this.props;

		if (onColumnResize) {
			onColumnResize(columnIndex, width);
			this.refs.BodyGrid?.recomputeGridSize();
		}

		this.setState({
			readonly: false,
		});
	}



	_wrapPropertyGetter(value) {
		return value instanceof Function
			? value
			: () => value;
	}



	_wrapSizeGetter(size) {
		return this._wrapPropertyGetter(size);
	}



	_getAllColumnsWidth(columnCount, columnWidth) {
		let width = 0;
		const columnWidthGetter = this._wrapSizeGetter(columnWidth);

		for (let i = 0; i < columnCount; i++) {
			width += columnWidthGetter({ index: i });
		}

		return width;
	}



	_handleGridScroll(props) {
		const {
			activeRowsLimit,
			displayScrollbar,
			headerHeight,
			height,
			onScroll,
			rowCount,
			rowHeight,
		} = this.props;

		if (onScroll) {
			onScroll(props);
		}

		if (!displayScrollbar) {
			return false;
		}

		const {
			gridHeight,
			horizontalScrollbarSize,
			scrollHeight,
			scrollTop,
		} = this.state;

		const tableHeight = height - headerHeight - horizontalScrollbarSize;

		clearTimeout(this._scrollTimeout);

		if (this.scrollbarRef) {
			this.scrollbarRef.scrollTop = props.scrollTop;
		}

		if (scrollHeight !== props.scrollHeight) {
			this.setState({
				scrollHeight: props.scrollHeight,
			});
		}

		if (props.scrollTop === 0) {
			// this will call setState only once for displaying/hiding of top shadow
			this.setState({
				scrollPosition: props.scrollTop,
				scrollTop: null,
			});
		} else if (
			(gridHeight - props.scrollTop <= tableHeight)
			|| (gridHeight - scrollTop <= tableHeight)
		) {
			// this will call setState only once for displaying/hiding of bottom shadow
			this.setState({
				scrollPosition: props.scrollTop,
				scrollTop: null,
			});
		} else {
			// for other cases we can update scrollTop state with some non-blocking delay
			this._scrollTimeout = setTimeout(() => {
				this.setState({
					scrollPosition: scrollTop,
					scrollTop: null,
				});
			}, 500);
		}

		if (activeRowsLimit && rowCount > activeRowsLimit) {
			const trigger = activeRowsLimit * rowHeight - props.clientHeight;
			this.setState({
				showInactiveRowsFooter: props.scrollTop > trigger,
			});
		}
	}



	_calculateGridSizes(props) {
		const {
			columnCount,
			columnWidth,
			height,
			headerHeight,
			rowCount,
			rowHeight,
			width,
		} = props;

		const allColumnsWidth = this._getAllColumnsWidth(columnCount, columnWidth);
		const gridHeight = rowHeight * rowCount;
		let hasHorizontalScrollbars = false;
		let horizontalScrollbarSize = 0;
		let shorterThanViewport = 0;

		if (width >= allColumnsWidth) {
			shorterThanViewport = width - allColumnsWidth;
		} else {
			horizontalScrollbarSize = scrollbarSize();
			const tableHeight = height - headerHeight - scrollbarSize();
			hasHorizontalScrollbars = true;

			if (gridHeight > tableHeight) {
				shorterThanViewport = scrollbarSize();
			} else {
				shorterThanViewport = false;
			}
		}

		this.setState({
			gridHeight,
			hasHorizontalScrollbars,
			horizontalScrollbarSize,
			shorterThanViewport,
		}, () => {
			this.refs.BodyGrid?.forceUpdate();
		});
	}



	_handleScrollbarScroll(e) {
		if (!e) {
			e = window.event;
		}

		this.setState({
			scrollPosition: (e.target || e.srcElement).scrollTop,
			scrollTop: (e.target || e.srcElement).scrollTop,
		});
	}



	_attachScrollListener() {
		this.scrollbarRef.addEventListener('scroll', this._handleScrollbarScroll);
	}



	_removeScrollListener() {
		this.scrollbarRef.removeEventListener('scroll', this._handleScrollbarScroll);
	}



	componentDidMount() {
		this.scrollbarRef.addEventListener('mouseenter', this._attachScrollListener);
		this.scrollbarRef.addEventListener('mouseleave', this._removeScrollListener);

		this._calculateGridSizes(this.props);
	}



	componentWillUnmount() {
		this.scrollbarRef.removeEventListener('scroll', this._handleScrollbarScroll);
		this.scrollbarRef.removeEventListener('mouseenter', this._attachScrollListener);
		this.scrollbarRef.removeEventListener('mouseleave', this._removeScrollListener);
	}



	componentDidUpdate(prevProps) {
		const {
			scrollTop,
		} = this.props;

		const {
			scrollTop: prevScrollTop,
		} = prevProps;

		if (scrollTop !== prevScrollTop) {
			this.setState({
				scrollPosition: scrollTop,
				scrollTop,
			});

			this.scrollbarRef.scrollTop = scrollTop;
			this.refs.BodyGrid?.forceUpdate();
		}
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			columnCount,
			rowCount,
			width,
		} = this.props;

		const {
			columnCount: nextColumnCount,
			rowCount: nextRowCount,
			width: nextWidth,
		} = nextProps;

		if (
			width !== nextWidth
			|| rowCount !== nextRowCount
			|| columnCount !== nextColumnCount
		) {
			this._calculateGridSizes(nextProps);
		}

		if (!nextProps.activeRowsLimit) {
			this.setState({
				showInactiveRowsFooter: false,
			});
		}
	}



	_renderHeader() {
		const {
			columnCount,
			columnsMaxWidths,
			columnsMinWidths,
			columnWidth,
			disabledResizableColumns,
			headerCellRenderer,
			headerHeight,
			height,
			isResizable,
		} = this.props;

		const {
			hasHorizontalScrollbars,
		} = this.state;

		const cells = [];

		for (let i = 0; i < columnCount; i++) {
			cells.push(headerCellRenderer({ columnIndex: i }));
		}

		if (isResizable) {
			return (
				<ResizableTableHeaderCells
					columnCount={columnCount}
					columnWidth={columnWidth}
					columnsMaxWidths={columnsMaxWidths}
					columnsMinWidths={columnsMinWidths}
					disabledColumns={disabledResizableColumns}
					hasHorizontalScrollbars={hasHorizontalScrollbars}
					headerHeight={headerHeight}
					onDragStartCallback={this._handleColumnsResizingStart}
					onDragStopCallback={this._handleColumnsResizingStop}
					tableHeight={height}
				>
					{cells}
				</ResizableTableHeaderCells>
			);
		}

		return cells;
	}



	_getColumnCount() {
		const {
			columnCount,
		} = this.props;

		const {
			shorterThanViewport,
		} = this.state;

		if (shorterThanViewport) {
			return columnCount + 1;
		}

		return columnCount;
	}



	_getColumnWidth({ index }) {
		const {
			columnCount,
			columnWidth,
		} = this.props;

		const {
			shorterThanViewport,
		} = this.state;

		if (shorterThanViewport && columnCount === index) {
			return shorterThanViewport;
		}

		const columnWidthGetter = this._wrapSizeGetter(columnWidth);

		return columnWidthGetter({
			index,
		});
	}



	_handleHorizontalGridScroll() {
		const {
			onHorizontalScroll,
		} = this.props;

		if (onHorizontalScroll) {
			onHorizontalScroll(this.containerRef.scrollLeft);
		}
	}



	getColumnLeft(columnIndex) {
		const column = this.containerRef.querySelector('.resizable-header-cells .resizable-header-cells__cell:nth-child(' + (columnIndex + 1) + ')');

		return column
			? column.offsetLeft
			: null;
	}



	rerender() {
		this.refs.BodyGrid?.recomputeGridSize();
	}



	scrollHorizontallyTo(left, speed) {
		horizontalScrollTo(
			this.containerRef,
			left,
			speed,
		);
	}



	render() {
		const {
			activeRowsLimit,
			bodyCellRenderer,
			cellRangeRenderer,
			columnCount,
			columnWidth,
			data,
			disableContextMenuOnScrollableArea,
			disabledContent,
			displayScrollbar,
			headerHeight,
			height,
			inactiveRowsFooter,
			noDataMessage,
			overscanColumnCount,
			rowCount,
			rowHeight,
			width,
		} = this.props;

		const {
			gridHeight,
			horizontalScrollbarSize,
			readonly,
			scrollHeight,
			scrollPosition,
			scrollTop,
			shorterThanViewport,
			showInactiveRowsFooter,
		} = this.state;

		let allColumnsWidth = this._getAllColumnsWidth(columnCount, columnWidth);

		if (shorterThanViewport !== false) {
			allColumnsWidth += shorterThanViewport;
		}

		const tableHeight = height - headerHeight - horizontalScrollbarSize;

		const createContext = memoize((
			headerHeight,
			isScrollableHorizontally,
			scrollbarSize,
			tableHeight,
		) => {
			return {
				headerHeight,
				isScrollableHorizontally,
				scrollbarSize,
				tableHeight,
			};
		});

		return (
			<FixedHeaderGridContext.Provider
				value={createContext(
					headerHeight,
					horizontalScrollbarSize > 0,
					scrollbarSize(),
					height,
				)}
			>
				<div
					className={classNames({
						'rv-table': true,
						'rv-table--is-readonly': readonly,
					})}
					style={{
						width,
						height,
					}}
				>
					<div
						className="rv-table__scrollbar js-scrollable"
						ref={(ref) => this.scrollbarRef = ref}
						style={{
							display: displayScrollbar && (gridHeight > tableHeight) ? 'block' : 'none',
							top: headerHeight,
							bottom: horizontalScrollbarSize,
							width: scrollbarSize() || 16,
						}}
					>
						<div
							style={{
								height: scrollHeight,
								width: scrollbarSize() || 16,
							}}
						>
						</div>
					</div>

					<div
						className="rv-table__content"
						onScroll={this._handleHorizontalGridScroll}
						ref={(ref) => this.containerRef = ref}
						style={{
							position: scrollbarSize() === 0 ? 'relative' : 'initial', // fix for floating scrolbars on Safari
						}}
					>
						<div
							className={classNames({
								'rv-table__grid': true,
								'rv-table__grid--header': true,
								'rv-table__grid--header-shadow': scrollPosition > 0 || scrollPosition === null,
							})}
							style={{
								top: 0,
								left: 0,
								width: allColumnsWidth,
								height: headerHeight,
							}}
						>
							{this._renderHeader()}
						</div>

						<div
							className={classNames({
								'rv-table__grid-wrapper': true,
								'rv-table__grid-wrapper--disabled': disabledContent,
							})}
							onContextMenu={disableContextMenuOnScrollableArea
								? (e) => e.preventDefault()
								: null
							}
							style={{
								height: tableHeight,
								width: allColumnsWidth,
							}}
						>
							{noDataMessage && rowCount === 0 && (
								<div
									className="rv-table__no-data-message"
									style={{
										height: tableHeight,
										width,
									}}
								>
									<DatatableOverlay definedZIndex={false}>
										{noDataMessage}
									</DatatableOverlay>
								</div>
							)}

							{rowCount > 0 && (
								<Grid
									cellRangeRenderer={cellRangeRenderer}
									cellRenderer={bodyCellRenderer}
									className={classNames({
										'js-scrollable': true,
										'rv-table__grid': true,
										'rv-table__grid--has-some-inactive-rows': !!activeRowsLimit,
										'rv-table__grid--disabled': disabledContent,
									})}
									columnCount={this._getColumnCount()}
									columnWidth={this._getColumnWidth}
									height={tableHeight}
									key={`table-${shorterThanViewport ? shorterThanViewport + '-short' : 'normal'}`}
									onScroll={this._handleGridScroll}
									onSectionRendered={this.props.onSectionRendered}
									overscanColumnCount={overscanColumnCount}
									overscanIndicesGetter={overscanIndicesGetter}
									ref="BodyGrid"
									rowCount={rowCount}
									rowHeight={rowHeight}
									scrollTop={scrollTop}
									width={allColumnsWidth}
									{...data}
								/>
							)}
						</div>
					</div>

					{((scrollPosition === null) || (gridHeight - scrollPosition > tableHeight)) && (
						<div
							className="rv-table__bottom-shadow"
							style={{
								bottom: horizontalScrollbarSize,
							}}
						/>
					)}

					{inactiveRowsFooter && (
						<div
							className={classNames({
								'rv-table__footer-element': true,
								'rv-table__footer-element--hidden': !showInactiveRowsFooter,
							})}
							style={{
								bottom: horizontalScrollbarSize,
								right: scrollbarSize() || 16,
							}}
						>
							{inactiveRowsFooter}
						</div>
					)}
				</div>
			</FixedHeaderGridContext.Provider>
		);
	}

}

FixedHeaderGrid.defaultProps = {
	displayScrollbar: true,
	isResizable: false,
};

FixedHeaderGrid.propTypes = {
	activeRowsLimit: PropTypes.number,
	bodyCellRenderer: PropTypes.func.isRequired,
	columnCount: PropTypes.number.isRequired,
	columnsMaxWidths: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	columnsMinWidths: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	columnWidth: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.func,
	]).isRequired,
	data: PropTypes.any,
	disableContextMenuOnScrollableArea: PropTypes.bool,
	/** Overlay table body by disabled layer */
	disabledContent: PropTypes.bool,
	/** Indexes of columns where we don't enable resizable feature */
	disabledResizableColumns: PropTypes.instanceOf(List),
	displayScrollbar: PropTypes.bool,
	headerCellRenderer: PropTypes.func.isRequired,
	headerHeight: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	/** Footer to show when inactive rows are visible */
	inactiveRowsFooter: PropTypes.node,
	/** Enable resizable columns for table */
	isResizable: PropTypes.bool,
	/** Specific message shown when we will have no data */
	noDataMessage: PropTypes.node,
	overscanColumnCount: PropTypes.number,
	onColumnResize: PropTypes.func,
	onSectionRendered: PropTypes.func,
	onScroll: PropTypes.func,
	rowCount: PropTypes.number.isRequired,
	rowHeight: PropTypes.number.isRequired,
	scrollTop: PropTypes.number,
	width: PropTypes.number.isRequired,
};



export default FixedHeaderGrid;
