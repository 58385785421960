import React from 'react';

import styles from './BackLink.module.scss';



type Props = {
	label: React.ReactNode,
	onClickCallback: () => void,
};



const BackLink: React.FC<Props> = (props) => {
	const {
		label,
		onClickCallback,
	} = props;

	const _handleClick = (e) => {
		e.preventDefault();

		onClickCallback();
	};

	return (
		<a
			className={styles.component}
			href="#"
			onClick={_handleClick}
		>
			{label}
		</a>
	);
};



export default BackLink;
