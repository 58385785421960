import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AdminAccountDestructConfirmationModal from '~/components/app/AdminAccountDestructConfirmationModal';
import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon from '~/components/patterns/icons/BasicIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Emphasis from '~/components/patterns/typography/Emphasis';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InternalLink from '~/components/patterns/links/InternalLink';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useDeleteMessagingAppChannelsMutation,
} from '~/components/app/AccountCustomerTeamMessagingAppChannelOverview.gql';

import useAccountName from '~/hooks/useAccountName';
import useMessagingAppChannels from '~/hooks/useMessagingAppChannels';
import useMessagingAppDefinitions from '~/hooks/useMessagingAppDefinitions';
import useOpenModal from '~/hooks/useOpenModal';



const messagingAppTypeMapping = [
	GraphQL.MessagingAppType.Slack,
	GraphQL.MessagingAppType.MicrosoftTeams,
];



type Props = {
	accountId: CK.AccountId,
};

const AccountCustomerTeamMessagingAppChannelOverview: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const messagingAppChannels = useMessagingAppChannels();

	const hasMessagingAppChannels = messagingAppChannels.count > 0;

	return (
		<EditableFormWrapper
			isForAdmins={true}
			isReadOnly={hasMessagingAppChannels === false}
			title="Messaging App Channels"
		>
			{hasMessagingAppChannels ? (
				<FormRows>
					{messagingAppTypeMapping
						.filter((messagingAppType) => messagingAppChannels.listByType(messagingAppType).length > 0)
						.map((messagingAppType) => (
							<MessagingAppChannelFormRow
								accountId={accountId}
								key={messagingAppType}
								messagingAppType={messagingAppType}
							/>
						))
					}
				</FormRows>
			) : (
				<FormRow label="">
					<StaticText>
						<Emphasis>
							No messaging app channels
						</Emphasis>
					</StaticText>
				</FormRow>
			)}

			<EditablePart>
				<ButtonsLayout>
					<CancelButton />
				</ButtonsLayout>
			</EditablePart>
		</EditableFormWrapper>
	);
};

type MessagingAppChannelFormRowProps = {
	accountId: CK.AccountId,
	messagingAppType: GraphQL.MessagingAppType,
};

const MessagingAppChannelFormRow: React.FC<MessagingAppChannelFormRowProps> = (props) => {
	const {
		accountId,
		messagingAppType,
	} = props;

	const accountName = useAccountName(accountId);
	const messagingAppChannels = useMessagingAppChannels();
	const messagingAppDefinitions = useMessagingAppDefinitions();

	const [deleteMessagingAppChannelsMutation] = useDeleteMessagingAppChannelsMutation();

	const openDestructConfirmationModal = useOpenModal(
		() => (
			<AdminAccountDestructConfirmationModal
				accountId={accountId}
				confirmButton={`Delete ${messagingAppDefinitions.getLabel(messagingAppType)}`}
				description={(
					<p>
						Delete all {messagingAppChannels.listByType(messagingAppType).length} {messagingAppDefinitions.getLabel(messagingAppType)} channels in <strong>{accountName} ({accountId})</strong>.
					</p>
				)}
				onConfirmation={
					async () => {
						deleteMessagingAppChannelsMutation({
							variables: {
								accountId,
								messagingAppChannelIds: messagingAppChannels.listByType(messagingAppType).map((channel) => channel.id),
							},
						});
					}
				}
				titleAction="Delete"
				titleObject={messagingAppDefinitions.getLabel(messagingAppType)}
			/>
		),
		[
			accountId,
			accountName,
			deleteMessagingAppChannelsMutation,
			messagingAppChannels,
			messagingAppDefinitions,
			messagingAppType,
		],
	);

	return (
		<FormRow
			label={(
				<AttachedIcon
					icon={(
						<BasicIcon type={messagingAppDefinitions.getIcon(messagingAppType)} />
					)}
				>
					{messagingAppDefinitions.getLabel(messagingAppType)}
				</AttachedIcon>
			)}
		>
			<StaticList>
				{messagingAppChannels.listByType(messagingAppType).map((messagingAppChannel) => {
					return (messagingAppChannel.label);
				})}

				<EditablePart>
					<InternalLink
						onClickCallback={openDestructConfirmationModal}
					>
						Delete {messagingAppDefinitions.getLabel(messagingAppType)}
					</InternalLink>
				</EditablePart>
			</StaticList>
		</FormRow>
	);
};



export default AccountCustomerTeamMessagingAppChannelOverview;
