import React from 'react';
import {
	type Stripe,
	loadStripe,
} from '@stripe/stripe-js';
import {
	Elements,
} from '@stripe/react-stripe-js';

import {
	STRIPE_PUBLIC_KEYS,
} from '~/config';



const stripeAccounts: Record<string, Promise<Stripe | null>> = {};



type Props = {
	billingEntity: string | null,
	children?: React.ReactNode,
};

const StripeContext: React.FC<Props> = (props) => {
	const {
		billingEntity,
		children,
	} = props;

	if (billingEntity === null) {
		return null;
	}

	// @ts-ignore
	const stripePublicKey = STRIPE_PUBLIC_KEYS[billingEntity];

	if (stripePublicKey === undefined) {
		return null;
	}

	let stripePromise = stripeAccounts[billingEntity];

	if (stripePromise === undefined) {
		stripePromise = loadStripe(stripePublicKey);
		stripeAccounts[billingEntity] = stripePromise;
	}

	return (
		<Elements
			key={billingEntity}
			options={{
				locale: 'en',
				fonts: [
					{
						cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:300',
					},
				],
			}}
			stripe={stripePromise}
		>
			{children}
		</Elements>
	);
};



export default StripeContext;
