/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountDetailedDiscountsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountDetailedDiscountsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly discounts: ReadonlyArray<{ readonly __typename?: 'Discount', readonly coupon: string, readonly percentage: CK.Percentage | null, readonly type: GraphQL.DiscountType, readonly amount: ReadonlyArray<{ readonly __typename?: 'DiscountExactAmount', readonly currency: GraphQL.Currency, readonly value: number }> | null }> } | null };


export const AccountDetailedDiscountsDocument = gql`
    query AccountDetailedDiscounts($accountId: AccountId!) {
  account(id: $accountId) {
    id
    discounts {
      amount {
        currency
        value
      }
      coupon
      percentage
      type
    }
  }
}
    `;

/**
 * __useAccountDetailedDiscountsQuery__
 *
 * To run a query within a React component, call `useAccountDetailedDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailedDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailedDiscountsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountDetailedDiscountsQuery(baseOptions: Apollo.QueryHookOptions<AccountDetailedDiscountsQuery, AccountDetailedDiscountsQueryVariables> & ({ variables: AccountDetailedDiscountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDetailedDiscountsQuery, AccountDetailedDiscountsQueryVariables>(AccountDetailedDiscountsDocument, options);
      }
export function useAccountDetailedDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDetailedDiscountsQuery, AccountDetailedDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDetailedDiscountsQuery, AccountDetailedDiscountsQueryVariables>(AccountDetailedDiscountsDocument, options);
        }
export function useAccountDetailedDiscountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountDetailedDiscountsQuery, AccountDetailedDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountDetailedDiscountsQuery, AccountDetailedDiscountsQueryVariables>(AccountDetailedDiscountsDocument, options);
        }
export type AccountDetailedDiscountsQueryHookResult = ReturnType<typeof useAccountDetailedDiscountsQuery>;
export type AccountDetailedDiscountsLazyQueryHookResult = ReturnType<typeof useAccountDetailedDiscountsLazyQuery>;
export type AccountDetailedDiscountsSuspenseQueryHookResult = ReturnType<typeof useAccountDetailedDiscountsSuspenseQuery>;
export type AccountDetailedDiscountsQueryResult = Apollo.QueryResult<AccountDetailedDiscountsQuery, AccountDetailedDiscountsQueryVariables>;