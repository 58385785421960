import classNames from 'classnames';
import React from 'react';



export enum AbstractCheckboxFieldCheckedState {
	Checked = 'checked',
	Indeterminate = 'indeterminate',
	NotChecked = 'not-checked',
}

export enum AbstractCheckboxFieldSize {
	Small = 'small',
	Default = 'default',
}

type Props = {
	/** Possibility to disable animation when changing value */
	animation?: boolean,
	/** Center align whole field */
	centerAligned?: boolean,
	/** Set compact size of component because default size has reserved size for form fields */
	compact?: boolean,
	/** Checked state */
	checkedState?: AbstractCheckboxFieldCheckedState,
	/** Additional description attached to label */
	description?: React.ReactNode,
	/** Using this option we can define whether component value should be controlled. When enabled value can be changed everytime we update value prop */
	isControlled?: boolean,
	/** Possibility to make field disabled */
	isDisabled?: boolean,
	/** Possibility to make field readonly */
	isReadOnly?: boolean,
	/** Checkbox label */
	label?: React.ReactNode,
	maxWidth?: number,
	name: string,
	/** Callback triggered on field blur */
	onBlur?: () => void,
	/** Callback triggered on value change */
	onChangeCallback?: (newCheckedState: boolean) => void,
	/** Callback triggered on field click */
	onClick?: (event: React.MouseEvent) => void,
	/** Callback triggered on field focus */
	onFocus?: () => void,
	/** Callback triggered on component mouse enter */
	onMouseEnter?: () => void,
	/** Callback triggered on component mouse leave */
	onMouseLeave?: () => void,
	size?: AbstractCheckboxFieldSize,
};



const AbstractCheckboxField: React.FC<Props> = (props) => {
	const {
		animation = true,
		centerAligned,
		compact,
		checkedState = AbstractCheckboxFieldCheckedState.NotChecked,
		description,
		isControlled,
		isDisabled,
		isReadOnly,
		label,
		maxWidth,
		name,
		onBlur,
		onChangeCallback,
		onClick,
		onFocus,
		onMouseEnter,
		onMouseLeave,
		size = AbstractCheckboxFieldSize.Default,
	} = props;

	const fieldRef = React.useRef<HTMLInputElement | null>(null);

	const additionalAttributes: React.HTMLProps<HTMLInputElement> = {};
	let checkedValue = false;

	if (checkedState === AbstractCheckboxFieldCheckedState.Indeterminate || checkedState === AbstractCheckboxFieldCheckedState.Checked) {
		checkedValue = true;
	}

	if (isControlled) {
		additionalAttributes.checked = checkedValue;
	} else {
		additionalAttributes.defaultChecked = checkedValue;
	}

	const style: React.CSSProperties = {};

	if (maxWidth) {
		style.flex = '0 1 auto';
		style.maxWidth = maxWidth;
	}

	const componentClasses = classNames({
		'checkbox-field': true,
		'checkbox-field--center-aligned': centerAligned,
		'checkbox-field--compact': compact,
		'checkbox-field--empty': !label && !description,
		'checkbox-field--small': size === AbstractCheckboxFieldSize.Small,
		'checkbox-field--prevent-animation': !animation,
	});

	const inputClasses = classNames({
		'checkbox-field__input': true,
		'checkbox-field__input--deselect': checkedState === AbstractCheckboxFieldCheckedState.Indeterminate,
	});

	return (
		<div
			className={componentClasses}
			style={style}
		>
			<input
				className={inputClasses}
				disabled={isDisabled}
				id={name}
				name={name}
				onBlur={onBlur}
				onChange={() => {
					if (onChangeCallback) {
						const newCheckedState = fieldRef.current?.checked || false;
						onChangeCallback(newCheckedState);
					}
				}}
				onFocus={onFocus}
				readOnly={isReadOnly}
				ref={fieldRef}
				type="checkbox"
				value="1"
				{...additionalAttributes}
			/>
			<label
				className="checkbox-field__element"
				htmlFor={name}
				onClick={!isDisabled ? onClick : undefined}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				<span className="checkbox-field__label">
					{label && (
						<span>{label}</span>
					)}
				</span>
				{description && (
					<span className="checkbox-field__description">
						{description}
					</span>
				)}
			</label>
		</div>
	);
};



export default AbstractCheckboxField;
