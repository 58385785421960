import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import Copy from '~/components/logic/Copy';
import DestructConfirmationModal from './DestructConfirmationModal';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';

import {
	useDeleteCustomElementMutation,
} from './DeleteCustomElementConfirmationModal.gql';

import useGetCustomElementDeletability from '~/hooks/useGetCustomElementDeletability';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';

import {
	CustomElementDeletionViabilityVerdict,
} from '~/model/customElements';



const messages = defineMessages({
	caution: {
		id: 'ui.general.caution',
	},
	cautionConnectedSegment: {
		id: 'ui.deleteCustomElementConfirmation.cautionConnectedSegment',
	},
	confirmButton: {
		id: 'ui.deleteCustomElementConfirmation.confirmButton',
	},
	description: {
		id: 'ui.deleteCustomElementConfirmation.description',
	},
	labelName: {
		id: 'ui.deleteCustomElementConfirmation.labelName',
	},
	labelElementType: {
		id: 'ui.deleteCustomElementConfirmation.labelElementType',
	},
	labelFromWebsite: {
		id: 'ui.deleteCustomElementConfirmation.labelFromWebsite',
	},
	title: {
		id: 'ui.deleteCustomElementConfirmation.title',
	},
	verb: {
		id: 'ui.deleteCustomElementConfirmation.verb',
	},
	warnPermanent: {
		id: 'ui.deleteCustomElementConfirmation.warnPermanent',
	},
});



type Props = {
	customElementName: string,
	websiteId: CK.WebsiteId,
};

const DeleteCustomElementConfirmationModal: React.FC<Props> = (props) => {
	const {
		customElementName,
		websiteId,
	} = props;

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const getCustomElementDeletability = useGetCustomElementDeletability(websiteId);
	const websiteDisplayName = useWebsiteDisplayName(websiteId);

	const [deleteCustomElement] = useDeleteCustomElementMutation();

	const customElementDefinition = customElementDefinitions.getByName(customElementName);

	const label = customElementDefinition?.label || '...';
	const dataType = customElementDefinition?.dataType;

	const customElementDeletability = customElementDefinition !== null
		? getCustomElementDeletability(customElementDefinition)
		: null;

	const handleDeletion = React.useCallback(
		async () => {
			await deleteCustomElement({
				variables: {
					name: customElementName,
					websiteId,
				},
			});
		},
		[
			customElementName,
			deleteCustomElement,
			websiteId,
		],
	);

	return (
		<DestructConfirmationModal
			confirmButton={(
				<FormattedMessage {...messages.confirmButton} />
			)}
			confirmationPhrase={label}
			description={(
				<FormattedMessage {...messages.description} />
			)}
			disabled={customElementDeletability?.verdict !== CustomElementDeletionViabilityVerdict.NotPrevented}
			loading={customElementDefinition === null || customElementDeletability === null}
			onConfirmation={handleDeletion}
			titleAction={(
				<FormattedMessage {...messages.verb} />
			)}
			titleObject={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Copy {...messages.warnPermanent} />

			<LabeledValuesBox
				items={[
					{
						label: <FormattedMessage {...messages.labelName} />,
						value: label,
					},
					{
						label: <FormattedMessage {...messages.labelElementType} />,
						value: dataType,
					},
					{
						label: <FormattedMessage {...messages.labelFromWebsite} />,
						value: websiteDisplayName,
					},
				]}
			/>

			{customElementDeletability?.verdict === CustomElementDeletionViabilityVerdict.NotPrevented && (
				<CalloutMessage
					borders={true}
					message={<FormattedMessage {...messages.caution} />}
					status={CalloutMessageStatus.Warning}
				>
					<FormattedMessage
						{...messages.cautionConnectedSegment}
						values={{
							numberOfAffectedSegments: customElementDeletability.dependentSegments.length,
						}}
					/>
				</CalloutMessage>
			)}
		</DestructConfirmationModal>
	);
};



export default DeleteCustomElementConfirmationModal;
