import classNames from 'classnames';
import React from 'react';

import DecentText from '~/components/patterns/typography/DecentText';



export enum AttachedNoteAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

type Props = {
	alignment?: AttachedNoteAlignment,
	children?: React.ReactNode,
	/** Attached note component */
	note: React.ReactNode,
};



const AttachedNote: React.FC<Props> = (props) => {
	const {
		alignment = AttachedNoteAlignment.Left,
		children,
		note,
	} = props;

	const componentClasses = classNames({
		'attached-note': true,
		[ 'attached-note--' + alignment + '-aligned' ]: true,
	});

	return (
		<div className={componentClasses}>
			<div className="attached-note__source">
				{children}
			</div>
			{note && (
				<div className="attached-note__note">
					<DecentText>
						{note}
					</DecentText>
				</div>
			)}
		</div>
	);
};



export default AttachedNote;
