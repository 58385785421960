import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import AbstractCheckboxField, {
	AbstractCheckboxFieldCheckedState,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import {
	ContextConsumer,
} from './CheckboxGroupContainer';



class CheckboxInGroup extends Component {

	render() {
		const {
			name,
		} = this.props;

		const extraProps = { ...this.props };
		delete extraProps.name;

		return (
			<ContextConsumer>
				{({ disabled, indeterminate, processChange, processClick, name: groupName, value }) => {
					let checkedState = AbstractCheckboxFieldCheckedState.NotChecked;

					if (indeterminate.indexOf(name) !== -1) {
						checkedState = AbstractCheckboxFieldCheckedState.Indeterminate;
					}

					if (value.indexOf(name) !== -1) {
						checkedState = AbstractCheckboxFieldCheckedState.Checked;
					}

					return (
						<AbstractCheckboxField
							checkedState={checkedState}
							compact={true}
							isControlled={true}
							isDisabled={disabled.indexOf(name) !== -1}
							name={groupName + '_' + name}
							onChangeCallback={(value) => {
								processChange(name, value);
							}}
							onClick={(event) => {
								processClick(name, event);

								event.preventDefault();
							}}
							{...extraProps}
						/>
					);
				}}
			</ContextConsumer>
		);
	}

}

CheckboxInGroup.propTypes = {
	name: PropTypes.string.isRequired,
};



export default CheckboxInGroup;
