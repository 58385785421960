import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Ellipsis from '~/components/patterns/values/Ellipsis';
import IssueTitle from '~/components/logic/issues/IssueTitle';
import MultiselectTableField from '~/components/logic/formFields/MultiselectTableField';
import UpcomingValueUpdateHighlight from '~/components/patterns/values/UpcomingValueUpdateHighlight';

import useFormContext from '~/hooks/useFormContext';

import {
	type IssueName,
	PageIssue,
} from '~/model/issuesNew';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	columnIssue: {
		id: 'ui.indexNowIssuesSelectField.columnIssue',
	},
	columnStatus: {
		id: 'ui.indexNowIssuesSelectField.columnStatus',
	},
	doesBlockSubmission: {
		id: 'ui.indexNowIssuesSelectField.doesBlockSubmission',
	},
	doesNotBlockSubmission: {
		id: 'ui.indexNowIssuesSelectField.doesNotBlockSubmission',
	},
	willBlockSubmission: {
		id: 'ui.indexNowIssuesSelectField.willBlockSubmission',
	},
	willNotBlockSubmission: {
		id: 'ui.indexNowIssuesSelectField.willNotBlockSubmission',
	},
});



const issues = [
	PageIssue.TitleDuplicate,
	PageIssue.TitleIncorrectLength,
	PageIssue.TitleMissing,
	PageIssue.TitleTooMany,
	PageIssue.MetaDescriptionDuplicate,
	PageIssue.MetaDescriptionIncorrectLength,
	PageIssue.MetaDescriptionMissing,
	PageIssue.MetaDescriptionTooMany,
	PageIssue.H1Duplicate,
	PageIssue.H1IncorrectLength,
	PageIssue.H1Missing,
	PageIssue.H1TooMany,
	PageIssue.CanonicalLinkMissing,
	PageIssue.CanonicalLinkTooMany,
	PageIssue.HreflangConflictingTargets,
	PageIssue.HreflangInvalidTarget,
	PageIssue.HreflangInvalidValue,
	PageIssue.HreflangMissingSelfReference,
	PageIssue.HreflangMissingSpecificAudience,
	PageIssue.HreflangMissingXDefault,
	PageIssue.RobotDirectivesConflicting,
	PageIssue.RobotDirectivesInvalid,
	PageIssue.RobotDirectivesUnsupported,
	PageIssue.SchemaOrgErrors,
	PageIssue.SchemaOrgInvalidJson,
];



type Props = {
	name: string,
};

const IndexNowIssuesSelectField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	const formContext = useFormContext();
	const defaultValue = formContext.defaultValues[name];

	const renderHeader = React.useCallback(
		({ columnIndex }) => {
			switch (columnIndex) {

				case 0:
					return (
						<FormattedMessage {...messages.columnIssue} />
					);

				case 1:
					return (
						<FormattedMessage {...messages.columnStatus} />
					);

			}

			return null;
		},
		[],
	);

	const renderCell = React.useCallback(
		({ columnIndex, rowIndex, isChecked }) => {
			const issue = getArrayItemAtSafeIndex(issues, rowIndex) as IssueName;

			switch (columnIndex) {

				case 0:
					return (
						<Ellipsis>
							<IssueTitle
								issue={{
									context: {
										type: null,
									},
									name: issue,
									state: GraphQL.IssueState.Open,
								}}
							/>
						</Ellipsis>
					);
				case 1:
					const valueChanged = defaultValue.includes(issue) !== isChecked;

					if (!valueChanged) {
						if (isChecked) {
							return (
								<FormattedMessage {...messages.doesBlockSubmission} />
							);
						}

						return (
							<FormattedMessage {...messages.doesNotBlockSubmission} />
						);
					}

					if (isChecked) {
						return (
							<UpcomingValueUpdateHighlight updateDescription={<FormattedMessage {...messages.willBlockSubmission} />}>
								<FormattedMessage {...messages.willBlockSubmission} />
							</UpcomingValueUpdateHighlight>
						);
					}

					return (
						<UpcomingValueUpdateHighlight updateDescription={<FormattedMessage {...messages.willNotBlockSubmission} />}>
							<FormattedMessage {...messages.willNotBlockSubmission} />
						</UpcomingValueUpdateHighlight>
					);

			}

			return null;
		},
		[
			defaultValue,
		],
	);

	return (
		<MultiselectTableField
			cellRenderer={renderCell}
			columnCount={2}
			columnWidth={({ index, width }) => [width * 0.6, width * 0.4][index]}
			headerRenderer={renderHeader}
			items={issues}
			name={name}
			tableHeight={400}
		/>
	);
};



export default IndexNowIssuesSelectField;
