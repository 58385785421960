import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AbbreviatedNumber from '~/components/app/AbbreviatedNumber';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import IssuesCount, {
	IssuesCountFlow,
	IssuesCountSize,
	IssuesCountType,
} from '~/components/patterns/issues/IssuesCount';

import {
	type IssueCategoryName,
} from '~/model/issuesNew';

import matchAndReturn from '~/utilities/matchAndReturn';



export enum HistoricalIssuesCategoryChangesBadgeType {
	Opened = 'opened',
	Resolved = 'resolved',
}

const messages = defineMessages({
	resolvedUnavailable: {
		id: 'ui.historicalIssues.resolvedIssues.unavailableTooltip',
	},
});

const tooltipMessages = defineMessages({
	[HistoricalIssuesCategoryChangesBadgeType.Opened]: {
		id: 'ui.historicalIssues.openedIssues.tooltip',
	},
	[HistoricalIssuesCategoryChangesBadgeType.Resolved]: {
		id: 'ui.historicalIssues.resolvedIssues.tooltip',
	},
});



type Props = {
	inList: boolean,
	issueCategory: {
		isInitialComparison: boolean,
		issues: ReadonlyArray<{
			scope: {
				movements: Record<string, any>,
				name: 'pages' | 'platform',
			},
		}>,
		name: IssueCategoryName,
	},
	type: HistoricalIssuesCategoryChangesBadgeType,
};

const HistoricalIssuesCategoryChangesBadge: React.FC<Props> = (props) => {
	const {
		inList,
		issueCategory,
		type,
	} = props;

	let tooltip: React.ReactNode;
	let value: React.ReactNode;

	if (type === HistoricalIssuesCategoryChangesBadgeType.Resolved && issueCategory.isInitialComparison) {
		value = '-';

		tooltip = (
			<FormattedMessage {...messages.resolvedUnavailable} />
		);
	} else {
		const numberOfPages = issueCategory.issues.reduce(
			(total, issue) => {
				if (issue.scope.name !== 'pages') {
					return total;
				}

				return total + matchAndReturn(type, {
					[HistoricalIssuesCategoryChangesBadgeType.Opened]: issue.scope.movements.opened + issue.scope.movements.opened_new_page,
					[HistoricalIssuesCategoryChangesBadgeType.Resolved]: issue.scope.movements.closed,
				});
			},
			0,
		);

		value = (
			<AbbreviatedNumber value={numberOfPages} />
		);

		tooltip = (
			<FormattedMessage
				{...tooltipMessages[type]}
				values={{
					count: numberOfPages,
				}}
			/>
		);
	}

	return (
		<Hint
			attachment={HintAttachment.Center}
			popup={tooltip}
			targetCustomCursor={false}
		>
			<IssuesCount
				flow={inList ? IssuesCountFlow.Column : IssuesCountFlow.Row}
				size={inList ? IssuesCountSize.Default : IssuesCountSize.Large}
				type={matchAndReturn(type, {
					[HistoricalIssuesCategoryChangesBadgeType.Opened]: IssuesCountType.Opened,
					[HistoricalIssuesCategoryChangesBadgeType.Resolved]: IssuesCountType.Fixed,
				})}
				value={value}
			/>
		</Hint>
	);
};



export default HistoricalIssuesCategoryChangesBadge;
