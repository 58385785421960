import React from 'react';

import GraphQL from '~/types/graphql';

import AssignedSegmentsOverview from '~/components/logic/segments/AssignedSegmentsOverview';
import PageScreenshot from './PageScreenshot';
import SummaryLayout from '~/components/atoms/pageDetail/components/SummaryLayout';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageImportance from '~/hooks/usePageImportance';
import usePageScreenshotUrl from '~/hooks/usePageScreenshotUrl';
import usePageSegments from '~/hooks/usePageSegments';
import usePageType from '~/hooks/usePageType';
import useWebsiteId from '~/hooks/useWebsiteId';



type Props = {
	statusMessages: ReadonlyArray<React.ReactNode>,
};

const Summary: React.FC<Props> = (props) => {
	const {
		statusMessages,
	} = props;

	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const pageImportance = usePageImportance(legacyUrlId, websiteId);
	const pageScreenshotUrl = usePageScreenshotUrl(legacyUrlId, websiteId);
	const pageSegments = usePageSegments(legacyUrlId, websiteId);
	const pageType = usePageType(legacyUrlId, websiteId) ?? GraphQL.PageType.Page;

	function renderAssignedSegments() {
		if (
			pageType !== GraphQL.PageType.Missing
			&& pageType !== GraphQL.PageType.Page
			&& pageType !== GraphQL.PageType.Redirect
			&& pageType !== GraphQL.PageType.ServerError
			&& pageType !== GraphQL.PageType.Unreachable
		) {
			return null;
		}

		return (
			<AssignedSegmentsOverview
				loading={pageSegments === null}
				segmentNames={pageSegments ?? []}
			/>
		);
	}

	return (
		<SummaryLayout
			screenshot={pageType === GraphQL.PageType.Page && (
				<PageScreenshot
					pageImportance={pageImportance}
					pageScreenshotUrl={pageScreenshotUrl}
				/>
			)}
			segments={renderAssignedSegments()}
			statusMessages={statusMessages}
		/>
	);
};



export default Summary;
