import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import Measure from 'react-measure';



class IncidentFeedbackFormLayout extends Component {

	constructor(props, context) {
		super(props, context);

		this._refMeasure = React.createRef();

		this._handleComponentResize = this._handleComponentResize.bind(this);

		this.state = {
			componentHeight: false,
		};
	}



	componentDidMount() {
		this.setState({
			componentHeight: this._refMeasure.current.offsetHeight,
		});
	}



	_handleComponentResize() {
		this.setState({
			componentHeight: this._refMeasure.current.offsetHeight,
		});
	}



	render() {
		const {
			buttons,
			description,
			textAreaField,
		} = this.props;

		const {
			componentHeight,
		} = this.state;

		return (
			<div className="incident-feedback-form">
				<div className="incident-feedback-form__container">
					{description && (
						<div className="incident-feedback-form__description">
							{description}
						</div>
					)}

					<Measure
						bounds={true}
						innerRef={this._refMeasure}
						onResize={this._handleComponentResize}
					>
						{({ measureRef }) => (
							<div
								className="incident-feedback-form__form-row"
								ref={measureRef}
							>
								{typeof textAreaField === 'function' ? textAreaField({
									height: componentHeight,
								}) : textAreaField}
							</div>
						)}
					</Measure>

					<div className="incident-feedback-form__action-buttons">
						{buttons}
					</div>
				</div>
			</div>
		);
	}

}

IncidentFeedbackFormLayout.propTypes = {
	/** Form buttons */
	buttons: PropTypes.node.isRequired,
	/** Optional description text */
	description: PropTypes.node,
	/** Text area field */
	textAreaField: PropTypes.node.isRequired,
};



export default IncidentFeedbackFormLayout;
