import {
	useUserIntercomLinkQuery,
} from './useUserIntercomLink.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserIntercomLink(legacyUserId: string | null): string | null {
	const { data } = useUserPropertiesQuery(
		useUserIntercomLinkQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.intercomLink ?? null;
}



export default useUserIntercomLink;
