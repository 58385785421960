import classNames from 'classnames';
import React from 'react';



export enum FaviconUsageContext {
	Card = 'card',
	Default = 'default',
	List = 'list',
	Sidebar = 'sidebar',
}

type Props = {
	/** Initials visible when there is no logo URL */
	initials?: string,
	/** Logo image URL */
	logoURL?: string,
	/** Predefined style depends on selected context */
	usageContext?: FaviconUsageContext,
};



const Favicon: React.FC<Props> = (props) => {
	const {
		initials,
		logoURL,
		usageContext = FaviconUsageContext.Default,
	} = props;

	return (
		<div
			className={classNames({
				'favicon': true,
				'favicon--style-card': usageContext === FaviconUsageContext.Card,
				'favicon--style-sidebar': usageContext === FaviconUsageContext.Sidebar,
				'favicon--style-list': usageContext === FaviconUsageContext.List,
			})}
		>
			<div className="favicon__placeholder">
				{!logoURL && initials}
			</div>
			{logoURL && (
				<img
					className="favicon__image"
					src={logoURL}
				/>
			)}
		</div>
	);
};



export default Favicon;
