import React from 'react';
import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useHistoricalIssuesComparisonQuery,
} from './useHistoricalIssuesComparison.gql';

import {
	formatDateForBackend,
	formatEndDateForBackend,
} from '~/model/historicalChanges';

import {
	filterIssuesComparison,
} from '~/model/websiteIssuesComparison';

import {
	dateFromSelector,
	dateToSelector,
	filterSelector,
	scopeSelector,
} from '~/state/websiteIssuesComparison/selectors';



function useHistoricalIssuesComparison(websiteId: CK.WebsiteId | null): ReadonlyArray<{
	configuration: Record<string, any>,
	health: {
		change: {
			next: {
				gained: number,
				toGain: number,
			},
			previous: {
				gained: number,
				toGain: number,
			},
			type: 'worse' | 'better' | 'same',
		},
		toGain: number,
	},
	isIgnored: boolean,
	isInitialComparison: boolean,
	issues: ReadonlyArray<{
		configuration: Record<string, any>,
		context: any,
		health: {
			change: {
				next: {
					gained: number,
					toGain: number,
				},
				previous: {
					gained: number,
					toGain: number,
				},
				type: 'worse' | 'better' | 'same',
			},
			toGain: number,
		},
		isConfigurationModified: boolean,
		isIgnored: boolean,
		isInitialComparison: boolean,
		name: string,
		scope: {
			movements: Record<string, any>,
			name: 'pages' | 'platform',
		},
		state: GraphQL.IssueState,
	}>,
	name: string,
	state: GraphQL.IssueCategoryState,
}> | null {
	const dateFrom = useSelector(dateFromSelector);
	const dateTo = useSelector(dateToSelector);
	const filter = useSelector(filterSelector);
	const scope = useSelector(scopeSelector);

	const {
		data = null,
		loading,
	} = useHistoricalIssuesComparisonQuery({
		skip: websiteId === null,
		variables: {
			scope: scope === 'website' ? scope : 'segment/' + scope,
			since: formatDateForBackend(new Date(dateFrom)),
			until: formatEndDateForBackend(new Date(dateFrom), new Date(dateTo)),
			websiteId: websiteId ?? '',
		},
	});

	const [lastData, setLastData] = React.useState<any | null>(null);

	React.useEffect(
		() => {
			if (loading) {
				return;
			}

			if (data !== null && data.website !== null && data.website.historicalIssuesComparison !== null) {
				const result = data.website.historicalIssuesComparison.issues.changes;

				setLastData(
					result.length > 0
						? filterIssuesComparison(result, filter)
						: [],
				);
			} else {
				setLastData(null);
			}
		},
		[
			data,
			filter,
			loading,
			setLastData,
		],
	);

	return lastData;
}



export default useHistoricalIssuesComparison;
