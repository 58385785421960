import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import SimpleNavigation from '~/components/patterns/navigations/simpleNavigation/SimpleNavigation';
import SimpleNavigationItem from '~/components/patterns/navigations/simpleNavigation/SimpleNavigationItem';
import SplitButton, {
	SplitButtonStyle,
} from '~/components/patterns/buttons/SplitButton';

import useAccountId from '~/hooks/useAccountId';
import useAccountType from '~/hooks/useAccountType';
import useUrlState from '~/hooks/useUrlState';



const messages = defineMessages({
	buttonLabel: {
		id: 'ui.teamDetail.websites.addWebsite',
	},
	dropdownAddSingle: {
		id: 'ui.teamDetail.websites.addWebsite.dropdown.addSingleWebsite',
	},
	dropdownAddMultiple: {
		id: 'ui.teamDetail.websites.addWebsite.dropdown.addMultipleWebsites',
	},
});



const AddWebsiteHeaderButton: React.FC = () => {
	const accountId = useAccountId();
	const accountType = useAccountType(accountId);
	const urlState = useUrlState();

	const showDropdown = accountType !== GraphQL.AccountType.EndUser;

	if (showDropdown) {
		return (
			<SplitButton
				dropdown={(
					<SimpleNavigation>
						<SimpleNavigationItem
							linkRouteName="account.websites.new"
							linkRouteParams={{
								accountId,
								view: urlState.params.view,
							}}
						>
							<FormattedMessage {...messages.dropdownAddSingle} />
						</SimpleNavigationItem>
						<SimpleNavigationItem
							linkRouteName="account.websites.new"
							linkRouteParams={{
								accountId,
								bulk: true,
								view: urlState.params.view,
							}}
						>
							<FormattedMessage {...messages.dropdownAddMultiple} />
						</SimpleNavigationItem>
					</SimpleNavigation>
				)}
				dropdownWidth={190}
				icon={(
					<BasicIcon type={BasicIconType.Plus} />
				)}
				iconColor="#ffffff"
				linkRouteName="account.websites.new"
				linkRouteParams={{
					accountId,
					view: urlState.params.view,
				}}
				style={SplitButtonStyle.Action}
			>
				<FormattedMessage {...messages.buttonLabel} />
			</SplitButton>
		);
	}

	return (
		<Button
			icon={(
				<BasicIcon type={BasicIconType.Plus} />
			)}
			iconColor="#ffffff"
			linkRouteName="account.websites.new"
			linkRouteParams={{
				accountId,
				view: urlState.params.view,
			}}
			style={ButtonStyle.Action}
		>
			<FormattedMessage {...messages.buttonLabel} />
		</Button>
	);
};



export default AddWebsiteHeaderButton;
