import memoize from 'memoizee';

import type CK from '~/types/contentking';

import {
	type AccountWebsiteSizesQuery,
	useAccountWebsiteSizesQuery,
} from './useAccountWebsiteSizes.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



const format = memoize(
	(data: AccountWebsiteSizesQuery | undefined) => {
		const websites = data?.account?.websites ?? null;

		if (websites === null) {
			return null;
		}

		return {
			totalPageCapacity: websites
				.map((website) => website.pageCapacity)
				.reduce((total, pageCapacity) => {
					return total + pageCapacity;
				}, 0),
			websites,
		};
	},
);



function useAccountWebsiteSizes(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountWebsiteSizesQuery,
		accountId,
	);

	return format(data);
}



export default useAccountWebsiteSizes;
