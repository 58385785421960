import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const GraphIncreaseIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M2,17.865a1,1,0,0,0-1,1v1.5a.249.249,0,0,0,.25.25h3.5a.249.249,0,0,0,.25-.25v-1.5a1,1,0,0,0-1-1Z"
				fill={color}
			/>
			<path
				d="M8,14.865a1,1,0,0,0-1,1v4.5a.249.249,0,0,0,.25.25h3.5a.249.249,0,0,0,.25-.25v-4.5a1,1,0,0,0-1-1Z"
				fill={color}
			/>
			<path
				d="M14,11.365a1,1,0,0,0-1,1v8a.249.249,0,0,0,.25.25h3.5a.249.249,0,0,0,.25-.25v-8a1,1,0,0,0-1-1Z"
				fill={color}
			/>
			<path
				d="M19.25,20.615h3.5a.249.249,0,0,0,.25-.25v-12a1,1,0,0,0-1-1H20a1,1,0,0,0-1,1v12A.249.249,0,0,0,19.25,20.615Z"
				fill={color}
			/>
			<path
				d="M18.069,4.207l.307.46A.751.751,0,0,0,19,5h.025a.75.75,0,0,0,.626-.377l2-3.5a.751.751,0,0,0-.027-.788.742.742,0,0,0-.717-.328l-4,.5a.749.749,0,0,0-.531,1.16l.307.46a.251.251,0,0,1-.07.347L2.445,11.919a1,1,0,0,0,1.11,1.664L17.723,4.138A.249.249,0,0,1,18.069,4.207Z"
				fill={color}
			/>
			<path
				d="M1,24H23a1,1,0,0,0,0-2H1a1,1,0,0,0,0,2Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default GraphIncreaseIconBuilder;
