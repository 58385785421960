/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyWebsiteModalQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type VerifyWebsiteModalQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly verificationMethods: ReadonlyArray<{ readonly __typename?: 'WebsiteVerificationMethod', readonly type: GraphQL.WebsiteVerificationMethodType }> } | null };


export const VerifyWebsiteModalDocument = gql`
    query VerifyWebsiteModal($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    verificationMethods {
      type
    }
  }
}
    `;

/**
 * __useVerifyWebsiteModalQuery__
 *
 * To run a query within a React component, call `useVerifyWebsiteModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyWebsiteModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyWebsiteModalQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useVerifyWebsiteModalQuery(baseOptions: Apollo.QueryHookOptions<VerifyWebsiteModalQuery, VerifyWebsiteModalQueryVariables> & ({ variables: VerifyWebsiteModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyWebsiteModalQuery, VerifyWebsiteModalQueryVariables>(VerifyWebsiteModalDocument, options);
      }
export function useVerifyWebsiteModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyWebsiteModalQuery, VerifyWebsiteModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyWebsiteModalQuery, VerifyWebsiteModalQueryVariables>(VerifyWebsiteModalDocument, options);
        }
export function useVerifyWebsiteModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyWebsiteModalQuery, VerifyWebsiteModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifyWebsiteModalQuery, VerifyWebsiteModalQueryVariables>(VerifyWebsiteModalDocument, options);
        }
export type VerifyWebsiteModalQueryHookResult = ReturnType<typeof useVerifyWebsiteModalQuery>;
export type VerifyWebsiteModalLazyQueryHookResult = ReturnType<typeof useVerifyWebsiteModalLazyQuery>;
export type VerifyWebsiteModalSuspenseQueryHookResult = ReturnType<typeof useVerifyWebsiteModalSuspenseQuery>;
export type VerifyWebsiteModalQueryResult = Apollo.QueryResult<VerifyWebsiteModalQuery, VerifyWebsiteModalQueryVariables>;