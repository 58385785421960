import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BillingCycleField from '~/components/logic/formFields/BillingCycleField';
import BillingCycleName from '~/components/names/BillingCycleName';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms//basis/EditablePart';
import Form from '~/components/atoms/forms//basis/Form';
import FormRow from '~/components/atoms/forms//basis/FormRow';
import FormRows from '~/components/atoms/forms//basis/FormRows';
import PageBundleField from '~/components/app/PageBundleField';
import PlanField from '~/components/app/PlanField';
import PlanName from '~/components/names/PlanName';
import Parenthesis from '~/components/logic/Parenthesis';
import PaymentAuthorizationContext from '~/components/logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '~/components/logic/PaymentAuthorizationError';
import PaymentCancelButton from '~/components/logic/PaymentCancelButton';
import PaymentFailureFormError from '~/components/app/PaymentFailureFormError';
import PaymentSubmitButton from '~/components/logic/PaymentSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubscriptionChangeSummary from '~/components/app/SubscriptionChangeSummary';

import {
	useUpdateSubscriptionMutation,
} from './SubscriptionForm.gql';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useAllowedPlans from '~/hooks/useAllowedPlans';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import usePageBundle from '~/hooks/usePageBundle';
import useRequestAdminConfirmationIfApplicable from '~/hooks/useRequestAdminConfirmationIfApplicable';



const messages = defineMessages({
	agencyPlanLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.plan',
	},
	billingCycleLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.billingCycle',
	},
	cannotSubmitDueToPaymentIssues: {
		id: 'ui.subscriptionForm.cannotSubmitDueToPaymentIssues',
	},
	contactBoxTitle: {
		id: 'ui.billing.needHelpPickingPlan.header',
	},
	downgradeBoxDescription: {
		id: 'ui.downgradeBox.message',
	},
	downgradeBoxTitle: {
		id: 'ui.downgradeBox.title',
	},
	formErrorsBlank: {
		id: 'ui.formErrors.blank',
	},
	pageBundleLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.pageBundle',
	},
	saveButton: {
		id: 'ui.teamDetail.billing.package.confirmationButton',
	},
	title: {
		id: 'ui.teamDetail.subscriptionForm.header',
	},
});

const validations = {
	pageBundle: [
		{
			message: (
				<FormattedMessage {...messages.formErrorsBlank} />
			),
			field: 'pageBundle',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
};



const SubscriptionForm = (props) => {
	const {
		onEnterEditModeCallback,
		preselectedPlan,
		team,
	} = props;

	const accountId = team.get('id');

	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const accountPageBundle = usePageBundle(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);
	const allowedPlans = useAllowedPlans(accountId);
	const requestAdminConfirmationIfApplicable = useRequestAdminConfirmationIfApplicable(accountId);

	const isAllowedToSpendMoney = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.SpendMoney,
	);

	const [updateSubscription] = useUpdateSubscriptionMutation();

	const handleSubmit = React.useCallback(
		async ({ values }) => {
			if (accountTariff === null) {
				return;
			}

			await requestAdminConfirmationIfApplicable();

			await updateSubscription({
				variables: {
					accountId,
					billingCycle: values.billingCycle,
					pageBundle: values.pageBundle,
					plan: values.plan,
					tariff: accountTariff,
				},
			});
		},
		[
			accountId,
			accountTariff,
			requestAdminConfirmationIfApplicable,
			updateSubscription,
		],
	);

	const pageBundleUsage = team.get('websites').reduce((reduction, website) => {
		return reduction + website.get('page_capacity');
	}, 0);

	if (
		accountFeaturesUsage === null
		|| accountBillingCycle === null
		|| accountPageBundle === null
		|| accountPlan === null
		|| allowedBillingCycles === null
		|| allowedPlans === null
	) {
		return null;
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToSpendMoney}
			isOpen={!!preselectedPlan}
			key={accountId}
			onEnterEditModeCallback={onEnterEditModeCallback}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.pageBundleLabel} />
						)}
					>
						<StaticText>
							<FormattedNumber value={pageBundleUsage} />
							&nbsp;/&nbsp;
							<FormattedNumber value={accountPageBundle} />
							&nbsp;
							<span className="percentage">
								<Parenthesis
									content={(
										<FormattedNumber
											maximumFractionDigits={2}
											minimumFractionDigits={1}
											style="percent"
											value={pageBundleUsage / accountPageBundle}
										/>
									)}
								/>
							</span>
						</StaticText>
					</FormRow>

					{accountIsOutOfBand === false && (
						<>
							<FormRow
								label={(
									<FormattedMessage {...messages.billingCycleLabel} />
								)}
							>
								<StaticText>
									<BillingCycleName billingCycle={accountBillingCycle} />
								</StaticText>
							</FormRow>

							<FormRow
								label={(
									<FormattedMessage {...messages.agencyPlanLabel} />
								)}
							>
								<StaticText>
									<PlanName plan={accountPlan} />
								</StaticText>
							</FormRow>
						</>
					)}
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<PaymentAuthorizationContext
					accountId={accountId}
					onSubmit={handleSubmit}
					useModal={true}
				>
					{({ formRef, handleFormSuccess }) => {
						const defaultValues = {
							billingCycle: accountBillingCycle,
							pageBundle: accountPageBundle,
							plan: preselectedPlan || accountPlan,
						};

						return (
							<Form
								defaultDataHasChanged={!!preselectedPlan}
								defaultFocus="pageBundle"
								defaultValues={defaultValues}
								onSuccess={handleFormSuccess}
								ref={formRef}
								validations={validations}
							>
								{({ values }) => {
									const isPlanAvailable = accountFeaturesUsage
										.getPlanAvailability(values.plan || accountPlan)
										.isAvailable;

									const isBillingCycleEditable = allowedBillingCycles.length > 1;
									const isPlanEditable = allowedPlans.length > 1 && accountPlan !== GraphQL.AccountPlan.Enterprise;

									return (
										<>
											<FormRows>
												<FormRow
													htmlFor="pageBundle"
													label={(
														<FormattedMessage {...messages.pageBundleLabel} />
													)}
												>
													<PageBundleField
														accountId={team.get('id')}
														name="pageBundle"
													/>
												</FormRow>


												{accountIsOutOfBand === false && (
													<>
														<FormRow
															htmlFor="billingCycle"
															label={(
																<FormattedMessage {...messages.billingCycleLabel} />
															)}
														>
															{isBillingCycleEditable ? (
																<BillingCycleField
																	allowedBillingCycles={allowedBillingCycles}
																	name="billingCycle"
																/>
															) : (
																<StaticText>
																	<BillingCycleName billingCycle={accountBillingCycle} />
																</StaticText>
															)}
														</FormRow>
														<FormRow
															htmlFor="plan"
															label={(
																<FormattedMessage {...messages.agencyPlanLabel} />
															)}
														>
															{isPlanEditable ? (
																<PlanField name="plan" />
															) : (
																<StaticText>
																	<PlanName plan={accountPlan} />
																</StaticText>
															)}
														</FormRow>

														{!isPlanAvailable && (
															<FormRow fullwidth={true}>
																<CalloutMessage
																	borders={true}
																	message={(
																		<FormattedMessage {...messages.downgradeBoxTitle} />
																	)}
																	status={CalloutMessageStatus.Warning}
																>
																	<p>
																		<FormattedMessage
																			{...messages.downgradeBoxDescription}
																			values={{
																				text__pricingPlan: (
																					<PlanName plan={values.plan || accountPlan} />
																				),
																			}}
																		/>
																	</p>
																</CalloutMessage>
															</FormRow>
														)}
													</>
												)}

												<SubscriptionChangeSummary
													accountId={accountId}
													billingCycle={values.billingCycle}
													hidePrice={false}
													pageBundle={values.pageBundle}
													plan={values.plan}
												/>
											</FormRows>

											<PaymentFailureFormError
												errorCode="failedPayment"
											/>

											<PaymentAuthorizationError />

											<ButtonsLayout>
												<PaymentCancelButton />

												<PaymentSubmitButton
													isDisabled={team.get('has_payment_issues')}
													tooltip={team.get('has_payment_issues') && (
														<FormattedMessage {...messages.cannotSubmitDueToPaymentIssues} />
													)}
												>
													<FormattedMessage {...messages.saveButton} />
												</PaymentSubmitButton>
											</ButtonsLayout>
										</>
									);
								}}
							</Form>
						);
					}}
				</PaymentAuthorizationContext>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default SubscriptionForm;
