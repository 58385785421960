/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteSegmentDefinitionsQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteSegmentDefinitionsQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null }> } | null };


export const WebsiteSegmentDefinitionsDocument = gql`
    query WebsiteSegmentDefinitions($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    pageSegments {
      color
      filterDefinition
      icon {
        files {
          scaleFactor
          url
        }
        name
      }
      id
      isBeingReevaluated
      isManaged
      label
      name
      shortcode
      sizeLimit
    }
  }
}
    `;

/**
 * __useWebsiteSegmentDefinitionsQuery__
 *
 * To run a query within a React component, call `useWebsiteSegmentDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteSegmentDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteSegmentDefinitionsQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteSegmentDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<WebsiteSegmentDefinitionsQuery, WebsiteSegmentDefinitionsQueryVariables> & ({ variables: WebsiteSegmentDefinitionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteSegmentDefinitionsQuery, WebsiteSegmentDefinitionsQueryVariables>(WebsiteSegmentDefinitionsDocument, options);
      }
export function useWebsiteSegmentDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteSegmentDefinitionsQuery, WebsiteSegmentDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteSegmentDefinitionsQuery, WebsiteSegmentDefinitionsQueryVariables>(WebsiteSegmentDefinitionsDocument, options);
        }
export function useWebsiteSegmentDefinitionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteSegmentDefinitionsQuery, WebsiteSegmentDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteSegmentDefinitionsQuery, WebsiteSegmentDefinitionsQueryVariables>(WebsiteSegmentDefinitionsDocument, options);
        }
export type WebsiteSegmentDefinitionsQueryHookResult = ReturnType<typeof useWebsiteSegmentDefinitionsQuery>;
export type WebsiteSegmentDefinitionsLazyQueryHookResult = ReturnType<typeof useWebsiteSegmentDefinitionsLazyQuery>;
export type WebsiteSegmentDefinitionsSuspenseQueryHookResult = ReturnType<typeof useWebsiteSegmentDefinitionsSuspenseQuery>;
export type WebsiteSegmentDefinitionsQueryResult = Apollo.QueryResult<WebsiteSegmentDefinitionsQuery, WebsiteSegmentDefinitionsQueryVariables>;