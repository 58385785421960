import React from 'react';

import GraphQL from '~/types/graphql';

import FetchingLocationName from '~/components/names/FetchingLocationName';
import SelectField from '~/components/atoms/forms/components/SelectField';



const FetchingLocationMapping = [
	GraphQL.FetchingLocation.LeasewebEu,
	GraphQL.FetchingLocation.LeasewebUk,
	GraphQL.FetchingLocation.LeasewebUs,
] as const;



type Props = {
	name: string,
};

const FetchingLocationField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	return (
		<SelectField
			name={name}
			options={FetchingLocationMapping.map((fetchingLocation) => {
				return {
					name: fetchingLocation,
					label: (
						<FetchingLocationName fetchingLocation={fetchingLocation} />
					),
				};
			})}
		/>
	);
};



export default React.memo(FetchingLocationField);
