import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SchemaOrgIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				stroke={color}
				strokeLinejoin="round"
				strokeWidth="2"
			>
				<path
					d="M22.33 27h0A3.64 3.64 0 0 0 26 23.39v-3.52a3.64 3.64 0 0 1 1.82-3.16L29 16l-1.23-.71A3.64 3.64 0 0 1 26 12.13V8.61A3.64 3.64 0 0 0 22.33 5h0M9.67 27h0A3.64 3.64 0 0 1 6 23.39v-3.52a3.64 3.64 0 0 0-1.8-3.16L3 16l1.23-.71A3.64 3.64 0 0 0 6 12.13V8.61A3.64 3.64 0 0 1 9.67 5h0"
					strokeLinecap="round"
				/>
				<circle
					cx="15.84"
					cy="17.14"
					r=".53"
				/>
				<circle
					cx="15.81"
					cy="12.93"
					r=".61"
				/>
				<path
					d="M16,16.74c1.78.48,1.33,5.21-1.46,5.78"
					strokeLinecap="round"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default SchemaOrgIconBuilder;
