import Immutable, {
	Map,
} from 'immutable';

import {
	LOGOUT_SUCCESSFUL,
	STORE_LOADED_BILLING_DETAILS,
	STORE_LOADED_DISCOUNTS,
} from '~/actions';



function createDefaultState() {
	return new Map();
}



function createTeam() {
	return new Map({
		details: new Map(),
	});
}



export const billing = function (state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case STORE_LOADED_BILLING_DETAILS: {
			const {
				billingDetails,
				discounts,
				tax,
				teamId,
			} = action;

			if (!state.has(teamId)) {
				state = state.set(teamId, createTeam());
			}

			const data = {};
			if (billingDetails !== undefined) {
				data.details = Immutable.fromJS(billingDetails);
			}

			if (discounts !== undefined) {
				data.discounts = discounts;
			}

			if (tax !== undefined) {
				data.tax = tax;
			}

			state = state.mergeDeep({
				[teamId]: data,
			});

			break;
		}

		case STORE_LOADED_DISCOUNTS: {
			const {
				discounts,
				teamId,
			} = action;

			const accountId = teamId + '';

			if (!state.has(accountId)) {
				state = state.set(accountId, createTeam());
			}

			state = state.mergeDeep({
				[accountId]: {
					discounts,
				},
			});

			break;
		}

	}

	return state;
};
