import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import Copy from '~/components/logic/Copy';
import RichText from '~/components/patterns/typography/RichText';
import SimpleBox, {
	SimpleBoxBackground,
} from '~/components/patterns/boxes/SimpleBox';

import {
	DATA_TYPE_BOOLEAN,
	DATA_TYPE_DATE,
	DATA_TYPE_HASH,
	DATA_TYPE_INTEGER,
	DATA_TYPE_NUMBER,
	DATA_TYPE_STRING,
} from '~/model/customElements';



const messages = defineMessages({
	maximumLength: {
		id: 'ui.customElements.summary.maximumLength',
	},
	willBeJustOne: {
		id: 'ui.customElements.summary.willBeJustOne',
	},
});

const dataTypeMessages = defineMessages({
	[DATA_TYPE_BOOLEAN]: {
		id: 'ui.customElements.summary.isBoolean',
	},
	[DATA_TYPE_DATE]: {
		id: 'ui.customElements.summary.isDate',
	},
	[DATA_TYPE_HASH]: {
		id: 'ui.customElements.summary.isString',
	},
	[DATA_TYPE_INTEGER]: {
		id: 'ui.customElements.summary.isNumber',
	},
	[DATA_TYPE_NUMBER]: {
		id: 'ui.customElements.summary.isNumber',
	},
	[DATA_TYPE_STRING]: {
		id: 'ui.customElements.summary.isString',
	},
});



type Props = {
	elementType?:
		| typeof DATA_TYPE_BOOLEAN
		| typeof DATA_TYPE_DATE
		| typeof DATA_TYPE_HASH
		| typeof DATA_TYPE_INTEGER
		| typeof DATA_TYPE_NUMBER
		| typeof DATA_TYPE_STRING,
	isSingleOccurrence: boolean | null,
};

const ExtractionSummaryInfo: React.FC<Props> = (props) => {
	const {
		elementType,
		isSingleOccurrence,
	} = props;

	if (!elementType) {
		return null;
	}

	return (
		<SimpleBox
			background={SimpleBoxBackground.Dark}
			paddingSize={2}
		>
			<RichText>
				<ul>
					<li>
						<Copy {...dataTypeMessages[elementType]} />
					</li>

					{isSingleOccurrence === false && (
						<li>
							<Copy {...messages.willBeJustOne} />
						</li>
					)}

					{elementType === DATA_TYPE_STRING && (
						<li>
							<Copy {...messages.maximumLength} />
						</li>
					)}
				</ul>
			</RichText>
		</SimpleBox>
	);
};



export default ExtractionSummaryInfo;
