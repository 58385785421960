import useBatchContext from '~/hooks/useBatchContext';



function useBatchContextDashboard(websiteId: string, scope: string) {
	return useBatchContext(
		`dashboard/${websiteId}/${scope}`,
		999,
	);
}



export default useBatchContextDashboard;
