import {
	CONDUCTOR_APP_URL,
	CONTENTKING_CONDUCTOR_APP_URL,
} from '~/config';

import {
	getRouterState,
} from '~/routing/router';



export function getConductorLoginLink(redirectPath: string | null = null) {
	const redirectUri = redirectPath !== null
		? `&redirectUri=${CONTENTKING_CONDUCTOR_APP_URL}${redirectPath}`
		: '';

	return `${CONDUCTOR_APP_URL}/login.html?ckBanner=true${redirectUri}`;
}



export function redirectToConductorLogin() {
	const urlState = getRouterState();

	window.location.href = getConductorLoginLink(urlState.path);
}
