import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';



const MainNavigationLayout = (props) => {
	const {
		activeItemIndex = undefined,
		primaryItems,
		secondaryItems,
	} = props;

	const listClasses = classNames({
		'main-nav__list': true,
		'main-nav__list--highlighted-secondary-item': activeItemIndex && activeItemIndex >= primaryItems.length,
		'main-nav__list--highlight-items': activeItemIndex !== undefined,
		'main-nav__list--highlight-first': activeItemIndex === 0,
		'main-nav__list--highlight-second': activeItemIndex === 1,
		'main-nav__list--highlight-third': activeItemIndex === 2,
		'main-nav__list--highlight-fourth': activeItemIndex === 3,
		'main-nav__list--highlight-fifth': activeItemIndex === 4,
		'main-nav__list--highlight-sixth': activeItemIndex === 5,
	});

	return (
		<nav
			aria-label="Main navigation"
			className="main-nav"
			role="navigation"
		>
			<ul className={listClasses}>
				{primaryItems.filter((x) => !!x).length > 0 && secondaryItems.filter((x) => !!x).length > 0 && (
					<li
						className="main-nav__divider"
					/>
				)}
				{primaryItems.map((item, index) => {
					if (!item) {
						return false;
					}

					const itemClasses = classNames({
						'main-nav__item': true,
						'main-nav__item--highlighted': index == activeItemIndex,
					});

					return (
						<li
							className={itemClasses}
							key={'main-nav-primary-item-' + index}
						>
							{item}
						</li>
					);
				})}
				{secondaryItems.filter((x) => !!x).length > 0 && (
					<li
						className="main-nav__divider"
					/>
				)}
				{secondaryItems.filter((x) => !!x).map((item, index) => {
					const itemClasses = classNames({
						'main-nav__item': true,
						'main-nav__item--highlighted': index + primaryItems.length == activeItemIndex,
					});

					return (
						<li
							className={itemClasses}
							key={'main-nav-secondary-item-' + index}
						>
							{item}
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

MainNavigationLayout.defaultProps = {
	secondaryItems: [],
};

MainNavigationLayout.propTypes = {
	activeItemIndex: PropTypes.number,
	primaryItems: PropTypes.array.isRequired,
	secondaryItems: PropTypes.array,
};



export default MainNavigationLayout;
