import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PagesBrokenIconBuilder: IconBuilder = {
	icon: () => {
		return (
			<g>
				<defs>
					<path
						d="M0 0h24v28H0V0zm16 28a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
						id="a"
					/>
				</defs>
				<g
					fill="none"
					fillRule="evenodd"
					transform="translate(3)"
				>
					<path d="M20 24L0 24 0 0 20 0z" />
					<mask
						fill="#fff"
						id="b"
					>
						<use xlinkHref="#a" />
					</mask>
					<g
						mask="url(#b)"
						stroke="#8595A6"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<g transform="translate(1 1)">
							<path d="M0.333333333 21.7942918L0.333333333 3 15.3704584 3 15.3704584 21.7942918z" />
							<path d="M3 8.333h9.333H3zm0 4h9.333H3zm0 4h9.333H3z" />
							<path
								d="M3 2.79951793L3 0.333333333 18.3946464 0.333333333 18.3946464 19.5744765 15.5747595 19.5744765"
								strokeOpacity=".703"
							/>
						</g>
					</g>
					<g transform="translate(10 14)">
						<path
							d="M0 0H12V12H0z"
							fill="none"
						/>
						<rect
							fill="#FF5959"
							height="11"
							rx="5.5"
							stroke="#FF6759"
							strokeOpacity=".5"
							width="11"
							x=".5"
							y=".5"
						/>
						<path
							d="M5.25 8.25H6.75V9.75H5.25z"
							fill="#FAFBFC"
						/>
						<path
							d="M5.125 3.117a.847.847 0 0 1 .854-.867h.042c.486 0 .868.395.854.867l-.1 3.516a.903.903 0 0 1-.898.867h.246a.905.905 0 0 1-.898-.867l-.1-3.516z"
							fill="#FAFBFC"
						/>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 28,
	viewBoxWidth: 28,
};



export default PagesBrokenIconBuilder;
