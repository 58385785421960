import React from 'react';
import {
	useSelector,
} from 'react-redux';

import CK from '~/types/contentking';

import useEnabledWebsiteColumnsList from '~/hooks/useEnabledWebsiteColumnsList';
import usePages from '~/hooks/usePages';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	filterSelector,
	sortBySelector,
} from '~/state/ui/selectors';



function useFilteredPages() {
	const columns = useEnabledWebsiteColumnsList();
	const filter = useSelector(filterSelector);
	const sortBy = useSelector(sortBySelector);
	const websiteId = useWebsiteId() as ReturnType<typeof useWebsiteId> | null;

	const finalColumns = React.useMemo(
		() => {
			return columns.push(CK.PagesCommonColumn.Type).toArray();
		},
		[
			columns,
		],
	);

	return usePages({
		columns: finalColumns,
		filter,
		limit: 1000,
		pollIntervalInMilliseconds: 10000,
		sortBy,
		websiteId,
	});
}



export default useFilteredPages;
