import isDomain from './isDomain';



export default function isDomainPattern(value: string): boolean {
	let pattern = value;

	if (pattern.includes('*')) {
		if (pattern.startsWith('*')) {
			pattern = pattern.replace('*', 'foo');
		} else {
			return false;
		}
	}

	if (pattern.includes(':')) {
		return false;
	}

	return isDomain(pattern);
}
