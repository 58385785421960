import type CK from '~/types/contentking';

import {
	usePageImportanceQuery,
} from './usePageImportance.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageImportance(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
): number | null {
	const { data } = usePageDetailPropertiesQuery(
		usePageImportanceQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.pageTypeData?.importance ?? null;
}



export default usePageImportance;
