import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Name of value */
	name: React.ReactNode,
};



const NamedValue: React.FC<Props> = (props) => {
	const {
		children,
		name,
	} = props;

	return (
		<span className="named-value">
			<div className="named-value__name">
				{name}
			</div>
			<div className="named-value__value">
				{children}
			</div>
		</span>
	);
};



export default NamedValue;
