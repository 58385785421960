import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import SubmitButton from '~/components/app/SubmitButton';



const messages = defineMessages({
	label: {
		id: 'ui.alertsConfiguration.modal.bulk.submitButton',
	},
});



type Props = {
	numberOfAlertDefinitions: number,
};

const BulkSaveDefinitionsButton: React.FC<Props> = (props) => {
	const {
		numberOfAlertDefinitions,
	} = props;

	return (
		<SubmitButton>
			<FormattedMessage
				{...messages.label}
				values={{
					count__definitions: numberOfAlertDefinitions,
				}}
			/>
		</SubmitButton>
	);
};


export default BulkSaveDefinitionsButton;
