import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



let blockComponentCounter = 1;



const BlockIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		const componentId = blockComponentCounter++;

		return (
			<g>
				<defs>
					<circle
						cx="9"
						cy="9"
						id={'path-block-' + componentId}
						r="10"
					>
					</circle>
				</defs>
				<g
					fill="none"
					fillRule="evenodd"
					id="Page-1"
					stroke="none"
					strokeWidth="1"
				>
					<g
						id="ignored-status"
						transform="translate(3.000000, 3.000000)"
					>
						<g id="icon-ignored">
							<g id="ball">
								<g id="path-1-Clipped">
									<mask
										fill="white"
										id={'mask-block-' + componentId}
									>
										<use xlinkHref={'#path-block-' + componentId}></use>
									</mask>
									<circle
										cx="9"
										cy="9"
										fill={color}
										fillOpacity="0"
										mask={'url(#mask-block-' + componentId + ')'}
										r="8"
										stroke={color}
										strokeWidth="2.1"
									>
									</circle>
								</g>
							</g>
							<path
								d="M1.6,8.1 L16.4,8.1 C16.7865993,8.1 17.1,8.41340068 17.1,8.8 L17.1,9.2 C17.1,9.58659932 16.7865993,9.9 16.4,9.9 L1.6,9.9 C1.21340068,9.9 0.9,9.58659932 0.9,9.2 L0.9,8.8 C0.9,8.41340068 1.21340068,8.1 1.6,8.1 Z"
								fill={color}
								id="stripe"
								transform="translate(9.000000, 9.000000) rotate(45.000000) translate(-9.000000, -9.000000) "
							>
							</path>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default BlockIconBuilder;

