import Immutable from 'immutable';
import isEqual from 'lodash/isEqual';

import CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	ColumnsWithoutChangeTracking,
} from '~/model/pagesColumns';

import arrayMove from '~/utilities/arrayMove';
import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import {
	isArray,
	isList,
} from '~/utilities/typeCheck';



export type Criteria = {
	filterDefinition: GraphQL.PageSegment['filterDefinition'],
	sizeLimit: GraphQL.PageSegment['sizeLimit'],
};

export type Identifier = {
	color: GraphQL.PageSegment['color'],
	icon: {
		name: string,
	} | null,
	label: GraphQL.PageSegment['label'],
	shortcode: GraphQL.PageSegment['shortcode'],
};

export type SegmentDefinition = {
	color: GraphQL.PageSegment['color'],
	filterDefinition: GraphQL.PageSegment['filterDefinition'],
	icon: {
		files: ReadonlyArray<{
			scaleFactor: GraphQL.ImageSizeVersion['scaleFactor'],
			url: GraphQL.ImageSizeVersion['url'],
		}>,
		name: GraphQL.PageSegmentIcon['name'],
	} | null,
	id: GraphQL.PageSegment['id'],
	isBeingReevaluated: GraphQL.PageSegment['isBeingReevaluated'],
	isManaged: GraphQL.PageSegment['isManaged'],
	label: GraphQL.PageSegment['label'],
	name: GraphQL.PageSegment['name'],
	shortcode: GraphQL.PageSegment['shortcode'],
	sizeLimit: GraphQL.PageSegment['sizeLimit'],
};



export const COLORS = [
	'#72c035',
	'#3cc2a9',
	'#56a3f9',
	'#bf86d5',
	'#ff65b3',
	'#ff5858',
	'#ff8627',
	'#d5b833',
	'#9ea6af',
];



export const COLUMNS_ALLOWED_FOR_SIZE_LIMIT: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.AaAverageTimeSpentOnSite,
	CK.PagesCommonColumn.AaBounceRate,
	CK.PagesCommonColumn.AaPageViews,
	CK.PagesCommonColumn.AaRevenue,
	CK.PagesCommonColumn.AaUniqueVisitors,
	CK.PagesCommonColumn.Health,
	CK.PagesCommonColumn.Relevance,
	CK.PagesCommonColumn.TimeFound,
	CK.PagesCommonColumn.GaActiveUsers,
	CK.PagesCommonColumn.GaAverageEngagementTime,
	CK.PagesCommonColumn.GaAverageTime,
	CK.PagesCommonColumn.GaBounceRate,
	CK.PagesCommonColumn.GaEngagementRate,
	CK.PagesCommonColumn.GaPageValue,
	CK.PagesCommonColumn.GaPageViews,
	CK.PagesCommonColumn.GaScreenPageViews,
	CK.PagesCommonColumn.GaUniquePageViews,
	CK.PagesCommonColumn.GaActiveUsers,
	CK.PagesCommonColumn.GscClicks,
	CK.PagesCommonColumn.GscCtr,
	CK.PagesCommonColumn.GscImpressions,
	CK.PagesCommonColumn.GscPosition,
	CK.PagesCommonColumn.LfaBingDesktopFrequency,
	CK.PagesCommonColumn.LfaBingDesktopLastVisit,
	CK.PagesCommonColumn.LfaBingFrequency,
	CK.PagesCommonColumn.LfaBingLastVisit,
	CK.PagesCommonColumn.LfaBingMobileFrequency,
	CK.PagesCommonColumn.LfaBingMobileLastVisit,
	CK.PagesCommonColumn.LfaGoogleDesktopFrequency,
	CK.PagesCommonColumn.LfaGoogleDesktopLastVisit,
	CK.PagesCommonColumn.LfaGoogleFrequency,
	CK.PagesCommonColumn.LfaGoogleLastVisit,
	CK.PagesCommonColumn.LfaGoogleMobileFrequency,
	CK.PagesCommonColumn.LfaGoogleMobileLastVisit,
	CK.PagesCommonColumn.LighthouseCumulativeLayoutShift,
	CK.PagesCommonColumn.LighthouseFirstContentfulPaint,
	CK.PagesCommonColumn.LighthouseLargestContentfulPaint,
	CK.PagesCommonColumn.LighthousePerformance,
	CK.PagesCommonColumn.LighthouseSpeedIndex,
	CK.PagesCommonColumn.LighthouseTimeToInteractive,
	CK.PagesCommonColumn.LighthouseTotalBlockingTime,
	CK.PagesCommonColumn.NumberOfHreflangs,
	CK.PagesCommonColumn.NumberOfIncomingInternalCanonicals,
	CK.PagesCommonColumn.NumberOfIncomingInternalLinks,
	CK.PagesCommonColumn.NumberOfIncomingInternalRedirects,
	CK.PagesCommonColumn.NumberOfOutgoingExternalLinks,
	CK.PagesCommonColumn.NumberOfOutgoingInternalLinks,
	CK.PagesCommonColumn.SchemaOrgNumberOfTypes,
	CK.PagesCommonColumn.TimeServerResponse,
	CK.PagesCommonColumn.UrlDepth,
];

export enum SegmentDepends {
	OnLinkColumns = 'depends_on_link_columns',
	OnSchemaOrgColumns = 'depends_on_schema_org_columns',
	OnTimeServerResponseColumn = 'depends_on_time_server_response_column',
	OnColumnWithoutChangeTracking = 'depends_on_column_without_change_tracking',
}



export function normalizeFilter(filter) {
	return filter.map((value) => {
		if (isList(value) && typeof value.first() === 'boolean') {
			if (value.size === 1) {
				return value.first();
			} else if (value.size === 2) {
				return null;
			}
		} else if (isArray(value) && typeof value[0] === 'boolean') {
			if (value.length === 1) {
				return value[0];
			} else if (value.length === 2) {
				return null;
			}
		}

		return value;
	}).filter((value) => value !== null);
}



export function dependsOnlyOnColumns(
	segments: ReadonlyArray<SegmentDefinition>,
	segment: SegmentDefinition,
	columnValidator: (columnName: CK.PagesColumn) => boolean,
): boolean {
	const filter = segment.filterDefinition;

	if (
		segment.sizeLimit !== null
		&& columnValidator(segment.sizeLimit.field as CK.PagesColumn) === false
	) {
		return false;
	}

	return Object.keys(segment.filterDefinition).every((key) => {
		if (columnValidator(key as CK.PagesColumn) === false) {
			return false;
		}

		if (key === 'segments') {
			return filterSegmentDefinitionsByNames(
				segments,
				[
					...filter.segments.included_in,
					...filter.segments.not_included_in,
				],
			).every(
				(segmentDefinition) => dependsOnlyOnColumns(
					segments,
					segmentDefinition,
					columnValidator,
				),
			);
		}

		return true;
	});
}



export function dependsOnColumns(
	segments: ReadonlyArray<SegmentDefinition>,
	segment: SegmentDefinition,
	columns: ReadonlyArray<string>,
): boolean {
	const filter = segment.filterDefinition;

	if (columns.some((column) => column in filter)) {
		return true;
	}

	const sizeLimitField = segment.sizeLimit?.field;
	if (sizeLimitField && columns.includes(sizeLimitField)) {
		return true;
	}

	if ('segments' in filter) {
		const segmentNames = [...new Set([
			...filter.segments.included_in,
			...filter.segments.not_included_in,
		])] as ReadonlyArray<string>;

		return segmentNames.some((segmentName) => {
			const segment = getSegmentDefinitionByName(segments, segmentName);

			if (segment === null) {
				return false;
			}

			return dependsOnColumns(
				segments,
				segment,
				columns,
			);
		});
	}

	return false;
}



export function doesColumnRemovalResultInDuplicateSegments(
	column: CK.PagesColumn,
	segments: ReadonlyArray<SegmentDefinition>,
) {
	const segmentsWithColumn: Array<SegmentDefinition> = [];
	const otherSegments: Array<SegmentDefinition> = [];

	segments.forEach((segment) => {
		const segmentDependsOnColumn = dependsOnColumns(
			segments,
			segment,
			[column],
		);

		if (segmentDependsOnColumn) {
			segmentsWithColumn.push(segment);
		} else {
			otherSegments.push(segment);
		}
	});

	const segmentsWithColumnRemoved = segmentsWithColumn.map((segment) => {
		segment = {
			...segment,
			filterDefinition: { ...segment.filterDefinition },
			sizeLimit: segment.sizeLimit !== null
				? { ...segment.sizeLimit }
				: null,
		};

		delete segment.filterDefinition[column];

		if (segment.sizeLimit?.field === column) {
			segment = {
				...segment,
				sizeLimit: null,
			};
		}

		return segment;
	});

	const segmentsToCheck = [
		...segmentsWithColumnRemoved,
		...otherSegments,
	];

	return segmentsToCheck.some((segment) => {
		return segmentsWithColumnRemoved.some((segmentWithoutColumn) => {
			if (segment.name === segmentWithoutColumn.name) {
				return false;
			}

			return isCriteriaSame(segment, segmentWithoutColumn);
		});
	});
}



export function dependsOnColumnsWithoutChangeTracking(
	segments: ReadonlyArray<SegmentDefinition>,
	segment: SegmentDefinition,
): SegmentDepends | null {
	const depends = dependsOnColumns(segments, segment, ColumnsWithoutChangeTracking);

	if (!depends) {
		return null;
	}

	if (dependsOnColumns(
		segments,
		segment,
		[
			CK.PagesCommonColumn.NumberOfIncomingInternalLinks,
			CK.PagesCommonColumn.NumberOfIncomingInternalCanonicals,
			CK.PagesCommonColumn.NumberOfIncomingInternalRedirects,
			CK.PagesCommonColumn.NumberOfOutgoingExternalLinks,
			CK.PagesCommonColumn.NumberOfOutgoingInternalLinks,
		],
	)) {
		return SegmentDepends.OnLinkColumns;
	}

	if (dependsOnColumns(
		segments,
		segment,
		[
			CK.PagesCommonColumn.SchemaOrgNumberOfTypes,
			CK.PagesCommonColumn.SchemaOrgTypes,
		],
	)) {
		return SegmentDepends.OnSchemaOrgColumns;
	}

	if (dependsOnColumns(
		segments,
		segment,
		[
			CK.PagesCommonColumn.TimeServerResponse,
		],
	)) {
		return SegmentDepends.OnTimeServerResponseColumn;
	}

	return SegmentDepends.OnColumnWithoutChangeTracking;
}



export function containsCircularReference(
	segments: ReadonlyArray<{
		name: GraphQL.PageSegment['name'],
		filterDefinition: GraphQL.PageSegment['filterDefinition'],
		sizeLimit: GraphQL.PageSegment['sizeLimit'],
	}>,
	segment: {
		name: GraphQL.PageSegment['name'],
		filterDefinition: GraphQL.PageSegment['filterDefinition'],
		sizeLimit: GraphQL.PageSegment['sizeLimit'],
	},
	hierarchy: Array<string> = [],
) {
	if (('segments' in segment.filterDefinition) === false) {
		return false;
	}

	const hasCircularReferenceViaIncludedIn = segment
		.filterDefinition
		.segments
		.included_in
		.filter((segmentName) => {
			if (hierarchy.includes(segmentName)) {
				return true;
			}

			hierarchy.push(segmentName);

			const otherSegment = getSegmentDefinitionByName(segments, segmentName);

			if (otherSegment === null) {
				return false;
			}

			return containsCircularReference(
				segments,
				otherSegment,
				hierarchy,
			);
		})
		.length > 0;

	if (hasCircularReferenceViaIncludedIn) {
		return true;
	}

	const hasCircularReferenceViaNotIncludedIn = segment
		.filterDefinition
		.segments
		.not_included_in
		.filter((segmentName) => {
			if (hierarchy.includes(segmentName)) {
				return true;
			}

			hierarchy.push(segmentName);

			const otherSegment = getSegmentDefinitionByName(segments, segmentName);

			if (otherSegment === null) {
				return false;
			}

			return containsCircularReference(
				segments,
				otherSegment,
				hierarchy,
			);
		})
		.length > 0;

	if (hasCircularReferenceViaNotIncludedIn) {
		return true;
	}

	return false;
}



export function denormalizeFilter(filter) {
	return filter.map((value) => {
		if (typeof value === 'boolean') {
			return Immutable.List([value]);
		}

		return value;
	});
}



export function getSegmentDefinitionByCriteria(
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	criteria: Criteria,
) {
	return segmentDefinitions.find(
		(segmentDefinition: SegmentDefinition) => {
			return isCriteriaSame(segmentDefinition, criteria);
		},
	) ?? null;
}



export function getSegmentDefinitionByIdentifier(
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	identifier: Identifier,
) {
	return segmentDefinitions.find(
		(segmentDefinition: SegmentDefinition) => {
			return isIdentifierSame(segmentDefinition, identifier);
		},
	) ?? null;
}



export function getSegmentDefinitionByName<T extends { name: GraphQL.PageSegment['name'] }>(
	segmentDefinitions: ReadonlyArray<T>,
	segmentName: string,
) {
	return segmentDefinitions.find(
		(segmentDefinition) => segmentDefinition.name === segmentName,
	) ?? null;
}



export function filterSegmentDefinitionsByNames(
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	segmentNames: ReadonlyArray<string>,
) {
	if (segmentDefinitions.length === 0) {
		return segmentDefinitions;
	}

	return segmentDefinitions.filter(
		(segmentDefinition) => segmentNames.includes(segmentDefinition.name),
	);
}



export function isSegmentChanged({
	editedSegment,
	updatedColor,
	updatedFilterDefinition,
	updatedIconName,
	updatedLabel,
	updatedShortcode,
	updatedSizeLimit,
}: {
	editedSegment: SegmentDefinition,
	updatedColor: string,
	updatedFilterDefinition: CK.FilterDefinition,
	updatedIconName: string | null,
	updatedLabel: string,
	updatedShortcode: string | null,
	updatedSizeLimit: GraphQL.PageSegment['sizeLimit'],
}) {
	if (editedSegment.color !== updatedColor) {
		return true;
	}

	if (editedSegment.label !== updatedLabel) {
		return true;
	}

	if ((editedSegment.icon?.name ?? null) !== updatedIconName) {
		return true;
	}

	if (editedSegment.shortcode !== updatedShortcode) {
		return true;
	}

	return isCriteriaSame(
		editedSegment,
		{
			filterDefinition: updatedFilterDefinition,
			sizeLimit: updatedSizeLimit,
		},
	) === false;
}



export function isSegmentInvalidBecauseOfHealth(
	segment: SegmentDefinition,
	segments: ReadonlyArray<SegmentDefinition>,
	updatedFilterDefinition: GraphQL.PageSegment['filterDefinition'],
	updatedSizeLimit: GraphQL.PageSegment['sizeLimit'],
	isPageSegmentUsedForIgnoring: (segmentDefinition: SegmentDefinition) => boolean,
) {
	segments = segments.map(
		(segmentDefinition) => {
			if (segmentDefinition.id !== segment.id) {
				return segmentDefinition;
			}

			return {
				...segmentDefinition,
				filterDefinition: updatedFilterDefinition,
				sizeLimit: updatedSizeLimit,
			};
		},
	);

	return segments.some((segment) => {
		const isIgnoringActive = isPageSegmentUsedForIgnoring(segment);

		if (!isIgnoringActive) {
			return false;
		}

		return dependsOnColumns(
			segments,
			segment,
			[CK.PagesCommonColumn.Health],
		);
	});
}



export function isCriteriaSame(criteriaA: Criteria, criteriaB: Criteria) {
	return (
		isFilterSame(criteriaA.filterDefinition, criteriaB.filterDefinition)
		&& isSizeLimitSame(criteriaA.sizeLimit, criteriaB.sizeLimit)
	);
}

function isFilterSame(
	filterDefinitionA: GraphQL.PageSegment['filterDefinition'],
	filterDefinitionB: GraphQL.PageSegment['filterDefinition'],
) {
	const filterAEntries = Object.entries(filterDefinitionA);
	const filterBEntries = Object.entries(filterDefinitionB);

	if (
		filterAEntries.length === 1
		&& filterBEntries.length === 1
		&& 'static' in filterDefinitionA
		&& 'static' in filterDefinitionB
	) {
		return false;
	}

	return (
		filterAEntries.length === filterBEntries.length
		&& filterAEntries.every(
			([field, value]) => isEqual(value, filterDefinitionB[field]),
		)
	);
}

function isSizeLimitSame(
	sizeLimitA: GraphQL.PageSegment['sizeLimit'],
	sizeLimitB: GraphQL.PageSegment['sizeLimit'],
) {
	if (sizeLimitA === null && sizeLimitB === null) {
		return true;
	}

	if (sizeLimitA === null || sizeLimitB === null) {
		return false;
	}

	if (
		sizeLimitA.field !== sizeLimitB.field
		|| sizeLimitA.order !== sizeLimitB.order
	) {
		return false;
	}

	if (sizeLimitA.limit.type !== sizeLimitB.limit.type) {
		return false;
	}

	const type = sizeLimitA.limit.type;

	return sizeLimitA.limit[type] === sizeLimitB.limit[type];
}

export function isIdentifierSame(
	identifierA: Identifier,
	identifierB: Identifier,
) {
	return (
		identifierA.color === identifierB.color
		&& identifierA.icon?.name === identifierB.icon?.name
		&& identifierA.label === identifierB.label
		&& identifierA.shortcode === identifierB.shortcode
	);
}



export function isSegmentStatic(segmentDefinition: SegmentDefinition) {
	return 'static' in segmentDefinition.filterDefinition;
}



export function listSegmentDependencies(filterDefinition: CK.FilterDefinition) {
	let result: Array<string> = [];

	if ('segments' in filterDefinition) {
		filterDefinition.segments.included_in.forEach((segmentName) => {
			result = [
				...result,
				segmentName,
			];
		});

		filterDefinition.segments.not_included_in.forEach((segmentName) => {
			result = [
				...result,
				segmentName,
			];
		});
	}

	return [... new Set(result)];
}



export enum SegmentsComparisonVerdict {
	DependsOnCustomElement,
	DependsOnOtherSegments,
	IsStatic,
	NotPresent,
	PresentIdentical,
	PresentInDualConflict,
	PresentManaged,
	PresentWithSameCriteria,
	PresentWithSameIdentifier,
}

type SegmentsComparison = {
	verdict:
		| SegmentsComparisonVerdict.DependsOnCustomElement
		| SegmentsComparisonVerdict.IsStatic
		| SegmentsComparisonVerdict.NotPresent,
} | {
	similarSegments: ReadonlyArray<SegmentDefinition>,
	verdict:
		| SegmentsComparisonVerdict.PresentInDualConflict,
} | {
	similarSegment: SegmentDefinition,
	similarSegments: ReadonlyArray<SegmentDefinition>,
	verdict:
		| SegmentsComparisonVerdict.PresentIdentical
		| SegmentsComparisonVerdict.PresentManaged
		| SegmentsComparisonVerdict.PresentWithSameCriteria
		| SegmentsComparisonVerdict.PresentWithSameIdentifier,
} | {
	neededSegments: ReadonlyArray<SegmentDefinition>,
	verdict: SegmentsComparisonVerdict.DependsOnOtherSegments,
};

export function compareSegments(
	sourceSegments: ReadonlyArray<SegmentDefinition>,
	targetSegments: ReadonlyArray<SegmentDefinition>,
	targetCustomElements,
	importedSegments: ReadonlyArray<SegmentDefinition>,
) {
	const comparison: Record<string, SegmentsComparison> = {};

	sourceSegments.forEach((sourceSegment) => {
		let verdict = SegmentsComparisonVerdict.NotPresent;

		if ('static' in sourceSegment.filterDefinition) {
			comparison[sourceSegment.name] = {
				verdict: SegmentsComparisonVerdict.IsStatic,
			};

			return;
		}

		const similarSegments = targetSegments.filter((segment) => {
			return (
				isIdentifierSame(segment, sourceSegment)
				|| isCriteriaSame(segment, sourceSegment)
			);
		});

		if (similarSegments.length > 1) {
			verdict = SegmentsComparisonVerdict.PresentInDualConflict;
		} else if (similarSegments.length === 1) {
			const similarSegment = getArrayItemAtSafeIndex(similarSegments, 0);

			const sameCriteria = isCriteriaSame(similarSegment, sourceSegment);
			const sameIdentifier = isIdentifierSame(similarSegment, sourceSegment);

			if (sameCriteria && sameIdentifier) {
				verdict = SegmentsComparisonVerdict.PresentIdentical;
			} else if (sameCriteria) {
				verdict = SegmentsComparisonVerdict.PresentWithSameCriteria;
			} else if (sameIdentifier) {
				if (similarSegment.isManaged) {
					verdict = SegmentsComparisonVerdict.PresentManaged;
				} else {
					verdict = SegmentsComparisonVerdict.PresentWithSameIdentifier;
				}
			}

			comparison[sourceSegment.name] = {
				similarSegment,
				similarSegments,
				verdict,
			};

			return;
		}

		const neededSegments: Array<SegmentDefinition> = [];

		if (verdict === SegmentsComparisonVerdict.NotPresent) {
			const segmentDependencies = listSegmentDependencies(sourceSegment.filterDefinition);

			segmentDependencies.forEach((segmentName) => {
				const dependency = getSegmentDefinitionByName(sourceSegments, segmentName);

				if (dependency === null) {
					return;
				}

				const equivalentSegment = targetSegments.find(
					(segment) => isCriteriaSame(segment, dependency),
				);

				if (!equivalentSegment) {
					const isDependencyImported = getSegmentDefinitionByName(importedSegments, segmentName);

					if (!isDependencyImported) {
						neededSegments.push(dependency);
					}
				}
			});

			if (neededSegments.length > 0) {
				verdict = SegmentsComparisonVerdict.DependsOnOtherSegments;
			}
		}

		const missingCustomElement = Object.entries(sourceSegment.filterDefinition).some(([field]) => {
			return (
				field.indexOf('custom_') === 0
				&& targetCustomElements.getByName(field.substr(7)) === null
			);
		});

		if (missingCustomElement) {
			verdict = SegmentsComparisonVerdict.DependsOnCustomElement;
		}

		comparison[sourceSegment.name] = {
			neededSegments,
			similarSegments,
			verdict,
		};
	});

	return (segmentName: string) => {
		const result = comparison[segmentName];

		if (result === undefined) {
			throw new Error(`Comparison for segment '${segmentName}' isn't calculated`);
		}

		return result;
	};
}



function findSegmentIdentifier(subject: GraphQL.SegmentIdentifier) {
	return (segmentIdentifier: GraphQL.SegmentIdentifier) => {
		return (
			subject.color === segmentIdentifier.color
			&& subject.iconName === segmentIdentifier.iconName
			&& subject.label === segmentIdentifier.label
			&& subject.shortcode === segmentIdentifier.shortcode
		);
	};
}



export function modifySegmentsDisplayOrder(
	subject: GraphQL.SegmentIdentifier,
	userSegmentsDisplayOrder: ReadonlyArray<GraphQL.SegmentIdentifier>,
	websiteSegmentsDisplayOrder: ReadonlyArray<GraphQL.SegmentIdentifier>,
) {
	let nextSegmentDisplayOrder = [...userSegmentsDisplayOrder];

	const segmentIndex = websiteSegmentsDisplayOrder.findIndex(findSegmentIdentifier(subject));

	let previousIndexInDisplayOrder = -1;

	// To properly place the `subject` in the `segmentsDisplayOrder` we need to ensure all segments in
	// `websiteSegmentsDisplayOrder` preceeding the `subject` are present in
	// `userSegmentsDisplayOrder` in the same order.
	for (let index = 0; index < segmentIndex; index++) {
		const segmentIdentifier = getArrayItemAtSafeIndex(websiteSegmentsDisplayOrder, index);

		const currentIndexInDisplayOrder = nextSegmentDisplayOrder.findIndex(findSegmentIdentifier(segmentIdentifier));

		if (currentIndexInDisplayOrder === -1) {
			// If the current segment is not present in the `userSegmentsDisplayOrder`, add it to the
			// beginning.
			nextSegmentDisplayOrder.splice(previousIndexInDisplayOrder + 1, 0, segmentIdentifier);

			previousIndexInDisplayOrder = previousIndexInDisplayOrder + 1;
		} else if (currentIndexInDisplayOrder < previousIndexInDisplayOrder) {
			// If the current segment is present but its placed preceeding the previous segment, move it
			// down to just after the previous segment.
			nextSegmentDisplayOrder = arrayMove(
				nextSegmentDisplayOrder,
				currentIndexInDisplayOrder,
				previousIndexInDisplayOrder + 1,
			);

			previousIndexInDisplayOrder = currentIndexInDisplayOrder;
		} else {
			// Otherwise continue
			previousIndexInDisplayOrder = currentIndexInDisplayOrder;
		}
	}

	// Finally we place the subject directly after the last preceeding segment.
	const subjectIndexInDisplayOrder = nextSegmentDisplayOrder.findIndex(findSegmentIdentifier(subject));

	if (subjectIndexInDisplayOrder === -1) {
		nextSegmentDisplayOrder.splice(previousIndexInDisplayOrder + 1, 0, subject);
	} else if (subjectIndexInDisplayOrder < previousIndexInDisplayOrder) {
		nextSegmentDisplayOrder = arrayMove(
			nextSegmentDisplayOrder,
			subjectIndexInDisplayOrder,
			previousIndexInDisplayOrder,
		);
	} else {
		nextSegmentDisplayOrder = arrayMove(
			nextSegmentDisplayOrder,
			subjectIndexInDisplayOrder,
			previousIndexInDisplayOrder + 1,
		);
	}

	return nextSegmentDisplayOrder;
}



export enum SegmentDefinitionValidityVerdict {
	InvalidBecauseConflictingCriteria,
	InvalidBecauseConflictingIdentifier,
	InvalidBecauseEmpty,
	InvalidBecauseHealth,
	InvalidBecauseUnchanged,
	Valid,
}

export type SegmentDefinitionValidity = {
	verdict:
		| SegmentDefinitionValidityVerdict.InvalidBecauseEmpty
		| SegmentDefinitionValidityVerdict.InvalidBecauseHealth
		| SegmentDefinitionValidityVerdict.InvalidBecauseUnchanged
		| SegmentDefinitionValidityVerdict.Valid,
} | {
	segmentWithSameCriteria: SegmentDefinition,
	verdict: SegmentDefinitionValidityVerdict.InvalidBecauseConflictingCriteria,
} | {
	segmentWithSameIdentifier: SegmentDefinition,
	verdict: SegmentDefinitionValidityVerdict.InvalidBecauseConflictingIdentifier,
};

export function getSegmentDefinitionValidity({
	currentFilterDefinition,
	currentSizeLimit,
	editedSegment,
	segmentColor,
	segmentDefinitions,
	segmentIconName,
	segmentLabel,
	segmentShortcode,
	isPageSegmentUsedForIgnoring,
}: {
	currentFilterDefinition: GraphQL.PageSegment['filterDefinition'],
	currentSizeLimit: GraphQL.PageSegment['sizeLimit'] | null,
	editedSegment: SegmentDefinition | null,
	segmentColor: string | null,
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	segmentIconName: string | null,
	segmentLabel: string | null,
	segmentShortcode: string | null,
	isPageSegmentUsedForIgnoring: (segmentDefinition: SegmentDefinition) => boolean,
}): SegmentDefinitionValidity {
	const otherSegments = segmentDefinitions.filter((segment) => segment !== editedSegment);

	if (Object.entries(currentFilterDefinition).length === 0 && currentSizeLimit === null) {
		return {
			verdict: SegmentDefinitionValidityVerdict.InvalidBecauseEmpty,
		};
	}

	const segmentWithSameCriteria = getSegmentDefinitionByCriteria(
		otherSegments,
		{
			filterDefinition: currentFilterDefinition,
			sizeLimit: currentSizeLimit,
		},
	);

	if (segmentWithSameCriteria !== null) {
		return {
			segmentWithSameCriteria,
			verdict: SegmentDefinitionValidityVerdict.InvalidBecauseConflictingCriteria,
		};
	}

	if (segmentColor !== null && segmentLabel !== null) {
		const segmentWithSameIdentifier = otherSegments.find(
			(segmentDefinition) => isIdentifierSame(
				segmentDefinition,
				{
					color: segmentColor,
					icon: segmentIconName !== null ? {
						name: segmentIconName,
					} : null,
					label: segmentLabel,
					shortcode: segmentShortcode,
				},
			),
		);

		if (segmentWithSameIdentifier) {
			return {
				segmentWithSameIdentifier,
				verdict: SegmentDefinitionValidityVerdict.InvalidBecauseConflictingIdentifier,
			};
		}
	}

	if (editedSegment !== null) {
		const segmentHasChanged = isSegmentChanged({
			editedSegment,
			updatedColor: segmentColor ?? editedSegment.color,
			updatedFilterDefinition: currentFilterDefinition,
			updatedIconName: segmentIconName,
			updatedLabel: segmentLabel ?? editedSegment.label,
			updatedShortcode: segmentShortcode,
			updatedSizeLimit: currentSizeLimit,
		});

		if (!segmentHasChanged) {
			return {
				verdict: SegmentDefinitionValidityVerdict.InvalidBecauseUnchanged,
			};
		}

		const invalidBecauseOfHealth = isSegmentInvalidBecauseOfHealth(
			editedSegment,
			segmentDefinitions,
			currentFilterDefinition,
			currentSizeLimit,
			isPageSegmentUsedForIgnoring,
		);

		if (invalidBecauseOfHealth) {
			return {
				verdict: SegmentDefinitionValidityVerdict.InvalidBecauseHealth,
			};
		}
	}

	return {
		verdict: SegmentDefinitionValidityVerdict.Valid,
	};
}
