import classNames from 'classnames';
import React from 'react';

import {
	type DraggableProps,
} from '~/components/patterns/tables/newTable/NewTable';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



export enum NewTableRowType {
	Body = 'body',
	Header = 'header',
}



type ChildProps = {
	hovered: boolean,
};

type Props = {
	children?: RenderProp<ChildProps>,
	draggableProps?: DraggableProps,
	isDragging?: boolean,
	isEven?: boolean,
	onClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void,
	style?: React.CSSProperties,
	type?: NewTableRowType,
};

const NewTableRow = React.forwardRef<HTMLTableRowElement, Props>((props, ref) => {
	const {
		children,
		draggableProps,
		isDragging = false,
		isEven = false,
		onClick = undefined,
		style,
		type = NewTableRowType.Body,
	} = props;

	const rowClasses = classNames({
		'new-table__row': true,
		'new-table__row--is-dragging': isDragging === true,
		'new-table__row--is-clickable': onClick !== undefined,
		'new-table__row--even': isEven === true,
		'new-table__row--odd': isEven === false,
		[`new-table__row--${type}`]: true,
	});

	const [hovered, setHovered] = React.useState(false);

	return (
		<tr
			{...draggableProps}
			className={rowClasses}
			onClick={onClick}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			ref={ref}
			style={style}
		>
			{renderProp(children, { hovered })}
		</tr>
	);
});



export default NewTableRow;
