import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StaticText from '~/components/atoms/forms/components/StaticText';



const messages = defineMessages({
	attn: {
		id: 'ui.invoiceDetails.customInvoiceFields.attn',
	},
	customerReference: {
		id: 'ui.invoiceDetails.customInvoiceFields.reference',
	},
	purchaseOrderNumber: {
		id: 'ui.invoiceDetails.customInvoiceFields.poNumber',
	},
	supplierNumber: {
		id: 'ui.invoiceDetails.customInvoiceFields.supplierNumber',
	},
});



type Props = {
	extraInvoiceDetails: GraphQL.BillingExtraInvoiceDetails | null,
};

const ExtraInvoiceDetailsOverview: React.FC<Props> = (props) => {
	const {
		extraInvoiceDetails,
	} = props;

	return (
		<FormRows>
			<FormRow
				label={(
					<FormattedMessage {...messages.attn} />
				)}
			>
				<StaticText focusTarget="attn">
					{extraInvoiceDetails?.attn || '-'}
				</StaticText>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.purchaseOrderNumber} />
				)}
			>
				<StaticText focusTarget="purchaseOrderNumber">
					{extraInvoiceDetails?.purchaseOrderNumber || '-'}
				</StaticText>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.supplierNumber} />
				)}
			>
				<StaticText focusTarget="supplierNumber">
					{extraInvoiceDetails?.supplierNumber || '-'}
				</StaticText>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.customerReference} />
				)}
			>
				<StaticText focusTarget="customerReference">
					{extraInvoiceDetails?.customerReference || '-'}
				</StaticText>
			</FormRow>
		</FormRows>
	);
};



export default ExtraInvoiceDetailsOverview;
