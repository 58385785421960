import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import FilterFieldLayout from '~/components/patterns/filtering/FilterFieldLayout';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import IssuesFilterSection from '~/components/atoms/filters/IssuesFilterSection';
import MultiselectField, {
	MultiselectFieldSize,
} from '~/components/atoms/forms/components/MultiselectField';
import SegmentSelectField, {
	SegmentSelectFieldSize,
} from '~/components/atoms/forms/components/SegmentSelectField';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';

import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	updateIssuesFilter,
} from '~/actions/issues';

import {
	ISSUE_PSEUDO_STATE_IGNORED,
	ISSUE_STATE_CLOSED,
	ISSUE_STATE_NOT_APPLICABLE,
	ISSUE_STATE_NOT_REQUIRED,
	ISSUE_STATE_OPEN,
	ISSUE_STATE_UNKNOWN,
} from '~/model/issues';

import {
	issuesFilterSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	segmentFieldLabel: {
		id: 'ui.issuesOverview.filter.segment.label',
	},
	stateFieldLabel: {
		id: 'ui.issuesOverview.filter.state.label',
	},
});

const stateMessages = defineMessages({
	[ISSUE_STATE_CLOSED]: {
		id: 'ui.issuesOverview.filter.state.closed',
	},
	[ISSUE_PSEUDO_STATE_IGNORED]: {
		id: 'ui.issuesOverview.filter.state.ignored',
	},
	[ISSUE_STATE_NOT_APPLICABLE]: {
		id: 'ui.issuesOverview.filter.state.notApplicable',
	},
	[ISSUE_STATE_NOT_REQUIRED]: {
		id: 'ui.issuesOverview.filter.state.notRequired',
	},
	[ISSUE_STATE_OPEN]: {
		id: 'ui.issuesOverview.filter.state.open',
	},
	[ISSUE_STATE_UNKNOWN]: {
		id: 'ui.issuesOverview.filter.state.unknown',
	},
});



type Props = {
	inline: boolean,
};

const IssuesOverviewFilter: React.FC<Props> = (props) => {
	const {
		inline,
	} = props;

	const websiteId = useWebsiteId();

	const dispatch = useDispatch();
	const issuesFilter = useSelector(issuesFilterSelector);
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const viewportType = useViewportType();

	const segmentFieldRef = React.useRef<any>();
	const stateFieldRef = React.useRef<any>();

	React.useEffect(
		() => {
			issuesFilter.forEach((value, field) => {
				if (field === 'segment') {
					segmentFieldRef.current.changeValue(value, false);
				} else {
					stateFieldRef.current.changeValue((value && value.toJS) ? value.toJS() : value, false);
				}
			});
		},
		[
			issuesFilter,
			segmentFieldRef,
			stateFieldRef,
		],
	);

	const stateOptions = React.useMemo(
		() => {
			return [
				ISSUE_STATE_OPEN,
				ISSUE_STATE_CLOSED,
				ISSUE_PSEUDO_STATE_IGNORED,
				ISSUE_STATE_UNKNOWN,
				ISSUE_STATE_NOT_REQUIRED,
				ISSUE_STATE_NOT_APPLICABLE,
			].map((state) => {
				return {
					name: state,
					title: (
						<FormattedMessage {...stateMessages[state]} />
					),
				};
			});
		},
		[],
	);

	const handleFilterChange = React.useCallback(
		(name, value) => {
			dispatch(
				updateIssuesFilter(
					issuesFilter.set(name, value),
				),
			);
		},
		[
			dispatch,
			issuesFilter,
		],
	);

	function renderField(field, labelKey, fieldName) {
		if (viewportType.isSmall) {
			return (
				<FormRow
					label={(
						<FormattedMessage {...labelKey} />
					)}
					labelIcon={fieldName === 'segment' && (
						<BasicIcon
							size={14}
							type={BasicIconType.Segment}
						/>
					)}
				>
					{field}
				</FormRow>
			);
		}

		return (
			<FilterFieldLayout
				field={field}
				label={(
					<TableLabel
						label={(
							<FormattedMessage {...labelKey} />
						)}
						labelIcon={fieldName === 'segment' && (
							<BasicIcon
								size={14}
								type={BasicIconType.Segment}
							/>
						)}
					/>
				)}
			/>
		);
	}

	const defaultValues = {
		segment: issuesFilter.get('segment'),
		state: issuesFilter.get('state'),
	};

	let componentWidth = 280;
	let dropdownWidth = 280;

	if (!viewportType.isSmall && inline) {
		dropdownWidth = 250;
		componentWidth = 190;
	}

	const segmentField = renderField(
		<SegmentSelectField
			dropdownWidth={dropdownWidth}
			name="segment"
			ref={segmentFieldRef}
			segments={segmentDefinitions.listAll()}
			size={viewportType.isSmall ? SegmentSelectFieldSize.Default : SegmentSelectFieldSize.Small}
			width={componentWidth}
		/>,
		messages.segmentFieldLabel,
		'segment',
	);

	const stateField = renderField(
		<MultiselectField
			dropdownWidth={dropdownWidth}
			isOnlyLinkVisible={true}
			name="state"
			options={stateOptions}
			ref={stateFieldRef}
			size={viewportType.isSmall ? MultiselectFieldSize.Default : MultiselectFieldSize.Small}
			width={componentWidth}
		/>,
		messages.stateFieldLabel,
		'state',
	);

	if (viewportType.isSmall) {
		return (
			<Form
				defaultValues={defaultValues}
				onChangeCallback={handleFilterChange}
			>
				{stateField}
				{segmentField}
			</Form>
		);
	}

	return (
		<Form
			defaultValues={defaultValues}
			onChangeCallback={handleFilterChange}
		>
			<IssuesFilterSection inline={inline}>
				{stateField}
				{segmentField}
			</IssuesFilterSection>
		</Form>
	);
};



export default IssuesOverviewFilter;
