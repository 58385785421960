import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import CompareModePickerBuilder from '~/components/patterns/forms/fields/CompareModePicker';

import {
	useSourceModePickerQuery,
	useUpdateSourceModeMutation,
} from './SourceModePicker.gql';



const messages = defineMessages({
	compare: {
		id: 'ui.pageDetail.sourceMode.compare',
	},
	primary: {
		id: 'ui.pageDetail.sourceMode.primary',
	},
});



const SourceModePicker: React.FC = () => {
	const { data } = useSourceModePickerQuery();
	const [updateSourceMode] = useUpdateSourceModeMutation();

	const kingdomAdmin = data?.authenticatedSession?.kingdomAdmin;
	const user = data?.authenticatedSession?.isImpersonated
		? data.authenticatedSession.kingdomAdmin
		: data?.authenticatedSession?.user;

	const selectedSourceMode = user?.personalTweaks?.pageDetailSourceMode ?? GraphQL.PageDetailSourceMode.Primary;

	const options = [
		{
			label: <FormattedMessage {...messages.primary} />,
			name: GraphQL.PageDetailSourceMode.Primary,
			selected: selectedSourceMode === GraphQL.PageDetailSourceMode.Primary,
		},
		{
			label: <FormattedMessage {...messages.compare} />,
			name: GraphQL.PageDetailSourceMode.Compare,
			selected: selectedSourceMode === GraphQL.PageDetailSourceMode.Compare,
		},
	];



	function update(nextSourceMode: GraphQL.PageDetailSourceMode) {
		if (user) {
			updateSourceMode({
				variables: {
					email: user.email,
					mode: nextSourceMode,
				},
				optimisticResponse: {
					UpdateUserPageDetailSourceMode: {
						__typename: 'BasicMutationResult',
						query: {
							__typename: 'Query',
							authenticatedSession: {
								__typename: 'AuthenticatedSession',
								kingdomAdmin: kingdomAdmin ? {
									__typename: 'User',
									id: kingdomAdmin.id,
									legacyId: kingdomAdmin.legacyId,
									personalTweaks: {
										__typename: 'UserPersonalTweaks',
										pageDetailSourceMode: nextSourceMode,
									},
								} : null,
								user: {
									__typename: 'User',
									id: user.id,
									legacyId: user.legacyId,
									personalTweaks: {
										__typename: 'UserPersonalTweaks',
										pageDetailSourceMode: nextSourceMode,
									},
								},
							},
						},
					},
				},
			});
		}
	}



	return (
		<CompareModePickerBuilder
			onChangeCallback={update}
			options={options}
		/>
	);
};



export default SourceModePicker;
