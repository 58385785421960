import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttachedHeading, {
	AttachedHeadingAlignment,
} from '~/components/patterns/structuredValues/AttachedHeading';
import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Caption from '~/components/patterns/headings/Caption';
import Emphasis from '~/components/patterns/typography/Emphasis';
import SegmentDefinitionIdentifier from './SegmentDefinitionIdentifier';
import SegmentsList from '~/components/atoms/segments/SegmentsList';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';



const messages = defineMessages({
	blankPlaceholder: {
		id: 'ui.pageDetail.segments.inSegment.none',
	},
	label: {
		id: 'ui.pageDetail.segments.inSegment',
	},
});



type Props = {
	loading?: boolean,
	segmentNames: ReadonlyArray<string>,
};

const AssignedSegmentsOverview: React.FC<Props> = (props) => {
	const {
		loading = false,
		segmentNames,
	} = props;

	const websiteId = useWebsiteId();

	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const isLoading = loading || segmentDefinitions.isLoaded === false;

	const segments = segmentDefinitions.listAll().filter(
		(segmentDefinition) => segmentNames.includes(segmentDefinition.name),
	);

	const segmentsCount = segments.length;

	return (
		<AttachedHeading
			heading={(
				<AttachedIcon
					icon={(
						<BasicIcon
							size={14}
							type={BasicIconType.Segment}
						/>
					)}
				>
					<Caption>
						<FormattedMessage
							{...messages.label}
							values={{
								count: segmentsCount,
							}}
						/>
					</Caption>
				</AttachedIcon>
			)}
			headingAlignment={AttachedHeadingAlignment.Left}
		>
			{isLoading ? (
				<SquareSkeleton
					maxWidth={120}
					style={SquareSkeletonStyle.Light}
				/>
			) : segments.length > 0 ? (
				<SegmentsList inline={true}>
					{segments.map((segmentDefinition) => (
						<SegmentDefinitionIdentifier
							key={segmentDefinition.name}
							segmentDefinition={segmentDefinition}
						/>
					))}
				</SegmentsList>
			) : (
				<Emphasis>
					<FormattedMessage {...messages.blankPlaceholder} />
				</Emphasis>
			)}
		</AttachedHeading>
	);
};



export default AssignedSegmentsOverview;
