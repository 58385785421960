import React from 'react';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	type BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import BenefitName from './BenefitName';
import HelpHint, {
	HelpHintStyle,
} from '~/components/patterns/hints/HelpHint';

import {
	type Benefit,
} from '~/model/benefits';



type Props = {
	benefit: Benefit,
	hint?: React.ReactNode,
	iconType?: BasicIconType,
	isEnterprise?: boolean,
};

const BenefitNameWithIcon: React.FC<Props> = (props) => {
	const {
		benefit,
		hint,
		iconType,
		isEnterprise,
	} = props;

	let result = (
		<BenefitName benefit={benefit} />
	);

	if (hint) {
		result = (
			<AttachedElement
				element={(
					<HelpHint
						message={hint}
						style={isEnterprise ? HelpHintStyle.Light : HelpHintStyle.Dark}
					/>
				)}
			>
				{result}
			</AttachedElement>
		);
	}

	if (iconType) {
		result = (
			<AttachedIcon
				ellipsis={false}
				icon={(
					<BasicIcon
						size={20}
						type={iconType}
					/>
				)}
			>
				{result}
			</AttachedIcon>
		);
	}

	return result;
};



export default BenefitNameWithIcon;
