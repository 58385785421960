import {
	intervalToDuration,
	isValid,
	parseISO,
} from 'date-fns';
import React from 'react';
import {
	FormattedRelativeTime,
} from 'react-intl';

import DateTime from '~/components/app/DateTime';
import Hint from '~/components/patterns/hints/hint/Hint';



type Props = {
	datetime: string | Date,
};

const ShortTimeAgo: React.FC<Props> = (props) => {
	const {
		datetime,
	} = props;

	const date = datetime instanceof Date
		? datetime
		: parseISO(datetime);

	if (!isValid(date)) {
		return null;
	}

	let unit;
	let value;

	const intervals = intervalToDuration({
		end: new Date(),
		start: date,
	});

	if (intervals.years !== undefined && intervals.years > 0) {
		unit = 'year';
		value = -intervals.years;
	} else if (intervals.months !== undefined && intervals.months > 0) {
		unit = 'month';
		value = -intervals.months;
	} else if (intervals.days !== undefined && intervals.days > 0) {
		unit = 'day';
		value = -intervals.days;
	} else if (intervals.hours !== undefined && intervals.hours > 0) {
		unit = 'hour';
		value = -intervals.hours;
	} else if (intervals.minutes !== undefined && intervals.minutes > 0) {
		unit = 'minute';
		value = -intervals.minutes;
	} else if (intervals.seconds !== undefined && intervals.seconds > 0) {
		unit = 'second';
		value = -intervals.seconds;
	}

	return (
		<Hint
			popup={(
				<DateTime datetime={date} />
			)}
		>
			<FormattedRelativeTime
				style="short"
				unit={unit}
				value={value}
			/>
		</Hint>
	);
};



export default ShortTimeAgo;
