import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	DEFAULT_SORT_BY,
	LIST_OF_PRIMARY_ISSUE_CHANGES,
	createAffectedPagesComparisonFilterParameter,
	getDefaultFilter,
} from '~/model/affectedPagesComparison';

import {
	getCategoryUrlIdentifier,
	getUrlIdentifier,
} from '~/model/issues/identifierMapping';

import {
	dateFromSelector,
	dateToSelector,
	scopeSelector,
} from '~/state/websiteIssuesComparison/selectors';



const messages = defineMessages({
	seeChanges: {
		id: 'ui.issuesComparison.seeChanges',
	},
});



const SeeChangesButton = (props) => {
	const {
		isDisabled,
		issueCategory,
		issueType,
	} = props;

	const dateFrom = useSelector(dateFromSelector);
	const dateTo = useSelector(dateToSelector);
	const scope = useSelector(scopeSelector);
	const websiteId = useWebsiteId();

	const affectedPagesScope = scope === 'website' ? 'all' : scope;

	const affectedPagesFilter = getDefaultFilter(issueCategory, issueType, affectedPagesScope)
		.set('segment', affectedPagesScope)
		.set(CK.PagesCommonColumn.IssueChange, LIST_OF_PRIMARY_ISSUE_CHANGES.toArray());

	return (
		<Button
			disabled={isDisabled}
			icon={(
				<BasicIcon
					size={19}
					type={BasicIconType.Summary}
				/>
			)}
			linkRouteName="website.issues.issueCategory.singleIssueAffectedPages"
			linkRouteParams={{
				end_date: dateTo,
				filter: createAffectedPagesComparisonFilterParameter(
					affectedPagesFilter,
					DEFAULT_SORT_BY,
					issueCategory,
					issueType,
				),
				issuesOverviewCategory: getCategoryUrlIdentifier(issueCategory),
				issuesOverviewIssue: getUrlIdentifier(issueType),
				start_date: dateFrom,
				websiteId,
			}}
			size={ButtonSize.XSmall}
			style={ButtonStyle.Hollow}
		>
			<FormattedMessage {...messages.seeChanges} />
		</Button>
	);
};



export default SeeChangesButton;
