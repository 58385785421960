import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import Ellipsis from '~/components/patterns/values/Ellipsis';
import Hint from '~/components/patterns/hints/hint/Hint';
import InternalLink from '~/components/patterns/links/InternalLink';
import ManagementTable from '~/components/patterns/tables/ManagementTable';
import Measurer from '~/utilities/Measurer';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import Small from '~/components/patterns/typography/Small';
import TextualList from '~/components/patterns/lists/TextualList';

import useImpersonateUser from '~/hooks/useImpersonateUser';



type Props = {
	availableAccounts: ReadonlyArray<{
		accountId: CK.AccountId,
		accountPhase: GraphQL.AccountPhase,
		accountState: string,
		email: string,
		memberships: ReadonlyArray<{
			accountId: CK.AccountId,
			accountPhase: GraphQL.AccountPhase,
			accountState: string,
			numberOfHomeWebsites: number,
			role: GraphQL.UserRole,
		}>,
		numberOfHomeWebsites: number,
		role: GraphQL.UserRole,
	}>,
	forgetLocation: boolean,
};

const SwitchAccountModal: React.FC<Props> = (props) => {
	const {
		availableAccounts,
		forgetLocation,
	} = props;

	const impersonateUser = useImpersonateUser();

	const [isSwitching, setIsSwitching] = React.useState(false);

	const handleRowClick = React.useCallback(
		async (row) => {
			setIsSwitching(true);

			await impersonateUser(row.email, forgetLocation);
		},
		[
			forgetLocation,
			impersonateUser,
			setIsSwitching,
		],
	);

	return (
		<SimpleModal
			alignToTop={true}
			isLoading={isSwitching}
			size={SimpleModalSize.XXLarge}
			title="Choose qualified user to login as"
		>
			{!isSwitching && (
				<Measurer>
					{({ containerWidth }) => (
						<ManagementTable
							columnLabels={['Account ID', 'Phase', 'E-mail', 'Role', 'Websites', 'All memberships']}
							columnWidths={[100, 160, 320, 110, 120, 100]}
							height={[0, 500]}
							onRowClick={handleRowClick}
							rows={availableAccounts}
							width={[800, Math.max(800, containerWidth)]}
						>
							{({ row }) => (
								<>
									<span>{row.accountId}</span>
									<span>{row.accountPhase} ({row.accountState})</span>
									<Ellipsis>{row.email}</Ellipsis>
									<span>{row.role}</span>
									<span>{row.numberOfHomeWebsites}</span>
									<span>
										{row.memberships.length}{' '}
										<Hint
											popup={(
												<TextualList
													items={row.memberships.map((membership) => ({
														label: (
															<>
																<b>{membership.role}</b> in account <b>{membership.accountId}</b>, <span>{membership.accountPhase} ({membership.accountState})</span> with {membership.numberOfHomeWebsites} websites
															</>
														),
													}))}
												/>
											)}
										>
											<Small>
												<InternalLink onClickCallback={(e) => e.stopPropagation()}>(see details)</InternalLink>
											</Small>
										</Hint>
									</span>
								</>
							)}
						</ManagementTable>
					)}
				</Measurer>
			)}
		</SimpleModal>
	);
};



export default SwitchAccountModal;
