import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CameraIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M21.444,6.125H20.128a.534.534,0,0,1-.481-.346C19.029,4.475,18.39,3.125,17,3.125H12c-1.071,0-1.636.778-2.659,2.191-.384.527-.51.809-.844.809h-6C.434,6.125,0,7.334,0,8.348V18.736a2.254,2.254,0,0,0,2.556,2.389H21.444A2.254,2.254,0,0,0,24,18.736V8.348C24,7.334,23.557,6.125,21.444,6.125Zm-.944,7a5.75,5.75,0,1,1-5.75-5.75A5.756,5.756,0,0,1,20.5,13.125ZM5,9.875a1.25,1.25,0,1,1-1.25-1.25A1.25,1.25,0,0,1,5,9.875Z"
				fill={color}
			/>
			<path
				d="M2.5,5.109l3,.016h0a.5.5,0,0,0,.5-.5v-.25a1.5,1.5,0,0,0-1.5-1.5h-1A1.5,1.5,0,0,0,2,4.375v.234A.5.5,0,0,0,2.5,5.109Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CameraIconBuilder;
