import React from 'react';

import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import ValueUpdateComparison, {
	ValueUpdateComparisonHighlightType,
} from '~/components/patterns/values/ValueUpdateComparison';

import styles from './ContentChangeComparison.module.scss';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	/** Badge attached to title */
	badge?: React.ReactNode,
	/** Visual highlight of update */
	highlightType?: ValueUpdateComparisonHighlightType,
	/** Updated content after change */
	newContent: React.ReactNode,
	/** Content before change */
	oldContent?: React.ReactNode,
	/** State of search engine activity */
	searchEngineActivityStates?: React.ReactNode,
	/** Title of displayed changes */
	title?: React.ReactNode,
};

const ContentChangeComparison: React.FC<Props> = (props) => {
	const {
		badge,
		highlightType,
		newContent,
		oldContent,
		searchEngineActivityStates,
		title,
	} = props;

	const viewportType = useViewportType();

	let searchEngineActivityStatesElement;

	if (searchEngineActivityStates) {
		searchEngineActivityStatesElement = (
			<div className={styles.searchEngineActivityStates}>
				<List
					inline={true}
					size={ListSize.Small}
				>
					{searchEngineActivityStates}
				</List>
			</div>
		);
	}

	const mobileSEActivityStatesElements = viewportType.isSmall && searchEngineActivityStatesElement;

	return (
		<section className={styles.component}>
			<div className={styles.heading}>
				<div className={styles.titlePart}>
					{!viewportType.isSmall && searchEngineActivityStatesElement}
					{title && (
						<h3 className={styles.title}>
							{title}
						</h3>
					)}
				</div>
				{(badge || mobileSEActivityStatesElements) && (
					<div className={styles.badge}>
						{badge}
						{mobileSEActivityStatesElements}
					</div>
				)}
			</div>
			<div className={styles.list}>
				<ValueUpdateComparison
					highlightType={highlightType}
					prevValue={oldContent}
					value={newContent}
				/>
			</div>
		</section>
	);
};



export default ContentChangeComparison;

export {
	ValueUpdateComparisonHighlightType as ContentChangeComparisonHighlightType,
};
