import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';



const messages = defineMessages({
	[GraphQL.UserRole.Admin]: {
		id: 'ui.userRole.admin',
	},
	[GraphQL.UserRole.Editor]: {
		id: 'ui.userRole.editor',
	},
	[GraphQL.UserRole.Manager]: {
		id: 'ui.userRole.manager',
	},
	[GraphQL.UserRole.Viewer]: {
		id: 'ui.userRole.viewer',
	},
});



type Props = {
	role: GraphQL.UserRole | string,
};



const UserRoleName: React.FC<Props> = (props) => {
	if (!messages[props.role]) {
		return (
			<>{props.role}</>
		);
	}

	return (
		<FormattedMessage {...messages[props.role]} />
	);
};



export default React.memo(UserRoleName);
