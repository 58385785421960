import {
	IsConductorClient,
} from '~/config';

import GraphQL from '~/types/graphql';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const ManagedViaConductorActions: Array<Action> = [
	GraphQL.ActionWithAccount.InviteMember,
	GraphQL.ActionWithAccount.ManageOrganizationAccessControl,
	GraphQL.ActionWithAccountMembership.ChangeRole,
	GraphQL.ActionWithAccountMembership.RemoveUser,
	GraphQL.ActionWithUser.ManagePersonalDetails,
];



export type Action =
	| GraphQL.ActionWithAccount
	| GraphQL.ActionWithAccountMembership
	| GraphQL.ActionWithInvitation
	| GraphQL.ActionWithPlatform
	| GraphQL.ActionWithUser
	| GraphQL.ActionWithUserWebsiteAccess
	| GraphQL.ActionWithWebsite;

export enum ObjectType {
	Account = 'accounts',
	AccountMembership = 'accountMemberships',
	Invitation = 'invitations',
	Platform = 'platform',
	User = 'members',
	UserWebsiteAccess = 'userWebsiteAccess',
	Website = 'websites',
}

type PermissionCheck = {
	yes: boolean,
	no: boolean,
	noBecauseManaged: boolean,
	noBecauseManagedViaConductor: boolean,
	noBecauseInsufficientPermissions: boolean,
	noBecauseInsufficientPermissionsForKingdomAdmin: boolean,
};

const loadingState = {
	yes: false,
	no: false,
	noBecauseManaged: false,
	noBecauseManagedViaConductor: false,
	noBecauseInsufficientPermissions: false,
	noBecauseInsufficientPermissionsForKingdomAdmin: false,
};



export function combinePermissionChecks(checks: ReadonlyArray<PermissionCheck>) {
	if (checks.length === 0) {
		return loadingState;
	}

	if (checks.length === 1) {
		return getArrayItemAtSafeIndex(checks, 0);
	}

	return {
		yes: checks.some((check) => check.yes),
		no: checks.every((check) => check.no),
		noBecauseManaged: checks.every((check) => check.noBecauseManaged),
		noBecauseManagedViaConductor: checks.every((check) => check.noBecauseManagedViaConductor),
		noBecauseInsufficientPermissions: checks.every((check) => check.noBecauseInsufficientPermissions),
		noBecauseInsufficientPermissionsForKingdomAdmin: checks.every((check) => check.noBecauseInsufficientPermissionsForKingdomAdmin),
	};
}



export function isUserAllowed({
	action,
	managedActions,
	objectId,
	objectType,
	permissions,
	permissionsForKingdomAdmin,
}: {
	action: Action,
	managedActions: Record<ObjectType, Record<number | string, Array<Action>>>,
	objectId: number | string | undefined | null,
	objectType: ObjectType,
	permissions: Record<ObjectType, Record<number | string, Array<Action>>>,
	permissionsForKingdomAdmin: Record<ObjectType, Record<number | string, Array<Action>>>,
}): PermissionCheck {
	if (IsConductorClient && ManagedViaConductorActions.includes(action)) {
		return {
			yes: false,
			no: true,
			noBecauseManaged: false,
			noBecauseManagedViaConductor: true,
			noBecauseInsufficientPermissions: false,
			noBecauseInsufficientPermissionsForKingdomAdmin: false,
		};
	}

	if (objectType === ObjectType.Account && typeof objectId === 'string') {
		objectId = parseInt(objectId);
	} else if (objectType === ObjectType.AccountMembership && typeof objectId === 'string' && objectId.includes('@')) {
		const objectIdApart = objectId.split('/');

		const accountIdPart = getArrayItemAtSafeIndex(objectIdApart, 0);
		const userIdPart = btoa(getArrayItemAtSafeIndex(objectIdApart, 1));

		objectId = `${accountIdPart}/${userIdPart}`;
	} else if (objectType === ObjectType.Invitation && typeof objectId === 'string') {
		objectId = parseInt(objectId);
	} else if (objectType === ObjectType.User && typeof objectId === 'string' && objectId.includes('@')) {
		objectId = btoa(objectId);
	} else if (objectType === ObjectType.UserWebsiteAccess && typeof objectId === 'string' && objectId.includes('@')) {
		const objectIdApart = objectId.split('/');

		const websiteIdPart = getArrayItemAtSafeIndex(objectIdApart, 0);
		const userIdPart = btoa(getArrayItemAtSafeIndex(objectIdApart, 2));

		objectId = `${websiteIdPart}/compat/${userIdPart}`;
	}

	if (
		objectId === undefined
		|| objectId === null
	) {
		return loadingState;
	}

	const objectManagedActions = managedActions[objectType][objectId];
	const objectPermissions = permissions[objectType][objectId];

	if (
		objectManagedActions === undefined
		|| objectPermissions === undefined
	) {
		return loadingState;
	}

	const areRoyalPermissionsInsufficient = ((objectId: number | string) => {
		const objectPermissionsForKingdomAdmin = permissionsForKingdomAdmin[objectType][objectId] ?? null;

		if (objectPermissionsForKingdomAdmin === null) {
			return null;
		}

		return objectPermissionsForKingdomAdmin.includes(action) === false;
	})(objectId);

	const noBecauseManaged = objectManagedActions.includes(action);
	const noBecauseInsufficientPermissions = objectPermissions.includes(action) === false;
	const noBecauseInsufficientPermissionsForKingdomAdmin = areRoyalPermissionsInsufficient ?? false;

	const noBecauseRelevantInsufficientPermissions = areRoyalPermissionsInsufficient !== null
		? noBecauseInsufficientPermissionsForKingdomAdmin
		: noBecauseInsufficientPermissions;

	const isAllowed = (
		noBecauseManaged === false
		&& noBecauseRelevantInsufficientPermissions === false
	);

	return {
		yes: isAllowed,
		no: isAllowed === false,
		noBecauseManaged,
		noBecauseManagedViaConductor: false,
		noBecauseInsufficientPermissions,
		noBecauseInsufficientPermissionsForKingdomAdmin,
	};
}
