import classNames from 'classnames';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import HiddenContent from '~/components/patterns/content/HiddenContent';



const messages = defineMessages({
	noLabel: {
		id: 'ui.general.boolean.values.no',
		defaultMessage: 'No',
	},
	yesLabel: {
		id: 'ui.general.boolean.values.yes',
		defaultMessage: 'Yes',
	},
});



type Props = {
	/** When not active we will hide feedback buttons */
	active?: boolean,
	/** Feedback label */
	label?: React.ReactNode,
	/** Callback triggered when clicking of negative feedback button */
	onNegativeFeedbackClickCallback: () => void,
	/** Callback triggered when clicking of positive feedback button */
	onPositiveFeedbackClickCallback: () => void,
	/** Additional overlay */
	overlay?: React.ReactNode,
	/** Possibility to open or hide defined overlay */
	overlayVisible?: boolean,
};



const IncidentBoxFeedbackFooter: React.FC<Props> = (props) => {
	const {
		active = true,
		label,
		onNegativeFeedbackClickCallback,
		onPositiveFeedbackClickCallback,
		overlay,
		overlayVisible = false,
	} = props;

	const overlayClasses = classNames({
		'incident-box-feedback__overlay': true,
		'incident-box-feedback__overlay--hidden': !overlayVisible,
	});

	return (
		<div className="incident-box-feedback">
			<div className="incident-box-feedback__content">
				<div className="incident-box-feedback__label">
					{label}
				</div>

				<div className="incident-box-feedback__buttons">
					<HiddenContent hiddenContent={!active}>
						<Button
							icon={(
								<BasicIcon
									color="#8595A6"
									size={17}
									type={BasicIconType.ThumbsUp}
								/>
							)}
							onClick={onPositiveFeedbackClickCallback}
							size={ButtonSize.XXSmall}
							style={ButtonStyle.Hollow}
							uppercase={true}
						>
							<FormattedMessage {...messages.yesLabel} />
						</Button>
						<Button
							icon={(
								<BasicIcon
									color="#8595A6"
									size={17}
									type={BasicIconType.ThumbsDown}
								/>
							)}
							onClick={onNegativeFeedbackClickCallback}
							size={ButtonSize.XXSmall}
							style={ButtonStyle.Hollow}
							uppercase={true}
						>
							<FormattedMessage {...messages.noLabel} />
						</Button>
					</HiddenContent>
				</div>
			</div>

			{overlay && (
				<div className={overlayClasses}>
					{overlay}
				</div>
			)}
		</div>
	);
};



export default IncidentBoxFeedbackFooter;
