/**
 * iOS Safari has problem that after reload of the page, when we load asynchronously any content,
 * you won't be able to scroll by touch gestures. To prevent this problem,
 * we have to remove -webkit-overflow-scrolling: touch; property.
 * We will add this again by JS with a small delay.
 *
 * 1. This workaround have to be active only on iOS Safari. On iOS Chrome
 *    we are able to see a small flash caused by adding -webkit-overflow-scrolling.
 *    To detect iOS Chrome we have to check presence of 'CriOS' in userAgent.
 */

export function fixScrolling(skipChrome = false) {
	/* [1] */
	if (skipChrome && navigator.userAgent.match('CriOS')) {
		return;
	}

	const scrollableElements = document.querySelectorAll('.js-scrollable');
	Array.prototype.forEach.call(scrollableElements, (el) => {
		el.style['-webkit-overflow-scrolling'] = 'auto';
	});

	setTimeout(() => {
		Array.prototype.forEach.call(scrollableElements, (el) => {
			el.style['-webkit-overflow-scrolling'] = 'touch';
		});
	}, 350);
}
