import useBatchContext from '~/hooks/useBatchContext';



function useBatchContextMultiDashboardAccountSection(accountId: number) {
	return useBatchContext(
		`multiDashboard/account/${accountId}`,
		10,
	);
}



export default useBatchContextMultiDashboardAccountSection;
