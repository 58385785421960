import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';

import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	[GraphQL.LogSourceProvider.Akamai]: {
		id: 'ui.logFileAnalysisLogSourceForm.akamaiDataStream',
	},
	[GraphQL.LogSourceProvider.Cloudflare]: {
		id: 'ui.logFileAnalysisLogSourceForm.cloudflareWorker',
	},
	[GraphQL.LogSourceProvider.CloudflareLogpush]: {
		id: 'ui.logFileAnalysisLogSourceForm.cloudflareLogpush',
	},
	[GraphQL.LogSourceProvider.Cloudfront]: {
		id: 'ui.logFileAnalysisLogSourceForm.cloudfrontStandardLogging',
	},
	[GraphQL.LogSourceProvider.Fastly]: {
		id: 'ui.logFileAnalysisLogSourceForm.fastlyRealtimeLogStreaming',
	},
	caution: {
		id: 'ui.general.caution',
	},
	stillPresentWarning: {
		id: 'ui.logFileAnalysisLogSourceForm.stillPresentWarning',
	},
});



type Props = {
	provider: GraphQL.LogSourceProvider,
};

const LogFileAnalysisLogSourceStillPresentWarning: React.FC<Props> = (props) => {
	const {
		provider,
	} = props;

	const {
		isFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	return (
		<CalloutMessage
			borders={true}
			message={(
				<FormattedMessage {...messages.caution} />
			)}
			status={CalloutMessageStatus.Critical}
		>
			<Copy
				{...messages.stillPresentWarning}
				values={{
					linkArticle: matchAndReturn(provider, {
						[GraphQL.LogSourceProvider.Akamai]: (
							linkExternal('https://www.contentkingapp.com/support/setting-up-akamai-integration/#disabling-log-file-analysis')
						),
						[GraphQL.LogSourceProvider.Cloudflare]: (
							linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-worker-integration/#removing-the-cloudflare-worker')
						),
						[GraphQL.LogSourceProvider.CloudflareLogpush]: (
							linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-logpush-integration/#removing-cloudflare-logpush')
						),
						[GraphQL.LogSourceProvider.Cloudfront]: (
							linkExternal('https://www.contentkingapp.com/support/setting-up-cloudfront-integration/#disabling-log-file-analysis')
						),
						[GraphQL.LogSourceProvider.Fastly]: (
							linkExternal('https://www.contentkingapp.com/support/setting-up-fastly-integration/#disabling-log-file-analysis')
						),
					}),
					provider: <FormattedMessage {...messages[provider]} />,
					reason: !isFeatureEnabled ? 'notOnPlan' : 'notEnabled',
				}}
			/>
		</CalloutMessage>
	);
};



export default LogFileAnalysisLogSourceStillPresentWarning;
