import {
	selectedWebsiteIdSelector,
} from '../ui/selectors';



const selector = (state) => state.get('pagesGraphs');



const currentDataSelector = (state) => selector(state).get(selectedWebsiteIdSelector(state));

export const statisticsSelector = (state) => currentDataSelector(state).get('statistics');

export const totalSelector = (state) => {
	const currentData = currentDataSelector(state);

	if (!currentData) {
		return null;
	}

	return currentData.get('total');
};
