import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const LinksIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Group"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="link1"
					transform="translate(2.000000, 3.000000)"
				>
					<path
						d="M28.3604063,36.7774688 L22.0250938,43.1127813 C19.9247813,45.2130938 16.5216563,45.2130938 14.4230625,43.1127813 L11.8879063,40.5793438 C9.7893125,38.4790313 9.7893125,35.0759063 11.8879063,32.9755938 L18.2232188,26.6402813 C20.3235313,24.5416875 23.7266563,24.5416875 25.8269688,26.6402813 L28.3604063,29.1754375 C30.459,31.2740313 30.459,34.678875 28.3604063,36.7774688 L28.3604063,36.7774688 Z"
						id="Stroke-1311"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M44.8327344,20.3051406 L38.4974219,26.6404531 C36.3971094,28.7407656 32.9939844,28.7407656 30.8953906,26.6404531 L28.3602344,24.1070156 C26.2616406,22.0067031 26.2616406,18.6035781 28.3602344,16.5032656 L34.6955469,10.1679531 C36.7958594,8.06935938 40.1989844,8.06935938 42.2992969,10.1679531 L44.8327344,12.7031094 C46.9313281,14.8017031 46.9313281,18.2065469 44.8327344,20.3051406 L44.8327344,20.3051406 Z"
						id="Stroke-1312"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M22.0249219,32.9761094 L34.6955469,20.3054844"
						id="Stroke-1313"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default LinksIconBuilder;

