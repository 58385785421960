import classNames from 'classnames';
import React from 'react';



export enum IncidentBoxState {
	Active = 'active',
	Past = 'past',
}

type Props = {
	/** ID for situation when we would like to use this box as HTML anchor target */
	anchorID?: string,
	children: React.ReactNode,
	/** Additional box footer. We can set multiple footer rows using Array. */
	footer?: React.ReactNode,
	/** Box highlight */
	highlighted?: boolean,
	/** Title of incident */
	title: React.ReactNode,
	/** Scope of incident (Property suitable for displaying of segment label thumbnails) */
	scope?: React.ReactNode,
	/** Possible state of incident */
	state?: IncidentBoxState,
};



const IncidentBox: React.FC<Props> = (props) => {
	const {
		anchorID,
		children,
		footer,
		highlighted,
		scope,
		state = IncidentBoxState.Active,
		title,
	} = props;

	const renderFooter = () => {
		const items = Array.isArray(footer) ? footer : [footer];

		return items.map((item, index) => {
			return (
				<div
					className="incident-box__footer"
					key={'box-footer-' + index}
				>
					{item}
				</div>
			);
		});
	};

	const componentClasses = classNames({
		'incident-box': true,
		'incident-box--is-highlighted': highlighted,
		['incident-box--state-' + state]: true,
	});

	const additionalAttributes: React.AllHTMLAttributes<HTMLDivElement> = {};

	if (anchorID) {
		additionalAttributes.id = anchorID;
	}

	return (
		<div
			className={componentClasses}
			{...additionalAttributes}
		>
			<div className="incident-box__header">
				<div className="incident-box__title">
					{title}
				</div>
				{scope && (
					<div className="incident-box__scope">
						{scope}
					</div>
				)}
			</div>
			<div className="incident-box__body">
				{children}
			</div>
			{footer && renderFooter()}
		</div>
	);
};



export default IncidentBox;
