import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ArticleIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M22.044,0H1.956A1.959,1.959,0,0,0,0,1.954V22.042A1.959,1.959,0,0,0,1.956,24H22.044A1.959,1.959,0,0,0,24,22.042V1.954A1.959,1.959,0,0,0,22.044,0ZM21,22,3,22.04a1,1,0,0,1-1-1L1.958,3a1,1,0,0,1,1-1L21,1.956a1,1,0,0,1,1,1V21A1,1,0,0,1,21,22Z"
				fill={color}
			/>
			<path
				d="M4.5,8.5h15a1,1,0,0,0,0-2H4.5a1,1,0,1,0,0,2Z"
				fill={color}
			/>
			<path
				d="M4.5,13.5h15a1,1,0,0,0,0-2H4.5a1,1,0,1,0,0,2Z"
				fill={color}
			/>
			<path
				d="M4.5,18.5h6a1,1,0,0,0,0-2h-6a1,1,0,0,0,0,2Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ArticleIconBuilder;
