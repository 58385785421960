import {
	type TYPE_MISSING,
	type TYPE_NOT_CRAWLED_YET,
	type TYPE_NO_DATA,
	type TYPE_OVERQUOTA,
	type TYPE_PAGE,
	type TYPE_REDIRECT,
	type TYPE_SERVER_ERROR,
	type TYPE_UNREACHABLE,
} from '~/model/pages';



export enum LinkType {
	IncomingInternalLinks = 'incoming',
	OutgoingInternalLinks = 'outgoing_internal',
	OutgoingExternalLinks = 'outbound',
	IncomingCanonicals = 'canonicals',
	IncomingRedirects = 'redirects',
}

export type PageType =
	| typeof TYPE_MISSING
	| typeof TYPE_NOT_CRAWLED_YET
	| typeof TYPE_NO_DATA
	| typeof TYPE_OVERQUOTA
	| typeof TYPE_PAGE
	| typeof TYPE_REDIRECT
	| typeof TYPE_SERVER_ERROR
	| typeof TYPE_UNREACHABLE;
