import classNames from 'classnames';
import React from 'react';

import Gravatar, {
	GravatarStyle,
} from '~/components/patterns/users/Gravatar';



export enum UserCardCtaElementVisibility {
	Always = 'always',
	OnHover = 'on-hover',
}

type Props = {
	avatar: {
		email: string | null,
		placeholder?: React.ReactNode,
	},
	ctaElement?: React.ReactNode,
	ctaElementVisibility?: UserCardCtaElementVisibility,
	/** Details of user */
	details: React.ReactNode,
	/** Name of user */
	name: React.ReactNode,
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
	tags?: React.ReactNode,
};



const UserCard: React.FC<Props> = (props) => {
	const {
		avatar,
		ctaElement,
		ctaElementVisibility = UserCardCtaElementVisibility.Always,
		details,
		name,
		onClick,
		tags,
	} = props;

	const componentClasses = classNames({
		'user-card': true,
		'user-card--is-clickable': onClick,
		'user-card--hide-cta-element': ctaElementVisibility === UserCardCtaElementVisibility.OnHover,
	});

	return (
		<div
			className={componentClasses}
			onClick={onClick}
		>
			{tags && (
				<div className="user-card__tags">
					{tags}
				</div>
			)}

			<div className="user-card__container">
				<div className="user-card__avatar">
					<Gravatar
						email={avatar.email}
						placeholder={avatar.placeholder}
						style={GravatarStyle.Member}
					/>
				</div>
				<div className="user-card__content">
					<h4 className="user-card__name">
						{name}
					</h4>

					<div className="user-card__details">
						{details}
					</div>
				</div>
			</div>

			{ctaElement && (
				<div className="user-card__cta-element">
					{ctaElement}
				</div>
			)}
		</div>
	);
};



export default UserCard;
