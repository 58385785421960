import React from 'react';
import {
	useSelector,
} from 'react-redux';

import AuthenticatedSection from './AuthenticatedSection';
import AuthenticatedSession from '~/components/app/AuthenticatedSession';
import HtmlLanguageProvider from '~/components/providers/HtmlLanguageProvider';
import UnauthenticatedSection from './UnauthenticatedSection';

import {
	inAuthenticatedSectionSelector,
	isAuthenticatedSelector,
} from '~/state/authentication/selectors';

import {
	currentUserLocaleSelector,
} from '~/state/localization/selectors';



const Main: React.FC = () => {
	const isAuthenticated = useSelector(isAuthenticatedSelector);
	const currentUserLocale = useSelector(currentUserLocaleSelector);
	const inAuthenticatedSection = useSelector(inAuthenticatedSectionSelector);

	React.useEffect(() => {
		const element = document.querySelector('.js-app-loader');

		if (element !== null) {
			element.classList.add('app-loader--is-finished');

			setTimeout(() => {
				element.parentNode?.removeChild(element);
			}, 350);
		}
	}, []);

	return (
		<HtmlLanguageProvider language={currentUserLocale}>
			{(inAuthenticatedSection && isAuthenticated) ? (
				<>
					<AuthenticatedSession />
					<AuthenticatedSection />
				</>
			) : (
				<UnauthenticatedSection />
			)}
		</HtmlLanguageProvider>
	);
};



export default Main;
