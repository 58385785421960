import React from 'react';

import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import TimelineMessageEntry from '~/components/patterns/time/timeline/TimelineMessageEntry';



type Props = {
	isFirstEntry: boolean,
	isLastEntry: boolean,
};

const NoResultsTimelineEntry: React.FC<Props> = (props) => {
	const {
		isFirstEntry,
		isLastEntry,
	} = props;

	return (
		<TimelineMessageEntry
			isFirstChild={isFirstEntry}
			isLastChild={isLastEntry}
		>
			<NoSearchResults />
		</TimelineMessageEntry>
	);
};



export default NoResultsTimelineEntry;
