import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import TextField, {
	TextFieldSize,
} from '~/components/atoms/forms/components/TextField';



const messages = defineMessages({
	placeholder: {
		id: 'ui.customElements.extractionSteps.header.expression.placeholder',
	},
});



type Props = {
	name: string,
	width: number,
};

const ExpressionField: React.FC<Props> = (props) => {
	const {
		name,
		width,
	} = props;

	const intl = useIntl();

	return (
		<FieldStatus
			focusFieldErrorMessage={name}
			name={name}
			showErrorMessageOnFocus={true}
			showIcon={false}
		>
			<TextField
				name={name}
				placeholder={intl.formatMessage(messages.placeholder)}
				size={TextFieldSize.Small}
				width={width}
			/>
		</FieldStatus>
	);
};



export default ExpressionField;
