import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';
import {
	PaymentAuthorizationContextContext,
} from '~/components/logic/PaymentAuthorizationContext';
import SubmitButton, {
	STYLE_ACTION as SUBMIT_BUTTON_STYLE_ACTION,
} from '~/components/atoms/forms/components/SubmitButton';



const messages = defineMessages({
	verifyButton: {
		id: 'ui.3dsecure.button',
	},
});



type Props = {
	children: React.ReactNode,
	isDisabled?: boolean,
	tooltip?: React.ReactNode,
};

const PaymentSubmitButton: React.FC<Props> = (props) => {
	const {
		children,
		isDisabled = false,
		tooltip,
	} = props;

	const {
		dataHasChanged,
		isSubmitting,
		isValid,
	} = React.useContext(FormContext);

	const {
		isAuthorizing,
		isResubmitting,
	} = React.useContext(PaymentAuthorizationContextContext);

	if (isAuthorizing && !isResubmitting) {
		return (
			<SubmitButton
				disabled={true}
				inactive={true}
				style={SUBMIT_BUTTON_STYLE_ACTION}
			>
				<FormattedMessage {...messages.verifyButton} />
			</SubmitButton>
		);
	}

	return (
		<SubmitButton
			disabled={isDisabled || isResubmitting}
			inactive={!isValid || !dataHasChanged || isResubmitting}
			progress={isSubmitting}
			style={SUBMIT_BUTTON_STYLE_ACTION}
			tooltip={tooltip}
		>
			{children}
		</SubmitButton>
	);
};



export default PaymentSubmitButton;
