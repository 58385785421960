import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import {
	injectIntl,
} from 'react-intl';
import Immutable from 'immutable';

import ChartTooltip from '~/components/patterns/charts/components/ChartTooltip';

import {
	setHighchartsGlobalStyles,
} from '~/utilities/highcharts';



class DonutChart extends Component {

	constructor(props, context) {
		super(props, context);

		this.chartRef = React.createRef();
	}



	UNSAFE_componentWillMount() {
		setHighchartsGlobalStyles();
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			data: prevData,
		} = this.props;

		const {
			data: nextData,
		} = nextProps;

		if (nextData && nextData.length > 0 && (!Immutable.is(Immutable.fromJS(prevData), Immutable.fromJS(nextData)))) {
			const chart = this.chartRef.current && this.chartRef.current.chart;

			if (chart && chart.series) {
				const nextSeriesData = this._setDonutChartLowValuesToVisible(nextData);

				chart.series.forEach((series, index) => {
					series.setData(
						nextSeriesData[index].data,
						false,
						false,
						true,
					);
				});

				chart.redraw();
			}
		}
	}



	shouldComponentUpdate() {
		return false;
	}



	_setDonutChartLowValuesToVisible(data) {
		const seriesData = Immutable.fromJS(data);
		const totalCount = seriesData
			.get(0)
			.get('data')
			.reduce((a, dataSlice) => a + dataSlice.get('y'), 0);
		const minSliceSize = 5;
		const minValue = Math.round((totalCount / 360) * minSliceSize);

		return seriesData.setIn([0, 'data'],
			seriesData
				.get(0)
				.get('data')
				.map((dataSlice) => (dataSlice.get('y') < minValue && dataSlice.get('y') > 0)
					? dataSlice
						.set('originalY', dataSlice.get('y'))
						.set('y', minValue)
					: dataSlice
						.set('originalY', dataSlice.get('y')),
				),
		).toJS();
	}



	render() {
		const {
			chartHeight,
			data,
			disabled,
			intl,
			tooltipFormatter,
			viewportType,
		} = this.props;

		const seriesData = this._setDonutChartLowValuesToVisible(data);

		const config = {
			chart: {
				backgroundColor: '#FFFFFF',
				height: chartHeight,
				spacing: [0, 0, 0, 0],
				type: 'pie',
			},
			plotOptions: {
				pie: {
					borderWidth: seriesData[0].data.length > 1 ? 1 : 0,
					dataLabels: {
						enabled: false,
					},
					innerSize: '59%',
					size: '90%',
					states: {
						hover: {
							halo: false,
						},
						inactive: {
							opacity: 1,
						},
					},
				},
			},
			series: seriesData,
			tooltip: {
				animation: false,
				backgroundColor: 'transparent',
				borderWidth: 0,
				enabled: !disabled && viewportType.isSmall === false,
				formatter: function () {
					const currentSeriesData = this
						.series
						.data
						.find((dataSlice) => dataSlice.name === this.key);

					let content;

					if (tooltipFormatter) {
						content = tooltipFormatter({
							key: this.key,
							count: currentSeriesData.originalY,
						});
					} else {
						content = intl.formatNumber(this.originalY);
					}

					return ReactDOMServer.renderToString(
						<ChartTooltip color={this.color}>
							{content}
						</ChartTooltip>,
					);
				},
				padding: 0,
				shadow: false,
				shape: 'square',
				useHTML: true,
			},
		};

		return (
			<HighchartsReact
				highcharts={Highcharts}
				options={config}
				ref={this.chartRef}
			/>
		);
	}

}

DonutChart.propTypes = {
	chartHeight: PropTypes.number.isRequired,
	data: PropTypes.array,
	tooltipFormatter: PropTypes.func,
	viewportType: PropTypes.shape({
		isSmall: PropTypes.bool.isRequired,
	}).isRequired,
};



export default injectIntl(DonutChart);
