import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '../../atoms/forms/basis/FormRow';
import GlobalFormMessagePattern from '~/components/atoms/forms/basis/GlobalFormMessagePattern';
import Parenthesis from '~/components/logic/Parenthesis';
import StaticText from '../../atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';
import TextPreloader, {
	TextPreloaderSize,
} from '~/components/patterns/loaders/TextPreloader';

import {
	useConnectGoogleSearchConsoleAccountMutation,
} from './GoogleSearchConsoleRow.gql';

import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useFormContext from '~/hooks/useFormContext';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';

import {
	authenticateGoogleSearchConsole,
} from '~/model/googleApi/authenticate';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	connectLink: {
		id: 'ui.websites.form.api.gsc.connectLink',
	},
	errorUnrelatedAccount: {
		id: 'ui.websites.form.api.gsc.errors.unrelatedAccount',
	},
	reconnectLink: {
		id: 'ui.websites.form.api.gsc.reconnectLink',
	},
	title: {
		id: 'ui.websites.form.api.gsc.title',
	},
});

const statusMessages = defineMessages({
	[GraphQL.WebsiteIntegrationStatus.Available]: {
		id: 'ui.websites.form.api.status.available',
	},
	[GraphQL.WebsiteIntegrationStatus.Disabled]: {
		id: 'ui.websites.form.api.status.disabled',
	},
	[GraphQL.WebsiteIntegrationStatus.Enabled]: {
		id: 'ui.websites.form.api.status.enabled',
	},
});



type Props = {
	isGscIntegrationOwned: boolean,
	name: string,
};

const GoogleSearchConsoleRow: React.FC<Props> = (props) => {
	const {
		isGscIntegrationOwned,
		name,
	} = props;

	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const websiteId = useWebsiteId();

	const formContext = useFormContext();
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const [connectGoogleSearchConsoleAccount] = useConnectGoogleSearchConsoleAccountMutation();

	const gscIntegrationStatus = websiteIntegrations.getStatus(GraphQL.WebsiteIntegrationType.GoogleSearchConsole);

	const [gscConnectError, setGscConnectError] = React.useState<string | null>(null);

	const handleGSCConnectClick = React.useCallback(
		async (event) => {
			if (effectiveHomeAccountId === null) {
				throw new Error(
					`effectiveHomeAccountId can't null when connecting Google Search Console integration`,
				);
			}

			event.preventDefault();
			setGscConnectError(null);

			const { code } = await authenticateGoogleSearchConsole();

			try {
				await connectGoogleSearchConsoleAccount({
					variables: {
						authCode: code,
						ownerAccountId: effectiveHomeAccountId,
						websiteId,
					},
				});

				formContext.onChangeHandler(name, true);
			} catch (apolloError) {
				const error = FormError.fromApolloError(apolloError);

				if (error !== undefined && error.getName() === 'unrelated_gsc_account') {
					setGscConnectError('unrelated_gsc_account');
				} else {
					throw error;
				}
			}
		},
		[
			connectGoogleSearchConsoleAccount,
			effectiveHomeAccountId,
			formContext,
			name,
			setGscConnectError,
			websiteId,
		],
	);

	return (
		<>
			<FormRow
				label={(
					<FormattedMessage {...messages.title} />
				)}
			>
				{gscIntegrationStatus === null && (
					<StaticText>
						<TextPreloader size={TextPreloaderSize.Small} />
					</StaticText>
				)}

				{gscIntegrationStatus === GraphQL.WebsiteIntegrationStatus.Available && (
					<StaticText>
						<ExternalLink
							href="#"
							onClickCallback={handleGSCConnectClick}
						>
							<FormattedMessage {...messages.connectLink} />
						</ExternalLink>
					</StaticText>
				)}

				{(gscIntegrationStatus !== null && gscIntegrationStatus !== GraphQL.WebsiteIntegrationStatus.Available) && (isGscIntegrationOwned ? (
					<FieldStatus name={name}>
						<SwitchField
							activeStateLabel={(
								<AttachedElement
									element={(
										<Parenthesis
											content={(
												<ExternalLink
													href="#"
													onClickCallback={handleGSCConnectClick}
												>
													<FormattedMessage {...messages.reconnectLink} />
												</ExternalLink>
											)}
										/>
									)}
								>
									<FormattedMessage {...statusMessages[GraphQL.WebsiteIntegrationStatus.Enabled]} />
								</AttachedElement>
							)}
							inactiveStateLabel={(
								<FormattedMessage {...statusMessages[GraphQL.WebsiteIntegrationStatus.Disabled]} />
							)}
							name={name}
						/>
					</FieldStatus>
				) : (
					<StaticText>
						<FormattedMessage {...statusMessages[gscIntegrationStatus]} />
					</StaticText>
				))}
			</FormRow>

			{gscConnectError === 'unrelated_gsc_account' && (
				<GlobalFormMessagePattern>
					<FormattedMessage {...messages.errorUnrelatedAccount} />
				</GlobalFormMessagePattern>
			)}
		</>
	);
};



export default GoogleSearchConsoleRow;
