import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Emphasis from '~/components/patterns/typography/Emphasis';
import FilterDefinition from '~/components/logic/filters/FilterDefinition';
import HighlightedBox from '~/components/patterns/boxes/HighlightedBox';



const messages = defineMessages({
	addCriteria: {
		id: 'ui.segments.editor.filterBox.addCriteria',
	},
	addFirstCriteria: {
		id: 'ui.segments.editor.filterBox.addFirstCriteria',
	},
	empty: {
		id: 'ui.segments.editor.filterBox.empty',
	},
	title: {
		id: 'ui.segments.editor.filterBox.title',
	},
});



type Props = {
	filterDefinition: CK.FilterDefinition,
	openAddColumn: () => void,
};

const FilterBox: React.FC<Props> = (props) => {
	const {
		filterDefinition,
		openAddColumn,
	} = props;

	const hasCriteria = Object.values(filterDefinition).length > 0;

	return (
		<HighlightedBox
			ctaElements={(
				<Button
					onClick={openAddColumn}
					size={ButtonSize.XXSmall}
					style={ButtonStyle.Hollow}
					uppercase={true}
				>
					{hasCriteria ? (
						<FormattedMessage {...messages.addCriteria} />
					) : (
						<FormattedMessage {...messages.addFirstCriteria} />
					)}
				</Button>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			{hasCriteria === false && (
				<Emphasis>
					<FormattedMessage {...messages.empty} />
				</Emphasis>
			)}

			{Object.entries(filterDefinition).map(([column, value]) => (
				<FilterDefinition
					column={column as CK.PagesColumn}
					key={column}
					value={value}
				/>
			))}
		</HighlightedBox>
	);
};



export default FilterBox;
