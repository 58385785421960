import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import LighthouseThresholdsForm from '~/components/app/LighthouseThresholdsForm';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalContentWithSidebar from '~/components/atoms/modals/parts/ModalContentWithSidebar';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalPanel, {
	ALIGNMENT_TOP as MODAL_ALIGNMENT_TOP,
	SIZE_XLARGE as MODAL_SIZE_XLARGE,
} from '~/components/atoms/panels/ModalPanel';
import RichText from '~/components/patterns/typography/RichText';

import useModals from '~/hooks/useModals';



const messages = defineMessages({
	description: {
		id: 'ui.websites.form.monitoring.lighthouseThresholds.modal.description',
	},
	sidebar: {
		id: 'ui.websites.form.monitoring.lighthouseThresholds.modal.sidebar',
	},
	title: {
		id: 'ui.websites.form.monitoring.lighthouseThresholds.modal.title',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	websiteId: CK.WebsiteId,
};



const LighthouseThresholdsModal: React.FC<Props> = (props) => {
	const {
		accountId,
		websiteId,
	} = props;

	const modals = useModals();

	const closeModal = modals.closeModal;

	return (
		<ModalPanel
			alignment={MODAL_ALIGNMENT_TOP}
			canBeClosed={true}
			canBeClosedViaOverlay={false}
			onCloseCallback={closeModal}
			size={MODAL_SIZE_XLARGE}
		>
			<ModalContainer
				gapsSize={0}
				header={(
					<ModalHeader
						iconType={ModalHeaderIconType.CogWheel}
						title={(
							<FormattedMessage {...messages.title} />
						)}
					/>
				)}
				headerGapsSize={1}
			>
				<ModalContentWithSidebar
					gapsSize={1}
					sidebar={(
						<RichText>
							<Copy
								{...messages.sidebar}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/academy/core-web-vitals/'),
								}}
							/>
						</RichText>
					)}
					slimSidebar={true}
				>
					<LighthouseThresholdsForm
						accountId={accountId}
						websiteId={websiteId}
					/>
				</ModalContentWithSidebar>
			</ModalContainer>
		</ModalPanel>
	);
};



export default LighthouseThresholdsModal;
