import React from 'react';

import type CK from '~/types/contentking';

import useAllWebsiteEmailSettings from '~/hooks/useAllWebsiteEmailSettings';



function useWebsiteEmailSettings(websiteId: CK.WebsiteId | null) {
	const allWebsiteEmailSettings = useAllWebsiteEmailSettings();

	return React.useMemo(
		() => {
			return {
				invitations: allWebsiteEmailSettings.listInvitationsByWebsiteId(websiteId),
				isLoaded: allWebsiteEmailSettings.isLoaded,
				users: allWebsiteEmailSettings.listUsersByWebsiteId(websiteId),
			};
		},
		[
			allWebsiteEmailSettings,
			websiteId,
		],
	);
}



export default useWebsiteEmailSettings;
