import type CK from '~/types/contentking';

import {
	useAccountActivePremiumTrialQuery,
} from './useAccountActivePremiumTrial.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountActivePremiumTrial(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountActivePremiumTrialQuery,
		accountId,
	);

	return data?.account?.premiumTrial ?? null;
}



export default useAccountActivePremiumTrial;
