import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CancelButton from '~/components/app/CancelButton';
import CheckListTable from '../../forms/components/CheckListTable';
import Form from '../../forms/basis/Form';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import ModalContentSection from '../parts/ModalContentSection';
import ModalTextSection from '../parts/ModalTextSection';
import SubmitButton from '~/components/app/SubmitButton';



const messages = defineMessages({
	content: {
		id: 'ui.websites.exclusionsModal.importer.description',
	},
	importRules: {
		id: 'ui.websites.exclusionsModal.importer.importRules',
	},
	setupNewList: {
		id: 'ui.websites.exclusionsModal.importer.setupNewList',
	},
	tableHeader: {
		id: 'ui.websites.exclusionsModal.importer.rule',
	},
});



class Importer extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleSubmit = this._handleSubmit.bind(this);
	}



	_handleSubmit(values) {
		const {
			onSubmitCallback,
			robotsRules,
		} = this.props;

		const selectedRules = robotsRules.filter((rule, index) => values.rules.indexOf(index) !== -1);

		onSubmitCallback(
			selectedRules.toArray(),
		);
	}



	render() {
		const {
			onSkipCallback,
			robotsRules,
		} = this.props;

		return (
			<Form
				defaultValues={{
					rules: robotsRules.map((rule, index) => index).toArray(),
				}}
				onSuccess={this._handleSubmit}
			>
				<ModalTextSection>
					<FormattedMessage {...messages.content} />
				</ModalTextSection>

				<ModalContentSection enforceReadableTextLength={true}>
					<CheckListTable
						items={robotsRules.map((rule, index) => {
							return {
								label: rule,
								value: index,
							};
						}).toArray()}
						label={(
							<FormattedMessage {...messages.tableHeader} />
						)}
						name="rules"
					/>

					<ButtonsLayout layout={ButtonsLayoutType.Steps}>
						<CancelButton onClickCallback={onSkipCallback}>
							<FormattedMessage {...messages.setupNewList} />
						</CancelButton>

						<SubmitButton>
							<FormattedMessage {...messages.importRules} />
						</SubmitButton>
					</ButtonsLayout>
				</ModalContentSection>
			</Form>
		);
	}

}

Importer.propTypes = {
	onSkipCallback: PropTypes.func.isRequired,
	onSubmitCallback: PropTypes.func.isRequired,
	robotsRules: PropTypes.object.isRequired,
	website: PropTypes.object.isRequired,
};



export default Importer;
