import React from 'react';

import CK from '~/types/contentking';

import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	type SegmentDefinition,
	filterSegmentDefinitionsByNames,
} from '~/model/segments';



function isPageSegmentAllowedForIgnoring(
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	segmentDefinition: SegmentDefinition,
) {
	if (CK.PagesCommonColumn.Health in segmentDefinition.filterDefinition) {
		return false;
	}

	if (segmentDefinition.sizeLimit?.field === CK.PagesCommonColumn.Health) {
		return false;
	}

	if ('segments' in segmentDefinition.filterDefinition) {
		const dependencySegmentDefinitions = filterSegmentDefinitionsByNames(
			segmentDefinitions,
			[
				...segmentDefinition.filterDefinition.segments.included_in,
				...segmentDefinition.filterDefinition.segments.not_included_in,
			],
		);

		return dependencySegmentDefinitions.every(
			(dependencySegmentDefinition) => isPageSegmentAllowedForIgnoring(
				segmentDefinitions,
				dependencySegmentDefinition,
			),
		);
	}

	return true;
}



function useIsPageSegmentAllowedForIgnoring(
	websiteId: CK.WebsiteId,
) {
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	return React.useCallback(
		(segmentDefinition: SegmentDefinition) => {
			return isPageSegmentAllowedForIgnoring(
				segmentDefinitions.listAll(),
				segmentDefinition,
			);
		},
		[
			segmentDefinitions,
		],
	);
}



export default useIsPageSegmentAllowedForIgnoring;
