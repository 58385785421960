/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddonsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AddonsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly customElementsLimit: number, readonly dataRetentionInMonths: number, readonly enrichmentFieldsLimit: number, readonly id: CK.AccountId, readonly addons: ReadonlyArray<{ readonly __typename?: 'AccountAddonState', readonly addonType: GraphQL.AddonType, readonly amount: number }>, readonly availableAddons: ReadonlyArray<{ readonly __typename?: 'AccountAvailableAddon', readonly addonType: GraphQL.AddonType, readonly maximumAmount: number }> } | null };

export type AdministerAccountAddonsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  addons: ReadonlyArray<GraphQL.AddonAmountInput> | GraphQL.AddonAmountInput;
}>;


export type AdministerAccountAddonsMutation = { readonly __typename?: 'Mutation', readonly AdministerAccountAddons: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly customElementsLimit: number, readonly dataRetentionInMonths: number, readonly enrichmentFieldsLimit: number, readonly id: CK.AccountId, readonly addons: ReadonlyArray<{ readonly __typename?: 'AccountAddonState', readonly addonType: GraphQL.AddonType, readonly amount: number }>, readonly availableAddons: ReadonlyArray<{ readonly __typename?: 'AccountAvailableAddon', readonly addonType: GraphQL.AddonType, readonly maximumAmount: number }>, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }> } | null } } };


export const AddonsFormDocument = gql`
    query AddonsForm($accountId: AccountId!) {
  account(id: $accountId) {
    addons {
      addonType
      amount
    }
    availableAddons {
      addonType
      maximumAmount
    }
    customElementsLimit
    dataRetentionInMonths
    enrichmentFieldsLimit
    id
  }
}
    `;

/**
 * __useAddonsFormQuery__
 *
 * To run a query within a React component, call `useAddonsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddonsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddonsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAddonsFormQuery(baseOptions: Apollo.QueryHookOptions<AddonsFormQuery, AddonsFormQueryVariables> & ({ variables: AddonsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddonsFormQuery, AddonsFormQueryVariables>(AddonsFormDocument, options);
      }
export function useAddonsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddonsFormQuery, AddonsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddonsFormQuery, AddonsFormQueryVariables>(AddonsFormDocument, options);
        }
export function useAddonsFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddonsFormQuery, AddonsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddonsFormQuery, AddonsFormQueryVariables>(AddonsFormDocument, options);
        }
export type AddonsFormQueryHookResult = ReturnType<typeof useAddonsFormQuery>;
export type AddonsFormLazyQueryHookResult = ReturnType<typeof useAddonsFormLazyQuery>;
export type AddonsFormSuspenseQueryHookResult = ReturnType<typeof useAddonsFormSuspenseQuery>;
export type AddonsFormQueryResult = Apollo.QueryResult<AddonsFormQuery, AddonsFormQueryVariables>;
export const AdministerAccountAddonsDocument = gql`
    mutation AdministerAccountAddons($accountId: AccountId!, $addons: [AddonAmountInput!]!) {
  AdministerAccountAddons(accountId: $accountId, addons: $addons) {
    query {
      account(id: $accountId) {
        addons {
          addonType
          amount
        }
        availableAddons {
          addonType
          maximumAmount
        }
        customElementsLimit
        dataRetentionInMonths
        enrichmentFieldsLimit
        features {
          feature
          id
        }
        id
      }
    }
  }
}
    `;
export type AdministerAccountAddonsMutationFn = Apollo.MutationFunction<AdministerAccountAddonsMutation, AdministerAccountAddonsMutationVariables>;

/**
 * __useAdministerAccountAddonsMutation__
 *
 * To run a mutation, you first call `useAdministerAccountAddonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerAccountAddonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerAccountAddonsMutation, { data, loading, error }] = useAdministerAccountAddonsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      addons: // value for 'addons'
 *   },
 * });
 */
export function useAdministerAccountAddonsMutation(baseOptions?: Apollo.MutationHookOptions<AdministerAccountAddonsMutation, AdministerAccountAddonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerAccountAddonsMutation, AdministerAccountAddonsMutationVariables>(AdministerAccountAddonsDocument, options);
      }
export type AdministerAccountAddonsMutationHookResult = ReturnType<typeof useAdministerAccountAddonsMutation>;
export type AdministerAccountAddonsMutationResult = Apollo.MutationResult<AdministerAccountAddonsMutation>;
export type AdministerAccountAddonsMutationOptions = Apollo.BaseMutationOptions<AdministerAccountAddonsMutation, AdministerAccountAddonsMutationVariables>;