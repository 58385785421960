import React from 'react';

import GraphQL from '~/types/graphql';

import SelectField, {
	SelectFieldSize,
} from '~/components/atoms/forms/components/SelectField';
import UserRoleName from '~/components/names/UserRoleName';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountId from '~/hooks/useAccountId';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';

import {
	ROLES_LIST,
} from '~/model/users';



type Props = {
	name: string,
	size?: SelectFieldSize,
	width?: number,
};

const RoleSelectField: React.FC<Props> = (props) => {
	const {
		name,
		size,
		width = 150,
	} = props;

	const accountId = useAccountId();

	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);

	const canUserControlAdminRole = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageOrganizationAccessControl,
	);

	const isAdminRoleAvailable = accountEnabledFeatures?.includes(GraphQL.AccountFeature.AdminRole);

	const options = React.useMemo(
		() => {
			return ROLES_LIST.filter(
				(role) => {
					if (role === GraphQL.UserRole.Admin) {
						return isAdminRoleAvailable && canUserControlAdminRole.yes;
					}

					return true;
				},
			).map(
				(role) => ({
					label: (
						<UserRoleName role={role} />
					),
					name: role,
				}),
			);
		},
		[
			canUserControlAdminRole.yes,
			isAdminRoleAvailable,
		],
	);

	return (
		<SelectField
			dropdownWidth={width}
			name={name}
			options={options}
			size={size}
			width={width}
		/>
	);
};



export default React.memo(RoleSelectField);

export {
	SelectFieldSize as RoleSelectFieldSize,
};
