import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import {
	compileDefinitionTermsNew,
} from './compileDefinitionTerms';
import Caption, {
	CaptionStyle,
} from '~/components/patterns/headings/Caption';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import ExternalLink from '~/components/patterns/links/ExternalLink';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import usePageDetailSourceMode from '~/hooks/usePageDetailSourceMode';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	useOpenGraphBlockPartPrimaryQuery,
	useOpenGraphBlockPartSrcQuery,
} from './OpenGraphBlockPart.gql';



const messages = defineMessages({
	openGraphDescription: {
		id: 'ui.contentData.open_graph_description',
	},
	openGraphImage: {
		id: 'ui.contentData.open_graph_image',
	},
	openGraphTitle: {
		id: 'ui.contentData.open_graph_title',
	},
	openGraphType: {
		id: 'ui.contentData.open_graph_type',
	},
	openGraphUrl: {
		id: 'ui.contentData.open_graph_url',
	},
	title: {
		id: 'ui.pageDetail.openGraphBlock.title',
	},
});



const OpenGraphBlockPart: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const pageDetailSourceMode = usePageDetailSourceMode();
	const websiteId = useWebsiteId();

	const {
		data: primaryData,
	} = usePageDetailPropertiesQuery(
		useOpenGraphBlockPartPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	const {
		data: srcData,
	} = usePageDetailPropertiesQuery(
		useOpenGraphBlockPartSrcQuery,
		legacyUrlId,
		websiteId,
		{
			skip: pageDetailSourceMode !== GraphQL.PageDetailSourceMode.Compare,
		},
	);

	const primaryPropertyData = primaryData?.lookupPageByLegacyId?.pageTypeData?.primaryProperties ?? null;
	const srcPropertyData = srcData?.lookupPageByLegacyId?.pageTypeData?.srcProperties ?? null;

	return (
		<DefinitionTermsList>
			<Caption style={CaptionStyle.Highlighted}>
				<FormattedMessage {...messages.title} />
			</Caption>
			{compileDefinitionTermsNew(
				[
					{
						alwaysVisible: false,
						property: 'openGraphType',
						name: 'open_graph_type',
						label: (
							<FormattedMessage {...messages.openGraphType} />
						),
					},
					{
						alwaysVisible: false,
						property: 'openGraphUrl',
						name: 'open_graph_url',
						label: (
							<FormattedMessage {...messages.openGraphUrl} />
						),
					},
					{
						alwaysVisible: true,
						property: 'openGraphTitle',
						name: 'open_graph_title',
						label: (
							<FormattedMessage {...messages.openGraphTitle} />
						),
					},
					{
						alwaysVisible: true,
						property: 'openGraphImage',
						name: 'open_graph_image',
						label: (
							<FormattedMessage {...messages.openGraphImage} />
						),
						valueFormatter: (value) => {
							if (value && value !== '') {
								return (
									<ExternalLink href={value}>
										{value}
									</ExternalLink>
								);
							}

							return value;
						},
					},
					{
						alwaysVisible: true,
						property: 'openGraphDescription',
						name: 'open_graph_description',
						label: (
							<FormattedMessage {...messages.openGraphDescription} />
						),
					},
				],
				{
					compare: pageDetailSourceMode === GraphQL.PageDetailSourceMode.Compare,
					propertyData: primaryPropertyData,
					srcPropertyData,
				},
			)}
		</DefinitionTermsList>
	);
};



export default React.memo(OpenGraphBlockPart);
