import React from 'react';



const EXCLUDE_ELEMENTS = `
	.form-wrapper-container--in-edit-mode,
	.select-field__virtual-select.select-field__virtual-select--open,
	.select-field__dropdown,
	.select-field__search-field,
	.editable-value__text-field
`;



function useCloseByEscape(
	onCloseCallback: () => void,
): void {
	React.useEffect(() => {
		function listener(event: KeyboardEvent): void {
			if (
				event.target instanceof Element
				&& event.target.closest(EXCLUDE_ELEMENTS)
			) {
				return;
			}

			if (event.key === 'Escape') {
				onCloseCallback();
			}
		}

		document.addEventListener('keyup', listener);

		return (): void => {
			document.removeEventListener('keyup', listener);
		};
	}, [onCloseCallback]);
}



export default useCloseByEscape;
