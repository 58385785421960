import {
	produce,
} from 'immer';
import {
	type GetState,
	type SetState,
	type State,
	create,
} from 'zustand';



type ModifyState<TState> = (modifyCallback: (state: TState) => void) => void;

type CreateCallback<TState extends State> = (value: {
	get: GetState<TState>,
	modify: ModifyState<TState>,
	set: SetState<TState>,
}) => TState;



function createStore<TState extends State>(createCallback: CreateCallback<TState>) {
	return create<TState>((set, get) => createCallback({
		get,
		set,
		modify: (modifyCallback: (state: TState) => void) => {
			set((state) => produce(state, modifyCallback));
		},
	}));
}



export default createStore;
