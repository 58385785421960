import React from 'react';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	depthJump?: number,
	children: RenderProp<{ depthLayer: number }>,
};

const DepthLayerContext = React.createContext<number>(1);



const DepthLayer: React.FC<Props> = (props) => {
	const {
		children,
		depthJump = 1,
	} = props;

	return (
		<DepthLayerContext.Consumer>
			{(depthLayer) => (
				<DepthLayerContext.Provider value={depthLayer + depthJump}>
					{renderProp(
						children,
						{
							depthLayer,
						},
					)}
				</DepthLayerContext.Provider>
			)}
		</DepthLayerContext.Consumer>
	);
};



export default DepthLayer;

export {
	DepthLayerContext,
};
