/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountPhaseQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountPhaseQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly phase: GraphQL.AccountPhase } | null };


export const AccountPhaseDocument = gql`
    query AccountPhase($accountId: AccountId!) {
  account(id: $accountId) {
    id
    phase
  }
}
    `;

/**
 * __useAccountPhaseQuery__
 *
 * To run a query within a React component, call `useAccountPhaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPhaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPhaseQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountPhaseQuery(baseOptions: Apollo.QueryHookOptions<AccountPhaseQuery, AccountPhaseQueryVariables> & ({ variables: AccountPhaseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountPhaseQuery, AccountPhaseQueryVariables>(AccountPhaseDocument, options);
      }
export function useAccountPhaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountPhaseQuery, AccountPhaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountPhaseQuery, AccountPhaseQueryVariables>(AccountPhaseDocument, options);
        }
export function useAccountPhaseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountPhaseQuery, AccountPhaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountPhaseQuery, AccountPhaseQueryVariables>(AccountPhaseDocument, options);
        }
export type AccountPhaseQueryHookResult = ReturnType<typeof useAccountPhaseQuery>;
export type AccountPhaseLazyQueryHookResult = ReturnType<typeof useAccountPhaseLazyQuery>;
export type AccountPhaseSuspenseQueryHookResult = ReturnType<typeof useAccountPhaseSuspenseQuery>;
export type AccountPhaseQueryResult = Apollo.QueryResult<AccountPhaseQuery, AccountPhaseQueryVariables>;