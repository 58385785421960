import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import DestructConfirmationModal from '~/components/app/DestructConfirmationModal';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';

import useRevokeAgencyAccess from '~/hooks/useRevokeAgencyAccess';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	confirmButton: {
		id: 'ui.revokeAccessForConnectedAccountConfirmation.confirmButton',
	},
	description: {
		id: 'ui.revokeAccessForConnectedAccountConfirmation.description',
	},
	labelConnectedAccount: {
		id: 'ui.revokeAccessForConnectedAccountConfirmation.labelConnectedAccount',
	},
	title: {
		id: 'ui.revokeAccessForConnectedAccountConfirmation.title',
	},
	verb: {
		id: 'ui.revokeAccessForConnectedAccountConfirmation.verb',
	},
	warnPermanent: {
		id: 'ui.revokeAccessForConnectedAccountConfirmation.warnPermanent',
	},
});



type Props = {
	agencyAccountId: CK.AccountId,
	agencyAccountName: string,
	clientAccountId: CK.AccountId,
};

const RevokeAccessForConnectedAccountConfirmationModal: React.FC<Props> = (props) => {
	const {
		agencyAccountId,
		agencyAccountName,
		clientAccountId,
	} = props;

	const revokeAgencyAccess = useRevokeAgencyAccess();

	const handleRemoval = React.useCallback(
		async () => {
			try {
				await revokeAgencyAccess({
					agencyId: agencyAccountId,
					clientId: clientAccountId,
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}
		},
		[
			agencyAccountId,
			clientAccountId,
			revokeAgencyAccess,
		],
	);

	return (
		<DestructConfirmationModal
			confirmButton={(
				<FormattedMessage {...messages.confirmButton} />
			)}
			confirmationPhrase={agencyAccountName}
			description={(
				<FormattedMessage {...messages.description} />
			)}
			onConfirmation={handleRemoval}
			titleAction={(
				<FormattedMessage {...messages.verb} />
			)}
			titleObject={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Copy {...messages.warnPermanent} />

			<LabeledValuesBox
				items={[
					{
						label: <FormattedMessage {...messages.labelConnectedAccount} />,
						value: agencyAccountName,
					},
				]}
			/>
		</DestructConfirmationModal>
	);
};



export default RevokeAccessForConnectedAccountConfirmationModal;
