import {
	setExtra,
} from '@sentry/browser';
import React from 'react';

import {
	type Orientation,
	type Viewport,
	getCurrentDeviceOrientation,
	getCurrentViewportSize,
} from '~/utilities/viewportSize';



type Context = {
	windowSize: {
		width: number,
		height: number,
	},
	type: Viewport,
	orientation: Orientation,
};



function calculateState(): Context {
	const height = window.innerHeight;
	const width = window.innerWidth;

	return {
		windowSize: {
			width,
			height,
		},
		type: getCurrentViewportSize(width),
		orientation: getCurrentDeviceOrientation(width, height),
	};
}



const defaultViewport = calculateState();



export const ViewportContext = React.createContext<Context>(defaultViewport);



type Props = {
	children?: React.ReactNode,
};

const ViewportProvider: React.FC<Props> = ({ children }) => {
	const [viewport, setViewport] = React.useState(defaultViewport);

	React.useLayoutEffect(
		() => {
			function handleResize(): void {
				setViewport(calculateState());
			}

			setViewport(calculateState());
			window.addEventListener('resize', handleResize);

			return (): void => {
				window.removeEventListener('resize', handleResize);
			};
		},
		[],
	);

	React.useEffect(
		() => {
			setExtra('viewport', viewport);
		},
		[
			viewport,
		],
	);

	return (
		<ViewportContext.Provider value={viewport}>
			{children}
		</ViewportContext.Provider>
	);
};



export default React.memo<React.FC<Props>>(ViewportProvider);
