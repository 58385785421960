import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	filtersActive: {
		id: 'ui.filteringToolbar.active.mobile',
	},
});



type Props = {
	activeFiltersCount: number,
};

const ActiveMobileFilter: React.FC<Props> = (props) => {
	const {
		activeFiltersCount,
	} = props;

	return (
		<div className="filtering-tag">
			<FormattedMessage
				{...messages.filtersActive}
				values={{
					count: activeFiltersCount,
				}}
			/>
		</div>
	);
};



export default ActiveMobileFilter;
