import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ShoppingCartDisableIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="m18 12c-3.3137085 0-6 2.6862915-6 6s2.6862915 6 6 6 6-2.6862915 6-6c-.0038574-3.3121094-2.6878906-5.9961426-6-6zm0 1.9999978c.5971526-.0006277 1.1867661.1333598 1.725.3920022.0721911.0353839.1229604.1033269.1364345.1825861.0134741.0792593-.0119914.1601622-.0684345.2174139l-5 5c-.0576566.0569279-.1390136.0829324-.219.07-.0800118-.0137312-.1483064-.0656052-.183-.139-.5914713-1.239201-.505979-2.6951726.2264553-3.856636s2.0094252-1.8660602 3.3825447-1.8663662zm0 8.000003c-.5963701.0003673-1.1852033-.1332564-1.723-.3910008-.0726931-.0348567-.1240121-.1027504-.1377163-.1821952-.0137042-.0794447.0119024-.1606081.0687163-.2178048l5-5c.0571967-.0568139.1383601-.0824205.2178048-.0687163.0794448.0137042.1473385.0650232.1821952.1377163.5909305 1.2389501.5053022 2.6943716-.2268336 3.8554803-.7321357 1.1611088-2.0085063 1.8657092-3.3811664 1.8665205z"
				fill={color}
			/>
			<path
				d="m12.657 14c0-.5522847-.4477153-1-1-1h-3.418c-.11416427-.0019124-.21252619-.0809312-.239-.192l-1.15-5c-.01759216-.07371265-.00079878-.15142622.04566007-.21129787.04645884-.05987165.11756764-.09543788.19333993-.09670213h13.191c.1606336.00012194.3114271.07741287.4053174.20775005.0938904.13033718.1194429.29784731.0686826.45024995l-.754 2.248c-.1143566.3397509-.0383675.7147147.1992398.9831377s.6005796.38935.9516964.3170652c.3511168-.0722849.636806-.3267527.7490638-.6672029l1-3c.1959686-.58790819.097551-1.23416941-.2645377-1.73709238-.3620887-.50292296-.9437532-.80126161-1.5634623-.80190762h-14.558c-.1167585.00053909-.21821094-.08012392-.244-.194l-.878-3.819c-.20247618-.87306655-.98176781-1.49010949-1.878-1.4870117h-2.514c-.55228475 0-1 .44772695-1 1.0000117s.44771525 1 1 1l2.24-.055c.11860844-.00223834.22271749.07855028.25.194l3.357 14.6c.02331454.102022-.01938335.2077691-.107.265-.84997142.553409-1.22460749 1.6070831-.91478559 2.5728591.30982191.9657761 1.22750095 1.6048742 2.2407868 1.5605485s1.87165218-.7611161 2.09595719-1.7502573c.2243049-.9891412-.24090899-2.0060764-1.1359584-2.4831503-.06367113-.0344414-.10953934-.0945069-.126-.165l-.33-1.437c-.01563515-.073518.00263644-.1501692.04975524-.2087284s.11808397-.0928112.19324476-.0932716h2.844c.5522847 0 1-.4477153 1-1z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ShoppingCartDisableIconBuilder;
