import React from 'react';



const HeaderCaption = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="header-caption">
			{children}
		</div>
	);
};



export default HeaderCaption;
