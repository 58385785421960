import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CalloutMessage from '~/components/patterns/messages/embedded/CalloutMessage';
import FormPaymentMessage from '~/components/atoms/payments/FormPaymentMessage';



const messages = defineMessages({
	description: {
		id: 'ui.upgradeToPaid.paymentMethodStep.invoice.description',
	},
	message: {
		id: 'ui.upgradeToPaid.paymentMethodStep.invoice.message',
	},
});



type Props = {
	email: string,
};

const InvoicePaymentStates: React.FC<Props> = (props) => {
	const {
		email,
	} = props;

	return (
		<FormPaymentMessage>
			<CalloutMessage
				centeredContent={true}
				message={(
					<FormattedMessage {...messages.message} />
				)}
			>
				<FormattedMessage
					{...messages.description}
					values={{
						email,
					}}
				/>
			</CalloutMessage>
		</FormPaymentMessage>
	);
};



export default InvoicePaymentStates;
