import React from 'react';

import GraphQL from '~/types/graphql';

import DataProcessingAgreementModal from '~/components/app/DataProcessingAgreementModal';

import useDataProcessingAgreementPromptSnooze from '~/hooks/useDataProcessingAgreementPromptSnooze';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useLegalDocumentRequirement from '~/hooks/useLegalDocumentRequirement';
import useModals from '~/hooks/useModals';
import useRoyalMode from '~/hooks/useRoyalMode';



const DataProcessingAgreementModalPrompt: React.FC = () => {
	const dataProcessingAgreementPromptSnooze = useDataProcessingAgreementPromptSnooze();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const modals = useModals();
	const royalMode = useRoyalMode();

	const canAcceptAgreement = useIsAllowedWithAccount(
		effectiveHomeAccountId,
		GraphQL.ActionWithAccount.ManageBilling,
	);

	const openModal = modals.openModal;

	const [showModal] = React.useState(dataProcessingAgreementPromptSnooze.isSnoozed === false);

	const requirement = useLegalDocumentRequirement(
		effectiveHomeAccountId,
		GraphQL.LegalDocumentType.Dpa,
	);

	React.useEffect(
		() => {
			if (
				canAcceptAgreement.yes
				&& showModal
				&& requirement.document?.requirement === GraphQL.LegalDocumentRequirementType.RequiredWithUrgency
				&& royalMode.isImpersonated === false
			) {
				openModal(() => (
					<DataProcessingAgreementModal
						canBeClosed={true}
					/>
				));
			}
		},
		[
			canAcceptAgreement.yes,
			openModal,
			requirement.document?.requirement,
			royalMode,
			showModal,
		],
	);

	React.useEffect(
		() => {
			const interval = setInterval(
				() => dataProcessingAgreementPromptSnooze.tryResetSnooze(),
				10000,
			);

			return () => clearInterval(interval);
		},
		[
			dataProcessingAgreementPromptSnooze,
		],
	);

	return null;
};



export default DataProcessingAgreementModalPrompt;
