import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import PureRadioFields, {
	SIZE_DEFAULT as BUILDER_SIZE_DEFAULT,
	SIZE_SMALL as BUILDER_SIZE_SMALL,
	ITEM_STYLE_TAG as LIST_ITEM_STYLE_TAG,
	ITEM_STYLE_TEXTUAL as LIST_ITEM_STYLE_TEXTUAL,
} from './PureRadioFields';



export const ITEM_STYLE_TAG = LIST_ITEM_STYLE_TAG;
export const ITEM_STYLE_TEXTUAL = LIST_ITEM_STYLE_TEXTUAL;
export const SIZE_DEFAULT = BUILDER_SIZE_DEFAULT;
export const SIZE_SMALL = BUILDER_SIZE_SMALL;



class AbstractRadioList extends Component {

	_handleFieldClick(index) {
		this['inputElement' + index].click();
	}



	_wrapPropertyGetter(value) {
		return value instanceof Function
			? value
			: () => value;
	}



	_wrapItemClassNameGetter(itemClassName, value, checked, disabled, readonly) {
		return this._wrapPropertyGetter(itemClassName).call(this, value, checked, disabled, readonly);
	}



	render() {
		const {
			className,
			fieldClassName,
			inline,
			isControlled,
			itemClassName,
			itemFlexBehavior,
			itemMaxWidth,
			itemStyle,
			items,
			name,
			onBlurCallback,
			onFocusCallback,
			onChangeCallback,
			size,
			width,
			wrapItems,
		} = this.props;

		const style = {};

		if (width) {
			style.width = width;
		}

		const componentClasses = classNames(
			{
				'radio-list': true,
				'radio-list--wrap-items': wrapItems,
				[ 'radio-list--' + itemStyle + '-style' ]: true,
			},
			className,
		);

		const containerClasses = classNames({
			'radio-list__container': true,
			'radio-list__container--inline': inline,
		});

		return (
			<div
				className={componentClasses}
				style={style}
			>
				<div className={containerClasses}>
					<PureRadioFields
						fieldClassName={fieldClassName}
						isControlled={isControlled}
						itemRenderer={({ data, field, index }) => {
							const itemStyle = {};

							if (inline) {
								itemStyle.flex = itemFlexBehavior;
								itemStyle.maxWidth = itemMaxWidth;
							}

							const isChecked = data.checked;

							const itemClasses = classNames({
								'radio-list__item': true,
								'radio-list__item--is-disabled': data.disabled,
								'radio-list__item--is-readonly': data.readonly,
								'radio-list__item--is-checked': isChecked,
								'radio-list__item--is-inline': inline,
							}, itemClassName && this._wrapItemClassNameGetter(itemClassName, data.value, isChecked, data.disabled, data.readonly));

							return (
								<div
									className={itemClasses}
									key={index}
									style={itemStyle}
								>
									<div className="radio-list__item-container">
										{field}
									</div>
								</div>
							);
						}}
						itemStyle={itemStyle}
						items={items}
						name={name}
						onBlurCallback={onBlurCallback}
						onChangeCallback={onChangeCallback}
						onFocusCallback={onFocusCallback}
						size={size}
					/>
				</div>
			</div>
		);
	}

}

AbstractRadioList.defaultProps = {
	inline: false,
	isControlled: false,
	itemFlexBehavior: '0 0 auto',
	itemStyle: ITEM_STYLE_TEXTUAL,
	size: SIZE_DEFAULT,
	wrapItems: true,
};

AbstractRadioList.propTypes = {
	className: PropTypes.string,
	fieldClassName: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string,
	]),
	inline: PropTypes.bool,
	isControlled: PropTypes.bool,
	itemClassName: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string,
	]),
	itemFlexBehavior: PropTypes.string,
	itemMaxWidth: PropTypes.number,
	itemStyle: PropTypes.oneOf([
		ITEM_STYLE_TEXTUAL,
		ITEM_STYLE_TAG,
	]),
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	name: PropTypes.string.isRequired,
	/** Callback triggered on field blur */
	onBlurCallback: PropTypes.func,
	/** Callback triggered on value change */
	onChangeCallback: PropTypes.func,
	/** Callback triggered on field focus */
	onFocusCallback: PropTypes.func,
	size: PropTypes.oneOf([
		SIZE_DEFAULT,
		SIZE_SMALL,
	]).isRequired,
	width: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.bool,
	]),
	wrapItems: PropTypes.bool,
};



export default AbstractRadioList;
