import React from 'react';

import type CK from '~/types/contentking';

import FormsList from '~/components/atoms/lists/FormsList';
import MarketingEmailSettingsForm from '~/components/atoms/forms/forms/MarketingEmailSettingsForm';
import WebsitesReportsAlertsForm from '~/components/atoms/forms/forms/WebsitesReportsAlertsForm';

import useUserMemberships from '~/hooks/useUserMemberships';



type Props = {
	legacyUserId: string,
} & ({
	accountId: CK.AccountId,
	isForCurrentUser: false,
} | {
	accountId?: never,
	isForCurrentUser: true,
});

const EmailSettingsGroup: React.FC<Props> = (props) => {
	const {
		accountId,
		isForCurrentUser,
		legacyUserId,
	} = props;

	let userMemberships = useUserMemberships(legacyUserId);

	if (isForCurrentUser === false && userMemberships !== null) {
		userMemberships = userMemberships.filter(
			(membership) => membership.account.id === accountId,
		);
	}

	return (
		<FormsList>
			<WebsitesReportsAlertsForm
				legacyUserId={legacyUserId}
				userAccountMemberships={userMemberships}
			/>

			<MarketingEmailSettingsForm
				isForCurrentUser={isForCurrentUser}
				legacyUserId={legacyUserId}
			/>
		</FormsList>
	);
};



export default EmailSettingsGroup;
