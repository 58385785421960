import type CK from '~/types/contentking';

import useAccountProblems from '~/hooks/useAccountProblems';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountType from '~/hooks/useAccountType';
import useAccountWebsiteCount from '~/hooks/useAccountWebsiteCount';
import useAccountWebsiteRestrictions from '~/hooks/useAccountWebsiteRestrictions';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';

import {
	normalizePageCapacity,
} from '~/model/pricing/tariffs';



export enum AddingWebsiteDisallowedReason {
	MaximumPageBundleReached,
	MaximumWebsitesLimitReached,
	PaymentIssues,
}



function useAddingWebsiteDisallowedReason(accountId: CK.AccountId | null) {
	const accountProblems = useAccountProblems(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountType = useAccountType(accountId);
	const accountWebsiteCount = useAccountWebsiteCount(accountId);
	const accountWebsiteRestrictions = useAccountWebsiteRestrictions(accountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);

	if (
		accountWebsiteRestrictions !== null
		&& accountWebsiteSizes !== null
		&& accountTariff !== null
		&& accountType !== null
	) {
		if (
			accountWebsiteRestrictions.maximumWebsites !== null
			&& accountWebsiteCount !== null
			&& accountWebsiteCount >= accountWebsiteRestrictions.maximumWebsites
		) {
			return AddingWebsiteDisallowedReason.MaximumWebsitesLimitReached;
		}

		if (accountWebsiteRestrictions.maximumPageBundle !== null) {
			const minimumPageCapacity = normalizePageCapacity({
				accountType,
				pageCapacity: 1,
				tariff: accountTariff,
			});

			if ((accountWebsiteSizes.totalPageCapacity + minimumPageCapacity) >= accountWebsiteRestrictions.maximumPageBundle) {
				return AddingWebsiteDisallowedReason.MaximumPageBundleReached;
			}
		}
	}

	if (accountProblems.hasPaymentProblem) {
		return AddingWebsiteDisallowedReason.PaymentIssues;
	}

	return null;
}



export default useAddingWebsiteDisallowedReason;
