import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import AbstractTextField, {
	AbstractTextFieldSize,
	AbstractTextFieldType,
} from '~/components/patterns/forms/fields/AbstractTextField';
import {
	DatatableContext,
} from '~/components/logic/datatables/Datatable';
import FilterFieldLayout from '~/components/patterns/filtering/FilterFieldLayout';
import InteractiveTableColumn, {
	type InteractiveTableColumnRef,
} from '~/components/atoms/dataTables/parts/InteractiveTableColumn';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';



const messages = defineMessages({
	searchPlaceholder: {
		id: 'ui.general.search.placeholder',
	},
	title: {
		id: 'ui.segments.editor.addColumn.title',
	},
});



type Props = {
	children?: React.ReactNode,
	isOpen?: boolean,
	onChangeCallback: (input: {
		visible: boolean,
	}) => void,
	onClickCallback: () => void,
	onSearchCallback: (searchTerm: string) => void,
	onRequestClose: () => void,
	width?: number,
};

const ColumnAddColumn: React.FC<Props> = (props) => {
	const {
		children,
		isOpen = false,
		onChangeCallback,
		onClickCallback,
		onSearchCallback,
		onRequestClose,
		width,
	} = props;

	const {
		datatableHeaderHeight: headerHeight,
		datatableHeight: tableHeight,
		isScrollableHorizontally,
		scrollbarSize,
	} = React.useContext(DatatableContext);

	const intl = useIntl();

	const handleOpenCallbacks = React.useCallback(
		() => {
			onChangeCallback({
				visible: true,
			});

			onClickCallback();
		},
		[
			onChangeCallback,
			onClickCallback,
		],
	);

	const handleCloseCallbacks = React.useCallback(
		() => {
			onChangeCallback({
				visible: false,
			});

			onRequestClose();
		},
		[
			onChangeCallback,
			onRequestClose,
		],
	);

	const ref = React.useRef<InteractiveTableColumnRef | null>(null);
	React.useEffect(
		() => {
			onChangeCallback({ visible: isOpen });

			if (!isOpen) {
				return;
			}

			function keyListener(e) {
				if (e.key === 'Escape') {
					handleCloseCallbacks();
				}
			}

			function mouseListener(e) {
				if (
					(ref.current && ref.current.contains(e.target))
					|| e.target.hasAttribute('data-markjs')
					|| e.target.tagName === 'input'
					|| e.target.querySelectorAll('[data-markjs]').length > 0
				) {
					return;
				}

				e.preventDefault();
				e.stopPropagation();

				handleCloseCallbacks();
			}

			setTimeout(() => {
				document.addEventListener('click', mouseListener);
				document.addEventListener('keydown', keyListener);
			}, 1);

			return () => {
				document.removeEventListener('click', mouseListener);
				document.removeEventListener('keydown', keyListener);
			};
		},
		[
			handleCloseCallbacks,
			isOpen,
			onChangeCallback,
			onRequestClose,
		],
	);

	let columnHeight = tableHeight;

	if (isScrollableHorizontally) {
		columnHeight -= scrollbarSize;
	}

	return (
		<InteractiveTableColumn
			header={(
				<FilterFieldLayout
					field={isOpen ? (
						<AbstractTextField
							attributes={{
								placeholder: intl.formatMessage(messages.searchPlaceholder),
							}}
							name="column-add-column-search"
							onChangeCallback={onSearchCallback}
							size={AbstractTextFieldSize.Small}
							type={AbstractTextFieldType.Search}
							width={260}
						/>
					) : null}
					label={(
						<TableLabel
							centerAligned={true}
							isHighlighted={true}
							label={<FormattedMessage {...messages.title} />}
						/>
					)}
				/>
			)}
			headerHeight={headerHeight}
			height={columnHeight}
			isHighlighted={isOpen}
			onClickCallback={handleOpenCallbacks}
			ref={ref}
			width={width}
		>
			{isOpen ? children : null}
		</InteractiveTableColumn>
	);
};



export default ColumnAddColumn;
