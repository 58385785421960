import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountIsShadowQuery,
} from './useAccountIsShadow.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsShadow(accountId: CK.AccountId | null): GraphQL.Account['isOutOfBand'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountIsShadowQuery,
		accountId,
	);

	return data?.account?.isShadow ?? null;
}



export default useAccountIsShadow;
