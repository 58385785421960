import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AlertDefinitionRecipientsList from '~/components/app/AlertDefinitionRecipientsList';
import AlertTypeTitle from '~/components/logic/alerts/AlertTypeTitle';
import Copy from '~/components/logic/Copy';
import DestructConfirmationModal from '~/components/app/DestructConfirmationModal';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';
import MarginsList from '~/components/atoms/lists/MarginsList';
import Measurer from '~/utilities/Measurer';
import ScopeLabel, {
	ScopeLabelSize,
	ScopeLabelStyle,
} from '~/components/logic/scopes/ScopeLabel';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';

import {
	useBulkDeleteAlertDefinitionsMutation,
} from './DeleteAlertDefinitionConfirmationModal.gql';

import useClearAlertDefinitionsBulkSelection from '~/hooks/useClearAlertDefinitionsBulkSelection';
import useSetFlashMessage from '~/hooks/useSetFlashMessage';
import useWebsiteAlertDefinitions from '~/hooks/useWebsiteAlertDefinitions';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
} from '~/model/alerts';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	confirmButton: {
		id: 'ui.deleteAlertDefinitionConfirmation.confirmButton',
	},
	labelFromWebsite: {
		id: 'ui.deleteAlertDefinitionConfirmation.labelFromWebsite',
	},
	labelRecipients: {
		id: 'ui.deleteAlertDefinitionConfirmation.labelRecipients',
	},
	labelScope: {
		id: 'ui.deleteAlertDefinitionConfirmation.labelScope',
	},
	labelType: {
		id: 'ui.deleteAlertDefinitionConfirmation.labelType',
	},
	title: {
		id: 'ui.deleteAlertDefinitionConfirmation.title',
	},
	verb: {
		id: 'ui.deleteAlertDefinitionConfirmation.verb',
	},
	warnPermanent: {
		id: 'ui.deleteAlertDefinitionConfirmation.warnPermanent',
	},
});



type Props = {
	selectedAlertDefinitionIds: ReadonlyArray<string>,
};

const DeleteAlertDefinitionConfirmationModal: React.FC<Props> = (props) => {
	const {
		selectedAlertDefinitionIds,
	} = props;

	const websiteId = useWebsiteId();

	const alertDefinitions = useWebsiteAlertDefinitions(websiteId);
	const clearAlertDefinitionsBulkSelection = useClearAlertDefinitionsBulkSelection();
	const setFlashMessage = useSetFlashMessage();
	const websiteDisplayName = useWebsiteDisplayName(websiteId);

	const [bulkDeleteAlertDefinitions] = useBulkDeleteAlertDefinitionsMutation();

	const selectedAlertDefinitions = React.useMemo(
		() => {
			return alertDefinitions.listAll().filter(
				(alertDefinition) => selectedAlertDefinitionIds.includes(alertDefinition.id),
			);
		},
		[
			alertDefinitions,
			selectedAlertDefinitionIds,
		],
	);

	const handleDeletion = React.useCallback(
		async (): Promise<void> => {
			const alertDefinitionIds = selectedAlertDefinitions.map(
				(alertDefinition) => alertDefinition.id,
			);

			await bulkDeleteAlertDefinitions({
				variables: {
					alertDefinitionIds,
					websiteId,
				},
			});

			setFlashMessage({
				message: 'alertDefinitionsDeleted',
				messageVariables: {
					number__alertDefinitions: alertDefinitionIds.length,
				},
				route: 'website.alerts.configuration',
				style: 'success',
			});

			clearAlertDefinitionsBulkSelection();
		},
		[
			bulkDeleteAlertDefinitions,
			clearAlertDefinitionsBulkSelection,
			selectedAlertDefinitions,
			setFlashMessage,
			websiteId,
		],
	);

	function renderSingleAlertDefinitionDetails() {
		const alertDefinition = getArrayItemAtSafeIndex(selectedAlertDefinitions, 0);

		const scope = alertDefinition.alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT
			? 'segment:' + alertDefinition.settings.segmentName
			: alertDefinition.scope;

		return (
			<LabeledValuesBox
				items={[
					{
						label: (
							<FormattedMessage {...messages.labelType} />
						),
						value: (
							<AlertTypeTitle
								alertType={alertDefinition.alertType}
								settings={alertDefinition.settings}
							/>
						),
					},
					{
						label: (
							<FormattedMessage {...messages.labelScope} />
						),
						value: (
							<ScopeLabel
								scope={scope}
								showHint={false}
								style={ScopeLabelStyle.Label}
							/>
						),
					},
					{
						label: (
							<FormattedMessage {...messages.labelRecipients} />
						),
						value: (
							<AlertDefinitionRecipientsList
								containerWidth={262}
								recipients={alertDefinition.recipients}
							/>
						),
					},
					{
						label: (
							<FormattedMessage {...messages.labelFromWebsite} />
						),
						value: websiteDisplayName,
					},
				]}
			/>
		);
	}

	function renderMultipleAlertDefinitionsDetails() {
		return (
			<MarginsList>
				<Measurer>
					{({ containerWidth }) => {
						const firstColumnWidth = Math.floor(containerWidth * 0.5) - 2;
						const secondColumnWidth = Math.floor(containerWidth * 0.3) - 2;
						const thirdColumnWidth = containerWidth - firstColumnWidth - secondColumnWidth - 2;

						return (
							<SmallTable
								columns={[
									{
										render: {
											cell: ({ row }) => {
												return (
													<Ellipsis>
														<AlertTypeTitle
															alertType={row.alertType}
															settings={row.settings}
														/>
													</Ellipsis>
												);
											},
											header: () => (
												<FormattedMessage {...messages.labelType} />
											),
										},
										width: firstColumnWidth,
									},
									{
										render: {
											cell: ({ row }) => {
												const scope = row.alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT
													? 'segment:' + row.settings.segmentName
													: row.scope;

												return (
													<Ellipsis>
														<ScopeLabel
															scope={scope}
															showHint={false}
															size={ScopeLabelSize.Small}
															style={ScopeLabelStyle.Label}
														/>
													</Ellipsis>
												);
											},
											header: () => (
												<FormattedMessage {...messages.labelScope} />
											),
										},
										width: secondColumnWidth,
									},
									{
										render: {
											cell: ({ innerWidth, row }) => {
												return (
													<AlertDefinitionRecipientsList
														containerWidth={innerWidth}
														recipients={row.recipients}
													/>
												);
											},
											header: () => (
												<FormattedMessage {...messages.labelRecipients} />
											),
										},
										width: thirdColumnWidth,
									},
								]}
								// data={{
								// 	selectedAlertDefinitions,
								// }}
								key={containerWidth}
								rows={selectedAlertDefinitions}
								tableWidth={containerWidth}
							/>
						);
					}}
				</Measurer>

				<LabeledValuesBox
					items={[
						{
							label: (
								<FormattedMessage {...messages.labelFromWebsite} />
							),
							value: websiteDisplayName,
						},
					]}
				/>
			</MarginsList>
		);
	}

	return (
		<DestructConfirmationModal
			confirmButton={(
				<FormattedMessage
					{...messages.confirmButton}
					values={{
						alertDefinitions: selectedAlertDefinitions.length,
					}}
				/>
			)}
			confirmationPhrase={(
				selectedAlertDefinitions.length === 1
					? null
					: `DELETE ${selectedAlertDefinitions.length}`
			)}
			onConfirmation={handleDeletion}
			titleAction={(
				<FormattedMessage {...messages.verb} />
			)}
			titleObject={(
				<FormattedMessage
					{...messages.title}
					values={{
						alertDefinitions: selectedAlertDefinitions.length,
					}}
				/>
			)}
		>
			<Copy
				{...messages.warnPermanent}
				values={{
					alertDefinitions: selectedAlertDefinitions.length,
				}}
			/>

			{selectedAlertDefinitions.length === 1
				? renderSingleAlertDefinitionDetails()
				: renderMultipleAlertDefinitionsDetails()
			}
		</DestructConfirmationModal>
	);
};



export default DeleteAlertDefinitionConfirmationModal;
