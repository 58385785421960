import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useAccountAdminDefaultWebsiteSettingsFormQuery,
	useUpdateAccountAdminDefaultWebsiteSettingsMutation,
} from './AccountAdminUpdateWebsiteDefaultSettingsForm.gql';



type Props = {
	accountId: CK.AccountId,
};

const AccountAdminDefaultWebsiteSettingsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const { data } = useAccountAdminDefaultWebsiteSettingsFormQuery({
		variables: {
			accountId,
		},
	});

	const [updateAccountAdminDefaultWebsiteSettings] = useUpdateAccountAdminDefaultWebsiteSettingsMutation();

	const adminDefaultWebsiteSettings = data?.account?.adminSettings ?? null;

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateAccountAdminDefaultWebsiteSettings({
				variables: {
					accountId,
					discoverAmpPagesByDefault: values.discoverAmpPagesByDefault,
					trackH2ToH6HistoricallyByDefault: values.trackH2ToH6HistoricallyByDefault,
					trackNumericOnlyChangesByDefault: values.trackNumericOnlyChangesByDefault,
				},
			});
		},
		[
			accountId,
			updateAccountAdminDefaultWebsiteSettings,
		],
	);

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageAdminDefaultWebsiteSettings}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					isForAdmins={true}
					title="Default website settings"
				>
					<DisplayPart>
						<FormRows>
							<FormRow label="Also track H2-H6 changes">
								<StaticText focusTarget="trackH2ToH6HistoricallyByDefault">
									{adminDefaultWebsiteSettings?.trackH2ToH6HistoricallyByDefault ? 'enabled' : 'disabled'}
								</StaticText>
							</FormRow>

							<FormRow label="Also track number-only changes">
								<StaticText focusTarget="trackNumericOnlyChangesByDefault">
									{adminDefaultWebsiteSettings?.trackNumericOnlyChangesByDefault ? 'enabled' : 'disabled'}
								</StaticText>
							</FormRow>

							<FormRow label="Discover AMP pages">
								<StaticText focusTarget="discoverAmpPagesByDefault">
									{adminDefaultWebsiteSettings?.discoverAmpPagesByDefault ? 'enabled' : 'disabled'}
								</StaticText>
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form
							defaultValues={{
								discoverAmpPagesByDefault: adminDefaultWebsiteSettings?.discoverAmpPagesByDefault,
								trackH2ToH6HistoricallyByDefault: adminDefaultWebsiteSettings?.trackH2ToH6HistoricallyByDefault,
								trackNumericOnlyChangesByDefault: adminDefaultWebsiteSettings?.trackNumericOnlyChangesByDefault,
							}}
							onSuccess={handleSubmit}
						>
							<FormRows>
								<FormRow
									htmlFor="trackH2ToH6HistoricallyByDefault"
									label="Also track H2-H6 changes"
								>
									<CheckboxField
										label="enabled"
										name="trackH2ToH6HistoricallyByDefault"
										width={false}
									/>
								</FormRow>

								<FormRow
									htmlFor="trackNumericOnlyChangesByDefault"
									label="Also track number-only changes"
								>
									<CheckboxField
										label="enabled"
										name="trackNumericOnlyChangesByDefault"
										width={false}
									/>
								</FormRow>

								<FormRow
									htmlFor="discoverAmpPagesByDefault"
									label="Discover AMP pages"
								>
									<CheckboxField
										label="enabled"
										name="discoverAmpPagesByDefault"
										width={false}
									/>
								</FormRow>
							</FormRows>

							<FormErrorMessages />

							<ButtonsLayout>
								<CancelButton />
								<SaveSubmitButton />
							</ButtonsLayout>
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WhenAccountActionAllowed>
	);
};


export default AccountAdminDefaultWebsiteSettingsForm;
