import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import CountSlider from '~/components/patterns/forms/fields/CountSlider';
import CountSliderContext from '~/components/atoms/forms/components/countSliders/abstractBuilders/CountSliderContext';
import CountSliderFieldLayout from '~/components/patterns/forms/fieldParts/countSliders/CountSliderFieldLayout';
import CountSliderLabels, {
	type CountSliderLabelsLabel,
} from '~/components/patterns/forms/fieldParts/countSliders/CountSliderLabels';
import VerifyWebsiteLink, {
	VerifyWebsiteLinkStyle,
} from '~/components/app/VerifyWebsiteLink';

import useFormContext from '~/hooks/useFormContext';

import {
	calculateRecommendedFetchingRate,
} from '~/model/crawlingVelocity';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	average: {
		id: 'ui.forms.websiteSpeed.average.label',
	},
	boost: {
		id: 'ui.forms.websiteSpeed.boost.label',
	},
	paused: {
		id: 'ui.forms.websiteSpeed.paused.label',
	},
	value: {
		id: 'ui.forms.websiteSpeed.value',
	},
	needsVerification: {
		id: 'ui.forms.websiteSpeed.needsVerification',
	},
});



type Props = {
	isVerified: boolean,
	name: string,
	pageTotal: number,
};

const CrawlingSpeedField: React.FC<Props> = (props) => {
	const {
		isVerified,
		name,
		pageTotal,
	} = props;

	const formContext = useFormContext();

	const {
		defaultValues,
		focused,
		values,
	} = formContext;

	const renderField = React.useCallback(
		({
			currentStep,
			currentValue,
			isMaxLimitReached,
			minStep,
			onSliderStepChangeHandler,
			sliderMax,
		}) => {
			const highlightedValue = minStep || undefined;

			const recommendedFetchingRate = calculateRecommendedFetchingRate(pageTotal);
			const showBubble = focused === name;

			let handlerBubble: React.ReactNode = undefined;
			let leftLabel: CountSliderLabelsLabel | undefined = undefined;

			if (showBubble) {
				handlerBubble = (
					<FormattedNumber
						style="percent"
						value={currentValue}
					/>
				);
			}

			if (currentValue > 0.0) {
				leftLabel = [
					{
						label: (
							<FormattedMessage {...messages.average} />
						),
						uppercaseLabel: true,
						value: (
							<FormattedMessage
								{...messages.value}
								values={{
									count: Math.ceil(recommendedFetchingRate * currentValue * 100) / 100,
								}}
							/>
						),
					},
					{
						label: (
							<FormattedMessage {...messages.boost} />
						),
						uppercaseLabel: true,
						value: (
							<FormattedMessage
								{...messages.value}
								values={{
									count: Math.ceil(Math.max(1.0, recommendedFetchingRate * 2) * currentValue * 100) / 100,
								}}
							/>
						),
					},
				];
			} else {
				leftLabel = {
					label: (
						<FormattedMessage {...messages.paused} />
					),
					uppercaseLabel: true,
				};
			}

			if (showBubble && isMaxLimitReached) {
				handlerBubble = (
					<FormattedMessage
						{...messages.needsVerification}
						values={{
							link_verify: (chunks) => (
								<VerifyWebsiteLink style={VerifyWebsiteLinkStyle.Light}>
									{chunks}
								</VerifyWebsiteLink>
							),
						}}
					/>
				);
			}

			return (
				<CountSliderFieldLayout
					slider={(
						<CountSlider
							handlerBubble={handlerBubble}
							highlightedValue={highlightedValue}
							max={sliderMax}
							onChangeCallback={onSliderStepChangeHandler}
							showStepMarks={true}
							value={currentStep}
						/>
					)}
					sliderLabels={(
						<CountSliderLabels
							leftLabel={leftLabel}
						/>
					)}
				/>
			);
		},
		[
			focused,
			name,
			pageTotal,
		],
	);

	const value = values[name] ?? defaultValues[name];

	if (value === undefined) {
		return null;
	}

	const notches = [
		0.0,
		0.2,
		0.4,
		0.6,
		0.8,
		1.0,
		// Verified domains only
		2.0,
		3.0,
		5.0,
		10.0,
	];

	if (notches.includes(defaultValues[name]) === false) {
		notches.push(defaultValues[name]);
	}

	notches.sort((notchA, notchB) => notchA - notchB);

	return (
		<CountSliderContext
			maxLimit={isVerified ? Math.max(...notches) : 2.0}
			maxValue={Math.max(...notches)}
			minLimit={0.0}
			minValue={Math.min(...notches)}
			name={name}
			numberOfSteps={notches.length}
			stepGetter={(value) => {
				return notches.indexOf(value);
			}}
			value={value}
			valueGetter={(step) => getArrayItemAtSafeIndex(notches, step)}
		>
			{renderField}
		</CountSliderContext>
	);
};



export default CrawlingSpeedField;
