import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import FlexibleBlocks from '~/components/patterns/structures/FlexibleBlocks';
import WebsitesOverviewGridCard from '~/components/app/WebsitesOverviewGridCard';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	onWebsiteClickCallback: (websiteId: CK.WebsiteId, event: React.MouseEvent) => void,
	websites: ReadonlyArray<{
		displayName: string,
		id: CK.WebsiteId,
		pageCapacity: number,
		pagesCount: number,
		problem: GraphQL.WebsiteProblem | null,
	}>,
};

const WebsitesOverviewGrid: React.FC<Props> = (props) => {
	const {
		onWebsiteClickCallback,
		websites,
	} = props;

	const viewportType = useViewportType();

	return (
		<FlexibleBlocks
			blockMinWidth={(!viewportType.isSmall && !viewportType.isMedium) ? 470 : undefined}
		>
			{websites.map((website) => (
				<WebsitesOverviewGridCard
					key={website.id}
					onWebsiteClickCallback={onWebsiteClickCallback}
					website={website}
				/>
			))}
		</FlexibleBlocks>
	);
};



export default WebsitesOverviewGrid;
