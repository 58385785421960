import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AddMemberModalAssignWebsitesStep from '~/components/app/AddMemberModalAssignWebsitesStep';
import AddMemberModalSuccessStep from '~/components/app/AddMemberModalSuccessStep';
import AlertsAndReportsStep from './AlertsAndReportsStep';
import {
	DisabledOverlayDueToInsufficientPermissions,
	DisabledOverlayDueToInsufficientPermissionsForKingdomAdmin,
	DisabledOverlayDueToMaximumInvitationsReached,
} from './AnnouncementOverlay';
import FlashMessage, {
	FlashMessageType,
} from '~/components/patterns/messages/popup/FlashMessage';
import InviteMultipleMembersStep from './InviteMultipleMembersStep/InviteMultipleMembersStep';
import InviteSingleMemberStep from './InviteSingleMemberStep';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';

import {
	useInviteNewUserMutation,
	useValidateEmailMutation,
} from './Modal.gql';

import useAccountId from '~/hooks/useAccountId';
import useAccountUserRestrictions from '~/hooks/useAccountUserRestrictions';
import useAllInvitations from '~/hooks/useAllInvitations';
import useFlashMessage from '~/hooks/useFlashMessage';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useUrlState from '~/hooks/useUrlState';

import {
	WebsiteAccess,
} from '~/model/users';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	formErrorAlreadyInvited: {
		id: 'ui.teamDetail.teamInvitation.errorAlreadyInvited',
	},
	formErrorAlreadyMember: {
		id: 'ui.teamDetail.teamInvitation.errorAlreadyMember',
	},
	formErrorDisallowedEmail: {
		id: 'ui.formErrors.disallowedEmail',
	},
	formErrorTrialAbuserEmail: {
		id: 'ui.formErrors.trialAbuserEmail',
	},
	invitationSentFlashMessage: {
		id: 'ui.members.new.flashMessages.invitationSent',
	},
});



const AddMemberModal: React.FC = () => {
	const accountId = useAccountId();

	const allInvitations = useAllInvitations();
	const accountUserRestrictions = useAccountUserRestrictions(accountId);
	const urlState = useUrlState();

	const canManageDetails = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageDetails,
	);

	const multiStepModalRef = React.useRef<MultiStepModalRef | null>(null);

	const [invitation, setInvitation] = React.useState<{
		email: string | null,
		role: GraphQL.UserRole | null,
	}>({
		email: null,
		role: null,
	});

	const [emails, setEmails] = React.useState<Array<string>>([]);

	const {
		flashMessage: invitationSentFlashMessage,
		showFlashMessage: showInvitationSentFlashMessage,
	} = useFlashMessage(
		<FlashMessage type={FlashMessageType.Success}>
			<FormattedMessage
				{...messages.invitationSentFlashMessage}
				values={{
					emails: emails.length,
				}}
			/>
		</FlashMessage>,
	);

	const [inviteNewUser] = useInviteNewUserMutation();
	const [validateEmail] = useValidateEmailMutation();

	const sendInvitation = React.useCallback(
		async (
			input: {
				email: string,
				role: GraphQL.UserRole,
				scope: WebsiteAccess,
				assignedWebsites?: ReadonlyArray<CK.WebsiteId>,
			},
		) => {
			const {
				email,
				role,
				scope,
				assignedWebsites = null,
			} = input;

			if (accountId === null) {
				return;
			}

			try {
				await inviteNewUser({
					variables: {
						accountId,
						assignedWebsites,
						email,
						hasLimitedWebsitesAccess: scope === WebsiteAccess.Selected,
						role,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error, {
					alreadyInvitedIntoHomeAccount: email,
					alreadyUserOfHomeAccount: email,
					disallowedEmail: email,
					emailDomainNotWhitelisted: email,
					trialAbuserEmail: email,
				});
			}

			setEmails([email]);
			setInvitation({ email, role });
			showInvitationSentFlashMessage();

			multiStepModalRef.current?.goToNextStep();
		},
		[
			accountId,
			inviteNewUser,
			showInvitationSentFlashMessage,
		],
	);

	const handleRoleUpdate = React.useCallback(
		(role: GraphQL.UserRole) => {
			setInvitation(
				(currentInvitation) => ({
					...currentInvitation,
					role,
				}),
			);
		},
		[],
	);

	const handleSubmitLimitedWebsitesAccess = React.useCallback(
		(input: {
			assignedWebsites: Array<string>,
			scope: WebsiteAccess,
		}) => {
			const {
				assignedWebsites,
				scope,
			} = input;

			return sendInvitation({
				email: invitation.email ?? '',
				role: invitation.role ?? GraphQL.UserRole.Viewer,
				scope,
				assignedWebsites,
			});
		},
		[
			invitation,
			sendInvitation,
		],
	);

	const handleSubmitMemberDetails = React.useCallback(
		async (
			input: {
				email: string,
				role: GraphQL.UserRole,
			},
		) => {
			const {
				email,
				role,
			} = input;

			if (accountId === null) {
				return;
			}

			if (role === GraphQL.UserRole.Editor || role === GraphQL.UserRole.Viewer) {
				try {
					await validateEmail({
						variables: {
							accountId,
							email,
						},
					});
				} catch (error) {
					throw FormError.fromApolloError(error, {
						alreadyInvitedIntoHomeAccount: email,
						alreadyUserOfHomeAccount: email,
						disallowedEmail: email,
						emailDomainNotWhitelisted: email,
						trialAbuserEmail: email,
					});
				}

				setEmails([email]);
				setInvitation({ email, role });

				multiStepModalRef.current?.goToNextStep();
			} else {
				await sendInvitation({
					email,
					role,
					scope: WebsiteAccess.All,
				});
			}
		},
		[
			accountId,
			sendInvitation,
			validateEmail,
		],
	);

	const shouldWebsitesBeAssigned = (
		invitation.role === GraphQL.UserRole.Editor
		|| invitation.role === GraphQL.UserRole.Viewer
	);

	const contentIsLoading = (
		accountId === null
		|| accountUserRestrictions === null
		|| allInvitations === null
	);

	let disabledOverlay: React.ReactNode | null = null;

	if (
		accountId !== null
		&& accountUserRestrictions !== null
		&& allInvitations !== null
	) {
		if (canManageDetails.noBecauseInsufficientPermissions) {
			disabledOverlay = (
				<DisabledOverlayDueToInsufficientPermissions />
			);
		} else if (canManageDetails.noBecauseInsufficientPermissionsForKingdomAdmin) {
			disabledOverlay = (
				<DisabledOverlayDueToInsufficientPermissionsForKingdomAdmin />
			);
		}

		if (
			allInvitations.listByAccount(accountId).length
			>= accountUserRestrictions.maximumInvitations
		) {
			disabledOverlay = (
				<DisabledOverlayDueToMaximumInvitationsReached
					accountId={accountId}
					maximumInvitations={accountUserRestrictions.maximumInvitations}
				/>
			);
		}
	}

	const handleBulkContinue = React.useCallback(
		(emails: Array<string>) => {
			setEmails(emails);

			showInvitationSentFlashMessage();

			multiStepModalRef.current?.goToNextStep();
		},
		[
			showInvitationSentFlashMessage,
		],
	);

	const bulk = !!urlState.params.bulk;

	return (
		<MultiStepModal
			disabledContentOverlay={disabledOverlay}
			flashMessage={invitationSentFlashMessage}
			isContentDisabled={({ activeStep }) => activeStep === 0 && disabledOverlay !== null}
			key={bulk ? 'bulk' : 'single'}
			preloader={contentIsLoading && (
				<LoadingDots isStretched={true} />
			)}
			ref={multiStepModalRef}
		>
			{bulk ? (
				<InviteMultipleMembersStep
					accountId={accountId}
					isContentDisabled={disabledOverlay !== null}
					onContinueCallback={handleBulkContinue}
				/>
			) : (
				<>
					<InviteSingleMemberStep
						accountId={accountId}
						isContentDisabled={disabledOverlay !== null}
						onRoleUpdateCallback={handleRoleUpdate}
						onSubmitCallback={handleSubmitMemberDetails}
					/>

					{shouldWebsitesBeAssigned && (
						<AddMemberModalAssignWebsitesStep
							inviteeAccountId={accountId}
							onSubmitCallback={handleSubmitLimitedWebsitesAccess}
						/>
					)}

					<AlertsAndReportsStep
						accountId={accountId}
						invitationEmail={invitation.email || ''}
					/>
				</>
			)}

			<AddMemberModalSuccessStep
				emails={emails}
			/>
		</MultiStepModal>
	);
};



export default AddMemberModal;
