import React from 'react';
import {
	useSelector,
} from 'react-redux';

import useTariffs, {
	type LoadedTariffs,
} from '~/hooks/useTariffs';

import {
	teamSelector,
} from '~/state/ui/selectors';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	children: RenderProp<LoadedTariffs>,
	placeholder?: React.ReactNode,
};

const MoneyStuff: React.FC<Props> = (props) => {
	const account = useSelector(teamSelector);
	const tariffs = useTariffs();

	const {
		children,
		placeholder,
	} = props;

	if (account && tariffs.isReady) {
		return <>{renderProp(children, tariffs)}</>;
	}

	return <>{placeholder ?? null}</>;
};



export default MoneyStuff;
