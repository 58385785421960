import classNames from 'classnames';
import React from 'react';



export enum AttachedHeadingAlignment {
	Center,
	Left,
}

type Props = {
	children?: React.ReactNode,
	/** Attached heading component */
	heading: React.ReactNode,
	/** Possibility to change heading alignment */
	headingAlignment?: AttachedHeadingAlignment,
	/** We can force set maximum width of component when ellipsis is enabled */
	maxWidth?: number,
};



const AttachedHeading: React.FC<Props> = (props) => {
	const {
		children,
		heading,
		headingAlignment = AttachedHeadingAlignment.Center,
		maxWidth,
	} = props;

	const headingClasses = classNames({
		'attached-heading__heading': true,
		'attached-heading__heading--center-aligned': headingAlignment === AttachedHeadingAlignment.Center,
	});

	return (
		<div
			className="attached-heading"
			style={{
				maxWidth,
			}}
		>
			<div className={headingClasses}>
				{heading}
			</div>
			<div className="attached-heading__content">
				{children}
			</div>
		</div>
	);
};



export default AttachedHeading;
