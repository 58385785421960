import {
	endOfYear,
	isBefore,
	isFuture,
	isSameDay,
	isToday,
	startOfYear,
} from 'date-fns';
import React from 'react';

import DateRangeSelectField from '~/components/patterns/forms/fields/DateRangeSelectField';

import usePredefinedDateRanges from '~/hooks/usePredefinedDateRanges';

import {
	DateRangePreset,
	findPredefinedRangeForDates,
	getDateRangeDefinition,
} from '~/model/dateRangePresets';



type DateRange = {
	end: Date | null,
	start: Date | null,
};

type Props = {
	dateRange: DateRange,
	onSelectDateRange: (dateRange: DateRange) => void,
	pageFoundAt: Date,
	width?: number,
};

const HistoryDateRangeFilter: React.FC<Props> = (props) => {
	const {
		dateRange,
		onSelectDateRange,
		pageFoundAt,
		width,
	} = props;

	const predefinedDateRanges = usePredefinedDateRanges(pageFoundAt);

	const selectedPreset = findPredefinedRangeForDates(
		dateRange.start,
		dateRange.end,
		pageFoundAt,
	);

	const presetsOptions = predefinedDateRanges.map((dateRange) => ({
		disabled: dateRange.disabled,
		highlighted: dateRange.name === selectedPreset,
		label: dateRange.label,
		value: dateRange.name,
	}));

	const disabledDate = React.useCallback(
		(date: Date) => {
			if (isToday(date) || isSameDay(pageFoundAt, date)) {
				return false;
			}

			if (isFuture(date)) {
				return true;
			}

			return !isBefore(pageFoundAt, date);
		},
		[
			pageFoundAt,
		],
	);

	return (
		<DateRangeSelectField
			disabledDate={disabledDate}
			endDate={dateRange.end}
			lowerBound={startOfYear(pageFoundAt)}
			onPresetSelect={(dateRangeName) => {
				if (dateRangeName !== DateRangePreset.Custom) {
					const dateRangeDefinition = getDateRangeDefinition(dateRangeName);

					onSelectDateRange({
						end: dateRangeDefinition.endDate(),
						start: dateRangeDefinition.startDate(pageFoundAt),
					});
				}
			}}
			onRangeSelect={({ startDate, endDate }) => {
				onSelectDateRange({
					end: endDate,
					start: startDate,
				});
			}}
			presetsOptions={presetsOptions}
			startDate={dateRange.start}
			upperBound={endOfYear(new Date())}
			width={width}
		/>
	);
};

export default HistoryDateRangeFilter;
