import React from 'react';

import NumberDiff, {
	NumberDiffImpact,
	NumberDiffSize,
} from '~/components/patterns/values/NumberDiff';



type Props = {
	number: number,
	prevNumber: number,
};

const WebsiteHealthNumberDiff: React.FC<Props> = (props) => {
	const {
		number,
		prevNumber,
	} = props;

	const diffValue = Math.round((number - prevNumber) * 100) / 100;

	let numberImpact = NumberDiffImpact.Neutral;

	if (diffValue > 0) {
		numberImpact = NumberDiffImpact.Positive;
	}

	if (diffValue < 0) {
		numberImpact = NumberDiffImpact.Negative;
	}

	return (
		<NumberDiff
			diffValue={diffValue}
			impact={numberImpact}
			size={NumberDiffSize.Small}
		/>
	);
};



export default WebsiteHealthNumberDiff;
