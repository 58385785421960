import React from 'react';
import {
	useSelector,
} from 'react-redux';

import CurrentView from '~/components/app/IssuesScreen/CurrentView';
import HistoricalView from '~/components/app/IssuesScreen/HistoricalView';

import {
	isActiveSelector,
} from '~/state/websiteIssuesComparison/selectors';



type Props = {
	innerPanel?: () => React.ReactElement,
};

const IssuesOverviewScreen: React.FC<Props> = (props) => {
	const {
		innerPanel,
	} = props;

	const isComparisonModeActive = useSelector(isActiveSelector);

	if (isComparisonModeActive) {
		return (
			<HistoricalView innerPanel={innerPanel} />
		);
	}

	return (
		<CurrentView innerPanel={innerPanel} />
	);
};



export default IssuesOverviewScreen;
