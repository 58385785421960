import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Hint, {
	HintPopupVisibility,
	type HintRef,
} from '~/components/patterns/hints/hint/Hint';

import {
	usePageWordpressEditLinkQuery,
} from './EditInWordpressButton.gql';

import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	label: {
		id: 'ui.editInWordpressButton.label',
	},
});

const unavailableMessages = defineMessages({
	[GraphQL.WordpressEditLinkUnavailabilityReason.NotEditable]: {
		id: 'ui.editInWordpressButton.unavailable.notEditable',
	},
	[GraphQL.WordpressEditLinkUnavailabilityReason.PluginNotActive]: {
		id: 'ui.editInWordpressButton.unavailable.pluginNotActive',
	},
	[GraphQL.WordpressEditLinkUnavailabilityReason.PluginNotInstalled]: {
		id: 'ui.editInWordpressButton.unavailable.pluginNotInstalled',
	},
});



type Props = {
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const EditInWordpressButton: React.FC<Props> = (props) => {
	const {
		legacyUrlId,
		websiteId,
	} = props;

	const viewportType = useViewportType();
	const hintRef = React.useRef<HintRef | null>(null);

	const shouldOpenLink = React.useRef(false);

	const { data, loading } = usePageWordpressEditLinkQuery({
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			const link = data.lookupPageByLegacyId?.wordpressEditLink.link ?? null;

			if (shouldOpenLink.current) {
				if (link !== null) {
					window.open(link, '_blank');
				} else {
					hintRef.current?.open();
				}
			}
		},
		variables: {
			legacyUrlId,
			websiteId,
		},
	});

	const link = data?.lookupPageByLegacyId?.wordpressEditLink.link ?? null;
	const unavailabilityReason = data?.lookupPageByLegacyId?.wordpressEditLink.unavailabilityReason ?? null;

	const handleClick = React.useCallback(
		(event) => {
			if (loading === true) {
				event.preventDefault();
				shouldOpenLink.current = true;
			}
		},
		[
			loading,
		],
	);

	return (
		<Hint
			popup={unavailabilityReason && (
				<FormattedMessage {...unavailableMessages[unavailabilityReason]} />
			)}
			popupVisibility={(
				unavailabilityReason !== null
					? HintPopupVisibility.OnHover
					: HintPopupVisibility.Never
			)}
			ref={hintRef}
		>
			<Button
				compact={viewportType.isSmall || viewportType.isMedium}
				disabled={!loading && (link === null || link === '')}
				href={link !== null ? link : undefined}
				icon={(
					<BasicIcon type={BasicIconType.WordPress} />
				)}
				onClick={handleClick}
				size={ButtonSize.Small}
				style={ButtonStyle.Hollow}
				uppercase={true}
			>
				<FormattedMessage {...messages.label} />
			</Button>
		</Hint>
	);
};



export default EditInWordpressButton;
