import memoize from 'memoizee';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	type AllWebsiteEmailSettingsQuery,
	useAllWebsiteEmailSettingsQuery,
} from './useAllWebsiteEmailSettings.gql';

import usePollInterval from '~/hooks/usePollInterval';



const emptyList = [];

const format = memoize(
	(data: AllWebsiteEmailSettingsQuery | undefined) => {
		const accounts = data?.authenticatedSession?.accounts ?? null;

		return {
			listInvitationsByWebsiteId: (websiteId: CK.WebsiteId | null) => {
				if (accounts === null) {
					return emptyList;
				}

				const result: Array<{
					alertEmailsSetup: GraphQL.AlertEmailsSetup,
					displayName: string,
					invitationId: CK.InvitationId,
					isWeeklyReportEnabled: boolean,
				}> = [];

				accounts.forEach((account) => {
					account.invitations.forEach((invitation) => {
						if (invitation.websites.some((website) => website.id === websiteId) === false) {
							return;
						}

						const websiteEmailSettings = invitation.websiteEmailSettings.find(
							(websiteEmailSettings) => websiteEmailSettings.websiteId === websiteId,
						);

						if (websiteEmailSettings === undefined) {
							throw new Error(`Invitation.websiteEmailSettings must be present for website listed in Invitation.websites`);
						}

						result.push({
							alertEmailsSetup: websiteEmailSettings.alertEmailsSetup,
							displayName: invitation.displayName,
							invitationId: invitation.id,
							isWeeklyReportEnabled: websiteEmailSettings.isWeeklyReportEnabled,
						});
					});
				});

				return result;
			},
			listUsersByWebsiteId: (websiteId: CK.WebsiteId | null) => {
				if (accounts === null) {
					return emptyList;
				}

				const result: Record<CK.UserId, {
					alertEmailsSetup: GraphQL.AlertEmailsSetup,
					displayName: string,
					isWeeklyReportEnabled: boolean,
					userEmail: string,
					userUniqueId: CK.UserId,
				}> = {};

				accounts.forEach((account) => {
					account.memberships.forEach((membership) => {
						if (result[membership.user.uniqueId] !== undefined) {
							return;
						}

						if (membership.websites.some((website) => website.id === websiteId) === false) {
							return;
						}

						const websiteEmailSettings = membership.user.websiteEmailSettings.find(
							(websiteEmailSettings) => websiteEmailSettings.websiteId === websiteId,
						);

						if (websiteEmailSettings === undefined) {
							throw new Error(`User.websiteEmailSettings must be present for website listed in User.websites`);
						}

						result[membership.user.uniqueId] = {
							alertEmailsSetup: websiteEmailSettings.alertEmailsSetup,
							displayName: membership.user.displayName,
							isWeeklyReportEnabled: websiteEmailSettings.isWeeklyReportEnabled,
							userEmail: membership.user.email,
							userUniqueId: membership.user.uniqueId,
						};
					});
				});

				return Object.values(result);
			},
			isLoaded: accounts !== null,
		};
	},
);



function useAllWebsiteEmailSettings() {
	const { data } = useAllWebsiteEmailSettingsQuery({
		pollInterval: usePollInterval(30000),
	});

	return format(data);
}



export default useAllWebsiteEmailSettings;
