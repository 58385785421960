import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PasswordShowIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="Group"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					transform="translate(3.000000, 5.250000)"
				>
					<path
						d="M18,6.75 C18,6.75 15.56325,13.5 9,13.5 C2.43675,13.5 0,6.75 0,6.75 C0,6.75 2.1255,0 9,0 C15.8745,0 18,6.75 18,6.75 Z"
						id="Path"
					>
					</path>
					<path
						d="M12.75,6.75 C12.75,8.82075 11.07075,10.5 9,10.5 C6.92925,10.5 5.25,8.82075 5.25,6.75 C5.25,4.67925 6.92925,3 9,3 C11.07075,3 12.75,4.67925 12.75,6.75 Z"
						id="Path"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default PasswordShowIconBuilder;

