/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportUserIntoAccountMutationVariables = GraphQL.Exact<{
  changeMainAccount: GraphQL.Scalars['Boolean']['input'];
  email: GraphQL.Scalars['String']['input'];
  sourceAccountId: GraphQL.Scalars['AccountId']['input'];
  targetAccountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type ImportUserIntoAccountMutation = { readonly __typename?: 'Mutation', readonly ImportUser: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly displayName: string, readonly email: string, readonly hasLimitedWebsitesAccess: boolean, readonly id: CK.InvitationId, readonly role: GraphQL.UserRole, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly displayName: string, readonly email: string, readonly hasTwoFactorAuthentication: boolean, readonly id: string, readonly isChromeExtensionInstalled: boolean, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> } | null } } };


export const ImportUserIntoAccountDocument = gql`
    mutation ImportUserIntoAccount($changeMainAccount: Boolean!, $email: String!, $sourceAccountId: AccountId!, $targetAccountId: AccountId!) {
  ImportUser(
    changeMainAccount: $changeMainAccount
    email: $email
    sourceAccountId: $sourceAccountId
    targetAccountId: $targetAccountId
  ) {
    query {
      account(id: $targetAccountId) {
        id
        invitations {
          displayName
          email
          hasLimitedWebsitesAccess
          id
          role
          websites {
            id
          }
        }
        memberships {
          account {
            id
          }
          hasLimitedWebsitesAccess
          role
          user {
            displayName
            email
            hasTwoFactorAuthentication
            id
            isChromeExtensionInstalled
            legacyId
          }
          websites {
            id
          }
        }
      }
    }
  }
}
    `;
export type ImportUserIntoAccountMutationFn = Apollo.MutationFunction<ImportUserIntoAccountMutation, ImportUserIntoAccountMutationVariables>;

/**
 * __useImportUserIntoAccountMutation__
 *
 * To run a mutation, you first call `useImportUserIntoAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUserIntoAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUserIntoAccountMutation, { data, loading, error }] = useImportUserIntoAccountMutation({
 *   variables: {
 *      changeMainAccount: // value for 'changeMainAccount'
 *      email: // value for 'email'
 *      sourceAccountId: // value for 'sourceAccountId'
 *      targetAccountId: // value for 'targetAccountId'
 *   },
 * });
 */
export function useImportUserIntoAccountMutation(baseOptions?: Apollo.MutationHookOptions<ImportUserIntoAccountMutation, ImportUserIntoAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportUserIntoAccountMutation, ImportUserIntoAccountMutationVariables>(ImportUserIntoAccountDocument, options);
      }
export type ImportUserIntoAccountMutationHookResult = ReturnType<typeof useImportUserIntoAccountMutation>;
export type ImportUserIntoAccountMutationResult = Apollo.MutationResult<ImportUserIntoAccountMutation>;
export type ImportUserIntoAccountMutationOptions = Apollo.BaseMutationOptions<ImportUserIntoAccountMutation, ImportUserIntoAccountMutationVariables>;