import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import {
	useLogoutMutation,
} from './useLogout.gql';

import {
	FORCE_UNAUTHENTICATED_SECTION,
	LOGOUT_SUCCESSFUL,
	LOGOUT_UNSUCCESSFUL,
} from '~/actions';

import {
	cache,
} from '~/apollo';

import {
	IsConductorClient,
} from '~/config';

import {
	Intercom,
} from '~/globals';

import {
	navigate,
} from '~/routing/router';



function useLogout() {
	const dispatch = useDispatch();

	const [logout] = useLogoutMutation();

	return React.useCallback(
		async () => {
			Intercom()('shutdown');

			if (IsConductorClient) {
				window.location.href = 'https://app.conductor.com/gateway/conductor-platform-logout';
				return;
			}

			dispatch({
				type: FORCE_UNAUTHENTICATED_SECTION,
			});

			try {
				await logout();
			} catch (error) {
				dispatch({
					type: LOGOUT_UNSUCCESSFUL,
				});

				throw error;
			}

			dispatch({
				type: LOGOUT_SUCCESSFUL,
			});

			cache.reset();

			navigate('login');
		},
		[
			dispatch,
			logout,
		],
	);
}



export default useLogout;
