import Immutable from 'immutable';

import {
	UPDATE_ISSUES_FILTER,
} from '~/actions';

import {
	DEFAULT_FILTER,
} from '~/model/issues';

import encodeInBase64 from '~/utilities/encodeInBase64';



function normalizeIssuesFilter(filter) {
	filter = filter.filter((value) => value !== '');
	filter = filter.set('state', (filter.get('state') || DEFAULT_FILTER.get('state')).sort());

	let defaultFilter = DEFAULT_FILTER;
	defaultFilter = defaultFilter.set('state', defaultFilter.get('state').sort());

	if (JSON.stringify(filter) === JSON.stringify(defaultFilter)) {
		return null;
	}

	return filter;
}



export function createFilterParameter(filter) {
	const normalizedFilter = normalizeIssuesFilter(filter);

	return normalizedFilter
		? encodeInBase64(JSON.stringify(normalizedFilter))
		: false;
}



export function updateIssuesFilter(filter) {
	return (dispatch) => {
		filter = Immutable.fromJS(filter).map((value, key) => {
			if (value === undefined) {
				return DEFAULT_FILTER.get(key) || '';
			}

			return value;
		});

		dispatch({
			type: UPDATE_ISSUES_FILTER,
			filter,
		});

		return Promise.resolve();
	};
}
