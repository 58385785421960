import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const FilterIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m24 24h-24v-24h24z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="m5.24955 6.02399998 5.25 7.50000002v6.75l3-2.25v-4.5l5.25-7.50000002z" />
					<path d="m19.5 6.03000011h-15v-2.25h15z" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default FilterIconBuilder;

