import classNames from 'classnames';
import React from 'react';

import styles from './Emphasis.module.scss';



type Props = {
	/** Emphasis value */
	children: React.ReactNode,
	/** Enable ellpsis support (… will be color of this component) to prevent overflowing */
	ellipsis?: boolean,
	/** Make text more decent (shown in lighter color) */
	muted?: boolean,
};



const Emphasis: React.FC<Props> = (props) => {
	const {
		children,
		ellipsis,
		muted,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isMuted]: muted,
		[styles.isWithEllipsis]: ellipsis,
	});

	return (
		<span className={componentClasses}>
			{children}
		</span>
	);
};



export default Emphasis;
