import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AnalyticsAndGSCIntegrationIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M9.77508 20.0476H1.83008V2.58008H22.4001V8.61258"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M4.94238 17.3001V11.7026H8.23238V17.3001"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M10.5127 17.3V5.71753H13.8002V11.7025"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M16.0322 10.605V7.82495H19.3197V9.57745"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.3552 26.6776C15.5777 27.958 17.1556 28.8429 18.8856 29.2184C20.6157 29.5938 22.4185 29.4425 24.0617 28.784C25.705 28.1255 27.1134 26.99 28.1054 25.5238C29.0975 24.0576 29.6277 22.3279 29.6277 20.5576H20.7652V11.6951C19.4085 11.6958 18.07 12.0078 16.8528 12.6071C15.6357 13.2064 14.5722 14.077 13.7444 15.1518C12.9166 16.2267 12.3464 17.4772 12.0779 18.8071C11.8093 20.1369 11.8494 21.5107 12.1952 22.8226"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M23.0703 11.0625V18.165H30.1728C30.1731 17.2322 29.9897 16.3085 29.6328 15.4466C29.276 14.5847 28.7529 13.8016 28.0933 13.142C27.4337 12.4824 26.6506 11.9593 25.7887 11.6025C24.9268 11.2457 24.0031 11.0622 23.0703 11.0625Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M5.39453 24.8251H14.867C15.0527 24.8254 15.2367 24.7891 15.4083 24.7183C15.58 24.6475 15.736 24.5435 15.8674 24.4123C15.9989 24.2811 16.1031 24.1253 16.1743 23.9537C16.2454 23.7822 16.282 23.5983 16.282 23.4126V19.1626"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.6396 20.7026L16.2821 19.0601L17.8396 20.6151"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default AnalyticsAndGSCIntegrationIconBuilder;

