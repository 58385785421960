import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AppearAnimation from '~/components/patterns/animations/AppearAnimation';
import AttachedFormControlElement from '~/components/patterns/forms/layouts/AttachedFormControlElement';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy, {
	linkInternal,
} from '~/components/logic/Copy';
import DatatableBodyCell, {
	DatatableBodyCellAlignment,
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableHeaderCell, {
	DatatableHeaderCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';
import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableOverlay from '~/components/patterns/tables/datatables/DatatableOverlay';
import FixedHeaderGrid from '~/components/patterns/tables/datatables/FixedHeaderGrid';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import MarginsList from '~/components/atoms/lists/MarginsList';
import Measurer from '~/utilities/Measurer';
import Protip from '~/components/patterns/messages/embedded/Protip';
import RichText from '~/components/patterns/typography/RichText';
import Small from '~/components/patterns/typography/Small';
import SubmitButton from '~/components/atoms/forms/components/SubmitButton';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';

import {
	useSuggestedDomainsQuery,
} from '../AddMultipleWebsitesStep/AddMultipleWebsitesStep.gql';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	addAll: {
		id: 'ui.websites.new.steps.single.sidebar.suggestedDomains.addAll',
	},
	bulkProtip: {
		id: 'ui.websites.form.bulkProtip',
	},
	description: {
		id: 'ui.websites.new.steps.websiteDetails.sidebar',
	},
	noSuggestions: {
		id: 'ui.websites.new.steps.single.sidebar.suggestedDomains.noSuggestions',
	},
	select: {
		id: 'ui.websites.new.steps.single.sidebar.suggestedDomains.select',
	},
	title: {
		id: 'ui.websites.new.steps.single.sidebar.suggestedDomains.title',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	canAddWebsitesInBulk: boolean,
	onSelectAllDomains: (domains: Array<string>) => void,
	onSelectDomain: (domain: string) => void,
};

const SuggestedDomains: React.FC<Props> = (props) => {
	const {
		accountId,
		canAddWebsitesInBulk,
		onSelectAllDomains,
		onSelectDomain,
	} = props;

	const { data, loading } = useSuggestedDomainsQuery({
		variables: {
			accountId: accountId ?? 0,
		},
		skip: accountId === null,
		fetchPolicy: 'no-cache',
	});

	const isLoading = accountId === null || loading;
	const noSuggestions = data?.account?.suggestedDomains.length === 0;

	const suggestedDomains = data?.account?.suggestedDomains || [];

	if (noSuggestions) {
		if (!canAddWebsitesInBulk) {
			return null;
		}

		return (
			<AppearAnimation delay={500}>
				<Protip>
					<FormattedMessage
						{...messages.bulkProtip}
						values={{
							linkBulkStep: linkInternal('account.websites.new', { bulk: true }),
						}}
					/>
				</Protip>
			</AppearAnimation>
		);
	}

	return (
		<AppearAnimation>
			<MarginsList>
				<RichText>
					<Copy {...messages.description} />
				</RichText>
				<BorderedBox
					footer={canAddWebsitesInBulk && (
						<AttachedFormControlElement
							button={(
								<SubmitButton
									disabled={isLoading}
									icon={(
										<BasicIcon
											size={15}
											type={BasicIconType.Plus}
										/>
									)}
									onClickCallback={() => {
										onSelectAllDomains(suggestedDomains as Array<string>);
									}}
									size={ButtonSize.XXSmall}
									style={ButtonStyle.Hollow}
									uppercase={true}
								>
									<FormattedMessage {...messages.addAll} />
								</SubmitButton>
							)}
						/>
					)}
					paddingSize={0}
				>
					<DatatableContainer
						overlay={(
							isLoading ? (
								<DatatableOverlay>
									<LoadingDots />
								</DatatableOverlay>
							) : null
						)}
					>
						<Measurer>
							{({ containerWidth }) => {
								const suggestedWebsitesTableWidthGetter = ({ index }) => {
									if (index == 0) {
										return containerWidth - 86;
									}

									return 70;
								};

								return (
									<FixedHeaderGrid
										bodyCellRenderer={({ columnIndex, key, rowIndex, style }) => {
											if (columnIndex === 0) {
												return (
													<DatatableBodyCell
														alignment={DatatableBodyCellAlignment.Left}
														cssStyle={style}
														key={key}
														rowIndex={rowIndex}
														separator={false}
														size={DatatableBodyCellSize.Small}
													>
														{suggestedDomains[rowIndex]}
													</DatatableBodyCell>
												);
											}

											if (columnIndex === 1) {
												return (
													<DatatableBodyCell
														alignment={DatatableBodyCellAlignment.Right}
														cssStyle={style}
														key={key}
														rowIndex={rowIndex}
														separator={false}
														size={DatatableBodyCellSize.Small}
													>
														<InternalLink
															onClickCallback={() => {
																onSelectDomain(
																	getArrayItemAtSafeIndex(suggestedDomains, rowIndex),
																);
															}}
															style={InternalLinkStyle.Decent}
														>
															<Small>
																<FormattedMessage {...messages.select} />
															</Small>
														</InternalLink>
													</DatatableBodyCell>
												);
											}

											return (
												<DatatableBodyCell
													cssStyle={style}
													key={key}
													rowIndex={rowIndex}
													separator={false}
													size={DatatableBodyCellSize.Small}
												/>
											);
										}}
										columnCount={2}
										columnWidth={suggestedWebsitesTableWidthGetter}
										headerCellRenderer={({ columnIndex }) => {
											let label;

											switch (columnIndex) {

												case 0:
													label = (
														<FormattedMessage {...messages.title} />
													);
													break;

											}

											return (
												<DatatableHeaderCell
													key={'header_' + columnIndex}
													separator={false}
													size={DatatableHeaderCellSize.Small}
													width={suggestedWebsitesTableWidthGetter({ index: columnIndex })}
												>
													<TableLabel label={label} />
												</DatatableHeaderCell>
											);
										}}
										headerHeight={32}
										height={210}
										overscanColumnCount={2}
										rowCount={suggestedDomains.length}
										rowHeight={32}
										width={containerWidth}
									/>
								);
							}}
						</Measurer>
					</DatatableContainer>
				</BorderedBox>
			</MarginsList>
		</AppearAnimation>
	);
};



export default SuggestedDomains;
