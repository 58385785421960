import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DatatableBodyCell from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import Emphasis from '~/components/patterns/typography/Emphasis';



const messages = defineMessages({
	message: {
		id: 'ui.dataTable.tooManyRows',
	},
});



type Props = {
	limit: number,
	rowIndex: number,
	total: number,
};

const FullwidthCell: React.FC<Props> = (props) => {
	const {
		limit,
		rowIndex,
		total,
	} = props;

	return (
		<DatatableBodyCell
			cssStyle={{
				width: '100%',
			}}
			rowIndex={rowIndex}
		>
			<Emphasis>
				<Ellipsis>
					<FormattedMessage
						{...messages.message}
						values={{
							limit,
							total,
						}}
					/>
				</Ellipsis>
			</Emphasis>
		</DatatableBodyCell>
	);
};



export default React.memo(FullwidthCell);
