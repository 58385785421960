import PropTypes from 'prop-types';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE,
	ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES,
	ISSUE_CHANGES_GROUP_SEGMENT_CHANGED,
	ISSUE_CHANGES_GROUP_UNAFFECTED,
	LIST_OF_SECONDARY_ISSUE_CHANGES_GROUPS,
} from '~/model/affectedPagesComparison';



const messages = defineMessages({
	[ISSUE_CHANGES_GROUP_NO_LONGER_APPLICABLE]: {
		id: 'ui.issueChangesGroups.noLongerApplicable',
	},
	[ISSUE_CHANGES_GROUP_ONLY_IMPORTANCE_CHANGES]: {
		id: 'ui.issueChangesGroups.onlyImportanceChanges',
	},
	[ISSUE_CHANGES_GROUP_SEGMENT_CHANGED]: {
		id: 'ui.issueChangesGroups.segmentChanged',
	},
	[ISSUE_CHANGES_GROUP_UNAFFECTED]: {
		id: 'ui.issueChangesGroups.unaffected',
	},
});



const SecondaryIssueChangesGroupName = (props) => {
	const {
		issueChangesGroup,
	} = props;

	return (
		<FormattedMessage {...messages[issueChangesGroup]} />
	);
};

SecondaryIssueChangesGroupName.propTypes = {
	issueChangesGroup: PropTypes.oneOf(LIST_OF_SECONDARY_ISSUE_CHANGES_GROUPS.toArray()).isRequired,
};



export default SecondaryIssueChangesGroupName;
