/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GrantAgencyAccessMutationVariables = GraphQL.Exact<{
  agencyId: GraphQL.Scalars['AccountId']['input'];
  clientId: GraphQL.Scalars['AccountId']['input'];
}>;


export type GrantAgencyAccessMutation = { readonly __typename?: 'Mutation', readonly GrantAgencyAccess: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly agencyConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessAgencyConnection', readonly agencyAccountId: CK.AccountId, readonly agencyAccountName: string, readonly id: any, readonly inviterEmail: string | null, readonly isInitiatedByAgency: boolean, readonly isPending: boolean, readonly level: GraphQL.AccountAccessConnectionLevel }>, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null } } };


export const GrantAgencyAccessDocument = gql`
    mutation GrantAgencyAccess($agencyId: AccountId!, $clientId: AccountId!) {
  GrantAgencyAccess(agencyId: $agencyId, clientId: $clientId) {
    query {
      account(id: $clientId) {
        agencyConnections {
          agencyAccountId
          agencyAccountName
          id
          inviterEmail
          isInitiatedByAgency
          isPending
          level
        }
        features {
          feature
          id
          inUse
        }
        id
      }
    }
  }
}
    `;
export type GrantAgencyAccessMutationFn = Apollo.MutationFunction<GrantAgencyAccessMutation, GrantAgencyAccessMutationVariables>;

/**
 * __useGrantAgencyAccessMutation__
 *
 * To run a mutation, you first call `useGrantAgencyAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantAgencyAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantAgencyAccessMutation, { data, loading, error }] = useGrantAgencyAccessMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGrantAgencyAccessMutation(baseOptions?: Apollo.MutationHookOptions<GrantAgencyAccessMutation, GrantAgencyAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GrantAgencyAccessMutation, GrantAgencyAccessMutationVariables>(GrantAgencyAccessDocument, options);
      }
export type GrantAgencyAccessMutationHookResult = ReturnType<typeof useGrantAgencyAccessMutation>;
export type GrantAgencyAccessMutationResult = Apollo.MutationResult<GrantAgencyAccessMutation>;
export type GrantAgencyAccessMutationOptions = Apollo.BaseMutationOptions<GrantAgencyAccessMutation, GrantAgencyAccessMutationVariables>;