import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BackButton from '~/components/app/BackButton';
import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CodeValue from '~/components/patterns/values/CodeValue';
import FieldWithAttachedButton from '~/components/patterns/forms/layouts/FieldWithAttachedButton';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import SelectField from '~/components/atoms/forms/components/SelectField';
import SubmitButton from '~/components/app/SubmitButton';
import WidthLimiter from '~/components/patterns/utils/WidthLimiter';

import {
	validateField,
} from '~/components/app/validations';

import {
	useCreateLogFileAnalysisS3BucketMutation,
	useLogFileAnalysisS3BucketsQuery,
} from '~/components/app/CreateLogFileAnalysisS3BucketForm.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	notEmpty,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	[GraphQL.LogFileAnalysisS3BucketRegion.EuCentral_1]: {
		id: 'ui.createLogFileAnalysisS3BucketForm.region.euCentral1',
	},
	[GraphQL.LogFileAnalysisS3BucketRegion.UsEast_2]: {
		id: 'ui.createLogFileAnalysisS3BucketForm.region.usEast2',
	},
	accessKeyId: {
		id: 'ui.createLogFileAnalysisS3BucketForm.accessKeyId',
	},
	bucket: {
		id: 'ui.createLogFileAnalysisS3BucketForm.bucket',
	},
	chooseRegion: {
		id: 'ui.createLogFileAnalysisS3BucketForm.chooseRegion',
	},
	createBucket: {
		id: 'ui.createLogFileAnalysisS3BucketForm.createBucket',
	},
	domain: {
		id: 'ui.createLogFileAnalysisS3BucketForm.domain',
	},
	ok: {
		id: 'ui.general.ok',
	},
	region: {
		id: 'ui.createLogFileAnalysisS3BucketForm.region',
	},
	secretAccessKey: {
		id: 'ui.createLogFileAnalysisS3BucketForm.secretAccessKey',
	},
});



function validations(buckets: ReadonlyArray<{ region: GraphQL.LogFileAnalysisS3BucketRegion }>) {
	return {
		validateRegion: validateField(
			'region',
			(f) => ([
				f.validateNonEmpty(),
				f.custom({
					message: 'bucket exists',
					rule: ({ value }) => (
						buckets.find((bucket) => bucket.region === value) === undefined
					),
				}),
			]),
		),
	};
}



type Props = {
	footnote?: React.ReactNode,
	loadCloudflareLogpushVerification?: (bucket: GraphQL.LogFileAnalysisS3Bucket) => void,
	logSourceProvider: (
		| GraphQL.LogSourceProvider.Akamai
		| GraphQL.LogSourceProvider.CloudflareLogpush
		| GraphQL.LogSourceProvider.Cloudfront
		| GraphQL.LogSourceProvider.Fastly
	),
	showAccessFields?: boolean,
	showDomainField?: boolean,
};

const CreateLogFileAnalysisS3BucketForm: React.FC<Props> = (props) => {
	const {
		footnote = null,
		loadCloudflareLogpushVerification = null,
		logSourceProvider,
		showAccessFields = false,
		showDomainField = false,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const websiteId = useWebsiteId();

	const intl = useIntl();

	const [createLogFileAnalysisS3Bucket] = useCreateLogFileAnalysisS3BucketMutation();

	const { data } = useLogFileAnalysisS3BucketsQuery({
		variables: {
			logSourceProvider,
			websiteId,
		},
	});

	const buckets = (data?.website?.logFileAnalysisS3Buckets ?? []).filter(notEmpty);

	const handleCreateBucket = React.useCallback(
		async (values) => {
			const { data } = await createLogFileAnalysisS3Bucket({
				variables: {
					logSourceProvider,
					region: values.region,
					websiteId,
				},
			});

			const buckets = data?.CreateLogFileAnalysisS3Bucket.query.website?.logFileAnalysisS3Buckets ?? [];
			const bucket = buckets.find((bucket) => bucket?.region === values.region) ?? null;

			if (bucket === null) {
				throw new Error('Bucket not created');
			}

			if (loadCloudflareLogpushVerification !== null) {
				loadCloudflareLogpushVerification(bucket);
			}
		},
		[
			createLogFileAnalysisS3Bucket,
			loadCloudflareLogpushVerification,
			logSourceProvider,
			websiteId,
		],
	);

	return (
		<Form
			onSuccess={handleCreateBucket}
			validations={validations(buckets)}
		>
			{({ values }) => {
				const bucket = buckets.find((bucket) => bucket.region === values.region) ?? null;

				return (
					<>
						<ModalContentSection enforceReadableTextLength={true}>
							<FormRow
								fullwidth={true}
								label={(
									<FormattedMessage
										values={{
											provider: null,
										}}
										{...messages.region}
									/>
								)}
							>
								<FieldWithAttachedButton
									button={(
										<SubmitButton>
											<FormattedMessage {...messages.createBucket} />
										</SubmitButton>
									)}
								>
									{({ containerWidth }) => (
										<SelectField
											dropdownWidth={containerWidth}
											name="region"
											options={[
												{
													label: 'EU',
													name: GraphQL.LogFileAnalysisS3BucketRegion.EuCentral_1,
												},
												{
													label: 'US',
													name: GraphQL.LogFileAnalysisS3BucketRegion.UsEast_2,
												},
											]}
											placeholder={intl.formatMessage(messages.chooseRegion)}
											width={containerWidth}
										/>
									)}
								</FieldWithAttachedButton>
							</FormRow>
						</ModalContentSection>

						<ModalContentSection enforceReadableTextLength={true}>
							<FormErrorMessages />
						</ModalContentSection>

						<ModalContentSection>
							<WidthLimiter width={580}>
								<LabeledValuesBox
									items={[
										{
											label: (
												<FormattedMessage
													values={{
														provider: logSourceProvider,
													}}
													{...messages.bucket}
												/>
											),
											value: <CodeValue>{bucket?.name}</CodeValue>,
										},
										{
											label: (
												<FormattedMessage
													values={{
														provider: logSourceProvider,
													}}
													{...messages.region}
												/>
											),
											value: (
												<CodeValue>
													{bucket !== null && (
														<FormattedMessage {...messages[bucket.region]} />
													)}
												</CodeValue>
											),
										},
										showAccessFields ? {
											label: <FormattedMessage {...messages.accessKeyId} />,
											value: <CodeValue>{bucket?.accessKeyId}</CodeValue>,
										} : null,
										showAccessFields ? {
											label: <FormattedMessage {...messages.secretAccessKey} />,
											value: <CodeValue>{bucket?.secretAccessKey}</CodeValue>,
										} : null,
										showDomainField ? {
											label: <FormattedMessage {...messages.domain} />,
											value: <CodeValue>{bucket?.domain}</CodeValue>,
										} : null,
									].filter(notEmpty)}
									labelWidth="30%"
								/>
							</WidthLimiter>
						</ModalContentSection>

						{footnote !== null && (
							<ModalTextSection>
								{footnote}
							</ModalTextSection>
						)}

						<ButtonsLayout>
							<BackButton />

							<Button
								disabled={bucket === null}
								onClick={() => {
									if (bucket === null) {
										throw new Error(`Bucket can't be null`);
									}

									if (loadCloudflareLogpushVerification !== null) {
										loadCloudflareLogpushVerification(bucket);
									}

									classicFormBehavior.finish();
								}}
								style={ButtonStyle.Action}
								width={ButtonWidth.SubmitButton}
							>
								<FormattedMessage {...messages.ok} />
							</Button>
						</ButtonsLayout>
					</>
				);
			}}
		</Form>
	);
};



export default CreateLogFileAnalysisS3BucketForm;
