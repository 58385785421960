import React from 'react';

import {
	ActivePanelContext,
} from '~/components/providers/ActivePanelProvider';
import {
	DynamicModalContext,
} from '~/components/logic/DynamicModal';



function useModalControls() {
	const activePanel = React.useContext(ActivePanelContext);
	const modals = React.useContext(DynamicModalContext);

	return React.useMemo(
		() => {
			if (modals.modal !== null) {
				return {
					canBeClosed: true,
					close: modals.closeCallback,
				};
			}

			if (activePanel.isModal && activePanel.close !== null) {
				return {
					canBeClosed: true,
					close: activePanel.close,
				};
			}

			return null;
		},
		[
			activePanel,
			modals,
		],
	);
}



export default useModalControls;
