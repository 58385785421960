import classNames from 'classnames';
import React from 'react';



type CountSliderLabelsLabelProps = {
	label: React.ReactNode,
	uppercaseLabel?: boolean,
	value?: React.ReactNode,
};

type CountSliderLabelsLabel = Array<CountSliderLabelsLabelProps> | CountSliderLabelsLabelProps;

type Props = {
	/** Definition for left aligned label */
	leftLabel?: CountSliderLabelsLabel,
	/** Definition for right aligned label */
	rightLabel?: CountSliderLabelsLabel,
};



const CountSliderLabels: React.FC<Props> = (props) => {
	const {
		leftLabel,
		rightLabel,
	} = props;

	const _renderLabelItem = (labelData: CountSliderLabelsLabel) => {
		const items = Array.isArray(labelData) ? labelData : [labelData];

		return items.map((item, index) => {
			const labelClasses = classNames({
				'count-slider-labels__label': true,
				'count-slider-labels__label--uppercase': item.uppercaseLabel,
			});

			return (
				<div
					className="count-slider-labels__item-content"
					key={'item-' + index}
				>
					<span className={labelClasses}>{item.label}</span>
					{item.value !== undefined && (
						<span className="count-slider-labels__value">{item.value}</span>
					)}
				</div>
			);
		});
	};

	if (!leftLabel && !rightLabel) {
		return null;
	}

	return (
		<div className="count-slider-labels">
			<div className="count-slider-labels__item">
				{leftLabel && _renderLabelItem(leftLabel)}
			</div>
			<div className="count-slider-labels__item">
				{rightLabel && _renderLabelItem(rightLabel)}
			</div>
		</div>
	);
};



export default CountSliderLabels;

export {
	CountSliderLabelsLabel,
	CountSliderLabelsLabelProps,
};
