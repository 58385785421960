import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import MultiselectField, {
	MultiselectFieldDropdownAttachment,
	type MultiselectFieldRef,
	MultiselectFieldSize,
} from '~/components/atoms/forms/components/MultiselectField';
import SelectFieldButtonToggler from '~/components/atoms/forms/components/scopes/SelectFieldButtonToggler';

import useViewportType from '~/hooks/useViewportType';



type Option = {
	name: string,
	title: React.ReactNode,
};

type Props = {
	dropdownAttachment?: MultiselectFieldDropdownAttachment,
	iconType?: BasicIconType,
	label: React.ComponentProps<typeof MultiselectField>['selectedLabelRenderer'],
	/** Form field name */
	name: string,
	/** Options in dropdown */
	options: Array<Option>,
	width?: number,
};

const MultiselectButton = React.forwardRef<MultiselectFieldRef, Props>((props, ref) => {
	const {
		dropdownAttachment = MultiselectFieldDropdownAttachment.Left,
		iconType,
		label,
		name,
		options,
		width = 100,
	} = props;

	const viewportType = useViewportType();

	const [animatedToggler, setAnimatedToggler] = React.useState(true);

	const _disableAnimatedToggler = () => {
		setAnimatedToggler(false);

		setTimeout(() => {
			setAnimatedToggler(true);
		}, 500);
	};

	const showLabel = !viewportType.isSmall;

	return (
		<MultiselectField
			dropdownAttachment={dropdownAttachment}
			dropdownWidth={250}
			labelRenderer={(label, isOpen, size, isDisabled) => {
				return (
					<SelectFieldButtonToggler
						animation={animatedToggler}
						icon={iconType && (
							<BasicIcon
								color={isOpen ? '#FFFFFF' : '#5893eb'}
								type={iconType}
							/>
						)}
						isDisabled={isDisabled}
						isOpen={isOpen}
						label={label}
					/>
				);
			}}
			name={name}
			onDropdownCloseCallback={_disableAnimatedToggler}
			onDropdownOpenCallback={_disableAnimatedToggler}
			options={options}
			ref={ref}
			scrollableDropdown={true}
			selectedLabelRenderer={label}
			size={MultiselectFieldSize.Small}
			width={showLabel ? width : 50}
		/>
	);
});



export default MultiselectButton;

export {
	BasicIconType as MultiselectButtonIconType,
	MultiselectFieldDropdownAttachment as MultiselectButtonDropdownAttachment,
	MultiselectFieldRef as MultiselectButtonRef,
};
