import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import DraggableSegmentDefinitionIdentifier from '~/components/logic/segments/DraggableSegmentDefinitionIdentifier';
import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';
import SegmentDefinitionIdentifier from '~/components/logic/segments/SegmentDefinitionIdentifier';
import {
	SegmentIdentifierType,
} from '~/components/patterns/segments/SegmentIdentifier';
import {
	OPERATOR_AND,
	OPERATOR_OR,
} from '../SegmentMultiselectField';
import SegmentsList from '~/components/atoms/segments/SegmentsList';
import SegmentSelectionSection, {
	TYPE_ADD as SECTION_TYPE_ADD,
	TYPE_REMOVE as SECTION_TYPE_REMOVE,
} from './SegmentSelectionSection';
import SwitchButtonsField, {
	SwitchButtonsFieldSize,
} from '../SwitchButtonsField';

import useFormContext from '~/hooks/useFormContext';

import {
	type SegmentDefinition,
	filterSegmentDefinitionsByNames,
} from '~/model/segments';



const messages = defineMessages({
	operatorAnd: {
		id: 'ui.segments.operation.and',
	},
	operatorOr: {
		id: 'ui.segments.operation.or',
	},
	selectionAddToFilter: {
		id: 'ui.segments.selection.addToFilter',
	},
	selectionIncluded: {
		id: 'ui.segments.selection.included',
	},
	selectionNotIncluded: {
		id: 'ui.segments.selection.notIncluded',
	},
});



type Props = {
	includedSegments: any,
	name: string,
	notIncludedSegments: any,
	onChangeCallback: (value: {
		includedSegments: any,
		notIncludedSegments: any,
		operator: string,
	}) => void,
	onOperatorChangeCallback: (value: {
		operator: string,
	}) => void,
	operator: string,
	segments: ReadonlyArray<SegmentDefinition>,
	segmentsNotAllowedForFiltering: Record<string, React.ReactNode> | undefined,
};

const SegmentSelectionArea: React.FC<Props> = (props) => {
	const {
		includedSegments,
		name,
		notIncludedSegments,
		onChangeCallback,
		onOperatorChangeCallback,
		operator,
		segments,
		segmentsNotAllowedForFiltering,
	} = props;

	const formContext = useFormContext();
	const intl = useIntl();

	function renderIncludedSegmentsArea() {
		const chosenSegments = filterSegmentDefinitionsByNames(
			segments,
			includedSegments,
		);

		return renderDragSourceArea({
			chosenSegments,
			dropCallback: (item) => {
				let newIncludedSegments = includedSegments.get ? includedSegments : [...includedSegments];
				let newNotIncludedSegments = notIncludedSegments.get ? notIncludedSegments : [...notIncludedSegments];

				if (newIncludedSegments.indexOf(item.segment) === -1) {
					const notIncludedInIndex = newNotIncludedSegments.indexOf(item.segment);

					if (newIncludedSegments.get) {
						newIncludedSegments = newIncludedSegments.push(item.segment);
					} else {
						newIncludedSegments.push(item.segment);
					}

					if (notIncludedInIndex !== -1) {
						if (newNotIncludedSegments.get) {
							newNotIncludedSegments = newNotIncludedSegments.splice(notIncludedInIndex, 1);
						} else {
							newNotIncludedSegments.splice(notIncludedInIndex, 1);
						}
					}

					onChangeCallback({
						includedSegments: newIncludedSegments,
						notIncludedSegments: newNotIncludedSegments,
						operator,
					});
				}
			},
			onClickCallback: (segmentName) => {
				let newIncludedSegments = includedSegments.get ? includedSegments : [...includedSegments];

				const includedInIndex = newIncludedSegments.indexOf(segmentName);

				if (includedInIndex !== -1) {
					if (newIncludedSegments.get) {
						newIncludedSegments = newIncludedSegments.splice(includedInIndex, 1);
					} else {
						newIncludedSegments.splice(includedInIndex, 1);
					}

					onChangeCallback({
						includedSegments: newIncludedSegments,
						notIncludedSegments,
						operator,
					});
				}
			},
			renderSwitch: includedSegments.size > 1 && (
				<SwitchButtonsField
					items={[
						{
							label: intl.formatMessage(messages.operatorAnd).toUpperCase(),
							value: OPERATOR_AND,
						},
						{
							label: intl.formatMessage(messages.operatorOr).toUpperCase(),
							value: OPERATOR_OR,
						},
					]}
					name="operator"
					size={SwitchButtonsFieldSize.XSmall}
					width={100}
				/>
			),
			title: intl.formatMessage(messages.selectionIncluded),
			type: SECTION_TYPE_ADD,
		});
	}

	function renderNotIncludedSegmentsArea() {
		return renderDragSourceArea({
			chosenSegments: filterSegmentDefinitionsByNames(
				segments,
				notIncludedSegments,
			),
			dropCallback: (item) => {
				let newIncludedSegments = includedSegments.get ? includedSegments : [...includedSegments];
				let newNotIncludedSegments = notIncludedSegments.get ? notIncludedSegments : [...notIncludedSegments];

				if (newNotIncludedSegments.indexOf(item.segment) === -1) {
					const includedInIndex = newIncludedSegments.indexOf(item.segment);

					if (newNotIncludedSegments.get) {
						newNotIncludedSegments = newNotIncludedSegments.push(item.segment);
					} else {
						newNotIncludedSegments.push(item.segment);
					}

					if (includedInIndex !== -1) {
						if (newIncludedSegments.get) {
							newIncludedSegments = newIncludedSegments.splice(includedInIndex, 1);
						} else {
							newIncludedSegments.splice(includedInIndex, 1);
						}
					}

					onChangeCallback({
						includedSegments: newIncludedSegments,
						notIncludedSegments: newNotIncludedSegments,
						operator,
					});
				}
			},
			onClickCallback: (segmentName) => {
				let newNotIncludedSegments = notIncludedSegments.get ? notIncludedSegments : [...notIncludedSegments];

				const notIncludedInIndex = newNotIncludedSegments.indexOf(segmentName);

				if (notIncludedInIndex !== -1) {
					if (newNotIncludedSegments.get) {
						newNotIncludedSegments = newNotIncludedSegments.splice(notIncludedInIndex, 1);
					} else {
						newNotIncludedSegments.splice(notIncludedInIndex, 1);
					}

					onChangeCallback({
						includedSegments,
						notIncludedSegments: newNotIncludedSegments,
						operator,
					});
				}
			},
			renderSwitch: false,
			title: intl.formatMessage(messages.selectionNotIncluded),
			type: SECTION_TYPE_ADD,
		});
	}

	function renderSegmentsSourceArea() {
		return renderDragSourceArea({
			chosenSegments: segments.filter((segment) => {
				return (
					includedSegments.indexOf(segment.name) === -1
					&& notIncludedSegments.indexOf(segment.name) === -1
				);
			}),
			dropCallback: (item) => {
				let newIncludedSegments = includedSegments.get ? includedSegments : [...includedSegments];
				let newNotIncludedSegments = notIncludedSegments.get ? notIncludedSegments : [...notIncludedSegments];

				const includedInIndex = newIncludedSegments.indexOf(item.segment);
				const notIncludedInIndex = newNotIncludedSegments.indexOf(item.segment);
				if (includedInIndex !== -1) {
					if (newIncludedSegments.get) {
						newIncludedSegments = newIncludedSegments.splice(includedInIndex, 1);
					} else {
						newIncludedSegments.splice(includedInIndex, 1);
					}
				} else if (notIncludedInIndex !== -1) {
					if (newNotIncludedSegments.get) {
						newNotIncludedSegments = newNotIncludedSegments.splice(notIncludedInIndex, 1);
					} else {
						newNotIncludedSegments.splice(notIncludedInIndex, 1);
					}
				}

				onChangeCallback({
					includedSegments: newIncludedSegments,
					notIncludedSegments: newNotIncludedSegments,
					operator,
				});
			},
			onClickCallback: false,
			renderSwitch: false,
			title: intl.formatMessage(messages.selectionAddToFilter),
			type: SECTION_TYPE_REMOVE,
		});
	}

	function renderDragSourceArea({ chosenSegments, dropCallback, onClickCallback, renderSwitch, title, type }: {
		chosenSegments: ReadonlyArray<SegmentDefinition>,
		dropCallback: any,
		onClickCallback: any,
		renderSwitch: any,
		title: React.ReactNode,
		type:
			| typeof SECTION_TYPE_ADD
			| typeof SECTION_TYPE_REMOVE,
	}) {
		const elements: Array<React.ReactNode> = [];

		chosenSegments.forEach((segment) => {
			if (!segmentsNotAllowedForFiltering || !segmentsNotAllowedForFiltering[segment.name]) {
				elements.push((
					<DraggableSegmentDefinitionIdentifier
						key={segment.name}
						onClickCallback={onClickCallback || undefined}
						segmentDefinition={segment}
						showCriteria={false}
						type={SegmentIdentifierType.Draggable}
					/>
				));
			} else {
				elements.push((
					<SegmentDefinitionIdentifier
						disabled={true}
						disabledExplanation={segmentsNotAllowedForFiltering[segment.name]}
						key={segment.name}
						segmentDefinition={segment}
						showCriteria={false}
					/>
				));
			}
		});

		return (
			<SegmentSelectionSection
				headerAppendix={renderSwitch}
				name={name}
				onDropLabelCallback={dropCallback}
				title={title}
				type={type}
			>
				{chosenSegments.length === 0 ? false : elements.length > 0 ? (
					<SegmentsList>
						{elements}
					</SegmentsList>
				) : []}
			</SegmentSelectionSection>
		);
	}

	const updatedFormContext = React.useMemo(
		() => ({
			...formContext,
			defaultValues: {
				operator,
			},
			onChangeHandler: (name, value) => {
				if (name === 'operator') {
					onOperatorChangeCallback({ operator: value });
				}

				return Promise.resolve();
			},
		}),
		[
			formContext,
			onOperatorChangeCallback,
			operator,
		],
	);

	return (
		<FormContext.Provider value={updatedFormContext}>
			<div className="segment-selection-area">
				<div className="segment-selection-area__column segment-selection-area__column--drag-target">
					{renderIncludedSegmentsArea()}

					{renderNotIncludedSegmentsArea()}
				</div>
				<div className="segment-selection-area__column segment-selection-area__column--drag-source">
					{renderSegmentsSourceArea()}
				</div>
			</div>
		</FormContext.Provider>
	);
};



export default SegmentSelectionArea;
