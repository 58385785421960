import React from 'react';



type Props = {
	screenshot?: React.ReactNode,
	segments?: React.ReactNode,
	statusMessages: ReadonlyArray<React.ReactNode>,
};

const SummaryLayout: React.FC<Props> = (props) => {
	const {
		screenshot,
		segments,
		statusMessages,
	} = props;

	return (
		<div className="page-detail-summary">
			{statusMessages.map((statusMessage, index) => {
				return (
					<div
						className="page-detail-summary__status-message"
						key={index}
					>
						{statusMessage}
					</div>
				);
			})}

			{(screenshot || segments) && (
				<div className="page-detail-summary__content">
					{screenshot && (
						<div className="page-detail-summary__screenshot">
							{screenshot}
						</div>
					)}

					{segments && (
						<div className="page-detail-summary__segments">
							{segments}
						</div>
					)}
				</div>
			)}
		</div>
	);
};



export default SummaryLayout;
