import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import AppearAnimation from '~/components/patterns/animations/AppearAnimation';
import CloseByEscape from './CloseByEscape';
import DepthLayer from '~/components/patterns/utils/DepthLayer';



export const ALIGNMENT_CENTER = 'center';
export const ALIGNMENT_TOP = 'top';
export const SIZE_DEFAULT = 'default';
export const SIZE_MEDIUM = 'medium';
export const SIZE_LARGE = 'large';
export const SIZE_XLARGE = 'xlarge';
export const SIZE_XXLARGE = 'xxlarge';



class ModalPanel extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleCloseModal = this._handleCloseModal.bind(this);
		this._handleModalContentClick = this._handleModalContentClick.bind(this);
		this._handleOverlayClick = this._handleOverlayClick.bind(this);
	}



	_handleCloseModal(e) {
		const {
			onCloseCallback,
		} = this.props;

		if (e) {
			e.preventDefault();
		}

		if (onCloseCallback) {
			onCloseCallback();
		}
	}



	_handleModalContentClick(e) {
		e.stopPropagation();
	}



	_handleOverlayClick(e) {
		const {
			canBeClosed,
			canBeClosedViaOverlay,
		} = this.props;

		if (!canBeClosed || !canBeClosedViaOverlay) {
			return false;
		}

		if (!e) {
			e = window.event;
		}

		this._handleCloseModal();
	}



	render() {
		const {
			alignment,
			canBeClosed,
			canBeClosedViaOverlay,
			size,
		} = this.props;

		let closeButton;

		if (canBeClosed) {
			closeButton = (
				<div>
					<a
						className="modal__close-button"
						href="#"
						onClick={this._handleCloseModal}
					>
						<span>&times;</span>
					</a>
					{canBeClosedViaOverlay && (
						<CloseByEscape
							callback={this._handleCloseModal}
						/>
					)}
				</div>
			);
		}

		const componentClasses = classNames({
			'modal': true,
			'js-scrollable': true,
			'modal--size-medium': size === SIZE_MEDIUM,
			'modal--size-large': size === SIZE_LARGE,
			'modal--size-xlarge': size === SIZE_XLARGE,
			'modal--size-xxlarge': size === SIZE_XXLARGE,
			'modal--has-clickable-overlay': canBeClosed && canBeClosedViaOverlay,
			['modal--' + alignment + '-aligned']: true,
		});

		return (
			<DepthLayer depthJump={3000}>
				<section
					className={componentClasses}
					onClick={this._handleOverlayClick}
				>
					<div className="modal__center">
						<AppearAnimation opacity={0.7}>
							<div
								className="modal__window"
								onClick={this._handleModalContentClick}
							>
								{this.props.children}
								{closeButton}
							</div>
						</AppearAnimation>
					</div>
				</section>
			</DepthLayer>
		);
	}

}

ModalPanel.defaultProps = {
	alignment: ALIGNMENT_CENTER,
	canBeClosed: true,
	canBeClosedViaOverlay: true,
	size: SIZE_DEFAULT,
};

ModalPanel.propTypes = {
	/** Possibility to change default alignment of modal to the center of screen */
	alignment: PropTypes.oneOf([
		ALIGNMENT_CENTER,
		ALIGNMENT_TOP,
	]),
	onCloseCallback: PropTypes.func,
	canBeClosed: PropTypes.bool,
	canBeClosedViaOverlay: PropTypes.bool,
	size: PropTypes.oneOf([
		SIZE_DEFAULT,
		SIZE_MEDIUM,
		SIZE_LARGE,
		SIZE_XLARGE,
		SIZE_XXLARGE,
	]),
};



export default ModalPanel;
