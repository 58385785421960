import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import NoDataMessage from './NoDataMessage';
import NumberWithBar from '~/components/patterns/charts/structures/NumberWithBar';
import WebVitalFormatter from '~/components/app/WebVitalFormatter';
import WebVitalDistributionBarChart from '~/components/app/WebVitalDistributionBarChart';

import WidgetCell from '~/components/patterns/widgets/WidgetCell';
import Widget from './Widget';

import {
	useCumulativeLayoutShiftWidgetQuery,
} from './CumulativeLayoutShiftWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateForQuery,
} from '~/model/dashboard';

import {
	WebVital,
} from '~/model/webVitals';



const messages = defineMessages({
	primaryActionLink: {
		id: 'ui.dashboardCumulativeLayoutShiftWidget.primaryActionLink',
	},
	title: {
		id: 'ui.dashboardCumulativeLayoutShiftWidget.title',
	},
	subtitle: {
		id: 'ui.dashboardCumulativeLayoutShiftWidget.subtitle',
	},
});



const CumulativeLayoutShiftWidget: React.FC = () => {
	const dateRange = useDashboardDateRange();
	const websiteId = useWebsiteId();
	const scope = useDashboardScopeSection();

	const {
		data: metricData,
		loading,
		reasonForNoData,
	} = useDashboardQuery('webVitalsOriginSummaryData', useCumulativeLayoutShiftWidgetQuery, {
		variables: {
			scope,
			websiteId,
			...formatDateForQuery(dateRange),
		},
	});

	return (
		<WidgetCell type={WidgetType.CumulativeLayoutShift}>
			<Widget
				flag={(
					<FormattedMessage {...messages.subtitle} />
				)}
				label={(
					<FormattedMessage {...messages.title} />
				)}
				loading={loading}
				overlay={!loading && !metricData?.cumulativeLayoutShiftScore && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages.primaryActionLink} />
					),
					routeName: 'website.platform',
					routeParams: {
						websiteId,
					},
				}}
				showEndDate={true}
			>
				{!loading && metricData?.cumulativeLayoutShiftScore && (
					<NumberWithBar
						barChart={(
							<WebVitalDistributionBarChart
								maximumFast={metricData.cumulativeLayoutShiftScore.maximumFast}
								maximumGood={metricData.cumulativeLayoutShiftScore.maximumGood}
								percentageFast={metricData.cumulativeLayoutShiftScore.percentageFast}
								percentageGood={metricData.cumulativeLayoutShiftScore.percentageGood}
								percentageSlow={metricData.cumulativeLayoutShiftScore.percentageSlow}
								type={WebVital.CLS}
							/>
						)}
						number={(
							<WebVitalFormatter
								showStatusIndicator={true}
								type={WebVital.CLS}
								value={metricData.cumulativeLayoutShiftScore.percentile}
								verdict={metricData.cumulativeLayoutShiftScore.verdict}
							/>
						)}
						stretchedBarChart={true}
					/>
				)}
			</Widget>
		</WidgetCell>
	);
};



export default CumulativeLayoutShiftWidget;
