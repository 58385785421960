import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CogWheelIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				transform="translate(4 4)"
			>
				<path
					d="m13.6173044 8.79144095c.0767928-.72121895.0571408-.79144095 0-1.50753472l1.6080485-1.28140451c.1239061-.09118981.1531175-.32459257.0765738-.45226042l-1.5314749-2.63818575c-.0697551-.11851578-.306325-.13871686-.4594424-.07537674l-1.856914.77076717c-.6017609-.42005491-.6696921-.46010475-1.3433147-.7840459l-.32216345-1.9465164c0-.1289521-.15317752-.37688368-.30629497-.37688368h-3.06294969c-.15317751 0-.38286871.17031425-.38286871.30150694l-.28165507 2.01411833c-.68629232.33929453-.76809891.39556735-1.32639352.7748209l-1.83776981-.75376736c-.13620561-.06626364-.43042881 0-.53604622.15075347l-1.53147484 2.63818576c-.07009858.11922067.02987249.37688368.15314748.45226042l1.61820631 1.20832692c-.05637476.71423376-.06303296.80199221.00962066 1.52189396l-1.62782697 1.26474616c-.15314748.1097131-.15314748.3459196-.07657374.4522899l1.53147484 2.6381858c.07866907.1316418.33318235.1332952.45944245.0753767l1.90030755-.7267202c.57137365.3804735.61586922.439992 1.30541867.7599809l.31666592 1.9265345c0 .1507534.22544267.3015069.38286871.3015069h3.06294969c.15317751 0 .38286871-.1537286.38286871-.3015069l.306295-1.9597952c.688075-.353068.7336265-.3821686 1.2795699-.7766654l1.9365272.7766654c.1205372.0558337.3962259-.0264563.4594725-.150783l1.5314749-2.6381858c.0796498-.1131103.0529524-.3768836-.0766038-.45226038zm-5.66645697 1.88441835c-1.4549011 0-2.68008097-1.20602773-2.68008097-2.63818571s1.22517987-2.63818576 2.68008097-2.63818576 2.68008097 1.20602778 2.68008097 2.63818576-1.22517987 2.63818571-2.68008097 2.63818571z"
					fillRule="nonzero"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CogWheelIconBuilder;

