import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum GoogleAnalyticsIconVariants {
	Active = 'active',
	Inactive = 'inactive',
}



type GoogleAnalyticsIconBuilderProps = {
	variant?: GoogleAnalyticsIconVariants,
};

let googleAnalyticsIconCounter = 0;

const GoogleAnalyticsIconBuilder: IconBuilder<GoogleAnalyticsIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = GoogleAnalyticsIconVariants.Active,
		} = props;

		const componentId = googleAnalyticsIconCounter++;

		if (variant === GoogleAnalyticsIconVariants.Inactive) {
			return (
				<g>
					<g opacity="0.6">
						<path
							d="M18.895 4.53478H16.054C15.3668 4.53478 14.8027 5.1026 14.8027 5.7943V9.49025H10.6899C10.0232 9.49025 9.47962 10.0374 9.47962 10.7188V14.8587H5.77705C5.10013 14.8587 4.55654 15.4058 4.55654 16.0872V18.9779C4.55654 19.6593 5.10013 20.2064 5.77705 20.2271H18.9053C19.5924 20.2271 20.1565 19.6593 20.1565 18.9676V5.7943C20.1463 5.1026 19.5822 4.53478 18.895 4.53478V4.53478Z"
							fill={'url(#ga-basic-a-' + componentId + ')'}
						/>
						<path
							d="M18.895 4.53478H16.0745C15.3873 4.53478 14.8232 5.1026 14.8232 5.7943V20.2271H18.895C19.5822 20.2271 20.1463 19.6593 20.1463 18.9676V5.7943C20.1463 5.1026 19.5822 4.53478 18.895 4.53478V4.53478Z"
							fill="#989FA7"
						/>
						<path
							d="M9.47962 10.7291V14.8586H5.78731C5.11039 14.8586 4.55654 15.4161 4.55654 16.0975V18.9882C4.55654 19.6696 5.11039 20.2271 5.78731 20.2271H14.813V9.49021H10.7104C10.0335 9.49021 9.47962 10.0477 9.47962 10.7291V10.7291Z"
							fill="#ACB5C0"
						/>
						<path
							d="M14.813 9.49021V20.2271H18.8848C19.5719 20.2271 20.136 19.6592 20.136 18.9675V14.8586L14.813 9.49021Z"
							fill={'url(#ga-basic-b-' + componentId + ')'}
						/>
						<path
							d="M10.7104 9.59345H14.813V9.49021H10.7104C10.0335 9.49021 9.47962 10.0477 9.47962 10.7291V10.8323C9.47962 10.1509 10.0335 9.59345 10.7104 9.59345Z"
							fill="white"
							opacity="0.2"
						/>
						<path
							d="M5.78731 14.9618H9.47962V14.8586H5.78731C5.11039 14.8586 4.55654 15.4161 4.55654 16.0974V16.2007C4.55654 15.5193 5.11039 14.9618 5.78731 14.9618V14.9618Z"
							fill="white"
							opacity="0.2"
						/>
						<path
							d="M18.895 4.53478H16.0745C15.3873 4.53478 14.8232 5.1026 14.8232 5.7943V5.89754C14.8232 5.20584 15.3873 4.63802 16.0745 4.63802H18.895C19.5822 4.63802 20.1463 5.20584 20.1463 5.89754V5.7943C20.1463 5.1026 19.5822 4.53478 18.895 4.53478Z"
							fill="white"
							opacity="0.2"
						/>
						<path
							d="M18.8951 20.1238H5.78732C5.11039 20.1238 4.55654 19.5663 4.55654 18.8849V18.9882C4.55654 19.6696 5.11039 20.2271 5.78732 20.2271H18.8849C19.5721 20.2271 20.1362 19.6592 20.1362 18.9675L20.1363 18.8643C20.1464 19.556 19.5823 20.1238 18.8951 20.1238V20.1238Z"
							fill="#ACB5C0"
							opacity="0.2"
						/>
						<path
							d="M18.895 4.53478H16.0745C15.3873 4.53478 14.8232 5.1026 14.8232 5.7943V9.49025H10.7206C10.0437 9.49025 9.48987 10.0477 9.48987 10.7291V14.8587H5.78731C5.11039 14.8587 4.55654 15.4162 4.55654 16.0975V18.9882C4.55654 19.6696 5.11039 20.2271 5.78731 20.2271H18.895C19.5822 20.2271 20.1463 19.6593 20.1463 18.9676V5.7943C20.1463 5.1026 19.5822 4.53478 18.895 4.53478V4.53478Z"
							fill={'url(#ga-basic-c-' + componentId + ')'}
						/>
					</g>
					<defs>
						<linearGradient
							gradientUnits="userSpaceOnUse"
							id={'ga-basic-a-' + componentId}
							x1="4.55654"
							x2="20.1565"
							y1="12.3809"
							y2="12.3809"
						>
							<stop
								stopColor="white"
								stopOpacity="0.1"
							/>
							<stop
								offset="1"
								stopColor="white"
								stopOpacity="0"
							/>
						</linearGradient>
						<linearGradient
							gradientUnits="userSpaceOnUse"
							id={'ga-basic-b-' + componentId}
							x1="12.1719"
							x2="21.1183"
							y1="12.2002"
							y2="14.4137"
						>
							<stop
								stopColor="#37424F"
							/>
							<stop
								offset="1"
								stopColor="#8595A6"
								stopOpacity="0"
							/>
						</linearGradient>
						<linearGradient
							gradientUnits="userSpaceOnUse"
							id={'ga-basic-c-' + componentId}
							x1="9.78413"
							x2="19.935"
							y1="9.80316"
							y2="19.8877"
						>
							<stop
								stopColor="#C8CCD2"
							/>
							<stop
								offset="1"
								stopColor="white"
								stopOpacity="0"
							/>
						</linearGradient>
					</defs>
				</g>
			);
		}

		return (
			<g>
				<path
					d="M17.9384 3.59998H15.0974C14.4102 3.59998 13.8461 4.16779 13.8461 4.85949V8.55544H9.73331C9.06664 8.55544 8.52305 9.10261 8.52305 9.78398V13.9239H4.82049C4.14357 13.9239 3.59998 14.471 3.59998 15.1524V18.0431C3.59998 18.7245 4.14357 19.2716 4.82049 19.2923H17.9487C18.6359 19.2923 19.2 18.7245 19.2 18.0328V4.85949C19.1897 4.16779 18.6256 3.59998 17.9384 3.59998V3.59998Z"
					fill={'url(#ga-basic-a-' + componentId + ')'}
				/>
				<path
					d="M17.9384 3.59998H15.0974C14.4102 3.59998 13.8461 4.16779 13.8461 4.85949V8.55544H9.73331C9.06664 8.55544 8.52305 9.10261 8.52305 9.78398V13.9239H4.82049C4.14357 13.9239 3.59998 14.471 3.59998 15.1524V18.0431C3.59998 18.7245 4.14357 19.2716 4.82049 19.2923H17.9487C18.6359 19.2923 19.2 18.7245 19.2 18.0328V4.85949C19.1897 4.16779 18.6256 3.59998 17.9384 3.59998V3.59998Z"
					fill={'url(#ga-basic-b-' + componentId + ')'}
				/>
				<path
					d="M17.9385 3.59998H15.118C14.4308 3.59998 13.8667 4.16779 13.8667 4.85949V19.2923H17.9385C18.6257 19.2923 19.1898 18.7245 19.1898 18.0328V4.85949C19.1898 4.16779 18.6257 3.59998 17.9385 3.59998V3.59998Z"
					fill="#F57C00"
				/>
				<path
					d="M8.52305 9.79429V13.9238H4.83074C4.15382 13.9238 3.59998 14.4813 3.59998 15.1627V18.0534C3.59998 18.7348 4.15382 19.2923 4.83074 19.2923H13.8564V8.55542H9.75382C9.0769 8.55542 8.52305 9.11291 8.52305 9.79429Z"
					fill="#FFC107"
				/>
				<path
					d="M13.8564 8.55542V19.2923H17.9282C18.6154 19.2923 19.1795 18.7245 19.1795 18.0327V13.9238L13.8564 8.55542Z"
					fill={'url(#ga-basic-c-' + componentId + ')'}
				/>
				<path
					d="M9.75384 8.65866H13.8564V8.55542H9.75384C9.07692 8.55542 8.52307 9.11291 8.52307 9.79429V9.89753C8.52307 9.21615 9.07692 8.65866 9.75384 8.65866Z"
					fill="white"
					opacity="0.2"
				/>
				<path
					d="M4.83074 14.027H8.52305V13.9238H4.83074C4.15382 13.9238 3.59998 14.4813 3.59998 15.1626V15.2659C3.59998 14.5845 4.15382 14.027 4.83074 14.027Z"
					fill="white"
					opacity="0.2"
				/>
				<path
					d="M17.9385 3.59998H15.118C14.4308 3.59998 13.8667 4.16779 13.8667 4.85949V4.96273C13.8667 4.27103 14.4308 3.70321 15.118 3.70321H17.9385C18.6257 3.70321 19.1898 4.27103 19.1898 4.96273V4.85949C19.1898 4.16779 18.6257 3.59998 17.9385 3.59998Z"
					fill="white"
					opacity="0.2"
				/>
				<path
					d="M17.9386 19.189H4.83076C4.15383 19.189 3.59998 18.6315 3.59998 17.9502V18.0534C3.59998 18.7348 4.15383 19.2923 4.83076 19.2923H17.9283C18.6155 19.2923 19.1796 18.7244 19.1796 18.0327L19.1797 17.9295C19.1899 18.6212 18.6257 19.189 17.9386 19.189Z"
					fill="#BF360C"
					opacity="0.2"
				/>
				<path
					d="M17.9384 3.59998H15.1179C14.4307 3.59998 13.8666 4.16779 13.8666 4.85949V8.55544H9.76408C9.08716 8.55544 8.53331 9.11293 8.53331 9.79431V13.9239H4.83074C4.15382 13.9239 3.59998 14.4814 3.59998 15.1627V18.0534C3.59998 18.7348 4.15382 19.2923 4.83074 19.2923H17.9384C18.6256 19.2923 19.1897 18.7245 19.1897 18.0328V4.85949C19.1897 4.16779 18.6256 3.59998 17.9384 3.59998V3.59998Z"
					fill={'url(#ga-basic-d-' + componentId + ')'}
				/>
				<defs>
					<linearGradient
						gradientUnits="userSpaceOnUse"
						id={'ga-basic-a-' + componentId}
						x1="3.59998"
						x2="19.2"
						y1="11.4461"
						y2="11.4461"
					>
						<stop
							stopColor="white"
							stopOpacity="0.1"
						/>
						<stop
							offset="1"
							stopColor="white"
							stopOpacity="0"
						/>
					</linearGradient>
					<linearGradient
						gradientUnits="userSpaceOnUse"
						id={'ga-basic-b-' + componentId}
						x1="3.59998"
						x2="19.2"
						y1="11.4461"
						y2="11.4461"
					>
						<stop
							stopColor="white"
							stopOpacity="0.1"
						/>
						<stop
							offset="1"
							stopColor="white"
							stopOpacity="0"
						/>
					</linearGradient>
					<linearGradient
						gradientUnits="userSpaceOnUse"
						id={'ga-basic-c-' + componentId}
						x1="11.2154"
						x2="20.1618"
						y1="11.2654"
						y2="13.4789"
					>
						<stop
							stopColor="#BF360C"
							stopOpacity="0.2"
						/>
						<stop
							offset="1"
							stopColor="#BF360C"
							stopOpacity="0.02"
						/>
					</linearGradient>
					<linearGradient
						gradientUnits="userSpaceOnUse"
						id={'ga-basic-d-' + componentId}
						x1="8.82757"
						x2="18.9784"
						y1="8.86836"
						y2="18.9529"
					>
						<stop
							stopColor="white"
							stopOpacity="0.1"
						/>
						<stop
							offset="1"
							stopColor="white"
							stopOpacity="0"
						/>
					</linearGradient>
				</defs>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default GoogleAnalyticsIconBuilder;

