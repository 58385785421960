import React from 'react';

import {
	useColumnSetsQuery,
} from './useColumnSets.gql';

import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import usePollInterval from '~/hooks/usePollInterval';



function useColumnSets() {
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const { data } = useColumnSetsQuery({
		pollInterval: usePollInterval(30_000),
	});

	const memberships = data?.authenticatedSession?.memberships ?? null;
	const rawPersonalColumnSets = data?.authenticatedSession?.personalColumnSets ?? null;

	return React.useMemo(
		() => {
			if (
				effectiveHomeAccountId === null
				|| memberships === null
				|| rawPersonalColumnSets === null
			) {
				return {
					columnSets: [],
					isLoaded: false,
					personalColumnSets: [],
					sharedColumnSets: [],
				};
			}

			const personalColumnSets = rawPersonalColumnSets.map(
				(columnSet) => ({
					...columnSet,
					sharedWithAccountId: null,
				}),
			);

			const sharedColumnSets = (memberships.find(
				(membership) => membership.account.id === effectiveHomeAccountId,
			)?.account.sharedColumnSets ?? []).map(
				(columnSet) => ({
					...columnSet,
					sharedWithAccountId: effectiveHomeAccountId,
				}),
			);

			return {
				columnSets: [
					...sharedColumnSets,
					...personalColumnSets,
				],
				isLoaded: true,
				personalColumnSets,
				sharedColumnSets,
			};
		},
		[
			effectiveHomeAccountId,
			memberships,
			rawPersonalColumnSets,
		],
	);
}



export default useColumnSets;
