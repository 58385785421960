/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupIndexNowKeyMutationVariables = GraphQL.Exact<{
  indexNowKey: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type SetupIndexNowKeyMutation = { readonly __typename?: 'Mutation', readonly SetupIndexNowKey: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isIndexNowEnabled: boolean } | null } } };


export const SetupIndexNowKeyDocument = gql`
    mutation SetupIndexNowKey($indexNowKey: String!, $websiteId: WebsiteId!) {
  SetupIndexNowKey(indexNowKey: $indexNowKey, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        id
        isIndexNowEnabled
      }
    }
  }
}
    `;
export type SetupIndexNowKeyMutationFn = Apollo.MutationFunction<SetupIndexNowKeyMutation, SetupIndexNowKeyMutationVariables>;

/**
 * __useSetupIndexNowKeyMutation__
 *
 * To run a mutation, you first call `useSetupIndexNowKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupIndexNowKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupIndexNowKeyMutation, { data, loading, error }] = useSetupIndexNowKeyMutation({
 *   variables: {
 *      indexNowKey: // value for 'indexNowKey'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useSetupIndexNowKeyMutation(baseOptions?: Apollo.MutationHookOptions<SetupIndexNowKeyMutation, SetupIndexNowKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupIndexNowKeyMutation, SetupIndexNowKeyMutationVariables>(SetupIndexNowKeyDocument, options);
      }
export type SetupIndexNowKeyMutationHookResult = ReturnType<typeof useSetupIndexNowKeyMutation>;
export type SetupIndexNowKeyMutationResult = Apollo.MutationResult<SetupIndexNowKeyMutation>;
export type SetupIndexNowKeyMutationOptions = Apollo.BaseMutationOptions<SetupIndexNowKeyMutation, SetupIndexNowKeyMutationVariables>;