/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManageRoyalAccessMutationVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
  royalGroup: GraphQL.RoyalGroup;
}>;


export type ManageRoyalAccessMutation = { readonly __typename?: 'Mutation', readonly ManageRoyalAccess: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly managedActions: ReadonlyArray<GraphQL.ActionWithUser>, readonly managedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUser>, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithUser>, readonly permittedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUser> | null, readonly royalGroup: GraphQL.RoyalGroup | null } | null } } };


export const ManageRoyalAccessDocument = gql`
    mutation ManageRoyalAccess($legacyUserId: ID!, $royalGroup: RoyalGroup!) {
  ManageRoyalAccess(legacyUserId: $legacyUserId, royalGroup: $royalGroup) {
    query {
      user(legacyId: $legacyUserId) {
        id
        legacyId
        managedActions
        managedActionsForKingdomAdmin
        permittedActions
        permittedActionsForKingdomAdmin
        royalGroup
      }
    }
  }
}
    `;
export type ManageRoyalAccessMutationFn = Apollo.MutationFunction<ManageRoyalAccessMutation, ManageRoyalAccessMutationVariables>;

/**
 * __useManageRoyalAccessMutation__
 *
 * To run a mutation, you first call `useManageRoyalAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageRoyalAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageRoyalAccessMutation, { data, loading, error }] = useManageRoyalAccessMutation({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *      royalGroup: // value for 'royalGroup'
 *   },
 * });
 */
export function useManageRoyalAccessMutation(baseOptions?: Apollo.MutationHookOptions<ManageRoyalAccessMutation, ManageRoyalAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageRoyalAccessMutation, ManageRoyalAccessMutationVariables>(ManageRoyalAccessDocument, options);
      }
export type ManageRoyalAccessMutationHookResult = ReturnType<typeof useManageRoyalAccessMutation>;
export type ManageRoyalAccessMutationResult = Apollo.MutationResult<ManageRoyalAccessMutation>;
export type ManageRoyalAccessMutationOptions = Apollo.BaseMutationOptions<ManageRoyalAccessMutation, ManageRoyalAccessMutationVariables>;