import React from 'react';

import useFeatureFlags from '~/hooks/useFeatureFlags';
import useRoyalMode from '~/hooks/useRoyalMode';

import {
	FeatureFlag,
} from '~/featureFlags';



function useKingdomAdminFeatures() {
	const featureFlags = useFeatureFlags();
	const royalMode = useRoyalMode();

	const isKingdomAdmin = royalMode.isActive;
	const isAdminModeEnabled = featureFlags.isEnabled(FeatureFlag.AdminFeatures);

	return React.useMemo(
		() => ({
			areAlwaysVisible: isKingdomAdmin,
			areVisible: isKingdomAdmin && isAdminModeEnabled,
			toggle: () => window.ContentKing.toggleFeatureFlag(FeatureFlag.AdminFeatures),
		}),
		[
			isAdminModeEnabled,
			isKingdomAdmin,
		],
	);
}



export default useKingdomAdminFeatures;
