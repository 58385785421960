import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import ScrollableBox from '~/components/atoms/boxes/ScrollableBox';
import Spinner from '~/components/patterns/loaders/Spinner';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useAcceptEnrichmentApiTermsOfUseMutation,
} from './AcceptEnrichmentApiTermsOfUseModal.gql';

import useAccountId from '~/hooks/useAccountId';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useLegalDocument, {
	LegalDocument,
} from '~/hooks/useLegalDocument';



const messages = defineMessages({
	intro: {
		id: 'ui.getEnrichmentApiTokenModal.intro',
	},
	submitButton: {
		id: 'ui.getEnrichmentApiTokenModal.accept',
	},
	title: {
		id: 'ui.getEnrichmentApiTokenModal.title',
	},
});



const AcceptEnrichmentApiTermsOfUseModal: React.FC = () => {
	const accountId = useAccountId();
	const classicFormBehavior = useClassicFormBehavior();
	const termsOfUse = useLegalDocument(LegalDocument.TermsOfUse);

	const [acceptEnrichmentApiTermsOfUse] = useAcceptEnrichmentApiTermsOfUseMutation();

	const handleSubmit = React.useCallback(
		async () => {
			if (accountId === null) {
				return;
			}

			await acceptEnrichmentApiTermsOfUse({
				variables: {
					accountId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			acceptEnrichmentApiTermsOfUse,
			accountId,
			classicFormBehavior,
		],
	);

	return (
		<ModalContainer
			header={(
				<ModalHeader
					title={(
						<FormattedMessage {...messages.title} />
					)}
				/>
			)}
		>
			<ModalTextSection>
				<FormattedMessage {...messages.intro} />
			</ModalTextSection>

			<ModalTextSection>
				<ScrollableBox>
					{termsOfUse.content !== null ? (
						termsOfUse.content
					) : (
						<div style={{ height: '1000px' }}>
							<Spinner />
						</div>
					)}
				</ScrollableBox>
			</ModalTextSection>

			<CenteredFormWrapper>
				<Form
					onSuccess={handleSubmit}
				>
					<FormErrorMessages />

					<ButtonsLayout>
						<CancelButton />

						<SubmitButton disallowImpersonation={true}>
							<FormattedMessage {...messages.submitButton} />
						</SubmitButton>
					</ButtonsLayout>
				</Form>
			</CenteredFormWrapper>
		</ModalContainer>
	);
};



export default AcceptEnrichmentApiTermsOfUseModal;
