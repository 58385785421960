import React from 'react';
import {
	FormattedMessage,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AnimateHeartbeat from '~/components/logic/score/AnimateHeartbeat';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import IssueInstruction from '~/components/logic/issues/IssueInstruction';
import IssueLayout, {
	STATE_NOT_REQUIRED,
} from '~/components/atoms/issues/components/issueDetail/IssueLayout';
import IssueScore, {
	IssueScoreSize,
	IssueScoreState,
} from '~/components/patterns/issues/IssueScore';
import IssueText from '~/components/atoms/issues/components/issueDetail/IssueText';
import LighthouseMonitoringNotEnabledMessage from './LighthouseMonitoringNotEnabledMessage';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import WidthLimiter from '~/components/patterns/utils/WidthLimiter';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';

import {
	isDictionaryId,
} from '~/localization/dictionaries';

import {
	IssueCategoryName,
	isRecognizedIssue,
} from '~/model/issuesNew';



type Props = {
	issueCategoryName: IssueCategoryName,
	issueCategoryPoints: number,
	issues: ReadonlyArray<{
		configuration: Record<string, any>,
		isConfigurationModified: boolean,
		name: string,
	}>,
};

const IssueCategoryNotApplicable: React.FC<Props> = (props) => {
	const {
		issueCategoryName,
		issueCategoryPoints,
		issues,
	} = props;

	const websiteId = useWebsiteId();

	const isLighthouseEnabled = useWebsiteIsLighthouseMonitored(websiteId);

	const descriptionId = `issues.categories.${issueCategoryName}.notApplicable.description`;
	const instructionsId = `issues.categories.${issueCategoryName}.notApplicable.instructionsIntroduction`;

	return (
		<IssueLayout
			actionElements={(
				<AnimateHeartbeat
					enabled={false}
				>
					<IssueScore
						isIgnored={false}
						size={IssueScoreSize.Small}
						state={IssueScoreState.NotRequired}
						value={issueCategoryPoints}
					/>
				</AnimateHeartbeat>
			)}
			message={(
				<>
					{issueCategoryName === IssueCategoryName.Lighthouse && (
						<PremiumFeatureSituation
							featureName={GraphQL.AccountFeature.LighthouseMonitoring}
							hideIfUnattainable={false}
							style={PremiumFeatureSituationStyle.Box}
						>
							{({ isFeatureEnabled, premiumAnnotation }) => {
								if (!isFeatureEnabled) {
									return (
										<WidthLimiter width={520}>
											{premiumAnnotation}
										</WidthLimiter>
									);
								}

								if (!isLighthouseEnabled) {
									return <LighthouseMonitoringNotEnabledMessage />;
								}

								return null;
							}}
						</PremiumFeatureSituation>
					)}

					{issueCategoryName === IssueCategoryName.WebVitalsOriginSummary && (
						<PremiumFeatureSituation
							featureName={GraphQL.AccountFeature.WebVitalsOriginSummary}
							hideIfUnattainable={false}
							style={PremiumFeatureSituationStyle.Box}
						>
							{({ isFeatureEnabled, premiumAnnotation }) => {
								if (!isFeatureEnabled) {
									return (
										<WidthLimiter width={520}>
											{premiumAnnotation}
										</WidthLimiter>
									);
								}

								return null;
							}}
						</PremiumFeatureSituation>
					)}
				</>
			)}
			state={STATE_NOT_REQUIRED}
			title={(
				isDictionaryId(descriptionId) ? (
					<FormattedMessage
						id={descriptionId}
					/>
				) : (
					<IssueCategoryTitle
						issueCategoryName={issueCategoryName}
					/>
				)
			)}
		>
			{isDictionaryId(instructionsId) && (
				<IssueText>
					<FormattedMessage
						id={instructionsId}
					/>
				</IssueText>
			)}

			{issues.filter(isRecognizedIssue).map((followupIssue) => (
				<IssueInstruction
					issue={followupIssue}
					key={followupIssue.name}
				/>
			))}
		</IssueLayout>
	);
};



export default IssueCategoryNotApplicable;
