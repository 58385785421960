import React from 'react';

import ButtonsLayout, {
	ButtonsLayoutType,
	ButtonsLayoutUsageContext,
} from '~/components/patterns/buttons/ButtonsLayout';



export enum ModalButtonsLayoutType {
	CenterActionButton = 'center-action-button',
	FormRow = 'form-row',
	Steps = 'steps',
}

type Props = {
	children?: React.ReactNode,
	type?: ModalButtonsLayoutType,
};



const ModalButtonsLayout: React.FC<Props> = (props) => {
	const {
		children,
		type = ModalButtonsLayoutType.CenterActionButton,
	} = props;

	let layoutType;

	if (type === ModalButtonsLayoutType.CenterActionButton) {
		layoutType = ButtonsLayoutType.CenterActionButton;
	}

	if (type === ModalButtonsLayoutType.FormRow) {
		layoutType = ButtonsLayoutType.FormRow;
	}

	if (type === ModalButtonsLayoutType.Steps) {
		layoutType = ButtonsLayoutType.Steps;
	}

	return (
		<ButtonsLayout
			layout={layoutType}
			layoutWidth={520}
			usageContext={ButtonsLayoutUsageContext.InModals}
		>
			{children}
		</ButtonsLayout>
	);
};



export default ModalButtonsLayout;
