import React from 'react';
import {
	useIntl,
} from 'react-intl';

import ChartContainer, {
	CHART_HEIGHT,
} from '~/components/atoms/charts/components/ChartContainer';
import ColumnChart from '~/components/atoms/charts/charts/ColumnChart';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';

import useViewportType from '~/hooks/useViewportType';

import {
	calculateTickIntervalNew,
} from '~/model/websiteStatistics/charts/utilities';

import {
	isArray,
} from '~/utilities/typeCheck';



type Props = {
	colors: ReadonlyArray<string> | Record<string, string>,
	data: any,
	labels?: Record<string, string>,
	name: string,
	numberOfYAxisPoints?: number,
	order?: ReadonlyArray<string>,
};

const BarPagesChart: React.FC<Props> = (props) => {
	const {
		colors,
		data,
		labels,
		name,
		numberOfYAxisPoints = 4,
		order,
	} = props;

	const intl = useIntl();
	const viewportType = useViewportType();

	const renderTooltip = React.useCallback(
		({ count, index }) => {
			const percentage = intl.formatNumber(
				data.get(index).get('percentage') / 100,
				{
					style: 'percent',
					maximumFractionDigits: 2,
					maximumSignificantDigits: 4,
				},
			);

			return intl.formatNumber(count) + ' (' + percentage + ')';
		},
		[
			data,
			intl,
		],
	);

	if (!data) {
		return (
			<SquareSkeleton
				height={CHART_HEIGHT}
				style={SquareSkeletonStyle.Transparent}
			/>
		);
	}

	const renderableData = order
		? data.sort((itemA, itemB) => {
			const positionA = order.indexOf(itemA.get('value'));
			const positionB = order.indexOf(itemB.get('value'));

			if (positionA === positionB) {
				return 0;
			}

			return positionA < positionB ? -1 : 1;
		})
		: data;

	return (
		<ChartContainer
			chart={(
				<ColumnChart
					animate={false}
					barWidth={15}
					data={[
						{
							name: 'foo',
							data: renderableData.map((item) => item.get('count')).toJS(),
						},
					]}
					key={name}
					maximum={({ maxValue }) => calculateTickIntervalNew(maxValue * 1.005, numberOfYAxisPoints)}
					minimum={0}
					renderTooltipCallback={renderTooltip}
					seriesColors={renderableData.map((item, index: number) => {
						if (isArray(colors)) {
							return colors[index % colors.length];
						}

						return colors[item.get('value')] || 'grey';
					}).toJS()}
					viewportType={viewportType}
					xAxisCaptions={renderableData.map((item) => {
						return labels ? labels[item.get('value')] : item.get('value');
					})}
					yAxisPoints={numberOfYAxisPoints}
				/>
			)}
			name={name}
			type="column-chart"
		/>
	);
};



export default React.memo(BarPagesChart);
