import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Hint, {
	HintPopupSkin,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import HintPopupLayout from '~/components/patterns/hints/hint/HintPopupLayout';



type GenericProps = {
	/** Possibility to display confirmation button before deletion */
	confirmation?: never,
	isDisabled?: boolean,
	/** By setting routeName we can change this component to Link instance */
	linkRouteName?: string,
	linkRouteParams?: {},
	/** Callback called when clicking on whole item */
	onClick?: () => void,
};

type ConfirmationProps = Omit<GenericProps, 'confirmation'> & {
	/** Possibility to display confirmation button before deletion */
	confirmation: {
		/** Button label for confirmation button */
		buttonLabel: React.ReactNode,
		/** Additional text visible in confirmation popup */
		dialog?: React.ReactNode,
	},
};

type Props = GenericProps | ConfirmationProps;



const TableDeleteButton: React.FC<Props> = (props) => {
	const {
		confirmation,
		isDisabled,
		linkRouteName,
		linkRouteParams,
		onClick,
	} = props;

	let buttonAttributes = {};

	if (!confirmation) {
		buttonAttributes = {
			linkRouteName,
			linkRouteParams,
			onClick,
		};
	}

	let button = (
		<Button
			disabled={isDisabled}
			icon={(
				<BasicIcon type={BasicIconType.Delete} />
			)}
			muted={true}
			size={ButtonSize.Small}
			style={ButtonStyle.Hollow}
			{...buttonAttributes}
		/>
	);

	if (confirmation) {
		button = (
			<Hint
				popup={confirmation.dialog}
				popupLayout={(children) => (
					<HintPopupLayout
						ctaElement={(
							<Button
								linkRouteName={linkRouteName}
								linkRouteParams={linkRouteParams}
								onClick={onClick}
								size={ButtonSize.XXSmall}
								style={ButtonStyle.Alert}
								uppercase={true}
							>
								{confirmation.buttonLabel}
							</Button>
						)}
					>
						{children}
					</HintPopupLayout>
				)}
				popupMaxWidth={250}
				popupOffset={({ placement }) => {
					if (placement === 'bottom-start' || placement === 'top-start') {
						return [-5, 0];
					}

					if (placement === 'bottom-end' || placement === 'top-end') {
						return [5, 0];
					}

					return [0, 0];
				}}
				popupSkin={HintPopupSkin.Light}
				popupVisibility={HintPopupVisibility.OnClick}
			>
				{button}
			</Hint>
		);
	}

	return button;
};



export default TableDeleteButton;
