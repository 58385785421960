import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useGoogleAnalyticsIntegrationQuery,
} from './useWebsiteGoogleAnalyticsIntegration.gql';



type GoogleAnalyticsIntegration = {
	integration: GraphQL.WebsiteGoogleAnalyticsIntegration | null,
	property: GraphQL.GoogleAnalyticsProperty | null,
	version: GraphQL.GoogleAnalyticsVersion | null,
	view: GraphQL.GoogleAnalyticsView | null,
	isLoading: boolean,
};



function useWebsiteGoogleAnalyticsIntegration(websiteId: CK.WebsiteId): GoogleAnalyticsIntegration {
	const { data } = useGoogleAnalyticsIntegrationQuery({
		variables: {
			websiteId,
		},
	});

	return React.useMemo(
		() => {
			const isLoading = data === undefined;
			const integration = data?.website?.googleAnalyticsIntegration ?? null;

			if (!integration) {
				return {
					integration: null,
					isLoading,
					property: null,
					version: null,
					view: null,
				};
			}

			const property = integration.googleAnalyticsAccount.properties.find((property) => {
				return property.id === integration.propertyId;
			}) ?? null;

			const view = property?.views?.find((view) => {
				return view.id === integration.viewId;
			}) ?? null;

			return {
				integration,
				isLoading,
				property,
				version: integration.version,
				view,
			};
		},
		[
			data,
		],
	);
}



export default useWebsiteGoogleAnalyticsIntegration;
