type Resolve = (value: void | PromiseLike<void>) => void;
type Reject = (reason?: any) => void;

type Script = {
	error?: Event | string,
	loading: boolean,
	promises: Array<[Resolve, Reject]>,
	src: string,
};

const scripts: Record<string, Script> = {};



export default function loadScript(src: string): Promise<void> {
	return new Promise((resolve, reject) => {
		let script: Script | null = scripts[src] ?? null;

		if (script !== null) {
			if (script.loading) {
				script.promises.push([resolve, reject]);
			} else {
				if (script.error) {
					reject(script.error);
				} else {
					resolve();
				}
			}
		}

		if (script === null) {
			script = {
				loading: true,
				promises: [[resolve, reject]],
				src,
			};

			scripts[src] = script;

			const scriptElement = document.createElement('script');

			scriptElement.src = src;
			scriptElement.async = true;

			scriptElement.onload = () => {
				if (script === null) {
					return;
				}

				script.loading = false;
				script.promises.forEach(([resolve]) => {
					resolve();
				});
			};

			scriptElement.onerror = (event) => {
				if (script === null) {
					return;
				}

				script.error = event;
				script.loading = false;
				script.promises.forEach(([, reject]) => {
					reject(event);
				});
			};

			document.body.appendChild(scriptElement);
		}
	});
}
