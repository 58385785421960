import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum HeartIconVariants {
	Default = 'default',
	Down = 'down',
	Up = 'up',
}



type HeartIconBuilderProps = {
	variant?: HeartIconVariants,
};

const HeartIconBuilder: IconBuilder<HeartIconBuilderProps> = {
	icon: (props) => {
		const {
			color = '#8595a6',
			variant = HeartIconVariants.Default,
		} = props;

		if (variant === HeartIconVariants.Down) {
			return (
				<g
					fill="none"
					fillRule="evenodd"
					id="Page-1"
					stroke="none"
					strokeWidth="1"
				>
					<g
						id="health-down"
						transform="translate(2.000000, 3.000000)"
					>
						<path
							d="M17.3842857,9.38571429 C19.0471429,7.62 19.174,4.17771429 17.3842857,2.37342857 C15.5414286,0.371142857 12.334,0.437142857 10.4071429,2.37342857 L9.66657143,3.04628571 L8.926,2.37342857 C8.00300347,1.44401067 6.74729124,0.921325933 5.43742857,0.921325933 C4.1275659,0.921325933 2.87185367,1.44401067 1.94885714,2.37342857 C0.0225221505,4.31363736 0.0225221505,7.44464836 1.94885714,9.38485714 L9.66657143,17.2131429 L17.3842857,9.38571429 L17.3842857,9.38571429 Z"
							id="Path"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<g
							id="Group"
							transform="translate(14.285714, 13.285714) scale(-1, 1) rotate(-180.000000) translate(-14.285714, -13.285714) translate(6.571429, 5.571429)"
						>
							<g>
								<polygon
									id="Path"
									points="15.4285714 15.4285714 0 15.4285714 0 0 15.4285714 0"
								>
								</polygon>
								<path
									d="M7.71428571,3.21857143 L7.71428571,12.0574286 M11.4651429,6.96857143 L7.71428571,3.21857143 M7.71428571,3.21857143 L3.96342857,6.96857143"
									id="Shape"
									stroke="#FFEEEE"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="5"
								>
								</path>
							</g>
							<g>
								<polygon
									id="Path"
									points="15.4285714 15.4285714 0 15.4285714 0 0 15.4285714 0"
								>
								</polygon>
								<path
									d="M7.71428571,3.21857143 L7.71428571,12.0574286 M11.4651429,6.96857143 L7.71428571,3.21857143 M7.71428571,3.21857143 L3.96342857,6.96857143"
									id="Shape"
									stroke="#FF5959"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								>
								</path>
							</g>
						</g>
					</g>
				</g>
			);
		}

		if (variant === HeartIconVariants.Up) {
			return (
				<g
					fill="none"
					fillRule="evenodd"
					id="Page-1"
					stroke="none"
					strokeWidth="1"
				>
					<g
						id="health-up"
						transform="translate(2.000000, 3.000000)"
					>
						<path
							d="M17.3842857,9.38571429 C19.0471429,7.62 19.174,4.17771429 17.3842857,2.37342857 C15.5414286,0.371142857 12.334,0.437142857 10.4071429,2.37342857 L9.66657143,3.04628571 L8.926,2.37342857 C8.00300347,1.44401067 6.74729124,0.921325933 5.43742857,0.921325933 C4.1275659,0.921325933 2.87185367,1.44401067 1.94885714,2.37342857 C0.0225221505,4.31363736 0.0225221505,7.44464836 1.94885714,9.38485714 L9.66657143,17.2131429 L17.3842857,9.38571429 L17.3842857,9.38571429 Z"
							id="Path"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<g
							id="Group"
							transform="translate(6.571429, 5.571429)"
						>
							<polygon
								id="Path"
								points="15.4285714 15.4285714 0 15.4285714 0 0 15.4285714 0"
							>
							</polygon>
							<path
								d="M7.71428571,3.21857143 L7.71428571,12.0574286 M11.4651429,6.96857143 L7.71428571,3.21857143 M7.71428571,3.21857143 L3.96342857,6.96857143"
								id="Shape"
								stroke="#ECF9EF"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="5"
							>
							</path>
							<g>
								<polygon
									id="Path"
									points="15.4285714 15.4285714 0 15.4285714 0 0 15.4285714 0"
								>
								</polygon>
								<path
									d="M7.71428571,3.21857143 L7.71428571,12.0574286 M11.4651429,6.96857143 L7.71428571,3.21857143 M7.71428571,3.21857143 L3.96342857,6.96857143"
									id="Shape"
									stroke="#89c32e"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								>
								</path>
							</g>
						</g>
					</g>
				</g>
			);
		}

		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g
					className="icon-basis"
					id="health"
					stroke={color}
					transform="translate(3.000000, 3.500000)"
				>
					<path
						d="M16.9073197,8.50300282 C18.5728504,6.73444962 18.6999115,3.28662942 16.9073197,1.47944278 C15.0614996,-0.5260622 11.8489142,-0.459956085 9.91895902,1.47944278 L9.1771969,2.15338174 L8.43543479,1.47944278 C7.51095427,0.548530567 6.25322311,0.0250054582 4.94125445,0.0250054582 C3.62928578,0.0250054582 2.37155462,0.548530567 1.44707411,1.47944278 C-0.482358036,3.42277103 -0.482358036,6.55881605 1.44707411,8.5021443 L9.1771969,16.3430163 L16.9073197,8.50300282 L16.9073197,8.50300282 Z"
						id="Path"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default HeartIconBuilder;

