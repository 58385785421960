import {
	List,
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
	LOGOUT_SUCCESSFUL,
} from '~/actions';

import {
	RESET_HISTORICAL_CHANGES_INTERVAL,
	STORE_HISTORICAL_CHANGES,
	UPDATE_FILTER,
	UPDATE_HISTORICAL_CHANGES_INTERVAL,
	UPDATE_SORT_BY,
} from '~/actions/historicalChanges';

import {
	CHANGE_VIEW,
	SET_COLUMNS,
} from '~/actions/teams/views';

import {
	decodeFilterParameter,
} from '~/model/filterParameter';

import {
	DEFAULT_FILTER,
	DEFAULT_SORT_BY,
	normalizeFilter,
	normalizeSortBy,
} from '~/model/historicalChanges';

import {
	coverSpan,
	createCoverage,
} from '~/utilities/coverage';



const defaultCoverage = createCoverage();



function createDefaultState() {
	return new Map({
		changesIn: new List(),
		coverage: defaultCoverage,
		dateInterval: new Map({
			startDate: null,
			endDate: null,
		}),
		filter: DEFAULT_FILTER,
		filtered: new List(),
		loading: false,
		sortBy: DEFAULT_SORT_BY,
		raw: new Map(),
		total: null,
		websiteId: null,
	});
}



export function historicalChanges(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			const {
				urlState,
			} = action;

			const oldState = state;

			if (urlState.params.websiteId !== state.get('websiteId')) {
				state = createDefaultState();
			}

			if (urlState.name.indexOf('website.pages') === 0) {
				state = state.mergeIn(['dateInterval'], {
					startDate: urlState.params.start_date || null,
				});

				state = state.mergeIn(['dateInterval'], {
					endDate: urlState.params.end_date || null,
				});

				if (
					state.get('dateInterval').get('startDate')
					&& state.get('dateInterval').get('endDate')
					&& urlState.params.filter
				) {
					const unpackedFilter = decodeFilterParameter(urlState.params.filter);

					if (unpackedFilter.filter) {
						state = state.mergeIn(['filter'], normalizeFilter(unpackedFilter.filter));
					}

					if (unpackedFilter.sortBy) {
						state = state.mergeIn(['sortBy'], normalizeSortBy(unpackedFilter.sortBy));
					}
				} else {
					state = state.set('filter', DEFAULT_FILTER);
					state = state.set('sortBy', DEFAULT_SORT_BY);
				}
			} else {
				state = state.set('filter', DEFAULT_FILTER);
				state = state.set('sortBy', DEFAULT_SORT_BY);
			}

			if (
				oldState.get('dateInterval') !== state.get('dateInterval')
				|| oldState.get('filter') !== state.get('filter')
				|| oldState.get('sortBy') !== state.get('sortBy')
			) {
				state = state.set('loading', true);
			}

			if (oldState.get('dateInterval') !== state.get('dateInterval')) {
				state = state.set('filtered', new List());
			}

			if (oldState !== state) {
				state = state.set('coverage', defaultCoverage);
			}

			break;
		}

		case CHANGE_VIEW:
		case SET_COLUMNS: {
			const {
				columns,
			} = action;

			let filter = state.get('filter');

			columns.forEach((column) => {
				if (column.enabled) {
					if (!filter.has(column.name) && DEFAULT_FILTER.has(column.name)) {
						filter = filter.set(
							column.name,
							DEFAULT_FILTER.get(column.name),
						);
					}
				} else {
					if (filter.has(column.name)) {
						filter = filter.delete(column.name);
					}
				}
			});

			if (state.get('filter') !== filter) {
				state = state.set('filter', filter);
			}

			break;
		}

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case RESET_HISTORICAL_CHANGES_INTERVAL: {
			state = state.mergeIn(['dateInterval'], {
				startDate: null,
				endDate: null,
			});

			break;
		}

		case STORE_HISTORICAL_CHANGES: {
			const {
				changesIn,
				dropOldFiltered,
				filtered,
				limit,
				offset,
				pages,
				total,
				websiteId,
			} = action;

			let raw = state.get('raw');
			pages.forEach((page) => {
				raw = raw.set(page.get('id'), page);
			});

			state = state.set(
				'coverage',
				coverSpan(
					state.get('coverage'),
					offset,
					offset + limit,
				),
			);

			if (dropOldFiltered) {
				state = state.set('filtered', new List());
			}

			let oldFiltered = state.get('filtered');
			filtered.forEach((pageId, index) => {
				oldFiltered = oldFiltered.set(
					index + offset,
					pageId,
				);
			});

			state = state.set('changesIn', changesIn);
			state = state.set('filtered', oldFiltered);
			state = state.set('loading', false);
			state = state.set('raw', raw);
			state = state.set('total', total);
			state = state.set('websiteId', websiteId);

			break;
		}

		case UPDATE_FILTER: {
			const {
				filter,
			} = action;

			state = state.mergeIn(['filter'], filter);

			state = state.setIn(
				['filter'],
				state.get('filter').filter((val) => val !== ''),
			);

			break;
		}

		case UPDATE_HISTORICAL_CHANGES_INTERVAL: {
			const {
				endDate,
				pagesFilter,
				pagesSortBy,
				startDate,
			} = action;

			state = state.mergeIn(['dateInterval'], {
				endDate,
				startDate,
			});

			state = state.mergeIn(['filter'], pagesFilter);
			state = state.mergeIn(['sortBy'], pagesSortBy);
			state = state.set('loading', true);

			break;
		}

		case UPDATE_SORT_BY: {
			const {
				sortBy,
			} = action;

			state = state.mergeIn(['sortBy'], sortBy);

			break;
		}

	}

	return state;
}
