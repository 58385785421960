import type CK from '~/types/contentking';

import {
	type ClientConnectionsQuery,
	useClientConnectionsQuery,
} from './useAccountClientConnections.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';

import memoizeById from '~/utilities/memoizeById';



const emptyList = [];

const format = memoizeById(
	(
		data: ClientConnectionsQuery | undefined,
	) => {
		const clientConnections = data?.account?.clientConnections ?? null;

		return {
			count: clientConnections?.length ?? 0,
			getByAccountId: (accountId: CK.AccountId) => {
				return (clientConnections ?? []).find(
					(clientConnection) => clientConnection.clientAccountId === accountId,
				) ?? null;
			},
			isLoaded: clientConnections !== null,
			listAll: () => clientConnections ?? emptyList,
		};
	},
);



function useAccountClientConnections(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useClientConnectionsQuery,
		accountId,
	);

	return format(accountId, data);
}



export default useAccountClientConnections;
