import Immutable, {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import DonutPagesChart from '../components/DonutPagesChart';



class StatusCodeChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		this.bracketColors = {
			'2xx': '#42CC67',
			'3xx': '#367be2',
			'4xx': '#FF5959',
			'5xx': '#FE8719',
		};

		this.bracketLabels = {
			'2xx': '2xx',
			'3xx': '3xx',
			'4xx': '4xx',
			'5xx': '5xx',
		};

		this.bracketOrder = [
			'2xx',
			'3xx',
			'4xx',
			'5xx',
		];

		this.specificColors = {
			'200': '#42CC67',
			'201': 'rgba(66,204,103,.95)',
			'202': 'rgba(66,204,103,.9)',
			'203': 'rgba(66,204,103,.85)',
			'204': 'rgba(66,204,103,.8)',
			'205': 'rgba(66,204,103,.75)',
			'206': 'rgba(66,204,103,.7)',
			'207': 'rgba(66,204,103,.65)',
			'208': 'rgba(66,204,103,.6)',
			'226': 'rgba(66,204,103,.55)',
			'300': 'rgba(23,161,229,.4)',
			'301': '#367be2',
			'302': 'rgba(23,161,229,.9)',
			'303': 'rgba(23,161,229,.8)',
			'304': 'rgba(23,161,229,.7)',
			'305': 'rgba(23,161,229,.6)',
			'306': 'rgba(23,161,229,.55)',
			'307': 'rgba(23,161,229,.5)',
			'308': 'rgba(23,161,229,.45)',
			'400': 'rgba(255,152,48,.7)',
			'401': 'rgba(255,152,48,.8)',
			'402': 'rgba(255,152,48,.55)',
			'403': 'rgba(255,152,48,.9)',
			'404': '#FF9830',
			'405': 'rgba(255,152,48,.6)',
			'406': 'rgba(255,152,48,.5)',
			'407': 'rgba(255,152,48,.45)',
			'408': 'rgba(255,152,48,.4)',
			'409': 'rgba(255,152,48,.35)',
			'410': 'rgba(255,152,48,.3)',
			'411': 'rgba(255,152,48,.25)',
			'412': 'rgba(255,152,48,.2)',
			'413': 'rgba(255,152,48,.15)',
			'414': '#8595A6',
			'415': '#8595A6',
			'416': '#8595A6',
			'417': '#8595A6',
			'418': '#8595A6',
			'421': '#8595A6',
			'422': '#8595A6',
			'423': '#8595A6',
			'424': '#8595A6',
			'426': '#8595A6',
			'428': '#8595A6',
			'429': '#8595A6',
			'431': '#8595A6',
			'451': '#8595A6',
			'500': '#FF5959',
			'501': 'rgba(255,89,89,.9)',
			'502': 'rgba(255,89,89,.8)',
			'503': 'rgba(255,89,89,.7)',
			'504': 'rgba(255,89,89,.6)',
			'505': 'rgba(255,89,89,.5)',
			'506': 'rgba(255,89,89,.45)',
			'507': 'rgba(255,89,89,.4)',
			'508': 'rgba(255,89,89,.35)',
			'510': 'rgba(255,89,89,.3)',
			'511': 'rgba(255,89,89,.25)',
		};
	}



	_createBracketsData(data) {
		const dataPerBracket = {
			'2xx': 0,
			'3xx': 0,
			'4xx': 0,
			'5xx': 0,
		};

		let total = 0;
		data.forEach((item) => {
			let bracket;

			if (item.get('value') >= 200 && item.get('value') < 300) {
				bracket = '2xx';
			} else if (item.get('value') >= 300 && item.get('value') < 400) {
				bracket = '3xx';
			} else if (item.get('value') >= 400 && item.get('value') < 500) {
				bracket = '4xx';
			} else if (item.get('value') >= 500) {
				bracket = '5xx';
			}

			total += item.get('count');
			dataPerBracket[bracket] += item.get('count');
		});

		return Immutable.fromJS([
			{
				value: '2xx',
				count: dataPerBracket['2xx'],
				percentage: dataPerBracket['2xx'] / total * 100,
			},
			{
				value: '3xx',
				count: dataPerBracket['3xx'],
				percentage: dataPerBracket['3xx'] / total * 100,
			},
			{
				value: '4xx',
				count: dataPerBracket['4xx'],
				percentage: dataPerBracket['4xx'] / total * 100,
			},
			{
				value: '5xx',
				count: dataPerBracket['5xx'],
				percentage: dataPerBracket['5xx'] / total * 100,
			},
		]);
	}



	render() {
		const {
			data,
		} = this.props;

		const useBrackets = (data && data.size > 6);

		return (
			<DonutPagesChart
				data={useBrackets ? this._createBracketsData(data) : data}
				labels={useBrackets ? this.bracketLabels : undefined}
				name="status_code"
				order={useBrackets ? this.bracketOrder : (data ? data.map((item) => item.get('value')).toJS().sort() : undefined)}
				percentageFactor={100}
				specificColors={useBrackets ? this.bracketColors : this.specificColors}
			/>
		);
	}

}

StatusCodeChart.propTypes = {
	data: PropTypes.instanceOf(List),
};



export default StatusCodeChart;
