import {
	List,
	Map,
} from 'immutable';
import memoizee from 'memoizee';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ChangeSummary, {
	PAGES_DIFF_CAPACITY,
} from '~/components/logic/pricing/ChangeSummary';
import DisplayPart from '../basis/DisplayPart';
import EditableFormWrapper from '../basis/EditableFormWrapper';
import EditablePart from '../basis/EditablePart';
import Form from '../basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '../basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import PageCapacityField from '~/components/app/PageCapacityField';
import PaymentAuthorizationContext from '../../../logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '../../../logic/PaymentAuthorizationError';
import PaymentCancelButton from '../../../logic/PaymentCancelButton';
import PaymentSubmitButton from '../../../logic/PaymentSubmitButton';
import StaticText from '../components/StaticText';
import WithPermission from '../../../logic/access/WithPermission';

import {
	ObjectType,
} from '~/model/permissions';

import {
	createPurchase,
} from '~/model/pricing/costs';

import {
	DETAIL_END_USER_PLAN,
	DETAIL_END_USER_PLAN_WEBSITES,
	DETAIL_END_USER_PLAN_WEBSITES_PAGES,
} from '~/model/pricing/endUser';



const messages = defineMessages({
	formErrors: {
		blank: {
			id: 'ui.formErrors.blank',
		},
	},
	pagesLabel: {
		id: 'ui.websiteSettings.plan.pageCapacity',
	},
	saveButton: {
		id: 'ui.general.saveButton',
	},
	title: {
		id: 'ui.websiteSettings.plan.header.endUser',
	},
});

const validations = {
	page_capacity: [
		{
			message: (
				<FormattedMessage {...messages.formErrors.blank} />
			),
			field: 'page_capacity',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
};



class EditEndUserWebsiteSubscriptionForm extends Component {

	constructor(props, context) {
		super(props, context);

		this._calculatePrice = memoizee(this._calculatePrice.bind(this), {
			length: 5,
		});
		this._handleFormChange = this._handleFormChange.bind(this);
		this._handleToggleEditMode = this._handleToggleEditMode.bind(this);

		this.state = {
			changedValues: {},
			showChangeSummary: false,
		};
	}



	_calculatePrice(endUserPlan, billingCycle, pageCapacity, tariff, teamBilling) {
		const discounts = teamBilling.get('discounts').filter((discount) => discount.get('type') !== 'dollars');
		const taxRate = teamBilling.get('tax');

		return tariff.calculatePurchaseCostDetails({
			billingCycle,
			discounts,
			purchases: [
				createPurchase({
					details: {
						[DETAIL_END_USER_PLAN]: endUserPlan,
						[DETAIL_END_USER_PLAN_WEBSITES]: List([
							Map({
								[DETAIL_END_USER_PLAN_WEBSITES_PAGES]: pageCapacity,
							}),
						]),
					},
					numberOfPages: pageCapacity,
				}),
			],
			taxRate,
		});
	}



	_handleFormChange(field, name, values) {
		const {
			website,
		} = this.props;

		this.setState(
			Object.assign({}, this.state, {
				changedValues: values,
				showChangeSummary: (
					website.get('page_capacity') !== values.page_capacity
				),
			}),
		);
	}



	_handleToggleEditMode(editMode) {
		if (!editMode) {
			this.setState({
				changedValues: {},
				showChangeSummary: false,
			});
		}
	}



	_renderOverview() {
		const {
			website,
		} = this.props;

		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.pagesLabel} />
					)}
				>
					<StaticText>
						<FormattedNumber value={website.get('pages_count')} />
						&nbsp;/&nbsp;
						<FormattedNumber value={website.get('pages_limit')} />
						&nbsp;
						<span className="percentage">
							({(
								<FormattedNumber
									maximumFractionDigits={2}
									minimumFractionDigits={1}
									style="percent"
									value={website.get('pages_count') / website.get('pages_limit')}
								/>
							)})
						</span>
					</StaticText>
				</FormRow>
			</FormRows>
		);
	}



	_renderChangesOverview(values, oldValues) {
		const {
			tariff,
			team,
			teamBilling,
		} = this.props;

		const isAccountChargeable = team.get('is_billable') && teamBilling.get('tax');

		if (!isAccountChargeable) {
			return false;
		}

		const priceOld = this._calculatePrice(
			team.get('end_user_plan'),
			team.get('billing_cycle'),
			oldValues.page_capacity,
			tariff,
			teamBilling,
		);

		const priceNew = this._calculatePrice(
			team.get('end_user_plan'),
			team.get('billing_cycle'),
			values.page_capacity,
			tariff,
			teamBilling,
		);

		if (!priceNew || !priceOld) {
			return false;
		}

		return (
			<ChangeSummary
				accountId={team.get('id')}
				allowedBillingCycles={team.get('allowed_billing_cycles')}
				billingCycleNew={team.get('billing_cycle')}
				billingCycleOld={team.get('billing_cycle')}
				currency={team.get('currency')}
				hidePrice={team.get('is_before_first_billing') && !team.get('is_billable')}
				pagesDiff={PAGES_DIFF_CAPACITY}
				pagesNew={values.page_capacity}
				pagesOld={oldValues.page_capacity}
				priceNew={priceNew.subtotal}
				priceOld={priceOld.subtotal}
				taxType={priceNew.tax > 0 ? 'Vat' : null}
			/>
		);
	}



	_renderForm(defaultValues) {
		const {
			submitCallback,
			team,
			website,
		} = this.props;

		return (
			<PaymentAuthorizationContext
				onSubmit={submitCallback}
				useModal={true}
			>
				{({ formRef, handleFormSuccess }) => (
					<Form
						defaultFocus="name"
						defaultValues={defaultValues}
						onChangeCallback={this._handleFormChange}
						onSuccess={handleFormSuccess}
						ref={formRef}
						validations={validations}
					>
						<FormRows>
							<FormRow
								htmlFor="page_capacity"
								label={(
									<FormattedMessage {...messages.pagesLabel} />
								)}
							>
								<PageCapacityField
									accountId={team.get('id')}
									name="page_capacity"
									numberOfPages={website.get('pages_count')}
								/>
							</FormRow>
						</FormRows>

						<PaymentAuthorizationError />

						<ButtonsLayout>
							<PaymentCancelButton />

							<PaymentSubmitButton>
								<FormattedMessage {...messages.saveButton} />
							</PaymentSubmitButton>
						</ButtonsLayout>
					</Form>
				)}
			</PaymentAuthorizationContext>
		);
	}



	render() {
		const {
			website,
		} = this.props;

		const {
			changedValues,
			showChangeSummary,
		} = this.state;

		const defaultValues = {
			page_capacity: website.get('page_capacity'),
		};

		return (
			<WithPermission
				action={GraphQL.ActionWithWebsite.UpdatePageCapacity}
				objectId={website.get('id')}
				objectType={ObjectType.Website}
				showMessage={false}
			>
				{({ isAllowed }) => (
					<EditableFormWrapper
						footer={({ editMode }) => {
							if (!editMode || !showChangeSummary) {
								return false;
							}

							return this._renderChangesOverview(changedValues, defaultValues);
						}}
						isAllowed={isAllowed}
						key={website.get('id')}
						onEnterEditModeCallback={this._handleToggleEditMode}
						title={(
							<FormattedMessage {...messages.title} />
						)}
					>
						<DisplayPart>
							{this._renderOverview()}
						</DisplayPart>

						<EditablePart>
							{this._renderForm(defaultValues)}
						</EditablePart>
					</EditableFormWrapper>
				)}
			</WithPermission>
		);
	}

}

EditEndUserWebsiteSubscriptionForm.propTypes = {
	team: PropTypes.object.isRequired,
	teamBilling: PropTypes.object.isRequired,
	website: PropTypes.object.isRequired,
};



export default EditEndUserWebsiteSubscriptionForm;
