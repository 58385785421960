import type CK from '~/types/contentking';

import {
	type WebsiteCustomElementDefinitionsQuery,
	useWebsiteCustomElementDefinitionsQuery,
} from './useWebsiteCustomElementDefinitions.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';

import {
	type CustomElementDefinition,
} from '~/model/customElements';

import memoizeById from '~/utilities/memoizeById';



type WebsiteCustomElementDefinitions = Readonly<{
	count: number,
	getByColumn: (column: string) => CustomElementDefinition | null,
	getByName: (name: string) => CustomElementDefinition | null,
	isLoaded: boolean,
	listAll: () => Readonly<Array<CustomElementDefinition>>,
}>;

const format = memoizeById(
	(data: WebsiteCustomElementDefinitionsQuery | undefined) => {
		const customElementDefinitions = data?.website?.customElements ?? null;

		return {
			count: customElementDefinitions?.length ?? 0,
			getByColumn: (column: string) => customElementDefinitions?.find((customElementDefinition) => customElementDefinition.column === column) ?? null,
			getByName: (name: string) => customElementDefinitions?.find((customElementDefinition) => customElementDefinition.name === name) ?? null,
			isLoaded: customElementDefinitions !== null,
			listAll: () => customElementDefinitions ?? [],
		};
	},
);



function useWebsiteCustomElementDefinitions(
	websiteId: CK.WebsiteId | null,
): WebsiteCustomElementDefinitions {
	const { data } = useWebsiteCustomElementDefinitionsQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return format(websiteId, data);
}



export default useWebsiteCustomElementDefinitions;
