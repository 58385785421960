import React from 'react';

import DropdownIcon from '~/components/patterns/icons/DropdownIcon';
import IconOnlyButton from '~/components/patterns/buttons/IconOnlyButton';



type Props = {
	/** Enable active state of button */
	active?: boolean,
	/** Enable ignoring whole pointer events on this component. Component will became just presentation of the icon. */
	ignorePointerEvents?: boolean,
	/** Callback triggered on component click */
	onClick: () => void,
	/** Callback triggered on component mouse enter */
	onMouseEnter?: () => void,
	/** Callback triggered on component mouse leave */
	onMouseLeave?: () => void,
	title?: string,
};



const ChevronButton: React.FC<Props> = (props) => {
	const {
		active = false,
		ignorePointerEvents,
		onClick,
		onMouseEnter,
		onMouseLeave,
		title,
	} = props;

	return (
		<IconOnlyButton
			hoverEffect={false}
			icon={(
				<DropdownIcon isActive={active} />
			)}
			ignorePointerEvents={ignorePointerEvents}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			title={title}
		/>
	);
};



export default ChevronButton;
