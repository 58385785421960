import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class AuthSectionsGroup extends Component {

	render() {
		const {
			divider,
			children,
		} = this.props;

		const componentClasses = classNames({
			'auth-sections': true,
			'auth-sections--with-divider': divider,
		});

		return (
			<div className={componentClasses}>
				{React.Children.map(
					children,
					(child, index) => {
						if (!child) {
							return false;
						}

						return (
							<div
								className="auth-sections__section"
								key={'auth-section-' + index}
							>
								{child}
							</div>
						);
					},
				)}
			</div>
		);
	}

}

AuthSectionsGroup.propTypes = {
	/** Enable divider between sections */
	divider: PropTypes.bool,
};



export default AuthSectionsGroup;
