import React from 'react';

import BorderedBox from '~/components/patterns/boxes/BorderedBox';



type Props = {
	children?: React.ReactNode,
	/** Additional Call-to-action elements displayed in the box header */
	headerActionElements?: React.ReactNode,
	/** State of collapsible fieldset */
	isCollapsed?: boolean,
	/** Make fieldset collapsible */
	isCollapsible?: boolean,
	/** Label of fieldset */
	label: React.ReactNode,
};



const Fieldset: React.FC<Props> = (props) => {
	const {
		children,
		headerActionElements,
		isCollapsed = false,
		isCollapsible = false,
		label,
	} = props;

	return (
		<BorderedBox
			headerActionElements={headerActionElements}
			headerLabel={label}
			isCollapsed={isCollapsed}
			isCollapsible={isCollapsible}
			paddingSize={2}
		>
			<>
				{children}
			</>
		</BorderedBox>
	);
};



export default Fieldset;
