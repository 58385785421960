import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import List from '~/components/patterns/lists/List';



class GeneralIssueCategoryCardContent extends Component {

	_renderChildren() {
		const {
			children,
		} = this.props;

		const childrenCount = React.Children.count(children);

		if (childrenCount > 1) {
			return (
				<List>
					{children}
				</List>
			);
		}

		return children;
	}



	render() {
		const {
			score,
			title,
		} = this.props;

		return (
			<div className="issue-category-card-content">
				<div className="issue-category-card-content__header">
					<div className="issue-category-card-content__title">
						{title}
					</div>
					{score && (
						<div className="issue-category-card-content__score">
							{score}
						</div>
					)}
				</div>
				<div className="issue-category-card-content__overview">
					{this._renderChildren()}
				</div>
			</div>
		);
	}

}

GeneralIssueCategoryCardContent.propTypes = {
	/** Issue category score */
	score: PropTypes.node,
	/** Title of issue category */
	title: PropTypes.node.isRequired,
};



export default GeneralIssueCategoryCardContent;
