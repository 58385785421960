import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ExternalLinkIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-external-link">
					<g id="external-link">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<path
							d="M4.92729418,5.3027925 C4.92717364,5.30763528 4.927113,5.31249332 4.927113,5.31736585 L4.927113,18.7097381 C4.927113,19.0183168 5.1703115,19.2687575 5.47118123,19.2687575 L18.5288188,19.2687575 C18.8296885,19.2687575 19.072887,19.0183168 19.072887,18.7097381 L19.072887,14.2375832 C19.072887,13.9290045 18.8296885,13.6785639 18.5288188,13.6785639 C18.227949,13.6785639 17.9847505,13.9290045 17.9847505,14.2375832 L17.9847505,18.1507188 L6.01524946,18.1507188 L6.01524946,5.84626969 L9.90559449,5.84626969 C10.2040032,5.84626969 10.4559318,5.59666235 10.4559318,5.2887561 C10.4559318,4.98985477 10.2095374,4.73124251 9.90559449,4.73124251 L5.47745027,4.73124251 C5.17904159,4.73124251 4.927113,4.98084985 4.927113,5.2887561 C4.927113,5.2934451 4.92717364,5.29812418 4.92729418,5.3027925 Z"
							fill={color}
							id="base"
						>
						</path>
						<path
							d="M15.9192373,2.7884879 C15.869183,2.73705812 15.8087914,2.69624971 15.7429592,2.66773972 C15.6760388,2.63922973 15.605854,2.62469523 15.534581,2.62469523 C15.4638522,2.62469523 15.3931233,2.63922973 15.3262029,2.66773972 C15.2603706,2.69624971 15.1999791,2.73705812 15.1499248,2.7884879 L10.7973789,7.2606428 C10.5846483,7.47921937 10.5846483,7.83251961 10.7973789,8.05109618 C11.0095656,8.26967275 11.3539607,8.26967275 11.5666914,8.05109618 L14.9905128,4.53318733 L14.9905128,13.2490747 C14.9905128,13.5576534 15.2337113,13.808094 15.534581,13.808094 C15.8354508,13.808094 16.0786493,13.5576534 16.0786493,13.2490747 L16.0786493,4.53318733 L19.5024706,8.05109618 C19.6085639,8.16010496 19.7478454,8.21488885 19.8871269,8.21488885 C20.0264083,8.21488885 20.1656898,8.16010496 20.2717831,8.05109618 C20.4845138,7.83251961 20.4845138,7.47921937 20.2717831,7.2606428 L15.9192373,2.7884879 Z"
							fill={color}
							id="arrow"
							transform="translate(15.534581, 8.216395) rotate(45.000000) translate(-15.534581, -8.216395) "
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ExternalLinkIconBuilder;

