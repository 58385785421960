import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	ISSUE_CHANGE_BROKEN_ENTERED,
	ISSUE_CHANGE_BROKEN_LEFT,
	ISSUE_CHANGE_CLOSED,
	ISSUE_CHANGE_IGNORED,
	ISSUE_CHANGE_NOT_APPLICABLE_POINTS,
	ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS,
	ISSUE_CHANGE_NOT_PAGE_POINTS,
	ISSUE_CHANGE_NOT_REQUIRED_POINTS,
	ISSUE_CHANGE_OPENED,
	ISSUE_CHANGE_OPENED_NEW_PAGE,
	ISSUE_CHANGE_OPEN_LESS_IMPORTANT,
	ISSUE_CHANGE_OPEN_MORE_IMPORTANT,
	ISSUE_CHANGE_OPEN_NOT_APPLICABLE,
	ISSUE_CHANGE_OPEN_NOT_PAGE,
	ISSUE_CHANGE_POINTS_LESS_IMPORTANT,
	ISSUE_CHANGE_POINTS_MORE_IMPORTANT,
	ISSUE_CHANGE_POINTS_NOT_APPLICABLE,
	ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT,
	ISSUE_CHANGE_POINTS_NOT_PAGE,
	ISSUE_CHANGE_POINTS_NOT_REQUIRED,
	ISSUE_CHANGE_UNIGNORED,
} from '~/model/affectedPagesComparison';



const messages = defineMessages({
	[ISSUE_CHANGE_BROKEN_ENTERED]: {
		id: 'ui.affectedPagesComparison.value.issueChange.brokenEntered',
	},
	[ISSUE_CHANGE_BROKEN_LEFT]: {
		id: 'ui.affectedPagesComparison.value.issueChange.brokenLeft',
	},
	[ISSUE_CHANGE_CLOSED]: {
		id: 'ui.affectedPagesComparison.value.issueChange.closed',
	},
	[ISSUE_CHANGE_IGNORED]: {
		id: 'ui.affectedPagesComparison.value.issueChange.ignored',
	},
	[ISSUE_CHANGE_NOT_APPLICABLE_POINTS]: {
		id: 'ui.affectedPagesComparison.value.issueChange.notApplicablePoints',
	},
	[ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS]: {
		id: 'ui.affectedPagesComparison.value.issueChange.notInSegmentPoints',
	},
	[ISSUE_CHANGE_NOT_PAGE_POINTS]: {
		id: 'ui.affectedPagesComparison.value.issueChange.notPagePoints',
	},
	[ISSUE_CHANGE_NOT_REQUIRED_POINTS]: {
		id: 'ui.affectedPagesComparison.value.issueChange.notRequiredPoints',
	},
	[ISSUE_CHANGE_OPEN_LESS_IMPORTANT]: {
		id: 'ui.affectedPagesComparison.value.issueChange.openLessImportant',
	},
	[ISSUE_CHANGE_OPEN_MORE_IMPORTANT]: {
		id: 'ui.affectedPagesComparison.value.issueChange.openMoreImportant',
	},
	[ISSUE_CHANGE_OPEN_NOT_APPLICABLE]: {
		id: 'ui.affectedPagesComparison.value.issueChange.openNotApplicable',
	},
	[ISSUE_CHANGE_OPEN_NOT_PAGE]: {
		id: 'ui.affectedPagesComparison.value.issueChange.openNotPage',
	},
	[ISSUE_CHANGE_OPENED_NEW_PAGE]: {
		id: 'ui.affectedPagesComparison.value.issueChange.openedNewPage',
	},
	[ISSUE_CHANGE_OPENED]: {
		id: 'ui.affectedPagesComparison.value.issueChange.opened',
	},
	[ISSUE_CHANGE_POINTS_LESS_IMPORTANT]: {
		id: 'ui.affectedPagesComparison.value.issueChange.pointsLessImportant',
	},
	[ISSUE_CHANGE_POINTS_MORE_IMPORTANT]: {
		id: 'ui.affectedPagesComparison.value.issueChange.pointsMoreImportant',
	},
	[ISSUE_CHANGE_POINTS_NOT_APPLICABLE]: {
		id: 'ui.affectedPagesComparison.value.issueChange.pointsNotApplicable',
	},
	[ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT]: {
		id: 'ui.affectedPagesComparison.value.issueChange.pointsNotInSegment',
	},
	[ISSUE_CHANGE_POINTS_NOT_PAGE]: {
		id: 'ui.affectedPagesComparison.value.issueChange.pointsNotPage',
	},
	[ISSUE_CHANGE_POINTS_NOT_REQUIRED]: {
		id: 'ui.affectedPagesComparison.value.issueChange.pointsNotRequired',
	},
	[ISSUE_CHANGE_UNIGNORED]: {
		id: 'ui.affectedPagesComparison.value.issueChange.unignored',
	},
});



type Props = {
	issueChange: (
		| typeof ISSUE_CHANGE_BROKEN_ENTERED
		| typeof ISSUE_CHANGE_BROKEN_LEFT
		| typeof ISSUE_CHANGE_CLOSED
		| typeof ISSUE_CHANGE_IGNORED
		| typeof ISSUE_CHANGE_NOT_APPLICABLE_POINTS
		| typeof ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS
		| typeof ISSUE_CHANGE_NOT_PAGE_POINTS
		| typeof ISSUE_CHANGE_NOT_REQUIRED_POINTS
		| typeof ISSUE_CHANGE_OPEN_LESS_IMPORTANT
		| typeof ISSUE_CHANGE_OPEN_MORE_IMPORTANT
		| typeof ISSUE_CHANGE_OPEN_NOT_APPLICABLE
		| typeof ISSUE_CHANGE_OPEN_NOT_PAGE
		| typeof ISSUE_CHANGE_OPENED
		| typeof ISSUE_CHANGE_OPENED_NEW_PAGE
		| typeof ISSUE_CHANGE_POINTS_LESS_IMPORTANT
		| typeof ISSUE_CHANGE_POINTS_MORE_IMPORTANT
		| typeof ISSUE_CHANGE_POINTS_NOT_APPLICABLE
		| typeof ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT
		| typeof ISSUE_CHANGE_POINTS_NOT_PAGE
		| typeof ISSUE_CHANGE_POINTS_NOT_REQUIRED
		| typeof ISSUE_CHANGE_UNIGNORED
	),
};

const IssueChangeName: React.FC<Props> = (props) => {
	const {
		issueChange,
	} = props;

	const message = messages[issueChange];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default IssueChangeName;
