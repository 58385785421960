import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import GuidedTour from '~/components/patterns/tours/GuidedTour';
import SubmitButton from '~/components/app/SubmitButton';



const messages = defineMessages({
	bodyTextForInvitee: {
		id: 'ui.onboardingGuideModalWelcomeStep.bodyTextForInvitee',
	},
	bodyTextForOwner: {
		id: 'ui.onboardingGuideModalWelcomeStep.bodyTextForOwner',
	},
	buttonLabel: {
		id: 'ui.onboardingGuideModalWelcomeStep.buttonLabel',
	},
});



type Props = {
	isForOwner: boolean,
	onSubmit: () => void,
};

const OnboardingGuideWelcomeStep: React.FC<Props> = (props) => {
	const {
		isForOwner,
		onSubmit,
	} = props;

	return (
		<GuidedTour
			form={(
				<Form
					defaultDataHasChanged={true}
					onSuccess={onSubmit}
				>
					<ButtonsLayout layout={ButtonsLayoutType.Steps}>
						<SubmitButton nextStepIcon={true}>
							<FormattedMessage {...messages.buttonLabel} />
						</SubmitButton>
					</ButtonsLayout>
				</Form>
			)}
		>
			<Copy
				{...messages[isForOwner ? 'bodyTextForOwner' : 'bodyTextForInvitee']}
			/>
		</GuidedTour>
	);
};



export default OnboardingGuideWelcomeStep;
