/**
 * Simple detection of mobile devices
 */

export default function isMobileDevice() {
	if (window.navigator.appName === 'Microsoft Internet Explorer') {
		return document.documentMode < 8;
	}

	if (/iP(od|hone|ad)/i.test(window.navigator.userAgent)) {
		return true;
	}

	if (/Android/i.test(window.navigator.userAgent)) {
		if (/Mobile/i.test(window.navigator.userAgent)) {
			return true;
		}
	}

	return false;
}
