import React from 'react';

import type CK from '~/types/contentking';

import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import Emphasis from '~/components/patterns/typography/Emphasis';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useWebsiteCustomerTeamGoogleSearchConsoleQuery,
} from '~/components/app/WebsiteCustomerTeamGoogleSearchConsoleOverview.gql';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteCustomerTeamGoogleSearchConsoleOverview: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const { data } = useWebsiteCustomerTeamGoogleSearchConsoleQuery({
		variables: {
			websiteId,
		},
	});

	const usedProperties = data?.website?.googleSearchConsoleUsedProperties ?? null;

	return (
		<EditableFormWrapper
			isForAdmins={true}
			isReadOnly={true}
			title="Google Search Console properties"
		>
			<FormRows>
				<FormRow label="Used properties">
					{usedProperties === null ? (
						<StaticText>
							<Emphasis>No data</Emphasis>
						</StaticText>
					) : usedProperties.length === 0 ? (
						<StaticText>
							<Emphasis>None</Emphasis>
						</StaticText>
					) : (
						<StaticList>
							{usedProperties}
						</StaticList>
					)}
				</FormRow>
			</FormRows>
		</EditableFormWrapper>
	);
};



export default WebsiteCustomerTeamGoogleSearchConsoleOverview;
