import Immutable from 'immutable';

import {
	LOGIN_SUCCESSFUL,
	LOGOUT_SUCCESSFUL,
	STORE_LOADED_TEAMS,
	STORE_UPDATED_TEAM,
} from '~/actions';
import {
	STORE_UPDATED_WEBSITE,
} from '~/actions/websites';



function updateStateWithWebsitesData({ state, websites }) {
	websites.forEach((website) => {
		state = updateStateWithWebsiteData({
			state,
			website,
		});
	});

	return state;
}



function updateStateWithWebsiteData({ state, website }) {
	const crawlingSpeed = Immutable.fromJS(website.crawling_speed);
	const isCrawlingSpeedSame = Immutable.is(state.get(website.id), crawlingSpeed);

	if (!isCrawlingSpeedSame) {
		return state.set(
			website.id,
			crawlingSpeed,
		);
	}

	return state;
}



function createDefaultState() {
	return Immutable.Map();
}



export function crawlingSpeed(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case LOGIN_SUCCESSFUL: {
			const {
				response,
			} = action;

			if (response.team) {
				response.team.websites.forEach((website) => {
					state = state.set(
						website.id,
						Immutable.fromJS(website.crawling_speed),
					);
				});
			}

			break;
		}

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case STORE_LOADED_TEAMS: {
			const {
				teams,
			} = action;

			for (const teamId in teams) {
				if (teams.hasOwnProperty(teamId)) {
					state = updateStateWithWebsitesData({
						state,
						websites: teams[teamId].websites,
					});
				}
			}

			break;
		}

		case STORE_UPDATED_TEAM: {
			const {
				team,
			} = action;

			state = updateStateWithWebsitesData({
				state,
				websites: team.websites,
			});

			break;
		}

		case STORE_UPDATED_WEBSITE: {
			const {
				website,
			} = action;

			state = updateStateWithWebsiteData({
				state,
				website,
			});

			break;
		}

	}

	return state;
}
