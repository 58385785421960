import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ThumbsDownIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-thumbs-down">
					<g id="thumbs-down">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<polygon
							id="Stroke-199"
							points="7.8978169 15.0015 4.30985915 15.0015 4.30985915 4.5015 7.8978169 4.5015"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
							transform="translate(6.103838, 9.751500) scale(1, -1) translate(-6.103838, -9.751500) "
						>
						</polygon>
						<path
							d="M7.90399437,18.7512 L9.02455775,18.7512 C9.67534648,18.7512 10.2995577,19.0212 10.7592761,19.50195 L10.7592761,19.50195 C11.2189944,19.98195 11.8432056,20.25195 12.4939944,20.25195 L16.7923606,20.25195 C17.8633606,20.25195 18.7713042,19.43145 18.9250225,18.3252 L19.7589803,12.3252 C19.9478958,10.96845 18.9393887,9.75195 17.6263183,9.75195 L13.6339521,9.75195 L14.4664732,6.7107 C14.7696,5.6037 13.9737127,4.50195 12.871107,4.50195 L12.871107,4.50195 C12.0938958,4.50195 11.4251493,5.06295 11.2527549,5.8542 C10.9797972,7.10745 10.4152056,8.9007 9.32265634,9.75195 L9.32481127,9.75195 L7.90399437,9.75195"
							id="Stroke-200"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
							transform="translate(13.842894, 12.376950) scale(1, -1) translate(-13.842894, -12.376950) "
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ThumbsDownIconBuilder;

