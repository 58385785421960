import React from 'react';

import AccountIcon from './builders/iconsData/navigationIcon/Account';
import AlertsIcon from './builders/iconsData/navigationIcon/Alerts';
import ConductorIcon from './builders/iconsData/navigationIcon/Conductor';
import DashboardIcon from './builders/iconsData/navigationIcon/Dashboard';
import ExclusionListIcon from './builders/iconsData/navigationIcon/ExclusionList';
import IssuesIcon from './builders/iconsData/navigationIcon/Issues';
import LockIcon from './builders/iconsData/navigationIcon/Lock';
import LogoutIcon from './builders/iconsData/navigationIcon/Logout';
import MagicWandIcon from './builders/iconsData/navigationIcon/MagicWand';
import PagesIcon from './builders/iconsData/navigationIcon/Pages';
import PlatformIcon from './builders/iconsData/navigationIcon/Platform';
import ProfileIcon from './builders/iconsData/navigationIcon/Profile';
import SettingsIcon from './builders/iconsData/navigationIcon/Settings';
import SupportIcon from './builders/iconsData/navigationIcon/Support';
import SwitchAccountIcon from './builders/iconsData/navigationIcon/SwitchAccount';
import TeamIcon from './builders/iconsData/navigationIcon/Team';
import ToggleAdminIcon from './builders/iconsData/navigationIcon/ToggleAdmin';
import TrashIcon from './builders/iconsData/navigationIcon/Trash';
import WebsitesIcon from './builders/iconsData/navigationIcon/Websites';

import InlineIcon from './builders/InlineIcon.part';

import {
	type Merge,
} from '~/types/utilities';



export enum NavigationIconType {
	Account = 'account',
	Alerts = 'alerts',
	Conductor = 'conductor',
	Dashboard = 'dashboard',
	ExclusionList = 'exclusion-list',
	Issues = 'issues',
	Lock = 'lock',
	Logout = 'logout',
	MagicWand = 'magic-wand',
	Pages = 'pages',
	Platform = 'platform',
	Profile = 'profile',
	Settings = 'settings',
	Support = 'support',
	SwitchAccount = 'switch-account',
	Team = 'team',
	ToggleAdmin = 'toggle-admin',
	Trash = 'trash',
	Websites = 'websites',
}

const componentMapping = {
	[NavigationIconType.Account]: AccountIcon,
	[NavigationIconType.Alerts]: AlertsIcon,
	[NavigationIconType.Conductor]: ConductorIcon,
	[NavigationIconType.Dashboard]: DashboardIcon,
	[NavigationIconType.ExclusionList]: ExclusionListIcon,
	[NavigationIconType.Issues]: IssuesIcon,
	[NavigationIconType.Lock]: LockIcon,
	[NavigationIconType.Logout]: LogoutIcon,
	[NavigationIconType.MagicWand]: MagicWandIcon,
	[NavigationIconType.Pages]: PagesIcon,
	[NavigationIconType.Platform]: PlatformIcon,
	[NavigationIconType.Profile]: ProfileIcon,
	[NavigationIconType.Settings]: SettingsIcon,
	[NavigationIconType.Support]: SupportIcon,
	[NavigationIconType.SwitchAccount]: SwitchAccountIcon,
	[NavigationIconType.Team]: TeamIcon,
	[NavigationIconType.ToggleAdmin]: ToggleAdminIcon,
	[NavigationIconType.Trash]: TrashIcon,
	[NavigationIconType.Websites]: WebsitesIcon,
} as const;



type BaseProps<IconType extends NavigationIconType = NavigationIconType> = {
	/** Custom icon color */
	color?: string,
	/** Square size of icon */
	size?: number,
	/** Type of icon */
	type: IconType,
};

type IconProps<IconType extends NavigationIconType = NavigationIconType> = (
	Parameters<typeof componentMapping[IconType]['icon']>[0]
);

type Props<IconType extends NavigationIconType = NavigationIconType> = (
	Merge<IconProps<IconType>, BaseProps<IconType>>
);

const NavigationIcon: React.FC<Props> = (props) => {
	const {
		color,
		size = 26,
		type,
		...rest
	} = props;

	const icon = componentMapping[type].icon({
		color,
		size,
		...rest,
	});

	return (
		<InlineIcon
			icon={icon}
			size={size}
			viewBoxHeight={componentMapping[type].viewBoxHeight}
			viewBoxWidth={componentMapping[type].viewBoxWidth}
		/>
	);
};



export default NavigationIcon;
