import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CountSlider, {
	CountSliderBubbleContentAlignment,
} from '~/components/patterns/forms/fields/CountSlider';
import CountSliderContext from '../../../atoms/forms/components/countSliders/abstractBuilders/CountSliderContext';
import CountSliderFieldLayout from '~/components/patterns/forms/fieldParts/countSliders/CountSliderFieldLayout';
import CountSliderLabels from '~/components/patterns/forms/fieldParts/countSliders/CountSliderLabels';
import FormDescription from '../../../atoms/forms/components/rowParts/FormDescription';
import {
	FormContext,
} from '../../../atoms/forms/basis/Form';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import SegmentPercentageScenario from './SegmentPercentageScenario';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	bubble: {
		id: 'ui.alertsConfiguration.fields.sensitivity.percentage.bubble',
	},
	label: {
		id: 'ui.alertsConfiguration.fields.sensitivity.label',
	},
	noValue: {
		id: 'ui.alertsConfiguration.fields.sensitivity.noValue',
	},
	value: {
		id: 'ui.alertsConfiguration.fields.sensitivity.percentage.value',
	},
});



const PERCENTAGES = [
	0,
	1,
	5,
	10,
	25,
	50,
	75,
];



type Props = {
	name: string,
	websiteId: CK.WebsiteId,
};

const SegmentPercentageField: React.FC<Props> = (props) => {
	const {
		name,
		websiteId,
	} = props;

	const formContext = React.useContext(FormContext);

	const {
		defaultValues,
		focused,
		values,
	} = formContext;

	const renderField = React.useCallback(
		({ currentStep, currentValue, minStep, onSliderStepChangeHandler, sliderMax }) => {
			const countSliderOptions: Record<string, any> = {};
			const countSliderLabels: Record<string, any> = {};

			if (minStep) {
				countSliderOptions.highlightedValue = minStep;
			}

			if (currentValue !== null) {
				countSliderLabels.leftLabel = {
					label: (
						<FormattedMessage {...messages.label} />
					),
					uppercaseLabel: true,
					value: (
						<FormattedMessage
							{...messages.value}
							values={{
								percentage: currentValue / 100,
							}}
						/>
					),
				};

				if (focused === name) {
					countSliderOptions.handlerBubble = (
						<FormattedMessage
							{...messages.bubble}
							values={{
								percentage: currentValue / 100,
							}}
						/>
					);
				}
			} else {
				countSliderLabels.leftLabel = {
					label: (
						<FormattedMessage {...messages.noValue} />
					),
					uppercaseLabel: false,
				};
			}

			if (currentStep === null) {
				currentStep = 0;
			}

			const currentPercentage = getArrayItemAtSafeIndex(PERCENTAGES, currentStep);

			return (
				<List size={ListSize.Small}>
					<CountSliderFieldLayout
						slider={(
							<CountSlider
								handlerBubbleContentAlignment={CountSliderBubbleContentAlignment.Center}
								max={sliderMax}
								onChangeCallback={onSliderStepChangeHandler}
								showStepMarks={true}
								value={currentStep}
								{...countSliderOptions}
							/>
						)}
						sliderLabels={(
							<CountSliderLabels
								{...countSliderLabels}
							/>
						)}
					/>
					<FormDescription>
						<div style={{ minHeight: '70px' }}>
							{values.segmentChangeType && (
								<SegmentPercentageScenario
									percentage={currentPercentage}
									scope={`segment/${values.segmentName}`}
									segmentChangeType={values.segmentChangeType}
									websiteId={websiteId}
								/>
							)}
						</div>
					</FormDescription>
				</List>
			);
		},
		[
			focused,
			name,
			values.segmentChangeType,
			values.segmentName,
			websiteId,
		],
	);

	const value = values[name] ?? defaultValues[name] ?? 0;

	return (
		<CountSliderContext
			maxLimit={Math.max(...PERCENTAGES)}
			maxValue={Math.max(...PERCENTAGES)}
			minLimit={getArrayItemAtSafeIndex(PERCENTAGES, 0)}
			minValue={Math.min(...PERCENTAGES)}
			name={name}
			numberOfSteps={PERCENTAGES.length}
			stepGetter={(value) => PERCENTAGES.indexOf(value)}
			value={value}
			valueGetter={(step) => getArrayItemAtSafeIndex(PERCENTAGES, step)}
		>
			{renderField}
		</CountSliderContext>
	);
};



export default SegmentPercentageField;
