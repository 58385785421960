/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdministerAccountCertifiedPartnerProgramSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  partnerAffiliateId: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
}>;


export type AdministerAccountCertifiedPartnerProgramSettingsMutation = { readonly __typename?: 'Mutation', readonly AdministerAccountCertifiedPartnerProgramSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly partnerAffiliateId: string | null } | null } | null } } };

export type CertifiedPartnerProgramFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type CertifiedPartnerProgramFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly partnerAffiliateId: string | null } | null } | null };


export const AdministerAccountCertifiedPartnerProgramSettingsDocument = gql`
    mutation AdministerAccountCertifiedPartnerProgramSettings($accountId: AccountId!, $partnerAffiliateId: String) {
  AdministerAccountCertifiedPartnerProgramSettings(
    accountId: $accountId
    partnerAffiliateId: $partnerAffiliateId
  ) {
    query {
      account(id: $accountId) {
        adminSettings {
          partnerAffiliateId
        }
        id
      }
    }
  }
}
    `;
export type AdministerAccountCertifiedPartnerProgramSettingsMutationFn = Apollo.MutationFunction<AdministerAccountCertifiedPartnerProgramSettingsMutation, AdministerAccountCertifiedPartnerProgramSettingsMutationVariables>;

/**
 * __useAdministerAccountCertifiedPartnerProgramSettingsMutation__
 *
 * To run a mutation, you first call `useAdministerAccountCertifiedPartnerProgramSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerAccountCertifiedPartnerProgramSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerAccountCertifiedPartnerProgramSettingsMutation, { data, loading, error }] = useAdministerAccountCertifiedPartnerProgramSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      partnerAffiliateId: // value for 'partnerAffiliateId'
 *   },
 * });
 */
export function useAdministerAccountCertifiedPartnerProgramSettingsMutation(baseOptions?: Apollo.MutationHookOptions<AdministerAccountCertifiedPartnerProgramSettingsMutation, AdministerAccountCertifiedPartnerProgramSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerAccountCertifiedPartnerProgramSettingsMutation, AdministerAccountCertifiedPartnerProgramSettingsMutationVariables>(AdministerAccountCertifiedPartnerProgramSettingsDocument, options);
      }
export type AdministerAccountCertifiedPartnerProgramSettingsMutationHookResult = ReturnType<typeof useAdministerAccountCertifiedPartnerProgramSettingsMutation>;
export type AdministerAccountCertifiedPartnerProgramSettingsMutationResult = Apollo.MutationResult<AdministerAccountCertifiedPartnerProgramSettingsMutation>;
export type AdministerAccountCertifiedPartnerProgramSettingsMutationOptions = Apollo.BaseMutationOptions<AdministerAccountCertifiedPartnerProgramSettingsMutation, AdministerAccountCertifiedPartnerProgramSettingsMutationVariables>;
export const CertifiedPartnerProgramFormDocument = gql`
    query CertifiedPartnerProgramForm($accountId: AccountId!) {
  account(id: $accountId) {
    adminSettings {
      partnerAffiliateId
    }
    id
  }
}
    `;

/**
 * __useCertifiedPartnerProgramFormQuery__
 *
 * To run a query within a React component, call `useCertifiedPartnerProgramFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertifiedPartnerProgramFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertifiedPartnerProgramFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCertifiedPartnerProgramFormQuery(baseOptions: Apollo.QueryHookOptions<CertifiedPartnerProgramFormQuery, CertifiedPartnerProgramFormQueryVariables> & ({ variables: CertifiedPartnerProgramFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CertifiedPartnerProgramFormQuery, CertifiedPartnerProgramFormQueryVariables>(CertifiedPartnerProgramFormDocument, options);
      }
export function useCertifiedPartnerProgramFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertifiedPartnerProgramFormQuery, CertifiedPartnerProgramFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CertifiedPartnerProgramFormQuery, CertifiedPartnerProgramFormQueryVariables>(CertifiedPartnerProgramFormDocument, options);
        }
export function useCertifiedPartnerProgramFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CertifiedPartnerProgramFormQuery, CertifiedPartnerProgramFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CertifiedPartnerProgramFormQuery, CertifiedPartnerProgramFormQueryVariables>(CertifiedPartnerProgramFormDocument, options);
        }
export type CertifiedPartnerProgramFormQueryHookResult = ReturnType<typeof useCertifiedPartnerProgramFormQuery>;
export type CertifiedPartnerProgramFormLazyQueryHookResult = ReturnType<typeof useCertifiedPartnerProgramFormLazyQuery>;
export type CertifiedPartnerProgramFormSuspenseQueryHookResult = ReturnType<typeof useCertifiedPartnerProgramFormSuspenseQuery>;
export type CertifiedPartnerProgramFormQueryResult = Apollo.QueryResult<CertifiedPartnerProgramFormQuery, CertifiedPartnerProgramFormQueryVariables>;