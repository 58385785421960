/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RobotsTxtViewerSidebarQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type RobotsTxtViewerSidebarQuery = { readonly __typename?: 'Query', readonly latest: { readonly __typename?: 'RobotsTxtRevision', readonly id: CK.ID } | null, readonly list: ReadonlyArray<{ readonly __typename?: 'RobotsTxtRevision', readonly id: CK.ID, readonly createdAt: CK.Timestamp, readonly isContentEmpty: boolean | null, readonly responseFailureReason: GraphQL.FetchingFailureReason | null, readonly responseStatusCode: number | null, readonly url: string, readonly searchEngineActivity: ReadonlyArray<{ readonly __typename?: 'SearchEngineActivityAtMoment', readonly lastVisit: CK.Timestamp | null, readonly searchEngine: GraphQL.SearchEngineActivitySearchEngine, readonly status: GraphQL.SearchEngineActivityAtMomentStatus }> | null }> | null };


export const RobotsTxtViewerSidebarDocument = gql`
    query RobotsTxtViewerSidebar($websiteId: WebsiteId!) {
  latest: robotsTxtRevisionLatest(websiteId: $websiteId) {
    id
  }
  list: robotsTxtRevisionsList(websiteId: $websiteId) {
    id
    createdAt
    isContentEmpty
    responseFailureReason
    responseStatusCode
    searchEngineActivity {
      lastVisit
      searchEngine
      status
    }
    url
  }
}
    `;

/**
 * __useRobotsTxtViewerSidebarQuery__
 *
 * To run a query within a React component, call `useRobotsTxtViewerSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useRobotsTxtViewerSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRobotsTxtViewerSidebarQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useRobotsTxtViewerSidebarQuery(baseOptions: Apollo.QueryHookOptions<RobotsTxtViewerSidebarQuery, RobotsTxtViewerSidebarQueryVariables> & ({ variables: RobotsTxtViewerSidebarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RobotsTxtViewerSidebarQuery, RobotsTxtViewerSidebarQueryVariables>(RobotsTxtViewerSidebarDocument, options);
      }
export function useRobotsTxtViewerSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RobotsTxtViewerSidebarQuery, RobotsTxtViewerSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RobotsTxtViewerSidebarQuery, RobotsTxtViewerSidebarQueryVariables>(RobotsTxtViewerSidebarDocument, options);
        }
export function useRobotsTxtViewerSidebarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RobotsTxtViewerSidebarQuery, RobotsTxtViewerSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RobotsTxtViewerSidebarQuery, RobotsTxtViewerSidebarQueryVariables>(RobotsTxtViewerSidebarDocument, options);
        }
export type RobotsTxtViewerSidebarQueryHookResult = ReturnType<typeof useRobotsTxtViewerSidebarQuery>;
export type RobotsTxtViewerSidebarLazyQueryHookResult = ReturnType<typeof useRobotsTxtViewerSidebarLazyQuery>;
export type RobotsTxtViewerSidebarSuspenseQueryHookResult = ReturnType<typeof useRobotsTxtViewerSidebarSuspenseQuery>;
export type RobotsTxtViewerSidebarQueryResult = Apollo.QueryResult<RobotsTxtViewerSidebarQuery, RobotsTxtViewerSidebarQueryVariables>;