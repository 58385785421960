import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import useAllWebsites from '~/hooks/useAllWebsites';
import useHasWebsitesScreen from '~/hooks/useHasWebsitesScreen';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	markAppEmptyState,
} from '~/actions';

import {
	EMPTY_STATE_NO_WEBSITES,
} from '~/model/ui/appEmpty';

import {
	navigate,
} from '~/routing/router';

import {
	lastSelectedWebsiteSelector,
} from '~/state/lastSelectedWebsite/selectors';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const RedirectToHomepage: React.FC = () => {
	const allWebsites = useAllWebsites();
	const dispatch = useDispatch();
	const hasWebsitesScreen = useHasWebsitesScreen();
	const lastSelectedWebsiteId = useSelector(lastSelectedWebsiteSelector);
	const urlState = useUrlState();
	const websiteId = useWebsiteId() as ReturnType<typeof useWebsiteId> | null;

	React.useEffect(
		() => {
			if (
				urlState.params.websiteId
				|| allWebsites.isLoaded === false
				|| (websiteId !== null && urlState.name !== 'home')
			) {
				return;
			}

			if (hasWebsitesScreen) {
				const params: Record<string, any> = {};

				if (urlState.params.extend) {
					params.extend = true;
				}

				navigate(
					'multiDashboard',
					params,
				);

				return;
			}

			if (
				urlState.name === 'home'
				&& !urlState.params.websiteId
				&& websiteId !== null
			) {
				const params: Record<string, any> = {
					websiteId,
				};

				if (urlState.params.extend) {
					params.extend = true;
				}

				dispatch(markAppEmptyState(false));

				navigate(
					'website.dashboard',
					params,
				);

				return;
			}

			if (
				lastSelectedWebsiteId
				&& allWebsites.listAll().find((website) => website.id === lastSelectedWebsiteId) !== undefined
			) {
				const params: Record<string, any> = {
					websiteId: lastSelectedWebsiteId,
				};

				if (urlState.params.extend) {
					params.extend = true;
				}

				dispatch(markAppEmptyState(false));

				navigate(
					'website.dashboard',
					params,
				);

				return;
			}

			if (allWebsites.listAll().length > 0) {
				const params: Record<string, any> = {
					websiteId: getArrayItemAtSafeIndex(allWebsites.listAll(), 0).id,
				};

				if (urlState.params.extend) {
					params.extend = true;
				}

				dispatch(markAppEmptyState(false));

				navigate(
					'website.dashboard',
					params,
				);

				return;
			}

			if (allWebsites.listAll().length === 0) {
				const params: Record<string, any> = {};

				if (urlState.params.reactivate) {
					params.reactivate = true;
				}

				dispatch(markAppEmptyState(EMPTY_STATE_NO_WEBSITES));

				navigate(
					'empty_state',
					params,
				);
			}
		},
		[
			allWebsites,
			dispatch,
			hasWebsitesScreen,
			lastSelectedWebsiteId,
			urlState,
			websiteId,
		],
	);

	return <>{null}</>;
};



export default RedirectToHomepage;
