import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import IndexNowIssuesSelectField from '~/components/app/IndexNowIssuesSelectField';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useUpdateIndexNowIssueConditionsMutation,
} from './IndexNowIssuesRequiredToPassModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useIndexNowConditions from '~/hooks/useIndexNowConditions';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	applyChanges: {
		id: 'ui.general.applyChanges',
	},
	description: {
		id: 'ui.indexNowIssuesRequiredToPassModal.description',
	},
	sidebar: {
		id: 'ui.indexNowIssuesRequiredToPassModal.sidebar',
	},
	title: {
		id: 'ui.indexNowIssuesRequiredToPassModal.title',
	},
});



const IndexNowIssuesRequiredToPassModal: React.FC = () => {
	const websiteId = useWebsiteId();
	const classicFormBehavior = useClassicFormBehavior();
	const {
		issuesRequiredToPass,
		loading,
	} = useIndexNowConditions(websiteId);

	const [updateIndexNowIssueConditions] = useUpdateIndexNowIssueConditionsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateIndexNowIssueConditions({
				variables: {
					issues: values.issues,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			updateIndexNowIssueConditions,
			websiteId,
		],
	);

	return (
		<SimpleModal
			iconType={BasicIconType.Plus}
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							linkSupport: linkExternal('https://www.contentkingapp.com/support/realtime-indexnow'),
						}}
					/>
				</RichText>
			)}
			size={SimpleModalSize.XLarge}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Form
				defaultValues={{
					issues: issuesRequiredToPass ?? [],
				}}
				key={loading ? 'loading' : 'ready'}
				onSuccess={handleSubmit}
			>
				<ModalTextSection>
					<Copy {...messages.description} />
				</ModalTextSection>

				<IndexNowIssuesSelectField name="issues" />

				<FormErrorMessages />

				<CenteredFormWrapper>
					<ButtonsLayout>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.applyChanges} />
						</SubmitButton>
					</ButtonsLayout>
				</CenteredFormWrapper>
			</Form>
		</SimpleModal>
	);
};



export default IndexNowIssuesRequiredToPassModal;
