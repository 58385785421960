import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const BookIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M20.166,4.254a1,1,0,0,0-.781-.247L6.452,5.5a1,1,0,0,1-.952-1c0-.312,0-.784,1.193-1.018C8.349,3.155,17.532,2,17.625,1.992a1,1,0,0,0-.25-1.984C17,.056,8.03,1.18,6.307,1.519,3.865,2,3.5,3.593,3.5,4.5V20a3.887,3.887,0,0,0,4,4c.1,0,12.124-1.508,12.124-1.508A1,1,0,0,0,20.5,21.5V5A1,1,0,0,0,20.166,4.254Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default BookIconBuilder;
