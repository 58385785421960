import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import HistoricalChange, {
	HistoricalChangeDifference,
} from './HistoricalChange';

import {
	type HistoricalMoment_PageHistoricalMomentTrackedChanges_Fragment,
} from './HistoricalData.gql';

import useLogFileAnalysisFeatureNudge from '~/hooks/useLogFileAnalysisFeatureNudge';

import {
	TYPE_MISSING,
	TYPE_OVERQUOTA,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	TYPE_UNREACHABLE,
} from '~/model/pages';

import {
	notEmpty,
} from '~/utilities/typeCheck';



const TYPES_WITH_STATUS_CODES = [
	TYPE_MISSING,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
];

const differenceMapping = {
	[GraphQL.PageElementDifference.Added]: HistoricalChangeDifference.Added,
	[GraphQL.PageElementDifference.Changed]: HistoricalChangeDifference.Changed,
	[GraphQL.PageElementDifference.Extracted]: HistoricalChangeDifference.Extracted,
	[GraphQL.PageElementDifference.None]: HistoricalChangeDifference.None,
	[GraphQL.PageElementDifference.Removed]: HistoricalChangeDifference.Removed,
};



const messages = defineMessages({
	[TYPE_OVERQUOTA]: {
		id: 'ui.pageDetail.changeTracking.status.overQuota.start',
	},
	[TYPE_PAGE]: {
		id: 'ui.pageDetail.changeTracking.status.page',
	},
	[TYPE_REDIRECT]: {
		id: 'ui.pageDetail.changeTracking.status.redirect',
	},
	[TYPE_SERVER_ERROR]: {
		id: 'ui.pageDetail.changeTracking.status.serverError',
	},
	[TYPE_UNREACHABLE]: {
		id: 'ui.pageDetail.changeTracking.status.unreachable',
	},
	other: {
		id: 'ui.pageDetail.changeTracking.status.nonPage',
	},
	special: {
		id: 'ui.pageDetail.changeTracking.status.nonPage',
	},
	typeChangeDescriptionMissingInaccessible: {
		id: 'ui.pageDetail.changeTracking.status.inaccessible',
	},
	typeChangeDescriptionMissingMissing: {
		id: 'ui.pageDetail.changeTracking.status.missing',
	},
});



type Props = {
	historicalMoment: HistoricalMoment_PageHistoricalMomentTrackedChanges_Fragment,
	isFirstEntry?: boolean,
	textHighlight?: string | null,
	websiteId: CK.WebsiteId,
};

const TrackedChangesTimelineEntryContent: React.FC<Props> = (props) => {
	const {
		historicalMoment,
		isFirstEntry,
		textHighlight,
		websiteId,
	} = props;

	const {
		nudgeLogFileAnalysis,
		upsellLogFileAnalysis,
	} = useLogFileAnalysisFeatureNudge(websiteId);

	return (
		<>
			{historicalMoment.trackedChanges.map((change, index) => {
				if (change.type === 'status_code') {
					return;
				}

				if (change.type === 'type') {
					if (
						(change.oldContent !== TYPE_MISSING && !messages[change.oldContent])
						|| (change.newContent !== TYPE_MISSING && !messages[change.newContent])
					) {
						return null;
					}

					let newValue;
					if (!TYPES_WITH_STATUS_CODES.includes(change.newContent)) {
						newValue = (
							<FormattedMessage {...messages[change.newContent]} />
						);
					} else {
						const statusCode = historicalMoment.trackedChanges.find((change) => {
							return change.type === 'status_code';
						});

						if (change.newContent === TYPE_MISSING) {
							if (statusCode?.newContent == 404 || statusCode?.newContent == 410) {
								newValue = (
									<FormattedMessage
										{...messages.typeChangeDescriptionMissingMissing}
										values={{
											statusCode: statusCode.newContent,
										}}
									/>
								);
							} else {
								newValue = (
									<FormattedMessage
										{...messages.typeChangeDescriptionMissingInaccessible}
										values={{
											statusCode: statusCode?.newContent ?? 'unavailable',
										}}
									/>
								);
							}
						} else {
							newValue = (
								<FormattedMessage
									{...messages[change.newContent]}
									values={{
										statusCode: statusCode?.newContent ?? 'unavailable',
									}}
								/>
							);
						}
					}

					return (
						<HistoricalChange
							difference={(
								change.newContent === TYPE_PAGE
									? HistoricalChangeDifference.Ok
									: HistoricalChangeDifference.Error
							)}
							key={`${change.type}/${change.position}`}
							newValue={newValue}
							nudgeOrUpsellLogFileAnalysis={false}
							position={change.position}
							searchEngineActivity={null}
							type={change.type}
						/>
					);
				} else if (change.type === 'last_unreliable_response') {
					return (
						<HistoricalChange
							difference={(
								change.difference === 'added'
									? HistoricalChangeDifference.UnreliableResponse
									: HistoricalChangeDifference.Ok
							)}
							key={`${change.type}/${change.position}`}
							newValue={change.newContent ?? change.oldContent}
							nudgeOrUpsellLogFileAnalysis={false}
							position={change.position}
							searchEngineActivity={null}
							type={change.type}
						/>
					);
				}

				const previous = historicalMoment.trackedChanges[index - 1] ?? {
					type: undefined,
					position: undefined,
				};

				const showTitle = (
					index === 0
						|| previous.type !== change.type
						|| previous.position !== change.position
				);

				const searchEngineActivity = change.searchEngineActivity ?? null;
				const firstRenderedChange = index === 0 && isFirstEntry === true;

				return (
					<HistoricalChange
						difference={differenceMapping[change.difference]}
						key={`${change.type}/${change.position}/${index}`}
						newValue={change.newContent}
						nudgeOrUpsellLogFileAnalysis={firstRenderedChange && (nudgeLogFileAnalysis || upsellLogFileAnalysis)}
						oldValue={change.oldContent}
						position={change.position}
						searchEngineActivity={searchEngineActivity}
						showTitle={showTitle}
						source={change.source ?? null}
						textHighlight={textHighlight}
						type={change.type}
					/>
				);
			}).filter(notEmpty)}
		</>
	);
};



export default TrackedChangesTimelineEntryContent;
