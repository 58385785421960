import React from 'react';
import type Immutable from 'immutable';
import {
	Link,
} from 'react-router5';

import type CK from '~/types/contentking';

import useNavigation from '~/hooks/useNavigation';

import {
	createFilterParameter as createHistoricalChangesFilterParameter,
} from '~/actions/historicalChanges';
import {
	createFilterParameter as createPagesFilterParameter,
} from '~/actions/pages/overview';

import {
	EMPTY_STATE_NO_WEBSITES,
} from '~/model/ui/appEmpty';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type ChildProps = {
	isActive: boolean,
	isDimmed: boolean,
};

type Props = {
	appEmptyState?: typeof EMPTY_STATE_NO_WEBSITES,
	children: RenderProp<ChildProps>,
	className?: string,
	columns: any,
	columnsParameter?: string,
	historicalChangesFilter: {},
	historicalChangesInterval: Immutable.Map<any, any>,
	historicalChangesSortBy: {},
	inHistoricalChangesMode?: boolean,
	isActive: boolean,
	onClick?: () => void,
	pagesFilter: {},
	pagesSortBy: {},
	viewParameter: CK.ColumnSetId | null,
	websiteId: CK.WebsiteId | null,
};

const PagesOverviewLink: React.FC<Props> = (props) => {
	const {
		appEmptyState,
		children,
		className,
		columns,
		columnsParameter,
		historicalChangesFilter,
		historicalChangesInterval,
		historicalChangesSortBy,
		inHistoricalChangesMode,
		isActive,
		onClick,
		pagesFilter,
		pagesSortBy,
		viewParameter,
		websiteId,
	} = props;

	const navigation = useNavigation();

	const navigate = navigation.navigate;

	const handleClick = React.useCallback(
		(event) => {
			const routeParams: Record<string, any> = {};

			routeParams.websiteId = websiteId;

			if (columnsParameter) {
				routeParams.columns = columnsParameter;
			}

			if (viewParameter) {
				routeParams.view = viewParameter;
			}

			if (inHistoricalChangesMode) {
				routeParams.filter = createHistoricalChangesFilterParameter(
					historicalChangesFilter,
					historicalChangesSortBy,
					columns,
					[],
				) || undefined;

				routeParams.start_date = historicalChangesInterval.get('startDate');
				routeParams.end_date = historicalChangesInterval.get('endDate');
			} else {
				routeParams.filter = createPagesFilterParameter(
					pagesFilter,
					pagesSortBy,
					columns,
				) || undefined;
			}

			navigate({
				event,
				routeName: 'website.pages',
				routeParams,
			});

			if (onClick) {
				onClick();
			}

			event.preventDefault();
		},
		[
			columns,
			columnsParameter,
			historicalChangesFilter,
			historicalChangesInterval,
			historicalChangesSortBy,
			inHistoricalChangesMode,
			navigate,
			onClick,
			pagesFilter,
			pagesSortBy,
			viewParameter,
			websiteId,
		],
	);

	if (websiteId === null || appEmptyState === EMPTY_STATE_NO_WEBSITES) {
		return (
			<Link routeName="home">
				{renderProp(
					children,
					{
						isActive: false,
						isDimmed: true,
					},
				)}
			</Link>
		);
	}

	return (
		<Link
			className={className}
			onClick={handleClick}
			routeName="website.pages"
			routeParams={{
				websiteId,
			}}
		>
			{renderProp(
				children,
				{
					isActive,
					isDimmed: false,
				},
			)}
		</Link>
	);
};



export default PagesOverviewLink;
