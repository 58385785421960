import type CK from '~/types/contentking';

import {
	useWebsiteIsVerifiedQuery,
} from './useWebsiteIsVerified.gql';

import useBatchContext from '~/hooks/useBatchContext';



function useWebsiteIsVerified(websiteId: CK.WebsiteId | null): boolean | null {
	const { data } = useWebsiteIsVerifiedQuery({
		context: useBatchContext('websites', 50),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return data?.website?.isVerified ?? null;
}



export default useWebsiteIsVerified;
