import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



let googleAnalyticsAttrsGroupIconCounter = 0;



const GoogleAnalyticsIconBuilder: IconBuilder = {
	icon: () => {
		const componentId = googleAnalyticsAttrsGroupIconCounter++;

		return (
			<g>
				<path
					d="M23.918 4.79999H20.13C19.2137 4.79999 18.4616 5.55707 18.4616 6.47934V11.4073H12.9778C12.0889 11.4073 11.3642 12.1368 11.3642 13.0453V18.5652H6.4274C5.52484 18.5652 4.80005 19.2947 4.80005 20.2032V24.0575C4.80005 24.966 5.52484 25.6955 6.4274 25.7231H23.9317C24.8479 25.7231 25.6 24.966 25.6 24.0437V6.47934C25.5864 5.55707 24.8342 4.79999 23.918 4.79999V4.79999Z"
					fill={'url(#ga-attrs-group-a-' + componentId + ')'}
				/>
				<path
					d="M23.918 4.79999H20.13C19.2137 4.79999 18.4616 5.55707 18.4616 6.47934V11.4073H12.9778C12.0889 11.4073 11.3642 12.1368 11.3642 13.0453V18.5652H6.4274C5.52484 18.5652 4.80005 19.2947 4.80005 20.2032V24.0575C4.80005 24.966 5.52484 25.6955 6.4274 25.7231H23.9317C24.8479 25.7231 25.6 24.966 25.6 24.0437V6.47934C25.5864 5.55707 24.8342 4.79999 23.918 4.79999V4.79999Z"
					fill={'url(#ga-attrs-group-b-' + componentId + ')'}
				/>
				<path
					d="M23.918 4.79999H20.1573C19.241 4.79999 18.4889 5.55707 18.4889 6.47934V25.7231H23.918C24.8342 25.7231 25.5863 24.966 25.5863 24.0437V6.47934C25.5863 5.55707 24.8342 4.79999 23.918 4.79999V4.79999Z"
					fill="#F57C00"
				/>
				<path
					d="M11.3642 13.059V18.5651H6.44107C5.53851 18.5651 4.80005 19.3084 4.80005 20.2169V24.0712C4.80005 24.9797 5.53851 25.723 6.44107 25.723H18.4753V11.4072H13.0052C12.1026 11.4072 11.3642 12.1505 11.3642 13.059Z"
					fill="#FFC107"
				/>
				<path
					d="M18.4752 11.4072V25.723H23.9043C24.8205 25.723 25.5727 24.9659 25.5727 24.0437V18.5651L18.4752 11.4072Z"
					fill={'url(#ga-attrs-group-c-' + componentId + ')'}
				/>
				<path
					d="M13.0052 11.5449H18.4752V11.4072H13.0052C12.1026 11.4072 11.3641 12.1505 11.3641 13.059V13.1967C11.3641 12.2882 12.1026 11.5449 13.0052 11.5449Z"
					fill="white"
					opacity="0.2"
				/>
				<path
					d="M6.44107 18.7027H11.3642V18.5651H6.44107C5.53851 18.5651 4.80005 19.3084 4.80005 20.2169V20.3545C4.80005 19.446 5.53851 18.7027 6.44107 18.7027Z"
					fill="white"
					opacity="0.2"
				/>
				<path
					d="M23.918 4.79999H20.1573C19.241 4.79999 18.4889 5.55707 18.4889 6.47934V6.61699C18.4889 5.69472 19.241 4.93764 20.1573 4.93764H23.918C24.8342 4.93764 25.5863 5.69472 25.5863 6.61699V6.47934C25.5863 5.55707 24.8342 4.79999 23.918 4.79999Z"
					fill="white"
					opacity="0.2"
				/>
				<path
					d="M23.9182 25.5854H6.44109C5.53852 25.5854 4.80005 24.842 4.80005 23.9335V24.0712C4.80005 24.9797 5.53852 25.723 6.44109 25.723H23.9045C24.8207 25.723 25.5729 24.9659 25.5729 24.0437L25.5731 23.906C25.5866 24.8283 24.8344 25.5854 23.9182 25.5854Z"
					fill="#BF360C"
					opacity="0.2"
				/>
				<path
					d="M23.918 4.79999H20.1573C19.2411 4.79999 18.4889 5.55707 18.4889 6.47934V11.4073H13.0189C12.1163 11.4073 11.3778 12.1506 11.3778 13.0591V18.5652H6.44107C5.53851 18.5652 4.80005 19.3085 4.80005 20.217V24.0712C4.80005 24.9797 5.53851 25.7231 6.44107 25.7231H23.918C24.8342 25.7231 25.5864 24.966 25.5864 24.0437V6.47934C25.5864 5.55707 24.8342 4.79999 23.918 4.79999V4.79999Z"
					fill={'url(#ga-attrs-group-d-' + componentId + ')'}
				/>
				<defs>
					<linearGradient
						gradientUnits="userSpaceOnUse"
						id={'ga-attrs-group-a-' + componentId}
						x1="4.80005"
						x2="25.6"
						y1="15.2615"
						y2="15.2615"
					>
						<stop
							stopColor="white"
							stopOpacity="0.1"
						/>
						<stop
							offset="1"
							stopColor="white"
							stopOpacity="0"
						/>
					</linearGradient>
					<linearGradient
						gradientUnits="userSpaceOnUse"
						id={'ga-attrs-group-b-' + componentId}
						x1="4.80005"
						x2="25.6"
						y1="15.2615"
						y2="15.2615"
					>
						<stop
							stopColor="white"
							stopOpacity="0.1"
						/>
						<stop
							offset="1"
							stopColor="white"
							stopOpacity="0"
						/>
					</linearGradient>
					<linearGradient
						gradientUnits="userSpaceOnUse"
						id={'ga-attrs-group-c-' + componentId}
						x1="14.9539"
						x2="26.8823"
						y1="15.0206"
						y2="17.9718"
					>
						<stop
							stopColor="#BF360C"
							stopOpacity="0.2"
						/>
						<stop
							offset="1"
							stopColor="#BF360C"
							stopOpacity="0.02"
						/>
					</linearGradient>
					<linearGradient
						gradientUnits="userSpaceOnUse"
						id={'ga-attrs-group-d-' + componentId}
						x1="11.7702"
						x2="25.3046"
						y1="11.8245"
						y2="25.2705"
					>
						<stop
							stopColor="white"
							stopOpacity="0.1"
						/>
						<stop
							offset="1"
							stopColor="white"
							stopOpacity="0"
						/>
					</linearGradient>
				</defs>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default GoogleAnalyticsIconBuilder;
