import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	type EnrichmentFieldsQuery,
	useEnrichmentFieldsQuery,
} from './useWebsiteEnrichmentFieldDefinitions.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';

import memoizeById from '~/utilities/memoizeById';



type EnrichmentFieldDefinition = {
	column: GraphQL.EnrichmentField['column'],
	dataType: GraphQL.EnrichmentField['dataType'],
	id: GraphQL.EnrichmentField['id'],
	label: GraphQL.EnrichmentField['label'],
};

type WebsiteEnrichmentFields = {
	count: number,
	getByColumn: (column: string) => EnrichmentFieldDefinition | null,
	getById: (id: string) => EnrichmentFieldDefinition | null,
	isLoaded: boolean,
	listAll: () => Readonly<Array<EnrichmentFieldDefinition>>,
};

const format = memoizeById(
	(data: EnrichmentFieldsQuery | undefined) => {
		const enrichmentFields = data?.website?.enrichmentFields ?? null;

		return {
			count: enrichmentFields?.length ?? 0,
			getByColumn: (column: string) => enrichmentFields?.find((field) => field.column === column) ?? null,
			getById: (id: string) => enrichmentFields?.find((field) => field.id === id) ?? null,
			isLoaded: enrichmentFields !== null,
			listAll: () => enrichmentFields ?? [],
		};
	},
);



function useWebsiteEnrichmentFieldDefinitions(
	websiteId: CK.WebsiteId | null,
): WebsiteEnrichmentFields {
	const { data } = useEnrichmentFieldsQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return format(websiteId, data);
}



export default useWebsiteEnrichmentFieldDefinitions;
