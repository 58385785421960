import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';



const messages = defineMessages({
	columnsDirective: {
		id: 'ui.platformIssueDetail.robotsTxt.columns.directive',
	},
	columnsError: {
		id: 'ui.platformIssueDetail.robotsTxt.columns.error',
	},
	columnsLine: {
		id: 'ui.platformIssueDetail.robotsTxt.columns.line',
	},
	error: {
		id: 'ui.platformIssueDetail.robotsTxt.lineErrors.cantMatchAnything',
	},
});



type Props = {
	invalidDirectives: ReadonlyArray<{
		line: number,
		line_content: string,
	}>,
	tableWidth: number,
};

const InvalidRobotsTxtDirectivesTable: React.FC<Props> = (props) => {
	const {
		invalidDirectives,
		tableWidth,
	} = props;

	const firstColumnWidth = 50;
	const secondColumnWidth = Math.ceil((tableWidth - firstColumnWidth) / 2);

	return (
		<SmallTable
			columns={[
				{
					render: {
						cell: ({ row }) => {
							return (
								<FormattedNumber value={row.line} />
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnsLine} />
						),
					},
					width: firstColumnWidth,
				},
				{
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis>
									<CodeValue>
										{row.line_content}
									</CodeValue>
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnsDirective} />
						),
					},
					width: secondColumnWidth - 2,
				},
				{
					render: {
						cell: () => {
							return (
								<Ellipsis>
									<FormattedMessage {...messages.error} />
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnsError} />
						),
					},
					width: tableWidth - firstColumnWidth - secondColumnWidth - 2,
				},
			]}
			rows={invalidDirectives}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(InvalidRobotsTxtDirectivesTable);
