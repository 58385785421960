import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DomainIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeWidth="1"
			>
				<g id="globe">
					<path
						d="M16,27 C12.686,27 10,22.075 10,16 C10,9.925 12.686,5 16,5 C19.314,5 22,9.925 22,16 C22,22.075 19.314,27 16,27 L16,27 Z"
						id="Stroke-1610"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M5,16 L27,16"
						id="Stroke-1611"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M6.8431,10 L25.1871,10"
						id="Stroke-1612"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M16,5 L16,27"
						id="Stroke-1613"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M27,16 C27,22.075 22.075,27 16,27 C9.925,27 5,22.075 5,16 C5,9.925 9.925,5 16,5 C22.075,5 27,9.925 27,16 L27,16 Z"
						id="Stroke-1614"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M6.8431,22 L25.1871,22"
						id="Stroke-1615"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default DomainIconBuilder;
