import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import CancelButton from '~/components/app/CancelButton';
import ColumnSelectField from './ColumnSelectField';
import Copy from '../../Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InteractiveRadioLabel from '~/components/atoms/forms/components/radioLists/InteractiveRadioLabel';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalContentWithSidebar from '~/components/atoms/modals/parts/ModalContentWithSidebar';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalPanel, {
	SIZE_XLARGE,
} from '~/components/atoms/panels/ModalPanel';
import PercentageField from '~/components/atoms/forms/components/countSliders/PercentageField';
import RadioList from '~/components/atoms/forms/components/RadioList';
import RichText from '~/components/patterns/typography/RichText';
import SelectField from '~/components/atoms/forms/components/SelectField';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	isInteger,
	isNumerical,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	buttonCreate: {
		id: 'ui.segments.editor.sizeLimitModal.button.create',
	},
	buttonEdit: {
		id: 'ui.segments.editor.sizeLimitModal.button.edit',
	},
	fieldLabel: {
		id: 'ui.segments.editor.sizeLimitModal.field.label',
	},
	fieldPlaceholder: {
		id: 'ui.segments.editor.sizeLimitModal.field.placeholder',
	},
	numberOfPagesInvalid: {
		id: 'ui.segments.editor.sizeLimitModal.numberOfPages.invalid',
	},
	numberOfPagesLabel: {
		id: 'ui.segments.editor.sizeLimitModal.numberOfPages.label',
	},
	orderAsc: {
		id: 'ui.segments.editor.sizeLimitModal.order.asc',
	},
	orderDesc: {
		id: 'ui.segments.editor.sizeLimitModal.order.desc',
	},
	percentageInvalid: {
		id: 'ui.segments.editor.sizeLimitModal.percentage.invalid',
	},
	percentageLabel: {
		id: 'ui.segments.editor.sizeLimitModal.percentage.label',
	},
	percentageTooltip: {
		id: 'ui.segments.editor.sizeLimitModal.percentage.tooltip',
	},
	sidebar: {
		id: 'ui.segments.editor.sizeLimitModal.sidebar',
	},
	title: {
		id: 'ui.segments.editor.sizeLimitModal.title',
	},
	typeLabel: {
		id: 'ui.segments.editor.sizeLimitModal.type.label',
	},
});

const validations = {
	field: [
		{
			field: 'field',
			message: '',
			rule: ({ values }) => {
				return values.field && values.field.length > 0;
			},
		},
	],
	numberOfPages: [
		{
			field: 'numberOfPages',
			message: '',
			rule: ({ values, name }) => {
				if (values.type !== 'numberOfPages') {
					return true;
				}

				return isNumerical(values[name]);
			},
		},
		{
			field: 'numberOfPages',
			message: <FormattedMessage {...messages.numberOfPagesInvalid} />,
			rule: ({ values }) => {
				if (values.type !== 'numberOfPages') {
					return true;
				}

				const numberOfPages = parseFloat(values.numberOfPages);
				return isInteger(numberOfPages) && numberOfPages > 0;
			},
		},
	],
	order: [
		{
			field: 'order',
			message: '',
			rule: ({ values }) => {
				return values.order && values.order.length > 0;
			},
		},
	],
	percentage: [
		{
			field: 'percentage',
			message: <FormattedMessage {...messages.percentageInvalid} />,
			rule: ({ values }) => {
				if (values.type !== 'percentage') {
					return true;
				}

				const percentage = parseFloat(values.percentage);
				return isInteger(percentage) && percentage >= 1 && percentage <= 99;
			},
		},
	],
};



type Props = {
	closeCallback: () => void,
	sizeLimit: CK.PageSegmentSizeLimit | null,
	totalNumberOfPages: number,
	updateSizeLimit: (sizeLimit: CK.PageSegmentSizeLimit) => void,
};

const SizeLimitModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
		sizeLimit,
		totalNumberOfPages,
		updateSizeLimit,
	} = props;

	const defaultValues = sizeLimit !== null ? {
		field: sizeLimit.field,
		numberOfPages: sizeLimit.limit.type === 'numberOfPages' ? sizeLimit.limit.numberOfPages : null,
		order: sizeLimit.order,
		percentage: (sizeLimit.limit.type === 'percentage' ? sizeLimit.limit.percentage : 10) * 100,
		type: sizeLimit.limit.type,
	} : {
		field: null,
		numberOfPages: null,
		order: 'desc',
		percentage: 10,
		type: 'percentage',
	};

	const handleSubmit = React.useCallback(
		(data) => {
			updateSizeLimit(
				((): CK.PageSegmentSizeLimit => {
					if (data.type === 'percentage') {
						return {
							field: data.field,
							limit: {
								percentage: parseInt(data.percentage, 10) / 100,
								type: 'percentage',
							},
							order: data.order,
						};
					}

					if (data.type === 'numberOfPages') {
						return {
							field: data.field,
							limit: {
								numberOfPages: parseInt(data.numberOfPages, 10),
								type: 'numberOfPages',
							},
							order: data.order,
						};
					}

					throw new Error(`Unknown limit type '${data.type}'`);
				})(),
			);

			closeCallback();
		},
		[
			closeCallback,
			updateSizeLimit,
		],
	);

	return (
		<Form
			defaultValues={defaultValues}
			onSuccess={handleSubmit}
			validations={validations}
		>
			{({ focused, values }) => (
				<ModalPanel
					canBeClosed={true}
					canBeClosedViaOverlay={true}
					onCloseCallback={closeCallback}
					size={SIZE_XLARGE}
				>
					<ModalContainer
						gapsSize={0}
						header={(
							<ModalHeader
								iconType={ModalHeaderIconType.Plus}
								title={
									<FormattedMessage {...messages.title} />
								}
							/>
						)}
						headerGapsSize={1}
					>
						<ModalContentWithSidebar
							gapsSize={2}
							sidebar={(
								<RichText>
									<Copy {...messages.sidebar} />
								</RichText>
							)}
							slimSidebar={true}
						>
							<FormRows>
								<FormRow
									fullwidth={true}
									htmlFor="field"
									label={
										<FormattedMessage {...messages.fieldLabel} />
									}
									maxFieldWidth={470}
								>
									<FieldStatus
										name="field"
										showIcon={false}
									>
										<ColumnSelectField
											name="field"
											placeholder={
												<FormattedMessage {...messages.fieldPlaceholder} />
											}
										/>
									</FieldStatus>
									<FieldStatus
										name="order"
										showIcon={false}
									>
										<SelectField
											dropdownWidth={180}
											name="order"
											options={[
												{
													label: <FormattedMessage {...messages.orderDesc} />,
													name: 'desc',
												},
												{
													label: <FormattedMessage {...messages.orderAsc} />,
													name: 'asc',
												},
											]}
											width={124}
										/>
									</FieldStatus>
								</FormRow>
								<FormRow
									fullwidth={true}
									htmlFor="type"
									label={
										<FormattedMessage {...messages.typeLabel} />
									}
								>
									<RadioList
										items={[
											{
												label: ({ isChecked }) => (
													<InteractiveRadioLabel
														ctaElement={isChecked && (
															<FieldStatus name="percentage">
																<PercentageField
																	handlerBubble={focused === 'percentage' ? (
																		<FormattedMessage
																			{...messages.percentageTooltip}
																			values={{
																				limitedNumberOfPages: Math.ceil(totalNumberOfPages * values.percentage / 100),
																				totalNumberOfPages,
																			}}
																		/>
																	) : undefined}
																	maxValue={99}
																	minValue={1}
																	name="percentage"
																	numberOfSteps={101}
																	width={170}
																/>
															</FieldStatus>
														)}
														label={
															<FormattedMessage {...messages.percentageLabel} />
														}
													/>
												),
												value: 'percentage',
											},
											{
												label: ({ isChecked }) => (
													<InteractiveRadioLabel
														ctaElement={isChecked && (
															<FieldStatus name="numberOfPages">
																<AttachedElement
																	element="pages"
																	inline={true}
																>
																	<TextField
																		name="numberOfPages"
																		width={65}
																	/>
																</AttachedElement>
															</FieldStatus>
														)}
														label={
															<FormattedMessage {...messages.numberOfPagesLabel} />
														}
													/>
												),
												value: 'numberOfPages',
											},
										]}
										name="type"
										width={false}
									/>
								</FormRow>
							</FormRows>

							<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
								<CancelButton />

								<SubmitButton>
									{sizeLimit !== null
										? <FormattedMessage {...messages.buttonEdit} />
										: <FormattedMessage {...messages.buttonCreate} />
									}
								</SubmitButton>
							</ModalButtonsLayout>
						</ModalContentWithSidebar>
					</ModalContainer>
				</ModalPanel>
			)}
		</Form>
	);
};



export default SizeLimitModal;
