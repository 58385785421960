import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StaticText from '~/components/atoms/forms/components/StaticText';

import useAccountPaymentMethod from '~/hooks/useAccountPaymentMethod';

import {
	METHOD_INVOICE,
	METHOD_SEPA_DEBIT,
} from '~/model/payments';



const messages = defineMessages({
	cardholderLabel: {
		id: 'ui.creditCard.cardHolder',
	},
	cardNumberLabel: {
		id: 'ui.creditCard.cardNumber',
	},
	payByInvoice: {
		id: 'ui.newTeam.form.invoice',
	},
	payBySepa: {
		id: 'ui.newTeam.form.sepaDebit',
	},
	paymentMethod: {
		id: 'ui.paymentOverview.method',
	},
	sepaLabel: {
		id: 'ui.sepaDebit.sepa',
	},
});



type Props = {
	accountId: CK.AccountId,
};

const PaymentMethodOverview: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const paymentMethod = useAccountPaymentMethod(accountId);

	let simpleContent = (
		<StaticText />
	);

	if (paymentMethod !== null) {
		if (paymentMethod.type === 'card') {
			return (
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.cardholderLabel} />
						)}
					>
						<StaticText>
							{paymentMethod.details.cardholder}
						</StaticText>
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.cardNumberLabel} />
						)}
					>
						<StaticText>
							**** **** **** {paymentMethod.details.last4}
						</StaticText>
					</FormRow>
				</FormRows>
			);
		} else if (paymentMethod.type === METHOD_INVOICE) {
			simpleContent = (
				<StaticText>
					<FormattedMessage {...messages.payByInvoice} />
				</StaticText>
			);
		} else if (paymentMethod.type === METHOD_SEPA_DEBIT) {
			return (
				<>
					<FormRow
						label={(
							<FormattedMessage {...messages.sepaLabel} />
						)}
					>
						<StaticText>
							{paymentMethod.details.bankCode} - {paymentMethod.details.last4}
						</StaticText>
					</FormRow>
				</>
			);
		}
	}

	return (
		<FormRow
			label={(
				<FormattedMessage {...messages.paymentMethod} />
			)}
		>
			{simpleContent}
		</FormRow>
	);
};



export default PaymentMethodOverview;
