import groupBy from 'lodash/groupBy';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeSnippets from '~/components/patterns/structuredValues/CodeSnippets';
import Value from './Value';



const messages = defineMessages({
	label: {
		id: 'ui.contentData.canonical',
	},
	labelHeader: {
		id: 'ui.contentData.linkCanonicalHeader',
	},
	labelHtml: {
		id: 'ui.contentData.linkCanonicalHtml',
	},
});



type Props = {
	canonicalLinks: ReadonlyArray<{
		href: string,
		type: string,
	}>,
};

const TooManyCanonicalLinksCase: React.FC<Props> = (props) => {
	const {
		canonicalLinks,
	} = props;

	const grouped = groupBy(canonicalLinks, (canonicalLink) => canonicalLink.type);

	const singleType = Object.values(grouped).length === 1;

	const perType = Object.fromEntries(
		Object.entries(grouped).map(
			([type, items]) => ([
				type,
				items.length,
			]),
		),
	);

	let numberOfLinksInHeader = 0;
	let numberOfLinksInHtml = 0;

	return (
		<CodeSnippets
			snippets={canonicalLinks.map((canonicalLink) => {
				let label;
				let number;

				if (canonicalLink.type === 'link_canonical_header') {
					label = (
						<FormattedMessage {...messages.labelHeader} />
					);
					number = ++numberOfLinksInHeader;
				}

				if (canonicalLink.type === 'canonical') {
					label = (
						<FormattedMessage {...messages.labelHtml} />
					);
					number = ++numberOfLinksInHtml;
				}

				if (singleType) {
					label = (
						<FormattedMessage {...messages.label} />
					);
				}

				return {
					label: (
						<span>
							{label}
							{(perType[canonicalLink.type] ?? 0) > 1 && (
								<span>&nbsp;#{number}</span>
							)}
						</span>
					),
					content: (
						<Value>
							{canonicalLink.href}
						</Value>
					),
				};
			})}
		/>
	);
};



export default TooManyCanonicalLinksCase;
