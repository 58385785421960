import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import IndexNowCustomElementChangesTriggerModal from '~/components/app/IndexNowCustomElementChangesTriggerModal';
import IndexNowNativeTriggersModal, {
	IndexNowNativeTriggersCategory,
} from '~/components/app/IndexNowNativeTriggersModal';
import InternalLink from '~/components/patterns/links/InternalLink';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticText from '~/components/atoms/forms/components/StaticText';

import useIndexNowTriggers from '~/hooks/useIndexNowTriggers';
import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useModals from '~/hooks/useModals';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	changeLink: {
		id: 'ui.websiteSettingsIndexNowTriggersForm.changeLink',
	},
	contentChanges: {
		id: 'ui.websiteSettingsIndexNowTriggersForm.contentChanges',
	},
	customElementChanges: {
		id: 'ui.websiteSettingsIndexNowTriggersForm.customElementChanges',
	},
	pageStatusChanges: {
		id: 'ui.websiteSettingsIndexNowTriggersForm.pageStatusChanges',
	},
	saveButton: {
		id: 'ui.general.saveButton',
	},
	title: {
		id: 'ui.websiteSettingsIndexNowTriggersForm.title',
	},
	triggersConfigured: {
		id: 'ui.websiteSettingsIndexNowTriggersForm.triggersConfigured',
	},
});



const WebsiteSettingsIndexNowTriggersForm: React.FC = () => {
	const modals = useModals();
	const websiteId = useWebsiteId();

	const isAllowedToManageSettings = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageSettings,
	);

	const indexNowTriggers = useIndexNowTriggers(websiteId);

	const openContentChangesTriggerModal = React.useCallback(
		() => {
			modals.openModal(() => (
				<IndexNowNativeTriggersModal
					category={IndexNowNativeTriggersCategory.ContentChanges}
				/>
			));
		},
		[
			modals,
		],
	);

	const openCustomElementChangesTriggerModal = React.useCallback(
		() => {
			modals.openModal(() => (
				<IndexNowCustomElementChangesTriggerModal />
			));
		},
		[
			modals,
		],
	);

	const openPageStatusChangesTriggerModal = React.useCallback(
		() => {
			modals.openModal(() => (
				<IndexNowNativeTriggersModal
					category={IndexNowNativeTriggersCategory.PageStatusChanges}
				/>
			));
		},
		[
			modals,
		],
	);

	function renderChangeLink(onClickCallback: () => void) {
		return (
			<FormattedMessage
				{...messages.changeLink}
				values={{
					linkModal: (chunks) => (
						<InternalLink
							onClickCallback={onClickCallback}
						>
							{chunks}
						</InternalLink>
					),
				}}
			/>
		);
	}

	function renderPart(editable: boolean) {
		return (
			<>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.pageStatusChanges} />
						)}
					>
						{indexNowTriggers.loading ? (
							<SquareSkeleton width={150} />
						) : (
							<StaticText>
								<FormattedMessage
									{...messages.triggersConfigured}
									values={{
										amount: indexNowTriggers.pageStatusTriggers?.length,
									}}
								/>
								{editable && renderChangeLink(openPageStatusChangesTriggerModal)}
							</StaticText>
						)}
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.contentChanges} />
						)}
					>
						{indexNowTriggers.loading ? (
							<SquareSkeleton width={150} />
						) : (
							<StaticText>
								<FormattedMessage
									{...messages.triggersConfigured}
									values={{
										amount: indexNowTriggers.contentTriggers?.length,
									}}
								/>
								{editable && renderChangeLink(openContentChangesTriggerModal)}
							</StaticText>
						)}
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.customElementChanges} />
						)}
					>
						{indexNowTriggers.loading ? (
							<SquareSkeleton width={150} />
						) : (
							<StaticText>
								<FormattedMessage
									{...messages.triggersConfigured}
									values={{
										amount: indexNowTriggers.customElementTriggers?.length,
									}}
								/>
								{editable && renderChangeLink(openCustomElementChangesTriggerModal)}
							</StaticText>
						)}
					</FormRow>
				</FormRows>

				{editable && (
					<ButtonsLayout>
						<CancelButton />
					</ButtonsLayout>
				)}
			</>
		);
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageSettings}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				{renderPart(false)}
			</DisplayPart>

			<EditablePart>
				{renderPart(true)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default WebsiteSettingsIndexNowTriggersForm;
