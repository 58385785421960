import React from 'react';

import type GraphQL from '~/types/graphql';

import List from '~/components/patterns/lists/List';
import ListOfBenefits from './ListOfBenefits';
import ReviewsOverview from './ReviewsOverview';
import SatisfiedCustomers from './SatisfiedCustomers';
import UnderlinedRows from '~/components/patterns/lists/UnderlinedRows';

import {
	Screen,
} from '~/model/satisfiedCustomers';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	benefits?: boolean,
	customerType: GraphQL.CustomerType | null,
};

const BusinessSection: React.FC<Props> = (props) => {
	const {
		benefits = true,
		customerType,
	} = props;

	const viewportType = useViewportType();

	return (
		<UnderlinedRows gapsSize={3}>
			<List>
				<SatisfiedCustomers
					customerType={customerType}
					screen={Screen.PricingScreen}
				/>
				{!viewportType.isMedium && (
					<ReviewsOverview reviewsCount={2} />
				)}
			</List>
			{benefits && (
				<ListOfBenefits />
			)}
		</UnderlinedRows>
	);
};



export default BusinessSection;
