import React from 'react';
import {
	FormattedMessage,
	defineMessage,
} from 'react-intl';

import AnnouncementPopup from '~/components/patterns/messages/popup/AnnouncementPopup';
import TimelineMessageEntry from '~/components/patterns/time/timeline/TimelineMessageEntry';



const message = defineMessage({
	id: 'ui.pageDetail.history.noHistoryYet',
});



const NoHistoryTimelineEntry: React.FC = () => {
	return (
		<TimelineMessageEntry
			isFirstChild={true}
			isLastChild={false}
		>
			<AnnouncementPopup>
				<FormattedMessage {...message} />
			</AnnouncementPopup>
		</TimelineMessageEntry>
	);
};



export default NoHistoryTimelineEntry;
