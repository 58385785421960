import memoize from 'memoizee';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	type AllUsersQuery,
	useAllUsersQuery,
} from './useAllUsers.gql';

import useHomeAccountIds from '~/hooks/useHomeAccountIds';

import {
	uniqueBy,
} from '~/utilities/unique';



type User = {
	displayName: string,
	email: string,
	firstName: string | null,
	hasTwoFactorAuthentication: boolean,
	id: string,
	isAlertRecipientByDefault: boolean,
	isReportRecipientByDefault: boolean,
	lastName: string | null,
	legacyId: string,
	memberships: ReadonlyArray<{
		accountId: CK.AccountId,
		hasLimitedWebsitesAccess: boolean,
		role: GraphQL.UserRole,
		websites: ReadonlyArray<{
			id: CK.WebsiteId,
		}>,
	}>,
	uniqueId: CK.UserId,
};

const emptyResult = [];

const format = memoize(
	(
		data: AllUsersQuery | undefined,
		homeAccountIds: ReadonlyArray<CK.AccountId> | null,
	) => {
		const accounts = data?.authenticatedSession?.accounts ?? null;

		if (accounts === null) {
			return {
				isLoaded: false,
				listAll: () => emptyResult,
				listByAccount: () => emptyResult,
				listByWebsite: () => emptyResult,
				listByWebsiteAccount: () => emptyResult,
			};
		}

		const users: Record<string, User> = {};

		accounts.forEach((account) => {
			account.memberships.forEach((membership) => {
				const user = users[membership.user.id] ?? {
					displayName: membership.user.displayName,
					email: membership.user.email,
					firstName: membership.user.firstName,
					hasTwoFactorAuthentication: membership.user.hasTwoFactorAuthentication,
					id: membership.user.id,
					isAlertRecipientByDefault: membership.user.isAlertRecipientByDefault,
					isReportRecipientByDefault: membership.user.isReportRecipientByDefault,
					lastName: membership.user.lastName,
					legacyId: membership.user.legacyId,
					memberships: [],
					uniqueId: membership.user.uniqueId,
				};

				users[user.id] = {
					...user,
					memberships: [
						...user.memberships,
						{
							accountId: account.id,
							hasLimitedWebsitesAccess: membership.hasLimitedWebsitesAccess,
							role: membership.role,
							websites: membership.websites,
						},
					],
				};
			});
		});

		const all = Object.values(users);
		const perAccount: Record<CK.AccountId, ReadonlyArray<User & {
			accountMembership: User['memberships'][number],
		}>> = {};

		accounts.forEach((account) => {
			perAccount[account.id] = all.filter(
				(user) => user.memberships.some(
					(membership) => membership.accountId === account.id,
				),
			).map(
				(user) => {
					const accountMembership = user.memberships.find(
						(membership) => membership.accountId === account.id,
					);

					if (accountMembership === undefined) {
						throw new Error(
							`accountMembership can't be null because it's checked in previous filter() call`,
						);
					}

					return {
						...user,
						accountMembership,
					};
				},
			);
		});

		const listByWebsiteAccount = (websiteAccountId: CK.AccountId) => {
			let result = perAccount[websiteAccountId] ?? [];

			const agencyAccountId = data?.authenticatedSession?.accounts.find(
				(account) => account.id === websiteAccountId,
			)?.agencyConnections.filter(
				(agencyConnection) => agencyConnection.isPending === false,
			)?.[0]?.agencyAccountId ?? null;

			if (
				agencyAccountId !== null
				&& homeAccountIds !== null
				&& homeAccountIds.includes(agencyAccountId)
			) {
				result = [
					...result,
					...perAccount[agencyAccountId] ?? [],
				];
			}

			return result;
		};

		return {
			isLoaded: true,
			listAll: () => all,
			listByAccount: (accountId: CK.AccountId) => perAccount[accountId] ?? [],
			listByWebsite: (websiteId: CK.WebsiteId) => {
				const websiteAccountId = data?.authenticatedSession?.accounts.find(
					(account) => account.websites.some((website) => website.id === websiteId),
				)?.id ?? null;

				if (websiteAccountId === null) {
					return emptyResult;
				}

				return listByWebsiteAccount(websiteAccountId)
					.filter((user) => user.accountMembership.websites.some((website) => website.id === websiteId))
					.filter(uniqueBy((user) => user.id))
					.map(({ ...user }: User) => {
						delete user['accountMembership'];
						delete user['websites'];

						return user;
					});
			},
			listByWebsiteAccount,
		};
	},
);



function useAllUsers() {
	const homeAccountIds = useHomeAccountIds();

	const { data } = useAllUsersQuery();

	return format(data, homeAccountIds);
}



export default useAllUsers;
