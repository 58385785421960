import React from 'react';

import {
	useConnectSlackAccountMutation,
} from './useRegisterSlackMessagingAppChannel.gql';

import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';

import {
	SLACK_CLIENT_ID,
	SLACK_REDIRECT_URL,
} from '~/config';

import getRandomString from '~/utilities/getRandomString';



type CallbackReturnType = Promise<{
	registeredMessagingAppChannelId: string,
}>;



function useRegisterSlackMessagingAppChannel() {
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const [connectSlackAccount] = useConnectSlackAccountMutation();

	return React.useCallback(
		(): CallbackReturnType => {
			if (effectiveHomeAccountId === null) {
				return Promise.reject();
			}

			const slackRedirectUrl = SLACK_REDIRECT_URL;
			const slackScope = 'incoming-webhook';
			const slackState = getRandomString(2);

			const authWindow = window.open(
				'https://slack.com/oauth/v2/authorize'
				+ '?client_id=' + SLACK_CLIENT_ID
				+ '&scope=' + slackScope
				+ '&redirect_uri=' + slackRedirectUrl
				+ '&state=' + slackState,
			);

			if (authWindow === null) {
				return Promise.reject();
			}

			const redirectUrl = new URL(slackRedirectUrl);

			return new Promise((resolve, reject) => {
				const listener = async (message) => {
					if (message.origin !== redirectUrl.origin) {
						return;
					}

					window.removeEventListener('message', listener);

					authWindow.close();

					if (!message.data.auth_code) {
						return reject();
					}

					if (message.data.state !== slackState) {
						return reject();
					}

					try {
						const { data } = await connectSlackAccount({
							variables: {
								accountId: effectiveHomeAccountId,
								authCode: message.data.auth_code,
								slackRedirectUrl,
							},
						});

						const registeredMessagingAppChannelId = data?.ConnectSlackAccount.registeredMessagingAppChannelId ?? null;

						if (registeredMessagingAppChannelId === null) {
							return reject();
						}

						return resolve({
							registeredMessagingAppChannelId,
						});
					} catch (error) {
						return reject(error);
					}
				};

				window.addEventListener('message', listener, false);
			});
		},
		[
			connectSlackAccount,
			effectiveHomeAccountId,
		],
	);
}



export default useRegisterSlackMessagingAppChannel;
