import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import AuthorizePaymentModal from '~/components/logic/AuthorizePaymentModal';
import ChurnedAccountModal from '~/components/app/ChurnedAccountModal';
import ExpiredTrialModal from '~/components/app/ExpiredTrialModal';
import LockedAccountModal from '~/components/app/LockedAccountModal';
import OnboardingGuideModal from '~/components/atoms/modals/OnboardingGuideModal';
import ReactivateAccountModal from '~/components/app/ReactivateAccountModal';

import {
	useForcedModalsQuery,
} from '~/components/app/ForcedModals.gql';

import useAccountId from '~/hooks/useAccountId';
import useAccountState from '~/hooks/useAccountState';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import usePollInterval from '~/hooks/usePollInterval';
import useUrlState from '~/hooks/useUrlState';

import {
	isActiveSelector as isPaymentAuthorizationActiveSelector,
	useModalSelector as usePaymentAuthorizationModalSelector,
} from '~/state/paymentAuthorization';



const ForcedModals: React.FC = () => {
	const accountId = useAccountId();
	const accountState = useAccountState(accountId);
	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const urlState = useUrlState();

	const [isModalHidden, setModalHidden] = React.useState(false);

	const isPaymentAuthorizationActive = useSelector(isPaymentAuthorizationActiveSelector);
	const usePaymentAuthorizationModal = useSelector(usePaymentAuthorizationModalSelector);

	const { data } = useForcedModalsQuery({
		fetchPolicy: 'network-only',
		pollInterval: usePollInterval(60_000),
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const accountIsCleanedUp = data?.account?.isCleanedUp ?? false;
	const accountIsTrialRevivable = data?.account?.isSuitableForTrialRevival ?? false;
	const userIsOnboarding = data?.authenticatedSession?.memberships.find(
		(membership) => membership.account.id === effectiveHomeAccountId,
	)?.isOnboardingTourInProgress ?? false;

	const hideModal = React.useCallback(
		() => {
			setModalHidden(true);
		},
		[],
	);

	if (isModalHidden) {
		return null;
	}

	if (
		isPaymentAuthorizationActive
		&& usePaymentAuthorizationModal
	) {
		return (
			<AuthorizePaymentModal />
		);
	}

	if (accountState === GraphQL.AccountState.Churned) {
		if (urlState.name.startsWith('account')) {
			return null;
		}

		return (
			<ChurnedAccountModal
				closeCallback={hideModal}
			/>
		);
	}

	if (
		accountState === GraphQL.AccountState.Expired
		&& accountIsTrialRevivable
	) {
		if (urlState.name === 'account.websites.new') {
			return null;
		}

		return (
			<ReactivateAccountModal
				closeCallback={hideModal}
			/>
		);
	}

	if (
		accountState === GraphQL.AccountState.Expired
		&& !accountIsCleanedUp
	) {
		if (
			urlState.name.startsWith('account')
			|| urlState.name.startsWith('userProfile')
			|| urlState.name.startsWith('website.detail')
		) {
			return null;
		}

		return (
			<ExpiredTrialModal
				closeCallback={hideModal}
			/>
		);
	}

	if (
		accountState === GraphQL.AccountState.Expired
		&& accountIsCleanedUp
	) {
		if (urlState.name.startsWith('account.pricing')) {
			return null;
		}

		return (
			<LockedAccountModal
				closeCallback={hideModal}
			/>
		);
	}

	if (userIsOnboarding) {
		return (
			<OnboardingGuideModal
				closeCallback={hideModal}
			/>
		);
	}

	return null;
};



export default ForcedModals;
