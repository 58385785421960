import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import EditNativeAlertDefinitionModal from './EditNativeAlertDefinitionModal';
import EditPagesEnteredOrLeftSegmentAlertDefinitionModal from './EditPagesEnteredOrLeftSegmentAlertDefinitionModal';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';

import useUrlState from '~/hooks/useUrlState';
import useWebsiteAlertDefinitions from '~/hooks/useWebsiteAlertDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
} from '~/model/alerts';



const messages = defineMessages({
	headerLabel: {
		id: 'ui.general.settings',
	},
});



const EditAlertDefinitionModal: React.FC = () => {
	const websiteId = useWebsiteId();

	const alertDefinitions = useWebsiteAlertDefinitions(websiteId);
	const urlState = useUrlState();

	const alertDefinition = alertDefinitions.getById(urlState.params.alertDefinitionId);

	if (alertDefinition === null) {
		return (
			<ModalContainer
				gapsSize={0}
				header={(
					<ModalHeader
						iconType={ModalHeaderIconType.CogWheel}
						title={(
							<FormattedMessage {...messages.headerLabel} />
						)}
					/>
				)}
				headerGapsSize={1}
				isLoading={true}
			/>
		);
	}

	if (alertDefinition.alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT) {
		return (
			<EditPagesEnteredOrLeftSegmentAlertDefinitionModal
				alertDefinition={alertDefinition}
			/>
		);
	}

	return (
		<EditNativeAlertDefinitionModal
			alertDefinition={alertDefinition}
		/>
	);
};



export default EditAlertDefinitionModal;
