import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CustomElementsIconBuilder: IconBuilder = {
	icon: ({ color = '#43ad96' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m32 32h-32v-32h32z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				>
					<path d="m27 27h-22v-22h22z" />
					<path d="m12.7 13.8h-4.4v-5.5h4.4z" />
					<path d="m23.7 13.8h-7.7v-5.5h7.7z" />
					<path d="m19.3 23.7h4.4v-6.6h-4.4z" />
					<path d="m8.3 23.7h7.7v-6.6h-7.7z" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default CustomElementsIconBuilder;
