/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnignorePageIssueOnSpecificPagesMutationVariables = GraphQL.Exact<{
  issueName: GraphQL.Scalars['String']['input'];
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueOnSpecificPagesMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueOnSpecificPages: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId } | null } } };


export const UnignorePageIssueOnSpecificPagesDocument = gql`
    mutation UnignorePageIssueOnSpecificPages($issueName: String!, $urls: [String!]!, $websiteId: WebsiteId!) {
  UnignorePageIssueOnSpecificPages(
    type: $issueName
    urls: $urls
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
      }
    }
  }
}
    `;
export type UnignorePageIssueOnSpecificPagesMutationFn = Apollo.MutationFunction<UnignorePageIssueOnSpecificPagesMutation, UnignorePageIssueOnSpecificPagesMutationVariables>;

/**
 * __useUnignorePageIssueOnSpecificPagesMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueOnSpecificPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueOnSpecificPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueOnSpecificPagesMutation, { data, loading, error }] = useUnignorePageIssueOnSpecificPagesMutation({
 *   variables: {
 *      issueName: // value for 'issueName'
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueOnSpecificPagesMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueOnSpecificPagesMutation, UnignorePageIssueOnSpecificPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueOnSpecificPagesMutation, UnignorePageIssueOnSpecificPagesMutationVariables>(UnignorePageIssueOnSpecificPagesDocument, options);
      }
export type UnignorePageIssueOnSpecificPagesMutationHookResult = ReturnType<typeof useUnignorePageIssueOnSpecificPagesMutation>;
export type UnignorePageIssueOnSpecificPagesMutationResult = Apollo.MutationResult<UnignorePageIssueOnSpecificPagesMutation>;
export type UnignorePageIssueOnSpecificPagesMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueOnSpecificPagesMutation, UnignorePageIssueOnSpecificPagesMutationVariables>;