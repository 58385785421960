import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkInternal,
} from '~/components/logic/Copy';
import ExpirationTour from '~/components/patterns/tours/ExpirationTour';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import InternalLink from '~/components/patterns/links/InternalLink';
import ModalPanel, {
	SIZE_XLARGE as MODAL_SIZE_XLARGE,
} from '~/components/atoms/panels/ModalPanel';
import MultiStepModal, {
	MultiStepModalNavigation,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import SocialProof from '~/components/app/SocialProof';
import SubmitButton from '~/components/app/SubmitButton';
import TextArea from '~/components/atoms/forms/components/TextArea';

import {
	validateField,
} from '~/components/app/validations';

import {
	useExtendTrialMutation,
	useRefreshAfterExtendingTrialLazyQuery,
} from './ExpiredTrialModal.gql';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsTrialExtensionAllowed from '~/hooks/useAccountIsTrialExtensionAllowed';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useUserFirstName from '~/hooks/useUserFirstName';

import {
	Intercom,
} from '~/globals';



const extendTrialMessages = defineMessages({
	cancelButton: {
		id: 'ui.expiredTrialModal.extendStep.cancelButton',
	},
	content: {
		id: 'ui.expiredTrialModal.extendStep.content',
	},
	feedbackPlaceholder: {
		id: 'ui.expiredTrialModal.extendStep.feedbackPlaceholder',
	},
	footnote: {
		id: 'ui.expiredTrialModal.extendStep.footnote',
	},
	submitButton: {
		id: 'ui.expiredTrialModal.extendStep.submitButton',
	},
	title: {
		id: 'ui.expiredTrialModal.extendStep.title',
	},
});

const signupMessages = defineMessages({
	contactUs: {
		id: 'ui.expiredTrialModal.signUpStep.contactUs',
	},
	content: {
		id: 'ui.expiredTrialModal.signUpStep.content',
	},
	extendTrial: {
		id: 'ui.expiredTrialModal.signUpStep.extendTrial',
	},
	footnote: {
		id: 'ui.expiredTrialModal.signUpStep.footnote',
	},
	signUp: {
		id: 'ui.expiredTrialModal.signUpStep.signUp',
	},
	title: {
		id: 'ui.expiredTrialModal.signUpStep.title',
	},
});

const trialExtendedMessages = defineMessages({
	content: {
		id: 'ui.expiredTrialModal.extendedStep.content',
	},
	continue: {
		id: 'ui.expiredTrialModal.extendedStep.continue',
	},
	footnote: {
		id: 'ui.expiredTrialModal.extendedStep.footnote',
	},
	title: {
		id: 'ui.expiredTrialModal.extendedStep.title',
	},
});

const validations = {
	validateFeedback: validateField(
		'feedback',
		(f) => ([
			f.validateNonEmpty(),
		]),
	),
};



type Props = {
	closeCallback: () => void,
};

const ExpiredTrialModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const accountId = useAccountId();
	const currentUserId = useCurrentUserId();

	const firstName = useUserFirstName(currentUserId);
	const intl = useIntl();
	const isTrialExtensionAllowed = useAccountIsTrialExtensionAllowed(accountId);
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const isAllowedToViewSignup = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ViewSignup,
	);

	const [extendTrial] = useExtendTrialMutation();

	const [refreshAfterExtendingTrialQuery] = useRefreshAfterExtendingTrialLazyQuery();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (accountId === null) {
				throw new Error(
					`Account ID isn't ready`,
				);
			}

			await extendTrial({
				variables: {
					accountId,
					feedback: values.feedback,
				},
			});
		},
		[
			accountId,
			extendTrial,
		],
	);

	const handleFinish = React.useCallback(
		async () => {
			if (accountId === null) {
				throw new Error(
					`Account ID isn't ready`,
				);
			}

			await refreshAfterExtendingTrialQuery({
				variables: {
					accountId,
				},
			});

			closeCallback();
		},
		[
			accountId,
			closeCallback,
			refreshAfterExtendingTrialQuery,
		],
	);

	function renderSignupStep() {
		return (
			<MultiStepModalStep
				name="signup"
				sidebar={(
					<SocialProof />
				)}
				slimSidebar={true}
				title={(
					<FormattedMessage {...signupMessages.title} />
				)}
			>
				{({ goToStep }) => (
					<ExpirationTour
						additionalInfo={(
							<Copy
								{...signupMessages.footnote}
								values={{
									linkAccount: linkInternal('account'),
								}}
							/>
						)}
						ctaButton={(
							<>
								{isAllowedToViewSignup.yes ? (
									<Button
										linkRouteName="account.pricing"
										size={ButtonSize.XLarge}
										style={ButtonStyle.Action}
									>
										<FormattedMessage {...signupMessages.signUp} />
									</Button>
								) : (
									<Button
										onClick={() => {
											Intercom()('show');
										}}
										size={ButtonSize.XLarge}
										style={ButtonStyle.Action}
									>
										<FormattedMessage {...signupMessages.contactUs} />
									</Button>
								)}
							</>
						)}
						ctaNote={isTrialExtensionAllowed && (
							<Copy
								{...signupMessages.extendTrial}
								values={{
									linkExtendStep: (chunks) => (
										<InternalLink onClickCallback={() => goToStep('extendTrial')}>
											{chunks}
										</InternalLink>
									),
								}}
							/>
						)}
					>
						<Copy
							{...signupMessages.content}
							values={{
								hasName: firstName !== null ? 'yes' : 'no',
								name: firstName,
							}}
						/>
					</ExpirationTour>
				)}
			</MultiStepModalStep>
		);
	}

	function renderExtendTrialStep() {
		return (
			<MultiStepModalStep
				name="extendTrial"
				sidebar={(
					<SocialProof />
				)}
				slimSidebar={true}
				title={(
					<FormattedMessage {...extendTrialMessages.title} />
				)}
			>
				{({ goToStep, goToNextStep }) => (
					<ExpirationTour
						additionalInfo={(
							<Copy {...extendTrialMessages.footnote} />
						)}
						form={(
							<Form
								defaultFocus="feedback"
								onSuccess={(values) => {
									handleSubmit(values).then(goToNextStep);
								}}
								validations={validations}
							>
								<TextArea
									name="feedback"
									placeholder={intl.formatMessage(extendTrialMessages.feedbackPlaceholder)}
									width="100%"
								/>

								<CenteredFormWrapper>
									<FormErrorMessages
										errors={{
											accountNotInTrial: `Account isn't in trial, so it can't be extended`,
											trialExtensionAlreadyGranted: `Trial extension has already been granted`,
										}}
									/>
								</CenteredFormWrapper>

								<ButtonsLayout
									layout={ButtonsLayoutType.FormRow}
								>
									<CancelButton onClickCallback={() => goToStep('signup')}>
										<FormattedMessage {...extendTrialMessages.cancelButton} />
									</CancelButton>

									<SubmitButton>
										<FormattedMessage {...extendTrialMessages.submitButton} />
									</SubmitButton>
								</ButtonsLayout>
							</Form>
						)}
					>
						<Copy {...extendTrialMessages.content} />
					</ExpirationTour>
				)}
			</MultiStepModalStep>
		);
	}

	function renderTrialExtendedStep() {
		return (
			<MultiStepModalStep
				name="trialExtended"
				sidebar={(
					<SocialProof />
				)}
				slimSidebar={true}
				title={(
					<FormattedMessage {...trialExtendedMessages.title} />
				)}
			>
				<ExpirationTour
					ctaButton={(
						<Button
							onClick={handleFinish}
							size={ButtonSize.Large}
							style={ButtonStyle.Action}
						>
							<FormattedMessage {...trialExtendedMessages.continue} />
						</Button>
					)}
					ctaNote={(
						<Copy {...trialExtendedMessages.footnote} />
					)}
				>
					<Copy {...trialExtendedMessages.content} />
				</ExpirationTour>
			</MultiStepModalStep>
		);
	}

	return (
		<ModalPanel
			canBeClosed={kingdomAdminFeatures.areVisible}
			canBeClosedViaOverlay={kingdomAdminFeatures.areVisible}
			onCloseCallback={closeCallback}
			size={MODAL_SIZE_XLARGE}
		>
			<MultiStepModal
				minHeight={200}
				navigationModel={MultiStepModalNavigation.Rigid}
			>
				{renderSignupStep()}

				{isTrialExtensionAllowed && renderExtendTrialStep()}

				{isTrialExtensionAllowed && renderTrialExtendedStep()}
			</MultiStepModal>
		</ModalPanel>
	);
};



export default ExpiredTrialModal;
