import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import CodeValue from '~/components/patterns/values/CodeValue';
import Declaration from '~/components/patterns/values/declarations/Declaration';
import DeclarationsList from '~/components/patterns/values/declarations/DeclarationsList';
import LineBreaks from '~/components/patterns/values/LineBreaks';
import Terminal from '~/components/patterns/views/Terminal';
import UnreliableResponseName from '~/components/names/UnreliableResponseName';



const messages = defineMessages({
	propertyMethod: {
		id: 'ui.snapshotHeaders.properties.method',
	},
	propertyRemoteAddress: {
		id: 'ui.snapshotHeaders.properties.remoteAddress',
	},
	propertyRequestedAt: {
		id: 'ui.snapshotHeaders.properties.requestedAt',
	},
	propertyStatusCode: {
		id: 'ui.snapshotHeaders.properties.statusCode',
	},
	propertyUnreachable: {
		id: 'ui.snapshotHeaders.properties.unreachable',
	},
	propertyUrl: {
		id: 'ui.snapshotHeaders.properties.url',
	},
	sectionGeneral: {
		id: 'ui.snapshotHeaders.sections.general',
	},
	sectionRequestHeaders: {
		id: 'ui.snapshotHeaders.sections.requestHeaders',
	},
	sectionResponseHeaders: {
		id: 'ui.snapshotHeaders.sections.responseHeaders',
	},
});



type TerminalDeclarationProps = {
	/** Name of property */
	property: React.ReactNode,
	/** Attached values */
	value: React.ReactNode,
};

const TerminalDeclaration: React.FC<TerminalDeclarationProps> = (props) => {
	const {
		property,
		value,
	} = props;

	return (
		<LineBreaks useHyphens={false}>
			<Declaration
				property={(
					<CodeValue bold={true}>
						{property}
					</CodeValue>
				)}
				value={(
					<CodeValue>
						{value}
					</CodeValue>
				)}
			/>
		</LineBreaks>
	);
};



type Props = {
	requestHttpHeaders?: ReadonlyArray<GraphQL.HttpHeader> | null,
	responseFailureReason?: GraphQL.FetchingFailureReason | null,
	responseHttpHeaders?: ReadonlyArray<GraphQL.HttpHeader> | null,
	responsePrimaryIp?: string | null,
	responseStatusCode?: number | null,
	timestamp: CK.Timestamp,
	url: string | null,
};

const SnapshotHeaders: React.FC<Props> = (props) => {
	const {
		requestHttpHeaders,
		responseFailureReason,
		responseHttpHeaders,
		responsePrimaryIp,
		responseStatusCode,
		timestamp,
		url,
	} = props;

	return (
		<>
			<Terminal
				title={(
					<FormattedMessage {...messages.sectionGeneral} />
				)}
			>
				<DeclarationsList>
					<TerminalDeclaration
						property={(
							<FormattedMessage {...messages.propertyUrl} />
						)}
						value={url}
					/>

					<TerminalDeclaration
						property={(
							<FormattedMessage {...messages.propertyRequestedAt} />
						)}
						value={timestamp}
					/>

					<TerminalDeclaration
						property={(
							<FormattedMessage {...messages.propertyMethod} />
						)}
						value="GET"
					/>

					{responseFailureReason && (
						<TerminalDeclaration
							property={(
								<FormattedMessage {...messages.propertyUnreachable} />
							)}
							value={(
								<UnreliableResponseName reason={responseFailureReason} />
							)}
						/>
					)}

					{responseStatusCode && (
						<TerminalDeclaration
							property={(
								<FormattedMessage {...messages.propertyStatusCode} />
							)}
							value={responseStatusCode}
						/>
					)}

					{responsePrimaryIp && (
						<TerminalDeclaration
							property={(
								<FormattedMessage {...messages.propertyRemoteAddress} />
							)}
							value={responsePrimaryIp}
						/>
					)}
				</DeclarationsList>
			</Terminal>

			<Terminal
				title={(
					<FormattedMessage {...messages.sectionResponseHeaders} />
				)}
			>
				{responseHttpHeaders && (
					<DeclarationsList>
						{[...responseHttpHeaders]
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((header) => (
								<React.Fragment key={header.name}>
									{header.values.map((value, index) => (
										<TerminalDeclaration
											key={index}
											property={`${header.name}:`}
											value={value}
										/>
									))}
								</React.Fragment>
							))}
					</DeclarationsList>
				)}
			</Terminal>

			<Terminal
				title={(
					<FormattedMessage {...messages.sectionRequestHeaders} />
				)}
			>
				{requestHttpHeaders && (
					<DeclarationsList>
						{[...requestHttpHeaders]
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((header) => (
								<React.Fragment key={header.name}>
									{header.values.map((value, index) => (
										<TerminalDeclaration
											key={index}
											property={`${header.name}:`}
											value={value}
										/>
									))}
								</React.Fragment>
							))}
					</DeclarationsList>
				)}
			</Terminal>
		</>
	);
};



export default SnapshotHeaders;
