import React from 'react';



type Props = {
	children: React.ReactNode,
};

const FormPaymentMessage: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="form-payment-message">
			{children}
		</div>
	);
};



export default FormPaymentMessage;
