import {
	differenceInDays,
	format,
	formatISO,
	isBefore,
	parseISO,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DateAndTimeLabel from '~/components/app/DateAndTimeLabel';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormSentence from '~/components/atoms/forms/basis/FormSentence';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	useAccountAdminTrialPhaseFormQuery,
	useUpdateAccountAdminTrialPhaseSettingsMutation,
} from './AccountAdminTrialPhaseForm.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	accountId: CK.AccountId,
	accountPhase: GraphQL.AccountPhase,
};

const AccountAdminTrialPhaseForm: React.FC<Props> = (props) => {
	const {
		accountId,
		accountPhase,
	} = props;

	const { data } = useAccountPropertiesQuery(
		useAccountAdminTrialPhaseFormQuery,
		accountId,
	);

	const [updateAccountAdminTrialPhaseSettings] = useUpdateAccountAdminTrialPhaseSettingsMutation();

	const adminSettings = data?.account?.adminSettings ?? null;
	const trialExpirationDate = data?.account?.trialExpirationDate ?? null;

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateAccountAdminTrialPhaseSettings({
				variables: {
					accountId,
					daysToDataCleanup: values.daysToDataCleanup,
					daysToTrialRevival: values.daysToTrialRevival,
					isTrialExtensionAllowedByCustomerTeam: values.isTrialExtensionAllowedByCustomerTeam,
					isTrialRevivalAllowedByCustomerTeam: values.isTrialRevivalAllowedByCustomerTeam,
					trialExpirationDate: formatISO(
						new Date(values.time_trial_ends_date + ' ' + values.time_trial_ends_time),
					),
				},
			});
		},
		[
			accountId,
			updateAccountAdminTrialPhaseSettings,
		],
	);

	if (
		adminSettings === null
		|| trialExpirationDate === null
	) {
		return null;
	}

	function renderTrialEndWarning(timeTrialEndsDate, timeTrialEndsTime, daysToDataCleanup) {
		const now = new Date();
		const timeTrialEnds = new Date(timeTrialEndsDate + ' ' + timeTrialEndsTime);

		if (differenceInDays(now, timeTrialEnds) > daysToDataCleanup) {
			return (
				<div
					style={{
						color: 'red',
					}}
				>
					Be careful. You set <b>Trial expiry date</b> to more than {daysToDataCleanup} days ago - this will <b>DELETE THE ACCOUNT IMMEDIATELY</b>.
				</div>
			);
		}

		if (isBefore(timeTrialEnds, now)) {
			return (
				<div>
					You set <b>Trial expiry date</b> to past - this will <b>EXPIRE THE ACCOUNT IMMEDIATELY</b>.

				</div>
			);
		}

		return false;
	}

	return (
		<>
			<DisplayPart>
				<FormRows>
					<FormRow label="Account phase">
						<StaticText>
							{accountPhase}
						</StaticText>
					</FormRow>

					<FormRow label="Trial expires at">
						<StaticText focusTarget="time_trial_ends_date">
							<DateAndTimeLabel timestamp={parseISO(trialExpirationDate)} />
						</StaticText>
					</FormRow>

					<FormRow label="Trial extension">
						<StaticText focusTarget="isTrialExtensionAllowedByCustomerTeam">
							{matchAndReturn(adminSettings.isTrialExtensionAllowedByCustomerTeam, {
								[GraphQL.BooleanOrAutomatic.Automatic]: 'automatic',
								[GraphQL.BooleanOrAutomatic.No]: 'disallowed',
								[GraphQL.BooleanOrAutomatic.Yes]: 'allowed',
							})}
						</StaticText>
					</FormRow>

					<FormRow label="Trial revival">
						<StaticText focusTarget="isTrialRevivalAllowedByCustomerTeam">
							{adminSettings.isTrialRevivalAllowedByCustomerTeam ? 'allowed' : 'disallowed'}
						</StaticText>
					</FormRow>

					<FormRow label="Websites data clean-up">
						<StaticText focusTarget="daysToDataCleanup">
							{adminSettings.daysToDataCleanup} days after trial expiry
						</StaticText>
					</FormRow>

					<FormRow label="Websites configuration clean-up">
						<StaticText focusTarget="daysToConfigurationCleanup">
							{adminSettings.daysToConfigurationCleanup} days after data clean-up
						</StaticText>
					</FormRow>

					{adminSettings.isTrialRevivalAllowedByCustomerTeam && (
						<FormRow label="Revival">
							<StaticText focusTarget="daysToTrialRevival">
								{adminSettings.daysToTrialRevival} days after trial clean-up
							</StaticText>
						</FormRow>
					)}
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						daysToConfigurationCleanup: adminSettings.daysToConfigurationCleanup,
						daysToDataCleanup: adminSettings.daysToDataCleanup,
						daysToTrialRevival: adminSettings.daysToTrialRevival,
						isTrialExtensionAllowedByCustomerTeam: adminSettings.isTrialExtensionAllowedByCustomerTeam,
						isTrialRevivalAllowedByCustomerTeam: adminSettings.isTrialRevivalAllowedByCustomerTeam,
						time_trial_ends_date: format(parseISO(trialExpirationDate), 'yyyy-MM-dd'),
						time_trial_ends_time: format(parseISO(trialExpirationDate), 'HH:mm'),
					}}
					onSuccess={handleSubmit}
				>
					{({ values }) => (
						<>
							<FormRows>
								<FormRow label="Account phase">
									<StaticText>
										{accountPhase}
									</StaticText>
								</FormRow>

								<FormRow
									description={renderTrialEndWarning(values.time_trial_ends_date, values.time_trial_ends_time, values.days_to_data_cleanup)}
									htmlFor="time_trial_ends_date"
									label="Trial expires at"
								>
									<FieldStatus name="time_trial_ends">
										<TextField
											name="time_trial_ends_date"
											resetButton={false}
											trimValue={true}
											type={TextFieldType.Date}
											width={160}
										/>
										<TextField
											name="time_trial_ends_time"
											resetButton={false}
											trimValue={true}
											type={TextFieldType.Time}
											width={120}
										/>
									</FieldStatus>
								</FormRow>

								<FormRow
									htmlFor="isTrialExtensionAllowedByCustomerTeam"
									label="Trial extension"
								>
									<SelectField
										name="isTrialExtensionAllowedByCustomerTeam"
										options={[
											{
												label: 'automatic',
												name: GraphQL.BooleanOrAutomatic.Automatic,
											},
											{
												label: 'allowed',
												name: GraphQL.BooleanOrAutomatic.Yes,
											},
											{
												label: 'disallowed',
												name: GraphQL.BooleanOrAutomatic.No,
											},
										]}
									/>
								</FormRow>

								<FormRow
									htmlFor="isTrialRevivalAllowedByCustomerTeam"
									label="Trial revival"
								>
									<CheckboxField
										label="allowed"
										name="isTrialRevivalAllowedByCustomerTeam"
										width={false}
									/>
								</FormRow>

								<FormRow
									htmlFor="daysToDataCleanup"
									label="Websites data clean-up"
								>
									<FieldStatus name="daysToDataCleanup">
										<FormSentence>
											<TextField
												attributes={{
													min: 1,
												}}
												name="daysToDataCleanup"
												placeholder="days after trial expiry"
												trimValue={true}
												type={TextFieldType.Number}
												width={150}
											/> days
										</FormSentence>
									</FieldStatus>
								</FormRow>

								<FormRow
									htmlFor="daysToConfigurationCleanup"
									label="Websites configuration clean-up"
								>
									<FieldStatus name="daysToTrialRevival">
										<FormSentence>
											<TextField
												attributes={{
													min: 0,
												}}
												disabled={true}
												name="daysToConfigurationCleanup"
												placeholder="days after data cleanup expiry"
												trimValue={true}
												type={TextFieldType.Number}
												width={150}
											/> days
										</FormSentence>
									</FieldStatus>
								</FormRow>

								{values.isTrialRevivalAllowedByCustomerTeam && (
									<FormRow
										htmlFor="daysToTrialRevival"
										label="Revival allowed after"
									>
										<FieldStatus name="daysToTrialRevival">
											<FormSentence>
												<TextField
													attributes={{
														min: 0,
													}}
													name="daysToTrialRevival"
													placeholder="days after trial clean-up"
													trimValue={true}
													type={TextFieldType.Number}
													width={150}
												/> days
											</FormSentence>
										</FieldStatus>
									</FormRow>
								)}
							</FormRows>

							<ButtonsLayout>
								<CancelButton />
								<SaveSubmitButton />
							</ButtonsLayout>
						</>
					)}
				</Form>
			</EditablePart>
		</>
	);
};



export default AccountAdminTrialPhaseForm;
