import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ChildIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M10.555,11.783a1.022,1.022,0,0,0-.993-1.032.974.974,0,0,0-.966.733.524.524,0,0,1-.509.4.529.529,0,0,1-.508-.381,1,1,0,0,0-1.96.281,2.282,2.282,0,0,0,2.468,2.1A2.282,2.282,0,0,0,10.555,11.783Z"
				fill={color}
			/>
			<path
				d="M17.381,10.783a1,1,0,0,0-.96.719.529.529,0,0,1-.508.381.524.524,0,0,1-.509-.4.975.975,0,0,0-.966-.733,1.022,1.022,0,0,0-.993,1.032,2.282,2.282,0,0,0,2.468,2.1,2.282,2.282,0,0,0,2.468-2.1A1,1,0,0,0,17.381,10.783Z"
				fill={color}
			/>
			<path
				d="M14.806,16.3a1,1,0,0,0-1.37.35A1.852,1.852,0,0,1,12,17.141a1.852,1.852,0,0,1-1.436-.488,1,1,0,0,0-1.72,1.02A3.6,3.6,0,0,0,12,19.141a3.6,3.6,0,0,0,3.156-1.468A1,1,0,0,0,14.806,16.3Z"
				fill={color}
			/>
			<path
				d="M22.567,9.927a.99.99,0,0,1-.379-.574A10.571,10.571,0,0,0,12,1,10.571,10.571,0,0,0,1.812,9.353a1,1,0,0,1-.379.574,3.5,3.5,0,0,0,.474,5.94,1,1,0,0,1,.467.506A10.5,10.5,0,0,0,12,23a10.5,10.5,0,0,0,9.626-6.627,1,1,0,0,1,.467-.506,3.5,3.5,0,0,0,.474-5.94ZM20.8,14.221a1,1,0,0,0-.755.673A8.46,8.46,0,0,1,12,21a8.46,8.46,0,0,1-8.042-6.106,1,1,0,0,0-.755-.673,1.5,1.5,0,0,1-.228-2.876,1,1,0,0,0,.637-.783A8.643,8.643,0,0,1,11.991,3,1.493,1.493,0,0,1,13.44,4.345a1,1,0,0,1-.89,1.09.589.589,0,0,1-.649-.618A1,1,0,0,0,9.95,4.375a2.539,2.539,0,0,0,.9,2.477A2.8,2.8,0,0,0,14.77,6.34a2.972,2.972,0,0,0,.664-2.155.249.249,0,0,1,.366-.241,8.97,8.97,0,0,1,4.588,6.618,1,1,0,0,0,.637.783,1.5,1.5,0,0,1-.228,2.876Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ChildIconBuilder;
