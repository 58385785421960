import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CarouselBlocks from '~/components/patterns/structures/CarouselBlocks';
import DomainsBox from '~/components/logic/platform/DomainsBox';
import HeaderContent from '~/components/logic/platform/HeaderContent';
import IssueCategoriesSidebarSummary from '~/components/app/IssueCategoriesSidebarSummary';
import PlatformIssueCategoriesList from '~/components/logic/issues/issuesCategoriesList/PlatformIssueCategoriesList';
import RobotsTxtBox from '~/components/logic/platform/RobotsTxtBox';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';
import WebVitalsOriginSummaryBlock from '~/components/logic/platform/WebVitalsOriginSummaryBlock';
import XmlSitemapsBox from '~/components/logic/platform/XmlSitemapsBox';



const messages = defineMessages({
	issuesLabel: {
		id: 'ui.platform.mobileNavigation.issues',
	},
	overviewLabel: {
		id: 'ui.platform.mobileNavigation.overview',
	},
});



enum Tab {
	Overview = 0,
	Issues = 1,
}



type Props = {
	fullscreenDetailPanel: any,
	innerPanel: any,
	isHeaderFixed?: boolean,
	websiteId: CK.WebsiteId,
};

const MobileLayout: React.FC<Props> = (props) => {
	const {
		fullscreenDetailPanel,
		innerPanel,
		isHeaderFixed = false,
		websiteId,
	} = props;

	const [activeTab, setActiveTab] = React.useState(Tab.Overview);

	let screenContentOverlay = false;

	if (fullscreenDetailPanel) {
		screenContentOverlay = fullscreenDetailPanel();
	} else if (innerPanel) {
		screenContentOverlay = innerPanel();
	}

	return (
		<ScreenLayout
			contentOverlay={screenContentOverlay}
			header={(
				<ScreenHeader
					footer={(
						<TabNavigation>
							<TabNavigationItem
								isActive={activeTab === Tab.Overview}
								onClickCallback={() => setActiveTab(Tab.Overview)}
								uppercase={true}
							>
								<FormattedMessage {...messages.overviewLabel} />
							</TabNavigationItem>
							<TabNavigationItem
								isActive={activeTab === Tab.Issues}
								onClickCallback={() => setActiveTab(Tab.Issues)}
								uppercase={true}
							>
								<FormattedMessage {...messages.issuesLabel} />
							</TabNavigationItem>
						</TabNavigation>
					)}
				>
					<HeaderContent
						websiteId={websiteId}
					/>
				</ScreenHeader>
			)}
			isHeaderFixed={isHeaderFixed}
		>
			<CarouselBlocks
				activeSlideIndex={activeTab}
				adaptiveHeight={false}
				draggable={true}
				onSlideChangeCallback={setActiveTab}
				selectableText={true}
				swipe={true}
			>
				<ScreenBody>
					<DomainsBox
						websiteId={websiteId}
					/>
					<XmlSitemapsBox
						websiteId={websiteId}
					/>
					<RobotsTxtBox
						websiteId={websiteId}
					/>
					<WebVitalsOriginSummaryBlock
						websiteId={websiteId}
					/>
				</ScreenBody>

				<IssueCategoriesSidebarSummary
					isLoading={false}
					renderIssueCategoriesList={({ isActionable }) => (
						<PlatformIssueCategoriesList
							numberOfPlaceholders={3}
							showActionable={isActionable}
							websiteId={websiteId}
						/>
					)}
				/>
			</CarouselBlocks>
		</ScreenLayout>
	);
};



export default MobileLayout;
