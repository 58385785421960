import React from 'react';

import useFormContext from '~/hooks/useFormContext';

import {
	getRecurly,
} from '~/recurly/main';



type Props = {
	name: 'cvv' | 'month' | 'number' | 'year',
	width?: number,
};

const RecurlyField: React.FC<Props> = (props) => {
	const {
		name,
		width = 280,
	} = props;

	const formContext = useFormContext();

	const formContextOnBlurHandler = formContext.onBlurHandler;
	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnFocusHandler = formContext.onFocusHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const [internalState, setInternalState] = React.useState({
		isFocus: false,
		isValid: false,
		valueLength: 0,
	});

	const internalStateIsFocus = internalState.isFocus;
	const internalStateIsValid = internalState.isValid;
	const internalStateValueLength = internalState.valueLength;

	React.useEffect(
		() => {
			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnUnmountHandler,
			name,
		],
	);

	React.useEffect(
		() => {
			const handleRecurlyChange = (recurlyState) => {
				const {
					fields,
				} = recurlyState;

				const field = fields[name];

				if (!field.focus && internalStateIsFocus) {
					formContextOnBlurHandler(name);
				}

				if (field.focus && !internalStateIsFocus) {
					formContextOnFocusHandler(name);
				}

				if (field.length !== internalStateValueLength || field.valid !== internalStateIsValid) {
					formContextOnChangeHandler(name, {
						isValid: field.valid,
					}, {
						timeout: 250,
					});
				}

				setInternalState({
					isFocus: field.focus,
					isValid: field.valid,
					valueLength: field.length,
				});
			};

			getRecurly((recurly) => {
				recurly.on('change', handleRecurlyChange);
			});

			return () => {
				getRecurly((recurly) => {
					recurly.off('change', handleRecurlyChange);
				});
			};
		},
		[
			formContextOnBlurHandler,
			formContextOnChangeHandler,
			formContextOnFocusHandler,
			internalStateIsFocus,
			internalStateIsValid,
			internalStateValueLength,
			name,
		],
	);

	const style: React.CSSProperties = {};

	if (width) {
		style.width = width;
	}

	return (
		<div style={style}>
			<div data-recurly={name}></div>
		</div>
	);
};



export default RecurlyField;
