import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import {
	type ContextMenuEntry,
} from '~/components/ContextMenuProvider';

import writeToClipboard from '~/utilities/writeToClipboard';



const messages = defineMessages({
	copyFullUrl: {
		id: 'ui.contentOverview.contextMenu.copyFullUrl',
	},
	openPageDetail: {
		id: 'ui.contentOverview.contextMenu.openPageDetail',
	},
	openPageOnWebsite: {
		id: 'ui.contentOverview.contextMenu.openPageOnWebsite',
	},
});



export function openPageDetailItem(url: string): ContextMenuEntry {
	return {
		icon: <BasicIcon type={BasicIconType.NewTab} />,
		label: <FormattedMessage {...messages.openPageDetail} />,
		onClick: () => {
			window.open(url, '_blank');
		},
	};
}



export function openPageOnWebsiteItem(url: string): ContextMenuEntry {
	return {
		icon: <BasicIcon type={BasicIconType.ExternalLink} />,
		label: <FormattedMessage {...messages.openPageOnWebsite} />,
		onClick: () => {
			window.open(url, '_blank', 'noopener,noreferrer');
		},
	};
}



export function copyUrlToClipboardItem(url: string): ContextMenuEntry {
	return {
		icon: <BasicIcon type={BasicIconType.CopyURL} />,
		label: <FormattedMessage {...messages.copyFullUrl} />,
		onClick: () => {
			writeToClipboard(url);
		},
	};
}



export function copyUrlIdToClipboardItem(urlId: string): ContextMenuEntry {
	return {
		icon: <BasicIcon type={BasicIconType.CopyURL} />,
		label: 'Copy URL ID',
		onClick: () => {
			writeToClipboard(urlId);
		},
	};
}
