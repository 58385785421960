import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const OutOfStockCartIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M18,12a6,6,0,1,0,6,6A6.007,6.007,0,0,0,18,12Zm0,2a3.973,3.973,0,0,1,1.725.392.25.25,0,0,1,.068.4l-5,5a.254.254,0,0,1-.219.07.249.249,0,0,1-.183-.139A4,4,0,0,1,18,14Zm0,8a3.981,3.981,0,0,1-1.723-.391.249.249,0,0,1-.069-.4l5-5a.249.249,0,0,1,.4.069A4,4,0,0,1,18,22Z"
				fill={color}
			/>
			<path
				d="M12.657,14a1,1,0,0,0-1-1H8.239A.25.25,0,0,1,8,12.808l-1.15-5A.25.25,0,0,1,7.089,7.5H20.28a.5.5,0,0,1,.474.658L20,10.406a1,1,0,1,0,1.9.633l1-3A1.929,1.929,0,0,0,21.072,5.5H6.514a.249.249,0,0,1-.244-.194L5.392,1.487A1.921,1.921,0,0,0,3.514,0H1A1,1,0,0,0,1,2l2.24-.055a.252.252,0,0,1,.25.194l3.357,14.6a.25.25,0,0,1-.107.265,2.25,2.25,0,1,0,2.286-.1.253.253,0,0,1-.126-.165l-.33-1.437A.25.25,0,0,1,8.813,15h2.844A1,1,0,0,0,12.657,14Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default OutOfStockCartIconBuilder;
