import React from 'react';

import Measurer, {
	type MeasurerChildrenProps,
} from '~/utilities/Measurer';

import {
	type RenderProp,
} from '~/utilities/renderProp';



type Props = {
	children: RenderProp<MeasurerChildrenProps>,
};

const IssueIllustration: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="issue-illustration">
			<Measurer>
				{children}
			</Measurer>
		</div>
	);
};



export default IssueIllustration;

export type {
	MeasurerChildrenProps as IssueIllustrationChildrenProps,
};
