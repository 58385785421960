import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import ClipboardTextField from '~/components/app/ClipboardTextField';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MarginsList from '~/components/atoms/lists/MarginsList';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModal from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import SelectField from '~/components/atoms/forms/components/SelectField';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	useCreateEnrichmentFieldMutation,
} from './CreateEnrichmentFieldModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	validateField,
} from '~/components/app/validations';



enum Steps {
	Setup = 'setup',
	Success = 'success',
}



const messages = defineMessages({
	modalSidebar: {
		id: 'ui.createEnrichmentFieldModal.modalSidebar',
	},
	modalTitle: {
		id: 'ui.createEnrichmentFieldModal.modalTitle',
	},
	setupStepButton: {
		id: 'ui.createEnrichmentFieldModal.setupStepButton',
	},
	setupStepDataTypeLabel: {
		id: 'ui.createEnrichmentFieldModal.setupStepDataTypeLabel',
	},
	setupStepDescription: {
		id: 'ui.createEnrichmentFieldModal.setupStepDescription',
	},
	setupStepNameLabel: {
		id: 'ui.createEnrichmentFieldModal.setupStepNameLabel',
	},
	setupStepNamePlaceholder: {
		id: 'ui.createEnrichmentFieldModal.setupStepNamePlaceholder',
	},
	successStepButton: {
		id: 'ui.createEnrichmentFieldModal.successStepButton',
	},
	successStepDescription: {
		id: 'ui.createEnrichmentFieldModal.successStepDescription',
	},
	successStepHeading: {
		id: 'ui.createEnrichmentFieldModal.successStepHeading',
	},
	successStepSupport: {
		id: 'ui.createEnrichmentFieldModal.successStepSupport',
	},
});

const linkSupport = linkExternal('https://www.contentkingapp.com/support/data-enrichment-api/');



const validateDataType = validateField(
	'dataType',
	(f) => [
		f.validateNonEmpty(),
	],
);

const validateLabel = validateField(
	'label',
	(f) => [
		f.validateNonEmpty(),
		f.validateMaximumLength(100),
		f.customGlobal({
			field: 'label',
			globalRule: 'enrichmentFieldAlreadyExists',
			message: 'Already there',
		}),
	],
);

const validations = {
	validateDataType,
	validateLabel,
};



const CreateEnrichmentFieldModal: React.FC = () => {
	const classicFormBehavior = useClassicFormBehavior();
	const intl = useIntl();
	const websiteId = useWebsiteId();

	const [createEnrichmentField, { data }] = useCreateEnrichmentFieldMutation();

	const modalRef = React.useRef<any>();

	const createdEnrichmentFieldId = data?.CreateEnrichmentField.enrichmentField?.id ?? '';

	const handleSubmit = React.useCallback(
		async (values) => {
			await createEnrichmentField({
				variables: {
					dataType: values.dataType,
					label: values.label,
					websiteId,
				},
			});

			modalRef.current.goToNextStep();
		},
		[
			createEnrichmentField,
			websiteId,
		],
	);

	return (
		<MultiStepModal
			isContentDisabled={false}
			ref={modalRef}
		>
			<MultiStepModalStep
				headerIconType={MultiStepModalStepHeaderIconType.Plus}
				name={Steps.Setup}
				sidebar={(
					<Copy
						{...messages.modalSidebar}
						values={{
							linkSupport,
						}}
					/>
				)}
				title={(
					<FormattedMessage {...messages.modalTitle} />
				)}
			>
				<Form
					defaultFocus="label"
					onSuccess={handleSubmit}
					validations={validations}
				>
					<ModalTextSection>
						<RichText>
							<FormattedMessage {...messages.setupStepDescription} />
						</RichText>
					</ModalTextSection>

					<CenteredFormWrapper>
						<FormRows>
							<FormRow
								htmlFor="label"
								label={(
									<FormattedMessage {...messages.setupStepNameLabel} />
								)}
							>
								<FieldStatus name="validateLabel">
									<TextField
										name="label"
										placeholder={intl.formatMessage(messages.setupStepNamePlaceholder)}
										trimValue={true}
									/>
								</FieldStatus>
							</FormRow>

							<FormRow
								htmlFor="dataType"
								label={(
									<FormattedMessage {...messages.setupStepDataTypeLabel} />
								)}
							>
								<FieldStatus name="validateDataType">
									<SelectField
										isInteractedByDefault={false}
										name="dataType"
										options={[
											{
												name: 'string',
												label: 'String',
											},
											{
												name: 'boolean',
												label: 'Boolean',
											},
											{
												name: 'date',
												label: 'Date',
											},
											{
												name: 'number',
												label: 'Number',
											},
										]}
									/>
								</FieldStatus>
							</FormRow>

							<ButtonsLayout
								alignment={ButtonsLayoutAlignment.Right}
								layout={ButtonsLayoutType.FormRow}
							>
								<CancelButton />

								<SubmitButton>
									<FormattedMessage {...messages.setupStepButton} />
								</SubmitButton>
							</ButtonsLayout>
						</FormRows>
					</CenteredFormWrapper>
				</Form>
			</MultiStepModalStep>

			<MultiStepModalStep
				headerIconType={MultiStepModalStepHeaderIconType.Plus}
				name={Steps.Success}
				title={(
					<FormattedMessage {...messages.modalTitle} />
				)}
			>
				<ConfirmationMessage
					animate={true}
					context={ConfirmationMessageContext.Confirmation}
					ctaButtons={(
						<Button
							onClick={classicFormBehavior.finish}
							style={ButtonStyle.Action}
							uppercase={false}
							width={ButtonWidth.SubmitButton}
						>
							<FormattedMessage {...messages.successStepButton} />
						</Button>
					)}
					message={(
						<FormattedMessage {...messages.successStepHeading} />
					)}
				>
					<MarginsList>
						<Copy
							{...messages.successStepDescription}
						/>

						<ClipboardTextField
							name="createdEnrichmentFieldId"
							value={createdEnrichmentFieldId}
						/>

						<Copy
							{...messages.successStepSupport}
							values={{
								linkSupport,
							}}
						/>
					</MarginsList>
				</ConfirmationMessage>
			</MultiStepModalStep>
		</MultiStepModal>
	);
};



export default CreateEnrichmentFieldModal;
