import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ExportHeaderButton from '~/components/app/ExportHeaderButton';
import HeaderButtonsLayout from '../../atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderOptionsLayout from '../../atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleWithNumericValue, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '../../atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import HeaderTitleLayout from '../../atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';

import {
	ExportType,
} from '~/model/exports';
import Backlink from '~/components/logic/links/Backlink';
import useViewportType from '~/hooks/useViewportType';
import SitemapsListActiveFilter from '~/components/app/SitemapsListActiveFilter';
import HeaderFilterLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderFilterLayout';



const messages = defineMessages({
	exportButton: {
		id: 'ui.exports.type.general.button.openDialog.label',
	},
	title: {
		id: 'ui.screen.sitemapsList.title',
	},
});



type Props = {
	activeFilterClickCallback: (filterProperty: string) => void,
	backlinkCallback?: () => void,
	backlinkLabel?: string,
	numberOfSitemaps: number,
};

const Header: React.FC<Props> = (props) => {
	const {
		activeFilterClickCallback,
		backlinkCallback,
		backlinkLabel,
		numberOfSitemaps,
	} = props;

	const viewportType = useViewportType();

	return (
		<ScreenHeader>
			<HeaderTitleLayout>
				{backlinkCallback && backlinkLabel && (
					<Backlink
						callback={backlinkCallback}
						label={backlinkLabel}
					/>
				)}

				<HeaderTitleWithNumericValue
					size={TITLE_SIZE_SMALL}
					title={(
						<FormattedMessage {...messages.title} />
					)}
					value={numberOfSitemaps}
				/>
			</HeaderTitleLayout>

			<HeaderFilterLayout compactView={viewportType.isSmall || viewportType.isMedium}>
				<SitemapsListActiveFilter
					onFilterClickCallback={activeFilterClickCallback}
				/>
			</HeaderFilterLayout>

			<HeaderOptionsLayout>
				<HeaderButtonsLayout>
					<ExportHeaderButton
						exportType={ExportType.SitemapsList}
						linkLabel={(
							<FormattedMessage {...messages.exportButton} />
						)}
					/>
				</HeaderButtonsLayout>
			</HeaderOptionsLayout>
		</ScreenHeader>
	);
};



export default React.memo(Header);
