import React from 'react';

import WebsiteAdminFetchingSettingNumericFieldRow, {
	WebsiteAdminFetchingSettingNumericFieldRowUnit,
} from '~/components/app/WebsiteAdminFetchingSettingNumericFieldRow';



type Props = {
	name: string,
};

const WebsiteAdminFetchingSettingMainRequestTimeoutFieldRow: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	return (
		<WebsiteAdminFetchingSettingNumericFieldRow
			autoValue={10000}
			label="Time-out"
			name={name}
			unit={WebsiteAdminFetchingSettingNumericFieldRowUnit.Millisecond}
		/>
	);
};



export default WebsiteAdminFetchingSettingMainRequestTimeoutFieldRow;
