import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import DestructConfirmationModal from '~/components/app/DestructConfirmationModal';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';
import SmallImageLabel from '~/components/patterns/images/SmallImageLabel';
import WebsiteFavicon, {
	WebsiteFaviconUsageContext,
} from '~/components/logic/websites/WebsiteFavicon';

import {
	useDeleteWebsiteMutation,
} from './RemoveWebsiteConfirmationModal.gql';

import useAccountClientConnections from '~/hooks/useAccountClientConnections';
import useAccountId from '~/hooks/useAccountId';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useNavigation from '~/hooks/useNavigation';
import useWebsiteAccountName from '~/hooks/useWebsiteAccountName';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteDomain from '~/hooks/useWebsiteDomain';
import useWebsiteLogo from '~/hooks/useWebsiteLogo';

import {
	notEmpty,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	confirmButton: {
		id: 'ui.removeWebsiteConfirmation.confirmButton',
	},
	description: {
		id: 'ui.removeWebsiteConfirmation.description',
	},
	labelDomain: {
		id: 'ui.removeWebsiteConfirmation.labelDomain',
	},
	labelFromAccount: {
		id: 'ui.removeWebsiteConfirmation.labelFromAccount',
	},
	labelWebsiteName: {
		id: 'ui.removeWebsiteConfirmation.labelWebsiteName',
	},
	title: {
		id: 'ui.removeWebsiteConfirmation.title',
	},
	verb: {
		id: 'ui.removeWebsiteConfirmation.verb',
	},
	warnPermanent: {
		id: 'ui.removeWebsiteConfirmation.warnPermanent',
	},
	yourAccount: {
		id: 'ui.removeWebsiteConfirmation.yourAccount',
	},
});



type Props = {
	websiteId: CK.WebsiteId | null,
};

const RemoveWebsiteConfirmationModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const navigation = useNavigation();

	const accountId = useAccountId();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const accountClientConnections = useAccountClientConnections(effectiveHomeAccountId);
	const accountName = useWebsiteAccountName(websiteId);
	const displayName = useWebsiteDisplayName(websiteId);
	const domain = useWebsiteDomain(websiteId);
	const websiteLogo = useWebsiteLogo(websiteId);

	const showFromAccount = accountClientConnections.count > 0;

	const [deleteWebsite] = useDeleteWebsiteMutation();

	const handleDeletion = React.useCallback(
		async () => {
			if (
				accountId === null
				|| websiteId === null
			) {
				return;
			}

			await deleteWebsite({
				variables: {
					accountId,
					websiteId,
				},
			});

			navigation.navigate({
				routeName: 'account.websites',
				routeParams: {
					accountId: navigation.keepParameter(),
				},
			});
		},
		[
			accountId,
			deleteWebsite,
			navigation,
			websiteId,
		],
	);

	return (
		<DestructConfirmationModal
			confirmButton={(
				<FormattedMessage {...messages.confirmButton} />
			)}
			confirmationPhrase={displayName}
			description={(
				<FormattedMessage {...messages.description} />
			)}
			onConfirmation={handleDeletion}
			titleAction={(
				<FormattedMessage {...messages.verb} />
			)}
			titleObject={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Copy {...messages.warnPermanent} />

			<LabeledValuesBox
				items={[
					{
						label: <FormattedMessage {...messages.labelWebsiteName} />,
						value: (
							websiteLogo !== null ? (
								<SmallImageLabel
									image={(
										<WebsiteFavicon
											usageContext={WebsiteFaviconUsageContext.List}
											websiteId={websiteId}
										/>
									)}
									label={displayName}
								/>
							) : (
								displayName
							)
						),
					},
					{
						label: <FormattedMessage {...messages.labelDomain} />,
						value: domain,
					},
					showFromAccount ? {
						label: <FormattedMessage {...messages.labelFromAccount} />,
						value: (
							accountName ?? <FormattedMessage {...messages.yourAccount} />
						),
					} : null,
				].filter(notEmpty)}
			/>
		</DestructConfirmationModal>
	);
};



export default RemoveWebsiteConfirmationModal;
