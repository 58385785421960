import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SwitchAccountIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				transform="translate(.625 .522727)"
			>
				<path
					d="m26 26h-26v-26h26z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				>
					<path d="m19.922825 16.4177c-.9823125 2.734875-3.7106875 4.70275-6.9225 4.70275-4.0389375 0-7.3125-3.1094375-7.3125-6.94525" />
					<path d="m6.077175 9.57255c.9823125-2.734875 3.7106875-4.70275 6.9225-4.70275 4.0389375 0 7.3125 3.1094375 7.3125 6.94525" />
					<path d="m22.769175 8.64849375-2.458625 3.23456255-3.23375-2.45862505" />
					<path d="m3.04744375 17.2264625 2.614625-3.1094375 3.1094375 2.614625" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default SwitchAccountIconBuilder;

