import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import BulkActionsController from '~/components/patterns/ctaElements/BulkActionsController';
import Button from '~/components/patterns/buttons/Button';
import DeleteAlertDefinitionConfirmationModal from '~/components/app/DeleteAlertDefinitionConfirmationModal';

import useAlertTypeDefinitions from '~/hooks/useAlertTypeDefinitions';
import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useModals from '~/hooks/useModals';
import useWebsiteAlertDefinitions from '~/hooks/useWebsiteAlertDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	delete: {
		id: 'ui.alertsConfiguration.buttons.delete.label',
	},
	edit: {
		id: 'ui.forms.editLink',
	},
	editMessagingApp: {
		id: 'ui.alertsConfiguration.buttons.editMessagingApp.label',
	},
	editRecipients: {
		id: 'ui.alertsConfiguration.buttons.editRecipients.label',
	},
	editScope: {
		id: 'ui.alertsConfiguration.buttons.editScope.label',
	},
	editSensitivity: {
		id: 'ui.alertsConfiguration.buttons.editSensitivity.label',
	},
	label: {
		id: 'ui.alertsConfiguration.actionsController.label',
	},
});



type Props = {
	selectedAlertDefinitionIds: ReadonlyArray<string>,
};


const ActionsController: React.FC<Props> = (props) => {
	const {
		selectedAlertDefinitionIds,
	} = props;

	const websiteId = useWebsiteId();

	const alertDefinitions = useWebsiteAlertDefinitions(websiteId);
	const alertTypeDefinitions = useAlertTypeDefinitions();
	const modals = useModals();

	const isAllowedToManageDefinitions = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageAlertDefinitions,
	);

	if (alertTypeDefinitions.isLoaded === false) {
		return null;
	}

	function openDeleteAlertDefinitionConfirmationModal() {
		modals.openModal(
			() => (
				<DeleteAlertDefinitionConfirmationModal
					selectedAlertDefinitionIds={selectedAlertDefinitionIds}
				/>
			),
		);
	}

	const actionButtons: Array<React.ReactNode> = [];
	const destructiveButtons: Array<React.ReactNode> = [];

	if (selectedAlertDefinitionIds.length === 1) {
		actionButtons.push((
			<Button
				icon={(
					<BasicIcon type={BasicIconType.CogWheel} />
				)}
				key="edit-recipients"
				linkRouteName="website.alerts.configuration.editDefinition"
				linkRouteParams={{
					alertDefinitionId: selectedAlertDefinitionIds[0],
					websiteId,
				}}
			>
				<FormattedMessage {...messages.edit} />
			</Button>
		));

		if (isAllowedToManageDefinitions.yes) {
			destructiveButtons.push((
				<Button
					icon={(
						<BasicIcon type={BasicIconType.Delete} />
					)}
					key="delete"
					onClick={openDeleteAlertDefinitionConfirmationModal}
				>
					<FormattedMessage {...messages.delete} />
				</Button>
			));
		}
	} else {
		const hasScope = selectedAlertDefinitionIds.some(
			(alertDefinitionId) => {
				const alertDefinition = alertDefinitions.getById(alertDefinitionId);

				if (alertDefinition === null) {
					return false;
				}

				const alertTypeDefinition = alertTypeDefinitions.getByType(alertDefinition.alertType);

				if (alertTypeDefinition === null) {
					return false;
				}

				return alertTypeDefinition.hasScope;
			},
		);

		const hasSensitivity = selectedAlertDefinitionIds.some(
			(alertDefinitionId) => {
				const alertDefinition = alertDefinitions.getById(alertDefinitionId);

				if (alertDefinition === null) {
					return false;
				}

				const alertTypeDefinition = alertTypeDefinitions.getByType(alertDefinition.alertType);

				if (alertTypeDefinition === null) {
					return false;
				}

				return alertTypeDefinition.hasSensitivity;
			},
		);

		if (isAllowedToManageDefinitions.yes) {
			if (hasScope) {
				actionButtons.push((
					<Button
						icon={(
							<BasicIcon type={BasicIconType.Globe} />
						)}
						key="edit-scope"
						linkRouteName="website.alerts.configuration.editDefinitionsScope"
						linkRouteParams={{
							alertDefinitionIds: selectedAlertDefinitionIds.join(','),
							websiteId,
						}}
					>
						<FormattedMessage {...messages.editScope} />
					</Button>
				));
			}

			if (hasSensitivity) {
				actionButtons.push((
					<Button
						icon={(
							<BasicIcon type={BasicIconType.Sensitivity} />
						)}
						key="edit-sensitivity"
						linkRouteName="website.alerts.configuration.editDefinitionsSensitivity"
						linkRouteParams={{
							alertDefinitionIds: selectedAlertDefinitionIds.join(','),
							websiteId,
						}}
					>
						<FormattedMessage {...messages.editSensitivity} />
					</Button>
				));
			}
		}

		actionButtons.push((
			<Button
				icon={(
					<BasicIcon type={BasicIconType.People} />
				)}
				key="edit-recipients"
				linkRouteName="website.alerts.configuration.editDefinitionsRecipients"
				linkRouteParams={{
					alertDefinitionIds: selectedAlertDefinitionIds.join(','),
					websiteId,
				}}
			>
				<FormattedMessage {...messages.editRecipients} />
			</Button>
		));

		if (isAllowedToManageDefinitions.yes) {
			actionButtons.push((
				<Button
					icon={(
						<BasicIcon type={BasicIconType.MessagingApp} />
					)}
					key="delete"
					linkRouteName="website.alerts.configuration.editDefinitionsMessagingApp"
					linkRouteParams={{
						alertDefinitionIds: selectedAlertDefinitionIds.join(','),
						websiteId,
					}}
				>
					<FormattedMessage {...messages.editMessagingApp} />
				</Button>
			));

			destructiveButtons.push((
				<Button
					icon={(
						<BasicIcon type={BasicIconType.Delete} />
					)}
					key="delete"
					onClick={openDeleteAlertDefinitionConfirmationModal}
				>
					<FormattedMessage {...messages.delete} />
				</Button>
			));
		}
	}

	return (
		<BulkActionsController
			actionButtons={actionButtons}
			destructiveButtons={destructiveButtons}
			label={(
				<FormattedMessage
					{...messages.label}
					values={{
						count__definitions: selectedAlertDefinitionIds.length,
					}}
				/>
			)}
		/>
	);
};



export default ActionsController;
