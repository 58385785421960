/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImpersonateUserMutationVariables = GraphQL.Exact<{
  enableSearch: GraphQL.Scalars['Boolean']['input'];
  identifier: GraphQL.Scalars['String']['input'];
}>;


export type ImpersonateUserMutation = { readonly __typename?: 'Mutation', readonly ImpersonateUser: { readonly __typename?: 'ImpersonateUserResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly isImpersonated: boolean, readonly kingdomAdmin: { readonly __typename?: 'User', readonly email: string, readonly firstName: string | null, readonly id: string, readonly legacyId: string } | null, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string } } | null }, readonly suggestedImpersonations: ReadonlyArray<{ readonly __typename?: 'SuggestedImpersonation', readonly accountId: CK.AccountId, readonly accountPhase: GraphQL.AccountPhase, readonly accountState: string, readonly email: string, readonly numberOfHomeWebsites: number, readonly role: GraphQL.UserRole, readonly signature: string, readonly memberships: ReadonlyArray<{ readonly __typename?: 'SuggestedImpersonationMembership', readonly accountId: number, readonly accountPhase: GraphQL.AccountPhase, readonly accountState: string, readonly numberOfHomeWebsites: number, readonly role: GraphQL.UserRole }> }> | null } };


export const ImpersonateUserDocument = gql`
    mutation ImpersonateUser($enableSearch: Boolean!, $identifier: String!) {
  ImpersonateUser(enableSearch: $enableSearch, identifier: $identifier) {
    query {
      authenticatedSession {
        isImpersonated
        kingdomAdmin {
          email
          firstName
          id
          legacyId
        }
        user {
          email
          id
          legacyId
        }
      }
    }
    suggestedImpersonations {
      accountId
      accountPhase
      accountState
      email
      memberships {
        accountId
        accountPhase
        accountState
        numberOfHomeWebsites
        role
      }
      numberOfHomeWebsites
      role
      signature
    }
  }
}
    `;
export type ImpersonateUserMutationFn = Apollo.MutationFunction<ImpersonateUserMutation, ImpersonateUserMutationVariables>;

/**
 * __useImpersonateUserMutation__
 *
 * To run a mutation, you first call `useImpersonateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateUserMutation, { data, loading, error }] = useImpersonateUserMutation({
 *   variables: {
 *      enableSearch: // value for 'enableSearch'
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useImpersonateUserMutation(baseOptions?: Apollo.MutationHookOptions<ImpersonateUserMutation, ImpersonateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImpersonateUserMutation, ImpersonateUserMutationVariables>(ImpersonateUserDocument, options);
      }
export type ImpersonateUserMutationHookResult = ReturnType<typeof useImpersonateUserMutation>;
export type ImpersonateUserMutationResult = Apollo.MutationResult<ImpersonateUserMutation>;
export type ImpersonateUserMutationOptions = Apollo.BaseMutationOptions<ImpersonateUserMutation, ImpersonateUserMutationVariables>;