import classNames from 'classnames';
import React from 'react';



export enum ColorizerStyle {
	Critical = 'critical',
	Highlight = 'highlight',
	History = 'history',
	Important = 'important',
	Inherited = 'inherited',
	Notice = 'notice',
	Premium = 'premium',
	Remark = 'remark',
	Success = 'success',
	Warning = 'warning',
}

type Props = {
	children?: React.ReactNode,
	/** UI color style we want to apply on selected text */
	style: ColorizerStyle,
};



const Colorizer: React.FC<Props> = (props) => {
	const {
		children,
		style = ColorizerStyle.Inherited,
	} = props;

	const componentClasses = classNames({
		'colorizer': true,
		['colorizer--' + style + '-style']: true,
	});

	return (
		<span className={componentClasses}>
			{children}
		</span>
	);
};



export default Colorizer;
