import classNames from 'classnames';
import React from 'react';

import styles from './ValueUpdateComparison.module.scss';



export enum ValueUpdateComparisonHighlightType {
	Added = 'added',
	Changed = 'changed',
	Error = 'error',
	Extracted = 'extracted',
	Info = 'info',
	None = 'none',
	Ok = 'ok',
	Removed = 'removed',
	UnreliableResponse = 'unreliable-response',
}



type Props = {
	/** Visual highlight of update */
	highlightType?: ValueUpdateComparisonHighlightType,
	prevValue?: React.ReactNode,
	value: React.ReactNode,
};

const ValueUpdateComparison: React.FC<Props> = (props) => {
	const {
		highlightType,
		prevValue,
		value,
	} = props;

	const valueHighlightClasses = classNames({
		[styles.valueHighlight]: true,
		[styles['valueHighlight--type-' + highlightType] ?? '']: highlightType,
	});

	const newValueClasses = classNames({
		[styles.value]: true,
		[styles.isNew]: true,
	});

	const previousValueClasses = classNames({
		[styles.value]: true,
		[styles.isPrevious]: true,
	});

	return (
		<div>
			<div className={newValueClasses}>
				<div className={valueHighlightClasses}>
					<span>
						{value}
					</span>
				</div>
			</div>
			{prevValue && (
				<div className={previousValueClasses}>
					<div className={styles.valueHighlight}>
						<span>
							{prevValue}
						</span>
					</div>
				</div>
			)}
		</div>
	);
};



export default ValueUpdateComparison;
