import React from 'react';



type FieldRowBuilderContextType = {
	isEditable: boolean | null,
	renderDisplay: ((display: React.ReactNode) => React.ReactElement) | null,
	renderField: ((field: React.ReactNode) => React.ReactElement) | null,
};

const FieldRowBuilderContext = React.createContext<FieldRowBuilderContextType | null>(null);



type Props = {
	children: React.ReactElement,
	isEditable?: boolean,
	renderDisplay?: (display: React.ReactNode) => React.ReactElement,
	renderField?: (field: React.ReactNode) => React.ReactElement,
};

const FieldRowBuilder: React.FC<Props> = (props) => {
	const {
		children,
		isEditable,
		renderDisplay,
		renderField,
	} = props;

	const fieldRowBuilderContext = React.useMemo(
		() => ({
			isEditable: isEditable ?? null,
			renderDisplay: renderDisplay ?? null,
			renderField: renderField ?? null,
		}),
		[
			isEditable,
			renderDisplay,
			renderField,
		],
	);

	return (
		<FieldRowBuilderContext.Provider value={fieldRowBuilderContext}>
			{children}
		</FieldRowBuilderContext.Provider>
	);
};



export default FieldRowBuilder;

export {
	FieldRowBuilderContext,
};
