import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import LabeledValue from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import PerformanceScore, {
	PerformanceScoreStatus,
} from '~/components/patterns/webVitals/PerformanceScore';



const messages = defineMessages({
	label: {
		id: 'ui.pageDetail.lighthouse.performance.label',
	},
});



type Props = {
	range: GraphQL.WebVitalRange | undefined,
	value: number | undefined,
};

const LighthousePerformanceScore: React.FC<Props> = (props) => {
	const {
		range,
		value,
	} = props;

	let status: PerformanceScoreStatus | undefined = undefined;

	if (range === GraphQL.WebVitalRange.Good) {
		status = PerformanceScoreStatus.Good;
	} else if (range === GraphQL.WebVitalRange.NeedsImprovement) {
		status = PerformanceScoreStatus.NeedsImprovement;
	} else if (range === GraphQL.WebVitalRange.Bad) {
		status = PerformanceScoreStatus.Poor;
	}

	return (
		<LabeledValue
			label={(
				<FormattedMessage {...messages.label} />
			)}
		>
			<PerformanceScore
				status={status}
				value={value}
			/>
		</LabeledValue>
	);
};



export default LighthousePerformanceScore;
