import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountPhaseQuery,
} from './useAccountPhase.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountPhase(accountId: CK.AccountId | null): GraphQL.Account['phase'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountPhaseQuery,
		accountId,
	);

	return data?.account?.phase ?? null;
}



export default useAccountPhase;
