import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import Copy from '~/components/logic/Copy';
import {
	type AlertGroup,
} from './CreateAlertDefinitionsModal';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import Form from '~/components/atoms/forms/basis/Form';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import RadioList from '~/components/atoms/forms/components/RadioList';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';

import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';

import {
	ALERT_GROUP_CUSTOM_ELEMENT_ALERTS,
	ALERT_GROUP_ENRICHMENT_FIELD_ALERTS,
	ALERT_GROUP_ENTERED_OR_LEFT_ALERTS,
	ALERT_GROUP_LIGHTHOUSE_ALERTS,
	ALERT_GROUP_PAGE_ALERTS,
	ALERT_GROUP_PLATFORM_ALERTS,
} from '~/model/alerts';



const messages = defineMessages({
	breadcrumb: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.breadcrumb',
	},
	continue: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.continue',
	},
	description: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.description',
	},
	sidebar: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.sidebar',
	},
	title: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.title',
	},
});

const messagesOptions = defineMessages({
	[ALERT_GROUP_CUSTOM_ELEMENT_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.options.custom_element_alerts',
	},
	[ALERT_GROUP_ENRICHMENT_FIELD_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.options.enrichmentFieldAlerts',
	},
	[ALERT_GROUP_ENTERED_OR_LEFT_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.options.entered_or_left_alerts',
	},
	[ALERT_GROUP_LIGHTHOUSE_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.options.lighthouse_alerts',
	},
	[ALERT_GROUP_PAGE_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.options.page_alerts',
	},
	[ALERT_GROUP_PLATFORM_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertGroup.options.platform_alerts',
	},
});

const validations = {
	alertGroup: [
		{
			message: '',
			field: 'alertGroup',
			rule: ({ values, name }) => {
				return values[name] !== null;
			},
		},
	],
};



type Props = {
	alertGroup: AlertGroup | null,
	onContinue: (alertGroup: AlertGroup) => void,
};

const SelectAlertGroupStep: React.FC<Props> = (props) => {
	const {
		alertGroup,
		onContinue,
	} = props;

	const {
		isFeatureAttainable: isCustomElementsFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.CustomElements_2);

	const {
		isFeatureAttainable: isEnrichmentFieldsFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.EnrichmentFields_5);

	const {
		isFeatureAttainable: isLighthouseMonitoringFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LighthouseMonitoring);

	return (
		<MultiStepModalStep
			breadcrumb={(
				<FormattedMessage {...messages.breadcrumb} />
			)}
			headerIconType={MultiStepModalStepHeaderIconType.Plus}
			name="selectAlertGroup"
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							link_article: (chunks) => (
								<ExternalLink
									href="https://www.contentkingapp.com/support/configuring-alerts/"
									key="link"
								>
									{chunks}
								</ExternalLink>
							),
						}}
					/>
				</RichText>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Form
				defaultDataHasChanged={true}
				defaultValues={{
					alertGroup,
				}}
				key="select-alert-group"
				onSuccess={(values) => onContinue(values.alertGroup)}
				validations={validations}
			>
				<ModalTextSection>
					<FormattedMessage {...messages.description} />
				</ModalTextSection>
				<ModalTextSection>
					<RadioList
						items={[
							{
								label: <FormattedMessage {...messagesOptions[ALERT_GROUP_PAGE_ALERTS]} />,
								value: ALERT_GROUP_PAGE_ALERTS,
							},
							{
								label: <FormattedMessage {...messagesOptions[ALERT_GROUP_PLATFORM_ALERTS]} />,
								value: ALERT_GROUP_PLATFORM_ALERTS,
							},
							{
								label: <FormattedMessage {...messagesOptions[ALERT_GROUP_ENTERED_OR_LEFT_ALERTS]} />,
								value: ALERT_GROUP_ENTERED_OR_LEFT_ALERTS,
							},
							{
								label: <FormattedMessage {...messagesOptions[ALERT_GROUP_CUSTOM_ELEMENT_ALERTS]} />,
								value: ALERT_GROUP_CUSTOM_ELEMENT_ALERTS,
								omit: isCustomElementsFeatureAttainable === false,
							},
							{
								label: <FormattedMessage {...messagesOptions[ALERT_GROUP_ENRICHMENT_FIELD_ALERTS]} />,
								value: ALERT_GROUP_ENRICHMENT_FIELD_ALERTS,
								omit: isEnrichmentFieldsFeatureAttainable === false,
							},
							{
								label: <FormattedMessage {...messagesOptions[ALERT_GROUP_LIGHTHOUSE_ALERTS]} />,
								value: ALERT_GROUP_LIGHTHOUSE_ALERTS,
								omit: isLighthouseMonitoringFeatureAttainable === false,
							},
						].filter((option) => !option.omit)}
						name="alertGroup"
						width={false}
					/>

					<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.continue} />
						</SubmitButton>
					</ModalButtonsLayout>
				</ModalTextSection>
			</Form>
		</MultiStepModalStep>
	);
};



export default SelectAlertGroupStep;
