import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AppearAnimation from '~/components/patterns/animations/AppearAnimation';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import ConfirmationPanel from '~/components/patterns/modals/ConfirmationPanel';
import ExternalLink, {
	ExternalLinkStyle,
} from '~/components/patterns/links/ExternalLink';
import Small from '~/components/patterns/typography/Small';
import Spinner from '~/components/patterns/loaders/Spinner';

import useLegalDocument, {
	LegalDocument,
} from '~/hooks/useLegalDocument';



export { LegalDocument };



const messages = defineMessages({
	[LegalDocument.DataProcessingAgreement]: {
		id: 'ui.legalDocumentPopup.dataProcessingAgreement',
	},
	[LegalDocument.PrivacyPolicy]: {
		id: 'ui.legalDocumentPopup.privacyPolicy',
	},
	[LegalDocument.TermsOfUse]: {
		id: 'ui.legalDocumentPopup.termsOfUse',
	},
	continue: {
		id: 'ui.legalDocumentPopup.continue',
	},
	saveAsPdf: {
		id: 'ui.legalDocumentPopup.saveAsPdf',
	},
});



type Props = {
	onClose: () => void,
	type: LegalDocument,
};

const LegalDocumentPopup: React.FC<Props> = (props) => {
	const {
		onClose,
		type,
	} = props;

	const document = useLegalDocument(type);

	return (
		<AppearAnimation stretched={true}>
			<ConfirmationPanel
				ctaElement={(
					<ButtonsGroup>
						{document.pdf !== null && (
							<Button
								href={document.pdf}
								style={ButtonStyle.Hollow}
							>
								<FormattedMessage {...messages.saveAsPdf} />
							</Button>
						)}
						<Button
							onClick={() => onClose()}
							style={ButtonStyle.Action}
						>
							<FormattedMessage {...messages.continue} />
						</Button>
					</ButtonsGroup>
				)}
				title={(
					<>
						<FormattedMessage {...messages[type]} />

						{document.article !== null && (
							<Small>
								<ExternalLink
									children=""
									href={document.article}
									style={ExternalLinkStyle.Decent}
								/>
							</Small>
						)}
					</>
				)}
			>
				{document.content !== null ? (
					document.content
				) : (
					<Spinner />
				)}
			</ConfirmationPanel>
		</AppearAnimation>
	);
};

export default LegalDocumentPopup;
