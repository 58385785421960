import React from 'react';

import SimpleBox from '~/components/patterns/boxes/SimpleBox';



type Props = {
	/** Billing cycle text */
	children: React.ReactNode,
};



const BillingCycleSwitchBox: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<SimpleBox paddingSize={1}>
			<div className="billing-cycle-switch">
				{children}
			</div>
		</SimpleBox>
	);
};



export default BillingCycleSwitchBox;
