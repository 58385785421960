import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ProfileIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g transform="translate(-18.000000, -821.000000)">
				<g transform="translate(0.000000, 766.000000)">
					<g
						id="Teams"
						transform="translate(0.000000, 46.000000)"
					>
						<g
							id="Group-7_1_"
							transform="translate(19.000000, 10.000000)"
						>
							<path
								d="M15.2,18.2c-1-0.2-1.7-1.2-1.5-2.2c0.2-1,1.2-1.7,2.3-1.5c1,0.2,1.7,1.2,1.5,2.2
								C17.2,17.7,16.2,18.4,15.2,18.2z M20.4,18c0.2-0.6,0.2-0.7,0.3-1.3l1.6-0.8c0.1-0.1,0.2-0.3,0.1-0.4l-0.9-2.6
								c0-0.1-0.2-0.2-0.4-0.1l-1.8,0.4c-0.5-0.5-0.5-0.5-1-0.9l0.1-1.8c0-0.1-0.1-0.4-0.2-0.4l-2.7-0.5c-0.1,0-0.4,0.1-0.4,0.2
								l-0.6,1.7c-0.7,0.2-0.7,0.2-1.3,0.5l-1.5-1c-0.1-0.1-0.4-0.1-0.5,0L9.4,13c-0.1,0.1,0,0.3,0.1,0.4l1.2,1.4
								c-0.2,0.6-0.2,0.7-0.3,1.3l-1.7,0.8c-0.2,0.1-0.2,0.3-0.1,0.4l0.9,2.6c0,0.1,0.3,0.2,0.4,0.1l1.8-0.3c0.4,0.4,0.5,0.5,1,0.9
								l-0.1,1.8c0,0.1,0.1,0.3,0.3,0.3l2.7,0.5c0.1,0,0.4-0.1,0.4-0.2l0.6-1.7c0.7-0.2,0.7-0.2,1.3-0.5l1.6,1c0.1,0.1,0.4,0,0.4-0.1
								l1.8-2.1c0.1-0.1,0.1-0.3,0-0.4L20.4,18z"
								fill="none"
								id="Stroke-1_1_"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							/>
							<path
								d="M7.1,8.3c-3.2,0.8-5.5,3.7-5.5,7.1h4.7"
								fill="none"
								id="Stroke-3_1_"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							/>
							<path
								d="M12.8,4.7c0,2.2-1.8,4-4,4c-2.2,0-4-1.8-4-4s1.8-4,4-4C11,0.7,12.8,2.5,12.8,4.7z"
								fill="none"
								id="Stroke-5_1_"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							/>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default ProfileIconBuilder;

