import React from 'react';



type Props = {
	children: React.ReactNode,
};

const FilterDropdownButtons: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="filter-dropdown-buttons">
			{React.Children.map(
				children,
				(child) => {
					if (!child) {
						return false;
					}

					return (
						<div className="filter-dropdown-buttons__button">
							{child}
						</div>
					);
				},
			)}
		</div>
	);
};



export default FilterDropdownButtons;
