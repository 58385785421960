import React from 'react';

import DestructConfirmationModalForm from '~/components/app/DestructConfirmationModalForm';
import HeaderTitleWithValue, {
	SIZE_MODAL as TITLE_SIZE_MODAL,
	STYLE_CRITICAL as TITLE_STYLE_CRITICAL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalPanel, {
	SIZE_MEDIUM as MODAL_SIZE_MEDIUM,
} from '~/components/atoms/panels/ModalPanel';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



type Props = {
	children?: React.ReactNode,
	confirmationPhrase?: string | null,
	confirmButton: React.ReactNode,
	description?: React.ReactNode,
	disabled?: boolean,
	globalFormErrors?: React.ComponentProps<typeof DestructConfirmationModalForm>['globalFormErrors'],
	loading?: boolean,
	onConfirmation?: () => void | Promise<void>,
	titleAction: React.ReactNode,
	titleObject: React.ReactNode,
};

const DestructConfirmationModal: React.FC<Props> = (props) => {
	const {
		children,
		confirmationPhrase,
		confirmButton,
		description,
		disabled,
		globalFormErrors,
		loading,
		onConfirmation,
		titleAction,
		titleObject,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();

	return (
		<ModalPanel
			onCloseCallback={classicFormBehavior.cancel}
			size={MODAL_SIZE_MEDIUM}
		>
			<ModalContainer
				gapsSize={2}
				header={(
					<ModalHeader
						iconType={ModalHeaderIconType.Delete}
						title={(
							<HeaderTitleWithValue
								size={TITLE_SIZE_MODAL}
								style={TITLE_STYLE_CRITICAL}
								title={titleAction}
								value={titleObject}
							/>
						)}
					/>
				)}
				headerGapsSize={1}
			>
				<DestructConfirmationModalForm
					confirmButton={confirmButton}
					confirmationPhrase={confirmationPhrase}
					description={description}
					disabled={disabled}
					globalFormErrors={globalFormErrors}
					loading={loading}
					onConfirmation={onConfirmation}
				>
					{children}
				</DestructConfirmationModalForm>
			</ModalContainer>
		</ModalPanel>
	);
};



export default DestructConfirmationModal;
