import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const FlagIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M23.382,2.174a1.586,1.586,0,0,0-1.616-.1,12.136,12.136,0,0,1-4.126.885A18.636,18.636,0,0,1,12.9,1.98C9.543,1,8.38.768,5.806,1.1A1.5,1.5,0,0,0,4.5,2.59V17.61A1.5,1.5,0,0,0,6.172,19.1c2.255-.259,3.305-.039,6.442.879A19.314,19.314,0,0,0,17.64,21a14.09,14.09,0,0,0,5.08-1.152c.672-.256,1.28-.6,1.28-1.528V3.4A1.5,1.5,0,0,0,23.382,2.174Z"
				fill={color}
			/>
			<path
				d="M1.5,0A1.5,1.5,0,0,0,0,1.5v21a1.5,1.5,0,0,0,3,0V1.5A1.5,1.5,0,0,0,1.5,0Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default FlagIconBuilder;
