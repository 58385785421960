import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const HistoryIconBuilder: IconBuilder = {
	icon: ({ color = '#00B2B9' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="M24 24L0 24 0 0 24 0z"
					fill="none"
				/>
				<path
					d="M3.75 12A8.25 8.25 0 1 1 12 20.25"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12 6.75L12 12.1409138 15 15"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
					fill={color}
				/>
				<path
					d="M3.75 12L5.785 10.137"
					fill={color}
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M2.293 12L3.785 9.65"
					fill={color}
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					transform="matrix(-1 0 0 1 6.078 0)"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default HistoryIconBuilder;

