import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import SitemapsTable from './SitemapsTable';

import {
	loadData as loadSitemaps,
	selector as sitemapsSelector,
} from '~/state/sitemapsViolatingFilesizeLimit';



const messages = defineMessages({
	sizeLabel: {
		id: 'ui.screen.sitemapsList.headings.size.label',
	},
	sizeValue: {
		id: 'ui.screen.sitemapsList.headings.size.value',
	},
	url: {
		id: 'ui.screen.sitemapsList.headings.sitemap.label',
	},
});



type Props = {
	tableWidth: number,
};

const SitemapsViolatingFilesizeLimitTable: React.FC<Props> = (props) => {
	const {
		tableWidth,
	} = props;

	const sitemaps = useSelector(sitemapsSelector);

	const urlColumnWidth = Math.floor(tableWidth * 0.8);

	return (
		<SitemapsTable
			columns={[
				{
					render: {
						cell: ({ row }) => row.url,
						header: () => <FormattedMessage {...messages.url} />,
					},
					width: urlColumnWidth,
				},
				{
					render: {
						cell: ({ row }) => {
							const sizeUncompressed = row.size_uncompressed;
							const sizeInMegabytes = Math.round(sizeUncompressed / 1024 / 1024);

							return (
								<FormattedMessage
									{...messages.sizeValue}
									values={{
										size: sizeInMegabytes,
									}}
								/>
							);
						},
						header: () => <FormattedMessage {...messages.sizeLabel} />,
					},
					width: tableWidth - urlColumnWidth - 2,
				},
			]}
			loadData={loadSitemaps}
			sitemaps={sitemaps}
			tableWidth={tableWidth}
		/>
	);
};



export default SitemapsViolatingFilesizeLimitTable;
