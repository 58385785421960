/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConversionsBlockQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ConversionsBlockQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageType: GraphQL.PageType, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly analyticServices: ReadonlyArray<{ readonly __typename?: 'AnalyticServiceItemsCollection', readonly type: GraphQL.AnalyticServiceType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'NullableContentItem', readonly content: string | null, readonly position: number }> }>, readonly tagManagers: ReadonlyArray<{ readonly __typename?: 'TagManagerItemsCollection', readonly type: GraphQL.TagManagerType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'NullableContentItem', readonly content: string | null, readonly position: number }> }>, readonly visualAnalyticServices: ReadonlyArray<{ readonly __typename?: 'VisualAnalyticServiceItemsCollection', readonly type: GraphQL.VisualAnalyticServiceType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'NullableContentItem', readonly content: string | null, readonly position: number }> }> } | null } | null };


export const ConversionsBlockDocument = gql`
    query ConversionsBlock($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageType
    pageTypeData {
      analyticServices {
        contentItems {
          content
          position
        }
        type
      }
      tagManagers {
        contentItems {
          content
          position
        }
        type
      }
      visualAnalyticServices {
        contentItems {
          content
          position
        }
        type
      }
    }
  }
}
    `;

/**
 * __useConversionsBlockQuery__
 *
 * To run a query within a React component, call `useConversionsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversionsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversionsBlockQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useConversionsBlockQuery(baseOptions: Apollo.QueryHookOptions<ConversionsBlockQuery, ConversionsBlockQueryVariables> & ({ variables: ConversionsBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversionsBlockQuery, ConversionsBlockQueryVariables>(ConversionsBlockDocument, options);
      }
export function useConversionsBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversionsBlockQuery, ConversionsBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversionsBlockQuery, ConversionsBlockQueryVariables>(ConversionsBlockDocument, options);
        }
export function useConversionsBlockSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConversionsBlockQuery, ConversionsBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConversionsBlockQuery, ConversionsBlockQueryVariables>(ConversionsBlockDocument, options);
        }
export type ConversionsBlockQueryHookResult = ReturnType<typeof useConversionsBlockQuery>;
export type ConversionsBlockLazyQueryHookResult = ReturnType<typeof useConversionsBlockLazyQuery>;
export type ConversionsBlockSuspenseQueryHookResult = ReturnType<typeof useConversionsBlockSuspenseQuery>;
export type ConversionsBlockQueryResult = Apollo.QueryResult<ConversionsBlockQuery, ConversionsBlockQueryVariables>;