import type CK from '~/types/contentking';

import {
	useAccountUserRestrictionsQuery,
} from './useAccountUserRestrictions.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountUserRestrictions(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountUserRestrictionsQuery,
		accountId,
	);

	return data?.account?.userRestrictions ?? null;
}



export default useAccountUserRestrictions;
