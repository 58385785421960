import React from 'react';

import type CK from '~/types/contentking';

import AccountSettingsForm from '~/components/app/AccountSettingsForm';
import FormsList from '~/components/atoms/lists/FormsList';



type Props = {
	accountId: CK.AccountId | null,
};

const AccountProfileGroup: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	if (accountId === null) {
		return null;
	}

	return (
		<FormsList>
			<AccountSettingsForm
				accountId={accountId}
			/>
		</FormsList>
	);
};



export default AccountProfileGroup;
