import type CK from '~/types/contentking';

import {
	usePlatformIssueCategoriesQuery,
} from './usePlatformIssueCategories.gql';

import usePlatformQuery from '~/hooks/usePlatformQuery';



function usePlatformIssueCategories(
	websiteId: CK.WebsiteId,
) {
	const { data } = usePlatformQuery(
		usePlatformIssueCategoriesQuery,
		websiteId,
	);

	return data?.platformData?.issueCategories ?? null;
}



export default usePlatformIssueCategories;
