import type CK from '~/types/contentking';

import {
	useWebsiteNameQuery,
} from './useWebsiteName.gql';

import useBatchContext from '~/hooks/useBatchContext';



function useWebsiteName(websiteId: CK.WebsiteId | null): string | null {
	const { data } = useWebsiteNameQuery({
		context: useBatchContext('websites', 50),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	const name = data?.website?.name;

	if (name === undefined) {
		return null;
	}

	return name || '';
}



export default useWebsiteName;
