import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AddressFields from '~/components/app/AddressFields';
import BackButton from '~/components/app/BackButton';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import ExtraInvoiceDetailsFields from '~/components/app/ExtraInvoiceDetailsFields';
import Form from '~/components/atoms/forms/basis/Form';
import MarginsList, {
	SIZE_LARGE as MARGINS_SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import PaymentServicesList from '~/components/atoms/lists/PaymentServicesList';
import SubmitButton from '~/components/app/SubmitButton';

import useAccountSignup from '~/hooks/useAccountSignup';

import {
	runAction,
} from '~/model/actions';



const messages = defineMessages({
	saveButton: {
		id: 'ui.upgradeToPaid.billingDetailsStep.saveButton',
	},
});



type Props = {
	accountId: CK.AccountId,
	isDeliveryAddressEnabled: boolean,
	isExtraInvoiceDetailsEnabled: boolean,
	isModalStepActive: boolean,
	onSuccessCallback: () => void,
};

const AdditionalCustomerDetailsSignupModalStep: React.FC<Props> = (props) => {
	const {
		accountId,
		isDeliveryAddressEnabled,
		isExtraInvoiceDetailsEnabled,
		isModalStepActive,
		onSuccessCallback,
	} = props;

	const accountSignup = useAccountSignup(accountId);

	const handleChange = React.useCallback(
		(field, value) => {
			accountSignup.setProperty(
				'additionalCustomerDetails',
				{
					...accountSignup.additionalCustomerDetails,
					[field]: value,
				},
			);
		},
		[
			accountSignup,
		],
	);

	const handleSubmit = React.useCallback(
		(values) => {
			const customerDetails: Record<string, any> = {};

			if (isDeliveryAddressEnabled) {
				if (values.name) {
					customerDetails.deliveryAddress = {
						name: values.name,
						address: values.address,
						city: values.city,
						country: values.country,
						postalCode: values.postalCode,
						state: values.state || null,
					};
				} else {
					customerDetails.deliveryAddress = null;
				}
			}

			if (isExtraInvoiceDetailsEnabled) {
				customerDetails.extraInvoiceDetails = {
					attn: values.attn,
					customerReference: values.customerReference,
					purchaseOrderNumber: values.purchaseOrderNumber,
					supplierNumber: values.supplierNumber,
				};
			}

			return runAction({
				action: 'StoreCustomerDetails',
				input: {
					accountId,
					customerDetails,
				},
			}).then(() => {
				onSuccessCallback();
			});
		},
		[
			accountId,
			isDeliveryAddressEnabled,
			isExtraInvoiceDetailsEnabled,
			onSuccessCallback,
		],
	);

	const deliveryAddressDefaultValues = isDeliveryAddressEnabled ? {
		address: accountSignup.additionalCustomerDetails.address,
		city: accountSignup.additionalCustomerDetails.city,
		country: accountSignup.additionalCustomerDetails.country,
		name: accountSignup.additionalCustomerDetails.name,
		postalCode: accountSignup.additionalCustomerDetails.postalCode,
		state: accountSignup.additionalCustomerDetails.state,
	} : {};

	const extraInvoiceDetailsDefaultValues = isExtraInvoiceDetailsEnabled ? {
		attn: accountSignup.additionalCustomerDetails.attn,
		customerReference: accountSignup.additionalCustomerDetails.customerReference,
		purchaseOrderNumber: accountSignup.additionalCustomerDetails.purchaseOrderNumber,
		supplierNumber: accountSignup.additionalCustomerDetails.supplierNumber,
	} : {};

	return (
		<MarginsList size={MARGINS_SIZE_LARGE}>
			<CenteredFormWrapper>
				<Form
					clearOnFieldUnmount={true}
					defaultDataHasChanged={true}
					defaultFocus={isExtraInvoiceDetailsEnabled ? 'attn' : 'address'}
					defaultValues={{
						...deliveryAddressDefaultValues,
						...extraInvoiceDetailsDefaultValues,
					}}
					isDisabled={!isModalStepActive}
					onChangeCallback={handleChange}
					onSuccess={handleSubmit}
				>
					<div
						style={{
							minHeight: 320,
						}}
					>
						{isExtraInvoiceDetailsEnabled && (
							<ExtraInvoiceDetailsFields />
						)}

						{isDeliveryAddressEnabled && (
							<AddressFields />
						)}
					</div>

					<ButtonsLayout>
						<BackButton />

						<SubmitButton nextStepIcon={true}>
							<FormattedMessage {...messages.saveButton} />
						</SubmitButton>
					</ButtonsLayout>
				</Form>
			</CenteredFormWrapper>

			<PaymentServicesList />
		</MarginsList>
	);
};



export default AdditionalCustomerDetailsSignupModalStep;
