import Immutable from 'immutable';

import {
	dictionary as enUSDictionary,
} from './languages/en-US';



export type DictionaryId = (
	| keyof typeof enUSDictionary
);



export const dictionaries = Immutable.Map({
	'en-US': enUSDictionary,
});

export const dictionariesNames = Immutable.Map({
	'en-US': 'English',
});



export function isDictionaryId(id: string): id is DictionaryId {
	return enUSDictionary.hasOwnProperty(id);
}



export function assertDictionaryId(id: string): asserts id is DictionaryId {
	if (enUSDictionary.hasOwnProperty(id) === false) {
		throw new Error(`Missing dictionary id for ${id}`);
	}
}



export function assertDictionaryMessage(message: { id: string }): asserts message is { id: DictionaryId } {
	assertDictionaryId(message.id);
}
