import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DataStudioAndAPIsIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M7.2002 18.6574V16.1299"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M7.2002 12.3025V7.22249C7.2002 7.07705 7.22884 6.93304 7.2845 6.79867C7.34016 6.6643 7.42173 6.54221 7.52457 6.43937C7.62742 6.33653 7.74951 6.25495 7.88387 6.19929C8.01824 6.14364 8.16226 6.11499 8.3077 6.11499H27.5002C27.6456 6.11499 27.7897 6.14364 27.924 6.19929C28.0584 6.25495 28.1805 6.33653 28.2833 6.43937C28.3862 6.54221 28.4677 6.6643 28.5234 6.79867C28.579 6.93304 28.6077 7.07705 28.6077 7.22249V23.7025C28.6077 23.8479 28.579 23.9919 28.5234 24.1263C28.4677 24.2607 28.3862 24.3828 28.2833 24.4856C28.1805 24.5885 28.0584 24.67 27.924 24.7257C27.7897 24.7813 27.6456 24.81 27.5002 24.81H8.3077C8.01397 24.81 7.73227 24.6933 7.52457 24.4856C7.31688 24.2779 7.2002 23.9962 7.2002 23.7025V22.86"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M7.29785 10.0825H28.3579"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M13.3296 15.7774C14.2505 15.7774 14.9971 15.0308 14.9971 14.1099C14.9971 13.1889 14.2505 12.4424 13.3296 12.4424C12.4087 12.4424 11.6621 13.1889 11.6621 14.1099C11.6621 15.0308 12.4087 15.7774 13.3296 15.7774Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M11.6629 14.1099H5.04039C4.60521 14.1092 4.18807 13.9359 3.88058 13.628C3.5731 13.32 3.40039 12.9026 3.40039 12.4674V4.65991"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M13.3296 22.4051C14.2505 22.4051 14.9971 21.6585 14.9971 20.7376C14.9971 19.8166 14.2505 19.0701 13.3296 19.0701C12.4087 19.0701 11.6621 19.8166 11.6621 20.7376C11.6621 21.6585 12.4087 22.4051 13.3296 22.4051Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M11.6629 20.75H5.04039C4.60544 20.75 4.1883 20.9228 3.88074 21.2303C3.57318 21.5379 3.40039 21.955 3.40039 22.39V28.11"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M13.3295 15.3825C14.053 15.3825 14.6395 14.7959 14.6395 14.0725C14.6395 13.349 14.053 12.7625 13.3295 12.7625C12.606 12.7625 12.0195 13.349 12.0195 14.0725C12.0195 14.7959 12.606 15.3825 13.3295 15.3825Z"
					fill={color}
					opacity="0.3"
				/>
				<path
					d="M13.3295 22.1276C14.053 22.1276 14.6395 21.5411 14.6395 20.8176C14.6395 20.0941 14.053 19.5076 13.3295 19.5076C12.606 19.5076 12.0195 20.0941 12.0195 20.8176C12.0195 21.5411 12.606 22.1276 13.3295 22.1276Z"
					fill={color}
					opacity="0.3"
				/>
				<path
					d="M17.6475 16.5V21.17"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M20.2246 14.4524V21.1699"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M22.8027 14.0725V21.17"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M25.377 13.23V21.17"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default DataStudioAndAPIsIconBuilder;

