import calendar from 'calendar-month-array';
import classNames from 'classnames';
import {
	format,
	isAfter,
	isBefore,
	isSameDay,
	isToday,
} from 'date-fns';
import React from 'react';

import {
	isArray,
} from '~/utilities/typeCheck';



type Props = {
	/** Date of selected calendar view */
	currentDate: Date,
	/** Definition of disabled days */
	disabledDate?: (Date) => boolean,
	/** Callback triggered when clicking on non-disabled date */
	onClickCallback?: (Date) => void,
	/** Callback triggered when doing :hover on non-disabled date */
	onMouseEnterCallback?: (Date) => void,
	/** Selected date or date range on calendar view */
	selectedDate?: (Date | null) | [Date | null, Date | null],
};



const Calendar: React.FC<Props> = (props) => {
	const {
		currentDate,
		disabledDate,
		onClickCallback,
		onMouseEnterCallback,
		selectedDate,
	} = props;

	const [weekdays, weeks] = React.useMemo(
		() => {
			const month = calendar(currentDate, {
				weekStartDay: 1,
				formatHeader: (date) => date.toString().slice(0, 2),
				formatSiblingMonthDate: () => null,
			});

			const weekdays: Array<string> = month[0];
			const weeks: Array<Array<Date | null>> = month.slice(1);

			if (weeks.length == 5) {
				weeks.push([null, null, null, null, null, null, null]);
			}

			if (weeks.length == 6) {
				weeks.push([null, null, null, null, null, null, null]);
			}

			return [
				weekdays,
				weeks,
			];
		},
		[
			currentDate,
		],
	);

	return (
		<div className="calendar">
			<div className="calendar__week-days">
				{weekdays.map((day, index) => {
					return (
						<div
							className="calendar__cell"
							key={'week-day-' + index}
						>
							{day}
						</div>
					);
				})}
			</div>
			<div className="calendar__days">
				{weeks.map((week, weekIndex) => {
					return (
						<div
							className="calendar__week"
							key={'week-' + weekIndex}
						>
							{week.map((day, dayIndex) => {
								const isDayDisabled = (
									day === null
									|| disabledDate?.(day)
								);

								let isDaySelected = false;
								let isInRange = false;

								if (selectedDate && day) {
									if (isArray(selectedDate)) {
										const [startDate, endDate] = selectedDate;

										isDaySelected = (
											(startDate !== null && isSameDay(day, startDate))
											|| (endDate !== null && isSameDay(day, endDate))
										);

										isInRange = (
											startDate !== null
											&& endDate !== null
											&& isAfter(day, startDate)
											&& isBefore(day, endDate)
										);
									} else {
										isDaySelected = isSameDay(day, selectedDate);
									}
								}

								const dayClasses = classNames({
									'calendar__cell': true,
									'calendar__cell--day': true,
									'calendar__cell--is-disabled': isDayDisabled,
									'calendar__cell--is-clickable': !isDayDisabled && onClickCallback,
									'calendar__cell--is-selected': isDaySelected,
									'calendar__cell--is-in-range': isInRange,
									'calendar__cell--is-today': day !== null && isToday(day),
								});

								return (
									<div
										className={dayClasses}
										key={'day-' + dayIndex}
										onClick={!isDayDisabled ? () => onClickCallback?.(day) : undefined}
										onMouseEnter={!isDayDisabled ? () => onMouseEnterCallback?.(day) : undefined}
									>
										<div className="calendar__cell-content">
											{day !== null && format(day, 'd')}
										</div>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
};



export default Calendar;
