import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import AnalyticServiceName from '../../names/AnalyticServiceName';
import ConcatenateFormatter from '~/components/app/ConcatenateFormatter';

import {
	LIST_OF_ALL_SERVICES,
} from '~/model/analyticServices';



class AnalyticServicesFormatter extends PureComponent {

	render() {
		const {
			value,
		} = this.props;

		return (
			<ConcatenateFormatter
				formatter={(service) => (
					<AnalyticServiceName service={service} />
				)}
				value={(value || []).filter((service) => LIST_OF_ALL_SERVICES.includes(service))}
			/>
		);
	}

}

AnalyticServicesFormatter.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.instanceOf(List),
	]),
};



export default AnalyticServicesFormatter;
