import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import StatusMessage from '~/components/patterns/messages/embedded/StatusMessage';



const messages = defineMessages({
	description: {
		id: 'ui.pageDetail.statusMessage.notCrawledYet.description',
	},
	pausedDescription: {
		id: 'ui.pageDetail.statusMessage.notCrawledYet.paused.description',
	},
	pausedTitle: {
		id: 'ui.pageDetail.statusMessage.notCrawledYet.paused.title',
	},
	title: {
		id: 'ui.pageDetail.statusMessage.notCrawledYet.title',
	},
});



type Props = {
	isCrawlingPaused: boolean | null,
};

const NotCrawledYet: React.FC<Props> = (props) => {
	const {
		isCrawlingPaused,
	} = props;

	if (isCrawlingPaused) {
		return (
			<StatusMessage
				message={(
					<FormattedMessage {...messages.pausedTitle} />
				)}
			>
				<FormattedMessage {...messages.pausedDescription} />
			</StatusMessage>
		);
	}

	return (
		<StatusMessage
			message={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<FormattedMessage {...messages.description} />
		</StatusMessage>
	);
};



export default NotCrawledYet;
