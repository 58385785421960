import {
	STORE_UPDATED_TEAM,
	storeLoadedBillingDetails,
	storeLoadedDiscounts,
	storeLoadedTeams,
} from '~/actions';

import {
	runAction,
} from '~/model/actions';

import {
	loadDiscountDetailsViaRecurly,
	loadTaxDetails,
} from '~/model/pricing/recurly';

import {
	loadTeamBillingDetailsViaApi,
	loadViaApi,
} from '~/model/teams';

import {
	EmptyTeamBilling,
	teamsBillingSelector,
	teamsSelector,
} from '~/state/teams/selectors';



export function loadTeamBillingDetails(teamId) {
	return (dispatch, getState) => {
		if (teamId === null) {
			return Promise.resolve();
		}

		const team = teamsSelector(getState()).get(teamId.toString());
		const teamBilling = teamsBillingSelector(getState()).get(teamId);

		if (!team) {
			return Promise.resolve();
		}

		const promises = [];

		if (
			!teamBilling
			|| teamBilling === EmptyTeamBilling
			|| !teamBilling.get('details')
		) {
			promises.push(
				loadTeamBillingDetailsViaApi(teamId).then(
					(billingDetails) => {
						return loadTaxDetails(
							billingDetails.country,
							billingDetails.vat_number,
						).then((tax) => {
							dispatch(
								storeLoadedBillingDetails(
									teamId,
									billingDetails,
									undefined,
									tax,
								),
							);
						});
					},
				),
			);
		} else if (!teamBilling.get('tax')) {
			promises.push(
				loadTaxDetails(
					teamBilling.get('details').get('country'),
					teamBilling.get('details').get('vat_number'),
				).then((tax) => {
					dispatch(
						storeLoadedBillingDetails(
							teamId,
							undefined,
							undefined,
							tax,
						),
					);
				}),
			);
		}

		if (
			!teamBilling
			|| teamBilling === EmptyTeamBilling
			|| !teamBilling.get('discounts')
		) {
			promises.push(
				loadDiscountDetailsViaRecurly(
					team.get('id'),
				).then((discounts) => {
					dispatch(
						storeLoadedDiscounts(
							teamId,
							discounts,
						),
					);
				}),
			);
		}

		return Promise.all(promises);
	};
}



export function loadTeams() {
	return (dispatch) => {
		return loadViaApi()
			.then(
				(data) => {
					dispatch(storeLoadedTeams(data));
				},
			);
	};
}



export function synchronizeTeams() {
	return (dispatch) => {
		return loadViaApi()
			.then(
				(data) => {
					dispatch(storeLoadedTeams(data));
				},
			);
	};
}



export function storeUpdatedTeam(teamId, team) {
	return {
		type: STORE_UPDATED_TEAM,
		id: teamId,
		team,
	};
}



export function updateBillingDetails(teamId, details) {
	return (dispatch) => {
		const billingDetailsData = Object.assign({}, details);

		return runAction({
			action: 'StoreBillingDetails',
			input: {
				accountId: teamId,
				billingDetails: billingDetailsData,
			},
		}).then(({ billingDetails }) => {
			dispatch(
				storeLoadedBillingDetails(
					teamId,
					billingDetails,
				),
			);

			return billingDetails;
		});
	};
}



export function updateSubscription(accountId, plan, billingCycle, pageBundle, threeDSecureToken) {
	return (dispatch, getState) => {
		const account = teamsSelector(getState()).get(accountId.toString());

		let promise;

		if (account.get('is_billable')) {
			promise = runAction({
				action: 'UpdateSubscription',
				input: {
					accountId,
					plan,
					billingCycle,
					pageBundle,
					threeDSecureToken,
				},
			});
		}

		return promise.then(({ account }) => {
			dispatch(
				storeUpdatedTeam(accountId, account),
			);
		});
	};
}



export function updateEndUser(accountId, endUserPlan, billingCycle, threeDSecureToken) {
	return (dispatch) => {
		const promise = runAction({
			action: 'UpdateEndUserPlan',
			input: {
				accountId,
				billingCycle,
				endUserPlan,
				threeDSecureToken,
			},
		});

		return promise.then(({ account }) => {
			dispatch(
				storeUpdatedTeam(accountId, account),
			);
		});
	};
}
