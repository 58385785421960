import React, {
	Component,
} from 'react';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import CK from '~/types/contentking';

import ActiveFilter from '../../filters/ActiveFilter';
import ColumnName from '../../../names/ColumnName';

import {
	updateFilter as affectedPagesComparisonUpdateFilter,
} from '~/actions/affectedPagesComparison';

import {
	removeDefaultFilterValues as affectedPagesComparisonRemoveDefaultFilterValues,
} from '~/model/affectedPagesComparison';

import {
	currentFilterSelector as affectedPagesComparisonFilterSelector,
	categorySelector as affectedPagesComparisonIssueCategorySelector,
	singleTypeSelector as affectedPagesComparisonIssueTypeSelector,
} from '~/state/affectedPagesComparison/selectors';



const select = createSelector(
	affectedPagesComparisonFilterSelector,
	affectedPagesComparisonIssueCategorySelector,
	affectedPagesComparisonIssueTypeSelector,
	(
		affectedPagesComparisonFilter,
		affectedPagesComparisonIssueCategory,
		affectedPagesComparisonIssueType,
	) => {
		return {
			affectedPagesComparisonFilter,
			affectedPagesComparisonIssueCategory,
			affectedPagesComparisonIssueType,
		};
	},
);



class AffectedPagesComparisonFilterBar extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleRemoveFilter = this._handleRemoveFilter.bind(this);
	}



	_handleRemoveFilter(filterId) {
		const {
			dispatch,
		} = this.props;

		dispatch(
			affectedPagesComparisonUpdateFilter({
				[filterId]: undefined,
			}),
		);
	}



	render() {
		const {
			affectedPagesComparisonFilter,
			affectedPagesComparisonIssueCategory,
			affectedPagesComparisonIssueType,
			onFilterClickCallback,
		} = this.props;

		const activeFilters = affectedPagesComparisonRemoveDefaultFilterValues(
			affectedPagesComparisonFilter,
			affectedPagesComparisonIssueCategory,
			affectedPagesComparisonIssueType,
		);

		return (
			<ActiveFilter
				activeFilters={activeFilters}
				clearLink={false}
				filter={affectedPagesComparisonFilter}
				filterNames={{
					issue_change: (
						<ColumnName column={CK.PagesCommonColumn.ChangeType} />
					),
				}}
				onFilterClickCallback={onFilterClickCallback}
				removeFilterCallback={this._handleRemoveFilter}
				segmentsManagement={false}
			/>
		);
	}

}



export default connect(select)(AffectedPagesComparisonFilterBar);
