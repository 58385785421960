import {
	useUserIsAlertRecipientByDefaultQuery,
} from './useUserIsAlertRecipientByDefault.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserIsAlertRecipientByDefault(legacyUserId: string | null) {
	const { data } = useUserPropertiesQuery(
		useUserIsAlertRecipientByDefaultQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.isAlertRecipientByDefault ?? null;
}



export default useUserIsAlertRecipientByDefault;
