import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from 'react-sortable-hoc';

import CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AbstractCheckboxField, {
	AbstractCheckboxFieldCheckedState,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ColumnCategoryName from '~/components/names/ColumnCategoryName';
import ColumnController from '~/components/patterns/forms/fieldParts/pageColumns/ColumnController';
import ColumnName from '~/components/names/ColumnName';
import ColumnsConfiguratorLayout from '~/components/atoms/columnsConfigurator/ColumnsConfiguratorLayout';
import ColumnSetsPickerHint from '~/components/logic/interfaceHints/ColumnSetsPickerHint';
import ColumnsCategoryController from '~/components/patterns/forms/fieldParts/pageColumns/ColumnsCategoryController';
import ColumnsCTA from '~/components/logic/columnsConfigurator/ColumnsCTA';
import ColumnsMessage from '~/components/patterns/forms/fieldParts/pageColumns/ColumnsMessage';
import ColumnsSetPicker from '~/components/atoms/columnsConfigurator/components/ColumnsSetPicker';
import ColumnsSets from '~/components/atoms/columnsConfigurator/components/ColumnsSets';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import DragHandlerIcon from '~/components/patterns/icons/DragHandler';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';

import useCurrentColumnsSetup from '~/hooks/useCurrentColumnsSetup';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useIsColumnSetModified from '~/hooks/useIsColumnSetModified';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';

import {
	setColumns,
} from '~/actions/teams/views';

import {
	PagesColumnsCategory,
	flattenColumnsSetup,
	isTrackedChangesColumn,
} from '~/model/pagesColumns';

import {
	isInHistoricalChangesModeSelector,
} from '~/state/historicalChanges/selectors';

import {
	columnsSetsListVisibilitySelector,
} from '~/state/ui/selectors';

import arrayMove from '~/utilities/arrayMove';
import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import touchSupported from '~/utilities/touchSupported';



const iconMapping = {
	[PagesColumnsCategory.AdobeAnalytics]: AttributesGroupIconType.AdobeAnalytics,
	[PagesColumnsCategory.Content]: AttributesGroupIconType.Content,
	[PagesColumnsCategory.Conversions]: AttributesGroupIconType.Conversions,
	[PagesColumnsCategory.CustomElements]: AttributesGroupIconType.CustomElements,
	[PagesColumnsCategory.EnrichmentFields]: AttributesGroupIconType.EnrichmentFields,
	[PagesColumnsCategory.Fundamentals]: AttributesGroupIconType.Fundamentals,
	[PagesColumnsCategory.GoogleAnalytics]: AttributesGroupIconType.GoogleAnalytics,
	[PagesColumnsCategory.GoogleSearchConsole]: AttributesGroupIconType.GoogleSearchConsole,
	[PagesColumnsCategory.Indexability]: AttributesGroupIconType.Indexability,
	[PagesColumnsCategory.Lighthouse]: AttributesGroupIconType.Lighthouse,
	[PagesColumnsCategory.Relations]: AttributesGroupIconType.Relations,
	[PagesColumnsCategory.SchemaOrg]: AttributesGroupIconType.SchemaOrg,
	[PagesColumnsCategory.SearchEngineActivity]: AttributesGroupIconType.SearchEngineActivity,
	[PagesColumnsCategory.Social]: AttributesGroupIconType.Social,
} as const;

function isColumnLocked(column: CK.PagesColumn, isInHistoricalChangesMode: boolean = false) {
	if (column === CK.PagesCommonColumn.Url) {
		return true;
	}

	if (isInHistoricalChangesMode) {
		return isTrackedChangesColumn(column) === false;
	}

	return false;
}

function isColumnAlwaysChecked(column: CK.PagesColumn) {
	return (
		column === CK.PagesCommonColumn.Url
		|| column === CK.PagesCommonColumn.Segments
	);
}



const DragHandle = SortableHandle(() => {
	return (
		<DragHandlerIcon />
	);
});



const messages = defineMessages({
	customElementsButtonLabel: {
		id: 'ui.columnsConfigurator.customElements.addButtonLabel',
	},
	customElementsExampleColumn1: {
		id: 'ui.customElements.exampleColumn1',
	},
	customElementsExampleColumn2: {
		id: 'ui.customElements.exampleColumn2',
	},
	customElementsExampleColumn3: {
		id: 'ui.customElements.exampleColumn3',
	},
	customElementsPlaceholder: {
		id: 'ui.columnsConfigurator.messages.noCustomElements',
	},
	lighthouseButtonLabel: {
		id: 'ui.columnsConfigurator.lighthouse.enableButtonLabel',
	},
	lighthousePlaceholder: {
		id: 'ui.columnsConfigurator.messages.lighthouseNotEnabled',
	},
	logFileAnalysisButtonLabel: {
		id: 'ui.columnsConfigurator.logFileAnalysis.enableButtonLabel',
	},
	logFileAnalysisPlaceholder: {
		id: 'ui.columnsConfigurator.messages.logFileAnalysisNotEnabled',
	},
	unavailableInChangeTracking: {
		id: 'ui.columnsConfigurator.messages.unavailableInChangeTracking',
	},
});



type ColumnProps = {
	alwaysChecked: boolean,
	enabled: boolean,
	handleCheckboxClick: (columnName: CK.PagesColumn) => void,
	isInHistoricalChangesMode: boolean,
	locked: boolean,
	name: CK.PagesColumn,
};

const Column = SortableElement<ColumnProps>(({
	alwaysChecked,
	enabled,
	handleCheckboxClick,
	isInHistoricalChangesMode,
	locked,
	name,
}: ColumnProps) => {
	const unavailableInChangeTracking = isTrackedChangesColumn(name) === false;

	return (
		<ColumnController
			dragHandler={[
				(
					<DragHandle key="dragHandle" />
				),
			]}
			isLocked={locked}
			key={name}
			label={(
				<AbstractCheckboxField
					checkedState={enabled ? AbstractCheckboxFieldCheckedState.Checked : AbstractCheckboxFieldCheckedState.NotChecked}
					isControlled={true}
					isDisabled={locked || alwaysChecked}
					isReadOnly={alwaysChecked}
					label={(
						<ColumnName
							column={name}
						/>
					)}
					name={name}
					onClick={(
						alwaysChecked
							? undefined
							: () => handleCheckboxClick(name)
					)}
				/>
			)}
			labelTooltip={isInHistoricalChangesMode && unavailableInChangeTracking && (
				<FormattedMessage {...messages.unavailableInChangeTracking} />
			)}
		/>
	);
});



type ColumnsListProps = {
	categoryName: PagesColumnsCategory,
	columns: ReadonlyArray<{
		enabled: boolean,
		name: CK.PagesColumn,
	}>,
	handleCheckboxClick: (columnName: CK.PagesColumn) => void,
	isInHistoricalChangesMode: boolean,
	websiteId: CK.WebsiteId,
};

const ColumnsList = SortableContainer<ColumnsListProps>(({
	categoryName,
	columns,
	handleCheckboxClick,
	isInHistoricalChangesMode,
	websiteId,
}: ColumnsListProps) => {
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const isLighthouseMonitored = useWebsiteIsLighthouseMonitored(websiteId);

	const {
		isFeatureEnabled: isLighthouseFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LighthouseMonitoring);

	const {
		isFeatureEnabled: isLogFileAnalysisFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const {
		isLogFileAnalysisEnabled,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	if (
		categoryName === PagesColumnsCategory.CustomElements
		&& customElementDefinitions.isLoaded
		&& customElementDefinitions.count === 0
	) {
		return (
			<ColumnsMessage>
				<InterfaceMessage
					ctaElement={(
						<Button
							linkRouteName="website.detail.customElements"
							linkRouteParams={{
								action: 'create_custom_element',
								websiteId,
							}}
							size={ButtonSize.Small}
							style={ButtonStyle.Hollow}
							uppercase={true}
						>
							<FormattedMessage {...messages.customElementsButtonLabel} />
						</Button>
					)}
				>
					<FormattedMessage {...messages.customElementsPlaceholder} />
				</InterfaceMessage>
			</ColumnsMessage>
		);
	}

	if (
		categoryName === PagesColumnsCategory.Lighthouse
		&& isLighthouseFeatureEnabled
		&& isLighthouseMonitored === false
	) {
		return (
			<ColumnsMessage>
				<InterfaceMessage
					ctaElement={(
						<Button
							linkRouteName="website.detail.monitoring"
							linkRouteParams={{
								websiteId,
							}}
							size={ButtonSize.Small}
							style={ButtonStyle.Hollow}
							uppercase={true}
						>
							<FormattedMessage {...messages.lighthouseButtonLabel} />
						</Button>
					)}
				>
					<FormattedMessage {...messages.lighthousePlaceholder} />
				</InterfaceMessage>
			</ColumnsMessage>
		);
	}

	if (
		categoryName === PagesColumnsCategory.SearchEngineActivity
		&& isLogFileAnalysisFeatureEnabled
		&& !isLogFileAnalysisEnabled
	) {
		return (
			<ColumnsMessage>
				<InterfaceMessage
					ctaElement={(
						<Button
							linkRouteName="website.detail.logFileAnalysis"
							linkRouteParams={{
								websiteId,
							}}
							size={ButtonSize.Small}
							style={ButtonStyle.Hollow}
							uppercase={true}
						>
							<FormattedMessage {...messages.logFileAnalysisButtonLabel} />
						</Button>
					)}
				>
					<FormattedMessage {...messages.logFileAnalysisPlaceholder} />
				</InterfaceMessage>
			</ColumnsMessage>
		);
	}

	return (
		<div>
			{columns.map((column, index) => {
				if (categoryName === PagesColumnsCategory.CustomElements) {
					if (customElementDefinitions.getByColumn(column.name) === null) {
						return false;
					}
				}

				if (categoryName === PagesColumnsCategory.EnrichmentFields) {
					if (enrichmentFieldDefinitions.getByColumn(column.name) === null) {
						return false;
					}
				}

				return (
					<Column
						alwaysChecked={isColumnAlwaysChecked(column.name)}
						collection="item"
						disabled={false}
						enabled={column.enabled || isColumnAlwaysChecked(column.name)}
						handleCheckboxClick={handleCheckboxClick}
						index={index}
						isInHistoricalChangesMode={isInHistoricalChangesMode}
						key={`item-${column.name}`}
						locked={isColumnLocked(column.name, isInHistoricalChangesMode)}
						name={column.name}
					/>
				);
			}).filter((element) => !!element)}
		</div>
	);
});



type CategoryProps = {
	category: {
		columns: ReadonlyArray<{
			enabled: boolean,
			name: CK.PagesColumn,
		}>,
		locked: boolean,
		name: PagesColumnsCategory,
	},
	handleColumnCheckboxClick: (columnName: CK.PagesColumn) => void,
	isInHistoricalChangesMode: boolean,
	onColumnSortEnd: (
		{ oldIndex, newIndex }: { oldIndex: number, newIndex: number },
	) => void,
	onColumnSortStart: () => void,
	sortingInProgress: boolean,
	websiteId: CK.WebsiteId,
};

const Category = SortableElement<CategoryProps>(({
	category,
	handleColumnCheckboxClick,
	isInHistoricalChangesMode,
	onColumnSortEnd,
	onColumnSortStart,
	sortingInProgress,
	websiteId,
}: CategoryProps) => {
	let columns = category.columns;
	const categoryName = category.name;

	if (categoryName === PagesColumnsCategory.Fundamentals) {
		columns = columns.filter((column) => column.name !== CK.PagesCommonColumn.Url);
	}

	const columnsList = (
		<ColumnsList
			categoryName={categoryName}
			columns={columns}
			handleCheckboxClick={handleColumnCheckboxClick}
			helperClass="page-column-controller--highlighted"
			isInHistoricalChangesMode={isInHistoricalChangesMode}
			lockAxis="y"
			lockToContainerEdges={true}
			onSortEnd={onColumnSortEnd}
			onSortStart={onColumnSortStart}
			pressDelay={touchSupported ? 200 : 0}
			useDragHandle={!touchSupported}
			websiteId={websiteId}
		/>
	);

	let columnsListElement;

	if (categoryName === PagesColumnsCategory.CustomElements) {
		columnsListElement = (
			<PremiumFeatureSituation
				featureName={GraphQL.AccountFeature.CustomElements_2}
				hideIfUnattainable={false}
				style={PremiumFeatureSituationStyle.Ribbon}
			>
				{({ isFeatureEnabled, premiumAnnotation }) => (
					<DisabledContent
						disabledContent={!isFeatureEnabled}
						disabledOverlay={!isFeatureEnabled && premiumAnnotation}
					>
						{!isFeatureEnabled && (
							<>
								<ColumnController
									dragHandler={[
										(
											<DragHandle key="dragHandle" />
										),
									]}
									isLocked={false}
									label={(
										<AbstractCheckboxField
											isControlled={true}
											isDisabled={false}
											isReadOnly={false}
											label={(
												<FormattedMessage {...messages.customElementsExampleColumn1} />
											)}
											name="custom_element_1"
										/>
									)}
								/>
								<ColumnController
									dragHandler={[
										(
											<DragHandle key="dragHandle" />
										),
									]}
									isLocked={false}
									label={(
										<AbstractCheckboxField
											isControlled={true}
											isDisabled={false}
											isReadOnly={false}
											label={(
												<FormattedMessage {...messages.customElementsExampleColumn2} />
											)}
											name="custom_element_2"
										/>
									)}
								/>
								<ColumnController
									dragHandler={[
										(
											<DragHandle key="dragHandle" />
										),
									]}
									isLocked={false}
									label={(
										<AbstractCheckboxField
											isControlled={true}
											isDisabled={false}
											isReadOnly={false}
											label={(
												<FormattedMessage {...messages.customElementsExampleColumn3} />
											)}
											name="custom_element_3"
										/>
									)}
								/>
							</>
						)}

						{isFeatureEnabled && columnsList}
					</DisabledContent>
				)}
			</PremiumFeatureSituation>
		);
	} else if (categoryName === PagesColumnsCategory.EnrichmentFields) {
		columnsListElement = (
			<PremiumFeatureSituation
				featureName={GraphQL.AccountFeature.EnrichmentFields_5}
				hideIfUnattainable={false}
				style={PremiumFeatureSituationStyle.Ribbon}
			>
				{({ isFeatureEnabled, premiumAnnotation }) => (
					<DisabledContent
						disabledContent={!isFeatureEnabled}
						disabledOverlay={!isFeatureEnabled && premiumAnnotation}
					>
						{!isFeatureEnabled && (
							<>
								<ColumnController
									dragHandler={[
										(
											<DragHandle key="dragHandle" />
										),
									]}
									isLocked={false}
									label={(
										<AbstractCheckboxField
											isControlled={true}
											isDisabled={false}
											isReadOnly={false}
											label={(
												<FormattedMessage {...messages.customElementsExampleColumn1} />
											)}
											name="custom_element_1"
										/>
									)}
								/>
								<ColumnController
									dragHandler={[
										(
											<DragHandle key="dragHandle" />
										),
									]}
									isLocked={false}
									label={(
										<AbstractCheckboxField
											isControlled={true}
											isDisabled={false}
											isReadOnly={false}
											label={(
												<FormattedMessage {...messages.customElementsExampleColumn2} />
											)}
											name="custom_element_2"
										/>
									)}
								/>
								<ColumnController
									dragHandler={[
										(
											<DragHandle key="dragHandle" />
										),
									]}
									isLocked={false}
									label={(
										<AbstractCheckboxField
											isControlled={true}
											isDisabled={false}
											isReadOnly={false}
											label={(
												<FormattedMessage {...messages.customElementsExampleColumn3} />
											)}
											name="custom_element_3"
										/>
									)}
								/>
							</>
						)}

						{isFeatureEnabled && columnsList}
					</DisabledContent>
				)}
			</PremiumFeatureSituation>
		);
	} else if (categoryName === PagesColumnsCategory.Lighthouse) {
		columnsListElement = (
			<PremiumFeatureSituation
				featureName={GraphQL.AccountFeature.LighthouseMonitoring}
				hideIfUnattainable={false}
				style={PremiumFeatureSituationStyle.Ribbon}
			>
				{({ isFeatureEnabled, premiumAnnotation }) => (
					<DisabledContent
						disabledContent={!isFeatureEnabled}
						disabledOverlay={!isFeatureEnabled && premiumAnnotation}
					>
						{columnsList}
					</DisabledContent>
				)}
			</PremiumFeatureSituation>
		);
	} else if (categoryName === PagesColumnsCategory.SearchEngineActivity) {
		columnsListElement = (
			<PremiumFeatureSituation
				featureName={GraphQL.AccountFeature.LogFileAnalysis}
				hideIfUnattainable={false}
				style={PremiumFeatureSituationStyle.Ribbon}
			>
				{({ isFeatureEnabled, premiumAnnotation }) => (
					<DisabledContent
						disabledContent={!isFeatureEnabled}
						disabledOverlay={!isFeatureEnabled && premiumAnnotation}
					>
						{columnsList}
					</DisabledContent>
				)}
			</PremiumFeatureSituation>
		);
	} else {
		columnsListElement = columnsList;
	}

	return (
		<ColumnsCategoryController
			animation={!sortingInProgress}
			isLocked={category.locked}
			key={categoryName + '-container'}
			label={(
				<>
					<AttributesGroupIcon
						size={20}
						type={iconMapping[categoryName]}
					/>
					<ColumnCategoryName columnCategory={categoryName} />
				</>
			)}
			name={categoryName}
			suffix={[
				category.locked === false && (
					<DragHandle key="dragHandle" />
				),
			]}
		>
			{columnsListElement}
		</ColumnsCategoryController>
	);
});



type ColumnsCategoriesListProps = {
	categories: ReadonlyArray<{
		columns: ReadonlyArray<{
			enabled: boolean,
			name: CK.PagesColumn,
		}>,
		locked: boolean,
		name: PagesColumnsCategory,
	}>,
	handleColumnCheckboxClick: (columnName: CK.PagesColumn) => void,
	isAdobeAnalyticsIntegrationEnabled: boolean,
	isInHistoricalChangesMode: boolean,
	onColumnSortEnd: (
		categoryName: PagesColumnsCategory,
		{ oldIndex, newIndex }: { oldIndex: number, newIndex: number },
	) => void,
	onColumnSortStart: () => void,
	sortingInProgress: boolean,
	websiteId: CK.WebsiteId,
};

const ColumnsCategoriesList = SortableContainer<ColumnsCategoriesListProps>(({
	categories,
	handleColumnCheckboxClick,
	isAdobeAnalyticsIntegrationEnabled,
	isInHistoricalChangesMode,
	onColumnSortEnd,
	onColumnSortStart,
	sortingInProgress,
	websiteId,
}: ColumnsCategoriesListProps) => {
	const {
		isFeatureAttainable: isLogFileAnalysisFeatureAttainable,
		isFeatureEnabled: isLogFileAnalysisFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const {
		isFeatureAttainable: isCustomElementsFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.CustomElements_2);

	const {
		isFeatureAttainable: isEnrichmentFieldsFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.EnrichmentFields_5);

	const {
		isFeatureAttainable: isLighthouseMonitoringFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LighthouseMonitoring);

	const {
		hasAvailableLogSources,
		isLogFileAnalysisEnabled,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const showSearchEngineActivityCategory = (
		isLogFileAnalysisEnabled
		|| (isLogFileAnalysisFeatureAttainable && hasAvailableLogSources)
		|| (isLogFileAnalysisFeatureEnabled && hasAvailableLogSources)
	);

	return (
		<div>
			{categories
				.map((category, categoryIndex) => {
					if (category.locked) {
						return null;
					}

					const name = category.name;

					if (
						(name === PagesColumnsCategory.AdobeAnalytics && !isAdobeAnalyticsIntegrationEnabled)
						|| (name === PagesColumnsCategory.CustomElements && !isCustomElementsFeatureAttainable)
						|| (name === PagesColumnsCategory.EnrichmentFields && !isEnrichmentFieldsFeatureAttainable)
						|| (name === PagesColumnsCategory.Lighthouse && !isLighthouseMonitoringFeatureAttainable)
						|| (name === PagesColumnsCategory.SearchEngineActivity && !showSearchEngineActivityCategory)
					) {
						return null;
					}

					return (
						<Category
							category={category}
							collection="section"
							disabled={false}
							handleColumnCheckboxClick={handleColumnCheckboxClick}
							index={categoryIndex}
							isInHistoricalChangesMode={isInHistoricalChangesMode}
							key={`item-${category.name}`}
							onColumnSortEnd={onColumnSortEnd.bind(null, category.name)}
							onColumnSortStart={onColumnSortStart.bind(null, category.name)}
							sortingInProgress={sortingInProgress}
							websiteId={websiteId}
						/>
					);
				})
			}
		</div>
	);
});



type Props = {
	onColumnsSortEndCallback?: () => void,
};

const ColumnsConfigurator: React.FC<Props> = (props) => {
	const {
		onColumnsSortEndCallback,
	} = props;

	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const websiteId = useWebsiteId();

	const categories = useCurrentColumnsSetup();
	const columnsSetsListVisible = useSelector(columnsSetsListVisibilitySelector);
	const dispatch = useDispatch();
	const isColumnSetModified = useIsColumnSetModified();
	const isInHistoricalChangesMode = useSelector(isInHistoricalChangesModeSelector);
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const [sortingInProgress, setSortingInProgress] = React.useState(false);

	const handleSortStart = React.useCallback(
		() => {
			setSortingInProgress(true);
		},
		[
			setSortingInProgress,
		],
	);

	function onColumnSortEnd(
		categoryName: PagesColumnsCategory,
		{ oldIndex, newIndex }: { oldIndex: number, newIndex: number },
	) {
		const categoryIndex = categories.findIndex((category) => category.name === categoryName);
		const updatedCategory = getArrayItemAtSafeIndex(categories, categoryIndex);
		const numberOfLockedColumns = updatedCategory.columns.filter((column) => isColumnLocked(column.name)).length;

		const newCategories = [
			...categories.slice(0, categoryIndex),
			{
				...updatedCategory,
				columns: arrayMove(
					updatedCategory.columns,
					oldIndex + numberOfLockedColumns,
					newIndex + numberOfLockedColumns,
				),
			},
			...categories.slice(categoryIndex + 1),
		];

		dispatch(
			setColumns(
				flattenColumnsSetup(newCategories),
			),
		);

		if (onColumnsSortEndCallback) {
			onColumnsSortEndCallback();
		}

		setSortingInProgress(false);
	}

	const handleCategorySortEnd = React.useCallback(
		({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
			const newCategories = arrayMove(
				categories,
				oldIndex,
				newIndex,
			);

			dispatch(
				setColumns(
					flattenColumnsSetup(newCategories),
				),
			);

			if (onColumnsSortEndCallback) {
				onColumnsSortEndCallback();
			}

			// we need to prevent animation when changing order of sortable items
			setTimeout(() => {
				setSortingInProgress(false);
			}, 200);
		},
		[
			categories,
			dispatch,
			onColumnsSortEndCallback,
			setSortingInProgress,
		],
	);

	const handleColumnCheckboxClick = React.useCallback(
		(columnName: CK.PagesColumn) => {
			dispatch(
				setColumns(
					flattenColumnsSetup(categories).map((column) => {
						if (column.name === columnName) {
							return {
								...column,
								enabled: !column.enabled,
							};
						}

						return column;
					}),
				),
			);
		},
		[
			categories,
			dispatch,
		],
	);

	function renderLockedColumns(columns: ReadonlyArray<{
		enabled: boolean,
		name: CK.PagesColumn,
	}>) {
		return columns.map((column) => {
			const enabled = column.enabled || isColumnAlwaysChecked(column.name);

			return (
				<ColumnController
					isLocked={true}
					key={column.name}
					label={(
						<AbstractCheckboxField
							checkedState={(enabled
								? AbstractCheckboxFieldCheckedState.Checked
								: AbstractCheckboxFieldCheckedState.NotChecked
							)}
							isControlled={true}
							isDisabled={true}
							isReadOnly={true}
							label={(
								<ColumnName
									column={column.name}
								/>
							)}
							name={column.name}
						/>
					)}
				/>
			);
		});
	}

	function renderLockedCategories() {
		const lockedCategories = categories.filter((category) => category.locked);

		if (lockedCategories.length > 0) {
			return lockedCategories.map((category) => {
				const categoryName = category.name;

				return (
					<ColumnsCategoryController
						animation={!sortingInProgress}
						isLocked={true}
						key={categoryName + '-container'}
						label={(
							<>
								<AttributesGroupIcon
									size={20}
									type={iconMapping[categoryName]}
								/>
								<ColumnCategoryName columnCategory={categoryName} />
							</>
						)}
						name={categoryName}
					>
						{renderLockedColumns(category.columns.filter((column) => isColumnLocked(column.name)))}

						<ColumnsList
							categoryName={categoryName}
							columns={category.columns.filter((column) => !isColumnLocked(column.name))}
							handleCheckboxClick={handleColumnCheckboxClick}
							helperClass="page-column-controller--highlighted"
							isInHistoricalChangesMode={isInHistoricalChangesMode}
							lockAxis="y"
							lockToContainerEdges={true}
							onSortEnd={onColumnSortEnd.bind(null, categoryName)}
							onSortStart={handleSortStart}
							pressDelay={touchSupported ? 200 : 0}
							useDragHandle={!touchSupported}
							websiteId={websiteId}
						/>
					</ColumnsCategoryController>
				);
			});
		}

		return null;
	}

	const isAdobeAnalyticsIntegrationEnabled = websiteIntegrations.isEnabled(GraphQL.WebsiteIntegrationType.AdobeAnalytics);

	return (
		<ColumnsConfiguratorLayout
			footer={(
				<ColumnsCTA
					accountId={effectiveHomeAccountId}
					isColumnSetModified={isColumnSetModified ?? false}
				/>
			)}
			header={(
				<ColumnSetsPickerHint>
					<ColumnsSetPicker
						isColumnSetModified={isColumnSetModified}
					/>
				</ColumnSetsPickerHint>
			)}
		>
			{columnsSetsListVisible && (
				<ColumnsSets />
			)}

			{!columnsSetsListVisible && (
				<>
					{renderLockedCategories()}
					<ColumnsCategoriesList
						categories={categories}
						handleColumnCheckboxClick={handleColumnCheckboxClick}
						helperClass="page-columns-category-controller--highlighted"
						isAdobeAnalyticsIntegrationEnabled={isAdobeAnalyticsIntegrationEnabled}
						isInHistoricalChangesMode={isInHistoricalChangesMode}
						lockAxis="y"
						onColumnSortEnd={onColumnSortEnd}
						onColumnSortStart={handleSortStart}
						onSortEnd={handleCategorySortEnd}
						onSortStart={handleSortStart}
						pressDelay={touchSupported ? 200 : 0}
						sortingInProgress={sortingInProgress}
						useDragHandle={!touchSupported}
						websiteId={websiteId}
					/>
				</>
			)}
		</ColumnsConfiguratorLayout>
	);
};



export default ColumnsConfigurator;
