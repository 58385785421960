import classNames from 'classnames';
import React from 'react';



type Props = {
	children: React.ReactNode,
	overlay?: React.ReactNode,
};



const AttachedChartOverlay: React.FC<Props> = (props) => {
	const {
		children,
		overlay,
	} = props;

	const componentClasses = classNames({
		'attached-chart-overlay': true,
		'attached-chart-overlay--has-visible-overlay': overlay,
	});

	return (
		<div className={componentClasses}>
			<div className="attached-chart-overlay__chart">
				{children}
			</div>
			{overlay && (
				<div className="attached-chart-overlay__overlay">
					{overlay}
				</div>
			)}
		</div>
	);
};



export default AttachedChartOverlay;
