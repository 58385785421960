import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AirplaneIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M23.108,5.136a2.975,2.975,0,0,0,0-4.248,2.983,2.983,0,0,0-4.247,0L16.119,3.63a.5.5,0,0,1-.354.146c-1.5,0-2.87-.01-4.112-.018C4.989,3.714,1.944,3.7.673,4.96A2.462,2.462,0,0,0,0,6.805c0,2.349,2.1,2.433,5.28,2.56,1.068.043,2.313.093,3.722.224a.5.5,0,0,1,.307.852L4.991,14.759a.5.5,0,0,1-.353.147H2.523A2.525,2.525,0,0,0,0,17.429c0,1.887,1.387,2.279,2.856,2.7.171.049.347.1.528.152a.5.5,0,0,1,.337.338c.049.164.094.326.138.484.4,1.426.819,2.9,2.711,2.9a2.527,2.527,0,0,0,2.523-2.524V19.361a.5.5,0,0,1,.146-.354l4.318-4.319a.5.5,0,0,1,.851.307c.133,1.417.183,2.667.225,3.738C14.761,21.9,14.845,24,17.19,24a2.729,2.729,0,0,0,2.076-.71c1.143-1.16,1.1-3.671,1.016-9.171-.027-1.673-.058-3.623-.062-5.887a.5.5,0,0,1,.147-.355Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default AirplaneIconBuilder;
