import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import HttpAuthFields, {
	validateHttpAuthPassword,
	validateHttpAuthUsername,
} from '~/components/app/HttpAuthFields';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';

import {
	setupHttpAuthentication,
} from '~/actions/websites';



const messages = defineMessages({
	passwordLabel: {
		id: 'ui.websites.form.monitoring.httpAuthentication.password',
	},
	title: {
		id: 'ui.websites.form.monitoring.httpAuthentication.label',
	},
	usernameLabel: {
		id: 'ui.websites.form.monitoring.httpAuthentication.username',
	},
});

const validations = {
	validateHttpAuthPassword,
	validateHttpAuthUsername,
};



type Props = {
	websiteId: CK.WebsiteId,
};

const SetupHttpAuthenticationModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const dispatch = useDispatch();

	const handleSubmit = React.useCallback(
		async (values) => {
			await dispatch(
				setupHttpAuthentication({
					password: values.httpAuthPassword,
					username: values.httpAuthUsername,
					websiteId,
				}),
			);

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			dispatch,
			websiteId,
		],
	);

	return (
		<SimpleModal
			size={SimpleModalSize.Medium}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<CenteredFormWrapper>
				<Form
					defaultFocus="httpAuthUsername"
					onSuccess={handleSubmit}
					validations={validations}
				>
					<HttpAuthFields />

					<FormErrorMessages />

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</CenteredFormWrapper>
		</SimpleModal>
	);
};



export default SetupHttpAuthenticationModal;
