export default function getArrayItemAtSafeIndex<TItem>(
	items: Iterable<TItem>,
	index: number,
): TItem {
	const item = items[index];

	if (item === undefined) {
		throw new Error(
			`Index ${index} doesn't exist in array`,
		);
	}

	return item;
}
