import type CK from '~/types/contentking';

import {
	usePageIsUnreliableQuery,
} from './usePageIsUnreliable.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageIsUnreliable(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageIsUnreliableQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.isUnreliable ?? null;
}



export default usePageIsUnreliable;
