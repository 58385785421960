import type CK from '~/types/contentking';

import {
	useEffectiveHomeAccountIdQuery,
} from './useEffectiveHomeAccountId.gql';

import useAccountId from '~/hooks/useAccountId';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteId from '~/hooks/useWebsiteId';



function useEffectiveHomeAccountId(): CK.AccountId | null {
	const accountId = useAccountId();
	const urlState = useUrlState();
	const websiteId = useWebsiteId();

	const { data } = useEffectiveHomeAccountIdQuery();

	const memberships = data?.authenticatedSession?.memberships ?? [];

	if (urlState.params.websiteId) {
		const membership = memberships.find(
			(membership) => {
				return membership.account.websites.some(
					(website) => website.id === websiteId,
				);
			},
		) ?? memberships.find(
			(membership) => {
				return membership.account.connectedClientAccounts.some(
					(connectedClientAccount) => connectedClientAccount.websites.some(
						(website) => website.id === websiteId,
					),
				);
			},
		) ?? null;

		if (membership !== null) {
			return membership.account.id;
		}
	}

	if (urlState.params.accountId) {
		const membership = memberships.find(
			(membership) => membership.account.id === accountId,
		) ?? memberships.find(
			(membership) => {
				return membership.account.connectedClientAccounts.some(
					(connectedClientAccount) => connectedClientAccount.id === accountId,
				);
			},
		) ?? null;

		if (membership !== null) {
			return membership.account.id;
		}
	}

	if (memberships[0]) {
		return memberships[0].account.id;
	}

	return null;
}



export default useEffectiveHomeAccountId;
