/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAdminDangerousEscapeHatchesFormQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAdminDangerousEscapeHatchesFormQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly isBlacklistDisabled: boolean, readonly isShadowDomFlattened: boolean, readonly pagePropertiesWithDisabledHistoricalTracking: ReadonlyArray<string> } | null } | null };

export type UpdateWebsiteAdminDangerousEscapeHatchesMutationVariables = GraphQL.Exact<{
  isBlacklistDisabled: GraphQL.Scalars['Boolean']['input'];
  isShadowDomFlattened: GraphQL.Scalars['Boolean']['input'];
  pagePropertiesWithDisabledHistoricalTracking: GraphQL.InputMaybe<ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input']>;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteAdminDangerousEscapeHatchesMutation = { readonly __typename?: 'Mutation', readonly AdministerWebsiteSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly isBlacklistDisabled: boolean, readonly isShadowDomFlattened: boolean, readonly pagePropertiesWithDisabledHistoricalTracking: ReadonlyArray<string> } | null } | null } } };


export const WebsiteAdminDangerousEscapeHatchesFormDocument = gql`
    query WebsiteAdminDangerousEscapeHatchesForm($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    adminSettings {
      isBlacklistDisabled
      isShadowDomFlattened
      pagePropertiesWithDisabledHistoricalTracking
    }
  }
}
    `;

/**
 * __useWebsiteAdminDangerousEscapeHatchesFormQuery__
 *
 * To run a query within a React component, call `useWebsiteAdminDangerousEscapeHatchesFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAdminDangerousEscapeHatchesFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAdminDangerousEscapeHatchesFormQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAdminDangerousEscapeHatchesFormQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAdminDangerousEscapeHatchesFormQuery, WebsiteAdminDangerousEscapeHatchesFormQueryVariables> & ({ variables: WebsiteAdminDangerousEscapeHatchesFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAdminDangerousEscapeHatchesFormQuery, WebsiteAdminDangerousEscapeHatchesFormQueryVariables>(WebsiteAdminDangerousEscapeHatchesFormDocument, options);
      }
export function useWebsiteAdminDangerousEscapeHatchesFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAdminDangerousEscapeHatchesFormQuery, WebsiteAdminDangerousEscapeHatchesFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAdminDangerousEscapeHatchesFormQuery, WebsiteAdminDangerousEscapeHatchesFormQueryVariables>(WebsiteAdminDangerousEscapeHatchesFormDocument, options);
        }
export function useWebsiteAdminDangerousEscapeHatchesFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteAdminDangerousEscapeHatchesFormQuery, WebsiteAdminDangerousEscapeHatchesFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAdminDangerousEscapeHatchesFormQuery, WebsiteAdminDangerousEscapeHatchesFormQueryVariables>(WebsiteAdminDangerousEscapeHatchesFormDocument, options);
        }
export type WebsiteAdminDangerousEscapeHatchesFormQueryHookResult = ReturnType<typeof useWebsiteAdminDangerousEscapeHatchesFormQuery>;
export type WebsiteAdminDangerousEscapeHatchesFormLazyQueryHookResult = ReturnType<typeof useWebsiteAdminDangerousEscapeHatchesFormLazyQuery>;
export type WebsiteAdminDangerousEscapeHatchesFormSuspenseQueryHookResult = ReturnType<typeof useWebsiteAdminDangerousEscapeHatchesFormSuspenseQuery>;
export type WebsiteAdminDangerousEscapeHatchesFormQueryResult = Apollo.QueryResult<WebsiteAdminDangerousEscapeHatchesFormQuery, WebsiteAdminDangerousEscapeHatchesFormQueryVariables>;
export const UpdateWebsiteAdminDangerousEscapeHatchesDocument = gql`
    mutation UpdateWebsiteAdminDangerousEscapeHatches($isBlacklistDisabled: Boolean!, $isShadowDomFlattened: Boolean!, $pagePropertiesWithDisabledHistoricalTracking: [String!], $websiteId: WebsiteId!) {
  AdministerWebsiteSettings(
    isBlacklistDisabled: $isBlacklistDisabled
    isShadowDomFlattened: $isShadowDomFlattened
    pagePropertiesWithDisabledHistoricalTracking: $pagePropertiesWithDisabledHistoricalTracking
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        adminSettings {
          isBlacklistDisabled
          isShadowDomFlattened
          pagePropertiesWithDisabledHistoricalTracking
        }
      }
    }
  }
}
    `;
export type UpdateWebsiteAdminDangerousEscapeHatchesMutationFn = Apollo.MutationFunction<UpdateWebsiteAdminDangerousEscapeHatchesMutation, UpdateWebsiteAdminDangerousEscapeHatchesMutationVariables>;

/**
 * __useUpdateWebsiteAdminDangerousEscapeHatchesMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteAdminDangerousEscapeHatchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteAdminDangerousEscapeHatchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteAdminDangerousEscapeHatchesMutation, { data, loading, error }] = useUpdateWebsiteAdminDangerousEscapeHatchesMutation({
 *   variables: {
 *      isBlacklistDisabled: // value for 'isBlacklistDisabled'
 *      isShadowDomFlattened: // value for 'isShadowDomFlattened'
 *      pagePropertiesWithDisabledHistoricalTracking: // value for 'pagePropertiesWithDisabledHistoricalTracking'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteAdminDangerousEscapeHatchesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteAdminDangerousEscapeHatchesMutation, UpdateWebsiteAdminDangerousEscapeHatchesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteAdminDangerousEscapeHatchesMutation, UpdateWebsiteAdminDangerousEscapeHatchesMutationVariables>(UpdateWebsiteAdminDangerousEscapeHatchesDocument, options);
      }
export type UpdateWebsiteAdminDangerousEscapeHatchesMutationHookResult = ReturnType<typeof useUpdateWebsiteAdminDangerousEscapeHatchesMutation>;
export type UpdateWebsiteAdminDangerousEscapeHatchesMutationResult = Apollo.MutationResult<UpdateWebsiteAdminDangerousEscapeHatchesMutation>;
export type UpdateWebsiteAdminDangerousEscapeHatchesMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteAdminDangerousEscapeHatchesMutation, UpdateWebsiteAdminDangerousEscapeHatchesMutationVariables>;