import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
	injectIntl,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import CodeValue from '~/components/patterns/values/CodeValue';
import ExclusionRuleFormLayout from '../components/layout/ExclusionRuleFormLayout';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '../basis/Form';
import SubmitButton, {
	SIZE_SMALL,
	STYLE_HOLLOW,
} from '../components/SubmitButton';
import TextField, {
	TextFieldSize,
} from '../components/TextField';



const messages = defineMessages({
	addRuleFieldButton: {
		id: 'ui.websites.exclusionsModal.manager.addRule',
	},
	addRuleFieldPlaceholder: {
		id: 'ui.websites.exclusionsModal.manager.addRule.fieldPlaceholder',
	},
	formErrors: {
		blank: {
			id: 'ui.formErrors.blank',
		},
		invalid: {
			id: 'ui.modal.urlExclusionList.validationError',
		},
	},
});

const validations = {
	firstlayer: [
		{
			message: (
				<FormattedMessage {...messages.formErrors.blank} />
			),
			field: 'rule',
			rule: ({ values }) => {
				return values.rule && values.rule.length > 0;
			},
		},
	],
	secondLayer: [
		{
			message: (
				<FormattedMessage
					{...messages.formErrors.invalid}
					values={{
						code: (chunks) => (
							<CodeValue>{chunks}</CodeValue>
						),
					}}
				/>
			),
			field: 'rule',
			rule: ({ values }) => {
				if (!values.rule || values.rule.length === 0) {
					return true;
				}

				const firstLetter = values.rule.substr(0, 1);

				return firstLetter === '/' || firstLetter === '*' || firstLetter === 'A';
			},
		},
	],
};



class AddExclusionRuleForm extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleFormSubmit = this._handleFormSubmit.bind(this);

		this.state = {
			inputKey: 0,
		};
	}



	_handleFormSubmit(model) {
		const {
			submitCallback,
		} = this.props;

		const {
			inputKey,
		} = this.state;

		this.setState({
			inputKey: inputKey + 1,
		}, function () {
			if (submitCallback) {
				submitCallback(model);
			}

			this.fieldRef.focus();
		});
	}



	render() {
		const {
			inputKey,
		} = this.state;

		const {
			intl,
		} = this.props;

		return (
			<Form
				defaultValues={{
					rule: '',
				}}
				key={inputKey}
				onSuccess={this._handleFormSubmit}
				validations={validations}
			>
				{({ isValid }) => {
					return (
						<ExclusionRuleFormLayout
							button={(
								<SubmitButton
									icon={(
										<BasicIcon
											size={18}
											type={BasicIconType.Plus}
										/>
									)}
									inactive={!isValid}
									size={SIZE_SMALL}
									style={STYLE_HOLLOW}
								>
									<FormattedMessage {...messages.addRuleFieldButton} />
								</SubmitButton>
							)}
							field={(
								<FieldStatus
									allowOk={false}
									name="secondLayer"
									showIcon={false}
								>
									<TextField
										name="rule"
										placeholder={intl.formatMessage(messages.addRuleFieldPlaceholder)}
										ref={(ref) => this.fieldRef = ref}
										size={TextFieldSize.Small}
										trimValue={true}
										width="100%"
									/>
								</FieldStatus>
							)}
						/>
					);
				}}
			</Form>
		);
	}

}

AddExclusionRuleForm.propTypes = {
	submitCallback: PropTypes.func,
};



export default injectIntl(AddExclusionRuleForm);
