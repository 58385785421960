import Immutable from 'immutable';

import removeDefaultValues from '~/utilities/removeDefaultValues';



export const ISSUE_STATE_CLOSED = 'closed';
export const ISSUE_STATE_NOT_APPLICABLE = 'not_applicable';
export const ISSUE_STATE_NOT_REQUIRED = 'not_required';
export const ISSUE_STATE_OPEN = 'open';
export const ISSUE_STATE_UNKNOWN = 'unknown';

export const ISSUE_PSEUDO_STATE_IGNORED = 'ignored';



export const DEFAULT_FILTER = Immutable.Map({
	state: Immutable.List([
		ISSUE_STATE_CLOSED,
		ISSUE_STATE_NOT_APPLICABLE,
		ISSUE_STATE_NOT_REQUIRED,
		ISSUE_STATE_OPEN,
		ISSUE_STATE_UNKNOWN,
		ISSUE_PSEUDO_STATE_IGNORED,
	]),
	segment: 'all',
});



export function removeDefaultFilterValues(activeFilters) {
	return removeDefaultValues(activeFilters, Immutable.fromJS(DEFAULT_FILTER));
}
