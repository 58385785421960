/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdministerAccountFeaturesMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  features: GraphQL.Scalars['Array']['input'];
}>;


export type AdministerAccountFeaturesMutation = { readonly __typename?: 'Mutation', readonly AdministerAccountFeatures: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null } } };


export const AdministerAccountFeaturesDocument = gql`
    mutation AdministerAccountFeatures($accountId: AccountId!, $features: Array!) {
  AdministerAccountFeatures(accountId: $accountId, features: $features) {
    query {
      account(id: $accountId) {
        features {
          feature
          id
          inUse
        }
        id
      }
    }
  }
}
    `;
export type AdministerAccountFeaturesMutationFn = Apollo.MutationFunction<AdministerAccountFeaturesMutation, AdministerAccountFeaturesMutationVariables>;

/**
 * __useAdministerAccountFeaturesMutation__
 *
 * To run a mutation, you first call `useAdministerAccountFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerAccountFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerAccountFeaturesMutation, { data, loading, error }] = useAdministerAccountFeaturesMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useAdministerAccountFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<AdministerAccountFeaturesMutation, AdministerAccountFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerAccountFeaturesMutation, AdministerAccountFeaturesMutationVariables>(AdministerAccountFeaturesDocument, options);
      }
export type AdministerAccountFeaturesMutationHookResult = ReturnType<typeof useAdministerAccountFeaturesMutation>;
export type AdministerAccountFeaturesMutationResult = Apollo.MutationResult<AdministerAccountFeaturesMutation>;
export type AdministerAccountFeaturesMutationOptions = Apollo.BaseMutationOptions<AdministerAccountFeaturesMutation, AdministerAccountFeaturesMutationVariables>;