import React from 'react';

import ScoreBar from '~/components/patterns/scores/components/ScoreBar';



type Props = {
	value: number | null,
};

const HealthFormatter: React.FC<Props> = (props) => {
	const {
		value,
	} = props;

	if (value !== null) {
		return (
			<div>
				<ScoreBar
					maximum={1000}
					value={value}
				/>
			</div>
		);
	}

	return (
		<div>
			<ScoreBar />
		</div>
	);
};



export default React.memo(HealthFormatter);
