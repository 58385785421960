import React from 'react';

import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountType from '~/hooks/useAccountType';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useRoyalMode from '~/hooks/useRoyalMode';
import useUserEmail from '~/hooks/useUserEmail';
import useUserRole from '~/hooks/useUserRole';

import {
	CONTENTKING_ENVIRONMENT,
} from '~/config';

import {
	pendo,
} from '~/globals';



const Pendo: React.FC = () => {
	const currentUserId = useCurrentUserId();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const currentUserEmail = useUserEmail(currentUserId);
	const currentUserRole = useUserRole(effectiveHomeAccountId, currentUserId);
	const isImpersonated = useRoyalMode().isImpersonated;

	const homeAccountPhase = useAccountPhase(effectiveHomeAccountId);
	const homeAccountType = useAccountType(effectiveHomeAccountId);

	React.useEffect(
		() => {
			if (isImpersonated === true) {
				return;
			}

			if (
				currentUserEmail === null
				|| currentUserRole === null
				|| effectiveHomeAccountId === null
				|| homeAccountPhase === null
				|| homeAccountType === null
			) {
				return;
			}

			pendo().then((pendo) => {
				pendo.initialize({
					visitor: {
						id: currentUserEmail,
						app_language: 'en-US',
						environment: CONTENTKING_ENVIRONMENT,
						role: currentUserRole,
					},
					account: {
						id: effectiveHomeAccountId,
						environment: CONTENTKING_ENVIRONMENT,
						phase: homeAccountPhase,
						type: homeAccountType,
					},
				});
			});
		},
		[
			currentUserEmail,
			currentUserRole,
			effectiveHomeAccountId,
			homeAccountPhase,
			homeAccountType,
			isImpersonated,
		],
	);

	return null;
};



export default Pendo;
