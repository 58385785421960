import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import BarPagesChart from '../components/BarPagesChart';



class UrlDepthChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		this.colors = {
			'1': '#367be2',
			'2': '#367be2',
			'3': '#367be2',
			'4': '#367be2',
			'5': '#367be2',
			'6': '#367be2',
			'7': '#367be2',
			'8': '#367be2',
			'9': '#367be2',
			'10+': '#367be2',
		};

		this.labels = {
			'1': '1',
			'2': '2',
			'3': '3',
			'4': '4',
			'5': '5',
			'6': '6',
			'7': '7',
			'8': '8',
			'9': '9',
			'10+': '10+',
		};
	}



	render() {
		const {
			data,
		} = this.props;

		let reorderedData = data;

		if (data) {
			const lastItem = data.find((item) => item.get('value') === '10+');
			const lastItemIndex = data.findKey((item) => item.get('value') === '10+');
			reorderedData = data.delete(lastItemIndex);
			reorderedData = reorderedData.push(lastItem);
		}

		return (
			<BarPagesChart
				colors={this.colors}
				data={reorderedData}
				labels={this.labels}
				name="url_depth"
			/>
		);
	}

}

UrlDepthChart.propTypes = {
	data: PropTypes.instanceOf(List),
};



export default UrlDepthChart;
