import PropTypes from 'prop-types';
import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import ManageColumnsLink from '../../logic/columnsConfigurator/ManageColumnsLink';
import ExportHeaderButton from '~/components/app/ExportHeaderButton';
import HeaderButtonsLayout from '../screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderLayout from '../../logic/pagesOverview/HeaderLayout';
import HeaderOptionsSwitchLayout from '../screenLayouts/components/header/layouts/HeaderOptionsSwitchLayout';
import HeaderTitle, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '../screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleNumericValue from '../screenLayouts/components/header/headerTitle/builders/HeaderTitleNumericValue';
import HeaderTitleValue, {
	STYLE_DECENT as TITLE_VALUE_STYLE_DECENT,
} from '../screenLayouts/components/header/headerTitle/builders/HeaderTitleValue';
import HeaderTitleLayout from '../screenLayouts/components/header/layouts/HeaderTitleLayout';
import PagesOverviewFilterBar from '../../logic/pagesOverview/activeTableFilterBar/PagesOverviewFilterBar';
import PagesViewSwitch from '../../logic/pagesOverview/PagesViewSwitch';
import TrackedChangesCalendar from '../../logic/pagesOverview/TrackedChangesCalendar';

import {
	usePagesOverviewHeaderQuery,
} from './PagesOverviewHeader.gql';

import useDatatableContext from '~/hooks/useDatatableContext';
import useFilteredPages from '~/hooks/useFilteredPages';
import usePollInterval from '~/hooks/usePollInterval';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ExportType,
} from '~/model/exports';

import {
	calculatePagesOverviewPercentage,
} from '~/model/pages';

import {
	PAGES_SECTION_TABLE,
	pagesSectionValidation,
} from '~/model/ui/pagesScreen';

import {
	totalSelector as totalFilteredGraphPagesSelector,
} from '~/state/pagesGraphs/selectors';



const messages = defineMessages({
	exportButton: {
		id: 'ui.exports.type.general.button.openDialog.label',
	},
	title: {
		id: 'ui.contentOverview.title',
	},
});



function calculatePagesNumber({
	activeFilter,
	pageCapacity,
	pagesSection,
	totalFilteredGraphPages,
	totalFilteredListPages,
}) {
	const totalCount = pagesSection === PAGES_SECTION_TABLE
		? (isNotLoaded(totalFilteredListPages) ? totalFilteredGraphPages : totalFilteredListPages)
		: (isNotLoaded(totalFilteredGraphPages) ? totalFilteredListPages : totalFilteredGraphPages);

	if (totalCount === null) {
		return false;
	}

	return (
		totalCount > pageCapacity
		&& activeFilter.has('type')
		&& activeFilter.get('type').size === 1
		&& activeFilter.get('type').first() === 'page'
	)
		? pageCapacity
		: totalCount;
}



function isNotLoaded(value) {
	return value === undefined || value === null;
}



const PagesOverviewHeader = (props) => {
	const {
		activeFilter,
		pagesSection,
	} = props;

	const filteredPages = useFilteredPages();

	const totalFilteredGraphPages = useSelector(totalFilteredGraphPagesSelector);

	const {
		scrollToColumn,
	} = useDatatableContext();

	const websiteId = useWebsiteId();

	const {
		data,
	} = usePagesOverviewHeaderQuery({
		pollInterval: usePollInterval(30000),
		variables: {
			websiteId,
		},
	});

	const websiteData = (data && data.website) || null;

	const titleSuffix = [];

	let totalFilteredPages;

	if (websiteData !== null) {
		totalFilteredPages = calculatePagesNumber({
			activeFilter,
			pageCapacity: websiteData.pageCapacity,
			pagesSection,
			totalFilteredGraphPages,
			totalFilteredListPages: filteredPages.total,
		});

		const percentage = calculatePagesOverviewPercentage({
			matchedPages: totalFilteredPages,
			totalPages: websiteData.pageTotal,
		});

		if (websiteData.pageTotal > 0 && totalFilteredPages !== false) {
			titleSuffix.push(
				<HeaderTitleNumericValue
					key="title-numeric-value"
					value={totalFilteredPages}
				/>,
			);
		}

		if (percentage !== null) {
			titleSuffix.push(
				<HeaderTitleValue
					key="title-percentage"
					style={TITLE_VALUE_STYLE_DECENT}
					value={(
						<FormattedNumber
							maximumFractionDigits={2}
							minimumFractionDigits={0}
							style="percent"
							value={percentage}
						/>
					)}
				/>,
			);
		}
	}

	const title = (
		<HeaderTitleLayout keepPredefinedWidth={true}>
			<HeaderTitle
				size={TITLE_SIZE_SMALL}
				value={titleSuffix}
			>
				<FormattedMessage {...messages.title} />
			</HeaderTitle>
			<ManageColumnsLink
				allowInterfaceHint={true}
			/>
		</HeaderTitleLayout>
	);

	return (
		<HeaderLayout
			filter={(
				<PagesOverviewFilterBar
					onFilterClickCallback={(filterName) => {
						scrollToColumn(filterName);
					}}
				/>
			)}
			switchElement={websiteData !== null && (
				<HeaderOptionsSwitchLayout>
					<PagesViewSwitch />
				</HeaderOptionsSwitchLayout>
			)}
			title={title}
		>
			<HeaderButtonsLayout>
				{pagesSection === PAGES_SECTION_TABLE && (
					<TrackedChangesCalendar />
				)}

				{pagesSection === PAGES_SECTION_TABLE && (
					<ExportHeaderButton
						exportType={ExportType.ContentOverview}
						linkLabel={(
							<FormattedMessage {...messages.exportButton} />
						)}
						totalFilteredPages={totalFilteredPages}
					/>
				)}
			</HeaderButtonsLayout>
		</HeaderLayout>
	);
};

PagesOverviewHeader.propTypes = {
	activeFilter: PropTypes.any,
	pagesSection: pagesSectionValidation().isRequired,
	totalFilteredGraphPages: PropTypes.number,
};



export default PagesOverviewHeader;
