import React from 'react';

import type CK from '~/types/contentking';

import {
	usePageSegmentsQuery,
} from './usePageSegments.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageSegments(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
): Readonly<Array<string>> | null {
	const { data } = usePageDetailPropertiesQuery(
		usePageSegmentsQuery,
		legacyUrlId,
		websiteId,
	);

	return React.useMemo(
		() => {
			if (!data?.lookupPageByLegacyId?.pageSegments) {
				return null;
			}

			return data.lookupPageByLegacyId.pageSegments.map(
				(pageSegment) => pageSegment.name,
			);
		},
		[
			data?.lookupPageByLegacyId?.pageSegments,
		],
	);
}



export default usePageSegments;
