/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateSchemaOrgMutationVariables = GraphQL.Exact<{
  schemaOrgJsons: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type ValidateSchemaOrgMutation = { readonly __typename?: 'Mutation', readonly ValidateSchemaOrg: { readonly __typename?: 'ValidateSchemaOrgResult', readonly errors: ReadonlyArray<any> } };


export const ValidateSchemaOrgDocument = gql`
    mutation ValidateSchemaOrg($schemaOrgJsons: [String!]!) {
  ValidateSchemaOrg(schemaOrgJsons: $schemaOrgJsons) {
    errors
  }
}
    `;
export type ValidateSchemaOrgMutationFn = Apollo.MutationFunction<ValidateSchemaOrgMutation, ValidateSchemaOrgMutationVariables>;

/**
 * __useValidateSchemaOrgMutation__
 *
 * To run a mutation, you first call `useValidateSchemaOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateSchemaOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateSchemaOrgMutation, { data, loading, error }] = useValidateSchemaOrgMutation({
 *   variables: {
 *      schemaOrgJsons: // value for 'schemaOrgJsons'
 *   },
 * });
 */
export function useValidateSchemaOrgMutation(baseOptions?: Apollo.MutationHookOptions<ValidateSchemaOrgMutation, ValidateSchemaOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateSchemaOrgMutation, ValidateSchemaOrgMutationVariables>(ValidateSchemaOrgDocument, options);
      }
export type ValidateSchemaOrgMutationHookResult = ReturnType<typeof useValidateSchemaOrgMutation>;
export type ValidateSchemaOrgMutationResult = Apollo.MutationResult<ValidateSchemaOrgMutation>;
export type ValidateSchemaOrgMutationOptions = Apollo.BaseMutationOptions<ValidateSchemaOrgMutation, ValidateSchemaOrgMutationVariables>;