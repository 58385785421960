import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import ColumnFilterFormatter from '~/components/logic/filters/ColumnFilterFormatter';
import ColumnName from '~/components/names/ColumnName';
import Declaration from '~/components/patterns/values/declarations/Declaration';



const messages = defineMessages({
	property: {
		id: 'ui.general.propertyDeclaration',
	},
});



type Props = {
	column: CK.PagesColumn,
	value: any,
};

const FilterDefinition: React.FC<Props> = (props) => {
	const {
		column,
		value,
	} = props;

	return (
		<Declaration
			property={(
				<FormattedMessage
					{...messages.property}
					values={{
						text__property: (
							<ColumnName
								column={column}
								textOnly={true}
							/>
						),
					}}
				/>
			)}
			value={(
				<ColumnFilterFormatter
					columnName={column}
					value={value}
				/>
			)}
		/>
	);
};



export default FilterDefinition;
