import classNames from 'classnames';
import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



export enum NativeSelectFieldSize {
	Default = 'default',
	Small = 'small',
}



const messages = defineMessages({
	placeholder: {
		id: 'ui.general.selectFieldPlaceholder',
		defaultMessage: 'Choose',
	},
});



type Option = {
	disabled?: boolean,
	divider?: boolean,
	key?: string,
	label: React.ReactNode,
	labelNative?: string,
	name: string,
};

type Props = {
	attributes?: React.AllHTMLAttributes<HTMLSelectElement>,
	name: string,
	onBlurCallback?: () => void,
	onChangeCallback?: (event: React.ChangeEvent) => void,
	onFocusCallback?: () => void,
	options: ReadonlyArray<Option>,
	placeholder?: boolean | string,
	size: NativeSelectFieldSize,
	tabIndex?: number,
	value?: string,
	width?: React.CSSProperties['width'],
};

const NativeSelectField: React.FC<Props> = (props) => {
	const {
		attributes = {},
		name,
		onBlurCallback,
		onChangeCallback,
		onFocusCallback,
		options,
		placeholder,
		size,
		tabIndex,
		value,
		width = 280,
	} = props;

	const intl = useIntl();

	const style: React.CSSProperties = {};

	if (width) {
		style.width = width;
	}

	const componentClasses = classNames({
		'native-select-field': true,
		'native-select-field--empty': !value,
		'native-select-field--small': size === NativeSelectFieldSize.Small,
	});

	const ref = React.useRef<HTMLDivElement>(null);
	const [strippedOptions, setStrippedOptions] = React.useState<ReadonlyArray<Option>>([]);

	// React only allows raw strings as children of a `option` element. To get the
	// raw string from a (possibly) more complicated option we render the `label`
	// of that option to an offscreen element. We then grab the `innerText` of
	// that element and use that to render a `option` element within our `select`.
	React.useEffect(() => {
		if (!ref.current || !ref.current.children as any) {
			return;
		}

		const nextOptions: Array<Option> = [];
		Array.from(ref.current.children).forEach((child: HTMLElement, i) => {
			const option = getArrayItemAtSafeIndex(options, i);

			nextOptions.push({
				...option,
				label: child.innerText,
			});
		});

		setStrippedOptions(nextOptions);
	}, [options]);

	const needsPlaceholder = placeholder && !value;

	return (
		<div
			className={componentClasses}
			style={style}
		>
			<select
				className="native-select-field__field"
				id={name}
				name={name}
				onBlur={onBlurCallback}
				onChange={onChangeCallback}
				onFocus={onFocusCallback}
				tabIndex={tabIndex}
				value={needsPlaceholder ? '' : value}
				{...attributes}
			>
				{needsPlaceholder && (
					<option
						disabled={true}
						value=""
					>
						{placeholder !== true
							? placeholder
							: intl.formatMessage(messages.placeholder)}
					</option>
				)}
				{strippedOptions.map((option) => (
					<option
						disabled={option.disabled || option.divider}
						key={option.name}
						value={option.name}
					>
						{option.label}
					</option>
				))}
			</select>

			<div
				className="native-select-field__off-screen"
				hidden={true}
				ref={ref}
			>
				{options.map((option) => (
					<span key={option.name}>
						{option.divider ? '———' : (option.labelNative || option.label)}
					</span>
				))}
			</div>
		</div>
	);
};



export default NativeSelectField;
