import React from 'react';

import type DistributionBarChart from '~/components/patterns/charts/charts/DistributionBarChart';



type Props = {
	/** Attached distribution bar chart for better overview about filtering values */
	distributionBarChart?: React.ReactElement<typeof DistributionBarChart> | false,
	/** Filter field */
	field?: React.ReactNode,
	/** Filtering flag show at the bottom with additional info */
	filteringFlag?: React.ReactNode,
	/** Label text */
	label: React.ReactNode,
};



const FilterFieldLayout: React.FC<Props> = (props) => {
	const {
		distributionBarChart,
		field,
		filteringFlag,
		label,
	} = props;

	return (
		<div className="filter-field-layout">
			{label && (
				<div className="filter-field-layout__label">
					{label}
				</div>
			)}
			{(field || field === false) && (
				<div className="filter-field-layout__field">
					{field}
				</div>
			)}
			{(distributionBarChart || distributionBarChart === false) && (
				<div className="filter-field-layout__bar-chart">
					{distributionBarChart}
				</div>
			)}
			{filteringFlag && (
				<div className="filter-field-layout__flag">
					{filteringFlag}
				</div>
			)}
		</div>
	);
};



export default FilterFieldLayout;
