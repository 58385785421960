import React from 'react';

import type GraphQL from '~/types/graphql';

import {
	useAlertTypeDefinitionsQuery,
} from './useAlertTypeDefinitions.gql';



type AlertTypeDefinition = {
	hasScope: GraphQL.AlertTypeDefinition['hasScope'],
	hasSensitivity: GraphQL.AlertTypeDefinition['hasSensitivity'],
	type: GraphQL.AlertTypeDefinition['type'],
};

type AlertTypeDefinitions = {
	getByType: (type: string) => AlertTypeDefinition | null,
	isLoaded: boolean,
	listAll: () => Readonly<Array<AlertTypeDefinition>>,
};



function useAlertTypeDefinitions(): AlertTypeDefinitions {
	const { data } = useAlertTypeDefinitionsQuery();

	const alertTypeDefinitions = data?.alertTypeDefinitions ?? null;

	return React.useMemo(
		() => {
			return {
				getByType: (type: string) => alertTypeDefinitions?.find((alertTypeDefinition) => alertTypeDefinition.type === type) ?? null,
				isLoaded: alertTypeDefinitions !== null,
				listAll: () => alertTypeDefinitions ?? [],
			};
		},
		[
			alertTypeDefinitions,
		],
	);
}



export default useAlertTypeDefinitions;
