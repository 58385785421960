import classNames from 'classnames';
import React from 'react';

import TextInspector from '~/components/patterns/typography/TextInspector';



export enum StringSelectionHighlightType {
	Highlighted = 'highlighted',
	Selection = 'selection',
	Underlined = 'underlined',
}

type Props = {
	children: string,
	highlightEndIndex: number,
	highlightStartIndex: number,
	/** Possibility to set different type of highlight */
	highlightType?: StringSelectionHighlightType,
};



const StringSelectionHighlight: React.FC<Props> = (props) => {
	const {
		highlightEndIndex,
		highlightStartIndex,
		highlightType = StringSelectionHighlightType.Selection,
		children,
	} = props;

	const text = children;
	const textLength = children.length;
	const highlightedBlankCharacters: Array<React.ReactElement> = [];

	if (highlightEndIndex > textLength) {
		// Size of this element will be 0.5px. To create fake whitespace
		// we will create 10-times more elements.
		for (let i = 0; i < (highlightEndIndex - textLength) * 10; i++) {
			highlightedBlankCharacters.push(
				<span
					className="string-selection-highlight__blank-character"
					key={'highlighted-blank-char-' + i}
				/>,
			);
		}
	}

	const highlightClasses = classNames({
		'string-selection-highlight': true,
		'string-selection-highlight--highlighted': highlightType === StringSelectionHighlightType.Highlighted,
		'string-selection-highlight--underlined': highlightType === StringSelectionHighlightType.Underlined,
	});

	return (
		<>
			<TextInspector text={text.slice(0, highlightStartIndex)} />
			<i className={highlightClasses}>
				<TextInspector text={text.slice(highlightStartIndex, highlightEndIndex)} />
				{highlightedBlankCharacters}
			</i>
			<TextInspector text={text.slice(highlightEndIndex, textLength)} />
		</>
	);
};



export default StringSelectionHighlight;
