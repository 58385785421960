import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const IssuesIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m26 26h-26v-26h26z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				>
					<path d="m19.5290179 14.3990753c0 4.8939652-2.9231346 8.8608099-6.5290179 8.8608099-3.60588329 0-6.52901786-3.9668447-6.52901786-8.8608099 0-4.89396529 2.92313457-8.86080999 6.52901786-8.86080999 3.6058833 0 6.5290179 3.9668447 6.5290179 8.86080999z" />
					<path d="m7.24756218 10.2054871 11.48454242-.0037309" />
					<path d="m6.47098214 13-2.79815051-.9327168v-1.8654337" />
					<path d="m7.24756218 18.6342626-2.6423868.8944754v1.8654337" />
					<path d="m19.5290179 13 2.7981505-.9327168v-1.8654337" />
					<path d="m18.7196062 18.6505851 2.6750319.8786193v1.8654337" />
					<path d="m10.4968678 6.17726961s.0540976-2.3877551-2.16110489-3.43706154" />
					<path d="m15.7849992 6.38256059s-.0410395-2.59295281 2.1741629-3.64225925" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default IssuesIconBuilder;

