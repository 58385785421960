import React from 'react';

import {
	ButtonSize,
} from '~/components/patterns/buttons/Button';
import ButtonContextProvider from '~/components/atoms/buttons/ButtonContextProvider';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	dateRangePicker?: React.ReactNode,
	otherButtons?: React.ReactNode,
	scopeSelection?: React.ReactNode,
	title: React.ReactNode,
};



const DashboardHeader: React.FC<Props> = (props) => {
	const {
		dateRangePicker,
		otherButtons,
		scopeSelection,
		title,
	} = props;

	const viewportType = useViewportType();

	return (
		<ButtonContextProvider
			compact={viewportType.isSmall}
			iconColor="#5893eb"
			iconSize={24}
			size={ButtonSize.Small}
			uppercase={true}
		>
			<section className="dashboard-header">
				<div className="dashboard-header__container">
					<div className="dashboard-header__title">
						{title}
					</div>

					<div className="dashboard-header__buttons">
						<HeaderButtonsLayout>
							{!viewportType.isSmall && dateRangePicker}
							{!viewportType.isSmall && scopeSelection}
							{otherButtons}
						</HeaderButtonsLayout>
					</div>
				</div>

				{viewportType.isSmall && (
					<div className="dashboard-header__footer">
						<div className="dashboard-header__date-range-picker">
							{dateRangePicker}
						</div>

						{scopeSelection && (
							<div className="dashboard-header__scope-selection">
								{scopeSelection}
							</div>
						)}
					</div>
				)}
			</section>
		</ButtonContextProvider>
	);
};



export default DashboardHeader;
