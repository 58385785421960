import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CoinsIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M8.222,19.475a.248.248,0,0,0,.239-.249V16.885a.25.25,0,0,0-.261-.25c-.4.018-.8.027-1.2.027a18.6,18.6,0,0,1-6.2-.9A.25.25,0,0,0,.461,16v2.153C.461,18.9,3.388,19.5,7,19.5Q7.627,19.5,8.222,19.475Z"
				fill={color}
			/>
			<path
				d="M7,7.662a14.944,14.944,0,0,0,6.406-1.087.249.249,0,0,0,.133-.221V4a.25.25,0,0,0-.341-.233,18.6,18.6,0,0,1-6.2.9,18.6,18.6,0,0,1-6.2-.9A.25.25,0,0,0,.461,4V6.355a.249.249,0,0,0,.133.22A14.944,14.944,0,0,0,7,7.662Z"
				fill={color}
			/>
			<path
				d="M7,3.662a14.944,14.944,0,0,0,6.406-1.087.249.249,0,0,0,.133-.221V1.933C13.539,1.188,10.612.582,7,.582S.461,1.188.461,1.933v.422a.249.249,0,0,0,.133.22A14.944,14.944,0,0,0,7,3.662Z"
				fill={color}
			/>
			<path
				d="M7,15.662c.427,0,.834-.011,1.223-.029a.25.25,0,0,0,.238-.25V13.851a2.325,2.325,0,0,1,.145-.885.248.248,0,0,0-.03-.237.252.252,0,0,0-.216-.1c-.453.024-.908.037-1.36.037a18.6,18.6,0,0,1-6.2-.9A.25.25,0,0,0,.461,12v2.359a.249.249,0,0,0,.133.22A14.944,14.944,0,0,0,7,15.662Z"
				fill={color}
			/>
			<path
				d="M7,11.662a21,21,0,0,0,6.4-1.087.25.25,0,0,0,.134-.221V8a.25.25,0,0,0-.341-.233,18.6,18.6,0,0,1-6.2.9,18.6,18.6,0,0,1-6.2-.9A.25.25,0,0,0,.461,8v2.359a.249.249,0,0,0,.133.22A14.944,14.944,0,0,0,7,11.662Z"
				fill={color}
			/>
			<path
				d="M10.8,15.681a.25.25,0,0,0-.341.233v2.358a.249.249,0,0,0,.133.221A14.944,14.944,0,0,0,17,19.58a14.944,14.944,0,0,0,6.406-1.087.25.25,0,0,0,.133-.221V15.914a.25.25,0,0,0-.341-.233,18.6,18.6,0,0,1-6.2.9A18.6,18.6,0,0,1,10.8,15.681Z"
				fill={color}
			/>
			<path
				d="M17,20.58a18.6,18.6,0,0,1-6.2-.9.25.25,0,0,0-.341.233v2.153c0,.747,2.927,1.351,6.539,1.351s6.539-.6,6.539-1.351V19.914a.25.25,0,0,0-.341-.233A18.6,18.6,0,0,1,17,20.58Z"
				fill={color}
			/>
			<path
				d="M10.461,13.851v.421a.249.249,0,0,0,.133.221A14.944,14.944,0,0,0,17,15.58a14.944,14.944,0,0,0,6.406-1.087.249.249,0,0,0,.133-.221v-.421c0-.745-2.927-1.351-6.539-1.351S10.461,13.106,10.461,13.851Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CoinsIconBuilder;
