import type React from 'react';

import {
	isFunction,
} from '~/utilities/typeCheck';



export type RenderProp<ChildProps> =
	((props: ChildProps) => React.ReactNode) | React.ReactNode;



export function renderProp<ExpectedChildProps, PassedChildProps extends ExpectedChildProps>(
	renderFn: RenderProp<ExpectedChildProps> | undefined | null,
	childProps: PassedChildProps,
): React.ReactElement | null {
	if (renderFn === undefined || renderFn === null) {
		return null;
	}

	return (
		isFunction(renderFn)
			? renderFn(childProps)
			: renderFn
	) as React.ReactElement | null;
}

