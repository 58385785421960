import classNames from 'classnames';
import React from 'react';

import styles from './LazyImage.module.scss';



const cache: Record<string, HTMLImageElement> = {};



type Props = {
	animated?: boolean,
	height: React.CSSProperties['height'],
	placeholder?: React.ReactNode,
	src: string | null,
	width: React.CSSProperties['width'],
};

const LazyImage: React.FC<Props> = (props) => {
	const {
		animated = true,
		height,
		placeholder,
		src,
		width,
	} = props;

	const [image, setImage] = React.useState<HTMLImageElement | null>(
		src !== null ? (cache[src] ?? null) : null,
	);

	React.useLayoutEffect(
		() => {
			if (src !== null && !cache[src]) {
				setImage(null);

				const image = new Image();

				image.onload = () => {
					setImage(image);
					cache[src] = image;
				};

				image.src = src;
			} else if (src === null) {
				setImage(null);
			}
		},
		[
			src,
		],
	);

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.animated]: animated,
	});

	return (
		<div
			className={componentClasses}
			style={{
				height,
				width,
			}}
		>
			{placeholder && (
				<div className={styles.placeholder}>
					{placeholder}
				</div>
			)}

			{image !== null && (
				<img
					className={styles.img}
					height={height}
					src={image.src}
					width={width}
				/>
			)}
		</div>
	);
};



export default React.memo(LazyImage);
