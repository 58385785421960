import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ArtIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M23.92,11.237A12.337,12.337,0,0,0,14.935.868,9.789,9.789,0,0,0,9.159.84a12.483,12.483,0,0,0-8.8,8.906A11.918,11.918,0,0,0,3.518,21.131a2.487,2.487,0,0,0,3.081.358,1.5,1.5,0,0,0,.256-2.334l-.809-.81a1,1,0,0,1,0-1.414,1.1,1.1,0,0,1,1.6-.189c1.41,1.409,5.62,5.656,5.622,5.657a3.985,3.985,0,0,0,2.815,1.183,3.751,3.751,0,0,0,1.928-.53A12.018,12.018,0,0,0,23.92,11.237Zm-9.443,7.626a2.506,2.506,0,0,1,0-3.537,2.562,2.562,0,0,1,3.535,0,2.5,2.5,0,1,1-3.535,3.537ZM14.245,7.2a2,2,0,0,1,2-2h0a2,2,0,1,1-2,2Zm3.7,6.01h0a1.5,1.5,0,1,1,2.121,0A1.5,1.5,0,0,1,17.942,13.205ZM11.649,4.72a2,2,0,1,1-2.827,0A2.048,2.048,0,0,1,11.649,4.72Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ArtIconBuilder;
