import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountDetailedDiscounts from '~/hooks/useAccountDetailedDiscounts';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountTaxRate from '~/hooks/useAccountTaxRate';
import useAccountType from '~/hooks/useAccountType';
import usePageBundle from '~/hooks/usePageBundle';
import useTariffs from '~/hooks/useTariffs';

import {
	DETAIL_PLAN,
} from '~/model/pricing/universal';

import {
	calculatePurchaseCostDetails,
	createPurchase,
} from '~/model/pricing/costs';



type Input = {
	billingCycle?: GraphQL.Term | null,
	pageBundle?: number | null,
	plan?: GraphQL.AccountPlan | null,
};

type ReturnType = (input?: Input) => (Record<string, any> | null);



function useCalculatePrice(accountId: CK.AccountId | null): ReturnType {
	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountCurrency = useAccountCurrency(accountId);
	const accountDiscounts = useAccountDetailedDiscounts(accountId);
	const accountPageBundle = usePageBundle(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountTax = useAccountTaxRate(accountId);
	const accountType = useAccountType(accountId);
	const tariffs = useTariffs();

	return React.useCallback(
		({
			billingCycle,
			pageBundle,
			plan,
		} = {}) => {
			if (
				accountCurrency === null
				|| accountDiscounts === null
				|| accountTariff === null
				|| accountType === null
				|| tariffs.isReady === false
			) {
				return null;
			}

			billingCycle ??= accountBillingCycle;
			pageBundle ??= accountPageBundle;
			plan ??= accountPlan;

			if (
				billingCycle === null
				|| pageBundle === null
				|| plan === null
			) {
				return null;
			}

			const discounts = accountDiscounts.map((discount) => {
				const amount = {};

				if (discount.amount !== null) {
					discount.amount.forEach(({ currency, value }) => {
						amount[currency] = value / 100;
					});
				}

				return {
					amount,
					code: discount.coupon,
					rate: discount.percentage !== null
						? discount.percentage / 100
						: null,
					type: discount.type,
				};
			});

			return calculatePurchaseCostDetails({
				accountType,
				billingCycle,
				currency: accountCurrency,
				discounts,
				purchases: [
					createPurchase({
						details: {
							[DETAIL_PLAN]: plan,
						},
						numberOfPages: pageBundle,
					}),
				],
				tariff: accountTariff,
				tariffs: tariffs.tariffs,
				taxRate: accountTax?.rate ?? 0.0,
				taxType: accountTax?.type ?? null,
			});
		},
		[
			accountBillingCycle,
			accountCurrency,
			accountDiscounts,
			accountPageBundle,
			accountPlan,
			accountTariff,
			accountTax,
			accountType,
			tariffs.isReady,
			tariffs.tariffs,
		],
	);
}



export default useCalculatePrice;
