import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import Copy from '~/components/logic/Copy';
import ItemPerLineTextArea from '~/components/app/ItemPerLineTextArea';



const messages = defineMessages({
	instruction: {
		id: 'ui.staticSegmentUrlsField.instruction',
	},
	placeholder: {
		id: 'ui.staticSegmentUrlsField.placeholder',
	},
});



type Props = {
	domain: string | null,
	maximumAmount: number,
	name: string,
};

const StaticSegmentUrlsField: React.FC<Props> = (props) => {
	const {
		domain,
		maximumAmount,
		name,
	} = props;

	const intl = useIntl();

	return (
		<ItemPerLineTextArea
			instruction={(
				<Copy
					{...messages.instruction}
					values={{
						domain: domain ?? '',
						maximumAmount,
					}}
				/>
			)}
			name={name}
			placeholder={intl.formatMessage(messages.placeholder)}
		/>
	);
};



export default StaticSegmentUrlsField;
