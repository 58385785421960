import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum SnapshotIconVariants {
	Default = 'default',
	NotAvailable = 'not-available',
}



type SnapshotIconBuilderProps = {
	variant?: SnapshotIconVariants,
};

const SnapshotIconBuilder: IconBuilder<SnapshotIconBuilderProps> = {
	icon: (props) => {
		const {
			color,
			variant = SnapshotIconVariants.Default,
		} = props;

		if (variant === SnapshotIconVariants.NotAvailable) {
			return (
				<g
					fill="none"
					fillRule="evenodd"
					id="Page-1"
					opacity=".7"
					stroke="none"
					strokeWidth="1"
				>
					<g
						id="Slice"
						stroke={color ?? '#99acbf'}
						transform="translate(4.000000, 4.000000)"
					>
						<path
							d="M9.69016338,4.84507042 C9.23945915,4.61971831 8.67607887,4.3943662 8.00002254,4.3943662 C5.97185352,4.3943662 4.39438873,5.97183099 4.39438873,8 C4.39438873,8.22535211 4.39438873,8.33802817 4.39438873,8.56338028"
							id="Path"
						>
						</path>
						<path
							d="M7.43659718,11.6056338 C7.6619493,11.6056338 7.77462535,11.6056338 7.99997746,11.6056338 C9.80279437,11.6056338 11.2676056,10.2535211 11.6056338,8.56338028"
							id="Path"
						>
						</path>
						<path
							d="M3.60564507,14.7605634 L14.8732394,14.7605634 C15.5492958,14.7605634 16,14.3098592 16,13.6338028 L16,5.07042254"
							id="Path"
						>
						</path>
						<path
							d="M12.9577465,2.02816901 L12.056338,2.02816901 L12.056338,0.563380282 C12.056338,0.225352113 11.8309859,0 11.4929577,0 L4.3943662,0 C4.05633803,0 3.83098592,0.225352113 3.83098592,0.563380282 L3.83098592,2.02816901 L1.12676056,2.02816901 C0.450704225,2.02816901 0,2.47887324 0,3.15492958 L0,11.943662"
							id="Path"
						>
						</path>
						<line
							id="Path"
							x1="0"
							x2="16"
							y1="14.7605634"
							y2="2.36617465"
						>
						</line>
					</g>
				</g>
			);
		}

		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				opacity=".7"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="icon_snapshot"
					stroke={color ?? '#367be2'}
					transform="translate(4.000000, 4.000000)"
				>
					<path
						d="M7.99995544,11.5894675 C9.99776578,11.5894675 11.6172693,9.9699414 11.6172693,7.97211976 C11.6172693,5.97429812 9.99776578,4.3547494 7.99995544,4.3547494 C6.0021338,4.3547494 4.38258508,5.97429812 4.38258508,7.97211976 C4.38258508,9.9699414 6.0021338,11.5894675 7.99995544,11.5894675 Z"
						id="Path"
					>
					</path>
					<path
						d="M14.9564273,1.98954918 L12.1738521,1.98954918 L12.1738521,0.368689519 C12.1738521,0.270911756 12.1349761,0.17713461 12.0659259,0.107982723 C11.9967627,0.0388421369 11.902963,0 11.8052078,0 L4.19478216,0 C4.0969931,0 4.00321595,0.0388421369 3.93407537,0.107982723 C3.86493478,0.17713461 3.82609264,0.270911756 3.82609264,0.368689519 L3.82609264,1.98954918 L1.04349484,1.98954918 C0.764853082,1.99501895 0.499456299,2.10955635 0.304352821,2.30857003 C0.109238043,2.50758372 1.00374594e-15,2.77519553 1.00374594e-15,3.0538938 L1.00374594e-15,13.7251635 C-0.00183668166,14.0044155 0.106842194,14.2730444 0.302341212,14.4723971 C0.49782893,14.6718629 0.764265421,14.7857787 1.04349484,14.7895081 L14.9564273,14.7895081 C15.2356794,14.7857787 15.5021611,14.6718629 15.6975584,14.4723971 C15.8930687,14.2730444 16.001786,14.0044155 16,13.7251635 L16,3.0538938 C16,2.77519553 15.8906954,2.50758372 15.6956372,2.30857003 C15.5004659,2.10955635 15.2351143,1.99501895 14.9564273,1.98954918 L14.9564273,1.98954918 Z"
						id="Path"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SnapshotIconBuilder;

