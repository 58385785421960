import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MetaDescriptionIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<polygon
					id="Stroke-3"
					points="44.364168 48.0609048 14.7884257 48.0609048 14.7884257 11.0953857 31.423741 11.0953857 44.364168 24.0339643 44.364168 24.0284192"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 2}
				>
				</polygon>
				<polyline
					id="Stroke-4"
					points="44.363059 24.0287889 31.4244804 24.0287889 31.4244804 11.0902102"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 2}
				>
				</polyline>
				<polyline
					id="Stroke-5"
					points="22.3995715 30.9263414 18.7028348 34.6230782 22.3995715 38.3198149"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 1.5}
				>
				</polyline>
				<path
					d="M26.1248331,30.6092107 L33.0271691,30.6092107"
					id="Stroke-30"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 1.5}
				>
				</path>
				<path
					d="M26.1248331,34.6092107 L33.0271691,34.6092107"
					id="Stroke-30"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 1.5}
				>
				</path>
				<path
					d="M26.1248331,38.6092107 L33.0271691,38.6092107"
					id="Stroke-30"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 1.5}
				>
				</path>
				<polyline
					id="Stroke-6"
					points="36.7504344 30.9263414 40.4471712 34.6230782 36.7504344 38.3198149"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={(60 / size) * 1.5}
				>
				</polyline>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default MetaDescriptionIconBuilder;

