import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const CustomerLogosList: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const elements = React.Children.toArray(children).filter((child) => !!child);

	return (
		<div className="customer-logos-list">
			{elements.map((child, index) => {
				return (
					<div
						className="customer-logos-list__logo"
						key={index}
					>
						{child}
					</div>
				);
			})}
		</div>
	);
};



export default CustomerLogosList;
