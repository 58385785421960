import classNames from 'classnames';
import React from 'react';



export enum NumberOfAffectedPagesImpact {
	Negative = 'negative',
	Neutral = 'neutral',
	Positive = 'positive',
}



type Props = {
	children?: React.ReactNode,
	impact?: NumberOfAffectedPagesImpact,
};



const NumberOfAffectedPages: React.FC<Props> = (props) => {
	const {
		children,
		impact = NumberOfAffectedPagesImpact.Neutral,
	} = props;

	const componentClasses = classNames({
		'number-of-affected-pages': true,
		'number-of-affected-pages--positive-impact': impact === NumberOfAffectedPagesImpact.Positive,
		'number-of-affected-pages--negative-impact': impact === NumberOfAffectedPagesImpact.Negative,
	});

	return (
		<span className={componentClasses}>
			{children}
		</span>
	);
};



export default NumberOfAffectedPages;
