import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import SegmentFilterSegment from '~/components/atoms/segments/filters/SegmentFilterSegment';



const messages = defineMessages({
	title: {
		id: 'ui.scopes.website.title',
	},
});



const SegmentFilterWebsiteScopeLabel: React.FC = () => {
	return (
		<SegmentFilterSegment
			color="#F3F3F3"
			inversedColors={true}
			label={(
				<FormattedMessage {...messages.title} />
			)}
			symbol={(
				<BasicIcon
					size={16}
					type={BasicIconType.Globe}
				/>
			)}
		/>
	);
};



export default SegmentFilterWebsiteScopeLabel;
