import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import MultiselectField from '~/components/atoms/forms/components/MultiselectField';

import {
	CUSTOM_ELEMENT_CHANGE_TYPES_FOR_DATA_TYPE,
	CUSTOM_ELEMENT_CHANGE_TYPE_ADDED,
	CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED,
	CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED_TO_FALSE,
	CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED_TO_TRUE,
	CUSTOM_ELEMENT_CHANGE_TYPE_DECREASED,
	CUSTOM_ELEMENT_CHANGE_TYPE_INCREASED,
	CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_BACKWARD,
	CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_FORWARD,
	CUSTOM_ELEMENT_CHANGE_TYPE_REMOVED,
} from '~/model/alerts';



const messages = defineMessages({
	[CUSTOM_ELEMENT_CHANGE_TYPE_ADDED]: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes.options.added',
	},
	[CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED]: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes.options.changed',
	},
	[CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED_TO_FALSE]: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes.options.changedToFalse',
	},
	[CUSTOM_ELEMENT_CHANGE_TYPE_CHANGED_TO_TRUE]: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes.options.changedToTrue',
	},
	[CUSTOM_ELEMENT_CHANGE_TYPE_DECREASED]: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes.options.decreased',
	},
	[CUSTOM_ELEMENT_CHANGE_TYPE_INCREASED]: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes.options.increased',
	},
	[CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_BACKWARD]: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes.options.movedBackward',
	},
	[CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_FORWARD]: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes.options.movedForward',
	},
	[CUSTOM_ELEMENT_CHANGE_TYPE_REMOVED]: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes.options.removed',
	},
});



type Props = {
	dataType?: 'string' | 'date' | 'number' | 'integer' | 'boolean',
	name: string,
	isDisabled?: boolean,
};

const CustomElementChangeTypesField: React.FC<Props> = (props) => {
	const {
		dataType,
		name,
		isDisabled,
	} = props;

	let options: Array<{
		title: React.ReactNode,
		name: string,
	}> = [];

	if (dataType) {
		const changeTypes = CUSTOM_ELEMENT_CHANGE_TYPES_FOR_DATA_TYPE[dataType];

		options = changeTypes.map((changeType) => {
			const message = messages[changeType]
				? <FormattedMessage {...messages[changeType]} />
				: changeType;

			return {
				title: message,
				name: changeType,
			};
		});
	}

	return (
		<MultiselectField
			isDisabled={isDisabled}
			isOnlyLinkVisible={true}
			name={name}
			options={options}
		/>
	);
};



export default CustomElementChangeTypesField;
