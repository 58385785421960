import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';

import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	action: {
		id: 'ui.lighthouseMonitoringNotEnabled.action',
	},
	message: {
		id: 'ui.lighthouseMonitoringNotEnabled.message',
	},
});



type Props = {
	fullwidth?: boolean,
};

const LighthouseMonitoringNotEnabledMessage: React.FC<Props> = (props) => {
	const {
		fullwidth,
	} = props;

	const websiteId = useWebsiteId();

	return (
		<InterfaceMessage
			ctaElement={(
				<Button
					linkRouteName="website.detail.monitoring"
					linkRouteParams={{
						websiteId,
					}}
					size={ButtonSize.Small}
					style={ButtonStyle.Hollow}
					uppercase={true}
				>
					<FormattedMessage {...messages.action} />
				</Button>
			)}
			fullwidth={fullwidth}
		>
			<FormattedMessage {...messages.message} />
		</InterfaceMessage>
	);
};



export default LighthouseMonitoringNotEnabledMessage;
