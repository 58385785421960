import React from 'react';

import type ValidationMessage from '~/components/patterns/messages/embedded/ValidationMessage';



type Props = {
	button: React.ReactNode,
	children?: React.ReactNode, /** Control elements can be attached to this target */
	validationMessage?: React.ReactElement<typeof ValidationMessage>,
};



const AttachedFormControlElement: React.FC<Props> = (props) => {
	const {
		button,
		children,
		validationMessage,
	} = props;

	return (
		<div className="attached-form-control-element">
			{children && (
				<div className="attached-form-control-element__target">
					{children}
				</div>
			)}
			<div className="attached-form-control-element__control-element">
				{validationMessage && (
					<div className="attached-form-control-element__validation-message">
						{validationMessage}
					</div>
				)}
				<div className="attached-form-control-element__button">
					{button}
				</div>
			</div>
		</div>
	);
};



export default AttachedFormControlElement;
