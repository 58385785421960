/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserFirstNameQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserFirstNameQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly firstName: string | null, readonly id: string, readonly legacyId: string } | null };


export const UserFirstNameDocument = gql`
    query UserFirstName($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    firstName
    id
    legacyId
  }
}
    `;

/**
 * __useUserFirstNameQuery__
 *
 * To run a query within a React component, call `useUserFirstNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFirstNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFirstNameQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserFirstNameQuery(baseOptions: Apollo.QueryHookOptions<UserFirstNameQuery, UserFirstNameQueryVariables> & ({ variables: UserFirstNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFirstNameQuery, UserFirstNameQueryVariables>(UserFirstNameDocument, options);
      }
export function useUserFirstNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFirstNameQuery, UserFirstNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFirstNameQuery, UserFirstNameQueryVariables>(UserFirstNameDocument, options);
        }
export function useUserFirstNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserFirstNameQuery, UserFirstNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserFirstNameQuery, UserFirstNameQueryVariables>(UserFirstNameDocument, options);
        }
export type UserFirstNameQueryHookResult = ReturnType<typeof useUserFirstNameQuery>;
export type UserFirstNameLazyQueryHookResult = ReturnType<typeof useUserFirstNameLazyQuery>;
export type UserFirstNameSuspenseQueryHookResult = ReturnType<typeof useUserFirstNameSuspenseQuery>;
export type UserFirstNameQueryResult = Apollo.QueryResult<UserFirstNameQuery, UserFirstNameQueryVariables>;