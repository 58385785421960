import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import CreateLogFileAnalysisS3BucketForm from '~/components/app/CreateLogFileAnalysisS3BucketForm';
import ModalPanel, {
	SIZE_XXLARGE as MODAL_SIZE_XXLARGE,
} from '~/components/atoms/panels/ModalPanel';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModal from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';

import useModals from '~/hooks/useModals';



const messages = defineMessages({
	confirmation: {
		id: 'ui.installFastlyLogStreamingModal.confirmation',
	},
	description: {
		id: 'ui.installFastlyLogStreamingModal.description',
	},
	ok: {
		id: 'ui.general.ok',
	},
	oneMoreThing: {
		id: 'ui.installFastlyLogStreamingModal.oneMoreThing',
	},
	sidebar: {
		id: 'ui.installFastlyLogStreamingModal.sidebar',
	},
	title: {
		id: 'ui.installFastlyLogStreamingModal.title',
	},
});



const InstallFastlyLogStreamingModal: React.FC = () => {
	const modals = useModals();

	return (
		<ModalPanel
			onCloseCallback={modals.closeCallback}
			size={MODAL_SIZE_XXLARGE}
		>
			<MultiStepModal>
				<MultiStepModalStep
					name="create-bucket"
					sidebar={(
						<RichText>
							<Copy
								{...messages.sidebar}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-fastly-integration/#2-configuring-the-fastly-real-time-log-streaming'),
								}}
							/>
						</RichText>
					)}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<>
						<ModalTextSection>
							<RichText>
								<Copy
									{...messages.description}
									values={{
										linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-fastly-integration/#2-configuring-the-fastly-real-time-log-streaming'),
									}}
								/>
							</RichText>
						</ModalTextSection>

						<CreateLogFileAnalysisS3BucketForm
							logSourceProvider={GraphQL.LogSourceProvider.Fastly}
							showAccessFields={true}
							showDomainField={true}
						/>
					</>
				</MultiStepModalStep>

				<MultiStepModalStep
					name="one-more-thing"
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					{({ isActive }) => (isActive && (
						<ConfirmationMessage
							animate={true}
							context={ConfirmationMessageContext.Pending}
							ctaButtons={(
								<Button
									onClick={() => modals.closeCallback()}
									style={ButtonStyle.Action}
									width={ButtonWidth.SubmitButton}
								>
									<FormattedMessage {...messages.ok} />
								</Button>
							)}
							message={(
								<FormattedMessage {...messages.oneMoreThing} />
							)}
						>
							<RichText>
								<Copy
									{...messages.confirmation}
									values={{
										linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-fastly-integration/#2-configuring-the-fastly-real-time-log-streaming'),
									}}
								/>
							</RichText>
						</ConfirmationMessage>
					))}
				</MultiStepModalStep>
			</MultiStepModal>

		</ModalPanel>
	);
};



export default InstallFastlyLogStreamingModal;
