import {
	CONTENTKING_ENVIRONMENT,
	RECURLY_PUBLIC_KEY,
} from '~/config';

import {
	getRecurly,
} from './main';



export function getTokenUsingData(data) {
	return new Promise((resolve, reject) => {
		getRecurly((recurly) => {
			recurly.token(data, (error, token) => {
				if (error) {
					reject(error);
				} else {
					resolve({
						recurlyToken: token.id,
					});
				}
			});
		});
	});
}



export function getTokenUsingPayPal({ description }) {
	if (CONTENTKING_ENVIRONMENT !== 'production') {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				reject({
					recurlyToken: 'fake-token',
				});
			}, 3000);
		});
	}

	return new Promise((resolve, reject) => {
		getRecurly((recurly) => {
			recurly.configure({
				publicKey: RECURLY_PUBLIC_KEY,
			});

			recurly.paypal({ description }, (error, token) => {
				if (error) {
					reject(error);
				} else {
					resolve({
						recurlyToken: token.id,
					});
				}
			});
		});
	});
}
