import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import IncidentBoxFooter from '~/components/patterns/incidents/IncidentBoxFooter';
import ShortTimeAgo from '~/components/app/ShortTimeAgo';



const messages = defineMessages({
	detected: {
		id: 'ui.alerts.overview.incident.detected',
	},
	lastChange: {
		id: 'ui.alerts.overview.incident.lastChange',
	},
});



class ActiveIncidentTimestamps extends Component {

	render() {
		const {
			lastEvolvedAt,
			openedAt,
		} = this.props;

		return (
			<IncidentBoxFooter>
				<div>
					<FormattedMessage {...messages.detected} />
					<span>&nbsp;</span>
					<ShortTimeAgo datetime={openedAt} />
				</div>
				<div>
					<FormattedMessage {...messages.lastChange} />
					<span>&nbsp;</span>
					<ShortTimeAgo datetime={lastEvolvedAt} />
				</div>
			</IncidentBoxFooter>
		);
	}

}

ActiveIncidentTimestamps.propTypes = {
	lastEvolvedAt: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.string,
	]).isRequired,
	openedAt: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.string,
	]).isRequired,
};



export default ActiveIncidentTimestamps;
