import React from 'react';

import type CK from '~/types/contentking';

import {
	useIsDomainPreverifiedLazyQuery,
} from '~/hooks/useIsDomainPreverified.gql';

import isDomain from '~/utilities/isDomain';



function useIsDomainPreverified(
	accountId: CK.AccountId | null,
) {
	const [isDomainPreverifiedQuery, { data }] = useIsDomainPreverifiedLazyQuery({
		fetchPolicy: 'no-cache',
	});

	const isDomainPreverified = data?.isDomainPreverified?.isPreverified ?? null;

	const checkDomainIsPreverified = React.useCallback(
		async (domain: string) => {
			if (accountId === null || isDomain(domain) === false) {
				return null;
			}

			const { data } = await isDomainPreverifiedQuery({
				variables: {
					accountId,
					domain,
				},
			});

			return data?.isDomainPreverified?.isPreverified ?? null;
		},
		[
			accountId,
			isDomainPreverifiedQuery,
		],
	);

	return React.useMemo(
		() => {
			return {
				checkDomainIsPreverified,
				isDomainPreverified,
			};
		},
		[
			checkDomainIsPreverified,
			isDomainPreverified,
		],
	);
}



export default useIsDomainPreverified;

