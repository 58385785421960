import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	DropTarget,
} from 'react-dnd';

import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	blankSlate: {
		id: 'ui.segments.selection.hint.drag',
	},
	includeHint: {
		id: 'ui.segments.selection.hint.drop.include',
	},
	removeHint: {
		id: 'ui.segments.selection.hint.drop.remove',
	},
});



export const TYPE_ADD = 'add';
export const TYPE_REMOVE = 'remove';



class SegmentSelectionSection extends Component {

	_renderTitle() {
		const {
			title,
		} = this.props;

		return (
			<div className="segment-selection-section__title">
				{title}
			</div>
		);
	}



	_renderHeaderAppendix() {
		const {
			headerAppendix,
		} = this.props;

		if (!headerAppendix) {
			return false;
		}

		return (
			<div className="segment-selection-section__header-appendix">
				{headerAppendix}
			</div>
		);
	}



	_renderCanDropOverview() {
		const {
			type,
		} = this.props;

		const dashedBox = classNames({
			'dashed-box': true,
			'dashed-box--info': type === TYPE_ADD,
		});

		return (
			<div className="segment-selection-section__can-drop-info">
				<div className={dashedBox}>
					<FormattedMessage {...messages[type === TYPE_REMOVE ? 'removeHint' : 'includeHint']} />
				</div>
			</div>
		);
	}



	render() {
		const {
			canDrop,
			connectDropTarget,
		} = this.props;

		const {
			children,
		} = this.props;

		let content = false;

		if (children) {
			content = (
				<div className="segment-selection-section__content">
					{children}
				</div>
			);
		} else {
			content = (
				<div className="segment-selection-section__blank-slate">
					<FormattedMessage {...messages.blankSlate} />
				</div>
			);
		}

		return connectDropTarget(
			<div className="segment-selection-section">
				<div className="segment-selection-section__header">
					{this._renderTitle()}
					{this._renderHeaderAppendix()}
				</div>
				<div className="segment-selection-section__scrollable-area js-scrollable">
					{content}
					{canDrop && this._renderCanDropOverview()}
				</div>
			</div>,
		);
	}

}

SegmentSelectionSection.defaultProps = {
	type: TYPE_ADD,
};

SegmentSelectionSection.propTypes = {
	headerAppendix: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.bool,
	]),
	title: PropTypes.string.isRequired,
	type: PropTypes.oneOf([
		TYPE_ADD,
		TYPE_REMOVE,
	]).isRequired,
};



export default DropTarget('segment', {
	drop(props, monitor) {
		props.onDropLabelCallback(
			monitor.getItem(),
		);
	},
}, (connect, monitor) => {
	return {
		canDrop: monitor.canDrop(),
		connectDropTarget: connect.dropTarget(),
		isOverCurrent: monitor.isOver({ shallow: true }),
	};
})(SegmentSelectionSection);
