import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class ThreeDSecureIframe extends Component {

	shouldComponentUpdate() {
		return false;
	}



	render() {
		const {
			innerRef,
			children,
			preloader,
		} = this.props;

		return (
			<div className="three-d-secure-iframe">
				<div className="three-d-secure-iframe__preloader">
					{preloader}
				</div>
				<div
					className="three-d-secure-iframe__iframe"
					ref={innerRef}
				>
					{children}
				</div>
			</div>
		);
	}

}

ThreeDSecureIframe.propTypes = {
	preloader: PropTypes.node.isRequired,
};



export default React.forwardRef((props, ref) => (
	<ThreeDSecureIframe
		{...props}
		innerRef={ref}
	/>
));
