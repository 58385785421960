import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ContextNavigationButton, {
	ContextNavigationButtonIconType,
} from '~/components/patterns/navigations/ContextNavigationButton';
import List from '~/components/patterns/lists/List';

import useCurrentUserHasPassword from '~/hooks/useCurrentUserHasPassword';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useNavigation from '~/hooks/useNavigation';
import useUserIntercomLink from '~/hooks/useUserIntercomLink';

import {
	IsConductorClient,
} from '~/config';



const messages = defineMessages({
	changePassword: {
		id: 'ui.settings.changePassword',
	},
	setPassword: {
		id: 'ui.settings.setPassword',
	},
});



const CurrentUserProfileContextActions: React.FC = () => {
	const currentUserId = useCurrentUserId();

	const currentUserHasPassword = useCurrentUserHasPassword();
	const navigation = useNavigation();
	const userIntercomLink = useUserIntercomLink(currentUserId);
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const changePasswordLink = navigation.createRoute({
		keepParameters: true,
		routeParams: {
			action: 'change_password',
		},
	});

	const buttons: Array<React.ReactNode> = [];

	if (IsConductorClient === false && currentUserHasPassword !== null) {
		buttons.push(
			<ContextNavigationButton
				iconType={ContextNavigationButtonIconType.Lock}
				key="changePassword"
				routeName={changePasswordLink.name}
				routeParams={changePasswordLink.params}
			>
				{currentUserHasPassword
					? <FormattedMessage {...messages.changePassword} />
					: <FormattedMessage {...messages.setPassword} />
				}
			</ContextNavigationButton>,
		);
	}

	if (kingdomAdminFeatures.areVisible && userIntercomLink !== null) {
		buttons.push(
			<ContextNavigationButton
				href={userIntercomLink}
				iconType={ContextNavigationButtonIconType.Link}
				key="intercom"
			>
				<span>Intercom link</span>
			</ContextNavigationButton>,
		);
	}

	if (buttons.length === 0) {
		return null;
	}

	return (
		<List>
			{buttons}
		</List>
	);
};



export default CurrentUserProfileContextActions;
