import React from 'react';

import CodeSnippets from '~/components/patterns/structuredValues/CodeSnippets';
import TextInspector from '~/components/patterns/typography/TextInspector';
import Value from './Value';



type Props = {
	content: React.ReactNode,
	label: React.ReactNode,
};

const ContentHighlightBox: React.FC<Props> = (props) => {
	const {
		content,
		label,
	} = props;

	let element;

	if (typeof content === 'string' && content.length > 0) {
		element = (
			<TextInspector text={content} />
		);
	} else {
		element = content;
	}

	return (
		<CodeSnippets
			snippets={[
				{
					label,
					content: (
						<Value>
							{element}
						</Value>
					),
				},
			]}
		/>
	);
};



export default ContentHighlightBox;
