import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DotInterfaceHint from '~/components/logic/DotInterfaceHint';

import {
	HINT_COLUMN_SETS_PICKER,
} from '~/model/interfaceHints';

import {
	INTERACTION_COLUMNS_SETS_LIST_OPEN,
	INTERACTION_PAGES_OVERVIEW_OPEN,
} from '~/model/uiInteractions';



const messages = defineMessages({
	message: {
		id: 'ui.interfaceHints.hint.columnSetsPicker',
	},
});



type Props = {
	children: React.ReactNode,
};

const ColumnSetsPickerHint: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<DotInterfaceHint
			conditionsAlready={[
				({ uiInteractions }) => uiInteractions.get(INTERACTION_PAGES_OVERVIEW_OPEN) >= 4,
			]}
			conditionsNotAnymore={[
				({ uiInteractions }) => uiInteractions.get(INTERACTION_COLUMNS_SETS_LIST_OPEN) === 0,
			]}
			content={(
				<FormattedMessage {...messages.message} />
			)}
			iconPosition={{
				left: 55,
				bottom: 2,
			}}
			name={HINT_COLUMN_SETS_PICKER}
		>
			{children}
		</DotInterfaceHint>
	);
};



export default React.memo(ColumnSetsPickerHint);
