import classNames from 'classnames';
import React from 'react';

import StatusFlag, {
	StatusFlagSize,
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';



export enum WebsiteOverviewSize {
	Default = 'default',
	Small = 'small',
}

export enum WebsiteOverviewStatus {
	Critical = 'critical',
	Default = 'default',
	Paused = 'paused',
	Warning = 'warning',
}

type Props = {
	/** Details of website */
	details?: React.ReactNode,
	favicon: React.ReactNode,
	/** Name of user */
	name: React.ReactNode,
	size?: WebsiteOverviewSize,
	status?: WebsiteOverviewStatus,
};



const WebsiteOverview: React.FC<Props> = (props) => {
	const {
		details,
		favicon,
		name,
		size = WebsiteOverviewSize.Default,
		status = WebsiteOverviewStatus.Default,
	} = props;

	const componentClasses = classNames({
		'website-overview': true,
		['website-overview--' + size + '-size']: true,
	});

	let iconStatus;

	if (status === WebsiteOverviewStatus.Critical) {
		iconStatus = StatusFlagStatus.Critical;
	}

	if (status === WebsiteOverviewStatus.Paused) {
		iconStatus = StatusFlagStatus.Paused;
	}

	if (status === WebsiteOverviewStatus.Warning) {
		iconStatus = StatusFlagStatus.Warning;
	}

	return (
		<div className={componentClasses}>
			<div className="website-overview__favicon">
				{status !== WebsiteOverviewStatus.Default && (
					<div className="website-overview__status-icon">
						<StatusFlag
							size={size === WebsiteOverviewSize.Default ? StatusFlagSize.Medium : StatusFlagSize.Default}
							status={iconStatus}
						/>
					</div>
				)}
				{favicon}
			</div>
			<div className="website-overview__content">
				<h4 className="website-overview__name">
					{name}
				</h4>

				{details && (
					<div className="website-overview__details">
						{details}
					</div>
				)}
			</div>
		</div>
	);
};



export default WebsiteOverview;
