import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import MissingValue from '~/components/app/MissingValue';



type Props = {
	currency: string,
	value: number | null,
};

const CurrencyFormatter: React.FC<Props> = (props) => {
	const {
		currency,
		value = null,
	} = props;

	if (value === null) {
		return (
			<MissingValue ellipsis={true} />
		);
	}

	return (
		<FormattedNumber
			currency={currency}
			maximumFractionDigits={2}
			minimumFractionDigits={2}
			style="currency"
			value={value}
		/>
	);
};



export default React.memo(CurrencyFormatter);
