import React from 'react';

import type CK from '~/types/contentking';

import useAccountDisplayName from '~/hooks/useAccountDisplayName';



type Props = {
	accountId: CK.AccountId | null,
};

const AccountDisplayName: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountDisplayName = useAccountDisplayName(accountId);

	return (
		<>{accountDisplayName}</>
	);
};



export default AccountDisplayName;
