import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';
import UnreliableResponseName from '~/components/names/UnreliableResponseName';



const messages = defineMessages({
	title: {
		id: 'ui.platform.robots.sitemapReference',
	},
});



type Props = {
	issue: {
		context: {
			inaccessibleSitemapReferences: ReadonlyArray<{
				status_code: number | null,
				type: string,
				url: string,
			}>,
		},
	},
	tableWidth: number,
};

const RobotsTxtInaccessibleSitemapReferencesTable: React.FC<Props> = (props) => {
	const {
		issue,
		tableWidth,
	} = props;

	const firstColumnWidth = Math.ceil(tableWidth * 2 / 3);

	return (
		<SmallTable
			columns={[
				{
					hasData: () => true,
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis>
									<CodeValue>
										{row.url}
									</CodeValue>
								</Ellipsis>
							);
						},
						header: () => <FormattedMessage {...messages.title} />,
					},
					width: firstColumnWidth,
				},
				{
					hasData: () => true,
					render: {
						cell: ({ row }) => {
							const statusCode = row.status_code;
							const type = row.type;

							if (statusCode !== null) {
								return (
									<Ellipsis>
										<CodeValue>
											{statusCode}
										</CodeValue>
									</Ellipsis>
								);
							}

							return (
								<UnreliableResponseName reason={type} />
							);
						},
						header: () => 'Response',
					},
					width: tableWidth - firstColumnWidth - 2,
				},
			]}
			rows={issue.context.inaccessibleSitemapReferences}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(RobotsTxtInaccessibleSitemapReferencesTable);
