import classNames from 'classnames';
import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';



export enum ScoreNumberSize {
	Default = 'default',
	Large = 'large',
	Responsive = 'responsive',
	Small = 'small',
	XLarge = 'xlarge',
	XSmall = 'xsmall',
}

export enum ScoreNumberStyle {
	Colored = 'colored',
	Default = 'default',
	Greyscale = 'greyscale',
	GreyscaleDark = 'greyscale-dark',
}

type Props = {
	className?: string,
	label?: string,
	localize?: boolean,
	maximum?: number,
	/** Function used for calculation of percent value. Can be customized. It's represented by float number between 0 and 1. */
	percentCalculation?: (value: number, maximum: number) => number,
	/** Display percent share instead of maximum value */
	percentShare?: boolean,
	size?: ScoreNumberSize,
	style?: ScoreNumberStyle,
	value?: number | null,
};



const ScoreNumber: React.FC<Props> = (props) => {
	const {
		className,
		label,
		localize = true,
		maximum,
		percentCalculation = (value, maximum) => {
			return value / maximum;
		},
		percentShare = false,
		size = ScoreNumberSize.Default,
		style = ScoreNumberStyle.Default,
		value = null,
	} = props;

	const _renderValuePart = (value) => {
		if (value === false || value === undefined || value === null) {
			return (
				<big>—</big>
			);
		}

		return (
			<big>
				{localize ? (
					<FormattedNumber value={value} />
				) : value}
			</big>
		);
	};

	const _renderMaximumPart = (value, maximum) => {
		if ((maximum === false || maximum === undefined) && percentShare === false) {
			return false;
		}

		if (percentShare) {
			const percentValue = percentCalculation(value, maximum);

			return (
				<span className="score-number__maximum">
					<span> </span>
					<FormattedNumber
						maximumFractionDigits={2}
						minimumFractionDigits={0}
						style="percent"
						value={percentValue}
					/>
				</span>
			);
		}

		return (
			<span className="score-number__maximum">
				<span> / </span>
				{localize ? (
					<FormattedNumber value={maximum} />
				) : maximum}
			</span>
		);
	};

	let scorePercentage: number = 0;

	if (maximum && value) {
		scorePercentage = parseInt(
			(percentCalculation(value, maximum) * 100).toString(),
			10,
		);
	}

	const componentClasses = classNames({
		'score-number': true,
		'score-number--greyscale': style === ScoreNumberStyle.Greyscale,
		'score-number--greyscale-dark': style === ScoreNumberStyle.GreyscaleDark,

		'score-number--state-good': style === ScoreNumberStyle.Colored && maximum && value && scorePercentage === 100,
		'score-number--state-fine': style === ScoreNumberStyle.Colored && maximum && value && scorePercentage > 75 && scorePercentage < 100,
		'score-number--state-normal': style === ScoreNumberStyle.Colored && maximum && value && scorePercentage > 50 && scorePercentage <= 75,
		'score-number--state-worse': style === ScoreNumberStyle.Colored && maximum && value && scorePercentage > 25 && scorePercentage <= 50,
		'score-number--state-bad': style === ScoreNumberStyle.Colored && maximum && value && scorePercentage <= 25,

		'score-number--size-default': size === ScoreNumberSize.Default,
		'score-number--size-large': size === ScoreNumberSize.Large,
		'score-number--size-small': size === ScoreNumberSize.Small,
		'score-number--size-xlarge': size === ScoreNumberSize.XLarge,
		'score-number--size-xsmall': size === ScoreNumberSize.XSmall,
	}, className);

	return (
		<div className={componentClasses}>
			{_renderValuePart(value)}
			{_renderMaximumPart(value, maximum)}
			{label && (
				<div className="score-number__label">
					{label}
				</div>
			)}
		</div>
	);
};



export default ScoreNumber;
