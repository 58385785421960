import React from 'react';

import MultiselectFieldFilter, {
	type MultiselectFieldFilterRef,
} from './MultiselectFieldFilter';
import SegmentDefinitionFilterIdentifier from '../segments/SegmentDefinitionFilterIdentifier';
import SegmentFilterWebsiteScopeLabel from '~/components/app/SegmentFilterWebsiteScopeLabel';
import SegmentMultiselectToggler, {
	SegmentMultiselectTogglerSize,
} from '~/components/atoms/forms/components/segments/SegmentMultiselectToggler';

import {
	type SegmentDefinition,
} from '~/model/segments';



type Props = {
	allValue?: any,
	dropdownInnerPanelWidth?: number,
	dropdownWidth?: number,
	hideInnerOptions?: boolean,
	name: string,
	options: Array<any>,
	scrollableDropdown?: boolean,
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	visibleOptionsCount?: number,
	width: number,
};

const SegmentMultiselectFieldFilter = React.forwardRef<MultiselectFieldFilterRef, Props>((props, ref) => {
	const {
		segmentDefinitions,
	} = props;

	return (
		<MultiselectFieldFilter
			{...props}
			isOnlyLinkVisible={true}
			labelRenderer={(label, isOpen) => (
				<SegmentMultiselectToggler
					isOpen={isOpen}
					label={label}
					size={SegmentMultiselectTogglerSize.Small}
				/>
			)}
			ref={ref}
			selectedLabelRenderer={({ selectedOptions, defaultSelectedLabelRenderer }) => {
				if (selectedOptions.length === 1) {
					if (selectedOptions[0] === 'website') {
						return (
							<SegmentFilterWebsiteScopeLabel />
						);
					}

					const segmentDefinition = segmentDefinitions.find((segmentDefinition) => selectedOptions[0] === 'segment:' + segmentDefinition.name);

					if (segmentDefinition) {
						return (
							<SegmentDefinitionFilterIdentifier
								segmentDefinition={segmentDefinition}
							/>
						);
					}
				}

				return defaultSelectedLabelRenderer();
			}}
		/>
	);
});



export default SegmentMultiselectFieldFilter;

export {
	MultiselectFieldFilterRef as SegmentMultiselectFieldFilterRef,
};
