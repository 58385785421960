import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountCustomerTypeQuery,
} from './useAccountCustomerType.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountCustomerType(accountId: CK.AccountId | null): GraphQL.Account['customerType'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountCustomerTypeQuery,
		accountId,
	);

	return data?.account?.customerType ?? null;
}



export default useAccountCustomerType;
