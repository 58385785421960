import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserEmail from '~/hooks/useUserEmail';



const messages = defineMessages({
	placeholder: {
		id: 'ui.settings.userNamePlaceholder',
	},
});



type Props = {
	email: string,
};

const NoName: React.FC<Props> = (props) => {
	const {
		email,
	} = props;

	const currentUserId = useCurrentUserId();

	const currentUserEmail = useUserEmail(currentUserId);

	if (email === currentUserEmail) {
		return (
			<FormattedMessage {...messages.placeholder} />
		);
	}

	return (
		<span>{email}</span>
	);
};



export default NoName;
