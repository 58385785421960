import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	SearchEngine,
} from '~/model/websites/searchEngines';



const messages = defineMessages({
	[SearchEngine.Baidu]: {
		id: 'ui.searchEngines.baidu',
	},
	[SearchEngine.Bing]: {
		id: 'ui.searchEngines.bing',
	},
	[SearchEngine.DuckDuckGo]: {
		id: 'ui.searchEngines.duckDuckGo',
	},
	[SearchEngine.Google]: {
		id: 'ui.searchEngines.google',
	},
	[SearchEngine.Seznam]: {
		id: 'ui.searchEngines.seznam',
	},
	[SearchEngine.Yahoo]: {
		id: 'ui.searchEngines.yahoo',
	},
	[SearchEngine.Yandex]: {
		id: 'ui.searchEngines.yandex',
	},
});



type Props = {
	searchEngine: SearchEngine,
};

const SearchEngineName: React.FC<Props> = (props) => {
	const {
		searchEngine,
	} = props;

	const message = messages[searchEngine];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default SearchEngineName;
