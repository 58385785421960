import classNames from 'classnames';
import React from 'react';

import styles from './Link.module.scss';



export enum FakeLinkStyle {
	Decent = 'decent',
	ExternalLink = 'extrnal-link',
	InternalLink = 'internal-link',
	Light = 'light',
}



type Props = {
	children?: React.ReactNode,
	ellipsis?: boolean,
	icon?: React.ReactNode,
	style?: FakeLinkStyle,
};

const FakeLink: React.FC<Props> = (props) => {
	const {
		ellipsis,
		children,
		icon,
		style = FakeLinkStyle.Decent,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isExternal]: style === FakeLinkStyle.ExternalLink,
		[styles.isInternal]: style === FakeLinkStyle.InternalLink,
		[styles.isFake]: true,
		[styles.isWithEllipsis]: ellipsis,
		[styles['style-' + style] ?? '']: true,
	});

	return (
		<div className={componentClasses}>
			{icon && (
				<span className={styles.icon}>
					{icon}
				</span>
			)}
			<span className={styles.text}>
				{children}
			</span>
		</div>
	);
};



export default FakeLink;
