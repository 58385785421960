import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ComparisonFilter from '~/components/logic/issuesOverview/ComparisonFilter';
import IssuesOverviewFilter from '~/components/app/IssuesOverviewFilter';
import PopupButton, {
	PopupButtonSize,
	PopupButtonStyle,
} from '~/components/patterns/buttons/PopupButton';
import Title from '~/components/patterns/headings/Title';

import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	dateFromSelector,
	dateToSelector,
	isActiveSelector,
} from '~/state/websiteIssuesComparison/selectors';



const messages = defineMessages({
	filterTitle: {
		id: 'ui.issuesOverview.filterTitle',
	},
});



type Props = {
	issuesFilterParameter: string | null,
};

const OpenFilterButton: React.FC<Props> = (props) => {
	const {
		issuesFilterParameter,
	} = props;

	const dateFrom = useSelector(dateFromSelector);
	const dateTo = useSelector(dateToSelector);
	const isComparisonActive = useSelector(isActiveSelector);

	const viewportType = useViewportType();
	const websiteId = useWebsiteId();

	if (viewportType.isSmall) {
		const params: Record<string, any> = {
			websiteId,
		};

		if (issuesFilterParameter) {
			params.filter = issuesFilterParameter;
		}

		if (isComparisonActive) {
			params.start_date = dateFrom;
			params.end_date = dateTo;
		}

		return (
			<Button
				icon={(
					<BasicIcon type={BasicIconType.Filter} />
				)}
				linkRouteName="website.issues.filter"
				linkRouteParams={params}
				size={ButtonSize.Small}
				style={ButtonStyle.Hollow}
			/>
		);
	}

	return (
		<PopupButton
			buttonIcon={(
				<BasicIcon type={BasicIconType.Filter} />
			)}
			preventOutsideClickCallback={(target) => {
				if (target.closest('.select-field__dropdown')) {
					return true;
				}

				return false;
			}}
			size={PopupButtonSize.Small}
			style={PopupButtonStyle.Hollow}
		>
			<Title
				indented={true}
				underlined={true}
			>
				<FormattedMessage {...messages.filterTitle} />
			</Title>

			{isComparisonActive
				? (
					<ComparisonFilter
						inline={false}
					/>
				)
				: (
					<IssuesOverviewFilter
						inline={false}
					/>
				)
			}
		</PopupButton>
	);
};



export default OpenFilterButton;
