import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const JavaScriptIconBuilder: IconBuilder = {
	icon: () => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					fillRule="nonzero"
					id="javascript"
					transform="translate(1.000000, 1.000000)"
				>
					<polygon
						fill="#F7DF1E"
						id="Path"
						points="0 0 22 0 22 22 0 22"
					>
					</polygon>
					<path
						d="M5.784625,18.3847812 L7.46814062,17.3659062 C7.79298437,17.9417734 8.0884375,18.4290391 8.79716406,18.4290391 C9.4765,18.4290391 9.90489844,18.1633203 9.90489844,17.1296641 L9.90489844,10.1001484 L11.9722969,10.1001484 L11.9722969,17.1588828 C11.9722969,19.3001875 10.7170938,20.2748906 8.88576562,20.2748906 C7.23189844,20.2748906 6.27189062,19.4183516 5.78453906,18.3846094 M13.0952422,18.1632344 L14.7785859,17.1886172 C15.2217656,17.9122969 15.7977188,18.4439062 16.8165937,18.4439062 C17.6733047,18.4439062 18.2195234,18.0155937 18.2195234,17.4248594 C18.2195234,16.7160469 17.6583516,16.4649375 16.7132109,16.0515781 L16.1964687,15.8298594 C14.7048516,15.1950391 13.7154531,14.3975391 13.7154531,12.7140234 C13.7154531,11.1633672 14.8968359,9.98189844 16.7428594,9.98189844 C18.0571875,9.98189844 19.0023281,10.4397734 19.6815781,11.6359375 L18.0717969,12.6697656 C17.7173047,12.0347734 17.3335078,11.78375 16.7427734,11.78375 C16.1372578,11.78375 15.7532891,12.1677187 15.7532891,12.6697656 C15.7532891,13.2899766 16.1372578,13.5411719 17.0232734,13.9251406 L17.5401016,14.1466016 C19.2975234,14.8998437 20.2869219,15.6676953 20.2869219,17.3953828 C20.2869219,19.2562734 18.8249531,20.2751484 16.8608516,20.2751484 C14.9410938,20.2751484 13.7005859,19.3595703 13.0951562,18.1633203"
						fill="#000000"
						id="Shape"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default JavaScriptIconBuilder;

