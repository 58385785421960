import React from 'react';

import useInterfaceHint from './useInterfaceHint';
import usePendoGuides from './usePendoGuides';



function usePendoGuideInterfaceHint({
	guide,
	name,
}) {
	const interfaceHint = useInterfaceHint({
		conditionsAlready: [],
		conditionsNotAnymore: [],
		name,
	});

	const pendoGuides = usePendoGuides();

	const show = React.useCallback(
		() => {
			interfaceHint.display(() => {
				pendoGuides.showGuide(guide);
				interfaceHint.markOpened();
			});
		},
		[
			guide,
			interfaceHint,
			pendoGuides,
		],
	);

	return React.useMemo(
		() => ({
			show,
		}),
		[
			show,
		],
	);
}



export default usePendoGuideInterfaceHint;
