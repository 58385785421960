import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import SelectField, {
	SelectFieldSize,
} from '~/components/atoms/forms/components/SelectField';



const messages = defineMessages({
	examples: {
		id: 'ui.customElements.examples',
	},
	explanation: {
		id: 'ui.customElements.elementToExtract.date.explanation',
	},
});



type Props = {
	name: string,
	size?: SelectFieldSize,
	width?: number,
};

const CustomElementDatePatternSelectField: React.FC<Props> = (props) => {
	const {
		name,
		size = SelectFieldSize.Default,
		width,
	} = props;

	return (
		<SelectField
			dropdownFooter={(
				<FormattedMessage
					{...messages.explanation}
					values={{
						code: (chunks) => (
							<CodeValue>{chunks}</CodeValue>
						),
					}}
				/>
			)}
			dropdownWidth={250}
			hasScrollableDropdown={false}
			name={name}
			options={[
				{
					label: 'DD/MM/YYYY',
					name: 'dmy',
					description: (
						<FormattedMessage
							{...messages.examples}
							values={{
								content: '24/9/2020; 24.9.2020; 24-09-2020; 24 September 2020',
							}}
						/>
					),
				},
				{
					label: 'MM/DD/YYYY',
					name: 'mdy',
					description: (
						<FormattedMessage
							{...messages.examples}
							values={{
								content: '9/24/2020; 9.24.2020; 09-24-2020; September 24th, 2020',
							}}
						/>
					),
				},
				{
					label: 'YYYY/DD/MM',
					name: 'ydm',
					description: (
						<FormattedMessage
							{...messages.examples}
							values={{
								content: '2020/24/9; 2020-24-09; 2020 Sep 24',
							}}
						/>
					),
				},
				{
					label: 'YYYY/MM/DD',
					name: 'ymd',
					description: (
						<FormattedMessage
							{...messages.examples}
							values={{
								content: '2020/9/24; 2020-09-24; 2020, 24th Sep',
							}}
						/>
					),
				},
			]}
			size={size}
			width={width}
		/>
	);
};



export default CustomElementDatePatternSelectField;

export {
	SelectFieldSize as CustomElementDatePatternSelectFieldSize,
};
