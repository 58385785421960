import type CK from '~/types/contentking';

import {
	useIsExtraInvoiceDetailsEnabledQuery,
} from './useAccountIsExtraInvoiceDetailsEnabled.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsExtraInvoiceDetailsEnabled(accountId: CK.AccountId | null): boolean | null {
	const { data } = useAccountPropertiesQuery(
		useIsExtraInvoiceDetailsEnabledQuery,
		accountId,
	);

	return data?.account?.isExtraInvoiceDetailsEnabled ?? null;
}



export default useAccountIsExtraInvoiceDetailsEnabled;
