import React from 'react';

import styles from './TimelineSpacer.module.scss';



type Props = {
	height: React.CSSProperties['height'],
};

const TimelineSpacer: React.FC<Props> = (props) => {
	const {
		height,
	} = props;

	return (
		<section
			className={styles.component}
			style={{ height }}
		/>
	);
};



export default TimelineSpacer;
