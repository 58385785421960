import React from 'react';
import {
	FormattedMessage,
	useIntl,
} from 'react-intl';

import ExternalLink from '~/components/patterns/links/ExternalLink';

import {
	assertDictionaryId,
} from '~/localization/dictionaries';

import {
	type ExternalLinkDestination,
} from '~/model/externalLinks';



type Props = {
	link: ExternalLinkDestination,
};

const LearnLink: React.FC<Props> = (props) => {
	const {
		link,
	} = props;

	const intl = useIntl();

	const urlId = `links.${link}.url`;
	const messageId = `links.${link}.label`;

	assertDictionaryId(urlId);
	assertDictionaryId(messageId);

	const url = intl.formatMessage({
		id: urlId,
	});

	return (
		<ExternalLink href={url}>
			<FormattedMessage id={messageId} />
		</ExternalLink>
	);
};



export default LearnLink;
