import classNames from 'classnames';
import React from 'react';

import styles from './StatusDot.module.scss';



export enum StatusDotStatus {
	Critical = 'critical',
	Disabled = 'disabled',
	Success = 'success',
	Warning = 'warning',
}

type Props = {
	status: StatusDotStatus,
};



const StatusDot: React.FC<Props> = (props) => {
	const {
		status,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isCritical]: status === StatusDotStatus.Critical,
		[styles.isDisabled]: status === StatusDotStatus.Disabled,
		[styles.isSuccess]: status === StatusDotStatus.Success,
		[styles.isWarning]: status === StatusDotStatus.Warning,
	});

	return (
		<i className={componentClasses} />
	);
};



export default StatusDot;
