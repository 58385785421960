import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CarouselBlocks from '~/components/patterns/structures/CarouselBlocks';
import CurrentPage from './CurrentPage';
import HistoricalData from './HistoricalData';
import HistoryDateRangeFilter from './HistoryDateRangeFilter';
import Measurer from '~/utilities/Measurer';
import Navigation, {
	NavigationTab,
} from './Navigation';
import PageDetailContentHeaderLayout from '~/components/patterns/screens/pageDetail/layouts/PageDetailContentHeaderLayout';
import PageHeader from './PageHeader';
import PageIssuesSidebar from '~/components/app/PageIssuesSidebar';
import PagePropertiesFilter from './PagePropertiesFilter';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenBodyLayout, {
	ScreenBodyLayoutPosition,
} from '~/components/patterns/screens/parts/body/ScreenBodyLayout';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import SearchFilter from './SearchFilter';
import SectionHeader from '~/components/patterns/headings/SectionHeader';
import SourceModePicker from './SourceModePicker';

import useHistoryFilter from './useHistoryFilter';
import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import useNavigation from '~/hooks/useNavigation';
import usePageTimeDiscoveredAt from '~/hooks/usePageTimeDiscoveredAt';
import usePreloadPageDetail from './usePreloadPageDetail';
import usePrioritizePage from '~/hooks/usePrioritizePage';
import useUrlState from '~/hooks/useUrlState';
import useViewportType from '~/hooks/useViewportType';
import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsDomTracked from '~/hooks/useWebsiteIsDomTracked';

import {
	TABBED_VIEW_BREAKPOINT,
} from '~/utilities/viewportSize';

import {
	getRouterState,
} from '~/routing/router';



const messages = defineMessages({
	title: {
		id: 'ui.pageDetail.pageDataTitle',
	},
});



type Props = {
	backlink: string,
	closeCallback: () => void,
	innerPanel?: () => React.ReactNode,
	fullscreenDetailPanel?: () => React.ReactNode,
};

const PageDetailScreen: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
		innerPanel,
		fullscreenDetailPanel,
	} = props;

	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	usePrioritizePage(legacyUrlId, websiteId);
	usePreloadPageDetail();

	const historyFilter = useHistoryFilter();
	const isDomTracked = useWebsiteIsDomTracked(websiteId);

	const navigation = useNavigation();
	const pageTimeDiscoveredAt = usePageTimeDiscoveredAt(legacyUrlId, websiteId);
	const urlState = useUrlState();
	const viewportType = useViewportType();
	const viewportWidth = useViewportWidth();
	const showTabbedView = viewportWidth < TABBED_VIEW_BREAKPOINT;

	const routeName = urlState.name || '';

	const {
		returnRouteName,
		activeTab,
	} = React.useMemo(
		() => {
			if (routeName.startsWith('website.pages.detail.history')) {
				return {
					activeTab: NavigationTab.History,
					returnRouteName: 'website.pages.detail.history',
				};
			} else if (showTabbedView && routeName.startsWith('website.pages.detail.issue')) {
				return {
					activeTab: NavigationTab.Issues,
					returnRouteName: 'website.pages.detail.issue',
				};
			}

			return {
				activeTab: NavigationTab.CurrentPage,
				returnRouteName: 'website.pages.detail',
			};
		},
		[
			routeName,
			showTabbedView,
		],
	);

	const handleCarouselSwipe = React.useCallback(
		(nextTab) => {
			const {
				name: routeName,
				params: routeParams,
			} = getRouterState();

			if (routeName.includes('issuesCategory')) {
				return;
			}

			let nextRouteName = 'website.pages.detail';

			if (nextTab === NavigationTab.History) {
				nextRouteName = 'website.pages.detail.history';
			} else if (nextTab === NavigationTab.Issues) {
				nextRouteName = 'website.pages.detail.issues';
			}

			navigation.navigate({
				routeName: nextRouteName,
				routeParams,
				options: {
					replace: true,
				},
			});
		},
		[
			navigation,
		],
	);

	if (showTabbedView) {
		const showSourceModePicker = isDomTracked;
		let contentOverlay: React.ReactNode = null;

		if (fullscreenDetailPanel) {
			contentOverlay = fullscreenDetailPanel();
		} else if (innerPanel) {
			contentOverlay = innerPanel();
		}

		return (
			<ScreenLayout
				contentOverlay={contentOverlay}
				header={(
					<PageHeader
						backlink={backlink}
						closeCallback={closeCallback}
						footer={(
							<Navigation
								activeTab={activeTab}
								pageId={legacyUrlId}
								uppercase={true}
								websiteId={websiteId}
							/>
						)}
					/>
				)}
				isHeaderFixed={!viewportType.isSmall}
			>
				<CarouselBlocks
					activeSlideIndex={activeTab}
					draggable={true}
					onSlideChangeCallback={handleCarouselSwipe}
					selectableText={true}
				>
					<ScreenBody
						header={showSourceModePicker && (
							<PageDetailContentHeaderLayout
								controlElements={(
									<SourceModePicker />
								)}
							/>
						)}
					>
						<CurrentPage />
					</ScreenBody>

					<ScreenBody
						gaps={false}
						header={showSourceModePicker && (
							<PageDetailContentHeaderLayout
								controlElements={(
									<SourceModePicker />
								)}
							/>
						)}
					>
						<HistoricalData
							filter={historyFilter}
							urlId={legacyUrlId}
							websiteId={websiteId}
						/>
					</ScreenBody>

					<PageIssuesSidebar
						legacyUrlId={legacyUrlId}
						returnRouteName={returnRouteName}
						websiteId={websiteId}
					/>
				</CarouselBlocks>
			</ScreenLayout>
		);
	}

	const contentOverlay = innerPanel?.() ?? null;
	const showPageDataTitle = viewportWidth > 1360 || viewportWidth < 1220;
	const showSourceModePicker = isDomTracked;
	const showSourceModePickerInHeader = viewportWidth >= 1220;

	return (
		<ScreenLayout
			header={(
				<PageHeader
					backlink={backlink}
					closeCallback={closeCallback}
				/>
			)}
		>
			<ScreenBodyLayout
				contentOverlay={contentOverlay}
				hideContentScrollbar={true}
				hideSidebarScrollbar={true}
				sidebar={(
					<PageIssuesSidebar
						legacyUrlId={legacyUrlId}
						returnRouteName={returnRouteName}
						websiteId={websiteId}
					/>
				)}
				sidebarPosition={ScreenBodyLayoutPosition.Right}
			>
				<ScreenBody
					gaps={activeTab === NavigationTab.CurrentPage}
					header={(
						<PageDetailContentHeaderLayout
							controlElements={(
								(showSourceModePicker && !showSourceModePickerInHeader) && (
									<SourceModePicker />
								)
							)}
							filterFields={(
								(activeTab === NavigationTab.History) && ([
									<Measurer key="date-range">
										{({ containerWidth }) => (
											<HistoryDateRangeFilter
												dateRange={historyFilter.dateRange}
												onSelectDateRange={historyFilter.setDateRange}
												pageFoundAt={pageTimeDiscoveredAt ?? new Date()}
												width={containerWidth}
											/>
										)}
									</Measurer>,
									<Measurer key="page-properties">
										{({ containerWidth }) => (
											<PagePropertiesFilter
												onSelectProperties={historyFilter.setProperties}
												selectedProperties={historyFilter.properties}
												width={containerWidth}
											/>
										)}
									</Measurer>,
									<SearchFilter
										key="search"
										onChange={historyFilter.setSearchTerm}
										value={historyFilter.searchTerm}
									/>,
								])
							)}
							navigation={(
								<SectionHeader
									controlElements={(
										<Navigation
											activeTab={activeTab}
											hiddenTabs={[
												NavigationTab.Issues,
											]}
											pageId={legacyUrlId}
											websiteId={websiteId}
										/>
									)}
									title={(
										(!showSourceModePicker || showPageDataTitle) && (
											<FormattedMessage {...messages.title} />
										)
									)}
									titleSuffix={(
										(showSourceModePicker && showSourceModePickerInHeader) && (
											<SourceModePicker />
										)
									)}
								/>
							)}
						/>
					)}
				>
					{(activeTab === NavigationTab.CurrentPage) && (
						<CurrentPage />
					)}

					{activeTab === NavigationTab.History && (
						<HistoricalData
							filter={historyFilter}
							urlId={legacyUrlId}
							websiteId={websiteId}
						/>
					)}
				</ScreenBody>
			</ScreenBodyLayout>

			{fullscreenDetailPanel?.()}
		</ScreenLayout>
	);
};



export default PageDetailScreen;
