import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import FilterDropdownButtons from '~/components/atoms/filters/FilterDropdownButtons';
import SegmentWithDuplicateCriteriaExistsMessage from '../segmentManagement/SegmentWithDuplicateCriteriaExistsMessage';
import WithPermission from '../access/WithPermission';

import useUrlState from '~/hooks/useUrlState';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	removeDefaultFilterValues,
} from '~/model/pages';

import {
	ObjectType,
} from '~/model/permissions';

import {
	denormalizeFilter,
	getSegmentDefinitionByCriteria,
	getSegmentDefinitionByName,
} from '~/model/segments';

import {
	navigate,
} from '~/routing/router';

import encodeInBase64 from '~/utilities/encodeInBase64';



const messages = defineMessages({
	segmentsCreate: {
		id: 'ui.contentOverview.tableFilter.segment.createNewSegment',
	},
	segmentsUpdate: {
		id: 'ui.contentOverview.tableFilter.segment.update',
	},
});



const ActiveFilterSegmentsCTA = (props) => {
	const {
		activeFilters,
		pagesFilter,
		pagesSortBy,
	} = props;

	const websiteId = useWebsiteId();

	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const urlState = useUrlState();

	const activeSegmentDefinition = React.useMemo(
		() => {
			if (segmentDefinitions.isLoaded === false) {
				return null;
			}

			return getSegmentDefinitionByName(
				segmentDefinitions.listAll(),
				pagesFilter.get('segments').get('included_in').first(),
			);
		},
		[
			pagesFilter,
			segmentDefinitions,
		],
	);

	const handleCreateSegment = React.useCallback(
		(e) => {
			e.preventDefault();

			const currentFilter = removeDefaultFilterValues(pagesFilter);
			const currentSort = pagesSortBy;

			const back = encodeInBase64(
				JSON.stringify({
					name: urlState.name,
					params: urlState.params,
				}),
			);

			navigate('website.segmentEditor', {
				back,
				filter: encodeInBase64(JSON.stringify(currentFilter.toJS())),
				sortBy: encodeInBase64(JSON.stringify(currentSort.toJS())),
				websiteId,
			});
		},
		[
			pagesFilter,
			pagesSortBy,
			urlState,
			websiteId,
		],
	);

	const handleEditSegment = React.useCallback(
		(e) => {
			if (activeSegmentDefinition === null) {
				throw new Error(
					`activeSegmentDefinition can't be null`,
				);
			}

			e.preventDefault();

			const segmentFilter = denormalizeFilter(activeSegmentDefinition.filterDefinition);
			const currentFilter = removeDefaultFilterValues(pagesFilter.remove('segments'));
			const currentSort = pagesSortBy;
			const updatedCriteria = segmentFilter.merge(currentFilter);

			const back = encodeInBase64(
				JSON.stringify({
					name: urlState.name,
					params: urlState.params,
				}),
			);

			navigate('website.segmentEditor', {
				back,
				filter: encodeInBase64(JSON.stringify(updatedCriteria.toJS())),
				segmentName: activeSegmentDefinition.name,
				sortBy: encodeInBase64(JSON.stringify(currentSort.toJS())),
				websiteId,
			});
		},
		[
			activeSegmentDefinition,
			pagesFilter,
			pagesSortBy,
			urlState,
			websiteId,
		],
	);

	if (
		segmentDefinitions.isLoaded === false
	) {
		return null;
	}

	const showUpdateButton = (
		pagesFilter.has('segments')
		&& activeSegmentDefinition !== null
		&& pagesFilter.get('segments').get('included_in').size === 1
		&& pagesFilter.get('segments').get('not_included_in').size === 0
		&& activeFilters.size > 1
	);

	const showCreateButton = (
		activeFilters.size > 0
		&& (
			!pagesFilter.has('segments')
			|| !(
				pagesFilter.get('segments').get('included_in').size === 1
				&& pagesFilter.get('segments').get('not_included_in').size === 0
				&& activeFilters.size === 1
			)
		)
	);

	if (!showCreateButton && !showUpdateButton) {
		return null;
	}

	const segmentWithSameCriteria = showCreateButton ? getSegmentDefinitionByCriteria(segmentDefinitions.listAll(), {
		filterDefinition: removeDefaultFilterValues(pagesFilter),
		sizeLimit: null,
	}) : null;

	return (
		<FilterDropdownButtons>
			{showCreateButton && (
				<WithPermission
					action={GraphQL.ActionWithWebsite.ManageSegments}
					objectId={websiteId}
					objectType={ObjectType.Website}
				>
					{({ isAllowed }) => (
						<Button
							disabled={isAllowed.yes === false || !!segmentWithSameCriteria}
							ellipsis={true}
							icon={(
								<BasicIcon
									type={BasicIconType.Segment}
								/>
							)}
							iconSize={14}
							onClick={handleCreateSegment}
							size={ButtonSize.XXSmall}
							style={ButtonStyle.Hollow}
							tooltip={segmentWithSameCriteria && (
								<SegmentWithDuplicateCriteriaExistsMessage
									segmentWithSameCriteria={segmentWithSameCriteria}
								/>
							)}
							uppercase={true}
						>
							<FormattedMessage {...messages.segmentsCreate} />
						</Button>
					)}
				</WithPermission>
			)}

			{showUpdateButton && (
				<WithPermission
					action={GraphQL.ActionWithWebsite.ManageSegments}
					objectId={websiteId}
					objectType={ObjectType.Website}
				>
					{({ isAllowed }) => (
						<Button
							disabled={isAllowed.yes === false}
							ellipsis={true}
							icon={(
								<BasicIcon
									color={activeSegmentDefinition.color}
									type={BasicIconType.Segment}
								/>
							)}
							iconSize={14}
							onClick={handleEditSegment}
							size={ButtonSize.XXSmall}
							style={ButtonStyle.Hollow}
							uppercase={true}
						>
							<FormattedMessage {...messages.segmentsUpdate} />
						</Button>
					)}
				</WithPermission>
			)}
		</FilterDropdownButtons>
	);
};



export default ActiveFilterSegmentsCTA;
