import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import NewTabLink from '~/components/atoms/NewTabLink';



const messages = defineMessages({
	label: {
		id: 'ui.openGoogleSearchConsoleButton.label',
	},
});



type Props = {
	link: string,
};

const OpenGoogleSearchConsoleButton: React.FC<Props> = (props) => {
	const {
		link,
	} = props;

	return (
		<NewTabLink url={link}>
			<Button
				icon={(
					<BasicIcon type={BasicIconType.ExternalLink} />
				)}
				size={ButtonSize.Small}
				style={ButtonStyle.Hollow}
				uppercase={true}
			>
				<FormattedMessage {...messages.label} />
			</Button>
		</NewTabLink>
	);
};



export default OpenGoogleSearchConsoleButton;
