/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RefreshEnrichmentApiTokenMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type RefreshEnrichmentApiTokenMutation = { readonly __typename?: 'Mutation', readonly RefreshEnrichmentApiToken: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly enrichmentApiToken: string | null, readonly id: CK.AccountId } | null } } };


export const RefreshEnrichmentApiTokenDocument = gql`
    mutation RefreshEnrichmentApiToken($accountId: AccountId!) {
  RefreshEnrichmentApiToken(accountId: $accountId) {
    query {
      account(id: $accountId) {
        enrichmentApiToken
        id
      }
    }
  }
}
    `;
export type RefreshEnrichmentApiTokenMutationFn = Apollo.MutationFunction<RefreshEnrichmentApiTokenMutation, RefreshEnrichmentApiTokenMutationVariables>;

/**
 * __useRefreshEnrichmentApiTokenMutation__
 *
 * To run a mutation, you first call `useRefreshEnrichmentApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshEnrichmentApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshEnrichmentApiTokenMutation, { data, loading, error }] = useRefreshEnrichmentApiTokenMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRefreshEnrichmentApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshEnrichmentApiTokenMutation, RefreshEnrichmentApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshEnrichmentApiTokenMutation, RefreshEnrichmentApiTokenMutationVariables>(RefreshEnrichmentApiTokenDocument, options);
      }
export type RefreshEnrichmentApiTokenMutationHookResult = ReturnType<typeof useRefreshEnrichmentApiTokenMutation>;
export type RefreshEnrichmentApiTokenMutationResult = Apollo.MutationResult<RefreshEnrichmentApiTokenMutation>;
export type RefreshEnrichmentApiTokenMutationOptions = Apollo.BaseMutationOptions<RefreshEnrichmentApiTokenMutation, RefreshEnrichmentApiTokenMutationVariables>;