import React from 'react';



const FieldDropdownDivider: React.FC = () => {
	return (
		<div className="field-dropdown-divider" />
	);
};



export default FieldDropdownDivider;
