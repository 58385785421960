import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import ColumnName from '~/components/names/ColumnName';
import Declaration from '~/components/patterns/values/declarations/Declaration';
import SizeLimit from './SizeLimit';



const messages = defineMessages({
	property: {
		id: 'ui.general.propertyDeclaration',
	},
});



type Props = {
	sizeLimitDefinition: CK.PageSegmentSizeLimit,
};

const SizeLimitDefinition: React.FC<Props> = (props) => {
	const {
		sizeLimitDefinition,
	} = props;

	return (
		<Declaration
			property={(
				<FormattedMessage
					{...messages.property}
					values={{
						text__property: (
							<ColumnName column={sizeLimitDefinition.field as CK.PagesColumn} />
						),
					}}
				/>
			)}
			value={(
				<SizeLimit sizeLimitDefinition={sizeLimitDefinition} />
			)}
		/>
	);
};



export default SizeLimitDefinition;
