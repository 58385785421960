import useHiddenDashboardScopeIdentifiers from './useHiddenDashboardScopeIdentifiers';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteScopes from './useWebsiteScopes';
import useWebsiteSegmentDefinitions from './useWebsiteSegmentDefinitions';

import {
	type Scope,
	createScopeIdentifierId,
} from '~/model/scopes';



function useDashboardScopes(): Array<Scope> | null {
	const websiteId = useWebsiteId();

	const hiddenScopeIdentifierIds = useHiddenDashboardScopeIdentifiers();
	const scopes = useWebsiteScopes();
	const segments = useWebsiteSegmentDefinitions(websiteId);

	if (hiddenScopeIdentifierIds === null || scopes === null || segments.isLoaded === false) {
		return null;
	}

	return scopes.filter((scope) => {
		const scopeIdentifierId = createScopeIdentifierId(
			scope,
			segments.listAll(),
		);

		return !hiddenScopeIdentifierIds.includes(scopeIdentifierId);
	});
}



export default useDashboardScopes;
