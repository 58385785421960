import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BackButton from '~/components/app/BackButton';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
	ButtonsLayoutUsageContext,
} from '~/components/patterns/buttons/ButtonsLayout';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
	linkInternal,
} from '~/components/logic/Copy';
import CustomElementChangeTypesField from '../../fields/CustomElementChangeTypesField';
import EnrichmentFieldSelectField from '~/components/app/EnrichmentFieldSelectField';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import FormSentence from '~/components/atoms/forms/basis/FormSentence';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	CUSTOM_ELEMENT_CHANGE_TYPE_DECREASED,
	CUSTOM_ELEMENT_CHANGE_TYPE_INCREASED,
	CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_BACKWARD,
	CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_FORWARD,
} from '~/model/alerts';

import {
	ELEMENT_TYPE_DATE,
} from '~/model/customElements';



const messages = defineMessages({
	breadcrumb: {
		id: 'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.breadcrumb',
	},
	continue: {
		id: 'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.continue',
	},
	description: {
		id: 'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.description',
	},
	fieldsPropertyChangeTypes: {
		id: 'ui.alertsConfiguration.fields.propertyChangeTypes',
	},
	fieldsCustomElement: {
		id: 'ui.alertsConfiguration.fields.enrichmentField',
	},
	fieldsMinimalNumericChange: {
		id: 'ui.alertsConfiguration.fields.minimalNumericChange',
	},
	minimalNumericChangeDescription: {
		id: 'ui.alertsConfiguration.fields.minimalNumericChange.description',
	},
	minimalNumericChangeSuffixDays: {
		id: 'ui.alertsConfiguration.fields.minimalNumericChange.suffix.days',
	},
	noCustomElements: {
		id: 'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.noCustomElements',
	},
	sidebar: {
		id: 'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.sidebar',
	},
	title: {
		id: 'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.title',
	},
});

const validations = {
	property: [
		{
			message: '',
			field: 'property',
			rule: ({ name, values }) => !!values[name],
		},
	],
	propertyChangeTypes: [
		{
			message: '',
			field: 'propertyChangeTypes',
			rule: ({ name, values }) => (values[name] ?? []).length > 0,
		},
	],
};



type Props = {
	onSubmit: (values: Object) => void,
};

const SetupEnrichmentFieldAlertStep: React.FC<Props> = (props) => {
	const {
		onSubmit,
	} = props;

	const formRef = React.useRef<FormRef>(null);

	const websiteId = useWebsiteId();

	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	const hasEnrichmentFields = enrichmentFieldDefinitions.count > 0;

	const [hasMinimalNumericChange, setMinimalNumericChange] = React.useState(false);

	return (
		<MultiStepModalStep
			breadcrumb={(
				<FormattedMessage {...messages.breadcrumb} />
			)}
			headerIconType={MultiStepModalStepHeaderIconType.Plus}
			name="setupEnrichmentFieldAlert"
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							minimalNumericChange: hasMinimalNumericChange ? 'visible' : 'hidden',
							link: linkExternal('https://www.contentkingapp.com/support/configuring-alerts'),
						}}
					/>
				</RichText>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<PremiumFeatureSituation
				featureName={GraphQL.AccountFeature.EnrichmentFields_5}
				hideIfUnattainable={false}
				style={PremiumFeatureSituationStyle.Box}
			>
				{({ isFeatureEnabled, premiumAnnotation }) => (
					<List size={ListSize.XLarge}>
						<ModalTextSection>
							{premiumAnnotation}
						</ModalTextSection>

						<DisabledContent disabledContent={!isFeatureEnabled}>
							<Form
								defaultDataHasChanged={true}
								defaultValues={{
									property: null,
									propertyChangeTypes: [],
									minimalNumericChange: 1,
								}}
								key="setup-custom-elements"
								onChangeCallback={(name, value) => {
									if (name === 'propertyChangeTypes') {
										setMinimalNumericChange(
											value.includes(CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_BACKWARD)
											|| value.includes(CUSTOM_ELEMENT_CHANGE_TYPE_MOVED_FORWARD)
											|| value.includes(CUSTOM_ELEMENT_CHANGE_TYPE_DECREASED)
											|| value.includes(CUSTOM_ELEMENT_CHANGE_TYPE_INCREASED),
										);
									} else if (name === 'property') {
										formRef.current?.setValue('propertyChangeTypes', []);
										formRef.current?.setValue('minimalNumericChange', 1);
										setMinimalNumericChange(false);
									}
								}}
								onSuccess={onSubmit}
								ref={formRef}
								validations={validations}
							>
								{({ values }) => {
									const dataType = enrichmentFieldDefinitions.getByColumn(values.property)?.dataType as any;

									return (
										<>
											<ModalTextSection>
												<FormattedMessage {...messages.description} />
											</ModalTextSection>

											<CenteredFormWrapper>
												<FormRows>
													<FormRow
														description={
															!hasEnrichmentFields && (
																<FormattedMessage
																	{...messages.noCustomElements}
																	values={{
																		link: linkInternal(
																			'website.detail.enrichmentFields',
																			{
																				action: 'create_enrichment_field',
																				websiteId,
																			},
																		),
																	}}
																/>
															)
														}
														htmlFor="property"
														label={<FormattedMessage {...messages.fieldsCustomElement} />}
													>
														<EnrichmentFieldSelectField
															name="property"
														/>
													</FormRow>

													<FormRow
														htmlFor="propertyChangeTypes"
														label={(
															<FormattedMessage {...messages.fieldsPropertyChangeTypes} />
														)}
													>
														<CustomElementChangeTypesField
															dataType={dataType}
															isDisabled={values.property === null}
															key={values.property}
															name="propertyChangeTypes"
														/>
													</FormRow>

													{hasMinimalNumericChange && (
														<FormRow
															description={<FormattedMessage {...messages.minimalNumericChangeDescription} />}
															htmlFor="minimalNumericChange"
															label={<FormattedMessage {...messages.fieldsMinimalNumericChange} />}
														>
															<FormSentence>
																<TextField
																	attributes={{
																		min: 1,
																		step: 1,
																	}}
																	name="minimalNumericChange"
																	resetButton={false}
																	type={TextFieldType.Number}
																	width={140}
																/>

																{dataType === ELEMENT_TYPE_DATE && (
																	<FormattedMessage {...messages.minimalNumericChangeSuffixDays} />
																)}
															</FormSentence>
														</FormRow>
													)}
												</FormRows>

												{isFeatureEnabled && (
													<ButtonsLayout
														alignment={ButtonsLayoutAlignment.Right}
														layout={ButtonsLayoutType.Steps}
														usageContext={ButtonsLayoutUsageContext.InModals}
													>
														<BackButton />

														<SubmitButton>
															<FormattedMessage
																{...messages.continue}
															/>
														</SubmitButton>
													</ButtonsLayout>
												)}
											</CenteredFormWrapper>
										</>
									);
								}}
							</Form>
						</DisabledContent>

						{!isFeatureEnabled && (
							<CenteredFormWrapper>
								<ButtonsLayout
									alignment={ButtonsLayoutAlignment.Right}
									layout={ButtonsLayoutType.Steps}
									usageContext={ButtonsLayoutUsageContext.InModals}
								>
									<BackButton />

									<DisabledContent disabledContent={true}>
										<Form isDisabled={true}>
											<SubmitButton>
												<FormattedMessage
													{...messages.continue}
												/>
											</SubmitButton>
										</Form>
									</DisabledContent>
								</ButtonsLayout>
							</CenteredFormWrapper>
						)}
					</List>
				)}
			</PremiumFeatureSituation>
		</MultiStepModalStep>
	);
};



export default SetupEnrichmentFieldAlertStep;
