/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateIndexNowContentTriggersMutationVariables = GraphQL.Exact<{
  contentTriggers: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateIndexNowContentTriggersMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteIndexNowContentChangeTriggers: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly indexNowNativeTriggers: ReadonlyArray<string> } | null } } };

export type UpdateIndexNowPageStatusTriggersMutationVariables = GraphQL.Exact<{
  pageStatusTriggers: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateIndexNowPageStatusTriggersMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteIndexNowPageStatusChangeTriggers: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly indexNowNativeTriggers: ReadonlyArray<string> } | null } } };


export const UpdateIndexNowContentTriggersDocument = gql`
    mutation UpdateIndexNowContentTriggers($contentTriggers: [String!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteIndexNowContentChangeTriggers(
    contentChangeTriggers: $contentTriggers
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        indexNowNativeTriggers
      }
    }
  }
}
    `;
export type UpdateIndexNowContentTriggersMutationFn = Apollo.MutationFunction<UpdateIndexNowContentTriggersMutation, UpdateIndexNowContentTriggersMutationVariables>;

/**
 * __useUpdateIndexNowContentTriggersMutation__
 *
 * To run a mutation, you first call `useUpdateIndexNowContentTriggersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndexNowContentTriggersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndexNowContentTriggersMutation, { data, loading, error }] = useUpdateIndexNowContentTriggersMutation({
 *   variables: {
 *      contentTriggers: // value for 'contentTriggers'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateIndexNowContentTriggersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndexNowContentTriggersMutation, UpdateIndexNowContentTriggersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIndexNowContentTriggersMutation, UpdateIndexNowContentTriggersMutationVariables>(UpdateIndexNowContentTriggersDocument, options);
      }
export type UpdateIndexNowContentTriggersMutationHookResult = ReturnType<typeof useUpdateIndexNowContentTriggersMutation>;
export type UpdateIndexNowContentTriggersMutationResult = Apollo.MutationResult<UpdateIndexNowContentTriggersMutation>;
export type UpdateIndexNowContentTriggersMutationOptions = Apollo.BaseMutationOptions<UpdateIndexNowContentTriggersMutation, UpdateIndexNowContentTriggersMutationVariables>;
export const UpdateIndexNowPageStatusTriggersDocument = gql`
    mutation UpdateIndexNowPageStatusTriggers($pageStatusTriggers: [String!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteIndexNowPageStatusChangeTriggers(
    pageStatusChangeTriggers: $pageStatusTriggers
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        indexNowNativeTriggers
      }
    }
  }
}
    `;
export type UpdateIndexNowPageStatusTriggersMutationFn = Apollo.MutationFunction<UpdateIndexNowPageStatusTriggersMutation, UpdateIndexNowPageStatusTriggersMutationVariables>;

/**
 * __useUpdateIndexNowPageStatusTriggersMutation__
 *
 * To run a mutation, you first call `useUpdateIndexNowPageStatusTriggersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndexNowPageStatusTriggersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndexNowPageStatusTriggersMutation, { data, loading, error }] = useUpdateIndexNowPageStatusTriggersMutation({
 *   variables: {
 *      pageStatusTriggers: // value for 'pageStatusTriggers'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateIndexNowPageStatusTriggersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndexNowPageStatusTriggersMutation, UpdateIndexNowPageStatusTriggersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIndexNowPageStatusTriggersMutation, UpdateIndexNowPageStatusTriggersMutationVariables>(UpdateIndexNowPageStatusTriggersDocument, options);
      }
export type UpdateIndexNowPageStatusTriggersMutationHookResult = ReturnType<typeof useUpdateIndexNowPageStatusTriggersMutation>;
export type UpdateIndexNowPageStatusTriggersMutationResult = Apollo.MutationResult<UpdateIndexNowPageStatusTriggersMutation>;
export type UpdateIndexNowPageStatusTriggersMutationOptions = Apollo.BaseMutationOptions<UpdateIndexNowPageStatusTriggersMutation, UpdateIndexNowPageStatusTriggersMutationVariables>;