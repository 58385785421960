import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';

import useUrlState from '~/hooks/useUrlState';

import {
	getCategoryUrlIdentifier,
} from '~/model/issues/identifierMapping';

import {
	IssueCategoryName,
} from '~/model/issuesNew';



const messages = defineMessages({
	notAvailable: {
		id: 'ui.ignoreIssueCategory.notAvailable.tooltip',
	},
});



type Props = {
	issueCategoryName: IssueCategoryName,
};

const IssueCategoryIgnoringButton: React.FC<Props> = (props) => {
	const {
		issueCategoryName,
	} = props;

	const urlState = useUrlState();

	const linkRoute = (() => {
		if (urlState.name.indexOf('website.issues.') === 0) {
			return {
				name: 'website.issues.issueCategory.categoryIgnoring',
				params: {
					...urlState.params,
					issuesOverviewCategory: getCategoryUrlIdentifier(issueCategoryName),
				},
			};
		} else if (urlState.name.indexOf('website.platform.') === 0) {
			return {
				name: 'website.platform.issuesCategory.categoryIgnoring',
				params: {
					...urlState.params,
					platformScreenIssuesCategoryType: getCategoryUrlIdentifier(issueCategoryName),
				},
			};
		} else if (urlState.name.indexOf('website.pages.detail.') === 0) {
			return {
				name: 'website.pages.detail.issuesCategory.categoryIgnoring',
				params: {
					...urlState.params,
					pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategoryName),
				},
			};
		}

		return null;
	})();

	if (linkRoute === null) {
		return null;
	}

	const isDisabled = [
		IssueCategoryName.Domain,
		IssueCategoryName.RobotsTxt,
		IssueCategoryName.XmlSitemap,
	].includes(issueCategoryName);

	return (
		<Button
			disabled={isDisabled}
			icon={(
				<BasicIcon
					size={24}
					type={BasicIconType.Block}
				/>
			)}
			linkRouteName={linkRoute.name}
			linkRouteParams={linkRoute.params}
			size={ButtonSize.Medium}
			style={ButtonStyle.Hollow}
			tooltip={isDisabled ? (
				<FormattedMessage {...messages.notAvailable} />
			) : (
				<FormattedMessage id={`issues.categories.${issueCategoryName}.ignoringButton`} />
			)}
		/>
	);
};



export default IssueCategoryIgnoringButton;
