import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ScopeIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="scope"
					stroke={color}
					transform="translate(4.000000, 4.000000)"
				>
					<g
						id="Group"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<polyline
							id="Path"
							points="0.571428571 6.19428571 0.571428571 0.572571429 6.44914286 0.572571429"
						>
						</polyline>
						<polyline
							id="Path"
							points="10.0628571 0.571428571 15.8994286 0.571428571 15.8994286 6.19428571"
						>
						</polyline>
						<polyline
							id="Path"
							points="15.8994286 9.936 15.8994286 15.6365714 10.0628571 15.6365714"
						>
						</polyline>
						<polyline
							id="Path"
							points="6.44914286 15.6365714 0.571428571 15.6365714 0.571428571 9.936"
						>
						</polyline>
					</g>
					<circle
						cx="8.23542857"
						cy="8.08685714"
						id="Oval"
						r="3.42857143"
					>
					</circle>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ScopeIconBuilder;

