import type Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AlertDefinitionRecipientsList from '~/components/app/AlertDefinitionRecipientsList';
import AlertTypeTitle from '../alerts/AlertTypeTitle';
import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon from '~/components/patterns/icons/BasicIcon';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import CheckboxGroupContainer from '../checkboxGroup/CheckboxGroupContainer';
import CheckboxInGroup from '../checkboxGroup/CheckboxInGroup';
import Copy from '../Copy';
import DeleteAlertDefinitionConfirmationModal from '~/components/app/DeleteAlertDefinitionConfirmationModal';
import Hint from '~/components/patterns/hints/hint/Hint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import MasterCheckbox from '../checkboxGroup/MasterCheckbox';
import MessagingAppChannelsFieldFilter from '~/components/app/MessagingAppChannelsFieldFilter';
import MissingValue from '~/components/app/MissingValue';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';
import PlainTextFieldFilter from '../datatables/PlainTextFieldFilter';
import RecipientsFieldFilter from '../datatables/RecipientsFieldFilter';
import ResizableDatatable, {
	type ResizableDatatableColumnDefinitions,
	ResizableDatatableHeaderCellAlignment,
	ResizableDatatableHeaderHeight,
	ResizableDatatableRowHeight,
} from '../datatables/ResizableDatatable';
import RichText from '~/components/patterns/typography/RichText';
import ScopeLabel, {
	ScopeLabelSize,
	ScopeLabelStyle,
} from '../scopes/ScopeLabel';
import ScopesFieldFilter from '../datatables/ScopesFieldFilter';
import ScreenMessage from '~/components/patterns/messages/embedded/ScreenMessage';
import SegmentPercentageScenario from './fields/SegmentPercentageScenario';
import SensitivityIndicator from './SensitivityIndicator';
import TableActionButton, {
	TableActionButtonIconType,
} from '~/components/patterns/tables/datatables/buttons/TableActionButton';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import WithPermission from '../access/WithPermission';
import WithSeparator from '~/components/atoms/WithSeparator';

import useMessagingAppDefinitions from '~/hooks/useMessagingAppDefinitions';
import useModals from '~/hooks/useModals';
import useViewportOrientation from '~/hooks/useViewportOrientation';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
	getAlertGroup,
} from '~/model/alerts';

import {
	ObjectType,
} from '~/model/permissions';

import {
	navigate,
} from '~/routing/router';



const messages = defineMessages({
	blankSlateDescription: {
		id: 'ui.alertsConfiguration.blankSlate.description',
	},
	blankSlateTitle: {
		id: 'ui.alertsConfiguration.blankSlate.title',
	},
	columnMessagingAppChannel: {
		id: 'ui.alertsConfiguration.fields.messagingApp',
	},
	columnRecipients: {
		id: 'ui.alertsConfiguration.fields.recipients',
	},
	columnScope: {
		id: 'ui.alertsConfiguration.fields.scope',
	},
	columnSensitivity: {
		id: 'ui.alertsConfiguration.fields.sensitivity',
	},
	columnType: {
		id: 'ui.alertsConfiguration.fields.type',
	},
	noneMessagingAppChannel: {
		id: 'ui.alertsConfiguration.messagingApp.none',
	},
	segmentPercentage: {
		id: 'ui.alertsConfiguration.fields.sensitivity.percentage.value',
	},
});



type Props = {
	filter: Immutable.Map<any, any>,
	filteredAlertDefinitions: Readonly<Array<GraphQL.AlertDefinition>>,
	isLoaded: boolean,
	onFilterChangeCallback: (value: any) => void,
	onSelectionCallback: (value: any) => void,
	selectedDefinitions: ReadonlyArray<any>,
	totalNumberOfAlertDefinitions: number,
};

const OverviewDatatable: React.FC<Props> = (props) => {
	const {
		filter,
		filteredAlertDefinitions,
		isLoaded,
		onFilterChangeCallback,
		onSelectionCallback,
		selectedDefinitions,
		totalNumberOfAlertDefinitions,
	} = props;

	const websiteId = useWebsiteId();

	const messagingAppDefinitions = useMessagingAppDefinitions();
	const modals = useModals();
	const viewportOrientation = useViewportOrientation();
	const viewportType = useViewportType();

	const modalsOpenModal = modals.openModal;

	const openDeleteAlertDefinitionConfirmationModal = React.useCallback(
		(alertDefinitionId: string) => {
			modalsOpenModal(
				() => (
					<DeleteAlertDefinitionConfirmationModal
						selectedAlertDefinitionIds={[alertDefinitionId]}
					/>
				),
			);
		},
		[
			modalsOpenModal,
		],
	);

	const columns: ResizableDatatableColumnDefinitions<typeof rowGetter> = React.useMemo(
		() => {
			const columns: ResizableDatatableColumnDefinitions<typeof rowGetter> = [
				{
					filterName: 'type',
					name: 'alert_definition_type',
					render: {
						cell({ row }) {
							return (
								<Ellipsis>
									<AlertTypeTitle
										alertType={row.alertType}
										settings={row.settings}
									/>
								</Ellipsis>
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<PlainTextFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => (
							<FormattedMessage {...messages.columnType} />
						),
					},
					resizable: true,
					width: 400,
				},
				{
					filterName: 'scope',
					name: 'alert_definition_scope',
					render: {
						cell({ row }) {
							const scope = row.alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT
								? 'segment:' + row.settings.segmentName
								: row.scope;

							return (
								<Ellipsis>
									<ScopeLabel
										scope={scope}
										size={ScopeLabelSize.Small}
										style={ScopeLabelStyle.Label}
									/>
								</Ellipsis>
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<ScopesFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => (
							<FormattedMessage {...messages.columnScope} />
						),
					},
					resizable: true,
					width: 250,
				},
				{
					filterName: 'sensitivity',
					name: 'alert_definition_sensitivity',
					render: {
						cell({ row }) {
							if (row.alertType === ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT) {
								if (row.settings.segmentChangeSizePercentage !== undefined) {
									return (
										<Hint
											popup={(
												<SegmentPercentageScenario
													percentage={row.settings.segmentChangeSizePercentage}
													scope={`segment/${row.settings.segmentName}`}
													segmentChangeType={row.settings.segmentChangeType}
													websiteId={websiteId}
												/>
											)}
										>
											<FormattedMessage
												{...messages.segmentPercentage}
												values={{
													percentage: row.settings.segmentChangeSizePercentage / 100,
												}}
											/>
										</Hint>
									);
								}
							}

							if (row.settings.sensitivityLevel === undefined) {
								return (
									<NotApplicableValue ellipsis={true} />
								);
							}

							return (
								<SensitivityIndicator
									sensitivity={row.settings.sensitivityLevel}
								/>
							);
						},
						label: () => (
							<FormattedMessage {...messages.columnSensitivity} />
						),
					},
					resizable: true,
					width: 250,
				},
				{
					filterName: 'recipients',
					name: 'alert_definition_recipients',
					render: {
						cell({ innerWidth, row }) {
							return (
								<AlertDefinitionRecipientsList
									containerWidth={innerWidth}
									recipients={row.recipients}
								/>
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<RecipientsFieldFilter
								name={filterName}
								ref={ref}
								websiteId={websiteId}
								width={filterWidth}
							/>
						),
						label: () => (
							<FormattedMessage {...messages.columnRecipients} />
						),
					},
					resizable: true,
					width: 250,
				},
				{
					filterName: 'messagingAppChannelId',
					name: 'alert_definition_messagingAppChannels',
					render: {
						cell({ row }) {
							if (row.messagingAppChannels.length > 0) {
								return (
									<Ellipsis>
										<WithSeparator separator=", ">
											{row.messagingAppChannels.map((channel) => (
												<AttachedIcon
													ellipsis={true}
													icon={(
														<BasicIcon type={messagingAppDefinitions.getIcon(channel.messagingAppType)} />
													)}
													key={channel.id}
												>
													{channel.label}
												</AttachedIcon>
											))}
										</WithSeparator>
									</Ellipsis>
								);
							}

							return (
								<Ellipsis
									altPopupText={(
										<FormattedMessage {...messages.noneMessagingAppChannel} />
									)}
								>
									<MissingValue ellipsis={true}>
										<FormattedMessage {...messages.noneMessagingAppChannel} />
									</MissingValue>
								</Ellipsis>
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<MessagingAppChannelsFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => (
							<FormattedMessage {...messages.columnMessagingAppChannel} />
						),
					},
					resizable: true,
					width: 150,
				},
				{
					clickable: false,
					name: 'alert_definition_actions',
					render: {
						cell({ row }) {
							return (
								<ButtonsGroup>
									<TableActionButton
										iconType={TableActionButtonIconType.CogWheel}
										linkRouteName="website.alerts.configuration.editDefinition"
										linkRouteParams={{
											alertDefinitionId: row.id,
											group: getAlertGroup(row.alertType),
											type: row.alertType,
											websiteId,
										}}
										tooltip="Edit alert definition"
									/>

									<WithPermission
										action={GraphQL.ActionWithWebsite.ManageAlertDefinitions}
										objectId={websiteId}
										objectType={ObjectType.Website}
									>
										{({ isAllowed }) => {
											if (isAllowed.yes === false) {
												return null;
											}

											return (
												<TableActionButton
													iconType={TableActionButtonIconType.Delete}
													onClick={() => {
														openDeleteAlertDefinitionConfirmationModal(row.id);
													}}
													tooltip="Delete alert definition"
												/>
											);
										}}
									</WithPermission>
								</ButtonsGroup>
							);
						},
						header: () => false,
					},
					width: 250,
				},
			];

			if (!(viewportType.isSmall || (viewportType.isMedium && viewportOrientation.isPortrait))) {
				columns.unshift({
					clickable: false,
					headerAlignment: ResizableDatatableHeaderCellAlignment.Center,
					name: 'alert_definition_checkbox',
					render: {
						cell({ row }) {
							return (
								<CheckboxInGroup
									name={row.id}
								/>
							);
						},
						header: () => (
							<MasterCheckbox />
						),
					},
					width: 64,
				});
			}

			return columns;
		},
		[
			messagingAppDefinitions,
			openDeleteAlertDefinitionConfirmationModal,
			viewportOrientation,
			viewportType,
			websiteId,
		],
	);

	const handleRowClick = React.useCallback(
		({ row }) => {
			navigate('website.alerts.configuration.editDefinition', {
				alertDefinitionId: row.id,
				group: getAlertGroup(row.alertType),
				type: row.alertType,
				websiteId,
			});
		},
		[
			websiteId,
		],
	);

	const rowGetter = React.useCallback(
		({ rowIndex }) => {
			return filteredAlertDefinitions[rowIndex];
		},
		[
			filteredAlertDefinitions,
		],
	);

	const renderBlankSlate = React.useCallback(
		() => (
			<NoSearchResults />
		),
		[],
	);

	if (isLoaded && totalNumberOfAlertDefinitions === 0) {
		return (
			<BlankSlate websiteId={websiteId} />
		);
	}

	return (
		<CheckboxGroupContainer
			name="alerts_configuration"
			onChangeCallback={onSelectionCallback}
			options={filteredAlertDefinitions.map((definition) => definition.id)}
			value={selectedDefinitions}
		>
			<ResizableDatatable
				blankSlate={renderBlankSlate}
				columns={columns}
				filter={filter}
				headerHeight={ResizableDatatableHeaderHeight.Medium}
				isLoading={isLoaded === false}
				key={viewportOrientation.isLandscape ? 'table-in-landscape' : 'table-in-portrait'}
				numberOfPinnedColumns={2}
				onFilterChange={onFilterChangeCallback}
				onRowClick={handleRowClick}
				rowGetter={rowGetter}
				rowHeightStyle={ResizableDatatableRowHeight.Small}
				rowsCount={filteredAlertDefinitions.length}
			/>
		</CheckboxGroupContainer>
	);
};



type BlankSlateProps = {
	websiteId: CK.WebsiteId,
};

const BlankSlate: React.FC<BlankSlateProps> = (props) => {
	const {
		websiteId,
	} = props;

	return (
		<ScreenMessage
			indented={true}
			title={(
				<FormattedMessage {...messages.blankSlateTitle} />
			)}
		>
			<RichText>
				<Copy
					{...messages.blankSlateDescription}
					values={{
						linkCreateAlertDefinitions: (chunks) => (
							<InternalLink
								routeName="website.alerts.configuration.createDefinitions"
								routeParams={{
									websiteId,
								}}
								style={InternalLinkStyle.Decent}
							>
								{chunks}
							</InternalLink>
						),
					}}
				/>
			</RichText>
		</ScreenMessage>
	);
};



export default OverviewDatatable;
