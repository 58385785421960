export function unique(value, index, self) {
	return self.indexOf(value) === index;
}



export function uniqueBy(getBy) {
	return (value, index, self) => self.findIndex(
		(item) => getBy(item) === getBy(value),
	) === index;
}
