import React, {
	Component,
} from 'react';



class ResponsiveContent extends Component {

	render() {
		const {
			children,
		} = this.props;

		return (
			<div className="responsive-column responsive-column--content">
				{children}
			</div>
		);
	}

}



export default ResponsiveContent;
