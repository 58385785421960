import React from 'react';

import SwitchButton from './SwitchButton.part';
import SwitchButtonsGroup from './SwitchButtonsGroup.part';



export enum SwitchButtonsSize {
	XSmall = 'xsmall',
	Small = 'small',
	Default = 'default',
}

type Props = {
	buttons: Array<{
		/** Tooltip available for disabled buttons to explain reason of disabled state */
		disabledTooltip?: React.ReactNode,
		/** Icon can be attached to label */
		icon?: React.ReactElement,
		/** Active style of menu item */
		isActive?: boolean,
		/** Disabled menu item */
		isDisabled?: boolean,
		/** Button label */
		label: React.ReactNode,
		/** By setting routeName we can change this component to Link instance */
		linkRouteName?: string,
		linkRouteParams?: {},
		onClickCallback?: () => void,
		/** Make font uppercased */
		uppercase?: boolean,
	}>,
	/** Stretched buttons to the full-width of available space in container */
	fullwidth?: boolean,
	/** Size of buttons */
	size?: SwitchButtonsSize,
	/** Make font uppercased for all buttons */
	uppercase?: boolean,
	/** Custom width of button */
	width?: React.CSSProperties['width'],
};



const SwitchButtons: React.FC<Props> = (props) => {
	const {
		buttons,
		fullwidth = true,
		size = SwitchButtonsSize.Default,
		uppercase,
		width,
	} = props;

	return (
		<SwitchButtonsGroup
			fullwidth={fullwidth}
			width={width}
		>
			{buttons.map((button, index) => (
				<SwitchButton
					disabledTooltip={button.disabledTooltip}
					icon={button.icon}
					isActive={button.isActive}
					isDisabled={button.isDisabled}
					key={'switch-button-' + index}
					linkRouteName={button.linkRouteName}
					linkRouteParams={button.linkRouteParams}
					onClickCallback={button.onClickCallback}
					size={size}
					uppercase={uppercase || button.uppercase || false}
				>
					{button.label}
				</SwitchButton>
			))}
		</SwitchButtonsGroup>
	);
};



export default SwitchButtons;
