import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';

import FlashMessage, {
	FlashMessageType,
} from '~/components/patterns/messages/popup/FlashMessage';

import {
	STYLE_CRITICAL,
	STYLE_SUCCESS,
	STYLE_WARNING,
	clearFlashMessage,
} from '~/actions/flashMessage';

import {
	selector as flashMessageSelector,
} from '~/state/flashMessage/selectors';

import {
	urlStateSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	alertDefinitionsCreated: {
		id: 'ui.flashMessages.alertDefinitionsCreated',
	},
	alertDefinitionsDeleted: {
		id: 'ui.flashMessages.alertDefinitionsDeleted',
	},
	alertDefinitionsEdited: {
		id: 'ui.flashMessages.alertDefinitionsEdited',
	},
});



const select = createSelector(
	flashMessageSelector,
	urlStateSelector,
	(
		flashMessage,
		urlState,
	) => {
		return {
			flashMessage,
			urlState,
		};
	},
);



const styleMapping = {
	[STYLE_CRITICAL]: FlashMessageType.Critical,
	[STYLE_SUCCESS]: FlashMessageType.Success,
	[STYLE_WARNING]: FlashMessageType.Warning,
};



class FlashMessageProvider extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			flashMessage: null,
		};
	}



	componentDidUpdate(prevProps) {
		const {
			flashMessage: prevFlashMessage,
			urlState: prevUrlState,
		} = prevProps;

		const {
			dispatch,
			flashMessage: nextFlashMessage,
			urlState: nextUrlState,
		} = this.props;

		if (
			(!prevFlashMessage || prevFlashMessage.get('route') !== prevUrlState.name)
			&& (nextFlashMessage && nextFlashMessage.get('route') === nextUrlState.name)
		) {
			this.setState({
				flashMessage: nextFlashMessage,
			});

			dispatch(
				clearFlashMessage(),
			);

			this._timeout = setTimeout(() => {
				this.setState({
					flashMessage: null,
				});
			}, 5000);
		}
	}



	componentWillUnmount() {
		if (this._timeout) {
			clearTimeout(this._timeout);
		}
	}



	render() {
		const {
			flashMessage,
		} = this.state;

		let flashMessageElement;

		if (flashMessage) {
			flashMessageElement = (
				<FlashMessage
					type={styleMapping[flashMessage.get('style')]}
				>
					<FormattedMessage
						{...messages[flashMessage.get('message')]}
						values={flashMessage.get('messageVariables')}
					/>
				</FlashMessage>
			);
		}

		return this.props.children({
			flashMessage: flashMessageElement,
		});
	}

}



export default connect(select)(FlashMessageProvider);
