import Highcharts from 'highcharts';



// Workaround for https://github.com/highcharts/highcharts/issues/13710
(function (H) {
	H.seriesTypes.pie.prototype.drawEmpty = function () {
		const start = this.startAngleRad;
		const end = this.endAngleRad;
		const options = this.options;

		let centerX;
		let centerY;

		// Draw auxiliary graph if there're no visible points.
		if (this.total === 0) {
			centerX = this.center[0];
			centerY = this.center[1];
			if (!this.graph) {
				this.graph = this.chart.renderer
					.arc(centerX, centerY, this.center[1] / 2, 0, start, end)
					.addClass('highcharts-empty-series')
					.add(this.group);
			}

			this.graph.attr({
				d: H.SVGRenderer.prototype.symbols.arc(
					centerX,
					centerY,
					this.center[2] / 2,
					0,
					{
						start,
						end,
						innerR: this.center[3] / 2,
					},
				),
			});
			if (!this.chart.styledMode) {
				this.graph.attr({
					'stroke-width': options.borderWidth,
					'fill': options.fillColor || 'none',
					'stroke': options.color || '#cccccc',
				});
			}
		} else if (this.graph) {
			// Destroy the graph object.
			this.graph = this.graph.destroy();
		}
	};
})(Highcharts);



/**
 * Helper methods for Highcharts
 */



// Apply Highcharts global styles
export function setHighchartsGlobalStyles() {
	// Load the fonts
	Highcharts.createElement('link', {
		href: '//fonts.googleapis.com/css?family=Roboto:300,400',
		rel: 'stylesheet',
		type: 'text/css',
	}, null, document.getElementsByTagName('head')[0]);

	Highcharts.theme = {
		chart: {
			style: {
				fontFamily: 'Roboto, sans-serif',
				fontWeight: '300',
			},
		},
		credits: {
			enabled: false,
		},
		legend: {
			enabled: false,
		},
		title: {
			text: null,
		},
		tooltip: {
			hideDelay: 0,
		},
		yAxis: {
			gridLineColor: 'rgba(232, 232, 232, 0.7)',
		},
	};

	// Apply global theme
	Highcharts.setOptions(Highcharts.theme);
}



// Shared styles for bar charts and stacked bar charts
export function getSharedBarChartStyles() {
	return {
		chart: {
			animation: false,
		},
		plotOptions: {
			series: {
				animation: false,
				borderColor: '#f9fafb',
				pointWidth: 16,
				states: {
					inactive: {
						opacity: 1,
					},
				},
			},
		},
		tooltip: {
			backgroundColor: '#5C6773',
			borderColor: 'rgba(0,0,0,0.12)',
			borderWidth: 1,
			followPointer: true,
			padding: 6,
			shadow: false,
			style: {
				color: '#fff',
				fontSize: 12,
				fontWeight: 400,
				whiteSpace: 'nowrap',
			},
		},
		xAxis: {
			labels: {
				enabled: false,
			},
			maxPadding: 0,
			minPadding: 0,
			lineWidth: 0,
			tickWidth: 0,
			title: {
				text: null,
			},
		},
		yAxis: {
			tickColor: 'rgba(232,232,232,0.7)',
			title: {
				text: null,
			},
		},
	};
}



// Calculation is take from this thread https://stackoverflow.com/a/40356854/2961039
function _getCirclePath(cx, cy, r) {
	return [
		'M',
		cx,
		cy,
		'm',
		-r,
		0,
		'a',
		r,
		r,
		0,
		1,
		0,
		r * 2,
		0,
		'a',
		r,
		r,
		0,
		1,
		0,
		-r * 2,
		0,
	];
}



export function defineHighchartsSmallCircleMarkerSymbol() {
	Highcharts.SVGRenderer.prototype.symbols.smallCircle = function (x, y, w, h) {
		return _getCirclePath(x + (w / 2), y + (h / 2), 2);
	};

	if (Highcharts.VMLRenderer) {
		Highcharts.VMLRenderer.prototype.symbols.cross = Highcharts.SVGRenderer.prototype.symbols.cross;
	}
}



export function setCustomRoundedCorners() {
	const rel = Highcharts.relativeLength;

	Highcharts.wrap(Highcharts.seriesTypes.column.prototype, 'translate', function (proceed) {
		const options = this.options;
		const topMargin = options.topMargin || 0;
		const bottomMargin = options.bottomMargin || 0;

		proceed.call(this);

		this.points.forEach((point) => {
			const shapeArgs = point.shapeArgs;
			const w = shapeArgs.width;
			const h = shapeArgs.height;
			const x = shapeArgs.x;
			const y = shapeArgs.y;

			// Get the radius
			let rTopLeft = rel(options.borderRadiusTopLeft || 0, w);
			let rTopRight = rel(options.borderRadiusTopRight || 0, w);
			let rBottomRight = rel(options.borderRadiusBottomRight || 0, w);
			let rBottomLeft = rel(options.borderRadiusBottomLeft || 0, w);

			if (rTopLeft || rTopRight || rBottomRight || rBottomLeft) {
				const maxR = Math.min(w, h) / 2;

				if (rTopLeft > maxR) {
					rTopLeft = maxR;
				}

				if (rTopRight > maxR) {
					rTopRight = maxR;
				}

				if (rBottomRight > maxR) {
					rBottomRight = maxR;
				}

				if (rBottomLeft > maxR) {
					rBottomLeft = maxR;
				}

				// Preserve the box for data labels
				point.dlBox = point.shapeArgs;

				point.shapeType = 'path';
				point.shapeArgs.d = [
					'M',
					x + rTopLeft,
					y + topMargin,
					// top side
					'L',
					x + w - rTopRight,
					y + topMargin,
					// top right corner
					'C',
					x + w - rTopRight / 2,
					y,
					x + w,
					y + rTopRight / 2,
					x + w,
					y + rTopRight,
					// right side
					'L',
					x + w,
					y + h - rBottomRight,
					// bottom right corner
					'C',
					x + w,
					y + h - rBottomRight / 2,
					x + w - rBottomRight / 2,
					y + h,
					x + w - rBottomRight,
					y + h + bottomMargin,
					// bottom side
					'L',
					x + rBottomLeft,
					y + h + bottomMargin,
					// bottom left corner
					'C',
					x + rBottomLeft / 2,
					y + h,
					x,
					y + h - rBottomLeft / 2,
					x,
					y + h - rBottomLeft,
					// left side
					'L',
					x,
					y + rTopLeft,
					// top left corner
					'C',
					x,
					y + rTopLeft / 2,
					x + rTopLeft / 2,
					y,
					x + rTopLeft,
					y,
					'Z',
				];
			}
		});
	});
}
