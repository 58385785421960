import React from 'react';

import {
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import SegmentDefinitionIdentifier, {
	SegmentDefinitionIdentifierSize,
} from '~/components/logic/segments/SegmentDefinitionIdentifier';
import WebsiteScopeLabel, {
	WebsiteScopeLabelSize,
} from '~/components/app/WebsiteScopeLabel';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	getSegmentDefinitionByName,
} from '~/model/segments';

import matchAndReturn from '~/utilities/matchAndReturn';



export enum ScopeLabelSize {
	Default = 'default',
	Small = 'small',
}

export enum ScopeLabelStyle {
	Label = 'label',
	Thumb = 'thumb',
}



type Props = {
	hintDisplayOn?: HintPopupVisibility,
	onClickCallback?: () => void,
	scope: string,
	showHint?: boolean,
	size?: ScopeLabelSize,
	style: ScopeLabelStyle,
};

const ScopeLabel: React.FC<Props> = (props) => {
	const {
		hintDisplayOn = HintPopupVisibility.OnHover,
		onClickCallback,
		scope,
		showHint = true,
		size = ScopeLabelSize.Default,
		style,
	} = props;

	const websiteId = useWebsiteId();

	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	if (scope === 'website') {
		return (
			<WebsiteScopeLabel
				hintDisplayOn={hintDisplayOn}
				onClickCallback={onClickCallback}
				onlyThumb={style === ScopeLabelStyle.Thumb}
				showHint={showHint}
				size={matchAndReturn(size, {
					[ScopeLabelSize.Default]: WebsiteScopeLabelSize.Default,
					[ScopeLabelSize.Small]: WebsiteScopeLabelSize.Small,
				})}
			/>
		);
	}

	let segment;

	if (scope.indexOf('segment:') === 0) {
		const segmentName = scope.substr('segment:'.length);
		segment = getSegmentDefinitionByName(
			segmentDefinitions.listAll(),
			segmentName,
		);
	}

	if (scope.indexOf('segment/') === 0) {
		const segmentName = scope.substr('segment/'.length);
		segment = getSegmentDefinitionByName(
			segmentDefinitions.listAll(),
			segmentName,
		);
	}

	if (segment) {
		return (
			<SegmentDefinitionIdentifier
				hintDisplayOn={hintDisplayOn}
				onClickCallback={onClickCallback}
				onlyThumb={style === ScopeLabelStyle.Thumb}
				segmentDefinition={segment}
				showCriteria={showHint}
				size={matchAndReturn(size, {
					[ScopeLabelSize.Default]: SegmentDefinitionIdentifierSize.Default,
					[ScopeLabelSize.Small]: SegmentDefinitionIdentifierSize.Small,
				})}
			/>
		);
	}

	return null;
};



export default React.memo(ScopeLabel);

export {
	HintPopupVisibility as ScopeLabelHintDisplayOn,
};
