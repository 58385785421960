import React from 'react';



type ActivePanelContextType = {
	close: (() => void) | null,
	isModal: boolean,
};



const ActivePanelContext = React.createContext<ActivePanelContextType>({
	close: null,
	isModal: false,
});



type Props = {
	children?: React.ReactNode,
	close: (() => void) | null,
	isModal: boolean,
};



const ActivePanelProvider: React.FC<Props> = (props) => {
	const {
		children,
		close,
		isModal,
	} = props;

	const value = React.useMemo(
		() => ({
			close,
			isModal,
		}),
		[
			close,
			isModal,
		],
	);

	return (
		<ActivePanelContext.Provider value={value}>
			{children}
		</ActivePanelContext.Provider>
	);
};



export default ActivePanelProvider;

export {
	ActivePanelContext,
};
