/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IgnorePageIssueCategoryOnSpecificPagesMutationVariables = GraphQL.Exact<{
  issueCategoryName: GraphQL.Scalars['String']['input'];
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IgnorePageIssueCategoryOnSpecificPagesMutation = { readonly __typename?: 'Mutation', readonly IgnorePageIssueCategoryOnSpecificPages: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type IgnorePageIssueOnSpecificPagesMutationVariables = GraphQL.Exact<{
  issueName: GraphQL.Scalars['String']['input'];
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IgnorePageIssueOnSpecificPagesMutation = { readonly __typename?: 'Mutation', readonly IgnorePageIssueOnSpecificPages: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type UnignorePageIssueCategoryOnSpecificPagesMutationVariables = GraphQL.Exact<{
  issueCategoryName: GraphQL.Scalars['String']['input'];
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueCategoryOnSpecificPagesMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueCategoryOnSpecificPages: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type UnignorePageIssueOnSpecificPagesMutationVariables = GraphQL.Exact<{
  issueName: GraphQL.Scalars['String']['input'];
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueOnSpecificPagesMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueOnSpecificPages: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const IgnorePageIssueCategoryOnSpecificPagesDocument = gql`
    mutation IgnorePageIssueCategoryOnSpecificPages($issueCategoryName: String!, $urls: [String!]!, $websiteId: WebsiteId!) {
  IgnorePageIssueCategoryOnSpecificPages(
    issueCategory: $issueCategoryName
    urls: $urls
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type IgnorePageIssueCategoryOnSpecificPagesMutationFn = Apollo.MutationFunction<IgnorePageIssueCategoryOnSpecificPagesMutation, IgnorePageIssueCategoryOnSpecificPagesMutationVariables>;

/**
 * __useIgnorePageIssueCategoryOnSpecificPagesMutation__
 *
 * To run a mutation, you first call `useIgnorePageIssueCategoryOnSpecificPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnorePageIssueCategoryOnSpecificPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignorePageIssueCategoryOnSpecificPagesMutation, { data, loading, error }] = useIgnorePageIssueCategoryOnSpecificPagesMutation({
 *   variables: {
 *      issueCategoryName: // value for 'issueCategoryName'
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIgnorePageIssueCategoryOnSpecificPagesMutation(baseOptions?: Apollo.MutationHookOptions<IgnorePageIssueCategoryOnSpecificPagesMutation, IgnorePageIssueCategoryOnSpecificPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgnorePageIssueCategoryOnSpecificPagesMutation, IgnorePageIssueCategoryOnSpecificPagesMutationVariables>(IgnorePageIssueCategoryOnSpecificPagesDocument, options);
      }
export type IgnorePageIssueCategoryOnSpecificPagesMutationHookResult = ReturnType<typeof useIgnorePageIssueCategoryOnSpecificPagesMutation>;
export type IgnorePageIssueCategoryOnSpecificPagesMutationResult = Apollo.MutationResult<IgnorePageIssueCategoryOnSpecificPagesMutation>;
export type IgnorePageIssueCategoryOnSpecificPagesMutationOptions = Apollo.BaseMutationOptions<IgnorePageIssueCategoryOnSpecificPagesMutation, IgnorePageIssueCategoryOnSpecificPagesMutationVariables>;
export const IgnorePageIssueOnSpecificPagesDocument = gql`
    mutation IgnorePageIssueOnSpecificPages($issueName: String!, $urls: [String!]!, $websiteId: WebsiteId!) {
  IgnorePageIssueOnSpecificPages(
    type: $issueName
    urls: $urls
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type IgnorePageIssueOnSpecificPagesMutationFn = Apollo.MutationFunction<IgnorePageIssueOnSpecificPagesMutation, IgnorePageIssueOnSpecificPagesMutationVariables>;

/**
 * __useIgnorePageIssueOnSpecificPagesMutation__
 *
 * To run a mutation, you first call `useIgnorePageIssueOnSpecificPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnorePageIssueOnSpecificPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignorePageIssueOnSpecificPagesMutation, { data, loading, error }] = useIgnorePageIssueOnSpecificPagesMutation({
 *   variables: {
 *      issueName: // value for 'issueName'
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIgnorePageIssueOnSpecificPagesMutation(baseOptions?: Apollo.MutationHookOptions<IgnorePageIssueOnSpecificPagesMutation, IgnorePageIssueOnSpecificPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgnorePageIssueOnSpecificPagesMutation, IgnorePageIssueOnSpecificPagesMutationVariables>(IgnorePageIssueOnSpecificPagesDocument, options);
      }
export type IgnorePageIssueOnSpecificPagesMutationHookResult = ReturnType<typeof useIgnorePageIssueOnSpecificPagesMutation>;
export type IgnorePageIssueOnSpecificPagesMutationResult = Apollo.MutationResult<IgnorePageIssueOnSpecificPagesMutation>;
export type IgnorePageIssueOnSpecificPagesMutationOptions = Apollo.BaseMutationOptions<IgnorePageIssueOnSpecificPagesMutation, IgnorePageIssueOnSpecificPagesMutationVariables>;
export const UnignorePageIssueCategoryOnSpecificPagesDocument = gql`
    mutation UnignorePageIssueCategoryOnSpecificPages($issueCategoryName: String!, $urls: [String!]!, $websiteId: WebsiteId!) {
  UnignorePageIssueCategoryOnSpecificPages(
    issueCategory: $issueCategoryName
    urls: $urls
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePageIssueCategoryOnSpecificPagesMutationFn = Apollo.MutationFunction<UnignorePageIssueCategoryOnSpecificPagesMutation, UnignorePageIssueCategoryOnSpecificPagesMutationVariables>;

/**
 * __useUnignorePageIssueCategoryOnSpecificPagesMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueCategoryOnSpecificPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueCategoryOnSpecificPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueCategoryOnSpecificPagesMutation, { data, loading, error }] = useUnignorePageIssueCategoryOnSpecificPagesMutation({
 *   variables: {
 *      issueCategoryName: // value for 'issueCategoryName'
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueCategoryOnSpecificPagesMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueCategoryOnSpecificPagesMutation, UnignorePageIssueCategoryOnSpecificPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueCategoryOnSpecificPagesMutation, UnignorePageIssueCategoryOnSpecificPagesMutationVariables>(UnignorePageIssueCategoryOnSpecificPagesDocument, options);
      }
export type UnignorePageIssueCategoryOnSpecificPagesMutationHookResult = ReturnType<typeof useUnignorePageIssueCategoryOnSpecificPagesMutation>;
export type UnignorePageIssueCategoryOnSpecificPagesMutationResult = Apollo.MutationResult<UnignorePageIssueCategoryOnSpecificPagesMutation>;
export type UnignorePageIssueCategoryOnSpecificPagesMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueCategoryOnSpecificPagesMutation, UnignorePageIssueCategoryOnSpecificPagesMutationVariables>;
export const UnignorePageIssueOnSpecificPagesDocument = gql`
    mutation UnignorePageIssueOnSpecificPages($issueName: String!, $urls: [String!]!, $websiteId: WebsiteId!) {
  UnignorePageIssueOnSpecificPages(
    type: $issueName
    urls: $urls
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePageIssueOnSpecificPagesMutationFn = Apollo.MutationFunction<UnignorePageIssueOnSpecificPagesMutation, UnignorePageIssueOnSpecificPagesMutationVariables>;

/**
 * __useUnignorePageIssueOnSpecificPagesMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueOnSpecificPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueOnSpecificPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueOnSpecificPagesMutation, { data, loading, error }] = useUnignorePageIssueOnSpecificPagesMutation({
 *   variables: {
 *      issueName: // value for 'issueName'
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueOnSpecificPagesMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueOnSpecificPagesMutation, UnignorePageIssueOnSpecificPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueOnSpecificPagesMutation, UnignorePageIssueOnSpecificPagesMutationVariables>(UnignorePageIssueOnSpecificPagesDocument, options);
      }
export type UnignorePageIssueOnSpecificPagesMutationHookResult = ReturnType<typeof useUnignorePageIssueOnSpecificPagesMutation>;
export type UnignorePageIssueOnSpecificPagesMutationResult = Apollo.MutationResult<UnignorePageIssueOnSpecificPagesMutation>;
export type UnignorePageIssueOnSpecificPagesMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueOnSpecificPagesMutation, UnignorePageIssueOnSpecificPagesMutationVariables>;