import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BackLink from '~/components/patterns/links/BackLink';



const messages = defineMessages({
	affectedLinks: {
		id: 'ui.backlink.desktop.affectedLinks',
	},
	affectedPages: {
		id: 'ui.backlink.desktop.affectedPages',
	},
	alertPages: {
		id: 'ui.backlink.desktop.pagesOverview',
	},
	alerts: {
		id: 'ui.backlink.desktop.alerts',
	},
	closedPages: {
		id: 'ui.backlink.desktop.closedPages',
	},
	default: {
		id: 'ui.backlink.desktop.default',
	},
	issueDetail: {
		id: 'ui.backlink.desktop.issueDetail',
	},
	issuesOverview: {
		id: 'ui.backlink.desktop.issuesOverview',
	},
	pageDetail: {
		id: 'ui.backlink.desktop.pageDetail',
	},
	pagesOverview: {
		id: 'ui.backlink.desktop.pagesOverview',
	},
	platform: {
		id: 'ui.backlink.desktop.platform',
	},
	website: {
		id: 'ui.backlink.desktop.website',
	},
	websites: {
		id: 'ui.backlink.desktop.websites',
	},
	websiteSettings: {
		id: 'ui.backlink.desktop.websiteSettings',
	},
});



class Backlink extends Component {

	render() {
		const {
			callback,
			label,
		} = this.props;

		return (
			<BackLink
				label={(
					<FormattedMessage {...messages[label]} />
				)}
				onClickCallback={callback}
			/>
		);
	}

}

Backlink.propTypes = {
	callback: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
};



export default Backlink;
