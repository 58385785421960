import React from 'react';

import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';



const DefinitionTermValueSkeleton: React.FC = () => {
	return (
		<SquareSkeleton
			height={14}
			maxWidth={Math.floor(Math.random() * (300 - 100 + 1)) + 100}
			style={SquareSkeletonStyle.Light}
		/>
	);
};



export default React.memo(DefinitionTermValueSkeleton);
