import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import ItemPerLineTextArea from '~/components/app/ItemPerLineTextArea';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticList from '~/components/atoms/forms/components/StaticList';

import {
	useAccountAdminPreverifiedDomainsFormQuery,
	useUpdateAccountAdminPreverifiedDomainsMutation,
} from './AccountAdminPreverifiedDomainsForm.gql';

import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';



type Props = {
	accountId: CK.AccountId,
};

const AccountAdminPreverifiedDomainsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const isAllowedToManageInternals = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageInternals,
	);

	const { data } = useAccountAdminPreverifiedDomainsFormQuery({
		variables: {
			accountId,
		},
	});

	const [updateAccountAdminPreverifiedDomains] = useUpdateAccountAdminPreverifiedDomainsMutation();

	const preverifiedDomains = data?.account?.adminSettings?.preverifiedDomainsByCustomerTeam ?? null;

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateAccountAdminPreverifiedDomains({
				variables: {
					accountId,
					preverifiedDomains: values.preverifiedDomains,
				},
			});
		},
		[
			accountId,
			updateAccountAdminPreverifiedDomains,
		],
	);

	if (preverifiedDomains === null) {
		return null;
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageInternals}
			isForAdmins={true}
			title="Preverified domains"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Preverified domains">
						<StaticList focusTarget="preverifiedDomains">
							{preverifiedDomains}
						</StaticList>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						preverifiedDomains,
					}}
					onSuccess={handleSubmit}
				>
					<FormRows>
						<FormRow
							htmlFor="preverifiedDomains"
							label="Preverified domains"
						>
							<FieldStatus name="preverifiedDomains">
								<ItemPerLineTextArea
									name="preverifiedDomains"
									size={{
										rows: 8,
										width: '100%',
									}}
								/>
							</FieldStatus>
						</FormRow>
					</FormRows>

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default AccountAdminPreverifiedDomainsForm;
