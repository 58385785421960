import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CarouselBlocks from '~/components/patterns/structures/CarouselBlocks';
import HeaderLayout from '~/components/logic/issuesOverview/components/HeaderLayout';
import HistoricalIssuesChart from '~/components/logic/charts/HistoricalIssuesChart';
import HistoricalWebsiteHealthChart from '~/components/logic/charts/HistoricalWebsiteHealthChart';
import MarginsList from '~/components/atoms/lists/MarginsList';
import OpenFilterButton from '~/components/logic/issuesOverview/components/OpenFilterButton';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';

import useViewportType from '~/hooks/useViewportType';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



enum Tab {
	Graphs,
	Issues,
}

const TABS = [
	Tab.Issues,
	Tab.Graphs,
];

const tabMessages = defineMessages({
	[Tab.Graphs]: {
		id: 'ui.issuesOverview.tabs.graphs',
	},
	[Tab.Issues]: {
		id: 'ui.issuesOverview.tabs.issues',
	},
});



type Props = {
	innerPanel: any,
	issueCategoriesList: React.ReactNode,
	issuesFilterParameter: string,
};

const HistoricalMobileLayout: React.FC<Props> = (props) => {
	const {
		innerPanel,
		issueCategoriesList,
		issuesFilterParameter,
	} = props;

	const viewportType = useViewportType();

	const [activeTab, setActiveTab] = React.useState(Tab.Issues);
	const [tabsNavigationProgress, setTabsNavigationProgress] = React.useState(false);

	const handleTabSwipe = React.useCallback(
		(index: number) => {
			setActiveTab(getArrayItemAtSafeIndex(TABS, index));
		},
		[
			setActiveTab,
		],
	);

	const updateActivePageTab = React.useCallback(
		(state) => {
			if (tabsNavigationProgress === false) {
				return;
			}

			setTimeout(() => {
				setActiveTab(state);
				setTabsNavigationProgress(true);

				setTimeout(() => {
					setTabsNavigationProgress(false);
				}, 300);
			}, 0);
		},
		[
			setActiveTab,
			setTabsNavigationProgress,
			tabsNavigationProgress,
		],
	);

	const updateActivePageTabToIssues = React.useCallback(
		() => {
			updateActivePageTab(Tab.Issues);
		},
		[
			updateActivePageTab,
		],
	);

	const updateActivePageTabToGraphs = React.useCallback(
		() => {
			updateActivePageTab(Tab.Graphs);
		},
		[
			updateActivePageTab,
		],
	);

	const onClickCallbacks = {
		[Tab.Graphs]: updateActivePageTabToGraphs,
		[Tab.Issues]: updateActivePageTabToIssues,
	};

	let screenBodyOverlay = false;

	if (innerPanel) {
		screenBodyOverlay = innerPanel();
	}

	return (
		<ScreenLayout
			contentOverlay={screenBodyOverlay}
			header={(
				<HeaderLayout
					buttons={(
						<OpenFilterButton
							issuesFilterParameter={issuesFilterParameter}
						/>
					)}
					footer={(
						<TabNavigation>
							{TABS.map((tab) => (
								<TabNavigationItem
									isActive={activeTab === tab}
									key={tab}
									onClickCallback={onClickCallbacks[tab]}
									uppercase={true}
								>
									<FormattedMessage {...tabMessages[tab]} />
								</TabNavigationItem>
							))}
						</TabNavigation>
					)}
				/>
			)}
			isHeaderFixed={viewportType.isSmall === false}
		>
			<CarouselBlocks
				activeSlideIndex={TABS.indexOf(activeTab)}
				draggable={true}
				onSlideChangeCallback={handleTabSwipe}
				selectableText={true}
			>
				{issueCategoriesList}

				<ScreenBody>
					<MarginsList>
						<HistoricalWebsiteHealthChart />
						<HistoricalIssuesChart />
					</MarginsList>
				</ScreenBody>
			</CarouselBlocks>
		</ScreenLayout>
	);
};



export default HistoricalMobileLayout;
