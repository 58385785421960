import React from 'react';

import type CK from '~/types/contentking';

import DomainsBox from '~/components/logic/platform/DomainsBox';
import HeaderContent from '~/components/logic/platform/HeaderContent';
import IssueCategoriesSidebarSummary from '~/components/app/IssueCategoriesSidebarSummary';
import PlatformIssueCategoriesList from '~/components/logic/issues/issuesCategoriesList/PlatformIssueCategoriesList';
import RobotsTxtBox from '~/components/logic/platform/RobotsTxtBox';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenBodyLayout, {
	ScreenBodyLayoutPosition,
} from '~/components/patterns/screens/parts/body/ScreenBodyLayout';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import WebVitalsOriginSummaryBlock from '~/components/logic/platform/WebVitalsOriginSummaryBlock';
import XmlSitemapsBox from '~/components/logic/platform/XmlSitemapsBox';



type Props = {
	fullscreenDetailPanel: any,
	innerPanel: any,
	websiteId: CK.WebsiteId,
};

const DesktopLayout: React.FC<Props> = (props) => {
	const {
		fullscreenDetailPanel,
		innerPanel,
		websiteId,
	} = props;

	return (
		<ScreenLayout
			header={(
				<ScreenHeader>
					<HeaderContent
						websiteId={websiteId}
					/>
				</ScreenHeader>
			)}
		>
			<ScreenBodyLayout
				contentOverlay={innerPanel ? innerPanel() : false}
				sidebar={(
					<IssueCategoriesSidebarSummary
						isLoading={false}
						renderIssueCategoriesList={({ isActionable }) => (
							<PlatformIssueCategoriesList
								numberOfPlaceholders={3}
								showActionable={isActionable}
								websiteId={websiteId}
							/>
						)}
					/>
				)}
				sidebarPosition={ScreenBodyLayoutPosition.Right}
			>
				<ScreenBody>
					<DomainsBox
						websiteId={websiteId}
					/>
					<XmlSitemapsBox
						websiteId={websiteId}
					/>
					<RobotsTxtBox
						websiteId={websiteId}
					/>
					<WebVitalsOriginSummaryBlock
						websiteId={websiteId}
					/>
				</ScreenBody>
			</ScreenBodyLayout>

			{fullscreenDetailPanel && fullscreenDetailPanel()}
		</ScreenLayout>
	);
};



export default DesktopLayout;
