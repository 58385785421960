import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import BarPagesChart from '../components/BarPagesChart';



class RelevanceChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		this.colors = {
			'0-1': '#367be2',
			'1-2': '#367be2',
			'2-3': '#367be2',
			'3-4': '#367be2',
			'4-5': '#367be2',
			'5-6': '#367be2',
			'6-7': '#367be2',
			'7-8': '#367be2',
			'8-9': '#367be2',
			'9-10': '#367be2',
		};

		this.labels = {
			'0-1': '0-1',
			'1-2': '1-2',
			'2-3': '2-3',
			'3-4': '3-4',
			'4-5': '4-5',
			'5-6': '5-6',
			'6-7': '6-7',
			'7-8': '7-8',
			'8-9': '8-9',
			'9-10': '9-10',
		};
	}



	render() {
		const {
			data,
		} = this.props;

		return (
			<BarPagesChart
				colors={this.colors}
				data={data}
				labels={this.labels}
				name="relevance"
			/>
		);
	}

}

RelevanceChart.propTypes = {
	data: PropTypes.instanceOf(List),
};



export default RelevanceChart;
