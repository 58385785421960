import classNames from 'classnames';
import React from 'react';

import ValidationMessage, {
	ValidationMessageStatus,
} from '~/components/patterns/messages/embedded/ValidationMessage';

import styles from './TextListField.module.scss';

import useFieldStatus from '~/hooks/useFieldStatus';
import useFormContext from '~/hooks/useFormContext';



type Props = {
	instruction?: React.ReactNode,
	/** Disabled state of component */
	isDisabled?: boolean,
	/** Name of HTML field */
	name: string,
	placeholder?: string,
	size?: {
		height: React.CSSProperties['height'],
		width: React.CSSProperties['width'],
	} | {
		rows: number,
		width: React.CSSProperties['width'],
	},
};

const TextListField: React.FC<Props> = (props) => {
	const {
		instruction,
		isDisabled,
		name,
		placeholder,
		size = null,
	} = props;

	const fieldStatus = useFieldStatus({
		allowError: true,
		allowOk: true,
		name,
		showErrorMessageOnFocus: false,
	});

	const formContext = useFormContext();

	const formContextOnMountHandler = formContext.onMountHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	React.useEffect(
		() => {
			formContextOnMountHandler(
				name,
				{
					setValues: true,
				},
			);

			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			name,
		],
	);

	const rows = (size !== null && 'rows' in size) ? size.rows : undefined;
	const style: React.CSSProperties = {};
	const textareaStyle: React.CSSProperties = {};

	if (size !== null && 'width' in size) {
		style.width = size.width;
	}

	if (size !== null && 'height' in size) {
		textareaStyle.height = size.height;
	}

	let validationMessageStatus = ValidationMessageStatus.Invalid;

	if (fieldStatus.showOk) {
		validationMessageStatus = ValidationMessageStatus.Valid;
	}

	const showError = !!(fieldStatus.showError && fieldStatus.errorMessage);

	return (
		<div
			className={classNames({
				[styles.component]: true,
				[styles.isDisabled]: isDisabled,
				[styles.hasValidationMessage]: !!(fieldStatus.showError && fieldStatus.errorMessage),
			})}
			style={style}
		>
			<textarea
				className={styles.textArea}
				defaultValue={formContext.defaultValues[name]}
				disabled={isDisabled}
				name={name}
				onBlur={() => {
					if (!isDisabled) {
						formContext.onBlurHandler(name);
					}
				}}
				onChange={!isDisabled ? (event) => {
					const value = event.target.value;

					formContext.onChangeHandler(name, value, {
						timeout: 250,
					});
				} : undefined}
				onFocus={() => {
					if (!isDisabled) {
						formContext.onFocusHandler(name);
					}
				}}
				placeholder={placeholder}
				rows={rows}
				style={textareaStyle}
			/>
			{(showError || fieldStatus.showOk) && (
				<div className={styles.validationMessage}>
					<ValidationMessage
						explanatoryTooltip={showError ? instruction : undefined}
						status={validationMessageStatus}
					>
						{fieldStatus.errorMessage}
					</ValidationMessage>
				</div>
			)}
		</div>
	);
};



export default TextListField;
