/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateAlertDefinitionsRecipientsMutationVariables = GraphQL.Exact<{
  addedRecipients: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  alertDefinitionIds: ReadonlyArray<GraphQL.Scalars['AlertDefinitionId']['input']> | GraphQL.Scalars['AlertDefinitionId']['input'];
  removedRecipients: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type BulkUpdateAlertDefinitionsRecipientsMutation = { readonly __typename?: 'Mutation', readonly BulkUpdateAlertDefinitionsRecipients: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly alertDefinitions: ReadonlyArray<{ readonly __typename?: 'AlertDefinition', readonly alertType: string, readonly id: CK.AlertDefinitionId, readonly recipients: ReadonlyArray<string>, readonly scope: string, readonly settings: Record<string, any>, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType }> }> } | null } } };


export const BulkUpdateAlertDefinitionsRecipientsDocument = gql`
    mutation BulkUpdateAlertDefinitionsRecipients($addedRecipients: [String!]!, $alertDefinitionIds: [AlertDefinitionId!]!, $removedRecipients: [String!]!, $websiteId: WebsiteId!) {
  BulkUpdateAlertDefinitionsRecipients(
    addedRecipients: $addedRecipients
    alertDefinitionIds: $alertDefinitionIds
    removedRecipients: $removedRecipients
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        alertDefinitions {
          alertType
          id
          messagingAppChannels {
            id
            label
            messagingAppType
          }
          recipients
          scope
          settings
        }
        id
      }
    }
  }
}
    `;
export type BulkUpdateAlertDefinitionsRecipientsMutationFn = Apollo.MutationFunction<BulkUpdateAlertDefinitionsRecipientsMutation, BulkUpdateAlertDefinitionsRecipientsMutationVariables>;

/**
 * __useBulkUpdateAlertDefinitionsRecipientsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateAlertDefinitionsRecipientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAlertDefinitionsRecipientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateAlertDefinitionsRecipientsMutation, { data, loading, error }] = useBulkUpdateAlertDefinitionsRecipientsMutation({
 *   variables: {
 *      addedRecipients: // value for 'addedRecipients'
 *      alertDefinitionIds: // value for 'alertDefinitionIds'
 *      removedRecipients: // value for 'removedRecipients'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useBulkUpdateAlertDefinitionsRecipientsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateAlertDefinitionsRecipientsMutation, BulkUpdateAlertDefinitionsRecipientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateAlertDefinitionsRecipientsMutation, BulkUpdateAlertDefinitionsRecipientsMutationVariables>(BulkUpdateAlertDefinitionsRecipientsDocument, options);
      }
export type BulkUpdateAlertDefinitionsRecipientsMutationHookResult = ReturnType<typeof useBulkUpdateAlertDefinitionsRecipientsMutation>;
export type BulkUpdateAlertDefinitionsRecipientsMutationResult = Apollo.MutationResult<BulkUpdateAlertDefinitionsRecipientsMutation>;
export type BulkUpdateAlertDefinitionsRecipientsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateAlertDefinitionsRecipientsMutation, BulkUpdateAlertDefinitionsRecipientsMutationVariables>;