/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TwitterCardsBlockPartPrimaryQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type TwitterCardsBlockPartPrimaryQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly isPrioritized: boolean, readonly lastCheckedAt: CK.Timestamp | null, readonly pageType: GraphQL.PageType, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly primaryProperties: { readonly __typename?: 'OnPageProperties', readonly twitterCard: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterDescription: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterImage: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterSite: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterTitle: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> } } } | null } | null };

export type TwitterCardsBlockPartSrcQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type TwitterCardsBlockPartSrcQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly isPrioritized: boolean, readonly lastCheckedAt: CK.Timestamp | null, readonly pageType: GraphQL.PageType, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly srcProperties: { readonly __typename?: 'OnPageProperties', readonly twitterCard: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterDescription: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterImage: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterSite: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterTitle: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> } } } | null } | null };

export type TwitterCardPrimaryPropertiesFragmentFragment = { readonly __typename?: 'OnPageProperties', readonly twitterCard: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterDescription: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterImage: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterSite: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> }, readonly twitterTitle: { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> } };

export type ContentItemsCollectionFragmentFragment = { readonly __typename?: 'ContentItemsCollection', readonly isExpectedToBeNonEmpty: boolean, readonly type: GraphQL.ContentType, readonly contentItems: ReadonlyArray<{ readonly __typename?: 'ContentItem', readonly content: string, readonly position: number }> };

export const ContentItemsCollectionFragmentFragmentDoc = gql`
    fragment ContentItemsCollectionFragment on ContentItemsCollection {
  contentItems {
    content
    position
  }
  isExpectedToBeNonEmpty
  type
}
    `;
export const TwitterCardPrimaryPropertiesFragmentFragmentDoc = gql`
    fragment TwitterCardPrimaryPropertiesFragment on OnPageProperties {
  twitterCard {
    ...ContentItemsCollectionFragment
  }
  twitterDescription {
    ...ContentItemsCollectionFragment
  }
  twitterImage {
    ...ContentItemsCollectionFragment
  }
  twitterSite {
    ...ContentItemsCollectionFragment
  }
  twitterTitle {
    ...ContentItemsCollectionFragment
  }
}
    ${ContentItemsCollectionFragmentFragmentDoc}`;
export const TwitterCardsBlockPartPrimaryDocument = gql`
    query TwitterCardsBlockPartPrimary($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    isPrioritized
    lastCheckedAt
    pageType
    pageTypeData {
      primaryProperties {
        ...TwitterCardPrimaryPropertiesFragment
      }
    }
  }
}
    ${TwitterCardPrimaryPropertiesFragmentFragmentDoc}`;

/**
 * __useTwitterCardsBlockPartPrimaryQuery__
 *
 * To run a query within a React component, call `useTwitterCardsBlockPartPrimaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwitterCardsBlockPartPrimaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwitterCardsBlockPartPrimaryQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useTwitterCardsBlockPartPrimaryQuery(baseOptions: Apollo.QueryHookOptions<TwitterCardsBlockPartPrimaryQuery, TwitterCardsBlockPartPrimaryQueryVariables> & ({ variables: TwitterCardsBlockPartPrimaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TwitterCardsBlockPartPrimaryQuery, TwitterCardsBlockPartPrimaryQueryVariables>(TwitterCardsBlockPartPrimaryDocument, options);
      }
export function useTwitterCardsBlockPartPrimaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TwitterCardsBlockPartPrimaryQuery, TwitterCardsBlockPartPrimaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TwitterCardsBlockPartPrimaryQuery, TwitterCardsBlockPartPrimaryQueryVariables>(TwitterCardsBlockPartPrimaryDocument, options);
        }
export function useTwitterCardsBlockPartPrimarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TwitterCardsBlockPartPrimaryQuery, TwitterCardsBlockPartPrimaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TwitterCardsBlockPartPrimaryQuery, TwitterCardsBlockPartPrimaryQueryVariables>(TwitterCardsBlockPartPrimaryDocument, options);
        }
export type TwitterCardsBlockPartPrimaryQueryHookResult = ReturnType<typeof useTwitterCardsBlockPartPrimaryQuery>;
export type TwitterCardsBlockPartPrimaryLazyQueryHookResult = ReturnType<typeof useTwitterCardsBlockPartPrimaryLazyQuery>;
export type TwitterCardsBlockPartPrimarySuspenseQueryHookResult = ReturnType<typeof useTwitterCardsBlockPartPrimarySuspenseQuery>;
export type TwitterCardsBlockPartPrimaryQueryResult = Apollo.QueryResult<TwitterCardsBlockPartPrimaryQuery, TwitterCardsBlockPartPrimaryQueryVariables>;
export const TwitterCardsBlockPartSrcDocument = gql`
    query TwitterCardsBlockPartSrc($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    isPrioritized
    lastCheckedAt
    pageType
    pageTypeData {
      srcProperties {
        ...TwitterCardPrimaryPropertiesFragment
      }
    }
  }
}
    ${TwitterCardPrimaryPropertiesFragmentFragmentDoc}`;

/**
 * __useTwitterCardsBlockPartSrcQuery__
 *
 * To run a query within a React component, call `useTwitterCardsBlockPartSrcQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwitterCardsBlockPartSrcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwitterCardsBlockPartSrcQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useTwitterCardsBlockPartSrcQuery(baseOptions: Apollo.QueryHookOptions<TwitterCardsBlockPartSrcQuery, TwitterCardsBlockPartSrcQueryVariables> & ({ variables: TwitterCardsBlockPartSrcQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TwitterCardsBlockPartSrcQuery, TwitterCardsBlockPartSrcQueryVariables>(TwitterCardsBlockPartSrcDocument, options);
      }
export function useTwitterCardsBlockPartSrcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TwitterCardsBlockPartSrcQuery, TwitterCardsBlockPartSrcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TwitterCardsBlockPartSrcQuery, TwitterCardsBlockPartSrcQueryVariables>(TwitterCardsBlockPartSrcDocument, options);
        }
export function useTwitterCardsBlockPartSrcSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TwitterCardsBlockPartSrcQuery, TwitterCardsBlockPartSrcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TwitterCardsBlockPartSrcQuery, TwitterCardsBlockPartSrcQueryVariables>(TwitterCardsBlockPartSrcDocument, options);
        }
export type TwitterCardsBlockPartSrcQueryHookResult = ReturnType<typeof useTwitterCardsBlockPartSrcQuery>;
export type TwitterCardsBlockPartSrcLazyQueryHookResult = ReturnType<typeof useTwitterCardsBlockPartSrcLazyQuery>;
export type TwitterCardsBlockPartSrcSuspenseQueryHookResult = ReturnType<typeof useTwitterCardsBlockPartSrcSuspenseQuery>;
export type TwitterCardsBlockPartSrcQueryResult = Apollo.QueryResult<TwitterCardsBlockPartSrcQuery, TwitterCardsBlockPartSrcQueryVariables>;