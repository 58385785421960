import type CK from '~/types/contentking';

import {
	useAccountMaximumRegularSignupPageBundleQuery,
} from './useAccountMaximumRegularSignupPageBundle.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountMaximumRegularSignupPageBundle(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountMaximumRegularSignupPageBundleQuery,
		accountId,
	);

	return data?.account?.maximumRegularSignupPageBundle ?? null;
}



export default useAccountMaximumRegularSignupPageBundle;
