import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	CardNumberElement,
	IbanElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AccountStripeContext from '~/components/app/Stripe/AccountStripeContext';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import ChoosePaymentMethodFields, {
	validateCardFields,
	validatePaymentMethod,
	validateSepaFields,
} from '~/components/app/ChoosePaymentMethodFields';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import PaymentFailureFormError from '~/components/app/PaymentFailureFormError';
import PaymentMethodOverview from '~/components/app/PaymentMethodOverview';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';

import {
	useUpdatePaymentMethodInBillingTabMutation,
} from './PaymentMethodBlock.gql';

import useEffectiveAllowedPaymentMethods from '~/hooks/useEffectiveAllowedPaymentMethods';
import useInitiateSepaDirectDebitSetup from '~/hooks/useInitiateSepaDirectDebitSetup';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useLoadPaymentStatus from '~/hooks/useLoadPaymentStatus';

import {
	METHOD_CARD,
	METHOD_SEPA_DEBIT,
} from '~/model/payments';

import FormError from '~/utilities/FormError';
import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	formErrorsStripeError: {
		id: 'ui.general.unknownError',
	},
	paymentMethodTitle: {
		id: 'ui.teamDetail.billing.paymentMethodTitle',
	},
});

const validations = {
	...validateCardFields,
	validatePaymentMethod,
	...validateSepaFields,
};



type Props = {
	accountId: CK.AccountId,
	country: string | null,
	emails: Readonly<Array<string>> | null,
};

const PaymentMethodBlock: React.FC<Props> = (props) => {
	const {
		accountId,
		country,
		emails,
	} = props;

	const elements = useElements();
	const initiateSepaDirectDebitSetup = useInitiateSepaDirectDebitSetup();
	const loadPaymentStatus = useLoadPaymentStatus();
	const stripe = useStripe();

	const isAllowedToManageBilling = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageBilling,
	);

	const paymentMethods = useEffectiveAllowedPaymentMethods(
		accountId,
		country ?? '',
	);

	const [updatePaymentMethod] = useUpdatePaymentMethodInBillingTabMutation();

	const handleSuccess = React.useCallback(
		async (values, { createError }) => {
			if (elements === null || stripe === null) {
				return;
			}

			const paymentMethodDetails: Record<string, any> = {};

			if (values.paymentMethod === METHOD_CARD) {
				const card = elements.getElement(CardNumberElement);

				if (card === null) {
					throw FormError.fromUnknownError(
						new Error(`Card element can't be accessed`),
					);
				}

				const cardInput = {
					card,
					billing_details: {
						name: values.cardHolder,
					},
				};

				const paymentStatus = await loadPaymentStatus(accountId);

				const clientSecret = paymentStatus?.stripeAction?.clientSecret ?? null;

				if (clientSecret !== null) {
					const stripeAction = await stripe.confirmCardPayment(clientSecret, {
						payment_method: cardInput,
						setup_future_usage: 'off_session',
					});

					if (stripeAction.error !== undefined) {
						if (stripeAction.error.code === 'card_declined') {
							return Promise.reject(
								createError('invalidPaymentMethod', {
									details: stripeAction.error.decline_code,
								}),
							);
						}

						throw FormError.fromStripeError(stripeAction.error);
					}

					paymentMethodDetails.paymentMethodId = stripeAction.paymentIntent.payment_method;
				} else {
					const stripeAction = await stripe.createPaymentMethod({
						type: 'card',
						...cardInput,
					});

					if (stripeAction.error !== undefined) {
						throw FormError.fromStripeError(stripeAction.error);
					}

					paymentMethodDetails.paymentMethodId = stripeAction.paymentMethod.id;
				}
			} else if (values.paymentMethod === METHOD_SEPA_DEBIT) {
				const sepaDebit = elements.getElement(IbanElement);

				if (emails === null) {
					throw FormError.fromUnknownError(
						new Error(`Email isn't loaded yet for storing Sepa payment method`),
					);
				}

				if (sepaDebit === null) {
					throw FormError.fromUnknownError(
						new Error(`Sepa element can't be accessed`),
					);
				}

				const clientSecret = await initiateSepaDirectDebitSetup(accountId);

				if (clientSecret === null) {
					throw FormError.fromUnknownError(
						new Error(`Sepa setup intent client secret can't be generated`),
					);
				}

				const sepaDebitInput = {
					sepa_debit: sepaDebit,
					billing_details: {
						email: getArrayItemAtSafeIndex(emails, 0),
						name: values.sepaName,
					},
				};

				const stripeAction = await stripe.confirmSepaDebitSetup(clientSecret, {
					payment_method: sepaDebitInput,
				});

				if (stripeAction.error !== undefined) {
					throw FormError.fromStripeError(stripeAction.error);
				}

				if (stripeAction.setupIntent.payment_method === null) {
					throw FormError.fromUnknownError(
						new Error(`stripe.confirmSepaDebitSetup didn't produce error, but setupIntent.payment_method is null`),
					);
				}

				paymentMethodDetails.paymentMethodId = stripeAction.setupIntent.payment_method;
			}

			await updatePaymentMethod({
				variables: {
					accountId,
					paymentMethod: {
						type: values.paymentMethod,
						details: paymentMethodDetails,
					},
				},
			});
		},
		[
			accountId,
			elements,
			emails,
			initiateSepaDirectDebitSetup,
			loadPaymentStatus,
			stripe,
			updatePaymentMethod,
		],
	);

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageBilling}
			key={accountId}
			title={(
				<FormattedMessage {...messages.paymentMethodTitle} />
			)}
		>
			<DisplayPart>
				<PaymentMethodOverview
					accountId={accountId}
				/>
			</DisplayPart>

			<EditablePart>
				{paymentMethods !== null && (
					<Form
						clearOnFieldUnmount={true}
						defaultValues={{
							paymentMethod: paymentMethods.length > 1 ? '' : paymentMethods[0],
						}}
						onSuccess={handleSuccess}
						validations={validations}
					>
						<ChoosePaymentMethodFields
							paymentMethods={paymentMethods}
						/>

						<PaymentFailureFormError
							errorCode="invalidPaymentMethod"
						/>

						<FormErrorMessages
							errors={{
								stripeError: (
									<FormattedMessage {...messages.formErrorsStripeError} />
								),
							}}
						/>

						<ButtonsLayout>
							<CancelButton />

							<SaveSubmitButton />
						</ButtonsLayout>
					</Form>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};

const PaymentMethodBlockWrapper: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	return (
		<AccountStripeContext accountId={accountId}>
			<PaymentMethodBlock {...props} />
		</AccountStripeContext>
	);
};



export default PaymentMethodBlockWrapper;
