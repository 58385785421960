import React from 'react';



type Props = {
	from?: React.ReactNode,
	to?: React.ReactNode,
};



const ValuesRange: React.FC<Props> = (props) => {
	const {
		from,
		to,
	} = props;

	return (
		<div className="values-range">
			{from && (
				<span className="values-range__value">{from}</span>
			)}
			{from && to && (
				<span className="values-range__divider">&mdash;</span>
			)}
			{to && (
				<span className="values-range__value">{to}</span>
			)}
		</div>
	);
};



export default ValuesRange;
