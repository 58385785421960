export enum UserAgent {
	Automatic = 'automatic',
	Baiduspider = 'baiduspider',
	BingbotDesktop = 'bingbot_desktop',
	BingbotMobile = 'bingbot_mobile',
	ContentKing = 'contentking',
	Custom = 'custom',
	DuckDuckGo = 'duckduckgo',
	GooglebotDesktop = 'googlebot_desktop',
	GooglebotMobile = 'googlebot_mobile',
	GooglechromeDesktop = 'googlechrome_desktop',
	GooglechromeMobile = 'googlechrome_mobile',
	ScreamingFrog = 'screamingfrog',
	Seznambot = 'seznambot',
	Slurp = 'slurp',
	Yandexbot = 'yandexbot',
}
