import Immutable from 'immutable';

import {
	CHANGE_URL_STATE,
} from '~/actions';

import {
	selectedWebsiteIdSelector,
} from './ui/selectors';

import {
	coverSpan,
	createCoverage,
	getPatch,
} from '~/utilities/coverage';



function createDefaultState() {
	return Immutable.Map({
		data: Immutable.Map(),
		websiteId: null,
	});
}



function createDefaultWebsite(websiteId) {
	return Immutable.Map({
		coverage: Immutable.Map(),
		keys: Immutable.Map(),
		websiteId,
	});
}



export function createState(name, loadFunction, ttl = null) {
	const STORE_ACTION = name + '/store';



	const currentWebsiteDataSelector = (state) => state.get(name).get('data').get(selectedWebsiteIdSelector(state));



	const loadData = (key, parameters) => {
		return (dispatch, getState) => {
			const websiteId = selectedWebsiteIdSelector(getState());

			if (!websiteId) {
				return Promise.resolve();
			}

			const currentData = currentWebsiteDataSelector(getState());

			const patch = getPatch(
				currentData ? currentData.getIn(['coverage', key], createCoverage()) : createCoverage(),
				0,
				1,
			);

			if (patch.coverSpan === 0) {
				return Promise.resolve();
			}

			return loadFunction({ key, parameters, websiteId })
				.then(
					(data) => {
						dispatch({
							data,
							key,
							type: STORE_ACTION,
							websiteId,
						});
					},
				);
		};
	};



	const reducer = (state, action) => {
		if (state === undefined) {
			state = createDefaultState();
		}

		switch (action.type) {

			case CHANGE_URL_STATE: {
				const {
					urlState,
				} = action;

				if (
					urlState.params.websiteId
					&& !state.get('data').has(urlState.params.websiteId)
				) {
					state = state.setIn(
						['data', urlState.params.websiteId],
						createDefaultWebsite(urlState.params.websiteId),
					);
				}

				break;
			}

			case STORE_ACTION: {
				const {
					data,
					key,
					websiteId,
				} = action;

				state = state.setIn(
					['data', websiteId, 'keys', key],
					data,
				);

				state = state.setIn(
					['data', websiteId, 'coverage', key],
					coverSpan(
						state.getIn(['data', websiteId, 'coverage', key], createCoverage()),
						0,
						1,
						ttl !== null ? (Date.now() / 1000 + ttl) : Infinity,
					),
				);

				break;
			}

		}

		return state;
	};



	return {
		loadData,
		reducer,
		selector: (state) => currentWebsiteDataSelector(state).get('keys'),
	};
}
