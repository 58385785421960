import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Possibility to control whether CTA element is open or closed */
	isOpen?: boolean,
};



const ColumnsCTALayout: React.FC<Props> = (props) => {
	const {
		children,
		isOpen = true,
	} = props;

	const componentClasses = classNames({
		'columns-cta': true,
		'columns-cta--open': isOpen,
	});

	return (
		<div
			className={componentClasses}
			style={{
				height: isOpen === false ? 0 : undefined,
			}}
		>
			<div className="columns-cta__content">
				{children}
			</div>
		</div>
	);
};



export default ColumnsCTALayout;
