import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const LogFileAnalysisIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M8.13379 7.58276H11.2513"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M8.13379 11.1152H9.83129"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M8.13379 14.6477H9.83129"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M8.13379 18.1804H11.9863"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M8.13379 21.7126H19.5913"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M22.5712 3.59518C22.3925 3.24483 22.1206 2.95065 21.7853 2.74508C21.45 2.5395 21.0645 2.43053 20.6712 2.43018H3.05371"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M22.8037 24.7503V20.6528"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M6.69559 29.2951L24.6356 29.2501C24.8766 29.2505 25.1152 29.2033 25.338 29.1114C25.5608 29.0195 25.7632 28.8846 25.9339 28.7144C26.1045 28.5443 26.2399 28.3422 26.3324 28.1197C26.425 27.8972 26.4728 27.6586 26.4731 27.4176V24.7501L8.53309 24.7851V27.4726C8.53176 27.9593 8.33767 28.4257 7.99328 28.7696C7.6489 29.1135 7.18229 29.307 6.69559 29.3076V29.3076C6.20935 29.307 5.74325 29.1133 5.39966 28.7693C5.05607 28.4252 4.86309 27.9589 4.86309 27.4726L4.89559 4.27513C4.89492 3.78932 4.70165 3.3236 4.35813 2.98008C4.01461 2.63657 3.54889 2.44329 3.06309 2.44263V2.44263C2.57897 2.44326 2.1146 2.63466 1.77064 2.97534C1.42668 3.31602 1.23085 3.77854 1.22559 4.26263V12.4726H4.88559"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M19.195 19.3275C23.2722 19.3275 26.5775 16.0222 26.5775 11.945C26.5775 7.86776 23.2722 4.5625 19.195 4.5625C15.1178 4.5625 11.8125 7.86776 11.8125 11.945C11.8125 16.0222 15.1178 19.3275 19.195 19.3275Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M27.0425 17.4075L30.6425 21C30.7998 21.1572 30.9247 21.3438 31.0099 21.5493C31.0951 21.7548 31.1389 21.975 31.1389 22.1975C31.1389 22.4199 31.0951 22.6402 31.0099 22.8456C30.9247 23.0511 30.7998 23.2378 30.6425 23.395V23.395C30.3251 23.7121 29.8948 23.8902 29.4462 23.8902C28.9976 23.8902 28.5673 23.7121 28.25 23.395L24.6475 19.8L27.0425 17.4075Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M19.195 16.7025C21.8225 16.7025 23.9525 14.5725 23.9525 11.945C23.9525 9.3175 21.8225 7.1875 19.195 7.1875C16.5675 7.1875 14.4375 9.3175 14.4375 11.945C14.4375 14.5725 16.5675 16.7025 19.195 16.7025Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M24.415 17.1675L25.8 18.5525"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M16.8477 11.9452C16.8483 11.3228 17.0959 10.7261 17.536 10.286C17.9761 9.84586 18.5728 9.59832 19.1952 9.59766"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default LogFileAnalysisIconBuilder;

