import type CK from '~/types/contentking';

import {
	useWebsiteIsLogFileAnalysisEnabledQuery,
} from './useWebsiteIsLogFileAnalysisEnabled.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteIsLogFileAnalysisEnabled(websiteId: CK.WebsiteId | null): boolean | null {
	const { data } = useWebsiteIsLogFileAnalysisEnabledQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.isLogFileAnalysisEnabled) ?? null;
}



export default useWebsiteIsLogFileAnalysisEnabled;
