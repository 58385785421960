import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import {
	Map,
} from 'immutable';

import DatatableBodyCell, {
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableLayout from '~/components/patterns/tables/datatables/DatatableLayout';
import FixedHeaderGrid from '~/components/patterns/tables/datatables/FixedHeaderGrid';
import InternalLink from '~/components/patterns/links/InternalLink';
import {
	renderEllipsisCell,
} from '../../atoms/dataTables/utils/ReactVirtualizedCells';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';
import DatatableHeaderCell, {
	DatatableHeaderCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';

import {
	loadUniqueElementContentIssueUrls,
} from '~/actions/pages/detail';

import {
	selectedPageIdSelector,
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';

import {
	duplicateContentUrlsSelector,
} from '~/state/duplicateContentUrls/selectors';



const messages = defineMessages({
	pagesWithSame: {
		h1: {
			id: 'issues.unique_element_content_h1.pagesWithSameH1',
		},
		meta_description: {
			id: 'issues.unique_element_content_metaDescription.pagesWithSameMetaDescription',
		},
		title: {
			id: 'issues.unique_element_content_title.pagesWithSameTitle',
		},
	},
});



const HEADER_HEIGHT = 32;
const ROW_HEIGHT = 40;
const TABLE_HEIGHT = 220;
const LOAD_LIMIT = 100;
const ELLIPSIS_POPUP_ZINDEX = 2000;

const columnWidths = {
	url: 580,
};

const elementTypeSelector = (state, props) => {
	return props.elementType;
};

const duplicateUrlsSelector = createSelector(
	duplicateContentUrlsSelector,
	elementTypeSelector,
	selectedPageIdSelector,
	(duplicateUrls, elementType, pageId) => {
		return duplicateUrls
			.get(pageId, new Map())
			.get('unique_element_content_' + elementType, new Map());
	},
);

const select = createSelector(
	duplicateUrlsSelector,
	selectedPageIdSelector,
	selectedWebsiteIdSelector,
	(duplicateUrls, pageId, websiteId) => {
		return {
			duplicateUrls,
			pageId,
			websiteId,
		};
	},
);



class PagesWithSameDuplicateContent extends Component {

	constructor(props, context) {
		super(props, context);

		this.loadTimeout = null;
		this.loadingOffset = 0;
		this._boundGetColumnWidth = this._getColumnWidth.bind(this);
		this._boundRenderCell = this._renderCell.bind(this);
		this._boundRenderHeader = this._renderHeader.bind(this);
		this._boundOnSectionRenderedCallback = this._onSectionRenderedCallback.bind(this);
	}



	componentDidMount() {
		const { dispatch } = this.props;
		const {
			pageId,
			elementType,
		} = this.props;

		dispatch(loadUniqueElementContentIssueUrls(
			pageId,
			'unique_element_content_' + elementType,
			0,
			LOAD_LIMIT,
		)).then(() => {
			this._rerender();
		});
	}



	_getColumnWidth({ index }) {
		const {
			tableWidth,
		} = this.props;

		switch (index) {

			case 0:
				return tableWidth < columnWidths.url ? columnWidths.url : (tableWidth - 2);

		}
	}



	_rerender() {
		this.refs.Grid?.refs.BodyGrid?.forceUpdate();
	}



	_renderHeader({ columnIndex }) {
		const {
			elementType,
		} = this.props;

		let label;

		switch (columnIndex) {

			case 0: {
				label = (
					<FormattedMessage {...messages.pagesWithSame[elementType]} />
				);
				break;
			}

		}

		return (
			<DatatableHeaderCell
				key={'header_' + columnIndex}
				size={DatatableHeaderCellSize.Small}
				width={this._getColumnWidth({ index: columnIndex })}
			>
				<TableLabel label={label} />
			</DatatableHeaderCell>
		);
	}



	_renderCell({ columnIndex, key, rowIndex, style }) {
		let cell;

		switch (columnIndex) {

			case 0:
				cell = this._renderUrlCell({ rowIndex });
				break;

		}

		return (
			<DatatableBodyCell
				cssStyle={style}
				key={key}
				rowIndex={rowIndex}
				size={DatatableBodyCellSize.Small}
			>
				{cell}
			</DatatableBodyCell>
		);
	}



	_renderUrlCell({ rowIndex }) {
		const {
			duplicateUrls,
			websiteId,
		} = this.props;

		const url = duplicateUrls.get(rowIndex);

		if (!url) {
			return (
				<SquareSkeleton
					rowIndex={rowIndex}
					style={SquareSkeletonStyle.Light}
				/>
			);
		}

		const displayUrl = (
			<InternalLink
				key={url.get('id')}
				routeName="website.pages.detail"
				routeParams={{
					websiteId,
					id: url.get('id'),
				}}
			>
				{url.get('url')}
			</InternalLink>
		);

		return renderEllipsisCell(
			displayUrl,
			ELLIPSIS_POPUP_ZINDEX,
		);
	}



	_calculateTableHeight() {
		const {
			urlsCount,
		} = this.props;

		const calculated = ROW_HEIGHT * urlsCount;

		if (TABLE_HEIGHT > calculated) {
			return calculated + HEADER_HEIGHT + scrollbarSize();
		}

		return TABLE_HEIGHT + HEADER_HEIGHT + scrollbarSize();
	}



	_onSectionRenderedCallback({ rowStartIndex }) {
		const { dispatch } = this.props;
		const {
			pageId,
			elementType,
		} = this.props;

		if (rowStartIndex > (this.loadingOffset + 20) || (rowStartIndex < this.loadingOffset - 20)) {
			if (this.loadTimeout) {
				clearTimeout(this.loadTimeout);
			}

			this.loadTimeout = setTimeout(() => {
				this.loadingOffset = Math.max(0, rowStartIndex);
				dispatch(loadUniqueElementContentIssueUrls(
					pageId,
					'unique_element_content_' + elementType,
					this.loadingOffset,
					LOAD_LIMIT,
				)).then(() => {
					this._rerender();
				});
			}, 500);
		}
	}



	render() {
		const {
			tableWidth,
			urlsCount,
		} = this.props;

		const tableHeight = this._calculateTableHeight();

		// -2 is because of table border around this component
		const tableWidthOffset = -2;

		let calculatedTableHeight = tableHeight;

		if (this._getColumnWidth({ index: 0 }) <= (tableWidth + tableWidthOffset)) {
			calculatedTableHeight -= scrollbarSize();
		}

		return (
			<DatatableLayout>
				<FixedHeaderGrid
					bodyCellRenderer={this._boundRenderCell}
					columnCount={1}
					columnWidth={this._boundGetColumnWidth}
					headerCellRenderer={this._boundRenderHeader}
					headerHeight={HEADER_HEIGHT}
					height={calculatedTableHeight}
					onSectionRendered={this._boundOnSectionRenderedCallback}
					overscanColumnCount={1}
					ref="Grid"
					rowCount={urlsCount}
					rowHeight={ROW_HEIGHT}
					width={tableWidth + tableWidthOffset}
				/>
			</DatatableLayout>
		);
	}

}

PagesWithSameDuplicateContent.propTypes = {
	pageId: PropTypes.string.isRequired,
	elementType: PropTypes.any,
	tableWidth: PropTypes.number.isRequired,
	urlsCount: PropTypes.number.isRequired,
	websiteId: PropTypes.string.isRequired,
};



export default connect(select)(PagesWithSameDuplicateContent);
