import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import List from '~/components/patterns/lists/List';
import StatusFlag, {
	StatusFlagSize,
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';



export const STATE_BROKEN = 'broken';
export const STATE_CLOSED = 'closed';
export const STATE_IGNORED = 'ignored';
export const STATE_NOT_REQUIRED = 'not-required';
export const STATE_UNKNOWN = 'unknown';

class IssueLayout extends Component {

	_renderIcon() {
		const {
			state,
		} = this.props;

		let iconStatus;

		if (state === STATE_BROKEN) {
			iconStatus = StatusFlagStatus.Critical;
		}

		if (state === STATE_CLOSED) {
			iconStatus = StatusFlagStatus.Success;
		}

		if (state === STATE_IGNORED) {
			iconStatus = StatusFlagStatus.Disabled;
		}

		if (state === STATE_NOT_REQUIRED) {
			iconStatus = StatusFlagStatus.NotRequired;
		}

		if (state === STATE_UNKNOWN) {
			iconStatus = StatusFlagStatus.Indefinite;
		}

		if (!iconStatus) {
			return false;
		}

		return (
			<div className="issue-detail__header-icon">
				<StatusFlag
					size={StatusFlagSize.Medium}
					status={iconStatus}
				/>
			</div>
		);
	}



	render() {
		const {
			actionElements,
			children,
			ignoreLink,
			onMouseEnterCallback,
			onMouseLeaveCallback,
			message,
			state,
			title,
		} = this.props;

		const componentClasses = classNames({
			'issue-detail': true,
			[ 'issue-detail--is-' + state ]: true,
		});

		const headerClasses = classNames({
			'issue-detail__header': true,
			'issue-detail__header--column-on-small': ignoreLink,
		});

		return (
			<div
				className={componentClasses}
				onMouseEnter={onMouseEnterCallback}
				onMouseLeave={onMouseLeaveCallback}
			>
				{message && (
					<div className="issue-detail__message">
						{message}
					</div>
				)}

				<div className={headerClasses}>
					<div className="issue-detail__header-content">
						{this._renderIcon()}
						<h3 className="issue-detail__title">
							{title}
						</h3>
					</div>
					{(ignoreLink || actionElements) && (
						<div className="issue-detail__additional-info">
							{ignoreLink && (
								<div className="issue-detail__ignore-link">
									<div className="issue-detail__ignore-link-container">
										{ignoreLink}
									</div>
								</div>
							)}
							{actionElements && (
								<div className="issue-detail__action-elements">
									{actionElements}
								</div>
							)}
						</div>
					)}
				</div>
				<div className="issue-detail__description">
					<List>
						{children}
					</List>
				</div>
			</div>
		);
	}

}

IssueLayout.defaultProps = {
	state: STATE_BROKEN,
};

IssueLayout.propTypes = {
	/** Action elements of issue */
	actionElements: PropTypes.node,
	/** Possible ignore link */
	ignoreLink: PropTypes.node,
	/** on mouse hover callback */
	onMouseEnterCallback: PropTypes.func,
	/** on mouse leave callback */
	onMouseLeaveCallback: PropTypes.func,
	/** Possible message */
	message: PropTypes.node,
	/** Possible state of issue */
	state: PropTypes.oneOf([
		STATE_BROKEN,
		STATE_CLOSED,
		STATE_IGNORED,
		STATE_NOT_REQUIRED,
		STATE_UNKNOWN,
	]).isRequired,
	/** Issue title */
	title: PropTypes.node.isRequired,
};



export default IssueLayout;
