import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ButtonNumericValue from '~/components/patterns/buttons/parts/ButtonNumericValue';

import matchAndReturn from '~/utilities/matchAndReturn';



export enum AffectedButtonScope {
	Links = 'links',
	Pages = 'pages',
}

export enum AffectedButtonSize {
	Medium,
	XSmall,
}

const scopeLabelMessages = defineMessages({
	[AffectedButtonScope.Links]: {
		id: 'ui.issueDetail.linksAffected.label',
	},
	[AffectedButtonScope.Pages]: {
		id: 'ui.issueDetail.pagesAffected.label',
	},
});



type Props = {
	count: number,
	onClickCallback: () => void,
	percentage?: number,
	scope: AffectedButtonScope,
	size: AffectedButtonSize,
};

const AffectedButton: React.FC<Props> = (props) => {
	const {
		count,
		onClickCallback,
		percentage,
		scope,
		size,
	} = props;

	const handleClick = React.useCallback(
		(e) => {
			e.preventDefault();

			onClickCallback();
		},
		[
			onClickCallback,
		],
	);

	return (
		<Button
			icon={(
				<BasicIcon
					size={size === AffectedButtonSize.XSmall ? 18 : 24}
					type={matchAndReturn(scope, {
						[AffectedButtonScope.Links]: BasicIconType.Link,
						[AffectedButtonScope.Pages]: BasicIconType.Pages,
					})}
				/>
			)}
			onClick={handleClick}
			size={matchAndReturn(size, {
				[AffectedButtonSize.XSmall]: ButtonSize.XSmall,
				[AffectedButtonSize.Medium]: ButtonSize.Medium,
			})}
			style={ButtonStyle.Hollow}
			tooltip={(
				<FormattedMessage
					{...scopeLabelMessages[scope]}
					values={{
						count,
					}}
				/>
			)}
		>
			<ButtonNumericValue>
				{percentage !== undefined ? (
					<FormattedNumber
						maximumFractionDigits={0}
						minimumFractionDigits={0}
						style="percent"
						value={percentage / 100}
					/>
				) : (
					<FormattedNumber value={count} />
				)}
			</ButtonNumericValue>
		</Button>
	);
};



export default AffectedButton;
