/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DistributionOfIsIndexableWidgetQueryVariables = GraphQL.Exact<{
  date: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DistributionOfIsIndexableWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly distributionOfIsIndexable: { readonly __typename?: 'WebsiteDashboardDistributionOfIsIndexable', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: ReadonlyArray<{ readonly __typename?: 'WebsiteDashboardDistributionOfIsIndexableData', readonly percentageOfPages: CK.Percentage, readonly numberOfPages: number, readonly state: boolean }> | null } } };


export const DistributionOfIsIndexableWidgetDocument = gql`
    query DistributionOfIsIndexableWidget($date: DateYMD!, $scope: String!, $websiteId: WebsiteId!) {
  dashboardData {
    distributionOfIsIndexable(date: $date, scope: $scope, websiteId: $websiteId) {
      data {
        percentageOfPages
        numberOfPages
        state
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useDistributionOfIsIndexableWidgetQuery__
 *
 * To run a query within a React component, call `useDistributionOfIsIndexableWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributionOfIsIndexableWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributionOfIsIndexableWidgetQuery({
 *   variables: {
 *      date: // value for 'date'
 *      scope: // value for 'scope'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDistributionOfIsIndexableWidgetQuery(baseOptions: Apollo.QueryHookOptions<DistributionOfIsIndexableWidgetQuery, DistributionOfIsIndexableWidgetQueryVariables> & ({ variables: DistributionOfIsIndexableWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistributionOfIsIndexableWidgetQuery, DistributionOfIsIndexableWidgetQueryVariables>(DistributionOfIsIndexableWidgetDocument, options);
      }
export function useDistributionOfIsIndexableWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistributionOfIsIndexableWidgetQuery, DistributionOfIsIndexableWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistributionOfIsIndexableWidgetQuery, DistributionOfIsIndexableWidgetQueryVariables>(DistributionOfIsIndexableWidgetDocument, options);
        }
export function useDistributionOfIsIndexableWidgetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DistributionOfIsIndexableWidgetQuery, DistributionOfIsIndexableWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DistributionOfIsIndexableWidgetQuery, DistributionOfIsIndexableWidgetQueryVariables>(DistributionOfIsIndexableWidgetDocument, options);
        }
export type DistributionOfIsIndexableWidgetQueryHookResult = ReturnType<typeof useDistributionOfIsIndexableWidgetQuery>;
export type DistributionOfIsIndexableWidgetLazyQueryHookResult = ReturnType<typeof useDistributionOfIsIndexableWidgetLazyQuery>;
export type DistributionOfIsIndexableWidgetSuspenseQueryHookResult = ReturnType<typeof useDistributionOfIsIndexableWidgetSuspenseQuery>;
export type DistributionOfIsIndexableWidgetQueryResult = Apollo.QueryResult<DistributionOfIsIndexableWidgetQuery, DistributionOfIsIndexableWidgetQueryVariables>;