import classNames from 'classnames';
import React from 'react';



export enum StripedTableCellType {
	Body = 'body',
	Cta = 'cta',
	Header = 'header',
}

type Props = {
	children: React.ReactNode | string,
	/** Type of cell */
	type?: StripedTableCellType,
};



const StripedTableCell: React.FC<Props> = (props) => {
	const {
		children,
		type = StripedTableCellType.Body,
	} = props;

	const componentClasses = classNames({
		'striped-table-cell': true,
		[ 'striped-table-cell--' + type ]: true,
	});

	return (
		<div className={componentClasses}>
			<div className="striped-table-cell__container">
				{React.Children.map(
					children,
					(child) => {
						if (!child) {
							return false;
						}

						return (
							<div className="striped-table-cell__container-column">
								{child}
							</div>
						);
					},
				)}
			</div>
		</div>
	);
};



export default StripedTableCell;
