import React from 'react';
import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import IssueCategoriesList from '~/components/app/IssueCategoriesList';

import usePlatformIssueCategories from '~/hooks/usePlatformIssueCategories';

import {
	getCategoryUrlIdentifier,
} from '~/model/issues/identifierMapping';

import {
	navigate,
} from '~/routing/router';

import {
	platformScreenIssuesCategoryTypeSelector,
} from '~/state/ui/content/selectors';

import {
	verticalScrollTo,
} from '~/utilities/scrollTo';



type Props = {
	numberOfPlaceholders: number,
	showActionable: boolean,
	websiteId: CK.WebsiteId,
};

const PlatformIssueCategoriesList: React.FC<Props> = (props) => {
	const {
		numberOfPlaceholders,
		showActionable,
		websiteId,
	} = props;

	const openedIssueCategoryName = useSelector(platformScreenIssuesCategoryTypeSelector);
	const platformIssueCategories = usePlatformIssueCategories(websiteId);

	const handleIssueCategoryOpen = React.useCallback(
		(issueCategoryName) => {
			setTimeout(() => {
				const element = document.querySelector('.js-scrollable-overlay');

				if (element) {
					verticalScrollTo(element, 0, 600);
				}
			}, 200);

			navigate('website.platform.issuesCategory', {
				websiteId,
				platformScreenIssuesCategoryType: getCategoryUrlIdentifier(issueCategoryName),
			});
		},
		[
			websiteId,
		],
	);

	const handleIssueCategoryClose = React.useCallback(
		() => {
			navigate('website.platform', {
				websiteId,
			});
		},
		[
			websiteId,
		],
	);

	return (
		<IssueCategoriesList
			issueCategories={platformIssueCategories}
			numberOfPlaceholders={numberOfPlaceholders}
			onIssueCategoryClose={handleIssueCategoryClose}
			onIssueCategoryOpen={handleIssueCategoryOpen}
			openedIssueCategoryName={openedIssueCategoryName}
			showActionable={showActionable}
		/>
	);
};



export default PlatformIssueCategoriesList;
