import React, {
	Component,
} from 'react';
import type Immutable from 'immutable';

import type CK from '~/types/contentking';

import AlertsLink from '~/components/app/AlertsLink';
import DashboardLink from '~/components/app/DashboardLink';
import IssuesOverviewLink from '~/components/app/IssuesOverviewLink';
import PagesOverviewLink from '~/components/app/PagesOverviewLink';
import PlatformLink from '~/components/app/PlatformLink';
import WebsiteSettingsLink from '~/components/app/WebsiteSettingsLink';



type Props = {
	children?: React.ReactNode,
	className?: string,
	columns: {},
	columnsParameter?: string,
	historicalChangesFilter: {},
	historicalChangesInterval: Immutable.Map<any, any>,
	historicalChangesSortBy: {},
	inHistoricalChangesMode: boolean,
	isActive: boolean,
	onClick?: () => void,
	pagesFilter: {},
	pagesSortBy: {},
	section?: string,
	viewParameter?: CK.ColumnSetId | null,
	websiteId: CK.WebsiteId | null,
};



class WebsiteLink extends Component<Props> {

	_renderAlertsScreenLink() {
		const {
			className,
			onClick,
			websiteId,
		} = this.props;

		return (
			<AlertsLink
				className={className}
				keepSettings={true}
				onClick={onClick}
				websiteId={websiteId}
			>
				{this.props.children}
			</AlertsLink>
		);
	}



	_renderDashboardScreenLink() {
		const {
			className,
			onClick,
			websiteId,
		} = this.props;

		return (
			<DashboardLink
				className={className}
				onClick={onClick}
				websiteId={websiteId}
			>
				{this.props.children}
			</DashboardLink>
		);
	}



	_renderIssuesScreenLink() {
		const {
			className,
			onClick,
			websiteId,
		} = this.props;

		return (
			<IssuesOverviewLink
				className={className}
				onClick={onClick}
				websiteId={websiteId}
			>
				{this.props.children}
			</IssuesOverviewLink>
		);
	}



	_renderPlatformScreenLink() {
		const {
			className,
			onClick,
			websiteId,
		} = this.props;

		return (
			<PlatformLink
				className={className}
				onClick={onClick}
				websiteId={websiteId}
			>
				{this.props.children}
			</PlatformLink>
		);
	}



	_renderWebsiteDetailScreenLink() {
		const {
			className,
			onClick,
			websiteId,
		} = this.props;

		return (
			<WebsiteSettingsLink
				className={className}
				onClick={onClick}
				websiteId={websiteId}
			>
				{this.props.children}
			</WebsiteSettingsLink>
		);
	}



	_renderPagesScreenLink() {
		const {
			className,
			columns,
			columnsParameter,
			historicalChangesFilter,
			historicalChangesInterval,
			historicalChangesSortBy,
			inHistoricalChangesMode,
			isActive,
			onClick,
			pagesFilter,
			pagesSortBy,
			viewParameter,
			websiteId,
		} = this.props;

		return (
			<PagesOverviewLink
				className={className}
				columns={columns}
				columnsParameter={columnsParameter}
				historicalChangesFilter={historicalChangesFilter}
				historicalChangesInterval={historicalChangesInterval}
				historicalChangesSortBy={historicalChangesSortBy}
				inHistoricalChangesMode={inHistoricalChangesMode}
				isActive={isActive}
				onClick={onClick}
				pagesFilter={pagesFilter}
				pagesSortBy={pagesSortBy}
				viewParameter={viewParameter ?? null}
				websiteId={websiteId}
			>
				{this.props.children}
			</PagesOverviewLink>
		);
	}



	render() {
		const {
			section,
			websiteId,
		} = this.props;

		if (!websiteId) {
			return false;
		}

		if (section === 'website.dashboard') {
			return this._renderDashboardScreenLink();
		} else if (section === 'website.alerts') {
			return this._renderAlertsScreenLink();
		} else if (section === 'website.issues') {
			return this._renderIssuesScreenLink();
		} else if (section === 'website.pages') {
			return this._renderPagesScreenLink();
		} else if (section === 'website.platform') {
			return this._renderPlatformScreenLink();
		} else if (section === 'website.detail') {
			return this._renderWebsiteDetailScreenLink();
		}

		return this._renderPagesScreenLink();
	}

}



export default WebsiteLink;
