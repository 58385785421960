import classNames from 'classnames';
import React from 'react';

import StatusFlag, {
	StatusFlagSize,
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';



export enum WebsiteCardStatus {
	Critical = 'critical',
	Default = 'default',
	Paused = 'paused',
	Warning = 'warning',
}

type Props = {
	/** Info about capacity of selected website */
	capacityInfo?: React.ReactNode,
	/** Details of user */
	details: React.ReactNode,
	favicon: React.ReactNode,
	/** List of integration icons */
	integrationIcons?: React.ReactNode,
	/** Name of website */
	name: React.ReactNode,
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
	status?: WebsiteCardStatus,
};



const WebsiteCard: React.FC<Props> = (props) => {
	const {
		capacityInfo,
		details,
		favicon,
		integrationIcons,
		name,
		onClick,
		status = WebsiteCardStatus.Default,
	} = props;

	const componentClasses = classNames({
		'website-card': true,
		'website-card--clickable': onClick,

		// Statuses
		'website-card--status-critical': status === WebsiteCardStatus.Critical,
		'website-card--status-paused': status === WebsiteCardStatus.Paused,
		'website-card--status-warning': status === WebsiteCardStatus.Warning,
	});

	let iconStatus;

	if (status === WebsiteCardStatus.Critical) {
		iconStatus = StatusFlagStatus.Critical;
	}

	if (status === WebsiteCardStatus.Paused) {
		iconStatus = StatusFlagStatus.Paused;
	}

	if (status === WebsiteCardStatus.Warning) {
		iconStatus = StatusFlagStatus.Warning;
	}

	return (
		<div
			className={componentClasses}
			onClick={onClick}
		>
			<div className="website-card__container">
				<div className="website-card__favicon">
					{status !== WebsiteCardStatus.Default && (
						<div className="website-card__status-icon">
							<StatusFlag
								size={StatusFlagSize.Medium}
								status={iconStatus}
							/>
						</div>
					)}
					{favicon}
				</div>
				<div className="website-card__content">
					<h4 className="website-card__name">
						{name}
					</h4>

					<div className="website-card__details">
						{capacityInfo && (
							<div className="website-card__usage-info">
								{capacityInfo}
							</div>
						)}

						{details}
					</div>
				</div>
			</div>

			{integrationIcons && (
				<div className="website-card__integration-icons">
					{integrationIcons}
				</div>
			)}
		</div>
	);
};



export default WebsiteCard;
