import React from 'react';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserLocale from '~/hooks/useUserLocale';

import {
	pendo,
} from '~/globals';

import {
	LOCALE_EN_US,
} from '~/model/locales';

import {
	getPendoGuideName,
} from '~/model/pendoGuides';



function usePendoGuides() {
	const currentUserId = useCurrentUserId();
	const currentUserLocale = useUserLocale(currentUserId);

	const locale = currentUserLocale ?? LOCALE_EN_US;

	const showGuide = React.useCallback(
		(guide) => {
			pendo().then((pendo) => {
				pendo.loadGuides().then(() => {
					const onboardingGuide = pendo.findGuideByName(
						getPendoGuideName(guide, locale),
					);

					if (onboardingGuide && !onboardingGuide.isComplete()) {
						onboardingGuide.show();
					}
				});
			});
		},
		[
			locale,
		],
	);

	return React.useMemo(
		() => ({
			showGuide,
		}),
		[
			showGuide,
		],
	);
}



export default usePendoGuides;
