import React from 'react';
import {
	CellMeasurer,
	List,
} from 'react-virtualized';
import {
	type ListRowProps,
} from 'react-virtualized/dist/es/List';

import TimelineLoader from '~/components/patterns/time/timeline/TimelineLoader';
import TimelineSpacer from '~/components/patterns/time/timeline/TimelineSpacer';

import EstimatedCellMeasurerCache from '~/utilities/EstimatedCellMeasurerCache';
import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



function defaultKeyMapper(rowIndex, columnIndex) {
	return ''.concat(rowIndex, '-').concat(columnIndex);
}

export type TimelineRef = {
	getOffsetForRow: (input: Parameters<List['getOffsetForRow']>[0]) => number | undefined,
	scrollToPosition: (scrollTop: number | undefined) => void,
};

export type TimelineRowRendererInput<Item> = {
	isFirst: boolean,
	isLast: boolean,
	item: Item,
	itemIndex: number,
};



type Props<Item, Items extends ReadonlyArray<Item>> = {
	estimatedItemHeight: number,
	fill: boolean,
	height: number,
	itemHeightProvider: (item: Items[0]) => number,
	itemRenderer: (input: TimelineRowRendererInput<Items[0]>) => React.ReactNode,
	items: ReadonlyArray<Item | null> | null,
	width: number,
};

function Timeline<Item, Items extends ReadonlyArray<Item>>(props: Props<Item, Items>, ref: React.ForwardedRef<TimelineRef>) {
	const {
		estimatedItemHeight,
		fill,
		height,
		itemHeightProvider,
		itemRenderer,
		items,
		width,
	} = props;

	const [, setRenderBump] = React.useState(0);

	const listRef = React.useRef<List>(null);

	React.useImperativeHandle(ref, () => ({
		getOffsetForRow: (input) => listRef.current?.getOffsetForRow(input),
		scrollToPosition: (input) => listRef.current?.scrollToPosition(input),
	}));

	const measurementCache = React.useMemo(
		() => {
			return new EstimatedCellMeasurerCache({
				fixedWidth: true,
				defaultHeight: estimatedItemHeight,
				keyMapper: defaultKeyMapper,
			});
		},
		[
			estimatedItemHeight,
		],
	);

	const rowRenderer = React.useCallback(
		(props: ListRowProps) => {
			const {
				index,
				key,
				parent,
				style,
			} = props;

			const item = items !== null
				? getArrayItemAtSafeIndex(items, index)
				: null;

			return (
				<CellMeasurer
					cache={measurementCache}
					columnIndex={0}
					key={key}
					parent={parent}
					rowIndex={index}
				>
					<div style={style}>
						{item !== null ? itemRenderer({
							isFirst: index === 0,
							isLast: index === ((items?.length ?? 0) - 1),
							item,
							itemIndex: index,
						}) : (
							<TimelineLoader
								message=""
							/>
						)}
					</div>
				</CellMeasurer>
			);
		},
		[
			itemRenderer,
			items,
			measurementCache,
		],
	);

	React.useEffect(
		() => {
			if (items === null) {
				return;
			}

			measurementCache.clearAll();

			items.forEach((item, index) => {
				if (item === null) {
					return;
				}

				const itemHeight = itemHeightProvider(item);

				measurementCache.estimate(index, 0, undefined, itemHeight);
			});

			listRef.current?.forceUpdateGrid();
			setRenderBump((renderBump) => renderBump + 1);
		},
		[
			itemHeightProvider,
			items,
			measurementCache,
		],
	);

	return (
		<>
			<List
				deferredMeasurementCache={measurementCache}
				estimatedRowSize={measurementCache.getAverageRowHeight()}
				height={height}
				overscanRowCount={2}
				ref={listRef}
				rowCount={items?.length ?? 0}
				rowHeight={measurementCache.rowHeight}
				rowRenderer={rowRenderer}
				width={width}
			/>

			{fill && (
				<TimelineSpacer
					height={Math.max(0, height - measurementCache.getTotalHeight())}
				/>
			)}
		</>
	);
}



export default React.forwardRef(Timeline);
