/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptAccessToClientMutationVariables = GraphQL.Exact<{
  agencyId: GraphQL.Scalars['AccountId']['input'];
  clientId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AcceptAccessToClientMutation = { readonly __typename?: 'Mutation', readonly AcceptAccessToClient: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly clientConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessClientConnection', readonly clientAccountId: CK.AccountId, readonly clientAccountName: string, readonly id: any, readonly inviterEmail: string | null, readonly isInitiatedByAgency: boolean, readonly isPending: boolean, readonly level: GraphQL.AccountAccessConnectionLevel }>, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null, readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId }> } | null } } };


export const AcceptAccessToClientDocument = gql`
    mutation AcceptAccessToClient($agencyId: AccountId!, $clientId: AccountId!) {
  AcceptAccessToClient(agencyId: $agencyId, clientId: $clientId) {
    query {
      account(id: $agencyId) {
        clientConnections {
          clientAccountId
          clientAccountName
          id
          inviterEmail
          isInitiatedByAgency
          isPending
          level
        }
        features {
          feature
          id
          inUse
        }
        id
      }
      authenticatedSession {
        accounts {
          id
        }
      }
    }
  }
}
    `;
export type AcceptAccessToClientMutationFn = Apollo.MutationFunction<AcceptAccessToClientMutation, AcceptAccessToClientMutationVariables>;

/**
 * __useAcceptAccessToClientMutation__
 *
 * To run a mutation, you first call `useAcceptAccessToClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAccessToClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAccessToClientMutation, { data, loading, error }] = useAcceptAccessToClientMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useAcceptAccessToClientMutation(baseOptions?: Apollo.MutationHookOptions<AcceptAccessToClientMutation, AcceptAccessToClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptAccessToClientMutation, AcceptAccessToClientMutationVariables>(AcceptAccessToClientDocument, options);
      }
export type AcceptAccessToClientMutationHookResult = ReturnType<typeof useAcceptAccessToClientMutation>;
export type AcceptAccessToClientMutationResult = Apollo.MutationResult<AcceptAccessToClientMutation>;
export type AcceptAccessToClientMutationOptions = Apollo.BaseMutationOptions<AcceptAccessToClientMutation, AcceptAccessToClientMutationVariables>;