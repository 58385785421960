import Immutable, {
	List,
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
} from '~/actions';

import {
	MARK_START_OF_LOADING,
	STORE,
	STORE_SITEMAP_IS_PRIORITIZED,
	STORE_TOTAL_COUNT,
	UPDATE_FILTER,
	UPDATE_SORT_BY,
} from '~/actions/sitemapsList';

import {
	decodeFilterParameter,
} from '~/model/filterParameter';

import {
	DEFAULT_FILTER,
	DEFAULT_SORT_BY,
} from '~/model/sitemapsList';



function createDefaultState() {
	return Map({
		data: Map(),
		filter: DEFAULT_FILTER,
		loading: true,
		sortBy: DEFAULT_SORT_BY,
		totals: Map(),
		websiteId: null,
	});
}



function createDefaultWebsite(websiteId) {
	return Map({
		loaded: false,
		sitemaps: Map(),
		range: List(),
		requestInitiatedAt: null,
		total: null,
		websiteId,
	});
}



export function sitemapsList(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			const {
				previousUrlState,
				urlState,
			} = action;

			if (urlState.params.websiteId !== previousUrlState.params.websiteId) {
				state = createDefaultState();
			}

			if (urlState.name.indexOf('website.platform') === 0) {
				const websiteId = urlState.params.websiteId;

				if (!state.get('data').has(websiteId)) {
					state = state.setIn(
						['data', websiteId],
						createDefaultWebsite(websiteId),
					);
				}

				if (!state.get('totals').has(websiteId)) {
					state = state.setIn(
						['totals', websiteId],
						Map(),
					);
				}

				state = state.set('websiteId', urlState.params.websiteId);
			}

			if (urlState.name.indexOf('website.platform.sitemaps') === 0) {
				if (!state.get('data').has(urlState.params.websiteId)) {
					state = state.set('loading', true);
				}

				let newFilter;
				let newSortBy;

				if (urlState.params.filter) {
					const unpackedFilter = decodeFilterParameter(urlState.params.filter);

					if (unpackedFilter.filter) {
						newFilter = unpackedFilter.filter;
					}

					if (unpackedFilter.sortBy) {
						newSortBy = unpackedFilter.sortBy;
					}
				} else {
					newFilter = DEFAULT_FILTER;
					newSortBy = DEFAULT_SORT_BY;
				}

				if (!Immutable.is(state.get('filter'), newFilter)) {
					state = state.set('filter', Immutable.fromJS(newFilter));
				}

				if (!Immutable.is(state.get('sortBy'), newSortBy)) {
					state = state.set('sortBy', Immutable.fromJS(newSortBy));
				}
			}

			break;
		}

		case MARK_START_OF_LOADING: {
			const {
				initiatedAt,
			} = action;

			state = state.set('initiatedAt', initiatedAt);

			break;
		}

		case STORE: {
			const {
				initiatedAt,
				websiteId,
				total,
				offset,
				range,
				sitemaps,
			} = action;

			if (state.get('initiatedAt') > initiatedAt) {
				break;
			}

			state = state.set('loading', false);

			if (!state.get('data').has(websiteId)) {
				state = state.setIn(
					['data', websiteId],
					createDefaultWebsite(websiteId),
				);
			}

			state = state.setIn(
				['data', websiteId, 'total'],
				total,
			);
			state = state.setIn(
				['data', websiteId, 'loaded'],
				true,
			);
			state = state.setIn(
				['data', websiteId, 'range'],
				state.get('data').get(websiteId).get('range').withMutations((mutableRange) => {
					for (const index in range) {
						mutableRange.set(offset + parseInt(index, 10), range[index]);
					}
				}),
			);
			state = state.setIn(
				['data', websiteId, 'sitemaps'],
				state.get('data').get(websiteId).get('sitemaps').withMutations((mutableSitemaps) => {
					for (const index in sitemaps) {
						const sitemapId = sitemaps[index].id.toString();

						if (mutableSitemaps.has(sitemapId)) {
							mutableSitemaps.set(
								sitemapId,
								mutableSitemaps.get(sitemapId).merge(sitemaps[index]),
							);
						} else {
							mutableSitemaps.set(
								sitemapId,
								Immutable.fromJS(sitemaps[index]),
							);
						}
					}
				}),
			);

			break;
		}

		case STORE_SITEMAP_IS_PRIORITIZED: {
			const {
				isPrioritized,
				sitemapId,
				websiteId,
			} = action;

			state = state.setIn(
				['data', websiteId, 'sitemaps', sitemapId.toString(), 'is_prioritized'],
				isPrioritized,
			);

			break;
		}

		case STORE_TOTAL_COUNT: {
			const {
				issueType,
				totalCount,
				websiteId,
			} = action;

			state = state.mergeIn(
				['totals', websiteId],
				{
					[issueType]: totalCount,
				},
			);

			break;
		}

		case UPDATE_FILTER: {
			const oldState = state;
			const {
				filter,
			} = action;

			state = state.mergeIn(['filter'], filter);
			state = state.setIn(['filter'], state.get('filter').filter((val) => val !== ''));

			if (state !== oldState) {
				state = state.set('loading', true);
			}

			break;
		}

		case UPDATE_SORT_BY: {
			const oldState = state;
			const {
				sortBy,
			} = action;

			state = state.mergeIn(['sortBy'], sortBy);

			if (state !== oldState) {
				state = state.set('loading', true);
			}

			break;
		}

	}

	return state;
}
