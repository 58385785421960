import React from 'react';

import {
	MultiStepModalContext,
} from '~/components/patterns/modals/MultiStepModal';



function useMultiStepModal() {
	const context = React.useContext(MultiStepModalContext);

	return context;
}



export default useMultiStepModal;
