import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';
import {
	defineMessages,
	injectIntl,
} from 'react-intl';

import DonutPagesChart from '../components/DonutPagesChart';



const messages = defineMessages({
	crossdomain: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.external',
	},
	internal_other: {
		id: 'ui.contentOverview.canonicalTypes.internal.otherPage',
	},
	internal_self: {
		id: 'ui.contentOverview.canonicalTypes.internal.self',
	},
	invalid: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.invalid',
	},
	none: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.none',
	},
	not_applicable: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.notApplicable',
	},
});



class CanonicalTypeChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		const {
			intl,
		} = this.props;

		this.labels = {
			crossdomain: intl.formatMessage(messages.crossdomain),
			internal_other: intl.formatMessage(messages.internal_other),
			internal_self: intl.formatMessage(messages.internal_self),
			invalid: intl.formatMessage(messages.invalid),
			none: intl.formatMessage(messages.none),
			not_applicable: intl.formatMessage(messages.not_applicable),
		};

		this.order = [
			'internal_self',
			'internal_other',
			'crossdomain',
			'invalid',
			'none',
			'not_applicable',
		];

		this.specificColors = {
			crossdomain: '#FE8719',
			internal_other: 'rgba(66,204,103,0.8)',
			internal_self: '#42CC67',
			invalid: 'rgba(254,135,25,0.8)',
			none: '#FF5959',
			not_applicable: '#8595A6',
		};
	}



	render() {
		const {
			data,
		} = this.props;

		return (
			<DonutPagesChart
				data={data}
				labels={this.labels}
				name="canonical_type"
				order={this.order}
				percentageFactor={100}
				specificColors={this.specificColors}
			/>
		);
	}

}

CanonicalTypeChart.propTypes = {
	data: PropTypes.instanceOf(List),
};



export default injectIntl(CanonicalTypeChart);
