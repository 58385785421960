import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useOfferAgencyAccessMutation,
} from './ConnectToAgencyModal.gql';

import useAccountId from '~/hooks/useAccountId';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	description: {
		id: 'ui.accountAccess.connectToAgency.description',
	},
	emailLabel: {
		id: 'ui.accountAccess.connectToAgency.emailLabel',
	},
	formErrorsAlreadyConnected: {
		id: 'ui.accountAccess.connectToAgency.errors.alreadyConnected',
	},
	formErrorsConnectionNotAgency: {
		id: 'ui.accountAccess.connectToAgency.errors.notAgency',
	},
	formErrorsConnectionTargetAmbiguous: {
		id: 'ui.accountAccess.connectToAgency.errors.ambiguousTarget',
	},
	formErrorsConnectionUnknownUser: {
		id: 'ui.accountAccess.connectToAgency.errors.unknownUser',
	},
	formErrorsConnectionUnsupportedByClient: {
		id: 'ui.accountAccess.connectToAgency.errors.insufficientPrivileges',
	},
	formErrorsMaximumAllowedTrialConnectionsLimitReached: {
		id: 'ui.accountAccess.connectToAgency.errors.maximumAllowedTrialConnectionsLimitReached',
	},
	submitButton: {
		id: 'ui.accountAccess.connectToAgency.submitButton',
	},
	title: {
		id: 'ui.accountAccess.connectToAgency.title',
	},
});

const validations = {
	email: validateField(
		'email',
		(f) => [
			f.customGlobal({
				message: (
					<FormattedMessage {...messages.formErrorsConnectionUnknownUser} />
				),
				globalRule: 'account_access_unknown_invitee',
			}),
			f.customGlobal({
				message: (
					<FormattedMessage {...messages.formErrorsConnectionNotAgency} />
				),
				globalRule: 'account_access_unsupported_by_target',
			}),
			f.customGlobal({
				message: (
					<FormattedMessage {...messages.formErrorsAlreadyConnected} />
				),
				globalRule: 'account_access_already_exists',
			}),
			f.customGlobal({
				message: (
					<FormattedMessage {...messages.formErrorsConnectionTargetAmbiguous} />
				),
				globalRule: 'accountAccessAmbiguousTarget',
			}),
			f.validateNonEmpty(),
			f.validateEmail(),
		],
	),
};



const ConnectToAgencyModal = (props) => {
	const {
		closeCallback,
	} = props;

	const accountId = useAccountId();

	const [offerAgencyAccess] = useOfferAgencyAccessMutation();

	const handleSubmit = React.useCallback(
		async ({ email }) => {
			if (accountId === null) {
				return;
			}

			try {
				await offerAgencyAccess({
					variables: {
						agencyContactEmail: email,
						clientId: accountId,
						level: GraphQL.AccountAccessConnectionLevel.Owner,
					},
				});
			} catch (e) {
				throw FormError.fromApolloError(e);
			}

			closeCallback();
		},
		[
			accountId,
			closeCallback,
			offerAgencyAccess,
		],
	);

	return (
		<ModalContainer
			header={(
				<ModalHeader
					title={(
						<FormattedMessage {...messages.title} />
					)}
				/>
			)}
		>
			<ModalTextSection>
				<RichText>
					<Copy {...messages.description} />
				</RichText>
			</ModalTextSection>

			<CenteredFormWrapper>
				<Form
					defaultValues={{
						email: '',
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<FormRow
						htmlFor="email"
						label={(
							<FormattedMessage {...messages.emailLabel} />
						)}
					>
						<FieldStatus name="email">
							<TextField
								autoComplete={TextFieldAutocomplete.Off}
								name="email"
								type={TextFieldType.Email}
							/>
						</FieldStatus>
					</FormRow>

					<FormErrorMessages
						errors={{
							maximumAllowedTrialConnectionsLimitReached: (
								<Copy
									{...messages.formErrorsMaximumAllowedTrialConnectionsLimitReached}
									values={{
										link_support: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
									}}
								/>
							),
						}}
					/>

					<ButtonsLayout>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.submitButton} />
						</SubmitButton>
					</ButtonsLayout>
				</Form>
			</CenteredFormWrapper>
		</ModalContainer>
	);
};



export default ConnectToAgencyModal;
