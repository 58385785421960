import React from 'react';

import styles from './Separator.module.scss';



type Props = {
	children: React.ReactNode,
};



const Separator: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<span className={styles.component}>
			{children}
		</span>
	);
};



export default Separator;
