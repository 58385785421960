import React from 'react';

import AbstractRadioList, {
	ITEM_STYLE_TAG as LIST_ITEM_STYLE_TAG,
	ITEM_STYLE_TEXTUAL as LIST_ITEM_STYLE_TEXTUAL,
	SIZE_DEFAULT as LIST_SIZE_DEFAULT,
	SIZE_SMALL as LIST_SIZE_SMALL,
} from './builders/AbstractRadioList';

import useFormContext from '~/hooks/useFormContext';



export const ITEM_STYLE_TAG = LIST_ITEM_STYLE_TAG;
export const ITEM_STYLE_TEXTUAL = LIST_ITEM_STYLE_TEXTUAL;
export const SIZE_DEFAULT = LIST_SIZE_DEFAULT;
export const SIZE_SMALL = LIST_SIZE_SMALL;



type Props = {
	className?: string,
	fieldClassName?: (() => string) | string,
	inline?: boolean,
	isControlled?: boolean,
	items: ReadonlyArray<{
		description?: React.ReactNode,
		disabled?: boolean,
		label: React.ReactNode | ((input: {
			isChecked: boolean,
			isDisabled: boolean,
		}) => React.ReactNode),
		value: boolean | string,
	}>,
	itemClassName?: (() => string) | string,
	itemFlexBehavior?: string,
	itemMaxWidth?: number,
	itemStyle?:
	| typeof ITEM_STYLE_TAG
	| typeof ITEM_STYLE_TEXTUAL,
	name: string,
	onChangeCallback?: (name: string, value: boolean | string) => void,
	size?:
	| typeof SIZE_DEFAULT
	| typeof SIZE_SMALL,
	width?: boolean | number,
	wrapItems?: boolean,
};

const RadioList: React.FC<Props> = (props) => {
	const {
		className,
		fieldClassName,
		inline = false,
		isControlled = false,
		itemClassName,
		itemFlexBehavior = '0 0 auto',
		itemMaxWidth,
		itemStyle = ITEM_STYLE_TEXTUAL,
		items,
		name,
		onChangeCallback,
		size = SIZE_DEFAULT,
		width = 280,
		wrapItems = true,
	} = props;

	const formContext = useFormContext();

	const formContextOnMountHandler = formContext.onMountHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	React.useEffect(
		() => {
			formContextOnMountHandler(
				name,
				{
					interacted: true,
				},
			);

			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			name,
		],
	);

	return (
		<AbstractRadioList
			className={className}
			fieldClassName={fieldClassName}
			inline={inline}
			isControlled={isControlled}
			itemClassName={itemClassName}
			itemFlexBehavior={itemFlexBehavior}
			itemMaxWidth={itemMaxWidth}
			itemStyle={itemStyle}
			items={items.map((rawItem) => {
				const item = {
					checked: false,
					disabled: false,
					...rawItem,
				};

				const isChecked = isControlled
					? (formContext.defaultValues[name] === item.value)
					: formContext.values[name] === item.value;

				if (formContext.isDisabled) {
					item.disabled = true;
				}

				item.checked = isChecked;

				if (typeof item.label === 'function') {
					item.label = item.label({
						isChecked,
						isDisabled: formContext.isDisabled,
					});
				}

				return item;
			})}
			name={name}
			onBlurCallback={() => {
				formContext.onBlurHandler(name);
			}}
			onChangeCallback={(value) => {
				formContext.onChangeHandler(name, value).then(() => {
					if (onChangeCallback) {
						onChangeCallback(name, value);
					}
				});
			}}
			onFocusCallback={() => {
				formContext.onFocusHandler(name);
			}}
			size={size}
			width={width}
			wrapItems={wrapItems}
		/>
	);
};



export default RadioList;
