import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** When custom text is defined we can also specify optional subtext */
	subtext?: React.ReactNode,
	/** Possible custom text in overlay */
	text?: React.ReactNode,
};



const ChartOverlay: React.FC<Props> = (props) => {
	const {
		children,
		subtext,
		text,
	} = props;

	const _renderText = () => {
		if (!text) {
			return false;
		}

		return (
			<div className="chart-overlay__text-layer">
				<span className="chart-overlay__text-gap">{text}</span>
				{subtext && (
					<div className="chart-overlay__subtext">
						<span className="chart-overlay__text-gap">
							{subtext}
						</span>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="chart-overlay">
			<div className="chart-overlay__content">
				{_renderText() || children}
			</div>
		</div>
	);
};



export default ChartOverlay;
