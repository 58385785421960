import React from 'react';



type Props = {
	/** Name of this feature */
	label: React.ReactNode,
	/** Value of this feature */
	value: React.ReactNode,
};



const PlanFeatureCell: React.FC<Props> = (props) => {
	const {
		label,
		value,
	} = props;

	return (
		<div className="plan-feature-cell">
			<div className="plan-feature-cell__label">
				{label}
			</div>
			<div className="plan-feature-cell__value">
				{value}
			</div>
		</div>
	);
};



export default PlanFeatureCell;
