import classNames from 'classnames';
import React from 'react';

import BasicIcon, {
	BasicIconType,
	HeartIconVariants,
} from '~/components/patterns/icons/BasicIcon';



export enum HealthIconImpact {
	Negative = 'negative',
	Neutral = 'neutral',
	Positive = 'positive',
}

type Props = {
	/** Active highlight animation */
	highlighted?: boolean,
	/** Possibility to extend health icon by impact visualisation */
	impact?: HealthIconImpact,
	size?: number,
};

const HealthIcon: React.FC<Props> = (props) => {
	const {
		highlighted = false,
		impact = HealthIconImpact.Neutral,
		size = 15,
	} = props;

	let iconVariant = HeartIconVariants.Default;

	if (impact === HealthIconImpact.Negative) {
		iconVariant = HeartIconVariants.Down;
	}

	if (impact === HealthIconImpact.Positive) {
		iconVariant = HeartIconVariants.Up;
	}

	return (
		<div
			className={classNames({
				'health-icon-animation': true,
				'health-icon-animation--animation-enabled': highlighted,
			})}
			style={{
				height: size,
				width: size,
			}}
		>
			<div className="health-icon-animation__icon">
				<BasicIcon
					size={size}
					type={BasicIconType.Heart}
					variant={iconVariant}
				/>
			</div>
		</div>
	);
};



export default HealthIcon;
