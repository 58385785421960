import React from 'react';

import ButtonContext from './ButtonContext';



const ButtonContextProvider = (props) => {
	const {
		children,
	} = props;

	const contextProps = { ...props };
	delete contextProps.children;

	const dependencies = [];

	for (const prop in contextProps) {
		dependencies.push(contextProps[prop]);
	}

	const buttonProps = React.useMemo(
		() => contextProps,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		dependencies,
	);

	return (
		<ButtonContext.Provider value={buttonProps}>
			{children}
		</ButtonContext.Provider>
	);
};



export default ButtonContextProvider;
