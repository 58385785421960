/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePersonalColumnSetMutationVariables = GraphQL.Exact<{
  columns: ReadonlyArray<GraphQL.ColumnSetColumnInput> | GraphQL.ColumnSetColumnInput;
  name: GraphQL.Scalars['String']['input'];
}>;


export type CreatePersonalColumnSetMutation = { readonly __typename?: 'Mutation', readonly CreatePersonalColumnSet: { readonly __typename?: 'CreateColumnSetResult', readonly createdColumnSet: { readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId }, readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly sharedColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly personalColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> } | null } } };

export type CreateSharedColumnSetMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  columns: ReadonlyArray<GraphQL.ColumnSetColumnInput> | GraphQL.ColumnSetColumnInput;
  name: GraphQL.Scalars['String']['input'];
}>;


export type CreateSharedColumnSetMutation = { readonly __typename?: 'Mutation', readonly CreateSharedColumnSet: { readonly __typename?: 'CreateColumnSetResult', readonly createdColumnSet: { readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId }, readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly sharedColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly personalColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> } | null } } };

export type DeleteColumnSetMutationVariables = GraphQL.Exact<{
  id: GraphQL.Scalars['ColumnSetId']['input'];
}>;


export type DeleteColumnSetMutation = { readonly __typename?: 'Mutation', readonly DeleteColumnSet: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly sharedColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly personalColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> } | null } } };

export type ShareColumnSetMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  id: GraphQL.Scalars['ColumnSetId']['input'];
}>;


export type ShareColumnSetMutation = { readonly __typename?: 'Mutation', readonly ShareColumnSet: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly sharedColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly personalColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> } | null } } };

export type UpdateColumnSetMutationVariables = GraphQL.Exact<{
  columns: ReadonlyArray<GraphQL.ColumnSetColumnInput> | GraphQL.ColumnSetColumnInput;
  id: GraphQL.Scalars['ColumnSetId']['input'];
  name: GraphQL.Scalars['String']['input'];
}>;


export type UpdateColumnSetMutation = { readonly __typename?: 'Mutation', readonly UpdateColumnSet: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly sharedColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly personalColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> } | null } } };

export type ColumnSetsFragment = { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly sharedColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly personalColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> };

export const ColumnSetsFragmentDoc = gql`
    fragment ColumnSets on AuthenticatedSession {
  memberships {
    account {
      id
      sharedColumnSets {
        columns {
          enabled
          name
        }
        id
        isDefault
        name
      }
    }
    user {
      id
      legacyId
    }
  }
  personalColumnSets {
    columns {
      enabled
      name
    }
    id
    isDefault
    name
  }
}
    `;
export const CreatePersonalColumnSetDocument = gql`
    mutation CreatePersonalColumnSet($columns: [ColumnSetColumnInput!]!, $name: String!) {
  CreatePersonalColumnSet(columns: $columns, name: $name) {
    createdColumnSet {
      id
    }
    query {
      authenticatedSession {
        ...ColumnSets
      }
    }
  }
}
    ${ColumnSetsFragmentDoc}`;
export type CreatePersonalColumnSetMutationFn = Apollo.MutationFunction<CreatePersonalColumnSetMutation, CreatePersonalColumnSetMutationVariables>;

/**
 * __useCreatePersonalColumnSetMutation__
 *
 * To run a mutation, you first call `useCreatePersonalColumnSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonalColumnSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonalColumnSetMutation, { data, loading, error }] = useCreatePersonalColumnSetMutation({
 *   variables: {
 *      columns: // value for 'columns'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePersonalColumnSetMutation(baseOptions?: Apollo.MutationHookOptions<CreatePersonalColumnSetMutation, CreatePersonalColumnSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePersonalColumnSetMutation, CreatePersonalColumnSetMutationVariables>(CreatePersonalColumnSetDocument, options);
      }
export type CreatePersonalColumnSetMutationHookResult = ReturnType<typeof useCreatePersonalColumnSetMutation>;
export type CreatePersonalColumnSetMutationResult = Apollo.MutationResult<CreatePersonalColumnSetMutation>;
export type CreatePersonalColumnSetMutationOptions = Apollo.BaseMutationOptions<CreatePersonalColumnSetMutation, CreatePersonalColumnSetMutationVariables>;
export const CreateSharedColumnSetDocument = gql`
    mutation CreateSharedColumnSet($accountId: AccountId!, $columns: [ColumnSetColumnInput!]!, $name: String!) {
  CreateSharedColumnSet(accountId: $accountId, columns: $columns, name: $name) {
    createdColumnSet {
      id
    }
    query {
      authenticatedSession {
        ...ColumnSets
      }
    }
  }
}
    ${ColumnSetsFragmentDoc}`;
export type CreateSharedColumnSetMutationFn = Apollo.MutationFunction<CreateSharedColumnSetMutation, CreateSharedColumnSetMutationVariables>;

/**
 * __useCreateSharedColumnSetMutation__
 *
 * To run a mutation, you first call `useCreateSharedColumnSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedColumnSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedColumnSetMutation, { data, loading, error }] = useCreateSharedColumnSetMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      columns: // value for 'columns'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateSharedColumnSetMutation(baseOptions?: Apollo.MutationHookOptions<CreateSharedColumnSetMutation, CreateSharedColumnSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSharedColumnSetMutation, CreateSharedColumnSetMutationVariables>(CreateSharedColumnSetDocument, options);
      }
export type CreateSharedColumnSetMutationHookResult = ReturnType<typeof useCreateSharedColumnSetMutation>;
export type CreateSharedColumnSetMutationResult = Apollo.MutationResult<CreateSharedColumnSetMutation>;
export type CreateSharedColumnSetMutationOptions = Apollo.BaseMutationOptions<CreateSharedColumnSetMutation, CreateSharedColumnSetMutationVariables>;
export const DeleteColumnSetDocument = gql`
    mutation DeleteColumnSet($id: ColumnSetId!) {
  DeleteColumnSet(id: $id) {
    query {
      authenticatedSession {
        ...ColumnSets
      }
    }
  }
}
    ${ColumnSetsFragmentDoc}`;
export type DeleteColumnSetMutationFn = Apollo.MutationFunction<DeleteColumnSetMutation, DeleteColumnSetMutationVariables>;

/**
 * __useDeleteColumnSetMutation__
 *
 * To run a mutation, you first call `useDeleteColumnSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteColumnSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteColumnSetMutation, { data, loading, error }] = useDeleteColumnSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteColumnSetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteColumnSetMutation, DeleteColumnSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteColumnSetMutation, DeleteColumnSetMutationVariables>(DeleteColumnSetDocument, options);
      }
export type DeleteColumnSetMutationHookResult = ReturnType<typeof useDeleteColumnSetMutation>;
export type DeleteColumnSetMutationResult = Apollo.MutationResult<DeleteColumnSetMutation>;
export type DeleteColumnSetMutationOptions = Apollo.BaseMutationOptions<DeleteColumnSetMutation, DeleteColumnSetMutationVariables>;
export const ShareColumnSetDocument = gql`
    mutation ShareColumnSet($accountId: AccountId!, $id: ColumnSetId!) {
  ShareColumnSet(accountId: $accountId, id: $id) {
    query {
      authenticatedSession {
        ...ColumnSets
      }
    }
  }
}
    ${ColumnSetsFragmentDoc}`;
export type ShareColumnSetMutationFn = Apollo.MutationFunction<ShareColumnSetMutation, ShareColumnSetMutationVariables>;

/**
 * __useShareColumnSetMutation__
 *
 * To run a mutation, you first call `useShareColumnSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareColumnSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareColumnSetMutation, { data, loading, error }] = useShareColumnSetMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShareColumnSetMutation(baseOptions?: Apollo.MutationHookOptions<ShareColumnSetMutation, ShareColumnSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareColumnSetMutation, ShareColumnSetMutationVariables>(ShareColumnSetDocument, options);
      }
export type ShareColumnSetMutationHookResult = ReturnType<typeof useShareColumnSetMutation>;
export type ShareColumnSetMutationResult = Apollo.MutationResult<ShareColumnSetMutation>;
export type ShareColumnSetMutationOptions = Apollo.BaseMutationOptions<ShareColumnSetMutation, ShareColumnSetMutationVariables>;
export const UpdateColumnSetDocument = gql`
    mutation UpdateColumnSet($columns: [ColumnSetColumnInput!]!, $id: ColumnSetId!, $name: String!) {
  UpdateColumnSet(columns: $columns, id: $id, name: $name) {
    query {
      authenticatedSession {
        ...ColumnSets
      }
    }
  }
}
    ${ColumnSetsFragmentDoc}`;
export type UpdateColumnSetMutationFn = Apollo.MutationFunction<UpdateColumnSetMutation, UpdateColumnSetMutationVariables>;

/**
 * __useUpdateColumnSetMutation__
 *
 * To run a mutation, you first call `useUpdateColumnSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColumnSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColumnSetMutation, { data, loading, error }] = useUpdateColumnSetMutation({
 *   variables: {
 *      columns: // value for 'columns'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateColumnSetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateColumnSetMutation, UpdateColumnSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateColumnSetMutation, UpdateColumnSetMutationVariables>(UpdateColumnSetDocument, options);
      }
export type UpdateColumnSetMutationHookResult = ReturnType<typeof useUpdateColumnSetMutation>;
export type UpdateColumnSetMutationResult = Apollo.MutationResult<UpdateColumnSetMutation>;
export type UpdateColumnSetMutationOptions = Apollo.BaseMutationOptions<UpdateColumnSetMutation, UpdateColumnSetMutationVariables>;