import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import CanonicalTypeName from '~/components/names/CanonicalTypeName';
import MissingValue from '~/components/app/MissingValue';
import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';

import {
	CANONICAL_TYPE_NONE,
	CANONICAL_TYPE_NOT_APPLICABLE,
	LIST_OF_ALL_CANONICAL_TYPES,
} from '~/model/pages';



class CanonicalTypeFormatter extends PureComponent {

	render() {
		const {
			value,
		} = this.props;

		if (value === CANONICAL_TYPE_NONE) {
			return (
				<MissingValue />
			);
		}

		if (value === CANONICAL_TYPE_NOT_APPLICABLE) {
			return (
				<NotApplicableValue />
			);
		}

		return (
			<CanonicalTypeName canonicalType={value} />
		);
	}

}

CanonicalTypeFormatter.propTypes = {
	value: PropTypes.oneOf(LIST_OF_ALL_CANONICAL_TYPES).isRequired,
};



export default CanonicalTypeFormatter;
