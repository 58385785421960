import React from 'react';

import AbstractSelectField, {
	AbstractSelectFieldSize,
} from '~/components/patterns/forms/fields/AbstractSelectField';
import DateRangeCalendarPicker, {
	type Props as DateRangeCalendarPickerProps,
} from '~/components/patterns/forms/fieldParts/DateRangeCalendarPicker';
import DateRangeSelectToggler, {
	DateRangeSelectTogglerSize as DateRangeSelectFieldSize,
} from './DateRangeSelectToggler.part';



type Props = DateRangeCalendarPickerProps & {
	size?: DateRangeSelectFieldSize,
	width?: number,
};



const DateRangeSelectField: React.FC<Props> = (props) => {
	const {
		endDate,
		presetsOptions,
		startDate,
		size = DateRangeSelectFieldSize.Default,
		width = 280,
		...restPickerProps
	} = props;

	const selectedPresetLabel = presetsOptions?.find((option) => option.highlighted)?.label ?? '-';

	return (
		<AbstractSelectField
			dropdownWidth={610}
			label={selectedPresetLabel}
			labelRenderer={(label, isOpen) => (
				<DateRangeSelectToggler
					endDate={endDate}
					isOpen={isOpen}
					label={label}
					size={size}
					startDate={startDate}
				/>
			)}
			size={AbstractSelectFieldSize.Default}
			width={width}
		>
			<DateRangeCalendarPicker
				endDate={endDate}
				presetsOptions={presetsOptions}
				startDate={startDate}
				{...restPickerProps}
			/>
		</AbstractSelectField>
	);
};



export default DateRangeSelectField;
