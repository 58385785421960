import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import AffectedPagesPerCategoryChart from '../charts/AffectedPagesPerCategoryChart';
import CarouselBlocks from '~/components/patterns/structures/CarouselBlocks';
import HeaderLayout from './components/HeaderLayout';
import HealthPerSegmentChart from '../charts/HealthPerSegmentChart';
import MarginsList from '~/components/atoms/lists/MarginsList';
import OpenFilterButton from './components/OpenFilterButton';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';

import useViewportType from '~/hooks/useViewportType';

import {
	issuesFilterParameterSelector,
} from '~/state/ui/content/selectors';



enum Tab {
	Issues = 0,
	Graphs = 1,
}

const tabMessages = defineMessages({
	[Tab.Graphs]: {
		id: 'ui.issuesOverview.tabs.graphs',
	},
	[Tab.Issues]: {
		id: 'ui.issuesOverview.tabs.issues',
	},
});



type Props = {
	innerPanel?: () => React.ReactElement,
	issueCategoriesList: React.ReactNode,
	websiteId: CK.WebsiteId | null,
};

const MobileLayout: React.FC<Props> = (props) => {
	const {
		innerPanel,
		issueCategoriesList,
		websiteId,
	} = props;

	const issuesFilterParameter = useSelector(issuesFilterParameterSelector);
	const viewportType = useViewportType();

	const [activeTab, setActiveTab] = React.useState(Tab.Issues);

	const headerNavigation = (
		<TabNavigation>
			<TabNavigationItem
				isActive={activeTab === Tab.Issues}
				onClickCallback={() => setActiveTab(Tab.Issues)}
				uppercase={true}
			>
				<FormattedMessage {...tabMessages[Tab.Issues]} />
			</TabNavigationItem>
			<TabNavigationItem
				isActive={activeTab === Tab.Graphs}
				onClickCallback={() => setActiveTab(Tab.Graphs)}
				uppercase={true}
			>
				<FormattedMessage {...tabMessages[Tab.Graphs]} />
			</TabNavigationItem>
		</TabNavigation>
	);

	return (
		<ScreenLayout
			contentOverlay={innerPanel ? innerPanel() : null}
			header={(
				<HeaderLayout
					buttons={(
						<OpenFilterButton
							issuesFilterParameter={issuesFilterParameter}
						/>
					)}
					footer={headerNavigation}
				/>
			)}
			isHeaderFixed={viewportType.isSmall === false}
		>
			<CarouselBlocks
				activeSlideIndex={activeTab}
				adaptiveHeight={false}
				draggable={true}
				onSlideChangeCallback={setActiveTab}
				selectableText={true}
				swipe={true}
			>
				{issueCategoriesList}

				<ScreenBody>
					<MarginsList>
						<HealthPerSegmentChart
							websiteId={websiteId}
						/>

						<AffectedPagesPerCategoryChart
							websiteId={websiteId}
						/>
					</MarginsList>
				</ScreenBody>
			</CarouselBlocks>
		</ScreenLayout>
	);
};



export default MobileLayout;
