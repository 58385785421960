import React from 'react';

import ScoreInfo from '../../../scores/ScoreInfo';



type Props = {
	animateHeartbeat?: boolean,
	impact?: number,
	label: React.ReactNode,
	maximum?: number,
	paused?: boolean,
	value?: number | null,
};

const HeaderScoreInfo: React.FC<Props> = (props) => {
	const {
		animateHeartbeat = false,
		impact,
		label,
		maximum,
		paused = false,
		value = null,
	} = props;

	return (
		<div className="header-score">
			<ScoreInfo
				animateHeartbeat={animateHeartbeat}
				impact={impact}
				label={label}
				maximum={maximum}
				paused={paused}
				value={value}
			/>
		</div>
	);
};



export default HeaderScoreInfo;
