import {
	useUserMembershipsQuery,
} from './useUserMemberships.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserMemberships(legacyUserId: string | null) {
	const { data } = useUserPropertiesQuery(
		useUserMembershipsQuery,
		legacyUserId,
		{
			poll: true,
		},
	);

	return data?.user?.memberships ?? null;
}



export default useUserMemberships;
