import React from 'react';



type Props = {
	children: React.ReactNode,
	/** Optional title */
	title?: React.ReactNode,
};



const Terminal: React.FC<Props> = (props) => {
	const {
		title,
	} = props;

	const children = React.Children.toArray(props.children);
	const elements = children.filter((child) => !!child);

	return (
		<section className="terminal">
			{title && (
				<div className="terminal__title">
					{title}
				</div>
			)}
			<div
				className="terminal__body"
				style={{
					['--font-size' as any]: '12px',
				}}
			>
				{React.Children.map(
					elements,
					(child, index) => {
						return (
							<div
								className="terminal__row"
								key={'terminal-row-' + index}
							>
								{child}
							</div>
						);
					},
				)}
			</div>
		</section>
	);
};



export default Terminal;
