import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const UnisexIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M12.225,18.362a.5.5,0,0,0,.393.2h2.3a.247.247,0,0,1,.243.2l.921,4.847v.007a.5.5,0,0,0,.484.388h2.969a.5.5,0,0,0,.483-.388v-.007l.921-4.847a.248.248,0,0,1,.243-.2h2.321a.5.5,0,0,0,.478-.626c-1.965-7.162-1.88-7.546-3.007-8.6a.5.5,0,0,0-.757.1L18.482,12.16a.5.5,0,0,1-.837,0L15.923,9.411a.5.5,0,0,0-.755-.1c-1.154,1.063-1.066,1.47-3.027,8.621A.493.493,0,0,0,12.225,18.362Z"
				fill={color}
			/>
			<path
				d="M13.834,4.206A4.207,4.207,0,1,0,18.041,0,4.211,4.211,0,0,0,13.834,4.206Zm4.207,2.227A2.222,2.222,0,0,1,15.9,4.81a.247.247,0,0,1,.158-.3,4.63,4.63,0,0,0,1.8-1.128.247.247,0,0,1,.353,0,4.615,4.615,0,0,0,1.8,1.128.248.248,0,0,1,.157.3A2.224,2.224,0,0,1,18.041,6.433Z"
				fill={color}
			/>
			<path
				d="M.5,18.064h1.79a.248.248,0,0,1,.247.227l.438,5.255A.494.494,0,0,0,3.463,24H7.42a.5.5,0,0,0,.493-.454l.438-5.255a.247.247,0,0,1,.246-.227h1.791a.5.5,0,0,0,.495-.5V14.107A5.442,5.442,0,1,0,0,14.107v3.462A.494.494,0,0,0,.5,18.064Z"
				fill={color}
			/>
			<path
				d="M5.441,0A4.2,4.2,0,1,0,9.646,4.205,4.209,4.209,0,0,0,5.441,0Zm0,6.431A2.229,2.229,0,0,1,3.215,4.205a2.132,2.132,0,0,1,.157-.8.245.245,0,0,1,.152-.143.247.247,0,0,1,.207.024,5.313,5.313,0,0,0,2.8.792,5.332,5.332,0,0,0,.9-.076.2.2,0,0,1,.237.2A2.228,2.228,0,0,1,5.441,6.431Z"
				fill={color}
			/>
			<path
				d="M12.225,18.362a.5.5,0,0,0,.393.2h2.3a.247.247,0,0,1,.243.2l.921,4.847v.007a.5.5,0,0,0,.484.388h2.969a.5.5,0,0,0,.483-.388v-.007l.921-4.847a.248.248,0,0,1,.243-.2h2.321a.5.5,0,0,0,.478-.626c-1.965-7.162-1.88-7.546-3.007-8.6a.5.5,0,0,0-.757.1L18.482,12.16a.5.5,0,0,1-.837,0L15.923,9.411a.5.5,0,0,0-.755-.1c-1.154,1.063-1.066,1.47-3.027,8.621A.493.493,0,0,0,12.225,18.362Z"
				fill={color}
			/>
			<path
				d="M13.834,4.206A4.207,4.207,0,1,0,18.041,0,4.211,4.211,0,0,0,13.834,4.206Zm4.207,2.227A2.222,2.222,0,0,1,15.9,4.81a.247.247,0,0,1,.158-.3,4.63,4.63,0,0,0,1.8-1.128.247.247,0,0,1,.353,0,4.615,4.615,0,0,0,1.8,1.128.248.248,0,0,1,.157.3A2.224,2.224,0,0,1,18.041,6.433Z"
				fill={color}
			/>
			<path
				d="M.5,18.064h1.79a.248.248,0,0,1,.247.227l.438,5.255A.494.494,0,0,0,3.463,24H7.42a.5.5,0,0,0,.493-.454l.438-5.255a.247.247,0,0,1,.246-.227h1.791a.5.5,0,0,0,.495-.5V14.107A5.442,5.442,0,1,0,0,14.107v3.462A.494.494,0,0,0,.5,18.064Z"
				fill={color}
			/>
			<path
				d="M5.441,0A4.2,4.2,0,1,0,9.646,4.205,4.209,4.209,0,0,0,5.441,0Zm0,6.431A2.229,2.229,0,0,1,3.215,4.205a2.132,2.132,0,0,1,.157-.8.245.245,0,0,1,.152-.143.247.247,0,0,1,.207.024,5.313,5.313,0,0,0,2.8.792,5.332,5.332,0,0,0,.9-.076.2.2,0,0,1,.237.2A2.228,2.228,0,0,1,5.441,6.431Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default UnisexIconBuilder;

