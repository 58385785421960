import Immutable from 'immutable';

/**
 * Utility for getting a new checked items when doing multiselection in some grid
 *
 * @param {Array} grid Grid of boolean values representing current checked states. First level represents rows. Second inner level represents columns.
 * @param {Object} firstClickedCoordinates Coordinates of first click (start of defined range).
 * @param {Object} secondClickedCoordinates Coordinates of second click (end of defined range).
 * @param {Boolean} newCheckedStatus New state which will be set to all items inside defined range.
 * @return {Array} Grid of boolean values representing new checked states.
 */

export function gridMultiselection(grid, firstClickedCoordinates, secondClickedCoordinates, newCheckedStatus) {
	let firstCoordinates = firstClickedCoordinates;
	let secondCoordinates = secondClickedCoordinates;

	// order coordinates by its value (smaller value will be always first first)
	if (firstClickedCoordinates.rowIndex > secondClickedCoordinates.rowIndex) {
		firstCoordinates = secondClickedCoordinates;
		secondCoordinates = firstClickedCoordinates;
	} else if (firstClickedCoordinates.rowIndex == secondClickedCoordinates.rowIndex && firstClickedCoordinates.columnIndex > secondClickedCoordinates.columnIndex) {
		firstCoordinates = secondClickedCoordinates;
		secondCoordinates = firstClickedCoordinates;
	}

	const columnsRange = [
		firstCoordinates.columnIndex,
		secondCoordinates.columnIndex,
	];

	let currentColumnIndex = firstCoordinates.columnIndex;
	const newGrid = Immutable.fromJS(grid).toJS();

	for (let rowIndex = firstCoordinates.rowIndex; rowIndex <= secondCoordinates.rowIndex; rowIndex++) {
		// in last row we don't want to proceed whole columns in grid
		const maxColumnIndex = rowIndex < secondCoordinates.rowIndex ? Math.max(...columnsRange) : secondCoordinates.columnIndex;

		while (currentColumnIndex <= maxColumnIndex) {
			newGrid[rowIndex][currentColumnIndex] = newCheckedStatus;

			currentColumnIndex++;
		}

		currentColumnIndex = Math.min(...columnsRange);
	}

	return newGrid;
}



export function gridIntersection(grid, firstClickedCoordinates, secondClickedCoordinates) {
	let firstCoordinates = firstClickedCoordinates;
	let secondCoordinates = secondClickedCoordinates;

	// order coordinates by its value (smaller value will be always first first)
	if (firstClickedCoordinates.rowIndex > secondClickedCoordinates.rowIndex) {
		firstCoordinates = secondClickedCoordinates;
		secondCoordinates = firstClickedCoordinates;
	} else if (firstClickedCoordinates.rowIndex == secondClickedCoordinates.rowIndex && firstClickedCoordinates.columnIndex > secondClickedCoordinates.columnIndex) {
		firstCoordinates = secondClickedCoordinates;
		secondCoordinates = firstClickedCoordinates;
	}

	const columnsRange = [
		firstCoordinates.columnIndex,
		secondCoordinates.columnIndex,
	];

	let currentColumnIndex = firstCoordinates.columnIndex;
	const intersection = [];

	for (let rowIndex = firstCoordinates.rowIndex; rowIndex <= secondCoordinates.rowIndex; rowIndex++) {
		// in last row we don't want to proceed whole columns in grid
		const maxColumnIndex = rowIndex < secondCoordinates.rowIndex ? Math.max(...columnsRange) : secondCoordinates.columnIndex;

		while (currentColumnIndex <= maxColumnIndex) {
			if (!intersection[rowIndex]) {
				intersection[rowIndex] = [];
			}

			intersection[rowIndex][currentColumnIndex] = true;

			currentColumnIndex++;
		}

		currentColumnIndex = Math.min(...columnsRange);
	}

	return intersection;
}
