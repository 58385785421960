import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ContentIconBuilder: IconBuilder = {
	icon: ({ color = '#2AA6E3' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeWidth="1"
			>
				<g id="document1-copy">
					<polygon
						id="Stroke-681"
						points="24.001 26 8.001 26 8.001 6 24.001 6"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polygon>
					<path
						d="M11.0006,9.6 L21.0006,9.6"
						id="Stroke-682"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M11.0006,12.8 L21.0006,12.8"
						id="Stroke-683"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M11.0006,16 L21.0006,16"
						id="Stroke-684"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M11.0006,19.2 L17.0006,19.2"
						id="Stroke-685"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default ContentIconBuilder;
