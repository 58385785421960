import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const ButtonNumericValue: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<span className="button-numeric-value">
			{children}
		</span>
	);
};



export default ButtonNumericValue;
