/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExtractEmailsFromFreeInputMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  sessionId: GraphQL.Scalars['String']['input'];
  freeInput: GraphQL.Scalars['String']['input'];
}>;


export type ExtractEmailsFromFreeInputMutation = { readonly __typename?: 'Mutation', readonly StageEmailsForValidationFromFreeInput: { readonly __typename?: 'StageEmailsForValidationFromFreeInputResult', readonly remainingFreeInputText: string, readonly stagingArea: { readonly __typename?: 'StagingAreaOfInvitationsValidationSession', readonly failedEmails: ReadonlyArray<{ readonly __typename?: 'InvitationsValidationFailedEmail', readonly email: string, readonly failureReason: GraphQL.InvitationValidationFailure }>, readonly finishedEmails: ReadonlyArray<{ readonly __typename?: 'InvitationsValidationFinishedEmail', readonly email: string }>, readonly pendingEmails: ReadonlyArray<{ readonly __typename?: 'InvitationsValidationPendingEmail', readonly email: string }> } } };


export const ExtractEmailsFromFreeInputDocument = gql`
    mutation ExtractEmailsFromFreeInput($accountId: AccountId!, $sessionId: String!, $freeInput: String!) {
  StageEmailsForValidationFromFreeInput(
    accountId: $accountId
    sessionId: $sessionId
    bulkEmailsFreeInput: $freeInput
  ) {
    stagingArea {
      failedEmails {
        email
        failureReason
      }
      finishedEmails {
        email
      }
      pendingEmails {
        email
      }
    }
    remainingFreeInputText
  }
}
    `;
export type ExtractEmailsFromFreeInputMutationFn = Apollo.MutationFunction<ExtractEmailsFromFreeInputMutation, ExtractEmailsFromFreeInputMutationVariables>;

/**
 * __useExtractEmailsFromFreeInputMutation__
 *
 * To run a mutation, you first call `useExtractEmailsFromFreeInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractEmailsFromFreeInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractEmailsFromFreeInputMutation, { data, loading, error }] = useExtractEmailsFromFreeInputMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionId: // value for 'sessionId'
 *      freeInput: // value for 'freeInput'
 *   },
 * });
 */
export function useExtractEmailsFromFreeInputMutation(baseOptions?: Apollo.MutationHookOptions<ExtractEmailsFromFreeInputMutation, ExtractEmailsFromFreeInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtractEmailsFromFreeInputMutation, ExtractEmailsFromFreeInputMutationVariables>(ExtractEmailsFromFreeInputDocument, options);
      }
export type ExtractEmailsFromFreeInputMutationHookResult = ReturnType<typeof useExtractEmailsFromFreeInputMutation>;
export type ExtractEmailsFromFreeInputMutationResult = Apollo.MutationResult<ExtractEmailsFromFreeInputMutation>;
export type ExtractEmailsFromFreeInputMutationOptions = Apollo.BaseMutationOptions<ExtractEmailsFromFreeInputMutation, ExtractEmailsFromFreeInputMutationVariables>;