import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BackButton from '~/components/app/BackButton';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import CustomerDetailsFields, {
	validateAddress,
	validateCityAndPostalCode,
	validateCountry,
	validateEmail,
	validateName,
	validateState,
	validateVatNumber,
} from '~/components/app/CustomerDetailsFields';
import Form from '~/components/atoms/forms/basis/Form';
import MarginsList, {
	SIZE_LARGE as MARGINS_SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import PaymentServicesList from '~/components/atoms/lists/PaymentServicesList';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useStoreSignupCustomerDetailsMutation,
} from './CustomerDetailsSignupModalStep.gql';

import useAccountSignup from '~/hooks/useAccountSignup';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	saveButtonWithoutInvoiceDetails: {
		id: 'ui.upgradeToPaid.billingDetailsStep.saveButton',
	},
	saveButtonWithInvoiceDetails: {
		id: 'ui.upgradeToPaid.billingDetailsStep.saveButtonWithInvoiceDetails',
	},
});

const validations = {
	validateAddress,
	validateCityAndPostalCode,
	validateCountry,
	validateEmail,
	validateName,
	validateState,
	validateVatNumber,
};



type Props = {
	accountId: CK.AccountId,
	hasInvoiceDetailsStep: boolean,
	onCancelCallback: () => void,
	onSuccessCallback: () => void,
	setVatNumberAssumption: React.Dispatch<React.SetStateAction<boolean>>,
	showBack: boolean,
};

const CustomerDetailsSignupModalStep: React.FC<Props> = (props) => {
	const {
		accountId,
		hasInvoiceDetailsStep,
		onCancelCallback,
		onSuccessCallback,
		setVatNumberAssumption,
		showBack,
	} = props;

	const accountSignup = useAccountSignup(accountId);
	const accountSignupSetProperty = accountSignup.setProperty;

	const [storeSignupCustomerDetails] = useStoreSignupCustomerDetailsMutation();

	const handleBlur = React.useCallback(
		(field) => {
			if (field === 'vatNumber') {
				setVatNumberAssumption(false);
			}
		},
		[
			setVatNumberAssumption,
		],
	);

	const handleChange = React.useCallback(
		(field, value) => {
			if (field === 'email') {
				accountSignupSetProperty(
					'email',
					value,
				);
			} else {
				accountSignupSetProperty(
					'customerDetails',
					(customerDetails) => ({
						...customerDetails,
						[field]: value,
					}),
				);
			}
		},
		[
			accountSignupSetProperty,
		],
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				await storeSignupCustomerDetails({
					variables: {
						accountId,
						customerDetails: {
							address: {
								address: values.address,
								city: values.city,
								country: values.country,
								name: values.name,
								postalCode: values.postalCode,
								state: values.state || null,
							},
							email: values.email,
							vatNumber: values.vatNumber || null,
						},
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error, {
					invalidVatNumber: values.vatNumber,
				});
			}

			setVatNumberAssumption(false);

			onSuccessCallback();
		},
		[
			accountId,
			onSuccessCallback,
			setVatNumberAssumption,
			storeSignupCustomerDetails,
		],
	);

	return (
		<MarginsList size={MARGINS_SIZE_LARGE}>
			<CenteredFormWrapper>
				<Form
					clearOnFieldUnmount={true}
					defaultDataHasChanged={true}
					defaultFocus=""
					defaultValues={{
						address: accountSignup.customerDetails.address ?? '',
						city: accountSignup.customerDetails.city ?? '',
						country: accountSignup.customerDetails.country ?? accountSignup.defaultCountry ?? undefined,
						email: accountSignup.email ?? null,
						name: accountSignup.customerDetails.name ?? '',
						postalCode: accountSignup.customerDetails.postalCode ?? '',
						state: accountSignup.customerDetails.state ?? undefined,
						vatNumber: accountSignup.customerDetails.vatNumber ?? null,
					}}
					onBlurCallback={handleBlur}
					onChangeCallback={handleChange}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<div
						style={{
							minHeight: 320,
						}}
					>
						<CustomerDetailsFields />
					</div>

					<ButtonsLayout>
						{showBack && (
							<BackButton onClickCallback={onCancelCallback} />
						)}

						{!showBack && (
							<CancelButton onClickCallback={onCancelCallback} />
						)}

						<SubmitButton nextStepIcon={true}>
							<FormattedMessage {...messages[hasInvoiceDetailsStep ? 'saveButtonWithInvoiceDetails' : 'saveButtonWithoutInvoiceDetails']} />
						</SubmitButton>
					</ButtonsLayout>
				</Form>
			</CenteredFormWrapper>

			<PaymentServicesList />
		</MarginsList>
	);
};



export default CustomerDetailsSignupModalStep;
