import React from 'react';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useManageRoyalAccessMutation,
} from './AdminUserRoyalAccessForm.gql';

import useUserRoyalGroup from '~/hooks/useUserRoyalGroup';



const royalGroupOptions = Object.values(GraphQL.RoyalGroup).map((royalGroup) => ({
	label: royalGroup,
	name: royalGroup,
}));



type Props = {
	userId: string | null,
};

const AdminUserRoyalAccessForm: React.FC<Props> = (props) => {
	const {
		userId,
	} = props;

	const userRoyalGroup = useUserRoyalGroup(userId);

	const [manageRoyalAccess] = useManageRoyalAccessMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (userId === null) {
				throw new Error(
					`userId can't be null`,
				);
			}

			await manageRoyalAccess({
				variables: {
					legacyUserId: userId,
					royalGroup: values.royalGroup,
				},
			});
		},
		[
			manageRoyalAccess,
			userId,
		],
	);

	return (
		<EditableFormWrapper
			isForAdmins={true}
			title="Royal access"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Royal group">
						<StaticText focusTarget="royalGroup">
							{userRoyalGroup ?? ''}
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						royalGroup: userRoyalGroup,
					}}
					onSuccess={handleSubmit}
				>
					<FormRows>
						<FormRow
							htmlFor="royalGroup"
							label="Royal group"
						>
							<SelectField
								name="royalGroup"
								options={royalGroupOptions}
							/>
						</FormRow>
					</FormRows>

					<FormErrorMessages
						errors={{
							royalGroupNotAllowedForUpdate: `Selected royal group can't be granted via app.`,
						}}
					/>

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default AdminUserRoyalAccessForm;
