import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import FlexibleBlocks from '~/components/patterns/structures/FlexibleBlocks';
import MembersOverviewGridCard from '~/components/app/MembersOverviewGridCard';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserEmail from '~/hooks/useUserEmail';



type Props = {
	accountId: CK.AccountId | null,
	isLoading: boolean,
	members: ReadonlyArray<{
		__typename?: 'Invitation',
		displayName: GraphQL.Invitation['displayName'],
		email: GraphQL.Invitation['email'],
		id: GraphQL.Invitation['id'],
	} | {
		__typename?: 'User',
		displayName: GraphQL.User['displayName'],
		email: GraphQL.User['email'],
		id: GraphQL.User['id'],
		isChromeExtensionInstalled: GraphQL.User['isChromeExtensionInstalled'],
		membership: {
			hasLimitedWebsitesAccess: boolean,
			role: GraphQL.UserRole,
			websites: ReadonlyArray<{
				id: CK.WebsiteId,
			}>,
		},
	}>,
};

const MembersOverviewGrid: React.FC<Props> = (props) => {
	const {
		accountId,
		isLoading,
		members,
	} = props;

	const currentUserId = useCurrentUserId();

	const currentUserEmail = useUserEmail(currentUserId);

	if (isLoading || accountId === null) {
		return null;
	}

	// logged user needs to be always first
	const loggedInMembers = members.filter((member) => member.email === currentUserEmail);
	// rest of users are sorted alphabetically
	const otherMembers = members.filter((member) => member.email !== currentUserEmail).sort((memberA, memberB) => {
		if (memberA.displayName.toLowerCase() < memberB.displayName.toLowerCase()) {
			return -1;
		}

		if (memberA.displayName.toLowerCase() > memberB.displayName.toLowerCase()) {
			return 1;
		}

		return 0;
	});

	return (
		<FlexibleBlocks>
			{loggedInMembers.concat(otherMembers).map((member) => (
				<MembersOverviewGridCard
					accountId={accountId}
					currentUserEmail={currentUserEmail}
					key={member.id}
					member={member}
				/>
			))}
		</FlexibleBlocks>
	);
};



export default MembersOverviewGrid;
