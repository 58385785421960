import {
	parseISO,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';

import {
	usePageTypeLastChangedAtQuery,
} from './usePageTypeLastChangedAt.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageTypeLastChangedAt(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageTypeLastChangedAtQuery,
		legacyUrlId,
		websiteId,
	);

	const pageTypeLastChangedAt = data?.lookupPageByLegacyId?.pageTypeLastChangedAt ?? null;

	return React.useMemo(
		() => {
			if (pageTypeLastChangedAt === null) {
				return null;
			}

			return parseISO(pageTypeLastChangedAt);
		},
		[
			pageTypeLastChangedAt,
		],
	);
}



export default usePageTypeLastChangedAt;
