import React from 'react';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import EmailDomainNotWhitelistedErrorMessage from '~/components/logic/errorMessages/EmailDomainNotWhitelistedErrorMessage';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InternalLink from '~/components/patterns/links/InternalLink';
import MultiselectField from '~/components/atoms/forms/components/MultiselectField';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';
import TextField, {
	TextFieldAutocomplete,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useAdministerUserSettingsMutation,
} from './AdminUserSettingsForm.gql';

import useAccountId from '~/hooks/useAccountId';
import useAccountOrganizationTeams from '~/hooks/useAccountOrganizationTeams';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountUserRestrictions from '~/hooks/useAccountUserRestrictions';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useUserEmail from '~/hooks/useUserEmail';
import useUserIsAlertRecipientByDefault from '~/hooks/useUserIsAlertRecipientByDefault';
import useUserIsReportRecipientByDefault from '~/hooks/useUserIsReportRecipientByDefault';
import useUserMainAccountId from '~/hooks/useUserMainAccountId';
import useUserOrganizationTeam from '~/hooks/useUserOrganizationTeam';
import useUserPlatformTourWebinarOffering from '~/hooks/useUserPlatformTourWebinarOffering';
import useUserReviewSites from '~/hooks/useUserReviewSites';

import {
	isEmailWhitelisted,
} from '~/model/users';

import FormError from '~/utilities/FormError';



const reviewSitesOptions = [
	{
		title: 'Positive NPS (8-10)',
		name: 'positive-nps',
	},
	{
		title: 'Capterra',
		name: 'capterra',
	},
	{
		title: 'G2',
		name: 'g2',
	},
	{
		title: 'Google',
		name: 'google',
	},
	{
		title: 'TrustRadius',
		name: 'trustradius',
	},
	{
		title: 'Product Hunt',
		name: 'producthunt',
	},
];



type Props = {
	isForCurrentUser: boolean,
	legacyUserId: string | null,
};

const AdminUserSettingsForm: React.FC<Props> = (props) => {
	const {
		isForCurrentUser,
		legacyUserId,
	} = props;

	const accountId = useAccountId();
	const homeAccountId = useEffectiveHomeAccountId();
	const effectiveAccountId = isForCurrentUser
		? homeAccountId
		: accountId;

	const accountPhase = useAccountPhase(effectiveAccountId);
	const accountUserRestrictions = useAccountUserRestrictions(effectiveAccountId);
	const accountOrganizationTeams = useAccountOrganizationTeams(effectiveAccountId);
	const userEmail = useUserEmail(legacyUserId);
	const userIsAlertRecipientByDefault = useUserIsAlertRecipientByDefault(legacyUserId);
	const userIsReportRecipientByDefault = useUserIsReportRecipientByDefault(legacyUserId);
	const userMainAccountId = useUserMainAccountId(legacyUserId);
	const userOrganizationTeam = useUserOrganizationTeam(legacyUserId);
	const userPlatformTourWebinarOffering = useUserPlatformTourWebinarOffering(legacyUserId);
	const userReviewSites = useUserReviewSites(legacyUserId);

	const [organizationTeamInput, setOrganizationTeamInput] = React.useState<'select' | 'text'>('select');

	const [administerUserSettings] = useAdministerUserSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (legacyUserId === null) {
				throw new Error(
					`legacyUserId can't be null`,
				);
			}

			try {
				await administerUserSettings({
					variables: {
						isAlertRecipientByDefault: values.isAlertRecipientByDefault,
						isReportRecipientByDefault: values.isReportRecipientByDefault,
						legacyUserId,
						mainAccountId: values.mainAccountId,
						newEmail: isForCurrentUser
							? (values.newEmail?.trim() || null)
							: null,
						organizationTeam: values.organizationTeam || null,
						platformTourWebinarOffering: accountPhase === GraphQL.AccountPhase.Trial
							? values.platformTourWebinarOffering
							: null,
						reviewSites: values.reviewSites,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error, {
					foreignMainAccountId: values.mainAccountId,
				});
			}

			setOrganizationTeamInput('select');
		},
		[
			accountPhase,
			administerUserSettings,
			isForCurrentUser,
			legacyUserId,
		],
	);

	const validations = React.useMemo(
		() => ({
			validateNewEmail: validateField(
				'newEmail',
				(f) => [
					f.when(() => isForCurrentUser),
					f.validateNonEmpty(),
					f.validateEmail(),
					f.custom({
						message: (
							<EmailDomainNotWhitelistedErrorMessage
								accountId={effectiveAccountId}
							/>
						),
						rule: ({ value }) => isEmailWhitelisted(
							value,
							accountUserRestrictions?.emailDomainsWhitelist ?? [],
						),
					}),
				],
			),
			validateMainAccountId: validateField(
				'mainAccountId',
				(f) => [
					f.validateNonEmpty(),
					f.validateInteger(),
					f.customGlobal({
						message: `Main account ID must be of one of user's home accounts`,
						globalRule: 'foreignMainAccountId',
					}),
				],
			),
		}),
		[
			accountUserRestrictions,
			effectiveAccountId,
			isForCurrentUser,
		],
	);

	function formatOfferWebinar(webinarOffering) {
		if (webinarOffering === 'auto') {
			return 'Automatic';
		} else if (webinarOffering === 'registered') {
			return 'Registered';
		} else if (webinarOffering === 'no') {
			return 'No';
		} else if (webinarOffering === 'yes') {
			return 'Yes';
		}

		return '';
	}

	function getOptionsLabels(
		options: ReadonlyArray<{
			name: string,
			title: string,
		}>,
		values: ReadonlyArray<string> | null,
	) {
		if (values === null || values.length === 0) {
			return ['none'];
		}

		return options
			.filter((option) => values.includes(option.name))
			.map((option) => option.title);
	}

	return (
		<EditableFormWrapper
			isForAdmins={true}
			title="User Settings"
		>
			<DisplayPart>
				<FormRows>
					{isForCurrentUser && (
						<FormRow label="Email">
							<StaticText focusTarget="newEmail">
								{userEmail}
							</StaticText>
						</FormRow>
					)}

					<FormRow label="Main account ID">
						<StaticText focusTarget="mainAccountId">
							{userMainAccountId}
						</StaticText>
					</FormRow>

					<FormRow label="Team">
						<StaticText focusTarget="organizationTeam">
							{userOrganizationTeam ?? ''}
						</StaticText>
					</FormRow>

					<FormRow label="Reviewed on">
						<StaticList focusTarget="reviewSites">
							{getOptionsLabels(reviewSitesOptions, userReviewSites)}
						</StaticList>
					</FormRow>

					{accountPhase === GraphQL.AccountPhase.Trial && (
						<FormRow label="Offer webinar">
							<StaticText focusTarget="platformTourWebinarOffering">
								{formatOfferWebinar(userPlatformTourWebinarOffering)}
							</StaticText>
						</FormRow>
					)}

					<FormRow label="Receive alerts for newly added websites">
						<StaticList focusTarget="isAlertRecipientByDefault">
							{userIsAlertRecipientByDefault ? 'yes' : 'no'}
						</StaticList>
					</FormRow>

					<FormRow label="Receive reports for newly added websites">
						<StaticList focusTarget="isReportRecipientByDefault">
							{userIsReportRecipientByDefault ? 'yes' : 'no'}
						</StaticList>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						isAlertRecipientByDefault: userIsAlertRecipientByDefault,
						isReportRecipientByDefault: userIsReportRecipientByDefault,
						mainAccountId: userMainAccountId,
						newEmail: userEmail,
						organizationTeam: userOrganizationTeam,
						platformTourWebinarOffering: userPlatformTourWebinarOffering,
						reviewSites: userReviewSites,
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<FormRows>
						{isForCurrentUser && (
							<FormRow
								htmlFor="newEmail"
								label="Email"
							>
								<FieldStatus name="validateNewEmail">
									<TextField
										autoComplete={TextFieldAutocomplete.Off}
										name="newEmail"
										trimValue={true}
										type={TextFieldType.Email}
									/>
								</FieldStatus>
							</FormRow>
						)}

						<FormRow
							description="Account ID of the account into which we sync this user to Intercom, Mailchimp, and other integrations. This means all user-level properties such as role and limited website access are also reported there in the context of this account."
							htmlFor="mainAccountId"
							label="Main Account ID"
						>
							<FieldStatus name="validateMainAccountId">
								<TextField name="mainAccountId" />
							</FieldStatus>
						</FormRow>

						<FormRow
							description={(
								<>
									{organizationTeamInput === 'select' && (
										<InternalLink onClickCallback={() => setOrganizationTeamInput('text')}>
											Add another team
										</InternalLink>
									)}

									{organizationTeamInput === 'text' && (
										<InternalLink onClickCallback={() => setOrganizationTeamInput('select')}>
											Select team
										</InternalLink>
									)}
								</>
							)}
							htmlFor="organizationTeam"
							label="Team"
						>
							<FieldStatus name="validateOrganizationTeam">
								{organizationTeamInput === 'select' && (
									<SelectField
										name="organizationTeam"
										options={accountOrganizationTeams?.map((team) => ({
											label: team,
											name: team,
										})) ?? []}
										searchable={accountOrganizationTeams !== null && accountOrganizationTeams.length > 10}
									/>
								)}

								{organizationTeamInput === 'text' && (
									<TextField name="organizationTeam" />
								)}
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="reviewSites"
							label="Reviewed on"
						>
							<MultiselectField
								name="reviewSites"
								options={reviewSitesOptions}
							/>
						</FormRow>

						{accountPhase === GraphQL.AccountPhase.Trial && (
							<FormRow
								htmlFor="platformTourWebinarOffering"
								label="Offer webinar"
							>
								<SelectField
									name="platformTourWebinarOffering"
									options={[
										GraphQL.PlatformTourWebinarOffering.Yes,
										GraphQL.PlatformTourWebinarOffering.No,
										GraphQL.PlatformTourWebinarOffering.Auto,
										GraphQL.PlatformTourWebinarOffering.Registered,
									].map((option) => ({
										label: formatOfferWebinar(option),
										name: option,
									}))}
								/>
							</FormRow>
						)}

						<FormRow
							htmlFor="isAlertRecipientByDefault"
							label="Receive alerts for newly added websites"
						>
							<SwitchField
								activeStateLabel="yes"
								inactiveStateLabel="no"
								name="isAlertRecipientByDefault"
							/>
						</FormRow>

						<FormRow
							htmlFor="isReportRecipientByDefault"
							label="Receive reports for newly added websites"
						>
							<SwitchField
								activeStateLabel="yes"
								inactiveStateLabel="no"
								name="isReportRecipientByDefault"
							/>
						</FormRow>
					</FormRows>

					<FormErrorMessages />

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default AdminUserSettingsForm;
