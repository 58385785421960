import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';
import {
	defineMessages,
	injectIntl,
} from 'react-intl';

import DonutPagesChart from '../components/DonutPagesChart';



const messages = defineMessages({
	indexable: {
		no: {
			id: 'ui.contentOverview.indexable.no',
		},
		not_applicable: {
			id: 'ui.general.notApplicable',
		},
		yes: {
			id: 'ui.contentOverview.indexable.yes',
		},
	},
});



class IndexableChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		const {
			intl,
		} = this.props;

		this.labels = {
			no: intl.formatMessage(messages.indexable.no),
			not_applicable: intl.formatMessage(messages.indexable.not_applicable),
			yes: intl.formatMessage(messages.indexable.yes),
		};

		this.order = [
			'yes',
			'no',
			'not_applicable',
		];

		this.specificColors = {
			no: '#FF743B',
			not_applicable: '#8595A6',
			yes: '#42CC67',
		};
	}



	render() {
		const {
			data,
			field,
		} = this.props;

		return (
			<DonutPagesChart
				data={data}
				labels={this.labels}
				name={field}
				order={this.order}
				percentageFactor={100}
				specificColors={this.specificColors}
			/>
		);
	}

}

IndexableChart.propTypes = {
	data: PropTypes.instanceOf(List),
	field: PropTypes.string.isRequired,
};



export default injectIntl(IndexableChart);
