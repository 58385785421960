import React from 'react';

import AbstractSelectField, {
	type AbstractSelectFieldRef,
	AbstractSelectFieldSize,
} from '~/components/patterns/forms/fields/AbstractSelectField';
import CompareModePickerToggler from './CompareModePickerToggler.part';
import FieldDropdownSelectableOptions, {
	type FieldDropdownSelectableOptionsProps,
} from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdownSelectableOptions';



type Props = {
	dropdownWidth?: number,
	onChangeCallback: (name: string) => void,
	/** Option in the picker */
	options: Array<FieldDropdownSelectableOptionsProps>,
	width?: number,
};



const CompareModePicker: React.FC<Props> = (props) => {
	const {
		dropdownWidth,
		onChangeCallback,
		options,
		width = 195,
	} = props;

	const selectRef = React.useRef<AbstractSelectFieldRef | null>(null);

	const getSelectedModeLabel = () => {
		const selectedOption = options.filter((option) => !('divider' in option) && option.selected)[0];

		if (selectedOption !== undefined && !('divider' in selectedOption)) {
			return selectedOption.label;
		}

		return '-';
	};

	const handleOptionChange = (option) => {
		onChangeCallback(option);

		selectRef.current?.close();
	};

	return (
		<AbstractSelectField
			dropdownWidth={dropdownWidth || width + 5}
			label={getSelectedModeLabel()}
			labelRenderer={(label, isOpen) => (
				<CompareModePickerToggler
					isOpen={isOpen}
					label={label}
				/>
			)}
			ref={selectRef}
			size={AbstractSelectFieldSize.Small}
			width={width}
		>
			<FieldDropdownSelectableOptions
				onOptionChangeCallback={handleOptionChange}
				options={options}
			/>
		</AbstractSelectField>
	);
};



export default CompareModePicker;
