import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import GuidedTour from '~/components/patterns/tours/GuidedTour';
import RadioList from '~/components/atoms/forms/components/RadioList';
import SubmitButton from '~/components/app/SubmitButton';

import {
	validateField,
} from '~/components/app/validations';

import {
	useIsNumberOfEmployeesEnoughForOutOfBandQuery,
	useUpdateNumberOfEmployeesMutation,
} from '~/components/app/NumberOfEmployeesForm.gql';

import useAccountId from '~/hooks/useAccountId';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	insufficientForOutOfBand: {
		id: 'ui.numberOfEmployeesForm.insufficientForOutOfBand',
	},
	enoughForOutOfBand: {
		id: 'ui.numberOfEmployeesForm.enoughForOutOfBand',
	},
	nextButton: {
		id: 'ui.numberOfEmployeesForm.nextButton',
	},
	prompt: {
		id: 'ui.numberOfEmployeesForm.prompt',
	},
});

const validations = {
	validateIsNumberOfEmployeesEnoughForOutOfBand: validateField(
		'isNumberOfEmployeesEnoughForOutOfBand',
		(f) => ([
			f.validateBoolean(),
		]),
	),
};



const numberOfEmployeesOptions = [
	{
		label: (
			<FormattedMessage {...messages.insufficientForOutOfBand} />
		),
		value: false,
	},
	{
		label: (
			<FormattedMessage {...messages.enoughForOutOfBand} />
		),
		value: true,
	},
];



type Props = {
	description?: React.ReactElement,
	onSubmitCallback: () => void,
};

const NumberOfEmployeesForm: React.FC<Props> = (props) => {
	const {
		description,
		onSubmitCallback,
	} = props;

	const accountId = useAccountId();
	const classicFormBehavior = useClassicFormBehavior();
	const viewportType = useViewportType();

	const [updateNumberOfEmployees] = useUpdateNumberOfEmployeesMutation();
	const { data, loading } = useIsNumberOfEmployeesEnoughForOutOfBandQuery({
		variables: {
			accountId: accountId ?? 0,
		},
		skip: accountId === null,
	});

	const isNumberOfEmployeesEnoughForOutOfBand = data?.account?.isNumberOfEmployeesEnoughForOutOfBand;

	const handleSubmit = React.useCallback(
		async (values) => {
			if (accountId === null) {
				return;
			}

			await updateNumberOfEmployees({
				variables: {
					accountId,
					isNumberOfEmployeesEnoughForOutOfBand: values.isNumberOfEmployeesEnoughForOutOfBand,
				},
			});

			onSubmitCallback();

			classicFormBehavior.finish();
		},
		[
			accountId,
			classicFormBehavior,
			onSubmitCallback,
			updateNumberOfEmployees,
		],
	);

	return (
		<GuidedTour
			form={(
				<Form
					defaultDataHasChanged={true}
					defaultValues={{
						isNumberOfEmployeesEnoughForOutOfBand,
					}}
					key={loading ? 'loading' : 'ready'}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<FormRow fullwidth={viewportType.isSmall}>
						<RadioList
							items={numberOfEmployeesOptions}
							name="isNumberOfEmployeesEnoughForOutOfBand"
							width={false}
						/>
					</FormRow>

					<FormErrorMessages />

					<ButtonsLayout layout={ButtonsLayoutType.Steps}>
						<SubmitButton nextStepIcon={true}>
							<FormattedMessage {...messages.nextButton} />
						</SubmitButton>
					</ButtonsLayout>
				</Form>
			)}
		>
			{description}
			<FormattedMessage {...messages.prompt} />
		</GuidedTour>
	);
};



export default NumberOfEmployeesForm;
