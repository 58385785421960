/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddTrialAbuserEmailsMutationVariables = GraphQL.Exact<{
  emailPatterns: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type AddTrialAbuserEmailsMutation = { readonly __typename?: 'Mutation', readonly AddTrialAbuserEmails: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly kingdomSettings: { readonly __typename?: 'KingdomSettings', readonly trialAbuserEmails: ReadonlyArray<string> } | null } } };


export const AddTrialAbuserEmailsDocument = gql`
    mutation AddTrialAbuserEmails($emailPatterns: [String!]!) {
  AddTrialAbuserEmails(emailPatterns: $emailPatterns) {
    query {
      kingdomSettings {
        trialAbuserEmails
      }
    }
  }
}
    `;
export type AddTrialAbuserEmailsMutationFn = Apollo.MutationFunction<AddTrialAbuserEmailsMutation, AddTrialAbuserEmailsMutationVariables>;

/**
 * __useAddTrialAbuserEmailsMutation__
 *
 * To run a mutation, you first call `useAddTrialAbuserEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrialAbuserEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrialAbuserEmailsMutation, { data, loading, error }] = useAddTrialAbuserEmailsMutation({
 *   variables: {
 *      emailPatterns: // value for 'emailPatterns'
 *   },
 * });
 */
export function useAddTrialAbuserEmailsMutation(baseOptions?: Apollo.MutationHookOptions<AddTrialAbuserEmailsMutation, AddTrialAbuserEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrialAbuserEmailsMutation, AddTrialAbuserEmailsMutationVariables>(AddTrialAbuserEmailsDocument, options);
      }
export type AddTrialAbuserEmailsMutationHookResult = ReturnType<typeof useAddTrialAbuserEmailsMutation>;
export type AddTrialAbuserEmailsMutationResult = Apollo.MutationResult<AddTrialAbuserEmailsMutation>;
export type AddTrialAbuserEmailsMutationOptions = Apollo.BaseMutationOptions<AddTrialAbuserEmailsMutation, AddTrialAbuserEmailsMutationVariables>;