import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import IssueCardsList from '~/components/atoms/issues/components/lists/IssueCardsList';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import WebsiteIssueCategoryCard from './WebsiteIssueCategoryCard';
import WebsiteIssueCategoryCardSkeleton from '~/components/atoms/issues/components/categoryCards/WebsiteIssueCategoryCardSkeleton';

import useFilteredWebsiteScopeIssues from '~/hooks/useFilteredWebsiteScopeIssues';
import useSortedIssues from '~/hooks/useSortedIssues';

import {
	closeWebsiteIssueCategoryDetail,
	openWebsiteIssueCategoryDetail,
} from '~/actions/issuesScreen';

import {
	sortIssues,
} from '~/model/issuesNew';

import {
	issuesOverviewCategorySelector,
} from '~/state/ui/content/selectors';

import {
	fixScrolling,
} from '~/utilities/fixScrolling';

import {
	verticalScrollTo,
} from '~/utilities/scrollTo';



type Props = {
	numberOfPlaceholders: number,
	websiteId: CK.WebsiteId,
};

const WebsiteIssueCategoriesList: React.FC<Props> = (props) => {
	const {
		numberOfPlaceholders,
		websiteId,
	} = props;

	const dispatch = useDispatch();
	const issueCategories = useFilteredWebsiteScopeIssues(websiteId);
	const openedIssueCategoryName = useSelector(issuesOverviewCategorySelector);
	const sortedIssueCategories = useSortedIssues(issueCategories, sortIssues);

	const numberOfIssueCategories = issueCategories?.length;

	React.useEffect(
		() => {
			// fix scrolling on iOS touch devices
			fixScrolling();
		},
		[
			numberOfIssueCategories,
		],
	);

	const handleIssueCategoryBlur = React.useCallback(
		() => {
			dispatch(
				closeWebsiteIssueCategoryDetail(),
			);
		},
		[
			dispatch,
		],
	);
	const handleIssueCategoryFocus = React.useCallback(
		(issueCategoryName: string) => {
			setTimeout(() => {
				const element = document.querySelector('.js-scrollable-overlay');

				if (element) {
					verticalScrollTo(element, 0, 600);
				}
			}, 200);

			dispatch(
				openWebsiteIssueCategoryDetail(issueCategoryName),
			);
		},
		[
			dispatch,
		],
	);

	function renderContent() {
		if (issueCategories === null) {
			return [...Array.from({ length: numberOfPlaceholders }).keys()].map(
				(i) => (
					<WebsiteIssueCategoryCardSkeleton key={i} />
				),
			);
		}

		return sortedIssueCategories.map(
			(issueCategory) => (
				<WebsiteIssueCategoryCard
					isActive={issueCategory.name === openedIssueCategoryName}
					issueCategory={issueCategory}
					key={issueCategory.name}
					onBlurIssueCallback={handleIssueCategoryBlur}
					onFocusIssueCallback={handleIssueCategoryFocus}
				/>
			),
		);
	}

	return (
		<IssueCardsList
			animations={false}
			blankSlate={(
				<NoSearchResults />
			)}
		>
			{renderContent()}
		</IssueCardsList>
	);
};



export default WebsiteIssueCategoriesList;
