import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';



const messages = {
	numberOfPages: defineMessages({
		asc: {
			id: 'ui.segmentSizeLimit.typeDescription.numberOfPages.bottom',
		},
		desc: {
			id: 'ui.segmentSizeLimit.typeDescription.numberOfPages.top',
		},
	}),
	percentage: defineMessages({
		asc: {
			id: 'ui.segmentSizeLimit.typeDescription.percentage.bottom',
		},
		desc: {
			id: 'ui.segmentSizeLimit.typeDescription.percentage.top',
		},
	}),
};



type Props = {
	sizeLimitDefinition: CK.PageSegmentSizeLimit,
};

const SizeLimit: React.FC<Props> = (props) => {
	const {
		sizeLimitDefinition,
	} = props;

	const type = sizeLimitDefinition.limit.type;
	const order = sizeLimitDefinition.order;

	return (
		<FormattedMessage
			{...messages[type][order]}
			values={sizeLimitDefinition.limit}
		/>
	);
};



export default SizeLimit;
