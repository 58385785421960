import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const XmlSitemapIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeWidth="1"
			>
				<g id="network">
					<polygon
						id="Stroke-335"
						points="18.999 11 12.999 11 12.999 5 18.999 5"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polygon>
					<polygon
						id="Stroke-336"
						points="18.999 26 12.999 26 12.999 20 18.999 20"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polygon>
					<polygon
						id="Stroke-337"
						points="9.999 26 3.999 26 3.999 20 9.999 20"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polygon>
					<polygon
						id="Stroke-338"
						points="27.999 26 21.999 26 21.999 20 27.999 20"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polygon>
					<path
						d="M15.9994,11 L15.9994,20"
						id="Stroke-339"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<polyline
						id="Stroke-340"
						points="6.9988 20 6.9988 16 24.9988 16 24.9988 20"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</polyline>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default XmlSitemapIconBuilder;
