import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import AddressOverview from '~/components/app/AddressOverview';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';



const messages = defineMessages({
	email: {
		id: 'ui.newTeam.form.billingEmail',
	},
	vatNumber: {
		id: 'ui.newTeam.form.vat',
	},
});



type Props = {
	customerAddress: GraphQL.BillingDetailsAddress | null,
	emails: Readonly<Array<string>> | null,
	vatNumber: string | null,
};

const CustomerDetailsOverview: React.FC<Props> = (props) => {
	const {
		customerAddress,
		emails,
		vatNumber,
	} = props;

	return (
		<FormRows>
			<AddressOverview
				address={customerAddress}
			/>

			{vatNumber !== null && (
				<FormRow
					label={(
						<FormattedMessage {...messages.vatNumber} />
					)}
				>
					<StaticText focusTarget="vatNumber">
						{vatNumber}
					</StaticText>
				</FormRow>
			)}

			<FormRow
				label={(
					<FormattedMessage {...messages.email} />
				)}
			>
				<StaticList focusTarget="email">
					{emails ?? ['-']}
				</StaticList>
			</FormRow>
		</FormRows>
	);
};



export default CustomerDetailsOverview;
