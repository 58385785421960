import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import EmptyValue from '~/components/app/EmptyValue';
import MissingValue from '~/components/app/MissingValue';
import Ellipsis from '~/components/patterns/values/Ellipsis';



const messages = defineMessages({
	emptyLabel: {
		id: 'ui.general.empty',
	},
	missingLabel: {
		id: 'ui.general.missing',
	},
});



type Props = {
	/** When we show ellipsis, we will take hint message from original value. Using altPopupText we can set custom hint message. */
	altPopupText?: React.ReactNode,
	/** Possibility to disable placeholder for blank values */
	blankPlaceholder?: boolean,
	/** Value in cell */
	value: React.ReactNode,
	/** Custom z-index value for hint */
	zIndex?: number,
};

const TextualCellValue: React.FC<Props> = (props) => {
	const {
		altPopupText,
		blankPlaceholder = true,
		value,
		zIndex,
	} = props;

	let content = value;
	const additionalAttrs = {};

	if (blankPlaceholder && (value === null || value === undefined)) {
		content = (
			<MissingValue ellipsis={true} />
		);

		additionalAttrs['popup'] = (
			<FormattedMessage {...messages.missingLabel} />
		);
	}

	if (blankPlaceholder && value === '') {
		content = (
			<EmptyValue ellipsis={true} />
		);

		additionalAttrs['popup'] = (
			<FormattedMessage {...messages.emptyLabel} />
		);
	}

	if (zIndex) {
		additionalAttrs['popupZIndex'] = zIndex;
	}

	if (altPopupText) {
		additionalAttrs['altPopupText'] = altPopupText;
	}

	return (
		<Ellipsis {...additionalAttrs}>
			{content}
		</Ellipsis>
	);
};



export default TextualCellValue;
