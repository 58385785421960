import {
	Map,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import CK from '~/types/contentking';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import ChartLayout from '~/components/patterns/charts/layouts/ChartLayout';
import ColumnCategoryName from '../../../../names/ColumnCategoryName';
import ColumnName from '../../../../names/ColumnName';
import FlexibleBlocks from '~/components/patterns/structures/FlexibleBlocks';
import HealthLabel from '~/components/patterns/structuredValues/HealthLabel';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import WebVitalName from '~/components/app/WebVitalName';

import BooleanChart from '../fields/BooleanChart';
import CanonicalTypeChart from '../fields/CanonicalTypeChart';
import ContentPresenceChart from '../fields/ContentPresenceChart';
import DomainChart from '../fields/DomainChart';
import HealthChart from '../fields/HealthChart';
import HreflangLanguageChart from '../fields/HreflangLanguageChart';
import IndexableChart from '../fields/IndexableChart';
import NumberOfHreflangsChart from '../fields/NumberOfHreflangsChart';
import PageTypeChart from '../fields/PageTypeChart';
import RelevanceChart from '../fields/RelevanceChart';
import RobotsTxtChart from '../fields/RobotsTxtChart';
import StatusCodeChart from '../fields/StatusCodeChart';
import UrlDepthChart from '../fields/UrlDepthChart';
import WebVitalsChart from '../fields/WebVitalsChart';

import {
	WebVital,
} from '~/model/webVitals';



const icons = {
	content: AttributesGroupIconType.Content,
	conversions: AttributesGroupIconType.Conversions,
	fundamentals: AttributesGroupIconType.Fundamentals,
	indexability: AttributesGroupIconType.Indexability,
	lighthouse: AttributesGroupIconType.Lighthouse,
	relations: AttributesGroupIconType.Relations,
	social: AttributesGroupIconType.Social,
};



class PageAttributesCategoryBox extends PureComponent {

	_renderChart(column, data) {
		if (column === CK.PagesCommonColumn.Domain) {
			return (
				<DomainChart
					data={data}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.CanonicalType) {
			return (
				<CanonicalTypeChart
					data={data}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.Health) {
			return (
				<HealthChart
					data={data}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.HreflangLanguage) {
			return (
				<HreflangLanguageChart
					data={data}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.IsDisallowedInRobotsTxt) {
			return (
				<RobotsTxtChart
					data={data}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.IsInSitemap || column === CK.PagesCommonColumn.IsIndexable || column === CK.PagesCommonColumn.IsSecured || column === CK.PagesCommonColumn.IsLinked) {
			return (
				<BooleanChart
					data={data}
					falseColor={(column === CK.PagesCommonColumn.IsInSitemap || column === CK.PagesCommonColumn.IsIndexable) ? '#ff743b' : undefined}
					field={column}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.IsIndexableDueToMetaRobots || column === CK.PagesCommonColumn.IsIndexableDueToXRobotsTag) {
			return (
				<IndexableChart
					data={data}
					field={column}
					key={column}
				/>
			);
		} else if (
			column === CK.PagesCommonColumn.LighthouseCumulativeLayoutShift
			|| column === CK.PagesCommonColumn.LighthouseFirstContentfulPaint
			|| column === CK.PagesCommonColumn.LighthouseLargestContentfulPaint
			|| column === CK.PagesCommonColumn.LighthousePerformance
			|| column === CK.PagesCommonColumn.LighthouseSpeedIndex
			|| column === CK.PagesCommonColumn.LighthouseTimeToInteractive
			|| column === CK.PagesCommonColumn.LighthouseTotalBlockingTime
		) {
			return (
				<WebVitalsChart
					data={data}
					field={column}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.NumberOfHreflangs) {
			return (
				<NumberOfHreflangsChart
					data={data}
					key={column}
				/>
			);
		} else if ([
			CK.PagesCommonColumn.H1,
			CK.PagesCommonColumn.MetaDescription,
			CK.PagesCommonColumn.OpenGraphDescription,
			CK.PagesCommonColumn.OpenGraphImage,
			CK.PagesCommonColumn.OpenGraphTitle,
			CK.PagesCommonColumn.OpenGraphUrl,
			CK.PagesCommonColumn.Title,
			CK.PagesCommonColumn.TwitterDescription,
			CK.PagesCommonColumn.TwitterImage,
			CK.PagesCommonColumn.TwitterTitle,
		].indexOf(column) !== -1) {
			return (
				<ContentPresenceChart
					data={data}
					field={column}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.Relevance) {
			return (
				<RelevanceChart
					data={data}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.StatusCode) {
			return (
				<StatusCodeChart
					data={data}
					key={column}
				/>
			);
		} else if (column === CK.PagesCommonColumn.Type) {
			return (
				<PageTypeChart
					data={data}
					key={column}
					percentageFactor={100}
				/>
			);
		} else if (column === CK.PagesCommonColumn.UrlDepth) {
			return (
				<UrlDepthChart
					data={data}
					key={column}
				/>
			);
		}
	}



	_renderCharts() {
		const {
			categoryColumns,
			statistics,
		} = this.props;

		return categoryColumns.map((column) => {
			const data = statistics.getIn([column, 'website']);
			const loading = statistics.getIn([column, 'loading']);

			let label = (
				<ColumnName column={column} />
			);

			if (column === CK.PagesCommonColumn.Health) {
				label = (
					<HealthLabel>
						{label}
					</HealthLabel>
				);
			}

			if (column === CK.PagesCommonColumn.LighthouseLargestContentfulPaint) {
				label = (
					<WebVitalName
						showCoreWebVitalFlag={true}
						type={WebVital.LCP}
					/>
				);
			}

			if (column === CK.PagesCommonColumn.LighthouseCumulativeLayoutShift) {
				label = (
					<WebVitalName
						showCoreWebVitalFlag={true}
						type={WebVital.CLS}
					/>
				);
			}

			return (
				<ChartLayout
					headerElements={(!data || loading) && (
						<LoadingDots />
					)}
					key={column}
					label={label}
				>
					{this._renderChart(column, data)}
				</ChartLayout>
			);
		});
	}



	render() {
		const {
			category,
			categoryColumns,
		} = this.props;

		if (categoryColumns.size === 0) {
			return false;
		}

		return (
			<BorderedBox
				headerIcon={(
					<AttributesGroupIcon
						size={28}
						type={icons[category]}
					/>
				)}
				headerLabel={(
					<ColumnCategoryName columnCategory={category} />
				)}
				paddingSize={3}
			>
				<FlexibleBlocks blockMinWidth={300}>
					{this._renderCharts()}
				</FlexibleBlocks>
			</BorderedBox>
		);
	}

}

PageAttributesCategoryBox.propTypes = {
	category: PropTypes.string.isRequired,
	categoryColumns: PropTypes.object.isRequired,
	statistics: PropTypes.instanceOf(Map).isRequired,
};



export default PageAttributesCategoryBox;
