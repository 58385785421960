import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const GraphDecreaseIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M20,18a1,1,0,0,0-1,1v1.5a.249.249,0,0,0,.25.25h3.5A.249.249,0,0,0,23,20.5V19a1,1,0,0,0-1-1Z"
				fill={color}
			/>
			<path
				d="M16,15H14a1,1,0,0,0-1,1v4.5a.249.249,0,0,0,.25.25h3.5A.249.249,0,0,0,17,20.5V16A1,1,0,0,0,16,15Z"
				fill={color}
			/>
			<path
				d="M8,11.5a1,1,0,0,0-1,1v8a.249.249,0,0,0,.25.25h3.5A.249.249,0,0,0,11,20.5v-8a1,1,0,0,0-1-1Z"
				fill={color}
			/>
			<path
				d="M1.25,20.75h3.5A.249.249,0,0,0,5,20.5V8.5a1,1,0,0,0-1-1H2a1,1,0,0,0-1,1v12A.249.249,0,0,0,1.25,20.75Z"
				fill={color}
			/>
			<path
				d="M21,13.751a.75.75,0,0,0,.651-1.122l-2-3.5a.748.748,0,0,0-.626-.377.767.767,0,0,0-.649.333l-.307.46a.244.244,0,0,1-.159.106.247.247,0,0,1-.187-.037L3.555.169a1,1,0,0,0-1.11,1.664l14.168,9.445a.248.248,0,0,1,.107.16.252.252,0,0,1-.037.187l-.307.46a.75.75,0,0,0,.531,1.16l4,.5A.726.726,0,0,0,21,13.751Z"
				fill={color}
			/>
			<path
				d="M1,24H23a1,1,0,0,0,0-2H1a1,1,0,0,0,0,2Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default GraphDecreaseIconBuilder;
