import scrollbarSize from 'dom-helpers/scrollbarSize';

import '../scss/app.scss';
import 'normalize.css';



export function setupCss() {
	document.documentElement.style.setProperty('--scrollbar-size', `${scrollbarSize()}px`);
}
