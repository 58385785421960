import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	hasWebVitals?: boolean,
	title?: React.ReactNode,
};

const WidgetGrid: React.FC<Props> = (props) => {
	const {
		children,
		hasWebVitals,
		title,
	} = props;

	return (
		<div
			className={
				classNames({
					'widget-grid': true,
					'widget-grid--with-web-vitals': hasWebVitals,
				})}
		>
			{title && (
				<div className="widget-grid__title">
					{title}
				</div>
			)}

			<div className="widget-grid__grid">
				{children}
			</div>
		</div>
	);
};



export default WidgetGrid;
