import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import classNames from 'classnames';
import Draggable from 'react-draggable';

import scrollbarSize from 'dom-helpers/scrollbarSize';



class ResizableTableHeaderCells extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleDraggingStart = this._handleDraggingStart.bind(this);
		this._handleDraggingStop = this._handleDraggingStop.bind(this);

		this.state = {
			hoveredCellIndex: false,
			isDragging: false,
		};
	}



	_handleDraggingStart() {
		const {
			onDragStartCallback,
		} = this.props;

		this.setState({
			isDragging: true,
		});

		if (onDragStartCallback) {
			onDragStartCallback();
		}
	}



	_handleDraggingStop(event, data) {
		const {
			onDragStopCallback,
		} = this.props;

		const {
			hoveredCellIndex,
		} = this.state;

		const newColumnWidth = this._getColumnWidth(hoveredCellIndex) + data.x;

		if (onDragStopCallback) {
			onDragStopCallback(hoveredCellIndex, newColumnWidth);
		}

		this._leaveTimeout = setTimeout(() => {
			this.setState({
				hoveredCellIndex: false,
				isDragging: false,
			});
		}, 0);
	}



	componentWillUnmount() {
		clearTimeout(this._leaveTimeout);
	}



	_handleCellMouseEnter(cellIndex) {
		const {
			isDragging,
		} = this.state;

		if (isDragging) {
			return false;
		}

		clearTimeout(this._leaveTimeout);

		this.setState({
			hoveredCellIndex: cellIndex,
		});
	}



	_handleCellMouseLeave() {
		const {
			isDragging,
		} = this.state;

		if (isDragging) {
			return false;
		}

		this._leaveTimeout = setTimeout(() => {
			this.setState({
				hoveredCellIndex: false,
			});
		}, 100);
	}



	_wrapPropertyGetter(value) {
		return value instanceof Function
			? value
			: () => value;
	}



	_wrapSizeGetter(size) {
		return this._wrapPropertyGetter(size);
	}



	_getColumnWidth(columnIndex) {
		const {
			columnWidth,
		} = this.props;

		const columnWidthGetter = this._wrapSizeGetter(columnWidth);

		return columnWidthGetter({
			index: columnIndex,
		});
	}



	_getDragHandlerDistance(hoveredCellIndex) {
		let distance = 0;

		for (let i = 0; i <= hoveredCellIndex; i++) {
			distance += this._getColumnWidth(i);
		}

		// 2 pixels to fit handler on cells divider
		return distance - 5;
	}



	_getLeftDragBoundary(hoveredCellIndex) {
		const {
			columnsMinWidths,
		} = this.props;

		if (hoveredCellIndex === false) {
			return 0;
		}

		const minWidth = columnsMinWidths.get
			? columnsMinWidths.get(hoveredCellIndex)
			: columnsMinWidths[hoveredCellIndex];

		return -1 * this._getColumnWidth(hoveredCellIndex) + minWidth;
	}



	_getRightDragBoundary(hoveredCellIndex) {
		const {
			columnsMaxWidths,
		} = this.props;

		if (hoveredCellIndex === false) {
			return 0;
		}

		const maxWidth = columnsMaxWidths.get
			? columnsMaxWidths.get(hoveredCellIndex)
			: columnsMaxWidths[hoveredCellIndex];

		return maxWidth - this._getColumnWidth(hoveredCellIndex);
	}



	_renderDragHandler(index) {
		const {
			disabledColumns,
			hasHorizontalScrollbars,
			headerHeight,
			tableHeight,
		} = this.props;

		if (index === false) {
			return (
				<span />
			);
		}

		const handlerClasses = classNames({
			'resizable-header-cells__drag-handler': true,
			'resizable-header-cells__drag-handler--is-disabled': disabledColumns.includes(index),
		});

		return (
			<Draggable
				axis="x"
				bounds={{
					left: this._getLeftDragBoundary(index),
					right: this._getRightDragBoundary(index),
				}}
				defaultClassNameDragging="resizable-header-cells__drag-handler--is-active"
				disabled={disabledColumns.includes(index)}
				onStart={this._handleDraggingStart}
				onStop={this._handleDraggingStop}
			>
				<div
					className={handlerClasses}
					onMouseEnter={this._handleCellMouseEnter.bind(this, index)}
					onMouseLeave={this._handleCellMouseLeave.bind(this, index)}
					style={{
						height: headerHeight,
						left: this._getDragHandlerDistance(index),
					}}
				>
					<div
						className="resizable-header-cells__drag-handler-line"
						style={{
							height: tableHeight - (hasHorizontalScrollbars ? scrollbarSize() : 0),
						}}
					/>
				</div>
			</Draggable>
		);
	}



	render() {
		const {
			children,
		} = this.props;

		const {
			hoveredCellIndex,
		} = this.state;

		return (
			<div className="resizable-header-cells">
				{React.Children.map(
					children,
					(child, index) => {
						if (!child) {
							return false;
						}

						return (
							<div
								className="resizable-header-cells__cell"
								onMouseEnter={this._handleCellMouseEnter.bind(this, index)}
							>
								{child}
							</div>
						);
					},
				)}

				{
					// We need to generate 2 drag handlers to fix movement from the right cell
					// to the right drag handler. When we hover on drag handler we will set
					// correct `hoveredCellIndex`.
					hoveredCellIndex > 0 && this._renderDragHandler(hoveredCellIndex - 1)
				}
				{this._renderDragHandler(hoveredCellIndex)}
			</div>
		);
	}

}

ResizableTableHeaderCells.defaultProps = {
	disabledColumns: List(),
};

ResizableTableHeaderCells.propTypes = {
	/** Count of columns */
	columnCount: PropTypes.number.isRequired,
	/** Function or number used for calculation of column widths */
	columnWidth: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.func,
	]).isRequired,
	columnsMaxWidths: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]).isRequired,
	columnsMinWidths: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]).isRequired,
	/** Indexes of columns where we don't enable resizable feature */
	disabledColumns: PropTypes.instanceOf(List),
	/** Flag if content is shorter than viewport width and we should display bottom horizontal scrollbars */
	hasHorizontalScrollbars: PropTypes.bool.isRequired,
	/** Height of table header */
	headerHeight: PropTypes.number.isRequired,
	/** Callback triggered when dragging starts */
	onDragStartCallback: PropTypes.func,
	/** Callback triggered when dragging stops */
	onDragStopCallback: PropTypes.func,
	/** Defined height of whole table */
	tableHeight: PropTypes.number.isRequired,
};



export default ResizableTableHeaderCells;
