import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import useFilteredWebsiteScopeIssues from '~/hooks/useFilteredWebsiteScopeIssues';
import useGetIssueCategory from '~/hooks/useGetIssueCategory';

import {
	issuesOverviewCategorySelector,
} from '~/state/ui/content/selectors';



function useOpenedWebsiteIssueCategory(websiteId: CK.WebsiteId) {
	const openedIssueCategoryName = useSelector(issuesOverviewCategorySelector);

	const websiteIssueCategories = useFilteredWebsiteScopeIssues(websiteId);

	return useGetIssueCategory(
		websiteIssueCategories,
		openedIssueCategoryName,
	);
}



export default useOpenedWebsiteIssueCategory;
