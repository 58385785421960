import React from 'react';

import type CK from '~/types/contentking';

import StripeContext from '~/components/app/Stripe/StripeContext';

import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';



type Props = {
	accountId: CK.AccountId | null,
	children?: React.ReactNode,
};

const AccountStripeContext: React.FC<Props> = (props) => {
	const {
		accountId,
		children,
	} = props;

	const billingEntity = useAccountBillingEntity(accountId);

	return (
		<StripeContext
			billingEntity={billingEntity}
		>
			{children}
		</StripeContext>
	);
};



export default AccountStripeContext;
