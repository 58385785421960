import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CanonicalLinkIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Group"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="canonical-url-"
					transform="translate(3.000000, 2.000000)"
				>
					<path
						d="M18.90625,41.2665 C18.90625,44.1144687 16.5979687,46.42275 13.75,46.42275 C10.9020313,46.42275 8.59375,44.1144687 8.59375,41.2665 C8.59375,38.4185312 10.9020313,36.11025 13.75,36.11025 C16.5979687,36.11025 18.90625,38.4185312 18.90625,41.2665 L18.90625,41.2665 Z"
						id="Stroke-9"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M46.40625,17.204 C46.40625,20.0519687 44.0979687,22.36025 41.25,22.36025 C38.4020313,22.36025 36.09375,20.0519687 36.09375,17.204 C36.09375,14.3560312 38.4020313,12.04775 41.25,12.04775 C44.0979687,12.04775 46.40625,14.3560312 46.40625,17.204 L46.40625,17.204 Z"
						id="Stroke-11"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<polyline
						id="Stroke-13"
						points="24.0625 8.59375 30.9375 15.46875 24.0625 22.34375"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polyline>
					<path
						d="M13.75,36.09375 L13.75,22.34375 C13.75,18.5470312 16.8282812,15.46875 20.625,15.46875 L30.9375,15.46875"
						id="Stroke-15"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default CanonicalLinkIconBuilder;

