import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	UserAgent,
} from '~/model/websites/userAgents';



const messages = defineMessages({
	[UserAgent.Automatic]: {
		id: 'ui.userAgent.automatic',
	},
	[UserAgent.Baiduspider]: {
		id: 'ui.userAgent.baiduspider',
	},
	[UserAgent.BingbotDesktop]: {
		id: 'ui.userAgent.bingbotDesktop',
	},
	[UserAgent.BingbotMobile]: {
		id: 'ui.userAgent.bingbotMobile',
	},
	[UserAgent.ContentKing]: {
		id: 'ui.userAgent.kingkevinbot',
	},
	[UserAgent.Custom]: {
		id: 'ui.userAgent.customUserAgent',
	},
	[UserAgent.DuckDuckGo]: {
		id: 'ui.userAgent.duckDuckGo',
	},
	[UserAgent.GooglebotDesktop]: {
		id: 'ui.userAgent.googlebotDesktop',
	},
	[UserAgent.GooglebotMobile]: {
		id: 'ui.userAgent.googlebotMobile',
	},
	[UserAgent.GooglechromeDesktop]: {
		id: 'ui.userAgent.googlechromeDesktop',
	},
	[UserAgent.GooglechromeMobile]: {
		id: 'ui.userAgent.googlechromeMobile',
	},
	[UserAgent.ScreamingFrog]: {
		id: 'ui.userAgent.screamingFrog',
	},
	[UserAgent.Seznambot]: {
		id: 'ui.userAgent.seznambot',
	},
	[UserAgent.Slurp]: {
		id: 'ui.userAgent.slurp',
	},
	[UserAgent.Yandexbot]: {
		id: 'ui.userAgent.yandexbot',
	},
});



type Props = {
	userAgent: UserAgent,
};

const UserAgentName: React.FC<Props> = (props) => {
	const {
		userAgent,
	} = props;

	const message = messages[userAgent];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};


export default UserAgentName;
