import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PlusIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="M24 24L0 24 0 0 24 0z"
					fill="none"
				/>
				<rect
					fill={color}
					height="15"
					rx="1"
					width="2"
					x="11"
					y="4.5"
				/>
				<rect
					fill={color}
					height="2"
					rx="1"
					width="15"
					x="4.5"
					y="11"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default PlusIconBuilder;

