import React from 'react';



type Props = {
	children?: React.ReactNode,
};

const VersionsOverview: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div className="versions-overview">
			{children}
		</div>
	);
};



export default VersionsOverview;
