import React from 'react';

import styles from './ScreenshotImage.module.scss';



type Props = {
	image: React.ReactNode,
	label?: React.ReactNode,
};

const ScreenshotImage: React.FC<Props> = (props) => {
	const {
		image,
		label,
	} = props;

	return (
		<div className={styles.component}>
			{image}
			{label && (
				<div className={styles.label}>
					{label}
				</div>
			)}
		</div>
	);
};



export default ScreenshotImage;
