import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '../basis/Form';
import IncidentFeedbackFormLayout from '~/components/patterns/incidents/IncidentFeedbackFormLayout';
import SubmitButton, {
	SubmitButtonSize,
} from '~/components/app/SubmitButton';
import TextArea from '../components/TextArea';



const messages = defineMessages({
	description: {
		id: 'ui.alerts.feedback.questionNegative',
	},
	formErrors: {
		blank: {
			id: 'ui.formErrors.blank',
		},
	},
	submitButton: {
		id: 'ui.alerts.feedback.submit',
	},
});

const validations = {
	feedback: [
		{
			message: (
				<FormattedMessage {...messages.formErrors.blank} />
			),
			field: 'feedback',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
};



class IncidentFeedbackForm extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleSubmitForm = this._handleSubmitForm.bind(this);
	}



	_handleSubmitForm(data) {
		const {
			onSubmitCallback,
		} = this.props;

		if (onSubmitCallback) {
			return onSubmitCallback(data);
		}
	}



	render() {
		return (
			<Form
				defaultValues={{
					feedback: '',
				}}
				onSuccess={this._handleSubmitForm}
				validations={validations}
			>
				<IncidentFeedbackFormLayout
					buttons={[
						// <CancelButton
						// 	onClickCallback={onCancelCallback}
						// 	key="cancel-button"
						// 	size={CANCEL_BUTTON_SIZE_SMALL}
						// />,
						<SubmitButton
							key="submit-button"
							size={SubmitButtonSize.Small}
						>
							<FormattedMessage {...messages.submitButton} />
						</SubmitButton>,
					]}
					description={(
						<FormattedMessage {...messages.description} />
					)}
					textAreaField={({ height }) => (
						<FieldStatus
							name="feedback"
							showIcon={false}
						>
							<TextArea
								attributes={{
									style: {
										height,
									},
								}}
								name="feedback"
								resizable={false}
								rows={2}
								width="100%"
							/>
						</FieldStatus>
					)}
				/>
			</Form>
		);
	}

}

IncidentFeedbackForm.propTypes = {
	onCancelCallback: PropTypes.func.isRequired,
	onSubmitCallback: PropTypes.func.isRequired,
};



export default IncidentFeedbackForm;
