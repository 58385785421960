import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	useAddCustomerWebsiteMutation,
	useAddEndUserWebsiteMutation,
	useAddTrialWebsiteMutation,
	useReviveTrialByAddingWebsiteMutation,
} from './useCreateWebsite.gql';

import useAccountIsTrialRevivable from '~/hooks/useAccountIsTrialRevivable';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountState from '~/hooks/useAccountState';
import useAccountType from '~/hooks/useAccountType';
import useRequestAdminConfirmationIfApplicable from '~/hooks/useRequestAdminConfirmationIfApplicable';



export type CreateWebsiteInput = {
	acceptedLegalDocuments: ReadonlyArray<GraphQL.LegalDocumentType>,
	domain: string,
	fetchingLocation?: GraphQL.FetchingLocation,
	httpAuthCredentials?: {
		password: string,
		username: string,
	},
	pageCapacity: number,
	skipValidation: boolean,
	threeDSecureToken?: string,
	userAgentSettings?: GraphQL.UserAgentSettingsInput,
};


function useCreateWebsite(accountId: CK.AccountId | null) {
	const requestAdminConfirmationIfApplicable = useRequestAdminConfirmationIfApplicable(accountId);

	const accountIsTrialRevivable = useAccountIsTrialRevivable(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountState = useAccountState(accountId);
	const accountType = useAccountType(accountId);

	const [addCustomerWebsite] = useAddCustomerWebsiteMutation();
	const [addEndUserWebsite] = useAddEndUserWebsiteMutation();
	const [addTrialWebsite] = useAddTrialWebsiteMutation();
	const [reviveTrialByAddingWebsite] = useReviveTrialByAddingWebsiteMutation();

	return React.useCallback(
		async (input: CreateWebsiteInput) => {
			const {
				acceptedLegalDocuments,
				domain,
				fetchingLocation = null,
				httpAuthCredentials = null,
				pageCapacity,
				skipValidation,
				threeDSecureToken = null,
				userAgentSettings = null,
			} = input;

			if (accountId === null) {
				return;
			}

			await requestAdminConfirmationIfApplicable();

			if (accountIsTrialRevivable) {
				const { data } = await reviveTrialByAddingWebsite({
					variables: {
						accountId,
						acceptedLegalDocuments,
						domain,
						fetchingLocation,
						httpAuthCredentials,
						pageCapacity,
						skipValidation,
						userAgentSettings,
					},
				});

				return data?.ReviveTrialByAddingWebsite.website.id;
			} else if (accountPhase === GraphQL.AccountPhase.Trial && accountState === GraphQL.AccountState.Active) {
				const { data } = await addTrialWebsite({
					variables: {
						accountId,
						acceptedLegalDocuments,
						domain,
						fetchingLocation,
						httpAuthCredentials,
						pageCapacity,
						skipValidation,
						userAgentSettings,
					},
				});

				return data?.AddTrialWebsite.website.id;
			} else if (accountType === GraphQL.AccountType.EndUser) {
				const { data } = await addEndUserWebsite({
					variables: {
						accountId,
						acceptedLegalDocuments,
						domain,
						fetchingLocation,
						httpAuthCredentials,
						pageCapacity,
						skipValidation,
						threeDSecureToken,
						userAgentSettings,
					},
				});

				return data?.AddEndUserWebsite.website.id;
			} else if (accountType === GraphQL.AccountType.Universal) {
				const { data } = await addCustomerWebsite({
					variables: {
						accountId,
						acceptedLegalDocuments,
						domain,
						fetchingLocation,
						httpAuthCredentials,
						pageCapacity,
						skipValidation,
						threeDSecureToken,
						userAgentSettings,
					},
				});

				return data?.AddCustomerWebsite.website.id;
			}
		},
		[
			accountId,
			accountIsTrialRevivable,
			accountPhase,
			accountState,
			accountType,
			addCustomerWebsite,
			addEndUserWebsite,
			addTrialWebsite,
			requestAdminConfirmationIfApplicable,
			reviveTrialByAddingWebsite,
		],
	);
}



export default useCreateWebsite;
