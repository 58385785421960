import classNames from 'classnames';
import React, {
	Component,
} from 'react';



type Props = {
	children?: React.ReactNode,
	/** Additional style object generated by react-virtualized */
	cssStyle?: React.CSSProperties,
	/** Hide text in the cell and display unfoldable icon only */
	isCompact?: boolean,
	/** Render on empty cell to fill remaining space on the grid row */
	isEmpty: boolean,
	/** We can manually tell that row is hovered */
	isHovered?: boolean,
	/** onClick callback triggered on cell click */
	onClickCallback: (rowIndex: Props['rowIndex']) => void,
	/** on mouse hover callback */
	onMouseEnter?: (e: any) => void,
	/** on mouse leave callback */
	onMouseLeave?: (e: any) => void,
	/** Index of row in the datatable */
	rowIndex: number,
	/** Optional label attached to the content of the collapsed cell. This label will be visible on hover. */
	showMoreLabel?: React.ReactNode,
};



class CodeUnfoldableCell extends Component<Props> {

	static defaultProps = {
		compact: false,
		empty: false,
	};



	constructor(props, context) {
		super(props, context);

		this._handleCellClick = this._handleCellClick.bind(this);
	}



	_handleCellClick(e) {
		e.preventDefault();

		const {
			onClickCallback,
			rowIndex,
		} = this.props;

		onClickCallback(rowIndex);
	}



	render() {
		const {
			cssStyle,
			children,
			isCompact,
			isEmpty,
			isHovered,
			onMouseEnter,
			onMouseLeave,
			showMoreLabel,
		} = this.props;

		const componentClasses = classNames({
			'code-unfoldable-cell': true,
			'code-unfoldable-cell--is-compact': isCompact,
			'code-unfoldable-cell--is-empty': isEmpty,
			'code-unfoldable-cell--is-hovered': isHovered,
		});

		return (
			<div
				className={componentClasses}
				onClick={this._handleCellClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				style={cssStyle}
			>
				<div className="code-unfoldable-cell__content">
					{!isEmpty && !isCompact && children}
					{!isEmpty && !isCompact && showMoreLabel && (
						<span className="code-unfoldable-cell__show-more-link">
							{showMoreLabel}
						</span>
					)}
				</div>
			</div>
		);
	}

}



export default CodeUnfoldableCell;
