import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CalendarIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-calendar">
					<g id="calendar">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<polyline
							id="Stroke-1830"
							points="16.0870322 5.87088098 18.9203295 5.87088098 18.9203295 19.8124438 4.75100878 19.8124438 4.75100878 5.87088098 7.58501463 5.87088098"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</polyline>
						<path
							d="M9.71051903,5.75 L13.9615278,5.75"
							id="Stroke-1831"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M4.75100878,9.5403467 L18.9203295,9.5403467"
							fill="#FFFFFF"
							id="Fill-1832"
						>
						</path>
						<path
							d="M4.75100878,9.53946613 L18.9203295,9.53946613"
							id="Stroke-1833"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M8.64776683,7.33761059 L8.64776683,7.33761059 C8.06112762,7.33761059 7.58501463,6.84449357 7.58501463,6.23690296 L7.58501463,4.77076039 C7.58501463,4.16243598 8.06112762,3.67005276 8.64776683,3.67005276 C9.23440604,3.67005276 9.71051903,4.16243598 9.71051903,4.77076039 L9.71051903,6.23690296 C9.71051903,6.84449357 9.23440604,7.33761059 8.64776683,7.33761059 L8.64776683,7.33761059 Z"
							id="Stroke-1835"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M15.02428,7.33761059 L15.02428,7.33761059 C14.4376408,7.33761059 13.9615278,6.84449357 13.9615278,6.23690296 L13.9615278,4.77076039 C13.9615278,4.16243598 14.4376408,3.67005276 15.02428,3.67005276 C15.6109192,3.67005276 16.0870322,4.16243598 16.0870322,4.77076039 L16.0870322,6.23690296 C16.0870322,6.84449357 15.6109192,7.33761059 15.02428,7.33761059 L15.02428,7.33761059 Z"
							id="Stroke-1836"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M7.58501463,12.4746865 L9.00201756,12.4746865"
							id="Stroke-1838"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M7.58501463,14.6761017 L9.00201756,14.6761017"
							id="Stroke-1841"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M7.58501463,16.877517 L9.00201756,16.877517"
							id="Stroke-1844"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M11.127522,12.4746865 L12.5445249,12.4746865"
							id="Stroke-1847"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M14.6700293,12.4746865 L16.0870322,12.4746865"
							id="Stroke-1850"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M11.127522,14.6761017 L12.5445249,14.6761017"
							id="Stroke-1853"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M14.6700293,14.6761017 L16.0870322,14.6761017"
							id="Stroke-1856"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M14.6700293,16.877517 L16.0870322,16.877517"
							id="Stroke-1859"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M11.127522,16.877517 L12.5445249,16.877517"
							id="Stroke-1862"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CalendarIconBuilder;

