/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountConfirmationNameQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountConfirmationNameQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly confirmationName: string } | null };


export const AccountConfirmationNameDocument = gql`
    query AccountConfirmationName($accountId: AccountId!) {
  account(id: $accountId) {
    id
    confirmationName
  }
}
    `;

/**
 * __useAccountConfirmationNameQuery__
 *
 * To run a query within a React component, call `useAccountConfirmationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountConfirmationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountConfirmationNameQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountConfirmationNameQuery(baseOptions: Apollo.QueryHookOptions<AccountConfirmationNameQuery, AccountConfirmationNameQueryVariables> & ({ variables: AccountConfirmationNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountConfirmationNameQuery, AccountConfirmationNameQueryVariables>(AccountConfirmationNameDocument, options);
      }
export function useAccountConfirmationNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountConfirmationNameQuery, AccountConfirmationNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountConfirmationNameQuery, AccountConfirmationNameQueryVariables>(AccountConfirmationNameDocument, options);
        }
export function useAccountConfirmationNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountConfirmationNameQuery, AccountConfirmationNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountConfirmationNameQuery, AccountConfirmationNameQueryVariables>(AccountConfirmationNameDocument, options);
        }
export type AccountConfirmationNameQueryHookResult = ReturnType<typeof useAccountConfirmationNameQuery>;
export type AccountConfirmationNameLazyQueryHookResult = ReturnType<typeof useAccountConfirmationNameLazyQuery>;
export type AccountConfirmationNameSuspenseQueryHookResult = ReturnType<typeof useAccountConfirmationNameSuspenseQuery>;
export type AccountConfirmationNameQueryResult = Apollo.QueryResult<AccountConfirmationNameQuery, AccountConfirmationNameQueryVariables>;