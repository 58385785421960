import type CK from '~/types/contentking';

import {
	useAccountAccessRestrictionsQuery,
} from './useAccountAccessRestrictions.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountAccessRestrictions(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountAccessRestrictionsQuery,
		accountId,
	);

	return data?.account?.accountAccessRestrictions ?? null;
}



export default useAccountAccessRestrictions;
