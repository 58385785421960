import PropTypes from 'prop-types';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

/* eslint-disable sort-imports */
import {
	ISSUE_CHANGE_CLOSED,
	ISSUE_CHANGE_IGNORED,
	ISSUE_CHANGE_OPENED,
	ISSUE_CHANGE_OPENED_NEW_PAGE,
	ISSUE_CHANGE_UNIGNORED,
	ISSUE_CHANGE_OPEN_NOT_APPLICABLE,
	ISSUE_CHANGE_OPEN_NOT_PAGE,
	ISSUE_CHANGE_NOT_APPLICABLE_POINTS,
	ISSUE_CHANGE_NOT_PAGE_POINTS,
	ISSUE_CHANGE_NOT_REQUIRED_POINTS,
	ISSUE_CHANGE_POINTS_NOT_APPLICABLE,
	ISSUE_CHANGE_POINTS_NOT_PAGE,
	ISSUE_CHANGE_POINTS_NOT_REQUIRED,
	ISSUE_CHANGE_OPEN_LESS_IMPORTANT,
	ISSUE_CHANGE_OPEN_MORE_IMPORTANT,
	ISSUE_CHANGE_POINTS_LESS_IMPORTANT,
	ISSUE_CHANGE_POINTS_MORE_IMPORTANT,
	ISSUE_CHANGE_BROKEN_ENTERED,
	ISSUE_CHANGE_BROKEN_LEFT,
	ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS,
	ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT,
	LIST_OF_ALL_ISSUE_CHANGES,
} from '~/model/affectedPagesComparison';
/* eslint-enable */



const messages = defineMessages({
	[ISSUE_CHANGE_CLOSED]: {
		id: 'ui.issueChanges.closed',
	},
	[ISSUE_CHANGE_IGNORED]: {
		id: 'ui.issueChanges.ignored',
	},
	[ISSUE_CHANGE_OPENED]: {
		id: 'ui.issueChanges.opened',
	},
	[ISSUE_CHANGE_OPENED_NEW_PAGE]: {
		id: 'ui.issueChanges.openedNewPage',
	},
	[ISSUE_CHANGE_UNIGNORED]: {
		id: 'ui.issueChanges.unignored',
	},
	[ISSUE_CHANGE_OPEN_NOT_APPLICABLE]: {
		id: 'ui.issueChanges.openNotApplicable',
	},
	[ISSUE_CHANGE_OPEN_NOT_PAGE]: {
		id: 'ui.issueChanges.openNotPage',
	},
	[ISSUE_CHANGE_NOT_APPLICABLE_POINTS]: {
		id: 'ui.issueChanges.notApplicablePoints',
	},
	[ISSUE_CHANGE_NOT_PAGE_POINTS]: {
		id: 'ui.issueChanges.notPagePoints',
	},
	[ISSUE_CHANGE_NOT_REQUIRED_POINTS]: {
		id: 'ui.issueChanges.notRequiredPoints',
	},
	[ISSUE_CHANGE_POINTS_NOT_APPLICABLE]: {
		id: 'ui.issueChanges.pointsNotApplicable',
	},
	[ISSUE_CHANGE_POINTS_NOT_PAGE]: {
		id: 'ui.issueChanges.pointsNotPage',
	},
	[ISSUE_CHANGE_POINTS_NOT_REQUIRED]: {
		id: 'ui.issueChanges.pointsNotRequired',
	},
	[ISSUE_CHANGE_OPEN_LESS_IMPORTANT]: {
		id: 'ui.issueChanges.openLessImportant',
	},
	[ISSUE_CHANGE_OPEN_MORE_IMPORTANT]: {
		id: 'ui.issueChanges.openMoreImportant',
	},
	[ISSUE_CHANGE_POINTS_LESS_IMPORTANT]: {
		id: 'ui.issueChanges.pointsLessImportant',
	},
	[ISSUE_CHANGE_POINTS_MORE_IMPORTANT]: {
		id: 'ui.issueChanges.pointsMoreImportant',
	},
	[ISSUE_CHANGE_BROKEN_ENTERED]: {
		id: 'ui.issueChanges.brokenEntered',
	},
	[ISSUE_CHANGE_BROKEN_LEFT]: {
		id: 'ui.issueChanges.brokenLeft',
	},
	[ISSUE_CHANGE_NOT_IN_SEGMENT_POINTS]: {
		id: 'ui.issueChanges.notInSegmentPoints',
	},
	[ISSUE_CHANGE_POINTS_NOT_IN_SEGMENT]: {
		id: 'ui.issueChanges.pointsNotInSegment',
	},
});



const IssueChangeName = (props) => {
	const {
		issueChange,
	} = props;

	return (
		<FormattedMessage {...messages[issueChange]} />
	);
};

IssueChangeName.propTypes = {
	issueChange: PropTypes.oneOf(LIST_OF_ALL_ISSUE_CHANGES.toArray()).isRequired,
};



export default IssueChangeName;
