import React from 'react';

import {
	DatatableContext,
} from '~/components/logic/DatatableContextProvider';



function useDatatableContext() {
	const {
		isColumnVisible,
		scrollToColumn,
		setIsColumnVisible,
		setScrollToColumn,
	} = React.useContext(DatatableContext) || {};

	return React.useMemo(
		() => ({
			isColumnVisible,
			scrollToColumn,
			setIsColumnVisible,
			setScrollToColumn,
		}),
		[
			isColumnVisible,
			scrollToColumn,
			setIsColumnVisible,
			setScrollToColumn,
		],
	);
}



export default useDatatableContext;
