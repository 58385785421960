import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	IbanElement,
	useElements,
} from '@stripe/react-stripe-js';

import useFormContext from '~/hooks/useFormContext';

import matchAndReturn from '~/utilities/matchAndReturn';

import {
	type Rule,
} from '~/utilities/validations';



const messages = defineMessages({
	empty: {
		id: 'ui.formErrors.blank',
	},
});



export enum StripeFieldType {
	CardCvc = 'CardCvc',
	CardExpiry = 'CardExpiry',
	CardNumber = 'CardNumber',
	Iban = 'Iban',
}



type Props = {
	disabled?: boolean,
	name: string,
	type: StripeFieldType,
};

const StripeField: React.FC<Props> = (props) => {
	const {
		disabled = false,
		name,
		type,
	} = props;

	const elements = useElements();
	const formContext = useFormContext();
	const intl = useIntl();

	const {
		onBlurHandler,
		onChangeHandler,
		onFocusHandler,
		onMountHandler,
		onUnmountHandler,
	} = formContext;

	React.useEffect(
		() => {
			onMountHandler(name, {
				value: { message: intl.formatMessage(messages.empty) },
			});

			return () => {
				onUnmountHandler(name);
			};
		},
		[
			onMountHandler,
			onUnmountHandler,
			intl,
			name,
		],
	);

	React.useEffect(
		() => {
			if (elements === null) {
				return;
			}

			const element: any = matchAndReturn(type, {
				[StripeFieldType.CardCvc]: elements.getElement(CardCvcElement),
				[StripeFieldType.CardExpiry]: elements.getElement(CardExpiryElement),
				[StripeFieldType.CardNumber]: elements.getElement(CardNumberElement),
				[StripeFieldType.Iban]: elements.getElement(IbanElement),
			});

			if (element === null) {
				throw new Error(
					`Stripe element '${type}' can't be obtained`,
				);
			}

			function changeHandler(event) {
				if (event.error) {
					onChangeHandler(name, event.error, {
						timeout: 250,
					});
				} else if (event.empty) {
					onChangeHandler(name, { message: intl.formatMessage(messages.empty) }, {
						timeout: 250,
					});
				} else if (event.complete) {
					onChangeHandler(name, 'xxxx', {
						timeout: 250,
					});
				}
			}

			function blurHandler() {
				onBlurHandler(name);
			}

			function focusHandler() {
				onFocusHandler(name);
			}

			element.on('change', changeHandler);
			element.on('blur', blurHandler);
			element.on('focus', focusHandler);

			return () => {
				element.off('change', changeHandler);
				element.off('blur', blurHandler);
				element.off('focus', focusHandler);
			};
		},
		[
			elements,
			intl,
			name,
			onBlurHandler,
			onChangeHandler,
			onFocusHandler,
			type,
		],
	);

	const style = {
		base: {
			'color': '#263340',
			'fontFamily': 'Roboto, sans-serif',
			'fontSize': '16px',
			'fontSmoothing': 'antialiased',
			'fontWeight': '300',
			'::placeholder': {
				color: '#98A5B3',
				fontFamily: 'Roboto, sans-serif',
				fontStyle: 'italic',
				fontWeight: '300',
				fontSmoothing: 'antialiased',
			},
		},
	};

	const element = matchAndReturn(type, {
		[StripeFieldType.CardCvc]: (
			<CardCvcElement
				className="StripeElement"
				options={{
					disabled: disabled || formContext.isSubmitting,
					style,
				}}
			/>
		),
		[StripeFieldType.CardExpiry]: (
			<CardExpiryElement
				className="StripeElement"
				options={{
					disabled: disabled || formContext.isSubmitting,
					style,
				}}
			/>
		),
		[StripeFieldType.CardNumber]: (
			<CardNumberElement
				className="StripeElement"
				options={{
					disabled: disabled || formContext.isSubmitting,
					showIcon: true,
					style,
				}}
			/>
		),
		[StripeFieldType.Iban]: (
			<IbanElement
				className="StripeElement"
				options={{
					disabled: disabled || formContext.isSubmitting,
					style,
					supportedCountries: ['SEPA'],
				}}
			/>
		),
	});

	const width = matchAndReturn(type, {
		[StripeFieldType.CardCvc]: 56,
		[StripeFieldType.CardExpiry]: 112,
		[StripeFieldType.CardNumber]: 280,
		[StripeFieldType.Iban]: 280,
	});

	return (
		<div
			key={type}
			style={{
				width,
			}}
		>
			{element}
		</div>
	);
};



export default StripeField;

export function validateStripeField(field: string): Array<Rule> {
	return [
		{
			message: ({ values }) => {
				return values[field] ? <>{values[field].message}</> : null;
			},
			field,
			rule: ({ values }) => {
				return !(values[field] instanceof Object);
			},
		},
	];
}
