import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import Ellipsis from '~/components/patterns/values/Ellipsis';



export const STYLE_DECENT = 'decent';
export const STYLE_DEFAULT = 'default';
export const STYLE_NUMBER = 'number';

class HeaderTitleValue extends Component {

	render() {
		const {
			style,
			value,
		} = this.props;

		const componentClasses = classNames({
			'header-title-value': true,
			'header-title-value--decent-style': style === STYLE_DECENT,
			'header-title-value--number-style': style === STYLE_NUMBER,
		});

		return (
			<div className={componentClasses}>
				<Ellipsis
					altPopupText={value}
					hoverTimeout={50}
					popupZIndex={4000}
				>
					{value}
				</Ellipsis>
			</div>
		);
	}

}

HeaderTitleValue.defaultProps = {
	style: STYLE_DEFAULT,
};

HeaderTitleValue.propTypes = {
	style: PropTypes.oneOf([
		STYLE_DECENT,
		STYLE_DEFAULT,
		STYLE_NUMBER,
	]),
	value: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.number,
	]).isRequired,
};



export default HeaderTitleValue;
