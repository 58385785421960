import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import HeaderTitleWithValue from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import InternalLink from '~/components/patterns/links/InternalLink';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';

import {
	useVerifyWebsiteModalQuery,
} from './VerifyWebsiteModal.gql';

import useIntercom from '~/hooks/useIntercom';
import useModals from '~/hooks/useModals';
import useNavigation from '~/hooks/useNavigation';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	button: {
		id: 'ui.verifyWebsiteModal.button',
	},
	description: {
		id: 'ui.verifyWebsiteModal.description',
	},
	title: {
		id: 'ui.verifyWebsiteModal.title',
	},
});

const verificationMethodMessages = defineMessages({
	[GraphQL.WebsiteVerificationMethodType.CloudflareWorker]: {
		id: 'ui.websiteVerificationMethods.cloudflareWorker',
	},
	[GraphQL.WebsiteVerificationMethodType.GoogleSearchConsole]: {
		id: 'ui.websiteVerificationMethods.googleSearchConsole',
	},
});



const VerifyWebsiteModal: React.FC = () => {
	const websiteId = useWebsiteId();

	const modals = useModals();
	const intercom = useIntercom();
	const navigation = useNavigation();
	const websiteDisplayName = useWebsiteDisplayName(websiteId);

	const { data } = useVerifyWebsiteModalQuery({
		variables: {
			websiteId,
		},
	});

	const verificationMethods = data?.website?.verificationMethods.map((verificationMethod) => (
		<li key={verificationMethod.type}>
			<FormattedMessage
				{...verificationMethodMessages[verificationMethod.type]}
				values={{
					link_integrations: (chunks) => (
						<InternalLink
							onClickCallback={() => {
								navigation.navigate({
									routeName: 'website.detail.integrations',
									routeParams: {
										websiteId,
									},
								});

								modals.closeModal();
							}}
						>
							{chunks}
						</InternalLink>
					),
					link_logFileAnalysis: (chunks) => (
						<InternalLink
							onClickCallback={() => {
								navigation.navigate({
									routeName: 'website.detail.logFileAnalysis',
									routeParams: {
										websiteId,
									},
								});

								modals.closeModal();
							}}
						>
							{chunks}
						</InternalLink>
					),
				}}
			/>
		</li>
	)) ?? [];

	return (
		<SimpleModal
			size={SimpleModalSize.Medium}
			title={(
				<HeaderTitleWithValue
					title={(
						<FormattedMessage
							{...messages.title}
						/>
					)}
					value={websiteDisplayName}
				/>
			)}
		>
			<RichText>
				<Copy
					{...messages.description}
					values={{
						link_intercom: (chunks) => (
							<InternalLink
								onClickCallback={() => {
									intercom.show();
									modals.closeModal();
								}}
							>
								{chunks}
							</InternalLink>
						),
						verificationMethods,
					}}
				/>
			</RichText>

			<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
				<Button
					onClick={modals.closeCallback}
					style={ButtonStyle.Hollow}
					width={ButtonWidth.SubmitButton}
				>
					<FormattedMessage {...messages.button} />
				</Button>
			</ModalButtonsLayout>
		</SimpleModal>
	);
};



export default VerifyWebsiteModal;
