/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserRoleInAccountQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  legacyUserId: GraphQL.Scalars['String']['input'];
}>;


export type UserRoleInAccountQuery = { readonly __typename?: 'Query', readonly userAccountMembership: { readonly __typename?: 'AccountMembership', readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } } | null };


export const UserRoleInAccountDocument = gql`
    query UserRoleInAccount($accountId: AccountId!, $legacyUserId: String!) {
  userAccountMembership(accountId: $accountId, legacyUserId: $legacyUserId) {
    account {
      id
    }
    role
    user {
      id
      legacyId
    }
  }
}
    `;

/**
 * __useUserRoleInAccountQuery__
 *
 * To run a query within a React component, call `useUserRoleInAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleInAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleInAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserRoleInAccountQuery(baseOptions: Apollo.QueryHookOptions<UserRoleInAccountQuery, UserRoleInAccountQueryVariables> & ({ variables: UserRoleInAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRoleInAccountQuery, UserRoleInAccountQueryVariables>(UserRoleInAccountDocument, options);
      }
export function useUserRoleInAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRoleInAccountQuery, UserRoleInAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRoleInAccountQuery, UserRoleInAccountQueryVariables>(UserRoleInAccountDocument, options);
        }
export function useUserRoleInAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserRoleInAccountQuery, UserRoleInAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserRoleInAccountQuery, UserRoleInAccountQueryVariables>(UserRoleInAccountDocument, options);
        }
export type UserRoleInAccountQueryHookResult = ReturnType<typeof useUserRoleInAccountQuery>;
export type UserRoleInAccountLazyQueryHookResult = ReturnType<typeof useUserRoleInAccountLazyQuery>;
export type UserRoleInAccountSuspenseQueryHookResult = ReturnType<typeof useUserRoleInAccountSuspenseQuery>;
export type UserRoleInAccountQueryResult = Apollo.QueryResult<UserRoleInAccountQuery, UserRoleInAccountQueryVariables>;