import classNames from 'classnames';
import React from 'react';



export enum CodeDataCellType {
	Default = 'default',
	New = 'new',
	Old = 'old',
}

type Props = {
	/** Additional style object generated by react-virtualized */
	cssStyle?: React.CSSProperties,
	children?: React.ReactNode,
	/** on mouse hover callback */
	onMouseEnter?: (e: any) => void,
	/** on mouse leave callback */
	onMouseLeave?: (e: any) => void,
	/** Specific type of cell reflecting eg. added value, removed value, ... */
	type?: CodeDataCellType,
};



const CodeDataCell: React.FC<Props> = (props) => {
	const {
		cssStyle,
		children,
		onMouseEnter,
		onMouseLeave,
		type = CodeDataCellType.Default,
	} = props;

	const componentClasses = classNames({
		'code-data-cell': true,
		'code-data-cell--is-empty': !children,
		['code-data-cell--' + type + '-type']: true,
	});

	return (
		<div
			className={componentClasses}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={cssStyle}
		>
			<div
				className="code-data-cell__content"
				style={{
					['--font-size' as any]: '12px',
				}}
			>
				<pre>{children}</pre>
			</div>
		</div>
	);
};



export default CodeDataCell;
