import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SupportIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				transform="translate(.8125 .761364)"
			>
				<path
					d="m25.5364555 25.5397259h-25.21212126v-25.21212117h25.21212126z"
					fill="none"
				/>
				<path
					d="m18.4460191 5.87348352h-11.03109092c-1.74042424 0-3.15151515 1.4110909-3.15151515 3.15151515v6.30381813c0 1.7404243 1.41109091 3.1515152 3.15151515 3.1515152h3.15151512l1.6592728 3.3185455c.2899394.5806666 1.1187878.5806666 1.4095151 0l1.6584849-3.3185455h3.152303c1.7404242 0 3.1515151-1.4110909 3.1515151-3.1515152v-6.30381813c0-1.74042425-1.4110909-3.15151515-3.1515151-3.15151515z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					d="m13.5928433 15.852835c0 .3860606-.3009697.695697-.7027878.695697-.4002425 0-.6909697-.3096364-.6909697-.695697 0-.3970909.2907272-.6956969.6909697-.6956969.4018181 0 .7027878.298606.7027878.6956969m-.4908485-7.0412727c.6799394 0 1.2487879.19854546 1.7057576.60745455.4561818.39709091.6791515.91551515.6791515 1.54503035 0 .7949696-.3450909 1.2582424-1.1361212 1.7987272-.5018788.3529697-.8028485.6295152-.8918788 1.0707273-.078.3970909-.2560606.5625455-.5790909.5625455-.3009697 0-.5239394-.2206061-.5239394-.5735758 0-.2765455.1000606-.5743636.312-.8942424.1331516-.2103637.3561212-.4301818.5010909-.5515152l.345091-.2867879c.1567878-.1213333.2678787-.2213939.3458787-.2765454.3017576-.253697.4569697-.5294546.4569697-.8493333 0-.6736364-.5231515-1.12587884-1.2929091-1.12587884-1.2030909 0-1.4591515.81624244-1.9610303.81624244-.2678787 0-.5018787-.2316364-.5018787-.507394 0-.29703025.200909-.51842419.590909-.77290904.5680607-.37424243 1.2259394-.56254546 1.95-.56254546"
					fill={color}
					fillRule="nonzero"
				/>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default SupportIconBuilder;

