import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ScreenMessage from '~/components/patterns/messages/embedded/ScreenMessage';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import useAccountCanSignup from '~/hooks/useAccountCanSignup';
import useAccountId from '~/hooks/useAccountId';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountState from '~/hooks/useAccountState';



const messages = defineMessages({
	addWebsiteButton: {
		id: 'ui.noWebsites.addWebsiteButton',
	},
	addWebsiteTitle: {
		id: 'ui.noWebsites.addWebsiteTitle',
	},
	signupButton: {
		id: 'ui.noWebsites.signupButton',
	},
	signupTitle: {
		id: 'ui.noWebsites.signupTitle',
	},
	nonManager: {
		id: 'ui.noWebsites.nonManager',
	},
});



const NoWebsites: React.FC = () => {
	const accountId = useAccountId();

	const accountCanSignup = useAccountCanSignup(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountState = useAccountState(accountId);

	function renderMessage(
		action: GraphQL.ActionWithAccount,
		linkRouteName,
		linkRouteParams,
		buttonMessage,
		titleMessage,
	) {
		if (accountId === null) {
			return null;
		}

		return (
			<WhenAccountActionAllowed
				accountId={accountId}
				action={action}
				messageForInsufficientPermissions={(
					<FormattedMessage {...messages.nonManager} />
				)}
				showMessage={false}
			>
				{({ isAllowed, message }) => {
					if (isAllowed.yes === false) {
						return (
							<ScreenMessage>
								{message}
							</ScreenMessage>
						);
					}

					return (
						<ScreenMessage
							ctaElement={(
								<Button
									linkRouteName={linkRouteName}
									linkRouteParams={linkRouteParams}
									size={ButtonSize.XLarge}
									style={ButtonStyle.Action}
								>
									{buttonMessage}
								</Button>
							)}
							indented={true}
							title={titleMessage}
						/>
					);
				}}
			</WhenAccountActionAllowed>
		);
	}

	const suggestAddingWebsite = (
		(
			accountPhase === GraphQL.AccountPhase.Trial
			&& accountState === GraphQL.AccountState.Preactive
		)
		|| (
			accountPhase === GraphQL.AccountPhase.Trial
			&& accountState === GraphQL.AccountState.Active
		)
		|| (
			accountPhase === GraphQL.AccountPhase.Customer
			&& accountState !== GraphQL.AccountState.Churned
		)
	);

	if (suggestAddingWebsite) {
		return renderMessage(
			GraphQL.ActionWithAccount.AddWebsite,
			'account.websites.new',
			{
				accountId,
			},
			(
				<FormattedMessage {...messages.addWebsiteButton} />
			),
			(
				<FormattedMessage {...messages.addWebsiteTitle} />
			),
		);
	}

	const suggestSignup = accountCanSignup;

	if (suggestSignup) {
		return renderMessage(
			GraphQL.ActionWithAccount.ViewSignup,
			'account.pricing',
			{
				accountId,
			},
			(
				<FormattedMessage {...messages.signupButton} />
			),
			(
				<FormattedMessage {...messages.signupTitle} />
			),
		);
	}

	return null;
};



export default NoWebsites;
