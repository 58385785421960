import classNames from 'classnames';
import React from 'react';



export enum DatatableHeaderCellAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

export enum DatatableHeaderCellColorStyle {
	AdobeAnalytics = 'adobe_analytics',
	Content = 'content',
	Conversions = 'conversions',
	CustomElements = 'custom_elements',
	EnrichmentFields = 'enrichment_fields',
	Fundamentals = 'fundamentals',
	GoogleAnalytics = 'google_analytics',
	GoogleSearchConsole = 'google_search_console',
	History = 'history',
	Indexability = 'indexability',
	Lighthouse = 'lighthouse',
	Media = 'media',
	Realtions = 'relations',
	SchemaOrg = 'schema_org',
	SearchEngineActivity = 'search_engine_activity',
	Social = 'social',
}

export enum DatatableHeaderCellPadding {
	Default = 'default',
	CheckboxPadding = 'checkbox-padding',
	NoPadding = 'no-padding',
}

export enum DatatableHeaderCellSize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	alignment?: DatatableHeaderCellAlignment,
	/** Attached action button to whole cell */
	attachedButton?: React.ReactNode,
	children?: React.ReactNode,
	colorStyle?: DatatableHeaderCellColorStyle,
	/** CSS style provided by react-virtualized */
	cssStyle?: React.CSSProperties,
	isHighlighted?: boolean,
	isSticky?: boolean,
	isStriped?: boolean,
	name?: string,
	onMouseEnterCallback?: () => void,
	onMouseLeaveCallback?: () => void,
	rowIndex?: number,
	padding?: DatatableHeaderCellPadding,
	separator?: boolean,
	size?: DatatableHeaderCellSize,
	width?: number,
};



const DatatableHeaderCell: React.FC<Props> = (props) => {
	const {
		alignment = DatatableHeaderCellAlignment.Left,
		attachedButton,
		colorStyle,
		cssStyle,
		children,
		isHighlighted,
		isSticky,
		isStriped,
		name,
		onMouseEnterCallback,
		onMouseLeaveCallback,
		padding = DatatableHeaderCellPadding.Default,
		rowIndex,
		separator = true,
		size = DatatableHeaderCellSize.Default,
		width,
	} = props;

	const cellClasses = classNames({
		'datatable-header-cell': true,
		'datatable-header-cell--center-aligned': alignment === DatatableHeaderCellAlignment.Center,
		'datatable-header-cell--right-aligned': alignment === DatatableHeaderCellAlignment.Right,
		'datatable-header-cell--highlighted-column': isHighlighted,
		'datatable-header-cell--has-padding': padding !== DatatableHeaderCellPadding.NoPadding,
		'datatable-header-cell--checkbox': padding === DatatableHeaderCellPadding.CheckboxPadding,
		'datatable-header-cell--has-separator': separator,
		'datatable-header-cell--is-striped': isStriped,
		'datatable-header-cell--sticky': isSticky,
		'datatable-header-cell--has-top-border': rowIndex !== undefined && rowIndex > 0,
		'datatable-header-cell--has-bottom-border': rowIndex !== undefined,
		['datatable-header-cell--' + size]: size !== DatatableHeaderCellSize.Default,
		['datatable-header-cell--cell-' + name]: !!name,
		['datatable-header-cell--style-' + colorStyle]: !!colorStyle,
	});

	const componentStyle: React.CSSProperties = {
		...cssStyle,
	};

	if (width) {
		componentStyle.width = width;
	}

	return (
		<div
			className={classNames({
				'datatable-header-cell__wrapper': true,
				['datatable-header-cell__wrapper--cell-' + name]: !!name,
			})}
			onMouseEnter={onMouseEnterCallback}
			onMouseLeave={onMouseLeaveCallback}
			style={{
				...componentStyle,
				width,
			}}
		>
			<div
				className={cellClasses}
			>
				{attachedButton && (
					<div className="datatable-header-cell__attached-btn">{attachedButton}</div>
				)}
				<div className="datatable-header-cell__container">
					{children}
				</div>
			</div>
		</div>
	);
};



export default DatatableHeaderCell;
