import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import {
	Intercom,
} from '~/globals';



class IntercomActivator extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleClick = this._handleClick.bind(this);
	}



	_handleClick(event) {
		if (event) {
			event.preventDefault();
		}

		Intercom()('show');
	}



	render() {
		const {
			children,
		} = this.props;

		return (
			children && React.cloneElement(
				children,
				{
					onClick: this._handleClick,
					onClickCallback: this._handleClick,
				},
			)
		);
	}

}

IntercomActivator.propTypes = {
	children: PropTypes.element.isRequired,
};



export default IntercomActivator;
