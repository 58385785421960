export default <const>{
	'states.CA.AB': `Alberta`,
	'states.CA.BC': `British Columbia`,
	'states.CA.MB': `Manitoba`,
	'states.CA.NB': `New Brunswick`,
	'states.CA.NL': `Newfoundland`,
	'states.CA.NS': `Nova Scotia`,
	'states.CA.NT': `Northwest Territories`,
	'states.CA.NU': `Nunavut`,
	'states.CA.ON': `Ontario`,
	'states.CA.PE': `Prince Edward Island`,
	'states.CA.QC': `Quebec`,
	'states.CA.SK': `Saskatchewan`,
	'states.CA.YT': `Yukon`,
	'states.US.AA': `Armed Forces Americas`,
	'states.US.AE': `Armed Forces`,
	'states.US.AK': `Alaska`,
	'states.US.AL': `Alabama`,
	'states.US.AP': `Armed Forces Pacific`,
	'states.US.AR': `Arkansas`,
	'states.US.AS': `America Samoa`,
	'states.US.AZ': `Arizona`,
	'states.US.CA': `California`,
	'states.US.CO': `Colorado`,
	'states.US.CT': `Connecticut`,
	'states.US.DC': `District of Columbia`,
	'states.US.DE': `Delaware`,
	'states.US.FL': `Florida`,
	'states.US.FM': `Federated States of Micronesia`,
	'states.US.GA': `Georgia`,
	'states.US.GU': `Guam`,
	'states.US.HI': `Hawaii`,
	'states.US.IA': `Iowa`,
	'states.US.ID': `Idaho`,
	'states.US.IL': `Illinois`,
	'states.US.IN': `Indiana`,
	'states.US.KS': `Kansas`,
	'states.US.KY': `Kentucky`,
	'states.US.LA': `Louisiana`,
	'states.US.MA': `Massachusetts`,
	'states.US.MD': `Maryland`,
	'states.US.ME': `Maine`,
	'states.US.MH': `Marshall Islands`,
	'states.US.MI': `Michigan`,
	'states.US.MN': `Minnesota`,
	'states.US.MO': `Missouri`,
	'states.US.MS': `Mississippi`,
	'states.US.MT': `Montana`,
	'states.US.NC': `North Carolina`,
	'states.US.ND': `North Dakota`,
	'states.US.NE': `Nebraska`,
	'states.US.NH': `New Hampshire`,
	'states.US.NJ': `New Jersey`,
	'states.US.NM': `New Mexico`,
	'states.US.NV': `Nevada`,
	'states.US.NY': `New York`,
	'states.US.MP': `Northern Mariana Islands`,
	'states.US.OH': `Ohio`,
	'states.US.OK': `Oklahoma`,
	'states.US.OR': `Oregon`,
	'states.US.PA': `Pennsylvania`,
	'states.US.PR': `Puerto Rico`,
	'states.US.PW': `Palau`,
	'states.US.RI': `Rhode Island`,
	'states.US.SC': `South Carolina`,
	'states.US.SD': `South Dakota`,
	'states.US.TN': `Tennessee`,
	'states.US.TX': `Texas`,
	'states.US.UT': `Utah`,
	'states.US.VA': `Virginia`,
	'states.US.VI': `Virgin Islands`,
	'states.US.VT': `Vermont`,
	'states.US.WA': `Washington`,
	'states.US.WI': `Wisconsin`,
	'states.US.WV': `West Virginia`,
	'states.US.WY': `Wyoming`,
};
