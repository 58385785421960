import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SpinnerIconBuilder: IconBuilder = {
	icon: ({ color = '#89c32e' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="crawling-spinner"
					transform="translate(3.000000, 3.000000)"
				>
					<path
						d="M9,0.4921875 C4.30136719,0.4921875 0.4921875,4.30136719 0.4921875,9 C0.4921875,13.6986328 4.30136719,17.5078125 9,17.5078125 C13.6986328,17.5078125 17.5078125,13.6986328 17.5078125,9 C17.5078125,4.30136719 13.6986328,0.4921875 9,0.4921875 Z"
						fill={color}
						id="Stroke-302"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M3.8671875,9 C3.8671875,11.8347123 6.16528764,14.1328125 9,14.1328125"
						id="Stroke-302-Copy"
						stroke="#FFFFFF"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M14.1328125,9 C14.1328125,6.16528764 11.8347123,3.8671875 9,3.8671875"
						id="Stroke-302-Copy-2"
						stroke="#FFFFFF"
						strokeLinecap="round"
						strokeWidth="2"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SpinnerIconBuilder;

