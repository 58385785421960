import React from 'react';



function useKeepAround<T = any>(currentValue: T): T {
	const keptValue = React.useRef<T>(currentValue);

	React.useEffect(
		() => {
			if (currentValue !== null && currentValue !== undefined) {
				keptValue.current = currentValue;
			}
		},
		[
			currentValue,
		],
	);

	return currentValue ?? keptValue.current;
}



export default useKeepAround;
