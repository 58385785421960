import {
	useUpdateWebsiteIntegrationsMutation,
} from './useUpdateWebsiteIntegrations.gql';



function useUpdateWebsiteIntegrations() {
	const [updateWebsiteIntegrations] = useUpdateWebsiteIntegrationsMutation();

	return updateWebsiteIntegrations;
}



export default useUpdateWebsiteIntegrations;
