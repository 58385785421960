import classNames from 'classnames';
import React from 'react';
import {
	Link,
} from 'react-router5';

import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';



export enum SimpleNavigationItemHighlightStyle {
	Strong = 'strong',
	Decent = 'decent',
}

type Props = {
	children?: React.ReactNode,
	highlightStyle?: SimpleNavigationItemHighlightStyle,
	/** Possible explanation visible on :hover */
	hint?: React.ReactNode,
	isDisabled?: boolean,
	/** Active highlighted style of menu item */
	isHighlighted?: boolean,
	/** By setting routeName we can change this component to Link instance */
	linkRouteName?: string,
	linkRouteParams?: {},
	/** Callback called when clicking on whole item */
	onClickCallback?: () => void,
	/** Additional special call-to-action element, like additional clickable icon vibisle on :hover */
	specialCTAElement?: React.ReactNode,
};



const SimpleNavigationItem: React.FC<Props> = (props) => {
	const {
		children,
		highlightStyle = SimpleNavigationItemHighlightStyle.Decent,
		hint,
		isDisabled,
		isHighlighted,
		linkRouteName,
		linkRouteParams,
		onClickCallback,
		specialCTAElement,
	} = props;

	const handleItemClick = React.useCallback(
		(e) => {
			if (!onClickCallback) {
				return false;
			}

			e.preventDefault();

			onClickCallback();
		},
		[
			onClickCallback,
		],
	);

	const renderSpecialCTAElement = () => {
		if (!specialCTAElement) {
			return null;
		}

		return (
			<span className="simple-nav-item__cta-element">
				{specialCTAElement}
			</span>
		);
	};

	const renderLabel = () => (
		<span className="simple-nav-item__label">
			<span className="simple-nav-item__text-content">
				{children}
			</span>
		</span>
	);

	const componentClasses = classNames({
		'simple-nav-item': true,
		'simple-nav-item--is-disabled': isDisabled,
		'simple-nav-item--is-decently-highlighted': isHighlighted && highlightStyle === SimpleNavigationItemHighlightStyle.Decent && !isDisabled,
		'simple-nav-item--is-strongly-highlighted': isHighlighted && highlightStyle === SimpleNavigationItemHighlightStyle.Strong && !isDisabled,
		'simple-nav-item--is-clickable': (onClickCallback || linkRouteName) && !isDisabled,
	});

	let output;

	if (linkRouteName) {
		output = (
			<Link
				className={componentClasses}
				onClick={handleItemClick}
				routeName={linkRouteName}
				routeParams={linkRouteParams}
			>
				{renderLabel()}
				{renderSpecialCTAElement()}
			</Link>
		);
	} else {
		output = (
			<div
				className={componentClasses}
				onClick={handleItemClick}
			>
				{renderLabel()}
				{renderSpecialCTAElement()}
			</div>
		);
	}

	if (hint) {
		output = (
			<Hint
				attachment={HintAttachment.Center}
				blurDelay={50}
				inline={false}
				popup={hint}
				popupOffset={[0, -10]}
			>
				{output}
			</Hint>
		);
	}

	return output;
};



export default SimpleNavigationItem;
