/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KingdomSettingsGuaranteedBlacklistDomainsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type KingdomSettingsGuaranteedBlacklistDomainsQuery = { readonly __typename?: 'Query', readonly kingdomSettings: { readonly __typename?: 'KingdomSettings', readonly guaranteedBlacklistDomains: ReadonlyArray<string> } | null };


export const KingdomSettingsGuaranteedBlacklistDomainsDocument = gql`
    query KingdomSettingsGuaranteedBlacklistDomains {
  kingdomSettings {
    guaranteedBlacklistDomains
  }
}
    `;

/**
 * __useKingdomSettingsGuaranteedBlacklistDomainsQuery__
 *
 * To run a query within a React component, call `useKingdomSettingsGuaranteedBlacklistDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKingdomSettingsGuaranteedBlacklistDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKingdomSettingsGuaranteedBlacklistDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKingdomSettingsGuaranteedBlacklistDomainsQuery(baseOptions?: Apollo.QueryHookOptions<KingdomSettingsGuaranteedBlacklistDomainsQuery, KingdomSettingsGuaranteedBlacklistDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KingdomSettingsGuaranteedBlacklistDomainsQuery, KingdomSettingsGuaranteedBlacklistDomainsQueryVariables>(KingdomSettingsGuaranteedBlacklistDomainsDocument, options);
      }
export function useKingdomSettingsGuaranteedBlacklistDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KingdomSettingsGuaranteedBlacklistDomainsQuery, KingdomSettingsGuaranteedBlacklistDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KingdomSettingsGuaranteedBlacklistDomainsQuery, KingdomSettingsGuaranteedBlacklistDomainsQueryVariables>(KingdomSettingsGuaranteedBlacklistDomainsDocument, options);
        }
export function useKingdomSettingsGuaranteedBlacklistDomainsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<KingdomSettingsGuaranteedBlacklistDomainsQuery, KingdomSettingsGuaranteedBlacklistDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<KingdomSettingsGuaranteedBlacklistDomainsQuery, KingdomSettingsGuaranteedBlacklistDomainsQueryVariables>(KingdomSettingsGuaranteedBlacklistDomainsDocument, options);
        }
export type KingdomSettingsGuaranteedBlacklistDomainsQueryHookResult = ReturnType<typeof useKingdomSettingsGuaranteedBlacklistDomainsQuery>;
export type KingdomSettingsGuaranteedBlacklistDomainsLazyQueryHookResult = ReturnType<typeof useKingdomSettingsGuaranteedBlacklistDomainsLazyQuery>;
export type KingdomSettingsGuaranteedBlacklistDomainsSuspenseQueryHookResult = ReturnType<typeof useKingdomSettingsGuaranteedBlacklistDomainsSuspenseQuery>;
export type KingdomSettingsGuaranteedBlacklistDomainsQueryResult = Apollo.QueryResult<KingdomSettingsGuaranteedBlacklistDomainsQuery, KingdomSettingsGuaranteedBlacklistDomainsQueryVariables>;