import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ImageIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M24,3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V21a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3ZM22,21a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"
				fill={color}
			/>
			<circle
				cx="14"
				cy="9.5"
				fill={color}
				r="3"
			/>
			<path
				d="M14,5.25a.75.75,0,0,0,.75-.75V4a.75.75,0,0,0-1.5,0v.5A.75.75,0,0,0,14,5.25Z"
				fill={color}
			/>
			<path
				d="M9.935,6.494A.75.75,0,1,0,11,5.434l-.353-.354A.75.75,0,0,0,9.581,6.141Z"
				fill={color}
			/>
			<path
				d="M8.5,10.25H9a.75.75,0,0,0,0-1.5H8.5a.75.75,0,0,0,0,1.5Z"
				fill={color}
			/>
			<path
				d="M18.065,12.506a.75.75,0,0,0-1.06,1.06l.353.354a.75.75,0,0,0,1.061-1.061Z"
				fill={color}
			/>
			<path
				d="M18.25,9.5a.75.75,0,0,0,.75.75h.5a.75.75,0,0,0,0-1.5H19A.75.75,0,0,0,18.25,9.5Z"
				fill={color}
			/>
			<path
				d="M17.535,6.715a.743.743,0,0,0,.53-.221l.354-.353A.75.75,0,0,0,17.358,5.08l-.353.354a.751.751,0,0,0,.53,1.281Z"
				fill={color}
			/>
			<path
				d="M15.8,16.086a4.573,4.573,0,0,0-1.449.234.249.249,0,0,0-.12.388,7.827,7.827,0,0,1,1.518,3.654.161.161,0,0,0,.159.138h3.154a1.536,1.536,0,0,0,1.264-.663A4.607,4.607,0,0,0,15.8,16.086Z"
				fill={color}
			/>
			<path
				d="M7.914,14.551a6.875,6.875,0,0,0-4.32,1.518.25.25,0,0,0-.094.2v2.7A1.535,1.535,0,0,0,5.035,20.5h9.427a.251.251,0,0,0,.193-.09.249.249,0,0,0,.053-.205A6.909,6.909,0,0,0,7.914,14.551Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ImageIconBuilder;
