import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ComplexListField, {
	type ComplexListFieldMaximumNumberOfRowsReachedTooltipInput,
	type ComplexListFieldRendererInput,
	type ComplexListFieldValidationInput,
} from '~/components/logic/customElements/extractionSteps/ComplexListField';
import ExpressionField from './ExpressionField';
import MethodSelectionField from './MethodSelectionField';
import OccurrenceSelectionField from './OccurrenceSelectionField';

import {
	EXTRACTION_METHOD_CSS,
	EXTRACTION_METHOD_REGEXP,
	EXTRACTION_METHOD_XPATH,
	OCCURRENCES_ALL,
	validateExpressionOfCss,
	validateExpressionOfRegexp,
	validateExpressionOfXPath,
} from '~/model/customElements';



const messages = defineMessages({
	addButton: {
		id: 'ui.customElements.extractionSteps.actions.addAnotherStep',
	},
	columnExpression: {
		id: 'ui.customElements.extractionSteps.header.expression.label',
	},
	columnMethod: {
		id: 'ui.customElements.extractionSteps.header.method',
	},
	columnOccurrence: {
		id: 'ui.customElements.extractionSteps.header.occurrence',
	},
	columnStep: {
		id: 'ui.customElements.extractionSteps.header.step',
	},
	deleteStepButtonLabel: {
		id: 'ui.customElements.extractionSteps.actions.deleteStep.button',
	},
	formErrorsBlank: {
		id: 'ui.formErrors.blank',
	},
	formErrorsInvalidCss: {
		id: 'ui.customElements.formErrors.invalidCss',
	},
	formErrorsInvalidRegexp: {
		id: 'ui.customElements.formErrors.invalidRegexp',
	},
	formErrorsInvalidXPath: {
		id: 'ui.customElements.formErrors.invalidXPath',
	},
	formErrorsNotRegexpAfterRegexp: {
		id: 'ui.customElements.formErrors.notRegexpAfterRegexp',
	},
	tooMany: {
		id: 'ui.customElements.extractionSteps.tooMany',
	},
});



type Props = {
	name: string,
	showAddButton: boolean,
};

const ExtractionStepsConfigurator: React.FC<Props> = (props) => {
	const {
		name,
		showAddButton,
	} = props;

	// <TableDeleteButton
	// 	confirmation={!!(values[ruleTypeFieldName] || values[ruleExpressionFieldName]) ? {
	// 		buttonLabel: (
	// 			<FormattedMessage {...messages.deleteStepButtonLabel} />
	// 		),
	// 	} : undefined}
	// 	onClick={onRemoveStepCallback.bind(this, step)}
	// />

	const fields = React.useMemo(
		() => {
			return [
				{
					label: (
						<FormattedMessage {...messages.columnMethod} />
					),
					name: 'method',
					renderer: ({ fieldName }: ComplexListFieldRendererInput) => (
						<MethodSelectionField
							name={fieldName}
							width={130 - 8}
						/>
					),
					validation: ({ f, getItemValue, listItems, rowIndex }: ComplexListFieldValidationInput) => ([
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsBlank} />
							),
							rule: ({ value, values }) => {
								return !!value || (rowIndex !== 0 && !getItemValue(values, 'expression'));
							},
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsNotRegexpAfterRegexp} />
							),
							rule: ({ value, values }) => {
								const method = value;

								if (method) {
									let precededByRegexp = false;

									for (const { itemValues: otherItemValues, rowIndex: otherRowIndex } of listItems(values)) {
										if (otherRowIndex === rowIndex) {
											if (precededByRegexp && method !== EXTRACTION_METHOD_REGEXP) {
												return false;
											}

											return true;
										}

										const otherStepMethod = otherItemValues.method;

										if (precededByRegexp && otherStepMethod !== EXTRACTION_METHOD_REGEXP) {
											return true;
										}

										if (otherStepMethod === EXTRACTION_METHOD_REGEXP) {
											precededByRegexp = true;
										}
									}
								}

								return true;
							},
						}),
					]),
					width: 130,
				},
				{
					label: (
						<FormattedMessage {...messages.columnExpression} />
					),
					name: 'expression',
					renderer: ({ fieldName }: ComplexListFieldRendererInput) => (
						<ExpressionField
							name={fieldName}
							width={230 - 8}
						/>
					),
					validation: ({ f, getItemValue, rowIndex }: ComplexListFieldValidationInput) => ([
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsBlank} />
							),
							rule: ({ value, values }) => {
								return !!value || (rowIndex !== 0 && !getItemValue(values, 'method'));
							},
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsInvalidCss} />
							),
							rule: ({ value, values }) => {
								if (getItemValue(values, 'method') !== EXTRACTION_METHOD_CSS) {
									return true;
								}

								return validateExpressionOfCss(value);
							},
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsInvalidRegexp} />
							),
							rule: ({ value, values }) => {
								if (getItemValue(values, 'method') !== EXTRACTION_METHOD_REGEXP) {
									return true;
								}

								return validateExpressionOfRegexp(value);
							},
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsInvalidXPath} />
							),
							rule: ({ value, values }) => {
								if (getItemValue(values, 'method') !== EXTRACTION_METHOD_XPATH) {
									return true;
								}

								return validateExpressionOfXPath(value);
							},
						}),
					]),
					width: 230,
				},
				{
					defaultValue: OCCURRENCES_ALL,
					label: (
						<FormattedMessage {...messages.columnOccurrence} />
					),
					name: 'occurrence',
					renderer: ({ fieldName }: ComplexListFieldRendererInput) => (
						<OccurrenceSelectionField
							name={fieldName}
							width={120 - 8}
						/>
					),
					width: 120,
				},
			];
		},
		[],
	);

	const maximumNumberOfRowsReachedTooltip = React.useCallback(
		({ maximumNumberOfRows }: ComplexListFieldMaximumNumberOfRowsReachedTooltipInput) => (
			<FormattedMessage
				{...messages.tooMany}
				values={{
					number: maximumNumberOfRows,
				}}
			/>
		),
		[],
	);

	return (
		<ComplexListField
			addButtonLabel={(
				<FormattedMessage {...messages.addButton} />
			)}
			addExtraEmptyRow={false}
			fields={fields}
			maximumNumberOfRows={6}
			maximumNumberOfRowsReachedTooltip={maximumNumberOfRowsReachedTooltip}
			name={name}
			showAddButton={showAddButton}
			showRowNumbers={true}
		/>
	);
};



export default ExtractionStepsConfigurator;
