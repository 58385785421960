import {
	loadIncidentsViaApi,
} from '~/model/alerts';

import {
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';



export const ACTION_STORE_INCIDENTS = 'ACTION_STORE_INCIDENTS';



export function loadIncidents() {
	return (dispatch, getState) => {
		const websiteId = selectedWebsiteIdSelector(getState());

		return loadIncidentsViaApi(websiteId)
			.then(({ incidents }) => {
				dispatch({
					type: ACTION_STORE_INCIDENTS,
					incidents,
					websiteId,
				});
			});
	};
}
