import React from 'react';

import FormsList from '~/components/atoms/lists/FormsList';
import AccountMonitoringForm from '~/components/app/AccountMonitoringForm';



const MonitoringGroup: React.FC = () => {
	return (
		<FormsList>
			<AccountMonitoringForm />
		</FormsList>
	);
};



export default MonitoringGroup;
