import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountUnpaidSituationQuery,
} from './useAccountUnpaidSituation.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



type ReturnType = {
	unpaidPhase: NonNullable<GraphQL.Account['unpaidPhase']>,
	unpaidSince: NonNullable<GraphQL.Account['unpaidSince']>,
	unpaidSituation: NonNullable<GraphQL.Account['unpaidSituation']>,
};



function useAccountUnpaidSituation(accountId: CK.AccountId | null): ReturnType | null {
	const { data } = useAccountPropertiesQuery(
		useAccountUnpaidSituationQuery,
		accountId,
	);

	const unpaidPhase = data?.account?.unpaidPhase ?? null;
	const unpaidSince = data?.account?.unpaidSince ?? null;
	const unpaidSituation = data?.account?.unpaidSituation ?? null;

	return React.useMemo(
		() => {
			if (
				unpaidPhase === null
				|| unpaidSince === null
				|| unpaidSituation === null
			) {
				return null;
			}

			return {
				unpaidPhase,
				unpaidSince,
				unpaidSituation,
			};
		},
		[
			unpaidPhase,
			unpaidSince,
			unpaidSituation,
		],
	);
}



export default useAccountUnpaidSituation;
