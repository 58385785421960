import {
	List,
} from 'immutable';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';
import {
	defineMessages,
	injectIntl,
} from 'react-intl';

import DonutPagesChart from '../components/DonutPagesChart';



const messages = defineMessages({
	missing: {
		id: 'ui.screen.pagesGraphs.content.missing',
	},
});



class HreflangLanguageChart extends PureComponent {

	constructor(props, context) {
		super(props, context);

		const {
			intl,
		} = this.props;

		this.labels = {
			missing: intl.formatMessage(messages.missing),
		};

		this.randomColors = [
			'#367be2',
			'#FE8719',
			'#FF5959',
		];

		this.specificColors = {
			missing: '#8595A6',
		};
	}



	render() {
		let {
			data,
		} = this.props;

		if (data) {
			data = data.filter((item) => {
				return (
					item.get('value') !== 'others'
					|| item.get('count') > 0
				);
			});
		}

		return (
			<DonutPagesChart
				data={data}
				labels={this.labels}
				name="hreflang_language"
				percentageFactor={100}
				randomColors={this.randomColors}
				specificColors={this.specificColors}
			/>
		);
	}

}

HreflangLanguageChart.propTypes = {
	data: PropTypes.instanceOf(List),
};



export default injectIntl(HreflangLanguageChart);
