import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CrownKingIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<g>
				<path
					d="M19.7,23.4H4.3c-0.6,0-1-0.4-1-1v-2.6c0-0.6,0.4-1,1-1h15.4c0.6,0,1,0.4,1,1v2.6C20.7,23,20.2,23.4,19.7,23.4z"
					fill={color}
				/>
			</g>
			<path
				d="M20.5,3.3l-4.7,9.5l-3.3-9.5h-1.1l-3.3,9.5L3.4,3.3H2l1.5,13.9c0,0.3,0.2,0.4,0.5,0.4H20c0.3,0,0.5-0.2,0.5-0.4L22,3.3H20.5z"
				fill={color}
			/>
			<circle
				cx="12"
				cy="2.6"
				fill={color}
				r="2.3"
			/>
			<circle
				cx="2.7"
				cy="2.6"
				fill={color}
				r="2.3"
			/>
			<circle
				cx="21.2"
				cy="2.6"
				fill={color}
				r="2.3"
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CrownKingIconBuilder;
