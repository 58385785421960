import React from 'react';

import {
	Intercom,
} from '~/globals';



function useIntercom() {
	return {
		showIntercom: React.useCallback(() => Intercom()('show'), []),
	};
}



export default useIntercom;
