import {
	List,
	Map,
} from 'immutable';

import {
	encodeFilterParameter,
} from './filterParameter';

import removeDefaultValues from '~/utilities/removeDefaultValues';



export const CHANGE_BETTER = 'better';
export const CHANGE_SAME = 'same';
export const CHANGE_WORSE = 'worse';



export const DEFAULT_FILTER = Map({
	change: List([
		CHANGE_BETTER,
		CHANGE_WORSE,
	]),
	scope: 'website',
});



export function createFilterParameter(filter) {
	return encodeFilterParameter({
		defaultFilter: DEFAULT_FILTER,
		filter,
	});
}



export function filterIssuesComparison(issues, filter) {
	return issues.filter((issue) => {
		let result = true;

		if (filter.get('change').size < 2) {
			result = result && filter.get('change').includes(issue.health.change.type);
		}

		return result;
	});
}



export function removeDefaultFilterValues(filter) {
	return removeDefaultValues(filter, DEFAULT_FILTER);
}



const ORDER = [
	CHANGE_WORSE,
	CHANGE_SAME,
	CHANGE_BETTER,
];

export function sortIssuesComparison(issues) {
	return [...issues].sort((issueA, issueB) => {
		const healthChangeA = issueA.health.change;
		const healthChangeB = issueB.health.change;

		const issueAState = ORDER.indexOf(healthChangeA.type);
		const issueBState = ORDER.indexOf(healthChangeB.type);

		if (issueAState === issueBState) {
			const issueAScore = healthChangeA.previous.toGain - healthChangeA.next.toGain;
			const issueBScore = healthChangeB.previous.toGain - healthChangeB.next.toGain;

			if (issueAScore === issueBScore) {
				return issueA.name < issueB.name;
			}

			if (healthChangeA.type === CHANGE_WORSE) {
				return issueAScore < issueBScore ? -1 : 1;
			}

			return issueAScore < issueBScore ? 1 : -1;
		}

		return issueAState < issueBState ? -1 : 1;
	});
}
