import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import Measure from 'react-measure';

import AttachedHeading from '~/components/patterns/structuredValues/AttachedHeading';
import Title from '~/components/patterns/headings/Title';



const SIDEBAR_LAYOUT_BREAKPOINT = 820;



class RecordDetailLayout extends Component {

	constructor(props, context) {
		super(props, context);

		this._refMeasure = React.createRef();

		this._handleComponentResize = this._handleComponentResize.bind(this);

		this.state = {
			compactLayout: null,
		};
	}



	_setLayoutType(elementWidth) {
		this.setState({
			compactLayout: elementWidth < SIDEBAR_LAYOUT_BREAKPOINT,
		});
	}



	componentDidMount() {
		const elementWidth = this._refMeasure.current.offsetWidth;
		this._setLayoutType(elementWidth);
	}



	_handleComponentResize(contentRect) {
		this._setLayoutType(contentRect.bounds.width);
	}



	render() {
		const {
			additionalCTAElements,
			additionalCTAElementsTitle,
			children,
			navigation,
			overview,
		} = this.props;

		const {
			compactLayout,
		} = this.state;

		const layoutClasses = classNames({
			'record-detail-layout': true,
			'record-detail-layout--has-sidebar': compactLayout === false,
		});

		return (
			<Measure
				bounds={true}
				innerRef={this._refMeasure}
				onResize={this._handleComponentResize}
			>
				{({ measureRef }) => (
					<section
						className={layoutClasses}
						ref={measureRef}
					>
						{overview && (
							<div className="record-detail-layout__overview">
								{overview}
							</div>
						)}

						{compactLayout !== null && (
							<div className="record-detail-layout__body">
								<div className="record-detail-layout__sidebar">
									{navigation && (
										<div className="record-detail-layout__navigation">
											{React.cloneElement(navigation, {
												compactLayout,
											})}
										</div>
									)}
									{!compactLayout && additionalCTAElements && (
										<div className="record-detail-layout__cta-elements">
											{additionalCTAElements}
										</div>
									)}
								</div>
								<div className="record-detail-layout__content">
									{typeof children === 'function' ? children({
										compactLayout,
									}) : children}
								</div>
								{compactLayout && additionalCTAElements && (
									<div className="record-detail-layout__cta-elements">
										<AttachedHeading
											heading={(
												<Title>
													{additionalCTAElementsTitle}
												</Title>
											)}
											maxWidth={260}
										>
											{additionalCTAElements}
										</AttachedHeading>
									</div>
								)}
							</div>
						)}
					</section>
				)}
			</Measure>
		);
	}

}

RecordDetailLayout.propTypes = {
	/** Additional call-to-action elements */
	additionalCTAElements: PropTypes.node,
	/** Title for additional call-to-action elements, visible in compact layout */
	additionalCTAElementsTitle: PropTypes.node,
	/** Navigation */
	navigation: PropTypes.node,
	/** Record overview info */
	overview: PropTypes.node,
};



export default RecordDetailLayout;
