import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GlobalFormMessage from '~/components/atoms/forms/basis/GlobalFormMessage';



const messages = defineMessages({
	['insufficient_funds']: {
		id: 'ui.recurlyErrors.insufficientFunds',
	},
	fallback: {
		id: 'ui.recurlyErrors.cardDeclined',
	},
});



type Props = {
	errorCode: string,
};

const PaymentFailureFormError: React.FC<Props> = (props) => {
	const {
		errorCode,
	} = props;

	return (
		<GlobalFormMessage name={errorCode}>
			{({ globalError }) => {
				return (
					<FormattedMessage
						{...messages[globalError.details] ?? messages.fallback}
					/>
				);
			}}
		</GlobalFormMessage>
	);
};



export default PaymentFailureFormError;
