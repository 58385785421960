import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AddWebsiteIntegrationsForm from '~/components/app/AddWebsiteIntegrationsForm';
import Copy from '~/components/logic/Copy';
import MarginsList, {
	SIZE_LARGE as MARGINS_SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';



const messages = defineMessages({
	breadcrumb: {
		id: 'ui.websites.new.steps.integrations.inBreadcrumbs',
	},
	sidebar: {
		id: 'ui.websites.new.steps.integrations.sidebar',
	},
	title: {
		id: 'ui.websites.new.steps.integrations.title',
	},
});



type Props = {
	onContinueCallback: () => void,
	websiteId: CK.WebsiteId | null,
};

const AddWebsiteModalIntegrationsStep: React.FC<Props> = (props) => {
	const {
		onContinueCallback,
		websiteId,
	} = props;

	return (
		<MultiStepModalStep
			breadcrumb={(
				<FormattedMessage {...messages.breadcrumb} />
			)}
			headerIconType={MultiStepModalStepHeaderIconType.Plus}
			name="integrations-step"
			sidebar={(
				<MarginsList size={MARGINS_SIZE_LARGE}>
					<RichText>
						<Copy {...messages.sidebar} />
					</RichText>
				</MarginsList>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			{websiteId !== null && (
				<AddWebsiteIntegrationsForm
					onSkipCallback={onContinueCallback}
					onSubmitCallback={onContinueCallback}
					websiteId={websiteId}
				/>
			)}
		</MultiStepModalStep>
	);
};



export default AddWebsiteModalIntegrationsStep;
