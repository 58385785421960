import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AccountIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g transform="translate(-18.000000, -780.000000)">
				<g
					id="User-menu"
					transform="translate(0.000000, 766.000000)"
				>
					<g
						id="Teams-Copy"
						transform="translate(0.000000, 4.000000)"
					>
						<g
							id="Group-7"
							transform="translate(19.000000, 11.000000)"
						>
							<polyline
								fill="none"
								id="Stroke-1"
								points="5.9,17.3 1.1,17.3 1.1,4.6 17.3,4.6 17.3,6.6"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							/>
							<polyline
								fill="none"
								id="Stroke-3"
								points="5.7,4.6 5.7,0.8 12.7,0.8 12.7,4.6"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							/>
							<path
								d="M15.6,18.2c-1-0.2-1.7-1.2-1.5-2.2c0.2-1,1.2-1.7,2.3-1.5c1,0.2,1.7,1.2,1.5,2.2
								C17.7,17.7,16.6,18.4,15.6,18.2z M20.8,18c0.2-0.6,0.2-0.7,0.3-1.3l1.6-0.8c0.1-0.1,0.2-0.3,0.1-0.4L22,12.9
								c0-0.1-0.2-0.2-0.4-0.1l-1.8,0.4c-0.5-0.5-0.5-0.5-1-0.9l0.1-1.8c0-0.1-0.1-0.4-0.2-0.4l-2.7-0.5c-0.1,0-0.4,0.1-0.4,0.2
								l-0.6,1.7c-0.7,0.2-0.7,0.2-1.3,0.5l-1.5-1c-0.1-0.1-0.4-0.1-0.5,0l-1.8,2.1c-0.1,0.1,0,0.3,0.1,0.4l1.2,1.3
								c-0.2,0.6-0.2,0.7-0.3,1.3l-1.7,0.8C9,17,9,17.2,9.1,17.3l0.9,2.6c0,0.1,0.3,0.2,0.4,0.1l1.8-0.3c0.4,0.4,0.5,0.5,1,0.9
								l-0.1,1.8c0,0.1,0.1,0.3,0.3,0.3l2.7,0.5c0.1,0,0.4-0.1,0.4-0.2l0.6-1.7c0.7-0.2,0.7-0.2,1.3-0.5l1.6,1c0.1,0.1,0.4,0,0.4-0.1
								l1.8-2.1c0.1-0.1,0.1-0.3,0-0.4L20.8,18z"
								fill="none"
								id="Stroke-5"
								stroke={color}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							/>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default AccountIconBuilder;

