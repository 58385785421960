import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import ActiveFilter from '../filters/ActiveFilter';

import {
	updateFilter,
} from '~/actions/websiteIssuesComparison';

import {
	DEFAULT_FILTER,
	removeDefaultFilterValues,
} from '~/model/websiteIssuesComparison';

import {
	filterSelector,
} from '~/state/websiteIssuesComparison/selectors';



const messages = defineMessages({
	change: {
		id: 'ui.historicalIssues.overview.filter.label',
	},
	scope: {
		id: 'ui.issuesOverview.filter.segment.label',
	},
});



const ComparisonActiveFilterBar: React.FC = () => {
	const dispatch = useDispatch();
	const filter = useSelector(filterSelector);

	const handleRemoveFilter = React.useCallback(
		(field) => {
			dispatch(
				updateFilter(filter.merge({
					[field]: DEFAULT_FILTER.get(field),
				})),
			);
		},
		[
			dispatch,
			filter,
		],
	);

	const activeFilters = removeDefaultFilterValues(filter);

	return (
		<ActiveFilter
			activeFilters={activeFilters}
			clearLink={false}
			filter={filter}
			filterNames={{
				change: (
					<FormattedMessage {...messages.change} />
				),
				scope: (
					<FormattedMessage {...messages.scope} />
				),
			}}
			removeFilterCallback={handleRemoveFilter}
			segmentsManagement={false}
			supportsScrollToField={false}
		/>
	);
};



export default ComparisonActiveFilterBar;
