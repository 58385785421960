import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';
import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import SearchEngineActivityState, {
	SearchEngineActivityStateIconType,
	SearchEngineActivityStateStatus,
} from '~/components/patterns/statuses/SearchEngineActivityState';

import useLogFileAnalysisFeatureNudge from '~/hooks/useLogFileAnalysisFeatureNudge';
import useWebsiteId from '~/hooks/useWebsiteId';

import matchAndReturn from '~/utilities/matchAndReturn';

import {
	isArray,
	notEmpty,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	nudge: {
		id: 'ui.searchEngineActivityBadges.nudge',
	},
	seen: {
		id: 'ui.searchEngineActivityBadges.seen',
	},
	upsellAnnotation: {
		id: 'ui.searchEngineActivityBadges.upsellAnnotation',
	},
	visited: {
		id: 'ui.searchEngineActivityBadges.visited',
	},
});



export enum SearchEngineActivityBadgesWording {
	SeenBySearchEngine,
	VisitedBySearchEngine,
}



type Props = {
	isCompact?: boolean,
	searchEngineActivity: ReadonlyArray<{
		searchEngine: GraphQL.SearchEngineActivitySearchEngine,
		status: GraphQL.SearchEngineActivityAtMomentStatus,
	}> | null,
	showUpsellOrNudge?: boolean,
	showFeatureNotEnabled?: boolean,
	wording?: SearchEngineActivityBadgesWording,
};

const SearchEngineActivityBadges: React.FC<Props> = (props) => {
	const {
		isCompact,
		searchEngineActivity,
		showUpsellOrNudge = false,
		showFeatureNotEnabled = true,
		wording = SearchEngineActivityBadgesWording.VisitedBySearchEngine,
	} = props;

	const websiteId = useWebsiteId();
	const {
		isLogFileAnalysisEnabled,
		nudgeLogFileAnalysis,
		upsellLogFileAnalysis,
	} = useLogFileAnalysisFeatureNudge(websiteId);

	if (!isLogFileAnalysisEnabled || searchEngineActivity === null) {
		if (showUpsellOrNudge && upsellLogFileAnalysis) {
			return (
				<PremiumFeatureSituation
					extraAnnotation={(
						<FormattedMessage {...messages.upsellAnnotation} />
					)}
					featureName={GraphQL.AccountFeature.LogFileAnalysis}
					style={PremiumFeatureSituationStyle.Tooltip}
				>
					{({ premiumAnnotation }) => {
						if (premiumAnnotation !== null) {
							return React.cloneElement(
								premiumAnnotation,
								{},
								(
									<List
										inline={true}
										size={ListSize.Small}
									>
										<SearchEngineActivityState
											isCompact={true}
											key="google"
											label={null}
											searchEngineIconType={SearchEngineActivityStateIconType.Google}
											status={SearchEngineActivityStateStatus.NotOnPlan}
										/>
										<SearchEngineActivityState
											isCompact={true}
											key="bing"
											label={null}
											searchEngineIconType={SearchEngineActivityStateIconType.Bing}
											status={SearchEngineActivityStateStatus.NotOnPlan}
										/>
									</List>
								),
							);
						}

						return null;
					}}
				</PremiumFeatureSituation>
			);
		} else if (showUpsellOrNudge && nudgeLogFileAnalysis) {
			return (
				<Hint
					attachment={HintAttachment.Right}
					blurDelay={200}
					popup={(
						<Copy
							{...messages.nudge}
							values={{
								linkSettings: (chunks) => (
									<InternalLink
										routeName="website.detail.logFileAnalysis"
										routeParams={{ websiteId }}
										style={InternalLinkStyle.Decent}
									>
										{chunks}
									</InternalLink>
								),
							}}
						/>
					)}
					popupOffset={[0, 2]}
				>
					<List
						inline={true}
						size={ListSize.Small}
					>
						<SearchEngineActivityState
							isCompact={true}
							label={null}
							searchEngineIconType={SearchEngineActivityStateIconType.Google}
							status={SearchEngineActivityStateStatus.NotEnabled}
						/>
						<SearchEngineActivityState
							isCompact={true}
							label={null}
							searchEngineIconType={SearchEngineActivityStateIconType.Bing}
							status={SearchEngineActivityStateStatus.NotEnabled}
						/>
					</List>
				</Hint>
			);
		}

		return null;
	}

	const states = ['google', 'bing'].map((searchEngine) => {
		const status = (
			isArray(searchEngineActivity)
				? searchEngineActivity.find((activity) => activity.searchEngine === searchEngine)?.status
				: searchEngineActivity[searchEngine]
		) ?? null;

		if (status === null) {
			return null;
		}

		if (status === 'featureNotEnabled' && !showFeatureNotEnabled) {
			return null;
		}

		const icon = {
			bing: SearchEngineActivityStateIconType.Bing,
			google: SearchEngineActivityStateIconType.Google,
		}[searchEngine];

		const stateStatus = {
			visitedSince: SearchEngineActivityStateStatus.Visited,
			featureNotEnabled: SearchEngineActivityStateStatus.Unknown,
			notVisitedSince: SearchEngineActivityStateStatus.NotVisited,
		}[status];

		if (icon === undefined || status === undefined) {
			return null;
		}

		return (
			<SearchEngineActivityState
				isCompact={isCompact ?? status === 'featureNotEnabled'}
				key={searchEngine}
				label={matchAndReturn(wording, {
					[SearchEngineActivityBadgesWording.VisitedBySearchEngine]: (
						<FormattedMessage
							{...messages.visited}
							values={{
								searchEngine,
								status,
							}}
						/>
					),
					[SearchEngineActivityBadgesWording.SeenBySearchEngine]: (
						<FormattedMessage
							{...messages.seen}
							values={{
								searchEngine,
								status,
							}}
						/>
					),
				})}
				searchEngineIconType={icon}
				status={stateStatus}
			/>
		);
	}).filter(notEmpty);

	return (
		<List
			inline={true}
			size={ListSize.Small}
		>
			{states}
		</List>
	);
};



export default SearchEngineActivityBadges;
