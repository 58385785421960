import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ComparisonFilter from '~/components/logic/issuesOverview/ComparisonFilter';
import FlexibleBlocks from '~/components/patterns/structures/FlexibleBlocks';
import HeaderLayout from '~/components/logic/issuesOverview/components/HeaderLayout';
import HistoricalIssuesChart from '~/components/logic/charts/HistoricalIssuesChart';
import HistoricalWebsiteHealthChart from '~/components/logic/charts/HistoricalWebsiteHealthChart';
import LoadingMessage from '~/components/patterns/loaders/LoadingMessage';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenBodyLayout from '~/components/patterns/screens/parts/body/ScreenBodyLayout';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import ScreenOverlay from '~/components/patterns/screens/parts/ScreenOverlay';



const messages = defineMessages({
	loadingSubtitle: {
		id: 'ui.historicalIssues.loading.subtitle',
	},
	loadingTitle: {
		id: 'ui.historicalIssues.loading.title',
	},
});



type Props = {
	isLoading: boolean,
	innerPanel: any,
	issueCategoriesList: React.ReactNode,
};

const HistoricalDesktopLayout: React.FC<Props> = (props) => {
	const {
		isLoading,
		innerPanel,
		issueCategoriesList,
	} = props;

	let overlay: React.ReactNode = null;

	if (isLoading) {
		overlay = (
			<ScreenOverlay>
				<LoadingMessage
					subtitle={(
						<FormattedMessage {...messages.loadingSubtitle} />
					)}
					title={(
						<FormattedMessage {...messages.loadingTitle} />
					)}
				/>
			</ScreenOverlay>
		);
	}

	return (
		<ScreenLayout
			contentOverlay={overlay}
			header={(
				<HeaderLayout
					footer={(
						<ComparisonFilter
							inline={true}
						/>
					)}
				/>
			)}
		>
			{overlay ? null : (
				<ScreenBodyLayout
					contentOverlay={innerPanel ? innerPanel() : false}
					sidebar={issueCategoriesList}
				>
					<ScreenBody>
						<FlexibleBlocks
							blockMinWidth={500}
							contentMaxWidth={740}
						>
							<HistoricalWebsiteHealthChart />
							<HistoricalIssuesChart />
						</FlexibleBlocks>
					</ScreenBody>
				</ScreenBodyLayout>
			)}
		</ScreenLayout>
	);
};



export default HistoricalDesktopLayout;
