import React from 'react';
import {
	useSelector,
} from 'react-redux';

import DatatableContextProvider from '~/components/logic/DatatableContextProvider';
import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';
import PagesGraphs from '~/components/logic/pagesOverview/PagesGraphs';
import PagesHistoricalView from '~/components/atoms/dataTables/PagesHistoricalView';
import PagesOverview from '~/components/atoms/dataTables/PagesOverview';
import PagesScreenHeader, {
	PagesScreenHeaderType,
} from '~/components/atoms/pagesOverview/PagesScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';

import useEnabledWebsiteColumnsList from '~/hooks/useEnabledWebsiteColumnsList';
import useGetFilterType from '~/hooks/useGetFilterType';
import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	PAGES_SECTION_GRAPHS,
	PAGES_SECTION_TABLE,
} from '~/model/ui/pagesScreen';

import {
	isInHistoricalChangesModeSelector,
} from '~/state/historicalChanges/selectors';

import {
	filterSelector,
} from '~/state/ui/selectors';

import {
	pagesSectionSelector,
} from '~/state/ui/content/selectors';



const PagesOverviewPage: React.FC = () => {
	const websiteId = useWebsiteId();

	const activeFilter = useSelector(filterSelector);
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enabledWebsiteColumnsList = useEnabledWebsiteColumnsList();
	const getFilterType = useGetFilterType();
	const isInHistoricalChangesMode = useSelector(isInHistoricalChangesModeSelector);
	const pagesSection = useSelector(pagesSectionSelector);
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const viewportWidth = useViewportWidth();

	function renderContent() {
		if (segmentDefinitions.isLoaded === false) {
			return (
				<LoadingDots
					isStretched={true}
					size={LoadingDotsSize.Large}
				/>
			);
		} else if (isInHistoricalChangesMode) {
			return (
				<PagesHistoricalView />
			);
		} else if (pagesSection === PAGES_SECTION_TABLE) {
			return (
				<PagesOverview />
			);
		} else if (pagesSection === PAGES_SECTION_GRAPHS) {
			return (
				<PagesGraphs
					columns={enabledWebsiteColumnsList}
					customElementDefinitions={customElementDefinitions}
					getFilterType={getFilterType}
					segmentDefinitions={segmentDefinitions.listAll()}
					viewportWidth={viewportWidth}
				/>
			);
		}

		return null;
	}

	const headerType = isInHistoricalChangesMode
		? PagesScreenHeaderType.ChangeTracking
		: PagesScreenHeaderType.ContentOverview;

	return (
		<DatatableContextProvider>
			<ScreenLayout
				header={(
					<PagesScreenHeader
						activeFilter={activeFilter}
						pagesSection={pagesSection}
						type={headerType}
					/>
				)}
			>
				{renderContent()}
			</ScreenLayout>
		</DatatableContextProvider>
	);
};



export default PagesOverviewPage;
