/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteEnrichmentFieldMutationVariables = GraphQL.Exact<{
  id: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DeleteEnrichmentFieldMutation = { readonly __typename?: 'Mutation', readonly DeleteEnrichmentField: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly enrichmentFields: ReadonlyArray<{ readonly __typename?: 'EnrichmentField', readonly column: `ef_${string}`, readonly dataType: GraphQL.EnrichmentFieldDataType, readonly id: CK.ID, readonly label: string }> } | null } } };


export const DeleteEnrichmentFieldDocument = gql`
    mutation DeleteEnrichmentField($id: String!, $websiteId: WebsiteId!) {
  DeleteEnrichmentField(id: $id, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        id
        enrichmentFields {
          column
          dataType
          id
          label
        }
      }
    }
  }
}
    `;
export type DeleteEnrichmentFieldMutationFn = Apollo.MutationFunction<DeleteEnrichmentFieldMutation, DeleteEnrichmentFieldMutationVariables>;

/**
 * __useDeleteEnrichmentFieldMutation__
 *
 * To run a mutation, you first call `useDeleteEnrichmentFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnrichmentFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnrichmentFieldMutation, { data, loading, error }] = useDeleteEnrichmentFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDeleteEnrichmentFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEnrichmentFieldMutation, DeleteEnrichmentFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEnrichmentFieldMutation, DeleteEnrichmentFieldMutationVariables>(DeleteEnrichmentFieldDocument, options);
      }
export type DeleteEnrichmentFieldMutationHookResult = ReturnType<typeof useDeleteEnrichmentFieldMutation>;
export type DeleteEnrichmentFieldMutationResult = Apollo.MutationResult<DeleteEnrichmentFieldMutation>;
export type DeleteEnrichmentFieldMutationOptions = Apollo.BaseMutationOptions<DeleteEnrichmentFieldMutation, DeleteEnrichmentFieldMutationVariables>;