import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FormFieldsWrapper from '~/components/patterns/forms/wrappers/FormFieldsWrapper';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



const messages = defineMessages({
	editing: {
		id: 'ui.forms.editingMode',
	},
});

const EXCLUDE_ELEMENTS = '.editable-value__text-field, .select-field__virtual-select.select-field__virtual-select--open, .select-field__dropdown, .select-field__search-field';



type ContextType = {
	closeEditModeHandler: (isCalledBySubmit: boolean) => void,
	defaultFocusTarget: string | null,
	editMode: boolean,
	isEditable: boolean,
	openEditModeHandler: (focusTarget: string | null) => void,
};

export const EditableFormWrapperContext = React.createContext<ContextType | null>(null);



type Props = {
	children: RenderProp<{
		editMode: boolean,
	}>,
	exitEditModeDelay?: number,
	footer?: RenderProp<{
		editMode: boolean,
	}>,
	isEditable: boolean,
	isForAdmins?: boolean,
	isOpen?: boolean,
	onEnterEditModeCallback?: (editMode: boolean) => void,
	subtitle?: React.ReactNode,
	title: React.ReactNode,
};



const EditableFormWrapperBase: React.FC<Props> = (props) => {
	const {
		isEditable,
		children,
		exitEditModeDelay = null,
		footer = null,
		isForAdmins = false,
		isOpen = false,
		onEnterEditModeCallback = null,
		subtitle = null,
		title,
	} = props;

	const [editMode, setEditMode] = React.useState(isOpen);
	const [focusTarget, setFocusTarget] = React.useState<string | null>(null);

	const editableWrapperRef = React.useRef<HTMLDivElement>(null);

	const focusEditableWrapper = React.useCallback(
		() => {
			editableWrapperRef.current?.focus();
		},
		[
			editableWrapperRef,
		],
	);

	const handleAreaClick = React.useCallback(
		() => {
			setEditMode(true);

			focusEditableWrapper();

			if (onEnterEditModeCallback !== null) {
				onEnterEditModeCallback(true);
			}
		},
		[
			focusEditableWrapper,
			onEnterEditModeCallback,
			setEditMode,
		],
	);

	const handleExitEditMode = React.useCallback(
		(isCalledBySubmit: boolean) => {
			// we have to call it in async way
			setTimeout(() => {
				setEditMode(false);
				setFocusTarget(null);

				if (onEnterEditModeCallback !== null) {
					onEnterEditModeCallback(false);
				}
			}, (isCalledBySubmit && exitEditModeDelay !== null) ? exitEditModeDelay : 0); // delay is used only when calling onSubmit
		},
		[
			exitEditModeDelay,
			onEnterEditModeCallback,
			setEditMode,
			setFocusTarget,
		],
	);

	const handleKeyup = React.useCallback(
		(e) => {
			if (e.keyCode === 27 && editMode) {
				// prevent closing when you hit ESC key inside specific element
				if (e.target.closest(EXCLUDE_ELEMENTS)) {
					return false;
				}

				handleExitEditMode(false);
			}
		},
		[
			editMode,
			handleExitEditMode,
		],
	);

	React.useEffect(
		() => {
			document.addEventListener('keyup', handleKeyup);

			return () => {
				document.removeEventListener('keyup', handleKeyup);
			};
		},
		[
			handleKeyup,
		],
	);

	React.useEffect(
		() => {
			if (editMode && isEditable === false) {
				setEditMode(false);
			}
		},
		[
			editMode,
			isEditable,
			setEditMode,
		],
	);

	function renderFooter() {
		if (footer === null) {
			return null;
		}

		return renderProp(footer, {
			editMode,
		});
	}

	const newContext = React.useMemo(
		() => ({
			closeEditModeHandler: handleExitEditMode,
			defaultFocusTarget: focusTarget,
			editMode,
			isEditable,
			openEditModeHandler: (focusTarget: string) => {
				setEditMode(true);
				setFocusTarget(focusTarget);

				if (!focusTarget) {
					focusEditableWrapper();
				}

				if (onEnterEditModeCallback !== null) {
					onEnterEditModeCallback(true);
				}
			},
		}),
		[
			editMode,
			focusEditableWrapper,
			focusTarget,
			handleExitEditMode,
			isEditable,
			onEnterEditModeCallback,
		],
	);

	return (
		<FormFieldsWrapper
			editMode={editMode}
			footer={renderFooter()}
			isForAdmins={isForAdmins}
			onClickCallback={!editMode && isEditable ? handleAreaClick : undefined}
			ref={editableWrapperRef}
			subtitle={editMode ? (
				<FormattedMessage {...messages.editing} />
			) : subtitle}
			title={title}
		>
			<EditableFormWrapperContext.Provider value={newContext}>
				{renderProp(children, {
					editMode,
				})}
			</EditableFormWrapperContext.Provider>
		</FormFieldsWrapper>
	);
};



export default EditableFormWrapperBase;
