/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsiteIndexNowStatusMutationVariables = GraphQL.Exact<{
  isIndexNowEnabled: GraphQL.Scalars['Boolean']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteIndexNowStatusMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteIndexNowStatus: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isIndexNowEnabled: boolean } | null } } };


export const UpdateWebsiteIndexNowStatusDocument = gql`
    mutation UpdateWebsiteIndexNowStatus($isIndexNowEnabled: Boolean!, $websiteId: WebsiteId!) {
  UpdateWebsiteIndexNowStatus(
    isIndexNowEnabled: $isIndexNowEnabled
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        isIndexNowEnabled
      }
    }
  }
}
    `;
export type UpdateWebsiteIndexNowStatusMutationFn = Apollo.MutationFunction<UpdateWebsiteIndexNowStatusMutation, UpdateWebsiteIndexNowStatusMutationVariables>;

/**
 * __useUpdateWebsiteIndexNowStatusMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteIndexNowStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteIndexNowStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteIndexNowStatusMutation, { data, loading, error }] = useUpdateWebsiteIndexNowStatusMutation({
 *   variables: {
 *      isIndexNowEnabled: // value for 'isIndexNowEnabled'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteIndexNowStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteIndexNowStatusMutation, UpdateWebsiteIndexNowStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteIndexNowStatusMutation, UpdateWebsiteIndexNowStatusMutationVariables>(UpdateWebsiteIndexNowStatusDocument, options);
      }
export type UpdateWebsiteIndexNowStatusMutationHookResult = ReturnType<typeof useUpdateWebsiteIndexNowStatusMutation>;
export type UpdateWebsiteIndexNowStatusMutationResult = Apollo.MutationResult<UpdateWebsiteIndexNowStatusMutation>;
export type UpdateWebsiteIndexNowStatusMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteIndexNowStatusMutation, UpdateWebsiteIndexNowStatusMutationVariables>;