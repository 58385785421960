import classNames from 'classnames';
import React from 'react';

import AbstractBoxContent, {
	AbstractBoxContentScope,
} from '~/components/patterns/boxes/AbstractBoxContent.part';

import styles from './SimpleBox.module.scss';



export enum SimpleBoxBackground {
	White = 'white',
	Dark = 'dark',
}



type Props = {
	/** Predefined or completely custom background */
	background?: React.CSSProperties['backgroundColor'] | SimpleBoxBackground,
	borderColor?: React.CSSProperties['borderColor'],
	children?: React.ReactNode,
	/** Drop shadow around whole box */
	dropShadow?: boolean,
	hasBorders?: boolean,
	/** Maximum height of content part. When content will be bigger it will became scrollable. */
	maxHeight?: React.CSSProperties['maxHeight'],
	/** Maximum limitation for box width */
	maxWidth?: React.CSSProperties['maxWidth'],
	/** Customizable size of padding */
	paddingSize?: 0 | 1 | 2 | 3,
};

const SimpleBox: React.FC<Props> = (props) => {
	const {
		background = SimpleBoxBackground.White,
		borderColor,
		children,
		dropShadow = false,
		hasBorders = true,
		maxHeight,
		maxWidth,
		paddingSize = 3,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.dropShadow]: dropShadow,
		[styles.backgroundDark]: background === SimpleBoxBackground.Dark,
		[styles.backgroundWhite]: background === SimpleBoxBackground.White,
		[styles.hasBorders]: hasBorders,
	});

	const style: React.CSSProperties = {};

	if (borderColor) {
		style.borderColor = borderColor;
	}

	if (maxWidth) {
		style.maxWidth = maxWidth;
	}

	// possibility to set completely custom background
	if (background !== SimpleBoxBackground.Dark && background !== SimpleBoxBackground.White) {
		style.background = background;
	}

	return (
		<div
			className={componentClasses}
			style={style}
		>
			<div
				className={styles.content}
				style={{
					maxHeight,
				}}
			>
				<AbstractBoxContent
					paddingSize={paddingSize}
					scope={AbstractBoxContentScope.Content}
				>
					{children}
				</AbstractBoxContent>
			</div>
		</div>
	);
};



export default SimpleBox;
