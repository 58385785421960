/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResumeSubscriptionRenewalMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type ResumeSubscriptionRenewalMutation = { readonly __typename?: 'Mutation', readonly ResumeSubscriptionRenewal: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isSubscriptionRenewalEnabled: boolean } | null } } };


export const ResumeSubscriptionRenewalDocument = gql`
    mutation ResumeSubscriptionRenewal($accountId: AccountId!) {
  ResumeSubscriptionRenewal(accountId: $accountId) {
    query {
      account(id: $accountId) {
        id
        isSubscriptionRenewalEnabled
      }
    }
  }
}
    `;
export type ResumeSubscriptionRenewalMutationFn = Apollo.MutationFunction<ResumeSubscriptionRenewalMutation, ResumeSubscriptionRenewalMutationVariables>;

/**
 * __useResumeSubscriptionRenewalMutation__
 *
 * To run a mutation, you first call `useResumeSubscriptionRenewalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeSubscriptionRenewalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeSubscriptionRenewalMutation, { data, loading, error }] = useResumeSubscriptionRenewalMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useResumeSubscriptionRenewalMutation(baseOptions?: Apollo.MutationHookOptions<ResumeSubscriptionRenewalMutation, ResumeSubscriptionRenewalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeSubscriptionRenewalMutation, ResumeSubscriptionRenewalMutationVariables>(ResumeSubscriptionRenewalDocument, options);
      }
export type ResumeSubscriptionRenewalMutationHookResult = ReturnType<typeof useResumeSubscriptionRenewalMutation>;
export type ResumeSubscriptionRenewalMutationResult = Apollo.MutationResult<ResumeSubscriptionRenewalMutation>;
export type ResumeSubscriptionRenewalMutationOptions = Apollo.BaseMutationOptions<ResumeSubscriptionRenewalMutation, ResumeSubscriptionRenewalMutationVariables>;