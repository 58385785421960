import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableOverlay, {
	DatatableOverlayStyle,
} from '~/components/patterns/tables/datatables/DatatableOverlay';
import DropdownIcon from '~/components/patterns/icons/DropdownIcon';
import ExportHeaderButton from '~/components/app/ExportHeaderButton';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderDropdownLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderDropdownLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderTitle, {
	SIZE_SMALL as HEADER_TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleNumericValue from '~/components/atoms/screenLayouts/components/header/headerTitle/builders/HeaderTitleNumericValue';
import HeaderTitleWithNumericValue, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import IncomingCanonicalLinksTable from '~/components/app/IncomingCanonicalLinksTable';
import IncomingInternalLinksTable from '~/components/app/IncomingInternalLinksTable';
import IncomingRedirectsTable from '~/components/app/IncomingRedirectsTable';
import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';
import OutgoingExternalLinksTable from '~/components/app/OutgoingExternalLinksTable';
import OutgoingInternalLinksTable from '~/components/app/OutgoingInternalLinksTable';
import ScreenHeader, {
	ScreenHeaderScope,
} from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import SimpleNavigation from '~/components/patterns/navigations/simpleNavigation/SimpleNavigation';
import SimpleNavigationItem from '~/components/patterns/navigations/simpleNavigation/SimpleNavigationItem';
import StickToScreenBottom, {
	StickToScreenBottomPreset,
} from '~/components/patterns/utils/StickToScreenBottom';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';

import {
	useContentRelationsPanelQuery,
} from './ContentRelationsPanel.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import useUrlState from '~/hooks/useUrlState';
import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ExportType,
} from '~/model/exports';

import {
	LinkType,
} from '~/model/pagesNew';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	exportButton: {
		id: 'ui.exports.type.general.button.openDialog.label',
	},
});

const linkMessages = defineMessages({
	[LinkType.IncomingInternalLinks]: {
		id: 'ui.pageDetail.linkData.incoming.linkLabel',
	},
	[LinkType.IncomingCanonicals]: {
		id: 'ui.pageDetail.linkData.incomingCanonical.linkLabel',
	},
	[LinkType.IncomingRedirects]: {
		id: 'ui.pageDetail.linkData.incomingRedirects.linkLabel',
	},
	[LinkType.OutgoingExternalLinks]: {
		id: 'ui.pageDetail.linkData.outbound.linkLabel',
	},
	[LinkType.OutgoingInternalLinks]: {
		id: 'ui.pageDetail.linkData.outgoingInternal.linkLabel',
	},
});

const titleMessages = defineMessages({
	[LinkType.IncomingInternalLinks]: {
		id: 'ui.pageDetail.linkData.incoming.panelTitle',
	},
	[LinkType.IncomingCanonicals]: {
		id: 'ui.pageDetail.linkData.incomingCanonical.panelTitle',
	},
	[LinkType.IncomingRedirects]: {
		id: 'ui.pageDetail.linkData.incomingRedirects.panelTitle',
	},
	[LinkType.OutgoingExternalLinks]: {
		id: 'ui.pageDetail.linkData.outbound.panelTitle',
	},
	[LinkType.OutgoingInternalLinks]: {
		id: 'ui.pageDetail.linkData.outgoingInternal.panelTitle',
	},
});



const TABS_BREAKPOINT = 1790;
const HEADER_HEIGHT = 55;

const linkTypes = [
	LinkType.IncomingInternalLinks,
	LinkType.OutgoingInternalLinks,
	LinkType.OutgoingExternalLinks,
	LinkType.IncomingCanonicals,
	LinkType.IncomingRedirects,
];



const ContentRelationsPanel = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const urlState = useUrlState();
	const viewportWidth = useViewportWidth();

	const linksType = urlState.params.pageDetailLinksType;

	const [dataLoading] = React.useState(false);
	const [hiddenDropdown, setHiddenDropdown] = React.useState(true);

	const { data } = usePageDetailPropertiesQuery(
		useContentRelationsPanelQuery,
		legacyUrlId,
		websiteId,
	);

	const getLinksCount = (linksType: LinkType) => matchAndReturn(linksType, {
		[LinkType.IncomingInternalLinks]: data?.lookupPageByLegacyId?.numberOfIncomingInternalLinks ?? 0,
		[LinkType.OutgoingInternalLinks]: data?.lookupPageByLegacyId?.pageTypeData?.numberOfOutgoingInternalLinks ?? 0,
		[LinkType.OutgoingExternalLinks]: data?.lookupPageByLegacyId?.pageTypeData?.numberOfOutgoingExternalLinks ?? 0,
		[LinkType.IncomingCanonicals]: data?.lookupPageByLegacyId?.numberOfIncomingCanonicalLinks ?? 0,
		[LinkType.IncomingRedirects]: data?.lookupPageByLegacyId?.numberOfIncomingRedirects ?? 0,
	});

	React.useEffect(
		() => {
			setHiddenDropdown(true);
		},
		[
			linksType,
		],
	);

	const toggleDropdown = React.useCallback(
		() => {
			setHiddenDropdown(
				(hiddenDropdown) => hiddenDropdown === false,
			);
		},
		[],
	);

	if (!linksType) {
		return null;
	}

	const count = getLinksCount(linksType);

	const dataTable = matchAndReturn(linksType, {
		[LinkType.IncomingInternalLinks]: () => (
			<IncomingInternalLinksTable
				fallbackLinksCount={count}
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>
		),
		[LinkType.OutgoingInternalLinks]: () => (
			<OutgoingInternalLinksTable
				fallbackLinksCount={count}
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>
		),
		[LinkType.OutgoingExternalLinks]: () => (
			<OutgoingExternalLinksTable
				fallbackLinksCount={count}
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>
		),
		[LinkType.IncomingCanonicals]: () => (
			<IncomingCanonicalLinksTable
				fallbackLinksCount={count}
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>
		),
		[LinkType.IncomingRedirects]: () => (
			<IncomingRedirectsTable
				fallbackLinksCount={count}
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>
		),
	});

	let footer: React.ReactNode;
	let navigation: React.ReactNode;
	let title: React.ReactNode;

	if (viewportWidth < TABS_BREAKPOINT) {
		footer = (
			<HeaderDropdownLayout isOpen={!hiddenDropdown}>
				<SimpleNavigation>
					{linkTypes.filter((linkType) => linksType !== linkType).map((linkType) => (
						<SimpleNavigationItem
							key={linkType}
							linkRouteName="website.pages.detail.links"
							linkRouteParams={{
								id: legacyUrlId,
								pageDetailLinksType: linkType,
								websiteId,
							}}
						>
							<FormattedMessage {...linkMessages[linkType]} />
						</SimpleNavigationItem>
					))}
				</SimpleNavigation>
			</HeaderDropdownLayout>
		);

		title = (
			<HeaderTitle
				icon={(
					<DropdownIcon isActive={!hiddenDropdown} />
				)}
				onClickCallback={toggleDropdown}
				size={HEADER_TITLE_SIZE_SMALL}
				value={(
					<HeaderTitleNumericValue value={count} />
				)}
			>
				<FormattedMessage {...titleMessages[linksType]} />
			</HeaderTitle>
		);
	} else {
		navigation = (
			<TabNavigation stretchItems={false}>
				{linkTypes.map((linkType) => (
					<TabNavigationItem
						isActive={linksType === linkType}
						isNotReady={getLinksCount(linkType) === 0}
						key={linkType}
						linkRouteName="website.pages.detail.links"
						linkRouteParams={{
							id: legacyUrlId,
							pageDetailLinksType: linkType,
							websiteId,
						}}
						uppercase={true}
					>
						<FormattedMessage {...linkMessages[linkType]} />
					</TabNavigationItem>
				))}
			</TabNavigation>
		);

		title = (
			<HeaderTitleWithNumericValue
				size={TITLE_SIZE_SMALL}
				title={(
					<FormattedMessage {...titleMessages[linksType]} />
				)}
				value={count}
			/>
		);
	}

	let overlay;

	if (dataLoading) {
		overlay = (
			<DatatableOverlay
				datatableHeaderHeight={HEADER_HEIGHT}
				style={DatatableOverlayStyle.Disabled}
			>
				<LoadingDots size={LoadingDotsSize.Large} />
			</DatatableOverlay>
		);
	}

	return (
		<>
			<ScreenHeader
				footer={footer}
				scope={ScreenHeaderScope.Panel}
			>
				<HeaderTitleLayout>
					{title}
				</HeaderTitleLayout>

				<HeaderOptionsLayout>
					{navigation}

					<HeaderButtonsLayout>
						<ExportHeaderButton
							exportType={ExportType.PageRelations}
							linkLabel={(
								<FormattedMessage {...messages.exportButton} />
							)}
						/>
					</HeaderButtonsLayout>
				</HeaderOptionsLayout>
			</ScreenHeader>
			<DatatableContainer overlay={overlay}>
				<StickToScreenBottom
					preset={StickToScreenBottomPreset.FullscreenDetail}
				>
					{dataTable}
				</StickToScreenBottom>
			</DatatableContainer>
		</>
	);
};



export default ContentRelationsPanel;
