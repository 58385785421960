import GraphQL from '~/types/graphql';

import {
	DETAIL_END_USER_PLAN,
	DETAIL_END_USER_PLAN_WEBSITES,
	DETAIL_END_USER_PLAN_WEBSITES_PAGES,
} from './endUser';

import {
	createTariff,
} from './tariff';



const END_USER_PAGES_POINTS = [
	1_000,
	2_000,
	3_000,
	4_000,
	5_000,
	10_000,
	25_000,
	50_000,
	100_000,
	200_000,
	300_000,
	400_000,
	500_000,
	600_000,
	700_000,
	800_000,
	900_000,
	1_000_000,
	1_250_000,
	1_500_000,
	1_750_000,
	2_000_000,
	2_500_000,
	3_000_000,
	3_500_000,
	4_000_000,
	4_500_000,
	5_000_000,
	6_000_000,
	7_000_000,
	8_000_000,
	9_000_000,
	10_000_000,
];



function chooseEndUserPlan({ billingCycle, details }) {
	let endUserPlan = details.get(DETAIL_END_USER_PLAN);

	if (endUserPlan === 'end_user_essentials') {
		endUserPlan = 'end_user';
	}

	return endUserPlan + '.' + billingCycle;
}



function convertEndUserDetailsToAddons(details) {
	const websites = details.get(DETAIL_END_USER_PLAN_WEBSITES);

	const result = {};

	websites.forEach((website) => {
		const pageCapacity = website.get(DETAIL_END_USER_PLAN_WEBSITES_PAGES);

		for (const pagesPoint of END_USER_PAGES_POINTS) {
			if (pageCapacity <= pagesPoint) {
				if (result['pages.' + pagesPoint] === undefined) {
					result['pages.' + pagesPoint] = 0;
				}

				result['pages.' + pagesPoint]++;

				return;
			}
		}
	});

	return result;
}



function normalizeEndUserPageCapacity(numberOfPages) {
	for (const pagesPoint of END_USER_PAGES_POINTS) {
		if (numberOfPages <= pagesPoint) {
			return pagesPoint;
		}
	}

	return;
}



export default createTariff({
	accountTypes: {
		[GraphQL.AccountType.EndUser]: {
			choosePlan: chooseEndUserPlan,
			convertDetailsToAddons: convertEndUserDetailsToAddons,
			convertPagesToAddons: () => {
				return {};
			},
			normalizePageCapacity: normalizeEndUserPageCapacity,
			normalizePagesAmount: normalizeEndUserPageCapacity,
		},
	},
});
