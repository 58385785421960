import type GraphQL from '~/types/graphql';

import {
	useOnPageRequestBlockingQuery,
} from './useOnPageRequestBlocking.gql';



type OnPageRequestBlocking = {
	mode: GraphQL.OnPageRequestsBlockingMode,
	rules: GraphQL.Website['onPageRequestsBlockingRules'],
};



function useOnPageRequestBlocking(websiteId: string): OnPageRequestBlocking | null {
	const { data = null } = useOnPageRequestBlockingQuery({
		skip: !websiteId,
		variables: {
			websiteId,
		},
	});

	if (!data?.website) {
		return null;
	}

	return {
		mode: data.website.onPageRequestsBlockingMode,
		rules: data.website.onPageRequestsBlockingRules,
	};
}



export default useOnPageRequestBlocking;
