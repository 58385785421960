import React from 'react';

import BooleanFormatter from '~/components/app/BooleanFormatter';
import DateFormatter from '~/components/app/DateFormatter';
import EmptyValue from '~/components/app/EmptyValue';
import EnrichmentFieldNoValue from '~/components/app/EnrichmentFieldNoValue';
import EnrichmentFieldNotProvidedValue from '~/components/app/EnrichmentFieldNotProvidedValue';
import NumberFormatter from '~/components/app/NumberFormatter';
import StringFormatter from '~/components/app/StringFormatter';
import TextInspector from '~/components/patterns/typography/TextInspector';

import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	DATA_TYPE_BOOLEAN,
	DATA_TYPE_DATE,
	DATA_TYPE_NUMBER,
	DATA_TYPE_STRING,
} from '~/model/customElements';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	column: string,
	useTextInspector: boolean,
	value: any,
};

const EnrichmentFieldFormatter: React.FC<Props> = (props) => {
	const {
		column,
		useTextInspector,
		value,
	} = props;

	const websiteId = useWebsiteId();

	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	const enrichmentFieldDefinition = enrichmentFieldDefinitions.getByColumn(column);

	if (enrichmentFieldDefinition === null || !value) {
		return null;
	}

	const actualValue = value.get ? value.get('value') : value.value;
	const isProvided = value.get ? value.get('isProvided') : value.isProvided;

	if (isProvided === false) {
		return (
			<EnrichmentFieldNotProvidedValue />
		);
	}

	if (actualValue === null) {
		return (
			<EnrichmentFieldNoValue />
		);
	}

	const dataType = enrichmentFieldDefinition.dataType;

	if (dataType === DATA_TYPE_STRING) {
		if (useTextInspector) {
			if (actualValue === '') {
				return (
					<EmptyValue />
				);
			}

			return (
				<TextInspector text={actualValue} />
			);
		}

		return (
			<StringFormatter value={actualValue} />
		);
	}

	return matchAndReturn(dataType, {
		[DATA_TYPE_BOOLEAN]: (
			<BooleanFormatter
				value={actualValue}
			/>
		),
		[DATA_TYPE_DATE]: (
			<DateFormatter
				timeZone="Europe/Amsterdam"
				value={actualValue * 1000}
			/>
		),
		[DATA_TYPE_NUMBER]: (
			<NumberFormatter value={actualValue} />
		),
	});
};



export default EnrichmentFieldFormatter;
