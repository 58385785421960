import Immutable, {
	Map,
} from 'immutable';

import {
	LOGOUT_SUCCESSFUL,
} from '~/actions';

import {
	STORE_ROBOTS_RULES,
} from '~/actions/websites';



function createDefaultState() {
	return Map();
}



export function robotsRules(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case STORE_ROBOTS_RULES: {
			const {
				accountId,
				websiteId,
				rules,
			} = action;

			state = state.setIn(
				[
					accountId.toString(),
					websiteId,
				],
				Immutable.fromJS(rules.sort()),
			);

			break;
		}

	}

	return state;
}
