import {
	WIP,
} from '~/localization/languages/constants';



export default <const>{
	'ui.3dsecure.button': `Verify payment`,
	'ui.3dsecure.failure': `Unfortunately, the payment verification failed. Please try again. If the problem persists, try a different Credit Card.`,
	'ui.3dsecure.prompt': `Please verify the payment.`,
	'ui.3dsecure.title': `Payment verification`,
	'ui.accountAccess.accessRights.makeChanges': `Make changes`,
	'ui.accountAccess.accessRights.readOnly': `Read-only`,
	'ui.accountAccess.agencyAccessOverview.actions.revokeAccess': `Revoke access`,
	'ui.accountAccess.agencyAccessOverview.actions.viewRequest': `View request`,
	'ui.accountAccess.agencyAccessOverview.title': `Connected account`,
	'ui.accountAccess.buttons.connectAgencyAccount': `Grant account access`,
	'ui.accountAccess.connectToAgency.description': `
		<p>Enter the email address of the contact person of the account you wish to grant access to.</p>
		<p>You can revoke this access at any time.</p>
	`,
	'ui.accountAccess.connectToAgency.emailLabel': `Email address`,
	'ui.accountAccess.connectToAgency.errors.alreadyConnected': `Request or established connection to provided account already exists.`,
	'ui.accountAccess.connectToAgency.errors.ambiguousTarget': `That user is a team member in multiple Conductor Website Monitoring accounts. Please enter the email address of a user who is a team member only in the account you want to connect to.`,
	'ui.accountAccess.connectToAgency.errors.insufficientPrivileges': `That user does not have sufficient access rights to accept this request.`,
	'ui.accountAccess.connectToAgency.errors.maximumAllowedTrialConnectionsLimitReached': `The account that this email address belongs to is already connected to the maximum number of trial accounts. Learn more in <link_support>our support article</link_support> or <showIntercom>contact us</showIntercom>.`,
	'ui.accountAccess.connectToAgency.errors.notAgency': `The email address you specified cannot be connected to.`,
	'ui.accountAccess.connectToAgency.errors.unknownUser': `We don't know any user with that email address.`,
	'ui.accountAccess.connectToAgency.submitButton': `Grant access`,
	'ui.accountAccess.connectToAgency.title': `Grant access`,
	'ui.accountAccess.connectToClient.errors.connectionAlreadyExists': `Your account is already connected to this account.`,
	'ui.accountAccess.connectToClient.errors.connectionUnsupportedByClient': `This email address belongs to a user in an account that cannot provide access to other accounts. To do that, they first need to change their connection direction. Learn more in <link>our support article</link>.`,
	'ui.accountAccess.connectToClient.errors.insufficientPrivileges': `That user does not have sufficient access rights to accept this request.`,
	'ui.accountAccess.connectToClient.errors.maximumAllowedTrialConnectionsLimitReached.description': `Your account is already connected to the maximum number of trial accounts. Learn more in <link_support>our support article</link_support> or <show_intercom>contact us</show_intercom>.`,
	'ui.accountAccess.connectToClient.errors.maximumAllowedTrialConnectionsLimitReached.title': `Maximum allowed trial connections limit reached`,
	'ui.accountAccess.connectToClient.errors.unknownEmailAddress': `We don't know any user with that email address. {canCreateNewAccount, select,
		yes {Do you want to <link_step>create a new account for them</link_step> instead?}
		other {}
	}`,
	'ui.accountAccess.connectToClient.errors.unknownUser': `We don't know any user with that email address.`,
	'ui.accountAccess.contextSelect.clientAccountsLabel': `Connected accounts`,
	'ui.accountAccess.contextSelect.yourAccountLabel': `Your {numberOfAccounts, plural,
		=0 {accounts}
		=1 {account}
		other {accounts}
	}`,
	'ui.accountAccess.createClient.formErrors.emailBelongsToExistingUser': `This email address has an existing Conductor Website Monitoring account. Perhaps you want to {text__link} instead?`,
	'ui.accountAccess.createClient.formErrors.emailBelongsToExistingUser.link': `connect with that account`,
	'ui.accountAccess.createClient.formErrors.emailBelongsToYou': `This is the email address you're currently logged in with. Enter the email address of your client instead.`,
	'ui.accountAccess.createClient.formErrors.emailBelongsToYourClient': `This email address belongs to an account you’re already connected to. Perhaps you want to {text__link}?`,
	'ui.accountAccess.createClient.formErrors.emailBelongsToYourClient.link': `manage that account`,
	'ui.accountAccess.createClient.formErrors.emailBelongsToYourTeamMember': `This email address belongs to one of your existing team members. Remove them from your team before you create a separate account for them.`,
	'ui.accountAccess.grantAccessToAgency.caution.description': `Only grant access if you know the account requesting access. Not sure what to do? <showIntercom>Contact us</showIntercom>.`,
	'ui.accountAccess.grantAccessToAgency.denyButton': `Deny access`,
	'ui.accountAccess.grantAccessToAgency.description': `<p>Granting access to the account requesting it will allow them to access your account. You can revoke this access at any time.</p>`,
	'ui.accountAccess.grantAccessToAgency.identification.agency': `Account`,
	'ui.accountAccess.grantAccessToAgency.submitButton': `Grant access`,
	'ui.accountAccess.grantAccessToAgency.title': `Grant access`,
	'ui.accountAccess.overview.actions.cancelRequest': `Cancel request`,
	'ui.accountAccess.overview.heading.accessRights': `Access Rights`,
	'ui.accountAccess.overview.heading.account': `Account`,
	'ui.accountAccess.overview.heading.action': `Action`,
	'ui.accountAccess.overview.isPending': `(pending)`,
	'ui.acl.assignmentsTable.heading.contributor': `Team member`,
	'ui.acl.assignmentsTable.title.contributor': `Assigned team members`,
	'ui.admin.onPageRequestsWhitelistRulesDisplay': `{countRules, plural,
		=0 {no rules}
		=1 {# rule}
		other {# rules}
	}`,
	'ui.admin.onPageRequestsWhitelistRulesEdit': `{countRules, plural,
		=0 {no rules}
		=1 {# rule}
		other {# rules}
	} (<linkModal>configure</linkModal>)`,
	'ui.adobeAnalytics.connectionModal.connectButton': `Connect`,
	'ui.adobeAnalytics.connectionModal.description': `
	<p>To connect your Adobe Analytics, you need to create a dedicated project in your Adobe Developer Console and set up a Service Account (JWT) integration. Learn how in <linkArticle>our support article</linkArticle>.</p>
	<p>Once done, please enter these credentials from your Adobe Analytics to authenticate:</p>
	`,
	'ui.adobeAnalytics.connectionModal.errors.expiredCredentials': `The provided credentials are expired. Please generate a new public/private keypair. <showIntercom>Contact us</showIntercom> if you need help.`,
	'ui.adobeAnalytics.connectionModal.errors.invalidCredentials': `We are not able to connect to your Adobe Analytics. Make sure that the credentials you entered are correct and please <showIntercom>contact us</showIntercom> if you need help.`,
	'ui.adobeAnalytics.connectionModal.fields.clientId': `Client ID`,
	'ui.adobeAnalytics.connectionModal.fields.clientSecret': `Client Secret`,
	'ui.adobeAnalytics.connectionModal.fields.organizationId': `Organization Id`,
	'ui.adobeAnalytics.connectionModal.fields.privateKey': `Private Key`,
	'ui.adobeAnalytics.connectionModal.fields.technicalAccountId': `Technical{br}Account ID`,
	'ui.adobeAnalytics.connectionModal.sidebar': `
		<p>With the Adobe Analytics integration you can pull in your website's data from Adobe Analytics and work with it directly in Conductor Website Monitoring.</p>
		<p>Follow <linkArticle>this step-by-step guide</linkArticle> to learn how to set up the integration and retrieve the necessary credentials for authentication.</p>
	`,
	'ui.adobeAnalytics.connectionModal.title': `Connect Adobe Analytics`,
	'ui.affectedPages.general.titles.mobilePagesWithIssues': `{pagesCount, plural,
		=1 {Page}
		other {Pages}
	} with issues`,
	'ui.affectedPages.specific.canonicalLink.headings.element': `Canonical Link`,
	'ui.affectedPages.specific.canonicalLink.headings.url': `Canonical URL Target`,
	'ui.affectedPages.specific.canonicalLink.present': `canonical link present`,
	'ui.affectedPages.specific.images.headings.alt': `Images without alt-attribute`,
	'ui.affectedPages.specific.images.headings.mixedTransport': `Images served over mixed transport`,
	'ui.affectedPages.specific.images.headings.title': `Images without title-attribute`,
	'ui.affectedPages.specific.links.headings.broken': `Broken links`,
	'ui.affectedPages.specific.links.headings.redirecting': `Links to redirect`,
	'ui.affectedPages.specific.links.headings.toCanonicalized': `Links to canonicalized pages`,
	'ui.affectedPages.specific.xmlSitemap.status': `Status`,
	'ui.affectedPages.specific.xmlSitemap.status.incorrectlyMissing': `Incorrectly Missing`,
	'ui.affectedPages.specific.xmlSitemap.status.incorrectlyPresent': `Incorrectly Present`,
	'ui.affectedPagesComparison.filter.issueChange.brokenEntered': `Affected pages entered the segment`,
	'ui.affectedPagesComparison.filter.issueChange.brokenLeft': `Affected pages left the segment`,
	'ui.affectedPagesComparison.filter.issueChange.closed': `Issue resolved`,
	'ui.affectedPagesComparison.filter.issueChange.ignored': `Issue ignored`,
	'ui.affectedPagesComparison.filter.issueChange.notApplicablePoints': `Issue became applicable`,
	'ui.affectedPagesComparison.filter.issueChange.notInSegmentPoints': `Unaffected pages entered the segment`,
	'ui.affectedPagesComparison.filter.issueChange.notPagePoints': `Added pages`,
	'ui.affectedPagesComparison.filter.issueChange.notRequiredPoints': `Issue became required`,
	'ui.affectedPagesComparison.filter.issueChange.opened': `Existing pages`,
	'ui.affectedPagesComparison.filter.issueChange.openedNewPage': `Added pages`,
	'ui.affectedPagesComparison.filter.issueChange.openLessImportant': `Affected pages became less important`,
	'ui.affectedPagesComparison.filter.issueChange.openMoreImportant': `Affected pages became more important`,
	'ui.affectedPagesComparison.filter.issueChange.openNotApplicable': `Issue became not applicable`,
	'ui.affectedPagesComparison.filter.issueChange.openNotPage': `Pages became non-pages`,
	'ui.affectedPagesComparison.filter.issueChange.pointsLessImportant': `Unaffected pages became less important`,
	'ui.affectedPagesComparison.filter.issueChange.pointsMoreImportant': `Unaffected page became more important`,
	'ui.affectedPagesComparison.filter.issueChange.pointsNotApplicable': `Issue became not applicable`,
	'ui.affectedPagesComparison.filter.issueChange.pointsNotInSegment': `Unaffected pages left the segment`,
	'ui.affectedPagesComparison.filter.issueChange.pointsNotPage': `Pages became non-pages`,
	'ui.affectedPagesComparison.filter.issueChange.pointsNotRequired': `Issue became not required`,
	'ui.affectedPagesComparison.filter.issueChange.unignored': `Issue unignored`,
	'ui.affectedPagesComparison.filter.issueChangesGroup.ignoring': `Ignoring changed`,
	'ui.affectedPagesComparison.filter.issueChangesGroup.noLongerApplicable': `No longer applicable`,
	'ui.affectedPagesComparison.filter.issueChangesGroup.onlyImportanceChanges': `Importance changed`,
	'ui.affectedPagesComparison.filter.issueChangesGroup.opened': `Issue opened`,
	'ui.affectedPagesComparison.filter.issueChangesGroup.resolved': `Issue resolved`,
	'ui.affectedPagesComparison.filter.issueChangesGroup.segmentChanged': `Segment changed`,
	'ui.affectedPagesComparison.filter.issueChangesGroup.unaffected': `Unaffected pages`,
	'ui.affectedPagesComparison.value.issueChange.brokenEntered': `Affected page entered the segment`,
	'ui.affectedPagesComparison.value.issueChange.brokenLeft': `Affected page left the segment`,
	'ui.affectedPagesComparison.value.issueChange.closed': `Issue resolved`,
	'ui.affectedPagesComparison.value.issueChange.ignored': `Issue ignored`,
	'ui.affectedPagesComparison.value.issueChange.notApplicablePoints': `Issue became applicable`,
	'ui.affectedPagesComparison.value.issueChange.notInSegmentPoints': `Unaffected page entered the segment`,
	'ui.affectedPagesComparison.value.issueChange.notPagePoints': `Added page`,
	'ui.affectedPagesComparison.value.issueChange.notRequiredPoints': `Issue became required`,
	'ui.affectedPagesComparison.value.issueChange.opened': `on existing page`,
	'ui.affectedPagesComparison.value.issueChange.openedNewPage': `on added page`,
	'ui.affectedPagesComparison.value.issueChange.openLessImportant': `Affected page became less important`,
	'ui.affectedPagesComparison.value.issueChange.openMoreImportant': `Affected page became more important`,
	'ui.affectedPagesComparison.value.issueChange.openNotApplicable': `Issue became not applicable`,
	'ui.affectedPagesComparison.value.issueChange.openNotPage': `Page became non-page`,
	'ui.affectedPagesComparison.value.issueChange.pointsLessImportant': `Unaffected page became less important`,
	'ui.affectedPagesComparison.value.issueChange.pointsMoreImportant': `Unaffected page became more important`,
	'ui.affectedPagesComparison.value.issueChange.pointsNotApplicable': `Issue became not applicable`,
	'ui.affectedPagesComparison.value.issueChange.pointsNotInSegment': `Unaffected page left the segment`,
	'ui.affectedPagesComparison.value.issueChange.pointsNotPage': `Page became a non-page`,
	'ui.affectedPagesComparison.value.issueChange.pointsNotRequired': `Issue became not required`,
	'ui.affectedPagesComparison.value.issueChange.unignored': `Issue unignored`,
	'ui.affectedPagesComparison.value.issueChangesGroup.ignoring': `Ignoring changed`,
	'ui.affectedPagesComparison.value.issueChangesGroup.noLongerApplicable': `No longer applicable`,
	'ui.affectedPagesComparison.value.issueChangesGroup.onlyImportanceChanges': `Importance changed`,
	'ui.affectedPagesComparison.value.issueChangesGroup.opened': `Issue opened`,
	'ui.affectedPagesComparison.value.issueChangesGroup.resolved': `Issue resolved`,
	'ui.affectedPagesComparison.value.issueChangesGroup.segmentChanged': `Segment changed`,
	'ui.affectedPagesComparison.value.issueChangesGroup.unaffected': `Unaffected page`,
	'ui.alerts.feedback.questionLabel': `Was this alert useful?`,
	'ui.alerts.feedback.questionNegative': `Sorry to hear that. What can we do to make it more useful for you?`,
	'ui.alerts.feedback.submit': `Submit`,
	'ui.alerts.feedback.thanksGeneric': `Thanks for the input! We'll use it to further improve our alerts.`,
	'ui.alerts.feedback.thanksOnAnswer': `Great, thanks for the feedback!`,
	'ui.alerts.overview.activeIncidentsTitle': `Active incidents`,
	'ui.alerts.overview.blankSlate': `
		<p>We have not had any alerts for this website yet. The moment we catch something serious, we'll let you know.</p>
		<p>Watch this short video to learn how it works!</p>
	`,
	'ui.alerts.overview.configureAlertsButton': `Alert Settings`,
	'ui.alerts.overview.incident.details.affected': `affected`,
	'ui.alerts.overview.incident.details.allChangesReverted': `All changes reverted`,
	'ui.alerts.overview.incident.details.entered': `entered`,
	'ui.alerts.overview.incident.details.issueHasBeenResolved': `The underlying issue has been resolved.`,
	'ui.alerts.overview.incident.details.left': `left`,
	'ui.alerts.overview.incident.details.linesAdded': `{count__lines, plural,
		one {line added}
		other {lines added}
	}`,
	'ui.alerts.overview.incident.details.linesRemoved': `{count__lines, plural,
		one {line removed}
		other {lines removed}
	}`,
	'ui.alerts.overview.incident.details.noLongerMatchingCriteria': `no longer matching criteria`,
	'ui.alerts.overview.incident.details.pageType.missing': `removed`,
	'ui.alerts.overview.incident.details.pageType.redirect': `redirected`,
	'ui.alerts.overview.incident.details.pageType.serverError': `return server errors`,
	'ui.alerts.overview.incident.details.pageType.unreachable': `unreachable`,
	'ui.alerts.overview.incident.details.removedFromAlert': `{count__pages, plural, zero {removed from alert} one {removed from alert}
		other {removed from alert}
	}`,
	'ui.alerts.overview.incident.details.removedFromAlert.tooltip': `These pages were part of the alert before, but are no longer applicable. For example because the underlying problem was ignored or the issue configuration was changed.`,
	'ui.alerts.overview.incident.details.resolved': `resolved`,
	'ui.alerts.overview.incident.details.restored': `restored`,
	'ui.alerts.overview.incident.details.reverted': `reverted`,
	'ui.alerts.overview.incident.details.robotsTxtFlapping': `Two robots.txt versions are alternating.`,
	'ui.alerts.overview.incident.details.sslCertificate.domain': `Domain`,
	'ui.alerts.overview.incident.details.sslCertificate.expiration': `Expiration`,
	'ui.alerts.overview.incident.details.sslCertificate.renewed': `SSL certificate has been renewed.`,
	'ui.alerts.overview.incident.details.sslCertificate.replaced': `SSL certificate has been replaced.`,
	'ui.alerts.overview.incident.details.websiteHasTroubleResponding.currentStatus': `Current status`,
	'ui.alerts.overview.incident.details.websiteHasTroubleResponding.failureReasonPercentage': `{reason} ({percentage, number, ::percent})`,
	'ui.alerts.overview.incident.details.websiteHasTroubleResponding.failureReasonTotal': `{reason} ({count, number})`,
	'ui.alerts.overview.incident.details.websiteHasTroubleResponding.overallFailedRequests': `
		<p>This {isIncidentActive, select,
			yes {is}
			other {was}
		} most likely a website-wide problem, affecting also pages that Conductor Website Monitoring did not check during that period.</p>
		<p>Overall {count, plural,
			one {# request}
			other {# requests}
		} failed:</p>
	`,
	'ui.alerts.overview.incident.details.websiteHasTroubleResponding.overallImpact': `Overall impact`,
	'ui.alerts.overview.incident.details.websiteHasTroubleResponding.overallImpact.description': ``,
	'ui.alerts.overview.incident.details.websiteHasTroubleResponding.recentFailedRequests': `
		<p>{percentage, number, ::percent} of requests during last {window, time, ::m} minutes failed:</p>
	`,
	'ui.alerts.overview.incident.detected': `Detected:`,
	'ui.alerts.overview.incident.followup.viewChanges': `View changes`,
	'ui.alerts.overview.incident.followup.viewIssue': `View issue`,
	'ui.alerts.overview.incident.followup.viewPages': `View pages`,
	'ui.alerts.overview.incident.lastChange': `Last change:`,
	'ui.alerts.overview.noActiveIncidentsPlaceholder': `There are no active incidents at the moment.`,
	'ui.alerts.overview.recentIncidentsTitle': `Past incidents`,
	'ui.alertsConfiguration.actionsController.label': `{count__definitions, plural,
		one {# definition selected}
		other {# definitions selected}
	}`,
	'ui.alertsConfiguration.alert': `Alert`,
	'ui.alertsConfiguration.blankSlate.description': `
		<p>Someone on your team removed all alerts for this website.</p>
		<p>You can easily re-add them by clicking the <linkCreateAlertDefinitions>Create alert definitions</linkCreateAlertDefinitions> button at the top of the screen.</p>
	`,
	'ui.alertsConfiguration.blankSlate.title': `There are no alerts set up for this website.`,
	'ui.alertsConfiguration.buttons.backToOverview.label': `Back to incidents`,
	'ui.alertsConfiguration.buttons.createAlert.label': `Create alert definitions`,
	'ui.alertsConfiguration.buttons.delete.label': `Delete`,
	'ui.alertsConfiguration.buttons.editMessagingApp.label': `Edit messaging app`,
	'ui.alertsConfiguration.buttons.editRecipients.label': `Edit recipients`,
	'ui.alertsConfiguration.buttons.editScope.label': `Edit scope`,
	'ui.alertsConfiguration.buttons.editSensitivity.label': `Edit sensitivity`,
	'ui.alertsConfiguration.configurePagesEnteredOrLeftSegment': `Alternatively, you can also set up an alert for <link_switch>Pages entering or leaving a segment</link_switch>.`,
	'ui.alertsConfiguration.fields.customElement': `Custom element`,
	'ui.alertsConfiguration.fields.enrichmentField': `Enrichment field`,
	'ui.alertsConfiguration.fields.messagingApp': `Messaging app`,
	'ui.alertsConfiguration.fields.minimalNumericChange': `Min. absolute change`,
	'ui.alertsConfiguration.fields.minimalNumericChange.description': `For increased/decreased trigger`,
	'ui.alertsConfiguration.fields.minimalNumericChange.suffix.days': `days`,
	'ui.alertsConfiguration.fields.propertyChangeTypes': `Change type`,
	'ui.alertsConfiguration.fields.propertyChangeTypes.options.added': `Added`,
	'ui.alertsConfiguration.fields.propertyChangeTypes.options.changed': `Changed`,
	'ui.alertsConfiguration.fields.propertyChangeTypes.options.changedToFalse': `Changed to no`,
	'ui.alertsConfiguration.fields.propertyChangeTypes.options.changedToTrue': `Changed to yes`,
	'ui.alertsConfiguration.fields.propertyChangeTypes.options.decreased': `Decreased`,
	'ui.alertsConfiguration.fields.propertyChangeTypes.options.increased': `Increased`,
	'ui.alertsConfiguration.fields.propertyChangeTypes.options.movedBackward': `Moved backward`,
	'ui.alertsConfiguration.fields.propertyChangeTypes.options.movedForward': `Moved forward`,
	'ui.alertsConfiguration.fields.propertyChangeTypes.options.removed': `Removed`,
	'ui.alertsConfiguration.fields.recipients': `Recipients`,
	'ui.alertsConfiguration.fields.scope': `Scope`,
	'ui.alertsConfiguration.fields.segment': `Segment`,
	'ui.alertsConfiguration.fields.segment.currentSize': `Currently contains {size, plural,
		one {1 page}
		other {# pages}
	}`,
	'ui.alertsConfiguration.fields.segmentChangeType': `Change type`,
	'ui.alertsConfiguration.fields.sensitivity': `Sensitivity`,
	'ui.alertsConfiguration.fields.sensitivity.label': `Sensitivity level:`,
	'ui.alertsConfiguration.fields.sensitivity.noValue': `Move the slider to set the sensitivity.`,
	'ui.alertsConfiguration.fields.sensitivity.percentage.bubble': `{percentage, plural,
		=0 {any}
		other {{percentage, number, percent}}
	}`,
	'ui.alertsConfiguration.fields.sensitivity.percentage.value': `{percentage, plural,
		=0 {any page}
		other {{percentage, number, percent}}
	}`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.always': `On this sensitivity level, the alert will be triggered if any page is affected.`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.complex': `
		Threshold for triggering alert at current scope size ({scopePages, number} pages):
		<ul>
			<li><b>{mostImportantPages, number}</b> of the most important pages</li>
			<li><b>{leastImportantPages, number}</b> of the least important pages</li>
		</ul>
	`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.empty': `At the current scope size (no pages), the alert will never trigger.`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.pagesEntered': `At least {percentage, number, percent} of the size of this segment (currently {threshold, plural,
		one {# page}
		other {# pages}
	}), needs to enter this segment for the alert to trigger.`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.pagesEntered.any': `The alert will trigger if any page enters the segment.`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.pagesEnteredOrLeft': `At least {percentage, number, percent} of the size of this segment (currently {threshold, plural,
		one {# page}
		other {# pages}
	}), needs to leave or enter this segment for the alert to trigger.`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.pagesEnteredOrLeft.any': `The alert will trigger if any page enters or leaves the segment.`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.pagesLeft': `At least {percentage, number, percent} of the size of this segment (currently {threshold, plural,
		one {# page}
		other {# pages}
	}), needs to leave this segment for the alert to trigger.`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.pagesLeft.any': `The alert will trigger if any page leaves the segment.`,
	'ui.alertsConfiguration.fields.sensitivity.scenario.single': `
		Threshold for triggering alert at current scope size ({scopePages, number} pages):
		<ul>
			<li><b>{necessaryPages}</b> page</li>
		</ul>
	`,
	'ui.alertsConfiguration.fields.type': `Type`,
	'ui.alertsConfiguration.levels.always': `Always`,
	'ui.alertsConfiguration.levels.high': `High`,
	'ui.alertsConfiguration.levels.low': `Low`,
	'ui.alertsConfiguration.levels.medium': `Medium`,
	'ui.alertsConfiguration.messagingApp.connectNudge': `Connect your messaging app to start receiving alerts directly there.`,
	'ui.alertsConfiguration.messagingApp.none': `none`,
	'ui.alertsConfiguration.modal.bulk.header': `{count__definitions, plural,
		one {# selected definition}
		other {# selected definitions}
	}`,
	'ui.alertsConfiguration.modal.bulk.submitButton': `{count__definitions, plural,
		one {Save definition}
		other {Save definitions}
	}`,
	'ui.alertsConfiguration.modal.bulkEditMessagingApp.description': `Configure which channel from your messaging app should be notified about the selected alerts.`,
	'ui.alertsConfiguration.modal.bulkEditRecipients.description': `Configure which users in your account should receive the selected alerts.`,
	'ui.alertsConfiguration.modal.bulkEditScope.description': `Configure whether the selected alerts should trigger for the entire website or a specific segment.`,
	'ui.alertsConfiguration.modal.bulkEditSensitivity.description': `Configure the sensitivity level of the selected alerts. Setting a higher sensitivity level will trigger sending more alerts.`,
	'ui.alertsConfiguration.modal.create.alreadyCreatedDefinitions': `{count__definitions, plural,
		=0 {no definitions configured}
		one {# definition configured}
		other {# definitions configured}
	}`,
	'ui.alertsConfiguration.modal.create.alreadyExists': `already exists for all scopes`,
	'ui.alertsConfiguration.modal.create.alreadyExists.tooltip': `This alert configuration can only exist once per website.`,
	'ui.alertsConfiguration.modal.create.fakeSettings.breadcrumb': `Settings`,
	'ui.alertsConfiguration.modal.create.fakeTrigger.breadcrumb': `Trigger`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.breadcrumb': `Type`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.continue': `Continue`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.description': `Choose what you want to be alerted on.`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.options.custom_element_alerts': `Custom elements`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.options.enrichmentFieldAlerts': `Enrichment fields`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.options.entered_or_left_alerts': `Pages entering or leaving a segment`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.options.lighthouse_alerts': `Conductor Lighthouse Web Vitals`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.options.page_alerts': `Page properties and issues`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.options.platform_alerts': `Platform properties and issues`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.sidebar': `
		<p>Every website comes with a default set of alert definitions that will ensure that you are notified about every important change that occurs on your website.</p>
		<p>However, you can create additional alert definitions to cover your more specific use cases of what you want to be alerted about.</p>
		<p>You can learn more in <link_article>our support article</link_article>.</p>
	`,
	'ui.alertsConfiguration.modal.create.selectAlertGroup.title': `Create alert definitions`,
	'ui.alertsConfiguration.modal.create.selectAlertType.breadcrumb': `Trigger`,
	'ui.alertsConfiguration.modal.create.selectAlertType.continue': `Continue to settings`,
	'ui.alertsConfiguration.modal.create.selectAlertType.description': `Choose what you want to be alerted on.`,
	'ui.alertsConfiguration.modal.create.selectAlertType.sidebar.lighthouse_alerts': `
		<p>When Lighthouse Monitoring is enabled on a website, Conductor Website Monitoring automatically sets up default alert definitions for the Lighthouse Web Vitals.</p>
		<p>You can create more alert definitions of the same type, for example if you want to set up alerting for specific parts of your website.</p>
		<p>Some examples:</p>
		<ul>
			<li>get alerted about pages with a lot of visitors sooner than other pages</li>
			<li>alert different team members depending on the affected section of the website</li>
		</ul>
		<p>See our best practices for alert configuration in <link_article>our support article</link_article>.</p>
	`,
	'ui.alertsConfiguration.modal.create.selectAlertType.sidebar.page_alerts': `
		<p>When adding a website to Conductor Website Monitoring we automatically set up default alert definitions for page properties and issues.</p>
		<p>You can create more alert definitions of the same type, for example if you want to set up alerting for specific parts of your website.</p>
		<p>Some examples:</p>
		<ul>
			<li>alert about pages with a lot of visitors sooner than other pages</li>
			<li>alert different team members depending on the affected section of the website</li>
		</ul>
		<p>See our best practices for alert configuration in <link_article>our support article</link_article>.</p>
	`,
	'ui.alertsConfiguration.modal.create.selectAlertType.sidebar.property_alerts': `
	<p>When adding a website to Conductor Website Monitoring we automatically set up default alert definitions for platform properties and issues.</p>
	<p>Note that you can only have one definition with these alert types per website.</p>
	<p>You can learn more in <link_article>our support article</link_article>.</p>
`,
	'ui.alertsConfiguration.modal.create.selectAlertType.title.lighthouse_alerts': `Create alerts for Conductor Lighthouse Web Vitals`,
	'ui.alertsConfiguration.modal.create.selectAlertType.title.page_alerts': `Create alerts for page properties or issues`,
	'ui.alertsConfiguration.modal.create.selectAlertType.title.property_alerts': `Create alerts for platform properties or issues`,
	'ui.alertsConfiguration.modal.create.setupCustomElementAlert.breadcrumb': `Trigger`,
	'ui.alertsConfiguration.modal.create.setupCustomElementAlert.continue': `Continue to settings`,
	'ui.alertsConfiguration.modal.create.setupCustomElementAlert.description': `Configure which custom element you want to be alerted about, and how it needs to change for the alert to trigger.`,
	'ui.alertsConfiguration.modal.create.setupCustomElementAlert.noCustomElements': `<link_custom_elements>Create a custom element</link_custom_elements> to use this feature`,
	'ui.alertsConfiguration.modal.create.setupCustomElementAlert.sidebar': `
		<p><b>Custom element</b> determines on which custom element this alert will trigger.</p>
		<p><b>Change type</b> determines how the custom element needs to change for the alert to trigger.</p>
		{minimalNumericChange, select,
			visible {<p><b>Min. absolute change</b> determines by how much the custom element needs to change at a minimum for the alert to trigger.</p>}
			other {}
		}
		<p>Learn more about these settings in <link_article>our support article</link_article>.</p>
	`,
	'ui.alertsConfiguration.modal.create.setupCustomElementAlert.title': `Create alert for custom element`,
	'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.breadcrumb': `Trigger`,
	'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.continue': `Continue to settings`,
	'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.description': `Configure which enrichment field you want to be alerted about, and how it needs to change for the alert to trigger.`,
	'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.noCustomElements': `<link>Create enrichment field</link> to use this feature`,
	'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.sidebar': `
		<p><b>Enrichment field</b> determines on which enrichment field this alert will trigger.</p>
		<p><b>Change type</b> determines how the enrichment field needs to change for the alert to trigger.</p>
		{minimalNumericChange, select,
			visible {<p><b>Min. absolute change</b> determines by how much the enrichment field needs to change at a minimum for the alert to trigger.</p>}
			other {}
		}
		<p>Learn more about these settings in <link>our support article</link>.</p>
	`,
	'ui.alertsConfiguration.modal.create.setupEnrichmentFieldAlert.title': `Create alert for enrichment field`,
	'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.breadcrumb': `Trigger`,
	'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.continue': `Continue to settings`,
	'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.description': `Configure which segment you want to be alerted about and how much the segment needs to change for the alert to open.`,
	'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.sidebar': `
		<p><b>Segment</b> determines what part of the website you will be alerted about.</p>
		<p><b>Sensitivity</b> determines how soon the alert triggers, based on the number of affected pages and their <i>Importance</i>.</p>
		<p>Learn more about these settings in <link_article>our support article</link_article>.</p>
	`,
	'ui.alertsConfiguration.modal.create.setupEnteredOrLeftAlert.title': `Create alert on pages entering or leaving a segment`,
	'ui.alertsConfiguration.modal.create.setupSettings.breadcrumb': `Settings`,
	'ui.alertsConfiguration.modal.create.setupSettings.continue': `Save {count__definitions, plural,
		one {definition}
		other {definitions}
	}`,
	'ui.alertsConfiguration.modal.create.setupSettings.description': `Configure the scope and sensitivity level of the alert definition, as well as who to notify.`,
	'ui.alertsConfiguration.modal.create.setupSettings.sidebar': `
		<p>These settings will be applied to all alert types you selected in the previous step.</p>
		{scope, select,
			visible {<p><b>Scope</b> determines what part of the website you will be alerted about.</p>}
			other {}
		}
		{sensitivity, select,
			visible {<p><b>Sensitivity</b> determines how soon the alert triggers, based on the number of affected pages and their <i>Importance</i>.</p>}
			other {}
		}
		<p><b>Recipients</b> are the team members who will receive the alert via email.</p>
		<p><b>Messaging app</b> determines which channel in Slack or in Microsoft Teams gets notified when an alert triggers or closes.</p>
		<p>Not sure how to configure these settings? Learn more in <link_article>our support article</link_article>.</p>
	`,
	'ui.alertsConfiguration.modal.create.setupSettings.title.custom_element_alerts': `Create alert for custom element`,
	'ui.alertsConfiguration.modal.create.setupSettings.title.enrichmentFieldAlerts': `Create alert for enrichment field`,
	'ui.alertsConfiguration.modal.create.setupSettings.title.entered_or_left_alerts': `Create alert on pages entering or leaving a segment`,
	'ui.alertsConfiguration.modal.create.setupSettings.title.lighthouse_alerts': `Create {count__definitions, plural,
		one {1 alert}
		other {# alerts}
	} for Conductor Lighthouse Web Vitals`,
	'ui.alertsConfiguration.modal.create.setupSettings.title.page_alerts': `Create {count__definitions, plural,
		one {1 alert}
		other {# alerts}
	} for page properties or issues`,
	'ui.alertsConfiguration.modal.create.setupSettings.title.platform_alerts': `Create {count__definitions, plural,
		one {1 alert}
		other {# alerts}
	} for platform properties or issues`,
	'ui.alertsConfiguration.modal.edit.description': `Configure the scope and sensitivity level of an alert, as well as who to notify.`,
	'ui.alertsConfiguration.modal.edit.descriptionPagesEnteredOrLeftSegment': `Configure which segment you want to be alerted about, how much the segment needs to change for the alert to open, and who should receive the alert.`,
	'ui.alertsConfiguration.modal.edit.sidebar': `
		<p><b>Scope</b> determines what part of the website you will be alerted about.</p>
		<p><b>Sensitivity</b> determines how soon the alert triggers, based on the number of affected pages and their <i>Importance</i>.</p>
		<p><b>Recipients</b> are the team members who will receive the alert via email.</p>
		<p><b>Messaging app</b> determines which channel in Slack or in Microsoft Teams gets notified when an alert triggers or closes.</p>
	`,
	'ui.alertsConfiguration.modal.edit.sidebarPagesEnteredOrLeftSegment': `
		<p><b>Segment</b> determines on which segment this alert will trigger.</p>
		<p><b>Change type</b> determines the direction of the segment change (pages entering and / or leaving).</p>
		<p><b>Sensitivity</b> determines how soon the alert should trigger: how much the segment needs to change for the alert to trigger.</p>
		<p><b>Recipients</b> are the team members who will receive the alert via email.</p>
		<p><b>Messaging app</b> determines which channel in Slack or in Microsoft Teams gets notified when an alert triggers or closes.</p>
	`,
	'ui.alertsConfiguration.segmentChangeType.entered': `Pages entered`,
	'ui.alertsConfiguration.segmentChangeType.enteredOrLeft': `Pages entered or left`,
	'ui.alertsConfiguration.segmentChangeType.left': `Pages left`,
	'ui.alertsConfiguration.title': `Alert definitions`,
	'ui.allMembershipLabels.disabledHint': `{isConductorClient, select,
		yes {Please log in to your <linkContentKing>Conductor Website Monitoring account</linkContentKing> to work with this account. Once logged in, you can access it at https://app.contentkingapp.com/.}
		no {Please log in to your <linkToConductorLogin>Conductor Intelligence account</linkToConductorLogin> to work with this account. Once logged in, you can access it at https://contentking.app.conductor.com/.}
		other {}
	} You can always <showIntercom>contact us</showIntercom> if you need help.`,
	'ui.almostEqualTo': `≈`,
	'ui.appUpdated.button': `Refresh`,
	'ui.appUpdated.message': `🚀 Conductor Website Monitoring has been updated!`,
	'ui.backlink.desktop.affectedLinks': `Affected Links`,
	'ui.backlink.desktop.affectedPages': `Affected Pages`,
	'ui.backlink.desktop.alerts': `Alerts`,
	'ui.backlink.desktop.closedPages': `Fixed Pages`,
	'ui.backlink.desktop.default': `Back`,
	'ui.backlink.desktop.issueDetail': `Issue`,
	'ui.backlink.desktop.issuesOverview': `Issues`,
	'ui.backlink.desktop.pageDetail': `Page`,
	'ui.backlink.desktop.pagesOverview': `Pages`,
	'ui.backlink.desktop.platform': `Platform`,
	'ui.backlink.desktop.website': `Website`,
	'ui.backlink.desktop.websites': `Websites`,
	'ui.backlink.desktop.websiteSettings': `Website Settings`,
	'ui.backlink.mobile.affectedLinks': `Back to affected links`,
	'ui.backlink.mobile.affectedPages': `Back to affected pages`,
	'ui.backlink.mobile.closedPages': `Back to fixed pages`,
	'ui.backlink.mobile.default': `Back`,
	'ui.backlink.mobile.issueDetail': `Back to issue`,
	'ui.backlink.mobile.issuesOverview': `Back to issues`,
	'ui.backlink.mobile.members': `Back to team members`,
	'ui.backlink.mobile.pageDetail': `Back to page`,
	'ui.backlink.mobile.pagesOverview': `Back to pages`,
	'ui.backlink.mobile.platform': `Back to platform`,
	'ui.backlink.mobile.website': `Back to website`,
	'ui.backlink.mobile.websites': `Back to websites`,
	'ui.backlink.mobile.websiteSettings': `Back to website settings`,
	'ui.betaFeatureRibbon.featureDisabled': `
		<p>We are still polishing this feature.</p>
		<p>Try it out now, and please <showIntercom>share your feedback with us</showIntercom>!</p>
	`,
	'ui.betaFeatureRibbon.featureEnabled': `
		<p>We are still polishing this feature.</p>
		<p>How do you like this feature so far? Please <showIntercom>share your feedback with us</showIntercom>!</p>
	`,
	'ui.betaFeatureRibbon.ribbon': `Beta`,
	'ui.billing.changeSummary.cannotUpgradeSubscriptionBecauseInsufficientPermissionsForKingdomAdmin': `This change requires upgrading the subscription. Your Royalty level doesn't have sufficient access rights to make this change on user's behalf.`,
	'ui.billing.changeSummary.cannotUpgradeSubscriptionBecauseManaged': `This change requires upgrading your subscription. Please <showIntercom>contact us</showIntercom> if you want to make any changes to your subscription.`,
	'ui.billing.changeSummary.header': `Change summary`,
	'ui.billing.changeSummary.list.billingCycleChanged.longer': `Billing cycle will change to <b>{billingCycleName}</b>.`,
	'ui.billing.changeSummary.list.billingCycleChanged.shorter': `Billing cycle will change to <b>{billingCycleName}</b> after your current subscription period ends.`,
	'ui.billing.changeSummary.list.pagesChanged': `{pagesDiff, select,
		bundle {Page Bundle}
		capacity {Page Capacity}
		other {}
	} will change to <b>{pages, number} pages</b>.`,
	'ui.billing.changeSummary.list.planChanged': `Plan will change to <b>{planName}</b>.`,
	'ui.billing.changeSummary.list.priceChanged.additionalCost': `These changes cost an additional <b>{additionalCostPerBillingCycle}</b>.`,
	'ui.billing.changeSummary.list.priceChanged.newPrice': `You'll be charged <b>{pricePerBillingCycle}</b>.`,
	'ui.billing.changeSummary.list.websitesChanged.more': `You are going to add <b>{websites, plural,
		=1 {# website}
		other {# websites}
	}</b> with a total Page Capacity of <b>{additionalWebsitesPageCapacity, number} pages</b>.
	`,
	'ui.billing.changeSummary.pricePerBillingCycle': `{price}{tax, select,
		SalesTax { (+Sales Tax)}
		Vat { (+VAT)}
		other {}
	} {billingCycle, select,
		monthly {per month}
		quarterly {per quarter}
		annually {per year}
		other {{months, plural,
			one {per month}
			other {every # months}
		}}
	}`,
	'ui.billing.changeSummary.proratedCharge': `You'll be charged a prorated amount now.`,
	'ui.billing.changeSummary.proratedCredit': `The cost difference for the remainder of your current billing cycle will be credited to your account.`,
	'ui.billing.changeSummary.single.billingCycleChanged.longer': `Billing cycle will change to <b>{billingCycleName}</b>. You'll be charged <b>{pricePerBillingCycle}</b>. The cost difference for the remainder of your current billing cycle will be credited to your account.`,
	'ui.billing.changeSummary.single.billingCycleChanged.shorter': `Billing cycle will change to <b>{billingCycleName}</b> after your current subscription period ends. You'll be charged <b>{pricePerBillingCycle}</b>.`,
	'ui.billing.changeSummary.single.pagesChanged.less': `{pagesDiff, select,
		bundle {Page Bundle}
		capacity {Page Capacity}
		other {}
	} will change to <b>{pages, number} pages</b>. The cost difference for the remainder of your current billing cycle will be credited to your account.`,
	'ui.billing.changeSummary.single.pagesChanged.more': `{pagesDiff, select,
		bundle {Page Bundle}
		capacity {Page Capacity}
		other {}
	} will change to <b>{pages, number} pages</b>, for an additional cost of <b>{additionalCostPerBillingCycle}</b>. You'll be charged a prorated amount now.`,
	'ui.billing.changeSummary.single.planChanged.higher': `Plan will change to <b>{planName}</b>, for an additional cost of <b>{additionalCostPerBillingCycle}</b>. You'll be charged a prorated amount now.`,
	'ui.billing.changeSummary.single.planChanged.lower': `Plan will change to <b>{planName}</b>. The cost difference for the remainder of your current billing cycle will be credited to your account.`,
	'ui.billing.cycle.annually': `Annually`,
	'ui.billing.cycle.anyInMonths': `{count__months, plural,
		one {# month}
		other {# months}
	}`,
	'ui.billing.cycle.monthly': `Monthly`,
	'ui.billing.cycle.quarterly': `Quarterly`,
	'ui.billing.cycleDescription.annually': `Billed annually`,
	'ui.billing.cycleDescription.annuallyDiscount': `2 months for free`,
	'ui.billing.cycleDescription.monthly': `Billed monthly`,
	'ui.billing.formGroups.billingDetails': `Billing details`,
	'ui.billing.formGroups.invoices': `Invoices`,
	'ui.billing.needHelpPickingPlan.header': `Need some help picking the right plan?`,
	'ui.billing.paymentBillingCycle.annually.inline': `per month, paid annually`,
	'ui.billing.paymentBillingCycle.annually.multiline': `per month, {br}paid annually`,
	'ui.billing.paymentBillingCycle.monthly.inline': `per month`,
	'ui.billing.paymentBillingCycle.monthly.multiline': `per month`,
	'ui.billing.paymentBillingCycle.monthsNotation.inline': `per month, paid {count__months, plural,
		one {per month}
		other {per # months}
	}`,
	'ui.billing.paymentBillingCycle.monthsNotation.multiline': `per month, {br}paid {count__months, plural,
		one {per month}
		other {per # months}
	}`,
	'ui.billing.paymentBillingCycle.quarterly.inline': `per month, paid quarterly`,
	'ui.billing.paymentBillingCycle.quarterly.multiline': `per month, {br}paid quarterly`,
	'ui.billing.perCycle.annually': `per year`,
	'ui.billing.perCycle.inMonths': `per {count__months, plural,
		one {month}
		other {# months}
	}`,
	'ui.billing.perCycle.monthly': `per month`,
	'ui.billing.perCycle.quarterly': `per quarter`,
	'ui.billing.pricePerCycle.annually': `{price} per year`,
	'ui.billing.pricePerCycle.monthly': `{price} per month`,
	'ui.billing.pricePerCycle.quarterly': `{price} per quarter`,
	'ui.billing.priceSummary.discounts.customExact': `Special Discount`,
	'ui.billing.priceSummary.discounts.customPercentage': `{count__discountAmount, number, percent} Special Discount`,
	'ui.billing.priceSummary.website': `{count, plural,
		=1 {# website}
		other {# websites}
	}`,
	'ui.billing.switchCurrencyLink': `Switch currency`,
	'ui.billingErrors.disallowedChangeOfBillingEntity': `Change of billing entity isn't supported yet. Please contact us.`,
	'ui.bookADemoButton': `Book a demo`,
	'ui.bookWebinar.button': `Watch our webinar`,
	'ui.bookWebinar.description': `In this webinar you will learn all about how to get the most out of Conductor Website Monitoring in just 1 hour.`,
	'ui.bookWebinar.title': `Watch our Platform Tour webinar`,
	'ui.bulkActions.controller.label': `{count, plural,
		=1 {# alert selected}
		other {# alerts selected}
	}`,
	'ui.button.changeEnrichmentApiToken': `Confirm`,
	'ui.button.changeReportingApiToken': `Confirm`,
	'ui.calendarRange.rangeSelector.fromLabel': `From`,
	'ui.calendarRange.rangeSelector.toLabel': `To`,
	'ui.cancelConnectedAccountAccessRequestConfirmation.changeYourMind': `If you change your mind later on, you will need to send a new access request.`,
	'ui.cancelConnectedAccountAccessRequestConfirmation.confirmButton': `Cancel request`,
	'ui.cancelConnectedAccountAccessRequestConfirmation.labelConnectedAccount': `Connected account`,
	'ui.cancelConnectedAccountAccessRequestConfirmation.title': `access request`,
	'ui.cancelConnectedAccountAccessRequestConfirmation.verb': `Cancel`,
	'ui.cancelConnectedAccountAccessRequestConfirmation.warnPermanent': `This action will <b>cancel your request</b> to access the following account:`,
	'ui.cancelInvitationConfirmation.confirmButton': `Cancel invitation`,
	'ui.cancelInvitationConfirmation.description': `By cancelling the invitation the invitee won't be able to join your team or access any of your team's data. They will have to be re-invited again to join your team.`,
	'ui.cancelInvitationConfirmation.labelAccount': `To join account`,
	'ui.cancelInvitationConfirmation.labelInvitee': `Invitee`,
	'ui.cancelInvitationConfirmation.title': `invitation`,
	'ui.cancelInvitationConfirmation.verb': `Cancel`,
	'ui.cancelInvitationConfirmation.warnPermanent': `This action will <b>permanently cancel</b> the invitation of the invitee:`,
	'ui.cancelInvitationConfirmation.yourAccount': `Your account`,
	'ui.changePasswordModal.sudoModeDescription': `Before {hasPassword, select,
		yes {changing}
		other {setting}
	} your password, please enter the 6-digit code generated by the Authenticator app on your mobile device:`,
	'ui.changeReportingApiTokenModal.confirm': `Confirm`,
	'ui.changeRole.downgrade.description': `Downgrading to this role will limit the team member's capabilities.`,
	'ui.changeRole.downgrade.title': `Team member will have reduced access`,
	'ui.changeRoleModal.steps.changeRole.content.submitButton.changeRole': `Change role`,
	'ui.changeRoleModal.steps.changeRole.content.submitButton.selectWebsites': `Select websites`,
	'ui.changeRoleModal.title': `Change role for`,
	'ui.chooseCustomerType.prompt': `What best describes your organization?`,
	'ui.churnedAccountModal.contactUs': `Contact us`,
	'ui.churnedAccountModal.content': `
		<p>Your Conductor Website Monitoring subscription has ended. Do you want to give Conductor Website Monitoring another chance?</p>
		<p>Sign up now to stay one step ahead of SEO disasters and keep your websites optimized.</p>
	`,
	'ui.churnedAccountModal.footnote': `You can still <linkAccount>manage your account</linkAccount>.{br}<showIntercom>Contact us</showIntercom> if you have any questions.`,
	'ui.churnedAccountModal.greeting': `<p>Hi {firstName},</p>`,
	'ui.churnedAccountModal.oneMoreThing': `<p>Just one more thing before you get started:</p>`,
	'ui.churnedAccountModal.signUp': `Sign up`,
	'ui.churnedAccountModal.title': `Nice to see you again!`,
	'ui.clipboardTextField.buttonLabel': `Copy value to clipboard`,
	'ui.clipboardTextField.valueCopied': `Value copied to clipboard!`,
	'ui.coBranding.customLogo.changeButton': `Change`,
	'ui.coBranding.customLogo.label': `Custom logo`,
	'ui.coBranding.customLogo.paragraph': `Size of the logo has to be <b>160x39px</b>.{br}Make sure the logo has <b>transparent background</b> and is adjusted for usage on dark backgrounds.`,
	'ui.coBranding.customLogo.uploadButton': `Upload custom logo`,
	'ui.coBranding.customLogo.uploadError.fileFormat': `Invalid image format. Please use JPG, PNG, SVG, or BMP`,
	'ui.coBranding.customLogo.uploadError.fileSize': `Selected image is too large. Please use an image smaller than 2MB`,
	'ui.coBranding.customLogo.view.notUploaded': `Click to upload`,
	'ui.coBranding.customLogo.view.uploaded': `Uploaded. Click here to update`,
	'ui.coBranding.interface.logo': `{accountName} logo`,
	'ui.codeViewer.hiddenLines': `{count, plural,
		=1 {1 hidden line}
		other {# hidden lines}
	}`,
	'ui.codeViewer.showLines': `Show lines`,
	'ui.columnsConfigurator.addForm.submit': `SAVE COLUMN SET`,
	'ui.columnsConfigurator.addForm.success': `Column set saved`,
	'ui.columnsConfigurator.addForm.title': `Save as new column set`,
	'ui.columnsConfigurator.button.createColumnSet': `CREATE COLUMN SET`,
	'ui.columnsConfigurator.button.enableColumnSets': `ENABLE COLUMN SETS`,
	'ui.columnsConfigurator.button.rename': `RENAME`,
	'ui.columnsConfigurator.button.revert': `revert changes`,
	'ui.columnsConfigurator.button.save': `SAVE`,
	'ui.columnsConfigurator.button.saveAs': `SAVE AS…`,
	'ui.columnsConfigurator.button.share': `SHARE`,
	'ui.columnsConfigurator.customElements.addButtonLabel': `Create custom element`,
	'ui.columnsConfigurator.delete.success': `Column set deleted`,
	'ui.columnsConfigurator.deleteForm.body': `This column set is shared, so by deleting it everybody in your team will lose access.`,
	'ui.columnsConfigurator.deleteForm.submit': `DELETE COLUMN SET`,
	'ui.columnsConfigurator.deleteForm.title': `Delete column set`,
	'ui.columnsConfigurator.drawer.heading': `Column sets`,
	'ui.columnsConfigurator.enabledColumnSets.note': `With <b>column sets</b> you can define different column configurations and quickly toggle between them.`,
	'ui.columnsConfigurator.heading.personal': `Personal sets`,
	'ui.columnsConfigurator.heading.shared': `Shared sets`,
	'ui.columnsConfigurator.heading.tag.changed': `(changed)`,
	'ui.columnsConfigurator.heading.tag.deleted': `(deleted)`,
	'ui.columnsConfigurator.heading.tag.unsaved': `(unsaved)`,
	'ui.columnsConfigurator.heading.view': `Column set`,
	'ui.columnsConfigurator.heading.view.personal': `Column set`,
	'ui.columnsConfigurator.heading.view.shared': `Shared column set`,
	'ui.columnsConfigurator.heading.view.unsaved': `New column set`,
	'ui.columnsConfigurator.lighthouse.enableButtonLabel': `Go to settings`,
	'ui.columnsConfigurator.logFileAnalysis.enableButtonLabel': `Go to settings`,
	'ui.columnsConfigurator.messages.lighthouseNotEnabled': `You don't have Conductor Lighthouse Web Vitals enabled.`,
	'ui.columnsConfigurator.messages.logFileAnalysisNotEnabled': `You don't have Log File Analysis enabled.`,
	'ui.columnsConfigurator.messages.noCustomElements': `You don't have any custom elements configured yet. `,
	'ui.columnsConfigurator.messages.unavailableInChangeTracking': `Conductor Website Monitoring doesn't support change tracking on this column yet.`,
	'ui.columnsConfigurator.sharing.bottomMessage.personal': `<b>Sharing column set</b> makes it accessible and editable by all members of your team.`,
	'ui.columnsConfigurator.sharing.bottomMessage.shared': `This column set is <b>shared</b> and accessible by all your team members.`,
	'ui.columnsConfigurator.sharing.toggleLabel': `Share column set with the team`,
	'ui.columnsConfigurator.toggleLabel': `Manage columns`,
	'ui.columnsConfigurator.view.default': `Default`,
	'ui.columnsConfigurator.view.socialMedia': `Social media`,
	'ui.columnsConfigurator.view.unavailable': `Name already in use`,
	'ui.columnsConfigurator.view.unsaved': `unsaved`,
	'ui.conductorAuthentication.title': `Authenticating...`,
	'ui.connectedAccounts.addAccount': `Connect account`,
	'ui.connectedAccounts.menuLabel': `Connected Accounts`,
	'ui.connectedAccounts.modal.acceptAccessToClient.acceptButton': `Accept request`,
	'ui.connectedAccounts.modal.acceptAccessToClient.description': `Conductor Website Monitoring account <b>{clientAccountName}</b> offers you access to their account.`,
	'ui.connectedAccounts.modal.acceptAccessToClient.identification.account': `Account`,
	'ui.connectedAccounts.modal.acceptAccessToClient.identification.email': `Email address`,
	'ui.connectedAccounts.modal.acceptAccessToClient.rejectButton': `Reject request`,
	'ui.connectedAccounts.modal.acceptAccessToClient.title': `Accept access request`,
	'ui.connectedAccounts.modal.connectAccount.accessRequestedStep.description': `
		<p>Congratulations! Your connection request has been sent to: <b>{email}</b>.</p>
		<p>Once your request has been accepted, you'll be able to access and manage their account like it was your own.</p>
	`,
	'ui.connectedAccounts.modal.connectAccount.accessRequestedStep.heading': `Access was successfully requested`,
	'ui.connectedAccounts.modal.connectAccount.accessRequestedStep.title': `Connect to an existing account`,
	'ui.connectedAccounts.modal.connectAccount.accountCreatedStep.createdWithoutUserDescription': `
		<p>Congratulations! You've created a new Conductor Website Monitoring account <b>{accountName}</b></p>
		<p>You can access the account right away.</p>
	`,
	'ui.connectedAccounts.modal.connectAccount.accountCreatedStep.createdWithUserDescription': `
		<p>Congratulations! You've created a new Conductor Website Monitoring account for <b>{email}</b>.</p>
		<p>The invitation to their brand new Conductor Website Monitoring account is waiting for them in their mailbox. You can skip the wait, though, and access their account right away.</p>
	`,
	'ui.connectedAccounts.modal.connectAccount.accountCreatedStep.heading': `Account was successfully created`,
	'ui.connectedAccounts.modal.connectAccount.accountCreatedStep.title': `Create a new account`,
	'ui.connectedAccounts.modal.connectAccount.connectExisting.description': `Enter the email address of a user on the account you want to connect to.`,
	'ui.connectedAccounts.modal.connectAccount.connectExisting.emailLabel': `Email address`,
	'ui.connectedAccounts.modal.connectAccount.connectExisting.sidebar': `
		<p>The person you're sending the access request to needs to have the Manager-role on the account you're connecting to.</p>
		<p>After accepting the invitation, you will be able to access it and manage it like it was your own: add websites, invite more team members, create segments and alerts, etc.</p>
		<p>You can learn more in <link_article>our support article</link_article>.</p>
	`,
	'ui.connectedAccounts.modal.connectAccount.connectExisting.title': `Connect to an existing account`,
	'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.action.connectExistingAccount': `Connect to an existing Conductor Website Monitoring account`,
	'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.action.createNewAccount': `Create a new Conductor Website Monitoring account`,
	'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.cancel': `cancel`,
	'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.description': `Choose whether you want to create a new account, or connect to an existing account.`,
	'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.invite': `If you want to invite somebody as a user to your account ({accountName}) instead, go to <link_team_members>Team members</link_team_members>.`,
	'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.sidebar': `
		<p>Connecting your account to another account lets you seamlessly collaborate with another team on their websites, as if their websites were in your account. This is typically used by agencies to connect with their clients' accounts.</p>
		<p>Access across accounts is only one-directional: team members in your account can access the connected account, but the connected account can’t access your account.</p>
		<p>You can learn more in <link_article>our support article</link_article>.</p>
	`,
	'ui.connectedAccounts.modal.connectAccount.connectToAccountStep.title': `Connect to an account`,
	'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.accountNameLabel': `Account name`,
	'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.createWithoutUser': `Create the account without a user`,
	'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.createWithoutUserDescription': `Choose whether you want to create this account with a user who will be in charge of it, or whether you want to create the account without any user.`,
	'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.createWithUser': `Add a user to the new account`,
	'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.description': `{canCreateAccountWithoutUsers, select,
		yes {Enter the website that the new account will monitor.}
		other {Enter the website that the new account will monitor and the email address of the person who will be in charge of the account. This person will receive an invitation to Conductor Website Monitoring.}
	}`,
	'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.emailLabel': `Email address`,
	'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.sidebar': `
		<p>This will create a new Conductor Website Monitoring trial account with the website that you enter.</p>
		{canCreateAccountWithoutUsers, select,
			yes {
				<p>You will immediately get access to the account, and be able to manage it in the same way as your own account.</p>
				<p>If you enter an email address, it will be added as a user and receive an invitation to access the account. They will be able to manage the account, add websites and invite other users.</p>
				<p>You can always add users to the connected accounts later.</p>
			}
			other {
				<p>The email address you enter will be added as a user and receive an invitation to access the account. They will be able to manage the account, add websites and invite other users.</p>
				<p>You will also immediately get access to the account, and be able to manage it in the same way as your own account.</p>
			}
		}
		<p>You can learn more about this in <link_article>our support article</link_article>.</p>
	`,
	'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.title': `Create a new account`,
	'ui.connectedAccounts.overview.action.cancelRequest': `Cancel request`,
	'ui.connectedAccounts.overview.action.removeAccess': `Remove access`,
	'ui.connectedAccounts.overview.action.viewRequest': `View request`,
	'ui.connectedAccounts.overview.blankSlate': `
		<p>Your account is not connected to any account yet.</p>
		<p>Once connected to another account, you can seamlessly collaborate with another team on improving their websites, in the same way as if their websites were in your account.</p>
		{isCertifiedPartner, select,
			yes {<p>As a Certified Partner you’ll <b>earn 20% commission</b> on all revenue from your clients.</p>}
			other {}
		}
		<p><openModal>Add an account</openModal> to get started, read more in <linkArticle>our support article</linkArticle>, or watch this short video about how you can collaborate with another team in Conductor Website Monitoring.</p>
	`,
	'ui.connectedAccounts.overview.featureUnavailable': `
		{accountPhase, select,
			trial {Connected Accounts is not available during trials. Once you sign up, you will be able to connect to other Conductor Website Monitoring accounts.}
			other {Connected Accounts is not available on your account.}
		}
		{br}
		you can read more in <linkSupport>our support article</linkSupport>
	`,
	'ui.connectedAccounts.overview.status.accessOffered': `Access offered`,
	'ui.connectedAccounts.overview.status.accessRequested': `Access requested`,
	'ui.connectedAccounts.overview.status.connected': `Connected`,
	'ui.connectedAccounts.overview.table.column.accessRights': `Access rights`,
	'ui.connectedAccounts.overview.table.column.action': `Action`,
	'ui.connectedAccounts.overview.table.column.name': `Account`,
	'ui.connectedAccounts.overview.table.column.status': `Status`,
	'ui.connectedAccounts.settings.connectionDirection.cannotModifyConnectionDirectionDueToExistingConnectionToClient': `Your account has pending requests to access, or already has access, to other accounts. To change the connection direction, first you need to remove those connections from the <link_connected_accounts>Connected accounts</link_connected_accounts> screen.`,
	'ui.connectedAccounts.settings.connectionDirection.cannotModifyConnectionDueToExistingConnectionToAgency': `
		{connectionStatus, select,
			pending {Account {accountName} has pending request to access your account. To change the connection direction, first you need to <linkCancelRequest>cancel that request</linkCancelRequest>.}
			established {Account {accountName} has access to your account. To change the connection direction, first you need to <linkRemoveConnection>remove that connection</linkRemoveConnection>.}
			other {}
		}
	`,
	'ui.connectedAccounts.settings.connectionDirection.description': `
		<p>The Connected Accounts feature allows you to connect with other Conductor Website Monitoring accounts. This is typically used by agencies to collaborate with their clients who have their own Conductor Website Monitoring account.</p>
		<p>You can learn more in <link_support>our support article</link_support>.</p>
	`,
	'ui.connectedAccounts.settings.connectionDirection.label': `Connection{br}direction`,
	'ui.connectedAccounts.settings.connectionDirection.title': `Connection direction`,
	'ui.connectedAccounts.settings.connectionDirection.value.connectToAgency': `Other accounts can request access to this account`,
	'ui.connectedAccounts.settings.connectionDirection.value.connectToClient': `This account can request access to other accounts`,
	'ui.connectedAccounts.title': `Connected Accounts`,
	'ui.contentData.adobe_analytics': `Adobe Analytics`,
	'ui.contentData.adobe_tag_manager': `Adobe Tag Manager`,
	'ui.contentData.blocked_by_meta_robots': `Blocked by meta robots`,
	'ui.contentData.canonical': `Canonical`,
	'ui.contentData.canonical_link': `Canonical`,
	'ui.contentData.canonicalToSelf': `self`,
	'ui.contentData.clicktale': `Clicktale`,
	'ui.contentData.clicky': `Clicky`,
	'ui.contentData.contentsquare': `Contentsquare`,
	'ui.contentData.conversions.sections.qualitativeAnalytics': `Visual Analytics`,
	'ui.contentData.conversions.sections.quantitativeAnalytics': `Analytics`,
	'ui.contentData.conversions.sections.tagManagement': `Tag Manager`,
	'ui.contentData.crazy_egg': `CrazyEgg`,
	'ui.contentData.google_analytics': `Google Analytics`,
	'ui.contentData.google_tag_manager': `Google Tag Manager`,
	'ui.contentData.h1': `H1`,
	'ui.contentData.h2': `H2`,
	'ui.contentData.h3': `H3`,
	'ui.contentData.h4': `H4`,
	'ui.contentData.h5': `H5`,
	'ui.contentData.h6': `H6`,
	'ui.contentData.hotjar': `Hotjar`,
	'ui.contentData.inspectlet': `Inspectlet`,
	'ui.contentData.is_in_sitemap': `In XML sitemap`,
	'ui.contentData.is_in_sitemap_values.no': `no`,
	'ui.contentData.is_in_sitemap_values.yes': `yes`,
	'ui.contentData.is_indexable': `Indexable`,
	'ui.contentData.is_indexable_values.no': `no`,
	'ui.contentData.is_indexable_values.yes': `yes`,
	'ui.contentData.is_secured': `HTTPS`,
	'ui.contentData.is_secured_values.no': `no`,
	'ui.contentData.is_secured_values.yes': `yes`,
	'ui.contentData.link_alternate_hreflang': `Hreflang`,
	'ui.contentData.link_amp': `AMP variant`,
	'ui.contentData.link_next': `Next`,
	'ui.contentData.link_prev': `Prev`,
	'ui.contentData.linkCanonicalHeader': `Canonical (HTTP header)`,
	'ui.contentData.linkCanonicalHtml': `Canonical (on-page)`,
	'ui.contentData.loadingDetails': `Loading details`,
	'ui.contentData.meta_bingbot': `Meta Bingbot`,
	'ui.contentData.meta_description': `Meta Description`,
	'ui.contentData.meta_googlebot': `Meta Googlebot`,
	'ui.contentData.meta_robots': `Meta robots`,
	'ui.contentData.meta_slurp': `Meta Slurp`,
	'ui.contentData.meta_yandex': `Meta Yandex`,
	'ui.contentData.microsoftClarity': `Microsoft Clarity`,
	'ui.contentData.mobile_variant': `Mobile variant`,
	'ui.contentData.mouseflow': `Mouseflow`,
	'ui.contentData.notLoadedYet': `Not loaded yet.`,
	'ui.contentData.noValue.link': `Contact us`,
	'ui.contentData.noValue.text': `None detected. {link} if you believe this to be in error.`,
	'ui.contentData.open_graph_description': `Open Graph Description`,
	'ui.contentData.open_graph_image': `Open Graph Image`,
	'ui.contentData.open_graph_title': `Open Graph Title`,
	'ui.contentData.open_graph_type': `Open Graph Type`,
	'ui.contentData.open_graph_url': `Open Graph URL`,
	'ui.contentData.qualitativeAnalytics': `Visual Analytics`,
	'ui.contentData.quantitativeAnalytics': `Analytics`,
	'ui.contentData.segmentComTagManager': `Segment.com`,
	'ui.contentData.smartlook': `Smartlook`,
	'ui.contentData.tagManagement': `Tag manager`,
	'ui.contentData.title': `Title`,
	'ui.contentData.twitter_card': `Twitter Card`,
	'ui.contentData.twitter_description': `Twitter Description`,
	'ui.contentData.twitter_image': `Twitter Image`,
	'ui.contentData.twitter_site': `Twitter Site`,
	'ui.contentData.twitter_title': `Twitter Title`,
	'ui.contentData.type': `Page`,
	'ui.contentData.url': `URL`,
	'ui.contentData.x_robots_tag': `X-Robots-Tag Header`,
	'ui.contentOverview.adobeAnalytics.notConnected': `AA not connected`,
	'ui.contentOverview.allowed.no': `Disallowed`,
	'ui.contentOverview.allowed.yes': `Allowed`,
	'ui.contentOverview.canonicalTypes.external': `Cross-domain`,
	'ui.contentOverview.canonicalTypes.internal.otherPage': `Internal, other page`,
	'ui.contentOverview.canonicalTypes.internal.self': `Internal, self`,
	'ui.contentOverview.canonicalTypes.invalid': `Invalid`,
	'ui.contentOverview.category.adobeAnalytics': `Adobe Analytics`,
	'ui.contentOverview.category.content': `Content`,
	'ui.contentOverview.category.conversions': `Conversions`,
	'ui.contentOverview.category.customElements': `Custom Elements`,
	'ui.contentOverview.category.enrichmentFields': `Enrichment Fields`,
	'ui.contentOverview.category.fundamentals': `Fundamentals`,
	'ui.contentOverview.category.google_analytics': `Google Analytics`,
	'ui.contentOverview.category.google_search_console': `Google Search Console`,
	'ui.contentOverview.category.indexability': `Indexability`,
	'ui.contentOverview.category.lighthouse': `Lighthouse Web Vitals`,
	'ui.contentOverview.category.relations': `Relations`,
	'ui.contentOverview.category.schemaOrg': `Schema.org`,
	'ui.contentOverview.category.searchEngineActivity': `Search Engine Activity`,
	'ui.contentOverview.category.social': `Social`,
	'ui.contentOverview.changeTracking.changeType.added': `Added pages`,
	'ui.contentOverview.changeTracking.changeType.addedTo': `Added to`,
	'ui.contentOverview.changeTracking.changeType.changed': `Changed pages`,
	'ui.contentOverview.changeTracking.changeType.changesIn.inAllSelectedColumns': `in all selected columns`,
	'ui.contentOverview.changeTracking.changeType.changesIn.inAnyColumn': `in any column`,
	'ui.contentOverview.changeTracking.changeType.changesIn.inAnySelectedColumn': `in any selected column`,
	'ui.contentOverview.changeTracking.changeType.changesIn.inAnyVisibleColumn': `in any visible column`,
	'ui.contentOverview.changeTracking.changeType.other': `Other type changes`,
	'ui.contentOverview.changeTracking.changeType.redirected': `Redirected pages`,
	'ui.contentOverview.changeTracking.changeType.removed': `Removed pages`,
	'ui.contentOverview.changeTracking.changeType.removedFrom': `Removed from`,
	'ui.contentOverview.changeTracking.changeType.value.added': `added page`,
	'ui.contentOverview.changeTracking.changeType.value.changed': `changed`,
	'ui.contentOverview.changeTracking.changeType.value.other': `other`,
	'ui.contentOverview.changeTracking.changeType.value.redirected': `redirected`,
	'ui.contentOverview.changeTracking.changeType.value.removed': `removed page`,
	'ui.contentOverview.contextMenu.copyFullUrl': `Copy full URL of page`,
	'ui.contentOverview.contextMenu.openPageDetail': `Open details in new tab`,
	'ui.contentOverview.contextMenu.openPageOnWebsite': `Open page on website`,
	'ui.contentOverview.googleAnalytics.notConnected': `GA not connected`,
	'ui.contentOverview.googleSearchConsole.notConnected': `GSC not connected`,
	'ui.contentOverview.indexable.no': `Non-indexable`,
	'ui.contentOverview.indexable.yes': `Indexable`,
	'ui.contentOverview.pageTypes.error': `Server Error`,
	'ui.contentOverview.pageTypes.missing': `Missing`,
	'ui.contentOverview.pageTypes.page': `Page`,
	'ui.contentOverview.pageTypes.powerMenu.added': `Page added`,
	'ui.contentOverview.pageTypes.powerMenu.other': `Other`,
	'ui.contentOverview.pageTypes.powerMenu.redirected': `Page redirected`,
	'ui.contentOverview.pageTypes.powerMenu.removed': `Page removed`,
	'ui.contentOverview.pageTypes.redirect': `Redirect`,
	'ui.contentOverview.pageTypes.unknown': `Unknown`,
	'ui.contentOverview.pageTypes.unreachable': `Unreachable`,
	'ui.contentOverview.persistentTag': `long-term`,
	'ui.contentOverview.tableFilter.canonicalTypes.external': `Cross-domain`,
	'ui.contentOverview.tableFilter.canonicalTypes.internal': `Internal`,
	'ui.contentOverview.tableFilter.canonicalTypes.internal.otherPage': `Other page`,
	'ui.contentOverview.tableFilter.canonicalTypes.internal.self': `Self`,
	'ui.contentOverview.tableFilter.canonicalTypes.invalid': `Invalid`,
	'ui.contentOverview.tableFilter.canonicalTypes.none': `Missing`,
	'ui.contentOverview.tableFilter.canonicalTypes.notApplicable': `Not applicable`,
	'ui.contentOverview.tableFilter.no': `No`,
	'ui.contentOverview.tableFilter.placeholder': `filter`,
	'ui.contentOverview.tableFilter.placeholder.all': `all`,
	'ui.contentOverview.tableFilter.segment.createNewSegment': `Create new segment`,
	'ui.contentOverview.tableFilter.segment.update': `Update`,
	'ui.contentOverview.tableFilter.yes': `Yes`,
	'ui.contentOverview.tableHeading.aa_average_time_spent_on_site': `Average time spent on site`,
	'ui.contentOverview.tableHeading.aa_bounce_rate': `Bounce rate`,
	'ui.contentOverview.tableHeading.aa_page_views': `Page Views`,
	'ui.contentOverview.tableHeading.aa_revenue': `Revenue`,
	'ui.contentOverview.tableHeading.aa_unique_visitors': `Unique Visitors`,
	'ui.contentOverview.tableHeading.alertStatus': `Status`,
	'ui.contentOverview.tableHeading.analytics_services': `Analytics`,
	'ui.contentOverview.tableHeading.canonical_type': `Canonical Type`,
	'ui.contentOverview.tableHeading.canonical_url': `Canonical URL`,
	'ui.contentOverview.tableHeading.change_type': `Change Type`,
	'ui.contentOverview.tableHeading.difference': `Difference`,
	'ui.contentOverview.tableHeading.domain': `Domain`,
	'ui.contentOverview.tableHeading.foundAt': `Discovered`,
	'ui.contentoverview.tableheading.ga_active_users': `Active Users`,
	'ui.contentoverview.tableheading.ga_average_engagement_time': `User Engagement`,
	'ui.contentOverview.tableHeading.ga_average_time': `Avg. Time on Page`,
	'ui.contentOverview.tableHeading.ga_bounce_rate': `Bounce Rate`,
	'ui.contentOverview.tableHeading.ga_engagement_rate': `Engagement Rate`,
	'ui.contentOverview.tableHeading.ga_page_value': `Page Value`,
	'ui.contentOverview.tableHeading.ga_page_views': `Pageviews`,
	'ui.contentoverview.tableheading.ga_screen_page_views': `Screen Pageviews`,
	'ui.contentOverview.tableHeading.ga_unique_page_views': `Unique Pageviews`,
	'ui.contentOverview.tableHeading.gsc_clicks': `Clicks`,
	'ui.contentOverview.tableHeading.gsc_ctr': `CTR`,
	'ui.contentOverview.tableHeading.gsc_impressions': `Impressions`,
	'ui.contentOverview.tableHeading.gsc_position': `Position`,
	'ui.contentOverview.tableHeading.h1': `H1`,
	'ui.contentOverview.tableHeading.hreflang_changes': `Hreflang changes`,
	'ui.contentOverview.tableHeading.hreflang_language': `Hreflang`,
	'ui.contentOverview.tableHeading.is_disallowed_in_robots_txt': `Robots.txt`,
	'ui.contentOverview.tableHeading.is_in_sitemap': `In XML sitemap`,
	'ui.contentOverview.tableHeading.is_indexable': `Indexable`,
	'ui.contentOverview.tableHeading.is_indexable_due_to_meta_robots': `Meta robots`,
	'ui.contentOverview.tableHeading.is_indexable_due_to_x_robots_tag': `X-Robots-Tag`,
	'ui.contentOverview.tableHeading.is_linked': `Linked`,
	'ui.contentOverview.tableHeading.is_secured': `HTTPS`,
	'ui.contentOverview.tableHeading.issueStatus': `Issue status`,
	'ui.contentOverview.tableHeading.lfa_desktop_frequency': `<i>Desktop</i> Visit frequency`,
	'ui.contentOverview.tableHeading.lfa_desktop_last_visit': `<i>Desktop</i> Last visited`,
	'ui.contentOverview.tableHeading.lfa_frequency': `Visit frequency`,
	'ui.contentOverview.tableHeading.lfa_last_visit': `Last visited`,
	'ui.contentOverview.tableHeading.lfa_mobile_frequency': `<i>Mobile</i> Visit frequency`,
	'ui.contentOverview.tableHeading.lfa_mobile_last_visit': `<i>Mobile</i> Last visited`,
	'ui.contentOverview.tableHeading.lighthouse_cumulative_layout_shift': `Cumulative Layout Shift`,
	'ui.contentOverview.tableHeading.lighthouse_first_contentful_paint': `First Contentful Paint`,
	'ui.contentOverview.tableHeading.lighthouse_largest_contentful_paint': `Largest Contentful Paint`,
	'ui.contentOverview.tableHeading.lighthouse_performance': `Performance`,
	'ui.contentOverview.tableHeading.lighthouse_speed_index': `Speed Index`,
	'ui.contentOverview.tableHeading.lighthouse_time_to_interactive': `Time to Interactive`,
	'ui.contentOverview.tableHeading.lighthouse_total_blocking_time': `Total Blocking Time`,
	'ui.contentOverview.tableHeading.link_amp': `AMP variant`,
	'ui.contentOverview.tableHeading.link_next': `Next`,
	'ui.contentOverview.tableHeading.link_prev': `Prev`,
	'ui.contentOverview.tableHeading.meta_description': `Meta Description`,
	'ui.contentOverview.tableHeading.mobile_variant': `Mobile variant`,
	'ui.contentOverview.tableHeading.number_of_hreflangs': `Hreflang variants`,
	'ui.contentOverview.tableHeading.number_of_incoming_internal_canonicals': `Incoming canonical links`,
	'ui.contentOverview.tableHeading.number_of_incoming_internal_links': `Incoming internal links`,
	'ui.contentOverview.tableHeading.number_of_incoming_internal_redirects': `Incoming redirects`,
	'ui.contentOverview.tableHeading.number_of_outgoing_external_links': `Outgoing external links`,
	'ui.contentOverview.tableHeading.number_of_outgoing_internal_links': `Outgoing internal links`,
	'ui.contentOverview.tableHeading.open_graph_description': `Open Graph Description`,
	'ui.contentOverview.tableHeading.open_graph_image': `Open Graph Image`,
	'ui.contentOverview.tableHeading.open_graph_title': `Open Graph Title`,
	'ui.contentOverview.tableHeading.open_graph_type': `Open Graph Type`,
	'ui.contentOverview.tableHeading.open_graph_url': `Open Graph URL`,
	'ui.contentOverview.tableHeading.redirect': `Redirect target`,
	'ui.contentOverview.tableHeading.relevance': `Importance`,
	'ui.contentOverview.tableHeading.relevance.tooltip': `from <big>{value, number}</big>`,
	'ui.contentOverview.tableHeading.schemaOrgNumberOfTypes': `Schema.org items`,
	'ui.contentOverview.tableHeading.schemaOrgTypes': `Schema.org types`,
	'ui.contentOverview.tableHeading.score': `Health`,
	'ui.contentOverview.tableHeading.score.tooltip': `up to <big>{value, number}</big>`,
	'ui.contentOverview.tableHeading.segments': `Segments`,
	'ui.contentOverview.tableHeading.status_code': `Status`,
	'ui.contentOverview.tableHeading.tag_managers': `Tag Manager`,
	'ui.contentOverview.tableHeading.timeServerResponse': `Download Time`,
	'ui.contentOverview.tableHeading.title': `Title`,
	'ui.contentOverview.tableHeading.twitter_card': `Twitter Card`,
	'ui.contentOverview.tableHeading.twitter_description': `Twitter Description`,
	'ui.contentOverview.tableHeading.twitter_image': `Twitter Image`,
	'ui.contentOverview.tableHeading.twitter_site': `Twitter Site`,
	'ui.contentOverview.tableHeading.twitter_title': `Twitter Title`,
	'ui.contentOverview.tableHeading.type': `Type`,
	'ui.contentOverview.tableHeading.url': `URL`,
	'ui.contentOverview.tableHeading.url_depth': `URL Depth`,
	'ui.contentOverview.tableHeading.url_full': `Full URL`,
	'ui.contentOverview.tableHeading.visual_analytics_services': `Visual Analytics`,
	'ui.contentOverview.tableHeading.xml_sitemap_status': `Status`,
	'ui.contentOverview.title': `Pages`,
	'ui.contentOverview.views.graphs': `Graphs`,
	'ui.contentOverview.views.table': `Table`,
	'ui.contentOverview.webVitals.noData': `not calculated yet`,
	'ui.cookiesSetup.addButton': `Add cookie`,
	'ui.cookiesSetup.columns.name': `Cookie`,
	'ui.cookiesSetup.columns.value': `Value`,
	'ui.createEnrichmentFieldModal.modalSidebar': `
		<p><i>Name</i> determines how this enrichment field will be shown in Conductor Website Monitoring.</p>
		<p><i>Field Type</i> is the type of data that this enrichment field will be populated with.</p>
		<p>See how to populate this enrichment field in <linkSupport>our support article</linkSupport>.</p>
	`,
	'ui.createEnrichmentFieldModal.modalTitle': `Create enrichment field`,
	'ui.createEnrichmentFieldModal.setupStepButton': `Create enrichment field`,
	'ui.createEnrichmentFieldModal.setupStepDataTypeLabel': `Field type`,
	'ui.createEnrichmentFieldModal.setupStepDescription': `Configure the name of the enrichment field, as well as its type.`,
	'ui.createEnrichmentFieldModal.setupStepNameLabel': `Name`,
	'ui.createEnrichmentFieldModal.setupStepNamePlaceholder': `e.g. Owner, Revenue, Date, …`,
	'ui.createEnrichmentFieldModal.successStepButton': `OK`,
	'ui.createEnrichmentFieldModal.successStepDescription': `Congratulations! You have successfully created the enrichment field with the following ID:`,
	'ui.createEnrichmentFieldModal.successStepHeading': `Enrichment field was successfully created`,
	'ui.createEnrichmentFieldModal.successStepSupport': `See how to populate this enrichment field in <linkSupport>our support article</linkSupport>.`,
	'ui.createLogFileAnalysisS3BucketForm.accessKeyId': `Access Key ID`,
	'ui.createLogFileAnalysisS3BucketForm.bucket': `{provider, select,
		CloudflareLogpush {Bucket path}
		other {Bucket}
	}`,
	'ui.createLogFileAnalysisS3BucketForm.chooseRegion': `Choose region`,
	'ui.createLogFileAnalysisS3BucketForm.createBucket': `Create bucket`,
	'ui.createLogFileAnalysisS3BucketForm.domain': `Domain`,
	'ui.createLogFileAnalysisS3BucketForm.region': `{provider, select,
		CloudflareLogpush {Bucket region}
		other {Region}
	}`,
	'ui.createLogFileAnalysisS3BucketForm.region.euCentral1': `eu-central-1`,
	'ui.createLogFileAnalysisS3BucketForm.region.usEast2': `us-east-2`,
	'ui.createLogFileAnalysisS3BucketForm.secretAccessKey': `Secret Access Key`,
	'ui.creditCard.cardHolder': `Card holder`,
	'ui.creditCard.cardNumber': `Card number`,
	'ui.creditCard.currency': `Currency`,
	'ui.creditCard.cvc': `CVC`,
	'ui.creditCard.expiryAndCvc': `Expiry and CVC`,
	'ui.creditCard.firstNamePlaceholder': `First name`,
	'ui.creditCard.lastNamePlaceholder': `Last name`,
	'ui.creditCard.monthShortcut': `MM`,
	'ui.creditCard.name': `Name`,
	'ui.creditCard.yearShortcut': `YY`,
	'ui.customElements.createModal.title.mainPart': `Custom element`,
	'ui.customElements.createModal.title.prefix': `Create`,
	'ui.customElements.deleteModal.segmentsWarning': `You have {numberOfAffectedSegments, plural,
		=1 {# segment}
		other {# segments}
	} based on this element. If you delete this element, it will be removed from {numberOfAffectedSegments, plural,
		=1 {the segment definition}
		other {all segment definitions}
	}.`,
	'ui.customElements.deleteModal.title.mainPart': `Custom element`,
	'ui.customElements.deleteModal.title.prefix': `Delete`,
	'ui.customElements.editModal.title.mainPart': `Custom element`,
	'ui.customElements.editModal.title.prefix': `Settings`,
	'ui.customElements.elementToExtract.date.description': `This is the format of the date on the page, not the format in which we will display the date.`,
	'ui.customElements.elementToExtract.date.explanation': `Conductor Website Monitoring recognizes many date format variants, including long-form (<code>September</code>) and short-form (<code>Sep</code>) months, ordinal numbers (<code>24th</code>) and different separators like <code>.</code> and <code>-</code>.`,
	'ui.customElements.elementToExtract.element': `Element to extract is a`,
	'ui.customElements.elementToExtract.elementFormat': `in format`,
	'ui.customElements.elementToExtract.number.description': `This is the format of the number on the page, not the format in which we will display the number.`,
	'ui.customElements.elementTypes.boolean': `boolean`,
	'ui.customElements.elementTypes.date': `date`,
	'ui.customElements.elementTypes.number': `number`,
	'ui.customElements.elementTypes.string': `string`,
	'ui.customElements.exampleColumn1': `Author`,
	'ui.customElements.exampleColumn2': `Publish date`,
	'ui.customElements.exampleColumn3': `Last updated`,
	'ui.customElements.examples': `Examples: {content}`,
	'ui.customElements.extractElementFrom.label': `Extract element from`,
	'ui.customElements.extractElementFrom.options.dom': `rendered DOM`,
	'ui.customElements.extractElementFrom.options.src': `response body`,
	'ui.customElements.extractionCleanup.condenseWhitespace.description': `Removes leading and trailing whitespace and condenses sequences of whitespace.`,
	'ui.customElements.extractionCleanup.condenseWhitespace.label': `Remove excessive whitespace`,
	'ui.customElements.extractionCleanup.decodeHtmlEntities.description': `HTML entities will be replaced by the corresponding characters, e.g. <code>&amp;amp;</code> will be replaced by <code>&amp;</code>.`,
	'ui.customElements.extractionCleanup.decodeHtmlEntities.label': `Replace HTML entities`,
	'ui.customElements.extractionCleanup.dropEmpty.description': `Extracted values not containing any character will be considered not found.`,
	'ui.customElements.extractionCleanup.dropEmpty.label': `Drop empty values`,
	'ui.customElements.extractionCleanup.stripTags.description': `All HTML markup will be stripped away, but their text content will be kept.`,
	'ui.customElements.extractionCleanup.stripTags.label': `Strip away HTML markup`,
	'ui.customElements.extractionMethods.types.css': `CSS selector`,
	'ui.customElements.extractionMethods.types.regexp': `Regular expression`,
	'ui.customElements.extractionMethods.types.xpath': `XPath`,
	'ui.customElements.extractionSteps.actions.addAnotherStep': `Add another step`,
	'ui.customElements.extractionSteps.actions.deleteStep.button': `Remove step`,
	'ui.customElements.extractionSteps.header.expression.label': `Expression`,
	'ui.customElements.extractionSteps.header.expression.placeholder': `Enter expression`,
	'ui.customElements.extractionSteps.header.method': `Method`,
	'ui.customElements.extractionSteps.header.occurrence': `Occurrence`,
	'ui.customElements.extractionSteps.header.step': `Step`,
	'ui.customElements.extractionSteps.tooMany': `Maximum number of extraction steps is {number, number}. Delete one of the steps above to add a new one.`,
	'ui.customElements.form.fields.name.label': `Element name`,
	'ui.customElements.form.fields.name.placeholder': `e.g. Author, Date, Price, …`,
	'ui.customElements.form.fieldsets.extractionSteps.caption': `Extraction steps`,
	'ui.customElements.form.fieldsets.transformationSteps.addAnotherStepButton': `Add transformation`,
	'ui.customElements.form.fieldsets.transformationSteps.caption': `Transformation steps`,
	'ui.customElements.form.saveButton': `Save element`,
	'ui.customElements.formErrors.alreadyUsedLabel': `A custom element with this name already exists on this website. Please choose a different name.`,
	'ui.customElements.formErrors.invalidCss': `This is not a valid CSS selector.`,
	'ui.customElements.formErrors.invalidRegexp': `This is not a valid regular expression.`,
	'ui.customElements.formErrors.invalidXPath': `This is not a valid XPath expression.`,
	'ui.customElements.formErrors.notRegexpAfterRegexp': `All steps after a regular expression need to use regular expression as well.`,
	'ui.customElements.formErrors.stringTransformationIncompatibleWithElement': `This transformation is only available if the extracted element is a string.`,
	'ui.customElements.formErrors.stringTransformationIncompatibleWithPreviousTransformation': `This transformation is only available if the previous transformation step outputs a string.`,
	'ui.customElements.notExtractedYetValue': `not extracted yet`,
	'ui.customElements.notFoundValue': `not found`,
	'ui.customElements.occurrences.all': `All`,
	'ui.customElements.occurrences.first': `First`,
	'ui.customElements.occurrences.last': `Last`,
	'ui.customElements.offerSetupService': `Want some help setting up your Custom Element Extraction? <link_survey>Fill out this form</link_survey> and we'll set it up for you!`,
	'ui.customElements.optionalRestartTracking.checkboxLabel': `Yes, delete data tracked up to this point.`,
	'ui.customElements.optionalRestartTracking.question': `Do you want to delete the existing data of this custom element?`,
	'ui.customElements.overview.addButton.label': `Create custom element`,
	'ui.customElements.overview.addButton.limitReachedHint': `Maximum number of custom elements has been reached on this website.`,
	'ui.customElements.overview.blankSlate': `You don't have any custom elements configured yet.`,
	'ui.customElements.overview.table.deletionDisabledDueToAlertDefinitions': `Please delete all alert definitions that are based on this custom element before deleting it. You can always <showIntercom>contact us</showIntercom> if you need any help.`,
	'ui.customElements.overview.table.deletionDisabledDueToSegments': `You have {segments, plural,
		=1 {# segment}
		other {# segments}
	} based on this custom element. Deleting this custom element would result in multiple identical segment definitions. Please remove this custom element from {segments, plural,
		=1 {the segment definition}
		other {segment definitions}
	} before deleting it. You can always <showIntercom>contact us</showIntercom> if you need any help.`,
	'ui.customElements.overview.table.header.name': `Name`,
	'ui.customElements.overview.table.header.type': `Element type`,
	'ui.customElements.overview.title': `Custom elements`,
	'ui.customElements.summary.isBoolean': `The element type of this extraction is <i>a boolean</i>.`,
	'ui.customElements.summary.isDate': `The element type of this extraction is <i>a date</i>.`,
	'ui.customElements.summary.isNumber': `The element type of this extraction is <i>a number</i>.`,
	'ui.customElements.summary.isString': `The element type of this extraction is <i>a string</i>.`,
	'ui.customElements.summary.maximumLength': `Maximum length will be 1920 characters.`,
	'ui.customElements.summary.willBeJustOne': `Only <i>the first</i> occurrence will be stored.`,
	'ui.customElements.tester.fields.url.description': `This URL needs to be on {text__domain}.`,
	'ui.customElements.tester.fields.url.label': `Page URL`,
	'ui.customElements.tester.fields.url.placeholder': `/some-url`,
	'ui.customElements.tester.formErrors.invalidContent.description': `Custom element extraction only works on pages.`,
	'ui.customElements.tester.formErrors.invalidContent.title': `The URL does not return HTML.`,
	'ui.customElements.tester.formErrors.invalidDomain.description': WIP(`Please try again with a different URL.`),
	'ui.customElements.tester.formErrors.invalidDomain.title': WIP(`This URL isn't on website's domain.`),
	'ui.customElements.tester.formErrors.invalidState.description': WIP(`Please try again later.`),
	'ui.customElements.tester.formErrors.invalidState.title': WIP(`Initial website analysis isn't finished yet.`),
	'ui.customElements.tester.formErrors.invalidUrl.description': WIP(`Please try again with a different URL.`),
	'ui.customElements.tester.formErrors.invalidUrl.title': WIP(`The URL is invalid.`),
	'ui.customElements.tester.formErrors.notFound': `not found on this URL`,
	'ui.customElements.tester.formErrors.requestFailedDueToNoResponse.description': `We are currently having trouble checking this page ({reason}). `,
	'ui.customElements.tester.formErrors.requestFailedDueToNoResponse.title': `The URL is unreachable.`,
	'ui.customElements.tester.formErrors.requestFailedDueToStatusCode.description': `Custom element extraction only works on pages (2xx).`,
	'ui.customElements.tester.formErrors.requestFailedDueToStatusCode.title': `The URL returns {statusCode}.`,
	'ui.customElements.tester.output.elementType': `Element type:`,
	'ui.customElements.tester.output.value': `Value:`,
	'ui.customElements.tester.submitButton.label': `Run test`,
	'ui.customElements.tester.text': `Here you can test what will be extracted from a specific page.`,
	'ui.customElements.tester.title': `Element tester`,
	'ui.customElements.tester.url': `URL: `,
	'ui.customElements.transformations.append.description': `Add a string after the element.`,
	'ui.customElements.transformations.append.label': `Append`,
	'ui.customElements.transformations.applyPattern.description': `Apply regular expression to the extracted element.`,
	'ui.customElements.transformations.applyPattern.label': `Regular expression`,
	'ui.customElements.transformations.castToBoolean.description': `Evaluate expression to get Yes/No result.`,
	'ui.customElements.transformations.castToBoolean.label': `Turn into Yes/No`,
	'ui.customElements.transformations.characterLength.description': `Get the number of characters of the extracted element.`,
	'ui.customElements.transformations.characterLength.label': `Measure length of string`,
	'ui.customElements.transformations.checksum.description': `Uniquely concatenate all elements and compute an MD5 hash.`,
	'ui.customElements.transformations.checksum.label': `Compute MD5 hash`,
	'ui.customElements.transformations.concatenate.description': `Join extracted elements into one string.`,
	'ui.customElements.transformations.concatenate.label': `Concatenate`,
	'ui.customElements.transformations.extractDate.description': `Extract date matching specified format from string.`,
	'ui.customElements.transformations.extractDate.label': `Extract date`,
	'ui.customElements.transformations.extractFromJson.description': `Extract data from JSON using JMESPath expression.`,
	'ui.customElements.transformations.extractFromJson.label': `Extract from JSON`,
	'ui.customElements.transformations.extractNumber.description': `Extract number matching specified format from string.`,
	'ui.customElements.transformations.extractNumber.label': `Extract number`,
	'ui.customElements.transformations.numberOfElements.description': `Get number of extracted elements.`,
	'ui.customElements.transformations.numberOfElements.label': `Count number of elements`,
	'ui.customElements.transformations.prepend.description': `Add a string before the element.`,
	'ui.customElements.transformations.prepend.label': `Prepend`,
	'ui.customElements.transformations.replace.description': `Replace (part of) string by another string.`,
	'ui.customElements.transformations.replace.label': `Replace`,
	'ui.customElements.transformations.stripTags.description': `All HTML markup will be stripped away, but their text content will be kept.`,
	'ui.customElements.transformations.stripTags.label': `Strip away HTML markup`,
	'ui.customElements.transformations.truncate.description': `Limit the string length to the specified number of characters.`,
	'ui.customElements.transformations.truncate.label': `Truncate`,
	'ui.customElements.transformations.whitelistHtmlAttributes.description': `Remove all attributes except for whitelisted ones.`,
	'ui.customElements.transformations.whitelistHtmlAttributes.label': `Strip away HTML attributes`,
	'ui.customElements.transformationSteps.actions.deleteStep.button': WIP(`Remove step`),
	'ui.customElements.transformationSteps.header.parameters': `Parameters`,
	'ui.customElements.transformationSteps.header.step': `Step`,
	'ui.customElements.transformationSteps.header.type': `Transformation`,
	'ui.customElements.transformationSteps.parameterPlaceholder': `Enter`,
	'ui.customElements.transformationSteps.parameters.append.label': `String to add after the element`,
	'ui.customElements.transformationSteps.parameters.concatenate.label': `Separator (optional)`,
	'ui.customElements.transformationSteps.parameters.concatenate.placeholder': `For example: ,`,
	'ui.customElements.transformationSteps.parameters.expression.label': `Expression`,
	'ui.customElements.transformationSteps.parameters.extractedType.label': `Extracted type`,
	'ui.customElements.transformationSteps.parameters.pattern.label': `Format`,
	'ui.customElements.transformationSteps.parameters.prepend.label': `String to add before the element`,
	'ui.customElements.transformationSteps.parameters.replace.inputPattern.label': `Input pattern`,
	'ui.customElements.transformationSteps.parameters.replace.outputPattern.label': `Output pattern`,
	'ui.customElements.transformationSteps.parameters.replace.replaceWith.label': `Replace with`,
	'ui.customElements.transformationSteps.parameters.replace.searchFor.label': `Search for`,
	'ui.customElements.transformationSteps.parameters.smartPattern.label': `Format`,
	'ui.customElements.transformationSteps.parameters.truncate.label': `Maximum string length`,
	'ui.customElements.transformationSteps.parameters.whitelistHtmlAttributes.label': `Attribute whitelist`,
	'ui.customElements.transformationSteps.tooMany': `Maximum number of transformations is {number, number}. Delete one of the transformations above to add a new one.`,
	'ui.customElements.warning.items.elementTypeChanged': `As the element type of this custom element changes from {text__oldElementType, select,
		boolean {boolean}
		date {date}
		integer {integer}
		number {number}
		string {string}
		other {}
	} to {text__newElementType, select,
		boolean {boolean}
		date {date}
		integer {integer}
		number {number}
		string {string}
		other {}
	}, the data tracked up to this point will be deleted.`,
	'ui.customElements.warning.items.segmentsMayBeImpacted': `You have {numberOfAffectedSegments, plural,
		=1 {# segment}
		other {# segments}
	} based on this element. The changes you're making here may impact the segment contents and connected alert definitions and issue ignoring.`,
	'ui.customElements.warning.items.segmentsWillBeUpdated': `You have {numberOfAffectedSegments, plural,
		=1 {# segment}
		other {# segments}
	} based on this element. As the element type changes, this element will be removed from the segment {numberOfAffectedSegments, plural,
		=1 {definition}
		other {definitions}
	}.`,
	'ui.customerTypes.agency': `Agency or freelancer`,
	'ui.customerTypes.ecommerce': `eCommerce or comparison site`,
	'ui.customerTypes.marketplace': `Marketplace or classifieds`,
	'ui.customerTypes.other': `Other`,
	'ui.customerTypes.publisher': `Publisher`,
	'ui.customerTypes.travel': `Travel`,
	'ui.customHttpHeadersSetup.addButton': `Add HTTP header`,
	'ui.customHttpHeadersSetup.columns.name': `HTTP header`,
	'ui.customHttpHeadersSetup.columns.value': `Value`,
	'ui.customHttpHeadersSetup.errors.authorization': `Authentication on this website is active. You need to remove the authentication in the Advanced settings section before you can add this header.`,
	'ui.customHttpHeadersSetup.errors.blacklisted': `This HTTP header is not supported. Please contact us and we will set it up for you.`,
	'ui.customHttpHeadersSetup.errors.userAgent': `You can specify the user agent string in the Settings section.`,
	'ui.dashboard.alertsTable.blank.message': `There were no alerts recently, but there are still some {link} on the website.`,
	'ui.dashboard.alertsTable.blank.message.link': `issues`,
	'ui.dashboard.alertsTable.header.title': `Recent alerts`,
	'ui.dashboard.kpi.graphs.notEnoughData.message': `Graphs will be accessible once we collect enough data about the website.`,
	'ui.dashboard.kpi.health.paused.message': `This chart is not available because monitoring is paused.`,
	'ui.dashboard.kpi.pages.title': `Pages`,
	'ui.dashboard.noAccountWebsite.message': `There are no websites in this account.`,
	'ui.dashboard.noChanges.message': `There were no changes in this date range.`,
	'ui.dashboard.noData.dataGotTrackedLater': `Conductor Website Monitoring didn't store this data in the selected date range.`,
	'ui.dashboard.noData.segmentCannotContainPages': `This segment is configured to contain non-pages only.`,
	'ui.dashboard.noData.segmentContainsNonPagesOnly.historical': `At the end of the date range the segment contained non-pages only.`,
	'ui.dashboard.noData.segmentContainsNonPagesOnly.live': `This segment contains non-pages only.`,
	'ui.dashboard.noData.segmentDoesNotExist': `Segment doesn't exist anymore.`,
	'ui.dashboard.noData.segmentIsEmpty.historical': `At the end of the selected date range this segment was empty.`,
	'ui.dashboard.noData.segmentIsEmpty.live': `This segment is currently empty.`,
	'ui.dashboard.noData.segmentWasAddedLater': `Segment was created after selected date range.`,
	'ui.dashboard.noData.websiteContainsNonPagesOnly.historical': `At the end of the date range the website contained non-pages only.`,
	'ui.dashboard.noData.websiteContainsNonPagesOnly.live': `Website contains non-pages only.`,
	'ui.dashboard.noData.websiteIsEmpty.historical': `At the end of the selected date range the website was empty.`,
	'ui.dashboard.noData.websiteIsEmpty.live': `Website is currently empty.`,
	'ui.dashboard.noData.websiteNotFound': `Website not found`,
	'ui.dashboard.noData.websiteWasAddedLater': `Website was added after selected date range.`,
	'ui.dashboard.noData.webVitalsOriginSummaryDataNotDownloadedYet': `Data not available yet.`,
	'ui.dashboard.noData.webVitalsOriginSummaryNotFound': `Data not available in the Chrome User Experience Report.`,
	'ui.dashboard.noScopes.message': `You have no scopes selected for this website`,
	'ui.dashboard.noScopes.select': `Try to <linkSelect>select</linkSelect> some segments`,
	'ui.dashboard.title': `Dashboard`,
	'ui.dashboard.tour.closeTourButton': `Close tour`,
	'ui.dashboard.tour.continueButton': `Continue`,
	'ui.dashboard.tour.step1': `This window shows you the current number of <b>pages</b> on your website, as well as how that number has changed over time.`,
	'ui.dashboard.tour.step1.inDiscovery': `We are now going through the whole website and discovering all its <b>pages</b>. You can follow the progress below.`,
	'ui.dashboard.tour.step2': `We thoroughly test every page for SEO issues. We then use this information to calculate <b>the health of the website</b>, as shown below.`,
	'ui.dashboard.tour.step3': `This element displays which website you are working on, as well as its health. When you add more websites, you’ll also use this element to switch between them.`,
	'ui.dashboard.tour.step4': `This is an overview of website’s <b>segments</b> - fully customizable bundles of pages that match specific criteria. You can create as many as you want. Here are a couple to get you started.`,
	'ui.dashboard.tour.step5': `Here’s a summary of some of the information we are tracking about your website. Apart from health and page totals, you can also see the <b>number of issues</b> the website has.`,
	'ui.dashboard.tour.step6': `In this section you can <b>set up your profile</b>.`,
	'ui.dashboard.tour.step6.manager': `In this section you can <b>add websites and team members and also set up your profile</b>.`,
	'ui.dashboard.tour.step7': `Go ahead and look around. If you have any questions just click on the support button in the bottom right corner.`,
	'ui.dashboard.tour.step7.button': `Let's optimize!`,
	'ui.dashboard.tour.step7.title': `That's all!`,
	'ui.dashboardCoreWebVitalsWidget.primaryActionLink': `View platform`,
	'ui.dashboardCoreWebVitalsWidget.subtitle': `Origin Summary`,
	'ui.dashboardCoreWebVitalsWidget.title': `Core Web Vitals`,
	'ui.dashboardCoreWebVitalsWidget.verdict.cannotBeAssessed': `Cannot be assessed`,
	'ui.dashboardCoreWebVitalsWidget.verdict.fail': `Does not pass`,
	'ui.dashboardCoreWebVitalsWidget.verdict.pass': `Passes`,
	'ui.dashboardCumulativeLayoutShiftWidget.primaryActionLink': `View platform`,
	'ui.dashboardCumulativeLayoutShiftWidget.subtitle': `Origin Summary`,
	'ui.dashboardCumulativeLayoutShiftWidget.title': `Core Web Vital: CLS`,
	'ui.dashboardDistributionOfIsIndexableWidget.primaryActionLink': `view all graphs`,
	'ui.dashboardDistributionOfIsIndexableWidget.title': `Indexable`,
	'ui.dashboardDistributionOfPageTypeWidget.primaryActionLink': `view all graphs`,
	'ui.dashboardDistributionOfPageTypeWidget.title': `Type`,
	'ui.dashboardFirstInputDelayWidget.primaryActionLink': `View platform`,
	'ui.dashboardFirstInputDelayWidget.subtitle': `Origin Summary`,
	'ui.dashboardFirstInputDelayWidget.title': `Core Web Vital: FID`,
	'ui.dashboardHealthChangesWidget.primaryActionLink': `view Issues changes`,
	'ui.dashboardHealthChangesWidget.title': `Health Changes`,
	'ui.dashboardHealthChangesWidget.tooltip': `Health: {value, number}`,
	'ui.dashboardHealthWidget.primaryActionLink.changes': `view Issues changes`,
	'ui.dashboardHealthWidget.primaryActionLink.current': `view Issues`,
	'ui.dashboardHealthWidget.title': `Health`,
	'ui.dashboardIssueChangesWidget.primaryActionLink': `view Issues changes`,
	'ui.dashboardIssueChangesWidget.title': `Issues Changes`,
	'ui.dashboardLargestContentfulPaintWidget.primaryActionLink': `View platform`,
	'ui.dashboardLargestContentfulPaintWidget.subtitle': `Origin Summary`,
	'ui.dashboardLargestContentfulPaintWidget.title': `Core Web Vital: LCP`,
	'ui.dashboardNumberOfActiveIncidentsWidget.primaryActionLink': `view Alerts`,
	'ui.dashboardNumberOfActiveIncidentsWidget.title': `Active Incidents`,
	'ui.dashboardNumberOfActiveIncidentsWidget.titleShort': `Incidents`,
	'ui.dashboardNumberOfIssuesWidget.primaryActionLink.changes': `view Issues changes`,
	'ui.dashboardNumberOfIssuesWidget.primaryActionLink.current': `view Issues`,
	'ui.dashboardNumberOfIssuesWidget.title': `Issues`,
	'ui.dashboardNumberOfPagesWidget.primaryActionLink.changes': `view Page changes`,
	'ui.dashboardNumberOfPagesWidget.primaryActionLink.current': `view Pages`,
	'ui.dashboardNumberOfPagesWidget.title': `Pages`,
	'ui.dashboardPageChangesWidget.primaryActionLink': `view Page changes`,
	'ui.dashboardPageChangesWidget.title': `Page Changes`,
	'ui.dataProcessingAgreementModal.accept': `Accept`,
	'ui.dataProcessingAgreementModal.alreadyAccepted': `
		<p>Your Conductor Website Monitoring account has already accepted our Data Processing Agreement.</p>
		<p>You can always read it <linkDocument>here</linkDocument>.</p>
	`,
	'ui.dataProcessingAgreementModal.cannotAccept': `
		<p>Unfortunately, you don't have sufficient permissions to accept our Data Processing Agreement.</p>
		<p>Please ask {userRole, select,
			manager {an admin}
			other {a manager}
		} in your Conductor Website Monitoring account to accept the DPA.</p>
	`,
	'ui.dataProcessingAgreementModal.description': `Important: there’s no DPA in place between Conductor and you, which is a requirement. Please read and accept the DPA below.`,
	'ui.dataProcessingAgreementModal.hasAccepted': `
		<p>Thank you for agreeing with our Data Processing Agreement.</p>
		<p>You can always read it <linkDocument>here</linkDocument>.</p>
	`,
	'ui.dataProcessingAgreementModal.title': `Data Processing Agreement`,
	'ui.dataTable.tooManyRows': `Only the first {limit, number} of total {total, number} rows can be displayed in your browser. Use the filters to get more specific results.`,
	'ui.datePeriod': `Date period: {since} to {until}`,
	'ui.dateRange.endDateLabel': `End date`,
	'ui.dateRange.filter.desktopSelectedLabel': `Displaying changes between`,
	'ui.dateRange.filter.mobile.displayingLabel': `Displaying changes`,
	'ui.dateRange.filter.mobile.hideButton': `Hide changes`,
	'ui.dateRange.filter.mobileLink': `Changes`,
	'ui.dateRange.filter.mobileSelectedLabel': `Changes between`,
	'ui.dateRange.filter.rangeEndFormatDesktop': `Do MMM YYYY`,
	'ui.dateRange.filter.rangeEndFormatMobile': `DD/MM/YYYY`,
	'ui.dateRange.filter.rangeStartFormatDesktop': `Do MMM`,
	'ui.dateRange.filter.rangeStartFormatMobile': `DD/MM`,
	'ui.dateRange.filter.resetButtonTitle': `Exit`,
	'ui.dateRange.filter.selectDatesLabel': `Change date range`,
	'ui.dateRange.filter.trackedChangesLabel': `Tracked Changes`,
	'ui.dateRange.includeVisibleColumns': `Pages with changes in visible columns only. Changes in Health and Importance are never included.`,
	'ui.dateRange.includeVisibleColumns.explanation': `This is because changes made to one page can affect Health and Importance for other pages too. Including those metrics would skew the results.`,
	'ui.dateRange.includeVisibleColumns.link': `Why?`,
	'ui.dateRange.rangeLabel': `Date Range`,
	'ui.dateRange.ranges.custom': `Custom`,
	'ui.dateRange.ranges.everything': `Everything`,
	'ui.dateRange.ranges.last2Weeks': `Last 2 weeks`,
	'ui.dateRange.ranges.last4Weeks': `Last 4 weeks`,
	'ui.dateRange.ranges.last7Days': `Last {count__days, number} days`,
	'ui.dateRange.ranges.last14Days': `Last 14 days`,
	'ui.dateRange.ranges.lastLogin': `Since last login`,
	'ui.dateRange.ranges.previousWeek': `Previous week`,
	'ui.dateRange.ranges.thisWeek': `This week`,
	'ui.dateRange.ranges.today': `Today`,
	'ui.dateRange.ranges.yesterday': `Yesterday`,
	'ui.dateRange.startDateLabel': `Start date`,
	'ui.dateRange.unavailable': `Conductor Website Monitoring hasn't been monitoring this website long enough.`,
	'ui.definitionTerm.showMoreLink': `+{count, number} more`,
	'ui.deleteAlertDefinitionConfirmation.confirmButton': `Delete {alertDefinitions, plural,
		one {definition}
		other {definitions}
	}`,
	'ui.deleteAlertDefinitionConfirmation.labelFromWebsite': `From website`,
	'ui.deleteAlertDefinitionConfirmation.labelRecipients': `Recipients`,
	'ui.deleteAlertDefinitionConfirmation.labelScope': `Scope`,
	'ui.deleteAlertDefinitionConfirmation.labelType': `Type`,
	'ui.deleteAlertDefinitionConfirmation.title': `{alertDefinitions, plural,
		one {alert definition}
		other {# alert definitions}
	}`,
	'ui.deleteAlertDefinitionConfirmation.verb': `Delete`,
	'ui.deleteAlertDefinitionConfirmation.warnPermanent': `This action will <b>permanently delete</b> {alertDefinitions, plural,
		one {the alert definition}
		other {these # alert definitions}
	}:`,
	'ui.deleteCustomElementConfirmation.cautionConnectedSegment': `You have {numberOfAffectedSegments, plural,
		one {# segment}
		other {# segments}
	} based on this custom element. If you delete this custom element, it will be removed from the segment definition, connected alert definitions and issue ignoring.`,
	'ui.deleteCustomElementConfirmation.confirmButton': `Delete custom element`,
	'ui.deleteCustomElementConfirmation.description': `Deleting a custom element will delete it and all its tracked data. You won't be able to filter on it, set alerts for its changes, or create segments based on it.`,
	'ui.deleteCustomElementConfirmation.labelElementType': `Element type`,
	'ui.deleteCustomElementConfirmation.labelFromWebsite': `From website`,
	'ui.deleteCustomElementConfirmation.labelName': `Name`,
	'ui.deleteCustomElementConfirmation.title': `custom element`,
	'ui.deleteCustomElementConfirmation.verb': `Delete`,
	'ui.deleteCustomElementConfirmation.warnPermanent': `This action will <b>permanently delete</b> the custom element:`,
	'ui.deleteEnrichmentFieldConfirmation.confirmButton': `Delete enrichment field`,
	'ui.deleteEnrichmentFieldConfirmation.description': `Deleting an enrichment field will delete it and all its tracked data. You won't be able to filter on it, set alerts for its changes, or create segments based on it.`,
	'ui.deleteEnrichmentFieldConfirmation.labelElementType': `Field type`,
	'ui.deleteEnrichmentFieldConfirmation.labelFromWebsite': `From website`,
	'ui.deleteEnrichmentFieldConfirmation.labelName': `Name`,
	'ui.deleteEnrichmentFieldConfirmation.title': `enrichment field`,
	'ui.deleteEnrichmentFieldConfirmation.verb': `Delete`,
	'ui.deleteEnrichmentFieldConfirmation.warnPermanent': `This action will <b>permanently delete</b> the enrichment field:`,
	'ui.deleteSegmentConfirmation.cautionConnectedAlertDefinitions': `
		You have {alertDefinitions, plural,
			one {# alert definition}
			other {# alert definitions}
		} based on this segment. Deleting this segment will delete the connected {alertDefinitions, plural,
			one {alert definition}
			other {alert definitions}
		}.
	`,
	'ui.deleteSegmentConfirmation.cautionConnectedIgnoredIssues': `
		You have {ignoredIssues, plural,
			one {# issue ignoring rule}
			other {# issue ignoring rules}
		} based on this segment. Deleting this segment will delete the connected {ignoredIssues, plural,
			one {issue ignoring rule}
			other {issue ignoring rules}
		}.
	`,
	'ui.deleteSegmentConfirmation.cautionConnections': `
		You have {alertDefinitions, plural,
			one {# alert definition}
			other {# alert definitions}
		}, and {ignoredIssues, plural,
			one {# issue ignoring rule}
			other {# issue ignoring rules}
		} based on this segment. Deleting this segment will delete the connected {alertDefinitions, plural,
			one {alert definition}
			other {alert definitions}
		} and {ignoredIssues, plural,
			one {issue ignoring rule}
			other {issue ignoring rules}
		}.
	`,
	'ui.deleteSegmentConfirmation.confirmButton': `Delete segment`,
	'ui.deleteSegmentConfirmation.description': `Deleting a segment will remove it from the entire website. You won't be able to filter on it, see its report on the Dashboard, or receive alerts about it.`,
	'ui.deleteSegmentConfirmation.labelFromWebsite': `From website`,
	'ui.deleteSegmentConfirmation.labelSegment': `Segment`,
	'ui.deleteSegmentConfirmation.title': `segment`,
	'ui.deleteSegmentConfirmation.verb': `Delete`,
	'ui.deleteSegmentConfirmation.warnPermanent': `This action will <b>permanently delete</b> the segment:`,
	'ui.destructConfirmationModal.confirmationPhraseLabel': `To proceed, type <b>{confirmationPhrase}</b> in the box below:`,
	'ui.destructConfirmationModal.confirmationRequired': `Confirmation required`,
	'ui.deviceType.desktop': `Desktop`,
	'ui.deviceType.mobile': `Mobile`,
	'ui.disableSubscriptionRenewal.button': `Stop subscription renewal`,
	'ui.disableSubscriptionRenewal.modal.button': `Stop subscription renewal`,
	'ui.disableSubscriptionRenewal.modal.checkboxOne': `This account's subscription will not be renewed`,
	'ui.disableSubscriptionRenewal.modal.checkboxTwo': `After {paidUntil, date, medium} I will lose access to all data in this account`,
	'ui.disableSubscriptionRenewal.modal.content': `This action will stop the automatic renewal of your Conductor Website Monitoring subscription. After your current subscription period ends, you will no longer be able to access this account.`,
	'ui.disableSubscriptionRenewal.modal.title': `Stop subscription renewal?`,
	'ui.distributionFilter.label': `{value}: {count, number} ({percentage, number, ::percent scale/0.01 .00})`,
	'ui.downgradeBox.content': `
		<p>The following features are not included in the plan you selected:</p>
		{lostFeatures}
		<p>To keep using these features, you'll need the {betterPlan} plan.</p>
	`,
	'ui.downgradeBox.features.alertsForCustomElements': `Alerts for Custom Elements`,
	'ui.downgradeBox.features.customElements': `Custom Element Extraction`,
	'ui.downgradeBox.features.customElementsMoreThan': `More than {numberOfElements, number} custom elements per website`,
	'ui.downgradeBox.features.enrichmentFields': `Data Enrichment API`,
	'ui.downgradeBox.features.enrichmentFieldsMoreThan': `More than {numberOfElements, number} enrichment fields per website`,
	'ui.downgradeBox.features.indexNow': `Real-time IndexNow`,
	'ui.downgradeBox.features.issuesConfiguration': `Issues Configuration`,
	'ui.downgradeBox.features.lighthouseMonitoring': `Core Web Vitals Lighthouse Monitoring`,
	'ui.downgradeBox.features.logFileAnalysis': `Log File Analysis`,
	'ui.downgradeBox.features.microsoftTeams': `Microsoft Teams integration`,
	'ui.downgradeBox.features.rendering': `Javascript Rendering`,
	'ui.downgradeBox.features.webVitalsOriginSummary': `Core Web Vitals Origin Summary`,
	'ui.downgradeBox.message': `Some of the features you are using are not available on the {text__pricingPlan} plan. Once you subscribe to this plan, these features will be disabled and reset to the default settings.`,
	'ui.downgradeBox.title': `You are using features not supported by this plan`,
	'ui.editInWordpressButton.label': `Edit page`,
	'ui.editInWordpressButton.unavailable.notEditable': `This page is not individually editable in WordPress.`,
	'ui.editInWordpressButton.unavailable.pluginNotActive': `The WordPress plugin got deactivated. Please reactivate it in WordPress.`,
	'ui.editInWordpressButton.unavailable.pluginNotInstalled': `The WordPress plugin is not installed. Please install it in WordPress.`,
	'ui.endUser.plan.essentials': `Essentials`,
	'ui.endUser.plan.pro': `Pro`,
	'ui.enrichmentApi.changeTokenHint': `Caution: you will need to update the configuration of current integrations with the Data Enrichment API when changing the token.`,
	'ui.enrichmentApi.changeTokenLink': `Change Data Enrichment API token`,
	'ui.enrichmentApi.getToken': `Get Data Enrichment API token`,
	'ui.enrichmentApi.hint': `The Data Enrichment API is used to push your custom page metrics and data to Conductor Website Monitoring. Read more about this in our support article.`,
	'ui.enrichmentApi.tokenLabel': `Data Enrichment API`,
	'ui.enrichmentFields.notProvided': `not provided`,
	'ui.enrichmentFields.noValue': `no value`,
	'ui.enrichmentFieldsOverview.addButtonLabel': `Create enrichment field`,
	'ui.enrichmentFieldsOverview.addButtonLimitReachedHint': `Maximum number of enrichment fields has been reached on this website.`,
	'ui.enrichmentFieldsOverview.blankSlate': `You don't have any enrichment fields configured yet.`,
	'ui.enrichmentFieldsOverview.cannotDeleteTooltip': `Please delete all alert definitions that are based on this enrichment field before deleting it. You can always <showIntercom>contact us</showIntercom> if you need any help.`,
	'ui.enrichmentFieldsOverview.dataTypeColumn': `Field type`,
	'ui.enrichmentFieldsOverview.idColumn': `ID`,
	'ui.enrichmentFieldsOverview.nameColumn': `Name`,
	'ui.enrichmentFieldsOverview.title': `Enrichment Fields`,
	'ui.enterSudoModeForm.password': `Password`,
	'ui.enterSudoModeForm.twoFactorHavingTrouble': `
		<p>Having trouble? Head over to <linkArticle>our support article</linkArticle>.</p>
	`,
	'ui.enterSudoModeForm.wrongPasswordError': `Incorrect password`,
	'ui.events.title': `Alerts`,
	'ui.expandableFilter.operator.contains': `Contains`,
	'ui.expandableFilter.operator.empty': `Present but empty`,
	'ui.expandableFilter.operator.empty_or_missing': `Not present or empty`,
	'ui.expandableFilter.operator.ends_with': `Ends with`,
	'ui.expandableFilter.operator.equals': `Exact match`,
	'ui.expandableFilter.operator.matches': `Matching RegExp`,
	'ui.expandableFilter.operator.missing': `Not present`,
	'ui.expandableFilter.operator.not_contains': `Not containing`,
	'ui.expandableFilter.operator.not_matches': `Not matching RegExp`,
	'ui.expandableFilter.operator.present': `Present`,
	'ui.expandableFilter.operator.starts_with': `Starts with`,
	'ui.expiredTrialModal.extendedStep.content': `
	<p>Thanks for the feedback. We appreciate it!</p>
	<p>As promised we've just added 7 extra days to your trial. Happy exploring!</p>
	`,
	'ui.expiredTrialModal.extendedStep.continue': `Let's continue`,
	'ui.expiredTrialModal.extendedStep.footnote': `<showIntercom>Contact us</showIntercom> if you have any questions`,
	'ui.expiredTrialModal.extendedStep.title': `Trial successfully extended`,
	'ui.expiredTrialModal.extendStep.cancelButton': `I'll just sign up now`,
	'ui.expiredTrialModal.extendStep.content': `
	<p>Please answer the question below and we'll gladly extend you trial by 7 days.</p>
	<p><b>What would help you decide to sign up for Conductor Website Monitoring?</b></p>
	`,
	'ui.expiredTrialModal.extendStep.feedbackPlaceholder': `Write your answer here`,
	'ui.expiredTrialModal.extendStep.footnote': `<showIntercom>Contact us</showIntercom> if you have any questions`,
	'ui.expiredTrialModal.extendStep.submitButton': `Extend my trial`,
	'ui.expiredTrialModal.extendStep.title': `Add 7 more days to your trial`,
	'ui.expiredTrialModal.signUpStep.contactUs': `Contact us`,
	'ui.expiredTrialModal.signUpStep.content': `
		<p>{hasName, select,
			yes {Hi {name},}
			other {Hi,}
		}</p>
		<p>Your trial has come to an end, but the good times don't have to!</p>
		<p>Sign up now to stay one step ahead of SEO disasters and keep your websites optimized.</p>
	`,
	'ui.expiredTrialModal.signUpStep.extendTrial': `Need more time? <linkExtendStep>Extend your trial</linkExtendStep>`,
	'ui.expiredTrialModal.signUpStep.footnote': `You can still <linkAccount>manage your account</linkAccount>.{br}<showIntercom>Contact us</showIntercom> if you have any questions.`,
	'ui.expiredTrialModal.signUpStep.signUp': `Sign up`,
	'ui.expiredTrialModal.signUpStep.title': `Sign up now to keep your websites optimized`,
	'ui.exports.type.general.button.download.label': `Download from browser`,
	'ui.exports.type.general.button.openDialog.label': `Export`,
	'ui.exports.type.general.button.submit.label': `Start export`,
	'ui.exports.type.general.button.submit.label.inProgress': `Close`,
	'ui.exports.type.general.content': `All data matching your current filter settings will be exported to CSV. Once it's ready you will receive an email to download your exported data.`,
	'ui.exports.type.general.content.inProgress': `Your export is being prepared. As soon as it's ready you will receive an email to download it.`,
	'ui.exports.type.general.content.inProgress.largeExport': `Your export is being prepared. As it's quite large this may take up to 15 minutes to complete. As soon as it's ready you will receive an email to download it.`,
	'ui.exports.type.general.title': `Export to CSV`,
	'ui.exports.type.general.title.inProgress': `Export in progress`,
	'ui.exportSnapshotDownloadButton.label': `Download`,
	'ui.featuresInUse.problem': `The following features are not included in the plan you selected:`,
	'ui.featuresInUse.prompt': `To keep using these features, you'll need the {plan} plan.`,
	'ui.featuresInUse.title': `You are using features not supported by this plan`,
	'ui.featuresInUseTooltip': `
		<p>You have features in use that are not part of this plan:</p>
		{lostFeatures}
		<p>Once you sign up for this plan, these features will be disabled and reset to the default settings.</p>
	`,
	'ui.fetchingLocations.theNetherlands': `The Netherlands`,
	'ui.fetchingLocations.unitedKingdom': `United Kingdom`,
	'ui.fetchingLocations.unitedStates': `United States`,
	'ui.fetchingLocationField.label': `Monitor from`,
	'ui.fetchingLocationField.account.hint': `Choose the default country from which Conductor Website Monitoring will monitor your websites. This will only affect websites you're newly adding, and won't change the setting for existing websites in your account. You can always change this for individual websites.`,
	'ui.fetchingLocationField.website.hint': `Choose from which country you want to have this website monitored.`,
	'ui.filteringToolbar.active.desktop.columnsAndFilters': `{count__filters, plural,
		=0 {# filters}
		=1 {# filter}
		other {# filters}
	}, {count__columns, plural,
		=0 {# columns}
		=1 {# column}
		other {# columns}
	}`,
	'ui.filteringToolbar.active.desktop.filters': `{count, plural,
		=0 {# filters active}
		=1 {# filter active}
		other {# filters active}
	}`,
	'ui.filteringToolbar.active.mobile': `{count, plural,
		=1 {Filter active}
		other {Filters active}
	}`,
	'ui.filteringToolbar.resetLink': `Clear`,
	'ui.filteringToolbar.title.columns': `Columns`,
	'ui.filteringToolbar.title.filters': `Filters`,
	'ui.filteringToolbar.xMore': `+{count} MORE`,
	'ui.flashMessages.alertDefinitionsCreated': `{number__alertDefinitions, plural,
		one {# alert}
		other {# alerts}
	} created`,
	'ui.flashMessages.alertDefinitionsDeleted': `{number__alertDefinitions, plural,
		one {# alert}
		other {# alerts}
	} deleted`,
	'ui.flashMessages.alertDefinitionsEdited': `{number__alertDefinitions, plural,
		one {# alert}
		other {# alerts}
	} edited`,
	'ui.formErrors.blank': `Field cannot be blank`,
	'ui.formErrors.cannotContainSemicolon': `HTTP cookie can't contain a semicolon character. If it's supposed to be part of the cookie, it must be encoded as <code>%3B</code>.`,
	'ui.formErrors.disallowedEmail': `Please enter your work email address.`,
	'ui.formErrors.domainNotWhitelisted.generic': `This domain is not allowed to be monitored.`,
	'ui.formErrors.domainNotWhitelisted.notSolvable': `This domain is not allowed to be monitored. Please contact your Admin for more information.`,
	'ui.formErrors.domainNotWhitelisted.solvable': `This domain is not allowed to be monitored. You can change the allowed domains <a>here</a>.`,
	'ui.formErrors.emailDomainNotWhitelisted.notSolvable': `This email domain is not allowed. Please enter an allowed email domain or contact your Admin.`,
	'ui.formErrors.emailDomainNotWhitelisted.solvable': `This email domain is not allowed. You can change the allowed email domains <a>here</a>.`,
	'ui.formErrors.emptyPassword': `Password must be entered`,
	'ui.formErrors.general': `Enter a valid value, please.`,
	'ui.formErrors.invalidBoolean': `Please select an option`,
	'ui.formErrors.invalidCreditCardNumber': `Enter a valid credit card number`,
	'ui.formErrors.invalidCVC': `Enter a valid CVC`,
	'ui.formErrors.invalidDomain': `You have to enter valid domain`,
	'ui.formErrors.invalidEmail': `This is not a valid email`,
	'ui.formErrors.invalidInput': `Due to a technical problem saving failed. Please try again.`,
	'ui.formErrors.invalidJson': `This is not a valid JSON`,
	'ui.formErrors.invalidRegexp': `This is not a valid regular expression.`,
	'ui.formErrors.invalidYearAndMonth': `Enter a valid date`,
	'ui.formErrors.maxChars': `{maxCharacters} characters or less, please.`,
	'ui.formErrors.maxValue': `Value must be less than or equal to {maxValue}`,
	'ui.formErrors.minChars': `at least {minCharacters} characters, please.`,
	'ui.formErrors.minValue': `Value must be greater than or equal to {minValue}`,
	'ui.formErrors.passwordMustContainDigit': `Password needs to contain at least one digit.`,
	'ui.formErrors.passwordMustContainLowerCaseLetter': `Password needs to contain at least one lower case letter.`,
	'ui.formErrors.passwordMustContainSpecialCharacter': `Password needs to contain at least one character which is not letter or digit.`,
	'ui.formErrors.passwordMustContainUpperCaseLetter': `Password needs to contain at least one upper case letter.`,
	'ui.formErrors.passwordsNotEqual': `Entered passwords don't match`,
	'ui.formErrors.passwordTooShort': `Password needs to be at least 8 characters.`,
	'ui.formErrors.trialAbuserEmail': `This email address was already used to create multiple trial accounts. Please use a different email address or <contactLink>contact us</contactLink> to learn more.`,
	'ui.forms.editingMode': `Editing`,
	'ui.forms.editLink': `Edit`,
	'ui.forms.iconPicker.clearIconButton.label': `Remove icon`,
	'ui.forms.insufficientPermissionsForKingdomAdminTooltip': `Your Royalty level doesn't have sufficient access rights to make changes here on user's behalf.`,
	'ui.forms.insufficientPermissionsForKingdomAdminTooltipAdminOnly': `Your Royalty level doesn't have sufficient access rights to make changes here.`,
	'ui.forms.insufficientPermissionsTooltip': `You don't have sufficient access rights to make any changes.`,
	'ui.forms.insufficientPermissionsTooltip.manager': `You don't have sufficient access rights to make any changes. Please contact your Admin for more information.`,
	'ui.forms.managed': `Managed`,
	'ui.forms.managedTooltip': `Please <showIntercom>contact us</showIntercom> if you'd like to make any changes.`,
	'ui.forms.options.only': `only`,
	'ui.forms.options.showMore': `More options`,
	'ui.forms.websiteSpeed.average.label': `Average`,
	'ui.forms.websiteSpeed.boost.label': `Boost`,
	'ui.forms.websiteSpeed.needsVerification': `You need to <link_verify>verify</link_verify> this website before you can increase the monitoring speed above 100%.`,
	'ui.forms.websiteSpeed.paused.label': `Monitoring paused`,
	'ui.forms.websiteSpeed.value': `{count, number} URLs/sec`,
	'ui.general.actions': `Actions`,
	'ui.general.applyChanges': `Apply changes`,
	'ui.general.available': `Available`,
	'ui.general.backButton': `Back`,
	'ui.general.boolean.values.no': `No`,
	'ui.general.boolean.values.yes': `Yes`,
	'ui.general.cancelButton': `Cancel`,
	'ui.general.caution': `Caution`,
	'ui.general.chooseNewPasswordButton': `Enter new password`,
	'ui.general.clearFilters': `Clear filters`,
	'ui.general.close': `Close`,
	'ui.general.comingSoon': `Coming soon`,
	'ui.general.continue': `Continue`,
	'ui.general.deleteButton': `Delete`,
	'ui.general.disabled': `Disabled`,
	'ui.general.enabled': `Enabled`,
	'ui.general.empty': `empty`,
	'ui.general.everybody': `everybody`,
	'ui.general.exit': `Exit`,
	'ui.general.fraction': `{partA} / {partB}`,
	'ui.general.install': `Install`,
	'ui.general.installed': `Installed`,
	'ui.general.insufficientPermissions': `Insufficient permissions`,
	'ui.general.loading': `Loading`,
	'ui.general.logout.modal.button': `Log out`,
	'ui.general.logout.modal.content': `{clientType, select,
		conductor {You can log back in via Conductor anytime.}
		other {You can log back in anytime.}
	}`,
	'ui.general.logout.modal.title': `{clientType, select,
		conductor {Log out of Conductor Website Monitoring & Conductor Intelligence}
		other {Log out of Conductor Website Monitoring}
	}`,
	'ui.general.missing': `not present`,
	'ui.general.multiselect.noSearchResults': `No results match "{value}"`,
	'ui.general.next': `Next`,
	'ui.general.nobody': `nobody`,
	'ui.general.noCrawledData': `I've just started crawling this website. In a moment you will find the website's pages in this overview.`,
	'ui.general.noCrawledData.subtitle': `Your results will be ready shortly.`,
	'ui.general.noCrawledData.title': `Hang on while we analyze this website…`,
	'ui.general.noCrawledData.waitingTime1.subtitle': `No worries, though. Your results should start to appear in the next few minutes.`,
	'ui.general.noCrawledData.waitingTime1.title': `Well, it's taking us longer than usual to complete the analysis.`,
	'ui.general.noCrawledData.waitingTime2.subtitle': `But we don't want to slow you down. Check back in 10 minutes and with any luck we'll have some results for you.`,
	'ui.general.noCrawledData.waitingTime2.title': `Hmmm… looks like something went wrong somewhere. We're looking into it.`,
	'ui.general.noCrawledData.waitingTime3.subtitle': `Feel free to contact us in the meantime and we can problem-solve it together.`,
	'ui.general.noCrawledData.waitingTime3.title': `We're still trying to figure out why the website isn't getting along well with our crawler.`,
	'ui.general.noSearchResults': `We could not find anything based on your filtering criteria.`,
	'ui.general.notAccessibleFeature': `You don't have sufficient access rights to use this feature.`,
	'ui.general.notAccessibleFeatureToManager': `You don't have sufficient rights to make this change. Please contact your Admin for more information.`,
	'ui.general.notAllowedBecauseInsufficientRoyaltyLevel': `Your Royalty level doesn't have sufficient access rights to make this change on user's behalf.`,
	'ui.general.notAllowedBecauseManaged': `Please <showIntercom>contact us</showIntercom> if you'd like to make any changes.`,
	'ui.general.notAllowedBecauseManagedViaConductor': `Please go to your <linkConductor>Conductor Intelligence account</linkConductor> to make this change. Once done, it will also be reflected in this Conductor Website Monitoring account. You can always <showIntercom>contact us</showIntercom> if you need help.`,
	'ui.general.notApplicable': `not applicable`,
	'ui.general.numberedItem': `{number}. {content}`,
	'ui.general.off': `Off`,
	'ui.general.ok': `OK`,
	'ui.general.on': `On`,
	'ui.general.onDate': `on {date, date, medium}`,
	'ui.general.or': `or`,
	'ui.general.ordinalPosition': `{position, selectordinal,
		one {#st}
		two {#nd}
		few {#rd}
		other {#th}
	}`,
	'ui.general.pageNotAccessible': `Page not accessible`,
	'ui.general.pageNotFound': `Page not found`,
	'ui.general.pageRedirects': `Page redirects`,
	'ui.general.parenthesis': `({content})`,
	'ui.general.previousButton': `Previous`,
	'ui.general.propertyDeclaration': `{text__property}:`,
	'ui.general.range': `{partA} - {partB}`,
	'ui.general.readOnly': `Read-only`,
	'ui.general.redirectTarget': `Redirect target`,
	'ui.general.reinstall': `reinstall`,
	'ui.general.saveAndClose': `Save and close`,
	'ui.general.saveAndContinue': `Save and continue`,
	'ui.general.saveButton': `Save`,
	'ui.general.saveNewPasswordButton': `Save new password`,
	'ui.general.screenNotFound': `This screen doesn't exist`,
	'ui.general.search.placeholder': `search`,
	'ui.general.selectFieldPlaceholder': WIP(`Choose`),
	'ui.general.serverError': `Something went wrong. Please try again.`,
	'ui.general.settings': `Settings`,
	'ui.general.skipThisStep': `skip this step`,
	'ui.general.stillLoading': `Still loading`,
	'ui.general.unavailable': `Unavailable`,
	'ui.general.unexpectedError': `Something went wrong.`,
	'ui.general.unknownError': `Something went wrong. Please, try again later`,
	'ui.general.upgradeBadge': `Upgrade`,
	'ui.getEnrichmentApiTokenModal.accept': `Accept`,
	'ui.getEnrichmentApiTokenModal.intro': `The Conductor Terms of Use are applicable to our API Services as well.`,
	'ui.getEnrichmentApiTokenModal.title': `Terms of Use`,
	'ui.getReportingApiTokenModal.accept': `Accept`,
	'ui.getReportingApiTokenModal.intro': `The Conductor Terms of Use are applicable to our API Services as well.`,
	'ui.getReportingApiTokenModal.title': `Terms of Use`,
	'ui.googleAnalyticsDependenciesWarning.alertDefinitions': `{alertDefinitions, plural,
		one {# alert definition}
		other {# alert definitions}
	}`,
	'ui.googleAnalyticsDependenciesWarning.segments': `{segments, plural,
		one {# segment}
		other {# segments}
	}`,
	'ui.googleAnalyticsDependenciesWarning.warning': `
		<p>
			You have {dependencies} based on {googleAnalyticsVersion, select,
				v4 {Google Analytics 4}
				other {Universal Analytics}
			} metrics. When connected to {googleAnalyticsVersion, select,
				v4 {Universal Analytics}
				other {Google Analytics 4}
			}, you will see the metrics of both Google Analytics versions in Conductor Website Monitoring, but {googleAnalyticsVersion, select,
				v4 {Google Analytics 4}
				other {Universal Analytics}
			} data will no longer be updated.
		</p>
		<p>
			Please edit these segments and/or alert definitions so they depend on the {googleAnalyticsVersion, select,
				v4 {Universal Analytics}
				other {Google Analytics 4}
			} metrics.
		</p>
	`,
	'ui.googleLookerStudio.accountSettings.connect': `Connect with Google Looker Studio`,
	'ui.googleLookerStudio.accountSettings.open': `Open in Google Looker Studio`,
	'ui.googleLookerStudio.dashboard.connect': `Connect GDS`,
	'ui.googleLookerStudio.dashboard.interfaceHint': `Set up a connector to work with this data directly in Google Looker Studio. {text__firstLink} about how our connector works and start off by exploring {text__secondLink}.`,
	'ui.googleLookerStudio.dashboard.interfaceHint.firstLink': `Learn more`,
	'ui.googleLookerStudio.dashboard.interfaceHint.firstLink.target': `https://www.contentkingapp.com/support/`,
	'ui.googleLookerStudio.dashboard.interfaceHint.secondLink': `our report template`,
	'ui.googleLookerStudio.dashboard.open': `Open in Looker Studio`,
	'ui.headingLevelsSkipped.level': `{level} ({count, number})`,
	'ui.healthImpact.issue.alreadyBest': `Changes of this issue did not improve the {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health, because all existing pages were already unaffected or ignored before.`,
	'ui.healthImpact.issue.alreadyWorst': `Changes of this issue did not worsen the {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health, because all existing pages were already affected before.`,
	'ui.healthImpact.issue.negative': `Changes of this issue worsened the {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health by {count, plural,
		=0 {less than 1 point}
		=1 {# point}
		other {# points}
	}.`,
	'ui.healthImpact.issue.none': `Changes of this issue did not impact the {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health.`,
	'ui.healthImpact.issue.positive': `Changes of this issue improved the {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health by {count, plural,
		=0 {less than 1 point}
		=1 {# point}
		other {# points}
	}.`,
	'ui.healthImpact.issueCategory.negative': `Changes in this issue category worsened the {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health by {count, plural,
		=0 {less than 1 point}
		=1 {# point}
		other {# points}
	}.`,
	'ui.healthImpact.issueCategory.none': `Changes in this issue category did not impact the {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health.`,
	'ui.healthImpact.issueCategory.positive': `Changes in this issue category improved the {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health by {count, plural,
		=0 {less than 1 point}
		=1 {# point}
		other {# points}
	}.`,
	'ui.healthImpact.unavailable': `You can only compare Health from the day after the first changes were tracked.`,
	'ui.hints.why.label': `why?`,
	'ui.historicalIssueDetail.noPrimaryChanges': `This issue was not opened, resolved, or ignored on any page.`,
	'ui.historicalIssues.loading.subtitle': `This might take a while.`,
	'ui.historicalIssues.loading.title': `Please wait while historical data is being loaded.`,
	'ui.historicalIssues.openedIssues.tooltip': `{count, plural,
		=0 {No issue in this category was}
		=1 {# issue in this category was}
		other {# issues in this category were}
	} opened in the selected date range. This excludes issues that were opened and resolved within the same date range.`,
	'ui.historicalIssues.overview.filter.improved': `Improved`,
	'ui.historicalIssues.overview.filter.label': `Impact on Health`,
	'ui.historicalIssues.overview.filter.worsened': `Worsened`,
	'ui.historicalIssues.resolvedIssues.tooltip': `{count, plural,
		=0 {No issue in this category was}
		=1 {# issue in this category was}
		other {# issues in this category were}
	} resolved in the selected date range. This excludes issues that were resolved and opened within the same date range.`,
	'ui.historicalIssues.resolvedIssues.unavailableTooltip': `You can only compare resolved issues from the day after the first changes were tracked.`,
	'ui.httpAuthFields.password': `Password`,
	'ui.httpAuthFields.username': `Username`,
	'ui.ignoreIssueCategory.notAvailable.tooltip': `Issues can’t be ignored all at once. However, you can ignore them one by one.`,
	'ui.ignoringOnSegments.notAllowedDueToHealth.explanation': `This segment is based on Health. Ignoring such segments is not supported.`,
	'ui.ignoringOnSegments.notAllowedDueToHealth.status': `cannot be ignored`,
	'ui.incomplete3DSecureChallengeBanner.button': `Verify payment`,
	'ui.incomplete3DSecureChallengeBanner.content': `If you are the card holder, please verify the payment now. In case you're not the card holder, contact the relevant person or department within your organization to resolve this issue. If there's anything we can help with, please <showIntercom>contact us</showIntercom>.`,
	'ui.incomplete3DSecureChallengeBanner.title': `Your bank requires payment verification`,
	'ui.indexNowCustomElementChangesTriggerModal.columnCustomElement': `Custom Element`,
	'ui.indexNowCustomElementChangesTriggerModal.description': `Configure which Custom Element changes trigger a submission to IndexNow.`,
	'ui.indexNowCustomElementChangesTriggerModal.noCustomElements': `
		<p>You don't have any custom elements configured yet</p>
		<p><linkCreateCustomElement>Create a custom element</linkCreateCustomElement></p>
	`,
	'ui.indexNowCustomElementChangesTriggerModal.sidebar': `
		<p>Submitting a page to IndexNow gives search engines a signal they should crawl the page with priority.</p>
		<p>This way we can make sure search engines see the freshest and newest content you've just published, and the most recent changes you've made on your website.</p>
		<p>Configure which Custom Element changes Conductor Website Monitoring will submit to IndexNow.</p>
		<p>You can read more in <linkSupport>our support article.</linkSupport></p>
	`,
	'ui.indexNowCustomElementChangesTriggerModal.title': `Custom Element changes to submit to IndexNow`,
	'ui.indexNowIssuesRequiredToPassModal.description': `Configure which issue are blocking a submission of a URL to IndexNow. Issues that are ignored on a page will not block it's submission.`,
	'ui.indexNowIssuesRequiredToPassModal.sidebar': `
		<p>Submitting a page to IndexNow gives search engines a signal they should crawl the page with priority.</p>
		<p>This way we can make sure search engines see the freshest and newest content you've just published, and the most recent changes you've made on your website.</p>
		<p>Configure which issues are blocking a submission of a URL to IndexNow.</p>
		<p>You can read more in <linkSupport>our support article.</linkSupport></p>
	`,
	'ui.indexNowIssuesRequiredToPassModal.title': `Manage issues blocking submission to IndexNow`,
	'ui.indexNowIssuesSelectField.columnIssue': `Issue`,
	'ui.indexNowIssuesSelectField.columnStatus': `Status`,
	'ui.indexNowIssuesSelectField.doesBlockSubmission': `blocks submission`,
	'ui.indexNowIssuesSelectField.doesNotBlockSubmission': `does not block submission`,
	'ui.indexNowIssuesSelectField.willBlockSubmission': `will block submission`,
	'ui.indexNowIssuesSelectField.willNotBlockSubmission': `will not block submission`,
	'ui.indexNowNativeTriggersModal.columnContentChange': `Content change`,
	'ui.indexNowNativeTriggersModal.columnPageStatusChange': `Page status change`,
	'ui.indexNowNativeTriggersModal.contentChange.h1': `H1`,
	'ui.indexNowNativeTriggersModal.contentChange.h2-h6': `H2 - H6`,
	'ui.indexNowNativeTriggersModal.contentChange.metaDescription': `Meta Description`,
	'ui.indexNowNativeTriggersModal.contentChange.title': `Title`,
	'ui.indexNowNativeTriggersModal.description': `Configure which {category, select,
		contentChanges {content changes}
		pageStatusChanges {page status changes}
		other {changes}
	} trigger a submission to IndexNow.`,
	'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameIndexable': `Page became indexable`,
	'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameMissing': `Page became missing`,
	'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameNonIndexable': `Page became non-indexable`,
	'ui.indexNowNativeTriggersModal.pageStatusChange.pageBecameRedirect': `Page became redirected`,
	'ui.indexNowNativeTriggersModal.pageStatusChange.pageWasAdded': `Page was added`,
	'ui.indexNowNativeTriggersModal.pageStatusChange.pageWasRestored': `Page was restored`,
	'ui.indexNowNativeTriggersModal.sidebar': `
		<p>Submitting a page to IndexNow gives search engines a signal they should crawl the page with priority.</p>
		<p>This way we can make sure search engines see the freshest and newest content you've just published, and the most recent changes you've made on your website.</p>
		<p>Configure which changes Conductor Website Monitoring will submit to IndexNow.</p>
		<p>You can read more in <linkArticle>our support article</linkArticle>.</p>
	`,
	'ui.indexNowNativeTriggersModal.title': `{category, select,
		contentChanges {Content changes}
		pageStatusChanges {Page status changes}
		other {Changes}
	} to submit to IndexNow`,
	'ui.indexNowSegmentBlocklistForm.allDeniedWarning': `This will block all segments on this website. Conductor Website Monitoring will not submit any page that is included in at least one segment to IndexNow.`,
	'ui.indexNowSegmentBlocklistForm.allowAllExcept': `Allow all except`,
	'ui.indexNowSegmentBlocklistForm.denyAllExcept': `Deny all except`,
	'ui.indexNowSegmentBlocklistForm.isBlocked': `blocked`,
	'ui.indexNowSegmentBlocklistForm.isNotBlocked': `not blocked`,
	'ui.indexNowSegmentBlocklistForm.segmentColumn': `Segment`,
	'ui.indexNowSegmentBlocklistForm.statusColumn': `Status`,
	'ui.indexNowSegmentBlocklistForm.willBeBlocked': `will be blocked`,
	'ui.indexNowSegmentBlocklistForm.willNotBeBlocked': `will not be blocked`,
	'ui.indexNowSegmentConditionsModal.description': `Configure what segments a URL can be in to be submitted to IndexNow. Only indexable pages will be submitted to IndexNow. If a page is non-indexable for any reason, Conductor Website Monitoring will not submit it to IndexNow`,
	'ui.indexNowSegmentConditionsModal.sidebar': `
		<p>Submitting a page to IndexNow gives search engines a signal they should crawl the page with priority.</p>
		<p>This way we can make sure search engines see the freshest and newest content you've just published, and the most recent changes you've made on your website.</p>
		<p>Configure which segments can a URL be in for Conductor Website Monitoring to submit it to IndexNow.</p>
		<p>You can read more in <linkSupport>our support article.</linkSupport></p>
	`,
	'ui.indexNowSegmentConditionsModal.title': `Manage segments allowlist and blocklist`,
	'ui.indexNowTriggerSelectField.doesNotTrigger': `does not trigger submission`,
	'ui.indexNowTriggerSelectField.doesTrigger': `triggers submission`,
	'ui.indexNowTriggerSelectField.statusColumn': `Status`,
	'ui.indexNowTriggerSelectField.willNotTrigger': `will not trigger submission`,
	'ui.indexNowTriggerSelectField.willTrigger': `will trigger submission`,
	'ui.indexNowUrlConditionsModal.allDeniedWarning': `This will block all segments on this website. Conductor Website Monitoring will not submit any page that is included in at least one segment to IndexNow.`,
	'ui.indexNowUrlConditionsModal.description': `Specify which URLs should Conductor Website Monitoring submit to IndexNow. Only indexable pages will be submitted to IndexNow. If a page in non-indexable from any reason, Conductor Website Monitoring will not submit it to IndexNow.`,
	'ui.indexNowUrlConditionsModal.sidebar': `
		<p>Conductor Website Monitoring submits only indexable pages to IndexNow. Non-indexable pages due to robots txt directives, meta robot directives, X-Robots-Tag or canonical links, will not be submitted to IndexNow.</p>
		<p>However; If you don't want some pages to be submitted to IndexNow, you can specify it here. This might be useful for excluding pages matching patterns:</p>
		<ul>
			<li><code>*?filter=</code></li>
			<li><code>/blog/updates/01</code></li>
		</ul>
		<p>You can read more in <linkSupport>our support article.</linkSupport></p>
	`,
	'ui.indexNowUrlConditionsModal.title': `Manage URL allowlist and blocklist`,
	'ui.installAkamaiDataStreamModal.confirmation': `
		<p>To give Conductor Website Monitoring access to your log files, you will need to install the Akamai DataStream for this domain in your Akamai account. Follow <linkArticle>this step-by-step guide</linkArticle> to learn how.</p>
		<p>If you've already configured it, it might take a few hours until Akamai sets everything up.</p>
		<p>Please <showIntercom>contact us</showIntercom> if it is taking more than 24 hours.</p>
	`,
	'ui.installAkamaiDataStreamModal.description': `
		<p>You need to install the Akamai DataStream in your Akamai account. Learn how in <linkArticle>our support article</linkArticle>.</p>
		<p>The data will be streamed to an AWS S3 bucket. Please choose the region of the bucket below, and enter the provided AWS credentials into Akamai when installing the DataStream.</p>
	`,
	'ui.installAkamaiDataStreamModal.footnote': `After configuring the DataStream, it might take a few hours until Akamai sets everything up.`,
	'ui.installAkamaiDataStreamModal.oneMoreThing': `One more thing`,
	'ui.installAkamaiDataStreamModal.sidebar': `
		<p>To get access to your log files for this website, please install Akamai DataStream for this domain in your Akamai account.</p>
		<p>After the Akamai DataStream is installed, you will see the search engine activity on this website directly in Conductor Website Monitoring.</p>
		<p>Follow <linkArticle>this step-by-step guide</linkArticle> to learn how to install the Akamai DataStream.</p>
	`,
	'ui.installAkamaiDataStreamModal.title': `Install Akamai DataStream`,
	'ui.installCloudflareLogpushModal.confirmation': `
		<p>To give Conductor Website Monitoring access to your log files, you will need to install the Cloudflare Logpush for this domain in your Cloudflare account. Follow <linkArticle>this step-by-step guide</linkArticle> to learn how.</p>
		<p>Please <showIntercom>contact us</showIntercom> if you need help with setting it up.</p>
	`,
	'ui.installCloudflareLogpushModal.description': `
		<p>You need to install the Logpush in your Cloudflare account. Learn how in <linkArticle>our support article</linkArticle>.</p>
		<p>The data will be streamed to an AWS S3 bucket. Please choose the region of the bucket below.</p>
		<p>Conductor Website Monitoring will then create the bucket for you, and you will see the AWS credentials you need to enter when configuring the Logpush below.</p>
	`,
	'ui.installCloudflareLogpushModal.oneMoreThing': `One more thing`,
	'ui.installCloudflareLogpushModal.ownershipToken': `Ownership token`,
	'ui.installCloudflareLogpushModal.sidebar': `
		<p>To get access to your log files for this website, please install Logpush for this domain in your Cloudflare account.</p>
		<p>After the Cloudflare Logpush is installed, you will see the search engine activity on this website directly in Conductor Website Monitoring.</p>
		<p>Follow <linkArticle>this step-by-step guide</linkArticle> to learn how to install the Cloudflare Logpush.</p>
	`,
	'ui.installCloudflareLogpushModal.title': `Install Cloudflare Logpush`,
	'ui.installCloudflareLogpushModal.verification': `
		<p>Enter the Ownership token you see below to the Cloudflare UI to validate access to the bucket.</p>
	`,
	'ui.installCloudflareWorkerModal.apiToken': `API token`,
	'ui.installCloudflareWorkerModal.description': `
		<p>To install Cloudflare Worker, you need to create an API token in your Cloudflare account. Learn how in <linkArticle>our support article</linkArticle>.</p>
		<p>Once done, please enter the token below:</p>
	`,
	'ui.installCloudflareWorkerModal.errors.cloudflareApiTokenCannotAccessWebsite': `The Cloudflare API token doesn't provide access to website {websiteDisplayName}`,
	'ui.installCloudflareWorkerModal.errors.cloudflareApiTokenInvalidFormat': `The Cloudflare API token isn't properly formatted`,
	'ui.installCloudflareWorkerModal.installButton': `Install`,
	'ui.installCloudflareWorkerModal.sidebar': `
		<p>To get access to your log files for this website, Conductor Website Monitoring will install a Cloudflare Worker for this domain in your Cloudflare account.</p>
		<p>After the Cloudflare Worker is installed, you will see the search engine activity on this website directly in Conductor Website Monitoring.</p>
		<p>Follow <linkArticle>this step-by-step guide</linkArticle> to learn how to retrieve the necessary API token for authentication.</p>
	`,
	'ui.installCloudflareWorkerModal.title': `Install Cloudflare Worker`,
	'ui.installCloudfrontStandardLoggingModal.breadcrumb.awsAccountId': `Enter AWS Account ID`,
	'ui.installCloudfrontStandardLoggingModal.breadcrumb.bucket': `Choose region`,
	'ui.installCloudfrontStandardLoggingModal.confirmation': `
		<p>To give Conductor Website Monitoring access to your log files, you will need to install the Cloudfront Standard logging for this domain in your AWS account. Follow <linkArticle>this step-by-step guide</linkArticle> to learn how.</p>
		<p>Please <showIntercom>contact us</showIntercom> if you need help with setting it up.</p>
	`,
	'ui.installCloudfrontStandardLoggingModal.description': `
		<p>You need to install the CloudFront Standard logging in your AWS account. Learn how in <linkArticle>our support article</linkArticle>.</p>
		<p>Please enter your AWS account ID, so Conductor Website Monitoring can grant you access for streaming the log data to the S3 bucket in the Conductor AWS account.</p>
	`,
	'ui.installCloudfrontStandardLoggingModal.oneMoreThing': `One more thing`,
	'ui.installCloudfrontStandardLoggingModal.sidebar': `
		<p>To get access to your log files for this website, please install the Standard logging for this domain in your AWS account.</p>
		<p>After the Standard logging is installed, you will see the search engine activity on this website directly in Conductor Website Monitoring.</p>
		<p>Follow <linkArticle>this step-by-step quide</linkArticle> to learn how to install the Cloudfront Standard logging.</p>
	`,
	'ui.installCloudfrontStandardLoggingModal.title': `Install Cloudfront Standard logging`,
	'ui.installFastlyLogStreamingModal.confirmation': `
		<p>To give Conductor Website Monitoring access to your log files, you will need to install the Fastly Real-Time Log Streaming for this domain in your AWS account. Follow <linkArticle>this step-by-step guide</linkArticle> to learn how.</p>
		<p>Please <showIntercom>contact us</showIntercom> if you need help with setting it up.</p>
	`,
	'ui.installFastlyLogStreamingModal.description': `
		<p>You need to install the Install Fastly Real-Time Log Streaming in your Fastly account. Learn how in <linkArticle>our support article</linkArticle>.</p>
		<p>The data will be streamed to an AWS S3 bucket. Please choose the region of the bucket below.</p>
		<p>Conductor Website Monitoring will then create the bucket for you, and you will see the AWS credentials you need to enter when configuring the Real-Time Log Streaming below.</p>
	`,
	'ui.installFastlyLogStreamingModal.oneMoreThing': `One more thing`,
	'ui.installFastlyLogStreamingModal.sidebar': `
		<p>To get access to your log files for this website, please install Fastly Real-Time Log Streaming for this domain in your Fastly account.</p>
		<p>After the Fastly Real-Time Log Streaming is installed, you will see the search engine activity on this website directly in Conductor Website Monitoring.</p>
		<p>Follow <linkArticle>this step-by-step guide</linkArticle> to learn how to install the Fastly Real-Time Log Streaming.</p>
	`,
	'ui.installFastlyLogStreamingModal.title': `Install Fastly Real-Time Log Streaming`,
	'ui.interfaceHints.hint.coBranding': `Want to personalize your experience even more? Click here to replace the Conductor Website Monitoring logo with your own.`,
	'ui.interfaceHints.hint.columnSetsPicker': `Column sets lets you control and customize the information displayed in Pages to suit your needs.`,
	'ui.interfaceHints.hint.eventsScreen': `Whenever something major suddenly breaks or changes on your website, you'll find it here.`,
	'ui.interfaceHints.hint.lastCheckedAt': `As part of our continuous monitoring we check this page automatically and regularly. And whenever you're looking at a page in Conductor Website Monitoring it will get constantly checked to always provide you with the newest data.`,
	'ui.interfaceHints.hint.percentageOfAffectedPages': `This button will take you to an <b>overview of all pages with this issue</b>. It's a great starting point for fixing your website.`,
	'ui.interfaceHints.hint.recrawlButton': `As part of our continuous monitoring we check this page automatically and regularly. But whenever needed, you can always <b>check it immediately</b> using this button.`,
	'ui.interfaceHints.hint.relevance': `This shows the importance of individual pages within the website on a scale from 0 to 10. The importance of a page is calculated using many factors, including the number of internal links pointing to the page, its depth within the website structure and how often its content changes.`,
	'ui.invalidHreflangs.hreflang': `Hreflang`,
	'ui.invalidHreflangs.target': `Target`,
	'ui.invoiceDetails.customInvoiceFields.attn': `Attn.`,
	'ui.invoiceDetails.customInvoiceFields.poNumber': `PO Number`,
	'ui.invoiceDetails.customInvoiceFields.reference': `Your Reference`,
	'ui.invoiceDetails.customInvoiceFields.supplierNumber': `Supplier Number`,
	'ui.invoices.date': `Date`,
	'ui.invoices.daysPastDueDate': `Days overdue: {number__daysPastDueDate, number}`,
	'ui.invoices.dueDate': `Due: {date__dueDate, date, medium}`,
	'ui.invoices.orderId': `Number`,
	'ui.invoices.paid': `Paid`,
	'ui.invoices.pending': `Pending`,
	'ui.invoices.price': `Amount`,
	'ui.invoices.status': `Status`,
	'ui.invoices.tablePlaceholder': `Your invoice will be ready soon.`,
	'ui.invoices.title': `Invoice history`,
	'ui.issueChanges.brokenEntered': `Affected pages entered the segment`,
	'ui.issueChanges.brokenLeft': `Affected pages left the segment`,
	'ui.issueChanges.closed': `Issue resolved`,
	'ui.issueChanges.ignored': `Issue ignored`,
	'ui.issueChanges.notApplicablePoints': `Issue became applicable`,
	'ui.issueChanges.notInSegmentPoints': `Unaffected pages entered the segment`,
	'ui.issueChanges.notPagePoints': `Added pages`,
	'ui.issueChanges.notRequiredPoints': `Issue became required`,
	'ui.issueChanges.opened': `Issue opened on existing pages`,
	'ui.issueChanges.openedNewPage': `Issue opened on added pages`,
	'ui.issueChanges.openLessImportant': `Affected pages became less important`,
	'ui.issueChanges.openMoreImportant': `Affected pages became more important`,
	'ui.issueChanges.openNotApplicable': `Issue became not applicable`,
	'ui.issueChanges.openNotPage': `Pages became non-pages`,
	'ui.issueChanges.pointsLessImportant': `Unaffected pages became less important`,
	'ui.issueChanges.pointsMoreImportant': `Unaffected pages became more important`,
	'ui.issueChanges.pointsNotApplicable': `Issue became not applicable`,
	'ui.issueChanges.pointsNotInSegment': `Unaffected pages left the segment`,
	'ui.issueChanges.pointsNotPage': `Pages became non-pages`,
	'ui.issueChanges.pointsNotRequired': `Issue became not required`,
	'ui.issueChanges.unignored': `Issue unignored`,
	'ui.issueChangesGroups.noLongerApplicable': `No longer applicable`,
	'ui.issueChangesGroups.onlyImportanceChanges': `Importance changed`,
	'ui.issueChangesGroups.segmentChanged': `Segment changed`,
	'ui.issueChangesGroups.unaffected': `Unaffected pages`,
	'ui.issueChangesTable.toggleSecondary.hide': `Hide other changes impacting {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health`,
	'ui.issueChangesTable.toggleSecondary.show': `Show other changes impacting {scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health`,
	'ui.issueChangesTable.toggleSecondary.tooltip': `{scope, select,
		segment {Segment}
		website {Website}
		other {}
	} Health can also change without issues being opened, resolved, or ignored. Learn more in <link>our support article</link>.`,
	'ui.issueDetail.affectedPagesBar.amountOfPages': `Pages affected:`,
	'ui.issueDetail.affectedPagesBar.link': `see affected pages`,
	'ui.issueDetail.affectedPagesBar.percentageOfSegment': `(<b>{percentage, number, percent}</b> of the segment)`,
	'ui.issueDetail.affectedPagesBar.percentageOfWebsite': `(<b>{percentage, number, percent}</b> of the website)`,
	'ui.issueDetail.affectedPagesChart.controls.hideSegments': `Hide segments`,
	'ui.issueDetail.affectedPagesChart.controls.showSegments': `Show segments`,
	'ui.issueDetail.affectedPagesChart.ignoredOnSegment': `{issues, plural,
		one {Issue}
		other {Issues}
	} ignored on {pages, number, percent} of pages in segment: {segmentName}`,
	'ui.issueDetail.affectedPagesChart.ignoredOnWebsite': `{issues, plural,
		one {Issue}
		other {Issues}
	} ignored on {pages, number, percent} of pages`,
	'ui.issueDetail.affectedPagesChart.notApplicableOnSegment': `{issues, plural,
		one {Issue}
		other {Issues}
	} not applicable on {pages, number, percent} of pages in segment: {segmentName}`,
	'ui.issueDetail.affectedPagesChart.notApplicableOnWebsite': `{issues, plural,
		one {Issue}
		other {Issues}
	} not applicable on {pages, number, percent} of pages`,
	'ui.issueDetail.affectedPagesChart.notPresentOnSegment': `{issues, plural,
		one {Issue}
		other {Issues}
	} closed on {pages, number, percent} of pages in segment: {segmentName}`,
	'ui.issueDetail.affectedPagesChart.notPresentOnWebsite': `{issues, plural,
		one {Issue}
		other {Issues}
	} closed on {pages, number, percent} of pages`,
	'ui.issueDetail.affectedPagesChart.notRequiredOnSegment': `{issues, plural,
		one {Issue}
		other {Issues}
	} not required on {pages, number, percent} of pages in segment: {segmentName}`,
	'ui.issueDetail.affectedPagesChart.notRequiredOnWebsite': `{issues, plural,
		one {Issue}
		other {Issues}
	} not required on {pages, number, percent} of pages`,
	'ui.issueDetail.affectedPagesChart.presentOnSegment': `{issues, plural,
		one {Issue}
		other {Issues}
	} open on {pages, number, percent} of pages in segment: {segmentName}`,
	'ui.issueDetail.affectedPagesChart.presentOnWebsite': `{issues, plural,
		one {Issue}
		other {Issues}
	} open on {pages, number, percent} of pages`,
	'ui.issueDetail.affectedPagesChart.unknownOnSegment': `{issues, plural,
		one {Issue}
		other {Issues}
	} still unknown on {pages, number, percent} of pages in segment: {segmentName}`,
	'ui.issueDetail.affectedPagesChart.unknownOnWebsite': `{issues, plural,
		one {Issue}
		other {Issues}
	} still unknown on {pages, number, percent} of pages`,
	'ui.issueDetail.allCasesIgnoredBox.content.images_alt_attribute': `All unoptimized images on this page have been ignored. You will still get notified of new unoptimized images. If that's not intended, ignore the issue itself.`,
	'ui.issueDetail.allCasesIgnoredBox.content.images_mixed_transport': `All unoptimized images on this page have been ignored. You will still get notified of new unoptimized images. If that's not intended, ignore the issue itself.`,
	'ui.issueDetail.allCasesIgnoredBox.content.links_3xx': `All links to redirects on this page have been ignored. You will still get notified of new links to redirects. If that's not intended, ignore the issue itself.`,
	'ui.issueDetail.allCasesIgnoredBox.content.links_4xx': `All broken links on this page have been ignored. You will still get notified of new broken links. If that's not intended, ignore the issue itself.`,
	'ui.issueDetail.allCasesIgnoredBox.content.links_to_canonicalized': `All links to canonicalized pages on this page have been ignored. You will still get notified of new links to canonicalized pages. If that's not intended, ignore the issue itself.`,
	'ui.issueDetail.allCasesIgnoredBox.ignoreLink': `IGNORE ISSUE`,
	'ui.issueDetail.allCasesIgnoredBox.label.images_alt_attribute': `All unoptimized images have been ignored`,
	'ui.issueDetail.allCasesIgnoredBox.label.images_mixed_transport': `All unoptimized images have been ignored`,
	'ui.issueDetail.allCasesIgnoredBox.label.links_3xx': `All links to redirects have been ignored`,
	'ui.issueDetail.allCasesIgnoredBox.label.links_4xx': `All links that are broken have been ignored`,
	'ui.issueDetail.allCasesIgnoredBox.label.links_to_canonicalized': `All links to canonicalized pages have been ignored`,
	'ui.issueDetail.allIgnoredPagesPercentage': `ignored on all pages`,
	'ui.issueDetail.closedPages': `{percentage, number} OK`,
	'ui.issueDetail.closedPagesPercentage': `{percentage, number}% OK`,
	'ui.issueDetail.details.imageMarkup.alt': `ALT`,
	'ui.issueDetail.details.imageMarkup.src': `SOURCE`,
	'ui.issueDetail.details.links.label': `LABEL`,
	'ui.issueDetail.details.links.statusCode': `STATUS`,
	'ui.issueDetail.details.links.target': `TARGET`,
	'ui.issueDetail.details.links.type': `TYPE`,
	'ui.issueDetail.details.links.url': `URL`,
	'ui.issueDetail.elements.canonicalLink': `Canonical links`,
	'ui.issueDetail.elements.h1': `H1 headings`,
	'ui.issueDetail.elements.h2': `H2 headings`,
	'ui.issueDetail.elements.image': `Images`,
	'ui.issueDetail.elements.metaDescription': `Meta descriptions`,
	'ui.issueDetail.elements.title': `Titles`,
	'ui.issueDetail.ignoringButton.ignoringActive': `Configure issue ignoring`,
	'ui.issueDetail.ignoringButton.setupIgnoring': `Ignore this issue`,
	'ui.issueDetail.ignoringModal.casesTable.general.button.ignoreOnWebsite': `Ignore on website`,
	'ui.issueDetail.ignoringModal.casesTable.general.button.stopIgnoring': `Stop ignoring`,
	'ui.issueDetail.ignoringModal.casesTable.general.column.ignoredOn.heading': `Ignored on`,
	'ui.issueDetail.ignoringModal.casesTable.general.column.ignoredOn.notIgnored': `not ignored`,
	'ui.issueDetail.ignoringModal.casesTable.general.column.ignoredOn.thisPage': `this page`,
	'ui.issueDetail.ignoringModal.casesTable.general.column.ignoredOn.website': `website`,
	'ui.issueDetail.ignoringModal.casesTable.general.column.page.heading': `Page`,
	'ui.issueDetail.ignoringModal.casesTable.general.column.url.heading': `URL`,
	'ui.issueDetail.ignoringModal.casesTable.images.selectionLabel': `<b>{selected, number}</b> {selected, plural,
		=1 {image}
		other {images}
	} selected`,
	'ui.issueDetail.ignoringModal.casesTable.links.selectionLabel': `<b>{selected, number}</b> {selected, plural,
		=1 {link}
		other {links}
	} selected`,
	'ui.issueDetail.ignoringModal.header.ignoringLabel': `Ignoring`,
	'ui.issueDetail.ignoringModal.header.tabLabel.images': `Images`,
	'ui.issueDetail.ignoringModal.header.tabLabel.links': `Links`,
	'ui.issueDetail.ignoringModal.header.tabLabel.page': `Page`,
	'ui.issueDetail.ignoringModal.header.tabLabel.pages': `Pages`,
	'ui.issueDetail.ignoringModal.header.tabLabel.segments': `Segments`,
	'ui.issueDetail.ignoringModal.header.tabLabel.website': `Website`,
	'ui.issueDetail.ignoringModal.header.tabsHeadline': `Ignoring`,
	'ui.issueDetail.ignoringModal.ignoringStatus.ignored': `ignored`,
	'ui.issueDetail.ignoringModal.ignoringStatus.notIgnored': `not ignored`,
	'ui.issueDetail.ignoringModal.ignoringStatus.willBeIgnored': `will be ignored`,
	'ui.issueDetail.ignoringModal.ignoringStatus.willBeUnignored': `will be unignored`,
	'ui.issueDetail.ignoringModal.scope.general.button.stopIgnoring': `Stop ignoring`,
	'ui.issueDetail.ignoringModal.scope.general.column.status.heading': `Status`,
	'ui.issueDetail.ignoringModal.scope.general.ignoredAlreadyOnWebsite.description': `{count__issues, plural,
		one {This issue is already ignored on the whole website, which also covers this page.}
		other {These issues are already ignored on the whole website, which also covers this page.}
	}`,
	'ui.issueDetail.ignoringModal.scope.images.ignoredAlreadyOnPage.description': `This issue is already ignored on this page, which also covers all images.`,
	'ui.issueDetail.ignoringModal.scope.images.ignoredAlreadyOnWebsite.description': `This issue is already ignored on the whole website, which also covers all images.`,
	'ui.issueDetail.ignoringModal.scope.images.ignoringActive.description': `<b>{ignoredCases, number}</b> specific {ignoredCases, plural,
		=1 {image is}
		other {images are}
	} ignored:`,
	'ui.issueDetail.ignoringModal.scope.images.ignoringInactive.description': `No specific images are ignored.`,
	'ui.issueDetail.ignoringModal.scope.images.noImagesIgnored.description': `No specific images are ignored. You can ignore specific images from a page detail screen.`,
	'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnPage.description': `This issue is already ignored on this page, which also covers all links.`,
	'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnWebsite.description': `This issue is already ignored on the whole website, which also covers all links.`,
	'ui.issueDetail.ignoringModal.scope.links.ignoringActive.description': `<b>{ignoredCases, number}</b> specific {ignoredCases, plural,
		=1 {link is}
		other {links are}
	} ignored:`,
	'ui.issueDetail.ignoringModal.scope.links.ignoringInactive.description': `No specific links are ignored.`,
	'ui.issueDetail.ignoringModal.scope.links.noLinksIgnored.description': `No specific links are ignored. You can ignore specific links from a page detail screen.`,
	'ui.issueDetail.ignoringModal.scope.page.ignore.bulletList.alerts': `{count__issues, plural,
		one {You will not be alerted about this issue on this page.}
		other {You will not be alerted about these issues on this page.}
	}`,
	'ui.issueDetail.ignoringModal.scope.page.ignore.bulletList.reports': `{count__issues, plural,
		one {This issue will not be included in reports for this page.}
		other {These issues will not be included in reports for this page.}
	}`,
	'ui.issueDetail.ignoringModal.scope.page.ignore.button': `Ignore on page`,
	'ui.issueDetail.ignoringModal.scope.page.ignore.description': `{count__issues, plural,
		one {You can ignore this issue on this page.}
		other {You can ignore these issues on this page.}
	}`,
	'ui.issueDetail.ignoringModal.scope.page.ignoringActive.bulletList.alerts': `{count__issues, plural,
		one {You are not being alerted about this issue on this page.}
		other {You are not being alerted about these issues on this page.}
	}`,
	'ui.issueDetail.ignoringModal.scope.page.ignoringActive.bulletList.reports': `{count__issues, plural,
		one {This issue is not being included in reports for this page.}
		other {These issues are not being included in reports for this page.}
	}`,
	'ui.issueDetail.ignoringModal.scope.page.ignoringActive.description': `{count__issues, plural,
		one {This issue is being ignored on this page.}
		other {These issues are being ignored on this page.}
	}`,
	'ui.issueDetail.ignoringModal.scope.pages.ignoredAlreadyOnWebsite.description': `This issue is already ignored on the whole website, which also covers all pages.`,
	'ui.issueDetail.ignoringModal.scope.pages.ignoringActive.description': `This issue is ignored on <b>{ignoredPages, number}</b> {ignoredPages, plural,
		=1 {page}
		other {pages}
	}:`,
	'ui.issueDetail.ignoringModal.scope.pages.ignoringActive.pagesTable.button.stopIgnoring': `Stop ignoring`,
	'ui.issueDetail.ignoringModal.scope.pages.ignoringActive.pagesTable.columnHeading.page': `Page`,
	'ui.issueDetail.ignoringModal.scope.pages.ignoringActive.pagesTable.selectionLabel': `<b>{selected, number}</b> {selected, plural,
		=1 {page}
		other {pages}
	} selected`,
	'ui.issueDetail.ignoringModal.scope.pages.ignoringInactive.description': `{count__issues, plural,
		one {This issue is not ignored on any page. You can ignore this issue on a specific page from its detail screen.}
		other {These issues are not ignored on any page. You can ignore these issues on a specific page from its detail screen.}
	}`,
	'ui.issueDetail.ignoringModal.scope.website.ignore.bulletList.alerts': `{count__issues, plural,
		one {You will not be alerted about this issue.}
		other {You will not be alerted about these issues.}
	}`,
	'ui.issueDetail.ignoringModal.scope.website.ignore.bulletList.reports': `{count__issues, plural,
		one {This issue will not be included in reports.}
		other {These issues will not be included in reports.}
	}`,
	'ui.issueDetail.ignoringModal.scope.website.ignore.button': `Ignore on website`,
	'ui.issueDetail.ignoringModal.scope.website.ignore.description': `{count__issues, plural,
		one {You can ignore this issue across the <b>entire</b> website:}
		other {You can ignore these issues across the <b>entire</b> website:}
	}`,
	'ui.issueDetail.ignoringModal.scope.website.ignoringActive.bulletList.alerts': `{count__issues, plural,
		one {You are not being alerted about this issue.}
		other {You are not being alerted about these issues.}
	}`,
	'ui.issueDetail.ignoringModal.scope.website.ignoringActive.bulletList.reports': `{count__issues, plural, one{This issue is not being included in reports.} other{These issues are not being included in reports.}
	}`,
	'ui.issueDetail.ignoringModal.scope.website.ignoringActive.description': `{count__issues, plural,
		one {This issue is being ignored across the entire website.}
		other {These issues are being ignored across the entire website.}
	}`,
	'ui.issueDetail.ignoringModal.segments.column.segment.heading': `Segment`,
	'ui.issueDetail.ignoringModal.segments.noSegmentsDescription': `This website has no segments.`,
	'ui.issueDetail.ignoringTable.pendingChanges': `<b>{count, number}</b> pending {count, plural,
		=0 {changes}
		=1 {change}
		other {changes}
	}`,
	'ui.issueDetail.issueDetailTable.pagesChangedImpactLabel': `Only impact changed`,
	'ui.issueDetail.issueDetailTable.pagesCount': `{count, plural,
		=0 {pages}
		=1 {page}
		other {pages}
	}`,
	'ui.issueDetail.issueDetailTable.pagesFixedLabel': `Fixed`,
	'ui.issueDetail.issueDetailTable.pagesIgnoredLabel': `Ignored`,
	'ui.issueDetail.issueDetailTable.pagesNonApplicableHint': `Pages that had this issue open on the start date didn't contain the tested element on the end date or were not a page anymore.`,
	'ui.issueDetail.issueDetailTable.pagesNonApplicableLabel': `No longer applicable`,
	'ui.issueDetail.issueDetailTable.pagesUnignoredLabel': `Unignored`,
	'ui.issueDetail.issueDetailTable.segmentScope.pagesChangedImpactHint': `The issue status didn't change, but the <em>Importance</em> of these pages changed which resulted in a different impact on the segment health.`,
	'ui.issueDetail.issueDetailTable.websiteScope.pagesChangedImpactHint': `The issue status didn't change, but the <em>Importance</em> of these pages changed which resulted in a different impact on the website health.`,
	'ui.issueDetail.issueScore.decreasedLabel': `Health decreased by {count, plural,
		=0 {# points}
		=1 {# point}
		other {# points}
	}`,
	'ui.issueDetail.issueScore.increasedLabel': `Health increased by {count, plural,
		=0 {# points}
		=1 {# point}
		other {# points}
	}`,
	'ui.issueDetail.learnWhyLink': `Learn why`,
	'ui.issueDetail.linksAffected.label': `{count, plural,
		=0 {# internal links affected}
		=1 {# internal link affected}
		other {# internal links affected}
	}`,
	'ui.issueDetail.metaRobots.meta_bingbot': `Bing`,
	'ui.issueDetail.metaRobots.meta_googlebot': `Googlebot`,
	'ui.issueDetail.metaRobots.meta_robots': `All robots`,
	'ui.issueDetail.metaRobots.meta_slurp': `Yahoo`,
	'ui.issueDetail.metaRobots.meta_yandex': `Yandex`,
	'ui.issueDetail.openPages': `{count, number} {count, plural,
		=0 {pages}
		=1 {page}
		other {pages}
	}`,
	'ui.issueDetail.openPagesPercentage.singleIssue': `issue on <big>{percentage, number}%</big> of pages`,
	'ui.issueDetail.openPagesPercentage.singleIssue.all': `issue on <big>all</big> pages`,
	'ui.issueDetail.pagesAffected.label': `{count, plural,
		=0 {# pages affected}
		=1 {# page affected}
		other {# pages affected}
	}`,
	'ui.issueDetail.pagesAffected.openedLabel': `Issue was opened on {count, number} pages.`,
	'ui.issueDetail.pagesAffected.resolvedLabel': `Issue was resolved on {count, number} pages.`,
	'ui.issues.card.allIgnoredPagesPercentage': `ignored on <big>all</big> pages`,
	'ui.issues.card.closedPagesPercentage': `OK on <big>all</big> pages`,
	'ui.issues.card.openPagesPercentage': `issues on <big>{percentage, number}%</big> of pages`,
	'ui.issues.card.openPagesPercentage.all': `issues on <big>all</big> pages`,
	'ui.issues.cardType.closed': `Fixed issues`,
	'ui.issues.cardType.ignored': `Ignored issues`,
	'ui.issues.cardType.open': `Open issues`,
	'ui.issues.issueInstructionChanged': `The configuration of this issue was changed from Conductor's default settings through {text__link}.`,
	'ui.issues.issueInstructionChanged.link': `Issue Configuration`,
	'ui.issues.noClosedIssues': `You don’t have any closed issues yet. Don’t worry, this is only the start of your journey.`,
	'ui.issues.noOpenIssues': `There are no open issues. Keep up the good work!`,
	'ui.issues.platform.robotsTxt.assetsDisallowed.factors.assetUrl': `Asset URL`,
	'ui.issues.showMore.closedLink': `{count} more fixed issues`,
	'ui.issues.showMore.ignoredLink': `{count} more ignored issues`,
	'ui.issues.showMore.openLink': `{count} more open issues`,
	'ui.issuesComparison.seeChanges': `See changes`,
	'ui.issuesConfiguration.category.notAvailableTooltip': `Issue configuration is not available for these issues.`,
	'ui.issuesConfiguration.modal.introduction': `Here you can set up how we test for this type of issue on your website. NOTE: changes you make here will apply across the entire website.`,
	'ui.issuesConfiguration.modal.revert': `You can always {text__link}.`,
	'ui.issuesConfiguration.modal.revert.link': `revert back to the default settings`,
	'ui.issuesConfiguration.settings.lengthUnit': `Length units`,
	'ui.issuesConfiguration.settings.lengthUnit.characters': `Characters`,
	'ui.issuesConfiguration.settings.lengthUnit.pixels': `Pixels`,
	'ui.issuesConfiguration.settings.linksToCanonicalizedRequiredOnScope': `Links to non-canonicalized pages required on`,
	'ui.issuesConfiguration.settings.maximumLength': `Maximum length`,
	'ui.issuesConfiguration.settings.maximumLength.ofDescription': `Description max. length`,
	'ui.issuesConfiguration.settings.maximumLength.ofTitle': `Title max. length`,
	'ui.issuesConfiguration.settings.minimumLength': `Minimum length`,
	'ui.issuesConfiguration.settings.minimumLength.ofDescription': `Description min. length`,
	'ui.issuesConfiguration.settings.minimumLength.ofTitle': `Title min. length`,
	'ui.issuesConfiguration.settings.requiredOnScope': `Required on`,
	'ui.issuesConfiguration.settings.requiredOnScope.allPages': `All pages`,
	'ui.issuesConfiguration.settings.requiredOnScope.indexablePages': `Indexable pages`,
	'ui.issuesConfiguration.settings.requiredOnScope.searchEngineAccessiblePages': `Search engine accessible pages`,
	'ui.issuesOverview.charts.affectedPagesPerCategoryChart.title': `Affected pages per category`,
	'ui.issuesOverview.charts.affectedPagesPerSegmentChart.segments.closed': `OK: {count, number, percent}`,
	'ui.issuesOverview.charts.affectedPagesPerSegmentChart.segments.open': `Have issues: {count, number, percent}`,
	'ui.issuesOverview.charts.affectedPagesPerSegmentChart.title': `Affected pages per segment`,
	'ui.issuesOverview.charts.healthPerSegmentChart.title': `Health per segment`,
	'ui.issuesOverview.charts.historicalIssuesChart.legend.openedIssues': `Opened`,
	'ui.issuesOverview.charts.historicalIssuesChart.legend.resolvedIssues': `Resolved`,
	'ui.issuesOverview.charts.historicalIssuesChart.title': `Issues`,
	'ui.issuesOverview.charts.historicalWebsiteHealthChart.noChange': `No change`,
	'ui.issuesOverview.charts.historicalWebsiteHealthChart.title': `Health`,
	'ui.issuesOverview.charts.historicalWebsiteHealthChart.tooltip': `Health: {value, number}`,
	'ui.issuesOverview.filter.all': `All`,
	'ui.issuesOverview.filter.segment.all': `All`,
	'ui.issuesOverview.filter.segment.label': `Segments`,
	'ui.issuesOverview.filter.state.all': `All`,
	'ui.issuesOverview.filter.state.closed': `Closed`,
	'ui.issuesOverview.filter.state.ignored': `Ignored`,
	'ui.issuesOverview.filter.state.label': `Status`,
	'ui.issuesOverview.filter.state.notApplicable': `Not applicable`,
	'ui.issuesOverview.filter.state.notRequired': `Not required`,
	'ui.issuesOverview.filter.state.open': `Open`,
	'ui.issuesOverview.filter.state.unknown': `Still unknown`,
	'ui.issuesOverview.filterTitle': `Filter`,
	'ui.issuesOverview.historySummary.openedIssues': `Opened issues`,
	'ui.issuesOverview.historySummary.resolvedIssues': `Resolved issues`,
	'ui.issuesOverview.mobileLabel': `Issues`,
	'ui.issuesOverview.noIssueFound': `No issue has been found.`,
	'ui.issuesOverview.score': `Website Health`,
	'ui.issuesOverview.selected': `selected`,
	'ui.issuesOverview.selectedIssues': `{count, plural,
		=0 {selected issues}
		=1 {selected issue}
		other {selected issues}
	}`,
	'ui.issuesOverview.tabs.graphs': `Graphs`,
	'ui.issuesOverview.tabs.issues': `Issues`,
	'ui.issuesOverview.title': `Issues`,
	'ui.kickstartPage.title': `Logging you in…`,
	'ui.legalDocumentPopup.continue': `Continue`,
	'ui.legalDocumentPopup.dataProcessingAgreement': `Data Processing Agreement`,
	'ui.legalDocumentPopup.privacyPolicy': `Privacy Policy`,
	'ui.legalDocumentPopup.saveAsPdf': `Save as PDF`,
	'ui.legalDocumentPopup.termsOfUse': `Terms of Use`,
	'ui.lighthouseMonitoringNotEnabled.action': `Go to Settings`,
	'ui.lighthouseMonitoringNotEnabled.message': `You need to enable Lighthouse Monitoring before you can use this feature.`,
	'ui.lockedAccountModal.clickToLogout': `Click here to {logout}`,
	'ui.lockedAccountModal.contactUs': `Contact us`,
	'ui.lockedAccountModal.content': `
		<p>Long time - no see. We would love to show you how much better Conductor Website Monitoring became since you were around.</p>
		<p>Want to continue using Conductor Website Monitoring? Don't wait any longer and go ahead!</p>
	`,
	'ui.lockedAccountModal.greeting': `<p>Hi {firstName},</p>`,
	'ui.lockedAccountModal.oneMoreThing': `<p>Just one more thing before you get started:</p>`,
	'ui.lockedAccountModal.signUp': `Sign up`,
	'ui.lockedAccountModal.title': `Nice to see you again!`,
	'ui.logFileAnalysisAkamaiForm.title': `Log Sources`,
	'ui.logFileAnalysisCloudFlareSourceForm.deliveryMethod': `Delivery method`,
	'ui.logFileAnalysisCloudFlareSourceForm.enableLogFileAnalysisWarning': `You need to enable Log File Analysis above before installing the Cloudflare Worker or Cloudflare Logpush.`,
	'ui.logFileAnalysisCloudFlareSourceForm.logpushDescription': `Make sure your Cloudflare account supports this feature. Learn more in <linkArticle>our support article</linkArticle>.`,
	'ui.logFileAnalysisCloudFlareSourceForm.uninstallDeliveryMethodWarning': `There are two delivery methods installed. We recommend keeping only your preferred delivery method installed. Please <linkWorkerArticle>uninstall the Cloudflare Worker</linkWorkerArticle>, or <linkLogpushArticle>remove the Cloudflare Logpush</linkLogpushArticle>.`,
	'ui.logFileAnalysisForm.disableLogFileAnalysisWarning': `This will completely disable the Log File Analysis feature. You won't be able to work with the search engine data.`,
	'ui.logFileAnalysisForm.noAvailableLogSourcesMessage': `This website is not running on Cloudflare, Akamai, Cloudfront nor Fastly. More log sources will be supported soon. Read more in <linkArticle>our support article</linkArticle>.`,
	'ui.logFileAnalysisForm.noLogSourcesWarning': `There are no log sources installed, meaning that search engine visits are not collected.`,
	'ui.logFileAnalysisForm.title': `Log File Analysis`,
	'ui.logFileAnalysisLogSourceForm.akamaiDataStream': `Akamai DataStream`,
	'ui.logFileAnalysisLogSourceForm.akamaiExtraUpsell': `With Akamai DataStream you can set up the Log File Analysis feature on websites running on Akamai.`,
	'ui.logFileAnalysisLogSourceForm.cloudflareWorker': `Cloudflare Worker`,
	'ui.logFileAnalysisLogSourceForm.cloudflareLogpush': `Cloudflare Logpush`,
	'ui.logFileAnalysisLogSourceForm.cloudfrontExtraUpsell': `With Cloudfront Standard Logging you can set up the Log File Analysis feature on websites running on Cloudfront.`,
	'ui.logFileAnalysisLogSourceForm.cloudfrontStandardLogging': `Cloudfront Standard Logging`,
	'ui.logFileAnalysisLogSourceForm.enableLogFileAnalysisWarning': `You need to enable Log File Analysis above before installing the {provider}`,
	'ui.logFileAnalysisLogSourceForm.fastlyExtraUpsell': `With Fastly Real-Time Log Streaming you can set up the Log File Analysis feature on websites running on Fastly.`,
	'ui.logFileAnalysisLogSourceForm.fastlyRealtimeLogStreaming': `Fastly Real-Time Log Streaming`,
	'ui.logFileAnalysisLogSourceForm.install': `<openModal>Install</openModal>`,
	'ui.logFileAnalysisLogSourceForm.reinstall': `Installed (<openModal>reinstall</openModal>)`,
	'ui.logFileAnalysisLogSourceForm.stillPresentWarning': `The {provider} is still present on this website, even though Log File Analysis {reason, select,
		notOnPlan {is not included in your plan anymore}
		other {is disabled}
	}. We recommend <linkArticle>uninstalling the {provider}</linkArticle>.`,
	'ui.logFileAnalysisLogSourceForm.title': `Log Sources`,
	'ui.loginPage.googleSignInButton': `Sign in with Google`,
	'ui.loginPage.linkToContentKing': `New to Conductor Website Monitoring? Have a look around!`,
	'ui.loginScreen.invitationResent': `Your previous invitation has expired, so we've sent you a brand new one.`,
	'ui.loginScreen.linkToContentKing': `New to Conductor Website Monitoring? Have a look around!`,
	'ui.loginScreen.lostPasswordSuccess': `We've sent you an email with instructions to reset your password.`,
	'ui.loginScreen.magicLinkResent': `This magic link has already expired. We've sent you a new one which you can use to log in to your account right now.`,
	'ui.loginScreen.resetPasswordSuccess': `Congratulations, you have successfully set a new password.`,
	'ui.loginWithCredentialsForm.backToEmail': `Back to email address`,
	'ui.loginWithCredentialsForm.email': `Email address`,
	'ui.loginWithCredentialsForm.errors.userNotFound': `Incorrect email address`,
	'ui.loginWithCredentialsForm.errors.wrongPassword': `Incorrect password`,
	'ui.loginWithCredentialsForm.forgottenPassword': `Forgotten password?`,
	'ui.loginWithCredentialsForm.login': `Log in`,
	'ui.loginWithCredentialsForm.magicLinkSent': `We've sent you a magic link which you can use to log in to your account.`,
	'ui.loginWithCredentialsForm.password': `Password`,
	'ui.loginWithCredentialsForm.title': `Log in to your account`,
	'ui.loginWithGoogleForm.errors.invalidIdToken': `Google authentication failed.`,
	'ui.loginWithGoogleForm.errors.userNotFound': `We couldn't find your account.`,
	'ui.logout': `Logout`,
	'ui.logout.simple': `logout`,
	'ui.logSourceProvider.akamai': `Akamai DataStream`,
	'ui.logSourceProvider.cloudflare': `Cloudflare`,
	'ui.logSourceProvider.cloudfront': `Cloudfront Standard Logging`,
	'ui.logSourceProvider.fastly': `Fastly Real-Time Log Streaming`,
	'ui.logSourceProviderIcon.tooltip': `{provider}: {status}`,
	'ui.logSourceStatus.available': `can be installed`,
	'ui.logSourceStatus.enabled': `installed`,
	'ui.logSourceStatus.notAvailable': `not available, because the website is not using {provider}.`,
	'ui.lostPassword.backToEmailAddress': `Back to email address`,
	'ui.magicLink.title': `Check your email!`,
	'ui.mainError.linkLabel': `Or return to dashboard`,
	'ui.mainError.message': `We've noted the problem and will get right on fixing it.`,
	'ui.mainError.reloadButtonLabel': `Reload`,
	'ui.mainError.title': `Whoa! Looks like something went wild on our end. Sorry about that!`,
	'ui.memberDetail.sections.websiteAccess.title': `Website access`,
	'ui.memberDetail.sections.websiteAccess.websites': `Websites`,
	'ui.members.detail.label': `User Settings`,
	'ui.members.formGroups.assignedWebsites': `Website access`,
	'ui.members.formGroups.emailSettings': `Email settings`,
	'ui.members.formGroups.personalSettings': `Personal settings`,
	'ui.members.new.assignWebsites.sidebar': `
		<p>Here you can choose whether this invitee will have access to all websites or selected websites only.</p>
		<p>We recommend giving your colleagues access to all websites. Limiting the invitee's access to selected websites is useful when inviting your clients or external collaborators.</p>
		<p>Read more in <linkSupport>our support article</linkSupport>.</p>
	`,
	'ui.members.new.bulk.blankSlate': `Enter the email addresses of the team members you want to invite.`,
	'ui.members.new.bulk.sidebar.description': `Enter the email addresses of the team members you want to invite in this free text input.`,
	'ui.members.new.bulk.sidebar.freeBulkInput.addButton': `Add team members`,
	'ui.members.new.bulk.sidebar.freeBulkInput.skippedLines': `{lines, number} skipped`,
	'ui.members.new.bulk.sidebar.freeBulkInput.skippedLines.tooltip': `Each email address needs to be on a separate line, optionally with a , or ; after every line. You can invite only team members that aren't in your Conductor Website Monitoring account yet and aren't already in the table on the left.`,
	'ui.members.new.bulk.sidebar.freeBulkInput.skippedLines.tooltip.limit': `Also, your Conductor Website Monitoring account can only have up to {maxInvitations, plural,
		=1 {1 pending invitation}
		other {# pending invitations}
	}. You can invite more team members after some of the pending invitations are accepted, or after you cancel their invitations.`,
	'ui.members.new.bulk.sidebar.hint': `If you're unsure which role to pick, you can also <linkInviteSingleMember>invite a single team member</linkInviteSingleMember> instead and see the role differences there, or take a look at <linkSupport>our support article</linkSupport>.`,
	'ui.members.new.bulk.sidebar.maxInvitationsLimitReached': `Your Conductor Website Monitoring account can only have up to {maxInvitations, plural,
		=1 {1 pending invitation}
		other {# pending invitations}
	}. You can invite more team members after some of the pending invitations are accepted, or after you cancel their invitations.`,
	'ui.members.new.bulk.sidebar.tab.freeTextInput': `Free text input`,
	'ui.members.new.bulk.title': `Invite new team members`,
	'ui.members.new.bulk.validationStatus.failed': `{emails, plural,
		=1 {<b>1 member</b>}
		other {<b># members</b>}
	} can't be invited.`,
	'ui.members.new.bulk.validationStatus.failedExplanation': `Conductor Website Monitoring can't invite some of the team members you specified. Click on the message in this footer to see which team members they are, and hover on their individual error icons to find out more for each team member. Please <showIntercom>contact us</showIntercom> for help.`,
	'ui.members.new.bulk.validationStatus.pending': `{emails, plural,
		=1 {<b>1 email</b> is being validated.}
		other {<b># emails</b> are being validated.}
	}`,
	'ui.members.new.bulkProtip': `If you want to invite multiple team members, you can <linkBulkStep>invite them in bulk</linkBulkStep>`,
	'ui.members.new.flashMessages.invitationSent': `{emails, plural,
		=1 {Invitation}
		other {Invitations}
	} sent`,
	'ui.members.new.freeTextInputSidebar.add': `Add team members.`,
	'ui.members.new.freeTextInputSidebar.description': `Enter the email addresses of the team members you want to invite in this free text input.`,
	'ui.members.new.overlay.maximumInvitationsReached.button': `Go to Team Members`,
	'ui.members.new.overlay.maximumInvitationsReached.description': `You can only invite up to {maximumInvitations, plural,
		=1 {1 team member}
		other {# team members}
	} in your account. You can invite more team members after those who are invited accept their pending invitations, or after you cancel their invitations on Team Members.`,
	'ui.members.new.overlay.maximumInvitationsReached.title': `Wow, a lot of team members are invited!`,
	'ui.members.new.success.description': `Congratulations! You have successfully invited {emails, plural,
		=1 {<b>{email}</b>}
		other {<b># team members</b>}
	} to your Conductor Website Monitoring account.`,
	'ui.members.new.success.finish': `OK`,
	'ui.members.new.success.heading': `{emails, plural,
		=1 {Team member was successfully invited}
		other {Team members were successfully invited}
	}`,
	'ui.members.new.success.title': `{emails, plural,
		=1 {Team member was successfully invited}
		other {Team members were successfully invited}
	}`,
	'ui.members.new.title': `Invite new team member`,
	'ui.membersOverview.2fa.active': `Configured`,
	'ui.membersOverview.2fa.inactive': `Not configured`,
	'ui.membersOverview.columns.2fa': `2FA`,
	'ui.membersOverview.columns.browserExtension': `Extensions`,
	'ui.membersOverview.columns.role': `Role`,
	'ui.membersOverview.columns.teamMember': `Team member`,
	'ui.membersOverviewTable.editInvitee': `The invitee needs to join your Conductor Website Monitoring account before you can edit their details.`,
	'ui.membersOverviewTable.invited': `invited`,
	'ui.membersOverviewTable.removeSelf': `If you want to remove yourself from this Conductor Website Monitoring account, contact one of your colleagues or the <showIntercom>Conductor team</showIntercom>.`,
	'ui.membersOverviewTable.you': `you`,
	'ui.menu.account': `Account`,
	'ui.menu.activity': `Activity`,
	'ui.menu.alerts': `Alerts`,
	'ui.menu.conductor': `Conductor`,
	'ui.menu.content': `Pages`,
	'ui.menu.dashboard': `Dashboard`,
	'ui.menu.issues': `Issues`,
	'ui.menu.multidashboard': `Websites`,
	'ui.menu.platform': `Platform`,
	'ui.menu.profile': `Profile`,
	'ui.menu.progress.tooltip': `We've recently started monitoring this website, please give us a moment to find all pages.`,
	'ui.menu.settings': `Settings`,
	'ui.menu.support': `Support`,
	'ui.menu.team': `Team`,
	'ui.menu.teams': `Teams`,
	'ui.menu.userNamePlaceholder': `You`,
	'ui.menu.websites.addWebsiteLabel': `Add website`,
	'ui.menu.websites.closeLink': `close websites menu`,
	'ui.messagingApps.addMicrosoftTeamsChannel': `add Teams channel`,
	'ui.messagingApps.addSlackChannel': `add Slack channel`,
	'ui.microsoftTeams.addChannelFormDescription': `
		<p>To receive Conductor Website Monitoring alerts in a Microsoft Teams channel, add the Conductor Website Monitoring app to that channel. Learn how in <link>our support article</link>.</p>
		<p>Please enter the name of the Microsoft Teams channel and the Webhook URL from the connector:</p>
	`,
	'ui.microsoftTeams.invalidIncomingWebhookUrl': `This isn't a valid incoming webhook URL. Please make sure to follow the instructions described in <link>our support article</link>.`,
	'ui.modal.urlExclusionList.importStep.breadcrumbs': `Import`,
	'ui.modal.urlExclusionList.importStep.sidebar': `
		<p>The URL Exclusion List is used to keep Conductor Website Monitoring out of certain parts of the website.</p>
		<p>You can add or remove patterns at any time, but data for URLs matching the defined URL patterns will be removed from our index immediately.</p>
		<p>In the next step, you will be able to define custom exclusion rules as well. Read more in <linkArticle>our support article</linkArticle>.</p>
	`,
	'ui.modal.urlExclusionList.manageStep.breadcrumbs': `Manage`,
	'ui.modal.urlExclusionList.manageStep.sidebar': `
		<p>The URL Exclusion List is used to keep Conductor Website Monitoring out of certain parts of the website.</p>
		<p>You can add or remove patterns at any time, but data for URLs matching the defined URL patterns will be removed from our index immediately.</p>
		<p>The exclusion patterns follow the robots.txt format and they support both Disallow and Allow directives. An asterisk (<code>*</code>) matches any character. Some examples:</p>
		<ul>
			<li><code>*?filter=</code> excludes all URLs containing <code>?filter=</code></li>
			<li><code>/admin/</code> excludes all URLs starting with <code>/admin/</code></li>
			<li><code>Allow: /admin/folder/</code> includes all URLs starting with <code>/admin/folder/</code></li>
		</ul>
		<p>You can find more information about the URL Exclusion List in <linkArticle>our support article</linkArticle></p>
	`,
	'ui.modal.urlExclusionList.validationError': `URL patterns need to start with a <code>/</code> or <code>*</code>. See the examples on the right.`,
	'ui.multidashboard.kpi.pages.discovery.message': `Discovering pages on the website`,
	'ui.multidashboard.segmentsTable.header.health': `Health`,
	'ui.multidashboard.segmentsTable.header.issues': `Issues`,
	'ui.multidashboard.segmentsTable.header.pages': `Pages`,
	'ui.multidashboard.title': `Websites`,
	'ui.multiselect.all': `All`,
	'ui.multiselect.multiple': `Multiple`,
	'ui.navigation.lock.billingFailed.accountSuspended.creditCard.message': `Unfortunately we were unable to charge your credit card after multiple tries. Due to this the account has been suspended.`,
	'ui.navigation.lock.billingFailed.accountSuspended.header': `This account has been suspended`,
	'ui.navigation.lock.billingFailed.accountSuspended.paypal.message': `Unfortunately we were unable to charge your PayPal after multiple tries. Due to this the account has been suspended.`,
	'ui.navigation.lock.billingFailed.button': `Go to Billing Details`,
	'ui.navigation.lock.billingFailed.manager': `It's easy to fix this, simply go to Billing Details and follow the steps.`,
	'ui.navigation.lock.billingFailed.member': `Please contact a team manager to resolve this issue.`,
	'ui.newTeam.form.address': `Address`,
	'ui.newTeam.form.billingEmail': `Billing email`,
	'ui.newTeam.form.company': `Company`,
	'ui.newTeam.form.country': `Country`,
	'ui.newTeam.form.creditCard': `Credit Card`,
	'ui.newTeam.form.invoice': `Pay by invoice`,
	'ui.newTeam.form.paypal': `PayPal`,
	'ui.newTeam.form.province': `Province`,
	'ui.newTeam.form.sepaDebit': `SEPA`,
	'ui.newTeam.form.state': `State`,
	'ui.newTeam.form.vat': `VAT number`,
	'ui.newTeam.form.zipCodeAndCity': `Zip code + City`,
	'ui.newTeam.invitationForm.email': `Email`,
	'ui.newTeam.invitationForm.emailPlaceholder': `you@example.com`,
	'ui.newTeam.invitationForm.invitedContributorRights': `
		<ul>
			<li>Access only the selected websites</li>
			<li>Receive email reports</li>
		</ul>
	`,
	'ui.newTeam.invitationForm.invitedManagerRights': `
		<ul>
			<li>Access and manage all websites in your team</li>
			<li>Receive email reports</li>
			<li>View and manage other team members</li>
			<li>Setup and update billing preferences</li>
		</ul>
	`,
	'ui.newTeam.invitationForm.invitedMemberRights': `
		<ul>
			<li>Access all websites in your team</li>
			<li>Receive email reports</li>
		</ul>
	`,
	'ui.newTeam.invitationForm.invitedUserWillBeAbleTo': `Invited user will be able to:`,
	'ui.newTeam.invitationForm.selectWebsitesButton': `Select websites`,
	'ui.newTeam.invitationForm.sendInvitationButton': `Send invitation`,
	'ui.nonIndexableReasons.canonicalLink': `Canonical points to different URL.`,
	'ui.nonIndexableReasons.metaRobots': `Meta robots directives prevent indexing of this page.`,
	'ui.nonIndexableReasons.robotsTxt': `Robots.txt directives block access to this page.`,
	'ui.nonIndexableReasons.xRobotsTag': `X-Robots-Tag directives prevent indexing of this page.`,
	'ui.notifications.backgroundFailure': `We're having difficulties refreshing the data. {retryLink}.`,
	'ui.notifications.backgroundFailure.link': `Retry manually`,
	'ui.notifications.serverError.stage1': `We're experiencing technical difficulties. Please hold on while we retry.`,
	'ui.notifications.serverError.stage2': `We're still experiencing technical difficulties. Please hold on for another retry.`,
	'ui.notifications.serverError.stage3': `We're sorry, due to technical difficulties your action couldn't be processed. {retryLink}.`,
	'ui.notifications.serverError.stage3.link': `Retry manually`,
	'ui.notifications.timeoutError.stage1': `This action is taking longer than usual. Please hold on.`,
	'ui.notifications.timeoutError.stage2': `We're retrying the action. Please hold on.`,
	'ui.notifications.timeoutError.stage3': `We're still having difficulties processing the action. Please hold on or {retryLink}.`,
	'ui.notifications.timeoutError.stage3.link': `retry manually`,
	'ui.notifications.timeoutError.stage4': `We're sorry, but the action couldn't be processed. {retryLink}.`,
	'ui.notifications.timeoutError.stage4.link': `Retry manually`,
	'ui.noWebsites.addWebsiteButton': `Add a website`,
	'ui.noWebsites.addWebsiteTitle': `Add a website to get started`,
	'ui.noWebsites.nonManager': `Contact a team manager to add a website and get started.`,
	'ui.noWebsites.signupButton': `Sign up`,
	'ui.noWebsites.signupTitle': `Sign up to get started`,
	'ui.numberFilter.operator.any': `Any value`,
	'ui.numberFilter.operator.equalTo': `Equal to`,
	'ui.numberFilter.operator.lessThan': `Less than`,
	'ui.numberFilter.operator.moreThan': `More than`,
	'ui.numberFilter.operator.withinRange': `Within the range`,
	'ui.numberFilter.suffix.daysAgo': `days ago`,
	'ui.numberFilter.suffix.milliseconds': `milliseconds`,
	'ui.numberFilter.suffix.rangeExample': `E.g. [5;10]`,
	'ui.numberFilter.suffix.seconds': `seconds`,
	'ui.numberOfEmployeesForm.insufficientForOutOfBand': `Less than 500 employees`,
	'ui.numberOfEmployeesForm.enoughForOutOfBand': `500 employees and more`,
	'ui.numberOfEmployeesForm.nextButton': `Next`,
	'ui.numberOfEmployeesForm.prompt': `How many people work in your organization?`,
	'ui.onboarding.confirmationTitle': `That's all!`,
	'ui.onboarding.form.firstNameLabel': `First name`,
	'ui.onboarding.form.lastNameLabel': `Last name`,
	'ui.onboarding.form.laterLink': `I will do this later`,
	'ui.onboarding.form.nameLabel': `Name`,
	'ui.onboarding.form.nextButton': `Next`,
	'ui.onboarding.form.noThanksLink': `No thanks`,
	'ui.onboarding.form.passwordLabel': `Password`,
	'ui.onboarding.letsGetStarted': `Let’s get started!`,
	'ui.onboarding.missingNameText': `
		<p>If you change your mind you can always set your name in your profile.</p>
		<p>Would you like to set up a password so you can easily access your account later?</p>
	`,
	'ui.onboarding.nextButton': `Next`,
	'ui.onboarding.passwordText': `
		<p>Would you like to set up a password so you can easily access your account later?</p>
	`,
	'ui.onboarding.step1.bodyText': `{showFinally, select,
		yes {Finally, may}
		no {May}
		other {}
	} I know your name so I can address you properly?`,
	'ui.onboarding.step1.firstNameField.label': `First name`,
	'ui.onboarding.step1.lastNameField.label': `Last name`,
	'ui.onboarding.step1.nextButton': `Next`,
	'ui.onboarding.step1.noThanksLink': `No thanks`,
	'ui.onboarding.welcomeTitle': `Welcome to Conductor Website Monitoring`,
	'ui.onboardingGuideModal.titleWithName': `Nice to meet you, {name}!`,
	'ui.onboardingGuideModal.titleWithoutName': `Welcome to Conductor Website Monitoring`,
	'ui.onboardingGuideModalWelcomeStep.bodyTextForInvitee': `
		<p>Hi, it's The Conductor Team!</p>
		<p>We'd like to ask you a few questions before we get started.</p>
		<p>Don't worry, in just 10 seconds you'll be in the app!</p>
	`,
	'ui.onboardingGuideModalWelcomeStep.bodyTextForOwner': `
		<p>Hi, it's The Conductor Team!</p>
		<p>To better understand your use case, we'd like to ask you a few questions.</p>
		<p>Don't worry, in just 10 seconds you'll be in the app!</p>
	`,
	'ui.onboardingGuideModalWelcomeStep.buttonLabel': `Let's go`,
	'ui.onPageRequestBlockingModal.allDeniedWarning': `This will block all on-page requests, including on-page requests to this website's domain.`,
	'ui.onPageRequestBlockingModal.description': `Specify what on-page requests should be blocked when rendering your website.`,
	'ui.onPageRequestBlockingModal.sidebar': `
		<p>To prevent triggering analytics or ad tracking services when rendering your websites, Conductor Website Monitoring doesn't execute on-page requests to certain domains, such as Google Analytics, Adobe Analytics or Google Ads.</p>
		<p>On top of that, you can specify your custom ruleset for requests that will be blocked for your website.</p>
		<p>If you're not sure which requests to block, check out <linkArticle>our support article</linkArticle>.</p>
	`,
	'ui.onPageRequestBlockingModal.title': `On-page request blocking`,
	'ui.openGoogleSearchConsoleButton.label': `Open in GSC`,
	'ui.organizationAccess.domainsWhitelist': `Allowed domains`,
	'ui.organizationAccess.domainsWhitelist.errors.violated': `Some websites are on domains not included in this list. Please add those domains to the list or remove the websites from your account.`,
	'ui.organizationAccess.emailDomainsWhitelist': `Allowed email domains`,
	'ui.organizationAccess.emailDomainsWhitelist.errors.violated': `Some team members are on email domains not included in this list. Please add those domains to the list or remove the team members from your account.`,
	'ui.organizationAccess.enforced2FA': `Enforced 2FA`,
	'ui.organizationAccess.enforced2FA.errors.violated': `Some team members don't have two-factor authentication active. Please ask the team members to set it up or remove them from your account.`,
	'ui.organizationAccess.managersCanAffectSubscription': `Managers can make Subscription-related changes`,
	'ui.organizationAccess.managersCanControlAgencyAccess': `Managers can accept agency access requests`,
	'ui.organizationAccess.managersCanSeeApiTokens': `Managers can see integration tokens`,
	'ui.organizationAccess.title': `Organization Access Control`,
	'ui.pageDetail.adobeAnalytics.title': `Adobe Analytics`,
	'ui.pageDetail.buttons.checkPageButton.checkingNow': `Checking now`,
	'ui.pageDetail.buttons.checkPageButton.checkNow': `Check now`,
	'ui.pageDetail.buttons.checkPageButton.checkSoon': `Soon`,
	'ui.pageDetail.buttons.checkPageButton.hours': `In {hours, number} {hours, plural,
		=1 {hour}
		other {hours}
	}`,
	'ui.pageDetail.buttons.checkPageButton.minutes': `In {minutes, number} {minutes, plural,
		=1 {minute}
		other {minutes}
	}`,
	'ui.pageDetail.buttons.monitoringPaused': `Monitoring paused`,
	'ui.pageDetail.buttons.openPage': `Open page`,
	'ui.pageDetail.changeTracking.status.inaccessible': `{statusCode, select,
		unavailable {Page was inaccessible}
		other {Page was inaccessible ({statusCode})}
	}`,
	'ui.pageDetail.changeTracking.status.missing': `{statusCode, select,
		unavailable {Page was removed}
		other {Page was removed ({statusCode})}
	}`,
	'ui.pageDetail.changeTracking.status.monitoring.paused': `Monitoring paused`,
	'ui.pageDetail.changeTracking.status.monitoring.resumed': `Monitoring resumed`,
	'ui.pageDetail.changeTracking.status.nonPage': `This was not a page`,
	'ui.pageDetail.changeTracking.status.overQuota.start': `Page doesn't fit available page capacity`,
	'ui.pageDetail.changeTracking.status.page': `{statusCode, select,
		unavailable {Page was restored}
		other {Page was restored ({statusCode})}
	}`,
	'ui.pageDetail.changeTracking.status.redirect': `{statusCode, select,
		unavailable {Page was redirected}
		other {Page was redirected ({statusCode})}
	}`,
	'ui.pageDetail.changeTracking.status.serverError': `{statusCode, select,
		unavailable {Page showed a server error}
		other {Page showed a server error ({statusCode})}
	}`,
	'ui.pageDetail.changeTracking.status.unreachable': `Page became unreachable`,
	'ui.pageDetail.content.title': `Content`,
	'ui.pageDetail.contentBlock.hideH3ToH6Headings': `Hide H3-H6 headings`,
	'ui.pageDetail.contentBlock.showH3ToH6Headings': `Show H3-H6 headings`,
	'ui.pageDetail.conversions.analyticsDetectedButNotIdentified': `Detected, but the {name} was not identified. <linkArticle>Learn more</linkArticle> about how we detect analytics tracking.`,
	'ui.pageDetail.conversions.analyticsNotDetected': `None detected. <linkArticle>Learn more</linkArticle> about how we detect analytics tracking.`,
	'ui.pageDetail.conversions.containerId': `Container ID`,
	'ui.pageDetail.conversions.title': `Conversions`,
	'ui.pageDetail.conversions.trackingId': `Tracking ID`,
	'ui.pageDetail.customElements.noDefinitions.link': `here`,
	'ui.pageDetail.customElements.noDefinitions.text': `Create your first custom element {text__link}.`,
	'ui.pageDetail.customElements.title': `Custom elements`,
	'ui.pageDetail.dataDeletedDueToRetentionPeriod': `Data before this period were deleted because of your account's data retention. <showIntercom>Contact us</showIntercom> if you want to keep your existing data for longer than {dataRetentionInMonths, plural,
		zero {{dataRetentionInDays, plural,
			one {# day}
			other {# days}
		}}
		one {# month}
		other {# months}
	}.`,
	'ui.pageDetail.enrichmentFields.noDefinitions.link': `here`,
	'ui.pageDetail.enrichmentFields.noDefinitions.text': `Create your first enrichment field {text__link}.`,
	'ui.pageDetail.enrichmentFields.title': `Enrichment fields`,
	'ui.pageDetail.error3xx': `{statusCode} Redirect`,
	'ui.pageDetail.error4xx': `{statusCode} Error`,
	'ui.pageDetail.error5xx': `{statusCode} Server Error`,
	'ui.pageDetail.googleAnalytics.notEnabled': `<linkIntegrations>Connect Google Analytics</linkIntegrations> to see GA metrics for this page.`,
	'ui.pageDetail.googleAnalytics.title': `Google Analytics`,
	'ui.pageDetail.googleSearchConsole.notEnabled': `<linkIntegrations>Connect Google Search Console</linkIntegrations> to see GSC metrics for this page.`,
	'ui.pageDetail.googleSearchConsole.title': `Google Search Console`,
	'ui.pageDetail.history.foundAt': `This page was discovered on {date} at {time}.`,
	'ui.pageDetail.history.newerChanges': `Show newer changes`,
	'ui.pageDetail.history.noHistoryYet': `We haven't found any changes to display yet. As soon as we do, you'll find them here.`,
	'ui.pageDetail.history.olderChanges': `Show older changes`,
	'ui.pageDetail.history.pageDiscovered': `Page discovered`,
	'ui.pageDetail.history.referencedBy': `The reference was found in: <code>{referenceUrl}</code>`,
	'ui.pageDetail.history.unreliableResponse': `Unreliable response`,
	'ui.pageDetail.history.unreliableResponse.started': `Started having trouble checking the page: {unreliableResponse}`,
	'ui.pageDetail.history.unreliableResponse.stopped': `Page was restored (200)`,
	'ui.pageDetail.indexability.title': `Indexability`,
	'ui.pageDetail.issuesSummary.issues': `Issues`,
	'ui.pageDetail.issuesSummary.type.closed': `Closed`,
	'ui.pageDetail.issuesSummary.type.open': `Open`,
	'ui.pageDetail.lighthouse.dataNotAvailableYet': `Data not available yet.`,
	'ui.pageDetail.lighthouse.lighthouseNotEnabled': `Enable Lighthouse Monitoring for this website <linkSettings>here</linkSettings>.`,
	'ui.pageDetail.lighthouse.performance.label': `Performance Score`,
	'ui.pageDetail.lighthouse.title': `Conductor Lighthouse Web Vitals`,
	'ui.pageDetail.lighthouse.upsell': `This feature is available from our Pro plan.`,
	'ui.pageDetail.linkData.ampVariant': `AMP variant`,
	'ui.pageDetail.linkData.externalDomain': `external domain`,
	'ui.pageDetail.linkData.groupTitle.incoming': `Incoming`,
	'ui.pageDetail.linkData.groupTitle.outgoing': `Outgoing`,
	'ui.pageDetail.linkData.hreflang': `Hreflang`,
	'ui.pageDetail.linkData.incoming.columns.label': `Label`,
	'ui.pageDetail.linkData.incoming.columns.title': `Title`,
	'ui.pageDetail.linkData.incoming.columns.url': `URL`,
	'ui.pageDetail.linkData.incoming.columns.urlBroken': `Broken URL`,
	'ui.pageDetail.linkData.incoming.columns.urlRedirected': `Redirected URL`,
	'ui.pageDetail.linkData.incoming.linkLabel': `Incoming links int`,
	'ui.pageDetail.linkData.incoming.linkLabelShort': `Internal links`,
	'ui.pageDetail.linkData.incoming.panelTitle': `Incoming links internally`,
	'ui.pageDetail.linkData.incomingCanonical.columns.url': `URL`,
	'ui.pageDetail.linkData.incomingCanonical.linkLabel': `Incoming canonicals int`,
	'ui.pageDetail.linkData.incomingCanonical.linkLabelShort': `Canonical links`,
	'ui.pageDetail.linkData.incomingCanonical.panelTitle': `Incoming canonicals internally`,
	'ui.pageDetail.linkData.incomingRedirects.columns.status': `Status`,
	'ui.pageDetail.linkData.incomingRedirects.columns.url': `URL`,
	'ui.pageDetail.linkData.incomingRedirects.linkLabel': `Incoming redirects int`,
	'ui.pageDetail.linkData.incomingRedirects.linkLabelShort': `Redirects`,
	'ui.pageDetail.linkData.incomingRedirects.panelTitle': `Incoming redirects internally`,
	'ui.pageDetail.linkData.loading': `Loading`,
	'ui.pageDetail.linkData.mobileVariant': `Mobile variant`,
	'ui.pageDetail.linkData.outbound.columns.label': `Label`,
	'ui.pageDetail.linkData.outbound.columns.status': `Status`,
	'ui.pageDetail.linkData.outbound.columns.title': `Title`,
	'ui.pageDetail.linkData.outbound.columns.url': `URL`,
	'ui.pageDetail.linkData.outbound.linkLabel': `Outgoing links ext`,
	'ui.pageDetail.linkData.outbound.linkLabelShort': `External links`,
	'ui.pageDetail.linkData.outbound.panelTitle': `Outgoing links externally`,
	'ui.pageDetail.linkData.outgoingInternal.columns.label': `Label`,
	'ui.pageDetail.linkData.outgoingInternal.columns.status': `Status`,
	'ui.pageDetail.linkData.outgoingInternal.columns.title': `Title`,
	'ui.pageDetail.linkData.outgoingInternal.columns.url': `URL`,
	'ui.pageDetail.linkData.outgoingInternal.linkLabel': `Outgoing links int`,
	'ui.pageDetail.linkData.outgoingInternal.linkLabelShort': `Internal links`,
	'ui.pageDetail.linkData.outgoingInternal.panelTitle': `Outgoing links internally`,
	'ui.pageDetail.linkData.pagination.label': `Pagination`,
	'ui.pageDetail.linkData.pagination.nextLabel': `next`,
	'ui.pageDetail.linkData.pagination.prevLabel': `prev`,
	'ui.pageDetail.linkData.status.broken': `Broken`,
	'ui.pageDetail.linkData.status.forbidden': `Forbidden`,
	'ui.pageDetail.linkData.status.notBeingCheckedBecauseOfDomain': `not being checked`,
	'ui.pageDetail.linkData.status.notBeingCheckedBecauseOfDomain.hint': `Links to this domain are not being monitored.`,
	'ui.pageDetail.linkData.status.notBeingCheckedBecauseOfPort': `not being checked`,
	'ui.pageDetail.linkData.status.notBeingCheckedBecauseOfPort.hint': `Outgoing externals links to non-standard ports are not being monitored.`,
	'ui.pageDetail.linkData.status.ok': `OK`,
	'ui.pageDetail.linkData.status.redirect': `Redirect`,
	'ui.pageDetail.linkData.status.unknown': `Unknown`,
	'ui.pageDetail.linkData.status.unreachable': `Unreachable`,
	'ui.pageDetail.linkData.unknown': `unknown`,
	'ui.pageDetail.loadingHistoricalData': `Loading your tracked changes…`,
	'ui.pageDetail.nonPage': `Non-page`,
	'ui.pageDetail.openGraphBlock.title': `Open Graph`,
	'ui.pageDetail.overquota': `Over capacity`,
	'ui.pageDetail.page': `Page`,
	'ui.pageDetail.pageDataTitle': `Page data`,
	'ui.pageDetail.pagePropertiesFilter.allSelected': `All page properties`,
	'ui.pageDetail.pagePropertiesFilter.multipleSelected': `Multiple page properties`,
	'ui.pageDetail.pagePropertiesFilter.noneSelected': `-`,
	'ui.pageDetail.relations.title': `Relations`,
	'ui.pageDetail.relevance.label': `Importance`,
	'ui.pageDetail.robotsTxt.allowed.no': `disallowed`,
	'ui.pageDetail.robotsTxt.allowed.yes': `allowed`,
	'ui.pageDetail.score': `Page Health`,
	'ui.pageDetail.screenPlaceholder': `Give us a moment to snap a picture.`,
	'ui.pageDetail.searchEngineActivity.logFileAnalysisNotEnabled': `Enable Log File Analysis for this website <linkSettings>here</linkSettings>.`,
	'ui.pageDetail.searchEngineActivity.title': `Search engine activity`,
	'ui.pageDetail.searchEngineActivity.upsell': `This feature is available from our Pro plan.`,
	'ui.pageDetail.searchEngineVisits.bingDesktop': `Bing desktop`,
	'ui.pageDetail.searchEngineVisits.bingMobile': `Bing mobile`,
	'ui.pageDetail.searchEngineVisits.disabledPeriod': `Log File Analysis has not been enabled for a period this long.`,
	'ui.pageDetail.searchEngineVisits.googleDesktop': `Google desktop`,
	'ui.pageDetail.searchEngineVisits.googleMobile': `Google mobile`,
	'ui.pageDetail.searchEngineVisits.noData': `There were no visits yet`,
	'ui.pageDetail.searchEngineVisits.period.month': `Month`,
	'ui.pageDetail.searchEngineVisits.period.quarter': `3 months`,
	'ui.pageDetail.searchEngineVisits.period.year': `Year`,
	'ui.pageDetail.searchEngineVisits.title': `Visits per day`,
	'ui.pageDetail.segments.inSegment': `{count, plural,
		=1 {In segment}
		other {In segments}
	}`,
	'ui.pageDetail.segments.inSegment.none': `This page is not in any segments.`,
	'ui.pageDetail.social.title': `Social`,
	'ui.pageDetail.sourceMode.compare': `DOM/SRC comparison`,
	'ui.pageDetail.sourceMode.primary': `DOM view`,
	'ui.pageDetail.statusMessage.general.incomingRelationsLink': `See relations to this page`,
	'ui.pageDetail.statusMessage.general.warning': `Warning`,
	'ui.pageDetail.statusMessage.missing.incomingRelationsDescription': `This page has incoming relations. Remove them or point them to an actual page.`,
	'ui.pageDetail.statusMessage.missing.since': `Since`,
	'ui.pageDetail.statusMessage.missing.title': `This page is missing ({text__statusCode})`,
	'ui.pageDetail.statusMessage.notCrawledYet.description': `We've just discovered this page and are currently analysing it. The results should be ready any moment now.`,
	'ui.pageDetail.statusMessage.notCrawledYet.paused.description': `We've discovered this page but haven't analysed it yet as monitoring has been paused. We will analyse it as soon as monitoring resumes.`,
	'ui.pageDetail.statusMessage.notCrawledYet.paused.title': `Monitoring is paused`,
	'ui.pageDetail.statusMessage.notCrawledYet.title': `We are analyzing this page…`,
	'ui.pageDetail.statusMessage.overquota.description': `This page is not monitored because your total number of pages exceeds the Page Capacity setting. To start monitoring this page, increase the Page Capacity setting.`,
	'ui.pageDetail.statusMessage.overquota.descriptionTitle': `Over capacity`,
	'ui.pageDetail.statusMessage.overquota.title': `This page is not being monitored`,
	'ui.pageDetail.statusMessage.redirect.incomingRelationsDescription': `This page has incoming relations. Update them to point directly to the destination URL.`,
	'ui.pageDetail.statusMessage.redirect.since': `Redirecting since`,
	'ui.pageDetail.statusMessage.redirect.title': `This page is being redirected ({text__statusCode})`,
	'ui.pageDetail.statusMessage.redirect.to': `Redirect target`,
	'ui.pageDetail.statusMessage.serverError.incomingRelationsDescription': `This page has incoming relations. If you don't expect the server error to be resolved soon remove these relations or point them to a different page.`,
	'ui.pageDetail.statusMessage.serverError.since': `Since`,
	'ui.pageDetail.statusMessage.serverError.title': `This page returns a server error ({text__statusCode})`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.label': `We are currently having trouble checking this page`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.connectIssue': `connect failure`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.emptyResponse': `empty response`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.lighthouseTimeout': `Lighthouse time-out`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.redirectClient': `client-side redirect`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.renderTimeout': `rendering time-out`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.sizeLimit': `size limit`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.timeout': `time-out`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.too_many_on_page_requests': `too many on-page requests`,
	'ui.pageDetail.statusMessage.unreliableCircumstances.reasons.waf': `blocked by web application firewall`,
	'ui.pageDetail.tabs.currentData': `Current Version`,
	'ui.pageDetail.tabs.historicalData': `Tracked Changes`,
	'ui.pageDetail.tabs.history': `Changes`,
	'ui.pageDetail.tabs.issues': `Issues`,
	'ui.pageDetail.tabs.lastSeenData': `Last seen version`,
	'ui.pageDetail.twitterCardsBlock.title': `Twitter Cards`,
	'ui.pageDetail.type.4xx.always.description': `This page is inaccessible and we don't have any information that it ever was accessible.`,
	'ui.pageDetail.type.4xx.always.title': `This page is inaccessible.`,
	'ui.pageDetail.type.4xx.links.link': `see links to this page`,
	'ui.pageDetail.type.4xx.links.text': `You have {linksCount, number} {linksCount, plural,
		=1 {broken link}
		other {broken links}
	} to this page. You should fix this immediately by removing the links or pointing them to a different page.`,
	'ui.pageDetail.type.4xx.links.warning': `Warning`,
	'ui.pageDetail.type.4xx.noIssues': `Because the page became inaccessible, there are no issues to be shown.`,
	'ui.pageDetail.type.4xx.noIssuesAlways': `Because this page is inaccessible, there are no issues to be shown.`,
	'ui.pageDetail.type.4xx.spotted.description': `If this page is inaccessible by accident, take action NOW to prevent impacting search engines and visitors.`,
	'ui.pageDetail.type.4xx.spotted.title': `This page became inaccessible {timeAgo}.`,
	'ui.pageDetail.type.5xx.always.description': `This page is showing a server error and we don't have any information that it ever was a regular page.`,
	'ui.pageDetail.type.5xx.always.title': `This page shows a server error.`,
	'ui.pageDetail.type.5xx.links.link': `see links to this page`,
	'ui.pageDetail.type.5xx.links.text': `You have {linksCount, number} {linksCount, plural,
		=1 {broken link}
		other {broken links}
	} to this page. If you don't expect the server error to be resolved soon you should remove these links or change them to point to a different page.`,
	'ui.pageDetail.type.5xx.links.warning': `Warning`,
	'ui.pageDetail.type.5xx.noIssues': `Because the page started showing a server error, there are no issues to be shown.`,
	'ui.pageDetail.type.5xx.noIssuesAlways': `Because this page contains a server error, there are no issues to display.`,
	'ui.pageDetail.type.5xx.spotted.description': `Having server errors in a website is highly discouraged. You should take action NOW to prevent impacting search engines and visitors.`,
	'ui.pageDetail.type.5xx.spotted.title': `This page started showing a server error {timeAgo}.`,
	'ui.pageDetail.type.404.always.description': `This page does not exist and we don’t have any information that it ever existed.`,
	'ui.pageDetail.type.404.always.title': `This page does not exist`,
	'ui.pageDetail.type.404.links.link': `see links to this page`,
	'ui.pageDetail.type.404.links.text': `You have {linksCount, number} {linksCount, plural,
		=1 {broken link}
		other {broken links}
	} to this page. You should fix this immediately by removing the links or pointing them to a different page.`,
	'ui.pageDetail.type.404.links.warning': `Warning`,
	'ui.pageDetail.type.404.noIssues': `Because the page was removed, there are no issues to be shown.`,
	'ui.pageDetail.type.404.noIssuesAlways': `Because this page doesn’t exist and we don’t have any further information, there are no issues to display.`,
	'ui.pageDetail.type.404.spotted.description': `If this page is removed by accident, take action NOW to prevent impacting search engines and visitors.`,
	'ui.pageDetail.type.404.spotted.title': `This page was removed {timeAgo}`,
	'ui.pageDetail.type.noData.noIssues': `Because we have no data for this page, there are no issues to be shown.`,
	'ui.pageDetail.type.noData.title': `We have no data for this page`,
	'ui.pageDetail.type.redirect.noIssues': `Because the page was redirected, there are no issues to be shown.`,
	'ui.pageDetail.type.redirect.noIssuesAlways': `Because this page redirects to another page, there are no issues to display.`,
	'ui.pageDetail.type.redirect.spotted.description': `If this page is redirected by accident, take action NOW to prevent impacting search engines and visitors.`,
	'ui.pageDetail.type.redirect.spotted.targetUnknown': `We just discovered this redirect and are now checking where it's going to…`,
	'ui.pageDetail.type.redirect.spotted.title': `This page was redirected {timeAgo} to:`,
	'ui.pageDetail.type.redirect.spotted.titleUnknownTarget': `This page was redirected {timeAgo}`,
	'ui.pageDetail.type.unreachable.label': `This page is unreachable`,
	'ui.pageDetail.type.unreachable.links.link': `see all links to this page`,
	'ui.pageDetail.type.unreachable.links.text': `You have {linksCount, number} {linksCount, plural,
		=1 {link}
		other {links}
	} to this page. You should improve the loading time of this page or change these links to point to a faster page.`,
	'ui.pageDetail.type.unreachable.links.warning': `Warning`,
	'ui.pageDetail.type.unreachable.noIssues': `Because this page is unreachable there are no issues to display.`,
	'ui.pageDetail.type.unreachable.noIssuesAlways': `Because this page is unreachable there are no issues to display.`,
	'ui.pageDetail.type.unreachable.spotted.description': `This page took too long to respond when we last checked it. Slow pages and unavailable pages have a very negative impact on your visitor's experience and the performance of the website in search engines.`,
	'ui.pageDetail.type.unreachable.spotted.title': `This page timed out the last time we checked it`,
	'ui.pageDetail.unreachable': `Unreachable`,
	'ui.pageDetail.xmlSitemap': `XML Sitemap`,
	'ui.pageLastCheckedInfo.hint': `Page is being checked right now.`,
	'ui.pageLastCheckedInfo.label': `checking now`,
	'ui.pageLastCheckedInfo.paused': `Monitoring is paused. {pastRelativeText}`,
	'ui.pageLastCheckedMessage.generic.pastDays': `{days, plural,
		=1 {# day}
		other {# days}
	} ago`,
	'ui.pageLastCheckedMessage.generic.pastHours': `{hours, plural,
		=1 {# hour}
		other {# hours}
	} ago`,
	'ui.pageLastCheckedMessage.generic.pastMinutes': `{minutes, plural,
		=1 {past minute}
		other {# minutes ago}
	}`,
	'ui.pageLastCheckedMessage.generic.pastMonths': `{months, plural,
		=1 {# month}
		other {# months}
	} ago`,
	'ui.pageLastCheckedMessage.generic.pastSeconds': `past {seconds, plural,
		=1 {second}
		other {# seconds}
	}`,
	'ui.pageLastCheckedMessage.generic.pastWeeks': `{weeks, plural,
		=1 {# week}
		other {# weeks}
	} ago`,
	'ui.pageLastCheckedMessage.page.pastDays': `Page was last checked {days, plural,
		=1 {# day ago}
		other {# days ago}
	}.`,
	'ui.pageLastCheckedMessage.page.pastHours': `Page was last checked {hours, plural,
		=1 {in the past hour}
		other {# hours ago}
	}.`,
	'ui.pageLastCheckedMessage.page.pastMinutes': `Page was last checked {minutes, plural,
		=1 {in the past minute}
		other {in the past # minutes}
	}.`,
	'ui.pageLastCheckedMessage.page.pastMonths': `Page was last checked {months, plural,
		=1 {# month ago}
		other {# months ago}
	}.`,
	'ui.pageLastCheckedMessage.page.pastSeconds': `Page was last checked {seconds, plural,
		=1 {in the past second}
		other {in the past # seconds}
	}.`,
	'ui.pageLastCheckedMessage.page.pastWeeks': `Page was last checked {weeks, plural,
		=1 {# week ago}
		other {# weeks ago}
	}.`,
	'ui.pageLastCheckedMessage.robotsTxt.pastDays': `Robots.txt was last checked {days, plural,
		=1 {# day ago}
		other {# days ago}
	}.`,
	'ui.pageLastCheckedMessage.robotsTxt.pastHours': `Robots.txt was last checked {hours, plural,
		=1 {# hour ago}
		other {# hours ago}
	}.`,
	'ui.pageLastCheckedMessage.robotsTxt.pastMinutes': `Robots.txt was last checked {minutes, plural,
		=1 {# minute ago}
		other {# minutes ago}
	}.`,
	'ui.pageLastCheckedMessage.robotsTxt.pastMonths': `Robots.txt was last checked {months, plural,
		=1 {# month ago}
		other {# months ago}
	}.`,
	'ui.pageLastCheckedMessage.robotsTxt.pastSeconds': `Robots.txt was last checked {seconds, plural,
		=1 {# second ago}
		other {# seconds ago}
	}.`,
	'ui.pageLastCheckedMessage.robotsTxt.pastWeeks': `Robots.txt was last checked {weeks, plural,
		=1 {# week ago}
		other {# weeks ago}
	}.`,
	'ui.pageLastCheckedMessage.sitemap.pastDays': `XML sitemap was last checked {days, plural,
		=1 {# day ago}
		other {# days ago}
	}.`,
	'ui.pageLastCheckedMessage.sitemap.pastHours': `XML sitemap was last checked {hours, plural,
		=1 {# hour ago}
		other {# hours ago}
	}.`,
	'ui.pageLastCheckedMessage.sitemap.pastMinutes': `XML sitemap was last checked {minutes, plural,
		=1 {# minute ago}
		other {# minutes ago}
	}.`,
	'ui.pageLastCheckedMessage.sitemap.pastMonths': `XML sitemap was last checked {months, plural,
		=1 {# month ago}
		other {# months ago}
	}.`,
	'ui.pageLastCheckedMessage.sitemap.pastSeconds': `XML sitemap was last checked {seconds, plural,
		=1 {# second ago}
		other {# seconds ago}
	}.`,
	'ui.pageLastCheckedMessage.sitemap.pastWeeks': `XML sitemap was last checked {weeks, plural,
		=1 {# week ago}
		other {# weeks ago}
	}.`,
	'ui.pageUnknownLastCheckedInfo.hint': `Page is being checked right now.`,
	'ui.pageUnknownLastCheckedInfo.label': `checking now`,
	'ui.pageUnknownLastCheckedInfo.paused': `Monitoring is paused`,
	'ui.paymentOverview.method': `Selected method`,
	'ui.paymentOverview.renewInfo': `Your subscription will automatically renew on {date} and you will be charged {price}.`,
	'ui.paypal.button': `Continue to PayPal`,
	'ui.paypal.description': `You'll be redirected to PayPal to authorize the payment.`,
	'ui.paypal.failedAuthorizationError': `Unfortunately the payment authorization failed. Please try again. If the problem persists, try a different PayPal account.`,
	'ui.paypal.orderSummary': `Conductor Website Monitoring Subscription`,
	'ui.plan.billingInterval': `PAID {billingInterval, select,
		annually {ANNUALLY}
		monthly {MONTHLY}
		other {}
	}`,
	'ui.plan.contactForDetails': `Chat with us`,
	'ui.plan.contactUsButton.label': `Contact us`,
	'ui.plan.form.annuallyPaymentTerm': `Annually`,
	'ui.plan.form.monthlyPaymentTerm': `Monthly`,
	'ui.plan.form.quarterlyPaymentTerm': `Quarterly`,
	'ui.plan.monitoringPaused': `Monitoring paused`,
	'ui.plan.ofPages': `{count, plural,
		=0 {pages}
		=1 {page}
		other {pages}
	}`,
	'ui.plan.ofPlan': `of plan`,
	'ui.plan.price.startingAtPrefix': `Starting at`,
	'ui.plan.signUpButton.label': `Sign up`,
	'ui.plan.upgradeFromXLPlan.buttonText': `Contact us`,
	'ui.plan.upgradeFromXLPlan.description': `<p>You are already on the biggest plan, but we will gladly work with you to create a fitting plan.</p>`,
	'ui.plan.upgradePlan': `Upgrade plan`,
	'ui.plan.whenYouHitPlanLimit': `
		<ul>
			<li>New pages will not be added</li>
			<li>Only a part of this website will be monitored</li>
			<li>You can miss important alerts and issues</li>
		</ul>
		<p>If you expect a lot of new content soon, it may be a good idea to upgrade soon.</p>
	`,
	'ui.plan.whenYouHitPlanLimitTitle': `When you hit the limit of your plan:`,
	'ui.plan.youAreClosePlanLimit': `
		<ul>
			<li>New pages will not be added</li>
			<li>Only a part of this website will be monitored</li>
			<li>You can miss important alerts and issues</li>
		</ul>
		<p>Upgrade now to be sure you don't miss anything.</p>
	`,
	'ui.plan.youAreClosePlanLimitTitle': `You are close to your plan's limit. <br />When you hit the limit of your plan:`,
	'ui.plan.youHitPlanLimit': `
		<ul>
			<li>New pages are not being added</li>
			<li>Only a part of this website is being monitored</li>
			<li>You are missing important alerts and issues</li>
		</ul>
		<p>Upgrade now to be sure you don't miss anything.</p>
	`,
	'ui.plan.youHitPlanLimitTitle': `You are at your plan's limit. <br />You should upgrade now, because:`,
	'ui.planBenefits.benefit.adobeAnalytics': `Adobe Analytics`,
	'ui.planBenefits.benefit.alerting': `Proactive Alerting™`,
	'ui.planBenefits.benefit.alertsForCustomElements': `Alerts for Custom Elements`,
	'ui.planBenefits.benefit.auditLog': `Audit log`,
	'ui.planBenefits.benefit.changeTracking': `Change Tracking™`,
	'ui.planBenefits.benefit.chatSupport': `Chat support`,
	'ui.planBenefits.benefit.clientAccess': `Client access`,
	'ui.planBenefits.benefit.cobranding': `Co-branding`,
	'ui.planBenefits.benefit.coreWebVitalsMonitoring': `Core Web Vitals Monitoring`,
	'ui.planBenefits.benefit.customElementExtraction': `Custom Element Extraction`,
	'ui.planBenefits.benefit.customMonitoringLocation': `Custom Monitoring Location`,
	'ui.planBenefits.benefit.customTraining': `Custom training`,
	'ui.planBenefits.benefit.cwvMonitoringAndJSRendering': `JavaScript Rendering & Core Web Vitals Monitoring`,
	'ui.planBenefits.benefit.dataEnrichmentAPI': `Data Enrichment API`,
	'ui.planBenefits.benefit.dataRetention': `Data Retention`,
	'ui.planBenefits.benefit.dedicatedSuccessManager': `Dedicated Success Manager`,
	'ui.planBenefits.benefit.emailSupport': `Email support`,
	'ui.planBenefits.benefit.enforce2FA': `Enforce 2FA`,
	'ui.planBenefits.benefit.googleAnalytics': `Google Analytics`,
	'ui.planBenefits.benefit.googleChromeExtension': `Google Chrome Extension`,
	'ui.planBenefits.benefit.googleLookerStudio': `Google Looker Studio`,
	'ui.planBenefits.benefit.googleSearchConsole': `Google Search Console`,
	'ui.planBenefits.benefit.guidesAndVideos': `Guides and videos`,
	'ui.planBenefits.benefit.httpBodySnapshot': `HTTP Body Snapshots`,
	'ui.planBenefits.benefit.httpHeaderSnapshot': `HTTP Header Snapshots`,
	'ui.planBenefits.benefit.jsRendering': `JavaScript Rendering`,
	'ui.planBenefits.benefit.lightspeedMonitoring': `Lightspeed Monitoring`,
	'ui.planBenefits.benefit.logFileAnalysis': `Log File Analysis`,
	'ui.planBenefits.benefit.logFileExplorer': `Log File Explorer`,
	'ui.planBenefits.benefit.microsoftTeams': `Microsoft Teams`,
	'ui.planBenefits.benefit.monitoringFromUSUKEU': `Monitoring from US, UK, EU`,
	'ui.planBenefits.benefit.nonStopMonitoring': `24/7 Monitoring™`,
	'ui.planBenefits.benefit.organizationAdminRole': `Organization Admin role`,
	'ui.planBenefits.benefit.pageBundles': `Available URL Bundles`,
	'ui.planBenefits.benefit.phoneSupport': `Phone support`,
	'ui.planBenefits.benefit.realTimeIndexNow': `Real-time IndexNow`,
	'ui.planBenefits.benefit.rechecks': `Rechecks`,
	'ui.planBenefits.benefit.reportingApi': `Reporting API`,
	'ui.planBenefits.benefit.restrictEmailDomains': `Restrict email domains`,
	'ui.planBenefits.benefit.slack': `Slack`,
	'ui.planBenefits.benefit.users': `Users`,
	'ui.planBenefits.benefit.websiteAuditing': `Real-time Auditing™`,
	'ui.planBenefits.benefit.websites': `Websites`,
	'ui.planBenefits.enterprise.adminAndAccessControlFeatures': `Admin &{nbsp}Access Control features`,
	'ui.planBenefits.enterprise.analyticsAndGSCIntegrations': `Analytics &{nbsp}Google Search Console Integration`,
	'ui.planBenefits.enterprise.bodySnapshots': `HTTP Header &{nbsp}Body Snapshots`,
	'ui.planBenefits.enterprise.changeTrackingAndAlerting': `Change Tracking {br}&{nbsp}Pro-active Alerting`,
	'ui.planBenefits.enterprise.chatPlatformIntegrations': `Chat Platform Integrations`,
	'ui.planBenefits.enterprise.customElements': `Up to 20 Custom {br}Elements`,
	'ui.planBenefits.enterprise.customMonitoringLocation': `Custom Monitoring Location`,
	'ui.planBenefits.enterprise.CWVMonitoringAndJSRendering': `Core Web Vitals Monitoring &{nbsp}JS Rendering`,
	'ui.planBenefits.enterprise.dataRetention': `Up to 60m {br}Data Retention`,
	'ui.planBenefits.enterprise.dataStudioAndAPIs': `Data Studio &{nbsp}APIs`,
	'ui.planBenefits.enterprise.dedicatedSuccessManager': `Dedicated Success Manager`,
	'ui.planBenefits.enterprise.invoiceBilling': `Invoice billing`,
	'ui.planBenefits.enterprise.logFileAnalysis': `Log File Analysis &{nbsp}Explorer (add-on)`,
	'ui.planBenefits.enterprise.onboardingAndCustomTraining': `Onboarding &{nbsp}Custom Training`,
	'ui.planBenefits.enterprise.prioritySupport': `Priority Phone, Chat & Email Support`,
	'ui.planBenefits.enterprise.realtimeAuditing': `Real-time Auditing {br}&{nbsp}24/7 Monitoring`,
	'ui.planBenefits.enterprise.unlimitedUsersRechecks': `Unlimited Users {br}&{nbsp}Rechecks`,
	'ui.planBenefits.enterprise.unlimitedUsersWebsitesRechecks': `Unlimited Users, {br}Websites &{nbsp}Rechecks`,
	'ui.planBenefits.flags.mostPopular': `Most popular`,
	'ui.planBenefits.section.accessControl.heading': `Access Control`,
	'ui.planBenefits.section.advancedAnalysis.heading': `Advanced analysis`,
	'ui.planBenefits.section.advancedFeatures.heading': `Advanced features`,
	'ui.planBenefits.section.collaboration.heading': `Collaboration`,
	'ui.planBenefits.section.MainFeatures.benefitHints.rechecks': `Thanks to 24/7 monitoring, you get unlimited rechecks of your website completely free of charge.`,
	'ui.planBenefits.section.MainFeatures.heading': `Main features`,
	'ui.planBenefits.section.integrations.heading': `Integrations`,
	'ui.planBenefits.section.monitoringSettings.benefitHints.lightspeedMonitoring': `Monitor websites with up to 100 requests per second. This is meant for very large websites (> 5M pages).`,
	'ui.planBenefits.section.monitoringSettings.heading': `Monitoring Settings`,
	'ui.planBenefits.section.services.heading': `Services`,
	'ui.planBenefits.values.amount': `<b>{count, number}</b>`,
	'ui.planBenefits.values.addon': `add-on`,
	'ui.planBenefits.values.headersFullBodyAddon': `Headers {br}Full body (add-on)`,
	'ui.planBenefits.values.headersOnly': `Headers only`,
	'ui.planBenefits.values.monthsCount': `<b>{count, number}</b> months`,
	'ui.planBenefits.values.nonStandard': `Non-standard`,
	'ui.planBenefits.values.pageBundles': `Bundles from <b>{from, number, ::compact-short} - {to, number, ::compact-short}</b>`,
	'ui.planBenefits.values.perWebsite': `<b>{count, number}</b> per website`,
	'ui.planBenefits.values.priority': `Priority`,
	'ui.planBenefits.values.standard': `Standard`,
	'ui.planBenefits.values.unlimited': `Unlimited`,
	'ui.planBenefits.values.upToMonthsCount': `up to <b>{count, number}</b> months`,
	'ui.planBenefits.values.upToPerWebsite': `up to <b>{count, number}</b> per website`,
	'ui.planBenefits.values.upsellAmount': `<b>{base, number}</b> ($ for <b>{upsell}</b>)`,
	'ui.planBenefits.values.upsellMonthsCount': `<b>{base, number}</b> months ($ for up to <b>{upsell, number}</b> months)`,
	'ui.planBenefits.values.upsellPerWebsite': `<b>{base, number}</b> ($ for up to <b>{upsell, number}</b> per website)`,
	'ui.planBenefits.values.weeksCount': `<b>{count, number}</b> weeks`,
	'ui.plans.basic.name': `Basic`,
	'ui.plans.c2Enterprise.name': `Enterprise`,
	'ui.plans.c2Professional.name': `Professional`,
	'ui.plans.c2Starter.name': `Starter`,
	'ui.plans.enterpriseLite.name': `Enterprise Lite`,
	'ui.plans.enterprise.name': `Enterprise`,
	'ui.plans.essentials.name': `Essentials`,
	'ui.plans.pro.name': `Pro`,
	'ui.plans.standard.name': `Standard`,
	'ui.plans.unavailablePlan.accountTooLarge': `This plan is not available for an account that large.`,
	'ui.plans.unavailablePlan.disallowed': `This plan is not available for your account.`,
	'ui.platform.domains.canonical': `Canonical domain`,
	'ui.platform.domains.https': `HTTPS`,
	'ui.platform.domains.https.available': `Available`,
	'ui.platform.domains.https.notAvailable': `Not available`,
	'ui.platform.domains.title': `Domain`,
	'ui.platform.mobileNavigation.issues': `Issues`,
	'ui.platform.mobileNavigation.overview': `Overview`,
	'ui.platform.robots.bingLastVisit': `Bing last visited`,
	'ui.platform.robots.enableLogFileAnalysis': `To see when Google and Bing last visited, enable Log File Analysis for this website <linkSettings>here</linkSettings>`,
	'ui.platform.robots.googleLastVisit': `Google last visited`,
	'ui.platform.robots.lastChange': `Last change`,
	'ui.platform.robots.missing': `There is no robots.txt file for this website.`,
	'ui.platform.robots.none': `There is no robots.txt file for this website and we have no record that there ever was one.`,
	'ui.platform.robots.sitemapReference': `XML Sitemap reference`,
	'ui.platform.robots.title': `Robots.txt`,
	'ui.platform.robots.url': `URL`,
	'ui.platform.robots.viewer.button': `View Robots.txt`,
	'ui.platform.robotsTxtViewer.lastChecked.hint.paused': `Monitoring is paused. {pastRelativeText}`,
	'ui.platform.score.label': `Platform health`,
	'ui.platform.sitemaps.indexes': `Sitemap Indexes`,
	'ui.platform.sitemaps.sitemaps': `Sitemaps`,
	'ui.platform.sitemaps.sitemapUrls': `Sitemap URLs`,
	'ui.platform.sitemaps.title': `XML Sitemaps`,
	'ui.platform.sitemaps.viewer.sitemaps.button': `View XML Sitemaps`,
	'ui.platform.sitemaps.viewer.sitemaps.button.mobile': `XML Sitemaps`,
	'ui.platform.sitemapsViewer.tab.sitemaps': `XML Sitemaps`,
	'ui.platform.sitemapsViewer.tab.urls': `Sitemap URLs`,
	'ui.platform.sitemapUrlsViewer.changeFrequency.value.daily': `Daily`,
	'ui.platform.sitemapUrlsViewer.changeFrequency.value.invalid': `Invalid`,
	'ui.platform.sitemapUrlsViewer.changeFrequency.value.monthly': `Monthly`,
	'ui.platform.sitemapUrlsViewer.changeFrequency.value.weekly': `Weekly`,
	'ui.platform.sitemapUrlsViewer.tableHeading.changeFrequency': `Change frequency`,
	'ui.platform.sitemapUrlsViewer.tableHeading.lastModified': `Last modified`,
	'ui.platform.sitemapUrlsViewer.tableHeading.priority': `Priority`,
	'ui.platform.sitemapUrlsViewer.tableHeading.sitemap': `Sitemap`,
	'ui.platform.sitemapUrlsViewer.tableHeading.url': `URL`,
	'ui.platform.sitemapUrlsViewer.title': `Sitemap URLs`,
	'ui.platform.title': `Platform`,
	'ui.platform.webVitalsOriginSummary.cannotBeAssessedTooltip': `There is insufficient data for the required Core Web Vitals.`,
	'ui.platform.webVitalsOriginSummary.coreWebVitalsAssessment': `Core Web Vitals assessment`,
	'ui.platform.webVitalsOriginSummary.coreWebVitalsAssessment.verdict.cannotBeAssessed': `Cannot be assessed`,
	'ui.platform.webVitalsOriginSummary.coreWebVitalsAssessment.verdict.fail': `Does not pass`,
	'ui.platform.webVitalsOriginSummary.coreWebVitalsAssessment.verdict.pass': `Passes`,
	'ui.platform.webVitalsOriginSummary.insufficientData': `{webVital, select,
		cls {There is insufficient Cumulative Layout Shift (CLS) data for pages on this website, and this web vital is required for the Core Web Vitals assessment.}
		fcp {There is insufficient First Contentful Paint (FCP) data for pages on this website.}
		fid {There is insufficient First Input Delay (FID) data for pages on this website, but this web vital is optional for the Core Web Vitals assessment.}
		lcp {There is insufficient Largest Contentful Paint (LCP) data for pages on this website, and this web vital is required for the Core Web Vitals assessment.}
		other {}
	}`,
	'ui.platform.webVitalsOriginSummary.noOriginDataAvailable': `Real-world speed data for this origin is not available in the Chrome User Experience Report. Learn more in <linkArticle>our support article</linkArticle>.`,
	'ui.platform.webVitalsOriginSummary.title': `Web Vitals Origin Summary`,
	'ui.platformIssueDetail.domain.columns.exampleUrl': `Example URL`,
	'ui.platformIssueDetail.domain.columns.response': `Response`,
	'ui.platformIssueDetail.invalidHttpsCertificate.domainVariant': `Domain variant`,
	'ui.platformIssueDetail.invalidHttpsCertificate.error.label': `Error`,
	'ui.platformIssueDetail.invalidHttpsCertificate.error.matchNone': `SSL certificate is not matching this website.`,
	'ui.platformIssueDetail.invalidHttpsCertificate.error.statusExpired': `SSL certificate expired.`,
	'ui.platformIssueDetail.invalidHttpsCertificate.error.statusNotValidYet': `SSL certificate is not valid yet.`,
	'ui.platformIssueDetail.invalidHttpsCertificate.property.commonName': `Common Name`,
	'ui.platformIssueDetail.invalidHttpsCertificate.property.issuerName': `Issuer Name`,
	'ui.platformIssueDetail.invalidHttpsCertificate.property.validFrom': `Valid From`,
	'ui.platformIssueDetail.invalidHttpsCertificate.property.validUntil': `Expiry Date`,
	'ui.platformIssueDetail.robotsTxt.columns.directive': `Directive`,
	'ui.platformIssueDetail.robotsTxt.columns.error': `Error`,
	'ui.platformIssueDetail.robotsTxt.columns.line': `Line`,
	'ui.platformIssueDetail.robotsTxt.lineErrors.cantMatchAnything': `Directive can't match anything.`,
	'ui.platformIssueDetail.robotsTxt.lineErrors.illegalCharacters': `Line contains illegal characters.`,
	'ui.platformIssueDetail.robotsTxt.lineErrors.invalidFormat': `Directive has invalid format.`,
	'ui.platformIssueDetail.robotsTxtInaccessible.unreachable': `Robots.txt is unreachable ({reason})`,
	'ui.premiumTrialBar.button': `Upgrade now`,
	'ui.pricing.addWebsiteButton': `Add a website`,
	'ui.pricing.addWebsiteWarning': `Please add a website before signing up.`,
	'ui.pricing.agency.benefits.ACL': `{enabled, select,
		no {No individual client access}
		yes {Individual client access}
		other {}
	}`,
	'ui.pricing.agency.benefits.adobeAnalyticsIntegration': `{enabled, select,
		no {No Adobe Analytics integration}
		yes {Adobe Analytics integration}
		other {}
	}`,
	'ui.pricing.agency.benefits.coreWebVitalsOriginSummary': `{enabled, select,
		no {No Core Web Vitals Origin Summary}
		yes {Core Web Vitals Origin Summary}
		other {}
	}`,
	'ui.pricing.agency.benefits.customAlertTrigger': `{enabled, select,
		no {No Alerts for Custom Elements}
		yes {Alerts for Custom Elements}
		other {}
	}`,
	'ui.pricing.agency.benefits.customElements': `Custom Element Extraction: {number, plural,
		=0 {not included}
		other {# per website}
	}`,
	'ui.pricing.agency.benefits.customLogo': `Customize with your own logo`,
	'ui.pricing.agency.benefits.dataRetention': `Data retention: {months, number} months`,
	'ui.pricing.agency.benefits.googleLookerStudio': `{enabled, select,
		no {No Google Looker Studio}
		yes {Google Looker Studio}
		other {}
	}`,
	'ui.pricing.agency.benefits.issuesConfiguration': `{enabled, select,
		no {No Issue Configuration}
		yes {Issue Configuration}
		other {}
	}`,
	'ui.pricing.agency.benefits.lighthouseMonitoring': `{enabled, select,
		no {No Core Web Vitals Lighthouse Monitoring}
		yes {Core Web Vitals Lighthouse Monitoring}
		other {}
	}`,
	'ui.pricing.agency.benefits.logFileAnalysis': `{enabled, select,
		no {No Log File Analysis}
		yes {Log File Analysis}
		other {}
	}`,
	'ui.pricing.agency.benefits.microsoftTeams': `{enabled, select,
		no {No Microsoft Teams}
		yes {Microsoft Teams}
		other {}
	}`,
	'ui.pricing.agency.benefits.realtimeIndexNow': `{enabled, select,
		no {No Real-time IndexNow}
		yes {Real-time IndexNow}
		other {}
	}`,
	'ui.pricing.agency.benefits.rendering': `{enabled, select,
		no {No Javascript Rendering}
		yes {Javascript Rendering}
		other {}
	}`,
	'ui.pricing.agency.benefits.reportingAPI': `{enabled, select,
		no {No Reporting API}
		yes {Reporting API}
		other {}
	}`,
	'ui.pricing.agency.benefits.unlimitedUsers': `Users: unlimited`,
	'ui.pricing.agency.benefits.unlimitedWebsites': `Websites: unlimited`,
	'ui.pricing.agency.benefitTooltips.ACL.content': `This feature allows you to limit user access to specific websites. Read more about this in {text__link}.`,
	'ui.pricing.agency.benefitTooltips.ACL.href': `https://www.contentkingapp.com/support/account-users/`,
	'ui.pricing.agency.benefitTooltips.ACL.link': `our support article`,
	'ui.pricing.agency.benefitTooltips.issuesConfiguration.content': `Issue Configuration gives you full control over the auditing parameters. Read more about this in {text__link}.`,
	'ui.pricing.agency.benefitTooltips.issuesConfiguration.href': `https://www.contentkingapp.com/support/issue-configuration/`,
	'ui.pricing.agency.benefitTooltips.issuesConfiguration.link': `our support article`,
	'ui.pricing.agency.benefitTooltips.reportingAPI.content': `Use the Reporting API to extract Conductor Website Monitoring metrics for integrations with other tooling. Read more about this in {text__link}.`,
	'ui.pricing.agency.benefitTooltips.reportingAPI.href': `https://www.contentkingapp.com/support/reporting-api/`,
	'ui.pricing.agency.benefitTooltips.reportingAPI.link': `our support article`,
	'ui.pricing.agency.planUnavailabilityReasons.issuesConfiguration': `Some of your issues have a custom configuration applied, which requires the Pro plan.`,
	'ui.pricing.benefits.adjustPlan.body': `You're fully in control. Make changes to your subscription whenever you want.`,
	'ui.pricing.benefits.adjustPlan.title': `Adjust your plan anytime{nbsp}{hint}`,
	'ui.pricing.benefits.moneyBack.body': `After your first payment, you'll still have 30 days to cancel and request a full refund.`,
	'ui.pricing.benefits.moneyBack.title': `30-day money back guarantee{nbsp}{hint}`,
	'ui.pricing.signUp.back': `Back to Billing details`,
	'ui.pricing.signUp.backToCreditCardDetails': `Back to Credit card details`,
	'ui.pricing.signUp.confirm': `Confirm payment`,
	'ui.pricing.signUp.error.vat.countryCode.missing': `Country code missing from VAT number`,
	'ui.pricing.signUp.error.vat.countryCode.notMatching': `VAT number not valid for selected country`,
	'ui.pricing.signUp.error.vat.invalid': `VAT number not valid`,
	'ui.profileCompleteness.completed.description': `You have completed all tasks and your account is now fully set up.`,
	'ui.profileCompleteness.completed.title': `{name, select,
		null {Congratulations! 🎉}
		other {Congratulations, {name}! 🎉}
	}`,
	'ui.profileCompleteness.task.addSecondWebsite': `Add a second website`,
	'ui.profileCompleteness.task.connectGoogleAnalytics': `Connect Google Analytics`,
	'ui.profileCompleteness.task.connectGoogleLookerStudio': `Connect Google Looker Studio`,
	'ui.profileCompleteness.task.connectGoogleSearchConsole': `Connect Google Search Console`,
	'ui.profileCompleteness.task.connectSlack': `Connect Slack or Microsoft Teams`,
	'ui.profileCompleteness.task.installChromeExtension': `Install our Chrome extension`,
	'ui.profileCompleteness.task.inviteSecondUser': `Invite a second member to the team`,
	'ui.profileCompleteness.task.inviteThirdUser': `Invite a third member to the team`,
	'ui.profileCompleteness.task.setAccountName': `Set the account name`,
	'ui.profileCompleteness.title': `{name, select,
		null {Hi, {br}let's get your account set up!}
		other {Hi {name}, {br}let's get your account set up!}
	}`,
	'ui.protectUnsavedChanges.description': `You have unsaved changes, which will be lost if you leave now.`,
	'ui.protectUnsavedChanges.discardAndExit': `Discard changes`,
	'ui.protectUnsavedChanges.saveAndExit': `Save`,
	'ui.protectUnsavedChanges.stayOnPage': `continue editing`,
	'ui.protectUnsavedChanges.title': `Unsaved changes`,
	'ui.pruneOrphansModal.description': `<p>Orphaned URLs are pages without any incoming relation. If you purge these they will no longer be monitored, but if Conductor Website Monitoring finds new relations to these pages they will be added and monitored again.</p>`,
	'ui.pruneOrphansModal.submitButton': `Purge orphans`,
	'ui.pruneOrphansModal.title': `Purge orphans on <b>{text__websiteName}</b>`,
	'ui.pruneOrphansModal.warnings.data': `Orphaned URLs and their data will be purged`,
	'ui.pruneOrphansModal.warnings.monitoring': `Orphaned URLs will no longer be monitored`,
	'ui.reactivateAccountModal.addWebsite': `Add a website`,
	'ui.reactivateAccountModal.clickToLogout': `Click here to {logout}`,
	'ui.reactivateAccountModal.contactUs': `Contact us`,
	'ui.reactivateAccountModal.content': `
		<p>Long time - no see. We would love to show you how much better Conductor Website Monitoring became since you were around.</p>
		<p>Click the button below to restart your trial.</p>
	`,
	'ui.reactivateAccountModal.contentGreeting': `<p>Hi {firstName},</p>`,
	'ui.reactivateAccountModal.greeting': `<p>Hi {firstName},</p>`,
	'ui.reactivateAccountModal.oneMoreThing': `<p>Just one more thing before you get started:</p>`,
	'ui.reactivateAccountModal.title': `Welcome back!`,
	'ui.recurlyErrors.cardDeclined': `Card was declined.`,
	'ui.recurlyErrors.cardTypeNotAccepted': `Card was declined.`,
	'ui.recurlyErrors.connectionFailed': `Connection to Recurly service failed. Please try again later.`,
	'ui.recurlyErrors.insufficientFunds': `Your card was declined due to insufficient funds.`,
	'ui.recurlyErrors.invalidBillingDetails': `Billing details token is either invalid or expired.`,
	'ui.recurlyErrors.invalidRequest': `Data provided to Recurly are invalid.`,
	'ui.recurlyErrors.serverDown': `Connection to Recurly service failed because their servers are not available. Please try again later.`,
	'ui.recurlyErrors.transactionFailed': `Transaction couldn't be completed due to unknown reason.`,
	'ui.recurlyErrors.unknownProblem': `Request couldn't be completed due to unknown reason. Please try again later.`,
	'ui.reevaluationOverlay.message': `the segment you're filtering on is currently being recalculated.`,
	'ui.reevaluationOverlay.title': `Just a moment,`,
	'ui.relativeDate.today': `Today`,
	'ui.relativeDate.yesterday': `Yesterday`,
	'ui.relativeTimeFormatter.future': `{distance} <suffix>from now</suffix>`,
	'ui.relativeTimeFormatter.past': `{distance} <suffix>ago</suffix>`,
	'ui.remainingTime.default': `{remainingTime} left`,
	'ui.remainingTime.noMoreTime': `no more time`,
	'ui.remainingTime.premiumTrial': `<b>{remainingTime}</b> remaining on {plan, select,
		unknown {premium trial}
		other {{plan} plan trial}
	}`,
	'ui.remainingTime.trial': `<b>{remainingTime}</b> left in free trial`,
	'ui.removeAccessToConnectedAccountConfirmation.confirmButton': `Remove access`,
	'ui.removeAccessToConnectedAccountConfirmation.description': `By removing access you and your colleagues will not be able to access the connected account anymore, unless you're re-invited.`,
	'ui.removeAccessToConnectedAccountConfirmation.labelConnectedAccount': `Connected account`,
	'ui.removeAccessToConnectedAccountConfirmation.title': `access to connected account`,
	'ui.removeAccessToConnectedAccountConfirmation.verb': `Remove`,
	'ui.removeAccessToConnectedAccountConfirmation.warnPermanent': `This action will <b>permanently remove access</b> to the following connected account:`,
	'ui.removeTeamMemberConfirmation.confirmButton': `Remove team member`,
	'ui.removeTeamMemberConfirmation.errorAlreadyRemoved': `{signature} has already been removed from the team.`,
	'ui.removeTeamMemberConfirmation.errorLastTeamMember': `It's not possible to remove {signature} because they are the last remaining member in the team.`,
	'ui.removeTeamMemberConfirmation.labelEmail': `Email address`,
	'ui.removeTeamMemberConfirmation.labelFromAccount': `From account`,
	'ui.removeTeamMemberConfirmation.labelName': `Name`,
	'ui.removeTeamMemberConfirmation.labelTeamMember': `Team member`,
	'ui.removeTeamMemberConfirmation.title': `team member`,
	'ui.removeTeamMemberConfirmation.verb': `Remove`,
	'ui.removeTeamMemberConfirmation.warnPermanent': `This action will <b>permanently remove</b> the team member:`,
	'ui.removeWebsiteConfirmation.confirmButton': `Remove website`,
	'ui.removeWebsiteConfirmation.description': `Removing this website will delete all the data we have collected about it, including tracked changes. Conductor Website Monitoring will stop monitoring it and you will no longer receive any reports or alerts for this website.`,
	'ui.removeWebsiteConfirmation.labelDomain': `Domain`,
	'ui.removeWebsiteConfirmation.labelFromAccount': `From account`,
	'ui.removeWebsiteConfirmation.labelWebsiteName': `Website name`,
	'ui.removeWebsiteConfirmation.title': `website`,
	'ui.removeWebsiteConfirmation.verb': `Remove`,
	'ui.removeWebsiteConfirmation.warnPermanent': `This action will <b>permanently remove</b> the website:`,
	'ui.removeWebsiteConfirmation.yourAccount': `Your account`,
	'ui.requestPasswordResetForm.backToLogin': `Back to login`,
	'ui.requestPasswordResetForm.email': `Email address`,
	'ui.requestPasswordResetForm.errors.invalidToken': `Link has expired. Please try to reset your password again.`,
	'ui.requestPasswordResetForm.errors.unknownEmail': `This email is not known.`,
	'ui.requestPasswordResetForm.submit': `Reset password`,
	'ui.requestPasswordResetForm.title': `Forgot your password?`,
	'ui.resetPassword.continueToLoginLink': `Continue to login`,
	'ui.resetPassword.successTitle': `Your password has been set`,
	'ui.resetPasswordForm.backToLogin': `Back to login`,
	'ui.resetPasswordForm.errors.expiredToken': `Link from email already expired. Please request password reset again.`,
	'ui.resetPasswordForm.errors.invalidToken': `Link from email already expired. Please request password reset again.`,
	'ui.resetPasswordForm.password': `Password`,
	'ui.resetPasswordForm.submit': `Set new password`,
	'ui.resetPasswordForm.title': `Set your new password`,
	'ui.resumeSubscriptionRenewal.button': `Resume subscription`,
	'ui.resumeSubscriptionRenewal.modal.button': `Resume subscription`,
	'ui.resumeSubscriptionRenewal.modal.content': `This action will automatically renew this account's subscription after your current billing period ends, ensuring you can continue using this account and won't lose access to any of its data.`,
	'ui.resumeSubscriptionRenewal.modal.title': `Resume subscription`,
	'ui.revokeAccessForConnectedAccountConfirmation.confirmButton': `Revoke access`,
	'ui.revokeAccessForConnectedAccountConfirmation.description': `By revoking access for the connected account, the team in that account will not be able to access your account anymore, unless you invite them again.`,
	'ui.revokeAccessForConnectedAccountConfirmation.labelConnectedAccount': `Connected account`,
	'ui.revokeAccessForConnectedAccountConfirmation.title': `access for connected account`,
	'ui.revokeAccessForConnectedAccountConfirmation.verb': `Revoke`,
	'ui.revokeAccessForConnectedAccountConfirmation.warnPermanent': `This action will <b>permanently revoke access</b> for the following connected account:`,
	'ui.robotDirectives.directive': `Directive`,
	'ui.robotDirectives.method': `Method`,
	'ui.robotDirectives.searchEngine': `Search Engine`,
	'ui.robotsTxtComparisonAnnotation.contentsRemoved': `robots.txt file contents were removed.`,
	'ui.robotsTxtComparisonAnnotation.empty': `robots.txt was not available in either of these versions.`,
	'ui.robotsTxtComparisonAnnotation.emptyBecause': `The first version {previousFailureReason, select,
		redirect_client {was redirected ({previousStatusCode})}
		empty_response {was removed ({previousStatusCode})}
		time_limit {was timing out}
		unavailable {was returning a server error ({previousStatusCode})}
		other {was returning ({previousStatusCode})}
	} and the second version {nextReason, select,
		redirect_client {was redirected ({nextStatusCode})}
		empty_response {was removed ({nextStatusCode})}
		time_limit {was timing out}
		unavailable {was returning a server error ({nextStatusCode})}
		other {was returning ({nextStatusCode})}
	}.`,
	'ui.robotsTxtComparisonAnnotation.redirected': `robots.txt was redirected ({statusCode}).`,
	'ui.robotsTxtComparisonAnnotation.removed': `robots.txt was removed ({statusCode}).`,
	'ui.robotsTxtComparisonAnnotation.restored': `robots.txt became available again.`,
	'ui.robotsTxtComparisonAnnotation.same': `These two versions of the robots.txt file are identical.`,
	'ui.robotsTxtComparisonAnnotation.timeout': `robots.txt began timing out.`,
	'ui.robotsTxtComparisonAnnotation.unavailable': `robots.txt began returning a server error ({statusCode}).`,
	'ui.robotsTxtComparisonHeader.comparingVersions': `Comparing versions`,
	'ui.robotsTxtComparisonHeader.title': `Robots.txt`,
	'ui.robotsTxtComparisonHeader.trackedChanges': `Tracked changes`,
	'ui.robotsTxtComparisonSidebar.liveVersion': `Live version`,
	'ui.robotsTxtStatusMessage.empty.short': `empty`,
	'ui.robotsTxtStatusMessage.empty.title': `robots.txt file {latestRevision, select,
		yes {is}
		other {was}
	} empty.`,
	'ui.robotsTxtStatusMessage.missing.short': `missing {statusCode}`,
	'ui.robotsTxtStatusMessage.missing.title': `robots.txt {latestRevision, select,
		yes {is}
		other {was}
	} missing ({statusCode}).`,
	'ui.robotsTxtStatusMessage.redirected.short': `redirected {statusCode}`,
	'ui.robotsTxtStatusMessage.redirected.title': `robots.txt {latestRevision, select,
		yes {is being}
		other {was}
	} redirected ({statusCode}).`,
	'ui.robotsTxtStatusMessage.timeout.short': `time-out`,
	'ui.robotsTxtStatusMessage.timeout.title': `robots.txt {latestRevision, select,
		yes {is}
		other {was}
	} timing-out.`,
	'ui.robotsTxtStatusMessage.unavailable.short': `server error {statusCode}`,
	'ui.robotsTxtStatusMessage.unavailable.title': `Robots.txt {latestRevision, select,
		yes {is}
		other {was}
	} returning a server error ({statusCode}).`,
	'ui.robotsTxtViewerHeader.backLink': `Platform`,
	'ui.robotsTxtViewerHeader.compareLatest': `Compare to latest`,
	'ui.robotsTxtViewerHeader.compareLatestVersion': `Compare to latest version`,
	'ui.robotsTxtViewerHeader.compareNextVersion': `Compare to next version`,
	'ui.robotsTxtViewerHeader.comparePrevious': `Compare to previous`,
	'ui.robotsTxtViewerHeader.comparePreviousVersion': `Compare to previous version`,
	'ui.robotsTxtViewerHeader.comparisonUnavailable': `Comparing different robots.txt versions will be available once there are at least two robots.txt versions tracked.`,
	'ui.robotsTxtViewerHeader.downloadUnavailable': `There's neither response body nor HTTP headers tracked for this robots.txt version.`,
	'ui.robotsTxtViewerHeader.liveVersion': `Live version`,
	'ui.robotsTxtViewerHeader.openOnWebsite': `Open on website`,
	'ui.robotsTxtViewerHeader.revisionsAgo': `{revisionsAgo, plural,
		one {1 version}
		other {# versions}
	} ago`,
	'ui.robotsTxtViewerHeader.title': `Robots.txt`,
	'ui.robotsTxtViewerScreen.headers': `Headers`,
	'ui.robotsTxtViewerScreen.headersUnavailable': `Headers are only available for requests made after this feature was introduced.`,
	'ui.robotsTxtViewerScreen.responseBody': `Response body`,
	'ui.robotsTxtViewSidebar.liveVersion': `Live version`,
	'ui.roles.rights.accessWebsites': `Access websites`,
	'ui.roles.rights.addAndRemoveWebsites': `Add and remove websites`,
	'ui.roles.rights.manageBillingSettings': `Manage billing settings`,
	'ui.roles.rights.manageTeamMembers': `Manage team members`,
	'ui.roles.rights.manageWebsiteSettings': `Manage website settings`,
	'ui.roles.rights.organizationAccessControl': `Organization access control`,
	'ui.roles.rights.receiveAlertsAndReports': `Receive alerts & reports`,
	'ui.schemaOrg.invalidElements': `This page contains {count, plural,
		one {# schema.org script element}
		other {# schema.org script elements}
	} with invalid JSON-LD script.`,
	'ui.schemaOrgErrors.element': `Element`,
	'ui.schemaOrgErrors.error': `Error`,
	'ui.schemaOrgErrors.path': `Path`,
	'ui.schemaOrgInvalidJson.tableCell': `Schema script #{position}`,
	'ui.schemaOrgInvalidJson.tableHeader': `Schema script elements with invalid JSON-LD`,
	'ui.scopes.multiselectField.label': `Scope ({numberOfSelectedScopes, number}/{numberOfAllScopes, number})`,
	'ui.scopes.multiselectField.label.placeholder': `Scope`,
	'ui.scopes.website.description': `This is the entire website.`,
	'ui.scopes.website.title': `Website`,
	'ui.screen.alertPages.columns.nonindexability_reason': `Non-indexability reason`,
	'ui.screen.alertPages.columns.status.added': `Added`,
	'ui.screen.alertPages.columns.status.changed': `Changed`,
	'ui.screen.alertPages.columns.status.changedToFalse': `Changed to No`,
	'ui.screen.alertPages.columns.status.changedToTrue': `Changed to Yes`,
	'ui.screen.alertPages.columns.status.decreased': `Decreased`,
	'ui.screen.alertPages.columns.status.entered': `Entered`,
	'ui.screen.alertPages.columns.status.ignored': `Ignored`,
	'ui.screen.alertPages.columns.status.increased': `Increased`,
	'ui.screen.alertPages.columns.status.left': `Left`,
	'ui.screen.alertPages.columns.status.movedBackward': `Moved backward`,
	'ui.screen.alertPages.columns.status.movedForward': `Moved forward`,
	'ui.screen.alertPages.columns.status.noLongerMatchingCriteria': `No longer matching criteria`,
	'ui.screen.alertPages.columns.status.notPage': `No longer a page`,
	'ui.screen.alertPages.columns.status.notRequired': `Not required`,
	'ui.screen.alertPages.columns.status.open': `Open`,
	'ui.screen.alertPages.columns.status.overQuota': `Over quota`,
	'ui.screen.alertPages.columns.status.removed': `Removed`,
	'ui.screen.alertPages.columns.status.resolved': `Resolved`,
	'ui.screen.alertPages.columns.status.restored': `Restored`,
	'ui.screen.alertPages.columns.status.reverted': `Reverted`,
	'ui.screen.alertPages.columns.status.unknown': `Unknown`,
	'ui.screen.pagesGraphs.content.available': `Available`,
	'ui.screen.pagesGraphs.content.empty': `Empty`,
	'ui.screen.pagesGraphs.content.missing': `Missing`,
	'ui.screen.pagesGraphs.webVitals.good': `Good`,
	'ui.screen.pagesGraphs.webVitals.needsImprovement': `Needs improvement`,
	'ui.screen.pagesGraphs.webVitals.poor': `Poor`,
	'ui.screen.sitemapsList.cells.items.inSitemap': `{count__items, plural,
		=0 {# URLs}
		=1 {# URL}
		other {# URLs}
	}`,
	'ui.screen.sitemapsList.cells.items.inSitemapIndex': `{count__items, plural,
		=0 {# sitemaps}
		=1 {# sitemap}
		other {# sitemaps}
	}`,
	'ui.screen.sitemapsList.cells.lastChecked.checkingNow': `checking now`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.cannotRecheck': `Sitemaps are automatically checked every hour. As they are harder to process you can only request a manual re-check once per hour.`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.canRecheck': `Sitemaps are automatically checked every hour. Click to initiate an immediate check.`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.checkingNow': `Sitemap is being checked right now.`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.pastDays': `XML sitemap was last checked {days, plural,
		=1 {# day ago}
		other {# days ago}
	}.`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.pastHours': `XML sitemap was last checked {hours, plural,
		=1 {# hour ago}
		other {# hours ago}
	}.`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.pastMinutes': `XML sitemap was last checked {minutes, plural,
		=1 {# minute ago}
		other {# minutes ago}
	}.`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.pastMonths': `XML sitemap was last checked {months, plural,
		=1 {# month ago}
		other {# months ago}
	}.`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.pastSeconds': `XML sitemap was last checked {seconds, plural,
		=1 {# second ago}
		other {# seconds ago}
	}.`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.pastWeeks': `XML sitemap was last checked {weeks, plural,
		=1 {# week ago}
		other {# weeks ago}
	}.`,
	'ui.screen.sitemapsList.cells.lastChecked.hint.paused': `Monitoring is paused. {pastRelativeText}`,
	'ui.screen.sitemapsList.cells.lastChecked.notCheckedYet': `not checked yet`,
	'ui.screen.sitemapsList.cells.reference.defaultLocation': `Default location`,
	'ui.screen.sitemapsList.cells.reference.googleSearchConsole': `Google Search Console`,
	'ui.screen.sitemapsList.cells.reference.missing': `Missing`,
	'ui.screen.sitemapsList.cells.reference.robotsTxt': `Robots.txt`,
	'ui.screen.sitemapsList.cells.reference.sitemapIndex': `Sitemap Index`,
	'ui.screen.sitemapsList.cells.status.invalid': `Invalid`,
	'ui.screen.sitemapsList.cells.status.missing': `Missing`,
	'ui.screen.sitemapsList.cells.status.redirected': `Redirected`,
	'ui.screen.sitemapsList.cells.status.serverError': `Server Error`,
	'ui.screen.sitemapsList.cells.status.timeout': `Time-out`,
	'ui.screen.sitemapsList.cells.status.valid': `Valid`,
	'ui.screen.sitemapsList.cells.type.index': `index`,
	'ui.screen.sitemapsList.cells.type.sitemap': `sitemap`,
	'ui.screen.sitemapsList.headings.items.label': `Items`,
	'ui.screen.sitemapsList.headings.lastChecked.label': `Last checked`,
	'ui.screen.sitemapsList.headings.lastChecked.tooltip': `Sitemap is automatically checked every hour. Click to initiate an immediate check.`,
	'ui.screen.sitemapsList.headings.lastModified.label': `Last modified`,
	'ui.screen.sitemapsList.headings.reference.label': `Reference`,
	'ui.screen.sitemapsList.headings.sitemap.label': `Sitemap`,
	'ui.screen.sitemapsList.headings.size.label': `Filesize`,
	'ui.screen.sitemapsList.headings.size.value': `{size, number} MB`,
	'ui.screen.sitemapsList.headings.status.label': `Status`,
	'ui.screen.sitemapsList.headings.type.label': `Type`,
	'ui.screen.sitemapsList.title': `XML Sitemaps`,
	'ui.searchEngineActivity.bingDesktop': `Bing <muted>desktop</muted>`,
	'ui.searchEngineActivity.bingMobile': `Bing <muted>mobile</muted>`,
	'ui.searchEngineActivity.frequency': `Frequency`,
	'ui.searchEngineActivity.googleDesktop': `Google <muted>desktop</muted>`,
	'ui.searchEngineActivity.googleMobile': `Google <muted>mobile</muted>`,
	'ui.searchEngineActivity.lastVisited': `Last visited`,
	'ui.searchEngineActivity.searchEngine': `Search engine`,
	'ui.searchEngineActivityBadges.nudge': `
		With our Log File Analysis feature, you can see whether Google and Bing visited after any change.
		{br}{br}
		Enable Log File Analysis for this website <linkSettings>here</linkSettings>.
	`,
	'ui.searchEngineActivityBadges.seen': `
		{status, select,
			featureNotEnabled {Log File Analysis wasn't enabled at the time of this change.}
			visitedSince {Seen by {searchEngine, select, bing {Bing} google {Google} other {}}}
			notVisitedSince {Not seen by {searchEngine, select, bing {Bing} google {Google} other {}}}
			other {}
		}
	`,
	'ui.searchEngineActivityBadges.upsellAnnotation': `With our Log File Analysis feature, you can see whether Google and Bing visited after any change.`,
	'ui.searchEngineActivityBadges.visited': `
		{status, select,
			featureNotEnabled {Log File Analysis wasn't enabled at the time of this change.}
			visitedSince {{searchEngine, select, bing {Bing} google {Google} other {}} has visited since this change was made.}
			notVisitedSince {{searchEngine, select, bing {Bing} google {Google} other {}} hasn't visited since Conductor Website Monitoring tracked this change.}
			other {}
		}
	`,
	'ui.searchEngines.baidu': `Baidu`,
	'ui.searchEngines.bing': `Bing`,
	'ui.searchEngines.duckDuckGo': `DuckDuckGo`,
	'ui.searchEngines.google': `Google`,
	'ui.searchEngines.seznam': `Seznam`,
	'ui.searchEngines.yahoo': `Yahoo`,
	'ui.searchEngines.yandex': `Yandex`,
	'ui.segments.editor.addColumn.confirmButton': `Add this column`,
	'ui.segments.editor.addColumn.title': `+ Add column`,
	'ui.segments.editor.exit': `Exit editor`,
	'ui.segments.editor.filterBox.addCriteria': `Add`,
	'ui.segments.editor.filterBox.addFirstCriteria': `Add criteria`,
	'ui.segments.editor.filterBox.empty': `No filter criteria has been set yet.`,
	'ui.segments.editor.filterBox.staticSegmentDescription': `Static list of URLs`,
	'ui.segments.editor.filterBox.title': `Filter criteria`,
	'ui.segments.editor.heading.create': `Creating`,
	'ui.segments.editor.heading.edit': `Editing`,
	'ui.segments.editor.identifierBox.changeLabel': `Change`,
	'ui.segments.editor.identifierBox.setLabel': `Set label`,
	'ui.segments.editor.identifierBox.title': `Label`,
	'ui.segments.editor.identifierModal.button.create': `Set label`,
	'ui.segments.editor.identifierModal.button.edit': `Change label`,
	'ui.segments.editor.identifierModal.title': `Choose label`,
	'ui.segments.editor.placeholderLabel': `New Segment`,
	'ui.segments.editor.removeColumn.default': `This will hide the column.`,
	'ui.segments.editor.removeColumn.withFilter': `This will unset the filter and hide the column.`,
	'ui.segments.editor.removeColumn.withFilterAndSizeLimit': `This will unset the filter and size limit, and hide the column.`,
	'ui.segments.editor.removeColumn.withSizeLimit': `This will unset the size limit and hide the column.`,
	'ui.segments.editor.sidebar.button.create': `Save segment`,
	'ui.segments.editor.sidebar.button.edit': `Save changes`,
	'ui.segments.editor.sidebar.title': `Segment settings`,
	'ui.segments.editor.sizeLimitBox.changeLimit': `Change`,
	'ui.segments.editor.sizeLimitBox.empty': `No size limit has been set.`,
	'ui.segments.editor.sizeLimitBox.removeLimit': `Remove`,
	'ui.segments.editor.sizeLimitBox.removeTooltip': `This will remove the size limit for this segment.`,
	'ui.segments.editor.sizeLimitBox.setLimit': `Set limit`,
	'ui.segments.editor.sizeLimitBox.title': `Size limit`,
	'ui.segments.editor.sizeLimitBox.withoutLimit': `{numberOfPages, plural,
		=0 {(without size limit: 0 pages)}
		=1 {(without size limit: # page)}
		other {(without size limit: # pages)}
	}`,
	'ui.segments.editor.sizeLimitModal.button.create': `Set size limit`,
	'ui.segments.editor.sizeLimitModal.button.edit': `Change size limit`,
	'ui.segments.editor.sizeLimitModal.field.disabledColumn': `Limiting the segment size on this column is not supported.`,
	'ui.segments.editor.sizeLimitModal.field.label': `Column to sort on`,
	'ui.segments.editor.sizeLimitModal.field.placeholder': `Choose`,
	'ui.segments.editor.sizeLimitModal.numberOfPages.invalid': `Choose a whole number greater than 0.`,
	'ui.segments.editor.sizeLimitModal.numberOfPages.label': `Number of pages`,
	'ui.segments.editor.sizeLimitModal.order.asc': `Ascending`,
	'ui.segments.editor.sizeLimitModal.order.desc': `Descending`,
	'ui.segments.editor.sizeLimitModal.percentage.invalid': `Choose a whole number between 1 and 99.`,
	'ui.segments.editor.sizeLimitModal.percentage.label': `Percentage of pages`,
	'ui.segments.editor.sizeLimitModal.percentage.tooltip': `{totalNumberOfPages, plural,
		=1 {Currently {limitedNumberOfPages} out of # page.}
		other {Currently {limitedNumberOfPages} out of # pages.}
	}`,
	'ui.segments.editor.sizeLimitModal.sidebar': `
		<p>Limiting the segment size will set the maximum number of pages that the segment will contain.</p>
		<p>Choose the column on which the pages will be sorted, the sorting order, and set the size limit.</p>
		<p>Example: for a segment with <i>Top 5% most visited pages</i>, choose <i>Pageviews</i> as the column to sort on in <i>descending</i> order and set <i>Percentage of pages</i> to 5%.</p>
	`,
	'ui.segments.editor.sizeLimitModal.title': `Limit segment size`,
	'ui.segments.editor.sizeLimitModal.type.label': `Size limit`,
	'ui.segments.editor.sizeLimitTableFooter.link': `Change limit`,
	'ui.segments.editor.sizeLimitTableFooter.message': `{inactiveNumberOfPages, plural,
		=1 {1 page doesn't fit in the size limit.}
		other {# pages don't fit in the size limit.}
	}`,
	'ui.segments.editor.sortingDisabled': `Because the segment has a size limit set, sorting of this table cannot be changed.`,
	'ui.segments.editor.title': `Segment editor`,
	'ui.segments.importModal.sidebar.paragraph1': `Segments are logical groupings of your pages based on the criteria of each segment.`,
	'ui.segments.importModal.sidebar.paragraph2': `When importing segments from another website, you can always edit or delete them later.`,
	'ui.segments.importModal.steps.chooseSegments.actions.alreadyExists.label': `already exists`,
	'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.label': `cannot be imported`,
	'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.conflictWithManaged': `Segment {targetSegmentIdentifier} on the website you're importing to has the same label, but as that segment was manually provisioned for you by the Conductor team you can't import this segment. Contact us if you wish to create such a segment also on this website.`,
	'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.dependsOnOtherSegment': `This segment is based on the following segments: {dependencies} These segments need to be selected for importing as well.`,
	'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.dualConflict': `This segment conflicts with these two segments on the website you're importing to: {segmentA} and {segmentB}.`,
	'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.isStatic': `Importing a segment based on a custom set ot URLs is not supported. Contact us if you wish to create such a segment also on this website.`,
	'ui.segments.importModal.steps.chooseSegments.actions.cannotBeImported.reasons.missingCustomElement': `This segment is based on a custom element that does not exist on the website you're importing to.`,
	'ui.segments.importModal.steps.chooseSegments.actions.willBeImported.label': `will be imported`,
	'ui.segments.importModal.steps.chooseSegments.actions.willBeRenamed.label': `existing segment will be renamed`,
	'ui.segments.importModal.steps.chooseSegments.actions.willBeRenamed.tooltip': `Because segment {targetSegmentIdentifier} on the website you're importing to has the same criteria, it will be renamed. All ignoring rules and defined alerts will be preserved.`,
	'ui.segments.importModal.steps.chooseSegments.actions.willBeReplaced.label': `existing segment will be replaced`,
	'ui.segments.importModal.steps.chooseSegments.actions.willBeReplaced.tooltip': `Because the segment on the website you're importing to has the same label, its criteria will be updated. Old segment criteria: {targetSegmentCriteria} New segment criteria: {sourceSegmentCriteria} All ignoring rules and defined alerts will be preserved.`,
	'ui.segments.importModal.steps.chooseSegments.actions.willNotBeImported.label': `select to import`,
	'ui.segments.importModal.steps.chooseSegments.columns.segment': `Segment`,
	'ui.segments.importModal.steps.chooseSegments.columns.status': `Status`,
	'ui.segments.importModal.steps.chooseSegments.description': `Choose which segments should be imported from {sourceWebsite}.`,
	'ui.segments.importModal.steps.chooseSegments.submitButton': `Import segments`,
	'ui.segments.importModal.steps.chooseSegments.tableSummary': `{count, plural,
		=1 {<b>#</b> segment}
		other {<b>#</b> segments}
	} will be imported to {websiteName}`,
	'ui.segments.importModal.steps.chooseSegments.title': `Choose segments`,
	'ui.segments.importModal.steps.chooseWebsite.description': `Choose from which website you want to import segments.`,
	'ui.segments.importModal.steps.chooseWebsite.noUniqueSegments': `Identical segments configured`,
	'ui.segments.importModal.steps.chooseWebsite.numberOfSegments': `{numberOfSegments, plural,
		=0 {no segment}
		=1 {# segment}
		other {# segments}
	} configured`,
	'ui.segments.importModal.steps.chooseWebsite.submitButton': `Continue to segment selection`,
	'ui.segments.importModal.steps.chooseWebsite.title': `Choose website`,
	'ui.segments.importModal.steps.chooseWebsite.websiteFieldLabel': `Website`,
	'ui.segments.importModal.title.mainPart': `Segments`,
	'ui.segments.importModal.title.prefix': `Import`,
	'ui.segments.management.cannotDependOnDependant': `You can't filter on segments that depend on the segment you're currently editing.`,
	'ui.segments.management.cannotDependOnSelf': `You can't filter on the segment you're currently editing.`,
	'ui.segments.management.createSegmentButton': `Create segment`,
	'ui.segments.management.createSegmentButtonClassicOption': `Segment <small>(recommended)</small>`,
	'ui.segments.management.createSegmentButtonStaticOption': `Static segment`,
	'ui.segments.management.importSegmentsButton': `Import segments`,
	'ui.segments.management.editLabel': `Edit label`,
	'ui.segments.management.errors.duplicateCriteria': `A segment with these criteria already exists on this website: {segment}. Choose different segment criteria.`,
	'ui.segments.management.errors.duplicateIdentifier': `A segment with this label already exists on this website. Choose a different color, symbol or name.`,
	'ui.segments.management.errors.emptyCriteria': `Each segment needs to have segment criteria defined. Choose some segment criteria to save this segment.`,
	'ui.segments.management.errors.unchanged': `No changes made to segment.`,
	'ui.segments.management.field.color': `Segment color`,
	'ui.segments.management.field.name': `Segment name`,
	'ui.segments.management.field.segmentLabel': `Preview`,
	'ui.segments.management.heading.create': `Creating new segment`,
	'ui.segments.management.heading.edit': `Editing segment`,
	'ui.segments.management.placeholder.newSegment': `New segment`,
	'ui.segments.management.save': `Save segment`,
	'ui.segments.management.saveAs': `Save as new`,
	'ui.segments.management.segmentLabel.long': `Long`,
	'ui.segments.management.segmentLabel.short': `Short`,
	'ui.segments.management.update': `Save updates`,
	'ui.segments.management.updateDetails': `Change`,
	'ui.segments.operation.and': `and`,
	'ui.segments.operation.andIn': `and in`,
	'ui.segments.operation.andNot': `and not`,
	'ui.segments.operation.andNotIn': `and not in`,
	'ui.segments.operation.not': `not`,
	'ui.segments.operation.notIn': `not in`,
	'ui.segments.operation.or': `or`,
	'ui.segments.operation.orIn': `or in`,
	'ui.segments.overview.blankSlate': `You don't have any segments configured.`,
	'ui.segments.overview.columns.criteria': `Filter criteria`,
	'ui.segments.overview.columns.identifier': `Label`,
	'ui.segments.overview.columns.sizeLimit': `Size limit`,
	'ui.segments.overview.noCriteria': `none`,
	'ui.segments.overview.noSizeLimit': `none`,
	'ui.segments.overview.title': `Segments`,
	'ui.segments.popup.editLink': `Edit segment`,
	'ui.segments.popup.filterCriteria': `Filter criteria`,
	'ui.segments.popup.isBeingReevaluated': `Segment is being recalculated`,
	'ui.segments.popup.sizeLimit': `Size limit`,
	'ui.segments.selection.addToFilter': `Segments`,
	'ui.segments.selection.hint.drag': `Drag a segment here to filter`,
	'ui.segments.selection.hint.drop.include': `Drop segment here to filter`,
	'ui.segments.selection.hint.drop.remove': `Drop segment here to remove it from filter`,
	'ui.segments.selection.hint.remove': `To remove a segment from filter, simply click on it or drag it back.`,
	'ui.segments.selection.inAlertDefinitions.disabled.dueToLinksColumns': `This segment uses data about Links. Alerting about such a segment is not supported yet.`,
	'ui.segments.selection.inAlertDefinitions.disabled.dueToSchemaOrgColumns': `This segment uses data about Schema.org. Alerting about such a segment is not supported yet.`,
	'ui.segments.selection.inAlertDefinitions.disabled.dueToTimeServerResponseColumn': `This segment uses data about Download Time. Alerting about such a segment is not supported yet.`,
	'ui.segments.selection.included': `Included in`,
	'ui.segments.selection.inTrackedChanges.disabled.dueToLinksColumns': `This segment uses data about Links. Searching on this data while in Tracked Changes is currently not supported.`,
	'ui.segments.selection.inTrackedChanges.disabled.dueToSchemaOrgColumns': `This segment uses data about Schema.org. Searching on this data while in Tracked Changes is currently not supported.`,
	'ui.segments.selection.inTrackedChanges.disabled.dueToTimeServerResponseColumn': `This segment uses data about Download Time. Searching on this data while in Tracked Changes is currently not supported.`,
	'ui.segments.selection.notIncluded': `And not included in`,
	'ui.segmentSizeLimit.typeDescription.numberOfPages.bottom': `Bottom {numberOfPages, number}`,
	'ui.segmentSizeLimit.typeDescription.numberOfPages.top': `Top {numberOfPages, number}`,
	'ui.segmentSizeLimit.typeDescription.percentage.bottom': `Bottom {percentage, number, percent}`,
	'ui.segmentSizeLimit.typeDescription.percentage.top': `Top {percentage, number, percent}`,
	'ui.segmentsManagement.cannotModifyManaged': `This segment was manually provisioned for you by the Conductor team. Contact us if you wish to make any changes to this segment.`,
	'ui.sepaDebit.iban': `IBAN`,
	'ui.sepaDebit.mandate': `By providing your payment information and confirming this payment, you authorise (A) ContentKing B.V. and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.`,
	'ui.sepaDebit.name': `Account holder`,
	'ui.sepaDebit.sepa': `SEPA`,
	'ui.settings.browserExtension.form.label': `Chrome extension`,
	'ui.settings.browserExtension.form.viewInStore': `view in Chrome store`,
	'ui.settings.browserExtension.title': `Extensions`,
	'ui.settings.changePassword': `Change password`,
	'ui.settings.emailNotifications.reports': `Reports`,
	'ui.settings.emailNotifications.teamMember': `Team member`,
	'ui.settings.emailNotifications.teamMember.view': `{teamMembersCount, number} {teamMembersCount, plural,
		=1 {team member}
		other {team members}
	}`,
	'ui.settings.emailNotifications.title': `Reports and alerts`,
	'ui.settings.emailNotifications.website': `Website`,
	'ui.settings.emailNotifications.website.view': `{websitesCount, number} {websitesCount, plural,
		=1 {website}
		other {websites}
	}`,
	'ui.settings.emailSettings.gettingStarted': `Getting Started`,
	'ui.settings.emailSettings.monthlyNewsletter': `Monthly Newsletter`,
	'ui.settings.emailSettings.newFeatures': `New Features`,
	'ui.settings.emailSettings.serviceMessages': `Service Messages`,
	'ui.settings.emailSettings.title': `Email Settings`,
	'ui.settings.form.emailLabel': `Email`,
	'ui.settings.form.firstNameLabel': `First name`,
	'ui.settings.form.incorrectOldPasswordError': `The entered password is incorrect.`,
	'ui.settings.form.jobLabel': `Job title`,
	'ui.settings.form.lastNameLabel': `Last name`,
	'ui.settings.form.newPasswordLabel': `New password`,
	'ui.settings.form.oldPasswordLabel': `Old password`,
	'ui.settings.form.passwordAlreadyUsedInPast': `You already used this password recently. Please choose another one.`,
	'ui.settings.form.passwordLabel': `Password`,
	'ui.settings.form.verifyPasswordLabel': `Verify new password`,
	'ui.settings.menuLabel': `Your profile`,
	'ui.settings.personalSettings.title': `Personal Settings`,
	'ui.settings.setPassword': `Set password`,
	'ui.settings.title': `Your account`,
	'ui.settings.userNamePlaceholder': `You`,
	'ui.settings.userSettings.title': `User Settings`,
	'ui.setupIndexNowKeyModal.description': `Enter the IndexNow API key hosted on this domain which Conductor Website Monitoring will use for submitting to IndexNow.`,
	'ui.setupIndexNowKeyModal.indexNowKey': `IndexNow API key`,
	'ui.setupIndexNowKeyModal.invalidIndexNowKey': `IndexNow API key is invalid`,
	'ui.setupIndexNowKeyModal.sidebar': `
	<p>Following the best practises of the IndexNow protocol, the API key needs to be hosted on the root directory on this domain in a txt file.</p>
	<p>You can read more in <linkSupport>our support article</linkSupport></p>`,
	'ui.setupIndexNowKeyModal.title': `Setup IndexNow API key`,
	'ui.signup.featuresInUse.back': `back to plan comparison`,
	'ui.signup.featuresInUse.chooseBetterPlan.label': `Switch to {plan} plan`,
	'ui.signup.featuresInUse.chooseBetterPlan.note': `<p>Only {amount}/month extra{br}Access to additional features</p>`,
	'ui.signup.featuresInUse.keepCurrentPlan.label': `Continue with {plan} plan`,
	'ui.signup.featuresInUse.keepCurrentPlan.note': `<p>If you sign up with the {plan} plan, <b>these features will be disabled</b> and reset to the default settings.</p>`,
	'ui.signup.featuresInUse.title': `Plan features`,
	'ui.signup.legal.agreement': `By clicking "Confirm payment" you agree to our {documents}.`,
	'ui.signup.legal.dataProcessingAgreement': `<showDocument>Data Processing Agreement</showDocument>`,
	'ui.signup.legal.privacyPolicy': `Learn how we process your personal data in our <showDocument>Privacy Policy</showDocument>.`,
	'ui.signup.legal.termsOfUse': `<showDocument>Terms of Use</showDocument>`,
	'ui.signup.pagesConfigurator.enterpriseAnnouncement': `For accounts from {from, number, ::scale/0.000001} to {to, number, ::scale/0.000001} million pages, our Enterprise plan is the right fit.`,
	'ui.signup.pagesConfigurator.title': `Altogether my websites have {inputField} pages`,
	'ui.signup.pagesConfigurator.tooltip1': `You're free to divide your page bundle across as many websites as you want, in increments of 1,000 pages per website.`,
	'ui.signup.pagesConfigurator.tooltip2': `You only pay for actual pages, never for redirects, 404s, errors or time-outs.`,
	'ui.signup.satisfiedCustomers.title': `Trusted by the best`,
	'ui.sitemapsErrorsTable.error': `Error`,
	'ui.sitemapsErrorsTable.lineNumber': `Line`,
	'ui.sitemapsErrorsTable.string': `String`,
	'ui.slack.connect': `Connect`,
	'ui.snapshotHeaders.properties.method': `Request Method:`,
	'ui.snapshotHeaders.properties.remoteAddress': `Remote IP Address:`,
	'ui.snapshotHeaders.properties.requestedAt': `Requested at:`,
	'ui.snapshotHeaders.properties.statusCode': `Status Code:`,
	'ui.snapshotHeaders.properties.unreachable': `Unreachable:`,
	'ui.snapshotHeaders.properties.url': `URL:`,
	'ui.snapshotHeaders.sections.general': `General`,
	'ui.snapshotHeaders.sections.requestHeaders': `Request headers`,
	'ui.snapshotHeaders.sections.responseHeaders': `Response headers`,
	'ui.snapshotViewer.overlay.javascriptRenderingNotEnabled': `When this change was tracked, JS Rendering was not enabled for this website.`,
	'ui.snapshotViewer.overlay.renderedDomNotAvailable': `Rendered DOM snapshot is not available.`,
	'ui.snapshotViewer.overlay.renderedDomNotTracked': `When this change was tracked, storing of Rendered DOM snapshots was not enabled for this website.`,
	'ui.snapshotViewer.overlay.responseBodyNotAvailable': `Response Body snapshot is not available.`,
	'ui.snapshotViewer.overlay.responseBodyNotTracked': `When this change was tracked, storing of Response Body snapshots was not enabled for this website.`,
	'ui.snapshotViewer.overlay.snapshotNotAvailable': `Snapshot is not available`,
	'ui.snapshotViewer.overlay.tooLarge': `This snapshot is too large to display. Please download the snapshot instead.`,
	'ui.snapshotViewer.tabs.header': `Headers`,
	'ui.snapshotViewer.tabs.renderedDom': `Rendered DOM`,
	'ui.snapshotViewer.tabs.responseBody': `Response Body`,
	'ui.snapshotViewer.title': `Snapshot`,
	'ui.snapshotViewer.truncatedLinePopup': `This line is too long to display. Please download the snapshot instead.`,
	'ui.snapshotViewer.viewSnapshotLink.disabledLabel': `Snapshot not available`,
	'ui.snapshotViewer.viewSnapshotLink.label': `View snapshot`,
	'ui.snapshotViewer.viewSnapshotLink.tooltip.featureNotAvailable': `Snapshots are only available for requests made after this feature was introduced.`,
	'ui.snapshotViewer.viewSnapshotLink.tooltip.featureNotEnabled': `When this change was tracked, storing of HTTP Header snapshots was not enabled for this website.`,
	'ui.snapshotViewer.viewSnapshotLink.tooltip.notApplicable': `Snapshot of this change not available as no request to the URL was made.`,
	'ui.snapshotViewer.viewSnapshotLink.tooltip.notWithinDataRetention': `This snapshot doesn’t fit in your account’s data retention period. <showIntercom>Contact us</showIntercom> to get access to up to 60 months of historical data.`,
	'ui.staticSegmentCriteria': `Static list of URLs`,
	'ui.staticSegmentCaution.text': `Static segment is a static set of URLs that do not update automatically. If you can define this set of URLs by filter criteria, we highly recommend to <linkClassic>turn this segment into a standard segment</linkClassic> instead. That way the pages will automatically move in and out of the segment based on the segment filter criteria. Find more information in <linkSupport>our support article</linkSupport>.`,
	'ui.staticSegmentCaution.title': `Are you sure this should be a static segment?`,
	'ui.staticSegmentUrlsField.instruction': `Static segments on this website can contain only full URLs on domain {domain}: minimum 1 URL and maximum {maximumAmount} URLs. Each URL needs to be on a separate line. Please <showIntercom>contact us</showIntercom> for help.`,
	'ui.staticSegmentUrlsField.placeholder': `Enter the full URLs here, one URL per line. Example:\nhttp://www.example.com/page1.html\nhttp://www.example.com/page2.html\nhttp://www.example.com/page3.html`,
	'ui.subscriptionForm.cannotSubmitDueToPaymentIssues': `Unfortunately your account is having payment issues. Please resolve this or contact us before making changes to your subscription.`,
	'ui.teamDetail.accountSettings.accountName': `Account name`,
	'ui.teamDetail.accountSettings.apiTokenInfo': `Want to connect your website to our API? Use this token to authenticate it. Find more on our API at {link}. Using WordPress? Our WP plugin makes it even easier to configure. Learn more at https://wordpress.org/plugins/contentking/`,
	'ui.teamDetail.accountSettings.apiTokenLabel': `CMS API`,
	'ui.teamDetail.accountSettings.changeEnrichmentApiHint': `Caution: you will need to update the configuration of current integrations with the Data Enrichment API when changing the token.`,
	'ui.teamDetail.accountSettings.changeEnrichmentApiLink': `Change Data Enrichment API token`,
	'ui.teamDetail.accountSettings.changeReportingApiHint': `Caution: you will need to update the configuration of current integrations with the Reporting API when changing the token.`,
	'ui.teamDetail.accountSettings.changeReportingApiHint.googleLookerStudioInUse': `Caution: you will need to update the configuration of current integrations with the Reporting API when changing the token. This will also affect the Google Looker Studio connector.`,
	'ui.teamDetail.accountSettings.changeReportingApiLink': `Change Reporting API token`,
	'ui.teamDetail.accountSettings.cmsApiHint': `The CMS API is used to integrate Conductor Website Monitoring in publishing workflows. Read more about this in <link>our support article</link>.`,
	'ui.teamDetail.accountSettings.getReportingApiToken': `Get Reporting API token`,
	'ui.teamDetail.accountSettings.menuLabel': `Account`,
	'ui.teamDetail.accountSettings.pluginsTitle': `Integration tokens`,
	'ui.teamDetail.accountSettings.reportingApiHint': `The Reporting API is used to extract Conductor Website Monitoring metrics for integrations with other tooling. Read more about this in <link>our support article</link>.`,
	'ui.teamDetail.accountSettings.reportingApiTokenLabel': `Reporting API`,
	'ui.teamDetail.accountSettings.requestTeamDeletion': `Request account deletion`,
	'ui.teamDetail.accountSettings.title': `Profile`,
	'ui.teamDetail.accountSettings.trialEndDateLabel': `Trial end date`,
	'ui.teamDetail.admin.menuLabel': `Admin`,
	'ui.teamDetail.billing.agencyPackage.billingCycle': `Billing Cycle`,
	'ui.teamDetail.billing.agencyPackage.pageBundle': `Page Bundle`,
	'ui.teamDetail.billing.agencyPackage.plan': `Plan`,
	'ui.teamDetail.billing.agencyPackage.trialAccountLimit.message': `During your trial your account is limited to {count__agencyAccountSize, number} pages. {linkToSignUp} to monitor more and larger websites.`,
	'ui.teamDetail.billing.billingDetailsTitle': `Billing details`,
	'ui.teamDetail.billing.deliveryAddressTitle': `Delivery Address`,
	'ui.teamDetail.billing.invoiceDetailsTitle': `Invoice details`,
	'ui.teamDetail.billing.menuLabel': `Billing`,
	'ui.teamDetail.billing.menuLabelSignup': `Sign up`,
	'ui.teamDetail.billing.package.confirmationButton': `Adjust subscription`,
	'ui.teamDetail.billing.paymentMethodTitle': `Payment method`,
	'ui.teamDetail.billing.stoppedWebsites.description': `
		<p>Your payment was declined multiple times. Your subscription is now suspended.</p>
		<ul>
			<li>Check if you have sufficient funds</li>
			<li>Check transfer limits on your account</li>
			<li>Update payment information</li>
			<li>Choose a different payment method</li>
		</ul>
		<p>If you are still having problems or have any questions you can always <link_contact>contact us</link_contact>.</p>
	`,
	'ui.teamDetail.billing.stoppedWebsites.title': `Subscription suspended`,
	'ui.teamDetail.billing.unpaidWebsites.description': `
		<p>Your payment was declined. We will try to collect the payment again.</p>
		<ul>
			<li>Check if you have sufficient funds</li>
			<li>Check transfer limits on your account</li>
			<li>Update payment information</li>
			<li>Choose a different payment method</li>
		</ul>
		<p>If you are still having problems or have any questions you can always <link_contact>contact us</link_contact>.</p>
	`,
	'ui.teamDetail.billing.unpaidWebsites.title': `Payment failed`,
	'ui.teamDetail.members.browserExtension.installed': `installed`,
	'ui.teamDetail.members.browserExtension.installTooltip.member': `This team member hasn't installed <link>Conductor Website Monitoring Extension</link> for Google Chrome yet.`,
	'ui.teamDetail.members.browserExtension.installTooltip.user': `Hey, did you know that now you can see Conductor Website Monitoring data and the most pressing SEO issues directly when looking at a page in your browser? <link>Install our Chrome extension</link>`,
	'ui.teamDetail.members.browserExtension.notInstalled': `not installed`,
	'ui.teamDetail.members.cancelInvitation': `Cancel invitation`,
	'ui.teamDetail.members.editDetails.button': `Edit details`,
	'ui.teamDetail.members.invitationSent': `Invitation sent`,
	'ui.teamDetail.members.inviteMember': `Invite new team member`,
	'ui.teamDetail.members.inviteMember.dropdown.inviteMultipleMembers': `Invite multiple team members`,
	'ui.teamDetail.members.inviteMember.dropdown.inviteSingleMember': `Invite single team member`,
	'ui.teamDetail.members.inviteMobileMember': `Invite member`,
	'ui.teamDetail.members.menuLabel': `Team Members`,
	'ui.teamDetail.members.mobileTitle': `Members`,
	'ui.teamDetail.members.removeFromTeam': `Remove from team`,
	'ui.teamDetail.members.roles.change': `Change role`,
	'ui.teamDetail.members.title': `Team members`,
	'ui.teamDetail.subscriptionForm.header': `Subscription`,
	'ui.teamDetail.teamInvitation.errorAlreadyInvited': `This person has already been invited.`,
	'ui.teamDetail.teamInvitation.errorAlreadyMember': `This person is already a team member.`,
	'ui.teamDetail.teamInvitation.errorBlacklisted': `This address cannot be sent invitations. If you own it, please contact us.`,
	'ui.teamDetail.websites.add.errors.unreachable': `Unfortunately, we can't reach this website ({reason}). Please contact us for help.`,
	'ui.teamDetail.websites.add.save': `Add website`,
	'ui.teamDetail.websites.addWebsite': `Add website`,
	'ui.teamDetail.websites.addWebsite.dropdown.addMultipleWebsites': `Add multiple websites`,
	'ui.teamDetail.websites.addWebsite.dropdown.addSingleWebsite': `Add single website`,
	'ui.teamDetail.websites.menuLabel': `Websites`,
	'ui.teamDetail.websites.summary.label.subtotal': `Subtotal`,
	'ui.teamDetail.websites.summary.label.total.annually': `Total (per year)`,
	'ui.teamDetail.websites.summary.label.total.monthly': `Total (per month)`,
	'ui.teamDetail.websites.summary.label.total.quarterly': `Total (per quarter)`,
	'ui.teamDetail.websites.summary.label.vat': `VAT`,
	'ui.teamDetail.websites.title': `Websites`,
	'ui.teamDetail.websites.warningMessage': `Some of your websites are at risk of not being monitored.`,
	'ui.textInspector.bomCharacter': `This is a byte order mark (BOM) character.`,
	'ui.textInspector.newlineCharacter': `This is a newline character.`,
	'ui.textInspector.tabCharacter': `This is a tab character.`,
	'ui.textInspector.unknownCharacter': `This is an unknown non-printable character.`,
	'ui.timingFormatter.milliseconds': `ms`,
	'ui.timingFormatter.seconds': `s`,
	'ui.tooLong.characters': `{characters, plural,
		=0 {# characters}
		=1 {# character}
		other {# characters}
	} too long`,
	'ui.tooLong.pixels': `{pixels, plural,
		=0 {# pixels}
		=1 {# pixel}
		other {# pixels}
	} too long`,
	'ui.tooShort.characters': `{characters, plural,
		=0 {# characters}
		=1 {# character}
		other {# characters}
	} too short`,
	'ui.tooShort.pixels': `{pixels, plural,
		=0 {# pixels}
		=1 {# pixel}
		other {# pixels}
	} too short`,
	'ui.trialBar.accountCompleteness': `Account completeness`,
	'ui.trialBar.button': `Sign up now`,
	'ui.twoFactorAuthenticationDisabling.description': `
		<p>Disabling two-factor authentication significantly decreases security of your account.</p>
		<p>If you want to proceed, enter the 6-digit code generated by the Authenticator app on your mobile device:</p>
	`,
	'ui.twoFactorAuthenticationDisabling.havingTrouble': `
		<p>Having trouble? Head over to <linkArticle>our support article</linkArticle>.</p>
	`,
	'ui.twoFactorAuthenticationDisabling.submit': `Disable 2FA`,
	'ui.twoFactorAuthenticationDisabling.title': `Disable 2FA`,
	'ui.twoFactorAuthenticationLogin.errors.invalidCode': `The code you entered isn't valid. Please enter a valid verification code.`,
	'ui.twoFactorAuthenticationSettings.active': `active`,
	'ui.twoFactorAuthenticationSettings.disableLink': `(<openModal>disable</openModal>)`,
	'ui.twoFactorAuthenticationSettings.inactive': `inactive`,
	'ui.twoFactorAuthenticationSettings.setupLink': `<openModal>set up 2FA</openModal>`,
	'ui.twoFactorAuthenticationSettings.title': `Two-factor authentication`,
	'ui.twoFactorAuthenticationSetup.authenticatorAppHint': `
		We recommend using the Google Authenticator app for the 2FA in Conductor Website Monitoring, although other authenticator apps are also supported.
		{br}{br}
		Google Authenticator is available for <linkIosApp>iOS</linkIosApp> as well as for <linkAndroidApp>Android</linkAndroidApp>.
	`,
	'ui.twoFactorAuthenticationSetup.backToQrCode': `Back to the QR code`,
	'ui.twoFactorAuthenticationSetup.codePrompt': `Enter the 6-digit code generated by the Authenticator app:`,
	'ui.twoFactorAuthenticationSetup.havingTroubles': `Having troubles scanning the QR code?`,
	'ui.twoFactorAuthenticationSetup.manualEntryAccount': `Account:`,
	'ui.twoFactorAuthenticationSetup.manualEntryKey': `Key:`,
	'ui.twoFactorAuthenticationSetup.manualEntryPrompt': `Choose the <i>Manual entry</i> option in the Authenticator{helpHint} app and enter these details:`,
	'ui.twoFactorAuthenticationSetup.qrCodePrompt': `Scan this QR code with the Authenticator{helpHint} app on your mobile device to get started:`,
	'ui.twoFactorAuthenticationSetup.sidebar': `
		<p>Two-factor authentication (2FA) significantly increases the security of your account by requiring a one-time code from your mobile phone when logging in.</p>
		<p>We recommend Google Authenticator for this, which is available <linkIosApp>for iOS</linkIosApp> as well as <linkAndroidApp>for Android</linkAndroidApp>.</p>
		<p>You can learn more about this in <linkArticle>our support article on 2FA</linkArticle>.</p>
	`,
	'ui.twoFactorAuthenticationSetup.sidebar.linkSupportTarget': `https://www.contentkingapp.com/support/two-factor-authentication/`,
	'ui.twoFactorAuthenticationSetup.submit': `Set up 2FA`,
	'ui.twoFactorAuthenticationSetup.successMessage': `Two-factor authentication has been successfully activated in your account. Good job!`,
	'ui.twoFactorAuthenticationSetup.successTitle': `Two-factor authentication enabled!`,
	'ui.twoFactorAuthenticationSetup.sudoModeDescription': `Before setting up the two-factor authentication, please enter your password.`,
	'ui.twoFactorAuthenticationSetup.title': `Set up 2FA`,
	'ui.twoFactorAuthenticationSetupBeforeJoining.success.button': `Continue to App`,
	'ui.twoFactorAuthenticationSetupBeforeJoining.success.content': `Congratulations, you have successfully set up two-factor authentication.`,
	'ui.twoFactorAuthenticationSetupBeforeJoining.success.title': `Your account is now secured`,
	'ui.twoFactorAuthenticationSetupBeforeJoining.title': `Set up two-factor authentication`,
	'ui.twoFactorAuthenticationSetupBeforeJoining.whatIs2FA': `What is 2FA?`,
	'ui.updateWebsiteAwsAccountIdForm.awsAccountId': `AWS Account ID`,
	'ui.updateWebsiteAwsAccountIdForm.awsAccountIdValidation': `Please enter your 12 digit AWS Account ID`,
	'ui.updateWebsiteAwsAccountIdForm.description': `
		<p>You need to install the CloudFront Standard logging in your AWS account. Learn how in <linkArticle>our support article</linkArticle>.</p>
		<p>Please enter your AWS account ID, so Conductor Website Monitoring can grant you access for streaming the log data to the S3 bucket in the Conductor AWS account.</p>
	`,
	'ui.upgradeToPaid.billingDetailsStep.saveButton': `Select payment method`,
	'ui.upgradeToPaid.billingDetailsStep.saveButtonWithInvoiceDetails': `Configure invoice details`,
	'ui.upgradeToPaid.billingDetailsStep.title': `Billing details`,
	'ui.upgradeToPaid.congratulations.button': `Continue`,
	'ui.upgradeToPaid.congratulations.description': `
		<p>You successfully signed up for Conductor Website Monitoring.</p>
		<p>Now you're ready to keep up the great work optimizing your websites. And when it's time for a break, rest assured that Conductor Website Monitoring is keeping an eye on them 24/7 and will let you know about any unexpected issues or changes immediately.</p>
	`,
	'ui.upgradeToPaid.congratulations.title': `Congratulations!`,
	'ui.upgradeToPaid.invoiceDetailsStep.saveButton': `Select payment method`,
	'ui.upgradeToPaid.paymentMethodStep.invoice.description': `The email will be sent to {email}.`,
	'ui.upgradeToPaid.paymentMethodStep.invoice.message': `You'll receive your invoice and payment instructions by email.`,
	'ui.upgradeToPaid.paymentMethodStep.title': `Payment method`,
	'ui.upsell.box.button': `Try for FREE`,
	'ui.upsell.box.text': `{plan, select,
		enterprise {HTTP Body Snapshots, up to 20 custom elements, up to 5 years of historical data and advanced access control.}
		pro {
			{tariff, select,
				v3 {Google Looker Studio connector, Reporting API access, Custom Element Extraction and 12 months of historical data.}
				v4 {Log File Analysis, Real-time IndexNow, Microsoft Teams integration and 10 Custom Elements per website.}
				other {}
			}
		}
		prov4 {Log File Analysis, Real-time IndexNow, Microsoft Teams integration and 10 Custom Elements per website.}
		other {}
	} Sounds great, right? Try it – without commitments`,
	'ui.upsell.box.title': `{days, plural,
		one {# day}
		other {# days}
	} of our <i>{plan}</i> plan, on the house`,
	'ui.upsell.legacy.button': `Contact us`,
	'ui.upsell.legacy.description': `Your account is on a legacy plan, where new features are no longer supported. If you want to switch to any of our current plans, contact us and we'll be happy to assist you.`,
	'ui.upsell.tooltip.firstParagraph': `This feature is not included in your plan.`,
	'ui.upsell.trial.button': `Try for free`,
	'ui.upsell.trial.ribbon': `Try for FREE`,
	'ui.upsell.trial.tooltip.secondParagraph.manager': `You can try out this plan completely free of charge for {days, plural,
		one {# day}
		other {# days}
	}. At the end of this period it will automatically revert to your current plan unless you upgrade your account.`,
	'ui.upsell.trial.tooltip.secondParagraph.member': `You can try out this plan completely free of charge for {days, plural,
		one {# day}
		other {# days}
	}. Just ask one of the managers on your team to start the trial.`,
	'ui.upsell.unavailablePlan.button': `Contact us`,
	'ui.upsell.unavailablePlan.description': `If you want to switch to this plan, contact us and we'll be happy to assist you.`,
	'ui.upsell.unavailablePlan.enterpriseDescription': `Contact us to learn more about pricing and how this feature can help you.`,
	'ui.upsell.upgrade.button': `See the benefits`,
	'ui.upsell.upgrade.ribbon': `Upgrade your plan`,
	'ui.upsell.upgrade.tooltip.secondParagraph.manager': `Upgrading to the {text__pricingPlan} plan costs just {text__price} per month and unlocks many useful features. `,
	'ui.upsell.upgrade.tooltip.secondParagraph.member': `Ask one of the managers on your team to upgrade your account.`,
	'ui.urlBlocklistForm.addButton': `Add URL pattern`,
	'ui.urlBlocklistForm.column.operator': `Operator`,
	'ui.urlBlocklistForm.column.value': `URL pattern`,
	'ui.urlBlocklistForm.maximumRules': `You can only specify up to 8 rules. Make some of the rules above broader, or delete one of them and add a new one.`,
	'ui.urlBlocklistForm.mode.allowAllExcept': `Allow all except`,
	'ui.urlBlocklistForm.mode.denyAllExcept': `Deny all except`,
	'ui.urlBlocklistForm.operator.contains': `Contains`,
	'ui.urlBlocklistForm.operator.endsWith': `Ends with`,
	'ui.urlBlocklistForm.operator.equals': `Exact match`,
	'ui.urlBlocklistForm.operator.matches': `Regular expression`,
	'ui.urlBlocklistForm.operator.notContains': `Not containing`,
	'ui.urlBlocklistForm.operator.startsWith': `Starts with`,
	'ui.userAgent.automatic': `Automatic`,
	'ui.userAgent.baiduspider': `Baiduspider`,
	'ui.userAgent.bingbotDesktop': `BingBot (desktop)`,
	'ui.userAgent.bingbotMobile': `BingBot (mobile)`,
	'ui.userAgent.customUserAgent': `Custom User Agent`,
	'ui.userAgent.duckDuckGo': `DuckDuckGo`,
	'ui.userAgent.googlebotDesktop': `Googlebot (desktop)`,
	'ui.userAgent.googlebotMobile': `Googlebot (mobile)`,
	'ui.userAgent.googlechromeDesktop': `Google Chrome (desktop)`,
	'ui.userAgent.googlechromeMobile': `Google Chrome (mobile)`,
	'ui.userAgent.kingkevinbot': `ContentKing`,
	'ui.userAgent.screamingFrog': `Screaming Frog`,
	'ui.userAgent.seznambot': `SeznamBot`,
	'ui.userAgent.slurp': `Slurp`,
	'ui.userAgent.yandexbot': `YandexBot`,
	'ui.userAgentFields.error.custom': `Custom User Agent is available only on verified websites. Read more in <linkArticle>our support article</linkArticle>.`,
	'ui.userAgentFields.description.automatic': `Google Chrome (desktop), and if the first request fails then Googlebot (desktop)`,
	'ui.userAgentFields.description.custom': `You need to verify this website before you can set a Custom User Agent.`,
	'ui.userAgentFields.hint.account': `Choose the default User Agent by which Conductor Website Monitoring will identify itself. This will only affect websites you're newly adding, and won't change the setting for existing websites in your account. You can always change this for individual websites. Read more about this in <linkArticle>our support article</linkArticle>.`,
	'ui.userAgentFields.hint.website': `User Agent controls how Conductor Website Monitoring identifies itself. In most cases, you won't need to change this setting. Read more about this in <linkArticle>our support article</linkArticle>.`,
	'ui.userAgentFields.userAgent': `User Agent`,
	'ui.userProfile.backToTeamMembers': `Team members`,
	'ui.userProfile.membershipsColumnAccount': `Account`,
	'ui.userProfile.membershipsColumnRole': `Role`,
	'ui.userProfile.numberOfMemberships': `Team Member in {numberOfMemberships, plural,
		=0 {no accounts}
		=1 {# account}
		other {# accounts}
	}`,
	'ui.userProfile.title': `Your Profile`,
	'ui.userRole.admin': `Admin`,
	'ui.userRole.editor': `Editor`,
	'ui.userRole.manager': `Manager`,
	'ui.userRole.viewer': `Viewer`,
	'ui.userRoleAndAccess': `{role} <small>({numberOfWebsites, plural,
		=0 {no websites}
		=1 {on # website}
		other {on # websites}
	})</small>`,
	'ui.validateUrls.invalidUrls': `{numberOfInvalidUrls, plural,
		=1 {<b># URL</b> is}
		other {<b># URLs</b> are}
	} not valid full {numberOfInvalidUrls, plural,
		=1 {URL}
		other {URLs}
	}{allowedDomains, select,
		none {}
		other{ on <b>{allowedDomains}</b>}
	}`,
	'ui.validateUrls.maximumAmount': `<b>Maximum of {maximumAmount, plural,
		=1 {# URL}
		other {# URLs}
	}</b> is exceeded`,
	'ui.validateUrls.minimumAmount': `<b>No URL</b> is specified`,
	'ui.values.noData': `no data`,
	'ui.values.notEnoughData': `not enough data`,
	'ui.values.noValueYet': `data not available yet`,
	'ui.valueTest.status.tooLong': `too long`,
	'ui.verifyTwoFactorAuthenticationForm.backToLogin': `Back to email address`,
	'ui.verifyTwoFactorAuthenticationForm.code': `Verification code`,
	'ui.verifyTwoFactorAuthenticationForm.errors.invalidCodeError': `The code you entered isn't valid. Please enter a valid verification code.`,
	'ui.verifyTwoFactorAuthenticationForm.havingTrouble': `Having trouble?`,
	'ui.verifyTwoFactorAuthenticationForm.submit': `Log me in`,
	'ui.verifyTwoFactorAuthenticationForm.title': `Authenticator passcode required`,
	'ui.verifyWebsiteModal.button': `OK`,
	'ui.verifyWebsiteModal.description': `
		<p>By verifying this website you prove your ownership of this website to Conductor Website Monitoring.</p>
		<p>Verification is in place to protect the websites we monitor, and is required before giving you access to features which could potentially be abused, such as JS Rendering.</p>
		<p>You can verify this website in the following ways:</p>
		<ul>{verificationMethods}</ul>
		<p>If you can't connect any of these services, <link_intercom>contact us</link_intercom>.</p>
	`,
	'ui.verifyWebsiteModal.title': `Verify website`,
	'ui.waitForRetry.seconds': `Please wait {seconds, plural,
		=1 {# second}
		other {# seconds}
	} before trying again`,
	'ui.websiteAccess.allWebsites': `all`,
	'ui.websiteAccess.allWebsites.option': `All websites <small>(recommended)</small>`,
	'ui.websiteAccess.prompt': `Which websites will this team member have access to?`,
	'ui.websiteAccess.selectedWebsites': `selected`,
	'ui.websiteAccess.selectedWebsites.option': `Selected websites <small>(useful when inviting clients or external collaborators)</small>`,
	'ui.websiteDetail.backToWebsites': `All websites`,
	'ui.websiteDetail.pruneOrphansLink': `Purge orphan pages`,
	'ui.websiteDetail.title': `Settings`,
	'ui.websites.capacity.limitNear.label': `Near Page Capacity`,
	'ui.websites.capacity.limitReached.label': `At Page Capacity`,
	'ui.websites.detail.alertSettings': `Alert Settings`,
	'ui.websites.detail.deleteWebsite': `Delete website`,
	'ui.websites.detail.title': `Website`,
	'ui.websites.exclusionsModal.importer.description': `We've scanned your robots.txt file and found the following excluded URL patterns. Would you like us to add these rules to your URL Exclusion List?`,
	'ui.websites.exclusionsModal.importer.importRules': `Import exclusions`,
	'ui.websites.exclusionsModal.importer.rule': `URL PATTERN`,
	'ui.websites.exclusionsModal.importer.setupNewList': `Skip`,
	'ui.websites.exclusionsModal.importer.title': `Import URL exclusions from robots.txt`,
	'ui.websites.exclusionsModal.manager.addRule': `ADD PATTERN`,
	'ui.websites.exclusionsModal.manager.addRule.fieldErrorDuplicate': `This URL pattern is already on the exclusion list.`,
	'ui.websites.exclusionsModal.manager.addRule.fieldPlaceholder': `URL pattern`,
	'ui.websites.exclusionsModal.manager.description': `These are the URL patterns you are currently excluding from Conductor Website Monitoring.`,
	'ui.websites.exclusionsModal.manager.propagationTimeWarning': `Please note that it may take up to 10 minutes for the changes to be applied.`,
	'ui.websites.exclusionsModal.manager.rule': `URL PATTERN`,
	'ui.websites.exclusionsModal.manager.title': `Manage URL Exclusion List`,
	'ui.websites.form.api.adobeAnalytics.connectAnother': `reconnect project or connect a new one`,
	'ui.websites.form.api.adobeAnalytics.connectLink': `Connect`,
	'ui.websites.form.api.adobeAnalytics.eVar': `eVar with full URL`,
	'ui.websites.form.api.adobeAnalytics.expiredCredentialsWarning': `
		<p>Adobe Analytics data is no longer being updated because the credentials of the connected Adobe Analytics project have expired. Please generate a new public/private keypair for the connected project in your Adobe account, and then reconnect that project in Conductor Website Monitoring.</p>
		<p>Read more in <linkArticle>our support article</linkArticle>.</p>
	`,
	'ui.websites.form.api.adobeAnalytics.matchingMethod': `Match data on`,
	'ui.websites.form.api.adobeAnalytics.matchingMethod.explanation': `
		{dataMatchingMethod, select,
			fullUrl {<p>To match the data on full URL, please select your custom eVar with the full URL. Note that custom eVars can be max. 255 characters long, which means Conductor Website Monitoring will not be showing any data for URLs longer than 255 characters.</p>}
			pageTitle {<p>Data will be matched on page title. Data for pages with the same page title will be aggregated, which means Conductor Website Monitoring will be showing the same data for these pages.</p>}
			other {}
		}
		<p>Read more in <linkArticle>our support article</linkArticle>.</p>
	`,
	'ui.websites.form.api.adobeAnalytics.matchingMethod.explanation.title': `How the data will be matched`,
	'ui.websites.form.api.adobeAnalytics.matchingMethod.fullUrl': `Full URL`,
	'ui.websites.form.api.adobeAnalytics.matchingMethod.pageTitle': `Page Title`,
	'ui.websites.form.api.adobeAnalytics.noDimensionsInReportSuite': `
		<p>Conductor Website Monitoring matches the data on full URL using a custom eVar with the full URL, but we could not find any eVar in the selected Report Suite. Please select a different Report Suite, or create a custom eVar with the full URL.</p>
		<p>Note that custom eVars can be max. 255 characters long, which means Conductor Website Monitoring will not be showing any data for URLs longer than 255 characters.</p>
		<p><linkArticle>Read more in our support article</linkArticle></p>
	`,
	'ui.websites.form.api.adobeAnalytics.noDimensionsInReportSuite.title': `Data can't be matched on full URL`,
	'ui.websites.form.api.adobeAnalytics.noReportSuitesInAccount': `We could not find any Report Suite in your Adobe Analytics. Please create a Report Suite in Adobe Analytics, or connect a different Adobe Analytics project.`,
	'ui.websites.form.api.adobeAnalytics.noReportSuitesInAccount.title': `No Report Suites found`,
	'ui.websites.form.api.adobeAnalytics.project': `Project`,
	'ui.websites.form.api.adobeAnalytics.project.hint': `Choose the name of the project in the Adobe Developer Console, via which you provided Conductor Website Monitoring access to the Report Suites in your Adobe Analytics.`,
	'ui.websites.form.api.adobeAnalytics.reportSuite': `Report Suite`,
	'ui.websites.form.api.adobeAnalytics.reportSuite.hint': `Choose the Report Suite with the entire website.`,
	'ui.websites.form.api.adobeAnalytics.saveButton': `Save`,
	'ui.websites.form.api.adobeAnalytics.title': `Adobe Analytics`,
	'ui.websites.form.api.gds.title': `Google Looker Studio`,
	'ui.websites.form.api.googleAnalytics.account': `Account`,
	'ui.websites.form.api.googleAnalytics.connectAnother': `connect another account`,
	'ui.websites.form.api.googleAnalytics.connectLink': `Connect`,
	'ui.websites.form.api.googleAnalytics.errors.unrelatedAccount': `Website not found in Google Analytics account. Make sure to connect the right account.`,
	'ui.websites.form.api.googleAnalytics.errors.unrelatedAccount.link': `our support article`,
	'ui.websites.form.api.googleAnalytics.errors.unrelatedAccount.target': `https://www.contentkingapp.com/support/google-analytics/#connect-error`,
	'ui.websites.form.api.googleAnalytics.errors.unrelatedAccount.text': `Website not found in Google Analytics account. Read {text__link} for steps how to resolve this.`,
	'ui.websites.form.api.googleAnalytics.property': `Property`,
	'ui.websites.form.api.googleAnalytics.saveButton': `Save`,
	'ui.websites.form.api.googleAnalytics.title': `Google Analytics`,
	'ui.websites.form.api.googleAnalytics.view': `View`,
	'ui.websites.form.api.googleAnalytics.webpropertyAndView': `Property / View`,
	'ui.websites.form.api.gsc.connectLink': `Connect`,
	'ui.websites.form.api.gsc.errors.unrelatedAccount': `Website not found in Google Search Console account. Make sure to connect the right account.`,
	'ui.websites.form.api.gsc.reconnectLink': `Reconnect`,
	'ui.websites.form.api.gsc.title': `Google Search Console`,
	'ui.websites.form.api.microsoftTeams.title': `Microsoft Teams`,
	'ui.websites.form.api.slack.description.connect': `Connect to receive alert notifications directly on Slack.`,
	'ui.websites.form.api.slack.description.select': `You can always configure which Slack channel will be notified per each alert individually on <link_alert_definitions>Alert definitions</link_alert_definitions>`,
	'ui.websites.form.api.slack.enabled': `Enabled (<link_alert_definitions>change</link_alert_definitions>)`,
	'ui.websites.form.api.slack.title': `Slack`,
	'ui.websites.form.api.status.available': `Available`,
	'ui.websites.form.api.status.disabled': `Disabled`,
	'ui.websites.form.api.status.enabled': `Enabled`,
	'ui.websites.form.api.status.unavailable': `Unavailable`,
	'ui.websites.form.api.wp.availableMessage': `Install the {link} to benefit from even faster auditing and the ability to switch into WordPress directly from Conductor Website Monitoring.`,
	'ui.websites.form.api.wp.availableMessage.link': `Conductor Website Monitoring WordPress plugin`,
	'ui.websites.form.api.wp.title': `WP Plugin`,
	'ui.websites.form.bulkProtip': `If you want to add multiple websites, you can <linkBulkStep>add them in bulk</linkBulkStep>.`,
	'ui.websites.form.deviceType.hint': `Choose the device type for which you're optimising this website. This setting is reflected in Core Web Vitals Origin Summary, Conductor Lighthouse Web Vitals, and in JavaScript Rendering. Read more in <linkSupport>our support article</linkSupport>.`,
	'ui.websites.form.deviceType.label': `Device Type`,
	'ui.websites.form.domain': `Domain`,
	'ui.websites.form.domain.placeholder': `www.example.com`,
	'ui.websites.form.exclusions.link': `{rulesCount, plural,
		=0 {Set up URL Exclusion List}
		other {Manage URL Exclusion List}
	}`,
	'ui.websites.form.integrationsTitle': `Integrations`,
	'ui.websites.form.logSources.cloudflare': `Cloudflare`,
	'ui.websites.form.logSources.connectLink': `Connect`,
	'ui.websites.form.logSources.saveButton': `Save`,
	'ui.websites.form.logSources.title': `Log Sources`,
	'ui.websites.form.messagingAppsTitle': `Messaging Apps`,
	'ui.websites.form.monitoringSettings': `Monitoring Settings`,
	'ui.websites.form.monitoringSettings.custom': `Custom`,
	'ui.websites.form.monitoringSettings.default': `Default`,
	'ui.websites.form.monitoring.cookies.configured': `{count__cookies, plural,
		=0 {none}
		other {# configured}
	}`,
	'ui.websites.form.monitoring.cookies.edit': `{count__cookies, plural,
		=0 {<link_modal>configure</link_modal>}
		other {# configured (<link_modal>change</link_modal>)}
	}`,
	'ui.websites.form.monitoring.cookies.label': `Cookies`,
	'ui.websites.form.monitoring.cookies.modalTitle': `Edit cookies`,
	'ui.websites.form.monitoring.httpAuthentication.enabledAndChange': `Enabled (<linkModal>change</linkModal>)`,
	'ui.websites.form.monitoring.httpAuthentication.configure': `configure`,
	'ui.websites.form.monitoring.httpAuthentication.label': `HTTP Auth`,
	'ui.websites.form.monitoring.httpAuthentication.hint': `If this website is protected by the HTTP Authentication, configure it here to let Conductor Website Monitoring monitor it.`,
	'ui.websites.form.monitoring.httpAuthentication.password': `Password`,
	'ui.websites.form.monitoring.httpAuthentication.username': `Username`,
	'ui.websites.form.monitoring.httpHeaders.configured': `{count__headers, plural,
		=0 {none}
		other {# configured}
	}`,
	'ui.websites.form.monitoring.httpHeaders.edit': `{count__headers, plural,
		=0 {<link_modal>configure</link_modal>}
		other {# configured (<link_modal>change</link_modal>)}
	}`,
	'ui.websites.form.monitoring.httpHeaders.label': `HTTP Headers`,
	'ui.websites.form.monitoring.httpHeaders.modalTitle': `Edit HTTP Headers`,
	'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.customElementInUse': `Some custom elements are extracted from the rendered DOM. Before disabling JS Rendering, you need to <link_cee>change their settings</link_cee> to be extracted from the response body.`,
	'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.disabled': `Disabled`,
	'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.enabled': `Enabled`,
	'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.javascriptRendering': `Audit using JS Rendering`,
	'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.javascriptRendering.description': `Conductor Website Monitoring will audit your website based on the {trackDom, select,
		yes {<i>rendered DOM</i>}
		no {<i>response body</i>}
		other {}
	}.`,
	'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.lighthouseMonitoring': `Lighthouse Monitoring`,
	'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.needsVerification': `You need to <link_verify>verify this website</link_verify> before you can enable JavaScript Rendering and Lighthouse.`,
	'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.title': `Javascript Rendering & Lighthouse`,
	'ui.websites.form.monitoring.lighthouseThresholds.configured': `{usesDefaultThresholds, select,
		yes {Default}
		no {configured}
		other {}
	}`,
	'ui.websites.form.monitoring.lighthouseThresholds.edit': `{usesDefaultThresholds, select,
		yes {Default}
		no {configured}
		other {}
	} (<linkModal>change</linkModal>)`,
	'ui.websites.form.monitoring.lighthouseThresholds.hint': `Choose thresholds for evaluating Conductor Lighthouse Web Vitals.`,
	'ui.websites.form.monitoring.lighthouseThresholds.label': `Web Vitals{br}Settings`,
	'ui.websites.form.monitoring.lighthouseThresholds.modal.description': `
		<p>Here you can set the thresholds for evaluating Conductor Lighthouse Web Vitals. NOTE: changes you make here will apply across the entire website.</p>
		<p>You can always <linkRevertDefault>revert back to the default settings</linkRevertDefault>.</p>
	`,
	'ui.websites.form.monitoring.lighthouseThresholds.modal.sidebar': `
		<p>Conductor Lighthouse Web Vitals gives you an approximation of user experience on your website.</p>
		<p>Your website comes with the same default thresholds for evaluating score of each web vital as is used in Lighthouse.</p>
		<p>Here you can tailor thresholds of each web vital that Conductor Website Monitoring will use for deciding whether the web vital score is good, needs improvement or is poor.</p>
		<p>Learn more about Web Vitals and their thresholds in <linkArticle>our Academy article</linkArticle>.</p>
	`,
	'ui.websites.form.monitoring.lighthouseThresholds.modal.title': `Edit Web Vitals Settings`,
	'ui.websites.form.monitoring.onPageRequestBlocking.configured': `all {mode, select,
		allowAllExcept {allowed}
		denyAllExcept {denied}
		other {}
	} {count__rules, plural,
		=0 {}
		=1 {except # URL pattern}
		other {except # URL patterns}
	}`,
	'ui.websites.form.monitoring.onPageRequestBlocking.edit': `all {mode, select,
		allowAllExcept {allowed}
		denyAllExcept {denied}
		other {}
	} {count__rules, plural,
		=0 {}
		=1 {except # URL pattern}
		other {except # URL patterns}
	} (<link_modal>change</link_modal>)`,
	'ui.websites.form.monitoring.onPageRequestBlocking.hint': `Specify what non-standard on-page requests should be blocked when JS rendering is enabled for this website to prevent your custom analytics or ad tracking services from being triggered.`,
	'ui.websites.form.monitoring.onPageRequestBlocking.label': `On-page request blocking`,
	'ui.websites.form.monitoring.onPageRequestBlocking.warningDescription': `
		<p>Conductor Website Monitoring will start rendering the pages on this website, which may increase the data traffic between Conductor Website Monitoring and the web server.</p>
		<p>Conductor Website Monitoring blocks all major analytics and ad services out of the box, but if you're using a custom analytics or ad service you may need to configure on-page request blocking.</p>
		<p><link_article>Read more in our support article</link_article></p>
	`,
	'ui.websites.form.monitoring.titleAdvancedSettings': `Advanced settings`,
	'ui.websites.form.monitoring.titleMiscellaneous': `Miscellaneous`,
	'ui.websites.form.monitoring.titleSettings': `Settings`,
	'ui.websites.form.monitoringSpeed.higherThanRecommended': `The monitoring speed is set higher than recommended, which may cause undesirably high load on this website.`,
	'ui.websites.form.monitoringSpeed.label': `Speed`,
	'ui.websites.form.monitoringSpeed.lowerThanRecommended': `The monitoring speed is set lower than recommended. This may delay detecting problems and changes.`,
	'ui.websites.form.monitoringSpeed.throttledWarning': `This website has experienced performance problems before. Be careful not to set the speed setting too high.`,
	'ui.websites.form.monitoringSpeed.zero': `When monitoring is paused new problems and changes won't be detected.`,
	'ui.websites.form.offPeakSpeed.label': `Off-peak speed`,
	'ui.websites.form.peakMode.disableLink': `Disable peak / off-peak speeds`,
	'ui.websites.form.peakMode.enableLink': `Set peak / off-peak speeds`,
	'ui.websites.form.peakSpeed.label': `Peak speed`,
	'ui.websites.form.peakTime.label': `Peak time`,
	'ui.websites.form.relevantSearchEngines.label': `Relevant search engines`,
	'ui.websites.form.relevantSearchEngines.selection': `{count__relevantSearchEngines, plural,
		=0 {none}
		=1 {# search engine}
		other {# search engines}
	}`,
	'ui.websites.form.submissionInfo': `We use the billing information you provided in the team settings.`,
	'ui.websites.form.trackH2ToH6Historically.label': `Also track H2-H6 changes`,
	'ui.websites.form.trackNumericOnlyChanges.label': `Also track number-only changes`,
	'ui.websites.form.usingTrialInfo.limitReached': `Your website reached the maximum number of pages allowed in the trial version. Sign up now to prevent missing any important issues on the rest of the pages.`,
	'ui.websites.form.usingTrialInfo.listItems.pagesLimit': `Your trial is limited to {count__pagesAmount, number} pages per website.`,
	'ui.websites.form.usingTrialInfo.listItems.remainingWebsites': `
		You can add {freeWebsites, plural,
			=1 {one more website}
			other {# more websites}
		}.
	`,
	'ui.websites.form.usingTrialInfo.remainingTimeInTrial.day': `
		Your trial will expire in {count, plural,
			=1 {one day}
			other {# days}
		}.
	`,
	'ui.websites.form.usingTrialInfo.remainingTimeInTrial.hour': `
		Your trial will expire in {count, plural,
			=1 {one hour}
			other {# hours}
		}.
	`,
	'ui.websites.form.usingTrialInfo.remainingTimeInTrial.minute': `
		Your trial will expire in {count, plural,
			=1 {one minute}
			other {# minutes}
		}.
	`,
	'ui.websites.form.usingTrialInfo.remainingTimeInTrial.zero': `Your trial will expire now`,
	'ui.websites.form.usingTrialInfo.title': `You are on our free trial.`,
	'ui.websites.form.verified': `Verified`,
	'ui.websites.form.verified.hint': `Certain features are only available for verified websites: JavaScript Rendering, raising the monitoring speed above 100% and setting a Custom User Agent.`,
	'ui.websites.form.verify': `verify`,
	'ui.websites.form.websiteDetailsTitle': `Website Details`,
	'ui.websites.form.websiteMonitoringTitle': `Monitoring Settings`,
	'ui.websites.form.websiteName': `Website name`,
	'ui.websites.formGroups.accountProfile': `Account profile`,
	'ui.websites.formGroups.admin': `Admin`,
	'ui.websites.formGroups.connectedAccounts': `Connected accounts`,
	'ui.websites.formGroups.customElements': `Custom elements`,
	'ui.websites.formGroups.emailSettings': `Email settings`,
	'ui.websites.formGroups.enrichmentFields': `Enrichment fields`,
	'ui.websites.formGroups.indexNow': `Real-time IndexNow`,
	'ui.websites.formGroups.integrations': `Integrations`,
	'ui.websites.formGroups.integrationTokens': `Integration tokens`,
	'ui.websites.formGroups.logFileAnalysis': `Log File Analysis`,
	'ui.websites.formGroups.monitoring': `Monitoring`,
	'ui.websites.formGroups.nonPages': `Non-pages`,
	'ui.websites.formGroups.organizationAccess': `Access Control`,
	'ui.websites.formGroups.segments': `Segments`,
	'ui.websites.formGroups.subscription': `Subscription`,
	'ui.websites.formGroups.websiteDetails': `Website details`,
	'ui.websites.formGroups.websiteMonitoring': `Monitoring`,
	'ui.websites.list.billingIssues': `You can't add a website until billing issues are resolved`,
	'ui.websites.list.limitReached': `You've reached limitations of the trial.<br />{signUpLink} to add more websites.`,
	'ui.websites.list.limitReached.signUpLink': `Sign up now`,
	'ui.websites.list.pages': `pages`,
	'ui.websites.new.changeSummary.overMaximumPages.message': `You have entered {countWebsites, plural,
		=1 {<b># website</b>}
		other {<b># websites</b>}
	} with a total Page Capacity of <b>{numberOfPages, number} pages</b>, but on your current plan you can add max. {maximumNumberOfPages, number} pages to your Page Bundle. <showIntercom>Contact us</showIntercom> if you want to monitor more pages.
	`,
	'ui.websites.new.flashMessages.websiteAdded': `{countWebsites, plural,
		=1 {Website added}
		other {# websites added}
	}`,
	'ui.websites.new.formErrors.alreadyInYourAccount': `You're already monitoring this website.`,
	'ui.websites.new.formErrors.alreadyOnOtherAccounts': `This domain is already being monitored by Conductor Website Monitoring. Please contact us if you are the rightful owner of this domain.`,
	'ui.websites.new.formErrors.blacklisted': `This domain has been excluded from monitoring. If you own it, please contact us.`,
	'ui.websites.new.formErrors.maximumPageBundleExceeded': `Maximum page bundle would be exceeded after adding websites.`,
	'ui.websites.new.formErrors.maximumPageCapacityExceeded': `Maximum page capacity exceeded at least on one website.`,
	'ui.websites.new.formErrors.redirect': `This website redirects to {redirectTarget}. Do you want to add this website instead?`,
	'ui.websites.new.formErrors.redirect.changed': `The domain you entered ({enteredDomain}) redirects to {redirectTarget}. We've updated your entry to the target domain.`,
	'ui.websites.new.formErrors.targetRedirected': `This website is being redirected. Please use the one it's being redirected to, or contact us.`,
	'ui.websites.new.formErrors.tooLargePageBundle': `Too large page bundle.`,
	'ui.websites.new.formErrors.unverifiedWebsitesLimitExceeded': `You can only monitor {maxUnverifiedWebsites, plural,
		=1 {# unverified website}
		other {up to # unverified websites}
	} {canSignupRemoveWebsiteLimit, select,
		yes {in your trial account. You can add more websites after you <link_signup>sign up</link_signup>, or after you <link_support>verify any website</link_support> Conductor Website Monitoring is already monitoring for you.}
		other {in your Conductor Website Monitoring account. Please <link_support>contact us</link_support> if you want to add more websites.}
	}`,
	'ui.websites.new.formErrors.websitesLimitExceeded': `Maximum websites count would be exceeded after adding websites.`,
	'ui.websites.new.legal.notice': `Before using the application, please verify what crawl speed your infrastructure can support and adjust your settings accordingly. If your server does not have capacity to support the selected crawling speed, your website may experience performance issues. You hereby confirm that you are authorized to crawl the website(s) you add.`,
	'ui.websites.new.overlay.churnedAccount.button': `Sign up`,
	'ui.websites.new.overlay.churnedAccount.description': `<p>You need to sign up for a paid plan before you can add new websites.</p>`,
	'ui.websites.new.overlay.churnedAccount.title': `Sign up before adding a website.`,
	'ui.websites.new.overlay.disallowedManagement.button': `Contact us`,
	'ui.websites.new.overlay.disallowedManagement.description': `<p>Please contact us if you wish to make any changes.</p>`,
	'ui.websites.new.overlay.disallowedManagement.title': `This is a managed account.`,
	'ui.websites.new.overlay.maximumWebsitesReached.button': `Sign up`,
	'ui.websites.new.overlay.maximumWebsitesReached.description': `<p>You can only monitor {count__maximumWebsites, plural,
		=1 {# website}
		other {up to # websites}
	} {canSignupRemoveWebsiteLimit, select,
		yes {in your trial account. You can add more by signing up.}
		other {in your Conductor Website Monitoring account. Please contact us if you want to add more websites.}
	}</p>`,
	'ui.websites.new.overlay.maximumWebsitesReached.title': `Your account’s website limit is reached`,
	'ui.websites.new.overlay.paymentIssues.button': `Manage billing`,
	'ui.websites.new.overlay.paymentIssues.description': `<p>Unfortunately your account is having payment issues. Please resolve this before adding a new website.</p>`,
	'ui.websites.new.overlay.paymentIssues.title': `We haven't received your payment`,
	'ui.websites.new.steps.alertsReports.inBreadcrumbs': `Alerts and reports`,
	'ui.websites.new.steps.alertsReports.sidebar': `
		<p><b>Reports</b> contain a summary of everything that happened to all your selected websites over the past week.</p>
		<p><b>Alerts</b> are sent whenever a new serious issue or significant change occurs on the website. You can configure which alerts you want to receive and for which website parts at any time.</p>
	`,
	'ui.websites.new.steps.alertsReports.title': `Set up alerts and reports`,
	'ui.websites.new.steps.integrations.inBreadcrumbs': `Integrations`,
	'ui.websites.new.steps.integrations.sidebar': `
		<p>By connecting to your <b>messaging app</b> important alerts are delivered right into any of your team's messaging channels.</p>
		<p>By connecting to <b>Google Search Console</b> you can see and filter on impressions, clicks and other Google Search Console metrics directly in Conductor Website Monitoring.</p>
		<p>By connecting to <b>Google Analytics</b> you can see and filter on Pageviews, Bounce Rate and other Google Analytics metrics directly in Conductor Website Monitoring.</p>
	`,
	'ui.websites.new.steps.integrations.title': `Set up integrations`,
	'ui.websites.new.steps.multiple.applyPageCapacityButton': `Apply to all`,
	'ui.websites.new.steps.multiple.blankSlate': `Enter your websites in the free text input.`,
	'ui.websites.new.steps.multiple.breadcrumb': `Websites`,
	'ui.websites.new.steps.multiple.sidebar.description': `Pick websites from the suggested websites, or use the free text input.`,
	'ui.websites.new.steps.multiple.sidebar.freeBulkInput.addButton': `Add these websites`,
	'ui.websites.new.steps.multiple.sidebar.freeBulkInput.skippedLines': `{lines, number} skipped`,
	'ui.websites.new.steps.multiple.sidebar.freeBulkInput.skippedLines.tooltip': `Each website needs to be on a separate line, optionally with a , or ; after every line. You can add only websites that aren't in your Conductor Website Monitoring account yet and aren't already in the table on the left.`,
	'ui.websites.new.steps.multiple.sidebar.freeBulkInput.skippedLines.tooltip.limit': `Also, you can only monitor {maxUnverifiedWebsites, plural,
		=1 {# unverified website}
		other {up to # unverified websites}
	} {canSignupRemoveWebsiteLimit, select,
		yes {in your trial account. You can add more after you <link_signup>sign up</link_signup>, or after you <link_support>verify any website</link_support> in your Conductor Website Monitoring account.}
		other {in your Conductor Website Monitoring account. Please <link_support>contact us</link_support> if you want to add more websites.}
	}`,
	'ui.websites.new.steps.multiple.sidebar.maxWebsitesLimitReached': `You can only monitor {maxWebsites, plural,
		=1 {# website}
		other {up to # websites}
	} {canSignupRemoveWebsiteLimit, select,
		yes {in your trial account. You can add more after <link_signup>signing up</link_signup>.}
		other {in your Conductor Website Monitoring account. Please contact us if you want to add more websites.}
	}`,
	'ui.websites.new.steps.multiple.sidebar.suggestedDomains.add': `Add`,
	'ui.websites.new.steps.multiple.sidebar.suggestedDomains.addAll': `Add all websites`,
	'ui.websites.new.steps.multiple.sidebar.suggestedDomains.exhaustedSuggestions': `There are no more websites to suggest to you right now.`,
	'ui.websites.new.steps.multiple.sidebar.suggestedDomains.noSuggestions': `
		<p>There are no websites to suggest to you right now.</p>
		<p>Use the <linkTab>Free text input</linkTab></p>
	`,
	'ui.websites.new.steps.multiple.sidebar.tab.freeTextInput': `Free text input`,
	'ui.websites.new.steps.multiple.sidebar.tab.suggestedWebsites': `Suggested websites`,
	'ui.websites.new.steps.multiple.title': `Add websites`,
	'ui.websites.new.steps.multiple.validationStatus.failed': `<b>{domains, plural,
		=1 {# website}
		other {# websites}
	}</b> can't be added`,
	'ui.websites.new.steps.multiple.validationStatus.failedExplanation': `Conductor Website Monitoring can't reach some of the websites you specified. Click on the message in this footer to see which websites they are, and hover on their individual error icons to find out more for each website. Please <showIntercom>contact us</showIntercom> for help.`,
	'ui.websites.new.steps.multiple.validationStatus.pending': `{domains, plural,
		=1 {<b># website</b> is being validated}
		other {<b># websites</b> are being validated}
	}`,
	'ui.websites.new.steps.single.sidebar.suggestedDomains.addAll': `Add all websites`,
	'ui.websites.new.steps.single.sidebar.suggestedDomains.noSuggestions': `There are no websites to suggest to you right now.`,
	'ui.websites.new.steps.single.sidebar.suggestedDomains.select': `Select`,
	'ui.websites.new.steps.single.sidebar.suggestedDomains.title': `WEBSITE`,
	'ui.websites.new.steps.success.addAnother': `Add another website`,
	'ui.websites.new.steps.success.addMore': `Add more websites`,
	'ui.websites.new.steps.success.description': `
		<p>Congratulations! You have successfully added {domains, plural,
			=1 {<b>{domainName}</b>}
			other {<b># websites</b>}
		} to your Conductor Website Monitoring account.</p>
		<p>From now on, Conductor Website Monitoring will continuously monitor {domains, plural,
			=1 {your domain, track its changes}
			other {them, track their changes}
		} and keep you informed 24/7 about any issues and unexpected changes.</p>
	`,
	'ui.websites.new.steps.success.finish': `OK`,
	'ui.websites.new.steps.success.heading': `{domains, plural,
		=1 {Website was successfully added}
		other {Websites were successfully added}
	}`,
	'ui.websites.new.steps.success.title': `{domains, plural,
		=1 {Add a website}
		other {Add websites}
	}`,
	'ui.websites.new.steps.success.viewSettings': `View settings`,
	'ui.websites.new.steps.success.viewWebsite': `View website`,
	'ui.websites.new.steps.websiteDetails': `Website Details`,
	'ui.websites.new.steps.websiteDetails.extraPageBundleNeeded': `This website's page capacity exceeds your plan's page bundle.`,
	'ui.websites.new.steps.websiteDetails.sidebar': `<p>Here are some websites suggested for you based on websites in your Google Analytics and Google Search Console account.</p>`,
	'ui.websites.new.steps.websiteDetails.withinPageBundle': `This website's page capacity is within your plan's page bundle. You can add it for no extra cost.`,
	'ui.websites.new.title': `Add a website`,
	'ui.websites.overview.table.columns.customElements': `Custom elements`,
	'ui.websites.overview.table.columns.domain': `Domain`,
	'ui.websites.overview.table.columns.integrations': `Integrations`,
	'ui.websites.overview.table.columns.logSources': `Log Sources`,
	'ui.websites.overview.table.columns.name': `Website name`,
	'ui.websites.overview.table.columns.planCapacity': `Page Capacity usage`,
	'ui.websites.overview.table.columns.segments': `Segments`,
	'ui.websites.overview.table.logSourceStatus.available': `Can be installed`,
	'ui.websites.overview.table.logSourceStatus.enabled': `Installed`,
	'ui.websites.overview.table.logSourceStatus.notAvailable': `Not available`,
	'ui.websites.overview.table.planCapacity.values.near': `Near maximum`,
	'ui.websites.overview.table.planCapacity.values.reached': `At maximum`,
	'ui.websites.overview.table.planCapacity.values.within': `Good`,
	'ui.websites.preview.showMoreWebsites': `+{count} more {count, plural,
		=0 {websites}
		=1 {website}
		other {websites}
	}`,
	'ui.websites.views.grid': `Grid`,
	'ui.websites.views.list': `List`,
	'ui.websites.websiteStatus.capacityReached': `Page Capacity reached`,
	'ui.websites.websiteStatus.nearCapacity': `Near Page Capacity`,
	'ui.websites.websiteStatus.paused': `Monitoring paused`,
	'ui.websites.websiteStatus.paymentIssues': `Payment failed`,
	'ui.websiteSettings.plan.header.agency': `Usage Settings`,
	'ui.websiteSettings.plan.header.endUser': `Plan`,
	'ui.websiteSettings.plan.pageCapacity': `Page Capacity`,
	'ui.websiteSettings.plan.pageCapacity.description': `Maximum number of pages that Conductor Website Monitoring will monitor on a website. Read more in <linkSupport>our support article</linkSupport>.`,
	'ui.websiteSettings.plan.pageCapacityLimit.link': `Get in touch`,
	'ui.websiteSettings.plan.pageCapacityLimit.message': `We've prepared a special offer for plans of this size. {linkToIntercomGetInTouch} to find out more.`,
	'ui.websiteSettings.plan.trialLimit.link': `Sign up now`,
	'ui.websiteSettings.plan.trialLimit.pageBundle.message': `During your trial your total available page bundle is limited. {linkToSignUp} to monitor more and larger websites.`,
	'ui.websiteSettings.plan.trialLimit.pageCapacity.message': `During your trial websites are limited to {count__pageBundleSelected, number} pages. {linkToSignUp} to monitor more and larger websites.`,
	'ui.websiteSettingsIndexNowConditionsForm.blockingIssues': `{amount, plural,
		=0 {no blocking issues}
		one {1 blocking issue}
		other {# blocking issues}
	}`,
	'ui.websiteSettingsIndexNowConditionsForm.changeLink': ` (<linkModal>change</linkModal>)`,
	'ui.websiteSettingsIndexNowConditionsForm.issuesBlockingSubmission': `Issues blocking submission`,
	'ui.websiteSettingsIndexNowConditionsForm.segmentsBlocklist': `Segments blocklist`,
	'ui.websiteSettingsIndexNowConditionsForm.segmentsConfigured': `All {mode, select,
		allowAllExcept {allowed}
		denyAllExcept {denied}
		other {}
	} {rules, plural,
		=0 {}
		one {except 1 segment}
		other {except # segments}
	}`,
	'ui.websiteSettingsIndexNowConditionsForm.title': `IndexNow conditions`,
	'ui.websiteSettingsIndexNowConditionsForm.urlBlocklist': `URL blocklist`,
	'ui.websiteSettingsIndexNowConditionsForm.urlsConfigured': `All {mode, select,
		allowAllExcept {allowed}
		denyAllExcept {denied}
		other {}
	} {rules, plural,
		=0 {}
		one {except 1 URL pattern}
		other {except # URL patterns}
	}`,
	'ui.websiteSettingsIndexNowForm.changeKey': `change API key`,
	'ui.websiteSettingsIndexNowForm.enable': `Enable`,
	'ui.websiteSettingsIndexNowForm.submitPages': `Submit pages to IndexNow`,
	'ui.websiteSettingsIndexNowForm.title': `Real-time IndexNow`,
	'ui.websiteSettingsIndexNowTriggersForm.changeLink': ` (<linkModal>change</linkModal>)`,
	'ui.websiteSettingsIndexNowTriggersForm.contentChanges': `Content changes`,
	'ui.websiteSettingsIndexNowTriggersForm.customElementChanges': `Custom Element changes`,
	'ui.websiteSettingsIndexNowTriggersForm.pageStatusChanges': `Page status changes`,
	'ui.websiteSettingsIndexNowTriggersForm.title': `IndexNow triggers`,
	'ui.websiteSettingsIndexNowTriggersForm.triggersConfigured': `{amount, plural,
		=0 {No triggers configured}
		one {1 trigger configured}
		other {# triggers configured}
	}`,
	'ui.websiteVerificationMethods.cloudflareWorker': `<link_logFileAnalysis>Install Cloudflare Worker</link_logFileAnalysis>`,
	'ui.websiteVerificationMethods.googleSearchConsole': `<link_integrations>Connect Google Search Console</link_integrations>`,
	'ui.webVitalMetricsTable.column.metric': `Metric`,
	'ui.webVitalMetricsTable.column.value': `Value`,
	'ui.webVitalMetricsTable.insufficientData': `insufficient data`,
	'ui.webVitals.name.cls': `Cumulative Layout Shift`,
	'ui.webVitals.name.cls.short': `CLS`,
	'ui.webVitals.name.fcp': `First Contentful Paint`,
	'ui.webVitals.name.fcp.short': `FCP`,
	'ui.webVitals.name.fid': `First Input Delay`,
	'ui.webVitals.name.fid.short': `FID`,
	'ui.webVitals.name.inp': `Interaction to Next Paint`,
	'ui.webVitals.name.inp.short': `INP`,
	'ui.webVitals.name.lcp': `Largest Contentful Paint`,
	'ui.webVitals.name.lcp.short': `LCP`,
	'ui.webVitals.name.performance': `Performance`,
	'ui.webVitals.name.performance.short': `Performance`,
	'ui.webVitals.name.si': `Speed Index`,
	'ui.webVitals.name.si.short': `SI`,
	'ui.webVitals.name.tbt': `Total Blocking Time`,
	'ui.webVitals.name.tbt.short': `TBT`,
	'ui.webVitals.name.tti': `Time to Interactive`,
	'ui.webVitals.name.tti.short': `TTI`,
	'ui.webVitals.score.cls': `{score, number, ::scale/0.01 .###}`,
	'ui.webVitals.score.fcp': `{score, number, ::scale/0.001 .0} s`,
	'ui.webVitals.score.fid': `{score, number, ::.} ms`,
	'ui.webVitals.score.lcp': `{score, number, ::scale/0.001 .0} s`,
	'ui.webVitals.score.performance': `{score, number, ::.}`,
	'ui.webVitals.score.si': `{score, number, ::scale/0.001 .0} s`,
	'ui.webVitals.score.tbt': `{score, number, ::.} ms`,
	'ui.webVitals.score.tti': `{score, number, ::scale/0.001 .0} s`,
	'ui.webVitals.tooltip.coreWebVital': `Core Web Vital`,
	'ui.webVitals.tooltip.fast': `{percentage, number, ::percent .} of loads for pages on this website have a good (< {fastThreshold}) {name}.`,
	'ui.webVitals.tooltip.good': `{percentage, number, ::percent .} of loads for pages on this website have a ({fastThreshold} ~ {goodThreshold}) {name} that needs improvement`,
	'ui.webVitals.tooltip.slow': `{percentage, number, ::percent .} of loads for pages on this website have a poor (> {goodThreshold}) {name}.`,
	'ui.webVitals.verdict.good': `Good`,
	'ui.webVitals.verdict.needsImprovement': `Needs improvement`,
	'ui.webVitals.verdict.poor': `Poor`,
};
