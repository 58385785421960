import React from 'react';



function useInitialValue<Value = any>(value: Value): Value {
	const initialValue = React.useRef(value);

	React.useEffect(
		() => {
			if (initialValue.current !== null && initialValue.current !== undefined) {
				return;
			}

			initialValue.current = value;
		},
		[
			initialValue,
			value,
		],
	);

	if (initialValue.current === null || initialValue.current === undefined) {
		return value;
	}

	return initialValue.current;
}



export default useInitialValue;
