import GraphQL from '~/types/graphql';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsDomTracked from '~/hooks/useWebsiteIsDomTracked';

import {
	usePageDetailSourceModeQuery,
} from './usePageDetailSourceMode.gql';



function usePageDetailSourceMode(): GraphQL.PageDetailSourceMode {
	const { data } = usePageDetailSourceModeQuery();

	const websiteId = useWebsiteId();

	const isDomTracked = useWebsiteIsDomTracked(websiteId);

	const user = data?.authenticatedSession?.isImpersonated
		? data.authenticatedSession.kingdomAdmin
		: data?.authenticatedSession?.user;

	if (!isDomTracked) {
		return GraphQL.PageDetailSourceMode.Primary;
	}

	if (!user?.personalTweaks?.pageDetailSourceMode) {
		return GraphQL.PageDetailSourceMode.Primary;
	}

	return user.personalTweaks.pageDetailSourceMode;
}



export default usePageDetailSourceMode;
