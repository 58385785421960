import React from 'react';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import usePrevious from '~/hooks/usePrevious';
import useUserLocale from '~/hooks/useUserLocale';

import {
	location,
} from '~/globals';



const RefreshOnLocaleChange = () => {
	const currentUserId = useCurrentUserId();
	const currentUserLocale = useUserLocale(currentUserId);

	const previousCurrentUserLocale = usePrevious(currentUserLocale) ?? currentUserLocale;

	React.useEffect(
		() => {
			if (
				previousCurrentUserLocale !== null
				&& previousCurrentUserLocale !== currentUserLocale
			) {
				setTimeout(
					() => {
						location.reload(true);
					},
					1000,
				);
			}
		},
		[
			currentUserLocale,
			previousCurrentUserLocale,
		],
	);

	return null;
};



export default RefreshOnLocaleChange;
