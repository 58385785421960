import classNames from 'classnames';
import React from 'react';



export enum PlansComparisonTableCellsStyle {
	Default = 'default',
	Light = 'light',
}

type Props = {
	/** Available cells */
	cells: Array<React.ReactNode>,
	/** Possibility to show higher cell */
	higher?: boolean,
	/** Possible to indent this group of cells by left gap */
	isIndented?: boolean,
	style?: PlansComparisonTableCellsStyle,
};



const PlansComparisonTableCells: React.FC<Props> = (props) => {
	const {
		cells,
		higher,
		isIndented,
		style,
	} = props;

	const componentClasses = classNames({
		'plan-comparison-table-cells': true,
		'plan-comparison-table-cells--higher': higher,
		'plan-comparison-table-cells--is-indented': isIndented,
		['plan-comparison-table-cells--' + style + '-style']: true,
	});

	return (
		<div className={componentClasses}>
			{cells.map((cell, i) => {
				return (
					<div
						className="plan-comparison-table-cells__cell"
						key={'plan-comparison-table-cell-' + i}
					>
						{cell}
					</div>
				);
			})}
		</div>
	);
};



export default PlansComparisonTableCells;
