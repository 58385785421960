/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RenderedDomSnapshotQueryVariables = GraphQL.Exact<{
  timestamp: GraphQL.Scalars['Timestamp']['input'];
  urlId: GraphQL.Scalars['UrlId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type RenderedDomSnapshotQuery = { readonly __typename?: 'Query', readonly responseSnapshot: { readonly __typename?: 'ResponseSnapshot', readonly id: CK.ID | null, readonly isResponseBodyTracked: boolean, readonly isRendered: boolean, readonly dom: { readonly __typename?: 'ResponseSnapshotCode', readonly isTooLarge: boolean, readonly longestLineCharacters: number | null, readonly rows: ReadonlyArray<string> | null } | null } | null };


export const RenderedDomSnapshotDocument = gql`
    query RenderedDomSnapshot($timestamp: Timestamp!, $urlId: UrlId!, $websiteId: WebsiteId!) {
  responseSnapshot(
    timeReceivedAt: $timestamp
    urlId: $urlId
    websiteId: $websiteId
  ) {
    id
    isResponseBodyTracked
    isRendered
    dom {
      isTooLarge
      longestLineCharacters
      rows
    }
  }
}
    `;

/**
 * __useRenderedDomSnapshotQuery__
 *
 * To run a query within a React component, call `useRenderedDomSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenderedDomSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenderedDomSnapshotQuery({
 *   variables: {
 *      timestamp: // value for 'timestamp'
 *      urlId: // value for 'urlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useRenderedDomSnapshotQuery(baseOptions: Apollo.QueryHookOptions<RenderedDomSnapshotQuery, RenderedDomSnapshotQueryVariables> & ({ variables: RenderedDomSnapshotQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RenderedDomSnapshotQuery, RenderedDomSnapshotQueryVariables>(RenderedDomSnapshotDocument, options);
      }
export function useRenderedDomSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RenderedDomSnapshotQuery, RenderedDomSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RenderedDomSnapshotQuery, RenderedDomSnapshotQueryVariables>(RenderedDomSnapshotDocument, options);
        }
export function useRenderedDomSnapshotSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RenderedDomSnapshotQuery, RenderedDomSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RenderedDomSnapshotQuery, RenderedDomSnapshotQueryVariables>(RenderedDomSnapshotDocument, options);
        }
export type RenderedDomSnapshotQueryHookResult = ReturnType<typeof useRenderedDomSnapshotQuery>;
export type RenderedDomSnapshotLazyQueryHookResult = ReturnType<typeof useRenderedDomSnapshotLazyQuery>;
export type RenderedDomSnapshotSuspenseQueryHookResult = ReturnType<typeof useRenderedDomSnapshotSuspenseQuery>;
export type RenderedDomSnapshotQueryResult = Apollo.QueryResult<RenderedDomSnapshotQuery, RenderedDomSnapshotQueryVariables>;