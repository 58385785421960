import React from 'react';

import {
	type ModalFactory,
} from '~/components/logic/DynamicModal';

import useModals from '~/hooks/useModals';



function useOpenModal(
	modalFactory: ModalFactory,
	dependencies: Array<any>,
) {
	const modals = useModals();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const factory = React.useMemo(() => modalFactory, dependencies);

	return React.useCallback(
		() => {
			modals.openModal(factory);
		},
		[
			modals,
			factory,
		],
	);
}



export default useOpenModal;
