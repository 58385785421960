import classNames from 'classnames';
import React from 'react';
import {
	Link,
} from 'react-router5';



export enum SiteLogoBadge {
	CertifiedPartner,
	PoweredByContentKing,
}

type Props = {
	/** Flag showing special badge when custom logo set */
	badge?: SiteLogoBadge,
	/** Possibility to set alt tag for custom logos */
	customLogoAlt?: string,
	/** Source path to custom logo */
	customLogoSrc?: string,
	routeName?: string,
	routeParams?: {},
};



const SiteLogo: React.FC<Props> = (props) => {
	const {
		badge,
		customLogoAlt,
		customLogoSrc,
		routeName,
		routeParams = {},
	} = props;

	const componentClasses = classNames({
		'site-logo': true,
		'site-logo--default': !customLogoSrc,
		'site-logo--custom': customLogoSrc,
	});

	let content = (
		<>ContentKing</>
	);

	if (customLogoSrc) {
		content = (
			<span className="site-logo__container">
				<span className="site-logo__custom-logo">
					<img
						alt={customLogoAlt || 'Logo'}
						src={customLogoSrc}
						title="ContentKing"
					/>
				</span>

				{badge === SiteLogoBadge.CertifiedPartner ? (
					<span className="site-logo__certified-partner-badge">
						ContentKing certified partner
					</span>
				) : badge === SiteLogoBadge.PoweredByContentKing ? (
					<span className="site-logo__powered-by-info">
						powered by ContentKing
					</span>
				) : null}
			</span>
		);
	}

	if (routeName) {
		return (
			<Link
				className={componentClasses}
				routeName={routeName}
				routeParams={routeParams}
			>
				{content}
			</Link>
		);
	}

	return (
		<div className={componentClasses}>
			{content}
		</div>
	);
};



export default SiteLogo;
