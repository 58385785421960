import classNames from 'classnames';
import React from 'react';



type TextualListItemsProp = {
	/** Label of item */
	label: React.ReactNode,
	/** Additional description of item */
	description?: React.ReactNode,
};

type Props = {
	children?: React.ReactNode,
	/** Items can be defined as children of this component of using items prop. Benefit of this prop is that we are able to explain more information */
	items?: Array<TextualListItemsProp>,
	/** Hide dash when list contains only one child */
	textOnlyWhenSingle?: boolean,
};



const TextualList: React.FC<Props> = (props) => {
	const {
		children,
		items,
		textOnlyWhenSingle,
	} = props;

	let elements;

	if (items) {
		elements = items;
	} else {
		elements = React.Children.toArray(children).filter((child) => !!child);
		elements = elements.map((item) => {
			return {
				label: item,
			};
		});
	}

	const componentClasses = classNames({
		'textual-list': true,
		'textual-list--hide-bullets': textOnlyWhenSingle && elements.length < 2,
	});

	return (
		<div className={componentClasses}>
			{elements.map((child, index) => {
				return (
					<div
						className="textual-list__item"
						key={index}
					>
						<div className="textual-list__item-content">
							<span className="textual-list__label">
								{child.label}
							</span>
							{child.description && (
								<span className="textual-list__description">
									{child.description}
								</span>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};



export default TextualList;
