/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegisterMicrosoftTeamsIncomingWebhookMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  incomingWebhookUrl: GraphQL.Scalars['String']['input'];
  label: GraphQL.Scalars['String']['input'];
}>;


export type RegisterMicrosoftTeamsIncomingWebhookMutation = { readonly __typename?: 'Mutation', readonly RegisterMicrosoftTeamsIncomingWebhook: { readonly __typename?: 'RegisterMicrosoftTeamsIncomingWebhookResult', readonly registeredMessagingAppChannelId: CK.ID | null, readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType }> } | null } } };


export const RegisterMicrosoftTeamsIncomingWebhookDocument = gql`
    mutation RegisterMicrosoftTeamsIncomingWebhook($accountId: AccountId!, $incomingWebhookUrl: String!, $label: String!) {
  RegisterMicrosoftTeamsIncomingWebhook(
    accountId: $accountId
    incomingWebhookUrl: $incomingWebhookUrl
    label: $label
  ) {
    query {
      account(id: $accountId) {
        id
        messagingAppChannels {
          id
          label
          messagingAppType
        }
      }
    }
    registeredMessagingAppChannelId
  }
}
    `;
export type RegisterMicrosoftTeamsIncomingWebhookMutationFn = Apollo.MutationFunction<RegisterMicrosoftTeamsIncomingWebhookMutation, RegisterMicrosoftTeamsIncomingWebhookMutationVariables>;

/**
 * __useRegisterMicrosoftTeamsIncomingWebhookMutation__
 *
 * To run a mutation, you first call `useRegisterMicrosoftTeamsIncomingWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMicrosoftTeamsIncomingWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMicrosoftTeamsIncomingWebhookMutation, { data, loading, error }] = useRegisterMicrosoftTeamsIncomingWebhookMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      incomingWebhookUrl: // value for 'incomingWebhookUrl'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useRegisterMicrosoftTeamsIncomingWebhookMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMicrosoftTeamsIncomingWebhookMutation, RegisterMicrosoftTeamsIncomingWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMicrosoftTeamsIncomingWebhookMutation, RegisterMicrosoftTeamsIncomingWebhookMutationVariables>(RegisterMicrosoftTeamsIncomingWebhookDocument, options);
      }
export type RegisterMicrosoftTeamsIncomingWebhookMutationHookResult = ReturnType<typeof useRegisterMicrosoftTeamsIncomingWebhookMutation>;
export type RegisterMicrosoftTeamsIncomingWebhookMutationResult = Apollo.MutationResult<RegisterMicrosoftTeamsIncomingWebhookMutation>;
export type RegisterMicrosoftTeamsIncomingWebhookMutationOptions = Apollo.BaseMutationOptions<RegisterMicrosoftTeamsIncomingWebhookMutation, RegisterMicrosoftTeamsIncomingWebhookMutationVariables>;