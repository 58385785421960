import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const NewTabIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g>
				<path
					d="M19.34,19.38H4.5V5.44
					C4.46,4.92,4.88,4.5,5.4,4.5h5.66c0.51,0.03,0.91,0.43,0.94,0.94v2.68h7.33L19.34,19.38z"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.57,4.5h4.82c0.25,0,0.49,0.1,0.67,0.27
					s0.28,0.42,0.28,0.67v0.93"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.91,10.45v5.81"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.01,13.36h5.8"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default NewTabIconBuilder;

