import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CarouselBlocks from '~/components/patterns/structures/CarouselBlocks';
import IssueListContainer from '~/components/atoms/issues/components/lists/IssueListContainer';
import SectionHeader from '~/components/patterns/headings/SectionHeader';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';

import useViewportType from '~/hooks/useViewportType';
import useViewportWidth from '~/hooks/useViewportWidth';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import {
	TABBED_VIEW_BREAKPOINT,
} from '~/utilities/viewportSize';



const messages = defineMessages({
	issues: {
		id: 'ui.pageDetail.issuesSummary.issues',
	},
	tabClosed: {
		id: 'ui.pageDetail.issuesSummary.type.closed',
	},
	tabOpen: {
		id: 'ui.pageDetail.issuesSummary.type.open',
	},
});

enum Tab {
	Open = 0,
	Closed = 1,
}



type Props = {
	isLoading: boolean,
	renderIssueCategoriesList: RenderProp<{
		isActionable: boolean,
	}>,
};

const IssueCategoriesSidebarSummary: React.FC<Props> = (props) => {
	const {
		isLoading,
		renderIssueCategoriesList,
	} = props;

	const viewportType = useViewportType();
	const viewportWidth = useViewportWidth();

	const [activeTab, setActiveTab] = React.useState(Tab.Open);

	const tabbedView = viewportWidth < TABBED_VIEW_BREAKPOINT;

	const openIssueCategoriesList = renderProp(renderIssueCategoriesList, {
		isActionable: true,
	});

	const closedIssueCategoriesList = renderProp(renderIssueCategoriesList, {
		isActionable: false,
	});

	if (tabbedView) {
		return (
			<IssueListContainer>
				{openIssueCategoriesList}
				{closedIssueCategoriesList}
			</IssueListContainer>
		);
	}

	return (
		<IssueListContainer
			header={(
				<SectionHeader
					controlElements={(
						<div
							style={{
								minWidth: viewportType.isSmall || viewportType.isMedium ? 160 : 200,
							}}
						>
							<TabNavigation>
								<TabNavigationItem
									isActive={activeTab === Tab.Open}
									onClickCallback={() => setActiveTab(Tab.Open)}
									uppercase={true}
								>
									<FormattedMessage {...messages.tabOpen} />
								</TabNavigationItem>
								<TabNavigationItem
									isActive={activeTab === Tab.Closed}
									onClickCallback={() => setActiveTab(Tab.Closed)}
									uppercase={true}
								>
									<FormattedMessage {...messages.tabClosed} />
								</TabNavigationItem>
							</TabNavigation>
						</div>
					)}
					title={(
						<FormattedMessage
							{...messages.issues}
							values={{
								count: 10,
							}}
						/>
					)}
				/>
			)}
			isHeaderFixed={true}
		>
			<CarouselBlocks
				activeSlideIndex={activeTab}
				adaptiveHeight={false}
				draggable={false}
				key={isLoading === false ? 'ready' : 'loading'}
				selectableText={true}
				swipe={false}
			>
				{openIssueCategoriesList}
				{closedIssueCategoriesList}
			</CarouselBlocks>
		</IssueListContainer>
	);
};



export default IssueCategoriesSidebarSummary;
