import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Copy from '~/components/logic/Copy';
import DecentText, {
	DecentTextAlignment,
} from '~/components/patterns/typography/DecentText';
import HelpHint from '~/components/patterns/hints/HelpHint';
import List from '~/components/patterns/lists/List';



const messages = defineMessages({
	adjustPlanHint: {
		id: 'ui.pricing.benefits.adjustPlan.body',
	},
	adjustPlanTitle: {
		id: 'ui.pricing.benefits.adjustPlan.title',
	},
	moneyBackHint: {
		id: 'ui.pricing.benefits.moneyBack.body',
	},
	moneyBackTitle: {
		id: 'ui.pricing.benefits.moneyBack.title',
	},
});



const ListOfBenefits: React.FC = () => {
	return (
		<List>
			<DecentText
				alignment={DecentTextAlignment.Center}
				strong={true}
			>
				<Copy
					{...messages.moneyBackTitle}
					values={{
						hint: (
							<HelpHint
								message={(
									<FormattedMessage {...messages.moneyBackHint} />
								)}
							/>
						),
					}}
				/>
			</DecentText>
			<DecentText
				alignment={DecentTextAlignment.Center}
				strong={true}
			>
				<Copy
					{...messages.adjustPlanTitle}
					values={{
						hint: (
							<HelpHint
								message={(
									<FormattedMessage {...messages.adjustPlanHint} />
								)}
							/>
						),
					}}
				/>
			</DecentText>
		</List>
	);
};



export default ListOfBenefits;
