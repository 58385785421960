import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import Measurer from '~/utilities/Measurer';
import MultiselectListTable from '~/components/atoms/forms/components/MultiselectListTable';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';

import useAllWebsites from '~/hooks/useAllWebsites';



const messages = defineMessages({
	assignedWebsitesCount: {
		id: 'ui.settings.emailNotifications.website.view',
	},
	alertsLabel: {
		id: 'ui.events.title',
	},
	reportsLabel: {
		id: 'ui.settings.emailNotifications.reports',
	},
	websiteLabel: {
		id: 'ui.settings.emailNotifications.website',
	},
});



type Props = {
	accountId: CK.AccountId,
	invitationWebsites: ReadonlyArray<{
		id: CK.WebsiteId,
	}>,
	onSubmitCallback: (values: {
		emailSettings: ReadonlyArray<{
			id: CK.WebsiteId,
			alerts: string,
			reports: string,
		}>,
	}) => void,
};

const AlertsAndReportsForm: React.FC<Props> = (props) => {
	const {
		accountId,
		invitationWebsites,
		onSubmitCallback,
	} = props;

	const allWebsites = useAllWebsites();

	const websites = React.useMemo(
		() => {
			if (allWebsites.isLoaded === false) {
				return [];
			}

			return allWebsites
				.listByUserAccount(accountId)
				.filter((website) => invitationWebsites.some((invitationWebsite) => invitationWebsite.id === website.id))
				.map((website) => ({
					label: website.displayName,
					name: website.id,
				}));
		},
		[
			accountId,
			allWebsites,
			invitationWebsites,
		],
	);

	const handleFormSubmit = React.useCallback(
		(values) => {
			return onSubmitCallback({
				emailSettings: websites.map(({ name: websiteId }) => {
					let alertsStatus = GraphQL.AlertEmailsSetup.Some;

					if (values.assignedWebsites.alerts[websiteId] === true) {
						alertsStatus = GraphQL.AlertEmailsSetup.All;
					} else if (values.assignedWebsites.alerts[websiteId] === false) {
						alertsStatus = GraphQL.AlertEmailsSetup.None;
					}

					return {
						id: websiteId,
						alerts: alertsStatus,
						reports: values.assignedWebsites.weeklyReports[websiteId],
					};
				}),
			});
		},
		[
			onSubmitCallback,
			websites,
		],
	);

	const assignedWebsites = {
		alerts: {},
		weeklyReports: {},
	};

	websites.forEach(({ name: websiteId }) => {
		assignedWebsites.alerts[websiteId] = true;
		assignedWebsites.weeklyReports[websiteId] = true;
	});

	return (
		<Form
			defaultDataHasChanged={true}
			defaultFocus="assigned_websites"
			defaultValues={{
				assignedWebsites,
			}}
			isDisabled={allWebsites.isLoaded === false}
			key={allWebsites.isLoaded === false ? 'loading' : 'ready'}
			onSuccess={handleFormSubmit}
		>
			<div
				style={{
					minHeight: 343,
				}}
			>
				<FormRow
					fullwidth={true}
					htmlFor="assignedWebsites"
				>
					<Measurer>
						{({ containerWidth }) => (
							<MultiselectListTable
								columns={[
									{
										name: 'weeklyReports',
										label: (
											<FormattedMessage {...messages.reportsLabel} />
										),
									},
									{
										name: 'alerts',
										label: (
											<FormattedMessage {...messages.alertsLabel} />
										),
									},
								]}
								label={(
									<FormattedMessage {...messages.websiteLabel} />
								)}
								name="assignedWebsites"
								rows={websites}
								tableHeight={275}
								tableWidth={containerWidth}
							/>
						)}
					</Measurer>
				</FormRow>
			</div>

			<ButtonsLayout layout={ButtonsLayoutType.Steps}>
				<SaveSubmitButton />
			</ButtonsLayout>
		</Form>
	);
};



export default AlertsAndReportsForm;
