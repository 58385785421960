import type CK from '~/types/contentking';

import {
	useAccountConfirmationNameQuery,
} from './useAccountConfirmationName.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountConfirmationName(accountId: CK.AccountId | null): string | null {
	const { data } = useAccountPropertiesQuery(
		useAccountConfirmationNameQuery,
		accountId,
	);

	return data?.account?.confirmationName ?? null;
}



export default useAccountConfirmationName;
