import {
	IssueCategoryName,
	isRecognizedIssueCategory,
	isRecognizedIssueCategoryName,
} from '~/model/issuesNew';

import {
	getAbsoluteCategoryType,
} from '~/model/issues/identifierMapping';



function useGetIssueCategory<TItem extends { name: string }>(
	issueCategories: ReadonlyArray<TItem> | null,
	issueCategoryName: string | null,
): (TItem & { name: IssueCategoryName }) | null {
	if (issueCategories === null || issueCategoryName === null) {
		return null;
	}

	if (Object.values(IssueCategoryName).includes(issueCategoryName as any) === false) {
		issueCategoryName = getAbsoluteCategoryType(issueCategoryName);
	}

	if (issueCategoryName === null) {
		return null;
	}

	if (!isRecognizedIssueCategoryName(issueCategoryName)) {
		return null;
	}

	return issueCategories.filter(isRecognizedIssueCategory).find(
		(issueCategory) => issueCategory.name === issueCategoryName,
	) ?? null;
}



export default useGetIssueCategory;
