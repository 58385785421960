import type CK from '~/types/contentking';

import useBatchContext from '~/hooks/useBatchContext';



function useBatchContextPlatform(
	websiteId: CK.WebsiteId | null,
) {
	return useBatchContext(
		`platform/${websiteId ?? ''}`,
		999,
	);
}



export default useBatchContextPlatform;
