import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Announcement, {
	AnnouncementStyle,
} from '~/components/patterns/messages/embedded/Announcement';
import Copy from '../Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FormWrapperContainer, {
	FormWrapperContainerSize,
} from '~/components/patterns/forms/wrappers/FormWrapperContainer';
import PlanName from '~/components/names/PlanName';
import PremiumAnnouncement from '~/components/patterns/messages/embedded/PremiumAnnouncement';
import SubmitButton from '~/components/app/SubmitButton';

import useAccountId from '~/hooks/useAccountId';
import useAccountPremiumTrialOffer from '~/hooks/useAccountPremiumTrialOffer';
import useAccountTariff from '~/hooks/useAccountTariff';
import useActivatePremiumTrial from '~/hooks/useActivatePremiumTrial';



const messages = defineMessages({
	button: {
		id: 'ui.upsell.box.button',
	},
	text: {
		id: 'ui.upsell.box.text',
	},
	title: {
		id: 'ui.upsell.box.title',
	},
});



const PremiumTrialForm: React.FC = () => {
	const accountId = useAccountId();

	const accountPremiumTrialOffer = useAccountPremiumTrialOffer(accountId);
	const accountTariff = useAccountTariff(accountId);
	const activatePremiumTrial = useActivatePremiumTrial(accountId);

	if (
		accountPremiumTrialOffer === null
		|| accountTariff === null
	) {
		return null;
	}

	return (
		<FormWrapperContainer size={FormWrapperContainerSize.Form}>
			<PremiumAnnouncement>
				<Announcement
					ctaElement={(
						<Form
							defaultDataHasChanged={true}
							onSuccess={activatePremiumTrial}
						>
							<SubmitButton>
								<FormattedMessage {...messages.button} />
							</SubmitButton>
						</Form>
					)}
					style={AnnouncementStyle.Light}
					title={(
						<Copy
							{...messages.title}
							values={{
								days: accountPremiumTrialOffer.durationInDays,
								plan: (
									<PlanName plan={accountPremiumTrialOffer.plan} />
								),
							}}
						/>
					)}
				>
					<p>
						<strong>
							<FormattedMessage
								{...messages.text}
								values={{
									plan: accountPremiumTrialOffer.plan,
									tariff: accountTariff,
								}}
							/>
						</strong>
					</p>
				</Announcement>
			</PremiumAnnouncement>
		</FormWrapperContainer>
	);
};



export default PremiumTrialForm;
