import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ConductorIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				stroke="none"
			>
				<circle
					cx="7.625"
					cy="7.625"
					fill={color}
					r="2.4"
				/>
				<circle
					cx="13"
					cy="7.625"
					fill={color}
					r="2.4"
				/>
				<circle
					cx="7.625"
					cy="13"
					fill={color}
					r="2.4"
				/>
				<circle
					cx="13"
					cy="13"
					fill={color}
					r="2.4"
				/>
				<circle
					cx="18.375"
					cy="13"
					fill={color}
					r="2.4"
				/>
				<circle
					cx="13"
					cy="18.375"
					fill={color}
					r="2.4"
				/>
				<circle
					cx="18.375"
					cy="18.375"
					fill={color}
					r="2.4"
				/>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default ConductorIconBuilder;
