import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountEndUserPlanQuery,
} from './useAccountEndUserPlan.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountEndUserPlan(accountId: CK.AccountId | null): GraphQL.Account['endUserPlan'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountEndUserPlanQuery,
		accountId,
	);

	return data?.account?.endUserPlan ?? null;
}



export default useAccountEndUserPlan;
