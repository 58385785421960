import memoizee from 'memoizee';

import {
	get,
} from '~/model/api/API';

import {
	calculateAllPointsInRange,
} from '~/model/rangeChoices';

import tariffC1 from './c1';
import tariffC2 from './c2';
import tariffV2 from './v2';
import tariffV3 from './v3';
import tariffV4 from './v4';



export enum Tariff {
	C1 = 'c1',
	C2 = 'c2',
	V2 = 'v2',
	V3 = 'v3',
	V4 = 'v4',
}



const TARIFFS = {
	[Tariff.C1]: tariffC1,
	[Tariff.C2]: tariffC2,
	[Tariff.V2]: tariffV2,
	[Tariff.V3]: tariffV3,
	[Tariff.V4]: tariffV4,
};



function getTariff(tariff: Tariff) {
	return TARIFFS[tariff];
}



export function loadTariffsViaApi() {
	return get('/pricing');
}



export function choosePlan({ accountType, billingCycle, details, tariff }) {
	return getTariff(tariff).choosePlan({
		accountType,
		billingCycle,
		details,
	});
}



export function convertDetailsToAddons({ accountType, details, tariff }) {
	return getTariff(tariff).convertDetailsToAddons({
		accountType,
		details,
	});
}



export function convertPagesToAddons({ accountType, numberOfPages, tariff }) {
	return getTariff(tariff).convertPagesToAddons({
		accountType,
		numberOfPages,
	});
}



export function normalizePageCapacity({ accountType, pageCapacity, tariff }) {
	return getTariff(tariff).normalizePageCapacity({
		accountType,
		pageCapacity,
	});
}



export function normalizePagesAmount({ accountType, numberOfPages, tariff }) {
	return getTariff(tariff).normalizePagesAmount({
		accountType,
		numberOfPages,
	});
}



export const listAllPossiblePagesAmounts = memoizee((accountType, tariff, maximumPageCapacity = Infinity) => {
	return calculateAllPointsInRange(
		0,
		maximumPageCapacity,
		(numberOfPages) => getTariff(tariff).normalizePagesAmount({
			accountType,
			numberOfPages,
		}),
	);
}, {
	length: 3,
});



export const listAllPossiblePageCapacities = memoizee((accountType, tariff, maximumPageCapacity = null) => {
	const allPossiblePagesAmounts = listAllPossiblePagesAmounts(accountType, tariff, maximumPageCapacity);

	return calculateAllPointsInRange(
		0,
		maximumPageCapacity || allPossiblePagesAmounts[allPossiblePagesAmounts.length - 1],
		(pageCapacity) => getTariff(tariff).normalizePageCapacity({
			accountType,
			pageCapacity,
		}),
	);
}, {
	length: 3,
});
