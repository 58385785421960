import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import IgnoringMessage from '~/components/patterns/messages/embedded/IgnoringMessage';
import IgnoringModalBodyLayout from '~/components/atoms/issues/components/modals/IgnoringModalBodyLayout';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/atoms/forms/components/SubmitButton';

import {
	useIgnorePageIssueOnWholeWebsiteMutation,
	useIgnorePlatformIssueOnWholeWebsiteMutation,
	useUnignorePageIssueOnWholeWebsiteMutation,
	useUnignorePlatformIssueOnWholeWebsiteMutation,
} from './IgnoreOnWebsite.gql';

import useTrackIgnoringUpdateInPendo from '~/hooks/useTrackIgnoringUpdateInPendo';



const messages = defineMessages({
	descriptionDefault: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignore.description',
	},
	descriptionIgnored: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignoringActive.description',
	},
	ignoreButton: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignore.button',
	},
	stopIgnoringButton: {
		id: 'ui.issueDetail.ignoringModal.scope.general.button.stopIgnoring',
	},
});

const alertWarningMessages = defineMessages({
	default: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignore.bulletList.alerts',
	},
	ignored: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignoringActive.bulletList.alerts',
	},
});

const reportsWarningMessages = defineMessages({
	default: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignore.bulletList.reports',
	},
	ignored: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignoringActive.bulletList.reports',
	},
});



type Props = {
	isIgnored: boolean,
	isPlatformIssue: boolean,
	issueName: string,
	reloadCallback: () => Promise<void>,
	websiteId: CK.WebsiteId,
};

const IgnoreOnWebsite: React.FC<Props> = (props) => {
	const {
		isIgnored,
		isPlatformIssue,
		issueName,
		reloadCallback,
		websiteId,
	} = props;

	const trackIgnoringUpdateInPendo = useTrackIgnoringUpdateInPendo();

	const [ignoringInProgress, setIgnoringInProgress] = React.useState(false);

	const [ignorePageIssueOnWholeWebsite] = useIgnorePageIssueOnWholeWebsiteMutation();
	const [ignorePlatformIssueOnWholeWebsite] = useIgnorePlatformIssueOnWholeWebsiteMutation();
	const [unignorePageIssueOnWholeWebsite] = useUnignorePageIssueOnWholeWebsiteMutation();
	const [unignorePlatformIssueOnWholeWebsite] = useUnignorePlatformIssueOnWholeWebsiteMutation();

	const handleClickIgnoreLink = React.useCallback(
		async (e) => {
			e.preventDefault();

			setIgnoringInProgress(true);

			if (isIgnored) {
				if (isPlatformIssue) {
					await unignorePlatformIssueOnWholeWebsite({
						variables: {
							issueName,
							websiteId,
						},
					});
				} else {
					await unignorePageIssueOnWholeWebsite({
						variables: {
							issueName,
							websiteId,
						},
					});
				}
			} else {
				if (isPlatformIssue) {
					await ignorePlatformIssueOnWholeWebsite({
						variables: {
							issueName,
							websiteId,
						},
					});
				} else {
					await ignorePageIssueOnWholeWebsite({
						variables: {
							issueName,
							websiteId,
						},
					});
				}
			}

			trackIgnoringUpdateInPendo(
				websiteId,
				issueName,
				isIgnored === false,
				'website',
			);

			await reloadCallback();

			setIgnoringInProgress(false);
		},
		[
			isIgnored,
			isPlatformIssue,
			issueName,
			ignorePageIssueOnWholeWebsite,
			ignorePlatformIssueOnWholeWebsite,
			reloadCallback,
			trackIgnoringUpdateInPendo,
			unignorePageIssueOnWholeWebsite,
			unignorePlatformIssueOnWholeWebsite,
			websiteId,
		],
	);

	const flag = isIgnored ? 'ignored' : 'default';

	return (
		<IgnoringModalBodyLayout
			button={(
				<SubmitButton
					onClickCallback={handleClickIgnoreLink}
					progress={ignoringInProgress}
				>
					{isIgnored ? (
						<FormattedMessage {...messages.stopIgnoringButton} />
					) : (
						<FormattedMessage {...messages.ignoreButton} />
					)}
				</SubmitButton>
			)}
		>
			{isIgnored ? (
				<IgnoringMessage>
					<Copy
						{...messages.descriptionIgnored}
						values={{
							count__issues: 1,
						}}
					/>
				</IgnoringMessage>
			) : (
				<RichText>
					<Copy
						{...messages.descriptionDefault}
						values={{
							count__issues: 1,
						}}
					/>
				</RichText>
			)}

			<RichText>
				<ul>
					<li>
						<FormattedMessage
							{...alertWarningMessages[flag]}
							values={{
								count__issues: 1,
							}}
						/>
					</li>
					<li>
						<FormattedMessage
							{...reportsWarningMessages[flag]}
							values={{
								count__issues: 1,
							}}
						/>
					</li>
				</ul>
			</RichText>
		</IgnoringModalBodyLayout>
	);
};



export default IgnoreOnWebsite;
