import classNames from 'classnames';
import React from 'react';

import styles from './AbstractBoxContent.part.module.scss';



export enum AbstractBoxContentScope {
	Header = 'header',
	Content = 'content',
}



type Props = {
	children?: React.ReactNode,
	/** Customizable size of content padding */
	paddingSize: 0 | 1 | 2 | 3,
	scope: AbstractBoxContentScope,
};

const AbstractBoxContent: React.FC<Props> = (props) => {
	const {
		children,
		paddingSize,
		scope = AbstractBoxContentScope.Content,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.contentScope]: scope === AbstractBoxContentScope.Content,
		[styles.hasSomeGaps]: paddingSize > 0,
		[styles.largeGaps]: paddingSize === 2,
		[styles.xlargeGaps]: paddingSize === 3,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default AbstractBoxContent;
