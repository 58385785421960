import classNames from 'classnames';
import React from 'react';



export type InteractiveTableColumnRef = HTMLDivElement | null;

type Props = {
	children: React.ReactNode,
	header: React.ReactNode,
	headerHeight?: number,
	height?: React.CSSProperties['height'],
	isHighlighted?: boolean,
	onClickCallback?: () => void,
	width?: React.CSSProperties['width'],
};

const InteractiveTableColumn = React.forwardRef<InteractiveTableColumnRef, Props>((props, ref) => {
	const {
		header,
		headerHeight,
		height,
		children,
		isHighlighted,
		onClickCallback,
		width,
	} = props;

	const contentRef = React.useRef<HTMLDivElement>(null);
	const headerRef = React.useRef<HTMLDivElement>(null);

	const componentClasses = classNames({
		'interactive-table-column': true,
		'interactive-table-column--is-clickable': onClickCallback,
		'interactive-table-column--is-highlighted': isHighlighted,
	});

	const handleClick = React.useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (
				event.target !== contentRef.current
				&& event.target !== headerRef.current
				&& (
					!(event.target instanceof Node)
					|| !headerRef.current?.contains(event.target)
				)
			) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();

			if (onClickCallback) {
				onClickCallback();
			}
		},
		[
			onClickCallback,
		],
	);

	return (
		<div
			className={componentClasses}
			onClick={handleClick}
			ref={ref}
			style={{
				height,
				width,
			}}
		>
			<div
				className="interactive-table-column__header"
				ref={headerRef}
				style={{
					minHeight: headerHeight && (headerHeight - 1),
				}}
			>
				{header}
			</div>
			<div
				className="interactive-table-column__content"
				ref={contentRef}
			>
				{children}
			</div>
		</div>
	);
});



export default InteractiveTableColumn;
