import {
	addDays,
	format,
	formatISO,
	isBefore,
	isWithinInterval,
	parseISO,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DateAndTimeLabel from '~/components/app/DateAndTimeLabel';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormSentence from '~/components/atoms/forms/basis/FormSentence';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useAccountAdminCustomerPhaseFormQuery,
	useUpdateAccountAdminCustomerPhaseSettingsMutation,
} from './AccountAdminCustomerPhaseForm.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';
import useAccountUnpaidSituation from '~/hooks/useAccountUnpaidSituation';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const nonRenewalProperties = [
	'daysOfNonRenewalGracePeriod',
	'daysOfNonRenewalSuspension',
] as const;

const paymentFailureProperties = [
	'daysOfPaymentFailureGracePeriod',
	'daysOfPaymentFailureNoticeToBillingManagers',
	'daysOfPaymentFailureNoticeToAllUsers',
	'daysOfPaymentFailureSuspension',
] as const;



const validations = {
	daysOfNonRenewalGracePeriod: validateField(
		'daysOfNonRenewalGracePeriod',
		(f) => [
			f.validateMinimumValue(0),
		],
	),
	daysOfNonRenewalSuspension: validateField(
		'daysOfNonRenewalSuspension',
		(f) => [
			f.validateMinimumValue(0),
		],
	),
	daysOfPaymentFailureGracePeriod: validateField(
		'daysOfPaymentFailureGracePeriod',
		(f) => [
			f.validateMinimumValue(0),
		],
	),
	daysOfPaymentFailureNoticeToAllUsers: validateField(
		'daysOfPaymentFailureNoticeToAllUsers',
		(f) => [
			f.validateMinimumValue(0),
		],
	),
	daysOfPaymentFailureNoticeToBillingManagers: validateField(
		'daysOfPaymentFailureNoticeToBillingManagers',
		(f) => [
			f.validateMinimumValue(0),
		],
	),
	daysOfPaymentFailureSuspension: validateField(
		'daysOfPaymentFailureSuspension',
		(f) => [
			f.validateMinimumValue(0),
		],
	),
};

function createDaysDescriptor<TItem>(
	items: ReadonlyArray<TItem>,
	description: string,
	getter: (item: TItem) => number,
	now: Date,
	date: Date | null = null,
) {
	return (theItem) => describeDays(
		items
			.slice(0, items.findIndex((item) => item === theItem) + 1)
			.map((item) => getter(item)),
		description,
		items.findIndex((item) => item === theItem) === items.length - 1,
		now,
		date,
	);
}



function describeDays(
	items: ReadonlyArray<number>,
	main: string,
	isLast: boolean,
	now: Date,
	date: Date | null,
) {
	const thisItem = getArrayItemAtSafeIndex(items, items.length - 1);
	const previousItems = items.slice(0, -1);
	const previousTotal = previousItems.reduce((total, value) => total + value, 0);
	const isSkipped = thisItem === 0;

	const lastAppendix = isLast ? ', cleanup ' + ((previousTotal + thisItem) > 0 ? (previousTotal + thisItem) + ' days after' : 'right after') + ' ' + main : '';

	let dateDescription: React.ReactNode | null = null;

	if (date !== null) {
		const startDate = addDays(date, previousTotal);
		const endDate = addDays(date, previousTotal + thisItem - 1);

		const isCurrent = isWithinInterval(
			now,
			{
				start: startDate,
				end: isLast ? addDays(new Date(), 365 * 50) : addDays(endDate, 1),
			},
		);

		const isCurrentPrecisely = isWithinInterval(
			now,
			{
				start: startDate,
				end: addDays(endDate, 1),
			},
		);

		if (isSkipped) {
			if (isLast && isCurrent) {
				dateDescription = (
					<span
						style={{
							color: 'red',
						}}
					>
						<b>[PAST!]</b>
					</span>
				);
			}
		} else {
			dateDescription = (isCurrent ? (isCurrentPrecisely ? '[CURRENT] ' : '[PAST!] ') : '') + (
				format(startDate, 'yyyy-MM-dd') !== format(endDate, 'yyyy-MM-dd') ? (
					'from ' + format(startDate, 'yyyy-MM-dd') + ' to ' + format(endDate, 'yyyy-MM-dd')
				) : (
					'during ' + format(startDate, 'yyyy-MM-dd')
				)
			);

			if (isCurrent) {
				dateDescription = (
					<b>{dateDescription}</b>
				);

				if (isLast) {
					dateDescription = (
						<span
							style={{
								color: 'red',
							}}
						>
							{dateDescription}
						</span>
					);
				}
			}
		}
	}

	if (isSkipped) {
		return [
			'skipped' + lastAppendix,
			dateDescription,
		];
	}

	if (previousTotal > 0) {
		return [
			previousTotal + ' days after ' + main + lastAppendix,
			dateDescription,
		];
	}

	return [
		'right after ' + main + lastAppendix,
		dateDescription,
	];
}



type Props = {
	accountId: CK.AccountId,
	accountPhase: GraphQL.AccountPhase,
};

const AccountAdminCustomerPhaseForm: React.FC<Props> = (props) => {
	const {
		accountId,
		accountPhase,
	} = props;

	const unpaidSituation = useAccountUnpaidSituation(accountId);

	const { data } = useAccountPropertiesQuery(
		useAccountAdminCustomerPhaseFormQuery,
		accountId,
	);

	const [updateAccountAdminCustomerPhaseSettings] = useUpdateAccountAdminCustomerPhaseSettingsMutation();

	const accountData = data?.account ?? null;
	const adminSettings = accountData?.adminSettings ?? null;
	const premiumTrial = accountData?.premiumTrial ?? null;

	const handleSubmit = React.useCallback(
		async (values) => {
			if (adminSettings === null) {
				throw new Error(`adminSettings can't be null`);
			}

			await updateAccountAdminCustomerPhaseSettings({
				variables: {
					accountId,
					daysOfPaymentFailureGracePeriod: values.daysOfPaymentFailureGracePeriod,
					daysOfPaymentFailureNoticeToBillingManagers: values.daysOfPaymentFailureNoticeToBillingManagers,
					daysOfPaymentFailureNoticeToAllUsers: values.daysOfPaymentFailureNoticeToAllUsers,
					daysOfPaymentFailureSuspension: values.daysOfPaymentFailureSuspension,
					daysOfNonRenewalGracePeriod: values.daysOfNonRenewalGracePeriod,
					daysOfNonRenewalSuspension: values.daysOfNonRenewalSuspension,
					daysToPremiumTrialRevival: values.daysToPremiumTrialRevival ?? adminSettings.daysToPremiumTrialRevival,
					isPremiumTrialRevivalAllowedByCustomerTeam: values.isPremiumTrialRevivalAllowedByCustomerTeam,
					premiumTrialExpirationDate: premiumTrial !== null
						? formatISO(
							new Date(values.time_premium_trial_ends_date + ' ' + values.time_premium_trial_ends_time),
						)
						: null,
				},
			});
		},
		[
			accountId,
			adminSettings,
			premiumTrial,
			updateAccountAdminCustomerPhaseSettings,
		],
	);

	if (
		accountData === null
		|| adminSettings === null
	) {
		return null;
	}

	function renderPremiumTrialEndWarning(timePremiumTrialEndsDate, timePremiumTrialEndsTime) {
		const now = new Date();
		const timePremiumTrialEnds = new Date(timePremiumTrialEndsDate + ' ' + timePremiumTrialEndsTime);

		if (isBefore(timePremiumTrialEnds, now)) {
			return (
				<div>
					You set <b>Premium trial expiry date</b> to past - this will <b>END PREMIUM TRIAL IMMEDIATELY</b>.
				</div>
			);
		}

		return null;
	}

	const describePaymentFailure = createDaysDescriptor(
		paymentFailureProperties,
		'payment failure',
		(item) => adminSettings[item],
		new Date(),
		unpaidSituation?.unpaidSituation === GraphQL.AccountUnpaidSituation.PaymentFailure
			? new Date(unpaidSituation.unpaidSince)
			: null,
	);

	const describeNonRenewalFailure = createDaysDescriptor(
		nonRenewalProperties,
		'end of subscription',
		(item) => adminSettings[item],
		new Date(),
		unpaidSituation?.unpaidSituation === GraphQL.AccountUnpaidSituation.NonRenewal
			? new Date(unpaidSituation.unpaidSince)
			: null,
	);

	return (
		<>
			<DisplayPart>
				<FormRows>
					<FormRow label="Account phase">
						<StaticText>
							{accountPhase}
						</StaticText>
					</FormRow>

					{premiumTrial !== null && (
						<FormRow label="Premium trial expires at">
							<StaticText focusTarget="time_premium_trial_ends_date">
								<DateAndTimeLabel timestamp={parseISO(premiumTrial.expirationDate)} />
							</StaticText>
						</FormRow>
					)}

					<FormRow label="Premium trial revival enabled">
						<StaticText focusTarget="isPremiumTrialRevivalAllowedByCustomerTeam">
							{adminSettings.isPremiumTrialRevivalAllowedByCustomerTeam ? 'yes' : 'no'}
						</StaticText>
					</FormRow>

					{adminSettings.isPremiumTrialRevivalAllowedByCustomerTeam && (
						<FormRow label="Premium trial revival">
							<StaticText focusTarget="daysToPremiumTrialRevival">
								{adminSettings.daysToPremiumTrialRevival} days after previous premium trial end
							</StaticText>
						</FormRow>
					)}

					<FormRow
						description={describePaymentFailure('daysOfPaymentFailureGracePeriod')}
						htmlFor="daysOfPaymentFailureGracePeriod"
						label="PF grace period"
					>
						<StaticText focusTarget="daysOfPaymentFailureGracePeriod">
							{adminSettings.daysOfPaymentFailureGracePeriod} days
						</StaticText>
					</FormRow>

					<FormRow
						description={describePaymentFailure('daysOfPaymentFailureNoticeToBillingManagers')}
						htmlFor="daysOfPaymentFailureNoticeToBillingManagers"
						label="PF notice to billing managers"
					>
						<StaticText focusTarget="daysOfPaymentFailureNoticeToBillingManagers">
							{adminSettings.daysOfPaymentFailureNoticeToBillingManagers} days
						</StaticText>
					</FormRow>

					<FormRow
						description={describePaymentFailure('daysOfPaymentFailureNoticeToAllUsers')}
						htmlFor="daysOfPaymentFailureNoticeToAllUsers"
						label="PF notice to all users"
					>
						<StaticText focusTarget="daysOfPaymentFailureNoticeToAllUsers">
							{adminSettings.daysOfPaymentFailureNoticeToAllUsers} days
						</StaticText>
					</FormRow>

					<FormRow
						description={describePaymentFailure('daysOfPaymentFailureSuspension')}
						htmlFor="daysOfPaymentFailureSuspension"
						label="PF suspension"
					>
						<StaticText focusTarget="daysOfPaymentFailureSuspension">
							{adminSettings.daysOfPaymentFailureSuspension} days
						</StaticText>
					</FormRow>

					<FormRow
						description={describeNonRenewalFailure('daysOfNonRenewalGracePeriod')}
						htmlFor="daysOfNonRenewalGracePeriod"
						label="NR grace period"
					>
						<StaticText focusTarget="daysOfNonRenewalGracePeriod">
							{adminSettings.daysOfNonRenewalGracePeriod} days
						</StaticText>
					</FormRow>

					<FormRow
						description={describeNonRenewalFailure('daysOfNonRenewalSuspension')}
						htmlFor="daysOfNonRenewalSuspension"
						label="NR suspension"
					>
						<StaticText focusTarget="daysOfNonRenewalSuspension">
							{adminSettings.daysOfNonRenewalSuspension} days
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						daysOfPaymentFailureGracePeriod: adminSettings.daysOfPaymentFailureGracePeriod,
						daysOfPaymentFailureNoticeToBillingManagers: adminSettings.daysOfPaymentFailureNoticeToBillingManagers,
						daysOfPaymentFailureNoticeToAllUsers: adminSettings.daysOfPaymentFailureNoticeToAllUsers,
						daysOfPaymentFailureSuspension: adminSettings.daysOfPaymentFailureSuspension,
						daysOfNonRenewalGracePeriod: adminSettings.daysOfNonRenewalGracePeriod,
						daysOfNonRenewalSuspension: adminSettings.daysOfNonRenewalSuspension,
						daysToPremiumTrialRevival: adminSettings.daysToPremiumTrialRevival,
						isPremiumTrialRevivalAllowedByCustomerTeam: adminSettings.isPremiumTrialRevivalAllowedByCustomerTeam,
						time_premium_trial_ends_date: premiumTrial !== null ? format(parseISO(premiumTrial.expirationDate), 'yyyy-MM-dd') : undefined,
						time_premium_trial_ends_time: premiumTrial !== null ? format(parseISO(premiumTrial.expirationDate), 'HH:mm') : undefined,
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					{({ values }) => {
						const describePaymentFailure = createDaysDescriptor(
							paymentFailureProperties,
							'payment failure',
							(item) => values[item] * 1,
							new Date(),
							unpaidSituation?.unpaidSituation === GraphQL.AccountUnpaidSituation.PaymentFailure
								? new Date(unpaidSituation.unpaidSince)
								: null,
						);

						const describeNonRenewalFailure = createDaysDescriptor(
							nonRenewalProperties,
							'end of subscription',
							(item) => values[item] * 1,
							new Date(),
							unpaidSituation?.unpaidSituation === GraphQL.AccountUnpaidSituation.NonRenewal
								? new Date(unpaidSituation.unpaidSince)
								: null,
						);

						return (
							<>
								<FormRows>
									<FormRow label="Account phase">
										<StaticText>
											{accountPhase}
										</StaticText>
									</FormRow>

									<FormRow label="Premium trial active">
										<StaticText>
											{premiumTrial !== null ? 'yes' : 'no'}
										</StaticText>
									</FormRow>

									{premiumTrial !== null && (
										<FormRow
											description={renderPremiumTrialEndWarning(values.time_premium_trial_ends_date, values.time_premium_trial_ends_time)}
											htmlFor="time_premium_trial_expires"
											label="Premium trial expires at"
										>
											<FieldStatus name="time_premium_trial_expires">
												<TextField
													name="time_premium_trial_ends_date"
													resetButton={false}
													trimValue={true}
													type={TextFieldType.Date}
													width={160}
												/>
												<TextField
													name="time_premium_trial_ends_time"
													resetButton={false}
													trimValue={true}
													type={TextFieldType.Time}
													width={120}
												/>
											</FieldStatus>
										</FormRow>
									)}

									<FormRow
										htmlFor="isPremiumTrialRevivalAllowedByCustomerTeam"
										label="Premium trial revival enabled"
									>
										<CheckboxField
											label="yes"
											name="isPremiumTrialRevivalAllowedByCustomerTeam"
											width={false}
										/>
									</FormRow>

									{values.isPremiumTrialRevivalAllowedByCustomerTeam && (
										<FormRow
											htmlFor="daysToPremiumTrialRevival"
											label="Premium trial revival"
										>
											<FieldStatus name="daysToPremiumTrialRevival">
												<FormSentence>
													after
													<TextField
														attributes={{
															min: 0,
														}}
														name="daysToPremiumTrialRevival"
														placeholder="days after previous premium trial end"
														trimValue={true}
														type={TextFieldType.Number}
														width={100}
													/> days
												</FormSentence>
											</FieldStatus>
										</FormRow>
									)}

									<FormRow
										description={describePaymentFailure('daysOfPaymentFailureGracePeriod')}
										htmlFor="daysOfPaymentFailureGracePeriod"
										label="PF grace period"
									>
										<FieldStatus name="daysOfPaymentFailureGracePeriod">
											<FormSentence>
												<TextField
													attributes={{
														min: 0,
													}}
													name="daysOfPaymentFailureGracePeriod"
													placeholder="days"
													trimValue={true}
													type={TextFieldType.Number}
													width={100}
												/> days
											</FormSentence>
										</FieldStatus>
									</FormRow>

									<FormRow
										description={describePaymentFailure('daysOfPaymentFailureNoticeToBillingManagers')}
										htmlFor="daysOfPaymentFailureNoticeToBillingManagers"
										label="PF notice to billing managers"
									>
										<FieldStatus name="daysOfPaymentFailureNoticeToBillingManagers">
											<FormSentence>
												<TextField
													attributes={{
														min: 0,
													}}
													name="daysOfPaymentFailureNoticeToBillingManagers"
													placeholder="days"
													trimValue={true}
													type={TextFieldType.Number}
													width={100}
												/> days
											</FormSentence>
										</FieldStatus>
									</FormRow>

									<FormRow
										description={describePaymentFailure('daysOfPaymentFailureNoticeToAllUsers')}
										htmlFor="daysOfPaymentFailureNoticeToAllUsers"
										label="PF notice to all users"
									>
										<FieldStatus name="daysOfPaymentFailureNoticeToAllUsers">
											<FormSentence>
												<TextField
													attributes={{
														min: 0,
													}}
													name="daysOfPaymentFailureNoticeToAllUsers"
													placeholder="days"
													trimValue={true}
													type={TextFieldType.Number}
													width={100}
												/> days
											</FormSentence>
										</FieldStatus>
									</FormRow>

									<FormRow
										description={describePaymentFailure('daysOfPaymentFailureSuspension')}
										htmlFor="daysOfPaymentFailureSuspension"
										label="PF suspension"
									>
										<FieldStatus name="daysOfPaymentFailureSuspension">
											<FormSentence>
												<TextField
													attributes={{
														min: 0,
													}}
													name="daysOfPaymentFailureSuspension"
													placeholder="days"
													trimValue={true}
													type={TextFieldType.Number}
													width={100}
												/> days
											</FormSentence>
										</FieldStatus>
									</FormRow>

									<FormRow
										description={describeNonRenewalFailure('daysOfNonRenewalGracePeriod')}
										htmlFor="daysOfNonRenewalGracePeriod"
										label="NR grace period"
									>
										<FieldStatus name="daysOfNonRenewalGracePeriod">
											<FormSentence>
												<TextField
													attributes={{
														min: 0,
													}}
													name="daysOfNonRenewalGracePeriod"
													placeholder="days"
													trimValue={true}
													type={TextFieldType.Number}
													width={100}
												/> days
											</FormSentence>
										</FieldStatus>
									</FormRow>

									<FormRow
										description={describeNonRenewalFailure('daysOfNonRenewalSuspension')}
										htmlFor="daysOfNonRenewalSuspension"
										label="NR suspension"
									>
										<FieldStatus name="daysOfNonRenewalSuspension">
											<FormSentence>
												<TextField
													attributes={{
														min: 0,
													}}
													name="daysOfNonRenewalSuspension"
													placeholder="days"
													trimValue={true}
													type={TextFieldType.Number}
													width={100}
												/> days
											</FormSentence>
										</FieldStatus>
									</FormRow>
								</FormRows>

								<ButtonsLayout>
									<CancelButton />
									<SaveSubmitButton />
								</ButtonsLayout>
							</>
						);
					}}
				</Form>
			</EditablePart>
		</>
	);
};



export default AccountAdminCustomerPhaseForm;
