import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	CANONICAL_TYPE_CROSSDOMAIN,
	CANONICAL_TYPE_GROUP_INTERNAL,
	CANONICAL_TYPE_INTERNAL_OTHER,
	CANONICAL_TYPE_INTERNAL_SELF,
	CANONICAL_TYPE_INVALID,
	CANONICAL_TYPE_NONE,
	CANONICAL_TYPE_NOT_APPLICABLE,
} from '~/model/pages';



const fullMessages = defineMessages({
	[CANONICAL_TYPE_CROSSDOMAIN]: {
		id: 'ui.contentOverview.canonicalTypes.external',
	},
	[CANONICAL_TYPE_GROUP_INTERNAL]: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.internal',
	},
	[CANONICAL_TYPE_INTERNAL_OTHER]: {
		id: 'ui.contentOverview.canonicalTypes.internal.otherPage',
	},
	[CANONICAL_TYPE_INTERNAL_SELF]: {
		id: 'ui.contentOverview.canonicalTypes.internal.self',
	},
	[CANONICAL_TYPE_INVALID]: {
		id: 'ui.contentOverview.canonicalTypes.invalid',
	},
	[CANONICAL_TYPE_NONE]: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.none',
	},
	[CANONICAL_TYPE_NOT_APPLICABLE]: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.notApplicable',
	},
});

const shortMessages = defineMessages({
	[CANONICAL_TYPE_CROSSDOMAIN]: {
		id: 'ui.contentOverview.canonicalTypes.external',
	},
	[CANONICAL_TYPE_GROUP_INTERNAL]: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.internal',
	},
	[CANONICAL_TYPE_INTERNAL_OTHER]: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.internal.otherPage',
	},
	[CANONICAL_TYPE_INTERNAL_SELF]: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.internal.self',
	},
	[CANONICAL_TYPE_INVALID]: {
		id: 'ui.contentOverview.canonicalTypes.invalid',
	},
	[CANONICAL_TYPE_NONE]: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.none',
	},
	[CANONICAL_TYPE_NOT_APPLICABLE]: {
		id: 'ui.contentOverview.tableFilter.canonicalTypes.notApplicable',
	},
});



type Props = {
	canonicalType: (
		| typeof CANONICAL_TYPE_CROSSDOMAIN
		| typeof CANONICAL_TYPE_GROUP_INTERNAL
		| typeof CANONICAL_TYPE_INTERNAL_OTHER
		| typeof CANONICAL_TYPE_INTERNAL_SELF
		| typeof CANONICAL_TYPE_INVALID
		| typeof CANONICAL_TYPE_NONE
		| typeof CANONICAL_TYPE_NOT_APPLICABLE
	),
	useFullVersion?: boolean,
};

const CanonicalTypeName: React.FC<Props> = (props) => {
	const {
		canonicalType,
		useFullVersion = true,
	} = props;

	const message = (
		useFullVersion
			? fullMessages[canonicalType]
			: shortMessages[canonicalType]
	);

	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default CanonicalTypeName;
