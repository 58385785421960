/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AlertTypeDefinitionsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AlertTypeDefinitionsQuery = { readonly __typename?: 'Query', readonly alertTypeDefinitions: ReadonlyArray<{ readonly __typename?: 'AlertTypeDefinition', readonly hasScope: boolean, readonly hasSensitivity: boolean, readonly id: string, readonly type: string }> };


export const AlertTypeDefinitionsDocument = gql`
    query AlertTypeDefinitions {
  alertTypeDefinitions {
    hasScope
    hasSensitivity
    id
    type
  }
}
    `;

/**
 * __useAlertTypeDefinitionsQuery__
 *
 * To run a query within a React component, call `useAlertTypeDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertTypeDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertTypeDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAlertTypeDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<AlertTypeDefinitionsQuery, AlertTypeDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AlertTypeDefinitionsQuery, AlertTypeDefinitionsQueryVariables>(AlertTypeDefinitionsDocument, options);
      }
export function useAlertTypeDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlertTypeDefinitionsQuery, AlertTypeDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AlertTypeDefinitionsQuery, AlertTypeDefinitionsQueryVariables>(AlertTypeDefinitionsDocument, options);
        }
export function useAlertTypeDefinitionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AlertTypeDefinitionsQuery, AlertTypeDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AlertTypeDefinitionsQuery, AlertTypeDefinitionsQueryVariables>(AlertTypeDefinitionsDocument, options);
        }
export type AlertTypeDefinitionsQueryHookResult = ReturnType<typeof useAlertTypeDefinitionsQuery>;
export type AlertTypeDefinitionsLazyQueryHookResult = ReturnType<typeof useAlertTypeDefinitionsLazyQuery>;
export type AlertTypeDefinitionsSuspenseQueryHookResult = ReturnType<typeof useAlertTypeDefinitionsSuspenseQuery>;
export type AlertTypeDefinitionsQueryResult = Apollo.QueryResult<AlertTypeDefinitionsQuery, AlertTypeDefinitionsQueryVariables>;