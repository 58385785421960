import React from 'react';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';



function useWebsiteScopes(): Array<string> | null {
	const websiteId = useWebsiteId();

	const segments = useWebsiteSegmentDefinitions(websiteId);

	return React.useMemo(
		() => {
			if (segments.isLoaded === false) {
				return null;
			}

			const segmentScopes = segments
				.listAll()
				.map((segmentDefinition) => 'segment/' + segmentDefinition.name);

			return [
				'website',
				...segmentScopes,
			];
		},
		[
			segments,
		],
	);
}



export default useWebsiteScopes;
