import React from 'react';

import type GraphQL from '~/types/graphql';

import {
	useBillingEntitiesQuery,
} from './useBillingEntities.gql';



type BillingEntities = {
	getByCode: (code: string) => Readonly<{
		id: GraphQL.BillingEntity['id'],
		allowedCurrencies: GraphQL.BillingEntity['allowedCurrencies'],
		forCountries: GraphQL.BillingEntity['forCountries'],
	}>,
	getByCountry: (country: string) => string,
};



function useBillingEntities(): BillingEntities | null {
	const { data } = useBillingEntitiesQuery();

	const billingEntities = data?.billingEntities ?? null;

	return React.useMemo(
		() => {
			if (billingEntities === null) {
				return null;
			}

			return {
				getByCode: (code) => {
					const billingEntity = billingEntities.find(
						(billingEntity) => billingEntity.id === code,
					);

					if (billingEntity === undefined) {
						throw new Error(
							`Billing entity with code '${code}' doesn't exist`,
						);
					}

					return billingEntity;
				},
				getByCountry: (country) => {
					const billingEntity = billingEntities.find(
						(billingEntity) => billingEntity.forCountries.includes(country),
					);

					if (billingEntity !== undefined) {
						return billingEntity.id;
					}

					const fallbackBillingEntity = billingEntities.find(
						(billingEntity) => billingEntity.forCountries.includes('*'),
					);

					if (fallbackBillingEntity !== undefined) {
						return fallbackBillingEntity.id;
					}

					throw new Error(
						`Fallback billing entity (needed for country '${country}') doesn't exist`,
					);
				},
			};
		},
		[
			billingEntities,
		],
	);
}



export default useBillingEntities;
