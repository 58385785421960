import alerts from './en-US/alerts';
import countries from './en-US/countries';
import issues from './en-US/issues';
import links from './en-US/links';
import states from './en-US/states';
import ui from './en-US/ui';



export const dictionary = {
	...alerts,
	...countries,
	...ui,
	...issues,
	...links,
	...states,
} as const;
