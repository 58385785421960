import React from 'react';

import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';

import styles from './LoadingMessage.module.scss';



type Props = {
	subtitle?: React.ReactNode,
	title: React.ReactNode,
};

const LoadingMessage: React.FC<Props> = (props) => {
	const {
		subtitle,
		title,
	} = props;

	return (
		<div className={styles.component}>
			<div className={styles.title}>
				{title}
			</div>
			{subtitle && (
				<div className={styles.subtitle}>
					{subtitle}
				</div>
			)}
			<div className={styles.preloader}>
				<LoadingDots size={LoadingDotsSize.Large} />
			</div>
		</div>
	);
};



export default LoadingMessage;
