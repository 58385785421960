import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';

import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';



const messages = defineMessages({
	generic: {
		id: 'ui.formErrors.domainNotWhitelisted.generic',
	},
	notSolvable: {
		id: 'ui.formErrors.domainNotWhitelisted.notSolvable',
	},
	solvable: {
		id: 'ui.formErrors.domainNotWhitelisted.solvable',
	},
});



type Props ={
	accountId: CK.AccountId,
	generic?: boolean,
};

const DomainNotWhitelistedErrorMessage: React.FC<Props> = (props) => {
	const {
		accountId,
		generic,
	} = props;

	const isSolvable = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageOrganizationAccessControl,
	);

	if (generic) {
		return (
			<FormattedMessage {...messages.generic} />
		);
	}

	if (isSolvable.yes) {
		return (
			<FormattedMessage
				{...messages.solvable}
				values={{
					a: (chunks) => (
						<InternalLink
							routeName="account.settings.organizationAccess"
							routeParams={{
								accountId,
							}}
							style={InternalLinkStyle.Decent}
						>
							{chunks}
						</InternalLink>
					),
				}}
			/>
		);
	}

	return (
		<FormattedMessage {...messages.notSolvable} />
	);
};



export default React.memo(DomainNotWhitelistedErrorMessage);
