import {
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
	COLUMNS_SETS_LIST_TOGGLE,
	INITIALIZE_FROM_LOCAL_STORAGE,
} from '~/actions';
import {
	INTERACTION_COLUMNS_SETS_LIST_OPEN,
	INTERACTION_ISSUES_CATEGORY_OPEN,
	INTERACTION_ISSUE_CARD_OPEN,
	INTERACTION_PAGES_OVERVIEW_OPEN,
	INTERACTION_PAGE_DETAIL_OPEN,
	INTERACTION_PRIORITIZE_BUTTON_CLICK,
	INTERACTION_TEAM_PROFILE_OPEN,
	INTERACTION_WEBSITE_DETAIL_OPEN,
} from '~/model/uiInteractions';



function increaseUiInteractionValue(state, uiInteractionName) {
	const currentValue = state.get(uiInteractionName);
	return state.set(uiInteractionName, currentValue + 1);
}



function isUrlNameAccessed(urlState, previousUrlState, urlName) {
	return urlState.name.indexOf(urlName) === 0;
}



function isUrlNameAccessedUniqely(urlState, previousUrlState, urlName) {
	return urlState.name.indexOf(urlName) === 0 && previousUrlState.name.indexOf(urlName) !== 0;
}



const createDefaultState = () => {
	return new Map({
		[INTERACTION_COLUMNS_SETS_LIST_OPEN]: 0,
		[INTERACTION_ISSUE_CARD_OPEN]: 0,
		[INTERACTION_ISSUES_CATEGORY_OPEN]: 0,
		[INTERACTION_PAGE_DETAIL_OPEN]: 0,
		[INTERACTION_PAGES_OVERVIEW_OPEN]: 0,
		[INTERACTION_PRIORITIZE_BUTTON_CLICK]: 0,
		[INTERACTION_TEAM_PROFILE_OPEN]: 0,
		[INTERACTION_WEBSITE_DETAIL_OPEN]: 0,
	});
};



export const uiInteractions = (state, action) => {
	if (state === undefined) {
		state = createDefaultState();
	}

	const {
		type,
	} = action;

	switch (type) {

		case CHANGE_URL_STATE: {
			const {
				previousUrlState,
				urlState,
			} = action;

			if (isUrlNameAccessedUniqely(urlState, previousUrlState, 'website.pages.detail')) {
				state = increaseUiInteractionValue(state, INTERACTION_PAGE_DETAIL_OPEN);
			}

			if (isUrlNameAccessedUniqely(urlState, previousUrlState, 'website.pages')) {
				state = increaseUiInteractionValue(state, INTERACTION_PAGES_OVERVIEW_OPEN);
			}

			if (isUrlNameAccessed(urlState, previousUrlState, 'website.issues.issueCategory')
				|| isUrlNameAccessed(urlState, previousUrlState, 'website.pages.detail.issuesCategory')) {
				state = increaseUiInteractionValue(state, INTERACTION_ISSUE_CARD_OPEN);
			}

			if (isUrlNameAccessed(urlState, previousUrlState, 'website.issues.issueCategory')) {
				state = increaseUiInteractionValue(state, INTERACTION_ISSUES_CATEGORY_OPEN);
			}

			if (isUrlNameAccessedUniqely(urlState, previousUrlState, 'account.websites.website')) {
				state = increaseUiInteractionValue(state, INTERACTION_WEBSITE_DETAIL_OPEN);
			}

			if (isUrlNameAccessedUniqely(urlState, previousUrlState, 'account.profile')) {
				state = increaseUiInteractionValue(state, INTERACTION_TEAM_PROFILE_OPEN);
			}

			break;
		}

		case COLUMNS_SETS_LIST_TOGGLE: {
			const {
				value,
			} = action;

			if (value === true) {
				state = increaseUiInteractionValue(state, INTERACTION_COLUMNS_SETS_LIST_OPEN);
			}

			break;
		}

		case INITIALIZE_FROM_LOCAL_STORAGE: {
			// const {
			// 	data,
			// } = action;

			// (Future development) For supporting storing UI interactions in local storage

			break;
		}

	}

	return state;
};
