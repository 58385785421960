import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import Copy from '~/components/logic/Copy';
import IgnoringModalBodyLayout from '~/components/atoms/issues/components/modals/IgnoringModalBodyLayout';
import IgnoringStatus, {
	STATE_IGNORED,
} from '~/components/atoms/issues/components/IgnoringStatus';
import IgnoringTable, {
	type IgnoringTableCellRendererInput,
} from '~/components/logic/issues/ignoring/IgnoringTable';
import InternalLink from '~/components/patterns/links/InternalLink';
import {
	renderEllipsisCell,
} from '~/components/atoms/dataTables/utils/ReactVirtualizedCells';
import RichText from '~/components/patterns/typography/RichText';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';
import UpcomingValueUpdateHighlight from '~/components/patterns/values/UpcomingValueUpdateHighlight';

import {
	useUnignorePageIssueOnSpecificPagesMutation,
} from './IgnorePages.gql';

import useTrackIgnoringUpdateInPendo from '~/hooks/useTrackIgnoringUpdateInPendo';

import {
	loadPagesByIds,
} from '~/actions/pages/overview';

import {
	getCategoryUrlIdentifier,
} from '~/model/issues/identifierMapping';

import {
	type IssueCategoryName,
	type IssueName,
} from '~/model/issuesNew';

import {
	createFullId,
} from '~/model/pages';

import {
	pageBasicsSelector,
} from '~/state/pages/selectors';



const messages = defineMessages({
	alreadyIgnoredOnWebsite: {
		id: 'ui.issueDetail.ignoringModal.scope.pages.ignoredAlreadyOnWebsite.description',
	},
	headingsPage: {
		id: 'ui.issueDetail.ignoringModal.scope.pages.ignoringActive.pagesTable.columnHeading.page',
	},
	headingsStatus: {
		id: 'ui.issueDetail.ignoringModal.scope.general.column.status.heading',
	},
	ignoringActiveDescription: {
		id: 'ui.issueDetail.ignoringModal.scope.pages.ignoringActive.description',
	},
	ignoringStatusIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.ignored',
	},
	ignoringStatusChangeWillBeUnignored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.willBeUnignored',
	},
	noPagesIgnoredDescription: {
		id: 'ui.issueDetail.ignoringModal.scope.pages.ignoringInactive.description',
	},
});

const ELLIPSIS_POPUP_ZINDEX = 2000;

const columnWidths = {
	page: 300,
	status: 202,
};

function getColumnWidth({ index }) {
	switch (index) {

		case 0:
			return columnWidths.page;
		case 1:
			return columnWidths.status;

	}
}

function renderHeader({ columnIndex }) {
	let cellContent;

	switch (columnIndex) {

		case 0:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsPage} />
					)}
				/>
			);
			break;
		case 1:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsStatus} />
					)}
				/>
			);
			break;

	}

	return cellContent;
}

function renderStatusCell({ isChecked }) {
	const text = (
		<FormattedMessage {...messages.ignoringStatusIgnored} />
	);

	const possibleDescription = (
		<FormattedMessage {...messages.ignoringStatusChangeWillBeUnignored} />
	);

	const message = (
		<UpcomingValueUpdateHighlight updateDescription={isChecked && possibleDescription}>
			<IgnoringStatus state={STATE_IGNORED}>
				{text}
			</IgnoringStatus>
		</UpcomingValueUpdateHighlight>
	);

	return renderEllipsisCell(
		message,
		ELLIPSIS_POPUP_ZINDEX,
		isChecked ? possibleDescription : text,
	);
}



type Props = {
	ignoringRuleOnPages: {
		ignoredPageLegacyIds: ReadonlyArray<number>,
	},
	ignoringRuleOnWebsite: {
		isEffective: boolean,
	},
	issueCategoryName: IssueCategoryName,
	issueName: IssueName,
	reloadCallback: () => Promise<void>,
	websiteId: CK.WebsiteId,
};

const IgnorePages: React.FC<Props> = (props) => {
	const {
		ignoringRuleOnPages,
		ignoringRuleOnWebsite,
		issueCategoryName,
		issueName,
		reloadCallback,
		websiteId,
	} = props;

	const basics = useSelector(pageBasicsSelector);
	const dispatch = useDispatch();
	const trackIgnoringUpdateInPendo = useTrackIgnoringUpdateInPendo();

	const [unignorePageIssueOnSpecificPages] = useUnignorePageIssueOnSpecificPagesMutation();

	const ignoredPageIds = ignoringRuleOnPages.ignoredPageLegacyIds;

	React.useEffect(
		() => {
			dispatch(
				loadPagesByIds(ignoredPageIds),
			);
		},
		[
			dispatch,
			ignoredPageIds,
		],
	);

	const handleSubmit = React.useCallback(
		async (selectedRows) => {
			await unignorePageIssueOnSpecificPages({
				variables: {
					issueName,
					urls: ignoredPageIds
						.filter((pageId) => selectedRows.includes(pageId))
						.map((pageId) => basics.get(createFullId(websiteId, pageId)).get('url')),
					websiteId,
				},
			});

			trackIgnoringUpdateInPendo(
				websiteId,
				issueName,
				false,
				'page',
			);

			await reloadCallback();
		},
		[
			basics,
			ignoredPageIds,
			issueName,
			reloadCallback,
			trackIgnoringUpdateInPendo,
			unignorePageIssueOnSpecificPages,
			websiteId,
		],
	);

	const renderPageUrlCell = React.useCallback(
		({ pageId }) => {
			const pageBasics = basics.get(createFullId(websiteId, pageId));

			if (!pageBasics) {
				return false;
			}

			return renderEllipsisCell(
				(
					<InternalLink
						ellipsis={true}
						routeName="website.pages.detail.issuesCategory"
						routeParams={{
							websiteId,
							id: pageId,
							pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategoryName),
						}}
					>
						{pageBasics.get('displayUrl')}
					</InternalLink>
				),
				ELLIPSIS_POPUP_ZINDEX,
				pageBasics.get('displayUrl'),
			);
		},
		[
			basics,
			issueCategoryName,
			websiteId,
		],
	);

	const renderCell = React.useCallback(
		({
			columnIndex,
			isChecked,
			item: pageId,
		}: IgnoringTableCellRendererInput<number>) => {
			let cellContent;

			switch (columnIndex) {

				case 0:
					cellContent = renderPageUrlCell({ pageId });

					break;

				case 1:
					cellContent = renderStatusCell({
						isChecked,
					});

					break;

			}

			return cellContent;
		},
		[
			renderPageUrlCell,
		],
	);

	if (ignoringRuleOnWebsite.isEffective) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					<FormattedMessage {...messages.alreadyIgnoredOnWebsite} />
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	if (ignoredPageIds.length === 0) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					<FormattedMessage
						{...messages.noPagesIgnoredDescription}
						values={{
							count__issues: 1,
						}}
					/>
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	return (
		<IgnoringModalBodyLayout>
			<RichText>
				<p>
					<Copy
						{...messages.ignoringActiveDescription}
						values={{
							ignoredPages: ignoredPageIds.length,
						}}
					/>
				</p>
			</RichText>

			<IgnoringTable
				bodyCellRenderer={renderCell}
				columnCount={2}
				columnWidth={getColumnWidth}
				headerCellRenderer={renderHeader}
				items={ignoredPageIds}
				name="pages"
				onSubmitChangesCallback={handleSubmit}
			/>
		</IgnoringModalBodyLayout>
	);
};



export default IgnorePages;
