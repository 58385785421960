/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveTeamMemberConfirmationQueryVariables = GraphQL.Exact<{
  userId: GraphQL.Scalars['ID']['input'];
}>;


export type RemoveTeamMemberConfirmationQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly initials: string, readonly legacyId: string, readonly signature: string } | null };

export type RemoveUserMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  email: GraphQL.Scalars['String']['input'];
}>;


export type RemoveUserMutation = { readonly __typename?: 'Mutation', readonly RemoveUser: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }> } | null } } };


export const RemoveTeamMemberConfirmationDocument = gql`
    query RemoveTeamMemberConfirmation($userId: ID!) {
  user(legacyId: $userId) {
    email
    id
    initials
    legacyId
    signature
  }
}
    `;

/**
 * __useRemoveTeamMemberConfirmationQuery__
 *
 * To run a query within a React component, call `useRemoveTeamMemberConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoveTeamMemberConfirmationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveTeamMemberConfirmationQuery(baseOptions: Apollo.QueryHookOptions<RemoveTeamMemberConfirmationQuery, RemoveTeamMemberConfirmationQueryVariables> & ({ variables: RemoveTeamMemberConfirmationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemoveTeamMemberConfirmationQuery, RemoveTeamMemberConfirmationQueryVariables>(RemoveTeamMemberConfirmationDocument, options);
      }
export function useRemoveTeamMemberConfirmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemoveTeamMemberConfirmationQuery, RemoveTeamMemberConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemoveTeamMemberConfirmationQuery, RemoveTeamMemberConfirmationQueryVariables>(RemoveTeamMemberConfirmationDocument, options);
        }
export function useRemoveTeamMemberConfirmationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RemoveTeamMemberConfirmationQuery, RemoveTeamMemberConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RemoveTeamMemberConfirmationQuery, RemoveTeamMemberConfirmationQueryVariables>(RemoveTeamMemberConfirmationDocument, options);
        }
export type RemoveTeamMemberConfirmationQueryHookResult = ReturnType<typeof useRemoveTeamMemberConfirmationQuery>;
export type RemoveTeamMemberConfirmationLazyQueryHookResult = ReturnType<typeof useRemoveTeamMemberConfirmationLazyQuery>;
export type RemoveTeamMemberConfirmationSuspenseQueryHookResult = ReturnType<typeof useRemoveTeamMemberConfirmationSuspenseQuery>;
export type RemoveTeamMemberConfirmationQueryResult = Apollo.QueryResult<RemoveTeamMemberConfirmationQuery, RemoveTeamMemberConfirmationQueryVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($accountId: AccountId!, $email: String!) {
  RemoveUser(accountId: $accountId, email: $email) {
    query {
      account(id: $accountId) {
        id
        memberships {
          account {
            id
          }
          user {
            id
            legacyId
          }
        }
      }
    }
  }
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;