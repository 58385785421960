import classNames from 'classnames';
import React from 'react';



export enum WebVitalsValueStatus {
	Good = 'good',
	NeedsImprovement = 'needs-improvement',
	Poor = 'poor',
	Unknown = 'unknown',
}



type Props = {
	/** Web Vitals value */
	children: React.ReactNode,
	/** Status definition of value */
	status?: WebVitalsValueStatus,
};

const WebVitalsValue: React.FC<Props> = (props) => {
	const {
		children,
		status,
	} = props;

	const componentClasses = classNames({
		'web-vitals-value': true,
		['web-vitals-value--' + status + '-status']: status !== undefined,
	});

	return (
		<span className={componentClasses}>
			{children}
		</span>
	);
};



export default WebVitalsValue;
