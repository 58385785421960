import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PriceTagIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M1.382,11.384,13.16,22.953l.017.016a2,2,0,0,0,2.808-.021l7.065-7.063a2,2,0,0,0-.012-2.841L11.3,1.5c-.018-.019-.035-.037-.054-.054A3.751,3.751,0,0,0,8.855.465H2.363a2,2,0,0,0-2,2L.37,8.96A3.784,3.784,0,0,0,1.382,11.384ZM4.863,6.465a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,4.863,6.465Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default PriceTagIconBuilder;
