import classNames from 'classnames';
import React from 'react';



export enum AttachedCTAElementAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

type Props = {
	/** Alignment of attached CTA element */
	alignment?: AttachedCTAElementAlignment,
	children?: React.ReactNode,
	/** Compact look of attached CTA element */
	compact?: boolean,
	/** Attached CTA element */
	ctaElement: React.ReactNode,
};



const AttachedCTAElement: React.FC<Props> = (props) => {
	const {
		alignment = AttachedCTAElementAlignment.Center,
		children,
		compact,
		ctaElement,
	} = props;

	const componentClasses = classNames({
		'attached-cta-element': true,
		'attached-cta-element--compact': compact,
	});

	const ctaElementClasses = classNames({
		'attached-cta-element__attached-component': true,
		['attached-cta-element__attached-component--' + alignment + '-aligned']: true,
	});

	return (
		<div className={componentClasses}>
			<div className="attached-cta-element__source-component">
				{children}
			</div>
			{ctaElement && (
				<div className={ctaElementClasses}>
					{ctaElement}
				</div>
			)}
		</div>
	);
};



export default AttachedCTAElement;
