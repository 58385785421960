/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IgnorePageIssueCategoryOnSegmentsMutationVariables = GraphQL.Exact<{
  issueCategoryName: GraphQL.Scalars['String']['input'];
  segments: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IgnorePageIssueCategoryOnSegmentsMutation = { readonly __typename?: 'Mutation', readonly IgnorePageIssueCategoryOnSegments: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type UnignorePageIssueCategoryOnSegmentsMutationVariables = GraphQL.Exact<{
  issueCategoryName: GraphQL.Scalars['String']['input'];
  segments: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueCategoryOnSegmentsMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueCategoryOnSegments: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const IgnorePageIssueCategoryOnSegmentsDocument = gql`
    mutation IgnorePageIssueCategoryOnSegments($issueCategoryName: String!, $segments: [String!]!, $websiteId: WebsiteId!) {
  IgnorePageIssueCategoryOnSegments(
    issueCategory: $issueCategoryName
    segments: $segments
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type IgnorePageIssueCategoryOnSegmentsMutationFn = Apollo.MutationFunction<IgnorePageIssueCategoryOnSegmentsMutation, IgnorePageIssueCategoryOnSegmentsMutationVariables>;

/**
 * __useIgnorePageIssueCategoryOnSegmentsMutation__
 *
 * To run a mutation, you first call `useIgnorePageIssueCategoryOnSegmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnorePageIssueCategoryOnSegmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignorePageIssueCategoryOnSegmentsMutation, { data, loading, error }] = useIgnorePageIssueCategoryOnSegmentsMutation({
 *   variables: {
 *      issueCategoryName: // value for 'issueCategoryName'
 *      segments: // value for 'segments'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIgnorePageIssueCategoryOnSegmentsMutation(baseOptions?: Apollo.MutationHookOptions<IgnorePageIssueCategoryOnSegmentsMutation, IgnorePageIssueCategoryOnSegmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgnorePageIssueCategoryOnSegmentsMutation, IgnorePageIssueCategoryOnSegmentsMutationVariables>(IgnorePageIssueCategoryOnSegmentsDocument, options);
      }
export type IgnorePageIssueCategoryOnSegmentsMutationHookResult = ReturnType<typeof useIgnorePageIssueCategoryOnSegmentsMutation>;
export type IgnorePageIssueCategoryOnSegmentsMutationResult = Apollo.MutationResult<IgnorePageIssueCategoryOnSegmentsMutation>;
export type IgnorePageIssueCategoryOnSegmentsMutationOptions = Apollo.BaseMutationOptions<IgnorePageIssueCategoryOnSegmentsMutation, IgnorePageIssueCategoryOnSegmentsMutationVariables>;
export const UnignorePageIssueCategoryOnSegmentsDocument = gql`
    mutation UnignorePageIssueCategoryOnSegments($issueCategoryName: String!, $segments: [String!]!, $websiteId: WebsiteId!) {
  UnignorePageIssueCategoryOnSegments(
    issueCategory: $issueCategoryName
    segments: $segments
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePageIssueCategoryOnSegmentsMutationFn = Apollo.MutationFunction<UnignorePageIssueCategoryOnSegmentsMutation, UnignorePageIssueCategoryOnSegmentsMutationVariables>;

/**
 * __useUnignorePageIssueCategoryOnSegmentsMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueCategoryOnSegmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueCategoryOnSegmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueCategoryOnSegmentsMutation, { data, loading, error }] = useUnignorePageIssueCategoryOnSegmentsMutation({
 *   variables: {
 *      issueCategoryName: // value for 'issueCategoryName'
 *      segments: // value for 'segments'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueCategoryOnSegmentsMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueCategoryOnSegmentsMutation, UnignorePageIssueCategoryOnSegmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueCategoryOnSegmentsMutation, UnignorePageIssueCategoryOnSegmentsMutationVariables>(UnignorePageIssueCategoryOnSegmentsDocument, options);
      }
export type UnignorePageIssueCategoryOnSegmentsMutationHookResult = ReturnType<typeof useUnignorePageIssueCategoryOnSegmentsMutation>;
export type UnignorePageIssueCategoryOnSegmentsMutationResult = Apollo.MutationResult<UnignorePageIssueCategoryOnSegmentsMutation>;
export type UnignorePageIssueCategoryOnSegmentsMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueCategoryOnSegmentsMutation, UnignorePageIssueCategoryOnSegmentsMutationVariables>;