import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AddressFields, {
	validateAddress,
	validateCityAndPostalCode,
	validateCountry,
	validateName,
	validateState,
} from '~/components/app/AddressFields';
import AppearAnimation, {
	AppearAnimationDirection,
} from '~/components/patterns/animations/AppearAnimation';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import {
	NotApplicable,
} from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import TextField, {
	TextFieldAutocomplete,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
	validateFields,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';

import isCountryInEuropeanUnion from '~/utilities/isCountryInEuropeanUnion';
import isVatNumberCompatibleWithCountry from '~/utilities/isVatNumberCompatibleWithCountry';



const messages = defineMessages({
	email: {
		id: 'ui.newTeam.form.billingEmail',
	},
	formErrorsVatInvalid: {
		id: 'ui.pricing.signUp.error.vat.invalid',
	},
	formErrorsVatMissingCountryCode: {
		id: 'ui.pricing.signUp.error.vat.countryCode.missing',
	},
	formErrorsVatNotMatching: {
		id: 'ui.pricing.signUp.error.vat.countryCode.notMatching',
	},
	vatNumber: {
		id: 'ui.newTeam.form.vat',
	},
});



const CustomerDetailsFields: React.FC = () => {
	const country = useFormContext().values.country;
	const showVat = isCountryInEuropeanUnion(country);

	return (
		<FormRows>
			<AddressFields />

			{showVat && (
				<AppearAnimation
					delay={50}
					direction={AppearAnimationDirection.Down}
				>
					<FormRow
						htmlFor="vatNumber"
						label={(
							<FormattedMessage {...messages.vatNumber} />
						)}
					>
						<FieldStatus name="validateVatNumber">
							<TextField
								emptyAsNull={true}
								name="vatNumber"
								trimValue={true}
							/>
						</FieldStatus>
					</FormRow>
				</AppearAnimation>
			)}

			<FormRow
				htmlFor="email"
				label={(
					<FormattedMessage {...messages.email} />
				)}
			>
				<FieldStatus name="validateEmail">
					<TextField
						autoComplete={TextFieldAutocomplete.Off}
						emptyAsNull={true}
						name="email"
						trimValue={true}
						type={TextFieldType.Email}
					/>
				</FieldStatus>
			</FormRow>
		</FormRows>
	);
};



export default CustomerDetailsFields;

export {
	validateAddress,
	validateCityAndPostalCode,
	validateCountry,
	validateName,
	validateState,
};

export const validateEmail = validateField(
	'email',
	(f) => [
		f.validateNonEmpty(),
		f.validateEmails(),
	],
);

export const validateVatNumber = validateFields(
	['vatNumber', 'country'],
	{
		vatNumber: (f) => [
			f.custom({
				message: null,
				rule: ({ value }) => {
					return !!value || NotApplicable;
				},
			}),
			f.validateNonEmpty(),
			f.validateMaximumLength(20),
			f.custom({
				message: (
					<FormattedMessage {...messages.formErrorsVatMissingCountryCode} />
				),
				rule: ({ value }) => {
					return /^[A-Za-z]{2}/.test(value);
				},
			}),
			f.custom({
				message: (
					<FormattedMessage {...messages.formErrorsVatNotMatching} />
				),
				rule: ({ value, values }) => {
					return isVatNumberCompatibleWithCountry(
						values.country,
						value,
					);
				},
			}),
			f.customGlobal({
				field: 'vatNumber',
				globalRule: 'invalidVatNumber',
				message: (
					<FormattedMessage {...messages.formErrorsVatInvalid} />
				),
			}),
		],
	},
);
