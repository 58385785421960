/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnterSudoModeUsingTwoFactorAuthenticationMutationVariables = GraphQL.Exact<{
  code: GraphQL.Scalars['String']['input'];
}>;


export type EnterSudoModeUsingTwoFactorAuthenticationMutation = { readonly __typename?: 'Mutation', readonly EnterSudoModeUsingTwoFactorAuthentication: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly sudoModeStatus: { readonly __typename?: 'SudoModeStatus', readonly isSudoEnabled: boolean, readonly suggestedMethodToEnter: GraphQL.MethodToEnterSudoMode | null } } } };

export type EnterSudoModeUsingPasswordMutationVariables = GraphQL.Exact<{
  password: GraphQL.Scalars['String']['input'];
}>;


export type EnterSudoModeUsingPasswordMutation = { readonly __typename?: 'Mutation', readonly EnterSudoModeUsingPassword: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly sudoModeStatus: { readonly __typename?: 'SudoModeStatus', readonly isSudoEnabled: boolean, readonly suggestedMethodToEnter: GraphQL.MethodToEnterSudoMode | null } } } };


export const EnterSudoModeUsingTwoFactorAuthenticationDocument = gql`
    mutation EnterSudoModeUsingTwoFactorAuthentication($code: String!) {
  EnterSudoModeUsingTwoFactorAuthentication(code: $code) {
    query {
      sudoModeStatus {
        isSudoEnabled
        suggestedMethodToEnter
      }
    }
  }
}
    `;
export type EnterSudoModeUsingTwoFactorAuthenticationMutationFn = Apollo.MutationFunction<EnterSudoModeUsingTwoFactorAuthenticationMutation, EnterSudoModeUsingTwoFactorAuthenticationMutationVariables>;

/**
 * __useEnterSudoModeUsingTwoFactorAuthenticationMutation__
 *
 * To run a mutation, you first call `useEnterSudoModeUsingTwoFactorAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterSudoModeUsingTwoFactorAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterSudoModeUsingTwoFactorAuthenticationMutation, { data, loading, error }] = useEnterSudoModeUsingTwoFactorAuthenticationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useEnterSudoModeUsingTwoFactorAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<EnterSudoModeUsingTwoFactorAuthenticationMutation, EnterSudoModeUsingTwoFactorAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnterSudoModeUsingTwoFactorAuthenticationMutation, EnterSudoModeUsingTwoFactorAuthenticationMutationVariables>(EnterSudoModeUsingTwoFactorAuthenticationDocument, options);
      }
export type EnterSudoModeUsingTwoFactorAuthenticationMutationHookResult = ReturnType<typeof useEnterSudoModeUsingTwoFactorAuthenticationMutation>;
export type EnterSudoModeUsingTwoFactorAuthenticationMutationResult = Apollo.MutationResult<EnterSudoModeUsingTwoFactorAuthenticationMutation>;
export type EnterSudoModeUsingTwoFactorAuthenticationMutationOptions = Apollo.BaseMutationOptions<EnterSudoModeUsingTwoFactorAuthenticationMutation, EnterSudoModeUsingTwoFactorAuthenticationMutationVariables>;
export const EnterSudoModeUsingPasswordDocument = gql`
    mutation EnterSudoModeUsingPassword($password: String!) {
  EnterSudoModeUsingPassword(password: $password) {
    query {
      sudoModeStatus {
        isSudoEnabled
        suggestedMethodToEnter
      }
    }
  }
}
    `;
export type EnterSudoModeUsingPasswordMutationFn = Apollo.MutationFunction<EnterSudoModeUsingPasswordMutation, EnterSudoModeUsingPasswordMutationVariables>;

/**
 * __useEnterSudoModeUsingPasswordMutation__
 *
 * To run a mutation, you first call `useEnterSudoModeUsingPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterSudoModeUsingPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterSudoModeUsingPasswordMutation, { data, loading, error }] = useEnterSudoModeUsingPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEnterSudoModeUsingPasswordMutation(baseOptions?: Apollo.MutationHookOptions<EnterSudoModeUsingPasswordMutation, EnterSudoModeUsingPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnterSudoModeUsingPasswordMutation, EnterSudoModeUsingPasswordMutationVariables>(EnterSudoModeUsingPasswordDocument, options);
      }
export type EnterSudoModeUsingPasswordMutationHookResult = ReturnType<typeof useEnterSudoModeUsingPasswordMutation>;
export type EnterSudoModeUsingPasswordMutationResult = Apollo.MutationResult<EnterSudoModeUsingPasswordMutation>;
export type EnterSudoModeUsingPasswordMutationOptions = Apollo.BaseMutationOptions<EnterSudoModeUsingPasswordMutation, EnterSudoModeUsingPasswordMutationVariables>;