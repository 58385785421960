import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	type ArrayElement,
} from '~/types/utilities';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import MultiselectTableField from '~/components/logic/formFields/MultiselectTableField';
import SubmitButton, {
	SIZE_SMALL as SUBMIT_BUTTON_SIZE_SMALL,
	STYLE_HOLLOW as SUBMIT_BUTTON_STYLE_HOLLOW,
} from '~/components/atoms/forms/components/SubmitButton';

import useFormContext from '~/hooks/useFormContext';



const messages = defineMessages({
	applyChangesButton: {
		id: 'ui.general.applyChanges',
	},
	cancelButton: {
		id: 'ui.general.cancelButton',
	},
	pendingChangesLabel: {
		id: 'ui.issueDetail.ignoringTable.pendingChanges',
	},
});

export type IgnoringTableCellRendererInput<TItem> = {
	checkedItems: ReadonlyArray<TItem>,
	columnIndex: number,
	isChecked: boolean,
	isDisabled: boolean,
	item: TItem,
	rowIndex: number,
};

export type IgnoringTableDisabledGetterInput<TItem> = {
	item: TItem,
};

export type IgnoringTableGetColumnWidthInput = {
	index: number,
	width: number,
};

export type IgnoringTableHeaderCellRendererInput = {
	columnIndex: number,
};



type Props<TItems extends ReadonlyArray<any>> = {
	bodyCellRenderer: (input: IgnoringTableCellRendererInput<ArrayElement<TItems>>) => React.ReactNode,
	columnCount: number,
	columnWidth: number | ((input: IgnoringTableGetColumnWidthInput) => number | undefined),
	disabledGetter?: (input: IgnoringTableDisabledGetterInput<ArrayElement<TItems>>) => boolean,
	disabledRowExplanationRenderer?: () => React.ReactNode,
	headerCellRenderer: (input: IgnoringTableHeaderCellRendererInput) => React.ReactNode,
	items: TItems,
	name: string,
	onSubmitChangesCallback: (value: any) => Promise<void>,
	valueGetter?: (item: ArrayElement<TItems>) => any,
};

function IgnoringTable<TItems extends ReadonlyArray<any>>(props: Props<TItems>) {
	const {
		bodyCellRenderer,
		columnCount,
		columnWidth,
		disabledGetter,
		disabledRowExplanationRenderer,
		headerCellRenderer,
		items,
		name,
		onSubmitChangesCallback,
		valueGetter,
	} = props;

	const [counter, setCounter] = React.useState(0);

	const handleApplyChanges = React.useCallback(
		(values) => {
			return onSubmitChangesCallback(values[name]).then(() => {
				setCounter((counter) => counter + 1);
			});
		},
		[
			name,
			onSubmitChangesCallback,
			setCounter,
		],
	);

	const handleCancelChangesClick = React.useCallback(
		() => setCounter((counter) => counter + 1),
		[
			setCounter,
		],
	);

	const renderFooterCTA = React.useCallback(
		({ selectedData }) => (
			<div>
				<IgnoringTableCancelButton
					handleCancelChangesClick={handleCancelChangesClick}
					selectedData={selectedData}
				/>

				<IgnoringTableSubmitButton
					selectedData={selectedData}
				/>
			</div>
		),
		[
			handleCancelChangesClick,
		],
	);

	const renderFooterLabel = React.useCallback(
		({ selectedData }) => {
			if (selectedData.length === 0) {
				return false;
			}

			return (
				<Copy
					{...messages.pendingChangesLabel}
					values={{
						count: selectedData.length,
					}}
				/>
			);
		},
		[],
	);

	return (
		<Form
			defaultValues={{
				[name]: [],
			}}
			key={counter}
			onSuccess={handleApplyChanges}
		>
			<MultiselectTableField
				cellRenderer={bodyCellRenderer}
				columnCount={columnCount}
				columnWidth={columnWidth}
				disabledGetter={disabledGetter}
				disabledRowExplanationRenderer={disabledRowExplanationRenderer}
				footerCTARenderer={renderFooterCTA}
				footerLabelRenderer={renderFooterLabel}
				headerRenderer={headerCellRenderer}
				items={items}
				name={name}
				valueGetter={valueGetter}
			/>
		</Form>
	);
}



type IgnoringTableCancelButtonProps = {
	handleCancelChangesClick: () => void,
	selectedData: ReadonlyArray<any>,
};

const IgnoringTableCancelButton: React.FC<IgnoringTableCancelButtonProps> = (props) => {
	const {
		handleCancelChangesClick,
		selectedData,
	} = props;

	const {
		isSubmitting,
	} = useFormContext();

	if (isSubmitting || selectedData.length === 0) {
		return null;
	}

	return (
		<Button
			onClick={handleCancelChangesClick}
			size={ButtonSize.Small}
			style={ButtonStyle.Link}
		>
			<FormattedMessage {...messages.cancelButton} />
		</Button>
	);
};



type IgnoringTableSubmitButtonProps = {
	selectedData: ReadonlyArray<any>,
};

const IgnoringTableSubmitButton: React.FC<IgnoringTableSubmitButtonProps> = (props) => {
	const {
		selectedData,
	} = props;

	const {
		isSubmitting,
	} = useFormContext();

	return (
		<SubmitButton
			disabled={selectedData.length === 0}
			progress={isSubmitting}
			size={SUBMIT_BUTTON_SIZE_SMALL}
			style={SUBMIT_BUTTON_STYLE_HOLLOW}
			uppercase={true}
		>
			<FormattedMessage {...messages.applyChangesButton} />
		</SubmitButton>
	);
};



export default IgnoringTable;
