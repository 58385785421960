import React from 'react';

import AdminAndAccessControlFeaturesIcon from './builders/iconsData/benefitIcon/AdminAndAccessControlFeatures';
import AnalyticsAndGSCIntegrationIcon from './builders/iconsData/benefitIcon/AnalyticsAndGSCIntegration';
import BodySnapshotsIcon from './builders/iconsData/benefitIcon/BodySnapshots';
import CustomElementsIcon from './builders/iconsData/benefitIcon/CustomElements';
import CustomMonitoringLocationIcon from './builders/iconsData/benefitIcon/CustomMonitoringLocation';
import CWVMonitoringAndJSRenderingIcon from './builders/iconsData/benefitIcon/CWVMonitoringAndJSRendering';
import DataRetentionIcon from './builders/iconsData/benefitIcon/DataRetention';
import DataStudioAndAPIsIcon from './builders/iconsData/benefitIcon/DataStudioAndAPIs';
import DedicatedSuccessManagerIcon from './builders/iconsData/benefitIcon/DedicatedSuccessManager';
import ChangeTrackingAndAlertingIcon from './builders/iconsData/benefitIcon/ChangeTrackingAndAlerting';
import ChatPlatformIntegrationsIcon from './builders/iconsData/benefitIcon/ChatPlatformIntegrations';
import InvoiceBillingIcon from './builders/iconsData/benefitIcon/InvoiceBilling';
import LogFileAnalysisIcon from './builders/iconsData/benefitIcon/LogFileAnalysis';
import OnboardingAndCustomTrainingIcon from './builders/iconsData/benefitIcon/OnboardingAndCustomTraining';
import PrioritySupportIcon from './builders/iconsData/benefitIcon/PrioritySupport';
import RealtimeAuditingIcon from './builders/iconsData/benefitIcon/RealtimeAuditing';
import UnlimitedUsersWebsitesRechecksIcon from './builders/iconsData/benefitIcon/UnlimitedUsersWebsitesRechecks';

import InlineIcon from './builders/InlineIcon.part';

import {
	type Merge,
} from '~/types/utilities';



export enum BenefitIconType {
	AdminAndAccessControlFeatures = 'admin-and-access-control-features',
	AnalyticsAndGSCIntegrations = 'analytics-and-gsc-integrations',
	BodySnapshots = 'body-snapshots',
	CustomElements = 'custom-elements',
	CustomMonitoringLocation = 'custom-monitoring-location',
	CWVMonitoringAndJSRendering = 'cwv-monitoring-and-js-rendering',
	DataRetention = 'data-retention',
	DataStudioAndAPIs = 'data-studio-and-apis',
	DedicatedSuccessManager = 'dedicated-success-manager',
	ChangeTrackingAndAlerting = 'change-tracking-and-alerting',
	ChatPlatformIntegrations = 'chat-platform-integrations',
	InvoiceBilling = 'invoice-billing',
	LogFileAnalysis = 'log-file-analysis',
	OnboardingAndCustomTraining = 'onboarding-and-custom-traning',
	PrioritySupport = 'priority-support',
	RealtimeAuditing = 'realtime-auditing',
	UnlimitedUsersWebsitesRechecks = 'unlimited-users-websites-rechecks',
}

const componentMapping = {
	[BenefitIconType.AdminAndAccessControlFeatures]: AdminAndAccessControlFeaturesIcon,
	[BenefitIconType.AnalyticsAndGSCIntegrations]: AnalyticsAndGSCIntegrationIcon,
	[BenefitIconType.BodySnapshots]: BodySnapshotsIcon,
	[BenefitIconType.CustomElements]: CustomElementsIcon,
	[BenefitIconType.CustomMonitoringLocation]: CustomMonitoringLocationIcon,
	[BenefitIconType.CWVMonitoringAndJSRendering]: CWVMonitoringAndJSRenderingIcon,
	[BenefitIconType.DataRetention]: DataRetentionIcon,
	[BenefitIconType.DataStudioAndAPIs]: DataStudioAndAPIsIcon,
	[BenefitIconType.DedicatedSuccessManager]: DedicatedSuccessManagerIcon,
	[BenefitIconType.ChangeTrackingAndAlerting]: ChangeTrackingAndAlertingIcon,
	[BenefitIconType.ChatPlatformIntegrations]: ChatPlatformIntegrationsIcon,
	[BenefitIconType.InvoiceBilling]: InvoiceBillingIcon,
	[BenefitIconType.LogFileAnalysis]: LogFileAnalysisIcon,
	[BenefitIconType.OnboardingAndCustomTraining]: OnboardingAndCustomTrainingIcon,
	[BenefitIconType.PrioritySupport]: PrioritySupportIcon,
	[BenefitIconType.RealtimeAuditing]: RealtimeAuditingIcon,
	[BenefitIconType.UnlimitedUsersWebsitesRechecks]: UnlimitedUsersWebsitesRechecksIcon,
} as const;



type BaseProps<IconType extends BenefitIconType = BenefitIconType> = {
	/** Custom icon color */
	color?: string,
	/** Square size of icon */
	size?: number,
	/** Type of icon */
	type: IconType,
};

type IconProps<IconType extends BenefitIconType = BenefitIconType> = (
	Parameters<typeof componentMapping[IconType]['icon']>[0]
);

type Props<IconType extends BenefitIconType = BenefitIconType> = (
	Merge<IconProps<IconType>, BaseProps<IconType>>
);

const BenefitIcon: React.FC<Props> = (props) => {
	const {
		color,
		size = 32,
		type,
		...rest
	} = props;

	const icon = componentMapping[type].icon({
		color,
		size,
		...rest,
	});

	return (
		<InlineIcon
			icon={icon}
			size={size}
			viewBoxHeight={componentMapping[type].viewBoxHeight}
			viewBoxWidth={componentMapping[type].viewBoxWidth}
		/>
	);
};



export default BenefitIcon;
