import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedPlans from '~/hooks/useAllowedPlans';

import {
	LIST_OF_ALL_PLANS,
	isFeatureAvailable,
} from '~/model/universal';



function usePlanNecessaryForCurrentFeatures(
	accountId: CK.AccountId | null,
): GraphQL.AccountPlan | null {
	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountTariff = useAccountTariff(accountId);
	const allowedPlans = useAllowedPlans(accountId);

	if (
		accountFeaturesAvailability === null
		|| accountFeaturesUsage === null
		|| accountTariff === null
		|| allowedPlans === null
	) {
		return null;
	}

	const activeFeatures = accountFeaturesUsage.usedFeatures;

	for (const plan of LIST_OF_ALL_PLANS[accountTariff].filter((plan) => allowedPlans.includes(plan)).toArray()) {
		if (activeFeatures.every((featureName) => isFeatureAvailable(accountFeaturesAvailability, accountTariff, plan, featureName))) {
			return plan;
		}
	}

	return null;
}



export default usePlanNecessaryForCurrentFeatures;
