import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';
import TextualCellValue from '~/components/logic/datatables/cellValues/TextualCellValue';

import {
	trimHostname,
} from '~/model/utils';



class UrlCellValue extends Component {

	render() {
		const {
			value,
		} = this.props;

		if (value === null) {
			return (
				<NotApplicableValue ellipsis={true} />
			);
		}

		return (
			<TextualCellValue
				value={trimHostname(value)}
				zIndex={1200}
			/>
		);
	}

}

UrlCellValue.propTypes = {
	value: PropTypes.string.isRequired,
};



export default UrlCellValue;
