import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import MultiWebsitesDashboardWebsiteStatisticsBlock from '~/components/app/MultiWebsitesDashboardWebsiteStatisticsBlock';
import WebsiteDetailedLabel from '~/components/app/WebsiteDetailedLabel';
import WebsiteStatisticsPageChangesChart from '~/components/logic/charts/WebsiteStatisticsPageChangesChart';
import WebsiteStatisticsSheet from '~/components/patterns/websites/WebsiteStatisticsSheet';

import useNavigation from '~/hooks/useNavigation';
import useScrollContainer from '~/hooks/useScrollContainer';
import useWebsiteTimeCreatedAt from '~/hooks/useWebsiteTimeCreatedAt';



type Props = {
	accountId: CK.AccountId,
	website: {
		displayName: string,
		id: CK.WebsiteId,
		pageCapacity: number,
		pagesCount: number,
		problem: GraphQL.WebsiteProblem | null,
	},
};

const MultiWebsitesDashboardWebsiteCard: React.FC<Props> = (props) => {
	const {
		accountId,
		website,
	} = props;

	const navigate = useNavigation().navigate;
	const websiteId = website.id;
	const websiteCreatedAt = useWebsiteTimeCreatedAt(websiteId);

	const [hasBeenVisible, setHasBeenVisible] = React.useState(false);
	const [isVisible, setIsVisible] = React.useState(false);
	const scrollContainer = useScrollContainer();

	React.useEffect(
		() => {
			if (!hasBeenVisible && isVisible) {
				setHasBeenVisible(true);
			}
		},
		[
			hasBeenVisible,
			isVisible,
		],
	);

	const handleVisibilitySensorInput = React.useCallback(
		(_isVisible) => setIsVisible(_isVisible),
		[],
	);

	const handleHeaderClick = React.useCallback(
		() => {
			navigate({
				routeName: 'website.dashboard',
				routeParams: {
					websiteId,
				},
			});
		},
		[
			navigate,
			websiteId,
		],
	);

	return (
		<VisibilitySensor
			containment={scrollContainer}
			intervalCheck={true}
			offset={{
				bottom: -1000,
				top: -1000,
			}}
			onChange={handleVisibilitySensorInput}
			partialVisibility={true}
			scrollCheck={true}
		>
			<WebsiteStatisticsSheet
				chart={(
					<WebsiteStatisticsPageChangesChart
						accountId={accountId}
						hasBeenVisible={hasBeenVisible}
						isVisible={isVisible}
						websiteCreatedAt={websiteCreatedAt}
						websiteId={website.id}
					/>
				)}
				onHeaderClick={handleHeaderClick}
				overview={websiteCreatedAt !== null && (
					<MultiWebsitesDashboardWebsiteStatisticsBlock
						accountId={accountId}
						hasBeenVisible={hasBeenVisible}
						isVisible={isVisible}
						websiteCreatedAt={websiteCreatedAt}
						websiteId={website.id}
					/>
				)}
				title={(
					<WebsiteDetailedLabel
						website={website}
					/>
				)}
			/>
		</VisibilitySensor>
	);
};



export default MultiWebsitesDashboardWebsiteCard;
