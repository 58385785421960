/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAwsAccountIdQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAwsAccountIdQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly logFileAnalysisForeignAwsAccountId: string | null } | null };

export type UpdateWebsiteAwsAccountIdMutationVariables = GraphQL.Exact<{
  awsAccountId: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteAwsAccountIdMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteLogFileAnalysisAwsAccountId: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly logFileAnalysisForeignAwsAccountId: string | null } | null } } };


export const WebsiteAwsAccountIdDocument = gql`
    query WebsiteAwsAccountId($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    logFileAnalysisForeignAwsAccountId
  }
}
    `;

/**
 * __useWebsiteAwsAccountIdQuery__
 *
 * To run a query within a React component, call `useWebsiteAwsAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAwsAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAwsAccountIdQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAwsAccountIdQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAwsAccountIdQuery, WebsiteAwsAccountIdQueryVariables> & ({ variables: WebsiteAwsAccountIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAwsAccountIdQuery, WebsiteAwsAccountIdQueryVariables>(WebsiteAwsAccountIdDocument, options);
      }
export function useWebsiteAwsAccountIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAwsAccountIdQuery, WebsiteAwsAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAwsAccountIdQuery, WebsiteAwsAccountIdQueryVariables>(WebsiteAwsAccountIdDocument, options);
        }
export function useWebsiteAwsAccountIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteAwsAccountIdQuery, WebsiteAwsAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAwsAccountIdQuery, WebsiteAwsAccountIdQueryVariables>(WebsiteAwsAccountIdDocument, options);
        }
export type WebsiteAwsAccountIdQueryHookResult = ReturnType<typeof useWebsiteAwsAccountIdQuery>;
export type WebsiteAwsAccountIdLazyQueryHookResult = ReturnType<typeof useWebsiteAwsAccountIdLazyQuery>;
export type WebsiteAwsAccountIdSuspenseQueryHookResult = ReturnType<typeof useWebsiteAwsAccountIdSuspenseQuery>;
export type WebsiteAwsAccountIdQueryResult = Apollo.QueryResult<WebsiteAwsAccountIdQuery, WebsiteAwsAccountIdQueryVariables>;
export const UpdateWebsiteAwsAccountIdDocument = gql`
    mutation UpdateWebsiteAwsAccountId($awsAccountId: String!, $websiteId: WebsiteId!) {
  UpdateWebsiteLogFileAnalysisAwsAccountId(
    awsAccountId: $awsAccountId
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        logFileAnalysisForeignAwsAccountId
      }
    }
  }
}
    `;
export type UpdateWebsiteAwsAccountIdMutationFn = Apollo.MutationFunction<UpdateWebsiteAwsAccountIdMutation, UpdateWebsiteAwsAccountIdMutationVariables>;

/**
 * __useUpdateWebsiteAwsAccountIdMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteAwsAccountIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteAwsAccountIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteAwsAccountIdMutation, { data, loading, error }] = useUpdateWebsiteAwsAccountIdMutation({
 *   variables: {
 *      awsAccountId: // value for 'awsAccountId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteAwsAccountIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteAwsAccountIdMutation, UpdateWebsiteAwsAccountIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteAwsAccountIdMutation, UpdateWebsiteAwsAccountIdMutationVariables>(UpdateWebsiteAwsAccountIdDocument, options);
      }
export type UpdateWebsiteAwsAccountIdMutationHookResult = ReturnType<typeof useUpdateWebsiteAwsAccountIdMutation>;
export type UpdateWebsiteAwsAccountIdMutationResult = Apollo.MutationResult<UpdateWebsiteAwsAccountIdMutation>;
export type UpdateWebsiteAwsAccountIdMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteAwsAccountIdMutation, UpdateWebsiteAwsAccountIdMutationVariables>;