import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import RadioList from './RadioList';



class ColorList extends Component {

	_getItems() {
		const {
			colors,
		} = this.props;

		const items = [];

		colors.forEach((color) => {
			items.push({
				label: (
					<span
						className="color-form-tag__content"
						style={{
							backgroundColor: color,
						}}
					/>
				),
				showRadioElement: false,
				value: color,
			});
		});

		return items;
	}



	render() {
		const {
			name,
			onChangeCallback,
		} = this.props;

		const optionalProps = {};
		if (onChangeCallback) {
			optionalProps.onChangeCallback = onChangeCallback;
		}

		return (
			<RadioList
				className="color-form-tag__wrapper"
				inline={true}
				itemClassName="color-form-tag"
				items={this._getItems()}
				name={name}
				width={false}
				{...optionalProps}
			/>
		);
	}

}

ColorList.propTypes = {
	colors: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	onChangeCallback: PropTypes.func,
};



export default ColorList;
