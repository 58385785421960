import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import AccountContextSelect from '../accountAccess/AccountContextSelect';
import AccountPricingSection from '~/components/app/AccountPricingSection';
import AccountSettingsSection from '~/components/app/AccountSettingsSection';
import AccountAdmin from '../accountAdmin/AccountAdmin';
import ConnectedAccountsSection from '../accountAccess/ConnectedAccountsSection';
import HeaderNavigationLayout from '~/components/patterns/layout/screen/headers/HeaderNavigationLayout';
import MembersOverviewSection from '~/components/app/MembersOverviewSection';
import NumberBadge from '~/components/patterns/tags/NumberBadge';
import OnlyForAdmin from '~/components/app/OnlyForAdmin';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';
import TabNavigation, {
	TabNavigationStyle,
} from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem, {
	TabNavigationItemStyle,
} from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';
import TabNavigationStaticItem, {
	TabNavigationStaticItemStyle,
} from '~/components/patterns/navigations/tabNavigation/TabNavigationStaticItem';
import WebsitesOverviewSection from '~/components/app/WebsitesOverviewSection';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import useAccountCanSignup from '~/hooks/useAccountCanSignup';
import useAccountClientConnections from '~/hooks/useAccountClientConnections';
import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountId from '~/hooks/useAccountId';
import useAccountProblems from '~/hooks/useAccountProblems';
import useAccountWebsiteCount from '~/hooks/useAccountWebsiteCount';
import useAllInvitations from '~/hooks/useAllInvitations';
import useAllUsers from '~/hooks/useAllUsers';
import useHomeAccountIds from '~/hooks/useHomeAccountIds';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useViewportType from '~/hooks/useViewportType';

import {
	teamDetailTabSelector,
} from '~/state/ui/content/selectors';



export const TAB_ADMIN = 'admin';
export const TAB_CONNECTED_ACCOUNTS = 'connectedAccounts';
export const TAB_MEMBERS = 'members';
export const TAB_PRICING = 'pricing';
export const TAB_SETTINGS = 'settings';
export const TAB_WEBSITES = 'websites';

const menuLabelMessages = defineMessages({
	[TAB_ADMIN]: {
		id: 'ui.teamDetail.admin.menuLabel',
	},
	[TAB_CONNECTED_ACCOUNTS]: {
		id: 'ui.connectedAccounts.menuLabel',
	},
	[TAB_MEMBERS]: {
		id: 'ui.teamDetail.members.menuLabel',
	},
	[TAB_PRICING]: {
		id: 'ui.teamDetail.billing.menuLabelSignup',
	},
	[TAB_SETTINGS]: {
		id: 'ui.teamDetail.accountSettings.menuLabel',
	},
	[TAB_WEBSITES]: {
		id: 'ui.teamDetail.websites.menuLabel',
	},
});

const tabsToRoutes = {
	[TAB_WEBSITES]: 'account.websites',
	[TAB_MEMBERS]: 'account.members',
	[TAB_SETTINGS]: 'account.settings',
	[TAB_PRICING]: 'account.pricing',
	[TAB_CONNECTED_ACCOUNTS]: 'account.connectedAccounts',
	[TAB_ADMIN]: 'account.admin',
};

export const AccountScreenContext = React.createContext<any>(null);



const AccountScreen = (props) => {
	const {
		carouselPanel,
	} = props;

	const stickyContentElementRef = React.useRef(null);

	const accountId = useAccountId();
	const homeAccountIds = useHomeAccountIds();

	const accountCanSignup = useAccountCanSignup(accountId);
	const accountClientConnections = useAccountClientConnections(accountId);
	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);
	const accountProblems = useAccountProblems(accountId);
	const accountWebsiteCount = useAccountWebsiteCount(accountId);
	const allInvitations = useAllInvitations();
	const allUsers = useAllUsers();
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const teamDetailTab = useSelector(teamDetailTabSelector);
	const viewportType = useViewportType();

	const canManageDetails = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageDetails,
	);

	const canViewBilling = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ViewBilling,
	);

	const canViewSignup = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ViewSignup,
	);

	const getNavigationItems = () => {
		let menuItems = [
			TAB_WEBSITES,
			TAB_MEMBERS,
			TAB_SETTINGS,
			TAB_PRICING,
			TAB_CONNECTED_ACCOUNTS,
			TAB_ADMIN,
		];

		if (canManageDetails.yes === false) {
			menuItems = menuItems.filter((item) => ![
				TAB_MEMBERS,
				TAB_SETTINGS,
			].includes(item));
		}

		if (!accountCanSignup || canViewSignup.yes === false) {
			menuItems = menuItems.filter((item) => item !== TAB_PRICING);
		}

		const showAdmin = (
			kingdomAdminFeatures.areVisible
			&& accountId !== null
			&& homeAccountIds !== null
			&& homeAccountIds.includes(accountId)
		);

		if (!showAdmin) {
			menuItems = menuItems.filter((item) => item !== TAB_ADMIN);
		}

		const showConnectedAccounts = (
			accountEnabledFeatures !== null
			&& accountEnabledFeatures.includes(GraphQL.AccountFeature.ConnectToClient)
		);

		if (!showConnectedAccounts) {
			menuItems = menuItems.filter((item) => item !== TAB_CONNECTED_ACCOUNTS);
		}

		return menuItems;
	};

	function renderNavigation() {
		const navigationItems: Array<React.ReactNode> = [];
		const items = getNavigationItems();
		const activeTabIndex = items.indexOf(teamDetailTab);

		items.forEach((tab) => {
			let icon;

			if (tab === TAB_WEBSITES) {
				icon = accountWebsiteCount !== null && (
					<NumberBadge
						color="#98A5B3"
						value={accountWebsiteCount}
					/>
				);
			} else if (tab === TAB_MEMBERS) {
				icon = allUsers.isLoaded && allInvitations !== null && accountId !== null && (
					<NumberBadge
						color="#98A5B3"
						value={allUsers.listByAccount(accountId).length + allInvitations.listByAccount(accountId).length}
					/>
				);
			} else if (tab === TAB_CONNECTED_ACCOUNTS) {
				icon = accountClientConnections.count > 0 && (
					<NumberBadge
						color="#98A5B3"
						value={accountClientConnections.count}
					/>
				);
			}

			if (tab === TAB_SETTINGS) {
				if (canViewBilling.yes && accountProblems.hasPaymentProblem) {
					const iconStatus = accountProblems.isSubscriptionSuspended
						? StatusFlagStatus.Critical
						: StatusFlagStatus.Warning;

					icon = (
						<StatusFlag status={iconStatus} />
					);
				}
			}

			navigationItems.push((
				<TabNavigationItem
					isActive={tab === teamDetailTab}
					key={tab}
					linkRouteName={tabsToRoutes[tab]}
					linkRouteParams={{
						accountId,
					}}
					style={TabNavigationItemStyle.Light}
					suffixIcon={icon}
					uppercase={viewportType.isSmall}
				>
					<FormattedMessage {...menuLabelMessages[tab]} />
				</TabNavigationItem>
			));
		});

		const accountContextSelect = (
			<AccountContextSelect />
		);

		if (viewportType.isSmall) {
			navigationItems.unshift((
				<TabNavigationStaticItem
					key="account-context-select"
					style={TabNavigationStaticItemStyle.Light}
				>
					{accountContextSelect}
				</TabNavigationStaticItem>
			));
		}

		return (
			<HeaderNavigationLayout
				switchElement={!viewportType.isSmall && accountContextSelect}
			>
				<TabNavigation
					scrollToItem={activeTabIndex >= 0 ? activeTabIndex : 0}
					stretchItems={false}
					style={TabNavigationStyle.Dark}
				>
					{navigationItems}
				</TabNavigation>
			</HeaderNavigationLayout>
		);
	}

	function renderContent() {
		switch (teamDetailTab) {

			case TAB_WEBSITES:
				return (
					<WebsitesOverviewSection
						panel={carouselPanel}
					/>
				);

			case TAB_MEMBERS:
				return (
					<WhenAccountActionAllowed
						accountId={accountId}
						action={GraphQL.ActionWithAccount.ManageDetails}
					>
						{({ isAllowed }) => {
							if (isAllowed.yes === false) {
								return false;
							}

							return (
								<MembersOverviewSection
									panel={carouselPanel}
								/>
							);
						}}
					</WhenAccountActionAllowed>
				);

			case TAB_SETTINGS:
				return (
					<WhenAccountActionAllowed
						accountId={accountId}
						action={GraphQL.ActionWithAccount.ManageDetails}
					>
						{({ isAllowed }) => {
							if (isAllowed.yes === false) {
								return false;
							}

							return (
								<AccountSettingsSection />
							);
						}}
					</WhenAccountActionAllowed>
				);

			case TAB_PRICING:
				return (
					<WhenAccountActionAllowed
						accountId={accountId}
						action={GraphQL.ActionWithAccount.ViewSignup}
					>
						{({ isAllowed }) => {
							if (isAllowed.yes === false) {
								return false;
							}

							return (
								<AccountPricingSection />
							);
						}}
					</WhenAccountActionAllowed>
				);

			case TAB_CONNECTED_ACCOUNTS:
				return (
					<ConnectedAccountsSection />
				);

			case TAB_ADMIN:
				return (
					<OnlyForAdmin>
						<AccountAdmin />
					</OnlyForAdmin>
				);

		}

		return null;
	}

	const accountScreenContext = React.useMemo(
		() => ({
			stickyContentElementRef,
		}),
		[stickyContentElementRef],
	);

	return (
		<AccountScreenContext.Provider value={accountScreenContext}>
			<ScreenLayout
				header={(
					<ScreenHeader
						header={renderNavigation()}
					/>
				)}
				isHeaderFixed={true}
				stickyContentElements={(
					<div ref={stickyContentElementRef} />
				)}
			>
				{renderContent()}
			</ScreenLayout>
		</AccountScreenContext.Provider>
	);
};



export default AccountScreen;
