import React from 'react';
import {
	defineMessage,
} from 'react-intl';

import CloudflareStreamVideo from '~/components/patterns/videos/CloudflareStreamVideo';
import Copy, {
	linkExternal,
	linkInternal,
} from '~/components/logic/Copy';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';
import RichText from '~/components/patterns/typography/RichText';
import WidthLimiter from '~/components/patterns/utils/WidthLimiter';



const message = defineMessage({
	id: 'ui.connectedAccounts.overview.blankSlate',
});



type Props = {
	isCertifiedPartner: boolean,
};

const BlankSlateMessage: React.FC<Props> = (props) => {
	const {
		isCertifiedPartner,
	} = props;

	return (
		<WidthLimiter width={750}>
			<InterfaceMessage fullwidth={true}>
				<RichText>
					<Copy
						{...message}
						values={{
							isCertifiedPartner: isCertifiedPartner ? 'yes' : 'no',
							linkArticle: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
							openModal: linkInternal('account.connectedAccounts.connectAccount'),
						}}
					/>
					<CloudflareStreamVideo
						cloudflareStreamId="7f04207bfc465970f4f9d12d7ca70bac"
						height={360}
						posterSrc="https://www.contentkingapp.com/media/video-posters/collaboration/collaboration@2x.png"
						width={637}
					/>
				</RichText>
			</InterfaceMessage>
		</WidthLimiter>
	);
};



export default BlankSlateMessage;
