import GraphQL from '~/types/graphql';



export function doesAccountTypeHavePageBundle(accountType) {
	return accountType === GraphQL.AccountType.Universal;
}



export function listUsedBillingCycles(
	accountPhase: GraphQL.AccountPhase | null,
	accountBillingCycle: GraphQL.Term | null,
) {
	if (accountPhase === null || accountBillingCycle === null) {
		return [];
	}

	if (accountPhase !== GraphQL.AccountPhase.Customer) {
		return [];
	}

	return [
		accountBillingCycle,
	];
}
