import React from 'react';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserEmail from '~/hooks/useUserEmail';



function useIsCurrentUser() {
	const currentUserId = useCurrentUserId();

	const currentUserEmail = useUserEmail(currentUserId);

	return React.useCallback(
		(email: string): boolean | null => {
			if (currentUserEmail === null) {
				return null;
			}

			return currentUserEmail === email;
		},
		[
			currentUserEmail,
		],
	);
}



export default useIsCurrentUser;
