import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const FundamentalsIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeWidth="1"
			>
				<g id="info">
					<path
						d="M27,15.8903 C27,21.9053 22.075,26.7803 16,26.7803 C9.925,26.7803 5,21.9053 5,15.8903 C5,9.8753 9.925,5.0003 16,5.0003 C22.075,5.0003 27,9.8753 27,15.8903 L27,15.8903 Z"
						id="Stroke-1594"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M15.0527,15.0972 C15.0527,14.5372 15.5207,14.0732 16.1187,14.0732 C16.7167,14.0732 17.1857,14.5372 17.1857,15.0972 L17.1857,20.6832 C17.1857,21.2432 16.6847,21.7082 16.1187,21.7082 C15.5207,21.7082 15.0527,21.2432 15.0527,20.6832 L15.0527,15.0972 Z M16.1187,9.7672 C17.1857,9.7192 17.7827,11.1432 16.9757,11.8482 C16.2967,12.6162 14.8257,12.0402 14.8907,10.9832 C14.8907,10.3272 15.4557,9.7672 16.1187,9.7672 L16.1187,9.7672 Z"
						fill={color}
						id="Fill-1595"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default FundamentalsIconBuilder;
