import React from 'react';

import GraphQL from '~/types/graphql';

import AdobeAnalyticsBlock from '../../../logic/pageDetail/components/AdobeAnalyticsBlock';
import ContentBlock from '../../../logic/pageDetail/components/ContentBlock';
import ConversionsBlock from '../../../logic/pageDetail/components/ConversionsBlock';
import CustomElementsBlock from '../../../logic/pageDetail/components/CustomElementsBlock';
import EnrichmentFieldsBlock from '~/components/app/PageDetail/EnrichmentFieldsBlock';
import GoogleAnalyticsBlock from '../../../logic/pageDetail/components/GoogleAnalyticsBlock';
import GoogleSearchConsoleBlock from '../../../logic/pageDetail/components/GoogleSearchConsoleBlock';
import Grid, {
	GridGapsSize,
} from '~/components/patterns/structures/Grid';
import IndexabilityBlock from '../../../logic/pageDetail/components/IndexabilityBlock';
import LighthouseBlock from '~/components/logic/pageDetail/components/LighthouseBlock';
import MarginsList from '../../lists/MarginsList';
import RelationsBlock from '../../../logic/pageDetail/components/RelationsBlock';
import SchemaOrgBlock from '../../../logic/pageDetail/components/SchemaOrgBlock';
import SearchEngineActivityBlock from '~/components/logic/pageDetail/components/SearchEngineActivityBlock';
import SocialBlock from '../../../logic/pageDetail/components/SocialBlock';
import WithCustomElementsFeature from '~/components/app/WithCustomElementsFeature';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import useLogFileAnalysisFeatureNudge from '~/hooks/useLogFileAnalysisFeatureNudge';
import usePageType from '~/hooks/usePageType';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



export const COLUMNS_LAYOUT_BREAKPOINT = 1420;



const PageCurrentData: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const pageType = usePageType(legacyUrlId, websiteId);
	const viewportWidth = useViewportWidth();
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const isAdobeAnalyticsEnabled = websiteIntegrations.isEnabled(GraphQL.WebsiteIntegrationType.AdobeAnalytics);
	const isGoogleAnalyticsEnabled = websiteIntegrations.isEnabled(GraphQL.WebsiteIntegrationType.GoogleAnalytics);

	const {
		isFeatureAttainable: isEnrichmentFieldsFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.EnrichmentFields_5);

	const {
		isFeatureAttainable: isLighthouseMonitoringFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LighthouseMonitoring);

	const {
		isLogFileAnalysisEnabled,
		nudgeLogFileAnalysis,
		upsellLogFileAnalysis,
	} = useLogFileAnalysisFeatureNudge(websiteId);

	const isTypePage = pageType === GraphQL.PageType.Page || pageType === null;

	const showGoogleAnalyticsBlock = (
		isGoogleAnalyticsEnabled
		|| !isAdobeAnalyticsEnabled
	);

	const showAdobeAnalyticsBlock = isAdobeAnalyticsEnabled;

	const showLogFileAnalysisBlock = (
		isLogFileAnalysisEnabled
		|| nudgeLogFileAnalysis
		|| upsellLogFileAnalysis
	);

	const showLighthouseBlock = (
		isTypePage
		&& isLighthouseMonitoringFeatureAttainable
	);

	if (viewportWidth < COLUMNS_LAYOUT_BREAKPOINT) {
		return (
			<Grid
				columnsCount={1}
				gapsSize={GridGapsSize.Responsive}
			>
				<IndexabilityBlock />

				{showLighthouseBlock && (
					<LighthouseBlock />
				)}

				{isTypePage && (
					<ContentBlock />
				)}

				{isTypePage && (
					<WithCustomElementsFeature>
						<CustomElementsBlock />
					</WithCustomElementsFeature>
				)}

				{isEnrichmentFieldsFeatureAttainable && (
					<EnrichmentFieldsBlock />
				)}

				<GoogleSearchConsoleBlock />

				{showGoogleAnalyticsBlock && (
					<GoogleAnalyticsBlock />
				)}

				{showAdobeAnalyticsBlock && (
					<AdobeAnalyticsBlock />
				)}

				<RelationsBlock />

				{showLogFileAnalysisBlock && (
					<SearchEngineActivityBlock />
				)}

				{isTypePage && (
					<SocialBlock />
				)}

				{isTypePage && (
					<ConversionsBlock />
				)}

				{isTypePage && (
					<SchemaOrgBlock />
				)}
			</Grid>
		);
	}

	return (
		<Grid
			columnsCount={2}
			gapsSize={GridGapsSize.Responsive}
		>
			<MarginsList>
				<IndexabilityBlock />

				{showLighthouseBlock && (
					<LighthouseBlock />
				)}

				{isTypePage && (
					<WithCustomElementsFeature>
						<CustomElementsBlock />
					</WithCustomElementsFeature>
				)}

				{isEnrichmentFieldsFeatureAttainable && (
					<EnrichmentFieldsBlock />
				)}

				<GoogleSearchConsoleBlock />

				{showGoogleAnalyticsBlock && (
					<GoogleAnalyticsBlock />
				)}

				{showAdobeAnalyticsBlock && (
					<AdobeAnalyticsBlock />
				)}

				{isTypePage && (
					<SocialBlock />
				)}
			</MarginsList>

			<MarginsList>
				{isTypePage && (
					<ContentBlock />
				)}

				<RelationsBlock />

				{showLogFileAnalysisBlock && (
					<SearchEngineActivityBlock />
				)}

				{isTypePage && (
					<ConversionsBlock />
				)}

				{isTypePage && (
					<SchemaOrgBlock />
				)}
			</MarginsList>
		</Grid>
	);
};



export default PageCurrentData;
