import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ScreenAnnotation from '~/components/patterns/screens/parts/ScreenAnnotation';

import {
	isNumber,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	empty: {
		id: 'ui.robotsTxtComparisonAnnotation.empty',
	},
	emptyBecause: {
		id: 'ui.robotsTxtComparisonAnnotation.emptyBecause',
	},
	contentsRemoved: {
		id: 'ui.robotsTxtComparisonAnnotation.contentsRemoved',
	},
	redirected: {
		id: 'ui.robotsTxtComparisonAnnotation.redirected',
	},
	removed: {
		id: 'ui.robotsTxtComparisonAnnotation.removed',
	},
	restored: {
		id: 'ui.robotsTxtComparisonAnnotation.restored',
	},
	same: {
		id: 'ui.robotsTxtComparisonAnnotation.same',
	},
	timeout: {
		id: 'ui.robotsTxtComparisonAnnotation.timeout',
	},
	unavailable: {
		id: 'ui.robotsTxtComparisonAnnotation.unavailable',
	},
});



type Props = {
	isNextContentEmpty: boolean,
	lines?: Array<any>,
	nextFailureReason: GraphQL.FetchingFailureReason | null,
	nextStatusCode: number | null,
	previousFailureReason: GraphQL.FetchingFailureReason | null,
	previousStatusCode: number | null,
};

const RobotsTxtComparisonAnnotation: React.FC<Props> = (props) => {
	const {
		isNextContentEmpty,
		lines,
		nextFailureReason,
		nextStatusCode,
		previousFailureReason,
		previousStatusCode,
	} = props;

	if (!isNumber(nextStatusCode) || !isNumber(previousStatusCode)) {
		return null;
	}

	let message: React.ReactNode = null;

	if (
		(previousStatusCode < 300 || previousStatusCode >= 400)
		&& (nextStatusCode >= 300 && nextStatusCode < 400)
	) {
		message = (
			<FormattedMessage
				{...messages.redirected}
				values={{
					statusCode: nextStatusCode,
				}}
			/>
		);
	} else if (
		(previousStatusCode < 400 || previousStatusCode >= 500)
		&& (nextStatusCode >= 400 && nextStatusCode < 500)
	) {
		message = (
			<FormattedMessage
				{...messages.removed}
				values={{
					statusCode: nextStatusCode,
				}}
			/>
		);
	} else if (
		previousStatusCode < 500
		&& nextStatusCode >= 500
	) {
		message = (
			<FormattedMessage
				{...messages.unavailable}
				values={{
					statusCode: nextStatusCode,
				}}
			/>
		);
	} else if (
		previousFailureReason !== GraphQL.FetchingFailureReason.TimeLimit
		&& nextFailureReason === GraphQL.FetchingFailureReason.TimeLimit
	) {
		message = (
			<FormattedMessage {...messages.timeout} />
		);
	} else if (
		previousStatusCode < 300
		&& nextStatusCode < 300
		&& (lines?.every((line) => line.op === 'removed') || isNextContentEmpty)
	) {
		message = (
			<FormattedMessage {...messages.contentsRemoved} />
		);
	} else if (
		previousStatusCode >= 300
		&& nextStatusCode < 300
	) {
		message = (
			<FormattedMessage {...messages.restored} />
		);
	} else if (
		previousStatusCode < 300
		&& nextStatusCode < 300
		&& lines?.every((line) => line.op === 'same')
	) {
		message = (
			<FormattedMessage {...messages.same} />
		);
	} else if (isNextContentEmpty) {
		if (previousFailureReason !== null && nextFailureReason !== null) {
			message = (
				<FormattedMessage
					{...messages.emptyBecause}
					values={{
						nextFailureReason,
						nextStatusCode,
						previousFailureReason,
						previousStatusCode,
					}}
				/>
			);
		} else {
			message = (
				<FormattedMessage {...messages.empty} />
			);
		}
	}

	if (message === null) {
		return null;
	}

	return (
		<ScreenAnnotation>
			{message}
		</ScreenAnnotation>
	);
};



export default RobotsTxtComparisonAnnotation;
