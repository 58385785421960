/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillingEntitiesQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type BillingEntitiesQuery = { readonly __typename?: 'Query', readonly billingEntities: ReadonlyArray<{ readonly __typename?: 'BillingEntity', readonly id: GraphQL.SubscriptionsSellerCode, readonly allowedCurrencies: ReadonlyArray<GraphQL.Currency>, readonly forCountries: ReadonlyArray<string> }> };


export const BillingEntitiesDocument = gql`
    query BillingEntities {
  billingEntities {
    id
    allowedCurrencies
    forCountries
  }
}
    `;

/**
 * __useBillingEntitiesQuery__
 *
 * To run a query within a React component, call `useBillingEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<BillingEntitiesQuery, BillingEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingEntitiesQuery, BillingEntitiesQueryVariables>(BillingEntitiesDocument, options);
      }
export function useBillingEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingEntitiesQuery, BillingEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingEntitiesQuery, BillingEntitiesQueryVariables>(BillingEntitiesDocument, options);
        }
export function useBillingEntitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BillingEntitiesQuery, BillingEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BillingEntitiesQuery, BillingEntitiesQueryVariables>(BillingEntitiesDocument, options);
        }
export type BillingEntitiesQueryHookResult = ReturnType<typeof useBillingEntitiesQuery>;
export type BillingEntitiesLazyQueryHookResult = ReturnType<typeof useBillingEntitiesLazyQuery>;
export type BillingEntitiesSuspenseQueryHookResult = ReturnType<typeof useBillingEntitiesSuspenseQuery>;
export type BillingEntitiesQueryResult = Apollo.QueryResult<BillingEntitiesQuery, BillingEntitiesQueryVariables>;