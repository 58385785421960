import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Completed state of achievement */
	isDone: boolean,
	/** Callback triggered on click */
	onClick: () => void,
	/** Description of achievement */
	label: React.ReactNode,
};



const Achievement: React.FC<Props> = (props) => {
	const {
		isDone,
		label,
		onClick,
	} = props;

	const componentClasses = classNames({
		'profile-completeness-achievement': true,
		'profile-completeness-achievement--is-completed': isDone,
	});

	return (
		<div
			className={componentClasses}
			onClick={onClick}
		>
			<div className="profile-completeness-achievement__label">
				{label}
			</div>
		</div>
	);
};



export default Achievement;
