import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import SelectField from '../../../atoms/forms/components/SelectField';

import {
	SEGMENT_CHANGE_TYPE_PAGES_ENTERED,
	SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT,
	SEGMENT_CHANGE_TYPE_PAGES_LEFT,
} from '~/model/alerts';



const messages = defineMessages({
	[SEGMENT_CHANGE_TYPE_PAGES_ENTERED]: {
		id: 'ui.alertsConfiguration.segmentChangeType.entered',
	},
	[SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT]: {
		id: 'ui.alertsConfiguration.segmentChangeType.enteredOrLeft',
	},
	[SEGMENT_CHANGE_TYPE_PAGES_LEFT]: {
		id: 'ui.alertsConfiguration.segmentChangeType.left',
	},
});



type Props = {
	name: string,
};

const SegmentChangeTypeField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	const options = [
		SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT,
		SEGMENT_CHANGE_TYPE_PAGES_ENTERED,
		SEGMENT_CHANGE_TYPE_PAGES_LEFT,
	];

	return (
		<SelectField
			name={name}
			options={options.map((option) => {
				return {
					label: (
						<FormattedMessage {...messages[option]} />
					),
					name: option,
				};
			})}
		/>
	);
};



export default React.memo(SegmentChangeTypeField);
