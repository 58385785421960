import classNames from 'classnames';
import React, {
	PureComponent,
} from 'react';

import HelpHint from '~/components/patterns/hints/HelpHint';
import Hint from '~/components/patterns/hints/hint/Hint';
import Ellipsis from '~/components/patterns/values/Ellipsis';



export enum TableLabelActiveSortingState {
	Ascending = 'asc',
	Descending = 'desc',
	NoSorting = 'no-sorting',
}

type Props = {
	activeSortingState?: TableLabelActiveSortingState,
	/** Possibility to align to the center */
	centerAligned?: boolean,
	/** Possible hoverable tooltip available when label is in disabled state */
	disabledTooltip?: React.ReactNode | string,
	/** Attached help hint with explanation tooltip */
	explanatoryTooltip?: React.ReactNode | string,
	/** Additional footer row */
	footer?: React.ReactNode,
	/** Allow to set sorting icon as disabled */
	isDisabled?: boolean,
	/** Show label text in highlighted style */
	isHighlighted?: boolean,
	/** Enable sortable label */
	isSortable?: boolean,
	/** Label text */
	label?: React.ReactNode,
	/** Additional small checkbox attached to label */
	labelCheckbox?: React.ReactNode,
	/** Icon prepend to label */
	labelIcon?: React.ReactNode,
	/** Filtering operator */
	labelOperator?: React.ReactNode | string,
	/** Callback which makes label clickable */
	onClickCallback?: () => void,
	/** Make default sort state reversed */
	reversedSorting?: boolean,
	/** Additional label suffix */
	suffix?: React.ReactNode,
	/** Make label texts uppercased */
	uppercase?: boolean,
};



class TableLabel extends PureComponent<Props> {

	static defaultProps = {
		activeSortingState: TableLabelActiveSortingState.NoSorting,
		centerAligned: false,
		isSortable: false,
		reversedSorting: false,
		uppercase: true,
	};



	constructor(props) {
		super(props);

		this._handleLabelClick = this._handleLabelClick.bind(this);
	}



	_handleLabelClick(e) {
		e.preventDefault();

		const {
			onClickCallback,
		} = this.props;

		if (!onClickCallback) {
			return false;
		}

		onClickCallback();
	}



	_renderCheckbox() {
		const {
			labelCheckbox,
		} = this.props;

		if (!labelCheckbox) {
			return false;
		}

		return (
			<span className="table-label__checkbox">
				{labelCheckbox}
			</span>
		);
	}



	_renderIcon() {
		const {
			labelIcon,
		} = this.props;

		if (!labelIcon) {
			return false;
		}

		return (
			<span className="table-label__icon">
				{labelIcon}
			</span>
		);
	}



	_renderLabel() {
		const {
			explanatoryTooltip,
			label,
			labelOperator,
		} = this.props;

		return (
			<span className="table-label__label-text">
				{label && (
					<Ellipsis
						hoverTimeout={50}
						popupZIndex={2500}
					>
						{label}
					</Ellipsis>
				)}
				{labelOperator && (
					<em className="table-label__operator">
						{labelOperator}
					</em>
				)}
				{explanatoryTooltip && (
					<span className="table-label__help-hint">
						<HelpHint message={explanatoryTooltip} />
					</span>
				)}
			</span>
		);
	}



	_renderSuffix() {
		const {
			suffix,
		} = this.props;

		if (!suffix) {
			return false;
		}

		return (
			<span className="table-label__suffix">
				{suffix}
			</span>
		);
	}



	render() {
		const {
			activeSortingState,
			centerAligned,
			footer,
			isDisabled,
			isHighlighted,
			isSortable,
			labelCheckbox,
			onClickCallback,
			reversedSorting,
			disabledTooltip,
			uppercase,
		} = this.props;

		const componentClasses = classNames({
			'table-label': true,
			'table-label--center-aligned': centerAligned,
		});

		const labelClasses = classNames({
			'table-label__label': true,
			'table-label__label--has-checkbox': labelCheckbox,
			'table-label__label--is-highlighted': isHighlighted,
			'table-label__label--is-uppercase': uppercase,
		});

		const containerClasses = classNames({
			'table-label__container': true,
			'table-label__container--is-clickable': onClickCallback,
			'table-label__container--is-disabled': isDisabled,
			'table-label__container--is-sortable': isSortable,
			'table-label__container--reversed-sorting': reversedSorting,
			'table-label__container--sort-asc': activeSortingState === TableLabelActiveSortingState.Ascending,
			'table-label__container--sort-desc': activeSortingState === TableLabelActiveSortingState.Descending,
		});

		let container;

		if (onClickCallback) {
			container = (
				<a
					className={containerClasses}
					onClick={this._handleLabelClick}
				>
					{this._renderIcon()}
					{this._renderLabel()}
				</a>
			);
		} else {
			container = (
				<span className={containerClasses}>
					{this._renderIcon()}
					{this._renderLabel()}
				</span>
			);
		}

		if (isDisabled && disabledTooltip) {
			container = (
				<Hint popup={disabledTooltip}>
					{container}
				</Hint>
			);
		}

		return (
			<span className={componentClasses}>
				<span className={labelClasses}>
					{this._renderCheckbox()}
					{container}
					{this._renderSuffix()}
				</span>
				{footer && (
					<span className="table-label__footer">
						{footer}
					</span>
				)}
			</span>
		);
	}

}



export default TableLabel;
