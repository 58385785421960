export const alertIdSelector = (state) => state.get('ui').get('content').get('alertId');

export const contentCurrentSelector = (state) => state.get('ui').get('content').get('current');

export const contentStackSelector = (state) => state.get('ui').get('content').get('stack');

export const contextTypeSelector = (state) => state.get('ui').get('content').get('contextType');

export const dateRangePanelScopeSelector = (state) => state.get('ui').get('content').get('usageScope');

export const issuesFilterParameterSelector = (state) => state.get('ui').get('content').get('issuesFilterParameter');

export const issuesOverviewCategorySelector = (state) => state.get('ui').get('content').get('issuesOverviewCategory');

export const issuesOverviewIgnoringScopeSelector = (state) => state.get('ui').get('content').get('issuesOverviewIgnoringScope');

export const issuesOverviewIssueSelector = (state) => state.get('ui').get('content').get('issuesOverviewIssue');

export const openEventSelector = (state) => state.get('ui').get('content').get('openEvent');

export const pageDetailIgnoringScopeSelector = (state) => state.get('ui').get('content').get('pageDetailIgnoringScope');

export const pageDetailIssuesCategoryTypeSelector = (state) => state.get('ui').get('content').get('pageDetailIssuesCategoryType');

export const pageDetailIssueSelector = (state) => state.get('ui').get('content').get('pageDetailIssue');

export const pagesColumnsParameterSelector = (state) => state.get('ui').get('content').get('pagesColumnsParameter');

export const pagesSectionSelector = (state) => state.get('ui').get('content').get('pagesSection');

export const pagesViewParameterSelector = (state) => state.get('ui').get('content').get('pagesViewParameter');

export const platformScreenIgnoringScopeSelector = (state) => state.get('ui').get('content').get('platformIgnoringScope');

export const platformScreenIssuesCategoryTypeSelector = (state) => state.get('ui').get('content').get('platformScreenIssuesCategoryType');

export const platformScreenIssueSelector = (state) => state.get('ui').get('content').get('platformIssue');

export const teamDetailTabSelector = (state) => state.get('ui').get('content').get('teamDetailTab');
