import React from 'react';

import {
	ViewportContext,
} from '~/components/ViewportProvider';

import {
	Viewport,
} from '~/utilities/viewportSize';



function useViewportType() {
	const viewport = React.useContext(ViewportContext);

	return React.useMemo(
		() => {
			return {
				isExtraLarge: viewport.type === Viewport.Xlarge,
				isLarge: viewport.type === Viewport.Large,
				isMedium: viewport.type === Viewport.Medium,
				isSmall: viewport.type === Viewport.Small,
			};
		},
		[viewport.type],
	);
}



export default useViewportType;
