import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import Fraction from '~/components/logic/Fraction';
import Parenthesis from '~/components/logic/Parenthesis';
import StaticText from '~/components/atoms/forms/components/StaticText';



type Props = {
	website: any,
};

const WebsiteAdminUsageOverview: React.FC<Props> = (props) => {
	const {
		website,
	} = props;

	return (
		<EditableFormWrapper
			isForAdmins={true}
			isReadOnly={true}
			title="Usage overview"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Non-pages usage">
						<StaticText>
							<Fraction
								partA={(
									<FormattedNumber value={website.get('nonpages_count')} />
								)}
								partB={(
									<FormattedNumber value={website.get('nonpages_limit')} />
								)}
							/>
							{' '}
							<span className="percentage">
								<Parenthesis
									content={(
										<FormattedNumber
											style="percent"
											value={website.get('nonpages_count') / website.get('nonpages_limit')}
										/>
									)}
								/>
							</span>
						</StaticText>
					</FormRow>

					<FormRow label="Sitemaps usage">
						<StaticText>
							<Fraction
								partA={(
									<FormattedNumber value={website.get('sitemaps_count')} />
								)}
								partB={(
									<FormattedNumber value={website.get('sitemaps_limit')} />
								)}
							/>
							{' '}
							<span className="percentage">
								<Parenthesis
									content={(
										<FormattedNumber
											style="percent"
											value={website.get('sitemaps_count') / website.get('sitemaps_limit')}
										/>
									)}
								/>
							</span>
						</StaticText>
					</FormRow>

					<FormRow label="Total URLs">
						<StaticText>
							<FormattedNumber value={website.get('all_count')} />
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>
		</EditableFormWrapper>
	);
};



export default WebsiteAdminUsageOverview;
