import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import SidebarProfileInfo from '~/components/app/SidebarProfileInfo';

import {
	menu,
} from '~/actions';

import {
	isUserNavigationOpenSelector,
} from '~/state/ui/menu/selectors';



const UserNavigationToggler: React.FC = () => {
	const dispatch = useDispatch();
	const open = useSelector(isUserNavigationOpenSelector);

	const toggleSelect = React.useCallback(
		(e) => {
			e.preventDefault();

			if (open) {
				dispatch(menu.closeUserNavigation());
			} else {
				dispatch(menu.openUserNavigation());
			}
		},
		[
			dispatch,
			open,
		],
	);

	return (
		<SidebarProfileInfo
			onClick={toggleSelect}
		/>
	);
};



export default UserNavigationToggler;
