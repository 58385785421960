import GraphQL from '~/types/graphql';



export const PLAN_HIGHER = 'PLAN_HIGHER';
export const PLAN_LOWER = 'PLAN_LOWER';
export const PLAN_SAME = 'PLAN_SAME';



export function comparePlans(
	plansHierarchy: any,
	previous: any,
	next: any,
) {
	if (previous === next) {
		return PLAN_SAME;
	}

	const previousOrder = plansHierarchy.indexOf(previous);
	const nextOrder = plansHierarchy.indexOf(next);

	return previousOrder > nextOrder ? PLAN_LOWER : PLAN_HIGHER;
}



export function isEnterprisePlan(plan: GraphQL.AccountPlan) {
	return (
		plan === GraphQL.AccountPlan.Enterprise
		|| plan === GraphQL.AccountPlan.EnterpriseLite
		|| plan === GraphQL.AccountPlan.C2Enterprise
	);
}
