import React from 'react';



type Props = {
	button: React.ReactNode,
	field: React.ReactNode,
};

const ExclusionRuleFormLayout: React.FC<Props> = (props) => {
	const {
		button,
		field,
	} = props;

	return (
		<div className="exclusion-rule-form">
			<div className="exclusion-rule-form__field">
				{field}
			</div>
			<div className="exclusion-rule-form__button">
				{button}
			</div>
		</div>
	);
};



export default ExclusionRuleFormLayout;
