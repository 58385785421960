import React from 'react';
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';



export type ModalFactory = ({ closeCallback }: { closeCallback: () => void }) => React.ReactNode;

type ModalContext = {
	closeCallback: () => void,
	closeModal: () => void,
	modal: ModalFactory | null,
	openModal: (modalFactory: ModalFactory) => void,
};



const DynamicModalContext = React.createContext<ModalContext>({
	closeCallback: () => null,
	closeModal: () => null,
	modal: null,
	openModal: () => null,
});



type Props = {
	children?: React.ReactNode,
};

const DynamicModalProvider: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const [modal, setModal] = React.useState<ModalFactory | null>(null);

	const closeCallback = React.useCallback(
		() => {
			setModal(null);
		},
		[
			setModal,
		],
	);

	const openModal = React.useCallback(
		(modalFactory: ModalFactory): void => {
			setModal(() => modalFactory);
		},
		[
			setModal,
		],
	);

	const value = React.useMemo(
		() => ({
			closeCallback,
			closeModal: closeCallback,
			modal,
			openModal,
		}),
		[
			closeCallback,
			modal,
			openModal,
		],
	);

	return (
		<DynamicModalContext.Provider value={value}>
			{children}
		</DynamicModalContext.Provider>
	);
};



const DynamicModalRenderer = () => {
	const modalContext = React.useContext(DynamicModalContext);

	return (
		<TransitionGroup>
			{modalContext.modal ? (
				<CSSTransition
					classNames={{
						exit: 'modal--before-closing',
						exitActive: 'modal--closing',
					}}
					in={true}
					timeout={200}
				>
					{modalContext.modal({
						closeCallback: modalContext.closeCallback,
					})}
				</CSSTransition>
			) : null}
		</TransitionGroup>
	);
};



export default DynamicModalProvider;

export {
	DynamicModalContext,
	DynamicModalRenderer,
};
