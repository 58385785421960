/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageTimeDiscoveredAtQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageTimeDiscoveredAtQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly timeDiscoveredAt: CK.Timestamp | null } | null };


export const PageTimeDiscoveredAtDocument = gql`
    query PageTimeDiscoveredAt($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    timeDiscoveredAt
  }
}
    `;

/**
 * __usePageTimeDiscoveredAtQuery__
 *
 * To run a query within a React component, call `usePageTimeDiscoveredAtQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageTimeDiscoveredAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageTimeDiscoveredAtQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageTimeDiscoveredAtQuery(baseOptions: Apollo.QueryHookOptions<PageTimeDiscoveredAtQuery, PageTimeDiscoveredAtQueryVariables> & ({ variables: PageTimeDiscoveredAtQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageTimeDiscoveredAtQuery, PageTimeDiscoveredAtQueryVariables>(PageTimeDiscoveredAtDocument, options);
      }
export function usePageTimeDiscoveredAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageTimeDiscoveredAtQuery, PageTimeDiscoveredAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageTimeDiscoveredAtQuery, PageTimeDiscoveredAtQueryVariables>(PageTimeDiscoveredAtDocument, options);
        }
export function usePageTimeDiscoveredAtSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageTimeDiscoveredAtQuery, PageTimeDiscoveredAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageTimeDiscoveredAtQuery, PageTimeDiscoveredAtQueryVariables>(PageTimeDiscoveredAtDocument, options);
        }
export type PageTimeDiscoveredAtQueryHookResult = ReturnType<typeof usePageTimeDiscoveredAtQuery>;
export type PageTimeDiscoveredAtLazyQueryHookResult = ReturnType<typeof usePageTimeDiscoveredAtLazyQuery>;
export type PageTimeDiscoveredAtSuspenseQueryHookResult = ReturnType<typeof usePageTimeDiscoveredAtSuspenseQuery>;
export type PageTimeDiscoveredAtQueryResult = Apollo.QueryResult<PageTimeDiscoveredAtQuery, PageTimeDiscoveredAtQueryVariables>;