export const ACTION_WEBSITE_ISSUES_COMPARISON_UPDATE_FILTER = 'ACTION_WEBSITE_ISSUES_COMPARISON_UPDATE_FILTER';



export function updateFilter(filter) {
	return {
		type: ACTION_WEBSITE_ISSUES_COMPARISON_UPDATE_FILTER,
		filter,
	};
}
