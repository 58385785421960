import classNames from 'classnames';
import React from 'react';

import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';



export enum GroupBoxStyle {
	Default = 'default',
	Eminence = 'eminence',
}

export enum GroupBoxSize {
	Small = 'small',
	Default = 'default',
	Large = 'large',
}



type Props = {
	children?: React.ReactNode,
	/** Default state of collapsible box */
	defaultOpen?: boolean,
	/** Make box collapsible */
	isCollapsible?: boolean,
	/** Possibility to set different size of box */
	size?: GroupBoxSize,
	/** Possibility to switch style of box */
	style?: GroupBoxStyle,
	/** Title of whole group */
	title: React.ReactNode,
	/** Possibility to disable title wrapping and enable ellipsis support */
	titleEllipsis?: boolean,
	/** Optional icon before title */
	titleIcon?: React.ReactNode,
};

const GroupBox: React.FC<Props> = (props) => {
	const {
		children,
		defaultOpen = false,
		isCollapsible = false,
		size = GroupBoxSize.Default,
		style = GroupBoxStyle.Default,
		title,
		titleEllipsis = false,
		titleIcon,
	} = props;

	const [isCollapsed, setCollapsed] = React.useState<boolean>(!defaultOpen);

	const handleHeaderClick = (e) => {
		e.preventDefault();

		if (!isCollapsible) {
			return false;
		}

		setCollapsed(!isCollapsed);
	};

	const componentClasses = classNames({
		'group-box': true,
		['group-box--' + size + '-size']: true,
		['group-box--' + style + '-style']: true,
	});

	const contentClasses = classNames({
		'group-box__content': true,
		'group-box__content--is-collapsible': isCollapsible,
		'group-box__content--is-collapsed': isCollapsible && isCollapsed,
	});

	const headerClasses = classNames({
		'group-box__header': true,
		'group-box__header--use-ellipsis': titleEllipsis,
		'group-box__header--is-collapsible': isCollapsible,
		'group-box__header--is-collapsed': isCollapsible && isCollapsed,
	});

	let iconSize = 12;

	if (size === GroupBoxSize.Small) {
		iconSize = 10;
	}

	if (size === GroupBoxSize.Large) {
		iconSize = 14;
	}

	return (
		<div className={componentClasses}>
			<div
				className={headerClasses}
				onClick={handleHeaderClick}
			>
				{titleIcon && (
					<div className="group-box__title-icon">
						{titleIcon}
					</div>
				)}
				<div className="group-box__title">
					{title}
				</div>
				{isCollapsible && (
					<div className="group-box__toggle-icon">
						<DropdownIcon
							isActive={!isCollapsed}
							size={iconSize}
							style={style === GroupBoxStyle.Eminence ? DropdownIconStyle.White : DropdownIconStyle.Greyscale}
						/>
					</div>
				)}
			</div>
			<div className={contentClasses}>
				<div className="group-box__body">
					{children}
				</div>
			</div>
		</div>
	);
};



export default GroupBox;
