import memoize from 'memoizee';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import ActiveIncidentCard from '~/components/logic/alertsScreen/ActiveIncidentCard';
import BlankSlate, {
	BlankSlateStatus,
} from '~/components/patterns/messages/embedded/BlankSlate';
import BlankSlateMessage from '~/components/logic/alertsScreen/BlankSlateMessage';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderTitleWithValue from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import IncidentsOverview from '~/components/patterns/incidents/IncidentsOverview';
import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';
import MarginsList, {
	SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import PastIncidentCard from '~/components/logic/alertsScreen/PastIncidentCard';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenOverlay, {
	ScreenOverlayGapsSize,
	ScreenOverlayStyle,
} from '~/components/patterns/screens/parts/ScreenOverlay';
import ScrollableContentScreen from '~/components/logic/layouts/screens/ScrollableContentScreen';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	loadIncidents,
} from '~/actions/incidents';

import {
	listIncidents,
} from '~/model/alerts';

import {
	currentDataSelector,
} from '~/state/incidents/selectors';

import {
	openEventSelector,
} from '~/state/ui/content/selectors';



const messages = defineMessages({
	activeIncidentsTitle: {
		id: 'ui.alerts.overview.activeIncidentsTitle',
	},
	configureButtonLabel: {
		id: 'ui.alerts.overview.configureAlertsButton',
	},
	noActiveIncidentsPlaceholder: {
		id: 'ui.alerts.overview.noActiveIncidentsPlaceholder',
	},
	recentIncidentsTitle: {
		id: 'ui.alerts.overview.recentIncidentsTitle',
	},
	title: {
		id: 'ui.events.title',
	},
});



const SYNC_INTERVAL = 30000;



const memoizedListIncidents = memoize(listIncidents);



const AlertsScreen: React.FC = () => {
	const dispatch = useDispatch();
	const incidents = useSelector(currentDataSelector);
	const openAlert = useSelector(openEventSelector);
	const websiteId = useWebsiteId();

	const [syncWebsiteId, setSyncWebsiteId] = React.useState<CK.WebsiteId | null>(websiteId);

	React.useEffect(
		() => {
			setSyncWebsiteId(websiteId);
		},
		[
			websiteId,
		],
	);

	React.useEffect(
		() => {
			if (syncWebsiteId === null) {
				const timeout = setTimeout(
					() => {
						setSyncWebsiteId(syncWebsiteId);
					},
					SYNC_INTERVAL,
				);

				return () => {
					clearTimeout(timeout);
				};
			}

			dispatch(
				loadIncidents(),
			).finally(() => {
				setSyncWebsiteId(null);
			});
		},
		[
			dispatch,
			syncWebsiteId,
		],
	);

	const activeIncidents = incidents && memoizedListIncidents(incidents, 'active');
	const pastIncidents = incidents && memoizedListIncidents(incidents, 'past');

	const renderActiveIncidents = () => {
		if (!activeIncidents || !pastIncidents) {
			return false;
		}

		if (activeIncidents.length === 0 && pastIncidents.length === 0) {
			return null;
		}

		return (
			<IncidentsOverview
				placeholder={(
					<BlankSlate status={BlankSlateStatus.Positive}>
						<FormattedMessage {...messages.noActiveIncidentsPlaceholder} />
					</BlankSlate>
				)}
				title={(
					<FormattedMessage {...messages.activeIncidentsTitle} />
				)}
			>
				{activeIncidents.map((incident) => {
					return (
						<ActiveIncidentCard
							incident={incident}
							key={incident.get('id')}
							openAlertId={openAlert && openAlert.get('id')}
							websiteId={websiteId}
						/>
					);
				})}
			</IncidentsOverview>
		);
	};

	const renderOverlay = () => {
		if (!activeIncidents || !pastIncidents) {
			return (
				<ScreenOverlay style={ScreenOverlayStyle.Light}>
					<LoadingDots size={LoadingDotsSize.Large} />
				</ScreenOverlay>
			);
		}

		if (activeIncidents.length === 0 && pastIncidents.length === 0) {
			return (
				<ScreenOverlay
					gapsSize={ScreenOverlayGapsSize.Small}
					style={ScreenOverlayStyle.Light}
				>
					<BlankSlateMessage />
				</ScreenOverlay>
			);
		}

		return false;
	};

	return (
		<ScrollableContentScreen
			contentOverlay={renderOverlay()}
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						<HeaderTitleWithValue
							title={(
								<FormattedMessage {...messages.title} />
							)}
						/>
					</HeaderTitleLayout>

					<HeaderOptionsLayout>
						<HeaderButtonsLayout>
							<Button
								icon={(
									<BasicIcon type={BasicIconType.CogWheel} />
								)}
								linkRouteName="website.alerts.configuration"
								linkRouteParams={{
									websiteId,
								}}
								style={ButtonStyle.Hollow}
							>
								<FormattedMessage {...messages.configureButtonLabel} />
							</Button>
						</HeaderButtonsLayout>
					</HeaderOptionsLayout>
				</ScreenHeader>
			)}
		>
			<MarginsList size={SIZE_LARGE}>
				{renderActiveIncidents()}

				{pastIncidents && pastIncidents.length > 0 && (
					<IncidentsOverview
						title={(
							<FormattedMessage {...messages.recentIncidentsTitle} />
						)}
					>
						{pastIncidents.map((incident) => {
							return (
								<PastIncidentCard
									incident={incident}
									key={incident.get('id')}
									openAlertId={openAlert && openAlert.get('id')}
									websiteId={websiteId}
								/>
							);
						})}
					</IncidentsOverview>
				)}
			</MarginsList>
		</ScrollableContentScreen>
	);
};



export default AlertsScreen;
