import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Backlink from '~/components/logic/links/Backlink';
import ExplainedValue from '~/components/patterns/values/ExplainedValue';
import ExportHeaderButton from '~/components/app/ExportHeaderButton';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleWithNumericValue, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';

import {
	ExportType,
} from '~/model/exports';

import {
	PageIssue,
} from '~/model/issuesNew';



const messages = defineMessages({
	exportButton: {
		id: 'ui.exports.type.general.button.openDialog.label',
	},
	titleTooltipContent: {
		id: 'issues.links.internal.message',
	},
	titleTooltipLabel: {
		id: 'issues.links.internal',
	},
});

const titleMessages = defineMessages({
	[PageIssue.LinksBroken]: {
		id: 'issues.links_4xx.affectedLinksTitle',
	},
	[PageIssue.LinksRedirected]: {
		id: 'issues.links_3xx.affectedLinksTitle',
	},
});



type Props = {
	backlinkCallback: (() => void) | null,
	backlinkLabel: React.ReactNode,
	issueName: PageIssue,
	numberOfAffectedLinks: number | null,
};

const Header: React.FC<Props> = (props) => {
	const {
		backlinkCallback,
		backlinkLabel,
		issueName,
		numberOfAffectedLinks,
	} = props;

	return (
		<ScreenHeader>
			<HeaderTitleLayout>
				{backlinkCallback && backlinkLabel && (
					<Backlink
						callback={backlinkCallback}
						label={backlinkLabel}
					/>
				)}

				<HeaderTitleWithNumericValue
					size={TITLE_SIZE_SMALL}
					title={(
						<FormattedMessage
							{...titleMessages[issueName]}
							values={{
								text__internal: (
									<ExplainedValue
										explanation={(
											<FormattedMessage {...messages.titleTooltipContent} />
										)}
									>
										<FormattedMessage {...messages.titleTooltipLabel} />
									</ExplainedValue>
								),
							}}
						/>
					)}
					value={numberOfAffectedLinks}
				/>
			</HeaderTitleLayout>

			<HeaderOptionsLayout>
				<HeaderButtonsLayout>
					<ExportHeaderButton
						exportType={ExportType.AffectedLinks}
						linkLabel={(
							<FormattedMessage {...messages.exportButton} />
						)}
					/>
				</HeaderButtonsLayout>
			</HeaderOptionsLayout>
		</ScreenHeader>
	);
};



export default React.memo(Header);
