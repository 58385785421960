import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const BingIconBuilder: IconBuilder = {
	icon: () => (
		<g
			fill="none"
			fillRule="evenodd"
			stroke="none"
			strokeWidth="1"
		>
			<g
				fillRule="nonzero"
				id="test"
				transform="translate(3.000000, 3.000000)"
			>
				<polygon
					fill="#F26522"
					id="Path"
					points="8.55527 0 0 0 0 8.55528 8.55527 8.55528"
				/>
				<polygon
					fill="#8DC63F"
					id="Path"
					points="18.0001 0 9.44482 0 9.44482 8.55528 18.0001 8.55528"
				/>
				<polygon
					fill="#00AEEF"
					id="Path"
					points="8.55527 9.44482 0 9.44482 0 18.0001 8.55527 18.0001"
				/>
				<polygon
					fill="#FFC20E"
					id="Path"
					points="18.0001 9.44482 9.44482 9.44482 9.44482 18.0001 18.0001 18.0001"
				/>
			</g>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default BingIconBuilder;

