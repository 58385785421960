import {
	Map,
} from 'immutable';

import {
	basics,
} from './basics/reducer';
import {
	billing,
} from './billing/reducer';
import {
	robotsRules,
} from './robotsRules/reducer';



function createDefaultState() {
	return new Map();
}



export const teams = function (state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	state = state.merge({
		basics: basics(state.get('basics'), action),
	});

	state = state.merge({
		billing: billing(state.get('billing'), action),
	});

	state = state.merge({
		robotsRules: robotsRules(state.get('robotsRules'), action),
	});

	return state;
};
