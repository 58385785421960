import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import IssueCardsList from '~/components/atoms/issues/components/lists/IssueCardsList';
import IssueCategoriesListCard from '~/components/app/IssueCategoriesListCard';
import IssueCategoryCardSkeleton from '~/components/atoms/issues/components/categoryCards/IssueCategoryCardSkeleton';

import {
	isRecognizedIssueCategory,
	sortIssues,
} from '~/model/issuesNew';



const messages = defineMessages({
	emptyListActionable: {
		id: 'ui.issues.noOpenIssues',
	},
	emptyListNotActionable: {
		id: 'ui.issues.noClosedIssues',
	},
});



type Props = {
	issueCategories: ReadonlyArray<{
		isActionable: boolean,
		isIgnored: boolean,
		issues: ReadonlyArray<{
			context: any,
			isActionable: boolean,
			name: string,
			state: GraphQL.IssueState,
		}>,
		name: string,
		pointsGained: number,
		pointsToGain: number,
		state: GraphQL.IssueCategoryState,
	}> | null,
	numberOfPlaceholders: number,
	onIssueCategoryClose: () => void,
	onIssueCategoryOpen: (issueCategoryName: string) => void,
	openedIssueCategoryName: string | null,
	showActionable: boolean,
};

const IssueCategoriesList: React.FC<Props> = (props) => {
	const {
		issueCategories,
		numberOfPlaceholders,
		onIssueCategoryClose,
		onIssueCategoryOpen,
		openedIssueCategoryName,
		showActionable,
	} = props;

	function renderContent() {
		if (issueCategories === null) {
			return [...Array.from({ length: numberOfPlaceholders }).keys()].map(
				(i) => (
					<IssueCategoryCardSkeleton key={i} />
				),
			);
		}

		const filteredIssueCategories = issueCategories
			.filter(isRecognizedIssueCategory)
			.filter((issueCategory) => issueCategory.isActionable === showActionable);

		if (filteredIssueCategories.length === 0) {
			return (
				<BlankSlate>
					<FormattedMessage {...(showActionable ? messages.emptyListActionable : messages.emptyListNotActionable)} />
				</BlankSlate>
			);
		}

		return sortIssues(filteredIssueCategories).map((issueCategory) => (
			<IssueCategoriesListCard
				issueCategory={issueCategory}
				key={issueCategory.name}
				onBlurIssueCallback={onIssueCategoryClose}
				onClickIssueCallback={onIssueCategoryOpen}
				openedIssueCategoryName={openedIssueCategoryName}
			/>
		));
	}

	return (
		<IssueCardsList animations={false}>
			{renderContent()}
		</IssueCardsList>
	);
};



export default IssueCategoriesList;
