import React from 'react';

import type CK from '~/types/contentking';

import useDataLoading from '~/hooks/useDataLoading';
import useFetchFurtherRange from '~/hooks/useFetchFurtherRange';



type Edges<TNode> = ReadonlyArray<{
	cursor: string,
	loadedAt: number,
	node: TNode,
} | null>;



function useGraphQLConnection<TQueryData extends Record<string, any>, TNode>(
	input: {
		batchLimit: number,
		context?: {
			columns?: any,
			filter?: any,
			sortBy?: any,
			websiteId?: CK.WebsiteId | null,
		},
		customIsNotLoaded?: (edges: Edges<TNode>) => boolean,
		data: TQueryData | undefined,
		fallbackTotalCount?: number,
		fetchMore: (input: {
			variables: {
				limit: number,
				offset: number,
			},
		}) => Promise<{
			data: TQueryData,
		}>,
		getter: (data: TQueryData) => ({
			edges: Edges<TNode>,
			totalCount: number,
		} | null),
		pollIntervalInMilliseconds: number,
	},
) {
	const {
		batchLimit,
		context,
		customIsNotLoaded = null,
		data,
		fallbackTotalCount,
		fetchMore,
		getter,
		pollIntervalInMilliseconds,
	} = input;

	const connection = data !== undefined
		? getter(data)
		: null;

	const edges = connection?.edges ?? null;
	const totalCount = connection?.totalCount ?? fallbackTotalCount ?? 0;

	const rowGetter = React.useCallback(
		({ rowIndex }: { rowIndex: number }) => {
			return edges?.[rowIndex]?.node ?? null;
		},
		[
			edges,
		],
	);

	const fetchFurtherRange = useFetchFurtherRange(
		connection,
		batchLimit,
		pollIntervalInMilliseconds,
		fetchMore,
		customIsNotLoaded,
	);

	return {
		fetchFurtherRange: useDataLoading(fetchFurtherRange, Object.values(context ?? {})),
		isLoaded: connection !== null,
		rowGetter,
		totalCount,
	};
}



export default useGraphQLConnection;
