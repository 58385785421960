import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const CustomElementsIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M6.13281 28.1226H25.3828C26.0459 28.1226 26.6817 27.8592 27.1506 27.3903C27.6194 26.9215 27.8828 26.2856 27.8828 25.6226V6.37256C27.8828 5.70952 27.6194 5.07363 27.1506 4.60479C26.6817 4.13595 26.0459 3.87256 25.3828 3.87256H6.13281C5.46977 3.87256 4.83389 4.13595 4.36505 4.60479C3.8962 5.07363 3.63281 5.70952 3.63281 6.37256V25.6226C3.63281 26.2856 3.8962 26.9215 4.36505 27.3903C4.83389 27.8592 5.46977 28.1226 6.13281 28.1226V28.1226Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M15.0875 14.4424L24.5625 14.4424V7.24488L15.0875 7.24488V14.4424Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M6.93289 14.4424H11.8379V7.24488L6.93289 7.24488L6.93289 14.4424Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M16.4047 17.7175H6.92969V24.915H16.4047V17.7175Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M24.5632 17.7175H19.6582V24.915H24.5632V17.7175Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default CustomElementsIconBuilder;

