/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageScreenshotUrlQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageScreenshotUrlQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly screenshotUrl: string } | null } | null };


export const PageScreenshotUrlDocument = gql`
    query PageScreenshotUrl($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageTypeData {
      screenshotUrl
    }
  }
}
    `;

/**
 * __usePageScreenshotUrlQuery__
 *
 * To run a query within a React component, call `usePageScreenshotUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageScreenshotUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageScreenshotUrlQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageScreenshotUrlQuery(baseOptions: Apollo.QueryHookOptions<PageScreenshotUrlQuery, PageScreenshotUrlQueryVariables> & ({ variables: PageScreenshotUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageScreenshotUrlQuery, PageScreenshotUrlQueryVariables>(PageScreenshotUrlDocument, options);
      }
export function usePageScreenshotUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageScreenshotUrlQuery, PageScreenshotUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageScreenshotUrlQuery, PageScreenshotUrlQueryVariables>(PageScreenshotUrlDocument, options);
        }
export function usePageScreenshotUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageScreenshotUrlQuery, PageScreenshotUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageScreenshotUrlQuery, PageScreenshotUrlQueryVariables>(PageScreenshotUrlDocument, options);
        }
export type PageScreenshotUrlQueryHookResult = ReturnType<typeof usePageScreenshotUrlQuery>;
export type PageScreenshotUrlLazyQueryHookResult = ReturnType<typeof usePageScreenshotUrlLazyQuery>;
export type PageScreenshotUrlSuspenseQueryHookResult = ReturnType<typeof usePageScreenshotUrlSuspenseQuery>;
export type PageScreenshotUrlQueryResult = Apollo.QueryResult<PageScreenshotUrlQuery, PageScreenshotUrlQueryVariables>;