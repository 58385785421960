/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetPasswordSessionQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type ResetPasswordSessionQuery = { readonly __typename?: 'Query', readonly resetPasswordSession: { readonly __typename?: 'ResetPasswordSession', readonly isTwoFactorAuthenticationEnabled: boolean, readonly isTwoFactorAuthenticationVerified: boolean } | null };


export const ResetPasswordSessionDocument = gql`
    query ResetPasswordSession {
  resetPasswordSession {
    isTwoFactorAuthenticationEnabled
    isTwoFactorAuthenticationVerified
  }
}
    `;

/**
 * __useResetPasswordSessionQuery__
 *
 * To run a query within a React component, call `useResetPasswordSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResetPasswordSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useResetPasswordSessionQuery(baseOptions?: Apollo.QueryHookOptions<ResetPasswordSessionQuery, ResetPasswordSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResetPasswordSessionQuery, ResetPasswordSessionQueryVariables>(ResetPasswordSessionDocument, options);
      }
export function useResetPasswordSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResetPasswordSessionQuery, ResetPasswordSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResetPasswordSessionQuery, ResetPasswordSessionQueryVariables>(ResetPasswordSessionDocument, options);
        }
export function useResetPasswordSessionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ResetPasswordSessionQuery, ResetPasswordSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ResetPasswordSessionQuery, ResetPasswordSessionQueryVariables>(ResetPasswordSessionDocument, options);
        }
export type ResetPasswordSessionQueryHookResult = ReturnType<typeof useResetPasswordSessionQuery>;
export type ResetPasswordSessionLazyQueryHookResult = ReturnType<typeof useResetPasswordSessionLazyQuery>;
export type ResetPasswordSessionSuspenseQueryHookResult = ReturnType<typeof useResetPasswordSessionSuspenseQuery>;
export type ResetPasswordSessionQueryResult = Apollo.QueryResult<ResetPasswordSessionQuery, ResetPasswordSessionQueryVariables>;