import {
	parseISO,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';

import {
	usePageLastCheckedAtQuery,
} from './usePageLastCheckedAt.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageLastCheckedAt(
	legacyUrlId: number | null,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageLastCheckedAtQuery,
		legacyUrlId,
		websiteId,
	);

	const lastCheckedAt = data?.lookupPageByLegacyId?.lastCheckedAt ?? null;

	return React.useMemo(
		() => {
			if (lastCheckedAt === null) {
				return null;
			}

			return parseISO(lastCheckedAt);
		},
		[
			lastCheckedAt,
		],
	);
}



export default usePageLastCheckedAt;
