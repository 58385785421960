import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';



const messages = defineMessages({
	password: {
		id: 'ui.httpAuthFields.password',
	},
	username: {
		id: 'ui.httpAuthFields.username',
	},
});



const HttpAuthFields: React.FC = () => {
	return (
		<FormRows>
			<FormRow
				label={(
					<FormattedMessage {...messages.username} />
				)}
			>
				<FieldStatus name="validateHttpAuthUsername">
					<TextField
						name="httpAuthUsername"
						trimValue={true}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.password} />
				)}
			>
				<FieldStatus name="validateHttpAuthPassword">
					<TextField
						name="httpAuthPassword"
						type={TextFieldType.Password}
					/>
				</FieldStatus>
			</FormRow>
		</FormRows>
	);
};



export default HttpAuthFields;



export const validateHttpAuthUsername = validateField(
	'httpAuthUsername',
	(f) => ([
		f.validateNonEmpty(),
	]),
);



export const validateHttpAuthPassword = validateField(
	'httpAuthPassword',
	(f) => ([
		f.validateNonEmpty(),
	]),
);
