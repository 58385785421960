import {
	cache,
} from '~/apollo';



function useApolloCache() {
	return cache;
}



export default useApolloCache;
