class Tariff {

	constructor({ accountTypes, billingCycle }) {
		this.accountTypes = accountTypes;
		this.billingCycle = billingCycle;
	}



	choosePlan({ accountType, billingCycle, details }) {
		return this.accountTypes[accountType].choosePlan({
			billingCycle,
			details,
		});
	}



	convertDetailsToAddons({ accountType, details }) {
		return this.accountTypes[accountType].convertDetailsToAddons(details);
	}



	convertPagesToAddons({ accountType, numberOfPages }) {
		return this.accountTypes[accountType].convertPagesToAddons(numberOfPages);
	}



	normalizePageCapacity({ accountType, pageCapacity }) {
		return this.accountTypes[accountType].normalizePageCapacity(
			Math.max(1, pageCapacity),
		);
	}



	normalizePagesAmount({ accountType, numberOfPages }) {
		return this.accountTypes[accountType].normalizePagesAmount(numberOfPages);
	}

}



export function createTariff({
	accountTypes,
	billingCycle,
}) {
	return new Tariff({
		accountTypes,
		billingCycle,
	});
}
