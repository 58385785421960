/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisableSubscriptionRenewalMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type DisableSubscriptionRenewalMutation = { readonly __typename?: 'Mutation', readonly DisableSubscriptionRenewal: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isSubscriptionRenewalEnabled: boolean } | null } } };


export const DisableSubscriptionRenewalDocument = gql`
    mutation DisableSubscriptionRenewal($accountId: AccountId!) {
  DisableSubscriptionRenewal(accountId: $accountId) {
    query {
      account(id: $accountId) {
        id
        isSubscriptionRenewalEnabled
      }
    }
  }
}
    `;
export type DisableSubscriptionRenewalMutationFn = Apollo.MutationFunction<DisableSubscriptionRenewalMutation, DisableSubscriptionRenewalMutationVariables>;

/**
 * __useDisableSubscriptionRenewalMutation__
 *
 * To run a mutation, you first call `useDisableSubscriptionRenewalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableSubscriptionRenewalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableSubscriptionRenewalMutation, { data, loading, error }] = useDisableSubscriptionRenewalMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDisableSubscriptionRenewalMutation(baseOptions?: Apollo.MutationHookOptions<DisableSubscriptionRenewalMutation, DisableSubscriptionRenewalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableSubscriptionRenewalMutation, DisableSubscriptionRenewalMutationVariables>(DisableSubscriptionRenewalDocument, options);
      }
export type DisableSubscriptionRenewalMutationHookResult = ReturnType<typeof useDisableSubscriptionRenewalMutation>;
export type DisableSubscriptionRenewalMutationResult = Apollo.MutationResult<DisableSubscriptionRenewalMutation>;
export type DisableSubscriptionRenewalMutationOptions = Apollo.BaseMutationOptions<DisableSubscriptionRenewalMutation, DisableSubscriptionRenewalMutationVariables>;