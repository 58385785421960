import GraphQL from '~/types/graphql';

import useAccountId from '~/hooks/useAccountId';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountType from '~/hooks/useAccountType';



function useAccountPotentialMaximumCustomElementsLimit(): number | null {
	const accountId = useAccountId();

	const accountTariff = useAccountTariff(accountId);
	const accountType = useAccountType(accountId);

	if (
		accountTariff === null
		|| accountType === null
	) {
		return null;
	}

	if (accountTariff === 'v3') {
		if (accountType === GraphQL.AccountType.EndUser) {
			return 2;
		}

		return 5;
	}

	if (accountType === GraphQL.AccountType.EndUser) {
		return 5;
	}

	return 10;
}



export default useAccountPotentialMaximumCustomElementsLimit;
