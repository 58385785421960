import type CK from '~/types/contentking';

import {
	useAccountPaymentMethodQuery,
} from './useAccountPaymentMethod.gql';



function useAccountPaymentMethod(accountId: CK.AccountId | null) {
	const { data } = useAccountPaymentMethodQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	return data?.account?.paymentMethod ?? null;
}



export default useAccountPaymentMethod;
