import PropTypes from 'prop-types';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import PaymentMethodLabel, {
	PaymentMethodLabelStyle,
} from '~/components/patterns/forms/fieldParts/PaymentMethodLabel';
import RadioTagsList from '~/components/patterns/forms/fields/RadioTagsList';

import useFormContext from '~/hooks/useFormContext';

import {
	METHOD_CARD,
	METHOD_INVOICE,
	METHOD_PAYPAL,
} from '~/model/payments';



const messages = defineMessages({
	[METHOD_CARD]: {
		id: 'ui.newTeam.form.creditCard',
		defaultMessage: 'Credit Card',
	},
	[METHOD_INVOICE]: {
		id: 'ui.newTeam.form.invoice',
		defaultMessage: 'Pay by invoice',
	},
	[METHOD_PAYPAL]: {
		id: 'ui.newTeam.form.paypal',
		defaultMessage: 'PayPal',
	},
});

const STYLES = {
	[METHOD_CARD]: PaymentMethodLabelStyle.TextLabel,
	[METHOD_INVOICE]: PaymentMethodLabelStyle.TextLabel,
	[METHOD_PAYPAL]: PaymentMethodLabelStyle.PayPal,
};



const PaymentTypeList = (props) => {
	const {
		name,
		supportedTypes,
	} = props;

	const formContext = useFormContext();

	const isDisabled = formContext.isSubmitting;

	const items = supportedTypes.map((type) => {
		return {
			disabled: isDisabled,
			label: (
				<PaymentMethodLabel
					isDisabled={isDisabled}
					style={STYLES[type]}
				>
					<FormattedMessage {...messages[type]} />
				</PaymentMethodLabel>
			),
			value: type,
		};
	});

	return (
		<RadioTagsList
			itemMaxWidth={170}
			items={items}
			name={name}
			onChangeCallback={formContext.validate}
		/>
	);
};

PaymentTypeList.defaultProps = {
	supportedTypes: [
		METHOD_CARD,
		METHOD_INVOICE,
		METHOD_PAYPAL,
	],
};

PaymentTypeList.propTypes = {
	name: PropTypes.string.isRequired,
	supportedTypes: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(
			PropTypes.oneOf([
				METHOD_CARD,
				METHOD_INVOICE,
				METHOD_PAYPAL,
			]),
		),
	]),
};



export default PaymentTypeList;
