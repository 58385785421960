import React from 'react';

import CancelButton from '~/components/app/CancelButton';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import GlobalFormMessage from '~/components/atoms/forms/basis/GlobalFormMessage';
import ModalButtonsLayout from '~/components/patterns/modals/parts/ModalButtonsLayout';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useImportUserIntoAccountMutation,
} from '~/components/app/ImportUserModal.gql';

import useAccountId from '~/hooks/useAccountId';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useAccountDisplayName from '~/hooks/useAccountDisplayName';



const validations = {
	validateEmail: validateField(
		'email',
		(f) => ([
			f.validateNonEmpty(),
			f.validateEmail(),
		]),
	),
	validateSourceAccountId: validateField(
		'sourceAccountId',
		(f) => ([
			f.validateNonEmpty(),
			f.validateInteger(),
		]),
	),
};



const ImportUserModal: React.FC = () => {
	const classicFormBehavior = useClassicFormBehavior();
	const targetAccountId = useAccountId();
	const targetAccountName = useAccountDisplayName(targetAccountId);

	const [importUserIntoAccount] = useImportUserIntoAccountMutation();

	async function handleSubmit(values) {
		if (targetAccountId === null) {
			return;
		}

		await importUserIntoAccount({
			variables: {
				changeMainAccount: true,
				email: values.email,
				sourceAccountId: parseInt(values.sourceAccountId, 10),
				targetAccountId,
			},
		});

		classicFormBehavior.finish();
	}

	return (
		<SimpleModal
			size={SimpleModalSize.Small}
			title="Import user"
		>
			<Form
				defaultFocus="email"
				defaultValues={{
					email: '',
					sourceAccountId: '',
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRows>
					<FormRow
						label="User email"
					>
						<FieldStatus name="validateEmail">
							<TextField name="email" />
						</FieldStatus>
					</FormRow>

					<FormRow
						description={(
							<span>The ID of the account you want to import the user <em>from</em>.</span>
						)}
						label="From account"
					>
						<FieldStatus name="validateSourceAccountId">
							<TextField name="sourceAccountId" />
						</FieldStatus>
					</FormRow>

					<hr />

					<FormRow label="Into account">
						<StaticText>
							{targetAccountName} ({targetAccountId})
						</StaticText>
					</FormRow>

				</FormRows>

				<GlobalFormMessage>
					{({ globalError }) => globalError.getMessage()}
				</GlobalFormMessage>

				<ModalButtonsLayout>
					<CancelButton />

					<SubmitButton>
						Import user
					</SubmitButton>
				</ModalButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default ImportUserModal;
