import type CK from '~/types/contentking';

import {
	useAccountIsNumberOfEmployeesEnoughForOutOfBandQuery,
} from './useAccountIsNumberOfEmployeesEnoughForOutOfBand.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsNumberOfEmployeesEnoughForOutOfBand(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountIsNumberOfEmployeesEnoughForOutOfBandQuery,
		accountId,
	);

	return data?.account?.isNumberOfEmployeesEnoughForOutOfBand ?? null;
}



export default useAccountIsNumberOfEmployeesEnoughForOutOfBand;
