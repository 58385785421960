import memoize from 'memoizee';

import {
	type PermissionsQuery,
	type UserWebsiteAccessPermissionsQuery,
	usePermissionsQuery,
	useUserWebsiteAccessPermissionsQuery,
} from './usePermission.gql';

import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import usePollInterval from '~/hooks/usePollInterval';

import {
	type Action,
	ObjectType,
	isUserAllowed,
} from '~/model/permissions';



type MainAccountMemberships = Exclude<PermissionsQuery['authenticatedSession'], null>['accounts'][number]['memberships'] | undefined;
type AccountMemberships = Exclude<UserWebsiteAccessPermissionsQuery['authenticatedSession'], null>['accounts'][number]['memberships'] | undefined;
type AccountMembershipWebsiteAccess = Exclude<AccountMemberships, undefined>[number]['websiteAccess'] | undefined;
type AccountWebsites = Exclude<UserWebsiteAccessPermissionsQuery['authenticatedSession'], null>['accounts'][number]['websites'] | undefined;
type WebsiteUserAccess = Exclude<AccountWebsites, undefined>[number]['userAccess'] | undefined;

const format = memoize(
	(showAsKingdomAdmin: boolean, mainData: PermissionsQuery | undefined, userWebsiteAccessData: UserWebsiteAccessPermissionsQuery | undefined) => {
		const managedActions = {
			[ObjectType.Account]: {},
			[ObjectType.AccountMembership]: {},
			[ObjectType.Invitation]: {},
			[ObjectType.Platform]: {},
			[ObjectType.User]: {},
			[ObjectType.UserWebsiteAccess]: {},
			[ObjectType.Website]: {},
		};

		const managedActionsForKingdomAdmin = {
			[ObjectType.Account]: {},
			[ObjectType.AccountMembership]: {},
			[ObjectType.Invitation]: {},
			[ObjectType.Platform]: {},
			[ObjectType.User]: {},
			[ObjectType.UserWebsiteAccess]: {},
			[ObjectType.Website]: {},
		};

		const permissions = {
			[ObjectType.Account]: {},
			[ObjectType.AccountMembership]: {},
			[ObjectType.Invitation]: {},
			[ObjectType.Platform]: {},
			[ObjectType.User]: {},
			[ObjectType.UserWebsiteAccess]: {},
			[ObjectType.Website]: {},
		};

		const permissionsForKingdomAdmin = {
			[ObjectType.Account]: {},
			[ObjectType.AccountMembership]: {},
			[ObjectType.Invitation]: {},
			[ObjectType.Platform]: {},
			[ObjectType.User]: {},
			[ObjectType.UserWebsiteAccess]: {},
			[ObjectType.Website]: {},
		};

		for (const account of mainData?.authenticatedSession?.accounts ?? []) {
			managedActions[ObjectType.Account][account.id] = account.managedActions;
			managedActionsForKingdomAdmin[ObjectType.Account][account.id] = account.managedActionsForKingdomAdmin;
			permissions[ObjectType.Account][account.id] = account.permittedActions;
			permissionsForKingdomAdmin[ObjectType.Account][account.id] = account.permittedActionsForKingdomAdmin;

			for (const invitation of account.invitations) {
				managedActions[ObjectType.Invitation][invitation.id] = invitation.managedActions;
				managedActionsForKingdomAdmin[ObjectType.Invitation][invitation.id] = invitation.managedActionsForKingdomAdmin;
				permissions[ObjectType.Invitation][invitation.id] = invitation.permittedActions;
				permissionsForKingdomAdmin[ObjectType.Invitation][invitation.id] = invitation.permittedActionsForKingdomAdmin;
			}

			for (const membership of (account.memberships as MainAccountMemberships) ?? []) {
				const membershipId = `${membership.account.id}/${membership.user.legacyId}`;

				managedActions[ObjectType.AccountMembership][membershipId] = membership.managedActions;
				managedActionsForKingdomAdmin[ObjectType.AccountMembership][membershipId] = membership.managedActionsForKingdomAdmin;
				permissions[ObjectType.AccountMembership][membershipId] = membership.permittedActions;
				permissionsForKingdomAdmin[ObjectType.AccountMembership][membershipId] = membership.permittedActionsForKingdomAdmin;

				managedActions[ObjectType.User][membership.user.legacyId] = membership.user.managedActions;
				managedActionsForKingdomAdmin[ObjectType.User][membership.user.legacyId] = membership.user.managedActionsForKingdomAdmin;
				permissions[ObjectType.User][membership.user.legacyId] = membership.user.permittedActions;
				permissionsForKingdomAdmin[ObjectType.User][membership.user.legacyId] = membership.user.permittedActionsForKingdomAdmin;
			}

			for (const website of account.websites) {
				managedActions[ObjectType.Website][website.id] = website.managedActions;
				managedActionsForKingdomAdmin[ObjectType.Website][website.id] = website.managedActionsForKingdomAdmin;
				permissions[ObjectType.Website][website.id] = website.permittedActions;
				permissionsForKingdomAdmin[ObjectType.Website][website.id] = website.permittedActionsForKingdomAdmin;
			}
		}

		for (const account of userWebsiteAccessData?.authenticatedSession?.accounts ?? []) {
			for (const membership of (account.memberships as AccountMemberships) ?? []) {
				for (const userWebsiteAccess of (membership.websiteAccess as AccountMembershipWebsiteAccess) ?? []) {
					managedActions[ObjectType.UserWebsiteAccess][userWebsiteAccess.id] = userWebsiteAccess.managedActions;
					managedActionsForKingdomAdmin[ObjectType.UserWebsiteAccess][userWebsiteAccess.id] = userWebsiteAccess.managedActionsForKingdomAdmin;
					permissions[ObjectType.UserWebsiteAccess][userWebsiteAccess.id] = userWebsiteAccess.permittedActions;
					permissionsForKingdomAdmin[ObjectType.UserWebsiteAccess][userWebsiteAccess.id] = userWebsiteAccess.permittedActionsForKingdomAdmin;

					// support by email
					const compatibleId = `${userWebsiteAccess.website.id}/compat/${membership.user.legacyId}`;

					managedActions[ObjectType.UserWebsiteAccess][compatibleId] = userWebsiteAccess.managedActions;
					managedActionsForKingdomAdmin[ObjectType.UserWebsiteAccess][compatibleId] = userWebsiteAccess.managedActionsForKingdomAdmin;
					permissions[ObjectType.UserWebsiteAccess][compatibleId] = userWebsiteAccess.permittedActions;
					permissionsForKingdomAdmin[ObjectType.UserWebsiteAccess][compatibleId] = userWebsiteAccess.permittedActionsForKingdomAdmin;
				}
			}

			for (const website of (account.websites as AccountWebsites) ?? []) {
				for (const userWebsiteAccess of (website.userAccess as WebsiteUserAccess) ?? []) {
					if (managedActions[ObjectType.UserWebsiteAccess][userWebsiteAccess.id] !== undefined) {
						continue;
					}

					managedActions[ObjectType.UserWebsiteAccess][userWebsiteAccess.id] = userWebsiteAccess.managedActions;
					managedActionsForKingdomAdmin[ObjectType.UserWebsiteAccess][userWebsiteAccess.id] = userWebsiteAccess.managedActionsForKingdomAdmin;
					permissions[ObjectType.UserWebsiteAccess][userWebsiteAccess.id] = userWebsiteAccess.permittedActions;
					permissionsForKingdomAdmin[ObjectType.UserWebsiteAccess][userWebsiteAccess.id] = userWebsiteAccess.permittedActionsForKingdomAdmin;

					// support by email
					const compatibleId = `${website.id}/compat/${userWebsiteAccess.user.legacyId}`;

					managedActions[ObjectType.UserWebsiteAccess][compatibleId] = userWebsiteAccess.managedActions;
					managedActionsForKingdomAdmin[ObjectType.UserWebsiteAccess][compatibleId] = userWebsiteAccess.managedActionsForKingdomAdmin;
					permissions[ObjectType.UserWebsiteAccess][compatibleId] = userWebsiteAccess.permittedActions;
					permissionsForKingdomAdmin[ObjectType.UserWebsiteAccess][compatibleId] = userWebsiteAccess.permittedActionsForKingdomAdmin;
				}
			}
		}

		managedActions[ObjectType.Platform]['platform'] = [];
		managedActionsForKingdomAdmin[ObjectType.Platform]['platform'] = [];
		permissions[ObjectType.Platform]['platform'] = [];
		permissionsForKingdomAdmin[ObjectType.Platform]['platform'] = mainData?.platformPermittedActionsForKingdomAdmin ?? undefined;

		const effectiveManagedActions = showAsKingdomAdmin
			? managedActionsForKingdomAdmin
			: managedActions;

		return (parameters: {
			action: Action,
			objectId: number | string | undefined | null,
			objectType: ObjectType,
		}): ReturnType<typeof isUserAllowed> => isUserAllowed({
			...parameters,
			managedActions: effectiveManagedActions,
			permissions,
			permissionsForKingdomAdmin,
		});
	},
);



function usePermission() {
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const { data: mainData } = usePermissionsQuery({
		pollInterval: usePollInterval(30_000),
	});

	const { data: userWebsiteAccessData } = useUserWebsiteAccessPermissionsQuery({
		fetchPolicy: 'cache-only',
		returnPartialData: true,
	});

	return format(
		kingdomAdminFeatures.areVisible,
		mainData,
		userWebsiteAccessData,
	);
}



export default usePermission;
