import Immutable from 'immutable';
import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import useLoadTrackedChangesOnPages from '~/hooks/useLoadTrackedChangesOnPages';

import {
	UPDATE_SORT_BY,
} from '~/actions/historicalChanges';



function useUpdateTrackedChangesSortBy() {
	const dispatch = useDispatch();
	const loadTrackedChangesOnPages = useLoadTrackedChangesOnPages();

	return React.useCallback(
		(sortBy) => {
			dispatch({
				type: UPDATE_SORT_BY,
				sortBy: Immutable.fromJS(sortBy),
			});

			loadTrackedChangesOnPages(0, true);
		},
		[
			dispatch,
			loadTrackedChangesOnPages,
		],
	);
}



export default useUpdateTrackedChangesSortBy;
