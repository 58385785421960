import React from 'react';

import SwitchButtons, {
	SwitchButtonsSize,
} from '~/components/patterns/buttons/SwitchButtons';

import useFormContext from '~/hooks/useFormContext';



type Props = {
	items: ReadonlyArray<{
		disabled?: boolean,
		label: React.ReactNode,
		tooltip?: React.ReactNode,
		value: string,
	}>,
	name: string,
	size?: SwitchButtonsSize,
	width?: number,
};

const SwitchButtonsField = React.forwardRef<any, Props>((props, ref) => {
	const {
		items,
		name,
		size = SwitchButtonsSize.Default,
		width,
	} = props;

	const formContext = useFormContext();

	const formContextOnMountHandler = formContext.onMountHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const [selectedItem, setSelectedItem] = React.useState(
		() => {
			let selectedItem = 0;

			items.forEach((item, i) => {
				if (formContext.defaultValues[name] === item.value) {
					selectedItem = i;
				}
			});

			return selectedItem;
		},
	);

	React.useEffect(
		() => {
			formContextOnMountHandler(
				name,
				{
					interacted: true,
				},
			);

			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			name,
		],
	);

	React.useImperativeHandle(ref, () => ({
		setValue: (value) => {
			let selectedItem = 0;

			items.forEach((item, i) => {
				if (value === item.value) {
					selectedItem = i;
				}
			});

			setSelectedItem(selectedItem);
		},
	}));

	const buttons = items.map((item, i) => ({
		disabledTooltip: item.tooltip,
		isActive: selectedItem === i,
		isDisabled: formContext.isDisabled || item.disabled || false,
		label: item.label,
		onClickCallback: () => {
			formContext.onChangeHandler(name, item.value);

			setSelectedItem(i);
		},
	}));

	return (
		<SwitchButtons
			buttons={buttons}
			fullwidth={true}
			size={size}
			width={width}
		/>
	);
});



export default SwitchButtonsField;

export {
	SwitchButtonsSize as SwitchButtonsFieldSize,
};
