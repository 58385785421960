import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountType from '~/hooks/useAccountType';
import useAllowedPlans from '~/hooks/useAllowedPlans';

import {
	LIST_OF_ALL_PLANS,
	isFeatureAvailable,
} from '~/model/universal';



function useFindRequiredPlan(accountId: CK.AccountId | null) {
	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountType = useAccountType(accountId);
	const allowedPlans = useAllowedPlans(accountId);

	return React.useCallback(
		(featureName) => {
			if (
				accountFeaturesAvailability === null
				|| allowedPlans === null
				|| accountTariff === null
			) {
				return null;
			}

			if (accountType !== GraphQL.AccountType.Universal) {
				return null;
			}

			for (const plan of LIST_OF_ALL_PLANS[accountTariff].filter((plan) => allowedPlans.includes(plan)).toArray()) {
				if (isFeatureAvailable(accountFeaturesAvailability, accountTariff, plan, featureName)) {
					if (plan !== accountPlan) {
						return plan;
					}
				}
			}

			return null;
		},
		[
			accountFeaturesAvailability,
			accountPlan,
			accountTariff,
			accountType,
			allowedPlans,
		],
	);
}



export default useFindRequiredPlan;
