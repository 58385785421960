/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAlertDefinitionMutationVariables = GraphQL.Exact<{
  alertDefinitionId: GraphQL.Scalars['AlertDefinitionId']['input'];
  data: GraphQL.Scalars['Array']['input'];
  messagingAppChannelIds: ReadonlyArray<GraphQL.Scalars['ID']['input']> | GraphQL.Scalars['ID']['input'];
  recipients: GraphQL.Scalars['Array']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateAlertDefinitionMutation = { readonly __typename?: 'Mutation', readonly UpdateAlertDefinition: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly alertDefinitions: ReadonlyArray<{ readonly __typename?: 'AlertDefinition', readonly alertType: string, readonly id: CK.AlertDefinitionId, readonly recipients: ReadonlyArray<string>, readonly scope: string, readonly settings: Record<string, any>, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType }> }>, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null } } };


export const UpdateAlertDefinitionDocument = gql`
    mutation UpdateAlertDefinition($alertDefinitionId: AlertDefinitionId!, $data: Array!, $messagingAppChannelIds: [ID!]!, $recipients: Array!, $websiteId: WebsiteId!) {
  UpdateAlertDefinition(
    alertDefinitionId: $alertDefinitionId
    data: $data
    messagingAppChannelIds: $messagingAppChannelIds
    recipients: $recipients
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        alertDefinitions {
          alertType
          id
          messagingAppChannels {
            id
            label
            messagingAppType
          }
          recipients
          scope
          settings
        }
        id
        integrations {
          isNotOwned
          status
          type
        }
      }
    }
  }
}
    `;
export type UpdateAlertDefinitionMutationFn = Apollo.MutationFunction<UpdateAlertDefinitionMutation, UpdateAlertDefinitionMutationVariables>;

/**
 * __useUpdateAlertDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateAlertDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertDefinitionMutation, { data, loading, error }] = useUpdateAlertDefinitionMutation({
 *   variables: {
 *      alertDefinitionId: // value for 'alertDefinitionId'
 *      data: // value for 'data'
 *      messagingAppChannelIds: // value for 'messagingAppChannelIds'
 *      recipients: // value for 'recipients'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateAlertDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAlertDefinitionMutation, UpdateAlertDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAlertDefinitionMutation, UpdateAlertDefinitionMutationVariables>(UpdateAlertDefinitionDocument, options);
      }
export type UpdateAlertDefinitionMutationHookResult = ReturnType<typeof useUpdateAlertDefinitionMutation>;
export type UpdateAlertDefinitionMutationResult = Apollo.MutationResult<UpdateAlertDefinitionMutation>;
export type UpdateAlertDefinitionMutationOptions = Apollo.BaseMutationOptions<UpdateAlertDefinitionMutation, UpdateAlertDefinitionMutationVariables>;