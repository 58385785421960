import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const GraphsIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g
					id="statistics1"
					stroke={color}
					transform="translate(4.000000, 4.000000)"
				>
					<polygon
						id="Stroke-202"
						points="15.5 15.5 0.5 15.5 0.5 0.5 15.5 0.5"
					>
					</polygon>
					<path
						d="M4.30769231,6.50045 L4.30769231,12.6153846"
						id="Stroke-203"
					>
					</path>
					<path
						d="M8,4.2491 L8,12.6153846"
						id="Stroke-204"
					>
					</path>
					<path
						d="M11.6923077,8.7518 L11.6923077,12.6153846"
						id="Stroke-205"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default GraphsIconBuilder;

