import classNames from 'classnames';
import React from 'react';

import {
	EditableFormWrapperContext,
} from '~/components/atoms/forms/basis/EditableFormWrapperBase';



type Props = {
	children?: React.ReactNode,
	focusTarget: string,
};



const TextTarget: React.FC<Props> = (props) => {
	const {
		children,
		focusTarget,
	} = props;

	const editableFormWrapperContext = React.useContext(EditableFormWrapperContext);

	const isEditable = editableFormWrapperContext?.isEditable ?? false;
	const openEditModeHandler = editableFormWrapperContext?.openEditModeHandler ?? null;

	const handleTextClick = React.useCallback(
		(e) => {
			if (openEditModeHandler === null) {
				return false;
			}

			e.preventDefault();
			e.stopPropagation();

			if (window.getSelection()?.toString().length === 0) {
				openEditModeHandler(focusTarget);
			}
		},
		[
			focusTarget,
			openEditModeHandler,
		],
	);

	const componentClasses = classNames({
		'form-text-target': true,
		'form-text-target--clickable': isEditable,
	});

	return (
		<span
			className={componentClasses}
			onClick={isEditable ? handleTextClick : undefined}
		>
			{children}
		</span>
	);
};



export default TextTarget;
