import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import UrlBlocklistForm from '~/components/app/UrlBlocklistForm';

import {
	useUpdateWebsiteOnPageRequestsBlockingRulesetMutation,
} from './OnPageRequestBlockingModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useOnPageRequestBlocking from '~/hooks/useOnPageRequestBlocking';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	allDeniedWarning: {
		id: 'ui.onPageRequestBlockingModal.allDeniedWarning',
	},
	description: {
		id: 'ui.onPageRequestBlockingModal.description',
	},
	title: {
		id: 'ui.onPageRequestBlockingModal.title',
	},
	sidebar: {
		id: 'ui.onPageRequestBlockingModal.sidebar',
	},
});



const OnPageRequestBlockingModal: React.FC = () => {
	const websiteId = useWebsiteId();
	const classicFormBehavior = useClassicFormBehavior();
	const onPageRequestBlocking = useOnPageRequestBlocking(websiteId);

	const [
		updateWebsiteOnPageRequestsBlockingRuleset,
	] = useUpdateWebsiteOnPageRequestsBlockingRulesetMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteOnPageRequestsBlockingRuleset({
				variables: {
					mode: values.mode,
					rules: values.rules,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			updateWebsiteOnPageRequestsBlockingRuleset,
			websiteId,
		],
	);

	return (
		<SimpleModal
			alignToTop={true}
			iconType={BasicIconType.CogWheel}
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							linkArticle: linkExternal('https://www.contentkingapp.com/support/javascript-rendering/#which-requests-to-block'),
						}}
					/>
				</RichText>
			)}
			size={SimpleModalSize.XXLarge}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ModalTextSection>
				<Copy {...messages.description} />
			</ModalTextSection>

			{onPageRequestBlocking && (
				<UrlBlocklistForm
					__UGLY__transformEnumsToCamelCase={true}
					allDeniedWarning={(
						<FormattedMessage {...messages.allDeniedWarning} />
					)}
					defaultValues={{
						mode: onPageRequestBlocking.mode,
						rules: onPageRequestBlocking.rules,
					}}
					maxRules={8}
					onSubmit={handleSubmit}
				/>
			)}
		</SimpleModal>
	);
};



export default OnPageRequestBlockingModal;
