import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useAdministerAccountEmailSettingsMutation,
	useEmailSettingsFormQuery,
} from './EmailSettingsForm.gql';

import FormError from '~/utilities/FormError';



type Props = {
	accountId: CK.AccountId,
};

const EmailSettingsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const { data } = useEmailSettingsFormQuery({
		variables: {
			accountId,
		},
	});

	const areIntercomAutomaticMessagesForSalesEnabled = data?.account?.adminSettings?.areIntercomAutomaticMessagesForSalesEnabled ?? null;
	const areIntercomAutomaticMessagesForTrialRevivalEnabled = data?.account?.adminSettings?.areIntercomAutomaticMessagesForTrialRevivalEnabled ?? null;
	const areIntercomAutomaticMessagesForWelcomeEnabled = data?.account?.adminSettings?.areIntercomAutomaticMessagesForWelcomeEnabled ?? null;

	const [administerAccountEmailSettings] = useAdministerAccountEmailSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				await administerAccountEmailSettings({
					variables: {
						accountId,
						areIntercomAutomaticMessagesForSalesEnabled: values.areIntercomAutomaticMessagesForSalesEnabled,
						areIntercomAutomaticMessagesForTrialRevivalEnabled: values.areIntercomAutomaticMessagesForTrialRevivalEnabled,
						areIntercomAutomaticMessagesForWelcomeEnabled: values.areIntercomAutomaticMessagesForWelcomeEnabled,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}
		},
		[
			accountId,
			administerAccountEmailSettings,
		],
	);

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageInternals}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					isForAdmins={true}
					title="Emails"
				>
					<DisplayPart>
						<FormRows>
							<FormRow label="Welcome email">
								<StaticText focusTarget="areIntercomAutomaticMessagesForWelcomeEnabled">
									{areIntercomAutomaticMessagesForWelcomeEnabled ? 'enabled' : 'disabled'}
								</StaticText>
							</FormRow>

							<FormRow label="Sales email">
								<StaticText focusTarget="areIntercomAutomaticMessagesForSalesEnabled">
									{areIntercomAutomaticMessagesForSalesEnabled ? 'enabled' : 'disabled'}
								</StaticText>
							</FormRow>

							<FormRow label="Revival email">
								<StaticText focusTarget="areIntercomAutomaticMessagesForTrialRevivalEnabled">
									{areIntercomAutomaticMessagesForTrialRevivalEnabled ? 'enabled' : 'disabled'}
								</StaticText>
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form
							defaultValues={{
								areIntercomAutomaticMessagesForSalesEnabled,
								areIntercomAutomaticMessagesForTrialRevivalEnabled,
								areIntercomAutomaticMessagesForWelcomeEnabled,
							}}
							onSuccess={handleSubmit}
						>
							<FormRows>
								<FormRow
									htmlFor="areIntercomAutomaticMessagesForWelcomeEnabled"
									label="Welcome email"
								>
									<CheckboxField
										label="enabled"
										name="areIntercomAutomaticMessagesForWelcomeEnabled"
										width={false}
									/>
								</FormRow>

								<FormRow
									htmlFor="areIntercomAutomaticMessagesForSalesEnabled"
									label="Sales email"
								>
									<CheckboxField
										label="enabled"
										name="areIntercomAutomaticMessagesForSalesEnabled"
										width={false}
									/>
								</FormRow>

								<FormRow
									htmlFor="areIntercomAutomaticMessagesForTrialRevivalEnabled"
									label="Revival email"
								>
									<CheckboxField
										label="enabled"
										name="areIntercomAutomaticMessagesForTrialRevivalEnabled"
										width={false}
									/>
								</FormRow>
							</FormRows>

							<ButtonsLayout>
								<CancelButton />
								<SaveSubmitButton />
							</ButtonsLayout>
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WhenAccountActionAllowed>
	);
};



export default EmailSettingsForm;
