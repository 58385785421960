import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	STATUS_INVALID,
	STATUS_MISSING,
	STATUS_REDIRECTED,
	STATUS_SERVER_ERROR,
	STATUS_TIMEOUT,
	STATUS_VALID,
} from '~/model/sitemaps';



const messages = defineMessages({
	[STATUS_INVALID]: {
		id: 'ui.screen.sitemapsList.cells.status.invalid',
	},
	[STATUS_MISSING]: {
		id: 'ui.screen.sitemapsList.cells.status.missing',
	},
	[STATUS_REDIRECTED]: {
		id: 'ui.screen.sitemapsList.cells.status.redirected',
	},
	[STATUS_SERVER_ERROR]: {
		id: 'ui.screen.sitemapsList.cells.status.serverError',
	},
	[STATUS_TIMEOUT]: {
		id: 'ui.screen.sitemapsList.cells.status.timeout',
	},
	[STATUS_VALID]: {
		id: 'ui.screen.sitemapsList.cells.status.valid',
	},
});



type Props = {
	sitemapStatus: (
		| typeof STATUS_INVALID
		| typeof STATUS_MISSING
		| typeof STATUS_REDIRECTED
		| typeof STATUS_SERVER_ERROR
		| typeof STATUS_TIMEOUT
		| typeof STATUS_VALID
	),
};

const SitemapStatusName: React.FC<Props> = (props) => {
	const {
		sitemapStatus,
	} = props;

	const message = messages[sitemapStatus];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...messages[sitemapStatus]} />
	);
};



export default SitemapStatusName;
