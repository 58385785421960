import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import HistoricalSummary from './HistoricalSummary';
import IssueCardsList from '~/components/atoms/issues/components/lists/IssueCardsList';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import WebsiteIssueCategoryCardSkeleton from '~/components/atoms/issues/components/categoryCards/WebsiteIssueCategoryCardSkeleton';
import WebsiteIssueCategoryComparisonCard from '~/components/logic/issues/issuesCategoriesList/WebsiteIssueCategoryComparisonCard';

import {
	closeWebsiteIssueCategoryDetail,
	openWebsiteIssueCategoryDetail,
} from '~/actions/issuesScreen';

import {
	sortIssuesComparison,
} from '~/model/websiteIssuesComparison';

import {
	issuesOverviewCategorySelector,
} from '~/state/ui/content/selectors';

import {
	filterSelector,
} from '~/state/websiteIssuesComparison/selectors';

import {
	verticalScrollTo,
} from '~/utilities/scrollTo';



type Props = {
	loading: boolean,
	numberOfPlaceholders: number,
	websiteIssuesComparisonData: any,
};

const HistoricalIssuesList: React.FC<Props> = (props) => {
	const {
		loading,
		numberOfPlaceholders,
		websiteIssuesComparisonData,
	} = props;

	const dispatch = useDispatch();
	const filter = useSelector(filterSelector);
	const openedIssueType = useSelector(issuesOverviewCategorySelector);

	const handleIssueCategoryBlur = React.useCallback(
		() => {
			dispatch(
				closeWebsiteIssueCategoryDetail(),
			);
		},
		[dispatch],
	);

	const handleIssueCategoryFocus = React.useCallback(
		(issueType) => {
			setTimeout(() => {
				const element = document.querySelector('.js-scrollable-overlay');

				if (element) {
					verticalScrollTo(element, 0, 600);
				}
			}, 200);

			dispatch(
				openWebsiteIssueCategoryDetail(issueType),
			);
		},
		[dispatch],
	);

	React.useEffect(
		() => {
			if (websiteIssuesComparisonData === null || openedIssueType === null) {
				return;
			}

			if (!websiteIssuesComparisonData.some((issuesCategory) => issuesCategory.category === openedIssueType)) {
				handleIssueCategoryBlur();
			}
		},
		[
			handleIssueCategoryBlur,
			openedIssueType,
			websiteIssuesComparisonData,
		],
	);

	const items: Array<React.ReactNode> = [];

	if (loading) {
		for (let i = 0; i < numberOfPlaceholders; i++) {
			items.push((
				<WebsiteIssueCategoryCardSkeleton key={i} />
			));
		}
	} else if (websiteIssuesComparisonData === null) {
		return null;
	} else {
		sortIssuesComparison(websiteIssuesComparisonData).forEach((issuesCategory) => {
			items.push((
				<WebsiteIssueCategoryComparisonCard
					filter={filter}
					isActive={issuesCategory.category === openedIssueType}
					issueCategory={issuesCategory}
					key={issuesCategory.category}
					onBlurIssueCallback={handleIssueCategoryBlur}
					onFocusIssueCallback={handleIssueCategoryFocus}
					onMouseEnterCallback={() => {}}
					onMouseLeaveCallback={() => {}}
				/>
			));
		});
	}

	return (
		<IssueCardsList
			animations={false}
			blankSlate={(
				<NoSearchResults />
			)}
			overview={items.length > 0 && (
				<HistoricalSummary
					data={websiteIssuesComparisonData}
				/>
			)}
		>
			{items}
		</IssueCardsList>
	);
};



export default HistoricalIssuesList;
