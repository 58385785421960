import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import PriceItem from '~/components/patterns/pricing/priceItems/PriceItem';
import PriceItems from '~/components/patterns/pricing/priceItems/PriceItems';
import PriceItemsDivider from '~/components/patterns/pricing/priceItems/PriceItemsDivider';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountEndUserPlan from '~/hooks/useAccountEndUserPlan';
import useLegacyBillingDetails from '~/hooks/useLegacyBillingDetails';
import useTariffs from '~/hooks/useTariffs';

import {
	createPurchase,
} from '~/model/pricing/costs';

import {
	DETAIL_END_USER_PLAN,
	DETAIL_END_USER_PLAN_WEBSITES,
	DETAIL_END_USER_PLAN_WEBSITES_PAGES,
} from '~/model/pricing/endUser';



const messages = defineMessages({
	customFixedDiscount: {
		id: 'ui.billing.priceSummary.discounts.customExact',
	},
	customPercentDiscount: {
		id: 'ui.billing.priceSummary.discounts.customPercentage',
	},
	priceSummaryEndUserItem: {
		id: 'ui.billing.priceSummary.website',
	},
	subtotal: {
		id: 'ui.teamDetail.websites.summary.label.subtotal',
	},
	vat: {
		id: 'ui.teamDetail.websites.summary.label.vat',
	},
});

const totalAmountMessages = defineMessages({
	[GraphQL.Term.Annually]: {
		id: 'ui.teamDetail.websites.summary.label.total.annually',
	},
	[GraphQL.Term.Monthly]: {
		id: 'ui.teamDetail.websites.summary.label.total.monthly',
	},
	[GraphQL.Term.Quarterly]: {
		id: 'ui.teamDetail.websites.summary.label.total.quarterly',
	},
});



type Props = {
	accountId: CK.AccountId,
	pageCapacity: number,
};

const AddEndUserWebsitePriceSummaryBox: React.FC<Props> = (props) => {
	const {
		accountId,
		pageCapacity,
	} = props;

	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountEndUserPlan = useAccountEndUserPlan(accountId);
	const billingDetails = useLegacyBillingDetails(accountId);
	const tariffs = useTariffs();

	if (
		accountBillingCycle === null
		|| !billingDetails
		|| tariffs.isReady === false
	) {
		return null;
	}

	const discounts = billingDetails.get('discounts');
	const tax = billingDetails.get('tax');

	const normalizedPageCapacity = tariffs.tariff.normalizePageCapacity({
		pageCapacity,
	});

	const calculatedPrice = tariffs.tariff.calculatePurchaseCostDetails({
		billingCycle: accountBillingCycle,
		discounts: discounts ? discounts.toJS() : [],
		purchases: [
			createPurchase({
				details: {
					[DETAIL_END_USER_PLAN]: accountEndUserPlan,
					[DETAIL_END_USER_PLAN_WEBSITES]: Immutable.List([
						Immutable.Map({
							[DETAIL_END_USER_PLAN_WEBSITES_PAGES]: normalizedPageCapacity,
						}),
					]),
				},
				numberOfPages: 0,
			}),
		],
		taxRate: tax?.get('is_applied') ? tax?.get('rate') : 0.0,
	});

	let commitmentDiscount = 0.0;
	const hasTax = calculatedPrice.tax > 0;

	const hasOtherDiscounts = calculatedPrice.discounts.some(
		(discount) => discount.reason !== 'commitment',
	);

	calculatedPrice.discounts.forEach((discount) => {
		if (discount.reason === 'commitment') {
			commitmentDiscount = discount.value;
		}
	});

	return (
		<SimpleBox>
			<PriceItems>
				<PriceItem
					currency={calculatedPrice.currency}
					key="purchase"
					label={(
						<FormattedMessage
							{...messages.priceSummaryEndUserItem}
							values={{
								count: 1,
							}}
						/>
					)}
					priceValue={calculatedPrice.cost - commitmentDiscount}
				/>

				{calculatedPrice.discounts.map((discount) => {
					let discountLabel: React.ReactNode = false;

					if (discount.reason === 'commitment') {
						return false;
					} else if (discount.type === 'percent') {
						discountLabel = (
							<FormattedMessage
								{...messages.customPercentDiscount}
								values={{
									count__discountAmount: discount.rate,
								}}
							/>
						);
					} else if (discount.type === 'dollars') {
						discountLabel = (
							<FormattedMessage {...messages.customFixedDiscount} />
						);
					}

					return (
						<PriceItem
							currency={calculatedPrice.currency}
							key={discount.code}
							label={discountLabel}
							priceValue={-discount.value}
						/>
					);
				})}

				{hasTax && hasOtherDiscounts && (
					<PriceItem
						currency={calculatedPrice.currency}
						label={(
							<FormattedMessage {...messages.subtotal} />
						)}
						priceValue={calculatedPrice.subtotal}
					/>
				)}

				{hasTax && hasOtherDiscounts && (
					<PriceItemsDivider />
				)}

				{hasTax && (
					<PriceItem
						currency={calculatedPrice.currency}
						label={(
							<FormattedMessage {...messages.vat} />
						)}
						priceValue={calculatedPrice.tax}
					/>
				)}

				<PriceItemsDivider />

				<PriceItem
					currency={calculatedPrice.currency}
					highlighted={true}
					label={(
						<FormattedMessage {...totalAmountMessages[accountBillingCycle]} />
					)}
					priceValue={calculatedPrice.total}
				/>
			</PriceItems>
		</SimpleBox>
	);
};



export default AddEndUserWebsitePriceSummaryBox;
