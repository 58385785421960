import React from 'react';

import Gravatar, {
	GravatarStyle,
} from '~/components/patterns/users/Gravatar';



type Props = {
	avatar: {
		email: string | null,
		placeholder?: React.ReactNode,
	},
	changeRoleLink?: React.ReactNode,
	/** Optional user's label */
	label?: React.ReactNode,
	/** Name of user */
	name: React.ReactNode,
	/** Role of user */
	role: React.ReactNode,
};



const UserOverview: React.FC<Props> = (props) => {
	const {
		avatar,
		changeRoleLink,
		label,
		name,
		role,
	} = props;

	return (
		<div className="user-overview">
			<div className="user-overview__avatar">
				<Gravatar
					email={avatar.email}
					placeholder={avatar.placeholder}
					style={GravatarStyle.Member}
				/>
			</div>
			<div className="user-overview__content">
				{label && (
					<div className="user-overview__label">
						{label}
					</div>
				)}

				<h4 className="user-overview__name">
					{name}
				</h4>

				<div className="user-overview__role">
					{role}

					{changeRoleLink && (
						<span className="user-overview__change-role-link">
							{changeRoleLink}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};



export default UserOverview;
