import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BlankValue from '~/components/patterns/values/BlankValue';



const messages = defineMessages({
	label: {
		id: 'ui.general.empty',
		defaultMessage: 'empty',
	},
});



type Props = {
	/** Enable ellpsis support to prevent overflowing */
	ellipsis?: boolean,
};

const EmptyValue: React.FC<Props> = (props) => {
	const {
		ellipsis,
	} = props;

	return (
		<BlankValue ellipsis={ellipsis}>
			<FormattedMessage {...messages.label} />
		</BlankValue>
	);
};



export default EmptyValue;
