import type CK from '~/types/contentking';

import {
	useAccountMaximumSignupPageBundleQuery,
} from './useAccountMaximumSignupPageBundle.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountMaximumSignupPageBundle(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountMaximumSignupPageBundleQuery,
		accountId,
	);

	return data?.account?.maximumSignupPageBundle ?? null;
}



export default useAccountMaximumSignupPageBundle;
