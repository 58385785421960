import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import WebsiteIntegrationStatusBadge from '~/components/app/WebsiteIntegrationStatusBadge';

import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsitesOverviewTableIntegrationsList: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const tags: Array<React.ReactElement> = [];

	websiteIntegrations.listAll().forEach((integration) => {
		if (
			integration.status === GraphQL.WebsiteIntegrationStatus.FeatureNotEnabled
			|| integration.status === GraphQL.WebsiteIntegrationStatus.NotAvailable
		) {
			return;
		}

		tags.push(
			<WebsiteIntegrationStatusBadge
				integrationType={integration.type}
				key={integration.type}
				status={integration.status}
			/>,
		);
	});

	return (
		<List
			inline={true}
			size={ListSize.Small}
		>
			{tags}
		</List>
	);
};



export default WebsitesOverviewTableIntegrationsList;
