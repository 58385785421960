/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IssuesChangesWidgetQueryVariables = GraphQL.Exact<{
  endDate: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  startDate: GraphQL.Scalars['DateYMD']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IssuesChangesWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly issuesChanges: { readonly __typename?: 'WebsiteDashboardIssuesChanges', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: ReadonlyArray<{ readonly __typename?: 'WebsiteDashboardIssuesChangesData', readonly endDate: CK.DateYMD, readonly startDate: CK.DateYMD, readonly changes: ReadonlyArray<{ readonly __typename?: 'WebsiteDashboardIssuesChange', readonly changeType: GraphQL.PageIssueChangeType, readonly numberOfIssues: number }> }> | null } } };


export const IssuesChangesWidgetDocument = gql`
    query IssuesChangesWidget($endDate: DateYMD!, $scope: String!, $startDate: DateYMD!, $websiteId: WebsiteId!) {
  dashboardData {
    issuesChanges(
      endDate: $endDate
      scope: $scope
      startDate: $startDate
      websiteId: $websiteId
    ) {
      data {
        changes {
          changeType
          numberOfIssues
        }
        endDate
        startDate
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useIssuesChangesWidgetQuery__
 *
 * To run a query within a React component, call `useIssuesChangesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuesChangesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuesChangesWidgetQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      scope: // value for 'scope'
 *      startDate: // value for 'startDate'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIssuesChangesWidgetQuery(baseOptions: Apollo.QueryHookOptions<IssuesChangesWidgetQuery, IssuesChangesWidgetQueryVariables> & ({ variables: IssuesChangesWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssuesChangesWidgetQuery, IssuesChangesWidgetQueryVariables>(IssuesChangesWidgetDocument, options);
      }
export function useIssuesChangesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssuesChangesWidgetQuery, IssuesChangesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssuesChangesWidgetQuery, IssuesChangesWidgetQueryVariables>(IssuesChangesWidgetDocument, options);
        }
export function useIssuesChangesWidgetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IssuesChangesWidgetQuery, IssuesChangesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IssuesChangesWidgetQuery, IssuesChangesWidgetQueryVariables>(IssuesChangesWidgetDocument, options);
        }
export type IssuesChangesWidgetQueryHookResult = ReturnType<typeof useIssuesChangesWidgetQuery>;
export type IssuesChangesWidgetLazyQueryHookResult = ReturnType<typeof useIssuesChangesWidgetLazyQuery>;
export type IssuesChangesWidgetSuspenseQueryHookResult = ReturnType<typeof useIssuesChangesWidgetSuspenseQuery>;
export type IssuesChangesWidgetQueryResult = Apollo.QueryResult<IssuesChangesWidgetQuery, IssuesChangesWidgetQueryVariables>;