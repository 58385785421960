import {
	useCurrentUserHasPasswordQuery,
} from './useCurrentUserHasPassword.gql';



function useCurrentUserHasPassword(): boolean | null {
	const { data } = useCurrentUserHasPasswordQuery();

	return data?.authenticatedSession?.user.hasPassword ?? null;
}



export default useCurrentUserHasPassword;
