import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountEnabledFeaturesQuery,
} from './useAccountEnabledFeatures.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



type EnabledFeatures = Readonly<Array<GraphQL.AccountFeature>>;

function useAccountEnabledFeatures(accountId: CK.AccountId | null): EnabledFeatures | null {
	const { data } = useAccountPropertiesQuery(
		useAccountEnabledFeaturesQuery,
		accountId,
	);

	const features = data?.account?.features ?? null;

	return React.useMemo(
		() => {
			if (features === null) {
				return null;
			}

			return features.map((feature) => feature.feature);
		},
		[
			features,
		],
	);
}



export default useAccountEnabledFeatures;
