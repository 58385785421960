import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import ActiveFilter from '../../filters/ActiveFilter';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	updatePagesFilter,
} from '~/actions/pages/overview';

import {
	removeDefaultFilterValues,
} from '~/model/pages';

import {
	filterSelector as pagesFilterSelector,
	sortBySelector as pagesSortBySelector,
} from '~/state/ui/selectors';



const PagesOverviewFilterBar = (props) => {
	const {
		onFilterClickCallback,
	} = props;

	const websiteId = useWebsiteId();

	const dispatch = useDispatch();
	const filter = useSelector(pagesFilterSelector);
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const sortBy = useSelector(pagesSortBySelector);

	const handleRemoveFilter = React.useCallback(
		(filterId) => {
			dispatch(
				updatePagesFilter({
					[filterId]: undefined,
				}),
			);
		},
		[
			dispatch,
		],
	);

	const activeFilters = removeDefaultFilterValues(filter);

	return (
		<ActiveFilter
			activeFilters={activeFilters}
			clearLink={false}
			dispatch={dispatch}
			filter={filter}
			onFilterClickCallback={onFilterClickCallback}
			removeFilterCallback={handleRemoveFilter}
			segmentDefinitions={segmentDefinitions}
			segmentsManagement={true}
			sortBy={sortBy}
		/>
	);
};



export default PagesOverviewFilterBar;
