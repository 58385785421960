import classNames from 'classnames';
import React from 'react';



export enum WebVitalsStatusIndicatorStatus {
	Good = 'good',
	NeedsImprovement = 'needs-improvement',
	Poor = 'poor',
}



type Props = {
	/** Status definition */
	status?: WebVitalsStatusIndicatorStatus,
};

const WebVitalsStatusIndicator: React.FC<Props> = (props) => {
	const {
		status,
	} = props;

	const componentClasses = classNames({
		'web-vitals-status-indicator': true,
		['web-vitals-status-indicator--' + status + '-status']: status !== undefined,
	});

	return (
		<span className={componentClasses}>
			<span className="web-vitals-status-indicator__icon" />
		</span>
	);
};



export default WebVitalsStatusIndicator;
