import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MultiselectField from '~/components/atoms/forms/components/MultiselectField';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SearchEngineName from '~/components/names/SearchEngineName';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';
import WithPermission from '~/components/logic/access/WithPermission';
import YesOrNo from '~/components/app/YesOrNo';

import {
	useUpdateWebsiteAuditingSettingsMutation,
	useWebsiteSettingsMiscellaneousFormQuery,
} from './WebsiteSettingsMiscellaneousForm.gql';

import {
	ObjectType,
} from '~/model/permissions';

import {
	SearchEngine,
} from '~/model/websites/searchEngines';



const messages = defineMessages({
	relevantSearchEngines: {
		id: 'ui.websites.form.relevantSearchEngines.label',
	},
	relevantSearchEnginesSelection: {
		id: 'ui.websites.form.relevantSearchEngines.selection',
	},
	title: {
		id: 'ui.websites.form.monitoring.titleMiscellaneous',
	},
	trackH2ToH6Historically: {
		id: 'ui.websites.form.trackH2ToH6Historically.label',
	},
	trackNumericOnlyChanges: {
		id: 'ui.websites.form.trackNumericOnlyChanges.label',
	},
});

const SearchEngineMapping = [
	SearchEngine.Baidu,
	SearchEngine.Bing,
	SearchEngine.DuckDuckGo,
	SearchEngine.Google,
	SearchEngine.Seznam,
	SearchEngine.Yahoo,
	SearchEngine.Yandex,
] as const;

const relevantSearchEnginesOptions = SearchEngineMapping.map((searchEngine) => {
	return {
		name: searchEngine,
		title: (
			<SearchEngineName searchEngine={searchEngine} />
		),
	};
});



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteSettingsMiscellaneousForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const { data } = useWebsiteSettingsMiscellaneousFormQuery({
		variables: {
			websiteId,
		},
	});

	const isTrackingOfH2ToH6HistoricallyEnabled = data?.website?.isTrackingOfH2ToH6HistoricallyEnabled ?? null;
	const isTrackingOfNumericOnlyChangesEnabled = data?.website?.isTrackingOfNumericOnlyChangesEnabled ?? null;
	const relevantSearchEngines = data?.website?.relevantSearchEngines ?? null;

	const [updateWebsiteAuditingSettings] = useUpdateWebsiteAuditingSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (relevantSearchEngines === null) {
				return;
			}

			const updatedRelevantSearchEngines = [
				...values.relevantSearchEngines,
			];

			if (
				relevantSearchEngines.includes('others')
				&& updatedRelevantSearchEngines.includes('others') === false
			) {
				updatedRelevantSearchEngines.push('others');
			}

			await updateWebsiteAuditingSettings({
				variables: {
					relevantSearchEngines: updatedRelevantSearchEngines,
					isTrackingOfH2ToH6HistoricallyEnabled: values.isTrackingOfH2ToH6HistoricallyEnabled,
					isTrackingOfNumericOnlyChangesEnabled: values.isTrackingOfNumericOnlyChangesEnabled,
					websiteId,
				},
			});
		},
		[
			relevantSearchEngines,
			updateWebsiteAuditingSettings,
			websiteId,
		],
	);

	function formatActiveRelevantSearchEngines(relevantSearchEngines) {
		return relevantSearchEngines.filter((searchEngine) => searchEngine !== 'others');
	}

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageAuditingSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							<FormRow
								label={(
									<FormattedMessage {...messages.relevantSearchEngines} />
								)}
							>
								<StaticText focusTarget="relevantSearchEngines">
									{relevantSearchEngines !== null ? (
										<FormattedMessage
											{...messages.relevantSearchEnginesSelection}
											values={{
												count__relevantSearchEngines: formatActiveRelevantSearchEngines(relevantSearchEngines).length,
											}}
										/>
									) : (
										<SquareSkeleton maxWidth={80} />
									)}
								</StaticText>
							</FormRow>

							<FormRow
								label={(
									<FormattedMessage {...messages.trackH2ToH6Historically} />
								)}
							>
								<StaticText focusTarget="isTrackingOfH2ToH6HistoricallyEnabled">
									{isTrackingOfH2ToH6HistoricallyEnabled !== null ? (
										<YesOrNo state={isTrackingOfH2ToH6HistoricallyEnabled} />
									) : (
										<SquareSkeleton maxWidth={80} />
									)}
								</StaticText>
							</FormRow>

							<FormRow
								label={(
									<FormattedMessage {...messages.trackNumericOnlyChanges} />
								)}
							>
								<StaticText focusTarget="isTrackingOfNumericOnlyChangesEnabled">
									{isTrackingOfNumericOnlyChangesEnabled !== null ? (
										<YesOrNo state={isTrackingOfNumericOnlyChangesEnabled} />
									) : (
										<SquareSkeleton maxWidth={80} />
									)}
								</StaticText>
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form
							defaultValues={{
								relevantSearchEngines: relevantSearchEngines !== null
									? formatActiveRelevantSearchEngines(relevantSearchEngines)
									: null,
								isTrackingOfH2ToH6HistoricallyEnabled,
								isTrackingOfNumericOnlyChangesEnabled,
							}}
							onSuccess={handleSubmit}
						>
							<FormRows>
								<FormRow
									htmlFor="relevantSearchEngines"
									label={(
										<FormattedMessage {...messages.relevantSearchEngines} />
									)}
								>
									<FieldStatus
										focusFieldErrorMessage="relevantSearchEngines"
										name="relevantSearchEngines"
									>
										<MultiselectField
											isOnlyLinkVisible={true}
											name="relevantSearchEngines"
											options={relevantSearchEnginesOptions}
										/>
									</FieldStatus>
								</FormRow>

								<FormRow
									htmlFor="isTrackingOfH2ToH6HistoricallyEnabled"
									label={(
										<FormattedMessage {...messages.trackH2ToH6Historically} />
									)}
								>
									<FieldStatus name="isTrackingOfH2ToH6HistoricallyEnabled">
										<SwitchField
											activeStateLabel={(
												<YesOrNo state={true} />
											)}
											inactiveStateLabel={(
												<YesOrNo state={false} />
											)}
											name="isTrackingOfH2ToH6HistoricallyEnabled"
										/>
									</FieldStatus>
								</FormRow>

								<FormRow
									htmlFor="isTrackingOfNumericOnlyChangesEnabled"
									label={(
										<FormattedMessage {...messages.trackNumericOnlyChanges} />
									)}
								>
									<FieldStatus name="isTrackingOfNumericOnlyChangesEnabled">
										<SwitchField
											activeStateLabel={(
												<YesOrNo state={true} />
											)}
											inactiveStateLabel={(
												<YesOrNo state={false} />
											)}
											name="isTrackingOfNumericOnlyChangesEnabled"
										/>
									</FieldStatus>
								</FormRow>
							</FormRows>

							<ButtonsLayout>
								<CancelButton />
								<SaveSubmitButton />
							</ButtonsLayout>
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WithPermission>
	);
};



export default WebsiteSettingsMiscellaneousForm;
