/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsitesOverviewQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type WebsitesOverviewQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly displayName: string, readonly domain: string, readonly logo: string | null, readonly pageCapacity: number, readonly pageCapacityUsageDescription: GraphQL.WebsitePageCapacityUsageDescription, readonly pageCapacityUsageRatio: number, readonly pagesCount: number, readonly problem: GraphQL.WebsiteProblem | null, readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElement', readonly name: string }>, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }>, readonly logSources: ReadonlyArray<{ readonly __typename?: 'LogSource', readonly provider: GraphQL.LogSourceProvider, readonly status: GraphQL.LogSourceStatus }>, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly id: CK.PageSegmentId }> }> } | null };


export const WebsitesOverviewDocument = gql`
    query WebsitesOverview($accountId: AccountId!) {
  account(id: $accountId) {
    id
    websites {
      customElements {
        name
      }
      id
      displayName
      domain
      integrations {
        isNotOwned
        status
        type
      }
      logo
      logSources {
        provider
        status
      }
      pageCapacity
      pageCapacityUsageDescription
      pageCapacityUsageRatio
      pageSegments {
        id
      }
      pagesCount
      problem
    }
  }
}
    `;

/**
 * __useWebsitesOverviewQuery__
 *
 * To run a query within a React component, call `useWebsitesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsitesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsitesOverviewQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useWebsitesOverviewQuery(baseOptions: Apollo.QueryHookOptions<WebsitesOverviewQuery, WebsitesOverviewQueryVariables> & ({ variables: WebsitesOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsitesOverviewQuery, WebsitesOverviewQueryVariables>(WebsitesOverviewDocument, options);
      }
export function useWebsitesOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsitesOverviewQuery, WebsitesOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsitesOverviewQuery, WebsitesOverviewQueryVariables>(WebsitesOverviewDocument, options);
        }
export function useWebsitesOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsitesOverviewQuery, WebsitesOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsitesOverviewQuery, WebsitesOverviewQueryVariables>(WebsitesOverviewDocument, options);
        }
export type WebsitesOverviewQueryHookResult = ReturnType<typeof useWebsitesOverviewQuery>;
export type WebsitesOverviewLazyQueryHookResult = ReturnType<typeof useWebsitesOverviewLazyQuery>;
export type WebsitesOverviewSuspenseQueryHookResult = ReturnType<typeof useWebsitesOverviewSuspenseQuery>;
export type WebsitesOverviewQueryResult = Apollo.QueryResult<WebsitesOverviewQuery, WebsitesOverviewQueryVariables>;