import type CK from '~/types/contentking';

import {
	useAccountTariffQuery,
} from './useAccountTariff.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';
import {
	Tariff,
} from '~/model/pricing/tariffs';



function useAccountTariff(accountId: CK.AccountId | null): Tariff | null {
	const { data } = useAccountPropertiesQuery(
		useAccountTariffQuery,
		accountId,
	);

	const tariff = data?.account?.tariff ?? null;

	if (tariff === null) {
		return null;
	}

	if (Object.values(Tariff).includes(tariff as any)) {
		return tariff as Tariff;
	}

	throw new Error(`Unknown tariff: ${tariff}`);
}



export default useAccountTariff;
