import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import FileExportForm from '~/components/atoms/forms/forms/FileExportForm';
import PopupButton, {
	PopupButtonSize,
	PopupButtonStyle,
} from '~/components/patterns/buttons/PopupButton';

import useViewportType from '~/hooks/useViewportType';

import {
	ExportType,
} from '~/model/exports';

import {
	getRouterState,
} from '~/routing/router';
import goTo from '~/routing/goTo';



type Props = {
	exportType: ExportType,
	linkLabel: React.ReactNode,
	totalFilteredPages?: number,
};

const ExportHeaderButton: React.FC<Props> = (props) => {
	const {
		exportType,
		linkLabel,
		totalFilteredPages,
	} = props;

	const popupButtonRef = React.useRef<PopupButton | null>(null);
	const viewportType = useViewportType();

	const handleClosePopup = React.useCallback(
		() => {
			popupButtonRef.current?._handleClosePopup();
		},
		[
			popupButtonRef,
		],
	);

	const handleOpenMobileExport = React.useCallback(
		(e) => {
			e.preventDefault();
			const routerState = getRouterState();

			if (exportType === ExportType.ContentOverview) {
				goTo(e, 'website.pages.export', {
					websiteId: routerState.params.websiteId,
				});
			} else if (exportType === ExportType.ChangeTracking) {
				goTo(e, 'website.pages.export', {
					websiteId: routerState.params.websiteId,
				});
			} else if (exportType === ExportType.AffectedPages) {
				goTo(e, 'website.issues.issueCategory.affectedPages.export', {
					filter: routerState.params.filter,
					issuesOverviewCategory: routerState.params.issuesOverviewCategory,
					websiteId: routerState.params.websiteId,
				});
			} else if (exportType === ExportType.SingleIssueAffectedPages) {
				goTo(e, 'website.issues.issueCategory.singleIssueAffectedPages.export', {
					issuesOverviewCategory: routerState.params.issuesOverviewCategory,
					issuesOverviewIssue: routerState.params.issuesOverviewIssue,
					websiteId: routerState.params.websiteId,
				});
			}
		},
		[
			exportType,
		],
	);

	if (viewportType.isSmall) {
		return (
			<Button
				icon={(
					<BasicIcon type={BasicIconType.Export} />
				)}
				onClick={handleOpenMobileExport}
				style={ButtonStyle.Hollow}
			/>
		);
	}

	return (
		<PopupButton
			buttonIcon={(
				<BasicIcon type={BasicIconType.Export} />
			)}
			label={linkLabel}
			popupZIndex={1990}
			ref={popupButtonRef}
			size={PopupButtonSize.Small}
			style={PopupButtonStyle.Hollow}
			uppercase={true}
		>
			<FileExportForm
				currentLocale="en-US"
				onCancelCallback={handleClosePopup}
				totalFilteredPages={totalFilteredPages}
				type={exportType}
			/>
		</PopupButton>
	);
};



export default ExportHeaderButton;
