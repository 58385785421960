import React from 'react';

import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalContentWithSidebar from '~/components/atoms/modals/parts/ModalContentWithSidebar';
import ModalHeader, {
	ModalHeaderIconType as SimpleModalIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalPanel, {
	ALIGNMENT_CENTER as MODAL_PANEL_ALIGNMENT_CENTER,
	ALIGNMENT_TOP as MODAL_PANEL_ALIGNMENT_TOP,
} from '~/components/atoms/panels/ModalPanel';

import useModals from '~/hooks/useModals';



export enum SimpleModalSize {
	Large = 'large',
	Medium = 'medium',
	Small = 'default',
	XLarge = 'xlarge',
	XXLarge = 'xxlarge',
}

type Props = {
	alignToTop?: boolean,
	canBeClosed?: boolean,
	canBeClosedViaOverlay?: boolean,
	children?: React.ReactNode,
	closeCallback?: () => void,
	iconType?: SimpleModalIconType,
	isLoading?: boolean,
	size: SimpleModalSize,
	sidebar?: React.ReactNode,
	title: React.ReactNode,
} & ({
	/** Smaller modals cannot have sidebar */
	size: SimpleModalSize.Small | SimpleModalSize.Medium | SimpleModalSize.Large,
	sidebar?: never,
} | & {
	/** Sidebar is only available for XL and XXL modals */
	size: SimpleModalSize.XLarge | SimpleModalSize.XXLarge,
	sidebar?: React.ReactNode,
});



const SimpleModal: React.FC<Props> = (props) => {
	const {
		alignToTop = false,
		canBeClosed = true,
		canBeClosedViaOverlay = false,
		children,
		closeCallback,
		iconType,
		isLoading,
		size,
		sidebar,
		title,
	} = props;

	const modals = useModals();

	const closeModal = closeCallback ?? modals.closeModal;
	const hasSidebar = !!sidebar;

	return (
		<ModalPanel
			alignment={alignToTop ? MODAL_PANEL_ALIGNMENT_TOP : MODAL_PANEL_ALIGNMENT_CENTER}
			canBeClosed={canBeClosed}
			canBeClosedViaOverlay={canBeClosedViaOverlay}
			onCloseCallback={closeModal}
			size={size}
		>
			<ModalContainer
				gapsSize={hasSidebar ? 0 : 1}
				header={(
					<ModalHeader
						iconType={iconType}
						title={title}
					/>
				)}
				headerGapsSize={hasSidebar ? 1 : 0}
				isLoading={isLoading}
			>
				{hasSidebar ? (
					<ModalContentWithSidebar
						gapsSize={1}
						sidebar={sidebar}
						slimSidebar={size === SimpleModalSize.XLarge}
					>
						{children}
					</ModalContentWithSidebar>
				) : (
					children
				)}
			</ModalContainer>
		</ModalPanel>
	);
};



export default SimpleModal;

export { SimpleModalIconType };
