import React from 'react';

import AdminJobStatistics from '~/components/app/AdminJobStatistics';
import AdminKingdomSettings from '~/components/app/AdminKingdomSettings';
import AdminSchemaOrgValidator from '~/components/app/AdminSchemaOrgValidator';
import HeaderNavigationLayout from '~/components/patterns/layout/screen/headers/HeaderNavigationLayout';
import HeaderTitle from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import RecordDetailLayout from '~/components/atoms/layouts/records/RecordDetailLayout';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import TabNavigation, {
	TabNavigationStyle,
} from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem, {
	TabNavigationItemStyle,
} from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';

import useUrlState from '~/hooks/useUrlState';
import useViewportType from '~/hooks/useViewportType';



const GeneralAdminScreen: React.FC = () => {
	const urlState = useUrlState();
	const viewportType = useViewportType();

	const getNavigationItems = () => {
		return [
			{
				label: 'General Admin',
				routeName: 'generalAdmin',
			},
			{
				label: 'Settings',
				routeName: 'generalAdmin.settings',
			},
			{
				label: 'Schema.org validator',
				routeName: 'generalAdmin.schemaOrgValidator',
			},
			{
				label: 'Job statistics',
				routeName: 'generalAdmin.jobStatistics',
			},
		];
	};

	function renderNavigation() {
		const navigationItems: Array<React.ReactNode> = [];
		const items = getNavigationItems();

		items.forEach(({ label, routeName }) => {
			navigationItems.push((
				<TabNavigationItem
					isActive={routeName === urlState.name}
					key={routeName}
					linkRouteName={routeName}
					linkRouteParams={{}}
					style={TabNavigationItemStyle.Light}
					uppercase={viewportType.isSmall}
				>
					{label}
				</TabNavigationItem>
			));
		});

		return (
			<HeaderNavigationLayout>
				<TabNavigation
					scrollToItem={0}
					stretchItems={false}
					style={TabNavigationStyle.Dark}
				>
					{navigationItems}
				</TabNavigation>
			</HeaderNavigationLayout>
		);
	}

	return (
		<ScreenLayout
			header={(
				<ScreenHeader
					header={renderNavigation()}
				/>
			)}
			isHeaderFixed={true}
		>
			{urlState.name === 'generalAdmin' && (
				<ScreenLayout
					header={(
						<ScreenHeader>
							<HeaderTitleLayout>
								<HeaderTitle baselineAlignment={false}>
									Welcome to ContentKing platform admin panel
								</HeaderTitle>
							</HeaderTitleLayout>
						</ScreenHeader>
					)}
				/>
			)}

			{urlState.name === 'generalAdmin.jobStatistics' && (
				<ScreenLayout
					header={(
						<ScreenHeader>
							<HeaderTitleLayout>
								<HeaderTitle baselineAlignment={false}>
									Job statistics
								</HeaderTitle>
							</HeaderTitleLayout>
						</ScreenHeader>
					)}
				>
					<AdminJobStatistics />
				</ScreenLayout>
			)}

			{urlState.name === 'generalAdmin.schemaOrgValidator' && (
				<ScreenLayout
					header={(
						<ScreenHeader>
							<HeaderTitleLayout>
								<HeaderTitle baselineAlignment={false}>
									Schema.org validator
								</HeaderTitle>
							</HeaderTitleLayout>
						</ScreenHeader>
					)}
				>
					<RecordDetailLayout>
						<AdminSchemaOrgValidator />
					</RecordDetailLayout>
				</ScreenLayout>
			)}

			{urlState.name.startsWith('generalAdmin.settings') && (
				<ScreenLayout
					header={(
						<ScreenHeader>
							<HeaderTitleLayout>
								<HeaderTitle baselineAlignment={false}>
									Settings
								</HeaderTitle>
							</HeaderTitleLayout>
						</ScreenHeader>
					)}
				>
					<AdminKingdomSettings />
				</ScreenLayout>
			)}
		</ScreenLayout>
	);
};



export default GeneralAdminScreen;
