import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Form from '~/components/atoms/forms/basis/Form';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MultiselectTableField from '~/components/logic/formFields/MultiselectTableField';
import RadioList from '~/components/atoms/forms/components/RadioList';
import SegmentDefinitionIdentifier from '~/components/logic/segments/SegmentDefinitionIdentifier';
import SubmitButton from '~/components/app/SubmitButton';
import UpcomingValueUpdateHighlight from '~/components/patterns/values/UpcomingValueUpdateHighlight';

import {
	useUpdateIndexNowSegmentsBlocklistMutation,
} from './IndexNowSegmentBlocklistForm.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useIndexNowConditions from '~/hooks/useIndexNowConditions';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	getSegmentDefinitionByName,
} from '~/model/segments';



const messages = defineMessages({
	[GraphQL.IndexNowSegmentsBlocklistMode.AllowAllExcept]: {
		id: 'ui.indexNowSegmentBlocklistForm.allowAllExcept',
	},
	[GraphQL.IndexNowSegmentsBlocklistMode.DenyAllExcept]: {
		id: 'ui.indexNowSegmentBlocklistForm.denyAllExcept',
	},
	allDeniedTitle: {
		id: 'ui.general.caution',
	},
	applyChanges: {
		id: 'ui.general.applyChanges',
	},
	allDeniedWarning: {
		id: 'ui.indexNowSegmentBlocklistForm.allDeniedWarning',
	},
	isBlocked: {
		id: 'ui.indexNowSegmentBlocklistForm.isBlocked',
	},
	isNotBlocked: {
		id: 'ui.indexNowSegmentBlocklistForm.isNotBlocked',
	},
	segmentColumn: {
		id: 'ui.indexNowSegmentBlocklistForm.segmentColumn',
	},
	statusColumn: {
		id: 'ui.indexNowSegmentBlocklistForm.statusColumn',
	},
	willBeBlocked: {
		id: 'ui.indexNowSegmentBlocklistForm.willBeBlocked',
	},
	willNotBeBlocked: {
		id: 'ui.indexNowSegmentBlocklistForm.willNotBeBlocked',
	},
});



const IndexNowSegmentBlocklistForm: React.FC = () => {
	const websiteId = useWebsiteId();

	const classicFormBehavior = useClassicFormBehavior();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const [updateSegmentsBlocklist] = useUpdateIndexNowSegmentsBlocklistMutation();

	const {
		loading,
		segmentsBlocklistAllowedSegments,
		segmentsBlocklistDisallowedSegments,
		segmentsBlocklistMode,
	} = useIndexNowConditions(websiteId);

	const defaultSegments = (
		segmentsBlocklistMode === GraphQL.IndexNowSegmentsBlocklistMode.AllowAllExcept
			? segmentsBlocklistDisallowedSegments
			: segmentsBlocklistAllowedSegments
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateSegmentsBlocklist({
				variables: {
					mode: values.mode,
					segments: values.segments,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			updateSegmentsBlocklist,
			websiteId,
		],
	);

	const renderHeader = React.useCallback(
		({ columnIndex }) => {
			switch (columnIndex) {

				case 0:
					return (
						<FormattedMessage {...messages.segmentColumn} />
					);

				case 1:
					return (
						<FormattedMessage {...messages.statusColumn} />
					);

			}

			return null;
		},
		[],
	);

	const renderCell = React.useCallback(
		({ columnIndex, isChecked, item, values }) => {
			const segment = getSegmentDefinitionByName(
				segmentDefinitions.listAll(),
				item,
			);

			switch (columnIndex) {

				case 0:
					if (segment === null) {
						return null;
					}

					return (
						<SegmentDefinitionIdentifier
							segmentDefinition={segment}
							showCriteria={true}
							showEditLink={false}
						/>
					);

				case 1:
					if (segment === null) {
						return null;
					}

					const wasBlocked = segmentsBlocklistMode === GraphQL.IndexNowSegmentsBlocklistMode.AllowAllExcept
						? defaultSegments?.includes(segment.name)
						: !defaultSegments?.includes(segment.name);

					const isBlocked = (
						values.mode === GraphQL.IndexNowSegmentsBlocklistMode.AllowAllExcept
							? isChecked
							: !isChecked
					);

					const valueChanged = wasBlocked !== undefined && wasBlocked !== isBlocked;

					if (!valueChanged) {
						if (isBlocked) {
							return (
								<FormattedMessage {...messages.isBlocked} />
							);
						}

						return (
							<FormattedMessage {...messages.isNotBlocked} />
						);
					}

					if (isBlocked) {
						return (
							<UpcomingValueUpdateHighlight updateDescription={<FormattedMessage {...messages.willBeBlocked} />}>
								<FormattedMessage {...messages.willBeBlocked} />
							</UpcomingValueUpdateHighlight>
						);
					}

					return (
						<UpcomingValueUpdateHighlight updateDescription={<FormattedMessage {...messages.willNotBeBlocked} />}>
							<FormattedMessage {...messages.willNotBeBlocked} />
						</UpcomingValueUpdateHighlight>
					);

			}

			return null;
		},
		[
			defaultSegments,
			segmentsBlocklistMode,
			segmentDefinitions,
		],
	);

	const isBlockingEverything = (values) => {
		if (values.mode === GraphQL.IndexNowSegmentsBlocklistMode.DenyAllExcept) {
			return values.segments.length === 0;
		}

		return false;
	};

	return (
		<Form
			defaultValues={{
				mode: segmentsBlocklistMode,
				segments: defaultSegments,
			}}
			isDisabled={loading}
			key={loading ? 'loading' : 'ready'}
			onSuccess={handleSubmit}
		>
			{({ values }) => (
				<>
					<FormRows>
						<RadioList
							inline={true}
							items={[
								{
									label: (
										<FormattedMessage {...messages[GraphQL.IndexNowSegmentsBlocklistMode.AllowAllExcept]} />
									),
									value: GraphQL.IndexNowSegmentsBlocklistMode.AllowAllExcept,
								},
								{
									label: (
										<FormattedMessage {...messages[GraphQL.IndexNowSegmentsBlocklistMode.DenyAllExcept]} />
									),
									value: GraphQL.IndexNowSegmentsBlocklistMode.DenyAllExcept,
								},
							]}
							name="mode"
							wrapItems={false}
						/>

						{segmentDefinitions.isLoaded && (
							<MultiselectTableField
								cellRenderer={(args) => renderCell({ ...args, values })}
								columnCount={2}
								columnWidth={({ width }) => width / 2}
								disabledGetter={() => loading}
								headerRenderer={renderHeader}
								items={segmentDefinitions.listAll().map((segmentDefinition) => segmentDefinition.name)}
								name="segments"
								tableHeight={Math.min(segmentDefinitions.count, 8) * 40}
							/>
						)}

						{isBlockingEverything(values) && (
							<CalloutMessage
								borders={true}
								message={(
									<FormattedMessage {...messages.allDeniedTitle} />
								)}
								status={CalloutMessageStatus.Critical}
							>
								<FormattedMessage {...messages.allDeniedWarning} />
							</CalloutMessage>
						)}
					</FormRows>

					<CenteredFormWrapper>
						<ButtonsLayout>
							<CancelButton />

							<SubmitButton>
								<FormattedMessage {...messages.applyChanges} />
							</SubmitButton>
						</ButtonsLayout>
					</CenteredFormWrapper>
				</>
			)}
		</Form>
	);
};



export default IndexNowSegmentBlocklistForm;
