/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectConductorOrganizationMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  conductorAccountId: GraphQL.Scalars['Int']['input'];
  sendWelcomeEmails: GraphQL.Scalars['Boolean']['input'];
}>;


export type ConnectConductorOrganizationMutation = { readonly __typename?: 'Mutation', readonly ConnectConductorOrganization: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly conductorOrganizationId: string | null, readonly id: CK.AccountId, readonly isDemoAvailable: boolean, readonly isProfileCompletenessAvailable: boolean, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }>, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly displayName: string, readonly email: string, readonly hasLimitedWebsitesAccess: boolean, readonly id: CK.InvitationId, readonly role: GraphQL.UserRole, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly displayName: string, readonly email: string, readonly hasTwoFactorAuthentication: boolean, readonly id: string, readonly isChromeExtensionInstalled: boolean, readonly isPlatformTourWebinarOfferingAvailable: boolean, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly profileCompleteness: { readonly __typename?: 'AccountProfileCompleteness', readonly tasks: ReadonlyArray<{ readonly __typename?: 'AccountProfileCompletenessTaskStatus', readonly isDone: boolean, readonly type: GraphQL.AccountProfileCompletenessTask }> } | null } | null } } };

export type ValidateConductorOrganizationConnectionMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  conductorAccountId: GraphQL.Scalars['Int']['input'];
}>;


export type ValidateConductorOrganizationConnectionMutation = { readonly __typename?: 'Mutation', readonly ValidateConductorOrganizationConnection: { readonly __typename?: 'ValidateConductorOrganizationConnectionResult', readonly addedConductorUsers: ReadonlyArray<{ readonly __typename?: 'ValidateConductorOrganizationConnectionResultAddedUser', readonly email: string, readonly userRole: GraphQL.UserRole }>, readonly conductorOrganization: { readonly __typename?: 'ConductorOrganization', readonly id: string, readonly name: string }, readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly name: string | null } | null }, readonly keptUsers: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string } }>, readonly removedUsers: ReadonlyArray<{ readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string }> } };


export const ConnectConductorOrganizationDocument = gql`
    mutation ConnectConductorOrganization($accountId: AccountId!, $conductorAccountId: Int!, $sendWelcomeEmails: Boolean!) {
  ConnectConductorOrganization(
    accountId: $accountId
    conductorAccountId: $conductorAccountId
    sendWelcomeEmails: $sendWelcomeEmails
  ) {
    query {
      account(id: $accountId) {
        conductorOrganizationId
        features {
          feature
          id
        }
        id
        invitations {
          displayName
          email
          hasLimitedWebsitesAccess
          id
          role
          websites {
            id
          }
        }
        isDemoAvailable
        isProfileCompletenessAvailable
        memberships {
          account {
            id
          }
          hasLimitedWebsitesAccess
          role
          user {
            displayName
            email
            hasTwoFactorAuthentication
            id
            isChromeExtensionInstalled
            isPlatformTourWebinarOfferingAvailable
            legacyId
          }
          websites {
            id
          }
        }
        profileCompleteness {
          tasks {
            isDone
            type
          }
        }
      }
    }
  }
}
    `;
export type ConnectConductorOrganizationMutationFn = Apollo.MutationFunction<ConnectConductorOrganizationMutation, ConnectConductorOrganizationMutationVariables>;

/**
 * __useConnectConductorOrganizationMutation__
 *
 * To run a mutation, you first call `useConnectConductorOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectConductorOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectConductorOrganizationMutation, { data, loading, error }] = useConnectConductorOrganizationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      conductorAccountId: // value for 'conductorAccountId'
 *      sendWelcomeEmails: // value for 'sendWelcomeEmails'
 *   },
 * });
 */
export function useConnectConductorOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<ConnectConductorOrganizationMutation, ConnectConductorOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectConductorOrganizationMutation, ConnectConductorOrganizationMutationVariables>(ConnectConductorOrganizationDocument, options);
      }
export type ConnectConductorOrganizationMutationHookResult = ReturnType<typeof useConnectConductorOrganizationMutation>;
export type ConnectConductorOrganizationMutationResult = Apollo.MutationResult<ConnectConductorOrganizationMutation>;
export type ConnectConductorOrganizationMutationOptions = Apollo.BaseMutationOptions<ConnectConductorOrganizationMutation, ConnectConductorOrganizationMutationVariables>;
export const ValidateConductorOrganizationConnectionDocument = gql`
    mutation ValidateConductorOrganizationConnection($accountId: AccountId!, $conductorAccountId: Int!) {
  ValidateConductorOrganizationConnection(
    accountId: $accountId
    conductorAccountId: $conductorAccountId
  ) {
    addedConductorUsers {
      email
      userRole
    }
    conductorOrganization {
      id
      name
    }
    query {
      account(id: $accountId) {
        id
        name
      }
    }
    keptUsers {
      account {
        id
      }
      role
      user {
        email
        id
        legacyId
      }
    }
    removedUsers {
      email
      id
      legacyId
    }
  }
}
    `;
export type ValidateConductorOrganizationConnectionMutationFn = Apollo.MutationFunction<ValidateConductorOrganizationConnectionMutation, ValidateConductorOrganizationConnectionMutationVariables>;

/**
 * __useValidateConductorOrganizationConnectionMutation__
 *
 * To run a mutation, you first call `useValidateConductorOrganizationConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateConductorOrganizationConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateConductorOrganizationConnectionMutation, { data, loading, error }] = useValidateConductorOrganizationConnectionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      conductorAccountId: // value for 'conductorAccountId'
 *   },
 * });
 */
export function useValidateConductorOrganizationConnectionMutation(baseOptions?: Apollo.MutationHookOptions<ValidateConductorOrganizationConnectionMutation, ValidateConductorOrganizationConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateConductorOrganizationConnectionMutation, ValidateConductorOrganizationConnectionMutationVariables>(ValidateConductorOrganizationConnectionDocument, options);
      }
export type ValidateConductorOrganizationConnectionMutationHookResult = ReturnType<typeof useValidateConductorOrganizationConnectionMutation>;
export type ValidateConductorOrganizationConnectionMutationResult = Apollo.MutationResult<ValidateConductorOrganizationConnectionMutation>;
export type ValidateConductorOrganizationConnectionMutationOptions = Apollo.BaseMutationOptions<ValidateConductorOrganizationConnectionMutation, ValidateConductorOrganizationConnectionMutationVariables>;