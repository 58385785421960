import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import GlobalFormMessage, {
	GlobalFormMessageStyle,
} from '~/components/atoms/forms/basis/GlobalFormMessage';

import useFormContext from '~/hooks/useFormContext';



const messages = defineMessages({
	failedAuthorizationError: {
		id: 'ui.paypal.failedAuthorizationError',
	},
	paypalButtonDescription: {
		id: 'ui.paypal.description',
	},
	unexpectedError: {
		id: 'ui.general.unexpectedError',
	},
});



const PayPalButton: React.FC = () => {
	const formContext = useFormContext();

	return (
		<div>
			{!formContext.globalError && (
				<div className="paypal-button">
					<div className="paypal-button__note">
						<FormattedMessage {...messages.paypalButtonDescription} />
					</div>
				</div>
			)}

			<GlobalFormMessage
				name="paypal-authorization"
				style={GlobalFormMessageStyle.None}
			>
				<CalloutMessage
					centeredContent={true}
					message={(
						<FormattedMessage {...messages.unexpectedError} />
					)}
					status={CalloutMessageStatus.Critical}
				/>

				<div className="paypal-button">
					<div className="paypal-button__note">
						<FormattedMessage {...messages.failedAuthorizationError} />
					</div>
				</div>
			</GlobalFormMessage>
		</div>
	);
};



export default PayPalButton;
