import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import ActiveFilter from '~/components/logic/filters/ActiveFilter';

import {
	updateFilter,
} from '~/actions/alertPages';

import {
	removeDefaultFilterValues,
} from '~/model/alertPages';

import {
	currentAlertSelector,
	currentFilterSelector,
} from '~/state/alertPages/selectors';



const messages = defineMessages({
	alertStatus: {
		id: 'ui.contentOverview.tableHeading.alertStatus',
	},
});



type Props = {
	onFilterClickCallback: (filterProperty: string) => void,
};

const AlertPagesActiveFilterBar: React.FC<Props> = (props) => {
	const {
		onFilterClickCallback,
	} = props;

	const alert = useSelector(currentAlertSelector);
	const dispatch = useDispatch();
	const filter = useSelector(currentFilterSelector);

	const handleRemoveFilter = React.useCallback(
		(filterId) => {
			dispatch(
				updateFilter({
					[filterId]: undefined,
				}),
			);
		},
		[
			dispatch,
		],
	);

	const activeFilters = removeDefaultFilterValues(
		filter,
		alert.get('type'),
		alert.get('settings'),
	);

	return (
		<ActiveFilter
			activeFilters={activeFilters}
			clearLink={false}
			filter={filter}
			filterNames={{
				alert_status: (
					<FormattedMessage {...messages.alertStatus} />
				),
				issue_status: (
					<FormattedMessage {...messages.alertStatus} />
				),
				status: (
					<FormattedMessage {...messages.alertStatus} />
				),
			}}
			onFilterClickCallback={onFilterClickCallback}
			removeFilterCallback={handleRemoveFilter}
			segmentsManagement={false}
		/>
	);
};



export default AlertPagesActiveFilterBar;
