import classNames from 'classnames';
import React from 'react';

import Hint, {
	HintAttachment,
	HintPopupSize,
	HintPopupSkin,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import HintPopupLayout from '~/components/patterns/hints/hint/HintPopupLayout';
import RichText from '~/components/patterns/typography/RichText';

import touchSupported from '~/utilities/touchSupported';



export enum RibbonStyle {
	Premium = 'premium',
	Remark = 'remark',
}

export enum RibbonTooltipVisibility {
	Always = 'always',
	Auto = 'auto',
}

type Props = {
	children: React.ReactNode,
	/** Specific style of ribbon */
	style: RibbonStyle,
	/** Attached CTA element to tooltip */
	tooltipCtaElement?: React.ReactNode,
	/** Optional tooltip message visible on hover */
	tooltipMessage?: React.ReactNode,
	tooltipVisibility?: RibbonTooltipVisibility,
};



const Ribbon: React.FC<Props> = (props) => {
	const {
		children,
		style,
		tooltipCtaElement,
		tooltipMessage,
		tooltipVisibility = RibbonTooltipVisibility.Auto,
	} = props;

	const componentClasses = classNames({
		'ribbon': true,
		[ 'ribbon--' + style + '-style' ]: style,
	});

	const ribbon = (
		<span className={componentClasses}>
			<span className="ribbon__content">
				{children}
			</span>
		</span>
	);

	if (tooltipMessage) {
		let popupVisibility;

		if (tooltipVisibility == RibbonTooltipVisibility.Always) {
			popupVisibility = HintPopupVisibility.Always;
		} else {
			popupVisibility = touchSupported ? HintPopupVisibility.OnClick : HintPopupVisibility.OnHover;
		}

		let popupSkin;

		if (style === RibbonStyle.Premium) {
			popupSkin = HintPopupSkin.Premium;
		}

		if (style === RibbonStyle.Remark) {
			popupSkin = HintPopupSkin.Remark;
		}

		return (
			<Hint
				attachment={HintAttachment.Center}
				blurDelay={200}
				popup={(
					<RichText>
						{tooltipMessage}
					</RichText>
				)}
				popupLayout={(children) => {
					return (
						<HintPopupLayout ctaElement={tooltipCtaElement}>
							{children}
						</HintPopupLayout>
					);
				}}
				popupMaxWidth={280}
				popupSize={HintPopupSize.Large}
				popupSkin={popupSkin}
				popupVisibility={popupVisibility}
			>
				{ribbon}
			</Hint>
		);
	}

	return ribbon;
};



export default Ribbon;
