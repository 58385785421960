/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptDataProcessingAgreementMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AcceptDataProcessingAgreementMutation = { readonly __typename?: 'Mutation', readonly AcceptLegalAgreement: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly legalDocumentRequirements: ReadonlyArray<{ readonly __typename?: 'LegalDocumentRequirement', readonly acceptedAt: CK.Timestamp | null, readonly documentType: GraphQL.LegalDocumentType, readonly requirement: GraphQL.LegalDocumentRequirementType }> } | null } } };


export const AcceptDataProcessingAgreementDocument = gql`
    mutation AcceptDataProcessingAgreement($accountId: AccountId!) {
  AcceptLegalAgreement(accountId: $accountId, documentName: "dpa") {
    query {
      account(id: $accountId) {
        id
        legalDocumentRequirements {
          acceptedAt
          documentType
          requirement
        }
      }
    }
  }
}
    `;
export type AcceptDataProcessingAgreementMutationFn = Apollo.MutationFunction<AcceptDataProcessingAgreementMutation, AcceptDataProcessingAgreementMutationVariables>;

/**
 * __useAcceptDataProcessingAgreementMutation__
 *
 * To run a mutation, you first call `useAcceptDataProcessingAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptDataProcessingAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptDataProcessingAgreementMutation, { data, loading, error }] = useAcceptDataProcessingAgreementMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAcceptDataProcessingAgreementMutation(baseOptions?: Apollo.MutationHookOptions<AcceptDataProcessingAgreementMutation, AcceptDataProcessingAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptDataProcessingAgreementMutation, AcceptDataProcessingAgreementMutationVariables>(AcceptDataProcessingAgreementDocument, options);
      }
export type AcceptDataProcessingAgreementMutationHookResult = ReturnType<typeof useAcceptDataProcessingAgreementMutation>;
export type AcceptDataProcessingAgreementMutationResult = Apollo.MutationResult<AcceptDataProcessingAgreementMutation>;
export type AcceptDataProcessingAgreementMutationOptions = Apollo.BaseMutationOptions<AcceptDataProcessingAgreementMutation, AcceptDataProcessingAgreementMutationVariables>;