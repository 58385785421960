import React from 'react';



function useLocalStorage(
	key: string,
	initialValue: any,
	formatValue: ((value: any) => any) | null = null,
) {
	const [value, setValue] = React.useState<string>(
		() => {
			if (!('localStorage' in window)) {
				return JSON.stringify(initialValue);
			}

			return window.localStorage.getItem(key) ?? JSON.stringify(initialValue);
		},
	);

	const setItem = React.useCallback(
		(newValue: any) => {
			newValue = JSON.stringify(newValue);

			setValue(newValue);

			if ('localStorage' in window) {
				window.localStorage.setItem(key, newValue);
			}
		},
		[
			key,
		],
	);

	React.useEffect(
		() => {
			function storageListener(event: StorageEvent) {
				if (event.key === key && event.newValue !== value) {
					setValue(event.newValue ?? JSON.stringify(initialValue));
				}
			}

			window.addEventListener('storage', storageListener);

			return () => {
				window.removeEventListener('storage', storageListener);
			};
		},
		[
			key,
			value,
			initialValue,
		],
	);

	const currentValue = (
		formatValue !== null
			? formatValue(JSON.parse(value))
			: JSON.parse(value)
	);

	return [currentValue, setItem];
}



export default useLocalStorage;
