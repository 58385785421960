import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MagicWandIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g
					id="magic-wand"
					stroke={color}
					strokeWidth="2"
					transform="translate(4.000000, 5.000000)"
				>
					<g id="Group">
						<path
							d="M0,14 L4,14"
							id="Path"
						>
						</path>
						<path
							d="M2,12 L2,16"
							id="Path"
						>
						</path>
						<path
							d="M11,2 L15,2"
							id="Path"
						>
						</path>
						<path
							d="M13,0 L13,4"
							id="Path"
						>
						</path>
						<polygon
							id="Path"
							points="22 17.87 18.87 21 4 6.131 7.13 3"
						>
						</polygon>
						<path
							d="M7,9 L10,6"
							id="Path"
						>
						</path>
						<path
							d="M0,0 L3,3"
							id="Path"
						>
						</path>
						<path
							d="M3,0 L0,3"
							id="Path"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 30,
	viewBoxWidth: 30,
};



export default MagicWandIconBuilder;

