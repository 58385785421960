import React from 'react';

import {
	ScopeSectionContext,
} from '~/components/app/Dashboard/ScopeSection';

import {
	type Scope,
} from '~/model/scopes';

import {
	MissingContextProviderError,
} from '~/errors';



function useDashboardScopeSection(): Scope {
	const context = React.useContext(ScopeSectionContext);

	if (context === null) {
		throw new MissingContextProviderError('ScopeSectionContext');
	}

	return context.scope;
}



export default useDashboardScopeSection;
