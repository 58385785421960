import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import MissingValue from '~/components/app/MissingValue';



type Props = {
	maximumFractionDigits?: number,
	minimumFractionDigits?: number,
	value: number | null,
};

const NumberFormatter: React.FC<Props> = (props) => {
	const {
		maximumFractionDigits,
		minimumFractionDigits,
		value = null,
	} = props;

	if (value === null) {
		return (
			<MissingValue ellipsis={true} />
		);
	}

	return (
		<FormattedNumber
			maximumFractionDigits={maximumFractionDigits}
			minimumFractionDigits={minimumFractionDigits}
			value={value}
		/>
	);
};



export default React.memo(NumberFormatter);
