import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
} from '~/components/atoms/forms/components/TextField';

import {
	useInstallCloudflareWorkerMutation,
} from './InstallCloudflareWorkerModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	validateField,
} from './validations';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	apiToken: {
		id: 'ui.installCloudflareWorkerModal.apiToken',
	},
	cloudflareApiTokenCannotAccessWebsiteError: {
		id: 'ui.installCloudflareWorkerModal.errors.cloudflareApiTokenCannotAccessWebsite',
	},
	cloudflareApiTokenInvalidFormatError: {
		id: 'ui.installCloudflareWorkerModal.errors.cloudflareApiTokenInvalidFormat',
	},
	description: {
		id: 'ui.installCloudflareWorkerModal.description',
	},
	installButton: {
		id: 'ui.installCloudflareWorkerModal.installButton',
	},
	sidebar: {
		id: 'ui.installCloudflareWorkerModal.sidebar',
	},
	title: {
		id: 'ui.installCloudflareWorkerModal.title',
	},
});

const validations = {
	validateApiToken: validateField(
		'apiToken',
		(f) => ([
			f.validateNonEmpty(),
			f.customGlobal({
				globalRule: 'invalidCloudflareApiTokenFormat',
				message: (
					<FormattedMessage {...messages.cloudflareApiTokenInvalidFormatError} />
				),
			}),
		]),
	),
};



const InstallCloudflareWorkerModal: React.FC = () => {
	const classicFormBehavior = useClassicFormBehavior();
	const websiteId = useWebsiteId();
	const websiteDisplayName = useWebsiteDisplayName(websiteId);
	const [installCloudflareWorker] = useInstallCloudflareWorkerMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				await installCloudflareWorker({
					variables: {
						apiToken: values.apiToken,
						websiteId,
					},
				});

				classicFormBehavior.finish();
			} catch (error) {
				throw FormError.fromApolloError(error, {
					invalidCloudflareApiTokenFormat: values.apiToken,
				});
			}
		},
		[
			classicFormBehavior,
			installCloudflareWorker,
			websiteId,
		],
	);

	return (
		<SimpleModal
			iconType={BasicIconType.Plus}
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-worker-integration/#2-setting-up-the-cloudflare-worker-integration'),
						}}
					/>
				</RichText>
			)}
			size={SimpleModalSize.XLarge}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ModalTextSection>
				<Copy
					{...messages.description}
					values={{
						linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-worker-integration/#2-setting-up-the-cloudflare-worker-integration'),
					}}
				/>
			</ModalTextSection>

			<Form
				onSuccess={handleSubmit}
				validations={validations}
			>
				<CenteredFormWrapper>
					<FormRow
						htmlFor="apiToken"
						label={(
							<FormattedMessage {...messages.apiToken} />
						)}
					>
						<FieldStatus
							allowOk={false}
							name="validateApiToken"
						>
							<TextField
								autoComplete={TextFieldAutocomplete.Off}
								name="apiToken"
								spellCheck={false}
								trimValue={true}
							/>
						</FieldStatus>
					</FormRow>

					<FormErrorMessages
						errors={{
							cloudflareApiTokenCannotAccessWebsite: (
								<FormattedMessage
									{...messages.cloudflareApiTokenCannotAccessWebsiteError}
									values={{ websiteDisplayName }}
								/>
							),
						}}
					/>

					<ButtonsLayout>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.installButton} />
						</SubmitButton>
					</ButtonsLayout>
				</CenteredFormWrapper>
			</Form>
		</SimpleModal>
	);
};



export default InstallCloudflareWorkerModal;
