/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectGoogleSearchConsoleAccountMutationVariables = GraphQL.Exact<{
  authCode: GraphQL.Scalars['String']['input'];
  ownerAccountId: GraphQL.Scalars['AccountId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ConnectGoogleSearchConsoleAccountMutation = { readonly __typename?: 'Mutation', readonly ConnectGoogleSearchConsoleAccount: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly sharedColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly personalColumnSets: ReadonlyArray<{ readonly __typename?: 'ColumnSet', readonly id: CK.ColumnSetId, readonly isDefault: boolean, readonly name: string, readonly columns: ReadonlyArray<{ readonly __typename?: 'ColumnSetColumn', readonly enabled: boolean, readonly name: CK.PagesColumn }> }> } | null, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null } } };


export const ConnectGoogleSearchConsoleAccountDocument = gql`
    mutation ConnectGoogleSearchConsoleAccount($authCode: String!, $ownerAccountId: AccountId!, $websiteId: WebsiteId!) {
  ConnectGoogleSearchConsoleAccount(
    authCode: $authCode
    ownerAccountId: $ownerAccountId
    websiteId: $websiteId
  ) {
    query {
      authenticatedSession {
        memberships {
          account {
            id
            sharedColumnSets {
              columns {
                enabled
                name
              }
              id
              isDefault
              name
            }
          }
          user {
            id
            legacyId
          }
        }
        personalColumnSets {
          columns {
            enabled
            name
          }
          id
          isDefault
          name
        }
      }
      website(id: $websiteId) {
        id
        integrations {
          isNotOwned
          status
          type
        }
      }
    }
  }
}
    `;
export type ConnectGoogleSearchConsoleAccountMutationFn = Apollo.MutationFunction<ConnectGoogleSearchConsoleAccountMutation, ConnectGoogleSearchConsoleAccountMutationVariables>;

/**
 * __useConnectGoogleSearchConsoleAccountMutation__
 *
 * To run a mutation, you first call `useConnectGoogleSearchConsoleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectGoogleSearchConsoleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectGoogleSearchConsoleAccountMutation, { data, loading, error }] = useConnectGoogleSearchConsoleAccountMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *      ownerAccountId: // value for 'ownerAccountId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useConnectGoogleSearchConsoleAccountMutation(baseOptions?: Apollo.MutationHookOptions<ConnectGoogleSearchConsoleAccountMutation, ConnectGoogleSearchConsoleAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectGoogleSearchConsoleAccountMutation, ConnectGoogleSearchConsoleAccountMutationVariables>(ConnectGoogleSearchConsoleAccountDocument, options);
      }
export type ConnectGoogleSearchConsoleAccountMutationHookResult = ReturnType<typeof useConnectGoogleSearchConsoleAccountMutation>;
export type ConnectGoogleSearchConsoleAccountMutationResult = Apollo.MutationResult<ConnectGoogleSearchConsoleAccountMutation>;
export type ConnectGoogleSearchConsoleAccountMutationOptions = Apollo.BaseMutationOptions<ConnectGoogleSearchConsoleAccountMutation, ConnectGoogleSearchConsoleAccountMutationVariables>;