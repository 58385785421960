import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import useAccountId from '~/hooks/useAccountId';
import useAccountTariff from '~/hooks/useAccountTariff';

import {
	Tariff,
} from '~/model/pricing/tariffs';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';



const messages = {
	[Tariff.C1]: defineMessages({
		[GraphQL.AccountPlan.EnterpriseLite]: {
			id: 'ui.plans.enterpriseLite.name',
		},
		[GraphQL.AccountPlan.Enterprise]: {
			id: 'ui.plans.enterprise.name',
		},
	}),
	[Tariff.C2]: defineMessages({
		[GraphQL.AccountPlan.C2Enterprise]: {
			id: 'ui.plans.c2Enterprise.name',
		},
		[GraphQL.AccountPlan.C2Professional]: {
			id: 'ui.plans.c2Professional.name',
		},
		[GraphQL.AccountPlan.C2Starter]: {
			id: 'ui.plans.c2Starter.name',
		},
	}),
	[Tariff.V3]: defineMessages({
		[GraphQL.AccountPlan.Basic]: {
			id: 'ui.plans.basic.name',
		},
		[GraphQL.AccountPlan.Enterprise]: {
			id: 'ui.plans.enterprise.name',
		},
		[GraphQL.AccountPlan.Pro]: {
			id: 'ui.plans.standard.name',
		},
		[GraphQL.AccountPlan.Prov4]: {
			id: 'ui.plans.pro.name',
		},
		[GraphQL.AccountPlan.Standard]: {
			id: 'ui.plans.essentials.name',
		},
	}),
	fallback: defineMessages({
		[GraphQL.AccountPlan.Basic]: {
			id: 'ui.plans.basic.name',
		},
		[GraphQL.AccountPlan.Enterprise]: {
			id: 'ui.plans.enterprise.name',
		},
		[GraphQL.AccountPlan.Pro]: {
			id: 'ui.plans.pro.name',
		},
		[GraphQL.AccountPlan.Standard]: {
			id: 'ui.plans.standard.name',
		},
	}),
};



type Props = {
	plan: GraphQL.AccountPlan,
};

const PlanName: React.FC<Props> = (props) => {
	const {
		plan,
	} = props;


	const accountId = useAccountId();
	const accountTariff = useAccountTariff(accountId);

	if (accountTariff === null) {
		return null;
	}

	const message = messages[accountTariff]?.[plan] ?? messages.fallback[plan] ?? null;

	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default PlanName;
