import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import EnterSudoModeForm from '~/components/app/EnterSudoModeForm';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validatePassword,
} from '~/components/app/validatePassword';

import {
	validateField,
	validateFields,
} from '~/components/app/validations';

import {
	useChangePasswordMutation,
} from './ChangePasswordModal.gql';

import useCurrentUserHasPassword from '~/hooks/useCurrentUserHasPassword';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useRoyalMode from '~/hooks/useRoyalMode';
import useSudoModeStatus from '~/hooks/useSudoModeStatus';

import FormError from '~/utilities/FormError';

import {
	type Rule,
} from '~/utilities/validations';



const messages = defineMessages({
	changePasswordTitle: {
		id: 'ui.settings.changePassword',
	},
	chooseNewPasswordButton: {
		id: 'ui.general.chooseNewPasswordButton',
	},
	incorrectOldPasswordError: {
		id: 'ui.settings.form.incorrectOldPasswordError',
	},
	newPasswordLabel: {
		id: 'ui.settings.form.newPasswordLabel',
	},
	oldPasswordLabel: {
		id: 'ui.settings.form.oldPasswordLabel',
	},
	passwordAlreadyUsedInPast: {
		id: 'ui.settings.form.passwordAlreadyUsedInPast',
	},
	passwordsNotEqualError: {
		id: 'ui.formErrors.passwordsNotEqual',
	},
	saveNewPasswordButton: {
		id: 'ui.general.saveNewPasswordButton',
	},
	setPasswordTitle: {
		id: 'ui.settings.setPassword',
	},
	sudoModeDescription: {
		id: 'ui.changePasswordModal.sudoModeDescription',
	},
	verifyPasswordLabel: {
		id: 'ui.settings.form.verifyPasswordLabel',
	},
});



type Props = {
	closeCallback: () => void,
};

const ChangePasswordModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const userId = useCurrentUserId();

	const hasPassword = useCurrentUserHasPassword();
	const royalMode = useRoyalMode();
	const sudoModeStatus = useSudoModeStatus(GraphQL.MethodToEnterSudoMode.Password);

	const multiStepModalRef = React.useRef<MultiStepModalRef | null>(null);

	const [changePassword] = useChangePasswordMutation();

	const isLoading = (
		hasPassword === null
		|| sudoModeStatus.isLoading
	);

	const enableOldPasswordStep = (
		royalMode.isImpersonated === true
			? false
			: (hasPassword ?? false)
	);

	const handleSubmit = React.useCallback(
		async (values, { createError }) => {
			if (userId === null) {
				throw new Error(
					`userId can't be null in handleSubmit`,
				);
			}

			try {
				await changePassword({
					variables: {
						legacyUserId: userId,
						newPassword: values.newPassword,
						oldPassword: values.oldPassword,
					},
				});

				closeCallback();
			} catch (error) {
				const formError = FormError.fromApolloError(error);

				if (formError?.name === 'requiredSudoMode') {
					multiStepModalRef.current?.goToStep('enterSudoMode');
				} else if (formError?.name === 'incorrect_old_password') {
					throw createError('incorrectOldPassword', {
						value: values.oldPassword,
					});
				} else if (formError?.name === 'passwordAlreadyUsedInPast') {
					throw createError('passwordAlreadyUsedInPast', {
						value: values.newPassword,
					});
				} else {
					throw createError('unexpected-error');
				}
			}
		},
		[
			changePassword,
			closeCallback,
			userId,
		],
	);

	function renderTitle() {
		if (hasPassword) {
			return (
				<FormattedMessage {...messages.changePasswordTitle} />
			);
		}

		return (
			<FormattedMessage {...messages.setPasswordTitle} />
		);
	}

	const validations = React.useMemo(
		() => {
			const validations: Record<string, Array<Rule>> = {};

			if (enableOldPasswordStep) {
				validations['validateOldPassword'] = validateField(
					'oldPassword',
					(f) => ([
						f.validateNonEmpty(),
						f.validateMinimumLength(8),
						f.validateMaximumLength(64),
						f.customGlobal({
							message: (
								<FormattedMessage {...messages.incorrectOldPasswordError} />
							),
							globalRule: 'incorrectOldPassword',
						}),
					]),
				);
			}

			validations['validateNewPassword'] = validateField(
				'newPassword',
				(f) => [
					validatePassword(f),
					f.customGlobal({
						message: (
							<FormattedMessage {...messages.passwordAlreadyUsedInPast} />
						),
						globalRule: 'passwordAlreadyUsedInPast',
					}),
				],
			);

			validations['validateVerifyPassword'] = validateFields(
				['newPassword', 'verifyPassword'],
				{
					verifyPassword: (f) => ([
						f.whenOtherField(
							'newPassword',
							({ values }) => {
								return values['newPassword'].length >= 8 && values['newPassword'].length <= 64;
							},
						),
						f.custom({
							message: (
								<FormattedMessage {...messages.passwordsNotEqualError} />
							),
							rule: ({ values }) => {
								return values['newPassword'] === values['verifyPassword'];
							},
						}),
					]),
				},
			);

			return validations;
		},
		[
			enableOldPasswordStep,
		],
	);

	return (
		<MultiStepModal
			initialStep={(
				sudoModeStatus.suggestedMethodToEnter === GraphQL.MethodToEnterSudoMode.TwoFactorAuthentication ? 0 : 1
			)}
			key={isLoading ? 'loading' : 'ready'}
			minHeight={0}
			ref={multiStepModalRef}
		>
			<MultiStepModalStep
				name="enterSudoMode"
				title={renderTitle()}
			>
				{({ isActive }) => isActive && (
					<EnterSudoModeForm
						description={(
							<FormattedMessage
								{...messages.sudoModeDescription}
								values={{
									hasPassword: hasPassword ? 'yes' : 'no',
								}}
							/>
						)}
						disallowedMethod={GraphQL.MethodToEnterSudoMode.Password}
					/>
				)}
			</MultiStepModalStep>

			<MultiStepModalStep
				name="change-password"
				title={renderTitle()}
			>
				<CenteredFormWrapper>
					<Form
						defaultValues={{
							newPassword: '',
							oldPassword: '',
							verifyPassword: '',
						}}
						key={isLoading ? 'loading' : 'ready'}
						onSuccess={handleSubmit}
						validations={validations}
					>
						{({ errors }) => (
							<>
								<FormRows>
									{enableOldPasswordStep && (
										<FormRow
											htmlFor="oldPassword"
											label={(
												<FormattedMessage {...messages.oldPasswordLabel} />
											)}
										>
											<FieldStatus
												allowOk={false}
												name="validateOldPassword"
											>
												<TextField
													autoComplete={TextFieldAutocomplete.CurrentPassword}
													name="oldPassword"
													type={TextFieldType.Password}
												/>
											</FieldStatus>
										</FormRow>
									)}

									<FormRow
										htmlFor="newPassword"
										label={(
											<FormattedMessage {...messages.newPasswordLabel} />
										)}
									>
										<FieldStatus
											focusFieldErrorMessage="newPassword"
											name="validateNewPassword"
										>
											<TextField
												autoComplete={TextFieldAutocomplete.NewPassword}
												name="newPassword"
												type={TextFieldType.Password}
											/>
										</FieldStatus>
									</FormRow>

									<FormRow
										htmlFor="verifyPassword"
										label={(
											<FormattedMessage {...messages.verifyPasswordLabel} />
										)}
									>
										<FieldStatus
											allowOk={!errors['validateNewPassword']}
											focusFieldErrorMessage="verifyPassword"
											name="validateVerifyPassword"
										>
											<TextField
												name="verifyPassword"
												resetButton={false}
												type={TextFieldType.Password}
											/>
										</FieldStatus>
									</FormRow>
								</FormRows>

								<FormErrorMessages />

								<ButtonsLayout>
									<CancelButton />

									<SubmitButton>
										<FormattedMessage {...messages.saveNewPasswordButton} />
									</SubmitButton>
								</ButtonsLayout>
							</>
						)}
					</Form>
				</CenteredFormWrapper>
			</MultiStepModalStep>

		</MultiStepModal>

	);
};



export default ChangePasswordModal;
