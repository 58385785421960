import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	lastSelectedWebsiteIdSelector,
} from '~/state/ui/selectors';



function useSelectedWebsiteId(): CK.WebsiteId | null {
	const lastSelectedWebsiteId = useSelector(lastSelectedWebsiteIdSelector);
	const websiteId = useWebsiteId() as ReturnType<typeof useWebsiteId> | null;

	return websiteId ?? lastSelectedWebsiteId ?? null;
}



export default useSelectedWebsiteId;
