import classNames from 'classnames';
import React from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	Link,
} from 'react-router5';

import type CK from '~/types/contentking';

import {
	createFilterParameter as createCurrentIssuesFilterParameter,
} from '~/actions/issues';

import {
	createFilterParameter as createWebsiteIssuesComparisonFilterParameter,
} from '~/model/websiteIssuesComparison';

import {
	appEmptyStateSelector,
	issuesFilterSelector as currentWebsiteIssuesFilterSelector,
	selectedSectionSelector,
} from '~/state/ui/selectors';

import {
	dateFromSelector,
	dateToSelector,
	isActiveSelector as isComparisonModeActiveSelector,
	filterSelector as websiteIssuesComparisonFilterSelector,
} from '~/state/websiteIssuesComparison/selectors';

import {
	EMPTY_STATE_NO_WEBSITES,
} from '~/model/ui/appEmpty';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type ChildProps = {
	isActive: boolean,
	isDimmed: boolean,
};

type Props = {
	children: RenderProp<ChildProps>,
	className?: string,
	onClick?: () => void,
	websiteId: CK.WebsiteId | null,
};

const IssuesOverviewLink: React.FC<Props> = (props) => {
	const {
		children,
		className,
		onClick,
		websiteId,
	} = props;

	const appEmptyState = useSelector(appEmptyStateSelector);
	const currentIssuesFilter = useSelector(currentWebsiteIssuesFilterSelector);
	const dateFrom = useSelector(dateFromSelector);
	const dateTo = useSelector(dateToSelector);
	const isComparisonModeActive = useSelector(isComparisonModeActiveSelector);
	const selectedSection = useSelector(selectedSectionSelector);
	const websiteIssuesComparisonFilter = useSelector(websiteIssuesComparisonFilterSelector);

	const isActive = selectedSection === 'website.issues';

	function handleClick() {
		if (onClick) {
			onClick();
		}
	}

	if (websiteId === null || appEmptyState === EMPTY_STATE_NO_WEBSITES) {
		return (
			<Link routeName="home">
				{renderProp(
					children,
					{
						isActive: false,
						isDimmed: true,
					},
				)}
			</Link>
		);
	}

	const finalClassName = classNames({
		'active': isActive,
	}, className);

	return (
		<Link
			className={finalClassName}
			onClick={handleClick}
			routeName="website.issues"
			routeParams={{
				end_date: dateTo,
				filter: isComparisonModeActive
					? createWebsiteIssuesComparisonFilterParameter(websiteIssuesComparisonFilter)
					: createCurrentIssuesFilterParameter(currentIssuesFilter),
				start_date: dateFrom,
				websiteId,
			}}
		>
			{renderProp(
				children,
				{
					isActive,
					isDimmed: false,
				},
			)}
		</Link>
	);
};



export default IssuesOverviewLink;
