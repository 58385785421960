import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';
import {
	connect,
} from 'react-redux';

import ClearFiltersLink from '~/components/app/ClearFiltersLink';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';



class EmptyResultOverlay extends PureComponent {

	constructor(props, context) {
		super(props, context);

		this._resetFilter = this._resetFilter.bind(this);
	}



	_resetFilter() {
		const {
			dispatch,
			onResetFilterCallback,
			resetAction,
		} = this.props;

		if (onResetFilterCallback) {
			onResetFilterCallback();
		} else if (resetAction) {
			dispatch(resetAction());
		}
	}



	render() {
		const {
			filter,
			onResetFilterCallback,
			removeDefaultValues,
			resetAction,
		} = this.props;

		if (resetAction || onResetFilterCallback) {
			const activeFilters = removeDefaultValues(filter);

			return (
				<NoSearchResults
					additionalInfo={activeFilters.size > 0 && (
						<ClearFiltersLink onClickCallback={this._resetFilter} />
					)}
				/>
			);
		}

		return (
			<NoSearchResults />
		);
	}

}

EmptyResultOverlay.propTypes = {
	filter: PropTypes.object.isRequired,
	onResetFilterCallback: PropTypes.func,
	removeDefaultValues: PropTypes.func.isRequired,
	resetAction: PropTypes.func,
};



export default connect()(EmptyResultOverlay);
