import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import ChartLegend from '~/components/patterns/charts/components/ChartLegend';
import LargeChartLayout from '~/components/patterns/charts/layouts/LargeChartLayout';
import MultiColumnChart from './MultiColumnChart';

import {
	useHistoricalIssuesChartQuery,
} from './HistoricalIssuesChart.gql';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	formatDateForBackend,
	formatEndDateForBackend,
} from '~/model/historicalChanges';

import {
	dateFromSelector,
	dateToSelector,
	scopeSelector,
} from '~/state/websiteIssuesComparison/selectors';



const messages = defineMessages({
	legend: {
		opened: {
			id: 'ui.issuesOverview.charts.historicalIssuesChart.legend.openedIssues',
			defaultMessage: 'Opened',
		},
		resolved: {
			id: 'ui.issuesOverview.charts.historicalIssuesChart.legend.resolvedIssues',
			defaultMessage: 'Resolved',
		},
	},
	title: {
		id: 'ui.issuesOverview.charts.historicalIssuesChart.title',
	},
});



const CATEGORIES = [
	{
		name: 'opened',
		color: '#FF5959',
	},
	{
		name: 'resolved',
		color: '#42CC67',
	},
];



function useChartData({
	scope,
	since,
	until,
	websiteId,
}) {
	const {
		data,
		loading,
	} = useHistoricalIssuesChartQuery({
		variables: {
			since: formatDateForBackend(new Date(since)),
			until: formatEndDateForBackend(new Date(since), new Date(until)),
			websiteId,
		},
	});

	const intl = useIntl();

	return React.useMemo(
		() => {
			const aggregateHistoricalComparisons = data?.website?.aggregateHistoricalComparisons ?? null;

			if (loading || aggregateHistoricalComparisons === null) {
				return null;
			}

			const records = aggregateHistoricalComparisons.filter((comparison) => comparison.scope === (scope === 'website' ? scope : 'segment/' + scope));

			return {
				dataPoints: records.map((comparison) => ({
					opened: comparison.numberOfIssuesOpenedOnExistingPages + comparison.numberOfIssuesOpenedOnAddedPages,
					resolved: comparison.numberOfResolvedIssues,
				})),
				xAxisCaptions: records.map((comparison) => {
					if (comparison.since === comparison.until) {
						return intl.formatDate(comparison.since, {
							day: 'numeric',
							month: 'short',
						});
					}

					return intl.formatDate(comparison.since, {
						day: 'numeric',
						month: 'short',
					}) + ' -<br/>' + intl.formatDate(comparison.until, {
						day: 'numeric',
						month: 'short',
					});
				}),
			};
		},
		[
			data,
			intl,
			loading,
			scope,
		],
	);
}



const HistoricalIssuesChart = () => {
	const intl = useIntl();

	const dateFrom = useSelector(dateFromSelector);
	const dateTo = useSelector(dateToSelector);
	const scope = useSelector(scopeSelector);
	const websiteId = useWebsiteId();

	const chartData = useChartData({
		scope,
		since: dateFrom,
		until: dateTo,
		websiteId,
	});

	const renderTooltip = React.useCallback(
		({ count, category }) => {
			return intl.formatMessage(messages.legend[category]) + ': ' + intl.formatNumber(count);
		},
		[intl],
	);

	return (
		<LargeChartLayout
			details={(
				<ChartLegend
					items={CATEGORIES.map((category) => {
						return {
							color: category.color,
							label: (
								<FormattedMessage {...messages.legend[category.name]} />
							),
						};
					})}
					showNumberSign={false}
				/>
			)}
			label={(
				<FormattedMessage {...messages.title} />
			)}
			name="historical-issues"
			overlay={false}
		>
			{chartData !== null && (
				<MultiColumnChart
					categories={CATEGORIES}
					data={chartData.dataPoints}
					isDisabled={false}
					name="issue-changes"
					numberOfYAxisPoints={5}
					renderTooltip={renderTooltip}
					xAxisCaptions={chartData.xAxisCaptions}
				/>
			)}
		</LargeChartLayout>
	);
};



export default HistoricalIssuesChart;
