/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateExtraInvoiceDetailsInBillingTabMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  customerDetails: GraphQL.Scalars['Array']['input'];
}>;


export type UpdateExtraInvoiceDetailsInBillingTabMutation = { readonly __typename?: 'Mutation', readonly StoreCustomerDetails: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly billingDetails: { readonly __typename?: 'AccountBillingDetails', readonly extraInvoiceDetails: { readonly __typename?: 'BillingExtraInvoiceDetails', readonly attn: string | null, readonly customerReference: string | null, readonly purchaseOrderNumber: string | null, readonly supplierNumber: string | null } | null } | null } | null } } };


export const UpdateExtraInvoiceDetailsInBillingTabDocument = gql`
    mutation UpdateExtraInvoiceDetailsInBillingTab($accountId: AccountId!, $customerDetails: Array!) {
  StoreCustomerDetails(accountId: $accountId, customerDetails: $customerDetails) {
    query {
      account(id: $accountId) {
        id
        billingDetails {
          extraInvoiceDetails {
            attn
            customerReference
            purchaseOrderNumber
            supplierNumber
          }
        }
      }
    }
  }
}
    `;
export type UpdateExtraInvoiceDetailsInBillingTabMutationFn = Apollo.MutationFunction<UpdateExtraInvoiceDetailsInBillingTabMutation, UpdateExtraInvoiceDetailsInBillingTabMutationVariables>;

/**
 * __useUpdateExtraInvoiceDetailsInBillingTabMutation__
 *
 * To run a mutation, you first call `useUpdateExtraInvoiceDetailsInBillingTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtraInvoiceDetailsInBillingTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtraInvoiceDetailsInBillingTabMutation, { data, loading, error }] = useUpdateExtraInvoiceDetailsInBillingTabMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      customerDetails: // value for 'customerDetails'
 *   },
 * });
 */
export function useUpdateExtraInvoiceDetailsInBillingTabMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExtraInvoiceDetailsInBillingTabMutation, UpdateExtraInvoiceDetailsInBillingTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExtraInvoiceDetailsInBillingTabMutation, UpdateExtraInvoiceDetailsInBillingTabMutationVariables>(UpdateExtraInvoiceDetailsInBillingTabDocument, options);
      }
export type UpdateExtraInvoiceDetailsInBillingTabMutationHookResult = ReturnType<typeof useUpdateExtraInvoiceDetailsInBillingTabMutation>;
export type UpdateExtraInvoiceDetailsInBillingTabMutationResult = Apollo.MutationResult<UpdateExtraInvoiceDetailsInBillingTabMutation>;
export type UpdateExtraInvoiceDetailsInBillingTabMutationOptions = Apollo.BaseMutationOptions<UpdateExtraInvoiceDetailsInBillingTabMutation, UpdateExtraInvoiceDetailsInBillingTabMutationVariables>;