import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const StatsRisingIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M1.634,18.75a1.439,1.439,0,0,0,.989-.391L8.976,12.4a.48.48,0,0,1,.67.01l2.137,2.138a3.456,3.456,0,0,0,4.773,0l3.577-3.577a.24.24,0,0,1,.34,0l1.694,1.688a.965.965,0,0,0,.681.281.966.966,0,0,0,.965-.964V6.214a.965.965,0,0,0-.965-.964H17.063A.964.964,0,0,0,16.382,6.9l1.7,1.7a.243.243,0,0,1,.071.171.237.237,0,0,1-.071.17l-3.572,3.573a.485.485,0,0,1-.682,0l-2.138-2.138A3.359,3.359,0,0,0,7,10.294L.645,16.248a1.447,1.447,0,0,0,.989,2.5Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default StatsRisingIconBuilder;

