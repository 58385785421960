import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import ChartContainer from '../../atoms/charts/components/ChartContainer';
import ChartTooltip from '~/components/patterns/charts/components/ChartTooltip';
import GlobalChartOverlay, {
	GLOBAL_CHART_OVERLAY_NOT_ENOUGH_DATA,
} from './overlay/GlobalChartOverlay';
import HealthLabel from '~/components/patterns/structuredValues/HealthLabel';
import IntervalOverviewChart from '../../atoms/charts/charts/IntervalOverviewChart';
import LargeChartLayout from '~/components/patterns/charts/layouts/LargeChartLayout';
import NumberDiff, {
	NumberDiffImpact,
	NumberDiffSize,
} from '~/components/patterns/values/NumberDiff';
import UnchangedValue from '~/components/patterns/values/UnchangedValue';

import {
	useHistoricalWebsiteHealthChartQuery,
} from './HistoricalWebsiteHealthChart.gql';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	formatDateForBackend,
	formatEndDateForBackend,
} from '~/model/historicalChanges';

import {
	dateFromSelector,
	dateToSelector,
	scopeSelector,
} from '~/state/websiteIssuesComparison/selectors';



const messages = defineMessages({
	label: {
		id: 'ui.contentOverview.tableHeading.score',
	},
	noChange: {
		id: 'ui.issuesOverview.charts.historicalWebsiteHealthChart.noChange',
	},
	title: {
		id: 'ui.issuesOverview.charts.historicalWebsiteHealthChart.title',
	},
	tooltip: {
		id: 'ui.issuesOverview.charts.historicalWebsiteHealthChart.tooltip',
	},
});



function useChartData({
	scope,
	since,
	until,
	websiteId,
}) {
	const {
		data,
		loading,
	} = useHistoricalWebsiteHealthChartQuery({
		variables: {
			endDate: formatEndDateForBackend(new Date(since), new Date(until)),
			scope,
			startDate: formatDateForBackend(new Date(since)),
			websiteId,
		},
	});

	const intl = useIntl();

	return React.useMemo(
		() => {
			if (loading || !data || !data.website || !data.website.dashboardData.healthChanges.data) {
				return null;
			}

			if (data.website.dashboardData.healthChanges.data.length === 0) {
				return null;
			}

			const records = data.website.dashboardData.healthChanges.data;

			const dataPoints = records.map((item) => item.health);
			const xAxisCaptions = records.map((item) => intl.formatDate(item.date, {
				day: 'numeric',
				month: 'short',
			}));

			return {
				dataPoints,
				size: xAxisCaptions.length,
				xAxisCaptions,
			};
		},
		[
			data,
			intl,
			loading,
		],
	);
}



const Legend = (props) => {
	const {
		dataPoints,
	} = props;

	const value = dataPoints[0] !== null
		? dataPoints[dataPoints.length - 1] - dataPoints[0]
		: 0;

	if (value === 0) {
		return (
			<UnchangedValue>
				<FormattedMessage {...messages.noChange} />
			</UnchangedValue>
		);
	}

	let impact = NumberDiffImpact.Neutral;

	if (value < 0) {
		impact = NumberDiffImpact.Negative;
	}

	if (value > 0) {
		impact = NumberDiffImpact.Positive;
	}

	return (
		<NumberDiff
			diffValue={value}
			impact={impact}
			size={NumberDiffSize.Large}
		/>
	);
};

const HistoricalWebsiteHealthChart = () => {
	const intl = useIntl();

	const dateFrom = useSelector(dateFromSelector);
	const dateTo = useSelector(dateToSelector);
	const scope = useSelector(scopeSelector);
	const websiteId = useWebsiteId();

	const chartData = useChartData({
		scope: scope === 'website'
			? scope
			: 'segment/' + scope,
		since: dateFrom,
		until: dateTo,
		websiteId,
	});

	const renderTooltip = React.useCallback(
		(formatter) => {
			return ReactDOMServer.renderToString(
				<ChartTooltip color={formatter.color}>
					{intl.formatMessage(messages.tooltip, { value: formatter.y })}
				</ChartTooltip>,
			);
		},
		[intl],
	);

	return (
		<LargeChartLayout
			details={chartData !== null && chartData.size > 0 && (
				<Legend dataPoints={chartData.dataPoints} />
			)}
			label={(
				<HealthLabel>
					<FormattedMessage {...messages.title} />
				</HealthLabel>
			)}
			name="historical-health"
			overlay={chartData !== null && chartData.size === 0 && (
				<GlobalChartOverlay
					overlayStyle={GLOBAL_CHART_OVERLAY_NOT_ENOUGH_DATA}
				/>
			)}
		>
			{chartData !== null && (
				<ChartContainer
					chart={(
						<IntervalOverviewChart
							data={[
								{
									color: '#FE8719',
									data: chartData.dataPoints,
								},
							]}
							disabled={false}
							key="chart-health"
							label={(
								<FormattedMessage {...messages.title} />
							)}
							maximum={1000}
							minimum={0}
							renderTooltipCallback={renderTooltip}
							xAxisCaptions={chartData.xAxisCaptions}
						/>
					)}
					disabled={false}
					name="historical-health"
					padded={true}
					type="interval-overview-chart"
				/>
			)}
		</LargeChartLayout>
	);
};



export default HistoricalWebsiteHealthChart;
