import React from 'react';

import DatatableHeaderCell, {
	DatatableHeaderCellAlignment,
	DatatableHeaderCellColorStyle,
	DatatableHeaderCellPadding,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';



type Props = {
	alignment: DatatableHeaderCellAlignment | undefined,
	children?: React.ReactNode,
	columnIndex: number,
	columnName: string,
	colorStyle: DatatableHeaderCellColorStyle | undefined,
	height: number,
	isInHighlightedColumn: boolean,
	isSticky: boolean,
	isStriped: boolean,
	left: number,
	onMouseEnter: ((input: {
		columnIndex: number,
	}) => void) | undefined,
	onMouseLeave: ((input: {
		columnIndex: number,
	}) => void) | undefined,
	padding: DatatableHeaderCellPadding | undefined,
	width: number,
};

const HeaderCell: React.FC<Props> = (props) => {
	const {
		alignment,
		children,
		colorStyle,
		columnIndex,
		columnName,
		height,
		isInHighlightedColumn,
		isSticky,
		isStriped,
		left,
		onMouseEnter,
		onMouseLeave,
		padding,
		width,
	} = props;


	const handleMouseEnter = React.useCallback(
		() => {
			if (onMouseEnter) {
				onMouseEnter({ columnIndex });
			}
		},
		[
			columnIndex,
			onMouseEnter,
		],
	);

	const handleMouseLeave = React.useCallback(
		() => {
			if (onMouseLeave) {
				onMouseLeave({ columnIndex });
			}
		},
		[
			columnIndex,
			onMouseLeave,
		],
	);

	const cssStyle: React.CSSProperties = {
		position: 'absolute',
		height,
		width,
		left: 0,
		top: 0,
		transform: `translateX(${left}px)`,
	};

	return (
		<DatatableHeaderCell
			alignment={alignment}
			colorStyle={colorStyle}
			cssStyle={cssStyle}
			isHighlighted={isInHighlightedColumn}
			isSticky={isSticky}
			isStriped={isStriped}
			name={columnName}
			onMouseEnterCallback={handleMouseEnter}
			onMouseLeaveCallback={handleMouseLeave}
			padding={padding}
			separator={columnIndex !== 0}
			width={width}
		>
			{children}
		</DatatableHeaderCell>
	);
};



export default HeaderCell;

export {
	DatatableHeaderCellAlignment as HeaderCellAlignment,
	DatatableHeaderCellColorStyle as HeaderCellColorStyle,
	DatatableHeaderCellPadding as HeaderCellPadding,
};
