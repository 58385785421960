import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const VersionsIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => (
		<g fill="none">
			<path
				d="M10.6266 20.142H7.125V6.90527H17.7059V8.61378"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.2"
			/>
			<path
				d="M4.53711 17.6635V4.42676H15.1265"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.2"
			/>
			<path
				d="M2 15.2283V2H12.5894"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.2"
			/>
			<path
				d="M12.9612 16.251L11.2189 17.5112L9.9248 15.7266"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.2"
			/>
			<path
				d="M16.9785 13.249V16.8944L19.203 18.493"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.2"
			/>
			<path
				d="M11.3789 16.6575C11.3829 15.3917 11.8149 14.1646 12.6048 13.1755C13.3948 12.1865 14.496 11.4938 15.7296 11.2101C16.9631 10.9264 18.2564 11.0683 19.399 11.6129C20.5416 12.1574 21.4664 13.0725 22.023 14.2094C22.5795 15.3462 22.7351 16.6379 22.4644 17.8743C22.1937 19.1108 21.5126 20.2193 20.532 21.0196C19.5513 21.8199 18.3287 22.2649 17.0631 22.2821C15.7975 22.2994 14.5632 21.888 13.5611 21.1148"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.2"
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default VersionsIconBuilder;

