import React from 'react';

import DesktopPanelStack from './DesktopPanelStack';
import MobilePanelStack from './MobilePanelStack';

import useViewportType from '~/hooks/useViewportType';



const PanelStack = () => {
	const viewportType = useViewportType();

	const [animations, setAnimations] = React.useState(false);

	React.useEffect(
		() => {
			setAnimations(true);
		},
		[],
	);

	if (viewportType.isSmall) {
		return (
			<MobilePanelStack
				animations={animations}
			/>
		);
	}

	return (
		<DesktopPanelStack
			animations={animations}
		/>
	);
};



export default PanelStack;
