import React from 'react';

import usePrevious from '~/hooks/usePrevious';
import useUrlState from '~/hooks/useUrlState';

import {
	getRouter,
} from '~/routing/router';

import areObjectsEqualShallow from '~/utilities/areObjectsEqualShallow';



type Callback = (urlState: {
	name: string,
	params: Record<string, any>,
}) => Record<string, any> | undefined;



const useProjectUrlParams = (callback: Callback, deps): void => {
	const urlState = useUrlState();
	const previousUrlState = usePrevious(urlState);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	callback = React.useCallback(callback, deps);

	React.useEffect(
		() => {
			const updatedParams = callback({
				name: urlState.name,
				params: { ...urlState.params },
			});

			if (updatedParams === undefined) {
				return;
			}

			for (const param in updatedParams) {
				if (updatedParams[param] === false) {
					delete updatedParams[param];
				}
			}

			if (areObjectsEqualShallow(urlState.params, updatedParams)) {
				return;
			}

			const router = getRouter();
			router.navigate(
				urlState.name,
				updatedParams,
				{
					replace: previousUrlState !== urlState,
				},
			);
		},
		[
			callback,
			previousUrlState,
			urlState,
		],
	);
};



export default useProjectUrlParams;
