import Immutable from 'immutable';
import React from 'react';

import CK from '~/types/contentking';

import FormWrapperHeader from '~/components/patterns/forms/wrappers/FormWrapperHeader.part';
import PagesColumnsDatatable, {
	type PagesColumnsDatatableFixedColumnWidths,
} from '~/components/app/PagesColumnsDatatable';
import StickToScreenBottom, {
	StickToScreenBottomPreset,
} from '~/components/patterns/utils/StickToScreenBottom';

import useNonPages from '~/hooks/useNonPages';

import {
	removeDefaultFilterValues,
} from '~/model/pages';



const fixedColumnWidths: PagesColumnsDatatableFixedColumnWidths = {
	[CK.PagesCommonColumn.Url]: '100%',
};



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteDetailScreenNonPagesSection: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const [filter, setFilter] = React.useState(Immutable.Map({
		url_full: null,
	}));

	const [sortBy, setSortBy] = React.useState(Immutable.Map({
		key: 'url',
		direction: true,
	}));

	const nonPages = useNonPages({
		filter,
		limit: 1000,
		pollIntervalInMilliseconds: 30000,
		sortBy,
		websiteId,
	});

	const updateFilter = React.useCallback(
		(filterUpdate) => {
			setFilter((filter) => filter.merge(filterUpdate));
		},
		[],
	);

	const updateSortBy = React.useCallback(
		(sortBy) => {
			setSortBy((previousSortBy) => previousSortBy.merge(sortBy));
		},
		[],
	);

	const columns = React.useMemo(
		() => {
			return Immutable.List([
				CK.PagesCommonColumn.Url,
			]);
		},
		[],
	);

	return (
		<div
			className="new-table"
		>
			<FormWrapperHeader
				title="Non-pages"
			/>

			<StickToScreenBottom
				preset={StickToScreenBottomPreset.FullscreenDetail}
				presetOffset={1}
			>
				{({ height: containerHeight, width: containerWidth }) => (
					<PagesColumnsDatatable
						columns={columns}
						distributions={nonPages.distributions}
						filter={filter}
						fixedColumnWidths={fixedColumnWidths}
						height={containerHeight}
						isColumnWidthFixed={true}
						isCrawled={true}
						isLoading={nonPages.loading}
						isRowClickable={true}
						onFilterChangeCallback={updateFilter}
						onLoadPagesCallback={nonPages.fetchFurtherRange}
						onSortChangeCallback={updateSortBy}
						pagesCount={nonPages.total}
						removeDefaultFilterValues={removeDefaultFilterValues}
						resetFilterAction={() => {}}
						rowGetter={nonPages.rowGetter}
						segmentDefinitions={[]}
						sortBy={sortBy}
						websiteId={websiteId}
						width={containerWidth}
					/>
				)}
			</StickToScreenBottom>
		</div>
	);
};



export default WebsiteDetailScreenNonPagesSection;
