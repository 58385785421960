import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PauseIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="pause"
					transform="translate(3.000000, 3.000000)"
				>
					<path
						d="M9,0.3375 C4.2159375,0.3375 0.3375,4.2159375 0.3375,9 C0.3375,13.7840625 4.2159375,17.6625 9,17.6625 C13.7840625,17.6625 17.6625,13.7840625 17.6625,9 C17.6625,4.2159375 13.7840625,0.3375 9,0.3375 Z"
						fill={color}
						id="Stroke-302"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<polygon
						fill="#FAFBFC"
						id="Stroke-303"
						points="7.8195375 12.15 5.4570375 12.15 5.4570375 5.85 7.8195375 5.85"
						stroke="#FAFBFC"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</polygon>
					<polygon
						fill="#FAFBFC"
						id="Stroke-304"
						points="12.5445375 12.15 10.1820375 12.15 10.1820375 5.85 12.5445375 5.85"
						stroke="#FAFBFC"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</polygon>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default PauseIconBuilder;

