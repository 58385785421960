import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PagesIgnoringIconBuilder: IconBuilder = {
	icon: () => {
		return (
			<g>
				<defs>
					<path
						d="M0 0h24v28H0V0zm16 28a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
						id="a"
					/>
				</defs>
				<g
					fill="none"
					fillRule="evenodd"
					transform="translate(3)"
				>
					<path d="M20 24L0 24 0 0 20 0z" />
					<mask
						fill="#fff"
						id="b"
					>
						<use xlinkHref="#a" />
					</mask>
					<g
						mask="url(#b)"
						stroke="#8595A6"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<g transform="translate(1 1)">
							<path d="M0.333333333 21.7942918L0.333333333 3 15.3704584 3 15.3704584 21.7942918z" />
							<path d="M3 8.333h9.333H3zm0 4h9.333H3zm0 4h9.333H3z" />
							<path
								d="M3 2.79951793L3 0.333333333 18.3946464 0.333333333 18.3946464 19.5744765 15.5747595 19.5744765"
								strokeOpacity=".703"
							/>
						</g>
					</g>
					<g transform="translate(9 13)">
						<path
							d="M0 0H14V14H0z"
							fill="none"
						/>
						<circle
							cx="7"
							cy="7"
							fill="none"
							r="5.625"
							stroke="#8595A6"
						/>
						<path
							d="M2.664 2.87a.502.502 0 0 1 .705.005l7.804 7.805a.498.498 0 0 1 .005.704l.266-.266a.502.502 0 0 1-.704-.005L2.935 3.308a.498.498 0 0 1-.005-.704l-.266.266z"
							fill="#8595A6"
						/>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 28,
	viewBoxWidth: 28,
};



export default PagesIgnoringIconBuilder;
