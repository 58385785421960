import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import ExpressiveValue, {
	ExpressiveValueStatus,
} from '~/components/patterns/values/ExpressiveValue';
import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import List from '~/components/patterns/lists/List';
import RobotsTxtDiffButton from '../RobotsTxtDiffButton';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';



const messages = defineMessages({
	flapping: {
		id: 'ui.alerts.overview.incident.details.robotsTxtFlapping',
	},
	linesAdded: {
		id: 'ui.alerts.overview.incident.details.linesAdded',
	},
	linesRemoved: {
		id: 'ui.alerts.overview.incident.details.linesRemoved',
	},
	reverted: {
		id: 'ui.alerts.overview.incident.details.allChangesReverted',
	},
});



class RobotsTxtChangedDetails extends Component {

	_renderDiff({
		isIncidentActive,
		linesAdded,
		linesRemoved,
	}) {
		if (linesAdded > 0 || linesRemoved > 0) {
			return (
				<List>
					{linesAdded > 0 && (
						<ExpressiveValue
							label={(
								<FormattedMessage
									{...messages.linesAdded}
									values={{
										count__lines: linesAdded,
									}}
								/>
							)}
							status={isIncidentActive ? ExpressiveValueStatus.Warning : ExpressiveValueStatus.Neutral}
							value={(
								<FormattedNumber value={linesAdded} />
							)}
						/>
					)}

					{linesRemoved > 0 && (
						<ExpressiveValue
							label={(
								<FormattedMessage
									{...messages.linesRemoved}
									values={{
										count__lines: linesRemoved,
									}}
								/>
							)}
							status={isIncidentActive ? ExpressiveValueStatus.Warning : ExpressiveValueStatus.Neutral}
							value={(
								<FormattedNumber value={linesRemoved} />
							)}
						/>
					)}
				</List>
			);
		}

		return (
			<SeparatedBlocks>
				<FormattedMessage {...messages.reverted} />
			</SeparatedBlocks>
		);
	}



	render() {
		const {
			incident,
			websiteId,
		} = this.props;

		const isIncidentActive = incident.get('phase') === 'active';

		const isModern = incident.hasIn(['state', 'lines_added']);

		const linesAdded = incident.getIn(['state', 'lines_added']);
		const linesRemoved = incident.getIn(['state', 'lines_removed']);

		const isFlapping = isIncidentActive && incident.getIn(['state', 'is_flapping']);

		if (isFlapping) {
			return (
				<IncidentBoxContent
					button={(
						<RobotsTxtDiffButton
							nextRevision={incident.getIn(['state', 'flapping_revision_next'])}
							previousRevision={incident.getIn(['state', 'flapping_revision_previous'])}
							websiteId={websiteId}
						/>
					)}
				>
					<SeparatedBlocks>
						<FormattedMessage {...messages.flapping} />
					</SeparatedBlocks>

					{this._renderDiff({
						isIncidentActive,
						linesAdded: incident.getIn(['state', 'flapping_lines_added']),
						linesRemoved: incident.getIn(['state', 'flapping_lines_removed']),
					})}
				</IncidentBoxContent>
			);
		}

		return (
			<IncidentBoxContent
				button={(isIncidentActive || (linesAdded > 0 || linesRemoved > 0)) && (
					<RobotsTxtDiffButton
						nextRevision={incident.getIn(['state', 'revision_next'])}
						previousRevision={incident.getIn(['state', 'revision_previous'])}
						websiteId={websiteId}
					/>
				)}
			>
				{isModern && this._renderDiff({
					isIncidentActive,
					linesAdded,
					linesRemoved,
				})}
			</IncidentBoxContent>
		);
	}

}

RobotsTxtChangedDetails.propTypes = {
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string,
};



export default RobotsTxtChangedDetails;
