import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MusicIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M21.5,2.25A2.25,2.25,0,0,0,18.4.168L9.4,3.85A2.241,2.241,0,0,0,8,5.932V15.75a.25.25,0,0,1-.25.25H6.5a4,4,0,0,0,0,8,4.1,4.1,0,0,0,4-4V10.758a.248.248,0,0,1,.155-.231l8-3.273A.25.25,0,0,1,19,7.485v4.27a.25.25,0,0,1-.25.25H17.5a4,4,0,1,0,0,8,4.1,4.1,0,0,0,4-4Zm-10.655,5.5a.25.25,0,0,1-.234-.024.247.247,0,0,1-.111-.207V6.436a.5.5,0,0,1,.311-.463l7.5-3.069A.5.5,0,0,1,19,3.367v.876a.249.249,0,0,1-.155.231Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MusicIconBuilder;
