import React from 'react';
import {
	useSelector,
} from 'react-redux';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useIsIntercomEnabled from '~/hooks/useIsIntercomEnabled';
import useUserMainAccountId from '~/hooks/useUserMainAccountId';
import useViewportType from '~/hooks/useViewportType';

import {
	APP_VERSION,
	CONTENTKING_ENVIRONMENT,
} from '~/config';

import {
	userInfoSelector,
} from '~/state/authentication/selectors';

import {
	Intercom,
} from '~/globals';



const IntercomComponent: React.FC = () => {
	const currentUserId = useCurrentUserId();

	const isIntercomEnabled = useIsIntercomEnabled();
	const userInfo = useSelector(userInfoSelector);
	const userMainAccountId = useUserMainAccountId(currentUserId);
	const viewportType = useViewportType();

	const isViewportSmall = viewportType.isSmall;

	React.useEffect(
		() => {
			if (isIntercomEnabled === false) {
				return;
			}

			Intercom()('boot', {
				'app_id': 'kl8vlrob',
				'custom_launcher_selector': '#intercom-activator',
				'hide_default_launcher': isViewportSmall,
				'Application Version': APP_VERSION,
				'Intercom Blocked': false,
			});

			return () => {
				Intercom()('shutdown');
			};
		},
		[
			isIntercomEnabled,
			isViewportSmall,
		],
	);

	React.useEffect(
		() => {
			if (isIntercomEnabled === false) {
				return;
			}

			Intercom()('update', {
				'language_override': userInfo.get('locale').split('-')[0],
				'user_hash': userInfo.get('user_hash'),
				'user_id': userInfo.get('intercom_user_id'),
				'company': {
					id: CONTENTKING_ENVIRONMENT + '-' + userMainAccountId,
				},
			});
		},
		[
			isIntercomEnabled,
			userInfo,
			userMainAccountId,
		],
	);

	return <>{null}</>;
};



export default IntercomComponent;
