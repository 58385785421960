import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import CK from '~/types/contentking';

import BooleanFormatter from '~/components/app/BooleanFormatter';
import CanonicalTypeName from '~/components/names/CanonicalTypeName';
import ExpandableFilterField, {
	OPERATOR_STARTS_WITH,
} from '~/components/atoms/forms/components/ExpandableFilterField';
import InputRangeField, {
	InputRangeFieldDirection,
} from '~/components/atoms/forms/components/InputRangeField';
import IsDisallowedFormatter from '~/components/logic/formatters/IsDisallowedFormatter';
import MultiselectField, {
	MultiselectFieldSize,
} from '~/components/atoms/forms/components/MultiselectField';
import NumberFilterField, {
	NumberFilterFieldMode,
	NumberFilterFieldOperator,
	NumberFilterFieldSize,
} from '~/components/atoms/forms/components/NumberFilterField';
import PageTypeName from '~/components/names/PageTypeName';
import SegmentMultiselectField, {
	SegmentMultiselectFieldDropdownAttachment,
} from '~/components/atoms/forms/components/SegmentMultiselectField';
import TextField, {
	TextFieldSize,
} from '~/components/atoms/forms/components/TextField';

import useGetFilterType from '~/hooks/useGetFilterType';

import {
	CANONICAL_TYPE_CROSSDOMAIN,
	CANONICAL_TYPE_GROUP_INTERNAL,
	CANONICAL_TYPE_INTERNAL_OTHER,
	CANONICAL_TYPE_INTERNAL_SELF,
	CANONICAL_TYPE_INVALID,
	CANONICAL_TYPE_NONE,
	CANONICAL_TYPE_NOT_APPLICABLE,
	TYPE_MISSING,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	TYPE_UNREACHABLE,
} from '~/model/pages';

import {
	FILTER_TYPE_BOOLEAN,
	FILTER_TYPE_BOOLEAN_OR_NA,
	FILTER_TYPE_CUSTOM_DAYS_AGO,
	FILTER_TYPE_CUSTOM_NUMBER,
	FILTER_TYPE_CUSTOM_STRING,
	FILTER_TYPE_DAYS_AGO,
	FILTER_TYPE_MILLISECONDS,
	FILTER_TYPE_MONTHS,
	FILTER_TYPE_NUMBER,
	FILTER_TYPE_PERCENTAGE,
	FILTER_TYPE_PSEUDO_ENUM,
	FILTER_TYPE_SECONDS,
	FILTER_TYPE_STRING,
	FILTER_TYPE_STRING_NON_NULLABLE,
} from '~/model/pagesColumnsFiltering';

import {
	type SegmentDefinition,
} from '~/model/segments';

import {
	getValueByScoreCondition,
} from '~/utilities/scores';



const messages = defineMessages({
	all: {
		id: 'ui.multiselect.all',
	},
	indexableNo: {
		id: 'ui.contentOverview.indexable.no',
	},
	indexableYes: {
		id: 'ui.contentOverview.indexable.yes',
	},
	multiple: {
		id: 'ui.multiselect.multiple',
	},
	textFieldPlaceholder: {
		id: 'ui.contentOverview.tableFilter.placeholder',
	},
});

const changeTypeMessages = defineMessages({
	added: {
		id: 'ui.contentOverview.changeTracking.changeType.added',
	},
	changed: {
		id: 'ui.contentOverview.changeTracking.changeType.changed',
	},
	other: {
		id: 'ui.contentOverview.changeTracking.changeType.other',
	},
	redirected: {
		id: 'ui.contentOverview.changeTracking.changeType.redirected',
	},
	removed: {
		id: 'ui.contentOverview.changeTracking.changeType.removed',
	},
});

const changesInMessages = defineMessages({
	changed_in_all_selected_columns: {
		id: 'ui.contentOverview.changeTracking.changeType.changesIn.inAllSelectedColumns',
	},
	changed_in_any_column: {
		id: 'ui.contentOverview.changeTracking.changeType.changesIn.inAnyColumn',
	},
	changed_in_any_selected_column: {
		id: 'ui.contentOverview.changeTracking.changeType.changesIn.inAnySelectedColumn',
	},
	changed_in_any_visible_column: {
		id: 'ui.contentOverview.changeTracking.changeType.changesIn.inAnyVisibleColumn',
	},
});

export type ColumnFilterRef = {
	changeValue: (value: any) => void,
};

const emptyArray = [];



type Props = {
	columnName: CK.PagesColumn,
	columnWidth: number,
	isAtRightEdge?: boolean,
	segmentDefinitions?: ReadonlyArray<SegmentDefinition>,
	segmentsNotAllowedForFiltering?: Record<string, React.ReactNode>,
};

const ColumnFilter = React.forwardRef<ColumnFilterRef, Props>((props, ref) => {
	const {
		columnName,
		columnWidth,
		isAtRightEdge = false,
		segmentDefinitions = emptyArray,
		segmentsNotAllowedForFiltering,
	} = props;

	const getFilterType = useGetFilterType();
	const intl = useIntl();

	const fieldRef = React.useRef<any>();

	const filterType = getFilterType(columnName);

	React.useImperativeHandle(
		ref,
		() => ({
			changeValue: (value) => {
				if (!fieldRef.current) {
					return;
				}

				if (filterType === FILTER_TYPE_PSEUDO_ENUM) {
					value = value || '';
				}

				let field = fieldRef.current;

				if (columnName === CK.PagesCommonColumn.Segments) {
					field.changeValue(value, false);
				} else {
					if (field.getWrappedInstance) {
						field = field.getWrappedInstance();
					}

					field.changeValue(value);
				}
			},
		}),
		[
			columnName,
			filterType,
		],
	);

	function renderMultiselectFilter({ dropdownWidth, options, selectedLabelRenderer }: {
		dropdownWidth?: number,
		options: ReadonlyArray<any>,
		selectedLabelRenderer?: (selectedOptions: any) => React.ReactNode,
	}) {
		const additionalAttributes: Partial<React.ComponentProps<typeof MultiselectField>> = {};

		if (isAtRightEdge) {
			additionalAttributes.dropdownAttachment = SegmentMultiselectFieldDropdownAttachment.Right;
		}

		if (!dropdownWidth || dropdownWidth < columnWidth) {
			dropdownWidth = columnWidth;
		}

		return (
			<MultiselectField
				dropdownWidth={dropdownWidth}
				isOnlyLinkVisible={true}
				name={columnName}
				options={options}
				ref={fieldRef}
				selectedLabelRenderer={selectedLabelRenderer}
				size={MultiselectFieldSize.Small}
				width={columnWidth}
				{...additionalAttributes}
			/>
		);
	}

	function renderNumericFilter({ dropdownWidth, mode, placeholder }: {
		dropdownWidth?: number,
		mode?: NumberFilterFieldMode,
		placeholder?: string,
	} = {}) {
		if (!placeholder) {
			placeholder = intl.formatMessage(messages.textFieldPlaceholder).toLowerCase();
		}

		if (!dropdownWidth || dropdownWidth < columnWidth) {
			dropdownWidth = columnWidth;
		}

		return (
			<NumberFilterField
				defaultOption={NumberFilterFieldOperator.Any}
				dropdownWidth={dropdownWidth}
				maxLength={1000}
				mode={mode}
				name={columnName}
				placeholder={placeholder}
				ref={fieldRef}
				size={NumberFilterFieldSize.Small}
				width={columnWidth}
			/>
		);
	}

	function renderExpandableFilter({ defaultOperator, disabledOperators, placeholder }: {
		defaultOperator?: string,
		disabledOperators?: ReadonlyArray<string>,
		placeholder?: string,
	} = {}) {
		if (!placeholder) {
			placeholder = intl.formatMessage(messages.textFieldPlaceholder).toLowerCase();
		}

		return (
			<ExpandableFilterField
				defaultOperator={defaultOperator || undefined}
				disabledOperators={disabledOperators || undefined}
				maxLength={1000}
				name={columnName}
				placeholder={placeholder}
				ref={fieldRef}
				size={TextFieldSize.Small}
				width={columnWidth}
			/>
		);
	}

	function renderRangeFilter({ direction, highlightColorFactory, max, min, step }: {
		direction: InputRangeFieldDirection,
		highlightColorFactory: (value: number) => string,
		max: number,
		min: number,
		step: number,
	}) {
		return (
			<InputRangeField
				direction={direction}
				highlightColorFactory={highlightColorFactory}
				max={max}
				min={min}
				name={columnName}
				ref={fieldRef}
				step={step}
				width={columnWidth}
			/>
		);
	}

	function renderSegmentsFilter() {
		const additionalAttributes: Partial<React.ComponentProps<typeof SegmentMultiselectField>> = {};

		if (isAtRightEdge) {
			additionalAttributes.dropdownAttachment = SegmentMultiselectFieldDropdownAttachment.Right;
		}

		return (
			<SegmentMultiselectField
				name={columnName}
				placeholder={intl.formatMessage(messages.textFieldPlaceholder).toLowerCase()}
				ref={fieldRef}
				segments={segmentDefinitions}
				segmentsNotAllowedForFiltering={segmentsNotAllowedForFiltering}
				width={columnWidth}
				{...additionalAttributes}
			/>
		);
	}

	function renderSimpleTextFilter({ placeholder }: {
		placeholder?: string,
	} = {}) {
		if (!placeholder) {
			placeholder = intl.formatMessage(messages.textFieldPlaceholder).toLowerCase();
		}

		return (
			<TextField
				attributes={{
					maxLength: 1000,
				}}
				name={columnName}
				placeholder={placeholder}
				ref={fieldRef}
				size={TextFieldSize.Small}
				width={columnWidth}
			/>
		);
	}

	if (filterType === FILTER_TYPE_PSEUDO_ENUM) {
		return renderSimpleTextFilter();
	}

	if (columnName === CK.PagesCommonColumn.CanonicalType) {
		return renderMultiselectFilter({
			options: [
				{
					name: CANONICAL_TYPE_GROUP_INTERNAL,
					title: (
						<CanonicalTypeName
							canonicalType={CANONICAL_TYPE_GROUP_INTERNAL}
							useFullVersion={false}
						/>
					),
					children: [
						{
							name: 'self',
							title: (
								<CanonicalTypeName
									canonicalType={CANONICAL_TYPE_INTERNAL_SELF}
									useFullVersion={false}
								/>
							),
						},
						{
							name: 'other',
							title: (
								<CanonicalTypeName
									canonicalType={CANONICAL_TYPE_INTERNAL_OTHER}
									useFullVersion={false}
								/>
							),
						},
					],
				},
				{
					name: CANONICAL_TYPE_CROSSDOMAIN,
					title: (
						<CanonicalTypeName
							canonicalType={CANONICAL_TYPE_CROSSDOMAIN}
							useFullVersion={false}
						/>
					),
				},
				{
					name: CANONICAL_TYPE_INVALID,
					title: (
						<CanonicalTypeName
							canonicalType={CANONICAL_TYPE_INVALID}
							useFullVersion={false}
						/>
					),
				},
				{
					name: CANONICAL_TYPE_NONE,
					title: (
						<CanonicalTypeName
							canonicalType={CANONICAL_TYPE_NONE}
							useFullVersion={false}
						/>
					),
				},
				{
					name: CANONICAL_TYPE_NOT_APPLICABLE,
					title: (
						<CanonicalTypeName
							canonicalType={CANONICAL_TYPE_NOT_APPLICABLE}
							useFullVersion={false}
						/>
					),
				},
			],
			dropdownWidth: 210,
		});
	}

	if (filterType === FILTER_TYPE_STRING) {
		return renderExpandableFilter();
	}

	if (columnName === 'change_type') {
		return renderMultiselectFilter({
			options: [
				{
					name: 'changed',
					title: intl.formatMessage(changeTypeMessages.changed),
					children: [
						{
							name: 'in_any_column',
							title: intl.formatMessage(changesInMessages.changed_in_any_column),
						},
						{
							name: 'in_any_visible_column',
							title: intl.formatMessage(changesInMessages.changed_in_any_visible_column),
						},
						{
							divider: true,
						},
						{
							name: 'in_any_selected_column',
							title: intl.formatMessage(changesInMessages.changed_in_any_selected_column),
						},
						{
							name: 'in_all_selected_columns',
							title: intl.formatMessage(changesInMessages.changed_in_all_selected_columns),
						},
					],
					onlyOneChildSelectable: true,
				},
				{
					name: 'added',
					title: intl.formatMessage(changeTypeMessages.added),
				},
				{
					name: 'removed',
					title: intl.formatMessage(changeTypeMessages.removed),
				},
				{
					name: 'redirected',
					title: intl.formatMessage(changeTypeMessages.redirected),
				},
				{
					name: 'other',
					title: intl.formatMessage(changeTypeMessages.other),
				},
			],
			selectedLabelRenderer: ({ selectedOptions }) => {
				if (selectedOptions.length === 0) {
					return '-';
				}

				if (selectedOptions.length === 1 && selectedOptions[0].indexOf('changed_in') === 0) {
					return (
						<>
							<FormattedMessage {...changeTypeMessages.changed} />
							&nbsp;
							<FormattedMessage {...changesInMessages[selectedOptions[0]]} />
						</>
					);
				}

				if (selectedOptions.length === 1) {
					return (
						<FormattedMessage {...changeTypeMessages[selectedOptions[0]]} />
					);
				}

				if (selectedOptions.length === 5) {
					return (
						<FormattedMessage {...messages.all} />
					);
				}

				return (
					<FormattedMessage {...messages.multiple} />
				);
			},
			dropdownWidth: 210,
		});
	}

	if (filterType === FILTER_TYPE_STRING_NON_NULLABLE) {
		return renderExpandableFilter({
			disabledOperators: [
				'empty',
				'empty_or_missing',
				'missing',
				'present',
			],
		});
	}

	if (filterType === FILTER_TYPE_DAYS_AGO) {
		return renderNumericFilter({
			dropdownWidth: 235,
			mode: NumberFilterFieldMode.DaysAgo,
		});
	}

	if (filterType === FILTER_TYPE_SECONDS) {
		return renderNumericFilter({
			dropdownWidth: 235,
			mode: NumberFilterFieldMode.Seconds,
		});
	}

	if (filterType === FILTER_TYPE_MILLISECONDS) {
		return renderNumericFilter({
			dropdownWidth: 235,
			mode: NumberFilterFieldMode.Milliseconds,
		});
	}

	if (filterType === FILTER_TYPE_MONTHS) {
		return renderNumericFilter({
			dropdownWidth: 235,
			mode: NumberFilterFieldMode.Months,
		});
	}

	if (filterType === FILTER_TYPE_PERCENTAGE) {
		return renderNumericFilter({
			mode: NumberFilterFieldMode.Percent,
		});
	}

	if (filterType === FILTER_TYPE_NUMBER) {
		return renderNumericFilter();
	}

	if (columnName === CK.PagesCommonColumn.Health) {
		return renderRangeFilter({
			highlightColorFactory: (value) => {
				return getValueByScoreCondition(
					value,
					1000,
					{
						good: '#42cc67',
						fine: '#ffb131',
						normal: '#ff9830',
						worse: '#ff7437',
						bad: '#ff5959',
					},
				);
			},
			direction: InputRangeFieldDirection.LesserOrEqual,
			max: 1000,
			min: 0,
			step: 1,
		});
	}

	if (columnName === CK.PagesCommonColumn.IsDisallowedInRobotsTxt) {
		return renderMultiselectFilter({
			options: [
				{
					name: 'false',
					title: (
						<IsDisallowedFormatter value={false} />
					),
				},
				{
					name: 'true',
					title: (
						<IsDisallowedFormatter value={true} />
					),
				},
			],
			dropdownWidth: 160,
		});
	}

	if (filterType === FILTER_TYPE_BOOLEAN) {
		return renderMultiselectFilter({
			options: [
				{
					name: 'true',
					title: (
						<BooleanFormatter value={true} />
					),
				},
				{
					name: 'false',
					title: (
						<BooleanFormatter value={false} />
					),
				},
			],
			dropdownWidth: 160,
		});
	}

	if (filterType === FILTER_TYPE_BOOLEAN_OR_NA) {
		return renderMultiselectFilter({
			options: [
				{
					name: 'yes',
					title: intl.formatMessage(messages.indexableYes),
				},
				{
					name: 'no',
					title: intl.formatMessage(messages.indexableNo),
				},
			],
			dropdownWidth: 160,
		});
	}

	if (columnName === CK.PagesCommonColumn.Relevance) {
		return renderRangeFilter({
			direction: InputRangeFieldDirection.GreaterOrEqual,
			highlightColorFactory: (value) => getValueByScoreCondition(value),
			max: 10,
			min: 0,
			step: 0.1,
		});
	}

	if (columnName === CK.PagesCommonColumn.Segments) {
		return renderSegmentsFilter();
	}

	if (columnName === CK.PagesCommonColumn.StatusCode) {
		return renderExpandableFilter({
			defaultOperator: OPERATOR_STARTS_WITH,
		});
	}

	if (columnName === CK.PagesCommonColumn.Type) {
		return renderMultiselectFilter({
			options: [
				{
					name: TYPE_PAGE,
					suffix: '(2xx)',
					title: (
						<PageTypeName pageType={TYPE_PAGE} />
					),
				},
				{
					name: TYPE_REDIRECT,
					suffix: '(3xx)',
					title: (
						<PageTypeName pageType={TYPE_REDIRECT} />
					),
				},
				{
					name: TYPE_MISSING,
					suffix: '(4xx)',
					title: (
						<PageTypeName pageType={TYPE_MISSING} />
					),
				},
				{
					name: TYPE_SERVER_ERROR,
					suffix: '(5xx)',
					title: (
						<PageTypeName pageType={TYPE_SERVER_ERROR} />
					),
				},
				{
					name: TYPE_UNREACHABLE,
					title: (
						<PageTypeName pageType={TYPE_UNREACHABLE} />
					),
				},
			],
			dropdownWidth: 215,
		});
	}

	if (filterType === FILTER_TYPE_CUSTOM_DAYS_AGO) {
		return renderNumericFilter({
			dropdownWidth: 235,
			mode: NumberFilterFieldMode.DaysAgo,
		});
	}

	if (filterType === FILTER_TYPE_CUSTOM_NUMBER) {
		return renderNumericFilter();
	}

	if (filterType === FILTER_TYPE_CUSTOM_STRING) {
		return renderExpandableFilter();
	}

	return null;
});



export default ColumnFilter;
