import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import IssueCategoriesList from '~/components/app/IssueCategoriesList';
import NoIssuesNotification from '~/components/atoms/pageDetail/components/NoIssuesNotification';

import useNavigation from '~/hooks/useNavigation';
import usePageIsPristine from '~/hooks/usePageIsPristine';
import usePageIssueCategories from '~/hooks/usePageIssueCategories';
import usePageStatusCode from '~/hooks/usePageStatusCode';
import usePageType from '~/hooks/usePageType';
import useUrlState from '~/hooks/useUrlState';

import {
	type IssueCategoryName,
} from '~/model/issuesNew';

import {
	getAbsoluteCategoryType,
	getCategoryUrlIdentifier,
} from '~/model/issues/identifierMapping';



type Props = {
	legacyUrlId: number,
	numberOfPlaceholders: number,
	returnRouteName: string,
	showActionable: boolean,
	websiteId: CK.WebsiteId,
};

const PageIssueCategoriesList: React.FC<Props> = (props) => {
	const {
		legacyUrlId,
		numberOfPlaceholders,
		returnRouteName,
		showActionable,
		websiteId,
	} = props;

	const navigation = useNavigation();
	const pageIsPristine = usePageIsPristine(legacyUrlId, websiteId);
	const pageIssueCategories = usePageIssueCategories(legacyUrlId, websiteId);
	const pageStatusCode = usePageStatusCode(legacyUrlId, websiteId);
	const pageType = usePageType(legacyUrlId, websiteId);
	const urlState = useUrlState();

	const openedIssueCategoryName = getAbsoluteCategoryType(
		urlState.params.pageDetailIssuesCategoryType,
	);

	const handleIssueCategoryClose = React.useCallback(
		() => {
			navigation.navigate({
				routeName: returnRouteName,
				routeParams: {
					id: legacyUrlId,
					websiteId,
				},
			});
		},
		[
			legacyUrlId,
			navigation,
			returnRouteName,
			websiteId,
		],
	);

	const handleIssueCategoryOpen = React.useCallback(
		(issueCategoryName: IssueCategoryName) => {
			navigation.navigate({
				routeName: 'website.pages.detail.issuesCategory',
				routeParams: {
					id: legacyUrlId,
					pageDetailIssuesCategoryType: getCategoryUrlIdentifier(issueCategoryName),
					websiteId,
				},
			});
		},
		[
			legacyUrlId,
			navigation,
			websiteId,
		],
	);

	if (
		pageType === null
		|| pageType === GraphQL.PageType.OverQuota
	) {
		return null;
	}

	if (pageType !== GraphQL.PageType.Page) {
		return (
			<NoIssuesNotification
				pageIsPristine={pageIsPristine}
				pageStatusCode={pageStatusCode}
				pageType={pageType}
			/>
		);
	}

	return (
		<IssueCategoriesList
			issueCategories={pageIssueCategories}
			numberOfPlaceholders={numberOfPlaceholders}
			onIssueCategoryClose={handleIssueCategoryClose}
			onIssueCategoryOpen={handleIssueCategoryOpen}
			openedIssueCategoryName={openedIssueCategoryName}
			showActionable={showActionable}
		/>
	);
};



export default PageIssueCategoriesList;
