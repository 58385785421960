import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import List from '~/components/patterns/lists/List';
import TextPreloader from '~/components/patterns/loaders/TextPreloader';



class IssuesDetailLayout extends Component {

	render() {
		const {
			actionElements,
			headerOnMouseEnterCallback,
			headerOnMouseLeaveCallback,
			children,
			icon,
			illustration,
			isLoading,
			title,
		} = this.props;

		if (isLoading) {
			return (
				<div className="issues-detail-layout">
					<div className="issues-detail-layout__preloader">
						<TextPreloader />
					</div>
				</div>
			);
		}

		return (
			<div className="issues-detail-layout">
				<div className="issues-detail-layout__body">
					<div
						className="issues-detail-layout__header"
						onMouseEnter={headerOnMouseEnterCallback}
						onMouseLeave={headerOnMouseLeaveCallback}
					>
						<div className="issues-detail-layout__header-top">
							{icon && (
								<span className="issues-detail-layout__icon">
									{icon}
								</span>
							)}
							<h3 className="issues-detail-layout__title">
								{title}
							</h3>
							{actionElements && (
								<div className="issues-detail-layout__action-elements">
									{actionElements}
								</div>
							)}
						</div>

						{illustration && (
							<div className="issues-detail-layout__illustration">
								{illustration}
							</div>
						)}
					</div>
					<div className="issues-detail-layout__content">
						<List>
							{children}
						</List>
					</div>
				</div>
			</div>
		);
	}

}

IssuesDetailLayout.propTypes = {
	/** Action elements for whole detail */
	actionElements: PropTypes.node,
	/** on mouse hover callback over header part */
	headerOnMouseEnterCallback: PropTypes.func,
	/** on mouse leave callback over header part */
	headerOnMouseLeaveCallback: PropTypes.func,
	/** Detail icon */
	icon: PropTypes.node,
	/** Illustration describing current situation */
	illustration: PropTypes.node,
	/** Show preloader instead of content */
	isLoading: PropTypes.bool,
	/** Main title of whole detail */
	title: PropTypes.node,
};



export default IssuesDetailLayout;
