import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import LabeledValue, {
	LabeledValueSize,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';



const messages = defineMessages({
	health: {
		id: 'ui.multidashboard.segmentsTable.header.health',
		defaultMessage: 'Health',
	},
	issues: {
		id: 'ui.multidashboard.segmentsTable.header.issues',
		defaultMessage: 'Issues',
	},
	pages: {
		id: 'ui.multidashboard.segmentsTable.header.pages',
		defaultMessage: 'Pages',
	},
});



const SKELETON_HEIGHT = 36;



const WebsiteStatisticsSkeletonBlock: React.FC = () => {
	return (
		<SeparatedBlocks>
			<LabeledValue
				label={(
					<FormattedMessage {...messages.health} />
				)}
				size={LabeledValueSize.Small}
			>
				<SquareSkeleton
					height={SKELETON_HEIGHT}
					style={SquareSkeletonStyle.Transparent}
				/>
			</LabeledValue>
			<LabeledValue
				label={(
					<FormattedMessage {...messages.pages} />
				)}
				size={LabeledValueSize.Small}
			>
				<SquareSkeleton
					height={SKELETON_HEIGHT}
					style={SquareSkeletonStyle.Transparent}
				/>
			</LabeledValue>
			<LabeledValue
				label={(
					<FormattedMessage {...messages.issues} />
				)}
				size={LabeledValueSize.Small}
			>
				<SquareSkeleton
					height={SKELETON_HEIGHT}
					style={SquareSkeletonStyle.Transparent}
				/>
			</LabeledValue>
		</SeparatedBlocks>
	);
};



export default WebsiteStatisticsSkeletonBlock;
