import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import HeaderTitleValue, {
	STYLE_NUMBER,
} from './HeaderTitleValue';



class HeaderTitleNumericValue extends Component {

	render() {
		const {
			value,
		} = this.props;

		return (
			<HeaderTitleValue
				style={STYLE_NUMBER}
				value={(
					<FormattedNumber value={value} />
				)}
			/>
		);
	}

}

HeaderTitleNumericValue.propTypes = {
	value: PropTypes.number.isRequired,
};



export default HeaderTitleNumericValue;
