import classNames from 'classnames';
import React from 'react';

import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';



type Props = {
	children: React.ReactNode,
	/** Flag whether we are showing inner nested group */
	isInner?: boolean,
	/** Additional link prepared for showing hidden options */
	showMoreLink?: {
		label: React.ReactNode,
		onClickCallback: (e: React.MouseEvent) => void,
		onMouseEnter?: (e: React.MouseEvent) => void,
		onMouseLeave?: (e: React.MouseEvent) => void,
	},
	/** Custom width of component */
	width?: React.CSSProperties['width'],
};



const FieldDropdownOptionsGroup = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		children,
		isInner,
		showMoreLink,
		width,
	} = props;

	const componentClasses = classNames({
		'field-dropdown-options-group': true,
		'field-dropdown-options-group--inner': isInner,
	});

	return (
		<div
			className={componentClasses}
			ref={ref}
			style={{
				width,
			}}
		>
			{children}

			{showMoreLink && (
				<div
					className="field-dropdown-options-group__show-more-link"
					onClick={showMoreLink.onClickCallback}
					onMouseEnter={showMoreLink.onMouseEnter}
					onMouseLeave={showMoreLink.onMouseLeave}
				>
					<InternalLink
						onClickCallback={showMoreLink.onClickCallback}
						style={InternalLinkStyle.Decent}
					>
						{showMoreLink.label}
					</InternalLink>
				</div>
			)}
		</div>
	);
});



export default FieldDropdownOptionsGroup;
