/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportSegmentsMutationVariables = GraphQL.Exact<{
  changeCriteriaInstructions: ReadonlyArray<GraphQL.ImportSegmentsChangeCriteriaInstructionInput> | GraphQL.ImportSegmentsChangeCriteriaInstructionInput;
  changeIdentifierInstructions: ReadonlyArray<GraphQL.ImportSegmentsChangeIdentifierInstructionInput> | GraphQL.ImportSegmentsChangeIdentifierInstructionInput;
  createInstructions: ReadonlyArray<GraphQL.ImportSegmentsCreateInstructionInput> | GraphQL.ImportSegmentsCreateInstructionInput;
  sourceWebsiteId: GraphQL.Scalars['WebsiteId']['input'];
  targetWebsiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ImportSegmentsMutation = { readonly __typename?: 'Mutation', readonly ImportSegments: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null }> } | null } } };


export const ImportSegmentsDocument = gql`
    mutation ImportSegments($changeCriteriaInstructions: [ImportSegmentsChangeCriteriaInstructionInput!]!, $changeIdentifierInstructions: [ImportSegmentsChangeIdentifierInstructionInput!]!, $createInstructions: [ImportSegmentsCreateInstructionInput!]!, $sourceWebsiteId: WebsiteId!, $targetWebsiteId: WebsiteId!) {
  ImportSegments(
    changeCriteriaInstructions: $changeCriteriaInstructions
    changeIdentifierInstructions: $changeIdentifierInstructions
    createInstructions: $createInstructions
    sourceWebsiteId: $sourceWebsiteId
    targetWebsiteId: $targetWebsiteId
  ) {
    query {
      website(id: $targetWebsiteId) {
        id
        pageSegments {
          color
          filterDefinition
          icon {
            files {
              scaleFactor
              url
            }
            name
          }
          id
          isBeingReevaluated
          isManaged
          label
          name
          shortcode
          sizeLimit
        }
      }
    }
  }
}
    `;
export type ImportSegmentsMutationFn = Apollo.MutationFunction<ImportSegmentsMutation, ImportSegmentsMutationVariables>;

/**
 * __useImportSegmentsMutation__
 *
 * To run a mutation, you first call `useImportSegmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSegmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSegmentsMutation, { data, loading, error }] = useImportSegmentsMutation({
 *   variables: {
 *      changeCriteriaInstructions: // value for 'changeCriteriaInstructions'
 *      changeIdentifierInstructions: // value for 'changeIdentifierInstructions'
 *      createInstructions: // value for 'createInstructions'
 *      sourceWebsiteId: // value for 'sourceWebsiteId'
 *      targetWebsiteId: // value for 'targetWebsiteId'
 *   },
 * });
 */
export function useImportSegmentsMutation(baseOptions?: Apollo.MutationHookOptions<ImportSegmentsMutation, ImportSegmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSegmentsMutation, ImportSegmentsMutationVariables>(ImportSegmentsDocument, options);
      }
export type ImportSegmentsMutationHookResult = ReturnType<typeof useImportSegmentsMutation>;
export type ImportSegmentsMutationResult = Apollo.MutationResult<ImportSegmentsMutation>;
export type ImportSegmentsMutationOptions = Apollo.BaseMutationOptions<ImportSegmentsMutation, ImportSegmentsMutationVariables>;