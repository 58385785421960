import {
	createSelector,
} from 'reselect';

import {
	createFilter,
} from '~/model/affectedPages';



const selector = (state) => state.get('affectedPages');

export const categorySelector = (state) => selector(state).get('category');

export const dataSelector = (state) => selector(state).get('data');

export const filterSelector = (state) => selector(state).get('filter');

export const loadingSelector = (state) => selector(state).get('loading');

export const sortBySelector = (state) => selector(state).get('sortBy');

export const singleTypeSelector = (state) => selector(state).get('type');



export const currentFilterSelector = createSelector(
	filterSelector,
	categorySelector,
	singleTypeSelector,
	(filter, issueCategory, issueType) => {
		return createFilter(filter, issueCategory, issueType);
	},
);
