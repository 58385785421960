import flattenChildren from 'react-keyed-flatten-children';
import React from 'react';

import DisabledContent from '~/components/patterns/content/DisabledContent';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import UnifiedHeightGroup from '~/components/patterns/utils/UnifiedHeightGroup';

import {
	isFunction,
	isNumber,
	notEmpty,
} from '~/utilities/typeCheck';



export enum MultiStepModalNavigation {
	Free = 'free',
	Linear = 'linear',
	Rigid = 'rigid',
}



const navigationModels = {
	[MultiStepModalNavigation.Free]: (): boolean => true,
	[MultiStepModalNavigation.Linear]: ({ furthestStep, targetStep }): boolean => {
		return targetStep <= furthestStep;
	},
	[MultiStepModalNavigation.Rigid]: (): boolean => false,
};



export type Step = {
	breadcrumb?: React.ReactNode,
	headerIconType?: React.ReactNode,
	name: string,
	title?: React.ReactNode,
};

type MultiStepModalContextType = {
	activeStep: number,
	breadcrumbs: Array<{
		active: boolean,
		label: React.ReactNode,
	}>,
	fullheight?: boolean,
	goToNextStep: () => void,
	goToPreviousStep: () => void,
	goToStep: (name: string | number) => void,
	headerRef: React.MutableRefObject<HTMLDivElement | null>,
	minHeight?: number,
	numberOfSteps: number,
	preloader?: React.ReactNode,
	registerStep: (step: Step, index: number) => () => void,
};

export const MultiStepModalContext = React.createContext<MultiStepModalContextType | null>(null);

export const MultiStepModalStepIndex = React.createContext<number>(-1);



export type MultiStepModalRef = {
	goToNextStep: () => void,
	goToPreviousStep: () => void,
	goToStep: (target: string | number) => void,
	isStepActive: (target: string | number) => boolean,
};

type Props = {
	children?: React.ReactNode,
	disabledContentOverlay?: React.ReactNode,
	flashMessage?: React.ReactNode,
	fullheight?: boolean,
	initialStep?: number,
	isContentDisabled?: (
		| ((input: { activeStep: number }) => boolean)
		| boolean
	),
	minHeight?: number,
	navigationModel?: MultiStepModalNavigation,
	preloader?: React.ReactNode,
};



const MultiStepModal = React.forwardRef<any, Props>((props, ref: React.Ref<MultiStepModalRef>) => {
	const {
		children,
		disabledContentOverlay,
		flashMessage,
		fullheight = false,
		initialStep = 0,
		minHeight = 500,
		navigationModel = MultiStepModalNavigation.Rigid,
		preloader,
	} = props;


	const [steps, setSteps] = React.useState<Array<Step>>([]);

	const [activeStep, setActiveStep] = React.useState<number>(initialStep);
	const [furthestStep, setFurthestStep] = React.useState<number>(initialStep);

	const isStepAccessible = navigationModels[navigationModel];
	const isContentDisabled = (
		isFunction(props.isContentDisabled)
			? props.isContentDisabled({ activeStep })
			: props.isContentDisabled
	) ?? false;

	const registerStep = React.useCallback(
		(step: Step, index: number) => {
			setSteps((steps) => ([
				...steps.slice(0, index),
				step,
				...steps.slice(index),
			]));

			const unregisterStep = (): void => {
				setSteps((steps) => {
					const index = steps.indexOf(step);

					if (index !== -1) {
						const nextSteps = [
							...steps.slice(0, index),
							...steps.slice(index + 1),
						];

						// Ensure we don't move forward when unmounting steps
						setActiveStep((activeStep) => {
							if (activeStep >= index) {
								return activeStep - 1;
							}

							return activeStep;
						});

						setFurthestStep((furthestStep) => {
							if (furthestStep >= index) {
								return furthestStep - 1;
							}

							return furthestStep;
						});

						return nextSteps;
					}

					return steps;
				});
			};

			return unregisterStep;
		},
		[],
	);

	const goToStep = React.useCallback(
		(target: string | number) => {
			let index: number;

			if (isNumber(target)) {
				index = target;
			} else {
				index = steps.findIndex((step) => step.name === target);
			}

			if (index === -1) {
				return;
			}

			setActiveStep(index);

			setFurthestStep((furthestStep) => Math.max(index, furthestStep));
		},
		[
			steps,
		],
	);

	const goToNextStep = React.useCallback(
		() => {
			goToStep(Math.min(activeStep + 1, steps.length - 1));
		},
		[
			activeStep,
			goToStep,
			steps.length,
		],
	);

	const goToPreviousStep = React.useCallback(
		() => {
			goToStep(Math.max(activeStep - 1, 0));
		},
		[
			activeStep,
			goToStep,
		],
	);

	React.useImperativeHandle(ref, () => ({
		goToNextStep,
		goToPreviousStep,
		goToStep,
		isStepActive: (target: number | string): boolean => {
			if (isNumber(target)) {
				return activeStep === target;
			}

			return steps.findIndex((step) => step.name === target) === activeStep;
		},
	}));

	const breadcrumbs = steps
		.filter((step) => !!step.breadcrumb)
		.map((step) => {
			const index = steps.findIndex(({ name }) => name === step.name);
			let label = step.breadcrumb;

			if (
				step.name !== steps[activeStep]?.name
				&& !isContentDisabled
				&& isStepAccessible({ furthestStep, targetStep: index })
			) {
				label = (
					<InternalLink
						onClickCallback={() => goToStep(step.name)}
						style={InternalLinkStyle.Decent}
					>
						{step.breadcrumb}
					</InternalLink>
				);
			}

			return {
				active: !isContentDisabled && activeStep === index,
				label,
			};
		});

	const step: Step | null = steps[activeStep] ?? null;
	const numberOfSteps = steps.length;

	const headerRef = React.useRef<HTMLDivElement | null>(null);

	const multiStepModalContext = React.useMemo(
		() => ({
			activeStep,
			breadcrumbs,
			fullheight,
			goToNextStep,
			goToPreviousStep,
			goToStep,
			headerRef,
			minHeight,
			numberOfSteps,
			preloader,
			registerStep,
		}),
		[
			activeStep,
			breadcrumbs,
			fullheight,
			goToNextStep,
			goToPreviousStep,
			goToStep,
			headerRef,
			minHeight,
			numberOfSteps,
			preloader,
			registerStep,
		],
	);

	return (
		<MultiStepModalContext.Provider value={multiStepModalContext}>
			<ModalContainer
				flashMessage={flashMessage}
				gapsSize={0}
				header={(
					<div ref={headerRef} />
				)}
				headerGapsSize={step !== null && step.headerIconType ? 1 : 2}
			>
				<DisabledContent
					disabledContent={isContentDisabled}
					disabledOverlay={disabledContentOverlay}
				>
					<UnifiedHeightGroup>
						<div
							className="multi-step-modal"
						>
							{flattenChildren(children)
								.filter(notEmpty)
								.map((child, index) => (
									<div
										className="multi-step-modal__step"
										key={React.isValidElement(child) ? child.key : index}
									>
										<MultiStepModalStepIndex.Provider value={index}>
											{child}
										</MultiStepModalStepIndex.Provider>
									</div>
								))
							}
						</div>
					</UnifiedHeightGroup>
				</DisabledContent>
			</ModalContainer>
		</MultiStepModalContext.Provider>
	);
});



export default MultiStepModal;
