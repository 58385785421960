import React from 'react';



type Props = {
	/** Content of the page */
	children: React.ReactNode,
};



const PageContent = React.forwardRef<HTMLDivElement, Props>(
	(props, ref) => {
		const {
			children,
		} = props;

		return (
			<article
				className="page-content"
				role="main"
			>
				<div
					className="page-content__scrollable-area js-scrollable"
					id="js-page-scrollable-area"
					ref={ref}
				>
					{children}
				</div>
			</article>
		);
	},
);



export default PageContent;
