import {
	useSelector,
} from 'react-redux';
import {
	type State,
} from 'router5/dist/types/base';

import {
	urlStateSelector,
} from '~/state/ui/selectors';



function useUrlState(): State {
	return useSelector(urlStateSelector);
}



export default useUrlState;
