import React from 'react';
import {
	useSelector,
} from 'react-redux';

import useUrlState from '~/hooks/useUrlState';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	issuesOverviewCategorySelector,
} from '~/state/ui/content/selectors';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	children: RenderProp<{
		linkRouteName: string,
		linkRouteParams: Record<string, any>,
	}>,
};

const WebsiteIssueConfigurationLinkRoute: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const issuesOverviewCategory = useSelector(issuesOverviewCategorySelector);
	const urlState = useUrlState();
	const websiteId = useWebsiteId();

	return (
		<>
			{renderProp(children, {
				linkRouteName: 'website.issues.issueCategory.configuration',
				linkRouteParams: {
					issuesOverviewCategory: issuesOverviewCategory || urlState.params.pageDetailIssuesCategoryType,
					websiteId,
				},
			})}
		</>
	);
};



export default WebsiteIssueConfigurationLinkRoute;
