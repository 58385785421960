import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import DisplayPart from '../basis/DisplayPart';
import EditableFormWrapper from '../basis/EditableFormWrapper';
import EditablePart from '../basis/EditablePart';
import Form from '../basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '../basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import GlobalFormMessage from '../basis/GlobalFormMessage';
import PaymentAuthorizationContext from '../../../logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '../../../logic/PaymentAuthorizationError';
import PaymentCancelButton from '../../../logic/PaymentCancelButton';
import PaymentMethodDetails from '../../../logic/PaymentMethodDetails';
import PaymentMethodFields from '../sharedFields/PaymentMethodFields';
import PaymentSubmitButton from '../../../logic/PaymentSubmitButton';
import PaymentTypeList from '../../../logic/formFields/PaymentTypeList';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	METHOD_CARD,
	METHOD_INVOICE,
	METHOD_PAYPAL,
} from '~/model/payments';

import RecurlyException from '~/recurly/Exception';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	continueToPaypalButton: {
		id: 'ui.paypal.button',
	},
	formErrors: {
		blank: {
			id: 'ui.formErrors.blank',
		},
		invalidCreditCardNumber: {
			id: 'ui.formErrors.invalidCreditCardNumber',
		},
		invalidCVC: {
			id: 'ui.formErrors.invalidCVC',
		},
		invalidYearAndMonth: {
			id: 'ui.formErrors.invalidYearAndMonth',
		},
		serverError: {
			id: 'ui.general.serverError',
		},
		unknownError: {
			id: 'ui.general.unknownError',
		},
	},
	paymentMethodTitle: {
		id: 'ui.teamDetail.billing.paymentMethodTitle',
	},
	saveButton: {
		id: 'ui.general.saveButton',
	},
});

const validations = {
	cvc: [
		{
			message: (
				<FormattedMessage {...messages.formErrors.invalidCVC} />
			),
			field: 'cvv',
			rule: ({ values }) => {
				// skip this validation when entering PayPal data
				if (values.paymentMethod !== METHOD_CARD) {
					return true;
				}

				if ('cvv' in values && values.cvv instanceof Object) {
					return values.cvv.isValid;
				}

				return false;
			},
		},
	],
	name: [
		{
			message: (
				<FormattedMessage {...messages.formErrors.blank} />
			),
			fields: ['first_name', 'last_name'],
			rule: ({ values }) => {
				// skip this validation when entering PayPal data
				if (values.paymentMethod !== METHOD_CARD) {
					return true;
				}

				return !!values.first_name && !!values.last_name;
			},
		},
	],
	number: [
		{
			message: (
				<FormattedMessage {...messages.formErrors.invalidCreditCardNumber} />
			),
			field: 'number',
			rule: ({ values, name }) => {
				// skip this validation when entering PayPal data
				if (values.paymentMethod !== METHOD_CARD) {
					return true;
				}

				if (values[name] && values[name] instanceof Object) {
					return values[name].isValid;
				}

				return false;
			},
		},
	],
	year_and_month: [
		{
			message: (
				<FormattedMessage {...messages.formErrors.invalidYearAndMonth} />
			),
			fields: ['year', 'month'],
			rule: ({ values }) => {
				// skip this validation when entering PayPal data
				if (values.paymentMethod !== METHOD_CARD) {
					return true;
				}

				if (values.month && values.month instanceof Object && values.year && values.year instanceof Object) {
					return values.month.isValid && values.year.isValid;
				}

				return false;
			},
		},
	],
	paymentMethod: [
		{
			message: '',
			field: 'paymentMethod',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
};



class PaymentMethodForm extends Component {

	constructor(props, context) {
		super(props, context);

		this.formRef = React.createRef();

		this._handleBillingInfoTokenization = this._handleBillingInfoTokenization.bind(this);
		this._handleFormChange = this._handleFormChange.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
		this._handleSubmitError = this._handleSubmitError.bind(this);

		this.state = {
			globalErrorMessageKey: '',
			isPaymentMethodAcquired: false,
		};
	}



	_handleSubmit({ billingInfo, threeDSecureToken, values }) {
		const {
			submitWithCreditCardCallback,
			submitWithInvoiceCallback,
			submitWithPaypalCallback,
		} = this.props;

		const methods = {
			[METHOD_CARD]: submitWithCreditCardCallback,
			[METHOD_INVOICE]: submitWithInvoiceCallback,
			[METHOD_PAYPAL]: submitWithPaypalCallback,
		};

		const methodCallback = methods[values.paymentMethod];

		billingInfo = Object.assign({}, billingInfo, {
			threeDSecureToken,
		});

		return methodCallback({
			model: values,
			values: billingInfo,
		});
	}



	_handleBillingInfoTokenization(values) {
		return this._paymentMethodFields.getValues(values)
			.then((billingInfo) => {
				this.setState({
					isPaymentMethodAcquired: true,
				});

				return billingInfo;
			});
	}



	_handleSubmitError(error) {
		if (error instanceof FormError) {
			return error;
		} else if (error instanceof RecurlyException) {
			this.setState({
				globalErrorMessageKey: error.intlMessageKey,
			});

			return new FormError('invalid-input');
		}

		throw error;
	}



	_renderOverview() {
		const {
			billingDetails,
		} = this.props;

		return (
			<FormRows>
				<PaymentMethodDetails
					billingDetails={billingDetails}
				/>
			</FormRows>
		);
	}



	_handleFormChange(field, value) {
		if (field === 'paypal' && value) {
			this.formRef.current.submit();
		}
	}



	_renderForm() {
		const {
			allowedPaymentMethods,
			billingDetails,
		} = this.props;

		const {
			globalErrorMessageKey,
			isPaymentMethodAcquired,
		} = this.state;

		return (
			<PaymentAuthorizationContext
				onBillingInfoTokenization={this._handleBillingInfoTokenization}
				onError={this._handleSubmitError}
				onSubmit={this._handleSubmit}
				ref={this.formRef}
				useModal={true}
			>
				{({ formRef, handleFormSuccess, isAuthorizing }) => (
					<Form
						defaultValues={{
							paymentMethod: '',
						}}
						onChangeCallback={this._handleFormChange}
						onSuccess={handleFormSuccess}
						ref={formRef}
						validations={validations}
					>
						{({ isSubmitting, values }) => {
							return (
								<>
									<FormRows>
										<FormRow indentationSize={2}>
											<PaymentTypeList
												disabledTypes={(isSubmitting || isAuthorizing) ? allowedPaymentMethods : []}
												name="paymentMethod"
												onChangeCallback={() => {
													this.setState({
														isPaymentMethodAcquired: false,
													});
												}}
												paymentMethod={values.paymentMethod}
												supportedTypes={allowedPaymentMethods}
											/>
										</FormRow>

										<PaymentMethodFields
											billingDetails={billingDetails}
											isPaymentMethodAcquired={isPaymentMethodAcquired}
											paymentMethod={values.paymentMethod}
											ref={(ref) => this._paymentMethodFields = ref}
										/>
									</FormRows>

									<GlobalFormMessage name="invalid-input">
										<FormattedMessage {...globalErrorMessageKey} />
									</GlobalFormMessage>

									<GlobalFormMessage name="timeout">
										<FormattedMessage {...messages.formErrors.unknownError} />
									</GlobalFormMessage>

									<GlobalFormMessage name="server-error">
										<FormattedMessage {...messages.formErrors.serverError} />
									</GlobalFormMessage>

									<PaymentAuthorizationError />

									<ButtonsLayout>
										<PaymentCancelButton />

										<PaymentSubmitButton>
											<FormattedMessage {...messages[values.paymentMethod === 'paypal' ? 'continueToPaypalButton' : 'saveButton']} />
										</PaymentSubmitButton>
									</ButtonsLayout>
								</>
							);
						}}
					</Form>
				)}
			</PaymentAuthorizationContext>
		);
	}



	render() {
		const {
			accountId,
			onChangeCallback,
		} = this.props;

		return (
			<WhenAccountActionAllowed
				accountId={accountId}
				action={GraphQL.ActionWithAccount.ManageBilling}
				showMessage={false}
			>
				{({ isAllowed }) => (
					<EditableFormWrapper
						isAllowed={isAllowed}
						key={accountId}
						onEnterEditModeCallback={onChangeCallback}
						title={(
							<FormattedMessage {...messages.paymentMethodTitle} />
						)}
					>
						<DisplayPart>
							{this._renderOverview()}
						</DisplayPart>

						<EditablePart>
							{this._renderForm()}
						</EditablePart>
					</EditableFormWrapper>
				)}
			</WhenAccountActionAllowed>
		);
	}

}

PaymentMethodForm.propTypes = {
	accountId: PropTypes.number,
	billingDetails: PropTypes.object,
	submitWithCreditCardCallback: PropTypes.func.isRequired,
	submitWithInvoiceCallback: PropTypes.func.isRequired,
	submitWithPaypalCallback: PropTypes.func.isRequired,
};



export default PaymentMethodForm;
