/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthenticatedSessionQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AuthenticatedSessionQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly isImpersonated: boolean, readonly keepAlive: boolean, readonly kingdomAdmin: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string } | null, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string } } | null };


export const AuthenticatedSessionDocument = gql`
    query AuthenticatedSession {
  authenticatedSession {
    isImpersonated
    keepAlive
    kingdomAdmin {
      email
      id
      legacyId
    }
    user {
      email
      id
      legacyId
    }
  }
}
    `;

/**
 * __useAuthenticatedSessionQuery__
 *
 * To run a query within a React component, call `useAuthenticatedSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticatedSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticatedSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticatedSessionQuery(baseOptions?: Apollo.QueryHookOptions<AuthenticatedSessionQuery, AuthenticatedSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthenticatedSessionQuery, AuthenticatedSessionQueryVariables>(AuthenticatedSessionDocument, options);
      }
export function useAuthenticatedSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthenticatedSessionQuery, AuthenticatedSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthenticatedSessionQuery, AuthenticatedSessionQueryVariables>(AuthenticatedSessionDocument, options);
        }
export function useAuthenticatedSessionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AuthenticatedSessionQuery, AuthenticatedSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AuthenticatedSessionQuery, AuthenticatedSessionQueryVariables>(AuthenticatedSessionDocument, options);
        }
export type AuthenticatedSessionQueryHookResult = ReturnType<typeof useAuthenticatedSessionQuery>;
export type AuthenticatedSessionLazyQueryHookResult = ReturnType<typeof useAuthenticatedSessionLazyQuery>;
export type AuthenticatedSessionSuspenseQueryHookResult = ReturnType<typeof useAuthenticatedSessionSuspenseQuery>;
export type AuthenticatedSessionQueryResult = Apollo.QueryResult<AuthenticatedSessionQuery, AuthenticatedSessionQueryVariables>;