import React from 'react';

import {
	ContextMenuContext,
} from '~/components/ContextMenuProvider';

import {
	MissingContextProviderError,
} from '~/errors';



const useContextMenu = () => {
	const contextMenu = React.useContext(ContextMenuContext);

	if (contextMenu === null) {
		throw new MissingContextProviderError('ContextMenuContext');
	}

	return contextMenu;
};



export default useContextMenu;
