import {
	differenceInCalendarDays,
} from 'date-fns';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import {
	DateRangePreset,
	listApplicableRanges,
} from '~/model/dateRangePresets';



const messages = defineMessages({
	[DateRangePreset.Custom]: {
		id: 'ui.dateRange.ranges.custom',
	},
	[DateRangePreset.Everything]: {
		id: 'ui.dateRange.ranges.everything',
	},
	[DateRangePreset.Last14Days]: {
		id: 'ui.dateRange.ranges.last14Days',
	},
	[DateRangePreset.Last4Weeks]: {
		id: 'ui.dateRange.ranges.last4Weeks',
	},
	[DateRangePreset.Last7Days]: {
		id: 'ui.dateRange.ranges.last7Days',
	},
	[DateRangePreset.PreviousWeek]: {
		id: 'ui.dateRange.ranges.previousWeek',
	},
	[DateRangePreset.ThisWeek]: {
		id: 'ui.dateRange.ranges.thisWeek',
	},
	[DateRangePreset.Today]: {
		id: 'ui.dateRange.ranges.today',
	},
	[DateRangePreset.Yesterday]: {
		id: 'ui.dateRange.ranges.yesterday',
	},
});



type DateRangeOption = {
	disabled: boolean,
	label: string,
	name: DateRangePreset,
	value: DateRangePreset,
};



function usePredefinedDateRanges(
	websiteCreatedAt: Date,
	excludeRanges?: Array<DateRangePreset>,
): Array<DateRangeOption> {
	const intl = useIntl();

	const result: Array<DateRangeOption> = [];

	listApplicableRanges(websiteCreatedAt, excludeRanges)
		.forEach((range) => {
			const message = messages[range];

			result.push({
				disabled: false,
				label: intl.formatMessage(
					message,
					range === DateRangePreset.Last7Days
						? {
							count__days: Math.min(
								7,
								differenceInCalendarDays(
									new Date(),
									websiteCreatedAt,
								) + 1,
							),
						}
						: {},
				),
				name: range,
				value: range,
			});
		});

	result.push({
		disabled: false,
		label: intl.formatMessage(messages[DateRangePreset.Custom]),
		name: DateRangePreset.Custom,
		value: DateRangePreset.Custom,
	});

	return result;
}



export default usePredefinedDateRanges;
