import memoize from 'memoizee';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import {
	FormContext,
} from '../../atoms/forms/basis/Form';
import Measurer from '~/utilities/Measurer';
import SelectableRowsTable from '../datatables/SelectableRowsTable';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';



const describeValue = (
	disabledGetter,
	items,
	value,
	valueGetter,
) => {
	let itemValues = items.map((item) => {
		return valueGetter
			? valueGetter(item)
			: item;
	});

	if (itemValues.toArray) {
		itemValues = itemValues.toArray();
	}

	const getItem = (rowIndex) => {
		return items.get
			? items.get(rowIndex)
			: items[rowIndex];
	};

	const getItemValue = (rowIndex) => {
		return itemValues.get
			? itemValues.get(rowIndex)
			: itemValues[rowIndex];
	};

	const checkedItemValues = value || [];

	const checkedIndexes = checkedItemValues.map((itemValue) => {
		return itemValues.indexOf(itemValue);
	});

	const checkedItems = checkedIndexes.map((rowIndex) => {
		return getItem(rowIndex);
	});

	const isChecked = (rowIndex) => {
		return checkedItemValues.indexOf(getItemValue(rowIndex)) !== -1;
	};

	const disabledIndexes = [];
	const disabledItems = [];
	const disabledItemValues = [];

	if (disabledGetter) {
		items.forEach((item, rowIndex) => {
			const isDisabled = disabledGetter({
				checkedItems,
				item,
				value: getItemValue(rowIndex),
			});

			if (isDisabled) {
				disabledIndexes.push(rowIndex);
				disabledItemValues.push(getItemValue(rowIndex));
				disabledItems.push(item);
			}
		});
	}

	if (disabledItemValues.length > 0) {
		const prunedCheckedItemValues = checkedItemValues.filter((itemValue) => disabledItemValues.indexOf(itemValue) === -1);

		if (prunedCheckedItemValues.length < checkedItemValues.length) {
			return describeValue(
				disabledGetter,
				items,
				prunedCheckedItemValues,
				valueGetter,
			);
		}
	}

	return {
		checkedIndexes,
		checkedItemValues,
		checkedItems,
		disabledIndexes,
		disabledItemValues,
		disabledItems,
		getItem,
		getItemValue,
		isChecked,
		itemValues,
	};
};

const memoizedDescribeValue = memoize(describeValue);



class MultiselectTableField extends Component {

	constructor(props, context) {
		super(props, context);

		this._describeValue = this._describeValue.bind(this);

		this._handleSelectionChange = this._handleSelectionChange.bind(this);
		this._renderCell = this._renderCell.bind(this);
		this._renderDisabledRowExplanation = this._renderDisabledRowExplanation.bind(this);
		this._renderHeader = this._renderHeader.bind(this);
	}



	componentDidMount() {
		const {
			name,
		} = this.props;

		const {
			onMountHandler,
		} = this.context;

		onMountHandler(name, {
			setValues: true,
		});
	}



	componentWillUnmount() {
		const {
			name,
		} = this.props;

		const {
			onUnmountHandler,
		} = this.context;

		onUnmountHandler(name);
	}



	_describeValue(newValue = null) {
		const {
			disabledGetter,
			items,
			name,
			valueGetter,
		} = this.props;

		const {
			defaultValues,
			values,
		} = this.context;

		return memoizedDescribeValue(
			disabledGetter,
			items,
			newValue || values[name] || defaultValues[name],
			valueGetter,
		);
	}



	_handleSelectionChange(checkedIndexes) {
		const {
			items,
			name,
			valueGetter,
		} = this.props;

		const {
			onChangeHandler,
		} = this.context;

		const {
			checkedItemValues,
		} = this._describeValue(
			checkedIndexes.map((rowIndex) => {
				const item = items.get
					? items.get(rowIndex)
					: items[rowIndex];

				return valueGetter
					? valueGetter(item)
					: item;
			}),
		);

		onChangeHandler(name, checkedItemValues);
	}



	_renderCell({ columnIndex, isDisabled, rowIndex }) {
		const {
			cellRenderer,
		} = this.props;

		const {
			checkedItems,
			getItem,
			isChecked,
		} = this._describeValue();

		return cellRenderer({
			checkedItems,
			columnIndex,
			item: getItem(rowIndex),
			isChecked: isChecked(rowIndex),
			isDisabled,
			rowIndex,
		});
	}



	_renderDisabledRowExplanation({ rowIndex }) {
		const {
			disabledRowExplanationRenderer,
		} = this.props;

		if (!disabledRowExplanationRenderer) {
			return false;
		}

		const {
			checkedItems,
			getItem,
			getItemValue,
			isChecked,
		} = this._describeValue();

		return disabledRowExplanationRenderer({
			checkedItems,
			item: getItem(rowIndex),
			itemValue: getItemValue(rowIndex),
			isChecked: isChecked(rowIndex),
			rowIndex,
		});
	}



	_renderHeader({ columnIndex }) {
		const {
			headerRenderer,
		} = this.props;

		return (
			<TableLabel label={headerRenderer({ columnIndex })} />
		);
	}



	render() {
		const {
			columnCount,
			columnWidth,
			footerCTARenderer,
			footerLabelRenderer,
			items,
			masterCheckbox,
			name,
			tableHeight,
		} = this.props;

		const additionalAttributes = {};

		if (tableHeight) {
			additionalAttributes.height = tableHeight;
		}

		const {
			checkedIndexes,
			disabledIndexes,
		} = this._describeValue();

		return (
			<Measurer>
				{({ containerWidth }) => (
					<SelectableRowsTable
						bodyCellRenderer={this._renderCell}
						columnCount={columnCount}
						columnWidth={columnWidth}
						disabledRowExplanationRenderer={this._renderDisabledRowExplanation}
						disabledRows={disabledIndexes}
						footerCTA={footerCTARenderer}
						footerLabel={footerLabelRenderer}
						headerCellRenderer={this._renderHeader}
						masterCheckbox={masterCheckbox}
						name={name}
						onSelectionChangeCallback={this._handleSelectionChange}
						ref={(ref) => this.tableRef = ref}
						rowCount={items.size || items.length || 0}
						selectedRows={checkedIndexes}
						width={containerWidth}
						{...additionalAttributes}
					/>
				)}
			</Measurer>
		);
	}

}

MultiselectTableField.contextType = FormContext;

MultiselectTableField.defaultProps = {
	masterCheckbox: true,
};

MultiselectTableField.propTypes = {
	cellRenderer: PropTypes.func.isRequired,
	columnCount: PropTypes.number.isRequired,
	columnWidth: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.number,
	]).isRequired,
	disabledGetter: PropTypes.func,
	disabledRowExplanationRenderer: PropTypes.func,
	footerCTARenderer: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.node,
	]),
	footerLabelRenderer: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.node,
	]),
	headerRenderer: PropTypes.func.isRequired,
	items: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]).isRequired,
	masterCheckbox: PropTypes.bool,
	name: PropTypes.string.isRequired,
	tableHeight: PropTypes.number,
	valueGetter: PropTypes.func,
};



export default MultiselectTableField;
