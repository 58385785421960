import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Call-to-action element like button or link */
	ctaElement?: React.ReactNode,
	/** Apply indentation around component */
	indented?: boolean,
	/** Optional bigger title */
	title?: React.ReactNode,
	/** Title icon */
	titleIcon?: React.ReactNode,
};



const ScreenMessage: React.FC<Props> = (props) => {
	const {
		children,
		ctaElement,
		indented,
		title,
		titleIcon,
	} = props;

	const componentClasses = classNames({
		'screen-message': true,
		'screen-message--indented': indented,
	});

	return (
		<div className={componentClasses}>
			{title && (
				<div className="screen-message__header">
					{titleIcon && (
						<div className="screen-message__icon">
							{titleIcon}
						</div>
					)}
					<h2 className="screen-message__title">{title}</h2>
				</div>
			)}

			{children && (
				<div className="screen-message__text">
					{children}
				</div>
			)}

			{ctaElement && (
				<div className="screen-message__cta-element">
					{ctaElement}
				</div>
			)}
		</div>
	);
};



export default ScreenMessage;
