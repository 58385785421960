import Immutable from 'immutable';
import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import useLoadTrackedChangesOnPages from '~/hooks/useLoadTrackedChangesOnPages';

import {
	UPDATE_FILTER,
} from '~/actions/historicalChanges';

import {
	DEFAULT_FILTER,
} from '~/model/historicalChanges';



function useUpdateTrackedChangesFilter() {
	const dispatch = useDispatch();
	const loadTrackedChangesOnPages = useLoadTrackedChangesOnPages();

	return React.useCallback(
		(filter) => {
			filter = Immutable.fromJS(filter).map(
				(value, key) => {
					if (value === undefined) {
						return DEFAULT_FILTER.get(key) !== undefined ? DEFAULT_FILTER.get(key) : '';
					}

					return value;
				},
			).toJS();

			dispatch({
				type: UPDATE_FILTER,
				filter: Immutable.fromJS(filter),
			});

			loadTrackedChangesOnPages(0, true);
		},
		[
			dispatch,
			loadTrackedChangesOnPages,
		],
	);
}



export default useUpdateTrackedChangesFilter;
