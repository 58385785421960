import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	AbstractTextFieldSize,
} from '~/components/patterns/forms/fields/AbstractTextField';
import CancelButton from '~/components/app/CancelButton';
import Copy from '~/components/logic/Copy';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
	ButtonsLayoutUsageContext,
} from '~/components/patterns/buttons/ButtonsLayout';
import DatatableBodyCell, {
	DatatableBodyCellAlignment,
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableHeaderCell, {
	DatatableHeaderCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';
import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableLayout from '~/components/patterns/tables/datatables/DatatableLayout';
import FieldStatus, {
	FieldStatusErrorMessageAlignment,
} from '~/components/patterns/forms/basis/FieldStatus';
import FixedHeaderGrid from '~/components/patterns/tables/datatables/FixedHeaderGrid';
import Form from '~/components/atoms/forms/basis/Form';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import Measurer from '~/utilities/Measurer';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';
import TableFieldLeveler from '~/components/patterns/tables/datatables/parts/TableFieldLeveler';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';
import TextField from '~/components/atoms/forms/components/TextField';
import WebVitalName from '~/components/app/WebVitalName';
import WebVitalsStatusIndicator, {
	WebVitalsStatusIndicatorStatus,
} from '~/components/patterns/webVitals/WebVitalsStatusIndicator';

import {
	useUpdateLighthouseThresholdsMutation,
} from './LighthouseThresholdsForm.gql';

import {
	validateField,
	validateFields,
} from '~/components/app/validations';

import useAccountDefaultLighthouseThresholds from '~/hooks/useAccountDefaultLighthouseThresholds';
import useWebsiteLighthouseThresholds from '~/hooks/useWebsiteLighthouseThresholds';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';

import {
	WebVital,
} from '~/model/webVitals';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	applyChanges: {
		id: 'ui.general.applyChanges',
	},
	description: {
		id: 'ui.websites.form.monitoring.lighthouseThresholds.modal.description',
	},
});



const rowTypes = [
	WebVital.Performance,
	WebVital.FCP,
	WebVital.TTI,
	WebVital.SI,
	WebVital.TBT,
	WebVital.LCP,
	WebVital.CLS,
];



const validations = {
	'validate-performance-needs-improvement': validateField(
		'performance-needs-improvement',
		(f) => ([
			f.validateNumber(),
			f.validateMinimumValue(0),
			f.validateMaximumValue(100),
		]),
	),
	'validate-performance-poor': validateFields(
		['performance-poor', 'performance-needs-improvement'],
		{
			'performance-poor': (f) => ([
				f.validateNumber(),
				f.validateMinimumValue(0),
				f.validateMaximumValue(100),
				f.custom({
					message: 'Value should be lower than the "needs improvement" threshold.',
					rule: ({ value, values }) => {
						return value < values['performance-needs-improvement'];
					},
				}),
			]),
		},
	),
};

[WebVital.FCP, WebVital.TTI, WebVital.SI, WebVital.TBT, WebVital.LCP, WebVital.CLS].forEach((type) => {
	validations[`validate-${type}-needs-improvement`] = validateField(
		`${type}-needs-improvement`,
		(f) => ([
			f.validateNumber(),
			f.validateMinimumValue(0),
		]),
	);

	validations[`validate-${type}-poor`] = validateFields(
		[`${type}-poor`, `${type}-needs-improvement`],
		{
			[`${type}-poor`]: (f) => ([
				f.validateNumber(),
				f.validateMinimumValue(0),
				f.custom({
					message: 'Value should be higher than the "needs improvement" threshold.',
					rule: ({ value, values }) => {
						return value > values[`${type}-needs-improvement`];
					},
				}),
			]),
		},
	);
});



type Props = {
	accountId: CK.AccountId | null,
	websiteId: CK.WebsiteId,
};

const LighthouseThresholdsForm: React.FC<Props> = (props) => {
	const {
		accountId,
		websiteId,
	} = props;

	const websiteThresholds = useWebsiteLighthouseThresholds(websiteId);
	const accountDefaultThresholds = useAccountDefaultLighthouseThresholds(accountId);
	const [updateThresholds] = useUpdateLighthouseThresholdsMutation();
	const classicFormBehavior = useClassicFormBehavior();

	if (websiteThresholds === null || accountDefaultThresholds === null) {
		return null;
	}

	function formatThresholdsToValues(thresholds: GraphQL.WebsiteLighthouseThresholds) {
		const values = {};

		rowTypes.forEach((type) => {
			let needsImprovementThreshold = thresholds[type][0];
			let poorThreshold = thresholds[type][1];

			if (type === WebVital.FCP || type === WebVital.TTI || type === WebVital.SI || type === WebVital.LCP) {
				needsImprovementThreshold = (needsImprovementThreshold / 1000).toFixed(1);
				poorThreshold = (poorThreshold / 1000).toFixed(1);
			} else if (type === WebVital.CLS) {
				needsImprovementThreshold = (needsImprovementThreshold / 100);
				poorThreshold = (poorThreshold / 100);
			}

			values[`${type}-needs-improvement`] = needsImprovementThreshold;
			values[`${type}-poor`] = poorThreshold;
		});

		return values;
	}

	function handleSubmit(values) {
		const thresholds = {};
		rowTypes.forEach((type) => {
			let needsImprovementThreshold = parseFloat(values[`${type}-needs-improvement`]);
			let poorThreshold = parseFloat(values[`${type}-poor`]);

			if (type === WebVital.FCP || type === WebVital.TTI || type === WebVital.SI || type === WebVital.LCP) {
				needsImprovementThreshold = needsImprovementThreshold * 1000;
				poorThreshold = poorThreshold * 1000;
			} else if (type === WebVital.CLS) {
				needsImprovementThreshold = needsImprovementThreshold * 100;
				poorThreshold = poorThreshold * 100;
			}

			thresholds[type] = [
				needsImprovementThreshold,
				poorThreshold,
			];
		});

		return updateThresholds({
			variables: {
				thresholds,
				websiteId,
			},
		}).then(() => {
			classicFormBehavior.finish();
		});
	}

	const defaultValues = formatThresholdsToValues(websiteThresholds);

	return (
		<Form
			defaultValues={defaultValues}
			onSuccess={handleSubmit}
			validations={validations}
		>
			{({ setValues }) => {
				function revertToDefaultThresholds() {
					if (accountDefaultThresholds !== null) {
						setValues(
							formatThresholdsToValues(accountDefaultThresholds),
						);
					}
				}

				return (
					<>
						<ModalTextSection>
							<RichText>
								<Copy
									{...messages.description}
									values={{
										linkRevertDefault: (chunks) => (
											<InternalLink
												onClickCallback={revertToDefaultThresholds}
												style={InternalLinkStyle.Decent}
											>
												{chunks}
											</InternalLink>
										),
									}}
								/>
							</RichText>
						</ModalTextSection>

						<Measurer>
							{({ containerWidth }) => {
								const tableWidth = containerWidth - 2;
								function getColumnWidth({ index }): number {
									if (index === 0) {
										return Math.max(200, tableWidth - 370);
									}

									return 185;
								}

								return (
									<DatatableLayout>
										<DatatableContainer>
											<FixedHeaderGrid
												bodyCellRenderer={(props) => (
													<BodyCell
														{...props}
														columnWidth={getColumnWidth}
													/>
												)}
												columnCount={3}
												columnWidth={getColumnWidth}
												headerCellRenderer={(props) => (
													<HeaderCell
														{...props}
														columnWidth={getColumnWidth}
														key={`header_${props.columnIndex}`}
													/>
												)}
												headerHeight={32}
												height={326}
												rowCount={7}
												rowHeight={42}
												width={tableWidth}
											/>
										</DatatableContainer>
									</DatatableLayout>
								);
							}}
						</Measurer>

						<ButtonsLayout
							alignment={ButtonsLayoutAlignment.Right}
							layout={ButtonsLayoutType.Steps}
							usageContext={ButtonsLayoutUsageContext.InModals}
						>
							<CancelButton />

							<SubmitButton>
								<FormattedMessage {...messages.applyChanges} />
							</SubmitButton>
						</ButtonsLayout>
					</>
				);
			}}
		</Form>
	);
};



const HeaderCell = (props) => {
	const {
		columnIndex,
		columnWidth,
	} = props;

	let label;
	let labelIcon;

	if (columnIndex === 0) {
		label = 'Web Vital metric';
	} else if (columnIndex === 1) {
		label = 'Needs Improvement';
		labelIcon = (
			<WebVitalsStatusIndicator status={WebVitalsStatusIndicatorStatus.NeedsImprovement} />
		);
	} else if (columnIndex === 2) {
		label = 'Poor';
		labelIcon = (
			<WebVitalsStatusIndicator status={WebVitalsStatusIndicatorStatus.Poor} />
		);
	}

	return (
		<DatatableHeaderCell
			key={'header_' + columnIndex}
			separator={columnIndex !== 0}
			size={DatatableHeaderCellSize.Small}
			width={columnWidth({ index: columnIndex })}
		>
			<TableLabel
				label={label}
				labelIcon={labelIcon}
			/>
		</DatatableHeaderCell>
	);
};



const typePrefix = {
	[WebVital.Performance]: 'below',
	[WebVital.FCP]: 'over',
	[WebVital.TTI]: 'over',
	[WebVital.SI]: 'over',
	[WebVital.TBT]: 'over',
	[WebVital.LCP]: 'over',
	[WebVital.CLS]: 'over',
};

const typeSuffix = {
	[WebVital.Performance]: '',
	[WebVital.FCP]: 's',
	[WebVital.TTI]: 's',
	[WebVital.SI]: 's',
	[WebVital.TBT]: 'ms',
	[WebVital.LCP]: 's',
	[WebVital.CLS]: '',
};

const BodyCell = (props) => {
	const {
		columnIndex,
		rowIndex,
		style,
	} = props;

	const type = getArrayItemAtSafeIndex(rowTypes, rowIndex);

	let content: React.ReactNode = null;

	switch (columnIndex) {

		case 0:
			content = (
				<WebVitalName
					type={type}
				/>
			);

			break;

		case 1:
		case 2:

			const name = `${type}-${columnIndex === 1 ? 'needs-improvement' : 'poor'}`;

			content = (
				<TableFieldLeveler
					fieldWidth={60}
					prefix={typePrefix[type]}
					suffix={typeSuffix[type]}
				>
					<FieldStatus
						allowOk={false}
						errorMessageAlignment={FieldStatusErrorMessageAlignment.Center}
						name={`validate-${name}`}
						showIcon={false}
					>
						<TextField
							name={name}
							resetButton={false}
							size={AbstractTextFieldSize.Small}
							width={false}
						/>
					</FieldStatus>
				</TableFieldLeveler>
			);

			break;

	}

	return (
		<DatatableBodyCell
			alignment={columnIndex === 1 ? DatatableBodyCellAlignment.Right : DatatableBodyCellAlignment.Left}
			cssStyle={style}
			rowIndex={rowIndex}
			separator={columnIndex !== 0}
			size={DatatableBodyCellSize.Small}
		>
			{content}
		</DatatableBodyCell>
	);
};



export default LighthouseThresholdsForm;
