import memoize from 'memoizee';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import ExternalLink from '~/components/patterns/links/ExternalLink';

import {
	type DisabledMembershipsQuery,
	useDisabledMembershipsQuery,
} from './useDisabledMemberships.gql';

import useAccountIds from '~/hooks/useAccountIds';

import {
	IsConductorClient,
} from '~/config';

import {
	getConductorLoginLink,
} from '~/model/conductor';



const messages = defineMessages({
	disabledHomeAccountHint: {
		id: 'ui.allMembershipLabels.disabledHint',
	},
	yourAccountLabel: {
		id: 'ui.accountAccess.contextSelect.yourAccountLabel',
	},
});

const format = memoize(
	(accountIds: ReadonlyArray<CK.AccountId> | null, data: DisabledMembershipsQuery | undefined) => {
		const allMembershipLabels = data?.authenticatedSession?.allMembershipLabels ?? null;

		if (
			accountIds === null
			|| allMembershipLabels === null
		) {
			return null;
		}

		return allMembershipLabels.filter(
			(membershipLabel) => accountIds.includes(membershipLabel.accountId) === false,
		).map((membershipLabel) => {
			return {
				accountDisplayName: membershipLabel.accountDisplayName || (
					<FormattedMessage
						{...messages.yourAccountLabel}
						values={{
							numberOfAccounts: 1,
						}}
					/>
				),
				accountId: membershipLabel.accountId,
				explanation: (
					<Copy
						{...messages.disabledHomeAccountHint}
						values={{
							isConductorClient: IsConductorClient ? 'yes' : 'no',
							linkToConductorLogin: (chunks) => (
								<ExternalLink
									href={getConductorLoginLink()}
								>
									{chunks}
								</ExternalLink>
							),
						}}
					/>
				),
				numberOfWebsitesInLimitedWebsitesAccess: membershipLabel.numberOfWebsitesInLimitedWebsitesAccess,
				role: membershipLabel.role,
			};
		});
	},
);



function useDisabledMemberships() {
	const accountIds = useAccountIds();

	const { data } = useDisabledMembershipsQuery();

	return format(accountIds, data);
}



export default useDisabledMemberships;
