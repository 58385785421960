import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AttachedFormControlElement from '~/components/patterns/forms/layouts/AttachedFormControlElement';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import InternalLink from '~/components/patterns/links/InternalLink';
import MarginsList from '~/components/atoms/lists/MarginsList';
import SubmitButton from '~/components/atoms/forms/components/SubmitButton';
import TextArea from '~/components/atoms/forms/components/TextArea';
import ValidationMessage, {
	ValidationMessageStatus,
} from '~/components/patterns/messages/embedded/ValidationMessage';

import {
	useExtractEmailsFromFreeInputMutation,
} from './SidebarFreeTextInput.gql';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	addButton: {
		id: 'ui.members.new.bulk.sidebar.freeBulkInput.addButton',
	},
	maxInvitationsLimitReached: {
		id: 'ui.members.new.bulk.sidebar.maxInvitationsLimitReached',
	},
	serverError: {
		id: 'ui.general.unexpectedError',
	},
	skippedLines: {
		id: 'ui.members.new.bulk.sidebar.freeBulkInput.skippedLines',
	},
	skippedLinesTooltip: {
		id: 'ui.members.new.bulk.sidebar.freeBulkInput.skippedLines.tooltip',
	},
	skippedLinesTooltipLimit: {
		id: 'ui.members.new.bulk.sidebar.freeBulkInput.skippedLines.tooltip.limit',
	},
});

const validations = {
	freeInput: [
		{
			message: '',
			field: 'freeInput',
			rule: ({ values }): boolean => {
				return values.freeInput.length > 0;
			},
		},
	],
};



type Props = {
	accountId?: CK.AccountId | null,
	addEmailsToStagingArea: (emails: Array<string>) => void,
	isDisabled?: boolean,
	isMaxInvitationsLimitReached?: boolean,
	maxInvitations: number,
	sessionId: string | undefined,
};

const SidebarFreeTextInput: React.FC<Props> = (props) => {
	const {
		accountId,
		addEmailsToStagingArea,
		isDisabled,
		isMaxInvitationsLimitReached,
		maxInvitations,
		sessionId,
	} = props;

	const formRef = React.useRef<FormRef>(null);
	const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

	const [extractEmailsFromFreeInput] = useExtractEmailsFromFreeInputMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			const response = await extractEmailsFromFreeInput({
				variables: {
					accountId: accountId ?? 0,
					sessionId: sessionId ?? '',
					freeInput: values.freeInput,
				},
			});

			const output = response.data?.StageEmailsForValidationFromFreeInput;

			if (!output) {
				return;
			}

			const remainingText = output.remainingFreeInputText || '';

			formRef.current?.setValue('freeInput', remainingText);

			if (textareaRef.current) {
				textareaRef.current.value = remainingText;
			}

			addEmailsToStagingArea([
				...output.stagingArea.finishedEmails.map((entry) => entry.email),
				...output.stagingArea.pendingEmails.map((entry) => entry.email),
				...output.stagingArea.failedEmails.map((entry) => entry.email),
			]);

			if (remainingText.length > 0) {
				const linesRemaining = remainingText.split('\n').length;

				throw new FormError('lines-remaining', linesRemaining);
			}
		},
		[
			accountId,
			addEmailsToStagingArea,
			extractEmailsFromFreeInput,
			sessionId,
		],
	);

	return (
		<Form
			defaultValues={{
				freeInput: '',
			}}
			isDisabled={isDisabled}
			onSuccess={handleSubmit}
			ref={formRef}
			validations={validations}
		>
			{({ globalError, isSubmitting, isValid }) => (
				<AttachedFormControlElement
					button={(
						<SubmitButton
							disabled={isSubmitting || !isValid || isMaxInvitationsLimitReached}
							icon={(
								<BasicIcon
									size={15}
									type={BasicIconType.Plus}
								/>
							)}
							size={ButtonSize.XXSmall}
							style={ButtonStyle.Hollow}
							tooltip={isMaxInvitationsLimitReached && (
								<FormattedMessage
									{...messages.maxInvitationsLimitReached}
									values={{
										maxInvitations,
										link_signup: (chunks) => (
											<InternalLink routeName="account.members">
												{chunks}
											</InternalLink>
										),
									}}
								/>
							)}
							uppercase={true}
						>
							<FormattedMessage {...messages.addButton} />
						</SubmitButton>
					)}
					validationMessage={
						isSubmitting ? (
							<ValidationMessage status={ValidationMessageStatus.ValidationInProgress} />
						) : globalError?.name === 'server-error' ? (
							<ValidationMessage status={ValidationMessageStatus.Invalid}>
								<FormattedMessage {...messages.serverError} />
							</ValidationMessage>
						) : globalError?.name === 'lines-remaining' ? (
							<ValidationMessage
								explanatoryTooltip={(
									<MarginsList>
										<FormattedMessage {...messages.skippedLinesTooltip} />

										<FormattedMessage
											{...messages.skippedLinesTooltipLimit}
											values={{
												maxInvitations,
											}}
										/>
									</MarginsList>
								)}
								status={ValidationMessageStatus.Invalid}
							>
								<FormattedMessage
									{...messages.skippedLines}
									values={{ lines: globalError.value }}
								/>
							</ValidationMessage>
						) : undefined
					}
				>
					<TextArea
						attributes={{
							style: {
								minHeight: 202,
							},
						}}
						isDisabled={isSubmitting}
						name="freeInput"
						placeholder={'jane@example.com\njohn@example.com\namy@example.com\nryan@example.com'}
						ref={textareaRef}
						resizable={true}
						rows={8}
						width="100%"
					/>
				</AttachedFormControlElement>
			)}
		</Form>
	);
};



export default SidebarFreeTextInput;
