import classNames from 'classnames';
import React from 'react';



export enum AttachedChartLegendAlignment {
	Bottom = 'bottom',
	Left = 'left',
	Right = 'right',
}

type Props = {
	children: React.ReactNode,
	/** Chart legend component */
	legend: React.ReactNode,
	/** Legend alignment */
	legendAlignment: AttachedChartLegendAlignment,
	/** Allow to fill-in container where is this component placed. This is useful when we want to allow legend grow according to size of chart. */
	stretched?: boolean,
};



const AttachedChartLegend: React.FC<Props> = (props) => {
	const {
		children,
		legend,
		legendAlignment,
		stretched,
	} = props;

	const componentClasses = classNames({
		'attached-chart-legend': true,
		'attached-chart-legend--stretched': legendAlignment === AttachedChartLegendAlignment.Bottom && stretched,
		'attached-chart-legend--left-legend': legendAlignment === AttachedChartLegendAlignment.Left,
		'attached-chart-legend--right-legend': legendAlignment === AttachedChartLegendAlignment.Right,
		'attached-chart-legend--bottom-legend': legendAlignment === AttachedChartLegendAlignment.Bottom,
	});

	return (
		<div className={componentClasses}>
			<div className="attached-chart-legend__chart">
				{children}
			</div>
			<div className="attached-chart-legend__legend">
				{legend}
			</div>
		</div>
	);
};



export default AttachedChartLegend;
