import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Current level */
	level: number,
	/** Maximum allowed level */
	max: number,
};



const LevelIndicator: React.FC<Props> = (props) => {
	const {
		level,
		max,
	} = props;

	const body: Array<React.ReactNode> = [];

	for (let index = 0; index < max; index++) {
		const itemClasses = classNames({
			'level-indicator__item': true,
			'level-indicator__item--highlighted': index < level,
		});

		body.push(
			<span
				className={itemClasses}
				key={'level-indicator-item-' + index}
			>
				{index < level ? 'x' : 'o'}
			</span>,
		);
	}

	return (
		<span className="level-indicator">
			{body}
		</span>
	);
};



export default LevelIndicator;
