import React from 'react';

import DecentText from '~/components/patterns/typography/DecentText';

import {
	useUserAgentsQuery,
} from './UserAgentHeaderValue.gql';

import {
	UserAgent,
} from '~/model/websites/userAgents';



function usePrefetch() {
	return useUserAgentsQuery();
}



type Props = {
	userAgent?: UserAgent | null,
	customHeaderValue?: string | null,
};

const UserAgentHeaderValue: React.FC<Props> = (props) => {
	const {
		customHeaderValue = null,
		userAgent: userAgentType = null,
	} = props;

	const { data } = useUserAgentsQuery();

	if (userAgentType === UserAgent.Custom) {
		return (
			<DecentText>
				{customHeaderValue}
			</DecentText>
		);
	}

	const userAgent = data?.userAgents.find((agent) => {
		return agent.type === userAgentType;
	});

	if (!userAgent) {
		return null;
	}

	return (
		<DecentText>
			{userAgent.headerValue}
		</DecentText>
	);
};



export default UserAgentHeaderValue;

export {
	usePrefetch,
};
