/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LogFileAnalysisS3BucketsQueryVariables = GraphQL.Exact<{
  logSourceProvider: GraphQL.LogSourceProvider;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type LogFileAnalysisS3BucketsQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly logFileAnalysisS3Buckets: ReadonlyArray<{ readonly __typename?: 'LogFileAnalysisS3Bucket', readonly accessKeyId: string, readonly domain: string, readonly name: string, readonly region: GraphQL.LogFileAnalysisS3BucketRegion, readonly secretAccessKey: string } | null> } | null };

export type CreateLogFileAnalysisS3BucketMutationVariables = GraphQL.Exact<{
  logSourceProvider: GraphQL.LogSourceProvider;
  region: GraphQL.LogFileAnalysisS3BucketRegion;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CreateLogFileAnalysisS3BucketMutation = { readonly __typename?: 'Mutation', readonly CreateLogFileAnalysisS3Bucket: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly logFileAnalysisS3Buckets: ReadonlyArray<{ readonly __typename?: 'LogFileAnalysisS3Bucket', readonly accessKeyId: string, readonly domain: string, readonly name: string, readonly region: GraphQL.LogFileAnalysisS3BucketRegion, readonly secretAccessKey: string } | null> } | null } } };


export const LogFileAnalysisS3BucketsDocument = gql`
    query LogFileAnalysisS3Buckets($logSourceProvider: LogSourceProvider!, $websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    logFileAnalysisS3Buckets(logSource: $logSourceProvider) {
      accessKeyId
      domain
      name
      region
      secretAccessKey
    }
  }
}
    `;

/**
 * __useLogFileAnalysisS3BucketsQuery__
 *
 * To run a query within a React component, call `useLogFileAnalysisS3BucketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogFileAnalysisS3BucketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogFileAnalysisS3BucketsQuery({
 *   variables: {
 *      logSourceProvider: // value for 'logSourceProvider'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useLogFileAnalysisS3BucketsQuery(baseOptions: Apollo.QueryHookOptions<LogFileAnalysisS3BucketsQuery, LogFileAnalysisS3BucketsQueryVariables> & ({ variables: LogFileAnalysisS3BucketsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogFileAnalysisS3BucketsQuery, LogFileAnalysisS3BucketsQueryVariables>(LogFileAnalysisS3BucketsDocument, options);
      }
export function useLogFileAnalysisS3BucketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogFileAnalysisS3BucketsQuery, LogFileAnalysisS3BucketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogFileAnalysisS3BucketsQuery, LogFileAnalysisS3BucketsQueryVariables>(LogFileAnalysisS3BucketsDocument, options);
        }
export function useLogFileAnalysisS3BucketsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LogFileAnalysisS3BucketsQuery, LogFileAnalysisS3BucketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LogFileAnalysisS3BucketsQuery, LogFileAnalysisS3BucketsQueryVariables>(LogFileAnalysisS3BucketsDocument, options);
        }
export type LogFileAnalysisS3BucketsQueryHookResult = ReturnType<typeof useLogFileAnalysisS3BucketsQuery>;
export type LogFileAnalysisS3BucketsLazyQueryHookResult = ReturnType<typeof useLogFileAnalysisS3BucketsLazyQuery>;
export type LogFileAnalysisS3BucketsSuspenseQueryHookResult = ReturnType<typeof useLogFileAnalysisS3BucketsSuspenseQuery>;
export type LogFileAnalysisS3BucketsQueryResult = Apollo.QueryResult<LogFileAnalysisS3BucketsQuery, LogFileAnalysisS3BucketsQueryVariables>;
export const CreateLogFileAnalysisS3BucketDocument = gql`
    mutation CreateLogFileAnalysisS3Bucket($logSourceProvider: LogSourceProvider!, $region: LogFileAnalysisS3BucketRegion!, $websiteId: WebsiteId!) {
  CreateLogFileAnalysisS3Bucket(
    logSource: $logSourceProvider
    region: $region
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        logFileAnalysisS3Buckets(logSource: $logSourceProvider) {
          accessKeyId
          domain
          name
          region
          secretAccessKey
        }
      }
    }
  }
}
    `;
export type CreateLogFileAnalysisS3BucketMutationFn = Apollo.MutationFunction<CreateLogFileAnalysisS3BucketMutation, CreateLogFileAnalysisS3BucketMutationVariables>;

/**
 * __useCreateLogFileAnalysisS3BucketMutation__
 *
 * To run a mutation, you first call `useCreateLogFileAnalysisS3BucketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLogFileAnalysisS3BucketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLogFileAnalysisS3BucketMutation, { data, loading, error }] = useCreateLogFileAnalysisS3BucketMutation({
 *   variables: {
 *      logSourceProvider: // value for 'logSourceProvider'
 *      region: // value for 'region'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCreateLogFileAnalysisS3BucketMutation(baseOptions?: Apollo.MutationHookOptions<CreateLogFileAnalysisS3BucketMutation, CreateLogFileAnalysisS3BucketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLogFileAnalysisS3BucketMutation, CreateLogFileAnalysisS3BucketMutationVariables>(CreateLogFileAnalysisS3BucketDocument, options);
      }
export type CreateLogFileAnalysisS3BucketMutationHookResult = ReturnType<typeof useCreateLogFileAnalysisS3BucketMutation>;
export type CreateLogFileAnalysisS3BucketMutationResult = Apollo.MutationResult<CreateLogFileAnalysisS3BucketMutation>;
export type CreateLogFileAnalysisS3BucketMutationOptions = Apollo.BaseMutationOptions<CreateLogFileAnalysisS3BucketMutation, CreateLogFileAnalysisS3BucketMutationVariables>;