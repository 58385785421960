import React from 'react';



type Props = {
	children: React.ReactNode,
};

const FormsGroupsList: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const elements = React.Children.toArray(children).filter((child) => !!child);

	return (
		<div className="forms-groups-list">
			{React.Children.map(
				elements,
				(child, index) => {
					return (
						<div
							className="forms-groups-list__item"
							key={'forms-groups-list-item-' + index}
						>
							{child}
						</div>
					);
				},
			)}
		</div>
	);
};



export default FormsGroupsList;
