import {
	useContentBlockPrimaryQuery,
} from '~/components/logic/pageDetail/components/ContentBlock.gql';

import {
	useIndexabilityBlockPrimaryQuery,
} from '~/components/logic/pageDetail/components/IndexabilityBlock.gql';

import {
	useLinksStructureBlockPartQuery,
} from '~/components/logic/pageDetail/components/LinksStructureBlockPart.gql';

import {
	useOpenGraphBlockPartPrimaryQuery,
} from '~/components/logic/pageDetail/components/OpenGraphBlockPart.gql';

import {
	useRelationsBlockPrimaryQuery,
} from '~/components/logic/pageDetail/components/RelationsBlock.gql';

import {
	useTwitterCardsBlockPartPrimaryQuery,
} from '~/components/logic/pageDetail/components/TwitterCardsBlockPart.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import usePageImportance from '~/hooks/usePageImportance';
import usePageIssueCategories from '~/hooks/usePageIssueCategories';
import usePageScreenshotUrl from '~/hooks/usePageScreenshotUrl';
import usePageSegments from '~/hooks/usePageSegments';
import usePageTimeDiscoveredAt from '~/hooks/usePageTimeDiscoveredAt';
import usePageType from '~/hooks/usePageType';
import usePageUrl from '~/hooks/usePageUrl';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsLogFileAnalysisEnabled from '~/hooks/useWebsiteIsLogFileAnalysisEnabled';



function usePreloadPageDetail(): void {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	useWebsiteIsLogFileAnalysisEnabled(websiteId);
	usePageImportance(legacyUrlId, websiteId);
	usePageIssueCategories(legacyUrlId, websiteId);
	usePageScreenshotUrl(legacyUrlId, websiteId);
	usePageSegments(legacyUrlId, websiteId);
	usePageTimeDiscoveredAt(legacyUrlId, websiteId);
	usePageType(legacyUrlId, websiteId);
	usePageUrl(legacyUrlId, websiteId);

	usePageDetailPropertiesQuery(
		useContentBlockPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	usePageDetailPropertiesQuery(
		useIndexabilityBlockPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	usePageDetailPropertiesQuery(
		useLinksStructureBlockPartQuery,
		legacyUrlId,
		websiteId,
	);

	usePageDetailPropertiesQuery(
		useOpenGraphBlockPartPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	usePageDetailPropertiesQuery(
		useRelationsBlockPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	usePageDetailPropertiesQuery(
		useTwitterCardsBlockPartPrimaryQuery,
		legacyUrlId,
		websiteId,
	);
}



export default usePreloadPageDetail;
