import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useUserRoleInAccountQuery,
} from './useUserRole.gql';

import usePollInterval from '~/hooks/usePollInterval';



function useUserRole(
	accountId: CK.AccountId | null,
	legacyUserId: string | null,
): GraphQL.UserRole | null {
	const { data } = useUserRoleInAccountQuery({
		pollInterval: usePollInterval(30_000),
		skip: accountId === null || legacyUserId === null,
		variables: {
			accountId: accountId ?? 0,
			legacyUserId: legacyUserId ?? '',
		},
	});

	return data?.userAccountMembership?.role ?? null;
}



export default useUserRole;
