import React from 'react';



type Props = {
	buttons?: React.ReactNode,
	footer?: React.ReactNode,
	score?: React.ReactNode,
	title: React.ReactNode,
};



const PageDetailHeader: React.FC<Props> = (props) => {
	const {
		buttons,
		footer,
		score,
		title,
	} = props;

	return (
		<section className="page-detail-header">
			<div className="page-detail-header__container">
				<div className="page-detail-header__title">
					{title}
				</div>

				<div className="page-detail-header__options">
					{buttons && (
						<div className="page-detail-header__buttons">
							{buttons}
						</div>
					)}

					<div className="page-detail-header__score">
						{score}
					</div>
				</div>
			</div>

			{footer && (
				<div className="page-detail-header__footer">
					{footer}
				</div>
			)}
		</section>
	);
};



export default PageDetailHeader;
