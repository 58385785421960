import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	useAccountProblemsQuery,
} from './useAccountProblems.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';
import useAccountUnpaidSituation from '~/hooks/useAccountUnpaidSituation';



function useAccountProblems(accountId: CK.AccountId | null) {
	const accountUnpaidSituation = useAccountUnpaidSituation(accountId);

	const { data } = useAccountPropertiesQuery(
		useAccountProblemsQuery,
		accountId,
	);

	const hasPausedWebsites = data?.account?.websites.some(
		(website) => website.isCrawlingPaused,
	) ?? false;

	const hasWebsitesAtPageCapacity = data?.account?.websites.some(
		(website) => website.problem === GraphQL.WebsiteProblem.CapacityReached,
	) ?? false;

	return React.useMemo(
		() => ({
			hasPausedWebsites,
			hasWebsitesAtPageCapacity,
			hasPaymentProblem: accountUnpaidSituation !== null,
			isSubscriptionSuspended: accountUnpaidSituation?.unpaidPhase === GraphQL.AccountUnpaidPhase.Suspension,
		}),
		[
			accountUnpaidSituation,
			hasPausedWebsites,
			hasWebsitesAtPageCapacity,
		],
	);
}



export default useAccountProblems;
