import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AlertsIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g>
				<path
					d="M13.03,4.59L3.1,21.41h19.8L13.03,4.59z"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					d="M13,10.5v4.83 M13,18v0.5"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default AlertsIconBuilder;

