import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AddEndUserWebsitePriceSummaryBox from '~/components/app/AddEndUserWebsitePriceSummaryBox';
import AppearAnimation, {
	AppearAnimationDirection,
} from '~/components/patterns/animations/AppearAnimation';
import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import AuthorizePaymentIframe from '../../AuthorizePaymentIframe';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
	linkInternal,
} from '~/components/logic/Copy';
import DomainNotWhitelistedErrorMessage from '~/components/logic/errorMessages/DomainNotWhitelistedErrorMessage';
import DecentText, {
	DecentTextAlignment,
	DecentTextSize,
} from '~/components/patterns/typography/DecentText';
import FetchingLocationFieldRow, {
	FetchingLocationFieldRowScope,
	validateFetchingLocation,
} from '~/components/app/FetchingLocationFieldRow';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HelpHint from '~/components/patterns/hints/HelpHint';
import HttpAuthFields, {
	validateHttpAuthPassword,
	validateHttpAuthUsername,
} from '~/components/app/HttpAuthFields';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MoneyStuff from '~/components/app/MoneyStuff';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import OnlyForAdmin from '~/components/app/OnlyForAdmin';
import PageBundleChangeSummary from '../PageBundleChangeSummary';
import PageCapacityField from '~/components/app/PageCapacityField';
import PaymentAuthorizationContext from '~/components/logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '~/components/logic/PaymentAuthorizationError';
import PaymentCancelButton from '~/components/logic/PaymentCancelButton';
import PaymentFailureFormError from '~/components/app/PaymentFailureFormError';
import PaymentSubmitButton from '~/components/logic/PaymentSubmitButton';
import RecurlyFormErrorMessages from '~/components/app/RecurlyFormErrorMessages';
import RichText from '~/components/patterns/typography/RichText';
import SelectField from '~/components/atoms/forms/components/SelectField';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';
import SuggestedDomains from './SuggestedDomains';
import SwitchField, {
	SwitchFieldStyle,
} from '~/components/app/SwitchField';
import TextField from '~/components/atoms/forms/components/TextField';
import UnreliableResponseName from '~/components/names/UnreliableResponseName';
import UserAgentFields, {
	UserAgentFieldsScope,
	validateUserAgent,
	validateUserAgentCustomValue,
} from '~/components/app/UserAgentFields';

import {
	validateField,
	validateWhenField,
} from '~/components/app/validations';

import {
	useAddSingleWebsiteQuery,
} from '~/components/logic/addWebsiteScreen/AddSingleWebsiteStep/AddSingleWebsiteStep.gql';

import useAccountCanSignupRemoveWebsiteLimit from '~/hooks/useAccountCanSignupRemoveWebsiteLimit';
import useAccountId from '~/hooks/useAccountId';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountType from '~/hooks/useAccountType';
import useAccountWebsiteRestrictions from '~/hooks/useAccountWebsiteRestrictions';
import useCreateWebsite, {
	type CreateWebsiteInput,
} from '~/hooks/useCreateWebsite';
import useIsDomainPreverified from '~/hooks/useIsDomainPreverified';
import useUrlState from '~/hooks/useUrlState';

import {
	doesAccountTypeHavePageBundle,
} from '~/model/accounts';

import {
	isDomainWhitelisted,
} from '~/model/websites';

import {
	UserAgent,
} from '~/model/websites/userAgents';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	authorizationDescription: {
		id: 'ui.3dsecure.prompt',
	},
	breadcrumb: {
		id: 'ui.websites.new.steps.websiteDetails',
	},
	custom: {
		id: 'ui.websites.form.monitoringSettings.custom',
	},
	default: {
		id: 'ui.websites.form.monitoringSettings.default',
	},
	disabled: {
		id: 'ui.general.disabled',
	},
	domainLabel: {
		id: 'ui.websites.form.domain',
	},
	domainPlaceholder: {
		id: 'ui.websites.form.domain.placeholder',
	},
	enabled: {
		id: 'ui.general.enabled',
	},
	httpAuthenticationLabel: {
		id: 'ui.websites.form.monitoring.httpAuthentication.label',
	},
	httpAuthenticationHint: {
		id: 'ui.websites.form.monitoring.httpAuthentication.hint',
	},
	legalNotice: {
		id: 'ui.websites.new.legal.notice',
	},
	monitoringSettings: {
		id: 'ui.websites.form.monitoringSettings',
	},
	pageCapacityDescription: {
		id: 'ui.websiteSettings.plan.pageCapacity.description',
	},
	pageCapacityLabel: {
		id: 'ui.websiteSettings.plan.pageCapacity',
	},
	saveButton: {
		id: 'ui.teamDetail.websites.add.save',
	},
	title: {
		id: 'ui.websites.new.title',
	},
});



const errorMessages = defineMessages({
	alreadyOnOtherAccounts: {
		id: 'ui.websites.new.formErrors.alreadyOnOtherAccounts',
	},
	alreadyInYourAccount: {
		id: 'ui.websites.new.formErrors.alreadyInYourAccount',
	},
	blacklisted: {
		id: 'ui.websites.new.formErrors.blacklisted',
	},
	invalidInput: {
		id: 'ui.formErrors.invalidInput',
	},
	redirect: {
		id: 'ui.websites.new.formErrors.redirect',
	},
	unreachable: {
		id: 'ui.teamDetail.websites.add.errors.unreachable',
	},
	unverifiedWebsitesLimitExceeded: {
		id: 'ui.websites.new.formErrors.unverifiedWebsitesLimitExceeded',
	},
});



type Props = {
	isDisabled?: boolean,
	onContinueCallback: (websiteId: CK.WebsiteId, domain: string) => void,
	onSelectAllDomains: (domains: Array<string>) => void,
};

const AddSingleWebsiteStep: React.FC<Props> = (props) => {
	const {
		isDisabled = false,
		onContinueCallback,
		onSelectAllDomains,
	} = props;

	const accountId = useAccountId();

	const accountPhase = useAccountPhase(accountId);
	const accountType = useAccountType(accountId);
	const accountWebsiteRestrictions = useAccountWebsiteRestrictions(accountId);
	const canSignupRemoveWebsiteLimit = useAccountCanSignupRemoveWebsiteLimit(accountId);
	const createWebsite = useCreateWebsite(accountId);
	const {
		checkDomainIsPreverified,
		isDomainPreverified,
	} = useIsDomainPreverified(accountId);
	const intl = useIntl();
	const urlState = useUrlState();

	const { data } = useAddSingleWebsiteQuery({
		variables: {
			accountId: accountId ?? 0,
		},
		skip: accountId === null,
	});

	const formRef = React.useRef<FormRef>(null);

	const canAddWebsitesInBulk = accountType !== GraphQL.AccountType.EndUser;
	const defaultFetchingLocation = data?.account?.defaultFetchingSettings.fetchingLocation ?? null;
	let defaultUserAgent = data?.account?.defaultFetchingSettings.userAgentSettings.type ?? null;

	if (defaultUserAgent === UserAgent.Automatic) {
		defaultUserAgent = UserAgent.GooglechromeDesktop;
	}

	const handleChange = React.useCallback(
		(field, value) => {
			if (field === 'domain') {
				checkDomainIsPreverified(value);
			}
		},
		[
			checkDomainIsPreverified,
		],
	);

	const handleSubmit = React.useCallback(
		async ({ values, threeDSecureToken }) => {
			const createWebsiteInput: CreateWebsiteInput = {
				acceptedLegalDocuments: [GraphQL.LegalDocumentType.Woc],
				domain: values.domain,
				pageCapacity: values.pageCapacity,
				skipValidation: values.dontValidate || false,
				threeDSecureToken,
			};

			if (values.monitoringSettings === 'custom') {
				createWebsiteInput.fetchingLocation = values.fetchingLocation;
				createWebsiteInput.userAgentSettings = {
					customValue: values.userAgentCustomValue,
					type: values.userAgent,
				};

				if (values.httpAuth === true) {
					createWebsiteInput.httpAuthCredentials = {
						password: values.httpAuthPassword,
						username: values.httpAuthUsername,
					};
				}
			}

			const websiteId = await createWebsite(createWebsiteInput);

			if (websiteId === undefined) {
				throw new Error(
					`Can't access ID of created website`,
				);
			}

			onContinueCallback(websiteId, values.domain);
		},
		[
			createWebsite,
			onContinueCallback,
		],
	);

	const handleError = React.useCallback(
		(error, values) => {
			return FormError.fromApolloError(error, {
				blacklisted_domain: values.domain,
				domainNotWhitelisted: values.domain,
				target_redirected: values.domain,
				unresolvable_domain: values.domain,
				unverifiedWebsitesLimitExhausted: values.domain,
				website_already_monitored: values.domain,
				website_already_registered: values.domain,
			});
		},
		[],
	);

	const hasPageBundle = accountType === null || doesAccountTypeHavePageBundle(accountType);

	const isLoaded = (
		accountId !== null
		&& accountWebsiteRestrictions !== null
		&& canSignupRemoveWebsiteLimit !== null
		&& defaultFetchingLocation !== null
		&& defaultUserAgent !== null
	);

	const validations = React.useMemo(
		() => {
			if (
				accountId === null
				|| accountWebsiteRestrictions === null
				|| canSignupRemoveWebsiteLimit === null
			) {
				return {};
			}

			return {
				validateDomain: validateDomain({
					accountId,
					canSignupRemoveWebsiteLimit,
					maximumUnverifiedWebsites: accountWebsiteRestrictions.maximumUnverifiedWebsites,
					domainsWhitelist: accountWebsiteRestrictions.domainsWhitelist,
				}),
				validatePageCapacity,
				...validateMonitoringSettings({
					isDomainPreverified: isDomainPreverified ?? false,
				}),
			};
		},
		[
			accountId,
			accountWebsiteRestrictions,
			canSignupRemoveWebsiteLimit,
			isDomainPreverified,
		],
	);

	return (
		<MultiStepModalStep
			breadcrumb={(
				<FormattedMessage {...messages.breadcrumb} />
			)}
			headerIconType={MultiStepModalStepHeaderIconType.Plus}
			name="add-single-website-step"
			preloader={!isLoaded && (
				<LoadingDots isStretched={true} />
			)}
			sidebar={(
				<SuggestedDomains
					accountId={accountId}
					canAddWebsitesInBulk={canAddWebsitesInBulk}
					onSelectAllDomains={onSelectAllDomains}
					onSelectDomain={(domain: string): void => {
						formRef.current?.changeFieldValue('domain', domain).then(() => {
							checkDomainIsPreverified(domain);
						});
					}}
				/>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			{({ isActive }) => {
				if (!isActive || !isLoaded) {
					return null;
				}

				return (
					<MoneyStuff
						placeholder={(
							<LoadingDots isStretched={true} />
						)}
					>
						{({ tariff }) => (
							<PaymentAuthorizationContext
								accountId={accountId}
								formRef={formRef}
								onError={handleError}
								onSubmit={handleSubmit}
								useModal={false}
							>
								{({ handleFormSuccess, isAuthorizing }) => (
									<Form
										defaultFocus={isDisabled ? null : 'domain'}
										defaultValues={{
											domain: urlState.params.domain || '',
											fetchingLocation: defaultFetchingLocation,
											monitoringSettings: 'default',
											pageCapacity: tariff.normalizePageCapacity({
												pageCapacity: accountPhase === GraphQL.AccountPhase.Trial ? accountWebsiteRestrictions.maximumPageCapacity : 1,
											}),
											userAgent: defaultUserAgent,
										}}
										ignoreFieldUnmounts={true}
										isDisabled={isDisabled}
										onChangeCallback={handleChange}
										onSuccess={handleFormSuccess}
										ref={formRef}
										validations={validations}
									>
										{({ values }) => {
											const hasDomain = values.domain && values.domain.length > 0;
											const showCustomMonitoringSettingFields = values.monitoringSettings === 'custom';

											return (
												<CenteredFormWrapper>
													<div style={{ display: isAuthorizing ? 'none' : 'block' }}>
														<FormRows>
															<FormRow
																htmlFor="domain"
																label={(
																	<FormattedMessage {...messages.domainLabel} />
																)}
															>
																<FieldStatus name="validateDomain">
																	<TextField
																		name="domain"
																		placeholder={intl.formatMessage(messages.domainPlaceholder)}
																		trimValue={true}
																	/>
																</FieldStatus>
															</FormRow>

															<FormRow
																description={(
																	<FormattedMessage
																		{...messages.pageCapacityDescription}
																		values={{
																			linkSupport: linkExternal('https://www.contentkingapp.com/support/page-capacity/#page-capacity'),
																		}}
																	/>
																)}
																htmlFor="pageCapacity"
																label={(
																	<FormattedMessage {...messages.pageCapacityLabel} />
																)}
															>
																<PageCapacityField
																	accountId={accountId}
																	name="pageCapacity"
																/>
															</FormRow>

															<FormRow
																htmlFor="monitoringSettings"
																label={(
																	<FormattedMessage {...messages.monitoringSettings} />
																)}
															>
																<SelectField
																	name="monitoringSettings"
																	options={[
																		{
																			name: 'default',
																			label: (
																				<FormattedMessage {...messages.default} />
																			),
																		},
																		{
																			name: 'custom',
																			label: (
																				<FormattedMessage {...messages.custom} />
																			),
																		},
																	]}
																/>
															</FormRow>

															{showCustomMonitoringSettingFields && (
																<AppearAnimation direction={AppearAnimationDirection.Down}>
																	<FormRows>
																		<UserAgentFields
																			isEditable={true}
																			isWebsiteVerified={isDomainPreverified ?? false}
																			scope={UserAgentFieldsScope.AddWebsite}
																			userAgent={null}
																		/>

																		<FetchingLocationFieldRow
																			fetchingLocation={null}
																			isEditable={true}
																			scope={FetchingLocationFieldRowScope.Website}
																		/>

																		<FormRow
																			label={(
																				<AttachedElement
																					element={(
																						<HelpHint
																							message={(
																								<FormattedMessage {...messages.httpAuthenticationHint} />
																							)}
																						/>
																					)}
																				>
																					<FormattedMessage {...messages.httpAuthenticationLabel} />
																				</AttachedElement>
																			)}
																		>
																			<SwitchField
																				activeStateLabel={(
																					<FormattedMessage {...messages.enabled} />
																				)}
																				inactiveStateLabel={(
																					<FormattedMessage {...messages.disabled} />
																				)}
																				name="httpAuth"
																				style={SwitchFieldStyle.Neutral}
																			/>
																		</FormRow>

																		{values.httpAuth === true && (
																			<AppearAnimation direction={AppearAnimationDirection.Down}>
																				<HttpAuthFields />
																			</AppearAnimation>
																		)}
																	</FormRows>
																</AppearAnimation>
															)}

															<OnlyForAdmin canBeHidden={true}>
																<FormRow
																	htmlFor="dontValidate"
																	label="Skip validation"
																>
																	<SwitchField
																		activeStateLabel="yes"
																		inactiveStateLabel="no"
																		name="dontValidate"
																	/>
																</FormRow>
															</OnlyForAdmin>

															<RecurlyFormErrorMessages />

															<FormErrorMessages />

															<PaymentAuthorizationError />

															<PaymentFailureFormError errorCode="failedPayment" />

															{hasDomain && hasPageBundle && (
																<PageBundleChangeSummary
																	accountId={accountId}
																	pageCapacity={values.pageCapacity}
																/>
															)}

															{hasDomain && !hasPageBundle && (
																<AddEndUserWebsitePriceSummaryBox
																	accountId={accountId}
																	pageCapacity={values.pageCapacity}
																/>
															)}
														</FormRows>

													</div>

													{isAuthorizing && (
														<ModalTextSection>
															<RichText>
																<FormattedMessage {...messages.authorizationDescription} />
															</RichText>
														</ModalTextSection>
													)}

													{isAuthorizing && (
														<AuthorizePaymentIframe />
													)}
													<Spacer top={SpacerGap.Default}>
														<DecentText
															alignment={DecentTextAlignment.Left}
															size={DecentTextSize.Small}
														>
															<FormattedMessage {...messages.legalNotice} />
														</DecentText>
													</Spacer>
													<ButtonsLayout
														alignment={ButtonsLayoutAlignment.Right}
														layout={ButtonsLayoutType.FormRowWithoutStatus}
													>
														<PaymentCancelButton />

														<PaymentSubmitButton>
															<FormattedMessage {...messages.saveButton} />
														</PaymentSubmitButton>
													</ButtonsLayout>
												</CenteredFormWrapper>
											);
										}}
									</Form>
								)}
							</PaymentAuthorizationContext>
						)}
					</MoneyStuff>
				);
			}}
		</MultiStepModalStep>
	);
};



const validateDomain = (input: {
	accountId: CK.AccountId,
	canSignupRemoveWebsiteLimit: boolean,
	domainsWhitelist: ReadonlyArray<string> | null,
	maximumUnverifiedWebsites: number | null,
}) => validateField(
	'domain',
	(f) => [
		f.validateNonEmpty(),
		f.validateDomain(),
		f.custom({
			message: (
				<DomainNotWhitelistedErrorMessage
					accountId={input.accountId}
				/>
			),
			rule: ({ value }) => {
				return isDomainWhitelisted(value, input.domainsWhitelist);
			},
		}),
		f.customGlobal({
			globalRule: 'domainNotWhitelisted',
			message: (
				<DomainNotWhitelistedErrorMessage
					accountId={input.accountId}
				/>
			),
		}),
		f.customGlobal({
			globalRule: 'website_already_monitored',
			message: (
				<FormattedMessage {...errorMessages.alreadyOnOtherAccounts} />
			),
		}),
		f.customGlobal({
			globalRule: 'website_already_registered',
			message: (
				<FormattedMessage {...errorMessages.alreadyInYourAccount} />
			),
		}),
		f.customGlobal({
			globalRule: 'blacklisted_domain',
			message: (
				<FormattedMessage {...errorMessages.blacklisted} />
			),
		}),
		f.customGlobal({
			field: 'domain',
			globalRule: 'unresolvable_domain',
			message: ({ details }) => (
				<FormattedMessage
					{...errorMessages.unreachable}
					values={{
						reason: (
							<UnreliableResponseName
								reason={details.unresolvableDomainReason}
							/>
						),
					}}
				/>
			),
		}),
		f.customGlobal({
			globalRule: 'target_redirected',
			message: ({ details }) => (
				<FormattedMessage
					{...errorMessages.redirect}
					values={{
						redirectTarget: (new URL(details.redirectTarget)).origin,
					}}
				/>
			),
		}),
		f.customGlobal({
			globalRule: 'unverifiedWebsitesLimitExhausted',
			message: (
				<Copy
					{...errorMessages.unverifiedWebsitesLimitExceeded}
					values={{
						canSignupRemoveWebsiteLimit: input.canSignupRemoveWebsiteLimit ? 'yes' : 'no',
						maxUnverifiedWebsites: input.maximumUnverifiedWebsites,
						link_signup: linkInternal('account.pricing'),
						link_support: linkExternal('https://www.contentkingapp.com/support/website-verification/#how-to-verify-a-website-in-contentking'),
					}}
				/>
			),
		}),
	],
);



const validatePageCapacity = validateField(
	'pageCapacity',
	(f) => [
		f.validateInteger(),
	],
);



const validateMonitoringSettings = ({ isDomainPreverified }: { isDomainPreverified: boolean }) => {
	const validateHttpAuth = validateWhenField(
		'httpAuth',
		({ value }) => value === true,
		{
			validateHttpAuthUsername,
			validateHttpAuthPassword,
		},
	);

	return validateWhenField(
		'monitoringSettings',
		({ value }) => value === 'custom',
		{
			validateFetchingLocation,
			validateUserAgent: validateUserAgent({ isWebsiteVerified: isDomainPreverified }),
			validateUserAgentCustomValue: validateUserAgentCustomValue({ isWebsiteVerified: isDomainPreverified }),
			...validateHttpAuth,
		},
	);
};



export default AddSingleWebsiteStep;
