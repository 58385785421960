import React from 'react';

import ChangeAccountModal, {
	type ChangeAccountModalAvailableAccounts,
} from '~/components/app/ChangeAccountModal';
import SwitchAccountModal from '~/components/app/SwitchAccountModal';

import useModals from '~/hooks/useModals';



function useOpenSwitchAccountModal(forgetLocation: boolean) {
	const modals = useModals();

	const handleAvailableAccounts = React.useCallback(
		(availableAccounts: ChangeAccountModalAvailableAccounts) => {
			modals.openModal(
				() => (
					<SwitchAccountModal
						availableAccounts={availableAccounts}
						forgetLocation={forgetLocation}
					/>
				),
			);
		},
		[
			forgetLocation,
			modals,
		],
	);

	return React.useCallback(
		(defaultInput: string = '') => {
			modals.openModal(
				() => (
					<ChangeAccountModal
						defaultInput={defaultInput}
						handleAvailableAccounts={handleAvailableAccounts}
					/>
				),
			);
		},
		[
			handleAvailableAccounts,
			modals,
		],
	);
}



export default useOpenSwitchAccountModal;
