import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	useGoogleAnalyticsVisibleColumnsQuery,
} from './useWebsiteGoogleAnalyticsVisibleColumns.gql';



const defaultVisibleColumns = [
	GraphQL.GoogleAnalyticsVersion.V3,
];


function useWebsiteGoogleAnalyticsVisibleColumns(
	websiteId: CK.WebsiteId,
): ReadonlyArray<GraphQL.GoogleAnalyticsVersion> {
	const { data } = useGoogleAnalyticsVisibleColumnsQuery({
		variables: {
			websiteId,
		},
	});

	return (data?.website?.googleAnalyticsVisibleColumns) ?? defaultVisibleColumns;
}



export default useWebsiteGoogleAnalyticsVisibleColumns;
