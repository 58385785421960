import React from 'react';

import type CK from '~/types/contentking';

import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';



type Props = {
	websiteId: CK.WebsiteId | null,
};

const WebsiteDisplayName: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteDisplayName = useWebsiteDisplayName(websiteId);

	return (
		<>{websiteDisplayName}</>
	);
};



export default WebsiteDisplayName;
