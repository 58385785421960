import {
	Component,
} from 'react';
import {
	pingIntercomViaApi,
} from '~/model/intercom';



const PING_INTERVAL = 1000 * 60 * 5;

class IntercomPinger extends Component {

	_ping() {
		pingIntercomViaApi()
			.catch(() => {})
			.then(() => {
				if (this.pingTimeout) {
					clearTimeout(this.pingTimeout);
					this.pingTimeout = undefined;
				}

				this.pingTimeout = setTimeout(() => this._ping(), PING_INTERVAL);
			});
	}



	componentDidMount() {
		this._ping();
	}



	componentWillUnmount() {
		if (this.pingTimeout) {
			clearTimeout(this.pingTimeout);
			this.pingTimeout = undefined;
		}
	}



	render() {
		return false;
	}

}

export default IntercomPinger;
