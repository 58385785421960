import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BackLink from '~/components/patterns/links/BackLink';
import CurrentUserProfileContextActions from '~/components/app/CurrentUserProfileContextActions';
import EmailSettingsGroup from '~/components/logic/memberDetail/formGroups/EmailSettingsGroup';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderTitleWithValue from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import PersonalSettingsGroup from '~/components/logic/memberDetail/formGroups/PersonalSettingsGroup';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScrollableContentScreen from '~/components/logic/layouts/screens/ScrollableContentScreen';
import Sections from '~/components/logic/lego/Sections';
import UserProfile from '~/components/app/UserProfile';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserMemberships from '~/hooks/useUserMemberships';

import matchAndReturn from '~/utilities/matchAndReturn';



enum Section {
	EmailSettings = 'emailSettings',
	PersonalSettings = 'personalSettings',
}

const SectionSorting = [
	Section.PersonalSettings,
	Section.EmailSettings,
];

const SectionRoutes = {
	[Section.EmailSettings]: 'userProfile.emailSettings',
	[Section.PersonalSettings]: 'userProfile',
};

const messages = defineMessages({
	actionsTitle: {
		id: 'ui.general.actions',
	},
	backToTeamMembers: {
		id: 'ui.userProfile.backToTeamMembers',
	},
	title: {
		id: 'ui.userProfile.title',
	},
	[Section.EmailSettings]: {
		id: 'ui.members.formGroups.emailSettings',
	},
	[Section.PersonalSettings]: {
		id: 'ui.members.formGroups.personalSettings',
	},
});



type Props = {
	backlink: any,
	closeCallback: () => void,
};

const UserProfileScreen: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
	} = props;

	const currentUserId = useCurrentUserId();

	const userMemberships = useUserMemberships(currentUserId);

	if (
		currentUserId === null
		|| userMemberships === null
	) {
		return null;
	}

	return (
		<ScrollableContentScreen
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						{backlink === 'account.members' && (
							<BackLink
								label={(
									<FormattedMessage {...messages.backToTeamMembers} />
								)}
								onClickCallback={closeCallback}
							/>
						)}

						<HeaderTitleWithValue
							title={(
								<FormattedMessage {...messages.title} />
							)}
						/>
					</HeaderTitleLayout>
				</ScreenHeader>
			)}
			withPadding={false}
		>
			<Sections
				actions={(
					<CurrentUserProfileContextActions />
				)}
				getSectionRoute={(section) => SectionRoutes[section]}
				overview={(
					<UserProfile
						userId={currentUserId}
					/>
				)}
				renderSectionContent={(section) => matchAndReturn(section, {
					[Section.EmailSettings]: (
						<EmailSettingsGroup
							isForCurrentUser={true}
							legacyUserId={currentUserId}
						/>
					),
					[Section.PersonalSettings]: (
						<PersonalSettingsGroup
							isForCurrentUser={true}
							memberships={userMemberships}
							userId={currentUserId}
						/>
					),
				})}
				renderSectionTitle={(section) => (
					<FormattedMessage {...messages[section]} />
				)}
				sections={SectionSorting}
			/>
		</ScrollableContentScreen>
	);
};



export default UserProfileScreen;
