import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import ReactDOMServer from 'react-dom';
import {
	injectIntl,
} from 'react-intl';

import ChartTooltip from '~/components/patterns/charts/components/ChartTooltip';

import {
	defineHighchartsSmallCircleMarkerSymbol,
	setHighchartsGlobalStyles,
} from '~/utilities/highcharts';



class IntervalOverviewChart extends Component {

	constructor(props, context) {
		super(props, context);

		this.chartRef = React.createRef();

		this._calculateTickPositions = this._calculateTickPositions.bind(this);
		this._calculateYAxisRange = this._calculateYAxisRange.bind(this);
	}



	_applyOpacityToLineColor(data) {
		return data.map((seriesData) => {
			seriesData = Object.assign({}, seriesData);

			if (seriesData.color) {
				seriesData = Object.assign({}, seriesData, {
					allowPointSelect: false,
					lineColor: new Highcharts.Color(seriesData.color).setOpacity(0.5).get(),
					marker: {
						lineColor: new Highcharts.Color(seriesData.color).setOpacity(0.5).get(),
					},
				});
			}

			return seriesData;
		});
	}



	_calculateTickPositions({ maximum, minimum }) {
		const interval = (maximum - minimum) / 4;

		const positions = [];

		for (let i = 0; i < 5; i++) {
			positions.push(i * interval);
		}

		return positions;
	}



	_calculateYAxisRange(props) {
		const {
			data,
			maximum,
			minimum,
		} = props;

		const limitValues = typeof maximum === 'function' || typeof minimum === 'function'
			? this._getLimitValues(data)
			: null;

		return {
			maximum: typeof maximum === 'function'
				? maximum(limitValues)
				: maximum,
			minimum: typeof minimum === 'function'
				? minimum(limitValues)
				: minimum,
		};
	}



	_getLimitValues(data) {
		const {
			stacked,
		} = this.props;

		let minValue = 0;
		let maxValue = 0;

		if (stacked && data && data.length > 0) {
			const stackedValues = data[0].data.slice(0);

			for (let i = 1; i < data.length; i++) {
				data[i].data.forEach((value, index) => {
					if (stackedValues[index] === undefined) {
						stackedValues[index] = value;
					} else {
						stackedValues[index] = stackedValues[index] + value;
					}
				});
			}

			minValue = Math.min.apply(Math, stackedValues);
			maxValue = Math.max.apply(Math, stackedValues);
		} else {
			data.forEach((seriesData) => {
				minValue = Math.min(minValue, Math.min.apply(Math, seriesData.data));
				maxValue = Math.max(maxValue, Math.max.apply(Math, seriesData.data));
			});
		}

		return {
			minValue,
			maxValue,
		};
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			data,
			xAxisCaptions,
		} = this.props;

		const {
			data: nextData,
			xAxisCaptions: nextXAxisCaptions,
		} = nextProps;

		if (nextData && nextData.length > 0 && (!Immutable.is(Immutable.fromJS(data), Immutable.fromJS(nextData)) || !Immutable.is(xAxisCaptions, nextXAxisCaptions))) {
			const chart = this.chartRef.current && this.chartRef.current.chart;

			if (chart && chart.series) {
				nextData.forEach((seriesData, seriesIndex) => {
					chart.series[seriesIndex].setData(
						seriesData.data,
						false,
						false,
						true,
					);
				});

				if (!Immutable.is(xAxisCaptions, nextXAxisCaptions)) {
					chart.xAxis[0].setCategories(nextXAxisCaptions.toArray ? nextXAxisCaptions.toArray() : nextXAxisCaptions);
				}

				const {
					maximum,
					minimum,
				} = this._calculateYAxisRange(nextProps);

				chart.yAxis[0].setExtremes(
					minimum,
					(maximum === minimum && maximum === 0) ? 10 : maximum,
				);

				chart.yAxis[0].update({
					tickPositions: this._calculateTickPositions({ maximum, minimum }),
				});

				chart.redraw();
			}
		}
	}



	UNSAFE_componentWillMount() {
		setHighchartsGlobalStyles();
		defineHighchartsSmallCircleMarkerSymbol();
	}



	shouldComponentUpdate() {
		return false;
	}



	render() {
		const {
			chartHeight,
			data,
			disabled,
			intl,
			onClickCallback,
			renderTooltipCallback,
			stacked,
			xAxisCaptions,
		} = this.props;

		const {
			maximum,
			minimum,
		} = this._calculateYAxisRange(this.props);

		const config = {
			chart: {
				backgroundColor: 'transparent',
				height: chartHeight,
				spacing: [10, 0, 0, 0],
				type: 'area',
			},
			plotOptions: {
				area: {
					animation: disabled ? false : {
						duration: 1000,
					},
					cursor: disabled || !onClickCallback ? 'default' : 'pointer',
					fillOpacity: 0.06,
					lineWidth: 2,
					marker: {
						fillColor: '#f8f9fa',
						lineColor: null,
						lineWidth: 2,
						symbol: 'smallCircle', // custom marker
					},
					point: {
						events: {
							// attach click event only on larger screen to prevent displaying of pointer cursor
							click: !disabled && function () {
								const clickedChangeType = this.series.name;
								const clickedValue = this.y;

								if (onClickCallback) {
									onClickCallback(this.x, clickedChangeType, clickedValue, this);
								}
							},
						},
					},
					stacking: stacked ? 'normal' : null,
					states: {
						hover: {
							lineWidthPlus: 0,
						},
						inactive: {
							opacity: 1,
						},
					},
				},
			},
			series: this._applyOpacityToLineColor(data),
			tooltip: {
				animation: false,
				backgroundColor: 'transparent',
				borderWidth: 0,
				enabled: !disabled,
				formatter: function () {
					if (renderTooltipCallback) {
						return renderTooltipCallback(this);
					}

					return ReactDOMServer.renderToString(
						<ChartTooltip color={this.color}>
							{intl.formatNumber(this.y)}
						</ChartTooltip>,
					);
				},
				padding: 0,
				shadow: false,
				shape: 'square',
				useHTML: true,
			},
			xAxis: {
				categories: xAxisCaptions.toArray ? xAxisCaptions.toArray() : xAxisCaptions,
				labels: {
					style: {
						color: '#8595A6',
						fontFamily: '\'Roboto\', sans-serif',
						fontSize: '11px',
						fontWeight: '300',
						lineHeight: '11px',
						textTransform: 'uppercase',
					},
					y: 18,
				},
				lineWidth: 0,
				tickWidth: 0,
				title: {
					text: null,
				},
			},
			yAxis: {
				labels: {
					style: {
						color: '#8595A6',
						fontFamily: '\'Roboto\', sans-serif',
						fontSize: '11px',
						fontWeight: '300',
						lineHeight: '11px',
					},
					x: -10,
				},
				max: (maximum === minimum && maximum === 0) ? 10 : maximum,
				min: minimum,
				tickPositions: this._calculateTickPositions({ maximum, minimum }),
				startOnTick: false,
				title: {
					text: null,
				},
			},
		};

		return (
			<HighchartsReact
				highcharts={Highcharts}
				options={config}
				ref={this.chartRef}
			/>
		);
	}

}

IntervalOverviewChart.defaultProps = {
	stacked: false,
};

IntervalOverviewChart.propTypes = {
	chartHeight: PropTypes.number.isRequired,
	chartWidth: PropTypes.number.isRequired,
	data: PropTypes.array,
	disabled: PropTypes.bool,
	maximum: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.number,
	]).isRequired,
	minimum: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.number,
	]).isRequired,
	stacked: PropTypes.bool,
	xAxisCaptions: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.instanceOf(Immutable.List),
	]).isRequired,
};



export default injectIntl(IntervalOverviewChart);
