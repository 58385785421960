import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import IgnoringModalBodyLayout from '../modals/IgnoringModalBodyLayout';
import IgnoringMessage from '~/components/patterns/messages/embedded/IgnoringMessage';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useIgnoreIssueCategoryOnWholeWebsiteMutation,
	useUnignoreIssueCategoryOnWholeWebsiteMutation,
} from './IgnoreCategoryOnWebsite.gql';

import useTrackIgnoringUpdateInPendo from '~/hooks/useTrackIgnoringUpdateInPendo';

import {
	type IssueCategoryName,
} from '~/model/issuesNew';



const messages = defineMessages({
	descriptionDefault: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignore.description',
	},
	descriptionIgnored: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignoringActive.description',
	},
	ignoreButton: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignore.button',
	},
	stopIgnoringButton: {
		id: 'ui.issueDetail.ignoringModal.scope.general.button.stopIgnoring',
	},
});

const alertWarningMessages = defineMessages({
	default: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignore.bulletList.alerts',
	},
	ignored: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignoringActive.bulletList.alerts',
	},
});

const reportsWarningMessages = defineMessages({
	default: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignore.bulletList.reports',
	},
	ignored: {
		id: 'ui.issueDetail.ignoringModal.scope.website.ignoringActive.bulletList.reports',
	},
});



type Props = {
	isIgnored: boolean,
	issueCategoryName: IssueCategoryName,
	numberOfIssues: number,
	reloadCallback: () => Promise<void>,
	websiteId: CK.WebsiteId,
};

const IgnoreCategoryOnWebsite: React.FC<Props> = (props) => {
	const {
		isIgnored,
		issueCategoryName,
		numberOfIssues,
		reloadCallback,
		websiteId,
	} = props;

	const trackIgnoringUpdateInPendo = useTrackIgnoringUpdateInPendo();

	const [ignoreIssueCategoryOnWholeWebsite] = useIgnoreIssueCategoryOnWholeWebsiteMutation();
	const [unignoreIssueCategoryOnWholeWebsite] = useUnignoreIssueCategoryOnWholeWebsiteMutation();

	const handleSubmit = React.useCallback(
		async () => {
			if (isIgnored) {
				await unignoreIssueCategoryOnWholeWebsite({
					variables: {
						issueCategoryName,
						websiteId,
					},
				});
			} else {
				await ignoreIssueCategoryOnWholeWebsite({
					variables: {
						issueCategoryName,
						websiteId,
					},
				});
			}

			trackIgnoringUpdateInPendo(
				websiteId,
				issueCategoryName,
				isIgnored === false,
				'website',
			);

			await reloadCallback();
		},
		[
			ignoreIssueCategoryOnWholeWebsite,
			isIgnored,
			issueCategoryName,
			reloadCallback,
			trackIgnoringUpdateInPendo,
			unignoreIssueCategoryOnWholeWebsite,
			websiteId,
		],
	);

	const flag = isIgnored ? 'ignored' : 'default';

	return (
		<Form
			defaultDataHasChanged={true}
			onSuccess={handleSubmit}
		>
			<IgnoringModalBodyLayout
				button={(
					<SubmitButton>
						{isIgnored ? (
							<FormattedMessage {...messages.stopIgnoringButton} />
						) : (
							<FormattedMessage {...messages.ignoreButton} />
						)}
					</SubmitButton>
				)}
			>
				{isIgnored ? (
					<IgnoringMessage>
						<Copy
							{...messages.descriptionIgnored}
							values={{
								count__issues: numberOfIssues,
							}}
						/>
					</IgnoringMessage>
				) : (
					<RichText>
						<Copy
							{...messages.descriptionDefault}
							values={{
								count__issues: numberOfIssues,
							}}
						/>
					</RichText>
				)}

				<RichText>
					<ul>
						<li>
							<FormattedMessage
								{...alertWarningMessages[flag]}
								values={{
									count__issues: numberOfIssues,
								}}
							/>
						</li>
						<li>
							<FormattedMessage
								{...reportsWarningMessages[flag]}
								values={{
									count__issues: numberOfIssues,
								}}
							/>
						</li>
					</ul>
				</RichText>
			</IgnoringModalBodyLayout>
		</Form>
	);
};



export default IgnoreCategoryOnWebsite;
