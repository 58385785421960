import React from 'react';

import useFormContext from '~/hooks/useFormContext';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import {
	isFunction,
} from '~/utilities/typeCheck';



type CeilTo = ((value: number) => number) | number | undefined;

function ceilValue(ceilTo: CeilTo, value: number) {
	if (!ceilTo) {
		return value;
	}

	if (isFunction(ceilTo)) {
		return ceilTo(value);
	}

	const ceilingNumber = ceilTo;

	if (value % ceilingNumber !== 0) {
		value = value - (value % ceilingNumber) + ceilingNumber;
	}

	return value;
}



type Props = {
	children: RenderProp<{
		currentStep: number,
		currentValue: number,
		isMaxLimitReached: boolean,
		isMinLimitReached: boolean,
		maxStep: number | false,
		maxValue: number,
		minStep: number | false,
		minValue: number,
		onSliderStepChangeHandler: (sliderStep: number) => void,
		onSliderValueChangeHandler: (value: number) => void,
		sliderMax: number,
	}>,
	ceilTo?: CeilTo,
	maxLimit: number,
	maxValue: number,
	minLimit: number,
	minValue: number,
	name: string,
	numberOfSteps: number,
	onChangeCallback?: (value: number) => void,
	stepGetter?: (value: number) => number,
	value: number,
	valueGetter?: (sliderStep: number) => number,
};

const CountSliderContext: React.FC<Props> = (props) => {
	const {
		ceilTo,
		children,
		maxLimit,
		maxValue,
		minLimit,
		minValue,
		name,
		numberOfSteps,
		onChangeCallback,
		stepGetter,
		value,
		valueGetter,
	} = props;

	const formContext = useFormContext();

	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnFocusHandler = formContext.onFocusHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	React.useEffect(
		() => {
			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnUnmountHandler,
			name,
		],
	);

	const getCorrectedValue = React.useCallback(
		(value) => {
			if (value > maxValue) {
				value = maxValue;
			}

			if (maxLimit && value > maxLimit) {
				value = maxLimit;
			}

			if (value < minValue) {
				value = minValue;
			}

			if (minLimit && value < minLimit) {
				value = minLimit;
			}

			// round value up to next step if needed
			value = ceilValue(ceilTo, value);

			return value;
		},
		[
			ceilTo,
			maxLimit,
			maxValue,
			minLimit,
			minValue,
		],
	);

	const onSliderStepChangeHandler = React.useCallback(
		(sliderStep: number) => {
			let value = valueGetter ? valueGetter(sliderStep) : sliderStep;
			value = getCorrectedValue(value);

			formContextOnFocusHandler(name);

			if (onChangeCallback) {
				onChangeCallback(value);
			}

			formContextOnChangeHandler(name, value);
		},
		[
			formContextOnChangeHandler,
			formContextOnFocusHandler,
			getCorrectedValue,
			name,
			onChangeCallback,
			valueGetter,
		],
	);

	const onSliderValueChangeHandler = React.useCallback(
		(value: number) => {
			value = getCorrectedValue(value);

			if (onChangeCallback) {
				onChangeCallback(value);
			}

			formContextOnChangeHandler(name, value, {
				timeout: 250,
			});
		},
		[
			formContextOnChangeHandler,
			getCorrectedValue,
			name,
			onChangeCallback,
		],
	);

	function getSliderStep(value) {
		return stepGetter ? stepGetter(value) : value;
	}

	const correctedValue = getCorrectedValue(value);

	const currentStep = correctedValue !== null ? getSliderStep(correctedValue) : null;
	const currentValue = correctedValue;

	return (
		<>
			{renderProp(children, {
				currentStep,
				currentValue,
				isMaxLimitReached: currentValue !== null ? currentValue >= maxLimit && currentValue < maxValue : false,
				isMinLimitReached: currentValue !== null ? currentValue <= minLimit && currentValue > minValue : false,
				maxStep: maxLimit ? getSliderStep(maxLimit) : false,
				maxValue,
				minStep: minLimit ? getSliderStep(minLimit) : false,
				minValue,
				onSliderStepChangeHandler,
				onSliderValueChangeHandler,
				sliderMax: numberOfSteps - 1,
			})}
		</>
	);
};



export default CountSliderContext;
