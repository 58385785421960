/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsiteIntegrationsMutationVariables = GraphQL.Exact<{
  enableGoogleSearchConsole: GraphQL.Scalars['Boolean']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteIntegrationsMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteIntegrations: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly googleSearchConsoleUsedProperties: ReadonlyArray<string> | null, readonly id: CK.WebsiteId, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null } } };


export const UpdateWebsiteIntegrationsDocument = gql`
    mutation UpdateWebsiteIntegrations($enableGoogleSearchConsole: Boolean!, $websiteId: WebsiteId!) {
  UpdateWebsiteIntegrations(
    enableGoogleSearchConsole: $enableGoogleSearchConsole
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        googleSearchConsoleUsedProperties
        id
        integrations {
          isNotOwned
          status
          type
        }
      }
    }
  }
}
    `;
export type UpdateWebsiteIntegrationsMutationFn = Apollo.MutationFunction<UpdateWebsiteIntegrationsMutation, UpdateWebsiteIntegrationsMutationVariables>;

/**
 * __useUpdateWebsiteIntegrationsMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteIntegrationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteIntegrationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteIntegrationsMutation, { data, loading, error }] = useUpdateWebsiteIntegrationsMutation({
 *   variables: {
 *      enableGoogleSearchConsole: // value for 'enableGoogleSearchConsole'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteIntegrationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteIntegrationsMutation, UpdateWebsiteIntegrationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteIntegrationsMutation, UpdateWebsiteIntegrationsMutationVariables>(UpdateWebsiteIntegrationsDocument, options);
      }
export type UpdateWebsiteIntegrationsMutationHookResult = ReturnType<typeof useUpdateWebsiteIntegrationsMutation>;
export type UpdateWebsiteIntegrationsMutationResult = Apollo.MutationResult<UpdateWebsiteIntegrationsMutation>;
export type UpdateWebsiteIntegrationsMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteIntegrationsMutation, UpdateWebsiteIntegrationsMutationVariables>;