import React from 'react';

import HealthIcon from '~/components/patterns/icons/HealthIcon';
import IssueValueCard, {
	IssueValueCardFlow as IssueScoreFlow,
	IssueValueCardSize as IssueScoreSize,
	IssueValueCardStyle,
} from '~/components/patterns/issues/IssueValueCard.part';



export enum IssueScoreState {
	Broken = 'open',
	Fixed = 'closed',
	NotApplicable = 'not_applicable',
	NotRequired = 'not_required',
	Unknown = 'unknown',
}

const STATE_LOOK_MAPPING = {
	[ IssueScoreState.Broken ]: IssueValueCardStyle.Informative,
	[ IssueScoreState.Fixed ]: IssueValueCardStyle.Positive,
	[ IssueScoreState.NotApplicable ]: IssueValueCardStyle.Neutral,
	[ IssueScoreState.NotRequired ]: IssueValueCardStyle.Neutral,
	[ IssueScoreState.Unknown ]: IssueValueCardStyle.Neutral,
};

type Props = {
	/** Flow of elements in layout of card */
	flow?: IssueScoreFlow,
	/** Special look for ignored issue */
	isIgnored?: boolean,
	/** Different sizes of card */
	size?: IssueScoreSize,
	/** State of score */
	state?: IssueScoreState,
	value?: number | false,
};



const IssueScore: React.FC<Props> = (props) => {
	const {
		flow = IssueScoreFlow.Row,
		isIgnored = false,
		size = IssueScoreSize.Default,
		state = IssueScoreState.Unknown,
	} = props;

	let value = props.value?.toString();

	if (props.value === undefined) {
		value = '–';
	}

	if (props.value === false) {
		value = undefined;
	}

	let cardStyle = STATE_LOOK_MAPPING[state];

	let iconSize = 20;

	if (size === IssueScoreSize.Small) {
		iconSize = 18;
	}

	if (size === IssueScoreSize.Large) {
		iconSize = 22;
	}

	const icon = (
		<HealthIcon size={iconSize} />
	);

	if (isIgnored) {
		cardStyle = IssueValueCardStyle.Neutral;
	}

	return (
		<IssueValueCard
			flow={flow}
			icon={icon}
			size={size}
			style={cardStyle}
			value={value}
		/>
	);
};

export {
	IssueScoreFlow,
	IssueScoreSize,
};

export default IssueScore;
