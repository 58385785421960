import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const KeyIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M23.113,5.954,21.849,4.69l.881-.88A1.914,1.914,0,1,0,20.023,1.1L9.04,12.085a6.014,6.014,0,1,0,2.707,2.707l3.739-3.737.762.762a1.75,1.75,0,1,0,2.475-2.475L17.96,8.58l1.416-1.417L20.64,8.426a1.788,1.788,0,0,0,2.473,0A1.751,1.751,0,0,0,23.113,5.954Zm-16.737,8.5a3,3,0,1,1-3,3A3,3,0,0,1,6.376,14.456Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default KeyIconBuilder;
