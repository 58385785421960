import React from 'react';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import CountSlider from '~/components/patterns/forms/fields/CountSlider';
import CountSliderContext from './abstractBuilders/CountSliderContext';
import CountSliderFieldLayout from '~/components/patterns/forms/fieldParts/countSliders/CountSliderFieldLayout';
import CountSliderInput from './abstractBuilders/CountSliderInput';

import useFormContext from '~/hooks/useFormContext';



type Props = {
	handlerBubble?: React.ReactNode,
	maxValue: number,
	minValue: number,
	name: string,
	numberOfSteps: number,
	width?: React.CSSProperties['width'],
};

const PercentageField: React.FC<Props> = (props) => {
	const {
		handlerBubble,
		maxValue,
		minValue,
		name,
		numberOfSteps,
		width,
	} = props;

	const formContext = useFormContext();

	const value = formContext.values[name] ?? formContext.defaultValues[name] ?? 0;

	return (
		<CountSliderContext
			maxLimit={maxValue}
			maxValue={maxValue}
			minLimit={minValue}
			minValue={minValue}
			name={name}
			numberOfSteps={numberOfSteps}
			value={value}
		>
			{({ currentStep, currentValue, maxValue, minValue, onSliderStepChangeHandler, onSliderValueChangeHandler, sliderMax }) => (
				<CountSliderFieldLayout
					slider={(
						<CountSlider
							handlerBubble={handlerBubble}
							max={sliderMax}
							onChangeCallback={onSliderStepChangeHandler}
							value={currentStep}
						/>
					)}
					sliderInfo={(
						<AttachedElement
							element="%"
							inline={true}
						>
							<CountSliderInput
								max={maxValue}
								min={minValue}
								name={name}
								onSliderValueChangeHandler={onSliderValueChangeHandler}
								value={currentValue}
								width={46}
							/>
						</AttachedElement>
					)}
					sliderInfoWidth={65}
					width={width}
				/>
			)}
		</CountSliderContext>
	);
};



export default PercentageField;
