import {
	post,
} from './api/API';

import FormError from '~/utilities/FormError';



export function runAction({
	action,
	input,
	options = {},
}) {
	return post(
		'/action?action=' + action,
		{
			[action]: input,
		},
		Object.assign({}, {
			serverErrorHandler: () => new FormError('server-error'),
			timeout: 120000,
			timeoutHandler: () => new FormError('timeout'),
		}, options),
	).then((response) => {
		return response.actions[action];
	});
}
