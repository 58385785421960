/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageNumberOfIncomingInternalLinksQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageNumberOfIncomingInternalLinksQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly numberOfIncomingInternalLinks: number | null } | null };


export const PageNumberOfIncomingInternalLinksDocument = gql`
    query PageNumberOfIncomingInternalLinks($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    numberOfIncomingInternalLinks
  }
}
    `;

/**
 * __usePageNumberOfIncomingInternalLinksQuery__
 *
 * To run a query within a React component, call `usePageNumberOfIncomingInternalLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageNumberOfIncomingInternalLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageNumberOfIncomingInternalLinksQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageNumberOfIncomingInternalLinksQuery(baseOptions: Apollo.QueryHookOptions<PageNumberOfIncomingInternalLinksQuery, PageNumberOfIncomingInternalLinksQueryVariables> & ({ variables: PageNumberOfIncomingInternalLinksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageNumberOfIncomingInternalLinksQuery, PageNumberOfIncomingInternalLinksQueryVariables>(PageNumberOfIncomingInternalLinksDocument, options);
      }
export function usePageNumberOfIncomingInternalLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageNumberOfIncomingInternalLinksQuery, PageNumberOfIncomingInternalLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageNumberOfIncomingInternalLinksQuery, PageNumberOfIncomingInternalLinksQueryVariables>(PageNumberOfIncomingInternalLinksDocument, options);
        }
export function usePageNumberOfIncomingInternalLinksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageNumberOfIncomingInternalLinksQuery, PageNumberOfIncomingInternalLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageNumberOfIncomingInternalLinksQuery, PageNumberOfIncomingInternalLinksQueryVariables>(PageNumberOfIncomingInternalLinksDocument, options);
        }
export type PageNumberOfIncomingInternalLinksQueryHookResult = ReturnType<typeof usePageNumberOfIncomingInternalLinksQuery>;
export type PageNumberOfIncomingInternalLinksLazyQueryHookResult = ReturnType<typeof usePageNumberOfIncomingInternalLinksLazyQuery>;
export type PageNumberOfIncomingInternalLinksSuspenseQueryHookResult = ReturnType<typeof usePageNumberOfIncomingInternalLinksSuspenseQuery>;
export type PageNumberOfIncomingInternalLinksQueryResult = Apollo.QueryResult<PageNumberOfIncomingInternalLinksQuery, PageNumberOfIncomingInternalLinksQueryVariables>;