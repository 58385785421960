/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountStateQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountStateQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly state: GraphQL.AccountState } | null };


export const AccountStateDocument = gql`
    query AccountState($accountId: AccountId!) {
  account(id: $accountId) {
    id
    state
  }
}
    `;

/**
 * __useAccountStateQuery__
 *
 * To run a query within a React component, call `useAccountStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountStateQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountStateQuery(baseOptions: Apollo.QueryHookOptions<AccountStateQuery, AccountStateQueryVariables> & ({ variables: AccountStateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountStateQuery, AccountStateQueryVariables>(AccountStateDocument, options);
      }
export function useAccountStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountStateQuery, AccountStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountStateQuery, AccountStateQueryVariables>(AccountStateDocument, options);
        }
export function useAccountStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountStateQuery, AccountStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountStateQuery, AccountStateQueryVariables>(AccountStateDocument, options);
        }
export type AccountStateQueryHookResult = ReturnType<typeof useAccountStateQuery>;
export type AccountStateLazyQueryHookResult = ReturnType<typeof useAccountStateLazyQuery>;
export type AccountStateSuspenseQueryHookResult = ReturnType<typeof useAccountStateSuspenseQuery>;
export type AccountStateQueryResult = Apollo.QueryResult<AccountStateQuery, AccountStateQueryVariables>;