import type Immutable from 'immutable';
import React from 'react';

import ChartContainer, {
	CHART_HEIGHT,
} from '~/components/atoms/charts/components/ChartContainer';
import ColumnChart from '~/components/atoms/charts/charts/ColumnChart';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';

import useViewportType from '~/hooks/useViewportType';

import {
	calculateTickIntervalNew,
} from '~/model/websiteStatistics/charts/utilities';



type Props = {
	categories: ReadonlyArray<{
		name: string,
		color?: string,
	}>,
	data: any,
	name: string,
	numberOfYAxisPoints: number,
	onClickCallback?: () => void,
	renderTooltip?: (input: {
		category: string,
		count: number,
	}) => React.ReactNode,
	xAxisCaptions: ReadonlyArray<string> | Immutable.List<string>,
};

const MultiColumnChart: React.FC<Props> = (props) => {
	const {
		categories,
		data,
		name,
		numberOfYAxisPoints,
		onClickCallback = null,
		renderTooltip = null,
		xAxisCaptions,
	} = props;

	const viewportType = useViewportType();

	const actualRenderTooltip = React.useCallback(
		({ count, seriesName }) => {
			if (renderTooltip === null) {
				return;
			}

			return renderTooltip({
				category: seriesName,
				count,
			});
		},
		[
			renderTooltip,
		],
	);

	if (!data) {
		return (
			<SquareSkeleton
				height={CHART_HEIGHT}
				style={SquareSkeletonStyle.Transparent}
			/>
		);
	}

	return (
		<ChartContainer
			chart={(
				<ColumnChart
					animate={false}
					barWidth={10}
					data={categories.map((category) => {
						const items = data.map((item) => item.toJS ? item.get(category.name) : item[category.name]);

						return {
							name: category.name,
							data: items.toJS
								? items.toJS()
								: items,
						};
					})}
					key={name}
					maximum={({ maxValue }) => calculateTickIntervalNew(maxValue * 1.1, numberOfYAxisPoints)}
					minBarLength={3}
					minimum={0}
					onClickCallback={onClickCallback}
					renderTooltipCallback={renderTooltip !== null ? actualRenderTooltip : undefined}
					seriesColors={categories.map((category) => category.color)}
					viewportType={viewportType}
					xAxisCaptions={xAxisCaptions}
					yAxisPoints={numberOfYAxisPoints}
				/>
			)}
			name={name}
			padded={true}
			type="column-chart"
		/>
	);
};



export default React.memo(MultiColumnChart);
