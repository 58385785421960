import {
	useSelector,
} from 'react-redux';

import CK from '~/types/contentking';

import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ManageableColumns,
	isCustomElementColumn,
	isEnrichmentFieldColumn,
} from '~/model/pagesColumns';

import {
	selectedColumnsSelector,
} from '~/state/pagesColumns/selectors';

import memoizeById from '~/utilities/memoizeById';



function listBaselineColumns(
	customElementDefinitions: ReturnType<typeof useWebsiteCustomElementDefinitions>,
	enrichmentFieldDefinitions: ReturnType<typeof useWebsiteEnrichmentFieldDefinitions>,
) {
	let result: Array<CK.PagesColumn> = [...ManageableColumns];

	result = result.concat(
		customElementDefinitions
			.listAll()
			.map((customElementDefinition) => customElementDefinition.column),
	);

	result = result.concat(
		enrichmentFieldDefinitions
			.listAll()
			.map((enrichmentFieldDefinition) => enrichmentFieldDefinition.column),
	);

	return result;
}

function createDefaultCurrentColumns(
	baselineColumns: ReadonlyArray<CK.PagesColumn>,
) {
	return baselineColumns.map((column) => {
		const enabledColumns: Array<CK.PagesColumn> = [
			CK.PagesCommonColumn.H1,
			CK.PagesCommonColumn.Health,
			CK.PagesCommonColumn.IsDisallowedInRobotsTxt,
			CK.PagesCommonColumn.IsIndexable,
			CK.PagesCommonColumn.IsIndexableDueToMetaRobots,
			CK.PagesCommonColumn.IsLinked,
			CK.PagesCommonColumn.IsSecured,
			CK.PagesCommonColumn.MetaDescription,
			CK.PagesCommonColumn.Relevance,
			CK.PagesCommonColumn.Segments,
			CK.PagesCommonColumn.Title,
			CK.PagesCommonColumn.Type,
			CK.PagesCommonColumn.Url,
		];

		return {
			name: column,
			enabled: (
				isCustomElementColumn(column)
				|| isEnrichmentFieldColumn(column)
				|| enabledColumns.includes(column)
			),
		};
	});
}

const format = memoizeById(
	(
		currentColumnSet: ReturnType<typeof useCurrentColumnSet>,
		customElementDefinitions: ReturnType<typeof useWebsiteCustomElementDefinitions>,
		enrichmentFieldDefinitions: ReturnType<typeof useWebsiteEnrichmentFieldDefinitions>,
		selectedColumns: ReadonlyArray<{
			enabled: boolean,
			name: CK.PagesColumn,
		}> | null,
	) => {
		function createCurrentColumns(
		) {
			if (selectedColumns !== null) {
				return selectedColumns;
			}

			const baselineColumns = listBaselineColumns(
				customElementDefinitions,
				enrichmentFieldDefinitions,
			);

			if (currentColumnSet === null) {
				return createDefaultCurrentColumns(baselineColumns);
			}

			const result = baselineColumns.concat(
				currentColumnSet
					.columns
					.filter((column) => !baselineColumns.includes(column.name))
					.map((column) => column.name),
			);

			return result.map((columnName) => {
				const column = currentColumnSet.columns.find((column) => column.name === columnName);

				return {
					name: columnName,
					enabled: column
						? column.enabled
						: false,
				};
			}).sort((columnA, columnB) => {
				const columnAOrder = currentColumnSet.columns.findIndex((column) => column.name === columnA.name);
				const columnBOrder = currentColumnSet.columns.findIndex((column) => column.name === columnB.name);

				if (columnAOrder === -1 && columnBOrder === -1) {
					return ManageableColumns.indexOf(columnA.name) < ManageableColumns.indexOf(columnB.name) ? -1 : 1;
				} else if (columnAOrder === -1) {
					return 1;
				} else if (columnBOrder === -1) {
					return -1;
				}

				return columnAOrder < columnBOrder ? -1 : 1;
			});
		}

		return createCurrentColumns();
	},
);



function useCurrentColumns() {
	const websiteId = useWebsiteId();

	const currentColumnSet = useCurrentColumnSet();
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const selectedColumns = useSelector(selectedColumnsSelector);

	return format(
		websiteId,
		currentColumnSet,
		customElementDefinitions,
		enrichmentFieldDefinitions,
		selectedColumns,
	);
}



export default useCurrentColumns;
