import React from 'react';

import type CK from '~/types/contentking';

import {
	useRevokeAgencyAccessMutation,
} from './useRevokeAgencyAccess.gql';



function useRevokeAgencyAccess() {
	const [revokeAgencyAccess] = useRevokeAgencyAccessMutation();

	return React.useCallback(
		async ({
			agencyId,
			clientId,
		}: {
			agencyId: CK.AccountId,
			clientId: CK.AccountId,
		}) => {
			await revokeAgencyAccess({
				variables: {
					agencyId,
					clientId,
				},
			});
		},
		[
			revokeAgencyAccess,
		],
	);
}



export default useRevokeAgencyAccess;
