import {
	useUserRoyalGroupQuery,
} from './useUserRoyalGroup.gql';

import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserRoyalGroup(legacyUserId: string | null) {
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const { data } = useUserPropertiesQuery(
		useUserRoyalGroupQuery,
		legacyUserId,
		{
			poll: false,
			skip: kingdomAdminFeatures.areVisible === false,
		},
	);

	return data?.user?.royalGroup ?? null;
}



export default useUserRoyalGroup;
