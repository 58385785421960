import classNames from 'classnames';
import React from 'react';

import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	fromLabel: {
		id: 'ui.calendarRange.rangeSelector.fromLabel',
		defaultMessage: 'From',
	},
	toLabel: {
		id: 'ui.calendarRange.rangeSelector.toLabel',
		defaultMessage: 'To',
	},
});



type Props = {
	endDate: string,
	isEndDateSelected?: boolean,
	isStartDateSelected?: boolean,
	onEndDateSelectionCallback: () => void,
	onStartDateSelectionCallback: () => void,
	startDate: string,
};



const DateRangeSelector: React.FC<Props> = (props) => {
	const {
		endDate,
		isEndDateSelected,
		isStartDateSelected,
		onEndDateSelectionCallback,
		onStartDateSelectionCallback,
		startDate,
	} = props;

	return (
		<div className="date-range-selector">
			<div className="date-range-selector__range">
				<div className="date-range-selector__label">
					<FormattedMessage {...messages.fromLabel} />
				</div>
				<div
					className={classNames({
						'date-range-selector__input-field': true,
						'date-range-selector__input-field--selected': isStartDateSelected,
					})}
					onClick={onStartDateSelectionCallback}
					tabIndex={-1}
				>
					{startDate}
				</div>
			</div>

			<div className="date-range-selector__range">
				<div className="date-range-selector__label">
					<FormattedMessage {...messages.toLabel} />
				</div>
				<div
					className={classNames({
						'date-range-selector__input-field': true,
						'date-range-selector__input-field--selected': isEndDateSelected,
					})}
					onClick={onEndDateSelectionCallback}
					tabIndex={-1}
				>
					{endDate}
				</div>
			</div>
		</div>
	);
};



export default DateRangeSelector;
