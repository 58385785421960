import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';



const messages = defineMessages({
	[GraphQL.FetchingLocation.LeasewebEu]: {
		id: 'ui.fetchingLocations.theNetherlands',
	},
	[GraphQL.FetchingLocation.LeasewebUk]: {
		id: 'ui.fetchingLocations.unitedKingdom',
	},
	[GraphQL.FetchingLocation.LeasewebUs]: {
		id: 'ui.fetchingLocations.unitedStates',
	},
});



type Props = {
	fetchingLocation: GraphQL.FetchingLocation,
};

const FetchingLocationName: React.FC<Props> = (props) => {
	const {
		fetchingLocation,
	} = props;

	const message = messages[fetchingLocation];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default FetchingLocationName;
