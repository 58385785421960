import React from 'react';

import NoName from '~/components/app/NoName';



type Props = {
	email: string,
	firstName: string | null,
	lastName: string | null,
};

const FullUserName: React.FC<Props> = (props) => {
	const {
		email,
		firstName,
		lastName,
	} = props;

	if (firstName && firstName.trim().length && lastName && lastName.trim().length) {
		return (
			<span>{firstName}&nbsp;{lastName}</span>
		);
	} else if (firstName && firstName.trim().length) {
		return (
			<span>{firstName}</span>
		);
	} else if (lastName && lastName.trim().length) {
		return (
			<span>{lastName}</span>
		);
	}

	return (
		<NoName email={email} />
	);
};



export default FullUserName;
