import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import InternalLink, {
	InternalLinkStyle as SimpleLogoutLinkStyle,
} from '~/components/patterns/links/InternalLink';

import useLogout from '~/hooks/useLogout';



const messages = defineMessages({
	logout: {
		id: 'ui.logout.simple',
	},
});



type Props = {
	style?: SimpleLogoutLinkStyle,
};

const SimpleLogoutLink: React.FC<Props> = (props) => {
	const {
		style,
	} = props;

	const logout = useLogout();

	return (
		<InternalLink
			onClickCallback={logout}
			style={style}
		>
			<FormattedMessage {...messages.logout} />
		</InternalLink>
	);
};



export default SimpleLogoutLink;

export {
	SimpleLogoutLinkStyle,
};
