/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IgnoreIssueCategoryOnWholeWebsiteMutationVariables = GraphQL.Exact<{
  issueCategoryName: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IgnoreIssueCategoryOnWholeWebsiteMutation = { readonly __typename?: 'Mutation', readonly IgnoreIssueCategoryOnWholeWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type UnignoreIssueCategoryOnWholeWebsiteMutationVariables = GraphQL.Exact<{
  issueCategoryName: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignoreIssueCategoryOnWholeWebsiteMutation = { readonly __typename?: 'Mutation', readonly UnignoreIssueCategoryOnWholeWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const IgnoreIssueCategoryOnWholeWebsiteDocument = gql`
    mutation IgnoreIssueCategoryOnWholeWebsite($issueCategoryName: String!, $websiteId: WebsiteId!) {
  IgnoreIssueCategoryOnWholeWebsite(
    issueCategory: $issueCategoryName
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type IgnoreIssueCategoryOnWholeWebsiteMutationFn = Apollo.MutationFunction<IgnoreIssueCategoryOnWholeWebsiteMutation, IgnoreIssueCategoryOnWholeWebsiteMutationVariables>;

/**
 * __useIgnoreIssueCategoryOnWholeWebsiteMutation__
 *
 * To run a mutation, you first call `useIgnoreIssueCategoryOnWholeWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreIssueCategoryOnWholeWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreIssueCategoryOnWholeWebsiteMutation, { data, loading, error }] = useIgnoreIssueCategoryOnWholeWebsiteMutation({
 *   variables: {
 *      issueCategoryName: // value for 'issueCategoryName'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIgnoreIssueCategoryOnWholeWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<IgnoreIssueCategoryOnWholeWebsiteMutation, IgnoreIssueCategoryOnWholeWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgnoreIssueCategoryOnWholeWebsiteMutation, IgnoreIssueCategoryOnWholeWebsiteMutationVariables>(IgnoreIssueCategoryOnWholeWebsiteDocument, options);
      }
export type IgnoreIssueCategoryOnWholeWebsiteMutationHookResult = ReturnType<typeof useIgnoreIssueCategoryOnWholeWebsiteMutation>;
export type IgnoreIssueCategoryOnWholeWebsiteMutationResult = Apollo.MutationResult<IgnoreIssueCategoryOnWholeWebsiteMutation>;
export type IgnoreIssueCategoryOnWholeWebsiteMutationOptions = Apollo.BaseMutationOptions<IgnoreIssueCategoryOnWholeWebsiteMutation, IgnoreIssueCategoryOnWholeWebsiteMutationVariables>;
export const UnignoreIssueCategoryOnWholeWebsiteDocument = gql`
    mutation UnignoreIssueCategoryOnWholeWebsite($issueCategoryName: String!, $websiteId: WebsiteId!) {
  UnignoreIssueCategoryOnWholeWebsite(
    issueCategory: $issueCategoryName
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type UnignoreIssueCategoryOnWholeWebsiteMutationFn = Apollo.MutationFunction<UnignoreIssueCategoryOnWholeWebsiteMutation, UnignoreIssueCategoryOnWholeWebsiteMutationVariables>;

/**
 * __useUnignoreIssueCategoryOnWholeWebsiteMutation__
 *
 * To run a mutation, you first call `useUnignoreIssueCategoryOnWholeWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignoreIssueCategoryOnWholeWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignoreIssueCategoryOnWholeWebsiteMutation, { data, loading, error }] = useUnignoreIssueCategoryOnWholeWebsiteMutation({
 *   variables: {
 *      issueCategoryName: // value for 'issueCategoryName'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignoreIssueCategoryOnWholeWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UnignoreIssueCategoryOnWholeWebsiteMutation, UnignoreIssueCategoryOnWholeWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignoreIssueCategoryOnWholeWebsiteMutation, UnignoreIssueCategoryOnWholeWebsiteMutationVariables>(UnignoreIssueCategoryOnWholeWebsiteDocument, options);
      }
export type UnignoreIssueCategoryOnWholeWebsiteMutationHookResult = ReturnType<typeof useUnignoreIssueCategoryOnWholeWebsiteMutation>;
export type UnignoreIssueCategoryOnWholeWebsiteMutationResult = Apollo.MutationResult<UnignoreIssueCategoryOnWholeWebsiteMutation>;
export type UnignoreIssueCategoryOnWholeWebsiteMutationOptions = Apollo.BaseMutationOptions<UnignoreIssueCategoryOnWholeWebsiteMutation, UnignoreIssueCategoryOnWholeWebsiteMutationVariables>;