import React from 'react';

import {
	EditableFormWrapperContext,
} from '~/components/atoms/forms/basis/EditableFormWrapperBase';



type Props = {
	children?: React.ReactNode,
};

const DisplayPart: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const editableFormWrapperContext = React.useContext(EditableFormWrapperContext);

	if (editableFormWrapperContext === null) {
		throw new Error(
			`DisplayPart can't be used outside of EditableFormWrapper`,
		);
	}

	const {
		editMode,
	} = editableFormWrapperContext;

	if (editMode) {
		return null;
	}

	return (
		<div>
			{children}
		</div>
	);
};



export default DisplayPart;
