import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAllowedPlansQuery,
} from './useAllowedPlans.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAllowedPlans(accountId: CK.AccountId | null): ReadonlyArray<GraphQL.AccountPlan> | null {
	const { data } = useAccountPropertiesQuery(
		useAllowedPlansQuery,
		accountId,
	);

	return data?.account?.allowedPlans ?? null;
}



export default useAllowedPlans;
