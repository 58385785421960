import React from 'react';



type Props = {
	children: React.ReactNode,
};

const FormTitle: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<h2 className="form-title">
			{children}
		</h2>
	);
};



export default FormTitle;
