import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import SelectAlertGroupStep from './SelectAlertGroupStep';
import SelectAlertTypeStep from './SelectAlertTypeStep';
import SetupCustomElementAlertStep from './SetupCustomElementAlertStep';
import SetupEnrichmentFieldAlertStep from './SetupEnrichmentFieldAlertStep';
import SetupEnteredOrLeftAlertStep from './SetupEnteredOrLeftAlertStep';
import SetupSettingsStep from './SetupSettingsStep';

import useNavigation from '~/hooks/useNavigation';
import useUrlState from '~/hooks/useUrlState';

import {
	ALERT_ENRICHMENT_FIELD_CHANGED,
	ALERT_GROUP_CUSTOM_ELEMENT_ALERTS,
	ALERT_GROUP_ENRICHMENT_FIELD_ALERTS,
	ALERT_GROUP_ENTERED_OR_LEFT_ALERTS,
	ALERT_GROUP_LIGHTHOUSE_ALERTS,
	ALERT_GROUP_PAGE_ALERTS,
	ALERT_GROUP_PLATFORM_ALERTS,
	ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
	ALERT_PROPERTY_CHANGED,
} from '~/model/alerts';



const messages = defineMessages({
	fakeSettingsBreadcrumb: {
		id: 'ui.alertsConfiguration.modal.create.fakeSettings.breadcrumb',
	},
	fakeTriggerBreadcrumb: {
		id: 'ui.alertsConfiguration.modal.create.fakeTrigger.breadcrumb',
	},
});



export type AlertGroup =
	| typeof ALERT_GROUP_CUSTOM_ELEMENT_ALERTS
	| typeof ALERT_GROUP_ENRICHMENT_FIELD_ALERTS
	| typeof ALERT_GROUP_ENTERED_OR_LEFT_ALERTS
	| typeof ALERT_GROUP_LIGHTHOUSE_ALERTS
	| typeof ALERT_GROUP_PAGE_ALERTS
	| typeof ALERT_GROUP_PLATFORM_ALERTS;



type Props = {
	closeCallback: () => void,
};

const CreateAlertDefinitionsModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const { navigate } = useNavigation();
	const urlState = useUrlState();

	const [alertGroup, setAlertGroup] = React.useState<AlertGroup | null>(
		urlState.params.type ?? null,
	);

	const [preparedAlertDefinitions, setPreparedAlertDefinitions] = React.useState<Array<Record<string, any>>>([]);

	const modal = React.useRef<MultiStepModalRef | null>(null);

	function updateAlertGroup(alertGroup: AlertGroup) {
		setAlertGroup(alertGroup);
		navigate({
			routeName: urlState.name,
			routeParams: {
				...urlState.params,
				type: alertGroup,
			},
			options: {
				replace: true,
			},
		});
	}

	return (
		<MultiStepModal
			initialStep={alertGroup !== null ? 1 : 0}
			ref={modal}
		>
			<SelectAlertGroupStep
				alertGroup={alertGroup}
				onContinue={(nextAlertGroup) => {
					updateAlertGroup(nextAlertGroup as AlertGroup);

					if (alertGroup !== nextAlertGroup) {
						setPreparedAlertDefinitions([]);
					}

					setTimeout(() => {
						modal.current?.goToNextStep();
					});
				}}
			/>

			{(
				alertGroup === ALERT_GROUP_PAGE_ALERTS
				|| alertGroup === ALERT_GROUP_PLATFORM_ALERTS
				|| alertGroup === ALERT_GROUP_LIGHTHOUSE_ALERTS
			) && (
				<SelectAlertTypeStep
					alertGroup={alertGroup}
					onSubmit={(alertTypes) => {
						setPreparedAlertDefinitions(
							alertTypes.map((alertType) => ({
								alertType,
							})),
						);

						modal.current?.goToNextStep();
					}}
				/>
			)}

			{alertGroup === ALERT_GROUP_ENTERED_OR_LEFT_ALERTS && (
				<SetupEnteredOrLeftAlertStep
					onSubmit={({ segmentChangeSizePercentage, segmentChangeType, segmentName }) => {
						setPreparedAlertDefinitions([
							{
								alertType: ALERT_PAGE_ENTERED_OR_LEFT_SEGMENT,
								settings: {
									segmentName,
									segmentChangeType,
									segmentChangeSizeNumberOfPages: null,
									segmentChangeSizePercentage,
								},
							},
						]);

						modal.current?.goToNextStep();
					}}
				/>
			)}

			{alertGroup === ALERT_GROUP_CUSTOM_ELEMENT_ALERTS && (
				<SetupCustomElementAlertStep
					onSubmit={(settings) => {
						setPreparedAlertDefinitions([
							{
								alertType: ALERT_PROPERTY_CHANGED,
								settings,
							},
						]);

						modal.current?.goToNextStep();
					}}
				/>
			)}

			{alertGroup === ALERT_GROUP_ENRICHMENT_FIELD_ALERTS && (
				<SetupEnrichmentFieldAlertStep
					onSubmit={(settings) => {
						setPreparedAlertDefinitions([
							{
								alertType: ALERT_ENRICHMENT_FIELD_CHANGED,
								settings,
							},
						]);

						modal.current?.goToNextStep();
					}}
				/>
			)}

			{alertGroup !== null && (
				<SetupSettingsStep
					alertGroup={alertGroup}
					onSubmit={closeCallback}
					preparedAlertDefinitions={preparedAlertDefinitions}
				/>
			)}

			{alertGroup === null && (
				// Add "fake" steps to show `trigger` & `settings` in the breadcrumbs
				// before an alertGroup selection is made.
				<>
					<MultiStepModalStep
						breadcrumb={(
							<FormattedMessage {...messages.fakeTriggerBreadcrumb} />
						)}
						name="fake-trigger"
					/>
					<MultiStepModalStep
						breadcrumb={(
							<FormattedMessage {...messages.fakeSettingsBreadcrumb} />
						)}
						name="fake-settings"
					/>
				</>
			)}
		</MultiStepModal>
	);
};



export default CreateAlertDefinitionsModal;
