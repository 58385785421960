import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AddMultipleWebsitesStep from './AddMultipleWebsitesStep/AddMultipleWebsitesStep';
import AddSingleWebsiteStep from './AddSingleWebsiteStep/AddSingleWebsiteStep';
import AddWebsiteModalAlertsAndReportsStep from '~/components/app/AddWebsiteModalAlertsAndReportsStep';
import AddWebsiteModalIntegrationsStep from '~/components/app/AddWebsiteModalIntegrationsStep';
import AddWebsiteModalSuccessStep from '~/components/app/AddWebsiteModalSuccessStep';
import FlashMessage, {
	FlashMessageType,
} from '~/components/patterns/messages/popup/FlashMessage';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import {
	DisabledOverlayDueToChurnedAccount,
	DisabledOverlayDueToDisallowedManagement,
	DisabledOverlayDueToInsufficientPermissions,
	DisabledOverlayDueToInsufficientPermissionsForKingdomAdmin,
	DisabledOverlayDueToMaximumWebsitesReached,
	DisabledOverlayDueToPaymentIssues,
} from './AnnouncementOverlay';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsChurned from '~/hooks/useAccountIsChurned';
import useAddingWebsiteDisallowedReason, {
	AddingWebsiteDisallowedReason,
} from '~/hooks/useAddingWebsiteDisallowedReason';
import useFlashMessage from '~/hooks/useFlashMessage';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useNavigation from '~/hooks/useNavigation';
import useUrlState from '~/hooks/useUrlState';



const messages = defineMessages({
	websiteAddedFlashMessage: {
		id: 'ui.websites.new.flashMessages.websiteAdded',
	},
});



const AddWebsiteModal: React.FC = () => {
	const accountId = useAccountId();
	const accountIsChurned = useAccountIsChurned(accountId);
	const addingWebsiteDisallowedReason = useAddingWebsiteDisallowedReason(accountId);
	const { navigate } = useNavigation();
	const urlState = useUrlState();

	const ref = React.useRef<MultiStepModalRef | null>(null);
	const [websiteId, setWebsiteId] = React.useState<CK.WebsiteId | null>(urlState.params.websiteId ?? null);
	const [domains, setDomains] = React.useState<Array<string>>([]);

	let initialStep = 0;

	if (urlState.params.step === 'integrations') {
		initialStep = 1;
	} else if (urlState.params.step === 'alerts_and_reports') {
		initialStep = 2;
	}

	const canAddWebsite = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.AddWebsite,
	);

	const {
		flashMessage: websiteAddedFlashMessage,
		showFlashMessage: showWebsiteAddedFlashMessage,
	} = useFlashMessage(
		<FlashMessage type={FlashMessageType.Success}>
			<FormattedMessage
				{...messages.websiteAddedFlashMessage}
				values={{
					countWebsites: domains.length,
				}}
			/>
		</FlashMessage>,
	);

	let disabledOverlay: React.ReactNode;

	if (accountId !== null && ref.current?.isStepActive(0)) {
		if (canAddWebsite.noBecauseInsufficientPermissions) {
			disabledOverlay = (
				<DisabledOverlayDueToInsufficientPermissions />
			);
		} else if (canAddWebsite.noBecauseInsufficientPermissionsForKingdomAdmin) {
			disabledOverlay = (
				<DisabledOverlayDueToInsufficientPermissionsForKingdomAdmin />
			);
		} else if (canAddWebsite.noBecauseManaged) {
			disabledOverlay = (
				<DisabledOverlayDueToDisallowedManagement />
			);
		} else if (accountIsChurned) {
			disabledOverlay = (
				<DisabledOverlayDueToChurnedAccount
					accountId={accountId}
				/>
			);
		} else if (addingWebsiteDisallowedReason === AddingWebsiteDisallowedReason.PaymentIssues) {
			disabledOverlay = (
				<DisabledOverlayDueToPaymentIssues
					accountId={accountId}
				/>
			);
		} else if (addingWebsiteDisallowedReason === AddingWebsiteDisallowedReason.MaximumWebsitesLimitReached) {
			disabledOverlay = (
				<DisabledOverlayDueToMaximumWebsitesReached
					accountId={accountId}
				/>
			);
		}
	}

	const isDisabled = !!disabledOverlay;

	return (
		<MultiStepModal
			disabledContentOverlay={disabledOverlay}
			flashMessage={websiteAddedFlashMessage}
			initialStep={initialStep}
			isContentDisabled={!!disabledOverlay}
			key={urlState.params.bulk ? 'bulk' : 'single'}
			ref={ref}
		>
			{urlState.params.bulk ? (
				<AddMultipleWebsitesStep
					accountId={accountId}
					domains={domains}
					onContinueCallback={(domains) => {
						setDomains(domains);
						showWebsiteAddedFlashMessage();

						navigate({
							routeName: 'account.websites.new',
							routeParams: {
								accountId,
								step: 'alerts_and_reports',
								bulk: true,
							},
							options: {
								replace: true,
							},
						});

						ref.current?.goToNextStep();
					}}
				/>
			) : (
				<>
					<AddSingleWebsiteStep
						isDisabled={isDisabled}
						onContinueCallback={(websiteId, domain) => {
							setWebsiteId(websiteId);
							setDomains([domain]);

							showWebsiteAddedFlashMessage();

							navigate({
								routeName: 'account.websites.new',
								routeParams: {
									accountId,
									step: 'integrations',
									websiteId,
								},
								options: {
									replace: true,
								},
							});

							ref.current?.goToNextStep();
						}}
						onSelectAllDomains={(domains) => {
							setDomains(domains);

							navigate({
								routeName: 'account.websites.new',
								routeParams: {
									accountId,
									bulk: true,
								},
								options: {
									replace: true,
								},
							});
						}}
					/>

					<AddWebsiteModalIntegrationsStep
						onContinueCallback={() => {
							if (websiteId === null) {
								return;
							}

							navigate({
								routeName: 'account.websites.new',
								routeParams: {
									accountId,
									step: 'alerts_and_reports',
									websiteId,
								},
								options: {
									replace: true,
								},
							});

							ref.current?.goToNextStep();
						}}
						websiteId={websiteId}
					/>
				</>
			)}

			<AddWebsiteModalAlertsAndReportsStep
				accountId={accountId}
				domains={domains}
				isBulk={!!urlState.params.bulk}
				onContinueCallback={() => {
					ref.current?.goToNextStep();
				}}
				websiteId={websiteId}
			/>

			<AddWebsiteModalSuccessStep
				domains={domains}
				websiteId={websiteId}
			/>

		</MultiStepModal>
	);
};



export default AddWebsiteModal;
