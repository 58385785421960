import classNames from 'classnames';
import React from 'react';



export enum IconOnlyButtonSize {
	Default = 'default',
	Large = 'large',
}



type Props = {
	/** Possibility to disable hover effect */
	hoverEffect?: boolean,
	/** Attached icon */
	icon: React.ReactElement,
	/** Enable ignoring whole pointer events on this component. Component will became just presentation of the icon. */
	ignorePointerEvents?: boolean,
	/** Callback triggered on component click */
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
	/** Callback triggered on component mouse enter */
	onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void,
	/** Callback triggered on component mouse leave */
	onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void,
	/** Custom title visible on button hover */
	title?: string,
	size?: IconOnlyButtonSize,
};

const IconOnlyButton: React.FC<Props> = (props) => {
	const {
		hoverEffect = true,
		icon,
		ignorePointerEvents,
		onClick,
		onMouseEnter,
		onMouseLeave,
		size = IconOnlyButtonSize.Default,
		title,
	} = props;

	const componentClasses = classNames({
		'icon-button': true,
		'icon-button--large': size === IconOnlyButtonSize.Large,
		'icon-button--no-pointer-events': ignorePointerEvents,
		'icon-button--no-hover': !hoverEffect,
	});

	let iconComponent = icon;

	if (size === IconOnlyButtonSize.Large) {
		iconComponent = React.cloneElement(icon, {
			size: 28,
		});
	}

	return (
		<button
			className={componentClasses}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			tabIndex={-1}
			title={title}
			type="button"
		>
			{iconComponent}
		</button>
	);
};



export default IconOnlyButton;
