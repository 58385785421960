import React from 'react';

import BasicIcon, {
	type BasicIconType,
} from '~/components/patterns/icons/BasicIcon';



type Props = {
	children?: React.ReactNode,
	iconType?: BasicIconType,
};

const AuthTitle: React.FC<Props> = (props) => {
	const {
		children,
		iconType,
	} = props;

	return (
		<h1 className="auth-title">
			{iconType !== undefined && (
				<span className="auth-title__icon">
					<BasicIcon
						color="#fff"
						size={36}
						type={iconType}
					/>
				</span>
			)}
			<span className="auth-title__title">
				{children}
			</span>
		</h1>
	);
};



export default AuthTitle;
