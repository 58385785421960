/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinishUnpaidSignupMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type FinishUnpaidSignupMutation = { readonly __typename?: 'Mutation', readonly FinishUnpaidSignup: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly paymentStatus: { readonly __typename?: 'AccountPaymentStatus', readonly isOkay: boolean, readonly stripeAction: { readonly __typename?: 'StripePaymentAction', readonly actionType: GraphQL.StripePaymentActionType, readonly clientSecret: string | null, readonly paymentMethod: string | null } | null } | null } | null } } };


export const FinishUnpaidSignupDocument = gql`
    mutation FinishUnpaidSignup($accountId: AccountId!) {
  FinishUnpaidSignup(accountId: $accountId) {
    query {
      account(id: $accountId) {
        id
        paymentStatus {
          isOkay
          stripeAction {
            actionType
            clientSecret
            paymentMethod
          }
        }
      }
    }
  }
}
    `;
export type FinishUnpaidSignupMutationFn = Apollo.MutationFunction<FinishUnpaidSignupMutation, FinishUnpaidSignupMutationVariables>;

/**
 * __useFinishUnpaidSignupMutation__
 *
 * To run a mutation, you first call `useFinishUnpaidSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishUnpaidSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishUnpaidSignupMutation, { data, loading, error }] = useFinishUnpaidSignupMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useFinishUnpaidSignupMutation(baseOptions?: Apollo.MutationHookOptions<FinishUnpaidSignupMutation, FinishUnpaidSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishUnpaidSignupMutation, FinishUnpaidSignupMutationVariables>(FinishUnpaidSignupDocument, options);
      }
export type FinishUnpaidSignupMutationHookResult = ReturnType<typeof useFinishUnpaidSignupMutation>;
export type FinishUnpaidSignupMutationResult = Apollo.MutationResult<FinishUnpaidSignupMutation>;
export type FinishUnpaidSignupMutationOptions = Apollo.BaseMutationOptions<FinishUnpaidSignupMutation, FinishUnpaidSignupMutationVariables>;