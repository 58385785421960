/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RobotsTxtBoxQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type RobotsTxtBoxQuery = { readonly __typename?: 'Query', readonly list: ReadonlyArray<{ readonly __typename?: 'RobotsTxtRevision', readonly id: CK.ID, readonly createdAt: CK.Timestamp }> | null, readonly platformData: { readonly __typename?: 'PlatformData', readonly id: CK.ID, readonly canonicalRobotsTxtIsPrioritized: boolean, readonly canonicalRobotsTxtLegacyUrlId: number | null, readonly robotsTxtUrls: ReadonlyArray<string>, readonly robotsTxtXmlSitemapReference: ReadonlyArray<string> | null, readonly robotsTxtSearchEngineActivity: ReadonlyArray<{ readonly __typename?: 'SearchEngineActivityAtMoment', readonly lastVisit: CK.Timestamp | null, readonly searchEngine: GraphQL.SearchEngineActivitySearchEngine, readonly status: GraphQL.SearchEngineActivityAtMomentStatus }> | null } | null };


export const RobotsTxtBoxDocument = gql`
    query RobotsTxtBox($websiteId: WebsiteId!) {
  list: robotsTxtRevisionsList(websiteId: $websiteId) {
    id
    createdAt
  }
  platformData(websiteId: $websiteId) {
    id
    canonicalRobotsTxtIsPrioritized
    canonicalRobotsTxtLegacyUrlId
    robotsTxtSearchEngineActivity {
      lastVisit
      searchEngine
      status
    }
    robotsTxtUrls
    robotsTxtXmlSitemapReference
  }
}
    `;

/**
 * __useRobotsTxtBoxQuery__
 *
 * To run a query within a React component, call `useRobotsTxtBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useRobotsTxtBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRobotsTxtBoxQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useRobotsTxtBoxQuery(baseOptions: Apollo.QueryHookOptions<RobotsTxtBoxQuery, RobotsTxtBoxQueryVariables> & ({ variables: RobotsTxtBoxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RobotsTxtBoxQuery, RobotsTxtBoxQueryVariables>(RobotsTxtBoxDocument, options);
      }
export function useRobotsTxtBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RobotsTxtBoxQuery, RobotsTxtBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RobotsTxtBoxQuery, RobotsTxtBoxQueryVariables>(RobotsTxtBoxDocument, options);
        }
export function useRobotsTxtBoxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RobotsTxtBoxQuery, RobotsTxtBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RobotsTxtBoxQuery, RobotsTxtBoxQueryVariables>(RobotsTxtBoxDocument, options);
        }
export type RobotsTxtBoxQueryHookResult = ReturnType<typeof useRobotsTxtBoxQuery>;
export type RobotsTxtBoxLazyQueryHookResult = ReturnType<typeof useRobotsTxtBoxLazyQuery>;
export type RobotsTxtBoxSuspenseQueryHookResult = ReturnType<typeof useRobotsTxtBoxSuspenseQuery>;
export type RobotsTxtBoxQueryResult = Apollo.QueryResult<RobotsTxtBoxQuery, RobotsTxtBoxQueryVariables>;