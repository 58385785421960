import Immutable from 'immutable';

import {
	WINDOW_HAS_FOCUS,
} from '~/actions';



function createDefaultState() {
	return Immutable.fromJS({
		hasFocus: false,
	});
}



export function _window(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case WINDOW_HAS_FOCUS: {
			const {
				value,
			} = action;

			state = state.set('hasFocus', value);

			break;
		}

	}

	return state;
}
