export default <const>{
	'countries.AC': `Ascension Island`,
	'countries.AD': `Andorra`,
	'countries.AE': `United Arab Emirates`,
	'countries.AF': `Afghanistan`,
	'countries.AG': `Antigua & Barbuda`,
	'countries.AI': `Anguilla`,
	'countries.AL': `Albania`,
	'countries.AM': `Armenia`,
	'countries.AO': `Angola`,
	'countries.AQ': `Antarctica`,
	'countries.AR': `Argentina`,
	'countries.AS': `American Samoa`,
	'countries.AT': `Austria`,
	'countries.AU': `Australia`,
	'countries.AW': `Aruba`,
	'countries.AX': `Åland Islands`,
	'countries.AZ': `Azerbaijan`,
	'countries.BA': `Bosnia & Herzegovina`,
	'countries.BB': `Barbados`,
	'countries.BD': `Bangladesh`,
	'countries.BE': `Belgium`,
	'countries.BF': `Burkina Faso`,
	'countries.BG': `Bulgaria`,
	'countries.BH': `Bahrain`,
	'countries.BI': `Burundi`,
	'countries.BJ': `Benin`,
	'countries.BL': `St. Barthélemy`,
	'countries.BM': `Bermuda`,
	'countries.BN': `Brunei`,
	'countries.BO': `Bolivia`,
	'countries.BQ': `Caribbean Netherlands`,
	'countries.BR': `Brazil`,
	'countries.BS': `Bahamas`,
	'countries.BT': `Bhutan`,
	'countries.BV': `Bouvet Island`,
	'countries.BW': `Botswana`,
	'countries.BY': `Belarus`,
	'countries.BZ': `Belize`,
	'countries.CA': `Canada`,
	'countries.CC': `Cocos (Keeling) Islands`,
	'countries.CD': `Congo - Kinshasa`,
	'countries.CF': `Central African Republic`,
	'countries.CG': `Congo - Brazzaville`,
	'countries.CH': `Switzerland`,
	'countries.CI': `Côte d’Ivoire`,
	'countries.CK': `Cook Islands`,
	'countries.CL': `Chile`,
	'countries.CM': `Cameroon`,
	'countries.CN': `China`,
	'countries.CO': `Colombia`,
	'countries.CP': `Clipperton Island`,
	'countries.CR': `Costa Rica`,
	'countries.CU': `Cuba`,
	'countries.CW': `Curaçao`,
	'countries.CX': `Christmas Island`,
	'countries.CY': `Cyprus`,
	'countries.CZ': `Czech Republic`,
	'countries.DE': `Germany`,
	'countries.DG': `Diego Garcia`,
	'countries.DJ': `Djibouti`,
	'countries.DK': `Denmark`,
	'countries.DM': `Dominica`,
	'countries.DO': `Dominican Republic`,
	'countries.DZ': `Algeria`,
	'countries.EA': `Ceuta & Melilla`,
	'countries.EC': `Ecuador`,
	'countries.EE': `Estonia`,
	'countries.EG': `Egypt`,
	'countries.EH': `Western Sahara`,
	'countries.ER': `Eritrea`,
	'countries.ES': `Spain`,
	'countries.ET': `Ethiopia`,
	'countries.FI': `Finland`,
	'countries.FJ': `Fiji`,
	'countries.FK': `Falkland Islands`,
	'countries.FM': `Micronesia`,
	'countries.FO': `Faroe Islands`,
	'countries.FR': `France`,
	'countries.GA': `Gabon`,
	'countries.GB': `United Kingdom`,
	'countries.GD': `Grenada`,
	'countries.GE': `Georgia`,
	'countries.GF': `French Guiana`,
	'countries.GG': `Guernsey`,
	'countries.GH': `Ghana`,
	'countries.GI': `Gibraltar`,
	'countries.GL': `Greenland`,
	'countries.GM': `Gambia`,
	'countries.GN': `Guinea`,
	'countries.GP': `Guadeloupe`,
	'countries.GQ': `Equatorial Guinea`,
	'countries.GR': `Greece`,
	'countries.GS': `South Georgia & South Sandwich Islands`,
	'countries.GT': `Guatemala`,
	'countries.GU': `Guam`,
	'countries.GW': `Guinea-Bissau`,
	'countries.GY': `Guyana`,
	'countries.HK': `Hong Kong SAR China`,
	'countries.HM': `Heard & McDonald Islands`,
	'countries.HN': `Honduras`,
	'countries.HR': `Croatia`,
	'countries.HT': `Haiti`,
	'countries.HU': `Hungary`,
	'countries.IC': `Canary Islands`,
	'countries.ID': `Indonesia`,
	'countries.IE': `Ireland`,
	'countries.IL': `Israel`,
	'countries.IM': `Isle of Man`,
	'countries.IN': `India`,
	'countries.IO': `British Indian Ocean Territory`,
	'countries.IQ': `Iraq`,
	'countries.IR': `Iran`,
	'countries.IS': `Iceland`,
	'countries.IT': `Italy`,
	'countries.JE': `Jersey`,
	'countries.JM': `Jamaica`,
	'countries.JO': `Jordan`,
	'countries.JP': `Japan`,
	'countries.KE': `Kenya`,
	'countries.KG': `Kyrgyzstan`,
	'countries.KH': `Cambodia`,
	'countries.KI': `Kiribati`,
	'countries.KM': `Comoros`,
	'countries.KN': `St. Kitts & Nevis`,
	'countries.KP': `North Korea`,
	'countries.KR': `South Korea`,
	'countries.KW': `Kuwait`,
	'countries.KY': `Cayman Islands`,
	'countries.KZ': `Kazakhstan`,
	'countries.LA': `Laos`,
	'countries.LB': `Lebanon`,
	'countries.LC': `St. Lucia`,
	'countries.LI': `Liechtenstein`,
	'countries.LK': `Sri Lanka`,
	'countries.LR': `Liberia`,
	'countries.LS': `Lesotho`,
	'countries.LT': `Lithuania`,
	'countries.LU': `Luxembourg`,
	'countries.LV': `Latvia`,
	'countries.LY': `Libya`,
	'countries.MA': `Morocco`,
	'countries.MC': `Monaco`,
	'countries.MD': `Moldova`,
	'countries.ME': `Montenegro`,
	'countries.MF': `St. Martin`,
	'countries.MG': `Madagascar`,
	'countries.MH': `Marshall Islands`,
	'countries.MK': `Macedonia`,
	'countries.ML': `Mali`,
	'countries.MM': `Myanmar (Burma)`,
	'countries.MN': `Mongolia`,
	'countries.MO': `Macau SAR China`,
	'countries.MP': `Northern Mariana Islands`,
	'countries.MQ': `Martinique`,
	'countries.MR': `Mauritania`,
	'countries.MS': `Montserrat`,
	'countries.MT': `Malta`,
	'countries.MU': `Mauritius`,
	'countries.MV': `Maldives`,
	'countries.MW': `Malawi`,
	'countries.MX': `Mexico`,
	'countries.MY': `Malaysia`,
	'countries.MZ': `Mozambique`,
	'countries.NA': `Namibia`,
	'countries.NC': `New Caledonia`,
	'countries.NE': `Niger`,
	'countries.NF': `Norfolk Island`,
	'countries.NG': `Nigeria`,
	'countries.NI': `Nicaragua`,
	'countries.NL': `Netherlands`,
	'countries.NO': `Norway`,
	'countries.NP': `Nepal`,
	'countries.NR': `Nauru`,
	'countries.NU': `Niue`,
	'countries.NZ': `New Zealand`,
	'countries.OM': `Oman`,
	'countries.PA': `Panama`,
	'countries.PE': `Peru`,
	'countries.PF': `French Polynesia`,
	'countries.PG': `Papua New Guinea`,
	'countries.PH': `Philippines`,
	'countries.PK': `Pakistan`,
	'countries.PL': `Poland`,
	'countries.PM': `St. Pierre & Miquelon`,
	'countries.PN': `Pitcairn Islands`,
	'countries.PR': `Puerto Rico`,
	'countries.PS': `Palestinian Territories`,
	'countries.PT': `Portugal`,
	'countries.PW': `Palau`,
	'countries.PY': `Paraguay`,
	'countries.QA': `Qatar`,
	'countries.QO': `Outlying Oceania`,
	'countries.RE': `Réunion`,
	'countries.RO': `Romania`,
	'countries.RS': `Serbia`,
	'countries.RU': `Russia`,
	'countries.RW': `Rwanda`,
	'countries.SA': `Saudi Arabia`,
	'countries.SB': `Solomon Islands`,
	'countries.SC': `Seychelles`,
	'countries.SD': `Sudan`,
	'countries.SE': `Sweden`,
	'countries.SG': `Singapore`,
	'countries.SH': `St. Helena`,
	'countries.SI': `Slovenia`,
	'countries.SJ': `Svalbard & Jan Mayen`,
	'countries.SK': `Slovakia`,
	'countries.SL': `Sierra Leone`,
	'countries.SM': `San Marino`,
	'countries.SN': `Senegal`,
	'countries.SO': `Somalia`,
	'countries.SR': `Suriname`,
	'countries.ST': `São Tomé & Príncipe`,
	'countries.SV': `El Salvador`,
	'countries.SX': `Sint Maarten`,
	'countries.SY': `Syria`,
	'countries.SZ': `Swaziland`,
	'countries.TA': `Tristan da Cunha`,
	'countries.TC': `Turks & Caicos Islands`,
	'countries.TD': `Chad`,
	'countries.TF': `French Southern Territories`,
	'countries.TG': `Togo`,
	'countries.TH': `Thailand`,
	'countries.TJ': `Tajikistan`,
	'countries.TK': `Tokelau`,
	'countries.TL': `Timor-Leste`,
	'countries.TM': `Turkmenistan`,
	'countries.TN': `Tunisia`,
	'countries.TO': `Tonga`,
	'countries.TR': `Turkey`,
	'countries.TT': `Trinidad & Tobago`,
	'countries.TV': `Tuvalu`,
	'countries.TW': `Taiwan`,
	'countries.TZ': `Tanzania`,
	'countries.UA': `Ukraine`,
	'countries.UG': `Uganda`,
	'countries.UM': `U.S. Outlying Islands`,
	'countries.US': `United States`,
	'countries.UY': `Uruguay`,
	'countries.UZ': `Uzbekistan`,
	'countries.VA': `Vatican City`,
	'countries.VC': `St. Vincent & Grenadines`,
	'countries.VE': `Venezuela`,
	'countries.VG': `British Virgin Islands`,
	'countries.VI': `U.S. Virgin Islands`,
	'countries.VN': `Vietnam`,
	'countries.VU': `Vanuatu`,
	'countries.WF': `Wallis & Futuna`,
	'countries.WS': `Samoa`,
	'countries.YE': `Yemen`,
	'countries.YT': `Mayotte`,
	'countries.ZA': `South Africa`,
	'countries.ZM': `Zambia`,
	'countries.ZW': `Zimbabwe`,
};
