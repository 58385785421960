import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import throttle from 'lodash/throttle';



class ScrollableBox extends Component {

	constructor(props, context) {
		super(props, context);

		this._handleScroll = throttle(this._handleScroll.bind(this), 50);

		this.state = {
			hasBottomShadow: false,
			hasTopShadow: false,
		};
	}



	_handleScroll() {
		const scrollableArea = this.scrollableAreaRef;
		const body = this.bodyRef;
		let hasBottomShadow = false;
		let hasTopShadow = false;
		const bodyHeight = body.offsetHeight;

		if (scrollableArea.offsetHeight < bodyHeight) {
			hasTopShadow = scrollableArea.scrollTop > 0;
			hasBottomShadow = bodyHeight - scrollableArea.scrollTop > scrollableArea.offsetHeight;
		}

		this.setState({
			hasTopShadow,
			hasBottomShadow,
		});
	}



	componentDidMount() {
		this._handleScroll();
	}



	render() {
		const {
			children,
			maxHeight,
		} = this.props;

		const {
			hasBottomShadow,
			hasTopShadow,
		} = this.state;

		const componentClasses = classNames({
			'scrollable-box': true,
			'scrollable-box--has-top-shadow': hasTopShadow,
			'scrollable-box--has-bottom-shadow': hasBottomShadow,
		});

		return (
			<section
				className={componentClasses}
			>
				<div
					className="scrollable-box__scrollable-area js-scrollable"
					onScroll={this._handleScroll}
					ref={(scrollableAreaRef) => this.scrollableAreaRef = scrollableAreaRef}
					style={{
						maxHeight,
					}}
				>
					<div
						className="scrollable-box__body"
						ref={(bodyRef) => this.bodyRef = bodyRef}
					>
						{children}
					</div>
				</div>
			</section>
		);
	}

}

ScrollableBox.defaultProps = {
	maxHeight: 380,
};

ScrollableBox.propTypes = {
	maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};



export default ScrollableBox;
