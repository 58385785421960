import classNames from 'classnames';
import React from 'react';

import Achievement from './Achievement.part';
import RichText from '~/components/patterns/typography/RichText';



type Props = {
	/** List of achievements */
	achievements?: Array<{
		isDone?: boolean,
		label: React.ReactNode,
		onClick: () => void,
	}>,
	/** Optional text visible bellow title */
	text?: React.ReactNode,
	/** Title of overview */
	title: React.ReactNode,
};



const ProfileCompletenessOverview: React.FC<Props> = (props) => {
	const {
		achievements,
		text,
		title,
	} = props;

	const componentClasses = classNames({
		'profile-completeness': true,
	});

	return (
		<div className={componentClasses}>
			<h3 className="profile-completeness__title">
				{title}
			</h3>
			{text && (
				<div className="profile-completeness__message">
					<RichText>
						{text}
					</RichText>
				</div>
			)}
			{achievements && (
				<div className="profile-completeness__achievements">
					{achievements.map((achievement, index) => (
						<Achievement
							isDone={achievement.isDone || false}
							key={'achievement-' + index}
							label={achievement.label}
							onClick={achievement.onClick}
						/>
					))}
				</div>
			)}
		</div>
	);
};



export default ProfileCompletenessOverview;
