import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountOrganizationTeamsQuery,
} from './useAccountOrganizationTeams.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountOrganizationTeams(accountId: CK.AccountId | null): GraphQL.Account['organizationTeams'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountOrganizationTeamsQuery,
		accountId,
	);

	return data?.account?.organizationTeams ?? null;
}



export default useAccountOrganizationTeams;
