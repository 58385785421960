import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';



const messages = defineMessages({
	buttonLabel: {
		id: 'ui.alertsConfiguration.buttons.createAlert.label',
	},
});



type Props = {
	hasDefinitions: boolean,
	websiteId: CK.WebsiteId,
};

const CreateAlertDefinitionsButton: React.FC<Props> = (props) => {
	const {
		hasDefinitions,
		websiteId,
	} = props;

	return (
		<Button
			icon={(
				<BasicIcon type={BasicIconType.Plus} />
			)}
			iconColor={hasDefinitions ? undefined : '#ffffff'}
			linkRouteName="website.alerts.configuration.createDefinitions"
			linkRouteParams={{
				websiteId,
			}}
			style={hasDefinitions ? ButtonStyle.Hollow : ButtonStyle.Action}
		>
			<FormattedMessage {...messages.buttonLabel} />
		</Button>
	);
};



export default CreateAlertDefinitionsButton;
