import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RobotsTxtIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g id="issue_robots">
					<g id="Platform">
						<g id="[1440]-Platform-MissingStates">
							<g id="issues">
								<g id="Cards">
									<g id="issueCardCategoryOverview-Copy">
										<g id="thumbnail">
											<g id="clipboard">
												<polyline
													id="Stroke-771"
													points="20.7954375 14.998875 14.9998125 14.998875 14.9998125 50.623875 44.9998125 50.623875 44.9998125 14.998875 39.1048125 14.998875"
													stroke={color}
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth={(60 / size) * 2}
												>
												</polyline>
												<path
													d="M33.69825,11.251125 L33.69825,11.251125 C33.69825,9.17925 32.01825,7.501125 29.94825,7.501125 L29.946375,7.501125 C27.876375,7.501125 26.19825,9.17925 26.19825,11.24925 L26.19825,11.251125 C26.19825,12.286125 25.35825,13.126125 24.32325,13.126125 L23.563875,13.126125 C21.913875,13.126125 20.575125,14.463 20.575125,16.113 L20.575125,15.938625 C20.575125,17.491125 21.83325,18.74925 23.387625,18.74925 L36.5145,18.74925 C38.067,18.74925 39.325125,17.491125 39.325125,15.938625 L39.325125,16.113 C39.325125,14.463 37.98825,13.126125 36.33825,13.126125 L35.57325,13.126125 C34.53825,13.126125 33.69825,12.286125 33.69825,11.251125 L33.69825,11.251125 Z"
													id="Stroke-772"
													stroke={color}
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth={(60 / size) * 2}
												>
												</path>
												<polyline
													id="Stroke-773"
													points="36.445125 18.75 39.37575 18.75 39.37575 45 20.62575 45 20.62575 18.75 23.387625 18.75"
													stroke={color}
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth={(60 / size) * 2}
												>
												</polyline>
												<path
													d="M26.251125,26.25 L33.751125,26.25"
													id="Stroke-774"
													stroke={color}
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth={(60 / size) * 2}
												>
												</path>
												<path
													d="M26.251125,31.875 L33.751125,31.875"
													id="Stroke-775"
													stroke={color}
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth={(60 / size) * 2}
												>
												</path>
												<path
													d="M26.251125,37.5 L33.751125,37.5"
													id="Stroke-776"
													stroke={color}
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth={(60 / size) * 2}
												>
												</path>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default RobotsTxtIconBuilder;

