import React from 'react';

import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';



type Props = {
	/** Hint explanation attached to term */
	explanation?: React.ReactNode,
	children: React.ReactNode,
};



const Term: React.FC<Props> = (props) => {
	const {
		explanation,
	} = props;

	let content = props.children;

	if (explanation) {
		content = (
			<Hint
				attachment={HintAttachment.Center}
				blurDelay={200}
				hoverDelay={250}
				popup={explanation}
				targetCustomCursor={false}
			>
				{content}
			</Hint>
		);
	}

	return (
		<div className="term">
			{content}
		</div>
	);
};



export default Term;
