import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PagesIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
			>
				<path
					d="m26 25.5934156h-26v-25.5934156h26z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				>
					<path d="m17.0639625 18.3952675h3.251625v-13.59570227h-11.3758125v2.40018251" />
					<path d="m8.93945 11.1971193h4.8758125" />
					<path d="m8.93945 13.5965021h4.8758125" />
					<path d="m8.93945 15.9958848h4.8758125" />
					<path d="m17.064125 20.7962498h-11.3758125v-13.59570227h11.3758125z" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 26,
	viewBoxWidth: 26,
};



export default PagesIconBuilder;

