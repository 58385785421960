import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	TYPE_MISSING,
	TYPE_NO_DATA,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	TYPE_UNREACHABLE,
} from '~/model/pages';



const messages = defineMessages({
	[TYPE_MISSING]: {
		id: 'ui.contentOverview.pageTypes.missing',
	},
	[TYPE_NO_DATA]: {
		id: 'ui.contentOverview.pageTypes.unknown',
	},
	[TYPE_PAGE]: {
		id: 'ui.contentOverview.pageTypes.page',
	},
	[TYPE_REDIRECT]: {
		id: 'ui.contentOverview.pageTypes.redirect',
	},
	[TYPE_SERVER_ERROR]: {
		id: 'ui.contentOverview.pageTypes.error',
	},
	[TYPE_UNREACHABLE]: {
		id: 'ui.contentOverview.pageTypes.unreachable',
	},
});



type Props = {
	pageType: (
		| typeof TYPE_MISSING
		| typeof TYPE_NO_DATA
		| typeof TYPE_PAGE
		| typeof TYPE_REDIRECT
		| typeof TYPE_SERVER_ERROR
		| typeof TYPE_UNREACHABLE
		| string
	),
};

const PageTypeName: React.FC<Props> = (props) => {
	const {
		pageType,
	} = props;

	if (messages[pageType]) {
		return (
			<FormattedMessage {...messages[pageType]} />
		);
	}

	return (
		<FormattedMessage {...messages[TYPE_NO_DATA]} />
	);
};



export default PageTypeName;
