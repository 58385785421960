/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSendingOfAlertsAndReportsFromWebsiteMutationVariables = GraphQL.Exact<{
  invitations: ReadonlyArray<GraphQL.UpdateSendingOfAlertsAndReportsFromWebsiteInvitation> | GraphQL.UpdateSendingOfAlertsAndReportsFromWebsiteInvitation;
  users: ReadonlyArray<GraphQL.UpdateSendingOfAlertsAndReportsFromWebsiteUser> | GraphQL.UpdateSendingOfAlertsAndReportsFromWebsiteUser;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateSendingOfAlertsAndReportsFromWebsiteMutation = { readonly __typename?: 'Mutation', readonly UpdateSendingOfAlertsAndReportsFromWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly connectedClientAccounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId }>, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly id: CK.InvitationId, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }> }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> }> } | null, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly alertDefinitions: ReadonlyArray<{ readonly __typename?: 'AlertDefinition', readonly id: CK.AlertDefinitionId, readonly recipients: ReadonlyArray<string> }> } | null } } };

export type UpdateSendingOfAlertsAndReportsFromWebsitesMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  domains: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  invitations: ReadonlyArray<GraphQL.UpdateSendingOfAlertsAndReportsFromWebsiteInvitation> | GraphQL.UpdateSendingOfAlertsAndReportsFromWebsiteInvitation;
  users: ReadonlyArray<GraphQL.UpdateSendingOfAlertsAndReportsFromWebsiteUser> | GraphQL.UpdateSendingOfAlertsAndReportsFromWebsiteUser;
}>;


export type UpdateSendingOfAlertsAndReportsFromWebsitesMutation = { readonly __typename?: 'Mutation', readonly UpdateSendingOfAlertsAndReportsFromWebsites: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly connectedClientAccounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId }>, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly id: CK.InvitationId, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }> }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> }> } | null } } };


export const UpdateSendingOfAlertsAndReportsFromWebsiteDocument = gql`
    mutation UpdateSendingOfAlertsAndReportsFromWebsite($invitations: [UpdateSendingOfAlertsAndReportsFromWebsiteInvitation!]!, $users: [UpdateSendingOfAlertsAndReportsFromWebsiteUser!]!, $websiteId: WebsiteId!) {
  UpdateSendingOfAlertsAndReportsFromWebsite(
    invitations: $invitations
    users: $users
    websiteId: $websiteId
  ) {
    query {
      authenticatedSession {
        accounts {
          connectedClientAccounts {
            id
          }
          id
          invitations {
            id
            websiteEmailSettings {
              alertEmailsSetup
              isWeeklyReportEnabled
              websiteId
            }
            websites {
              id
            }
          }
          memberships {
            account {
              id
            }
            user {
              email
              id
              legacyId
              websiteEmailSettings {
                alertEmailsSetup
                isWeeklyReportEnabled
                websiteId
              }
            }
            websites {
              id
            }
          }
        }
      }
      website(id: $websiteId) {
        alertDefinitions {
          id
          recipients
        }
        id
      }
    }
  }
}
    `;
export type UpdateSendingOfAlertsAndReportsFromWebsiteMutationFn = Apollo.MutationFunction<UpdateSendingOfAlertsAndReportsFromWebsiteMutation, UpdateSendingOfAlertsAndReportsFromWebsiteMutationVariables>;

/**
 * __useUpdateSendingOfAlertsAndReportsFromWebsiteMutation__
 *
 * To run a mutation, you first call `useUpdateSendingOfAlertsAndReportsFromWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendingOfAlertsAndReportsFromWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendingOfAlertsAndReportsFromWebsiteMutation, { data, loading, error }] = useUpdateSendingOfAlertsAndReportsFromWebsiteMutation({
 *   variables: {
 *      invitations: // value for 'invitations'
 *      users: // value for 'users'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateSendingOfAlertsAndReportsFromWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSendingOfAlertsAndReportsFromWebsiteMutation, UpdateSendingOfAlertsAndReportsFromWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSendingOfAlertsAndReportsFromWebsiteMutation, UpdateSendingOfAlertsAndReportsFromWebsiteMutationVariables>(UpdateSendingOfAlertsAndReportsFromWebsiteDocument, options);
      }
export type UpdateSendingOfAlertsAndReportsFromWebsiteMutationHookResult = ReturnType<typeof useUpdateSendingOfAlertsAndReportsFromWebsiteMutation>;
export type UpdateSendingOfAlertsAndReportsFromWebsiteMutationResult = Apollo.MutationResult<UpdateSendingOfAlertsAndReportsFromWebsiteMutation>;
export type UpdateSendingOfAlertsAndReportsFromWebsiteMutationOptions = Apollo.BaseMutationOptions<UpdateSendingOfAlertsAndReportsFromWebsiteMutation, UpdateSendingOfAlertsAndReportsFromWebsiteMutationVariables>;
export const UpdateSendingOfAlertsAndReportsFromWebsitesDocument = gql`
    mutation UpdateSendingOfAlertsAndReportsFromWebsites($accountId: AccountId!, $domains: [String!]!, $invitations: [UpdateSendingOfAlertsAndReportsFromWebsiteInvitation!]!, $users: [UpdateSendingOfAlertsAndReportsFromWebsiteUser!]!) {
  UpdateSendingOfAlertsAndReportsFromWebsites(
    accountId: $accountId
    domains: $domains
    invitations: $invitations
    users: $users
  ) {
    query {
      authenticatedSession {
        accounts {
          connectedClientAccounts {
            id
          }
          id
          invitations {
            id
            websiteEmailSettings {
              alertEmailsSetup
              isWeeklyReportEnabled
              websiteId
            }
            websites {
              id
            }
          }
          memberships {
            account {
              id
            }
            user {
              email
              id
              legacyId
              websiteEmailSettings {
                alertEmailsSetup
                isWeeklyReportEnabled
                websiteId
              }
            }
            websites {
              id
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateSendingOfAlertsAndReportsFromWebsitesMutationFn = Apollo.MutationFunction<UpdateSendingOfAlertsAndReportsFromWebsitesMutation, UpdateSendingOfAlertsAndReportsFromWebsitesMutationVariables>;

/**
 * __useUpdateSendingOfAlertsAndReportsFromWebsitesMutation__
 *
 * To run a mutation, you first call `useUpdateSendingOfAlertsAndReportsFromWebsitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendingOfAlertsAndReportsFromWebsitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendingOfAlertsAndReportsFromWebsitesMutation, { data, loading, error }] = useUpdateSendingOfAlertsAndReportsFromWebsitesMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      domains: // value for 'domains'
 *      invitations: // value for 'invitations'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useUpdateSendingOfAlertsAndReportsFromWebsitesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSendingOfAlertsAndReportsFromWebsitesMutation, UpdateSendingOfAlertsAndReportsFromWebsitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSendingOfAlertsAndReportsFromWebsitesMutation, UpdateSendingOfAlertsAndReportsFromWebsitesMutationVariables>(UpdateSendingOfAlertsAndReportsFromWebsitesDocument, options);
      }
export type UpdateSendingOfAlertsAndReportsFromWebsitesMutationHookResult = ReturnType<typeof useUpdateSendingOfAlertsAndReportsFromWebsitesMutation>;
export type UpdateSendingOfAlertsAndReportsFromWebsitesMutationResult = Apollo.MutationResult<UpdateSendingOfAlertsAndReportsFromWebsitesMutation>;
export type UpdateSendingOfAlertsAndReportsFromWebsitesMutationOptions = Apollo.BaseMutationOptions<UpdateSendingOfAlertsAndReportsFromWebsitesMutation, UpdateSendingOfAlertsAndReportsFromWebsitesMutationVariables>;