import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeSnippets from '~/components/patterns/structuredValues/CodeSnippets';
import Value from './Value';



const messages = defineMessages({
	metaBingbot: {
		id: 'ui.contentData.meta_bingbot',
	},
	metaGooglebot: {
		id: 'ui.contentData.meta_googlebot',
	},
	metaRobots: {
		id: 'ui.contentData.meta_robots',
	},
	metaSlurp: {
		id: 'ui.contentData.meta_slurp',
	},
	metaYandex: {
		id: 'ui.contentData.meta_yandex',
	},
	xRobotsTag: {
		id: 'ui.contentData.x_robots_tag',
	},
});



type Props = {
	context: {
		elements: Record<string, ReadonlyArray<string>>,
	},
};

const MetaRobotsCase: React.FC<Props> = (props) => {
	const {
		context,
	} = props;

	const snippets = Object.entries(context.elements).map(
		([elementType, elements]) => elements.map((element, i) => ({
			label: (
				<span>
					<FormattedMessage {...messages[elementType]} />
					{elements.length > 1 && (
						<span>&nbsp;#{i + 1}</span>
					)}
				</span>
			),
			content: (
				<Value>
					{element}
				</Value>
			),
		})),
	).flat();

	return (
		<CodeSnippets
			snippets={snippets}
		/>
	);
};



export default MetaRobotsCase;
