import React from 'react';

import AirplaneIcon from './builders/iconsData/segmentIcon/Airplane';
import ArchiveIcon from './builders/iconsData/segmentIcon/Archive';
import ArtIcon from './builders/iconsData/segmentIcon/Art';
import ArticleIcon from './builders/iconsData/segmentIcon/Article';
import BellIcon from './builders/iconsData/segmentIcon/Bell';
import BlogIcon from './builders/iconsData/segmentIcon/Blog';
import BookIcon from './builders/iconsData/segmentIcon/Book';
import BriefcaseIcon from './builders/iconsData/segmentIcon/Briefcase';
import CalendarIcon from './builders/iconsData/segmentIcon/Calendar';
import CameraIcon from './builders/iconsData/segmentIcon/Camera';
import CategoriesIcon from './builders/iconsData/segmentIcon/Categories';
import ChildIcon from './builders/iconsData/segmentIcon/Child';
import CoinsIcon from './builders/iconsData/segmentIcon/Coins';
import ContactIcon from './builders/iconsData/segmentIcon/Contact';
import CrownKingIcon from './builders/iconsData/segmentIcon/CrownKing';
import CrownQueenIcon from './builders/iconsData/segmentIcon/CrownQueen';
import DeliveryIcon from './builders/iconsData/segmentIcon/Delivery';
import DiamondIcon from './builders/iconsData/segmentIcon/Diamond';
import DiscountIcon from './builders/iconsData/segmentIcon/Discount';
import FashionIcon from './builders/iconsData/segmentIcon/Fashion';
import FemaleIcon from './builders/iconsData/segmentIcon/Female';
import FlagIcon from './builders/iconsData/segmentIcon/Flag';
import FoodIcon from './builders/iconsData/segmentIcon/Food';
import GiftIcon from './builders/iconsData/segmentIcon/Gift';
import GraphDecreaseIcon from './builders/iconsData/segmentIcon/GraphDecrease';
import GraphIncreaseIcon from './builders/iconsData/segmentIcon/GraphIncrease';
import HealthIcon from './builders/iconsData/segmentIcon/Health';
import HistoryIcon from './builders/iconsData/segmentIcon/History';
import HolidayIcon from './builders/iconsData/segmentIcon/Holiday';
import HourglassIcon from './builders/iconsData/segmentIcon/Hourglass';
import ImageIcon from './builders/iconsData/segmentIcon/Image';
import InStockBagIcon from './builders/iconsData/segmentIcon/InStockBag';
import InStockCartIcon from './builders/iconsData/segmentIcon/InStockCart';
import KeyIcon from './builders/iconsData/segmentIcon/Key';
import MagazineIcon from './builders/iconsData/segmentIcon/Magazine';
import MaleIcon from './builders/iconsData/segmentIcon/Male';
import MegaphoneIcon from './builders/iconsData/segmentIcon/Megaphone';
import MobileIcon from './builders/iconsData/segmentIcon/Mobile';
import MoneyBagIcon from './builders/iconsData/segmentIcon/MoneyBag';
import MusicIcon from './builders/iconsData/segmentIcon/Music';
import OutOfStockBagIcon from './builders/iconsData/segmentIcon/OutOfStockBag';
import OutOfStockCartIcon from './builders/iconsData/segmentIcon/OutOfStockCart';
import PriceTagIcon from './builders/iconsData/segmentIcon/PriceTag';
import ProductIcon from './builders/iconsData/segmentIcon/Product';
import RobotAllowedIcon from './builders/iconsData/segmentIcon/RobotAllowed';
import RobotDisallowedIcon from './builders/iconsData/segmentIcon/RobotDisallowed';
import ScienceIcon from './builders/iconsData/segmentIcon/Science';
import ShoppingCartIcon from './builders/iconsData/segmentIcon/ShoppingCart';
import ShoppingCartCheckIcon from './builders/iconsData/segmentIcon/ShoppingCartCheck';
import ShoppingCartDisableIcon from './builders/iconsData/segmentIcon/ShoppingCartDisable';
import SportIcon from './builders/iconsData/segmentIcon/Sport';
import StatsFailingIcon from './builders/iconsData/segmentIcon/StatsFailing';
import StatsRisingIcon from './builders/iconsData/segmentIcon/StatsRising';
import StopwatchIcon from './builders/iconsData/segmentIcon/Stopwatch';
import SupportIcon from './builders/iconsData/segmentIcon/Support';
import TargetIcon from './builders/iconsData/segmentIcon/Target';
import TechnologyIcon from './builders/iconsData/segmentIcon/Technology';
import TheatreIcon from './builders/iconsData/segmentIcon/Theatre';
import ToolboxIcon from './builders/iconsData/segmentIcon/Toolbox';
import UnisexIcon from './builders/iconsData/segmentIcon/Unisex';
import UserIcon from './builders/iconsData/segmentIcon/User';
import VideoIcon from './builders/iconsData/segmentIcon/Video';

import InlineIcon from './builders/InlineIcon.part';

import {
	type Merge,
} from '~/types/utilities';



export enum SegmentIconType {
	Airplane = 'airplane',
	Archive = 'archive',
	Art = 'art',
	Article = 'article',
	Bell = 'bell',
	Blog = 'blog',
	Book = 'book',
	Briefcase = 'briefcase',
	Calendar = 'calendar',
	Camera = 'camera',
	Categories = 'categories',
	Child = 'child',
	Coins = 'coins',
	Contact = 'contact',
	CrownKing = 'crown-king',
	CrownQueen = 'crown-queen',
	Delivery = 'delivery',
	Diamond = 'diamond',
	Discount = 'discount',
	Fashion = 'fashion',
	Female = 'female',
	Flag = 'flag',
	Food = 'food',
	Gift = 'gift',
	GraphDecrease = 'graph-decrease',
	GraphIncrease = 'graph-increase',
	Health = 'health',
	History = 'history',
	Holiday = 'holiday',
	Hourglass = 'hourglass',
	Image = 'image',
	InStockBag = 'in-stock-bag',
	InStockCart = 'in-stock-cart',
	Key = 'key',
	Magazine = 'magazine',
	Male = 'male',
	Megaphone = 'megaphone',
	Mobile = 'mobile',
	MoneyBag = 'money-bag',
	Music = 'music',
	OutOfStockBag = 'out-of-stock-bag',
	OutOfStockCart = 'out-of-stock-cart',
	PriceTag = 'price-tag',
	Product = 'product',
	RobotAllowed = 'robot-allowed',
	RobotDisallowed = 'robot-disallowed',
	Science = 'science',
	ShoppingCart = 'shopping-cart',
	ShoppingCartCheck = 'shopping-cart-check',
	ShoppingCartDisable = 'shopping-cart-disable',
	Sport = 'sport',
	StatsFailing = 'stats-failing',
	StatsRising = 'stats-rising',
	Stopwatch = 'stopwatch',
	Support = 'support',
	Target = 'target',
	Technology = 'technology',
	Theatre = 'theatre',
	Toolbox = 'toolbox',
	Unisex = 'unisex',
	User = 'user',
	Video = 'video',
}



const componentMapping = {
	[SegmentIconType.Airplane]: AirplaneIcon,
	[SegmentIconType.Archive]: ArchiveIcon,
	[SegmentIconType.Art]: ArtIcon,
	[SegmentIconType.Article]: ArticleIcon,
	[SegmentIconType.Bell]: BellIcon,
	[SegmentIconType.Blog]: BlogIcon,
	[SegmentIconType.Book]: BookIcon,
	[SegmentIconType.Briefcase]: BriefcaseIcon,
	[SegmentIconType.Calendar]: CalendarIcon,
	[SegmentIconType.Camera]: CameraIcon,
	[SegmentIconType.Categories]: CategoriesIcon,
	[SegmentIconType.Child]: ChildIcon,
	[SegmentIconType.Coins]: CoinsIcon,
	[SegmentIconType.Contact]: ContactIcon,
	[SegmentIconType.CrownKing]: CrownKingIcon,
	[SegmentIconType.CrownQueen]: CrownQueenIcon,
	[SegmentIconType.Delivery]: DeliveryIcon,
	[SegmentIconType.Diamond]: DiamondIcon,
	[SegmentIconType.Discount]: DiscountIcon,
	[SegmentIconType.Fashion]: FashionIcon,
	[SegmentIconType.Female]: FemaleIcon,
	[SegmentIconType.Flag]: FlagIcon,
	[SegmentIconType.Food]: FoodIcon,
	[SegmentIconType.Gift]: GiftIcon,
	[SegmentIconType.GraphDecrease]: GraphDecreaseIcon,
	[SegmentIconType.GraphIncrease]: GraphIncreaseIcon,
	[SegmentIconType.Health]: HealthIcon,
	[SegmentIconType.History]: HistoryIcon,
	[SegmentIconType.Holiday]: HolidayIcon,
	[SegmentIconType.Hourglass]: HourglassIcon,
	[SegmentIconType.Image]: ImageIcon,
	[SegmentIconType.InStockBag]: InStockBagIcon,
	[SegmentIconType.InStockCart]: InStockCartIcon,
	[SegmentIconType.Key]: KeyIcon,
	[SegmentIconType.Magazine]: MagazineIcon,
	[SegmentIconType.Male]: MaleIcon,
	[SegmentIconType.Megaphone]: MegaphoneIcon,
	[SegmentIconType.Mobile]: MobileIcon,
	[SegmentIconType.MoneyBag]: MoneyBagIcon,
	[SegmentIconType.Music]: MusicIcon,
	[SegmentIconType.OutOfStockBag]: OutOfStockBagIcon,
	[SegmentIconType.OutOfStockCart]: OutOfStockCartIcon,
	[SegmentIconType.PriceTag]: PriceTagIcon,
	[SegmentIconType.Product]: ProductIcon,
	[SegmentIconType.RobotAllowed]: RobotAllowedIcon,
	[SegmentIconType.RobotDisallowed]: RobotDisallowedIcon,
	[SegmentIconType.Science]: ScienceIcon,
	[SegmentIconType.ShoppingCart]: ShoppingCartIcon,
	[SegmentIconType.ShoppingCartCheck]: ShoppingCartCheckIcon,
	[SegmentIconType.ShoppingCartDisable]: ShoppingCartDisableIcon,
	[SegmentIconType.Sport]: SportIcon,
	[SegmentIconType.StatsFailing]: StatsFailingIcon,
	[SegmentIconType.StatsRising]: StatsRisingIcon,
	[SegmentIconType.Stopwatch]: StopwatchIcon,
	[SegmentIconType.Support]: SupportIcon,
	[SegmentIconType.Target]: TargetIcon,
	[SegmentIconType.Technology]: TechnologyIcon,
	[SegmentIconType.Theatre]: TheatreIcon,
	[SegmentIconType.Toolbox]: ToolboxIcon,
	[SegmentIconType.Unisex]: UnisexIcon,
	[SegmentIconType.User]: UserIcon,
	[SegmentIconType.Video]: VideoIcon,
};



type BaseProps<IconType extends SegmentIconType = SegmentIconType> = {
	/** Custom icon color */
	color?: string,
	/** Square size of icon */
	size?: number,
	/** Type of icon */
	type: IconType,
};

type IconProps<IconType extends SegmentIconType = SegmentIconType> = (
	Parameters<typeof componentMapping[IconType]['icon']>[0]
);

type Props<IconType extends SegmentIconType = SegmentIconType> = (
	Merge<IconProps<IconType>, BaseProps<IconType>>
);



const SegmentIcon = <IconType extends SegmentIconType>(props: Props<IconType>): React.ReactElement | null => {
	const {
		color,
		size = 20,
		type,
		...rest
	} = props;

	const iconDefinition = componentMapping[type];

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (iconDefinition === undefined) {
		return null;
	}

	const icon = iconDefinition.icon({
		color,
		size,
		...rest,
	});

	return (
		<InlineIcon
			icon={icon}
			size={size}
			viewBoxHeight={iconDefinition.viewBoxHeight}
			viewBoxWidth={iconDefinition.viewBoxWidth}
		/>
	);
};



export default SegmentIcon;
