import React from 'react';
import {
	useSelector,
} from 'react-redux';

import {
	type IssueName,
	PageIssue,
	isPageIssueWithAffectedLinks,
} from '~/model/issuesNew';

import {
	filterSelector,
} from '~/state/affectedLinks/selectors';

import matchAndReturn from '~/utilities/matchAndReturn';



function useAffectedLinksFilter(issueName: IssueName): Record<string, any> | null {
	const filter = useSelector(filterSelector);

	return React.useMemo(
		() => {
			if (!isPageIssueWithAffectedLinks(issueName)) {
				return null;
			}

			return {
				...filter.toJS(),
				'number_of_incoming_internal_links': '>0',
				'type': matchAndReturn(issueName, {
					[PageIssue.LinksRedirected]: ['redirect'],
					[PageIssue.LinksBroken]: ['missing'],
				}),
			};
		},
		[
			filter,
			issueName,
		],
	);
}



export default useAffectedLinksFilter;
