import classNames from 'classnames';
import React from 'react';

import Hint from '~/components/patterns/hints/hint/Hint';



export enum ColumnControllerSize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	labelTooltip?: React.ReactNode,
	dragHandler?: React.ReactNode,
	isDisabled?: boolean,
	isLocked?: boolean,
	label: React.ReactNode,
	size?: ColumnControllerSize,
};



const ColumnController: React.FC<Props> = (props) => {
	const {
		dragHandler,
		isDisabled,
		isLocked,
		label,
		labelTooltip,
		size = ColumnControllerSize.Default,
	} = props;

	const componentClasses = classNames({
		'page-column-controller': true,
		'page-column-controller--disabled': isDisabled,
		'page-column-controller--locked': isLocked,
		['page-column-controller--' + size + '-size']: true,
	});

	return (
		<div className={componentClasses}>
			<div className="page-column-controller__draggable-section">
				<div className="page-column-controller__content">
					<div className="page-column-controller__checkbox">
						{labelTooltip ? (
							<Hint
								popup={labelTooltip}
								popupOffset={[0, -8]}
							>
								{label}
							</Hint>
						) : label}
					</div>

					{dragHandler && (
						<div className="page-column-controller__drag-handler">
							{dragHandler}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};



export default ColumnController;
