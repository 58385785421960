import {
	Map,
} from 'immutable';

import GraphQL from '~/types/graphql';



export enum WebsiteAccess {
	All = 'all',
	Selected = 'selected',
}

export const ROLES_LIST = [
	GraphQL.UserRole.Viewer,
	GraphQL.UserRole.Editor,
	GraphQL.UserRole.Manager,
	GraphQL.UserRole.Admin,
];



export function createDisplayName(userInfo: any) {
	if (!(userInfo instanceof Map)) {
		userInfo = Map<any, any>(userInfo);
	}

	const firstName = userInfo.get('first_name');
	const lastName = userInfo.get('last_name');
	const email = userInfo.get('email');

	if (firstName && lastName) {
		return firstName + ' ' + lastName;
	} else if (firstName) {
		return firstName;
	} else if (lastName) {
		return lastName;
	}

	return email;
}



export function extractInitials(input: string, maxLength = 2) {
	const partBeforeAt = input.split('@')[0] ?? '';
	const dashedFromCamelCase = partBeforeAt.replace(/([A-Z])/g, (str) => {
		return '-' + str.toLowerCase();
	});

	let parts = dashedFromCamelCase.split(/[\s,\.,\+,_,\-]+/);
	parts = parts.filter((str) => str.length).slice(0, maxLength);

	return parts.map((part) => {
		return part[0];
	}).join('').toUpperCase();
}



export function isEmailWhitelisted(email: string, whitelist: ReadonlyArray<string>) {
	if (whitelist.length === 0) {
		return true;
	}

	const domain = email.split('@')[1] || '';

	return whitelist.some((rule) => {
		if (rule.substring(0, 1) === '@') {
			return domain === rule.substring(1);
		} else if (rule.substring(0, 2) === '*.') {
			rule = rule.substring(1);

			return (
				domain.substring(domain.length - rule.length) === rule
				|| domain === rule.substring(1)
			);
		}

		return false;
	});
}



export function sortUsers<TUser extends {
	displayName: string,
	email: string,
}>(
	currentUserEmail: string,
	users: ReadonlyArray<TUser>,
) {
	return [...users].sort((userA, userB) => {
		if (userA.email === currentUserEmail) {
			return -1;
		}

		if (userB.email === currentUserEmail) {
			return 1;
		}

		const displayNameA = userA.displayName.toLowerCase();
		const displayNameB = userB.displayName.toLowerCase();

		return displayNameA.localeCompare(displayNameB);
	});
}
