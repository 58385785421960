/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SchemaOrgBlockPrimaryQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type SchemaOrgBlockPrimaryQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly isPrioritized: boolean, readonly lastCheckedAt: CK.Timestamp | null, readonly pageType: GraphQL.PageType, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly primaryProperties: { readonly __typename?: 'OnPageProperties', readonly invalidSchemaOrgJson: ReadonlyArray<number>, readonly schemaOrgJson: ReadonlyArray<any> } } | null } | null };

export type SchemaOrgBlockSrcQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type SchemaOrgBlockSrcQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly isPrioritized: boolean, readonly lastCheckedAt: CK.Timestamp | null, readonly pageType: GraphQL.PageType, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly srcProperties: { readonly __typename?: 'OnPageProperties', readonly invalidSchemaOrgJson: ReadonlyArray<number>, readonly schemaOrgJson: ReadonlyArray<any> } } | null } | null };


export const SchemaOrgBlockPrimaryDocument = gql`
    query SchemaOrgBlockPrimary($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    isPrioritized
    lastCheckedAt
    pageType
    pageTypeData {
      primaryProperties {
        invalidSchemaOrgJson
        schemaOrgJson
      }
    }
  }
}
    `;

/**
 * __useSchemaOrgBlockPrimaryQuery__
 *
 * To run a query within a React component, call `useSchemaOrgBlockPrimaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemaOrgBlockPrimaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemaOrgBlockPrimaryQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useSchemaOrgBlockPrimaryQuery(baseOptions: Apollo.QueryHookOptions<SchemaOrgBlockPrimaryQuery, SchemaOrgBlockPrimaryQueryVariables> & ({ variables: SchemaOrgBlockPrimaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchemaOrgBlockPrimaryQuery, SchemaOrgBlockPrimaryQueryVariables>(SchemaOrgBlockPrimaryDocument, options);
      }
export function useSchemaOrgBlockPrimaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchemaOrgBlockPrimaryQuery, SchemaOrgBlockPrimaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchemaOrgBlockPrimaryQuery, SchemaOrgBlockPrimaryQueryVariables>(SchemaOrgBlockPrimaryDocument, options);
        }
export function useSchemaOrgBlockPrimarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SchemaOrgBlockPrimaryQuery, SchemaOrgBlockPrimaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SchemaOrgBlockPrimaryQuery, SchemaOrgBlockPrimaryQueryVariables>(SchemaOrgBlockPrimaryDocument, options);
        }
export type SchemaOrgBlockPrimaryQueryHookResult = ReturnType<typeof useSchemaOrgBlockPrimaryQuery>;
export type SchemaOrgBlockPrimaryLazyQueryHookResult = ReturnType<typeof useSchemaOrgBlockPrimaryLazyQuery>;
export type SchemaOrgBlockPrimarySuspenseQueryHookResult = ReturnType<typeof useSchemaOrgBlockPrimarySuspenseQuery>;
export type SchemaOrgBlockPrimaryQueryResult = Apollo.QueryResult<SchemaOrgBlockPrimaryQuery, SchemaOrgBlockPrimaryQueryVariables>;
export const SchemaOrgBlockSrcDocument = gql`
    query SchemaOrgBlockSrc($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    isPrioritized
    lastCheckedAt
    pageType
    pageTypeData {
      srcProperties {
        invalidSchemaOrgJson
        schemaOrgJson
      }
    }
  }
}
    `;

/**
 * __useSchemaOrgBlockSrcQuery__
 *
 * To run a query within a React component, call `useSchemaOrgBlockSrcQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemaOrgBlockSrcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemaOrgBlockSrcQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useSchemaOrgBlockSrcQuery(baseOptions: Apollo.QueryHookOptions<SchemaOrgBlockSrcQuery, SchemaOrgBlockSrcQueryVariables> & ({ variables: SchemaOrgBlockSrcQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchemaOrgBlockSrcQuery, SchemaOrgBlockSrcQueryVariables>(SchemaOrgBlockSrcDocument, options);
      }
export function useSchemaOrgBlockSrcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchemaOrgBlockSrcQuery, SchemaOrgBlockSrcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchemaOrgBlockSrcQuery, SchemaOrgBlockSrcQueryVariables>(SchemaOrgBlockSrcDocument, options);
        }
export function useSchemaOrgBlockSrcSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SchemaOrgBlockSrcQuery, SchemaOrgBlockSrcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SchemaOrgBlockSrcQuery, SchemaOrgBlockSrcQueryVariables>(SchemaOrgBlockSrcDocument, options);
        }
export type SchemaOrgBlockSrcQueryHookResult = ReturnType<typeof useSchemaOrgBlockSrcQuery>;
export type SchemaOrgBlockSrcLazyQueryHookResult = ReturnType<typeof useSchemaOrgBlockSrcLazyQuery>;
export type SchemaOrgBlockSrcSuspenseQueryHookResult = ReturnType<typeof useSchemaOrgBlockSrcSuspenseQuery>;
export type SchemaOrgBlockSrcQueryResult = Apollo.QueryResult<SchemaOrgBlockSrcQuery, SchemaOrgBlockSrcQueryVariables>;