import Immutable from 'immutable';

import CK from '~/types/contentking';

import {
	get,
} from './api/API';

import {
	transformBooleanOrNAFilterForApi,
} from './filter';

import {
	PagesColumnsCategory,
	getColumnCategory,
} from './pagesColumns';

import removeDefaultValues from '~/utilities/removeDefaultValues';



export const STATE_NOT_LOADED = 'STATE_NOT_LOADED';
export const STATE_LOADED = 'STATE_LOADED';

export const TYPE_MISSING = 'missing';
export const TYPE_NO_DATA = 'no-data';
export const TYPE_NOT_CRAWLED_YET = 'not_crawled_yet';
export const TYPE_OTHER = 'other';
export const TYPE_OVERQUOTA = 'over_quota';
export const TYPE_PAGE = 'page';
export const TYPE_REDIRECT = 'redirect';
export const TYPE_SERVER_ERROR = 'server_error';
export const TYPE_UNREACHABLE = 'timeout';

export const CANONICAL_TYPE_CROSSDOMAIN = 'crossdomain';
export const CANONICAL_TYPE_INTERNAL_OTHER = 'internal_other';
export const CANONICAL_TYPE_INTERNAL_SELF = 'internal_self';
export const CANONICAL_TYPE_INVALID = 'invalid';
export const CANONICAL_TYPE_NONE = 'none';
export const CANONICAL_TYPE_NOT_APPLICABLE = 'not_applicable';

export const CANONICAL_TYPE_GROUP_INTERNAL = 'internal';

export const LIST_OF_ALL_CANONICAL_TYPES = [
	CANONICAL_TYPE_CROSSDOMAIN,
	CANONICAL_TYPE_INTERNAL_OTHER,
	CANONICAL_TYPE_INTERNAL_SELF,
	CANONICAL_TYPE_INVALID,
	CANONICAL_TYPE_NONE,
	CANONICAL_TYPE_NOT_APPLICABLE,
];



export const UNRELIABLE_RESPONSE_CONNECT_ISSUE = 'connect_issue';
export const UNRELIABLE_RESPONSE_EMPTY_RESPONSE = 'empty_response';
export const UNRELIABLE_RESPONSE_LIGHTHOUSE_FAILED = 'lighthouse_failed';
export const UNRELIABLE_RESPONSE_REDIRECT_CLIENT = 'redirect_client';
export const UNRELIABLE_RESPONSE_RENDER_TIMEOUT = 'render_timeout';
export const UNRELIABLE_RESPONSE_SIZE_LIMIT = 'size_limit';
export const UNRELIABLE_RESPONSE_TIMEOUT = 'timeout';
export const UNRELIABLE_RESPONSE_TOO_MANY_ON_PAGE_REQUESTS = 'too_many_on_page_requests';
export const UNRELIABLE_RESPONSE_WEB_APPLICATION_FIREWALL = 'waf';



export const SINGLE_PAGE_RELATION_TYPES = [
	'outbound_links',
	'outgoing_internal_links',
];



export const DEFAULT_FILTER = normalizeFilter(Immutable.fromJS({
	[CK.PagesCommonColumn.CanonicalType]: [
		CANONICAL_TYPE_INTERNAL_SELF,
		CANONICAL_TYPE_INTERNAL_OTHER,
		CANONICAL_TYPE_CROSSDOMAIN,
		CANONICAL_TYPE_INVALID,
		CANONICAL_TYPE_NONE,
		CANONICAL_TYPE_NOT_APPLICABLE,
	],
	[CK.PagesCommonColumn.IsDisallowedInRobotsTxt]: [
		false,
		true,
	],
	[CK.PagesCommonColumn.IsInSitemap]: [
		false,
		true,
	],
	[CK.PagesCommonColumn.IsIndexable]: [
		false,
		true,
	],
	[CK.PagesCommonColumn.IsIndexableDueToMetaRobots]: [
		'yes',
		'no',
		'not_applicable',
	],
	[CK.PagesCommonColumn.IsIndexableDueToXRobotsTag]: [
		'yes',
		'no',
		'not_applicable',
	],
	[CK.PagesCommonColumn.IsLinked]: [
		true,
		false,
	],
	[CK.PagesCommonColumn.IsSecured]: [
		false,
		true,
	],
	[CK.PagesCommonColumn.Segments]: {
		included_in: [],
		not_included_in: [],
		operator: 'and',
	},
	[CK.PagesCommonColumn.Type]: [
		TYPE_MISSING,
		TYPE_PAGE,
		TYPE_REDIRECT,
		TYPE_SERVER_ERROR,
		TYPE_UNREACHABLE,
	],
}));

export const DEFAULT_SORT_BY = Immutable.fromJS({
	key: CK.PagesCommonColumn.Relevance,
	direction: false,
});



export const GRAPHABLE_FIELDS = [
	CK.PagesCommonColumn.CanonicalType,
	CK.PagesCommonColumn.Domain,
	CK.PagesCommonColumn.H1,
	CK.PagesCommonColumn.Health,
	CK.PagesCommonColumn.HreflangLanguage,
	CK.PagesCommonColumn.IsDisallowedInRobotsTxt,
	CK.PagesCommonColumn.IsInSitemap,
	CK.PagesCommonColumn.IsIndexable,
	CK.PagesCommonColumn.IsIndexableDueToMetaRobots,
	CK.PagesCommonColumn.IsIndexableDueToXRobotsTag,
	CK.PagesCommonColumn.IsLinked,
	CK.PagesCommonColumn.IsSecured,
	CK.PagesCommonColumn.LighthouseCumulativeLayoutShift,
	CK.PagesCommonColumn.LighthouseFirstContentfulPaint,
	CK.PagesCommonColumn.LighthousePerformance,
	CK.PagesCommonColumn.LighthouseSpeedIndex,
	CK.PagesCommonColumn.LighthouseTimeToInteractive,
	CK.PagesCommonColumn.LighthouseTotalBlockingTime,
	CK.PagesCommonColumn.LighthouseLargestContentfulPaint,
	CK.PagesCommonColumn.MetaDescription,
	CK.PagesCommonColumn.NumberOfHreflangs,
	CK.PagesCommonColumn.OpenGraphDescription,
	CK.PagesCommonColumn.OpenGraphImage,
	CK.PagesCommonColumn.OpenGraphTitle,
	CK.PagesCommonColumn.OpenGraphUrl,
	CK.PagesCommonColumn.Relevance,
	CK.PagesCommonColumn.StatusCode,
	CK.PagesCommonColumn.Title,
	CK.PagesCommonColumn.Type,
	CK.PagesCommonColumn.TwitterDescription,
	CK.PagesCommonColumn.TwitterImage,
	CK.PagesCommonColumn.TwitterTitle,
	CK.PagesCommonColumn.UrlDepth,
];



export function calculatePagesOverviewPercentage({ matchedPages, totalPages }) {
	if (matchedPages === false || totalPages === undefined || totalPages === null || totalPages === 0) {
		return null;
	}

	if (matchedPages >= totalPages) {
		return 1.0;
	}

	if (matchedPages === 0) {
		return 0.0;
	}

	const ratio = matchedPages / totalPages;

	if (ratio < 0.001) {
		return Math.ceil(ratio * 10000) / 10000;
	} else if (ratio < 0.01) {
		return Math.ceil(ratio * 1000) / 1000;
	} else if (ratio > 0.999) {
		return Math.floor(ratio * 10000) / 10000;
	} else if (ratio > 0.990) {
		return Math.floor(ratio * 1000) / 1000;
	}

	return Math.ceil(ratio * 100) / 100;
}



export function getFilter(filter) {
	let isInvalid = false;

	filter = transformBooleanOrNAFilterForApi(filter);

	filter = filter.map((value, key) => {
		if (
			key === CK.PagesCommonColumn.IsSecured
			|| key === CK.PagesCommonColumn.IsInSitemap
			|| key === CK.PagesCommonColumn.IsIndexable
			|| key === CK.PagesCommonColumn.IsLinked
			|| key === CK.PagesCommonColumn.IsDisallowedInRobotsTxt
		) {
			if (value.size !== undefined) {
				if (value.size === 2) {
					return null;
				} else if (value.size === 1) {
					return value.get(0);
				} else if (value.size === 0) {
					isInvalid = true;

					return null;
				}
			} else {
				if (value.length === 2) {
					return null;
				} else if (value.length === 1) {
					return value[0];
				} else if (value.length === 0) {
					isInvalid = true;

					return null;
				}
			}
		}

		if (
			key === CK.PagesCommonColumn.Type
			|| key === CK.PagesCommonColumn.CanonicalType
			|| key === CK.PagesCommonColumn.IsIndexableDueToMetaRobots
			|| key === CK.PagesCommonColumn.IsIndexableDueToXRobotsTag
		) {
			if (value.size !== undefined) {
				if (value.size === 0) {
					isInvalid = true;

					return null;
				}
			} else {
				if (value.length === 0) {
					isInvalid = true;

					return null;
				}
			}

			value = value.join(',');
		}

		if (key === CK.PagesCommonColumn.Segments) {
			if (value.get('included_in').size > 0 || value.get('not_included_in').size > 0) {
				let formattedValue = '';

				if (value.get('included_in').size > 0) {
					formattedValue += 'included_in:' + value.get('included_in').join(',') + ';';
				}

				if (value.get('not_included_in').size > 0) {
					formattedValue += 'not_included_in:' + value.get('not_included_in').join(',') + ';';
				}

				formattedValue += 'operator:' + value.get('operator');

				value = formattedValue;
			} else {
				return null;
			}
		}

		if (key === CK.PagesCommonColumn.Health) {
			if (value === 1000) {
				return null;
			}
		}

		return value;
	}).filter((value) => {
		return value !== null;
	});

	[
		CK.PagesCommonColumn.AnalyticsServices,
		CK.PagesCommonColumn.TagManagers,
		CK.PagesCommonColumn.VisualAnalyticsServices,
	].forEach((field) => {
		if (filter.has(field)) {
			filter = filter.set(field + '_legacy', filter.get(field));
			filter = filter.remove(field);
		}
	});

	return {
		criteria: filter.toJS(),
		isInvalid,
	};
}



export function normalizeFilter(filter) {
	filter = filter.filter((value) => value !== '');

	filter = filter.set(CK.PagesCommonColumn.Type, (filter.get(CK.PagesCommonColumn.Type) || DEFAULT_FILTER.get(CK.PagesCommonColumn.Type)).sort());
	filter = filter.set(CK.PagesCommonColumn.IsSecured, (filter.get(CK.PagesCommonColumn.IsSecured) || DEFAULT_FILTER.get(CK.PagesCommonColumn.IsSecured)).sort());
	filter = filter.set(CK.PagesCommonColumn.IsInSitemap, (filter.get(CK.PagesCommonColumn.IsInSitemap) || DEFAULT_FILTER.get(CK.PagesCommonColumn.IsInSitemap)).sort());
	filter = filter.set(CK.PagesCommonColumn.IsIndexable, (filter.get(CK.PagesCommonColumn.IsIndexable) || DEFAULT_FILTER.get(CK.PagesCommonColumn.IsIndexable)).sort());
	filter = filter.set(CK.PagesCommonColumn.IsLinked, (filter.get(CK.PagesCommonColumn.IsLinked) || DEFAULT_FILTER.get(CK.PagesCommonColumn.IsLinked)).sort());
	filter = filter.set(CK.PagesCommonColumn.CanonicalType, (filter.get(CK.PagesCommonColumn.CanonicalType) || DEFAULT_FILTER.get(CK.PagesCommonColumn.CanonicalType)).sort());

	return filter.sortBy((value, key) => key);
}



export function removeDefaultFilterValues(activeFilters) {
	activeFilters = removeDefaultValues(activeFilters, DEFAULT_FILTER);

	if (
		activeFilters.has(CK.PagesCommonColumn.Segments)
		&& activeFilters.get(CK.PagesCommonColumn.Segments).get('included_in').size === 0
		&& activeFilters.get(CK.PagesCommonColumn.Segments).get('not_included_in').size === 0
		&& activeFilters.get(CK.PagesCommonColumn.Segments).get('operator') === 'or'
	) {
		activeFilters = activeFilters.remove(CK.PagesCommonColumn.Segments);
	}

	return activeFilters;
}



export function createFullId(websiteId, pageId) {
	return websiteId + '/' + pageId;
}



export function getDisabledColumnIndexes(columns, enabledColumns) {
	const disabledColumnIndexes: Array<number> = [];

	columns.forEach((columnName, index) => {
		if (enabledColumns.includes(columnName)) {
			return;
		}

		const columnCategory = getColumnCategory(columnName);
		if (
			columnCategory === PagesColumnsCategory.CustomElements
			|| columnCategory === PagesColumnsCategory.EnrichmentFields
		) {
			return;
		}

		disabledColumnIndexes.push(index);
	});

	return Immutable.List(disabledColumnIndexes);
}



export function loadPageRelationsViaApi(websiteId, pageId, type, offset, limit) {
	return get('/websites/' + websiteId + '/pages/' + pageId + '/' + type, {
		limit,
		offset,
	});
}



export function loadPagesChartsViaApi(websiteId, fields, parameters) {
	const numberOfFields = fields.size !== undefined
		? fields.size
		: fields.length;

	if (numberOfFields > 0) {
		parameters.fields = fields
			.filter((field) => GRAPHABLE_FIELDS.includes(field))
			.join(',');
	}

	return get('/websites/' + websiteId + '/pages_statistics', parameters, {
		timeout: 30000,
	});
}



export function loadPagesByIdsViaApi(websiteId, ids) {
	return get('/websites/' + websiteId + '/urls?ids=' + ids.join(','));
}



export function loadUniqueElementContentIssueUrlsViaApi(domainId, pageId, issueType, parameters) {
	return get('/websites/' + domainId + '/pages/' + pageId + '/issues/' + issueType + '/pages', parameters);
}
