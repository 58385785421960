import React from 'react';

import CancelButton from '~/components/app/CancelButton';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import ItemPerLineTextArea from '~/components/app/ItemPerLineTextArea';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';

import {
	validateField,
} from '~/components/app/validations';

import {
	useAddOverridableBlacklistDomainsMutation,
} from './AdminAddOverridableBlacklistDomainsModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const validations = {
	validateDomains: validateField(
		'domains',
		(f) => [
			f.validateDomainPatterns('domains'),
		],
	),
};



const AdminAddOverridableBlacklistDomainsModal: React.FC = () => {
	const classicFormBehavior = useClassicFormBehavior();

	const [addOverridableBlacklistDomains] = useAddOverridableBlacklistDomainsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await addOverridableBlacklistDomains({
				variables: {
					domains: values.domains,
				},
			});

			classicFormBehavior.finish();
		},
		[
			addOverridableBlacklistDomains,
			classicFormBehavior,
		],
	);

	return (
		<SimpleModal
			size={SimpleModalSize.Small}
			title="Add overridable blacklist domains"
		>
			<Form
				defaultFocus="domains"
				defaultValues={{
					domains: '',
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRow
					description={(
						<>
							Place one domain per line. You can use wildcard at the beginning to block subdomains. Use "domain.com" and "*.domain.com" to completely block domain and its subdomains from being crawled.
						</>
					)}
					fullwidth={true}
					htmlFor="domains"
				>
					<FieldStatus name="validateDomains">
						<ItemPerLineTextArea
							name="domains"
							size={{
								rows: 8,
								width: '100%',
							}}
						/>
					</FieldStatus>
				</FormRow>

				<ButtonsLayout>
					<CancelButton />

					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default AdminAddOverridableBlacklistDomainsModal;
