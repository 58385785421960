import Immutable from 'immutable';
import React from 'react';

import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import CheckboxGroupContainer from '~/components/logic/checkboxGroup/CheckboxGroupContainer';
import CheckboxInGroup from '~/components/logic/checkboxGroup/CheckboxInGroup';
import FormWrapperHeader from '~/components/patterns/forms/wrappers/FormWrapperHeader.part';
import MasterCheckbox from '~/components/logic/checkboxGroup/MasterCheckbox';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import ResizableDatatable, {
	ResizableDatatableCellAlignment,
	type ResizableDatatableColumnDefinition,
	ResizableDatatableHeaderCellAlignment,
	ResizableDatatableHeaderHeight,
} from '~/components/logic/datatables/ResizableDatatable';
import StickToScreenBottom, {
	StickToScreenBottomPreset,
} from '~/components/patterns/utils/StickToScreenBottom';



export type NewDatatableColumnDefinitions<Rows extends ReadonlyArray<any>> = Array<ResizableDatatableColumnDefinition<Rows[0]>>;

const defaultFilter = Immutable.Map({});



type ResizableDatatableProps = React.ComponentProps<typeof ResizableDatatable>;

type Props<Row extends {}, RowId extends string> = {
	buttons?: React.ReactNode,
	columns: NewDatatableColumnDefinitions<ReadonlyArray<Row>>,
	filter?: any,
	isLoading?: ResizableDatatableProps['isLoading'],
	height?: ResizableDatatableProps['height'],
	onFilterChange?: ResizableDatatableProps['onFilterChange'],
	rows: ReadonlyArray<Row>,
	title?: React.ReactNode,
	width?: ResizableDatatableProps['width'],
} & ({
	allowSelection: true,
	onSelection: (rows: ReadonlyArray<Row>) => void,
	rowIdentifier: (row: Row) => RowId,
	defaultSelectedRows?: ReadonlyArray<RowId>,
} | {
	allowSelection?: false,
	onSelection?: never,
	defaultSelectedRows?: never,
	rowIdentifier?: never,
});

function NewDatatable<Row extends {}, RowId extends string>(props: Props<Row, RowId>) {
	const {
		allowSelection,
		buttons = null,
		columns,
		defaultSelectedRows,
		filter = null,
		height = 'auto',
		isLoading,
		onFilterChange,
		onSelection = null,
		rowIdentifier = null,
		rows,
		title = null,
		width = 'auto',
	} = props;

	const [selectedRowIds, setSelectedRowIds] = React.useState<ReadonlyArray<RowId>>(defaultSelectedRows ?? []);

	const rowsIdentification = React.useMemo(
		() => {
			if (
				allowSelection !== true
				|| rowIdentifier === null
			) {
				return null;
			}

			return {
				ids: rows.map(rowIdentifier),
				map: Object.fromEntries(
					rows.map((row) => [rowIdentifier(row), row]),
				),
			};
		},
		[
			allowSelection,
			rowIdentifier,
			rows,
		],
	);

	const hasFilter = filter !== null;

	const finalColumns = React.useMemo(
		() => {
			const result = [...columns];

			if (hasFilter === false) {
				result.map((column) => ({
					...column,
					render: {
						...column.render,
						filter: () => null,
					},
				}));
			}

			if (rowIdentifier !== null) {
				result.unshift({
					// clickable: true,
					alignment: ResizableDatatableCellAlignment.Center,
					headerAlignment: ResizableDatatableHeaderCellAlignment.Center,
					name: '__toggle',
					render: {
						cell: ({ row }) => (
							<CheckboxInGroup
								name={rowIdentifier(row)}
							/>
						),
						header: () => (
							<MasterCheckbox />
						),
					},
					width: 64,
				});
			}

			return result;
		},
		[
			columns,
			hasFilter,
			rowIdentifier,
		],
	);

	const rowGetter = React.useCallback(
		({ rowIndex }) => {
			return rows[rowIndex];
		},
		[
			rows,
		],
	);

	const handleSelection = React.useCallback(
		({ checked }: { checked: ReadonlyArray<RowId> }) => {
			setSelectedRowIds(checked);

			if (onSelection === null || rowsIdentification === null) {
				return;
			}

			onSelection(
				checked.map(
					(rowId) => rowsIdentification.map[rowId] as Row,
				),
			);
		},
		[
			onSelection,
			rowsIdentification,
		],
	);

	const renderBlankSlate = React.useCallback(
		() => (
			<NoSearchResults />
		),
		[],
	);

	let result = (
		<div
			className="new-table"
		>
			{(buttons !== null || title !== null) && (
				<FormWrapperHeader
					ctaElement={(buttons !== null && (
						<ButtonsGroup>
							{buttons}
						</ButtonsGroup>
					))}
					title={title}
				/>
			)}

			<StickToScreenBottom
				preset={StickToScreenBottomPreset.FullscreenDetail}
				presetOffset={1}
			>
				{({ height: containerHeight, width: containerWidth }) => (
					<ResizableDatatable
						blankSlate={renderBlankSlate}
						columns={finalColumns}
						filter={filter ?? defaultFilter}
						headerHeight={filter !== null ? ResizableDatatableHeaderHeight.Medium : ResizableDatatableHeaderHeight.Small}
						height={height === 'auto' ? containerHeight : height}
						isLoading={isLoading}
						numberOfPinnedColumns={allowSelection ? 1 : 0}
						onFilterChange={onFilterChange}
						rowGetter={rowGetter}
						rowsCount={rows.length}
						width={width === 'auto' ? containerWidth : width}
					/>
				)}
			</StickToScreenBottom>
		</div>
	);

	if (rowsIdentification !== null) {
		result = (
			<CheckboxGroupContainer
				name="foo"
				onChangeCallback={handleSelection}
				options={rowsIdentification.ids}
				value={selectedRowIds}
			>
				{result}
			</CheckboxGroupContainer>
		);
	}

	return result;
}



export default NewDatatable;

export {
	ResizableDatatableCellAlignment as NewDatatableCellAlignment,
	ResizableDatatableColumnDefinition as NewDatatableColumnDefinition,
};
