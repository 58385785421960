import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AlertTypeTitle from '~/components/logic/alerts/AlertTypeTitle';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import AlertCardsList from '~/components/logic/alerts/AlertCardsList';
import WebsiteFavicon, {
	WebsiteFaviconUsageContext,
} from '~/components/logic/websites/WebsiteFavicon';
import RecentAlertCard from '~/components/logic/alerts/RecentAlertCard';

import {
	useMultiWebsitesDashboardAlertsListQuery,
} from './MultiWebsitesDashboardAlertsList.gql';

import usePollInterval from '~/hooks/usePollInterval';

import {
	LIST_OF_ALL_ALERTS,
} from '~/model/alerts';

import sortArrayByProperty from '~/utilities/sortArrayByProperty';



const messages = defineMessages({
	title: {
		id: 'ui.dashboard.alertsTable.header.title',
	},
});



const MultiWebsitesDashboardAlertsList: React.FC = () => {
	const { data } = useMultiWebsitesDashboardAlertsListQuery({
		pollInterval: usePollInterval(30_000),
	});

	const incidents = (data?.authenticatedSession?.latestIncidents ?? []).filter(
		(incident) => LIST_OF_ALL_ALERTS.includes(incident.type),
	);

	const sortedIncidents = sortArrayByProperty(
		incidents,
		(incident) => incident.openedAt,
	).reverse();

	return (
		<BorderedBox
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			reversedColors={true}
		>
			<AlertCardsList
				events={sortedIncidents.map((incident) => (
					<RecentAlertCard
						alertId={incident.incidentId}
						icon={(
							<WebsiteFavicon
								usageContext={WebsiteFaviconUsageContext.List}
								websiteId={incident.website.id}
							/>
						)}
						key={incident.id}
						message={(
							<AlertTypeTitle
								alertType={incident.type}
								settings={incident.settings}
							/>
						)}
						websiteId={incident.website.id}
					/>
				))}
			/>
		</BorderedBox>
	);
};



export default MultiWebsitesDashboardAlertsList;
