import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DesktopIconBuilder: IconBuilder = {
	icon: ({ color = '#5C6773' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				opacity="0.7"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="Group"
					stroke={color}
					transform="translate(3.000000, 3.000000)"
				>
					<rect
						height="13.4545455"
						id="Rectangle"
						rx="1.14286"
						width="17.9392818"
						x="0.000134545455"
						y="0"
					>
					</rect>
					<g
						id="Path"
						strokeLinejoin="round"
						transform="translate(0.000000, 9.904115)"
					>
						<line
							x1="17.9392706"
							x2="1.99167282e-15"
							y1="0.560606061"
							y2="0.560606061"
						>
						</line>
					</g>
					<path
						d="M11.3616797,17.9393939 L11.3616797,15.6969697 L11.3616797,13.4545455 L6.57780394,13.4545455 L6.57780394,15.6969697 L6.57780394,17.9393939 M2.98998121,17.9393939 L14.9495585,17.9393939"
						id="Shape"
						strokeLinecap="round"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default DesktopIconBuilder;

