import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const TeamIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-team">
					<g id="team">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<path
							d="M7.21819205,10.465878 C4.86047444,11.0725582 3.11831857,13.2123355 3.11831857,15.7592991 L6.88820724,15.7592991"
							id="Stroke-1046"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M15.4163677,10.7867763 C17.0628758,10.7867763 18.3978454,9.45180668 18.3978454,7.80529858 C18.3978454,6.15879049 17.0628758,4.82450405 15.4163677,4.82450405 C14.0759324,4.82450405 12.9554871,5.71471154 12.5804112,6.93217105"
							id="Stroke-1047"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M11.420477,6.93244433 C11.0454011,5.71498482 9.92495572,4.82409413 8.5845205,4.82409413 C6.9380124,4.82409413 5.60304276,6.15906377 5.60304276,7.80557186 C5.60304276,9.45207996 6.9380124,10.7870496 8.5845205,10.7870496"
							id="Stroke-1048"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M13.4922077,13.2170496 C13.0412968,13.4425051 12.539146,13.5818775 12.0001025,13.5818775 C11.4617422,13.5818775 10.9595913,13.4425051 10.5086804,13.2170496 C7.83464195,13.8845344 5.85131705,16.2955415 5.85131705,19.1759059 L18.1488879,19.1759059 C18.1488879,16.2955415 16.165563,13.8845344 13.4922077,13.2170496 L13.4922077,13.2170496 Z"
							id="Stroke-1049"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M17.1117928,15.7594357 L20.8816814,15.7594357 C20.8816814,13.2090562 19.1347432,11.0665461 16.7722432,10.4632819"
							id="Stroke-1050"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M12.0005124,13.5816726 C10.1483616,13.5816726 8.64669155,12.0800025 8.64669155,10.2278517 C8.64669155,8.37570091 10.1483616,6.87403087 12.0005124,6.87403087 C13.8526632,6.87403087 15.3543332,8.37570091 15.3543332,10.2278517 C15.3543332,12.0800025 13.8526632,13.5816726 12.0005124,13.5816726 Z"
							id="Stroke-1051"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default TeamIconBuilder;

