import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import MarginBox, {
	SIZE_DEFAULT as BOX_SIZE_DEFAULT,
	SIZE_LARGE as BOX_SIZE_LARGE,
} from '../boxes/MarginBox';



export const SIZE_DEFAULT = BOX_SIZE_DEFAULT;
export const SIZE_LARGE = BOX_SIZE_LARGE;

class MarginsList extends Component {

	render() {
		const {
			children,
			size,
		} = this.props;

		const elements = React.Children.toArray(children).filter((child) => !!child);

		return (
			<div className="margins-list">
				{React.Children.map(
					elements,
					(child, index) => {
						// last child won't be wrapped by MarginBox
						if (React.Children.count(elements) - 1 === index) {
							return child;
						}

						return (
							<MarginBox size={size}>
								{child}
							</MarginBox>
						);
					},
				)}
			</div>
		);
	}

}

MarginsList.defaultProps = {
	size: SIZE_DEFAULT,
};

MarginsList.propTypes = {
	/** Possibility to set different size of gap */
	size: PropTypes.oneOf([
		SIZE_DEFAULT,
		SIZE_LARGE,
	]).isRequired,
};



export default MarginsList;
