import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useLegalDocumentRequirementQuery,
} from '~/hooks/useLegalDocumentRequirement.gql';



function useLegalDocumentRequirement(
	accountId: CK.AccountId | null,
	documentType: GraphQL.LegalDocumentType,
) {
	const { data, loading } = useLegalDocumentRequirementQuery({
		variables: {
			accountId: accountId ?? 0,
		},
		skip: accountId === null,
	});

	return React.useMemo(
		() => ({
			document: (
				data?.account?.legalDocumentRequirements
					.find((document) => {
						return document.documentType === documentType;
					})
			) ?? null,
			loading,
		}),
		[
			data,
			documentType,
			loading,
		],
	);
}



export default useLegalDocumentRequirement;
