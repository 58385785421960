import type CK from '~/types/contentking';

import {
	useAccountBillingEntityQuery,
} from './useAccountBillingEntity.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountBillingEntity(accountId: CK.AccountId | null): string | null {
	const { data } = useAccountPropertiesQuery(
		useAccountBillingEntityQuery,
		accountId,
	);

	return data?.account?.billingEntity ?? null;
}



export default useAccountBillingEntity;
