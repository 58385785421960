import React from 'react';

import {
	useDispatch,
	useSelector,
} from 'react-redux';

import {
	markInterfaceHintAsDisplayed,
	setInterfaceHintAsClosed,
	setInterfaceHintAsOpened,
	tryHidingInterfaceHint,
} from '~/actions/interfaceHints';

import {
	shouldHintBeDisplayedAlready,
} from '~/model/interfaceHints';

import {
	interfaceHintsSelector,
} from '~/state/ui/interfaceHints/selectors';

import {
	uiInteractionsSelector,
} from '~/state/ui/uiInteractions/selectors';



function useInterfaceHint({
	name,
	conditionsAlready,
	conditionsNotAnymore,
	isObsolete = false,
}) {
	const dispatch = useDispatch();

	const rules = React.useRef({
		conditionsAlready,
		conditionsNotAnymore,
	});

	const interfaceHints = useSelector(interfaceHintsSelector);
	const uiInteractions = useSelector(uiInteractionsSelector);

	const pendingRequestsRef = React.useRef([]);

	const context = React.useMemo(
		() => ({
			uiInteractions,
		}),
		[
			uiInteractions,
		],
	);

	const shouldBeDisplayed = React.useMemo(
		() => {
			return shouldHintBeDisplayedAlready(
				name,
				rules.current.conditionsAlready,
				isObsolete
					? () => true
					: rules.current.conditionsNotAnymore,
				context,
				interfaceHints.get('hints'),
				interfaceHints.get('openedHint'),
			);
		},
		[
			context,
			interfaceHints,
			isObsolete,
			name,
			rules,
		],
	);

	const shouldBeDisplayedRef = React.useRef(false);
	shouldBeDisplayedRef.current = shouldBeDisplayed;

	const hasInterfaceHints = interfaceHints.get('hints').size > 0;

	React.useEffect(
		() => {
			if (hasInterfaceHints) {
				pendingRequestsRef.current.forEach((callback) => {
					if (shouldBeDisplayedRef.current) {
						callback();
					}
				});
			}
		},
		[
			hasInterfaceHints,
			shouldBeDisplayedRef,
		],
	);

	const display = React.useCallback(
		(callback) => {
			if (interfaceHints.get('hints').size === 0) {
				pendingRequestsRef.current.push(callback);
			} else if (shouldBeDisplayedRef.current) {
				callback();
			}
		},
		[
			interfaceHints,
			pendingRequestsRef,
			shouldBeDisplayedRef,
		],
	);

	const markClosed = React.useCallback(
		() => {
			dispatch(setInterfaceHintAsClosed());
		},
		[
			dispatch,
		],
	);

	const markOpened = React.useCallback(
		() => {
			dispatch(setInterfaceHintAsOpened(name));
			dispatch(markInterfaceHintAsDisplayed(name));
		},
		[
			dispatch,
			name,
		],
	);

	React.useEffect(
		() => {
			dispatch(
				tryHidingInterfaceHint(
					name,
					isObsolete
						? () => true
						: rules.current.conditionsNotAnymore,
					context,
				),
			);
		},
		[
			context,
			dispatch,
			interfaceHints,
			isObsolete,
			name,
			rules,
		],
	);

	return React.useMemo(
		() => ({
			display,
			markClosed,
			markOpened,
			shouldBeDisplayed,
		}),
		[
			display,
			markClosed,
			markOpened,
			shouldBeDisplayed,
		],
	);
}



export default useInterfaceHint;
