import React from 'react';

import RichText from '~/components/patterns/typography/RichText';



type Props = {
	/** Additional Call-to-Action button */
	button?: React.ReactElement,
	/** Additional footer */
	footer?: React.ReactNode,
	/** Highlighted message */
	children?: React.ReactNode,
	title: React.ReactNode,
};



const ErrorAnnouncement: React.FC<Props> = (props) => {
	const {
		button,
		footer,
		children,
		title,
	} = props;

	return (
		<div className="error-announcement">
			<h2 className="error-announcement__title">
				<span>{title}</span>
			</h2>

			{children && (
				<div className="error-announcement__text">
					<RichText>
						{children}
					</RichText>
				</div>
			)}

			{button && (
				<div className="error-announcement__button">
					{button}
				</div>
			)}

			{footer && (
				<div className="error-announcement__footer">
					{footer}
				</div>
			)}
		</div>
	);
};



export default ErrorAnnouncement;
