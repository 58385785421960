import Immutable from 'immutable';

import {
	CHANGE_URL_STATE,
	LOGOUT_SUCCESSFUL,
	START_LOADING_PAGES_BY_IDS,
	STORE_LOADED_PAGES,
	STORE_PAGE_RELATIONS,
} from '~/actions';

import {
	createPageFromData,
	updatePageFromData,
} from '~/model/api/Page';

import {
	STATE_LOADED,
	STATE_NOT_LOADED,
	createFullId,
} from '~/model/pages';



function createDefaultState() {
	return Immutable.Map({
		basics: Immutable.Map(),
		outgoing_internal_links: Immutable.Map(),
		outbound_links: Immutable.Map(),
	});
}



export function pages(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case START_LOADING_PAGES_BY_IDS: {
			let basics = state.get('basics');

			basics = basics.withMutations((newBasics) => {
				for (const index in action.pagesIds) {
					const id = action.pagesIds[index];

					if (newBasics.get(id.toString())) {
						continue;
					}

					const page = createPageFromData(id, action.domainId);
					newBasics.set(page.get('fullId'), page);
				}
			});

			state = state.set('basics', basics);

			break;
		}

		case STORE_LOADED_PAGES: {
			let basics = state.get('basics');

			basics = basics.withMutations((newBasics) => {
				let fullId;

				for (const index in action.pages) {
					const page = action.pages[index];
					const id = page.id;

					let internalPage;
					fullId = createFullId(action.domainId, id);

					if (basics.get(fullId)) {
						internalPage = updatePageFromData(
							id,
							action.domainId,
							page,
						);
						internalPage = basics.get(internalPage.get('fullId')).mergeDeep(internalPage);
					} else {
						internalPage = createPageFromData(
							id,
							action.domainId,
							page,
						);
					}

					newBasics.set(internalPage.get('fullId'), internalPage);
				}

				for (const id in action.externalUrls) {
					let externalPage;
					fullId = createFullId(action.domainId, id);

					if (basics.get(fullId)) {
						externalPage = updatePageFromData(
							id,
							action.externalUrls[id].website_id,
							action.externalUrls[id],
							fullId,
						);
						externalPage = basics.get(fullId).mergeDeep(externalPage);
					} else {
						externalPage = createPageFromData(
							id,
							action.externalUrls[id].website_id,
							action.externalUrls[id],
							fullId,
						);
					}

					newBasics.set(externalPage.get('fullId'), externalPage);
				}
			});

			state = state.set('basics', basics);

			break;
		}

		case STORE_PAGE_RELATIONS: {
			const {
				data,
				fullId,
				offset,
				relationType: type,
			} = action;

			let allLinkData = state.get(type).get(fullId) || Immutable.Map({
				data: Immutable.Map(),
				_status: STATE_NOT_LOADED,
			});

			const pageUrls = {};
			data[type].forEach((link, i) => {
				pageUrls[link.id] = link.url;
				allLinkData = allLinkData.set('data', allLinkData.get('data').set(offset + i, Immutable.fromJS(link)));
			});

			let basics = state.get('basics');
			const currentPageDomainId = basics.get(fullId).get('domainId');

			for (const pageId in pageUrls) {
				const storedPage = basics.get(pageId);
				if (!storedPage) {
					basics = basics.set(pageId, createPageFromData(pageId, currentPageDomainId, { url: pageUrls[pageId] }));
				}
			}

			allLinkData = allLinkData.set('_status', STATE_LOADED);
			state = state.set(type, state.get(type).set(fullId, allLinkData));
			state = state.set('basics', basics);

			break;
		}

		case CHANGE_URL_STATE: {
			const urlState = action.urlState;

			if (urlState.name.indexOf('website.pages.detail') === 0) {
				let page = state.get('basics').get(createFullId(
					urlState.params.websiteId,
					urlState.params.id,
				));

				if (!page) {
					page = createPageFromData(
						urlState.params.id,
						urlState.params.websiteId,
					);

					state = state.mergeDeep({
						basics: {
							[page.get('fullId')]: page,
						},
					});
				}
			}

			break;
		}

	}

	return state;
}
