import React from 'react';

import ChartLegend, {
	type ChartLegendItemProps,
} from '~/components/patterns/charts/components/ChartLegend';



type Props = {
	data: Array<ChartLegendItemProps>,
	/** Title of overview tooltip */
	title: React.ReactNode,
};



const SharedChartTooltip: React.FC<Props> = (props) => {
	const {
		data,
		title,
	} = props;

	return (
		<div className="shared-chart-tooltip dark-bg-context">
			<div className="shared-chart-tooltip__title">{title}</div>
			{data.length > 0 && (
				<ChartLegend
					items={data}
				/>
			)}
		</div>
	);
};



export default SharedChartTooltip;
