import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import CountSlider, {
	CountSliderBubbleAlignment,
	CountSliderBubbleAttachment,
	CountSliderStyle,
} from '~/components/patterns/forms/fields/CountSlider';
import CountSliderHeadlineLayout from '~/components/patterns/forms/fieldParts/countSliders/CountSliderHeadlineLayout';
import EditableString from '~/components/patterns/values/EditableString';
import HighlightedValue, {
	HighlightedValueStyle,
} from '~/components/patterns/values/HighlightedValue';
import PageBundleFieldContext from '~/components/app/PageBundleFieldContext';

import useAccountId from '~/hooks/useAccountId';
import useAccountMaximumRegularSignupPageBundle from '~/hooks/useAccountMaximumRegularSignupPageBundle';
import useAccountPlan from '~/hooks/useAccountPlan';
import useFormContext from '~/hooks/useFormContext';
import useTariffs from '~/hooks/useTariffs';

import {
	isEnterprisePlan,
} from '~/model/plans';



const messages = defineMessages({
	enterpriseTooltip: {
		id: 'ui.signup.pagesConfigurator.enterpriseAnnouncement',
	},
});



type Props = {
	name: string,
	optimalPageBundle: number | null,
	title: (input: { inputField: React.ReactNode }) => React.ReactNode,
};

const PageBundleSignupField: React.FC<Props> = (props) => {
	const {
		name,
		optimalPageBundle,
		title,
	} = props;

	const accountId = useAccountId();

	const accountMaximumRegularSignupPageBundle = useAccountMaximumRegularSignupPageBundle(accountId);
	const accountPlan = useAccountPlan(accountId);
	const formContext = useFormContext();
	const intl = useIntl();
	const tariffs = useTariffs();

	const [alreadyInteractedSlider, setAlreadyInteractedSlider] = React.useState<boolean>(false);

	if (
		tariffs.isReady === false
		|| accountMaximumRegularSignupPageBundle === null
	) {
		return (
			<CountSliderHeadlineLayout
				slider={(
					<CountSlider
						animatedHandle={false}
						disabled={true}
						max={100}
						style={CountSliderStyle.Expressive}
						value={50}
					/>
				)}
				title={title({
					inputField: (
						<HighlightedValue style={HighlightedValueStyle.Expressive}>
							...
						</HighlightedValue>
					),
				})}
			/>
		);
	}

	const tariff = tariffs.tariff;
	const value = formContext.values[name] ?? formContext.defaultValues[name] ?? 0;

	return (
		<PageBundleFieldContext
			minimumNumberOfPages={optimalPageBundle ?? 0}
			name={name}
			showExtraStep={true}
			tariff={tariff}
			value={value}
		>
			{({ currentStep, currentValue, minStep, onSliderStepChangeHandler, onSliderValueChangeHandler, sliderMax, maxValue }) => {
				const countSliderOptions: any = {};

				const isEnterprise = accountPlan !== null && isEnterprisePlan(accountPlan);

				if (
					!isEnterprise
					&& currentValue > accountMaximumRegularSignupPageBundle
				) {
					const from = (accountMaximumRegularSignupPageBundle + 1_000_000);
					let to = maxValue;

					if (from === to) {
						to = 50_000_000;
					}

					countSliderOptions.handlerBubble = (
						<FormattedMessage
							{...messages.enterpriseTooltip}
							values={{ from, to }}
						/>
					);

					countSliderOptions.handlerBubbleAlignment = CountSliderBubbleAlignment.Right;
					countSliderOptions.handlerBubbleAttachment = CountSliderBubbleAttachment.Bottom;
				}

				return (
					<CountSliderHeadlineLayout
						slider={(
							<CountSlider
								animatedHandle={!alreadyInteractedSlider && currentStep === minStep}
								highlightedValue={minStep || undefined}
								max={sliderMax}
								onChangeCallback={(props) => {
									setAlreadyInteractedSlider(true);
									onSliderStepChangeHandler(props);
								}}
								style={CountSliderStyle.Expressive}
								value={currentStep}
								{...countSliderOptions}
							/>
						)}
						title={title({
							inputField: (
								<HighlightedValue style={HighlightedValueStyle.Expressive}>
									<EditableString
										editableValue={currentValue}
										inputAttributes={{
											maxLength: 8,
										}}
										inputType="tel"
										onChangeCallback={onSliderValueChangeHandler}
										value={intl.formatNumber(currentValue)}
									/>
								</HighlightedValue>
							),
						})}
					/>
				);
			}}
		</PageBundleFieldContext>
	);
};



export default PageBundleSignupField;
