import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow, {
	FormRowStyle,
} from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import GlobalFormMessagePattern from '~/components/atoms/forms/basis/GlobalFormMessagePattern';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalPanel, {
	SIZE_MEDIUM as MODAL_SIZE_MEDIUM,
} from '~/components/atoms/panels/ModalPanel';
import SegmentsPreview from '~/components/atoms/forms/components/segments/SegmentsPreview';
import SegmentColorField from '~/components/app/SegmentColorField';
import SegmentIcon from '~/components/patterns/icons/SegmentIcon';
import SegmentIdentifier from '~/components/patterns/segments/SegmentIdentifier';
import SegmentSymbolField from '~/components/app/SegmentSymbolField';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	COLORS,
	type SegmentDefinition,
	isIdentifierSame,
} from '~/model/segments';



const messages = defineMessages({
	fieldColor: {
		id: 'ui.segments.management.field.color',
	},
	fieldLabel: {
		id: 'ui.segments.management.field.name',
	},
	fieldSegmentLabel: {
		id: 'ui.segments.management.field.segmentLabel',
	},
	formErrorsBlank: {
		id: 'ui.formErrors.blank',
	},
	formErrorsDuplicate: {
		id: 'ui.segments.management.errors.duplicateIdentifier',
	},
	submitCreate: {
		id: 'ui.segments.editor.identifierModal.button.create',
	},
	submitEdit: {
		id: 'ui.segments.editor.identifierModal.button.edit',
	},
	title: {
		id: 'ui.segments.editor.identifierModal.title',
	},
});



type Props = {
	closeCallback: () => void,
	defaultValues: {
		color: string | null,
		label: string | null,
		iconName: string | null,
		shortcode: string | null,
	},
	editedSegment: SegmentDefinition | null,
	hasLabel?: boolean,
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	submitCallback: (data: {
		color: string,
		label: string,
		icon?: string,
		shortcode?: string,
	}) => void,
};

const IdentifierModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
		defaultValues,
		editedSegment,
		hasLabel,
		segmentDefinitions,
		submitCallback,
	} = props;

	const otherSegmentDefinitions = segmentDefinitions.filter(
		(segment) => editedSegment !== null && segment.id !== editedSegment.id,
	);

	const validations = {
		color: [
			{
				field: 'color',
				message: (
					<FormattedMessage {...messages.formErrorsBlank} />
				),
				rule: ({ values, name }) => {
					return !!values[name];
				},
			},
		],
		label: [
			{
				field: 'label',
				message: (
					<FormattedMessage {...messages.formErrorsBlank} />
				),
				rule: ({ values, name }) => {
					return !!values[name];
				},
			},
		],
		uniqueness: [
			{
				fields: [
					'color',
					'label',
					'symbol',
				],
				message: (
					<FormattedMessage {...messages.formErrorsDuplicate} />
				),
				rule: ({ values }) => !otherSegmentDefinitions.some(
					(segmentDefinition) => isIdentifierSame(segmentDefinition, {
						color: values.color,
						icon: values.symbol.icon ? {
							name: values.symbol.icon,
						} : null,
						label: values.label,
						shortcode: (!values.symbol.icon && values.symbol.value) || null,
					}),
				),
			},
		],
	};

	const handleSubmit = React.useCallback(
		(data) => {
			return submitCallback({
				color: data.color.toLowerCase(),
				icon: data.symbol.icon ?? null,
				label: data.label,
				shortcode: (!data.symbol.icon && data.symbol.value) || null,
			});
		},
		[
			submitCallback,
		],
	);

	return (
		<ModalPanel
			canBeClosed={true}
			canBeClosedViaOverlay={false}
			onCloseCallback={closeCallback}
			size={MODAL_SIZE_MEDIUM}
		>
			<ModalContainer
				gapsSize={2}
				header={(
					<ModalHeader
						iconType={ModalHeaderIconType.CogWheel}
						title={(
							<FormattedMessage {...messages.title} />
						)}
					/>
				)}
				headerGapsSize={1}
			>
				<Form
					defaultValues={{
						color: defaultValues.color ?? COLORS[0],
						label: defaultValues.label ?? '',
						symbol: {
							icon: defaultValues.iconName,
							value: defaultValues.shortcode ?? '',
						},
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					{({ errors, values }) => {
						let symbol;

						if (values.symbol) {
							if (values.symbol.icon) {
								symbol = (
									<SegmentIcon type={values.symbol.icon} />
								);
							} else if (values.symbol.value) {
								symbol = values.symbol.value;
							}
						}

						return (
							<>
								<FormRows>
									<FormRow
										htmlFor="color"
										label={(
											<FormattedMessage {...messages.fieldColor} />
										)}
									>
										<FieldStatus
											name="color"
											showIcon={false}
										>
											<SegmentColorField
												name="color"
											/>
										</FieldStatus>
									</FormRow>

									<FormRow
										htmlFor="label"
										label={(
											<FormattedMessage {...messages.fieldLabel} />
										)}
									>
										<SegmentSymbolField
											name="symbol"
										/>

										<FieldStatus name="label">
											<TextField
												name="label"
												trimValue={true}
												width={200}
											/>
										</FieldStatus>
									</FormRow>

									<FormRow
										label={(
											<FormattedMessage {...messages.fieldSegmentLabel} />
										)}
										style={FormRowStyle.Highlighted}
									>
										<SegmentsPreview
											label={(
												<SegmentIdentifier
													color={values.color}
													label={values.label}
													symbol={symbol}
												/>
											)}
											labelThumb={(
												<SegmentIdentifier
													color={values.color}
													symbol={symbol}
												/>
											)}
										/>
									</FormRow>
								</FormRows>

								{errors.uniqueness && (
									<GlobalFormMessagePattern>
										{errors.uniqueness}
									</GlobalFormMessagePattern>
								)}

								<ButtonsLayout>
									<CancelButton />

									<SubmitButton>
										{hasLabel ? (
											<FormattedMessage {...messages.submitEdit} />
										) : (
											<FormattedMessage {...messages.submitCreate} />
										)}
									</SubmitButton>
								</ButtonsLayout>
							</>
						);
					}}
				</Form>
			</ModalContainer>
		</ModalPanel>
	);
};



export default IdentifierModal;
