import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ToolboxIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M24,9.375a3.5,3.5,0,0,0-3.5-3.5H17.75a.5.5,0,0,1-.5-.5v-1A3.256,3.256,0,0,0,14,1.125H10a3.256,3.256,0,0,0-3.25,3.25v1a.5.5,0,0,1-.5.5H3.5A3.5,3.5,0,0,0,0,9.375v10a3.5,3.5,0,0,0,3.5,3.5h17a3.5,3.5,0,0,0,3.5-3.5Zm-14.75-5a.755.755,0,0,1,.75-.75h4a.755.755,0,0,1,.75.75v1a.5.5,0,0,1-.5.5H9.75a.5.5,0,0,1-.5-.5Zm7.52,10.41.59.48a.517.517,0,0,0,.32.11h1.31a.507.507,0,0,1,.4.8,2.982,2.982,0,0,1-5.091-.516.5.5,0,0,0-.451-.284h-3.7a.5.5,0,0,0-.451.284A3,3,0,0,1,7,17.375,3.1,3.1,0,0,1,4.68,16.2a.5.5,0,0,1,.39-.82H6.32a.517.517,0,0,0,.32-.11l.59-.48a.474.474,0,0,0,.19-.38.522.522,0,0,0-.17-.39l-.61-.52a.49.49,0,0,0-.32-.12H5.07a.5.5,0,0,1-.39-.82A3.1,3.1,0,0,1,7,11.375a3,3,0,0,1,2.7,1.716.5.5,0,0,0,.451.284h3.7a.5.5,0,0,0,.451-.284,2.982,2.982,0,0,1,5.091-.516.507.507,0,0,1-.4.8H17.68a.49.49,0,0,0-.32.12l-.61.52a.522.522,0,0,0-.17.39A.474.474,0,0,0,16.77,14.785Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ToolboxIconBuilder;

