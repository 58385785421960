import {
	List,
} from 'immutable';



export const END_USER_PLAN_ESSENTIALS = 'end_user_essentials';
export const END_USER_PLAN_PRO = 'end_user_pro';

export const LIST_OF_ALL_PLANS = List([
	END_USER_PLAN_ESSENTIALS,
	END_USER_PLAN_PRO,
]);
