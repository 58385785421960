import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const MagicWandIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="prune"
					transform="translate(0.000000, -1.000000)"
				>
					<polygon
						fill="#A5A5A5"
						fillOpacity="0"
						id="Fill-625"
						points="25 25 0 25 0 0 25 0"
					>
					</polygon>
					<path
						d="M5,16.5 L8,16.5"
						id="Stroke-626"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M6.5,15 L6.5,18"
						id="Stroke-627"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M14,7.5 L17,7.5"
						id="Stroke-628"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M15.5,6 L15.5,9"
						id="Stroke-629"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<polygon
						id="Stroke-630"
						points="22 19.5655555 19.5655555 22 8 10.4352222 10.4344444 8"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</polygon>
					<path
						d="M10,12 L12,10"
						id="Stroke-631"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M4,6 L6,8"
						id="Stroke-632"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
					<path
						d="M6,6 L4,8"
						id="Stroke-633"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MagicWandIconBuilder;

