import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const BugFixedIconBuilder: IconBuilder = {
	icon: ({ color = '#8595a6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g id="issue-fixed-bug">
					<g
						id="Group"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						transform="translate(2.571429, 1.714286)"
					>
						<path
							d="M15.4553571,11.5771683 C15.4553571,16.0946747 12.7570791,19.7563775 9.42857143,19.7563775 C6.1000638,19.7563775 3.40178571,16.0946747 3.40178571,11.5771683 C3.40178571,7.05966197 6.1000638,3.39795918 9.42857143,3.39795918 C12.7570791,3.39795918 15.4553571,7.05966197 15.4553571,11.5771683 Z"
							id="Path"
						>
						</path>
						<path
							d="M4.11862882,7.70616394 L14.7197449,7.70272003"
							id="Path"
						>
						</path>
						<polyline
							id="Path"
							points="3.40178571 10.2857143 0.818877549 9.42474489 0.818877549 7.70280609"
						>
						</polyline>
						<polyline
							id="Path"
							points="4.11862882 15.4865721 1.67950255 16.3122417 1.67950255 18.0341805"
						>
						</polyline>
						<polyline
							id="Path"
							points="15.4553571 10.2857143 18.0382653 9.42474489 18.0382653 7.70280609"
						>
						</polyline>
						<polyline
							id="Path"
							points="14.7082079 15.501639 17.1774681 16.3126722 17.1774681 18.034611"
						>
						</polyline>
						<path
							d="M7.11798789,3.98780931 C7.11798789,3.98780931 7.16792409,1.78372767 5.12312181,0.815137114"
							id="Path"
						>
						</path>
						<path
							d="M11.9993399,4.17730868 C11.9993399,4.17730868 11.9614573,1.78381377 14.0062596,0.815223214"
							id="Path"
						>
						</path>
					</g>
					<circle
						cx="16.2857143"
						cy="17.1428571"
						fill="#42CC67"
						fillRule="nonzero"
						id="Oval"
						r="5.14285714"
					>
					</circle>
					<g
						fill="#FAFAFA"
						fillRule="nonzero"
						id="Group"
						transform="translate(13.714286, 15.357143)"
					>
						<path
							d="M2.53434174,3.30432622 L2.1728476,3.68927022 C1.94626956,3.93054622 1.57502024,3.91643422 1.36519167,3.65849823 L0.30279648,2.35313823 C0.166960089,2.18614623 0.175242797,1.93585422 0.321938734,1.77964222 C0.468634671,1.62362622 0.703679486,1.61461023 0.860314671,1.75925823 L2.53434174,3.30432622 Z"
							id="Path"
						>
						</path>
						<path
							d="M1.10290595,3.16555822 L4.15554355,0.16597422 C4.34052399,-0.01630578 4.62765783,-0.01042578 4.80582806,0.179302226 C4.98896791,0.374322223 4.98896791,0.69047022 4.80582806,0.885490226 L2.2144452,3.64497423 C1.97332641,3.90193023 1.57299557,3.86665023 1.37476279,3.57088623 L1.10290595,3.16555822 Z"
							id="Path"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default BugFixedIconBuilder;

