import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AnnouncementPopup from '~/components/patterns/messages/popup/AnnouncementPopup';
import AppearAnimation from '~/components/patterns/animations/AppearAnimation';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import useRegisterMicrosoftTeamsMessagingAppChannel from '~/hooks/useRegisterMicrosoftTeamsMessagingAppChannel';



const messages = defineMessages({
	description: {
		id: 'ui.microsoftTeams.addChannelFormDescription',
	},
	invalidIncomingWebhookUrl: {
		id: 'ui.microsoftTeams.invalidIncomingWebhookUrl',
	},
});



export const validateIncomingWebhookUrl = validateField(
	'incomingWebhookUrl',
	(f) => [
		f.validateNonEmpty(),
		f.custom({
			message: (
				<Copy
					{...messages.invalidIncomingWebhookUrl}
					values={{
						link: linkExternal('https://www.contentkingapp.com/support/microsoft-teams/'),
					}}
				/>
			),
			rule: ({ value }) => /webhookb2/.test(value),
		}),
	],
);

export const validateLabel = validateField(
	'label',
	(f) => [
		f.validateNonEmpty(),
	],
);

const validations = {
	validateIncomingWebhookUrl,
	validateLabel,
};



type Props = {
	onCancel: () => void,
	onSuccess: (registeredMessagingAppChannelId: string) => void,
};

const AddMicrosoftTeamsMessagingAppChannelOverlay: React.FC<Props> = (props) => {
	const {
		onCancel,
		onSuccess,
	} = props;

	const registerMicrosoftTeamsMessagingAppChannel = useRegisterMicrosoftTeamsMessagingAppChannel();

	const handleSubmit = React.useCallback(
		async (values) => {
			const registeredMessagingAppChannelId = await registerMicrosoftTeamsMessagingAppChannel(
				values.incomingWebhookUrl,
				values.label,
			);

			onSuccess(registeredMessagingAppChannelId);
		},
		[
			onSuccess,
			registerMicrosoftTeamsMessagingAppChannel,
		],
	);

	return (
		<AppearAnimation>
			<AnnouncementPopup
				maxWidth="640px"
				title="Connect to a Microsoft Teams channel"
			>
				<PremiumFeatureSituation
					featureName={GraphQL.AccountFeature.MicrosoftTeams}
					style={PremiumFeatureSituationStyle.Box}
				>
					{({ isFeatureEnabled, premiumAnnotation }) => (
						<Form
							onSuccess={handleSubmit}
							validations={validations}
						>
							{isFeatureEnabled === false && premiumAnnotation}

							{isFeatureEnabled && (
								<FormRows>
									<RichText>
										<Copy
											{...messages.description}
											values={{
												link: linkExternal('https://www.contentkingapp.com/support/microsoft-teams/'),
											}}
										/>
									</RichText>

									<FormRow
										htmlFor="label"
										label="Channel name"
									>
										<FieldStatus name="validateLabel">
											<TextField
												name="label"
											/>
										</FieldStatus>
									</FormRow>

									<FormRow
										htmlFor="incomingWebhookUrl"
										label="Webhook URL"
									>
										<FieldStatus name="validateIncomingWebhookUrl">
											<TextField
												name="incomingWebhookUrl"
											/>
										</FieldStatus>
									</FormRow>
								</FormRows>
							)}

							<ButtonsLayout>
								<CancelButton
									onClickCallback={onCancel}
								/>

								<SubmitButton>
									Connect
								</SubmitButton>
							</ButtonsLayout>
						</Form>
					)}
				</PremiumFeatureSituation>
			</AnnouncementPopup>
		</AppearAnimation>
	);
};



export default AddMicrosoftTeamsMessagingAppChannelOverlay;
