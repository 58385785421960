import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField from '~/components/atoms/forms/components/TextField';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	validateField,
} from '~/components/app/validations';

import {
	useAdministerAccountCertifiedPartnerProgramSettingsMutation,
	useCertifiedPartnerProgramFormQuery,
} from './CertifiedPartnerProgramForm.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';

import FormError from '~/utilities/FormError';



const validations = {
	partnerAffiliateId: validateField(
		'partnerAffiliateId',
		(f) => [
			f.whenOtherField('isPartner', ({ value }) => !!value),
			f.validateNonEmpty(),
		],
	),
};



type Props = {
	accountId: CK.AccountId,
};

const CertifiedPartnerProgramForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const { data } = useAccountPropertiesQuery(
		useCertifiedPartnerProgramFormQuery,
		accountId,
	);

	const isLoading = (data?.account?.adminSettings ?? null) === null;
	const partnerAffiliateId = data?.account?.adminSettings?.partnerAffiliateId ?? null;

	const [administerAccountCertifiedPartnerProgramSettings] = useAdministerAccountCertifiedPartnerProgramSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				await administerAccountCertifiedPartnerProgramSettings({
					variables: {
						accountId,
						partnerAffiliateId: values.partnerAffiliateId ?? null,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}
		},
		[
			accountId,
			administerAccountCertifiedPartnerProgramSettings,
		],
	);

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageInternals}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					isForAdmins={true}
					title="Certified Partner Program"
				>
					<DisplayPart>
						<FormRows>
							<FormRow label="Certified Partner">
								<StaticText focusTarget="isPartner">
									{isLoading ? '' : (partnerAffiliateId !== null) ? 'yes' : 'no'}
								</StaticText>
							</FormRow>

							{partnerAffiliateId !== null && (
								<FormRow label="Partner ID">
									<StaticText focusTarget="partnerAffiliateId">
										{partnerAffiliateId}
									</StaticText>
								</FormRow>
							)}
						</FormRows>
					</DisplayPart>

					<EditablePart>
						{isLoading === false && (
							<Form
								defaultValues={{
									isPartner: partnerAffiliateId !== null,
									partnerAffiliateId,
								}}
								onSuccess={handleSubmit}
								validations={validations}
							>
								{({ values }) => {
									return (
										<>
											<FormRows>
												<FormRow
													htmlFor="isPartner"
													label="Certified Partner"
												>
													<CheckboxField
														label="yes"
														name="isPartner"
														width={false}
													/>
												</FormRow>

												{values.isPartner && (
													<FormRow
														htmlFor="partnerAffiliateId"
														label="Partner ID"
													>
														<TextField
															name="partnerAffiliateId"
															trimValue={true}
														/>
													</FormRow>
												)}
											</FormRows>

											<ButtonsLayout>
												<CancelButton />
												<SaveSubmitButton />
											</ButtonsLayout>
										</>
									);
								}}
							</Form>
						)}
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WhenAccountActionAllowed>
	);
};



export default CertifiedPartnerProgramForm;
