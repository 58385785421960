import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import Datatable, {
	DatatableCellAlignment,
	DatatableCellColorStyle,
	type DatatableColumnDefinition,
	type DatatableColumnDefinitions,
	type DatatableContextMenuEntries,
	DatatableHeaderCellAlignment,
	DatatableHeaderCellColorStyle,
	DatatableHeaderHeight,
	type DatatableRef,
	type DatatableRowGetterInput,
	DatatableRowHeight,
	DatatableUsageContext,
} from '~/components/logic/datatables/Datatable';

import {
	setColumnSettings,
} from '~/actions/columnsSettings';

import {
	getColumnMaximumSize,
	getColumnMinimumSize,
	isResizableColumn,
} from '~/model/pagesColumns';

import {
	currentColumnsSettingsSelector,
} from '~/state/columnsSettings/selectors';



const ResizableDatatable = React.forwardRef<DatatableRef, React.ComponentProps<typeof Datatable>>((props, ref) => {
	const {
		columns,
		...restProps
	} = props;

	const dispatch = useDispatch();
	const userColumnsSettings = useSelector(currentColumnsSettingsSelector);

	const onColumnResizeCallback = React.useCallback(
		(columnName, width) => {
			dispatch(setColumnSettings(columnName, width));
		},
		[
			dispatch,
		],
	);

	const resizedColumns = React.useMemo(
		() => columns.map((column) => {
			if (!column.name || column.resizable === false) {
				return column;
			}

			column = { ...column };

			if (
				isResizableColumn(column.name)
				|| column.resizable
			) {
				const userWidth = userColumnsSettings.getIn(
					[
						column.name,
						'width',
					],
					false,
				);

				if (column.widthMaximum === undefined) {
					column.widthMaximum = getColumnMaximumSize(column.name as CK.PagesColumn);
				}

				if (column.widthMinimum === undefined) {
					column.widthMinimum = getColumnMinimumSize(column.name as CK.PagesColumn);
				}

				column.width = userWidth || column.width;
				column.resizable = true;
			}

			return column;
		}),
		[
			columns,
			userColumnsSettings,
		],
	);

	return (
		<Datatable
			columns={resizedColumns}
			onColumnResize={onColumnResizeCallback}
			ref={ref}
			{...restProps}
		/>
	);
});



export default ResizableDatatable;

export {
	DatatableCellAlignment as ResizableDatatableCellAlignment,
	DatatableCellColorStyle as ResizableDatatableCellColorStyle,
	DatatableColumnDefinition as ResizableDatatableColumnDefinition,
	DatatableColumnDefinitions as ResizableDatatableColumnDefinitions,
	DatatableContextMenuEntries as ResizableDatatableContextMenuEntries,
	DatatableHeaderCellAlignment as ResizableDatatableHeaderCellAlignment,
	DatatableHeaderCellColorStyle as ResizableDatatableHeaderCellColorStyle,
	DatatableHeaderHeight as ResizableDatatableHeaderHeight,
	DatatableRef as ResizableDatatableRef,
	DatatableRowGetterInput as ResizableDatatableRowGetterInput,
	DatatableRowHeight as ResizableDatatableRowHeight,
	DatatableUsageContext as ResizableDatatableUsageContext,
};
