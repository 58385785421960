import React from 'react';

import type CK from '~/types/contentking';

import {
	useBulkUpdateAlertDefinitionsMessagingAppChannelsMutation,
} from './useBulkUpdateAlertDefinitionsMessagingAppChannels.gql';



function useBulkUpdateAlertDefinitionsMessagingAppChannels() {
	const [bulkUpdateAlertDefinitionsMessagingAppChannels] = useBulkUpdateAlertDefinitionsMessagingAppChannelsMutation();

	return React.useCallback(
		async (
			alertDefinitionIds: Readonly<Array<CK.AlertDefinitionId>>,
			messagingAppChannelIds: Readonly<Array<string>>,
			websiteId: CK.WebsiteId,
		) => {
			await bulkUpdateAlertDefinitionsMessagingAppChannels({
				variables: {
					alertDefinitionIds,
					messagingAppChannelIds,
					websiteId,
				},
			});
		},
		[
			bulkUpdateAlertDefinitionsMessagingAppChannels,
		],
	);
}



export default useBulkUpdateAlertDefinitionsMessagingAppChannels;
