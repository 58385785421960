import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DiscountIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M22.255,1.973a1.749,1.749,0,0,0-2.475-.035l-18,17.5a1.75,1.75,0,0,0,2.44,2.509l18-17.5A1.749,1.749,0,0,0,22.255,1.973Z"
				fill={color}
			/>
			<circle
				cx="5"
				cy="5.058"
				fill={color}
				r="3.5"
			/>
			<circle
				cx="19"
				cy="19.058"
				fill={color}
				r="3.5"
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default DiscountIconBuilder;
