import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import SegmentWithDuplicateCriteriaExistsMessage from '../SegmentWithDuplicateCriteriaExistsMessage';

import {
	type SegmentDefinitionValidity,
	SegmentDefinitionValidityVerdict,
} from '~/model/segments';



const messages = defineMessages({
	create: {
		id: 'ui.segments.editor.sidebar.button.create',
	},
	edit: {
		id: 'ui.segments.editor.sidebar.button.edit',
	},
	invalidEmpty: {
		id: 'ui.segments.management.errors.emptyCriteria',
	},
	invalidSameIdentifier: {
		id: 'ui.segments.management.errors.duplicateIdentifier',
	},
	invalidUnchanged: {
		id: 'ui.segments.management.errors.unchanged',
	},
});



type Props = {
	invalidStaticUrlsMessage?: React.ReactNode,
	isNewSegment: boolean,
	isSubmitting: boolean,
	onClickCallback: () => void,
	validity: SegmentDefinitionValidity,
};

const SaveButton: React.FC<Props> = (props) => {
	const {
		invalidStaticUrlsMessage = false,
		isNewSegment,
		isSubmitting,
		onClickCallback,
		validity,
	} = props;

	let tooltip;

	const isDisabled = (
		validity.verdict !== SegmentDefinitionValidityVerdict.Valid
		|| !!invalidStaticUrlsMessage
	);

	if (invalidStaticUrlsMessage) {
		tooltip = invalidStaticUrlsMessage;
	} else if (validity.verdict === SegmentDefinitionValidityVerdict.InvalidBecauseEmpty) {
		tooltip = (
			<FormattedMessage {...messages.invalidEmpty} />
		);
	} else if (validity.verdict === SegmentDefinitionValidityVerdict.InvalidBecauseUnchanged) {
		tooltip = (
			<FormattedMessage {...messages.invalidUnchanged} />
		);
	} else if (validity.verdict === SegmentDefinitionValidityVerdict.InvalidBecauseConflictingIdentifier) {
		tooltip = (
			<FormattedMessage {...messages.invalidSameIdentifier} />
		);
	} else if (validity.verdict === SegmentDefinitionValidityVerdict.InvalidBecauseConflictingCriteria) {
		tooltip = (
			<SegmentWithDuplicateCriteriaExistsMessage
				segmentWithSameCriteria={validity.segmentWithSameCriteria}
			/>
		);
	}

	return (
		<Button
			disabled={isDisabled}
			onClick={onClickCallback}
			progress={isSubmitting}
			style={ButtonStyle.Action}
			tooltip={tooltip}
			width={ButtonWidth.Fullwidth}
		>
			{isNewSegment ? (
				<FormattedMessage {...messages.create} />
			) : (
				<FormattedMessage {...messages.edit} />
			)}
		</Button>
	);
};



export default SaveButton;
