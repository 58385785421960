import Immutable from 'immutable';
import {
	type Store,
	applyMiddleware,
	legacy_createStore as createStore,
} from 'redux';
import throttle from 'lodash/throttle';

import {
	initializeFromLocalStorage,
	windowHasFocus,
} from '~/actions';

import * as middleware from '~/middleware';

import reducers from '~/reducers';

import {
	isVisible,
	onVisibilityChange,
} from '~/utilities/windowFocus';



export function setupStore(): Store {
	const store = createStore(
		reducers,
		applyMiddleware(middleware.thunk),
	);

	setupLocalStorage(store);
	setupWindowListeners(store);

	return store;
}



function setupWindowListeners(store: Store): void {
	store.dispatch(windowHasFocus(isVisible()));

	onVisibilityChange((hasFocus) => {
		store.dispatch(windowHasFocus(hasFocus));
	});
}



function setupLocalStorage(store: Store): void {
	try {
		const localStorageData = window.localStorage.getItem('ContentKing');

		if (localStorageData !== null) {
			store.dispatch(
				initializeFromLocalStorage(
					Immutable.fromJS(
						JSON.parse(localStorageData),
					),
				),
			);
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn('Failed to retrieve initialize state from localStorage:', error);
	}

	const listener = throttle(() => {
		const state = store.getState();

		try {
			window.localStorage.setItem('ContentKing', JSON.stringify({
				authentication: {
					authenticated: state.getIn(['authentication', 'authenticated']),
					inAuthenticatedSection: state.getIn(['authentication', 'inAuthenticatedSection']),
					userInfo: state.getIn(['authentication', 'userInfo']),
				},
				featureFlags: state.get('featureFlags'),
				localization: {
					currentLocale: state.getIn(['localization', 'currentLocale']),
					locales: state.getIn(['localization', 'locales']),
				},
				lastSearchedWebsite: state.get('lastSearchedWebsite'),
				lastSelectedWebsite: state.get('lastSelectedWebsite'),
			}));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn('Unable to persist state to LocalStorage.', error);
		}
	}, 1000);

	store.subscribe(listener);
}
