/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DomainsBoxQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DomainsBoxQuery = { readonly __typename?: 'Query', readonly platformData: { readonly __typename?: 'PlatformData', readonly id: CK.ID, readonly isHttpsSupported: boolean | null } | null };


export const DomainsBoxDocument = gql`
    query DomainsBox($websiteId: WebsiteId!) {
  platformData(websiteId: $websiteId) {
    id
    isHttpsSupported
  }
}
    `;

/**
 * __useDomainsBoxQuery__
 *
 * To run a query within a React component, call `useDomainsBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsBoxQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDomainsBoxQuery(baseOptions: Apollo.QueryHookOptions<DomainsBoxQuery, DomainsBoxQueryVariables> & ({ variables: DomainsBoxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DomainsBoxQuery, DomainsBoxQueryVariables>(DomainsBoxDocument, options);
      }
export function useDomainsBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DomainsBoxQuery, DomainsBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DomainsBoxQuery, DomainsBoxQueryVariables>(DomainsBoxDocument, options);
        }
export function useDomainsBoxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DomainsBoxQuery, DomainsBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DomainsBoxQuery, DomainsBoxQueryVariables>(DomainsBoxDocument, options);
        }
export type DomainsBoxQueryHookResult = ReturnType<typeof useDomainsBoxQuery>;
export type DomainsBoxLazyQueryHookResult = ReturnType<typeof useDomainsBoxLazyQuery>;
export type DomainsBoxSuspenseQueryHookResult = ReturnType<typeof useDomainsBoxSuspenseQuery>;
export type DomainsBoxQueryResult = Apollo.QueryResult<DomainsBoxQuery, DomainsBoxQueryVariables>;