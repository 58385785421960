/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePaymentMethodInBillingTabMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  paymentMethod: GraphQL.Scalars['Array']['input'];
}>;


export type UpdatePaymentMethodInBillingTabMutation = { readonly __typename?: 'Mutation', readonly StorePaymentMethod: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly paymentMethod: { readonly __typename?: 'AccountPaymentMethod', readonly details: Record<string, any>, readonly type: string } | null } | null } } };


export const UpdatePaymentMethodInBillingTabDocument = gql`
    mutation UpdatePaymentMethodInBillingTab($accountId: AccountId!, $paymentMethod: Array!) {
  StorePaymentMethod(accountId: $accountId, paymentMethod: $paymentMethod) {
    query {
      account(id: $accountId) {
        id
        paymentMethod {
          details
          type
        }
      }
    }
  }
}
    `;
export type UpdatePaymentMethodInBillingTabMutationFn = Apollo.MutationFunction<UpdatePaymentMethodInBillingTabMutation, UpdatePaymentMethodInBillingTabMutationVariables>;

/**
 * __useUpdatePaymentMethodInBillingTabMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodInBillingTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodInBillingTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodInBillingTabMutation, { data, loading, error }] = useUpdatePaymentMethodInBillingTabMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useUpdatePaymentMethodInBillingTabMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMethodInBillingTabMutation, UpdatePaymentMethodInBillingTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentMethodInBillingTabMutation, UpdatePaymentMethodInBillingTabMutationVariables>(UpdatePaymentMethodInBillingTabDocument, options);
      }
export type UpdatePaymentMethodInBillingTabMutationHookResult = ReturnType<typeof useUpdatePaymentMethodInBillingTabMutation>;
export type UpdatePaymentMethodInBillingTabMutationResult = Apollo.MutationResult<UpdatePaymentMethodInBillingTabMutation>;
export type UpdatePaymentMethodInBillingTabMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMethodInBillingTabMutation, UpdatePaymentMethodInBillingTabMutationVariables>;