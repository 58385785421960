import Immutable from 'immutable';

import {
	DEFAULT_FILTER,
} from '~/model/affectedLinks';

import {
	filterSelector,
} from '~/state/affectedLinks/selectors';



export const UPDATE_FILTER = 'AFFECTED_LINKS_UPDATE_FILTER';
export const UPDATE_SORT_BY = 'AFFECTED_LINKS_UPDATE_SORT_BY';



export function updateFilter(filter) {
	filter = Immutable.fromJS(filter).map((value, key) => {
		if (value === undefined) {
			return DEFAULT_FILTER.get(key) || '';
		}

		return value;
	});

	return (dispatch, getState) => {
		const originalFilter = filterSelector(getState());

		if (Immutable.is(originalFilter, filter)) {
			return Promise.resolve();
		}

		dispatch({
			type: UPDATE_FILTER,
			filter,
		});

		return Promise.resolve();
	};
}



export function updateSortBy(sortBy) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SORT_BY,
			sortBy: Immutable.fromJS(sortBy),
		});

		return Promise.resolve();
	};
}
