export const states = {
	CA: [
		'AB',
		'BC',
		'MB',
		'NB',
		'NL',
		'NT',
		'NS',
		'NU',
		'ON',
		'PE',
		'QC',
		'SK',
		'YT',
	],
	US: [
		'AL',
		'AK',
		'AS',
		'AZ',
		'AR',
		'AA',
		'AE',
		'AP',
		'CA',
		'CO',
		'CT',
		'DE',
		'DC',
		'FM',
		'FL',
		'GA',
		'GU',
		'HI',
		'ID',
		'IL',
		'IN',
		'IA',
		'KS',
		'KY',
		'LA',
		'ME',
		'MH',
		'MD',
		'MA',
		'MI',
		'MN',
		'MS',
		'MO',
		'MT',
		'NE',
		'NV',
		'NH',
		'NJ',
		'NM',
		'NY',
		'NC',
		'ND',
		'MP',
		'OH',
		'OK',
		'OR',
		'PA',
		'PR',
		'PW',
		'RI',
		'SC',
		'SD',
		'TN',
		'TX',
		'UT',
		'VA',
		'VI',
		'VT',
		'WA',
		'WI',
		'WV',
		'WY',
	],
};
