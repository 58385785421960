import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import {
	animateHeartbeat,
} from '~/actions';



type Props = {
	children?: React.ReactNode,
	enabled?: boolean,
};

const AnimateHeartbeat: React.FC<Props> = (props) => {
	const {
		children,
		enabled = true,
	} = props;

	const dispatch = useDispatch();

	function handleMouseEnter() {
		dispatch(animateHeartbeat(true));
	}

	function handleMouseLeave() {
		dispatch(animateHeartbeat(false));
	}

	return (
		<div
			onMouseEnter={enabled ? handleMouseEnter : undefined}
			onMouseLeave={enabled ? handleMouseLeave : undefined}
		>
			{children}
		</div>
	);
};



export default AnimateHeartbeat;
