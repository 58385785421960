import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import AlertsLink from '~/components/app/AlertsLink';
import CurrentPagesOverviewLink from '~/components/app/CurrentPagesOverviewLink';
import DashboardLink from '~/components/app/DashboardLink';
import IssuesOverviewLink from '~/components/app/IssuesOverviewLink';
import NavigationIcon, {
	NavigationIconType,
} from '~/components/patterns/icons/NavigationIcon';
import MainNavigationLayout from '~/components/patterns/navigations/MainNavigationLayout';
import MenuItemLayout from '~/components/atoms/navigations/builders/MenuItemLayout';
import PlatformLink from '~/components/app/PlatformLink';
import WebsiteSettingsLink from '~/components/app/WebsiteSettingsLink';

import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	selectedSectionSelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	alerts: {
		id: 'ui.menu.alerts',
	},
	content: {
		id: 'ui.menu.content',
	},
	dashboard: {
		id: 'ui.menu.dashboard',
	},
	issues: {
		id: 'ui.menu.issues',
	},
	platform: {
		id: 'ui.menu.platform',
	},
	settings: {
		id: 'ui.menu.settings',
	},
});



const MainNavigation: React.FC = () => {
	const selectedSection = useSelector(selectedSectionSelector);
	const viewportWidth = useViewportWidth();
	const websiteId = useWebsiteId();

	let activeItemIndex: number | undefined = undefined;

	switch (selectedSection) {

		case 'website.dashboard':
			activeItemIndex = 0;
			break;

		case 'website.pages':
			activeItemIndex = 1;
			break;

		case 'website.platform':
			activeItemIndex = 2;
			break;

		case 'website.issues':
			activeItemIndex = 3;
			break;

		case 'website.alerts':
			activeItemIndex = 4;
			break;

		case 'website.detail':
			activeItemIndex = 5;
			break;

	}

	return (
		<MainNavigationLayout
			activeItemIndex={activeItemIndex}
			primaryItems={[
				<DashboardLink
					key="dashboard-link"
					websiteId={websiteId}
				>
					{({ isActive, isDimmed }) => {
						return (
							<MenuItemLayout
								icon={(
									<NavigationIcon type={NavigationIconType.Dashboard} />
								)}
								isActive={isActive}
								isDimmed={isDimmed}
								label={(
									<FormattedMessage {...messages.dashboard} />
								)}
							/>
						);
					}}
				</DashboardLink>,
				<CurrentPagesOverviewLink key="pages-overview-link">
					{({ isActive, isDimmed }) => {
						return (
							<MenuItemLayout
								icon={(
									<NavigationIcon type={NavigationIconType.Pages} />
								)}
								isActive={isActive}
								isDimmed={isDimmed}
								label={(
									<FormattedMessage {...messages.content} />
								)}
							/>
						);
					}}
				</CurrentPagesOverviewLink>,
				<PlatformLink
					key="platform-link"
					websiteId={websiteId}
				>
					{({ isActive, isDimmed }) => {
						return (
							<MenuItemLayout
								icon={(
									<NavigationIcon type={NavigationIconType.Platform} />
								)}
								isActive={isActive}
								isDimmed={isDimmed}
								label={(
									<FormattedMessage {...messages.platform} />
								)}
							/>
						);
					}}
				</PlatformLink>,
				<IssuesOverviewLink
					key="issues-overview-link"
					websiteId={websiteId}
				>
					{({ isActive, isDimmed }) => {
						return (
							<MenuItemLayout
								icon={(
									<NavigationIcon type={NavigationIconType.Issues} />
								)}
								isActive={isActive}
								isDimmed={isDimmed}
								label={(
									<FormattedMessage {...messages.issues} />
								)}
							/>
						);
					}}
				</IssuesOverviewLink>,
				<AlertsLink
					keepSettings={false}
					key="alerts-link"
					websiteId={websiteId}
				>
					{({ isActive, isDimmed }) => {
						return (
							<MenuItemLayout
								icon={(
									<NavigationIcon type={NavigationIconType.Alerts} />
								)}
								isActive={isActive}
								isDimmed={isDimmed}
								label={(
									<FormattedMessage {...messages.alerts} />
								)}
							/>
						);
					}}
				</AlertsLink>,
			]}
			secondaryItems={viewportWidth > 374 ? [
				(
					<WebsiteSettingsLink
						key="website-settings-link"
						websiteId={websiteId}
					>
						{({ isActive, isDimmed }) => (
							<MenuItemLayout
								icon={(
									<NavigationIcon type={NavigationIconType.Settings} />
								)}
								isActive={isActive}
								isDimmed={isDimmed}
								label={(
									<FormattedMessage {...messages.settings} />
								)}
							/>
						)}
					</WebsiteSettingsLink>
				),
			] : []}
		/>
	);
};



export default MainNavigation;
