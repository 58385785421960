import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	AbstractCheckboxFieldCheckedState,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import Measurer from '~/utilities/Measurer';
import MultiselectListTable from '~/components/atoms/forms/components/MultiselectListTable';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useUpdateSendingOfAlertsAndReportsToUserMutation,
	useWebsitesReportsAlertsFormQuery,
} from './WebsitesReportsAlertsForm.gql';

import useAllWebsites from '~/hooks/useAllWebsites';
import useIsAllowedWithUserWebsiteAccessOracle from '~/hooks/useIsAllowedWithUserWebsiteAccessOracle';
import useUserUniqueId from '~/hooks/useUserUniqueId';

import {
	combinePermissionChecks,
} from '~/model/permissions';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	assignedWebsitesCount: {
		id: 'ui.settings.emailNotifications.website.view',
	},
	labelAlerts: {
		id: 'ui.events.title',
	},
	labelReports: {
		id: 'ui.settings.emailNotifications.reports',
	},
	labelWebsite: {
		id: 'ui.settings.emailNotifications.website',
	},
	title: {
		id: 'ui.settings.emailNotifications.title',
	},
});



type Props = {
	legacyUserId: string,
	userAccountMemberships: ReadonlyArray<{
		account: {
			id: CK.AccountId,
		},
		websites: ReadonlyArray<{
			id: CK.WebsiteId,
		}>,
	}> | null,
};

const WebsitesReportsAlertsForm: React.FC<Props> = (props) => {
	const {
		legacyUserId,
		userAccountMemberships,
	} = props;

	const allWebsites = useAllWebsites();
	const isAllowedWithUserWebsiteAccessOracle = useIsAllowedWithUserWebsiteAccessOracle();
	const userUniqueId = useUserUniqueId(legacyUserId);

	const { data } = useWebsitesReportsAlertsFormQuery({
		variables: {
			legacyUserId,
		},
	});

	const user = data?.user ?? null;

	const [updateSendingOfAlertsAndReportsToUser] = useUpdateSendingOfAlertsAndReportsToUserMutation();

	const handleSubmit = React.useCallback(
		async (data) => {
			if (user === null) {
				return;
			}

			const newEmailSettings = user.websiteEmailSettings.map(({ websiteId }) => {
				let alertsStatus = GraphQL.AlertEmailsSetup.Some;

				if (data.emailSettings.alerts[websiteId] === true) {
					alertsStatus = GraphQL.AlertEmailsSetup.All;
				} else if (data.emailSettings.alerts[websiteId] === false) {
					alertsStatus = GraphQL.AlertEmailsSetup.None;
				}

				return {
					alerts: alertsStatus,
					id: websiteId,
					reports: data.emailSettings.weeklyReports[websiteId],
				};
			});

			await updateSendingOfAlertsAndReportsToUser({
				variables: {
					legacyUserId,
					websites: newEmailSettings,
				},
			});
		},
		[
			legacyUserId,
			updateSendingOfAlertsAndReportsToUser,
			user,
		],
	);

	const calculatedData = React.useMemo(
		() => {
			if (
				allWebsites.isLoaded === false
				|| user === null
				|| userAccountMemberships === null
			) {
				return null;
			}

			const websitePermissionChecks: Array<ReturnType<typeof isAllowedWithUserWebsiteAccessOracle>> = [];

			const websitesList: Array<{
				disabled: boolean,
				label: string,
				name: string,
			}> = [];

			const emailSettings: {
				alerts: Record<CK.WebsiteId, boolean | AbstractCheckboxFieldCheckedState.Indeterminate>,
				weeklyReports: Record<CK.WebsiteId, boolean>,
			} = {
				alerts: {},
				weeklyReports: {},
			};

			let alertsCount = 0;
			let weeklyReportsCount = 0;

			const membershipAccountIds = userAccountMemberships.map(
				(userAccountMembership) => userAccountMembership.account.id,
			);

			allWebsites.listByUserAccounts(membershipAccountIds).forEach((website) => {
				const userHasAccess = userAccountMemberships.some(
					(membership) => membership.websites.some((userWebsite) => userWebsite.id === website.id),
				);

				if (userHasAccess === false) {
					return;
				}

				const permissionCheck = isAllowedWithUserWebsiteAccessOracle({
					action: GraphQL.ActionWithUserWebsiteAccess.ManageEmailPreferences,
					from: 'user',
					uniqueUserId: userUniqueId,
					websiteId: website.id,
				});

				websitePermissionChecks.push(permissionCheck);

				websitesList.push({
					disabled: permissionCheck.no,
					label: website.displayName,
					name: website.id,
				});

				const websiteEmailSettings = user.websiteEmailSettings.find(
					(websiteEmailSettings) => websiteEmailSettings.websiteId === website.id,
				);

				if (websiteEmailSettings === undefined) {
					throw new Error(`User.websiteEmailSettings must be present for website listed in User.websites`);
				}

				emailSettings.weeklyReports[website.id] = websiteEmailSettings.isWeeklyReportEnabled;

				emailSettings.alerts[website.id] = matchAndReturn(websiteEmailSettings.alertEmailsSetup, {
					[GraphQL.AlertEmailsSetup.All]: true,
					[GraphQL.AlertEmailsSetup.None]: false,
					[GraphQL.AlertEmailsSetup.Some]: AbstractCheckboxFieldCheckedState.Indeterminate,
				});

				if (websiteEmailSettings.alertEmailsSetup !== GraphQL.AlertEmailsSetup.None) {
					alertsCount++;
				}

				if (websiteEmailSettings.isWeeklyReportEnabled) {
					weeklyReportsCount++;
				}
			});

			return {
				alertsCount,
				emailSettings,
				websitePermissionChecks,
				websitesList,
				weeklyReportsCount,
			};
		},
		[
			allWebsites,
			isAllowedWithUserWebsiteAccessOracle,
			user,
			userAccountMemberships,
			userUniqueId,
		],
	);

	if (calculatedData === null) {
		return null;
	}

	return (
		<>
			<EditableFormWrapper
				isAllowed={combinePermissionChecks(calculatedData.websitePermissionChecks)}
				key={legacyUserId}
				title={(
					<FormattedMessage {...messages.title} />
				)}
			>
				<DisplayPart>
					<FormRows>
						<FormRow
							label={(
								<FormattedMessage {...messages.labelReports} />
							)}
						>
							<StaticText>
								<FormattedMessage
									{...messages.assignedWebsitesCount}
									values={{
										websitesCount: calculatedData.weeklyReportsCount,
									}}
								/>
							</StaticText>
						</FormRow>

						<FormRow
							label={(
								<FormattedMessage {...messages.labelAlerts} />
							)}
						>
							<StaticText>
								<FormattedMessage
									{...messages.assignedWebsitesCount}
									values={{
										websitesCount: calculatedData.alertsCount,
									}}
								/>
							</StaticText>
						</FormRow>
					</FormRows>
				</DisplayPart>

				<EditablePart>
					<Form
						defaultFocus="emailSettings"
						defaultValues={{
							emailSettings: calculatedData.emailSettings,
						}}
						onSuccess={handleSubmit}
					>
						<FormRows>
							<FormRow
								fullwidth={true}
								htmlFor="emailSettings"
							>
								<Measurer>
									{({ containerWidth }) => (
										<MultiselectListTable
											columns={[
												{
													name: 'weeklyReports',
													label: (
														<FormattedMessage {...messages.labelReports} />
													),
												},
												{
													name: 'alerts',
													label: (
														<FormattedMessage {...messages.labelAlerts} />
													),
												},
											]}
											label={(
												<FormattedMessage {...messages.labelWebsite} />
											)}
											name="emailSettings"
											rows={calculatedData.websitesList}
											tableWidth={containerWidth}
										/>
									)}
								</Measurer>
							</FormRow>
						</FormRows>

						<ButtonsLayout layout={ButtonsLayoutType.FormRowWithoutStatus}>
							<CancelButton />
							<SaveSubmitButton />
						</ButtonsLayout>
					</Form>
				</EditablePart>
			</EditableFormWrapper>
		</>
	);
};



export default WebsitesReportsAlertsForm;
