import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import LastCheckTimestamp from '~/components/patterns/time/LastCheckTimestamp';



const messages = defineMessages({
	hint: {
		id: 'ui.pageUnknownLastCheckedInfo.hint',
	},
	label: {
		id: 'ui.pageUnknownLastCheckedInfo.label',
	},
	paused: {
		id: 'ui.pageUnknownLastCheckedInfo.paused',
	},
});



type Props = {
	isPaused?: boolean,
};

const PageUnknownLastCheckedInfo: React.FC<Props> = (props) => {
	const {
		isPaused,
	} = props;

	return (
		<LastCheckTimestamp
			checkedAtLabel={
				isPaused ? (
					<FormattedMessage {...messages.paused} />
				) : (
					<FormattedMessage {...messages.label} />
				)
			}
			explanationMessage={
				isPaused ? null : <FormattedMessage {...messages.hint} />
			}
			isCrawlingPaused={isPaused}
		/>
	);
};



export default PageUnknownLastCheckedInfo;
