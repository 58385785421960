import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CustomerDetailsFields, {
	validateAddress,
	validateCityAndPostalCode,
	validateCountry,
	validateEmail,
	validateName,
	validateState,
	validateVatNumber,
} from '~/components/app/CustomerDetailsFields';
import CustomerDetailsOverview from '~/components/app/CustomerDetailsOverview';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';

import {
	useUpdateCustomerDetailsInBillingTabMutation,
} from './CustomerDetailsBlock.gql';



const messages = defineMessages({
	formErrorsDisallowedChangeOfBillingEntity: {
		id: 'ui.billingErrors.disallowedChangeOfBillingEntity',
	},
	title: {
		id: 'ui.teamDetail.billing.billingDetailsTitle',
	},
});

const validations = {
	validateAddress,
	validateCityAndPostalCode,
	validateCountry: [
		...validateCountry,
		{
			message: (
				<FormattedMessage {...messages.formErrorsDisallowedChangeOfBillingEntity} />
			),
			field: 'country',
			globalRule: 'disallowedChangeOfBillingEntity',
		},
	],
	validateEmail,
	validateName,
	validateState,
	validateVatNumber,
};



type Props = {
	accountId: CK.AccountId | null,
	customerAddress: GraphQL.BillingDetailsAddress | null,
	emails: Readonly<Array<string>> | null,
	vatNumber: string | null,
};

const CustomerDetailsBlock: React.FC<Props> = (props) => {
	const {
		accountId,
		customerAddress,
		emails,
		vatNumber,
	} = props;

	const [updateCustomerDetails] = useUpdateCustomerDetailsInBillingTabMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (accountId === null) {
				return;
			}

			await updateCustomerDetails({
				variables: {
					accountId,
					customerDetails: {
						address: {
							address: values.address,
							city: values.city,
							country: values.country,
							name: values.name,
							postalCode: values.postalCode,
							state: values.state,
						},
						email: values.email,
						vatNumber: values.vatNumber,
					},
				},
			});
		},
		[
			accountId,
			updateCustomerDetails,
		],
	);

	return (
		<EditableFormWrapper
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<CustomerDetailsOverview
					customerAddress={customerAddress}
					emails={emails}
					vatNumber={vatNumber}
				/>
			</DisplayPart>

			<EditablePart>
				{customerAddress !== null && (
					<Form
						clearOnFieldUnmount={true}
						defaultFocus="email"
						defaultValues={{
							address: customerAddress.address,
							city: customerAddress.city,
							country: customerAddress.country,
							email: emails !== null ? emails.join(', ') : null,
							name: customerAddress.name,
							postalCode: customerAddress.postalCode,
							state: customerAddress.state,
							vatNumber,
						}}
						onSuccess={handleSubmit}
						validations={validations}
					>
						<CustomerDetailsFields />

						<ButtonsLayout>
							<CancelButton />
							<SaveSubmitButton />
						</ButtonsLayout>
					</Form>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default CustomerDetailsBlock;
