import {
	format,
} from 'date-fns';
import Immutable from 'immutable';
import React from 'react';

import CK from '~/types/contentking';

import useEnabledColumnsList from '~/hooks/useEnabledColumnsList';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	createFilterParameter,
} from '~/actions/historicalChanges';

import {
	CHANGE_TYPE_CHANGED,
	DEFAULT_FILTER,
	DEFAULT_SORT_BY,
} from '~/model/historicalChanges';



function useNavigateToTrackedChanges() {
	const currentWebsiteId = useWebsiteId();

	const columns = useEnabledColumnsList();
	const segments = useWebsiteSegmentDefinitions(currentWebsiteId);

	return React.useMemo(
		() => ({
			endDate,
			filter = null,
			scope = 'website',
			startDate,
			websiteId = currentWebsiteId,
		}: {
			endDate: string | Date,
			filter?: any,
			scope?: string,
			startDate: string | Date,
			websiteId?: string,
		}) => {
			let totalFilter = DEFAULT_FILTER.merge(
				Immutable.fromJS(filter ? filter : {}),
			);

			if (!totalFilter.get(CK.PagesCommonColumn.ChangeType).includes(CHANGE_TYPE_CHANGED)) {
				totalFilter = totalFilter.set('changes_in', null);
			}

			if (scope !== 'website') {
				totalFilter = totalFilter.setIn(
					[CK.PagesCommonColumn.Segments, 'included_in'],
					totalFilter.getIn([CK.PagesCommonColumn.Segments, 'included_in']).push(scope.substring('segment/'.length)),
				);
			}

			return {
				routeName: 'website.pages',
				routeParams: {
					end_date: endDate instanceof Date
						? format(endDate, 'yyyy-MM-dd')
						: endDate,
					filter: createFilterParameter(
						totalFilter,
						DEFAULT_SORT_BY,
						columns,
						segments.listAll(),
					) || undefined,
					start_date: startDate instanceof Date
						? format(startDate, 'yyyy-MM-dd')
						: startDate,
					websiteId,
				},
			};
		},
		[
			currentWebsiteId,
			columns,
			segments,
		],
	);
}



export default useNavigateToTrackedChanges;
