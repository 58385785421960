import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import Copy, {
	linkExternal,
	linkInternal,
} from '~/components/logic/Copy';
import MarginsList from '~/components/atoms/lists/MarginsList';
import RichText from '~/components/patterns/typography/RichText';
import SidebarFreeTextInput from './SidebarFreeTextInput';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';



const messages = defineMessages({
	description: {
		id: 'ui.members.new.bulk.sidebar.description',
	},
	tabFreeTextInput: {
		id: 'ui.members.new.bulk.sidebar.tab.freeTextInput',
	},
	hint: {
		id: 'ui.members.new.bulk.sidebar.hint',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	addEmailsToStagingArea: (emails: Array<string>) => void,
	isDisabled?: boolean,
	isMaxInvitationsLimitReached: boolean,
	maxInvitations: number,
	sessionId: string | undefined,
};

const Sidebar: React.FC<Props> = (props) => {
	const {
		accountId,
		addEmailsToStagingArea,
		isMaxInvitationsLimitReached,
		maxInvitations,
		sessionId,
	} = props;

	return (
		<MarginsList>
			<RichText>
				<Copy {...messages.description} />
			</RichText>
			<BorderedBox
				header={(
					<TabNavigation stretchItems={false}>
						<TabNavigationItem
							isActive={true}
						>
							<FormattedMessage {...messages.tabFreeTextInput} />
						</TabNavigationItem>
					</TabNavigation>
				)}
				headerPaddingSize={0}
				reversedColors={true}
			>
				<SidebarFreeTextInput
					accountId={accountId}
					addEmailsToStagingArea={addEmailsToStagingArea}
					isMaxInvitationsLimitReached={isMaxInvitationsLimitReached}
					maxInvitations={maxInvitations}
					sessionId={sessionId}
				/>
			</BorderedBox>
			<RichText>
				<Copy
					{...messages.hint}
					values={{
						linkInviteSingleMember: linkInternal('account.members.new'),
						linkSupport: linkExternal('https://www.contentkingapp.com/support/account-users/'),
					}}
				/>
			</RichText>
		</MarginsList>
	);
};



export default Sidebar;
