import React from 'react';

import {
	createIssuesSortingOrder,
	sortIssuesBySortingOrder,
} from '~/model/issuesNew';



const emptyResult = [];



function useSortedIssues<TItem extends { name: string }>(
	items: ReadonlyArray<TItem> | null,
	sortingAlgorithm: (issues: ReadonlyArray<TItem>) => ReadonlyArray<TItem>,
): ReadonlyArray<TItem> {
	const sortingOrderRef = React.useRef<any>(null);

	return React.useMemo(
		() => {
			function getSortedIssues(): ReadonlyArray<TItem> {
				if (items === null) {
					return emptyResult;
				}

				if (sortingOrderRef.current === null) {
					sortingOrderRef.current = createIssuesSortingOrder(items, sortingAlgorithm);
				}

				const result = sortIssuesBySortingOrder(items, sortingOrderRef.current);

				if (result) {
					return result;
				}

				sortingOrderRef.current = null;

				return getSortedIssues();
			}

			return getSortedIssues();
		},
		[
			items,
			sortingAlgorithm,
		],
	);
}



export default useSortedIssues;
