import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import Form from '~/components/atoms/forms/basis/Form';
import TextField, {
	TextFieldAutocomplete,
	type TextFieldRef,
	TextFieldSize,
	TextFieldStyle,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import usePrevious from '~/hooks/usePrevious';



const messages = defineMessages({
	placeholder: {
		id: 'ui.general.search.placeholder',
	},
});



type Props = {
	autoFocus?: boolean,
	defaultValue?: string,
	onChangeCallback?: (field: string, value: string) => void,
};

const WebsitesSearchForm: React.FC<Props> = (props) => {
	const {
		autoFocus = false,
		defaultValue = '',
		onChangeCallback,
	} = props;

	const intl = useIntl();
	const searchFieldRef = React.useRef<TextFieldRef>(null);

	const previousAutoFocus = usePrevious(autoFocus);

	React.useEffect(
		() => {
			if (!autoFocus || previousAutoFocus === autoFocus) {
				return;
			}

			setTimeout(
				() => {
					const field: HTMLInputElement | null = (
						searchFieldRef.current?.getInputElement() ?? null
					);

					if (field !== null) {
						field.setSelectionRange(0, field.value.length);
						field.focus();
					}
				},
			);
		},
		[
			previousAutoFocus,
			autoFocus,
		],
	);

	return (
		<Form
			defaultFocus={autoFocus ? 'search' : ''}
			defaultValues={{
				search: defaultValue,
			}}
			onChangeCallback={onChangeCallback}
		>
			<TextField
				autoComplete={TextFieldAutocomplete.Off}
				name="search"
				placeholder={intl.formatMessage(messages.placeholder)}
				ref={searchFieldRef}
				resetButton={true}
				size={TextFieldSize.XSmall}
				style={TextFieldStyle.Eminence}
				type={TextFieldType.Search}
				width="100%"
			/>
		</Form>
	);
};



export default React.memo(WebsitesSearchForm);
