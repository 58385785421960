import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import NotEnoughData from '~/components/app/NotEnoughData';
import ValueWithUnit from '~/components/patterns/values/ValueWithUnit';



const messages = defineMessages({
	almostEqualTo: {
		id: 'ui.almostEqualTo',
	},
});


type Props = {
	value?: number | null,
	unit: React.ReactNode,
};

const SearchEngineVisitFrequencyFormatter: React.FC<Props> = (props) => {
	const {
		value,
		unit,
	} = props;

	if (value === null || value === undefined) {
		return (
			<NotEnoughData />
		);
	}

	if (value === 0) {
		return (
			<ValueWithUnit unit={unit}>
				<FormattedMessage {...messages.almostEqualTo} />
				<FormattedNumber
					key="number"
					maximumFractionDigits={0}
					minimumFractionDigits={0}
					value={value}
				/>
			</ValueWithUnit>
		);
	}

	return (
		<ValueWithUnit unit={unit}>
			<FormattedNumber
				key="number"
				maximumFractionDigits={value < .95 ? 1 : 0}
				minimumFractionDigits={value < .95 ? 1 : 0}
				value={value}
			/>
		</ValueWithUnit>
	);
};



export default SearchEngineVisitFrequencyFormatter;
