import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AuthButtonsLayout from '~/components/atoms/auth/AuthButtonsLayout';
import AuthFormLayout, {
	type AuthFormLayoutRef,
} from '~/components/atoms/auth/AuthFormLayout';
import AuthFormRow from '~/components/atoms/auth/AuthFormRow';
import AuthTitle from '~/components/atoms/auth/AuthTitle';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import FieldStatus, {
	FieldStatusErrorMessageSize,
} from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages, {
	FormErrorMessagesStyle,
} from '~/components/app/FormErrorMessages';
import SubmitButton, {
	SubmitButtonSize,
} from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldSize,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validatePassword,
} from '~/components/app/validatePassword';

import {
	validateField,
} from '~/components/app/validations';

import {
	useResetPasswordMutation,
} from '~/components/app/ResetPasswordForm.gql';

import useResetPasswordSession from '~/hooks/usePasswordResetSession';

import {
	LoginScreenView,
} from '~/model/loginScreen';

import {
	navigate,
} from '~/routing/router';

import FormError from '~/utilities/FormError';
import touchSupported from '~/utilities/touchSupported';



const messages = defineMessages({
	backToLogin: {
		id: 'ui.resetPasswordForm.backToLogin',
	},
	expiredTokenError: {
		id: 'ui.resetPasswordForm.errors.expiredToken',
	},
	invalidTokenError: {
		id: 'ui.resetPasswordForm.errors.invalidToken',
	},
	password: {
		id: 'ui.resetPasswordForm.password',
	},
	passwordAlreadyUsedInPast: {
		id: 'ui.settings.form.passwordAlreadyUsedInPast',
	},
	submit: {
		id: 'ui.resetPasswordForm.submit',
	},
	title: {
		id: 'ui.resetPasswordForm.title',
	},
});

const validations = {
	validatePassword: validateField(
		'password',
		(f) => [
			validatePassword(f),
			f.customGlobal({
				field: 'password',
				message: (
					<FormattedMessage {...messages.passwordAlreadyUsedInPast} />
				),
				globalRule: 'passwordAlreadyUsedInPast',
			}),
		],
	),
};



type Props = {
	isDisabled?: boolean,
	setView: (view: LoginScreenView) => void,
};

const ResetPasswordForm: React.FC<Props> = (props) => {
	const {
		isDisabled,
		setView,
	} = props;

	const layoutRef = React.useRef<AuthFormLayoutRef | null>(null);

	const [resetPasswordMutation] = useResetPasswordMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				const { data } = await resetPasswordMutation({
					variables: {
						password: values.password,
					},
				});

				const email = data?.ResetPassword.email ?? '';

				navigate(
					'passwordReset.finished',
					{ email },
					{ replace: true },
				);

				setView(LoginScreenView.PasswordResetFinished);
			} catch (error) {
				layoutRef.current?.shake();

				throw FormError.fromApolloError(error, {
					passwordAlreadyUsedInPast: values.password,
				});
			}
		},
		[
			resetPasswordMutation,
			setView,
		],
	);

	const resetPasswordSession = useResetPasswordSession();
	React.useEffect(
		() => {
			if (
				resetPasswordSession?.isTwoFactorAuthenticationEnabled === true
				&& resetPasswordSession.isTwoFactorAuthenticationVerified === false
			) {
				setView(LoginScreenView.PasswordResetTwoFactorAuthentication);
			}
		},
		[
			resetPasswordSession,
			setView,
		],
	);

	return (
		<AuthFormLayout
			header={(
				<AuthTitle>
					<FormattedMessage {...messages.title} />
				</AuthTitle>
			)}
			ref={layoutRef}
		>
			<Form
				defaultFocus={touchSupported ? null : 'password'}
				defaultValues={{
					password: '',
				}}
				isDisabled={isDisabled}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormErrorMessages
					errors={{
						expiredToken: <FormattedMessage {...messages.expiredTokenError} />,
						invalidToken: <FormattedMessage {...messages.invalidTokenError} />,
					}}
					style={FormErrorMessagesStyle.AuthForm}
				/>

				<AuthFormRow
					id="password"
					label={(
						<FormattedMessage {...messages.password} />
					)}
				>
					<FieldStatus
						errorMessageSize={FieldStatusErrorMessageSize.Medium}
						name="validatePassword"
						showIcon={false}
					>
						<TextField
							autoComplete={TextFieldAutocomplete.NewPassword}
							name="password"
							resetButton={false}
							size={TextFieldSize.Large}
							type={TextFieldType.Password}
							width="100%"
						/>
					</FieldStatus>
				</AuthFormRow>

				<AuthButtonsLayout
					leftButton={(
						<SubmitButton
							size={SubmitButtonSize.XLarge}
						>
							<FormattedMessage {...messages.submit} />
						</SubmitButton>
					)}
					rightButton={(
						<Button
							onClick={() => setView(LoginScreenView.Login)}
							size={ButtonSize.Medium}
							style={ButtonStyle.Link}
						>
							<FormattedMessage {...messages.backToLogin} />
						</Button>
					)}
				/>
			</Form>
		</AuthFormLayout>
	);
};



export default ResetPasswordForm;
