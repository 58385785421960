import classNames from 'classnames';
import noop from 'lodash/noop';
import React, {
	Component,
} from 'react';
import {
	DragDropContext,
	Draggable,
	Droppable,
} from 'react-beautiful-dnd';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



let stripedTableComponentCounter = 0;



export type StripedTableRendererInputBodyCell = {
	columnIndex: number,
	dragHandleProps?: any,
	rowKey: any,
	rowIndex: number,
};

export type StripedTableRendererInputHeaderCell = {
	columnIndex: number,
};

type DropResult = any;

type Props = {
	bodyCellRenderer: RenderProp<StripedTableRendererInputBodyCell>,
	columnCount: number,
	columnWidth?: number | ((input: {
		columnIndex: number,
	}) => void),
	/** Optional table footer */
	footer?: React.ReactNode,
	headerCellRenderer: RenderProp<StripedTableRendererInputHeaderCell>,
	/** Enable draggable rows of table */
	hasDraggableRows?: boolean,
	/** Has draggable rows specified own drag handler or is whole row draggable */
	hasDragHandler?: boolean,
	/** Index of column which will be visually highlighted */
	highlightedColumnIndex?: number,
	/** Callback triggered on drag end */
	onDragEndCallback?: (result: DropResult) => void,
	/** Callback triggered on row mouse over */
	onRowMouseEnterCallback?: (number) => void,
	onRowMouseLeaveCallback?: (number) => void,
	rowKeys: Array<any>,
};



class StripedTable extends Component<Props> {

	static defaultProps = {
		hasDraggableRows: false,
		hasDragHandler: false,
	};

	_componentId: string;



	constructor(props, context) {
		super(props, context);

		this._componentId = 'stripedTable-' + stripedTableComponentCounter++;
	}



	_rowMouseEnterHandler(rowIndex) {
		const {
			onRowMouseEnterCallback,
		} = this.props;

		if (onRowMouseEnterCallback) {
			onRowMouseEnterCallback(rowIndex);
		}
	}



	_rowMouseLeaveHandler(rowIndex) {
		const {
			onRowMouseLeaveCallback,
		} = this.props;

		if (onRowMouseLeaveCallback) {
			onRowMouseLeaveCallback(rowIndex);
		}
	}



	_wrapPropertyGetter(value) {
		return value instanceof Function
			? value
			: () => value;
	}



	_renderHeader() {
		const {
			columnCount,
			columnWidth,
			headerCellRenderer,
			highlightedColumnIndex,
		} = this.props;

		const columnWidthGetter = columnWidth && this._wrapPropertyGetter(columnWidth);
		const headerCells: Array<React.ReactElement> = [];

		for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
			const cellClasses = classNames({
				'striped-table__cell': true,
				'striped-table__cell--header': true,
				'striped-table__cell--is-highlighted': highlightedColumnIndex === columnIndex,
			});

			headerCells.push(
				<th
					className={cellClasses}
					key={'header-cell-' + columnIndex}
					style={{
						width: columnWidthGetter && columnWidthGetter({ columnIndex }),
					}}
				>
					{renderProp(headerCellRenderer, {
						columnIndex,
					})}
				</th>,
			);
		}

		return (
			<tr className="striped-table__row striped-table__row--header">
				{headerCells}
			</tr>
		);
	}



	_getRowStyle(isDragging, draggableStyle) {
		return {
			userSelect: 'none',

			// drop shadow around row when dragging
			boxShadow: isDragging ? '1px 1px 3px 0 rgba(0, 0, 0, 0.15)' : false,

			// styles we need to apply on draggables
			...draggableStyle,
		};
	}



	_renderBody() {
		const {
			hasDraggableRows,
			hasDragHandler,
			rowKeys,
		} = this.props;

		const bodyRows: Array<React.ReactElement> = [];

		rowKeys.forEach((rowKey, rowIndex) => {
			const rowClasses = classNames({
				'striped-table__row': true,
				'striped-table__row--body': true,
				'striped-table__row--even': (rowIndex + 1) % 2 === 0,
				'striped-table__row--odd': (rowIndex + 1) % 2 !== 0,
			});

			const rowId = 'body-row-' + this._componentId + '-' + rowKey;

			bodyRows.push(
				<Draggable
					draggableId={rowId}
					index={rowIndex}
					isDragDisabled={!hasDraggableRows}
					key={rowId}
				>
					{(provided, snapshot) => {
						const additionalProps = (
							!hasDragHandler
								? provided.dragHandleProps
								: {}
						);

						return (
							<tr
								className={rowClasses}
								onMouseEnter={this._rowMouseEnterHandler.bind(this, rowIndex)}
								onMouseLeave={this._rowMouseLeaveHandler.bind(this, rowIndex)}
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...additionalProps}
								style={this._getRowStyle(
									snapshot.isDragging,
									provided.draggableProps.style,
								)}
							>
								{this._renderBodyCells(rowKey, rowIndex, provided.dragHandleProps)}
							</tr>
						);
					}}
				</Draggable>,
			);
		});

		return bodyRows;
	}



	_renderBodyCells(rowKey, rowIndex, dragHandleProps) {
		const {
			bodyCellRenderer,
			columnCount,
			columnWidth,
			hasDragHandler,
			highlightedColumnIndex,
		} = this.props;

		const bodyCells: Array<React.ReactElement> = [];
		const columnWidthGetter = columnWidth && this._wrapPropertyGetter(columnWidth);

		for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
			const cellClasses = classNames({
				'striped-table__cell': true,
				'striped-table__cell--body': true,
				'striped-table__cell--is-highlighted': highlightedColumnIndex === columnIndex,
			});

			bodyCells.push(
				<td
					className={cellClasses}
					key={'body-cell-' + columnIndex}
					width={columnWidthGetter && columnWidthGetter({ columnIndex })}
				>
					<div
						className="striped-table__cell-content"
						style={{
							width: columnWidthGetter && columnWidthGetter({ columnIndex }),
						}}
					>
						{renderProp(bodyCellRenderer, hasDragHandler ? {
							columnIndex,
							dragHandleProps,
							rowKey,
							rowIndex,
						} : {
							columnIndex,
							rowKey,
							rowIndex,
						})}
					</div>
				</td>,
			);
		}

		return bodyCells;
	}



	_renderTable() {
		const {
			onDragEndCallback,
		} = this.props;

		return (
			<DragDropContext onDragEnd={onDragEndCallback ?? noop}>
				<table className="striped-table__table">
					<thead className="striped-table__thead">
						{this._renderHeader()}
					</thead>
					<Droppable
						droppableId={'droppable-' + this._componentId}
					>
						{(provided) => (
							<tbody
								className="striped-table__body"
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								{this._renderBody()}
								{provided.placeholder}
							</tbody>
						)}
					</Droppable>
				</table>
			</DragDropContext>
		);
	}



	render() {
		const {
			footer,
		} = this.props;

		return (
			<div className="striped-table">
				<div className="striped-table__table-wrapper">
					{this._renderTable()}
				</div>
				{footer && (
					<div className="striped-table__footer">
						{footer}
					</div>
				)}
			</div>
		);
	}

}



export default StripedTable;

export {
	DropResult as StripedTableDropResult,
};
