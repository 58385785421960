import React from 'react';

import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';
import SwitchFieldBuilder, {
	SwitchFieldStyle,
} from '~/components/patterns/forms/fields/SwitchField';



type Props = {
	activeStateLabel: React.ReactNode,
	inactiveStateLabel: React.ReactNode,
	isDisabled?: boolean,
	name: string,
	style?: SwitchFieldStyle,
};

const SwitchField: React.FC<Props> = (props) => {
	const {
		activeStateLabel,
		inactiveStateLabel,
		isDisabled,
		name,
		style,
	} = props;

	const {
		defaultValues,
		onBlurHandler,
		onFocusHandler,
		onChangeHandler,
		onMountHandler,
		onUnmountHandler,
		values,
	} = React.useContext(FormContext);

	const [checked, setChecked] = React.useState<boolean>(values[name] || defaultValues[name]);

	React.useEffect(
		() => {
			onMountHandler(
				name,
				{
					setValues: true,
				},
			);

			return () => {
				onUnmountHandler(name);
			};
		},
		[
			name,
			onMountHandler,
			onUnmountHandler,
		],
	);

	React.useEffect(
		() => {
			setChecked(values[name] ?? defaultValues[name]);
		},
		[
			defaultValues,
			values,
			name,
		],
	);

	const handleChange = (fieldName, isChecked) => {
		setChecked(isChecked);
		onChangeHandler(fieldName, isChecked);
	};

	return (
		<SwitchFieldBuilder
			isChecked={checked}
			isDisabled={isDisabled}
			label={(isChecked) => isChecked ? activeStateLabel : inactiveStateLabel}
			name={name}
			onBlurCallback={onBlurHandler}
			onChangeCallback={handleChange}
			onFocusCallback={onFocusHandler}
			style={style}
		/>
	);
};



export default SwitchField;

export {
	SwitchFieldStyle,
};
