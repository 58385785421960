type ApolloBatchContext = {
	batchName: string,
	batchMax: number | undefined,
};



function useBatchContext(name: string, batchMax?: number): ApolloBatchContext {
	return {
		batchName: name,
		batchMax,
	};
}



export default useBatchContext;
