import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ExclusionListIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="exclusion-list"
					transform="translate(3.000000, 3.000000)"
				>
					<polygon
						fill="#FFFFFF"
						fillOpacity="0"
						id="Fill-1"
						points="18 18 0 18 0 0 18 0"
					>
					</polygon>
					<g
						id="Group-2"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						transform="translate(1.000000, 1.000000)"
					>
						<path
							d="M7,1.5 L16,1.5"
							id="Stroke-6"
						>
						</path>
						<g id="Group-5">
							<path
								d="M0,0 L2.75,2.74685488"
								id="Stroke-6-Copy-6"
							>
							</path>
							<path
								d="M2.75,0 L0,2.74685488"
								id="Stroke-6-Copy-7"
							>
							</path>
						</g>
					</g>
					<g
						id="Group"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						transform="translate(1.000000, 7.500000)"
					>
						<path
							d="M7,1.5 L16,1.5"
							id="Stroke-6-Copy"
						>
						</path>
						<g id="Group-5-Copy">
							<path
								d="M0,0 L2.75,2.74685488"
								id="Stroke-6-Copy-6"
							>
							</path>
							<path
								d="M2.75,0 L0,2.74685488"
								id="Stroke-6-Copy-7"
							>
							</path>
						</g>
					</g>
					<g
						id="Group-3"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						transform="translate(1.000000, 14.000000)"
					>
						<path
							d="M7,1.5 L16,1.5"
							id="Stroke-6-Copy-2"
						>
						</path>
						<g id="Group-5-Copy-2">
							<path
								d="M0,0 L2.75,2.74685488"
								id="Stroke-6-Copy-6"
							>
							</path>
							<path
								d="M2.75,0 L0,2.74685488"
								id="Stroke-6-Copy-7"
							>
							</path>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ExclusionListIconBuilder;

