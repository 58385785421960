import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
	ButtonType,
} from '~/components/patterns/buttons/Button';

import useFormContext from '~/hooks/useFormContext';



const messages = defineMessages({
	changeButton: {
		id: 'ui.coBranding.customLogo.changeButton',
	},
	uploadButton: {
		id: 'ui.coBranding.customLogo.uploadButton',
	},
});



type Props = {
	accept?: string,
	name: string,
};

const FileUploadField: React.FC<Props> = (props) => {
	const {
		accept,
		name,
	} = props;

	const formContext = useFormContext();

	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const [fileName, setFileName] = React.useState<string | false>(
		formContext.defaultValues[props.name] ?? false,
	);

	const fieldRef = React.useRef<HTMLInputElement>(null);

	const handleRemoveFile = React.useCallback(
		(e) => {
			e.stopPropagation();
			e.preventDefault();

			setFileName(false);

			if (fieldRef.current) {
				fieldRef.current.value = '';
				fieldRef.current.type = 'text';
				fieldRef.current.type = 'file';
			}

			formContextOnChangeHandler(name, []);
		},
		[
			formContextOnChangeHandler,
			name,
		],
	);

	const handleUploadButtonClick = React.useCallback(
		() => {
			fieldRef.current?.focus();
			fieldRef.current?.click();
		},
		[],
	);

	React.useEffect(
		() => {
			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnUnmountHandler,
			name,
		],
	);

	function renderValueInfo() {
		if (!fileName) {
			return false;
		}

		const output: Array<React.ReactNode> = [];

		output.push(
			<div
				className="file-upload-field__filename"
				key="filename"
			>
				{fileName}
			</div>,
		);

		output.push(
			<Button
				className="file-upload-field__remove-button"
				icon={(
					<BasicIcon type={BasicIconType.Delete} />
				)}
				key="delete-btn"
				muted={true}
				onClick={handleRemoveFile}
				size={ButtonSize.Small}
				style={ButtonStyle.Hollow}
			/>,
		);

		return output;
	}

	return (
		<div className="file-upload-field">
			{renderValueInfo()}
			<input
				accept={accept}
				className="file-upload-field__input"
				data-lpignore="true"
				id={name}
				name={name}
				onBlur={() => {
					formContext.onBlurHandler(name);
				}}
				onChange={(event: any) => {
					if (event.target.files.length < 1) {
						return false;
					}

					const file: Record<string, any> = {
						file_type: event.target.files[0].type,
						file_size: event.target.files[0].size,
					};
					const value = event.target.value;

					const fileReader = new FileReader();
					fileReader.readAsDataURL(event.target.files[0]);
					fileReader.onload = (frEvent: any) => {
						file.file_content = frEvent.target.result;

						setFileName(
							value && value.length > 0 ? value.split('\\').pop() : false,
						);

						formContext.onChangeHandler(name, file);
					};
				}}
				onFocus={() => {
					formContext.onFocusHandler(name);
				}}
				ref={fieldRef}
				type="file"
			/>
			<label
				className="file-upload-field__upload-button"
				htmlFor={name}
			>
				<Button
					onClick={handleUploadButtonClick}
					size={ButtonSize.Small}
					style={ButtonStyle.Hollow}
					type={ButtonType.Button}
					uppercase={true}
				>
					{fileName ? (
						<FormattedMessage {...messages.changeButton} />
					) : (
						<FormattedMessage {...messages.uploadButton} />
					)}
				</Button>
			</label>
		</div>
	);
};



export default FileUploadField;
