import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ImagesIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Group"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="image"
					transform="translate(3.000000, 3.000000)"
				>
					<polygon
						id="Stroke-739"
						points="44.6892187 44.6875 10.3142187 44.6875 10.3142187 10.3125 44.6892187 10.3125"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polygon>
					<polyline
						id="Stroke-740"
						points="44.6876719 37.8064844 36.1025156 29.2213281 29.0986094 36.0378906 20.6251719 24.0616406 10.3143906 37.7085156"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polyline>
					<path
						d="M39.5322813,18.90625 C39.5322813,20.8054688 37.994,22.34375 36.0947813,22.34375 C34.1955625,22.34375 32.6572813,20.8054688 32.6572813,18.90625 C32.6572813,17.0070312 34.1955625,15.46875 36.0947813,15.46875 C37.994,15.46875 39.5322813,17.0070312 39.5322813,18.90625 L39.5322813,18.90625 Z"
						id="Stroke-741"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default ImagesIconBuilder;

