import React from 'react';

import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';



function useFormContext() {
	return React.useContext(FormContext);
}



export default useFormContext;
