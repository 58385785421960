import classNames from 'classnames';
import React from 'react';



type Props = {
	borders?: boolean,
	/** Button placed in the footer */
	footerButton?: React.ReactNode,
	/** Call-to-action elements placed in the footer */
	footerCTA?: React.ReactNode,
	/** Label placed in the footer */
	footerLabel?: React.ReactNode,
	children: React.ReactNode,
	maxWidth?: React.CSSProperties['maxWidth'],
};



const DatatableLayout: React.FC<Props> = (props) => {
	const {
		borders = true,
		footerButton,
		footerCTA,
		footerLabel,
		children,
		maxWidth,
	} = props;

	const _renderFooter = () => {
		if (!footerCTA && !footerLabel && !footerButton) {
			return false;
		}

		return (
			<div
				className="datatable-layout__footer"
				style={{ maxWidth }}
			>
				{footerLabel && (
					<div className="datatable-layout__footer-text">
						<span>
							{footerLabel}
						</span>
					</div>
				)}
				{footerCTA && (
					<div className="datatable-layout__footer-cta">
						{footerCTA}
					</div>
				)}
				{footerButton && (
					<div className="datatable-layout__footer-button">
						{footerButton}
					</div>
				)}
			</div>
		);
	};

	const componentClasses = classNames({
		'datatable-layout': true,
		'datatable-layout--has-borders': borders,
		'datatable-layout--with-footer': footerCTA || footerLabel || footerButton,
	});

	return (
		<div className={componentClasses}>
			<div className="datatable-layout__table">
				{children}
			</div>
			{_renderFooter()}
		</div>
	);
};



export default DatatableLayout;
