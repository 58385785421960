/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsAnyOnboardingTourInProgressQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type IsAnyOnboardingTourInProgressQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly isOnboardingTourInProgress: boolean | null, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }> } | null };


export const IsAnyOnboardingTourInProgressDocument = gql`
    query IsAnyOnboardingTourInProgress {
  authenticatedSession {
    memberships {
      account {
        id
      }
      isOnboardingTourInProgress
      user {
        id
        legacyId
      }
    }
  }
}
    `;

/**
 * __useIsAnyOnboardingTourInProgressQuery__
 *
 * To run a query within a React component, call `useIsAnyOnboardingTourInProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAnyOnboardingTourInProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAnyOnboardingTourInProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsAnyOnboardingTourInProgressQuery(baseOptions?: Apollo.QueryHookOptions<IsAnyOnboardingTourInProgressQuery, IsAnyOnboardingTourInProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsAnyOnboardingTourInProgressQuery, IsAnyOnboardingTourInProgressQueryVariables>(IsAnyOnboardingTourInProgressDocument, options);
      }
export function useIsAnyOnboardingTourInProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsAnyOnboardingTourInProgressQuery, IsAnyOnboardingTourInProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsAnyOnboardingTourInProgressQuery, IsAnyOnboardingTourInProgressQueryVariables>(IsAnyOnboardingTourInProgressDocument, options);
        }
export function useIsAnyOnboardingTourInProgressSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsAnyOnboardingTourInProgressQuery, IsAnyOnboardingTourInProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsAnyOnboardingTourInProgressQuery, IsAnyOnboardingTourInProgressQueryVariables>(IsAnyOnboardingTourInProgressDocument, options);
        }
export type IsAnyOnboardingTourInProgressQueryHookResult = ReturnType<typeof useIsAnyOnboardingTourInProgressQuery>;
export type IsAnyOnboardingTourInProgressLazyQueryHookResult = ReturnType<typeof useIsAnyOnboardingTourInProgressLazyQuery>;
export type IsAnyOnboardingTourInProgressSuspenseQueryHookResult = ReturnType<typeof useIsAnyOnboardingTourInProgressSuspenseQuery>;
export type IsAnyOnboardingTourInProgressQueryResult = Apollo.QueryResult<IsAnyOnboardingTourInProgressQuery, IsAnyOnboardingTourInProgressQueryVariables>;