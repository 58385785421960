import React from 'react';

import Announcement, {
	AnnouncementStyle,
} from '~/components/patterns/messages/embedded/Announcement';



type Props = {
	/** Optional Call-to-Action element */
	ctaElement?: React.ReactNode,
	children: React.ReactNode,
	/** Announcement title */
	title: React.ReactNode,
};



const PaymentAnnouncement: React.FC<Props> = (props) => {
	const {
		ctaElement,
		children,
		title,
	} = props;

	return (
		<section className="payment-announcement dark-bg-context">
			<Announcement
				ctaElement={ctaElement}
				style={AnnouncementStyle.Light}
				title={title}
			>
				{children}
			</Announcement>
		</section>
	);
};



export default PaymentAnnouncement;
