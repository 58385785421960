import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DeleteIconBuilder: IconBuilder = {
	icon: ({ color = '#FF5959' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Icons"
				stroke="none"
				strokeWidth="1"
			>
				<g id="btn-delete">
					<g id="delete">
						<rect
							height="24"
							id="bg"
							width="24"
							x="0"
							y="0"
						>
						</rect>
						<path
							d="M17.0311982,8.94597054 L17.0311982,18.8700777 C17.0311982,19.7136268 16.3839215,20.3968634 15.5847697,20.3968634 L8.35262681,20.3968634 C7.55347503,20.3968634 6.90619824,19.7136268 6.90619824,18.8700777 L6.90619824,8.94597054"
							id="Stroke-533"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M9.39510003,12.0091607 L9.39510003,16.5879911"
							id="Stroke-534"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M11.9665286,12.0091607 L11.9665286,16.5879911"
							id="Stroke-535"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M14.5379572,12.0091607 L14.5379572,16.5879911"
							id="Stroke-536"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<path
							d="M18.4789286,8.94597054 L5.46107146,8.94597054 L5.46107146,7.74515357 C5.46107146,6.72220714 6.24720539,5.89239911 7.21631253,5.89239911 L16.7236875,5.89239911 C17.6927947,5.89239911 18.4789286,6.72220714 18.4789286,7.74515357 L18.4789286,8.94597054 L18.4789286,8.94597054 Z"
							id="Stroke-537"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
						<polygon
							id="Stroke-538"
							points="14.138775 5.89331518 9.79948931 5.89331518 10.5234268 3.60313661 13.4148375 3.60313661"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</polygon>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default DeleteIconBuilder;

