import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import ChangeStatusLabel, {
	ChangeStatusLabelChangeType,
} from '~/components/patterns/statuses/ChangeStatusLabel';
import ColumnName from '~/components/names/ColumnName';
import Hint, {
	HintAttachment,
	type HintRef,
} from '~/components/patterns/hints/hint/Hint';
import HintPopupLayout from '~/components/patterns/hints/hint/HintPopupLayout';
import HintPopupNavigation from '~/components/patterns/hints/hint/HintPopupNavigation';
import HintPopupNavigationItem from '~/components/patterns/hints/hint/HintPopupNavigationItem';

import useEnabledColumnsList from '~/hooks/useEnabledColumnsList';
import useFilterKnownWebsiteColumns from '~/hooks/useFilterKnownWebsiteColumns';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	peekColumn,
} from '~/actions/historicalChanges';

import {
	peekedColumnsSelector,
} from '~/state/changeTrackingPeekedColumns/selectors';



const messages = defineMessages({
	changeTypeChanged: {
		id: 'ui.contentOverview.changeTracking.changeType.value.changed',
	},
});



const ChangedChangeStatusLabel = (props) => {
	const {
		allColumns,
		changes,
		scrollToColumn,
	} = props;

	const websiteId = useWebsiteId();

	const dispatch = useDispatch();
	const enabledColumns = useEnabledColumnsList();
	const filterKnownWebsiteColumns = useFilterKnownWebsiteColumns(websiteId);
	const peekedColumns = useSelector(peekedColumnsSelector);

	const hintRef = React.useRef<HintRef>(null);

	const changedColumns = filterKnownWebsiteColumns(
		allColumns === true
			? changes
			: allColumns.filter((column) => changes.includes(column)),
	);

	const handleClick = React.useCallback(
		(column) => {
			const columnsIsVisible = enabledColumns.includes(column) || peekedColumns.indexOf(column) !== -1;

			if (columnsIsVisible) {
				scrollToColumn(column);
			} else {
				dispatch(peekColumn(column));
				setTimeout(
					() => scrollToColumn(column),
					100,
				);
			}

			hintRef.current?.close();
		},
		[
			dispatch,
			enabledColumns,
			peekedColumns,
			scrollToColumn,
		],
	);

	return (
		<Hint
			attachment={HintAttachment.Center}
			blurDelay={500}
			inline={false}
			popup={(
				<HintPopupNavigation>
					{changedColumns.map((column) => {
						return (
							<HintPopupNavigationItem
								key={column}
								onClickCallback={() => handleClick(column)}
							>
								<ColumnName column={column} />
							</HintPopupNavigationItem>
						);
					})}
				</HintPopupNavigation>
			)}
			popupLayout={(children, maxHeight) => (
				<HintPopupLayout
					gutter={false}
					maxHeight={maxHeight}
				>
					{children}
				</HintPopupLayout>
			)}
			popupZIndex={4000}
			ref={hintRef}
		>
			<ChangeStatusLabel changeType={ChangeStatusLabelChangeType.Changed}>
				<FormattedMessage {...messages.changeTypeChanged} />
			</ChangeStatusLabel>
		</Hint>
	);
};



export default React.memo(ChangedChangeStatusLabel);
