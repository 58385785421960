/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIsDomTrackedQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIsDomTrackedQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isDomTracked: boolean } | null };


export const WebsiteIsDomTrackedDocument = gql`
    query WebsiteIsDomTracked($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isDomTracked
  }
}
    `;

/**
 * __useWebsiteIsDomTrackedQuery__
 *
 * To run a query within a React component, call `useWebsiteIsDomTrackedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIsDomTrackedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIsDomTrackedQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIsDomTrackedQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIsDomTrackedQuery, WebsiteIsDomTrackedQueryVariables> & ({ variables: WebsiteIsDomTrackedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIsDomTrackedQuery, WebsiteIsDomTrackedQueryVariables>(WebsiteIsDomTrackedDocument, options);
      }
export function useWebsiteIsDomTrackedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIsDomTrackedQuery, WebsiteIsDomTrackedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIsDomTrackedQuery, WebsiteIsDomTrackedQueryVariables>(WebsiteIsDomTrackedDocument, options);
        }
export function useWebsiteIsDomTrackedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteIsDomTrackedQuery, WebsiteIsDomTrackedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIsDomTrackedQuery, WebsiteIsDomTrackedQueryVariables>(WebsiteIsDomTrackedDocument, options);
        }
export type WebsiteIsDomTrackedQueryHookResult = ReturnType<typeof useWebsiteIsDomTrackedQuery>;
export type WebsiteIsDomTrackedLazyQueryHookResult = ReturnType<typeof useWebsiteIsDomTrackedLazyQuery>;
export type WebsiteIsDomTrackedSuspenseQueryHookResult = ReturnType<typeof useWebsiteIsDomTrackedSuspenseQuery>;
export type WebsiteIsDomTrackedQueryResult = Apollo.QueryResult<WebsiteIsDomTrackedQuery, WebsiteIsDomTrackedQueryVariables>;