import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const StarIconBuilder: IconBuilder = {
	icon: ({ color = '#FE8719' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g id="icon-star-rating">
					<path
						d="M12.7149474,3.92968421 L14.4922105,9.40168421 L20.2471579,9.40168421 C20.9747368,9.40168421 21.2778947,10.3351579 20.6892632,10.7633684 L16.0345263,14.1448421 L17.8117895,19.6155789 C18.0378947,20.3090526 17.2433684,20.8850526 16.6547368,20.4568421 L12,17.0753684 L7.34526316,20.4568421 C6.75536842,20.8850526 5.96336842,20.3090526 6.18821053,19.6155789 L7.96673684,14.1448421 L3.312,10.7621053 C2.72210526,10.3326316 3.02526316,9.40042105 3.75410526,9.40042105 L9.50652632,9.40042105 L11.2850526,3.92842105 C11.5098947,3.23621053 12.4901053,3.23621053 12.7149474,3.92842105 L12.7149474,3.92968421 Z"
						fill={color}
						fillRule="nonzero"
						id="Path"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default StarIconBuilder;

