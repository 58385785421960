import {
	useUserReviewSitesQuery,
} from './useUserReviewSites.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserReviewSites(legacyUserId: string | null) {
	const { data } = useUserPropertiesQuery(
		useUserReviewSitesQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.reviewSites ?? null;
}



export default useUserReviewSites;
