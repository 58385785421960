import React from 'react';

import Hint, {
	HintAttachment,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';



type Props = {
	/** Additional style object generated by react-virtualized */
	cssStyle?: React.CSSProperties,
	/** on mouse hover callback */
	onMouseEnter?: (e: any) => void,
	/** on mouse leave callback */
	onMouseLeave?: (e: any) => void,
	/** Popup to show when hovering the ellipsis */
	popup?: React.ReactNode,
	/** Whether to show dots or not */
	truncated?: boolean,
};



const CodeTruncatedCell: React.FC<Props> = (props) => {
	const {
		cssStyle,
		onMouseEnter,
		onMouseLeave,
		popup,
		truncated,
	} = props;

	return (
		<div
			className="code-truncated-cell"
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={cssStyle}
		>
			{truncated && (
				<Hint
					attachment={HintAttachment.Right}
					popup={popup}
					popupVisibility={!!popup ? HintPopupVisibility.OnHover : HintPopupVisibility.Never}
				>
					<div
						className="code-truncated-cell__content"
						style={{
							['--font-size' as any]: '12px',
						}}
					>
						...
					</div>
				</Hint>
			)}
		</div>
	);
};



export default CodeTruncatedCell;
