import {
	ApolloProvider,
} from '@apollo/client';
import React from 'react';
import {
	useStore,
} from 'react-redux';
import {
	RouterProvider,
} from 'react-router5';

import ConnectedIntlProvider from '~/components/ConnectedIntlProvider';
import ContextMenuProvider from '~/components/ContextMenuProvider';
import DepthLayer from '~/components/patterns/utils/DepthLayer';
import Main from '~/components/Main';
import MainErrorBoundary from '~/components/logic/MainErrorBoundary';
import ReduxStoreProvider from '~/components/providers/ReduxStoreProvider';
import SentryContext from '~/components/logic/SentryContext';
import ViewportProvider from '~/components/ViewportProvider';

import useSetupRouter from '~/hooks/useSetupRouter';

import {
	client as apolloClient,
} from '~/apollo';



const App: React.FC = () => {
	const reduxStore = useStore();

	const router = useSetupRouter(reduxStore);

	return (
		<>
			<SentryContext />
			<ConnectedIntlProvider>
				{router.isStarted() && (
					<RouterProvider router={router}>
						<ViewportProvider>
							<DepthLayer>
								<ContextMenuProvider>
									<MainErrorBoundary>
										<Main />
									</MainErrorBoundary>
								</ContextMenuProvider>
							</DepthLayer>
						</ViewportProvider>
					</RouterProvider>
				)}
			</ConnectedIntlProvider>
		</>
	);
};

const AppWithReduxAndApollo: React.FC = () => {
	return (
		<ReduxStoreProvider>
			<ApolloProvider client={apolloClient}>
				<App />
			</ApolloProvider>
		</ReduxStoreProvider>
	);
};



export default AppWithReduxAndApollo;

