/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLogFileAnalysisSettingsMutationVariables = GraphQL.Exact<{
  status: GraphQL.Scalars['Boolean']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateLogFileAnalysisSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteLogFileAnalysisSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isLogFileAnalysisEnabled: boolean, readonly logSources: ReadonlyArray<{ readonly __typename?: 'LogSource', readonly provider: GraphQL.LogSourceProvider, readonly status: GraphQL.LogSourceStatus }> } | null } } };


export const UpdateLogFileAnalysisSettingsDocument = gql`
    mutation UpdateLogFileAnalysisSettings($status: Boolean!, $websiteId: WebsiteId!) {
  UpdateWebsiteLogFileAnalysisSettings(status: $status, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        id
        isLogFileAnalysisEnabled
        logSources {
          provider
          status
        }
      }
    }
  }
}
    `;
export type UpdateLogFileAnalysisSettingsMutationFn = Apollo.MutationFunction<UpdateLogFileAnalysisSettingsMutation, UpdateLogFileAnalysisSettingsMutationVariables>;

/**
 * __useUpdateLogFileAnalysisSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateLogFileAnalysisSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLogFileAnalysisSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLogFileAnalysisSettingsMutation, { data, loading, error }] = useUpdateLogFileAnalysisSettingsMutation({
 *   variables: {
 *      status: // value for 'status'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateLogFileAnalysisSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLogFileAnalysisSettingsMutation, UpdateLogFileAnalysisSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLogFileAnalysisSettingsMutation, UpdateLogFileAnalysisSettingsMutationVariables>(UpdateLogFileAnalysisSettingsDocument, options);
      }
export type UpdateLogFileAnalysisSettingsMutationHookResult = ReturnType<typeof useUpdateLogFileAnalysisSettingsMutation>;
export type UpdateLogFileAnalysisSettingsMutationResult = Apollo.MutationResult<UpdateLogFileAnalysisSettingsMutation>;
export type UpdateLogFileAnalysisSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateLogFileAnalysisSettingsMutation, UpdateLogFileAnalysisSettingsMutationVariables>;