import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import Form from '~/components/atoms/forms/basis/Form';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import GoogleAnalyticsIntegrationFields, {
	validateGoogleAnalyticsAccountId,
	validateGoogleAnalyticsPropertyId,
	validateGoogleAnalyticsViewId,
} from '~/components/app/GoogleAnalyticsIntegrationFields';
import GoogleApi from '~/components/logic/GoogleApi';
import GoogleSearchConsoleRow from '~/components/logic/websiteIntegrations/GoogleSearchConsoleRow';
import MessagingAppChannelFieldRow from '~/components/app/MessagingAppChannelFieldRow';
import SubmitButton from '~/components/app/SubmitButton';

import useBulkUpdateAlertDefinitionsMessagingAppChannels from '~/hooks/useBulkUpdateAlertDefinitionsMessagingAppChannels';
import useUglyMicrosoftTeamsMessagingAppChannelOverlay from '~/hooks/useUglyMicrosoftTeamsMessagingAppChannelOverlay';
import useUpdateWebsiteIntegrations from '~/hooks/useUpdateWebsiteIntegrations';
import useWebsiteAlertDefinitions from '~/hooks/useWebsiteAlertDefinitions';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';

import {
	useConnectGoogleAnalyticsAccountToWebsiteMutation,
	useConnectGoogleAnalyticsV4AccountToWebsiteMutation,
} from './AddWebsiteIntegrationsForm.gql';



const messages = defineMessages({
	saveButton: {
		id: 'ui.general.saveAndContinue',
	},
	skipButton: {
		id: 'ui.general.skipThisStep',
	},
});

const validations = {
	validateGoogleAnalyticsAccountId,
	validateGoogleAnalyticsPropertyId,
	validateGoogleAnalyticsViewId,
};



type Props = {
	onSkipCallback: () => void,
	onSubmitCallback: () => void,
	websiteId: CK.WebsiteId,
};

const AddWebsiteIntegrationsForm: React.FC<Props> = (props) => {
	const {
		onSkipCallback,
		onSubmitCallback,
		websiteId,
	} = props;

	const alertDefinitions = useWebsiteAlertDefinitions(websiteId);
	const bulkUpdateAlertDefinitionsMessagingAppChannels = useBulkUpdateAlertDefinitionsMessagingAppChannels();
	const updateWebsiteIntegrations = useUpdateWebsiteIntegrations();
	const websiteIntegrations = useWebsiteIntegrations(websiteId);
	const [connectGoogleAnalyticsToWebsite] = useConnectGoogleAnalyticsAccountToWebsiteMutation();
	const [connectGoogleAnalyticsV4ToWebsite] = useConnectGoogleAnalyticsV4AccountToWebsiteMutation();

	const {
		addMicrosoftTeamsMessagingAppChannelOverlay,
		initiateAddMicrosoftTeamsMessagingAppChannel,
		messagingAppChannelFieldRowRef,
	} = useUglyMicrosoftTeamsMessagingAppChannelOverlay();

	const gscIntegrationStatus = websiteIntegrations.getStatus(GraphQL.WebsiteIntegrationType.GoogleSearchConsole);

	function submitForm(values): Promise<any> {
		const actions: Array<Promise<any>> = [];

		if (values.googleAnalyticsEnabled) {
			if (values.googleAnalyticsVersion === GraphQL.GoogleAnalyticsVersion.V4) {
				actions.push(
					connectGoogleAnalyticsV4ToWebsite({
						variables: {
							propertyId: values.googleAnalyticsPropertyId,
							websiteId,
						},
					}),
				);
			} else {
				actions.push(
					connectGoogleAnalyticsToWebsite({
						variables: {
							viewId: values.googleAnalyticsViewId,
							websiteId,
						},
					}),
				);
			}
		}

		if (values.gsc_integration_status !== undefined) {
			if (gscIntegrationStatus !== GraphQL.WebsiteIntegrationStatus.Available) {
				actions.push(
					updateWebsiteIntegrations({
						variables: {
							enableGoogleSearchConsole: values.gsc_integration_status,
							websiteId,
						},
					}),
				);
			}
		}

		if (values.messagingAppChannelId !== undefined && values.messagingAppChannelId !== 'none') {
			const alertDefinitionIds = alertDefinitions
				.listAll()
				.map((alertDefinition) => alertDefinition.id);

			if (alertDefinitionIds.length > 0) {
				actions.push(
					bulkUpdateAlertDefinitionsMessagingAppChannels(
						alertDefinitionIds,
						[values.messagingAppChannelId],
						websiteId,
					),
				);
			}
		}

		return Promise.all(actions).then(() => {
			onSubmitCallback();
		});
	}

	return (
		<DisabledContent
			disabledContent={!!addMicrosoftTeamsMessagingAppChannelOverlay}
			disabledOverlay={addMicrosoftTeamsMessagingAppChannelOverlay}
		>
			<CenteredFormWrapper>
				<GoogleApi>
					<Form
						defaultDataHasChanged={true}
						defaultValues={{
							gsc_integration_status: gscIntegrationStatus !== GraphQL.WebsiteIntegrationStatus.Available,
							messagingAppChannelId: 'none',
						}}
						ignoreFieldUnmounts={true}
						onSuccess={submitForm}
						validations={validations}
					>
						<div
							style={{
								minHeight: 376,
							}}
						>
							<FormRows>
								<MessagingAppChannelFieldRow
									name="messagingAppChannelId"
									onInitiateAddMicrosoftChannel={initiateAddMicrosoftTeamsMessagingAppChannel}
									ref={messagingAppChannelFieldRowRef}
								/>

								<GoogleSearchConsoleRow
									isGscIntegrationOwned={true}
									name="gsc_integration_status"
								/>

								<GoogleAnalyticsIntegrationFields
									enableByDefaultWhenSuggestionsAreFound={true}
									websiteId={websiteId}
								/>
							</FormRows>
						</div>
						<ButtonsLayout>
							<CancelButton onClickCallback={onSkipCallback}>
								<FormattedMessage {...messages.skipButton} />
							</CancelButton>

							<SubmitButton>
								<FormattedMessage {...messages.saveButton} />
							</SubmitButton>
						</ButtonsLayout>
					</Form>
				</GoogleApi>
			</CenteredFormWrapper>
		</DisabledContent>
	);
};



export default AddWebsiteIntegrationsForm;
