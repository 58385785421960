import React from 'react';

import {
	ExternalLinkStyle,
} from '~/components/patterns/links/ExternalLink';
import {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';



type Context = {
	externalLinkStyle?: ExternalLinkStyle,
	internalLinkStyle?: InternalLinkStyle,
};

type Props = {
	children?: React.ReactNode,
	externalLinkStyle?: ExternalLinkStyle,
	internalLinkStyle?: InternalLinkStyle,
};

export {
	ExternalLinkStyle,
	InternalLinkStyle,
};



export const LinkContext = React.createContext<Context | null>(null);



const LinkContextProvider: React.FC<Props> = (props) => {
	const {
		children,
		externalLinkStyle,
		internalLinkStyle,
	} = props;

	const linkProps = React.useMemo(
		() => ({
			externalLinkStyle,
			internalLinkStyle,
		}),
		[
			externalLinkStyle,
			internalLinkStyle,
		],
	);

	return (
		<LinkContext.Provider value={linkProps}>
			{children}
		</LinkContext.Provider>
	);
};



export default LinkContextProvider;

