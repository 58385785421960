import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const DiamondIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M18.019,8.836a.25.25,0,0,0-.241.183l-3.387,12.1a.25.25,0,0,0,.444.212l8.64-12.094a.251.251,0,0,0-.2-.4Z"
				fill={color}
			/>
			<path
				d="M16.182,9.154a.25.25,0,0,0-.24-.318H8.059a.25.25,0,0,0-.241.318l3.941,14.077a.25.25,0,0,0,.482,0Z"
				fill={color}
			/>
			<path
				d="M14.938,7.336a.25.25,0,0,0,.226-.357L12.174.7A.193.193,0,0,0,12,.586a.191.191,0,0,0-.173.11L8.836,6.979a.25.25,0,0,0,.226.357Z"
				fill={color}
			/>
			<path
				d="M9.165,21.327a.25.25,0,0,0,.444-.212L6.222,9.019a.249.249,0,0,0-.24-.183H.729a.251.251,0,0,0-.2.4Z"
				fill={color}
			/>
			<path
				d="M18.338,7.019a.249.249,0,0,0,.241.317h4.676a.25.25,0,0,0,.2-.4L20.062,2.356a.25.25,0,0,0-.441.081Z"
				fill={color}
			/>
			<path
				d="M5.422,7.336a.249.249,0,0,0,.24-.317L4.38,2.437a.25.25,0,0,0-.442-.081L.544,6.938a.25.25,0,0,0,.2.4Z"
				fill={color}
			/>
			<path
				d="M7.016,6.292a.251.251,0,0,0,.467.04L10.047.944A.25.25,0,0,0,9.822.586H5.745a.256.256,0,0,0-.2.1A.251.251,0,0,0,5.508.9Z"
				fill={color}
			/>
			<path
				d="M16.517,6.332a.25.25,0,0,0,.467-.04L18.492.9a.25.25,0,0,0-.236-.318H14.178a.25.25,0,0,0-.225.358Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default DiamondIconBuilder;
