/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteFetchingLocationQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteFetchingLocationQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly fetchingLocation: GraphQL.FetchingLocation, readonly id: CK.WebsiteId } | null };


export const WebsiteFetchingLocationDocument = gql`
    query WebsiteFetchingLocation($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    fetchingLocation
    id
  }
}
    `;

/**
 * __useWebsiteFetchingLocationQuery__
 *
 * To run a query within a React component, call `useWebsiteFetchingLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteFetchingLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteFetchingLocationQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteFetchingLocationQuery(baseOptions: Apollo.QueryHookOptions<WebsiteFetchingLocationQuery, WebsiteFetchingLocationQueryVariables> & ({ variables: WebsiteFetchingLocationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteFetchingLocationQuery, WebsiteFetchingLocationQueryVariables>(WebsiteFetchingLocationDocument, options);
      }
export function useWebsiteFetchingLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteFetchingLocationQuery, WebsiteFetchingLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteFetchingLocationQuery, WebsiteFetchingLocationQueryVariables>(WebsiteFetchingLocationDocument, options);
        }
export function useWebsiteFetchingLocationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteFetchingLocationQuery, WebsiteFetchingLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteFetchingLocationQuery, WebsiteFetchingLocationQueryVariables>(WebsiteFetchingLocationDocument, options);
        }
export type WebsiteFetchingLocationQueryHookResult = ReturnType<typeof useWebsiteFetchingLocationQuery>;
export type WebsiteFetchingLocationLazyQueryHookResult = ReturnType<typeof useWebsiteFetchingLocationLazyQuery>;
export type WebsiteFetchingLocationSuspenseQueryHookResult = ReturnType<typeof useWebsiteFetchingLocationSuspenseQuery>;
export type WebsiteFetchingLocationQueryResult = Apollo.QueryResult<WebsiteFetchingLocationQuery, WebsiteFetchingLocationQueryVariables>;