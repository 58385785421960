import classNames from 'classnames';
import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';



type Props = {
	/** Currency code for correct formatting */
	currency: string,
	/** Highlighted price value */
	highlighted?: boolean,
	/** Label of price item */
	label: React.ReactNode,
	/** Raw value of price. This will be formatted inside of this component into correct form. */
	priceValue: number,
	/** Additional sub-label */
	sublabel?: React.ReactNode,
};

const PriceItem: React.FC<Props> = (props) => {
	const {
		currency,
		highlighted = false,
		label,
		priceValue,
		sublabel,
	} = props;

	const componentClasses = classNames({
		'price-item': true,
		'price-item--highlighted': highlighted,
	});

	return (
		<div className={componentClasses}>
			<div className="price-item__label-section">
				<div className="price-item__label">
					{label}
				</div>

				{sublabel && (
					<div className="price-item__sublabel">
						{sublabel}
					</div>
				)}
			</div>

			<div className="price-item__value">
				<FormattedNumber
					currency={currency}
					style="currency"
					value={priceValue}
				/>
			</div>
		</div>
	);
};



export default PriceItem;
