import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';

import {
	LIST_OF_PRIMARY_STATUSES,
	createAlertPagesFilterParameter,
	listApplicableStatuses,
} from '~/model/alertPages';



const messages = defineMessages({
	seeChangesButton: {
		id: 'ui.alerts.overview.incident.followup.viewPages',
	},
});



class AlertPagesButton extends Component {

	render() {
		const {
			focusOnPrimary,
			incident,
			websiteId,
		} = this.props;

		let filter = null;

		if (focusOnPrimary) {
			const applicableStatuses = listApplicableStatuses(incident.get('type'), incident.get('settings'));

			const primaryStatuses = incident.get('settings').has('statuses')
				? incident.get('settings').get('statuses').get('primary')
				: LIST_OF_PRIMARY_STATUSES;

			filter = {
				status: primaryStatuses
					.filter((status) => applicableStatuses.includes(status))
					.toArray(),
			};
		}

		return (
			<Button
				linkRouteName="website.alerts.alertPages"
				linkRouteParams={{
					alertId: incident.get('id'),
					filter: createAlertPagesFilterParameter(
						filter || null,
						null,
						incident.get('type'),
						incident.get('settings'),
					),
					websiteId,
				}}
				size={ButtonSize.Small}
				style={ButtonStyle.Hollow}
			>
				<FormattedMessage {...messages.seeChangesButton} />
			</Button>
		);
	}

}

AlertPagesButton.propTypes = {
	focusOnPrimary: PropTypes.bool.isRequired,
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string.isRequired,
};



export default AlertPagesButton;
