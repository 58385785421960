import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useAccountPhase from './useAccountPhase';
import useAccountState from './useAccountState';



function useAccountIsChurned(accountId: CK.AccountId | null): boolean {
	const accountPhase = useAccountPhase(accountId);
	const accountState = useAccountState(accountId);

	return (
		accountPhase === GraphQL.AccountPhase.Customer
		&& accountState === GraphQL.AccountState.Churned
	);
}



export default useAccountIsChurned;
