import type CK from '~/types/contentking';

import {
	useWebsiteLogoQuery,
} from './useWebsiteLogo.gql';

import useBatchContext from '~/hooks/useBatchContext';



function useWebsiteLogo(websiteId: CK.WebsiteId | null): string | null {
	const { data } = useWebsiteLogoQuery({
		context: useBatchContext('websiteLogos', 50),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return data?.website?.logo ?? null;
}



export default useWebsiteLogo;
