import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import {
	injectIntl,
} from 'react-intl';

import ChartTooltip from '~/components/patterns/charts/components/ChartTooltip';

import {
	setCustomRoundedCorners,
	setHighchartsGlobalStyles,
} from '~/utilities/highcharts';



class ColumnChart extends Component {

	constructor(props, context) {
		super(props, context);

		this.chartRef = React.createRef();

		this._calculateTickPositions = this._calculateTickPositions.bind(this);
		this._calculateYAxisRange = this._calculateYAxisRange.bind(this);
		this._handleRender = this._handleRender.bind(this);

		this.state = {
			isRendered: false,
		};
	}



	_calculateTickPositions({ maximum, minimum }) {
		const {
			yAxisPoints,
		} = this.props;

		const interval = (maximum - minimum) / yAxisPoints;

		const positions = [];

		for (let i = 0; i < (yAxisPoints + 1); i++) {
			positions.push(i * interval);
		}

		return positions;
	}



	_calculateYAxisRange(props) {
		const {
			data,
			maximum,
			minimum,
		} = props;

		let flatData = [];
		data.forEach((item) => {
			flatData = flatData.concat(item.data);
		});

		const maxValue = Math.max.apply(Math, flatData);
		const minValue = Math.min.apply(Math, flatData);

		return {
			maximum: typeof maximum === 'function'
				? maximum({ maxValue, minValue })
				: maximum,
			minimum: typeof minimum === 'function'
				? minimum({ maxValue, minValue })
				: minimum,
		};
	}



	UNSAFE_componentWillMount() {
		setHighchartsGlobalStyles();
		setCustomRoundedCorners();
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			data,
			xAxisCaptions,
		} = this.props;

		const {
			data: nextData,
			xAxisCaptions: nextXAxisCaptions,
		} = nextProps;

		if (nextData && nextData.length > 0 && (!Immutable.is(Immutable.fromJS(data), Immutable.fromJS(nextData)) || !Immutable.is(xAxisCaptions, nextXAxisCaptions))) {
			const chart = this.chartRef.current && this.chartRef.current.chart;

			if (chart && chart.series) {
				if (!Immutable.is(xAxisCaptions, nextXAxisCaptions)) {
					chart.xAxis[0].setCategories(nextXAxisCaptions.toArray ? nextXAxisCaptions.toArray() : nextXAxisCaptions);
				}

				const nextSeriesData = this._setColumnChartDataZerosToNull(nextData);
				chart.series.forEach((series, index) => {
					series.setData(
						nextSeriesData[index].data,
						false,
						false,
						true,
					);
				});

				const {
					maximum,
					minimum,
				} = this._calculateYAxisRange(nextProps);

				chart.yAxis[0].setExtremes(
					minimum,
					(maximum === minimum && maximum === 0) ? 10 : maximum,
				);

				chart.yAxis[0].update({
					tickPositions: this._calculateTickPositions({ maximum, minimum }),
				});

				chart.redraw();
			}
		}
	}



	shouldComponentUpdate() {
		return false;
	}



	_handleRender() {
		if (!this.state.isRendered && this.props.onRenderCallback) {
			this.props.onRenderCallback();
		}

		this.setState({
			isRendered: true,
		});
	}



	_setColumnChartDataZerosToNull(data) {
		return Immutable.fromJS(data)
			.map((series) => series.set(
				'data',
				series.get('data')
					.map((value) => value === 0 ? null : value),
			)).toJS();
	}



	render() {
		const {
			animate,
			barWidth,
			chartHeight,
			data,
			disabled,
			intl,
			minBarLength,
			onClickCallback,
			renderTooltipCallback,
			seriesColors,
			viewportType,
			xAxisCaptions,
		} = this.props;

		const {
			maximum,
			minimum,
		} = this._calculateYAxisRange(this.props);

		const seriesData = this._setColumnChartDataZerosToNull(data);

		const config = {
			chart: {
				backgroundColor: 'transparent',
				events: {
					render: this._handleRender,
				},
				height: chartHeight,
				spacing: [10, 0, 0, 0], // top margin to prevent overflowing captions on y-axes
				type: 'column',
			},
			colors: seriesColors,
			plotOptions: {
				column: {
					animation: (
						animate
							? (disabled
								? false
								: {
									duration: 1000,
								}
							)
							: false
					),

					// Next two options needs custom plugin
					// See setCustomRoundedCorners() in highcharts.js
					borderRadiusTopLeft: 4,
					borderRadiusTopRight: 4,

					borderWidth: 0,
					colorByPoint: data.length === 1,
					colors: seriesColors,
					cursor: viewportType.isSmall || disabled || !onClickCallback ? 'default' : 'pointer',
					point: {
						events: {
							// attach click event only on larger screen to prevent displaying of pointer cursor
							click: viewportType.isSmall === false && !disabled && function () {
								const clickedChangeType = this.series.name;
								const clickedValue = this.y;

								if (onClickCallback) {
									onClickCallback(this.x, clickedChangeType, clickedValue, this);
								}
							},
						},
					},
					maxPointWidth: barWidth,
					minPointLength: minBarLength,
					states: {
						hover: {
							enabled: false,
						},
						inactive: {
							opacity: 1,
						},
					},
				},
			},
			series: seriesData,
			tooltip: {
				animation: false,
				backgroundColor: 'transparent',
				borderWidth: 0,
				enabled: !disabled && viewportType.isSmall === false,
				formatter: function () {
					let content;

					if (renderTooltipCallback) {
						content = renderTooltipCallback({
							count: this.y,
							index: this.point.index,
							key: this.key,
							seriesName: this.series.name,
						});
					} else {
						content = intl.formatNumber(this.y);
					}

					return ReactDOMServer.renderToString(
						<ChartTooltip color={this.color}>
							{content}
						</ChartTooltip>,
					);
				},
				padding: 0,
				shadow: false,
				shape: 'square',
				useHTML: true,
			},
			xAxis: {
				categories: xAxisCaptions.toArray ? xAxisCaptions.toArray() : xAxisCaptions,
				labels: {
					style: {
						color: '#8595A6',
						fontFamily: '\'Roboto\', sans-serif',
						fontSize: '11px',
						fontWeight: '300',
						lineHeight: '11px',
						textTransform: 'uppercase',
					},
					y: 18,
				},
				lineWidth: 0,
				tickWidth: 0,
				title: {
					text: null,
				},
			},
			yAxis: {
				labels: {
					style: {
						color: '#8595A6',
						fontFamily: '\'Roboto\', sans-serif',
						fontSize: '11px',
						lineHeight: '11px',
						fontWeight: '300',
					},
					x: -10,
				},
				max: (maximum === minimum && maximum === 0) ? 10 : maximum,
				min: minimum,
				tickPositions: this._calculateTickPositions({ maximum, minimum }),
				startOnTick: false,
				title: {
					text: null,
				},
			},
		};

		return (
			<HighchartsReact
				highcharts={Highcharts}
				options={config}
				ref={this.chartRef}
			/>
		);
	}

}

ColumnChart.defaultProps = {
	minBarLength: 2,
};

ColumnChart.propTypes = {
	animate: PropTypes.bool,
	barWidth: PropTypes.number.isRequired,
	chartHeight: PropTypes.number.isRequired,
	data: PropTypes.array,
	maximum: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.number,
	]).isRequired,
	minimum: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.number,
	]).isRequired,
	minBarLength: PropTypes.number,
	onClickCallback: PropTypes.func,
	onRenderCallback: PropTypes.func,
	seriesColors: PropTypes.array,
	viewportType: PropTypes.shape({
		isSmall: PropTypes.bool.isRequired,
	}).isRequired,
	xAxisCaptions: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.instanceOf(Immutable.List),
	]),
	yAxisPoints: PropTypes.number.isRequired,
};



export default injectIntl(ColumnChart);
