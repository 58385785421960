/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PurgePagesHistoryByFilterMutationVariables = GraphQL.Exact<{
  purgedProperties: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PurgePagesHistoryByFilterMutation = { readonly __typename?: 'Mutation', readonly PurgePagesHistoryByFilter: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const PurgePagesHistoryByFilterDocument = gql`
    mutation PurgePagesHistoryByFilter($purgedProperties: [String!]!, $websiteId: WebsiteId!) {
  PurgePagesHistoryByFilter(
    purgedProperties: $purgedProperties
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type PurgePagesHistoryByFilterMutationFn = Apollo.MutationFunction<PurgePagesHistoryByFilterMutation, PurgePagesHistoryByFilterMutationVariables>;

/**
 * __usePurgePagesHistoryByFilterMutation__
 *
 * To run a mutation, you first call `usePurgePagesHistoryByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurgePagesHistoryByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purgePagesHistoryByFilterMutation, { data, loading, error }] = usePurgePagesHistoryByFilterMutation({
 *   variables: {
 *      purgedProperties: // value for 'purgedProperties'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePurgePagesHistoryByFilterMutation(baseOptions?: Apollo.MutationHookOptions<PurgePagesHistoryByFilterMutation, PurgePagesHistoryByFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurgePagesHistoryByFilterMutation, PurgePagesHistoryByFilterMutationVariables>(PurgePagesHistoryByFilterDocument, options);
      }
export type PurgePagesHistoryByFilterMutationHookResult = ReturnType<typeof usePurgePagesHistoryByFilterMutation>;
export type PurgePagesHistoryByFilterMutationResult = Apollo.MutationResult<PurgePagesHistoryByFilterMutation>;
export type PurgePagesHistoryByFilterMutationOptions = Apollo.BaseMutationOptions<PurgePagesHistoryByFilterMutation, PurgePagesHistoryByFilterMutationVariables>;