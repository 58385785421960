import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import LazyImage from '~/components/patterns/images/LazyImage';
import PageRelevance from '~/components/atoms/pageDetail/components/PageRelevance';
import ScreenshotImage from '~/components/atoms/images/ScreenshotImage';
import ScreenPlaceholder from '~/components/patterns/images/ScreenPlaceholder';



const messages = defineMessages({
	screenPlaceholder: {
		id: 'ui.pageDetail.screenPlaceholder',
	},
});



type Props = {
	pageImportance: number | null,
	pageScreenshotUrl: string | null,
};

const PageScreenshot: React.FC<Props> = (props) => {
	const {
		pageImportance,
		pageScreenshotUrl,
	} = props;

	return (
		<ScreenshotImage
			image={(
				<LazyImage
					height={200}
					placeholder={(
						<ScreenPlaceholder
							message={(
								<FormattedMessage {...messages.screenPlaceholder} />
							)}
						/>
					)}
					src={pageScreenshotUrl}
					width={280}
				/>
			)}
			label={(
				<PageRelevance
					relevance={pageImportance}
				/>
			)}
		/>
	);
};



export default PageScreenshot;
