import Immutable, {
	Map,
} from 'immutable';

import {
	ACTION_STORE_INCIDENTS,
} from '~/actions/incidents';

import {
	doesAlertTypeExist,
} from '~/model/alerts';



function formatIncidents({ incidents }) {
	const result = {};

	incidents
		.filter(({ type }) => doesAlertTypeExist(type))
		.forEach((incident) => {
			// BC!
			incident.last_state = incident.state;

			result[incident.id] = incident;
		});

	return result;
}



function createDefaultState() {
	return Map();
}



export function incidents(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case ACTION_STORE_INCIDENTS: {
			const {
				incidents,
				websiteId,
			} = action;

			const currentIncidents = state.get(websiteId, Map());
			const loadedIncidents = formatIncidents({ incidents });

			if (!Immutable.is(currentIncidents, loadedIncidents)) {
				state = state.set(
					websiteId,
					Immutable.fromJS(loadedIncidents),
				);
			}
		}

	}

	return state;
}
