import React from 'react';
import {
	useIntl,
} from 'react-intl';

import {
	formatCopy,
} from '~/components/logic/Copy';

import {
	isDictionaryId,
} from '~/localization/dictionaries';

import {
	EMPTY,
} from '~/localization/languages/constants';

import {
	type IssueName,
} from '~/model/issuesNew';



type Props = {
	issue: {
		context: any,
		name: IssueName,
	},
};

const IssueInlineCaseDescription: React.FC<Props> = (props) => {
	const {
		issue,
	} = props;

	const intl = useIntl();

	const messageId = `issues.${issue.name}.inlineCaseDescription` as const;

	if (!isDictionaryId(messageId)) {
		return null;
	}

	const inlineCaseDescription = formatCopy(intl, {
		id: messageId,
	}, issue.context);

	if (inlineCaseDescription === messageId || inlineCaseDescription === EMPTY) {
		return null;
	}

	return (
		<>{inlineCaseDescription}</>
	);
};



export default IssueInlineCaseDescription;
