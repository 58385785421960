import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import ActiveFilter from '~/components/logic/filters/ActiveFilter';

import {
	updateFilter,
} from '~/actions/sitemapsList';

import {
	removeDefaultFilterValues,
} from '~/model/sitemapsList';

import {
	filterSelector,
} from '~/state/sitemapsList/selectors';



type Props = {
	onFilterClickCallback: (filterProperty: string) => void,
};

const SitemapsListActiveFilter: React.FC<Props> = (props) => {
	const {
		onFilterClickCallback,
	} = props;

	const dispatch = useDispatch();

	const sitemapsListFilters = useSelector(filterSelector);
	const activeFilters = removeDefaultFilterValues(sitemapsListFilters);

	const handleRemoveFilter = React.useCallback(
		(filterId) => {
			dispatch(
				updateFilter({
					[filterId]: undefined,
				}),
			);
		},
		[
			dispatch,
		],
	);

	return (
		<ActiveFilter
			activeFilters={activeFilters}
			clearLink={false}
			filter={sitemapsListFilters}
			onFilterClickCallback={onFilterClickCallback}
			removeFilterCallback={handleRemoveFilter}
			segmentsManagement={false}
		/>
	);
};



export default SitemapsListActiveFilter;
