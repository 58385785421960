import React from 'react';
import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';

import useColumnSets from '~/hooks/useColumnSets';

import {
	CHANGE_VIEW,
} from '~/actions/teams/views';



function useChangeColumnSet() {
	const columnSets = useColumnSets();
	const dispatch = useDispatch();

	return React.useCallback(
		(columnSetId: CK.ColumnSetId, closeColumnsConfigurator: boolean) => {
			if (columnSets.isLoaded === false) {
				return;
			}

			const chosenColumnSet = columnSets.columnSets.find(
				(columnSet) => columnSet.id === columnSetId,
			);

			if (chosenColumnSet === undefined) {
				return;
			}

			dispatch({
				type: CHANGE_VIEW,
				closeColumnsConfigurator,
				columns: chosenColumnSet.columns,
				viewId: columnSetId,
			});
		},
		[
			columnSets,
			dispatch,
		],
	);
}



export default useChangeColumnSet;
