import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import InternalLink from '~/components/patterns/links/InternalLink';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
} from '~/components/atoms/forms/components/TextField';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useImpersonateUser from '~/hooks/useImpersonateUser';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useRoyalMode from '~/hooks/useRoyalMode';

import FormError from '~/utilities/FormError';



const validations = {
	email: [
		{
			message: 'Field cannot be blank',
			field: 'email',
			rule: ({ values, name }) => {
				return values[name] !== undefined && values[name] !== null && values[name].length > 0;
			},
		},
		{
			message: ({ value }) => {
				if (value.includes('@')) {
					return `There is no user with this e-mail address.`;
				}

				return `There is no such account, website or user.`;
			},
			field: 'email',
			globalRule: 'unknownEmail',
		},
		{
			message: `Account with this ID doesn't exist.`,
			field: 'email',
			globalRule: 'unknownAccount',
		},
	],
};

export type ChangeAccountModalAvailableAccounts = ReadonlyArray<{
	accountId: CK.AccountId,
	accountPhase: GraphQL.AccountPhase,
	accountState: string,
	email: string,
	memberships: ReadonlyArray<{
		accountId: CK.AccountId,
		accountPhase: GraphQL.AccountPhase,
		accountState: string,
		role: GraphQL.UserRole,
		numberOfHomeWebsites: number,
	}>,
	numberOfHomeWebsites: number,
	role: GraphQL.UserRole,
	signature: string,
}>;



type Props = {
	defaultInput: string,
	handleAvailableAccounts: (availableAccounts: ChangeAccountModalAvailableAccounts) => void,
};

const ChangeAccountModal: React.FC<Props> = (props) => {
	const {
		defaultInput,
		handleAvailableAccounts,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const impersonateUser = useImpersonateUser();
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const royalMode = useRoyalMode();

	const formRef = React.useRef<any>(null);

	const handleSubmit = React.useCallback(
		async ({ email }) => {
			let suggestedImpersonations: ChangeAccountModalAvailableAccounts = [];

			try {
				suggestedImpersonations = await impersonateUser(email, true);
			} catch (error) {
				throw FormError.fromApolloError(error, {
					unknownEmail: email,
				});
			}

			if (suggestedImpersonations.length === 0) {
				throw new FormError('unknownEmail', email);
			}

			handleAvailableAccounts(
				suggestedImpersonations,
			);
		},
		[
			handleAvailableAccounts,
			impersonateUser,
		],
	);

	React.useEffect(
		() => {
			if (defaultInput !== '' && formRef.current) {
				formRef.current.setValues({
					email: defaultInput,
				}).then(
					() => formRef.current.submit(),
				);
			}
		},
		[
			defaultInput,
			formRef,
		],
	);

	return (
		<SimpleModal
			alignToTop={true}
			size={SimpleModalSize.Small}
			title="Switch Account"
		>
			<Form
				defaultDataHasChanged={defaultInput !== ''}
				defaultFocus="email"
				defaultValues={{
					email: defaultInput,
				}}
				onSuccess={handleSubmit}
				ref={formRef}
				validations={validations}
			>
				{({ setValues }) => (
					<>
						<ModalTextSection centered={true}>
							<RichText>
								↓ Email / Account ID / Website ↓
							</RichText>
						</ModalTextSection>

						<CenteredFormWrapper>
							<FormRow
								description={royalMode.isImpersonated === true && (
									<>
										<InternalLink
											onClickCallback={() => {
												setValues({ email: royalMode.email });
											}}
										>
											{royalMode.email}
										</InternalLink>
									</>
								)}
								htmlFor="email"
								label="Search input"
							>
								<FieldStatus name="email">
									<TextField
										autoComplete={kingdomAdminFeatures.areVisible ? TextFieldAutocomplete.On : TextFieldAutocomplete.Off}
										name="email"
										trimValue={true}
									/>
								</FieldStatus>
							</FormRow>

							<FormErrorMessages />

							<ButtonsLayout>
								<CancelButton onClickCallback={classicFormBehavior.cancel} />

								<SubmitButton>
									Switch Account
								</SubmitButton>
							</ButtonsLayout>
						</CenteredFormWrapper>
					</>
				)}
			</Form>
		</SimpleModal>
	);
};



export default ChangeAccountModal;
