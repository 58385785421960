export enum ProfileCompletenessTask {
	AddSecondWebsite = 'addSecondWebsite',
	ConnectGoogleAnalytics = 'connectGoogleAnalytics',
	ConnectGoogleDataStudio = 'connectGoogleDataStudio',
	ConnectGoogleSearchConsole = 'connectGoogleSearchConsole',
	ConnectSlack = 'connectSlack',
	InstallChromeExtension = 'installChromeExtension',
	InviteSecondUser = 'inviteSecondUser',
	InviteThirdUser = 'inviteThirdUser',
	SetAccountName = 'setAccountName',
}
