import {
	formatISO,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import DateAndTimeLabel from '~/components/app/DateAndTimeLabel';
import DomSrcBadge, {
	DomSrcBadgeType,
} from '~/components/patterns/tags/DomSrcBadge';
import ExportSnapshotDownloadButton, {
	SnapshotType,
} from '../../app/ExportSnapshotDownloadButton';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleWithValue, {
	SIZE_SMALL as HEADER_TITLE_WITH_VALUE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';
import PageSnapshotHeaders from './PageSnapshotHeaders';
import PageSnapshotRenderedDom from './PageSnapshotRenderedDom';
import PageSnapshotResponseBody from './PageSnapshotResponseBody';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import ScreenHeader, {
	ScreenHeaderScope,
} from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import ScreenOverlay, {
	ScreenOverlayStyle,
} from '~/components/patterns/screens/parts/ScreenOverlay';
import StickToScreenBottom, {
	StickToScreenBottomPreset,
} from '~/components/patterns/utils/StickToScreenBottom';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';
import WidthLimiter from '~/components/patterns/utils/WidthLimiter';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageUrl from '~/hooks/usePageUrl';
import useUrlId from '~/hooks/useUrlId';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteId from '~/hooks/useWebsiteId';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	title: {
		id: 'ui.snapshotViewer.title',
	},
	tabHeaders: {
		id: 'ui.snapshotViewer.tabs.header',
	},
	tabRenderedDom: {
		id: 'ui.snapshotViewer.tabs.renderedDom',
	},
	tabResponseBody: {
		id: 'ui.snapshotViewer.tabs.responseBody',
	},
});



export enum Tab {
	Headers = 'headers',
	RenderedDom = 'renderedDom',
	ResponseBody = 'responseBody',
}



const PageSnapshowPanel: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const urlState = useUrlState();
	const date = urlState.params.timestamp
		? new Date(parseInt(urlState.params.timestamp, 10) * 1000)
		: undefined;
	const timestamp = urlState.params.timestamp
		? formatISO(parseInt(urlState.params.timestamp, 10) * 1000)
		: undefined;

	const url = usePageUrl(legacyUrlId, websiteId);
	const urlId = useUrlId(legacyUrlId, websiteId);

	const isLoading = !urlId;

	const tabName = urlState.name.split('.').pop() as string;
	let activeTab = Tab.Headers;

	if (tabName === 'headers') {
		activeTab = Tab.Headers;
	} else if (tabName === 'responseBody') {
		activeTab = Tab.ResponseBody;
	} else if (tabName === 'renderedDom') {
		activeTab = Tab.RenderedDom;
	}

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.StoreResponseSnapshots}
			showUnavailablePlan={true}
			style={PremiumFeatureSituationStyle.Box}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => (
				<ScreenLayout
					contentOverlay={(
						isLoading && (
							<ScreenOverlay style={ScreenOverlayStyle.Light}>
								<LoadingDots size={LoadingDotsSize.Large} />
							</ScreenOverlay>
						)
					)}
					header={(
						<ScreenHeader
							footer={(
								<TabNavigation stretchItems={false}>
									<TabNavigationItem
										isActive={activeTab === Tab.Headers}
										linkRouteName="website.pages.detail.history.snapshot.headers"
										linkRouteParams={urlState.params}
									>
										<FormattedMessage {...messages.tabHeaders} />
									</TabNavigationItem>

									<TabNavigationItem
										icon={(
											<DomSrcBadge type={DomSrcBadgeType.Src} />
										)}
										isActive={activeTab === Tab.ResponseBody}
										isNotReady={!isFeatureEnabled}
										linkRouteName="website.pages.detail.history.snapshot.responseBody"
										linkRouteParams={urlState.params}
									>
										<FormattedMessage {...messages.tabResponseBody} />
									</TabNavigationItem>

									<TabNavigationItem
										icon={(
											<DomSrcBadge type={DomSrcBadgeType.Dom} />
										)}
										isActive={activeTab === Tab.RenderedDom}
										isNotReady={!isFeatureEnabled}
										linkRouteName="website.pages.detail.history.snapshot.renderedDom"
										linkRouteParams={urlState.params}
									>
										<FormattedMessage {...messages.tabRenderedDom} />
									</TabNavigationItem>
								</TabNavigation>
							)}
							scope={ScreenHeaderScope.Panel}
						>
							<HeaderTitleLayout>
								<HeaderTitleWithValue
									size={HEADER_TITLE_WITH_VALUE_SIZE_SMALL}
									title={(
										<FormattedMessage {...messages.title} />
									)}
									value={date ? (
										<DateAndTimeLabel timestamp={date} />
									) : undefined}
								/>
							</HeaderTitleLayout>

							<HeaderOptionsLayout>
								<ExportSnapshotDownloadButton
									isDisabled={isLoading}
									snapshotType={SnapshotType.Page}
									timestamp={timestamp ?? null}
									url={url}
									websiteId={websiteId}
								/>
							</HeaderOptionsLayout>
						</ScreenHeader>
					)}
				>
					<StickToScreenBottom preset={StickToScreenBottomPreset.FullscreenDetail}>
						{({ height, width }) => {
							if (isLoading || !timestamp) {
								return (
									<div style={{ height }} />
								);
							}

							if (activeTab === Tab.Headers) {
								return (
									<PageSnapshotHeaders
										height={height}
										timestamp={timestamp}
										url={url}
										urlId={urlId}
										websiteId={websiteId}
									/>
								);
							}

							if (!isFeatureEnabled) {
								return (
									<div style={{ height }}>
										<ScreenOverlay style={ScreenOverlayStyle.Light}>
											<WidthLimiter width={420}>
												{premiumAnnotation}
											</WidthLimiter>
										</ScreenOverlay>
									</div>
								);
							}

							return matchAndReturn(activeTab, {
								[Tab.ResponseBody]: (
									<PageSnapshotResponseBody
										height={height}
										timestamp={timestamp}
										urlId={urlId}
										websiteId={websiteId}
										width={width}
									/>
								),
								[Tab.RenderedDom]: (
									<PageSnapshotRenderedDom
										height={height}
										timestamp={timestamp}
										urlId={urlId}
										websiteId={websiteId}
										width={width}
									/>
								),
							});
						}}
					</StickToScreenBottom>
				</ScreenLayout>
			)}
		</PremiumFeatureSituation>
	);
};



export default PageSnapshowPanel;

