import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import LogoutConfirmationModal from '~/components/atoms/modals/LogoutConfirmationModal';
import MenuItemLayout from '~/components/atoms/navigations/builders/MenuItemLayout';
import NavigationIcon, {
	NavigationIconType,
} from '~/components/patterns/icons/NavigationIcon';

import useOpenModal from '~/hooks/useOpenModal';



const messages = defineMessages({
	logout: {
		id: 'ui.logout',
	},
});



type Props = {
	isLabelHidden?: boolean,
};

const LogoutMenuItem: React.FC<Props> = (props) => {
	const {
		isLabelHidden = true,
	} = props;

	const intl = useIntl();

	const openLogoutModal = useOpenModal(
		({ closeCallback }) => (
			<LogoutConfirmationModal
				closeCallback={closeCallback}
			/>
		),
		[],
	);

	return (
		<MenuItemLayout
			icon={(
				<NavigationIcon type={NavigationIconType.Logout} />
			)}
			isLabelHidden={isLabelHidden}
			label={(
				<FormattedMessage {...messages.logout} />
			)}
			onClickCallback={openLogoutModal}
			title={intl.formatMessage(messages.logout)}
		/>
	);
};



export default LogoutMenuItem;
