import type CK from '~/types/contentking';

import {
	useWebsiteLighthouseThresholdsQuery,
} from './useWebsiteLighthouseThresholds.gql';

import useBatchContextWebsiteConfiguration from '~/hooks/useBatchContextWebsiteConfiguration';



function useWebsiteLighthouseThresholds(websiteId: CK.WebsiteId | null) {
	const { data } = useWebsiteLighthouseThresholdsQuery({
		context: useBatchContextWebsiteConfiguration(websiteId),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId || '',
		},
	});

	return data?.website?.lighthouseThresholds ?? null;
}



export default useWebsiteLighthouseThresholds;
