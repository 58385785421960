import getOptionsLabel from '~/utilities/getOptionsLabel';



export default function getOptionLabel(options, value) {
	if (value === null) {
		return '';
	}

	return getOptionsLabel(options, [value]);
}
