import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	validatePassword,
} from '~/components/app/validatePassword';

import {
	validateField,
} from '~/components/app/validations';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserEmail from '~/hooks/useUserEmail';

import {
	runAction,
} from '~/model/actions';



const messages = defineMessages({
	laterLink: {
		id: 'ui.onboarding.form.laterLink',
	},
	nextButton: {
		id: 'ui.onboarding.form.nextButton',
	},
	passwordLabel: {
		id: 'ui.onboarding.form.passwordLabel',
	},
});

const validations = {
	validatePassword: validateField(
		'password',
		(f) => [
			validatePassword(f),
		],
	),
};



type Props = {
	cancelCallback: () => void,
	submitCallback: () => void,
};

const OnboardingPasswordForm: React.FC<Props> = (props) => {
	const {
		cancelCallback,
		submitCallback,
	} = props;

	const currentUserId = useCurrentUserId();
	const currentUserEmail = useUserEmail(currentUserId);

	const handleSubmit = React.useCallback(
		async ({ password }) => {
			await runAction({
				action: 'CreatePasswordDuringOnboarding',
				input: {
					email: currentUserEmail,
					password,
				},
			});

			submitCallback();
		},
		[
			currentUserEmail,
			submitCallback,
		],
	);

	return (
		<Form
			defaultValues={{
				password: '',
			}}
			onSuccess={handleSubmit}
			validations={validations}
		>
			{({ isValid }) => {
				return (
					<>
						<FormRow
							fullwidth={true}
							label={(
								<FormattedMessage {...messages.passwordLabel} />
							)}
						>
							<FieldStatus name="validatePassword">
								<TextField
									autoComplete={TextFieldAutocomplete.NewPassword}
									name="password"
									type={TextFieldType.Password}
									width="100%"
								/>
							</FieldStatus>
						</FormRow>

						<ButtonsLayout layout={ButtonsLayoutType.Steps}>
							<CancelButton
								onClickCallback={cancelCallback}
								visible={!isValid}
							>
								<FormattedMessage {...messages.laterLink} />
							</CancelButton>

							<SubmitButton
								nextStepIcon={true}
							>
								<FormattedMessage {...messages.nextButton} />
							</SubmitButton>
						</ButtonsLayout>
					</>
				);
			}}
		</Form>
	);
};



export default OnboardingPasswordForm;
