import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import ItemPerLineTextArea from '~/components/app/ItemPerLineTextArea';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticList from '~/components/atoms/forms/components/StaticList';

import {
	validateField,
} from '~/components/app/validations';

import {
	useManageWebsiteSuperConductorConfigurationMutation,
	useWebsiteAdminManageSuperConductorConfigurationFormQuery,
} from './WebsiteAdminManageSuperConductorConfigurationForm.gql';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';



const validations = {
	attachedWebPropertyGroupIds: validateField(
		'attachedWebPropertyGroupIds',
		(f) => [
			f.whenNonEmpty(),
			f.custom({
				message: 'Web Property Group IDs must be list of integer values.',
				rule: ({ value }) => {
					return value.every((currentValue) => {
						return /^\d+$/.test(currentValue);
					});
				},
			}),
		],
	),
};



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminManageSuperConductorConfigurationForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const isAllowedToManageSuperConductorConfiguration = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageInternals,
	);

	const { data } = useWebsiteAdminManageSuperConductorConfigurationFormQuery({
		variables: {
			websiteId,
		},
	});

	const [manageWebsiteSuperConductorConfiguration] = useManageWebsiteSuperConductorConfigurationMutation();

	const handleSubmit = React.useCallback(
		(values) => {
			return manageWebsiteSuperConductorConfiguration({
				variables: {
					attachedWebPropertyGroupIds: values.attachedWebPropertyGroupIds,
					websiteId,
				},
			});
		},
		[
			manageWebsiteSuperConductorConfiguration,
			websiteId,
		],
	);

	const attachedWebPropertyGroupIds = data?.website?.adminSettings?.attachedWebPropertyGroupIds ?? null;

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageSuperConductorConfiguration}
			isForAdmins={true}
			title="SuperConductor configuration"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Attached Web Property Group Ids">
						<StaticList focusTarget="attachedWebPropertyGroupIds">
							{attachedWebPropertyGroupIds}
						</StaticList>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						attachedWebPropertyGroupIds,
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<FormRows>
						<FormRow
							description={(
								<>
									Place one Web Property Group Id per line.
								</>
							)}
							htmlFor="attachedWebPropertyGroupIds"
							label="Attached Web Property Group Ids"
						>
							<FieldStatus name="attachedWebPropertyGroupIds">
								<ItemPerLineTextArea
									name="attachedWebPropertyGroupIds"
									size={{
										rows: 6,
										width: '100%',
									}}
								/>
							</FieldStatus>
						</FormRow>
					</FormRows>

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default WebsiteAdminManageSuperConductorConfigurationForm;
