import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



export const OPERATOR_AND = 'and';
export const OPERATOR_AND_IN = 'and in';
export const OPERATOR_AND_NOT = 'and not';
export const OPERATOR_AND_NOT_IN = 'and not in';
export const OPERATOR_NOT = 'not';
export const OPERATOR_NOT_IN = 'not in';
export const OPERATOR_OR = 'or';
export const OPERATOR_OR_IN = 'or in';
export const OPERATOR_OR_NOT = 'or not';
export const OPERATOR_OR_NOT_IN = 'or not in';



const messages = defineMessages({
	[OPERATOR_AND]: {
		id: 'ui.segments.operation.and',
	},
	[OPERATOR_AND_IN]: {
		id: 'ui.segments.operation.andIn',
	},
	[OPERATOR_AND_NOT]: {
		id: 'ui.segments.operation.andNot',
	},
	[OPERATOR_AND_NOT_IN]: {
		id: 'ui.segments.operation.andNotIn',
	},
	[OPERATOR_NOT]: {
		id: 'ui.segments.operation.not',
	},
	[OPERATOR_NOT_IN]: {
		id: 'ui.segments.operation.notIn',
	},
	[OPERATOR_OR]: {
		id: 'ui.segments.operation.or',
	},
	[OPERATOR_OR_IN]: {
		id: 'ui.segments.operation.orIn',
	},
	[OPERATOR_OR_NOT]: {
		id: 'ui.segments.operation.orNot',
	},
	[OPERATOR_OR_NOT_IN]: {
		id: 'ui.segments.operation.orNotIn',
	},
});



class SegmentFilterOperator extends Component {

	render() {
		const {
			operator,
		} = this.props;

		return (
			<div className="segment-filter-operator">
				<FormattedMessage {...messages[operator]} />
			</div>
		);
	}

}

SegmentFilterOperator.propTypes = {
	operator: PropTypes.oneOf([
		OPERATOR_AND,
		OPERATOR_AND_IN,
		OPERATOR_AND_NOT,
		OPERATOR_AND_NOT_IN,
		OPERATOR_NOT,
		OPERATOR_NOT_IN,
		OPERATOR_OR,
		OPERATOR_OR_IN,
		OPERATOR_OR_NOT,
		OPERATOR_OR_NOT_IN,
	]).isRequired,
};



export default SegmentFilterOperator;
