import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonWithDropdown, {
	type ButtonWithDropdownAction,
	ButtonWithDropdownIconType,
	ButtonWithDropdownStyle,
} from '~/components/app/ButtonWithDropdown';
import Copy from '~/components/logic/Copy';

import useAccountId from '~/hooks/useAccountId';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountState from '~/hooks/useAccountState';
import useAccountType from '~/hooks/useAccountType';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useUrlState from '~/hooks/useUrlState';



const messages = defineMessages({
	buttonLabel: {
		id: 'ui.teamDetail.members.inviteMember',
	},
	dropdownAddSingle: {
		id: 'ui.teamDetail.members.inviteMember.dropdown.inviteSingleMember',
	},
	dropdownAddMultiple: {
		id: 'ui.teamDetail.members.inviteMember.dropdown.inviteMultipleMembers',
	},
	managedViaConductorTooltip: {
		id: 'ui.general.notAllowedBecauseManagedViaConductor',
	},
});



const AddMemberHeaderButton: React.FC = () => {
	const accountId = useAccountId();
	const accountPhase = useAccountPhase(accountId);
	const accountState = useAccountState(accountId);
	const accountType = useAccountType(accountId);
	const urlState = useUrlState();

	const isAllowed = useIsAllowedWithAccount(accountId, GraphQL.ActionWithAccount.InviteMember);
	const isTrialExpired = accountPhase === GraphQL.AccountPhase.Trial && accountState === GraphQL.AccountState.Expired;

	const allowBulk = (
		accountType !== GraphQL.AccountType.EndUser
		&& isTrialExpired === false
		&& isAllowed.yes === true
	);

	const disabled = (
		isTrialExpired === true
		|| isAllowed.no === true
	);

	const actions: Array<ButtonWithDropdownAction> = [
		{
			label: (
				<FormattedMessage {...messages.dropdownAddSingle} />
			),
			linkRouteName: 'account.members.new',
			linkRouteParams: {
				accountId,
				view: urlState.params.view,
			},
		},
	];

	if (allowBulk) {
		actions.push({
			label: (
				<FormattedMessage {...messages.dropdownAddMultiple} />
			),
			linkRouteName: 'account.members.new',
			linkRouteParams: {
				accountId,
				bulk: true,
				view: urlState.params.view,
			},
		});
	}

	return (
		<ButtonWithDropdown
			actions={actions}
			iconType={ButtonWithDropdownIconType.Plus}
			isDisabled={disabled}
			label={(
				<FormattedMessage {...messages.buttonLabel} />
			)}
			style={ButtonWithDropdownStyle.Action}
			tooltip={isAllowed.noBecauseManagedViaConductor && (
				<Copy {...messages.managedViaConductorTooltip} />
			)}
		/>
	);
};



export default AddMemberHeaderButton;
