import classNames from 'classnames';
import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import RichText from '~/components/patterns/typography/RichText';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';



export enum CalloutMessageSize {
	Default = 'default',
	Small = 'small',
}

export enum CalloutMessageStatus {
	Critical = 'critical',
	Normal = 'normal',
	Success = 'success',
	Summary = 'summary',
	Warning = 'warning',
}

type Props = {
	/** Show borders around message */
	borders?: boolean,
	/** Alignment of text content */
	centeredContent?: boolean,
	children?: React.ReactNode,
	/** Possibility to disable borders highlight (depends on state) */
	highlightedBorders?: boolean,
	/** Main message */
	message: React.ReactNode,
	/** Size of message and text content */
	size?: CalloutMessageSize,
	/** Highlighted status of mentioned message */
	status?: CalloutMessageStatus,
};



const statusIconMapping = {
	[CalloutMessageStatus.Critical]: (
		<StatusFlag status={StatusFlagStatus.Critical} />
	),
	[CalloutMessageStatus.Success]: (
		<StatusFlag status={StatusFlagStatus.Success} />
	),
	[CalloutMessageStatus.Summary]: (
		<BasicIcon
			color="#5893eb"
			size={22}
			type={BasicIconType.Summary}
		/>
	),
	[CalloutMessageStatus.Warning]: (
		<StatusFlag status={StatusFlagStatus.Warning} />
	),
};



const CalloutMessage: React.FC<Props> = (props) => {
	const {
		borders = false,
		centeredContent = false,
		highlightedBorders = true,
		children,
		message,
		size = CalloutMessageSize.Default,
		status,
	} = props;

	const componentClasses = classNames({
		'callout': true,
		'callout--has-center-aligned-content': centeredContent,
		'callout--has-left-aligned-content': !centeredContent,
		'callout--small-size': size === CalloutMessageSize.Small,
	});

	let icon;

	if (status) {
		icon = statusIconMapping[status];
	}

	const messages = Array.isArray(message) ? message : [message];

	let component = (
		<div className={componentClasses}>
			{messages.map((messageItem, index) => (
				<div
					className="callout__message"
					key={'callout-' + index}
				>
					{icon && (
						<span className="callout__icon">
							{icon}
						</span>
					)}
					<span className="callout__message-body">
						{messageItem}
					</span>
				</div>
			))}

			{children && (
				<div className="callout__description">
					<RichText>
						{children}
					</RichText>
				</div>
			)}
		</div>
	);

	if (borders) {
		const additionalProps = {};

		if (highlightedBorders && status === CalloutMessageStatus.Critical) {
			additionalProps['borderColor'] = 'rgba(255, 89, 89, 0.5)'; // #ff5959 with 50% opacity
			additionalProps['background'] = '#fffcfc'; // mix of white and #ff5959 with 2% opacity
		}

		if (highlightedBorders && status === CalloutMessageStatus.Success) {
			additionalProps['borderColor'] = 'rgba(137, 195, 46, 0.5)'; // #89c32e with 50% opacity
			additionalProps['background'] = '#f8faf8'; // mix of white and #89c32e with 2% opacity
		}

		if (highlightedBorders && status === CalloutMessageStatus.Summary) {
			additionalProps['borderColor'] = 'rgba(54,123,226, 0.5)'; // #367be2 with 50% opacity
			additionalProps['background'] = '#f7fafe'; // mix of white and #367be2 with 4% opacity
		}

		if (highlightedBorders && status === CalloutMessageStatus.Warning) {
			additionalProps['borderColor'] = 'rgba(255, 116, 59, 0.5)'; // #ff743b with 50% opacity
			additionalProps['background'] = '#fffcfb'; // mix of white and #ff743b with 2% opacity
		}

		component = (
			<SimpleBox
				paddingSize={size === CalloutMessageSize.Small ? 1 : 2}
				{...additionalProps}
			>
				{component}
			</SimpleBox>
		);
	}

	return component;
};



export default CalloutMessage;
