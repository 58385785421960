import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BackButton from '~/components/app/BackButton';
import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CodeValue, {
	CodeValueWrapping,
} from '~/components/patterns/values/CodeValue';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import CreateLogFileAnalysisS3BucketForm from '~/components/app/CreateLogFileAnalysisS3BucketForm';
import GraphQL from '~/types/graphql';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import ModalPanel, {
	SIZE_XXLARGE as MODAL_SIZE_XXLARGE,
} from '~/components/atoms/panels/ModalPanel';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModal from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';
import Spinner, {
	SpinnerSize,
} from '~/components/patterns/loaders/Spinner';
import WidthLimiter from '~/components/patterns/utils/WidthLimiter';

import {
	useCloudflareLogpushVerificationLazyQuery,
} from '~/components/app/InstallCloudflareLogpushModal.gql';

import useModals from '~/hooks/useModals';



const messages = defineMessages({
	confirmation: {
		id: 'ui.installCloudflareLogpushModal.confirmation',
	},
	description: {
		id: 'ui.installCloudflareLogpushModal.description',
	},
	ok: {
		id: 'ui.general.ok',
	},
	oneMoreThing: {
		id: 'ui.installCloudflareLogpushModal.oneMoreThing',
	},
	ownershipToken: {
		id: 'ui.installCloudflareLogpushModal.ownershipToken',
	},
	sidebar: {
		id: 'ui.installCloudflareLogpushModal.sidebar',
	},
	title: {
		id: 'ui.installCloudflareLogpushModal.title',
	},
	verification: {
		id: 'ui.installCloudflareLogpushModal.verification',
	},
});



const InstallCloudflareLogpushModal: React.FC = () => {
	const modals = useModals();

	const [getCloudflareLogpushVerification, { data, stopPolling }] = useCloudflareLogpushVerificationLazyQuery();
	const ownershipToken = data?.cloudflareLogpushVerificationForS3Bucket ?? null;

	const loadCloudflareLogpushVerification = React.useCallback(
		(bucket: GraphQL.LogFileAnalysisS3Bucket) => {
			getCloudflareLogpushVerification({
				variables: {
					bucketName: bucket.name,
				},
				pollInterval: 1_000,
				onCompleted: (data) => {
					if (data.cloudflareLogpushVerificationForS3Bucket !== null) {
						stopPolling();
					}
				},
			});
		},
		[
			getCloudflareLogpushVerification,
			stopPolling,
		],
	);

	return (
		<ModalPanel
			onCloseCallback={modals.closeCallback}
			size={MODAL_SIZE_XXLARGE}
		>
			<MultiStepModal>
				<MultiStepModalStep
					name="create-bucket"
					sidebar={(
						<RichText>
							<Copy
								{...messages.sidebar}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-logpush-integration/#2-configuring-cloudflare-logpush'),
								}}
							/>
						</RichText>
					)}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<>
						<ModalTextSection>
							<RichText>
								<Copy
									{...messages.description}
									values={{
										linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-logpush-integration/#2-configuring-cloudflare-logpush'),
									}}
								/>
							</RichText>
						</ModalTextSection>

						<CreateLogFileAnalysisS3BucketForm
							loadCloudflareLogpushVerification={loadCloudflareLogpushVerification}
							logSourceProvider={GraphQL.LogSourceProvider.CloudflareLogpush}
						/>
					</>
				</MultiStepModalStep>

				<MultiStepModalStep
					name="ownership-token"
					sidebar={(
						<RichText>
							<Copy
								{...messages.sidebar}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-logpush-integration/#2-configuring-cloudflare-logpush'),
								}}
							/>
						</RichText>
					)}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					{({ goToNextStep }) => (
						<>
							<ModalTextSection>
								<RichText>
									<Copy
										{...messages.verification}
										values={{
											linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-logpush-integration/#2-configuring-cloudflare-logpush'),
										}}
									/>
								</RichText>
							</ModalTextSection>

							<ModalContentSection>
								<WidthLimiter width={580}>
									<LabeledValuesBox
										items={[
											{
												label: <FormattedMessage {...messages.ownershipToken} />,
												value: (
													ownershipToken === null
														? <Spinner size={SpinnerSize.Small} />
														: <CodeValue wrapping={CodeValueWrapping.Anywhere}>{ownershipToken}</CodeValue>
												),
											},
										]}
										labelWidth="30%"
									/>
								</WidthLimiter>
							</ModalContentSection>

							<ButtonsLayout>
								<BackButton />

								<Button
									disabled={ownershipToken === null}
									onClick={() => goToNextStep()}
									style={ButtonStyle.Action}
									width={ButtonWidth.SubmitButton}
								>
									<FormattedMessage {...messages.ok} />
								</Button>
							</ButtonsLayout>
						</>
					)}
				</MultiStepModalStep>

				<MultiStepModalStep
					name="one-more-thing"
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					{({ isActive }) => (isActive && (
						<ConfirmationMessage
							animate={true}
							context={ConfirmationMessageContext.Pending}
							ctaButtons={(
								<Button
									onClick={() => modals.closeCallback()}
									style={ButtonStyle.Action}
									width={ButtonWidth.SubmitButton}
								>
									<FormattedMessage {...messages.ok} />
								</Button>
							)}
							message={(
								<FormattedMessage {...messages.oneMoreThing} />
							)}
						>
							<RichText>
								<Copy
									{...messages.confirmation}
									values={{
										linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-logpush-integration/#2-configuring-cloudflare-logpush'),
									}}
								/>
							</RichText>
						</ConfirmationMessage>
					))}
				</MultiStepModalStep>
			</MultiStepModal>
		</ModalPanel>
	);
};



export default InstallCloudflareLogpushModal;
