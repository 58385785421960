import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const UserIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<circle
				cx="12"
				cy="6.25"
				fill={color}
				r="5.5"
			/>
			<path
				d="M12,13.25a9.511,9.511,0,0,0-9.5,9.5.5.5,0,0,0,.5.5H21a.5.5,0,0,0,.5-.5A9.511,9.511,0,0,0,12,13.25Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default UserIconBuilder;

