let pendoPromise;

export const location = window.location;

export const atob = window.atob;

export const btoa = window.btoa;

export const Intercom = () => window.Intercom;

export const pendo = () => {
	if (!pendoPromise) {
		pendoPromise = new Promise((resolve) => {
			const pendoScript = document.getElementById('pendo');
			pendoScript.onload = () => {
				resolve(window.pendo);
			};

			pendoScript.setAttribute('src', pendoScript.dataset.src);
		});
	}

	return pendoPromise;
};
