import {
	useUserEmailQuery,
} from './useUserEmail.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserEmail(legacyUserId: string | null): string | null {
	const { data } = useUserPropertiesQuery(
		useUserEmailQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.email ?? null;
}



export default useUserEmail;
