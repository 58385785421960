import React from 'react';

import FlexibleBlocks from '~/components/patterns/structures/FlexibleBlocks';
import Title from '~/components/patterns/headings/Title';



type Props = {
	children?: React.ReactNode,
	/** Placeholder when children are blank */
	placeholder?: React.ReactNode,
	/** Optional title */
	title?: React.ReactNode,
};



const IncidentsOverview: React.FC<Props> = (props) => {
	const {
		children,
		placeholder,
		title,
	} = props;

	return (
		<>
			{title && (
				<Title
					indented={true}
					underlined={true}
				>
					{title}
				</Title>
			)}
			{React.Children.count(children) > 0 ? (
				<FlexibleBlocks blockMinWidth={380}>
					{children}
				</FlexibleBlocks>
			) : placeholder}
		</>
	);
};



export default IncidentsOverview;
