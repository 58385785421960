import {
	dataSelector,
} from './selectors';
import {
	selectedWebsiteIdSelector,
} from '../ui/selectors';



const selectWebsite = (state) => dataSelector(state).get(selectedWebsiteIdSelector(state));

const selectSection = (state, section) => {
	const website = selectWebsite(state);
	return website ? website.get(section) : null;
};



export const totalSelector = (state) => selectSection(state, 'total');

export const rangeSelector = (state) => selectSection(state, 'range');

export const pagesSelector = (state) => selectSection(state, 'pages');
