import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import Copy from '~/components/logic/Copy';
import DotInterfaceHint from '~/components/logic/DotInterfaceHint';

import {
	HINT_AUTOPRIORITIZATION,
} from '~/model/interfaceHints';

import {
	INTERACTION_PAGE_DETAIL_OPEN,
} from '~/model/uiInteractions';



const messages = defineMessages({
	message: {
		id: 'ui.interfaceHints.hint.lastCheckedAt',
	},
});



type Props = {
	children: React.ReactNode,
};

const AutoprioritizationHint: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<DotInterfaceHint
			conditionsAlready={[
				({ uiInteractions }) => uiInteractions.get(INTERACTION_PAGE_DETAIL_OPEN) >= 3,
			]}
			content={(
				<Copy {...messages.message} />
			)}
			iconPosition={{
				left: 42,
				top: 9,
			}}
			name={HINT_AUTOPRIORITIZATION}
		>
			{children}
		</DotInterfaceHint>
	);
};



export default React.memo(AutoprioritizationHint);
