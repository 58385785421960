import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import Copy from '../../Copy';

import {
	SEGMENT_CHANGE_TYPE_PAGES_ENTERED,
	SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT,
	SEGMENT_CHANGE_TYPE_PAGES_LEFT,
} from '~/model/alerts';

import useScopeSize from '../hooks/useScopeSize';



const messages = defineMessages({
	[SEGMENT_CHANGE_TYPE_PAGES_ENTERED]: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.pagesEntered',
	},
	[SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT]: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.pagesEnteredOrLeft',
	},
	[SEGMENT_CHANGE_TYPE_PAGES_LEFT]: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.pagesLeft',
	},
});

const anyMessages = defineMessages({
	[SEGMENT_CHANGE_TYPE_PAGES_ENTERED]: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.pagesEntered.any',
	},
	[SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT]: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.pagesEnteredOrLeft.any',
	},
	[SEGMENT_CHANGE_TYPE_PAGES_LEFT]: {
		id: 'ui.alertsConfiguration.fields.sensitivity.scenario.pagesLeft.any',
	},
});



type Props = {
	scope: string,
	percentage: number,
	segmentChangeType:
		| typeof SEGMENT_CHANGE_TYPE_PAGES_ENTERED
		| typeof SEGMENT_CHANGE_TYPE_PAGES_ENTERED_OR_LEFT
		| typeof SEGMENT_CHANGE_TYPE_PAGES_LEFT,
	websiteId: string,
};

const SegmentPercentageScenario: React.FC<Props> = (props) => {
	const {
		scope,
		percentage,
		segmentChangeType,
		websiteId,
	} = props;

	const scopeSize = useScopeSize(websiteId, scope);

	if (scopeSize === null) {
		return null;
	}

	if (percentage === 0) {
		return (
			<Copy {...anyMessages[segmentChangeType]} />
		);
	}

	const threshold = Math.max(1, Math.floor(scopeSize * percentage / 100));

	return (
		<Copy
			{...messages[segmentChangeType]}
			values={{
				percentage: percentage / 100,
				threshold,
			}}
		/>
	);
};



export default SegmentPercentageScenario;
