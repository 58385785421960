import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import IndexNowSegmentBlocklistForm from '~/components/app/IndexNowSegmentBlocklistForm';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import RichText from '~/components/patterns/typography/RichText';



const messages = defineMessages({
	description: {
		id: 'ui.indexNowSegmentConditionsModal.description',
	},
	sidebar: {
		id: 'ui.indexNowSegmentConditionsModal.sidebar',
	},
	title: {
		id: 'ui.indexNowSegmentConditionsModal.title',
	},
});



const IndexNowSegmentConditionsModal: React.FC = () => {
	return (
		<SimpleModal
			alignToTop={true}
			iconType={BasicIconType.Plus}
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							linkSupport: linkExternal('https://www.contentkingapp.com/support/realtime-indexnow'),
						}}
					/>
				</RichText>
			)}
			size={SimpleModalSize.XLarge}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ModalTextSection>
				<Copy {...messages.description} />
			</ModalTextSection>

			<IndexNowSegmentBlocklistForm />
		</SimpleModal>
	);
};



export default IndexNowSegmentConditionsModal;
