import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import Measurer from '~/utilities/Measurer';
import MultiselectListTable, {
	MultiselectFieldTableCheckedState,
} from '../components/MultiselectListTable';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticText from '~/components/atoms/forms/components/StaticText';

import useIsAllowedWithUserWebsiteAccessOracle from '~/hooks/useIsAllowedWithUserWebsiteAccessOracle';



const messages = defineMessages({
	labelAlerts: {
		id: 'ui.events.title',
	},
	labelReports: {
		id: 'ui.settings.emailNotifications.reports',
	},
	labelMember: {
		id: 'ui.settings.emailNotifications.teamMember',
	},
});



type Props = {
	isLoaded: boolean,
	membersList: ReadonlyArray<{
		label: React.ReactNode,
		name: string,
		uniqueUserId: CK.UserId,
	}>,
	name: string,
	websiteId: CK.WebsiteId | null,
};

const MembersReportsAlertsFields: React.FC<Props> = (props) => {
	const {
		isLoaded,
		membersList,
		name,
		websiteId,
	} = props;

	const isAllowedWithUserWebsiteAccessOracle = useIsAllowedWithUserWebsiteAccessOracle();
	const [rows, setRows] = React.useState<ReadonlyArray<{
		disabled: boolean,
		label: React.ReactNode,
		name: string,
	}>>([]);

	React.useEffect(
		() => {
			const rows = membersList.map(({ label, name, uniqueUserId }) => {
				const disabled = isAllowedWithUserWebsiteAccessOracle({
					action: GraphQL.ActionWithUserWebsiteAccess.ManageEmailPreferences,
					from: 'website',
					uniqueUserId,
					websiteId,
				}).no;

				return {
					disabled,
					label,
					name,
				};
			});

			setRows(rows);
		},
		[
			isAllowedWithUserWebsiteAccessOracle,
			membersList,
			websiteId,
		],
	);

	if (isLoaded === false) {
		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.labelReports} />
					)}
				>
					<StaticText>
						<SquareSkeleton maxWidth={150} />
					</StaticText>
				</FormRow>

				<FormRow
					label={(
						<FormattedMessage {...messages.labelAlerts} />
					)}
				>
					<StaticText>
						<SquareSkeleton maxWidth={150} />
					</StaticText>
				</FormRow>
			</FormRows>
		);
	}

	return (
		<FormRow
			fullwidth={true}
			htmlFor={name}
		>
			<Measurer>
				{({ containerWidth }) => (
					<MultiselectListTable
						columns={[
							{
								name: 'weeklyReports',
								label: (
									<FormattedMessage {...messages.labelReports} />
								),
							},
							{
								name: 'alerts',
								label: (
									<FormattedMessage {...messages.labelAlerts} />
								),
							},
						]}
						label={(
							<FormattedMessage {...messages.labelMember} />
						)}
						name={name}
						rows={rows}
						tableHeight={308}
						tableWidth={containerWidth}
					/>
				)}
			</Measurer>
		</FormRow>
	);
};



export default MembersReportsAlertsFields;

export {
	MultiselectFieldTableCheckedState as MembersReportsAlertsFieldsCheckedState,
};
