import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useAccountDetailedDiscounts from '~/hooks/useAccountDetailedDiscounts';
import useAccountSignup from './useAccountSignup';
import useAccountTariff from './useAccountTariff';
import useAccountTaxRate from '~/hooks/useAccountTaxRate';
import useAllowedPlans from '~/hooks/useAllowedPlans';
import useCalculateTax from '~/hooks/useCalculateTax';
import useSignupCurrency from '~/hooks/useSignupCurrency';
import useTariffs from './useTariffs';

import {
	applyBillingCycleToCost,
} from '~/model/pricing/billingCycle';

import {
	calculatePurchaseCostDetails,
	createPurchase,
} from '~/model/pricing/costs';

import {
	DETAIL_PLAN,
} from '~/model/pricing/universal';



type CalculatePrice = ({ plan }: { plan: GraphQL.AccountPlan }) => {
	billingCycle: GraphQL.Term,
	discounts: Array<any>,
	discountPercentage: number,
	discountSum: number,
	priceWithoutDiscounts: number,
	priceWithoutDiscountsMonthly: number,
	priceWithDiscounts: number,
	priceWithDiscountsMonthly: number,
	tax: number,
	taxRate: number,
	totalPrice: number,
};

type ReturnType = {
	isReady: false,
	billingCycle: null,
	calculatePrice: null,
	currency: GraphQL.Currency,
} | {
	isReady: true,
	billingCycle: GraphQL.Term,
	calculatePrice: CalculatePrice,
	currency: GraphQL.Currency,
};

function useSignupPrice(
	accountId: CK.AccountId,
	customerDetailsProvided: boolean,
	assumeVatNumber?: boolean,
): ReturnType {
	const accountDiscounts = useAccountDetailedDiscounts(accountId);
	const accountSignup = useAccountSignup(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountTax = useAccountTaxRate(accountId);
	const allowedPlans = useAllowedPlans(accountId);
	const currency = useSignupCurrency(accountId);

	const {
		billingCycle,
		customerDetails,
		defaultCountry,
		pageBundle,
	} = accountSignup;

	const country = customerDetails.country ?? defaultCountry;
	let vatNumber = customerDetails.vatNumber;

	if (!vatNumber && (assumeVatNumber || country === 'GB')) {
		vatNumber = 'foo';
	}

	const calculatedTax = useCalculateTax(
		country,
		customerDetails.state,
		vatNumber,
	);

	const {
		isReady: areTariffsReady,
		tariffs,
	} = useTariffs();

	const isReady = (
		accountDiscounts !== null
		&& accountTariff !== null
		&& allowedPlans !== null
		&& areTariffsReady
		&& billingCycle !== null
		&& currency !== null
		&& pageBundle !== null
	);

	if (!isReady) {
		return {
			isReady: false,
			billingCycle: null,
			calculatePrice: null,
			currency: GraphQL.Currency.Eur,
		};
	}

	const result = {
		isReady: true,
		billingCycle,
		currency,
	} as ReturnType;

	const calculatePrice: CalculatePrice = ({
		plan,
	}) => {
		const discounts = accountDiscounts.map((discount) => {
			const amount = {};

			if (discount.amount !== null) {
				discount.amount.forEach(({ currency, value }) => {
					amount[currency] = value / 100;
				});
			}

			const rate = (
				discount.percentage !== null
					? discount.percentage / 100
					: null
			);

			return {
				amount,
				code: discount.coupon,
				rate,
				type: discount.type,
			};
		});

		const effectiveBillingCycle = (plan === GraphQL.AccountPlan.Enterprise && !allowedPlans.includes(plan))
			? GraphQL.Term.Annually
			: billingCycle;

		const costDetails = calculatePurchaseCostDetails({
			accountType: GraphQL.AccountType.Universal,
			billingCycle: effectiveBillingCycle,
			currency,
			discounts,
			purchases: [
				createPurchase({
					details: {
						[DETAIL_PLAN]: plan,
					},
					numberOfPages: pageBundle,
				}),
			],
			tariff: accountTariff,
			tariffs,
			taxRate: customerDetailsProvided
				? (accountTax?.rate ?? 0.0)
				: (calculatedTax?.rate ?? 0.0),
			taxType: null,
		});

		let discountPercentage = 1;
		let discountSum = 0;

		costDetails.discounts.forEach((discount) => {
			if (discount.type === 'percent') {
				discountPercentage = discountPercentage * (1 - discount.rate);
			} else if (discount.type === 'dollars') {
				discountSum += discount.amount;
			}
		});

		discountPercentage = 1 - discountPercentage;

		return {
			billingCycle: effectiveBillingCycle,
			discounts: costDetails.discounts,
			discountPercentage,
			discountSum,
			priceWithoutDiscounts: costDetails.cost,
			priceWithoutDiscountsMonthly: applyBillingCycleToCost({
				baseBillingCycle: effectiveBillingCycle,
				cost: costDetails.cost,
				newBillingCycle: GraphQL.Term.Monthly,
			}),
			priceWithDiscounts: costDetails.subtotal,
			priceWithDiscountsMonthly: applyBillingCycleToCost({
				baseBillingCycle: effectiveBillingCycle,
				cost: costDetails.subtotal,
				newBillingCycle: GraphQL.Term.Monthly,
			}),
			tax: costDetails.tax,
			taxRate: costDetails.taxRate,
			totalPrice: costDetails.total,
		};
	};

	result.calculatePrice = calculatePrice;

	return result;
}



export default useSignupPrice;
