import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CheckboxField from '../../atoms/forms/components/CheckboxField';
import List from '~/components/patterns/lists/List';
import RichText from '~/components/patterns/typography/RichText';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';



const messages = defineMessages({
	checkboxLabel: {
		id: 'ui.customElements.optionalRestartTracking.checkboxLabel',
	},
	question: {
		id: 'ui.customElements.optionalRestartTracking.question',
	},
});



const DataDeletionConfirmation: React.FC = () => {
	return (
		<SimpleBox paddingSize={2}>
			<List>
				<RichText>
					<p>
						<FormattedMessage {...messages.question} />
					</p>
				</RichText>
				<CheckboxField
					label={(
						<FormattedMessage {...messages.checkboxLabel} />
					)}
					name="restart_tracking"
					width={false}
				/>
			</List>
		</SimpleBox>
	);
};



export default DataDeletionConfirmation;
