import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import APIIntegrationStatus, {
	APIIntegrationStatusService,
	APIIntegrationStatusStatus,
	APIIntegrationStatusSize as ChromeExtensionSize,
} from '~/components/patterns/statuses/APIIntegrationStatus';
import ExternalLink from '~/components/patterns/links/ExternalLink';

import useIsCurrentUser from '~/hooks/useIsCurrentUser';



const messages = defineMessages({
	installTooltipMember: {
		id: 'ui.teamDetail.members.browserExtension.installTooltip.member',
	},
	installTooltipUser: {
		id: 'ui.teamDetail.members.browserExtension.installTooltip.user',
	},
	installedStatus: {
		id: 'ui.teamDetail.members.browserExtension.installed',
	},
	notInstalledStatus: {
		id: 'ui.teamDetail.members.browserExtension.notInstalled',
	},
});



export { ChromeExtensionSize };



type Props = {
	email: string,
	isChromeExtensionInstalled: boolean,
	size?: ChromeExtensionSize,
	showLabel: boolean,
};

const ChromeExtension: React.FC<Props> = (props) => {
	const {
		email,
		isChromeExtensionInstalled,
		size,
		showLabel,
	} = props;

	const isCurrentUser = useIsCurrentUser();

	const tooltip = isCurrentUser(email)
		? 'installTooltipUser'
		: 'installTooltipMember';

	return (
		<APIIntegrationStatus
			compact={!showLabel}
			label={(
				<FormattedMessage {...messages[isChromeExtensionInstalled ? 'installedStatus' : 'notInstalledStatus']} />
			)}
			service={APIIntegrationStatusService.ChromeWebStore}
			size={size}
			status={isChromeExtensionInstalled ? APIIntegrationStatusStatus.Installed : APIIntegrationStatusStatus.NotInstalled}
			tooltip={isChromeExtensionInstalled ? null : (
				<div
					/*
						Prevent click events from bubbling up. This is needed to open the
						external link without triggering the `onClick` event higher up.
					 */
					onClickCapture={(e) => e.stopPropagation()}
				>
					<FormattedMessage
						{...messages[tooltip]}
						values={{
							link: (...chunks: Array<any>) => (
								<ExternalLink href="https://chrome.google.com/webstore/detail/contentking-real-time-seo/kelobidgacbngfmplnjckcbgpkplnbnd">
									{chunks}
								</ExternalLink>
							),
						}}
					/>
				</div>
			)}
		/>
	);
};



export default ChromeExtension;
