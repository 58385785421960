import React from 'react';

import CustomerTeamContextDetailsOverview from '~/components/app/CustomerTeamContextDetailsOverview';

import {
	useUserCustomerTeamContextDetailsQuery,
} from './UserCustomerTeamContextDetailsOverview.gql';



type Props = {
	userId: string | null,
};

const UserCustomerTeamContextDetailsOverview: React.FC<Props> = (props) => {
	const {
		userId,
	} = props;

	const { data } = useUserCustomerTeamContextDetailsQuery({
		skip: userId === null,
		variables: {
			legacyUserId: userId ?? '',
		},
	});

	const details = data?.user?.customerTeamContext?.details ?? null;

	return (
		<CustomerTeamContextDetailsOverview
			data={details}
		/>
	);
};



export default UserCustomerTeamContextDetailsOverview;
