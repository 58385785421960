import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const FemaleIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M17.982,17.853l-1.8-6.286a4.263,4.263,0,0,0-1.238-2.125.5.5,0,0,0-.764.1L12.425,12.3a.5.5,0,0,1-.846,0L9.839,9.525a.5.5,0,0,0-.763-.1,4.226,4.226,0,0,0-1.254,2.13l-1.8,6.3a.5.5,0,0,0,.482.632H8.823a.251.251,0,0,1,.246.2l.931,4.9a.5.5,0,0,0,.489.4h3a.5.5,0,0,0,.49-.4l.931-4.9a.249.249,0,0,1,.245-.2H17.5a.5.5,0,0,0,.482-.632Z"
				fill={color}
			/>
			<path
				d="M11.979,8.515a4.25,4.25,0,1,0-4.25-4.25A4.255,4.255,0,0,0,11.979,8.515Zm0-2a2.248,2.248,0,0,1-2.16-1.64.25.25,0,0,1,.16-.3A4.68,4.68,0,0,0,11.8,3.432a.25.25,0,0,1,.357,0,4.693,4.693,0,0,0,1.821,1.14.249.249,0,0,1,.159.3A2.246,2.246,0,0,1,11.979,6.515Z"
				fill={color}
			/>
			<path
				d="M17.982,17.853l-1.8-6.286a4.263,4.263,0,0,0-1.238-2.125.5.5,0,0,0-.764.1L12.425,12.3a.5.5,0,0,1-.846,0L9.839,9.525a.5.5,0,0,0-.763-.1,4.226,4.226,0,0,0-1.254,2.13l-1.8,6.3a.5.5,0,0,0,.482.632H8.823a.251.251,0,0,1,.246.2l.931,4.9a.5.5,0,0,0,.489.4h3a.5.5,0,0,0,.49-.4l.931-4.9a.249.249,0,0,1,.245-.2H17.5a.5.5,0,0,0,.482-.632Z"
				fill={color}
			/>
			<path
				d="M11.979,8.515a4.25,4.25,0,1,0-4.25-4.25A4.255,4.255,0,0,0,11.979,8.515Zm0-2a2.248,2.248,0,0,1-2.16-1.64.25.25,0,0,1,.16-.3A4.68,4.68,0,0,0,11.8,3.432a.25.25,0,0,1,.357,0,4.693,4.693,0,0,0,1.821,1.14.249.249,0,0,1,.159.3A2.246,2.246,0,0,1,11.979,6.515Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default FemaleIconBuilder;
