import React from 'react';

import type GraphQL from '~/types/graphql';

import Form from '../basis/Form';
import SwitchButtonsField, {
	SwitchButtonsFieldSize,
} from '../components/SwitchButtonsField';



type Props = {
	allowedCurrencies: ReadonlyArray<GraphQL.Currency>,
	defaultCurrency: string,
	onChangeCallback: (string) => void,
};



const CurrencySelectForm: React.FC<Props> = (props) => {
	const {
		allowedCurrencies,
		defaultCurrency,
		onChangeCallback,
	} = props;

	const options = React.useMemo(
		() => {
			return allowedCurrencies.map((currency) => ({
				label: currency,
				value: currency,
			}));
		},
		[
			allowedCurrencies,
		],
	);

	const handleChange = React.useCallback(
		(fieldName, value) => {
			if (fieldName === 'currency') {
				onChangeCallback(value);
			}
		},
		[
			onChangeCallback,
		],
	);

	return (
		<Form
			defaultValues={{
				currency: defaultCurrency,
			}}
			onChangeCallback={handleChange}
		>
			<SwitchButtonsField
				items={options}
				name="currency"
				size={SwitchButtonsFieldSize.Small}
			/>
		</Form>
	);
};



export default CurrencySelectForm;
