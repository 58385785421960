import React from 'react';

import type CK from '~/types/contentking';

import {
	useAccountIdsQuery,
} from './useAccountIds.gql';

import useInitialUserPropertiesQuery from '~/hooks/useInitialUserPropertiesQuery';



function useAccountIds(): ReadonlyArray<CK.AccountId> | null {
	const { data } = useInitialUserPropertiesQuery(
		useAccountIdsQuery,
	);

	const accounts = data?.authenticatedSession?.accounts ?? null;

	return React.useMemo(
		() => {
			return accounts !== null
				? accounts.map((account) => account.id)
				: null;
		},
		[
			accounts,
		],
	);
}



export default useAccountIds;
