import React from 'react';

import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';
import TextField from '~/components/atoms/forms/components/TextField';

import useFormContext from '~/hooks/useFormContext';



type Props = {
	name: string,
};

const TimeField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	const formContext = useFormContext();

	const updatedFormContext = React.useMemo(
		() => ({
			...formContext,
			onChangeHandler: (name, value) => {
				if (value.indexOf(':') !== -1) {
					value = value.split(':');

					if (value[0].length > 0 && value[1].length > 0) {
						if (value[0].length === 1) {
							value[0] = '0' + value[0];
						}
					}

					value = value.join(':');
				} else if (value.length === 1) {
					value = '0' + value + ':00';
				} else if (value.length === 2) {
					value = value + ':00';
				} else if (value.length === 3) {
					value = '0' + value.substr(0, 1) + ':' + value.substr(1, 2);
				} else if (value.length === 4) {
					value = value.substr(0, 2) + ':' + value.substr(2, 2);
				}

				return formContext.onChangeHandler(name, value);
			},
		}),
		[
			formContext,
		],
	);

	return (
		<FormContext.Provider value={updatedFormContext}>
			<TextField
				name={name}
				placeholder="HH:MM"
				resetButton={false}
				trimValue={true}
				width={65}
			/>
		</FormContext.Provider>
	);
};



export default TimeField;
