import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import Copy from '~/components/logic/Copy';
import DestructConfirmationModal from '~/components/app/DestructConfirmationModal';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';
import SegmentDefinitionIdentifier from '~/components/logic/segments/SegmentDefinitionIdentifier';

import {
	useDeleteSegmentMutation,
} from './DeleteSegmentConfirmationModal.gql';

import useWebsiteAlertDefinitions from '~/hooks/useWebsiteAlertDefinitions';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteScopeIssues from '~/hooks/useWebsiteScopeIssues';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	SEGMENTS_FINISH_SEGMENT_DELETION,
} from '~/actions/segments';

import {
	type SegmentDefinition,
	getSegmentDefinitionByName,
} from '~/model/segments';

import {
	filterSelector as pagesFilterSelector,
} from '~/state/ui/selectors';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	caution: {
		id: 'ui.general.caution',
	},
	cautionConnectedAlertDefinitions: {
		id: 'ui.deleteSegmentConfirmation.cautionConnectedAlertDefinitions',
	},
	cautionConnectedIgnoredIssues: {
		id: 'ui.deleteSegmentConfirmation.cautionConnectedIgnoredIssues',
	},
	cautionConnections: {
		id: 'ui.deleteSegmentConfirmation.cautionConnections',
	},
	confirmButton: {
		id: 'ui.deleteSegmentConfirmation.confirmButton',
	},
	description: {
		id: 'ui.deleteSegmentConfirmation.description',
	},
	labelFromWebsite: {
		id: 'ui.deleteSegmentConfirmation.labelFromWebsite',
	},
	labelSegment: {
		id: 'ui.deleteSegmentConfirmation.labelSegment',
	},
	title: {
		id: 'ui.deleteSegmentConfirmation.title',
	},
	verb: {
		id: 'ui.deleteSegmentConfirmation.verb',
	},
	warnPermanent: {
		id: 'ui.deleteSegmentConfirmation.warnPermanent',
	},
});



type Props = {
	segmentDefinition: SegmentDefinition,
	websiteId: CK.WebsiteId,
};

const DeleteSegmentConfirmationModal: React.FC<Props> = (props) => {
	const {
		segmentDefinition,
		websiteId,
	} = props;

	const alertDefinitions = useAlertDefinitionsScopedToSegment(websiteId, segmentDefinition);
	const dispatch = useDispatch();
	const ignoredIssues = useIssuesIgnoredForSegment(websiteId, segmentDefinition);
	const pagesFilter = useSelector(pagesFilterSelector);
	const segments = useWebsiteSegmentDefinitions(websiteId);
	const websiteDisplayName = useWebsiteDisplayName(websiteId);

	const [deleteSegment] = useDeleteSegmentMutation();

	const segmentId = segmentDefinition.id;

	const handleDeletion = React.useCallback(
		async () => {
			try {
				await deleteSegment({
					variables: {
						segmentId,
						websiteId,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}

			let lastFilter = pagesFilter;

			if (lastFilter.has('segments')) {
				lastFilter = lastFilter.setIn(
					[
						'segments',
						'included_in',
					],
					lastFilter.get('segments')
						.get('included_in')
						.filter((segmentName) => {
							const segment = getSegmentDefinitionByName(
								segments.listAll(),
								segmentName,
							);

							return segment !== null && (segment.id !== segmentId);
						}),
				);

				lastFilter = lastFilter.setIn(
					[
						'segments',
						'not_included_in',
					],
					lastFilter.get('segments')
						.get('not_included_in')
						.filter((segmentName) => {
							const segment = getSegmentDefinitionByName(
								segments.listAll(),
								segmentName,
							);

							return segment !== null && (segment.id !== segmentId);
						}),
				);
			}

			dispatch({
				type: SEGMENTS_FINISH_SEGMENT_DELETION,
				lastFilter,
				segmentId,
				websiteId,
			});
		},
		[
			deleteSegment,
			dispatch,
			pagesFilter,
			segmentId,
			segments,
			websiteId,
		],
	);

	return (
		<DestructConfirmationModal
			confirmButton={(
				<FormattedMessage {...messages.confirmButton} />
			)}
			confirmationPhrase={segmentDefinition.label}
			description={(
				<FormattedMessage {...messages.description} />
			)}
			onConfirmation={handleDeletion}
			titleAction={(
				<FormattedMessage {...messages.verb} />
			)}
			titleObject={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Copy {...messages.warnPermanent} />

			<LabeledValuesBox
				items={[
					{
						label: <FormattedMessage {...messages.labelSegment} />,
						value: (
							<SegmentDefinitionIdentifier
								segmentDefinition={segmentDefinition}
								showCriteria={false}
							/>
						),
					},
					{
						label: <FormattedMessage {...messages.labelFromWebsite} />,
						value: websiteDisplayName,
					},
				]}
			/>

			{(alertDefinitions > 0 && ignoredIssues > 0) ? (
				<CalloutMessage
					borders={true}
					message={<FormattedMessage {...messages.caution} />}
					status={CalloutMessageStatus.Warning}
				>
					<FormattedMessage
						{...messages.cautionConnections}
						values={{
							alertDefinitions,
							ignoredIssues,
						}}
					/>
				</CalloutMessage>
			) : (alertDefinitions > 0) ? (
				<CalloutMessage
					borders={true}
					message={<FormattedMessage {...messages.caution} />}
					status={CalloutMessageStatus.Warning}
				>
					<FormattedMessage
						{...messages.cautionConnectedAlertDefinitions}
						values={{
							alertDefinitions,
						}}
					/>
				</CalloutMessage>
			) : (ignoredIssues > 0) ? (
				<CalloutMessage
					borders={true}
					message={<FormattedMessage {...messages.caution} />}
					status={CalloutMessageStatus.Warning}
				>
					<FormattedMessage
						{...messages.cautionConnectedIgnoredIssues}
						values={{
							ignoredIssues,
						}}
					/>
				</CalloutMessage>
			) : (
				null
			)}
		</DestructConfirmationModal>
	);
};



function useAlertDefinitionsScopedToSegment(
	websiteId: CK.WebsiteId,
	segmentDefinition: SegmentDefinition,
): number {
	const alertDefinitions = useWebsiteAlertDefinitions(websiteId);

	return React.useMemo(
		() => {
			if (alertDefinitions.isLoaded === false) {
				return 0;
			}

			const segmentScope = `segment:${segmentDefinition.name}`;

			return alertDefinitions
				.listAll()
				.filter((alertDefinition) => alertDefinition.scope === segmentScope)
				.length;
		},
		[
			alertDefinitions,
			segmentDefinition,
		],
	);
}



function useIssuesIgnoredForSegment(
	websiteId: CK.WebsiteId,
	segmentDefinition: SegmentDefinition,
): number {
	const websiteScopeIssues = useWebsiteScopeIssues(websiteId);

	return React.useMemo(
		() => {
			if (websiteScopeIssues === null) {
				return 0;
			}

			const segmentScope = `segment/${segmentDefinition.name}`;

			const scopeIssues = websiteScopeIssues.find(
				(scope) => scope.name === segmentScope,
			);

			if (scopeIssues === undefined) {
				return 0;
			}

			return scopeIssues.issueCategories.reduce(
				(totalIgnoredIssues, issueCategory) => {
					const ignoredIssuesInCategory = issueCategory.issues.filter((issue) => {
						if (issue.ignoringRuleOnSegments === null) {
							return false;
						}

						return issue.ignoringRuleOnSegments.ignoredSegments.includes(
							segmentDefinition.name,
						);
					});

					return totalIgnoredIssues + ignoredIssuesInCategory.length;
				},
				0,
			);
		},
		[
			segmentDefinition,
			websiteScopeIssues,
		],
	);
}



export default DeleteSegmentConfirmationModal;
