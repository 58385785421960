import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	no: {
		id: 'ui.contentOverview.allowed.yes', // intentional reverse (value is inversed but message isn't)
	},
	yes: {
		id: 'ui.contentOverview.allowed.no',
	},
});



type Props = {
	value: boolean,
};

const IsDisallowedFormatter: React.FC<Props> = (props) => {
	const {
		value,
	} = props;

	return (
		<FormattedMessage {...messages[value ? 'yes' : 'no']} />
	);
};



export default React.memo(IsDisallowedFormatter);
