import type CK from '~/types/contentking';

import {
	usePageNumberOfIncomingInternalLinksQuery,
} from './usePageNumberOfIncomingInternalLinks.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageNumberOfIncomingInternalLinks(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageNumberOfIncomingInternalLinksQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.numberOfIncomingInternalLinks ?? null;
}



export default usePageNumberOfIncomingInternalLinks;
