import React from 'react';

import RadioList, {
	ITEM_STYLE_TAG,
	SIZE_SMALL,
} from '~/components/atoms/forms/components/RadioList';



type Props = {
	isControlled?: boolean,
	itemMaxWidth?: number,
	items: Array<{
		label: React.ReactNode,
		value: string,
		disabled?: boolean,
	}>,
	name: string,
	onChangeCallback?: () => void,
};



const RadioTagsList: React.FC<Props> = (props) => {
	const {
		isControlled = false,
		itemMaxWidth,
		items,
		name,
		onChangeCallback,
	} = props;

	return (
		<RadioList
			inline={true}
			isControlled={isControlled}
			itemMaxWidth={itemMaxWidth}
			itemStyle={ITEM_STYLE_TAG}
			items={items}
			name={name}
			onChangeCallback={onChangeCallback}
			size={SIZE_SMALL}
			width={false}
		/>
	);
};



export default RadioTagsList;
