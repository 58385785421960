import classNames from 'classnames';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	/** Callback triggered when clicking on overlay */
	onOverlayClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
	/** Overlay placed over text field inside */
	overlay?: React.ReactNode,
};



const TextFieldOverlay: React.FC<Props> = (props) => {
	const {
		children,
		onOverlayClick,
		overlay,
	} = props;

	return (
		<div
			className={classNames({
				'text-field-overlay': true,
				'text-field-overlay--disabled-content': overlay,
			})}
		>
			{overlay && (
				<div
					className="text-field-overlay__overlay"
					onClick={onOverlayClick}
				>
					{overlay}
				</div>
			)}
			{children}
		</div>
	);
};



export default TextFieldOverlay;
