import Immutable from 'immutable';
import memoize from 'memoizee';
import React from 'react';
import {
	useSelector,
} from 'react-redux';

import PagesHistoricalViewDataTable from './PagesHistoricalViewDataTable';

import useCurrentColumns from '~/hooks/useCurrentColumns';
import useEnabledWebsiteColumnsList from '~/hooks/useEnabledWebsiteColumnsList';
import useLoadTrackedChangesOnPages from '~/hooks/useLoadTrackedChangesOnPages';
import useResetTrackedChangesFilter from '~/hooks/useResetTrackedChangesFilter';
import useUpdateTrackedChangesFilter from '~/hooks/useUpdateTrackedChangesFilter';
import useUpdateTrackedChangesSortBy from '~/hooks/useUpdateTrackedChangesSortBy';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	copyUrlToClipboardItem,
	openPageDetailItem,
	openPageOnWebsiteItem,
} from '~/model/contextMenu';

import {
	CHANGES_IN_ALL_SELECTED_COLUMNS,
	CHANGES_IN_ANY_COLUMN,
	CHANGES_IN_ANY_SELECTED_COLUMN,
	CHANGES_IN_ANY_VISIBLE_COLUMN,
	CHANGE_TYPE_ADDED,
	CHANGE_TYPE_CHANGED,
	CHANGE_TYPE_OTHER,
	CHANGE_TYPE_REDIRECTED,
	CHANGE_TYPE_REMOVED,
	getVisibleColumns,
} from '~/model/historicalChanges';

import {
	TYPE_MISSING,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	TYPE_UNREACHABLE,
} from '~/model/pages';

import {
	isCustomElementColumn,
	isEnrichmentFieldColumn,
} from '~/model/pagesColumns';

import {
	dateIntervalSelector,
	filterSelector,
	filteredSelector as filteredHistoricalChangesSelector,
	loadingSelector,
	rawSelector as rawHistoricalChangesSelector,
	sortBySelector,
	totalSelector,
} from '~/state/historicalChanges/selectors';

import {
	peekedColumnsSelector,
} from '~/state/changeTrackingPeekedColumns/selectors';

import goTo from '~/routing/goTo';
import {
	getRouter,
} from '~/routing/router';



const produceVisibleHistoricalChanges = memoize((filteredHistoricalChanges, rawHistoricalChanges) => {
	return filteredHistoricalChanges.map((id) => {
		return rawHistoricalChanges.get(id);
	});
});



const PagesHistoricalView: React.FC = () => {
	const websiteId = useWebsiteId();

	const columns = useEnabledWebsiteColumnsList();
	const currentColumns = useCurrentColumns();
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const filter = useSelector(filterSelector);
	const filteredHistoricalChanges = useSelector(filteredHistoricalChangesSelector);
	const historicalDateInterval = useSelector(dateIntervalSelector);
	const loadTrackedChangesOnPages = useLoadTrackedChangesOnPages();
	const loading = useSelector(loadingSelector);
	const peekedColumns = useSelector(peekedColumnsSelector);
	const rawHistoricalChanges = useSelector(rawHistoricalChangesSelector);
	const resetTrackedChangesFilter = useResetTrackedChangesFilter();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const sortBy = useSelector(sortBySelector);
	const total = useSelector(totalSelector);
	const updateTrackedChangesFilter = useUpdateTrackedChangesFilter();
	const updateTrackedChangesSortBy = useUpdateTrackedChangesSortBy();

	const historicalChanges = React.useMemo(
		() => produceVisibleHistoricalChanges(
			filteredHistoricalChanges,
			rawHistoricalChanges,
		),
		[
			filteredHistoricalChanges,
			rawHistoricalChanges,
		],
	);

	const originalFilter = filter;

	React.useEffect(
		() => {
			if (columns.size > 0) {
				loadTrackedChangesOnPages();
			}
		},
		[
			columns.size,
			loadTrackedChangesOnPages,
		],
	);

	const contextMenuEntries = React.useCallback(
		({ row }) => {
			const routeName = row.get('change_type') === 'changed'
				? 'website.pages.detail.history'
				: 'website.pages.detail';

			const routeParams = {
				id: row.get('id'),
				websiteId,
			};

			if (row.get('change_type') === 'changed') {
				routeParams['start-date'] = historicalDateInterval.get('startDate');
				routeParams['end-date'] = historicalDateInterval.get('endDate');
			}

			const pageDetailUrl = getRouter().buildPath(routeName, routeParams);

			return [
				openPageDetailItem(pageDetailUrl),
				openPageOnWebsiteItem(row.get('url')),
				copyUrlToClipboardItem(row.get('url')),
			];
		},
		[
			historicalDateInterval,
			websiteId,
		],
	);

	const handleFilterChange = React.useCallback(
		(filter) => {
			if (filter.segments) {
				filter.segments = Immutable.fromJS(filter.segments);
			}

			if (filter.canonical_type && filter.canonical_type.length) {
				const canonicalTypeFilter: Array<string> = [];

				if (filter.canonical_type.indexOf('crossdomain') !== -1) {
					canonicalTypeFilter.push('crossdomain');
				}

				if (filter.canonical_type.indexOf('internal_self') !== -1) {
					canonicalTypeFilter.push('internal_self');
				}

				if (filter.canonical_type.indexOf('internal_other') !== -1) {
					canonicalTypeFilter.push('internal_other');
				}

				if (filter.canonical_type.indexOf('invalid') !== -1) {
					canonicalTypeFilter.push('invalid');
				}

				if (filter.canonical_type.indexOf('none') !== -1) {
					canonicalTypeFilter.push('none');
				}

				if (filter.canonical_type.indexOf('not_applicable') !== -1) {
					canonicalTypeFilter.push('not_applicable');
				}

				filter.canonical_type = canonicalTypeFilter;
			}

			if (filter.change_type && filter.change_type.length) {
				const changeTypeFilter: Array<string> = [];

				if (filter.change_type.indexOf('added') !== -1) {
					changeTypeFilter.push(CHANGE_TYPE_ADDED);
				}

				if (filter.change_type.indexOf('other') !== -1) {
					changeTypeFilter.push(CHANGE_TYPE_OTHER);
				}

				if (filter.change_type.indexOf('redirected') !== -1) {
					changeTypeFilter.push(CHANGE_TYPE_REDIRECTED);
				}

				if (filter.change_type.indexOf('removed') !== -1) {
					changeTypeFilter.push(CHANGE_TYPE_REMOVED);
				}

				filter.changes_in_columns = null;

				if (filter.change_type.indexOf('changed_in_any_column') !== -1) {
					changeTypeFilter.push(CHANGE_TYPE_CHANGED);
					filter.changes_in = CHANGES_IN_ANY_COLUMN;
				} else if (filter.change_type.indexOf('changed_in_any_visible_column') !== -1) {
					changeTypeFilter.push(CHANGE_TYPE_CHANGED);
					filter.changes_in = CHANGES_IN_ANY_VISIBLE_COLUMN;
				} else if (filter.change_type.indexOf('changed_in_any_selected_column') !== -1) {
					changeTypeFilter.push(CHANGE_TYPE_CHANGED);
					filter.changes_in = CHANGES_IN_ANY_SELECTED_COLUMN;
					filter.changes_in_columns = originalFilter.get('changes_in_columns') ? originalFilter.get('changes_in_columns').toArray() : [];
				} else if (filter.change_type.indexOf('changed_in_all_selected_columns') !== -1) {
					changeTypeFilter.push(CHANGE_TYPE_CHANGED);
					filter.changes_in = CHANGES_IN_ALL_SELECTED_COLUMNS;
					filter.changes_in_columns = originalFilter.get('changes_in_columns') ? originalFilter.get('changes_in_columns').toArray() : [];
				} else {
					filter.changes_in = null;
				}

				filter.change_type = changeTypeFilter;
			}

			if (filter.type && filter.type.length) {
				const typeFilter: Array<string> = [];

				if (filter.type.indexOf(TYPE_PAGE) !== -1) {
					typeFilter.push(TYPE_PAGE);
				}

				if (filter.type.indexOf(TYPE_REDIRECT) !== -1) {
					typeFilter.push(TYPE_REDIRECT);
				}

				if (filter.type.indexOf(TYPE_MISSING) !== -1) {
					typeFilter.push(TYPE_MISSING);
				}

				if (filter.type.indexOf(TYPE_SERVER_ERROR) !== -1) {
					typeFilter.push(TYPE_SERVER_ERROR);
				}

				if (filter.type.indexOf(TYPE_UNREACHABLE) !== -1) {
					typeFilter.push(TYPE_UNREACHABLE);
				}

				filter.type = typeFilter;
			}

			if (filter.is_secured && filter.is_secured.length) {
				const isSecuredFilter: Array<boolean> = [];

				if (filter.is_secured.indexOf('true') !== -1) {
					isSecuredFilter.push(true);
				}

				if (filter.is_secured.indexOf('false') !== -1) {
					isSecuredFilter.push(false);
				}

				filter.is_secured = isSecuredFilter;
			}

			if (filter.is_linked && filter.is_linked.length) {
				const isLinkedFilter: Array<boolean> = [];

				if (filter.is_linked.indexOf('true') !== -1) {
					isLinkedFilter.push(true);
				}

				if (filter.is_linked.indexOf('false') !== -1) {
					isLinkedFilter.push(false);
				}

				filter.is_linked = isLinkedFilter;
			}

			if (filter.is_disallowed_in_robots_txt && filter.is_disallowed_in_robots_txt.length) {
				const isDisallowedInRobotsTxt: Array<boolean> = [];

				if (filter.is_disallowed_in_robots_txt.indexOf('true') !== -1) {
					isDisallowedInRobotsTxt.push(true);
				}

				if (filter.is_disallowed_in_robots_txt.indexOf('false') !== -1) {
					isDisallowedInRobotsTxt.push(false);
				}

				filter.is_disallowed_in_robots_txt = isDisallowedInRobotsTxt;
			}

			if (filter.is_indexable && filter.is_indexable.length) {
				const isIndexableFilter: Array<boolean> = [];

				if (filter.is_indexable.indexOf('true') !== -1) {
					isIndexableFilter.push(true);
				}

				if (filter.is_indexable.indexOf('false') !== -1) {
					isIndexableFilter.push(false);
				}

				filter.is_indexable = isIndexableFilter;
			}

			if (filter.is_indexable_due_to_meta_robots && filter.is_indexable_due_to_meta_robots.length) {
				const isIndexableDueToMetaRobotsFilter: Array<string> = [];

				if (filter.is_indexable_due_to_meta_robots.indexOf('yes') !== -1) {
					isIndexableDueToMetaRobotsFilter.push('yes');
				}

				if (filter.is_indexable_due_to_meta_robots.indexOf('no') !== -1) {
					isIndexableDueToMetaRobotsFilter.push('no');
				}

				if (filter.is_indexable_due_to_meta_robots.indexOf('not_applicable') !== -1) {
					isIndexableDueToMetaRobotsFilter.push('not_applicable');
				}

				filter.is_indexable_due_to_meta_robots = isIndexableDueToMetaRobotsFilter;
			}

			if (filter.is_indexable_due_to_x_robots_tag && filter.is_indexable_due_to_x_robots_tag.length) {
				const isIndexableDueToXRobotsTag: Array<string> = [];

				if (filter.is_indexable_due_to_x_robots_tag.indexOf('yes') !== -1) {
					isIndexableDueToXRobotsTag.push('yes');
				}

				if (filter.is_indexable_due_to_x_robots_tag.indexOf('no') !== -1) {
					isIndexableDueToXRobotsTag.push('no');
				}

				if (filter.is_indexable_due_to_x_robots_tag.indexOf('not_applicable') !== -1) {
					isIndexableDueToXRobotsTag.push('not_applicable');
				}

				filter.is_indexable_due_to_x_robots_tag = isIndexableDueToXRobotsTag;
			}

			updateTrackedChangesFilter(filter);
		},
		[
			originalFilter,
			updateTrackedChangesFilter,
		],
	);

	const handleOpenDetail = React.useCallback(
		(page, e) => {
			const routeName = page.get('change_type') === 'changed'
				? 'website.pages.detail.history'
				: 'website.pages.detail';

			const routeParams = {
				websiteId,
				id: page.get('id'),
			};

			if (page.get('change_type') === 'changed') {
				routeParams['start-date'] = historicalDateInterval.get('startDate');
				routeParams['end-date'] = historicalDateInterval.get('endDate');
			}

			goTo(e, routeName, routeParams);
		},
		[
			historicalDateInterval,
			websiteId,
		],
	);

	const handleSelectedColumnsChange = React.useCallback(
		(selectedColumns) => {
			updateTrackedChangesFilter({
				...filter.toJS(),
				changes_in_columns: selectedColumns,
			});
		},
		[
			filter,
			updateTrackedChangesFilter,
		],
	);

	let finalAllColumns = currentColumns.map((column) => column.name);

	finalAllColumns = finalAllColumns.filter(
		(column) => isCustomElementColumn(column) === false || customElementDefinitions.getByColumn(column) !== null,
	);

	finalAllColumns = finalAllColumns.filter(
		(column) => isEnrichmentFieldColumn(column) === false || enrichmentFieldDefinitions.getByColumn(column) !== null,
	);

	const finalEnabledColumns = columns;

	return (
		<PagesHistoricalViewDataTable
			columns={getVisibleColumns({
				allColumns: finalAllColumns,
				enabledColumns: finalEnabledColumns,
				peekedColumns,
			})}
			contextMenuEntries={contextMenuEntries}
			dataLoading={loading}
			enabledColumns={finalEnabledColumns}
			filter={filter}
			filterCallback={handleFilterChange}
			historicalChanges={historicalChanges}
			onOffsetChange={loadTrackedChangesOnPages}
			onRowClick={handleOpenDetail}
			onSelectedColumnsChange={handleSelectedColumnsChange}
			peekedColumns={peekedColumns}
			resetFilter={resetTrackedChangesFilter}
			segmentDefinitions={segmentDefinitions.listAll()}
			sortBy={sortBy}
			sortCallback={updateTrackedChangesSortBy}
			total={total || 0}
		/>
	);
};



export default PagesHistoricalView;
