import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import TextField from '~/components/atoms/forms/components/TextField';
import StandaloneFieldRow from '~/components/atoms/forms/basis/StandaloneFieldRow';
import SubmitButton from '~/components/app/SubmitButton';

import {
	validateFields,
} from '~/components/app/validations';

import {
	useSetNameDuringOnboardingMutation,
} from './OnboardingNameForm.gql';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';
import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	firstNameLabel: {
		id: 'ui.onboarding.step1.firstNameField.label',
	},
	lastNameLabel: {
		id: 'ui.onboarding.step1.lastNameField.label',
	},
	nextButton: {
		id: 'ui.onboarding.step1.nextButton',
	},
	noThanks: {
		id: 'ui.onboarding.step1.noThanksLink',
	},
});

const validations = {
	validateFirstAndLastName: validateFields(
		['firstName', 'lastName'],
		{
			firstName: (f) => [
				f.validateNonEmpty(),
				f.validateMaximumLength(100),
			],
			lastName: (f) => [
				f.validateNonEmpty(),
				f.validateMaximumLength(100),
			],
		},
	),
};



type Props = {
	onCancelCallback: () => void,
	onSubmitCallback: () => void,
};

const OnboardingNameForm: React.FC<Props> = (props) => {
	const {
		onCancelCallback,
		onSubmitCallback,
	} = props;

	const accountId = useAccountId();

	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const intl = useIntl();
	const viewportType = useViewportType();

	const [setNameDuringOnboarding] = useSetNameDuringOnboardingMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await setNameDuringOnboarding({
				variables: {
					firstName: values.firstName,
					lastName: values.lastName,
				},
			});

			onSubmitCallback();
		},
		[
			onSubmitCallback,
			setNameDuringOnboarding,
		],
	);

	return (
		<Form
			defaultValues={{
				firstName: '',
				lastName: '',
			}}
			onSuccess={handleSubmit}
			validations={validations}
		>
			{({ isValid }) => (
				<>
					{viewportType.isSmall ? (
						<CenteredFormWrapper>
							<StandaloneFieldRow>
								<TextField
									name="firstName"
									placeholder={intl.formatMessage(messages.firstNameLabel)}
									trimValue={true}
									width="100%"
								/>
							</StandaloneFieldRow>
							<StandaloneFieldRow>
								<TextField
									name="lastName"
									placeholder={intl.formatMessage(messages.lastNameLabel)}
									trimValue={true}
									width="100%"
								/>
							</StandaloneFieldRow>
						</CenteredFormWrapper>
					) : (
						<FormRow>
							<TextField
								name="firstName"
								placeholder={intl.formatMessage(messages.firstNameLabel)}
								trimValue={true}
								width={150}
							/>
							<TextField
								name="lastName"
								placeholder={intl.formatMessage(messages.lastNameLabel)}
								trimValue={true}
								width={200}
							/>
						</FormRow>
					)}

					<ButtonsLayout layout={ButtonsLayoutType.Steps}>
						{accountIsOutOfBand === false && (
							<CancelButton
								onClickCallback={onCancelCallback}
								visible={!isValid}
							>
								<FormattedMessage {...messages.noThanks} />
							</CancelButton>
						)}

						<SubmitButton nextStepIcon={true}>
							<FormattedMessage {...messages.nextButton} />
						</SubmitButton>
					</ButtonsLayout>
				</>
			)}
		</Form>
	);
};



export default OnboardingNameForm;
