import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import OnOrOff from '~/components/logic/OnOrOff';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';

import {
	useMarketingEmailSettingsFormQuery,
	useUpdateUserMarketingEmailsPreferencesMutation,
} from './MarketingEmailSettingsForm.gql';

import useIsAllowedWithUser from '~/hooks/useIsAllowedWithUser';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	gettingStarted: {
		id: 'ui.settings.emailSettings.gettingStarted',
	},
	monthlyNewsletter: {
		id: 'ui.settings.emailSettings.monthlyNewsletter',
	},
	newFeatures: {
		id: 'ui.settings.emailSettings.newFeatures',
	},
	serviceMessages: {
		id: 'ui.settings.emailSettings.serviceMessages',
	},
	title: {
		id: 'ui.settings.emailSettings.title',
	},
});



type Props = {
	isForCurrentUser: boolean,
	legacyUserId: string | null,
};

const MarketingEmailSettingsForm: React.FC<Props> = (props) => {
	const {
		isForCurrentUser,
		legacyUserId,
	} = props;

	const isAllowedToManageDetails = useIsAllowedWithUser(
		legacyUserId,
		GraphQL.ActionWithUser.ManageDetails,
	);

	const { data } = useMarketingEmailSettingsFormQuery({
		skip: legacyUserId === null,
		variables: {
			legacyUserId: legacyUserId ?? '',
		},
	});

	const emailSettings = data?.user ?? null;

	const [updateUserMarketingEmailsPreferences] = useUpdateUserMarketingEmailsPreferencesMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (legacyUserId === null) {
				throw new Error(
					`legacyUserId can't be null`,
				);
			}

			if (emailSettings === null) {
				throw new Error(
					`emailSettings can't be null`,
				);
			}

			try {
				await updateUserMarketingEmailsPreferences({
					variables: {
						legacyUserId,
						isSubscribedToFeatureAnnouncementEmails: values.isSubscribedToFeatureAnnouncementEmails,
						isSubscribedToNewsletterEmails: values.isSubscribedToNewsletterEmails,
						isSubscribedToOnboardingEmails: values.isSubscribedToOnboardingEmails,
						isSubscribedToServiceMessageEmails: (
							emailSettings.isAllowedToManageMonitoringSettings
								? values.isSubscribedToServiceMessageEmails
								: emailSettings.isSubscribedToServiceMessageEmails
						),
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}
		},
		[
			emailSettings,
			legacyUserId,
			updateUserMarketingEmailsPreferences,
		],
	);

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageDetails}
			isReadOnly={isForCurrentUser === false}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.gettingStarted} />
						)}
					>
						<StaticText focusTarget="isSubscribedToOnboardingEmails">
							<OnOrOff state={emailSettings?.isSubscribedToOnboardingEmails ?? null} />
						</StaticText>
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.newFeatures} />
						)}
					>
						<StaticText focusTarget="isSubscribedToFeatureAnnouncementEmails">
							<OnOrOff state={emailSettings?.isSubscribedToFeatureAnnouncementEmails ?? null} />

						</StaticText>
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.monthlyNewsletter} />
						)}
					>
						<StaticText focusTarget="isSubscribedToNewsletterEmails">
							<OnOrOff state={emailSettings?.isSubscribedToNewsletterEmails ?? null} />

						</StaticText>
					</FormRow>

					{emailSettings?.isAllowedToManageMonitoringSettings && (
						<FormRow
							label={(
								<FormattedMessage {...messages.serviceMessages} />
							)}
						>
							<StaticText focusTarget="isSubscribedToServiceMessageEmails">
								<OnOrOff state={emailSettings.isSubscribedToServiceMessageEmails} />
							</StaticText>
						</FormRow>
					)}
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultFocus="email_optin_for_feature_announcements"
					defaultValues={{
						isSubscribedToFeatureAnnouncementEmails: emailSettings?.isSubscribedToFeatureAnnouncementEmails ?? null,
						isSubscribedToNewsletterEmails: emailSettings?.isSubscribedToNewsletterEmails ?? null,
						isSubscribedToOnboardingEmails: emailSettings?.isSubscribedToOnboardingEmails ?? null,
						isSubscribedToServiceMessageEmails: emailSettings?.isSubscribedToServiceMessageEmails ?? null,
					}}
					onSuccess={handleSubmit}
				>
					<FormRows>
						<FormRow
							htmlFor="isSubscribedToOnboardingEmails"
							label={(
								<FormattedMessage {...messages.gettingStarted} />
							)}
						>
							<FieldStatus name="isSubscribedToOnboardingEmails">
								<SwitchField
									activeStateLabel={(
										<OnOrOff state={true} />
									)}
									inactiveStateLabel={(
										<OnOrOff state={false} />
									)}
									name="isSubscribedToOnboardingEmails"
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="isSubscribedToFeatureAnnouncementEmails"
							label={(
								<FormattedMessage {...messages.newFeatures} />
							)}
						>
							<FieldStatus name="isSubscribedToFeatureAnnouncementEmails">
								<SwitchField
									activeStateLabel={(
										<OnOrOff state={true} />
									)}
									inactiveStateLabel={(
										<OnOrOff state={false} />
									)}
									name="isSubscribedToFeatureAnnouncementEmails"
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="isSubscribedToNewsletterEmails"
							label={(
								<FormattedMessage {...messages.monthlyNewsletter} />
							)}
						>
							<FieldStatus name="isSubscribedToNewsletterEmails">
								<SwitchField
									activeStateLabel={(
										<OnOrOff state={true} />
									)}
									inactiveStateLabel={(
										<OnOrOff state={false} />
									)}
									name="isSubscribedToNewsletterEmails"
								/>
							</FieldStatus>
						</FormRow>

						{emailSettings?.isAllowedToManageMonitoringSettings && (
							<FormRow
								htmlFor="isSubscribedToServiceMessageEmails"
								label={(
									<FormattedMessage {...messages.serviceMessages} />
								)}
							>
								<FieldStatus name="isSubscribedToServiceMessageEmails">
									<SwitchField
										activeStateLabel={(
											<OnOrOff state={true} />
										)}
										inactiveStateLabel={(
											<OnOrOff state={false} />
										)}
										name="isSubscribedToServiceMessageEmails"
									/>
								</FieldStatus>
							</FormRow>
						)}
					</FormRows>

					<FormErrorMessages />

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default MarketingEmailSettingsForm;
