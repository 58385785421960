import React from 'react';

import type CK from '~/types/contentking';

import SmallImageLabel from '~/components/patterns/images/SmallImageLabel';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import WebsiteFavicon, {
	WebsiteFaviconUsageContext,
} from './WebsiteFavicon';

import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteName: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const displayName = useWebsiteDisplayName(websiteId);

	return (
		<SmallImageLabel
			image={(
				<WebsiteFavicon
					usageContext={WebsiteFaviconUsageContext.List}
					websiteId={websiteId}
				/>
			)}
			label={(
				<Ellipsis>
					{displayName}
				</Ellipsis>
			)}
		/>
	);
};



export default WebsiteName;
