import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import Copy from '../../Copy';
import ValidationMessage, {
	ValidationMessageStatus,
} from '~/components/patterns/messages/embedded/ValidationMessage';

import {
	ValidationStatus,
} from './InviteMultipleMembersStep';



const messages = defineMessages({
	failed: {
		id: 'ui.members.new.bulk.validationStatus.failed',
	},
	failedExplanation: {
		id: 'ui.members.new.bulk.validationStatus.failedExplanation',
	},
	pending: {
		id: 'ui.members.new.bulk.validationStatus.pending',
	},
});



type Props = {
	stagedEmails: Array<string>,
	values: Record<string, string | ValidationStatus>,
};

const ValidationStatusMessage: React.FC<Props> = ({ stagedEmails, values }) => {
	let pendingEmails = 0;
	let failedEmails = 0;

	stagedEmails.forEach((email) => {
		if (values[`status_${email}`] === ValidationStatus.Pending) {
			pendingEmails += 1;
		}

		if (values[`status_${email}`] === ValidationStatus.Failed) {
			failedEmails += 1;
		}
	});

	if (pendingEmails > 0) {
		return (
			<ValidationMessage status={ValidationMessageStatus.ValidationInProgress}>
				<Copy
					{...messages.pending}
					values={{
						emails: pendingEmails,
					}}
				/>
			</ValidationMessage>
		);
	}

	if (failedEmails > 0) {
		return (
			<ValidationMessage
				explanatoryTooltip={(
					<Copy {...messages.failedExplanation} />
				)}
				status={ValidationMessageStatus.Invalid}
			>
				<Copy
					{...messages.failed}
					values={{
						emails: failedEmails,
					}}
				/>
			</ValidationMessage>
		);
	}

	return null;
};

export default ValidationStatusMessage;
