import {
	useSelector,
} from 'react-redux';

import {
	windowHasFocusSelector,
} from '~/state/window/selectors';



function useWindowHasFocus(): boolean {
	return useSelector(windowHasFocusSelector);
}



export default useWindowHasFocus;
