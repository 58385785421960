import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class AuthButtonsLayout extends Component {

	render() {
		const {
			indentationSize,
			leftButton,
			rightButton,
		} = this.props;

		const componentClasses = classNames({
			'auth-buttons-layout': true,
			'auth-buttons-layout--indentation': indentationSize && indentationSize === 1,
			'auth-buttons-layout--bigger-indentation': indentationSize && indentationSize === 2,
		});

		return (
			<div className={componentClasses}>
				<div className="auth-buttons-layout__button auth-buttons-layout__button--left">
					{leftButton}
				</div>

				<div className="auth-buttons-layout__button auth-buttons-layout__button--right">
					{rightButton}
				</div>
			</div>
		);
	}

}

AuthButtonsLayout.defaultProps = {
	indentationSize: 2,
};

AuthButtonsLayout.propTypes = {
	/** Possibility to disable indentation of this component or set higher indentation */
	indentationSize: PropTypes.oneOf([
		0,
		1,
		2,
	]),
	leftButton: PropTypes.node,
	rightButton: PropTypes.node,
};



export default AuthButtonsLayout;
