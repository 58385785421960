/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StaticSegmentUrlsQueryVariables = GraphQL.Exact<{
  segmentId: GraphQL.Scalars['PageSegmentId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type StaticSegmentUrlsQuery = { readonly __typename?: 'Query', readonly staticPageSegmentUrls: { readonly __typename?: 'StaticPageSegmentUrlsResult', readonly id: CK.PageSegmentId, readonly urls: ReadonlyArray<string> } | null };

export type CreateStaticSegmentMutationVariables = GraphQL.Exact<{
  color: GraphQL.Scalars['String']['input'];
  iconName: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  label: GraphQL.Scalars['String']['input'];
  shortcode: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CreateStaticSegmentMutation = { readonly __typename?: 'Mutation', readonly CreateStaticSegment: { readonly __typename?: 'CreateStaticSegmentResult', readonly createdPageSegment: { readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null }, readonly createdPageSegmentUrls: { readonly __typename?: 'StaticPageSegmentUrlsResult', readonly id: CK.PageSegmentId, readonly urls: ReadonlyArray<string> }, readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null }> } | null } } };

export type UpdateStaticSegmentMutationVariables = GraphQL.Exact<{
  color: GraphQL.Scalars['String']['input'];
  iconName: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  label: GraphQL.Scalars['String']['input'];
  shortcode: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  segmentId: GraphQL.Scalars['PageSegmentId']['input'];
  urls: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateStaticSegmentMutation = { readonly __typename?: 'Mutation', readonly UpdateStaticSegment: { readonly __typename?: 'UpdateSegmentResult', readonly query: { readonly __typename?: 'Query', readonly staticPageSegmentUrls: { readonly __typename?: 'StaticPageSegmentUrlsResult', readonly id: CK.PageSegmentId, readonly urls: ReadonlyArray<string> } | null, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null }> } | null }, readonly updatedPageSegment: { readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null } } };

export type PageSegmentFragmentFragment = { readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null };

export const PageSegmentFragmentFragmentDoc = gql`
    fragment PageSegmentFragment on PageSegment {
  color
  filterDefinition
  icon {
    files {
      scaleFactor
      url
    }
    name
  }
  id
  isBeingReevaluated
  isManaged
  label
  name
  shortcode
  sizeLimit
}
    `;
export const StaticSegmentUrlsDocument = gql`
    query StaticSegmentUrls($segmentId: PageSegmentId!, $websiteId: WebsiteId!) {
  staticPageSegmentUrls(segmentId: $segmentId, websiteId: $websiteId) {
    id
    urls
  }
}
    `;

/**
 * __useStaticSegmentUrlsQuery__
 *
 * To run a query within a React component, call `useStaticSegmentUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticSegmentUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticSegmentUrlsQuery({
 *   variables: {
 *      segmentId: // value for 'segmentId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useStaticSegmentUrlsQuery(baseOptions: Apollo.QueryHookOptions<StaticSegmentUrlsQuery, StaticSegmentUrlsQueryVariables> & ({ variables: StaticSegmentUrlsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaticSegmentUrlsQuery, StaticSegmentUrlsQueryVariables>(StaticSegmentUrlsDocument, options);
      }
export function useStaticSegmentUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaticSegmentUrlsQuery, StaticSegmentUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaticSegmentUrlsQuery, StaticSegmentUrlsQueryVariables>(StaticSegmentUrlsDocument, options);
        }
export function useStaticSegmentUrlsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StaticSegmentUrlsQuery, StaticSegmentUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StaticSegmentUrlsQuery, StaticSegmentUrlsQueryVariables>(StaticSegmentUrlsDocument, options);
        }
export type StaticSegmentUrlsQueryHookResult = ReturnType<typeof useStaticSegmentUrlsQuery>;
export type StaticSegmentUrlsLazyQueryHookResult = ReturnType<typeof useStaticSegmentUrlsLazyQuery>;
export type StaticSegmentUrlsSuspenseQueryHookResult = ReturnType<typeof useStaticSegmentUrlsSuspenseQuery>;
export type StaticSegmentUrlsQueryResult = Apollo.QueryResult<StaticSegmentUrlsQuery, StaticSegmentUrlsQueryVariables>;
export const CreateStaticSegmentDocument = gql`
    mutation CreateStaticSegment($color: String!, $iconName: String, $label: String!, $shortcode: String, $urls: [String!]!, $websiteId: WebsiteId!) {
  CreateStaticSegment(
    color: $color
    iconName: $iconName
    label: $label
    shortcode: $shortcode
    urls: $urls
    websiteId: $websiteId
  ) {
    createdPageSegment {
      ...PageSegmentFragment
    }
    createdPageSegmentUrls {
      id
      urls
    }
    query {
      website(id: $websiteId) {
        id
        pageSegments {
          ...PageSegmentFragment
        }
      }
    }
  }
}
    ${PageSegmentFragmentFragmentDoc}`;
export type CreateStaticSegmentMutationFn = Apollo.MutationFunction<CreateStaticSegmentMutation, CreateStaticSegmentMutationVariables>;

/**
 * __useCreateStaticSegmentMutation__
 *
 * To run a mutation, you first call `useCreateStaticSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaticSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaticSegmentMutation, { data, loading, error }] = useCreateStaticSegmentMutation({
 *   variables: {
 *      color: // value for 'color'
 *      iconName: // value for 'iconName'
 *      label: // value for 'label'
 *      shortcode: // value for 'shortcode'
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCreateStaticSegmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateStaticSegmentMutation, CreateStaticSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStaticSegmentMutation, CreateStaticSegmentMutationVariables>(CreateStaticSegmentDocument, options);
      }
export type CreateStaticSegmentMutationHookResult = ReturnType<typeof useCreateStaticSegmentMutation>;
export type CreateStaticSegmentMutationResult = Apollo.MutationResult<CreateStaticSegmentMutation>;
export type CreateStaticSegmentMutationOptions = Apollo.BaseMutationOptions<CreateStaticSegmentMutation, CreateStaticSegmentMutationVariables>;
export const UpdateStaticSegmentDocument = gql`
    mutation UpdateStaticSegment($color: String!, $iconName: String, $label: String!, $shortcode: String, $segmentId: PageSegmentId!, $urls: [String!]!, $websiteId: WebsiteId!) {
  UpdateStaticSegment(
    color: $color
    iconName: $iconName
    label: $label
    shortcode: $shortcode
    segmentId: $segmentId
    urls: $urls
    websiteId: $websiteId
  ) {
    query {
      staticPageSegmentUrls(segmentId: $segmentId, websiteId: $websiteId) {
        id
        urls
      }
      website(id: $websiteId) {
        id
        pageSegments {
          ...PageSegmentFragment
        }
      }
    }
    updatedPageSegment {
      ...PageSegmentFragment
    }
  }
}
    ${PageSegmentFragmentFragmentDoc}`;
export type UpdateStaticSegmentMutationFn = Apollo.MutationFunction<UpdateStaticSegmentMutation, UpdateStaticSegmentMutationVariables>;

/**
 * __useUpdateStaticSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateStaticSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaticSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaticSegmentMutation, { data, loading, error }] = useUpdateStaticSegmentMutation({
 *   variables: {
 *      color: // value for 'color'
 *      iconName: // value for 'iconName'
 *      label: // value for 'label'
 *      shortcode: // value for 'shortcode'
 *      segmentId: // value for 'segmentId'
 *      urls: // value for 'urls'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateStaticSegmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaticSegmentMutation, UpdateStaticSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaticSegmentMutation, UpdateStaticSegmentMutationVariables>(UpdateStaticSegmentDocument, options);
      }
export type UpdateStaticSegmentMutationHookResult = ReturnType<typeof useUpdateStaticSegmentMutation>;
export type UpdateStaticSegmentMutationResult = Apollo.MutationResult<UpdateStaticSegmentMutation>;
export type UpdateStaticSegmentMutationOptions = Apollo.BaseMutationOptions<UpdateStaticSegmentMutation, UpdateStaticSegmentMutationVariables>;