import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AlertTypesSelectionTableField from '~/components/app/AlertTypesSelectionTableField';
import BackButton from '~/components/app/BackButton';
import Copy from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import Form from '~/components/atoms/forms/basis/Form';
import LighthouseMonitoringNotEnabledMessage from '~/components/app/LighthouseMonitoringNotEnabledMessage';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import LoadingWrapper from '~/components/atoms/preloaders/LoadingWrapper';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/atoms/forms/components/SubmitButton';

import useAlertTypeDefinitions from '~/hooks/useAlertTypeDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';

import {
	ALERT_GROUP_LIGHTHOUSE_ALERTS,
	ALERT_GROUP_PAGE_ALERTS,
	ALERT_GROUP_PLATFORM_ALERTS,
} from '~/model/alerts';



const messages = defineMessages({
	breadcrumb: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertType.breadcrumb',
	},
	continue: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertType.continue',
	},
	description: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertType.description',
	},
});

const messagesSidebar = defineMessages({
	[ALERT_GROUP_LIGHTHOUSE_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertType.sidebar.lighthouse_alerts',
	},
	[ALERT_GROUP_PAGE_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertType.sidebar.page_alerts',
	},
	[ALERT_GROUP_PLATFORM_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertType.sidebar.property_alerts',
	},
});

const messagesTitle = defineMessages({
	[ALERT_GROUP_LIGHTHOUSE_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertType.title.lighthouse_alerts',
	},
	[ALERT_GROUP_PAGE_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertType.title.page_alerts',
	},
	[ALERT_GROUP_PLATFORM_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.selectAlertType.title.property_alerts',
	},
});

const validations = {
	alertTypes: [
		{
			message: '',
			field: 'alertTypes',
			rule: ({ values, name }) => {
				return values[name].length > 0;
			},
		},
	],
};



type AlertGroup =
	| typeof ALERT_GROUP_LIGHTHOUSE_ALERTS
	| typeof ALERT_GROUP_PAGE_ALERTS
	| typeof ALERT_GROUP_PLATFORM_ALERTS;

type Props = {
	alertGroup: AlertGroup,
	onSubmit: (selectedAlertTypes: Array<string>) => void,
};

const SelectAlertTypeStep: React.FC<Props> = (props) => {
	const {
		alertGroup,
		onSubmit,
	} = props;

	const websiteId = useWebsiteId();

	const alertTypeDefinitions = useAlertTypeDefinitions();
	const isLighthouseMonitored = useWebsiteIsLighthouseMonitored(websiteId);

	const isLighthouseAlertGroup = alertGroup === ALERT_GROUP_LIGHTHOUSE_ALERTS;
	const isLoading = alertTypeDefinitions.isLoaded === false;

	const handleSubmit = React.useCallback(
		(values) => {
			onSubmit(
				values.alertTypes,
			);
		},
		[
			onSubmit,
		],
	);

	return (
		<MultiStepModalStep
			breadcrumb={(
				<FormattedMessage {...messages.breadcrumb} />
			)}
			headerIconType={MultiStepModalStepHeaderIconType.Plus}
			name="select-alert-type-step"
			sidebar={(
				<RichText>
					<Copy
						{...messagesSidebar[alertGroup]}
						values={{
							link_article: (chunks) => (
								<ExternalLink
									href={
										alertGroup === ALERT_GROUP_PLATFORM_ALERTS
											? 'https://www.contentkingapp.com/support/configuring-alerts/'
											: 'https://www.contentkingapp.com/support/configuring-alerts/#best-practices'
									}
									key="link"
								>
									{chunks}
								</ExternalLink>
							),
						}}
					/>
				</RichText>
			)}
			title={(
				<FormattedMessage {...messagesTitle[alertGroup]} />
			)}
		>
			<PremiumFeatureSituation
				featureName={GraphQL.AccountFeature.LighthouseMonitoring}
				hideIfUnattainable={false}
				showUnavailablePlan={true}
				style={PremiumFeatureSituationStyle.Box}
			>
				{({
					isFeatureEnabled: isLighthouseFeatureEnabled,
					premiumAnnotation,
				}) => (
					<Form
						defaultDataHasChanged={true}
						defaultValues={{
							alertTypes: [],
						}}
						key="select-alert-type"
						onSuccess={handleSubmit}
						validations={validations}
					>
						{({ isValid }) => (
							<>
								{isLighthouseAlertGroup && (
									!isLighthouseFeatureEnabled ? (
										<ModalTextSection>
											{premiumAnnotation}
										</ModalTextSection>
									) : !isLighthouseMonitored ? (
										<ModalTextSection>
											<LighthouseMonitoringNotEnabledMessage fullwidth={true} />
										</ModalTextSection>
									) : null
								)}

								<DisabledContent disabledContent={isLighthouseAlertGroup && !isLighthouseMonitored}>
									<ModalTextSection>
										<FormattedMessage {...messages.description} />
									</ModalTextSection>

									<ModalTextSection>
										<LoadingWrapper
											isLoading={isLoading}
											placeholder={(
												<LoadingDots />
											)}
										>
											<AlertTypesSelectionTableField
												alertGroup={alertGroup}
												disabled={(isLighthouseAlertGroup && !isLighthouseMonitored)}
												height={350}
												key={alertGroup}
												name="alertTypes"
											/>
										</LoadingWrapper>
									</ModalTextSection>
								</DisabledContent>

								<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
									<BackButton />

									<DisabledContent disabledContent={isLighthouseAlertGroup && !isLighthouseMonitored}>
										<SubmitButton
											disabled={!isValid || (isLighthouseAlertGroup && !isLighthouseMonitored)}
											inactive={!isValid || (isLighthouseAlertGroup && !isLighthouseMonitored)}
										>
											<FormattedMessage {...messages.continue} />
										</SubmitButton>
									</DisabledContent>
								</ModalButtonsLayout>
							</>
						)}
					</Form>
				)}
			</PremiumFeatureSituation>
		</MultiStepModalStep>
	);
};



export default SelectAlertTypeStep;
