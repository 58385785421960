import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InternalLink from '~/components/patterns/links/InternalLink';
import MultiselectField from '~/components/atoms/forms/components/MultiselectField';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';
import WebsiteAdminSettingsOnPageRequestsWhitelistRulesModal from '~/components/app/WebsiteAdminSettingsOnPageRequestsWhitelistRulesModal';

import {
	useUpdateWebsiteAdminDangerousEscapeHatchesMutation,
	useWebsiteAdminDangerousEscapeHatchesFormQuery,
} from './WebsiteAdminDangerousEscapeHatchesForm.gql';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useOpenModal from '~/hooks/useOpenModal';
import useWebsiteIsDomTracked from '~/hooks/useWebsiteIsDomTracked';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';
import useWebsiteOnPageRequestsWhitelistRules from '~/hooks/useWebsiteOnPageRequestsWhitelistRules';

import getOptionsLabel from '~/utilities/getOptionsLabel';



const messages = defineMessages({
	onPageRequestsWhitelistRulesDisplay: {
		id: 'ui.admin.onPageRequestsWhitelistRulesDisplay',
	},
	onPageRequestsWhitelistRulesEdit: {
		id: 'ui.admin.onPageRequestsWhitelistRulesEdit',
	},
});

const historicalPagePropertiesOptions = [
	{
		name: 'open_graph_image',
		title: 'Open Graph Image',
	},
];



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminDangerousEscapeHatchesForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteIsDomTracked = useWebsiteIsDomTracked(websiteId);
	const websiteIsLighthouseMonitored = useWebsiteIsLighthouseMonitored(websiteId);
	const websiteOnPageRequestsWhitelistRules = useWebsiteOnPageRequestsWhitelistRules(websiteId);

	const isAllowedToManageDangerousEscapeHatches = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageAdminDangerousEscapeHatches,
	);

	const { data } = useWebsiteAdminDangerousEscapeHatchesFormQuery({
		variables: {
			websiteId,
		},
	});

	const [updateWebsiteAdminDangerousEscapeHatches] = useUpdateWebsiteAdminDangerousEscapeHatchesMutation();

	const handleSubmit = React.useCallback(
		(values) => {
			return updateWebsiteAdminDangerousEscapeHatches({
				variables: {
					isBlacklistDisabled: values.isBlacklistDisabled,
					isShadowDomFlattened: values.isShadowDomFlattened,
					pagePropertiesWithDisabledHistoricalTracking: values.pagePropertiesWithDisabledHistoricalTracking,
					websiteId,
				},
			});
		},
		[
			updateWebsiteAdminDangerousEscapeHatches,
			websiteId,
		],
	);

	const openOnPageRequestsWhitelistRulesModal = useOpenModal(
		() => (
			<WebsiteAdminSettingsOnPageRequestsWhitelistRulesModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const adminSettings = data?.website?.adminSettings ?? null;

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageDangerousEscapeHatches}
			isForAdmins={true}
			title="Dangerous escape hatches"
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						label="Is rendering blacklist disabled?"
					>
						<StaticText focusTarget="isBlacklistDisabled">
							{adminSettings?.isBlacklistDisabled ? 'yes' : 'no'}
						</StaticText>
					</FormRow>

					<FormRow
						label="Flatten shadow DOM"
					>
						<StaticText focusTarget="isShadowDomFlattened">
							{adminSettings?.isShadowDomFlattened ? 'yes' : 'no'}
						</StaticText>
					</FormRow>

					<FormRow label="Page properties with disabled historical tracking">
						<StaticText focusTarget="pagePropertiesWithDisabledHistoricalTracking">
							{getOptionsLabel(historicalPagePropertiesOptions, adminSettings?.pagePropertiesWithDisabledHistoricalTracking ?? [])}
						</StaticText>
					</FormRow>

					{(websiteIsDomTracked || websiteIsLighthouseMonitored) && (
						<FormRow label="On-page requests whitelist">
							<StaticText>
								{websiteOnPageRequestsWhitelistRules !== null && (
									<FormattedMessage
										{...messages.onPageRequestsWhitelistRulesDisplay}
										values={{
											countRules: websiteOnPageRequestsWhitelistRules.length,
										}}
									/>
								)}
							</StaticText>
						</FormRow>
					)}
				</FormRows>
			</DisplayPart>

			<EditablePart>
				{adminSettings !== null && (
					<Form
						defaultValues={{
							isBlacklistDisabled: adminSettings.isBlacklistDisabled,
							isShadowDomFlattened: adminSettings.isShadowDomFlattened,
							pagePropertiesWithDisabledHistoricalTracking: adminSettings.pagePropertiesWithDisabledHistoricalTracking,
						}}
						onSuccess={handleSubmit}
					>
						<FormRows>
							<FormRow
								htmlFor="isBlacklistDisabled"
								label="Is rendering blacklist disabled?"
							>
								<FieldStatus name="isBlacklistDisabled">
									<SwitchField
										activeStateLabel="yes"
										inactiveStateLabel="no"
										name="isBlacklistDisabled"
									/>
								</FieldStatus>
							</FormRow>

							<FormRow
								htmlFor="isShadowDomFlattened"
								label="Flatten shadow DOM"
							>
								<FieldStatus name="isShadowDomFlattened">
									<SwitchField
										activeStateLabel="yes"
										inactiveStateLabel="no"
										name="isShadowDomFlattened"
									/>
								</FieldStatus>
							</FormRow>

							<FormRow
								htmlFor="pagePropertiesWithDisabledHistoricalTracking"
								label="Page properties with disabled historical tracking"
							>
								<MultiselectField
									isOnlyLinkVisible={true}
									name="pagePropertiesWithDisabledHistoricalTracking"
									options={historicalPagePropertiesOptions}
								/>
							</FormRow>

							{(websiteIsDomTracked || websiteIsLighthouseMonitored) && (
								<FormRow
									label="On-page requests whitelist"
								>
									<StaticText>
										{websiteOnPageRequestsWhitelistRules !== null && (
											<FormattedMessage
												{...messages.onPageRequestsWhitelistRulesEdit}
												values={{
													countRules: websiteOnPageRequestsWhitelistRules.length,
													linkModal: (chunks) => (
														<InternalLink onClickCallback={openOnPageRequestsWhitelistRulesModal}>
															{chunks}
														</InternalLink>
													),
												}}
											/>
										)}
									</StaticText>
								</FormRow>
							)}
						</FormRows>

						<ButtonsLayout>
							<CancelButton />
							<SaveSubmitButton />
						</ButtonsLayout>
					</Form>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default WebsiteAdminDangerousEscapeHatchesForm;
