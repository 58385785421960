import React from 'react';

import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';

import useViewportOrientation from '~/hooks/useViewportOrientation';
import useViewportType from '~/hooks/useViewportType';



type Props = {
	children: React.ReactNode,
	contentOverlay?: React.ReactNode,
	header: React.ReactElement,
	withPadding?: boolean,
};

const ScrollableContentScreen: React.FC<Props> = (props) => {
	const {
		children,
		contentOverlay,
		header,
		withPadding = true,
	} = props;

	const viewportOrientation = useViewportOrientation();
	const viewportType = useViewportType();

	return (
		<ScreenLayout
			contentOverlay={contentOverlay}
			header={header}
			isHeaderFixed={viewportType.isSmall === false && !(viewportType.isMedium && viewportOrientation.isPortrait)}
		>
			{withPadding ? (
				<ScreenBody>
					{children}
				</ScreenBody>
			) : (
				children
			)}
		</ScreenLayout>
	);
};



export default ScrollableContentScreen;
