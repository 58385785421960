import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useIsAllowedWithUserWebsiteAccessOracle from '~/hooks/useIsAllowedWithUserWebsiteAccessOracle';



function useCanManageUserAsAlertRecipient(websiteId: CK.WebsiteId | null) {
	const isAllowedWithUserWebsiteAccessOracle = useIsAllowedWithUserWebsiteAccessOracle();

	return React.useCallback(
		(legacyUserId: string) => isAllowedWithUserWebsiteAccessOracle({
			action: GraphQL.ActionWithUserWebsiteAccess.ManageEmailPreferences,
			from: 'website',
			legacyUserId,
			websiteId,
		}).yes,
		[
			isAllowedWithUserWebsiteAccessOracle,
			websiteId,
		],
	);
}



export default useCanManageUserAsAlertRecipient;
