import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	PureComponent,
} from 'react';

import styles from './DatatableOverlay.module.scss';



export enum DatatableOverlayStyle {
	Disabled = 'disabled',
	Transparent = 'transparent',
	White = 'white',
}

type Props = {
	/** Make content of overlay center aligned. Default alignment is to the top for better readability. */
	centeredContent?: boolean,
	children?: React.ReactNode,
	/** Reserved space form the top for datatable header */
	datatableHeaderHeight?: number,
	/** Possibility to cancel usage of predefined z-index property */
	definedZIndex: boolean,
	/** Style of overlay */
	style: DatatableOverlayStyle,
};



class DatatableOverlay extends PureComponent<Props> {

	static defaultProps = {
		centeredContent: false,
		definedZIndex: true,
		style: DatatableOverlayStyle.White,
	};

	static contextTypes = {
		datatableHeaderHeight: PropTypes.number,
	};

	render() {
		const {
			datatableHeaderHeight: datatableHeaderHeightContext,
		} = (this.context as any);

		const {
			centeredContent,
			children,
			datatableHeaderHeight: datatableHeaderHeightProp,
			definedZIndex,
			style,
		} = this.props;

		const componentClasses = classNames({
			[styles.component]: true,
			[styles.hasCenteredContent]: centeredContent,
			[styles.hasDefinedZIndex]: definedZIndex,
			[styles.hasDisabledBackground]: style === DatatableOverlayStyle.Disabled,
			[styles.hasWhiteBackground]: style === DatatableOverlayStyle.White,
			'js-scrollable': true,
		});

		const cssStyle = {};

		if (datatableHeaderHeightContext || datatableHeaderHeightProp) {
			cssStyle['top'] = datatableHeaderHeightProp ?? datatableHeaderHeightContext;

			// We will move overlay down by 1px when it's defined header height.
			// All headers in table has 1px border at the bottom side.
			cssStyle['top'] = cssStyle['top'] + 1;
		}

		return (
			<div
				className={componentClasses}
				style={cssStyle}
			>
				{children}
			</div>
		);
	}

}



export default DatatableOverlay;
