import {
	differenceInMinutes,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	type MessageDescriptor,
	defineMessages,
} from 'react-intl';

import ProcessingMessage from '~/components/patterns/loaders/ProcessingMessage';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteTimeCreatedAt from '~/hooks/useWebsiteTimeCreatedAt';



const messages = defineMessages({
	subtitleAboveSixtyMinutes: {
		id: 'ui.general.noCrawledData.waitingTime3.subtitle',
	},
	subtitleBelowFiveMinutes: {
		id: 'ui.general.noCrawledData.waitingTime1.subtitle',
	},
	subtitleBelowOneMinute: {
		id: 'ui.general.noCrawledData.subtitle',
	},
	subtitleBelowSixtyMinutes: {
		id: 'ui.general.noCrawledData.waitingTime2.subtitle',
	},
	titleAboveSixtyMinutes: {
		id: 'ui.general.noCrawledData.waitingTime3.title',
	},
	titleBelowFiveMinutes: {
		id: 'ui.general.noCrawledData.waitingTime1.title',
	},
	titleBelowOneMinute: {
		id: 'ui.general.noCrawledData.title',
	},
	titleBelowSixtyMinutes: {
		id: 'ui.general.noCrawledData.waitingTime2.title',
	},
});



const CrawlingInProgress: React.FC = () => {
	const websiteId = useWebsiteId();

	const websiteCreatedAt = useWebsiteTimeCreatedAt(websiteId);

	const [minutesSinceWebsiteCreated, setMinutesSinceWebsiteCreated] = React.useState(
		() => websiteCreatedAt ? differenceInMinutes(new Date(), websiteCreatedAt) : null,
	);

	const tick = React.useCallback(
		() => {
			if (websiteCreatedAt === null) {
				return;
			}

			setMinutesSinceWebsiteCreated(
				differenceInMinutes(new Date(), websiteCreatedAt),
			);
		},
		[
			setMinutesSinceWebsiteCreated,
			websiteCreatedAt,
		],
	);

	React.useEffect(
		() => {
			const timer = setInterval(
				tick,
				60000,
			);

			return () => clearInterval(timer);
		},
		[
			tick,
		],
	);

	if (
		minutesSinceWebsiteCreated === null
		|| websiteCreatedAt === null
	) {
		return null;
	}

	let titleMessage: MessageDescriptor = messages.titleBelowOneMinute;
	let subtitleMessage: MessageDescriptor = messages.subtitleBelowOneMinute;
	let showLoader = true;

	if (minutesSinceWebsiteCreated < 1) {
		titleMessage = messages.titleBelowOneMinute;
		subtitleMessage = messages.subtitleBelowOneMinute;
	} else if (minutesSinceWebsiteCreated < 5) {
		titleMessage = messages.titleBelowFiveMinutes;
		subtitleMessage = messages.subtitleBelowFiveMinutes;
	} else if (minutesSinceWebsiteCreated < 60) {
		titleMessage = messages.titleBelowSixtyMinutes;
		subtitleMessage = messages.subtitleBelowSixtyMinutes;
	} else if (minutesSinceWebsiteCreated > 60) {
		titleMessage = messages.titleAboveSixtyMinutes;
		subtitleMessage = messages.subtitleAboveSixtyMinutes;
		showLoader = false;
	}

	return (
		<ProcessingMessage
			showLoader={showLoader}
			title={(
				<FormattedMessage {...titleMessage} />
			)}
		>
			<FormattedMessage {...subtitleMessage} />
		</ProcessingMessage>
	);
};



export default CrawlingInProgress;
