import React from 'react';

import type CK from '~/types/contentking';

import {
	useWebsiteTimeCreatedAtQuery,
} from './useWebsiteTimeCreatedAt.gql';

import useBatchContext from '~/hooks/useBatchContext';



function useWebsiteTimeCreatedAt(websiteId: CK.WebsiteId | null): Date | null {
	const { data } = useWebsiteTimeCreatedAtQuery({
		context: useBatchContext('websites', 50),
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	const timeCreatedAt = data?.website?.timeCreatedAt ?? null;

	return React.useMemo(
		() => timeCreatedAt !== null ? new Date(timeCreatedAt) : null,
		[
			timeCreatedAt,
		],
	);
}



export default useWebsiteTimeCreatedAt;
