let hidden;
let visibilityChange;

if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
	hidden = 'hidden';
	visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
	hidden = 'msHidden';
	visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
	hidden = 'webkitHidden';
	visibilityChange = 'webkitvisibilitychange';
}



export function isVisible() {
	if (typeof document.addEventListener === 'undefined' || typeof document[hidden] === 'undefined') {
		return document.hasFocus();
	}

	return !document[hidden];
}



export function onVisibilityChange(callback) {
	if (typeof document.addEventListener === 'undefined' || typeof document[hidden] === 'undefined') {
		window.addEventListener('blur', () => {
			callback(false);
		});

		window.addEventListener('focus', () => {
			callback(true);
		});
	} else {
		document.addEventListener(
			visibilityChange,
			() => {
				if (document[hidden]) {
					callback(false);
				} else {
					callback(true);
				}
			},
			false,
		);
	}
}
