import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AdminAndAccessControlFeaturesIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g fill="none">
				<path
					d="M8.64762 20.4426H2.37012V18.2276C2.36985 16.5127 2.88409 14.8372 3.84632 13.4177C4.80856 11.9982 6.17454 10.9 7.76762 10.2651"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.123 10.2725C15.3071 10.7472 16.3704 11.48 17.2355 12.4175"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M10.938 11.2299C13.5378 11.2299 15.6455 9.12232 15.6455 6.52244C15.6455 3.92256 13.5378 1.81494 10.938 1.81494C8.33809 1.81494 6.23047 3.92256 6.23047 6.52244C6.23047 9.12232 8.33809 11.2299 10.938 11.2299Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
				/>
				<path
					d="M14.4248 21.325V26.075"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M14.4248 17.1426V18.2776"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M24.6055 22.8926V26.0751"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M24.6055 17.0449V19.7574"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M19.5146 25.0249V26.0749"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M19.5146 17.0449V21.9074"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M14.4245 21.225C15.235 21.225 15.892 20.568 15.892 19.7575C15.892 18.9471 15.235 18.29 14.4245 18.29C13.6141 18.29 12.957 18.9471 12.957 19.7575C12.957 20.568 13.6141 21.225 14.4245 21.225Z"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M19.5154 24.915C20.3258 24.915 20.9829 24.258 20.9829 23.4475C20.9829 22.637 20.3258 21.98 19.5154 21.98C18.7049 21.98 18.0479 22.637 18.0479 23.4475C18.0479 24.258 18.7049 24.915 19.5154 24.915Z"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M24.6052 22.7924C25.4157 22.7924 26.0727 22.1354 26.0727 21.3249C26.0727 20.5144 25.4157 19.8574 24.6052 19.8574C23.7947 19.8574 23.1377 20.5144 23.1377 21.3249C23.1377 22.1354 23.7947 22.7924 24.6052 22.7924Z"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
				<path
					d="M26.8575 14.3125H12.1725C11.2364 14.3125 10.4775 15.0714 10.4775 16.0075V27.2375C10.4775 28.1736 11.2364 28.9325 12.1725 28.9325H26.8575C27.7937 28.9325 28.5525 28.1736 28.5525 27.2375V16.0075C28.5525 15.0714 27.7937 14.3125 26.8575 14.3125Z"
					stroke={color}
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="1.25"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default AdminAndAccessControlFeaturesIconBuilder;

