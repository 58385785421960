import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckListTable from '~/components/atoms/forms/components/CheckListTable';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticList from '~/components/atoms/forms/components/StaticList';

import {
	useUpdateLimitedWebsitesAccessOfWebsiteMutation,
} from './ContributorsAssignmentForm.gql';

import useAllInvitations from '~/hooks/useAllInvitations';
import useAllUsers from '~/hooks/useAllUsers';
import useIsAllowedWithAccountMembershipOracle from '~/hooks/useIsAllowedWithAccountMembershipOracle';
import useIsAllowedWithInvitationOracle from '~/hooks/useIsAllowedWithInvitationOracle';



const messages = defineMessages({
	contributorLabel: {
		id: 'ui.acl.assignmentsTable.heading.contributor',
	},
	title: {
		id: 'ui.acl.assignmentsTable.title.contributor',
	},
});



type Props = {
	websiteAccountId: CK.AccountId,
	websiteId: CK.WebsiteId,
};

const ContributorsAssignmentForm: React.FC<Props> = (props) => {
	const {
		websiteAccountId,
		websiteId,
	} = props;

	const allInvitations = useAllInvitations();
	const allUsers = useAllUsers();
	const isAllowedWithAccountMembershipOracle = useIsAllowedWithAccountMembershipOracle();
	const isAllowedWithInvitationOracle = useIsAllowedWithInvitationOracle();

	const [updateLimitedWebsitesAccessOfWebsite] = useUpdateLimitedWebsitesAccessOfWebsiteMutation();

	const {
		assignedContributors,
		assignedContributorsNames,
		contributorsList,
		isEditable,
	} = React.useMemo(
		() => {
			if (
				allInvitations === null
				|| allUsers.isLoaded === false
			) {
				return {
					assignedContributors: null,
					assignedContributorsNames: null,
					contributorsList: null,
					isEditable: null,
				};
			}

			const contributorsList: Array<{
				label: string,
				value: string,
			}> = [];

			const assignedContributors: Array<string> = [];
			const assignedContributorsNames: Array<React.ReactNode> = [];

			const isEditable: Array<boolean> = [];

			allUsers
				.listByWebsiteAccount(websiteAccountId)
				.filter((user) => user.accountMembership.hasLimitedWebsitesAccess)
				.forEach((user) => {
					contributorsList.push({
						label: user.displayName,
						value: `${user.accountMembership.accountId}/${user.email}`,
					});

					if (user.accountMembership.websites.some((website) => website.id === websiteId)) {
						assignedContributors.push(`user/${user.accountMembership.accountId}/${user.email}`);
						assignedContributorsNames.push((
							<span key={user.id}>{user.displayName}</span>
						));
					}

					isEditable.push(
						isAllowedWithAccountMembershipOracle(
							user.accountMembership.accountId,
							user.id,
							GraphQL.ActionWithAccountMembership.ManageAssignedWebsites,
						).yes,
					);
				});

			allInvitations
				.listByWebsiteAccount(websiteAccountId)
				.filter((invitation) => invitation.hasLimitedWebsitesAccess)
				.forEach((invitation) => {
					contributorsList.push({
						label: invitation.displayName,
						value: invitation.email,
					});

					if (invitation.websites.some((website) => website.id === websiteId)) {
						assignedContributors.push(`invitation/${invitation.email}`);
						assignedContributorsNames.push((
							<span key={invitation.id}>{invitation.displayName}</span>
						));
					}

					isEditable.push(
						isAllowedWithInvitationOracle(
							invitation.id,
							GraphQL.ActionWithInvitation.ManageDetails,
						).yes,
					);
				});

			return {
				assignedContributors,
				assignedContributorsNames,
				contributorsList,
				isEditable,
			};
		},
		[
			allInvitations,
			allUsers,
			isAllowedWithAccountMembershipOracle,
			isAllowedWithInvitationOracle,
			websiteAccountId,
			websiteId,
		],
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			if (
				allInvitations === null
				|| assignedContributors === null
			) {
				return;
			}

			await updateLimitedWebsitesAccessOfWebsite({
				variables: {
					addedEmailMemberships: values.assignedContributors.filter(
						(contributor: string) => contributor.startsWith('user/'),
					).map(
						(contributor) => {
							const [, accountId, email] = contributor.split('/');

							return {
								accountId,
								email,
							};
						},
					).filter(
						(contributor) => allUsers.listAll().some(
							(user) => user.email === contributor.email,
						),
					).filter(
						(contributor) => assignedContributors.includes(`user/${contributor.accountId}/${contributor.email}`) === false,
					),
					addedInvitations: values.assignedContributors.filter(
						(contributor: string) => contributor.startsWith('invitation/'),
					).map(
						(contributor) => contributor.substr('invitation/'.length),
					).filter(
						(email) => allInvitations.listAll().some(
							(invitation) => invitation.email === email,
						),
					).filter(
						(email) => assignedContributors.includes(email) === false,
					),
					removedEmailMemberships: assignedContributors.filter(
						(contributor: string) => contributor.startsWith('user/'),
					).map(
						(contributor) => {
							const [, accountId, email] = contributor.split('/') as any;

							return {
								accountId,
								email,
							};
						},
					).filter(
						(contributor) => allUsers.listAll().some(
							(user) => user.email === contributor.email,
						),
					).filter(
						(contributor) => values.assignedContributors.includes(`user/${contributor.accountId}/${contributor.email}`) === false,
					),
					removedInvitations: assignedContributors.filter(
						(contributor: string) => contributor.startsWith('invitation/'),
					).map(
						(contributor) => contributor.substr('invitation/'.length),
					).filter(
						(email) => allInvitations.listAll().some(
							(invitation) => invitation.email === email,
						),
					).filter(
						(email) => values.assignedContributors.includes(email) === false,
					),
					websiteId,
				},
			});
		},
		[
			allInvitations,
			allUsers,
			assignedContributors,
			updateLimitedWebsitesAccessOfWebsite,
			websiteId,
		],
	);

	if (contributorsList === null || contributorsList.length < 1) {
		return null;
	}

	const canEditSomething = isEditable.some((isEntityEditable) => isEntityEditable);

	return (
		<EditableFormWrapper
			isReadOnly={canEditSomething === false}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.contributorLabel} />
						)}
					>
						<StaticList
							ellipsis={true}
							focusTarget="assignedContributors"
						>
							{assignedContributorsNames.length > 0 ? assignedContributorsNames : []}
						</StaticList>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultFocus="assignedContributors"
					defaultValues={{
						assignedContributors,
					}}
					onSuccess={handleSubmit}
				>
					<FormRows>
						<FormRow htmlFor="assignedContributors">
							<CheckListTable
								items={contributorsList}
								label={(
									<FormattedMessage {...messages.contributorLabel} />
								)}
								name="assignedContributors"
							/>
						</FormRow>
					</FormRows>

					<ButtonsLayout layout={ButtonsLayoutType.FormRowWithoutStatus}>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default ContributorsAssignmentForm;
