import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ColumnsIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeWidth="1"
			>
				<defs>
					<rect
						height="3"
						id="path-1"
						width="15"
						x="0.5"
						y="5.32907052e-15"
					>
					</rect>
				</defs>
				<g
					id="Group-17"
					transform="translate(4.000000, 4.000000)"
				>
					<g id="columns">
						<g id="Rectangle">
							<use
								fill={color}
								fillRule="evenodd"
								xlinkHref="#path-1"
							>
							</use>
							<rect
								height="2"
								stroke={color}
								strokeWidth="1"
								width="14"
								x="1"
								y="0.5"
							>
							</rect>
						</g>
						<polygon
							id="Stroke-1287"
							points="15.5 15.5 0.5 15.5 0.5 0.5 15.5 0.5"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</polygon>
						<path
							d="M8,2.75 L8,15.5"
							id="Stroke-1289"
							stroke={color}
							strokeLinecap="round"
							strokeLinejoin="round"
						>
						</path>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ColumnsIconBuilder;

