import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import AnalyticServiceName from '~/components/names/AnalyticServiceName';
import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import Caption, {
	CaptionStyle,
} from '~/components/patterns/headings/Caption';
import CodeValue from '~/components/patterns/values/CodeValue';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import MissingValue from '~/components/app/MissingValue';
import NamedValue from '~/components/patterns/structuredValues/NamedValue';
import RichText from '~/components/patterns/typography/RichText';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';
import TextInspector from '~/components/patterns/typography/TextInspector';

import {
	useConversionsBlockQuery,
} from './ConversionsBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	LIST_OF_ANALYTICS_SERVICES,
	LIST_OF_TAG_MANAGERS,
	LIST_OF_VISUAL_ANALYTICS_SERVICES,
} from '~/model/analyticServices';



const messages = defineMessages({
	analyticsDetectedButNotIdentified: {
		id: 'ui.pageDetail.conversions.analyticsDetectedButNotIdentified',
	},
	analyticsNotDetected: {
		id: 'ui.pageDetail.conversions.analyticsNotDetected',
	},
	containerId: {
		id: 'ui.pageDetail.conversions.containerId',
	},
	qualitativeAnalytics: {
		id: 'ui.contentData.qualitativeAnalytics',
	},
	quantitativeAnalytics: {
		id: 'ui.contentData.quantitativeAnalytics',
	},
	tagManagement: {
		id: 'ui.contentData.tagManagement',
	},
	title: {
		id: 'ui.pageDetail.conversions.title',
	},
	trackingId: {
		id: 'ui.pageDetail.conversions.trackingId',
	},
});



const sortByFixedOrder = (order) => (itemA, itemB) => {
	if (order.indexOf(itemA.type) > order.indexOf(itemB.type)) {
		return 1;
	}

	if (order.indexOf(itemA.type) < order.indexOf(itemB.type)) {
		return -1;
	}

	return 0;
};



type ValueElementProps = {
	content: any,
	name: React.ReactNode,
};

const ValueElement: React.FC<ValueElementProps> = ({ content, name }) => {
	if (content === null) {
		return (
			<NamedValue name={name}>
				<MissingValue />
			</NamedValue>
		);
	}

	if (content === '') {
		return (
			<MissingValue>
				<Copy
					{...messages.analyticsDetectedButNotIdentified}
					values={{
						name,
						linkArticle: linkExternal('https://www.contentkingapp.com/support/analytics-tracking-detection/'),
					}}
				/>
			</MissingValue>
		);
	}

	if (typeof content === 'string') {
		return (
			<NamedValue name={name}>
				<CodeValue>
					<TextInspector text={content} />
				</CodeValue>
			</NamedValue>
		);
	}

	return (
		<NamedValue name={name}>
			<CodeValue>{content}</CodeValue>
		</NamedValue>
	);
};



const ConversionsBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const { data } = usePageDetailPropertiesQuery(
		useConversionsBlockQuery,
		legacyUrlId,
		websiteId,
	);

	const analyticServices = data?.lookupPageByLegacyId?.pageTypeData?.analyticServices ?? null;
	const tagManagers = data?.lookupPageByLegacyId?.pageTypeData?.tagManagers ?? null;
	const visualAnalyticServices = data?.lookupPageByLegacyId?.pageTypeData?.visualAnalyticServices ?? null;

	function renderSkeleton() {
		return (
			<SquareSkeleton
				maxWidth={Math.floor(Math.random() * (300 - 100 + 1)) + 100}
				style={SquareSkeletonStyle.Light}
			/>
		);
	}

	function renderEmptyValueInfo() {
		return (
			<RichText>
				<MissingValue>
					<Copy
						{...messages.analyticsNotDetected}
						values={{
							linkArticle: linkExternal('https://www.contentkingapp.com/support/analytics-tracking-detection/'),
						}}
					/>
				</MissingValue>
			</RichText>
		);
	}

	function renderValues(
		data: ReadonlyArray<{
			contentItems: ReadonlyArray<{
				content: string | null,
				position: number,
			}>,
			type: GraphQL.AnalyticServiceType | GraphQL.TagManagerType | GraphQL.VisualAnalyticServiceType,
		}>,
		tagLabel: React.ReactNode,
	) {
		return data.map(
			(item) => item.contentItems.map(
				(contentItem) => (
					<DefinitionTerm
						description={contentItem.content !== null && (
							<ValueElement
								content={contentItem.content}
								name={tagLabel}
							/>
						)}
						key={'value-' + item.type + '-' + contentItem.position}
						showRowNumbers={false}
						term={(
							<AnalyticServiceName service={item.type} />
						)}
					/>
				),
			),
		).flat();
	}

	function renderQuantitativeAnalyticsBlock() {
		let values: React.ReactNode;

		if (analyticServices === null) {
			values = renderSkeleton();
		} else {
			if (analyticServices.length > 0) {
				values = renderValues(
					[...analyticServices].sort(sortByFixedOrder(LIST_OF_ANALYTICS_SERVICES)),
					(
						<FormattedMessage {...messages.trackingId} />
					),
				);
			} else {
				values = renderEmptyValueInfo();
			}
		}

		return (
			<DefinitionTermsList>
				<Caption style={CaptionStyle.Highlighted}>
					<FormattedMessage {...messages.quantitativeAnalytics} />
				</Caption>
				{values}
			</DefinitionTermsList>
		);
	}



	function renderQualitativeAnalyticsBlock() {
		let values: React.ReactNode;

		if (visualAnalyticServices === null) {
			values = renderSkeleton();
		} else {
			if (visualAnalyticServices.length > 0) {
				values = renderValues(
					[...visualAnalyticServices].sort(sortByFixedOrder(LIST_OF_VISUAL_ANALYTICS_SERVICES)),
					(
						<FormattedMessage {...messages.trackingId} />
					),
				);
			} else {
				values = renderEmptyValueInfo();
			}
		}

		return (
			<DefinitionTermsList>
				<Caption style={CaptionStyle.Highlighted}>
					<FormattedMessage {...messages.qualitativeAnalytics} />
				</Caption>
				{values}
			</DefinitionTermsList>
		);
	}

	function renderTagManagerBlock() {
		if (
			tagManagers === null
			|| tagManagers.length === 0
		) {
			return false;
		}

		return (
			<DefinitionTermsList>
				<Caption style={CaptionStyle.Highlighted}>
					<FormattedMessage {...messages.tagManagement} />
				</Caption>

				{renderValues(
					[...tagManagers].sort(sortByFixedOrder(LIST_OF_TAG_MANAGERS)),
					(
						<FormattedMessage {...messages.containerId} />
					),
				)}
			</DefinitionTermsList>
		);
	}

	return (
		<BorderedBox
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.Conversions}
				/>
			)}
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			paddingSize={3}
		>
			{renderQuantitativeAnalyticsBlock()}
			{renderQualitativeAnalyticsBlock()}
			{renderTagManagerBlock()}
		</BorderedBox>
	);
};



export default ConversionsBlock;
