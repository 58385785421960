import React from 'react';

import DropdownIconBuilder from './builders/iconsData/dropdownIcon/Dropdown';
import InlineIcon, {
	InlineIconTransformation,
} from './builders/InlineIcon.part';



export enum DropdownIconStyle {
	Default = 'default',
	Greyscale = 'greyscale',
	White = 'white',
}

type Props = {
	/** Icon is flipped when being active */
	isActive?: boolean,
	/** Size of icon */
	size?: number,
	/** Color style */
	style?: DropdownIconStyle,
};



const DropdownIcon: React.FC<Props> = (props) => {
	const {
		isActive = false,
		size = 10,
		style = DropdownIconStyle.Default,
	} = props;

	const iconComponent = DropdownIconBuilder;
	let color: string | undefined = undefined;

	if (style === DropdownIconStyle.Greyscale) {
		color = '#90979e';
	} else if (style === DropdownIconStyle.White) {
		color = '#ffffff';
	}

	const icon = iconComponent.icon({
		color,
		size,
	});

	return (
		<InlineIcon
			gap={false}
			grayscale={style === DropdownIconStyle.Greyscale}
			icon={icon}
			size={size}
			transform={isActive ? InlineIconTransformation.FlipVertically : InlineIconTransformation.Enabled}
			viewBoxHeight={iconComponent.viewBoxHeight}
			viewBoxWidth={iconComponent.viewBoxWidth}
		/>
	);
};



export default DropdownIcon;
