import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';



type Props = {
	children?: React.ReactNode,
	limitReachedTooltip?: React.ReactNode,
	onClickCallback: () => void,
};

const AddAnotherStepButton: React.FC<Props> = (props) => {
	const {
		children,
		limitReachedTooltip,
		onClickCallback,
	} = props;

	return (
		<Button
			disabled={!!limitReachedTooltip}
			icon={(
				<BasicIcon type={BasicIconType.Plus} />
			)}
			onClick={onClickCallback}
			size={ButtonSize.Small}
			style={ButtonStyle.Hollow}
			tooltip={limitReachedTooltip}
			uppercase={true}
		>
			{children}
		</Button>
	);
};



export default AddAnotherStepButton;
