import React, {
	Component,
} from 'react';



class AccordionWrapper extends Component {

	render() {
		const {
			children,
		} = this.props;

		return (
			<div className="accordion-wrapper">
				{children}
			</div>
		);
	}

}



export default AccordionWrapper;
