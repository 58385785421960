import {
	runAction,
} from './actions';



export const pingIntercomViaApi = () => {
	return runAction({
		action: 'ReportBlockedIntercom',
		input: {},
	});
};
