import React from 'react';



type Props = {
	billingCycleConfigurator?: React.ReactNode,
	currencyConfigurator: React.ReactNode,
	pagesConfigurator: React.ReactNode,
	plans?: React.ReactNode,
	plansOverview?: React.ReactNode,
	/** Optional overview of one selected plan */
	selectedPlanOverview?: React.ReactNode,
};



const MobileSignupScreenLayout: React.FC<Props> = (props) => {
	const {
		billingCycleConfigurator,
		currencyConfigurator,
		pagesConfigurator,
		plans,
		plansOverview,
		selectedPlanOverview,
	} = props;

	return (
		<div className="mobile-signup-layout">
			<div className="mobile-signup-layout__configurator mobile-signup-layout__configurator--pages">
				{pagesConfigurator}
			</div>

			{billingCycleConfigurator && (
				<div className="mobile-signup-layout__configurator mobile-signup-layout__configurator--payment-period">
					{billingCycleConfigurator}
				</div>
			)}

			<div className="mobile-signup-layout__plans-configurator">
				{plansOverview && (
					<div className="mobile-signup-layout__plans-overview">
						{plansOverview}
					</div>
				)}

				<div className="mobile-signup-layout__currency-configurator">
					{currencyConfigurator}
				</div>

				{selectedPlanOverview && (
					<div className="mobile-signup-layout__selected-plan-overview">
						{selectedPlanOverview}
					</div>
				)}

				{plans && (
					<div className="mobile-signup-layout__plans">
						{plans}
					</div>
				)}
			</div>
		</div>
	);
};



export default MobileSignupScreenLayout;
