import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import PageLastCheckedMessage, {
	PageLastCheckedMessageType,
} from '~/components/app/PageLastCheckedMessage';
import LastCheckTimestamp, {
	LastCheckTimestampCrawlingType,
	LastCheckTimestampIconAlignment,
} from '~/components/patterns/time/LastCheckTimestamp';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';

import {
	prioritizeSitemap,
} from '~/actions/sitemapsList';



const messages = defineMessages({
	cannotRecheckHint: {
		id: 'ui.screen.sitemapsList.cells.lastChecked.hint.cannotRecheck',
	},
	checkingNowHint: {
		id: 'ui.screen.sitemapsList.cells.lastChecked.hint.checkingNow',
	},
	checkingNow: {
		id: 'ui.screen.sitemapsList.cells.lastChecked.checkingNow',
	},
	canRecheckHint: {
		id: 'ui.screen.sitemapsList.cells.lastChecked.hint.canRecheck',
	},
	pausedHint: {
		id: 'ui.screen.sitemapsList.cells.lastChecked.hint.paused',
	},
	notCheckYet: {
		id: 'ui.screen.sitemapsList.cells.lastChecked.notCheckedYet',
	},
});



type Props = {
	canBePrioritized: boolean,
	isPrioritized: boolean,
	lastCrawledAt: string | null,
	sitemapId: number,
};

const RecrawlSitemapLink: React.FC<Props> = (props) => {
	const {
		canBePrioritized,
		isPrioritized,
		lastCrawledAt,
		sitemapId,
	} = props;

	const dispatch = useDispatch();

	const websiteId = useWebsiteId();
	const websiteIsCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId) ?? false;

	const handleClick = React.useCallback(
		() => {
			dispatch(
				prioritizeSitemap(
					sitemapId,
				),
			);
		},
		[
			dispatch,
			sitemapId,
		],
	);

	let checkedAtLabel: React.ReactNode = null;
	let hintLabel: React.ReactNode = null;

	if (isPrioritized) {
		checkedAtLabel = (
			<FormattedMessage {...messages.checkingNow} />
		);
	} else if (lastCrawledAt === null) {
		checkedAtLabel = (
			<FormattedMessage {...messages.notCheckYet} />
		);
	} else {
		checkedAtLabel = (
			<PageLastCheckedMessage
				date={lastCrawledAt}
			/>
		);
	}

	if (websiteIsCrawlingPaused && lastCrawledAt !== null) {
		hintLabel = (
			<FormattedMessage
				{...messages.pausedHint}
				values={{
					pastRelativeText: (
						<PageLastCheckedMessage
							date={lastCrawledAt}
							messageType={PageLastCheckedMessageType.Sitemap}
						/>
					),
				}}
			/>
		);
	} else if (isPrioritized) {
		hintLabel = (
			<FormattedMessage {...messages.checkingNowHint} />
		);
	} else if (canBePrioritized) {
		hintLabel = (
			<FormattedMessage {...messages.canRecheckHint} />
		);
	} else {
		hintLabel = (
			<FormattedMessage {...messages.cannotRecheckHint} />
		);
	}

	return (
		<LastCheckTimestamp
			checkedAtLabel={checkedAtLabel}
			crawlingType={isPrioritized ? LastCheckTimestampCrawlingType.InProgress : LastCheckTimestampCrawlingType.Ready}
			explanationMessage={hintLabel}
			iconAlignment={LastCheckTimestampIconAlignment.Left}
			isCrawlingPaused={websiteIsCrawlingPaused}
			onClick={(canBePrioritized && !isPrioritized) ? handleClick : undefined}
		/>
	);
};



export default RecrawlSitemapLink;
