import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountFeaturesUsageQuery,
} from './useAccountFeaturesUsage.gql';

import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';

import {
	LIST_OF_ALL_PLANS,
	getPlanAvailabilityModern,
} from '~/model/universal';



type FeaturesUsage = {
	enabledFeatures: Readonly<Array<GraphQL.AccountFeature>>,
	getNecessaryPlan: () => GraphQL.AccountPlan | null,
	getPlanAvailability: (plan: GraphQL.AccountPlan) => ReturnType<typeof getPlanAvailabilityModern>,
	usedFeatures: Readonly<Array<GraphQL.AccountFeature>>,
};



function useAccountFeaturesUsage(accountId: CK.AccountId | null): FeaturesUsage | null {
	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);

	const { data } = useAccountFeaturesUsageQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const features = data?.account?.features ?? null;

	return React.useMemo(
		() => {
			if (
				accountFeaturesAvailability === null
				|| accountPlan === null
				|| accountTariff === null
				|| features === null
			) {
				return null;
			}

			const usedFeatures = features
				.filter((feature) => feature.inUse)
				.map((feature) => feature.feature);

			const getPlanAvailability = (plan: GraphQL.AccountPlan) => {
				return getPlanAvailabilityModern(
					accountFeaturesAvailability,
					accountPlan,
					accountTariff,
					plan,
					usedFeatures,
				);
			};

			return {
				enabledFeatures: features.map((feature) => feature.feature),
				getNecessaryPlan: (): GraphQL.AccountPlan | null => {
					// @ts-ignore
					for (const plan of LIST_OF_ALL_PLANS[accountTariff]) {
						const planAvailability = getPlanAvailability(plan);

						if (planAvailability.isAvailable) {
							return plan;
						}
					}

					return null;
				},
				getPlanAvailability,
				usedFeatures,
			};
		},
		[
			accountFeaturesAvailability,
			accountPlan,
			accountTariff,
			features,
		],
	);
}



export default useAccountFeaturesUsage;
