/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportSegmentsModalQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type ImportSegmentsModalQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly displayName: string, readonly id: CK.WebsiteId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string, readonly files: ReadonlyArray<{ readonly __typename?: 'ImageSizeVersion', readonly scaleFactor: number, readonly url: string }> } | null }> }> } | null };


export const ImportSegmentsModalDocument = gql`
    query ImportSegmentsModal($accountId: AccountId!) {
  account(id: $accountId) {
    id
    websites {
      displayName
      id
      pageSegments {
        color
        filterDefinition
        icon {
          files {
            scaleFactor
            url
          }
          name
        }
        id
        isBeingReevaluated
        isManaged
        label
        name
        shortcode
        sizeLimit
      }
    }
  }
}
    `;

/**
 * __useImportSegmentsModalQuery__
 *
 * To run a query within a React component, call `useImportSegmentsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportSegmentsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportSegmentsModalQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useImportSegmentsModalQuery(baseOptions: Apollo.QueryHookOptions<ImportSegmentsModalQuery, ImportSegmentsModalQueryVariables> & ({ variables: ImportSegmentsModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImportSegmentsModalQuery, ImportSegmentsModalQueryVariables>(ImportSegmentsModalDocument, options);
      }
export function useImportSegmentsModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImportSegmentsModalQuery, ImportSegmentsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImportSegmentsModalQuery, ImportSegmentsModalQueryVariables>(ImportSegmentsModalDocument, options);
        }
export function useImportSegmentsModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ImportSegmentsModalQuery, ImportSegmentsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ImportSegmentsModalQuery, ImportSegmentsModalQueryVariables>(ImportSegmentsModalDocument, options);
        }
export type ImportSegmentsModalQueryHookResult = ReturnType<typeof useImportSegmentsModalQuery>;
export type ImportSegmentsModalLazyQueryHookResult = ReturnType<typeof useImportSegmentsModalLazyQuery>;
export type ImportSegmentsModalSuspenseQueryHookResult = ReturnType<typeof useImportSegmentsModalSuspenseQuery>;
export type ImportSegmentsModalQueryResult = Apollo.QueryResult<ImportSegmentsModalQuery, ImportSegmentsModalQueryVariables>;