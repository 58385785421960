import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ExternalLink from '~/components/patterns/links/ExternalLink';
import FakeLink from '~/components/patterns/links/FakeLink';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SelectField from '~/components/atoms/forms/components/SelectField';
import Spinner from '~/components/patterns/loaders/Spinner';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';
import TextPreloader, {
	TextPreloaderSize,
} from '~/components/patterns/loaders/TextPreloader';

import {
	useConnectGoogleAnalyticsAccountMutation,
	useConnectedGoogleAnalyticsAccountsQuery,
} from './GoogleAnalyticsIntegrationFields.gql';

import {
	validateField,
} from '~/components/app/validations';

import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useFormContext from '~/hooks/useFormContext';

import {
	authenticateGoogleAnalytics,
} from '~/model/googleApi/authenticate';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	account: {
		id: 'ui.websites.form.api.googleAnalytics.account',
	},
	connectAnother: {
		id: 'ui.websites.form.api.googleAnalytics.connectAnother',
	},
	connectLink: {
		id: 'ui.websites.form.api.googleAnalytics.connectLink',
	},
	disabled: {
		id: 'ui.websites.form.api.status.disabled',
	},
	enabled: {
		id: 'ui.websites.form.api.status.enabled',
	},
	property: {
		id: 'ui.websites.form.api.googleAnalytics.property',
	},
	title: {
		id: 'ui.websites.form.api.googleAnalytics.title',
	},
	view: {
		id: 'ui.websites.form.api.googleAnalytics.view',
	},
});



type Props = {
	enableByDefaultWhenSuggestionsAreFound?: boolean,
	websiteId: CK.WebsiteId,
};

const GoogleAnalyticsIntegrationFields: React.FC<Props> = (props) => {
	const {
		enableByDefaultWhenSuggestionsAreFound,
		websiteId,
	} = props;

	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const formContext = useFormContext();

	const {
		data,
		loading: loadingConnectedAccounts,
	} = useConnectedGoogleAnalyticsAccountsQuery({
		skip: effectiveHomeAccountId === null,
		variables: {
			accountId: effectiveHomeAccountId ?? 0,
			websiteId,
		},
		onCompleted: (data) => {
			const suggestedGoogleAnalyticsAccount = data.website?.suggestedGoogleAnalyticsAccount;

			const hasSuggestions = (
				(suggestedGoogleAnalyticsAccount?.googleAnalyticsAccountId ?? null) !== null
				|| (suggestedGoogleAnalyticsAccount?.googleAnalyticsPropertyId ?? null) !== null
				|| (suggestedGoogleAnalyticsAccount?.googleAnalyticsViewId ?? null) !== null
			);

			formContext.setValues({
				googleAnalyticsEnabled: (
					formContext.values.googleAnalyticsEnabled
					?? (hasSuggestions && enableByDefaultWhenSuggestionsAreFound)
					?? false
				),
				googleAnalyticsAccountId: (
					formContext.values.googleAnalyticsAccountId
					?? suggestedGoogleAnalyticsAccount?.googleAnalyticsAccountId
					?? null
				),
				googleAnalyticsPropertyId: (
					formContext.values.googleAnalyticsPropertyId
					?? suggestedGoogleAnalyticsAccount?.googleAnalyticsPropertyId
					?? null
				),
				googleAnalyticsViewId: (
					formContext.values.googleAnalyticsViewId
					?? suggestedGoogleAnalyticsAccount?.googleAnalyticsViewId
					?? null
				),
			});
		},
	});

	const [
		connectGoogleAnalyticsAccount,
		{
			loading: loadingNewConnection,
		},
	] = useConnectGoogleAnalyticsAccountMutation();

	const loading = loadingNewConnection || loadingConnectedAccounts;
	const connectedAccounts = data?.account?.connectedGoogleAnalyticsAccounts ?? [];

	const properties = (
		connectedAccounts
			.find((account) => account.id === formContext.values.googleAnalyticsAccountId)
			?.properties
	) ?? [];

	const selectedProperty = properties.find((property) => property.id === formContext.values.googleAnalyticsPropertyId) ?? null;

	const views = (
		properties
			.find((property) => property.id === selectedProperty?.id)
			?.views
	) ?? [];

	const showViewsSelect = selectedProperty?.version === GraphQL.GoogleAnalyticsVersion.V3;

	async function connectGoogleAnalytics(): Promise<void> {
		if (effectiveHomeAccountId === null) {
			throw new Error(
				`effectiveHomeAccountId can't be null when connecting Google Analytics integration`,
			);
		}

		const googleResponse = await authenticateGoogleAnalytics();

		const { data } = await connectGoogleAnalyticsAccount({
			variables: {
				authCode: googleResponse.code,
				ownerAccountId: effectiveHomeAccountId,
				websiteId,
			},
		});

		const connectedAccounts = data?.ConnectGoogleAnalyticsAccount.query.website?.account?.connectedGoogleAnalyticsAccounts;
		const suggestGoogleAnalyticsAccount = data?.ConnectGoogleAnalyticsAccount.query.website?.suggestedGoogleAnalyticsAccount;

		// Select the first connected Google Analytics by default when no suggested account is present
		// and we only have a single account connected.
		let nextGoogleAnalyticsAccountId = suggestGoogleAnalyticsAccount?.googleAnalyticsAccountId ?? null;
		if (nextGoogleAnalyticsAccountId === null && connectedAccounts?.length === 1) {
			nextGoogleAnalyticsAccountId = getArrayItemAtSafeIndex(connectedAccounts, 0).id;
		}

		formContext.setValues({
			googleAnalyticsEnabled: true,
			googleAnalyticsAccountId: nextGoogleAnalyticsAccountId,
			googleAnalyticsPropertyId: suggestGoogleAnalyticsAccount?.googleAnalyticsPropertyId,
			googleAnalyticsViewId: suggestGoogleAnalyticsAccount?.googleAnalyticsViewId,
		});
	}

	function handleGoogleAnalyticsAccountChange(_, value): void {
		if (value === 'connect_account') {
			connectGoogleAnalytics();

			formContext.setValues({
				googleAnalyticsAccountId: undefined,
				googleAnalyticsPropertyId: undefined,
				googleAnalyticsViewId: undefined,
			});
		} else if (value !== formContext.values.googleAnalyticsAccountId) {
			formContext.setValues({
				googleAnalyticsPropertyId: undefined,
				googleAnalyticsViewId: undefined,
			});
		}
	}

	function handlePropertyIdChange(_, value): void {
		if (value !== formContext.values.googleAnalyticsPropertyId) {
			const selectedProperty = properties.find((property) => property.id === value) ?? null;

			formContext.setValues({
				googleAnalyticsViewId: undefined,
				googleAnalyticsVersion: selectedProperty?.version,
			});
		}
	}

	return (
		<FormRows>
			<FormRow
				label={(
					<FormattedMessage {...messages.title} />
				)}
			>
				{loadingConnectedAccounts && (
					<Spinner />
				)}

				{loadingNewConnection && isEmpty(connectedAccounts) && (
					<Spinner />
				)}

				{!loading && isEmpty(connectedAccounts) && (
					<StaticText>
						<ExternalLink onClickCallback={connectGoogleAnalytics}>
							<FormattedMessage {...messages.connectLink} />
						</ExternalLink>
					</StaticText>
				)}

				{!loadingConnectedAccounts && !isEmpty(connectedAccounts) && (
					<SwitchField
						activeStateLabel={(
							<FormattedMessage {...messages.enabled} />
						)}
						inactiveStateLabel={(
							<FormattedMessage {...messages.disabled} />
						)}
						name="googleAnalyticsEnabled"
					/>
				)}
			</FormRow>

			{formContext.values.googleAnalyticsEnabled && (
				<FormRow
					htmlFor="googleAnalyticsAccountId"
					label={(
						<FormattedMessage {...messages.account} />
					)}
				>
					{loadingNewConnection ? (
						<TextPreloader size={TextPreloaderSize.Small} />
					) : (
						<FieldStatus
							allowOk={false}
							name="validateGoogleAnalyticsAccountId"
						>
							<SelectField
								isDisabled={loading}
								isInteractedByDefault={false}
								name="googleAnalyticsAccountId"
								onChangeCallback={handleGoogleAnalyticsAccountChange}
								options={[
									...connectedAccounts.map((account) => (
										{
											label: account.name,
											name: account.id,
										}
									)),
									{
										label: (
											<FakeLink>
												<FormattedMessage {...messages.connectAnother} />
											</FakeLink>
										),
										name: 'connect_account',
									},
								]}
								searchable={connectedAccounts.length > 7}
							/>
						</FieldStatus>
					)}
				</FormRow>
			)}

			{formContext.values.googleAnalyticsEnabled && (
				<FormRow
					htmlFor="googleAnalyticsPropertyId"
					label={(
						<FormattedMessage {...messages.property} />
					)}
				>
					<FieldStatus
						allowOk={false}
						name="validateGoogleAnalyticsPropertyId"
					>
						<SelectField
							isDisabled={loading || !formContext.values.googleAnalyticsAccountId}
							isInteractedByDefault={false}
							name="googleAnalyticsPropertyId"
							onChangeCallback={handlePropertyIdChange}
							options={properties.map((property) => ({
								description: property.id,
								label: property.name,
								name: property.id,
							}))}
							searchable={properties.length > 7}
						/>
					</FieldStatus>
				</FormRow>
			)}

			{formContext.values.googleAnalyticsEnabled && showViewsSelect && (
				<FormRow
					htmlFor="googleAnalyticsViewId"
					label={(
						<FormattedMessage {...messages.view} />
					)}
				>
					<FieldStatus
						allowOk={false}
						name="validateGoogleAnalyticsViewId"
					>
						<SelectField
							isDisabled={loading || !formContext.values.googleAnalyticsPropertyId}
							isInteractedByDefault={false}
							name="googleAnalyticsViewId"
							options={views.map((view) => ({
								label: view.name,
								name: view.id,
							}))}
							searchable={views.length > 7}
						/>
					</FieldStatus>
				</FormRow>
			)}
		</FormRows>
	);
};



export default GoogleAnalyticsIntegrationFields;

export const validateGoogleAnalyticsAccountId = validateField(
	'googleAnalyticsAccountId',
	(f) => [
		f.whenOtherField(
			'googleAnalyticsEnabled',
			({ value }) => value,
		),
		f.validateNonEmpty(),
	],
);

export const validateGoogleAnalyticsPropertyId = validateField(
	'googleAnalyticsPropertyId',
	(f) => [
		f.whenOtherField(
			'googleAnalyticsEnabled',
			({ value }) => value,
		),
		f.whenOtherField(
			'googleAnalyticsAccountId',
			({ value }) => value,
		),
		f.validateNonEmpty(),
	],
);

export const validateGoogleAnalyticsViewId = validateField(
	'googleAnalyticsViewId',
	(f) => [
		f.whenOtherField(
			'googleAnalyticsEnabled',
			({ value }) => value,
		),
		f.whenOtherField(
			'googleAnalyticsPropertyId',
			({ value }) => value,
		),
		f.whenOtherField(
			'googleAnalyticsVersion',
			({ value }) => value === GraphQL.GoogleAnalyticsVersion.V3,
		),
		f.validateNonEmpty(),
	],
);
