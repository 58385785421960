import React, {
	Component,
} from 'react';



type Props = {
	/** Specific call-to-action icon to component */
	ctaIcon?: React.ReactNode,
	/** Callback triggered when clicking on call-to-action icon */
	ctaIconOnClickCallback?: () => void,
	/** Checkbox label */
	label: React.ReactNode,
	/** Hoverable stamp placed above label on hover */
	labelHoverStamp?: React.ReactNode,
	/** Optional suffix attached to label */
	suffix?: React.ReactNode,
};



class CheckboxLabel extends Component<Props> {

	constructor(props, context) {
		super(props, context);

		this._handleCTAClick = this._handleCTAClick.bind(this);
	}



	_handleCTAClick(e) {
		const {
			ctaIconOnClickCallback,
		} = this.props;

		if (ctaIconOnClickCallback) {
			e.preventDefault();
			e.stopPropagation();

			ctaIconOnClickCallback();
		}
	}



	render() {
		const {
			ctaIcon,
			label,
			labelHoverStamp,
			suffix,
		} = this.props;

		return (
			<span className="checkbox-label">
				<span className="checkbox-label__container">
					<span className="checkbox-label__label">
						{label}
						{labelHoverStamp && (
							<span className="checkbox-label__label-stamp">
								{labelHoverStamp}
							</span>
						)}
					</span>
					{suffix && (
						<span className="checkbox-label__suffix">
							{suffix}
						</span>
					)}
					{ctaIcon && (
						<span
							className="checkbox-label__cta-icon"
							onClick={this._handleCTAClick}
						>
							{ctaIcon}
						</span>
					)}
				</span>
			</span>
		);
	}

}



export default CheckboxLabel;
