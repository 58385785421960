import React from 'react';

import {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ButtonContextProvider from '~/components/atoms/buttons/ButtonContextProvider';



type Props = {
	/** Action buttons */
	actionButtons?: Array<React.ReactNode>,
	/** Separated group with destructive buttons (like delete button) */
	destructiveButtons?: Array<React.ReactNode>,
	/** Count of selected items for bulk actions */
	label: React.ReactNode,
};



const BulkActionsController: React.FC<Props> = (props) => {
	const {
		actionButtons = [],
		destructiveButtons = [],
		label,
	} = props;

	return (
		<div className="bulk-actions-controller">
			<div className="bulk-actions-controller__label">
				{label}
			</div>

			<ButtonContextProvider
				compact={true}
				iconColor={false}
				iconSize={20}
				size={ButtonSize.XSmall}
				style={ButtonStyle.Hollow}
			>
				{actionButtons.length > 0 && (
					<div className="bulk-actions-controller__buttons">
						{actionButtons.map((button, index) => {
							return (
								<div
									className="bulk-actions-controller__button"
									key={'action-button-' + index}
								>
									{button}
								</div>
							);
						})}
					</div>
				)}

				{destructiveButtons.length > 0 && (
					<div className="bulk-actions-controller__buttons">
						{destructiveButtons.map((button, index) => {
							return (
								<div
									className="bulk-actions-controller__button"
									key={'destructive-button-' + index}
								>
									{button}
								</div>
							);
						})}
					</div>
				)}
			</ButtonContextProvider>
		</div>
	);
};



export default BulkActionsController;
