import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	OPERATOR_CONTAINS,
	OPERATOR_EMPTY,
	OPERATOR_EMPTY_OR_MISSING,
	OPERATOR_ENDS_WITH,
	OPERATOR_EQUALS,
	OPERATOR_MATCHES,
	OPERATOR_MISSING,
	OPERATOR_NOT_CONTAINING,
	OPERATOR_NOT_MATCHES,
	OPERATOR_PRESENT,
	OPERATOR_STARTS_WITH,
} from '~/components/atoms/forms/components/ExpandableFilterField';



const messages = defineMessages({
	[OPERATOR_CONTAINS]: {
		id: 'ui.expandableFilter.operator.contains',
	},
	[OPERATOR_EMPTY]: {
		id: 'ui.expandableFilter.operator.empty',
	},
	[OPERATOR_EMPTY_OR_MISSING]: {
		id: 'ui.expandableFilter.operator.empty_or_missing',
	},
	[OPERATOR_ENDS_WITH]: {
		id: 'ui.expandableFilter.operator.ends_with',
	},
	[OPERATOR_EQUALS]: {
		id: 'ui.expandableFilter.operator.equals',
	},
	[OPERATOR_MATCHES]: {
		id: 'ui.expandableFilter.operator.matches',
	},
	[OPERATOR_MISSING]: {
		id: 'ui.expandableFilter.operator.missing',
	},
	[OPERATOR_NOT_CONTAINING]: {
		id: 'ui.expandableFilter.operator.not_contains',
	},
	[OPERATOR_NOT_MATCHES]: {
		id: 'ui.expandableFilter.operator.not_matches',
	},
	[OPERATOR_PRESENT]: {
		id: 'ui.expandableFilter.operator.present',
	},
	[OPERATOR_STARTS_WITH]: {
		id: 'ui.expandableFilter.operator.starts_with',
	},
});



type Props = {
	operator: string,
};

const ExpandableFilterOperatorName: React.FC<Props> = (props) => {
	const {
		operator,
	} = props;

	if (!messages[operator]) {
		return (
			<>{operator}</>
		);
	}

	return (
		<FormattedMessage {...messages[operator]} />
	);
};



export default ExpandableFilterOperatorName;
