import classNames from 'classnames';
import React from 'react';



type Props = {
	children: React.ReactNode,
	/** Manually reveal sticky overview (it's hidden by default) */
	isVisible?: boolean,
	/** Reserve gap for scrollbar on the right sideof element */
	scrollbarOffset?: number,
	/** Element placed in sidebar */
	sidebar?: React.ReactNode,
};



const SignupPlansStickyOverview: React.FC<Props> = (props) => {
	const {
		children,
		isVisible,
		scrollbarOffset,
		sidebar,
	} = props;

	const componentClasses = classNames({
		'signup-plans-sticky-overview': true,
		'signup-plans-sticky-overview--is-visible': isVisible,
	});

	const wrapperClasses = classNames({
		'signup-plans-sticky-overview__wrapper': true,
		'signup-plans-sticky-overview__wrapper--is-visible': isVisible,
	});

	return (
		<div
			className={wrapperClasses}
			style={{
				right: scrollbarOffset,
			}}
		>
			<div className={componentClasses}>
				<div className="signup-plans-sticky-overview__content">
					<div className="signup-plans-sticky-overview__sidebar">
						{sidebar}
					</div>
					<div className="signup-plans-sticky-overview__plans-comparison">
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};



export default SignupPlansStickyOverview;
