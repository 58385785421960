import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	Link,
} from 'react-router5';

import ConductorLink from '~/components/patterns/links/ConductorLink';
import LogoutMenuItem from '~/components/app/LogoutMenuItem';
import MainNavigation from '~/components/logic/navigations/MainNavigation';
import NavigationIcon, {
	NavigationIconType,
} from '~/components/patterns/icons/NavigationIcon';
import MenuItemLayout from '~/components/atoms/navigations/builders/MenuItemLayout';
import MultiwebsitesNavigation from '~/components/atoms/navigations/websites/MultiwebsitesNavigation';
import ScreenSidebarLayout from '~/components/patterns/layout/ScreenSidebarLayout';
import ShadowLogo from '~/components/patterns/layout/ShadowLogo';
import SidebarProfileInfo from '~/components/app/SidebarProfileInfo';
import SidebarProfileLayout from '~/components/patterns/layout/SidebarProfileLayout';
import SiteLogo from '~/components/app/SiteLogo';
import StatusDot, {
	StatusDotStatus,
} from '~/components/patterns/statuses/StatusDot';
import TeamWebsitesDropdown from '~/components/app/TeamWebsitesDropdown';
import UserNav from '~/components/patterns/navigations/UserNav';
import UserNavigationDropdown from '~/components/app/UserNavigationDropdown';
import UserNavigationToggler from '~/components/app/UserNavigationToggler';
import WebsitesNavToggle from '~/components/app/WebsitesNavToggle';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsShadow from '~/hooks/useAccountIsShadow';
import useAccountProblems from '~/hooks/useAccountProblems';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useHasWebsitesScreen from '~/hooks/useHasWebsitesScreen';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useOpenSwitchAccountModal from '~/hooks/useOpenSwitchAccountModal';
import useUrlState from '~/hooks/useUrlState';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteAccountName from '~/hooks/useWebsiteAccountName';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	IsConductorClient,
} from '~/config';



const messages = defineMessages({
	account: {
		id: 'ui.menu.account',
	},
	conductor: {
		id: 'ui.menu.conductor',
	},
	profile: {
		id: 'ui.menu.profile',
	},
});



const ScreenSidebar: React.FC = () => {
	const accountId = useAccountId();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const websiteId = useWebsiteId();

	const hasWebsitesScreen = useHasWebsitesScreen();
	const homeAccountProblems = useAccountProblems(effectiveHomeAccountId);
	const accountIsShadow = useAccountIsShadow(effectiveHomeAccountId);
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const openSwitchAccountModal = useOpenSwitchAccountModal(true);
	const urlState = useUrlState();
	const viewportType = useViewportType();
	const websiteAccountName = useWebsiteAccountName(websiteId);

	function isLogoVisible() {
		// we will hide logo on smaller screens with websites menu item
		return !(viewportType.isSmall && hasWebsitesScreen);
	}

	let statusIcon: React.ReactNode;

	if (
		homeAccountProblems.hasWebsitesAtPageCapacity
		|| homeAccountProblems.isSubscriptionSuspended
	) {
		statusIcon = (
			<StatusDot status={StatusDotStatus.Critical} />
		);
	} else if (homeAccountProblems.hasPaymentProblem) {
		statusIcon = (
			<StatusDot status={StatusDotStatus.Warning} />
		);
	}

	const footerItems: Array<{
		content: React.ReactNode,
		highlighted?: boolean,
		name: string,
	}> = [];

	let conductorNavigation;
	let profile;

	if (
		!viewportType.isSmall
		&& !viewportType.isMedium
		&& kingdomAdminFeatures.areVisible
	) {
		footerItems.push({
			content: (
				<MenuItemLayout
					icon={(
						<NavigationIcon type={NavigationIconType.SwitchAccount} />
					)}
					label="Switch Account"
					onClickCallback={openSwitchAccountModal}
				/>
			),
			name: 'switchAccount',
		});

		footerItems.push({
			content: (
				<Link routeName="generalAdmin">
					<MenuItemLayout
						icon={(
							<NavigationIcon type={NavigationIconType.Lock} />
						)}
						label="General Admin"
					/>
				</Link>
			),
			name: 'generalAdmin',
		});
	}

	if (
		!viewportType.isSmall
		&& !viewportType.isMedium
		&& kingdomAdminFeatures.areAlwaysVisible
	) {
		footerItems.push({
			content: (
				<MenuItemLayout
					icon={(
						<NavigationIcon type={NavigationIconType.ToggleAdmin} />
					)}
					label="Toggle Admin"
					onClickCallback={kingdomAdminFeatures.toggle}
				/>
			),
			name: 'toggleAdmin',
		});
	}

	if (!viewportType.isSmall) {
		footerItems.push({
			content: (
				<Link
					routeName="account.websites"
					routeParams={{
						accountId: accountId ?? effectiveHomeAccountId,
					}}
				>
					<MenuItemLayout
						icon={(
							<NavigationIcon type={NavigationIconType.Account} />
						)}
						iconTag={statusIcon}
						label={(
							<FormattedMessage {...messages.account} />
						)}
					/>
				</Link>
			),
			highlighted: urlState.name.startsWith('account.'),
			name: 'account',
		});

		footerItems.push({
			content: (
				<Link routeName="userProfile">
					<MenuItemLayout
						icon={(
							<NavigationIcon type={NavigationIconType.Profile} />
						)}
						label={(
							<FormattedMessage {...messages.profile} />
						)}
					/>
				</Link>
			),
			highlighted: urlState.name == 'userProfile',
			name: 'profile',
		});
	}

	if (IsConductorClient && !viewportType.isSmall) {
		conductorNavigation = (
			<ConductorLink href="https://app.conductor.com">
				<MenuItemLayout
					icon={(
						<NavigationIcon type={NavigationIconType.Conductor} />
					)}
					label={(
						<FormattedMessage {...messages.conductor} />
					)}
				/>
			</ConductorLink>
		);
	}

	if (viewportType.isSmall || viewportType.isMedium) {
		profile = (
			<UserNavigationToggler />
		);
	} else {
		profile = (
			<SidebarProfileLayout
				logoutLink={(
					<LogoutMenuItem />
				)}
				profileOverview={(
					<Link routeName="userProfile">
						<SidebarProfileInfo />
					</Link>
				)}
			/>
		);
	}

	return (
		<ScreenSidebarLayout
			conductorNavigation={conductorNavigation}
			header={hasWebsitesScreen && (
				<MultiwebsitesNavigation />
			)}
			logo={(
				accountIsShadow ? (
					<ShadowLogo />
				) : isLogoVisible() ? (
					<SiteLogo
						accountId={effectiveHomeAccountId}
					/>
				) : (
					null
				)
			)}
			monochrome={accountIsShadow ?? false}
			profile={profile}
			userNavigation={footerItems.length > 0 ? (
				<UserNav items={footerItems} />
			) : undefined}
			userNavigationDropdown={viewportType.isSmall || viewportType.isMedium ? (
				<UserNavigationDropdown />
			) : undefined}
			websitesNavigation={(
				<WebsitesNavToggle accountName={websiteAccountName} />
			)}
			websitesNavigationDropdown={(
				<TeamWebsitesDropdown />
			)}
		>
			<MainNavigation />
		</ScreenSidebarLayout>
	);
};



export default ScreenSidebar;
