import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy from '~/components/logic/Copy';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';

import {
	type Values,
} from './types';



const messages = defineMessages({
	createdWithoutUserDescription: {
		id: 'ui.connectedAccounts.modal.connectAccount.accountCreatedStep.createdWithoutUserDescription',
	},
	createdWithUserDescription: {
		id: 'ui.connectedAccounts.modal.connectAccount.accountCreatedStep.createdWithUserDescription',
	},
	heading: {
		id: 'ui.connectedAccounts.modal.connectAccount.accountCreatedStep.heading',
	},
	ok: {
		id: 'ui.general.ok',
	},
	title: {
		id: 'ui.connectedAccounts.modal.connectAccount.accountCreatedStep.title',
	},
});



type Props = {
	values: Values,
	onClose: () => void,
};

const AccountCreatedStep: React.FC<Props> = (props) => {
	const {
		values,
		onClose,
	} = props;

	return (
		<MultiStepModalStep
			name="accountCreated"
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ConfirmationMessage
				animate={true}
				context={ConfirmationMessageContext.Confirmation}
				ctaButtons={(
					<Button
						onClick={onClose}
						style={ButtonStyle.Action}
						uppercase={false}
						width={ButtonWidth.SubmitButton}
					>
						<FormattedMessage {...messages.ok} />
					</Button>
				)}
				message={<FormattedMessage {...messages.heading} />}
			>
				{values.email && values.email.length > 0 ? (
					<Copy
						{...messages.createdWithUserDescription}
						values={{
							email: values.email,
						}}
					/>
				) : (
					<Copy
						{...messages.createdWithoutUserDescription}
						values={{
							accountName: values.accountName,
						}}
					/>
				)}
			</ConfirmationMessage>

		</MultiStepModalStep>
	);
};



export default AccountCreatedStep;
