import {
	format,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';

import DateLabel from '~/components/app/DateLabel';
import IndexNowSubmissionBadge from '~/components/app/IndexNowSubmissionBadge';
import TimelineEntry from '~/components/patterns/time/timeline/TimelineEntry';
import ViewSnapshotLink from '~/components/logic/pageDetail/components/ViewSnapshotLink';

import {
	type HistoricalMomentFragment,
} from './HistoricalData.gql';

import useIsoDate from '~/hooks/useIsoDate';



type Props = {
	children: React.ReactNode,
	historicalMoment: HistoricalMomentFragment,
	isFirstEntry?: boolean,
	isLastEntry?: boolean,
	urlId: number,
	websiteId: CK.WebsiteId,
};

const HistoricalMomentTimelineEntry: React.FC<Props> = (props) => {
	const {
		children,
		historicalMoment,
		isFirstEntry,
		isLastEntry,
		urlId,
		websiteId,
	} = props;

	const timestamp = useIsoDate(historicalMoment.trackedAt);

	return (
		<TimelineEntry
			additionalInfo={[
				<ViewSnapshotLink
					key="viewSnapshot"
					snapshotStatus={historicalMoment.snapshotStatusForHeaders}
					timestamp={timestamp}
					urlId={urlId}
					websiteId={websiteId}
				/>,
				historicalMoment.indexNowSubmission !== null && (
					<IndexNowSubmissionBadge
						indexNowSubmission={historicalMoment.indexNowSubmission}
					/>
				),
			]}
			date={(
				<DateLabel date={timestamp} />
			)}
			isFirstChild={isFirstEntry}
			isLastChild={isLastEntry}
			time={format(timestamp, 'p')}
		>
			{children}
		</TimelineEntry>
	);
};



export default HistoricalMomentTimelineEntry;
