import classNames from 'classnames';
import React from 'react';



export enum PlansComparisonTableHeadlineColumnCustomerType {
	Agency = 'agency',
	Ecommerce = 'e-commerce',
	Marketplace = 'marketplace',
	Publisher = 'publisher',
	TravelWebsite = 'travel',
}

type Props = {
	/** List of plan benefits */
	benefits: React.ReactNode,
	/** Defined customer type can change visible pro-tip message */
	customerType: PlansComparisonTableHeadlineColumnCustomerType | null,
	/** Details about plan */
	details: React.ReactNode,
	/** Attach flag to whole plan */
	flag?: React.ReactNode,
	/** Look of plan in disabled state */
	isDisabled?: boolean,
	/** Apply enterprise highlight */
	isEnterprise?: boolean,
	/** Flag whether plan is first child in the row */
	isFirst?: boolean,
	/** Possible to indent this element by left gap from others */
	isIndented?: boolean,
	/** Flag whether plan is last child in the row */
	isLast?: boolean,
	/** Possibility to enable pro-tip */
	visibleProTip?: boolean,
};



const PlansComparisonTableHeadlineColumn: React.FC<Props> = (props) => {
	const {
		benefits,
		customerType,
		details,
		flag,
		isDisabled,
		isEnterprise,
		isFirst,
		isIndented,
		isLast,
		visibleProTip,
	} = props;

	let componentClasses = classNames({
		'plans-comparison-table-headline-column': true,
		'plans-comparison-table-headline-column--is-disabled': isDisabled,
		'plans-comparison-table-headline-column--is-first': isFirst,
		'plans-comparison-table-headline-column--is-enterprise': isEnterprise,
		'plans-comparison-table-headline-column--is-highlighted': flag,
		'plans-comparison-table-headline-column--has-flag': flag,
		'plans-comparison-table-headline-column--is-indented': isIndented,
		'plans-comparison-table-headline-column--is-last': isLast,
		'plans-comparison-table-headline-column--pro-tip': visibleProTip,
		'dark-bg-context': isEnterprise,
	});

	if (customerType !== null) {
		componentClasses = classNames(componentClasses, {
			['plans-comparison-table-headline-column--customer-type-' + customerType]: true,
		});
	}

	return (
		<div className={componentClasses}>
			{flag && (
				<div className="plans-comparison-table-headline-column__flag">
					{flag}
				</div>
			)}

			<div className="plans-comparison-table-headline-column__plan-details">
				{details}
			</div>

			<div className="plans-comparison-table-headline-column__benefits">
				{benefits}
			</div>
		</div>
	);
};



export default PlansComparisonTableHeadlineColumn;
