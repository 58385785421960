import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ThreatreIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M10.073,5a18.1,18.1,0,0,0,6.69.716A.249.249,0,0,0,17,5.471V1A.994.994,0,0,0,15.627.075C12.191,1.467,4.809,1.468,1.373.076A.994.994,0,0,0,0,1V7.746c0,3.58,2.757,6.738,5.232,8.506A.25.25,0,0,0,5.622,16a8.764,8.764,0,0,1-.206-1.868V8.14A3.383,3.383,0,0,1,10.073,5Z"
				fill={color}
			/>
			<path
				d="M23.17,6.576a1.882,1.882,0,0,0-1.763-.182c-2.836,1.149-9.062,1.149-11.9,0A1.882,1.882,0,0,0,6.916,8.14v5.987c0,5.389,6.2,9.873,8.542,9.873S24,19.516,24,14.127V8.14A1.881,1.881,0,0,0,23.17,6.576ZM22,14.127C22,18.368,16.7,22,15.458,22s-6.542-3.632-6.542-7.873V8.665a.25.25,0,0,1,.33-.237,23.7,23.7,0,0,0,12.424,0,.25.25,0,0,1,.33.237Z"
				fill={color}
			/>
			<path
				d="M18.454,16.105a.753.753,0,0,0-1.025.273,2.277,2.277,0,0,1-3.942,0,.75.75,0,1,0-1.3.752,3.777,3.777,0,0,0,6.538,0A.751.751,0,0,0,18.454,16.105Z"
				fill={color}
			/>
			<path
				d="M18.293,10.748a2.253,2.253,0,0,0-2.123,1.5.75.75,0,1,0,1.415.5.75.75,0,0,1,1.415,0,.751.751,0,0,0,.708.5.77.77,0,0,0,.249-.042.751.751,0,0,0,.459-.957A2.255,2.255,0,0,0,18.293,10.748Z"
				fill={color}
			/>
			<path
				d="M13.331,12.749a.75.75,0,0,0,.708.5.775.775,0,0,0,.249-.042.75.75,0,0,0,.458-.957,2.251,2.251,0,0,0-4.246,0,.751.751,0,1,0,1.416.5.75.75,0,0,1,1.415,0Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ThreatreIconBuilder;

