import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useCurrentColumnsSetup from '~/hooks/useCurrentColumnsSetup';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';
import useWebsiteIsLogFileAnalysisEnabled from './useWebsiteIsLogFileAnalysisEnabled';

import {
	PagesColumnsCategory,
} from '~/model/pagesColumns';



type ReturnType = Readonly<Array<{
	name: PagesColumnsCategory,
	columns: ReadonlyArray<CK.PagesColumn>,
	enabledColumns: ReadonlyArray<CK.PagesColumn>,
}>>;



function useWebsiteColumnCategories(): ReturnType {
	const websiteId = useWebsiteId();

	const currentColumnsSetup = useCurrentColumnsSetup();
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const isLighthouseMonitored = useWebsiteIsLighthouseMonitored(websiteId);
	const isLogFileAnalysisEnabled = useWebsiteIsLogFileAnalysisEnabled(websiteId);
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	return React.useMemo(
		() => {
			const result: Array<{
				name: PagesColumnsCategory,
				columns: Array<CK.PagesColumn>,
				enabledColumns: Array<CK.PagesColumn>,
			}> = [];

			currentColumnsSetup.forEach((category) => {
				const columnCategory = category.name;

				if (
					columnCategory === PagesColumnsCategory.Lighthouse
					&& isLighthouseMonitored !== true
				) {
					return;
				}

				if (
					columnCategory === PagesColumnsCategory.SearchEngineActivity
					&& isLogFileAnalysisEnabled !== true
				) {
					return;
				}

				if (
					columnCategory === PagesColumnsCategory.AdobeAnalytics
					&& websiteIntegrations.isEnabled(GraphQL.WebsiteIntegrationType.AdobeAnalytics) === false
				) {
					return;
				}

				const columns: Array<CK.PagesColumn> = [];
				const enabledColumns: Array<CK.PagesColumn> = [];

				category.columns.filter((column) => {
					if (columnCategory === PagesColumnsCategory.CustomElements) {
						return customElementDefinitions.getByColumn(column.name) !== null;
					}

					if (columnCategory === PagesColumnsCategory.EnrichmentFields) {
						return enrichmentFieldDefinitions.getByColumn(column.name) !== null;
					}

					return true;
				}).forEach((column) => {
					columns.push(column.name);

					if (column.enabled) {
						enabledColumns.push(column.name);
					}
				});

				if (columns.length === 0) {
					return;
				}

				result.push({
					name: columnCategory,
					columns,
					enabledColumns,
				});
			});

			return result;
		},
		[
			currentColumnsSetup,
			customElementDefinitions,
			enrichmentFieldDefinitions,
			isLighthouseMonitored,
			isLogFileAnalysisEnabled,
			websiteIntegrations,
		],
	);
}



export default useWebsiteColumnCategories;
