import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ColumnSetName from './ColumnSetName';
import IconOnlyButton from '~/components/patterns/buttons/IconOnlyButton';
import SimpleNavigationItem from '~/components/patterns/navigations/simpleNavigation/SimpleNavigationItem';

import useChangeColumnSet from '~/hooks/useChangeColumnSet';

import {
	toggleColumnsSetsList,
} from '~/actions';



const messages = defineMessages({
	edit: {
		id: 'ui.forms.editLink',
	},
});



type Props = {
	columnSets: ReadonlyArray<{
		id: CK.ColumnSetId,
		name: string,
	}>,
	currentColumnSet: {
		id: CK.ColumnSetId,
	} | null,
};

const ColumnsSetsList: React.FC<Props> = (props) => {
	const {
		columnSets,
		currentColumnSet,
	} = props;

	return (
		<>
			{columnSets.map(
				(columnSet) => (
					<ColumnsSetsListItem
						columnSet={columnSet}
						currentColumnSetId={currentColumnSet?.id ?? null}
						key={columnSet.id}
					/>
				),
			)}
		</>
	);
};

type ColumnsSetsListItemProps = {
	columnSet: {
		id: CK.ColumnSetId,
		name: string,
	},
	currentColumnSetId: CK.ColumnSetId | null,
};

const ColumnsSetsListItem: React.FC<ColumnsSetsListItemProps> = (props) => {
	const {
		columnSet,
		currentColumnSetId,
	} = props;

	const changeColumnSet = useChangeColumnSet();
	const dispatch = useDispatch();
	const intl = useIntl();

	const handleSelectSet = React.useCallback(
		() => {
			if (currentColumnSetId !== columnSet.id) {
				changeColumnSet(columnSet.id, true);
			}
		},
		[
			changeColumnSet,
			columnSet,
			currentColumnSetId,
		],
	);

	const handleOpenColumnsConfigurator = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.stopPropagation();

			if (currentColumnSetId !== columnSet.id) {
				changeColumnSet(columnSet.id, false);
			} else {
				dispatch(toggleColumnsSetsList(false));
			}
		},
		[
			changeColumnSet,
			columnSet,
			currentColumnSetId,
			dispatch,
		],
	);

	return (
		<SimpleNavigationItem
			isHighlighted={currentColumnSetId === columnSet.id}
			key={columnSet.id}
			onClickCallback={handleSelectSet}
			specialCTAElement={(
				<IconOnlyButton
					icon={(
						<BasicIcon type={BasicIconType.CogWheel} />
					)}
					onClick={handleOpenColumnsConfigurator}
					title={intl.formatMessage(messages.edit)}
				/>
			)}
		>
			<ColumnSetName columnSet={columnSet} />
		</SimpleNavigationItem>
	);
};



export default ColumnsSetsList;
