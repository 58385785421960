import React from 'react';

import CancelButton from '~/components/app/CancelButton';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import ItemPerLineTextArea from '~/components/app/ItemPerLineTextArea';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';

import {
	validateField,
} from '~/components/app/validations';

import {
	useAddTrialAbuserEmailsMutation,
} from './AdminAddTrialAbuserEmailsModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const validations = {
	validateEmails: validateField(
		'emailPatterns',
		(f) => [
			f.validateEmailPatterns(),
		],
	),
};



const AdminAddTrialAbuserEmailsModal: React.FC = () => {
	const classicFormBehavior = useClassicFormBehavior();

	const [addTrialAbuserEmails] = useAddTrialAbuserEmailsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await addTrialAbuserEmails({
				variables: {
					emailPatterns: values.emailPatterns,
				},
			});

			classicFormBehavior.finish();
		},
		[
			addTrialAbuserEmails,
			classicFormBehavior,
		],
	);

	return (
		<SimpleModal
			size={SimpleModalSize.Small}
			title="Add trial abuser emails"
		>
			<Form
				defaultFocus="emailPatterns"
				defaultValues={{
					emailPatterns: '',
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRow
					description={(
						<>
							Place one email or entire domain per line.
						</>
					)}
					fullwidth={true}
					htmlFor="emailPatterns"
				>
					<FieldStatus name="validateEmails">
						<ItemPerLineTextArea
							name="emailPatterns"
							size={{
								rows: 8,
								width: '100%',
							}}

						/>
					</FieldStatus>
				</FormRow>

				<ButtonsLayout>
					<CancelButton />

					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default AdminAddTrialAbuserEmailsModal;
