import Immutable, {
	List,
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
	LOGOUT_SUCCESSFUL,
	UPDATE_ISSUES_FILTER,
} from '~/actions';

import {
	MARK_START_OF_LOADING,
	STORE,
	UPDATE_FILTER,
	UPDATE_SORT_BY,
} from '~/actions/affectedPagesComparison';

import {
	DEFAULT_SORT_BY,
} from '~/model/affectedPagesComparison';

import {
	decodeFilterParameter,
} from '~/model/filterParameter';

import {
	getAbsoluteCategoryType,
	getAbsoluteIssueType,
} from '~/model/issues/identifierMapping';

import {
	coverSpan,
	createCoverage,
} from '~/utilities/coverage';



function keepOnlyBasicFilter(unpackedFilter) {
	const trimmedFilter = {};

	trimmedFilter.filter = unpackedFilter.filter.filter((filterValue, filterName) => {
		return (
			filterName === 'segment'
			|| filterName === 'type'
			|| filterName === 'issue_change'
		);
	});
	trimmedFilter.sortBy = unpackedFilter.sortBy;

	return trimmedFilter;
}



function createDefaultState() {
	return Map({
		category: null,
		data: Map(),
		filter: Map(),
		loading: true,
		sortBy: DEFAULT_SORT_BY,
		type: null,
		websiteId: null,
	});
}



function createDefaultWebsite() {
	return new Map({
		loaded: false,
		pages: new Map(),
		range: new List(),
		rangeInLoading: createCoverage(),
		total: null,
	});
}



export function affectedPagesComparison(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			const {
				previousUrlState,
				urlState,
			} = action;

			if (urlState.params.websiteId !== previousUrlState.params.websiteId) {
				state = createDefaultState();
			}

			if (state.get('websiteId') !== urlState.params.websiteId) {
				state = state.set('websiteId', urlState.params.websiteId);
			}

			if (urlState.name.indexOf('website.issues') === 0) {
				const websiteId = urlState.params.websiteId;

				if (!state.get('data').has(websiteId)) {
					state = state.setIn(
						['data', websiteId],
						createDefaultWebsite(),
					);
				}
			}

			if (['website.issues.issueCategory.affectedPages', 'website.issues.issueCategory.singleIssueAffectedPages'].indexOf(urlState.name) > -1) {
				if (!state.get('data').has(urlState.params.websiteId)) {
					state = state.set('loading', true);
				}

				if (urlState.params.issuesOverviewCategory && state.get('category') !== urlState.params.issuesOverviewCategory) {
					state = state.set(
						'category',
						urlState.params.issuesOverviewCategory
							? getAbsoluteCategoryType(
								urlState.params.issuesOverviewCategory,
							)
							: urlState.params.issuesOverviewCategory,
					);

					state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
					state = state.setIn(['data', state.get('websiteId'), 'total'], null);
				}

				if (urlState.params.issuesOverviewIssue && state.get('type') !== urlState.params.issuesOverviewIssue) {
					state = state.set(
						'type',
						urlState.params.issuesOverviewIssue
							? getAbsoluteIssueType(
								urlState.params.issuesOverviewCategory,
								urlState.params.issuesOverviewIssue,
							)
							: urlState.params.issuesOverviewIssue,
					);

					state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
					state = state.setIn(['data', state.get('websiteId'), 'total'], null);
				}

				const issueOrCategoryChanged = (
					previousUrlState.name
					&& (
						previousUrlState.params.issuesOverviewCategory !== urlState.params.issuesOverviewCategory
						|| previousUrlState.params.issuesOverviewIssue !== urlState.params.issuesOverviewIssue
					)
				);

				if (issueOrCategoryChanged) {
					state = state.set('loading', true);

					if (state.get('data').has(urlState.params.websiteId)) {
						state = state.setIn(
							['data', urlState.params.websiteId],
							createDefaultWebsite(),
						);
					}
				}

				let newFilter;
				let newSortBy;

				if (urlState.params.filter) {
					let unpackedFilter = decodeFilterParameter(urlState.params.filter);

					if (issueOrCategoryChanged) {
						unpackedFilter = keepOnlyBasicFilter(unpackedFilter);
					}

					if (unpackedFilter.filter) {
						newFilter = unpackedFilter.filter;
					}

					if (unpackedFilter.sortBy) {
						newSortBy = unpackedFilter.sortBy;
					}
				} else {
					newFilter = Map();
					newSortBy = DEFAULT_SORT_BY;
				}

				if (!Immutable.is(state.get('filter'), newFilter)) {
					state = state.set('filter', newFilter);
					state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
					state = state.setIn(['data', state.get('websiteId'), 'total'], null);
				}

				if (!Immutable.is(state.get('sortBy'), newSortBy)) {
					state = state.set('sortBy', newSortBy);
					state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
					state = state.setIn(['data', state.get('websiteId'), 'total'], null);
				}
			}

			break;
		}

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case MARK_START_OF_LOADING: {
			const {
				initiatedAt,
				limit,
				offset,
				websiteId,
			} = action;

			state = state.setIn(
				['data', websiteId, 'rangeInLoading'],
				coverSpan(
					state.getIn(['data', websiteId, 'rangeInLoading']),
					offset,
					limit,
					(initiatedAt / 1000) + 30,
				),
			);

			break;
		}

		case STORE: {
			const {
				websiteId,
				total,
				offset,
				range,
				pages,
			} = action;

			state = state.set('loading', false);

			if (!state.get('data').has(websiteId)) {
				state = state.setIn(
					['data', websiteId],
					createDefaultWebsite(),
				);
			}

			state = state.setIn(
				['data', websiteId, 'total'],
				total,
			);
			state = state.setIn(
				['data', websiteId, 'loaded'],
				true,
			);
			state = state.setIn(
				['data', websiteId, 'range'],
				state.get('data').get(websiteId).get('range').withMutations((mutableRange) => {
					for (const index in range) {
						mutableRange.set(offset + parseInt(index, 10), range[index]);
					}
				}),
			);
			state = state.setIn(
				['data', websiteId, 'pages'],
				state.get('data').get(websiteId).get('pages').withMutations((mutablePages) => {
					for (const index in pages) {
						const pageId = pages[index].id.toString();

						if (pages[index].relevance.same !== undefined && pages[index].relevance.same !== null) {
							pages[index].relevance.same = parseFloat(pages[index].relevance.same).toFixed(2);
						}

						if (pages[index].relevance.previous !== undefined && pages[index].relevance.previous !== null) {
							pages[index].relevance.previous = parseFloat(pages[index].relevance.previous).toFixed(2);
						}

						if (pages[index].relevance.next !== undefined && pages[index].relevance.next !== null) {
							pages[index].relevance.next = parseFloat(pages[index].relevance.next).toFixed(2);
						}

						if (mutablePages.has(pageId)) {
							mutablePages.set(
								pageId,
								mutablePages.get(pageId).merge(pages[index]),
							);
						} else {
							mutablePages.set(
								pageId,
								Immutable.fromJS(pages[index]),
							);
						}
					}
				}),
			);

			break;
		}

		case UPDATE_FILTER: {
			const oldState = state;
			const {
				filter,
			} = action;

			state = state.mergeIn(['filter'], filter);
			state = state.setIn(['filter'], state.get('filter').filter((val) => val !== ''));

			if (state !== oldState) {
				state = state.set('loading', true);
				state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
			}

			break;
		}

		case UPDATE_ISSUES_FILTER: {
			const {
				filter,
			} = action;

			if (filter.get('segment')) {
				state = state.setIn(['filter', 'segment'], filter.get('segment'));
			}

			break;
		}

		case UPDATE_SORT_BY: {
			const oldState = state;
			const {
				sortBy,
			} = action;

			state = state.mergeIn(['sortBy'], sortBy);

			if (state !== oldState) {
				state = state.set('loading', true);
				state = state.setIn(['data', state.get('websiteId'), 'rangeInLoading'], createCoverage());
			}

			break;
		}

	}

	return state;
}
