import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ArrowLeftIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					fill={color}
					fillRule="nonzero"
					id="button-next"
					transform="translate(7.111111, 4.000000)"
				>
					<path
						d="M-0.349400409,4.06753023 C-0.829770727,3.57114757 -1.60821699,3.57114757 -2.08735559,4.06753023 C-2.58496997,4.58238867 -2.58496997,5.38670103 -2.10583137,5.88308369 L3.52805026,11.7103452 C4.3052648,12.5146575 5.54437388,12.5146575 6.30803951,11.7251258 L11.95547,5.88308369 C12.4346086,5.38670103 12.4346086,4.58238867 11.95547,4.08600601 C11.4578557,3.57114757 10.6794094,3.57114757 10.2002708,4.06753023 L4.92481934,9.52404436 L-0.349400409,4.06753023 Z"
						id="arrowDownIcon"
						transform="translate(4.925958, 8.005342) rotate(90.000000) translate(-4.925958, -8.005342) "
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ArrowLeftIconBuilder;

