import Immutable from 'immutable';
import React from 'react';

import type CK from '~/types/contentking';

import useWebsiteColumnCategories from '~/hooks/useWebsiteColumnCategories';



function useEnabledWebsiteColumnsList() {
	const websiteColumnCategories = useWebsiteColumnCategories();

	return React.useMemo(
		() => {
			const result: Array<CK.PagesColumn> = [];

			websiteColumnCategories.forEach((category) => {
				category.enabledColumns.forEach((columnName) => {
					result.push(columnName);
				});
			});

			return Immutable.List(result);
		},
		[
			websiteColumnCategories,
		],
	);
}



export default useEnabledWebsiteColumnsList;
