import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	AbstractCheckboxFieldCheckedState,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MembersReportsAlertsFields from '~/components/atoms/forms/sharedFields/MembersReportsAlertsFields';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useUpdateSendingOfAlertsAndReportsFromWebsiteMutation,
} from './MembersReportsAlertsForm.gql';

import useWebsiteEmailSettings from '~/hooks/useWebsiteEmailSettings';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	assignedMembersCount: {
		id: 'ui.settings.emailNotifications.teamMember.view',
	},
	labelAlerts: {
		id: 'ui.events.title',
	},
	labelReports: {
		id: 'ui.settings.emailNotifications.reports',
	},
	title: {
		id: 'ui.settings.emailNotifications.title',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const MembersReportsAlertsForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteEmailSettingsPerUser = useWebsiteEmailSettings(websiteId);

	const [updateSendingOfAlertsAndReportsFromWebsite] = useUpdateSendingOfAlertsAndReportsFromWebsiteMutation();

	const handleSubmit = React.useCallback(
		async (data) => {
			const users: Array<{
				email: string,
				reports: boolean,
				alerts: GraphQL.AlertEmailsSetup,
			}> = [];

			websiteEmailSettingsPerUser.users.forEach((user) => {
				if (data.assignedMembers.disabled[user.userEmail]) {
					return;
				}

				let alertsStatus = GraphQL.AlertEmailsSetup.Some;

				if (data.assignedMembers.alerts[user.userEmail] === true) {
					alertsStatus = GraphQL.AlertEmailsSetup.All;
				} else if (data.assignedMembers.alerts[user.userEmail] === false) {
					alertsStatus = GraphQL.AlertEmailsSetup.None;
				}

				users.push({
					email: user.userEmail,
					reports: data.assignedMembers.weeklyReports[user.userEmail],
					alerts: alertsStatus,
				});
			});

			const invitations: Array<{
				id: CK.InvitationId,
				reports: boolean,
				alerts: GraphQL.AlertEmailsSetup,
			}> = [];

			websiteEmailSettingsPerUser.invitations.forEach((invitation) => {
				invitations.push({
					id: invitation.invitationId,
					alerts: invitation.alertEmailsSetup,
					reports: invitation.isWeeklyReportEnabled,
				});
			});

			await updateSendingOfAlertsAndReportsFromWebsite({
				variables: {
					invitations,
					users,
					websiteId,
				},
			});
		},
		[
			updateSendingOfAlertsAndReportsFromWebsite,
			websiteEmailSettingsPerUser,
			websiteId,
		],
	);

	const membersList: Array<{
		label: string,
		name: string,
		uniqueUserId: CK.UserId,
	}> = [];

	const assignedMembers: {
		alerts: Record<string, boolean | AbstractCheckboxFieldCheckedState.Indeterminate>,
		disabled: {},
		weeklyReports: Record<string, boolean>,
	} = {
		alerts: {},
		disabled: {},
		weeklyReports: {},
	};

	let alertsCount = 0;
	let weeklyReportsCount = 0;

	websiteEmailSettingsPerUser.users.forEach((user) => {
		membersList.push({
			label: user.displayName,
			name: user.userEmail,
			uniqueUserId: user.userUniqueId,
		});

		assignedMembers.alerts[user.userEmail] = matchAndReturn(user.alertEmailsSetup, {
			[GraphQL.AlertEmailsSetup.All]: true,
			[GraphQL.AlertEmailsSetup.None]: false,
			[GraphQL.AlertEmailsSetup.Some]: AbstractCheckboxFieldCheckedState.Indeterminate,
		});

		assignedMembers.weeklyReports[user.userEmail] = user.isWeeklyReportEnabled;

		if (user.alertEmailsSetup !== GraphQL.AlertEmailsSetup.None) {
			alertsCount++;
		}

		if (user.isWeeklyReportEnabled) {
			weeklyReportsCount++;
		}
	});

	return (
		<EditableFormWrapper
			key={websiteId}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.labelReports} />
						)}
					>
						<StaticText>
							{websiteEmailSettingsPerUser.isLoaded ? (
								<FormattedMessage
									{...messages.assignedMembersCount}
									values={{
										teamMembersCount: weeklyReportsCount,
									}}
								/>
							) : (
								<SquareSkeleton maxWidth={150} />
							)}
						</StaticText>
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.labelAlerts} />
						)}
					>
						<StaticText>

							{websiteEmailSettingsPerUser.isLoaded ? (
								<FormattedMessage
									{...messages.assignedMembersCount}
									values={{
										teamMembersCount: alertsCount,
									}}
								/>
							) : (
								<SquareSkeleton maxWidth={150} />
							)}
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultFocus="assignedMembers"
					defaultValues={{
						assignedMembers,
					}}
					isDisabled={websiteEmailSettingsPerUser.isLoaded === false}
					key={websiteEmailSettingsPerUser.isLoaded ? 'ready' : 'loading'}
					onSuccess={handleSubmit}
				>
					<FormRows>
						<MembersReportsAlertsFields
							isLoaded={websiteEmailSettingsPerUser.isLoaded}
							membersList={membersList}
							name="assignedMembers"
							websiteId={websiteId}
						/>
					</FormRows>

					<ButtonsLayout layout={ButtonsLayoutType.FormRowWithoutStatus}>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default MembersReportsAlertsForm;
