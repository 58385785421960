/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCustomElementMutationVariables = GraphQL.Exact<{
  name: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DeleteCustomElementMutation = { readonly __typename?: 'Mutation', readonly DeleteCustomElement: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null, readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElement', readonly column: `custom_${string}`, readonly createdAt: CK.Timestamp, readonly dataType: GraphQL.CustomElementDataType, readonly extraction: Record<string, any>, readonly label: string, readonly name: string }> } | null } } };


export const DeleteCustomElementDocument = gql`
    mutation DeleteCustomElement($name: String!, $websiteId: WebsiteId!) {
  DeleteCustomElement(name: $name, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        account {
          features {
            feature
            id
            inUse
          }
          id
        }
        customElements {
          column
          createdAt
          dataType
          extraction
          label
          name
        }
        id
      }
    }
  }
}
    `;
export type DeleteCustomElementMutationFn = Apollo.MutationFunction<DeleteCustomElementMutation, DeleteCustomElementMutationVariables>;

/**
 * __useDeleteCustomElementMutation__
 *
 * To run a mutation, you first call `useDeleteCustomElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomElementMutation, { data, loading, error }] = useDeleteCustomElementMutation({
 *   variables: {
 *      name: // value for 'name'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDeleteCustomElementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomElementMutation, DeleteCustomElementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomElementMutation, DeleteCustomElementMutationVariables>(DeleteCustomElementDocument, options);
      }
export type DeleteCustomElementMutationHookResult = ReturnType<typeof useDeleteCustomElementMutation>;
export type DeleteCustomElementMutationResult = Apollo.MutationResult<DeleteCustomElementMutation>;
export type DeleteCustomElementMutationOptions = Apollo.BaseMutationOptions<DeleteCustomElementMutation, DeleteCustomElementMutationVariables>;