import type Immutable from 'immutable';
import React from 'react';

import ValueUpdateComparison, {
	ValueUpdateComparisonHighlightType,
} from '~/components/patterns/values/ValueUpdateComparison';

import {
	type PageType,
} from '~/model/pagesNew';



type Props = {
	customElements: Immutable.Map<string, any> | null,
	formatter?: (
		value: any,
		pageType: PageType,
		customElements: any,
	) => React.ReactNode,
	isColored?: boolean,
	pageType: Immutable.Map<string, any>,
	value: Immutable.Map<string, any> | null,
};

const DiffFormatter: React.FC<Props> = (props) => {
	const {
		customElements,
		formatter,
		isColored = true,
		pageType,
		value,
	} = props;

	if (!value) {
		return null;
	}

	const renderValue = (value: any, customElements: any, pageType: PageType) => {
		return formatter ? formatter(value, pageType, customElements) : value;
	};

	const previousPageType = pageType.has('same')
		? pageType.get('same')
		: pageType.get('previous');

	const nextPageType = pageType.has('same')
		? pageType.get('same')
		: pageType.get('next');

	const previousCustomElements = customElements
		? (
			customElements.has('same')
				? customElements.get('same')
				: customElements.get('previous')
		)
		: false;

	const nextCustomElements = customElements
		? (
			customElements.has('same')
				? customElements.get('same')
				: customElements.get('next')
		)
		: false;

	if (value.has('same')) {
		return renderValue(value.get('same'), nextCustomElements, nextPageType);
	} else if (previousPageType === null) {
		return renderValue(value.get('next'), nextCustomElements, nextPageType);
	}

	let highlightType;

	if (isColored) {
		if (value.get('change') === 'added') {
			highlightType = ValueUpdateComparisonHighlightType.Added;
		} else if (value.get('change') === 'removed') {
			highlightType = ValueUpdateComparisonHighlightType.Removed;
		} else {
			highlightType = ValueUpdateComparisonHighlightType.Changed;
		}
	}

	return (
		<ValueUpdateComparison
			highlightType={highlightType}
			prevValue={renderValue(value.get('previous'), previousCustomElements, previousPageType)}
			value={renderValue(value.get('next'), nextCustomElements, nextPageType)}
		/>
	);
};



export default React.memo(DiffFormatter);
