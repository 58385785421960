import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import DisabledContent from '~/components/patterns/content/DisabledContent';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import StaticText from '~/components/atoms/forms/components/StaticText';



const messages = defineMessages({
	available: {
		id: 'ui.websites.form.api.status.available',
	},
	title: {
		id: 'ui.websites.form.api.adobeAnalytics.title',
	},
});



const AdobeAnalyticsIntegrationUpsellForm: React.FC = () => {
	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.AdobeAnalyticsIntegration}
			hideIfUnattainable={true}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => {
				if (isFeatureEnabled) {
					return null;
				}

				return (
					<EditableFormWrapper
						isReadOnly={true}
						title={(
							<FormattedMessage {...messages.title} />
						)}
					>
						<DisplayPart>
							<DisabledContent
								disabledContent={true}
								disabledOverlay={premiumAnnotation}
							>
								<FormRows>
									<FormRow
										label={(
											<FormattedMessage {...messages.title} />
										)}
									>
										<StaticText>
											<FormattedMessage {...messages.available} />
										</StaticText>
									</FormRow>
								</FormRows>
							</DisabledContent>
						</DisplayPart>

					</EditableFormWrapper>
				);
			}}
		</PremiumFeatureSituation>
	);
};



export default AdobeAnalyticsIntegrationUpsellForm;
