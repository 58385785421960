import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const AnalyticsIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g>
				<g id="image">
					<polygon
						fill="none"
						id="Stroke-739"
						points="47.13 47.2 12.76 47.2 12.76 12.83 47.13 12.83 47.13 47.2"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={Math.floor(60 / size * 2)}
					/>
				</g>
				<line
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={Math.floor(60 / size * 2)}
					x1="12.76"
					x2="47.13"
					y1="20.74"
					y2="20.74"
				/>
				<line
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={Math.floor(60 / size * 2)}
					x1="33.92"
					x2="34.82"
					y1="16.81"
					y2="16.81"
				/>
				<line
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={Math.floor(60 / size * 2)}
					x1="41.56"
					x2="42.47"
					y1="16.81"
					y2="16.81"
				/>
				<line
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={Math.floor(60 / size * 2)}
					x1="37.72"
					x2="38.62"
					y1="16.81"
					y2="16.81"
				/>
				<polyline
					fill="none"
					points="17.99 42.13 17.99 34.38 22.9 34.38 22.9 42.13"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={Math.floor(60 / size * 2)}
				/>
				<polyline
					fill="none"
					points="36.99 42.13 36.99 28.47 41.9 28.47 41.9 42.13"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={Math.floor(60 / size * 2)}
				/>
				<polyline
					fill="none"
					points="27.49 42.13 27.49 26.38 32.4 26.38 32.4 42.13"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={Math.floor(60 / size * 2)}
				/>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default AnalyticsIconBuilder;

