import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormDescription from '~/components/atoms/forms/components/rowParts/FormDescription';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import Small from '~/components/patterns/typography/Small';
import StripeField, {
	StripeFieldType,
	validateStripeField,
} from '~/components/app/Stripe/StripeField';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';



const messages = defineMessages({
	ibanLabel: {
		id: 'ui.sepaDebit.iban',
	},
	mandateText: {
		id: 'ui.sepaDebit.mandate',
	},
	nameLabel: {
		id: 'ui.sepaDebit.name',
	},
});



const SepaDirectDebitFields: React.FC = () => {
	const formContext = useFormContext();

	return (
		<FormRows>
			<FormRow
				htmlFor="sepaName"
				label={(
					<FormattedMessage {...messages.nameLabel} />
				)}
			>
				<FieldStatus name="validateSepaName">
					<TextField
						disabled={formContext.isSubmitting}
						name="sepaName"
						trimValue={true}
						width={280}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow
				label={(
					<FormattedMessage {...messages.ibanLabel} />
				)}
			>
				<FieldStatus name="validateSepaIban">
					<StripeField
						name="sepaIban"
						type={StripeFieldType.Iban}
					/>
				</FieldStatus>
			</FormRow>

			<FormRow>
				<FormDescription>
					<Small>
						<FormattedMessage {...messages.mandateText} />
					</Small>
				</FormDescription>
			</FormRow>
		</FormRows>
	);
};



export default SepaDirectDebitFields;

export const validateSepaIban = validateStripeField('sepaIban');

export const validateSepaName = validateField(
	'sepaName',
	(f) => [
		f.validateNonEmpty(),
	],
);
