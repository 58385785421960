import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import InternalLink from '~/components/patterns/links/InternalLink';
import List from '~/components/patterns/lists/List';

import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	blankSlateMessage: {
		id: 'ui.dashboard.alertsTable.blank.message',
	},
	issuesLink: {
		id: 'ui.dashboard.alertsTable.blank.message.link',
	},
});



type Props = {
	events: any,
};

const AlertCardsList: React.FC<Props> = (props) => {
	const {
		events,
	} = props;

	const websiteId = useWebsiteId() as ReturnType<typeof useWebsiteId> | null;

	if (events.length === 0) {
		let link = (
			<FormattedMessage {...messages.issuesLink} />
		);

		if (websiteId !== null) {
			link = (
				<InternalLink
					routeName="website.issues"
					routeParams={{
						websiteId,
					}}
				>
					{link}
				</InternalLink>
			);
		}

		return (
			<BlankSlate>
				<FormattedMessage
					{...messages.blankSlateMessage}
					values={{
						link,
					}}
				/>
			</BlankSlate>
		);
	}

	return (
		<List>
			{events}
		</List>
	);
};



export default AlertCardsList;
