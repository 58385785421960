import React from 'react';

import NumberFilterField, {
	NumberFilterFieldMode,
	NumberFilterFieldOperator,
	NumberFilterFieldSize,
} from '~/components/atoms/forms/components/NumberFilterField';



type Props = {
	mode?: NumberFilterFieldMode,
	name: string,
	placeholder?: string,
	width: number,
};

const NumberFieldFilter = React.forwardRef<typeof NumberFilterField, Props>((props, ref) => {
	const {
		mode,
		name,
		placeholder = '> 0',
		width,
	} = props;

	return (
		<NumberFilterField
			defaultOption={NumberFilterFieldOperator.Any}
			dropdownWidth={width - 30}
			maxLength={1000}
			mode={mode}
			name={name}
			placeholder={placeholder}
			popperEnabled={false}
			ref={ref}
			size={NumberFilterFieldSize.Small}
			width={width}
		/>
	);
});



export default React.memo(NumberFieldFilter);

export {
	NumberFilterFieldMode as NumberFieldFilterMode,
};
