import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ColumnsManagementElements from '~/components/atoms/screenLayouts/components/header/layouts/ColumnsManagementElements';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';

import {
	closeColumnsConfigurator,
	openColumnsConfigurator,
} from '~/actions';

import {
	columnsConfiguratorVisibilitySelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	manageLink: {
		id: 'ui.columnsConfigurator.toggleLabel',
	},
});



const ManageColumnsLink: React.FC = () => {
	const columnsConfiguratorVisibility = useSelector(columnsConfiguratorVisibilitySelector);
	const dispatch = useDispatch();

	const handleToggleColumnsConfigurator = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			if (columnsConfiguratorVisibility) {
				return dispatch(closeColumnsConfigurator());
			}

			return dispatch(openColumnsConfigurator());
		},
		[
			columnsConfiguratorVisibility,
			dispatch,
		],
	);

	return (
		<ColumnsManagementElements>
			<InternalLink
				ellipsis={true}
				icon={(
					<BasicIcon
						size={18}
						type={BasicIconType.Columns}
					/>
				)}
				onClickCallback={handleToggleColumnsConfigurator}
				style={InternalLinkStyle.Decent}
			>
				<FormattedMessage {...messages.manageLink} />
			</InternalLink>
		</ColumnsManagementElements>
	);
};



export default ManageColumnsLink;
