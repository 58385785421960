import React from 'react';

import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';



type Props = {
	issue: {
		context: {
			directives: ReadonlyArray<{
				crawl_delay: string,
				user_agent: string,
			}>,
		},
	},
	tableWidth: number,
};

const CrawlDelayDirectivesTable: React.FC<Props> = (props) => {
	const {
		issue,
		tableWidth,
	} = props;

	const firstColumnWidth = Math.ceil(tableWidth / 2);

	return (
		<SmallTable
			columns={[
				{
					render: {
						cell: ({ row }) => {
							return row.user_agent;
						},
						header: () => 'User-agent',
					},
					width: firstColumnWidth,
				},
				{
					render: {
						cell: ({ row }) => {
							return row.crawl_delay;
						},
						header: () => 'Crawl-delay',
					},
					width: tableWidth - firstColumnWidth - 2,
				},
			]}
			rows={issue.context.directives}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(CrawlDelayDirectivesTable);
