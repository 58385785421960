/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type XmlSitemapsBoxQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type XmlSitemapsBoxQuery = { readonly __typename?: 'Query', readonly platformData: { readonly __typename?: 'PlatformData', readonly id: CK.ID, readonly xmlSitemapIndicesCount: number, readonly xmlSitemapsCount: number, readonly xmlSitemapUrlsCount: number } | null };


export const XmlSitemapsBoxDocument = gql`
    query XmlSitemapsBox($websiteId: WebsiteId!) {
  platformData(websiteId: $websiteId) {
    id
    xmlSitemapIndicesCount
    xmlSitemapsCount
    xmlSitemapUrlsCount
  }
}
    `;

/**
 * __useXmlSitemapsBoxQuery__
 *
 * To run a query within a React component, call `useXmlSitemapsBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useXmlSitemapsBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useXmlSitemapsBoxQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useXmlSitemapsBoxQuery(baseOptions: Apollo.QueryHookOptions<XmlSitemapsBoxQuery, XmlSitemapsBoxQueryVariables> & ({ variables: XmlSitemapsBoxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<XmlSitemapsBoxQuery, XmlSitemapsBoxQueryVariables>(XmlSitemapsBoxDocument, options);
      }
export function useXmlSitemapsBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<XmlSitemapsBoxQuery, XmlSitemapsBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<XmlSitemapsBoxQuery, XmlSitemapsBoxQueryVariables>(XmlSitemapsBoxDocument, options);
        }
export function useXmlSitemapsBoxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<XmlSitemapsBoxQuery, XmlSitemapsBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<XmlSitemapsBoxQuery, XmlSitemapsBoxQueryVariables>(XmlSitemapsBoxDocument, options);
        }
export type XmlSitemapsBoxQueryHookResult = ReturnType<typeof useXmlSitemapsBoxQuery>;
export type XmlSitemapsBoxLazyQueryHookResult = ReturnType<typeof useXmlSitemapsBoxLazyQuery>;
export type XmlSitemapsBoxSuspenseQueryHookResult = ReturnType<typeof useXmlSitemapsBoxSuspenseQuery>;
export type XmlSitemapsBoxQueryResult = Apollo.QueryResult<XmlSitemapsBoxQuery, XmlSitemapsBoxQueryVariables>;