import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountPaymentStatusLazyQuery,
} from './useAccountPaymentStatus.gql';



type PaymentStatus = GraphQL.Account['paymentStatus'] | null;
type ReturnType = (accountId: number) => Promise<PaymentStatus>;



function useLoadPaymentStatus(): ReturnType {
	const [loadAccountPaymentStatus] = useAccountPaymentStatusLazyQuery();

	return React.useCallback(
		async (accountId: CK.AccountId): Promise<PaymentStatus> => {
			const { data } = await loadAccountPaymentStatus({
				variables: {
					accountId,
				},
			});

			return data?.account?.paymentStatus ?? null;
		},
		[
			loadAccountPaymentStatus,
		],
	);
}



export default useLoadPaymentStatus;
