import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import BadgeWithValue from '~/components/patterns/structuredValues/BadgeWithValue';
import BlankValue from '~/components/patterns/values/BlankValue';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import DefinitionTermValueSkeleton from './DefinitionTermValueSkeleton';
import DomSrcBadge, {
	DomSrcBadgeType,
} from '~/components/patterns/tags/DomSrcBadge';
import LinkRelValue from '~/components/app/PageDetail/LinkRelValue';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import NamedValue from '~/components/patterns/structuredValues/NamedValue';



const messages = defineMessages({
	ampVariant: {
		id: 'ui.pageDetail.linkData.ampVariant',
	},
	hreflang: {
		id: 'ui.pageDetail.linkData.hreflang',
	},
	linkCanonicalInHeader: {
		id: 'ui.contentData.linkCanonicalHeader',
	},
	linkCanonicalOnPage: {
		id: 'ui.contentData.linkCanonicalHtml',
	},
	mobileVariant: {
		id: 'ui.pageDetail.linkData.mobileVariant',
	},
	pagination: {
		id: 'ui.pageDetail.linkData.pagination.label',
	},
	paginationNext: {
		id: 'ui.pageDetail.linkData.pagination.nextLabel',
	},
	paginationPrev: {
		id: 'ui.pageDetail.linkData.pagination.prevLabel',
	},
});



function compile<
	TProperty extends string,
	TLinkRelItem extends { content: string | null, legacyUrlId: number | null, isCrossdomain: boolean | null },
	TPropertyData extends Record<TProperty, {
		linkRelItems: ReadonlyArray<TLinkRelItem>,
	}>,
>({
	compare,
	pageUrl,
	primaryLinkRelData,
	property,
	srcLinkRelData,
	websiteId,
	wrapper = (element) => element,
}: {
	compare: boolean,
	pageUrl: string | null,
	primaryLinkRelData: TPropertyData,
	property: TProperty,
	srcLinkRelData: TPropertyData | null,
	websiteId: CK.WebsiteId,
	wrapper?: (element: React.ReactElement, linkRelItem: TPropertyData[TProperty]['linkRelItems'][number]) => React.ReactElement,
}) {
	const primaryItems = primaryLinkRelData[property].linkRelItems;
	const srcItems = srcLinkRelData !== null ? srcLinkRelData[property].linkRelItems : [];

	const numberOfItems = Math.max(
		primaryItems.length,
		compare ? srcItems.length : 0,
	);

	const items: Array<{
		linkRelItem: TLinkRelItem,
		primary: React.ReactElement,
		src: React.ReactElement | null,
	}> = [];

	for (let i = 0; i < numberOfItems; i++) {
		const linkRelItem = primaryItems[i] ?? srcItems[i] ?? null;

		if (linkRelItem === null) {
			throw new Error(
				`linkRelItem can't be null`,
			);
		}

		const item: typeof items[number] = {
			linkRelItem,
			primary: (
				<LinkRelValue
					linkRelItem={primaryItems[i] ?? null}
					pageUrl={pageUrl ?? ''}
					websiteId={websiteId}
				/>
			),
			src: null,
		};

		if (compare) {
			if (srcLinkRelData === null) {
				item.src = (
					<DefinitionTermValueSkeleton />
				);
			} else if (srcItems[i]?.content === primaryItems[i]?.content) {
				item.src = (
					<BlankValue>
						same as DOM
					</BlankValue>
				);
			} else {
				item.src = (
					<LinkRelValue
						linkRelItem={(srcItems[i]?.content ? srcItems[i] : null) ?? null}
						pageUrl={pageUrl ?? ''}
						websiteId={websiteId}
					/>
				);
			}
		}

		items.push(item);
	}

	return items.map(({ linkRelItem, primary, src }, index) => {
		if (compare) {
			return wrapper(
				<List
					key={index}
					size={ListSize.Small}
				>
					<BadgeWithValue
						badge={(
							<DomSrcBadge type={DomSrcBadgeType.Dom} />
						)}
					>
						{primary}
					</BadgeWithValue>

					<BadgeWithValue
						badge={(
							<DomSrcBadge type={DomSrcBadgeType.Src} />
						)}
					>
						{src}
					</BadgeWithValue>
				</List>,
				linkRelItem,
			);
		}

		return wrapper(
			<React.Fragment key={index}>
				{primary}
			</React.Fragment>,
			linkRelItem,
		);
	});
}



type PropertyData = {
	linkRelItems: ReadonlyArray<GraphQL.LinkRelItem>,
};

type PropertyDataWithoutHreflang = {
	linkRelItems: ReadonlyArray<Omit<GraphQL.LinkRelItem, 'hreflang'>>,
};

type Props = {
	compare: boolean,
	linkCanonicalHeaderData: PropertyDataWithoutHreflang | null,
	pageUrl: string | null,
	primaryLinkRelData: {
		linkAlternateHreflang: PropertyData,
		linkAmp: PropertyDataWithoutHreflang,
		linkCanonical: PropertyDataWithoutHreflang,
		linkMobileVariant: PropertyDataWithoutHreflang,
		linkNext: PropertyDataWithoutHreflang,
		linkPrev: PropertyDataWithoutHreflang,
		[key: string]: unknown,
	},
	srcLinkRelData: {
		linkAlternateHreflang: PropertyData,
		linkAmp: PropertyDataWithoutHreflang,
		linkCanonical: PropertyDataWithoutHreflang,
		linkMobileVariant: PropertyDataWithoutHreflang,
		linkNext: PropertyDataWithoutHreflang,
		linkPrev: PropertyDataWithoutHreflang,
		[key: string]: unknown,
	} | null,
	websiteId: CK.WebsiteId,
};

const OtherRelationsBlockPart: React.FC<Props> = (props) => {
	const {
		compare,
		linkCanonicalHeaderData,
		pageUrl,
		primaryLinkRelData,
		srcLinkRelData,
		websiteId,
	} = props;

	const canonicalLinkInHeaderItems = linkCanonicalHeaderData ? compile({
		compare: false,
		pageUrl,
		primaryLinkRelData: {
			linkCanonicalHeader: linkCanonicalHeaderData,
		},
		property: 'linkCanonicalHeader',
		srcLinkRelData: null,
		websiteId,
	}) : [];

	const canonicalLinkInHeader = canonicalLinkInHeaderItems.length > 0
		? (
			<DefinitionTerm
				description={canonicalLinkInHeaderItems}
				term={(
					<FormattedMessage {...messages.linkCanonicalInHeader} />
				)}
			/>
		)
		: null;

	const canonicalLinkOnPageItems = compile({
		compare,
		pageUrl,
		primaryLinkRelData,
		property: 'linkCanonical',
		srcLinkRelData,
		websiteId,
	});

	const canonicalLinkOnPage = canonicalLinkOnPageItems.length > 0
		? (
			<DefinitionTerm
				description={canonicalLinkOnPageItems}
				term={(
					<FormattedMessage {...messages.linkCanonicalOnPage} />
				)}
			/>
		)
		: null;

	let pagination: React.ReactNode = null;

	const linkPrevItems = compile({
		compare,
		pageUrl,
		primaryLinkRelData,
		property: 'linkPrev',
		srcLinkRelData,
		websiteId,
		wrapper: (element) => (
			<NamedValue
				name={(
					<FormattedMessage {...messages.paginationPrev} />
				)}
			>
				{element}
			</NamedValue>
		),
	});

	const linkNextItems = compile({
		compare,
		pageUrl,
		primaryLinkRelData,
		property: 'linkNext',
		srcLinkRelData,
		websiteId,
		wrapper: (element) => (
			<NamedValue
				name={(
					<FormattedMessage {...messages.paginationNext} />
				)}
			>
				{element}
			</NamedValue>
		),
	});

	const linkPaginationItems = [
		...linkPrevItems,
		...linkNextItems,
	];

	if (linkPaginationItems.length > 0) {
		pagination = (
			<DefinitionTerm
				description={linkPaginationItems}
				showRowNumbers={false}
				term={(
					<FormattedMessage {...messages.pagination} />
				)}
			/>
		);
	}

	const hreflangItems = compile({
		compare,
		pageUrl,
		primaryLinkRelData,
		property: 'linkAlternateHreflang',
		srcLinkRelData,
		websiteId,
		wrapper: (element, linkRelItem) => (
			<NamedValue
				name={linkRelItem.hreflang}
			>
				{element}
			</NamedValue>
		),
	});

	const hreflang = hreflangItems.length > 0
		? (
			<DefinitionTerm
				description={hreflangItems}
				showRowNumbers={false}
				term={(
					<FormattedMessage {...messages.hreflang} />
				)}
				visibleRowsCount={3}
			/>
		)
		: null;

	const mobileVariantItems = compile({
		compare,
		pageUrl,
		primaryLinkRelData,
		property: 'linkMobileVariant',
		srcLinkRelData,
		websiteId,
	});

	const mobileVariant = mobileVariantItems.length > 0
		? (
			<DefinitionTerm
				description={mobileVariantItems}
				term={(
					<FormattedMessage {...messages.mobileVariant} />
				)}
			/>
		)
		: null;

	const ampVariantItems = compile({
		compare,
		pageUrl,
		primaryLinkRelData,
		property: 'linkAmp',
		srcLinkRelData,
		websiteId,
	});

	const ampVariant = ampVariantItems.length > 0
		? (
			<DefinitionTerm
				description={ampVariantItems}
				term={(
					<FormattedMessage {...messages.ampVariant} />
				)}
			/>
		)
		: null;

	return (
		<DefinitionTermsList>
			{canonicalLinkInHeader}
			{canonicalLinkOnPage}
			{pagination}
			{hreflang}
			{mobileVariant}
			{ampVariant}
		</DefinitionTermsList>
	);
};



export default React.memo(OtherRelationsBlockPart);
