/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsNumberOfEmployeesEnoughForOutOfBandQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type IsNumberOfEmployeesEnoughForOutOfBandQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isNumberOfEmployeesEnoughForOutOfBand: boolean | null } | null };

export type UpdateNumberOfEmployeesMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  isNumberOfEmployeesEnoughForOutOfBand: GraphQL.Scalars['Boolean']['input'];
}>;


export type UpdateNumberOfEmployeesMutation = { readonly __typename?: 'Mutation', readonly UpdateNumberOfEmployees: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly allowedPlans: ReadonlyArray<GraphQL.AccountPlan>, readonly customElementsLimit: number, readonly enrichmentFieldsLimit: number, readonly id: CK.AccountId, readonly isOutOfBand: boolean, readonly isNumberOfEmployeesEnoughForOutOfBand: boolean | null, readonly plan: GraphQL.AccountPlan | null, readonly tariff: string, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }>, readonly websiteRestrictions: { readonly __typename?: 'AccountWebsiteRestrictions', readonly maximumPageBundle: number | null, readonly maximumPageCapacity: number, readonly maximumUnverifiedWebsites: number | null, readonly maximumWebsites: number | null } } | null } } };


export const IsNumberOfEmployeesEnoughForOutOfBandDocument = gql`
    query IsNumberOfEmployeesEnoughForOutOfBand($accountId: AccountId!) {
  account(id: $accountId) {
    id
    isNumberOfEmployeesEnoughForOutOfBand
  }
}
    `;

/**
 * __useIsNumberOfEmployeesEnoughForOutOfBandQuery__
 *
 * To run a query within a React component, call `useIsNumberOfEmployeesEnoughForOutOfBandQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsNumberOfEmployeesEnoughForOutOfBandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsNumberOfEmployeesEnoughForOutOfBandQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useIsNumberOfEmployeesEnoughForOutOfBandQuery(baseOptions: Apollo.QueryHookOptions<IsNumberOfEmployeesEnoughForOutOfBandQuery, IsNumberOfEmployeesEnoughForOutOfBandQueryVariables> & ({ variables: IsNumberOfEmployeesEnoughForOutOfBandQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsNumberOfEmployeesEnoughForOutOfBandQuery, IsNumberOfEmployeesEnoughForOutOfBandQueryVariables>(IsNumberOfEmployeesEnoughForOutOfBandDocument, options);
      }
export function useIsNumberOfEmployeesEnoughForOutOfBandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsNumberOfEmployeesEnoughForOutOfBandQuery, IsNumberOfEmployeesEnoughForOutOfBandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsNumberOfEmployeesEnoughForOutOfBandQuery, IsNumberOfEmployeesEnoughForOutOfBandQueryVariables>(IsNumberOfEmployeesEnoughForOutOfBandDocument, options);
        }
export function useIsNumberOfEmployeesEnoughForOutOfBandSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsNumberOfEmployeesEnoughForOutOfBandQuery, IsNumberOfEmployeesEnoughForOutOfBandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsNumberOfEmployeesEnoughForOutOfBandQuery, IsNumberOfEmployeesEnoughForOutOfBandQueryVariables>(IsNumberOfEmployeesEnoughForOutOfBandDocument, options);
        }
export type IsNumberOfEmployeesEnoughForOutOfBandQueryHookResult = ReturnType<typeof useIsNumberOfEmployeesEnoughForOutOfBandQuery>;
export type IsNumberOfEmployeesEnoughForOutOfBandLazyQueryHookResult = ReturnType<typeof useIsNumberOfEmployeesEnoughForOutOfBandLazyQuery>;
export type IsNumberOfEmployeesEnoughForOutOfBandSuspenseQueryHookResult = ReturnType<typeof useIsNumberOfEmployeesEnoughForOutOfBandSuspenseQuery>;
export type IsNumberOfEmployeesEnoughForOutOfBandQueryResult = Apollo.QueryResult<IsNumberOfEmployeesEnoughForOutOfBandQuery, IsNumberOfEmployeesEnoughForOutOfBandQueryVariables>;
export const UpdateNumberOfEmployeesDocument = gql`
    mutation UpdateNumberOfEmployees($accountId: AccountId!, $isNumberOfEmployeesEnoughForOutOfBand: Boolean!) {
  UpdateNumberOfEmployees(
    accountId: $accountId
    isNumberOfEmployeesEnoughForOutOfBand: $isNumberOfEmployeesEnoughForOutOfBand
  ) {
    query {
      account(id: $accountId) {
        allowedPlans
        customElementsLimit
        enrichmentFieldsLimit
        features {
          feature
          id
          inUse
        }
        id
        isOutOfBand
        isNumberOfEmployeesEnoughForOutOfBand
        plan
        tariff
        websiteRestrictions {
          maximumPageBundle
          maximumPageCapacity
          maximumUnverifiedWebsites
          maximumWebsites
        }
      }
    }
  }
}
    `;
export type UpdateNumberOfEmployeesMutationFn = Apollo.MutationFunction<UpdateNumberOfEmployeesMutation, UpdateNumberOfEmployeesMutationVariables>;

/**
 * __useUpdateNumberOfEmployeesMutation__
 *
 * To run a mutation, you first call `useUpdateNumberOfEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNumberOfEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNumberOfEmployeesMutation, { data, loading, error }] = useUpdateNumberOfEmployeesMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      isNumberOfEmployeesEnoughForOutOfBand: // value for 'isNumberOfEmployeesEnoughForOutOfBand'
 *   },
 * });
 */
export function useUpdateNumberOfEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNumberOfEmployeesMutation, UpdateNumberOfEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNumberOfEmployeesMutation, UpdateNumberOfEmployeesMutationVariables>(UpdateNumberOfEmployeesDocument, options);
      }
export type UpdateNumberOfEmployeesMutationHookResult = ReturnType<typeof useUpdateNumberOfEmployeesMutation>;
export type UpdateNumberOfEmployeesMutationResult = Apollo.MutationResult<UpdateNumberOfEmployeesMutation>;
export type UpdateNumberOfEmployeesMutationOptions = Apollo.BaseMutationOptions<UpdateNumberOfEmployeesMutation, UpdateNumberOfEmployeesMutationVariables>;