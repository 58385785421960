/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsiteNameMutationVariables = GraphQL.Exact<{
  name: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteNameMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteName: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly displayName: string } | null } } };


export const UpdateWebsiteNameDocument = gql`
    mutation UpdateWebsiteName($name: String!, $websiteId: WebsiteId!) {
  UpdateWebsiteName(name: $name, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        id
        displayName
      }
    }
  }
}
    `;
export type UpdateWebsiteNameMutationFn = Apollo.MutationFunction<UpdateWebsiteNameMutation, UpdateWebsiteNameMutationVariables>;

/**
 * __useUpdateWebsiteNameMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteNameMutation, { data, loading, error }] = useUpdateWebsiteNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteNameMutation, UpdateWebsiteNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteNameMutation, UpdateWebsiteNameMutationVariables>(UpdateWebsiteNameDocument, options);
      }
export type UpdateWebsiteNameMutationHookResult = ReturnType<typeof useUpdateWebsiteNameMutation>;
export type UpdateWebsiteNameMutationResult = Apollo.MutationResult<UpdateWebsiteNameMutation>;
export type UpdateWebsiteNameMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteNameMutation, UpdateWebsiteNameMutationVariables>;