/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddGuaranteedBlacklistDomainsMutationVariables = GraphQL.Exact<{
  domains: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type AddGuaranteedBlacklistDomainsMutation = { readonly __typename?: 'Mutation', readonly AddGuaranteedBlacklistDomains: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly kingdomSettings: { readonly __typename?: 'KingdomSettings', readonly guaranteedBlacklistDomains: ReadonlyArray<string> } | null } } };


export const AddGuaranteedBlacklistDomainsDocument = gql`
    mutation AddGuaranteedBlacklistDomains($domains: [String!]!) {
  AddGuaranteedBlacklistDomains(domains: $domains) {
    query {
      kingdomSettings {
        guaranteedBlacklistDomains
      }
    }
  }
}
    `;
export type AddGuaranteedBlacklistDomainsMutationFn = Apollo.MutationFunction<AddGuaranteedBlacklistDomainsMutation, AddGuaranteedBlacklistDomainsMutationVariables>;

/**
 * __useAddGuaranteedBlacklistDomainsMutation__
 *
 * To run a mutation, you first call `useAddGuaranteedBlacklistDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGuaranteedBlacklistDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGuaranteedBlacklistDomainsMutation, { data, loading, error }] = useAddGuaranteedBlacklistDomainsMutation({
 *   variables: {
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useAddGuaranteedBlacklistDomainsMutation(baseOptions?: Apollo.MutationHookOptions<AddGuaranteedBlacklistDomainsMutation, AddGuaranteedBlacklistDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGuaranteedBlacklistDomainsMutation, AddGuaranteedBlacklistDomainsMutationVariables>(AddGuaranteedBlacklistDomainsDocument, options);
      }
export type AddGuaranteedBlacklistDomainsMutationHookResult = ReturnType<typeof useAddGuaranteedBlacklistDomainsMutation>;
export type AddGuaranteedBlacklistDomainsMutationResult = Apollo.MutationResult<AddGuaranteedBlacklistDomainsMutation>;
export type AddGuaranteedBlacklistDomainsMutationOptions = Apollo.BaseMutationOptions<AddGuaranteedBlacklistDomainsMutation, AddGuaranteedBlacklistDomainsMutationVariables>;