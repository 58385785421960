import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const HolidayIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M2.483,11.9A1,1,0,0,0,3,12.463a1.014,1.014,0,0,0,.432.1.991.991,0,0,0,.336-.059L5.175,12a.959.959,0,0,0,.6-.616l.36-1.572a.25.25,0,0,1,.4-.142l1.274.988a.953.953,0,0,0,.858.094l2.393-.856a.252.252,0,0,1,.32.152l2.256,6.311a.249.249,0,0,1-.191.33,7.606,7.606,0,0,0-5.4,3.328.249.249,0,0,0,.125.354,1.293,1.293,0,0,0,.486.1,2.685,2.685,0,0,0,1.83-1,2.581,2.581,0,0,1,2-1.006,2.505,2.505,0,0,1,2.255,1.415l.012.022a1.175,1.175,0,0,0,.975.573,1.788,1.788,0,0,0,1.374-.759,2.5,2.5,0,0,1,4.287-.08l.012.017c.067.09.68.9,1.267.81a2.47,2.47,0,0,1,1.011.081A.25.25,0,0,0,24,20.3V17a.5.5,0,0,0-.5-.5H15.987a.249.249,0,0,1-.235-.166L13.261,9.37a.25.25,0,0,1,.152-.319l2.4-.858a.959.959,0,0,0,.6-.616l.359-1.572a.253.253,0,0,1,.16-.18.249.249,0,0,1,.237.038l1.275.989a.967.967,0,0,0,.857.094l1.412-.506a1,1,0,0,0,.605-1.277l-.169-.472a5.551,5.551,0,0,0-4.494-3.576,13.082,13.082,0,0,0-5.935.446.25.25,0,0,1-.3-.156L10.148.663a1,1,0,0,0-1.882.674l.265.741a.251.251,0,0,1-.136.314,13.113,13.113,0,0,0-4.872,3.42,5.55,5.55,0,0,0-1.208,5.613Z"
				fill={color}
			/>
			<path
				d="M1.417,24a4.315,4.315,0,0,1-.55-.036,1,1,0,0,1,.265-1.983c1.674.218,3.183-1.627,3.2-1.644a1.042,1.042,0,0,1,1.638.091,3.465,3.465,0,0,0,2.685,1.538,4.191,4.191,0,0,0,3.026-1.6,1.047,1.047,0,0,1,1.714.16,2.725,2.725,0,0,0,2.336,1.44,3.265,3.265,0,0,0,2.674-1.51,1.042,1.042,0,0,1,1.718-.028c.009.012,1.121,1.725,2.746,1.513a1,1,0,0,1,.265,1.983,4.519,4.519,0,0,1-3.691-1.261.249.249,0,0,0-.333,0,5.028,5.028,0,0,1-3.379,1.3,4.473,4.473,0,0,1-3.189-1.3.249.249,0,0,0-.331-.016,5.687,5.687,0,0,1-3.556,1.321,5.085,5.085,0,0,1-3.428-1.383.251.251,0,0,0-.328-.006A5.6,5.6,0,0,1,1.417,24Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default HolidayIconBuilder;
