import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';



const messages = defineMessages({
	viewIssueButton: {
		id: 'ui.alerts.overview.incident.followup.viewIssue',
	},
});



class PlatformIssueButton extends Component {

	render() {
		const {
			issueCategory,
			websiteId,
		} = this.props;

		return (
			<Button
				linkRouteName="website.platform.issuesCategory"
				linkRouteParams={{
					platformScreenIssuesCategoryType: issueCategory,
					websiteId,
				}}
				size={ButtonSize.Small}
				style={ButtonStyle.Hollow}
			>
				<FormattedMessage {...messages.viewIssueButton} />
			</Button>
		);
	}

}

PlatformIssueButton.propTypes = {
	issueCategory: PropTypes.string.isRequired,
	websiteId: PropTypes.string.isRequired,
};



export default PlatformIssueButton;
