import Immutable from 'immutable';

import {
	runAction,
} from '~/model/actions';

import {
	loadSitemapsRangeViaApi,
} from '~/model/sitemaps';

import {
	DEFAULT_FILTER,
	DEFAULT_SORT_BY,
} from '~/model/sitemapsList';

import {
	filterSelector,
	sortBySelector,
} from '~/state/sitemapsList/selectors';

import {
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';

import encodeInBase64 from '~/utilities/encodeInBase64';



export const MARK_START_OF_LOADING = 'SITEMAPS_LIST_MARK_START_OF_LOADING';
export const STORE = 'SITEMAPS_LIST_STORE';
export const STORE_SITEMAP_IS_PRIORITIZED = 'STORE_SITEMAP_IS_PRIORITIZED';
export const STORE_TOTAL_COUNT = 'SITEMAPS_LIST_STORE_TOTAL_COUNT';
export const UPDATE_FILTER = 'SITEMAPS_LIST_UPDATE_FILTER';
export const UPDATE_SORT_BY = 'SITEMAPS_LIST_UPDATE_SORT_BY';



function normalizeFilterAndSorting(filter, sortBy) {
	filter = filter.filter((value) => value !== '');

	const filterIsSame = JSON.stringify(filter.toJS()) === JSON.stringify(DEFAULT_FILTER.toJS());
	const sortingIsSame = sortBy.get('key') === DEFAULT_SORT_BY.get('key') && sortBy.get('direction') === DEFAULT_SORT_BY.get('direction');

	if (filterIsSame && sortingIsSame) {
		return null;
	}

	if (filter || sortBy) {
		return {
			filter: filter ? filter.toJS() : null,
			sortBy: sortBy ? sortBy.toJS() : null,
		};
	}
}



export function createSitemapsListFilterParameter(filter, sortBy) {
	const normalizedFilterAndSorting = normalizeFilterAndSorting(filter, sortBy);

	return normalizedFilterAndSorting
		? encodeInBase64(JSON.stringify(normalizedFilterAndSorting))
		: false;
}



export function loadSitemapsList(offset, limit = 1) {
	limit = 300 * limit;

	return (dispatch, getState) => {
		const websiteId = selectedWebsiteIdSelector(getState());

		if (!websiteId) {
			return Promise.resolve();
		}

		const initiatedAt = Date.now();

		const filter = filterSelector(getState()).toJS();
		const sortBy = sortBySelector(getState());

		const params = {
			limit,
			offset: Math.max(0, offset),
		};

		params.fields = [
			'can_be_prioritized',
			'is_prioritized',
			'last_crawled_at',
			'lfa_bing_frequency',
			'lfa_bing_last_visit',
			'lfa_google_frequency',
			'lfa_google_last_visit',
			'number_of_errors',
			'number_of_records',
			'references',
			'status',
			'type',
			'url',
		];

		for (const key in filter) {
			if (filter.hasOwnProperty(key)) {
				if (filter[key] && filter[key].length !== undefined && filter[key].length === 0) {
					dispatch(
						storeSitemapsList(
							initiatedAt,
							websiteId,
							0,
							offset,
							[],
							[],
						),
					);

					return Promise.resolve();
				}

				params['filter[' + key + ']'] = filter[key];
			}
		}

		if (sortBy !== undefined && sortBy !== null) {
			params.sort = sortBy.get('key');
			params.direction = sortBy.get('direction') ? 'asc' : 'desc';
		}

		dispatch({
			type: MARK_START_OF_LOADING,
			initiatedAt,
		});

		return loadSitemapsRangeViaApi(
			websiteId,
			params,
		).then((data) => {
			dispatch(
				storeSitemapsList(
					initiatedAt,
					websiteId,
					data.total,
					offset,
					data.url_ids,
					data.urls,
				),
			);
		});
	};
}



export function prioritizeSitemap(sitemapId) {
	return (dispatch, getState) => {
		const websiteId = selectedWebsiteIdSelector(getState());

		return runAction({
			action: 'PrioritizePage',
			input: {
				urlId: sitemapId,
				websiteId,
			},
		}).then(({ page }) => {
			dispatch({
				type: STORE_SITEMAP_IS_PRIORITIZED,
				isPrioritized: page.isPrioritized,
				sitemapId,
				websiteId,
			});
		});
	};
}



export function syncSitemapsList() {
	return loadSitemapsList(0);
}



function storeSitemapsList(initiatedAt, websiteId, total, offset, range, sitemaps) {
	return {
		type: STORE,
		initiatedAt,
		websiteId,
		total,
		offset,
		range,
		sitemaps,
	};
}



export function updateFilter(filter) {
	filter = Immutable.fromJS(filter).map((value, key) => {
		if (value === undefined) {
			return DEFAULT_FILTER.get(key) || '';
		}

		return value;
	});

	return (dispatch, getState) => {
		const originalFilter = filterSelector(getState());

		if (Immutable.is(originalFilter, filter)) {
			return Promise.resolve();
		}

		dispatch({
			type: UPDATE_FILTER,
			filter,
		});

		dispatch(
			loadSitemapsList(0),
		);

		return Promise.resolve();
	};
}



export function updateSortBy(sortBy) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SORT_BY,
			sortBy: Immutable.fromJS(sortBy),
		});

		dispatch(
			loadSitemapsList(0),
		);

		return Promise.resolve();
	};
}
