import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const PeopleIconBuilder: IconBuilder = {
	icon: ({ color = '#8595A6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				transform="translate(2 2)"
			>
				<path
					d="M20 20L0 20 0 0 20 0z"
					fill="none"
				/>
				<g
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					transform="translate(.625 1.875)"
				>
					<path d="M13.228 12.431h5.412a6.215 6.215 0 0 0-4.67-6.02" />
					<path d="M5.294 8.764C2.254 9.523 0 12.264 0 15.538h13.98c0-3.274-2.254-6.014-5.294-6.774m1.995-2.484c.51.31 1.105.499 1.746.499a3.389 3.389 0 0 0 0-6.779 3.38 3.38 0 0 0-3.193 2.297" />
					<path d="M10.803 5.366a3.813 3.813 0 1 1-7.625 0 3.813 3.813 0 0 1 7.625 0z" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default PeopleIconBuilder;

