import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import ActionsController from '../alertsConfiguration/ActionsController';
import ActiveFilter from '../filters/ActiveFilter';
import BackToAlertsOverviewButton from '~/components/app/BackToAlertsOverviewButton';
import CreateAlertDefinitionsButton from '../alertsConfiguration/CreateAlertDefinitionsButton';
import FlashMessageProvider from '../FlashMessageProvider';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderFilterLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderFilterLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderTitleWithValue from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import OverviewDatatable from '../alertsConfiguration/OverviewDatatable';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import {
	usePrefetch as useSensitivityFieldPrefetch,
} from '../alertsConfiguration/fields/SensitivityField';
import {
	usePrefetch as useScopeSizePrefetch,
} from '../alertsConfiguration/hooks/useScopeSize';
import WithPermission from '../access/WithPermission';

import useWebsiteAlertDefinitions from '~/hooks/useWebsiteAlertDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';
import useViewportType from '~/hooks/useViewportType';
import useViewportWidth from '~/hooks/useViewportWidth';

import {
	setSelectedDefinitions,
} from '~/actions/alertsConfiguration';

import {
	assertDictionaryId,
} from '~/localization/dictionaries';

import {
	SENSITIVITY_LEVEL_ALWAYS,
	SENSITIVITY_LEVEL_HIGH,
	SENSITIVITY_LEVEL_LOW,
	SENSITIVITY_LEVEL_MEDIUM,
	getSortByType,
} from '~/model/alerts';

import {
	ObjectType,
} from '~/model/permissions';

import {
	selectedDefinitionsSelector,
} from '~/state/alertsConfiguration/selectors';

import removeDefaultValues from '~/utilities/removeDefaultValues';



const messages = defineMessages({
	columnMessagingAppChannel: {
		id: 'ui.alertsConfiguration.fields.messagingApp',
	},
	columnRecipients: {
		id: 'ui.alertsConfiguration.fields.recipients',
	},
	columnScope: {
		id: 'ui.alertsConfiguration.fields.scope',
	},
	columnSensitivity: {
		id: 'ui.alertsConfiguration.fields.sensitivity',
	},
	columnType: {
		id: 'ui.alertsConfiguration.fields.type',
	},
	title: {
		id: 'ui.alertsConfiguration.title',
	},
});



const Prefetch = (props) => {
	const {
		websiteId,
	} = props;

	useSensitivityFieldPrefetch({ websiteId });
	useScopeSizePrefetch({ websiteId });

	return null;
};

const defaultFilter: Immutable.Map<string, any> = Immutable.Map({
	messagingAppChannelId: 'all',
	recipients: 'all',
	scope: 'all',
	sensitivity: Immutable.List([
		SENSITIVITY_LEVEL_ALWAYS,
		SENSITIVITY_LEVEL_HIGH,
		SENSITIVITY_LEVEL_LOW,
		SENSITIVITY_LEVEL_MEDIUM,
	]),
	type: '',
});



const AlertsConfigurationScreen = () => {
	const websiteId = useWebsiteId();

	const alertDefinitions = useWebsiteAlertDefinitions(websiteId);
	const dispatch = useDispatch();
	const intl = useIntl();
	const selectedDefinitions = useSelector(selectedDefinitionsSelector);
	const viewportType = useViewportType();
	const viewportWidth = useViewportWidth();

	const [filter, setFilter] = React.useState(defaultFilter);

	const filteredAlertDefinitions = React.useMemo(
		() => {
			if (alertDefinitions.isLoaded === false) {
				return [];
			}

			const result = alertDefinitions.listAll().filter((definition) => {
				if (filter.get('recipients') !== 'all') {
					if (filter.get('recipients').size === 0) {
						return false;
					}

					if (definition.recipients.length > 0) {
						if (!definition.recipients.some((recipientEmail) => filter.get('recipients').indexOf(recipientEmail) !== -1)) {
							return false;
						}
					} else {
						if (filter.get('recipients').indexOf('nobody') === -1) {
							return false;
						}
					}
				}

				if (filter.get('scope') !== 'all') {
					if (filter.get('scope').size === 0) {
						return false;
					}

					if (
						(definition.settings.segmentName === undefined && filter.get('scope').indexOf(definition.scope) === -1)
						|| (definition.settings.segmentName && filter.get('scope').indexOf('segment:' + definition.settings.segmentName) === -1)
					) {
						return false;
					}
				}

				if (filter.get('sensitivity').size < 4) {
					if (filter.get('sensitivity').size === 0) {
						return false;
					}

					if (filter.get('sensitivity').indexOf(definition.settings.sensitivityLevel) === -1) {
						return false;
					}
				}

				if (filter.get('messagingAppChannelId') !== 'all') {
					if (filter.get('messagingAppChannelId').size === 0) {
						return false;
					}

					if (definition.messagingAppChannels.length > 0) {
						if (!definition.messagingAppChannels.some((messagingAppChannel) => filter.get('messagingAppChannelId').indexOf(messagingAppChannel.id) !== -1)) {
							return false;
						}
					} else {
						if (filter.get('messagingAppChannelId').indexOf('none') === -1) {
							return false;
						}
					}
				}

				if (filter.get('type')) {
					const messageId = `alerts.types.${definition.alertType}.title`;

					assertDictionaryId(messageId);

					const alertTypeTitle = intl.formatMessage(
						{ id: messageId },
						definition.settings,
					).toLowerCase();

					const value = filter.get('type').toLowerCase();

					if (alertTypeTitle.includes(value) === false) {
						return false;
					}
				}

				return true;
			});

			result.sort((definitionA, definitionB) => {
				if (definitionA.alertType === definitionB.alertType) {
					if (definitionA.scope === definitionB.scope) {
						return 0;
					}

					const scopeAPosition = definitionA.scope === 'website'
						? 0
						: 1;

					const scopeBPosition = definitionB.scope === 'website'
						? 0
						: 1;

					return scopeAPosition < scopeBPosition ? -1 : 1;
				}

				return getSortByType(definitionA.alertType) < getSortByType(definitionB.alertType) ? -1 : 1;
			});

			return result;
		},
		[
			alertDefinitions,
			filter,
			intl,
		],
	);

	const handleFilter = React.useCallback(
		(update) => {
			setFilter(
				filter.merge(update),
			);
		},
		[
			filter,
			setFilter,
		],
	);

	const handleRemoveFilter = React.useCallback(
		(column) => {
			setFilter(
				defaultFilter.has(column)
					? filter.set(column, defaultFilter.get(column))
					: filter.remove(column),
			);
		},
		[
			filter,
			setFilter,
		],
	);

	const handleSelection = React.useCallback(
		({ checked }) => {
			dispatch(
				setSelectedDefinitions({
					selectedDefinitions: checked,
				}),
			);
		},
		[
			dispatch,
		],
	);

	return (
		<FlashMessageProvider>
			{({ flashMessage }) => (
				<ScreenLayout
					flashMessage={flashMessage}
					header={(
						<ScreenHeader>
							<Prefetch
								websiteId={websiteId}
							/>

							<HeaderTitleLayout>
								<HeaderTitleWithValue
									title={(
										<FormattedMessage {...messages.title} />
									)}
									value={(alertDefinitions.isLoaded && filteredAlertDefinitions.length > 0) ? filteredAlertDefinitions.length : undefined}
								/>
							</HeaderTitleLayout>

							<HeaderFilterLayout compactView={viewportType.isSmall || (viewportType.isMedium && selectedDefinitions.length === 0)}>
								{selectedDefinitions.length > 0 && (
									<ActionsController
										selectedAlertDefinitionIds={selectedDefinitions}
									/>
								)}

								{selectedDefinitions.length === 0 && (
									<ActiveFilter
										activeFilters={removeDefaultValues(filter, defaultFilter)}
										filterNames={{
											messagingAppChannel: (
												<FormattedMessage {...messages.columnMessagingAppChannel} />
											),
											recipients: (
												<FormattedMessage {...messages.columnRecipients} />
											),
											scope: (
												<FormattedMessage {...messages.columnScope} />
											),
											sensitivity: (
												<FormattedMessage {...messages.columnSensitivity} />
											),
											type: (
												<FormattedMessage {...messages.columnType} />
											),
										}}
										removeFilterCallback={handleRemoveFilter}
										segmentsManagement={false}
									/>
								)}
							</HeaderFilterLayout>

							<HeaderOptionsLayout>
								<HeaderButtonsLayout>
									<WithPermission
										action={GraphQL.ActionWithWebsite.ManageAlertDefinitions}
										objectId={websiteId}
										objectType={ObjectType.Website}
									>
										{({ isAllowed }) => {
											if (isAllowed.yes === false) {
												return false;
											}

											return (
												<CreateAlertDefinitionsButton
													hasDefinitions={alertDefinitions.isLoaded ? alertDefinitions.count > 0 : true}
													websiteId={websiteId}
												/>
											);
										}}
									</WithPermission>

									<BackToAlertsOverviewButton
										compact={viewportType.isMedium || (selectedDefinitions.length > 0 && viewportWidth < 1270)}
									/>
								</HeaderButtonsLayout>
							</HeaderOptionsLayout>
						</ScreenHeader>
					)}
				>
					<OverviewDatatable
						filter={filter}
						filteredAlertDefinitions={filteredAlertDefinitions}
						isLoaded={alertDefinitions.isLoaded}
						onFilterChangeCallback={handleFilter}
						onSelectionCallback={handleSelection}
						selectedDefinitions={selectedDefinitions}
						totalNumberOfAlertDefinitions={alertDefinitions.count}
					/>
				</ScreenLayout>
			)}
		</FlashMessageProvider>
	);
};



export default AlertsConfigurationScreen;
