import {
	useUserJobTitleQuery,
} from './useUserJobTitle.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserJobTitle(legacyUserId: string | null): string | null {
	const { data } = useUserPropertiesQuery(
		useUserJobTitleQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.jobTitle ?? null;
}



export default useUserJobTitle;
