import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';



const messages = defineMessages({
	[GraphQL.AnalyticServiceType.AdobeAnalytics]: {
		id: 'ui.contentData.adobe_analytics',
	},
	[GraphQL.TagManagerType.AdobeTagManager]: {
		id: 'ui.contentData.adobe_tag_manager',
	},
	[GraphQL.VisualAnalyticServiceType.Clicktale]: {
		id: 'ui.contentData.clicktale',
	},
	[GraphQL.VisualAnalyticServiceType.Clicky]: {
		id: 'ui.contentData.clicky',
	},
	[GraphQL.VisualAnalyticServiceType.Contentsquare]: {
		id: 'ui.contentData.contentsquare',
	},
	[GraphQL.VisualAnalyticServiceType.CrazyEgg]: {
		id: 'ui.contentData.crazy_egg',
	},
	[GraphQL.AnalyticServiceType.GoogleAnalytics]: {
		id: 'ui.contentData.google_analytics',
	},
	[GraphQL.TagManagerType.GoogleTagManager]: {
		id: 'ui.contentData.google_tag_manager',
	},
	[GraphQL.VisualAnalyticServiceType.Hotjar]: {
		id: 'ui.contentData.hotjar',
	},
	[GraphQL.VisualAnalyticServiceType.Inspectlet]: {
		id: 'ui.contentData.inspectlet',
	},
	[GraphQL.VisualAnalyticServiceType.MicrosoftClarity]: {
		id: 'ui.contentData.microsoftClarity',
	},
	[GraphQL.VisualAnalyticServiceType.Mouseflow]: {
		id: 'ui.contentData.mouseflow',
	},
	[GraphQL.TagManagerType.SegmentComTagManager]: {
		id: 'ui.contentData.segmentComTagManager',
	},
	[GraphQL.VisualAnalyticServiceType.Smartlook]: {
		id: 'ui.contentData.smartlook',
	},
});



type Props = {
	service: (
		| GraphQL.AnalyticServiceType
		| GraphQL.TagManagerType
		| GraphQL.VisualAnalyticServiceType
	),
};

const AnalyticServiceName: React.FC<Props> = (props) => {
	const {
		service,
	} = props;

	const message = messages[service];

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (message === undefined) {
		return null;
	}

	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default AnalyticServiceName;
