import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const LighthouseIconBuilder: IconBuilder = {
	icon: ({ color = '#6166e3' }) => {
		return (
			<g
				fill="none"
				stroke={color}
				strokeLinejoin="round"
				strokeWidth="2"
			>
				<path d="M24.68,16.42H7.76v5.14H24.68Z" />
				<g strokeLinecap="round">
					<path d="M10,16.42V12.57a6.22,6.22,0,0,1,.47-2.37,6.16,6.16,0,0,1,3.35-3.35,6.18,6.18,0,0,1,2.37-.47h0a6.18,6.18,0,0,1,6.19,6.19v3.85" />
					<path d="M10.28 11h11.93m-5.99-7.55v2.93M10 14.27v2.15M14.15 11v5.42M18.28 11v5.42m4.13-2.15v2.15M23 27.61l-.21-6.06m-13.11 0l-.37 6.06M3.54 9.42l3.6.76m-3.6 3.47l3.6-.77m21.52-3.46l-3.59.76m3.59 3.47l-3.59-.77" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default LighthouseIconBuilder;
