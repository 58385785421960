import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ScienceIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M20.5,19.345l-4.362-7.383A1,1,0,0,1,16,11.453V2.5a.5.5,0,0,1,.5-.5H18a1,1,0,0,0,0-2H6A1,1,0,0,0,6,2H7.5a.5.5,0,0,1,.5.5v8.954a1,1,0,0,1-.139.508L3.538,19.281A3,3,0,0,0,5.99,24H18.006A3,3,0,0,0,20.5,19.345ZM8.673,16a.5.5,0,0,1-.43-.754L9.86,12.509A1.013,1.013,0,0,0,10,12V2.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5V5.25a.5.5,0,0,1-.5.5H12.18a.75.75,0,0,0,0,1.5H13.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H12.18a.75.75,0,0,0,0,1.5H13.5a.5.5,0,0,1,.5.5V12a1,1,0,0,0,.139.509l1.618,2.737a.5.5,0,0,1-.43.754Zm.813,5.822a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,9.486,21.822Zm4.219-3.017a1,1,0,1,1,1-1A1,1,0,0,1,13.705,18.805Zm2.5,3.082a1,1,0,1,1,1-1A1,1,0,0,1,16.2,21.887Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ScienceIconBuilder;
