import {
	parseISO,
} from 'date-fns';
import React from 'react';



function useIsoDate<TDate extends string | null>(isoDate: TDate): TDate extends string ? Date : null;

function useIsoDate(isoDate: string | null): Date | null {
	return React.useMemo(
		() => {
			if (isoDate === null) {
				return null;
			}

			return parseISO(isoDate);
		},
		[
			isoDate,
		],
	);
}



export default useIsoDate;
