import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import ColumnName from '~/components/names/ColumnName';
import ColumnValueFormatter from '~/components/logic/formatters/ColumnValueFormatter';
import {
	linkInternal,
} from '~/components/logic/Copy';
import DatePeriodLabel from '~/components/app/DatePeriodLabel';
import DefinitionTermValueSkeleton from './DefinitionTermValueSkeleton';
import Emphasis from '~/components/patterns/typography/Emphasis';
import Grid, {
	GridGapsSize,
} from '~/components/patterns/structures/Grid';
import {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LabeledValue, {
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import OpenGoogleSearchConsoleButton from '~/components/app/OpenGoogleSearchConsoleButton';
import RichText from '~/components/patterns/typography/RichText';

import {
	useGoogleSearchConsoleBlockQuery,
} from './GoogleSearchConsoleBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



const messages = defineMessages({
	notEnabled: {
		id: 'ui.pageDetail.googleSearchConsole.notEnabled',
	},
	title: {
		id: 'ui.pageDetail.googleSearchConsole.title',
	},
});



const GoogleSearchConsoleBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const viewportType = useViewportType();
	const websiteId = useWebsiteId();

	const websiteIntegrations = useWebsiteIntegrations(websiteId);
	const isGoogleSearchConsoleEnabled = websiteIntegrations.isEnabled(GraphQL.WebsiteIntegrationType.GoogleSearchConsole);

	const { data } = usePageDetailPropertiesQuery(
		useGoogleSearchConsoleBlockQuery,
		legacyUrlId,
		websiteId,
		{
			skip: !isGoogleSearchConsoleEnabled,
		},
	);

	const googleSearchConsoleData = data?.lookupPageByLegacyId?.googleSearchConsoleData ?? null;
	const googleSearchConsoleMissingDataReason = data?.lookupPageByLegacyId?.googleSearchConsoleMissingDataReason ?? null;
	const googleSearchConsolePerformanceReportLink = data?.lookupPageByLegacyId?.googleSearchConsolePerformanceReportLink ?? null;

	const subtitle = googleSearchConsoleData !== null && (
		<DatePeriodLabel
			since={new Date(googleSearchConsoleData.dateRange.since)}
			until={new Date(googleSearchConsoleData.dateRange.until)}
		/>
	);

	const isSmaller = viewportType.isSmall || viewportType.isMedium;

	let maxValueWidth = isSmaller ? 350 : 210;

	if (googleSearchConsoleData === null) {
		maxValueWidth += 100;
	}

	function renderField(column: CK.PagesCommonColumn, field: string) {
		let value;

		if (googleSearchConsoleData !== null) {
			value = googleSearchConsoleData[field];
		} else if (googleSearchConsoleMissingDataReason !== null) {
			value = googleSearchConsoleMissingDataReason;
		}

		return (
			<LabeledValue
				flow={LabeledValueFlow.Row}
				label={(
					<ColumnName column={column} />
				)}
				maxWidth={maxValueWidth}
			>
				{value !== undefined ? (
					<ColumnValueFormatter
						column={column}
						value={value}
					/>
				) : (
					<DefinitionTermValueSkeleton />
				)}
			</LabeledValue>
		);
	}

	const gscClicks = renderField(CK.PagesCommonColumn.GscClicks, 'clicks');
	const gscCtr = renderField(CK.PagesCommonColumn.GscCtr, 'ctr');
	const gscImpressions = renderField(CK.PagesCommonColumn.GscImpressions, 'impressions');
	const gscPosition = renderField(CK.PagesCommonColumn.GscPosition, 'position');

	return (
		<BorderedBox
			headerActionElements={googleSearchConsolePerformanceReportLink !== null && (
				<OpenGoogleSearchConsoleButton
					link={googleSearchConsolePerformanceReportLink}
				/>
			)}
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.GoogleSearchConsole}
				/>
			)}
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			headerSublabel={subtitle}
			paddingSize={3}
		>
			{!isGoogleSearchConsoleEnabled ? (
				<RichText>
					<Emphasis>
						<FormattedMessage
							{...messages.notEnabled}
							values={{
								linkIntegrations: linkInternal(
									'website.detail.integrations',
									{ websiteId },
									{ style: InternalLinkStyle.Decent },
								),
							}}
						/>
					</Emphasis>
				</RichText>
			) : isSmaller ? (
				<LabeledValuesList>
					{gscClicks}
					{gscImpressions}
					{gscCtr}
					{gscPosition}
				</LabeledValuesList>
			) : (
				<Grid
					columnsCount={2}
					gapsSize={GridGapsSize.Responsive}
				>
					<LabeledValuesList>
						{gscClicks}
						{gscImpressions}
					</LabeledValuesList>

					<LabeledValuesList>
						{gscCtr}
						{gscPosition}
					</LabeledValuesList>
				</Grid>
			)}
		</BorderedBox>
	);
};



export default GoogleSearchConsoleBlock;
