import {
	Map,
} from 'immutable';

import {
	encodeFilterParameter,
} from './filterParameter';

import {
	LIST_OF_ALL_SITEMAP_REFERENCES,
	LIST_OF_ALL_SITEMAP_STATUSES,
	LIST_OF_ALL_SITEMAP_TYPES,
} from './sitemaps';

import removeDefaultValues from '~/utilities/removeDefaultValues';



export const SYNC_INTERVAL_SITEMAPS_LIST = 10000;



export const DEFAULT_FILTER: Map<string, any> = Map({
	references: LIST_OF_ALL_SITEMAP_REFERENCES,
	status: LIST_OF_ALL_SITEMAP_STATUSES,
	type: LIST_OF_ALL_SITEMAP_TYPES,
});

export const DEFAULT_SORT_BY = Map({
	key: 'type',
	direction: false,
});



export function createSitemapsListFilterParameter(filter, sortBy) {
	return encodeFilterParameter({
		defaultFilter: DEFAULT_FILTER,
		defaultSortBy: DEFAULT_SORT_BY,
		filter,
		sortBy,
	});
}



export function removeDefaultFilterValues(activeFilters) {
	return removeDefaultValues(activeFilters, DEFAULT_FILTER);
}
