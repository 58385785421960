import React from 'react';

import type CK from '~/types/contentking';

import {
	useAccountPaidUntilQuery,
} from './useAccountPaidUntil.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountPaidUntil(accountId: CK.AccountId | null): Date | null {
	const { data } = useAccountPropertiesQuery(
		useAccountPaidUntilQuery,
		accountId,
	);

	const paidUntil = data?.account?.paidUntil ?? null;

	return React.useMemo(
		() => {
			if (paidUntil === null) {
				return null;
			}

			return new Date(paidUntil);
		},
		[
			paidUntil,
		],
	);
}



export default useAccountPaidUntil;
