import classNames from 'classnames';
import React from 'react';
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';



type Props = {
	active?: boolean,
	children?: React.ReactNode,
	className?: string,
};

const MessageAnimation: React.FC<Props> = (props) => {
	const {
		active,
		children,
		className,
	} = props;

	return (
		<TransitionGroup>
			{children ? (
				<CSSTransition
					className={classNames(
						'message-animation',
						className,
					)}
					classNames={{
						enter: 'message-animation--before-opening',
						enterActive: 'message-animation--opening',
						exit: 'message-animation--before-closing',
						exitActive: 'message-animation--closing',
					}}
					in={active}
					timeout={300}
				>
					<div>
						{children}
					</div>
				</CSSTransition>
			) : null}
		</TransitionGroup>
	);
};



export default MessageAnimation;
