import classNames from 'classnames';
import React from 'react';



export enum PlanDetailsPlanNameSize {
	Default = 'default',
	Large = 'large',
}

type Props = {
	/** Collapse pricing area when no pricing is rendered */
	collapsePrice?: boolean,
	/** Call-to-action button */
	ctaButton: React.ReactElement,
	/** Compact look of detail */
	isCompact?: boolean,
	/** Styling of details when plan is disabled */
	isDisabled?: boolean,
	/** Plan name */
	name: React.ReactNode,
	/** Size of plan name */
	planNameSize?: PlanDetailsPlanNameSize,
	/** Price overview info */
	priceOverview: React.ReactNode,
};



const PlanDetails: React.FC<Props> = (props) => {
	const {
		collapsePrice,
		ctaButton,
		isCompact,
		isDisabled,
		name,
		planNameSize,
		priceOverview,
	} = props;

	const componentClasses = classNames({
		'plan-details': true,
		'plan-details--collapse-price': collapsePrice,
		'plan-details--is-compact': isCompact,
		'plan-details--is-disabled': isDisabled,
	});

	const planNameClasses = classNames({
		'plan-details__name': true,
		['plan-details__name--' + planNameSize + '-size']: true,
	});

	return (
		<div className={componentClasses}>
			<h3 className={planNameClasses}>
				{name}
			</h3>

			<div className="plan-details__price-overview">
				{priceOverview}
			</div>

			<div className="plan-details__cta-button">
				{ctaButton}
			</div>
		</div>
	);
};



export default PlanDetails;
