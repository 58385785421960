import type React from 'react';

import useCloseByEscape from '~/hooks/useCloseByEscape';



type Props = {
	callback: () => void,
};

const CloseByEscape: React.FC<Props> = (props) => {
	const {
		callback,
	} = props;

	useCloseByEscape(callback);

	return null;
};



export default CloseByEscape;
