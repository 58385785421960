import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const WebVitalsIconBuilder: IconBuilder = {
	icon: ({ color = '#5893eb' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeWidth="2"
			>
				<path
					d="M16,27C9.9,27,5,22.1,5,16S9.9,5,16,5s11,4.9,11,11S22.1,27,16,27z"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					d="M22,10l-5,3.8c0.6,0.2,1,0.6,1.3,1.2L22,10z"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2.4615"
				/>
				<path
					clipRule="evenodd"
					d="M16,23c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S16.6,23,16,23z"
					fill={color}
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M13,23c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S13.6,23,13,23z"
					fill={color}
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M19,23c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S19.6,23,19,23z"
					fill={color}
					fillRule="evenodd"
				/>
				<path
					d="M16,8V5"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					d="M10,10L8.2,8.2"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					d="M22,10l1.8-1.8"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					d="M24,16h3"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					d="M5,16h3"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					d="M16.6,17.9c-1,0.3-2.2-0.2-2.5-1.3c-0.3-1,0.2-2.2,1.3-2.5c1-0.3,2.2,0.2,2.5,1.3C18.2,16.4,17.7,17.6,16.6,17.9L16.6,17.9z"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2.4615"
				/>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default WebVitalsIconBuilder;
