import classNames from 'classnames';
import React from 'react';



export enum UnderlinedRowsStyle {
	Default = 'default',
	Light = 'light',
}

type Props = {
	/** Definition of gaps in every item of the list */
	gapsSize?: 0 | 1 | 2 | 3,
	children: React.ReactNode,
	style?: UnderlinedRowsStyle,
};



const UnderlinedRows: React.FC<Props> = (props) => {
	const {
		gapsSize = 0,
		children,
		style = UnderlinedRowsStyle.Default,
	} = props;

	const componentClasses = classNames({
		'underlined-rows': true,
		'underlined-rows--small-gaps': gapsSize === 1,
		'underlined-rows--large-gaps': gapsSize === 2,
		'underlined-rows--xlarge-gaps': gapsSize === 3,
		['underlined-rows--' + style + '-style']: true,
	});

	return (
		<div className={componentClasses}>
			{React.Children.map(
				children,
				(child) => {
					if (!child) {
						return false;
					}

					const extraProps: React.HTMLProps<HTMLDivElement> = {};
					let key: string | null | undefined = undefined;

					if (React.isValidElement(child) && 'key' in child) {
						key = child.key;
					}

					return (
						<div
							className="underlined-rows__row"
							{...extraProps}
							key={key}
						>
							{child}
						</div>
					);
				},
			)}
		</div>
	);
};



export default UnderlinedRows;
