import React from 'react';

import GraphQL from '~/types/graphql';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';



const iconMapping = {
	[GraphQL.MessagingAppType.Slack]: BasicIconType.Slack,
	[GraphQL.MessagingAppType.MicrosoftTeams]: BasicIconType.MicrosoftTeams,
};

const labelMapping = {
	[GraphQL.MessagingAppType.Slack]: 'Slack',
	[GraphQL.MessagingAppType.MicrosoftTeams]: 'Teams',
};



function useMessagingAppDefinitions() {
	return React.useMemo(
		() => ({
			getIcon: (type: GraphQL.MessagingAppType) => iconMapping[type],
			getLabel: (type: GraphQL.MessagingAppType) => labelMapping[type],
			listAll: () => [
				GraphQL.MessagingAppType.Slack,
				GraphQL.MessagingAppType.MicrosoftTeams,
			],
		}),
		[],
	);
}



export default useMessagingAppDefinitions;
