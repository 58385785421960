import PropTypes from 'prop-types';



enum Breakpoint {
	Mobile = 642,
	Desktop = 1168,
	Xlarge = 1440,
}

export enum Viewport {
	Small = 'VIEWPORT_SMALL',
	Medium = 'VIEWPORT_MEDIUM',
	Large = 'VIEWPORT_LARGE',
	Xlarge = 'VIEWPORT_XLARGE',
}

export enum Orientation {
	Landscape = 'LANDSCAPE',
	Portrait = 'PORTRAIT',
}

// Special breakpoint for tabbed view
export const TABBED_VIEW_BREAKPOINT = 830;



export function getCurrentDeviceOrientation(width: number, height: number): Orientation {
	if (width <= height) {
		return Orientation.Portrait;
	}

	return Orientation.Landscape;
}



export function getCurrentViewportSize(width: number): Viewport {
	if (width <= Breakpoint.Mobile) {
		return Viewport.Small;
	} else if (width <= Breakpoint.Desktop) {
		return Viewport.Medium;
	} else if (width <= Breakpoint.Xlarge) {
		return Viewport.Large;
	}

	return Viewport.Xlarge;
}



export function viewportOrientationValidation() {
	return PropTypes.oneOf([
		Orientation.Landscape,
		Orientation.Portrait,
	]);
}



export function viewportTypeValidation() {
	return PropTypes.oneOf([
		Viewport.Small,
		Viewport.Medium,
		Viewport.Large,
		Viewport.Xlarge,
	]);
}
