import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountCanSignupRemoveWebsiteLimitQuery,
} from './useAccountCanSignupRemoveWebsiteLimit.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountCanSignupRemoveWebsiteLimit(accountId: CK.AccountId | null): GraphQL.Account['canSignupRemoveWebsiteLimit'] | null {
	const { data } = useAccountPropertiesQuery(
		useAccountCanSignupRemoveWebsiteLimitQuery,
		accountId,
	);

	return data?.account?.canSignupRemoveWebsiteLimit ?? null;
}



export default useAccountCanSignupRemoveWebsiteLimit;
