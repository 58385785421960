/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsiteOnPageRequestsBlockingRulesetMutationVariables = GraphQL.Exact<{
  mode: GraphQL.OnPageRequestsBlockingMode;
  rules: ReadonlyArray<GraphQL.Scalars['Array']['input']> | GraphQL.Scalars['Array']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteOnPageRequestsBlockingRulesetMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteOnPageRequestsBlockingRuleset: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly onPageRequestsBlockingMode: GraphQL.OnPageRequestsBlockingMode, readonly onPageRequestsBlockingRules: ReadonlyArray<{ readonly __typename?: 'OnPageRequestsBlockingRule', readonly value: string, readonly operator: GraphQL.OnPageRequestsBlockingRuleOperator }> } | null } } };


export const UpdateWebsiteOnPageRequestsBlockingRulesetDocument = gql`
    mutation UpdateWebsiteOnPageRequestsBlockingRuleset($mode: OnPageRequestsBlockingMode!, $rules: [Array!]!, $websiteId: WebsiteId!) {
  UpdateWebsiteOnPageRequestsBlockingRuleset(
    mode: $mode
    rules: $rules
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        onPageRequestsBlockingMode
        onPageRequestsBlockingRules {
          value
          operator
        }
      }
    }
  }
}
    `;
export type UpdateWebsiteOnPageRequestsBlockingRulesetMutationFn = Apollo.MutationFunction<UpdateWebsiteOnPageRequestsBlockingRulesetMutation, UpdateWebsiteOnPageRequestsBlockingRulesetMutationVariables>;

/**
 * __useUpdateWebsiteOnPageRequestsBlockingRulesetMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteOnPageRequestsBlockingRulesetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteOnPageRequestsBlockingRulesetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteOnPageRequestsBlockingRulesetMutation, { data, loading, error }] = useUpdateWebsiteOnPageRequestsBlockingRulesetMutation({
 *   variables: {
 *      mode: // value for 'mode'
 *      rules: // value for 'rules'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteOnPageRequestsBlockingRulesetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteOnPageRequestsBlockingRulesetMutation, UpdateWebsiteOnPageRequestsBlockingRulesetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteOnPageRequestsBlockingRulesetMutation, UpdateWebsiteOnPageRequestsBlockingRulesetMutationVariables>(UpdateWebsiteOnPageRequestsBlockingRulesetDocument, options);
      }
export type UpdateWebsiteOnPageRequestsBlockingRulesetMutationHookResult = ReturnType<typeof useUpdateWebsiteOnPageRequestsBlockingRulesetMutation>;
export type UpdateWebsiteOnPageRequestsBlockingRulesetMutationResult = Apollo.MutationResult<UpdateWebsiteOnPageRequestsBlockingRulesetMutation>;
export type UpdateWebsiteOnPageRequestsBlockingRulesetMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteOnPageRequestsBlockingRulesetMutation, UpdateWebsiteOnPageRequestsBlockingRulesetMutationVariables>;