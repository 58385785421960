import React from 'react';



function usePrevious<T = any>(value: T): T | undefined {
	const ref = React.useRef<T | undefined>(undefined);

	React.useEffect(
		() => {
			ref.current = value;
		},
		[
			value,
		],
	);

	return ref.current;
}



export default usePrevious;
