import classNames from 'classnames';
import React from 'react';



export enum DatatableBodyCellAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

export enum DatatableBodyCellColorStyle {
	// Categories
	Content = 'content',
	Conversions = 'conversions',
	CustomElements = 'custom_elements',
	Fundamentals = 'fundamentals',
	GoogleAnalytics = 'google_analytics',
	GoogleSearchConsole = 'google_search_console',
	History = 'history',
	Indexability = 'indexability',
	Media = 'media',
	Realtions = 'relations',
	SchemaOrg = 'schema_org',
	Social = 'social',

	// Change styles
	Added = 'added',
	Changed = 'changed',
	Other = 'other',
	Redirected = 'redirected',
	Removed = 'removed',
}

export type DatatableBodyCellOnClickInput = {
	event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	rowIndex: number,
};

export enum DatatableBodyCellPadding {
	Default = 'default',
	CheckboxPadding = 'checkbox-padding',
	NoPadding = 'no-padding',
}

export enum DatatableBodyCellSize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	alignment?: DatatableBodyCellAlignment,
	children?: React.ReactNode,
	/** Manually set clickabled style. We set this style also when onClickCallback is defined. */
	clickable?: boolean,
	/** Applied style for cell display as colspan */
	colspan?: boolean,
	colorStyle?: DatatableBodyCellColorStyle,
	/** CSS style provided by react-virtualized */
	cssStyle?: React.CSSProperties,
	/** Bottom divider of cell */
	divider?: boolean,
	/** Inactive style of cell */
	inactive?: boolean,
	isInHighlightedColumn?: boolean,
	isInHighlightedRow?: boolean,
	onClickCallback?: (input: DatatableBodyCellOnClickInput) => void,
	onContextMenuCallback?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
	onMouseEnterCallback?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, rowIndex: number) => void,
	onMouseLeaveCallback?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, rowIndex: number) => void,
	padding?: DatatableBodyCellPadding,
	rowIndex: number,
	/** Show text in cell as semitransparent */
	semitransparent?: boolean,
	separator?: boolean,
	size?: DatatableBodyCellSize,
	/** Sticky cell */
	sticky?: boolean,
};

const DatatableBodyCell: React.FC<Props> = (props) => {
	const {
		alignment = DatatableBodyCellAlignment.Left,
		clickable,
		colspan,
		colorStyle,
		cssStyle,
		children,
		divider,
		inactive,
		isInHighlightedColumn,
		isInHighlightedRow,
		onClickCallback,
		onContextMenuCallback,
		onMouseEnterCallback,
		onMouseLeaveCallback,
		padding = DatatableBodyCellPadding.Default,
		rowIndex,
		semitransparent,
		separator = true,
		size = DatatableBodyCellSize.Default,
		sticky,
	} = props;

	const cellClasses = classNames({
		'datatable-body-cell': true,
		'datatable-body-cell--center-aligned': alignment === DatatableBodyCellAlignment.Center,
		'datatable-body-cell--right-aligned': alignment === DatatableBodyCellAlignment.Right,
		'datatable-body-cell--clickable': clickable || onClickCallback,
		'datatable-body-cell--colspan': colspan,
		'datatable-body-cell--has-divider': divider,
		'datatable-body-cell--even': rowIndex % 2 === 1,
		'datatable-body-cell--highlighted-column': isInHighlightedColumn,
		'datatable-body-cell--highlighted-row': isInHighlightedRow,
		'datatable-body-cell--has-separator': separator,
		'datatable-body-cell--has-padding': padding !== DatatableBodyCellPadding.NoPadding,
		'datatable-body-cell--checkbox': padding === DatatableBodyCellPadding.CheckboxPadding,
		'datatable-body-cell--inactive': inactive,
		'datatable-body-cell--sticky': sticky,
		'datatable-body-cell--semitransparent-text': semitransparent,
		['datatable-body-cell--' + size]: size !== DatatableBodyCellSize.Default,
		['datatable-body-cell--style-' + colorStyle]: !!colorStyle,
	});

	return (
		<div
			className={cellClasses}
			data-id={rowIndex}
			onClick={onClickCallback !== undefined ? (event) => {
				onClickCallback({ event, rowIndex });
			} : undefined}
			onContextMenu={onContextMenuCallback}
			onMouseEnter={onMouseEnterCallback !== undefined ? (event) => {
				onMouseEnterCallback(event, rowIndex);
			} : undefined}
			onMouseLeave={onMouseLeaveCallback !== undefined ? (event) => {
				onMouseLeaveCallback(event, rowIndex);
			} : undefined}
			style={cssStyle}
		>
			{children}
		</div>
	);
};



export default DatatableBodyCell;
