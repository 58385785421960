import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AbstractTextField, {
	AbstractTextFieldSize,
} from '~/components/patterns/forms/fields/AbstractTextField';
import ChevronButton from '~/components/patterns/forms/fieldParts/buttons/ChevronButton';
import FieldDropdownSelectableOptions from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdownSelectableOptions';
import ResetButton from '~/components/patterns/forms/fieldParts/buttons/ResetButton';

import useFormContext from '~/hooks/useFormContext';
import usePrevious from '~/hooks/usePrevious';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



export enum NumberFilterFieldMode {
	DaysAgo = 'days',
	Decimal = 'decimal',
	Milliseconds = 'milliseconds',
	Months = 'months',
	Percent = 'percent',
	Seconds = 'seconds',
}

export enum NumberFilterFieldOperator {
	Any = 'any',
	EqualTo = 'equal_to',
	LessThan = 'less_than',
	MoreThan = 'more_than',
	WithinRange = 'within_range',
}



const operatorMessages = defineMessages({
	[NumberFilterFieldOperator.Any]: {
		id: 'ui.numberFilter.operator.any',
	},
	[NumberFilterFieldOperator.EqualTo]: {
		id: 'ui.numberFilter.operator.equalTo',
	},
	[NumberFilterFieldOperator.LessThan]: {
		id: 'ui.numberFilter.operator.lessThan',
	},
	[NumberFilterFieldOperator.MoreThan]: {
		id: 'ui.numberFilter.operator.moreThan',
	},
	[NumberFilterFieldOperator.WithinRange]: {
		id: 'ui.numberFilter.operator.withinRange',
	},
});

const suffixMessages = defineMessages({
	days: {
		id: 'ui.numberFilter.suffix.daysAgo',
	},
	milliseconds: {
		id: 'ui.numberFilter.suffix.milliseconds',
	},
	rangeExample: {
		id: 'ui.numberFilter.suffix.rangeExample',
	},
	seconds: {
		id: 'ui.numberFilter.suffix.seconds',
	},
});

const DROPDOWN_MIN_WIDTH = 240;

const FILTER_OPERATOR_OPTIONS = [
	{
		operator: '=',
		name: NumberFilterFieldOperator.EqualTo,
		formatter: (currentValue) => {
			// get only numbers
			const newValue = (currentValue || '').match(/[\-\s\d\.\%]+/)?.[0] ?? '';
			return `= ${newValue.trim()}`;
		},
	},
	{
		operator: '>',
		name: NumberFilterFieldOperator.MoreThan,
		formatter: (currentValue) => {
			// get only numbers
			const newValue = (currentValue || '').match(/[\-\s\d\.\%]+/)?.[0] ?? '';
			return `> ${newValue.trim()}`;
		},
	},
	{
		operator: '<',
		name: NumberFilterFieldOperator.LessThan,
		formatter: (currentValue) => {
			// get only numbers
			const newValue = (currentValue || '').match(/[\-\s\d\.\%]+/)?.[0] ?? '';
			return `< ${newValue.trim()}`;
		},
	},
	{
		operator: '[',
		name: NumberFilterFieldOperator.WithinRange,
		formatter: (currentValue) => {
			// get only numbers
			const newValue = (currentValue || '').match(/[\-\s\d\.\%]+/)?.[0] ?? '';

			if (newValue.length > 0) {
				return `[${newValue.trim()};]`;
			}

			return '[;]';
		},
	},
	{
		operator: '',
		name: NumberFilterFieldOperator.Any,
		formatter: () => {
			// we will always delete whole text
			return '';
		},
	},
] as const;

function createOperators(mode) {
	const options = FILTER_OPERATOR_OPTIONS.filter((item) => {
		if (item.name === NumberFilterFieldOperator.WithinRange && mode === NumberFilterFieldMode.DaysAgo) {
			return false;
		}

		return true;
	});

	return {
		names: options.map((item) => item.name),
		options,
	};
}

type Operators = ReturnType<typeof createOperators>;

function applyModeOnInputValue(mode, value) {
	if (value === null || value === undefined) {
		return '';
	}

	if (value) {
		if (
			mode === NumberFilterFieldMode.DaysAgo
			|| mode === NumberFilterFieldMode.Seconds
			|| mode === NumberFilterFieldMode.Milliseconds
			|| mode === NumberFilterFieldMode.Months
		) {
			value = value.replace(new RegExp(`:${mode}`, 'g'), '');
		}
	}

	return value;
}

function applyModeOnOutputValue(filterOperators: Operators, mode, value) {
	if (!value) {
		return value;
	}

	if (
		mode === NumberFilterFieldMode.DaysAgo
		|| mode === NumberFilterFieldMode.Seconds
		|| mode === NumberFilterFieldMode.Milliseconds
		|| mode === NumberFilterFieldMode.Months
	) {
		const operator = getOperatorNameFromGivenValue(filterOperators, value);

		if (operator === NumberFilterFieldOperator.WithinRange) {
			let [min, max] = value.split(';');

			if (((/[\-\d\.]+/)).test(min)) {
				min = min.replace(/[\-\d\.]+/, `$&:${mode}`);
			}

			if (((/[\-\d\.]+/)).test(max)) {
				max = max.replace(/[\-\d\.]+/, `$&:${mode}`);
			}

			value = `${min};${max}`;
		} else {
			value = `${value}:${mode}`;
		}
	}

	return value;
}

function getOperatorNameFromGivenValue(filterOperators: Operators, value) {
	if (!value) {
		value = '';
	}

	value = value.trim();

	if (value === '') {
		return NumberFilterFieldOperator.Any;
	}

	let possibleOperator = value[0] ? value[0] : '';

	if (possibleOperator === '-') {
		possibleOperator = '=';
	}

	if (possibleOperator === '(') {
		possibleOperator = '[';
	}

	if (!isNaN(parseFloat(possibleOperator)) && isFinite(possibleOperator)) {
		possibleOperator = '=';
	}

	const operatorIndex = filterOperators.options.findIndex((item) => item.operator === possibleOperator);

	return getArrayItemAtSafeIndex(
		filterOperators.options,
		operatorIndex >= 0 ? operatorIndex : filterOperators.options.length - 1,
	).name;
}

function validateValue(value) {
	if (value === '') {
		return true;
	}

	if (/^[\=\>\<]?\s*\-?\d*\.?\d*\s*\%?$/.test(value)) {
		return true;
	}

	if (/^[\[\(]\s*\-?\d*\.?\d*\s*\%?\s*;\s*\-?\d*\.?\d*\s*\%?\s*[\]\)]$/.test(value)) {
		return true;
	}

	return false;
}



type Props = {
	attributes?: Record<string, any>,
	defaultOption?: string,
	dropdownWidth: number,
	maxLength?: number,
	name: string,
	mode?: NumberFilterFieldMode,
	placeholder: string,
	popperEnabled?: boolean,
	size?: AbstractTextFieldSize,
	trimValue?: boolean,
	width?: number,
};

const NumberFilterField = React.forwardRef<any, Props>((props, ref) => {
	const {
		attributes = {},
		defaultOption = FILTER_OPERATOR_OPTIONS[0].name,
		dropdownWidth,
		maxLength,
		mode = NumberFilterFieldMode.Decimal,
		name,
		placeholder,
		popperEnabled = true,
		size = AbstractTextFieldSize.Default,
		trimValue,
		width,
	} = props;

	const formContext = useFormContext();
	const prevDefaultValues = usePrevious(formContext.defaultValues);
	const prevFocused = usePrevious(formContext.focused);

	const fieldRef = React.useRef<any>();

	const filterOperators = React.useMemo(
		() => createOperators(mode),
		[
			mode,
		],
	);

	const [doingDropdownInteraction, setDoingDropdownInteraction] = React.useState(false);
	const [dropdownVisible, setDropdownVisible] = React.useState(name === formContext.focused);
	const [resetButtonVisible, setResetButtonVisible] = React.useState(false);
	const [selectedOperator, setSelectedOperator] = React.useState(
		getOperatorNameFromGivenValue(filterOperators, formContext.values[name]),
	);

	React.useImperativeHandle(ref, () => ({
		changeValue: (value) => {
			fieldRef.current.getFieldRef().current.value = applyModeOnInputValue(mode, value);

			setSelectedOperator(
				getOperatorNameFromGivenValue(filterOperators, value),
			);
		},
	}));

	const {
		defaultValues: formContextDefaultValues,
		focused: formContextFocused,
		onBlurHandler: formContextOnBlurHandler,
		onChangeHandler: formContextOnChangeHandler,
		onMountHandler: formContextOnMountHandler,
		onUnmountHandler: formContextOnUnmountHandler,
		values: formContextValues,
	} = formContext;

	const revealDropdown = React.useCallback(
		() => {
			setDropdownVisible(true);
			fieldRef.current.focus();
		},
		[
			setDropdownVisible,
		],
	);

	const closeDropdown = React.useCallback(
		() => {
			setDropdownVisible(false);

			formContextOnBlurHandler(name);
		},
		[
			formContextOnBlurHandler,
			name,
			setDropdownVisible,
		],
	);

	const isPossibleToDisplayResetButton = React.useCallback(
		() => (
			formContextValues[name]
			&& formContextValues[name].length > 0
			&& name !== formContextFocused
		),
		[
			formContextFocused,
			formContextValues,
			name,
		],
	);

	const handleDropdownOperatorChange = React.useCallback(
		(operatorName) => {
			const operatorIndex = filterOperators.names.indexOf(operatorName);
			const operatorData = getArrayItemAtSafeIndex(filterOperators.options, operatorIndex >= 0 ? operatorIndex : 0);

			const newValue = operatorData.formatter(formContextValues[name]);
			fieldRef.current.getFieldRef().current.value = newValue;

			if (validateValue(newValue)) {
				formContextOnChangeHandler(
					name,
					applyModeOnOutputValue(filterOperators, mode, newValue),
				);
			}

			fieldRef.current.focus();

			setDoingDropdownInteraction(false);
			setSelectedOperator(operatorName);
		},
		[
			filterOperators,
			formContextOnChangeHandler,
			formContextValues,
			mode,
			name,
			setDoingDropdownInteraction,
			setSelectedOperator,
		],
	);

	const handleKeyDown = React.useCallback(
		(e) => {
			e.stopPropagation();

			if (dropdownVisible) {
				let operatorIndex;

				// up key
				if (filterOperators.names.length > 0 && e.keyCode == 38) {
					operatorIndex = filterOperators.names.indexOf(selectedOperator);

					if (operatorIndex === false) {
						operatorIndex = filterOperators.names.length - 1;
					} else if (operatorIndex > 0) {
						operatorIndex = operatorIndex - 1;
					}

					handleDropdownOperatorChange(filterOperators.names[operatorIndex]);
				}

				// down key
				if (filterOperators.names.length > 0 && e.keyCode == 40) {
					operatorIndex = filterOperators.names.indexOf(selectedOperator);

					if (operatorIndex === false) {
						operatorIndex = 0;
					} else if (operatorIndex < filterOperators.names.length - 1) {
						operatorIndex = operatorIndex + 1;
					}

					handleDropdownOperatorChange(filterOperators.names[operatorIndex]);
				}
			}
		},
		[
			dropdownVisible,
			handleDropdownOperatorChange,
			filterOperators,
			selectedOperator,
		],
	);

	const handleKeyUp = React.useCallback(
		(e) => {
			e.stopPropagation();

			if (dropdownVisible) {
				// esc key
				if (e.keyCode === 27) {
					// we have to call it in async way to prevent closing modal
					setTimeout(() => {
						closeDropdown();
					}, 0);
				}
			}
		},
		[
			closeDropdown,
			dropdownVisible,
		],
	);

	const handleFieldMouseEnter = React.useCallback(
		() => {
			// set only when we do hover and we don't have focus on field
			setResetButtonVisible(
				isPossibleToDisplayResetButton(),
			);
		},
		[
			isPossibleToDisplayResetButton,
			setResetButtonVisible,
		],
	);

	const handleFieldMouseLeave = React.useCallback(
		() => {
			setResetButtonVisible(false);
		},
		[
			setResetButtonVisible,
		],
	);

	const handleToggleDropdown = React.useCallback(
		() => {
			if (dropdownVisible) {
				closeDropdown();
			} else {
				revealDropdown();
			}
		},
		[
			closeDropdown,
			dropdownVisible,
			revealDropdown,
		],
	);

	const handleDropdownMouseEnter = React.useCallback(
		() => {
			setDoingDropdownInteraction(true);
			setResetButtonVisible(isPossibleToDisplayResetButton());
		},
		[
			isPossibleToDisplayResetButton,
			setDoingDropdownInteraction,
			setResetButtonVisible,
		],
	);

	const handleDropdownMouseLeave = React.useCallback(
		() => {
			setDoingDropdownInteraction(false);
			setResetButtonVisible(false);
		},
		[
			setDoingDropdownInteraction,
			setResetButtonVisible,
		],
	);


	React.useEffect(
		() => {
			formContextOnMountHandler(
				name,
				{
					interacted: true,
				},
			);

			return () => {
				setDropdownVisible(false);
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			name,
			setDropdownVisible,
		],
	);

	React.useEffect(
		() => {
			if (
				(prevDefaultValues ?? {})[name] !== formContextDefaultValues[name]
				&& (
					formContextDefaultValues[name] === undefined
					|| formContextDefaultValues[name] === null
					|| formContextDefaultValues[name].length === 0
				)
			) {
				fieldRef.current.getFieldRef().current.value = '';
			}

			if (prevFocused === name && prevFocused !== formContextFocused) {
				// reset component state when loosing focus
				setDoingDropdownInteraction(false);
				setDropdownVisible(false);
				setResetButtonVisible(false);
			}
		},
		[
			fieldRef,
			formContextDefaultValues,
			formContextFocused,
			name,
			prevDefaultValues,
			prevFocused,
		],
	);

	const handleResetValue = React.useCallback(
		(e) => {
			e.preventDefault();

			if (!resetButtonVisible && name !== formContextFocused) {
				return;
			}

			formContextOnChangeHandler(name, '');

			fieldRef.current.getFieldRef().current.value = '';

			handleDropdownOperatorChange(defaultOption);
		},
		[
			defaultOption,
			fieldRef,
			formContextFocused,
			formContextOnChangeHandler,
			handleDropdownOperatorChange,
			name,
			resetButtonVisible,
		],
	);

	function renderDropdown() {
		const options: Array<{
			label: React.ReactNode,
			name: string,
			selected: boolean,
			suffix: React.ReactNode,
		}> = [];

		filterOperators.options.forEach((item) => {
			let suffix: React.ReactNode | null = null;

			if (item.name === NumberFilterFieldOperator.WithinRange) {
				suffix = (
					<span>
						<FormattedMessage {...suffixMessages.rangeExample} />
					</span>
				);
			} else if (item.name !== NumberFilterFieldOperator.Any && suffixMessages[mode]) {
				suffix = (
					<span>
						(<FormattedMessage {...suffixMessages[mode]} />)
					</span>
				);
			}

			options.push({
				label: (
					<FormattedMessage {...operatorMessages[item.name]} />
				),
				name: item.name,
				selected: selectedOperator === item.name,
				suffix,
			});
		});

		return (
			<FieldDropdownSelectableOptions
				onOptionChangeCallback={handleDropdownOperatorChange}
				onOptionMouseEnterCallback={handleDropdownMouseEnter}
				onOptionMouseLeaveCallback={handleDropdownMouseLeave}
				options={options}
			/>
		);
	}

	const additionalAttributes = Object.assign({}, attributes);

	additionalAttributes.autoComplete = 'never';

	if (placeholder) {
		additionalAttributes.placeholder = placeholder;
	}

	if (formContext.isDisabled) {
		additionalAttributes.disabled = 'disabled';
	}

	if (maxLength) {
		additionalAttributes.maxLength = maxLength;
	}

	let value = '';

	if (formContext.defaultValues[name]) {
		value = applyModeOnInputValue(mode, formContext.defaultValues[name]);
	} else {
		value = formContext.defaultValues[name];
	}

	const actionElements: Array<React.ReactElement> = [];

	if (resetButtonVisible) {
		actionElements.push(
			<ResetButton
				key="reset-button"
				onClick={handleResetValue}
				onMouseEnter={handleFieldMouseEnter}
				onMouseLeave={handleFieldMouseLeave}
				title="Cancel"
			/>,
		);
	} else {
		actionElements.push(
			<ChevronButton
				active={dropdownVisible}
				ignorePointerEvents={!dropdownVisible}
				key="chevron-button"
				onClick={handleToggleDropdown}
				onMouseEnter={handleDropdownMouseEnter}
				onMouseLeave={handleDropdownMouseLeave}
			/>,
		);
	}

	return (
		<AbstractTextField
			attributes={additionalAttributes}
			buttons={!formContext.isDisabled ? actionElements : []}
			dropdown={renderDropdown()}
			dropdownVisible={dropdownVisible}
			dropdownWidth={dropdownWidth < DROPDOWN_MIN_WIDTH ? DROPDOWN_MIN_WIDTH : dropdownWidth}
			name={name}
			onBlur={() => {
				if (!doingDropdownInteraction && !resetButtonVisible) {
					formContext.onBlurHandler(name);

					setDropdownVisible(false);
				}
			}}
			onChangeCallback={(value) => {
				if (validateValue(value)) {
					formContext.onChangeHandler(name, applyModeOnOutputValue(filterOperators, mode, value), {
						timeout: 250,
					});
				}

				setSelectedOperator(
					getOperatorNameFromGivenValue(filterOperators, value),
				);
			}}
			onFocus={() => {
				formContext.onFocusHandler(name);

				setDoingDropdownInteraction(false);
				setDropdownVisible(true);
				setResetButtonVisible(false);
			}}
			onKeyDown={handleKeyDown}
			onKeyUp={handleKeyUp}
			onMouseEnter={handleFieldMouseEnter}
			onMouseLeave={handleFieldMouseLeave}
			popperEnabled={popperEnabled}
			ref={fieldRef}
			size={size}
			trimValue={trimValue}
			value={value}
			width={width}
		/>
	);
});



export default NumberFilterField;

export {
	AbstractTextFieldSize as NumberFilterFieldSize,
};
