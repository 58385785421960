/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdministerAccountInvoiceSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  isDeliveryAddressEnabled: GraphQL.Scalars['Boolean']['input'];
  isExtraInvoiceDetailsEnabled: GraphQL.Scalars['Boolean']['input'];
}>;


export type AdministerAccountInvoiceSettingsMutation = { readonly __typename?: 'Mutation', readonly AdministerAccountInvoiceSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isDeliveryAddressEnabled: boolean, readonly isExtraInvoiceDetailsEnabled: boolean } | null } } };


export const AdministerAccountInvoiceSettingsDocument = gql`
    mutation AdministerAccountInvoiceSettings($accountId: AccountId!, $isDeliveryAddressEnabled: Boolean!, $isExtraInvoiceDetailsEnabled: Boolean!) {
  AdministerAccountInvoiceSettings(
    accountId: $accountId
    isDeliveryAddressEnabled: $isDeliveryAddressEnabled
    isExtraInvoiceDetailsEnabled: $isExtraInvoiceDetailsEnabled
  ) {
    query {
      account(id: $accountId) {
        id
        isDeliveryAddressEnabled
        isExtraInvoiceDetailsEnabled
      }
    }
  }
}
    `;
export type AdministerAccountInvoiceSettingsMutationFn = Apollo.MutationFunction<AdministerAccountInvoiceSettingsMutation, AdministerAccountInvoiceSettingsMutationVariables>;

/**
 * __useAdministerAccountInvoiceSettingsMutation__
 *
 * To run a mutation, you first call `useAdministerAccountInvoiceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerAccountInvoiceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerAccountInvoiceSettingsMutation, { data, loading, error }] = useAdministerAccountInvoiceSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      isDeliveryAddressEnabled: // value for 'isDeliveryAddressEnabled'
 *      isExtraInvoiceDetailsEnabled: // value for 'isExtraInvoiceDetailsEnabled'
 *   },
 * });
 */
export function useAdministerAccountInvoiceSettingsMutation(baseOptions?: Apollo.MutationHookOptions<AdministerAccountInvoiceSettingsMutation, AdministerAccountInvoiceSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerAccountInvoiceSettingsMutation, AdministerAccountInvoiceSettingsMutationVariables>(AdministerAccountInvoiceSettingsDocument, options);
      }
export type AdministerAccountInvoiceSettingsMutationHookResult = ReturnType<typeof useAdministerAccountInvoiceSettingsMutation>;
export type AdministerAccountInvoiceSettingsMutationResult = Apollo.MutationResult<AdministerAccountInvoiceSettingsMutation>;
export type AdministerAccountInvoiceSettingsMutationOptions = Apollo.BaseMutationOptions<AdministerAccountInvoiceSettingsMutation, AdministerAccountInvoiceSettingsMutationVariables>;