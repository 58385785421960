import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Colorizer, {
	ColorizerStyle,
} from '~/components/patterns/utils/Colorizer';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';

import useViewportType from '~/hooks/useViewportType';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';



const messages = defineMessages({
	currentVersion: {
		id: 'ui.pageDetail.tabs.currentData',
	},
	changes: {
		id: 'ui.pageDetail.tabs.history',
	},
	trackedChanges: {
		id: 'ui.pageDetail.tabs.historicalData',
	},
	issues: {
		id: 'ui.pageDetail.tabs.issues',
	},
	lastSeenVersion: {
		id: 'ui.pageDetail.tabs.lastSeenData',
	},
});



export enum NavigationTab {
	CurrentPage,
	History,
	Issues,
}



type Props = {
	activeTab: NavigationTab,
	hiddenTabs?: Array<NavigationTab>,
	pageId: number,
	uppercase?: boolean,
	websiteId: CK.WebsiteId,
};

const Navigation: React.FC<Props> = (props) => {
	const {
		activeTab,
		hiddenTabs = [],
		pageId,
		uppercase = false,
		websiteId,
	} = props;

	const viewportType = useViewportType();
	const websiteIsCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId) ?? false;

	const routeParams = {
		id: pageId,
		websiteId,
	};

	return (
		<TabNavigation>
			{!hiddenTabs.includes(NavigationTab.CurrentPage) && (
				<TabNavigationItem
					isActive={activeTab === NavigationTab.CurrentPage}
					linkRouteName="website.pages.detail"
					linkRouteParams={routeParams}
					uppercase={uppercase}
				>
					{websiteIsCrawlingPaused ? (
						<FormattedMessage {...messages.lastSeenVersion} />
					) : (
						<FormattedMessage {...messages.currentVersion} />
					)}
				</TabNavigationItem>
			)}

			{!hiddenTabs.includes(NavigationTab.History) && (
				<TabNavigationItem
					icon={(
						<BasicIcon type={BasicIconType.History} />
					)}
					isActive={activeTab === NavigationTab.History}
					linkRouteName="website.pages.detail.history"
					linkRouteParams={routeParams}
					uppercase={uppercase}
				>
					<Colorizer style={ColorizerStyle.History}>
						{viewportType.isSmall ? (
							<FormattedMessage {...messages.changes} />
						) : (
							<FormattedMessage {...messages.trackedChanges} />
						)}
					</Colorizer>
				</TabNavigationItem>
			)}

			{!hiddenTabs.includes(NavigationTab.Issues) && (
				<TabNavigationItem
					isActive={activeTab === NavigationTab.Issues}
					linkRouteName="website.pages.detail.issues"
					linkRouteParams={routeParams}
					uppercase={uppercase}
				>
					<FormattedMessage {...messages.issues} />
				</TabNavigationItem>
			)}
		</TabNavigation>
	);
};



export default Navigation;
