import React from 'react';

import GraphQL from '~/types/graphql';

import RealtimeValueUpdateHighlight from '~/components/patterns/values/RealtimeValueUpdateHighlight';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import WebVitalName from '~/components/app/WebVitalName';
import WebVitalFormatter from '~/components/app/WebVitalFormatter';
import WebVitalsMetric from '~/components/patterns/webVitals/WebVitalsMetric';
import WebVitalsStatusIndicator, {
	WebVitalsStatusIndicatorStatus,
} from '~/components/patterns/webVitals/WebVitalsStatusIndicator';

import {
	type WebVital,
} from '~/model/webVitals';



type Props = {
	range: GraphQL.WebVitalRange | undefined,
	type: WebVital,
	value: number | undefined,
};

const LighthouseWebVitalMetric: React.FC<Props> = (props) => {
	const {
		range,
		type,
		value,
	} = props;

	let status: WebVitalsStatusIndicatorStatus | undefined = undefined;

	if (range === GraphQL.WebVitalRange.Good) {
		status = WebVitalsStatusIndicatorStatus.Good;
	} else if (range === GraphQL.WebVitalRange.NeedsImprovement) {
		status = WebVitalsStatusIndicatorStatus.NeedsImprovement;
	} else if (range === GraphQL.WebVitalRange.Bad) {
		status = WebVitalsStatusIndicatorStatus.Poor;
	}

	return (
		<WebVitalsMetric
			label={(
				<WebVitalName type={type} />
			)}
			statusIndicator={(
				<WebVitalsStatusIndicator status={status} />
			)}
			value={value !== undefined ? (
				<RealtimeValueUpdateHighlight value={value}>
					<WebVitalFormatter
						type={type}
						value={value}
						verdict={range}
					/>
				</RealtimeValueUpdateHighlight>
			) : (
				<SquareSkeleton
					height={15}
					width={30}
				/>
			)}
		/>
	);
};



export default LighthouseWebVitalMetric;
