import React from 'react';

import DataProcessingAgreementModal from '~/components/app/DataProcessingAgreementModal';

import useAccountId from '~/hooks/useAccountId';
import useActivatePremiumTrial from '~/hooks/useActivatePremiumTrial';
import useModals from '~/hooks/useModals';
import useNavigation from '~/hooks/useNavigation';
import useUrlState from '~/hooks/useUrlState';

import {
	getRouterState,
} from '~/routing/router';



enum ExternalAction {
	ActivatePremiumTrial = 'activatepremiumtrial',
	DataProcessingAgreement = 'dataprocessingagreement',
}



const ExternalActions: React.FC = () => {
	const accountId = useAccountId();
	const activatePremiumTrial = useActivatePremiumTrial(accountId);
	const modals = useModals();
	const navigation = useNavigation();
	const urlState = useUrlState();

	React.useEffect(
		() => {
			const state = getRouterState();
			const externalAction = state.params.external_action?.toLowerCase();

			if (externalAction === ExternalAction.ActivatePremiumTrial && accountId) {
				removeExternalActionParam(navigation, urlState);
				activatePremiumTrial();
			} else if (externalAction === ExternalAction.DataProcessingAgreement && accountId) {
				removeExternalActionParam(navigation, urlState);
				modals.openModal(() => (
					<DataProcessingAgreementModal
						canBeClosed={false}
					/>
				));
			}
		},
		[
			accountId,
			activatePremiumTrial,
			modals,
			navigation,
			urlState,
		],
	);

	return null;
};



function removeExternalActionParam(navigation, urlState): void {
	const routeParams = {
		...urlState.params,
	};

	delete routeParams.external_action;

	navigation.navigate({
		routeName: urlState.routeName,
		routeParams,
		options: {
			replace: true,
		},
	});
}



export default ExternalActions;
