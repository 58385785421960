import classNames from 'classnames';
import React from 'react';

import useViewportType from '~/hooks/useViewportType';



export type MenuItemLayoutIsLabelHiddenInput = ReturnType<typeof useViewportType>;

const defaultIsLabelHidden = (viewportType: MenuItemLayoutIsLabelHiddenInput) => {
	return viewportType.isSmall || viewportType.isMedium;
};



type Props = {
	icon: React.ReactNode,
	iconTag?: React.ReactNode,
	isActive?: boolean,
	isDimmed?: boolean,
	isLabelHidden?: boolean | ((viewportType: MenuItemLayoutIsLabelHiddenInput) => boolean),
	label: React.ReactNode,
	onClick?: (e: React.MouseEvent) => void,
	onClickCallback?: () => void,
	title?: string,
};

const MenuItemLayout: React.FC<Props> = (props) => {
	const {
		icon,
		iconTag,
		isActive = false,
		isDimmed = false,
		isLabelHidden = defaultIsLabelHidden,
		label,
		onClick = null,
		onClickCallback = null,
		title,
	} = props;

	const viewportType = useViewportType();

	const handleClick = React.useCallback(
		(e) => {
			if (onClickCallback !== null) {
				e.preventDefault();
				onClickCallback();
			}
		},
		[
			onClickCallback,
		],
	);

	const componentClasses = classNames({
		'menu-item-layout': true,
		'menu-item-layout--is-active': isActive,
		'menu-item-layout--is-dimmed': isDimmed,
	});

	const hideLabel = typeof isLabelHidden === 'function' ? isLabelHidden(
		viewportType,
	) : isLabelHidden;

	const content = (
		<span
			className={componentClasses}
			title={onClick !== null ? title : undefined}
		>
			<span className="menu-item-layout__icon">
				{icon}
				{iconTag && (
					<span className="menu-item-layout__icon-tag">
						{iconTag}
					</span>
				)}
			</span>
			{!hideLabel && (
				<span className="menu-item-layout__label">
					{label}
				</span>
			)}
		</span>
	);

	if (onClick !== null || onClickCallback !== null) {
		return (
			<a
				href="#"
				onClick={onClick ?? handleClick}
				title={title}
			>
				{content}
			</a>
		);
	}

	return content;
};



export default MenuItemLayout;
