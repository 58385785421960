import React, {
	Component,
} from 'react';



class ResponsiveSidebar extends Component {

	render() {
		const {
			children,
		} = this.props;

		return (
			<div className="responsive-column responsive-column--sidebar">
				{children}
			</div>
		);
	}

}



export default ResponsiveSidebar;
