import React from 'react';

import type CK from '~/types/contentking';

import {
	useIndexNowConditionsQuery,
} from './useIndexNowConditions.gql';



function useIndexNowConditions(websiteId: CK.WebsiteId) {
	const { data, loading } = useIndexNowConditionsQuery({
		variables: {
			websiteId,
		},
	});


	return React.useMemo(
		() => {
			return {
				issuesRequiredToPass: data?.website?.indexNowIssuesRequiredToPass ?? null,
				loading,
				segmentsBlocklistAllowedSegments: data?.website?.indexNowSegmentsBlocklistAllowedSegments ?? null,
				segmentsBlocklistDisallowedSegments: data?.website?.indexNowSegmentsBlocklistDisallowedSegments ?? null,
				segmentsBlocklistMode: data?.website?.indexNowSegmentsBlocklistMode ?? null,
				urlBlocklistMode: data?.website?.indexNowUrlBlocklistMode ?? null,
				urlBlocklistRules: data?.website?.indexNowUrlBlocklistRules ?? null,
			};
		},
		[
			data,
			loading,
		],
	);
}



export default useIndexNowConditions;
