import React from 'react';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Emphasis from '~/components/patterns/typography/Emphasis';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';

import useAccountCustomElementsLimit from '~/hooks/useAccountCustomElementsLimit';
import useAccountDataRetentionInMonths from '~/hooks/useAccountDataRetentionInMonths';
import useAccountEnrichmentFieldsLimit from '~/hooks/useAccountEnrichmentFieldsLimit';
import useAccountId from '~/hooks/useAccountId';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAddonsAvailability from '~/hooks/useAddonsAvailability';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';

import {
	useAddonsFormQuery,
	useAdministerAccountAddonsMutation,
} from './AddonsForm.gql';

import matchAndReturn from '~/utilities/matchAndReturn';



const AddonsForm: React.FC = () => {
	const accountId = useAccountId();

	const accountCustomElementsLimit = useAccountCustomElementsLimit(accountId);
	const accountDataRetentionInMonths = useAccountDataRetentionInMonths(accountId);
	const accountEnrichmentFieldsLimit = useAccountEnrichmentFieldsLimit(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const addonsAvailability = useAddonsAvailability();

	const isAllowedToManageInternals = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageAdminAddons,
	);

	const { data } = useAddonsFormQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const [administerAccountAddons] = useAdministerAccountAddonsMutation();

	const addons = data?.account?.addons ?? null;

	const handleSubmit = React.useCallback(
		async (values) => {
			if (
				accountId === null
				|| accountPlan === null
				|| accountTariff === null
				|| addonsAvailability.isLoaded === false
			) {
				return;
			}

			const addons = addonsAvailability.listAvailableAddonsByTariffAndPlan(
				accountTariff,
				accountPlan,
			).map(
				(addon) => ({
					addonType: addon.addonType,
					amount: addon.maximumAmount > 1
						? parseInt(values[addon.addonType])
						: (values[addon.addonType] ? 1 : 0),
				}),
			);

			await administerAccountAddons({
				variables: {
					accountId,
					addons,
				},
			});
		},
		[
			accountId,
			accountPlan,
			accountTariff,
			addonsAvailability,
			administerAccountAddons,
		],
	);

	if (
		accountCustomElementsLimit === null
		|| accountDataRetentionInMonths === null
		|| accountEnrichmentFieldsLimit === null
		|| accountPlan === null
		|| accountTariff === null
		|| addons === null
		|| addonsAvailability.isLoaded === false
	) {
		return null;
	}

	const getCurrentAddonAmount = (addonType: GraphQL.AddonType): number => {
		return addons.find((addon) => addon.addonType === addonType)?.amount ?? 0;
	};

	const formatOptions = (addonType: GraphQL.AddonType, value: number) => {
		if (value === 0) {
			return 'no extra';
		}

		if (addonType === GraphQL.AddonType.CustomElements_5) {
			const baseCustomElementsAmount = accountCustomElementsLimit - getCurrentAddonAmount(addonType) * 5;

			return `+ ${value * 5} (total ${value * 5 + baseCustomElementsAmount})`;
		}

		if (addonType === GraphQL.AddonType.DataRetention_12) {
			const baseDataRetentionPeriodInMonths = accountDataRetentionInMonths - getCurrentAddonAmount(addonType) * 12;

			return `+ ${value}×12 months (total ${value * 12 + baseDataRetentionPeriodInMonths})`;
		}

		if (addonType === GraphQL.AddonType.EnrichmentFields_5) {
			const baseEnrichmentFieldsAmount = accountEnrichmentFieldsLimit - getCurrentAddonAmount(addonType) * 5;

			return `+ ${value * 5} (total ${value * 5 + baseEnrichmentFieldsAmount})`;
		}
	};

	const formatAddonLabel = (addonType: GraphQL.AddonType) => matchAndReturn(addonType, {
		[GraphQL.AddonType.CustomElements_5]: 'Custom elements',
		[GraphQL.AddonType.DataRetention_12]: 'Data retention',
		[GraphQL.AddonType.EnrichmentFields_5]: 'Enrichment fields',
		[GraphQL.AddonType.IndexNow]: 'IndexNow',
		[GraphQL.AddonType.LighthouseMonitoring]: 'Lighthouse Monitoring',
		[GraphQL.AddonType.MicrosoftTeams]: 'Microsoft Teams',
		[GraphQL.AddonType.Rendering]: 'Javascript Rendering',
		[GraphQL.AddonType.StoreResponseSnapshots]: 'Store response snapshots',
	});

	const availableAddons = addonsAvailability.listAvailableAddonsByTariffAndPlan(
		accountTariff,
		accountPlan,
	);

	const defaultValues: Record<string, string | boolean> = {};

	availableAddons.forEach((addon) => {
		defaultValues[addon.addonType] = addon.maximumAmount > 1
			? getCurrentAddonAmount(addon.addonType) + ''
			: getCurrentAddonAmount(addon.addonType) > 0;
	});

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageInternals}
			isForAdmins={true}
			isReadOnly={availableAddons.length === 0}
			title="Addons"
		>
			<DisplayPart>
				<FormRows>
					{availableAddons.length > 0 ? (
						availableAddons.map((addon) => (
							<FormRow
								key={addon.addonType}
								label={formatAddonLabel(addon.addonType)}
							>
								<StaticText focusTarget={addon.addonType}>
									{addon.maximumAmount > 1
										? formatOptions(addon.addonType, getCurrentAddonAmount(addon.addonType))
										: getCurrentAddonAmount(addon.addonType) > 0 ? 'enabled' : 'disabled'
									}
								</StaticText>
							</FormRow>
						))
					) : (
						<FormRow label="">
							<StaticText>
								<Emphasis>
									No available addons
								</Emphasis>
							</StaticText>
						</FormRow>
					)}
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={defaultValues}
					onSuccess={handleSubmit}
				>
					<FormRows>
						{availableAddons.map((addon) => (
							<FormRow
								key={addon.addonType}
								label={formatAddonLabel(addon.addonType)}
							>
								<StaticText focusTarget={addon.addonType}>
									{addon.maximumAmount > 1 ? (
										<SelectField
											name={addon.addonType}
											options={Array.from(Array(addon.maximumAmount + 1).keys()).map((value) => ({
												label: formatOptions(addon.addonType, value),
												name: value + '',
											}))}
										/>
									) : (
										<SwitchField
											activeStateLabel="enabled"
											inactiveStateLabel="disabled"
											name={addon.addonType}
										/>
									)}
								</StaticText>
							</FormRow>
						))}
					</FormRows>

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default AddonsForm;
