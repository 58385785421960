import React from 'react';

import type CK from '~/types/contentking';

import {
	usePageIssueCategoriesLazyQuery,
} from './usePageIssueCategories.gql';



function useReloadPageIssueCategories(
	legacyUrlId: number | null,
	websiteId: CK.WebsiteId | null,
) {
	const [reloadPageIssueCategories] = usePageIssueCategoriesLazyQuery({
		fetchPolicy: 'cache-and-network',
	});

	return React.useCallback(
		async () => {
			if (legacyUrlId === null || websiteId === null) {
				return;
			}

			await reloadPageIssueCategories({
				variables: {
					legacyUrlId,
					websiteId,
				},
			});
		},
		[
			legacyUrlId,
			reloadPageIssueCategories,
			websiteId,
		],
	);
}



export default useReloadPageIssueCategories;
