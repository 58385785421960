import React from 'react';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	/** Minimum width limit of blocks */
	blockMinWidth?: number,
	children?: React.ReactNode,
	/** Maximum width limit of content in every block */
	contentMaxWidth?: number,
};



const FlexibleBlocks: React.FC<Props> = (props) => {
	const {
		blockMinWidth,
		children,
		contentMaxWidth,
	} = props;

	const viewportType = useViewportType();

	const _renderItems = () => {
		const output: Array<React.ReactNode> = [];

		React.Children.map(
			children,
			(child, i) => {
				if (!child) {
					return false;
				}

				const blockStyle: React.CSSProperties = {};
				const containerStyle: React.CSSProperties = {};

				// To support fluid width on smaller devices, we will ignore
				// forced maxWidth.
				if (contentMaxWidth || blockMinWidth) {
					if (blockMinWidth && !viewportType.isSmall) {
						blockStyle.minWidth = blockMinWidth;
					}

					if (contentMaxWidth) {
						containerStyle.maxWidth = contentMaxWidth;
					}
				}

				output.push(
					<div
						className="flexible-blocks__block"
						key={(React.isValidElement(child) && child.key !== null) ? child.key : 'flexible-block-' + i}
						style={blockStyle}
					>
						<div
							className="flexible-blocks__block-container"
							style={containerStyle}
						>
							{child}
						</div>
					</div>,
				);
			},
		);

		return output;
	};

	const blockStyle: React.CSSProperties = {};

	// To support fluid width on smaller devices, we will ignore
	// forced maxWidth.
	if (blockMinWidth) {
		blockStyle.minWidth = blockMinWidth;
	}

	// we have 6 fake boxes because we need to keep the width on xlarge screens
	return (
		<div className="flexible-blocks">
			{_renderItems()}
			<div
				className="flexible-blocks__block flexible-blocks__block--fake"
				style={blockStyle}
			/>
			<div
				className="flexible-blocks__block flexible-blocks__block--fake"
				style={blockStyle}
			/>
			<div
				className="flexible-blocks__block flexible-blocks__block--fake"
				style={blockStyle}
			/>
			<div
				className="flexible-blocks__block flexible-blocks__block--fake"
				style={blockStyle}
			/>
			<div
				className="flexible-blocks__block flexible-blocks__block--fake"
				style={blockStyle}
			/>
			<div
				className="flexible-blocks__block flexible-blocks__block--fake"
				style={blockStyle}
			/>
		</div>
	);
};



export default FlexibleBlocks;
