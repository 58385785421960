import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import IncidentBox, {
	IncidentBoxState,
} from '~/components/patterns/incidents/IncidentBox';
import IncidentTitle from '~/components/logic/alerts/IncidentTitle';
import ScopeLabel, {
	ScopeLabelSize,
	ScopeLabelStyle,
} from '~/components/logic/scopes/ScopeLabel';



class SharedIncidentCardLayout extends Component {

	render() {
		const {
			children,
			footer,
			incident,
			openAlertId,
		} = this.props;

		return (
			<IncidentBox
				footer={footer}
				highlighted={incident.get('id') === openAlertId}
				scope={(
					<ScopeLabel
						scope={incident.get('scope')}
						size={ScopeLabelSize.Small}
						style={ScopeLabelStyle.Thumb}
					/>
				)}
				state={incident.get('phase') === 'past' ? IncidentBoxState.Past : IncidentBoxState.Active}
				title={(
					<IncidentTitle incident={incident} />
				)}
			>
				{children}
			</IncidentBox>
		);
	}

}

SharedIncidentCardLayout.propTypes = {
	footer: PropTypes.node,
	incident: PropTypes.object.isRequired,
	openAlertId: PropTypes.number,
};



export default SharedIncidentCardLayout;
