import memoize from 'memoizee';



export default function memoizeById<TData extends ReadonlyArray<unknown>, TReturn>(callback: (...data: TData) => Readonly<TReturn>) {
	const cache: Record<string, (...data: TData) => TReturn> = {};

	return (key: string | number | null, ...data: TData) => {
		key ??= '';

		let item = cache[key.toString()];

		if (item === undefined) {
			item = memoize(callback);
			cache[key.toString()] = item;
		}

		return item(...data);
	};
}
