import React from 'react';

import GlobalFormMessagePattern, {
	GlobalFormMessagePatternStyle as GlobalFormMessageStyle,
} from '~/components/atoms/forms/basis/GlobalFormMessagePattern';

import useFormContext from '~/hooks/useFormContext';

import type FormError from '~/utilities/FormError';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	children: RenderProp<{
		globalError: FormError,
	}>,
	name?: string,
	style?: GlobalFormMessageStyle,
};



const GlobalFormMessage: React.FC<Props> = (props) => {
	const {
		children,
		name,
		style = GlobalFormMessageStyle.Default,
	} = props;

	const {
		globalError,
	} = useFormContext();

	if (!globalError || (name !== undefined && globalError.getName() !== name)) {
		return null;
	}

	return (
		<GlobalFormMessagePattern
			style={style}
		>
			{renderProp(children, {
				globalError,
			})}
		</GlobalFormMessagePattern>
	);
};



export default GlobalFormMessage;

export {
	GlobalFormMessageStyle,
};
