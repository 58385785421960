/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSegmentMutationVariables = GraphQL.Exact<{
  segmentId: GraphQL.Scalars['PageSegmentId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DeleteSegmentMutation = { readonly __typename?: 'Mutation', readonly DeleteSegment: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly color: string, readonly filterDefinition: CK.FilterDefinition, readonly id: CK.PageSegmentId, readonly isBeingReevaluated: boolean, readonly isManaged: boolean, readonly label: string, readonly name: CK.PageSegmentName, readonly shortcode: string | null, readonly sizeLimit: CK.PageSegmentSizeLimit | null, readonly icon: { readonly __typename?: 'PageSegmentIcon', readonly name: string } | null }> } | null } } };


export const DeleteSegmentDocument = gql`
    mutation DeleteSegment($segmentId: PageSegmentId!, $websiteId: WebsiteId!) {
  DeleteSegment(segmentId: $segmentId, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        id
        pageSegments {
          color
          filterDefinition
          icon {
            name
          }
          id
          isBeingReevaluated
          isManaged
          label
          name
          shortcode
          sizeLimit
        }
      }
    }
  }
}
    `;
export type DeleteSegmentMutationFn = Apollo.MutationFunction<DeleteSegmentMutation, DeleteSegmentMutationVariables>;

/**
 * __useDeleteSegmentMutation__
 *
 * To run a mutation, you first call `useDeleteSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSegmentMutation, { data, loading, error }] = useDeleteSegmentMutation({
 *   variables: {
 *      segmentId: // value for 'segmentId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDeleteSegmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSegmentMutation, DeleteSegmentMutationVariables>(DeleteSegmentDocument, options);
      }
export type DeleteSegmentMutationHookResult = ReturnType<typeof useDeleteSegmentMutation>;
export type DeleteSegmentMutationResult = Apollo.MutationResult<DeleteSegmentMutation>;
export type DeleteSegmentMutationOptions = Apollo.BaseMutationOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>;