import React from 'react';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import {
	AbstractCheckboxFieldCheckedState,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import CheckboxGroupContainer from '~/components/logic/checkboxGroup/CheckboxGroupContainer';
import CheckboxInGroup from '~/components/logic/checkboxGroup/CheckboxInGroup';
import DatatableBodyCell, {
	DatatableBodyCellAlignment,
	DatatableBodyCellPadding,
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableHeaderCell, {
	DatatableHeaderCellAlignment,
	DatatableHeaderCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';
import DatatableLayout from '~/components/patterns/tables/datatables/DatatableLayout';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import FixedHeaderGrid from '~/components/patterns/tables/datatables/FixedHeaderGrid';
import MasterCheckbox from '~/components/logic/checkboxGroup/MasterCheckbox';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';

import useFormContext from '~/hooks/useFormContext';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const HEADER_HEIGHT = 66;
const ROW_HEIGHT = 40;
const TABLE_HEIGHT = 330;
const CHECKBOX_CELL_WIDTH = 100;
const LABEL_CELL_WIDTH = 150;



type Props = {
	columns: ReadonlyArray<{
		label: React.ReactNode,
		name: string,
	}>,
	label: React.ReactNode,
	name: string,
	rows: ReadonlyArray<{
		disabled?: boolean,
		label: React.ReactNode,
		name: string,
	}>,
	/** Possibility to change default table height */
	tableHeight?: number,
	tableWidth: number,
};

const MultiselectListTable: React.FC<Props> = (props) => {
	const {
		columns,
		label,
		name,
		rows,
		tableHeight = TABLE_HEIGHT,
		tableWidth,
	} = props;

	const formContext = useFormContext();

	const formContextOnMountHandler = formContext.onMountHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const [highlightedRow, setHighlightedRow] = React.useState<number | null>(null);

	const gridRef = React.useRef<any>(null);

	React.useEffect(
		() => {
			formContextOnMountHandler(name, {
				setValues: true,
			});

			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnMountHandler,
			formContextOnUnmountHandler,
			name,
		],
	);

	const handleTableMouseEnter = React.useCallback(
		(rowIndex) => {
			setHighlightedRow(rowIndex);

			gridRef.current?.refs.BodyGrid?.forceUpdate();
		},
		[],
	);

	const handleTableMouseLeave = React.useCallback(
		(rowIndex) => {
			if (highlightedRow === rowIndex) {
				setHighlightedRow(null);

				gridRef.current?.refs.BodyGrid?.forceUpdate();
			}
		},
		[
			highlightedRow,
		],
	);

	const getColumnWidth = React.useCallback(
		({ index }) => {
			if (index > 0) {
				return CHECKBOX_CELL_WIDTH;
			}

			const calculatedMinWidth = columns.length * CHECKBOX_CELL_WIDTH + LABEL_CELL_WIDTH;

			if (calculatedMinWidth > tableWidth) {
				return LABEL_CELL_WIDTH;
			}

			const calculatedHeight = ROW_HEIGHT * rows.length;
			let widthOffset = -2; // -2 is because of table border around this component

			if (tableHeight < calculatedHeight) {
				widthOffset -= scrollbarSize();
			}

			return tableWidth - (columns.length * CHECKBOX_CELL_WIDTH) + widthOffset;
		},
		[
			columns,
			rows,
			tableHeight,
			tableWidth,
		],
	);

	const calculateTableHeight = React.useCallback(
		() => {
			const calculated = ROW_HEIGHT * rows.length;

			if (tableHeight > calculated) {
				return calculated + HEADER_HEIGHT;
			}

			return tableHeight + HEADER_HEIGHT;
		},
		[
			rows,
			tableHeight,
		],
	);

	const renderCell = React.useCallback(
		({ columnIndex, key, rowIndex, style }) => {
			const row = getArrayItemAtSafeIndex(rows, rowIndex);

			let cellContent: React.ReactNode;

			if (columnIndex === 0) {
				cellContent = (
					<Ellipsis>
						{row.label}
					</Ellipsis>
				);
			} else if (columnIndex <= columns.length) {
				const column = getArrayItemAtSafeIndex(columns, columnIndex - 1);

				cellContent = (
					<CheckboxInGroup
						centerAligned={true}
						name={column.name + '__' + row.name}
					/>
				);
			}

			return (
				<DatatableBodyCell
					alignment={columnIndex !== 0 ? DatatableBodyCellAlignment.Center : DatatableBodyCellAlignment.Left}
					cssStyle={style}
					isInHighlightedRow={highlightedRow === rowIndex}
					key={key}
					onMouseEnterCallback={handleTableMouseEnter.bind(null, rowIndex)}
					onMouseLeaveCallback={handleTableMouseLeave.bind(null, rowIndex)}
					padding={columnIndex !== 0 ? DatatableBodyCellPadding.CheckboxPadding : DatatableBodyCellPadding.Default}
					rowIndex={rowIndex}
					separator={columnIndex !== 0 && columnIndex <= columns.length}
					size={DatatableBodyCellSize.Small}
				>
					{cellContent}
				</DatatableBodyCell>
			);
		},
		[
			columns,
			handleTableMouseEnter,
			handleTableMouseLeave,
			rows,
			highlightedRow,
		],
	);

	const renderHeader = React.useCallback(
		({ columnIndex }) => {
			let cellContent;
			let checkbox;

			if (columnIndex === 0) {
				cellContent = label;
			} else {
				const column = getArrayItemAtSafeIndex(columns, columnIndex - 1);

				cellContent = column.label;
				checkbox = (
					<MasterCheckbox
						items={rows.map((row) => column.name + '__' + row.name)}
						name={column.name}
					/>
				);
			}

			const isCenterAligned = columnIndex !== 0 && columnIndex <= columns.length;

			return (
				<DatatableHeaderCell
					alignment={isCenterAligned ? DatatableHeaderCellAlignment.Center : DatatableHeaderCellAlignment.Left}
					key={'header_' + columnIndex}
					separator={isCenterAligned}
					size={DatatableHeaderCellSize.Small}
					width={getColumnWidth({ index: columnIndex })}
				>
					<TableLabel
						centerAligned={isCenterAligned}
						footer={checkbox}
						label={cellContent}
					/>
				</DatatableHeaderCell>
			);
		},
		[
			columns,
			getColumnWidth,
			label,
			rows,
		],
	);

	const calculatedTableHeight = calculateTableHeight();
	const columnCount = 1 + columns.length;

	// -2 is because of table border around this component
	const tableWidthOffset = -2;

	const allValue: Array<string> = [];
	const indeterminate: Array<string> = [];

	for (const column in formContext.values[name]) {
		if (formContext.values[name].hasOwnProperty(column)) {
			for (const row in formContext.values[name][column]) {
				if (formContext.values[name][column].hasOwnProperty(row)) {
					if (formContext.values[name][column][row] === AbstractCheckboxFieldCheckedState.Indeterminate) {
						indeterminate.push(column + '__' + row);
					} else if (formContext.values[name][column][row]) {
						allValue.push(column + '__' + row);
					}
				}
			}
		}
	}

	const allOptions: Array<Array<string>> = [];
	const disabledOptions: Array<string> = [];

	rows.forEach((row, rowIndex) => {
		const rowOptions: Array<string> = [];

		columns.forEach((column, columnIndex) => {
			rowOptions[columnIndex] = column.name + '__' + row.name;

			if (row.disabled) {
				disabledOptions.push(column.name + '__' + row.name);
			}
		});

		allOptions[rowIndex] = rowOptions;
	});

	return (
		<DatatableLayout maxWidth={tableWidth}>
			<CheckboxGroupContainer
				disabled={disabledOptions}
				indeterminate={indeterminate}
				name={name}
				onChangeCallback={({ checked, unchecked }) => {
					const result = {
						disabled: {},
					};

					columns.forEach((column) => {
						result[column.name] = {};

						rows.forEach((row) => {
							const fieldName = column.name + '__' + row.name;

							if (checked.includes(fieldName)) {
								result[column.name][row.name] = true;
							} else if (unchecked.includes(fieldName)) {
								result[column.name][row.name] = false;
							} else {
								result[column.name][row.name] = AbstractCheckboxFieldCheckedState.Indeterminate;
							}

							result.disabled[row.name] = row.disabled;
						});
					});

					formContext.onChangeHandler(name, result);
				}}
				optionGroups={allOptions}
				value={allValue}
			>
				<FixedHeaderGrid
					bodyCellRenderer={renderCell}
					columnCount={columnCount}
					columnWidth={getColumnWidth}
					data={{
						data: formContext.values[name],
					}}
					headerCellRenderer={renderHeader}
					headerHeight={HEADER_HEIGHT}
					height={calculatedTableHeight}
					key={tableWidth}
					overscanColumnCount={columnCount}
					ref={gridRef}
					rowCount={rows.length}
					rowHeight={ROW_HEIGHT}
					width={tableWidth + tableWidthOffset}
				/>
			</CheckboxGroupContainer>
		</DatatableLayout>
	);
};



export default MultiselectListTable;

export {
	AbstractCheckboxFieldCheckedState as MultiselectFieldTableCheckedState,
};
