import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import DateLabel from '~/components/app/DateLabel';
import DefinitionTerm, {
	DefinitionTermStyle,
} from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import InternalLink from '~/components/patterns/links/InternalLink';
import StatusMessage from '~/components/patterns/messages/embedded/StatusMessage';

import usePageNumberOfIncomingInternalLinks from '~/hooks/usePageNumberOfIncomingInternalLinks';
import usePageStatusCode from '~/hooks/usePageStatusCode';
import usePageTypeLastChangedAt from '~/hooks/usePageTypeLastChangedAt';



const messages = defineMessages({
	incomingRelationsDescription: {
		id: 'ui.pageDetail.statusMessage.serverError.incomingRelationsDescription',
	},
	incomingRelationsLink: {
		id: 'ui.pageDetail.statusMessage.general.incomingRelationsLink',
	},
	incomingRelationsTitle: {
		id: 'ui.pageDetail.statusMessage.general.warning',
	},
	since: {
		id: 'ui.pageDetail.statusMessage.serverError.since',
	},
	title: {
		id: 'ui.pageDetail.statusMessage.serverError.title',
	},
});



type Props = {
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const ServerError: React.FC<Props> = (props) => {
	const {
		legacyUrlId,
		websiteId,
	} = props;

	const pageNumberOfIncomingInternalLinks = usePageNumberOfIncomingInternalLinks(legacyUrlId, websiteId);
	const pageStatusCode = usePageStatusCode(legacyUrlId, websiteId);
	const pageTypeLastChangedAt = usePageTypeLastChangedAt(legacyUrlId, websiteId);

	const numberOfIncomingLinks = pageNumberOfIncomingInternalLinks ?? 0;

	const definitionTerms: Array<React.ReactNode> = [];

	if (pageTypeLastChangedAt !== null) {
		definitionTerms.push(
			<DefinitionTerm
				description={(
					<DateLabel date={pageTypeLastChangedAt} />
				)}
				key="term-1"
				term={(
					<FormattedMessage {...messages.since} />
				)}
			/>,
		);
	}

	if (numberOfIncomingLinks > 0) {
		definitionTerms.push(
			<DefinitionTerm
				description={[
					<FormattedMessage
						key="description"
						{...messages.incomingRelationsDescription}
					/>,
					<InternalLink
						breakLines={true}
						key="link"
						routeName="website.pages.detail.links"
						routeParams={{
							websiteId,
							id: legacyUrlId,
							pageDetailLinksType: 'incoming',
						}}
					>
						<FormattedMessage {...messages.incomingRelationsLink} />
					</InternalLink>,
				]}
				key="term-2"
				showRowNumbers={false}
				style={DefinitionTermStyle.Critical}
				term={(
					<FormattedMessage {...messages.incomingRelationsTitle} />
				)}
			/>,
		);
	}

	return (
		<StatusMessage
			message={(
				<FormattedMessage
					{...messages.title}
					values={{
						text__statusCode: pageStatusCode,
					}}
				/>
			)}
		>
			{definitionTerms.length > 0 && (
				<DefinitionTermsList>
					{definitionTerms}
				</DefinitionTermsList>
			)}
		</StatusMessage>
	);
};



export default ServerError;
