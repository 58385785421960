import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const HourglassIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<path
				d="M20.5,22H19V18.5A7.01,7.01,0,0,0,14.6,12,7.011,7.011,0,0,0,19,5.5V2h1.5a1,1,0,0,0,0-2H3.5a1,1,0,0,0,0,2H5V5.5A7.011,7.011,0,0,0,9.4,12,7.01,7.01,0,0,0,5,18.5V22H3.5a1,1,0,0,0,0,2h17a1,1,0,0,0,0-2ZM8.19,6.92a1,1,0,0,1,.89-.544h5.84a1,1,0,0,1,.811,1.584,4.591,4.591,0,0,1-7.462,0A1,1,0,0,1,8.19,6.92ZM7.92,18.63l3.044-2.936a1.544,1.544,0,0,1,2.07,0l0,0,3.043,2.935a1.349,1.349,0,0,1,.269,1.6,1.461,1.461,0,0,1-1.3.771H8.957a1.46,1.46,0,0,1-1.3-.764A1.352,1.352,0,0,1,7.92,18.63Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default HourglassIconBuilder;
