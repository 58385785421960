import React, {
	Component,
} from 'react';

import FieldDropdownDivider from '~/components/patterns/forms/fieldParts/dropdowns/parts/FieldDropdownDivider.part';
import FieldDropdownOption from '~/components/patterns/forms/fieldParts/dropdowns/parts/FieldDropdownOption.part';
import FieldDropdownOptionsGroup from '~/components/patterns/forms/fieldParts/dropdowns/parts/FieldDropdownOptionsGroup.part';
import PureRadioFields, {
	SIZE_SMALL,
} from '~/components/atoms/forms/components/builders/PureRadioFields';



type FieldDropdownSelectableOptionsOptionProps = {
	description?: React.ReactNode,
	disabled?: boolean,
	divider?: never,
	label: React.ReactNode,
	name: string,
	selected?: boolean,
	/** Special items are shown in italic */
	special?: boolean,
	suffix?: React.ReactNode,
};

type FieldDropdownSelectableOptionsDividerProps = {
	divider: boolean,
};

export function isActualOption(option: FieldDropdownSelectableOptionsProps): option is FieldDropdownSelectableOptionsOptionProps {
	return isDividerOption(option) === false;
}

export function isDividerOption(option: FieldDropdownSelectableOptionsProps): option is FieldDropdownSelectableOptionsDividerProps {
	return 'divider' in option;
}

export type FieldDropdownSelectableOptionsProps = FieldDropdownSelectableOptionsOptionProps | FieldDropdownSelectableOptionsDividerProps;

type Props = {
	/** Options data */
	options: Array<FieldDropdownSelectableOptionsProps>,
	/** Callback triggered on option change */
	onOptionChangeCallback?: (name: string) => void,
	/** Callback triggered on component mouse enter */
	onOptionMouseEnterCallback?: (name: string) => void,
	/** Callback triggered on component mouse leave */
	onOptionMouseLeaveCallback?: (name: string) => void,
	/** Callback triggered when clicking on Show more link */
	onShowMoreClickCallback?: () => void,
	/** Label for "Show more" link visible when visibleOptionsCount set */
	showMoreLinkLabel?: React.ReactNode,
	/** We can limit amount of visible options */
	visibleOptionsCount?: number,
	/** Custom width of component */
	width?: React.CSSProperties['width'],
};

type State = {
	showAllOptions: boolean,
};



class FieldDropdownSelectableOptions extends Component<Props, State> {

	private ref = React.createRef<HTMLDivElement>();

	constructor(props, context) {
		super(props, context);

		this._handleShowAllOptions = this._handleShowAllOptions.bind(this);

		this.state = {
			showAllOptions: this._isSelectedOptionHidden(props.options) || false,
		};
	}



	_isSelectedOptionHidden(options) {
		const {
			visibleOptionsCount,
		} = this.props;

		const selectedStates = options.map((option) => {
			return !!option.selected;
		});

		if (visibleOptionsCount && selectedStates.indexOf(true) >= visibleOptionsCount) {
			return true;
		}

		return false;
	}



	UNSAFE_componentWillReceiveProps({ options: nextOptions }) {
		const {
			options: prevOptions,
		} = this.props;

		if (nextOptions !== prevOptions) {
			if (this._isSelectedOptionHidden(nextOptions)) {
				this.setState({
					showAllOptions: true,
				});
			}

			const prevSelectedOption = prevOptions.filter((option) => 'selected' in option && option.selected)[0] as (FieldDropdownSelectableOptionsOptionProps | undefined);
			const nextSelectedOption = nextOptions.filter((option) => 'selected' in option && option.selected)[0] as (FieldDropdownSelectableOptionsOptionProps | undefined);

			if (nextSelectedOption && prevSelectedOption?.name !== nextSelectedOption.name) {
				this.ref.current?.querySelector(`[name="${nextSelectedOption.name}"]`)?.scrollIntoView({
					block: 'center',
				});
			}
		}
	}



	_handleShowAllOptions() {
		const {
			onShowMoreClickCallback,
		} = this.props;

		if (onShowMoreClickCallback) {
			onShowMoreClickCallback();
		}

		setTimeout(() => {
			this.setState({
				showAllOptions: true,
			});
		});
	}



	_handleOptionChange(name) {
		const {
			onOptionChangeCallback,
		} = this.props;

		if (onOptionChangeCallback) {
			onOptionChangeCallback(name);
		}
	}



	_handleOptionMouseEnter(name) {
		const {
			onOptionMouseEnterCallback,
		} = this.props;

		if (onOptionMouseEnterCallback) {
			onOptionMouseEnterCallback(name);
		}
	}



	_handleOptionMouseLeave(name) {
		const {
			onOptionMouseLeaveCallback,
		} = this.props;

		if (onOptionMouseLeaveCallback) {
			onOptionMouseLeaveCallback(name);
		}
	}



	render() {
		const {
			options,
			showMoreLinkLabel,
			visibleOptionsCount,
			width,
		} = this.props;

		const {
			showAllOptions,
		} = this.state;

		let items = options;
		const isOutputLimited = visibleOptionsCount && items.length > visibleOptionsCount && !showAllOptions;

		if (isOutputLimited) {
			items = items.slice(0, visibleOptionsCount);
		}

		return (
			<FieldDropdownOptionsGroup
				ref={this.ref}
				showMoreLink={isOutputLimited ? {
					label: showMoreLinkLabel,
					onClickCallback: this._handleShowAllOptions,
					onMouseEnter: this._handleOptionMouseEnter.bind(this, 'show-more'),
					onMouseLeave: this._handleOptionMouseLeave.bind(this, 'show-more'),
				} : undefined}
				width={width}
			>
				{items.map((item, index) => {
					if ('divider' in item) {
						return (
							<FieldDropdownDivider key={'filter-divider-' + index} />
						);
					}

					return (
						<FieldDropdownOption
							isHighlighted={item.selected}
							isSpecial={item.special}
							key={'filter-option-' + item.name}
						>
							<PureRadioFields
								isControlled={true}
								items={[
									{
										checked: item.selected,
										description: item.description,
										disabled: item.disabled,
										label: item.label,
										labelSuffix: item.suffix,
										value: item.name,
									},
								]}
								name={item.name}
								onChangeCallback={this._handleOptionChange.bind(this, item.name)}
								onMouseEnterCallback={this._handleOptionMouseEnter.bind(this, item.name)}
								onMouseLeaveCallback={this._handleOptionMouseLeave.bind(this, item.name)}
								onMouseOverCallback={this._handleOptionMouseEnter.bind(this, item.name)}
								size={SIZE_SMALL}
							/>
						</FieldDropdownOption>
					);
				})}
			</FieldDropdownOptionsGroup>
		);
	}

}



export default FieldDropdownSelectableOptions;
