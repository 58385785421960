import classNames from 'classnames';
import React from 'react';
import {
	Link,
} from 'react-router5';

import LineBreaks from '~/components/patterns/values/LineBreaks';
import {
	LinkContext,
} from '~/components/patterns/links/LinkContextProvider';

import styles from './Link.module.scss';



export enum InternalLinkStyle {
	Auth = 'auth',
	Decent = 'decent',
	Default = 'default',
	Light = 'light',
}



type Props = {
	breakLines?: boolean,
	children?: React.ReactNode,
	disabled?: boolean,
	ellipsis?: boolean,
	icon?: React.ReactNode,
	onClickCallback?: (e: React.MouseEvent) => void,
	routeName?: string,
	routeParams?: {},
	style?: InternalLinkStyle,
	tabIndex?: number,
};

const InternalLink: React.FC<Props> = (props) => {
	const {
		breakLines = false,
		disabled = false,
		ellipsis = false,
		children,
		icon,
		onClickCallback,
		routeName,
		routeParams,
		style = InternalLinkStyle.Default,
		tabIndex,
	} = props;

	const context = React.useContext(LinkContext);

	const clickHandler = (e) => {
		if (disabled || onClickCallback) {
			e.preventDefault();
		}

		if (disabled) {
			return false;
		}

		if (routeName) {
			e.stopPropagation();
		}

		if (onClickCallback) {
			onClickCallback(e);
		}
	};

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isInternal]: true,
		[styles.isDisabled]: disabled,
		[styles.isHoverable]: !disabled,
		[styles.isWithEllipsis]: ellipsis,
		[styles['style-' + (context?.internalLinkStyle || style)] ?? '']: true,
	});

	const additionalAttributes: React.HTMLProps<HTMLAnchorElement> = {};

	if (disabled) {
		additionalAttributes.disabled = true;
	}

	if (tabIndex) {
		additionalAttributes.tabIndex = tabIndex;
	}

	let iconElement;

	if (icon) {
		iconElement = (
			<span className={styles.icon}>
				{icon}
			</span>
		);
	}

	const linkContent = (
		<span className={styles.text}>
			{children}
		</span>
	);

	let link;

	if (!disabled && routeName) {
		link = (
			<Link
				className={componentClasses}
				onClick={clickHandler}
				routeName={routeName}
				routeParams={routeParams}
				{...additionalAttributes}
			>
				{iconElement}
				{linkContent}
			</Link>
		);
	} else {
		link = (
			<a
				className={componentClasses}
				href="#"
				onClick={clickHandler}
				{...additionalAttributes}
			>
				{iconElement}
				{linkContent}
			</a>
		);
	}

	if (ellipsis) {
		return link;
	} else if (breakLines) {
		return (
			<LineBreaks>
				{link}
			</LineBreaks>
		);
	}

	return link;
};



export default InternalLink;
