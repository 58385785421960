import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BackButton from '~/components/app/BackButton';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
	ButtonsLayoutUsageContext,
} from '~/components/patterns/buttons/ButtonsLayout';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import GlobalFormMessage from '~/components/atoms/forms/basis/GlobalFormMessage';
import MessagingAppChannelFieldRow from '~/components/app/MessagingAppChannelFieldRow';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import RecipientsMultiselectField from '~/components/app/RecipientsMultiselectField';
import RichText from '~/components/patterns/typography/RichText';
import SegmentSelectField from '~/components/atoms/forms/components/SegmentSelectField';
import SensitivityField from '../../fields/SensitivityField';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useBulkCreateAlertDefinitionsMutation,
} from './SetupSettingsStep.gql';

import useCanManageUserAsAlertRecipient from '~/hooks/useCanManageUserAsAlertRecipient';
import useSetFlashMessage from '~/hooks/useSetFlashMessage';
import useUglyMicrosoftTeamsMessagingAppChannelOverlay from '~/hooks/useUglyMicrosoftTeamsMessagingAppChannelOverlay';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	ALERT_GROUP_CUSTOM_ELEMENT_ALERTS,
	ALERT_GROUP_ENRICHMENT_FIELD_ALERTS,
	ALERT_GROUP_ENTERED_OR_LEFT_ALERTS,
	ALERT_GROUP_LIGHTHOUSE_ALERTS,
	ALERT_GROUP_PAGE_ALERTS,
	ALERT_GROUP_PLATFORM_ALERTS,
} from '~/model/alerts';



const messages = defineMessages({
	breadcrumb: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.breadcrumb',
	},
	continue: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.continue',
	},
	description: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.description',
	},
	fieldRecipients: {
		id: 'ui.alertsConfiguration.fields.recipients',
	},
	fieldScope: {
		id: 'ui.alertsConfiguration.fields.scope',
	},
	fieldSensitivity: {
		id: 'ui.alertsConfiguration.fields.sensitivity',
	},
	invalidInput: {
		id: 'ui.formErrors.invalidInput',
	},
	sidebar: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.sidebar',
	},
});

const messagesTitle = defineMessages({
	[ALERT_GROUP_CUSTOM_ELEMENT_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.title.custom_element_alerts',
	},
	[ALERT_GROUP_ENRICHMENT_FIELD_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.title.enrichmentFieldAlerts',
	},
	[ALERT_GROUP_ENTERED_OR_LEFT_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.title.entered_or_left_alerts',
	},
	[ALERT_GROUP_LIGHTHOUSE_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.title.lighthouse_alerts',
	},
	[ALERT_GROUP_PAGE_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.title.page_alerts',
	},
	[ALERT_GROUP_PLATFORM_ALERTS]: {
		id: 'ui.alertsConfiguration.modal.create.setupSettings.title.platform_alerts',
	},
});



type AlertGroup =
	| typeof ALERT_GROUP_CUSTOM_ELEMENT_ALERTS
	| typeof ALERT_GROUP_ENRICHMENT_FIELD_ALERTS
	| typeof ALERT_GROUP_ENTERED_OR_LEFT_ALERTS
	| typeof ALERT_GROUP_LIGHTHOUSE_ALERTS
	| typeof ALERT_GROUP_PAGE_ALERTS
	| typeof ALERT_GROUP_PLATFORM_ALERTS;



type Props = {
	alertGroup: AlertGroup | null,
	onSubmit: () => void,
	preparedAlertDefinitions: Array<Record<string, any>>,
};

const SetupSettingsStep: React.FC<Props> = (props) => {
	const {
		alertGroup,
		onSubmit,
		preparedAlertDefinitions,
	} = props;

	const websiteId = useWebsiteId();

	const canManageUserAsAlertRecipient = useCanManageUserAsAlertRecipient(websiteId);
	const segments = useWebsiteSegmentDefinitions(websiteId);
	const setFlashMessage = useSetFlashMessage();

	const [bulkCreateAlertDefinitions] = useBulkCreateAlertDefinitionsMutation();

	const {
		addMicrosoftTeamsMessagingAppChannelOverlay,
		initiateAddMicrosoftTeamsMessagingAppChannel,
		messagingAppChannelFieldRowRef,
	} = useUglyMicrosoftTeamsMessagingAppChannelOverlay();

	const hasSensitivity =
		alertGroup === ALERT_GROUP_LIGHTHOUSE_ALERTS
		|| alertGroup === ALERT_GROUP_PAGE_ALERTS
		|| alertGroup === ALERT_GROUP_CUSTOM_ELEMENT_ALERTS
		|| alertGroup === ALERT_GROUP_ENRICHMENT_FIELD_ALERTS;

	const hasScope =
		alertGroup === ALERT_GROUP_LIGHTHOUSE_ALERTS
		|| alertGroup === ALERT_GROUP_PAGE_ALERTS
		|| alertGroup === ALERT_GROUP_CUSTOM_ELEMENT_ALERTS
		|| alertGroup === ALERT_GROUP_ENRICHMENT_FIELD_ALERTS;

	const handleSubmit = React.useCallback(
		async (values) => {
			const messagingAppChannelIds = values.messagingAppChannelId === 'none'
				? []
				: [values.messagingAppChannelId];

			const recipients = values.recipients.filter(canManageUserAsAlertRecipient);

			const alertDefinitions = preparedAlertDefinitions.map((preparedAlertDefinition) => {
				const result: Record<string, any> = {
					settings: {},
					...preparedAlertDefinition,
					messagingAppChannelIds,
					recipients,
				};

				if (hasScope) {
					result.settings.scope = values.scope === 'website'
						? values.scope
						: `segment:${values.scope}`;
				}

				if (hasSensitivity) {
					result.settings.sensitivityLevel = values.sensitivity;
				}

				return result;
			});

			await bulkCreateAlertDefinitions({
				variables: {
					alertDefinitions,
					websiteId,
				},
			});

			onSubmit();

			setFlashMessage({
				message: 'alertDefinitionsCreated',
				messageVariables: {
					number__alertDefinitions: preparedAlertDefinitions.length,
				},
				route: 'website.alerts.configuration',
				style: 'success',
			});
		},
		[
			bulkCreateAlertDefinitions,
			canManageUserAsAlertRecipient,
			hasScope,
			hasSensitivity,
			onSubmit,
			preparedAlertDefinitions,
			setFlashMessage,
			websiteId,
		],
	);

	return (
		<MultiStepModalStep
			breadcrumb={(
				<FormattedMessage {...messages.breadcrumb} />
			)}
			headerIconType={MultiStepModalStepHeaderIconType.Plus}
			name="setupNativeAlerts"
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							scope: hasScope ? 'visible' : 'hidden',
							sensitivity: hasSensitivity ? 'visible' : 'hidden',
							link_article: (chunks) => (
								<ExternalLink
									href="https://www.contentkingapp.com/support/configuring-alerts/#what-can-be-configured"
									key="link"
								>
									{chunks}
								</ExternalLink>
							),
						}}
					/>
				</RichText>
			)}
			title={(
				alertGroup !== null && (
					<FormattedMessage
						{...messagesTitle[alertGroup]}
						values={{
							count__definitions: preparedAlertDefinitions.length,
						}}
					/>
				)
			)}
		>
			<DisabledContent
				disabledContent={!!addMicrosoftTeamsMessagingAppChannelOverlay}
				disabledOverlay={addMicrosoftTeamsMessagingAppChannelOverlay}
			>
				<Form
					defaultDataHasChanged={true}
					defaultValues={{
						messagingAppChannelId: 'none',
						recipients: [],
						sensitivity: hasSensitivity ? 'medium' : null,
						scope: hasScope ? 'website' : null,
					}}
					key={`setup-settings-${alertGroup}`}
					onSuccess={handleSubmit}
				>
					{({ globalError, isSubmitting }) => (
						<>
							<ModalTextSection>
								<FormattedMessage {...messages.description} />
							</ModalTextSection>

							<CenteredFormWrapper>
								<FormRows>
									{hasScope && (
										<FormRow
											htmlFor="scope"
											label={(
												<FormattedMessage {...messages.fieldScope} />
											)}
										>
											<SegmentSelectField
												inAlertDefinitions={true}
												name="scope"
												segments={segments.listAll()}
												websiteScopeName="website"
											/>
										</FormRow>
									)}

									{hasSensitivity && (
										<FormRow
											htmlFor="sensitivity"
											label={(
												<FormattedMessage {...messages.fieldSensitivity} />
											)}
										>
											<SensitivityField
												name="sensitivity"
												websiteId={websiteId}
											/>
										</FormRow>
									)}

									<FormRow
										htmlFor="recipients"
										label={(
											<FormattedMessage {...messages.fieldRecipients} />
										)}
									>
										<RecipientsMultiselectField
											name="recipients"
											websiteId={websiteId}
										/>
									</FormRow>

									<MessagingAppChannelFieldRow
										name="messagingAppChannelId"
										onInitiateAddMicrosoftChannel={initiateAddMicrosoftTeamsMessagingAppChannel}
										ref={messagingAppChannelFieldRowRef}
									/>
								</FormRows>

								{globalError && !isSubmitting && (
									<GlobalFormMessage name={globalError.name}>
										<FormattedMessage {...messages.invalidInput} />
									</GlobalFormMessage>
								)}

								<ButtonsLayout
									alignment={ButtonsLayoutAlignment.Right}
									layout={ButtonsLayoutType.Steps}
									usageContext={ButtonsLayoutUsageContext.InModals}
								>
									<BackButton />

									<SubmitButton>
										<FormattedMessage
											{...messages.continue}
											values={{
												count__definitions: preparedAlertDefinitions.length,
											}}
										/>
									</SubmitButton>
								</ButtonsLayout>
							</CenteredFormWrapper>
						</>
					)}
				</Form>
			</DisabledContent>
		</MultiStepModalStep>
	);
};



export default SetupSettingsStep;
