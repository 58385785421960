import {
	type NavigationOptions,
	type Params,
} from 'router5/dist/types/base';

import {
	getRouter,
} from './router';



export default (
	event: MouseEvent,
	routeName: string,
	routeParams?: Params,
	routerOptions?: NavigationOptions,
	afterCallback?: () => void,
): void => {
	event.preventDefault();
	event.stopPropagation();

	const router = getRouter();

	if (event.ctrlKey || event.metaKey) {
		const openedTab = window.open(
			router.buildPath(routeName, routeParams),
			'_blank',
		);

		if (openedTab !== null && event.shiftKey) {
			openedTab.focus();
		}
	} else if (event.shiftKey) {
		window.open(
			router.buildPath(routeName, routeParams),
		);
	} else {
		router.navigate(routeName, routeParams ?? {}, routerOptions ?? {});
	}

	if (afterCallback && !(event.ctrlKey || event.metaKey || event.shiftKey)) {
		afterCallback();
	}
};
