import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import {
	ASSETS_STORAGE_VERSIONLESS,
} from '~/config';

import Button, {
	ButtonStyle,
	ButtonType,
} from '~/components/patterns/buttons/Button';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import Form from '../basis/Form';
import SubmitButton from '~/components/app/SubmitButton';

import useEnabledWebsiteColumnsList from '~/hooks/useEnabledWebsiteColumnsList';
import useExportAffectedLinks from '~/hooks/useExportAffectedLinks';
import useExportTrackedChangesOnPages from '~/hooks/useExportTrackedChangesOnPages';

import {
	exportCurrentAffectedPages,
	exportCurrentAlertPages,
	exportCurrentPages,
	exportCurrentSingleIssueAffectedPages,
	exportCurrentSitemapsList,
	exportHistoricalSingleIssueAffectedPages,
	exportPageRelations,
} from '~/actions/exports';

import {
	ExportType,
} from '~/model/exports';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	notSubmittedDownloadButton: {
		id: 'ui.exports.type.general.button.download.label',
	},
	notSubmittedContent: {
		id: 'ui.exports.type.general.content',
	},
	notSubmittedTitle: {
		id: 'ui.exports.type.general.title',
	},
	submitButton: {
		id: 'ui.exports.type.general.button.submit.label',
	},
	submittedContent: {
		id: 'ui.exports.type.general.content.inProgress',
	},
	submittedContentLarge: {
		id: 'ui.exports.type.general.content.inProgress.largeExport',
	},
	submittedDownloadButton: {
		id: 'ui.exports.type.general.button.submit.label.inProgress',
	},
	submittedTitle: {
		id: 'ui.exports.type.general.title.inProgress',
	},
});



type Props = {
	currentLocale: string,
	onCancelCallback: () => void,
	totalFilteredPages: number | undefined,
	type: ExportType,
};



const FileExportForm: React.FC<Props> = (props) => {
	const {
		currentLocale,
		onCancelCallback,
		totalFilteredPages,
		type,
	} = props;

	const enabledWebsiteColumnsList = useEnabledWebsiteColumnsList();
	const exportAffectedLinks = useExportAffectedLinks();
	const exportTrackedChangesOnPages = useExportTrackedChangesOnPages();

	const [exportResult, setExportResult] = React.useState<{
		data: null,
		isLarge: false,
	} | {
		data: Record<string, any>,
		isLarge: boolean,
	}>({
		data: null,
		isLarge: false,
	});

	const dispatch = useDispatch();

	const handleSubmitForm = React.useCallback(
		async () => {
			const action = matchAndReturn(type, {
				[ExportType.AffectedLinks]: exportAffectedLinks,
				[ExportType.AffectedPages]: exportCurrentAffectedPages,
				[ExportType.AlertPages]: exportCurrentAlertPages,
				[ExportType.ChangeTracking]: exportTrackedChangesOnPages,
				[ExportType.ContentOverview]: () => {
					return exportCurrentPages(enabledWebsiteColumnsList, totalFilteredPages);
				},
				[ExportType.HistoricalSingleIssueAffectedPages]: exportHistoricalSingleIssueAffectedPages,
				[ExportType.PageRelations]: exportPageRelations,
				[ExportType.SingleIssueAffectedPages]: exportCurrentSingleIssueAffectedPages,
				[ExportType.SitemapsList]: exportCurrentSitemapsList,
			});

			const { exportData, isLarge } = await dispatch(
				action(),
			);

			setExportResult({
				data: exportData,
				isLarge,
			});
		},
		[
			dispatch,
			enabledWebsiteColumnsList,
			exportAffectedLinks,
			exportTrackedChangesOnPages,
			setExportResult,
			totalFilteredPages,
			type,
		],
	);

	function renderSubmittedAction(exportResult: {
		data: Record<string, any>,
		isLarge: boolean,
	}) {
		let exportDownloadUrl = ASSETS_STORAGE_VERSIONLESS
			+ '/export.html'
			+ '?export=' + exportResult.data.filename
			+ '&lang=' + currentLocale
			+ '&download=' + exportResult.data.downloadUrl;

		if (exportResult.isLarge) {
			exportDownloadUrl += '&is_large=true';
		}

		return (
			<div>
				<ButtonsLayout layout={ButtonsLayoutType.CenterActionButton}>
					<span></span>
					<a
						href={exportDownloadUrl}
						target="_blank"
					>
						<FormattedMessage {...messages.notSubmittedDownloadButton} />
					</a>
					<span></span>
				</ButtonsLayout>
				<ButtonsLayout layout={ButtonsLayoutType.CenterActionButton}>
					<span></span>
					<span>
						<Button
							onClick={onCancelCallback}
							style={ButtonStyle.Action}
							type={ButtonType.Button}
						>
							<FormattedMessage {...messages.submittedDownloadButton} />
						</Button>
					</span>
					<span></span>
				</ButtonsLayout>
			</div>
		);
	}

	function renderSubmitAction() {
		return (
			<Form
				defaultDataHasChanged={true}
				onSuccess={handleSubmitForm}
			>
				<ButtonsLayout layout={ButtonsLayoutType.CenterActionButton}>
					<span>
						<CancelButton onClickCallback={onCancelCallback} />
					</span>
					<span>
						<SubmitButton>
							<FormattedMessage {...messages.submitButton} />
						</SubmitButton>
					</span>
					<span></span>
				</ButtonsLayout>
			</Form>
		);
	}

	const titleMessage = exportResult.data !== null
		? messages.submittedTitle
		: messages.notSubmittedTitle;

	const contentMessage = exportResult.data !== null
		? messages[exportResult.isLarge ? 'submittedContentLarge' : 'submittedContent']
		: messages.notSubmittedContent;

	return (
		<div className="export-panel">
			<h3 className="export-panel__title">
				<FormattedMessage {...titleMessage} />
			</h3>

			<div className="export-panel__content">
				<div className="export-panel__text typo">
					<p>
						<FormattedMessage {...contentMessage} />
					</p>
				</div>
			</div>

			<div className="export-panel__form">
				{exportResult.data !== null ? renderSubmittedAction(exportResult) : renderSubmitAction()}
			</div>
		</div>
	);
};



export default FileExportForm;
