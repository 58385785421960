import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';

import {
	REFERENCE_DEFAULT_LOCATION,
	REFERENCE_GOOGLE_SEARCH_CONSOLE,
	REFERENCE_MISSING,
	REFERENCE_ROBOTS_TXT,
	REFERENCE_SITEMAP_INDEX,
} from '~/model/sitemaps';



const messages = defineMessages({
	[REFERENCE_DEFAULT_LOCATION]: {
		id: 'ui.screen.sitemapsList.cells.reference.defaultLocation',
	},
	[REFERENCE_GOOGLE_SEARCH_CONSOLE]: {
		id: 'ui.screen.sitemapsList.cells.reference.googleSearchConsole',
	},
	[REFERENCE_MISSING]: {
		id: 'ui.screen.sitemapsList.cells.reference.missing',
	},
	[REFERENCE_ROBOTS_TXT]: {
		id: 'ui.screen.sitemapsList.cells.reference.robotsTxt',
	},
	[REFERENCE_SITEMAP_INDEX]: {
		id: 'ui.screen.sitemapsList.cells.reference.sitemapIndex',
	},
});



type Props = {
	sitemapReference: (
		| typeof REFERENCE_DEFAULT_LOCATION
		| typeof REFERENCE_GOOGLE_SEARCH_CONSOLE
		| typeof REFERENCE_MISSING
		| typeof REFERENCE_ROBOTS_TXT
		| typeof REFERENCE_SITEMAP_INDEX
	),
};

const SitemapReferenceName: React.FC<Props> = (props) => {
	const {
		sitemapReference,
	} = props;

	const message = messages[sitemapReference];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default SitemapReferenceName;
