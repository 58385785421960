import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SubmitButton from '~/components/app/SubmitButton';
import UrlBlocklistField from '~/components/app/UrlBlocklistField';
import WebsiteOnPageRequestBlockingModeField from '~/components/app/WebsiteOnPageRequestBlockingModeField';



const messages = defineMessages({
	allDeniedTitle: {
		id: 'ui.general.caution',
	},
	applyChanges: {
		id: 'ui.general.applyChanges',
	},
});



type Values = {
	mode: GraphQL.OnPageRequestsBlockingMode | GraphQL.UrlBlocklistMode,
	rules: ReadonlyArray<GraphQL.OnPageRequestsBlockingRule> | ReadonlyArray<GraphQL.UrlBlocklistRule>,
};

type Props = {
	/**
	 * GraphQL.OnPageRequestsBlockingRuleOperator & GraphQL.UrlBlocklistRuleOperator differ in casing,
	 * the former being in PascalCase and the latter in camelCase. Until this is aligned, we use this
	 * prop to change the case of the operators.
	 */
	__UGLY__transformEnumsToCamelCase?: boolean,
	allDeniedWarning: React.ReactNode,
	defaultValues?: Values,
	maxRules?: number,
	onSubmit: (values: Values) => Promise<void> | void,
};

const UrlBlocklistForm: React.FC<Props> = (props) => {
	const {
		__UGLY__transformEnumsToCamelCase,
		allDeniedWarning,
		defaultValues = null,
		maxRules = 8,
		onSubmit,
	} = props;

	const formRef = React.useRef<FormRef>(null);

	const formDefaultValues = {
		mode: defaultValues?.mode,
		rules: defaultValues?.rules ?? [],
	};

	const handleSubmit = React.useCallback(
		async (values: Record<string, any>) => {
			const mode = values.mode as Values['mode'];
			const rules = values.rules as Values['rules'];

			await onSubmit({
				mode,
				rules,
			});
		},
		[
			onSubmit,
		],
	);

	function isBlockingEverything(values) {
		if (values.mode === GraphQL.OnPageRequestsBlockingMode.DenyAllExcept) {
			const hasValidRules = values.rules.some((rule) => {
				const operator = rule.operator;
				const value = rule.value?.trim() || '';

				return operator !== undefined || value.length > 0;
			});

			return !hasValidRules;
		}

		return false;
	}

	return (
		<Form
			defaultValues={formDefaultValues}
			onSuccess={handleSubmit}
			ref={formRef}
		>
			{({ values }) => (
				<>
					<FormRows>
						<WebsiteOnPageRequestBlockingModeField
							__UGLY__transformEnumsToCamelCase={__UGLY__transformEnumsToCamelCase}
							name="mode"
						/>

						<UrlBlocklistField
							__UGLY__transformEnumsToCamelCase={__UGLY__transformEnumsToCamelCase}
							maximumNumberOfRules={maxRules}
							name="rules"
						/>

						{isBlockingEverything(values) && (
							<CalloutMessage
								borders={true}
								message={(
									<FormattedMessage {...messages.allDeniedTitle} />
								)}
								status={CalloutMessageStatus.Critical}
							>
								{allDeniedWarning}
							</CalloutMessage>
						)}

						<FormErrorMessages />
					</FormRows>

					<ButtonsLayout
						alignment={ButtonsLayoutAlignment.Right}
						layout={ButtonsLayoutType.Steps}
					>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.applyChanges} />
						</SubmitButton>
					</ButtonsLayout>
				</>
			)}
		</Form>
	);
};



export default UrlBlocklistForm;
