import {
	calculatePurchaseCostDetails,
	createPurchase,
} from './pricing/costs';

import {
	DETAIL_PLAN,
} from './pricing/universal';



export function calculateAccountPurchasesCostDetails({
	account,
	accountBilling,
	billingCycle,
	purchases,
	tariffs,
}) {
	return calculatePurchaseCostDetails({
		accountType: account.get('type'),
		billingCycle: billingCycle || account.get('billing_cycle'),
		currency: account.get('currency'),
		discounts: accountBilling.get('discounts') || [],
		purchases,
		tariff: account.get('tariff'),
		tariffs,
		taxRate: accountBilling.get('tax'),
	});
}



export function calculateCostDetails({
	account,
	accountBilling,
	billingCycle = null,
	pageBundle = null,
	plan = null,
	tariffs,
}) {
	const purchases = [
		createPurchase({
			details: {
				[DETAIL_PLAN]: plan || account.get('plan'),
			},
			numberOfPages: pageBundle || account.get('page_bundle'),
		}),
	];

	return calculateAccountPurchasesCostDetails({
		account,
		accountBilling,
		billingCycle,
		purchases,
		tariffs,
	});
}
