import React from 'react';

import {
	linear,
} from '~/utilities/easings';



type Options = {
	duration?: number,
	easing?: (t: number) => number,
};



function useAnimatedValue(
	initialValue: number,
	options: Options = {},
) {
	const [value, setValue] = React.useState(initialValue);
	const [targetValue, setTargetValue] = React.useState(initialValue || 0);
	const [duration, setDuration] = React.useState(0);

	const {
		duration: defaultDuration = 1000,
		easing = linear,
	} = options;

	const interpolateToValue = (nextValue: number, duration: number = defaultDuration) => {
		setTargetValue(nextValue);
		setDuration(duration);
	};

	React.useEffect(
		() => {
			let animRequest;
			const start = Date.now();
			const startValue = value;

			function tick() {
				const now = Date.now();
				const p = Math.min((now - start) / duration, 1) || 0;
				const t = easing(p);

				setValue((startValue + (targetValue - startValue) * t));

				if (p < 1) {
					animRequest = requestAnimationFrame(tick);
				} else {
					setValue(targetValue);
				}
			}

			tick();

			return () => {
				setValue(targetValue);
				cancelAnimationFrame(animRequest);
			};
		},
		[
			easing,
			targetValue,
			duration,
			value,
		],
	);

	return [value, interpolateToValue] as const;
}



export default useAnimatedValue;
