import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RobotDisallowedIconBuilder: IconBuilder = {
	icon: ({ color = '#ffffff' }) => (
		<g>
			<g>
				<path
					d="M5.34,7.2a3.91,3.91,0,0,0-3.9,3.9V14a1.77,1.77,0,0,0-.9,1.55,1.8,1.8,0,0,0,3.6,0A1.77,1.77,0,0,0,3.24,14V11.1a2.1,2.1,0,0,1,1.62-2Z"
					fill={color}
				/>
				<path
					d="M13.27,22.19h0V17.37H17v4.82h1.38a1.2,1.2,0,0,1,1.2,1.2h0a.6.6,0,0,1-.6.6H13.27a.6.6,0,0,1-.6-.6v-.61A.6.6,0,0,1,13.27,22.19Z"
					fill={color}
				/>
			</g>
			<path
				d="M1,24a1,1,0,0,1-.7-.29,1,1,0,0,1,0-1.41l22-22A1,1,0,0,1,23.7,1.71l-22,22A1,1,0,0,1,1,24Z"
				fill={color}
			/>
			<path
				d="M13.26,7.2h-6l-.2.52a11.44,11.44,0,0,0-.5,6.18Z"
				fill={color}
			/>
			<path
				d="M1,24a1,1,0,0,1-.7-.29,1,1,0,0,1,0-1.41l22-22A1,1,0,0,1,23.7,1.71l-22,22A1,1,0,0,1,1,24Z"
				fill={color}
			/>
			<path
				d="M16.26,4.2h0a4.2,4.2,0,0,0-8.4,0V6h6.6Z"
				fill={color}
			/>
			<path
				d="M11.33,16.2h5.53a11.38,11.38,0,0,0,.76-6.28Z"
				fill={color}
			/>
			<path
				d="M22.56,14V11.1a3.87,3.87,0,0,0-2.51-3.61l-1,1,.14.53a2.1,2.1,0,0,1,1.61,2V14a1.8,1.8,0,1,0,2.7,1.55A1.77,1.77,0,0,0,22.56,14Z"
				fill={color}
			/>
			<path
				d="M10.85,22.19h0V17.37h-.68l-3,3v1.83H5.79a1.2,1.2,0,0,0-.69.24l-.27.27a1.2,1.2,0,0,0-.24.69h0a.6.6,0,0,0,.6.6h5.66a.6.6,0,0,0,.6-.6v-.61A.6.6,0,0,0,10.85,22.19Z"
				fill={color}
			/>
		</g>
	),
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default RobotDisallowedIconBuilder;

