import classNames from 'classnames';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import styles from './DefinitionTerm.module.scss';



const messages = defineMessages({
	showMoreLink: {
		id: 'ui.definitionTerm.showMoreLink',
		defaultMessage: '+{count, number} more',
	},
});



export enum DefinitionTermStyle {
	Critical = 'critical',
	General = 'general',
	Warning = 'warning',
}

type Props = {
	description: React.ReactNode,
	showRowNumbers?: boolean,
	style?: DefinitionTermStyle,
	term: React.ReactNode,
	uppercaseTerm?: boolean,
	visibleRowsCount?: number,
};



const DefinitionTerm: React.FC<Props> = (props) => {
	const {
		description,
		showRowNumbers = true,
		style = DefinitionTermStyle.General,
		term,
		uppercaseTerm = true,
		visibleRowsCount,
	} = props;

	const [showAllRows, setShowAllRows] = React.useState<boolean>(false);

	const handleShowAllRows = React.useCallback(
		(e) => {
			e.preventDefault();

			setShowAllRows(true);
		},
		[
			setShowAllRows,
		],
	);

	let descriptionElements = description instanceof Array ? description.filter((child) => !!child) : [description];
	const descriptionElementsCount = descriptionElements.length;
	const hasHiddenRows = visibleRowsCount && !showAllRows && descriptionElementsCount > (visibleRowsCount + 1);

	if (hasHiddenRows) {
		descriptionElements = descriptionElements.slice(0, visibleRowsCount);
	}

	const componentClasses = classNames({
		[styles.component]: true,
		[styles[style + 'Style'] ?? '']: true,
	});

	const termClasses = classNames({
		[styles.term]: true,
		[styles.termUppercase]: uppercaseTerm,
	});

	return (
		<div className={componentClasses}>
			<div className={termClasses}>{term}</div>
			<div className={styles.description}>
				{descriptionElements.map((dataRow, index) => {
					return (
						<div
							className={styles.descriptionData}
							key={'item-' + index}
						>
							{showRowNumbers && descriptionElementsCount > 1 && (
								<div className={styles.rowNumber}>
									#{index + 1}
								</div>
							)}
							<div className={styles.descriptionDataValue}>
								{dataRow}
							</div>
						</div>
					);
				})}

				{hasHiddenRows && visibleRowsCount && (
					<a
						className={styles.showMoreLink}
						href="#"
						onClick={handleShowAllRows}
					>
						<FormattedMessage
							{...messages.showMoreLink}
							values={{
								count: descriptionElementsCount - visibleRowsCount,
							}}
						/>
					</a>
				)}
			</div>
		</div>
	);
};



export default DefinitionTerm;
