/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ComparisonRevisionFragment = { readonly __typename?: 'RobotsTxtRevision', readonly createdAt: CK.Timestamp, readonly id: CK.ID, readonly isContentEmpty: boolean | null, readonly responseStatusCode: number | null, readonly responseFailureReason: GraphQL.FetchingFailureReason | null };

export type RobotsTxtComparisonScreenQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
  previousRevisionId: GraphQL.Scalars['ID']['input'];
  nextRevisionId: GraphQL.Scalars['ID']['input'];
}>;


export type RobotsTxtComparisonScreenQuery = { readonly __typename?: 'Query', readonly comparison: { readonly __typename?: 'RobotsTxtRevisionsComparison', readonly id: CK.ID, readonly longestRowCharacters: number | null, readonly rows: ReadonlyArray<Record<string, any>> | null, readonly nextRevision: { readonly __typename?: 'RobotsTxtRevision', readonly createdAt: CK.Timestamp, readonly id: CK.ID, readonly isContentEmpty: boolean | null, readonly responseStatusCode: number | null, readonly responseFailureReason: GraphQL.FetchingFailureReason | null }, readonly previousRevision: { readonly __typename?: 'RobotsTxtRevision', readonly createdAt: CK.Timestamp, readonly id: CK.ID, readonly isContentEmpty: boolean | null, readonly responseStatusCode: number | null, readonly responseFailureReason: GraphQL.FetchingFailureReason | null } } | null };

export const ComparisonRevisionFragmentDoc = gql`
    fragment ComparisonRevision on RobotsTxtRevision {
  createdAt
  id
  isContentEmpty
  responseStatusCode
  responseFailureReason
}
    `;
export const RobotsTxtComparisonScreenDocument = gql`
    query RobotsTxtComparisonScreen($websiteId: WebsiteId!, $previousRevisionId: ID!, $nextRevisionId: ID!) {
  comparison: robotsTxtRevisionsComparison(
    websiteId: $websiteId
    previousRevisionId: $previousRevisionId
    nextRevisionId: $nextRevisionId
  ) {
    id
    longestRowCharacters
    nextRevision {
      ...ComparisonRevision
    }
    previousRevision {
      ...ComparisonRevision
    }
    rows
  }
}
    ${ComparisonRevisionFragmentDoc}`;

/**
 * __useRobotsTxtComparisonScreenQuery__
 *
 * To run a query within a React component, call `useRobotsTxtComparisonScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRobotsTxtComparisonScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRobotsTxtComparisonScreenQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *      previousRevisionId: // value for 'previousRevisionId'
 *      nextRevisionId: // value for 'nextRevisionId'
 *   },
 * });
 */
export function useRobotsTxtComparisonScreenQuery(baseOptions: Apollo.QueryHookOptions<RobotsTxtComparisonScreenQuery, RobotsTxtComparisonScreenQueryVariables> & ({ variables: RobotsTxtComparisonScreenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RobotsTxtComparisonScreenQuery, RobotsTxtComparisonScreenQueryVariables>(RobotsTxtComparisonScreenDocument, options);
      }
export function useRobotsTxtComparisonScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RobotsTxtComparisonScreenQuery, RobotsTxtComparisonScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RobotsTxtComparisonScreenQuery, RobotsTxtComparisonScreenQueryVariables>(RobotsTxtComparisonScreenDocument, options);
        }
export function useRobotsTxtComparisonScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RobotsTxtComparisonScreenQuery, RobotsTxtComparisonScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RobotsTxtComparisonScreenQuery, RobotsTxtComparisonScreenQueryVariables>(RobotsTxtComparisonScreenDocument, options);
        }
export type RobotsTxtComparisonScreenQueryHookResult = ReturnType<typeof useRobotsTxtComparisonScreenQuery>;
export type RobotsTxtComparisonScreenLazyQueryHookResult = ReturnType<typeof useRobotsTxtComparisonScreenLazyQuery>;
export type RobotsTxtComparisonScreenSuspenseQueryHookResult = ReturnType<typeof useRobotsTxtComparisonScreenSuspenseQuery>;
export type RobotsTxtComparisonScreenQueryResult = Apollo.QueryResult<RobotsTxtComparisonScreenQuery, RobotsTxtComparisonScreenQueryVariables>;