import React from 'react';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';



type Props = {
	/** Selected date or date range */
	children: React.ReactNode,
	/** Additional flag attached to date */
	flag?: React.ReactNode,
	/** State of search engine activity */
	searchEngineActivityStates?: React.ReactNode,
	trackedChangesLabel?: React.ReactNode,
};



const SelectedDateOverview: React.FC<Props> = (props) => {
	const {
		children,
		flag,
		searchEngineActivityStates,
		trackedChangesLabel,
	} = props;

	return (
		<div className="robots-txt-date-overview">
			{trackedChangesLabel && (
				<div className="robots-txt-date-overview__tracked-changes-label">
					<AttachedIcon
						icon={(
							<BasicIcon
								size={16}
								type={BasicIconType.History}
							/>
						)}
					>
						{trackedChangesLabel}
					</AttachedIcon>
				</div>
			)}
			<div className="robots-txt-date-overview__date">
				{children}
				{flag && (
					<span className="robots-txt-date-overview__flag">
						{flag}
					</span>
				)}
			</div>
			{searchEngineActivityStates && (
				<div className="robots-txt-date-overview__search-engine-activity-states">
					{searchEngineActivityStates}
				</div>
			)}
		</div>
	);
};



export default SelectedDateOverview;
