import classNames from 'classnames';
import React from 'react';

import {
	type PagesColumnsCategory,
} from '~/model/pagesColumns';



type Props = {
	children?: React.ReactNode,
	style: PagesColumnsCategory,
};

const DataCategoryCell: React.FC<Props> = (props) => {
	const {
		children,
		style,
	} = props;

	const componentClasses = classNames({
		'data-category-cell': true,
		['data-category-cell--style-' + style]: true,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default DataCategoryCell;
