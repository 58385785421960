import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import SelectField, {
	SelectFieldSize,
} from '~/components/atoms/forms/components/SelectField';

import {
	OCCURRENCES_ALL,
	OCCURRENCES_LAST,
} from '~/model/customElements';



const messages = defineMessages({
	all: {
		id: 'ui.customElements.occurrences.all',
	},
	first: {
		id: 'ui.customElements.occurrences.first',
	},
	last: {
		id: 'ui.customElements.occurrences.last',
	},
	specific: {
		id: 'ui.general.ordinalPosition',
	},
});

const options = (() => {
	const result = [
		{
			label: (
				<FormattedMessage {...messages.all} />
			),
			name: OCCURRENCES_ALL,
		},
		{
			label: (
				<FormattedMessage {...messages.first} />
			),
			name: 'specific:1',
		},
		{
			label: (
				<FormattedMessage {...messages.last} />
			),
			name: OCCURRENCES_LAST,
		},
	];

	for (let i = 2; i <= 25; i++) {
		result.push({
			label: (
				<FormattedMessage
					{...messages.specific}
					values={{
						position: i,
					}}
				/>
			),
			name: 'specific:' + i,
		});
	}

	return result;
})();



type Props = {
	name: string,
	width: number,
};

const OccurrenceSelectionField: React.FC<Props> = (props) => {
	const {
		name,
		width,
	} = props;

	return (
		<FieldStatus
			name={name}
			showIcon={false}
		>
			<SelectField
				dropdownWidth={150}
				name={name}
				options={options}
				popperBoundary={document.querySelector('.modal.js-scrollable')}
				size={SelectFieldSize.Small}
				width={width}
			/>
		</FieldStatus>
	);
};



export default OccurrenceSelectionField;
