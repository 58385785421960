import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import {
	linkExternal,
} from '~/components/logic/Copy';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StaticText from '~/components/atoms/forms/components/StaticText';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';
import SubmitButton from '~/components/app/SubmitButton';
import SwitchField from '~/components/app/SwitchField';

import {
	useUpdateLogFileAnalysisSettingsMutation,
} from '~/components/app/LogFileAnalysisForm.gql';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';



const messages = defineMessages({
	caution: {
		id: 'ui.general.caution',
	},
	disableLogFileAnalysisWarning: {
		id: 'ui.logFileAnalysisForm.disableLogFileAnalysisWarning',
	},
	disabled: {
		id: 'ui.websites.form.api.status.disabled',
	},
	enabled: {
		id: 'ui.websites.form.api.status.enabled',
	},
	noAvailableLogSourcesMessage: {
		id: 'ui.logFileAnalysisForm.noAvailableLogSourcesMessage',
	},
	noLogSourcesWarning: {
		id: 'ui.logFileAnalysisForm.noLogSourcesWarning',
	},
	saveButton: {
		id: 'ui.general.saveButton',
	},
	title: {
		id: 'ui.logFileAnalysisForm.title',
	},
	unavailable: {
		id: 'ui.websites.form.api.status.unavailable',
	},
});


const LogFileAnalysisForm: React.FC = () => {
	const websiteId = useWebsiteId();

	const isAllowedToManageLogFileAnalysis = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageLogFileAnalysis,
	);

	const {
		hasAvailableLogSources,
		isLoading,
		isLogFileAnalysisEnabled,
		isMissingLogSource,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const {
		isFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const [updateLogFileAnalysisSettings] = useUpdateLogFileAnalysisSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateLogFileAnalysisSettings({
				variables: {
					websiteId,
					status: values.isLogFileAnalysisEnabled,
				},
			});
		},
		[
			updateLogFileAnalysisSettings,
			websiteId,
		],
	);

	function renderDisplayPart() {
		return (
			<FormRows>
				<FormRow
					description={(
						isMissingLogSource ? (
							<FormattedMessage {...messages.noLogSourcesWarning} />
						) : !hasAvailableLogSources ? (
							<FormattedMessage
								{...messages.noAvailableLogSourcesMessage}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/support/log-file-analysis/#log-sources'),
								}}
							/>
						) : (
							null
						)
					)}
					label={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<StaticText>
						{isLogFileAnalysisEnabled ? (
							<AttachedIcon
								ellipsis={false}
								icon={isMissingLogSource && (
									<StatusFlag status={StatusFlagStatus.Critical} />
								)}
								iconPosition={AttachedIconPosition.Suffix}
							>
								<FormattedMessage {...messages.enabled} />
							</AttachedIcon>
						) : !hasAvailableLogSources ? (
							<FormattedMessage {...messages.unavailable} />
						) : (
							<FormattedMessage {...messages.disabled} />
						)}
					</StaticText>
				</FormRow>
			</FormRows>
		);
	}

	function renderEditablePart() {
		return (
			<Form
				defaultValues={{
					isLogFileAnalysisEnabled,
				}}
				key={isLoading ? 'loading' : 'ready'}
				onSuccess={handleSubmit}
			>
				{({ values }) => (
					<>
						<FormRows>
							<FormRow
								label={(
									<FormattedMessage {...messages.title} />
								)}
							>
								<FieldStatus
									name="isLogFileAnalysisEnabled"
									showIcon={false}
								>
									<SwitchField
										activeStateLabel={(
											<FormattedMessage {...messages.enabled} />
										)}
										inactiveStateLabel={(
											<FormattedMessage {...messages.disabled} />
										)}
										name="isLogFileAnalysisEnabled"
									/>
								</FieldStatus>
							</FormRow>

							{isLogFileAnalysisEnabled && !values.isLogFileAnalysisEnabled && (
								<CalloutMessage
									borders={true}
									message={(
										<FormattedMessage {...messages.caution} />
									)}
									status={CalloutMessageStatus.Critical}
								>
									<FormattedMessage {...messages.disableLogFileAnalysisWarning} />
								</CalloutMessage>
							)}
						</FormRows>

						<FormErrorMessages />

						<ButtonsLayout>
							<CancelButton />

							<SubmitButton>
								<FormattedMessage {...messages.saveButton} />
							</SubmitButton>
						</ButtonsLayout>
					</>
				)}
			</Form>
		);
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageLogFileAnalysis}
			isReadOnly={(
				isLogFileAnalysisEnabled === false
				&& (
					isFeatureEnabled === false
					|| hasAvailableLogSources === false
				)
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				{renderDisplayPart()}
			</DisplayPart>

			<EditablePart>
				{renderEditablePart()}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default LogFileAnalysisForm;
