import {
	captureException,
} from '@sentry/browser';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CustomerDetailsFields, {
	validateAddress,
	validateCityAndPostalCode,
	validateCountry,
	validateEmail,
	validateName,
	validateState,
	validateVatNumber,
} from '~/components/app/CustomerDetailsFields';
import CustomerDetailsOverview from '~/components/app/CustomerDetailsOverview';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import RecurlyFormErrorMessages from '~/components/app/RecurlyFormErrorMessages';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';

import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';



const messages = defineMessages({
	billingDetailsTitle: {
		id: 'ui.teamDetail.billing.billingDetailsTitle',
	},
});

const validations = {
	validateAddress,
	validateCityAndPostalCode,
	validateCountry,
	validateEmail,
	validateName,
	validateState,
	validateVatNumber,
};



const BillingDetailsForm = (props) => {
	const {
		billingDetails,
		submitCallback,
		team,
	} = props;

	const isAllowedToManageBilling = useIsAllowedWithAccount(
		team.get('id'),
		GraphQL.ActionWithAccount.ManageBilling,
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			if (values.email && values.email.length > 0) {
				values.email = values.email.trim();
			}

			try {
				await submitCallback({
					model: {
						address: values.address,
						city: values.city,
						country: values.country,
						email: values.email,
						company_name: values.name,
						state: values.state,
						vatNumber: values.vatNumber,
						zip_code: values.postalCode,
					},
				});
			} catch (e) {
				captureException(e);

				throw e;
			}
		},
		[
			submitCallback,
		],
	);

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageBilling}
			isReadOnly={true}
			key={team.get('id')}
			title={(
				<FormattedMessage {...messages.billingDetailsTitle} />
			)}
		>
			<DisplayPart>
				<CustomerDetailsOverview
					customerAddress={billingDetails ? {
						address: billingDetails.get('address'),
						city: billingDetails.get('city'),
						country: billingDetails.get('country'),
						name: billingDetails ? billingDetails.get('company_name') : '',
						state: billingDetails.get('state'),
						postalCode: billingDetails.get('zip_code'),
					} : null}
					emails={billingDetails ? [billingDetails.get('email')] : null}
					vatNumber={billingDetails ? billingDetails.get('vat_number') : null}
				/>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultFocus="company_name"
					defaultValues={{
						address: billingDetails ? billingDetails.get('address') : '',
						city: billingDetails ? billingDetails.get('city') : '',
						country: billingDetails
							? billingDetails.get('country')
							: team.get('country') || '',
						email: billingDetails ? billingDetails.get('email') : '',
						name: billingDetails ? billingDetails.get('company_name') : '',
						state: billingDetails
							? billingDetails.get('state')
							: team.get('state') || '',
						postalCode: billingDetails ? billingDetails.get('zip_code') : '',
						vatNumber: billingDetails ? billingDetails.get('vat_number') : '',
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<CustomerDetailsFields />

					<RecurlyFormErrorMessages />

					<FormErrorMessages />

					<ButtonsLayout>
						<CancelButton />
						<SaveSubmitButton />
					</ButtonsLayout>
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default BillingDetailsForm;
