import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import NotApplicableValue from '~/components/logic/values/blankValues/NotApplicableValue';



class LinksBrokenCellValue extends Component {

	render() {
		const {
			value,
		} = this.props;

		if (value === null) {
			return (
				<NotApplicableValue ellipsis={true} />
			);
		}

		const numberOfBrokenLinks = value.has('same')
			? value.get('same')
			: value.get('next');

		return (
			<FormattedNumber value={numberOfBrokenLinks} />
		);
	}

}

LinksBrokenCellValue.propTypes = {
	value: PropTypes.instanceOf(Immutable.Map).isRequired,
};



export default LinksBrokenCellValue;
