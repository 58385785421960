import React from 'react';

import Measurer from '~/utilities/Measurer';
import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



type Props = {
	button: React.ReactNode,
	children?: RenderProp<{ containerWidth: number }>,
};



const FieldWithAttachedButton: React.FC<Props> = (props) => {
	const {
		button,
		children,
	} = props;

	return (
		<div className="field-with-attached-button">
			<div className="field-with-attached-button__field">
				<Measurer>
					{({ containerWidth }) => (
						renderProp(children, {
							containerWidth,
						})
					)}
				</Measurer>
			</div>
			<div className="field-with-attached-button__button">
				{button}
			</div>
		</div>
	);
};



export default FieldWithAttachedButton;
