import Immutable from 'immutable';
import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import DonutPagesChart from '../components/DonutPagesChart';



const messages = defineMessages({
	no: {
		id: 'ui.contentOverview.tableFilter.no',
	},
	yes: {
		id: 'ui.contentOverview.tableFilter.yes',
	},
});



const BooleanChart = (props) => {
	const {
		data,
		falseColor = '#FF5959',
		field,
		height,
		percentageFactor = 100,
	} = props;

	const intl = useIntl();

	const labels = {
		true: intl.formatMessage(messages.yes),
		false: intl.formatMessage(messages.no),
	};

	const order = [
		true,
		false,
	];

	const specificColors = {
		true: '#42CC67',
		false: falseColor,
	};

	return (
		<DonutPagesChart
			data={(data && !data.toJS) ? Immutable.fromJS(data) : data}
			height={height}
			labels={labels}
			name={field}
			order={order}
			percentageFactor={percentageFactor}
			specificColors={specificColors}
		/>
	);
};



export default React.memo(BooleanChart);
