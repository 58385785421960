import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const BugOpenedIconBuilder: IconBuilder = {
	icon: ({ color = '#8595a6' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="issue-broken-bug"
					transform="translate(2.000000, 1.000000)"
				>
					<g
						id="Group"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						transform="translate(0.571429, 0.714286)"
					>
						<path
							d="M15.4553571,11.5771683 C15.4553571,16.0946747 12.7570791,19.7563775 9.42857143,19.7563775 C6.1000638,19.7563775 3.40178571,16.0946747 3.40178571,11.5771683 C3.40178571,7.05966197 6.1000638,3.39795918 9.42857143,3.39795918 C12.7570791,3.39795918 15.4553571,7.05966197 15.4553571,11.5771683 Z"
							id="Path"
						>
						</path>
						<path
							d="M4.11862882,7.70616394 L14.7197449,7.70272003"
							id="Path"
						>
						</path>
						<polyline
							id="Path"
							points="3.40178571 10.2857143 0.818877549 9.42474489 0.818877549 7.70280609"
						>
						</polyline>
						<polyline
							id="Path"
							points="4.11862882 15.4865721 1.67950255 16.3122417 1.67950255 18.0341805"
						>
						</polyline>
						<polyline
							id="Path"
							points="15.4553571 10.2857143 18.0382653 9.42474489 18.0382653 7.70280609"
						>
						</polyline>
						<polyline
							id="Path"
							points="14.7082079 15.501639 17.1774681 16.3126722 17.1774681 18.034611"
						>
						</polyline>
						<path
							d="M7.11798789,3.98780931 C7.11798789,3.98780931 7.16792409,1.78372767 5.12312181,0.815137114"
							id="Path"
						>
						</path>
						<path
							d="M11.9993399,4.17730868 C11.9993399,4.17730868 11.9614573,1.78381377 14.0062596,0.815223214"
							id="Path"
						>
						</path>
					</g>
					<g
						id="Group"
						transform="translate(9.142857, 11.000000)"
					>
						<polygon
							id="Path"
							points="0 0 10.2857143 0 10.2857143 10.2857143 0 10.2857143"
						>
						</polygon>
						<rect
							fill="#FF5959"
							fillRule="nonzero"
							height="9.42857143"
							id="Rectangle"
							rx="4.71428571"
							stroke="#FF6759"
							strokeOpacity="0.5"
							width="9.42857143"
							x="0.428571429"
							y="0.428571429"
						>
						</rect>
						<g
							fill="#FAFBFC"
							fillRule="nonzero"
							id="Path"
							transform="translate(4.285714, 1.714286)"
						>
							<polygon points="0.214285714 5.35714286 1.5 5.35714286 1.5 6.64285714 0.214285714 6.64285714"></polygon>
							<path d="M0.106938694,0.957369189 C0.09521244,0.546975523 0.428119929,0.214285714 0.839063786,0.214285714 L0.875221929,0.214285714 C1.2912897,0.214285714 1.61891493,0.552517097 1.60734702,0.957369189 L1.52123225,3.97120224 C1.50950601,4.38159591 1.16219371,4.71428571 0.752094677,4.71428571 L0.962191037,4.71428571 C0.549134331,4.71428571 0.204621369,4.37605433 0.193053463,3.97120224 L0.106938694,0.957369189 Z"></path>
						</g>
					</g>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default BugOpenedIconBuilder;

