/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateEmailMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  email: GraphQL.Scalars['String']['input'];
}>;


export type ValidateEmailMutation = { readonly __typename?: 'Mutation', readonly ValidateEmail: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type InviteNewUserMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  assignedWebsites: GraphQL.InputMaybe<ReadonlyArray<GraphQL.Scalars['WebsiteId']['input']> | GraphQL.Scalars['WebsiteId']['input']>;
  email: GraphQL.Scalars['String']['input'];
  hasLimitedWebsitesAccess: GraphQL.InputMaybe<GraphQL.Scalars['Boolean']['input']>;
  role: GraphQL.UserRole;
}>;


export type InviteNewUserMutation = { readonly __typename?: 'Mutation', readonly InviteNewUser: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly displayName: string, readonly email: string, readonly hasLimitedWebsitesAccess: boolean, readonly id: CK.InvitationId, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithInvitation>, readonly role: GraphQL.UserRole, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> } | null } } };


export const ValidateEmailDocument = gql`
    mutation ValidateEmail($accountId: AccountId!, $email: String!) {
  ValidateEmail(accountId: $accountId, email: $email) {
    query {
      ping
    }
  }
}
    `;
export type ValidateEmailMutationFn = Apollo.MutationFunction<ValidateEmailMutation, ValidateEmailMutationVariables>;

/**
 * __useValidateEmailMutation__
 *
 * To run a mutation, you first call `useValidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailMutation, { data, loading, error }] = useValidateEmailMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useValidateEmailMutation(baseOptions?: Apollo.MutationHookOptions<ValidateEmailMutation, ValidateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateEmailMutation, ValidateEmailMutationVariables>(ValidateEmailDocument, options);
      }
export type ValidateEmailMutationHookResult = ReturnType<typeof useValidateEmailMutation>;
export type ValidateEmailMutationResult = Apollo.MutationResult<ValidateEmailMutation>;
export type ValidateEmailMutationOptions = Apollo.BaseMutationOptions<ValidateEmailMutation, ValidateEmailMutationVariables>;
export const InviteNewUserDocument = gql`
    mutation InviteNewUser($accountId: AccountId!, $assignedWebsites: [WebsiteId!], $email: String!, $hasLimitedWebsitesAccess: Boolean, $role: UserRole!) {
  InviteNewUser(
    accountId: $accountId
    assignedWebsites: $assignedWebsites
    email: $email
    limitedWebsitesAccess: $hasLimitedWebsitesAccess
    role: $role
  ) {
    query {
      account(id: $accountId) {
        id
        invitations {
          displayName
          email
          hasLimitedWebsitesAccess
          id
          permittedActions
          role
          websites {
            id
          }
        }
      }
    }
  }
}
    `;
export type InviteNewUserMutationFn = Apollo.MutationFunction<InviteNewUserMutation, InviteNewUserMutationVariables>;

/**
 * __useInviteNewUserMutation__
 *
 * To run a mutation, you first call `useInviteNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteNewUserMutation, { data, loading, error }] = useInviteNewUserMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      assignedWebsites: // value for 'assignedWebsites'
 *      email: // value for 'email'
 *      hasLimitedWebsitesAccess: // value for 'hasLimitedWebsitesAccess'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useInviteNewUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteNewUserMutation, InviteNewUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteNewUserMutation, InviteNewUserMutationVariables>(InviteNewUserDocument, options);
      }
export type InviteNewUserMutationHookResult = ReturnType<typeof useInviteNewUserMutation>;
export type InviteNewUserMutationResult = Apollo.MutationResult<InviteNewUserMutation>;
export type InviteNewUserMutationOptions = Apollo.BaseMutationOptions<InviteNewUserMutation, InviteNewUserMutationVariables>;