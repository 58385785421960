import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticText from '~/components/atoms/forms/components/StaticText';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useWebsiteIsVerified from '~/hooks/useWebsiteIsVerified';

import {
	useUpdateWebsiteAdminVerificationMutation,
	useWebsiteAdminVerificationFormQuery,
} from './WebsiteAdminVerificationForm.gql';

import matchAndReturn from '~/utilities/matchAndReturn';



function renderIsVerifiedLabel(isVerified: boolean | null) {
	if (isVerified === null) {
		return '';
	}

	return ` (${isVerified ? 'Yes' : 'No'})`;
}



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminVerificationForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const isVerified = useWebsiteIsVerified(websiteId);

	const isAllowedToManageVerification = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageAdminVerification,
	);

	const { data } = useWebsiteAdminVerificationFormQuery({
		variables: {
			websiteId,
		},
	});

	const [updateWebsiteAdminVerification] = useUpdateWebsiteAdminVerificationMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteAdminVerification({
				variables: {
					verification: values.verification,
					websiteId,
				},
			});
		},
		[
			updateWebsiteAdminVerification,
			websiteId,
		],
	);

	const adminSettings = data?.website?.adminSettings ?? null;

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageVerification}
			isForAdmins={true}
			title="Verification"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Verified domain">
						<StaticText focusTarget="verification">
							{matchAndReturn(adminSettings?.verification ?? '', {
								'': '',
								'automatic': 'Automatic ' + renderIsVerifiedLabel(isVerified),
								'no': 'No',
								'yes': 'Yes',
							})}
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				{adminSettings !== null && (
					<Form
						defaultValues={{
							verification: adminSettings.verification,
						}}
						onSuccess={handleSubmit}
					>
						<FormRows>
							<FormRow
								htmlFor="verification"
								label="Verified domain"
							>
								<FieldStatus name="verification">
									<SelectField
										name="verification"
										options={[
											{
												name: 'yes',
												label: 'Yes',
											},
											{
												name: 'no',
												label: 'No',
											},
											{
												name: 'automatic',
												label: 'Automatic',
											},
										]}
									/>
								</FieldStatus>
							</FormRow>
						</FormRows>

						<FormErrorMessages />

						<ButtonsLayout>
							<CancelButton />
							<SaveSubmitButton />
						</ButtonsLayout>
					</Form>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default WebsiteAdminVerificationForm;
