import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import {
	usePopper,
} from 'react-popper';
import {
	type Boundary,
} from '@popperjs/core';
import {
	type OffsetsFunction,
} from '@popperjs/core/lib/modifiers/offset';

import DepthLayer from '~/components/patterns/utils/DepthLayer';
import HintPopupLayout from '~/components/patterns/hints/hint/HintPopupLayout';
import PopupBubble, {
	PopupBubbleSize as HintPopupSize,
	PopupBubbleStyle as HintPopupSkin,
	type PopupBubbleRef,
} from '~/components/patterns/popups/PopupBubble';

import classes from './Hint.module.scss';



export enum HintAttachment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

export enum HintPopupVisibility {
	Always = 'always',
	Never = 'never',
	OnHover = 'hover',
	OnClick = 'click',
}

export const HOVER_DELAY = 250;

export {
	HintPopupSize,
	HintPopupSkin,
};

type Props = {
	attachment?: HintAttachment,
	blurDelay?: number,
	/** Allow to break words in popup content */
	breakWords?: boolean,
	children: React.ReactNode,
	className?: string,
	hoverDelay?: number,
	/** Possibility to change inline type of element to block */
	inline?: boolean,
	onPopupMouseEnter?: () => void,
	onPopupMouseLeave?: () => void,
	onToggleCallback?: (isOpen: boolean) => void,
	/** Possibility to specify boundary for Popper where we will detect overflow */
	popperBoundary?: Boundary,
	popup: React.ReactNode,
	popupLayout?: (
		content: React.ReactNode,
		maxHeight?: number,
	) => React.ReactNode,
	popupMaxHeight?: number,
	popupMaxWidth?: React.CSSProperties['maxWidth'],
	popupOffset?: [number, number] | OffsetsFunction,
	popupSize?: HintPopupSize,
	popupSkin?: HintPopupSkin,
	/** Possibility to set popup bubble as untouchable by mouse pointer */
	popupUntouchable?: boolean,
	popupVisibility?: HintPopupVisibility,
	/** Manually set z-index CSS property. There is always built-in DepthLayer */
	popupZIndex?: number,
	/** Stop click events from propagating up the tree through the portal */
	preventClickPropagation?: boolean,
	preventOutsideClickCallback?: (target: HTMLElement) => boolean,
	/** When enabled we will use pointer cursor when Hint is clickable. When disabled cursor to display is based on current context. */
	targetCustomCursor?: boolean,
};

export type HintRef = {
	close: () => void,
	open: () => void,
};



const Hint = React.forwardRef<any, Props>((props, ref: React.Ref<HintRef>) => {
	const {
		attachment = HintAttachment.Left,
		blurDelay = 0,
		breakWords,
		children,
		className,
		hoverDelay = HOVER_DELAY,
		inline = true,
		onPopupMouseEnter,
		onPopupMouseLeave,
		onToggleCallback,
		preventClickPropagation = false,
		preventOutsideClickCallback,
		popperBoundary = 'clippingParents',
		popup,
		popupLayout = (content, maxHeight) => (
			<HintPopupLayout maxHeight={maxHeight}>
				{content}
			</HintPopupLayout>
		),
		popupMaxHeight,
		popupMaxWidth = 300,
		popupOffset,
		popupSize = HintPopupSize.Medium,
		popupSkin = HintPopupSkin.Dark,
		popupUntouchable,
		popupVisibility = HintPopupVisibility.OnHover,
		popupZIndex,
		targetCustomCursor = true,
	} = props;

	const blurTimeout = React.useRef<any | null>(null);
	const bubbleRef = React.useRef<PopupBubbleRef>(null);
	const hoverTimeout = React.useRef<any | null>(null);
	const hintRef = React.useRef<HTMLDivElement | null>(null);

	const [openPopup, setOpenPopup] = React.useState<boolean>(popupVisibility === HintPopupVisibility.Always);
	const [referenceElement, setReferenceElement] = React.useState<HTMLSpanElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);

	const {
		state: popperState,
		styles,
		attributes,
	} = usePopper(
		referenceElement,
		popperElement,
		{
			placement: attachment === HintAttachment.Left ? 'bottom-start' : (
				attachment === HintAttachment.Right ? 'bottom-end' : 'bottom'
			),
			modifiers: [
				{
					name: 'flip',
					options: {
						boundary: popperBoundary,
						altBoundary: false,
						// First useful placement will be used when there won't be enough space
						// for default placement.
						fallbackPlacements: ['bottom-start', 'bottom-end', 'top', 'top-start', 'top-end'],
					},
				},
				{
					name: 'preventOverflow',
					options: {
						mainAxis: false,
					},
				},
				{
					name: 'arrow',
					options: {
						element: bubbleRef.current?.getArrowRef().current,
					},
				},
				{
					name: 'offset',
					options: {
						offset: popupOffset,
					},
				},
			],
		},
	);

	const handleOpen = React.useCallback(
		() => {
			setOpenPopup(true);

			if (onToggleCallback) {
				onToggleCallback(true);
			}
		},
		[
			onToggleCallback,
		],
	);

	const handleClose = React.useCallback(
		() => {
			if (onToggleCallback) {
				onToggleCallback(false);
			}

			if (openPopup) {
				setOpenPopup(false);
			}
		},
		[
			onToggleCallback,
			openPopup,
		],
	);

	React.useEffect(
		() => {
			return () => {
				if (popupVisibility === HintPopupVisibility.OnHover) {
					clearTimeout(blurTimeout.current);
					clearTimeout(hoverTimeout.current);
				}

				if (openPopup) {
					setOpenPopup(false);
				}
			};
		},
		[
			openPopup,
			popupVisibility,
		],
	);

	React.useEffect(
		() => {
			if (!openPopup) {
				return;
			}

			function handleOutsideClick(event: MouseEvent) {
				const target = event.target;

				if (target === null || !(target instanceof HTMLElement)) {
					return;
				}

				if (popupVisibility === HintPopupVisibility.Always) {
					return;
				}

				if (preventOutsideClickCallback && preventOutsideClickCallback(target) === true) {
					return;
				}

				if (!(hintRef.current?.contains(target) || bubbleRef.current?.containsTarget(target))) {
					handleClose();
				}
			}

			document.addEventListener('click', handleOutsideClick, { capture: true });

			return () => {
				document.removeEventListener('click', handleOutsideClick, { capture: true });
			};
		},
		[
			handleClose,
			openPopup,
			popupVisibility,
			preventOutsideClickCallback,
		],
	);

	React.useEffect(
		() => {
			setOpenPopup(popupVisibility === HintPopupVisibility.Always);
		},
		[
			popupVisibility,
		],
	);

	React.useImperativeHandle(ref, () => ({
		close: handleClose,
		open: handleOpen,
	}));

	const handlePopupToggle = (e: React.MouseEvent<HTMLSpanElement>) => {
		if (popupVisibility !== HintPopupVisibility.OnClick) {
			return false;
		}

		e.preventDefault();
		e.stopPropagation();

		if (!openPopup) {
			handleOpen();
		} else {
			handleClose();
		}
	};

	const handleTargetMouseEnter = () => {
		if (popupVisibility !== HintPopupVisibility.OnHover) {
			return false;
		}

		clearTimeout(blurTimeout.current);

		hoverTimeout.current = setTimeout(() => {
			handleOpen();
		}, hoverDelay);
	};

	const handleTargetMouseLeave = () => {
		if (popupVisibility !== HintPopupVisibility.OnHover) {
			return false;
		}

		clearTimeout(hoverTimeout.current);

		blurTimeout.current = setTimeout(() => {
			handleClose();
		}, blurDelay);
	};

	const handlePopupClick = React.useCallback(
		(e) => {
			if (preventClickPropagation) {
				e.stopPropagation();
			}
		},
		[
			preventClickPropagation,
		],
	);

	const handlePopupMouseEnter = React.useCallback(
		() => {
			if (onPopupMouseEnter) {
				onPopupMouseEnter();
			}

			if (blurDelay === 0 || popupVisibility !== HintPopupVisibility.OnHover) {
				return false;
			}

			clearTimeout(blurTimeout.current);
		},
		[
			blurDelay,
			onPopupMouseEnter,
			popupVisibility,
		],
	);

	const handlePopupMouseLeave = React.useCallback(
		() => {
			if (onPopupMouseLeave) {
				onPopupMouseLeave();
			}

			if (blurDelay === 0 || popupVisibility !== HintPopupVisibility.OnHover) {
				return false;
			}

			blurTimeout.current = setTimeout(() => {
				handleClose();
			}, blurDelay);
		},
		[
			blurDelay,
			handleClose,
			onPopupMouseLeave,
			popupVisibility,
		],
	);

	const hintClasses = classNames({
		[classes.component]: true,
		[classes.blockElement]: !inline,
	}, className);

	const targetClasses = classNames({
		[classes.target]: true,
		[classes.isClickable]: targetCustomCursor && popupVisibility === HintPopupVisibility.OnClick,
		[classes.isHoverable]: targetCustomCursor && popupVisibility === HintPopupVisibility.OnHover,
	});

	const popperDropdownContainer = document.getElementById('popper-elements');

	return (
		<div
			className={hintClasses}
			ref={hintRef}
		>
			<DepthLayer depthJump={100}>
				{({ depthLayer }) => {
					// Bigger index will give this hint bigger priority.
					// This is good in case of more different hints next to each other.
					depthLayer += 50;

					const additionalStyle: React.CSSProperties = {};

					if (popupZIndex) {
						additionalStyle.zIndex = Math.max(depthLayer, popupZIndex);
					} else {
						additionalStyle.zIndex = depthLayer;
					}

					const additionalAttributes: any = {};

					if (popupOffset) {
						additionalAttributes.offset = popupOffset;
					}

					return (
						<>
							<span
								className={targetClasses}
								data-popper-placement={popperState && popperState.placement}
								onClick={handlePopupToggle}
								onMouseEnter={handleTargetMouseEnter}
								onMouseLeave={handleTargetMouseLeave}
								ref={setReferenceElement}
							>
								{children}
							</span>
							{openPopup && popperDropdownContainer && ReactDOM.createPortal(
								<div
									className={classNames({
										[classes.bubble]: true,
										[classes.isUntouchable]: popupUntouchable,
									})}
									ref={setPopperElement}
									style={{
										zIndex: depthLayer,
										...styles.popper,
									}}
									{...attributes.popper}
								>
									<PopupBubble
										arrowStyles={styles.arrow}
										breakWords={breakWords}
										gaps={false}
										maxWidth={popupMaxWidth}
										onClick={handlePopupClick}
										onMouseEnter={handlePopupMouseEnter}
										onMouseLeave={handlePopupMouseLeave}
										ref={bubbleRef}
										size={popupSize}
										style={popupSkin}
									>
										{popupLayout(popup, popupMaxHeight)}
									</PopupBubble>
								</div>,
								popperDropdownContainer,
							)}
						</>
					);
				}}
			</DepthLayer>
		</div>
	);
});



export default Hint;
