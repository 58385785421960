import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';
import EditableFormWrapperBase from '~/components/atoms/forms/basis/EditableFormWrapperBase';
import Hint from '~/components/patterns/hints/hint/Hint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import type usePermission from '~/hooks/usePermission';
import useUserRole from '~/hooks/useUserRole';

import {
	type RenderProp,
} from '~/utilities/renderProp';



const messages = defineMessages({
	editLink: {
		id: 'ui.forms.editLink',
	},
	insufficientPermissionsForKingdomAdminTooltip: {
		id: 'ui.forms.insufficientPermissionsForKingdomAdminTooltip',
	},
	insufficientPermissionsForKingdomAdminTooltipAdminOnly: {
		id: 'ui.forms.insufficientPermissionsForKingdomAdminTooltipAdminOnly',
	},
	managed: {
		id: 'ui.forms.managed',
	},
	managedTooltip: {
		id: 'ui.forms.managedTooltip',
	},
	managedViaConductor: {
		id: 'ui.general.notAllowedBecauseManagedViaConductor',
	},
	readOnly: {
		id: 'ui.general.readOnly',
	},
});

const insufficientPermissionsTooltipMessages = defineMessages({
	[GraphQL.UserRole.Admin]: {
		id: 'ui.forms.insufficientPermissionsTooltip',
	},
	[GraphQL.UserRole.Editor]: {
		id: 'ui.forms.insufficientPermissionsTooltip',
	},
	[GraphQL.UserRole.Manager]: {
		id: 'ui.forms.insufficientPermissionsTooltip.manager',
	},
	[GraphQL.UserRole.Viewer]: {
		id: 'ui.forms.insufficientPermissionsTooltip',
	},
});



type Props = {
	isAllowed?: ReturnType<ReturnType<typeof usePermission>>,
	children: RenderProp<{
		editMode: boolean,
	}>,
	exitEditModeDelay?: number,
	footer?: RenderProp<{
		editMode: boolean,
	}>,
	isForAdmins?: boolean,
	isOpen?: boolean,
	isReadOnly?: boolean,
	onEnterEditModeCallback?: (editMode: boolean) => void,
	title: React.ReactNode,
};



const EditableFormWrapper: React.FC<Props> = (props) => {
	const {
		isAllowed,
		children,
		exitEditModeDelay,
		footer = null,
		isForAdmins = false,
		isOpen = false,
		isReadOnly = false,
		onEnterEditModeCallback,
		title,
	} = props;

	const currentUserId = useCurrentUserId();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const currentUserRole = useUserRole(effectiveHomeAccountId, currentUserId);

	const isEditable = (
		isReadOnly === false
		&& (isAllowed?.yes ?? true)
	);

	let subtitle;

	if (isReadOnly) {
		subtitle = (
			<FormattedMessage {...messages.readOnly} />
		);
	} else if (isEditable === false) {
		if (isAllowed?.noBecauseInsufficientPermissions && isForAdmins === false) {
			subtitle = (
				<Hint
					blurDelay={100}
					popup={currentUserRole !== null && (
						<Copy {...insufficientPermissionsTooltipMessages[currentUserRole]} />
					)}
				>
					<FormattedMessage {...messages.readOnly} />
				</Hint>
			);
		} else if (isAllowed?.noBecauseInsufficientPermissionsForKingdomAdmin && isForAdmins === false) {
			subtitle = (
				<Hint
					blurDelay={100}
					popup={(
						<Copy {...messages.insufficientPermissionsForKingdomAdminTooltip} />
					)}
				>
					<FormattedMessage {...messages.readOnly} />
				</Hint>
			);
		} else if (
			isAllowed?.noBecauseInsufficientPermissions
			|| isAllowed?.noBecauseInsufficientPermissionsForKingdomAdmin
		) {
			subtitle = (
				<Hint
					blurDelay={100}
					popup={(
						<Copy {...messages.insufficientPermissionsForKingdomAdminTooltipAdminOnly} />
					)}
				>
					<FormattedMessage {...messages.readOnly} />
				</Hint>
			);
		} else if (isAllowed?.noBecauseManaged) {
			subtitle = (
				<Hint
					blurDelay={100}
					popup={(
						<Copy {...messages.managedTooltip} />
					)}
				>
					<FormattedMessage {...messages.managed} />
				</Hint>
			);
		} else if (isAllowed?.noBecauseManagedViaConductor) {
			subtitle = (
				<Hint
					blurDelay={100}
					popup={(
						<Copy {...messages.managedViaConductor} />
					)}
				>
					<FormattedMessage {...messages.readOnly} />
				</Hint>
			);
		}
	} else {
		subtitle = (
			<InternalLink
				onClickCallback={(e) => {
					e.preventDefault();

					return false;
				}}
				style={InternalLinkStyle.Decent}
			>
				<FormattedMessage {...messages.editLink} />
			</InternalLink>
		);
	}

	return (
		<EditableFormWrapperBase
			exitEditModeDelay={exitEditModeDelay}
			footer={footer}
			isEditable={isEditable}
			isForAdmins={isForAdmins}
			isOpen={isOpen}
			onEnterEditModeCallback={onEnterEditModeCallback}
			subtitle={subtitle}
			title={title}
		>
			{children}
		</EditableFormWrapperBase>
	);
};



export default EditableFormWrapper;
