import type CK from '~/types/contentking';

import {
	usePageUrlQuery,
} from './usePageUrl.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageUrl(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
): string | null {
	const { data } = usePageDetailPropertiesQuery(
		usePageUrlQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.url ?? null;
}



export default usePageUrl;
