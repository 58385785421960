import React from 'react';

import type CK from '~/types/contentking';

import {
	useHomeAccountIdsQuery,
} from './useHomeAccountIds.gql';

import useInitialUserPropertiesQuery from '~/hooks/useInitialUserPropertiesQuery';



function useHomeAccountIds(): ReadonlyArray<CK.AccountId> | null {
	const { data } = useInitialUserPropertiesQuery(
		useHomeAccountIdsQuery,
	);

	const memberships = data?.authenticatedSession?.memberships ?? null;

	return React.useMemo(
		() => {
			return memberships !== null
				? memberships.map(({ account }) => account.id)
				: null;
		},
		[
			memberships,
		],
	);
}



export default useHomeAccountIds;
