/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIsCrawlingPausedQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIsCrawlingPausedQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isCrawlingPaused: boolean } | null };


export const WebsiteIsCrawlingPausedDocument = gql`
    query WebsiteIsCrawlingPaused($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isCrawlingPaused
  }
}
    `;

/**
 * __useWebsiteIsCrawlingPausedQuery__
 *
 * To run a query within a React component, call `useWebsiteIsCrawlingPausedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIsCrawlingPausedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIsCrawlingPausedQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIsCrawlingPausedQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIsCrawlingPausedQuery, WebsiteIsCrawlingPausedQueryVariables> & ({ variables: WebsiteIsCrawlingPausedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIsCrawlingPausedQuery, WebsiteIsCrawlingPausedQueryVariables>(WebsiteIsCrawlingPausedDocument, options);
      }
export function useWebsiteIsCrawlingPausedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIsCrawlingPausedQuery, WebsiteIsCrawlingPausedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIsCrawlingPausedQuery, WebsiteIsCrawlingPausedQueryVariables>(WebsiteIsCrawlingPausedDocument, options);
        }
export function useWebsiteIsCrawlingPausedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteIsCrawlingPausedQuery, WebsiteIsCrawlingPausedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIsCrawlingPausedQuery, WebsiteIsCrawlingPausedQueryVariables>(WebsiteIsCrawlingPausedDocument, options);
        }
export type WebsiteIsCrawlingPausedQueryHookResult = ReturnType<typeof useWebsiteIsCrawlingPausedQuery>;
export type WebsiteIsCrawlingPausedLazyQueryHookResult = ReturnType<typeof useWebsiteIsCrawlingPausedLazyQuery>;
export type WebsiteIsCrawlingPausedSuspenseQueryHookResult = ReturnType<typeof useWebsiteIsCrawlingPausedSuspenseQuery>;
export type WebsiteIsCrawlingPausedQueryResult = Apollo.QueryResult<WebsiteIsCrawlingPausedQuery, WebsiteIsCrawlingPausedQueryVariables>;