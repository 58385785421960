import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	useAddCustomerWebsitesInBulkMutation,
	useAddTrialWebsitesInBulkMutation,
} from './useCreateWebsitesInBulk.gql';

import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountType from '~/hooks/useAccountType';
import useRequestAdminConfirmationIfApplicable from '~/hooks/useRequestAdminConfirmationIfApplicable';



function useCreateWebsitesInBulk(accountId: CK.AccountId | null) {
	const requestAdminConfirmationIfApplicable = useRequestAdminConfirmationIfApplicable(accountId);

	const accountPhase = useAccountPhase(accountId);
	const accountType = useAccountType(accountId);

	const [addCustomerWebsitesInBulk] = useAddCustomerWebsitesInBulkMutation();
	const [addTrialWebsitesInBulk] = useAddTrialWebsitesInBulkMutation();

	return React.useCallback(
		async (
			input: {
				acceptedLegalDocuments: ReadonlyArray<GraphQL.LegalDocumentType>,
				domains: Array<{
					domain: string,
					pageCapacity: number,
				}>,
				pageBundle?: number,
				sessionId: string,
				threeDSecureToken?: string,
			},
		) => {
			const {
				acceptedLegalDocuments,
				domains,
				sessionId,
				pageBundle = null,
				threeDSecureToken = null,
			} = input;

			if (
				accountId === null
			) {
				return;
			}

			await requestAdminConfirmationIfApplicable();

			if (accountPhase === GraphQL.AccountPhase.Trial) {
				await addTrialWebsitesInBulk({
					variables: {
						accountId,
						acceptedLegalDocuments,
						domains,
						sessionId,
					},
				});
			} else if (accountType === GraphQL.AccountType.Universal) {
				await addCustomerWebsitesInBulk({
					variables: {
						accountId,
						acceptedLegalDocuments,
						domains,
						pageBundle,
						sessionId,
						threeDSecureToken,
					},
				});
			}
		},
		[
			accountId,
			accountPhase,
			accountType,
			addCustomerWebsitesInBulk,
			addTrialWebsitesInBulk,
			requestAdminConfirmationIfApplicable,
		],
	);
}



export default useCreateWebsitesInBulk;
