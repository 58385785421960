import PropTypes from 'prop-types';
import React from 'react';



const ColumnsActionsLayout = (props) => {
	const {
		buttons,
		deletionActionElements,
		deletionButton,
		deletionNote,
		note,
		revertChangesElement,
		title,
	} = props;

	return (
		<div className="columns-actions">
			{title && (
				<div className="columns-actions__title">
					{title}
				</div>
			)}
			{(deletionActionElements || deletionButton || deletionNote) && (
				<div className="columns-actions__deletion-row">
					{deletionActionElements}

					{deletionNote && (
						<div className="columns-actions__note">
							{deletionNote}
						</div>
					)}

					{deletionButton && (
						<div className="columns-actions__deletion-button">
							{deletionButton}
						</div>
					)}
				</div>
			)}
			{note && (
				<div className="columns-actions__note">
					{note}
				</div>
			)}
			{buttons && (
				<div className="columns-actions__buttons">
					{buttons}
				</div>
			)}
			{revertChangesElement && (
				<div className="columns-actions__revert-changes">
					{revertChangesElement}
				</div>
			)}
		</div>
	);
};

ColumnsActionsLayout.propTypes = {
	buttons: PropTypes.node,
	deletionActionElements: PropTypes.node,
	deletionButton: PropTypes.node,
	deletionNote: PropTypes.node,
	note: PropTypes.node,
	revertChangesElement: PropTypes.node,
	title: PropTypes.node,
};



export default ColumnsActionsLayout;
