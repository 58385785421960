import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import SelectField, {
	SelectFieldSize,
} from '~/components/atoms/forms/components/SelectField';

import {
	EXTRACTION_METHOD_CSS,
	EXTRACTION_METHOD_REGEXP,
	EXTRACTION_METHOD_XPATH,
} from '~/model/customElements';



const LIST_OF_EXTRACTION_METHODS = [
	EXTRACTION_METHOD_CSS,
	EXTRACTION_METHOD_XPATH,
	EXTRACTION_METHOD_REGEXP,
];



const messages = defineMessages({
	[EXTRACTION_METHOD_CSS]: {
		id: 'ui.customElements.extractionMethods.types.css',
	},
	[EXTRACTION_METHOD_REGEXP]: {
		id: 'ui.customElements.extractionMethods.types.regexp',
	},
	[EXTRACTION_METHOD_XPATH]: {
		id: 'ui.customElements.extractionMethods.types.xpath',
	},
});

const options = LIST_OF_EXTRACTION_METHODS.map((method) => ({
	label: (
		<FormattedMessage {...messages[method]} />
	),
	name: method,
}));



type Props = {
	name: string,
	width: number,
};

const MethodSelectionField: React.FC<Props> = (props) => {
	const {
		name,
		width,
	} = props;

	return (
		<FieldStatus
			focusFieldErrorMessage={name}
			name={name}
			showIcon={false}
		>
			<SelectField
				dropdownWidth={180}
				isInteractedByDefault={false}
				name={name}
				options={options}
				popperBoundary={document.querySelector('.modal.js-scrollable')}
				size={SelectFieldSize.Small}
				width={width}
			/>
		</FieldStatus>
	);
};



export default MethodSelectionField;
