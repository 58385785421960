import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import Copy from '~/components/logic/Copy';
import Form from '~/components/atoms/forms/basis/Form';
import FieldOptionsGroup from '~/components/atoms/forms/components/builders/FieldOptionsGroup';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useCleanupOrphanUrlsMutation,
} from './PruneOrphansModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	description: {
		id: 'ui.pruneOrphansModal.description',
	},
	submitButton: {
		id: 'ui.pruneOrphansModal.submitButton',
	},
	title: {
		id: 'ui.pruneOrphansModal.title',
	},
	warningData: {
		id: 'ui.pruneOrphansModal.warnings.data',
	},
	warningMonitoring: {
		id: 'ui.pruneOrphansModal.warnings.monitoring',
	},
});

const validations = {
	monitoringWarning: [
		{
			message: '',
			field: 'monitoringWarning',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
	dataLossWarning: [
		{
			message: '',
			field: 'dataLossWarning',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
};



const PruneOrphansModal: React.FC = () => {
	const websiteId = useWebsiteId();

	const classicFormBehavior = useClassicFormBehavior();
	const websiteDisplayName = useWebsiteDisplayName(websiteId);

	const [cleanupOrphanUrls] = useCleanupOrphanUrlsMutation();

	const handleSubmit = React.useCallback(
		async () => {
			await cleanupOrphanUrls({
				variables: {
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			cleanupOrphanUrls,
			websiteId,
		],
	);

	return (
		<ModalContainer
			header={(
				<ModalHeader
					title={(
						<Copy
							{...messages.title}
							values={{
								text__websiteName: websiteDisplayName ?? '',
							}}
						/>
					)}
				/>
			)}
		>
			<ModalTextSection>
				<RichText>
					<Copy {...messages.description} />
				</RichText>
			</ModalTextSection>

			<CenteredFormWrapper>
				<Form
					defaultValues={{
						monitoringWarning: false,
						dataLossWarning: false,
					}}
					onSuccess={handleSubmit}
					validations={validations}
				>
					<FormRow fullwidth={true}>
						<FieldOptionsGroup>
							<CheckboxField
								label={(
									<FormattedMessage {...messages.warningData} />
								)}
								name="dataLossWarning"
								width={false}
							/>
							<CheckboxField
								label={(
									<FormattedMessage {...messages.warningMonitoring} />
								)}
								name="monitoringWarning"
								width={false}
							/>
						</FieldOptionsGroup>
					</FormRow>

					<ModalButtonsLayout type={ModalButtonsLayoutType.Steps}>
						<CancelButton />

						<SubmitButton positive={false}>
							<FormattedMessage {...messages.submitButton} />
						</SubmitButton>
					</ModalButtonsLayout>
				</Form>
			</CenteredFormWrapper>
		</ModalContainer>
	);
};



export default PruneOrphansModal;
