import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class TableSegmentFilterLayout extends Component {

	render() {
		const {
			children,
			icon,
		} = this.props;

		if (!React.Children.count(children)) {
			return (
				<div className="table-segment-filter" />
			);
		}

		return (
			<div className="table-segment-filter">
				<div className="table-segment-filter__icon">
					{icon}
				</div>
				<div className="table-segment-filter__filter-values">
					{children}
				</div>
			</div>
		);
	}

}

TableSegmentFilterLayout.propTypes = {
	// Icon necessary to highlight meaning of whole component
	icon: PropTypes.node.isRequired,
};



export default TableSegmentFilterLayout;
