import React from 'react';



type Props = {
	/** Name of property */
	property: React.ReactNode,
	/** Attached values */
	value: React.ReactNode,
};

const Declaration: React.FC<Props> = (props) => {
	const {
		property,
		value,
	} = props;

	return (
		<div className="declaration">
			<span className="declaration__property">
				{property}
			</span>
			<span className="declaration__value">
				{value}
			</span>
		</div>
	);
};



export default Declaration;
