import React from 'react';



type Props = {
	logoutLink: React.ReactNode,
	profileOverview: React.ReactNode,
};



const SidebarProfileLayout: React.FC<Props> = (props) => {
	const {
		logoutLink,
		profileOverview,
	} = props;

	return (
		<div className="sidebar-profile-layout">
			<div className="sidebar-profile-layout__overview">
				{profileOverview}
			</div>
			<div className="sidebar-profile-layout__logout-link">
				{logoutLink}
			</div>
		</div>
	);
};


export default SidebarProfileLayout;
