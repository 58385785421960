import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GlobalFormMessage, {
	GlobalFormMessageStyle as FormErrorMessagesStyle,
} from '~/components/atoms/forms/basis/GlobalFormMessage';



const messages = defineMessages({
	invalidInput: {
		id: 'ui.formErrors.invalidInput',
	},
	serverError: {
		id: 'ui.general.serverError',
	},
	unexpectedError: {
		id: 'ui.general.unexpectedError',
	},
	unknownError: {
		id: 'ui.general.unknownError',
	},
});



type Props = {
	errors?: Record<string, React.ComponentProps<typeof GlobalFormMessage>['children']>,
	style?: FormErrorMessagesStyle,
};

const FormErrorMessages: React.FC<Props> = (props) => {
	const {
		errors = {},
		style,
	} = props;

	return (
		<>
			<GlobalFormMessage
				name="invalid-input"
				style={style}
			>
				<FormattedMessage {...messages.invalidInput} />
			</GlobalFormMessage>

			<GlobalFormMessage
				name="timeout"
				style={style}
			>
				<FormattedMessage {...messages.unknownError} />
			</GlobalFormMessage>

			<GlobalFormMessage
				name="server-error"
				style={style}
			>
				<FormattedMessage {...messages.serverError} />
			</GlobalFormMessage>

			<GlobalFormMessage
				name="invalid_payload"
				style={style}
			>
				<FormattedMessage {...messages.unknownError} />
			</GlobalFormMessage>

			<GlobalFormMessage
				name="unexpected-error"
				style={style}
			>
				<FormattedMessage {...messages.unexpectedError} />
			</GlobalFormMessage>

			<GlobalFormMessage
				name="unknown"
				style={style}
			>
				<FormattedMessage {...messages.unknownError} />
			</GlobalFormMessage>

			<GlobalFormMessage
				name="unknownError"
				style={style}
			>
				<FormattedMessage {...messages.unknownError} />
			</GlobalFormMessage>

			{Object.entries(errors).map(([key, message]) => (
				<GlobalFormMessage
					key={key}
					name={key}
					style={style}
				>
					{message}
				</GlobalFormMessage>
			))}
		</>
	);
};



export default FormErrorMessages;

export {
	FormErrorMessagesStyle,
};
