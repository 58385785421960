import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AbbreviatedNumber from '~/components/app/AbbreviatedNumber';
import IssuesCount, {
	IssuesCountSize,
	IssuesCountType,
} from '~/components/patterns/issues/IssuesCount';
import LabeledValue from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';



const messages = defineMessages({
	openedIssues: {
		id: 'ui.issuesOverview.historySummary.openedIssues',
	},
	resolvedIssues: {
		id: 'ui.issuesOverview.historySummary.resolvedIssues',
	},
});



const HistoricalSummary = (props) => {
	const {
		data,
	} = props;

	let openedIssuesCount: React.ReactNode = null;
	let resolvedIssuesCount: React.ReactNode = null;

	if (data !== null) {
		const openedIssuesCountIssues: Array<{
			scope: {
				movements: Record<string, any>,
			},
		}> = [];

		const resolvedIssuesCountIssues: Array<{
			scope: {
				movements: Record<string, any>,
			},
		}> = [];

		data.forEach((issuesCategory) => {
			issuesCategory.issues.forEach((issue) => {
				if (issue.scope.name !== 'pages') {
					return;
				}

				openedIssuesCountIssues.push(issue);

				if (issue.is_initial_comparison) {
					return;
				}

				resolvedIssuesCountIssues.push(issue);
			});
		});

		openedIssuesCount = (
			<AbbreviatedNumber
				value={openedIssuesCountIssues.reduce((total, issue) => total + issue.scope.movements.opened + issue.scope.movements.opened_new_page, 0)}
			/>
		);

		if (resolvedIssuesCountIssues.length > 0) {
			resolvedIssuesCount = (
				<AbbreviatedNumber
					value={resolvedIssuesCountIssues.reduce((total, issue) => total + issue.scope.movements.closed, 0)}
				/>
			);
		} else {
			resolvedIssuesCount = '-';
		}
	}

	return (
		<SimpleBox paddingSize={2}>
			<SeparatedBlocks>
				<LabeledValue label={<FormattedMessage {...messages.openedIssues} />}>
					{openedIssuesCount !== null && (
						<IssuesCount
							size={IssuesCountSize.Large}
							type={IssuesCountType.Opened}
							value={openedIssuesCount}
						/>
					)}
				</LabeledValue>
				<LabeledValue label={<FormattedMessage {...messages.resolvedIssues} />}>
					{resolvedIssuesCount !== null && (
						<IssuesCount
							size={IssuesCountSize.Large}
							type={IssuesCountType.Fixed}
							value={resolvedIssuesCount}
						/>
					)}
				</LabeledValue>
			</SeparatedBlocks>
		</SimpleBox>
	);
};



export default HistoricalSummary;
