import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ExclamationIconBuilder: IconBuilder = {
	icon: ({ color = '#FF5959' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="alert"
					transform="translate(3.000000, 3.000000)"
				>
					<circle
						cx="9"
						cy="9"
						fill={color}
						id="base"
						r="9"
					>
					</circle>
					<rect
						fill="#FFFFFF"
						height="3.375"
						id="ball"
						rx="1.6875"
						width="3.375"
						x="7.3125"
						y="10.6875"
					>
					</rect>
					<path
						d="M7.46777894,4.85631614 C7.40454664,4.03820783 8.02258345,3.375 8.8360239,3.375 L9.10073598,3.375 C9.91962909,3.375 10.5378368,4.04689336 10.4828593,4.85631614 L10.2256139,8.64368386 C10.1700464,9.46179217 9.46209526,10.125 8.63674736,10.125 L9.36325265,10.125 C8.5413134,10.125 7.82306879,9.45310664 7.76050782,8.64368386 L7.46777894,4.85631614 Z"
						fill="#FFFFFF"
						id="stick"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default ExclamationIconBuilder;

