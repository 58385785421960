import React from 'react';

import {
	isFunction,
} from '~/utilities/typeCheck';



const serializeValue = <Value extends any>(value: Value) => {
	if (isFunction(value)) {
		return value.toString();
	}

	if (typeof value === 'object') {
		return JSON.stringify(value);
	}

	return (value as any).toString();
};

function assertFunctionOrObject(
	arg: unknown,
): asserts arg is Function | object {
	if (!['function', 'object'].includes(typeof arg)) {
		throw new Error(
			`Value must be of type object or function. Received ${typeof arg}`,
		);
	}
}

// function signature type intentionally open-ended to allow overloads

function useStableReference<Value extends object>(value: Value) {
	assertFunctionOrObject(value);

	const [internalValue, setInternalValue] = React.useState(() => value);

	React.useEffect(() => {
		const serializedValue = serializeValue(value);

		if (serializeValue(internalValue) !== serializedValue) {
			setInternalValue(() => value);
		}
	}, [value, internalValue]);

	return internalValue;
}



export default useStableReference;
