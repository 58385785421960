import {
	setContext,
	setTag,
	setUser,
} from '@sentry/browser';
import React from 'react';
import {
	useSelector,
} from 'react-redux';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useRoyalMode from '~/hooks/useRoyalMode';
import useUserEmail from '~/hooks/useUserEmail';

import {
	inAuthenticatedSectionSelector,
} from '~/state/authentication/selectors';



const SentryContext: React.FC = () => {
	const currentUserId = useCurrentUserId();

	const inAuthenticatedSection = useSelector(inAuthenticatedSectionSelector);

	const currentUserEmail = useUserEmail(currentUserId);
	const featureFlags = useFeatureFlags();
	const isImpersonated = useRoyalMode().isImpersonated;

	React.useEffect(
		() => {
			setContext('Feature flags',
				{
					'Feature Flags': featureFlags.listAllEnabled(),
				},
			);
		},
		[
			featureFlags,
		],
	);

	React.useEffect(
		() => {
			setUser({
				email: (inAuthenticatedSection && currentUserEmail)
					? currentUserEmail
					: undefined,
			});
		},
		[
			currentUserEmail,
			inAuthenticatedSection,
		],
	);

	React.useEffect(
		() => {
			setTag(
				'is_impersonated',
				inAuthenticatedSection ? isImpersonated : null,
			);
		},
		[
			inAuthenticatedSection,
			isImpersonated,
		],
	);

	return null;
};



export default React.memo(SentryContext);
