import type Immutable from 'immutable';
import React from 'react';

import type CK from '~/types/contentking';

import DiffFormatter from '../formatters/DiffFormatter';
import FieldFormatter from '../formatters/FieldFormatter';



type Props = {
	column: CK.PagesColumn,
	row: Immutable.Map<string, any>,
};

const ExtraFieldFormatter: React.FC<Props> = (props) => {
	const {
		column,
		row,
	} = props;

	return (
		<DiffFormatter
			customElements={row.has('custom_elements') ? row.get('custom_elements') : false}
			formatter={(value, pageType, customElements) => {
				return (
					<FieldFormatter
						column={column}
						customElements={customElements}
						pageType={pageType}
						value={value}
						zIndex={1200}
					/>
				);
			}}
			isColored={true}
			pageType={row.get('type')}
			value={row.get(column)}
		/>
	);
};



export default ExtraFieldFormatter;
