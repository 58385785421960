import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import ExternalLink from '~/components/patterns/links/ExternalLink';
import InternalLink from '~/components/patterns/links/InternalLink';
import MissingValue from '~/components/app/MissingValue';



const messages = defineMessages({
	self: {
		id: 'ui.contentData.canonicalToSelf',
	},
});



type Props = {
	linkRelItem: {
		content: string | null,
		isCrossdomain: boolean | null,
		legacyUrlId: number | null,
	} | null,
	pageUrl: string,
	websiteId: CK.WebsiteId,
};

const LinkRelValue: React.FC<Props> = (props) => {
	const {
		linkRelItem,
		pageUrl,
		websiteId,
	} = props;

	if (linkRelItem === null) {
		return (
			<MissingValue />
		);
	}

	if (pageUrl === linkRelItem.content) {
		return (
			<FormattedMessage {...messages.self} />
		);
	} else if (!linkRelItem.isCrossdomain && linkRelItem.legacyUrlId) {
		return (
			<InternalLink
				ellipsis={true}
				routeName="website.pages.detail"
				routeParams={{
					websiteId,
					id: linkRelItem.legacyUrlId,
				}}
			>
				{linkRelItem.content}
			</InternalLink>
		);
	} else if (linkRelItem.content) {
		return (
			<ExternalLink
				ellipsis={true}
				href={linkRelItem.content}
			>
				{linkRelItem.content}
			</ExternalLink>
		);
	}

	return (
		<MissingValue />
	);
};



export default LinkRelValue;
