import React from 'react';

import AttachedNote, {
	AttachedNoteAlignment,
} from '~/components/patterns/structuredValues/AttachedNote';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Grid, {
	GridGapsSize,
} from '~/components/patterns/structures/Grid';
import RichText from '~/components/patterns/typography/RichText';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	buttonALabel: React.ReactNode,
	buttonANote: React.ReactNode,
	buttonAOnClick: () => void,
	buttonAStyle: ButtonStyle,
	buttonBLabel: React.ReactNode,
	buttonBNote: React.ReactNode,
	buttonBOnClick: () => void,
	buttonBStyle: ButtonStyle,
};

const TwinButtons: React.FC<Props> = (props) => {
	const {
		buttonALabel,
		buttonANote,
		buttonAOnClick,
		buttonAStyle,
		buttonBLabel,
		buttonBNote,
		buttonBOnClick,
		buttonBStyle,
	} = props;

	const viewportType = useViewportType();

	return (
		<Grid
			columnsCount={viewportType.isSmall ? 1 : 2}
			gapsSize={GridGapsSize.Large}
		>
			<AttachedNote
				alignment={AttachedNoteAlignment.Center}
				note={(
					<RichText>
						{buttonANote}
					</RichText>
				)}
			>
				<Button
					onClick={buttonAOnClick}
					style={buttonAStyle}
				>
					{buttonALabel}
				</Button>
			</AttachedNote>

			<AttachedNote
				alignment={AttachedNoteAlignment.Center}
				note={(
					<RichText>
						{buttonBNote}
					</RichText>
				)}
			>
				<Button
					onClick={buttonBOnClick}
					style={buttonBStyle}
				>
					{buttonBLabel}
				</Button>
			</AttachedNote>
		</Grid>
	);
};



export default TwinButtons;

export {
	ButtonStyle as TwinButtonsStyle,
};
