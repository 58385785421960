import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ExternalLink from '~/components/patterns/links/ExternalLink';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import StaticText from '~/components/atoms/forms/components/StaticText';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



const messages = defineMessages({
	pluginStatusAvailable: {
		id: 'ui.websites.form.api.status.available',
	},
	pluginStatusEnabled: {
		id: 'ui.websites.form.api.status.enabled',
	},
	wpPlugin: {
		id: 'ui.websites.form.api.wp.title',
	},
	wpPluginAvailableInfo: {
		id: 'ui.websites.form.api.wp.availableMessage',
	},
	wpPluginLink: {
		id: 'ui.websites.form.api.wp.availableMessage.link',
	},
});



type Props = {
	showAvailabilityDetails: boolean,
};

const WordpressPluginRow: React.FC<Props> = (props) => {
	const {
		showAvailabilityDetails,
	} = props;

	const websiteId = useWebsiteId();
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const wordpressIntegrationStatus = websiteIntegrations.getStatus(GraphQL.WebsiteIntegrationType.Wordpress);

	if (wordpressIntegrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled) {
		return (
			<FormRow
				label={(
					<FormattedMessage {...messages.wpPlugin} />
				)}
			>
				<StaticText focusTarget="wp_plugin">
					<FormattedMessage {...messages.pluginStatusEnabled} />
				</StaticText>
			</FormRow>
		);
	}

	if (wordpressIntegrationStatus === GraphQL.WebsiteIntegrationStatus.Available) {
		return (
			<FormRow
				description={showAvailabilityDetails && (
					<FormattedMessage
						{...messages.wpPluginAvailableInfo}
						values={{
							link: (
								<ExternalLink href="https://wordpress.org/plugins/contentking/">
									<FormattedMessage {...messages.wpPluginLink} />
								</ExternalLink>
							),
						}}
					/>
				)}
				label={(
					<FormattedMessage {...messages.wpPlugin} />
				)}
			>
				<StaticText focusTarget="wp_plugin">
					<FormattedMessage {...messages.pluginStatusAvailable} />
				</StaticText>
			</FormRow>
		);
	}

	return null;
};



export default WordpressPluginRow;
