import React from 'react';
import {
	useSelector,
} from 'react-redux';

import SitemapsErrorsTable from './SitemapsErrorsTable';

import {
	loadData as loadSitemaps,
	selector as sitemapsSelector,
} from '~/state/invalidStructureSitemaps';



type Props = {
	tableWidth: number,
};

const InvalidStructureSitemapsErrorsTable: React.FC<Props> = (props) => {
	const {
		tableWidth,
	} = props;

	const sitemaps = useSelector(sitemapsSelector);

	return (
		<SitemapsErrorsTable
			loadSitemaps={loadSitemaps}
			sitemaps={sitemaps}
			tableWidth={tableWidth}
		/>
	);
};



export default InvalidStructureSitemapsErrorsTable;
