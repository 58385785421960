import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import ComparisonFilter from '~/components/logic/issuesOverview/ComparisonFilter';
import IssuesOverviewFilter from '~/components/app/IssuesOverviewFilter';
import ModalHeader from '~/components/patterns/modals/parts/ModalHeader';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';

import {
	isActiveSelector,
} from '~/state/websiteIssuesComparison/selectors';



const messages = defineMessages({
	title: {
		id: 'ui.issuesOverview.filterTitle',
	},
});



const IssuesOverviewFilterPanel: React.FC = () => {
	const isComparisonActive = useSelector(isActiveSelector);

	return (
		<ModalContainer
			header={(
				<ModalHeader
					title={(
						<FormattedMessage {...messages.title} />
					)}
				/>
			)}
		>
			{isComparisonActive
				? (
					<ComparisonFilter
						inline={false}
					/>
				)
				: (
					<IssuesOverviewFilter
						inline={false}
					/>
				)
			}
		</ModalContainer>
	);
};



export default IssuesOverviewFilterPanel;
