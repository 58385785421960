import classNames from 'classnames';
import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';

import AbstractBoxContent, {
	AbstractBoxContentScope,
} from '~/components/patterns/boxes/AbstractBoxContent.part';
import BorderedBoxHeader from './BorderedBoxHeader.part';
import Caption from '~/components/patterns/headings/Caption';

import {
	notEmpty,
} from '~/utilities/typeCheck';



export enum BorderedBoxStatus {
	Critical = 'critical',
	Neutral = 'neutral',
	Warning = 'warning',
}

type NoHeaderProps = {
	/** Custom header content */
	header?: never,
	/** The main box label */
	headerLabel?: never,
	/** Additional Call-to-action elements displayed in the box header */
	headerActionElements?: never,
	/** Icon displayed in the box header */
	headerIcon?: never,
};

type CustomHeaderProps = {
	/** Custom header content */
	header: React.ReactNode,
	/** The main box label */
	headerLabel?: never,
	/** Additional Call-to-action elements displayed in the box header */
	headerActionElements?: never,
	/** Icon displayed in the box header */
	headerIcon?: never,
};

type SpecificHeaderProps = {
	/** Custom header content */
	header?: never,
	/** The main box label */
	headerLabel: React.ReactNode,
	/** Additional Call-to-action elements displayed in the box header */
	headerActionElements?: React.ReactNode,
	/** Icon displayed in the box header */
	headerIcon?: React.ReactNode,
};

type GeneralProps = {
	/** Possibility to disable animation on collapsible boxes */
	animations?: boolean,
	children?: React.ReactNode,
	footer?: React.ReactNode,
	/** Customizable size of footer padding */
	footerPaddingSize?: 0 | 1 | 2 | 3,
	/** Customizable size of header padding */
	headerPaddingSize?: 0 | 1 | 2 | 3,
	/** Sub-label visible under main box label */
	headerSublabel?: React.ReactNode,
	/** Additional tag attached to label */
	headerTag?: React.ReactNode,
	/** Default state of collapsible box */
	isCollapsed?: boolean,
	/** Make box collapsible */
	isCollapsible?: boolean,
	/** Customizable size of content padding */
	paddingSize?: 0 | 1 | 2 | 3,
	/** Possibility to switch color scheme */
	reversedColors?: boolean,
	status?: BorderedBoxStatus,
};

type Props = GeneralProps & (NoHeaderProps | CustomHeaderProps | SpecificHeaderProps);

type BorderedBoxContextType = {
	recalculateHeight: () => void,
};

const BorderedBoxContext = React.createContext<BorderedBoxContextType | null>(null);



const BorderedBox: React.FC<Props> = (props) => {
	const {
		animations = true,
		footer,
		footerPaddingSize = 1,
		header,
		headerActionElements,
		headerIcon,
		headerLabel,
		headerPaddingSize = 1,
		headerSublabel,
		headerTag,
		children,
		isCollapsed = false,
		isCollapsible = false,
		paddingSize = 1,
		reversedColors = false,
		status = BorderedBoxStatus.Neutral,
	} = props;

	const [maxHeight, setMaxHeight] = React.useState<number | null>(null);

	const bodyRef = React.useRef<HTMLDivElement | null>(null);

	const calculateContentHeight = React.useCallback(
		() => {
			const body = bodyRef.current;

			if (body && maxHeight !== body.offsetHeight) {
				setMaxHeight(body.offsetHeight);
			}
		},
		[
			maxHeight,
		],
	);

	React.useEffect(
		() => {
			calculateContentHeight();
		},
		[
			calculateContentHeight,
			children,
		],
	);

	const elements = flattenChildren(children).filter(notEmpty);

	let headerElement = header;

	if (!header && headerLabel) {
		headerElement = (
			<BorderedBoxHeader
				actionElements={headerActionElements}
				icon={headerIcon}
				label={(
					<Caption>
						{headerLabel}
					</Caption>
				)}
				labelTag={headerTag}
				sublabel={headerSublabel}
			/>
		);
	}

	const componentClasses = classNames({
		'bordered-box': true,
		'bordered-box--reversed-colors': reversedColors,

		// Statuses
		'bordered-box--status-critical': status === BorderedBoxStatus.Critical,
		'bordered-box--status-warning': status === BorderedBoxStatus.Warning,
	});

	const contentClasses = classNames({
		'bordered-box__content': true,
		'bordered-box__content--animated': animations,
		'bordered-box__content--is-collapsible': isCollapsible,
		'bordered-box__content--is-collapsed': isCollapsible && isCollapsed,
	});

	const context = React.useMemo<BorderedBoxContextType>(
		() => ({
			recalculateHeight: () => calculateContentHeight,
		}),
		[
			calculateContentHeight,
		],
	);

	return (
		<div className={componentClasses}>
			{headerElement && (
				<div className="bordered-box__header">
					<AbstractBoxContent
						paddingSize={headerPaddingSize}
						scope={AbstractBoxContentScope.Header}
					>
						{headerElement}
					</AbstractBoxContent>
				</div>
			)}

			<BorderedBoxContext.Provider value={context}>
				<div
					className={contentClasses}
					style={{
						maxHeight: isCollapsible && !isCollapsed && maxHeight ? (maxHeight + 30) : undefined,
					}}
				>
					<div ref={bodyRef}>
						{elements.map((child, index) => {
							return (
								<div
									className="bordered-box__content-group"
									key={'content-group-' + index}
								>
									<AbstractBoxContent
										key={'content-box-' + index}
										paddingSize={paddingSize}
										scope={AbstractBoxContentScope.Content}
									>
										{child}
									</AbstractBoxContent>
								</div>
							);
						})}

						{footer && (
							<div className="bordered-box__footer">
								<AbstractBoxContent
									paddingSize={footerPaddingSize}
									scope={AbstractBoxContentScope.Content}
								>
									{footer}
								</AbstractBoxContent>
							</div>
						)}
					</div>
				</div>
			</BorderedBoxContext.Provider>
		</div>
	);
};



export default BorderedBox;

export {
	BorderedBoxContext,
};
