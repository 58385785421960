/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MembersOverviewSectionQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type MembersOverviewSectionQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly displayName: string, readonly email: string, readonly hasLimitedWebsitesAccess: boolean, readonly id: CK.InvitationId, readonly role: GraphQL.UserRole, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly displayName: string, readonly email: string, readonly hasTwoFactorAuthentication: boolean, readonly id: string, readonly isChromeExtensionInstalled: boolean, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> } | null };


export const MembersOverviewSectionDocument = gql`
    query MembersOverviewSection($accountId: AccountId!) {
  account(id: $accountId) {
    id
    invitations {
      displayName
      email
      hasLimitedWebsitesAccess
      id
      role
      websites {
        id
      }
    }
    memberships {
      account {
        id
      }
      hasLimitedWebsitesAccess
      role
      user {
        displayName
        email
        hasTwoFactorAuthentication
        id
        isChromeExtensionInstalled
        legacyId
      }
      websites {
        id
      }
    }
  }
}
    `;

/**
 * __useMembersOverviewSectionQuery__
 *
 * To run a query within a React component, call `useMembersOverviewSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersOverviewSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersOverviewSectionQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useMembersOverviewSectionQuery(baseOptions: Apollo.QueryHookOptions<MembersOverviewSectionQuery, MembersOverviewSectionQueryVariables> & ({ variables: MembersOverviewSectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersOverviewSectionQuery, MembersOverviewSectionQueryVariables>(MembersOverviewSectionDocument, options);
      }
export function useMembersOverviewSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersOverviewSectionQuery, MembersOverviewSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersOverviewSectionQuery, MembersOverviewSectionQueryVariables>(MembersOverviewSectionDocument, options);
        }
export function useMembersOverviewSectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembersOverviewSectionQuery, MembersOverviewSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MembersOverviewSectionQuery, MembersOverviewSectionQueryVariables>(MembersOverviewSectionDocument, options);
        }
export type MembersOverviewSectionQueryHookResult = ReturnType<typeof useMembersOverviewSectionQuery>;
export type MembersOverviewSectionLazyQueryHookResult = ReturnType<typeof useMembersOverviewSectionLazyQuery>;
export type MembersOverviewSectionSuspenseQueryHookResult = ReturnType<typeof useMembersOverviewSectionSuspenseQuery>;
export type MembersOverviewSectionQueryResult = Apollo.QueryResult<MembersOverviewSectionQuery, MembersOverviewSectionQueryVariables>;