import React from 'react';
import Measure from 'react-measure';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



export type MeasurerChildrenProps = {
	containerHeight: number,
	containerWidth: number,
};



type Props = {
	children: RenderProp<MeasurerChildrenProps>,
};

const Measurer: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const measureInnerRef = React.useRef<HTMLElement>(null);

	const [contentDimensions, setContentDimensions] = React.useState<{
		height: number,
		width: number,
	} | null>(null);

	const handleComponentResize = React.useCallback(
		(contentRect) => {
			setContentDimensions({
				height: contentRect.bounds.height,
				width: contentRect.bounds.width,
			});
		},
		[
			setContentDimensions,
		],
	);

	return (
		<Measure
			bounds={true}
			innerRef={measureInnerRef}
			onResize={handleComponentResize}
		>
			{({ measureRef }) => (
				<div
					ref={measureRef}
					style={{
						width: '100%',
					}}
				>
					{contentDimensions !== null && (
						renderProp(
							children,
							{
								containerHeight: contentDimensions.height,
								containerWidth: contentDimensions.width,
							},
						)
					)}
				</div>
			)}
		</Measure>
	);
};



export default Measurer;
