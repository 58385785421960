import classNames from 'classnames';
import React from 'react';



export enum DomSrcBadgeType {
	Dom = 'DOM',
	Src = 'SRC',
}

type Props = {
	type: DomSrcBadgeType,
};



const DomSrcBadge: React.FC<Props> = (props) => {
	const {
		type,
	} = props;

	const componentClasses = classNames({
		'dom-src-badge': true,
		['dom-src-badge--' + type.toLowerCase() + '-type']: true,
	});

	return (
		<span className={componentClasses}>
			<span className="dom-src-badge__value">
				{type}
			</span>
		</span>
	);
};



export default DomSrcBadge;
