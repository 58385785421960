import type CK from '~/types/contentking';

import {
	useUrlIdQuery,
} from './useUrlId.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function useUrlId(
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
): CK.UrlId | null {
	const { data } = usePageDetailPropertiesQuery(
		useUrlIdQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.id ?? null;
}



export default useUrlId;
