/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DistributionOfPageTypeWidgetQueryVariables = GraphQL.Exact<{
  date: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DistributionOfPageTypeWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly distributionOfPageType: { readonly __typename?: 'WebsiteDashboardDistributionOfPageType', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: ReadonlyArray<{ readonly __typename?: 'WebsiteDashboardDistributionOfPageTypeData', readonly percentageOfPages: CK.Percentage, readonly numberOfPages: number, readonly pageType: GraphQL.PageType }> | null } } };


export const DistributionOfPageTypeWidgetDocument = gql`
    query DistributionOfPageTypeWidget($date: DateYMD!, $scope: String!, $websiteId: WebsiteId!) {
  dashboardData {
    distributionOfPageType(date: $date, scope: $scope, websiteId: $websiteId) {
      data {
        percentageOfPages
        numberOfPages
        pageType
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useDistributionOfPageTypeWidgetQuery__
 *
 * To run a query within a React component, call `useDistributionOfPageTypeWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributionOfPageTypeWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributionOfPageTypeWidgetQuery({
 *   variables: {
 *      date: // value for 'date'
 *      scope: // value for 'scope'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDistributionOfPageTypeWidgetQuery(baseOptions: Apollo.QueryHookOptions<DistributionOfPageTypeWidgetQuery, DistributionOfPageTypeWidgetQueryVariables> & ({ variables: DistributionOfPageTypeWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistributionOfPageTypeWidgetQuery, DistributionOfPageTypeWidgetQueryVariables>(DistributionOfPageTypeWidgetDocument, options);
      }
export function useDistributionOfPageTypeWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistributionOfPageTypeWidgetQuery, DistributionOfPageTypeWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistributionOfPageTypeWidgetQuery, DistributionOfPageTypeWidgetQueryVariables>(DistributionOfPageTypeWidgetDocument, options);
        }
export function useDistributionOfPageTypeWidgetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DistributionOfPageTypeWidgetQuery, DistributionOfPageTypeWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DistributionOfPageTypeWidgetQuery, DistributionOfPageTypeWidgetQueryVariables>(DistributionOfPageTypeWidgetDocument, options);
        }
export type DistributionOfPageTypeWidgetQueryHookResult = ReturnType<typeof useDistributionOfPageTypeWidgetQuery>;
export type DistributionOfPageTypeWidgetLazyQueryHookResult = ReturnType<typeof useDistributionOfPageTypeWidgetLazyQuery>;
export type DistributionOfPageTypeWidgetSuspenseQueryHookResult = ReturnType<typeof useDistributionOfPageTypeWidgetSuspenseQuery>;
export type DistributionOfPageTypeWidgetQueryResult = Apollo.QueryResult<DistributionOfPageTypeWidgetQuery, DistributionOfPageTypeWidgetQueryVariables>;