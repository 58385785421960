import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ConfirmationMessage, {
	ConfirmationMessageContext,
} from '~/components/patterns/messages/embedded/ConfirmationMessage';
import Copy from '~/components/logic/Copy';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useWebsiteDomain from '~/hooks/useWebsiteDomain';



const messages = defineMessages({
	addAnother: {
		id: 'ui.websites.new.steps.success.addAnother',
	},
	addMore: {
		id: 'ui.websites.new.steps.success.addMore',
	},
	description: {
		id: 'ui.websites.new.steps.success.description',
	},
	finish: {
		id: 'ui.websites.new.steps.success.finish',
	},
	heading: {
		id: 'ui.websites.new.steps.success.heading',
	},
	title: {
		id: 'ui.websites.new.steps.success.title',
	},
	viewSettings: {
		id: 'ui.websites.new.steps.success.viewSettings',
	},
	viewWebsite: {
		id: 'ui.websites.new.steps.success.viewWebsite',
	},
});



type Props = {
	domains: Array<string>,
	websiteId: CK.WebsiteId | null,
};

const AddWebsiteModalSuccessStep: React.FC<Props> = (props) => {
	const {
		domains,
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const websiteDomain = useWebsiteDomain(websiteId);

	const addedSingleWebsite = domains.length <= 1 && websiteId !== null;
	const domainName = domains.length === 1 ? domains[0] : websiteDomain;
	const numberOfAddedWebsites = domains.length > 0 ? domains.length : 1;

	return (
		<MultiStepModalStep
			name="success"
			title={(
				<FormattedMessage
					{...messages.title}
					values={{ domains: numberOfAddedWebsites }}
				/>
			)}
		>
			{({ isActive }) => (isActive ? (
				<ConfirmationMessage
					animate={true}
					context={ConfirmationMessageContext.Confirmation}
					ctaButtons={(
						<>
							{addedSingleWebsite && (
								<Button
									linkRouteName="website.pages"
									linkRouteParams={{
										websiteId,
									}}
									style={ButtonStyle.Link}
								>
									<FormattedMessage {...messages.viewWebsite} />
								</Button>
							)}

							{addedSingleWebsite && (
								<Button
									linkRouteName="website.detail"
									linkRouteParams={{
										websiteId,
									}}
									style={ButtonStyle.Link}
								>
									<FormattedMessage {...messages.viewSettings} />
								</Button>
							)}

							<Button
								onClick={classicFormBehavior.finish}
								style={ButtonStyle.Action}
								uppercase={false}
								width={ButtonWidth.SubmitButton}
							>
								<FormattedMessage {...messages.finish} />
							</Button>
						</>
					)}
					message={(
						<FormattedMessage
							{...messages.heading}
							values={{ domains: numberOfAddedWebsites }}
						/>
					)}
				>
					<Copy
						{...messages.description}
						values={{
							domainName,
							domains: numberOfAddedWebsites,
						}}
					/>
				</ConfirmationMessage>
			) : null)}
		</MultiStepModalStep>
	);
};



export default AddWebsiteModalSuccessStep;
