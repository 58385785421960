import React from 'react';
import {
	useStripe,
} from '@stripe/react-stripe-js';

import type CK from '~/types/contentking';

import useLoadPaymentStatus from '~/hooks/useLoadPaymentStatus';

import {
	runAction,
} from '~/model/actions';



type ReturnType = () => Promise<void>;



function useResolvePaymentStatus(accountId: CK.AccountId): ReturnType {
	const loadPaymentStatus = useLoadPaymentStatus();

	const stripe = useStripe();

	return React.useCallback(
		async () => {
			if (stripe === null) {
				return;
			}

			const paymentStatus = await loadPaymentStatus(accountId);

			const clientSecret = paymentStatus?.stripeAction?.clientSecret ?? null;

			if (clientSecret !== null) {
				await stripe.confirmCardPayment(clientSecret);

				await runAction({
					action: 'RefreshPaymentStatus',
					input: {
						accountId,
					},
				});

				await loadPaymentStatus(accountId);
			}
		},
		[
			accountId,
			loadPaymentStatus,
			stripe,
		],
	);
}



export default useResolvePaymentStatus;
