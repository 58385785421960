import React from 'react';
import ReactDOM from 'react-dom';

import BreadcrumbNavigation from '~/components/atoms/navigations/BreadcrumbNavigation';
import ModalHeader, {
	ModalHeaderIconType as MultiStepModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import {
	MultiStepModalContext,
} from './MultiStepModal';



type Props = {
	iconType?: MultiStepModalHeaderIconType,
	title?: React.ReactNode,
};

const MultiStepModalHeader: React.FC<Props> = (props) => {
	const {
		iconType,
		title,
	} = props;

	const multiStepModalContext = React.useContext(MultiStepModalContext);

	if (multiStepModalContext === null) {
		throw new Error(
			`Can't be used outside of MultiStepModalContext`,
		);
	}

	const {
		activeStep,
		breadcrumbs,
		headerRef,
	} = multiStepModalContext;

	if (headerRef.current === null) {
		return null;
	}

	return ReactDOM.createPortal(
		<ModalHeader
			additionalInfo={breadcrumbs.length > 1 && (
				<BreadcrumbNavigation
					items={breadcrumbs}
				/>
			)}
			iconType={iconType}
			key={activeStep}
			title={title}
		/>,
		headerRef.current,
	);
};

export { MultiStepModalHeaderIconType };

export default MultiStepModalHeader;
