import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import usePermission from '~/hooks/usePermission';

import {
	ObjectType,
} from '~/model/permissions';



function useIsAllowedWithWebsite(
	websiteId: CK.WebsiteId | null,
	action: GraphQL.ActionWithWebsite,
) {
	const isAllowed = usePermission();

	return isAllowed({
		action,
		objectId: websiteId,
		objectType: ObjectType.Website,
	});
}



export default useIsAllowedWithWebsite;
