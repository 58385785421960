import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const APIIconBuilder: IconBuilder = {
	icon: () => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<g
					fill="#00B2B9"
					fillRule="nonzero"
					id="api-small"
					transform="translate(2.000000, 8.000000)"
				>
					<path
						d="M2.94888889,8.33666667 L3.43888889,7.01888889 L6.77555556,7.01888889 L7.26555556,8.33666667 L9.14333333,8.33666667 L6.14555556,0.555555556 L4.06888889,0.555555556 L1.07111111,8.33666667 L2.94888889,8.33666667 Z M6.32111111,5.56 L3.89444444,5.56 L5.10777778,2.21222222 L6.32111111,5.56 Z M11.5877778,8.33666667 L11.5877778,5.56 L13.5722222,5.56 C15.2511111,5.56 16.1844444,4.41666667 16.1844444,3.06333333 C16.1844444,1.69888889 15.2622222,0.555555556 13.5722222,0.555555556 L9.93222222,0.555555556 L9.93222222,8.33666667 L11.5877778,8.33666667 Z M13.3388889,4.10222222 L11.5888889,4.10222222 L11.5888889,2.01333333 L13.3388889,2.01333333 C13.9922222,2.01333333 14.4933333,2.41 14.4933333,3.06333333 C14.4933333,3.70555556 13.9922222,4.10222222 13.3377778,4.10222222 L13.3388889,4.10222222 Z M18.9444444,8.33666667 L18.9444444,0.555555556 L17.2888889,0.555555556 L17.2888889,8.33666667 L18.9444444,8.33666667 Z"
						id="Shape"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default APIIconBuilder;

