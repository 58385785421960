import React from 'react';



function useForwardedRef<TRef>(forwardedRef: React.ForwardedRef<TRef>) {
	const finalRef = React.useRef<TRef | null>(null);

	React.useEffect(
		() => {
			if (forwardedRef === null) {
				return;
			}

			if (typeof forwardedRef === 'function') {
				forwardedRef(finalRef.current ?? null);
			} else {
				forwardedRef.current = finalRef.current ?? null;
			}
		},
		[
			forwardedRef,
		],
	);

	return finalRef;
}



export default useForwardedRef;
