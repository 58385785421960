import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';

import ExpandableFilterField, {
	ExpandableFilterFieldSize,
	OPERATOR_EMPTY,
	OPERATOR_MISSING,
	OPERATOR_PRESENT,
} from '~/components/atoms/forms/components/ExpandableFilterField';



const messages = defineMessages({
	placeholder: {
		id: 'ui.contentOverview.tableFilter.placeholder',
	},
});



type Props = {
	name: string,
	placeholder?: string,
	width: number,
};

const PowerStaticTextFieldFilter = React.forwardRef<any, Props>((props, ref) => {
	const {
		name,
		placeholder,
		width,
	} = props;

	const intl = useIntl();

	return (
		<ExpandableFilterField
			disabledOperators={[
				OPERATOR_EMPTY,
				OPERATOR_MISSING,
				OPERATOR_PRESENT,
			]}
			maxLength={1000}
			name={name}
			placeholder={placeholder || intl.formatMessage(messages.placeholder)}
			ref={ref}
			size={ExpandableFilterFieldSize.Small}
			width={width}
		/>
	);
});



export default React.memo(PowerStaticTextFieldFilter);
